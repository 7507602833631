import { ProspectResponse, ProspectResponseTypes } from 'classes/prospectResponse'
import CenteredRowDiv from 'library/divs/CenteredRowDiv'
import { Icon, Typography } from 'library/materialUI'
import { dynamicButtonArray, Modal, ModalSection } from 'library/Modal'
import { AccountPageContext } from 'pages/accounts/AccountDashboard/AccountPageProvider'
import { triggerSupportBot } from 'pages/bot/InAppBot/triggerSupportBot'
import { ProspectModalContent, SettingsType } from 'pages/plays/playBuilder/nodes/contactSearch/ContactSearchNode'
import { useContext, useReducer, useState } from 'react'
import { components } from 'session-context'
import { ShareContext } from 'share-context'

interface ProspectModalProps {
  onHide: () => void
  selectedAccounts?: string[]
}

function GetProspectModalContent (settings: ProspectModalSettingsType, setSettings: (value: Partial<ProspectModalSettingsType>) => void) {
  const { isOn } = useContext(ShareContext)
  return (
    <>
      <ProspectModalContent
        settings={settings}
        updateSettings={setSettings}
        handlePersonaSelection={(value) => setSettings({ personaIDs: value as number[] })}
        emailComponentOn={isOn(components.EMAILS) || false}
      />
    </>
  )
}

const createModalSection = (title: string | JSX.Element, subtitle: string) => {
  return (
    <ModalSection
      title={title}
      subtitle={subtitle}
      titleType='h1'
    />
  )
}

const warningTitle = (title: string) => (
  <CenteredRowDiv>
    <Icon
      icon='warning'
      color='#F4D451'
      size='lg'
    />
    <Typography
      variant='h2'
      style={{ marginLeft: 10, fontSize: 20 }}
    >
      {title}
    </Typography>
  </CenteredRowDiv>
)

interface mappingType {
  title: string
  children?: JSX.Element
  btns?: dynamicButtonArray
  saveBtnText?: string
  cancelBtnText?: string
  showCancelBtn?: boolean
  handleSave?: string
  onHide?: string
}

const modalMapping: Record<ProspectResponseTypes | 'default', mappingType> = {
  'already prospected': {
    title: '',
    children: createModalSection(
      warningTitle('Contacts Already Gathered'),
      'You\'ve already gathered contacts from this company matching your selected buying committee personas in the last 30 days. Consider adjusting the titles first to ensure that you get the contacts you\'re looking for.'
    ),
    saveBtnText: 'GATHER ANYWAYS',
    cancelBtnText: 'GO BACK',
    handleSave: 'gather anyways',
    onHide: 'back'
  },
  'already prospected no contacts': {
    title: '',
    children: createModalSection(
      warningTitle('Recent Unsuccessful Look-Up'),
      'You’ve done a lookup in the past 30 days for your selected persona(s) at this company that didn’t return any contacts. Consider adjusting the titles first to ensure that you get the contacts you’re looking for.'
    ),
    saveBtnText: 'GATHER ANYWAYS',
    cancelBtnText: 'GO BACK',
    handleSave: 'gather anyways',
    onHide: 'back'
  },
  'no more contact credits': {
    title: '',
    children: createModalSection(
      warningTitle('No More Contact Credits'),
      'You\'ve use up all of your contact credits for the month. Upgrade your plan by contacting support or wait until your usage resets on the 1st of the month'
    ),
    saveBtnText: 'CONTACT SUPPORT',
    cancelBtnText: 'CANCEL',
    handleSave: 'support'
  },
  'prospect already in progress': {
    title: '',
    children: createModalSection(
      'We\'re already on it',
      'We\'re already grabbing contacts for these personas'
    ),
    saveBtnText: 'CLOSE',
    handleSave: 'close'
  },
  'prospect starting': {
    title: '',
    children: createModalSection(
      'Your Contacts Are On The Way!',
      'Grabbing your contacts could take some time. We\'ll send you an email when we have them'
    ),
    saveBtnText: 'GOT IT',
    showCancelBtn: false,
    handleSave: 'close'
  },
  default: {
    title: 'Prospect Contacts',
    saveBtnText: 'GATHER CONTACTS'
  }
}

interface ProspectModalSettingsType extends SettingsType {
  integrationEntryList: Array<any>
  integrations: Record<any, string[]>
}

export const defaultProspectModalSettings: ProspectModalSettingsType = {
  contactLimit: 5,
  personaIDs: [],
  emailEnabled: false,
  delaySend: false,
  emailID: 0,
  delayHours: 0,
  integrationEnabled: false,
  integrationList: [],
  integrationEntryList: [],
  integrations: {}
}

export const reducer = (state: ProspectModalSettingsType, changes: Partial<ProspectModalSettingsType>) => {
  return { ...state, ...changes } as ProspectModalSettingsType
}

export default function ProspectModal ({ onHide, selectedAccounts }: ProspectModalProps) {
  const { account } = useContext(AccountPageContext)
  const [settings, setSettings] = useReducer(reducer, defaultProspectModalSettings)
  const prospectModalContent = GetProspectModalContent(settings, setSettings)
  const [modalType, setModalType] = useState<ProspectResponseTypes | 'default'>('default')

  const handleSave = (gatherAnyways = false) => {
    if (selectedAccounts && selectedAccounts.length > 0) {
      ProspectResponse.bulkProspect({ domains: selectedAccounts, ...settings, gatherAnyways }).then((resp) => {
        setModalType(resp.resp)
      })
    } else {
      ProspectResponse.prospect({ ...settings, domain: account.domain, gatherAnyways }).then((resp) => {
        setModalType(resp.resp)
      })
    }
  }

  const btnFuctionMapping = {
    close: () => onHide(),
    save: () => handleSave(),
    'open buying committee': () => window.open('/contact_management/buying_committee'),
    back: () => setModalType('default'),
    'gather anyways': () => handleSave(true),
    support: () => { onHide(); triggerSupportBot() }
  }

  const { title, children, btns, saveBtnText, cancelBtnText, showCancelBtn, handleSave: mappingHandleSave, onHide: modalOnHide } = modalMapping[modalType]
  const displayChildren = children || prospectModalContent
  const saveToUse = mappingHandleSave && mappingHandleSave in btnFuctionMapping ? btnFuctionMapping[mappingHandleSave as keyof typeof btnFuctionMapping] : handleSave
  const hideToUse = modalOnHide && modalOnHide in btnFuctionMapping ? btnFuctionMapping[modalOnHide as keyof typeof btnFuctionMapping] : onHide

  return (
    <Modal
      open
      onHide={() => onHide()}
      title={title}
      handleSave={() => saveToUse()}
      noDividers
      closeBtn
      dynamicButtons={btns}
      saveBtnText={saveBtnText}
      cancelBtnText={cancelBtnText}
      closeBtnFunction={() => hideToUse()}
      showCancelButton={showCancelBtn}
    >
      {displayChildren}
    </Modal>
  )
}
