import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { jsPlumbUtil } from 'jsplumbtoolkit'
import EditModal from '../EditModal'
import useNodeStyles from './NodeStyles'
import Divider from '@material-ui/core/Divider'
import MenuItem from '@material-ui/core/MenuItem'
import { Select, Button } from '@material-ui/core'
import UserPicker from '../UserPicker'
import TeamPicker from '../TeamPicker'
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic'
import OptionPort from '../OptionPort'
import 'jsplumb'
import '../quill.css'
import { EVENT_TYPE, Emitter } from 'emitter'

const useStyles = makeStyles(theme => ({
  subtitle: {
    color: '#999',
    fontFamily: 'Poppins, sans serif',
    fontSize: '12px'
  },
  divider: {
    marginTop: 10,
    marginBottom: 10
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    marginRight: 8
  },
  input: {
    padding: 6,
    borderRadius: 30
  },
  inputRoot: {
    borderRadius: 30

  },
  select: {
    borderRadius: 30,
    paddingLeft: 10,
    fontFamily: 'Poppins, sans serif',
    fontSize: '14px',
    color: '#5C5C5C'
  },
  removeButton: {
    fontSize: 10,
    marginLeft: 10,
    backgroundColor: theme.palette.primary.main,
    padding: '5px 10px',
    borderRadius: 20,
    color: 'white',
    cursor: 'pointer'
  }
}))

const RemoveMethodButton = (props) => {
  const classes = useStyles()
  return (
    <div style={{ display: 'flex' }} onClick={props.onClick}>
      <div>
        Then:
      </div>
      <div className={classes.removeButton}>
        Remove
      </div>
    </div>
  )
}

const MethodObjectSelect = (props) => {
  const methodName = props.methodName
  const objectIds = props.objectIds || []

  if (methodName === 'single_agent') {
    return (
      <UserPicker
        selectedUserIds={objectIds}
        setSelectedUserIds={(userIDs) => props.onChange(userIDs)}
        single
      />
    )
  } else if (methodName === 'round_robin_group' || methodName === 'quick_draw_group') {
    return (
      <UserPicker
        selectedUserIds={objectIds}
        setSelectedUserIds={(userIDs) => props.onChange(userIDs)}
        autocomplete
      />
    )
  } else if (methodName === 'round_robin_team' || methodName === 'quick_draw_team') {
    const selectedTeamId = objectIds[0]
    return (
      <TeamPicker
        value={selectedTeamId}
        setSelectedTeamId={(teamID) => props.onChange([teamID])}
      />
    )
  } else {
    return (<></>)
  }
}

const MethodSelect = props => {
  const ITEM_HEIGHT = 40
  const ITEM_PADDING_TOP = 8
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 6.5 + ITEM_PADDING_TOP,
        width: 250
      }
    }
  }
  return (
    <div className='select' style={{ marginBottom: 10 }}>
      <Select
        fullWidth
        variant='outlined'
        value={props.value}
        onChange={props.handleChange}
        margin='dense'
        MenuProps={MenuProps}
        displayEmpty
      >
        <MenuItem value='' style={{ height: 40 }} disabled>Select a route method</MenuItem>
        <MenuItem value='single_agent' style={{ height: 40 }}>Route to a single agent</MenuItem>
        <MenuItem value='round_robin_group' style={{ height: 40 }}>Round robin within a custom group</MenuItem>
        <MenuItem value='quick_draw_group' style={{ height: 40 }}>Quick draw within a custom group</MenuItem>
        <MenuItem value='round_robin_team' style={{ height: 40 }}>Round robin within a team</MenuItem>
        <MenuItem value='quick_draw_team' style={{ height: 40 }}>Quick draw within a team</MenuItem>
        <MenuItem value='account_owner' style={{ height: 40 }}>Account owner</MenuItem>
      </Select>
    </div>
  )
}

function RouteModal (props) {
  const classes = useStyles()
  const [methods, setMethods] = useState(props.methods || [])

  const objectTypeDict = {
    single_agent: 'user_ids',
    round_robin_group: 'user_ids',
    quick_draw_group: 'user_ids',
    round_robin_team: 'team_ids',
    quick_draw_team: 'team_ids'
  }

  function cancelChanges () {
    props.onHide()
    setMethods(props.methods)
  }

  const handleChange = ({ attr, value, index }) => {
    const newMethods = [...methods]
    newMethods[index] = { ...newMethods[index], [attr]: value }
    setMethods([...newMethods])
  }

  const handleChangeMethodSelection = ({ methodName, index }) => {
    const newMethods = [...methods]
    const objectType = objectTypeDict[methodName]
    newMethods[index] = { ...newMethods[index], method_name: methodName, object_ids: [], object_type: objectType }
    setMethods([...newMethods])
  }

  const addMethod = () => {
    if (methods.length < 3) {
      const emptyMethod = { method_name: '', object_ids: [], object_type: '' }
      setMethods([...methods, emptyMethod])
    }
  }

  const removeMethod = (index) => {
    if (index > 0 && methods.length > index) {
      methods.splice(index, 1)
      setMethods([...methods])
    }
  }

  if (!props.open) {
    return <></>
  }

  const save = () => {
    props.save({ methods: methods })
  }

  Emitter.on(EVENT_TYPE.BROWSER_BACK, cancelChanges)

  return (
    <EditModal
      open={props.open}
      onHide={cancelChanges}
      save={save}
      title='Route conversation'
    >
      <div style={{}}>
        <div className={classes.subtitle}>
          Which teammate should this conversation be routed to?
        </div>
        <Divider className={classes.divider} />
        <div style={{ marginRight: 25 }}>
          <div style={{ marginBottom: 15 }}>
            {methods.map((method, index) => (
              <React.Fragment key={index}>
                <div style={{ marginBottom: 10 }}>
                  {index === 0 ? 'First By:' : <RemoveMethodButton onClick={() => removeMethod(index)} />}
                </div>
                <MethodSelect
                  key={index}
                  value={method.method_name}
                  handleChange={(e) => {
                    handleChangeMethodSelection({ methodName: e.target.value, index: index })
                  }}
                />
                <div>
                  <MethodObjectSelect
                    methodName={method.method_name}
                    objectIds={method.object_ids}
                    onChange={(ids) => handleChange({ attr: 'object_ids', value: ids, index: index })}
                  />
                </div>
                <Divider style={{ marginBottom: 15, marginTop: 15 }} />
              </React.Fragment>
            ))}
            <Button
              onClick={addMethod}
              variant='outlined'
              color='primary'
              margin='dense'
              disabled={methods.length >= 3}
            >
              Add Fallback Criteria
            </Button>
          </div>
        </div>
      </div>
    </EditModal>
  )
}

const RouteNode = props => {
  const base = props.base
  const classes = useNodeStyles(props)

  const assignKey = data => {
    data._reactKey = data._reactKey || jsPlumbUtil.uuid()
    return data._reactKey
  }

  const saveChanges = ({ methods }) => {
    if (!base.toolkit) {
      return
    }
    const node = base.getNode()
    const new_data = {
      ...base.state,
      methods: methods,
      // Eventually delete the lines below
      user_ids: undefined,
      method: undefined,
      secondaryMethod: undefined,
      team_id: undefined
    }

    base.toolkit.updateNode(node, new_data)
    props.setModalOpen(false)
  }

  // Reshape old flow body
  if (base.state && base.state.method) {
    if (base.state.method === 'round_robin_group' || base.state.method === 'quick_draw_group' || base.state.method === 'single_agent') {
      base.state.methods = [{ method_name: base.state.method, object_ids: base.state.user_ids, object_type: 'user_ids' }]
    } else if (base.state.method === 'round_robin_team' || base.state.method === 'quick_draw_team') {
      base.state.methods = [{ method_name: base.state.method, object_ids: [base.state.team_id], object_type: 'team_ids' }]
    }

    base.state.method = undefined
  }
  // End of reshape

  let body = 'Route to a single agent'
  if (base.state.methods?.length) {
    const firstMethodName = base.state.methods[0]?.method_name
    if (firstMethodName === 'round_robin_group') {
      body = 'Round robin within a custom group'
    } else if (firstMethodName === 'quick_draw_group') {
      body = 'Quick draw within a custom group'
    } else if (firstMethodName === 'round_robin_team') {
      body = 'Round robin within a team'
    } else if (firstMethodName === 'quick_draw_team') {
      body = 'Quick draw within a team'
    } else if (firstMethodName === 'account_owner') {
      body = 'Route to account owner'
    }
  }

  return (
    <>
      <div>
        <div
          className={`${classes.dragger} ${classes.agentConnection}`}
        >
          <div className='node-header'>
            <div className={classes.iconDiv}><HeadsetMicIcon /></div>
            <div className={classes.cardTitle}>Route Conversation</div>
          </div>
          <div className='node-body'>
            <div>{body}</div>
            <Divider style={{ marginTop: 5 }} />
            <div>
              {base.state.ports.map((c, index) => {
                return (
                  <OptionPort
                    index={index}
                    data={c}
                    key={assignKey(c)}
                    parent={base}
                    kind='agentConnection'
                  />
                )
              })}
            </div>
          </div>
          <jtk-target
            scope='default'
          />
        </div>
      </div>
      <RouteModal
        open={props.modalOpen}
        onHide={() => props.setModalOpen(false)}
        save={saveChanges}
        secondaryMethod={base.state.secondaryMethod}
        selectedUserIds={base.state.user_ids}
        method={base.state.method}
        selectedTeamId={base.state.team_id}
        shouldUseIntegration={base.state.should_use_integration}
        methods={base.state.methods}
      />
    </>
  )
}

export { RouteNode, RouteModal }
