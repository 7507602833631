import { useQuery, QueryFunction, UseQueryOptions, UseQueryResult } from 'react-query'

export interface QueryKeyListInfo {
  page?: number
  rowsPerPage?: number
  sort?: {
    criteria: string
    ascending: boolean
  }
  filter?: {
    search?: string
    startDate?: string | number | Date
    endDate?: string | number | Date
    include?: string[]
    [additional: string]: any
  }
}

export type BasicQueryKey = [string, ...(string | number | Record<string, any> | QueryKeyListInfo)[]]
export type PaginateQueryKey = [string, 'list', QueryKeyListInfo]

type CFQueryKey = PaginateQueryKey | BasicQueryKey

export type QueryConfig<TQueryFnData, TError, TData, TQueryKey extends CFQueryKey> = Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, 'queryKey' | 'queryFn'>

const UseCfQuery = <TResult = unknown, TQueryKey extends CFQueryKey = CFQueryKey, TError = unknown> (
  queryKey: TQueryKey,
  queryFn: QueryFunction<TResult, TQueryKey>,
  config?: QueryConfig<TResult, TError, TResult, TQueryKey>
): UseQueryResult<TResult, TError> => useQuery<TResult, TError, TResult, TQueryKey>(queryKey, queryFn, config)

export default UseCfQuery
