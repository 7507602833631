import { Card as MuiCard, CardContent, CardProps as MuiCardProps } from '@material-ui/core'
import { CardHeader } from 'material-ui/Card'
import { MuiThemeProvider } from 'material-ui/styles'
import { ReactNode } from 'react'

interface CardProps extends MuiCardProps {
  title?: string
  subtitle?: string
  content: ReactNode
}

export default function Card ({ title, subtitle, content } : CardProps) {
  return (
    <MuiThemeProvider>
      <MuiCard>
        {(title || subtitle) &&
          <CardHeader
            title={title}
            subtitle={subtitle}
          />}
        <CardContent>
          {content}
        </CardContent>
      </MuiCard>
    </MuiThemeProvider>
  )
}
