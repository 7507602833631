import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  bar: {
    width: '100%',
    height: 10,
    borderRadius: 5,
    backgroundColor: '#D3D3D3',
    marginTop: 10,
    marginBottom: 10,
    display: 'flex'
  },
  progress: {
    height: 10,
    backgroundColor: '#81AF20',
    borderRadius: 5,
    zIndex: 1
  },
  secondary: {
    height: 10,
    backgroundColor: '#688C17',
    borderRadius: 5,
    zIndex: 2
  }
}))

type ProgressBarProps = {
  current: number
  total: number
  secondary?: number
}

export default function ProgressBar ({ current, total, secondary }: ProgressBarProps) {
  const classes = useStyles()
  if ((current === 0 && !secondary) || total === 0) {
    return (
      <div className={classes.bar} />
    )
  }
  let progress = (current / total) * 100
  let secondaryProgress = 0
  if (secondary) {
    secondaryProgress = (secondary / total) * 100
  }
  if (progress > 100) progress = 100
  if (secondaryProgress + progress > 100) secondaryProgress = 100 - progress

  return (
    <div className={classes.bar}>
      <div className={classes.progress} style={{ width: `${progress}%` }} />
      {(secondary || secondary === 0) && <div className={classes.secondary} style={{ position: 'relative', right: progress > 0 ? '2%' : undefined, width: `${progress > 0 && secondaryProgress > 0 ? secondaryProgress + 2 : secondaryProgress}%` }} />}
    </div>
  )
}
