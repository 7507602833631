import { Tabs as MuiTabs, Tab as MuiTab } from '@material-ui/core'

interface TabsProps {
  value: string
  setValue: (val: string | any) => void
  variant?: 'standard' | 'scrollable' | 'fullWidth'
  tabs: string[]
  noIndicator?: boolean
}

export function Tabs (props: TabsProps) {
  const selectedTab = props.tabs.indexOf(props.value) === -1 ? 0 : props.tabs.indexOf(props.value)

  return (
    <MuiTabs
      variant={props.variant || 'fullWidth'}
      onChange={(e, val) => props.setValue(props.tabs[val])}
      value={selectedTab}
      indicatorColor='primary'
      textColor='primary'
      TabIndicatorProps={{
        style: { display: props.noIndicator ? 'none' : 'auto' }
      }}
    >
      {props.tabs.map((tab, index) => (
        <MuiTab label={tab} key={index} style={{ minWidth: 0 }} />
      ))}
    </MuiTabs>
  )
}
