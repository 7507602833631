import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import BlockIcon from '@material-ui/icons/Block'
import StarBorderIcon from '@material-ui/icons/StarBorder'
import StarIcon from '@material-ui/icons/Star'
import Tooltip from '@material-ui/core/Tooltip'

const useStyles = makeStyles(theme => ({
  leadScoring: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    color: '#7A7A7A'
  },
  cancelIcon: {
    height: '.6em',
    width: '.6em',
    cursor: 'pointer',
    '&:hover': {
      color: '#F0706F'
    }
  },
  disqualified: {
    height: '.6em',
    width: '.6em',
    cursor: 'pointer',
    fill: '#F0706F'
  },
  setStarIcon: {
    height: '.75em',
    width: '.75em',
    cursor: 'pointer',
    fill: 'gold'
  },
  setStarBorderIcon: {
    height: '.75em',
    width: '.75em',
    cursor: 'pointer'
  },
  rating: {
    display: 'flex',
    alignItems: 'center'
  },
  starIcon: {
    fill: 'gold'
  },
  score: props => ({
    gridArea: 'score',
    display: 'flex',
    justifyContent: 'end',
    color: props.fill
  })
}))

function Rating (props) {
  const classes = useStyles()
  const [hover, setHover] = useState(0)
  const score = props.score

  return (
    <div className={classes.rating} onMouseLeave={() => setHover(0)}>
      {
        [[1, 2, 3].map(stars => {
          const max = hover || score
          if (stars <= max) {
            return (
              <StarIcon
                className={classes.setStarIcon}
                key={stars}
                onClick={() => props.handleChange(stars)}
                onMouseEnter={() => setHover(stars)}
              />
            )
          } else {
            return (
              <StarBorderIcon
                className={classes.setStarBorderIcon}
                key={stars}
                onClick={() => props.handleChange(stars)}
                onMouseEnter={() => setHover(stars)}
              />
            )
          }
        })]
      }
    </div>
  )
}

function _parseScore (score) {
  // If score is null or undefined, it means that the state is unset (0 stars and qualified status)
  // If score is 0, it is unqualified and with 0 stars.
  let parsedScore = null

  parsedScore = parseInt(score)
  if (isNaN(parsedScore)) {
    parsedScore = null
  }

  return parsedScore
}

export function LeadScore (props) {
  const classes = useStyles(props)
  const height = props.size === 'lg' ? '.8em' : '.65em'

  const styles = {
    height: height,
    width: height
  }

  const score = _parseScore(props.score)

  if (score === null) {
    return <></>
  }

  if (score === 0) {
    return (
      <div className={classes.score}>
        <BlockIcon className={classes.disqualified} />
      </div>
    )
  }
  return (
    <div className={classes.score}>
      {[...Array(score)].map((e, i) => <StarIcon className={classes.starIcon} style={styles} key={i} />)}
      {[...Array(3 - score)].map((e, i) => <StarBorderIcon style={styles} key={3 - score + i} />)}
    </div>
  )
}

export function SetLeadScore (props) {
  const classes = useStyles()
  const score = _parseScore(props.score)

  const dqClass = score === 0 ? classes.disqualified : classes.cancelIcon

  return (
    <div className={classes.leadScoring}>
      <Tooltip title='Unqualified'>
        <BlockIcon className={dqClass} onClick={() => props.handleChange(0)} />
      </Tooltip> |
      <Rating score={score} handleChange={props.handleChange} />
    </div>
  )
}
