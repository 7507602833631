import { TextBox } from 'library/materialUI';
import { Modal, ModalSection } from 'library/Modal';
import { useQueryClient } from 'react-query'
import { useContext, useReducer } from 'react';
import Form, { formReducer } from 'classes/forms';
import { SessionContext } from 'session-context'
import { ChatSettings } from 'classes/chatSettings';

const initialState = {
  name: '',
  description: '',
  buttonText: 'SUBMIT',
  buttonPlacement: 'right'
}

export function NewFormModal (props: any) {
  const [state, dispatch] = useReducer(formReducer, initialState)
  const { data: chatSettings } = ChatSettings.loadOne()
  const { user } = useContext(SessionContext)
  const queryClient = useQueryClient()
  const history = props.history
  const newTab = props.newTab

  const saveForm = () => {
    const form = { ...state, createdByUserID: user.id }
    // Set some defaults
    form.chatRoutingSettings = [{ method: 'single_agent', objectIDs: [], type: '' }]
    form.chatMeetingRoutingSettings = [{ method: 'single_agent', objectIDs: [], type: '' }]
    form.meetingRoutingSettings = [{ method: 'single_agent', objectIDs: [], type: '' }]
    form.offlineMeetingRoutingSettings = [{ method: 'single_agent', objectIDs: [], type: '' }]
    form.chatRoutedMessage = 'Connecting you to someone momentarily.'
    form.chatTimeoutSeconds = 30
    form.chatTimeoutMessage = 'Sorry, it looks like no one’s available to chat right now. Would you like to book a meeting instead?'
    form.buttonSameAsPrimary = true
    form.buttonColor = '#' + chatSettings.buttonColor
    form.liveChatButtonText = 'Chat Now'
    form.bookMeetingButtonText = 'Book a Meeting'
    form.offlineMeetingButtonText = 'Book a Meeting'
    form.offlineHeader = 'Book a demo now?'
    form.offlineMessage = 'Would you like to book a time to speak with a member of our team? We’d love to show you what our products can do!'
    Form.save({ data: form })
      .then((resp: any) => {
        queryClient.setQueryData(['forms', resp.data.id], resp)
        if (newTab) {
          window.open(`/#/forms/${resp.data.id}`)
          props.setOpen(false)
        } else {
          history.push(`/forms/${resp.data.id}`)
        }
      })
  }

  return (
    <Modal
      open={props.open}
      title='New Form'
      onHide={props.onHide}
      handleSave={saveForm}
      saveDisabled={!state.name}
      saveIcon='save'
    >
      <ModalSection title='Name'>
        <TextBox
          value={state.name}
          onChange={(value: string) => dispatch({ type: 'name', value: value })}
        />
      </ModalSection>
      <ModalSection title='Description'>
        <TextBox
          rows={2}
          value={state.description}
          onChange={(value: string) => dispatch({ type: 'description', value: value })}
        />
      </ModalSection>
    </Modal>
  )
}
