const eventGroups = {
  messages: 'messages',
  routing: 'meta',
  tagging: 'meta',
  status: 'meta',
  leadScore: 'meta',
  calendar: 'calendar',
  cta: 'cta',
  create_contact: 'create_contact',
  ratingComplete: 'meta',
  quickDraw: 'meta',
  formSubmission: 'meta'
}

const eventTypes = {
  chat: 'messages',
  option_prompt: 'messages',
  join: 'routing',
  part: 'routing',
  add: 'routing',
  alert: 'routing',
  tag: 'tagging',
  untag: 'tagging',
  close: 'status',
  open: 'status',
  calendar_drop: 'calendar',
  cta_button: 'cta',
  button_push: 'cta',
  create_contact: 'create_contact',
  rating_complete: 'messages',
  lead_score: 'leadScore',
  quick_draw: 'quickDraw',
  form_submission: 'formSubmission'
}

const metaEvents = [
  'bot_start',
  'bot_end',
  'bot_trigger',
  'capture',
  'email_prompt',
  // 'option_prompt',
  'read',
  'type',
  'halt'
]

const isNewDate = ({ lastMeta, event }) => {
  const lastTimestamp = lastMeta.createdTimestamp
  const currentTimestamp = event.attributes.created_timestamp
  if (!lastTimestamp) return true

  const lastDate = new Date(lastTimestamp)
  const currentDate = new Date(currentTimestamp)
  return (currentDate.toDateString() !== lastDate.toDateString())
}

const newGroupDueToTime = (currentTimestamp, lastTimestamp) => {
  let timeDiff = 0

  if (lastTimestamp) {
    timeDiff = new Date(currentTimestamp) - new Date(lastTimestamp)
    timeDiff = timeDiff / 1000 / 60
  }

  return timeDiff > 15
}

const getEventGroupings = (event) => {
  const kind = event.attributes.kind
  const eventType = eventTypes[kind]
  const eventGroup = eventGroups[eventType]
  return { eventType, eventGroup }
}

const isNewGroup = ({ event, lastMeta }) => {
  const participantID = event.relationships.from_participant.data.id
  const currentTimestamp = event.attributes.created_timestamp
  const { eventGroup } = getEventGroupings(event)

  const lastParticipantID = lastMeta.participantID
  const lastEventGroup = lastMeta.eventGroup
  const lastTimestamp = lastMeta.createdTimestamp
  lastMeta.participantID = participantID
  lastMeta.createdTimestamp = currentTimestamp
  lastMeta.eventGroup = eventGroup

  if (participantID !== lastParticipantID) {
    return true
  } else if (newGroupDueToTime(currentTimestamp, lastTimestamp)) {
    return true
  } else if (eventGroup !== lastEventGroup) {
    return true
  } else if (eventGroup === 'meta') {
    return true
  }

  return false
}

const createNewGroup = (event) => {
  const { eventType, eventGroup } = getEventGroupings(event)
  return {
    type: 'events',
    events: {
      [eventType]: [event]
    },
    eventGroup
  }
}

const addEventToGroup = (event, group) => {
  const { eventType } = getEventGroupings(event)
  if (group.events[eventType]) {
    group.events[eventType].push(event)
  } else {
    group.events[eventType] = [event]
  }
}

const isTyping = (typing, participant) => {
  const now = new Date()
  const typingEvents = typing.filter(t =>
    t.participant_id !== participant.id && now - new Date(t.created_timestamp) < 2 * 60 * 1000)
  return typingEvents
}

const groupEvents = ({ events }) => {
  const displayEvents = events.filter(e => !metaEvents.includes(e.attributes.kind))
  const groups = []
  if (!displayEvents.length) return ({ groups, displayEvents })
  let currentGroup

  const lastMeta = {
    participantID: null,
    eventGroup: null,
    createdTimestamp: null
  }

  for (let i = 0; i < displayEvents.length; i++) {
    const event = displayEvents[i]
    const newDate = isNewDate({ lastMeta, event })
    const newGroup = isNewGroup({ event, lastMeta })
    if (newGroup) {
      if (currentGroup) groups.push(currentGroup)
      currentGroup = createNewGroup(event)
      if (newDate) {
        groups.push({
          type: 'dateDivider',
          date: event.attributes.created_timestamp
        })
      }
    } else {
      addEventToGroup(event, currentGroup)
    }
  }
  groups.push(currentGroup)

  return ({ groups, displayEvents })
}

export { groupEvents, isTyping }
