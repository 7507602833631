import { useState, useRef, useEffect } from 'react'
import { BuildAvatarSvgs, BuildPromptIconSvgs } from './svgs/BuildSvgs'
import { Modal } from 'library/Modal'
import { makeStyles } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'
import Badge from '@material-ui/core/Badge'
import ColorPicker from './ColorPicker'
import EditIcon from '@material-ui/icons/Edit'
import PhotoDropzone from './PhotoDropZone'

const useStyles = makeStyles(() => ({
  saveButton: {
    textAlign: 'right'
  },
  avatarIcons: {
    display: 'grid',
    margin: '16px 0px',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridGap: '30px 10px',
    justifyItems: 'center'
  },
  avatarClick: {
    cursor: 'pointer',
    height: 60,
    width: 60,
    margin: 0,
    borderRadius: '1000px'
  },
  avatar: {
    height: 60,
    width: 60,
    margin: 0,
    objectFit: 'cover',
    borderRadius: '1000px',
    pointerEvents: 'none'
  },
  editorModalContent: {
    display: 'flex',
    margin: 'auto',
    width: '100%',
    textAlign: 'center',
    verticalAlign: 'center'
  },
  avatarPreview: {
    margin: 'auto',
    marginBottom: '10px',
    height: 220,
    width: 220
  }
}))

export function BotIconSelector ({ state, dispatch, uploadNewPhoto }) {
  const classes = useStyles()
  const [makeSvg, svgAmount] = BuildAvatarSvgs()
  const [customPhoto, setCustomPhoto] = useState({
    file: state?.attributes?.bot_icon_url_preview,
    editedFile: null,
    preview: state?.attributes?.bot_icon_url_preview
  })
  const customPhotoEditor = useRef(null)
  const [editorOpen, setEditorOpen] = useState(false)
  const [editorPrimaryColor, setEditorPrimaryColor] = useState(state.attributes.bot_icon_primary_color)
  const [editorAccentColor, setEditorAccentColor] = useState(state.attributes.bot_icon_accent_color)

  const savePhoto = (iconIndex) => {
    iconIndex = iconIndex || state.attributes.bot_icon_id
    if (iconIndex === 0) return
    const contentType = (iconIndex === -1) ? 'image/png' : 'image/svg+xml'
    let image = null
    if (contentType === 'image/svg+xml') {
      image = makeSvg({ index: iconIndex, primaryColor: '#' + editorPrimaryColor, secondaryColor: '#' + editorAccentColor })
    } else {
      image = customPhoto?.editedFile ? customPhoto.editedFile : customPhoto.file
    }
    uploadNewPhoto({ image, contentType })
  }

  const avatarRows = []
  for (let i = 0; i < svgAmount; i++) {
    const selected = state.attributes.bot_icon_id === i + 1

    const icon = (
      <div
        onClick={() => {
          dispatch({ type: 'updateSetting', name: 'bot_icon_id', value: i + 1 })
          dispatch({
            type: 'updateSetting',
            name: 'bot_icon_url',
            url: 'data:image/svg+xml;base64,' + window.btoa(makeSvg({ index: i + 1, primaryColor: '#' + state.attributes.bot_icon_primary_color, secondaryColor: '#' + state.attributes.bot_icon_accent_color }))
          })
          savePhoto(i + 1)
        }}
        className={classes.avatarClick}
      >
        <Avatar
          src={'data:image/svg+xml;base64,' + window.btoa(makeSvg({ index: i + 1, primaryColor: '#' + state.attributes.bot_icon_primary_color, secondaryColor: '#' + state.attributes.bot_icon_accent_color }))}
          className={classes.avatar}
          style={{ boxShadow: (selected ? '0px 3px 3px rgba(0,0,0,0.2), 0px 1px 15px -3px rgba(0,0,0,0.4), 0px 0px 0px 4px #F4F2F7, 0px 0px 0px 8px #9933FF' : '0px 3px 3px rgba(0,0,0,0.2), 0px 1px 15px -3px rgba(0,0,0,0.4)'), background: '#' + state.attributes.primary_color }}
        />
      </div>
    )

    const avatar = selected ? (
      <Badge
        color='primary'
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        onClick={() => setEditorOpen(true)}
        badgeContent={
          <EditIcon style={{ cursor: 'pointer', fontSize: 12, color: 'white' }} />
        }
      >
        {icon}
      </Badge>
    ) : (
      icon
    )
    avatarRows.push(
      avatar
    )
  }

  const isFirstRender = useRef(true)
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }
    if (state.attributes.bot_icon_id === -1) {
      savePhoto(-1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customPhoto])

  return (
    <div style={{ width: '100%' }}>
      <div style={{ fontWeight: 600, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        Use a Default Icon
      </div>
      <div className={classes.avatarIcons}>
        {avatarRows}
      </div>
      <div style={{ fontWeight: 600 }}>
        Or upload a custom icon
      </div>
      <PhotoDropzone
        setIconIndex={(value) => {
          dispatch({ type: 'updateSetting', name: 'bot_icon_id', value: value })
          savePhoto(-1)
        }}
        iconIndex={state.attributes.bot_icon_id}
        setPhoto={setCustomPhoto}
        photo={customPhoto}
        editor={customPhotoEditor}
        setDefaultIcon={() => {
          dispatch({ type: 'updateSetting', name: 'bot_icon_id', value: 1 })
          savePhoto(1)
        }}
        backgroundColor={'#' + state.attributes.primary_color}
      />
      <Modal
        open={editorOpen}
        size='xs'
        title='Customize Bot Icon'
        saveBtnText='Save'
        onHide={() => {
          setEditorPrimaryColor(state.attributes.bot_icon_primary_color)
          setEditorAccentColor(state.attributes.bot_icon_accent_color)
          setEditorOpen(false)
        }}
        handleSave={() => {
          setEditorOpen(false)
          dispatch({
            type: 'updateSetting',
            name: 'bot_icon_url',
            url: 'data:image/svg+xml;base64,' + window.btoa(makeSvg({ index: state.attributes.bot_icon_id, primaryColor: '#' + editorPrimaryColor, secondaryColor: '#' + editorAccentColor }))
          })
          dispatch({ type: 'updateSetting', name: 'bot_icon_primary_color', value: editorPrimaryColor })
          dispatch({ type: 'updateSetting', name: 'bot_icon_accent_color', value: editorAccentColor })
          savePhoto()
        }}
      >
        <div className={classes.photoEditorContent}>
          <Avatar
            src={'data:image/svg+xml;base64,' + window.btoa(makeSvg({ index: state.attributes.bot_icon_id, primaryColor: '#' + editorPrimaryColor, secondaryColor: '#' + editorAccentColor }))}
            className={classes.avatarPreview}
            style={{ boxShadow: '0px 3px 3px rgba(0,0,0,0.2), 0px 1px 15px -3px rgba(0,0,0,0.4)', background: state.attributes.primary_color }}
          />
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '15px' }}>
            <>
              <ColorPicker title='Primary Color' color={editorPrimaryColor} setColor={setEditorPrimaryColor} />
              <ColorPicker title='Accent Color' color={editorAccentColor} setColor={setEditorAccentColor} />
            </>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export function PromptIconSelector ({ state, dispatch, uploadNewPhoto }) {
  const classes = useStyles()
  const [makeSvg, svgAmount] = BuildPromptIconSvgs()
  const [customPhoto, setCustomPhoto] = useState({
    file: state?.attributes?.prompt_icon_url_preview,
    editedFile: null,
    preview: state?.attributes?.prompt_icon_url_preview
  })
  const customPhotoEditor = useRef(null)
  const primaryColor = '#' + state.attributes.primary_color
  const contrastColor = '#' + state.attributes.contrast_color

  const savePhoto = (iconIndex) => {
    iconIndex = iconIndex || state.attributes.prompt_icon_id
    if (iconIndex === 0) return
    let contentType = (iconIndex === -1) ? 'image/png' : 'image/svg+xml'
    let image = null
    if (contentType === 'image/svg+xml') {
      image = makeSvg({ index: iconIndex, primaryColor: contrastColor, secondaryColor: contrastColor })
    } else {
      if (customPhoto?.editedFile) {
        image = customPhoto.editedFile
      } else {
        contentType = 'image/svg+xml'
        image = makeSvg({ index: 1, primaryColor: contrastColor, secondaryColor: contrastColor })
      }
    }
    uploadNewPhoto({ image, contentType })
  }

  const avatarRows = []
  for (let i = 0; i < svgAmount; i++) {
    const selected = state.attributes.prompt_icon_id === i + 1
    avatarRows.push(
      <div
        onClick={() => {
          savePhoto(i + 1)
          dispatch({ type: 'updateSetting', name: 'prompt_icon_id', value: i + 1 })
          dispatch({
            type: 'updateSetting',
            name: 'prompt_icon_url',
            url: 'data:image/svg+xml;base64,' + window.btoa(makeSvg({ index: i + 1, primaryColor: contrastColor, secondaryColor: contrastColor }))
          })
        }}
        className={classes.avatarClick}
      >
        <Avatar
          src={'data:image/svg+xml;base64,' + window.btoa(makeSvg({ index: i + 1, primaryColor: contrastColor, secondaryColor: contrastColor }))}
          className={classes.avatar}
          style={{ boxShadow: (selected ? '0px 3px 3px rgba(0,0,0,0.2), 0px 1px 15px -3px rgba(0,0,0,0.4), 0px 0px 0px 4px #F4F2F7, 0px 0px 0px 8px #9933FF' : '0px 3px 3px rgba(0,0,0,0.2), 0px 1px 15px -3px rgba(0,0,0,0.4)'), background: primaryColor }}
        />
      </div>
    )
  }

  const isFirstRender = useRef(true)
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }
    savePhoto(-1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customPhoto])

  return (
    <div style={{ width: '100%' }}>
      <div style={{ fontWeight: 600, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        Use a Default Icon
      </div>
      <div className={classes.avatarIcons}>
        {avatarRows}
      </div>
      <div style={{ fontWeight: 600 }}>
        Or upload a custom icon
      </div>
      <PhotoDropzone
        setIconIndex={(value) => {
          dispatch({ type: 'updateSetting', name: 'prompt_icon_id', value: value })
          savePhoto(-1)
        }}
        iconIndex={state.attributes.prompt_icon_id}
        setPhoto={setCustomPhoto}
        photo={customPhoto}
        editor={customPhotoEditor}
        setDefaultIcon={() => {
          dispatch({ type: 'updateSetting', name: 'prompt_icon_id', value: 1 })
          dispatch({
            type: 'updateSetting',
            name: 'prompt_icon_url',
            url: 'data:image/svg+xml;base64,' + window.btoa(makeSvg({ index: 1, primaryColor: state.attributes.contrast_color, secondaryColor: state.attributes.contrast_color }))
          })
        }}
        backgroundColor={'#' + state.attributes.primary_color}
      />
    </div>
  )
}
