import React from 'react'
import ConversationStarted from '../img/conversation-started.svg'
import ContactCreated from '../img/contact-created.svg'
import PageViewed from '../img/page-viewed.svg'
import ActivityDisplay from './ActivityDisplay'
import NoteOutlinedIcon from '@material-ui/icons/NoteOutlined'
import DateRangeIcon from '@material-ui/icons/DateRange'
import { ReactComponent as FormSubmitted } from '../img/form_submitted.svg'
import { SourceInfo } from 'pages/people/ContactPage/ContactSummary'

function TrackingEvent (props) {
  const event = props.event
  const type = event.attributes.kind
  const timestamp = event.attributes.created_timestamp
  let title = ''
  let imgSrc = ''
  let color = ''
  let icon = ''
  let source = ''
  if (type === 'page') {
    title = 'Viewed ' + event.attributes.title
    imgSrc = PageViewed
    color = '#39AFFF'
    source = event.attributes.referrer ? <SourceInfo source={event.attributes} /> : null
  } else if (type === 'conversation_started') {
    title = 'Conversation Started'
    imgSrc = ConversationStarted
    color = '#8E5AE2'
  } else if (type === 'contact_created') {
    title = 'Contact Created'
    imgSrc = ContactCreated
    color = '#61CBBC'
  } else if (type === 'note') {
    title = 'Note Added'
    icon = NoteOutlinedIcon
    color = '#FFB449'
  } else if (type === 'meeting_booked') {
    title = 'Meeting Booked'
    icon = DateRangeIcon
    color = '#FFB449'
  } else if (type === 'form_submission') {
    title = event.attributes.title + ' Form Submitted'
    icon = FormSubmitted
    color = '#63AFCD'
  } else {
    return <></>
  }

  if (props.contact) {
    let name = props.contact.attributes.first_name + ' ' + props.contact.attributes.last_name
    if (name === ' ') {
      if (props.contact.attributes.email) {
        name = props.contact.attributes.email
      } else {
        name = 'Site Visitor'
      }
    }
    title = name + ' - ' + title
  }

  if (props.by) {
    title = title + ' by ' + props.by
  }

  return (
    <ActivityDisplay
      title={title}
      imgSrc={imgSrc}
      icon={icon}
      color={color}
      source={source}
      timestamp={timestamp}
    />
  )
}

export default TrackingEvent
