import { useState, useEffect } from 'react'
import { makeStyles, ThemeProvider } from '@material-ui/core/styles'
import useNodeStyles from './NodeStyles'
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic'
import OptionPort from '../OptionPort'
import { jsPlumbUtil } from 'jsplumbtoolkit'
import { Select, MenuItem, Divider, Typography, FormControlLabel, Checkbox, Button, Tooltip } from '@material-ui/core'
import '../quill.css'
import { RichTextEditor } from 'library/richText/RichTextEditor'
import { EVENT_TYPE, Emitter } from 'emitter'
import { RoutingSelector } from 'library/routing/RoutingCriteria'
import { Modal, ModalSection } from 'library/Modal'
import { ContentRadioGroup, ContentRadio } from 'library/ContentRadioGroup'
import Picker from 'cf-components/Pickers'
import ContentCheckbox from 'library/ContentCheckbox'
import AttributeSelect from '../AttributeSelect'
import AddIcon from '@material-ui/icons/Add'
import { Icon } from 'library/materialUI'
import { createCustomField } from 'api/custom_fields'
import { useQueryClient } from 'react-query'
import CustomFieldsModal from 'components/CustomFieldsModal'
import theme from 'theme'

const useStyles = makeStyles(theme => ({
  sectionHeader: {
    fontSize: '15px',
    fontWeight: 600,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  form: {
    fontFamily: 'Poppins, sans serif'
  },
  divider: {
    marginTop: 5,
    marginBottom: 10
  },
  routeOptionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '14px',
    marginBottom: 7,
    fontWeight: 600
  },
  routeTypeDiv: {
    display: 'grid',
    gridTemplateColumns: '3fr 2fr',
    gridGap: 15
  },
  routeToDiv: {
    marginTop: 10
  },
  plusIcon: {
    marginLeft: '15px',
    cursor: 'pointer'
  },
  contentClass: {
    padding: 20,
    height: 360,
    overflowY: 'auto'
  }
}))

const delayOptions = [
  { value: '', label: 'How long should the bot wait for an agent before continuing?', disabled: true },
  { value: 30, label: '30 seconds' },
  { value: 60, label: '1 minute' },
  { value: 90, label: '90 seconds' },
  { value: 120, label: '2 minutes' },
  { value: 300, label: '5 minutes' },
  { value: 600, label: '10 minutes' }
]

function LiveChatModal (props) {
  const classes = useStyles()
  const queryClient = useQueryClient()
  const [routeByAccountOwnerPermission, setRouteByAccountOwnerPermission] = useState(false)
  const [fallbackDelay, setFallbackDelay] = useState(props.fallbackDelay)
  const [routeOptions, setRouteOptions] = useState(props.routeOptions || [{ method: 'single_agent', type: 'round_robin', objectIDs: [] }])
  const [routeMessage, setRouteMessage] = useState(props.routeMessage)
  const [timeoutMessage, setTimeoutMessage] = useState(props.timeoutMessage)
  const [timeoutOption, setTimeoutOption] = useState(props.timeoutOption || 'book_meeting')
  const [meetingType, setMeetingType] = useState(props.meetingType)
  const [meetingRouteOptions, setMeetingRouteOptions] = useState(props.meetingRouteOptions || [{ method: 'single_agent', type: 'round_robin', objectIDs: [] }])
  const [textMessageReminders, setTextMessageReminders] = useState(props.textMessageReminders)
  const [followUpEmail, setFollowUpEmail] = useState(props.followUpEmail)
  const [followUpEmailID, setFollowUpEmailID] = useState(props.followUpEmailID)
  const [mapComments, setMapComments] = useState(props.mapComments)
  const [contactAttribute, setContactAttribute] = useState(props.contactAttribute)
  // const completeState = props?.completeState
  const [newFieldModalOpen, setNewFieldModalOpen] = useState(false)

  function cancelChanges () {
    props.onHide()
    setRouteOptions(props.routeOptions || [{ method: 'single_agent', type: 'round_robin', objectIDs: [] }])
    setFallbackDelay(props.fallbackDelay)
    setRouteMessage(props.routeMessage)
    setTimeoutMessage(props.timeoutMessage)
    setMeetingRouteOptions(props.meetingRouteOptions || [{ method: 'single_agent', type: 'round_robin', objectIDs: [] }])
  }

  function save () {
    props.save({
      routeOptions,
      routeMessage,
      fallbackDelay,
      timeoutMessage,
      timeoutOption,
      meetingType,
      meetingRouteOptions,
      textMessageReminders,
      followUpEmail,
      followUpEmailID,
      mapComments,
      contactAttribute
    })
  }

  function editRouteMessage (value, delta, source, editor) {
    setRouteMessage(value)
  }

  useEffect(() => {
    if (props.open) {
      fetch('/api/auth/me', {
        method: 'GET',
        headers: { 'Content-Type': 'application/vnd.api+json' }
      })
        .then(response => response.json())
        .then(response => {
          if (response.data && response.data.id) {
            if (response.data.attributes.perms.tenant_route_by_account_owner) {
              setRouteByAccountOwnerPermission(true)
            }
          }
        })
    }
  }, [props.open])

  if (!props.open) {
    return <></>
  }

  Emitter.on(EVENT_TYPE.BROWSER_BACK, cancelChanges)

  return (
    <ThemeProvider theme={theme}>
      <Modal
        open={props.open}
        onHide={cancelChanges}
        handleSave={save}
        title='Live Chat'
        helplink='home/bots/nodes/LiveChat'
        helplinkLabel='Learn more about this skill'
        saveIcon='save'
      >
        <div className={classes.form}>
          <RoutingSelector
            routeOptions={routeOptions}
            handleChange={setRouteOptions}
            permissions={{ routeByAccountOwnerPermission }}
            routeTypeOptions
            borderColor={routeOptions.length && routeOptions[0].objectIDs?.length ? undefined : '#EA422A'}
          />
          <ModalSection title='Agent is Routed' subtitle='Enter a message to be sent when an agent is routed to the chat' titleType='h6' borderColor={routeMessage && routeMessage !== '<p><br></p>' ? undefined : '#EA422A'}>
            <div style={{ height: 105 }}>
              <div className='customQuill'>
                <RichTextEditor
                  message={routeMessage}
                  onChange={editRouteMessage}
                  placeholder='What should the bot tell the visitor when it finds an available agent?'
                  lines={2}
                />
              </div>
            </div>
          </ModalSection>
          <ModalSection title='Agent Fails to Respond' subtitle='How long do you want to give your agents to respond before the bot moves on?' titleType='h6' borderColor={(!fallbackDelay) ? '#EA422A' : undefined}>
            <Select
              margin='dense'
              value={fallbackDelay}
              fullWidth
              variant='outlined'
              onChange={(e) => setFallbackDelay(e.target.value)}
              style={{ marginBottom: 10 }}
            >
              {delayOptions.map((option, index) => (
                <MenuItem
                  value={option.value}
                  disabled={option.disabled}
                  key={index}
                >
                  {option.label}
                </MenuItem>
              ))}
            </Select>
            {props.showTimeoutMessage && (
              <>
                <Typography variant='body2' style={{ marginBottom: 10 }}>Enter a message to be sent if an agent doesn't respond in time</Typography>
                <div style={{ height: 105 }}>
                  <div className='customQuill'>
                    <RichTextEditor
                      message={timeoutMessage}
                      onChange={setTimeoutMessage}
                      placeholder='What should the bot tell the visitor when no agents are available?'
                      lines={2}
                    />
                  </div>
                </div>
              </>
            )}
            {props.showTimeoutNextStep && (
              <>
                <Typography variant='body2' style={{ marginBottom: 10 }}>Which option should be presented with the above message?</Typography>
                <ContentRadioGroup
                  value={timeoutOption}
                  onChange={(value) => {
                    setTimeoutOption(value)
                  }}
                >
                  <ContentRadio
                    label='Let the visitor book a meeting instead'
                    value='book_meeting'
                    checked={timeoutOption === 'book_meeting'}
                  >
                    <Typography variant='h6'>Meeting Type</Typography>
                    <Typography variant='body2'>Which type of meeting should be scheduled?</Typography>
                    <div style={{ marginBottom: 15 }}>
                      <Picker
                        selection={meetingType}
                        setSelection={setMeetingType}
                        objectType='meetingTypes'
                      />
                    </div>
                    <RoutingSelector
                      routeOptions={meetingRouteOptions}
                      handleChange={setMeetingRouteOptions}
                      subtitle='Which agent(s) should this meeting be scheduled for? Only agents with calendars will appear in the list.'
                      permissions={{ routeByAccountOwnerPermission }}
                    />
                    <Typography variant='h6'>Follow-up Settings</Typography>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={textMessageReminders}
                          onChange={(e) => setTextMessageReminders(e.target.checked)}
                          name='autoToggle'
                          color='primary'
                        />
                      }
                      style={{ marginTop: 10, fontSize: 12 }}
                      label='Prompt visitor for text message reminders.'
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={!!followUpEmail}
                          onChange={(e) => setFollowUpEmail(e.target.checked)}
                          name='autoToggle'
                          color='primary'
                        />
                      }
                      style={{ marginTop: -5, fontSize: 12 }}
                      label='If the visitor doesn’t book a meeting, send a follow-up email.'
                    />
                    {followUpEmail && (
                      <div style={({ marginBottom: 10 })}>
                        <Picker
                          selection={followUpEmailID}
                          setSelection={setFollowUpEmailID}
                          fullWidth
                          objectType='emails'
                          margin='dense'
                        />
                        <Button
                          style={{ fontSize: 12, color: 'gray', marginTop: 20, borderRadius: 50 }}
                          variant='outlined'
                          size='small'
                          onClick={() => window.open('/#/emails/new')}
                          endIcon={<AddIcon color='primary' style={{ fill: '#975ce6' }} />}
                        >
                          Create a new email
                        </Button>
                      </div>
                    )}
                  </ContentRadio>
                  <ContentRadio
                    label='Let the visitor leave additional comments'
                    value='leave_comments'
                    checked={timeoutOption === 'leave_comments'}
                  >
                    <ContentCheckbox
                      label='Map comments to a contact attribute?'
                      checked={mapComments}
                      onChange={(checked) => setMapComments(checked)}
                      noBorder
                    >
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <AttributeSelect
                          type='people'
                          value={contactAttribute}
                          setAttribute={(value) => setContactAttribute(value.attribute)}
                        />
                        <Tooltip title='Create new'>
                          <div
                            className={classes.plusIcon}
                            onClick={() => {
                              setNewFieldModalOpen(true)
                            }}
                          >
                            <Icon icon='addBox' size='md' />
                          </div>
                        </Tooltip>
                      </div>
                    </ContentCheckbox>
                  </ContentRadio>
                </ContentRadioGroup>
              </>
            )}
          </ModalSection>
        </div>
        {newFieldModalOpen && (
          <div>
            <CustomFieldsModal
              open={newFieldModalOpen}
              onHide={() => setNewFieldModalOpen(false)}
              save={(values) => {
                createCustomField({ values, forObject: 'people' }).then(_ => {
                  queryClient.invalidateQueries('custom_fields')
                })
              }}
            />
          </div>
        )}
      </Modal>
    </ThemeProvider>
  )
}

function LiveChatNode (props) {
  const base = props.base
  const classes = useNodeStyles(props)

  const assignKey = data => {
    data._reactKey = data._reactKey || jsPlumbUtil.uuid()
    return data._reactKey
  }

  const saveChanges = ({ routeOptions, routeMessage, fallbackDelay }) => {
    if (!base.toolkit) {
      return
    }
    const node = base.getNode()
    const newData = {
      ...base.state,
      routeOptions: routeOptions,
      routeMessage: routeMessage,
      fallbackDelay: fallbackDelay
    }

    base.toolkit.updateNode(node, newData)
    props.setModalOpen(false)
  }

  return (
    <>
      <div>
        <div
          className={`${classes.dragger} ${classes.agentConnection}`}
        >
          <div className='node-header'>
            <div className={classes.iconDiv}><HeadsetMicIcon /></div>
            <div className={classes.cardTitle}>Live Chat</div>
          </div>
          <div className='node-body'>
            <div>Route to live chat</div>
            <Divider style={{ marginTop: 5 }} />
            <div>
              {base.state.ports.map((c, index) => {
                return (
                  <OptionPort
                    index={index}
                    data={c}
                    key={assignKey(c)}
                    parent={base}
                    kind='agentConnection'
                  />
                )
              })}
            </div>
          </div>
          <jtk-target
            scope='default'
          />
        </div>
      </div>
      <LiveChatModal
        open={props.modalOpen}
        onHide={() => props.setModalOpen(false)}
        save={saveChanges}
        fallbackDelay={base.state.fallbackDelay}
        routeOptions={base.state.routeOptions}
        routeMessage={base.state.routeMessage}
      />
    </>
  )
}

export { LiveChatNode, LiveChatModal }
