import React from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { CardHeader, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Divider from '@material-ui/core/Divider'
import {
  SurveyDropdown,
  SurveyHorizontalRadio
} from '../components/SurveyComponents.jsx'
import { Formik, Form, FastField, ErrorMessage } from 'formik'
import * as Yup from 'yup'

const useStyles = makeStyles(theme => ({
  card: {
    width: 1000
  },
  scrollableDiv: {
    overflow: 'auto',
    height: 600
  },
  submit: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: theme.spacing(3),
    paddingTop: 10
  },
  invalidInput: {
    color: 'red',
    marginLeft: 40,
    fontSize: 12
  }
}))

const role_values = [
  'Marketer',
  'Business Development Rep',
  'Account Manager (Existing Accounts)',
  'Sales Rep (New Business)',
  'Customer Support'
]

const role_experience_values = [
  'Less than 1 year',
  '1-3 years',
  '3-5 years',
  '5-10 years',
  '10-20 years',
  'Over 20 years'
]

const gender_values = ['Female', 'Male', 'Prefer not to say']

const age_values = ['Under 18', '18-25', '26-34', '35-45', '46-55', '55+']

const education_values = [
  'Some High School',
  'High School',
  'Some College',
  "Bachelor's Degree",
  'Masters Degree',
  'Doctorate Degree'
]

const location_values = ['United States', 'Canada', 'UK', 'Other']

const employment_tenure_values = [
  'Less than 1 year',
  '1-3 years',
  '3-5 years',
  '5-10 years',
  'over 10 years'
]

const radio_values = ['1', '2', '3', '4', '5']

const starting_values = {
  role: '',
  role_experience: '',
  gender: '',
  age: '',
  education: '',
  location: '',
  employment_tenure: '',
  chat_experience: '',
  chat_usefulness: '',
  auto_response_trust: ''
}

const default_error_message = 'This is a required field'

const validationSchema = Yup.object().shape({
  role: Yup.string().required(default_error_message),
  role_experience: Yup.string().required(default_error_message),
  gender: Yup.string().required(default_error_message),
  age: Yup.string().required(default_error_message),
  education: Yup.string().required(default_error_message),
  location: Yup.string().required(default_error_message),
  employment_tenure: Yup.string().required(default_error_message),
  chat_experience: Yup.string().required(default_error_message),
  chat_usefulness: Yup.string().required(default_error_message),
  auto_response_trust: Yup.string().required(default_error_message)
})

function IntakeSurvey (props) {
  const classes = useStyles()

  return (
    <Card className={classes.card}>
      <CardHeader title='Registration Survey' />
      <CardContent>
        <p>
          Please complete this survey to complete your Signals registration.
        </p>
        <Divider />
        <div className={classes.scrollableDiv}>
          <Formik
            initialValues={starting_values}
            onSubmit={props.completeSurvey}
            validationSchema={validationSchema}
            render={({ errors, status, touched }) => (
              <Form>
                <FastField
                  component={SurveyDropdown}
                  id='role'
                  values={role_values}
                  name='role'
                  question='Which best describes your current role?'
                  label='Current role'
                />
                <ErrorMessage
                  name='role'
                  component='div'
                  className={classes.invalidInput}
                />
                <FastField
                  component={SurveyDropdown}
                  id='role_experience'
                  values={role_experience_values}
                  name='role_experience'
                  question='How long have you been in this type of role?'
                  label='Role experience'
                />
                <ErrorMessage
                  name='role_experience'
                  component='div'
                  className={classes.invalidInput}
                />
                <FastField
                  component={SurveyDropdown}
                  id='gender'
                  values={gender_values}
                  name='gender'
                  question='What is your gender?'
                  label='Gender'
                />
                <ErrorMessage
                  name='gender'
                  component='div'
                  className={classes.invalidInput}
                />
                <FastField
                  component={SurveyDropdown}
                  id='age'
                  values={age_values}
                  name='age'
                  question='How old are you?'
                  label='Age'
                />
                <ErrorMessage
                  name='age'
                  component='div'
                  className={classes.invalidInput}
                />
                <FastField
                  component={SurveyDropdown}
                  id='education'
                  values={education_values}
                  name='education'
                  question='Highest level of education completed?'
                  label='Education'
                />
                <ErrorMessage
                  name='education'
                  component='div'
                  className={classes.invalidInput}
                />
                <FastField
                  component={SurveyDropdown}
                  id='location'
                  values={location_values}
                  name='location'
                  question='Where are you located?'
                  label='Location'
                />
                <ErrorMessage
                  name='location'
                  component='div'
                  className={classes.invalidInput}
                />
                <FastField
                  component={SurveyDropdown}
                  id='employment_tenure'
                  values={employment_tenure_values}
                  name='employment_tenure'
                  question='How long have you been at your current employment?'
                  label='Employment length'
                />
                <ErrorMessage
                  name='employment_tenure'
                  component='div'
                  className={classes.invalidInput}
                />
                <FastField
                  component={SurveyHorizontalRadio}
                  id='chat_usefulness'
                  values={radio_values}
                  name='chat_usefulness'
                  question='How useful is chat?'
                  leftLabel='not at all'
                  rightLabel='very useful'
                />
                <ErrorMessage
                  name='chat_usefulness'
                  component='div'
                  className={classes.invalidInput}
                />
                <FastField
                  component={SurveyHorizontalRadio}
                  id='chat_experience'
                  values={radio_values}
                  name='chat_experience'
                  question='How much experience do you have using chat for work?'
                  leftLabel='none'
                  rightLabel='extensive'
                />
                <ErrorMessage
                  name='chat_experience'
                  component='div'
                  className={classes.invalidInput}
                />
                <FastField
                  component={SurveyHorizontalRadio}
                  id='auto_response_trust'
                  values={radio_values}
                  name='auto_response_trust'
                  question='To what extent do you trust auto-response?'
                  leftLabel='not at all'
                  rightLabel='to a great extent'
                />
                <ErrorMessage
                  name='auto_response_trust'
                  component='div'
                  className={classes.invalidInput}
                />
                <Divider />
                <div className={classes.submit}>
                  <Button
                    variant='contained'
                    color='primary'
                    type='submit'
                    disabled={Object.keys(errors).length !== 0 || !touched.role}
                  >
                    Submit
                  </Button>
                </div>
              </Form>
            )}
          />
        </div>
      </CardContent>
    </Card>
  )
}

export default IntakeSurvey
