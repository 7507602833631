const retargetingFlow = {
  flow: {
    data: {
      edges: [
        {
          data: {
            id: '8d5d8d8f-03a0-424e-a7c8-c1d503fab499',
            type: 'common'
          },
          source: 'c9691df9-e628-4273-9400-78a04e8c12f4.7c93394f-ef5c-4d32-8840-c219466c5408',
          target: '6e607e8e-000f-4702-a825-40583718d941'
        },
        {
          data: {
            id: '24835e45-9489-4a98-89c8-82f865645853',
            type: 'common'
          },
          source: 'start.default',
          target: 'c9691df9-e628-4273-9400-78a04e8c12f4'
        },
        {
          data: {
            id: '26c0f5fc-e272-4ced-a302-4f0073236757',
            type: 'common'
          },
          source: 'c9691df9-e628-4273-9400-78a04e8c12f4.84acf3f6-25c2-4ab4-be91-1efda5f9ee9b',
          target: '076cb47f-a096-445e-ae5c-e136c7eea20b'
        },
        {
          data: {
            id: 'b1f671cc-4c97-4c86-8e50-024ddedc55a4',
            type: 'common'
          },
          source: '6e607e8e-000f-4702-a825-40583718d941.response',
          target: '2d727b7f-177c-48c5-8180-029b52364777'
        },
        {
          data: {
            id: '8243882d-8057-4499-8bb1-5f32f35fa851',
            type: 'common'
          },
          source: '2d727b7f-177c-48c5-8180-029b52364777.default',
          target: '076cb47f-a096-445e-ae5c-e136c7eea20b'
        },
        {
          data: {
            id: 'cc6413f0-13e1-4c72-8dab-c064aff1acce',
            type: 'common'
          },
          source: 'c9691df9-e628-4273-9400-78a04e8c12f4.cc70354e-13d5-4902-9dd7-eca241741980',
          target: 'ae64af96-9f04-41e7-ada1-e5c7d23beac9'
        },
        {
          data: {
            id: 'f529024c-4d9b-46a4-8988-458df1b947d0',
            type: 'common'
          },
          source: 'ae64af96-9f04-41e7-ada1-e5c7d23beac9.default',
          target: 'f92fd756-1b07-4ed8-a7c9-207d9672a5ee'
        },
        {
          data: {
            id: '35f7aced-f83b-4fe1-90be-a244d92c1ac8',
            type: 'common'
          },
          source: 'c9691df9-e628-4273-9400-78a04e8c12f4.cc0dfb52-c553-4e79-b6e1-bd90e189be19',
          target: '7a3a7ef7-2225-46ae-a795-868a43504705'
        },
        {
          data: {
            id: '61d8005f-c0cb-4fa4-b2e3-6b628f4e048e',
            type: 'common'
          },
          source: '7a3a7ef7-2225-46ae-a795-868a43504705.default',
          target: 'f92fd756-1b07-4ed8-a7c9-207d9672a5ee'
        },
        {
          data: {
            id: '8ad89621-e62a-4be9-b3ab-d372436fb439',
            type: 'common'
          },
          source: 'f92fd756-1b07-4ed8-a7c9-207d9672a5ee.91ffdcd0-3b69-48b1-954e-006b0294bfdb',
          target: '94b9281a-9954-4431-8371-2ac9b96fd224'
        },
        {
          data: {
            id: 'a1f680cd-3219-4dda-b4b1-c2ed66f41204',
            type: 'common'
          },
          source: 'f92fd756-1b07-4ed8-a7c9-207d9672a5ee.ad63678c-25df-403e-b127-fa6a1cf4e35a',
          target: '3c991b87-9bb4-428d-a965-10ffc35d6590'
        },
        {
          data: {
            id: 'dc0b1920-de17-44da-9c51-58141aa00fc3',
            type: 'common'
          },
          source: '3c991b87-9bb4-428d-a965-10ffc35d6590.default',
          target: '1e519e58-1014-490b-aadc-9a46b7076096'
        },
        {
          data: {
            id: '3fe72c17-934f-4b3c-b0ee-6233d0904467',
            type: 'common'
          },
          source: '94b9281a-9954-4431-8371-2ac9b96fd224.default',
          target: 'f0c09e2f-3a56-4514-bc4c-0845ef6e4c26'
        },
        {
          data: {
            id: '3b362f4a-5952-421c-a826-c662c80c071a',
            type: 'common'
          },
          source: 'f0c09e2f-3a56-4514-bc4c-0845ef6e4c26.3525b5ca-09dd-4610-9883-7c56ba2e3c1b',
          target: '3c991b87-9bb4-428d-a965-10ffc35d6590'
        },
        {
          data: {
            id: 'd47ca0b6-fcb9-4c07-b361-5e286b1a881a',
            type: 'common'
          },
          source: 'f0c09e2f-3a56-4514-bc4c-0845ef6e4c26.73a0d8f2-3620-4fc4-80f8-1f75ba7f50d5',
          target: '0d08be1d-e649-4706-b823-e6e733a9f878'
        },
        {
          data: {
            id: '5cb13802-9d3d-4d98-acd2-25ecd3669e1e',
            type: 'common'
          },
          source: '0d08be1d-e649-4706-b823-e6e733a9f878.default',
          target: '3e63f56c-a8d8-47f1-a02c-2bad7db137e3'
        },
        {
          data: {
            id: '8833b39b-7c9a-4805-9db2-de41dc1c192a',
            type: 'common'
          },
          source: '3e63f56c-a8d8-47f1-a02c-2bad7db137e3.response',
          target: '5968d152-d737-4e79-b4a8-a7118be95fad'
        },
        {
          data: {
            id: '51d63874-f676-47d0-a702-26f050990788',
            type: 'common'
          },
          source: '5968d152-d737-4e79-b4a8-a7118be95fad.valid',
          target: '5a6ddd2d-eabf-4331-b776-5607418a8016'
        },
        {
          data: {
            id: 'b21fa08a-879a-48ae-a42c-f91654e38312',
            type: 'common'
          },
          source: '5a6ddd2d-eabf-4331-b776-5607418a8016.default',
          target: '27a9c8c8-24fb-421e-a7d5-3e05a6ce3548'
        },
        {
          data: {
            id: 'd39232e0-f06f-4c3a-9a3f-68778e5d338a',
            type: 'common'
          },
          source: '5968d152-d737-4e79-b4a8-a7118be95fad.invalid',
          target: '986a05d9-c54c-4cf8-8758-0122ce1dd33d'
        },
        {
          data: {
            id: 'a45c3f58-8b57-46d1-9e7e-fcc6a1565f46',
            type: 'common'
          },
          source: '986a05d9-c54c-4cf8-8758-0122ce1dd33d.default',
          target: '0aa3e256-40b9-44a4-ad0b-202509177805'
        },
        {
          data: {
            id: '6e1284c7-9447-4737-9c7c-e0de318dec71',
            type: 'common'
          },
          source: '27a9c8c8-24fb-421e-a7d5-3e05a6ce3548.complete',
          target: '43b6dbaa-d598-4fd8-aeb0-9e45dc0adaf3'
        },
        {
          data: {
            id: '81d36268-4ed8-483c-adfe-ee623e4d0dc1',
            type: 'common'
          },
          source: '43b6dbaa-d598-4fd8-aeb0-9e45dc0adaf3.default',
          target: '01cd80f9-efdc-41e9-a157-3c8013a966e3'
        },
        {
          data: {
            id: 'e5680579-6747-4f1d-9990-d168c1438994',
            type: 'common'
          },
          source: '01cd80f9-efdc-41e9-a157-3c8013a966e3.response',
          target: 'df22b92a-2130-4810-992e-c716c5af20e5'
        },
        {
          data: {
            id: '92059beb-2f9a-45e5-abca-6a583d833b23',
            type: 'common'
          },
          source: '076cb47f-a096-445e-ae5c-e136c7eea20b.default',
          target: '90243f9d-12f6-44ba-982b-55abd30f2dfb'
        },
        {
          data: {
            id: '63be20c4-1c8b-4e8c-8448-d22a19086dab',
            type: 'common'
          },
          source: '076cb47f-a096-445e-ae5c-e136c7eea20b.d6fb723f-15c3-4c2e-9fc3-80e1e89059bb',
          target: '03eb0867-95cf-4fcd-812d-6b0a293ecdd2'
        },
        {
          data: {
            id: '97de0dab-b788-4b29-b7d4-cd388fa0f4bc',
            type: 'common'
          },
          source: '90243f9d-12f6-44ba-982b-55abd30f2dfb.db90b196-1b68-48ca-80e8-273c2a8c0ae3',
          target: 'c85c2dd0-4295-41bb-992f-41e643797fff'
        },
        {
          data: {
            id: '94c62ed1-e2c8-4145-b68a-44cf15aa9481',
            type: 'common'
          },
          source: '03eb0867-95cf-4fcd-812d-6b0a293ecdd2.fe9c531d-a1b1-4892-a847-827b577d3142',
          target: 'c85c2dd0-4295-41bb-992f-41e643797fff'
        },
        {
          data: {
            id: 'b0e74299-5098-4d91-9c5d-ace64b3a1052',
            type: 'common'
          },
          source: '03eb0867-95cf-4fcd-812d-6b0a293ecdd2.fc19721e-0875-4287-92ba-f9b098d32afb',
          target: '5b48bd73-9be6-4b92-85d1-cd22af0eb6e8'
        },
        {
          data: {
            id: 'a88c1b36-d48f-4ee7-8f10-cbb91bb17dd0',
            type: 'common'
          },
          source: '5b48bd73-9be6-4b92-85d1-cd22af0eb6e8.away',
          target: '2aa74fd9-8255-4614-8eec-46aa7c146b80'
        },
        {
          data: {
            id: '6b01ba5e-c67b-4656-9e10-9f074e420576',
            type: 'common'
          },
          source: '5b48bd73-9be6-4b92-85d1-cd22af0eb6e8.no_response',
          target: '2aa74fd9-8255-4614-8eec-46aa7c146b80'
        },
        {
          data: {
            id: '83c2e2a5-cb27-47ab-8824-2d6a171a6fcd',
            type: 'common'
          },
          source: '2aa74fd9-8255-4614-8eec-46aa7c146b80.72e92afb-1deb-4bbb-b9ca-ca1c21ed5b37',
          target: 'c752877e-3a97-4779-9f91-5ad74360f38c'
        },
        {
          data: {
            id: 'e952f28e-69cd-4454-99be-d5f7da8037ce',
            type: 'common'
          },
          source: '2aa74fd9-8255-4614-8eec-46aa7c146b80.c82d1b20-d97b-4ee4-bfca-79e40d7be886',
          target: 'c85c2dd0-4295-41bb-992f-41e643797fff'
        },
        {
          data: {
            id: '1e2c651d-7b25-4177-b4cb-9cf0075f3004',
            type: 'common'
          },
          source: 'c85c2dd0-4295-41bb-992f-41e643797fff.response',
          target: '21b4ede2-ae06-431e-bc99-6b227e1a1e69'
        },
        {
          data: {
            id: 'd42ea7a1-1fdc-4046-88d0-7f831b619848',
            type: 'common'
          },
          source: '21b4ede2-ae06-431e-bc99-6b227e1a1e69.invalid',
          target: '1df631db-6180-4684-8fb6-8078a407e82e'
        },
        {
          data: {
            id: '7ed3958c-5231-492d-a23f-56708e21769a',
            type: 'common'
          },
          source: '1df631db-6180-4684-8fb6-8078a407e82e.valid',
          target: 'a7e9fe9b-49dc-4468-80d1-a353b51ddde0'
        },
        {
          data: {
            id: '53f78d5f-829b-407f-80a9-20c02ba94729',
            type: 'common'
          },
          source: '1df631db-6180-4684-8fb6-8078a407e82e.invalid',
          target: '21b4ede2-ae06-431e-bc99-6b227e1a1e69'
        },
        {
          data: {
            id: '6a97c76e-12bb-4b7a-8e53-54d3675bb81a',
            type: 'common'
          },
          source: '21b4ede2-ae06-431e-bc99-6b227e1a1e69.valid',
          target: '3f17b329-5ad7-4082-9ee2-42c45c767368'
        },
        {
          data: {
            id: '8f16a229-2dbf-4640-a025-29f9eef800ce',
            type: 'common'
          },
          source: '3f17b329-5ad7-4082-9ee2-42c45c767368.default',
          target: 'e4d4a0d9-4c59-4f19-b25e-32d9a7423893'
        },
        {
          data: {
            id: '68af29a4-1864-4407-a4bd-e33151a8861e',
            type: 'common'
          },
          source: 'a7e9fe9b-49dc-4468-80d1-a353b51ddde0.default',
          target: 'e4d4a0d9-4c59-4f19-b25e-32d9a7423893'
        },
        {
          data: {
            id: '3f9f9465-345e-4c11-a461-c9e61c49a079',
            type: 'common'
          },
          source: 'e4d4a0d9-4c59-4f19-b25e-32d9a7423893.default',
          target: '5b616908-5a7a-4976-a8d1-a4ca2bdfdf2a'
        },
        {
          data: {
            id: '29167a5f-dca6-4421-b912-0d22f006637b',
            type: 'common'
          },
          source: '90243f9d-12f6-44ba-982b-55abd30f2dfb.8e540fe5-3bb9-45b2-bac5-23ea6f2a8760',
          target: 'c752877e-3a97-4779-9f91-5ad74360f38c'
        },
        {
          data: {
            id: 'b829fcf9-b2bd-4d5d-b0b0-399cc3b0b0a3',
            type: 'common'
          },
          source: '03eb0867-95cf-4fcd-812d-6b0a293ecdd2.e6811866-ff6e-4f09-9115-8bc6f65ee0b4',
          target: 'c752877e-3a97-4779-9f91-5ad74360f38c'
        },
        {
          data: {
            id: '362300a1-3884-4ce7-99e9-48aba7b2fd1f',
            type: 'common'
          },
          source: 'c752877e-3a97-4779-9f91-5ad74360f38c.default',
          target: '8f9d0327-8793-4413-be78-bf4be921c7ac'
        },
        {
          data: {
            id: '7726c4fa-1559-4bb1-b8f6-ac7781106487',
            type: 'common'
          },
          source: '8f9d0327-8793-4413-be78-bf4be921c7ac.b37738e4-be59-42c4-87e6-5863d5b4c3cc',
          target: '7f0f50c3-296c-4cb3-a68d-0ec3fc23fd85'
        },
        {
          data: {
            id: 'db3650b7-69e8-4168-bef9-b420e6c9e3bb',
            type: 'common'
          },
          source: '8f9d0327-8793-4413-be78-bf4be921c7ac.7935342e-cf80-42d6-8b10-e7dee5d6007b',
          target: '47b560e1-9521-486b-944f-ba406e6fed9c'
        },
        {
          data: {
            id: 'a7bcf415-2a1f-48dc-a165-b958975e95c8',
            type: 'common'
          },
          source: '7f0f50c3-296c-4cb3-a68d-0ec3fc23fd85.f618cf6d-5b24-467c-be9c-ee6e2b5f8d84',
          target: '47b560e1-9521-486b-944f-ba406e6fed9c'
        },
        {
          data: {
            id: 'd96216b7-55f6-4908-bc16-e9c17bc285f7',
            type: 'common'
          },
          source: '47b560e1-9521-486b-944f-ba406e6fed9c.default',
          target: 'a94fba1b-0e39-4ad6-b2fd-b43453fed6c8'
        },
        {
          data: {
            id: 'a4ef2f5a-b4fc-4588-afdd-3c256b9eba9b',
            type: 'common'
          },
          source: '7f0f50c3-296c-4cb3-a68d-0ec3fc23fd85.ee57618c-649e-4a35-9505-3032049d3c85',
          target: 'dd5d6f5c-6a30-40e6-a2e6-75ab3eb4eb52'
        },
        {
          data: {
            id: '72d29084-4b14-43bb-9907-569298fc9f65',
            type: 'common'
          },
          source: 'dd5d6f5c-6a30-40e6-a2e6-75ab3eb4eb52.default',
          target: 'ce50d08e-df22-49de-a7dc-4e233575ca67'
        },
        {
          data: {
            id: '2c1fba71-97ee-4fa6-aff9-2020e4fa8ff4',
            type: 'common'
          },
          source: 'ce50d08e-df22-49de-a7dc-4e233575ca67.response',
          target: '1f7e9661-29ea-4c4f-a5c1-88fd5cdac59c'
        },
        {
          data: {
            id: '2dae91d0-f1b2-47ab-848e-05593304c22a',
            type: 'common'
          },
          source: '1f7e9661-29ea-4c4f-a5c1-88fd5cdac59c.valid',
          target: '0665c100-8413-42e8-bb1e-2df664055cac'
        },
        {
          data: {
            id: 'e87fbf22-bded-4fea-b2ca-d8d1024303ba',
            type: 'common'
          },
          source: '0665c100-8413-42e8-bb1e-2df664055cac.default',
          target: 'e2f15391-9e24-4a94-bdae-7e5be4e250bd'
        },
        {
          data: {
            id: 'fb9cb094-7b10-44bd-ae3d-44fe38bbdddf',
            type: 'common'
          },
          source: '1f7e9661-29ea-4c4f-a5c1-88fd5cdac59c.invalid',
          target: 'a96381f9-0e66-497e-b027-071f012fdce0'
        },
        {
          data: {
            id: 'acee500a-2751-4c79-9b19-01fd6c050332',
            type: 'common'
          },
          source: 'a96381f9-0e66-497e-b027-071f012fdce0.default',
          target: '232a691a-2bc1-4908-aac4-7a671dfc8a61'
        },
        {
          data: {
            id: '936fc59a-7d0f-4423-9285-a1701024225b',
            type: 'common'
          },
          source: 'e2f15391-9e24-4a94-bdae-7e5be4e250bd.complete',
          target: 'db3fd5e7-30d1-4861-bef8-c199fa890b05'
        },
        {
          data: {
            id: '936df9d9-9e1c-4d55-8729-222ff98c8d06',
            type: 'common'
          },
          source: 'db3fd5e7-30d1-4861-bef8-c199fa890b05.default',
          target: '577caead-f670-44ff-baa6-7d6f99cb035a'
        },
        {
          data: {
            id: '92e56fc3-71f2-4c8a-a52c-f43b0c1e01b7',
            type: 'common'
          },
          source: '577caead-f670-44ff-baa6-7d6f99cb035a.response',
          target: '9741ee86-6061-4307-8bbd-fb64a2f20732'
        }
      ],
      nodes: [
        {
          id: 'start',
          top: 0,
          left: 0,
          type: 'start',
          ports: [
            {
              id: 'default'
            }
          ]
        },
        {
          id: 'c9691df9-e628-4273-9400-78a04e8c12f4',
          top: 220,
          body: '<p>Welcome back! I see you have been checking us out. What can I help expedite for you?</p>',
          left: 0,
          type: 'options',
          ports: [
            {
              id: 'cc70354e-13d5-4902-9dd7-eca241741980',
              label: 'I\'d like a quote',
              _reactKey: '0e0f4df6-1c62-4aad-a828-204f6e298255'
            },
            {
              id: '84acf3f6-25c2-4ab4-be91-1efda5f9ee9b',
              label: 'Talk to an expert',
              _reactKey: '3f737485-a213-4701-a9e1-35108f37a2d9'
            },
            {
              id: 'cc0dfb52-c553-4e79-b6e1-bd90e189be19',
              label: 'Schedule a demo',
              _reactKey: 'baeb9b14-0bbf-454b-afcc-4548951b5ac8'
            },
            {
              id: '7c93394f-ef5c-4d32-8840-c219466c5408',
              label: 'Other',
              _reactKey: '3e0fa945-3b78-4f07-9355-8bc80489254d'
            }
          ],
          buttons: [
            {
              id: 'cc70354e-13d5-4902-9dd7-eca241741980',
              label: 'I\'d like a quote',
              _reactKey: '0e0f4df6-1c62-4aad-a828-204f6e298255'
            },
            {
              id: '84acf3f6-25c2-4ab4-be91-1efda5f9ee9b',
              label: 'Talk to an expert',
              _reactKey: '3f737485-a213-4701-a9e1-35108f37a2d9'
            },
            {
              id: 'cc0dfb52-c553-4e79-b6e1-bd90e189be19',
              label: 'Schedule a demo',
              _reactKey: 'baeb9b14-0bbf-454b-afcc-4548951b5ac8'
            },
            {
              id: '7c93394f-ef5c-4d32-8840-c219466c5408',
              label: 'Other',
              _reactKey: '3e0fa945-3b78-4f07-9355-8bc80489254d'
            }
          ],
          captureTimes: 3,
          mapAttribute: false,
          responseType: 'buttons',
          keywordOptions: [],
          secondResponse: 'Still didn\'t get that. Go ahead and try again',
          invalidResponse: 'Sorry, I didn\'t understand that. Please try again'
        },
        {
          id: '6e607e8e-000f-4702-a825-40583718d941',
          top: 540,
          body: '<p>Okay, what can I help you with?</p>',
          left: 0,
          type: 'options',
          ports: [
            {
              id: 'response',
              label: 'Visitor Response',
              _reactKey: '449c1de7-d4c6-4956-b165-4d49e9ce096b'
            }
          ],
          buttons: [],
          captureTimes: 3,
          mapAttribute: false,
          responseType: 'free_response',
          keywordOptions: [],
          secondResponse: 'Still didn\'t get that. Go ahead and try again',
          invalidResponse: 'Sorry, I didn\'t understand that. Please try again'
        },
        {
          id: '076cb47f-a096-445e-ae5c-e136c7eea20b',
          top: 490,
          body: '',
          left: 750,
          type: 'conditional',
          ports: [
            {
              id: 'default',
              label: 'Fallback Path',
              _reactKey: '2605cdae-fb7f-445b-9de6-9ada89bf3345'
            },
            {
              id: 'd6fb723f-15c3-4c2e-9fc3-80e1e89059bb',
              label: 'Agents Online',
              filters: [
                {
                  cmp: 'available',
                  prop: 'team',
                  value: 3
                }
              ],
              _reactKey: 'bd5297cf-d820-49c1-9768-1445b22ca4dd'
            }
          ],
          port_order: [
            'default',
            'd6fb723f-15c3-4c2e-9fc3-80e1e89059bb'
          ]
        },
        {
          id: '2d727b7f-177c-48c5-8180-029b52364777',
          top: 720,
          body: '<p>Got it. This is probably best handled by one of our experts.</p>',
          left: 0,
          type: 'chat',
          ports: [
            {
              id: 'default',
              _reactKey: '62dbe05d-2b00-4d3f-a76d-8cd6b20eb1b0'
            }
          ]
        },
        {
          id: 'ae64af96-9f04-41e7-ada1-e5c7d23beac9',
          top: 930,
          body: '<p>That\'s great! I just have a few questions so we can get you the most accurate quote.</p>',
          left: -850,
          type: 'chat',
          ports: [
            {
              id: 'default',
              _reactKey: 'ee0fb4f9-edba-4b46-95f8-0d94bbe5250f'
            }
          ]
        },
        {
          id: 'f92fd756-1b07-4ed8-a7c9-207d9672a5ee',
          top: 1180,
          body: '<p><em>[Qualifying Question #1 (industry, integration, company name, etc)]</em></p>',
          left: -850,
          type: 'options',
          ports: [
            {
              id: '91ffdcd0-3b69-48b1-954e-006b0294bfdb',
              label: '[Qualifying Response(s)]',
              _reactKey: 'dc9844b2-a560-4af1-8a91-53e6fa3ad06a'
            },
            {
              id: 'ad63678c-25df-403e-b127-fa6a1cf4e35a',
              label: '[Disqualifying Response(s)]',
              _reactKey: '0518978f-4f59-4342-a54a-60be7db39f43'
            }
          ],
          buttons: [
            {
              id: '91ffdcd0-3b69-48b1-954e-006b0294bfdb',
              label: '[Qualifying Response(s)]',
              _reactKey: 'dc9844b2-a560-4af1-8a91-53e6fa3ad06a'
            },
            {
              id: 'ad63678c-25df-403e-b127-fa6a1cf4e35a',
              label: '[Disqualifying Response(s)]',
              _reactKey: '0518978f-4f59-4342-a54a-60be7db39f43'
            }
          ],
          captureTimes: 3,
          mapAttribute: false,
          responseType: 'buttons',
          keywordOptions: [],
          secondResponse: 'Still didn\'t get that. Go ahead and try again',
          invalidResponse: 'Sorry, I didn\'t understand that. Please try again'
        },
        {
          id: '7a3a7ef7-2225-46ae-a795-868a43504705',
          top: 1150,
          body: '<p>That\'s great! I just have a few questions so we can set up your demo</p>',
          left: -330,
          type: 'chat',
          ports: [
            {
              id: 'default',
              _reactKey: 'b87db053-3b61-4fcb-845d-c63a7bc293cf'
            }
          ]
        },
        {
          id: '94b9281a-9954-4431-8371-2ac9b96fd224',
          top: 1240,
          body: '',
          left: -1150,
          type: 'alert_agent',
          ports: [
            {
              id: 'default',
              label: 'Next',
              _reactKey: 'd0b84485-a356-4af3-b411-6becf16deee6'
            }
          ],
          notification: {
            title: 'Conversation Alert',
            message: 'Site visitor is engaging with bot'
          },
          routeOptions: [
            {
              type: 'round_robin',
              method: 'single_agent',
              objectIDs: null
            }
          ],
          alertUnavailableAgents: false
        },
        {
          id: '3c991b87-9bb4-428d-a965-10ffc35d6590',
          top: 1560,
          body: '<p><span style=\'color: rgb(0, 0, 0);\'>Sorry, but based on this info, it looks like we may not be a good fit for your team. But please check out our</span><em style=\'color: rgb(0, 0, 0);\'> </em><em style=\'color: rgb(131, 65, 135);\'>[Blog,Content Link]</em><span style=\'color: rgb(131, 65, 135);\'> </span><span style=\'color: rgb(0, 0, 0);\'>for access to</span><span style=\'color: rgb(131, 65, 135);\'> </span><em style=\'color: rgb(131, 65, 135);\'>[description]</em><span style=\'color: rgb(0, 0, 0);\'>. We hope you find the info helpful!</span></p>',
          left: -620,
          type: 'chat',
          ports: [
            {
              id: 'default',
              _reactKey: 'f0f04017-9552-4bcc-a29a-99aae4a4ea14'
            }
          ]
        },
        {
          id: '1e519e58-1014-490b-aadc-9a46b7076096',
          top: 1840,
          body: '',
          left: -620,
          type: 'end_flow',
          ports: []
        },
        {
          id: 'f0c09e2f-3a56-4514-bc4c-0845ef6e4c26',
          top: 1470,
          body: '<p><em>[Qualifying Question #2 (company name, role, etc)]</em></p>',
          left: -1150,
          type: 'options',
          ports: [
            {
              id: '73a0d8f2-3620-4fc4-80f8-1f75ba7f50d5',
              label: '[Qualifying Response(s)]',
              _reactKey: 'e765fd8d-b14b-48cc-82c9-77a0d794a207'
            },
            {
              id: '3525b5ca-09dd-4610-9883-7c56ba2e3c1b',
              label: '[Disqualifying Response(s)]',
              _reactKey: '6f04949f-c650-4ffb-a1e7-95d4cc1ae649'
            }
          ],
          buttons: [
            {
              id: '73a0d8f2-3620-4fc4-80f8-1f75ba7f50d5',
              label: '[Qualifying Response(s)]',
              _reactKey: 'e765fd8d-b14b-48cc-82c9-77a0d794a207'
            },
            {
              id: '3525b5ca-09dd-4610-9883-7c56ba2e3c1b',
              label: '[Disqualifying Response(s)]',
              _reactKey: '6f04949f-c650-4ffb-a1e7-95d4cc1ae649'
            }
          ],
          captureTimes: 3,
          mapAttribute: false,
          responseType: 'buttons',
          keywordOptions: [],
          secondResponse: 'Still didn\'t get that. Go ahead and try again',
          invalidResponse: 'Sorry, I didn\'t understand that. Please try again'
        },
        {
          id: '0d08be1d-e649-4706-b823-e6e733a9f878',
          top: 1790,
          body: '<p>Got it. To provide you the most accurate quote I\'d love to schedule a time for you to meet with one of our experts. Just a few more questions to collect some information so our expert can reach out.</p>',
          left: -1150,
          type: 'chat',
          ports: [
            {
              id: 'default',
              _reactKey: '9fda2bcf-02ad-4d36-b0fb-e941e1535f86'
            }
          ]
        },
        {
          id: '5968d152-d737-4e79-b4a8-a7118be95fad',
          top: 2300,
          body: '<p>What\'s the best email address to send your calendar invite?</p>',
          left: -1150,
          type: 'email',
          ports: [
            {
              id: 'invalid',
              label: 'Failed to capture email',
              _reactKey: '2c6318d3-9c3a-4722-9a43-645c3bcd4de0'
            },
            {
              id: 'valid',
              label: 'Successfully captured email',
              _reactKey: 'c8d2efd8-2f54-4d2f-b80d-b82b83044a45'
            }
          ],
          unwanted: {
            spam: true,
            blocked: false,
            personal: true
          },
          captureTimes: 3,
          secondResponse: '<p>I need a business email please.</p>',
          invalidResponse: '<p>Sorry, that didn\'t work. Try again?</p>',
          unwantedResponse: '<p>Please enter a business email</p>'
        },
        {
          id: '3e63f56c-a8d8-47f1-a02c-2bad7db137e3',
          top: 2050,
          body: '<p>What is your full name?</p>',
          left: -1150,
          type: 'options',
          ports: [
            {
              id: 'response',
              label: 'Visitor Response',
              _reactKey: 'd4fa2dd6-5d47-47d5-af80-d51465111d73'
            }
          ],
          buttons: [],
          attribute: 'full_name',
          captureTimes: 3,
          mapAttribute: true,
          responseType: 'free_response',
          keywordOptions: [],
          secondResponse: 'Still didn\'t get that. Go ahead and try again',
          invalidResponse: 'Sorry, I didn\'t understand that. Please try again'
        },
        {
          id: '5a6ddd2d-eabf-4331-b776-5607418a8016',
          top: 2450,
          body: '',
          left: -1540,
          tags: [],
          type: 'tag',
          ports: [
            {
              id: 'default',
              _reactKey: '9931dadc-3c35-4254-a947-d73cd9d6ca04'
            }
          ]
        },
        {
          id: '27a9c8c8-24fb-421e-a7d5-3e05a6ce3548',
          top: 2720,
          body: '<p>Last step! Pick a time below that works best for you</p>',
          left: -1320,
          type: 'calendar',
          ports: [
            {
              id: 'complete',
              label: 'Meeting Scheduled',
              _reactKey: 'abe0bb18-c8d9-4cb4-9ff1-917c0fff334f'
            }
          ],
          method: 'single_agent',
          team_id: '',
          email_id: '',
          user_ids: [],
          route_type: '',
          meeting_type: '',
          routeOptions: [
            {
              type: '',
              method: 'single_agent',
              objectIDs: []
            }
          ],
          follow_up_email: false,
          text_message_reminders: true
        },
        {
          id: '986a05d9-c54c-4cf8-8758-0122ce1dd33d',
          top: 2650,
          body: '<p><span style=\'color: rgb(0, 0, 0);\'>Looks like you\'re not ready to book a demo. In the meantime, feel free to checkout ou</span><span style=\'color: rgb(76, 76, 76);\'>r</span><em style=\'color: rgb(76, 76, 76);\'> [Blog, Content link]</em><span style=\'color: rgb(76, 76, 76);\'> page for </span><em style=\'color: rgb(76, 76, 76);\'>[description].</em></p>',
          left: -880,
          type: 'chat',
          ports: [
            {
              id: 'default',
              _reactKey: '8a68fd5a-6edc-4f50-93c0-a623bebea42c'
            }
          ]
        },
        {
          id: '0aa3e256-40b9-44a4-ad0b-202509177805',
          top: 2890,
          body: '',
          left: -880,
          type: 'end_flow',
          ports: []
        },
        {
          id: '43b6dbaa-d598-4fd8-aeb0-9e45dc0adaf3',
          top: 2940,
          body: '',
          left: -1320,
          tags: [],
          type: 'tag',
          ports: [
            {
              id: 'default',
              _reactKey: 'c9c002d3-1f5c-4e30-b4c1-ae3974e108b3'
            }
          ]
        },
        {
          id: '01cd80f9-efdc-41e9-a157-3c8013a966e3',
          top: 3190,
          body: '<p>Thanks!</p><p><br></p><p>We\'re looking forward to it! If you\'d like to leave comments for the specialist, please type below.</p>',
          left: -1320,
          type: 'options',
          ports: [
            {
              id: 'response',
              label: 'Visitor Response',
              _reactKey: '0cd98d8d-0de6-4d1e-92df-3d7a40f916c4'
            }
          ],
          buttons: [],
          captureTimes: 3,
          mapAttribute: false,
          responseType: 'free_response',
          keywordOptions: [],
          secondResponse: 'Still didn\'t get that. Go ahead and try again',
          invalidResponse: 'Sorry, I didn\'t understand that. Please try again'
        },
        {
          id: 'df22b92a-2130-4810-992e-c716c5af20e5',
          top: 3480,
          body: '',
          left: -1320,
          type: 'end_flow',
          ports: []
        },
        {
          id: '90243f9d-12f6-44ba-982b-55abd30f2dfb',
          top: 900,
          body: '<p>No problem. What would you like to do?</p>',
          left: 430,
          type: 'options',
          ports: [
            {
              id: '8e540fe5-3bb9-45b2-bac5-23ea6f2a8760',
              label: 'Book a time for later',
              _reactKey: '1fb3ae90-ad33-4c10-8e71-980792d910de'
            },
            {
              id: 'db90b196-1b68-48ca-80e8-273c2a8c0ae3',
              label: 'Leave a message',
              _reactKey: 'd6a50632-5ebf-4643-a631-cbe25ba1ab0c'
            }
          ],
          buttons: [
            {
              id: '8e540fe5-3bb9-45b2-bac5-23ea6f2a8760',
              label: 'Book a time for later',
              _reactKey: '1fb3ae90-ad33-4c10-8e71-980792d910de'
            },
            {
              id: 'db90b196-1b68-48ca-80e8-273c2a8c0ae3',
              label: 'Leave a message',
              _reactKey: 'd6a50632-5ebf-4643-a631-cbe25ba1ab0c'
            }
          ],
          captureTimes: 3,
          mapAttribute: false,
          responseType: 'buttons',
          keywordOptions: [],
          secondResponse: 'Still didn\'t get that. Go ahead and try again',
          invalidResponse: 'Sorry, I didn\'t understand that. Please try again'
        },
        {
          id: '03eb0867-95cf-4fcd-812d-6b0a293ecdd2',
          top: 730,
          body: '<p>No problem. What would you like to do?</p>',
          left: 1080,
          type: 'options',
          ports: [
            {
              id: 'e6811866-ff6e-4f09-9115-8bc6f65ee0b4',
              label: 'Book a time for later',
              _reactKey: '3a53812d-99af-4fbf-8949-ae3782e5e049'
            },
            {
              id: 'fc19721e-0875-4287-92ba-f9b098d32afb',
              label: 'Chat with an agent',
              _reactKey: 'bc0b3fa0-aada-4abc-8dd4-e37a2adf8b53'
            },
            {
              id: 'fe9c531d-a1b1-4892-a847-827b577d3142',
              label: 'Leave a message',
              _reactKey: 'fe68e2a2-9202-4ca6-abad-96916daeb801'
            }
          ],
          buttons: [
            {
              id: 'e6811866-ff6e-4f09-9115-8bc6f65ee0b4',
              label: 'Book a time for later',
              _reactKey: '3a53812d-99af-4fbf-8949-ae3782e5e049'
            },
            {
              id: 'fc19721e-0875-4287-92ba-f9b098d32afb',
              label: 'Chat with an agent',
              _reactKey: 'bc0b3fa0-aada-4abc-8dd4-e37a2adf8b53'
            },
            {
              id: 'fe9c531d-a1b1-4892-a847-827b577d3142',
              label: 'Leave a message',
              _reactKey: 'fe68e2a2-9202-4ca6-abad-96916daeb801'
            }
          ],
          captureTimes: 3,
          mapAttribute: false,
          responseType: 'buttons',
          keywordOptions: [],
          secondResponse: 'Still didn\'t get that. Go ahead and try again',
          invalidResponse: 'Sorry, I didn\'t understand that. Please try again'
        },
        {
          id: 'c85c2dd0-4295-41bb-992f-41e643797fff',
          top: 1270,
          body: '<p>Sounds good, you can type your message below. 👇</p>',
          left: 180,
          type: 'options',
          ports: [
            {
              id: 'response',
              label: 'Visitor Response',
              _reactKey: '4f1b6c15-20b6-4bed-bece-45c7649ed318'
            }
          ],
          buttons: [],
          captureTimes: 3,
          mapAttribute: false,
          responseType: 'free_response',
          keywordOptions: [],
          secondResponse: 'Still didn\'t get that. Go ahead and try again',
          invalidResponse: 'Sorry, I didn\'t understand that. Please try again'
        },
        {
          id: '5b48bd73-9be6-4b92-85d1-cd22af0eb6e8',
          top: 1040,
          body: '',
          left: 1080,
          type: 'live_chat',
          ports: [
            {
              id: 'away',
              label: 'No one available',
              _reactKey: 'e1d9a33a-3552-47c9-a81a-70e75a1f1183'
            },
            {
              id: 'no_response',
              label: 'No one responds',
              _reactKey: '1fe49f6b-84a6-49aa-9cae-298fef3c2552'
            }
          ],
          routeMessage: '<p>One moment while I bring in an expert. If you have any questions while you wait please enter them below 👇</p>',
          routeOptions: [
            {
              type: 'round_robin',
              method: 'single_agent',
              objectIDs: null
            }
          ],
          fallbackDelay: 30
        },
        {
          id: '2aa74fd9-8255-4614-8eec-46aa7c146b80',
          top: 1310,
          body: '<p>hmm... seems like our agents are currently busy. However, would you like to leave them a note or schedule a time for later?</p>',
          left: 1080,
          type: 'options',
          ports: [
            {
              id: 'c82d1b20-d97b-4ee4-bfca-79e40d7be886',
              label: 'Leave a message',
              _reactKey: 'fc9ee51d-b490-4417-8f4e-af2d389382a6'
            },
            {
              id: '72e92afb-1deb-4bbb-b9ca-ca1c21ed5b37',
              label: 'Book a time for later',
              _reactKey: '282d781f-a538-4eea-869e-04f6696cdf29'
            }
          ],
          buttons: [
            {
              id: 'c82d1b20-d97b-4ee4-bfca-79e40d7be886',
              label: 'Leave a message',
              _reactKey: 'fc9ee51d-b490-4417-8f4e-af2d389382a6'
            },
            {
              id: '72e92afb-1deb-4bbb-b9ca-ca1c21ed5b37',
              label: 'Book a time for later',
              _reactKey: '282d781f-a538-4eea-869e-04f6696cdf29'
            }
          ],
          captureTimes: 3,
          mapAttribute: false,
          responseType: 'buttons',
          keywordOptions: [],
          secondResponse: 'Still didn\'t get that. Go ahead and try again',
          invalidResponse: 'Sorry, I didn\'t understand that. Please try again'
        },
        {
          id: 'c752877e-3a97-4779-9f91-5ad74360f38c',
          top: 1580,
          body: '<p>Sure! Just <em>[number of qualifying questions]</em> questions so I can get that set up for you.</p>',
          left: 780,
          type: 'chat',
          ports: [
            {
              id: 'default',
              _reactKey: '9bd2ad15-bb27-4344-ab00-1867e834dd57'
            }
          ]
        },
        {
          id: '21b4ede2-ae06-431e-bc99-6b227e1a1e69',
          top: 1540,
          body: '<p><span style=\'color: rgb(0, 0, 0);\'>So we can reach back out to you, can we get your email address?</span></p>',
          left: 180,
          type: 'email',
          ports: [
            {
              id: 'invalid',
              label: 'Failed to capture email',
              _reactKey: 'f6d08dd5-34f7-4b26-96b0-dab8e630aded'
            },
            {
              id: 'valid',
              label: 'Successfully captured email',
              _reactKey: '4ea6f872-86be-46dc-9e0d-52216c79f04b'
            }
          ],
          unwanted: {
            spam: true,
            blocked: true,
            personal: true
          },
          captureTimes: 3,
          secondResponse: '<p>Please enter a valid business email</p>',
          invalidResponse: '<p>Please enter a valid email</p>',
          unwantedResponse: '<p>Please enter a business email</p>'
        },
        {
          id: '1df631db-6180-4684-8fb6-8078a407e82e',
          top: 1680,
          body: '<p>Hm, I\'m still not understanding. Let\'s try a phone number instead.</p>',
          left: -160,
          type: 'phone',
          ports: [
            {
              id: 'invalid',
              label: 'Failed to capture phone',
              _reactKey: '6bcf7be0-7322-46f4-95ef-cfdfd7be8f40'
            },
            {
              id: 'valid',
              label: 'Successfully captured phone',
              _reactKey: '317bf007-60bc-4277-8c12-ca768e76d846'
            }
          ],
          country: 'US',
          captureTimes: 3,
          secondResponse: '<p>Still didn\'t get that. Please enter a valid number</p>',
          invalidResponse: '<p>Sorry, I didn\'t get that. Please enter a valid phone number</p>'
        },
        {
          id: 'a7e9fe9b-49dc-4468-80d1-a353b51ddde0',
          top: 1970,
          body: '',
          left: -160,
          tags: [],
          type: 'tag',
          ports: [
            {
              id: 'default',
              _reactKey: 'b7fa11ef-d0c9-4be7-89d1-95ec6d9d31bd'
            }
          ]
        },
        {
          id: '3f17b329-5ad7-4082-9ee2-42c45c767368',
          top: 1840,
          body: '',
          left: 180,
          tags: [],
          type: 'tag',
          ports: [
            {
              id: 'default',
              _reactKey: '9f5b8263-5ad2-4a26-bdc0-d07bfc502423'
            }
          ]
        },
        {
          id: 'e4d4a0d9-4c59-4f19-b25e-32d9a7423893',
          top: 2070,
          body: '<p>Thanks! We\'ll get back to you <em>[insert estimated time].</em></p>',
          left: 180,
          type: 'chat',
          ports: [
            {
              id: 'default',
              _reactKey: 'e34ae23b-5eca-4987-90ee-fcf4d5b2ff19'
            }
          ]
        },
        {
          id: '5b616908-5a7a-4976-a8d1-a4ca2bdfdf2a',
          top: 2290,
          body: '',
          left: 180,
          type: 'end_flow',
          ports: []
        },
        {
          id: '8f9d0327-8793-4413-be78-bf4be921c7ac',
          top: 1820,
          body: '<p><em>[Qualifying Question #1 (industry, integration, company name, etc)]</em></p>',
          left: 780,
          type: 'options',
          ports: [
            {
              id: 'b37738e4-be59-42c4-87e6-5863d5b4c3cc',
              label: '[Qualifying Response(s)]',
              _reactKey: '6f9286d5-81a9-4f7f-83a8-fee8048e7495'
            },
            {
              id: '7935342e-cf80-42d6-8b10-e7dee5d6007b',
              label: '[Disqualifying Response(s)]',
              _reactKey: '1f93d8ed-9dae-4f36-aeb7-812c5245f5b3'
            }
          ],
          buttons: [
            {
              id: 'b37738e4-be59-42c4-87e6-5863d5b4c3cc',
              label: '[Qualifying Response(s)]',
              _reactKey: '6f9286d5-81a9-4f7f-83a8-fee8048e7495'
            },
            {
              id: '7935342e-cf80-42d6-8b10-e7dee5d6007b',
              label: '[Disqualifying Response(s)]',
              _reactKey: '1f93d8ed-9dae-4f36-aeb7-812c5245f5b3'
            }
          ],
          captureTimes: 3,
          mapAttribute: false,
          responseType: 'buttons',
          keywordOptions: [],
          secondResponse: 'Still didn\'t get that. Go ahead and try again',
          invalidResponse: 'Sorry, I didn\'t understand that. Please try again'
        },
        {
          id: '7f0f50c3-296c-4cb3-a68d-0ec3fc23fd85',
          top: 2120,
          body: '<p><em>[Qualifying Question #2 (company name, role, etc)]</em></p>',
          left: 560,
          type: 'options',
          ports: [
            {
              id: 'ee57618c-649e-4a35-9505-3032049d3c85',
              label: '[Qualifying Response(s)]',
              _reactKey: '519ee894-8b26-425a-8a21-ed6b43f22310'
            },
            {
              id: 'f618cf6d-5b24-467c-be9c-ee6e2b5f8d84',
              label: '[Disqualifying Response(s)]',
              _reactKey: '86795cb8-b69f-4573-8cc5-314d2d9530a9'
            }
          ],
          buttons: [
            {
              id: 'ee57618c-649e-4a35-9505-3032049d3c85',
              label: '[Qualifying Response(s)]',
              _reactKey: '519ee894-8b26-425a-8a21-ed6b43f22310'
            },
            {
              id: 'f618cf6d-5b24-467c-be9c-ee6e2b5f8d84',
              label: '[Disqualifying Response(s)]',
              _reactKey: '86795cb8-b69f-4573-8cc5-314d2d9530a9'
            }
          ],
          captureTimes: 3,
          mapAttribute: false,
          responseType: 'buttons',
          keywordOptions: [],
          secondResponse: 'Still didn\'t get that. Go ahead and try again',
          invalidResponse: 'Sorry, I didn\'t understand that. Please try again'
        },
        {
          id: '47b560e1-9521-486b-944f-ba406e6fed9c',
          top: 2180,
          body: '<p><span style=\'color: rgb(0, 0, 0);\'>Sorry, but based on this info, it looks like we may not be a good fit for your team. But please check out our</span><em style=\'color: rgb(0, 0, 0);\'> </em><em style=\'color: rgb(131, 65, 135);\'>[Blog,Content Link]</em><span style=\'color: rgb(131, 65, 135);\'> </span><span style=\'color: rgb(0, 0, 0);\'>for access to</span><span style=\'color: rgb(131, 65, 135);\'> </span><em style=\'color: rgb(131, 65, 135);\'>[description]</em><span style=\'color: rgb(0, 0, 0);\'>. We hope you find the info helpful!</span></p>',
          left: 1070,
          type: 'chat',
          ports: [
            {
              id: 'default',
              _reactKey: '3a94f7c1-3f53-42c2-b9bf-eebff3c10f87'
            }
          ]
        },
        {
          id: 'a94fba1b-0e39-4ad6-b2fd-b43453fed6c8',
          top: 2560,
          body: '',
          left: 1240,
          type: 'end_flow',
          ports: []
        },
        {
          id: 'dd5d6f5c-6a30-40e6-a2e6-75ab3eb4eb52',
          top: 2410,
          body: '<p>Got it. To provide you the most accurate quote I\'d love to schedule a time for you to meet with one of our experts. Just a few more questions to collect some information so our expert can reach out.</p>',
          left: 560,
          type: 'chat',
          ports: [
            {
              id: 'default',
              _reactKey: '957b7c33-dd3a-430f-aaf9-adc9ac247590'
            }
          ]
        },
        {
          id: 'ce50d08e-df22-49de-a7dc-4e233575ca67',
          top: 2670,
          body: '<p>What is your full name?</p>',
          left: 560,
          type: 'options',
          ports: [
            {
              id: 'response',
              label: 'Visitor Response',
              _reactKey: '90e2d640-42fe-4d81-8645-1e02d45443e9'
            }
          ],
          buttons: [],
          attribute: 'full_name',
          captureTimes: 3,
          mapAttribute: true,
          responseType: 'free_response',
          keywordOptions: [],
          secondResponse: 'Still didn\'t get that. Go ahead and try again',
          invalidResponse: 'Sorry, I didn\'t understand that. Please try again'
        },
        {
          id: '1f7e9661-29ea-4c4f-a5c1-88fd5cdac59c',
          top: 2900,
          body: '<p>What\'s the best email address to send your calendar invite?</p>',
          left: 560,
          type: 'email',
          ports: [
            {
              id: 'invalid',
              label: 'Failed to capture email',
              _reactKey: '90e41c29-48d5-4211-a7ab-948c5af683b0'
            },
            {
              id: 'valid',
              label: 'Successfully captured email',
              _reactKey: 'd8785550-c993-4004-8bc3-72d2b1f6338f'
            }
          ],
          unwanted: {
            spam: true,
            blocked: false,
            personal: true
          },
          captureTimes: 3,
          secondResponse: '<p>Please enter a valid business email</p>',
          invalidResponse: '<p>Sorry, that didn\'t work. Try again?</p>',
          unwantedResponse: '<p>I need a business email please.</p>'
        },
        {
          id: '0665c100-8413-42e8-bb1e-2df664055cac',
          top: 3020,
          body: '',
          left: 200,
          tags: [],
          type: 'tag',
          ports: [
            {
              id: 'default',
              _reactKey: '587fb1f6-e0ab-4daa-980f-09d0d704ad11'
            }
          ]
        },
        {
          id: 'e2f15391-9e24-4a94-bdae-7e5be4e250bd',
          top: 3240,
          body: '',
          left: 360,
          type: 'calendar',
          ports: [
            {
              id: 'complete',
              label: 'Meeting Scheduled',
              _reactKey: '0d8dea60-b3a6-4087-bf14-53c7d6f6862b'
            }
          ],
          method: 'single_agent',
          team_id: null,
          user_ids: [],
          route_type: '',
          meeting_type: null,
          follow_up_email: false,
          text_message_reminders: false
        },
        {
          id: 'a96381f9-0e66-497e-b027-071f012fdce0',
          top: 3220,
          body: '<p><span style=\'color: rgb(0, 0, 0);\'>Looks like you\'re not ready to book a demo. In the meantime, feel free to checkout ou</span><span style=\'color: rgb(76, 76, 76);\'>r</span><em style=\'color: rgb(76, 76, 76);\'> [Blog, Content link]</em><span style=\'color: rgb(76, 76, 76);\'> page for </span><em style=\'color: rgb(76, 76, 76);\'>[description].</em></p>',
          left: 790,
          type: 'chat',
          ports: [
            {
              id: 'default',
              _reactKey: '38f413e3-46c0-4e20-a9f8-9f960199b736'
            }
          ]
        },
        {
          id: '232a691a-2bc1-4908-aac4-7a671dfc8a61',
          top: 3480,
          body: '',
          left: 790,
          type: 'end_flow',
          ports: []
        },
        {
          id: 'db3fd5e7-30d1-4861-bef8-c199fa890b05',
          top: 3430,
          body: '',
          left: 360,
          tags: [],
          type: 'tag',
          ports: [
            {
              id: 'default',
              _reactKey: 'f4eff366-6e5c-4f54-92be-5c7b9eb744e0'
            }
          ]
        },
        {
          id: '577caead-f670-44ff-baa6-7d6f99cb035a',
          top: 3670,
          body: '<p>Thanks!</p><p><br></p><p>We\'re looking forward to it! If you\'d like to leave comments for the specialist, please type below.</p>',
          left: 360,
          type: 'options',
          ports: [
            {
              id: 'response',
              label: 'Visitor Response',
              _reactKey: '441a72f1-e302-422a-b926-d16136d449da'
            }
          ],
          buttons: [],
          captureTimes: 3,
          mapAttribute: false,
          responseType: 'free_response',
          keywordOptions: [],
          secondResponse: 'Still didn\'t get that. Go ahead and try again',
          invalidResponse: 'Sorry, I didn\'t understand that. Please try again'
        },
        {
          id: '9741ee86-6061-4307-8bbd-fb64a2f20732',
          top: 3940,
          body: '',
          left: 360,
          type: 'end_flow',
          ports: []
        }
      ],
      ports: []
    }
  },
  targeting: {
    triggers: [],
    triggerOn: 'all_visitors',
    useTriggers: false
  },
  frequency: 'every time',
  template: {
    kind: 'flow',
    teams: [
      {
        away: 'Sorry, no one is available to chat right now',
        label: 'Click here',
        method: 'single_agent',
        teamId: null,
        welcome: 'Routing you to an agent now',
        userIds: [],
        routeMethod: 'round_robin'
      }
    ],
    botDelay: 0,
    urlMatches: '',
    welcomeMessage: 'Welcome to our site!',
    availabilityCriteria: 'both'
  },
  promptStyle: 'big'
}

export default retargetingFlow
