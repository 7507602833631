import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import InfoIcon from '@material-ui/icons/Info'
import CloseIcon from '@material-ui/icons/Close'
import { amber, green } from '@material-ui/core/colors'
import IconButton, { IconButtonProps } from '@material-ui/core/IconButton'
import Snackbar, { SnackbarProps } from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import WarningIcon from '@material-ui/icons/Warning'
import { makeStyles } from '@material-ui/core/styles'
import { white } from 'material-ui/styles/colors'
import Link from '@material-ui/core/Link'
import { Button } from 'library/materialUI'
import { SessionContext } from 'session-context'

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
}

const useStyles = makeStyles(theme => ({
  success: {
    backgroundColor: green[600]
  },
  link: {
    color: white
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  info: {
    backgroundColor: theme.palette.primary.main
  },
  warning: {
    backgroundColor: amber[700]
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  message: {
    display: 'flex',
    alignItems: 'center'
  },
  snackbar: {
    marginLeft: theme.spacing(7)
  },
  snackbarMessage: {
    display: 'flex',
    flexDirection: 'column'
  },
  snackbarMessageTitle: {
    fontSize: 18
  }
}))

function MySnackbarContentWrapper (props: {
  className?: string
  message: string
  link?: string
  detail?: string
  onClose: IconButtonProps['onClick']
  variant: keyof typeof variantIcon
} & Record<string, any>): JSX.Element {
  const classes = useStyles()
  const { className, message, link, onClose, variant, detail, requestID, requestURL, status, ...other } = props
  const Icon = variantIcon[variant]
  const { openBugModal } = useContext(SessionContext)

  return (
    <SnackbarContent
      className={clsx(classes[variant], className)}
      aria-describedby='client-snackbar'
      message={
        <>
          {link ? (
            <Link
              className={classes.link}
              underline='none'
              target='_blank'
              href={link} rel='noreferrer'
            >
              <div id='client-snackbar' className={classes.message}>
                <Icon className={clsx(classes.icon, classes.iconVariant)} />
                <div className={classes.snackbarMessage}>
                  <div className={classes.snackbarMessageTitle}>{message}</div>
                  {detail ? <div>{detail}</div> : <></>}
                </div>
              </div>
            </Link>)
            : (
              <div id='client-snackbar' className={classes.message}>
                <Icon className={clsx(classes.icon, classes.iconVariant)} />
                <div className={classes.snackbarMessage}>
                  <div className={classes.snackbarMessageTitle}>{message}</div>
                  {detail ? <div>{detail}</div> : <></>}
                </div>
              </div>)}
          {variant === 'error' && (status !== 409 && status !== 401 && status !== 400) && (
            <Button
              variant='outlined'
              color='inherit'
              onClick={(e: any) => {
                openBugModal(requestID, message, requestURL)
                if (onClose) {
                  onClose(e)
                }
              }}
            >
              Report Bug
            </Button>)}
        </>
      }
      action={[
        <IconButton
          key='close'
          aria-label='close'
          color='inherit'
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>
      ]}
      {...other}
    />
  )
}

MySnackbarContentWrapper.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired
}

export interface SnackState {
  open: boolean;
  variant?: 'error' | 'info' | 'success' | 'warning';
  message?: string;
  detail?: string;
  err?: string;
  link?: string;
  requestID?: string;
  requestURL?: string;
  status?: number;
}

export default function CustomizedSnackbars<T extends SnackState> (props: {
  state: T
  handler: (state: T) => void
  autoHideDuration?: SnackbarProps['autoHideDuration']
}): JSX.Element {
  const classes = useStyles()
  const handleClose: SnackbarProps['onClose'] = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    props.handler({ ...props.state, open: false })
  }
  let msg = props.state.message || ''
  const err = props.state.err || ''
  let variant = props.state.variant || 'success'
  if (err !== '') {
    variant = 'error'
    const parts = err.split(':|:')
    if (parts.length === 2 && parts[1]) {
      msg += ' ' + parts[1]
    } else {
      switch (parts[0]) {
        case 'not_found':
          msg += 'The requested resource was not found on the server.'
          break
        case 'unprocessable_entity':
          msg += 'The request sent to the server was invalid.'
          break
        case 'bad_request':
          msg += 'The request sent to the server was invalid.'
          break
        case 'unauthorized':
          msg += 'The requested resource is not accessible, you may need to log in again.'
          break
        case 'forbidden':
          msg += 'The requested resource is not accessible with your current credentials.'
          break
        case 'not_implemented':
          msg += 'The requested resource is not implemented by the server.'
          break
        case 'bad_gateway':
          msg += 'One of the servers dependencies was misbehaving, please try again.'
          break
        case 'gateway_timeout':
          msg += 'One of the servers dependencies took too long to respond. Please try again.'
          break
        case 'system_error':
          msg += 'There was an unexpected error on the server. Please try again.'
          break
        default:
          msg = msg || 'There was an unexpected error. Please try again.'
          break
      }
    }
  }

  return (
    <Snackbar
      className={classes.snackbar}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      open={props.state.open}
      autoHideDuration={props.state.variant !== 'error' ? props.autoHideDuration || 5000 : null}
      onClose={handleClose}
      disableWindowBlurListener
    >
      <MySnackbarContentWrapper
        onClose={e => handleClose(e, 'timeout')}
        variant={variant}
        message={msg}
        detail={props.state.detail}
        link={props.state.link || ''}
        requestID={props.state.requestID || '0'}
        requestURL={props.state.requestURL || ''}
        status={props.state.status || 0}
      />
    </Snackbar>
  )
}
