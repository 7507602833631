import { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import BotPreview from '../../bot/BotPreview'
import { TwitterPicker } from 'react-color'
import { Divider, TextField, InputAdornment } from '@material-ui/core'
import DefaultChatSize from 'img/default_chat_size.png'
import FullChatSize from 'img/full_screen_chat_size.png'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { getParticipant } from 'api/participants'
import { getChatPromptIconUrl } from 'api/chat_settings'
import axios from 'axios'
import EditIcon from '@material-ui/icons/Edit'
import { PromptIconSelector, BotIconSelector } from './Avatars/AvatarSettingsSection'
import StyleCard from 'library/cards/StyleCard'
import { LabeledCheckbox, Radio } from 'library/materialUI'

const useStyles = makeStyles(theme => ({
  appearancePage: {
    display: 'grid',
    gridTemplateColumns: 'auto 420px',
    fontFamily: 'Poppins, sans serif'
  },
  previewDiv: {
    position: 'sticky',
    top: 20,
    padding: 20,
    paddingTop: 0,
    minHeight: 700
  },
  mainSettings: {
    padding: 10,
    borderRadius: 10,
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: 15
  },
  textDiv: {
    display: 'grid',
    gridTemplateColumns: '2fr 3fr',
    gridGap: 15
  },
  mainSettingsSmall: {
    padding: 10,
    borderRadius: 10,
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridGap: 15,
    height: 700,
    overflow: 'scroll'
  },
  card: {
    borderRadius: 15,
    height: 'auto',
    backgroundColor: 'rgba(142, 90, 226, 0.04)',
    minWidth: 300
  },
  cardHeader: {
    backgroundColor: 'rgba(142, 90, 226, 0.15)',
    color: '#8e5ae2',
    padding: 12
  },
  cardHeaderTitle: {
    display: 'flex',
    justifyContent: 'center',
    color: '#8e5ae2',
    fontSize: '1.2em'
  },
  colorPickerDiv: {
    width: 276,
    marginTop: 10
  },
  input: {
    margin: '16px 0px'
  },
  sizePickerContainer: {
    borderRadius: 5,
    cursor: 'pointer',
    padding: 10,
    border: '1px solid #D3D3D3;'
  },
  sizePickerContainerSelected: {
    borderRadius: 5,
    cursor: 'pointer',
    padding: 10,
    border: '1px solid #9933FF;'
  }
}))

function HexToRGB (hex) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null
}

function RGBToBlackOrWhite (rgb) {
  const intensity = rgb.r * 0.299 + rgb.g * 0.587 + rgb.b * 0.114
  if (intensity > 186) {
    return '000000'
  } else {
    return 'ffffff'
  }
}

function EditableName (props) {
  const classes = useStyles()

  return (
    <div className={classes.input}>
      <TextField
        variant='outlined'
        fullWidth
        size='small'
        value={props.name}
        onChange={e => props.editName(e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <EditIcon style={{ pointerEvents: 'none' }} />
            </InputAdornment>
          )
        }}
      />
    </div>
  )
}

function AppearancePage ({ state, dispatch, botParticipantID, chatServiceUrl }) {
  const classes = useStyles()
  const [botParticipant, setBotParticipant] = useState(null)
  const participantID = botParticipantID
  const smallScreen = useMediaQuery('(max-width: 1500px)')
  const previewHeight = 700

  useEffect(() => {
    if (participantID) {
      getParticipant({ participantID, chatServiceUrl })
        .then(response => {
          setBotParticipant(response)
        })
    }
  }, [chatServiceUrl, participantID])
  if (!(state && botParticipant)) {
    return <></>
  }
  function uploadNewPhoto ({ image, contentType }) {
    const includes = ['avatar_upload_url']
    getParticipant({ participantID, chatServiceUrl, includes })
      .then(botResponse => {
        const uploadURL = contentType === 'image/svg+xml' ? botResponse.meta.avatar_upload_url_svg : botResponse.meta.avatar_upload_url_png
        const avatarURL = uploadURL.split('?')[0]
        axios.put(uploadURL, image, {
          headers: { 'Content-type': contentType }
        }).then(response => {
          if (response.status === 200) {
            dispatch({ type: 'updateSetting', name: 'bot_icon_url', value: avatarURL })
          }
        })
      })
  }
  function uploadNewPromptIconPhoto ({ image, contentType }) {
    getChatPromptIconUrl()
      .then(res => {
        const uploadURL = contentType === 'image/svg+xml' ? res.prompt_icon_upload_url_svg : res.prompt_icon_upload_url_png
        const promptIconBlobName = res.prompt_icon_blob_name
        axios.put(uploadURL, image, {
          headers: { 'Content-type': contentType }
        }).then(res => {
          if (res.status === 200) {
            dispatch({ type: 'updateSetting', name: 'prompt_icon_url', value: promptIconBlobName })
          }
        })
      })
  }
  const buttonColor = state.attributes.button_color || state.attributes.primary_color

  return (
    <div className={classes.appearancePage}>
      <div className={smallScreen ? classes.mainSettingsSmall : classes.mainSettings} style={{ gridTemplateColumns: smallScreen ? '1fr' : '1fr 1fr', height: '100%' }}>
        <StyleCard
          title='Customize Your Bot'
          defaultExpanded
        >
          <div style={{ width: '100%' }}>
            <div style={{ fontWeight: 600 }}>
              Change bot name
            </div>
            <EditableName name={state.attributes.bot_name} editName={(value) => dispatch({ type: 'updateSetting', name: 'bot_name', value: value })} />
            <BotIconSelector
              state={state}
              dispatch={dispatch}
              uploadNewPhoto={uploadNewPhoto}
            />
          </div>
        </StyleCard>
        <StyleCard
          title='Select Prompt Icon'
          defaultExpanded
        >
          <PromptIconSelector
            state={state}
            dispatch={dispatch}
            uploadNewPhoto={uploadNewPromptIconPhoto}
          />
        </StyleCard>
        <StyleCard
          title='Chat Color'
          defaultExpanded
        >
          <div>
            <div style={{ fontWeight: 600 }}>
              Primary Chat Color
            </div>
            <Divider />
            <div className={classes.colorPickerDiv}>
              <TwitterPicker
                triangle='hide'
                color={state.attributes.primary_color}
                onChange={(color) => {
                  dispatch({ type: 'updateSetting', name: 'primary_color', value: color?.hex?.substring(1) })
                  const rgb = HexToRGB(color?.hex?.substring(1))
                  const textColor = RGBToBlackOrWhite(rgb)
                  dispatch({ type: 'updateSetting', name: 'contrast_color', value: textColor })
                  if (state.attributes.button_color_same_as_primary) {
                    dispatch({ type: 'updateSetting', name: 'button_color', value: color?.hex?.substring(1) })
                  }
                }}
              />
            </div>
            <div className={classes.textDiv}>
              <div style={{ fontWeight: 600, marginTop: 20 }}>
                Button Text Color
              </div>
            </div>
            <Divider />
            <div className={classes.colorPickerDiv}>
              <TwitterPicker
                triangle='hide'
                color={buttonColor}
                colors={['#FF6900', '#FCB900', '#7BDCB5', '#00D084', '#8ED1FC', '#0693E3', '#ABB8C3', '#EB144C', '#F78DA7', '#000000']}
                onChange={(color) => {
                  dispatch({ type: 'updateSetting', name: 'button_color_same_as_primary', value: false })
                  dispatch({ type: 'updateSetting', name: 'button_color', value: color?.hex?.substring(1) })
                }}
              />
            </div>
            <div style={{ marginTop: 20 }}>
              <LabeledCheckbox
                checked={state.attributes.button_color_same_as_primary}
                onChange={(checked) => {
                  if (checked) {
                    dispatch({ type: 'updateSetting', name: 'button_color', value: state.attributes.primary_color })
                  }
                  dispatch({ type: 'updateSetting', name: 'button_color_same_as_primary', value: checked })
                }}
                label='Same as Primary Chat Color'
              />
            </div>
          </div>
        </StyleCard>
        <StyleCard
          title='Chat Size'
          defaultExpanded
        >
          <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridGap: 15, marginTop: 10 }}>
            <div
              className={state.attributes.chat_size === 'default' ? classes.sizePickerContainerSelected : classes.sizePickerContainer}
              onClick={() => dispatch({ type: 'updateSetting', name: 'chat_size', value: 'default' })}
            >
              <div style={{ marginLeft: 7, marginBottom: 10 }}>
                <Radio
                  label='Default'
                  checked={state.attributes.chat_size === 'default'}
                />
              </div>
              <div>
                <div style={{ margin: 'auto', width: 200 }}>
                  <img
                    alt='Default Chat Size Preview'
                    src={DefaultChatSize}
                    height={150}
                    width={200}
                  />
                </div>
              </div>
            </div>
            <div
              className={state.attributes.chat_size === 'fullscreen' ? classes.sizePickerContainerSelected : classes.sizePickerContainer}
              onClick={() => dispatch({ type: 'updateSetting', name: 'chat_size', value: 'fullscreen' })}
            >
              <div style={{ marginLeft: 7, marginBottom: 10 }}>
                <Radio
                  label='Full Screen'
                  checked={state.attributes.chat_size === 'fullscreen'}
                />
              </div>
              <div>
                <div style={{ margin: 'auto', width: 200 }}>
                  <img
                    alt='Full Screen Preview'
                    src={FullChatSize}
                    height={150}
                    width={200}
                  />
                </div>
              </div>
            </div>
          </div>
        </StyleCard>
        <StyleCard
          title='Branding'
          defaultExpanded
        >
          <div style={{ padding: '15px 0px' }}>
            <LabeledCheckbox
              checked={state.attributes.branding}
              onChange={(checked) => dispatch({ type: 'updateSetting', name: 'branding', value: checked })}
              label='Display Signals Branding'
            />
          </div>
        </StyleCard>
      </div>
      <div className={classes.previewDiv} style={{ height: previewHeight }}>
        <BotPreview
          message="Hi there! I'm a chatbot. Do you have any questions I can help you with?"
          primaryColor={'#' + state.attributes.primary_color}
          contrastColor={'#' + state.attributes.contrast_color}
          buttonColor={'#' + (state.attributes.button_color || state.attributes.primary_color)}
          agentName={state.attributes.bot_name}
          showChatIcon // {chat_size === 'default'}
          iconID={state.attributes.prompt_icon_id}
          avatarUrl={state.attributes.bot_icon_url}
          promptIconUrl={state.attributes.prompt_icon_url}
          branding={state.attributes.branding}
          botParticipant={botParticipant}
          router
          buttons={[{ label: 'Free trial' }, { label: 'Chat with an expert' }, { label: 'Learn more' }, { label: 'Just browsing' }]}
        />
      </div>
    </div>
  )
}

export default AppearancePage
