import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListSubheader from '@material-ui/core/ListSubheader'
import Collapse from '@material-ui/core/Collapse'
import { useState } from 'react'
import { ListItem } from '@material-ui/core'
import AccessControl from 'components/AccessControl'
import { Perms } from 'session-context'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    minWidth: 230,
    overflow: 'auto',
    fontFamily: 'Poppins, sans serif'
  },
  nested: {
    paddingLeft: theme.spacing(5)
  },
  itemHeader: {
    display: 'flex',
    alignItems: 'center'
  },
  listItemSelected: {
    fontSize: '15px',
    color: '#2A2A2A',
    fontWeight: 600
  },
  listItemUnselected: {
    fontSize: '15px',
    color: '#7A7A7A'
  },
  nav: {
    width: 230,
    borderRightStyle: 'solid',
    borderRightWidth: '1px',
    borderColor: '#d3d3d3'
  }
}))

export interface SubSections {
  [key: string]: Section[]
}

export interface Section {
  key: string
  sectionName: string
  sectionPerms?: keyof Perms
  action: (key: string) => void
}

interface SectionProps {
  selected: boolean
  sectionName: string
  onClick: () => void
}

interface SimpleNavBarProps {
  navTitle: string
  menu: SubSections
  startSection?: string
}

function SectionComponent ({ selected, sectionName, onClick }: SectionProps): JSX.Element {
  const classes = useStyles()
  return (
    <div>
      <ListItem
        button
        onClick={() => onClick()}
        className={classes.nested}
      >
        <div className={selected ? classes.listItemSelected : classes.listItemUnselected}>
          {sectionName}
        </div>
      </ListItem>
    </div>
  )
}

export default function SimpleNavBar ({ navTitle, menu, startSection }: SimpleNavBarProps): JSX.Element {
  const classes = useStyles()

  const [openSections, setOpenSections] = useState<string[]>(Object.keys(menu))
  const [currentSection, setCurrentSection] = useState<string>(startSection || openSections[0])

  function handleSubSectionClick (sectionName: string) {
    if (openSections.includes(sectionName)) {
      setOpenSections(openSections.filter(section => section !== sectionName))
    } else {
      setOpenSections([...openSections, sectionName])
    }
  }

  function handleNavClick (sectionKey: string) {
    setCurrentSection(sectionKey)
  }

  return (
    <div className={classes.nav}>
      <List
        component='nav'
        aria-labelledby='nested-list-subheader'
        disablePadding
        subheader={
          <ListSubheader component='div' id='nested-list-subheader' disableSticky>
            {navTitle}
          </ListSubheader>
        }
        className={classes.root}
      >
        {menu && Object.keys(menu).map((key, index) => (
          <div key={index}>
            <ListItem
              button
              onClick={() => handleSubSectionClick(key)}
            >
              <div className={classes.itemHeader}>
                {key}
              </div>
            </ListItem>
            <Collapse in={openSections.includes(key)} timeout='auto' unmountOnExit>
              <List component='div' disablePadding>
                {menu[key].map((subSection, index) => (
                  subSection.sectionPerms ? (
                    <AccessControl requiredPermission={subSection.sectionPerms} key={index}>
                      <SectionComponent
                        selected={subSection.key === currentSection}
                        sectionName={subSection.sectionName}
                        key={'menu-perm-' + subSection.sectionName + '-' + index}
                        onClick={() => {
                          handleNavClick(subSection.key)
                          subSection.action(subSection.key)
                        }}
                      />
                    </AccessControl>
                  ) : (
                    <SectionComponent
                      key={'menu-no-perm-' + subSection.sectionName + '-' + index}
                      selected={subSection.key === currentSection}
                      sectionName={subSection.sectionName}
                      onClick={() => {
                        handleNavClick(subSection.key)
                        subSection.action(subSection.key)
                      }}
                    />
                  )
                ))}
              </List>
            </Collapse>
          </div>
        ))}
      </List>
    </div>
  )
}
