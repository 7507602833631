import { TreeChild, mappingReducer } from 'cf-components/TreeMultiSelect'

export const departments: TreeChild[] = [
  {
    label: 'C-Suite',
    value: 'c_suite',
    children: [
      { label: 'Executive', value: 'executive' },
      { label: 'Finance Executive', value: 'finance_executive' },
      { label: 'Founder', value: 'founder' },
      { label: 'Human Resources Executive', value: 'human_resources_executive' },
      { label: 'Information Technology Executive', value: 'information_technology_executive' },
      { label: 'Legal Executive', value: 'legal_executive' },
      { label: 'Marketing Executive', value: 'marketing_executive' },
      { label: 'Medical & Health Executive', value: 'medical_health_executive' },
      { label: 'Operations Executive', value: 'operations_executive' },
      { label: 'Sales Executive', value: 'sales_executive' }
    ]
  },
  {
    label: 'Engineering & Technical',
    value: 'master_engineering_technical',
    children: [
      { label: 'Artificial Intelligence / Machine Learning', value: 'artificial_intelligence_machine_learning' },
      { label: 'Bioengineering', value: 'bioengineering' },
      { label: 'Biometrics', value: 'biometrics' },
      { label: 'Business Intelligence', value: 'business_intelligence' },
      { label: 'Chemical Engineering', value: 'chemical_engineering' },
      { label: 'Cloud / Mobility', value: 'cloud_mobility' },
      { label: 'Data Science', value: 'data_science' },
      { label: 'DevOps', value: 'devops' },
      { label: 'Digital Transformation', value: 'digital_transformation' },
      { label: 'Emerging Technology / Innovation', value: 'emerging_technology_innovation' },
      { label: 'Engineering & Technical', value: 'engineering_technical' },
      { label: 'Industrial Engineering', value: 'industrial_engineering' },
      { label: 'Mechanic', value: 'mechanic' },
      { label: 'Mobile Development', value: 'mobile_development' },
      { label: 'Product Development', value: 'product_development' },
      { label: 'Product Management', value: 'product_mangement' }, /* Yes i know it's misspelled. but apollo incorrectly setup a few like this for some reason. Doesn't work if you spell it correctly lol. */
      { label: 'Project Management', value: 'project_management' },
      { label: 'Research & Development', value: 'research_development' },
      { label: 'Scrum Master / Agile Coach', value: 'scrum_master_agile_coach' },
      { label: 'Software Development', value: 'software_development' },
      { label: 'Support / Technical Services', value: 'support_technical_services' },
      { label: 'Technician', value: 'technician' },
      { label: 'Technology Operations', value: 'technology_operations' },
      { label: 'Test / Quality Assurance', value: 'test_quality_assurance' },
      { label: 'UI / UX', value: 'ui_ux' },
      { label: 'Web Development', value: 'web_development' }
    ]
  },
  {
    label: 'Design',
    value: 'design',
    children: [
      { label: 'All Design', value: 'all_design' },
      { label: 'Product or UI/UX Design', value: 'product_ui_ux_design' },
      { label: 'Graphic / Visual / Brand Design', value: 'graphic_design' }
    ]
  },
  {
    label: 'Education',
    value: 'education',
    children: [
      { label: 'Teacher', value: 'teacher' },
      { label: 'Principal', value: 'principal' },
      { label: 'Superintendent', value: 'superintendent' },
      { label: 'Professor', value: 'professor' }
    ]
  },
  {
    label: 'Finance',
    value: 'master_finance',
    children: [
      { label: 'Accounting', value: 'accounting' },
      { label: 'Finance', value: 'finance' },
      { label: 'Financial Planning & Analysis', value: 'financial_planning_analysis' },
      { label: 'Financial Reporting', value: 'financial_reporting' },
      { label: 'Financial Strategy', value: 'financial_strategy' },
      { label: 'Financial Systems', value: 'financial_systems' },
      { label: 'Internal Audit & Control', value: 'internal_audit_control' },
      { label: 'Investor Relations', value: 'investor_relations' },
      { label: 'Mergers & Acquisitions', value: 'mergers_acquisitions' },
      { label: 'Real Estate Finance', value: 'real_estate_finance' },
      { label: 'Financial Risk', value: 'financial_risk' },
      { label: 'Shared Services', value: 'shared_services' },
      { label: 'Sourcing / Procurement', value: 'sourcing_procurement' },
      { label: 'Tax', value: 'tax' },
      { label: 'Treasury', value: 'treasury' }
    ]
  },
  {
    label: 'Human Resources',
    value: 'master_human_resources',
    children: [
      { label: 'Compensation & Benefits', value: 'compensation_benefits' },
      { label: 'Culture, Diversity & Inclusion', value: 'culture_diversity_inclusion' },
      { label: 'Employee & Labor Relations', value: 'employee_labor_relations' },
      { label: 'Health & Safety', value: 'health_safety' },
      { label: 'Human Resource Information System', value: 'human_resource_information_system' },
      { label: 'Human Resources', value: 'human_resources' },
      { label: 'HR Business Partner', value: 'hr_business_partner' },
      { label: 'Learning & Development', value: 'learning_development' },
      { label: 'Organizational Development', value: 'organizational_development' },
      { label: 'Recruiting & Talent Acquisition', value: 'recruiting_talent_acquisition' },
      { label: 'Talent Management', value: 'talent_management' },
      { label: 'Workforce Management', value: 'workforce_mangement' }, /* Yes i know it's misspelled. but apollo incorrectly setup a few like this for some reason. Doesn't work if you spell it correctly lol. */
      { label: 'People Operations', value: 'people_operations' }
    ]
  },
  {
    label: 'Information Technology',
    value: 'master_information_technology',
    children: [
      {
        label: 'Application Development',
        value: 'application_development'
      },
      {
        label: 'Business Service Management / ITSM',
        value: 'business_service_management_itsm'
      },
      { label: 'Collaboration & Web App', value: 'collaboration_web_app' },
      { label: 'Data Center', value: 'data_center' },
      { label: 'Data Warehouse', value: 'data_warehouse' },
      {
        label: 'Database Administration',
        value: 'database_administration'
      },
      { label: 'eCommerce Development', value: 'ecommerce_development' },
      {
        label: 'Enterprise Architecture',
        value: 'enterprise_architecture'
      },
      {
        label: 'Help Desk / Desktop Services',
        value: 'help_desk_desktop_services'
      },
      {
        label: 'HR / Financial / ERP Systems',
        value: 'hr_financial_erp_systems'
      },
      { label: 'Information Security', value: 'information_security' },
      { label: 'Information Technology', value: 'information_technology' },
      { label: 'Infrastructure', value: 'infrastructure' },
      { label: 'IT Asset Management', value: 'it_asset_management' },
      {
        label: 'IT Audit / IT Compliance',
        value: 'it_audit_it_compliance'
      },
      { label: 'IT Operations', value: 'it_operations' },
      { label: 'IT Procurement', value: 'it_procurement' },
      { label: 'IT Strategy', value: 'it_strategy' },
      { label: 'IT Training', value: 'it_training' },
      { label: 'Networking', value: 'networking' },
      {
        label: 'Project & Program Management',
        value: 'project_program_management'
      },
      { label: 'Quality Assurance', value: 'quality_assurance' },
      { label: 'Retail / Store Systems', value: 'retail_store_systems' },
      { label: 'Servers', value: 'servers' },
      {
        label: 'Storage & Disaster Recovery',
        value: 'storage_disaster_recovery'
      },
      { label: 'Telecommunications', value: 'telecommunications' },
      { label: 'Virtualization', value: 'virtualization' }
    ]
  },
  {
    label: 'Legal',
    value: 'master_legal',
    children: [
      { label: 'Acquisitions', value: 'acquisitions' },
      { label: 'Compliance', value: 'compliance' },
      { label: 'Contracts', value: 'contracts' },
      { label: 'Corporate Secretary', value: 'corporate_secretary' },
      { label: 'eDiscovery', value: 'ediscovery' },
      { label: 'Ethics', value: 'ethics' },
      { label: 'Governance', value: 'governance' },
      {
        label: 'Governmental Affairs & Regulatory Law',
        value: 'governmental_affairs_regulatory_law'
      },
      {
        label: 'Intellectual Property & Patent',
        value: 'intellectual_property_patent'
      },
      { label: 'Labor & Employment', value: 'labor_employment' },
      { label: 'Lawyer / Attorney', value: 'lawyer_attorney' },
      { label: 'Legal', value: 'legal' },
      { label: 'Legal Counsel', value: 'legal_counsel' },
      { label: 'Legal Operations', value: 'legal_operations' },
      { label: 'Litigation', value: 'litigation' },
      { label: 'Privacy', value: 'privacy' }
    ]
  },
  {
    label: 'Marketing',
    value: 'master_marketing',
    children: [
      { label: 'Advertising', value: 'advertising' },
      { label: 'Brand Management', value: 'brand_management' },
      { label: 'Content Marketing', value: 'content_marketing' },
      { label: 'Customer Experience', value: 'customer_experience' },
      { label: 'Customer Marketing', value: 'customer_marketing' },
      { label: 'Demand Generation', value: 'demand_generation' },
      { label: 'Digital Marketing', value: 'digital_marketing' },
      { label: 'eCommerce Marketing', value: 'ecommerce_marketing' },
      { label: 'Event Marketing', value: 'event_marketing' },
      { label: 'Field Marketing', value: 'field_marketing' },
      { label: 'Lead Generation', value: 'lead_generation' },
      { label: 'Marketing', value: 'marketing' },
      {
        label: 'Marketing Analytics / Insights',
        value: 'marketing_analytics_insights'
      },
      {
        label: 'Marketing Communications',
        value: 'marketing_communications'
      },
      { label: 'Marketing Operations', value: 'marketing_operations' },
      { label: 'Product Marketing', value: 'product_marketing' },
      { label: 'Public Relations', value: 'public_relations' },
      {
        label: 'Search Engine Optimization / Pay Per Click',
        value: 'search_engine_optimization_pay_per_click'
      },
      { label: 'Social Media Marketing', value: 'social_media_marketing' },
      {
        label: 'Strategic Communications',
        value: 'strategic_communications'
      },
      { label: 'Technical Marketing', value: 'technical_marketing' }
    ]
  },
  {
    label: 'Medical & Health',
    value: 'medical_health',
    children: [
      { label: 'Anesthesiology', value: 'anesthesiology' },
      { label: 'Chiropractics', value: 'chiropractics' },
      { label: 'Clinical Systems', value: 'clinical_systems' },
      { label: 'Dentistry', value: 'dentistry' },
      { label: 'Dermatology', value: 'dermatology' },
      { label: 'Doctors / Physicians', value: 'doctors_physicians' },
      { label: 'Epidemiology', value: 'epidemiology' },
      { label: 'First Responder', value: 'first_responder' },
      { label: 'Infectious Disease', value: 'infectious_disease' },
      { label: 'Medical Administration', value: 'medical_administration' },
      {
        label: 'Medical Education & Training',
        value: 'medical_education_training'
      },
      { label: 'Medical Research', value: 'medical_research' },
      { label: 'Medicine', value: 'medicine' },
      { label: 'Neurology', value: 'neurology' },
      { label: 'Nursing', value: 'nursing' },
      { label: 'Nutrition & Dietetics', value: 'nutrition_dietetics' },
      { label: 'Obstetrics / Gynecology', value: 'obstetrics_gynecology' },
      { label: 'Oncology', value: 'oncology' },
      { label: 'Ophthalmology', value: 'opthalmology' }, /* Yes i know it's misspelled. but apollo incorrectly setup a few like this for some reason. Doesn't work if you spell it correctly lol. */
      { label: 'Optometry', value: 'optometry' },
      { label: 'Orthopedics', value: 'orthopedics' },
      { label: 'Pathology', value: 'pathology' },
      { label: 'Pediatrics', value: 'pediatrics' },
      { label: 'Pharmacy', value: 'pharmacy' },
      { label: 'Physical Therapy', value: 'physical_therapy' },
      { label: 'Psychiatry', value: 'psychiatry' },
      { label: 'Psychology', value: 'psychology' },
      { label: 'Public Health', value: 'public_health' },
      { label: 'Radiology', value: 'radiology' },
      { label: 'Social Work', value: 'social_work' }
    ]
  },
  {
    label: 'Operations',
    value: 'master_operations',
    children: [
      { label: 'Call Center', value: 'call_center' },
      { label: 'Construction', value: 'construction' },
      { label: 'Corporate Strategy', value: 'corporate_strategy' },
      {
        label: 'Customer Service / Support',
        value: 'customer_service_support'
      },
      {
        label: 'Enterprise Resource Planning',
        value: 'enterprise_resource_planning'
      },
      { label: 'Facilities Management', value: 'facilities_management' },
      { label: 'Leasing', value: 'leasing' },
      { label: 'Logistics', value: 'logistics' },
      { label: 'Office Operations', value: 'office_operations' },
      { label: 'Operations', value: 'operations' },
      { label: 'Physical Security', value: 'physical_security' },
      { label: 'Project Development', value: 'project_development' },
      { label: 'Quality Management', value: 'quality_management' },
      { label: 'Real Estate', value: 'real_estate' },
      { label: 'Safety', value: 'safety' },
      { label: 'Store Operations', value: 'store_operations' },
      { label: 'Supply Chain', value: 'supply_chain' }
    ]
  },
  {
    label: 'Sales',
    value: 'master_sales',
    children: [
      { label: 'Account Management', value: 'account_management' },
      { label: 'Business Development', value: 'business_development' },
      { label: 'Channel Sales', value: 'channel_sales' },
      {
        label: 'Customer Retention & Development',
        value: 'customer_retention_development'
      },
      { label: 'Customer Success', value: 'customer_success' },
      { label: 'Field / Outside Sales', value: 'field_outside_sales' },
      { label: 'Inside Sales', value: 'inside_sales' },
      { label: 'Partnerships', value: 'partnerships' },
      { label: 'Revenue Operations', value: 'revenue_operations' },
      { label: 'Sales', value: 'sales' },
      { label: 'Sales Enablement', value: 'sales_enablement' },
      { label: 'Sales Engineering', value: 'sales_engineering' },
      { label: 'Sales Operations', value: 'sales_operations' },
      { label: 'Sales Training', value: 'sales_training' }
    ]
  },
  {
    label: 'Consulting',
    value: 'consulting',
    children: [
      { label: 'Consultant', value: 'consultant' }
    ]
  }
]

export const departmentMappings = mappingReducer(departments)

export const personSeniorities: TreeChild[] = [
  { value: 'owner', label: 'Owner' },
  { value: 'founder', label: 'Founder' },
  { value: 'c_suite', label: 'C-Suite' },
  { value: 'partner', label: 'Partner' },
  { value: 'vp', label: 'VP' },
  { value: 'head', label: 'Head' },
  { value: 'director', label: 'Director' },
  { value: 'manager', label: 'Manager' },
  { value: 'senior', label: 'Senior' },
  { value: 'entry', label: 'Entry' },
  { value: 'intern', label: 'Intern' }
]

export const seniorityMappings = mappingReducer(personSeniorities)
