import { makeStyles } from '@material-ui/core/styles'
import Loader from 'library/loading/Loader'
import { List, ListItem, Typography } from 'library/materialUI'
import CompanyItem from './CompanyItem'
import { CompanyRow, CSVFileAttributes } from './CSVUploadModal'

const useStyles = makeStyles((theme) => ({
  rowCount: {
    color: theme.palette.primary.main,
    textAlign: 'center'
  },
  list: {
    maxHeight: 410,
    overflowY: 'scroll'
  }
}))

interface CSVCompanyListProps {
  rows: CompanyRow[]
  rowsLeft: number
  // setCSVFileAttributes: (any: any) => void
  overrides: boolean
  rowsWithConflicts: CSVFileAttributes['rowsWithConflicts']
}

export default function CSVCompanyList ({ rows, rowsLeft, overrides, rowsWithConflicts } : CSVCompanyListProps) {
  const classes = useStyles()

  // const handleAccountOwnerChange = (row: CompanyRow) => {
  //   const newCompanyRows = Object.assign(rows, row)
  //   setCSVFileAttributes({ rows: newCompanyRows })
  // }

  if (rows.length === 0 || rows === undefined) {
    return (
      <div style={{ height: 410, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Loader type='spinner' />
      </div>
    )
  }

  return (
    <List
      className={classes.list}
    >
      {rows.map((company, index) => {
        return (
          <ListItem
            key={`${company.name}_${index}`}
          >
            <CompanyItem
              company={company}
              index={index}
              overrides={overrides}
              rowsWithConflicts={rowsWithConflicts}
              // setCSVFileAttributes={(changes) => setCSVFileAttributes(changes)}
              // handleAccountOwnerChange={(companyRow) => handleAccountOwnerChange(companyRow)}
            />
          </ListItem>
        )
      })}
      <Typography className={classes.rowCount}>+ {rowsLeft} more</Typography>
    </List>
  )
}
