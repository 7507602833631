const headers = {
  'Content-Type': 'application/vnd.api+json'
}

const getNotifications = () => {
  return fetch('/api/notifications', {
    method: 'GET',
    headers: headers,
    cache: 'no-store'
  })
    .then(response => response.json())
}

const getAllNotifications = ({ page, pageSize }) => {
  const params = {}
  const getUrl = window.location
  const baseUrl = getUrl.protocol + '//' + getUrl.host + '/' + getUrl.pathname.split('/')[1]
  const url = new URL(baseUrl + 'api/historical_notifications?include=dismissed')

  if (page && pageSize) {
    params['page[number]'] = page
    params['page[size]'] = pageSize
  }

  Object.keys(params).forEach(key =>
    url.searchParams.append(key, params[key])

  )

  return fetch(url, {
    method: 'GET',
    headers: headers,
    cache: 'no-store'
  })
    .then(response => response.json())
}

const getHistoricalNotifications = () => {
  return fetch('/api/historical_notifications', {
    method: 'GET',
    headers: headers,
    cache: 'no-store'
  })
    .then(response => response.json())
}

function resolveNotifications ({ chatServiceUrl, ids }) {
  const body = JSON.stringify({
    data: {
      attributes: {
        notification_ids: ids
      }
    }
  })
  return fetch('/api/notifications/bulk_resolve', {
    method: 'POST',
    headers: headers,
    body: body
  })
}

function dismissNotifications ({ chatServiceUrl, ids }) {
  const body = JSON.stringify({
    data: {
      attributes: {
        notification_ids: ids
      }
    }
  })
  return fetch('/api/notifications/bulk_dismiss', {
    method: 'POST',
    headers: headers,
    body: body
  })
}

const dismissNotification = (notification_id) => {
  return fetch(`/api/notifications/${notification_id}/dismiss`, {
    method: 'POST',
    headers: headers
  })
    .then(() => { })
}

const resolveNotification = (notification_id) => {
  return fetch(`/api/notifications/${notification_id}/resolve`, {
    method: 'POST',
    headers: headers
  })
    .then(() => { })
}

export { getNotifications, dismissNotification, resolveNotification, getAllNotifications, getHistoricalNotifications, resolveNotifications, dismissNotifications }
