import { doPatch, doPost } from './api'

const headers = {
  'Content-Type': 'application/vnd.api+json'
}

const getCompanyInfo = () => {
  return fetch('/api/company_profile', {
    method: 'GET',
    headers: headers
  })
    .then(response => response.json())
}

const updateCompanyInfo = (surveyObj) => {
  const method = surveyObj.id ? 'PATCH' : 'POST'
  const path = '/api/company_profile'

  const body = {
    type: 'company_profile',
    attributes: surveyObj.attributes
  }
  if (method === 'PATCH') {
    return doPatch({ path, data: body })
  }

  return doPost({ path, data: body })
}

const logCompanyAttachment = ({ file }) => {
  const method = 'POST'
  const path = '/api/company_profile/attachments'

  const body = {
    data: {
      type: 'company_profile_attachments',
      attributes: {
        file_name: file.name,
        size: file.size,
        media_type: file.type
      }
    }
  }

  return fetch(path, {
    method: method,
    headers: headers,
    body: JSON.stringify(body)
  })
    .then(response => response.json())
}

export { getCompanyInfo, updateCompanyInfo, logCompanyAttachment }
