import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Card, CardContent, Typography, FormControlLabel, Radio, Tooltip, Button } from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'

const useStyles = makeStyles(theme => ({
  selectorCard: {
    width: 550,
    borderRadius: 10,
    margin: 50
  },
  container: {
    display: 'flex',
    justifyContent: 'center'
  },
  cardContentArea: {
    height: '100%',
    backgroundColor: 'rgba(193, 166, 237, 0.1)',
    borderRadius: 10,
    padding: 30,
    paddingTop: 20
  },
  header: {
    backgroundColor: theme.palette.primary.main
  },
  subtitle1: {
    fontSize: 20,
    fontWeight: 600,
    color: 'white',
    padding: 20
  },
  subtitle2: {
    fontSize: 15,
    fontWeight: 600,
    marginBottom: 10
  },
  bodyText: {
    color: '#808080',
    marginBottom: 10,
    fontSize: 15
  },
  radio: {
    display: 'flex',
    width: 130
  },
  infoIcon: {
    marginTop: 'auto',
    marginBottom: 'auto',
    display: 'block'
  },
  nextButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
}))

const SelectDuration = props => {
  const classes = useStyles()
  const state = props.state
  const durationCriteria = props.durationCriteria

  return (
    <>
      <div className={classes.container}>
        <Card className={classes.selectorCard} raised>
          <div className={classes.header}>
            <Typography variant='subtitle1' className={classes.subtitle1}>
              Select Duration
            </Typography>
          </div>
          <CardContent
            className={classes.cardContentArea}
          >
            <Typography variant='body2' className={classes.bodyText}>
              Schedule when your test will begin and end.
            </Typography>
            {durationCriteria.map((duration, index) => (
              <div key={index} style={{ display: 'flex' }}>
                <FormControlLabel
                  control={
                    <Radio
                      color='primary'
                      checked={state.attributes.duration_criteria === duration.value}
                      onChange={
                        e => props.handleChange({ attr: 'duration_criteria', value: duration.value })
                      }
                    />
                  }
                  label={duration.name}
                  className={classes.radio}
                />
                <div className={classes.infoIcon}>
                  <Tooltip title={duration.tooltip} placement='bottom'>
                    <InfoIcon fontSize='small' style={{ verticalAlign: 'sub', color: '#AA88E2' }} />
                  </Tooltip>
                </div>
              </div>
            ))}
            <div className={classes.nextButtonContainer}>
              <Button
                onClick={props.handleNext}
                className={classes.nextButton}
                variant='outlined'
                color='primary'
                disabled={!state.attributes.duration_criteria}
              >
                Next
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>
    </>
  )
}

export default SelectDuration
