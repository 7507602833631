import React, { useState, useEffect } from 'react'
import { getMissingFAQs, FAQMissedTopicExport } from 'api/reports'
import FAQTopicsTable from './FAQTopicsTable'
import { downloadCSV } from 'components/downloadCSV'
import DateFormat from 'components/DateFormat'
import { convertDateRange } from 'cf-components/FilterBar/FilterBar'
import { getSessionDateRange } from 'cf-components/FilterBar/CustomDateRanges'

function FAQTopics (props) {
  let startingDate = getSessionDateRange()
  startingDate = { ...startingDate, startDate: new Date(startingDate.startDate), endDate: new Date(startingDate.endDate) }
  const [dateRange, setDateRange] = useState(startingDate)
  const [topics, setTopics] = useState([])

  useEffect(() => {
    getMissingFAQs({ attributes: convertDateRange(dateRange) }).then(response => {
      setTopics(response.data.attributes.topics)
    })
  }, [dateRange])

  function beginExport () {
    FAQMissedTopicExport({ attributes: convertDateRange(dateRange) }).then(response => {
      const date = new Date().toString()
      const filename = 'FAQ_Stats_' + DateFormat(date, 'isoDate') + '.csv'
      downloadCSV(filename, response)
    })
  }

  const filterOptions = {
    filterValues: [
      { value: 'Date', label: 'Filter by Date', toggle: false }
    ],
    dateRange: dateRange,
    setDateRange: setDateRange
  }

  return (
    <FAQTopicsTable
      rows={topics}
      handleExport={beginExport}
      filterOptions={filterOptions}
    />
  )
}

export default FAQTopics
