import AudioAlert from '../../assets/audio_alert.wav'
import TwoTone from '../../assets/two_tone.wav'
import CashRegister from '../../assets/cash_register.mp3'
import GameShow from '../../assets/game_show_music.mp3'
import Gong from '../../assets/gong.mp3'
import LevelUp from '../../assets/level_up.mp3'
import DecorativeOne from '../../assets/decorative_one.wav'
import DecorativeTwo from '../../assets/decorative_two.wav'
import Simple from '../../assets/notification_simple.wav'
import Trumpet from '../../assets/trumpet.mp3'
import Twinkle from '../../assets/twinkle.mp3'

export const chimeMapping: Record<string, string> = {
  audio: AudioAlert,
  twoTone: TwoTone,
  register: CashRegister,
  gameShow: GameShow,
  gong: Gong,
  levelUp: LevelUp,
  decorativeOne: DecorativeOne,
  decorativeTwo: DecorativeTwo,
  simple: Simple,
  trumpet: Trumpet,
  twinkle: Twinkle
}

export type SoundType = keyof typeof chimeMapping

type SoundProps = {
  sound: SoundType
  onEnd?: () => void
}

export function Sound ({ sound, onEnd }: SoundProps) {
  if (!sound) {
    return
  }
  let snd
  if (!chimeMapping[sound]) {
    const trimmed = sound.substring(0, sound.indexOf('.mp3')) + '.mp3'
    snd = new Audio(trimmed)
  } else {
    snd = new Audio(chimeMapping[sound])
  }
  if (onEnd) {
    snd.onended = onEnd
  }
  snd.play().catch((e: any) => {
    if (!e.message.includes('interact with document first')) {
      console.log('failed to play sound', { err: e, sound: sound })
    }
  })
  return snd
}
