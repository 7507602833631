
const descriptionText = 'Use this node when you would like to add a delay before continuing the play.'

const Description = (): JSX.Element => {
  return (
    <div>
      <div>
        {descriptionText}
      </div>
    </div>
  )
}

const bestPracticeText = 'Use this node when immediate continuation of the play is not in the best interest of the visitor, such as:'

const BestPractices = () => {
  return (
    <div>
      <div>
        {bestPracticeText}
      </div>
      <ul>
        <li>Sending follow up emails to interact further with the visitor</li>
        <li>Firing a bot after the user has been on the page for a set period of time</li>
      </ul>
    </div>
  )
}

export const delayDocs = {
  helpCenterLink: 'https://help.getsignals.ai/article/j98bvrmwqp-plays-delay-node',
  bestPractices: BestPractices,
  description: Description,
  videoLink: 'https://www.youtube.com/watch?v=wD9qLk4jND8&list=PL6SHkPxzWDamLhKs_lWXmkwP3W5y9yQlQ&index=8'
}
