import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Card, Select, MenuItem } from '@material-ui/core'
import Loader from 'library/loading/Loader'
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos'
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardIos'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import EmptyState from './EmptyState'

const useStyles = makeStyles(theme => ({
  card: {
    marginBottom: 12,
    fontFamily: 'poppins, sans serif',
    fontSize: '.9em'
  },
  stackedDataDiv: {
    display: 'flex',
    alignItems: 'center'
  },
  dataItem: {
    paddingRight: 10,
    paddingLeft: 10,
    borderRight: '1px solid #ccc',
    whiteSpace: 'nowrap'
  },
  lastDataItem: {
    paddingLeft: 10
  },
  dataHeader: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: '13px',
    color: '#777'
  },
  dataValue: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: '16px',
    color: '#222'
  },
  loadingDiv: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 70,
    height: 90
  },
  cardList: {
    padding: '0px 20px 20px 20px',
    fontFamily: 'poppins, sans serif'
  },
  paginationRow: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  paginationCard: {
    display: 'grid',
    gridTemplateColumns: '2.7fr 1fr .8fr',
    gridGap: 10,
    padding: '5px 15px',
    minWidth: '20%'
  },
  arrowsDiv: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  arrowIcons: {
    fontSize: '1rem',
    fill: '#4C4C4C'
  },
  pageCount: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '14px'
  },
  headerDiv: {
    display: 'flex',
    cursor: 'pointer',
    whiteSpace: 'nowrap'
  },
  noSortHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))

function ListItemStackedData (props) {
  const classes = useStyles()
  const padding = props.padding

  const length = props.items.length
  return (
    <div className={classes.stackedDataDiv}>
      {props.items.map((item, index) => (
        <div key={index} className={index === length - 1 ? classes.lastDataItem : classes.dataItem}>
          <div className={classes.dataHeader} style={padding && { padding: padding }}>
            {item.title}
          </div>
          <div className={classes.dataValue}>
            {item.value === '' ? '---' : item.value}
          </div>
        </div>
      ))}
    </div>
  )
}

/**
 * @param {{ style?: { [k: string]: any; }; children: React.ReactNode; }} props
 */
function ListItemCard (props) {
  const classes = useStyles()
  const { style = {} } = props

  return (
    <Card className={classes.card} style={style}>
      {props.children}
    </Card>
  )
}

function PaginationCard (props) {
  const classes = useStyles()
  const rowsPerPage = props.rowsPerPage
  const totalCount = props.overrideTotalCount || props.totalCount

  const startingValue = totalCount ? (props.pageNumber - 1) * props.rowsPerPage + 1 : 0
  let endingValue = props.pageNumber * props.rowsPerPage
  endingValue = endingValue > totalCount ? totalCount : endingValue

  const countLabel = startingValue + '-' + endingValue + ' of ' + totalCount
  const disableBack = props.disableBack || startingValue === 1
  const disableNext = (props.disableNext || props.overrideTotalCount) && endingValue >= totalCount

  return (
    <div className={classes.paginationRow}>
      <Card className={classes.paginationCard}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ marginBottom: 3, fontSize: '15px' }}>Rows per page:</div>
          <Select
            disableUnderline
            style={{ marginLeft: 10 }}
            value={rowsPerPage}
            onChange={(e) => props.setRowsPerPage(e.target.value)}
          >
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={25}>25</MenuItem>
          </Select>
        </div>
        {(!props.pagination || props.overrideTotalCount)
          ? (
            <div className={classes.pageCount}>{countLabel}</div>
          )
          : <></>}
        <div className={classes.arrowsDiv}>
          <ArrowBackIcon
            className={classes.arrowIcons}
            onClick={() => { if (!disableBack) { props.setPageNumber(props.pageNumber - 1) } }}
            style={{ fill: disableBack ? '#aaa' : '#333', cursor: disableBack ? null : 'pointer', marginRight: 5 }}
          />
          <ArrowForwardIcon
            className={classes.arrowIcons}
            onClick={() => { if (!disableNext) { props.setPageNumber(props.pageNumber + 1) } }}
            style={{ fill: disableNext ? '#aaa' : '#333', cursor: disableNext ? null : 'pointer' }}
          />
        </div>
      </Card>
    </div>
  )
}

function CardListHeader (props) {
  const classes = useStyles()

  function handleSort (column) {
    if (props.sortCriteria === column) {
      props.setSortAscending(!props.sortAscending)
    } else {
      props.setSortCriteria(column)
    }
  }
  const Icon = props.sortAscending ? ArrowUpwardIcon : ArrowDownwardIcon

  if (props.centerColumns) {
    return (
      <div className={props.headerClass}>
        {props.columns.map((column, index) => (
          <div
            key={index}
            className={classes.noSortHeader}
          >
            {column.label}
          </div>
        ))}
      </div>
    )
  }

  return (
    <div className={props.headerClass}>
      {props.columns.map((column, index) => (
        <div
          key={index}
          className={classes.headerDiv}
          onClick={() => handleSort(column.id)}
        >
          {column.label}
          <Icon
            fontSize='small'
            style={{
              marginLeft: 5,
              fill: '#7c7c7c',
              visibility: (props.sortCriteria === column.id) ? 'visible' : 'hidden'
            }}
          />
        </div>
      ))}
    </div>
  )
}

function CardList (props) {
  const classes = useStyles()
  const CustomCard = props.card
  const CustomHeader = props.customHeader
  const { sortCriteria, sortAscending } = props.sortOptions

  if (props?.isLoading) {
    return (
      <div className={classes.loadingDiv}>
        <Loader type='spinner' />
      </div>
    )
  }

  let rows = props.rows ? props.rows.sort((a, b) => {
    const sortValA = a[sortCriteria] ? a[sortCriteria] : ''
    const sortValB = b[sortCriteria] ? b[sortCriteria] : ''
    if (sortValA === sortValB) {
      return 0
    } else if (sortValA < sortValB) {
      return sortAscending ? -1 : 1
    } else {
      return sortAscending ? 1 : -1
    }
  }) : null

  if (!props.pagination && props.rows) {
    rows = rows.slice((props.pageNumber - 1) * props.rowsPerPage, props.pageNumber * props.rowsPerPage)
  }
  const length = rows?.length

  if (length) {
    return (
      <div className={classes.cardList}>
        {props.customHeader && <CustomHeader {...props.headerProps} />}
        {props.header && <CardListHeader {...props.headerProps} />}
        {rows.map((row, index) => (
          <CustomCard
            key={row.id}
            index={index}
            row={row}
            {...props.cardProps}
          />
        ))}
        <PaginationCard
          rowsPerPage={props.rowsPerPage}
          setRowsPerPage={props.setRowsPerPage}
          pageNumber={props.pageNumber}
          setPageNumber={props.setPageNumber}
          pagination={props.pagination}
          totalCount={props.rows.length}
          overrideTotalCount={props.totalCount}
          disableBack={props.disableBack}
          disableNext={props.disableNext}
        />
      </div>
    )
  }

  if (rows) {
    if (props.pageNumber > 1) {
      return (
        // I'm returning this basic table layout so the flash will look a little better?
        <div className={classes.cardList}>
          {props.customHeader && <CustomHeader {...props.headerProps} />}
          {props.header && <CardListHeader {...props.headerProps} />}
        </div>
      )
    }
    return (
      <div style={{ marginTop: 100 }}>
        <EmptyState
          message='No Records Found'
          sad
        />
      </div>
    )
  }
  return (
    <div className={classes.loadingDiv}>
      <Loader type='spinner' />
    </div>
  )
}

export { ListItemCard, ListItemStackedData, CardList, CardListHeader }
