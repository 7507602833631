import { makeStyles } from '@material-ui/core/styles'
import { LinearProgress } from 'library/materialUI'

const useStyles = makeStyles(theme => ({
  header: {
    margin: 20,
    marginBottom: 0,
    padding: 15,
    backgroundColor: '#F6F2F8BF',
    borderRadius: 15
  },
  title: {
    color: '#000000BF',
    font: 'normal normal bold 14px/21px Poppins'
  },
  details: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 5
  },
  progress: {
    backgroundColor: '#0000000D',
    padding: '4px 8px',
    borderRadius: 5,
    color: '#000000BF',
    font: 'normal normal normal 12px/18px Poppins'
  }
}))

type ModalHeaderProps = {
  title: string
  step: number
  stepCount: number
}

export function ModalHeader (props: ModalHeaderProps) {
  const classes = useStyles()

  const progress = 100 * props.step / props.stepCount
  const stepProgress = props.step + '/' + props.stepCount

  return (
    <div className={classes.header}>
      <div className={classes.details}>
        <div className={classes.title}>
          {props.title}
        </div>
        <div className={classes.progress}>
          {stepProgress}
        </div>
      </div>
      <div>
        <LinearProgress
          variant='determinate'
          color='primary'
          value={progress}
        />
      </div>
    </div>
  )
}
