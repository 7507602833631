import { useRef, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Sequence } from 'classes/sequences';
import { FlowBuilder, nodeMappingData } from 'library/flowBuilder/FlowBuilder';
import { ModalContentsProps } from 'library/flowBuilder/nodes/NodeModal';
import { Button, Icon, TextBox } from 'library/materialUI';
import { nodeFactory, nodeMapping } from '../nodeMapping';
import { SettingsDrawer } from 'library/SettingsDrawer';
import { ModalSection } from 'library/Modal';
import Tooltip from '@material-ui/core/Tooltip'

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 60,
    paddingTop: 5,
    paddingLeft: 15,
    paddingRight: 15
  },
  buttons: {
    height: 45
  },
  buttonStyle: {
    borderRadius: '100%',
    height: 45,
    width: 45
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    font: 'normal normal normal 20px/25px Poppins',
    fontWeight: 600,
    color: '#000000BF'
  },
  subtitle: {
    color: '#00000080',
    font: 'normal normal normal 14px/25px Poppins',
    height: 23,
    overflow: 'none'
  },
  nodeBody: {
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden'
  },
  fakeDiv: {
    position: 'absolute',
    width: '280px',
    height: '50px',
    top: 40,
    right: 40,
    zIndex: -5
  },
  tooltip: {
    zIndex: 1300
  },
  descriptionBox: {
    maxHeight: 200
  },
  itemActive: {
    display: 'flex',
    alignItems: 'center',
    color: '#81AF20',
    fontWeight: 0,
    fontSize: '12px',
    marginLeft: 10,
    backgroundColor: '#E6EFD2',
    borderRadius: 5,
    padding: 5,
    cursor: 'default'
  }
}))

export function PlayModal ({ settings, updateSettings, updatePorts, saveChanges, cancel }: ModalContentsProps): JSX.Element {
  const classes = useStyles()
  const [saveResponse, setSaveResponse] = useState<Sequence | null>(null)
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [settingsChanged, setSettingsChanged] = useState(false)
  const { data: sequence, isLoading } = Sequence.loadOne(settings.sequenceID)
  const [name, setName] = useState(isLoading ? '' : sequence.name)
  const [description, setDescription] = useState(isLoading ? '' : sequence.description)
  const dataRef = useRef<any>(null)

  useEffect(() => {
    if (saveResponse) {
      saveChanges()
    }
    // eslint-disable-next-line
  }, [saveResponse])

  if (isLoading) {
    return <div />
  }

  const initialData = {
    data: {
      nodes: [...sequence.nodes],
      edges: [...sequence.edges]
    }
  }

  const save = (payload: any) => {
    if (payload.data) {
      const data = dataRef.current.exportData()
      const exitNode = data.nodes.filter((node: any) => node.id === 'exit')[0]
      const ports = exitNode.ports.map((p: any) => ({ id: p.id, label: p.label }))
      updatePorts(ports)
      const newSequence = { ...sequence, ...settings, name: name, description: description, edges: data.edges, nodes: data.nodes, ports }
      return Sequence.save({ sequence: newSequence, objectType: 'plays' }).then((response: Sequence) => {
        updateSettings({ sequenceID: response.id, reusable: response.isReusable, name: response.name, description: response.description })
        setSaveResponse(response)
      })
    }
  }

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <div className={classes.header}>
        <div>
          <div className={classes.title}>
            {name}
            <Tooltip title='This play is currently active. If you want to deactivate it, remove it from your PlayMaker.'><div className={classes.itemActive}>Active</div></Tooltip>
          </div>
          <Tooltip
            title={description.length > 150 ? description : ''}
            disableTouchListener
            disableFocusListener
            enterDelay={1000}
            placement='bottom'
          >
            <div className={classes.subtitle}>
              {description.length < 150 ? description : `${description.substring(0, 150)}...`}
            </div>
          </Tooltip>
        </div>
        <div className={classes.buttons}>
          <Button
            variant='text'
            color='default'
            className={classes.buttonStyle}
            onClick={() => cancel()}
          >
            <Icon icon='close' size='lg' />
          </Button>
        </div>
      </div>
      <div style={{ height: 'calc(100% - 70px)', position: 'relative' }}>
        <SettingsDrawer
          title='Play Settings'
          open={drawerOpen}
          handleClose={() => setDrawerOpen(false)}
        >
          <ModalSection
            title='Play Title'
          >
            <TextBox
              value={name}
              onChange={(value: string) => {
                setName(value)
                setSettingsChanged(true)
              }}
              autoFocus
            />
          </ModalSection>
          <ModalSection
            title='Description'
          >
            <TextBox
              value={description}
              onChange={(value: string) => {
                setDescription(value)
                setSettingsChanged(true)
              }}
              rows={20}
            />
          </ModalSection>
        </SettingsDrawer>
        <FlowBuilder
          save={save}
          editDetails={() => setDrawerOpen(true)}
          edited={settingsChanged}
          dataRef={dataRef}
          object={sequence}
          nodeMapping={nodeMapping}
          nodeFactory={nodeFactory}
          initialData={initialData}
          flowOptions={['edit', 'version', 'save']}
          objectType='sequences'
          // altColor
          withinPlayrunner
        />
      </div>
    </div>
  )
}

function PlayBody ({ settings }: any): JSX.Element {
  const classes = useStyles()
  const { data: sequence, isLoading } = Sequence.loadOne(settings.sequenceID)

  if (isLoading) {
    return (
      <div />
    )
  }
  return (
    <div className={classes.nodeBody}>
      {sequence.description}
    </div>
  )
}

function PlayHeader ({ settings }: any): JSX.Element {
  const { data: sequence, isLoading } = Sequence.loadOne(settings.sequenceID)
  if (isLoading) {
    return (
      <div />
    )
  }
  return (
    <div>
      {sequence.name}
    </div>
  )
}

export const playMapping: nodeMappingData = {
  icon: 'play',
  title: 'Play',
  modal: PlayModal,
  color: '#333399',
  body: PlayBody,
  header: PlayHeader
}
