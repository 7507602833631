import { doGet, doPost } from './api'

interface Vote {
  value: string | number | string[]
  isUpvote: boolean
  submission?: boolean
}

interface Approval {
  value: string | number | string[]
  approval: string
}

export const addVote = ({ domain, field, vote }: { domain: string, field: string, vote: Vote }): Promise<any> => {
  const data = { attributes: vote }
  const path = `/companies/${domain}/cs_data/${field}/vote`
  return doPost({ path, data, useChatURL: true })
}

export const getAllVotes = ({ domain, field }: { domain: string, field: string }): Promise<any> => {
  const path = `/companies/${domain}/cs_data/${field}/votes`
  return doGet({ path, useChatURL: true })
}

export const getVote = ({ domain, field }: { domain: string, field: string }): Promise<any> => {
  const path = `/companies/${domain}/cs_data/${field}/vote`
  return doGet({ path, useChatURL: true })
}

export const reviewVotes = ({ domain, field, approval }: { domain: string, field: string, approval: Approval }): Promise<any> => {
  const data = { attributes: approval }
  const path = `/companies/${domain}/cs_data/${field}/review`
  return doPost({ path, data, useChatURL: true })
}
