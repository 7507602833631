import { SectionProps } from '../HelpCenter'
import { HelpCenterCardList } from '../HelpCenterCardList'
import { SectionCenter } from '../SectionCenter'
import { botTemplateMapping } from '../TemplateMapping'

const nodeList = ['Homepage', 'SecondNet', 'Retargeting', 'HomepageFAQ', 'Demo', 'LiveChatTemplate', 'Concierge', 'ConversationalContent', 'ABELiveChat'] // might need to be updated

export const BotTemplatesCenter = (props: SectionProps): JSX.Element => {
  return (
    <SectionCenter
      homeComponent={<HelpCenterCardList nodeMapping={botTemplateMapping} nodeList={nodeList} />}
      links={props.links}
      type='template'
    />
  )
}
