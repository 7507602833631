import { jsPlumbUtil } from 'jsplumbtoolkit'
import useNodeStyles from './NodeStyles'
import FinishedIcon from '@material-ui/icons/AssignmentTurnedIn'
import OptionPort from '../OptionPort'
import Divider from '@material-ui/core/Divider'

const ConversationStatusNode = props => {
  const base = props.base
  const classes = useNodeStyles(props)

  const assignKey = data => {
    data._reactKey = data._reactKey || jsPlumbUtil.uuid()
    return data._reactKey
  }

  const message = 'Close the conversation'

  return (
    <>
      <div>
        <div
          className={`${classes.dragger} ${classes.backgroundAction}`}
        >
          <div className='node-header'>
            <div className={classes.iconDiv}><FinishedIcon /></div>
            <div className={classes.cardTitle}>Close Conversation</div>
          </div>
          <div className='node-body'>
            {message}
            <Divider style={{ marginTop: 5 }} />
            <div>
              {base.state.ports.map((c, index) => {
                return (
                  <OptionPort
                    index={index}
                    data={c}
                    key={assignKey(c)}
                    parent={base}
                    kind='backgroundAction'
                    label='Output'
                  />
)
              })}
            </div>
          </div>
          <jtk-target scope='default' />
        </div>
      </div>
    </>
  )
}

export default ConversationStatusNode
