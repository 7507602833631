import { WizardSectionType, OnboardingWizard } from 'pages/onboarding/wizard/OnboardingWizard'
import { UserNameSection, userNameSubtitle } from './UserNameSection'
import { ProfilePicture, profilePictureSubtitle } from './ProfilePicture'
import { PhoneNumber, phoneNumberSubtitle } from './PhoneNumber'
import { CalendarConnection, calendarSubtitle } from './CalendarConnection'
import { BufferTimeSection, bufferTimeSubtitle } from './MeetingSettings'
import { ChimeSection, chimeSubtitle, EventNotifications, notificationsSubtitle } from './EventNotifications'
import { ConferencingProvider, conferencingSubtitle } from './ConferencingProvider'
import { Cronofy } from 'classes/cronofy'

const wizardSections: Record<string, WizardSectionType> = {
  userName: {
    title: "What's your name?",
    subtitle: userNameSubtitle,
    contents: UserNameSection
  },
  profilePicture: {
    title: 'Show us your face',
    subtitle: profilePictureSubtitle,
    contents: ProfilePicture
  },
  phoneNumber: {
    title: 'Can we get your number?',
    subtitle: phoneNumberSubtitle,
    contents: PhoneNumber
  },
  calendar: {
    title: 'When are you free?',
    subtitle: calendarSubtitle,
    contents: CalendarConnection
  },
  conferencing: {
    title: 'Connect your conferencing provider',
    subtitle: conferencingSubtitle,
    contents: ConferencingProvider
  },
  bufferTimes: {
    title: 'Set buffer and lead time',
    subtitle: bufferTimeSubtitle,
    contents: BufferTimeSection
  },
  notifications: {
    title: 'Event Notifications',
    subtitle: notificationsSubtitle,
    contents: EventNotifications
  },
  chimes: {
    title: 'Chime Notifications',
    subtitle: chimeSubtitle,
    contents: ChimeSection
  }
}

const personalSteps = [
  'userName',
  'profilePicture',
  'phoneNumber',
  'calendar',
  'notifications',
  'chimes'
]

const personalWithCalendarSteps = [
  'userName',
  'profilePicture',
  'phoneNumber',
  'calendar',
  'conferencing',
  'bufferTimes',
  'notifications',
  'chimes'
]

const getStep = () => {
  const onboardingStep = window.sessionStorage.getItem('onboardingStep')
  if (onboardingStep === 'calendar') {
    window.sessionStorage.removeItem('onboardingStep')
    return 3
  }
  if (onboardingStep === 'conferencing') {
    window.sessionStorage.removeItem('onboardingStep')
    return 4
  } else if (onboardingStep === 'photo') {
    window.sessionStorage.removeItem('onboardingStep')
    return 1
  }
  return 0
}

type WizardProps = {
  open: boolean
  handleFinish: () => void
}

export function PersonalSetupWizard ({ open, handleFinish }: WizardProps) {
  const { data: cronofy, isLoading } = Cronofy.load()
  const steps = cronofy.active ? personalWithCalendarSteps : personalSteps

  if (isLoading) {
    return <></>
  }

  const initialState = {
    open: open,
    step: getStep()
  }
  return (
    <OnboardingWizard
      initialState={initialState}
      steps={steps}
      sections={wizardSections}
      title='Personal Setup'
      handleFinish={handleFinish}
    />
  )
}
