import AppPage from 'cf-components/AppPage'
import { NPSSettings } from 'classes/npsSettings'
import { useContext } from 'react'
import { NPSContent } from './NPSContent'
import { NPSContext, NPSContextProvider } from './NPSContext'
import { NPSOverview } from './NPSOverview'
import { NPSResponses } from './NPSResponses'
import { NPSTargeting } from './NPSTargeting'
import { NPSTriggers } from './NPSTriggers'

const baseURL = '/admin_portal/nps'
const tabs = [
  { name: 'Overview', url: `${baseURL}/overview` },
  { name: 'Responses', url: `${baseURL}/responses` },
  { name: 'Content', url: `${baseURL}/content` },
  { name: 'Targeting', url: `${baseURL}/targeting` },
  { name: 'Triggers', url: `${baseURL}/triggers` }
]

function NPSTabsPage () {
  const { stateHandlers } = useContext(NPSContext)
  const [state, , hasChanged, reset] = stateHandlers

  const saveProps = {
    disabled: !hasChanged(),
    save: () => NPSSettings.save(state).then(reset),
    saveText: 'Save Settings',
    validPaths: 'nps'
  }

  return (
    <AppPage
      title='NPS Settings'
      tabs={tabs}
      padding={20}
      saveProps={saveProps}
    >
      <NPSOverview />
      <NPSResponses />
      <NPSContent />
      <NPSTargeting />
      <NPSTriggers />
    </AppPage>
  )
}

export function NPSPage () {
  const { settings, isLoading } = NPSSettings.load()

  if (isLoading) {
    return (<div />)
  }

  return (
    <NPSContextProvider settings={settings}>
      <NPSTabsPage />
    </NPSContextProvider>
  )
}
