import { ModalSection } from 'library/Modal';
import UpdatePlanModal from './UpdatePlanModal';
import { QuantityAdjuster } from 'library/QuantityAdjuster';
import { useEffect, useState } from 'react';
import { previewLicenseAdjustment } from 'api/billing';

type UpdateUsersModalProps = {
  open: boolean
  onClose: () => void
  onSave: (userChange: number) => void
  planPrice: number
  maxUsers: number
  allocated: number
}

export default function UpdateUsersModal ({ open, onClose, onSave, planPrice, maxUsers, allocated }: UpdateUsersModalProps): JSX.Element {
  const [userAdjustment, setUserAdjustment] = useState(0)
  const [loading, setLoading] = useState(false)
  const [changeInCost, setChangeInCost] = useState(0)
  const [totalPrice, setTotalPrice] = useState(planPrice)

  useEffect(() => {
    if (userAdjustment !== 0) {
      setLoading(true)
      previewLicenseAdjustment('users', userAdjustment).then((r) => {
        const resp = r.data.attributes
        if (resp.change_in_cents_prorated !== 0) {
          setChangeInCost(resp.change_in_cents_prorated / 100)
          setTotalPrice(planPrice + resp.change_in_cents_prorated / 100)
        }
        setLoading(false)
      })
    } else {
      setChangeInCost(0)
      setTotalPrice(planPrice)
    }
  }, [userAdjustment, planPrice])

  return (
    <UpdatePlanModal
      saveDisabled={loading}
      open={open}
      onClose={onClose}
      planPrice={totalPrice}
      onSave={() => {
        onSave(userAdjustment)
      }}
      duePrice={changeInCost}
      loading={loading}
    >
      <ModalSection
        title='Additional Users'
        subtitle={`Your plan contains ${allocated} users. Add more below.`}
      >
        <QuantityAdjuster
          value={userAdjustment}
          min={0}
          max={maxUsers}
          handleDecrement={() => setUserAdjustment(userAdjustment - 1)}
          handleIncrement={() => setUserAdjustment(userAdjustment + 1)}
        />
      </ModalSection>
    </UpdatePlanModal>
  )
}
