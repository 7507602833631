import React, { useEffect, useRef, useState } from 'react'
import { getFAQPieChart } from 'api/reports'
import Loader from 'library/loading/Loader'
import { createPieChart } from './CreatePieChart'
import FullScreenView from '../../reports/FullScreenView'
import EmptyState from 'components/EmptyState'
import { convertDateRange } from 'cf-components/FilterBar/FilterBar'

function PieChart (props) {
  const chartRef = useRef(null)

  const [visibility, setVisibility] = useState('hidden')
  const [fullScreenVisibility, setFullScreenVisibility] = useState('hidden')
  const [chartData, setChartData] = useState(null)

  useEffect(() => {
    setVisibility('hidden')

    if (props.dateValues) {
      if (chartRef.current) {
        chartRef.current.dispose()
      }
      getFAQPieChart({ attributes: convertDateRange(props.dateValues) }).then(response => {
        const id = 'piechart'
        const data = response.data.attributes.chart_data.map(object => {
          let name = object.name
          if (name.length > 25) {
            name = name.substr(0, 22) + '... '
          }
          return (
            { name: name, value: object.value }
          )
        })
        setChartData(data)
        if (data.length) {
          const chart = createPieChart({ data, id, setVisible: setVisibility })
          chartRef.current = chart
        } else {
          setTimeout(() => setVisibility('visible'), 500)
        }
      })
    }
  }, [props.dateValues])

  return (
    <>
      <div
        style={{
          display: visibility === 'visible' ? 'none' : 'block',
          ...props.loaderStyles
        }}
      >
        <Loader type='spinner' />
      </div>
      <div style={{ height: 560, visibility: visibility }}>
        {chartData && chartData.length > 0
          ? <div id='piechart' style={{ width: '100%', height: '100%' }} />
          : <EmptyState message='No Answers Given' />}
      </div>
      <FullScreenView
        createChart={() => createPieChart({ data: chartData, id: 'fullScreenChart', setVisible: setFullScreenVisibility })}
        open={props.fullScreen}
        onClose={() => props.exitFullScreen()}
        filter={props.filter}
        visibility={fullScreenVisibility}
        setVisibility={setFullScreenVisibility}
        title='FAQ Answers Given'
      />
    </>
  )
}

export default PieChart
