import React, { useEffect, useState, useContext } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { getAccountByDomain } from 'api/account_management'
import { getUser } from 'api/users'
import { SessionContext } from 'session-context'
import { ConversationContext } from '../../ConversationContext'
import { Avatar, getInitials } from 'cf-components/Avatars'
import EmptyState from 'cf-components/EmptyState'
import { HiddenLink } from 'cf-components/Link'
import BaseCard from './BaseCard'
import { SignalsScoreGear } from 'cf-components/SignalsScoreGear'
import { getCompanySummary } from 'api/participants'

const useStyles = makeStyles(theme => ({
  outerSectionCtn: {
    display: 'grid',
    gridTemplate: '1fr 1fr / 1fr 1fr',
    columnGap: '4px',
    rowGap: '8px',
    height: 160,
    fontSize: 25
  },
  opportunityStatus: {
    fontSize: 20,
    fontWeight: 600,
    color: '#a387d4'
  },
  abeScoreCtn: {
    width: 50
  }
}))

export default function ABECard (props) {
  const { user } = useContext(SessionContext)
  const { conversation } = useContext(ConversationContext)
  const [account, setAccount] = useState(undefined)
  const [accountOwner, setAccountOwner] = useState(undefined)
  const chatServiceUrl = window.chatServiceUrl
  const [accountDomain, setAccountDomain] = useState(conversation?.attributes?.domain)
  const userBillingPerm = user?.attributes?.perms?.semi_admin
  const participantID = conversation?.relationships.for_participant.data.id

  useEffect(() => {
    if (participantID && !accountDomain) {
      getCompanySummary({ chatServiceUrl, participantID }).then(res => {
        if (res?.data?.attributes) {
          setAccountDomain(res.data.attributes.domain)
        }
      })
    }
    // eslint-disable-next-line
  }, [chatServiceUrl, participantID])

  useEffect(() => {
    if (!accountDomain) {
      setAccount(null)
      setAccountOwner(null)
    } else {
      getAccountByDomain({ domain: accountDomain }).then(res => {
        if (!res?.data?.id) {
          setAccount(null)
          setAccountOwner(null)
        } else {
          const newAccount = res.data
          if (newAccount.attributes.owner_user_id) {
            getUser({ ID: newAccount.attributes.owner_user_id }).then(res => {
              if (res?.data) {
                setAccountOwner(res.data)
              } else {
                setAccountOwner(null)
              }
            })
          } else {
            setAccountOwner(null)
          }
          setAccount(newAccount)
        }
      })
    }
  }, [chatServiceUrl, accountDomain])

  return (
    <BaseCard
      title='Account-Based Engagement'
      id={props.id}
    >
      <ABEContent
        account={account?.attributes}
        accountOwner={accountOwner}
        canAccessBilling={userBillingPerm}
      />
    </BaseCard>
  )
}

/**
 * @param {{
 * account;
 * accountOwner;
 * }} props
 */
function ABEContent (props) {
  const classes = useStyles()
  const { account, accountOwner } = props

  if (!account) {
    return (
      <EmptyState
        sad
        message='No account summary to display'
      />
    )
  }

  const initials = getInitials(account.name, 'A')

  return (
    <div className={classes.outerSectionCtn}>
      <HiddenLink to={'/accounts/' + account.domain} target='_blank'>
        <ABESection title={account.name}>
          <Avatar
            avatarUrl={`/api/logo-service/logo/${account.domain}.png`}
            size='sm'
            initials={initials}
          />
        </ABESection>
      </HiddenLink>
      <HiddenLink to={'/accounts/' + account.domain} target='_blank'>
        <ABESection title='Signals Score'>
          <div className={classes.abeScoreCtn}>
            <SignalsScoreGear score={account.score} placement='left' domain={account.domain} />
          </div>
        </ABESection>
      </HiddenLink>
      {accountOwner ? (
        <HiddenLink to={'/users/' + accountOwner?.id} target='_blank'>
          <ABESection title='Account Owner'>
            <Avatar user={accountOwner} size='sm' />
          </ABESection>
        </HiddenLink>
      ) : (
        <ABESection title='Account Owner'>
          <div>None</div>
        </ABESection>
      )}
      <HiddenLink to={'/accounts/' + account.domain} target='_blank'>
        <ABESection title='Opportunity'>
          <div className={classes.opportunityStatus}>{account?.opportunity_status?.toUpperCase() || 'No Opp'}</div>
        </ABESection>
      </HiddenLink>
    </div>
  )
}

const ABESection = withStyles(theme => ({
  label: {
    paddingTop: 4,
    fontSize: 10,
    color: '#707070'
  },
  sectionCtn: {
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: '100%'
  },
  childrenCtn: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center'
  }
}))(
  /**
   * @param {{
   * classes: import('@material-ui/styles').ClassNameMap;
   * title: string;
   * children;
   * }} props
   */
  function (props) {
    const { classes, title = '', children } = props

    return (
      <div className={classes.sectionCtn}>
        <div className={classes.childrenCtn}>{children}</div>
        <div className={classes.label}>{title.toUpperCase()}</div>
      </div>
    )
  }
)
