import { makeStyles } from '@material-ui/core/styles'
import { useContext } from 'react'
import { SectionProps } from '../HelpCenter'
import { HelpCenterCard, THelpCenterCard } from '../HelpCenterCard'
import { HelpCenterContext } from '../HelpCenterContext'
import { SectionCenter } from '../SectionCenter'
import { ReportBug } from './ReportBug'
import { SendFeedback } from './SendFeedback'
import { HistoryCenter } from './HistoryCenter'

const useStyles = makeStyles(theme => ({
  headerText: {
    font: 'normal normal 600 24px/35px Poppins',
    color: '#000000BF'
  }
}))

const FeedbackHome = (): JSX.Element => {
  const classes = useStyles()
  const { navigateBack } = useContext(HelpCenterContext)

  const cards: THelpCenterCard[] = [
    { title: 'Report a Problem', subtitle: 'Let us know if something isn’t working or doesn’t look right', icon: 'reportProblem', link: 'Report a Problem' },
    { title: 'Request a Feature', subtitle: 'Help us improve Signals', icon: 'lightBulbWhite', link: 'Request a Feature' },
    { title: 'View Support History', subtitle: 'Manage your requests', icon: 'checkListIcon', link: 'Support History' }
  ]

  return (
    <div>
      <div className={classes.headerText} onClick={navigateBack}>
        Feedback
      </div>
      <div>
        {cards.map((card: THelpCenterCard, index: number) => (
          <HelpCenterCard key={index} card={card} />
        ))}
      </div>
    </div>
  )
}

export const sectionMapping = {
  'Report a Problem': ReportBug,
  'Request a Feature': SendFeedback,
  'Support History': HistoryCenter
}

export const FeedbackCenter = (props: SectionProps): JSX.Element => {
  return (
    <SectionCenter
      homeComponent={<FeedbackHome />}
      sectionMapping={sectionMapping}
      links={props.links}
    />
  )
}
