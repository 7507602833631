import { useState, useReducer } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { SavedReply } from 'classes/savedReplies'
import { Modal, ModalSection } from 'library/Modal'
import { Select, TextBox } from 'cf-components/material-wrappers/MaterialComponents'
import ButtonTextField from 'library/textFields/ButtonTextField'
import { RichTextEditor } from 'library/richText/RichTextEditor'

const useStyles = makeStyles(theme => ({
  categoryDiv: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: 20
  }
}))

const visibilityOptions = [
  { value: 'private', label: 'Only visible to you' },
  { value: 'tenant', label: 'Visible to your organization' }
]

interface modalProps {
  categories: string[]
  reply: any
  onHide: any
}

const reducer = (state: SavedReply, changes: any): SavedReply => {
  return { ...state, ...changes }
}

export function SavedReplyModal (props: modalProps): JSX.Element {
  const classes = useStyles()
  const [state, dispatch] = useReducer(reducer, props.reply)
  const [categoryText, setCategoryText] = useState('')
  const categories = [...new Set(Array.from(props.categories).concat(state.categories))]

  const addCategory = (category: string): void => {
    if (category && !state.categories.includes(category)) {
      const newCategories = state.categories.concat(category)
      dispatch({ categories: newCategories })
    }
  }

  const saveReply = (): void => {
    SavedReply.save(state).then(() => props.onHide())
  }

  return (
    <Modal
      open
      title='Edit Reply'
      onHide={props.onHide}
      size='sm'
      handleSave={saveReply}
      saveIcon='add'
      saveBtnText='Create Reply'
      saveDisabled={!state.name || !state.content}
    >
      <ModalSection
        title='Title'
        subtitle='Give your reply a short, recognizable title'
      >
        <TextBox
          value={state.name}
          onChange={(value: string) => dispatch({ name: value })}
          label='Title'
        />
      </ModalSection>
      <ModalSection title='Saved Reply Text'>
        <RichTextEditor
          message={state.content}
          onChange={(value: string) => dispatch({ content: value })}
          placeholder='Enter reply text here...'
          lines={3}
        />
      </ModalSection>
      <ModalSection title='Categories'>
        <div className={classes.categoryDiv}>
          <Select
            value={state.categories}
            options={categories}
            onChange={(value: any) => dispatch({ categories: value })}
            placeholder='Select Reply Categories'
            multiple
          />
          <ButtonTextField
            addButton={addCategory}
            label={categoryText}
            setLabel={setCategoryText}
          />
        </div>
      </ModalSection>
      <ModalSection
        title='Visibility'
        subtitle='Who should be able to see and use this reply'
      >
        <Select
          value={state.visibility}
          onChange={(value: any) => dispatch({ visibility: value })}
          options={visibilityOptions}
          placeholder='Set Reply Visibility'
        />
      </ModalSection>
    </Modal>
  )
}
