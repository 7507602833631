import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Icon } from 'library/materialUI'
import { PopoverDiv } from 'pages/plays/playBuilder/nodes/PopoverDiv'

const useStyles = makeStyles(theme => ({
  container: {
    borderRadius: '5px',
    border: '1px solid #0000001A',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    minWidth: '200px',
    width: '100%',
    cursor: 'pointer',
    backgroundColor: '#FFFFFF'
  },
  dropDown: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    minWidth: '200px',
    width: '100%',
    height: '40px',
    borderRadius: '5px',
    border: '1px solid #D3D3D3',
    color: '#C4C4C4',
    backgroundColor: '#FFFFFF'
  }
}))

export default function DropDownLoader () {
  const classes = useStyles()

  return (
    <div>
      <PopoverDiv
        popoverContents={
          <div className={classes.dropDown}>
            <div style={{ marginLeft: '10px' }}>
              Loading...
            </div>
          </div>
        }
        divContents={
          <div className={classes.container}>
            <div style={{ marginLeft: 'auto', marginRight: '5px', color: '#9933FF' }}>
              <CircularProgress color='inherit' size={14} />
            </div>
            <div style={{ marginRight: '10px', marginLeft: '5px' }}>
              <Icon icon='expand' size='sm' />
            </div>
          </div>
        }
      />
    </div>
  )
}
