import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import TimerIcon from '@material-ui/icons/Timer'
import EmailIcon from '@material-ui/icons/MailOutline'
import MeetingIcon from '@material-ui/icons/EventAvailable'
import ResponseIcon from '@material-ui/icons/FeedbackOutlined'
import ChatIcon from '@material-ui/icons/ChatOutlined'
import RoundedSelect from 'components/RoundedSelect'
import { getAgentPerformance } from 'api/reports'
import BaseCard from './BaseCard'
import EmptyState from 'cf-components/EmptyState'

const useStyles = makeStyles(theme => ({
  performance: {
    display: 'flex',
    flexFlow: 'row wrap',
    fontFamily: 'Poppins, sans serif',
    color: '#4C4C4C',
    marginTop: 16
  },
  performanceSection: {
    display: 'flex',
    flexBasis: '49%',
    alignItems: 'center'
  },
  performanceText: {
    fontSize: '.9em',
    display: 'flex',
    flexFlow: 'row nowrap',
    flexGrow: 1,
    marginLeft: 5,
    '& > div:first-child': {
      flexBasis: '2em',
      flexShrink: 1
    }
  },
  emptyCtn: {
    width: '100%'
  },
  selectCtn: {
    fontSize: 14
  }
}))

const timePeriods = [
  { value: 1, name: 'Today' },
  { value: 2, name: 'This week' },
  { value: 3, name: '30 days' }
]

/**
 * @param {(typeof timePeriods)[number]['value']} period
 * @returns {{ startDate: string; endDate: string; }}
 */
function getDates (period) {
  let endDate = new Date()
  endDate.setDate(endDate.getDate() + 1)
  endDate.setHours(0, 0, 0, 0)
  endDate = endDate.toISOString()
  let startDate = new Date()
  startDate.setHours(0, 0, 0, 0)

  if (period === 2) {
    const day = startDate.getDay()
    const diff = startDate.getDate() - day
    startDate.setDate(diff)
  } else if (period === 3) {
    startDate.setDate(startDate.getDate() - 30)
  }

  startDate = startDate.toISOString()

  return ({ startDate, endDate })
}

export default function PerformanceCard (props) {
  const [stats, setStats] = useState(null)
  const [period, setPeriod] = useState(2)
  const classes = useStyles()

  useEffect(() => {
    if (period) {
      const { startDate, endDate } = getDates(period)
      getAgentPerformance({ startDate, endDate })
        .then(response => {
          if (response.data) {
            setStats(response.data.attributes)
          } else {
            setStats(null)
          }
        })
    }
  }, [period])

  function formatMetric (value, type) {
    if (type === 'time') {
      if (!value) {
        return '-----'
      }
      if (value < 60) {
        return value + ' sec'
      } else if (value < 600) {
        const min = value / 60
        const sec = value % 60
        return Math.floor(min) + 'm ' + sec + 's'
      } else {
        return '> 10 min'
      }
    } else if (type === 'rate') {
      return Math.round(value * 100) + '%'
    }
    return value
  }

  function formatRank (value) {
    if (value === 1) {
      return '1st'
    } else if (value === 2) {
      return '2nd'
    } else if (value === 3) {
      return '3rd'
    } else {
      return value + 'th'
    }
  }

  function getColor (value, type) {
    if (type === 'response_time') {
      if (!value) {
        return '#4C4C4C'
      }
      if (value < 20) {
        return '#61CBBC'
      } else if (value < 60) {
        return '#EF8849'
      } else {
        return '#F0706F'
      }
    } else if (type === 'response_rate') {
      if (value > 0.95) {
        return '#61CBBC'
      } else if (value > 0.85) {
        return '#EF8849'
      } else {
        return '#F0706F'
      }
    } else if (type === 'email_rate') {
      if (value > 0.5) {
        return '#61CBBC'
      } else if (value > 0.3) {
        return '#EF8849'
      } else {
        return '#F0706F'
      }
    } else if (type === 'meeting_rate') {
      if (value > 0.3) {
        return '#61CBBC'
      } else if (value > 0.15) {
        return '#EF8849'
      } else {
        return '#F0706F'
      }
    }

    return '#4C4C4C'
  }

  const metrics = [
    { name: 'Response Time', value: 'response_time', rank: 'response_time_rank', icon: TimerIcon, type: 'time' },
    { name: 'Response Rate', value: 'response_rate', rank: 'response_rate_rank', icon: ResponseIcon, type: 'rate' },
    { name: 'Email Capture Rate', value: 'email_rate', rank: 'email_rate_rank', icon: EmailIcon, type: 'rate' },
    { name: 'Meeting Booked Rate', value: 'meeting_rate', rank: 'meeting_rate_rank', icon: MeetingIcon, type: 'rate' },
    { name: 'Total Conversations', value: 'total_conversations', rank: 'total_conversations_rank', icon: ChatIcon, type: 'number' }
  ]

  return (
    <BaseCard
      title='Performance'
      id={props.id}
      actions={
        <div className={classes.selectCtn}>
          <RoundedSelect
            options={timePeriods}
            value={period}
            setValue={setPeriod}
            width={110}
            height={22}
            lineHeight='1.1em'
            fontSize='.9em'
          />
        </div>
      }
    >
      <div className={classes.performance}>
        {!stats ? (
          <div className={classes.emptyCtn}>
            <EmptyState
              sad
              message='No stats to display'
            />
          </div>
        ) : metrics.map((metric, index) => {
          const value = stats[metric.value]
          const rank = stats[metric.rank]
          const performanceText = formatMetric(value, metric.type)
          const rankText = formatRank(rank)
          const color = getColor(value, metric.value)
          return (
            <div className={classes.performanceSection} key={index}>
              <Tooltip title={metric.name}>
                <metric.icon color='primary' size='lg' />
              </Tooltip>
              <div className={classes.performanceText}>
                <div>
                  {rankText}
                </div>
                <div style={{ color: color }}>
                  {performanceText}
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </BaseCard>
  )
}
