import { doDelete, doPatch, doPost } from 'api/api';
import GenericObject from './genericObject';
import { useDoQuery } from './useDoQuery';

interface OfferList {
  list: ChargifyOffer[],
  dict: { [id: ChargifyOffer['id']]: ChargifyOffer }
}

const defaultOffer: Partial<ChargifyOffer> = {
  benefits: [],
  missingBenefits: []
}

type textIndex = 1 | 2 | 3 | 4

export class ChargifyOffer extends GenericObject {
  monthlyOfferID: number
  annualOfferID: number
  description: string
  display: boolean
  annualCostInCents: number
  monthlyCostInCents: number
  benefits: string[]
  missingBenefits: string[]
  offerType: textIndex
  billingCycle: 'monthly' | 'annual'

  static loadAll (): { data: OfferList, isLoading: boolean } {
    return useDoQuery({
      path: '/v2/billing/offers',
      objectClass: ChargifyOffer
    })
  }

  static save (offer: ChargifyOffer) {
    const data = {
      type: 'chargify_offers',
      id: offer.id,
      attributes: offer
    }

    if (offer.id) {
      const path = `/admin_portal/offers/${offer.id}`
      return doPatch({ path, data })
    } else {
      const path = '/admin_portal/offers'
      return doPost({ path, data })
    }
  }

  static delete (offer: ChargifyOffer) {
    const path = `/admin_portal/offers/${offer.id}`
    return doDelete({ path })
  }

  constructor ({ row }: { row?: Record<string, any> } = {}) {
    super({ row })
    const offer = row?.attributes || defaultOffer
    this.monthlyOfferID = offer.monthly_offer_id
    this.annualOfferID = offer.annual_offer_id
    this.missingBenefits = offer.missing_benefits || offer.missingBenefits
    this.description = offer.description
    this.display = offer.display
    this.annualCostInCents = offer.annual_cost_in_cents
    this.monthlyCostInCents = offer.monthly_cost_in_cents
    this.benefits = offer.benefits
    this.offerType = offer.offer_type
    this.billingCycle = offer.billing_cycle
  }
}
