import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles(theme => ({
  reminder: {
    backgroundColor: '#ff9038',
    color: 'white',
    borderRadius: '10px',
    position: 'absolute',
    top: '15%',
    right: '15%',
    border: '1px solid rgba(142, 90, 226, 0.4)',
    display: 'flex',
    // animation: '$disappear 0.5s ease-in-out forwards',
    // animationDelay: '3.5s',
    opacity: '1'
  },
  text: {
    fontSize: 'calc(10px + 1vmin)',
    textAlign: 'center',
    margin: '15px 20px'
  },
  iconContainer: {
    textAlign: 'right',
    margin: '5px 10px 0 0'
  },
  icon: {
    cursor: 'pointer'
  },
  '@keyframes disappear': {
    '0%': {
      opacity: 1
    },
    '100%': {
      opacity: 0
    }
  }
}))

const ReminderModal = (props) => {
  const classes = useStyles()
  const handleClose = props.render

  return (
    <div className={classes.reminder}>
      <p className={classes.text}>Which notifications would you like to view on {props.name}?<br />Select all that apply</p>
      <div className={classes.iconContainer}><CloseIcon className={classes.icon} onClick={handleClose} /></div>
    </div>
  )
}

export default ReminderModal
