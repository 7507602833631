import { makeStyles } from '@material-ui/core/styles';
import { Button } from 'library/materialUI/Button';
import { Icon } from 'library/materialUI/Icon';
import Dropzone from 'react-dropzone';

const useStyles = makeStyles(theme => ({
  fileIcon: {
    color: 'gray',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  fileIconAccepted: {
    color: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  content: {
    margin: 8,
    textAlign: 'center',
    padding: 10,
    border: 'dotted grey 1px'
  },
  uploadBtn: {
    color: theme.palette.primary.main
  },
  dropText: {
    margin: 2
  }
}))

interface CSVDropZoneProps {
  handleDrop: (CSVFile: any) => void
  CSVFile?: File | null
}

export default function CSVDropZone ({ handleDrop, CSVFile }: CSVDropZoneProps) {
  const classes = useStyles()
  let displayText = (
    <>
      <p className={classes.dropText}>Drag a file here</p>
      <p className={classes.dropText}>-or-</p>
    </>
  )
  let btnText = 'Upload from computer'
  const dropHandler = (file: any) => {
    handleDrop(file[0])
  }

  if (CSVFile) {
    displayText = (<><p>{CSVFile.name}</p></>)
    btnText = 'UPLOAD A DIFFERENT FILE'
  }

  return (
    <Dropzone
      onDrop={dropHandler}
      accept='.csv'
    >
      {({ getRootProps, getInputProps }) => (
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <div className={classes.content}>
            <div className={CSVFile ? classes.fileIconAccepted : classes.fileIcon}>
              <Icon icon='file' size='lg' />
            </div>
            {displayText}
            <Button
              startIcon={<Icon icon='upload' size='sm' />}
              variant='outlined'
              size='small'
              className={classes.uploadBtn}
              style={{ textTransform: 'none' }}
            >
              {btnText}
            </Button>
          </div>
        </div>
      )}
    </Dropzone>
  )
}
