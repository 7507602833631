import { doPatch, doPost } from 'api/api';
import GenericObject from './genericObject';
import { UserMapping } from './intergrationUserMapping';
import { useDoQuery } from './useDoQuery';

export const initalMapping: IntegrationMappings = {
  mapping: [],
  user_mapping: [],
  route_mapping: []
}
export interface IntegrationMappings {
  mapping: any
  user_mapping: UserMapping[]
  route_mapping: any[]
}
export class Integration extends GenericObject {
  config: Record<any, any>
  enabled: boolean
  eventSettings: Record<any, any>
  name: string
  mappings: IntegrationMappings

  constructor ({ row }: { row: Record<string, any> }) {
    super({ row })
    const integration = row.attributes
    this.config = integration.config
    this.enabled = integration.enabled
    this.eventSettings = integration.event_settings
    this.name = integration.integration_name
    this.mappings = integration.mappings
  }

  static loadOne (integrationName: string): { data: Integration, isLoading: boolean } {
    return useDoQuery({
      path: `/integrations/${integrationName}`,
      objectClass: Integration
    })
  }

  static create (integration: Integration['name']) {
    const data = {
      type: 'integrations',
      attributes: {
        integration_name: integration,
        enabled: false
      }
    }
    return doPost({ path: '/integrations', data })
  }

  static update (integration: Integration) {
    const data = {
      attributes: {
        ...integration
      }
    }
    return doPatch({ path: `/integrations/${integration.name.toLowerCase()}`, data: data })
  }
}
