import { Button } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import DesertIsland from 'img/DesertIsland.svg'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
  button: {
    color: theme.palette.primary.contrastText,
    fontWeight: 'bold',
    backgroundColor: theme.palette.primary.light,
    border: `${theme.palette.secondary.main} 1px solid`
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 10,
    boxSizing: 'border-box',
    height: '100vh',
    width: '100vw'
  },
  header: {
    paddingTop: 30,
    paddingBottom: 30,
    display: 'flex',
    textAlign: 'center',
    font: theme.typography.fontFamily,
    maxWidth: 320
  }
  }))

function MobileRedirect () {
  const classes = useStyles()
  const history = useHistory()

  function redirectToChat () {
    const newPath = '/chat'
    history.push(newPath)
  }
const redirectMsg = 'We\'re sorry but this feature is not supported on our mobile application. Click the button below to be taken to our mobile chat'
  return (
    <div className={classes.container}>
      <img src={DesertIsland} alt='desert island' />
      <Typography variant='h5' className={classes.header}> {redirectMsg}</Typography>
      <Button
        variant='contained'
        size='medium'
        className={classes.button}
        onClick={redirectToChat}
      >
        Go To Chat
      </Button>
    </div>
  )
}

export default MobileRedirect
