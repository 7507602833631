import { useContext, useEffect, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import MessageEntry from './MessageEntry'
import ActiveConversationDisplay from './ActiveConversationDisplay'
import VisitorHeader from './VisitorHeader'
import Sidebar from './sidebar/Sidebar'
import { SessionContext } from 'session-context'
import { ConversationProps, ConversationProvider } from './ConversationContext'
import { getOnboardingData, setOnboardingData } from 'api/onboarding'
/* eslint-disable react-hooks/exhaustive-deps */

const useStyles = makeStyles(theme => ({
  activeConversation: {
    position: 'relative',
    display: 'grid',
    height: '100vh',
    width: '100%',
    gridTemplateAreas:
      `"contact sidebar"
    "messages sidebar"
    "message-entry sidebar"`,
    gridTemplateColumns: 'minmax(400px, calc(100% - 340px)) minmax(290px, 340px)',
    gridTemplateRows: '90px calc(100% - 210px) 120px'
  },
  messages: {
    gridArea: 'messages'
  },
  deletedConversation: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  deleteIcon: {
    height: 112,
    width: 112,
    color: '#B894EC'
  },
  subtitle: {
    maxWidth: 325,
    textAlign: 'center',
    margin: '0 0 20px'
  },
  button: {
    backgroundColor: 'white',
    border: '#B894EC 1px solid',
    color: '#B894EC'
  },
  islandIcon: {
    height: 300
  }
}))

function ActiveConversation (props: ConversationProps) {
  const { user, participant } = useContext(SessionContext)
  const classes = useStyles()
  const messageRef = useRef(null)
  const [checkOnboarding, setCheckOnboarding] = useState(false)

  useEffect(() => {
    if (checkOnboarding === false) {
      getOnboardingData('client_onboarding')
      .then(response => {
          if (response) {
            const data = JSON.parse(response.settings)
            data.learn_live_chat = true
            setOnboardingData('client_onboarding', data)
          }
        }
      )
      setCheckOnboarding(true)
    }
  }, [checkOnboarding])

  return (
    <div className={classes.activeConversation}>
      <ConversationProvider setMessage={messageRef.current} {...props}>
        <div style={{ gridArea: 'contact' }}>
          <VisitorHeader
            conversation={props.conversation}
            refreshConversation={props.refreshConversation}
          />
        </div>
        {/* This ID is for the Agent Chat Onboarding, do not remove */}
        <div id='conversation_history' style={{ gridArea: 'messages' }}>
          <ActiveConversationDisplay
            conversation={props.conversation}
            participant={participant}
          />
        </div>
        <div style={{ gridArea: 'sidebar' }}>
          <Sidebar open={props.open} setOpen={props.setOpen} />
        </div>
      </ConversationProvider>
      <div style={{ gridArea: 'message-entry' }}>
        <MessageEntry
          conversation={props.conversation}
          messageRef={messageRef}
          user={user}
          conferenceOpen={props.conferenceOpen}
          setConferenceOpen={props.setConferenceOpen}
          calendarOpen={props.calendarOpen}
          setCalendarOpen={props.setCalendarOpen}
        />
      </div>
    </div>
  )
}

export { ActiveConversation }
