import React, { useState, useRef } from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import Button from '@material-ui/core/Button'
import useInterval from 'use-interval'

function Alert (props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />
}

const VersionManager = (props) => {
  const [open, setOpen] = useState(false)
  const versionRef = useRef('6')

  useInterval(() => {
    fetch('/ui/version.txt', { cache: 'no-store' })
      .then(response => response.text())
      .then(response => {
        if (versionRef.current !== response) {
          setOpen(true)
        } else {
          setOpen(false)
        }
      })
  }, 60000)

  return (
    <Snackbar open={open} autoHideDuration={6000} action='Refresh'>
      <Alert
        severity='info' action={
          <Button
            color='secondary'
            size='small'
            variant='contained'
            onClick={() => {
              window.location.reload(true)
            }}
          >Refresh
          </Button>
        }
      >
        Updates to Signals are ready!
      </Alert>
    </Snackbar>
  )
}

export default VersionManager
