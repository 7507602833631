import { DateObj } from 'library/filter/dates/DateRangeMenu'

export const getStartingDate = (type: 'object' | 'array' = 'object') => {
  const today = new Date()
  const previousWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 6)
  let dateObject
  if (type === 'object') {
    dateObject = {
      startDate: previousWeek,
      endDate: today
    }
    return dateObject as DateObj
  } else {
    dateObject = [previousWeek, today]
    return dateObject as [Date, Date]
  }
}
