import { makeStyles } from '@material-ui/core/styles'
import { BaseNodeComponent } from 'jsplumbtoolkit-react'
import { Button, Card, Icon } from 'library/materialUI'
import { SequenceExitPort } from './SequenceExitPort'
import { assignKey } from 'library/flowBuilder/nodes/baseNode/helpers'
import { NodeHeader } from 'library/flowBuilder/nodes/baseNode/NodeComponents'

const useStyles = makeStyles(theme => ({
  node: {
    width: 210,
    padding: '2px 8px'
  },
  endFlowIndicator: {
    position: 'absolute',
    marginTop: 12,
    backgroundColor: '#FF5D5C',
    color: 'white',
    fontWeight: 600,
    padding: 5,
    display: 'flex',
    justifyContent: 'center',
    borderRadius: 2,
    width: 216
  },
  addButton: {
    color: '#333399',
    backgroundColor: 'white',
    fontSize: '14px',
    border: '1px solid #333399',
    outline: '1px',
    width: '100%',
    height: 32
  },
  portConnection: {
    position: 'absolute',
    borderRadius: 50,
    height: 16,
    width: 16,
    border: '1px solid white',
    backgroundColor: '#333399',
    top: 6,
    left: -6
  }
}))

const getNextID = (ports: any[]) => {
  const IDs = ports.map(p => parseInt(p.id.replace('exit', ''))).filter(id => !isNaN(id))
  IDs.push(0)
  return IDs.sort()[IDs.length - 1] + 1
}

type ExitNodeProps = { base: BaseNodeComponent}
function ExitNode (props: ExitNodeProps) {
  const classes = useStyles()
  const base = props.base
  const nodeMapping = window.flowBuilder.nodeMapping
  const nodeState = base.state
  const mapping = nodeMapping[nodeState.kind]

  function addNewPort () {
    const max = getNextID(nodeState.ports)
    const id = 'exit' + max
    const label = 'Exit ' + max
    const port = {
      id: id,
      label: label
    }
    base.addNewPort('default', port)
  }

  function removePort (portID: string) {
    const currentNode = base.getNode()
    base.toolkit.removePort(currentNode, portID)
  }

  function updateLabel (p: any, value: string) {
    const port = base.state.id + '.' + p.state.id
    const newData = {
      ...p.state,
      label: value
    }
    base.toolkit.updatePort(port, newData)
  }

  return (
    <div>
      <Card className={classes.node}>
        <div>
          <NodeHeader
            mapping={mapping}
            base={props.base}
          />
        </div>
        {nodeState.ports.map((p: any, index: number) => (
          <SequenceExitPort
            key={assignKey(p)}
            index={index}
            data={p}
            parent={props.base}
            color={mapping.color}
            updateLabel={updateLabel}
            removePort={removePort}
          />
        ))}
        <div style={{ height: 40, width: 210, marginTop: 10 }}>
          <Button
            variant='outlined'
            fullWidth
            onClick={addNewPort}
            startIcon={<Icon icon='add' />}
            classes={{ outlined: classes.addButton }}
          >
            <div className={classes.portConnection} />
            Add Play Exit
          </Button>
          <jtk-target
            scope='default'
          />
        </div>
      </Card>
      <div className={classes.endFlowIndicator}>
        End of Play
      </div>
    </div>
  )
}

class SequenceExitNode extends BaseNodeComponent {
  render () {
    return (
      <div className='base-node'>
        <ExitNode base={this} />
      </div>
    )
  }
}

export default SequenceExitNode
