import { useContext, useState, useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { FlowBuilderContext, selectionProps, Node } from '../FlowBuilderProvider'
import { Emitter, EVENT_TYPE } from '../helpers/EventEmitter'
import { OptionButton } from './OptionsMenu'
import DeleteIcon from '@material-ui/icons/Delete'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import AddBoxIcon from '@material-ui/icons/AddBox'
import AccountTreeIcon from '@material-ui/icons/AccountTree'

const useStyles = makeStyles(theme => ({
  selectionMenu: {
    position: 'absolute',
    backgroundColor: '#985CE6BF',
    color: 'white',
    zIndex: 1,
    height: 54,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 20,
    borderTopRightRadius: 30,
    borderBottomRightRadius: 30
  },
  title: {
    width: 160
  },
  buttons: {
    display: 'flex'
  },
  button: {
    marginRight: 15
  }
}))

export function SelectionMenu (): JSX.Element {
  const classes = useStyles()
  const [selection, setSelection] = useState<Node[]>([])
  const { toolkit, objectType } = useContext(FlowBuilderContext)
  const canSequenceRef = useRef(false)

  useEffect(() => {
    const selectionCallback = (payload: selectionProps) => {
      if (payload.toolkit.id === toolkit.id) {
        const nodeKinds = toolkit.getSelection().getNodes().map(node => node.data.kind)
        if (nodeKinds.includes('Start') || nodeKinds.includes('Sequence')) {
          canSequenceRef.current = false
        } else {
          canSequenceRef.current = true
        }
        setSelection(payload.selection)
      }
    }
    if (toolkit) {
      Emitter.on(EVENT_TYPE.NODE_SELECTION, selectionCallback)
    }
    return () => {
      Emitter.remove(EVENT_TYPE.NODE_SELECTION, selectionCallback)
    }
  }, [toolkit])

  const length = selection.length

  if (!length) {
    return <></>
  }

  const buttonAction = (kind: string): void => {
    Emitter.emit(EVENT_TYPE.SELECTION_OPTION, { kind, toolkitID: toolkit.id })
  }

  const deleteButton = {
    icon: DeleteIcon,
    label: 'Delete Selection',
    action: () => buttonAction('deleteSelection')
  }
  const cloneButton = {
    icon: FileCopyIcon,
    label: 'Clone Selection',
    action: () => buttonAction('cloneSelection')
  }
  const selectDescendentsButton = {
    icon: AccountTreeIcon,
    label: 'Select Descendents',
    singleOnly: true,
    action: () => buttonAction('selectDescendents')
  }
  const sequenceButton = {
    icon: AddBoxIcon,
    label: objectType === 'bots' ? 'Sequence Selection' : 'Create Play',
    action: () => Emitter.emit(EVENT_TYPE.SEQUENCE_SELECTION, {}),
    disabled: !canSequenceRef.current
  }

  const buttons = objectType === 'sequences' ? [deleteButton, cloneButton, selectDescendentsButton]
  : [deleteButton, cloneButton, selectDescendentsButton, sequenceButton]

  const title = length > 1 ? length + ' skills selected' : '1 skill selected'

  return (
    <div className={classes.selectionMenu}>
      <div className={classes.title}>
        {title}
      </div>
      <div className={classes.buttons}>
        {buttons.map((button: any, index) => {
          if (button.singleOnly && length > 1) {
            return <div key={index} />
          }
          return (
            <div className={classes.button} key={index}>
              <OptionButton button={button} />
            </div>
          )
      })}
      </div>
    </div>
  )
}
