import { Sequence } from 'classes/sequences'
import { useContext, useEffect, useReducer, useRef } from 'react'
import { FlowBuilderContext } from '../FlowBuilderProvider'
import { Emitter, EVENT_TYPE } from '../helpers/EventEmitter'
import { VersionHistoryDrawer } from './VersionHistoryDrawer'

export type VersionHistoryState = {
  versions: Sequence[]
  draftID: number | null
  publishedID: number | null
  menuOpen: boolean
  sequenceID: number | null
}

const initialState: VersionHistoryState = {
  versions: [],
  draftID: null,
  publishedID: null,
  menuOpen: false,
  sequenceID: null
}

const reducer = (state: any, changes: any) => {
  return { ...state, ...changes }
}

export const VersionMenu = (): JSX.Element => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const { save, toolkit } = useContext(FlowBuilderContext)
  const saveRef = useRef(save)
  const toolkitRef = useRef(toolkit)
  saveRef.current = save
  toolkitRef.current = toolkit

  useEffect(() => {
    Emitter.on(EVENT_TYPE.VERSION_HISTORY, (payload: any) => {
      if (toolkit.id === payload.toolkitID) {
        const { versions, draftID, publishedID, menuOpen, sequenceID } = payload
        dispatch({ versions, draftID, publishedID, menuOpen, sequenceID })
      }
    })
  }, [toolkit.id])

  return (
    <VersionHistoryDrawer
      state={state}
      hideMenu={() => dispatch({ menuOpen: false })}
      save={() => saveRef.current(state.draftID).then(() => dispatch({ menuOpen: false }))}
    />
  )
}
