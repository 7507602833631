import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { FieldArray } from 'formik'
import { Typography, Divider, Button } from '@material-ui/core'
import Route from './Route'
import RouteModal from './RouteModal'

const useStyles = makeStyles(theme => ({
  divider: {
    backgroundColor: theme.palette.primary.main,
    marginBottom: 10
  },
  subtitle: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}))

const methodLabelMapping = {
  single_agent: 'Route to a single agent',
  round_robin_group: 'Round robin within a group',
  round_robin_team: 'Round robin within a team',
  quick_draw_group: 'Quick draw within a group',
  quick_draw_team: 'Quick draw within a team'
}

function RoutingDiv (props) {
  const classes = useStyles()
  const [routeModalOpen, setRouteModalOpen] = useState()

  const values = props.values
  const router = props.botType === 'team_live_chat_router'

  let title = 'How should the live chat be routed?'
  if (props.botType === 'team_live_chat_router') {
    title = 'Route to live chat from a button'
  }

  function saveRoute ({ index, user_ids, method, team_id, welcome, away, label }) {
    props.setFieldValue(`teams[${index}].method`, method)
    props.setFieldValue(`teams[${index}].user_ids`, user_ids)
    props.setFieldValue(`teams[${index}].team_id`, team_id)
    props.setFieldValue(`teams[${index}].welcome`, welcome)
    props.setFieldValue(`teams[${index}].away`, away)
    props.setFieldValue(`teams[${index}].label`, label)
  }

  return (
    <FieldArray
      name='teams'
      render={arrayHelpers => {
        function createNewRoute () {
          arrayHelpers.push({
            routeOptions: [{ method: 'single_agent', type: 'round_robin', objectIDs: null }],
            label: 'Click to edit',
            welcome: 'Routing you to an agent now',
            away: 'Sorry. No one is available to chat right now'
          })
        }
        return (
          <div style={{ marginTop: 30 }}>
            <div className={classes.subtitle}>
              <Typography variant='subtitle1'>
                {title}
              </Typography>
              {router
                ? (
                  <Button
                    color='primary'
                    onClick={createNewRoute}
                  >
                    + Add new Button
                  </Button>)
                : <></>}
            </div>
            <Divider className={classes.divider} />
            <div style={{ display: 'flex' }}>
              {values.teams.map((team, index) => {
                return (
                  <Route
                    index={index}
                    key={index}
                    label={router ? team.label : methodLabelMapping[team.method]}
                    icon='liveChat'
                    modal={(
                      <RouteModal
                        open={routeModalOpen === index}
                        onHide={() => setRouteModalOpen(-1)}
                        save={(values) => saveRoute({ index, ...values })}
                        selectedUserIds={team.user_ids}
                        method={team.method}
                        selectedTeamId={team.team_id}
                        welcome={team.welcome}
                        away={team.away}
                        label={team.label}
                      />
                    )}
                    setModalOpen={(_) => {
                      setRouteModalOpen(index)
                    }}
                    removeRoute={router && (() => arrayHelpers.remove(index))}
                  />
                )
              })}
            </div>
          </div>
        )
      }}
    />
  )
}

export default RoutingDiv
