import { useDoQuery } from './useDoQuery'
import { GenericObject } from './genericObject'

interface TagList {
  list: Tag[]
  dict: {[id: Tag['id']]: Tag}
}

export class Tag extends GenericObject {
  static loadAll (): { data: TagList, isLoading: boolean } {
    return useDoQuery({ path: '/tags', objectClass: Tag, useChatURL: true })
  }

  constructor ({ row }: { row: Record<string, any> }) {
    super({ row })
  }
}

export default Tag
