import { doPost, doGet, doDelete, doPut, doPatch } from './api'

function getConversation ({ conversation_id, includes }) {
  let includeString = ''
  if (includes) {
    includeString = '?include=' + includes.join()
  }
  return doGet({ path: `/conversations/${conversation_id}` + includeString, useChatURL: true })
}

function getConversationEvents ({ conversationID }) {
  return doGet({ path: `/conversations/${conversationID}/events`, useChatURL: true })
}

function updateEmailCapture (conversationID) {
  return doPost({ path: `/conversations/${conversationID}/email_capture`, useChatURL: true })
}

function getFilterCounts () {
  return doGet({ path: '/conversation_counts/filter_counts', useChatURL: true })
}

function initiateConversation ({ visitorParticipantID, message, startUrl }) {
  return doPost({
    path: '/conversations/agent_initiated',
    data: {
      type: 'conversations',
      attributes: {
        visitor_participant_id: visitorParticipantID,
        message: message,
        start_url: startUrl
      }
    },
    useChatURL: true
  })
}

function batchUpdateStatus ({ status, conversationIDs }) {
  return doPost({
    path: '/conversations:set_status',
    data: {
      attributes: {
        conversation_ids: conversationIDs,
        status: status
      }
    },
    useChatURL: true
  })
}

function batchUpdateReviewStatus ({ reviewed, conversationIDs }) {
  return doPost({
    path: '/conversations:set_review_status',
    data: {
      attributes: {
        conversation_ids: conversationIDs,
        reviewed_status: reviewed
      }
    },
    useChatURL: true
  })
}

const batchDeleteConversations = ({ conversationIDs }) => {
  return doDelete({
    path: '/conversations',
    data: {
      attributes: {
        conversation_ids: conversationIDs
      }
    },
    useChatURL: true
  })
}

function batchUpdateAgents ({ conversationIDs, participantIDs }) {
  return doPost({
    path: '/conversations:add_agents',
    data: {
      attributes: {
        conversation_ids: conversationIDs,
        participant_ids: participantIDs
      }
    },
    useChatURL: true
  })
}

function getConversationFromList ({ activeConversationID }) {
  if (!activeConversationID) {
    return Promise.resolve({ data: null })
  }
  return doGet({
    path: `/conversation_list/${activeConversationID}`,
    useChatURL: true
  })
}

/**
 * Include `conversationFilters.participant_id` with `conversationFilters.values.filter = 'all_conversations'` to
 * get all conversations for a specific participant
 *
 * @param {{
 * chatServiceUrl?: string;
 * limit?: number;
 * page?: number;
 * conversationFilters: {
 *  kind: string;
 *  values: { [k: string]: any; }
 * };
 * }} param0
 * @returns {Promise<{ data: any[]; }>}
 */
function getConversationList ({ conversationFilters, limit, page, activeConversationID }) {
  const data = {
    attributes: {
      conversation_filters: conversationFilters,
      active_conversation_id: activeConversationID
    }
  }

  if (limit) {
    data.attributes.limit = limit
  }
  if (page) {
    data.attributes.page = page
  }
  return doPost({ path: '/conversation_list', data, useChatURL: true })
}

function createConversationView ({ attributes }) {
  return doPost({
    path: '/conversation_views',
    data: {
      type: 'conversation_views',
      attributes: attributes
    },
    useChatURL: true
  })
}

function deleteConversationView ({ id }) {
  return doDelete({ path: `/conversation_views/${id}`, useChatURL: true })
}

function updateConversationView ({ id, attributes }) {
  return doPut({
    path: `/conversation_views/${id}`,
    data: {
      type: 'conversation_views',
      attributes: attributes
    },
    useChatURL: true
  })
}

export function getConversationViews () {
  return doGet({ path: '/conversation_views', useChatURL: true })
}

export function patchConversation ({ conversationID, data }) {
  return doPatch({ path: `/conversations/${conversationID}`, data, useChatURL: true })
}

export const changeConversationStatus = ({ status, conversationID, chatServiceUrl }) => {
  return doPatch({
    path: `/conversations/${conversationID}`,
    data: {
      type: 'conversations',
      id: conversationID,
      attributes: {
        status: status
      }
    },
    useChatURL: true
  })
}

export const deleteConversation = ({ conversationID }) => {
  return doDelete({ path: `/conversations/${conversationID}`, useChatURL: true })
}

export {
  getConversation,
  getConversationEvents,
  getFilterCounts,
  initiateConversation,
  batchUpdateReviewStatus,
  batchUpdateStatus,
  batchUpdateAgents,
  batchDeleteConversations,
  getConversationList,
  createConversationView,
  updateConversationView,
  deleteConversationView,
  getConversationFromList,
  updateEmailCapture
}
