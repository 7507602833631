import { Segment } from 'classes/segments'
import CSVUploadModal from 'library/segments/CSVUploadModal'
import { Dispatch, SetStateAction, useState } from 'react'
import SegmentEditInfoModal, { SegmentEditInfoModalProps } from './SegmentEditInfoModal'
import { SegmentSetupModal } from './SegmentSetupModal'
import { defaultSegment } from 'pages/segments/segmentHelpers'
import { isEqual } from 'lodash'
import { DynamicSegmentProvider } from './DynamicSegmentContext'

export interface SegmentModalProps {
  segment?: Segment
  objectType: Segment['objectType']
  open: boolean
  setSegmentModalOpen: Dispatch<SetStateAction<boolean>>
}

export default function SegmentModal ({ segment, objectType, open, setSegmentModalOpen }: SegmentModalProps): JSX.Element {
  const [curSegment, setCurSegment] = useState<Segment>(segment || defaultSegment)
  const [segmentCriteriaModalOpen, setCriteriaModalOpen] = useState(false)
  const [openCSVModal, setOpenCSVModal] = useState(false)

  const handleFirstModalSave: SegmentEditInfoModalProps['onNext'] = (newSegment) => {
    newSegment.objectType = objectType
    setCurSegment(newSegment)
    setSegmentModalOpen(false)
    setCriteriaModalOpen(true)
  }

  const handleGoBack = (newSegment: Segment) => {
    setCurSegment(newSegment)
    setCriteriaModalOpen(false)
    setSegmentModalOpen(true)
  }

  const editTriggers = (newSegment: Segment) => {
    setCurSegment(newSegment)
  }

  const handleSave = (values: {
    triggers: Segment['triggers']
  }): void => {
    const updateTriggers = values.triggers !== undefined && !isEqual(values.triggers, curSegment.triggers)
    if (updateTriggers) {
      curSegment.triggers[0].filters = [...values.triggers[0].filters.filter((f) => f.prop !== '')]
    }
    const newSegment = Object.assign(curSegment, { segObjectType: 'domains' })
    Segment.save(newSegment).then(() => {
      setSegmentModalOpen(false)
      setCriteriaModalOpen(false)
      const newDefaultSegment = Object.assign(defaultSegment, { triggers: [{ filters: [] }] })
      setCurSegment(newDefaultSegment)
    })
  }

  return (
    <>
      <DynamicSegmentProvider segment={curSegment} save={handleSave}>
        <SegmentEditInfoModal
          segment={curSegment}
          open={open}
          onHide={() => {
            setSegmentModalOpen(false)
          }}
          onNext={handleFirstModalSave}
          currentSegments={[]}
        />
        <SegmentSetupModal
          open={segmentCriteriaModalOpen}
          onBack={handleGoBack}
          segment={curSegment}
          editTriggers={editTriggers}
          onHide={() => setCriteriaModalOpen(false)}
          save={handleSave}
          openCSVModal={() => { setCriteriaModalOpen(false); setOpenCSVModal(true) }}
        />
        <>
          {openCSVModal &&
            <CSVUploadModal
              handleGoBack={() => { setOpenCSVModal(false); setCriteriaModalOpen(true) }}
              closeCSVModal={() => setOpenCSVModal(false)}
              open={openCSVModal}
              segment={curSegment}
            />}
        </>
      </DynamicSegmentProvider>
    </>
  )
}
