import React, { useState, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import InboxIcon from '@material-ui/icons/Inbox'
import SearchIcon from '@material-ui/icons/Search'
import ChatModeFilters from './ChatModeFilters'
import SearchMode from './SearchMode'
import { SessionContext } from 'session-context'

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 260,
    fontFamily: theme.typography.fontFamily,
    height: '100%',
    display: 'grid',
    gridTemplateRows: '72px auto'
  },
  header: {
    borderBottom: 'solid rgba(0, 0, 0, .125) 2px',
    padding: 5
  },
  mode: {
    display: 'grid',
    height: 30,
    alignItems: 'center',
    gridGap: 10,
    gridTemplateColumns: '25px auto',
    fontSize: '16px',
    cursor: 'pointer'
  },
  selected: {
    color: theme.palette.primary.main,
    fontWeight: 600
  },
  unselected: {
    color: '#ccc'
  }
}))

function DrawerContents (props) {
  if (!props.open) {
    return <></>
  }
  if (props.mode === 'chat') {
    return (
      <ChatModeFilters />
    )
  }
  if (props.mode === 'search') {
    return (
      <SearchMode />
    )
  }
  return <></>
}

function FilterDrawer (props) {
  const classes = useStyles()
  const [mode, setMode] = useState('chat')
  const { user } = useContext(SessionContext)
  const reviewPerm = user.attributes.perms.manager

  return (
    <Drawer
      variant='persistent'
      open={props.open}
      ModalProps={{
        container: document.getElementById('agent-chat-window'),
        style: { position: 'absolute', left: 2 }
      }}
      PaperProps={{ style: { position: 'absolute', left: 2 } }}
      BackdropProps={{ style: { position: 'absolute', left: 2 } }}
    >
      <div id='left-menu-bar' className={classes.drawer}>
        <div className={classes.header}>
          <div
            className={`${classes.mode} ${mode === 'chat' ? classes.selected : classes.unselected}`}
            onClick={() => setMode('chat')}
          >
            <InboxIcon />
            Conversations
          </div>
          {reviewPerm &&
            <div
              className={`${classes.mode} ${mode === 'search' ? classes.selected : classes.unselected}`}
              onClick={() => setMode('search')}
            >
              <SearchIcon />
              Search and review
            </div>}
        </div>
        <DrawerContents mode={mode} open={props.open} />
      </div>
    </Drawer>
  )
}

export default FilterDrawer
