import Checkbox from '@material-ui/core/Checkbox'
import ListItemText from '@material-ui/core/ListItemText'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { getAllMeetingInfo } from 'api/meetings'
import { useCallback, useEffect, useState } from 'react'
import { doGet } from 'api/api'
import './quill.css'

const useStyles = makeStyles(theme => ({
  subtitle: {
    color: '#999',
    fontFamily: 'Poppins, sans serif',
    fontSize: '12px'
  },
  divider: {
    marginTop: 10,
    marginBottom: 10
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    marginRight: 8,
    marginBottom: 3,
    backgroundColor: '#975ce6',
    color: 'white'
  },
  input: {
    padding: 6
  },
  inputRoot: {
  },
  select: {
    minHeight: 32
  }
}))

const UserPicker = props => {
  const classes = useStyles(props)
  const [users, setUsers] = useState([])
  const [validUserIds, setValidUserIds] = useState([])
  const selectedUserIds = props.selectedUserIds
  const setSelectedUserIds = props.setSelectedUserIds

  const getUserList = useCallback(() => {
    doGet({ path: '/users?include=calendar_status' })
      .then(user_response => {
        let userDict = {}
        user_response.data.map(user => (userDict = { ...userDict, [user.id]: { ...user } }))

        setUsers(userDict)
        if (props.kind === 'calendar') {
          const usersWithBookMeetingsPerm = user_response.data
            .filter(row => row.attributes.perms.book_meetings)
            .map(row => row.id)
          const hasConferencing = user_response.included
            .filter(row => {
              const atts = row.attributes
              return atts.has_calendar === 'True' && atts.conferencing_connected
            })
            .map(user => user.id)
          const hasCalendar = user_response.included
            .filter(row => {
              const atts = row.attributes
              return atts.has_calendar === 'True' && atts.connected && usersWithBookMeetingsPerm.includes(row.id)
            })
            .map(row => row.id)
          getAllMeetingInfo()
            .then(response => {
              const usersWithMeetingInfo = response.data.filter(row => {
                const conferencingType = row?.attributes?.conferencing_type || ''
                switch (conferencingType) {
                  case 'url':
                    return !!row?.attributes?.location_url
                  case '':
                    return false
                  default:
                    return hasConferencing.includes(row.attributes.user_id)
                }
              }).map(row => row.attributes.user_id)
              const validUsers = usersWithMeetingInfo.filter(user_id => hasCalendar.includes(user_id))
              setValidUserIds(validUsers)
            })
        } else if (props.kind === 'hasChat') {
          const usersWithChatPerms = []
          for (const userID in userDict) {
            const user = userDict[userID]
            if (user.attributes.perms.send_chats) {
              usersWithChatPerms.push(user.id)
            }
          }

          setValidUserIds(usersWithChatPerms)
        } else {
          const user_ids = Object.keys(userDict).map(Number)
          setValidUserIds(user_ids)
        }
      })
  }, [props.kind])

  const handleChange = (event) => {
    setSelectedUserIds(event.target.value)
  }

  useEffect(() => {
    getUserList()
  }, [getUserList])

  if (users.length === 0) {
    return <div style={{ height: 42 }} />
  }

  const value = selectedUserIds.length > 0 ? selectedUserIds[0] : ''
  if (validUserIds.length === 0) {
    return (
      <div className='subtitle' style={{ marginTop: 30, fontSize: 19, textAlign: 'center' }}>
        No users have their calendar setup.
      </div>
    )
  }

  if (props.single) {
    return (
      <Select
        fullWidth
        variant='outlined'
        value={value}
        onChange={(e) => setSelectedUserIds([e.target.value])}
        margin='dense'
        displayEmpty
      >
        <MenuItem disabled value=''>Select an agent</MenuItem>
        {validUserIds.map(id => {
          const user = users[id]
          const name = user.attributes.name
          return (
            <MenuItem key={id} value={id} style={{ height: 40 }}>
              {name}
            </MenuItem>
          )
        })}
      </Select>
    )
  }

  let values = selectedUserIds
  let user_ids = Object.keys(users)
  user_ids = user_ids.map(Number)
  values = values.filter(v => user_ids.includes(v))

  if (props.autocomplete) {
    const activeUsers = []
    for (const validId of validUserIds) {
      activeUsers.push(users[validId])
    }
    return (
      <>
        <Autocomplete
          options={activeUsers}
          fullWidth
          value={activeUsers.filter(u => selectedUserIds.includes(u.id))}
          multiple
          classes={{ tag: classes.chip, inputRoot: classes.select }}
          autoHighlight
          onChange={(event, obj) => {
            const currUserIds = obj.map(user => user.id)
            setSelectedUserIds(currUserIds)
          }}
          getOptionLabel={(option) => option.attributes.name}
          renderOption={(option) => option.attributes.name}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder='Select agents'
              variant='outlined'
              margin='dense'
              className={classes.select}
              inputProps={{ ...params.inputProps }}
              style={{ borderRadius: 40 }}
            />
          )}
        />
      </>
    )
  }

  return (
    <div className='select'>
      <Select
        multiple
        fullWidth
        variant='outlined'
        value={values}
        onChange={handleChange}
        margin='dense'
        displayEmpty
        renderValue={(selected) => selected.length === 0 ? 'Select agents' : selected.map(id => users[id].attributes.name).join(', ')}
      >
        <MenuItem value='' disabled>Select agents</MenuItem>
        {validUserIds.map(id => {
          const user = users[id]
          const name = user.attributes.name
          return (
            <MenuItem key={id} value={id} style={{ height: 40 }}>
              <Checkbox checked={selectedUserIds.indexOf(id) > -1} color='primary' />
              <ListItemText primary={name} />
            </MenuItem>
          )
        })}
      </Select>
    </div>
  )
}

export default UserPicker
