import Card from 'library/materialUI/Card'
import IntegrationMapping from '../IntegrationMapping'
import { useContext } from 'react'
import { IntegrationPageContext } from '../IntegrationPage/IntegrationPageProvider'

interface LeadFieldMappingCardProps {
  save: any
  handleEdit: any
  integrationSettings: any
  leadExternalFields: any
}

export default function LeadFieldMappingCard ({ save, handleEdit, integrationSettings, leadExternalFields }: LeadFieldMappingCardProps) {
  const groups = [{ value: 'leads', name: 'Lead Fields' }, { value: 'custom', name: 'Custom Fields' }]
  const { handleSaved } = useContext(IntegrationPageContext)

  const runEdit = async (data: any) => {
    await handleEdit({ type: 'mapping', data: data })
    save()
    handleSaved()
  }

  return (
    <Card
      content={
        <>
          <IntegrationMapping
            currentMapping={integrationSettings.attributes.mappings
              .mapping}
            externalMappingFields={leadExternalFields}
            forObject='people'
            mappedGroups={groups}
            integrationName='Salesforce'
            handleEdit={(data: any) => { runEdit(data) }}
            title='Lead Field Mapping'
            disableOverwrite={undefined}
          />
        </>
      }
    />
  )
}
