/* eslint-disable @typescript-eslint/naming-convention */
import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Select,
  MenuItem,
  Typography,
  IconButton,
  Button
} from '@material-ui/core'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import { getUsers } from '../../../api/users'
import ControlPointIcon from '@material-ui/icons/ControlPoint'
import { UserMapping as UserMappingClass } from 'classes/intergrationUserMapping'
import { IntegrationUsers } from 'classes/integrationUsers'

const useStyles = makeStyles((theme) => ({
  subtitle1: {
    fontSize: 18,
    fontWeight: 600,
    marginBottom: 15
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  rowHeader: {
    alignItems: 'center',
    paddingBottom: 20,
    fontSize: 16,
    display: 'grid',
    gridTemplateColumns: '48px 210px auto 210px 150px',
    gridGap: 10,
    gridTemplateAreas: '"a cf b integration c"',
    maxWidth: 1100
  },
  bodyText: {
    marginBottom: 30,
    fontSize: 16
  },
  row: {
    display: 'grid',
    gridTemplateColumns: '48px 210px auto 210px 150px',
    gridGap: 10,
    maxWidth: 1100,
    alignItems: 'center',
    paddingBottom: 20
  },
  mappingRow: {
    display: 'flex',
    width: '50%',
    maxWidth: '50%'
  },
  fieldGroup: {
    display: 'grid',
    gridTemplateColumns: '230px auto',
    width: '100%'
  },
  select: {
    height: 40,
    width: 210
  },
  customField: {
    height: 40
  },
  customInput: {
    height: 40,
    marginTop: 0,
    width: 170
  },
  dottedLine: {
    borderTop: '2px dotted #975ce6',
    height: 10,
    marginTop: 20,
    width: '100%'
  },
  menuHeader: {
    borderBottom: '1px solid #BBB',
    width: '100%',
    fontWeight: 600,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: 'rgba(0,0,0,0.87)'
  },
  menuItem: {
    paddingTop: 3,
    paddingBottom: 3,
    paddingLeft: 25,
    fontSize: '.95em'
  }
}))

interface UserMappingRowProps {
  index: number
  users: any
  row: any
  integrationUsers: any
  deleteMapping: (index: number) => void
  editMapping: (value: any) => void
  integrationName: string
  mapping: UserMappingClass[]
  oneToOne?: boolean
}

const UserMappingRow = ({ index, users, row, integrationUsers, deleteMapping, editMapping, integrationName, mapping, oneToOne }: UserMappingRowProps) => {
  const classes = useStyles()
  const { newUsers, newIntegrationUsers } = HandleOneToOne({ users, mapping, integrationUsers, oneToOne, row })

  return (
    <>
      <div className={classes.row}>
        <IconButton>
          <HighlightOffIcon
            onClick={() => deleteMapping(index)}
            color='primary'
          />
        </IconButton>
        <Select
          variant='outlined'
          margin='dense'
          defaultValue='preview'
          className={classes.select}
          value={row.cf_user_id === 0 ? '' : row.cf_user_id}
          onChange={(e) => {
            editMapping({
              field: 'cf_user_id',
              value: e.target.value,
              index: index
            })
          }}
        >
          <MenuItem disabled style={{ opacity: 1 }}>
            <div className={classes.menuHeader}>Signals Users</div>
          </MenuItem>
          {newUsers.map((user: any, index: number) => {
            return (
              <MenuItem key={index} className={classes.menuItem} value={user.id}>
                {user.attributes.name}
              </MenuItem>
            )
          })}
        </Select>
        <div className={classes.dottedLine} />
        <Select
          variant='outlined'
          margin='dense'
          className={classes.select}
          value={row.integration_user_id}
          onChange={(e) => {
            editMapping({
              field: 'integration_user_id',
              value: e.target.value,
              index: index
            })
          }}
        >
          <MenuItem disabled style={{ opacity: 1 }}>
            <div className={classes.menuHeader}>{integrationName} Users</div>
          </MenuItem>
          {newIntegrationUsers.map((integrationUser: any, index: number) => {
            const values = 'attributes' in integrationUser ? integrationUser.attributes : integrationUser
            return (
              <MenuItem
                key={index}
                value={values.id}
                className={classes.menuItem}
              >
                {values?.name}
              </MenuItem>
            )
          })}
        </Select>
      </div>
    </>
  )
}

interface UserMappingProps {
  currentMapping: any
  integrationName: string
  integrationUsers: IntegrationUsers[]
  handleEdit: (value: any) => void
  oneToOne?: boolean
}

const UserMapping = ({ currentMapping, integrationName, integrationUsers, handleEdit, oneToOne }: UserMappingProps) => {
  const classes = useStyles()
  const [users, setUsers] = useState([])
  const [mapping, setMapping] = useState(currentMapping)

  // The users are mapped in a dicitonary fashion: {[user_id]: [integration_user_id]}
  const editMapping = ({ field, value, index }: { field: any, value: any, index: number }) => {
    if (!index) {
      index = 0
    }
    setMapping([...mapping], (mapping[index][field] = value))
    handleEdit(mapping)
  }

  const addMapping = () => {
    mapping.push({
      cf_user_id: 0,
      integration_user_id: ''
    })
    handleEdit(mapping)
  }

  const deleteMapping = (index: any) => {
    mapping.splice(index, 1)

    handleEdit(mapping)
  }

  useEffect(() => {
    getUsers().then(response => {
      setUsers(response.data)
    })
  }, [])

  useEffect(() => {
    if (currentMapping.length > 0) {
      setMapping(currentMapping)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMapping, currentMapping])

  return (
    <>
      <div className={classes.header}>
        <Typography className={classes.subtitle1}>
          User Mapping
        </Typography>
      </div>
      <Typography variant='body2' className={classes.bodyText}>
        Map Signals users to their corresponding {integrationName} users.
      </Typography>
      <div>
        <div className={classes.rowHeader}>
          <div style={{ gridArea: 'cf' }}>Signals User</div>
          <div style={{ gridArea: 'integration' }}>{integrationName} User</div>
        </div>
        {mapping.map((userMapping: any, index: number) => (
          <UserMappingRow
            users={users}
            integrationUsers={integrationUsers}
            row={userMapping}
            key={index}
            integrationName={integrationName}
            index={index}
            editMapping={editMapping}
            deleteMapping={deleteMapping}
            mapping={mapping}
            oneToOne={oneToOne}
          />
        ))}
        <div style={{ marginTop: 20 }}>
          <Button
            variant='outlined'
            color='primary'
            onClick={addMapping}
            startIcon={<ControlPointIcon />}
          >
            Add User
          </Button>
        </div>
      </div>
    </>
  )
}

export default UserMapping

interface HandleOneToOneProps {
  users: {id: number}[]
  mapping: UserMappingClass[]
  integrationUsers: IntegrationUsers[]
  row: UserMappingClass
  oneToOne?: boolean
}

interface HandleOneToOneResult {
  newUsers: {id: number}[]
  newIntegrationUsers: IntegrationUsers[]
}

function HandleOneToOne ({ users, mapping, integrationUsers, oneToOne, row }: HandleOneToOneProps) : HandleOneToOneResult {
  if (!oneToOne) {
    return { newUsers: users, newIntegrationUsers: integrationUsers }
  }
  const sigUsersInMapping = mapping.map(um => um.cf_user_id)
  const intUsersInMapping = mapping.map(um => um.integration_user_id)
  const newUsers = users.filter(user => !sigUsersInMapping.includes(user.id) || row.cf_user_id === user.id)
  const newIntegrationUsers = integrationUsers.filter(iu => !intUsersInMapping.includes(iu.id) || row.integration_user_id === iu.id)
  return { newUsers, newIntegrationUsers }
}
