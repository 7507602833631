import InformationIcon from 'library/InformationIcon';
import { Typography } from 'library/materialUI';
import SegmentCriteriaButtons from './SegmentCriteriaButtons';

export default function SegmentCriteria () {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '50%', paddingTop: 10, paddingLeft: 15, minWidth: 330 }}>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: 15 }}>
        <Typography style={{ fontSize: 16, marginRight: 10, fontWeight: 'bold' }}> Segment Filter Criteria </Typography>
        <InformationIcon message='Companies must meet ALL of the criteria listed here to be included in the segment' />
      </div>
      <SegmentCriteriaButtons />
    </div>
  )
}
