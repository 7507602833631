import { Typography, Dialog } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles'
import { Button } from './materialUI';
import { IconButton } from './materialUI/Button';
import { useState } from 'react'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: theme.typography.fontFamily,
    overflowY: 'hidden'
  },
  paper: {
    margin: 32,
    position: 'relative',
    overflowY: 'hidden'
  },
  body: {
    maxWidth: 800,
    maxHeight: 600
  },
  imgContainer: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#F5F5F5'
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    padding: 20
  },
  closeBtn: {
    position: 'absolute',
    right: 10,
    top: 10
  },
  button: {
    whiteSpace: 'nowrap',
    textTransform: 'none',
    padding: '5px 12px',
    marginLeft: 10,
    fontSize: 14,
    fontFamily: 'poppins',
    backgroundColor: theme.palette.primary.alt,
    minWidth: 80,
    color: 'white',
    '&:hover': {
      backgroundColor: '#6941A1'
    },
    '&:disabled': {
      backgroundColor: '#E2E2E2'
    },
    borderRadius: '5px !important'
  }
}))

interface IntroModalProps {
  open: boolean
  onClose: () => void
  onAction: () => void
  onSkip?: () => void
  title?: string
  subtitle?: string
  imgSrc?: string
  skipBtnText?: string
  actionBtnText?: string
}

export default function IntroModal ({ open, onClose, onAction, onSkip, title, subtitle, imgSrc, skipBtnText, actionBtnText }: IntroModalProps): JSX.Element {
  const classes = useStyles()
  const [loaded, setLoaded] = useState(false)
  const img = new Image()
  img.src = imgSrc
  img.onload = () => setLoaded(true)

  const handleClose = (_event: any, reason: string): void => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return
    }
    onClose()
  }

  return (
    <Dialog
      onClose={handleClose}
      className={classes.root}
      classes={{ paper: classes.paper }}
      open={open && loaded}
      fullWidth
      maxWidth='md'
    >
      <div className={classes.body}>
        <div className={classes.closeBtn}>
          <IconButton
            color='primary'
            size='md'
            icon='close'
            onClick={() => onClose()}
          />
        </div>
        <div className={classes.imgContainer}>
          {!loaded && <div style={{ width: 300, height: 300 }} />}
          <img src={imgSrc} alt='intro' height='100%' width='100%' />
        </div>
        <div className={classes.footer}>
          {title &&
            <Typography variant='h1' style={{ marginBottom: 10, color: 'primary', fontWeight: 700 }}>
              {title}
            </Typography>}
          {subtitle && <Typography variant='body2'>{subtitle}</Typography>}
          <div style={{ marginLeft: 'auto', marginTop: 20 }}>
            {onSkip &&
              <Button
                onClick={() => onSkip()}
                variant='text'
                color='primary'
                size='small'
              >
                {skipBtnText || 'Skip for now'}
              </Button>}
            <Button
              onClick={() => onAction()}
              variant='contained'
              color='primary'
              className={classes.button}
            >
              {actionBtnText || 'Let\'s go!'}
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  )
}
