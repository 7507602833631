import { useDoQuery } from './useDoQuery'
import { SearchParams } from './queryHelpers'
import { GenericObject } from './genericObject'
import { doDelete, doPatch, doPost, doGet } from 'api/api'
import { RecentActivity } from './recentActivity'
import { route } from 'library/routing/RoutingCriteria'

const htmlButtonPlacementMap: Record<string, string> = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
  full: 'center',
  inline: 'flex-start'
}
export class FormSubmission extends GenericObject {
  formID: number
  contactID: number
  participantID: number
  data: Object
  prompt: boolean
  firstName?: string
  lastName?: string
  contactAvatar?: string
  droppedCalendar?: string
  meetingBooked?: boolean
  liveChat?: boolean
  agentName?: string
  agentAvatar?: string
  icp?: boolean
  companyName?: string

  static loadOne (id: number): { data: FormSubmission, isLoading: boolean } {
    return useDoQuery({ useChatURL: true, path: `/forms/submissions/${id}`, objectClass: FormSubmission })
  }

  constructor ({ row }: { row: Record<string, any> }) {
    super({ row })
    const fs = row.attributes
    this.formID = fs.form_id
    this.contactID = fs.contact_id
    this.participantID = fs.participant_id
    this.data = fs.data
    this.prompt = fs.prompt
    this.firstName = fs.first_name
    this.lastName = fs.last_name
    this.contactAvatar = fs.contact_avatar
    this.droppedCalendar = fs.dropped_calendar
    this.meetingBooked = fs.meeting_booked
    this.liveChat = fs.live_chat
    this.agentName = fs.agent_name
    this.agentAvatar = fs.agent_avatar
    this.icp = fs.icp
    this.companyName = fs.company_name
  }
}

export interface FormField {
  id: number
  fieldType: 'title' | 'subtitle' | 'field'
  fieldID: string
  fieldLabel: string
  placeholder: string
  helperText: string
  isRequired: boolean
  isHidden: boolean
  options: Array<string>
  blockEmail: boolean
  spamUnwanted: boolean
  nonBusinessUnwanted: boolean
  blockedDomainsUnwanted: boolean
  sourceType: 'contact' | 'account'
  inputType: string
  hiddenDefault: string
  isCustomField: boolean
}

interface FormList {
  list: Form[]
  dict: { [id: Form['id']]: Form }
}

export class FormSummary extends GenericObject {
  prompts: number
  meetingsBooked: number
  pageViews: number
  liveChats: number
  submissions: number

  get submissionRate (): string {
    if (!this.submissions || !this.pageViews) {
      return '0.00%'
    }
    const rate = ((this.submissions / this.pageViews) * 100).toFixed(2).toString() + '%'
    return rate
  }

  get promptRate (): string {
    if (!this.prompts || !this.submissions) {
      return '0.00%'
    }
    const rate = ((this.prompts / this.submissions) * 100).toFixed(2).toString() + '%'
    return rate
  }

  get liveChatRate (): string {
    if (!this.liveChats || !this.prompts) {
      return '0.00%'
    }
    const rate = ((this.liveChats / this.prompts) * 100).toFixed(2).toString() + '%'
    return rate
  }

  get meetingBookedRate (): string {
    if (!this.meetingsBooked || !this.prompts) {
      return '0.00%'
    }
    const rate = ((this.meetingsBooked / this.prompts) * 100).toFixed(2).toString() + '%'
    return rate
  }

  constructor ({ row }: { row: Record<string, any> }) {
    super({ row })
    const summary = row.attributes
    this.prompts = summary.prompts || 0
    this.meetingsBooked = summary.meetings_booked || 0
    this.liveChats = summary.live_chats || 0
    this.pageViews = summary.page_views || 0
    this.submissions = summary.submissions || 0
  }
}
interface FormStyle {
  backgroundColor: string
  padding: string
  width: string
  cornerRadius: string
  dropShadow: boolean
}

interface TitleStyle {
  fontSize: string
  color: string
}
interface SubtitleStyle {
  fontSize: string
  color: string
}
interface LabelStyle {
  fontSize: string
  color: string
}
interface FieldStyle {
  backgroundColor: string
  borderColor: string
  textColor: string
  borderStyle: 'all' | 'bottom' | 'none'
  cornerRadius: string
}
interface HelperTextStyle {
  fontSize: string
  color: string
}
interface ButtonStyle {
  fontSize: string
  fillType: 'solid' | 'gradient'
  color: string
  primaryBackgroundColor: string
  secondaryBackgroundColor: string
  dropShadow: boolean
  align: 'left' | 'center' | 'right' | 'inline'
  width: string
  cornerRadius: string
  onClick: 'shadow' | 'downward-shift' | 'shrink' | 'none'
  onHover: 'darken' | 'lighten' | 'invert' | 'none'
}
interface FormStyles {
  form: FormStyle
  title: TitleStyle
  subtitle: SubtitleStyle
  label: LabelStyle
  field: FieldStyle
  helperText: HelperTextStyle
  button: ButtonStyle
}

interface EmailSettings {
  email_mode: string
  group_send: string
  email_recipients: Array<number>
  team_id: number
}

interface LeadScoreSettings {
  type: string
  score: number
}

const rowLengthSizeMap: Record<number, string> = {
  0: '100%',
  1: '100%',
  2: '50%',
  3: '33.33%',
  4: '25%',
  5: '20%'
}

export default class Form extends GenericObject {
  name: string
  description: string
  prompts: number
  conversations: number
  emailsCaptured: number
  meetingsBooked: number
  primaryGoals: number
  secondaryGoals: number
  formLayout: Array<Array<FormField>> | null
  buttonText: string
  buttonPlacement: string
  displayThankYou: boolean
  thankYouMessage: string
  redirectToExternal: boolean
  redirectURL: string
  emailSettings: EmailSettings[]
  integrationsV2: Record<string, Array<string>> | null
  integrationCriteria: 'all' | 'segments' | null
  integrationSegments: number[] | null
  integrationExcludedSegments: number[] | null
  createdByUserID: number
  updatedByUserID: number
  createNewFromNewEmail: boolean
  prepopulate: boolean
  followUp: boolean
  targeting: string
  targetSegmentList: number[]
  targetExcludedSegmentList: number[]
  targetLocationList: string[]
  promptHeader: string
  promptMessage: string
  liveChat: boolean
  liveChatButtonText: string
  chatRoutingSettings: route[]
  chatRoutedMessage: string
  chatTimeoutSeconds: number
  chatTimeoutMessage: string
  chatTimeoutAction: string
  chatBookMeeting: boolean
  chatMeetingType: number
  chatMeetingRoutingSettings: route[]
  chatMeetingTextReminder: boolean
  chatMeetingFollowupEmail: boolean
  chatMeetingFollowupEmailID: number
  chatMapComment: boolean
  chatContactMapAttribute: string
  bookMeeting: boolean
  bookMeetingButtonText: string
  meetingRoutingSettings: route[]
  meetingType: number
  meetingTextReminder: boolean
  meetingFollowupEmail: boolean
  meetingFollowupEmailID: number
  offlineHeader: string
  offlineMessage: string
  offlineBookMeeting: boolean
  offlineMeetingRoutingSettings: route[]
  offlineMeetingButtonText: string
  offlineMeetingType: number
  offlineTextReminder: boolean
  offlineFollowupEmail: boolean
  offlineFollowupEmailID: number
  buttonSameAsPrimary: boolean
  buttonColor: string
  showAgentProfiles: boolean
  agentProfiles: string[]
  liveChatBotID: number
  calendarBotID: number
  offlineBotID: number
  formExported: Date | string | null
  emailToSend: number | null
  emailDelay: number | null
  noStyle: boolean
  style: FormStyles | null
  leadScoreSettings: LeadScoreSettings[]
  buttonIcon: string | null
  useButtonIcon: boolean | null
  emailRecipients: number[]
  objectType = 'form'

  get link (): string { return `/forms/${this.id}` }

  static loadOne (id: number): { data: Form, isLoading: boolean } {
    return useDoQuery({ useChatURL: true, path: `/forms/${id}`, objectClass: Form })
  }
  static load (id: number): Promise<Form> {
    return doGet({ path: `/forms/${id}`, useChatURL: true }).then((r) => new Form({ row: r.data }))
  }

  static loadAll (): { data: FormList, isLoading: boolean } {
    return useDoQuery({
      path: '/forms',
      objectClass: Form,
      useChatURL: true
    })
  }

  static loadRecentActivities (ID: Form['id']): { data: any, isLoading: boolean } {
    const searchParams: SearchParams = {
      filters: [[
        {
          field: 'form_id',
          operator: 'eq',
          value: ID
        }
      ]],
      pageNumber: 1,
      pageSize: 5,
      sortColumn: 'created_timestamp',
      sortOrder: 'desc'
    }

    return useDoQuery({
      path: '/forms/submissions',
      useChatURL: true,
      objectClass: FormSubmission,
      searchParams,
      handleCustomResponse: (r) => {
        r.list = r.list.map((fs) => {
          let summary = 'Prompt not shown'
          if (fs.prompt) {
            summary = 'Prompt shown, no action taken'
            if (fs.liveChat) {
              summary = `Live Chat with ${fs.agentName}`
            } else if (fs.meetingBooked) {
              summary = `Meeting Booked with ${fs.droppedCalendar}`
            } else if (fs.droppedCalendar) {
              summary = `${fs.droppedCalendar}'s calendar shown`
            }
          }
          return new RecentActivity({
            avatar: fs.contactAvatar,
            summary,
            timestamp: fs.createdTimestamp.toISOString(),
            name: `${fs.firstName} ${fs.lastName}`,
            url: `/#/forms/${fs.formID}`
          })
        })
        return r
      }
    })
  }

  static loadStats (ID: Form['id']): { data: any, isLoading: boolean } {
    return useDoQuery({ useChatURL: true, path: `/forms/${ID}/report`, objectClass: FormSummary })
  }

  static loadSubmission (ID: number): Promise<any> {
    return doGet({ path: `/forms/submissions/${ID}`, useChatURL: true })
  }

  static getRosterData (ID: number): Promise<any> {
    return doGet({ path: `/forms/roster/${ID}`, useChatURL: true })
  }

  static save ({ data, silent = false }: { data: Form, silent: boolean }): Promise<any> {
    let path = '/forms'
    const body = {
      attributes: { ...data }
    }

    if (data?.id) {
      path += `/${data.id}`
      return doPatch({ path, data: body, useChatURL: true, silent })
    } else { return doPost({ path, data: body, useChatURL: true, silent }) }
  }

  static create (): void {
    window.open('/#/form/new')
  }

  async clone (): Promise<string> {
    const path = `/forms/${this.id}:clone`
    return doPost({ path, useChatURL: true }).then((response: any) => {
      return '/forms/' + response.data.id
    })
  }

  static removeForm (id: number, silent: boolean): Promise<any> {
    const path = `/forms/${id}`
    return doDelete({ path, useChatURL: true, silent })
  }

  delete () {
    const path = `/forms/${this.id}`
    return doDelete({ path, useChatURL: true })
  }

  static hasOneField (form: Form): boolean {
    let count = 0
    form.formLayout?.forEach((row: FormField[]) => {
      row.forEach((field: FormField) => {
        if (field.fieldType === 'field') count++
      })
    })
    return count === 1
  }

  static generateScript (form: Form, packetCode: string, pretty: boolean): string {
    let hostname = window.location.hostname
    if (window.location.port === '12345') {
      hostname += ':' + window.location.port
    }
    let t = '\t'
    let n = '\n'
    let autocomplete = 'autocomplete="off"'
    if (!pretty) {
      autocomplete = ''
      t = ''
      n = ''
    }
    let formStyle = ''
    let buttonStyle = ''
    if (!form.noStyle) {
      formStyle = `all: unset;display:block;background-color:${form.style?.form.backgroundColor};text-align:left;`
      formStyle += `padding:${form.style?.form.padding};`
      formStyle += `border-radius:${form.style?.form.cornerRadius};`
      if (form.style?.form.dropShadow) {
        formStyle += 'box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);'
      }
      if (form.style?.form.width.endsWith('px')) {
        formStyle += `width:${form.style?.form.width};`
      }
      const buttonHeight = 2.5 * parseInt(form.style?.button.fontSize || '0')

      buttonStyle = 'all: unset;text-align: center;'
      buttonStyle += `height: ${buttonHeight}px;`
      buttonStyle += `color:${form.style?.button.color};`
      buttonStyle += `border-radius:${form.style?.button.cornerRadius};`
      buttonStyle += `font-size:${form.style?.button.fontSize};`
      buttonStyle += `width:${form.style?.button.width};`
      if (form.style?.button.dropShadow) {
        buttonStyle += 'box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);'
      }
      if (form.style?.button.fillType === 'gradient') {
        buttonStyle += `background: linear-gradient(45deg, ${form.style?.button.primaryBackgroundColor} 0%, ${form.style?.button.secondaryBackgroundColor} 100%);`
      } else {
        buttonStyle += `background-color:${form.style?.button.primaryBackgroundColor};`
      }
    }
    let formScript = `<div style="${formStyle}"><form id="signals-form-${form.id}" ${autocomplete} class="signals-form"  hx-post="https://${hostname}/api/public/forms/${form.id}/submit" hx-disabled-elt="this"`
    if (form.displayThankYou) formScript += ` hx-swap="innerHTML" hx-trigger="submit" hx-on="htmx:beforeRequest: this.querySelector('#signals-submit-btn-${form.id}').disabled = true"`
    formScript += '>'
    formScript += `${n}<input type="hidden" name="packet_code" value="${packetCode}">`
    form?.formLayout?.forEach((row) => {
      row?.forEach((field: FormField) => {
        if (field.isHidden && field?.hiddenDefault) {
          formScript += `${n}<input type="hidden" id="${field.fieldID}" name="${field.fieldID}" value="${field.hiddenDefault}" />`
        }
      })
    })
    form?.formLayout?.forEach((row) => {
      if (row.length === 0) return
      formScript += `${n}${t}<div class="signals-form-row" style="display:flex;width:100%;">`
      row?.forEach((field: FormField) => {
        if (!field.isHidden) {
          formScript += `${n}${t}${t}<div class="signals-form-field" style="display:flex;flex-direction:column;padding:5px;width:${rowLengthSizeMap[row.length]}">`
          if (field.fieldType === 'field') {
            formScript += Form.generateHTMLFormField(form, field, n, t, packetCode, buttonStyle)
          } else if (field.fieldType === 'title' && field.fieldLabel !== '') {
            if (form.noStyle) {
              formScript += `${n}${t}${t}${t}<h1 class="signals-form-header">${field.fieldLabel}</h1>`
            } else {
              let style = ''
              style += `all: unset; font-size:${form.style?.title.fontSize};`
              style += `color:${form.style?.title.color};`
              formScript += `${n}${t}${t}${t}<div class="signals-form-header" style="${style}">${field.fieldLabel}</div>`
            }
          } else if (field.fieldType === 'subtitle' && field.fieldLabel !== '') {
            if (form.noStyle) {
              formScript += `${n}${t}${t}${t}<h3 class="signals-form-paragraph">${field.fieldLabel}</h3>`
            } else {
              let style = ''
              style += `all: unset; font-size:${form.style?.subtitle.fontSize};`
              style += `color:${form.style?.subtitle.color};`
              formScript += `${n}${t}${t}${t}<div class="signals-form-paragraph" style="${style}">${field.fieldLabel}</div>`
            }
          }
          formScript += `${n}${t}${t}</div>`
        }
      })
      formScript += `${n}${t}</div>`
    })
    if (!Form.hasOneField(form) || form?.style?.button.align !== 'inline') {
      formScript += Form.generateHTMLSubmitButton(form, packetCode, buttonStyle, n, t)
    }
    formScript += `${n}</form></div>`
    return formScript.replaceAll('class="ql-align-center"', 'style="text-align: center"').replaceAll('class="ql-align-right"', 'style="text-align: right"')
  }
  static generateHTMLSubmitButton (form: Form, packetCode: string, buttonStyle: string, n: string, t: string, inline?: boolean): string {
    let buttonHtml = ''
    if (!inline) {
      buttonHtml = `${n}${t}<div class="signals-form-submit" style="display:flex;align-items:center;justify-content:${htmlButtonPlacementMap[form.style?.button.align || 'right']};padding:5px;">`
    }
    let buttonClick = ''
    let buttonIconHTML = ''
    const buttonIconMapping = {
      outboundFill: 'outbound_fill.svg',
      arrowCircleRight: 'arrow_circle_right_fill.svg',
      expandCircleRight: 'expand_circle_right_fill.svg',
      outboundFillWhite: 'outbound_fill_white.svg',
      arrowCircleRightWhite: 'arrow_circle_right_fill_white.svg',
      expandCircleRightWhite: 'expand_circle_right_fill_white.svg',
      matArrowForward: 'material-arrow-forward.svg',
      keyboardArrowRight: 'material-keyboard-arrow-right.svg',
      callMade: 'material-call-made.svg'
    }
    const iconStyle = `style="width: ${form.style?.button.fontSize || 14}px; height: ${form.style?.button.fontSize || 14}px; margin-left: 5px; vertical-align: middle;"`
    if (packetCode === '') {
      buttonClick = 'onClick="return false"'
    }
    let onMouseDown = ''
    let onMouseUp = ''
    let onMouseOver = ''
    let onMouseOut = ''
    if (!form.noStyle) {
      switch (form.style?.button.onClick) {
        case 'shadow':
          onMouseDown = "this.style.boxShadow='0px 0px 0px'"
          onMouseUp = "this.style.boxShadow='0px 4px 4px rgba(0, 0, 0, 0.25)'"
          break
        case 'downward-shift':
          onMouseDown = "this.style.transform='translateY(2px)'"
          onMouseUp = "this.style.transform='translateY(0px)'"
          break
        case 'shrink':
          onMouseDown = "this.style.transform='scale(0.98)'"
          onMouseUp = "this.style.transform='scale(1)'"
          break
      }
      switch (form.style?.button.onHover) {
        case 'darken':
          onMouseOver = "this.style.filter='brightness(90%)'"
          onMouseOut = "this.style.filter='brightness(100%)'"
          break
        case 'lighten':
          onMouseOver = "this.style.filter='brightness(110%)'"
          onMouseOut = "this.style.filter='brightness(100%)'"
          break
        case 'invert':
          if (form.style?.button.fillType === 'gradient') {
            onMouseOver = `this.style.background='linear-gradient(45deg, ${form.style?.button.secondaryBackgroundColor} 0%, ${form.style?.button.primaryBackgroundColor} 100%)'`
            onMouseOut = `this.style.background='linear-gradient(45deg, ${form.style?.button.primaryBackgroundColor} 0%, ${form.style?.button.secondaryBackgroundColor} 100%)'`
          } else {
            onMouseOver = `this.style.backgroundColor='${form.style?.button.color}';this.style.color='${form.style?.button.primaryBackgroundColor}'`
            onMouseOut = `this.style.backgroundColor='${form.style?.button.primaryBackgroundColor}';this.style.color='${form.style?.button.color}'`
          }
          break
      }
    }

    if (form.useButtonIcon && form.buttonIcon && buttonIconMapping[form.buttonIcon]) {
      buttonIconHTML = `<img src="https://cdn.jsdelivr.net/gh/chatfunnels/cdn@main/${buttonIconMapping[form.buttonIcon]}" ${iconStyle}/>`
    }
    buttonHtml += `${n}${t}${t}<button id="signals-submit-btn-${form.id}" type="submit" ${buttonClick} onMouseOver="${onMouseOver}" onMouseOut="${onMouseOut}" onMouseDown="${onMouseDown}" onMouseUp="${onMouseUp}"  style="${buttonStyle}${inline ? 'margin-left:10px;' : ''}">${form.buttonText}${buttonIconHTML}</button>`
    if (!inline) {
      buttonHtml += `${n}${t}</div>`
    }
    return buttonHtml
  }
  static generateHTMLFormField (form: Form, field: FormField, n: string, t: string, packetCode: string, buttonStyle: string): string {
    const labelStyle = 'style="text-align: left"'
    let fieldScript = ''
    let classes = ''
    if (field.fieldLabel && field.fieldLabel !== '') {
      let labelStyle = ''
      if (!form.noStyle) {
        labelStyle += `all: unset; font-size:${form.style?.label.fontSize};`
        labelStyle += `color:${form.style?.label.color};`
      }
      fieldScript += `${n}${t}${t}${t}<label style="${labelStyle}" for='${field.fieldID}'>${field.fieldLabel}${field.isRequired ? '*' : ''}</label>`
    }
    let fieldStyle = ''
    if (!form.noStyle) {
      fieldStyle = 'all: unset;padding: 5px;'
      if (Form.hasOneField(form) && form?.style?.button.align === 'inline') fieldStyle += 'width: 96%;'
      fieldStyle += `background-color:${form.style?.field.backgroundColor};`
      if (form.style?.field.borderStyle === 'all') fieldStyle += `border: 1px solid ${form.style?.field.borderColor};`
      else if (form.style?.field.borderStyle === 'bottom') fieldStyle += `border-bottom: 1px solid ${form.style?.field.borderColor};`
      else fieldStyle += 'border: none;'
      fieldStyle += `border-radius:${form.style?.field.cornerRadius};`
      if (form.style?.field?.textColor) {
        fieldStyle += `color:${form.style?.field.textColor};`
      }
    }
    if (!form.noStyle && Form.hasOneField(form) && form?.style?.button.align === 'inline') {
      fieldScript += `${n}${t}${t}<div style="display:flex;flex-direction:row;align-items:center;">`
      fieldScript += `${n}${t}${t}<div style="width:100%;">`
    }
    if (field.inputType === 'dropdown') {
      fieldScript += `${n}${t}${t}${t}<select style="${fieldStyle} ${form?.style?.button.align === 'inline' ? 'align-items:center;' : ''}" name='${field.fieldID}' id='${field.fieldID}' ${field.isRequired ? `required onfocusout='this.style="${fieldStyle}"'` : ''}>`
      if (field.placeholder) fieldScript += `${n}${t}${t}${t}${t}<option value='' selected>${field.placeholder}</option>`
      field.options.forEach((option) => {
        fieldScript += `${n}${t}${t}${t}${t}<option value='${option}'>${option}</option>`
      })
      fieldScript += `${n}${t}${t}${t}</select>`
    } else if (field.inputType === 'multi') {
      const borderRadius = form.style?.field.cornerRadius === '50px' ? '5px' : form.style?.field.cornerRadius
      fieldScript += `${n}${t}${t}${t}<select style="${fieldStyle}border-radius: ${borderRadius};" multiple name='${field.fieldID}' id='${field.fieldID}' ${field.isRequired ? `required onfocusout='this.style="${fieldStyle}"'` : ''}>`
      field.options.forEach((option) => {
        fieldScript += `${n}${t}${t}${t}${t}<option value='${option}'>${option}</option>`
      })
      fieldScript += `${n}${t}${t}${t}</select>`
    } else if (field.inputType === 'radio') {
      field.options.forEach((option, index) => {
        fieldScript += `${n}${t}${t}${t}<div class="signals-radio" style="display:flex;flex-direction:row;">`
        fieldScript += `${n}${t}${t}${t}${t}<input type='radio' name='${field.fieldID}' id='${option}_${index}' name='${field.fieldID}' value='${option}'/>`
        fieldScript += `${n}${t}${t}${t}${t}<label ${labelStyle} for='${option}_${index}'>${option}</label>`
        fieldScript += `${n}${t}${t}${t}</div>`
      })
    } else if (field.inputType === 'date') {
      fieldScript += `${n}${t}${t}${t}<input style="${fieldStyle}" name='${field.fieldID}' id='${field.fieldID}' type='date' ${field.isRequired ? `required onfocusout='this.style="${fieldStyle}"'` : ''}/>`
    } else if (field.inputType === 'true-false') {
      fieldScript += `${n}${t}${t}${t}<div class="signals-radio" style="display:flex;flex-direction:row;">`
      fieldScript += `${n}${t}${t}${t}${t}<input type='radio' name='${field.fieldID}' id='${field.fieldID}_true' name='${field.fieldID}' value='true'/>`
      fieldScript += `${n}${t}${t}${t}${t}<label ${labelStyle} for='${field.fieldID}_true'>True</label>`
      fieldScript += `${n}${t}${t}${t}</div>`
      fieldScript += `${n}${t}${t}${t}<div class="signals-radio" style="display:flex;flex-direction:row;">`
      fieldScript += `${n}${t}${t}${t}${t}<input type='radio' name='${field.fieldID}' id='${field.fieldID}_false' name='${field.fieldID}' value='false'/>`
      fieldScript += `${n}${t}${t}${t}${t}<label ${labelStyle} for='${field.fieldID}_false'>False</label>`
      fieldScript += `${n}${t}${t}${t}</div>`
    } else if (field.inputType === 'numeric') {
      if (field.fieldID.includes('lead_score')) {
        fieldScript += `${n}${t}${t}${t}<input style="${fieldStyle}" name='${field.fieldID}' id='${field.fieldID}' type='number' ${field.isRequired ? `required onfocusout='this.style="${fieldStyle}"'` : ''} min='0' max='3'/>`
      } else {
        fieldScript += `${n}${t}${t}${t}<input style="${fieldStyle}" name='${field.fieldID}' id='${field.fieldID}' type='number' ${field.isRequired ? `required onfocusout='this.style="${fieldStyle}"'` : ''}/>`
      }
    } else {
      fieldScript += `${n}${t}${t}${t}<input style="${fieldStyle}" name='${field.fieldID}' id='${field.fieldID}' placeholder='${field.placeholder}' ${field.isRequired ? `required onfocusout='this.style="${fieldStyle}"'` : ''}`
      if (field.blockEmail) classes += 'block-email '
      if (field.spamUnwanted) classes += 'spam-unwanted '
      if (field.nonBusinessUnwanted) classes += 'non-business-unwanted '
      if (field.blockedDomainsUnwanted) classes += 'blocked-domains-unwanted '
      if (classes) fieldScript += ` class='${classes}' `
      if (field.fieldID.includes('email')) fieldScript += ' type="email" '
      else if (field.fieldID.includes('phone')) fieldScript += ' class="signals-phone-field" type="tel" '
      else fieldScript += ' type="text" '
      fieldScript += '/>'
    }
    if (!form.noStyle && Form.hasOneField(form) && form?.style?.button.align === 'inline') {
      fieldScript += `${n}${t}${t}</div>`
      fieldScript += Form.generateHTMLSubmitButton(form, packetCode, buttonStyle, n, t, true)
      fieldScript += `${n}${t}${t}</div>`
    }
    if (field.helperText) {
      if (form.noStyle) {
        fieldScript += `${n}${t}${t}${t}<small>${field.helperText}</small>`
      } else {
        let style = ''
        style += `all: unset; font-size:${form.style?.helperText.fontSize};`
        style += `color:${form.style?.helperText.color};`
        fieldScript += `${n}${t}${t}${t}<div style="${style}">${field.helperText}</div>`
      }
    }
    return fieldScript
  }

  constructor ({ row }: { row?: Record<string, any> } = {}) {
    super({ row })
    const form = row?.attributes
    this.name = form.name
    this.description = form.description
    this.prompts = form.prompts
    this.conversations = form.conversations
    this.emailsCaptured = form.emails_captured
    this.meetingsBooked = form.meetings_booked
    this.primaryGoals = form.primary_goals
    this.secondaryGoals = form.secondary_goals
    this.formLayout = form.form_layout
    this.buttonText = form.button_text
    this.buttonPlacement = form.button_placement
    this.displayThankYou = form.display_thank_you
    this.thankYouMessage = form.thank_you_message
    this.redirectToExternal = form.redirect_to_external
    this.redirectURL = form.redirect_url
    this.emailSettings = form.email_settings
    this.integrationsV2 = form.integrations_v2
    this.integrationCriteria = form.integration_criteria
    this.integrationSegments = form.integration_segments
    this.integrationExcludedSegments = form.integration_excluded_segments
    this.createdByUserID = form.created_by_user_id
    this.updatedByUserID = form.updated_by_user_id
    this.createNewFromNewEmail = form.create_new_from_new_email
    this.prepopulate = form.prepopulate
    this.followUp = form.follow_up
    this.targeting = form.targeting
    this.targetSegmentList = form.target_segment_list
    this.targetExcludedSegmentList = form.target_excluded_segment_list
    this.targetLocationList = form.target_location_list
    this.promptHeader = form.prompt_header
    this.promptMessage = form.prompt_message
    this.liveChat = form.live_chat
    this.liveChatButtonText = form.live_chat_button_text
    this.chatRoutingSettings = form.chat_routing_settings
    this.chatRoutedMessage = form.chat_routed_message
    this.chatTimeoutSeconds = form.chat_timeout_seconds
    this.chatTimeoutMessage = form.chat_timeout_message
    this.chatTimeoutAction = form.chat_timeout_action
    this.chatBookMeeting = form.chat_book_meeting
    this.chatMeetingType = form.chat_meeting_type
    this.chatMeetingRoutingSettings = form.chat_meeting_routing_settings
    this.chatMeetingTextReminder = form.chat_meeting_text_reminder
    this.chatMeetingFollowupEmail = form.chat_meeting_followup_email
    this.chatMeetingFollowupEmailID = form.chat_meeting_followup_email_id
    this.chatMapComment = form.chat_map_comment
    this.chatContactMapAttribute = form.chat_contact_map_attribute
    this.bookMeeting = form.book_meeting
    this.bookMeetingButtonText = form.book_meeting_button_text
    this.meetingRoutingSettings = form.meeting_routing_settings
    this.meetingType = form.meeting_type
    this.meetingTextReminder = form.meeting_text_reminder
    this.meetingFollowupEmail = form.meeting_followup_email
    this.meetingFollowupEmailID = form.meeting_followup_email_id
    this.offlineHeader = form.offline_header
    this.offlineMessage = form.offline_message
    this.offlineBookMeeting = form.offline_book_meeting
    this.offlineMeetingRoutingSettings = form.offline_meeting_routing_settings
    this.offlineMeetingButtonText = form.offline_meeting_button_text
    this.offlineMeetingType = form.offline_meeting_type
    this.offlineTextReminder = form.offline_text_reminder
    this.offlineFollowupEmail = form.offline_followup_email
    this.offlineFollowupEmailID = form.offline_followup_email_id
    this.buttonSameAsPrimary = form.button_same_as_primary
    this.buttonColor = form.button_color
    this.showAgentProfiles = form.show_agent_profiles
    this.agentProfiles = form.agent_profiles
    this.liveChatBotID = form.live_chat_bot_id
    this.calendarBotID = form.calendar_bot_id
    this.offlineBotID = form.offline_bot_id
    this.formExported = form.form_exported ? new Date(form.form_exported) : null
    this.emailToSend = form.email_to_send
    this.emailDelay = form.email_delay
    this.noStyle = form.no_style
    this.style = form.style
    this.leadScoreSettings = form.lead_score_settings
    // this.leadScoreSubmit = form.lead_score_submit
    // this.leadScoreBookMeeting = form.lead_score_book_meeting
    // this.leadScoreLiveChat = form.lead_score_live_chat
    this.buttonIcon = form.button_icon || 'matArrowForward'
    this.useButtonIcon = form.use_button_icon
    this.emailRecipients = form.email_recipients
  }
}

interface FieldAction {
  type: string
  value: any
}

export const fieldReducer = (state: FormField, action: FieldAction): FormField => {
  switch (action.type) {
    case 'updateLabel':
      return { ...state, fieldLabel: action.value }
    case 'updatePlaceholder':
      return { ...state, placeholder: action.value }
    case 'updateHelper':
      return { ...state, helperText: action.value }
    case 'updateRequired':
      return { ...state, isRequired: action.value }
    case 'updateHidden':
      return { ...state, isHidden: action.value }
    case 'updatePreview':
      return { ...state, options: action.value }
    case 'updateBlock':
      return { ...state, blockEmail: action.value }
    case 'updateSpam':
      return { ...state, spamUnwanted: action.value }
    case 'updateNonBusiness':
      return { ...state, nonBusinessUnwanted: action.value }
    case 'updateBlockedDomain':
      return { ...state, blockedDomainsUnwanted: action.value }
    case 'updateHiddenDefault':
      return { ...state, hiddenDefault: action.value }
  }
  return state
}

export interface FormAction {
  type: string
  value?: any
  index?: number
  field?: number | string
  subField?: number | string
}

export const formReducer = (state: Form, action: FormAction): Form => {
  switch (action.type) {
    case 'new':
      return action.value
    case 'addRow':
      if (state.formLayout && (action.index || action.index === 0)) {
        return { ...state, formLayout: [...state.formLayout.slice(0, action.index), action.value, ...state.formLayout?.slice(action.index)] }
      } else if (state.formLayout) {
        return { ...state, formLayout: [...state.formLayout, action.value] }
      }
      return { ...state, formLayout: [action.value] }
    case 'deleteRow':
      if (state.formLayout) {
        return { ...state, formLayout: [...state.formLayout.slice(0, action.index), ...state.formLayout.slice((action.index || 0) + 1)] }
      }
      return state
    case 'replaceForm':
      return action.value
    case 'updateRow':
      if (state.formLayout) {
        return { ...state, formLayout: [...state.formLayout.slice(0, action.index), action.value, ...state.formLayout?.slice((action.index || 0) + 1)] }
      }
      return { ...state, formLayout: [action.value] }
    case 'style':
      if (action.field && action.subField) {
        return { ...state, style: { ...state.style, [action.field]: { ...state.style[action.field], [action.subField]: action.value } } }
      }
      return state
    case 'description':
      return { ...state, description: action.value }
    case 'name':
      return { ...state, name: action.value }
    case 'buttonText':
      return { ...state, buttonText: action.value }
    case 'buttonPlacement':
      return { ...state, buttonPlacement: action.value }
    case 'displayThankYou':
      return { ...state, displayThankYou: action.value }
    case 'thankYouMessage':
      return { ...state, thankYouMessage: action.value }
    case 'redirectToExternal':
      return { ...state, redirectToExternal: action.value }
    case 'redirectURL':
      return { ...state, redirectURL: action.value }
    case 'emailSettings':
      return { ...state, emailSettings: action.value }
    case 'emailMode': {
      const tempMode = [...state?.emailSettings]
      tempMode[action?.index || 0].email_mode = action.value
      return { ...state, emailSettings: tempMode }
    }
    case 'groupSend': {
      const tempGroup = [...state?.emailSettings]
      tempGroup[action?.index || 0].group_send = action.value
      return { ...state, emailSettings: tempGroup }
    }
    case 'emailRecipients': {
      const tempRecipients = [...state?.emailSettings]
      tempRecipients[action?.index || 0].email_recipients = action.value
      return { ...state, emailSettings: tempRecipients }
    }
    case 'teamID': {
      const tempTeamId = [...state?.emailSettings]
      tempTeamId[action?.index || 0].team_id = action.value
      return { ...state, emailSettings: tempTeamId }
    }
    case 'addEmailSetting':
      return { ...state, emailSettings: state?.emailSettings?.concat({ email_mode: '', group_send: '', team_id: 0, email_recipients: [] }) || [{ email_mode: '', group_send: '', team_id: 0, email_recipients: [] }] }
    case 'removeEmailSetting':
      return { ...state, emailSettings: state?.emailSettings?.filter((_, i) => i !== action.index) }
    case 'integrations':
      return { ...state, integrationsV2: action.value }
    case 'integrationCriteria':
      return { ...state, integrationCriteria: action.value }
    case 'integrationSegments':
      return { ...state, integrationSegments: action.value }
    case 'integrationExcludedSegments':
      return { ...state, integrationExcludedSegments: action.value }
    case 'createNewFromNewEmail':
      return { ...state, createNewFromNewEmail: action.value }
    case 'prepopulate':
      return { ...state, prepopulate: action.value }
    case 'followUp':
      return { ...state, followUp: action.value }
    case 'targeting':
      return { ...state, targeting: action.value }
    case 'targetSegmentList':
      return { ...state, targetSegmentList: action.value }
    case 'targetExcludedSegmentList':
      return { ...state, targetExcludedSegmentList: action.value }
    case 'targetLocationList':
      return { ...state, targetLocationList: action.value }
    case 'promptHeader':
      return { ...state, promptHeader: action.value }
    case 'promptMessage':
      return { ...state, promptMessage: action.value }
    case 'liveChat':
      return { ...state, liveChat: action.value }
    case 'liveChatButtonText':
      return { ...state, liveChatButtonText: action.value }
    case 'chatRoutingSettings':
      return { ...state, chatRoutingSettings: action.value }
    case 'chatRoutedMessage':
      return { ...state, chatRoutedMessage: action.value }
    case 'chatTimeoutSeconds':
      return { ...state, chatTimeoutSeconds: action.value }
    case 'chatTimeoutMessage':
      return { ...state, chatTimeoutMessage: action.value }
    case 'chatTimeoutAction':
      return { ...state, chatTimeoutAction: action.value }
    case 'chatBookMeeting':
      return { ...state, chatBookMeeting: action.value }
    case 'chatMeetingType':
      return { ...state, chatMeetingType: action.value }
    case 'chatMeetingRoutingSettings':
      return { ...state, chatMeetingRoutingSettings: action.value }
    case 'chatMeetingTextReminder':
      return { ...state, chatMeetingTextReminder: action.value }
    case 'chatMeetingFollowupEmail':
      return { ...state, chatMeetingFollowupEmail: action.value }
    case 'chatMeetingFollowupEmailID':
      return { ...state, chatMeetingFollowupEmailID: action.value }
    case 'chatMapComment':
      return { ...state, chatMapComment: action.value }
    case 'chatContactMapAttribute':
      return { ...state, chatContactMapAttribute: action.value }
    case 'bookMeeting':
      return { ...state, bookMeeting: action.value }
    case 'bookMeetingButtonText':
      return { ...state, bookMeetingButtonText: action.value }
    case 'meetingRoutingSettings':
      return { ...state, meetingRoutingSettings: action.value }
    case 'meetingType':
      return { ...state, meetingType: action.value }
    case 'meetingTextReminder':
      return { ...state, meetingTextReminder: action.value }
    case 'meetingFollowupEmail':
      return { ...state, meetingFollowupEmail: action.value }
    case 'meetingFollowupEmailID':
      return { ...state, meetingFollowupEmailID: action.value }
    case 'offlineHeader':
      return { ...state, offlineHeader: action.value }
    case 'offlineMessage':
      return { ...state, offlineMessage: action.value }
    case 'offlineBookMeeting':
      return { ...state, offlineBookMeeting: action.value }
    case 'offlineMeetingRoutingSettings':
      return { ...state, offlineMeetingRoutingSettings: action.value }
    case 'offlineMeetingButtonText':
      return { ...state, offlineMeetingButtonText: action.value }
    case 'offlineMeetingType':
      return { ...state, offlineMeetingType: action.value }
    case 'offlineTextReminder':
      return { ...state, offlineTextReminder: action.value }
    case 'offlineFollowupEmail':
      return { ...state, offlineFollowupEmail: action.value }
    case 'offlineFollowupEmailID':
      return { ...state, offlineFollowupEmailID: action.value }
    case 'buttonSameAsPrimary':
      return { ...state, buttonSameAsPrimary: action.value }
    case 'buttonColor':
      return { ...state, buttonColor: action.value }
    case 'showAgentProfiles':
      return { ...state, showAgentProfiles: action.value }
    case 'agentProfiles':
      return { ...state, agentProfiles: action.value }
    case 'formExported':
      return { ...state, formExported: new Date() }
    case 'emailDelay':
      return { ...state, emailDelay: action.value }
    case 'emailToSend':
      return { ...state, emailToSend: action.value }
    case 'noStyle':
      return { ...state, noStyle: action.value }
    case 'leadScoreAdd':
      return { ...state, leadScoreSettings: state?.leadScoreSettings?.concat({ type: '', score: -1 }) || [{ type: '', score: -1 }] }
    case 'updateLeadScore': {
      const tempLead = [...state?.leadScoreSettings]
      tempLead[action.index || 0].type = action.value.type
      tempLead[action.index || 0].score = action.value.score
      return { ...state, leadScoreSettings: tempLead }
    }
    case 'leadRemove':
      return { ...state, leadScoreSettings: state?.leadScoreSettings?.filter((_, i) => i !== action.index) }
    case 'buttonIcon':
      return { ...state, buttonIcon: action.value }
    case 'useButtonIcon':
      return { ...state, useButtonIcon: action.value }
  }
  return state
}
