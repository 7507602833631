/* eslint-disable @typescript-eslint/naming-convention */
import { EmailSubscription, EmailSubscriptionTypes, builtinSubscriptionTypes, emailSubType, initialState, typeMapping } from 'classes/emailSubscriptions';
import { ClickAwayListener, List, ListItem, Popover } from 'library/materialUI';
import { useContext } from 'react';
import { components, SessionContext } from 'session-context';

interface EmailSubPopperOptionsProps {
  anchorEl: Element
  onClose: (value: EmailSubscription | null) => void
}

const createNewEmailSub = (type: EmailSubscriptionTypes): EmailSubscription => {
  const newInitialState = Object.assign(initialState, { type: type, name: typeMapping[type], meta: {}, cf_recipients: [] })
  return new EmailSubscription(newInitialState)
}

export default function EmailSubPopoverOptions ({ anchorEl, onClose }: EmailSubPopperOptionsProps) {
  const { isOn } = useContext(SessionContext)
  const hasScoringComponent = isOn(components.SCORE_INSIGHTS)
  const hasPlayMaker = isOn(components.PLAYMAKER)
  const options = emailSubType.filter((type: EmailSubscriptionTypes) => {
    switch (type) {
      case 'contacts':
        return hasPlayMaker
      case 'score_summary':
        return hasScoringComponent
      default:
        return !builtinSubscriptionTypes.includes(type)
    }
  }).map((type: EmailSubscriptionTypes) => {
    return { label: typeMapping[type], value: createNewEmailSub(type) }
  })

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
    >
      <ClickAwayListener onClickAway={() => onClose(null)}>
        <div>
          <List>
            {options.map(option => {
              return (
                <ListItem
                  key={option.label}
                  button
                  onClick={() => onClose(option.value)}
                >
                  {option.label}
                </ListItem>
              )
            })}
          </List>
        </div>
      </ClickAwayListener>
    </Popover>
  )
}
