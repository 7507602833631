import { doPost, doGet, doDelete, doPatch } from './api'

function getTeam ({ teamID }) {
  const path = `/teams/${teamID}`
  return doGet({ path, useChatURL: true })
}

function getTeams () {
  return doGet({ path: '/teams', useChatURL: true })
}

function removeTeamMembers ({ teamID, userIDs }) {
  const path = `/teams/${teamID}/relationships/teammembers`
  const data = userIDs.map(id => ({
    type: 'users',
    id: id
  }))
  return doDelete({ path, useChatURL: true, data: data })
}

function deleteTeams ({ ids }) {
  const path = '/teams'
  const attributes = ids.map(id => ({
    type: 'teams',
    id: id
  }))
  return doDelete({ path, useChatURL: true, data: attributes })
}

function createTeam ({ values }) {
  const body = {
    type: 'teams',
    attributes: {
      user_ids: values.members,
      name: values.name,
      availability_type: 'manual',
      timezone: 'US/Eastern'
    },
    relationships: {}
  }

  return doPost({ path: '/teams', data: body, useChatURL: true })
}

function addTeamMembers ({ values, teamID }) {
  const path = `/teams/${teamID}`
  const body = {
    type: 'teams',
    id: teamID,
    attributes: {
      user_ids: values.members
    },
    relationships: {}
  }
  return doPatch({ path, data: body, useChatURL: true })
}

function saveTeamSettings ({ values, teamID }) {
  const path = `/teams/${teamID}`
  const body = {
    type: 'teams',
    id: teamID,
    attributes: {
      availabilities: values.attributes.availabilities,
      timezone: values.attributes.timezone,
      offline_when_unavailable: values.attributes.offline_when_unavailable,
      availability_type: values.attributes.availability_type,
      online: values.attributes.online
    },
    relationships: {}
  }
  return doPatch({ path, data: body, useChatURL: true })
}

export { getTeams, deleteTeams, createTeam, getTeam, addTeamMembers, saveTeamSettings, removeTeamMembers }
