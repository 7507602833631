import { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Popover from '@material-ui/core/Popover'
import { ChromePicker } from 'react-color'

const useStyles = makeStyles(() => ({
  colorCombo: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 10,
    padding: 5,
    border: '1px solid rgba(0,0,0,0.3)',
    borderRadius: 5,
    color: 'rgba(0,0,0,0.7)',
    cursor: 'pointer',
    '&:hover': {
      borderColor: 'black'
    }
  },
  swatch: {
    borderRadius: '20%',
    height: 20,
    width: 20,
    border: '1px solid rgba(0,0,0,0.3)'
  },
  colorTitle: {
    color: 'rgba(0,0,0,0.7)',
    fontSize: '14px',
    marginLeft: 3
  }
}))

const ColorPicker = ({ title, color, setColor }) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)

  return (
    <>
      <div className={classes.colorCombo} onClick={handleClick}>
        <div className={classes.colorTitle}>
          {title}
        </div>
        <div className={classes.swatch} style={{ backgroundColor: '#' + color }} />
      </div>
      <Popover
        open={open}
        anchorEl={anchorEl}
        closeAfterTransition
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <ChromePicker
          disableAlpha
          color={'#' + color}
          onChange={(color) => {
            setColor(color.hex.substring(1))
          }}
        />
      </Popover>
    </>
  )
}

export default ColorPicker
