import { doGet, doPost } from './api'

export const getSnoozeTimestamp = (type: string) => {
  return doGet({
    path: `/email_warning/get_snooze/${type}`,
    useChatURL: true
  })
}

export const setSnoozeTimestamp = (type: string, timestamp: any) => {
  return doPost({
    data: { attributes: { type: type, timestamp: timestamp } },
    path: '/email_warning/set_snooze',
    useChatURL: true
  })
}
