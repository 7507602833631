import {
  addDays,
  subDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  isSameDay
} from 'date-fns'

const defineds = {
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1))
}

const staticRangeHandler = {
  range: {},
  isSelected (range) {
    const definedRange = this.range()
    return (
      isSameDay(range.startDate, definedRange.startDate) &&
      isSameDay(range.endDate, definedRange.endDate)
    )
  }
}

function createStaticRanges (ranges) {
  return ranges.map(range => ({ ...staticRangeHandler, ...range }))
}

export const startingDateRange = {
  startDate: subDays(defineds.startOfToday, 6),
  endDate: defineds.endOfToday,
  key: 'selection'
}

export function getSessionDateRange () {
  const dateRange = window.sessionStorage.getItem('DateRange')
  if (dateRange) {
    return JSON.parse(dateRange)
  } else {
    return startingDateRange
  }
}

export function setSessionDateRange (dateRange) {
  window.sessionStorage.setItem('DateRange', JSON.stringify(dateRange))
}

export const last30DaysRange = {
  startDate: subDays(defineds.startOfToday, 29),
  endDate: defineds.endOfToday
}

export const customStaticRanges = createStaticRanges([
  {
    label: 'This Week',
    range: () => ({
      startDate: defineds.startOfWeek,
      endDate: defineds.endOfToday
    })
  },
  {
    label: 'Last Week',
    range: () => ({
      startDate: defineds.startOfLastWeek,
      endDate: defineds.endOfLastWeek
    })
  },
  {
    label: 'Last 7 Days',
    range: () => ({
      startDate: subDays(defineds.startOfToday, 6),
      endDate: defineds.endOfToday
    })
  },
  {
    label: 'This Month',
    range: () => ({
      startDate: defineds.startOfMonth,
      endDate: defineds.endOfMonth
    })
  },
  {
    label: 'Last Month',
    range: () => ({
      startDate: defineds.startOfLastMonth,
      endDate: defineds.endOfLastMonth
    })
  },
  {
    label: 'Last 30 Days',
    range: () => ({
      startDate: subDays(defineds.startOfToday, 29),
      endDate: defineds.endOfToday
    })
  },
  {
    label: 'Last 90 Days',
    range: () => ({
      startDate: subDays(defineds.startOfToday, 89),
      endDate: defineds.endOfToday
    })
  }
])
