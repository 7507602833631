import { SurfaceDropComponent } from 'jsplumbtoolkit-react-drop'
import useStyles from '../nodes/NodeStyles'
import { ReactComponent as SequenceIcon } from 'img/SequenceIcon.svg'

const SequenceDragger = (props) => {
  const classes = useStyles(props)
  const template = props.template

  const title = template.name
  const body = template.description

  if ((title + ' ' + body).toLowerCase().includes(props.search.toLowerCase())) {
    return (
      <div
        data-node-type='sequence'
        title={title}
        className={`${classes.dragger} ${classes.flowSequence} dragger`}
        style={{ marginBottom: 20, cursor: 'pointer' }}
        description={body}
        ports={JSON.stringify(template.ports)}
        sequence-flow-id={template.sequence_flow_id}
        sequence-id={template.id}
      >
        <div className='node-header' style={{ justifyContent: 'space-between', paddingRight: 10 }}>
          <div style={{ display: 'flex', height: '100%' }}>
            <div className={classes.iconDiv}><SequenceIcon /></div>
            <div className={classes.cardTitle} style={{ display: 'flex', alignItems: 'center' }}>
              <div
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'no-wrap',
                  width: '100%',
                  height: '100%',
                  marginTop: 8,
                  display: '-webkit-box',
                  WebkitLineClamp: 4,
                  WebkitBoxOrient: 'vertical'
                }}
              >
                {title}
              </div>
            </div>
          </div>
        </div>
        <div className='node-body' style={{ minHeight: 42 }}>{body}</div>
      </div>
    )
  }
  return (<></>)
}

class DragDropSequenceSource extends SurfaceDropComponent {
  render () {
    return (
      <div className='node-tray'>
        {this.props.templates.map((t, index) => (
          <SequenceDragger
            key={index}
            template={t}
            search={this.props.search}
          />
        ))}
      </div>
    )
  }
}

export { DragDropSequenceSource }
