import { makeStyles } from '@material-ui/core/styles'
import Chip from '@material-ui/core/Chip'
import { Icon } from 'library/materialUI'
import { IconType } from 'library/materialUI/Icon'

const useStyles = makeStyles(theme => ({
  route: {
    display: 'flex',
    marginRight: 10,
    marginBottom: 5
  },
  chip: {
    color: theme.palette.primary.alt,
    borderColor: theme.palette.primary.alt,
    paddingLeft: 10
  }
}))

interface RouteProps {
  modal: JSX.Element
  label: string
  icon: IconType
  setModalOpen: (value: boolean) => void
  removeRoute?: () => void
}

function Route ({ modal, label, icon, setModalOpen, removeRoute }: RouteProps): JSX.Element {
  const classes = useStyles()
  return (
    <div className={classes.route}>
      <Chip
        icon={<Icon icon={icon} specificSize={20} />}
        label={label}
        variant='outlined'
        className={classes.chip}
        onClick={() => setModalOpen(true)}
        onDelete={removeRoute ? (_) => removeRoute : undefined}
      />
      {modal}
    </div>
  )
}

export default Route
