import { isArray, isEqual } from 'lodash'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'

interface useMappingStateProps<T> {
  isLoading: boolean,
  setMappingState: Dispatch<SetStateAction<T | any>>
  data: T,
  mappingState: T,
  setCanSave: Dispatch<SetStateAction<boolean>>
}

export function useMappingState<T> ({ isLoading, setMappingState, data, mappingState, setCanSave } : useMappingStateProps<T>) {
  useEffect(() => {
    if (!isLoading) {
      if (isArray(data)) {
        setMappingState([...data])
      } else {
        setMappingState(data)
      }
    }
  }, [isLoading, data, setMappingState])

  useEffect(() => {
    if (!isLoading) {
      if (!isEqual(mappingState, data)) {
        setCanSave(true)
      } else {
        setCanSave(false)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mappingState, data, isLoading])
}
interface HandleSaveProps<T> {
  mappingState: T,
  shouldSave: boolean,
  saveFunction: (mapping: T) => void
  handleSaved: () => void
}

export function HandleSave<T> ({ mappingState, shouldSave, saveFunction, handleSaved } : HandleSaveProps<T>) {
  const [lastSavedState, setLastSavedState] = useState<T | null>()

  const handleSave = () => {
    const ms = mappingState as T
      saveFunction(ms)
      setLastSavedState(ms)
  }

  useEffect(() => {
    if (shouldSave && !isEqual(mappingState, lastSavedState)) {
      handleSave()
      handleSaved()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldSave, mappingState])
}
