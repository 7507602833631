import AccountActivity from 'pages/accounts/AccountDashboard/AccountActivity'

interface SLAccountProps {
  accountID: number
}

export default function SLAccountCard ({ accountID }: SLAccountProps) {
  return (
    <div>
      <AccountActivity
        accountID={accountID}
        iFrame
      />
    </div>
  )
}
