import { makeStyles } from '@material-ui/core/styles';
import { Divider, Typography } from 'library/materialUI';
import OktaBtn from './OktaBtn';

const useStyles = makeStyles(theme => ({
  ssoDiv: {
    width: '100%',
    display: 'none'
  },
  ssoDivider: {
    width: '45%',
    margin: '10px',
    height: '2px',
    backgroundColor: '#00000080'
  },
  dividerDiv: {
    display: 'flex',
    width: '100%',
    margin: '10px 0',
    justifyContent: 'center'
  },
  btnDiv: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    marginTop: 17
  }
}))

export default function SSOButtons () {
  const classes = useStyles()

  return (
    <div className={classes.ssoDiv}>
      <div className={classes.dividerDiv}>
        <Divider className={classes.ssoDivider} />
        <Typography>or</Typography>
        <Divider className={classes.ssoDivider} />
      </div>
      <div className={classes.btnDiv}>
        <OktaBtn />
      </div>
    </div>
  )
}
