import React, { useState, useContext } from 'react'
import { SessionContext } from 'session-context'
import { ConversationWindowContext } from './conversation-window-context'
import { makeStyles } from '@material-ui/core/styles'
import { LeadScore } from './LeadScore'
import { Avatar } from 'cf-components/Avatars'
import Checkbox from '@material-ui/core/Checkbox'
import CircleIcon from '@material-ui/icons/RadioButtonUnchecked'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CheckIcon from '@material-ui/icons/Check'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import TimeSinceCalculator from 'cf-components/TimeSinceCalculator'
import { getStyle, getBody, getDisplayColor, conversationItemStyles, contactStyles, getVisitorInfo } from './conversationHelpers'
import { isTyping } from './groupEvents'

const useStyles = makeStyles(theme => ({
  avatarDiv: {
    position: 'relative',
    height: 44,
    width: 42,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))

function TimeSinceMessage (props) {
  const classes = contactStyles()
  const { user } = useContext(SessionContext)
  const reviewPermission = Boolean(user.attributes.perms.manager)
  const { setReviewedStatus } = useContext(ConversationWindowContext)
  const reviewed = props.reviewed
  const conversationID = props.conversationID

  const color = props.active ? '#FFF' : props.color
  let fontWeight = 500
  if (props.color !== '#7C7C7C') {
    fontWeight = 600
  }

  const handleReview = () => {
    setReviewedStatus({ conversationID, reviewed: !reviewed })
  }

  if (props.active && reviewPermission) {
    return (
      <div className={classes.reviewed} onClick={handleReview}>
        {reviewed
          ? <CheckBoxIcon style={{ fontSize: '1.1rem', paddingRight: 1, fill: '#8e5ae2' }} />
          : <CheckBoxOutlineBlankIcon style={{ fontSize: '1.1rem', paddingRight: 1 }} />}
        Review
      </div>
    )
  }

  return (
    <div className={classes.time}>
      {props.reviewed && reviewPermission &&
        <CheckIcon style={{ height: '0.9em', fontSize: '1.3em', fill: '#8e5ae2', paddingRight: 5 }} />}
      <div style={{ color: color, fontWeight: fontWeight }}>
        <TimeSinceCalculator
          notificationTime={props.msgTime}
        />
      </div>
    </div>
  )
}

function ContactAvatar (props) {
  const classes = useStyles()
  const showCheckbox = props.hover || props.showCheckBox
  const conversation = props.conversation
  return (
    <div className={classes.avatarDiv}>
      <div style={{ visibility: showCheckbox ? 'visible' : 'hidden', position: 'absolute' }}>
        <Checkbox
          style={{ padding: 0 }}
          color='primary'
          id={'checkbox' + props.convoID}
          checkedIcon={
            <CheckCircleIcon
              style={{
                backgroundColor: '#fff',
                borderRadius: '50%'
              }}
            />
          }
          icon={<CircleIcon />}
          checked={props.selected}
          onClick={(e) => {
            if (!e) e = window.event
            e.cancelBubble = true
            if (e.stopPropagation) e.stopPropagation()
            e.preventDefault()
            props.handleCheckbox(e)
          }}
        />
      </div>
      <div style={{ visibility: showCheckbox ? 'hidden' : 'visible' }}>
        <Avatar
          conversation={conversation}
          size='md'
          status={props.online ? 'available' : 'unavailable'}
          backgroundColor={props.active ? 'rgb(74, 74, 74)' : null}
          useLogos
        />
      </div>
    </div>
  )
}

function ContactInfo (props) {
  const classes = contactStyles()
  const nameColor = props.active ? '#FFF' : props.unread ? '#5b06cc' : '#4A4A4A'
  const online = props.isOnline
  const { visitorName, visitorInfo } = getVisitorInfo(props.conversation)
  return (
    <div className={classes.contactDiv}>
      <div style={{ display: 'flex', minWidth: 35, justifyContent: 'center', height: 44 }}>
        <ContactAvatar
          conversation={props.conversation}
          hover={props.hover}
          showCheckBox={props.showCheckBox}
          handleCheckbox={props.handleCheckbox}
          active={props.active}
          online={online}
          selected={props.selected}
        />
      </div>
      <div className={classes.contactInfo}>
        <div className={classes.contactName} style={{ color: nameColor, fontWeight: props.unread ? 600 : 500 }}>{visitorName}</div>
        <TimeSinceMessage
          msgTime={props.msgTime}
          active={props.active}
          color={props.color}
          hover={props.hover}
          reviewed={props.reviewed}
          conversationID={props.conversation.id}
        />
        <div className={classes.contactCompany} style={{ color: props.active ? '#FFF' : '#4B4B4B' }}>{visitorInfo}</div>
      </div>
    </div>
  )
}

function Conversation (props) {
  const classes = conversationItemStyles()
  const { participant } = useContext(SessionContext)
  const [hover, setHover] = useState(false)
  const conversation = props.conversation
  const body = getBody(conversation)
  const status = conversation.attributes.status
  const score = conversation.attributes.lead_score
  const reviewed = conversation.attributes.reviewed_timestamp
  const selected = props.selected
  const { myStyle, textColor } = getStyle({ style: props.style, status, active: props.active, selected, hover })
  const typing = isTyping(conversation.relationships.last_participant_events.data, participant)
  const msgTime = conversation.attributes.last_message_timestamp
  const color = getDisplayColor(msgTime, props.read, status)

  const unread = !(props.read || status === 'closed')

  const handleCheckbox = (e) => {
    props.handleAddToSelection({ conversation, shift: e.shiftKey })
  }

  const handleConversationClick = (e) => {
    if (e.ctrlKey || e.metaKey) {
      props.handleAddToSelection({ conversation })
    } else if (e.shiftKey) {
      props.handleAddToSelection({ conversation, shift: true })
    } else {
      props.handleSelect(conversation.id)
    }
  }
  return (
    <div
      style={myStyle}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={handleConversationClick}
      onMouseDown={(e) => { if (e.shiftKey) e.preventDefault() }}
    >
      <div className={classes.convoDiv}>
        <div className={classes.flagsDiv}>
          {unread && <div style={{ backgroundColor: color, height: '100%', width: '100%' }} />}
        </div>
        <div className={classes.infoDiv}>
          <ContactInfo
            conversation={conversation}
            msgTime={msgTime}
            active={props.active || selected}
            color={color}
            unread={unread}
            hover={hover}
            selected={selected}
            handleCheckbox={handleCheckbox}
            showCheckBox={props.showCheckBox}
            reviewed={Boolean(reviewed)}
            isOnline={props.online || false}
          />
        </div>
        <div className={classes.messageDiv} style={{ color: textColor }}>
          {(typing.length > 0) ? (
            <div className={classes.messageBody}>
              {typing[0].participant_name || 'Site Visitor'} {(typing.length < 2) ? ' is typing' : ' + ' + (typing.length - 1) + ' are typing'}
            </div>
          )
          : (
            <div className={classes.messageBody}>
              {body || ''}
            </div>)}
          <LeadScore score={score} fill={props.active ? '#FFF' : '#4A4A4A'} />
        </div>
      </div>
    </div>
  )
}

export default Conversation
