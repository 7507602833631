import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import EmailForm from './EmailForm'
import PasswordForm from './PasswordForm'
import { CircularProgress } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  form: {
    paddingRight: 50,
    paddingLeft: 50,
    marginTop: 30
  },
  submitButton: {
    width: '100%',
    height: 40
  },
  invalidInput: {
    color: 'red',
    fontSize: 12,
    height: 15
  },
  mobileForm: {
  }
}))

const FreeTrialForm = (props) => {
  const classes = useStyles()
  const [email, setEmail] = useState()
  const [fullName, setFullName] = useState('')
  const [formStage, setFormStage] = useState('email')
  const loading = props.loading
  const performRegistration = props.onSubmit
  const devMode = props.devMode

  const handleEmailSubmit = formikValues => {
    const email = formikValues.email
    const name = formikValues.full_name
    setEmail(email)
    setFullName(name)
    setFormStage('password')
  }

  const handleRegister = passwordValues => {
    const values = { full_name: fullName, email: email, password: passwordValues.password }
    performRegistration(values)
  }

  return (
    <>
      <div className={props.small ? classes.mobileForm : classes.form}>
        {loading ? (
          <div style={{ textAlign: 'center', marginTop: 50 }}>
            <div>
              <CircularProgress size={40} />
            </div>
            <div style={{ marginTop: 15, fontSize: 24, fontWeight: 100 }}>
              Getting everything ready for you
            </div>
          </div>
        ) : formStage === 'email' ? (
          <EmailForm onSubmit={handleEmailSubmit} email={props.email} devMode={devMode} />
        ) : formStage === 'password' ? (
          <PasswordForm onSubmit={handleRegister} loading={loading} />
        ) : <></>}
      </div>
    </>
  )
}

export default FreeTrialForm
