import { makeStyles } from '@material-ui/core/styles'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

const useStyles = makeStyles(theme => ({
  drawerToggleButton: {
    position: 'absolute',
    bottom: 115,
    border: '1px solid rgba(0,0,0,0.1)',
    display: 'flex',
    padding: 8,
    paddingLeft: 4,
    borderTopRightRadius: 50,
    borderBottomRightRadius: 50,
    boxShadow: '2px 2px 3px #AAA',
    backgroundColor: 'white',
    cursor: 'pointer',
    zIndex: 10
  }
}))

export function DrawerToggleButton (props: any): JSX.Element {
  const classes = useStyles()
  const handleToggle = props.handleToggle

  return (
    <div
      className={classes.drawerToggleButton}
      onClick={handleToggle}
    >
      {props.open
        ? <ChevronLeftIcon />
        : <ChevronRightIcon />}
    </div>
  )
}
