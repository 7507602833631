import { makeStyles } from '@material-ui/core/styles'
import { TextBox } from 'cf-components/material-wrappers/MaterialComponents'
import { useState } from 'react'
import { Button, Icon } from 'library/materialUI'

const useStyles = makeStyles(theme => ({
  header: {
    fontSize: 20,
    fontWeight: 'bold',
    fontFamily: 'poppins'
  },
  description: {
    fontSize: 16,
    fontFamily: 'poppins',
    marginTop: '20px'
  }
}))

interface PageCheckerProps {
  state: any
}

export default function PageChecker ({ state }: PageCheckerProps) {
  const classes = useStyles()
  const [url, setUrl] = useState('')
  const [pageState, setPageState] = useState('')

  const handleCheckPage = () => {
    const pageList = JSON.parse(state?.attributes?.blocked_pages || '{}')?.list || []
    if (pageList.some((page: any) => url.includes(page.url))) {
      setPageState('blocked')
    } else {
      setPageState('not blocked')
    }
  }

  return (
    <div>
      <div className={classes.header}>
        Blocked Pages
      </div>
      <div className={classes.description}>
        Paste a URL here to verify if bots will be blocked from appearing on it
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', marginTop: '20px' }}>
        <div style={{ width: '100%', marginRight: '10px' }}>
          <TextBox
            value={url}
            onChange={(value: string) => setUrl(value)}
          />
        </div>
        <div style={{ width: '186px' }}>
          <Button
            color='primary'
            variant='contained'
            onClick={handleCheckPage}
          >
            CHECK PAGE
          </Button>
        </div>
      </div>
      {pageState === 'blocked' && (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', backgroundColor: '#E3EEE3', marginTop: '10px', height: '60px', boxShadow: '0px 3px 6px #00000029', borderRadius: '5px' }}>
          <div style={{ marginLeft: '20px', marginRight: '12px' }}>
            <Icon icon='checkCircle' color='#4BA24E' size='sm' />
          </div>
          <div>
            Bots are blocked on this page
          </div>
        </div>
      )}
      {pageState === 'not blocked' && (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', backgroundColor: '#F6E2E0', marginTop: '10px', height: '60px', boxShadow: '0px 3px 6px #00000029', borderRadius: '5px' }}>
          <div style={{ marginLeft: '20px', marginRight: '12px' }}>
            <Icon icon='cancel' color='#EA422A' size='sm' />
          </div>
          <div>
            Bots are not blocked on this page
          </div>
        </div>
      )}
    </div>
  )
}
