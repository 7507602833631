import { useContext } from 'react';
import { CRMContext } from 'crm-context';
import { IconType } from 'library/materialUI/Icon';
import { BETWEEN, EQUALS, FilterComp, GREATER_THAN_OR_EQUAL, IN, INCLUDEDFILTERCOMP, INCLUDES, LESS_THAN_OR_EQUAL, NOT_EQUALS, NOT_IN, NOT_INCLUDES, QueryFilter } from 'classes/queryHelpers';
import { useSchema } from 'api/schema';
import { getLocations } from 'api/locations';
import { Segment } from 'classes/segments';
import User from 'classes/users';
import { OppValues } from 'helpers/filters';
import { getHighIntent } from 'api/high-intent';
import { TreeChild } from 'cf-components/TreeMultiSelect';
// import { useQuery } from 'react-query';

/* eslint-disable @typescript-eslint/naming-convention */

export type SelectorType = 'range' | 'select' | 'calendar' | 'tree'

export interface filterAttribute {
  name: string,
  dbKey: string,
  Icon: IconType,
  type: SelectorType,
  tooltip: string,
  getItems?: (search?: string) => { data: TreeChild[], isLoading: boolean }
}

const integrationLogoMap: Record<string, string> = {
  hubspot: 'hubspotClear',
  salesforce: 'salesforceCloud'
}

export interface DashboardFilter {
  field: string
  includeOperator?: FilterComp
  included: any[]
  excludeOperator?: FilterComp
  excluded: any[]
  type: SelectorType
}

/**
 * This can be used on filters that don't deal with include and exclude like
 * our range filters. Just have the values stored in the included array
 * @param filters Filters should never be length > 2
 * @param type Type will be given by the dashboard attributes
 * @returns A dashboard filter
 */
export function ConvertQueryFiltersToDashboardFilter (filters: QueryFilter[], field: string, type: SelectorType): DashboardFilter {
  const included: any[] = []
  const excluded: any[] = []

  const addFilter = (filter: QueryFilter, group: string[]) => {
    if (Array.isArray(filter.value)) {
      group.push(...filter.value)
    } else {
      group.push(filter.value)
    }
  }

  let includeOperator: FilterComp = EQUALS
  let excludeOperator: FilterComp = NOT_EQUALS
  filters.forEach((filter) => {
    if (INCLUDEDFILTERCOMP.includes(filter.operator)) {
      addFilter(filter, included)
      includeOperator = filter.operator
    } else {
      addFilter(filter, excluded)
      excludeOperator = filter.operator
    }
  })
  return {
    field: field,
    includeOperator: includeOperator,
    included: included,
    excluded: excluded,
    excludeOperator: excludeOperator,
    type: type
  }
}

// These are all the fields that the database column type is a list
const FILTER_LIST_TYPES = ['segments', 'high_intent_page_ids', 'traffic_sources', 'source_types', 'referrers', 'traffic_campaigns', 'source_domains']

// Need to figure out why segments has to be 'includes' and not 'in'
export function ConvertDashboardFilterToQueryFilters (filter: DashboardFilter): QueryFilter[] {
  const filters: QueryFilter[] = []
  if (filter.type === 'range') {
    const start = filter.included[0]
    const end = filter.included[1]
    if (start !== undefined && end !== undefined) {
      filters.push({
        field: filter.field,
        operator: GREATER_THAN_OR_EQUAL,
        value: +start
      })
      filters.push({
        field: filter.field,
        operator: LESS_THAN_OR_EQUAL,
        value: +end
      })
    }
  } else if (filter.type === 'calendar' && filter.included.length > 0) {
    filters.push({
      field: filter.field,
      operator: BETWEEN,
      value: filter.included
    })
  } else {
    if (filter.included.length > 0) {
      filters.push({
        field: filter.field,
        operator: FILTER_LIST_TYPES.includes(filter.field) ? INCLUDES : IN,
        value: filter.included
      })
    }
    if (filter.excluded.length > 0) {
      filters.push({
        field: filter.field,
        operator: FILTER_LIST_TYPES.includes(filter.field) ? NOT_INCLUDES : NOT_IN,
        value: filter.excluded
      })
    }
  }
  return filters
}

function Schema (obj: string, field: string, search?: string): { data: TreeChild[], isLoading: boolean } {
  const { data, isLoading } = useSchema(obj, field, search)
  return {
    data: data?.map((d: any) => ({ label: d.label, value: d.value })) || [],
    isLoading
  }
}

export function DashboardFilterAttributes (): Record<string, filterAttribute> {
  const { crmIntegrationName } = useContext(CRMContext)

  function getUsers (): { data: TreeChild[], isLoading: boolean } {
    const { data: users, isLoading: loadingUsers } = User.loadAll(undefined)
    if (loadingUsers) {
      return { data: [], isLoading: true }
    }
    return {
      data: users.list.map((user: User) => ({ label: user.name, value: user.id })),
      isLoading: false
    }
  }

  function getSegments (): { data: TreeChild[], isLoading: boolean } {
    const { data: segments, isLoading: loadingSegments } = Segment.loadAll({ searchParams: { sortColumn: 'name' } })
    if (loadingSegments) {
      return { data: [], isLoading: true }
    }
    return {
      data: segments.list.map((segment: Segment) => ({ label: segment.name, value: segment.id })),
      isLoading: false
    }
  }

  return {
    engagement_filter: {
      name: 'Signals Score',
      dbKey: 'engagement_score',
      Icon: 'dataUsage',
      type: 'range',
      tooltip: 'Signals score is calculated based on number of recent page views, conversations, unique visitors, emails captured, live chats, meetings booked, and CRM status'
    },
    owner_filter: {
      name: 'Account Owner',
      dbKey: 'owner_user_id',
      Icon: integrationLogoMap[crmIntegrationName] || 'crmAttributesIcon',
      type: 'select',
      tooltip: '',
      getItems: getUsers
    },
    revenue_filter: {
      name: 'Annual Revenue',
      dbKey: 'annual_revenue',
      Icon: 'revenueIcon',
      type: 'range',
      tooltip: ''
    },
    contacts_filter: {
      name: 'Contacts',
      dbKey: 'contacts',
      Icon: 'contacts',
      type: 'range',
      tooltip: 'The total number of contacts for the account that we have a matching email address for.'
    },
    type_filter: {
      name: crmIntegrationName === 'hubspot' ? 'Lifecycle Stage' : 'Account Type',
      dbKey: 'account_type',
      Icon: integrationLogoMap[crmIntegrationName] || 'crmAttributesIcon',
      type: 'select',
      tooltip: '',
      getItems: (search?: string) => Schema('account', 'type', search)
    },
    crm_type_filter: {
      name: crmIntegrationName === 'hubspot' ? 'Type' : 'CRM Record Type',
      dbKey: 'crm_record_type',
      Icon: integrationLogoMap[crmIntegrationName] || 'crmAttributesIcon',
      type: 'select',
      tooltip: 'The account type associated with the account. Pulled in from your CRM.',
      getItems: (search?: string) => Schema('account', 'crm_record_type', search)
    },
    employee_filter: {
      name: 'Employee Count',
      dbKey: 'number_of_employees',
      Icon: 'employeeIcon',
      type: 'range',
      tooltip: ''
    },
    high_intent_filter: {
      name: 'High-Intent Page',
      dbKey: 'high_intent_page_ids',
      Icon: 'webPage',
      type: 'select',
      tooltip: 'Use this filter to see companies that visited your High-Intent Pages',
      getItems: getHighIntent
    },
    industry_filter: {
      name: 'Industry',
      dbKey: 'industry',
      Icon: 'industryIcon',
      type: 'select',
      tooltip: '',
      getItems: () => Schema('account', 'industry')
    },
    recent_visits_filter: {
      name: 'Last Visit Date',
      dbKey: 'last_activity_timestamp',
      Icon: 'event',
      type: 'calendar',
      tooltip: 'The date and time that a visitor from this company was last active on your site.'
    },
    location_filter: {
      name: 'Location',
      dbKey: 'location',
      Icon: 'locationIcon',
      type: 'select',
      tooltip: '',
      getItems: getLocations
    },
    opp_filter: {
      name: 'Opportunity Status',
      dbKey: 'opportunity_status',
      Icon: integrationLogoMap[crmIntegrationName] || 'crmAttributesIcon',
      type: 'select',
      tooltip: 'The status of the most recent opportunity associated with the account. Pulled in from your CRM.',
      getItems: () => ({ data: OppValues, isLoading: false })
    },
    segment_filter: {
      name: 'Segment',
      dbKey: 'segments',
      Icon: 'segmentIcon',
      type: 'select',
      tooltip: '',
      getItems: getSegments
    },
    domain_filter: {
      name: 'Source Domain',
      dbKey: 'source_domains',
      Icon: 'webPage',
      type: 'select',
      tooltip: 'Use this filter to see companies that visited specific pages on your site',
      getItems: (search?: string) => Schema('page_view', 'source_domain', search)
    },
    total_visits_filter: {
      name: 'Site Visits',
      dbKey: 'site_visits',
      Icon: 'cursor',
      type: 'range',
      tooltip: 'Number of unique site visits from people associated with this account in the last 30 days'
    },
    traffic_filter: {
      name: 'Traffic Campaign Platform',
      dbKey: 'traffic_sources',
      Icon: 'trafficSourceSmall',
      type: 'select',
      tooltip: 'Email or ad campaign platform through which a visitor navigated to your site',
      getItems: (search?: string) => Schema('page_view', 'traffic_source', search)
    },
    source_type_filter: {
      name: 'Traffic Medium',
      dbKey: 'source_types',
      Icon: 'trafficSourceSmall',
      type: 'select',
      tooltip: 'Broad bucket categories through which a visitor navigated to your site',
      getItems: (search?: string) => Schema('page_view', 'source_types', search)
    },
    referrer_filter: {
      name: 'Traffic Source',
      dbKey: 'referrers',
      Icon: 'trafficSourceSmall',
      type: 'select',
      tooltip: 'Specific categories through which a visitor navigated to your site',
      getItems: (search?: string) => Schema('page_view', 'referrers', search)
    },
    traffic_campaign_filter: {
      name: 'Traffic Campaign',
      dbKey: 'traffic_campaigns',
      Icon: 'trafficSourceSmall',
      type: 'select',
      tooltip: 'Specific email or ad campaign through which a visitor navigated to your site',
      getItems: (search?: string) => Schema('page_view', 'traffic_campaigns', search)
    }
  }
}

export function getColor (index: number) {
  let color = '#EE6754'

  switch (index % 11) {
    case 0:
      break
    case 1:
      color = '#EC7F5D'
      break
    case 2:
      color = '#FEA660'
      break
    case 3:
      color = '#F5DC72'
      break
    case 4:
      color = '#B5E233'
      break
    case 5:
      color = '#6CD7B5'
      break
    case 6:
      color = '#74C5D1'
      break
    case 7:
      color = '#86C6E0'
      break
    case 8:
      color = '#6C8FD6'
      break
    case 9:
      color = '#F080A1'
      break
    default:
      color = '#AF63FD'
  }
  return color
}
