import Loader from 'library/loading/Loader'
import { makeStyles } from '@material-ui/core/styles'
import Persona from 'classes/personas'
import { Button, Icon } from 'library/materialUI'
import { factorOperatorToString, factorToString, importanceToString } from './SelectorHelper'
import { capitalizeString } from 'helpers/strings'
import { ModalSection } from 'library/Modal'

const useStyles = makeStyles(theme => ({
  oppTypeHeader: {
    fontSize: '20px',
    fontFamily: 'Poppins',
    color: theme.palette.primary.main,
    fontWeight: 'bold'
  },
  innerText: {
    fontSize: '14px',
    fontFamily: 'Poppins',
    color: '#00000080'
  },
  summaryCard: {
    marginTop: '5px',
    marginBottom: '5px',
    padding: '15px',
    paddingTop: '5px',
    background: '#F7F7F7 0% 0% no-repeat padding-box',
    borderRadius: '5px'
  },
  bodyContainer: {
    display: 'flex'
  },
  summaryBody: {
    flex: 'auto',
    fontSize: '14px',
    color: '#00000080'
  },
  editBtn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '10px'
  },
  cardTitle: {
    textAlign: 'left',
    color: '#000000BF',
    fontSize: '18px'
  }
}))

interface SingalsScoringStep5Props {
  oppTypes: string[]
  averageCycle: string
  personaPriority: number[]
  factors: any
  setStep: any
}

function SummaryCard ({ setStep, stepNumber, title, body, classes }: { title: string, body: string, setStep: any, stepNumber: number, classes: any }) {
  return (
    <div className={classes.summaryCard}>
      <div className={classes.cardTitle}>{title}</div>
      <div className={classes.bodyContainer}>
        <div className={classes.summaryBody}>
          {body}
        </div>
        <div className={classes.editBtn}>
          <Button
            variant='outlined'
            onClick={() => setStep(stepNumber)}
          >
            <div style={{ marginRight: 10 }}><Icon icon='edit' /></div> Edit
          </Button>
        </div>
      </div>
    </div>
  )
}

export default function SignalsScoringStep5 ({ oppTypes, averageCycle, personaPriority, setStep, factors }: SingalsScoringStep5Props): JSX.Element {
  const classes = useStyles()
  const { data: personas, isLoading } = Persona.loadAll()
  if (isLoading) {
    return <><Loader type='spinner' /></>
  }

  const personBody = personaPriority.map((numId, idx) => {
    return '(' + (idx + 1).toString() + ') ' + personas.dict[numId].name
  }).join(', ')
  const factorsBody = (
    <ul>
      {factors.map((factorGroup: any, index: number) => {
        return (
          <li key={index}>
            {factorGroup.factors.map((factor: any, index: number) => {
              return capitalizeString(factorToString(factor.name)) + ' ' + factorOperatorToString(factor.op, factor.name) + ' ' + ((factor.name !== 'icp_match' && factor.name !== 'open_opportunity') ? factor.value || '' : '') + (index === factorGroup.factors.length - 1 ? ' ' : ' and ')
            })}
            ({importanceToString(factorGroup.importance)})
          </li>
        )
      })}
    </ul>
  )
  const oppTypeCard = SummaryCard({ classes, setStep, title: 'Opportunity Types', body: oppTypes.join(', '), stepNumber: 1 })
  const dealCycleCard = SummaryCard({ classes, setStep, title: 'Average deal cycle', body: averageCycle, stepNumber: 2 })
  const bcCard = SummaryCard({ classes, setStep, title: 'Buying Committee', body: personBody, stepNumber: 3 })
  const factorsCard = SummaryCard({ classes, setStep, title: 'Factors', body: factorsBody, stepNumber: 4 })

  return (
    <ModalSection>
      <div className={classes.oppTypeHeader}>
        You're almost there!
      </div>
      <div className={classes.innerText} style={{ marginTop: '12px' }}>
        Confirm the details of your score before continuing
      </div>
      <div>
        <div style={{ marginBottom: 10 }}>
          {oppTypeCard}
        </div>
        <div style={{ marginBottom: 10 }}>
          {dealCycleCard}
        </div>
        <div style={{ marginBottom: 10 }}>
          {bcCard}
        </div>
        <div style={{ marginBottom: 10 }}>
          {factorsCard}
        </div>
      </div>
    </ModalSection>
  )
}
