import { makeStyles } from '@material-ui/core/styles';
import { adjustLicenseQuantity, cancelSubscription, getNeededIPLookups, getRenewalPreview, setBillingSettings } from 'api/billing';
import { queryClient } from 'App';
import { BillingContext } from 'billing-context';
import dateFormat from 'dateformat';
import { Button, Card, Icon, Tooltip, Typography } from 'library/materialUI';
import { IconType } from 'library/materialUI/Icon';
import ConfirmationModal, { ConfirmationModalProps } from 'library/modals/ConfirmationModal';
import ConctactCreditComponent from 'pages/billing_v2/ContactCreditComponent';
import { useCallback, useContext, useEffect, useState } from 'react';
import { CancellationModal } from './CancellationModal';
import { CreditCard } from './CreditCard';
import { UpgradeTile } from './UpgradeTile';
import AccessControl from 'components/AccessControl';
import { components } from 'session-context';
import ProgressBar from 'library/charts/ProgressBar';
import UpdateIPLookupModal from './UpdateIPLookupModal';
import UpdateContactCreditModal from './UpdateContactCreditModal';
import UpdateUsersModal from './UpdateUsersModal';
import SkeletonLabel from 'library/loading/skeleton/SkeletonLabel';
import AfterCancellationModal from './AfterCancellationModal';
import Loader from 'library/loading/Loader';
import WhyCancelModal from './WhyCancelModal';
// import { ReactComponent as UpArrowCircle } from 'img/up-arrow-circle.svg';

const useStyles = makeStyles(theme => ({
  messageDiv: {
    margin: 10,
    padding: 10,
    borderTop: '1px solid #dad8de'
  },
  card: {
    padding: 15,
    marginBottom: 20
  },
  cardTitle: {
    font: 'normal normal 600 20px/30px Poppins',
    color: '#404040',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 15
  },
  tile: {
    backgroundColor: '#F5F5F5',
    padding: 12,
    marginRight: 10,
    marginBottom: 10,
    borderRadius: 5,
    flexGrow: 1,
    minWidth: 240
  },
  smallTileTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    color: '#808080',
    font: 'normal normal normal 14px/21px Poppins',
    marginBottom: 10
  },
  lineItemGridContainer: {
    display: 'grid',
    gridTemplateColumns: 'auto auto'
  },
  lineItemName: {},
  lineItemPrice: { textAlign: 'right' },
  tiles: { display: 'flex', flexWrap: 'wrap' },
  subtitle: {
    textTransform: 'uppercase',
    color: '#000010BF',
    font: 'normal normal normal 14px/21px Poppins',
    marginBottom: 10
  },
  componentName: {
    textTransform: 'uppercase',
    color: '#00000080',
    font: 'normal normal normal 14px/21px Poppins'
  },
  usageCards: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  linkText: {
    color: '#0072CE',
    textDecoration: 'underline',
    cursor: 'pointer',
    marginRight: 5
  },
  switch: {
    marginLeft: 0
  }
}))

interface SmallTileProps {
  title: JSX.Element | string
  style?: Record<string, any>
  children: JSX.Element
}

function SmallTile ({ title, style, children }: SmallTileProps) {
  const classes = useStyles()

  return (
    <div className={classes.tile} style={style}>
      <div className={classes.smallTileTitle}>
        {title}
      </div>
      {children}
    </div>
  )
}

type LargeTileProps = {
  title: string
  icon: IconType
  usage: Record<string, any>
  loading: boolean
  component?: JSX.Element
  action?: () => void
  willCancel?: boolean
}
function currency (number: number, maximumFractionDigits: number) {
  if (!number) {
    return ''
  }
  const formatted = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits
  }).format(number)
  return formatted
}

function LargeTile (props: LargeTileProps) {
  const classes = useStyles()
  const usage = props.usage
  const loading = props.loading
  const atPlanLimit = usage?.max && usage?.max <= usage?.allocated
  const maxedOut = (usage?.used >= usage?.allocated) && usage?.allocated
  const usedText = usage?.allocated ? usage?.used + '/' + usage?.allocated : usage?.used
  const tooltip = usage?.allocated ? 'used / purchased' : 'used'
  const Component = props.component ? props.component : <></>
  let tooltipText = ''
  if (atPlanLimit) {
    tooltipText = `Your current plan is limited to ${usage.max} licenses.  If you need to purchase more
    than ${usage.max} licenses, please contact us via the Support link on the left sidebar.`
  } else if (props.willCancel) {
    tooltipText = 'Your plan is scheduled to cancel.  You will not be able to purchase additional licenses from this page. If you wish to add more credits please contact us via the Support link on the left sidebar.'
  }

  const showMax = usage?.max && usage?.max > 0
  return (
    <div className={classes.tile}>
      {!props.component
        ? (
          <>
            {loading ? (
              <>
                <div style={{ marginBottom: 10, display: 'flex', justifyContent: 'space-between' }}>
                  <Icon color='primary' size='lg' icon={props.icon} />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', minHeight: 220, minWidth: 240 }}>
                  <div>
                    <div className={classes.componentName}>{props.title}</div>
                    <SkeletonLabel size='full' />
                    <SkeletonLabel size='full' />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div style={{ marginBottom: 10, display: 'flex', justifyContent: 'space-between' }}>
                  <Icon color='primary' size='lg' icon={props.icon} />
                  {props.action && (
                    <Tooltip title={tooltipText}>
                      <div>
                        <Button
                          color='primary'
                          variant='outlined'
                          style={{ width: 120 }}
                          onClick={props.action}
                          disabled={atPlanLimit || props.willCancel}
                        >
                          + Buy More
                        </Button>
                      </div>
                    </Tooltip>
                  )}
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', minWidth: 240 }}>
                  <div>
                    <div className={classes.componentName}>{props.title}</div>
                    <div style={{ fontWeight: 'bold', color: maxedOut ? '#EA422A' : '#000000BF' }}>
                      <Tooltip title={tooltip}><div style={{ display: 'inline-block', padding: 3 }}>{usedText}</div></Tooltip>
                      {showMax ? (
                        <Tooltip title='max purchase allowed under plan'>
                          <Typography style={{ fontWeight: 'normal', fontSize: 14, display: 'inline' }}>(max {usage.max})</Typography>
                        </Tooltip>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  {Boolean(usage.allocated) &&
                    <ProgressBar current={usage.used} total={usage.allocated} />}
                </div>
              </>)}
          </>
        )
        : Component}
    </div>
  )
}

/* eslint-disable */
const defaultPreview = {
  plan_price: 0,
  total_discount_in_cents: 0,
  total_amount_due_in_cents: 0,
  plan_name: '',
  renewal_date: '',
  subtotal_in_cents: 0,
  line_items: [],
  can_cancel: false
}
/* eslint-enable */
export function MyPlan () {
  const classes = useStyles()
  const [cancelModalOpen, setCancelModalOpen] = useState(false)
  const [afterCancellationModal, setAfterCancellationModal] = useState(false)
  const [whyCancelModalOpen, setWhyCancelModalOpen] = useState(false)
  const [upgradeIPModalOpen, setUpgradeIPModalOpen] = useState(false)
  const [upgradeUsersModalOpen, setUpgradeUsersModalOpen] = useState(false)
  const [upgradeCreditsModalOpen, setUpgradeCreditsModalOpen] = useState(false)
  const { billing, usage, usageLoading, refetchBilling } = useContext(BillingContext)
  const [loading, setLoading] = useState(true)
  const [previewLoading, setPreviewLoading] = useState(false)
  const [renewalPreview, setRenewalPreview] = useState(defaultPreview)
  const [confirmationModalProps, setConfirmationModalProps] = useState<ConfirmationModalProps | undefined>(undefined)
  const willCancel = billing?.attributes?.scheduled_cancellation !== null

  const submitChange = (usage: Record<string, any>, qty: any) => {
    setConfirmationModalProps(undefined)
    adjustLicenseQuantity(usage.component, qty, true).then(() => {
      queryClient.invalidateQueries('component_usage')
    })
  }
  const renewPreview = useCallback(() => {
    getRenewalPreview({ adjustments: [] })
      .then(response => {
        setPreviewLoading(false)
        if (response.data) {
          const newPreview = response.data.attributes
          if (renewalPreview.subtotal_in_cents !== newPreview.subtotal_in_cents) {
            setRenewalPreview(newPreview)
          }
        }
      })
  }, [renewalPreview?.subtotal_in_cents])

  const neededLookups = (newQty: number) => {
    const allocated = usage?.contact_credits?.allocated || 0
    const newLookups = newQty - allocated
    getNeededIPLookups(newLookups)
      .then(response => {
        if (response?.data?.attributes) {
          const needed = response.data.attributes
          if (needed > 0) {
            setConfirmationModalProps({
              title: 'Upgrade IP Lookup credits too?',
              dynamicButtons: [
                {
                  text: 'Upgrade IP Lookup Credits',
                  color: 'primary',
                  action: () => {
                    setUpgradeIPModalOpen(true)
                    setConfirmationModalProps(undefined)
                  }
                },
                {
                  text: 'Maybe later',
                  action: () => setConfirmationModalProps(undefined)
                }
              ],
              onHide: () => setConfirmationModalProps(undefined),
              children: (
                <Typography variant='body2'>Maximize the value of your new contact credits by upgrading your IP Lookup credits to <b>{needed}</b>. Ensure you can identify enough companies, so your contact credits don't go to waste. Stay fully equipped to gather the right prospects!</Typography>
              )
            })
          }
        }
      })
  }

  useEffect(() => {
    if (previewLoading && billing?.attributes && !willCancel) {
      renewPreview()
    }
  }, [renewPreview, previewLoading, billing, willCancel])

  useEffect(() => {
    if (billing?.attributes && !usageLoading) {
      setLoading(false)
      if (!billing?.attributes?.scheduled_cancellation) {
        setPreviewLoading(true)
      }
    }
  }, [billing, usageLoading])

  const discounts = previewLoading ? 0 : renewalPreview?.total_discount_in_cents || 0
  const formattedDiscount = currency(discounts / 100 || 0, 2)
  const formattedPrice = previewLoading ? 0 : currency(renewalPreview?.total_amount_due_in_cents / 100 || 0, 2)
  const planPrice = previewLoading ? 0 : currency(renewalPreview?.plan_price / 100, 2)
  const plan = billing?.attributes?.plan_name || ''
  const period = billing?.attributes?.billing_period || ''
  const canCancel = renewalPreview?.can_cancel || false
  const cancelDate = loading && billing?.attributes?.scheduled_cancellation !== null ? '' : dateFormat(billing.attributes?.scheduled_cancellation, 'mmm dd, yyyy')
  const nextPayment = previewLoading && renewalPreview?.renewal_date === '' ? '' : dateFormat(renewalPreview?.renewal_date, 'mmm dd, yyyy')
  const planTitle = (
    <>
      <div style={{ display: 'flex' }}>
        Plan Cost {period ? <>({period})</> : <></>}
      </div>
    </>
  )

  return (
    <div>
      <Card className={classes.card}>
        <div className={classes.cardTitle}>
          <div>
            Your Plan
          </div>
          <div>
            {canCancel && (
              <Button
                color='primary'
                variant='outlined'
                style={{ width: 130, marginRight: 20 }}
                onClick={(_) => setCancelModalOpen(true)}
              >
                Cancel Plan
              </Button>
            )}
            <Button variant='outlined' onClick={() => { window.open('https://getsignals.ai/pricing/', '_blank') }}>
              COMPARE PLANS&nbsp;<Icon icon='launch' />
            </Button>
          </div>
        </div>
        <div style={{ display: 'flex' }}>
          <div>
            <div className={classes.tiles}>
              <SmallTile
                title={planTitle}
                style={{ minWidth: 315, minHeight: 170 }}
              >
                {loading ? (
                  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <SkeletonLabel size='full' />
                    <SkeletonLabel size='full' />
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <SkeletonLabel size='sm' />
                      <SkeletonLabel size='lg' />
                    </div>
                  </div>
                ) : (
                  <div>
                    <div style={{ color: '000010BF', fontSize: '14px', borderBottom: '2px solid #e2e0e3' }}>
                      <div className={classes.lineItemGridContainer}>
                        <div style={{ fontWeight: 900, marginBottom: 2 }} className={classes.lineItemName}>{plan}</div>
                        {!previewLoading ? <div style={{ fontWeight: 900, marginBottom: 2 }} className={classes.lineItemPrice}>{planPrice}</div> : <Loader />}
                      </div>
                      {renewalPreview.line_items.map((li: any, k: any) => {
                        const liPrice = currency(li.amount_in_cents / 100, 2)
                        return (
                          <div key={k} className={classes.lineItemGridContainer}>
                            <div style={{ marginLeft: 5 }} className={classes.lineItemName}> + {li.quantity} {li.component_label}(s)</div>
                            <div style={{ textAlign: 'right' }} className={classes.lineItemPrice}>{liPrice}</div>
                          </div>
                        )
                      })}
                    </div>
                    {discounts > 0 && (
                      <div className={classes.lineItemGridContainer}>
                        <div style={{ fontWeight: 900, marginBottom: 2, fontSize: 'smaller' }} className={classes.lineItemName}>Discounts</div>
                        <div style={{ fontWeight: 900, marginBottom: 2, fontSize: 'smaller' }} className={classes.lineItemPrice}>({formattedDiscount})</div>
                      </div>
                    )}
                    <div style={{ display: 'inline-block', fontWeight: 600, fontSize: '15px' }}>Subtotal</div>
                    <div style={{ fontSize: 30, position: 'relative', top: -25, fontWeight: 900, width: '100%', textAlign: 'right' }} className={classes.lineItemPrice}>{formattedPrice}</div>
                  </div>
                )}
              </SmallTile>
              <SmallTile title={willCancel ? 'Plan Ends' : 'Next Payment'}>
                <div style={{ color: '#000010BF', fontWeight: 'bold', paddingTop: 15 }}>
                  {willCancel ? cancelDate : nextPayment}
                </div>
              </SmallTile>
              <SmallTile title='Payment Information'>
                <div style={{ paddingRight: 20 }}>
                  <CreditCard />
                </div>
              </SmallTile>
            </div>
            <div className={classes.subtitle}>Included in your plan</div>
            <div className={classes.tiles}>
              <LargeTile
                icon='users'
                title='users'
                usage={usage?.users}
                loading={usageLoading}
                action={() => setUpgradeUsersModalOpen(true)}
              />
              <AccessControl requiredComponent={components.BOT_TESTS}>
                <LargeTile
                  icon='beaker'
                  title='Active A/B Tests'
                  usage={usage?.tests}
                  loading={usageLoading}
                />
              </AccessControl>
            </div>
          </div>
          <UpgradeTile />
        </div>
      </Card>
      <Card className={classes.card}>
        <div className={classes.cardTitle}>
          Your Usage
        </div>
        <div className={classes.usageCards}>
          <LargeTile
            icon='search'
            title='Reverse IP Lookups'
            usage={usage?.identification}
            loading={usageLoading}
            action={() => setUpgradeIPModalOpen(true)}
            willCancel={willCancel}
          />
          <AccessControl requiredComponent={components.EMAILS}>
            <LargeTile
              icon='email'
              title='Emails sent'
              usage={{ allocated: 0, used: 0 }}
              loading={usageLoading}
            />
          </AccessControl>
          <LargeTile
            icon='phone'
            title='Text Notifications'
            usage={usage?.texts}
            loading={usageLoading}
          />
          <LargeTile
            icon='search'
            title='Contact Credits'
            usage={{ allocated: 0, used: 0 }}
            loading={usageLoading}
            component={<ConctactCreditComponent action={() => setUpgradeCreditsModalOpen(true)} willCancel={willCancel} />}
          />
        </div>
      </Card>
      <CancellationModal
        open={cancelModalOpen}
        onClose={() => setCancelModalOpen(false)}
        cancelSubscription={() => {
          setCancelModalOpen(false)
          cancelSubscription().then(() => {
            setRenewalPreview(defaultPreview)
            refetchBilling()
            setAfterCancellationModal(true)
          }).finally(() => {
            setLoading(true)
          })
        }}
        renewalDate={nextPayment}
        planName={plan}
      />
      <AfterCancellationModal
        open={afterCancellationModal}
        setOpen={(value: boolean) => {
          setAfterCancellationModal(value)
          setWhyCancelModalOpen(true)
        }}
        planName={plan}
        endDate={cancelDate}
      />
      <WhyCancelModal
        open={whyCancelModalOpen}
        setOpen={(open: boolean) => setWhyCancelModalOpen(open)}
      />
      {confirmationModalProps && (
        <ConfirmationModal
          {...confirmationModalProps}
        />)}
      {upgradeIPModalOpen &&
        <UpdateIPLookupModal
          open={upgradeIPModalOpen}
          component={usage.identification.component}
          onClose={() => setUpgradeIPModalOpen(false)}
          onSave={(newQty: number, autoProvision: boolean) => {
            Promise.all([
              setBillingSettings({ auto_provision: { [usage?.identification?.component]: autoProvision } }), // eslint-disable-line
              adjustLicenseQuantity(usage.identification.component, newQty, false)
            ]).finally(() => {
              setUpgradeIPModalOpen(false)
              setLoading(true)
              renewPreview()
              queryClient.invalidateQueries('component_usage')
              queryClient.invalidateQueries('billing_settings')
            })
          }}
          planPrice={renewalPreview.subtotal_in_cents / 100}
          allocated={usage?.identification?.allocated || 0}
        />}
      {upgradeCreditsModalOpen &&
        <UpdateContactCreditModal
          open={upgradeCreditsModalOpen}
          onClose={() => setUpgradeCreditsModalOpen(false)}
          onSave={(newQty: number, lookupsPlus: boolean) => {
            const component = lookupsPlus ? components.CONTACT_LOOKUPS_PLUS : components.CONTACT_LOOKUPS
            Promise.all([
              setBillingSettings({ lookups_plus: lookupsPlus }), // eslint-disable-line
              adjustLicenseQuantity(component, newQty, false)
            ]).then(() => {
              queryClient.invalidateQueries('component_usage')
            }).finally(() => {
              setLoading(true)
              setUpgradeCreditsModalOpen(false)
              renewPreview()
              queryClient.invalidateQueries('billing_settings')
              neededLookups(newQty)
            })
          }}
          planPrice={renewalPreview.subtotal_in_cents / 100}
          allocated={usage?.contact_credits?.allocated || 0}
        />}
      {upgradeUsersModalOpen &&
        <UpdateUsersModal
          open={upgradeUsersModalOpen}
          onClose={() => setUpgradeUsersModalOpen(false)}
          planPrice={renewalPreview.subtotal_in_cents / 100}
          maxUsers={usage?.users?.max || 0}
          allocated={usage?.users?.allocated || 0}
          onSave={(userChange: number) => {
            setConfirmationModalProps({
              title: userChange > 0 ? 'Add Users' : 'Remove Users',
              dynamicButtons: [
                {
                  text: userChange > 0 ? 'Add' : 'Remove',
                  color: 'primary',
                  action: () => submitChange(usage.users, userChange)
                },
                {
                  text: 'Cancel',
                  action: () => setConfirmationModalProps(undefined)
                }
              ],
              onHide: () => setConfirmationModalProps(undefined),
              children: <div>Are you sure you want to {userChange > 0 ? 'add' : 'remove'} {userChange} users?</div>
            })
            setUpgradeUsersModalOpen(false)
          }}
        />}
    </div>
  )
}
