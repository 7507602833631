import React from 'react'
import DeleteModal from 'components/DeleteModal'
import FAQTable from './FAQTable'
import FAQModal from './FAQModal'

function FAQPage (props) {
  const deleteMessage = 'Are you sure you want to delete this FAQ?'
  const deleteTitle = 'Delete FAQ'

  let tags = []
  if (props.faqs) {
    tags = [].concat.apply([], props.faqs.map(f => f.attributes.tags))
  }
  tags = new Set(tags)

  const handleModalChange = props.setModalState

  return (
    <div>
      <FAQTable
        rows={props.faqs}
        selected={props.selected}
        setSelected={props.setSelected}
        editFAQ={props.editFAQ}
        handleDelete={props.setDeleteFAQID}
      />
      <FAQModal
        open={props.modalOpen}
        onHide={() => props.setModalOpen(false)}
        saveFAQ={props.save}
        state={props.modalState}
        onChange={handleModalChange}
        tags={tags}
      />
      <DeleteModal
        open={props.deleteModalShow}
        onHide={() => props.setDeleteFAQModalOpen(false)}
        deleteObject={props.beginDelete}
        message={deleteMessage}
        title={deleteTitle}
      />
    </div>
  )
}

export default FAQPage
