import IntegrationPage from './IntegrationPage/IntegrationPage'
import { useState } from 'react'
import TabProvider from './IntegrationPage/TabProvider'
import ConnectAndSellAuthCard from './ConnectAndSell/ConnectAndSellAuthCard'

export default function ConnectAndSellIntegrationPage () {
  const [saveEnabled, setSaveEnabled] = useState(false)
  const [initSave, setInitSave] = useState(false)
  const tabs = [
    {
      name: 'General',
      components: [<TabProvider key='firsttab' setCanSave={setSaveEnabled} shouldSave={initSave}><ConnectAndSellAuthCard key='cas-auth-card' /></TabProvider>]
    }
  ]

  return (
    <IntegrationPage
      integration='ConnectAndSell'
      tabs={tabs}
      saveDisabled={!saveEnabled}
      setShouldSave={setInitSave}
    />
  )
}
