import { doPost } from 'api/api'
import { CSVFile } from 'components/CSVs/CSVFile'
import { CSVFileAttributes } from 'library/segments/CSVUploadModal'

export class CSV {
  static segmentStage (CSVFileAttributes: CSVFileAttributes): Promise<any> {
    const CSVFile = CSVFileAttributes.CSVFile
    return doPost({ path: '/import/csv/stage', rawBody: CSVFile, useChatURL: true })
  }

  static segmentPreview (CSVFileAttributes: CSVFileAttributes): Promise<any> {
    const data = {
      attributes: {
        mapping: CSVFileAttributes.headerMapping,
        fallbackUserId: CSVFileAttributes.fallbackUserId
      }
    }
    return doPost({ path: `/import/companies/${CSVFileAttributes.staged_import_id}/preview`, useChatURL: true, data: data })
  }

  static segmentFinalize (CSVFileAttributes: CSVFileAttributes, segment_id: number): Promise<any> {
    const data = {
      attributes: {
        segmentId: segment_id,
        mapping: CSVFileAttributes.headerMapping,
        ...CSVFileAttributes
      }
    }
    return doPost({ path: `/import/companies/${CSVFileAttributes.staged_import_id}/finalize`, useChatURL: true, data: data })
  }

  static stage (CSVFile: CSVFile): Promise<any> {
    const file = CSVFile.file
    return doPost({ path: '/import/csv/stage', rawBody: file, useChatURL: true })
  }

  static finalize (CSVFile: CSVFile): Promise<any> {
    const data = {
      attributes: {
        mapping: CSVFile.headerMapping,
        ...CSVFile
      }
    }
    return doPost({ path: `/import/csv/${CSVFile.staged_import_id}/finalize`, useChatURL: true, data: data })
  }
}
