import { createContext, useEffect, useRef, useState } from 'react'
import { jsPlumbToolkit, jsPlumbUtil, Node, Surface } from 'jsplumbtoolkit'
import { initializeSurface } from './helpers/surfaceSetup'
import { initializeToolkit, Toolkit } from './helpers/toolkit'
import { addEventHandlers, removeEventHandlers } from './helpers/keyboardShortcuts'
import { Emitter, EVENT_TYPE } from './helpers/EventEmitter'
export type { selectionProps } from './helpers/toolkitHelpers'
export type { Node } from 'jsplumbtoolkit'

declare global {
  interface Window { flowBuilder: Record<string, any>, sequenceNode: any }
}

type SaveProps = {
  data: any
  publish?: boolean
}

export type FlowType = 'plays' | 'bots' | 'sequences'

export type SaveFunction = ({ data, publish }: SaveProps) => Promise<any>
interface ProviderProps {
  children: JSX.Element
  save?: SaveFunction
  editDetails?: () => void
  dataRef?: any
  object: any
  initialData: any
  nodeMapping: any
  nodeFactory: any
  preview?: boolean
  objectType: FlowType
}

interface FlowBuilderValues {
  toolkit: Toolkit
  surface: Surface | null
  addNodeFromEdge: (state: NodeState) => void
  nodeMapping: Record<string, any>
  nodeCategories: Record<string, any>[]
  setSurface: any
  save: (publish: boolean) => Promise<any>
  edit: () => void
  object: any
  objectType: FlowType
  dataLoaded: boolean
}

export interface NodeState {
  kind: string
  source: any
  left: number
  top: number
}

const initialValues = {
  toolkit: jsPlumbToolkit.newInstance({}),
  surface: null,
  setSurface: null,
  addNodeFromEdge: (state: NodeState) => { return state },
  save: () => new Promise(() => true),
  object: {},
  nodeMapping: {},
  nodeCategories: [],
  objectType: 'bot' as FlowType
}

const getNodeCategories = (nodeMapping: Record<string, any>): Record<string, any>[] => {
  const nodeCategories: Record<string, any>[] = []
  const categoryMap: Record<string, any> = {}
  for (const [key, value] of Object.entries(nodeMapping)) {
    const category = value.category
    if (category) {
      if (categoryMap[category]) {
        categoryMap[category].push({ type: key, label: value.title })
      } else {
        categoryMap[category] = [{ type: key, label: value.title }]
      }
    }
  }
  for (const [key, value] of Object.entries(categoryMap)) {
    nodeCategories.push({ label: key, nodes: value })
  }
  return nodeCategories
}

export const FlowBuilderContext = createContext<FlowBuilderValues>(initialValues)

export function FlowBuilderProvider (props: ProviderProps): JSX.Element {
  const elementRef = useRef<any>(null)
  const [surface, setSurface] = useState<Surface | null>(null)
  const [nodeCategories, setNodeCategories] = useState<Record<string, string[]>[]>([])
  const [toolkit] = useState<jsPlumbToolkit>(initializeToolkit(props.nodeFactory, props.preview || false))
  const [dataLoaded, setDataLoaded] = useState(false)
  const save = props.save
  const edit = props.editDetails

  useEffect(() => {
    if (!nodeCategories.length) {
      const categories = getNodeCategories(props.nodeMapping)
      setNodeCategories(categories)
    }
    if (surface) {
      window.flowBuilder = { nodeMapping: props.nodeMapping, mode: 'pan' }
      if (!props.preview) {
        elementRef.current = addEventHandlers(surface)
      }
      initializeSurface(surface, toolkit)
      setTimeout(() => {
        toolkit.load(props.initialData)
        surface.setZoom(0.7)
        surface.zoomToFitIfNecessary()
        setDataLoaded(true)
      })
    }
    return () => {
      if (elementRef.current) {
        removeEventHandlers(elementRef.current)
      }
    }
    // eslint-disable-next-line
  }, [surface])

  const addNodeFromEdge = (nodeState: NodeState): void => {
    const type = 'basic'
    const nodeData = { kind: nodeState.kind }
    toolkit.addFactoryNode(type, nodeData,
      function (newNode: Node) {
        if (nodeState.source) {
          const edgeData = {
            id: jsPlumbUtil.uuid(),
            type: 'common'
          }
          toolkit.addEdge({ source: nodeState.source, target: newNode, data: edgeData })
        }
        surface?.setPosition(newNode, nodeState.left, nodeState.top)
      }
    )
  }

  const handleSave = (publish: boolean): Promise<any> => {
    if (save) {
      Emitter.emit(EVENT_TYPE.STATE_SAVED, { toolkitRefID: toolkit.id })
      return save({ data: toolkit.exportData(), publish })
    } else {
      return Promise.resolve()
    }
  }

  if (props.dataRef) {
    props.dataRef.current = toolkit
  }

  const values = {
    toolkit: toolkit,
    surface: surface,
    addNodeFromEdge: addNodeFromEdge,
    setSurface: setSurface,
    save: handleSave,
    edit: edit,
    nodeMapping: props.nodeMapping,
    nodeCategories: nodeCategories,
    object: props.object,
    objectType: props.objectType,
    dataLoaded: dataLoaded
  }

  return (
    <FlowBuilderContext.Provider value={values}>
      {props.children}
    </FlowBuilderContext.Provider>
  )
}
