import { BillingContext } from 'billing-context'
import { makeStyles } from '@material-ui/core/styles'
import { useContext } from 'react'
import ChipIcon from 'img/card_chip.png'
import VisaIcon from 'img/visa_card.jpg'
import MastercardIcon from 'img/mastercard.png'
import DiscoverIcon from 'img/discover.png'
import AmericanExpressIcon from 'img/americanexpress.jpg'

const cardMapping = {
  visa: { name: 'Visa', img: VisaIcon },
  master: { name: 'MasterCard', img: MastercardIcon },
  americanExpress: { name: 'AmericanExpress', img: AmericanExpressIcon },
  bogus: { name: 'Visa', img: VisaIcon },
  discover: { name: 'Discover', img: DiscoverIcon }
}

const useStyles = makeStyles(theme => ({
  cardDetails: {
    font: 'normal normal normal 18px/27px Poppins',
    color: '#000000'
  },
  cardExpiration: {
    color: '#00000080',
    font: 'normal normal normal 14px/21px Poppins'
  }
}))

export function CreditCard () {
  const classes = useStyles()
  const { primaryPayment } = useContext(BillingContext)

  if (primaryPayment) {
    const profile = primaryPayment.attributes
    const expiration = profile.expiration_month.toString() + '/' + profile.expiration_year.toString()
    let cardType = profile.card_type
    let cardImage = ChipIcon
    if (profile.card_type && profile.card_type in cardMapping) {
      const mapping = cardMapping[profile.card_type]
      cardType = mapping.name
      cardImage = mapping.img
    }
    const number = profile.masked_card_number
    const lastFour = number.substr(number.length - 4)

    const cardDetails = cardType + ' ending in ' + lastFour
    const expires = 'Expires ' + expiration

    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ minWidth: 60, marginRight: 10 }}>
          <img src={cardImage} alt='Credit Card Logo' height={35} style={{ borderRadius: 5 }} />
        </div>
        <div>
          <div className={classes.cardDetails}>{cardDetails}</div>
          <div className={classes.cardExpiration}>{expires}</div>
        </div>
      </div>
    )
  }
  return (
    <div>
      Credit Card
    </div>
  )
}
