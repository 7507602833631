import { makeStyles } from '@material-ui/core/styles'
import Dropzone from 'react-dropzone'
import MuiDialogContent from '@material-ui/core/DialogContent'
import Button from '@material-ui/core/Button'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import { Icon } from 'library/materialUI'

const dropzoneStyles = makeStyles(theme => ({
  rightIcon: {
    marginRight: theme.spacing(1),
    color: 'black'
  },
  audioIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  dropzone: {
    cursor: 'pointer',
    height: 280,
    display: 'flex'
  },
  paragraph: {
    color: 'gray',
    fontSize: 16,
    marginBottom: 0,
    marginTop: 10
  },
  paragraph2: {
    color: 'gray',
    fontSize: 15,
    marginBottom: 10,
    marginTop: 0
  },
  content: {
    margin: 'auto',
    width: '75%',
    textAlign: 'center',
    verticalAlign: 'center'
  },
  button: {
    padding: '5px 15px'
  }
}))

interface SoundDropZoneProps {
  setSound: (file: any) => void
  setDisabled: (value: boolean) => void
  setUploadError: (value: boolean) => void
}

export default function SoundDropzone ({ setSound, setDisabled, setUploadError }: SoundDropZoneProps) {
  const classes = dropzoneStyles()

  const handleDrop = (acceptedFile: Array<any>) => {
    const soundFile = acceptedFile[0]
    if (soundFile.type !== 'audio/mpeg' || !soundFile.name.includes('.mp3')) {
      setUploadError(true)
      return
    }
    setDisabled(false)
    setSound(soundFile)
  }

  return (
    <Dropzone onDrop={handleDrop}>
      {({ getRootProps, getInputProps }) => (
        <MuiDialogContent
          dividers
          {...getRootProps()}
          className={classes.dropzone}
        >
          <input {...getInputProps()} />
          <div className={classes.content}>
            <div className={classes.audioIcon}>
              <Icon icon='audioFile' size='xl' color='gray' />
            </div>
            <p className={classes.paragraph}>Drag a sound file here</p>
            <p className={classes.paragraph2}>-or-</p>
            <Button variant='outlined' size='small' className={classes.button}>
              <CloudUploadIcon className={classes.rightIcon} />
              Upload from computer
            </Button>
          </div>
        </MuiDialogContent>
      )}
    </Dropzone>
  )
}
