/* eslint-disable @typescript-eslint/naming-convention */
import FTUX, { FTUXAnchor } from './FTUX'
import { DisplaySettings } from 'classes/displaySettings'
import OnboardingPrompt from './OnboardingPrompt'
import { useState } from 'react'

export interface promptListType {
  anchorID: string
  xOffset: number
  yOffset: number
  updateFunction: () => void
  anchors: FTUXAnchor[],
  visible: boolean,
  closingFunction?: () => void
}

interface OnboardingPromptMapperProps {
  defaultState: any
  promptList: promptListType[]
  pageName: string
  dimmed?: boolean
}

export default function OnboardingPromptMapper ({ defaultState, promptList, pageName, dimmed }: OnboardingPromptMapperProps) {
  const { settings, isLoading } = DisplaySettings.load()
  const [hidePrompts, setHidePrompts] = useState(false)

  if (!isLoading && settings.ftux) {
    if (!settings.ftux.settings[pageName]) {
        DisplaySettings.update({ page: 'ftux', type: pageName, settings: { ...defaultState } })
    }
  } else if (!isLoading && !settings) {
    DisplaySettings.create({ page: 'ftux', type: pageName, settings: { ...defaultState } })
  }

  return (
    <>
      {promptList.map((prompt, index) => {
        return (
          <div key={index}>
            <OnboardingPrompt
              anchorID={prompt.anchorID}
              xOffset={prompt.xOffset}
              yOffset={prompt.yOffset}
              visible={prompt.visible}
              hidden={hidePrompts}
              setHidden={setHidePrompts}
            >
              <FTUX
                page={pageName}
                anchors={prompt.anchors}
                updateFunction={prompt.updateFunction}
                closingFunction={prompt.closingFunction || undefined}
                dim={dimmed || false}
              />
            </OnboardingPrompt>
          </div>
        )
      })}
    </>
  )
}
