import { getConversationEvents } from 'api/conversations'
import { usePostageSocket } from 'components/PostageSocket'
import { makeStyles } from '@material-ui/core/styles'
import { createContext, ReactNode, useEffect, useReducer, useState } from 'react'
import { ActiveConversationLoading } from './ChatLoaders'
import DesertIsland from 'img/DesertIsland.svg'
import { Button } from '@material-ui/core'
import DeleteForeverIcon from '@material-ui/icons/DeleteForeverRounded'
import { useHistory } from 'react-router-dom'
import { getParticipantData, saveParticipantData } from 'api/participants'
import { unstable_batchedUpdates } from 'react-dom'
import { getContactIntegrationMappings } from 'pages/people/ContactPage/integrations/integration-helpers'
import dateFormat from 'dateformat'
/* eslint-disable @typescript-eslint/naming-convention */

const useStyles = makeStyles(theme => ({
  activeConversation: {
    position: 'relative',
    display: 'grid',
    height: '100vh',
    width: '100%',
    gridTemplateAreas:
      `"contact sidebar"
    "messages sidebar"
    "message-entry sidebar"`,
    gridTemplateColumns: 'auto minmax(290px, min(340px, 25%))',
    gridTemplateRows: '90px calc(100% - 210px) 120px'
  },
  messages: {
    gridArea: 'messages'
  },
  deletedConversation: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  deleteIcon: {
    height: 112,
    width: 112,
    color: '#B894EC'
  },
  subtitle: {
    maxWidth: 325,
    textAlign: 'center',
    margin: '0 0 20px'
  },
  button: {
    backgroundColor: 'white',
    border: '#B894EC 1px solid',
    color: '#B894EC'
  },
  islandIcon: {
    height: 300
  }
}))

const initialState = {
  events: [],
  participants: [],
  typing: []
}

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case 'refreshEvents':
      return {
        ...state,
        events: action.events,
        participants: action.participants,
        typing: action.typing
      }
    case 'appendEvent':
      return {
        ...state,
        events: [...state.events, action.value]
      }
    case 'appendTyping':
      return {
        ...state,
        typing: [...state.typing, {
          participant_id: action.value.relationships.from_participant.data.id,
          created_timestamp: action.value.attributes.created_timestamp,
          participant_name: action.value.attributes.participant_name,
          participant_picture: action.value.relationships.from_participant.data.avatar_url
        }]
      }
    case 'removeTyping':
      return {
        ...state,
        typing: state.typing.filter((event: any) => event.participant_id !== action.value.relationships.from_participant.data.id)
      }
    default:
      break
  }
}

const refreshConversationEvents = async ({ dispatch, conversationID }: any) => {
  const response = await getConversationEvents({ conversationID })
  if (response.data) {
    const participantDict: any = {}
    for (const p of response.included) {
      participantDict[p.id] = { ...p.attributes, id: p.id }
    }
    dispatch({
      type: 'refreshEvents',
      events: response.data,
      participants: participantDict,
      typing: response.meta.typing
    })
  }
}

interface ConversationValues {
  state: any
  loading: boolean
  connectionStatus: any
  conversation: any
  refreshConversation: any
  selectConversation: any
  conversationID: number
  setMessage: any
  integrationObjectMappings: any
  setIntegrationObjectMappings: (value: any) => void
  contact: any
  setContact: (value: any) => void
  refreshContact: boolean
  setRefreshContact: (value: boolean) => void
  saveContact: (state: any) => void
}

export interface ConversationProps {
  open: boolean
  setOpen: (value: boolean) => void
  conversation: any
  refreshConversation: any
  selectConversation: any
  conferenceOpen: boolean
  setConferenceOpen: (value: boolean) => void
  calendarOpen: boolean
  setCalendarOpen: (value: boolean) => void
}

interface ConversationProviderProps extends ConversationProps {
  children: ReactNode
  setMessage: any
}

export const ConversationContext = createContext<ConversationValues>({
  state: initialState,
  loading: false,
  connectionStatus: undefined,
  conversation: undefined,
  conversationID: 0,
  integrationObjectMappings: {},
  setIntegrationObjectMappings: () => undefined,
  refreshConversation: () => undefined,
  selectConversation: () => undefined,
  setMessage: () => undefined,
  contact: undefined,
  setContact: () => undefined,
  refreshContact: true,
  setRefreshContact: () => undefined,
  saveContact: () => undefined
})

export function ConversationProvider (props: ConversationProviderProps): JSX.Element {
  const classes = useStyles()
  const [state, dispatch] = useReducer(reducer, initialState)
  const [contact, setContact] = useState<any>(null)
  const [refreshContact, setRefreshContact] = useState(true)
  const [loading, setLoading] = useState(false)
  const [loadingParticipant, setLoadingParticipant] = useState(false)
  const [integrationObjectMappings, setIntegrationObjectMappings] = useState({})
  const conversation = props.conversation
  const conversationID = conversation?.id
  const contactID = conversation?.relationships?.for_contact?.data?.id
  const participantID = conversation?.relationships?.for_participant?.data?.id

  const history = useHistory()

  const refreshContactIntegrationMappings = (contactID: any) => {
    getContactIntegrationMappings(contactID).then((response) => setIntegrationObjectMappings(response))
  }

  useEffect(() => {
    if (conversationID) {
      setLoading(true)
      refreshConversationEvents({ dispatch, conversationID })
        .then(() => {
          setTimeout(() => {
            setLoading(false)
          }, 100)
        })
    }
  }, [conversationID])

  useEffect(() => {
    if (participantID && refreshContact && !loadingParticipant) {
      setLoadingParticipant(true)
      getParticipantData({ participantID }).then(response => {
        unstable_batchedUpdates(() => {
          setRefreshContact(false)
          setContact(response.data)
          if (contactID) {
            refreshContactIntegrationMappings(response.data.id)
          } else {
            setIntegrationObjectMappings({})
          }
          setLoadingParticipant(false)
        })
      })
    }
  }, [participantID, refreshContact, contactID, loadingParticipant])

  const saveContact = (state: any) => {
    if (participantID) {
      /**
      * The purpose of this is that when the participant is updated the updated_timestamp is not changed in the first response.
      * So we set it to now for the integrations to correctly be out of date
      */
      const newUpdatedTimestamp = dateFormat(new Date(), 'yyyy-mm-dd\'T\'HH:MM:ss.lo', true).replace(/..$/, ':$&').replace(/[+-]..:..$/, '000$&')
      saveParticipantData({ state, participantID })
        .then((response) => {
          props.refreshConversation()
          response.data.attributes.updated_timestamp = newUpdatedTimestamp
          setContact(response.data)
        })
    }
  }

  const { connectionStatus } = usePostageSocket((msg: any) => {
    if (msg.type === 'conversation_events') {
      if (msg.relationships.conversation.data.id === conversationID) {
        const pid = msg.relationships.from_participant.data.id
        if (state.participants[pid]) {
          dispatch({ type: 'appendEvent', value: msg })
          if (msg.attributes.kind === 'halt' || msg.attributes.kind === 'chat') {
            dispatch({ type: 'removeTyping', value: msg })
          } else if (msg.attributes.kind === 'type') {
            dispatch({ type: 'appendTyping', value: msg })
          }
        } else {
          refreshConversationEvents({ dispatch, conversationID })
        }
      }
    }
  })
  if (conversation === undefined) {
    return (
      <div className={classes.activeConversation}>
        <ActiveConversationLoading loading={loading} />
      </div>
    )
  }

  if (conversation === null) {
    return (
      <div className={classes.deletedConversation}>
        <img src={DesertIsland} alt='Desert Island Icon' className={classes.islandIcon} />
        <h3>The conversation does not exist on this account</h3>
        <Button
          variant='contained'
          size='medium'
          className={classes.button}
          onClick={() => history.goBack()}
        >
          GO BACK
        </Button>
      </div>
    )
  }

  if (conversation.attributes?.deleted_timestamp) {
    return (
      <div className={classes.deletedConversation}>
        <DeleteForeverIcon
          fontSize='large'
          className={classes.deleteIcon}
        />
        <h3>It looks like this conversation was deleted by an admin.</h3>
        <p className={classes.subtitle}>This is likely because the conversation was used for testing or it wasn't a legitimate conversation</p>
        <Button
          variant='contained'
          size='medium'
          className={classes.button}
          onClick={() => history.goBack()}
        >
          GO BACK
        </Button>
      </div>
    )
  }

  return (
    <ConversationContext.Provider
      value={{
        ...state,
        conversation: conversation,
        refreshConversation: props.refreshConversation,
        selectConversation: props.selectConversation,
        connectionStatus: connectionStatus,
        conversationID: conversationID,
        setMessage: props.setMessage,
        integrationObjectMappings: integrationObjectMappings,
        setIntegrationObjectMappings: setIntegrationObjectMappings,
        contact: contact,
        setContact: setContact,
        saveContact: saveContact,
        setRefreshContact: setRefreshContact,
        refreshContact: refreshContact
      }}
    >
      {props.children}
    </ConversationContext.Provider>
  )
}
