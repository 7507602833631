import { Divider, Fade, Paper, Popper, PopperPlacementType, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useQuery } from 'react-query'
import { hasComponent } from 'api/billing'
import { useDoQuery } from 'classes/useDoQuery'
import Loader from 'library/loading/Loader'
import BlurredImg from 'img/Blurred Insights.png'
import { Button, Icon } from 'library/materialUI'
import { useState, useContext } from 'react'
import { CircularProgressbar } from 'react-circular-progressbar'
import { SessionContext } from 'session-context'

const useStyles = makeStyles(theme => ({
  popperContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    padding: 20,
    width: 280
  },
  popperHeader: {
    display: 'flex',
    alignItems: 'center'
  },
  popper: {
    zIndex: 10000,
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '0 1em 1em 1em',
        borderColor: `transparent transparent ${theme.palette.background.paper} transparent`
      }
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '1em 1em 0 1em',
        borderColor: `${theme.palette.background.paper} transparent transparent transparent`
      }
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 1em 1em 0',
        borderColor: `transparent ${theme.palette.background.paper} transparent transparent`
      }
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 0 1em 1em',
        borderColor: `transparent transparent transparent ${theme.palette.background.paper}`
      }
    }
  },
  arrow: {
    position: 'absolute',
    fontSize: 7,
    width: '3em',
    height: '3em',
    '&::before': {
      content: '""',
      margin: 'auto',
      width: 0,
      height: 0,
      border: '1px solid black'
    }
  }
}))

interface SignalsScoreHistoryProps {
  anchorEl: any
  score: number
  open: boolean
  placement: PopperPlacementType
  setHovering?: (hovering: boolean) => void
  domain: string
}

interface Reason {
  attributes: string
}

export function SignalsScoreHistory ({ score, setHovering, anchorEl, placement, open, domain }: SignalsScoreHistoryProps): JSX.Element {
  const classes = useStyles()
  const [arrowRef, setArrowRef] = useState<HTMLSpanElement | null>(null)
  const { isError, isLoading, data: reasons } = useDoQuery({ path: `/accounts/${domain}/score/reasons`, objectClass: undefined, useChatURL: true })
  const [hovering, setLocalHovering] = useState(false)
  const { data: hasScoringComponent } = useQuery('has-scoring-component', () => hasComponent('score-insights'))
  const { setSalesModalOpen } = useContext(SessionContext)
  const _setHovering = (hovering: boolean) => {
    setLocalHovering(hovering)
    if (setHovering) {
      setHovering(hovering)
    }
  }

  return (
    <>
      <Popper
        id={domain}
        open={(open || hovering)}
        anchorEl={anchorEl}
        placement={placement}
        className={classes.popper}
        transition
        onMouseEnter={() => {
          _setHovering(true)
        }}
        onMouseLeave={() => {
          _setHovering(false)
        }}
        modifiers={{
          arrow: {
            enabled: true,
            element: arrowRef
          }
        }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={0}>
            <>
              <span className={classes.arrow} ref={setArrowRef} />
              <Paper className={classes.popperContainer}>
                <div className={classes.popperHeader}>
                  <div style={{ height: 50, width: 40 }}>
                    <CircularProgressbar
                      value={score}
                      text={score ? score.toString() : '0'}
                      strokeWidth={13}
                      styles={{
                        path: {
                          stroke: '#5AD8E6'
                        },
                        trail: {
                          stroke: '#f5f5f5'
                        },
                        text: {
                          fontSize: '36px',
                          fontWeight: 'bolder',
                          fill: '#505050',
                          transform: 'translateY(3px)'
                        }
                      }}
                    />
                  </div>
                  <Typography variant='h6' style={{ marginLeft: '1em' }}>Score Insights</Typography>
                </div>
                <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                <div className={classes.popperHeader}>
                  <Icon icon='thumbUp' color='#81AF20' specificSize={15} />
                  <Typography variant='h5' style={{ marginLeft: '1em' }}>Top Positive Contributers</Typography>
                </div>
                {(isError || isLoading) ? <Loader type='spinner' /> : (
                  <div>
                    <ul>
                      {reasons.data.slice(0, 3).map((reason: Reason, index: number) => {
                        if (!hasScoringComponent && index > 0) {
                          return <li key={index}><Typography variant='body1'><img src={BlurredImg} alt='blur' style={{ float: 'left', position: 'relative', width: '210px' }} /></Typography></li>
                        } else if ((!hasScoringComponent && index === 0) || (hasScoringComponent)) {
                          return <li key={index}><Typography variant='body1'>{reason.attributes}</Typography></li>
                        } else {
                          return <></>
                        }
                      })}
                    </ul>
                    {!hasScoringComponent && (
                      <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Button
                          variant='contained'
                          color='primary'
                          onClick={() => setSalesModalOpen(true)}
                        >
                          Upgrade to see more
                        </Button>
                      </div>
                    )}
                  </div>
                )}
              </Paper>
            </>
          </Fade>
        )}
      </Popper>
    </>
  )
}
