import { useState, useContext } from 'react'
import BaseCard from './BaseCard'
import { makeStyles } from '@material-ui/core/styles'
import { MenuItem, Menu, Checkbox, ListItemText, Tooltip, Fab, Divider, Chip } from '@material-ui/core'
import FilterListIcon from '@material-ui/icons/FilterList'
import SwapVertIcon from '@material-ui/icons/SwapVert'
import CloseIcon from '@material-ui/icons/Close'
import { ScrollBox } from 'cf-components/ScrollBox'
import { ConversationContext } from '../../ConversationContext'
import './customstyles.css'
import SavedReply from 'classes/savedReplies'
import Loader from 'library/loading/Loader'

const useStyles = makeStyles(theme => ({
  buttons: {
    fontSize: '12px',
    boxShadow: 'none',
    textTransform: 'none',
    color: 'white',
    maxHeight: 24
  },
  replyList: {
    maxHeight: 170
  },
  replyListExpanded: {
    maxHeight: 350
  },
  replyRow: {
    display: 'grid',
    gridTemplateColumns: 'auto 50px',
    gridGap: 10,
    alignItems: 'center',
    fontSize: '14px',
    padding: '5px 0px',
    cursor: 'pointer'
  },
  replyText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%'
  },
  actionsDiv: {
    display: 'flex',
    alignItems: 'center'
  },
  filterIcon: {
    height: 16,
    width: 16,
    marginRight: 6,
    marginBottom: 3
  },
  selectInput: {
    fontSize: '12px',
    color: '#4A4A4A'
  },
  menuItem: {
    padding: '3px 8px',
    fontSize: '12px'
  },
  labelText: {
    fontSize: '12px'
  },
  actionIcon: {
    height: 20,
    cursor: 'pointer'
  },
  filterChip: {
    marginRight: 4,
    maxWidth: '100%',
    backgroundColor: '#F0E3FF',
    fontSize: 12,
    color: theme.palette.primary.main,
    height: 'unset',
    '& > span': {
      paddingRight: 8
    },
    '& > svg': {
      color: 'inherit',
      width: 16,
      height: 16
    }
  }
}))

function InsertButton () {
  const classes = useStyles()

  return (
    <Fab
      variant='extended'
      size='small'
      className={classes.buttons}
      color='primary'
    >
      Insert
    </Fab>
  )
}

interface SavedReplyDisplayProps {
  title: string
  contents: string
  insertReply: (reply: string) => void
}

function SavedReplyDisplay ({ title, contents, insertReply } : SavedReplyDisplayProps) {
  const classes = useStyles()

  return (
    <div>
      <div
        className={classes.replyRow}
        onClick={() => insertReply(contents)}
      >
        <div className={classes.replyText}>
          {title}
        </div>
        <InsertButton />
      </div>
      <Divider />
    </div>
  )
}

interface FilterProps {
  filters: string[]
  handleChange: (action: string) => void
}

function Filter ({ filters, handleChange }: FilterProps) {
  const classes = useStyles()
  const { data, isLoading } = SavedReply.loadAll()
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  if (isLoading) {
    return (
      <Loader />
    )
  }

  const categories = SavedReply.getCategories(data.list)

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  if (!categories.length) {
    return <></>
  }

  return (
    <>
      {!filters.length ? <></> : (
        <Chip
          className={classes.filterChip}
          label={filters.length + ' filter' + (filters.length === 1 ? '' : 's')}
          onDelete={() => handleChange('reset')}
          deleteIcon={<CloseIcon />}
        />
      )}
      <FilterListIcon
        color='primary'
        onClick={handleClick}
        className={classes.actionIcon}
      />
      <Menu
        id='long-menu'
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        <MenuItem value='' className={classes.menuItem}>Filter By</MenuItem>
        {
          categories.map((c, index) => (
            <MenuItem value={c} key={index} className={classes.menuItem} onClick={() => handleChange(c)}>
              <Checkbox checked={filters.indexOf(c) > -1} color='primary' style={{ padding: 4 }} />
              <ListItemText primary={c} classes={{ primary: classes.labelText }} />
            </MenuItem>
          ))
        }
      </Menu>
    </>
  )
}

interface SavedRepliesCardProps {
  id: number
}

export default function SavedRepliesCard ({ id } : SavedRepliesCardProps) {
  const { setMessage } = useContext(ConversationContext)
  const { data, isLoading } = SavedReply.loadAll()
  const insertReply = (value: string) => setMessage(value)
  const classes = useStyles()
  const [filters, setFilters] = useState<string[]>([])
  const [expanded, setExpanded] = useState(false)

  if (isLoading) {
    return (
      <Loader />
    )
  }

  const replies = data.list

  function handleChange (value: string) {
    if (value === 'reset') {
      setFilters([])
    } else if (filters.indexOf(value) > -1) {
      setFilters([...filters].filter(f => f !== value))
    } else {
      setFilters([...filters, value])
    }
  }

  const filteredReplies = replies.filter(r =>
    filters.length === 0 || (r.categories &&
      (r.categories.filter(
        value => filters.indexOf(value) !== -1)).length > 0))

  return (
    <div id='saved_replies_2'>
      <BaseCard
        title='Saved Replies'
        id={id}
        actions={
          <div className={classes.actionsDiv}>
            <Filter
              handleChange={handleChange}
              filters={filters}
            />
            <Tooltip title={expanded ? 'Condense' : 'Expand'}>
              <SwapVertIcon
                color='primary'
                className={classes.actionIcon}
                onClick={() => setExpanded(!expanded)}
              />
            </Tooltip>
          </div>
        }
      >
        <ScrollBox className={`${classes.replyList} ${expanded ? classes.replyListExpanded : null} no-scroll`}>
          {filteredReplies.map(row => (
            <SavedReplyDisplay
              title={row.name}
              key={row.id}
              contents={row.content}
              insertReply={insertReply}
            />
          ))}
        </ScrollBox>
      </BaseCard>
    </div>
  )
}
