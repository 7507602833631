import MergeFieldsButton from 'cf-components/rich-text/MergeFieldsButton'
import { TextBox } from 'library/materialUI'
import { ModalSection } from 'library/Modal'
import { useRef } from 'react'

type NotificationSettingsProps = {
  notification: {
    title: string
    description: string
  }
  handleChange: (settings: any) => void
}

export function NotificationSettings (props: NotificationSettingsProps): JSX.Element {
  const { notification, handleChange } = props
  const inputRef = useRef<Record<any, any>>()

  const handlePropertyChange = (value: string, prop: 'title' | 'description', currentDescription?: string): void => {
    if (currentDescription && inputRef.current) {
      value = currentDescription.substring(0, inputRef.current.selectionStart) + value + currentDescription.substring(inputRef.current.selectionStart)
    }

    const settings = { ...notification }
    settings[prop] = value
    handleChange(settings)
  }

  return (
    <ModalSection
      title='Notification'
      subtitle='Configure the text and title of the notification. The agent can receive in-app, text, or email alerts depending on their preferred settings.'
    >
      <div style={{ marginBottom: 10 }}>
        <TextBox
          value={notification.title}
          onChange={(value: string) => handlePropertyChange(value, 'title')}
          label='Notification Title'
        />
      </div>
      <MergeFieldsButton
        title='{...} Insert Merge Field'
        action={(value: any) => handlePropertyChange(value, 'description', notification.description)}
        button
        alerts
      />
      <TextBox
        value={notification.description}
        onChange={(value: string) => handlePropertyChange(value, 'description')}
        label='Notification Text'
        inputRef={inputRef}
        rows={6}
      />
    </ModalSection>
  )
}
