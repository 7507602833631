import { useMemo } from 'react'
import { makeStyles, ThemeProvider } from '@material-ui/core/styles'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import Tooltip from '@material-ui/core/Tooltip'
import InfoIcon from '@material-ui/icons/Info'
import WarningIcon from '@material-ui/icons/Warning'
import { Formik, Form, FieldArray } from 'formik'
import theme from 'theme'
import { Modal } from 'cf-components/Modal'
import ConditionalDivider from 'cf-components/ConditionalDivider'
import SegmentFilterGroup from './SegmentFilterGroup'

const useStyles = makeStyles(theme => ({
  filterGroup: {
    border: '1px solid rgba(142,90,226,0.5)',
    backgroundColor: 'rgba(142,90,226,0.03)',
    borderRadius: 10
  },
  header: {
    display: 'flex',
    margin: '5px 5px 10px 15px',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  title: {
    fontSize: '0.95em',
    fontFamily: 'Poppins',
    color: 'rgba(0,0,0,0.75)',
    fontWeight: 600
  },
  buttonDiv: {
    margin: 15
  },
  icon: {
    marginRight: 10
  },
  triggerSet: {
    marginTop: 20
  },
  divider: {
    backgroundColor: theme.palette.primary.main
  },
  filterGroupButton: {
    marginTop: 30
  },
  iconButton: {
    padding: 8
  },
  sectionHeader: {
    fontSize: '14px',
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
    color: '#4C4C4C'
  },
  infoIcon: {
    marginLeft: 5,
    marginBottom: 2,
    fill: '#8e5ae2',
    fontSize: '1.3em'
  },
  warningCtn: {
    borderRadius: 10,
    padding: '10px 12px',
    border: '1px solid rgb(255,0,0)',
    marginBottom: 14,
    background: 'rgb(255 239 239)',
    color: theme.palette.text.primary,
    fontSize: '0.9rem'
  },
  warningTitleCtn: {
    color: '#ff3838',
    fontWeight: 'bold',
    fontSize: '1.1rem',
    display: 'flex',
    alignItems: 'flex-end',
    gap: '8px',
    paddingBottom: 8
  }
}))

function isGroupDisabled (values) {
  if (!values.label) {
    return true
  }
  if (!values.triggers.length) {
    return true
  }
  for (const trigger of values.triggers) {
    for (const filter of trigger.filters) {
      if (!filter.cmp || !filter.prop) {
        return true
      }
      if (!filter.value) {
        if (filter.cmp === 'is_known' || filter.cmp === 'not_known') {
          return false
        } else if (filter.value === 0) {
          return false
        } else {
          return true
        }
      }
    }
  }
  return false
}

// ###### Shape of trigger ######
// const triggers = [
//   {
//     filters: [{ cmp: 'contains', prop: 'url', value: 'httpsasdfasdfasdfasdfasdf' }]
//   },
//   {
//     filters: [{ cmp: 'in', prop: 'us_state', value: ['California'] }]
//   }
// ]

const newTrigger = {
  filters: []
}

function SegmentCriteriaEditor (props) {
  const classes = useStyles()
  const customFilters = props.customFilters
  const segmentObjectType = props.segmentObjectType || 'Contacts'

  /** @type {import('cf-components/Modal').ModalButtonProps} */
  const leftButtonProps = useMemo(() => ({
    text: props.handleBack ? 'Back' : 'Cancel',
    action: props.handleBack || props.onHide,
    variant: 'text'
  }), [props.handleBack, props.onHide])

  return (
    <Formik
      initialValues={{ triggers: props.triggers, label: props.label, id: props.id }}
      enableReinitialize
      onSubmit={values => props.save(values)}
      render={({ values, submitForm, resetForm }) => {
        const disabled = isGroupDisabled(values)
        return (
          <Modal
            open={props.open}
            onHide={() => {
              props.onHide()
              resetForm()
            }}
            size='md'
            title={values.label}
            rightButtonProps={{
              text: 'Save',
              action: submitForm,
              disabled: disabled
            }}
            leftButtonProps={leftButtonProps}
          >
            <ThemeProvider theme={theme}>
              {props.editStaticSegmentWarning && (
                <div className={classes.warningCtn}>
                  <div className={classes.warningTitleCtn}>
                    <WarningIcon />
                    <div>This is a STATIC Segment</div>
                  </div>
                  <div>
                    You are editing a static segment. Static segments are a one type snapshot of {segmentObjectType} that meet this criteria. Editing this segment will refresh the {segmentObjectType} in this segment.
                  </div>
                </div>
              )}
              <Form>
                <div className={classes.sectionHeader}>
                  Add Segment Filters
                  <Tooltip title={`${segmentObjectType} must meet ALL of the criteria listed here to be included in the group`}>
                    <InfoIcon className={classes.infoIcon} />
                  </Tooltip>
                </div>
                <Divider className={classes.divider} />
                <FieldArray
                  name='triggers'
                  render={arrayHelpers => (
                    <div className={classes.triggerSet}>
                      {values.triggers.map((trigger, index) => (
                        <div key={index}>
                          <SegmentFilterGroup
                            index={index}
                            trigger={trigger}
                            handleRemoveFilterGroup={() => arrayHelpers.remove(index)}
                            chatServiceUrl={props.chatServiceUrl}
                            customFilters={customFilters}
                          />
                          <ConditionalDivider
                            operator='or'
                            filterLen={values.triggers.length}
                            index={index}
                          />
                        </div>
                      ))}
                      <div className={classes.filterGroupButton}>
                        <Button
                          color='primary'
                          variant='outlined'
                          onClick={() => arrayHelpers.push(newTrigger)}
                        >
                          Add Filter Group
                        </Button>
                      </div>
                    </div>
                  )}
                />
              </Form>
            </ThemeProvider>
          </Modal>
        )
      }}
    />

  )
}

export default SegmentCriteriaEditor
