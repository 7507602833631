import React from 'react'
import BotHead from '../../img/Logo_purple_transparent_BG.png' // chatfunnels.png'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  poweredBy: {
    backgroundColor: '#FFF',
    color: '#999',
    boxShadow: '0px 0px 8px 2px rgba(85,85,85,0.3)',
    borderRadius: 10,
    fontFamily: 'Poppins',
    fontSize: 13,
    display: 'flex',
    marginTop: 5,
    alignItems: 'center',
    cursor: 'pointer',
    height: 45,
    '& p': {
      margin: '10px 15px 10px 0px'
    },
    '& img': {
      margin: 10
    }
  }

}))

function PoweredBy (props) {
  const classes = useStyles()
  return (
    <div className={classes.poweredBy} onClick={() => window.open('https://chatfunnels.com', '_blank')}>
      <img src={BotHead} height={25} width={25} alt='Signals Logo' />
      <p>Powered by Signals</p>
    </div>
  )
}

export default PoweredBy
