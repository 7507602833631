import AlertsList from 'pages/accounts/AccountManagement/Alerts/AlertsList'
import EditAlertModal from 'pages/accounts/AccountManagement/Alerts/EditAlertModal'

const PersonalAlertManagement = props => {
  return (
    <>
      <AlertsList personal />
      <EditAlertModal
        open={props.alertModalOpen}
        onHide={props.onHide}
        isGlobal={false}
      />
    </>
  )
}

export default PersonalAlertManagement
