import { makeStyles } from '@material-ui/core/styles'
import Fab from '@material-ui/core/Fab'
import PricingInformation from './PricingInformation'
import { Button } from 'library/materialUI'
import { ChargifyOffer } from 'classes/chargifyOffer'
import { ReactComponent as CreditCardIcon } from 'img/icon-credit-card.svg'
import { useContext, useEffect, useState } from 'react'
import { BillingContext } from 'billing-context'
import ChipIcon from 'img/card_chip.png'
import VisaIcon from 'img/visa.png'
import MastercardIcon from 'img/mastercard.png'
import DiscoverIcon from 'img/discover.png'
import AmericanExpressIcon from 'img/americanexpress.jpg'
import { getOfferPreview, purchaseOffer } from 'api/billing'
import PaymentMethodSelector from './PaymentMethodSelector'
import { ReactComponent as YellowLockIcon } from 'img/icon-material-lock.svg'

const useStyles = makeStyles(theme => ({
  rowStyle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    textAlign: 'center'
  },
  planTitle: {
    fontSize: '32px',
    fontWeight: 'bold',
    fontFamily: 'poppins',
    display: 'flex',
    justifyContent: 'center'
  },
  messageText: {
    fontSize: '20px',
    fontFamily: 'poppins',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    marginTop: '5px',
    marginBottom: '15px'
  },
  reactivate: {
    paddingRight: '92px',
    paddingLeft: '92px',
    fontSize: '20px',
    fontFamily: 'poppins',
    textTransform: 'none',
    borderRadius: '28px'
  },
  buttonContent: {
    paddingRight: '80px',
    paddingLeft: '80px',
    fontSize: '20px',
    fontFamily: 'poppins',
    textTransform: 'none',
    borderRadius: '28px'
  },
  seeOther: {
    paddingRight: '68px',
    paddingLeft: '68px',
    fontSize: '20px',
    fontFamily: 'poppins',
    textTransform: 'none',
    borderRadius: '28px',
    border: '0px',
    color: '#975ce6'
  },
  purpleBorder: {
    border: '2px',
    borderColor: '#975ce6',
    borderStyle: 'solid',
    borderRadius: '5px',
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'row',
    height: '124px',
    alignItems: 'center'
  }
}))

const cardMapping = {
  visa: { name: 'Visa', img: VisaIcon },
  master: { name: 'MasterCard', img: MastercardIcon },
  americanExpress: { name: 'AmericanExpress', img: AmericanExpressIcon },
  bogus: { name: 'Visa', img: VisaIcon },
  discover: { name: 'Discover', img: DiscoverIcon }
}

export default function ConfirmPlan (props: any) {
  const classes = useStyles()
  const planType = props.planType
  const planName = props.planType === 1 ? 'Starter' : (props.planType === 2 ? 'Team' : (props.planType === 3 ? 'Company' : (props.planType === 4 && 'Premium')))
  const annual = props.annual
  const onHold = props.onHold || false
  const [loading, setLoading] = useState(false)
  const { data: offers, isLoading: offersLoading } = ChargifyOffer.loadAll()
  const planInfo = offers.list.find((offer) => (offer.offerType === planType))
  const setModalStage = (stage: number) => {
    props.setModalStage(stage)
  }
  const { primaryPayment, payments, refetchBilling } = useContext(BillingContext)
  const [lastDigits, setLastDigits] = useState(primaryPayment.attributes.masked_card_number.substring(primaryPayment.attributes.masked_card_number.length - 4))
  const [expirationDate, setExpirationDate] = useState(primaryPayment.attributes.expiration_month.toString() + '/' + primaryPayment.attributes.expiration_year.toString().substring(primaryPayment.attributes.expiration_year.toString().length - 2))
  const [nextPaymentDate, setNextPaymentDate] = useState(null)
  const [monthlyCost, setMonthlyCost] = useState(0)
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(primaryPayment)
  const [showProfileSelection, setShowProfileSelection] = useState(false)
  const [cardType, setCardType] = useState(selectedPaymentMethod.attributes.card_type)
  const [cardImage, setCardImage] = useState(ChipIcon)
  const [buttonLoading, setButtonLoading] = useState(false)
  const handleSelectPaymentMethodId = (paymentMethodId: any) => {
    const paymentUsed = payments?.find((p: any) => p.id === paymentMethodId)
    setSelectedPaymentMethod(paymentUsed)
    setShowProfileSelection(false)
  }

  const handlePurchase = () => {
    if (selectedPaymentMethod && planInfo) {
      setButtonLoading(true)
      purchaseOffer((annual ? planInfo.annualOfferID : planInfo.monthlyOfferID), selectedPaymentMethod.id)
        .then(() => {
          window.location.reload()
        })
    }
  }

  useEffect(() => {
    if (cardType in cardMapping) {
      const mapping = cardMapping[cardType]
      setCardType(mapping.name)
      setCardImage(mapping.img)
    }
    if (selectedPaymentMethod) {
      setLastDigits(selectedPaymentMethod.attributes.masked_card_number.substring(selectedPaymentMethod.attributes.masked_card_number.length - 4))
      setExpirationDate(selectedPaymentMethod.attributes.expiration_month + '/' + selectedPaymentMethod.attributes.expiration_year.substring(selectedPaymentMethod.attributes.expiration_year.length - 2))
    }
  }, [selectedPaymentMethod, cardType])

  useEffect(() => {
    setLoading(true)
    if (!offersLoading) {
      getOfferPreview((annual ? planInfo?.annualOfferID : planInfo?.monthlyOfferID), primaryPayment.id)
        .then(response => {
          setNextPaymentDate(response.data.attributes.next_billing_manifest.start_date.substring(0, 10))
          setMonthlyCost(Math.round(response.data.attributes.current_billing_manifest.total_in_cents / 1200))
          setLoading(false)
        })
    }
  }, [annual, planInfo, primaryPayment, offersLoading])

  return (
    <div className={classes.rowStyle}>
      <div style={{ paddingRight: '30px', width: '460px' }}>
        {onHold ? (
          <div style={{ display: 'flex', marginBottom: '10px', marginTop: '150px', justifyContent: 'center', textAlign: 'center' }}>
            <YellowLockIcon />
          </div>
        ) : (
          <div style={{ paddingTop: '150px' }} />
        )}
        <div className={classes.planTitle}>
          {onHold ? 'Your account is on hold' : planName + ' Plan'}
        </div>
        <div className={classes.messageText} style={{ marginBottom: '30px' }}>
          Confirm details below to {onHold ? 'reactivate' : 'sign up'}
        </div>
        <div className={classes.purpleBorder}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ color: '#975ce6', paddingLeft: '20px' }}>
              {planName} Plan
            </div>
            {planInfo && (
              <div style={{ paddingLeft: '20px', paddingTop: '5px', paddingBottom: '5px', fontSize: '30px', fontWeight: 'bold', display: 'flex', flexDirection: 'row' }}>
                {loading ? 'Loading...' : (
                  <>
                    ${monthlyCost}
                    <div style={{ fontSize: '14px', paddingTop: '15px' }}>
                      /month
                    </div>
                  </>
                )}
              </div>
            )}
            <div style={{ paddingLeft: '20px' }}>
              after tax
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', fontWeight: 'normal', fontSize: '20px', marginLeft: 'auto', marginRight: '20px' }}>
            <div style={{ marginBottom: '10px' }}>
              Next Payment
            </div>
            <div style={{ fontSize: '26px', fontWeight: 'bold' }}>
              {loading ? 'Loading...' : nextPaymentDate}
            </div>
          </div>
        </div>
        {showProfileSelection ? (
          <div style={{ marginTop: '20px' }}>
            <PaymentMethodSelector
              onSelectPaymentMethodId={handleSelectPaymentMethodId}
              onCloseModal={() => refetchBilling()}
              selectedPaymentMethodId={selectedPaymentMethod.id}
            />
          </div>
        ) : (
          <div className={classes.purpleBorder} style={{ marginTop: '20px' }} onClick={() => setShowProfileSelection(true)}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ color: '#975ce6', paddingLeft: '20px' }}>
                <CreditCardIcon style={{ paddingRight: '10px' }} />Payment Information
              </div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ marginTop: '10px', marginLeft: '20px' }}>
                  <img src={cardImage} alt='Credit Card Logo' height={53} style={{ borderRadius: 5 }} />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', fontSize: '20px', fontWeight: 'bold', marginLeft: '20px', marginTop: '15px' }}>
                  {cardType} ending in {lastDigits}
                  <div style={{ fontSize: '14px', color: '#00000080' }}>
                    Expires {expirationDate}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div style={{ marginTop: '30px' }}>
          <Fab
            className={onHold ? classes.reactivate : classes.buttonContent}
            color='primary'
            size='large'
            variant='extended'
            onClick={() => (!buttonLoading ? handlePurchase() : null)}
            disabled={buttonLoading}
          >
            {buttonLoading ? 'Please Wait...' : onHold ? 'Reactivate' : 'Confirm Plan'}
          </Fab>
        </div>
        <div style={{ paddingTop: '20px', paddingBottom: '180px' }}>
          <Button
            className={classes.seeOther}
            size='small'
            variant='outlined'
            onClick={() => { setModalStage(1) }}
            disabled={buttonLoading}
          >
            See other plans
          </Button>
        </div>
      </div>
      <PricingInformation planType={planType} annual={annual} curPlanType={props.curPlanType} />
    </div>
  )
}
