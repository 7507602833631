import { useContext, useState } from 'react'
import { SessionContext } from 'session-context'
import { TenantDataContext } from './tenant-data-context'
import { ConversationWindowContext } from './conversation-window-context'
import CustomizedSnackBar from 'components/CustomizedSnackbar'
import { soundAlert } from './chatAlerts'
import { usePostageSocket } from 'components/PostageSocket'
import RouteSnackBar from './RouteSnackBar'
import { claimRoute } from 'api/conversation_routes'

const routeClaimedState = {
  open: true,
  variant: 'error',
  message: 'The conversation has already been claimed'
}

export default function ChatAlertManager (props) {
  const [snackState, setSnackState] = useState({ open: false })
  const { user, participant } = useContext(SessionContext)
  const { agentDict } = useContext(TenantDataContext)
  const { pendingRoutes, refreshRoutes, selectConversation } = useContext(ConversationWindowContext)
  const chimeSettings = user.attributes.notification_settings?.chimes || {}
  const participantID = agentDict?.users[user.id]
  const activeConversationID = props.activeConversationID
  const participantAvailability = participant?.attributes?.status
  const available = participantAvailability === 'available'
  const chatServiceUrl = window.chatServiceUrl
  const [dismissed, setDismissed] = useState(false)

  function claimQuickDrawRoute (route) {
    claimRoute({ chatServiceUrl, route })
      .then(response => {
        selectConversation(response.data.attributes.conversation_id)
        refreshRoutes()
      }).catch(error => {
        console.log(error)
        refreshRoutes()
        setSnackState(routeClaimedState)
      })
  }

  usePostageSocket((msg) => {
    switch (msg.type) {
      case 'conversation_events':
        if (!chimeSettings.message) { break }
        if (msg.attributes.kind !== 'chat') { break }
        if (msg.relationships.from_participant.data.id === participantID) { break }
        if (msg.relationships.conversation.data.id !== activeConversationID || document.hidden) {
          soundAlert('message')
        }
        break
      case 'route':
        if (chimeSettings.conversation) {
          soundAlert('conversation')
        }
        break
      case 'quick_draw_claim':
        refreshRoutes()
        break
      case 'quick_draw':
        if (chimeSettings.conversation) {
          soundAlert('conversation')
        }
        refreshRoutes()
        break
      default:
        break
    }
  })

  return (
    <div>
      <RouteSnackBar
        open={pendingRoutes.length > 0 && available && !dismissed}
        route={pendingRoutes.length ? pendingRoutes[0].id : null}
        claimRoute={claimQuickDrawRoute}
        dismiss={() => setDismissed(true)}
      />
      <CustomizedSnackBar state={snackState} handler={setSnackState} />
    </div>
  )
}
