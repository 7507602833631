import { getStartingValues, getContentPageBotValues, getAbeGreetingStartingValues } from '../botSettings'
import { getBot, getFlow } from 'api/bots'
import { Bot } from 'classes/bots'

function getTabs (botID, botType) {
  const baseURL = botID ? `/bots/${botID}/` : '/bots/new/'
  let tabs = []

  if (botType === 'flow') {
    tabs = [
      { name: 'Builder', url: baseURL + 'builder' },
      { name: 'Settings', url: baseURL + 'settings' },
      { name: 'Performance', url: baseURL + 'performance' }
    ]
  } else if (botType === 'content_page') {
    tabs = [
      { name: 'Builder', url: baseURL + 'builder' },
      { name: 'Content', url: baseURL + 'content' },
      { name: 'Performance', url: baseURL + 'performance' }
    ]
  } else {
    tabs = [
      { name: 'Message', url: baseURL + 'message' }
    ]
  }

  return tabs
}

function getInitialValues ({ botType, bot, flow, botParticipant, contentPagesDict }) {
  let startingValues = {}

  if (botType === 'content_page') {
    startingValues = getContentPageBotValues({ bot, flow, botType, botParticipant, contentPagesDict })
  } else if (botType === 'abe_greeting') {
    startingValues = getAbeGreetingStartingValues({ bot, flow, botType, botParticipant })
  } else {
    startingValues = getStartingValues({ bot, flow, botType, botParticipant })
  }

  return startingValues
}

function initializeBotFromID ({ botID, chatServiceUrl, setBotType, setBot, setFlow, setFlowID }) {
  getBot({ botID }).then(botResponse => {
    window.showHighlights = false
    setBotType(botResponse.data.attributes.kind)
    setBot(botResponse.data)
    const id = botResponse.data.attributes.flow_id
    getFlow({ flowID: id, chatServiceUrl }).then(response => {
      setFlow(response.data)
      setFlowID(response.data.id)
    })
  })
}

function publishBot ({ flowID, bot, botID, flow, setFlowID, setFlow, setMaxActiveBotsWarnings, setBot }) {
  const id = parseInt(botID)
  if (flowID) {
    const enabled = bot.attributes.enabled
    if (flowID !== flow?.id) {
      getFlow({ flowID })
        .then(response => {
          setFlow(response.data)
          setFlowID(response.data.id)
          Bot.update(id, { enabled: !enabled })
          setBot({ ...bot, attributes: { ...bot.attributes, enabled: !enabled } })
        })
    } else {
      Bot.update(id, { enabled: !enabled })
      setBot({ ...bot, attributes: { ...bot.attributes, enabled: !enabled } })
    }
  }
}

export { getTabs, getInitialValues, initializeBotFromID, publishBot }
