import { makeStyles } from '@material-ui/core/styles';
import { Button } from 'library/materialUI/Button';
import { Icon } from 'library/materialUI/Icon';
import { doPost } from 'api/api';

const useStyles = makeStyles(theme => ({
  oktaBTN: {
    width: '100%',
    borderRadius: 20
  }
}))

interface OktaBtnProps {
  tenantId: number
}

export default function OktaBtn ({ tenantId }: OktaBtnProps) {
  const classes = useStyles()
  const oktaLogoIcon = <Icon icon='oktaLogo' imgStyle={{ height: 27, width: 30 }} />

  async function handleClick () {
    const resp = await doPost({ path: '/api/sso/okta_auth', data: { attributes: { tenantId } } })
    window.location.href = resp.url
  }

  return (
    <Button
      onClick={handleClick}
      variant='outlined'
      color='default'
      className={classes.oktaBTN}
      startIcon={oktaLogoIcon}
    >
      Sign in with OKTA
    </Button>
  )
}
