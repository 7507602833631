import { makeStyles } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'
import { ReactComponent as BotIcon } from 'img/botIcon.svg'
import { ReactComponent as formsIcon } from 'img/forms_white.svg'
import LiveChatIcon from '@material-ui/icons/HeadsetMic'
import { activities } from './ActivityHelpers'
import { Tooltip } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  summaryTable: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: 10,
    marginTop: 20
  },
  summaryRow: {
    display: 'flex',
    padding: 10,
    paddingTop: 0,
    marginRight: 20,
    margingLeft: 5,
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '15px',
    borderBottom: '1px solid #8e5ae2'
  },
  avatar: {
    height: 34,
    width: 34,
    marginRight: 8
  },
  icon: {
    height: 24,
    width: 24
  },
  title: {
    display: 'flex',
    alignItems: 'center'
  }
}))

export function SummaryRow (props) {
  const classes = useStyles()
  const activity = activities[props.type]
  const Icon = activity?.icon || LiveChatIcon
  const color = activity?.color

  const style = Icon === BotIcon ? {
    transform: 'scale(1.3)',
    fill: 'white'
  } : (Icon === formsIcon ? {
    transform: 'scale(0.8)'
  } : null)

  return (
    <div className={classes.summaryRow} style={{ borderBottom: props.hideBorder ? '0px solid black' : null }}>
      <div className={classes.title}>
        <Avatar
          className={classes.avatar}
          style={{ backgroundColor: color }}
        >
          <Icon className={classes.icon} style={style} />
        </Avatar>
        <div>
          {activity?.title}
        </div>
        {activity?.allTime && (
          <Tooltip title='Value not affected by time frame'>
            <div style={{ marginLeft: 5, fontSize: '16px', color: '#868686', cursor: 'default' }}>
              *
            </div>
          </Tooltip>
        )}
      </div>
      <div>
        {props.value}
      </div>
    </div>
  )
}

function ActivitySummary (props) {
  const classes = useStyles()
  const summaryData = props.summaryData
  const isContact = props.isContact

  return (
    <div className={classes.summaryTable}>
      <SummaryRow
        type='pageViews'
        value={summaryData?.page_views}
      />
      <SummaryRow
        type='siteVisits'
        value={summaryData?.site_visits}
      />
      {!isContact ? (
        <SummaryRow
          type='visitors'
          value={summaryData?.visitors}
        />
      ) : (
        <SummaryRow
          type='contactCreated'
          value={summaryData?.created_at || '---'}
        />
      )}
      <SummaryRow
        type='forms'
        value={summaryData?.forms_submitted}
      />
      <SummaryRow
        type='conversations'
        value={summaryData?.conversations}
      />
      <SummaryRow
        type='liveChats'
        value={summaryData?.live_chats}
      />
      <SummaryRow
        type='calendarDrops'
        value={summaryData?.calendar_drops}
      />
      <SummaryRow
        type='meetingsBooked'
        value={summaryData?.meetings_booked}
      />
      <SummaryRow
        type='firstSeen'
        hideBorder={!isContact}
        value={summaryData?.first_seen || '---'}
      />
      {isContact && (
        <SummaryRow
          type='firstSource'
          value={summaryData?.first_source || '---'}
        />
      )}
      <SummaryRow
        type='lastSeen'
        hideBorder
        value={summaryData?.last_seen || '---'}
      />
      {isContact && (
        <SummaryRow
          type='lastSource'
          hideBorder
          value={summaryData?.last_source || '---'}
        />
      )}
    </div>
  )
}

export default ActivitySummary
