/* eslint-disable @typescript-eslint/naming-convention */
import { useState, useEffect, useRef, useReducer } from 'react'
import { doGet } from 'api/api'
import { makeStyles } from '@material-ui/core/styles'
import { Modal, ModalSection } from 'library/Modal'
import { MenuItem, FormControl, InputLabel, Select, TextField } from '@material-ui/core'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { chatRoles } from './userListHelpers'
import * as Yup from 'yup'
import Picker from 'cf-components/Pickers'
import { UserInvite } from 'classes/userInvites'
import { getUsers } from 'api/users'
import AccessControl from 'components/AccessControl'
import { components } from 'session-context'
const useStyles = makeStyles(theme => ({
  textBox: {
    marginTop: 5,
    display: 'flex'
  },
  invalidInput: {
    color: 'red',
    fontSize: 12
  },
  errorDiv: {
    height: 15
  },
  formControl: {
    minWidth: 245
  },
  selectsDiv: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  warning: {
    padding: 20,
    backgroundColor: 'rgba(232, 167, 2, 0.5)'
  },
  preview: {
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingTop: '10px'
  },
  error: {
    padding: 20,
    backgroundColor: 'rgba(189, 51, 51, 0.2)'
  },
  teamSelect: {
    width: '100%'
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: -4
  },
  chip: {
    marginRight: 8,
    marginTop: 4
  }
}))

let userList: any[] = []
getUsers().then(response => {
  userList = response.data
})
const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Please enter a valid email address')
    .required('Email address required')
    .test('email-in-use', 'Email address already in use', function (value) {
      return !userList.some(user => user?.attributes?.email === value)
    }),
  role: Yup.string().required('Please select a role')
})

const adminRoles = [1, 2, 9]
const salesRoles = [3, 4, 5, 6, 7]
const calendarRole = [8]

const chatPermissions = [
  { key: 1, value: 'All chats', db_value: 'conversations_all' },
  { key: 2, value: 'Own chats only', db_value: 'conversations_mine' },
  {
    key: 3,
    value: 'Own chats and empty ones',
    db_value: 'conversations_unclaimed'
  }
]

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 5.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
}

interface FormSelectProps {
  field: any
  form: { touched: any, errors: any, setFieldValue: any, setFieldTouched: any }
  name: any
  label: any
  id: any
  disabled: boolean
  secondaryLabel: string
  values: { key: any, value: any }[]
}

function FormSelect ({
  field,
  form: { touched, errors, setFieldValue, setFieldTouched },
  name,
  label,
  id,
  disabled,
  secondaryLabel,
  values
}: FormSelectProps) {
  const classes = useStyles()

  const inputLabel = useRef<HTMLLabelElement | null>(null)
  const [labelWidth, setLabelWidth] = useState(0)
  useEffect(() => {
    if (inputLabel.current) {
      setLabelWidth(inputLabel.current.offsetWidth)
    }
  }, [])

  return (
    <FormControl
      variant='outlined'
      className={classes.formControl}
      margin='dense'
    >
      <InputLabel ref={inputLabel} htmlFor={name}>
        {label}
      </InputLabel>
      <Select
        {...field}
        labelWidth={labelWidth}
        inputProps={{
          name: name,
          id: id
        }}
        MenuProps={MenuProps}
        onChange={e => {
          setFieldValue(field.name, e.target.value)
          if (adminRoles.includes(e.target.value as number) && field.name === 'role') {
            setFieldValue('permissions', 1)
          } else if (calendarRole.includes(e.target.value as number) && field.name === 'role') {
            setFieldValue('permissions', 2)
          }
        }}
        onBlur={() => setFieldTouched(field.name)}
        error={errors[id] && touched[id]}
        disabled={disabled}
      >
        <MenuItem disabled value=''>
          {secondaryLabel == null ? label : secondaryLabel}
        </MenuItem>
        {values.map(item => (
          <MenuItem key={item.key} value={item.key}>
            {item.value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

const previewLicenseChange = (roleID: number, tenantID?: number) => {
  let path = `/api/v2/billing/licenses/preview/${roleID}`
  if (tenantID) {
    path = `/api/v2/billing/admin_portal/${tenantID}/licenses/preview/${roleID}`
  }
  return doGet({ path })
}

interface AddUserPreviewProps {
  roleID: number
  error: string
  tenantID?: number
}

function AddUserPreview ({ roleID, error, tenantID }: AddUserPreviewProps) {
  const classes = useStyles()
  const [preview, setPreview] = useState<{ data: { attributes: { allowed: boolean } } } | null>(null)
  let allowed = true

  useEffect(() => {
    if (roleID) {
      previewLicenseChange(roleID, tenantID).then((result) => {
        setPreview(result)
      })
    }
  }, [roleID, tenantID])

  if (error) {
    if (Array.isArray(error) && error.length > 0 && error[0].hasOwnProperty('title')) {
      return (
        <div className={classes.error}><b>{error[0].title}</b><br /><br />{error[0].detail}</div>
      )
    }
    return (
      <div className={classes.error}>There was an error while trying to send your invite, please try again. If it continues to happen, please reach out to our support team.</div>
    )
  }

  if (!preview) {
    return (
      <></>
    )
  }

  allowed = preview?.data?.attributes?.allowed
  if (!allowed) {
    return (
      <p className={classes.warning}>Your current billing subscription doesn't allow any more users of this type.<span style={{ color: 'black' }}> Try a different role or <a href='#/settings/subscriptions/plan'> click here.</a> to manage your licenses.</span></p>
    )
  }

  if (preview && allowed) {
    return (
      <></>
    )
  }
  return <></>
}

const reducer = (state: any, changes: any) => {
  state = changes
  return state
}

interface UserPermissionModalProps {
  close: () => void
  open: boolean
  tenantId?: number
  admin?: boolean
}

function UserPermissionsModal ({ close, open, tenantId, admin }: UserPermissionModalProps) {
  const classes = useStyles()
  const [error, setError] = useState('')
  const [state, dispatch] = useReducer(reducer, [])
  function closeModal () {
    close()
    setError('')
    dispatch([])
  }

  const createInvite = (values: any, resetForm: any) => {
    const selectedPerm = chatPermissions[values.permissions - 1]

    let permissions = {
      conversations_all: false,
      conversations_mine: true,
      conversations_unclaimed: false
    }

    if (selectedPerm.value === 'All chats') {
      permissions = {
        conversations_all: true,
        conversations_mine: true,
        conversations_unclaimed: true
      }
    } else {
      permissions[selectedPerm.db_value as keyof typeof permissions] = true
    }

    // If the user is an admin or a marketer, they should already have the permissions
    // There is no need to add extra permissions

    const key = values.role
    const invite = {
      email: values.email,
      role: values.role,
      permissions: !salesRoles.includes(key) ? {} : permissions,
      teamIDs: state
    }

    UserInvite.save(invite, tenantId).then(resp => {
      if (resp.id) {
        resetForm()
        closeModal()
      } else {
        setError(resp)
      }
    })
  }

  return (
    <Formik
      initialValues={{ email: '', role: '', permissions: '', team_ids: [] }}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm }) => createInvite(values, resetForm)}
      render={({
        values,
        handleBlur,
        handleChange,
        submitForm
      }) => (
        <Modal
          title='Invite New User'
          onHide={() => closeModal()}
          open={open}
          saveBtnText='Send Invite'
          handleSave={submitForm}
          saveIcon='send'
          closeBtnFunction={closeModal}
          saveDisabled={!values.email || !values.role || !values.permissions}
        >
          <Form autoComplete='off'>
            <ModalSection
              title='User Info'
              subtitle="Enter your teammate's email address and we'll send them a link to register on your account."
            >
              <TextField
                variant='outlined'
                margin='dense'
                label='Email'
                name='email'
                id='email'
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                className={classes.textBox}
              />
              <div className={classes.errorDiv}>
                <ErrorMessage
                  name='email'
                  component='div'
                  className={classes.invalidInput}
                />
              </div>
              <div className={classes.selectsDiv}>
                <div>
                  <Field
                    component={FormSelect}
                    id='role'
                    values={chatRoles}
                    name='role'
                    disabled={false}
                    label='Role'
                  />
                  <div className={classes.errorDiv}>
                    <ErrorMessage
                      name='role'
                      component='div'
                      className={classes.invalidInput}
                    />
                  </div>
                </div>
                <AccessControl requiredComponent={components.CHAT}>
                  <div>
                    <Field
                      component={FormSelect}
                      id='permissions'
                      values={chatPermissions}
                      name='permissions'
                      disabled={!salesRoles.includes(values.role)}
                      label='Chat Permissions'
                      secondaryLabel='User has access to: '
                    />
                    <div className={classes.errorDiv}>
                      <ErrorMessage
                        name='permissions'
                        component='div'
                        className={classes.invalidInput}
                      />
                    </div>
                  </div>
                </AccessControl>
              </div>
            </ModalSection>
            <AccessControl requiredComponent={components.PLAYMAKER}>
              <ModalSection
                title='Team Settings (Optional)'
                subtitle='Add this user to one or more teams.'
              >
                <div className={classes.teamSelect}>
                  {admin ? (
                    <Picker
                      selection={state}
                      setSelection={(value: any) => dispatch(value)}
                      objectType='portalTeams'
                      multiple
                      label='Team Selection'
                      loadAllProps={tenantId ? { searchParams: { extraHeaders: { 'x-tenant-id': tenantId.toString() } } } : undefined}
                    />
                  ) : (
                    <Picker
                      selection={state}
                      setSelection={(value: any) => dispatch(value)}
                      objectType='teams'
                      multiple
                      label='Team Selection'
                    />
                  )}
                  <div className={classes.errorDiv}>
                    <ErrorMessage
                      name='team_ids'
                      component='div'
                      className={classes.invalidInput}
                    />
                  </div>
                </div>
              </ModalSection>
            </AccessControl>
            <div>
              <AddUserPreview roleID={values.role} tenantID={tenantId} error={error} />
            </div>
          </Form>
        </Modal>
      )}
    />

  )
}

export default UserPermissionsModal
