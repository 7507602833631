import { useState, useReducer, useContext, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { SessionContext, components } from 'session-context'
import Checkbox from '@material-ui/core/Checkbox'
import SettingsCard from 'cf-components/SettingsCard'
import { Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { track } from 'userpilot.js'
import { saveUser } from 'api/users'
import { getAllNotifications } from 'api/notifications'
import Divider from '@material-ui/core/Divider'
import DateFormat from 'dateformat'
import Button from '@material-ui/core/Button'
import TextsmsIcon from '@material-ui/icons/Textsms'
import EmailIcon from '@material-ui/icons/Email'
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows'
import LogoGraytransparentBG from 'img/Logo_Gray_transparent_BG.png'
import { NotificationContext } from 'notification-context'
import { getMessagingService } from 'api/messaging_service'
import { useHistory, useLocation } from 'react-router-dom'
import ReminderModal from './ReminderModal'
// teams icon is a placeholder
import { getOnboardingData, setOnboardingData } from 'api/onboarding'
import { Icon } from 'library/materialUI/Icon'
import AccessControl from 'components/AccessControl'

const useStyles = makeStyles(theme => ({
  centeredCell: {
    textAlign: 'center'
  },
  flexCell: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  paper: {
    border: '1px solid rgba(142, 90, 226, 0.2)'
  },
  button: {
    marginTop: 15,
    marginLeft: 10,
    marginBottom: 15
  },
  eventTypeHeading: {
    textAlign: 'left',
    font: 'normal normal bold 12px/17px Poppins',
    letterSpacing: 0,
    color: '#985CE6',
    opacity: 1
  },
  notificationMessage: {
    display: 'flex',
    marginTop: 23,
    textAlign: 'left',
    font: 'normal normal normal 15px Poppins',
    letterSpacing: 0,
    color: '#2E2E2E',
    textDecoration: 'none',
    marginLeft: 10,
    fontSize: '.9em'
  },
  notificationCard: {
    minHeight: 70,
    textDecoration: 'none',
    display: 'grid',
    gridTemplateColumns: '0.5fr 1fr 10.5fr 6fr 6fr',
    gridGap: 5,
    cursor: 'pointer'
  },
  notificationsHeader: {
    display: 'grid',
    gridTemplateColumns: '2fr 1fr 1fr',
    gridGap: 5,
    marginLeft: 30
  },
  notificationBody: {
    fontSize: '.9em',
    color: '#707070',
    cursor: 'default',
    marginBottom: 12,
    '& *': {
      marginBlock: '0.35em'
    }
  },
  notificationIcon: {
    height: 22,
    marginTop: 24,
    margin: 'auto'
  },
  loadMoreDiv: {
    marginTop: 10,
    marginBottom: 10
  },
  loadMoreButton: {
    fontSize: '1em'
  },
  circle: {
    borderRadius: '50%',
    background: '#985CE6',
    width: 10,
    height: 10,
    marginTop: 30,
    margin: 'auto'
  },
  channelIcons: {
    height: 22,
    color: '#848484',
    marginRight: 15
  },
  eventIcons: {
    height: 18,
    color: '#848484',
    marginRight: 6
  },
  pngIcons: {
    filter: 'invert(56%) sepia(3%) saturate(12%) hue-rotate(20deg) brightness(93%) contrast(84%)'
  },
  createSummary: {
    color: theme.palette.primary.main,
    fontSize: 14,
    marginBottom: 20,
    '&:hover': {
      color: theme.palette.primary.light
    },
    cursor: 'pointer'
  },
  summaryHeader: {
    display: 'grid',
    gridTemplateColumns: '4fr 4fr 4fr 1fr',
    fontSize: 17
  },
  rowPadding: {
    padding: '20px 0px'
  }
}))

const initialState = {
  routes: { desktop: false, email: false, text: false, app: true, external: null },
  calendars: { desktop: false, email: false, text: false, app: true, external: null },
  meetings: { desktop: false, email: false, text: false, app: true, external: null },
  alerts: { desktop: false, email: false, text: false, app: true, external: null },
  notes: { desktop: true, email: false, text: false, app: true, external: null },
  bot_tests: { desktop: false, email: true, text: false, app: false, external: null },
  followees: { desktop: true, email: false, text: false, app: true, external: null },
  chimes: { message: true, conversation: true, notification: true },
  reports_daily_chat_summary: { email: false },
  reports_daily_abe_summary: { email: false },
  feature_announcement: { desktop: false, email: false, text: false, app: true, external: null }
}

const icons = {
  routes: 'route',
  meetings: 'dateRange',
  alerts: 'chatAlert',
  calendars: 'calendar',
  notes: 'note',
  followees: 'followee',
  slack: 'slack',
  teams: 'teams',
  feature_announcement: 'megaPhone'
}

function NotificationsCard (props) {
  const classes = useStyles()

  if (props.index === props.max) {
    return (<></>)
  }

  const message = props.row.attributes.title
  const body = props.row.attributes.body
  const type = props.row.attributes.notification_type
  const icon = icons[type] || 'chatAlert'
  const eDate = new Date(props.row.attributes.created_timestamp)
  const old = Date.now() - eDate.getTime() > 1000 * 3600 * 24 * 3
  const resolved = !!props.row.attributes.resolved_timestamp
  const dismissed = !!props.row.attributes.dismissed_timestamp
  const showAsNew = resolved || dismissed || old

  return (
    <div className={classes.notificationCard} onClick={() => props.handleClick(props.row)}>
      {showAsNew
        ? (
          <div className={classes.circle} style={{ background: 'transparent' }} />
        ) : (
          <div className={classes.circle} />
        )}
      <div className={classes.notificationIcon}>
        <Icon icon={icon} color='#c6adf1' />
      </div>
      <div className={classes.notificationMessage}>
        {message}
        {body?.length && type === 'feature_announcement' && (
          <div
            onClick={e => {
              e.stopPropagation()
            }}
            className={classes.notificationBody}
            dangerouslySetInnerHTML={{ __html: body }}
          />
        )}
        {body && type === 'meetings' && (
          <div className={classes.notificationBody}>
            {body}
          </div>
        )}
      </div>
      <div className={classes.notificationMessage}>{DateFormat(props.row.attributes.created_timestamp, 'mmm dd, yyyy h:MM TT')}</div>
      <div className={classes.notificationMessage}>
        {props.row.attributes.app && (<img className={classes.channelIcons} src={LogoGraytransparentBG} alt='Signals Icon' />)}
        {props.row.attributes.email && (<EmailIcon className={classes.channelIcons} />)}
        {props.row.attributes.sms && (<TextsmsIcon className={classes.channelIcons} />)}
        {props.row.attributes.external?.slack && (<div className={`${classes.channelIcons} ${classes.pngIcons}`}><Icon icon='slack' /></div>)}
        {props.row.attributes.external?.teams && (<div className={`${classes.channelIcons} ${classes.pngIcons}`}><Icon icon='teams' /></div>)}
      </div>
    </div>
  )
}

function Notifications (props) {
  const classes = useStyles()
  const userID = props.user.id
  const setDisabled = props.setDisabled
  const [allNotifications, setAllNotifications] = useState([])
  const [pageSize, setPageSize] = useState(50)
  const { isOn } = useContext(SessionContext)
  const { resolve } = useContext(NotificationContext)
  const location = useLocation()
  const showHistory = location.search.includes('history=1')
  const [externalMessages, setExternalMessages] = useState(false)
  const [renderInstructions, setRenderInstructions] = useState(false)
  const history = useHistory()
  const [messagingIntegrationName, setMessagingIntegrationName] = useState('external')

  let passedBool = false
  if (history.location.state?.from === 'Integrations') {
    passedBool = true
  }

  useEffect(() => {
    // Get Messaging Integration
    // Give us the name of the integration, if any
    getMessagingService()
      .then((response) => {
        if (response.ok) {
          setExternalMessages(response.data.attributes.enabled)
          const name = response.data?.attributes?.integration_name || ''
          if (name) {
            setMessagingIntegrationName(name)
          }
        }
      })
    if (history.location.state?.from === 'Integrations') {
      // setRenderInstructions(true)
    }

    // eslint-disable-next-line
  }, [])

  let startingValues = props.user.attributes.notification_settings
  if (Object.entries(startingValues).length === 0) {
    startingValues = initialState
  }

  const [state, dispatch] = useReducer(reducer, startingValues)

  function reducer (state, action) {
    if (action.action === 'initialize') {
      setDisabled(true)
    } else {
      setDisabled(false)
    }

    let checked
    if (action.type === 'external') {
      if (state[action.event].external) {
        checked = null
      } else {
        checked = messagingIntegrationName
      }
    } else {
      try {
        checked = !state[action.event][action.type]
      } catch (e) {
        // TODO: On first click state.chimes does not exist throwing an error
      }
    }

    switch (action.action) {
      case 'initialize':
        return action.info
      case 'update':
        if (!(action.event in state)) {
          state[action.event] = { desktop: false, email: false, text: false, app: false, external: null }
        }
        getOnboardingData('client_onboarding')
          .then(response => {
            if (response) {
              const data = JSON.parse(response.settings)
              data.configure_notifications = true
              setOnboardingData('client_onboarding', data)
            }
          }
          )
        return {
          ...state,
          [action.event]: { ...state[action.event], [action.type]: checked }
        }
      default:
        throw new Error()
    }
  }

  const notificationTypes = ['app', 'email', 'text']
  const headers = ['In-App', 'Email', 'Text']
  if (externalMessages) {
    notificationTypes.push('external')
    const uppercaseIntegrationName = messagingIntegrationName.charAt(0).toUpperCase() + messagingIntegrationName.slice(1)
    headers.push(uppercaseIntegrationName)
  }
  const colPercent = 100 / (headers.length + 1)
  const colWidth = []
  for (let i = 0; i <= headers.length; i++) {
    colWidth.push(`${colPercent}%`)
  }
  const events = []
  const eventHeaders = []
  if (isOn(components.PLAYMAKER)) {
    events.push(
      'play_alerts',
      'routes',
      'alerts',
      'notes',
      'form_submissions'
    )
    eventHeaders.push(
      'PlayMaker Alert',
      'Routed to Conversation',
      'Chat Alerts',
      'Notes',
      'Form Submissions'
    )
  }
  if (isOn(components.CALENDAR)) {
    events.push('calendars', 'meetings')
    eventHeaders.push('Calendar Drop', 'Meeting Booked')
  }
  events.push('followees', 'case_updates')
  eventHeaders.push('Followed Visitor Updates', 'Support Case Updates')

  useEffect(() => {
    getAllNotifications({ page: 1, pageSize: pageSize })
      .then(response => {
        setAllNotifications(response.data)
      })
  }, [pageSize])

  function saveChanges () {
    track('Notifications Updated')
    saveUser({ userID, state: { notification_settings: state } }).then(response => {
      props.setDisabled(true)
      props.reloadUser()
    })
  }

  props.saveNotificationsRef.current = saveChanges
  props.resetNotificationsRef.current = () => dispatch({ action: 'initialize', info: startingValues })

  function disabledCheck (event, notificationType) {
    switch (notificationType) {
      case 'text':
        return ['notes', 'followees', 'bot_tests', 'reports_daily_abe_summary', 'reports_daily_chat_summary'].includes(event)
      case 'app':
        return ['reports_daily_abe_summary', 'reports_daily_chat_summary'].includes(event)
      default:
        return false
    }
  }

  const handleClick = (row) => {
    if (row.attributes.resolved_timestamp === null) {
      resolve(row.id)
    }
    if (row.attributes.action_link && row.attributes.action_link.length) {
      window.open(row.attributes.action_link, '_self')
    }
  }

  const handleClose = () => {
    setRenderInstructions(false)
  }

  return (
    <div>
      <SettingsCard
        title='Chime Alerts'
        subtitle='Choose your settings for audio notifications in the Signals application.'
      >
        <AccessControl requiredComponent={components.CHAT}>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.chimes?.conversation}
                  onChange={() => dispatch({ event: 'chimes', type: 'conversation', action: 'update' })}
                  color='primary'
                />
              }
              label='New conversations'
            />
          </div>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.chimes?.message}
                  onChange={() => dispatch({ event: 'chimes', type: 'message', action: 'update' })}
                  color='primary'
                />
              }
              label='New messages in your conversations'
            />
          </div>
        </AccessControl>
        <div>
          <FormControlLabel
            control={
              <Checkbox
                checked={state.chimes?.notification}
                onChange={() => dispatch({ event: 'chimes', type: 'notification', action: 'update' })}
                color='primary'
              />
            }
            label='In-app notifications'
          />
        </div>
      </SettingsCard>
      <SettingsCard title='Event Notifications' expanded={passedBool}>
        <Table>
          <colgroup>
            {colWidth.map((width, idx) => (
              <col key={idx} style={{ width: `${width}` }} />
            ))}
          </colgroup>
          <TableHead>
            <TableRow style={{ backgroundColor: '#F7F7F7' }}>
              <TableCell className={classes.eventTypeHeading}>
                Event Type
              </TableCell>
              {headers.map(header => (
                <TableCell key={header} className={classes.centeredCell}>
                  <div className={classes.flexCell}>
                    {header === 'In-App'
                      ? <img className={classes.eventIcons} src={LogoGraytransparentBG} alt='Logo' /> : header === 'Desktop'
                        ? <DesktopWindowsIcon className={classes.eventIcons} /> : header === 'Email'
                          ? <EmailIcon className={classes.eventIcons} /> : header === 'Text'
                            ? <TextsmsIcon className={classes.eventIcons} /> : header === 'Slack'
                              ? <div className={`${classes.eventIcons} ${classes.pngIcons}`}><Icon icon='slack' /></div> : header === 'Teams'
                                ? <div className={`${classes.eventIcons} ${classes.pngIcons}`}><Icon icon='teams' /></div> : <></>}
                    {header}
                  </div>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {events.map((event, index) => (
              <TableRow key={event}>
                <TableCell>{eventHeaders[index]}</TableCell>
                {notificationTypes.map(n => (
                  <TableCell key={n + ' ' + event} className={classes.centeredCell}>
                    <Checkbox
                      color='primary'
                      checked={Boolean(state[event]?.[n]) || false}
                      onChange={() => dispatch({ event: event, type: n, action: 'update' })}
                      disabled={disabledCheck(event, n)}
                    />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </SettingsCard>
      <SettingsCard
        title='Notification History'
        subtitle='View all of your past notifications.'
        expanded={showHistory}
      >
        <div>
          <div className={classes.notificationsHeader}>
            <div>
              NOTIFICATION
            </div>
            <div>
              SENT
            </div>
            <div>
              CHANNEL
            </div>
          </div>
          {allNotifications?.sort((a, b) => a.attributes.created_timestamp < b.attributes.created_timestamp ? 1 : -1).map((row, index) => (
            <div key={row.id}>
              <NotificationsCard
                index={index}
                row={row}
                allNotifications={allNotifications}
                max={allNotifications.length}
                handleClick={handleClick}
              />
              <Divider className={classes.divider} />
            </div>
          ))}
        </div>
        <div className={classes.loadMoreDiv}>
          {allNotifications.length > pageSize && pageSize < 200 ? (
            <Button
              fullWidth
              color='primary'
              className={classes.loadMoreButton}
              onClick={() => {
                if (pageSize === 50) {
                  setPageSize(pageSize + 50)
                } else if (pageSize === 100) {
                  setPageSize(pageSize + 100)
                }
              }}
            >
              Load More
            </Button>
          ) : (<></>)}
        </div>
      </SettingsCard>
      {renderInstructions &&
        <ReminderModal
          className={classes.reminder}
          name={messagingIntegrationName}
          render={handleClose}
        />}
    </div>
  )
}

export default Notifications
