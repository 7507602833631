import { Popover } from '@material-ui/core'
import { CSSProperties, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { ChromePicker } from 'react-color'

const useStyles = makeStyles(theme => ({
  colorEditButton: {
    cursor: 'pointer',
    border: '1px solid',
    borderRadius: 5,
    padding: 5,
    display: 'flex'
  },
  colorDisplay: {
    borderRadius: 5,
    padding: 5,
    display: 'flex'
  }
}))

export interface ColorPopOverProps {
  value: string
  onChange: (color: string) => void
  additionalInfoEdit: boolean
  style?: CSSProperties
  borderColor?: string
  placeholder?: string
}

export default function ColorPopOver (props: any) {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const value = props.value
  const additionalInfoEdit = props.additionalInfoEdit

  const handleClick = (event: any) => {
    if (additionalInfoEdit) {
      setAnchorEl(event.currentTarget)
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  return (
    <div style={props.style ? props.style : { width: '100%' }}>
      <div style={{ borderColor: props.borderColor ? props.borderColor : '#000000' }} className={additionalInfoEdit ? classes.colorEditButton : classes.colorDisplay} onClick={handleClick}>
        <div style={{ height: 20, width: 20, backgroundColor: value, marginRight: 10, border: '1px solid', borderRadius: 4, borderColor: props.borderColor ? props.borderColor : '#000000' }} />
        <div>
          {props.placeholder ? props.placeholder : value}
        </div>
      </div>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <ChromePicker disableAlpha color={value} onChange={(color: any) => props.onChange(color.hex)} />
      </Popover>
    </div>
  )
}
