import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  time: {
    fontSize: '.7em',
    color: '#7C7C7C'
  }
}))

function getDisplayColor (msgTime, read, status) {
  const now = new Date()
  const diffMs = now - new Date(msgTime)
  const minutes = diffMs / 60000

  let color = '#7C7C7C'

  if (minutes < 0.5) {
    color = '#EF8849'
  } else if (minutes > 0.5 && !read && status === 'open') {
    color = '#FF5D5C'
  }

  return color
}

function TimeSinceMessage (props) {
  const classes = useStyles()

  const [update, setUpdate] = useState(false)

  const now = new Date()
  const diffMs = now - new Date(props.msgTime)
  const minutes = diffMs / 60000
  const hours = minutes / 60
  const days = hours / 24
  const weeks = days / 7
  const months = days / 30
  const years = days / 365

  let displayTime = ''

  if (minutes < 0.5) {
    displayTime = 'Now'
  } else if (minutes < 60) {
    displayTime = Math.round(minutes) + ' min'
  } else if (days < 1) {
    const hrs = Math.round(hours)
    displayTime = hrs === 1 ? '1 hr' : hrs + ' hrs'
  } else if (weeks < 1) {
    const dys = Math.round(days)
    displayTime = dys === 1 ? '1 day' : dys + ' days'
  } else if (months < 1) {
    const wks = Math.round(weeks)
    displayTime = wks === 1 ? '1 week' : wks + ' wks'
  } else if (years < 1) {
    const mon = Math.round(months)
    displayTime = mon + ' mon'
  } else {
    const yrs = Math.round(years)
    displayTime = yrs === 1 ? '1 year' : yrs + ' yrs'
  }

  setTimeout(() => {
    setUpdate(!update)
  }, 60000)

  const color = props.color
  let fontWeight = 500
  if (props.color !== '#7C7C7C') {
    fontWeight = 600
  }

  return (
    <div className={classes.time} style={{ color: color, fontWeight: fontWeight }}>
      {displayTime}
    </div>
  )
}

export { TimeSinceMessage, getDisplayColor }
