import { makeStyles } from '@material-ui/core/styles'
import parse from 'html-react-parser'
import { ChatFunnelsAvatar } from 'cf-components/Avatars'
import Form from 'classes/forms'
import User from 'classes/users'
import { Icon } from 'library/materialUI'

const useStyles = makeStyles(theme => ({
  container: {
    margin: '20px',
    padding: '20px',
    backgroundColor: '#FFFFFF',
    borderRadius: '10px',
    boxShadow: '0px 6px 12px #00000029',
    width: 447,
    maxHeight: 220,
    marginTop: 'auto',
    marginBottom: 'auto',
    alignSelf: 'center'
  },
  title: {
    fontFamily: 'Arial',
    fontSize: '25px',
    fontWeight: 'bold',
    marginTop: '10px',
    marginLeft: '10px',
    display: 'flex',
    flexDirection: 'row',
    '& .ql-align-center': {
      textAlign: 'center'
    },
    '& .ql-align-left': {
      textAlign: 'left'
    },
    '& .ql-align-right': {
      textAlign: 'right'
    }
  },
  text: {
    fontFamily: 'Arial',
    fontSize: '16px',
    marginTop: '10px',
    marginLeft: '12px',
    '& p': {
      margin: 0
    },
    '& .ql-align-center': {
      textAlign: 'center'
    },
    '& .ql-align-left': {
      textAlign: 'left'
    },
    '& .ql-align-right': {
      textAlign: 'right'
    }
  },
  buttonContainer: {
    fontFamily: 'Arial',
    letterSpacing: '0.2px',
    display: 'flex',
    flexDirection: 'row',
    margin: '10px',
    marginTop: '20px'
  },
  buttonStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '34px',
    color: '#FFFFFF',
    fontSize: '17px',
    margin: '2px',
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: '5px',
    cursor: 'pointer',
    width: '100%',
    fontFamily: 'Arial',
    fontWeight: 'bold'
  }
}))

interface FollowUpPreviewProps {
  form: Form
  showOfflinePrompt: boolean
}

export default function FollowUpPreview ({ form, showOfflinePrompt }: FollowUpPreviewProps) {
  const classes = useStyles()
  const { data: users } = User.loadAll({})
  const finalUserList = form.agentProfiles ? users.list.filter((user: User) => form.agentProfiles.includes(user.id) && user.avatarUrl) : []
  const promptMessage = form.promptMessage
  const offlineMessage = form.offlineMessage

  if (showOfflinePrompt && !form.offlineBookMeeting) {
    return (
      <div>
        [No prompt will be shown when chat agents are offline]
      </div>
    )
  }
  const previewMessage = showOfflinePrompt ? offlineMessage : promptMessage || 'A member of our team is ready to chat with you now, or you can book a meeting for later. We’d love to show you what our products can do!'
  return (
    <div className={classes.container}>
      <div className={classes.title}>
        <div style={{ width: '95%', fontFamily: 'arial' }}>
          {showOfflinePrompt ? form.offlineHeader : form.promptHeader || 'Ready to talk now?'}
        </div>
        {form.showAgentProfiles && finalUserList.map((user, index) => {
          return (
            <div key={index} style={{ position: 'relative', left: '-10px', marginRight: '-5px', zIndex: 10 - index, border: '2px solid #FFFFFF', borderRadius: '100%' }}>
              <ChatFunnelsAvatar
                user={user}
                size='xs'
              />
            </div>
          )
        })}
        <div style={{ marginLeft: 'auto', marginRight: '2px' }}>
          <Icon icon='close' size='sm' />
        </div>
      </div>
      <div className={classes.text}>
        {parse(previewMessage)}
      </div>
      <div className={classes.buttonContainer}>
        {!showOfflinePrompt && form.liveChat &&
          <div className={classes.buttonStyle} style={{ backgroundColor: form.buttonColor || '#9933FF' }}>
            {form.liveChatButtonText}
          </div>}
        {!showOfflinePrompt && form.bookMeeting &&
          <div className={classes.buttonStyle} style={{ backgroundColor: form.buttonColor || '#9933FF' }}>
            {form.bookMeetingButtonText}
          </div>}
        {showOfflinePrompt &&
          <div className={classes.buttonStyle} style={{ backgroundColor: form.buttonColor || '#9933FF' }}>
            {form.offlineMeetingButtonText}
          </div>}
      </div>
    </div>
  )
}
