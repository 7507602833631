import { useContext, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import dateFormat from 'dateformat'
import sanitizeHTML from 'sanitize-html'
import { getConversationList } from 'api/conversations'
import BaseCard from './BaseCard'
import EmptyState from 'cf-components/EmptyState'
import { Avatar } from 'cf-components/Avatars'
import { ScrollBox } from 'cf-components/ScrollBox'
import { ConversationContext } from '../../ConversationContext'

const useStyles = makeStyles(theme => ({
  listCtn: {
    maxHeight: 60 * 4
  },
  conversationRow: {
    padding: '5px 2px',
    display: 'flex',
    flexFlow: 'row nowrap',
    fontSize: 12,
    cursor: 'pointer',
    marginBottom: 0.5,
    marginTop: 0.5,
    transition: theme.transitions.create('background-color', {
      duration: theme.transitions.duration.shortest
    }),
    '&:last-child': {
      marginBottom: 0
    },
    '&:first-child': {
      marginTop: 0
    },
    '&:hover': {
      backgroundColor: '#d5d5d5'
    }
  },
  avatarCtn: {
    margin: '4px 4px 0 0'
  },
  rightCtn: {
    flexGrow: 1,
    overflow: 'hidden'
  },
  topRow: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  lastMessageName: {
    color: '#985CE6',
    fontWeight: 'bold'
  },
  time: {
    color: '#454545',
    fontSize: 8
  },
  lastMessage: {
    margin: 3,
    marginLeft: 0,
    '& *': {
      padding: 0,
      margin: 0
    }
  }
}))

/**
 * @typedef {import('../../ActiveConversation').ConversationContextData} ConversationContextData
 */
/**
 * @param {Pick<ConversationContextData, 'conversation'|'selectConversation'> & {
 * bot;
 * }} props
 */
function ConversationRow (props) {
  const classes = useStyles()
  const { conversation, selectConversation, bot } = props
  const convAttrs = conversation.attributes
  const dateTxt = dateFormat(convAttrs.last_message_timestamp, 'mmm d - h:mm tt')
  const openConversation = e => {
    if (e.ctrlKey) {
      window.open('#/chat/' + conversation.id, '_blank')
    } else {
      selectConversation(conversation.id)
    }
  }

  let avatarParticipantUrl = ''
  let avatarParticipantID = convAttrs.last_message_participant_id
  if (avatarParticipantID === conversation.relationships.for_participant.data.id) {
    avatarParticipantUrl = convAttrs.contact_avatar
    if (conversation.relationships.for_contact.data.id) {
      avatarParticipantID = conversation.relationships.for_contact.data.id
    }
  } else if (convAttrs.last_message_participant_kind === 'bot') {
    avatarParticipantUrl = bot.avatar_url || ''
  }

  return (
    <div className={classes.conversationRow} onClick={openConversation}>
      <div className={classes.avatarCtn}>
        <Avatar
          participantID={avatarParticipantID}
          size='sm'
          avatarUrl={avatarParticipantUrl}
        />
      </div>
      <div className={classes.rightCtn}>
        <div className={classes.topRow}>
          <div className={classes.lastMessageName}>{convAttrs.last_message_name || convAttrs.name}</div>
          <div className={classes.time}>{dateTxt}</div>
        </div>
        <div
          className={classes.lastMessage}
          dangerouslySetInnerHTML={{
            __html: sanitizeHTML(
              convAttrs.last_message,
              {
                allowedSchemes: ['http', 'https'],
                allowedSchemesByTag: {
                  img: ['data']
                },
                allowedTags: sanitizeHTML.defaults.allowedTags.concat(['img', 'h1', 'h2', 'h3', 'u', 's'])
              }
            )
          }}
        />
      </div>
    </div>
  )
}

export default function RecentConversations (props) {
  const classes = useStyles()
  const { conversation, selectConversation, participants } = useContext(ConversationContext)
  const conversationID = conversation?.id
  const participantID = conversation?.relationships?.for_participant?.data.id
  const contactID = conversation?.relationships?.for_contact?.data.id
  const currentBot = Object.values(participants).find(participant => participant.kind === 'bot') || {}
  const [recentConversations, setRecentConversations] = useState([])

  useEffect(() => {
    /** @type {Parameters<getConversationList>[0]['conversationFilters']} */
    const conversationFilters = { kind: 'search', values: {} }

    if (contactID) {
      conversationFilters.values = { filter: { contact_id: contactID } }
    } else {
      conversationFilters.values = { filter: { participant_id: participantID } }
    }

    getConversationList({ conversationFilters, limit: 10 })
      // Filter out the current convo
      .then(res => res.data.filter(conv => conv.id !== conversationID))
      .then(conversations => setRecentConversations(conversations))
  }, [conversationID, participantID, contactID])

  return (
    <BaseCard
      title='Recent Conversations'
      id={props.id}
    >
      {recentConversations?.length ? (
        <ScrollBox className={classes.listCtn}>
          {recentConversations.map(conversation => (
            <ConversationRow
              key={conversation.id}
              conversation={conversation}
              selectConversation={selectConversation}
              bot={currentBot}
            />
          ))}
        </ScrollBox>
      ) : (
        <EmptyState
          sad
          message='No Other Conversations'
        />
      )}
    </BaseCard>
  )
}
