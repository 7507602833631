import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import CustomizedSnackbar from '../../../components/CustomizedSnackbar'
import SixsenseLogo from '../../../img/sixsense_banner.jpeg'
import {
  getSixsense,
  updateSixsense
} from '../../../api/sixsense'
import { INTEGRATIONS } from '../../../api/integrations'
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined'
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined'
import { Chip, Switch } from '@material-ui/core'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import { Link } from 'react-router-dom'

const useStyles = makeStyles({
  headerImage: {
    height: 65,
    marginBottom: 30
  },
  disabledHeaderImage: {
    height: 65,
    marginBottom: 30,
    filter: 'grayscale(100%)'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  manualToggle: {
    marginRight: 40,
    marginTop: 20
  },
  wrapIcon: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 15
  },
  container: {
    padding: 65,
    minWidth: 920
  },
  bodyText: {
    marginBottom: 25,
    fontSize: 16
  },
  subtitle1: {
    fontSize: 18,
    fontWeight: 600,
    marginBottom: 15
  },
  subtitle2: {
    fontSize: 15,
    fontWeight: 600,
    marginBottom: 10
  },
  groupContainer: {
    backgroundColor: 'rgba(193, 166, 237, 0.1)',
    padding: 30,
    borderRadius: 10,
    marginBottom: 20
  },
  apiKeyField: {
    display: 'flex'
  },
  textField: {
    width: '90%',
    marginRight: 10
  },
  visibilityButton: {
    height: 56,
    marginTop: -1
  },
  crumb: {
    fontSize: '.8em'
  },
  typography: {
    fontSize: '1.05em'
  },
  breadcrumbs: {
    position: 'absolute',
    top: 5
  },
  crumbWrapper: {
    padding: 10
  }

})

const initialValues = {
  type: 'integrations',
  attributes: {
    integration_settings: {
      integration_name: INTEGRATIONS.Sixsense,
      enabled: false,
      config: {},
      mappings: {}
    },
    external_keys: {
      api_key: ''
    }
  }
}

const SixsenseIntegrationPage = (props) => {
  const classes = useStyles()

  const [snackState, setSnackState] = useState({
    open: false,
    variant: 'success',
    message: 'Your changes have been saved'
  })
  const [showAPIKey, setShowAPIKey] = useState(false)
  const [integrationSettings, setIntegrationSettings] = useState(initialValues)
  const [loaded, setLoaded] = useState(false)
  const save = () => {
    updateSixsense({ integration: integrationSettings })
      .then((r) => {
        setSnackState({
          open: true,
          variant: 'success',
          message: 'Your changes have been saved'
        })
      })
  }

  const toggleIntegration = (checked) => {
    integrationSettings.attributes.integration_settings.enabled = checked
    save()
  }

  const handleEdit = ({ type, data }) => {
    setIntegrationSettings({
      ...integrationSettings,
      attributes: {
        ...integrationSettings.attributes,
        external_keys: {
          ...integrationSettings.attributes.external_keys,
          [type]: data
        }
      }
    })
  }

  useEffect(() => {
    if (loaded === false) {
      getSixsense()
        .then((response) => {
          if (response.ok) {
            setLoaded(true)
            setIntegrationSettings(response.data)
          }
          // else {
          //   updateClearbit({ integration: initialValues })
          //     .then((response) => response.json())
          //     .then((response) => {
          //       setLoaded(true)
          //       setIntegrationSettings(response.data)
          //     })
          // }
        })
    }
  }, [loaded])

  return (
    <>
      <Breadcrumbs aria-label='breadcrumb' classes={{ li: classes.crumb, ol: classes.crumbWrapper }}>
        <Link style={{ color: '#7A7A7A' }} to='/settings/integrations'>
          Integrations Page
        </Link>
        <Typography color='textPrimary' classes={{ root: classes.typography }}>6sense Integration Page</Typography>
      </Breadcrumbs>
      <div className={classes.container}>
        <div className={classes.header}>
          <img
            src={SixsenseLogo}
            className={integrationSettings.attributes.integration_settings.enabled ? classes.headerImage : classes.disabledHeaderImage}
            alt='Sixsense Logo'
          />
          {integrationSettings ? (
            <div className={classes.manualToggle}>
              <Chip
                label={
                  integrationSettings.attributes.integration_settings.enabled
                    ? 'Integration: On'
                    : 'Integration: Off'
                }
                color={
                  integrationSettings.attributes.integration_settings.enabled
                    ? 'primary'
                    : 'default'
                }
                className={classes.chip}
              />
              <Switch
                checked={
                  integrationSettings.attributes.integration_settings.enabled
                }
                onChange={(e) => toggleIntegration(e.target.checked)}
                name='master_toggle'
                className={classes.switch}
              />
            </div>
          ) : (
            <></>
          )}
        </div>
        <Typography variant='body2' className={classes.bodyText}>
          Integrate your Signals account with 6sense to enrich your data
          and power your business!
        </Typography>
        <div className={classes.groupContainer}>
          <Typography variant='subtitle1' className={classes.subtitle1}>
            API
          </Typography>
          <Typography variant='body2' className={classes.bodyText}>
            Access all of 6sense discovery capabilities using your API Key
          </Typography>
          <Typography variant='subtitle2' className={classes.subtitle2}>
            Your key:
          </Typography>
          <div style={{ marginBottom: 20 }} className={classes.apiKeyField}>
            <TextField
              className={classes.textField}
              color='primary'
              variant='outlined'
              type={showAPIKey ? 'text' : 'password'}
              value={integrationSettings.attributes.external_keys.api_key}
              onChange={(e) => {
                handleEdit({ type: 'api_key', data: e.target.value })
              }}
            />
            {showAPIKey ? (
              <Button
                color='primary'
                variant='outlined'
                startIcon={<VisibilityOffOutlinedIcon />}
                className={classes.visibilityButton}
                onClick={() => setShowAPIKey(false)}
              >
                Hide
              </Button>
            ) : (
              <Button
                color='primary'
                variant='outlined'
                startIcon={<VisibilityOutlinedIcon />}
                className={classes.visibilityButton}
                onClick={() => setShowAPIKey(true)}
              >
                Show
              </Button>
            )}
          </div>
        </div>
        <Button
          className={classes.actionButton}
          color='primary'
          variant='contained'
          onClick={save}
        >
          Save
        </Button>
      </div>
      <CustomizedSnackbar state={snackState} handler={setSnackState} />
    </>
  )
}

export default SixsenseIntegrationPage
