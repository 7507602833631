import { track } from 'userpilot.js'
import { doGet } from './api'

const headers = {
  'Content-Type': 'application/vnd.api+json'
}

function saveFAQ ({ name, questions, response, tags, keywords, excluded, id, chatServiceUrl }) {
  let url = `https://${chatServiceUrl}/faqs`

  let method = 'POST'
  if (id) {
    method = 'PATCH'
    url = new URL(`https://${chatServiceUrl}/faqs/` + id)
  }

  return fetch(url, {
    method: method,
    headers: headers,
    body: JSON.stringify({
      data: {
        type: 'faqs',
        attributes: {
          name: name,
          questions: questions,
          response: response,
          tags: tags,
          keywords: keywords,
          excluded_keywords: excluded
        }
      }
    })
  })
    .then(response => {
      track('FAQ Created')
      return response.json()
    })
}

function getFAQ ({ faq_id }) {
  return doGet({
    path: `/faqs/${faq_id}`,
    useChatURL: true
  })
}

function faqSearch ({ search, chatServiceUrl }) {
  const url = new URL(`https://${chatServiceUrl}/faqs:search`)
  const params = {}
  if (search) {
    params['filter[search]'] = search
  }

  Object.keys(params).forEach(key =>
    url.searchParams.append(key, params[key])
  )

  return fetch(url, {
    method: 'GET',
    headers: headers
  })
    .then(response => response.json())
}

function deleteFAQs ({ chatServiceUrl, faq_ids }) {
  const method = 'DELETE'
  const url = new URL(`https://${chatServiceUrl}/faqs`)
  const body = JSON.stringify({
    data: {
      attributes: {
        faq_ids: faq_ids
      }
    }
  })
  return fetch(url, {
    method: method,
    headers: headers,
    body: body
  })
}

export { saveFAQ, faqSearch, deleteFAQs, getFAQ }
