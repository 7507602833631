import dateFormat from 'dateformat'
interface FormattedDateProps {
  dateToFormat: string
  format?: string
}

function FormattedDate ({ dateToFormat, format }: FormattedDateProps) {
  if (!dateToFormat) {
    return ''
  }
  const formattedTime = format
    ? dateFormat(dateToFormat, format)
    : dateFormat(dateToFormat, 'mmm dd, yyyy  h:MM TT')

  return formattedTime
}

export default FormattedDate
