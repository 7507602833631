import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
    width: 80,
    height: 35,
    cursor: 'pointer',
    '&:hover': {
        backgroundColor: '#EAE7EF'
    }
  },
  buttonText: {
    color: '#9933FF',
    fontSize: 12
  }
}))

type TextOnlyButtonProps = {
  text: string
  onClick?: () => void
}

export function TextOnlyButton (props: TextOnlyButtonProps) {
  const classes = useStyles()

  return (
    <div className={classes.buttonContainer} onClick={props.onClick}>
      <div className={classes.buttonText}>
        {props.text}
      </div>
    </div>
  )
}
