import { RadioGroup } from '@material-ui/core'
import { Radio } from './materialUI'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  container: {
    borderRadius: 5,
    padding: 20,
    marginTop: 10,
    marginBottom: 10
  },
  selected: {
    border: '1px solid #9933FF'
  },
  unselected: {
    border: '1px solid #D3D3D3'
  },
  contentContainer: {
    paddingLeft: 10
  }
}))

interface ContentRadioProps {
  label: string
  value: string
  checked: boolean
  children: JSX.Element
  disabled?: boolean
  handleChange?: (value: string) => void
  alwaysShow?: boolean
}

function ContentRadio ({ label, value, handleChange, checked, disabled, children, alwaysShow }: ContentRadioProps): JSX.Element {
  const classes = useStyles()

  return (
    <div
      className={clsx({
        [classes.container]: true,
        [classes.selected]: checked,
        [classes.unselected]: !checked
      })}
    >
      <Radio
        checked={checked}
        label={label}
        value={value}
        disabled={disabled}
        handleChange={handleChange}
      />
      <div className={classes.contentContainer}>{(checked || alwaysShow) && children}</div>
    </div>
  )
}

interface ContentRadioGroupProps {
  children: typeof ContentRadio[]
  value: string
  onChange: (value: string) => void
}

function ContentRadioGroup ({ value, children, onChange }: ContentRadioGroupProps): JSX.Element {
  return (
    <RadioGroup
      value={value}
      onChange={(e) => onChange(e.target.value)}
    >
      {children}
    </RadioGroup>
  )
}

export { ContentRadio, ContentRadioGroup }
