import Form from 'classes/forms'
import FormEditor from './FormEditor'

export default function FormPage (props: any) {
  const formID = props.match.params.form_id
  const { data: formData, isLoading } = Form.loadOne(formID)

  if (isLoading) {
    return (
      <div />
    )
  }

  return (
    <FormEditor formData={formData} history={props.history} id={formID} />
  )
}
