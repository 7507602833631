import { TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { getCountries, getTerritories } from 'api/locations'
import Loader from 'library/loading/Loader'
import { useEffect, useState } from 'react'

interface LocationSelectorProps {
  setCountryValue: (value: string) => void
  setTerritoryValue: (value: string) => void
}

const countriesWithTerritories = ['United States', 'Canada']

export default function CountryStateSelector ({ setCountryValue, setTerritoryValue }: LocationSelectorProps): JSX.Element {
  const [country, setCountry] = useState<string>('')
  const [countries, setCountries] = useState<string[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    if (isLoading) {
      getCountries().then((response: any) => {
        setCountries(response.data)
        setIsLoading(false)
      })
    }
  }, [isLoading])
  if (isLoading) return <Loader type='dropdown' />
  return (
    <div style={{ display: 'flex' }}>
      <Autocomplete
        style={{ marginRight: 10, width: 200 }}
        value={country}
        options={countries}
        onChange={(e, value) => {
          if (value === null) value = ''
          setCountry(value)
          setCountryValue(value)
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label='Country'
            variant='outlined'
          />
        )}
      />
      {countriesWithTerritories.includes(country) &&
        <TerritorySelector
          setValue={setTerritoryValue}
          country={country}
        />}
    </div>
  )
}

interface TerritorySelectorProps {
  setValue: (value: string) => void
  country: string
}

function TerritorySelector ({ setValue, country }: TerritorySelectorProps): JSX.Element {
  const [territory, setTerritory] = useState<string>('')
  const [territories, setTerritories] = useState<string[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  useEffect(() => {
    getTerritories(country).then((response: any) => {
      setTerritories(response.data)
      setIsLoading(false)
    })
  }, [country])
  if (isLoading) return <Loader type='dropdown' />
  return (
    <Autocomplete
      style={{ width: 200 }}
      value={territory}
      options={territories}
      onChange={(e, value) => {
        if (value === null) value = ''
          setTerritory(value)
          setValue(value)
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label='State/Province'
          variant='outlined'
        />
      )}
    />
  )
}
