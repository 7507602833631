import React, { useEffect, useState } from 'react'
import { getInvoices, downloadInvoice } from 'api/billing'
import { makeStyles } from '@material-ui/core/styles'
import Table from 'cf-components/Table'
import { ListItemCard } from 'cf-components/CardList'
import { Button, CircularProgress } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  invoiceCard: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
    gridGap: 10,
    alignItems: 'center',
    padding: 15
  },
  header: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
    gridGap: 10,
    padding: '5px 10px'
  }
}))

const InvoiceCard = props => {
  const row = props.row
  const classes = useStyles()
  const [loading, setLoading] = useState(false)

  const handleDownloadInvoice = () => {
    if (row.id) {
      setLoading(true)
      downloadInvoice(row.id).then(blob => {
        if (blob) {
          const elem = window.document.createElement('a')
          elem.href = window.URL.createObjectURL(blob)
          elem.download = 'Invoice.pdf'
          document.body.appendChild(elem)
          elem.click()
          document.body.removeChild(elem)
          setLoading(false)
        }
      })
    }
  }

  return (
    <ListItemCard>
      <div
        className={classes.invoiceCard}
        onClick={() => {}}
      >
        <div>
          {row.dueDate}
        </div>
        <div>
          {row.invoiceNumber}
        </div>
        <div>
          ${row.amount}
        </div>
        <div>
          {row.status?.charAt(0)?.toUpperCase() + row.status?.slice(1)}
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Button variant='outlined' color='primary' onClick={handleDownloadInvoice}>
            Download PDF
          </Button>
          {loading && <CircularProgress style={{ marginLeft: 10 }} size={24} />}
        </div>
      </div>
    </ListItemCard>
  )
}

const InvoicesPage = props => {
  const classes = useStyles()
  const [invoices, setInvoices] = useState()

  const rows = invoices ? invoices.map((row, index) => {
    return {
      searchField: row?.attributes?.invoice_number,
      invoiceNumber: row?.attributes?.invoice_number,
      amount: row?.attributes?.amount,
      status: row?.attributes?.status,
      id: row?.id,
      dueDate: row?.attributes?.due_date
    }
  }) : null

  useEffect(() => {
    getInvoices()
      .then(response => {
        if (response.data) {
          setInvoices(response.data)
        }
      })
  }, [])

  const sortValues = [
    { label: 'Date', id: 'dueDate', header: true },
    { label: 'Invoice Number', id: 'invoiceNumber', header: true },
    { label: 'Amount', id: 'amount', header: true },
    { label: 'Status', id: 'download', header: true },
    { label: 'Download', id: 'status', header: false }

  ]

  return (
    <Table
      rows={rows}
      sortValues={sortValues}
      card={InvoiceCard}
      header
      headerClass={classes.header}
      startingSortColumn='due_date'
    />
  )
}

export default InvoicesPage
