import { LoadAllProps } from './queryHelpers';
import Team from './teams';
import { useDoQuery } from './useDoQuery';

export interface PortalTeamList {
    list: PortalTeam[]
    dict: { [id: PortalTeam['id']]: PortalTeam }
}

export class PortalTeam extends Team {
    static loadAll (props?: LoadAllProps): { data: PortalTeamList, isLoading: boolean } {
        return useDoQuery({
            path: '/admin_portal/tenants/teams',
            objectClass: PortalTeam,
            useChatURL: true,
            initialData: { list: [], dict: {} } as PortalTeamList,
            searchParams: props?.searchParams,
            extraHeaders: props?.searchParams?.extraHeaders
        })
    }

    constructor ({ row }: { row?: Record<string, any> } = {}) {
        super({ row })
    }
}
