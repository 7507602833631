import { useDoQuery } from './useDoQuery'
import { GenericObject } from './genericObject'

interface OpportunityStageList {
  list: OpportunityStage[]
  dict: { [id: OpportunityStage['id']]: OpportunityStage }
}

const initialState = {
  label: '',
  default: false
}

export class OpportunityStage extends GenericObject {
  name: string
  default: boolean

  static loadAll (): { data: OpportunityStageList, isLoading: boolean } {
    return useDoQuery({ path: '/api/integrations/crm/opportunities/fields/stage/options', objectClass: OpportunityStage })
  }

  constructor ({ row }: { row?: Record<string, any> }) {
    super({ row })
    const opportunityStage = row?.attributes || initialState
    this.name = opportunityStage.label
    this.default = opportunityStage.default
  }
}
