import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined'
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined'

const useStyles = makeStyles({
  apiKeyField: {
    display: 'flex'
  },
  textField: {
    width: '90%',
    marginRight: 10
  },
  visibilityButton: {
    height: 56,
    marginTop: -1,
    width: 'calc(10% - 10px)'
  }
})
const SensitiveTextField = (props) => {
  const classes = useStyles()
  const [showAPIKey, setShowAPIKey] = useState(false)
  const value = props.value
  const autoComplete = props.autoComplete

  return (
    <>
      <div style={{ marginBottom: 20 }} className={classes.apiKeyField}>
        <TextField
          className={classes.textField}
          color='primary'
          variant='outlined'
          type={showAPIKey ? 'text' : 'password'}
          value={value}
          autoComplete={autoComplete}
          onChange={
            // (e) => {handleEdit({ type: "api_key", data: e.target.value });}
            props.onChange
          }
        />
        {showAPIKey ? (
          <Button
            color='primary'
            variant='outlined'
            startIcon={<VisibilityOffOutlinedIcon />}
            className={classes.visibilityButton}
            onClick={() => setShowAPIKey(false)}
          >
            Hide
          </Button>
        ) : (
          <Button
            color='primary'
            variant='outlined'
            startIcon={<VisibilityOutlinedIcon />}
            className={classes.visibilityButton}
            onClick={() => setShowAPIKey(true)}
          >
            Show
          </Button>
        )}
      </div>
    </>
  )
}

export default SensitiveTextField
