import TreeMultiSelect, { TreeChild } from 'cf-components/TreeMultiSelect'
import Persona from 'classes/personas'
import Form from 'classes/forms'
import { Sequence } from 'classes/sequences'
import Loader from 'library/loading/Loader'

const mapping = {
  sequences: Sequence,
  personas: Persona,
  forms: Form
}

const createTreeChildList = (items: any) => {
  const treeChildItems: TreeChild[] = []
  items.forEach((item: any) => {
    const newTreeChild: TreeChild = { value: '' }
    if ('name' in item) {
      newTreeChild.label = item.name
    }
    if ('id' in item) {
      newTreeChild.value = item.id
    }
    treeChildItems.push(newTreeChild)
  })
  return treeChildItems
}

export type TreeObjectType = keyof typeof mapping

interface TreeSelectProps {
  objectType: TreeObjectType
  setSelection: (value: number[] | string[]) => void
  selection: string[] | undefined
}

export default function TreeSelect ({ objectType, selection, setSelection }: TreeSelectProps) {
  const object = mapping[objectType]
  const { data, isLoading } = object.loadAll()
  if (isLoading) {
    return (
      <Loader type='spinner' size='sm' />
    )
  }

  const treeChildItems: TreeChild[] = createTreeChildList(data.list)

  return (
    <TreeMultiSelect
      items={treeChildItems}
      selected={selection || []}
      setSelected={(value) => setSelection(value)}
    />
  )
}
