import { useDoQuery } from './useDoQuery'
import { GenericObject } from './genericObject'

interface OpportunityTypeList {
  list: OpportunityType[]
  dict: { [id: OpportunityType['id']]: OpportunityType }
}

const initialState = {
  label: '',
  default: false
}

export class OpportunityType extends GenericObject {
  name: string
  default: boolean

  static loadAll (): { data: OpportunityTypeList, isLoading: boolean, isError: boolean, error: any } {
    return useDoQuery({ path: '/api/integrations/crm/opportunities/fields/type/options', objectClass: OpportunityType })
  }

  constructor ({ row }: { row?: Record<string, any> }) {
    super({ row })
    const opportunityType = row?.attributes || initialState
    this.name = opportunityType.label
    this.default = opportunityType.default
  }
}
