import Bot from 'classes/bots'
import { ObjectDependency, ObjectDependencyChildTypes } from 'classes/objectDependency'
import { SearchParams } from 'classes/queryHelpers'
import Loader from '../loading/Loader'
import { Typography } from '../materialUI'
import { ObjectDependencyListItem } from './ObjectDependencyListItem'
import Form from 'classes/forms'
import { Sequence } from 'classes/sequences'

interface ObjectDependencyMessageProps {
  objectDependencyList: ObjectDependency[]
  objectType: ObjectDependencyChildTypes
}

const constructSearchParams = (objectDependencyList: ObjectDependency[]): { botSearchParams: SearchParams, playSearchParams: SearchParams } => {
  const botIDs: number[] = []
  const playIDs: number[] = []
  objectDependencyList.forEach(od => {
    if (od.parentType === 'bot') {
      botIDs.push(parseInt(od.parentID))
    } else if (od.parentType === 'play') {
      playIDs.push(parseInt(od.parentID))
    }
  })
  const botSearchParams: SearchParams = botIDs.length
    ? {
      filters: [
        [{ field: 'id', operator: 'in', value: botIDs }]
      ]
    }
    : {}
  const playSearchParams: SearchParams = playIDs.length ? {
    filters: [
      [{ field: 'sequences_v2.id', operator: 'in', value: playIDs }]
    ]
  } : {}
  return { botSearchParams, playSearchParams }
}

export default function ObjectDependencyMessage ({ objectDependencyList, objectType }: ObjectDependencyMessageProps) {
  const sps = constructSearchParams(objectDependencyList)
  const { data: botData, isLoading: botIsLoading, error } = Bot.loadAll({ searchParams: sps.botSearchParams })
  const { data: formData, isLoading: formIsLoading } = Form.loadAll()
  const { data: playData, isLoading: playIsLoading } = Sequence.loadAll({ searchParams: sps.playSearchParams })

  if (botIsLoading || formIsLoading || playIsLoading) {
    return (
      <Loader />
    )
  }

  if (error) {
    return (
      <div>Unable to find the relationship data for this object</div>
    )
  }

  const parentDict = {
    bot: botData.dict,
    form: {},
    play: playData.dict,
    playrunner: {
      name: 'Playmaker',
      id: undefined
    }
  }

  const formBots = Object.entries(botData.dict)
    .filter(([_, bot]) => bot.kind === 'Form Bot')
    .map(([botID, _]) => botID);

  formBots.forEach(botID => {
    const formID = Object.keys(formData.dict).find(id =>
      formData.dict[id].calendarBotID === Number(botID) || formData.dict[id].liveChatBotID === Number(botID) || formData.dict[id].offlineBotID === Number(botID)
    );

    if (formID) {
      parentDict.form[formID] = formData.dict[formID];

      objectDependencyList = objectDependencyList.map(od => {
        if (od.parentID === botID && od.parentType === 'bot') {
          return {
            ...od,
            parentID: formID,
            parentType: 'form'
          };
        }
        return od;
      });
    }
  });

  return (
    <div>
      <Typography>
        To delete this {objectType} remove its reference in the following:
      </Typography>
      {objectDependencyList.map(od => {
        return (
          <ObjectDependencyListItem
            objectDependency={od}
            parentDict={parentDict}
            key={`${od.parentType}-${od.parentID}`}
          />
        )
      })}
    </div>
  )
}
