import MergeFieldsButton from 'cf-components/rich-text/MergeFieldsButton'
import { EmojiPicker } from 'library/EmojiPicker'
import { useEffect, useRef, useState } from 'react'
import ReactQuill from 'react-quill'
import Quill from 'quill'
import { makeStyles } from '@material-ui/core/styles'
import { Divider } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  quillContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    paddingRight: 5,
    backgroundColor: '#EEEEEE',
    alignItems: 'center',
    justifyContent: 'space-around',
    borderRadius: 5,
    width: '100%'
  },
  divider: {
    background: 'white'
  }
}))

const Link = Quill.import('formats/link');
Link.sanitize = function (url: string) {
  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    return `https://${url}`
  }
  return url;
}

type ToolbarProps = {
  textEditorRef: any
  ID: string
  showMergeFields?: boolean
  insertMergeField?: any
  hideImage?: boolean
}

function CustomToolbar ({ textEditorRef, ID, showMergeFields, insertMergeField, hideImage }: ToolbarProps): JSX.Element {
  const classes = useStyles()
  const insertEmoji = (emojiProps: any): void => {
    const editor = textEditorRef.current.getEditor()
    const range = editor.getSelection()
    const position = range ? range.index : 0
    editor.insertText(position, emojiProps.native)
  }

  return (
    <div id={ID} style={{ display: 'flex' }}>
      <span className={classes.quillContainer}>
        <button className='ql-bold' />
        <button className='ql-italic' />
        <button className='ql-underline' />
        <Divider classes={{ root: classes.divider }} orientation='vertical' flexItem />
        <button className='ql-align' value='' />
        <button className='ql-align' value='center' />
        <button className='ql-align' value='right' />
        <Divider classes={{ root: classes.divider }} orientation='vertical' flexItem />
        <button className='ql-list' value='ordered' />
        <button className='ql-list' value='bullet' />
        <Divider classes={{ root: classes.divider }} orientation='vertical' flexItem />
        <button className='ql-link' />
        {!hideImage && (
          <>
            <button className='ql-image' />
            <Divider classes={{ root: classes.divider }} orientation='vertical' flexItem />
          </>
        )}
        <button className='ql-clean' />
        <Divider classes={{ root: classes.divider }} orientation='vertical' flexItem />
        <div style={{ fontSize: '0.9em' }}>
          <EmojiPicker
            insertEmoji={insertEmoji}
          />
        </div>
        {showMergeFields && (
          <>
            <Divider classes={{ root: classes.divider }} orientation='vertical' flexItem />
            <button className='ql-insertMergeField' style={{ padding: 0 }}>
              <MergeFieldsButton
                action={insertMergeField}
                accountOwner
                calendarAgent
              />
            </button>
          </>
        )}
      </span>
    </div>
  )
}

const getID = (elementName: string) => {
  const random = Math.floor((Math.random() * 100000))
  return elementName + random
}

export type EditorProps = {
  message: string
  onChange: (str: string) => void
  placeholder?: string
  showMergeFields?: boolean
  onEnter?: () => void
  noToolbar?: boolean
  editorRef?: any
  lines?: number
  hideImage?: boolean
}

/**
 *
 * @param props
 * message: string,
 * onChange: (str: string) => void,
 * placeholder?: string,
 * showMergeFields?: boolean,
 * onEnter?: () => void,
 * noToolbar?: boolean,
 * editorRef?: any,
 * lines?: number,
 * @returns
 */
export function RichTextEditor (props: EditorProps): JSX.Element {
  const [toolbarID] = useState(getID('toolbar'))
  const ref = useRef<any>(null)
  const editorRef = props.editorRef || ref
  const style: { [k: string]: any } = {}
  let theme = 'snow'

  const modules: any = {
    toolbar: {
      container: '#' + toolbarID
    },
    clipboard: {
      matchVisual: false
    }
  }

  if (props.noToolbar) {
    modules.toolbar = false
    theme = 'bubble'
  }

  if (props.lines) {
    style.height = props.lines * 30 + 'px'
  }

  const onChange = (str: string) => {
    if (str !== props.message) {
      props.onChange(str)
    }
  }

  const keyPressed = (event: any) => {
    if (!props.onEnter) { return }
    if (event.key === 'Enter' && event.shiftKey) {
      return
    }
    if (event.key === 'Enter') {
      props.onEnter()
    }
  }

  if (props.onEnter) {
    modules.keyboard = {
      bindings: {
        enter: {
          key: 13,
          handler: () => { return false }
        }
      }
    }
  }

  const insertMergeField = (mergeField: any) => {
    editorRef.current.blur()
    editorRef.current.focus()
    const cursorPosition = editorRef.current.getEditorSelection().index
    editorRef.current.editor.insertText(cursorPosition, mergeField)
    editorRef.current.setEditorSelection(cursorPosition + 1)
  }

  // This is here to prevent the editor from undoing the initial value that is populated from the database
  useEffect(() => {
    if (editorRef.current) {
      props.onChange(props.message)
      editorRef?.current?.editor?.history?.clear()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div id='quill-container'>
      {!props.noToolbar &&
        <CustomToolbar
          ID={toolbarID}
          textEditorRef={editorRef}
          showMergeFields={props.showMergeFields}
          insertMergeField={insertMergeField}
          hideImage={props.hideImage}
        />}
      <ReactQuill
        ref={editorRef}
        modules={modules}
        value={props.message}
        onChange={onChange}
        placeholder={props.placeholder}
        onKeyUp={(e) => keyPressed(e)}
        style={style}
        theme={theme}
        bounds='#quill-container'
        preserveWhitespace
      />
    </div>
  )
}
