import React, { useEffect, useState } from 'react'
import Modal from 'components/Modal'
import EditAccountForm from './EditAccountForm'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { getAccount } from 'api/account_management'

const EditAccountModal = (props) => {
  const [account, setAccount] = useState(props.account)

  useEffect(() => {
    if (props.accountID) {
      getAccount({ accountID: props.accountID })
        .then(response => {
          if (response.data) {
            setAccount(response.data?.attributes)
          }
        })
    }
  }, [props.accountID])

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required('Enter company name')
  })

  let startingValues

  if (account == null) {
    startingValues = {
      name: '',
      website: '',
      phone: '',
      fax: '',
      account_number: '',
      employee_count: '',
      industry: '',
      annual_revenue: '',
      address_street: '',
      address_city: '',
      address_state: '',
      address_postal_code: '',
      description: '',
      account_type: ''
    }
  } else {
    startingValues = {
      name: account.name,
      website: account.website,
      phone: account.phone,
      fax: account.fax,
      account_number: account.account_number,
      employee_count: account.employee_count,
      industry: account.industry,
      annual_revenue: account.annual_revenue,
      address_street: account.address_street,
      address_city: account.address_city,
      address_state: account.address_state,
      address_postal_code: account.address_postal_code,
      description: account.description,
      account_type: account.account_type
    }
  }

  const title = account == null ? 'Create Account' : 'Edit Account'

  return (
    <Formik
      initialValues={startingValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={(values, { resetForm }) => {
        props.saveAccount(values)
        if (account == null) {
          resetForm()
        }
      }}
    >{({ submitForm }) =>
    (
      <Modal
        title={title}
        onHide={props.onHide}
        open={props.open}
        onSubmit={submitForm}
        size='md'
      >
        <EditAccountForm
          account={account}
        />
      </Modal>
    )}
    </Formik>
  )
}

export default EditAccountModal
