import { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Paper from '@material-ui/core/Paper'
import { Tabs, Tab, Tooltip } from '@material-ui/core'
import { useLocation, Link } from 'react-router-dom'
import Input from '@material-ui/core/Input'
import EditIcon from '@material-ui/icons/Edit'
import { CircularLoadingButton } from './LoadingButton'
import CustomizedSnackbar from 'components/CustomizedSnackbar'
import Loader from 'library/loading/Loader'

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    color: 'rgba(0, 0, 0, 0.8)',
    placeItems: 'center',
    alignItems: 'center',
    padding: '30px 30px 15px 25px',
    boxShadow: '0px 0px 1px 0px rgba(0,0,0,0.5)',
    position: 'relative'
  },
  actionDiv: {
    display: 'flex',
    alignItems: 'center'
  },
  actionText: {
    color: 'rgba(0,0,0, 0.65)',
    marginRight: 15,
    marginLeft: 15
  },
  fab: {
    color: 'white',
    backgroundColor: theme.palette.primary.alt,
    '&:hover': {
      backgroundColor: '#6941A1'
    }
  },
  crumb: {
    fontSize: '.8em'
  },
  typography: {
    fontSize: '1.05em'
  },
  breadcrumbs: {
    position: 'absolute',
    top: 5
  },
  breadcrumbs2: {
    marginRight: '10px'
  },
  singlePageContent: {
    backgroundColor: 'white',
    minHeight: 'calc(100% - 85px)',
    overflowX: 'hidden',
    fontFamil: 'poppins, sans-serif'
  },
  paper: {
    boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2)',
    marginTop: -15
  },
  selectedLink: {
    textDecoration: 'none',
    color: theme.palette.primary.alt
  },
  unselectedLink: {
    textDecoration: 'none',
    color: 'rgba(0,0,0,0.54)'
  },
  input: {
    color: 'rgba(0,0,0,0.65)',
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: 32,
    width: 500
  },
  titleDiv: {
    display: 'flex',
    alignItems: 'center'
  },
  subtitle: {
    fontSize: 12,
    fontWeight: 100
  },
  loadingPage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh'
  },
  tabIndicator: {
    backgroundColor: theme.palette.primary.alt
  }
}))

const EditableTitle = (props) => {
  const classes = useStyles()
  const [title, setTitle] = useState(props.title)
  const [editMode, setEditMode] = useState(false)
  const extraTitleInfo = props.extraTitleInfo

  function remapEnterKey (event) {
    if (event.key === 'Enter') {
      setEditMode(false)
      props.saveName(title)
    }
  }

  useEffect(() => {
    setTitle(props.title)
  }, [props.title])

  if (editMode) {
    return (
      <Input
        value={title}
        disableUnderline
        onKeyPress={remapEnterKey}
        autoFocus
        classes={{ input: classes.input }}
        onChange={e => setTitle(e.target.value)}
        onBlur={() => {
          setEditMode(false)
          props.saveName(title)
        }}
      />
    )
  }

  return (
    <div className={classes.titleDiv} onClick={() => setEditMode(true)}>
      <Typography variant='h5'>
        {title}
      </Typography>
      <div style={{ marginLeft: 10, display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        <EditIcon style={{ fontSize: '1.2em', color: '#7C7C7C' }} />
      </div>
      {extraTitleInfo}
    </div>
  )
}

function ActionButton (props) {
  const classes = useStyles()
  const handleAction = props.action
  const actionText = props.actionText
  const fabButton = (
    <Fab
      size='small'
      color='primary'
      aria-label={actionText}
      onClick={handleAction}
      classes={{ root: classes.fab }}
      disabled={props.disabled}
    >
      {props.icon}
    </Fab>
  )

  if (props.link) {
    return (
      <Link to={props.link}>
        {props.tooltip ? (
          <Tooltip title={props.tooltip}>
            <span>
              {fabButton}
            </span>
          </Tooltip>
        ) : (
          fabButton
        )}
      </Link>
    )
  }

  if (props.saveProps) {
    return (
      <CircularLoadingButton
        saveProps={props.saveProps}
      />
    )
  }

  return (
    props.tooltip ? (
      <Tooltip title={props.tooltip}>
        <span>
          {fabButton}
        </span>
      </Tooltip>
    ) : (
      fabButton
    )
  )
}

export function ActionDiv (props) {
  const classes = useStyles()
  let icon = <AddIcon />
  const saveProps = props.saveProps
  let actionText = props.actionText
  if (saveProps) {
    actionText = saveProps.saveText
  }
  if (props.icon) {
    icon = props.icon
  }

  if (!actionText && !props.extraButtons) {
    return <></>
  }

  return (
    <div className={classes.actionDiv} id='action-div'>
      {props.extraButtons}
      {actionText && (
        <>
          <div className={classes.actionText}>
            <Typography variant='h6'>
              {actionText}
            </Typography>
          </div>
          <ActionButton
            link={props.link}
            icon={icon}
            actionText={actionText}
            action={props.action}
            saveProps={saveProps}
            disabled={props.disabled}
            tooltip={props.tooltip}
          />
        </>
      )}
    </div>
  )
}

function PageHeader (props) {
  const classes = useStyles()
  const crumbs = props.breadcrumbs
  const saveProps = props.saveProps
  const extraButtons = props.extraButtons // JSX.Element children
  const extraTitleInfo = props.extraTitleInfo // JSX.Element next to title
  const sameRowBreadCrumbs = props.sameRowBreadCrumbs
  const headerHeight = crumbs ? 90 : 60

  return (
    <div className={classes.header} id='page-header' style={{ boxShadow: props.hideBorder ? 'none' : null, height: headerHeight }}>
      <div style={{ display: sameRowBreadCrumbs ? 'flex' : 'block', flexDirection: sameRowBreadCrumbs ? 'row' : undefined }}>
        {crumbs &&
          <div className={sameRowBreadCrumbs ? classes.breadcrumbs2 : classes.breadcrumbs} id='breadcrumbs'>
            <Breadcrumbs aria-label='breadcrumb' classes={{ li: classes.crumb }}>
              {
                crumbs.map(crumb => {
                  if (crumb.link) {
                    return (
                      <Link style={{ color: '#7A7A7A' }} to={crumb.link} key={crumb.text}>
                        {crumb.text}
                      </Link>
                    )
                  } else {
                    return (
                      <Typography color='textPrimary' key={crumb.text} classes={{ root: classes.typography }}>{crumb.text}</Typography>
                    )
                  }
                })
              }
            </Breadcrumbs>
          </div>}
        <div>
          {props.editableTitle
            ? (
              <EditableTitle
                title={props.title}
                saveName={props.saveName}
                extraTitleInfo={extraTitleInfo}
              />)
            : (
              <Typography variant='h1' id='page-title'>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {props.title} {extraTitleInfo}
                </div>
              </Typography>)}
          {props.subtitle && (
            <div>
              <Typography variant='subtitle1' className={classes.subtitle}>
                {props.subtitle}
              </Typography>
            </div>
          )}
        </div>
      </div>
      <ActionDiv
        actionText={props.actionText}
        action={props.action}
        icon={props.icon}
        link={props.link}
        disabled={props.disabled}
        saveProps={saveProps}
        extraButtons={extraButtons}
        tooltip={props.tooltip}
      />
    </div>
  )
}

function TabPanel (props) {
  const [loaded, setLoaded] = useState(false)
  const { children, value, index } = props
  const visibility = value === index ? 'visible' : 'hidden'
  const padding = props.padding || 0

  const style = {
    position: 'absolute',
    top: 0,
    width: `calc(100% - ${2 * padding}px)`,
    backgroundColor: '#f8f8f8',
    borderTop: '1px solid #ccc',
    minHeight: `calc(100vh - 119px - ${2 * padding}px)`,
    padding: props.padding
  }
  if (props?.bgColor) {
    style.backgroundColor = props.bgColor
  }

  if (props.alwaysLoaded) {
    style.visibility = visibility
  }
  if (value === index || props.alwaysLoaded || loaded) {
    if (!loaded) {
      setLoaded(true)
    }
    return (
      <div
        style={style}
        hidden={value !== index}
      >
        {children}
      </div>
    )
  }

  return <></>
}

function CustomTab (props) {
  const classes = useStyles()
  const { t, tab, index } = props

  return (
    <Link to={t.url} className={index === tab ? classes.selectedLink : classes.unselectedLink}>
      <Tab
        label={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ marginRight: '5px' }}>{t.name}</span>
            {t.icon}
          </div>
        }
        style={{ opacity: 1 }}
      />
    </Link>
  )
}

function TabPage (props) {
  const classes = useStyles()
  const location = useLocation()
  let children = props.children || []
  if (!Array.isArray(children)) {
    children = [children]
  }
  children = children.filter(child => Boolean(child))
  const index = props.tabs.map(tab => tab.url).indexOf(location.pathname)
  const tab = index === -1 ? 0 : index
  let alwaysLoadedArray = []
  if (props.alwaysLoaded) {
    alwaysLoadedArray = props.alwaysLoaded
  }

  let actionText = props.actionText
  let action = props.action
  let icon = props.icon
  let disabled = props.disabled
  let link = props.link
  let saveProps = props.saveProps
  let tooltip = props.tooltip
  let extraButtons = props.extraButtons

  const tabProps = props.tabs[tab]

  if (tabProps) {
    if (Object.prototype.hasOwnProperty.call(tabProps, 'action')) {
      action = tabProps.action
    }
    if (Object.prototype.hasOwnProperty.call(tabProps, 'actionText')) {
      actionText = tabProps.actionText
    }
    if (Object.prototype.hasOwnProperty.call(tabProps, 'icon')) {
      icon = tabProps.icon
    }
    if (Object.prototype.hasOwnProperty.call(tabProps, 'disabled')) {
      disabled = tabProps.disabled
    }
    if (Object.prototype.hasOwnProperty.call(tabProps, 'link')) {
      link = tabProps.link
    }
    if (Object.prototype.hasOwnProperty.call(tabProps, 'saveProps')) {
      saveProps = tabProps.saveProps
    }
    if (Object.prototype.hasOwnProperty.call(tabProps, 'tooltip')) {
      tooltip = tabProps.tooltip
    }
    if (Object.prototype.hasOwnProperty.call(tabProps, 'extraButtons')) {
      extraButtons = tabProps.extraButtons
    }
  }

  return (
    <>
      <PageHeader
        title={props.title}
        actionText={actionText}
        link={link}
        action={action}
        icon={icon}
        disabled={disabled}
        breadcrumbs={props.breadcrumbs}
        hideBorder
        editableTitle={props.editableTitle}
        saveName={props.saveName}
        saveProps={saveProps}
        subtitle={props.subtitle}
        extraButtons={extraButtons}
        extraTitleInfo={props.extraTitleInfo}
        sameRowBreadCrumbs={props.sameRowBreadCrumbs}
        tooltip={tooltip}
      />
      <Paper position='static' className={classes.paper}>
        <Tabs classes={{ indicator: classes.tabIndicator }} value={tab} textColor='primary'>
          {
            props.tabs.map((t, index) => (
              <CustomTab t={t} key={index} tab={tab} index={index} />
            ))
          }
        </Tabs>
      </Paper>
      <div style={{ position: 'relative' }}>
        {children.map((child, index) => (
          <TabPanel
            value={tab}
            index={index}
            key={index}
            bgColor={tabProps.bgColor}
            padding={props.padding}
            alwaysLoaded={alwaysLoadedArray.includes(index)}
          >
            {child}
          </TabPanel>
        ))}
      </div>
    </>
  )
}

/**
 * @typedef {Object} AppPageProps
 * @prop {string} title - Page Title
 * @prop {string} [actionText] - Text for floating action button
 * @prop {string} [subtitle] - Text for floating action button
 * @prop {string} [link] - If used, the floating action button will redirect to the link
 * @prop {import('react').MouseEventHandler<HTMLButtonElement>} [action] - Function to be called when action button is pressed
 * @prop {Object} [icon] - The icon to be used for the action button
 * @prop {boolean} [disabled] - Is the button disabled
 * @prop {Array} [breadcrumbs] - Array of objects with link and text properties, renders breadcrumbs above the page title
 * @prop {Record<string, any>[]} [tabs] - Array of objects with name, url, and tab properties, renders clickable tabs for navigation
 * @prop {import('react').ReactNode | import('react').ReactNode[]} children
 * @prop {number | string} [padding]
 * @prop {boolean} [editableTitle]
 * @prop {(...args: any) => void} [saveName]
 * @prop {any} [saveProps]
 * @prop {boolean} [alwaysLoaded]
 * @prop {JSX.Element} [extraButtons] - Extra buttons to be rendered in the header
 * @prop {boolean} [isLoading]
 * @prop {JSX.Element} [extraTitleInfo] - Extra info to be rendered next to the title
 * @prop {boolean} [sameRowBreadCrumbs]
 */

/**
 * Page component with header, action button, and optional tabs
 * @param {AppPageProps} props
 */
function AppPage ({ title, actionText, link, action, icon, disabled = false, breadcrumbs, tabs, isLoading, ...props }) {
  const classes = useStyles()
  const [snackState, setSnackState] = useState({
    open: false,
    variant: 'success',
    message: 'Your settings have been saved'
  })

  useEffect(() => {
    if (props?.saveProps?.error === true) {
      setSnackState({
        open: true,
        variant: 'error',
        message: 'Failed to save: Invalid configuration on this page or other tabs on this page'
      })
    } else {
      setSnackState({
        open: false,
        variant: 'error',
        message: 'Failed to save: Invalid configuration'
      })
    }
  }, [props?.saveProps?.error])

  if (tabs) {
    return (
      <>
        <TabPage
          title={title}
          actionText={actionText}
          link={link}
          action={action}
          icon={icon}
          disabled={disabled}
          tabs={tabs}
          subtitle={props.subtitle}
          breadcrumbs={breadcrumbs}
          padding={props.padding}
          editableTitle={props.editableTitle}
          saveName={props.saveName}
          saveProps={props.saveProps}
          alwaysLoaded={props.alwaysLoaded}
          extraButtons={props.extraButtons}
          extraTitleInfo={props.extraTitleInfo}
          sameRowBreadCrumbs={props.sameRowBreadCrumbs}
        >
          {isLoading ? (
            <div className={classes.loadingPage}>
              <Loader type='spinner' size='lg' />
            </div>) : props.children}
        </TabPage>
        <CustomizedSnackbar state={snackState} handler={setSnackState} />
      </>
    )
  }

  return (
    <>
      <PageHeader
        title={title}
        subtitle={props.subtitle}
        actionText={actionText}
        link={link}
        action={action}
        icon={icon}
        disabled={disabled}
        breadcrumbs={breadcrumbs}
        editableTitle={props.editableTitle}
        saveName={props.saveName}
        saveProps={props.saveProps}
        extraButtons={props.extraButtons}
        extraTitleInfo={props.extraTitleInfo}
      />
      {isLoading ? (
        <div className={classes.loadingPage}>
          <Loader type='spinner' size='lg' />
        </div>) : (<div className={classes.singlePageContent}> {props.children} </div>)}
      <CustomizedSnackbar state={snackState} handler={setSnackState} />
    </>
  )
}

export default AppPage
