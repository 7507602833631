import { makeStyles } from '@material-ui/core/styles'
import { useCallback, useEffect, useState } from 'react'
import { Chip, Typography } from '@material-ui/core'
import { getTenantDomains, saveTenantDomains } from 'api/admin_only/tenants'
import ButtonTextField from 'components/ButtonTextField'

const useStyles = makeStyles(theme => ({
  selectMargin: {
    marginBottom: 10
  },
  smallChip: {
      margin: 5
  },
  deleteIcon: {
      fill: 'white'
  },
  keywords: {
      display: 'flex',
      marginTop: 8,
      flexFlow: 'wrap'
    }
}))

interface domainManagerProps {
  tenantId: number | null
}

export const AdminDomainManager = (props: domainManagerProps): JSX.Element => {
  const classes = useStyles()
  const tenantId = props.tenantId
  const [domains, setDomains] = useState<string[]>([])

  const loadDomains = useCallback(() => {
    getTenantDomains(tenantId)
    .then(response => {
      setDomains(response.data.attributes.domains)
    })
  }, [tenantId])

  const deleteDomain = (val: string) => {
    handleChange(domains.filter(value => value !== val))
  }

  const addDomain = (val: string) => {
    handleChange(domains.concat([val]))
  }

  const handleChange = (val: string[]) => {
    const attributes = { domains: val }
    saveTenantDomains({ tenantID: tenantId, attributes: attributes })
    .then(() => {
      setDomains(val)
    })
  }

  useEffect(() => {
    loadDomains()
  }, [loadDomains])

  return (
    <div className={classes.selectMargin}>
      <Typography variant='subtitle1'>
        This is the approved list of domains that the tenant has inserted. You can remove or add any
        to be included in the daily script check, but make sure this doesn't affect the tenant's
        bot fires.
      </Typography>
      <div className={classes.keywords}>
        {
          domains.map((option, index) => {
            return (
              <Chip
                key={index}
                label={option}
                onDelete={() => deleteDomain(option)}
                color='primary'
                className={classes.smallChip}
                classes={{ deleteIcon: classes.deleteIcon }}
              />
            )
          })
        }
      </div>
      <ButtonTextField
        placeholder='Type a new domain and hit enter...' addButton={(domain: string) => {
          addDomain(domain)
        }}
      />
    </div>
  )
}
