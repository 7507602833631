import { doDelete, doGet, doPost, doPatch } from '../api'

const updateOffer = offerObj => {
  if (offerObj.id) {
    return doPatch({
      path: `/api/admin_portal/offers/${offerObj.id}`,
      data: offerObj
    })
  }
  return doPost({
    path: '/api/admin_portal/offers',
    data: offerObj
  })
}

const getOffer = id => {
  return doGet({ path: `/api/admin_portal/offers/${id}` })
}

const deleteOffer = id => {
  return doDelete({ path: `/api/admin_portal/offers/${id}` })
}

const getTrialOffer = () => {
  return doGet({ path: '/api/admin_portal/offers/trial' })
}

const saveTrialOffer = (trialOfferObj) => {
  return doPatch({ path: '/api/admin_portal/offers/trial', data: trialOfferObj })
}

export { updateOffer, getOffer, deleteOffer, getTrialOffer, saveTrialOffer }
