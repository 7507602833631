import { useEffect, useState } from 'react';
import { getABMSettings, updateABMSettings } from 'api/account_management';
import { getHubSpotProperties, getHubSpotUsers } from 'api/hubspot';
import {
  INTEGRATIONS,
  addIntegrationSetting, getCredentialsStatus, getIntegration,
  getIntegrationFeature,
  getIntegrationFields,
  updateIntegration
} from 'api/integrations';
import HubSpotOAuth from '../OAuth/HubSpotOAuth';
import TabProvider from '../IntegrationPage/TabProvider';
import IntegrationPage from '../IntegrationPage/IntegrationPage';
import SyncCriteriaCard from './SyncCriteriaCard';
import SyncDelayCard from './SyncDelayCard';
import ContactOwnerCard from './ContactOwnerCard';
import ContactEnrollmentCard from './ContactEnrollmentCard';
import EventSettingsCard from '../EventSettingsCard';
import UserMappingCard from './UserMappingCard';
import TaskGenerationCard from './TaskGenerationCard';
import ContactFieldMappingCard from './ContactFieldMappingCard';
import OwnershipCard from './OwnershipCard';
import GetStartedCards from './GetStartedCards';
import CompanyFieldMappingCard from './CompanyFieldMappingCard';
import CreateAccountsCard from '../Salesforce/CreateAccountsCard';
import AccountCustomFieldSegmentCard from '../Salesforce/AccountCustomFieldSegmentCard';
import ScoreSyncingCard from '../ScoreSyncingCard';
import { hasComponent } from 'api/billing'
import { useQuery } from 'react-query';

/* eslint-disable */
const initialValues = {
  type: 'integrations',
  attributes: {
    integration_name: INTEGRATIONS.HubSpot,
    enabled: false,
    config: {
      cookie_form: false,
      delay_seconds: 0,
      min_lead_score: 0,
      static_list_id: '',
      owner_rules: ['meeting_scheduled', 'last_routed', 'closed_conversation'],
      task_creation: false,
      field_custom_mapping: []
    },
    mappings: {
      user_mapping: [],
      mapping: [
        {
          type: 'cf_field',
          data_type: 'str',
          overwrite: false,
          object_type: 'lead',
          external_field: 'firstname',
          internal_field: 'first_name'
        },
        {
          type: 'cf_field',
          data_type: 'str',
          overwrite: false,
          object_type: 'lead',
          external_field: 'lastname',
          internal_field: 'last_name'
        },
        {
          type: 'cf_field',
          data_type: 'str',
          overwrite: false,
          object_type: 'lead',
          external_field: 'phone',
          internal_field: 'phone'
        },
        {
          type: 'cf_field',
          data_type: 'str',
          overwrite: false,
          object_type: 'lead',
          external_field: 'email',
          internal_field: 'email'
        },
        {
          type: 'cf_field',
          data_type: 'str',
          overwrite: false,
          object_type: 'lead',
          external_field: 'company',
          internal_field: 'company_name'
        }
      ]
    },
    event_settings: {
      transcript: {
        enabled: false
      },
      meeting_booked: {
        enabled: false
      },
      email_sent: {
        enabled: false
      }
    }
  }
}
const defaultABMSettings: {
  owner_rules: string[],
  account_sync: boolean,
  fallback_user_id: number,
  segment_rules: {
    segment_id: number,
    rules: string[],
    segment_owner?: number
  }[],
  search_leads: boolean
} = {
  owner_rules: [],
  account_sync: false,
  fallback_user_id: 1,
  segment_rules: [],
  search_leads: false
}
/* eslint-enable */

const HubSpotIntegrationPage = () => {
  const [saveEnabled, setSaveEnabled] = useState(false)
  const [initSave, setInitSave] = useState(false)

  const [abmSyncSettings, setABMSyncSettings] = useState(defaultABMSettings)
  const [allowABE, setAllowABE] = useState(false)
  const [integrationSettings, setIntegrationSettings] = useState(initialValues)
  const [externalFields, setExternalFields] = useState([])
  const [accountExternalFields, setAccountExternalFields] = useState<string[]>([])
  const [hasHubSpotConnection, setHasHubSpotConnection] = useState(null)
  const [hubSpotUsers, setHubSpotUsers] = useState([])
  const [oauthUrl, setOauthUrl] = useState()
  const [focusMapping, setFocusMapping] = useState(false)
  const { data: hasScoringComponent = false } = useQuery('has-scoring-component', () => hasComponent('score-insights'))

  const save = () => {
    Promise.all([
      updateIntegration({ integration: integrationSettings }).then((response) => {
        setIntegrationSettings(response.data)
      }),
      // eslint-disable-next-line
      updateABMSettings({ ABMSettings: { attributes: { sync_settings: abmSyncSettings } } })
    ]).then(() => {
      setSaveEnabled(false)
    })
  }

  const handleEdit = ({ type, data }: { type: string, data: any }) => {
    if (type === 'mapping' || type === 'user_mapping') {
      setIntegrationSettings({
        ...integrationSettings,
        attributes: {
          ...integrationSettings.attributes,
          mappings: {
            ...integrationSettings.attributes.mappings,
            [type]: data
          }
        }
      })
    } else if (type === 'event_settings') {
      setIntegrationSettings({
        ...integrationSettings,
        attributes: {
          ...integrationSettings.attributes,
          [type]: data
        }
      })
    } else {
      setIntegrationSettings({
        ...integrationSettings,
        attributes: {
          ...integrationSettings.attributes,
          config: {
            ...integrationSettings.attributes.config,
            [type]: data
          }
        }
      })
    }
  }

  const handleABMSettingsChange = (settingName: string, settings: any) => {
    const newSettings = { ...abmSyncSettings }
    switch (settingName) {
      case 'owner_rules':
        newSettings.owner_rules = [...settings]
        break
      case 'account_sync':
        newSettings.account_sync = settings
        break
      case 'fallback_user_id':
        newSettings.fallback_user_id = settings
        break
      case 'segment_rules':
        newSettings.segment_rules = [...settings]
        break
    }
    setABMSyncSettings(newSettings)
  }

  const tabs = [
    {
      name: 'GET STARTED',
      url: '/settings/hubspot/get-started',
      components: [
        <TabProvider key='firsttab' setCanSave={setSaveEnabled} shouldSave={initSave}>
          <GetStartedCards />
        </TabProvider>
      ]
    },
    {
      name: 'CONVERSATION SYNC',
      url: '/settings/hubspot/conversation-sync',
      components: [
        <TabProvider key='secondtab' setCanSave={setSaveEnabled} shouldSave={initSave}>
          <SyncCriteriaCard key='sync-criteria' save={save} handleEdit={handleEdit} integrationSettings={integrationSettings} />
        </TabProvider>,
        <TabProvider key='secondtab' setCanSave={setSaveEnabled} shouldSave={initSave}>
          <ContactEnrollmentCard key='default-list' save={save} handleEdit={handleEdit} integrationSettings={integrationSettings} />
        </TabProvider>,
        <TabProvider key='secondtab' setCanSave={setSaveEnabled} shouldSave={initSave}>
          <SyncDelayCard key='sync-delay' save={save} handleEdit={handleEdit} integrationSettings={integrationSettings} />
        </TabProvider>,
        <TabProvider key='secondtab' setCanSave={setSaveEnabled} shouldSave={initSave}>
          <ContactOwnerCard key='default-owner' save={save} handleEdit={handleEdit} integrationSettings={integrationSettings} />
        </TabProvider>
      ]
    },
    {
      name: 'CONTACTS',
      url: '/settings/hubspot/contacts',
      components: [
        <TabProvider key='thirdtab' setCanSave={setSaveEnabled} shouldSave={initSave}>
          <ContactFieldMappingCard key='contact-field-mapping' save={save} handleEdit={handleEdit} integrationSettings={integrationSettings} contactExternalFields={externalFields} />
        </TabProvider>,
        <TabProvider key='thirdtab' setCanSave={setSaveEnabled} shouldSave={initSave}>
          <EventSettingsCard
            key='event-settings'
            save={save}
            handleEdit={handleEdit}
            integrationSettings={integrationSettings}
            title='Contact Event Settings'
            description='Sync the following Signals events. A HubSpot task will be created and attached to the contact'
            enabledMap={{
              transcript: {
                enabled: integrationSettings.attributes.event_settings?.transcript?.enabled,
                label: 'Conversations'
              },
              // eslint-disable-next-line
              meeting_booked: {
                enabled: integrationSettings.attributes.event_settings?.meeting_booked?.enabled,
                label: 'Meeting Booked'
              },
              // eslint-disable-next-line
              email_sent: {
                enabled: integrationSettings.attributes.event_settings?.email_sent?.enabled,
                label: 'Email Sent'
              }
            }}
          />
        </TabProvider>
      ]
    },
    {
      name: 'COMPANIES',
      url: '/settings/hubspot/accounts',
      components: [
        <TabProvider key='fourthtab' setCanSave={setSaveEnabled} shouldSave={initSave}>
          <CreateAccountsCard key='create-accounts' integration={integrationSettings.attributes.integration_name} />
        </TabProvider>,
        <TabProvider key='fourthtab' setCanSave={setSaveEnabled} shouldSave={initSave}>
          <CompanyFieldMappingCard key='company-mapping' save={save} integrationSettings={integrationSettings} externalFields={accountExternalFields} focusMapping={focusMapping} />
        </TabProvider>,
        <TabProvider key='fourthtab' setCanSave={setSaveEnabled} shouldSave={initSave}>
          <AccountCustomFieldSegmentCard key='account-custom-field-segment' handleEdit={handleEdit} setCanSave={setSaveEnabled} fields={integrationSettings.attributes.config?.field_custom_mapping || []} integration={integrationSettings.attributes.integration_name} />
        </TabProvider>,
        <TabProvider key='fourthtab' setCanSave={setSaveEnabled} shouldSave={initSave}>
          <TaskGenerationCard key='event-task-generation' save={save} handleEdit={handleEdit} integrationSettings={integrationSettings} />
        </TabProvider>,
        hasScoringComponent && (
          <TabProvider key='fourthtab' setCanSave={setSaveEnabled} shouldSave={initSave}>
            <ScoreSyncingCard key='score-syncing' save={save} handleEdit={handleEdit} integrationSettings={integrationSettings} integrationName='HubSpot' externalFields={accountExternalFields} setFocusMapping={setFocusMapping} />
          </TabProvider>)
      ]
    },
    {
      name: 'USERS',
      url: '/settings/hubspot/users',
      components: [
        <TabProvider key='fifthtab' setCanSave={setSaveEnabled} shouldSave={initSave}>
          <UserMappingCard key='user-mapping' save={save} handleEdit={handleEdit} integrationSettings={integrationSettings} hubspotUsers={hubSpotUsers} />
        </TabProvider>
      ]
    },
    {
      name: 'OWNERSHIP',
      url: '/settings/hubspot/ownership',
      components: [
        <TabProvider key='sixthtab' setCanSave={setSaveEnabled} shouldSave={initSave}>
          <OwnershipCard key='ownership' save={save} allowABE={allowABE} handleABMSettingsChange={handleABMSettingsChange} abmSyncSettings={abmSyncSettings} />
        </TabProvider>
      ]
    }
  ]

  useEffect(() => {
    getCredentialsStatus(INTEGRATIONS.HubSpot)
      .then((response) => {
        setOauthUrl(response.data.attributes.oauth_start_url)
        setHasHubSpotConnection(response.data.attributes.has_credentials)
      })
  }, [])

  useEffect(() => {
    if (hasHubSpotConnection) {
      getIntegrationFeature('crm.abe').then((resp: any) => {
        if (resp.data.attributes.integration.name === 'hubspot') {
          setAllowABE(true)
        }
      })
      getABMSettings()
        .then((response: any) => {
          setABMSyncSettings(response.data.attributes.sync_settings)
        })
      getIntegrationFields('hubspot', 'account')
        .then((response) => setAccountExternalFields(response.data.map((ef: any) => ef.attributes)))
      getIntegration(INTEGRATIONS.HubSpot)
        .then(response => {
          if (response.ok) {
            setIntegrationSettings(response.data)
          } else {
            addIntegrationSetting({ integration: initialValues }).then((response) => {
              setIntegrationSettings(response.data)
            })
          }
        })
      getHubSpotProperties()
        .then(response => {
          if (response.ok) {
            setExternalFields(response.data.attributes.contact.fields)
          }
        })

      getHubSpotUsers()
        .then(response => {
          setHubSpotUsers(response.data)
        })
    }
  }, [hasHubSpotConnection])

  if (hasHubSpotConnection === null) {
    return <></>
  }

  if (!hasHubSpotConnection) {
    return (
      <HubSpotOAuth
        name='HubSpot Integration OAuth'
        oauth_start_url='/api/hubspot/oauth'
        oauthUrl={oauthUrl}
      />
    )
  }

  return (
    <>
      <IntegrationPage
        integration='HubSpot'
        tabs={tabs}
        saveDisabled={!saveEnabled}
        setShouldSave={setInitSave}
        oauth
        learnMoreLink='https://help.getsignals.ai/article/a3v1b6wgux-hub-spot-integration'
      />
    </>
  )
}

export default HubSpotIntegrationPage
