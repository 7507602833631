import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import UnsavedChangesModal from './UnsavedChangesModal'

function UnsavedChangesHistoryModal (props: any) {
  const [modalOpen, setModalOpen] = useState(false)
  const [nextLocation, setNextLocation] = useState(null)
  const componentHistory = useHistory()
  const history = props.history ? props.history : componentHistory
  const disabled = props.disabled
  const validPaths = props.validPaths

  function navigateToNextLocation () {
    if (props.reset) {
      props.reset()
    }
    setModalOpen(false)
    history.block(() => { return true })
    history.push(nextLocation.pathname)
  }

  if (history) {
    if (!disabled) {
      history.block((nextRoute: any) => {
        if (validPaths && nextRoute.pathname.includes(validPaths)) {
          return true
        }
        setModalOpen(true)
        setNextLocation(nextRoute)
        return false
      })
    } else {
      history.block(() => {
        return true
      })
    }
  }

  return (
    <UnsavedChangesModal
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      save={props.save}
      cancelAction={navigateToNextLocation}
    />
  )
}

export default UnsavedChangesHistoryModal
