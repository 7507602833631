/* eslint-disable @typescript-eslint/naming-convention */
import { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Divider from '@material-ui/core/Divider'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import UserPicker from '../flow-builder/UserPicker'
import TeamPicker from '../flow-builder/TeamPicker'
import TextField from '@material-ui/core/TextField'
import { RichTextEditor } from 'library/richText/RichTextEditor'
import { Modal } from 'library/Modal'

const useStyles = makeStyles(theme => ({
  subtitle: {
    color: '#999',
    fontFamily: 'Poppins, sans serif',
    fontSize: '12px'
  },
  divider: {
    marginTop: 10,
    marginBottom: 10
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    marginRight: 8
  },
  input: {
    padding: 6,
    borderRadius: 30
  },
  inputRoot: {
    borderRadius: 30,
    paddingLeft: 10
  },
  select: {
    height: 24,
    borderRadius: 30
  },
  modalBody: {
    height: 270,
    overflowY: 'auto'
  },
  contentClass: {
    padding: 20
  }
}))

const MethodSelect = (props: any) => {
  const ITEM_HEIGHT = 40
  const ITEM_PADDING_TOP = 8
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 6.5 + ITEM_PADDING_TOP,
        width: 250
      }
    }
  }
  return (
    <Select
      fullWidth
      variant='outlined'
      value={props.value}
      onChange={props.handleChange}
      MenuProps={MenuProps}
      displayEmpty
      margin='dense'
    >
      <MenuItem value='' style={{ height: 40 }} disabled>Select a route method</MenuItem>
      <MenuItem value='single_agent' style={{ height: 40 }}>Route to a single agent</MenuItem>
      <MenuItem value='round_robin_group' style={{ height: 40 }}>Round robin within a custom group</MenuItem>
      <MenuItem value='quick_draw_group' style={{ height: 40 }}>Quick draw within a custom group</MenuItem>
      <MenuItem value='round_robin_team' style={{ height: 40 }}>Round robin within a team</MenuItem>
      <MenuItem value='quick_draw_team' style={{ height: 40 }}>Quick draw within a team</MenuItem>
    </Select>
  )
}

function RouteModal (props: any) {
  const classes = useStyles()
  const [selectedUserIds, setSelectedUserIds] = useState(props.selectedUserIds || [])
  const [method, setMethod] = useState(props.method)
  const [selectedTeamId, setSelectedTeamId] = useState(props.selectedTeamId)
  const [welcome, setWelcome] = useState(props.welcome)
  const [away, setAway] = useState(props.away)
  const [label, setLabel] = useState(props.label)

  function editWelcome (value: string) {
    setWelcome(value)
  }

  function editAway (value: string) {
    setAway(value)
  }

  function cancelChanges () {
    props.onHide()
    setSelectedUserIds(props.selectedUserIds)
  }

  if (!props.open) {
    return <></>
  }

  const save = () => {
    props.save({
      user_ids: selectedUserIds,
      method: method,
      team_id: selectedTeamId,
      welcome: welcome,
      away: away,
      label: label
    })
    props.onHide()
  }

  let subtitle = 'Which teammate should this conversation be routed to?'
  if (props.router) {
    subtitle = 'What should happen when the visitor clicks this button?'
  }

  return (
    <Modal
      open={props.open}
      onHide={cancelChanges}
      handleSave={save}
      title='Route conversation'
    >
      {props.router
        ? (
          <div style={{ marginBottom: 20 }}>
            <div className={classes.subtitle}>
              Enter a label for this button
            </div>
            <Divider className={classes.divider} />
            <div style={{ marginTop: -8 }}>
              <TextField
                variant='outlined'
                fullWidth
                value={label}
                margin='dense'
                onChange={e => setLabel(e.target.value)}
              />
            </div>
          </div>)
        : <></>}
      <div style={{ minHeight: 150 }}>
        <div className={classes.subtitle}>
          {subtitle}
        </div>
        <Divider className={classes.divider} />
        <div>
          <div style={{ marginBottom: 15 }}>
            <MethodSelect
              value={method}
              handleChange={(e: any) => {
                if (e.target.value === 'single_agent') {
                  if (selectedUserIds.length) {
                    setSelectedUserIds([selectedUserIds[0]])
                  } else {
                    setSelectedUserIds([])
                  }
                }
                setMethod(e.target.value)
              }}
            />
          </div>
          {method && (method.includes('group') || method === 'single_agent') ? (
            <UserPicker
              selectedUserIds={selectedUserIds}
              setSelectedUserIds={setSelectedUserIds}
              single={method === 'single_agent'}
            />
          ) : (
            <TeamPicker
              value={selectedTeamId}
              setSelectedTeamId={setSelectedTeamId}
            />
          )}
        </div>
      </div>
      <div style={{ marginTop: 25 }}>
        <div className={classes.subtitle}>
          Enter a message to be sent when an agent is available for chat
        </div>
        <Divider className={classes.divider} />
        <div style={{ minHeight: 80 }}>
          <RichTextEditor
            message={welcome}
            onChange={editWelcome}
            placeholder='Type your message here...'
          />
        </div>
      </div>
      <div style={{ marginTop: 25, marginBottom: 15 }}>
        <div className={classes.subtitle}>
          Enter a message to be sent when no one is available for chat
        </div>
        <Divider className={classes.divider} />
        <div style={{ minHeight: 80 }}>
          <RichTextEditor
            message={away}
            onChange={editAway}
            placeholder='Type your message here...'
          />
        </div>
      </div>
    </Modal>
  )
}

export default RouteModal
