import { makeStyles } from '@material-ui/core/styles'
import { IconButton, Tooltip } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/DeleteForever'
import EditIcon from '@material-ui/icons/Edit'
import FileCopyIcon from '@material-ui/icons/FileCopy'

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: 'absolute',
    top: -20,
    left: -30,
    padding: 20,
    opacity: 0,
    transition: 'opacity .15s, transform .2s',
    zIndex: 2000
  },
  menu: {
    padding: '6px 2px',
    borderRadius: 15
  },
  buttonDiv: {
    backgroundColor: 'white',
    borderRadius: 50,
    marginBottom: 5,
    boxShadow: '1px 1px rgba(0,0,0,0.2)'
  }
}))

type menuButton = {
  label: string
  icon: any
  action: () => void
}

type menuButtonProps = {
  button: menuButton
  color: string
}

type nodeMenuProps = {
  open: boolean
  mapping: Record<string, any>
  removeNode: () => void
  editNode: () => void
  cloneNode: () => void
}

function MenuButton ({ button, color }: menuButtonProps): JSX.Element {
  const Icon = button.icon
  const classes = useStyles()
  return (
    <div className={classes.buttonDiv}>
      <Tooltip title={button.label}>
        <IconButton onClick={button.action} style={{ padding: 2 }}>
          <Icon style={{ fill: color }} />
        </IconButton>
      </Tooltip>
    </div>
  )
}

export function NodeMenu (props: nodeMenuProps): JSX.Element {
  const classes = useStyles()
  const mapping = props.mapping
  const menuOptions = mapping.menuOptions

  const buttonList = [
    { label: 'Delete', icon: DeleteIcon, action: props.removeNode, kind: 'delete' },
    { label: 'Edit', icon: EditIcon, action: props.editNode, kind: 'edit' },
    { label: 'Clone', icon: FileCopyIcon, action: props.cloneNode, kind: 'clone' }
  ]

  const style = props.open ? { transform: 'translateX(-10px)', opacity: 1 } : { transform: 'translate(0px)' }

  const buttons = menuOptions ? buttonList.filter(b => menuOptions.includes(b.kind)) : buttonList

  return (
    <div className={classes.wrapper} id='wrapper' style={style}>
      {buttons.map((button, index) => (
        <MenuButton
          button={button}
          key={index}
          color={mapping.color}
        />
      ))}
    </div>
  )
}
