import { makeStyles } from '@material-ui/core/styles'
import { RadioButtons } from 'library/materialUI'

const useStyles = makeStyles(theme => ({
  popupContainer: {
    width: '313px'
  },
  innerContainer: {
    margin: '20px'
  }
}))

interface MatchComparatorProps {
  operator: string
  setOperator: (value: string) => void
}

export default function MatchComparator ({ operator, setOperator }: MatchComparatorProps): JSX.Element {
  const classes = useStyles()
  const matchComparisons = [
    { value: 'eq', label: 'complete match' }
  ]

  return (
    <div>
      <div className={classes.innerContainer}>
        <RadioButtons
          buttons={matchComparisons}
          handleChange={(value: string) => setOperator(value)}
          value={operator}
        />
      </div>
    </div>
  )
}
