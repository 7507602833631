import { CRMFeature } from 'classes/crm'
import { iconMapping } from 'library/materialUI/Icon'
import React from 'react'

const FEATURES = {
  CRMABE: 'crm.abe',
  CRMENROLL: 'crm.create_contact'
}

interface CRMProviderInterface {
  crmIntegrationName: string,
  crmIntegrationLabel: string,
  crmIntegrationLogo: string,
  loadingCRM: boolean
}

const CRMContext = React.createContext<CRMProviderInterface>({
  crmIntegrationName: '',
  crmIntegrationLabel: '',
  crmIntegrationLogo: '',
  loadingCRM: true
})

function CRMProvider ({ children } : { children?: JSX.Element | JSX.Element[] }) {
  const { data: crmIntegrationData, isLoading } = CRMFeature.loadOne(FEATURES.CRMABE)
  const crmIntegration = crmIntegrationData
  const providerValues: CRMProviderInterface = {
    crmIntegrationName: crmIntegration?.integration?.name || '',
    crmIntegrationLabel: crmIntegration?.integration?.label || '',
    crmIntegrationLogo: iconMapping[crmIntegration?.integration?.name] || 'crmAttributesIcon',
    loadingCRM: isLoading
  }

  return (
    <CRMContext.Provider value={providerValues}>
      {children}
    </CRMContext.Provider>
  )
}

export { CRMContext, CRMProvider }
