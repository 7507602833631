import { makeStyles } from '@material-ui/core/styles'
import { Icon, Typography } from 'library/materialUI';
import { SegmentType } from 'classes/segments';

const useStyles = makeStyles(theme => ({
  iconDiv: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '50%',
    padding: 12,
    cursor: 'pointer',
    paddingBottom: 45
  },
  selected: {
    background: '#E2E2E2',
    color: theme.palette.primary.main,
    border: '.5px #D3D3D3 solid',
    borderRadius: 5
  },
  notSelected: {
    color: theme.palette.primary.main,
    border: '.5px #D3D3D3 solid',
    borderRadius: 5
  },
  titleText: {
    fontSize: '1em',
    color: theme.palette.primary.main,
    fontWeight: 'bold'
  },
  notSelectedText: {
    fontSize: '1em',
    color: theme.palette.primary.main,
    fontWeight: 'bold'
  },
  primaryColor: {
    color: theme.palette.primary.main
  }
}))

interface SegmentTypeButtonsProps {
  handleClick: (objectValue: string, segmentType: SegmentType) => void,
  segmentType: SegmentType
}

export default function SegmentTypeButtons ({ handleClick, segmentType } : SegmentTypeButtonsProps) {
  const classes = useStyles()
  const dynamicSelected = segmentType === 'dynamic'
  const dynmaicSegmentText = 'Create a segment that automatically updates with new accounts based on company attributes you set'
  const staticSegmentText = 'Create a segment by manually adding accounts from your CRM or uploading a CSV'
  const textProps = { align: 'center' as const, variant: 'body2' as const, style: { fontSize: '12px' } }
  const selectedDiv = `${classes.iconDiv} + ${classes.selected}`
  const notSelectedDiv = `${classes.iconDiv} + ${classes.notSelected}`

  return (
    <div style={{ display: 'flex' }}>
      <div
        className={dynamicSelected ? selectedDiv : notSelectedDiv}
        style={{ marginRight: 10 }}
        onClick={() => handleClick('segmentType', 'dynamic')}
      >
        <Icon icon='refresh' size='xxlg' color='primary' />
        <Typography className={dynamicSelected ? classes.titleText : classes.notSelectedText}> Dynamic Segment </Typography>
        <Typography {...textProps} className={dynamicSelected ? classes.titleText : classes.notSelectedText}> {dynmaicSegmentText} </Typography>
      </div>
      <div
        className={!dynamicSelected ? selectedDiv : notSelectedDiv}
        onClick={() => handleClick('segmentType', 'static')}
      >
        <Icon icon='listAdd' size='xxlg' color='primary' />
        <Typography className={!dynamicSelected ? classes.titleText : classes.notSelectedText}> Static Segment </Typography>
        <Typography {...textProps} className={!dynamicSelected ? classes.titleText : classes.notSelectedText}> {staticSegmentText} </Typography>
      </div>
    </div>
  )
}
