import React, { useContext, useState } from 'react'
import { SessionContext } from '../../session-context'
import { Redirect } from 'react-router-dom'
import RegistrationTemplate from './RegistrationTemplate'
import RegistrationFromInviteSidebar from './RegistrationFromInviteSidebar'
import queryString from 'query-string'
import SSOButtons from './SSOButtons/SSOButtons'

const RegistrationFromInvitePage = props => {
  const [loggedIn, setLoggedIn] = useState(false)
  const [emailSwapState, setEmailSwapState] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const { setUser } = useContext(SessionContext)

  const performEmailSwap = values => {
    setEmailSwapState('swapping')
    const parameters = queryString.parse(props.location.search)

    const body = {
      data: {
        type: 'user_invites',
        id: parameters.invite,
        attributes: {
          email: values.email,
          authorization: {
            token: parameters.token
          }
        },
        relationships: {}
      }
    }
    fetch(`/api/user_invites/${parameters.invite}/email_swap`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/vnd.api+json' },
      body: JSON.stringify(body)
    })
      .then(response => response.json())
      .then(response => {
        if (response.data.success) {
          setEmailSwapState('complete')
        }
      })
  }

  const performRegistration = values => {
    setLoading(true)
    const parameters = queryString.parse(props.location.search)

    const body = {
      data: {
        type: 'user_invites',
        id: parameters.invite,
        attributes: {
          phone: values.phone,
          name: values.name,
          password: values.password,
          authorization: {
            token: parameters.token
          }
        },
        relationships: {}
      }
    }

    fetch(`/api/user_invites/${parameters.invite}/redeem`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/vnd.api+json' },
      body: JSON.stringify(body)
    })
      .then(response => response.json())
      .then(response => {
        if (response.data.error === 'email_duplicate') {
          setEmailSwapState('needed')
          setLoading(false)
        } else if (response.data.error === 'invalid_token') {
          setLoading(false)
          setError('Your invitation has expired. Please ask your account administrator for a new one.')
        } else if (response.data.id) {
          setUser(response.data)
          setLoggedIn(true)
          setLoading(false)
        }
      })
  }

  if (loggedIn) {
    const dashboard = '/dashboard'
    return <Redirect to={dashboard} />
  }

  return (
    <RegistrationTemplate>
      <RegistrationFromInviteSidebar
        onSubmit={performRegistration}
        loading={loading}
        emailSwapState={emailSwapState}
        performEmailSwap={performEmailSwap}
      />
      {error ? (
        <div style={{ color: 'red' }}>
          {error}
        </div>
      )
        : <></>}
      <SSOButtons />
    </RegistrationTemplate>
  )
}

export default RegistrationFromInvitePage
