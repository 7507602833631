import { Modal, ModalSection } from 'library/Modal'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import { useState } from 'react'

const useStyles = makeStyles((theme) => ({
  meetingForm: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column'
  },
  field: {
    marginBottom: 20
  },
  formControl: {
    '& label': {
      backgroundColor: 'white'
    }
  },
  invalidInput: {
    color: 'red',
    fontSize: 12,
    marginTop: '-15px',
    marginBottom: 15
  },
  textField: {
    cursor: 'text',
    width: '100%'
  },
  groupContainer: {
    backgroundColor: 'rgba(193, 166, 237, 0.1)',
    padding: 10,
    borderRadius: 10
  },
  subtitle1: {
    fontSize: 18,
    fontWeight: 600
  },
  bodyText: {
    marginBottom: 10,
    fontSize: '14px'
  },
  menuHeader: {
    borderBottom: '1px solid #BBB',
    width: '100%',
    fontWeight: 600,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: 'rgba(0,0,0,0.87)'
  }
}))

export const HighIntentCategoryModal = (props: any) => {
  const classes = useStyles()
  const [pageType, setPageType] = useState('')

  const newCategory = props.onSubmit

  function addCategory () {
    newCategory(pageType)
      .then(response => response)
  }

  return (
    <Modal
      title='New High-Intent Page Category'
      onHide={() => {
        props.onHide()
      }}
      open={props.open}
      handleSave={() => {
        addCategory()
        setPageType('')
      }}
      size='sm'
      saveBtnText='Create Category'
      saveIcon='add'
      saveDisabled={!pageType}
    >
      <ModalSection title='Category Name'>
        <TextField
          className={classes.textField}
          margin='dense'
          color='primary'
          variant='outlined'
          type='text'
          placeholder='Type category name here, ex: Pricing'
          value={pageType}
          onChange={e => setPageType(e.target.value)}
        />
      </ModalSection>
    </Modal>
  )
}
