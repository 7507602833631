declare global {
  interface Window { ChatFunnels: any; }
}

function closeInAppBots () {
  window.ChatFunnels._internal.closeBots()
}

export {
  closeInAppBots
}
