import OktaLoginPage from './OktaLoginPage';
import RegistrationTemplate from './RegistrationTemplate';

export default function AlternateLoginPage () {
  return (
    <RegistrationTemplate>
      <OktaLoginPage />
    </RegistrationTemplate>
  )
}
