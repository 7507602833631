import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Fab from '@material-ui/core/Fab'
import DateFormat from './DateFormat'
import Tooltip from '@material-ui/core/Tooltip'

const eventStyles = makeStyles(theme => ({
  contactEvent: {
    display: 'flex',
    paddingLeft: 10,
    width: '100%'
  },
  eventDetails: {
    height: 30,
    paddingLeft: 10,
    fontFamily: 'Poppins, sans serif',
    fontWeight: 500
  },
  iconDiv: {
    height: 42
  },
  eventTitle: {
    fontSize: '14px',
    lineHeight: '19px',
    maxHeight: 32,
    overflowY: 'hidden',
    paddingTop: 3
  },
  alternateTitle: {
    fontSize: '13px',
    lineHeight: '15px',
    maxHeight: 32,
    overflowY: 'hidden',
    textOverflow: 'ellipsis',
    margin: -2
  },
  eventDate: {
    display: 'flex',
    color: '#9B9B9B',
    fontSize: '12px'
  },
  alternateDate: {
    display: 'flex',
    color: '#9B9B9B',
    fontSize: '11px'
  },
  iconImage: {
    height: 20,
    width: 20
  },
  eventIcon: {
    height: 42,
    width: 42,
    border: '2px solid #8E5AE2',
    '&$disabled': {
      backgroundColor: '#FFF'
    }
  },
  disabled: {
  }
}))

function TrackingEvent (props) {
  const classes = eventStyles()

  const longTitle = props.title.length > 40

  return (
    <Tooltip title='' placement='left'>
      <div className={classes.contactEvent}>
        <div className={classes.iconDiv}>
          <Fab
            disabled
            classes={{
              root: classes.eventIcon,
              disabled: classes.disabled
            }}
            style={{ border: '2px solid ' + props.color }}
          >
            {props.imgSrc === ''
              ? <props.icon style={{ color: props.color }} />
              : <img src={props.imgSrc} className={classes.iconImage} alt={props.title} />}
          </Fab>
        </div>
        <div className={classes.eventDetails}>
          <div className={longTitle ? classes.alternateTitle : classes.eventTitle} style={{ color: props.color }}>
            {props.title}
          </div>
          <div className={longTitle ? classes.alternateDate : classes.eventDate}>
            <DateFormat
              dateToFormat={props.timestamp}
              format='mm/dd/yyyy | h:MM TT'
            />
            {props.source ? (<><div style={{ marginLeft: 3, marginRight: 3 }}>•</div>{props.source}</>) : <></>}
          </div>
        </div>
      </div>
    </Tooltip>
  )
}

export default TrackingEvent
