import { useState, useRef, useEffect } from 'react'
import SendIcon from '@material-ui/icons/Send'
import { sendTypingEvent, sendEvent } from 'api/conversation_events'
import './message.css'
import { RichTextEditor } from 'library/richText/RichTextEditor'
import { ReactComponent as InnerChatIcon } from 'img/Icon material-rate-review.svg'
import { isTyping } from '../../agent-chat/groupEvents'
import TypingIndicator from '../../agent-chat/TypingIndicator'

const sendTypingMessage = ({ message, conversation, value, avatarURL }) => {
  const chatServiceUrl = window.chatServiceUrl
  const conversationID = conversation.id
  const oldText = message.replace('<br>', '')
  const newText = (value).replace('<br>', '').replace('<p></p>', '')
  if ((oldText === '' || oldText === '<p></p>') && newText !== '') {
    sendTypingEvent({ chatServiceUrl, conversationID, kind: 'type', avatarURL })
  } else if ((newText === '' || newText === '<p></p>') && oldText !== '' && !oldText.includes('<p></p>')) {
    sendTypingEvent({ chatServiceUrl, conversationID, kind: 'halt' })
  }
}

const formatMessage = (msg) => {
  let text = msg
  if (msg.replace('<p>', '').replace('</p>', '').replace('<br>', '').length < 1) {
    return ''
  }
  if (msg.endsWith('<p><br></p>')) {
    text = msg.replace('<p><br></p>', '')
  }
  return text
}

function SavedRepliesButton (props) {
  const setRepliesWindow = props.setRepliesWindow

  return (
    <div
      style={{ width: '32px', height: '32px', borderRadius: '16px', backgroundColor: '#9933FF', display: 'flex', alignItems: 'center' }}
      onClick={() => setRepliesWindow(true)}
    >
      <InnerChatIcon style={{ marginLeft: '8px' }} />
    </div>
  )
}

function MessageEntry (props) {
  const [message, setMessage] = useState('')
  const setParentMessage = props.setParentMessage
  const chatServiceUrl = window.chatServiceUrl
  const state = props.state
  const events = state.events
  const participant = props.participant
  const conversation = props.conversation
  const conversationID = conversation.id
  const editorRef = useRef(null)
  const setRepliesWindow = props.setRepliesWindow
  const typing = isTyping(state.typing, participant)

  useEffect(() => {
    if (props.message !== '' && message !== props.message) {
      setMessage(props.message)
      setParentMessage('')
    }
  }, [message, setMessage, props, setParentMessage])

  function contentHTMLChanged (value) {
    const avatarURL = props.user.attributes.profile_picture_url
    sendTypingMessage({ message, conversation, value, avatarURL })
    setMessage(value)
  }

  const sendInputChat = () => {
    const body = formatMessage(message)
    if (!body) { return }
    const attributes = { kind: 'chat', body, data: {} }
    sendEvent({ chatServiceUrl, conversationID, attributes })
    setMessage('')
  }

  // const keyPressed = event => {
  //   if (event.key === 'Enter' && event.shiftKey) {
  //     return
  //   }
  //   if (event.key === 'Enter') {
  //     sendInputChat()
  //     setMessage('')
  //   }
  // }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', padding: (typing.length > 0) ? '0px 20px 20px 15px' : '15px 20px 20px 15px', boxShadow: '0px -5px 4px #88888850' }}>
      <TypingIndicator
        typing={typing}
        conversation={conversation}
        lastEvent={events[events.length - 1]}
        currentID={participant ? participant.id : undefined}
      />
      <div style={{ display: 'grid', gridTemplateColumns: '32px auto 30px', gridGap: 10 }}>
        <div
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <SavedRepliesButton setRepliesWindow={setRepliesWindow} />
        </div>
        <div className='mobile-text-entry' id='message-entry'>
          <RichTextEditor
            message={message}
            editorRef={editorRef}
            onChange={contentHTMLChanged}
            noToolbar
            placeholder='Type your message...'
          />
        </div>
        <div
          style={{ display: 'flex', alignItems: 'center' }}
          onClick={() => sendInputChat()}
        >
          <SendIcon
            style={{ fill: '#9933FF' }}
          />
        </div>
      </div>
    </div>
  )
}

export default MessageEntry
