import React, { useState, useEffect, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import ReactDOM from 'react-dom'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { getTenant, saveTenant } from 'api/tenants'
import { SessionContext } from 'session-context'
import AppPage from 'cf-components/AppPage'
import SettingsCard from 'cf-components/SettingsCard'

const useStyles = makeStyles((theme) => ({
  securityPage: {
    padding: 20
  },
  textDiv: {
    display: 'flex',
    alignItems: 'center'
  },
  dropDown: {
    marginLeft: 8,
    marginRight: 8,
    marginTop: -2,
    color: '#3c3c3c'
  }
}))

const SessionSettings = [
  { name: '15 minutes', value: 15 },
  { name: '30 minutes', value: 30 },
  { name: '1 hour', value: 1 * 60 },
  { name: '4 hours', value: 4 * 60 },
  { name: '1 day', value: 24 * 60 },
  { name: '3 days', value: 72 * 60 },
  { name: '1 week', value: 168 * 60 }
]

const LockTimes = [
  { name: '15 minutes', value: 15 },
  { name: '30 minutes', value: 30 },
  { name: '1 hour', value: 60 },
  { name: '2 hours', value: 120 },
  { name: '4 hours', value: 240 },
  { name: '8 hours', value: 480 },
  { name: '24 hours', value: 1440 }
]

const AllowedTimes = [
  { name: '3 attempts', value: 3 },
  { name: '4 attempts', value: 4 },
  { name: '5 attempts', value: 5 },
  { name: '6 attempts', value: 6 },
  { name: '7 attempts', value: 7 },
  { name: '8 attempts', value: 8 },
  { name: '9 attempts', value: 9 },
  { name: '10 attempts', value: 10 }

]

function UserPage ({ match }) {
  const classes = useStyles()
  const [sessionLength, setSessionLength] = useState(15)
  const [attemptsAllowed, setAttemptsAllowed] = useState(10)
  const [lockAccountTime, setLockAccountTime] = useState(15)
  const [mfaRequirement, setMfaRequirement] = useState(null)
  const [disabled, setDisabled] = useState(true)
  const { user } = useContext(SessionContext)

  function saveSettings () {
    const attributes = {
      session_length_minutes: sessionLength,
      lock_time: lockAccountTime,
      attempts_allowed: attemptsAllowed,
      mfa_requirement: mfaRequirement
    }

    const tenantID = user.relationships.tenant.data.id
    saveTenant({ tenantID, attributes }).then(() => {
      setDisabled(true)
    })
  }

  function handleMFACheck (event) {
    setDisabled(false)
    if (event.target.checked) {
      setMfaRequirement('required')
    } else {
      setMfaRequirement('optional')
    }
  }

  useEffect(() => {
    if (user) {
      const tenantID = user.relationships.tenant.data.id
      getTenant({ tenantID }).then(response => {
        ReactDOM.unstable_batchedUpdates(() => {
          setSessionLength(response.data.attributes.session_length_minutes)
          setAttemptsAllowed(response.data.attributes.attempts_allowed)
          setLockAccountTime(response.data.attributes.lock_time)
          setMfaRequirement(response.data.attributes.mfa_requirement)
        })
      })
    }
  }, [user])

  const saveProps = {
    disabled: disabled,
    save: saveSettings,
    saveText: 'Save Settings'
  }

  document.title = 'Security | Signals'

  return (
    <AppPage
      title='Security'
      saveProps={saveProps}
    >
      <div className={classes.securityPage}>
        <SettingsCard title='Session Timeouts' subtitle='Automatically log out idle users'>
          <div style={{ display: sessionLength ? 'flex' : 'none' }}>
            <div className={classes.textDiv}>
              Idle users will be logged out after
            </div>
            <Select
              className={classes.dropDown}
              margin='dense'
              value={sessionLength}
              onChange={e => {
                setDisabled(false)
                setSessionLength(e.target.value)
              }}
            >
              {SessionSettings.map((setting, index) => (
                <MenuItem value={setting.value} key={index}>{setting.name}</MenuItem>
              ))}
            </Select>
            <div className={classes.textDiv}>
              of inactivity.
            </div>
          </div>
        </SettingsCard>
        <SettingsCard title='Login Attempts' subtitle="Configure your account's login policy">
          <div style={{ display: attemptsAllowed ? 'flex' : 'none' }}>
            <div className={classes.textDiv}>
              If the user is not able to log in after
            </div>
            <Select
              className={classes.dropDown}
              margin='dense'
              value={attemptsAllowed}
              onChange={e => {
                setDisabled(false)
                setAttemptsAllowed(e.target.value)
              }}
            >
              {AllowedTimes.map((time, index) => (
                <MenuItem value={time.value} key={index}>{time.name}</MenuItem>
              ))}
            </Select>
            <div className={classes.textDiv}>
              the account should be locked for
            </div>
            <Select
              className={classes.dropDown}
              margin='dense'
              value={lockAccountTime}
              onChange={e => {
                setDisabled(false)
                setLockAccountTime(e.target.value)
              }}
            >
              {LockTimes.map((time, index) => (
                <MenuItem value={time.value} key={index}>{time.name}</MenuItem>
              ))}
            </Select>
          </div>
        </SettingsCard>
        <SettingsCard title='Multi-factor Authentication' subtitle='Users will receive a code via text message or email when logging in'>
          <FormControlLabel
            control={
              <Checkbox
                checked={mfaRequirement === 'required'}
                onChange={handleMFACheck}
                color='primary'
              />
            }
            label='Require multi-factor authentication for all users'
          />
        </SettingsCard>
      </div>
    </AppPage>
  )
}

export default UserPage
