import Skeleton from '@material-ui/lab/Skeleton';
import { SkeletonSize, SkeletonVariant } from './SkeletonLoader';

interface SkeletonNameIconAndSubtitleProps {
  size?: SkeletonSize
  variant?: SkeletonVariant
}

type NameIconAndSubLoaders = 'text1' | 'circle' | 'text2'
type LoaderParams = 'height' | 'width'
type MappingType = Record<SkeletonSize, Record<NameIconAndSubLoaders, Record<LoaderParams, string>>>

export default function SkeletonNameIconAndSubtitle ({ size = 'md', variant = 'circle' } : SkeletonNameIconAndSubtitleProps) {
  const mapping: MappingType = {
    sm: {
      text1: { height: '1em', width: '5em' },
      circle: { height: '1em', width: '1em' },
      text2: { height: '1em', width: '6em' }
    },
    md: {
      text1: { height: '1em', width: '6em' },
      circle: { height: '1.5em', width: '1.5em' },
      text2: { height: '1em', width: '5em' }
    },
    lg: {
      text1: { height: '1em', width: '7em' },
      circle: { height: '2em', width: '2em' },
      text2: { height: '1em', width: '6em' }
    }
  }
  const { text1, circle, text2 } = mapping[size]
  return (
    <div>
      <div style={{ display: 'flex', margin: '5px 0' }}>
        <Skeleton variant='text' {...text1} />
        <Skeleton variant={variant} {...circle} style={{ marginLeft: 5 }} />
      </div>
      <Skeleton variant='text' {...text2} />
    </div>
  )
}
