import { makeStyles, styled } from '@material-ui/core/styles'
import { Icon } from 'library/materialUI/Icon'
import Paper from '@material-ui/core/Paper'
import { Tabs, Tab } from '@material-ui/core'
import { Link, useLocation, Route } from 'react-router-dom'
import theme from 'theme'
import { ActionButton } from 'library/materialUI'
import StyledSwitch from 'components/StyledSwitch'

const useStyles = makeStyles(theme => ({
  routerPage: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 15,
    height: 50
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    textTransform: 'uppercase',
    fontSize: '15px',
    marginRight: 20,
    letterSpacing: 0,
    lineHeight: '25px',
    fontWeight: 600,
    color: '#555'
  },
  pageNames: {
    display: 'flex'
  },
  selectedLink: {
    textDecoration: 'none',
    color: theme.palette.primary.main
  },
  unselectedLink: {
    textDecoration: 'none',
    color: 'rgba(0,0,0,0.54)'
  },
  paper: {
    boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2)',
    marginTop: -8
  },
  tab: {
    minWidth: 0,
    minHeight: 32,
    lineHeight: 1.5,
    textTransform: 'none',
    fontSize: '0.8rem'
  },
  actionDiv: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: 15,
    paddingRight: 10,
    position: 'absolute',
    top: 0,
    right: 20
  }
}))

export const HiddenLink = styled(Link)({
  textDecoration: 'none',
  color: 'inherit'
})

function PageTitle (props) {
  const location = useLocation()
  const classes = useStyles()
  const selected = location.pathname.includes(props.title)
  const color = selected ? 'primary' : '#808080'

  const baseTab = Object.keys(props.pageConfig.tabs)[0]
  return (
    <HiddenLink to={props.baseLocation + '/' + props.title + '/' + baseTab}>
      <div className={classes.title} id={`${props.title}-page-title`}>
        <Icon
          icon={props.icon}
          color={color}
        />
        <div style={{ marginLeft: 3, color: color === 'primary' ? theme.palette.primary.main : undefined }}>
          {props.title}
        </div>
      </div>
    </HiddenLink>
  )
}

function CustomTab (props) {
  const classes = useStyles()
  const { t, tab, index, baseUrl } = props

  const url = baseUrl + '/' + t

  return (
    <Link to={url} className={index === tab ? classes.selectedLink : classes.unselectedLink}>
      <Tab label={t} style={{ opacity: 1 }} classes={{ root: classes.tab }} />
    </Link>
  )
}

const getRoutes = (pages, baseRoute) => {
  const routes = []
  for (const key in pages) {
    const tabs = pages[key].tabs
    for (const tab in tabs) {
      const path = baseRoute + '/' + key + '/' + tab
      const component = tabs[tab].component
      routes.push({ path, component })
    }
  }

  return routes
}

export function ActionDiv (props) {
  const classes = useStyles()
  if (props.action) {
    return (
      <div className={classes.actionDiv}>
        <div style={{ marginRight: 15 }}>{props.action.label}</div>
        {props.action.isToggle
          ? <StyledSwitch {...props.action} />
          : <ActionButton action={props.action} disabled={props.disabled} tootltip={props.tooltip} />}
      </div>
    )
  }

  return (
    <div />
  )
}

export function RouterPage (props: any) {
  const classes = useStyles()

  let baseUrl = ''
  const location = useLocation()
  const pathParts = location.pathname.substring(1).split('/')

  const page = pathParts[1]
  const tabDict = props.pages[page]?.tabs || {}
  const tabs = Object.keys(tabDict)

  const final = pathParts[pathParts.length - 1]
  if (tabs.includes(final)) {
    pathParts.pop()
    baseUrl = '/' + pathParts.join('/')
  } else {
    baseUrl = '/' + pathParts.join('/')
  }

  const tabNames = tabs.map(t => baseUrl + '/' + t)
  const index = tabNames.indexOf(location.pathname)
  const tab = index === -1 ? 0 : index

  const routes = getRoutes(props.pages, '/engagement')

  return (
    <div style={{ position: 'relative' }}>
      <div>
        <div className={classes.routerPage}>
          <div className={classes.pageNames}>
            {Object.keys(props.pages).map((page, index) => (
              <PageTitle
                title={page}
                icon={props.pages[page].icon}
                key={index}
                pageConfig={props.pages[page]}
                baseLocation='/engagement'
              />))}
          </div>
        </div>
        <Paper className={classes.paper}>
          <Tabs value={tab} indicatorColor='primary' textColor='primary' classes={{ root: classes.tab }}>
            {
              tabs.map((t, index) => (
                <CustomTab t={t} key={index} tab={tab} index={index} baseUrl={baseUrl} />
              ))
            }
          </Tabs>
        </Paper>
      </div>
      <div style={{ height: 'calc(100vh - 80px)', overflow: 'hidden' }}>
        {routes.map((route, index) => (
          <Route exact path={route.path} render={() => route.component} key={index} />
        ))}
      </div>
    </div>
  )
}
