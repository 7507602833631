import SegmentDefaultPage from 'pages/segments/SegmentDefaultPage'

const SegmentPage = ({ match }: any) => {
  const segmentID = match.params.segment_id

  return (
    <SegmentDefaultPage segmentID={segmentID} objectType='accounts' />
  )
}

export default SegmentPage
