import React, { useEffect, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import DashboardCard from 'pages/dashboard/DashboardCard'
import Tooltip from '@material-ui/core/Tooltip'
import { Button, Icon } from 'library/materialUI'
import { getAccountInsights } from 'api/account_management'
import theme from 'theme'
import Loader from 'library/loading/Loader'
import Markdown from 'react-markdown'

const useStyles = makeStyles(theme => ({
  scrollContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    position: 'relative'
  },
  questionButton: {
    margin: '20px 20px 0px 0px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start'
  },
  questionContainer: {
    display: 'flex',
    flexDirection: 'row',
    overflowX: 'auto',
    whiteSpace: 'nowrap',
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    scrollbarWidth: 'none'
  },
  questionItem: {
    flex: '0 0 auto'
  },
  fadeLeft: {
    position: 'absolute',
    height: '100%',
    left: 0,
    paddingTop: 28,
    paddingRight: 20,
    background: 'linear-gradient(to left, #ffffff00 , #ffffffff 60%)',
    cursor: 'pointer',
    zIndex: 1000
  },
  fadeRight: {
    position: 'absolute',
    height: '100%',
    right: 0,
    paddingTop: 28,
    paddingLeft: 20,
    background: 'linear-gradient(to right, #ffffff00, #ffffffff 60%)',
    cursor: 'pointer',
    zIndex: 1000
  },
  markdownStyles: {
    whiteSpace: 'pre-wrap',
    maxHeight: 400,
    overflowY: 'auto',
    marginTop: 16,
    paddingBottom: 20,
    '& p': {
      margin: '0 0 -20px 0',
      lineHeight: 1.3,
      fontSize: '16px'
    },
    '& ol, & ul': {
      padding: '0 0 0 20px',
      lineHeight: 1
    },
    '& ol': {
      listStyleType: 'decimal'
    },
    '& ul': {
      listStyleType: 'disc'
    },
    '& li': {
      fontSize: '16px',
      marginBottom: '4px',
      '& ul': {
        listStyleType: 'circle'
      }
    }
  },
  logoContainer: {
    display: 'flex',
    marginRight: 10,
    flexShrink: 0,
    border: '1px solid #d3d3d3',
    borderRadius: '50%',
    width: 45,
    height: 45,
    alignItems: 'center',
    justifyContent: 'center'
  }
}))

interface AccountInsightsCardProps {
  domain: string
}

function AccountInsightsCard ({ domain }: AccountInsightsCardProps) {
  const classes = useStyles()
  const defaultQuestions = [
    'What pages are visitors from this company spending the most time on?',
    'What are the most frequently visited pages for visitors from this company?',
    'What topics have visitors from this company shown the most interest in?',
    'What are the most common traffic sources for visitors from this company?',
    'How many active contacts are there currently for this company?',
    'How frequently does this company visit the website?',
    // 'What is the usual navigation path taken by visitors from this company?', // back end not ready for this question
    'How much time has this company spent on High-Intent pages?',
    // 'How many identified visitors from this company are repeat visitors, and how often do they return?', // back end not ready for this question
    'What are common job titles for visitors from this company?'
    // 'How many contacts discovered through Contact Lookup have visited the site?', // back end not ready for this question
    // 'How does this company\'s activity compare to the activity of companies with a higher Signals Score?' // back end not ready for this question
  ]
  const [insightHtml, setInsightHtml] = useState<string>('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [retryTime, setRetryTime] = useState<number | null>(null)
  const retryTimeoutRef = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    if (retryTime) {
      setError(`We are receiving a high volume of requests. We will try again in ${retryTime} seconds.`)
      const interval = setInterval(() => {
        setRetryTime(prevTime => prevTime ? prevTime - 1 : null)
      }, 1000)
      return () => clearInterval(interval)
    }
  }, [retryTime])

  // Scroll Handling
  const [showLeftScroll, setShowLeftScroll] = useState(false)
  const [showRightScroll, setShowRightScroll] = useState(true)

  function updateScrollButtonVisibility () {
    const container = document.getElementById('questions')
    if (container) {
      const maxScrollLeft = container.scrollWidth - container.clientWidth - 25
      setShowLeftScroll(container.scrollLeft > 0)
      setShowRightScroll(container.scrollLeft < maxScrollLeft)
    } else {
      setShowLeftScroll(false)
      setShowRightScroll(false)
    }
  }

  const handleScroll = (direction: 'left' | 'right') => {
    const container = document.getElementById('questions')
    if (!container) return

    const scrollAmount = 600
    const currentScroll = container.scrollLeft

    const newScrollPosition = direction === 'left' ? currentScroll - scrollAmount : currentScroll + scrollAmount

    container.scrollTo({
      left: newScrollPosition,
      behavior: 'smooth'
    })
  }

  const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))

  async function handleQuestionClick (question: string, retryCount = 3) {
    setInsightHtml('')
    setLoading(true)
    setError(null)

    try {
      let buffer = ''
      const decoder = new TextDecoder()
      const streamReader = await getAccountInsights({ domain, question })
      while (true) {
        const { done, value } = await streamReader.read()
        if (done) {
          break
        }
        buffer += decoder.decode(value, { stream: true })

        const parts = buffer.split('<<splitHere>>')
        // Keep the last part in the buffer as it might be incomplete
        buffer = parts.pop() || ''
        for (const part of parts) {
          const parsedObject = JSON.parse(part)
          setInsightHtml(prevHtml => prevHtml + parsedObject.data.attributes.insight)
          await delay(20) // Delay to allow incremental rendering
          setLoading(false)
        }
      }
    } catch (err) {
      const retrySeconds = Math.pow(2, retryCount)
      setRetryTime(retrySeconds)
      retryTimeoutRef.current = setTimeout(() => {
        handleQuestionClick(question, retryCount + 1)
      }, retrySeconds * 1000)
    }
  }

  const logoAI = (
    <div className={classes.logoContainer}>
      <Icon icon='logo' color={theme.palette.primary.alt} />
      <div style={{ marginTop: -20 }}>
        <Icon icon='aiSparkles' />
      </div>
    </div>
  )

  return (
    <DashboardCard
      title={
        <div style={{ display: 'flex' }}>
          Account Insights
          <Tooltip title='Powered by Artificial Intelligence'>
            <div>
              <Icon icon='aiSparkles' />
            </div>
          </Tooltip>
        </div>
      }
    >
      <>
        <div className={classes.scrollContainer}>
          {showLeftScroll && (
            <div style={{ position: 'relative' }}>
              <div className={classes.fadeLeft} onClick={() => handleScroll('left')}>
                <Icon icon='leftArrow' color='inherit' />
              </div>
            </div>
          )}
          <div className={classes.questionContainer} id='questions' onScroll={() => updateScrollButtonVisibility()}>
            {defaultQuestions.map((question, index) => (
              <div className={classes.questionItem} key={index}>
                <Button
                  key={index}
                  variant='outlined'
                  className={classes.questionButton}
                  startIcon={<Icon icon='aiSparkles' />}
                  style={{ textTransform: 'none' }}
                  onClick={() => handleQuestionClick(question)}
                >
                  {question}
                </Button>
              </div>
            ))}
          </div>
          {showRightScroll && (
            <div style={{ position: 'relative' }} onClick={() => handleScroll('right')}>
              <div className={classes.fadeRight}>
                <Icon icon='rightArrow' color='inherit' />
              </div>
            </div>
          )}
        </div>
        {loading ? (
          <div style={{ display: 'flex', alignItems: 'center', marginTop: 20 }}>
            {logoAI}
            <div>
              <Loader type='spinner' size='sm' />
            </div>
            {error && (
              <div style={{ marginLeft: 10 }}>{error}</div>
            )}
            <Button
              variant='outlined'
              style={{ textTransform: 'none', marginLeft: 20 }}
              onClick={() => {
                if (retryTimeoutRef.current) {
                  clearTimeout(retryTimeoutRef.current)
                }
                setRetryTime(null)
                setLoading(false)
                setInsightHtml('')
                setError(null)
              }}
            >
              Cancel
            </Button>
          </div>
        ) : insightHtml ? (
          <div style={{ display: 'flex', marginTop: 20 }}>
            {logoAI}
            <Markdown className={classes.markdownStyles}>
              {insightHtml}
            </Markdown>
          </div>
        ) : null}
      </>
    </DashboardCard>
  )
}

export default AccountInsightsCard
