import React from 'react';
import { Typography } from '@material-ui/core';

const ThemePage: React.FC = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center' }}>
      <div style={{ fontSize: 30, fontWeight: 600, textDecoration: 'underline', marginBottom: 15 }}> Font Sizes </div>
      <Typography style={{ marginBottom: 15 }} variant='h1'>Header 1</Typography>
      <Typography style={{ marginBottom: 15 }} variant='h2'>Header 2</Typography>
      <Typography style={{ marginBottom: 15 }} variant='h3'>Header 3</Typography>
      <Typography style={{ marginBottom: 15 }} variant='h4'>Header 4</Typography>
      <Typography style={{ marginBottom: 15 }} variant='h5'>Header 5</Typography>
      <Typography style={{ marginBottom: 15 }} variant='h6'>Header 6</Typography>
      <Typography style={{ marginBottom: 15 }} variant='subtitle1'>Subtitle 1</Typography>
      <Typography style={{ marginBottom: 15 }} variant='body1'>Body 1</Typography>
      <Typography style={{ marginBottom: 15 }} variant='body2'>Body 2</Typography>
    </div>
  );
};

export default ThemePage;
