import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { DateType } from 'classes/classHelpers'
// import { Icon } from "library/materialUI"

const useStyles = makeStyles(theme => ({
  optionContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 10,
    backgroundColor: '#F5F5F5',
    border: '1px solid #EBEBEB',
    borderRadius: 5
  },
  verifiedContainer: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#EDFAF7',
    borderRadius: 5,
    padding: 5
  }
}))

interface DetailOptionsProps {
  detailName: string
  current?: string | number | string[] | null
  updatedTimestamp?: string
}

function OptionRow (current: string | number | string[] | null, updatedTimestamp?: DateType): JSX.Element {
  const classes = useStyles()

  return (
    <div className={classes.optionContainer}>
      <div>
        {Array.isArray(current) ? current.join(', ') : current}
        <Typography variant='body2'>Last updated: {updatedTimestamp?.toLocaleString()}</Typography>
      </div>
      {/* <div>
        <SignalsVerified />
      </div> */}
    </div>
  )
}

// function SignalsVerified (): JSX.Element {
//   const classes = useStyles()
//   return (
//     <div className={classes.verifiedContainer}>
//       <Icon icon='verified' />
//       <Typography variant='body1' style={{ marginLeft: 5, color: '#47C59E' }}>Signals verified</Typography>
//     </div>
//   )
// }

export default function DetailOptions ({ detailName, current, updatedTimestamp }: DetailOptionsProps): JSX.Element {
  if (!current) {
    current = `No ${detailName} to show`
  }
  return (
    <div>
      {/* <div style={{ display: 'flex', justifyContent: 'space-between', padding: 10 }}>
        <Typography variant='h3'>{detailName}</Typography>
        <Typography variant='h3'>ORIGIN</Typography>
      </div> */}
      {OptionRow(current, updatedTimestamp)}
    </div>
  )
}
