/* eslint-disable @typescript-eslint/naming-convention */
import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import AvatarModal from './AvatarModal'
import MissingAvatar from '../../img/purple-avatar.svg'

const useStyles = makeStyles(theme => ({
  fileInput: {
    width: 150,
    color: 'transparent'
  },
  circleEditable: {
    borderRadius: '50%',
    height: 160,
    width: 160,
    overflow: 'hidden',
    cursor: 'pointer',
    border: 1
  },
  circle: {
    borderRadius: '50%',
    height: 160,
    width: 160,
    overflow: 'hidden',
    border: 1
  }
}))

interface ProfilePictureProps {
  linkedinPhoto: any
  url?: string
  editable: boolean
  user_id: string
  updateProfilePicture: (url: string) => void
}

function ProfilePicture ({ linkedinPhoto, url, editable, user_id, updateProfilePicture } : ProfilePictureProps) {
  const [modalShow, setModalShow] = useState(linkedinPhoto != null)
  const classes = useStyles()

  const handleImageClick = () => {
    setModalShow(true)
  }

  const displayUrl = url == null ? MissingAvatar : url

  return (
    <div>
      <img
        src={displayUrl}
        alt='User Avatar'
        className={editable ? classes.circleEditable : classes.circle}
        onClick={editable ? handleImageClick : () => ({})}
      />
      <AvatarModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        updateProfilePicture={updateProfilePicture}
        user_id={user_id}
        editable={editable}
      />
    </div>
  )
}

export default ProfilePicture
