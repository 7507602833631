import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'

export function CreateBarChart ({ data, id }: any) {
  am4core.addLicense('CH213476655')
  am4core.useTheme(am4themes_animated)

  const chart = am4core.create(id, am4charts.XYChart);

  chart.data = data

  const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
  categoryAxis.dataFields.category = 'category';
  categoryAxis.renderer.grid.template.location = 0;
  categoryAxis.renderer.minGridDistance = 30;

  categoryAxis.renderer.labels.template.adapter.add('dy', function (dy, target) {
    if (target.dataItem && target.dataItem.index && dy) {
      return dy + 25;
    }
    return dy;
  });

  chart.yAxes.push(new am4charts.ValueAxis());

  // Create series
  const series = chart.series.push(new am4charts.ColumnSeries());
  series.dataFields.valueY = 'value';
  series.dataFields.categoryX = 'category';
  series.name = 'Visits';
  series.columns.template.tooltipText = '{categoryX}: [bold]{valueY}[/]';
  series.columns.template.fillOpacity = 0.8;

  const columnTemplate = series.columns.template;
  columnTemplate.strokeWidth = 2;
  columnTemplate.strokeOpacity = 1;
}
