import React from 'react'
import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

const styles = {
  border: 'none',
  borderBottom: '1px solid',
  fontSize: 18,
  outline: 'none'

}

const defaultMaskOptions = {
  prefix: '$',
  suffix: ' USD',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ',',
  allowDecimal: true,
  decimalSymbol: '.',
  decimalLimit: 2,
  integerLimit: 12,
  allowNegative: false,
  allowLeadingZeroes: false
}

const CurrencyInput = ({ maskOptions, customStyles, ...inputProps }) => {
  const currencyMask = createNumberMask({
    ...defaultMaskOptions,
    ...maskOptions
  })

  const style = customStyles || styles

  return <MaskedInput mask={currencyMask} {...inputProps} style={style} />
}

export default CurrencyInput
