import { makeStyles } from '@material-ui/core/styles'
import { Tooltip, Typography } from '@material-ui/core'
import StyledSwitch from 'components/StyledSwitch'
import { Button, Icon, TextBox } from 'library/materialUI'

export const headerHeight = 60

const useStyles = makeStyles(theme => ({
  header: {
    height: 72,
    boxShadow: '0px 3px 6px #0000001A',
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1fr auto 1fr',
    padding: '0px 20px'
  },
  tabs: {
    display: 'flex',
    alignItems: 'center'
  },
  tab: {
    marginRight: 15,
    marginLeft: 15,
    cursor: 'pointer'
  },
  actionDiv: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  backButton: {
    height: 45,
    width: 45
  },
  title: {
    display: 'flex',
    alignItems: 'center'
  }
}))

export interface Tab {
  name: string
  onClick?: () => void
}

export type PageAction = {
  type: string
  action: () => void
  value: any
  label?: string
}

export type PageTitle = {
  name: string
  onClick?: () => void
  isEditMode?: boolean
  onChange?: (val: string | number) => void
  extra?: JSX.Element // Extra elements that can be displayed next to the title
}

interface PageHeaderProps {
  title: PageTitle
  tabs: Tab[]
  action?: PageAction
  back?: () => any
  subtitle?: string
}

function Tabs (props: { tabs: Tab[] }): JSX.Element {
  const classes = useStyles()
  return (
    <div className={classes.tabs}>
      {props.tabs.map((tab: Tab, index) => (
        <div
          onClick={tab.onClick}
          className={classes.tab}
          key={index}
        >
          <Typography variant='h3'>
            {tab.name}
          </Typography>
        </div>
      ))}
    </div>
  )
}

function ActionComponent (props: any): JSX.Element {
  const action = props.action
  if (action.type === 'toggle') {
    return (
      <StyledSwitch
        checked={action.value}
        toggle={() => action.action({ value: !props.value })}
      />
    )
  } else {
    return (<div />)
  }
}

function ActionWrapper (props: any): JSX.Element {
  const action = props.action
  if (action.label) {
    return (
      <Tooltip title={action.label}>
        <div>
          <ActionComponent action={props.action} />
        </div>
      </Tooltip>
    )
  } else {
    return (
      <ActionComponent action={props.action} />
    )
  }
}

export function PageHeader (props: PageHeaderProps): JSX.Element {
  const classes = useStyles()

  const handleChange = (val: string | number): void => {
    if (props.title.onChange !== undefined) {
      props.title.onChange(val)
    }
  }

  return (
    <div
      className={classes.header}
      id='page-header'
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {props.back ? (
          <Button
            variant='text'
            className={classes.backButton}
            onClick={() => { props.back() }}
          >
            <Icon icon='arrowBack' />
          </Button>
        )
          : <></>}
        <div
          onClick={props.title.onClick}
          style={{ cursor: props.title.onClick ? 'pointer' : 'default', width: 'fit-content' }}
        >
          {!props.title.isEditMode ? (
            <Typography className={classes.title} variant='h1'>
              {props.title.name} {props.title.extra}
            </Typography>
          ) : (
            <TextBox
              value={props.title.name}
              onChange={(e: string | number) => handleChange(e)}
              label='click save to update'
            />
          )}
          {props.subtitle ? (
            <Tooltip
              title={props.subtitle.length > 150 ? props.subtitle : ''}
              disableTouchListener
              disableFocusListener
              enterDelay={1000}
              placement='bottom'
            >
              <Typography variant='subtitle1' style={{ whiteSpace: 'nowrap' }}>
                {props.subtitle.length < 150 ? props.subtitle : `${props.subtitle.substring(0, 150)}...`}
              </Typography>
            </Tooltip>
          ) : <></>}
        </div>
      </div>

      <Tabs
        tabs={props.tabs}
      />
      {props.action &&
        <div className={classes.actionDiv}>
          <ActionWrapper
            action={props.action}
          />
        </div>}
    </div>
  )
}
