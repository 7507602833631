import React from 'react'
import { BasePortComponent } from 'jsplumbtoolkit-react'
import 'jsplumb'

class GenericPort extends BasePortComponent {
  render () {
    return (
      <div
        className={['nodrag']}
        style={{
          width: 32,
          height: 20,
          borderBottomRightRadius: 16,
          borderBottomLeftRadius: 16,
          backgroundColor: this.props.color,
          marginLeft: 'auto',
          marginRight: 'auto'
        }}
        data-port-id={this.state.id}
      >
        <jtk-source
          port-id={this.state.id}
          scope='default'
        />
      </div>
    )
  }
};

export default GenericPort
