import { nodeMapping } from 'pages/bots/docs/nodeMapping'
import { SectionProps } from '../HelpCenter'
import { HelpCenterCardList } from '../HelpCenterCardList'
import { SectionCenter } from '../SectionCenter'

const nodeList = ['Question', 'FAQ', 'Button', 'Message', 'SendEmail', 'AddToCampaign', 'ConversationStatus', 'ConversationGoal', 'RateConversation', 'TagConversation', 'LeadScore', 'AlertAgent', 'Calendar', 'LiveChat', 'EmailCapture', 'PhoneCapture']

export const BotNodesCenter = (props: SectionProps): JSX.Element => {
  return (
    <SectionCenter
      homeComponent={<HelpCenterCardList nodeMapping={nodeMapping} nodeList={nodeList} />}
      links={props.links}
      type='node'
    />
  )
}
