import { Checkbox, Divider } from 'library/materialUI'
import { useContext } from 'react'
import { DynamicSegmentContext } from './DynamicSegmentContext'
import SegmentCriteria from './SegmentCriteria'
import SegmentDisplay from './SegmentDisplay'

export const DynamicSegmentModal = (): JSX.Element => {
  const { changeCRMOnly, crmOnly } = useContext(DynamicSegmentContext)
  return (
    <>
      <div
        style={{ height: 575, display: 'flex', flexDirection: 'row' }}
      >
        <SegmentCriteria />
        <Divider orientation='vertical' style={{ height: '100%' }} />
        <SegmentDisplay />
      </div>
      <div style={{ width: '345px', borderTop: '1px solid #e4e4e4', marginTop: '-43px' }}>
        <Checkbox onChange={changeCRMOnly} checked={crmOnly} /> Only include companies from CRM
      </div>
    </>
  )
}
