import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
// import am4themes_animated from "@amcharts/amcharts4/themes/animated";

// am4core.useTheme(am4themes_animated);

function createSummaryChart ({ data, labels, id, setVisible }) {
  am4core.addLicense('CH213476655')

  if (!document.getElementById(id)) {
    return
  }

  const chart = am4core.create(id, am4charts.XYChart)

  chart.data = data

  const categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis())
  categoryAxis.dataFields.category = 'type'
  categoryAxis.numberFormatter.numberFormat = '#'
  categoryAxis.renderer.inversed = true
  categoryAxis.renderer.grid.template.location = 0
  categoryAxis.renderer.cellStartLocation = 0.1
  categoryAxis.renderer.cellEndLocation = 0.9
  categoryAxis.renderer.grid.template.strokeWidth = 0
  categoryAxis.renderer.labels.template.fontSize = '13px'
  categoryAxis.renderer.labels.template.fill = '#4C4C4C'

  const valueAxis = chart.xAxes.push(new am4charts.ValueAxis())
  valueAxis.min = 0
  valueAxis.maxPrecision = 0
  valueAxis.renderer.labels.template.fontSize = '12px'
  valueAxis.renderer.labels.template.fill = '#7C7C7C'

  chart.legend = new am4charts.Legend()
  chart.legend.fontSize = '13px'
  chart.legend.labels.template.fill = '#4C4C4C'

  function createSeries (field, name) {
    const series = chart.series.push(new am4charts.ColumnSeries())
    series.dataFields.valueX = field
    series.dataFields.categoryY = 'type'
    series.name = name
    series.columns.template.height = am4core.percent(100)
    series.sequencedInterpolation = true

    series.columns.template.column.tooltipHTML = '<span style="color: #ffffff;">{valueX}</span>'
  }

  createSeries('current_period', 'Selected Period')
  createSeries('last_period', 'Previous Period')
  createSeries('average', '4 Period Avg')

  chart.events.on('ready', () => {
    setVisible('visible')
  })

  return chart
}

export { createSummaryChart }
