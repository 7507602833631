import { Segment, filter } from 'classes/segments'
import { DynamicSegmentModal } from './DynamicSegmentModal'
import { StaticSegmentModal } from './StaticSegmentModal'
import { Modal } from 'library/Modal'
import { values } from 'lodash'
import { useContext, useEffect, useState } from 'react'
import { DynamicSegmentContext } from './DynamicSegmentContext'
export interface SegmentSetupProps {
  open: boolean
  segment: Segment
  onHide: () => void
  save: (values: {
    triggers: Segment['triggers']
  }) => void
  goBackText?: string
  onBack: (newsegment: Segment) => void
  editTriggers: (segment: Segment) => void
  openCSVModal?: () => void
}

interface segmentTypeProps {
  Component: Function
}

export const SegmentSetupModal = (props: SegmentSetupProps): JSX.Element => {
  const { triggers, setTriggers, resetTriggers } = useContext(DynamicSegmentContext)
  const [saveBtnDisabled, setSaveBtnDisabled] = useState(true)
  const segmentType = props.segment.segmentType
  const segmentID = props.segment.id
  const segmentMapping: Record<string, segmentTypeProps> = {
    static: { Component: StaticSegmentModal },
    dynamic: { Component: DynamicSegmentModal }
  }
  const action = props.segment.id ? 'Edit' : 'Create'
  const title = `${action} ${segmentType.charAt(0).toUpperCase() + segmentType.slice(1)} Segment`

  useEffect(() => {
    if (segmentType !== 'dynamic') {
      setSaveBtnDisabled(false)
      return
    }
    const filters = triggers[0] ? triggers[0].filters : undefined
    if (filters) {
      const definedFilters = filters.filter((filter: filter) => filter.prop !== '')
      const incompleteFilters = (definedFilters.filter((filter: filter) => values(filter).includes(''))).length > 0
      if (definedFilters.length === 0 || incompleteFilters) {
        setSaveBtnDisabled(true)
      } else {
        setSaveBtnDisabled(false)
      }
    } else {
      setSaveBtnDisabled(true)
    }
  }, [triggers, segmentType])

  if (!segmentType) {
    return (<></>)
  }

  const { Component } = segmentMapping[segmentType]

  return (
    <Modal
      open={props.open}
      size='lg'
      handleSave={() => {
        props.save({ triggers: triggers })
        setTriggers([{ filters: [] }])
      }}
      closeBtnFunction={() => {
        props.onBack(props.segment)
        if (segmentID) {
          resetTriggers()
        }
      }}
      onHide={() => {
        props.onHide()
        if (!segmentID) {
          setTriggers([{ filters: [] }])
        } else {
          resetTriggers()
        }
      }}
      cancelBtnText={props.goBackText || 'Go back'}
      saveBtnText={segmentID ? 'Save segment' : 'Create segment'}
      saveDisabled={saveBtnDisabled}
      saveIcon={segmentID ? 'save' : 'add'}
      title={title}
      key='segment_setup_modal'
      noPadding
      disableBackdropClick
      disableEscClick
      leftBtnText={segmentType === 'static' ? 'Upload CSV' : undefined}
      leftBtnFunction={() => {
        if (props.openCSVModal) {
          props.openCSVModal()
        }
      }}
      leftBtnIcon='upload'
    >
      <Component {...props} />
    </Modal>
  )
}
