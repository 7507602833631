import React, { useContext } from 'react'
import { getBotTest } from 'api/bot_tests'
import { SessionContext } from 'session-context'
import { useQuery } from 'react-query'
import AppPage from 'cf-components/AppPage'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
import DashboardContents from './DashboardContents'
import './reports.css'

const breadcrumbs = [
  {
    link: '/bot_tests',
    text: 'Bot Tests'
  },
  {
    link: null,
    text: 'Test Dashboard'
  }
]

function TestReportDashboard (props) {
  const { user } = useContext(SessionContext)
  const chatServiceUrl = user.links.chat_service
  const testID = props.match.params.bot_test_id

  const testQuery = useQuery('test' + testID, () => getBotTest({ chatServiceUrl, testID }))

  const title = testQuery.data?.data.attributes.name || ''

  return (
    <div id='dashboard-page'>
      <AppPage
        title={title}
        breadcrumbs={breadcrumbs}
        actionText='Export/Print'
        action={window.print}
        icon={<PictureAsPdfIcon />}
      >
        <DashboardContents
          testData={testQuery.data?.data}
          testID={testID}
        />
      </AppPage>
    </div>
  )
}

export default TestReportDashboard
