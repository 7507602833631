import { ReactNode } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { IconButton } from '@material-ui/core'
// import { Icon } from "library/materialUI"
import LoopOutlinedIcon from '@material-ui/icons/LoopOutlined'

const useStyles = makeStyles(theme => ({
  container: {
    margin: '20px',
    display: 'flex',
    flexDirection: 'column',
    height: '720px'
  },
  browserHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: '10px 10px 0px 0px',
    backgroundColor: '#E2E2E2',
    height: '32px'
  },
  mockControl: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '15px',
    marginRight: '5px'
  },
  mockControlButton: {
    backgroundColor: '#FFFFFF',
    width: '8px',
    height: '8px',
    borderRadius: '4px',
    margin: '2px'
  },
  mockTab: {
    alignSelf: 'flex-end',
    height: '26px',
    width: 'fit-content',
    borderRadius: '10px 10px 0px 0px',
    backgroundColor: '#FFFFFF',
    marginLeft: '15px',
    marginRight: 'auto',
    paddingLeft: '10px',
    paddingRight: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '10px',
    fontFamily: 'poppins'
  },
  urlBoxContainer: {
    height: '36px',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#FFFFFF'
  },
  mockUrlBox: {
    border: '1px solid #0000001A',
    borderRadius: '100px',
    minWidth: '473px',
    marginLeft: '60px',
    paddingLeft: '25px',
    paddingRight: '35px'
  }
}))

interface MockBrowserContainerProps {
  pageName: string
  pageURL: string
  children: ReactNode
  refreshIFrame?: () => null
}

export default function MockBrowserContainer ({ pageName, pageURL, children, refreshIFrame }: MockBrowserContainerProps) {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <div className={classes.browserHeader}>
        <div className={classes.mockControl}>
          <div className={classes.mockControlButton} />
          <div className={classes.mockControlButton} />
          <div className={classes.mockControlButton} />
        </div>
        <div className={classes.mockTab}>
          {pageName}
        </div>
        {refreshIFrame && (
          <IconButton onClick={refreshIFrame}><LoopOutlinedIcon /></IconButton>
        )}
      </div>
      <div className={classes.urlBoxContainer}>
        <div className={classes.mockUrlBox}>
          {pageURL}
        </div>
      </div>
      <div style={{ height: '100%' }}>
        {children}
      </div>
    </div>
  )
}
