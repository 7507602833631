import React, { useState } from 'react'
import { Paper } from 'library/materialUI'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from 'library/materialUI/Button'
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined'
import { Formik, Form, FastField, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import CircularProgress from '@material-ui/core/CircularProgress'
import PasswordConfirmationBlock from './PasswordConfirmationBlock'
import FormTextField from './FormTextField'

const useStyles = makeStyles(theme => ({
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3)
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    marginTop: 16
  },
  buttonProgress: {
    marginTop: -12,
    marginLeft: -12,
    position: 'absolute',
    top: '50%',
    left: '50%',
    zIndex: 5
  },
  wrapper: {
    position: 'relative'
  },
  invalidInput: {
    color: 'red',
    fontSize: 12,
    height: 25
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: 400,
    borderRadius: 10,
    padding: '10px 50px 30px 50px',
    minHeight: '100%'
  }
}))

const startingValues = {
  name: '',
  phone: '',
  password: ''
}

const phoneRegEx = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Please enter your full name')
    .required('Please enter your full name'),
  phone: Yup.string()
    .matches(phoneRegEx, 'Phone number is invalid')
    .required('Please enter your phone number'),
  password: Yup.string()
    .trim()
    .required('Password is required')
    .min(10, 'Password must match the criteria below')
    .test(
      'complexity',
      'Your password must match the criteria below',
      value => {
        let count = 0
        if (/[A-Z]/.test(value)) count++
        if (/[a-z]/.test(value)) count++
        if (/[0-9]/.test(value)) count++
        if (/[!@#$%^&*]/.test(value)) count++
        return count >= 2
      }
    )
})

function CompleteRegistrationForm (props) {
  const classes = useStyles()
  const [focused, setFocused] = useState(false)
  const [visible, setVisibility] = useState(false)

  const onSubmit = values => {
    setVisibility(false)
    props.onSubmit(validationSchema.cast(values))
  }

  return (
    <Formik
      initialValues={startingValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      render={({ handleChange, values, errors, submitForm, handleBlur, touched }) => {
        function trimPasswords () {
          values.password = values.password.trim()
          submitForm()
        }
        return (
          <Form className={classes.form}>
            <div className={classes.wrapper}>
              <FastField
                component={FormTextField}
                id='name'
                name='name'
                label='Full name'
                fullWidth
              />
              <div className={classes.invalidInput}>
                <ErrorMessage name='name' component='div' />
              </div>
            </div>
            <div className={classes.wrapper}>
              <FastField
                component={FormTextField}
                id='phone'
                name='phone'
                label='Phone'
                fullWidth
              />
              <div className={classes.invalidInput}>
                <ErrorMessage name='phone' component='div' />
              </div>
            </div>
            <PasswordConfirmationBlock
              style={{ marginBottom: 16 }}
              setFocused={setFocused}
              handleBlur={handleBlur}
              handleChange={handleChange}
              autoFocus={false}
              visible={visible}
              setVisibility={setVisibility}
              password={values.password}
              passwordError={errors.password && touched.password && !focused}
            />
            <div className={classes.wrapper}>
              {props.loading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
              <Button
                type='button'
                fullWidth
                variant='contained'
                color='primary'
                className={classes.submit}
                disabled={props.loading}
                onClick={trimPasswords}
              >
                Register
              </Button>
            </div>
          </Form>
        )
      }}
    />
  )
}

const RegistrationFromInviteSideBar = props => {
  const classes = useStyles()

  return (
    <Paper className={classes.paper}>
      <Avatar className={classes.avatar}>
        <AccountCircleOutlinedIcon color='primary' />
      </Avatar>
      <Typography component='h1' variant='h5'>
        Complete Signals Registration
      </Typography>

      {props.emailSwapState == null ? (
        <CompleteRegistrationForm
          onSubmit={props.onSubmit}
          loading={props.loading}
        />
      ) : (

        <>
          {props.emailSwapState === 'complete' ? (
            <Typography>Your invitation has been sent to the new email address. Please check it and try again.</Typography>
          ) : (
            <Formik
              initialValues={{ email: '' }}
              onSubmit={props.performEmailSwap}
              render={({ handleChange, errors, handleBlur, touched }) => (
                <Form className={classes.form}>
                  <Typography>
                    It looks like this email address is unavailable for use. Please provide an email address that is not yet associated with a Signals account.
                  </Typography>
                  <FastField
                    component={FormTextField}
                    id='email'
                    name='email'
                    label='New email address'
                    fullWidth
                  />
                  <Button
                    type='submit'
                    fullWidth
                    variant='contained'
                    color='primary'
                    className={classes.submit}
                  >
                    Change Email
                  </Button>
                </Form>
              )}
            />
          )}

        </>

      )}
    </Paper>
  )
}

export default RegistrationFromInviteSideBar
