import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import CustomizedSnackbar from 'components/CustomizedSnackbar'
import { INTEGRATIONS, updateIntegration as updateIntegrationDF } from 'api/integrations'
import { Chip, Switch } from '@material-ui/core'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import { Link, useHistory } from 'react-router-dom'
import TeamsLogo from 'img/ms_teams_logo.png'
import { getMessagingService, updateIntegration } from 'api/messaging_service'

const useStyles = makeStyles({
  headerImage: {
    height: 65,
    marginBottom: 30
  },
  disabledHeaderImage: {
    height: 65,
    marginBottom: 30,
    filter: 'grayscale(100%)'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  manualToggle: {
    marginRight: 40,
    marginTop: 20
  },
  wrapIcon: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 15
  },
  container: {
    padding: 65,
    minWidth: 920
  },
  bodyText: {
    marginBottom: 25,
    fontSize: 16
  },
  subtitle1: {
    fontSize: 18,
    fontWeight: 600,
    marginBottom: 15
  },
  subtitle2: {
    fontSize: 15,
    fontWeight: 600,
    marginBottom: 10
  },
  groupContainer: {
    backgroundColor: 'rgba(193, 166, 237, 0.1)',
    padding: 30,
    borderRadius: 10,
    marginBottom: 20
  },
  apiKeyField: {
    display: 'flex'
  },
  textField: {
    width: '90%',
    marginRight: 10
  },
  visibilityButton: {
    height: 56,
    marginTop: -1
  },
  crumb: {
    fontSize: '.8em'
  },
  typography: {
    fontSize: '1.05em'
  },
  breadcrumbs: {
    position: 'absolute',
    top: 5
  },
  crumbWrapper: {
    padding: 10
  }

})

const initialValues = {
  type: 'integrations',
  attributes: {
    integration_name: INTEGRATIONS.Teams,
    enabled: false,
    config: {},
    mappings: {}
  }
}

const TeamsIntegrationPage = (props) => {
  const classes = useStyles()

  const [snackState, setSnackState] = useState({
    open: false,
    variant: 'success',
    message: 'Your changes have been saved'
  })
  const [integrationSettings, setIntegrationSettings] = useState(initialValues)

  const hasTeamsConnection = true
  const history = useHistory()

  const save = async (settings) => {
    await updateIntegrationDF({ integration: settings })
    await updateIntegration({ integration: settings })
      .then((response) => {
        setSnackState({
          open: true,
          variant: 'success',
          message: 'Your changes have been saved'
        })
      })
    if (settings.attributes.enabled) {
      history.push({
        pathname: '/settings/my_settings/notifications',
        state: { from: 'Integrations' }
      })
    }
  }

  const toggleIntegration = (checked) => {
    integrationSettings.attributes.enabled = checked
    save(integrationSettings)
  }

  useEffect(() => {
    if (hasTeamsConnection) {
      getMessagingService(INTEGRATIONS.Teams)
        .then((response) => {
          if (response.ok) {
            setIntegrationSettings(response.data)
          }
        })
    }
  }, [hasTeamsConnection])

  return (
    <>
      <Breadcrumbs aria-label='breadcrumb' classes={{ li: classes.crumb, ol: classes.crumbWrapper }}>
        <Link style={{ color: '#7A7A7A' }} to='/settings/integrations'>
          Integrations Page
        </Link>
        <Typography color='textPrimary' classes={{ root: classes.typography }}>Teams Integration Page</Typography>
      </Breadcrumbs>
      <div className={classes.container}>
        <div className={classes.header}>
          <img
            src={TeamsLogo}
            className={integrationSettings.attributes.enabled ? classes.headerImage : classes.disabledHeaderImage}
            alt='Teams Logo'
          />
          {integrationSettings ? (
            <div className={classes.manualToggle}>
              <Chip
                label={
                  integrationSettings.attributes.enabled
                    ? 'Integration: On'
                    : 'Integration: Off'
                }
                color={
                  integrationSettings.attributes.enabled
                    ? 'primary'
                    : 'default'
                }
                className={classes.chip}
              />
              <Switch
                checked={
                  integrationSettings.attributes.enabled
                }
                onChange={(e) => toggleIntegration(e.target.checked)}
                name='master_toggle'
                className={classes.switch}
              />
            </div>
          ) : (
            <></>
          )}
        </div>
        <Typography variant='body2' className={classes.bodyText}>
          Integrate your Signals account with Teams to aid and enhance client communication!
        </Typography>
        <div className={classes.groupContainer}>
          <Typography variant='subtitle1' className={classes.subtitle1}>
            Connected!
          </Typography>
          <Typography variant='body2' className={classes.bodyText}>
            Turn on and off Teams integration using the toggle button above
          </Typography>
        </div>
      </div>
      <CustomizedSnackbar state={snackState} handler={setSnackState} />
    </>
  )
}

export default TeamsIntegrationPage
