import { Typography } from 'library/materialUI'
import FirefoxStep1 from 'img/firefox_notif_1.png'
import FirefoxStep2 from 'img/firefox_notif_2.png'

const FirefoxNotificationsDescription = (): JSX.Element => {
  return (
    <div style={{ fontFamily: 'Poppins', fontSize: '14px' }}>
      <Typography variant='h2' style={{ marginBottom: '20px' }}>To allow sound notifications in Firefox, do the following:</Typography>
      <Typography variant='h2' style={{ marginBottom: '20px' }}>1. In the Firefox URL bar, click the Settings/Autoplay icons next to where it says “app.chatfunnels.com”</Typography>
      <img src={FirefoxStep1} alt='step_1' />
      <Typography variant='h2' style={{ marginBottom: '20px', marginTop: '20px' }}>2. In the menu that appears, select “Allow Audio and Video” in the dropdown</Typography>
      <img src={FirefoxStep2} alt='step_2' />
    </div>
  )
}

export const FirefoxNotificationsDocs = {
  helpCenterLink: 'https://help.getsignals.ai/article/rigkx7qxcj-allow-sound-notifications-for-new-messages',
  description: FirefoxNotificationsDescription,
  videoLink: '',
  icon: 'smFirefox',
  title: 'Allow sound notifications in Firefox'
}
