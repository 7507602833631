import { makeStyles } from '@material-ui/core/styles'
import { NewPlayModal } from 'pages/engagement/NewPlayModal'
import { useState } from 'react'

const useStyles = makeStyles(theme => ({
  newPlayButton: {
    display: 'flex',
    backgroundColor: theme.palette.primary.alt,
    color: 'white',
    '&:hover': {
      backgroundColor: '#6941A1'
    },
    fontSize: '14px',
    alignItems: 'center',
    justifyContent: 'center',
    width: '244px',
    height: '44px',
    borderRadius: '5px',
    boxShadow: '0px 3px 6px #00000029',
    cursor: 'pointer',
    marginBottom: '12px',
    marginTop: '12px'
  }
}))

export default function NewPlayButton (): JSX.Element {
  const classes = useStyles()
  const [modalOpen, setModalOpen] = useState(false)

  return (
    <>
      <div onClick={() => setModalOpen(true)} className={classes.newPlayButton}>
        + Create new play
      </div>
      <NewPlayModal
        open={modalOpen}
        onHide={() => setModalOpen(false)}
        newTab
        setOpen={setModalOpen}
      />
    </>
  )
}
