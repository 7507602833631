import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied'

const emptyStyles = makeStyles(theme => ({
  emptyIcon: {
    color: '#999999',
    height: '2em',
    width: '2em'
  },
  outerDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
  },
  iconDiv: {
    display: 'flex',
    justifyContent: 'center'
  },
  displayText: {
    paddingTop: 5,
    fontFamily: 'Poppins, sans serif',
    fontWeight: 500,
    fontSize: '.9em',
    color: '#999999'
  },
  svgRoot: {
    fontSize: '1em'
  }
}))

function NoConversation (props) {
  const classes = emptyStyles()

  return (
    <div className={classes.outerDiv}>
      <div>
        <div className={classes.iconDiv}>
          <SentimentDissatisfiedIcon
            className={classes.emptyIcon}
            classes={{ root: classes.svgRoot }}
          />
        </div>
        <div className={classes.displayText}>
          {props.message}
        </div>
      </div>
    </div>
  )
}

export default NoConversation
