import { nodeMappingData } from 'library/flowBuilder/FlowBuilder'
import { HelpCenterCard, THelpCenterCard } from './HelpCenterCard'
import { basicMapping } from './TemplateMapping'

type nodeProps = {
  nodeMapping: Record<string, basicMapping | nodeMappingData>
  nodeList: string[]
}

export const HelpCenterCardList = (props: nodeProps): JSX.Element => {
  const { nodeMapping, nodeList } = props
  const cards: THelpCenterCard[] = Object.keys(nodeMapping).filter(key => nodeList.includes(key))
    .map(key => {
      const node = nodeMapping[key]
      return (
        { title: node.title, icon: node.icon, link: key }
      )
    })

  return (
    <div>
      {cards.map((card: THelpCenterCard, index: number) => (
        <HelpCenterCard key={index} card={card} size='small' />
      ))}
    </div>
  )
}
