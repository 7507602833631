import { useContext, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CircleIcon from '@material-ui/icons/FiberManualRecord'
import RenderEmoji from 'pages/chat/RenderEmoji'
import { useDoTypeQuery } from 'classes/useDoQuery'
import { Button, Icon, TextField } from 'library/materialUI'
import { sendTestEmail } from 'api/emails'
import { Modal, ModalSection } from 'library/Modal'
import { SessionContext } from 'session-context'
import { EmailWizardContext } from './EmailWizardContext'

const useStyles = makeStyles(theme => ({
  previewCard: {
    border: '2px solid #F0E3FF',
    width: 550
  },
  controlButtons: {
    borderBottom: '1px solid #F0E3FF',
    display: 'flex',
    alignItems: 'center'
  },
  headerSection: {
    padding: 4,
    borderBottom: '1px solid #F0E3FF',
    fontSize: '13px'
  },
  sectionHeader: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 10
  },
  emailBody: {
    padding: 20,
    fontSize: '15px',
    minHeight: 100
  },
  logoDiv: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 15
  },
  emailText: {
    whiteSpace: 'pre-wrap'
  },
  testButton: {
    display: 'flex',
    alignItems: 'center',
    float: 'right',
    marginTop: 10,
    color: '#9933FF',
    borderColor: '#9933FF',
    '&:hover': {
      borderColor: '#9933FF'
    }
  }
}))

function EmailPreview (props) {
  const classes = useStyles()
  const { userObject } = useContext(SessionContext)
  const { emailID } = useContext(EmailWizardContext)
  const { data: emailSettingsData, isLoading } = useDoTypeQuery({ path: '/integrations/email/setup', useChatURL: true })
  const [testModalOpen, setTestModalOpen] = useState(false)
  const [unsavedChangesModalOpen, setUnsavedChangesModalOpen] = useState(false)
  const [testEmailAddress, setTestEmailAddress] = useState(userObject.email)

  const fromType = props.fromType
  let fromString = props.fromEmail
  const subjectString = 'Subject: ' + props.subject

  if (fromType === 'default') {
    fromString = 'From: ' + (isLoading ? '...' : `${emailSettingsData.default_sender_name} <${emailSettingsData?.default_sender_email_prefix}@${emailSettingsData?.sender_domain}>`)
  } else if (fromType === 'account_owner') {
    fromString = 'From: {Account Owner\'s Email}'
  } else {
    fromString = 'From: ' + fromString + '@' + (emailSettingsData ? emailSettingsData?.sender_domain : 'app.getsignals.ai')
  }

  let width = 100
  if (props.layout === 'banner') {
    width = 550
  }

  function saveEmail () {
    props.handleSave()
    setUnsavedChangesModalOpen(false)
    setTestModalOpen(true)
  }

  function sendEmail () {
    sendTestEmail({ emailID: emailID, recipient: testEmailAddress })
  }

  function handleTestEmail () {
    if (props.shouldSave) {
      setUnsavedChangesModalOpen(true)
    } else {
      setTestModalOpen(true)
    }
  }

  return (
    <div>
      <div className={classes.sectionHeader}>
        This is what your email will look like to recipients
      </div>
      <Card className={classes.previewCard}>
        <div className={classes.controlButtons}>
          {[1, 2, 3].map((value) => (
            <CircleIcon
              key={value}
              style={{ fontSize: '1rem', color: '#c5ade0' }}
            />))}
        </div>
        <div className={classes.headerSection}>
          {fromString}
        </div>
        <div className={classes.headerSection}>
          {subjectString}
        </div>
        <div className={classes.emailBody}>
          {props.image &&
            <div className={classes.logoDiv}>
              <img src={props.image} width={width} alt='company logo' />
            </div>}
          <RenderEmoji
            className={classes.emailText}
            text={props.emailBody}
            rich='true'
          />
        </div>
      </Card>
      <Button
        className={classes.testButton}
        variant='outlined'
        onClick={handleTestEmail}
        disabled={!emailSettingsData?.ready_to_use}
        disabledToolTip='Complete the setup in your Email Settings to send a test email.'
      >
        <Icon icon='send' color={emailSettingsData?.ready_to_use ? '#9933FF' : '#E0E0E0'} /><div style={{ marginLeft: 5 }}>Send Test Email</div>
      </Button>
      <Modal
        open={testModalOpen}
        onHide={() => setTestModalOpen(false)}
        title='Send Test Email'
        size='md'
        hideCancelButton
        handleSave={() => {
          setTestModalOpen(false)
          sendEmail()
        }}
        saveBtnText='Send Email'
      >
        <ModalSection
          title='Email Address'
          subtitle='Enter the email address you would like to send the test email to'
        >
          <TextField
            label='Email Address'
            value={testEmailAddress}
            onChange={e => setTestEmailAddress(e.target.value)}
            fullWidth
          />
        </ModalSection>
      </Modal>
      <Modal
        open={unsavedChangesModalOpen}
        onHide={() => setUnsavedChangesModalOpen(false)}
        title='Unsaved Changes'
        size='sm'
        hideCancelButton
        handleSave={saveEmail}
        saveBtnText='Save Email'
        noDividers
      >
        <ModalSection
          title='You have unsaved changes'
          subtitle='You must save your changes before sending a test email'
        />
      </Modal>
    </div>
  )
}

export default EmailPreview
