import { useState, useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Modal, ModalSection } from 'cf-components/Modal'
import { makeStyles } from '@material-ui/core/styles'
import { TextField, Button } from '@material-ui/core'
// eslint-disable-next-line
import CSVTemplate from 'assets/template_v1.csv'
import Picker from 'cf-components/Pickers'
import { EVENT_TYPE, Emitter } from 'emitter'
import Account from 'classes/accounts'
import CSVDropZone from 'library/segments/CSVDropZone'

const useStyles = makeStyles((theme) => ({
  accountField: {
    display: 'flex',
    alignItems: 'center'
  },
  textLabel: {
    marginRight: 10,
    fontSize: 14,
    color: '#777777',
    width: 110
  },
  textField: {
    width: 300
  }
}))

const intialAccountState = {
  type: 'accounts',
  attributes: {
    name: '',
    website: '',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    owner_user_id: ''
  }
}

interface CSVUploadSectionProps {
  setMode: any
  hideCSV: boolean
}

function CSVUploadSection ({ setMode, hideCSV }: CSVUploadSectionProps) {
  if (hideCSV) {
    return (
      <></>
    )
  }

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', width: '100%', marginTop: 15 }}>
        <div style={{ height: 2, backgroundColor: '#975ce6', width: 'calc(50% - 20px)' }} />
        <div style={{ padding: '0px 10px' }}>
          OR
        </div>
        <div style={{ height: 2, backgroundColor: '#975ce6', width: 'calc(50% - 21px)' }} />
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          variant='outlined'
          color='primary'
          style={{ width: 375, marginTop: 20 }}
          onClick={() => setMode('csv')}
        >
          Upload from a CSV
        </Button>
      </div>
    </>
  )
}

interface AccountModalProps {
  CSVImportErrors: { detail: string }[]
  handleCSVImport: (csv: any, csvFallbackUserID: number) => void
  handleSubmit: (account: Account) => Promise<any>
  open: boolean
  onHide: any
  hideCSV?: boolean
  account?: any
}

export default function AccountModal ({ CSVImportErrors, account, handleCSVImport, handleSubmit, hideCSV = false, open, onHide }: AccountModalProps) {
  const classes = useStyles()
  const [saveDisabled, setSaveDisabled] = useState(false)
  const [accountState, setAccountState] = useState(account || intialAccountState)
  const [mode, setMode] = useState<'csv' | null>(null)
  const [csvFile, setCsvFile] = useState()
  const [csvFallbackUserID, setCsvFallbackUserID] = useState<number | null>(null)
  const [csvDownloading, setCsvDownloading] = useState(false)
  const history = useHistory()

  useEffect(() => {
    if (account) {
      setAccountState(account)
    }
  }, [account])

  const downloadTemplateCSV = () => {
    const fileName = CSVTemplate
    const link = document.createElement('a')
    link.setAttribute('href', fileName)
    link.setAttribute('download', 'abm_template.csv')
    document.body.appendChild(link)
    link.click()
  }

  const handleSaveClick = () => {
    if (mode === 'csv' && csvFile && csvFallbackUserID) {
      Emitter.emit(EVENT_TYPE.CSV_DOWNLOAD, { csvIsDownloading: true, type: 'accounts' })
      setCsvDownloading(true)
      handleCSVImport(csvFile, csvFallbackUserID)
    } else {
      handleSubmit(accountState).then(() => {
        setAccountState(intialAccountState)
      }).catch((error: Error) => {
        if (error.message.includes('duplicate_violation')) {
          if (error.message.includes('https')) {
            const link = error.message.split('/#/')[1]
            history.push(`/${link}`)
          }
        }
      })
    }
  }

  const handleUploadCSV = (file: any) => {
    setCsvFile(file)
  }

  const handleEdit = useCallback(({ attr, value }) => {
    setAccountState({
      ...accountState,
      attributes: {
        ...accountState.attributes,
        [attr]: value
      }
    })
  }, [accountState])

  useEffect(() => {
    if (mode === 'csv' && ((!csvFile || !csvFallbackUserID) || csvDownloading)) {
      setSaveDisabled(true)
    } else if (mode !== 'csv' && (!accountState.attributes.owner_user_id || !accountState.attributes.website)) {
      setSaveDisabled(true)
    } else {
      setSaveDisabled(false)
    }
    if (CSVImportErrors.length > 0) {
      setSaveDisabled(false)
    }
  }, [mode, csvFile, csvFallbackUserID, saveDisabled, accountState.attributes.owner_user_id, accountState.attributes.website, csvDownloading, CSVImportErrors.length])

  return (
    <>
      <Modal
        open={open}
        onHide={() => { onHide(); setAccountState(intialAccountState) }}
        title='Add Account'
        rightButtonProps={{
          text: 'Save',
          action: handleSaveClick,
          disabled: saveDisabled
        }}
        leftButtonProps={{
          text: mode === 'csv' ? 'Go Back' : undefined,
          action: mode === 'csv' ? () => setMode(null) : undefined,
          variant: 'text'
        }}
      >
        {mode === 'csv' ? (
          <div>
            <ModalSection
              title='Step 1: Download the CSV Template'
              subtitle="NOTE: 'Website' and 'Name' fields are mandatory."
            >
              <Button
                variant='outlined'
                color='primary'
                onClick={downloadTemplateCSV}
              >
                Click Here to Download the Template
              </Button>
            </ModalSection>
            <ModalSection title='Step 2: Upload your CSV'>
              <div style={{ marginBottom: 10 }}>
                <CSVDropZone
                  handleDrop={handleUploadCSV}
                  CSVFile={csvFile}
                />
              </div>
              <ModalSection
                title='Step 3: Choose a Fallback Owner'
                subtitle='What user should own these accounts in case a ChatFunnel user is not found in the export?'
              >
                <Picker
                  objectType='users'
                  selection={csvFallbackUserID || undefined}
                  setSelection={(value: number | string) => setCsvFallbackUserID(value as number)}
                />
              </ModalSection>
              {CSVImportErrors.map((error, index) => (
                <div style={{ color: 'red' }} key={index}>
                  {error?.detail}
                </div>

              ))}
            </ModalSection>
          </div>
        ) : (
          <>
            <ModalSection title='Account Info'>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div>
                  <div className={classes.accountField}>
                    <div className={classes.textLabel}> Company Name</div>
                    <TextField
                      variant='outlined'
                      color='primary'
                      margin='dense'
                      className={classes.textField}
                      onChange={(e) => handleEdit({ attr: 'name', value: e.target.value })}
                      value={accountState.attributes.name}
                    />
                  </div>
                  <div className={classes.accountField}>
                    <div className={classes.textLabel}> Website</div>
                    <TextField
                      variant='outlined'
                      color='primary'
                      margin='dense'
                      className={classes.textField}
                      onChange={(e) => handleEdit({ attr: 'website', value: e.target.value })}
                      value={accountState.attributes.website}
                    />
                  </div>
                </div>
              </div>
            </ModalSection>
            <ModalSection title='Account Owner'>
              <div style={{ marginTop: 10 }}>
                <Picker
                  objectType='users'
                  selection={accountState?.attributes?.owner_user_id || null}
                  setSelection={(value: number | string) => handleEdit({ attr: 'owner_user_id', value })}
                />
              </div>
            </ModalSection>
            <CSVUploadSection
              setMode={setMode}
              hideCSV={hideCSV}
            />
          </>
        )}
      </Modal>
    </>
  )
}
