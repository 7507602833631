import { makeStyles } from '@material-ui/core/styles'
import { Icon, TextBox } from 'library/materialUI'
import { SelectorProps } from './ValueSelector'

const useStyles = makeStyles(theme => ({
  textRow: {
    display: 'flex',
    marginBottom: 10
  },
  actionDiv: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 5,
    paddingRight: 5,
    width: 20,
    color: theme.palette.primary.main
  },
  addButton: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
    padding: 5
  }
}))

type ActionDivProps = {
  last: boolean
  handleRemove: () => void
}

function ActionDiv (props: ActionDivProps) {
  const classes = useStyles()
  if (props.last) {
    return <></>
    // return (
    //   <div
    //     className={classes.actionDiv}
    //     onClick={props.handleAdd}
    //   >
    //     OR
    //   </div>
    // )
  } else {
  return (
    <div
      className={classes.actionDiv}
      onClick={props.handleRemove}
    >
      <Icon icon='cancel' color='primary' />
    </div>
  )
  }
}

export function URLSelector (props: SelectorProps) {
  const classes = useStyles()
  const { state, setState, handleComplete } = props
  const values = state.value

  const handleChange = (value: string, index: number) => {
    const newValues = [...state.value]
    if (index >= newValues.length) {
      newValues.push(value)
    } else {
      newValues[index] = value
    }
    setState({ ...state, value: newValues, label: newValues[0] })
  }

  const handleRemove = (index: number) => {
    const newValues = [...state.value]
    newValues.splice(index, 1)
    setState({ ...state, value: newValues })
  }

  const handleEnter = () => {
    const currentValues = [...state.value]
    if (currentValues.length === 0 || !currentValues[0]) {
      return
    }
    const newValues = currentValues.filter((value: string) => value !== '')
    const updatedState = { ...state, value: newValues }
    handleComplete(updatedState)
  }

  const handleAdd = (value: string) => {
    if (value) { handleChange('', values.length) }
  }

  props.submissionRef.current = handleEnter

  return (
    <div>
      {values.map((value: string, index: number) => {
      return (
        <div key={index} className={classes.textRow}>
          <TextBox
            value={value}
            onChange={(updated: string) => handleChange(updated, index)}
            autoFocus
            onEnter={handleEnter}
            onTab={() => handleAdd(value)}
          />
          <ActionDiv
            last={index === values.length - 1}
            handleRemove={() => handleRemove(index)}
          />
        </div>
      )
      })}
      <div
        className={classes.addButton}
        onClick={() => {
          if (values.length !== 0) {
            handleAdd(values[values.length - 1])
          } else {
            handleAdd('')
          }
        }}
      >
        + add another
      </div>
    </div>
  )
}
