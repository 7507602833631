import { Container } from 'library/materialUI'
import { makeStyles } from '@material-ui/core/styles'
import { ScrollBox } from 'cf-components/ScrollBox'

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: '20px'
  }
}))

export function ObjectPaneContainer (props: any) {
  const classes = useStyles()

  return (
    <ScrollBox noScrollBar style={{ marginTop: 1, marginBottom: 3, backgroundColor: '#F3F3F3', background: '#F3F3F3' }}>
      <Container
        maxWidth='lg'
        className={classes.container}
      >
        {props.children}
      </Container>
    </ScrollBox>
  )
}
