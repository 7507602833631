import { useState, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import DeleteIcon from '@material-ui/icons/DeleteForever'
import EditIcon from '@material-ui/icons/Edit'
import Table from 'cf-components/Table'
import { ListItemCard } from 'cf-components/CardList'
import ActionsMenu from 'cf-components/ActionsMenu'
import { AvatarWithInfo, getInitials } from 'cf-components/Avatars'
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined'
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined'
import { SessionContext } from 'session-context'
import { User } from 'classes/users'

const useStyles = makeStyles((theme) => ({
  headerClass: {
    display: 'grid',
    gridTemplateColumns: '2fr 1fr 1fr 1fr 1fr 1fr 50px',
    padding: '5px 15px',
    alignItems: 'center'
  },
  meetingCard: {
    display: 'grid',
    gridTemplateColumns: '2fr 1fr 1fr 1fr 1fr 1fr 50px',
    padding: 15,
    alignItems: 'center'
  },
  clickableTitle: {
    cursor: 'pointer'
  },
  createdByUser: {
    fontSize: 9,
    color: theme.palette.primary.main
  }
}))

const MeetingTypesTable = (props) => {
  const { user } = useContext(SessionContext)
  const [filterAll, setFilterAll] = useState(user.attributes.role === 1)
  const [filterCompany, setFilterCompany] = useState(!user.attributes.role === 1)
  const [filterPersonal, setFilterPersonal] = useState(!user.attributes.role === 1)
  const { data: users } = User.loadAll()

  let rows = props.rows ? props.rows.map(row => {
    const name = row.attributes.name
    const initials = getInitials(name, 'M')

    return {
      id: row.id,
      searchField: row.attributes.name,
      name: name,
      length: row.attributes.length_in_minutes,
      leadTime: row.attributes.lead_time,
      reminder: row.attributes.reminder_lead_time,
      allowInAgentPages: row.attributes.allow_in_agent_pages,
      initials: initials,
      allowGlobalAccess: row.attributes.allow_global_access,
      createdByUserID: row.attributes.created_by_user_id
    }
  }) : null

  if (rows && filterCompany && !filterPersonal) {
    rows = rows.filter(row => row.allowGlobalAccess === true)
  } else if (rows && filterCompany && filterPersonal) {
    rows = rows.filter(row => row.allowGlobalAccess === true || row.createdByUserID === user.id)
  } else if (rows && !filterCompany && filterPersonal) {
    rows = rows.filter(row => row.createdByUserID === user.id)
  }

  const classes = useStyles()

  const handleEdit = (id) => {
    props.onEdit(id)
  }

  const handleDelete = (id) => {
    props.onDelete(id)
  }

  const formatTime = (timeInMinutes) => {
    let text = 'None'
    if (timeInMinutes > 0) {
      let hourText = ''
      let minuteText = ''
      const hours = Math.floor(timeInMinutes / 60)
      const minutes = timeInMinutes % 60
      if (hours > 0) {
        hourText = hours.toString()
      }
      if (minutes > 0) {
        minuteText = minutes.toString()
      }

      if (hours && !minutes) {
        if (hours === 1) {
          text = hourText + ' hour'
        } else {
          text = hourText + ' hours'
        }
      } else if (minutes && !hours) {
        text = minuteText + ' minutes'
      } else if (minutes && hours) {
        text = hourText + 'hr ' + minuteText + 'min'
      }
    }

    return text
  }

  const formatMeetingType = (row) => {
    if (row.allowGlobalAccess) {
      const text = 'Company'
      return text
    } else {
      const htmlComponent = (
        <div>
          <div>Private</div>
          <div className={classes.createdByUser}>{users.dict[row.createdByUserID]?.name}</div>
        </div>
      )
      return htmlComponent
    }
  }

  const handleCheckboxes = (type) => {
    if (type === 'all') {
      if (!filterAll) {
        setFilterCompany(false)
        setFilterPersonal(false)
      }
      setFilterAll(!filterAll)
    } else {
      if (type === 'company') {
        if (!filterCompany) {
          setFilterAll(false)
        } else if (filterCompany && !filterPersonal) {
          setFilterAll(true)
        }
        setFilterCompany(!filterCompany)
      } else if (type === 'personal') {
        if (!filterPersonal) {
          setFilterAll(false)
        } else if (filterPersonal && !filterCompany) {
          setFilterAll(true)
        }
        setFilterPersonal(!filterPersonal)
      }
    }
  }

  const handleDisabled = (id) => {
    if (id === user.id || user.attributes.perms.manager) {
      return false
    }
    return true
  }

  const MeetingTypeCard = (props) => {
    const classes = useStyles()
    const row = props.row
    const index = props.index
    const color = index % 2 ? '#fe9d10' : '#8e5ae2'
    const actions = [
      { name: 'Edit', action: () => handleEdit(row.id), icon: EditIcon, disabled: handleDisabled(row.createdByUserID) },
      { name: 'Delete', action: () => handleDelete(row.id), icon: DeleteIcon, disabled: handleDisabled(row.createdByUserID) }
    ]

    return (
      <ListItemCard>
        <div className={classes.meetingCard}>
          <div
            className={classes.clickableTitle}
            onClick={() => handleEdit(row.id)}
          >
            <AvatarWithInfo
              title={row.name}
              initials={row.initials}
              subtitle='Meeting'
              avatarColor={color}
              variant='rounded'
            />
          </div>
          <div>
            {formatTime(row.length)}
          </div>
          <div>
            {formatTime(row.leadTime)}
          </div>
          <div>
            {formatTime(row.reminder)}
          </div>
          <div>
            {row.allowInAgentPages ? (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <CheckCircleOutlineOutlinedIcon style={{ fill: '#0ca90c' }} />
                <div style={{ color: '#0ca90c', marginLeft: 5 }}>
                  Enabled
                </div>
              </div>
            )
              : (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <HighlightOffOutlinedIcon style={{ fill: ' #f78e0d' }} />
                  <div style={{ color: '#f78e0d', marginLeft: 5 }}>
                    Hidden
                  </div>
                </div>
              )}
          </div>
          <div>
            {formatMeetingType(row)}
          </div>
          <ActionsMenu
            actions={actions}
          />
        </div>
      </ListItemCard>
    )
  }

  const sortValues = [
    { label: 'Name', id: 'name', header: true },
    { label: 'Length', id: 'length', header: true },
    { label: 'Lead Time', id: 'leadTime', header: true },
    { label: 'Reminder', id: 'reminder', header: true },
    { label: 'Agent Pages', id: 'allow_in_agent_pages', header: true },
    { label: 'Meeting Type', id: 'allow_global_access', header: true }
  ]

  const filterOptions = {
    filterValues: [
      { value: filterAll, label: 'View all meetings', toggle: () => handleCheckboxes('all') },
      { value: filterCompany, label: 'View company meetings', toggle: () => handleCheckboxes('company') },
      { value: filterPersonal, label: 'View My Personal Meetings', toggle: () => handleCheckboxes('personal') }
    ]
  }

  return (
    <Table
      rows={rows}
      card={MeetingTypeCard}
      sortValues={sortValues}
      header
      headerClass={classes.headerClass}
      startingSortColumn='name'
      filterOptions={filterOptions}
    />
  )
}

export default MeetingTypesTable
