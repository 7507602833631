import { makeStyles } from '@material-ui/core/styles'
import { Divider } from 'library/materialUI'
import ControlPointIcon from '@material-ui/icons/ControlPoint'
import LinkMapItem from './LinkMapItem'
import Button from '@material-ui/core/Button'
import { EmailQueryStringSettings } from './EmailQueryStringSettings'

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '40vw',
    marginTop: '20px',
    marginBottom: '20px',
    marginLeft: '50px'
  }
}))

interface LinkTrackingMappingProps {
  settings: EmailQueryStringSettings
  dispatch: any
  setCanSave: (value: boolean) => void
  customFields: Object[]
}

export default function LinkTrackingMapping ({ settings, dispatch, setCanSave, customFields }: LinkTrackingMappingProps) {
  const classes = useStyles()

  const handleAdd = () => {
    dispatch({
      type: 'setFieldMappings',
      value: [...settings.fieldMappings, {
        signalsFieldID: '',
        emailField: '',
        canDelete: true,
        canEdit: true
      }]
    })
    setCanSave(true)
  }

  const handleRemove = (index: number) => {
    dispatch({ type: 'setFieldMappings', value: [...settings.fieldMappings.slice(0, index), ...settings.fieldMappings.slice(index + 1)] })
    setCanSave(true)
  }

  const handleEdit = (index: number, value: any) => {
    dispatch({ type: 'setFieldMappings', value: [...settings.fieldMappings.slice(0, index), value, ...settings.fieldMappings.slice(index + 1)] })
    setCanSave(true)
  }

  return (
    <div>
      <div className={classes.header}>
        <div>
          Signals Field
        </div>
        <div>
          Email Provider Field
        </div>
      </div>
      <Divider />
      <div style={{ marginTop: '20px' }}>
        {settings.fieldMappings.map((item, index) => {
          return (
            <div key={index}>
              <LinkMapItem handleEdit={(newValue: any) => handleEdit(index, newValue)} handleRemove={() => handleRemove(index)} signalsFieldID={item.signalsFieldID} emailField={item.emailField} canDelete={item.canDelete} canEdit={item.canEdit} customFields={customFields} />
            </div>
          )
        })}
      </div>
      <div style={{ marginTop: '20px' }}>
        <Button
          variant='outlined'
          color='primary'
          onClick={() => handleAdd()}
          startIcon={<ControlPointIcon />}
        >
          Add Field
        </Button>
      </div>
    </div>
  )
}
