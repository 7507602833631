/* eslint-disable @typescript-eslint/naming-convention */
import { saveSettings } from 'api/user_display_settings';
import Banner from './Banner';

export default function ICPBanner () {
  return (
    <Banner
      title='Set up your Ideal Customer Profile (ICP)'
      subtitle={'We\'ll analyze your current customers and create an auto-updating segment containing companies that look like your current customers'}
      icon='autoAwesome'
      setUpLink='/#/account_management/icp'
      handleClose={() => saveSettings({ kind: 'icp_setup', settings: { segment_page_banner: { complete: true } }, post: false })}
    />
  )
}
