import { ReactNode, useContext, useMemo, useState } from 'react'
import { truncateString } from 'library/helpers'
import { Avatar as CFAvatar, getInitials } from 'cf-components/Avatars'
import { makeStyles } from '@material-ui/core/styles'
import { ObjectAvatar, GroupedAvatars } from './TableAvatars'
import dateFormat from 'dateformat'
import { Select, MenuItem, Avatar } from '@material-ui/core'
import StyledSwitch from 'components/StyledSwitch'
import XIcon from '@material-ui/icons/HighlightOff'
import CheckedIcon from '@material-ui/icons/CheckCircleOutline'
import Tooltip from '@material-ui/core/Tooltip'
import Link, { HiddenLink } from 'cf-components/Link'
import { ActionsComponent } from './ActionsComponent'
import { ReactComponent as TestingBeakerIcon } from 'img/testing_beaker_icon.svg'
import { User } from 'classes/users'
import { npsScoreColors } from 'pages/onboarding/nps/NPSScore'
import { Icon } from 'library/materialUI'
import { getEmployeeRange, getRevenueRange } from 'pages/accounts/definedFields'
import { TableContext } from 'library/table/TableProvider'
import { SignalsScoreGear } from 'cf-components/SignalsScoreGear'
import ScoringUpgradeModal from 'pages/settings/Scoring/ScoringUpgradeModal'
import OppStatusText from 'pages/dashboard/OppStatusText'
import { formatRevenue } from 'helpers/numbers'

const useStyles = makeStyles(theme => ({
  text: {
    fontSize: '.9em',
    overflowX: 'hidden',
    textOverflow: 'ellipsis'
  },
  time: {
    fontSize: '.8em',
    color: '#8d8d8d'
  },
  select: {
    borderRadius: 30
  },
  selectMenu: {
    padding: '5px 30px 5px 15px',
    minWidth: 30
  },
  beakerIcon: {
    marginTop: 12,
    transform: 'scale(1.3)',
    fill: 'rgba(0, 135, 7, 0.7)'
  },
  icon: {
    cursor: 'default',
    height: 30,
    width: 30
  },
  npsScore: {
    height: 40,
    width: 40,
    borderRadius: 100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    font: 'normal normal 600 18px/27px Poppins'
  },
  container: {
    display: 'grid',
    alignItems: 'center',
    width: 'calc(100% - 10px)',
    gridTemplateColumns: '40px 1fr',
    gridGap: 10
  },
  nameParagraph: {
    margin: 0,
    fontSize: '1em',
    maxWidth: '230px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  emailParagraph: {
    margin: 0,
    fontSize: '.8em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  contactInfo: {
    minWidth: 0
  },
  accountAvatar: {
    display: 'flex',
    fontSize: '12px',
    maxWidth: '250px'
  },
  accountName: {
    fontSize: '16px',
    fontFamily: 'poppins',
    paddingLeft: 10,
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  dataItemColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    fontSize: '16px',
    fontFamily: 'poppins'
  }
}))

type BasicType = 'avatar' | 'date' | 'dateTime' | 'text' | 'createdAndEdited' | 'userAvatar' | 'npsScore' | 'leadScore' | 'companyAvatar' | 'revenueRange' | 'employeeRange' | 'dynamic' | 'signalScore' | 'opp'
type BasicField<Class = any> = { name: keyof Class, sortField?: keyof Class, label?: string, sort?: boolean, onClick?: (row: Class) => void, link?: string | ((row: Class) => string), noDisplay?: boolean, minWidth?: number, tooltip?: string, emptyState?: string | JSX.Element }
type ComponentField<Class = any> = BasicField<Class> & { type: BasicType }
type PriorityField<Class = any> = BasicField<Class> & { type: 'priority', count: number, changePriority: any }
type IconField<Class = any> = BasicField<Class> & { type: 'icon', data: object }
type ToggleOrIconField<Class = any> = BasicField<Class> & { type: 'toggleOrIcon', data: object, handleToggle: (arg: { value: boolean, ID: number }) => void }
type ToggleField<Class = any> = BasicField<Class> & { type: 'toggle', handleToggle: (arg: { value: boolean, ID: number, row: Class }) => void }
type AvatarGroupField<Class = any> = BasicField<Class> & { type: 'avatarGroup', objectType: any }
type CustomField<Class = any> = BasicField<Class> & { type: 'custom', component: ReactNode }
type Field<Class = any> = ComponentField<Class> | PriorityField<Class> | ToggleField<Class> | AvatarGroupField<Class> | IconField<Class> | ToggleOrIconField<Class> | CustomField<Class>
export type Fields<Class = any> = Field<Class>[]

const fieldMapping: Record<string, any> = {
  avatar: ObjectAvatar,
  date: DateComponent,
  dateTime: DateTimeComponent,
  text: TextComponent,
  avatarGroup: AvatarGroupComponent,
  createdAndEdited: CreatedAndEditedComponent,
  priority: PriorityComponent,
  toggle: ToggleComponent,
  botDetails: BotDetailsComponent,
  userAvatar: UserAvatarComponent,
  companyAvatar: CompanyDisplayComponent,
  icon: IconComponent,
  toggleOrIcon: ToggleOrIconComponent,
  npsScore: NPSScoreComponent,
  revenueRange: RevenueRangeComponent,
  employeeRange: EmployeeRangeComponent,
  leadScore: LeadScoreComponent,
  signalScore: SignalsScoreComponent,
  opp: OpportunityStatusComponent
}

function OpportunityStatusComponent (props: any) {
  return (
    <div>
      <OppStatusText status={props.row?.opportunityStatus} />
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {formatRevenue(props.row?.oppAmountCents / 100 || 0).toString()}
      </div>
    </div>
  )
}

function SignalsScoreComponent (props: any): JSX.Element {
  const [salesOpen, setSalesOpen] = useState(false)
  return (
    <div>
      <SignalsScoreGear
        score={props.row.signalsScore}
        placement='left'
        domain={props.row.domain}
        noPadding
      />
      <ScoringUpgradeModal open={salesOpen} onHide={() => setSalesOpen(false)} />
    </div>
  )
}

function LeadScoreComponent (props: any): JSX.Element {
  const iconArray = []
  for (let i = 0; i < props.value; i++) {
    iconArray.push(<Icon icon='star' color='gold' key={i} />)
  }
  return (
    <div style={{ display: 'flex' }}>
      {iconArray.length === 0 ? '---' : iconArray}
    </div>
  )
}

function RevenueRangeComponent (props: any): JSX.Element {
  const range = props.value ? getRevenueRange(props.value) : '---'
  return (
    <TextComponent
      value={range}
    />
  )
}

function EmployeeRangeComponent (props: any): JSX.Element {
  const range = props.value ? getEmployeeRange(props.value) : '---'
  return (
    <TextComponent
      value={range}
    />
  )
}
function CompanyDisplayComponent (props: any): JSX.Element {
  const row = props.row
  const classes = useStyles()
  const name = row?.companyName || row?.name
  const initials = getInitials(name, 'A')
  const link = `/accounts/${row?.domain}`
  const avatarUrl = `/api/logo-service/logo/${props.row?.domain}.png`
  const isICP = row?.companyIsIcp || row?.companyIsICP
  const companyDisplayContent = (
    <div
      className={classes.accountAvatar}
    >
      <CFAvatar
        participantID={row?.domain?.charCodeAt(1)}
        initials={initials}
        size='lg'
        avatarUrl={avatarUrl}
        variant='square'
        alt={initials}
        status={undefined}
        user={undefined}
        contact={undefined}
        backgroundColor={undefined}
        color={undefined}
        useLogos={undefined}
        domain={undefined}
        conversation={undefined}
      />
      <Tooltip title={row?.companyName || row?.name}>
        <div className={classes.dataItemColumn}>
          <div className={classes.accountName}>
            {truncateString(row?.companyName || row?.name || row?.domain, 21, true)}
          </div>
          <div style={{ marginLeft: '8px', color: row?.domain ? '#00000080' : 'black', fontSize: row?.domain ? '14px' : '16px' }}>
            {row?.domain || '---'}
          </div>
        </div>
      </Tooltip>
      {isICP && (
        <div style={{ marginLeft: '10px', marginTop: '7px' }}>
          <Icon icon='icp' />
        </div>
      )}
    </div>
  )
  return (
    row?.domain ? (
      <Link to={`${link}`} target='_blank'>
        {companyDisplayContent}
      </Link>
    ) : companyDisplayContent
  )
}

function UserAvatarComponent (props: any): JSX.Element {
  const classes = useStyles()
  const { data } = User.loadAll()
  const user = data ? data.dict[props.value] : null
  const displayType = props.row.displayType || ''

  if (user) {
    return (
      <ObjectAvatar
        row={user}
      />
    )
  }

  return (
    <div
      className={classes.container}
    >
      <div>
        <Avatar
          src='https://chatfunnels.com/wp-content/uploads/2022/01/Logo_purple.svg'
          variant='rounded'
        />
      </div>
      <div className={classes.contactInfo}>
        <div style={{ display: 'flex' }}><h3 className={classes.nameParagraph}>Signals</h3></div>
        <p className={classes.emailParagraph}>Auto-generated {displayType}</p>
      </div>
    </div>
  )
}

function NPSScoreComponent (props: any): JSX.Element {
  const classes = useStyles()
  return (
    <div>
      <div className={classes.npsScore} style={{ backgroundColor: npsScoreColors[props.value] }}>
        {props.value}
      </div>
    </div>
  )
}

function BotDetailsComponent (props: any): JSX.Element {
  const classes = useStyles()
  const bot = props.row
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
      {bot.availableBot &&
        <Tooltip title='Default Available Bot' placement='left'>
          <CheckedIcon style={{ fill: '#8e5ae2', marginRight: 5 }} />
        </Tooltip>}
      {bot.unavailableBot &&
        <Tooltip title='Default Unavailable Bot' placement='left'>
          <XIcon style={{ fill: '#F0706F', marginRight: 5 }} />
        </Tooltip>}
      {bot.activeTestID &&
        <Link to={`/bot_tests/dashboard/${bot.activeTestID}`}>
          <Tooltip title='Click to view test report' placement='left'>
            <TestingBeakerIcon className={classes.beakerIcon} />
          </Tooltip>
        </Link>}
    </div>
  )
}

function ToggleComponent (props: {
  row: any
  value: boolean
  handleToggle: ToggleField['handleToggle']
}): JSX.Element {
  const row = props.row
  return (
    <StyledSwitch
      checked={props.value}
      toggle={() => props.handleToggle({ value: !props.value, ID: row.id, row })}
    />
  )
}

function PriorityComponent (props: any): JSX.Element {
  const classes = useStyles()
  const row = props.row
  const options = Array.from({ length: props.count }, (x, i) => i + 1)

  if (props.value) {
    return (
      <div style={{ display: 'center', alignItems: 'flex' }}>
        <Select
          variant='outlined'
          margin='dense'
          className={classes.select}
          value={props.value}
          classes={{ selectMenu: classes.selectMenu }}
          onChange={(e) => props.changePriority({ ID: row.id, priority: e.target.value })}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 400
              }
            }
          }}
        >
          {options.map(option => (
            <MenuItem value={option} key={option}>{option}</MenuItem>
          ))}
        </Select>
      </div>
    )
  }
  return (
    <div style={{ display: 'flex', alignItems: 'center', marginLeft: 25 }}>
      -------
    </div>
  )
}

function CreatedAndEditedComponent (props: any): JSX.Element {
  const classes = useStyles()
  const { data, isLoading } = User.loadAll()
  if (isLoading) {
    return <div />
  }
  const row = props.row
  const editUser = data ? data.dict[row.updatedUserID] : null
  const date = dateFormat(props.value, 'mmm dd, yyyy')
  const editedDate = dateFormat(row.lastEditedTimestamp, 'mmm dd, yyyy')
  const editString = 'Last edited ' + editedDate + (editUser ? ' by ' + editUser.name : '')
  return (
    <div>
      <div className={classes.text}>
        {date}
      </div>
      <div className={classes.time}>
        {editString}
      </div>
    </div>
  )
}

function AvatarGroupComponent (props: any): JSX.Element {
  const { data, isLoading } = props.objectType.loadAll()
  if (isLoading) {
    return <div />
  }
  const objects = props.value.map((id: number) => data.dict[id])
  return (
    <GroupedAvatars
      objects={objects}
    />
  )
}

function TextComponent (props: any): JSX.Element {
  const classes = useStyles()
  let display = props.value
  if (typeof display === 'string' && !props.noCapitalize) {
    display = display.charAt(0).toUpperCase() + display.slice(1)
  }
  // Assuming font size is 21px
  if (props.width && display) {
    const width = props.width + (props.width * 0.6)
    const fontSize = 21
    const charWidth = fontSize * 0.6
    const maxChars = Math.floor(width / charWidth)
    if (display.length > maxChars) {
      return (
        <Tooltip style={{ width: props.width }} className={classes.text} title={display}>
          <div>
            {display}
          </div>
        </Tooltip>
      )
    }
  }
  return (
    <div style={{ width: props.width || 'auto' }} className={classes.text}>
      {display || props.emptyState || '---'}
    </div>
  )
}

function DateComponent (props: any): JSX.Element {
  const classes = useStyles()
  const format = props.label.toLowerCase() === 'month' ? 'mmmm, yyyy' : 'mmm dd, yyyy'
  const date = props.value ? dateFormat(props.value, format) : '---'
  return (
    <div className={classes.text}>
      {date}
    </div>
  )
}

function DateTimeComponent (props: any): JSX.Element {
  const classes = useStyles()
  if (!props.value) {
    return (
      <div>
        <div className={classes.text}>
          ---
        </div>
      </div>
    )
  }
  const date = dateFormat(props.value, 'mmm dd, yyyy')
  const time = dateFormat(props.value, 'h:MM TT')
  return (
    <div>
      <div className={classes.text}>
        {date}
      </div>
      <div className={classes.time}>
        {time}
      </div>
    </div>
  )
}

function IconComponent (props: any): JSX.Element {
  const classes = useStyles()

  const value = props.value
  const status = props.data[`${value}`]

  const Icon = status.icon
  const tooltip = status.tooltip
  const style = status.style

  return (
    <Tooltip title={tooltip} placement='right'>
      <Icon className={classes.icon} style={style} />
    </Tooltip>
  )
}

function ToggleOrIconComponent (props: any): JSX.Element {
  const { value, data, row } = props

  if (value in data) {
    return IconComponent(props)
  } else {
    return (
      <StyledSwitch
        checked={row.toggleOn}
        toggle={() => props.handleToggle({ value: !row.toggleOn, ID: row.id, row })}
      />
    )
  }
}

function ConditionalLink<Class = Record<string, any>> ({ link, row, ...props }: {
  link: BasicField<Class>['link']
  row: Class
  children: JSX.Element
}): JSX.Element {
  const cleanedLink = useMemo<string | undefined>(() => link ? typeof link === 'string' ? link : link(row) : undefined, [link, row])

  if (cleanedLink) {
    return (
      <HiddenLink to={cleanedLink}>
        {props.children}
      </HiddenLink>
    )
  } else {
    return (
      <>
        {props.children}
      </>
    )
  }
}

export function CardColumn<Class = any> (props: {
  row: Class
  field: Field<Class>
  width?: number
}): JSX.Element {
  const { name, type, link, ...rest } = props.field
  const row = props.row
  let Component
  if (type === 'custom') {
    Component = props.field.component
  } else if (type === 'dynamic') {
    const value = row?.dynamicField(name as string)
    return (
      <TextComponent
        row={row}
        value={value}
        width={props.width}
        noCapitalize
        {...rest}
      />
    )
  } else {
    Component = fieldMapping[type]
  }

  if (link === '_blank') {
    return (
      <Component
        row={row}
        value={row[name]}
        {...rest}
      />
    )
  }
  return (
    <ConditionalLink
      row={row}
      link={link}
    >
      <Component
        row={row}
        value={row[name]}
        hasLink
        {...rest}
      />
    </ConditionalLink>
  )
}

interface CardContentsProps {
  row: any
  fields?: any[]
  actions?: any[]
}

export function CardContents ({ row, fields, actions }: CardContentsProps): JSX.Element {
  const { fields: contextFields, actions: contextActions } = useContext(TableContext)
  const fieldsToUse = fields !== undefined ? fields : contextFields
  const actionsToUse = actions !== undefined ? actions : contextActions

  return (
    <>
      {fieldsToUse.map(field => (
        <CardColumn
          row={row}
          field={field}
          key={field.name as string}
        />
      ))}
      {actionsToUse &&
        <ActionsComponent
          actions={actionsToUse}
          row={row}
        />}
    </>
  )
}
