import React, { useState, useContext, useEffect, useCallback } from 'react'
import ConversationTagsModal from './ConversationTagsModal'
import ConversationTagsTable from './ConversationTagsTable'
import { SessionContext } from 'session-context'
import { saveTag, deleteTags, tagSearch } from 'api/tags'
import DeleteModal from 'components/DeleteModal'
import AppPage from 'cf-components/AppPage'

function ConversationTagsPage (props) {
  const { user } = useContext(SessionContext)
  const [chatServiceUrl] = useState(user.links.chat_service || 'localhost:8002/api/chat-service/a')
  const [token, setToken] = useState(null)
  const [tags, setTags] = useState(null)
  const [modalShow, setModalShow] = useState(false)
  const [deleteTagID, setDeleteTagID] = useState(0)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [modalState, setModalState] = useState({
    tag_name: '',
    description: '',
    is_goal: false
  })

  function openModal () {
    setModalState({
      tag_name: '',
      description: '',
      is_goal: false
    })
    setModalShow(true)
  }

  function editTag (name, description, isGoal, id) {
    setModalState({
      tag_name: name,
      description: description,
      is_goal: isGoal,
      id: id,
      edit: true
    })
    setModalShow(true)
  }

  function beginDelete () {
    deleteTags({ tag_ids: [deleteTagID], token, chatServiceUrl })
      .then(response => {
        loadTags()
        setDeleteTagID(0)
        setDeleteModalOpen(false)
      })
  }

  const loadTags = useCallback(() => {
    tagSearch({ search: '', token, chatServiceUrl }).then(response => {
      setTags(response.data)
    })
  }, [token, chatServiceUrl])

  function save (tag_name, description, is_goal, id = null) {
    saveTag({ tag_name, description, is_goal, token, chatServiceUrl, id }).then(response => {
      loadTags()
      setModalShow(false)
      setModalState({
        tag_name: '',
        description: '',
        is_goal: false
      })
    })
  }

  useEffect(() => {
    const headers = {
      'Content-Type': 'application/vnd.api+json'
    }
    fetch('/api/auth/token', {
      method: 'GET',
      headers: headers
    })
      .then(response => response.json())
      .then(account_response => {
        setToken(account_response.token)
      })
  }, [])

  useEffect(() => {
    loadTags()
  }, [loadTags])

  const deleteMessage = 'Are you sure you want to delete this tag?'
  const deleteTitle = 'Delete tag'

  document.title = 'Conversation Tags | Signals'

  return (
    <AppPage
      title='Conversation Tags'
      action={openModal}
      actionText='Create New Tag'
    >
      <ConversationTagsTable
        rows={tags}
        editTag={editTag}
        handleDelete={(tagID) => {
          setDeleteTagID(tagID)
          setDeleteModalOpen(true)
        }}
      />
      <ConversationTagsModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        state={modalState}
        onChange={setModalState}
        saveTag={save}
      />
      <DeleteModal
        open={deleteModalOpen}
        onHide={() => setDeleteModalOpen(false)}
        deleteObject={beginDelete}
        message={deleteMessage}
        title={deleteTitle}
      />
    </AppPage>
  )
}

export default ConversationTagsPage
