import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Modal, ModalSection } from 'library/Modal'
import { TextField } from '@material-ui/core'
import { getBotTests } from 'api/bot_tests'
import { previewQuantityLimit } from 'api/billing'

const useStyles = makeStyles(theme => ({
  warningMessage: {
    padding: 20,
    backgroundColor: 'rgba(232, 167, 2, 0.5)',
    marginTop: 20,
    borderRadius: 10
  }
}))

const CreateTestModal = props => {
  const classes = useStyles()
  const [name, setName] = useState('')
  const [maxActiveTestsReached, setMaxActiveTestsReached] = useState(false)
  const chatServiceUrl = props.chatServiceUrl

  function remapEnterKey (event) {
    if (event.key === 'Enter') {
      handleSubmit()
    }
  }

  const handleSubmit = () => {
    props.setName(name)
    props.setTestType('ab')
    props.onSubmit()
  }

  useEffect(() => {
    const isTestActive = (test) => {
      const row = test.attributes
      if (row.status === 'active' || row.status === 'paused') {
        if (new Date() > new Date(row.end_time) && row.auto_promote) {
          return false
        }
        return true
      }
      return false
    }

    if (props.open) {
      getBotTests({ chatServiceUrl })
        .then(response => {
          let quantity = 0
          const tests = response.data
          for (const test of tests) {
            if (isTestActive(test)) {
              quantity += 1
            }
          }
          previewQuantityLimit('active_bot_tests', quantity).then((result) => {
            const allowed = result.data.attributes.allowed
            if (!allowed) {
              setMaxActiveTestsReached(true)
            }
          })
        })
    }
  }, [chatServiceUrl, props.open])

  const disabled = maxActiveTestsReached || !name

  return (

    <Modal
      open={props.open}
      onHide={props.onHide}
      title='Create New Test'
      saveBtnText='Create Test'
      handleSave={handleSubmit}
      saveDisabled={disabled}
      saveIcon='add'
    >
      <ModalSection
        subtitle='Test two different variants of the same bot against each other.'
      />
      <ModalSection
        title='Test Name'
      >
        <TextField
          label='Test Name'
          name='name'
          fullWidth
          variant='outlined'
          margin='dense'
          onChange={e => setName(e.target.value)}
          onKeyPress={remapEnterKey}
        />
      </ModalSection>
      {maxActiveTestsReached &&
        <div className={classes.warningMessage}>
          Your have reached the maximum number of active tests for your subscription.
          To learn more about our plans,
          <a href='https://chatfunnels.com/pricing/' target='_blank' rel='noopener noreferrer'>
            click here.
          </a>
        </div>}
    </Modal>
  )
}

export default CreateTestModal
