import ChatPage from './chat/agent-chat/AgentChat'
import MobileRedirect from './MobileRedirect'
import { Route, Redirect, Switch } from 'react-router-dom'
import { PostageSocketProvider } from 'components/PostageSocket'
import { QuickChatPage } from './chat/mobile-chat/QuickChatPage'

function MobileApp () {
  return (
    <PostageSocketProvider>
      <Route exact path={['/', '/dashboard']}>
        <Redirect to='/chat' />
      </Route>
      {/* sends all mobile users to agent chat or desert island if navigating to unsupported page */}
      <Switch>
        <Route path='/chat/' render={() => <ChatPage />} />
        <Route path='/quick-chat/' render={() => <QuickChatPage />} />
        <Route path='/' render={() => <MobileRedirect />} />
      </Switch>
    </PostageSocketProvider>
  )
}

export default MobileApp
