import { makeStyles } from '@material-ui/core/styles'
import { Button, Icon } from 'library/materialUI'
import { nodeType } from 'pages/plays/playBuilder/nodes/nodeMapping'
import { SectionProps } from './HelpCenter'
import { HelpCenterIcon } from './HelpCenterCard'
import ReactPlayer from 'react-player/youtube'
import { playDocsMapping, botDocsMapping, playMapping, botMapping, settingsMapping } from './helpCenterMapping'
import { nodeType as botNodeType } from 'pages/bots/docs/nodeMapping'
import { useContext } from 'react'
import { HelpCenterContext } from './HelpCenterContext'

const useStyles = makeStyles(theme => ({
  nodeSettingsHeader: {
    display: 'grid',
    gridGap: 15,
    gridTemplateColumns: '50px auto',
    alignItems: 'center',
    marginTop: 20,
    marginBottom: 20
  },
  nodeTitle: {
    font: 'normal normal 600 24px/35px Poppins',
    color: '#000000BF'
  },
  sectionTitle: {
    font: 'normal normal 600 18px/27px Poppins',
    color: '#000000BF',
    marginTop: 15,
    marginBottom: 15
  },
  text: {
    font: 'normal normal normal 14px/21px Poppins',
    color: '#000000BF',
    marginBottom: 10
  },
  descriptionSection: {
    paddingBottom: 20,
    borderBottom: '1px solid #0000001A'
  },
  section: {
    marginTop: 20,
    marginBottom: 20
  }
}))

type SettingsProps = {
  nodeType: nodeType | botNodeType,
  flowType: string
}

type DescriptionProps = {
  children: JSX.Element
}

function NodeSettingsHeader ({ nodeType, flowType }: SettingsProps) {
  const classes = useStyles()

  let node = playMapping[nodeType]
  if (flowType === 'bots') {
    node = botMapping[nodeType]
  }
  if (flowType === 'settings') {
    node = settingsMapping[nodeType]
  }
  return (
    <div className={classes.nodeSettingsHeader}>
      <HelpCenterIcon size='small' icon={node.icon} />
      <div className={classes.nodeTitle}>
        {node.title}
      </div>
    </div>
  )
}

function NodeDescription ({ children }: DescriptionProps) {
  const classes = useStyles()
  return (
    <div className={classes.descriptionSection}>
      <div className={classes.sectionTitle}>
        Description
      </div>
      <div className={classes.text}>
        {children}
      </div>
    </div>
  )
}

function BestPracticesSection ({ children }: DescriptionProps) {
  const classes = useStyles()

  return (
    <div className={classes.section}>
      <div className={classes.sectionTitle}>
        Best Practices
      </div>
      <div className={classes.text}>
        {children}
      </div>
    </div>
  )
}

function HowItWorksSection ({ children }: DescriptionProps) {
  const classes = useStyles()

  return (
    <div className={classes.section}>
      <div className={classes.sectionTitle}>
        How It Works
      </div>
      <div className={classes.text}>
        {children}
      </div>
    </div>
  )
}

function HelpCenterLink ({ link }: { link: string }) {
  const classes = useStyles()
  return (
    <div className={classes.section}>
      <div className={classes.text}>
        Need more help?
      </div>
      <div>
        <Button
          variant='outlined'
          size='small'
          endIcon={<Icon icon='exit' />}
          onClick={() => window.open(link, '_blank')}
        >
          Go to Helpdocs
        </Button>
      </div>
    </div>
  )
}

function NodeSettingsOverview ({ nodeType, flowType }: SettingsProps) {
  let docs = playDocsMapping[nodeType]
  if (flowType === 'bots') {
    docs = botDocsMapping[nodeType]
  }
  if (flowType === 'settings') {
    docs = settingsMapping[nodeType]
  }
  const Description = docs.description
  const BestPractices = docs.bestPractices
  const HowItWorks = docs.howItWorks
  return (
    <div>
      <NodeDescription>
        <Description />
      </NodeDescription>
      {HowItWorks ? (
        <HowItWorksSection>
          <HowItWorks />
        </HowItWorksSection>
      )
      : <></>}
      {BestPractices && (
        <BestPracticesSection>
          <BestPractices />
        </BestPracticesSection>)}
      {docs.videoLink &&
        <ReactPlayer
          url={docs.videoLink}
          height={208}
          width={370}
        />}
      {docs.helpCenterLink &&
        <HelpCenterLink
          link={docs.helpCenterLink}
        />}
    </div>
  )
}

export function NodeSettings (props: SectionProps) {
  const { links } = props
  const { pathLinks } = useContext(HelpCenterContext)
  const nodeType = links[0]
  const flowType = pathLinks[1]
  return (
    <div>
      <NodeSettingsHeader
        nodeType={nodeType}
        flowType={flowType}
      />
      <NodeSettingsOverview
        nodeType={nodeType}
        flowType={flowType}
      />
    </div>
  )
}
