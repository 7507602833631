import Card from 'library/materialUI/Card'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { Icon } from 'library/materialUI'
import TextField from '@material-ui/core/TextField'
import { useContext, useEffect, useReducer, useState } from 'react'
import { TabContext } from '../IntegrationPage/TabProvider'
import { IntegrationPageContext } from '../IntegrationPage/IntegrationPageProvider'
import { INTEGRATIONS, getCredentialsStatus, setCredentials } from 'api/integrations'
import Loader from 'library/loading/Loader'

const useStyles = makeStyles((theme) => ({
  subtitle1: {
    fontSize: 18,
    fontWeight: 600,
    marginBottom: 15,
    color: '#000000CC'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  bodyText: {
    marginBottom: 30,
    fontSize: 14
  },
  lineItem: {
    marginTop: '12px',
    color: '#000000BF',
    size: '15px'
  }
}))

const initialValues = {
  username: '',
  password: '',
  apiKey: '',
  secretKey: ''
}

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case 'username':
      return { ...state, username: action.value }
    case 'password':
      return { ...state, password: action.value }
    case 'apiKey':
      return { ...state, apiKey: action.value }
    case 'secretKey':
      return { ...state, secretKey: action.value }
    default:
      return state
  }
}

export default function ConnectAndSellAuthCard (): JSX.Element {
  const classes = useStyles()
  const [state, dispatch] = useReducer(reducer, initialValues)
  const { setCanSave, shouldSave } = useContext(TabContext)
  const { handleSaved } = useContext(IntegrationPageContext)
  const [hasCredentials, setHasCredentials] = useState(false)
  const [loading, setLoading] = useState(true)

  const save = () => {
    setCredentials('connectandsell', [{
      attributes: {
        name: 'username',
        value: state.username,
        secret: false
      }
    },
    {
      attributes: {
        name: 'password',
        value: state.password,
        secret: true
      }
    },
    {
      attributes: {
        name: 'api_key',
        value: state.apiKey,
        secret: false
      }
    },
    {
      attributes: {
        name: 'secret_key',
        value: state.secretKey,
        secret: false
      }
    }])
  }

  useEffect(() => {
    if (shouldSave) {
      save()
      handleSaved()
      setCanSave(false)
    }
  })

  useEffect(() => {
    getCredentialsStatus(INTEGRATIONS.ConnectAndSell)
      .then((response) => {
        if (response.data) {
          setHasCredentials(response?.data?.attributes?.has_credentials)
          setLoading(false)
        } else {
          setLoading(false)
        }
      })
  }, [])

  if (loading) {
    return <div style={{ marginTop: '30vh' }}><Loader type='spinner' /></div>
  }

  return (
    <Card
      content={
        <>
          <div className={classes.header}>
            <Typography variant='subtitle1' className={classes.subtitle1}>
              Authorization Settings
            </Typography>
          </div>
          <Typography variant='body2' className={classes.bodyText}>
            Enter the required authorization credentials and keys to connect Signals to ConnectAndSell
          </Typography>
          <div style={{ display: 'flex', backgroundColor: '#EDF1FA', borderRadius: '5px', padding: '20px', fontSize: '14px' }}>
            <div style={{ marginRight: '10px' }}>
              <Icon icon='info' size='md' color='#547BD0' />
            </div>
            <div>
              To obtain your API Login ID, API Key, and Secret Key, contact ConnectAndSell support via email (support@connectandsell.com) or phone (1-800-893-5056).
            </div>
          </div>
          <div className={classes.lineItem}>
            <div>
              API Login ID:
            </div>
            <div>
              <TextField
                value={state.username}
                onChange={(e) => { dispatch({ type: 'username', value: e.target.value }); setCanSave(true) }}
                margin='dense'
                variant='outlined'
                placeholder={hasCredentials ? '********' : ''}
              />
            </div>
          </div>
          <div className={classes.lineItem}>
            <div>
              ConnectAndSell Account Password:
            </div>
            <div>
              <TextField
                value={state.password}
                onChange={(e) => { dispatch({ type: 'password', value: e.target.value }); setCanSave(true) }}
                margin='dense'
                variant='outlined'
                type='password'
                placeholder={hasCredentials ? '***************' : ''}
              />
            </div>
          </div>
          <div className={classes.lineItem}>
            <div>
              API Key:
            </div>
            <div>
              <TextField
                value={state.apiKey}
                onChange={(e) => { dispatch({ type: 'apiKey', value: e.target.value }); setCanSave(true) }}
                margin='dense'
                variant='outlined'
                placeholder={hasCredentials ? '***********' : ''}
              />
            </div>
          </div>
          <div className={classes.lineItem}>
            <div>
              Secret Key:
            </div>
            <div>
              <TextField
                value={state.secretKey}
                onChange={(e) => { dispatch({ type: 'secretKey', value: e.target.value }); setCanSave(true) }}
                margin='dense'
                variant='outlined'
                placeholder={hasCredentials ? '***********' : ''}
              />
            </div>
          </div>
        </>
      }
    />
  )
}
