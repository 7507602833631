import { useContext, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles'
import { PhotoDropZone } from './PhotoDropZone';
import { PhotoEditor } from './PhotoEditor';
import { Button, Icon, IconButton, Tooltip } from 'library/materialUI';
import base64 from 'base64-js'
import User from 'classes/users';
import { UserContext } from 'pages/onboarding/personalSetup/UserContext';

const useStyles = makeStyles(theme => ({
  photoUpload: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 280
  },
  photoHandler: {
    display: 'inline-flex',
    flexDirection: 'row-reverse',
    height: '100%',
    width: 230
  },
  photoPreview: {
    padding: 5
  },
  previewText: {
    font: 'normal normal normal 12px/25px Poppins',
    textAlign: 'center'
  },
  linkedInButton: {
    backgroundColor: '#D6E1EF',
    color: 'black'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  orText: {
    display: 'flex',
    justifyContent: 'center',
    color: '#8B8B8B',
    font: 'normal normal normal 12px/25px Poppins',
    padding: 5
  }
}))

// type ButtonPropsType = {
//   icon: IconType
//   title: string
// }

// type PhotoPreviewProps = {
//   photo: any
// }
// function PhotoPreview (props: PhotoPreviewProps) {
//   const classes = useStyles()
//   return (
//     <div className={classes.photoPreview}>
//       <img src={props.photo} style={{ borderRadius: 200 }} alt='Preview' />
//       <div className={classes.previewText}>
//         This is how your photo will appear in chat
//       </div>
//     </div>
//   )
// }

function PhotoHandler (props: any) {
  const classes = useStyles()

  return (
    <div className={classes.photoHandler}>
      <div>
        <Tooltip title='Change photo'>
          <IconButton size='small' onClick={props.newPhoto}>
            <Icon icon='addPhoto' color='primary' size='md' />
          </IconButton>
        </Tooltip>
      </div>
      <PhotoEditor
        {...props}
      />
    </div>
  )
}

type PhotoUploadProps = {
  saveRef: any
}

export function PhotoUpload (props: PhotoUploadProps) {
  const classes = useStyles()
  const { user } = useContext(UserContext)
  const [photo, setPhoto] = useState<any>(user.avatarUrl)
  const editorRef = useRef<any>(null)

  props.saveRef.current = () => {
    if (editorRef.current) {
      const img = base64.toByteArray(
        editorRef.current
          .getImageScaledToCanvas()
          .toDataURL('image/png')
          .replace(/^data:image\/(png|jpg);base64,/, '')
      )
      return User.postPhoto({ ID: user.id, img })
    }
  }

  const handleSetPhoto = (file: any) => {
    setPhoto(file)
    props.saveRef.current()
  }

  return (
    <div className={classes.photoUpload}>
      {photo
        ? (
          <PhotoHandler
            photo={photo}
            editorRef={editorRef}
            newPhoto={() => setPhoto(null)}
          />
        ) : (
          <div>
            <PhotoDropZone
              handler={handleSetPhoto}
            />
            <div className={classes.orText}>--or--</div>
            <div className={classes.buttonContainer}>
              <Button
                variant='contained'
                endIcon={<Icon icon='linkedin' color='#1A5FB8' />}
                size='small'
                classes={{ root: classes.linkedInButton }}
                onClick={User.linkedInOauth}
              >
                Use LinkedIn Photo
              </Button>
            </div>
          </div>
        )}
    </div>
  )
}
