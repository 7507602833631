import { createContext, ReactNode } from 'react'

interface MyProfileValues {
  profileDisabled: boolean
  updateContactInfo: () => void
}

export const MyProfileContext = createContext<MyProfileValues>({
  profileDisabled: false,
  updateContactInfo: () => undefined
})

interface MyProfileProviderProps {
  profileDisabled: boolean
  saveChanges: () => void
  children: ReactNode
}

export function MyProfileProvider ({ children, saveChanges, profileDisabled } : MyProfileProviderProps) : JSX.Element {
  return (
    <MyProfileContext.Provider value={{
      profileDisabled,
      updateContactInfo: saveChanges
    }}
    >
      {children}
    </MyProfileContext.Provider>
  )
}
