import { useEffect, useState, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import { getSegmentsFromDomain } from 'api/segments'
import EmptyState from 'cf-components/EmptyState'
import { AvatarWithInfo, getInitials } from 'cf-components/Avatars'
import { ListItemCard } from 'cf-components/CardList'
import BaseCard from './BaseCard'
import { getSegmentColor } from 'pages/segments/segmentHelpers'
import { ConversationContext } from '../../ConversationContext'

const listItemHeight = 51

const useStyles = makeStyles(theme => ({
  link: {
    textDecoration: 'none',
    color: 'inherit',
    width: '100%',
    height: '100%',
    cursor: 'pointer'
  },
  segmentCard: {
    margin: 4
  },
  listCtn: {
    maxHeight: (listItemHeight * 4) + 4,
    overflowY: 'auto',
    padding: 2
  }
}))

export default function SegmentsCard (props) {
  const { conversation } = useContext(ConversationContext)
  const contactID = conversation?.relationships?.for_contact?.data.id
  const companyDomain = conversation?.attributes?.domain

  return (
    <BaseCard title='Segments' id={props.id}>
      <SegmentsCardContent
        contactID={contactID}
        companyDomain={companyDomain}
      />
    </BaseCard>
  )
}

/**
 * @param {{ companyDomain?: string; }} props
 */
function SegmentsCardContent (props) {
  const classes = useStyles()
  const { companyDomain } = props
  const [segments, setSegments] = useState([])
  useEffect(() => {
    if (companyDomain) {
      getSegmentsFromDomain(companyDomain)
      .then(res => {
        if (res?.data) {
          setSegments(res.data)
        }
      })
    } else {
      setSegments([])
    }
  }, [companyDomain])

  if (!segments.length) {
    return (
      <EmptyState
        sad
        message='No segments to display'
      />
    )
  }

  return (
    <div className={classes.listCtn}>
      {segments.map((segment, i) => (
        <SegmentRow
          key={segment.id}
          segment={segment}
          index={i}
          totalCount={segments.length}
        />
      ))}
    </div>
  )
}

function SmallSegmentAvatar (props) {
  const style = {
    contactInfo: {
      paddingLeft: 10,
      fontSize: 14
    },
    nameParagraph: {
      paddingBottom: 2
    }
  }

  return <AvatarWithInfo style={style} {...props} />
}

/**
 * @param {{ segment: any; index: number; totalCount: number; }} props
 */
function SegmentRow (props) {
  const classes = useStyles()
  const { segment, index, totalCount } = props

  const objectType = segment.attributes.object_type
  const color = getSegmentColor(index)
  const marginBottom = index === (totalCount - 1) ? 0 : 6
  const initials = getInitials(segment.attributes.name, 'S')
  const subtitle = `${objectType?.charAt(0).toUpperCase()}${objectType?.slice(1)}: ${segment.attributes.object_count || '--'}`
  const linkUrl = `/${objectType.slice(0, -1) === 'domain' ? 'account' : objectType.slice(0, -1)}_segments/${segment.id}`

  return (
    <div className={classes.listItem}>
      <ListItemCard style={{ marginBottom }}>
        <Link to={linkUrl} className={classes.link} target='_blank'>
          <div className={classes.segmentCard}>
            <SmallSegmentAvatar
              title={segment.attributes.name}
              initials={initials}
              avatarColor={color}
              subtitle={subtitle}
              variant='rounded'
            />
          </div>
        </Link>
      </ListItemCard>
    </div>
  )
}
