
function loadTrackingEvents ({ chatServiceUrl, token, contactList }) {
  const headers = {
    'Content-Type': 'application/vnd.api+json',
    Authorization: `Bearer ${token}`
  }
  return fetch(`https://${chatServiceUrl}/participant_events?sort=-id&filter[kind]=contact_created,conversation_started,page&filter[person_id]=` + contactList, {
    method: 'GET',
    headers: headers
  })
    .then(response => response.json())
}

export { loadTrackingEvents }
