import { Typography } from 'library/materialUI'
import LeadTimeExplanation from 'img/lead_time_explanation.png'
import BuffertimeExplanation from 'img/buffer_time_explanation.png'

const CalendarSettingsDescription = (): JSX.Element => {
  return (
    <div>
      <Typography variant='h2'>Lead Time</Typography>
      <Typography variant='body2'>The minimum time between now and when the next meeting can be scheduled</Typography>
      <img src={LeadTimeExplanation} alt='lead time' style={{ maxWidth: 370, marginBottom: 10 }} />
      <Typography variant='h2'>Buffer Time</Typography>
      <Typography variant='body2'>The minimum amount of time between when one meeting ends and the next meeting starts</Typography>
      <img src={BuffertimeExplanation} alt='buffer time' style={{ maxWidth: 370 }} />
    </div>
  )
}

export const CalendarDocs = {
  helpCenterLink: 'https://help.getsignals.ai/article/wgr5xzf4ea-02-my-calendar#meeting_settings',
  description: CalendarSettingsDescription,
  videoLink: '',
  icon: 'calendar',
  title: 'Meeting Settings'
}
