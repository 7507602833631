import { useContext, useReducer } from 'react'
import { Select } from 'cf-components/material-wrappers/MaterialComponents'
import { HelpLink, ModalSection } from 'library/Modal'
import { MeetingSettings } from 'classes/meetingSettings'
import { SessionContext } from 'session-context'
import { ContentsProps } from '../wizard/OnboardingWizard'
import { Typography } from 'library/materialUI'
import { makeStyles } from '@material-ui/core/styles'
import { reducer } from 'library/helpers'

const useStyles = makeStyles(theme => ({
  titleDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  helpLink: {

  }
}))

const bufferTimes = [
  { value: 0, label: 'No Buffer' },
  { value: 15, label: '15 minutes' },
  { value: 30, label: '30 minutes' },
  { value: 45, label: '45 minutes' },
  { value: 60, label: '1 hour' },
  { value: 90, label: '90 minutes' },
  { value: 120, label: '2 hours' }
]

const leadTimes = [
  ...bufferTimes,
  { value: 180, label: '3 hours' },
  { value: 240, label: '4 hours' },
  { value: 300, label: '5 hours' },
  { value: 360, label: '6 hours' },
  { value: 420, label: '7 hours' },
  { value: 480, label: '8 hours' },
  { value: 720, label: '12 hours' },
  { value: 1440, label: '24 hours' },
  { value: 2880, label: '48 hours' }
]

export const bufferTimeSubtitle = 'Customize how your meetings are scheduled'
type BufferTimeProps = {
  handleNextRef: any
  initialState: any
}

function BufferTime (props: BufferTimeProps) {
  const [state, dispatch] = useReducer(reducer, props.initialState)
  const classes = useStyles()
  props.handleNextRef.current = () => {
    const updates: Partial<MeetingSettings> = {
      bufferTime: state.bufferTime,
      leadTime: state.leadTime,
      id: state.settingsID
    }
    MeetingSettings.save(state.userID, updates)
  }
  return (
    <div>
      <ModalSection
        title={<div className={classes.titleDiv}><Typography>Lead Time</Typography><HelpLink helplink='home/settings/options/calendar' className={classes.helpLink} /></div>}
      >
        <Select
          value={state.leadTime}
          onChange={(value: number) => dispatch({ leadTime: value })}
          options={leadTimes}
          disableClear
        />
      </ModalSection>
      <ModalSection
        title={<div className={classes.titleDiv}><Typography>Buffer Time</Typography><HelpLink helplink='home/settings/options/calendar' className={classes.helpLink} /></div>}
      >
        <Select
          value={state.bufferTime}
          onChange={(value: number) => dispatch({ bufferTime: value })}
          options={bufferTimes}
          disableClear
        />
      </ModalSection>
    </div>
  )
}

export function BufferTimeSection (props: ContentsProps) {
  const { userObject } = useContext(SessionContext)
  const { data: settings } = MeetingSettings.load({ userID: userObject.id })

  if (!settings?.id || !userObject?.id) {
    return (
      <div />
    )
  }

  const initialState = {
    bufferTime: settings.bufferTime,
    leadTime: settings.leadTime,
    userID: userObject.id as number,
    settingsID: settings.id as number
  }

  return (
    <BufferTime
      initialState={initialState}
      handleNextRef={props.handleNextRef}
    />
  )
}
