import { getTenantAPICallCount, getTenantMetric } from 'api/admin_only/tenants'
import { QueryFilterList } from 'classes/queryHelpers'
import { Chart } from 'library/charts/Chart'
import { SparkLineChartProps } from 'classes/report'
import { useReducer, useEffect, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Icon } from 'library/materialUI'
import { Card } from '@material-ui/core'
import { IconType } from 'library/materialUI/Icon'
import { getTenantROIOppSummaryReport } from 'api/reports'
import { dateHelper } from 'cf-components/DateRangeSelector'
import InformationIcon from 'library/InformationIcon'
import SkeletonLabel from 'library/loading/skeleton/SkeletonLabel'

const useStyles = makeStyles(theme => ({
  card: {
    marginRight: 20,
    marginBottom: 15
  },
  cardGrid: {
    display: 'grid',
    gridTemplateColumns: '1fr 4fr 1fr',
    gridGap: 5,
    padding: 10
  },
  value: {
    display: 'inline-block',
    fontSize: 30,
    fontWeight: 600,
    width: '40%'
  },
  chart: {
    height: 40,
    width: '60%',
    display: 'inline-block'
  },
  valueChangeDiv: {
    textAlign: 'right'
  }
}))

interface crmRowParams {
  Amount: number
  StageName: string
  attributes: { type: string }
}

interface summaryPropTypes {
  tenantId?: number
  userLength: number
  dateRange: {
    startDate: Date
    endDate: Date
  }
}

interface valueListTypes {
  title: string
  icon: IconType
  hasChart?: boolean
  iconColor: string
  tooltip: string
}

/* eslint-disable */
const cards: Record<string, valueListTypes> = {
  api_calls: { title: 'Reverse IP Lookups', icon: 'search', iconColor: '#68B7D7', tooltip: 'Number of Sixsense Reverse IP lookups', hasChart: true },
  pipeline_generated: { title: 'Pipeline Generated', icon: 'funnel', iconColor: '#C6ADF1', tooltip: 'Amount of pipeline generated by Signals (requires Salesforce integration)' },
  pipeline_closed: { title: 'Pipeline Closed', icon: 'dollarFunnel', iconColor: '#81AF20', tooltip: 'Amount of pipeline closed by Signals (requires Salesforce integration)' },
  user_count: { title: 'User Count', icon: 'person', iconColor: '#A2DC2B', tooltip: 'Current number of users' },
  visitors: { title: 'Visitors', icon: 'pointer', iconColor: '#000000', tooltip: 'Number of visitors for the given date range', hasChart: true },
  page_views: { title: 'Page Views', icon: 'world', iconColor: '#000000', tooltip: 'Number of page views for the given date range', hasChart: true },
  active_bots: { title: 'Bot Count', icon: 'botHeadAlt', iconColor: '#000000', tooltip: 'Number of bots in use by Plays' },
  prompts: { title: 'Bot Prompts', icon: 'funnel', iconColor: '#C6ADF1', tooltip: 'Number of bot prompts for the given date range', hasChart: true },
  conversations: { title: 'Conversations', icon: 'chat', iconColor: '#F3D450', tooltip: 'Number of conversations for the given date range', hasChart: true },
  live_chats: { title: 'Live Chats', icon: 'liveChat', iconColor: '#E76033', tooltip: 'Number of live chats for the given date range', hasChart: true },
  meetings_booked: { title: 'Meetings Booked', icon: 'dateRange', iconColor: '#954BE8', tooltip: 'Number of meetings booked for the given date range', hasChart: true },
  emails_captured: { title: 'Emails Captured', icon: 'at', iconColor: '#4972CC', tooltip: 'Number of emails captured for the given date range, hasChart:true' }
}
/* eslint-enable */

const reducer = (state: any, changes: any) => {
  return { ...state, ...changes }
}
export const AdminTenantSummary = (props: summaryPropTypes): JSX.Element => {
  const [values, setValues] = useReducer(reducer, { user_count: props.userLength.toLocaleString('en-US') }) // eslint-disable-line
  const classes = useStyles()
  const tenantId = props.tenantId
  const { dateRange } = useMemo(() => {
    return {
      dateRange: {
        startDate: dateHelper.dateToString(props.dateRange.startDate),
        endDate: dateHelper.dateToString(props.dateRange.endDate)
      }
    }
  }, [props.dateRange])

  const reportProps: SparkLineChartProps = {
    object: 'tenant',
    reportType: 'sparkLineChart',
    column: 'page_views',
    category: 'period',
    timePeriod: 'day',
    admin: true,
    tenantID: tenantId,
    skipWeekends: false
  }

  const filters: QueryFilterList = [
    [
      { field: 'start_date', operator: 'gte', value: dateRange.startDate },
      { field: 'end_date', operator: 'lte', value: dateRange.endDate }
    ]
  ]

  useEffect(() => {
    if (tenantId) {
      getTenantAPICallCount({ tenantId, dateRange })
        .then(response => {
          const apiCalls = response.data.attributes.calls || 0
          setValues({ api_calls: apiCalls.toLocaleString('en-US') }) // eslint-disable-line
        })
    }
    return () => {
      setValues({ api_calls: null }) // eslint-disable-line
    }
  }, [tenantId, dateRange]) // eslint-disable-line

  useEffect(() => {
    if (tenantId) {
      Object.keys(cards).forEach(key => {
        const tenantMetrics = [
          'visitors',
          'page_views',
          'active_bots',
          'prompts',
          'conversations',
          'live_chats',
          'meetings_booked',
          'emails_captured'
        ]
        if (tenantMetrics.includes(key)) {
          getTenantMetric({ tenantId, dateRange, metric: key }).then(response => {
            setValues({ [key]: response.data.attributes.count.toLocaleString('en-US') })
          })
        }
      })
    }
    return () => {
      setValues({
        visitors: null,
        page_views: null, // eslint-disable-line
        active_bots: null, // eslint-disable-line
        prompts: null,
        conversations: null,
        live_chats: null, // eslint-disable-line
        meetings_booked: null, // eslint-disable-line
        emails_captured: null // eslint-disable-line
      })
    }
  }, [tenantId, dateRange]) // eslint-disable-line

  useEffect(() => {
    if (tenantId) {
      getTenantROIOppSummaryReport({ tenantID: tenantId, start: dateRange.startDate, end: dateRange.endDate })
        .then(response => {
          const oppSummary = response.data.attributes.opp_summary
          let pipelineGenerated = 0
          let pipelineClosed = 0
          oppSummary.forEach((e: crmRowParams) => {
            pipelineGenerated += e.Amount
            if (e.StageName === 'Closed Won') {
              pipelineClosed += e.Amount
            }
          })
          setValues({
            pipeline_generated: '$' + pipelineGenerated.toLocaleString('en-US'), // eslint-disable-line
            pipeline_closed: '$' + pipelineClosed.toLocaleString('en-US') // eslint-disable-line
          })
        })
    }
    return () => {
      setValues({
        pipeline_generated: null, // eslint-disable-line
        pipeline_closed: null // eslint-disable-line
      })
    }
  }, [tenantId, dateRange]) // eslint-disable-line

  return (
    <div>
      {Object.keys(cards).map((k) => {
        const card = cards[k]
        const v = values[k]
        const loading = v === null || v === undefined
        return (
          <Card raised className={classes.card} key={k}>
            <div className={classes.cardGrid}>
              <Icon icon={card.icon} size='lg' color={card.iconColor} />
              <div>
                <div>{card.title}</div>
                <div className={classes.value}>
                  {loading ? <SkeletonLabel size='full' /> : v}
                </div>
                {card.hasChart && (
                  <div className={classes.chart}>
                    <Chart
                      reportProps={{ ...reportProps, column: k }}
                      filters={filters}
                    />
                  </div>
                )}
              </div>
              <div className={classes.valueChangeDiv}>
                <InformationIcon message={card.tooltip} />
              </div>
            </div>
          </Card>
        )
      })}
    </div>
  )
}
