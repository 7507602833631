import { makeStyles } from '@material-ui/core/styles'
import { Usage } from 'classes/prospectUsage'
import { Table } from 'library/table/Table'
import { Actions, Fields } from 'cf-components/table/Table'
import Loader from 'library/loading/Loader'

const useStyles = makeStyles(theme => ({
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    margin: '10px'
  },
  headerItem: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    width: '100%',
    margin: '10px',
    padding: '10px',
    border: '1px solid #0000001A',
    borderRadius: '5px'
  },
  headerTitle: {
    textAlign: 'left',
    fontSize: '14px'
  },
  headerMetric: {
    textAlign: 'left',
    fontSize: '30px',
    fontWeight: 'bold'
  }
}))

interface UsageProspectPageProps {
  component: string
}

export default function UsageProspectsPage ({ component }: UsageProspectPageProps) {
  const classes = useStyles()
  const fields: Fields<Usage> = [
    { name: 'tenantName', label: 'Tenant Name', type: 'text' },
    { name: 'purchasedThisPeriod', label: 'Purchased this Period', type: 'text' },
    { name: 'usedThisPeriod', label: 'Used This Period', type: 'text' },
    { name: 'usedAllTime', label: 'Used All Time', type: 'text' },
    { name: 'periodStart', label: 'Period Start', type: 'text' },
    { name: 'periodEnd', label: 'Period End', type: 'text' },
    { name: 'billingFrequency', label: 'Billing Frequency', type: 'text' },
    { name: 'pricePoint', label: 'Price Point', type: 'text' }
  ]

  const { data, isLoading } = Usage.loadSummary(component)

  const actions: Actions<Usage> = [
    // { name: 'Add to Integration', action: (row: Contact) => setIntegrationModal(row), icon: AddIntegration },
    // { type: 'delete', name: 'Delete Contact' }
  ]
  if (isLoading) {
    return <div style={{ padding: '30px' }}><Loader /></div>
  }
  const customerCount = data?.data?.attributes?.customer_count || 0
  const purchaseTotal = data?.data?.attributes?.purchase_total || 0
  const usageTotal = data?.data?.attributes?.usage_total || 0

  return (
    <div>
      <div className={classes.headerContainer}>
        <div className={classes.headerItem}>
          <div className={classes.headerTitle}>
            Customers with this enabled
          </div>
          <div className={classes.headerMetric}>
            {customerCount}
          </div>
        </div>
        <div className={classes.headerItem}>
          <div className={classes.headerTitle}>
            Lookups Purchased (all tenants)
          </div>
          <div className={classes.headerMetric}>
            {purchaseTotal}
          </div>
        </div>
        <div className={classes.headerItem}>
          <div className={classes.headerTitle}>
            Lookups Used (all tenants)
          </div>
          <div className={classes.headerMetric}>
            {usageTotal}
          </div>
        </div>
      </div>
      <Table<Usage>
        ObjectClass={Usage}
        fields={fields}
        actions={actions}
        gridTemplateColumns='1.5fr 1.5fr 1fr 1fr 1fr 1fr 1fr 1fr 50px'
        component={component}
        noEmail
        noExport
      />
    </div>
  )
}
