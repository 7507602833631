import { useDoQuery } from './useDoQuery'
import { doPost, doPatch, doDelete } from 'api/api'
import { DateType, castToDate } from './classHelpers'
import sanitizeHTML from 'sanitize-html'
import { pick } from 'lodash'
import { GenericObject } from './genericObject'

interface SavedReplyList {
  list: SavedReply[]
  dict: { [id: SavedReply['id']]: SavedReply }
  categories: string[]
}

const initialReplyList = {
  list: [],
  dict: {},
  categories: []
}

const dbProps = ['content', 'name', 'categories', 'visibility']

const defaultReply = {
  content: '',
  visibility: 'private',
  categories: [],
  title: ''
}

function transformReplyList (data: SavedReplyList): SavedReplyList {
  const replyList = data?.list || []
  const categories = [...new Set((replyList.map(r => r.categories))
    .reduce((a, value) => a.concat(value), [] as string[])
  )]
  return { ...data, categories }
}
export class SavedReply extends GenericObject {
  readonly id: number
  readonly createdTimestamp: DateType
  readonly updatedTimestamp: DateType
  readonly createdByUserID: number
  content: string
  priority: number
  name: string
  visibility: string
  categories: string[]

  get subtitle (): string { return sanitizeHTML(this.content, { allowedTags: [], allowedAttributes: {} }) }
  get searchField (): string { return this.name + this.content }
  get categoryText (): string { return this.categories && this.categories.length ? this.categories.join(', ') : '--None--' }
  get visibilityText (): string { return this.visibility === 'private' ? 'Private' : 'Organization' }

  static loadAll (): { data: SavedReplyList, isLoading: boolean } {
    return useDoQuery({
      path: '/saved_replies',
      objectClass: SavedReply,
      handleCustomResponse: transformReplyList,
      initialData: initialReplyList
    })
  }

  static updatePriority ({ ID, priority }: { ID: number, priority: number }): any {
    const data = {
      type: 'saved_replies',
      id: ID,
      attributes: {
        priority: priority
      }
    }
    const path = `/saved_replies/${ID}`
    return doPatch({ path, data })
  }

  static save (reply: SavedReply): any {
    const attributes = pick(reply, dbProps)

    const data = {
      type: 'saved_replies',
      id: reply.id,
      attributes: attributes
    }

    if (reply.id) {
      const path = `/saved_replies/${reply.id}`
      return doPatch({ path, data })
    } else {
      const path = '/saved_replies'
      return doPost({ path, data })
    }
  }

  delete (): Promise<any> {
    const path = `/saved_replies/${this.id}`
    return doDelete({ path: path })
  }

  static getCategories (replies: SavedReply[]) {
    let replyCategories : string[] = []
    replies.forEach(r => {
      if (r.categories != null) {
        r.categories.forEach(cat => {
          if (!replyCategories.includes(cat)) {
            replyCategories.push(cat)
          }
        })
      }
    })
    replyCategories = Array.from(new Set(replyCategories))
    return replyCategories
  }

  constructor ({ row, content }: { row?: Record<string, any>, content?: string } = {}) {
    super({ row })
    const reply = row?.attributes || defaultReply
    this.id = row?.id
    this.name = reply.title
    this.createdTimestamp = castToDate(reply.created_timestamp)
    this.updatedTimestamp = castToDate(reply.updated_timestamp)
    this.createdByUserID = reply.created_by_user_id
    this.content = content || reply.content
    this.priority = reply.priority
    this.visibility = reply.visibility
    this.categories = reply.categories
    this.delete = this.delete.bind(this)
  }
}

export default SavedReply
