import { Modal, ModalSection } from 'library/Modal';
import { defaultProspectModalSettings, reducer } from 'library/modals/ProspectModal';
import { ProspectModalContent, SettingsType } from 'pages/plays/playBuilder/nodes/contactSearch/ContactSearchNode';
import { useEffect, useReducer, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles'
import Picker from 'library/Picker';
import { updateLookupSettings } from 'api/lookup_settings';

const useStyles = makeStyles(theme => ({
  tabStyle: {
    width: '160px',
    height: '31px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottomColor: '#9933FF',
    cursor: 'pointer'
  }
}))

interface LookupSettings {
  enabled: boolean
  segments: string[]
  settings: SettingsType
}

interface AutoLookupSettingsModalProps {
  open: boolean
  setOpen: (value: boolean) => void
  allSettings: LookupSettings
  setAllSettings: (value: any) => void
}

export default function AutoLookupSettingsModal ({ open, setOpen, allSettings, setAllSettings }: AutoLookupSettingsModalProps) {
  const classes = useStyles()
  const [tab, setTab] = useState('segments')
  const [settings, setSettings] = useReducer(reducer, defaultProspectModalSettings)
  console.log('AutoLookupSettingsModal', { incoming: allSettings?.settings?.personaIDs, local: settings.personaIDs })

  const handleSave = () => {
    setTab('segments')
    setOpen(false)
    setAllSettings({ type: 'setSettings', value: settings })
    updateLookupSettings({ settings: { ...allSettings, settings: { ...settings } } })
  }
  useEffect(() => {
    if (allSettings?.settings) {
      setSettings(allSettings.settings)
    }
  }, [allSettings?.settings, setSettings])
  const saveBtnText = tab === 'segments' ? 'Next' : 'Save'

  return (
    <Modal
      open={open}
      onHide={() => {
        setTab('segments')
        setOpen(false)
      }}
      title='Contact Lookup Criteria'
      handleSave={() => {
        if (tab === 'segments') {
          setTab('contacts')
          return
        }
        handleSave()
      }}
      noDividers
      saveBtnText={saveBtnText}
      cancelBtnText='Cancel'
      closeBtnFunction={() => {
        setTab('segments')
        setOpen(false)
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div onClick={() => setTab('segments')} className={classes.tabStyle} style={{ color: tab === 'segments' ? '#9933FF' : '#808080', borderBottomWidth: tab === 'segments' ? '2px' : '0px' }}>
          Segments
        </div>
        <div onClick={() => setTab('contacts')} className={classes.tabStyle} style={{ color: tab === 'contacts' ? '#9933FF' : '#808080', borderBottomWidth: tab === 'contacts' ? '2px' : '0px' }}>
          Contacts
        </div>
      </div>
      {tab === 'segments' ? (
        <div>
          <ModalSection>
            <div style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '5px' }}>
              Segments
            </div>
            <div style={{ fontSize: '14px', marginBottom: '10px' }}>
              Select the segments you'd like to gather contacts for. We'll collect contacts whenever a company matches any of the selected segments.
            </div>
            <div>
              <Picker
                selection={allSettings?.segments || []}
                setSelection={(value) => setAllSettings({ type: 'setSegments', value: value })}
                multiple
                label='Segments'
                objectType='segments'
              />
            </div>
          </ModalSection>
        </div>
      )
        : (
          <ProspectModalContent
            settings={settings}
            updateSettings={setSettings}
            handlePersonaSelection={(value) => setSettings({ personaIDs: value as number[] })}
            emailComponentOn={false}
          />
        )}
    </Modal>
  )
}
