import { useContext, useState, useEffect } from 'react'
import PlayMakerGray from 'img/playrunner-gray.png'
import ContactDiscoveryGif from 'img/ContactDiscoveryOutreach.gif'
import { makeStyles } from '@material-ui/core/styles'
import { JsPlumbToolkitSurfaceComponent } from 'jsplumbtoolkit-react'
import { ViewOptions } from 'jsplumbtoolkit'
import { FlowBuilderContext } from './FlowBuilderProvider'
import BetterBaseNode from '../nodes/baseNode/BaseNode'
import { NodeDrawer } from '../nodes/NodeDrawer'
import FTUX from 'cf-components/FTUX'
import { NodePopover } from './menus/NodePopover'
import { Emitter, EVENT_TYPE } from './helpers/EventEmitter'
import { DrawerPage } from 'library/page/DrawerPage'
import { SelectionMenu } from './menus/SelectionMenu'
import { SurfaceControlsMenu } from './menus/SurfaceControlMenu'
import { OptionsMenu } from './menus/OptionsMenu'
import { SequenceModal } from './sequences/SequenceModal'
import SequenceExitNode from './sequences/SequenceExitNode'
import { FlowOption } from '../FlowBuilder'
import { DisplaySettings } from 'classes/displaySettings'
import { Icon } from 'library/materialUI'
import AccessControl from 'components/AccessControl'
import { components } from 'session-context'

const useStyles = makeStyles(theme => ({
  surfaceDiv: {
    height: '100%',
    flexGrow: 1,
    position: 'relative',
    '& .jtk-surface': {
      overflowY: 'auto',
      height: '100%',
      width: '100%',
      backgroundColor: '#E2E2E2'
    }
  },
  altSurfaceDiv: {
    '& .jtk-surface': {
      backgroundColor: '#B7B7DB'
    }
  }
}))

const renderParams = {
  layout: {
    type: 'Spring'
  },
  enableWheelZoom: true,
  wheelReverse: true,
  zoomToFit: true,
  consumeRightClick: false,
  style: { width: '600px', height: '400px' },
  dragOptions: {
    filter: '.nodrag, .nodrag *',
    grid: [10, 10]
  }
}

type FlowSurfaceProps = {
  flowOptions: FlowOption[]
  preview?: boolean
  altColor?: boolean
  objectType?: string
  history?: any
  outsideEdit?: boolean
  withinPlayrunner?: boolean
}

export function FlowSurface ({ flowOptions, preview, altColor, objectType, history, outsideEdit, withinPlayrunner }: FlowSurfaceProps): JSX.Element {
  const classes = useStyles()
  const [, setRender] = useState(0)
  const [open, setOpen] = useState(true)
  const { toolkit, setSurface, dataLoaded } = useContext(FlowBuilderContext)
  const [canSave, setCanSave] = useState(false)
  const { settings, isLoading } = DisplaySettings.load()
  const [dismissed, setDismissed] = useState(false)

  const handleClose = () => {
    setDismissed(true)
    DisplaySettings.update({ page: 'ftux', type: 'dismiss-unconnected-port', settings: { complete: true } })
    DisplaySettings.update({ page: 'ftux', type: 'unconnected-port', settings: { complete: true } })
  }

  useEffect(() => {
    DisplaySettings.update({ page: 'ftux', type: 'unconnected-port', settings: { complete: true } })
  }, [])

  useEffect(() => {
    if (!isLoading) {
      setDismissed(settings?.ftux?.settings['dismiss-unconnected-port']?.complete)
    }
    // eslint-disable-next-line
  }, [isLoading])

  useEffect(() => {
    Emitter.on(EVENT_TYPE.NODE_SELECTION, () => {
      setRender((old) => old + 1)
    })
    if (dataLoaded) {
      toolkit.bind('nodeUpdated', function (params: any) {
        if (!params.hasOwnProperty('updates')) {
          setCanSave(true)
        }
      })
      toolkit.bind('nodeRemoved', function (_: any) {
        setCanSave(true)
      })
      toolkit.bind('edgeAdded', function (params: any) {
        if (params.addedByMouse) {
          setCanSave(true)
        }
      })
      toolkit.bind('portRemoved', function (_: any) {
        setCanSave(true)
      })
      toolkit.bind('portAdded', function (_: any) {
        setCanSave(true)
      })
      Emitter.on(EVENT_TYPE.NODE_DATA_UPDATED, (payload: any) => {
        if (toolkit.id === payload.toolkitRefID) {
          setCanSave(true)
        }
      })
      Emitter.on(EVENT_TYPE.EDGE_DIRECT_REMOVE, (payload: any) => {
        if (toolkit.id === payload.toolkitRefID) {
          setCanSave(true)
        }
      })
      Emitter.on(EVENT_TYPE.STATE_SAVED, (payload: any) => {
        if (toolkit.id === payload.toolkitRefID) {
          setCanSave(false)
        }
      })
      if (outsideEdit) {
        setCanSave(true)
      }
    }
    // eslint-disable-next-line
  }, [dataLoaded, outsideEdit])

  const view: ViewOptions = {
    nodes: {
      basic: {
        component: BetterBaseNode
      },
      sequence: {
        component: BetterBaseNode
      },
      sequenceExit: {
        component: SequenceExitNode
      }
    },
    edges: {
      default: {
        endpoint: 'Blank',
        anchor: 'Continuous',
        paintStyle: { outlineWidth: 15, outlineStroke: '#658AE5' },
        connector: ['Flowchart', { cornerRadius: 20, cssClass: 'connector' }],
        overlays: [
          ['PlainArrow', { location: 1 }],
          ['Label', {
            location: 0.5,
            cssClass: 'delete-relationship',
            label: '<svg height="25" width="25" fill="white"><path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zm2.46-7.12l1.41-1.41L12 12.59l2.12-2.12 1.41 1.41L13.41 14l2.12 2.12-1.41 1.41L12 15.41l-2.12 2.12-1.41-1.41L10.59 14l-2.13-2.12zM15.5 4l-1-1h-5l-1 1H5v2h14V4z"></path></svg>',
            events: {
              tap: (params: any) => {
                toolkit.removeEdge(params.edge)
                Emitter.emit(EVENT_TYPE.EDGE_DIRECT_REMOVE, { toolkitRefID: toolkit.id, edge: params.edge })
              }
            }
          }],
          ['Label', {
            location: 0.5,
            cssClass: 'add-relationship',
            label: '<svg height="25" width="25" fill="white"><path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"></path></svg>',
            events: {
              tap: (params: any) => {
                Emitter.emit(EVENT_TYPE.ADD_FROM_EDGE, { params })
                Emitter.emit(EVENT_TYPE.EDGE_DIRECT_REMOVE, { toolkitRefID: toolkit.id, edge: params.edge })
              }
            }
          }]
        ]
      }
    }
  }

  if (preview) {
    if (view.edges) {
      view.edges.default.overlays = [['PlainArrow', { location: 1 }]]
    }

    return (
      <AccessControl requiredComponent={components.PLAYMAKER}>
        <div className={`${classes.surfaceDiv} ${altColor && classes.altSurfaceDiv}`}>
          <JsPlumbToolkitSurfaceComponent
            renderParams={renderParams}
            toolkit={toolkit}
            view={view}
            ref={(c: any) => { if (c) { setSurface(c.surface) } }}
          />
        </div>
      </AccessControl>
    )
  }
  // TODO: make "30 days" dynamic
  return (
    <AccessControl requiredComponent={components.PLAYMAKER}>
      <DrawerPage
        drawerWidth={260}
        drawerContents={<NodeDrawer />}
        open={open}
        pageClass={`${classes.surfaceDiv} ${altColor && classes.altSurfaceDiv}`}
        handleToggle={() => setOpen(!open)}
      >
        <SelectionMenu />
        <NodePopover objectType={objectType} />
        <SurfaceControlsMenu withinPlayrunner={withinPlayrunner} />
        <OptionsMenu options={flowOptions} canSave={canSave} history={history} />
        <SequenceModal />
        <JsPlumbToolkitSurfaceComponent
          renderParams={renderParams}
          toolkit={toolkit}
          view={view}
          ref={(c: any) => { if (c) { setSurface(c.surface) } }}
        />
        {!dismissed && !isLoading && objectType === 'sequences' && (
          <FTUX
            page='unconnected-port'
            anchors={
              [{
                title: <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}><div style={{ marginRight: '10px' }}><Icon icon='lightBulb' size='lg' color='black' /></div><div>Signals Tip</div>              </div>,
                body: (
                  <div>
                    To automate outreach to discovered contacts or send them to an integration, connect them to a Send Email skill or an integration skill.
                    <img src={ContactDiscoveryGif} alt='contact-discovery-gif' style={{ width: '100%', marginTop: '15px' }} />
                  </div>
                ),
                anchorID: 'play-save-button',
                anchorPos: 'top',
                anchorOriginHorizontal: -330,
                anchorOriginVertical: 300,
                transformOriginHorizontal: 'left',
                transformOriginVertical: 'center',
                arrowOffset: '-200px'
              }]
            }
            updateFunction={() => handleClose()}
          />
        )}
        {objectType === 'plays' && (
          <FTUX
            page='playrunner-builder'
            anchors={
              [{
                header: (
                  <div style={{ backgroundColor: '#d2d2dd', borderTopRightRadius: '10px', borderTopLeftRadius: '10px', textAlign: 'right', paddingTop: '23px' }}>
                    <img src={PlayMakerGray} alt='playMaker' style={{ width: '100%' }} />
                  </div>
                ),
                title: 'All your audience criteria in one place',
                body: (
                  <>
                    Your bot audience criteria now lives here. String filter skills together to narrow your
                    audience and create branching experiences based on different audiences and behaviors.
                  </>
                ),
                anchorID: 'flow-builder-drawer',
                anchorPos: 'left',
                anchorOriginHorizontal: 280,
                anchorOriginVertical: 'center',
                transformOriginHorizontal: 'left',
                transformOriginVertical: 'center',
                arrowOffset: '183px'
              },
              {
                title: 'Publish and enable PlayMaker when you\'re ready to go',
                body: (
                  <>
                    Your plays, including chatbots and any other automations you set up, will then be set in motion
                  </>
                ),
                anchorID: 'publish-button',
                anchorPos: 'top-right',
                anchorOriginHorizontal: -10,
                anchorOriginVertical: 70,
                transformOriginHorizontal: 'right',
                transformOriginVertical: 'top'
              }
              ]
            }
          />
        )}
      </DrawerPage>
    </AccessControl>
  )
}
