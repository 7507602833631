import { TextBox, Button, Icon } from 'library/materialUI'
import { Select, LabeledCheckbox } from 'cf-components/material-wrappers/MaterialComponents'
import Picker from 'cf-components/Pickers'
import Persona from 'classes/personas'
import { ModalContentsProps } from 'library/flowBuilder/nodes/NodeModal'
import { ModalSection } from 'library/Modal'
import * as yup from 'yup'
import { Fragment, useCallback, useEffect, useState } from 'react'
import { EditLinkButton } from '../EditLinkButton'
import { getIntegrationFeature } from 'api/integrations'
import IntegrationSelector from './IntegrationSelector'
import Email from 'classes/emails'
import { capitalize } from 'library/helpers'

export const ContactSearchSchema = yup.object().shape({
  ports: yup.array().min(1),
  settings: yup.object({
    integration: yup.string().min(1),
    listID: yup.string().min(1),
    personaIDs: yup.array().min(1),
    contactLimit: yup.number().max(15)
  })
})

interface IntegrationItem {
  name: string
  listIDs: string[]
}

export interface SettingsType {
  contactLimit: number
  personaIDs: number[]
  emailEnabled: boolean
  delaySend: boolean
  emailID: number
  delayHours: number
  integrationEnabled: boolean
  integrationList: IntegrationItem[]
}

interface ProspectModalContentsProps {
  settings: { contactLimit: number, personaIDs: number[], emailEnabled: boolean, integrationEnabled: boolean, delaySend: boolean, emailID: number, delayHours: number, integrationList: IntegrationItem[] }
  updateSettings: (value: Partial<SettingsType>) => void
  handlePersonaSelection: (value: number[] | string[]) => void
  emailComponentOn?: boolean
}

export const ProspectModalContent = ({ settings, updateSettings, handlePersonaSelection, emailComponentOn = true }: ProspectModalContentsProps) => {
  const delayOptions = [
    { value: 1, label: '1 hour' },
    { value: 2, label: '2 hours' },
    { value: 4, label: '4 hours' },
    { value: 24, label: '1 day' },
    { value: 48, label: '2 days' },
    { value: 72, label: '3 days' },
    { value: 168, label: '1 week' }
  ]

  const [availableIntegrations, setAvailableIntegrations] = useState([])

  const getAvailable = useCallback(() => {
    getIntegrationFeature('crm.create_contact').then((res: any) => {
      const available = res.data.attributes.available_integrations.filter((integration: any) => integration.enabled)
      const enabled = available.map((integration: any) => { return { value: integration.name, label: integration.label } })
      setAvailableIntegrations(enabled)
    })
    getIntegrationFeature('cadence.membership').then((res: any) => {
      const available = res.data.attributes.available_integrations.filter((integration: any) => integration.enabled)
      const enabled = available.map((integration: any) => { return { value: integration.name, label: integration.name.charAt(0).toUpperCase() + integration.name.slice(1) } })
      setAvailableIntegrations((prev) => [...prev, ...enabled])
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings.integrationList])

  const addIntegration = () => {
    updateSettings({ integrationList: [...settings.integrationList, { name: '', listIDs: [] }] })
  }

  useEffect(() => {
    getAvailable()
    if (!settings?.integrationList?.length) {
      updateSettings({ integrationList: [{ name: '', listIDs: [] }] })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <ModalSection
        title='Buying Committee'
        subtitle='Which personas should be used for contact search criteria?'
      >
        <div>
          <Picker
            objectType='personas'
            selection={settings.personaIDs}
            setSelection={handlePersonaSelection}
            placeholder='Select buying commitee personas'
            multiple
          />
          <div style={{ marginTop: '10px' }}>
            <EditLinkButton
              text='Edit Buying Commitee Personas'
              link='/#/contact_management/buying_committee'
            />
          </div>
        </div>
      </ModalSection>
      <ModalSection
        title='Contact Limit'
        subtitle='Set a cap on the number of contacts to be collected.'
      >
        <div style={{ width: 300 }}>
          <TextBox
            type='number'
            value={settings.contactLimit}
            onChange={(value: number) => updateSettings({ contactLimit: value })}
            error={settings.contactLimit < 1 || settings.contactLimit > 15}
            helperText={settings.contactLimit < 1 ? 'The value should be greater than zero' : settings.contactLimit > 15 ? 'No more than 15 contacts can be returned' : ''}
          />
        </div>
      </ModalSection>
      {emailComponentOn &&
        <ModalSection
          title=''
          subtitle=''
        >
          <div>
            <LabeledCheckbox
              label='Send contacts an email'
              checked={settings.emailEnabled}
              onChange={() => updateSettings({ emailEnabled: !settings.emailEnabled })}
            />
          </div>
          {settings.emailEnabled && (
            <div style={{ marginLeft: '28px', marginTop: '5px' }}>
              <div style={{ color: '#808080', marginBottom: '5px' }}>
                Which email would you like to send to?
              </div>
              <Picker
                selection={settings.emailID}
                setSelection={(value: any) => updateSettings({ emailID: value })}
                objectType='emails'
              />
              {settings.emailID ? (
                <Button
                  style={{ fontSize: 12, color: 'black', marginTop: 10, borderRadius: 50 }}
                  variant='outlined'
                  size='small'
                  onClick={() => window.open('/#/emails/edit/' + settings.emailID)}
                >
                  <div style={{ marginRight: '5px' }}>
                    <Icon icon='edit' />
                  </div>
                  <div style={{ marginTop: '2px' }}>
                    Edit Email
                  </div>
                </Button>
              ) : (
                <Button
                  style={{ fontSize: 12, color: 'black', marginTop: 10, borderRadius: 50 }}
                  variant='outlined'
                  size='small'
                  onClick={() => window.open('/#/emails/new')}
                >
                  <div style={{ marginRight: '5px' }}>
                    <Icon icon='add' />
                  </div>
                  <div style={{ marginTop: '2px' }}>
                    Create a new email
                  </div>
                </Button>)}
              <div style={{ paddingTop: 10 }}>
                <LabeledCheckbox
                  label='Add a delay before the email is sent'
                  checked={settings.delaySend}
                  onChange={(value: boolean) => updateSettings({ delaySend: value })}
                />
                {settings.delaySend &&
                  <div style={{ width: '300px' }}>
                    <Select
                      value={settings.delayHours}
                      onChange={(value: number) => updateSettings({ delayHours: value })}
                      options={delayOptions}
                      placeholder='Select delay period'
                    />
                  </div>}
              </div>
            </div>
          )}
        </ModalSection>}
      <ModalSection
        title=''
        subtitle=''
      >
        <div>
          <LabeledCheckbox
            label='Add contacts to an integration'
            checked={settings.integrationEnabled}
            onChange={() => updateSettings({ integrationEnabled: !settings.integrationEnabled })}
          />
        </div>
        {settings.integrationEnabled && (
          <div>
            <ModalSection
              title=''
              subtitle=''
            >
              {settings?.integrationList?.map((integrationSettings, index) => {
                return (
                  <div key={index} style={{ marginTop: index > 0 ? '40px' : '0px' }}>
                    <div style={{ display: 'flex' }}>
                      <div style={{ marginBottom: '5px' }}>
                        Integration {index + 1}
                      </div>
                      {index === 1 && (
                        <div style={{ cursor: 'pointer', marginRight: '5px', marginLeft: 'auto' }} onClick={() => updateSettings({ integrationList: settings.integrationList.filter((item, i) => i !== index) })}>
                          <Icon icon='close' />
                        </div>
                      )}
                    </div>
                    <IntegrationSelector
                      settings={integrationSettings}
                      updateIntegration={(value: any) => updateSettings({ integrationList: settings.integrationList.map((item, i) => i === index ? value : item) })}
                      availableIntegrations={availableIntegrations}
                    />
                  </div>
                )
              })}
            </ModalSection>
            {settings?.integrationList?.length < 2 && (
              <div style={{ marginTop: '10px' }}>
                <Button
                  style={{ fontSize: 12, color: 'black', borderRadius: 50 }}
                  variant='outlined'
                  size='small'
                  onClick={addIntegration}
                >
                  <div style={{ marginRight: '5px' }}>
                    <Icon icon='add' />
                  </div>
                  <div style={{ marginTop: '2px' }}>
                    Add another integration
                  </div>
                </Button>
              </div>
            )}
          </div>
        )}
      </ModalSection>
    </div>
  )
}

export function ContactSearchModal ({ settings, updateSettings, updatePorts }: ModalContentsProps): JSX.Element {
  const handlePersonaSelection = (values: any): void => {
    const ports = [{ id: 'default', label: 'Next' }]
    updateSettings({ personaIDs: values })
    updatePorts(ports)
  }

  return (
    <ProspectModalContent
      handlePersonaSelection={handlePersonaSelection}
      settings={settings as SettingsType}
      updateSettings={updateSettings}
    />
  )
}

export function ContactSearchBody ({ settings, parent }: any): JSX.Element {
  const { isLoading, data: personas } = Persona.loadAll()
  const { data: emails } = Email.loadAll()
  const emailName = emails.list.find((email: any) => email.id === settings.emailID)?.name
  // const { data: integrations } = getIntegrationFeature('crm.create_contact')
  const [crmName, setCrmName] = useState('')

  const updateData = useCallback(() => {
    if (!isLoading && parent.toolkit) {
      parent.toolkit.updateNode(parent.getNode(), parent.state)
    }
  }, [isLoading, parent])

  useEffect(() => {
    updateData()
  }, [updateData])

  useEffect(() => {
    if (settings.integrationEnabled && settings.integrationList.length > 0) {
      console.log(settings.integrationList[0].name)
      setCrmName(settings.integrationList[0].name)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings.integrationList])

  if (isLoading) {
    return (<>Loading</>)
  }

  const personaNames = settings.personaIDs
    .filter((id: any) => personas.dict[id])
    .map((id: any) => personas.dict[id].name)

  return (
    <div style={{ marginBottom: 10 }}>
      Search for:
      <div style={{ display: 'grid', justifyContent: 'center', marginTop: 10, marginBottom: 10 }}>
        {personaNames.length > 1 ? (
          <span>
            {personaNames.slice(0, -1).map((name: string, index: number) => (
              <Fragment key={index}><strong>{name}</strong>{index < personaNames.length - 2 ? ', ' : ' '}</Fragment>
            ))}
            and <strong>{personaNames[personaNames.length - 1]}</strong>
          </span>
        ) : (
          <strong>{personaNames[0]}</strong>
        )}
      </div>
      {/* <div style={{ display: 'flex', color: '#A7A7A7', font: 'normal normal normal Poppins', textAlign: 'center', margin: 0, alignItems: 'center', justifyContent: 'center' }}>
        Personas
        <InformationIcon
          color='#A7A7A7'
          message='Each port is a buying committee persona. You can connect them to an integration skill to send the contacts to that integration'
        />
      </div> */}
      {settings?.emailEnabled && (
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
          <div style={{ marginRight: '5px' }}>
            <Icon icon='email' color='#547BD0' size='lg' />
          </div>
          <div style={{ color: '#808080', fontSize: '12px' }}>
            Send <span style={{ color: '#343434' }}>{emailName}</span> email
          </div>
        </div>
      )}
      {settings?.integrationEnabled && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ marginRight: '7px', marginLeft: '2px' }}>
            <Icon icon={crmName || 'salesforce'} size='xs' />
          </div>
          <div style={{ color: '#808080', fontSize: '12px' }}>
            Send to <span style={{ color: '#343434' }}>{capitalize(crmName)}</span>
          </div>
        </div>
      )}
    </div>
  )
}
