import { doGet, doPost } from './api';

export function createOnboardingData (kind: string, settings: any) {
  if (kind === 'client_onboarding') {
    return {
      learn_live_chat: settings.learn_live_chat || false,
      complete_profile: settings.complete_profile || false,
      connect_calendar: settings.connect_calendar || false,
      learn_availability: settings.learn_availability || false,
      configure_notifications: settings.configure_notifications || false
    }
  }
}

export function getOnboardingData (kind: string) {
  return doGet({
    path: `/onboarding/${kind}`,
    useChatURL: true
  })
}

export function setOnboardingData (kind: string, settings: any) {
  const completeSettings = createOnboardingData(kind, settings)
  return doPost({
    path: `/onboarding/${kind}/update`,
    useChatURL: true,
    data: {
      attributes: completeSettings
    }
  })
}
