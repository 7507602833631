import EventEmitter from 'eventemitter3'

enum EVENT_TYPE {
  DROP_EDGE = 'DROP_EDGE',
  EDGE_DIRECT_REMOVE = 'EDGE_DIRECT_REMOVE',
  NODE_SELECTION = 'NODE_SELECTION',
  SURFACE_CONTROL = 'SURFACE_CONTROL',
  SELECTION_OPTION = 'SELECTION_OPTION',
  ADD_FROM_EDGE = 'ADD_FROM_EDGE',
  PROBLEM_NODES = 'PROBLEM_NODES',
  NODE_CONNECTION = 'NODE_CONNECTION',
  INVALID_NODES = 'INVALID_NODES',
  NODE_UPDATED = 'NODE_UPDATED',
  NODE_DATA_UPDATED = 'NODE_DATA_UPDATED',
  NODE_ADDED = 'NODE_ADDED',
  SEQUENCE_SELECTION = 'SEQUENCE_SELECTION',
  VERSION_HISTORY = 'VERSION_HISTORY',
  STATE_SAVED = 'STATE_SAVED',
  SET_FIRST_NODE = 'SET_FIRST_NODE'
}

const eventEmitter = new EventEmitter()

const Emitter = {
  on: (event: EVENT_TYPE, fn: any) => eventEmitter.on(event, fn),
  once: (event: EVENT_TYPE, fn: any) => eventEmitter.once(event, fn),
  off: (event: EVENT_TYPE, fn: any) => eventEmitter.off(event, fn),
  emit: (event: EVENT_TYPE, payload: object) => eventEmitter.emit(event, payload),
  remove: (event: EVENT_TYPE, fn: any) => eventEmitter.removeListener(event, fn)
}

Object.freeze(Emitter)

export { Emitter, EVENT_TYPE }
