import { Emitter, EVENT_TYPE } from './EventEmitter'
import { jsPlumbToolkit, jsPlumbUtil } from 'jsplumbtoolkit'

export interface Toolkit extends jsPlumbToolkit {
  id?: string
  preview?: boolean
}

export const initializeToolkit = (nodeFactory: any, preview: boolean): Toolkit => {
  const toolkit: Toolkit = jsPlumbToolkit.newInstance({
    portDataProperty: 'ports',
    nodeFactory: nodeFactory,
    beforeStartConnect: function (source, _) {
      const edges = source.getSourceEdges()
      if (edges.length) {
        return false
      }
    },
    beforeDetach: function (source: any, target: any, _edge: any) {
      const sourceNode = source.getNode()
      Emitter.emit(EVENT_TYPE.NODE_CONNECTION, {
        parent: sourceNode,
        target: target,
        type: 'detach'
      })
      return true
    },
    beforeConnect: function (source: any, target: any, _) {
      const edges = source.getEdges()
      if (edges.length) {
        return false
      }
      setTimeout(() => {
        const parent = source.objectType === 'Port' ? source.getNode() : source
        Emitter.emit(EVENT_TYPE.NODE_CONNECTION, {
          parent: parent,
          target: target,
          type: 'connect'
        })
      }, 30)
      return true
    }
  })

  toolkit.id = jsPlumbUtil.uuid()
  toolkit.preview = preview

  return toolkit
}
