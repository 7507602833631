import { track } from 'userpilot.js'
import { doPatch, doPost, doDelete, doGet } from './api'

const getMeetingTypes = (params) => {
  let path = '/meeting_types'
  if (params?.agent_pages_only) {
    path += '?filter[agent_pages_only]=1'
  }
  return doGet({ path, useChatURL: true })
}

const getMeetingType = ({ meeting_type_id }) => {
  return doGet({ path: `/meeting_types/${meeting_type_id}`, useChatURL: true })
}

const deleteMeetingType = ({ meeting_type_id }) => {
  return doDelete({ path: `/meeting_types/${meeting_type_id}`, useChatURL: true })
}

const updateMeetingType = ({ meetingType }) => {
  const path = '/meeting_types'
  if (meetingType.id) {
    return doPatch({
      path: path + `/${meetingType.id}`,
      data: meetingType,
      useChatURL: true
    })
  }
  return doPost({
    path,
    data: meetingType,
    useChatURL: true
  })
}

function getMeetingInfo ({ user_id }) {
  return doGet({ path: `/meeting_info/${user_id}`, useChatURL: true })
}

function getAllMeetingInfo () {
  return doGet({ path: '/meeting_info', useChatURL: true })
}

function updateMeetingInfo ({ user_id, meetingInfo }) {
  const path = `/meeting_info/${user_id}`
  track('Meeting Settings Setup')
  if (meetingInfo.id) {
    return doPatch({ path, useChatURL: true, data: meetingInfo })
  }
  return doPost({ path, useChatURL: true, data: meetingInfo })
}
export {
  getMeetingTypes,
  getMeetingInfo,
  updateMeetingInfo,
  updateMeetingType,
  getMeetingType,
  deleteMeetingType,
  getAllMeetingInfo
}
