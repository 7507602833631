import { useContext } from 'react'
import { HelpCenterContext } from '../HelpCenterContext'
import { makeStyles } from '@material-ui/core/styles'
import { THelpCenterCard, HelpCenterCard } from '../HelpCenterCard'
import { PlayNodesCenter } from './PlayNodesHome'
import { SectionProps } from '../HelpCenter'
import { SectionCenter } from '../SectionCenter'
import { OverviewsHome } from '../OverviewsHome'
import { PlayTemplatesCenter } from './PlayTemplatesHome'

const useStyles = makeStyles(theme => ({
  headerText: {
    font: 'normal normal 600 24px/35px Poppins',
    color: '#000000BF'
  }
}))

function PlaysHome () {
  const classes = useStyles()
  const { navigateBack } = useContext(HelpCenterContext)

  const cards: THelpCenterCard[] = [
    { title: 'PlayMaker Overview', icon: 'play', link: 'playsOverview' },
    { title: 'Template Types', icon: 'chat', link: 'playTemplates' },
    { title: 'Skill Types', icon: 'module', link: 'skills' }
  ]

  return (
    <div>
      <div className={classes.headerText} onClick={navigateBack}>
        Building Plays
      </div>
      <div>
        {cards.map((card: THelpCenterCard, index: number) => (
          <HelpCenterCard key={index} card={card} />
        ))}
      </div>
    </div>
  )
}

const sectionMapping = {
  skills: PlayNodesCenter,
  playsOverview: OverviewsHome,
  playTemplates: PlayTemplatesCenter
}

export function PlaysCenter (props: SectionProps) {
  return (
    <SectionCenter
      homeComponent={<PlaysHome />}
      sectionMapping={sectionMapping}
      links={props.links}
    />
  )
}
