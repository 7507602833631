import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'

/**
 * @param {{data: any[]; id: string; setVisibility: any; type: 'number'|'dollar'}} param0
 */
function createFunnelChart ({ data, id, setVisibility, type = 'number' }) {
  am4core.addLicense('CH213476655')

  if (!document.getElementById(id)) {
    return
  }

  const chart = am4core.create(id, am4charts.SlicedChart)
  chart.hiddenState.properties.opacity = 0

  const series = chart.series.push(new am4charts.FunnelSeries())
  series.colors.step = 2
  series.dataFields.value = 'count'
  series.dataFields.category = 'type'
  series.alignLabels = true
  series.labels.template.text = '{percent}'
  series.labels.template.fontSize = '13px'
  series.slices.template.tooltipHTML = '<span style="color: #ffffff;">{count} ({percent})</span>'

  series.bottomRatio = 1

  chart.legend = new am4charts.Legend()
  if (type === 'dollar') {
    chart.legend.numberFormatter.numberFormat = '$#,##0'
  }
  chart.legend.position = 'left'
  chart.legend.valign = 'bottom'
  chart.legend.margin(5, 5, 20, 5)
  chart.legend.fontSize = '13px'
  chart.legend.labels.template.text = '{type}'
  chart.legend.labels.template.tooltipText = 'Tooltip'
  chart.legend.scrollable = true
  series.legendSettings.valueText = '{count}'

  chart.data = data

  chart.events.on('ready', () => {
    setVisibility('visible')
  })

  return chart
}

export { createFunnelChart }
