import User from 'classes/users'
import { createContext, ReactNode } from 'react'

interface IUserContext {
  user: User
}
export const UserContext = createContext<IUserContext>({
  user: {} as any
})

type UserContextProviderProps = {
  children: ReactNode
  userID: number
}
export function UserContextProvider (props: UserContextProviderProps) {
  const { data: user, isLoading } = User.loadMe()
  return (
    <UserContext.Provider
      value={{ user }}
    >
      {isLoading ? <></> : props.children}
    </UserContext.Provider>
  )
}
