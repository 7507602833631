
const descriptionText = 'Use this bot template to create a bot flow which invites the user to sign up for a demo of your product. This bot:'

const Description = (): JSX.Element => {
  return (
    <div>
      <div>
        {descriptionText}
      </div>
      <ul>
        <li>Alerts agents to the conversation</li>
        <li>Asks qualifying questions to determine fit</li>
        <li>If the visitor is a good fit for your company, the bot sends the visitor a calendar invite</li>
        <li>Prompts the visitor to leave notes for review prior to the meeting</li>
      </ul>
    </div>
  )
}

const bestPracticeText = 'Verify that your agents have configured their calendars in Signals, so all agents can book meetings. Also, be sure to customize your qualifying questions and responses within the bot flow.'

const BestPractices = () => {
  return (
    <div>
      <div>
        {bestPracticeText}
      </div>
      <ul>
        <li><a href='#/settings/my_settings/my_calendar' target='_blank'>Calendar Settings</a></li>
      </ul>
    </div>
  )
}

export const demoBotDocs = {
  helpCenterLink: '',
  bestPractices: BestPractices,
  description: Description,
  videoLink: ''
}
