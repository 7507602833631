import Card from 'library/materialUI/Card'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from 'library/materialUI'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import StarIcon from '@material-ui/icons/Star'
import { useContext, useEffect } from 'react'
import { TabContext } from '../IntegrationPage/TabProvider'
import { IntegrationPageContext } from '../IntegrationPage/IntegrationPageProvider'
import { Checkbox, FormControlLabel } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  subtitle1: {
    fontSize: 18,
    fontWeight: 600,
    marginBottom: 15
  },
  subtitle2: {
    fontSize: 15,
    fontWeight: 600,
    marginBottom: 10
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  bodyText: {
    marginBottom: 30,
    fontSize: 16
  },
  select: {
    width: 250,
    height: 40
  },
  star: {
    fill: 'gold'
  },
  icon: {
    marginTop: 4,
    marginLeft: 4
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    margin: 0,
    padding: 0
  },
  lineItem: {
    display: 'flex',
    flexDireciton: 'row',
    marginTop: '10px'
  }
}))

const NStarIcon = (props: { stars: number }) => {
  const classes = useStyles()

  const stars = []
  const n = props.stars
  for (let i = 0; i < n; i++) {
    stars.push(<StarIcon key={i} className={classes.star} />)
  }

  return <>{stars}</>
}

const scores = [
  { key: 0, name: 'All (Default)', icon: <></> },
  { key: 1, name: 'One Star', icon: <NStarIcon stars={1} /> },
  { key: 2, name: 'Two Stars', icon: <NStarIcon stars={2} /> },
  { key: 3, name: 'Three Stars', icon: <NStarIcon stars={3} /> }
]

interface SyncCriteriaCardProps {
  save: any
  handleEdit: any
  integrationSettings: any
}

export default function SyncCriteriaCard ({ save, handleEdit, integrationSettings }: SyncCriteriaCardProps) {
  const classes = useStyles()
  const { setCanSave, shouldSave } = useContext(TabContext)
  const { handleSaved } = useContext(IntegrationPageContext)

  useEffect(() => {
    if (shouldSave) {
      save()
      handleSaved()
    }
  })

  return (
    <Card
      content={
        <>
          <div className={classes.header}>
            <Typography className={classes.subtitle1}>
              Sync Criteria
            </Typography>
          </div>
          <Typography variant='body2' className={classes.bodyText}>
            Only sync Signals contacts to HubSpot when the following criteria are met
          </Typography>
          <Typography variant='subtitle2' className={classes.subtitle2}>
            Minimum Criteria
          </Typography>
          <div style={{ marginBottom: 20 }}>
            <Select
              name='min_lead_score'
              variant='outlined'
              margin='dense'
              defaultValue='preview'
              className={classes.select}
              value={integrationSettings.attributes.config.min_lead_score}
              onChange={(leadScore) => {
                handleEdit({
                  type: 'min_lead_score',
                  data: leadScore.target.value
                })
                setCanSave(true)
              }}
            >
              <MenuItem value='preview' disabled>
                -- Min lead score --
              </MenuItem>
              {scores.map((score: any, index: number) => (
                <MenuItem key={index} value={score.key}>
                  <div className={classes.menuItem}>
                    <div>{score.name}</div>
                    <div className={classes.icon}>{score.icon}</div>
                  </div>
                </MenuItem>
              ))}
            </Select>
          </div>
          <FormControlLabel
            control={
              <Checkbox
                color='primary'
                checked={
                    integrationSettings.attributes.config.cookie_form
                }
                onChange={(e) => {
                  handleEdit({
                    type: 'cookie_form',
                    data: e.target.checked
                  })
                  setCanSave(true)
                }}
              />
            }
            label='Link hubspot cookie to email address immediately (using hubspot form)'
          />
        </>
      }
    />
  )
}
