import { jsPlumbUtil } from 'jsplumbtoolkit'

export const assignKey = (data: any): string => {
  data._reactKey = data._reactKey || jsPlumbUtil.uuid()
  return data._reactKey
}

export const getShadow = (state: any) => {
  if (state.unconnected) {
    return '0px 0px 4px 6px #FF5D5C'
  } else if (state.invalid) {
    return '0px 0px 4px 6px #FD9038'
  }
  return null
}

export const handleSelection = (base: any) => {
  const node = base.getNode()
  const toolkit = base.toolkit
  const selectedIDs = toolkit.getSelection().getNodes().map((n: any) => n.id)
  if (selectedIDs.includes(node.id)) {
    toolkit.removeFromSelection(node)
  } else {
    toolkit.addToSelection(node)
  }
}

const defaultPortIDs = ['default', 'valid', 'invalid']
export const cloneNode = (node: any) => {
  const toolkitNode = node.toolkit.getNode(node.state.id)
  const data = JSON.parse(JSON.stringify(toolkitNode.data))
  if (toolkitNode.data.kind !== 'AvailabilityBranching') {
    data.ports.forEach((port: any) => {
      const matches = port.id.match('.+-.+-.+-.+-.+')
      if (defaultPortIDs.includes(port.id) || matches === null) {
        // if it's not a UUID, keep it the same
      } else {
        // Otherwise give all ports a new id
        const uniqueID = jsPlumbUtil.uuid()
        if (data.portOrder.includes(port.id)) {
          data.portOrder[data.portOrder.indexOf(port.id)] = uniqueID
        }
        port.id = uniqueID
      }
    })
  }
  data.left = data.left + 200
  data.top = data.top + 70
  data.id = jsPlumbUtil.uuid()
  data.isCopy = true
  node.toolkit.addNode(data)
}
