import { makeStyles } from '@material-ui/core/styles'
import MapIcon from '@material-ui/icons/LocationOn'
import ListIcon from '@material-ui/icons/ViewList'
import { Button } from '@material-ui/core'
import PauseIcon from '@material-ui/icons/Pause'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'

const useStyles = makeStyles(theme => ({
  controlBar: {
    borderBottom: '1px solid rgba(0,0,0,0.2)',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '3px 10px'
  }
}))

function LiveViewHeader ({ mode, setMode, ...props }) {
  const classes = useStyles()
  const buttonText = mode === 'list' ? 'Map View' : 'List View'
  const Icon = mode === 'list' ? MapIcon : ListIcon

  function handleModeToggle () {
    if (mode === 'list') {
      setMode('map')
    } else {
      setMode('list')
    }
  }

  const feedButtonText = props.active ? 'Pause Feed' : 'Resume Feed'
  const FeedButtonIcon = props.active ? PauseIcon : PlayArrowIcon

  return (
    <div className={classes.controlBar}>
      <div style={{ display: 'flex', marginLeft: 'auto', marginRight: 0 }}>
        <Button
          variant='outlined'
          color='primary'
          startIcon={<FeedButtonIcon />}
          onClick={() => props.setActive(!props.active)}
          size='small'
          style={{ height: 38 }}
        >
          {feedButtonText}
        </Button>
        <Button
          startIcon={<Icon />}
          onClick={handleModeToggle}
          color='primary'
          size='small'
          style={{ height: 38, marginLeft: 10, width: 100 }}
        >
          {buttonText}
        </Button>
      </div>

    </div>
  )
}

export default LiveViewHeader
