import Account from 'classes/accounts'
import { createContext, Dispatch, ReactNode, SetStateAction, useState } from 'react'

interface AccountPageValues {
  account: Account
  isLoading: boolean
  error?: Error
  propspectModal: Account | null
  setProspectModal: Dispatch<SetStateAction<Account | null>>
  detailsModalOpen: boolean
  setDetailsModalOpen: Dispatch<SetStateAction<boolean>>
  lastSelectedDetail: string
  setLastSelectedDetail: Dispatch<SetStateAction<string>>
}

export const AccountPageContext = createContext<AccountPageValues>({
  account: new Account({}),
  isLoading: true,
  error: undefined,
  propspectModal: null,
  setProspectModal: () => ({}),
  detailsModalOpen: false,
  setDetailsModalOpen: () => ({}),
  lastSelectedDetail: '',
  setLastSelectedDetail: () => ('')
})

interface AccountPageProviderProps {
  children: ReactNode
  id: number | string
}

export default function AccountPageProvider ({ children, id } : AccountPageProviderProps) {
  const { data: account, isLoading, error } = Account.loadOne(id)
  const [propspectModal, setProspectModal] = useState<Account | null>(null)
  const [detailsModalOpen, setDetailsModalOpen] = useState<boolean>(false)
  const [lastSelectedDetail, setLastSelectedDetail] = useState<string>('companyName')

  return (
    <AccountPageContext.Provider
      value={{
        account,
        isLoading,
        error,
        propspectModal,
        setProspectModal,
        detailsModalOpen,
        setDetailsModalOpen,
        lastSelectedDetail,
        setLastSelectedDetail
      }}
    >
      {children}
    </AccountPageContext.Provider>
  )
}
