
const descriptionText = "Use this bot template to create a standard homepage bot to interact with first time visitors who come to the homepage of your website. This template is similar to the Homepage template with added functionality to answer FAQ's for your visitors. This bot:"

const Description = (): JSX.Element => {
  return (
    <div>
      <div>
        {descriptionText}
      </div>
      <ul>
        <li>Asks to show the user a demo. Default responses:</li>
        <ul>
          <li>Yes please!</li>
          <ul>
            <li>Asks qualifying questions to determine fit</li>
            <li>If the visitor is a good fit for your company, the bot sends the visitor a calendar invite</li>
          </ul>
          <li>I'd like to chat with a human first</li>
          <ul>
            <li>Attempts to route the visitor to live chat</li>
            <li>If no agents are available, attempts to book a meeting</li>
          </ul>
          <li>Nah, I need customer support</li>
          <ul>
            <li>Prompts the user to ask a question or submit a ticket</li>
            <li>Uses the FAQ node and associated knowledgebase to answer the visitor's questions</li>
          </ul>
        </ul>
      </ul>
    </div>
  )
}

const bestPracticeText = "This bot works best when connected to a thorough and detailed knowledgebase. You can do this using either the built in Signals FAQ's, or using a Customer Support integration"

const BestPractices = () => {
  return (
    <div>
      <div>
        {bestPracticeText}
      </div>
      <ul>
        <li><a href='#/settings/integrations' target='_blank'>Manage Integrations</a></li>
        <li><a href='#/settings/faq_management/faqs' target='_blank'>Signals FAQ Management</a></li>
      </ul>
    </div>
  )
}

export const homepageFAQBotDocs = {
  helpCenterLink: '',
  bestPractices: BestPractices,
  description: Description,
  videoLink: ''
}
