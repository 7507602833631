import { useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied'
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied'

const emptyStyles = makeStyles(theme => ({
  emptyIcon: {
    color: '#999999',
    height: 30,
    width: 30
  },
  outerDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
  },
  iconDiv: {
    display: 'flex',
    justifyContent: 'center'
  },
  displayText: {
    paddingTop: 5,
    fontFamily: 'poppins, sans serif',
    fontWeight: 500,
    fontSize: '13px',
    color: '#999999'
  }
}))

export default function EmptyState (props: {
  message?: string | string[]
  sad?: boolean
}): JSX.Element {
  const classes = emptyStyles()
  const message = useMemo(() => !props.message ? <></> : !Array.isArray(props.message) ? (
    <div className={classes.displayText}>{props.message}</div>
  ) : props.message.map(message => (
    <div key={message} className={classes.displayText}>{message}</div>
  )), [props.message, classes.displayText])

  return (
    <div className={classes.outerDiv}>
      <div>
        <div className={classes.iconDiv}>
          {props.sad ? (
            <SentimentDissatisfiedIcon
              className={classes.emptyIcon}
            />
          ) : (
            <SentimentSatisfiedIcon
              className={classes.emptyIcon}
            />
          )}
        </div>
        {message}
      </div>
    </div>
  )
}
