
import { Box, Grow, Typography } from 'library/materialUI';
import { Icon } from 'library/materialUI/Icon';

interface CalendarConfirmationProps {
  next: () => void
}

export default function CalendarConfirmation ({ next } : CalendarConfirmationProps) {
  return (
    <Box sx={{ display: 'flex' }}>
      <Grow in timeout={2000} addEndListener={() => setTimeout(() => { window.sessionStorage.setItem('onboardingConfirmation', 'none'); next() }, 2500)}>
        <div style={{ width: 500, height: 200, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
          <Icon icon='eventAvailable' size='xxlg' color='green' />
          <Typography>Your calendar is now connected!</Typography>
        </div>
      </Grow>
    </Box>
  )
}
