import { makeStyles } from '@material-ui/core/styles'
import { Icon, Tooltip } from './materialUI'

const useStyles = makeStyles(theme => ({
  chip: {
    maxHeight: '33px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '5px',
    borderRadius: '16px',
    paddingRight: 5
  },
  closeIcon: {
    display: 'flex',
    cursor: 'pointer'
  }
}))

type StaticChipProps = {
  value: string
  variant?: 'solid' | 'outlined'
  chipColor?: string
  fontColor?: string
  closeColor?: string
  canClose?: boolean
  onDelete?: () => void
}

export default function StaticChip (props: StaticChipProps) {
  const classes = useStyles()
  const value = props.value.length > 25 ? props.value.slice(0, 24) + '...' : props.value
  const variant = props.variant || 'outlined'
  const chipColor = props.chipColor || '#9933FF40'
  const styled = variant === 'outlined' ? { border: `1px solid ${chipColor}` } : { backgroundColor: chipColor }
  const closeColor = props.closeColor || '#000000'
  const fontColor = props.fontColor || '#000000'

  return (
    <Tooltip title={props.value.length > 25 ? props.value : ''}>
      <div className={classes.chip} style={styled}>
        <div style={{ marginRight: '10px', marginLeft: '10px', marginTop: '5px', marginBottom: '5px', fontSize: '14px', fontFamily: 'poppins', color: fontColor }}>
          {value}
        </div>
        {props.canClose ? (
          <div className={classes.closeIcon} onClick={props.onDelete}><Icon icon='cancel' color={closeColor} /></div>
        ) : <></>}
      </div>
    </Tooltip>
  )
}
