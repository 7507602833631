const headers = {
  'Content-Type': 'application/vnd.api+json'
}

function getRoutes ({ chatServiceUrl }) {
  return fetch(
    `https://${chatServiceUrl}/pending_conversation_routes`,
    {
      method: 'GET',
      headers: headers,
      cache: 'no-store'
    }
  ).then(response => response.json())
}

function claimRoute ({ chatServiceUrl, route }) {
  return fetch(
    `https://${chatServiceUrl}/pending_conversation_routes/${route}/claim`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/vnd.api+json' },
      body: JSON.stringify({
        data: {
        }
      })
    }
  ).then(response => {
    if (!response.ok) {
      throw Error(response.statusText)
    } else {
      return response.json()
    }
  })
}

export { getRoutes, claimRoute }
