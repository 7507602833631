import { GetIntegrationMapping, IntegrationMappingType, integrationsNames } from 'helpers/integrations';
import GenericObject from './genericObject';
import { LoadAllProps } from './queryHelpers';
import { useDoQuery, useDoTypeQuery } from './useDoQuery';
import { DateType } from './classHelpers';
import { doDelete } from 'api/api';

interface FormSubmissionList {
  list: FormSubmission[]
  dict: { [id: number]: FormSubmission }
  meta: { page: { total: number } }
}

type InsightMeta = {
  name: string,
  id?: number
}

type InsightTypes = 'integration' | 'email'

type Insight = {
  tenant_id: number,
  contact_id: number,
  created_timestamp: DateType
  play_id: number,
  type: InsightTypes,
  meta?: InsightMeta[]
}

type ContactInsight = {
  contact_id: number,
  insights: Insight[]
}[]

export default class FormSubmission extends GenericObject {
  objectType = 'FormSubmission'
  formID: number
  contactID: number
  participantID: number
  data: Record<string, any>
  prompt: boolean
  contactFirstName: string
  contactLastName: string
  email: string
  contactAvatar: string
  droppedCalendar: boolean
  meetingBooked: boolean
  liveChat: boolean
  agentName: string
  agentAvatar: string
  icp: boolean
  companyName: string
  companyDomain: string
  insights: ContactInsight
  followUp: string
  status: string

  static get selectedField (): string { return 'fs.id' }

  get insightIntegrations (): IntegrationMappingType[] {
    const addedToIntegrationsList: IntegrationMappingType[] = []
    this.insights.forEach(contactInsight => {
      contactInsight.insights.forEach(insights => {
        if (insights.type === 'integration' && insights.meta) {
          insights.meta.forEach((meta) => {
            const name = meta.name?.toLowerCase()
            if (integrationsNames.includes(name)) {
              addedToIntegrationsList.push(GetIntegrationMapping(name, insights.created_timestamp))
            }
          })
        } else if (insights.type === 'email' && insights.meta) {
          insights.meta.forEach((meta) => {
            const name = meta.name?.trim()
            addedToIntegrationsList.push(GetIntegrationMapping('email', insights.created_timestamp, name))
          })
        }
      })
    })
    return addedToIntegrationsList
  }

  dynamicField (label: string): any {
    if (label === 'full_name') {
      let name = this.data.first_name || ''
      if (this.data.middle_name) name += ` ${this.data.middle_name}`
      if (this.data.last_name) name += ` ${this.data.last_name}`
      return name
    }
    return this.data[label]
  }

  static loadAll (loadAllProps?: LoadAllProps): { data: FormSubmissionList, isLoading: boolean } {
    return useDoQuery({
      path: '/forms/submissions',
      useChatURL: true,
      objectClass: FormSubmission,
      initialData: { list: [], dict: {}, meta: { page: { total: 0 } } },
      searchParams: loadAllProps?.searchParams
    })
  }

  static loadCount (loadAllProps?: LoadAllProps): { data: number | number[], isLoading: boolean } {
    return useDoTypeQuery({
      path: '/forms/submissions:count',
      useChatURL: true,
      searchParams: loadAllProps?.searchParams,
      keepPreviousData: true
    })
  }

  delete (): Promise<any> {
    const path = `/forms/submissions/${this.id}`
    return doDelete({ path, useChatURL: true })
  }

  constructor ({ row }: { row?: Record<string, any> }) {
    super({ row })
    const attributes: Record<string, any> = row?.attributes || {}
    this.formID = attributes?.form_id
    this.contactID = attributes?.contact_id
    this.participantID = attributes?.participant_id
    this.data = attributes?.data
    this.prompt = attributes?.prompt
    this.contactFirstName = attributes?.first_name
    this.email = attributes?.email
    this.contactLastName = attributes?.last_name
    this.contactAvatar = attributes?.contact_avatar
    this.droppedCalendar = attributes?.dropped_calendar
    this.meetingBooked = attributes?.meeting_booked
    this.liveChat = attributes?.live_chat
    this.agentName = attributes?.agent_name
    this.agentAvatar = attributes?.agent_avatar
    this.icp = attributes?.icp
    this.companyName = attributes?.company_name
    this.companyDomain = attributes?.company_domain
    this.insights = attributes?.insights
    this.followUp = attributes?.follow_up
    this.status = attributes?.status
  }
}
