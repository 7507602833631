const headers = {
  'Content-Type': 'application/vnd.api+json'
}

const updateSixsense = ({ integration }) => {
  return fetch('/api/integrations/sixsense', {
    method: 'PATCH',
    headers: headers,
    body: JSON.stringify({ data: integration })
  })
    .then((response) => response.json())
}

const getSixsense = () => {
  return fetch('/api/integrations/sixsense', {
    method: 'GET',
    headers: headers
  })
    .then((response) => response.json())
}

export { getSixsense, updateSixsense }
