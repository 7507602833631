import { Snackbar } from 'library/materialUI'
import { useEffect, useState } from 'react'
import SnackbarContent, { typeMapping } from './SnackbarContent'

interface CSVDownloadSnackbarProps {
  open: boolean
  type: keyof typeof typeMapping
}

export default function CSVDownloadSnackbar ({ open, type } : CSVDownloadSnackbarProps) {
  const downloadingText = 'CSV Downloading'
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [snackbarTitle, setSnackbarTitle] = useState(downloadingText)

  useEffect(() => {
    if (openSnackbar && !open) {
      setSnackbarTitle('CSV Downloaded')
      setTimeout(() => {
        setOpenSnackbar(open)
        setSnackbarTitle(downloadingText)
      }, 5000)
    } else {
      setOpenSnackbar(open)
    }
    // eslint-disable-next-line
  }, [open])

  useEffect(() => {
    if (type === 'error') {
      setSnackbarTitle('Error')
    }
  }, [type])

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      open={openSnackbar}
    >
      <SnackbarContent
        onClose={() => setOpenSnackbar(false)}
        title={snackbarTitle}
        type={type}
      />
    </Snackbar>
  )
}
