import { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Card,
  Button,
  Typography
} from '@material-ui/core'
import {
  getIntegrationFeature,
  FEATURES
} from '../../../api/integrations'
import SalesforceIcon from 'img/salesforce.svg'
import { Modal, ModalSection } from 'library/Modal'
import { getABMSettings, updateABMSettings } from 'api/account_management'
import { useHistory } from 'react-router-dom'
import { Switch } from 'library/materialUI'

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: 18,
    fontWeight: 600,
    marginBottom: 15
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 10
  },
  integrationCard: {
    borderRadius: 5,
    border: '1px solid #975ce678',
    backgroundColor: '#f1eff6',
    display: 'flex',
    justifyContent: 'center'
  },
  switchActive: {
    color: 'white',
    backgroundColor: '#60c427'
  }
}))

const AccountIntegrationSettings = props => {
  const classes = useStyles()
  const history = useHistory()
  const [integrationEnabled, setIntegrationEnabled] = useState(false)
  const [abmSettings, setAbmSettings] = useState(null)

  const handleGoToIntegrations = () => {
    history.push('/settings/integrations')
  }

  const forceSync = () => {
    props.syncAccounts()
    props.onHide()
  }

  const toggleIntegration = checked => {
    if (abmSettings && abmSettings?.attributes?.sync_settings?.fallback_user_id) {
      const customSettings = { ...abmSettings }
      const syncSettings = customSettings.attributes.sync_settings
      const newSyncSettings = { ...syncSettings, account_sync: checked }
      const integrationBody = {
        attributes: {
          sync_settings: newSyncSettings
        }
      }

      updateABMSettings({ ABMSettings: integrationBody })
        .then(response => {
          setAbmSettings(response.data)
        })
    }
  }

  useEffect(() => {
    getIntegrationFeature(FEATURES.CRMABE)
      .then(response => {
        if (response.data.attributes.integration) {
          setIntegrationEnabled(true)
        } else {
          setIntegrationEnabled(false)
        }
      })
  }, [])

  useEffect(() => {
    if (integrationEnabled) {
      getABMSettings()
        .then(response => {
          setAbmSettings(response.data)
        })
    }
  }, [integrationEnabled])

  return (
    <>
      <Modal
        title='Configure Account Sync'
        open={props.open}
        onHide={props.onHide}
        size='md'
        saveIcon='cached'
        handleSave={forceSync}
        saveBtnText='Force Sync'
        saveDisabled={!integrationEnabled || !abmSettings?.attributes?.sync_settings?.fallback_user_id}
      >
        {integrationEnabled && abmSettings ? (
          <>
            <ModalSection
              title='Account Sync'
            >
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant='body2'>
                  Pull in accounts and account-related information from your CRM
                </Typography>
                <Switch
                  checked={abmSettings.attributes.sync_settings.account_sync}
                  handleChange={(checked) => toggleIntegration(checked)}
                  color='secondary'
                />
              </div>
              {!abmSettings?.attributes?.sync_settings?.fallback_user_id && (
                <div style={{ margin: 10 }}>
                  <Typography><i>*You must setup <b>Account Ownership Rules</b> before you can enable Account Sync.</i></Typography>
                </div>
              )}
            </ModalSection>
            <ModalSection
              title='Ownership Rules'
            >
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant='body2'>
                  Configure how your organization will determine the account owner on the integration settings page
                </Typography>
                <div style={{ width: 200 }}>
                  <Button variant='outlined' color='primary' onClick={handleGoToIntegrations} style={{ textTransform: 'none' }}>
                    Go to integrations
                  </Button>
                </div>
              </div>
            </ModalSection>
          </>
        )
          : (
            <Card className={classes.integrationCard}>
              <div style={{ padding: 20 }}>
                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 15 }}>
                  <img src={SalesforceIcon} style={{ height: 65 }} alt='logo' />
                </div>
                <div style={{ textAlign: 'center', fontWeight: 100, fontSize: 16, marginBottom: 15 }}>
                  It seems your CRM Integration for ABE hasn't been configured yet!
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Button variant='outlined' color='primary' onClick={handleGoToIntegrations}>
                    Go to Integrations
                  </Button>
                </div>
              </div>
            </Card>
          )}
      </Modal>
    </>
  )
}

export default AccountIntegrationSettings
