import Card from 'library/materialUI/Card'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Radio } from 'library/materialUI'
import { useContext, useEffect } from 'react'
import { TabContext } from '../IntegrationPage/TabProvider'
import { IntegrationPageContext } from '../IntegrationPage/IntegrationPageProvider'

const useStyles = makeStyles((theme) => ({
  subtitle1: {
    fontSize: 18,
    fontWeight: 600,
    marginBottom: 15
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  bodyText: {
    marginBottom: 30,
    fontSize: 16
  }
}))

interface SendingRecordsCardProps {
  save: any
  handleEdit: any
  integrationSettings: any
}

export default function SendingRecordsCard ({ save, handleEdit, integrationSettings }: SendingRecordsCardProps) {
  const classes = useStyles()
  const sendLeads = integrationSettings.attributes.config.send_as_leads
  const { setCanSave, shouldSave } = useContext(TabContext)
  const { handleSaved } = useContext(IntegrationPageContext)

  useEffect(() => {
    if (shouldSave) {
      save()
      handleSaved()
    }
  })

  return (
    <Card
      content={
        <>
          <div className={classes.header}>
            <Typography className={classes.subtitle1}>
              Sending Records to Salesforce
            </Typography>
          </div>
          <Typography variant='body2' className={classes.bodyText}>
            Choose when you would like leads to be synced to Salesforce from Signals
          </Typography>
          <div style={{ marginBottom: 20 }}>
            <Radio
              label='Send as Contacts if associated with an existing Account, send as Leads if not (recommended)'
              value={!sendLeads}
              checked={!sendLeads}
              handleChange={() => { handleEdit({ type: 'send_as_leads', data: false }); setCanSave(true) }}
            />
            <Radio
              label='Always send as Leads'
              value={sendLeads}
              checked={sendLeads}
              handleChange={() => { handleEdit({ type: 'send_as_leads', data: true }); setCanSave(true) }}
            />
          </div>
        </>
      }
    />
  )
}
