
const descriptionText = 'By placing a Conversation Status node in your bot, you can automatically change the status of the web visitor’s conversation to Open or Closed when they reach that point in your bot’s flow.'

const Description = (): JSX.Element => {
  return (
    <div>
      <div>
        {descriptionText}
      </div>
    </div>
  )
}

const bestPracticeText = 'This node is best used to automatically close the web visitor’s conversation with the bot when the bot is finished and when the bot does not include any agent chat routing.'

const BestPractices = () => {
  return (
    <div>
      <div>
        {bestPracticeText}
      </div>
      <ul>
        <li>Closing conversations helps agents quickly see which chats need attention</li>
        <li>Do not use this node on essential conversations that agents need to review personally</li>
      </ul>
    </div>
  )
}

export const ConversationStatusDocs = {
  helpCenterLink: 'https://help.getsignals.ai/article/i95o2xs95e-conversation-status-node',
  bestPractices: BestPractices,
  description: Description,
  videoLink: ''
}
