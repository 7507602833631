import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Chip, Switch, Button, Typography, TextField } from '@material-ui/core'
import CustomizedSnackbar from '../../../components/CustomizedSnackbar'
import { INTEGRATIONS } from '../../../api/integrations'
import ZoomInfoLogo from '../../../img/zoominfo_icon.png'
import { updateZoomInfo, getZoomInfo } from '../../../api/zoominfo'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import { Link } from 'react-router-dom'

const useStyles = makeStyles({
  headerImage: {
    height: 65,
    marginBottom: 30
  },
  disabledHeaderImage: {
    height: 65,
    marginBottom: 30,
    filter: 'grayscale(100%)'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  manualToggle: {
    marginRight: 40,
    marginTop: 20
  },
  wrapIcon: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 15
  },
  container: {
    padding: 65,
    minWidth: 920
  },
  bodyText: {
    marginBottom: 25,
    fontSize: 16
  },
  subtitle1: {
    fontSize: 18,
    fontWeight: 600,
    marginBottom: 15
  },
  subtitle2: {
    fontSize: 15,
    fontWeight: 600,
    marginBottom: 10
  },
  groupContainer: {
    backgroundColor: 'rgba(193, 166, 237, 0.1)',
    padding: 30,
    borderRadius: 10,
    marginBottom: 20
  },
  apiKeyField: {
    display: 'flex'
  },
  textField: {
    width: '90%',
    marginRight: 10
  },
  visibilityButton: {
    height: 56,
    marginTop: -1
  },
  breadcrumbs: {
    position: 'absolute',
    top: 5
  },
  crumbWrapper: {
    padding: 10
  },
  crumb: {
    fontSize: '.8em'
  },
  typography: {
    fontSize: '1.05em'
  }
})

const initialValues = {
  type: 'integrations',
  attributes: {
    integration_settings: {
      integration_name: INTEGRATIONS.ZoomInfo,
      enabled: false,
      config: {},
      mappings: {}
    },
    external_keys: {
      username: '',
      client_id: '',
      private_key: ''
    }
  }
}

const ZoomInfoIntegrationPage = (props) => {
  const [zoomInfoSettings, setZoomInfoSettings] = useState(initialValues)
  const [snackState, setSnackState] = useState({
    open: false,
    variant: 'success',
    message: 'Your changes have been saved'
  })
  const classes = useStyles()

  const save = () => {
    updateZoomInfo({ integration: zoomInfoSettings })
      .then((r) => {
        setSnackState({
          open: true,
          variant: 'success',
          message: 'Your changes have been saved'
        })
      })
  }

  const toggleIntegration = (checked) => {
    zoomInfoSettings.attributes.integration_settings.enabled = checked
    save()
  }

  const handleEdit = ({ type, data }) => {
    setZoomInfoSettings({
      ...zoomInfoSettings,
      attributes: {
        ...zoomInfoSettings.attributes,
        external_keys: {
          ...zoomInfoSettings.attributes.external_keys,
          [type]: data
        }
      }
    })
  }

  useEffect(() => {
    getZoomInfo()
      .then(response => {
        if (response.ok) {
          setZoomInfoSettings(response.data)
        } else {
          updateZoomInfo({ integration: initialValues }).then((response) => {
            setZoomInfoSettings(response.data)
          })
        }
      })
  }, [])

  return (
    <>
      <Breadcrumbs aria-label='breadcrumb' classes={{ li: classes.crumb, ol: classes.crumbWrapper }}>
        <Link style={{ color: '#7A7A7A' }} to='/settings/integrations'>
          Integrations Page
        </Link>
        <Typography color='textPrimary' classes={{ root: classes.typography }}>ZoomInfo Integration Page</Typography>
      </Breadcrumbs>
      <div className={classes.container}>
        <div className={classes.header}>
          <img
            src={ZoomInfoLogo}
            className={zoomInfoSettings.attributes.integration_settings.enabled ? classes.headerImage : classes.disabledHeaderImage}
            alt='Clearbit Logo'
          />
          {zoomInfoSettings ? (
            <div className={classes.manualToggle}>
              <Chip
                label={
                  zoomInfoSettings.attributes.integration_settings.enabled
                    ? 'Integration: On'
                    : 'Integration: Off'
                }
                color={
                  zoomInfoSettings.attributes.integration_settings.enabled
                    ? 'primary'
                    : 'default'
                }
                className={classes.chip}
              />
              <Switch
                checked={
                  zoomInfoSettings.attributes.integration_settings.enabled
                }
                onChange={(e) => toggleIntegration(e.target.checked)}
                name='master_toggle'
                className={classes.switch}
              />
            </div>
          ) : (
            <></>
          )}
        </div>
        <Typography variant='body2' className={classes.bodyText}>
          Integrate your Signals account with ZoomInfo to enrich your data
          and power your business!
        </Typography>
        <div className={classes.groupContainer}>
          <Typography variant='subtitle1' className={classes.subtitle1}>
            API
          </Typography>
          <Typography variant='body2' className={classes.bodyText}>
            Access all of ZoomInfo enrichment capabilities using your Client ID and Private Key
          </Typography>
          <Typography variant='subtitle2' className={classes.subtitle2}>
            Email Associated with ZoomInfo:
          </Typography>
          <TextField
            style={{ marginBottom: 15 }}
            className={classes.textField}
            color='primary'
            variant='outlined'
            type='text'
            value={zoomInfoSettings.attributes.external_keys.username}
            onChange={(e) => {
              handleEdit({ type: 'username', data: e.target.value })
            }}
          />
          <Typography variant='subtitle2' className={classes.subtitle2}>
            Client ID:
          </Typography>
          <TextField
            style={{ marginBottom: 15 }}
            className={classes.textField}
            color='primary'
            variant='outlined'
            type='text'
            value={zoomInfoSettings.attributes.external_keys.client_id}
            onChange={(e) => {
              handleEdit({ type: 'client_id', data: e.target.value })
            }}
          />
          <Typography variant='subtitle2' className={classes.subtitle2}>
            Private Key:
          </Typography>
          <div style={{ marginBottom: 20 }} className={classes.apiKeyField}>
            <TextField
              className={classes.textField}
              color='primary'
              variant='outlined'
              multiline
              rows={20}
              placeholder={
              `Make sure to copy the key in this format (Including the text in caps):
-----BEGIN PRIVATE KEY-----
Your private key goes here
-----END PRIVATE KEY-----`
              }
              type='text'
              value={zoomInfoSettings.attributes.external_keys.private_key}
              onChange={(e) => {
                handleEdit({ type: 'private_key', data: e.target.value })
              }}
            />
          </div>
        </div>
        <Button
          className={classes.actionButton}
          color='primary'
          variant='contained'
          onClick={save}
        >
          Save
        </Button>
      </div>
      <CustomizedSnackbar state={snackState} handler={setSnackState} />
    </>
  )
}

export default ZoomInfoIntegrationPage
