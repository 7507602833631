import { makeStyles } from '@material-ui/styles'
import { Icon } from 'library/materialUI/Icon'
import { Button, Drawer } from 'library/materialUI'

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 500,
    height: '100%',
    backgroundColor: 'white'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#F7F7F7',
    padding: 10
  },
  startIcon: {
    marginRight: 0
  },
  label: {
    marginLeft: 2
  },
  root: {
    minWidth: 0,
    padding: 5,
    marginRight: 5
  },
  contents: {
    padding: 10
  }
}))

export type DrawerKind = {
  component: Function
  title: string
} | null

function DrawerContents ({ state, dispatch, Component }: { state: any, dispatch: () => void, Component: Function | undefined }): JSX.Element {
  if (Component !== undefined) {
    return (
      <Component
        state={state}
        dispatch={dispatch}
      />
    )
  }
  return (<div />)
}

export function DrawerHeader ({ title, handleClose, handleSave }: { title: string, handleClose: () => void, handleSave?: () => void }): JSX.Element {
  const classes = useStyles()

  return (
    <div className={classes.header}>
      <div>
        {title}
      </div>
      <div>
        <Button
          variant='text'
          color='primary'
          size='small'
          startIcon={<Icon icon='close' />}
          onClick={handleClose}
          classes={{
            startIcon: classes.startIcon,
            label: classes.label,
            root: classes.root
          }}
        />
        {handleSave &&
          <Button
            variant='contained'
            color='primary'
            size='small'
            startIcon={<Icon icon='save' />}
            onClick={handleSave}
            classes={{
              startIcon: classes.startIcon,
              label: classes.label,
              root: classes.root
            }}
          >
            Save
          </Button>}
      </div>
    </div>
  )
}

type SettingsDrawerProps = {
  handleClose: () => void
  handleSave?: () => void
  state?: any
  dispatch?: any
  drawerContent: DrawerKind
}

export function SettingsDrawer ({ drawerContent, handleClose, handleSave, state, dispatch }: SettingsDrawerProps): JSX.Element {
  const classes = useStyles()
  const title = drawerContent ? drawerContent.title : ''

  return (
    <Drawer
      open={drawerContent !== null}
      variant='persistent'
      PaperProps={{ style: { position: 'absolute', left: 2, zIndex: 1201 } }}
    >
      <div className={classes.drawer}>
        <DrawerHeader
          title={title}
          handleClose={handleClose}
          handleSave={handleSave}
        />
        <div className={classes.contents}>
          <DrawerContents
            state={state}
            dispatch={dispatch}
            Component={drawerContent?.component}
          />
        </div>
      </div>
    </Drawer>
  )
}
