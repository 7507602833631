import React, { useState, useContext } from 'react'
import { disableMFA } from 'api/mfa'
import SetupMFA from './MfaModals'
import Modal from '../../components/Modal'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { SessionContext } from '../../session-context'
import WarningIcon from '@material-ui/icons/Warning'
import LockIcon from '@material-ui/icons/Lock'
import { green, yellow } from '@material-ui/core/colors'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import ChangePasswordModal from './ChangePasswordModal'

const useStyles = makeStyles({
  root: {
    maxWidth: 345
  },
  media: {
    height: 140
  },
  icon: {
    paddingLeft: '20px',
    paddingTop: '20px'
  },
  requiredInfoBox: {
    border: '1px solid darkgrey',
    borderRadius: 5,
    padding: 5,
    marginTop: 10,
    color: '#8c8c8c',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
})

function DisableConfirmModal (props) {
  return (
    <Modal
      title='Disable multi-factor authentication'
      onHide={() => {
        props.onHide()
      }}
      open={props.open}
      onSubmit={() => {
        props.disableMFA()
      }}
      size='xs'
      saveButtonText='Disable'
    >
      Are you sure you want to disable secure multi-factor authentication? Multi-factor authentication helps keep your account secure.
    </Modal>
  )
}

function MySecuritySettings (props) {
  const classes = useStyles()
  const { reloadUser } = useContext(SessionContext)
  const [openSetupModal, setOpenSetupModal] = useState(false)
  const [openPasswordModal, setOpenPasswordModal] = useState(false)
  const [openDisableConfirmModal, setOpenDisableConfirmModal] = useState(false)
  const tenant = props.tenant
  const { mfa_phone, mfa_email, email } = props.user.attributes
  const enabled = mfa_phone || mfa_email

  const handleDisableMFA = () => {
    disableMFA().then(response => {
      setOpenDisableConfirmModal(false)
      reloadUser()
    })
  }

  return (
    <div>
      <Card className={classes.root}>
        <CardActionArea>
          <div className={classes.icon}>
            {enabled ? (
              <LockIcon fontSize='large' style={{ color: green[500] }} />
            ) : (
              <WarningIcon fontSize='large' style={{ color: yellow[600] }} />
            )}
          </div>
          <CardContent>
            <Typography gutterBottom variant='h5' component='h2'>
              Multi-factor Authentication
            </Typography>
            <Typography variant='body2' color='textSecondary' component='p'>
              Secure your account with security codes sent via text message or email. Receive a new code on your device each time you login.
            </Typography>
            {tenant?.attributes?.mfa_requirement === 'required' &&
              <div className={classes.requiredInfoBox}>
                <InfoOutlinedIcon fontSize='small' style={{ marginRight: 5 }} />
                <div>
                  This feature is required by your organization.
                </div>
              </div>}
          </CardContent>
        </CardActionArea>
        <CardActions style={{ display: 'flex', justifyContent: 'space-between' }}>
          {enabled ? (
            <>
              <Button
                disabled={tenant?.attributes?.mfa_requirement === 'required'}
                size='small' color='primary' onClick={() => {
                  setOpenDisableConfirmModal(true)
                }}
              >
                Disable
              </Button>
              <Button
                size='small' color='primary' onClick={() => {
                  setOpenSetupModal(true)
                }}
              >
                Change method
              </Button>
            </>
          ) : (
            <Button
              size='small' color='primary' onClick={() => {
                setOpenSetupModal(true)
              }}
            >
              Setup
            </Button>
          )}
        </CardActions>
      </Card>
      <br />
      <Card className={classes.root}>
        <CardActionArea>
          <div className={classes.icon} />
          <CardContent>
            <Typography gutterBottom variant='h5' component='h2'>
              Change password
            </Typography>
            <Typography variant='body2' color='textSecondary' component='p'>
              Change the password you use to log in to Signals.
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <Button
            size='small' color='primary' onClick={() => {
              setOpenPasswordModal(true)
            }}
          >
            Change Password
          </Button>
        </CardActions>
      </Card>
      <ChangePasswordModal
        email={email}
        open={openPasswordModal}
        submit={(e) => {
          setOpenPasswordModal(false)
        }}
        onHide={() => {
          setOpenPasswordModal(false)
        }}
      />
      <SetupMFA
        user={props.user}
        reloadUser={reloadUser}
        open={openSetupModal}
        onHide={() => setOpenSetupModal(false)}
      />
      <DisableConfirmModal
        open={openDisableConfirmModal}
        disableMFA={handleDisableMFA}
        onHide={() => {
          setOpenDisableConfirmModal(false)
        }}
      />
    </div>
  )
}

export default MySecuritySettings
