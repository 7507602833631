import { makeStyles } from '@material-ui/core/styles'
import { BillingContext } from 'billing-context'
import { ChargifyOffer } from 'classes/chargifyOffer'
import NonActiveAccountModal from 'components/NonActiveAccountModal/NonActiveAccountModal'
import { Button } from 'library/materialUI'
import { useContext, useState } from 'react'

const useStyles = makeStyles(theme => ({
  upgradeTile: {
    backgroundColor: '#9933FF',
    borderRadius: 5,
    marginBottom: 10,
    padding: 20,
    color: 'white',
    maxWidth: 300,
    font: 'normal normal normal 14px/21px Poppins',
    display: 'flex',
    alignItems: 'center'
  },
  upgradeHeader: {
    font: 'normal normal bold 18px/27px Poppins',
    marginBottom: 5
  }
}))

export function UpgradeTile () {
  const classes = useStyles()
  const { billing } = useContext(BillingContext)
  const { data: offers } = ChargifyOffer.loadAll()
  const [modalOpen, setModalOpen] = useState(false)
  let benefits: string[] = []

  const activeOfferList = offers.list.filter(offer => offer.offerType === billing?.attributes?.plan_type)

  if (activeOfferList.length) {
    const activeOffer = activeOfferList[0]
    benefits = activeOffer.missingBenefits
  }

  if (!benefits.length) {
    return (
      <div />
    )
  }

  return (
    <div className={classes.upgradeTile}>
      <div>
        <div className={classes.upgradeHeader}>
          What you're missing:
        </div>
        <ul>
          {benefits.map((benefit, index) => (
            <li key={index}>{benefit}</li>
          ))}
        </ul>
        {modalOpen && (
          <NonActiveAccountModal
            inactiveType='active'
            initialStage={1}
            allowClose
            curPlanType={billing.attributes.plan_type}
          />
        )}
        <Button
          variant='contained'
          fullWidth
          style={{ backgroundColor: 'white', color: '#9933FF', marginTop: 5 }}
          onClick={() => setModalOpen(true)}
        >
          Upgrade Now
        </Button>
      </div>

    </div>
  )
}
