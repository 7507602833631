import { useDoQuery } from './useDoQuery'
import { doPost, doPatch, doDelete } from 'api/api'
import { GenericObject } from './genericObject'
import { DateType, castToDate } from './classHelpers'
import { fromName, fromValue } from './durations'
import { LoadAllProps } from './queryHelpers'

interface AlertList {
  list: Alert[]
  dict: { [id: Alert['id']]: Alert }
}

interface alertLoadAllProps extends LoadAllProps {
  personalOnly?: boolean
}

export interface Trigger {
  cmp: string;
  prop: string;
  value: any;
}

const DB_PROPS = [
  'name',
  'description',
  'enabled',
  'isGlobal',
  'targetIDs',
  'trigger',
  'filters',
  'notificationSettings',
  'duration',
  'domain'] as const

export default class Alert extends GenericObject {
  description: string
  enabled: boolean
  isGlobal: boolean
  targetIDs: { [key in 'users' | 'teams' | 'ao']?: number[] }
  trigger: Trigger
  /** Used for additionally filtering by a segment */
  filters: [Trigger] | []
  notificationSettings: { [key in 'app' | 'email' | 'sms']: boolean } | { [key in 'external']: Record<string, string> }
  duration: number
  domain: string | null
  readonly createdByUserID: number
  readonly deletedTimestamp: DateType
  readonly updatedUserID: number
  readonly lastEditedTimestamp: DateType

  get subtitle (): string { return this.isGlobal ? 'Global Alert' : 'Personal Alert' }
  get searchField (): string { return this.name + this.description }
  // eslint-disable-next-line
  constructor ({ row }: { row: Record<string, any> }) {
    super({ row })
    const alert = row.attributes
    this.description = alert.description
    this.enabled = alert.enabled
    this.isGlobal = alert.is_global
    this.targetIDs = alert.target_ids
    this.trigger = alert.trigger
    this.filters = alert.filters
    this.notificationSettings = alert.notification_settings
    this.duration = fromName(alert.duration).value
    this.domain = alert.domain
    this.createdByUserID = alert.created_by_user_id
    this.updatedUserID = alert.updated_by_user_id
    this.lastEditedTimestamp = castToDate(alert.updated_timestamp)
    this.deletedTimestamp = castToDate(alert.deleted_timestamp)
  }

  static loadAll (props?: alertLoadAllProps): { data: AlertList, isLoading: boolean } {
    let path = '/abm/alerts'
    if (props?.personalOnly) {
      path += '?filter[ownership]=mine'
    }
    return useDoQuery({
      path,
      useChatURL: true,
      objectClass: Alert,
      initialData: { list: [], dict: {} },
      searchParams: props?.searchParams
    })
  }

  static save (alert: Partial<Alert>, dbProps: readonly typeof DB_PROPS[number][] = DB_PROPS): Promise<any> {
    const attributes = dbProps.reduce((acc, cur) => {
      switch (cur) {
        case 'isGlobal': return { ...acc, is_global: alert[cur] }
        case 'targetIDs': return { ...acc, target_ids: alert[cur] }
        case 'notificationSettings': return { ...acc, notification_settings: alert[cur] }
        case 'duration': return { ...acc, duration: fromValue(alert[cur]).name }
        default: return { ...acc, [cur]: alert[cur] }
      }
    }, {} as Record<string, any>)

    const data = {
      type: 'alert',
      attributes
    }

    if (alert.id) {
      return doPatch({ path: `/abm/alerts/${alert.id}`, useChatURL: true, data })
    } else {
      return doPost({ path: '/abm/alerts', useChatURL: true, data })
    }
  }

  delete (): Promise<any> {
    return doDelete({ path: `/abm/alerts/${this.id}`, useChatURL: true })
  }
}
