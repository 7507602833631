import { makeStyles } from '@material-ui/core/styles'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
  andDivider: {
    color: theme.palette.primary.main,
    marginLeft: 30,
    marginTop: 2
  },
  orDivider: {
    width: 'calc(100% - 30px)',
    display: 'grid',
    gridTemplateColumns: 'auto 40px auto',
    alignItems: 'center',
    color: theme.palette.primary.main,
    margin: 'auto',
    marginTop: 10,
    marginBottom: 10
  },
  dividerText: {
    display: 'flex',
    justifyContent: 'center'
  },
  divider: {
    backgroundColor: theme.palette.primary.main
  }
}))

function ConditionalDivider (props) {
  const classes = useStyles()
  const filterLen = props.filterLen
  const index = props.index

  if (index < filterLen - 1) {
    if (props.operator === 'and') {
      return (
        <div className={classes.andDivider}>
          AND
        </div>
      )
    } else {
      return (
        <div className={classes.orDivider}>
          <Divider className={classes.divider} />
          <div className={classes.dividerText}>
            <Typography>OR</Typography>
          </div>
          <Divider className={classes.divider} />
        </div>
      )
    }
  }
  return <></>
}

export default ConditionalDivider
