import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Divider from '@material-ui/core/Divider'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import Slide from '@material-ui/core/Slide'
import Switch from '@material-ui/core/Switch'
import TextField from '@material-ui/core/TextField'
import { ThemeProvider, makeStyles } from '@material-ui/core/styles'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import SaveIcon from '@material-ui/icons/Save'
import SaveAltIcon from '@material-ui/icons/SaveAlt'
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications'
import { cloneSequence, createSequenceFlow, saveSequenceTemplate } from 'api/sequences'
import { Modal } from 'cf-components/Modal'
import CustomizedSnackbar from 'components/CustomizedSnackbar'
import { Modal as CFModal, ModalSection } from 'library/Modal'
import { Icon, Typography } from 'library/materialUI'
import React, { useRef, useState } from 'react'
import theme from 'theme'
import SequenceConfigModal from './SequenceConfigModal'
import SequenceEditor from './SequenceEditor'
import { EVENT_TYPE, Emitter } from 'emitter'
import { VersionHistoryModal } from '../surface/TopMenuBar'
import HistoryIcon from '@material-ui/icons/History'

const useStyles = makeStyles(theme => ({
  warningIcon: {
    position: 'relative',
    top: '13px',
    display: 'inline-block'
  },
  paper: {
    margin: 0
  },
  paperFullWidth: {
    width: '100%'
  },
  paperScrollPaper: {
    maxHeight: '100%'
  },
  dialogContents: {
    height: '100vh',
    width: '100%',
    display: 'grid',
    gridTemplateRows: '60px auto'
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '12px 25px',
    borderBottom: '1px solid rgba(0,0,0,0.2)'
  },
  title: {
    fontFamily: 'Poppins, sans serif',
    fontWeight: 600,
    color: 'rgba(0,0,0,0.65)',
    fontSize: '1.4em'
  },
  buttons: {
    display: 'flex'
  },
  button: {
    marginRight: 20
  },
  radioGroup: {
    padding: 10,
    paddingBottom: 0
  },
  subsection: {
    marginTop: 15
  },
  divider: {
    marginTop: 5,
    marginBottom: 5
  },
  sectionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '14px',
    fontWeight: 600
  },
  label: {
    fontWeight: 600,
    fontSize: '14px'
  },
  advancedDetails: {
    fontSize: '14px',
    padding: '5px 15px'
  }
}))

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

function SequenceFlowHeader (props) {
  const classes = useStyles()

  const handleCreateTemplate = props.createTemplate
  const title = props.title ? 'Sequence: ' + props.title : 'Edit Sequence'

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.header}>
        <div className={classes.title}>
          {title}
        </div>
        <div className={classes.buttons}>
          <Button
            variant='outlined'
            className={classes.button}
            color='primary'
            onClick={() => {
              if (props.canSave) {
                props.setSaveModalOpen('close')
                // props.save({ close: true })
              } else {
                props.close()
              }
            }}
            startIcon={<ArrowBackIcon />}
          >
            Back to Bot
          </Button>
          <Button
            variant='outlined'
            className={classes.button}
            color='primary'
            onClick={() => props.openConfigModal()}
            startIcon={<SettingsApplicationsIcon />}
          >
            Edit Details
          </Button>
          {!props.trackingTemplate &&
            <Button
              variant='outlined'
              className={classes.button}
              color='primary'
              disabled={props.canSave}
              onClick={handleCreateTemplate}
              startIcon={<SaveAltIcon />}
            >
              Create New Template
            </Button>}
          {props.trackingTemplate &&
            <Button
              startIcon={<HistoryIcon />}
              variant='outlined'
              color='primary'
              className={classes.button}
              onClick={() => props.setVersionModalOpen(true)}
            >
              Version History
            </Button>}
          <Button
            startIcon={<SaveIcon />}
            variant='contained'
            color='primary'
            disabled={!props.canSave}
            onClick={() => {
              if (props.trackingTemplate) {
                props.setSaveModalOpen('save')
              } else {
                props.save({ close: false })
              }
            }}
          >
            Save
          </Button>
        </div>
      </div>
    </ThemeProvider>
  )
}

function CreateTemplateModal (props) {
  const classes = useStyles()
  const [title, setTitle] = useState(props.title)
  const [description, setDescription] = useState(props.description)
  const [trackTemplate, setTrackTemplate] = useState(false)

  return (
    <Modal
      title='Create New Template'
      onHide={props.onHide}
      open={props.open}
      size='sm'
      rightButtonProps={{
        text: 'Create Template',
        action: () => {
          props.save({ title, description, trackTemplate })
          props.onHide()
        },
        icon: SaveIcon,
        disabled: !(title && description)
      }}
      leftButtonProps={{
        text: 'Cancel',
        action: props.onHide,
        variant: 'text'
      }}
    >
      <div className={classes.subsection}>
        <div className={classes.sectionHeader}>
          Basic Details
        </div>
        <Divider style={{ marginBottom: 5 }} />
        <TextField
          fullWidth
          onChange={(e) => setTitle(e.target.value)}
          variant='outlined'
          margin='dense'
          label='Sequence Title'
          value={title}
        />
        <div style={{ marginTop: 20 }}>
          <TextField
            fullWidth
            multiline
            rows={2}
            onChange={(e) => setDescription(e.target.value)}
            margin='dense'
            label='Sequence Description'
            variant='outlined'
            value={description}
          />
        </div>
      </div>
      <div className={classes.subsection}>
        <div className={classes.sectionHeader}>
          Advanced
          <FormControlLabel
            label='Track global sequence'
            labelPlacement='start'
            classes={{ label: classes.label }}
            control={
              <Switch
                checked={trackTemplate}
                onChange={(e) => setTrackTemplate(e.target.checked)}
                color='primary'
              />
            }
          />
        </div>
        <Divider style={{ marginBottom: 5 }} />
        <div className={classes.advancedDetails}>
          You are about to create a new global sequence from the current sequence.
          If you choose to track the global sequence, the current sequence will automatically update whenever any changes are made to the global version.
        </div>
      </div>
    </Modal>
  )
}

function SaveModal (props) {
  const classes = useStyles()
  const [saveOption, setSaveOption] = useState('')
  const isLocal = props.isLocal
  let cancelAction = props.onHide
  if (props.variant === 'close') {
    cancelAction = () => {
      props.onHide()
      props.backToBot()
    }
  }

  return (
    <Modal
      title='Save Sequence'
      onHide={props.onHide}
      open={props.variant !== false}
      size='sm'
      rightButtonProps={{
        text: 'Save',
        action: () => {
          props.save({ saveOption, close: props.variant === 'close' })
          props.onHide()
        },
        icon: SaveIcon,
        disabled: (!saveOption && !isLocal)
      }}
      leftButtonProps={{
        text: props.variant === 'close' ? 'Discard Changes' : 'Cancel',
        action: cancelAction,
        variant: 'text'
      }}
    >
      {isLocal ? (
        <div>
          Are you sure you want to leave without saving?
        </div>
      ) : (
        <>
          <div>
            You are editing a sequence that is tracking a global sequence. Please choose an option below:
          </div>
          <div className={classes.radioGroup}>
            <RadioGroup value={saveOption} onChange={(e) => setSaveOption(e.target.value)}>
              <FormControlLabel value='updateTemplate' control={<Radio color='primary' />} label='Update the global sequence with my current changes' />
              <FormControlLabel value='localOnly' control={<Radio color='primary' />} label='Update current sequence and stop tracking global sequence' />
            </RadioGroup>
          </div>
        </>)}
    </Modal>
  )
}

function WarningModal (props) {
  const classes = useStyles()
  const title = props.sequenceTitle
  const description = props.sequenceDescription
  const ports = props.ports.map(p => ({ id: p.id, label: p.label }))
  const sequenceID = props.sequenceTemplate?.id
  const sequenceFlowID = props.sequenceFlowID
  const warningTitle = (
    <div style={{ width: '300px', whiteSpace: 'nowrap' }}>
      <div className={classes.warningIcon}>
        <Icon
          icon='warning'
          color='#F4D451'
          size='lg'
        />
      </div>
      <Typography variant='h6' style={{ display: 'inline-block', marginLeft: '5px' }}>
        Editing sequences not supported
      </Typography>
    </div>
  )
  return (
    <CFModal
      open={props.open}
      onHide={props.close}
      title={warningTitle}
      noPadding
      handleSave={() => {
        if (sequenceID) {
          cloneSequence({ sequenceID }).then(response => {
            window.open(`/#/sequences/${response.data.id}`, '_blank')
            props.close()
          })
        } else {
          saveSequenceTemplate({ flowID: sequenceFlowID, ports, title, description, sequenceID }).then(response => {
            window.open(`/#/sequences/${response.data.id}`, '_blank')
            props.close()
          })
        }
      }}
      noDividers
      closeBtn
      saveBtnText='CLONE SEQUENCE'
      cancelBtnText='CANCEL'
      closeBtnFunction={props.close}
      showCancelButton
    >
      <div>
        <ModalSection>
          <div style={{ padding: '10px' }}>
            To make changes to a sequence in an A/B test:
            <ol>
              <li>Clone the sequence you want to A/B test</li>
              <li>Make the changes to the clone and save as a new local or global sequence</li>
              <li>Replace the original sequence with the clone here</li>
            </ol>
            For more detailed instructions, <a rel='noreferrer' href='https://help.getsignals.ai/article/xxbfga6z5u-ab-tests-editing-sequences' target='_blank'>visit this helpdoc.</a>
          </div>
        </ModalSection>
        <ModalSection />
      </div>
    </CFModal>
  )
}

function SequenceFlowModal (props) {
  const classes = useStyles()
  const dataRef = useRef(null)
  const saveRef = useRef(null)
  const toolkitInstanceRef = useRef(null)
  const refreshSequenceRef = useRef(null)
  const [canSave, setCanSave] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [templateModalOpen, setTemplateModalOpen] = useState(false)
  const [saveModalOpen, setSaveModalOpen] = useState(false)
  const [sequenceFlowID, setSequenceFlowID] = useState(props.sequenceFlowID)
  const [versionModalOpen, setVersionModalOpen] = useState(false)
  const [snackState, setSnackState] = useState({
    open: false,
    variant: 'success',
    message: 'Your sequence has been saved'
  })
  const handleClose = props.close
  const title = props.sequenceTitle
  const description = props.sequenceDescription
  const ports = props.ports.map(p => ({ id: p.id, label: p.label }))
  const sequenceID = props?.sequenceNode?.state?.trackTemplate ? props.sequenceTemplate?.id : null
  const abtest = window.location.hash.startsWith('#/bot_tests')
  window.sequenceID = sequenceID

  function saveFlow ({ close, saveOption }) {
    const flow = {
      data: {
        edges: dataRef.current.data.edges,
        nodes: dataRef.current.data.nodes
      }
    }
    createSequenceFlow({
      track: saveOption === 'updateTemplate',
      title,
      description,
      flow,
      ports,
      sequenceID
    })
      .then(response => {
        const flowID = response.attributes.id
        setSequenceFlowID(flowID)
        const parent = window.sequenceNode
        const parentNode = parent.getNode()
        const newData = {
          ...parent.state,
          title: title,
          description: description,
          sequenceFlowID: flowID
        }
        if (saveOption === 'localOnly') {
          newData.trackTemplate = false
        } else if (saveOption === 'updateTemplate') {
          window.refreshSequences()
        }
        parent.toolkit.updateNode(parentNode, newData)
        window.saveParentBot()
        setCanSave(false)
        if (close) {
          props.close()
        }
      })
  }

  function saveDetails ({ title, description, trackTemplate }) {
    if (props.sequenceTitle !== title) {
      setCanSave(true)
    } else if (props.sequenceDescription !== description) {
      setCanSave(true)
    } else if (props.sequenceNode.state.trackTemplate !== trackTemplate) {
      setCanSave(true)
    }
    const parent = window.sequenceNode
    const parentNode = parent.getNode()
    const newData = {
      ...parent.state,
      title: title,
      description: description,
      trackTemplate: trackTemplate,
      sequenceFlowID: sequenceFlowID
    }
    parent.toolkit.updateNode(parentNode, newData)
    setModalOpen(false)
  }

  function createTemplate ({ title, description, trackTemplate }) {
    const flowID = sequenceFlowID
    saveSequenceTemplate({ flowID, title, description, ports })
      .then(response => {
        const sequenceID = response.data.id
        if (trackTemplate) {
          const parent = window.sequenceNode
          const parentNode = parent.getNode()
          const newData = {
            ...parent.state,
            title: title,
            description: description,
            trackTemplate: trackTemplate,
            parentSequenceID: sequenceID
          }
          parent.toolkit.updateNode(parentNode, newData)
          window.saveParentBot()
        } else {
          setSnackState({
            open: true,
            variant: 'success',
            message: 'A new master sequence has been created'
          })
        }
        window.refreshSequences()
      })
  }

  function save (values) {
    saveFlow(values)
  }

  if (!saveRef.current) {
    saveRef.current = () => props.close()
  }
  if (abtest) {
    return WarningModal(props)
  }

  function restoreVersion (flowID) {
    setVersionModalOpen(false)
    setCanSave(false)
    props.restoreFlow(flowID)
  }

  Emitter.on(EVENT_TYPE.BROWSER_BACK, handleClose)

  return (
    <Dialog
      open={props.open}
      TransitionComponent={Transition}
      onEntered={() => { }}
      maxWidth='xl'
      fullWidth
      onClose={handleClose}
      classes={{
        paper: classes.paper,
        paperFullWidth: classes.paperFullWidth,
        paperScrollPaper: classes.paperScrollPaper
      }}
      onKeyDown={e => e.stopPropagation()}
      onKeyDownCapture={e => e.stopPropagation()}
      onKeyPress={e => e.stopPropagation()}
      onKeyPressCapture={e => e.stopPropagation()}
    >
      <div className={classes.dialogContents}>
        <SequenceFlowHeader
          title={props.sequenceTitle}
          close={props.close}
          canSave={canSave}
          save={saveRef.current}
          createTemplate={() => setTemplateModalOpen(true)}
          openConfigModal={() => setModalOpen(true)}
          trackingTemplate={props.sequenceNode.state.trackTemplate}
          setSaveModalOpen={(e) => setSaveModalOpen(e)}
          setVersionModalOpen={setVersionModalOpen}
        />
        <SequenceEditor
          sequenceFlow={props.sequenceFlow}
          setCanSave={setCanSave}
          dataRef={dataRef}
          restoreVersionRef={props.restoreVersionRef}
          refreshSequenceRef={refreshSequenceRef}
          toolkitInstanceRef={toolkitInstanceRef}
          saveRef={saveRef}
          save={save}
        />
        <CustomizedSnackbar
          state={snackState}
          handler={setSnackState}
        />
        <SequenceConfigModal
          open={modalOpen}
          onHide={() => setModalOpen(false)}
          title={props.sequenceTitle}
          description={props.sequenceDescription}
          trackTemplate={props.sequenceNode.state.trackTemplate}
          save={saveDetails}
          isTemplate={props.sequenceNode.state.trackTemplate}
        />
        <SaveModal
          variant={saveModalOpen}
          onHide={() => setSaveModalOpen(false)}
          save={saveRef.current}
          backToBot={() => {
            props.close()
            refreshSequenceRef.current()
          }}
          isLocal={!props.sequenceNode.state.trackTemplate}
        />
        <CreateTemplateModal
          open={templateModalOpen}
          onHide={() => setTemplateModalOpen(false)}
          title={props.sequenceTitle}
          description={props.sequenceDescription}
          save={createTemplate}
        />
        <VersionHistoryModal
          open={versionModalOpen}
          onHide={() => setVersionModalOpen(false)}
          sequenceID={sequenceID}
          loadFlow={restoreVersion}
          currentFlowID={props.sequenceFlowID}
        />
      </div>
    </Dialog>
  )
}

export default SequenceFlowModal
