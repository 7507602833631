import { makeStyles } from '@material-ui/core/styles'
import { Icon } from 'library/materialUI'

const useStyles = makeStyles(theme => ({
  grayText: {
    color: '#00000080',
    fontFamily: 'poppins'
  }
}))

interface LearnMoreLinkProps {
  link: string
}

export default function LearnMoreLink ({ link }: LearnMoreLinkProps): JSX.Element {
  const classes = useStyles()

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row', textDecoration: 'underline', marginTop: '15px' }}>
        <div style={{ marginTop: '2px', marginRight: '5px' }}>
          <Icon icon='smallHelp' color='gray' size='sm' />
        </div>
        <div>
          <a className={classes.grayText} href={link} target='_blank' rel='noopener noreferrer'>Learn more</a>
        </div>
      </div>
    </>
  )
}
