import React, { useState, useEffect, useContext, useCallback, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import AppPage from 'cf-components/AppPage'
import Chip from '@material-ui/core/Chip'
import { domainSearch, deleteDomains, createDomains, importFromCSV } from 'api/email_domains'
import { useDebounce } from 'use-debounce'
import { SessionContext } from 'session-context'
import { useLocation } from 'react-router-dom'
import { FilterBar } from 'cf-components/FilterBar/FilterBar'
import EmptyState from 'components/EmptyState'
import EmailDomainModal from './EmailDomainModal'

const useStyles = makeStyles(theme => ({
  tableDiv: {
    margin: '0px 20px'
  },
  chipContainer: {
    border: '2px solid rgba(0,0,0,0.2)',
    borderRadius: 8,
    minHeight: 200,
    padding: 15,
    marginBottom: 5
  },
  chip: {
    marginRight: 10,
    marginBottom: 10
  },
  emptyWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 'inherit'
  }
}))

function DomainTab (props) {
  const classes = useStyles()
  return (
    <>
      <FilterBar
        search={props.search}
        setSearch={props.setSearch}
      />
      <div className={classes.tableDiv}>
        <div className={classes.chipContainer}>
          {
            props.domains.length === 0
              ? (
                <div className={classes.emptyWrapper}>
                  <EmptyState message={'No ' + props.type + ' domains...'} />
                </div>
              ) : props.domains.map((domain, index) => (
                <Chip
                  label={domain.attributes.domain}
                  key={index}
                  color='primary'
                  onDelete={() => props.handleDelete(domain.id)}
                  className={classes.chip}
                />
              ))
          }
        </div>
      </div>
    </>
  )
}

function EmailManagementPage (props) {
  const [search, setSearch] = useState('')
  const [debouncedSearch] = useDebounce(search, 500)
  const [domains, setDomains] = useState([])
  const [modalOpen, setModalOpen] = useState(false)
  const [kind, setKind] = useState('spam')
  const [csvImportErrors, setCSVImportErrors] = useState(undefined)
  const { user } = useContext(SessionContext)
  const chatServiceUrl = user.links.chat_service
  const location = useLocation()

  const loadDomains = useCallback(() => {
    domainSearch({ search: debouncedSearch, chatServiceUrl })
      .then(response => setDomains(response.data))
  }, [chatServiceUrl, debouncedSearch])

  useEffect(() => {
    loadDomains()
  }, [debouncedSearch, loadDomains])

  const tabs = useMemo(() => [
    { name: 'Spam Domains', url: '/settings/email_management/spam_domains', tab: 0, kindValue: 'spam' },
    { name: 'Personal Domains', url: '/settings/email_management/personal_domains', tab: 1, kindValue: 'personal' },
    { name: 'Blocked Domains', url: '/settings/email_management/blocked_domains', tab: 2, kindValue: 'blocked' }
  ], [])

  useEffect(() => {
    if (!modalOpen) {
      const index = tabs.map(tab => tab.url).indexOf(location.pathname)
      const tab = index === -1 ? 0 : index
      setCSVImportErrors(undefined)
      setKind(tabs[tab].kindValue)
    }
  }, [location.pathname, modalOpen, tabs])

  function handleDelete (domainID) {
    const domain_ids = [domainID]
    deleteDomains({ domain_ids, chatServiceUrl })
      .then(() => loadDomains())
  }

  function handleCreate ({ domains, kind }) {
    createDomains({ domains, kind, chatServiceUrl })
      .then(() => {
        loadDomains()
        setModalOpen(false)
      })
  }

  function handleCSVImport ({ kind, csvFile }) {
    importFromCSV({ chatServiceUrl, csvFile, kind }).then(res => {
      if (res.ok) {
        loadDomains()
        setModalOpen(false)
        setCSVImportErrors(undefined)
      } else if (res.errors) {
        setCSVImportErrors(res.errors)
      }
    })
  }

  document.title = 'Email Capture Management | Signals'

  return (
    <>
      <AppPage
        title='Email Capture Management'
        tabs={tabs}
        action={() => setModalOpen(true)}
        actionText='Add Domain'
      >
        <DomainTab
          search={search}
          setSearch={setSearch}
          type='spam'
          domains={domains.filter(domain => domain.attributes.kind === 'spam')}
          handleDelete={handleDelete}
        />
        <DomainTab
          search={search}
          setSearch={setSearch}
          type='personal'
          domains={domains.filter(domain => domain.attributes.kind === 'personal')}
          handleDelete={handleDelete}
        />
        <DomainTab
          search={search}
          setSearch={setSearch}
          type='blocked'
          domains={domains.filter(domain => domain.attributes.kind === 'blocked')}
          handleDelete={handleDelete}
        />
      </AppPage>
      <EmailDomainModal
        open={modalOpen}
        kind={kind}
        setKind={setKind}
        onHide={() => setModalOpen(false)}
        createDomains={handleCreate}
        onCSVImport={handleCSVImport}
        csvImportErrors={csvImportErrors}
      />
    </>
  )
}

export default EmailManagementPage
