import { doPost } from 'api/api'

export type ProspectResponseTypes = 'already prospected' | 'already prospected no contacts' | 'prospect starting' | 'prospect already in progress' | 'no more contact credits'

type ManualProspectPayload = {
  domain: string
  personaIDs: number[]
  contactLimit: number
  gatherAnyways: boolean
  integrations: Record<string, string[]>
}

type BulkProspectPayload = {
  domains: string[]
  personaIDs: number[]
  contactLimit: number
  gatherAnyways: boolean
  integrations: Record<string, string[]>
}

export class ProspectResponse {
  resp: ProspectResponseTypes

  static prospect (data: ManualProspectPayload): Promise<ProspectResponse> {
    return doPost({ path: '/prospect', data: { attributes: data }, useChatURL: true })
    .then((resp) => {
      return new ProspectResponse({ row: resp.data })
    })
  }

  static bulkProspect (data: BulkProspectPayload): Promise<ProspectResponse> {
    return doPost({ path: '/prospect_bulk', data: { attributes: data }, useChatURL: true })
    .then((resp) => {
      return new ProspectResponse({ row: resp.data })
    })
  }

  constructor ({ row }: { row?: Record<string, any> }) {
    const prospectResponse = row?.attributes
    this.resp = prospectResponse.resp
  }
}
