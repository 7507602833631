const headers = {
  'Content-Type': 'application/vnd.api+json'
}

function getNotes ({ conversationID, chatServiceUrl }) {
  const method = 'GET'
  const url = `https://${chatServiceUrl}/notes?filter[conversation_id]=${conversationID}`

  return fetch(url, {
    method: method,
    headers: headers
  })
    .then(response => response.json())
}

function addNote ({ conversationID, noteBody, eventID, taggedUserIDs, chatServiceUrl, parentNoteID }) {
  if (noteBody) {
    const method = 'POST'
    const url = `https://${chatServiceUrl}/notes`

    const body = {
      data: {
        type: 'notes',
        attributes: {
          conversation_id: conversationID,
          conversation_event_id: eventID,
          body: noteBody,
          tagged_user_ids: taggedUserIDs,
          parent_note_id: parentNoteID
        }
      }
    }

    return fetch(url, {
      method: method,
      headers: headers,
      body: JSON.stringify(body)
    }).then(response => response.json())
  }
}

function updateNote ({ noteID, chatServiceUrl, attributes }) {
  if (noteID) {
    const method = 'PATCH'
    const url = `https://${chatServiceUrl}/notes/${noteID}`

    const body = {
      data: {
        type: 'notes',
        id: noteID,
        attributes: attributes
      }
    }

    return fetch(url, {
      method: method,
      headers: headers,
      body: JSON.stringify(body)
    })
      .then(response => response.json())
  }
}

function deleteNote ({ noteID, chatServiceUrl }) {
  if (noteID) {
    const method = 'DELETE'
    const url = `https://${chatServiceUrl}/notes`

    const body = {
      data: {
        type: 'notes',
        id: noteID
      }
    }

    return fetch(url, {
      method: method,
      headers: headers,
      body: JSON.stringify(body)
    })
      .then(response => response.json())
  }
}

export { addNote, deleteNote, getNotes, updateNote }
