import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import SortIcon from '@material-ui/icons/Sort'
import SortAscendingIcon from '@material-ui/icons/ArrowUpward'
import SortDescendingIcon from '@material-ui/icons/ArrowDownward'
import { Popover, MenuItem } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  icon: props => ({
    marginRight: props.iconOnly ? 0 : 5,
    fill: 'rgba(0, 0, 0, 0.54)',
    cursor: 'pointer'
  }),
  filterMenu: {
    paddingTop: 5,
    paddingBottom: 5
  },
  menuPaper: {
    borderRadius: 10
  },
  sortMenuItem: {
    width: 250,
    paddingTop: 4,
    paddingBottom: 4,
    fontSize: '0.97rem',
    display: 'flex',
    alignItems: 'center'
  },
  sortIcon: {
    marginLeft: 10
  },
  sortDiv: props => ({
    display: 'flex',
    flexDirection: 'row',
    margin: '5px',
    width: props.iconOnly ? '40px' : '93px',
    height: '40px',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid #D3D3D3',
    borderRadius: '5px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#F5F5F5'
    }
  })
}))

function SortMenu (props) {
  const classes = useStyles()
  const SortIcon = props.sortAscending ? SortAscendingIcon : SortDescendingIcon

  return (
    <div className={classes.filterMenu}>
      {props.sortFields.map((field, index) => (
        <MenuItem
          className={classes.sortMenuItem}
          key={index}
          onClick={() => {
            if (field.sortField) {
              return props.sort(field.sortField)
            }
            return props.sort(field.name)
          }}
        >
          {field.label}
          {field.name === props.sortCriteria
            ? <SortIcon fontSize='small' className={classes.sortIcon} /> : <></>}
        </MenuItem>
      ))}
    </div>
  )
}

export default function SortButton (props) {
  const classes = useStyles(props)
  const [state, setState] = useState({
    anchorEl: null,
    subMenu: null
  })

  const handleClick = (event) => {
    setState({
      ...state,
      anchorEl: event.currentTarget
    })
  }

  const handleClose = () => {
    setState({
      anchorEl: null,
      subMenu: null
    })
  }

  return (
    <>
      <div onClick={handleClick} className={classes.sortDiv}>
        <SortIcon
          className={classes.icon}
          style={{ fill: state.anchorEl ? 'black' : null }}
        />
        {!props.iconOnly && <div style={{ fontFamily: 'poppins', fontSize: '14px' }}>Sort</div>}
      </div>
      {state.anchorEl &&
        <Popover
          open={Boolean(state.anchorEl)}
          anchorEl={state.anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          style={{ marginTop: 10 }}
          classes={{ paper: classes.menuPaper }}
        >
          <SortMenu
            sortAscending={props.sortAscending}
            sortCriteria={props.sortCriteria}
            sort={props.sort}
            sortFields={props.sortFields}
          />
        </Popover>}
    </>
  )
}
