import { Select } from 'cf-components/material-wrappers/MaterialComponents';
import { EmailSubscription, PeriodTypes } from 'classes/emailSubscriptions';
import { Checkbox, RadioButtons, Typography } from 'library/materialUI';
import { ModalSection } from 'library/Modal';

interface EmailFrequencyModalSectionProps {
  setNewEmailSubscription: (value: any) => void
  newEmailSubscription: EmailSubscription
  showEmptyCheckbox?: boolean
}

export default function EmailFrequencyModalSection ({ setNewEmailSubscription, newEmailSubscription, showEmptyCheckbox }: EmailFrequencyModalSectionProps) {
  const defaultOption: Record<PeriodTypes, string> = {
    daily: '',
    weekly: 'monday',
    monthly: 'first'
  }
  const frequencyComponent: Record<PeriodTypes, JSX.Element> = {
    daily: (
      <div>
        <Typography variant='subtitle1'>Recieve this email on weekends?</Typography>
        <Checkbox
          onChange={() => setNewEmailSubscription({ frequency: { period: 'daily', option: newEmailSubscription.frequency.option === null ? 'weekends' : null } })}
          checked={newEmailSubscription.frequency.option === 'weekends'}
          label='Send updates on the weekends'
        />
      </div>
    ),
    weekly: (
      <div>
        <Typography variant='subtitle1'>On which day of the week should the update be sent?</Typography>
        <Select
          onChange={(value: string) => setNewEmailSubscription({ frequency: { period: 'weekly', option: value } })}
          value={{ label: newEmailSubscription?.frequency?.option?.charAt(0).toUpperCase() + newEmailSubscription?.frequency?.option?.slice(1), value: newEmailSubscription?.frequency?.option }}
          options={[
            { label: 'Monday', value: 'monday' },
            { label: 'Tuesday', value: 'tuesday' },
            { label: 'Wednesday', value: 'wednesday' },
            { label: 'Thursday', value: 'thursday' },
            { label: 'Friday', value: 'friday' },
            { label: 'Saturday', value: 'saturday' },
            { label: 'Sunday', value: 'sunday' }
          ]}
          disableClear
        />
      </div>
    ),
    monthly: (
      <div>
        <Typography variant='subtitle1'>On which day of the month should the update be sent?</Typography>
        <RadioButtons
          value={newEmailSubscription.frequency.option}
          handleChange={(value: string) => setNewEmailSubscription({ frequency: { period: 'monthly', option: value } })}
          buttons={[
            { label: 'First of the month', value: 'first' },
            { label: '15th of the month', value: 'fifteenth' }
          ]}
        />
      </div>
    )
  }

  return (
    <ModalSection
      title='Email Frequency'
      subtitle='How often do you want this email to be sent?'
    >
      <RadioButtons
        value={newEmailSubscription.frequencyPeriod}
        handleChange={(value: PeriodTypes) => setNewEmailSubscription({ frequency: { period: value, option: defaultOption[value] }, frequencyPeriod: value })}
        buttons={[
          { label: 'Daily', value: 'daily' },
          { label: 'Weekly', value: 'weekly' },
          { label: 'Monthly', value: 'monthly' }
        ]}
      />
      {frequencyComponent[newEmailSubscription.frequencyPeriod as PeriodTypes]}
      {showEmptyCheckbox &&
        <div>
          <Typography variant='subtitle1'>Receive this email when there's no new activity to show?</Typography>
          <Checkbox
            onChange={(value: boolean) => setNewEmailSubscription({ sendEmptyState: value })}
            checked={newEmailSubscription.sendEmptyState}
            label='Send updates on days without activity'
          />
        </div>}
    </ModalSection>
  )
}
