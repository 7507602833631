import { DisplaySettings } from 'classes/displaySettings'
import { jsPlumbToolkit, Node, Port } from 'jsplumbtoolkit'

export const getProblemNodes = (toolkit: jsPlumbToolkit): any => {
  const nodes = toolkit.getNodes()
  const unconnected = []
  for (const node of nodes) {
    if (node.data.kind === 'SequenceExit') {
      continue
    }
    const parents = node.getTargetEdges().length
    if (!parents) {
      if (node.id !== 'start') {
        unconnected.push(node)
        continue
      }
    }
    const ports: Port[] = node.getPorts()
    for (const port of ports) {
      const edges = (port as any).getEdges()
      if (port.id === 'again') { continue }
      if (port.data.type === 'integration') {
        DisplaySettings.update({ page: 'ftux', type: 'unconnected-port', settings: { complete: false } })
        continue
      }
      if (!edges.length) {
        unconnected.push(node)
        break
      }
    }
  }
  return unconnected
}

export const getInvalidNodes = (toolkit: jsPlumbToolkit): any => {
  const nodes = toolkit.getNodes()
  const nodeMapping = window.flowBuilder.nodeMapping
  const invalid = []
  for (const node of nodes) {
    const mapping = nodeMapping[node.data.kind]
    if (mapping.schema) {
      const isValid = mapping.schema.isValidSync(node.data)
      if (!isValid) {
        invalid.push(node)
      }
    }
  }
  return invalid
}

type nodeValidation = {
  unconnected: Node[]
  invalid: Node[]
}

export const validateNodes = (toolkit: jsPlumbToolkit): nodeValidation => {
  const unconnected = getProblemNodes(toolkit)
  const invalid = getInvalidNodes(toolkit)
  if (unconnected.length || invalid.length) {
    window.flowBuilder.showSaveProblems = true
  }
  return { unconnected, invalid }
}
