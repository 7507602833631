import { makeStyles } from '@material-ui/core/styles'
import Form from 'classes/forms'

const useStyles = makeStyles(theme => ({
  formBuilder: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: '82vh',
    overflow: 'auto',
    backgroundColor: '#E2E2E2',
    // Just commenting for now in case product manager changes mind
    // backgroundImage: 'linear-gradient(#ccc 1.5px, transparent 1.5px), linear-gradient(90deg, #ccc 1.5px, transparent 1.5px), linear-gradient(#eee 1px, transparent 1px), linear-gradient(90deg, #eee 1px, transparent 1px)',
    // backgroundPosition: '-1.5px -1.5px, -1.5px -1.5px, -1px -1px, -1px -1px',
    // backgroundSize: '100px 100px, 100px 100px, 20px 20px, 20px 20px'
    backgroundImage: 'radial-gradient(rgba(0,0,0,0.2) 1px, transparent 0);',
    backgroundSize: '40px 40px',
    backgroundPosition: '-21px -21px'
  },
  formView: {
    marginTop: 'auto',
    marginBottom: 'auto',
    minWidth: '482px'
  },
  formContent: {
    margin: '50px',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative'
    // width: 100%;
    // height: 100%;
  }
}))

interface FormBuilderProps {
  form: Form
  chatPrimaryColor?: string
}

export default function FormPreview ({ form }: FormBuilderProps) {
  const classes = useStyles()
  // const color = chatPrimaryColor ? `#${chatPrimaryColor}` : '#FFFFFF'

  /* eslint-disable */
  return (
    <div className={classes.formBuilder}>
      <div className={classes.formView}>
        <div className={classes.formContent} dangerouslySetInnerHTML={{ __html: Form.generateScript(form, '', false) }} />
      </div>
    </div>
  )
  /* eslint-enable */
}
