import { useContext, useEffect, useReducer, useState } from 'react'
import { IconType } from 'library/materialUI/Icon'
import { Table, TableHead, TableCell, TableBody, TableRow, Checkbox, Icon, LabeledCheckbox, Button } from 'library/materialUI'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { reducer } from 'library/helpers'
import { ModalSection } from 'library/Modal'
import { ContentsProps } from '../wizard/OnboardingWizard'
import { isEqual } from 'lodash'
import User from 'classes/users'
import { UserContext } from './UserContext'
import InformationIcon from 'library/InformationIcon'
import { soundAlert } from 'pages/chat/agent-chat/chatAlerts'
import { getMessagingService } from 'api/messaging_service'

const useStyles = makeStyles(theme => ({
  additionalDetails: {
    color: '#707070',
    font: 'normal normal normal 14px/21px Poppins',
    marginTop: 15
  },
  checkboxDiv: {
    height: 34,
    display: 'flex',
    alignItems: 'center'
  },
  labelDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
}))

export const notificationsSubtitle = 'Configure notifications for the events listed below'

const Cell = withStyles(theme => ({
  root: {
    padding: 0,
    textAlign: 'center'
  }
}))(TableCell)

type HeaderIcon = {
  label: string
  icon: IconType
  value: string
}

const notificationTypes = [
  { label: 'Routed to Conversation', tooltip: 'Get notified when you\'re routed into a conversation with a vistor', value: 'routes' },
  { label: 'Meeting Booked', tooltip: 'Get notified when a visitor books a meeting with you', value: 'meetings' },
  { label: 'Chat Alerts', tooltip: 'Get notified when a chat occurs', value: 'alerts' }
]

const additionalNotificationsText = 'You can change these settings and add additional alerts ' +
  'under the Notifications tab of the My Settings page'

export function EventNotifications (props: ContentsProps) {
  const { user } = useContext(UserContext)
  const [state, dispatch] = useReducer(reducer, user.notificationSettings)
  const classes = useStyles()
  const [messagingIntegrationName, setMessagingIntegrationName] = useState<'teams' | 'slack' | ''>('')
  const integrationLabels = {
    slack: 'Slack',
    teams: 'Teams'
  }

  useEffect(() => {
    getMessagingService()
      .then((response) => {
        if (response.ok) {
          const name = response.data?.attributes?.integration_name || ''
          if (name) {
            setMessagingIntegrationName(name.toLowerCase())
          }
        }
      })
  }, [])

  const headers: HeaderIcon[] = [
    { label: 'In-app', icon: 'logo', value: 'app' },
    { label: 'Email', icon: 'email', value: 'email' },
    { label: 'Text', icon: 'text', value: 'text' }
  ]
  if (messagingIntegrationName) {
    headers.push({
      label: integrationLabels[messagingIntegrationName],
      icon: `${messagingIntegrationName}IconBlack`,
      value: messagingIntegrationName
    })
  }

  const handleCheck = (event: string, type: string, value: boolean) => {
    const newState = { ...state[event] }
    if (type && type === messagingIntegrationName) {
      newState.external = value ? type : null
    } else {
      newState[type] = value
    }
    dispatch({ [event]: newState })
  }

  props.handleNextRef.current = () => {
    if (!isEqual(state, user.notificationSettings)) {
      User.update(user.id, { notificationSettings: state })
    }
  }

  return (
    <div style={{ marginTop: 15 }}>
      <Table>
        <TableHead>
          <Cell />
          {headers.map((header, index) => (
            <Cell key={index}>
              <div>
                <div style={{ height: 20 }}>
                  <Icon icon={header.icon} color='#4F4F4F' size='md' />
                </div>
                <div>
                  {header.label}
                </div>
              </div>
            </Cell>
          ))}
        </TableHead>
        <TableBody>
          {notificationTypes.map((notification, index) => (
            <TableRow key={index}>
              <Cell style={{ textAlign: 'left' }}>
                <div className={classes.labelDiv}>
                  {notification.label}
                  <InformationIcon message={notification.tooltip} color='grey' />
                </div>
              </Cell>
              {headers.map((header, index) => {
                return (
                  <Cell key={index}>
                    <Checkbox
                      checked={state[notification.value][header.value] || state[notification.value].external === header.value}
                      onChange={(value: boolean) => handleCheck(notification.value, header.value, value)}
                    />
                  </Cell>
                )
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div className={classes.additionalDetails}>
        {additionalNotificationsText}
      </div>
    </div>
  )
}

export const chimeSubtitle = 'Enabling chime alerts for these events will give you ' +
  'an audio notification in Signals'

const chimeTypes = [
  { label: 'New conversations', value: 'conversation' },
  { label: 'New messages in ongoing conversations', value: 'message' },
  { label: 'In-app notifications', value: 'notification' }
]

export function ChimeSection (props: ContentsProps) {
  const classes = useStyles()
  const { user } = useContext(UserContext)
  const [state, dispatch] = useReducer(reducer, user.notificationSettings.chimes)

  const additionalChimesText = 'You can change these settings under the Notifications tab of the My Settings page'

  props.handleNextRef.current = () => {
    const notificationSettings = { ...user.notificationSettings }
    notificationSettings.chimes = state
    User.update(user.id, { notificationSettings })
  }

  return (
    <div style={{ marginTop: 30 }}>
      <ModalSection
        title='Enable chime notifications for:'
      >
        <div>
          {chimeTypes.map((chime, index) => (
            <div key={index} className={classes.checkboxDiv}>
              <LabeledCheckbox
                label={chime.label}
                checked={state[chime.value]}
                onChange={(value: boolean) => dispatch({ [chime.value]: value })}
              />
            </div>
          ))}
          <Button
            variant='outlined'
            color='primary'
            startIcon={<Icon icon='volumeUp' />}
            style={{ margin: '5px 0px' }}
            onClick={() => soundAlert('message')}
          >
            Preview Chime
          </Button>
        </div>
      </ModalSection>
      <div className={classes.additionalDetails}>
        {additionalChimesText}
      </div>
    </div>
  )
}
