import { makeStyles, Theme } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import { DrawerToggleButton } from './DrawerToggleButton'
import clsx from 'clsx'

type Props = {
  drawerWidth: number
}

interface DrawerPageProps {
  drawerWidth: number
  drawerContents: any
  open: boolean
  handleToggle: any
  pageClass?: any
  children: any
}

const useStyles = makeStyles<Theme, Props>(theme => ({
  drawerPage: {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    display: 'flex'
  },
  wrap: {
    width: '100%',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  wrapShift: {
    width: props => `calc(100% - ${props.drawerWidth + 2}px)`,
    marginLeft: props => props.drawerWidth + 2,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  }
}))

export function DrawerPage (props: DrawerPageProps): JSX.Element {
  const classes = useStyles({ drawerWidth: props.drawerWidth })
  return (
    <div className={classes.drawerPage}>
      <Drawer
        open={props.open}
        variant='persistent'
        PaperProps={{ style: { position: 'absolute', left: 2, overflow: 'hidden' } }}
      >
        {props.drawerContents}
      </Drawer>
      <div className={`${props.pageClass} ${clsx(classes.wrap, { [classes.wrapShift]: props.open })}`}>
        <DrawerToggleButton open={props.open} handleToggle={props.handleToggle} />
        {props.children}
      </div>
    </div>
  )
}
