import { jsPlumbUtil } from 'jsplumbtoolkit'
import { nodeMappingData } from 'library/flowBuilder/FlowBuilder'
import { alertMapping } from './alertAgent/AlertNode'
import { BotModal, BotNodeSchema, FireBotBody } from './fireBot/BotNode'
import { ContactSearchModal, ContactSearchBody, ContactSearchSchema } from './contactSearch/ContactSearchNode'
// import { DelayBody, DelayModal, DelayNodeSchema } from './delay/DelayNode'
// import { CampaignEnrollmentBody, CampaignEnrollmentModal, CampaignEnrollmentNodeSchema } from './addToCampaign/CampaignEnrollmentNode'
// import { availabilityMapping, availabilityNodeData } from './availabilityBranching/AvailabilityBranchingNode'
import { geographyBranchingMapping } from './geography/GeographyBranching'
// import { visitorBranchingMapping } from './visitorBranching/VisitorBranchingNode'
import { WebsiteSignalMapping } from './urlBranching/WebsiteSignal'
import { segmentBranchingMapping } from './segmentBranching/SegmentBranching'
import { playMapping } from './play/PlayNode'
import { CreateAccountBody, CreateAccountModal, CreateAccountSchema } from './addAccount/AddAccountNode'
import { EmailModal, EmailNodeSchema, SendEmailBody } from './sendEmail/SendEmailNode'
import { IntegrationBody, IntegrationHeader, IntegrationModal, IntegrationSchema } from './integration/IntegrationNode'
import { SalesloftBody, SalesloftModal, SalesloftSchema } from './integration/SalesloftNode'
import { BotSignalMapping } from './botSignal/BotSignal'
import { IntegrationSignalMapping } from './integrationSignal/IntegrationSignal'

const colorMapping = {
  external: '#4972CC',
  internal: '#EC5F8A',
  outreach: '#49CDA2',
  admin: '#000000',
  start: '#81AF20'
}

export type nodeType = keyof typeof nodeMapping

const categories = {
  messaging: 'Engagement',
  integrations: 'Integrations',
  outreach: 'Outreach',
  internal: 'Internal',
  admin: 'Admin'
}
export const nodeMapping: Record<string, nodeMappingData> = {
  Start: {
    icon: 'start',
    title: 'Play Entry',
    color: colorMapping.start,
    menuOptions: []
  },
  // AvailabilityBranching: availabilityMapping,
  BotSignal: BotSignalMapping,
  WebsiteSignal: WebsiteSignalMapping,
  IntegrationSignal: IntegrationSignalMapping,
  SegmentBranching: segmentBranchingMapping,
  GeographyBranching: geographyBranchingMapping,
  // VisitorBranching: visitorBranchingMapping,
  Sequence: playMapping,
  SequenceExit: {
    title: 'Exit to PlayMaker',
    icon: 'exit',
    color: '#333399'
  },
  FireBot: {
    icon: 'chat',
    title: 'Fire Bot',
    color: colorMapping.external,
    modal: BotModal,
    body: FireBotBody,
    category: categories.messaging,
    schema: BotNodeSchema,
    helpCenterLink: 'home/plays/skills/FireBot'
  },
  SendEmail: {
    icon: 'email',
    title: 'Send Email',
    color: colorMapping.external,
    modal: EmailModal,
    body: SendEmailBody,
    category: categories.messaging,
    schema: EmailNodeSchema,
    helpCenterLink: 'home/plays/skills/SendEmail'
  },
  // AddToCampaign: {
  //   icon: 'addContact',
  //   title: 'Add to Campaign',
  //   color: colorMapping.external,
  //   category: categories.messaging,
  //   modal: CampaignEnrollmentModal,
  //   body: CampaignEnrollmentBody,
  //   schema: CampaignEnrollmentNodeSchema,
  //   helpCenterLink: 'home/plays/skills/AddToCampaign'
  // },
  ContactSearch: {
    icon: 'contactLookupNode',
    title: 'Contact Lookup',
    color: colorMapping.outreach,
    body: ContactSearchBody,
    modal: ContactSearchModal,
    modalSize: 'md',
    category: categories.outreach,
    schema: ContactSearchSchema,
    helpCenterLink: 'home/plays/skills/ContactSearch'
  },
  CreateAccount: {
    icon: 'createAccount',
    title: 'Create Account',
    color: colorMapping.internal,
    body: CreateAccountBody,
    modal: CreateAccountModal,
    category: categories.internal,
    schema: CreateAccountSchema,
    helpCenterLink: 'home/plays/skills/CreateAccount'
  },
  AlertAgent: alertMapping,
  // Delay: {
  //   icon: 'schedule',
  //   title: 'Delay',
  //   color: colorMapping.internal,
  //   modal: DelayModal,
  //   category: categories.internal,
  //   body: DelayBody,
  //   schema: DelayNodeSchema,
  //   helpCenterLink: 'home/plays/skills/Delay'
  // },
  Salesforce: {
    title: 'Salesforce',
    icon: 'salesforce',
    color: '#00A2E0',
    header: IntegrationHeader,
    body: IntegrationBody,
    modal: IntegrationModal,
    category: categories.integrations,
    schema: IntegrationSchema,
    helpCenterLink: '_blank'
  },
  Marketo: {
    title: 'Marketo',
    icon: 'marketo',
    color: '#5943B0',
    header: IntegrationHeader,
    body: IntegrationBody,
    modal: IntegrationModal,
    category: categories.integrations,
    schema: IntegrationSchema,
    helpCenterLink: '_blank'
  },
  Hubspot: {
    title: 'Hubspot',
    icon: 'hubspot',
    color: '#FF7A59',
    header: IntegrationHeader,
    body: IntegrationBody,
    modal: IntegrationModal,
    category: categories.integrations,
    schema: IntegrationSchema,
    helpCenterLink: '_blank'
  },
  Sendinblue: {
    title: 'Brevo',
    icon: 'brevo',
    color: '#0292FA',
    header: IntegrationHeader,
    body: IntegrationBody,
    modal: IntegrationModal,
    category: categories.integrations,
    schema: IntegrationSchema,
    helpCenterLink: '_blank'
  },
  Eloqua: {
    title: 'Eloqua',
    icon: 'eloqua',
    color: '#ED3237',
    header: IntegrationHeader,
    body: IntegrationBody,
    modal: IntegrationModal,
    category: categories.integrations,
    schema: IntegrationSchema,
    helpCenterLink: '_blank'
  },
  Salesloft: {
    title: 'Salesloft',
    icon: 'salesloft',
    color: '#05492D',
    body: SalesloftBody,
    modal: SalesloftModal,
    category: categories.integrations,
    schema: SalesloftSchema,
    helpCenterLink: 'home/plays/skills/Salesloft'
  },
  EndPlay: {
    icon: 'exit',
    title: 'End Play',
    color: '#000',
    category: categories.internal,
    menuOptions: ['delete'],
    completionText: 'END OF PLAY'
  }
}

export const nodeFactory = (type: string, data: any, callback: any): void => {
  data.id = data.id || jsPlumbUtil.uuid()
  if (type === 'sequence' || type === 'sequenceExit') {
    callback(data)
    return
  }
  if (!data.ports) {
    data.ports = []
  }
  data.type = type
  data.settings = {}
  switch (data.kind) {
    // case 'VisitorBranching': {
    //   const portID = jsPlumbUtil.uuid()
    //   data.ports = [
    //     { id: portID, label: 'Branch Label', filters: [[]], type: 'basic' }
    //   ]
    //   data.portOrder = [portID]
    //   data.settings.includeFallback = false
    //   break
    // }
    // case 'AvailabilityBranching':
    //   data = { ...data, ...availabilityNodeData }
    //   break
    case 'IntegrationSignal': {
      const portID = jsPlumbUtil.uuid()
      data.ports = [
        { id: portID, label: 'Branch Label', filters: [[]], type: 'basic' }
      ]
      data.portOrder = [portID]
      data.settings.includeFallback = false
      break
    }
    case 'BotSignal': {
      const portID = jsPlumbUtil.uuid()
      data.ports = [
        { id: portID, label: 'Branch Label', filters: [[]], type: 'basic' }
      ]
      data.portOrder = [portID]
      data.settings.includeFallback = false
      break
    }
    case 'WebsiteSignal': {
      const portID = jsPlumbUtil.uuid()
      data.ports = [
        { id: portID, label: 'Branch Label', filters: [[]], type: 'basic' } // Removed { prop: 'url', cmp: 'contains', value: '' } to allow multiple filters
      ]
      data.portOrder = [portID]
      data.settings.includeFallback = false
      break
    }
    case 'SegmentBranching': {
      const portID = jsPlumbUtil.uuid()
      data.ports = [
        { id: portID, label: 'Branch Label', filters: [[]], type: 'basic' }
      ]
      data.portOrder = [portID]
      data.settings.includeFallback = false
      break
    }
    case 'GeographyBranching': {
      const portID = jsPlumbUtil.uuid()
      data.ports = [
        { id: portID, label: 'Branch Label', filters: [[]], type: 'basic' }
      ]
      data.portOrder = [portID]
      data.settings.includeFallback = false
      break
    }
    case 'Delay':
      data.settings.delayValue = 10
      data.settings.delayUnits = 'minutes'
      data.ports = [{ id: 'default', label: 'Next', type: 'basic' }]
      break
    case 'CreateAccount':
      data.settings.integration = 'salesforce'
      data.settings.attribute = { id: '', name: '', value: '', type: '', options: [], validID: false }
      data.ports = [{ id: 'default', label: 'Next', type: 'basic' }]
      break
    case 'AlertAgent':
      data.settings.routeOptions = [{ method: 'single_agent', objectIDs: [], type: 'round_robin' }]
      data.settings.notification = {
        title: '',
        description: ''
      }
      data.ports = [{ id: 'default', label: 'Next', type: 'basic' }]
      break
    case 'EmailCampaign':
      data.ports = [{ id: 'default', label: 'Next', type: 'basic' }]
      data.settings.integration = ''
      data.settings.campaignID = null
      break
    case 'SendEmail':
      data.settings.emailID = null
      data.settings.delaySend = false
      data.settings.delayHours = 1
      data.settings.integrations = {
        salesforce: [],
        marketo: [],
        hubspot: [],
        sendinblue: [],
        eloqua: []
      }
      data.settings.activeIntegrations = {
        salesforce: false,
        marketo: false,
        hubspot: false,
        sendinblue: false,
        eloqua: false
      }
      data.ports = [
        { id: 'default', label: 'Next', type: 'basic' }
      ]
      break
    case 'FireBot':
      data.settings.botID = null
      data.settings.delayType = 'time'
      data.settings.delay = 0
      data.settings.scrollPercent = 0
      data.ports = [
        { id: 'default', label: 'Next', type: 'basic' }
      ]
      break
    case 'ContactSearch':
      data.settings.limitSearch = true
      data.settings.contactLimit = 5
      data.settings.personaIDs = []
      data.ports = [{ id: 'default', label: 'Next', type: 'basic' }]
      break
    // case 'AddToCampaign':
    //   data.settings.listID = ''
    //   data.settings.integration = ''
    //   data.ports = [{ id: 'default', label: 'Next', type: 'basic' }]
    //   break
    case 'Salesforce':
      data.settings.listIDs = []
      data.settings.mainIntegration = 'salesforce'
      data.settings.title = 'Salesforce'
      data.settings.color = '#00A2E0'
      data.settings.integrations = {
        salesforce: [],
        marketo: [],
        hubspot: [],
        sendinblue: [],
        eloqua: []
      }
      data.settings.activeIntegrations = {
        salesforce: false,
        marketo: false,
        hubspot: false,
        sendinblue: false,
        eloqua: false
      }
      data.ports = [
        { id: 'default', label: 'Next', type: 'basic' }
      ]
      break
    case 'Marketo':
      data.settings.listIDs = []
      data.settings.mainIntegration = 'marketo'
      data.settings.title = 'Marketo'
      data.settings.color = '#5943B0'
      data.settings.integrations = {
        salesforce: [],
        marketo: [],
        hubspot: [],
        sendinblue: [],
        eloqua: []
      }
      data.settings.activeIntegrations = {
        salesforce: false,
        marketo: false,
        hubspot: false,
        sendinblue: false,
        eloqua: false
      }
      data.ports = [
        { id: 'default', label: 'Next', type: 'basic' }
      ]
      break
    case 'Hubspot':
      data.settings.listIDs = []
      data.settings.mainIntegration = 'hubspot'
      data.settings.title = 'Hubspot'
      data.settings.color = '#FF7A59'
      data.settings.integrations = {
        salesforce: [],
        marketo: [],
        hubspot: [],
        sendinblue: [],
        eloqua: []
      }
      data.settings.activeIntegrations = {
        salesforce: false,
        marketo: false,
        hubspot: false,
        sendinblue: false,
        eloqua: false
      }
      data.ports = [
        { id: 'default', label: 'Next', type: 'basic' }
      ]
      break
    case 'Sendinblue':
      data.settings.listIDs = []
      data.settings.mainIntegration = 'sendinblue'
      data.settings.title = 'Sendinblue'
      data.settings.color = '#0292FA'
      data.settings.integrations = {
        salesforce: [],
        marketo: [],
        hubspot: [],
        sendinblue: [],
        eloqua: []
      }
      data.settings.activeIntegrations = {
        salesforce: false,
        marketo: false,
        hubspot: false,
        sendinblue: false,
        eloqua: false
      }
      data.ports = [
        { id: 'default', label: 'Next', type: 'basic' }
      ]
      break
    case 'Eloqua':
      data.settings.listIDs = []
      data.settings.mainIntegration = 'eloqua'
      data.settings.title = 'Eloqua'
      data.settings.color = '#ED3237'
      data.settings.integrations = {
        salesforce: [],
        marketo: [],
        hubspot: [],
        sendinblue: [],
        eloqua: []
      }
      data.settings.activeIntegrations = {
        salesforce: false,
        marketo: false,
        hubspot: false,
        sendinblue: false,
        eloqua: false
      }
      data.ports = [
        { id: 'default', label: 'Next', type: 'basic' }
      ]
      break
    case 'Salesloft':
      data.settings.title = 'Salesloft'
      data.settings.color = '#05492D'
      data.settings.cadenceId = null
      data.settings.action = 'add'
      data.settings.allowReEnroll = false
      data.settings.exceptionCadences = []
      data.ports = [
        { id: 'default', label: 'Next', type: 'basic' }
      ]
      break
  }
  callback(data)
}
