import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Divider from '@material-ui/core/Divider'
import dateFormat from 'dateformat'
import MessageGroup from './MessageGroup'
import CalendarDrop from './CalendarDrop'
import CTAEvent from './CTAEvent'
import CreateContact from './CreateContact'
import ConversationMeta from './ConversationMeta'
import { ConversationContext } from './ConversationContext'

const useStyles = makeStyles(theme => ({
  dateContainer: {
    paddingBottom: 0,
    marginBottom: 14,
    display: 'flex',
    justifyContent: 'center',
    width: 'calc(100% - 40px)',
    paddingLeft: 20,
    paddingRight: 20,
    position: 'sticky',
    top: 0,
    zIndex: '2'
  },
  dividerContainer: {
    flex: 1
  },
  dividerDiv: {
    paddingTop: 8,
    backgroundColor: 'white',
    height: 1
  },
  dateDiv: {
    fontSize: '12px',
    color: '#555555',
    paddingLeft: 10,
    paddingRight: 10,
    backgroundColor: 'white'
  }
}))

const componentMapping = {
  messages: MessageGroup,
  calendar: CalendarDrop,
  meta: ConversationMeta,
  cta: CTAEvent,
  create_contact: CreateContact
}

function DateDivider (props) {
  const classes = useStyles()
  const date = dateFormat(props.group.date, 'dddd, mmmm dd')

  return (
    <div className={classes.dateContainer}>
      <div className={classes.dividerContainer}>
        <div className={classes.dividerDiv}>
          <Divider />
        </div>
      </div>
      <div className={classes.dateDiv}>
        {date}
      </div>
      <div className={classes.dividerContainer}>
        <div className={classes.dividerDiv}>
          <Divider />
        </div>
      </div>
    </div>
  )
}

function MessageGroupDisplay (props) {
  const group = props.group
  const type = group.type
  if (type === 'dateDivider') {
    return (
      <DateDivider
        group={group}
      />
    )
  }

  const eventGroup = group.eventGroup

  if (!componentMapping[eventGroup]) {
    return (
      <></>
    )
  }
  const Component = componentMapping[eventGroup]

  return (
    <ConversationContext.Consumer>
      {state => (
        <Component
          group={group}
          state={state}
        />
      )}
    </ConversationContext.Consumer>
  )
}

export { MessageGroupDisplay }
