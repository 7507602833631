import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'

function createSecondaryMetrics ({ data, id, colorList }) {
  am4core.addLicense('CH213476655')
  const chart = am4core.create(id, am4charts.XYChart)
  chart.colors.list = colorList.map(c => am4core.color(c))

  chart.legend = new am4charts.Legend()
  chart.legend.position = 'top'
  chart.legend.paddingBottom = 20

  const xAxis = chart.xAxes.push(new am4charts.CategoryAxis())
  xAxis.dataFields.category = 'category'
  xAxis.renderer.cellStartLocation = 0.1
  xAxis.renderer.cellEndLocation = 0.9
  xAxis.renderer.grid.template.location = 0

  const yAxis = chart.yAxes.push(new am4charts.ValueAxis())
  yAxis.min = 0
  yAxis.maxPrecision = 0

  function createSeries (value, name) {
    const series = chart.series.push(new am4charts.ColumnSeries())
    series.dataFields.valueY = value
    series.dataFields.categoryX = 'category'
    series.name = name
    const bullet = series.bullets.push(new am4charts.LabelBullet())
    bullet.interactionsEnabled = false
    bullet.dy = 30
    bullet.label.text = '{valueY}'
    bullet.label.fill = am4core.color('#ffffff')

    series.columns.template.events.on('sizechanged', function (ev) {
      if (ev.target.dataItem && ev.target.dataItem.bullets) {
        const height = ev.target.pixelHeight
        ev.target.dataItem.bullets.each(function (id, bullet) {
          if (height < 50) {
            bullet.dy = -15
            bullet.label.fill = am4core.color('#4c4c4c')
          }
        })
      }
    })

    return series
  }

  chart.data = data
  for (const attribute in data[0]) {
    if (attribute !== 'category') {
      createSeries(attribute, attribute)
    }
  }

  return chart
}

export { createSecondaryMetrics }
