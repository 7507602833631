import { SectionProps } from '../HelpCenter'
import { HelpCenterCardList } from '../HelpCenterCardList'
import { SectionCenter } from '../SectionCenter'
import { notificationMapping } from '../NotificationMapping'

const nodeList = ['Chrome', 'Safari', 'Firefox', 'Edge', 'Brave']

export const NotificationsHome = (props: SectionProps): JSX.Element => {
  return (
    <SectionCenter
      homeComponent={<HelpCenterCardList nodeMapping={notificationMapping} nodeList={nodeList} />}
      links={props.links}
      type='node'
    />
  )
}
