import { useContext, useEffect, useReducer, useRef } from 'react'
import { ProblemNodesMenu } from './ProblemNodesMenu'
import { Node } from 'jsplumbtoolkit'
import { FlowBuilderContext } from '../FlowBuilderProvider'
import { Emitter, EVENT_TYPE } from '../helpers/EventEmitter'
import { getInvalidNodes, getProblemNodes } from './saveHelpers'

const initialState = {
  unconnected: [],
  invalid: [],
  menuAnchor: null,
  publish: false,
  zIndex: null
}

const reducer = (state: any, changes: any): any => {
  return { ...state, ...changes }
}

export function SaveMenu (): JSX.Element {
  const [state, dispatch] = useReducer(reducer, initialState)
  const { save, toolkit } = useContext(FlowBuilderContext)
  const saveRef = useRef(save)
  const toolkitRef = useRef(toolkit)
  saveRef.current = save
  toolkitRef.current = toolkit

  useEffect(() => {
    Emitter.on(EVENT_TYPE.INVALID_NODES, (payload: any) => {
      if (toolkit.id === payload.toolkitID) {
        const { unconnected, invalid, target, publish, zIndex } = payload
        window.flowBuilder.showSaveProblems = true
        dispatch({ unconnected, invalid, menuAnchor: target, publish, zIndex })
      }
    })
    Emitter.on(EVENT_TYPE.NODE_CONNECTION, () => {
      if (window.flowBuilder.showSaveProblems) {
        setTimeout(() => {
          const unconnected = getProblemNodes(toolkitRef.current)
          dispatch({ unconnected })
        }, 50)
      }
    })
    Emitter.on(EVENT_TYPE.NODE_UPDATED, () => {
      if (window.flowBuilder.showSaveProblems) {
        setTimeout(() => {
          const invalid = getInvalidNodes(toolkitRef.current)
          dispatch({ invalid })
        }, 50)
      }
    })
  }, [toolkit.id])

  useEffect(() => {
    Emitter.emit(EVENT_TYPE.PROBLEM_NODES, {
      unconnected: state.unconnected.map((node: Node) => node.id),
      invalid: state.invalid.map((node: Node) => node.id)
    })
  }, [state.unconnected, state.invalid])

  return (
    <ProblemNodesMenu
      state={state}
      hideMenu={() => dispatch({ menuAnchor: null })}
      save={() => {
        saveRef.current(state.publish).then(() => dispatch({ menuAnchor: null }))
      }}
    />
  )
}
