import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'

function createConversionTimeline ({ data, id, colorList, startDate }) {
  am4core.addLicense('CH213476655')
  const chart = am4core.create(id, am4charts.XYChart)
  const dateAxis = chart.xAxes.push(new am4charts.DateAxis())
  dateAxis.min = startDate.getTime()
  dateAxis.startLocation = 0.15
  dateAxis.endLocation = 0.95
  const valueAxis = chart.yAxes.push(new am4charts.ValueAxis())
  valueAxis.min = -0.02
  valueAxis.maxPrecision = 0
  valueAxis.extraMax = 0.1
  valueAxis.strictMinMax = true
  valueAxis.numberFormatter = new am4core.NumberFormatter()
  valueAxis.numberFormatter.numberFormat = '#%'
  chart.colors.list = colorList.map(c => am4core.color(c))

  for (let i = 0; i < data.length; i++) {
    createSeries(data[i].name, data[i].rates, i)
  }

  function createSeries (name, rates, index) {
    const rateSeries = chart.series.push(new am4charts.LineSeries())
    rateSeries.name = name
    rateSeries.dataFields.valueY = 'average'
    rateSeries.dataFields.dateX = 'date'
    rateSeries.stroke = chart.colors.getIndex(index)
    rateSeries.strokeOpacity = 1
    const rateSegment = rateSeries.segments.template
    rateSegment.strokeWidth = 3
    rateSegment.interactionsEnabled = true
    rateSeries.data = rates

    const bullet = rateSeries.bullets.push(new am4charts.CircleBullet())
    bullet.fill = new am4core.InterfaceColorSet().getFor('background')
    bullet.fillOpacity = 1
    bullet.strokeWidth = 2
    bullet.circle.radius = 4
    bullet.tooltipText = '{valueY.formatNumber("#.#%")}'

    // var series = chart.series.push(new am4charts.LineSeries())
    // series.dataFields.openValueY = 'open'
    // series.dataFields.valueY = 'close'
    // series.dataFields.dateX = 'date'
    // series.name = name
    // series.stroke = chart.colors.getIndex(index)
    // series.fill = series.stroke
    // series.fillOpacity = 0.3
    // series.strokeOpacity = 0

    // var segment = series.segments.template
    // segment.strokeWidth = 3
    // segment.interactionsEnabled = true

    // var hoverState = segment.states.create('hover')
    // hoverState.properties.strokeWidth = 3

    // var dimmed = segment.states.create('dimmed')
    // dimmed.properties.stroke = am4core.color('#dadada')
    // dimmed.properties.strokeWidth = 1

    // var defaultState = segment.states.create('default')
    // defaultState.properties.strokeWidth = 3

    // series.data = rates

    return rateSeries
  }

  const legend = new am4charts.Legend()
  chart.legend = legend
  legend.data = [{
    name: data[0].name,
    fill: chart.colors.getIndex(0)
  }, {
    name: data[1].name,
    fill: chart.colors.getIndex(1)
  }]

  return chart
}

export { createConversionTimeline }
