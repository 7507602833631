import { useState, useContext, useEffect, useReducer } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { CardHeader } from '@material-ui/core'
import SocialPage from './SocialMediaPage'
import ContactInfo from './ContactInfo'
import CusomizedSnackbar from '../../components/CustomizedSnackbar'
import AccessControl from '../../components/AccessControl'
import { SessionContext } from '../../session-context'
import { getUser } from '../../api/users'
import { Redirect } from 'react-router'

const useStyles = makeStyles(theme => ({
  card: {
    width: 1000
  }
}))

function UserPage (props) {
  const classes = useStyles()

  const user_id = props.user_id || props.match.params.user_id

  const session = useContext(SessionContext)

  const ownUser = Boolean(session.user.id === parseInt(user_id))

  // const editable = ownUser || session.user.attributes.perms.strict_admin
  const editable = ownUser

  const [initialState, setInitialState] = useState(null)

  const [state, dispatch] = useReducer(reducer, null)

  const [snackState, setSnackState] = useState({
    open: false,
    variant: 'success',
    message: 'Your settings have been saved'
  })

  function updateContactInfo (name, value) {
    dispatch({ type: 'updateInfo', name: name, value: value })
  }

  function updateSocialLink (name, value) {
    dispatch({ type: 'updateSocialProfile', name: name, value: value })
  }

  function updateProfilePicture (value) {
    dispatch({ type: 'updateInfo', name: 'profile_picture_url', value: value })
  }

  function reducer (state, action) {
    switch (action.type) {
      case 'initialize':
        return action.info
      case 'updateInfo':
        return { ...state, [action.name]: action.value }
      case 'updateSocialProfile':
        return {
          ...state,
          social_media_profiles: {
            ...state.social_media_profiles,
            [action.name]: action.value
          }
        }
      default:
        throw new Error()
    }
  }

  function save () {
    const headers = {
      'Content-Type': 'application/vnd.api+json'
    }
    const method = 'PATCH'
    const path = `/api/users/${user_id}`

    const body = {
      data: {
        id: user_id,
        type: 'users',
        attributes: state,
        relationships: {}
      }
    }

    fetch(path, {
      method: method,
      headers: headers,
      body: JSON.stringify(body)
    })
      .then(response => response.json())
      .then(user_response => {
        if ('data' in user_response) {
          setSnackState({
            open: true,
            variant: 'success',
            message: 'Your settings have been saved'
          })
          setInitialState(state)
        } else {
          setSnackState({
            open: true,
            variant: 'error',
            message: 'Settings not saved. Please try again later'
          })
        }
      })
  }

  useEffect(() => {
    if (user_id) {
      getUser({ ID: user_id }).then(user_response => {
        const data = user_response.data.attributes
        dispatch({ type: 'initialize', info: data })
        setInitialState(user_response.data.attributes)
      })
    }
  }, [user_id])

  function madeChanges () {
    if (initialState == null) {
      return true
    }
    if (state == null) {
      return true
    }
    if (
      state.name !== initialState.name ||
      state.phone !== initialState.phone ||
      state.email !== initialState.email
    ) { return true }
    if (
      (state.social_media_profiles.linkedin || '') !==
      (initialState.social_media_profiles.linkedin || '')
    ) { return true }
    if (
      (state.social_media_profiles.twitter || '') !==
      (initialState.social_media_profiles.twitter || '')
    ) { return true }
    if (
      (state.social_media_profiles.facebook || '') !==
      (initialState.social_media_profiles.facebook || '')
    ) { return true }
    if (
      (state.social_media_profiles.instagram || '') !==
      (initialState.social_media_profiles.instagram || '')
    ) { return true }
    return false
  }

  const saveDisabled = !madeChanges() || initialState == null

  if (!state) {
    return <></>
  }

  if (ownUser) {
    return (
      <Redirect to='/settings/my_settings/profile' />
    )
  }

  return (
    <div>
      <Card className={classes.card}>
        <CardHeader title='Signals Profile' />
        <CardContent>
          <ContactInfo
            info={state}
            updateContactInfo={updateContactInfo}
            updateProfilePicture={updateProfilePicture}
            editable={editable}
            user_id={user_id}
          />
          <br />
          <SocialPage
            profiles={state.social_media_profiles}
            updateSocialLink={updateSocialLink}
            editable={editable}
          />
          <br />
          {ownUser && (
            <AccessControl
              requiredPermission='strict_admin'
            >
              <Button
                variant='contained'
                color='primary'
                onClick={save}
                disabled={saveDisabled}
              >
                Save
              </Button>
            </AccessControl>
          )}
        </CardContent>
      </Card>
      <CusomizedSnackbar state={snackState} handler={setSnackState} />
    </div>
  )
}

export default UserPage
