import { useReducer } from 'react'
import { Modal, ModalSection } from 'library/Modal'
import { Typography, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import ConsentForm from 'classes/consentForms'
import { transform, isObject, isEqual } from 'lodash'

const useStyles = makeStyles((theme) => ({
  select: {
    width: '100%'
  },
  label: {
    color: 'grey'
  },
  textFieldDiv: {
    width: '48%'
  },
  textField: {
    variant: 'outlined',
    fullWidth: true,
    size: 'small',
    marginBottom: 20
  },
  multiTextFieldDiv: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}))

const textFieldSingleLine = {
  variant: 'outlined' as const,
  fullWidth: true,
  size: 'small' as const
}

const textFieldMultiLine = {
  size: 'medium' as const,
  rows: 2,
  multiline: true,
  variant: 'outlined' as const,
  fullWidth: true
}

// const languageValues = [
//   { value: 1, label: 'English' },
//   { value: 2, label: 'Spanish' }
// ]

interface EditFormModalProps {
  newForm: ConsentForm
  onHide: () => void
}

const reducer = (state: any, changes: any): any => {
  return { ...state, ...changes }
}

function difference (object: any, base: any): any {
  function changes (object: any, base: any): any {
    return transform(object, function (result, value, key) {
      if (!isEqual(value, base[key])) {
        result[key] = (isObject(value) && isObject(base[key])) ? changes(value, base[key]) : value
      }
    })
  }
  return changes(object, base)
}

export default function EditFormModal (props: EditFormModalProps): JSX.Element {
  const classes = useStyles()
  const { newForm, onHide } = props
  const [state, dispatch] = useReducer(reducer, newForm)
  const isNotNew = !!state.id

  const saveForm = (): void => {
    ConsentForm.save(state).then(() => props.onHide())
  }

  const updateForm = (): void => {
    const changes = Object.keys(difference(state, newForm))
    ConsentForm.update({ ID: state.id, updates: state, changes: changes }).then(() => props.onHide())
  }

  return (
    <Modal
      size='md'
      title={isNotNew ? 'Edit Form' : 'New Form'}
      onHide={onHide}
      open
      handleSave={isNotNew ? updateForm : saveForm}
      saveBtnText={isNotNew ? 'Save Form' : 'Create Form'}
      saveIcon={isNotNew ? 'save' : 'add'}
      saveDisabled={isNotNew ? isEqual(state, newForm) : (!state.consentMessage || !state.optInButtonText || !state.optOutButtonText || !state.optInConfirmationMsg || !state.optOutConfirmationMsg)}
    >
      <ModalSection
        title='Consent Form Name'
        subtitle='This allows for internal reference to the form. It will not be visible to visitors.'
      >
        <TextField
          value={state.name}
          {...textFieldSingleLine}
          placeholder='Primary'
          onChange={(e: any) => dispatch({ name: e.target.value })}
        />
      </ModalSection>
      <ModalSection
        title='Consent Message'
        subtitle='Describe what you&#39;re asking your web visitors to agree to, like asking permission to collect their name and email addresses'
      >
        <TextField
          value={state.consentMessage}
          {...textFieldMultiLine}
          rows={3}
          placeholder='Message description'
          onChange={(e) => dispatch({ consentMessage: e.target.value })}
        />
      </ModalSection>
      <ModalSection
        title='Opt-In Button'
        subtitle='This button gives consent to the terms above'
      >
        <TextField
          value={state.optInButtonText}
          placeholder='Yes, I agree'
          {...textFieldSingleLine}
          onChange={(e) => dispatch({ optInButtonText: e.target.value })}
          className={classes.textField}
        />
        <Typography variant='h2'>
          Opt-In Confirmation Message
        </Typography>
        <TextField
          value={state.optInConfirmationMsg}
          {...textFieldMultiLine}
          placeholder={'Great, thank you! Let\'s get back to our conversation'}
          onChange={(e) => dispatch({ optInConfirmationMsg: e.target.value })}
        />
      </ModalSection>
      <ModalSection
        title='Opt-Out Button'
        subtitle='This button declines consent to the terms above'
      >
        <TextField
          value={state.optOutButtonText}
          placeholder='No, not now'
          {...textFieldSingleLine}
          onChange={(e) => dispatch({ optOutButtonText: e.target.value })}
          className={classes.textField}
        />
        <Typography variant='h2'>
          Opt-Out Confirmation Message
        </Typography>
        <TextField
          value={state.optOutConfirmationMsg}
          {...textFieldMultiLine}
          placeholder='No worries, we understand! You can always check out our terms again later.'
          onChange={(e) => dispatch({ optOutConfirmationMsg: e.target.value })}
        />
      </ModalSection>
      <ModalSection>
        <Typography variant='h2'>
          Privacy Policy Link (optional)
        </Typography>
        <TextField
          value={state.privacyPolicyLink ? state.privacyPolicyLink : ''}
          placeholder='https://company.com/privacypolciy'
          {...textFieldSingleLine}
          onChange={(e) => dispatch({ privacyPolicyLink: e.target.value })}
          className={classes.textField}
        />
        <Typography variant='h2'>
          Privacy Policy Link Text
        </Typography>
        <TextField
          value={state.privacyPolicyLinkText ? state.privacyPolicyLinkText : ''}
          placeholder='View Privacy Policy'
          {...textFieldSingleLine}
          onChange={(e) => dispatch({ privacyPolicyLinkText: e.target.value })}
        />
      </ModalSection>
    </Modal>
  )
}
