import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import CustomizedSnackbar from 'components/CustomizedSnackbar'
import MarketoLogo from 'img/marketo_integration_logo.png'
import { INTEGRATIONS, setCredentials } from 'api/integrations'
import { Chip, Switch, Card, CardContent, Typography, Tooltip, Checkbox, FormControlLabel } from '@material-ui/core'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import { Link } from 'react-router-dom'
import IconButton from '@material-ui/core/IconButton'
import Collapse from '@material-ui/core/Collapse'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import SensitiveTextField from 'components/SensitiveTextField'
import clsx from 'clsx'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import ErrorOutlinedIcon from '@material-ui/icons/ErrorOutlined'
import DelayDropDown from './DelayDropDown'

import {
  getMarketo,
  updateMarketo,
  getMarketoFields,
  isAuthenticated
} from 'api/marketo'
import IntegrationMapping from './IntegrationMapping'
import { setCRMContactIntegrationMapping } from 'api/crm_contacts'

const useStyles = makeStyles((theme) => ({
  headerImage: {
    height: 65,
    marginBottom: 30
  },
  disabledHeaderImage: {
    height: 65,
    marginBottom: 30,
    filter: 'grayscale(100%)'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  manualToggle: {
    marginRight: 40,
    marginTop: 20
  },
  wrapIcon: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 15
  },
  textField: {
    cursor: 'text',
    width: '100%',
    marginBottom: 25,
    marginRight: 10
  },
  marketoContainer: {
    padding: 65,
    minWidth: 920
  },
  bodyText: {
    marginBottom: 25,
    fontSize: 16
  },
  subtitle1: {
    fontSize: 18,
    fontWeight: 600,
    marginBottom: 15
  },
  subtitle2: {
    fontSize: 15,
    fontWeight: 600,
    marginBottom: 10
  },
  groupContainer: {
    backgroundColor: 'rgba(193, 166, 237, 0.1)',
    padding: 30,
    borderRadius: 10,
    marginBottom: 20
  },
  apiKeyField: {
    display: 'flex'
  },
  visibilityButton: {
    height: 56,
    marginTop: -1
  },
  expandingCard: {
    backgroundColor: 'rgba(193, 166, 237, 0.1)',
    borderRadius: 10,
    marginBottom: 20
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  },
  cardHeader: {
    widht: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: 10
  },
  headerTitle: {
    paddingLeft: 20,
    fontSize: 24,
    fontWeight: 'bold',
    paddingTop: 10
  },
  connectedIcon: {
    color: 'rgba(0, 135, 7, 0.7)'
  },
  disconnectedIcon: {
    color: 'rgba(255, 188, 3)'
  },
  checkboxes: {
    display: 'block'
  },
  crumb: {
    fontSize: '.8em'
  },
  typography: {
    fontSize: '1.05em'
  },
  breadcrumbs: {
    position: 'absolute',
    top: 5
  },
  crumbWrapper: {
    padding: 10
  },
  warningMessage: {
    color: '#ff0000'
  }
}))

const initialValues = {
  type: 'integrations',
  attributes: {
    integration_settings: {
      integration_name: INTEGRATIONS.Marketo,
      enabled: false,
      config: { authenticated: false },
      mappings: {
        mapping: [
          {
            type: 'cf_field',
            data_type: 'str',
            overwrite: false,
            object_type: 'lead',
            external_field: 'firstName',
            internal_field: 'first_name'
          },
          {
            type: 'cf_field',
            data_type: 'str',
            overwrite: false,
            object_type: 'lead',
            external_field: 'lastName',
            internal_field: 'last_name'
          },
          {
            type: 'cf_field',
            data_type: 'str',
            overwrite: false,
            object_type: 'lead',
            external_field: 'phone',
            internal_field: 'phone'
          },
          {
            type: 'cf_field',
            data_type: 'str',
            overwrite: false,
            object_type: 'lead',
            external_field: 'email',
            internal_field: 'email'
          }
        ]
      },
      event_settings: {
        conversation: {
          enabled: false
        },
        meeting_booked: {
          enabled: false
        },
        tag: {
          enabled: false
        },
        lead: {
          enabled: false
        }
      }
    },
    external_keys: {
      rest_endpoint: '',
      rest_identity: '',
      client_id: '',
      authorized_user: '',
      client_secret: ''
    }
  }
}

const allEventSettings = [
  { key: 'meeting_booked', name: 'Meetings Scheduled' },
  { key: 'lead', name: 'Leads' },
  {
    key: 'conversation',
    name: 'Conversations'
  },
  {
    key: 'tag',
    name: 'Tags'
  }
]

const MarketoIntegrationPage = (props) => {
  const classes = useStyles()

  const [snackState, setSnackState] = useState({
    open: false,
    variant: 'success',
    message: 'Your changes have been saved'
  })
  const [integrationSettings, setIntegrationSettings] = useState(initialValues)
  const [externalMappingFields, setExternalMappingFields] = useState()
  const [expanded, setExpanded] = useState(false)

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  const save = () => {
    const integration = integrationSettings

    isAuthenticated({ integration }).then((response) => {
      if (response.ok) {
        integration.attributes.integration_settings.config.authenticated = true
        updateMarketo({ integration }).then((response) => {
          setIntegrationSettings(response.data)
          setExpanded(true)
        })
        setCRMContactIntegrationMapping(INTEGRATIONS.Marketo, integration.attributes.integration_settings.mappings.mapping)
      } else {
        integration.attributes.integration_settings.config.authenticated = false
        setCredentials(INTEGRATIONS.Marketo, [{
          attributes: {
            name: 'client_id',
            value: integration.attributes.external_keys.client_id,
            secret: false
          }
        },
        {
          attributes: {
            name: 'client_secret',
            value: integration.attributes.external_keys.client_secret,
            secret: true
          }
        },
        {
          attributes: {
            name: 'rest_identity',
            value: integration.attributes.external_keys.rest_identity,
            secret: false
          }
        },
        {
          attributes: {
            name: 'rest_endpoint',
            value: integration.attributes.external_keys.rest_endpoint,
            secret: false
          }
        },
        {
          attributes: {
            name: 'authorized_user',
            value: integration.attributes.external_keys.authorized_user,
            secret: false
          }
        }])
        updateMarketo({ integration }).then((response) => {
          setIntegrationSettings(response.data)
          setExpanded(false)
        })
      }
    })
  }

  const toggleIntegration = (checked) => {
    integrationSettings.attributes.integration_settings.enabled = checked
    save()
  }

  const handleConfig = ({ type, data }) => {
    setIntegrationSettings({
      ...integrationSettings,
      attributes: {
        ...integrationSettings.attributes,
        integration_settings: {
          ...integrationSettings.attributes.integration_settings,
          config: {
            ...integrationSettings.attributes.integration_settings.config,
            [type]: data
          }
        }
      }
    })
  }

  const handleEventSettings = ({ type, data }) => {
    const newEventSettings = integrationSettings.attributes.integration_settings.event_settings
    newEventSettings[type].enabled = data

    setIntegrationSettings({
      ...integrationSettings,
      attributes: {
        ...integrationSettings.attributes,
        integration_settings: {
          ...integrationSettings.attributes.integration_settings,
          event_settings: newEventSettings
        }
      }
    })
  }

  const handleEdit = ({ type, data }) => {
    if (type === 'mapping') {
      setIntegrationSettings({
        ...integrationSettings,
        attributes: {
          ...integrationSettings.attributes,
          integration_settings: {
            ...integrationSettings.attributes.integration_settings,
            mappings: {
              [type]: data
            }
          }
        }
      })
    } else {
      data = data.trim()
      setIntegrationSettings({
        ...integrationSettings,
        attributes: {
          ...integrationSettings.attributes,
          external_keys: {
            ...integrationSettings.attributes.external_keys,
            [type]: data
          }
        }
      })
    }
  }

  useEffect(() => {
    if (integrationSettings.attributes.integration_settings.config.authenticated) {
      getMarketoFields().then((response) => {
        if (response.ok) {
          setExternalMappingFields(response.data)
        }
      })
    }
  }, [integrationSettings])

  useEffect(() => {
    getMarketo().then((response) => {
      if (response.ok) {
        setIntegrationSettings(response.data)
      } else {
        setExpanded(true)
        updateMarketo({ integration: initialValues }).then((response) => {
          setIntegrationSettings(response.data)
        })
      }
    })
  }, [])

  return (
    <>
      <Breadcrumbs aria-label='breadcrumb' classes={{ li: classes.crumb, ol: classes.crumbWrapper }}>
        <Link style={{ color: '#7A7A7A' }} to='/settings/integrations'>
          Integrations Page
        </Link>
        <Typography color='textPrimary' classes={{ root: classes.typography }}>Marketo Integration Page</Typography>
      </Breadcrumbs>
      <div className={classes.marketoContainer}>
        <div className={classes.header}>
          <img
            alt='Marketo Logo'
            src={MarketoLogo}
            className={
              integrationSettings.attributes.integration_settings.enabled
                ? classes.headerImage
                : classes.disabledHeaderImage
            }
          />
          {integrationSettings ? (
            <div className={classes.manualToggle}>
              <Chip
                label={
                  integrationSettings.attributes.integration_settings.enabled
                    ? 'Integration: On'
                    : 'Integration: Off'
                }
                color={
                  integrationSettings.attributes.integration_settings.enabled
                    ? 'primary'
                    : 'default'
                }
                className={classes.chip}
              />
              <Switch
                checked={
                  integrationSettings.attributes.integration_settings.enabled
                }
                onChange={(e) => toggleIntegration(e.target.checked)}
                name='master_toggle'
                className={classes.switch}
              />
            </div>
          ) : (
            <></>
          )}
        </div>
        <Typography variant='body2' className={classes.bodyText}>
          Integrate your Signals account with Marketo to power your
          business!
        </Typography>
        <Card className={classes.expandingCard}>
          <div onClick={handleExpandClick} className={classes.cardHeader}>
            <div>
              <Typography variant='subtitle1' className={classes.headerTitle}>
                Authentication
              </Typography>
            </div>
            <div>
              {integrationSettings.attributes.integration_settings.config
                .authenticated ? (
                  <Tooltip title='Connected' placement='left'>
                    <IconButton disableRipple>
                      <CheckCircleOutlineIcon
                        fontSize='large'
                        className={classes.connectedIcon}
                      />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip title='Not Connected' placement='left'>
                    <IconButton disableRipple>
                      <ErrorOutlinedIcon
                        fontSize='large'
                        className={classes.disconnectedIcon}
                      />
                    </IconButton>
                  </Tooltip>
                )}
              <IconButton
                className={clsx(classes.expand, {
                  [classes.expandOpen]: expanded
                })}
                aria-expanded={expanded}
                aria-label='show more'
              >
                <ExpandMoreIcon />
              </IconButton>
            </div>
          </div>
          <Collapse in={expanded} timeout='auto' unmountOnExit>
            <CardContent>
              <div className={classes.groupContainer}>
                <Typography variant='subtitle1' className={classes.subtitle1}>
                  Authorization Settings
                </Typography>
                <Typography variant='body2' className={classes.bodyText}>
                  Access all of Marketo's capabilities using your authorization
                  keys
                </Typography>
                <Typography variant='subtitle2' className={classes.subtitle2}>
                  REST Endpoint:
                </Typography>
                <TextField
                  className={classes.textField}
                  color='primary'
                  variant='outlined'
                  value={
                    integrationSettings.attributes.external_keys.rest_endpoint
                  }
                  onChange={(e) => {
                    handleEdit({ type: 'rest_endpoint', data: e.target.value })
                  }}
                />
                <Typography variant='subtitle2' className={classes.subtitle2}>
                  REST Identity:
                </Typography>
                <TextField
                  className={classes.textField}
                  color='primary'
                  variant='outlined'
                  value={
                    integrationSettings.attributes.external_keys.rest_identity
                  }
                  onChange={(e) => {
                    handleEdit({ type: 'rest_identity', data: e.target.value })
                  }}
                />
                <Typography variant='subtitle2' className={classes.subtitle2}>
                  Client ID:
                </Typography>
                <TextField
                  className={classes.textField}
                  color='primary'
                  variant='outlined'
                  value={integrationSettings.attributes.external_keys.client_id}
                  onChange={(e) => {
                    handleEdit({ type: 'client_id', data: e.target.value })
                  }}
                />
                <Typography variant='subtitle2' className={classes.subtitle2}>
                  Authorized User:
                </Typography>
                <Typography variant='body2' className={classes.warningMessage}>
                  Make sure the authorized user is given "Access API" group permissions within Marketo. All admin permissions DO NOT grant
                  API Permissions to a user and will deny Signals from syncing information
                </Typography>
                <TextField
                  className={classes.textField}
                  color='primary'
                  variant='outlined'
                  value={
                    integrationSettings.attributes.external_keys.authorized_user
                  }
                  onChange={(e) => {
                    handleEdit({
                      type: 'authorized_user',
                      data: e.target.value
                    })
                  }}
                />
                <Typography variant='subtitle2' className={classes.subtitle2}>
                  Client Secret:
                </Typography>
                <SensitiveTextField
                  onChange={(e) => {
                    handleEdit({ type: 'client_secret', data: e.target.value })
                  }}
                  value={
                    integrationSettings.attributes.external_keys.client_secret
                  }
                />
              </div>
            </CardContent>
          </Collapse>
        </Card>
        {integrationSettings.attributes.integration_settings.config
          .authenticated ? (
            <>
              <div className={classes.groupContainer}>
                <Typography variant='subtitle1' className={classes.subtitle1}>
                  Synchronization Settings
                </Typography>
                <Typography variant='body2' className={classes.bodyText}>
                  Select the amount of time you want to delay after the conversation closes before synchronizing the data.
                </Typography>
                <Typography variant='subtitle2' className={classes.subtitle2}>
                  Delay time:
                </Typography>
                <DelayDropDown
                  handleEdit={(e) =>
                    handleConfig({ type: 'delay_seconds', data: e.target.value })}
                  value={
                    integrationSettings.attributes.integration_settings.config
                      .delay_seconds || 0
                  }
                />
              </div>
              <div className={classes.groupContainer}>
                <Typography variant='subtitle1' className={classes.subtitle1}>
                  Event Settings
                </Typography>
                <Typography variant='body2' className={classes.bodyText}>
                  Sync the following Signals events. A Marketo task will be
                  created and attached to the lead/contact
                </Typography>
                {allEventSettings.map((eventSetting, index) => (
                  <FormControlLabel
                    key={index}
                    className={classes.checkboxes}
                    control={
                      <Checkbox
                        color='primary'
                        checked={
                          integrationSettings.attributes.integration_settings.event_settings[eventSetting.key]
                            .enabled
                        }
                        onChange={(e) =>
                          handleEventSettings({ type: eventSetting.key, data: e.target.checked })}
                      />
                    }
                    label={eventSetting.name}
                  />
                ))}
              </div>
              <div className={classes.groupContainer}>
                <IntegrationMapping
                  currentMapping={
                    integrationSettings.attributes.integration_settings.mappings
                      .mapping
                  }
                  forObject='people'
                  integrationSettings={integrationSettings}
                  externalMappingFields={externalMappingFields}
                  integrationName='Marketo'
                  handleEdit={(data) =>
                    handleEdit({ type: 'mapping', data: data })}
                />
              </div>
            </>
          ) : (
            <></>
          )}
        <Button
          className={classes.actionButton}
          color='primary'
          variant='contained'
          onClick={() => {
            save()
            setSnackState({
              open: true,
              variant: 'success',
              message: 'Your changes have been saved'
            })
          }}
        >
          Save
        </Button>
      </div>
      <CustomizedSnackbar state={snackState} handler={setSnackState} />
    </>
  )
}

export default MarketoIntegrationPage
