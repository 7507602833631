import * as am4core from '@amcharts/amcharts4/core'
import * as am4maps from '@amcharts/amcharts4/maps'
import am4geodata_worldLow from '@amcharts/amcharts4-geodata/worldLow'
import am4geodata_data_countries2 from '@amcharts/amcharts4-geodata/data/countries2'

function createGeoChart ({ chartData, id, setVisible }) {
  am4core.addLicense('MP330981081')

  if (!document.getElementById(id)) {
    return
  }

  const chart = am4core.create(id, am4maps.MapChart)
  chart.projection = new am4maps.projections.Miller()

  // Create map polygon series for world map
  const worldSeries = chart.series.push(new am4maps.MapPolygonSeries())
  worldSeries.useGeodata = true
  worldSeries.geodata = am4geodata_worldLow
  worldSeries.exclude = ['AQ']

  const worldPolygon = worldSeries.mapPolygons.template
  worldPolygon.tooltipText = '{name} \n Conversations: {value} \n Emails Captured: {emails} \n Meetings Booked: {meetings}'
  worldPolygon.nonScalingStroke = true
  worldPolygon.strokeOpacity = 0.5
  worldPolygon.fill = am4core.color('#eee')

  worldSeries.heatRules.push({
    property: 'fill',
    target: worldPolygon,
    min: chart.colors.getIndex(1).brighten(1),
    max: chart.colors.getIndex(1).brighten(-0.3)
  })

  const hs = worldPolygon.states.create('hover')
  hs.properties.fill = chart.colors.getIndex(9)

  // Create country specific series (but hide it for now)
  const countrySeries = chart.series.push(new am4maps.MapPolygonSeries())
  countrySeries.useGeodata = true
  countrySeries.hide()
  countrySeries.geodataSource.events.on('done', function (ev) {
    worldSeries.hide()
    countrySeries.show()
  })

  countrySeries.heatRules.push({
    property: 'fill',
    target: countrySeries.mapPolygons.template,
    min: chart.colors.getIndex(1).brighten(1),
    max: chart.colors.getIndex(1).brighten(-0.3)
  })

  const countryPolygon = countrySeries.mapPolygons.template
  countryPolygon.tooltipText = '{name} \n Conversations: {value} \n Emails Captured: {emails} \n Meetings Booked: {meetings}'
  countryPolygon.nonScalingStroke = true
  countryPolygon.strokeOpacity = 0.5
  countryPolygon.fill = am4core.color('#eee')

  // Set up click events
  worldPolygon.events.on('hit', function (ev) {
    const country = ev.target.dataItem.dataContext.id
    const regionData = chartData[country]?.regions
    ev.target.series.chart.zoomToMapObject(ev.target)
    const map = ev.target.dataItem.dataContext.map
    if (map) {
      ev.target.isHover = false
      countrySeries.geodataSource.url = 'https://www.amcharts.com/lib/4/geodata/json/' + map + '.json'
      countrySeries.geodataSource.load()
      countrySeries.geodataSource.events.on('done', function (ev) {
        const newData = []

        for (const region of countrySeries.geodata.features) {
          const id = region.id
          let conversations = 0
          let emails = 0
          let meetings = 0
          if (regionData) {
            conversations = regionData[id]?.conversations || 0
            emails = regionData[id]?.emails_captured || 0
            meetings = regionData[id]?.meetings_booked || 0
          }
          newData.push({
            id: region.id,
            value: conversations,
            emails: emails,
            meetings: meetings
          })
        }
        countrySeries.data = newData
      })
    }
  })

  // Set up data for countries
  const data = []
  for (const cid in am4geodata_data_countries2) {
    if (Object.prototype.hasOwnProperty.call(am4geodata_data_countries2, cid)) {
      const country = am4geodata_data_countries2[cid]
      const conversations = chartData[cid]?.conversations || 0
      const emails = chartData[cid]?.emails_captured || 0
      const meetings = chartData[cid]?.meetings_booked || 0
      const map = country.maps.length ? country.maps[0] : null
      data.push({
        id: cid,
        map: map,
        value: conversations,
        emails: emails,
        meetings: meetings
      })
    }
  }
  worldSeries.data = data

  // Zoom control
  chart.zoomControl = new am4maps.ZoomControl()

  const homeButton = new am4core.Button()
  homeButton.events.on('hit', function () {
    worldSeries.show()
    countrySeries.hide()
    chart.goHome()
  })

  homeButton.icon = new am4core.Sprite()
  homeButton.padding(7, 5, 7, 5)
  homeButton.width = 30
  homeButton.icon.path = 'M16,8 L14,8 L14,16 L10,16 L10,10 L6,10 L6,16 L2,16 L2,8 L0,8 L8,0 L16,8 Z M16,8'
  homeButton.marginBottom = 10
  homeButton.parent = chart.zoomControl
  homeButton.insertBefore(chart.zoomControl.plusButton)

  chart.events.on('ready', () => {
    setVisible('visible')
  })

  return chart
}

export { createGeoChart }
