import { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Popper from '@material-ui/core/Popper'
import { FlowBuilderContext } from '../FlowBuilderProvider'
import { Button } from 'cf-components/material-wrappers/MaterialComponents'
import { Text } from 'cf-components/material-wrappers/TextComponent'
import { Node } from 'jsplumbtoolkit'

const useStyles = makeStyles(theme => ({
  popper: {
    overflow: 'visible',
    marginTop: 20,
    backgroundColor: 'white',
    boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
    padding: 8,
    borderRadius: 3,
    width: 250
  },
  buttons: {
    display: 'grid',
    gridGap: 10,
    gridTemplateColumns: '1fr 1fr'
  },
  textSection: {
    marginBottom: 10
  }
}))

function ProblemNode (props: any): JSX.Element {
  const { surface, nodeMapping } = useContext(FlowBuilderContext)

  const node = props.node
  const kind = node.data.kind
  const title = nodeMapping[kind].title

  return (
    <li onClick={() => surface?.centerOn(node)}>
      <Text variant='default' text={title} cursor='pointer' />
    </li>
  )
}

type ProblemType = 'unconnected' | 'invalid'

const getText = (type: ProblemType, length: number): string => {
  if (type === 'unconnected') {
    if (length === 1) {
      return '1 unconnected skill'
    } else {
      return length + ' unconnected skills'
    }
  } else if (type === 'invalid') {
    if (length === 1) {
      return '1 skill with invalid settings'
    } else {
      return length + ' skills with invalid settings'
    }
  }
  return ''
}

type NodeListProps = {
  type: ProblemType
  problemNodes: Node[]
}

function NodeList (props: NodeListProps): JSX.Element {
  const classes = useStyles()
  const problemNodes = props.problemNodes
  const length = problemNodes.length
  const color = props.type === 'unconnected' ? 'error' : 'warning'

  if (length) {
    return (
      <div className={classes.textSection}>
        <Text variant='default' text={getText(props.type, length)} color={color} />
        <ul style={{ margin: 3 }}>
          {problemNodes.map((node: Node, index: number) => (
            <ProblemNode node={node} key={index} />
        ))}
        </ul>
      </div>
    )
  }
  return <></>
}

const getExlanationText = (saveDisabled: boolean, publish: boolean): string => {
  if (saveDisabled) {
    return 'Fix the following issues to ensure that your play behaves as expected:'
  } else {
    const baseText = 'All issues have been fixed. Click below to save'
    const text = publish ? baseText + ' and publish.' : baseText + '.'
    return text
  }
}

export function ControlButtons (props: any): JSX.Element {
  const classes = useStyles()
  const state = props.state
  const label = props.saveDisabled ? 'Save Anyway' : 'Save'
  if (state.publish && props.saveDisabled) {
    return (
      <div className={classes.buttons} style={{ gridTemplateColumns: '1fr' }}>
        <Button
          label='cancel'
          variant='text'
          onClick={props.hideMenu}
          size='small'
        />
      </div>
    )
  } else {
    return (
      <div className={classes.buttons}>
        <Button
          label={label}
          onClick={props.save}
          variant='contained'
          size='small'
        />
        <Button
          label='cancel'
          variant='text'
          onClick={props.hideMenu}
          size='small'
        />
      </div>
    )
  }
}
interface ProblemNodesMenuProps {
  state: Record<any, any>
  save: () => void
  hideMenu: () => void
}

export function ProblemNodesMenu ({ state, save, hideMenu }: ProblemNodesMenuProps): JSX.Element {
  const classes = useStyles()
  const saveDisabled = Boolean(state.unconnected.length || state.invalid.length)
  // const showContactDiscoveryInfo = state.contactDiscoveryInfo

  // if (showContactDiscoveryInfo && !saveDisabled) {
  //   return (
  //     <PopUpTip
  //       menuAnchor={state.menuAnchor}
  //       body='To automate outreach to discovered contacts or send them to an integration, connect them to a Send Email skill or an integration skill.'
  //       onClose={hideMenu}
  //       zIndex={state.zIndex}
  //     />
  //   )
  // }

  return (
    <Popper
      open={Boolean(state.menuAnchor)}
      anchorEl={state.menuAnchor}
      style={state.zIndex ? { zIndex: state.zIndex } : {}}
    >
      <div className={classes.popper}>
        <div>
          <div className={classes.textSection}>
            <Text variant='default' text={getExlanationText(saveDisabled, state.publish)} />
          </div>
          <NodeList
            problemNodes={state.unconnected}
            type='unconnected'
          />
          <NodeList
            problemNodes={state.invalid}
            type='invalid'
          />
        </div>
        <ControlButtons
          save={save}
          hideMenu={hideMenu}
          saveDisabled={saveDisabled}
          state={state}
        />
      </div>
    </Popper>
  )
}
