import React, { useEffect, useState, useCallback, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CancelIcon from '@material-ui/icons/Cancel'
import dateFormat from 'dateformat'
import { isWeekend } from 'date-fns'
import { SessionContext } from 'session-context'
import { getSettings } from 'api/settings'
import { getTeams } from 'api/teams'
import { getAgents } from 'api/participants'
import BaseCard from './BaseCard'
import { ScrollBox } from 'cf-components/ScrollBox'

const useStyles = makeStyles(theme => ({
  cardCtn: {
    maxHeight: 240
  },
  section: {
    marginBottom: 10
  },
  icon: {
    position: 'relative',
    top: '.2em',
    fontSize: '1.2em',
    marginLeft: 4,
    '&.check': { color: '#61CCBC' },
    '&.cancel': { color: '#E66D6F' }
  },
  sectionTitle: {
    color: '#985CE6',
    fontWeight: 600
  },
  toggleScope: {
    color: '#585858',
    fontSize: 10,
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 4,
    '& > div': {
      cursor: 'pointer',
      width: 'max-content'
    }
  }
}))

/**
 * @typedef {object} TeamOrAgentAvailability
 * @property {boolean} online
 * @property {string} displayText
 */
/**
 * @typedef {object} Availability
 * @property {boolean} company
 * @property {{ [id: string]: TeamOrAgentAvailability }} teams
 * @property {{ [id: string]: TeamOrAgentAvailability }} agents
 */

/** @type {Availability} */
const defaultAvailability = {
  company: undefined,
  teams: {},
  agents: {}
}

/**
 * @typedef {'all'|'minimized'} Scope
 * Set to 'minimized' to see only available agents and only your teams
 *
 * Set to 'all' to see all (available and unavailable) agents
 * and all (yours and other) teams
 */

/** @type {Scope} */
const defaultScope = 'minimized'

export default function AvailabilityCard (props) {
  const classes = useStyles()
  const { user } = useContext(SessionContext)
  const chatServiceUrl = window.chatServiceUrl
  const [scope, setScope] = useState(defaultScope)
  const [companyAvailability, setCompanyAvailability] = useState(defaultAvailability.company)
  const [teamsAvailability, setTeamsAvailability] = useState(defaultAvailability.teams)
  const [agentsAvailability, setAgentsAvailability] = useState(defaultAvailability.agents)
  const [visibleAgents, setVisibleAgents] = useState([])

  const toggleScope = () => {
    const newScope = scope === 'all' ? 'minimized' : 'all'
    setScope(newScope)
  }

  const getTeamsAvailability = useCallback(() => getTeams({ chatServiceUrl }).then(res => {
    if (res.data) {
      const newTeamsAvailability = res.data.reduce((acc, team) => {
        if (scope === 'minimized') {
          // Ensure the current user is on the team
          const isOnTeam = team.relationships.teammembers.data.some(({ id }) => id === user.id)
          if (!isOnTeam) {
            return acc
          }
        }
        acc[team.id] = {
          displayText: team.attributes.name,
          online: team.attributes.is_currently_available
        }
        return acc
      }, {})
      setTeamsAvailability(newTeamsAvailability)
    }
  }), [chatServiceUrl, scope, user.id])

  const getUsersAvailability = useCallback(() => getAgents({ chatServiceUrl }).then(res => {
    if (res.data) {
      /** Omit current user from list */
      const newAgentsAvailability = res.data.reduce((acc, agent) => {
        if (!agent.attributes.agent_name) {
          return acc
        }
        if (agent.id === user.id) {
          return acc
        }
        if (agent.attributes.deleted_timestamp) {
          return acc
        }
        acc[agent.id] = {
          displayText: agent.attributes.agent_name,
          online: agent.attributes.status === 'available'
        }
        return acc
      }, {})
      setAgentsAvailability(newAgentsAvailability)
    }
  }), [chatServiceUrl, user.id])

  useEffect(() => {
    getSettings().then(res => {
      if (res.data && res.data.attributes) {
        const attrs = res.data.attributes
        // Different logic for different chat_setting
        if (attrs.chat_setting === 'scheduled') {
          if (!attrs.availabilities || !attrs.availabilities.length) {
            setCompanyAvailability(false)
          } else {
            const inTimezone = new Date(new Date().toLocaleString('en-US', { timeZone: attrs.timezone }))
            const weekDayOrEnd = isWeekend(inTimezone) ? 'Weekends' : 'Weekdays'
            const [dayOfWeek, time] = dateFormat(inTimezone, 'dddd"s"-HH:MM:ss').split('-')
            const isOnline = attrs.availabilities.some(({ days, start_time, end_time }) => {
              if (days === dayOfWeek || days === weekDayOrEnd || days === 'Everyday') {
                return start_time < time && time < end_time
              }
              return false
            })
            setCompanyAvailability(isOnline)
          }
        } else {
          // 'manual' status is default
          setCompanyAvailability(attrs.online)
        }
      }
    })
  }, [])

  useEffect(() => { getTeamsAvailability() }, [getTeamsAvailability])
  useEffect(() => { getUsersAvailability() }, [getUsersAvailability])

  useEffect(() => {
    const newAvailableAgents = Object.entries(agentsAvailability).reduce((acc, [id, data]) => {
      if (data.online) {
        return [id, ...acc]
      }
      if (scope === 'all') {
        return [...acc, id]
      }
      return acc
    }, [])
    setVisibleAgents(newAvailableAgents)
  }, [agentsAvailability, scope])

  return (
    <BaseCard
      title='Availability'
      id={props.id}
    >
      <ScrollBox className={classes.cardCtn + ' no-scroll'}>
        <div className={classes.section}>
          <span className={classes.sectionTitle}>Company</span>
          <AvailabilityIcon available={companyAvailability} />
        </div>
        <div className={classes.section}>
          <div className={classes.sectionTitle}>Your Teams</div>
          {Object.entries(teamsAvailability).map(([id, data]) => (
            <div key={id}>
              <span>{data.displayText}</span>
              <AvailabilityIcon available={data.online} />
            </div>
          ))}
        </div>
        <div className={classes.section}>
          <div className={classes.sectionTitle}>Agents</div>
          {visibleAgents.map(id => {
            const data = agentsAvailability[id]
            return (
              <div key={id}>
                <span>{data.displayText}</span>
                <AvailabilityIcon available={data.online} />
              </div>
            )
          })}
        </div>
      </ScrollBox>
      <div className={classes.toggleScope}>
        <div onClick={toggleScope}>
          {scope === 'all' ? 'Show only relavent teams and agents' : 'Show all teams and agents'}
        </div>
      </div>
    </BaseCard>
  )
}

/**
 * @param {{ available: boolean; }} props
 */
function AvailabilityIcon (props) {
  const className = useStyles().icon

  if (props.available) {
    return <CheckCircleIcon className={className + ' check'} />
  } else {
    return <CancelIcon className={className + ' cancel'} />
  }
}
