import { cleanFlow } from 'pages/bot/flowCleanup'
import { doDelete, doGet, doPatch, doPost } from './api'

function getSequenceFlow ({ sequenceFlowID }) {
  return doGet({
    path: `/sequence_flows/${sequenceFlowID}`,
    useChatURL: true
  }).then(response => {
    const flow = cleanFlow(response.attributes.flow)
    response.attributes.flow = flow
    return response
  })
}

function createSequenceFlow ({ flow, ports, sequenceID, track, title, description }) {
  const path = '/sequence_flows'
  const payload = {
    flow: cleanFlow(flow)
  }

  if (sequenceID) {
    payload.sequence_id = sequenceID
  }
  if (track) {
    payload.track = track
    payload.title = title
    payload.description = description
    payload.ports = ports
  }

  return doPost({ path, data: { attributes: payload }, useChatURL: true })
}

function saveSequenceTemplate ({ flowID, ports, title, description, sequenceID }) {
  let url = '/sequences'
  let body = {
    attributes: {
      description: ''
    }
  }
  if (sequenceID) {
    url += `/${sequenceID}`
    body = {
      attributes: {}
    }
  }

  if (flowID) {
    body.attributes.sequence_flow_id = flowID
  }
  if (ports) {
    body.attributes.ports = ports
  }
  if (description) {
    body.attributes.description = description
  }
  if (title) {
    body.attributes.name = title
  }

  if (sequenceID) {
    return doPatch({ path: url, data: body, useChatURL: true })
  }

  return doPost({ path: url, data: body, useChatURL: true })
}

function getSequenceTemplates () {
  return doGet({
    path: '/sequences',
    useChatURL: true,
    extraHeaders: { 'x-ignore-dependencies': 'True' }
  })
}

function getSequenceTemplate ({ sequenceID }) {
  return doGet({
    path: `/sequences/${sequenceID}`,
    useChatURL: true
  })
}

function deleteSequenceTemplates ({ sequenceIDs }) {
  return doDelete({
    path: '/sequences',
    useChatURL: true,
    data: {
      attributes: {
        sequence_ids: sequenceIDs
      }
    }
  })
}

function getSequenceOverview ({ sequenceID }) {
  return doGet({
    path: `/sequences/${sequenceID}/overview`,
    useChatURL: true
  })
}

function getSequenceReport ({ sequenceID, startDate, endDate, botIDs }) {
  return doPost({
    path: `/sequences/${sequenceID}/report`,
    useChatURL: true,
    data: {
      attributes: {
        start_date: startDate,
        end_date: endDate,
        bot_ids: botIDs
      }
    }
  })
}

function cloneSequence ({ sequenceID }) {
  return doPost({
    path: `/sequences/${sequenceID}:clone`,
    useChatURL: true,
    data: {}
  })
}

function sequenceFlowSearch ({ sequenceID }) {
  const params = new URLSearchParams()
  if (sequenceID) {
    params.set('filter[sequence_id]', sequenceID)
  }

  return doGet({
    path: '/sequence_flows:search?' + params.toString(),
    useChatURL: true
  })
}

export {
  createSequenceFlow,
  getSequenceFlow,
  saveSequenceTemplate,
  getSequenceTemplates,
  getSequenceTemplate,
  deleteSequenceTemplates,
  sequenceFlowSearch,
  cloneSequence,
  getSequenceReport,
  getSequenceOverview
}
