/* eslint-disable @typescript-eslint/naming-convention */
import {
  FilterAccountTypes,
  FilterCRMRecordTypes,
  FilterCurrency,
  FilterEmployees,
  FilterIndustries,
  FilterLocation,
  FilterOpportunityStages,
  FilterOpportunityTypes,
  FilterRevenues,
  FilterTextValue,
  FilterTrueOrFalse
} from 'pages/bot/criteria/FilterProperties'

const accountFilterGroups = [
  { value: 'account_attributes', name: 'Company Attributes' },
  { value: 'crm_attributes', name: 'CRM Account Attributes' }
]

const AccountComps = [
  { value: 'eq', name: 'equals' },
  { value: 'ne', name: 'does not equal' },
  { value: 'contains', name: 'contains' },
  { value: 'does_not_contain', name: 'does not contain' },
  { value: 'is_known', name: 'is known' },
  { value: 'not_known', name: 'is not known' }
]

const accountFilterProps = [
  // Company Values
  { value: 'is_crm_account', name: 'CRM Account', group: 'account_attributes' },
  { value: 'location', name: 'Location', group: 'account_attributes' },
  { value: 'domain', name: 'Domain', group: 'account_attributes' },
  { value: 'industry', name: 'Industry', group: 'account_attributes' },
  { value: 'annual_revenue_range', name: 'Annual Revenue', group: 'account_attributes' },
  { value: 'employee_range', name: 'Number of Employees', group: 'account_attributes' },

  // CRM Values
  { value: 'account_type', name: 'Account Type', group: 'crm_attributes' },
  { value: 'crm_record_type', name: 'Record Type', group: 'crm_attributes' },
  { value: 'is_open_opportunity', name: 'Open opportunity', group: 'crm_attributes' },
  { value: 'opportunity_type', name: 'Opportunity type', group: 'crm_attributes' },
  { value: 'opportunity_stage', name: 'Opportunity stage', group: 'crm_attributes' }
]

const multiSelectComps = [
  { value: 'in', name: 'in' },
  { value: 'not_in', name: 'not in' },
  { value: 'is_known', name: 'is known' },
  { value: 'not_known', name: 'is not known' }
]

const accountFilterComps = {
  name: AccountComps,
  opportunity_stage: multiSelectComps,
  opportunity_type: multiSelectComps,
  account_type: multiSelectComps,
  crm_record_type: multiSelectComps,
  industry: multiSelectComps,
  location: multiSelectComps,
  annual_revenue_range: [{ value: 'in', name: 'in' }],
  annual_revenue: [
    { value: 'gt', name: 'greater than' },
    { value: 'lt', name: 'less than' }
  ],
  employee_range: [{ value: 'in', name: 'in' }],
  number_of_employees: [
    { value: 'gt', name: 'greater than' },
    { value: 'lt', name: 'less than' }
  ],
  address_city: AccountComps,
  domain: AccountComps,
  is_crm_account: [{ value: 'is', name: 'is' }],
  is_open_opportunity: [{ value: 'is', name: 'is' }]
}

const accountFilterValues = {
  name: FilterTextValue,
  is_crm_account: FilterTrueOrFalse,
  account_type: FilterAccountTypes,
  crm_record_type: FilterCRMRecordTypes,
  industry: FilterIndustries,
  opportunity_type: FilterOpportunityTypes,
  opportunity_stage: FilterOpportunityStages,
  annual_revenue_range: FilterRevenues,
  employee_range: FilterEmployees,
  annual_revenue: FilterCurrency,
  address_city: FilterTextValue,
  domain: FilterTextValue,
  location: FilterLocation,
  is_open_opportunity: FilterTrueOrFalse
}

export const accountCustomFilters = {
  filterProps: accountFilterProps,
  filterValues: accountFilterValues,
  filterComps: accountFilterComps,
  filterGroups: accountFilterGroups
}
