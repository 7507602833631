import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { DateRange, DefinedRange } from 'react-date-range'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import { customStaticRanges, getSessionDateRange, startingDateRange, last30DaysRange, setSessionDateRange } from './CustomDateRanges'
import { ControlButtons } from './FilterBar'
import './date-picker-styles.css'

const useStyles = makeStyles(theme => ({
  dateRangePicker: {
    width: 365
  },
  definedRange: {
    marginTop: 60,
    width: 180
  }
}))

function DateRangeMenu (props) {
  const classes = useStyles()
  let startingDate = getSessionDateRange()
  startingDate = { ...startingDate, startDate: new Date(startingDate.startDate), endDate: new Date(startingDate.endDate) }
  const [state, setState] = React.useState([startingDate])

  const handleCancel = () => {
    props.handleBack()
    if (props.dateRange) {
      setState([props.dateRange])
    }
  }

  const handleApply = () => {
    setSessionDateRange(state[0])
    props.setDateRange(state[0])
    if (props.setDateChange) {
      props.setDateChange(true)
    }
    props.handleClose()
  }

  return (
    <div>
      <div style={{ display: 'flex' }}>
        <DefinedRange
          onChange={item => setState([item.selection])}
          ranges={state}
          inputRanges={[]}
          staticRanges={customStaticRanges}
          className={classes.definedRange}
        />
        <DateRange
          editableDateInputs
          onChange={item => setState([item.selection])}
          moveRangeOnFirstSelection={false}
          ranges={state}
          scroll={{ enabled: true }}
          className={classes.dateRangePicker}

        />
      </div>
      <ControlButtons
        handleCancel={handleCancel}
        handleApply={handleApply}
      />
    </div>
  )
}

export { DateRangeMenu, startingDateRange, last30DaysRange }
