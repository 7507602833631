/* eslint-disable @typescript-eslint/naming-convention */
import ModalWarning from 'components/ModalWarning'
import { ModalSection } from 'library/Modal'
import MappingRow, { MappingField } from 'pages/settings/Integrations/MappingRow'
import { CSVFile } from './CSVFile'

type mappingType = { type: string, data_type: 'string' | 'number', value: string, optional?: boolean }

interface CSVHeaderStepProps {
  CSVFile: CSVFile
  setCSVFile: ({ headerMapping }: { headerMapping: any }) => void
  setDisableNextBtn: (bool: boolean) => void
  mapping: Array<mappingType>
}

export default function CSVHeaderStep ({ CSVFile, setCSVFile, setDisableNextBtn, mapping }: CSVHeaderStepProps) {
  const headerMapping = CSVFile.headerMapping
  const keyMapping: Record<number, string> = {}
  Object.keys(headerMapping).forEach((key, index) => { keyMapping[index] = key })
  const headerOptions = CSVFile.headers.map((header: string) => { return { label: header, api_name: header, data_type: header } })
  const noHeaderOptions = CSVFile.headers.length === 0

  const editMapping = ({ field, index, value }: { field: string, index: number, value: string }) => {
    if (field === 'data_type') {
      const newHeaderMapping = Object.assign(headerMapping, { [keyMapping[index]]: { ...headerMapping[keyMapping[index]], value: value } })
      setCSVFile({ headerMapping: newHeaderMapping })
    }
  }

  if (Object.values(headerMapping).every((m) => {
    if (m.required && !m.value) {
      return false
    }
    return true
  })) {
    setDisableNextBtn(false)
  }

  return (
    <ModalSection title='Confirm CSV Fields' subtitle='Map the Signals field to the corresponding header on your CSV.'>
      <div style={{ display: 'grid', alignItems: 'center', gridTemplateColumns: '150px auto 150px' }}>
        <p>Signals Field</p>
        <span />
        <p>CSV Header</p>
      </div>
      {noHeaderOptions
        ? <ModalWarning body='No headers were found within CSV. Make sure your headers start on row 1' />
        : mapping.map((row: any, index: number) => (
          <MappingRow
            key={index}
            index={index}
            row={row}
            deleteMapping={() => undefined}
            editMapping={(value: MappingField) => editMapping({ field: value.field, index: value.index as number, value: value.value as string })}
            externalFields={headerOptions}
            customFields={[]}
            typeMapping={[]}
            mappedGroups={[]}
            disableOverwrite
            customClass
          />
      ))}
    </ModalSection>
  )
}
