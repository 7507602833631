
const headers = {
  'Content-Type': 'application/vnd.api+json'
}

export function getParticipants ({ chatServiceUrl, conversationID }) {
  const url = `https://${chatServiceUrl}/conversations/${conversationID}/relationships/participants`
  return fetch(url, {
    method: 'GET',
    headers: headers
  })
    .then(response => response.json())
}

export const addParticipants = ({ participants, chatServiceUrl, token, conversationID, type }) => {
  const headers = {
    'Content-Type': 'application/vnd.api+json',
    Authorization: `Bearer ${token}`,
    Pragma: 'no-cache'
  }
  const promise = fetch(`https://${chatServiceUrl}/conversations/${conversationID}/relationships/participants`, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify({
      data: participants,
      type: 'add'
    })
  })
    .then(response => response.json())
  return promise
}

export const removeParticipants = ({ participants, chatServiceUrl, token, conversationID }) => {
  const headers = {
    'Content-Type': 'application/vnd.api+json',
    Authorization: `Bearer ${token}`,
    Pragma: 'no-cache'
  }
  const promise = fetch(`https://${chatServiceUrl}/conversations/${conversationID}/relationships/participants`, {
    method: 'DELETE',
    headers: headers,
    body: JSON.stringify({
      data: participants
    })
  })
    .then(response => response.json())
  return promise
}
