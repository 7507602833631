import Card from 'library/materialUI/Card'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { Icon } from 'library/materialUI'

const useStyles = makeStyles((theme) => ({
  subtitle1: {
    fontSize: 18,
    fontWeight: 600,
    marginBottom: 15
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  bodyText: {
    marginBottom: 30,
    fontSize: 16
  },
  lineItem: {
    display: 'flex',
    flexDireciton: 'row',
    marginTop: '10px'
  }
}))

export default function IntegrationInfoCard () {
  const classes = useStyles()

  return (
    <Card
      content={
        <>
          <div className={classes.header}>
            <Typography variant='subtitle1' className={classes.subtitle1}>
              What the Integration Does
            </Typography>
          </div>
          <Typography variant='body2' className={classes.bodyText}>
            Signals' integration with Salesloft allows you to more closely align your marketing and sales teams by enabling you to:
            <div className={classes.lineItem}>
              <div style={{ marginRight: '7px', marginTop: '1px' }}>
                <Icon icon='addContact' size='sm' color='primary' />
              </div>
              <div>
                Automatically add prospected contacts to cadences in PlayMaker
              </div>
            </div>
            <div className={classes.lineItem}>
              <div style={{ marginLeft: '3px', marginRight: '9px', marginTop: '1px' }}>
                <Icon icon='playMakerPurple' size='sm' />
              </div>
              <div>
                Tailor engagement with targeted plays for visitors enrolled in certain cadences
              </div>
            </div>
            <div className={classes.lineItem}>
              <div style={{ marginRight: '7px', marginTop: '3px' }}>
                <Icon icon='message' size='sm' color='primary' />
              </div>
              <div>
                Add people to cadences, create tasks, and see Salesloft activity from agent chat
              </div>
            </div>
          </Typography>
        </>
      }
    />
  )
}
