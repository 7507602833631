import Skeleton from '@material-ui/lab/Skeleton';
import { SkeletonSize } from './SkeletonLoader';

interface SkeletonNumberProps {
  size: SkeletonSize
}

const skeletonNumberMapping = {
  sm: { height: '2em', width: '1em' },
  md: { height: '3em', width: '1.5em' },
  lg: { height: '4em', width: '2em' }
}

export default function SkeletonNumber ({ size } : SkeletonNumberProps) {
  const { height, width } = skeletonNumberMapping[size]
  return (
    <Skeleton variant='text' height={height} width={width} />
  )
}
