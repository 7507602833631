
const descriptionText = 'Use this skill when you want to fire a bot based upon specific visitor activity.'

const Description = (): JSX.Element => {
  return (
    <div>
      <div>
        {descriptionText}
      </div>
      <ul>
        <li>The play flow continues after reaching this skill even if the visitor does not interact with the bot</li>
        <li><a href='#/engagement/chatbots/chatbots' target='_blank'>Bot Roster</a></li>
        <li>The delay setting and scroll percent are evaluated together. Meaning, if a user scrolls to the desired percentage but hasn't met the delay requirement, the bot will not fire and vice versa. </li>
      </ul>
    </div>
  )
}

const bestPracticeText = 'Specificity is important when using this skill. Be sure to fire specific bots in specific instances based upon visitor site interaction.'

const BestPractices = () => {
  return (
    <div>
      <div>
        {bestPracticeText}
      </div>
      <ul>
        <li>Use this skill in conjunction with the Current Webpage skill to fire bots on specific pages</li>
        <li>Use this skill when specific visitors match established criteria</li>
        <ul>
          <li>The visitor matches criteria for a company segment</li>
          <li>The visitor has previously visited the site and taken specific actions</li>
        </ul>
      </ul>
    </div>
  )
}

export const fireBotDocs = {
  helpCenterLink: 'https://help.getsignals.ai/article/n80is3xlj0-fire-bot-node',
  bestPractices: BestPractices,
  description: Description,
  videoLink: 'https://youtu.be/bcZotkwMYHE'
}
