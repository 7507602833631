import ErrorHandler from 'pages/ErrorHandler'
import { ErrorBoundary } from 'react-error-boundary'
import { Route as ReactRoute } from 'react-router-dom'

export type RouteType = {
  exact?: boolean
  path: string
  component: any
}

export function Route (props: any) {
  const route = props.route
  const exact = route.exact === undefined ? true : route.exact
  const Component = route.component
  return (
    <ReactRoute
      exact={exact}
      path={route.path}
      render={(props) => (
        <ErrorBoundary
          key={route.path}
          FallbackComponent={ErrorHandler}
          onReset={window.location.reload}
        >
          <Component {...props} />
        </ErrorBoundary>
      )}
    />
  )
}

type RouterProps = {
  routes: RouteType[]
}

export function Router (props: RouterProps) {
  return (
    <>
      {props.routes.map((route, index) => {
        return (
          <Route route={route} key={index} />
        )
      })}
    </>
  )
}
