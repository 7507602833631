import { Button, Icon } from 'library/materialUI'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '15px 20px'
  }
}))

type FooterProps = {
  step: number
  stepCount: number
  next: (event: Event) => void
  back: () => void
  disabled: boolean
}

function NavButton (props: any) {
  return (
    <Button
      variant='contained'
      color='primary'
      size='small'
      onClick={props.onClick}
      startIcon={props.startIcon}
      endIcon={props.endIcon}
      disabled={props.disabled}
    >
      {props.children}
    </Button>
  )
}

function BackButton (props: FooterProps) {
  if (props.step === 1) {
    return (
      <div />
    )
  } else {
    return (
      <NavButton
        startIcon={<Icon icon='leftArrow' />}
        onClick={props.back}
      >
        Back
      </NavButton>
    )
  }
}

function NextButton (props: FooterProps) {
  const lastStep = props.step === props.stepCount

  if (lastStep) {
    return (
      <NavButton
        onClick={props.next}
        endIcon={<Icon icon='check' />}
        disabled={props.disabled}
      >
        Finish
      </NavButton>
    )
  } else {
    return (
      <NavButton
        onClick={props.next}
        endIcon={<Icon icon='rightArrow' />}
        disabled={props.disabled}
      >
        Next
      </NavButton>
    )
  }
}

export function WizardFooter (props: FooterProps) {
  const classes = useStyles()

  return (
    <div className={classes.footer}>
      <BackButton {...props} />
      <NextButton {...props} />
    </div>
  )
}
