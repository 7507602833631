import { Checkbox as MuiCheckbox, FormControlLabel } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  checkbox: {
    color: '#707070',
    '&.Mui-checked': {
      color: theme.palette.primary.alt
    }
  }
}))

export interface CheckboxProps {
  onChange: any
  checked: boolean
  label?: JSX.Element | string
  className?: string
  preventDefault?: boolean
  disabled?: boolean
  name?: string
}

function CustomCheckbox (props: CheckboxProps): JSX.Element {
  const classes = useStyles()

  return (
    <MuiCheckbox
      checked={props.checked}
      onChange={(event: any) => props.onChange(event.target.checked)}
      onMouseDown={(e) => props.preventDefault ? e.preventDefault() : null}
      className={props.className ? props.className : classes.checkbox}
      disabled={props.disabled}
    />
  )
}

export function LabeledCheckbox (props: CheckboxProps): JSX.Element {
  return (
    <FormControlLabel
      control={
        <CustomCheckbox
          {...props}
        />
      }
      label={props.label}
      className={props.className}
    />
  )
}

export function Checkbox (props: CheckboxProps): JSX.Element {
  if (props.label) {
    return (
      <LabeledCheckbox
        {...props}
      />
    )
  } else {
    return (
      <CustomCheckbox
        {...props}
      />
    )
  }
}

export default LabeledCheckbox
