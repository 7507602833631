import { Icon, Tooltip, Typography } from 'library/materialUI'
import { useContext } from 'react'
import { TableContext } from 'library/table/TableProvider'
import FilterBar from 'library/table/FilterBar'
import { makeStyles } from '@material-ui/core/styles'
import { ScrollBox } from 'cf-components/ScrollBox'
import Persona from 'classes/personas'
import BuyingCommitteeRow from './BuyingCommitteeRow'

const useStyles = makeStyles(theme => ({
  header: {
    display: 'grid',
    gridTemplateColumns: '80px 1fr 1fr 1fr 1fr 50px',
    marginTop: '20px',
    paddingBottom: '20px',
    alignItems: 'top',
    fontSize: '12px',
    borderBottomWidth: '1px',
    borderColor: '#CBCBCB',
    borderBottomStyle: 'solid'
  },
  headerItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    textAlign: 'center',
    textTransform: 'uppercase',
    fontSize: '14px',
    fontFamily: 'poppins',
    color: '#00000080',
    cursor: 'pointer'
  }
}))

export default function BuyingCommitteeList () {
  const classes = useStyles()
  const { rows, handleSort, searchParams } = useContext(TableContext)
  const columnHeaders = [
    { label: 'PERSONA NAME', column: 'name', tooltip: '' },
    { label: 'CONTACTS GATHERED', column: 'tenantID', tooltip: '' },
    { label: 'COMPANIES PROSPECTED', column: 'tenantID', tooltip: '' }
  ]

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex', flexDirection: 'row', borderBottomWidth: '1px', borderColor: '#CBCBCB', borderBottomStyle: 'solid' }}>
        <div style={{ marginLeft: '20px', marginTop: '20px', marginBottom: '20px', color: '#00000080' }}>
          <Typography>
            Showing {rows?.length || 0} of {rows?.length || 0}
          </Typography>
        </div>
        <div style={{ marginLeft: 'auto' }}>
          <div style={{ padding: '10px 20px' }}>
            <FilterBar />
          </div>
        </div>
      </div>
      <div className={classes.header}>
        <div className={classes.headerItem} />
        {columnHeaders.map((item, index) => {
          return (
            <Tooltip key={index} title={item.tooltip}>
              <div onClick={() => handleSort(item.column)} className={classes.headerItem}>
                {item.label} {searchParams.sortColumn === item.column && <div style={{ marginTop: '2px', marginLeft: '5px' }}><Icon icon={searchParams.sortOrder === 'desc' ? 'downArrow' : 'upArrow'} size='sm' /></div>}
              </div>
            </Tooltip>
          )
        })}
        <div className={classes.headerItem} />
      </div>
      <ScrollBox>
        {rows && rows.map((item: Persona, index: number) => {
          return (
            <div key={index}>
              <BuyingCommitteeRow row={item} />
            </div>
          )
        })}
      </ScrollBox>
    </div>
  )
}
