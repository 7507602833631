
const descriptionText = 'Ask the visitor a question in order to direct the bot flow. The following options are available when configuring this node:'

const QuestionDescription = (): JSX.Element => {
  return (
    <div>
      <div>
        {descriptionText}
      </div>
      <ul>
        <li>Buttons</li>
        <li>
          <div>Keywords</div>
          <ul>
            <li><a href='#/settings/keyword_groups' target='_blank'>Configure Keywords</a></li>
          </ul>
        </li>
        <li>Free Response</li>
      </ul>
    </div>
  )
}

const bestPracticeText = 'Buttons or Keywords are recommended in most instances.  Free Response is only recommended at or near the end of a bot flow in instances where an agent is also being routed into the conversation, either for live chat or to review and contact the visitor at a later date.'

const QuestionBestPractices = () => {
  return (
    <div>
      <div>
        {bestPracticeText}
      </div>
      <ul>
        <li>Use buttons to direct visitors along specific bot flows</li>
        <li>Use keywords when a specific response is expected</li>
        <li>Use free response sparingly</li>
      </ul>
    </div>
  )
}

export const QuestionDocs = {
  helpCenterLink: 'https://help.getsignals.ai/article/ag73xt55t1-question-node',
  bestPractices: QuestionBestPractices,
  description: QuestionDescription,
  videoLink: ''
}
