
const descriptionText = 'The Send Email Node can be used to send an email to the web visitors who are interacting with the bot. Please note that you must configure your email settings before using this feature.'

const Description = (): JSX.Element => {
  return (
    <div>
      <div>
        {descriptionText}
      </div>
      <ul>
        <li><a href='#/settings/email_settings' target='_blank'>Configure settings</a></li>
        <li><a href='#/engagement/emails/emails' target='_blank'>Create email templates</a></li>
      </ul>
    </div>
  )
}

const bestPracticeText = 'It is important to know what to do whether or not the email of the visitor is known:'

const BestPractices = () => {
  return (
    <div>
      <div>
        {bestPracticeText}
      </div>
      <ul>
        <li>If the email is known, the email will send and you can continue your bot flow.</li>
        <li>If the email is unknown, the bot will not be able to send the email. If this is the case, it is common practice to connect this Send Email Node to an Email Capture Node, then connect back to this same Send Email Node.</li>
      </ul>
    </div>
  )
}

export const SendEmailDocs = {
  helpCenterLink: 'https://help.getsignals.ai/article/z86hxde573-send-email-node',
  bestPractices: BestPractices,
  description: Description,
  videoLink: 'https://www.youtube.com/watch?v=votK4IpDjjA&list=PL6SHkPxzWDamLhKs_lWXmkwP3W5y9yQlQ&index=4'
}
