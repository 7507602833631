import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Switch from '@material-ui/core/Switch'
import { withStyles } from '@material-ui/styles'
import ScheduleIcon from '@material-ui/icons/Schedule'
import { getOnboardingData, setOnboardingData } from 'api/onboarding'
import { SessionContext } from 'session-context'

const useStyles = makeStyles(theme => ({
  availability: {
    fontFamily: 'Poppins, sans serif',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '19px',
    display: 'flex',
    alignItems: 'center'
  },
  available: {
    minWidth: 80,
    color: '#61CBBC',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  unavailable: {
    color: '#7C7C7C',
    minWidth: 80
  },
  outOfOfficeIcon: {
    color: '#9C9C9C',
    marginLeft: 4,
    display: 'flex',
    alignItems: 'center'
  }
}))

const CustomSwitch = withStyles({
  switchBase: {
    '&$checked': {
      color: '#08c20b'
    },
    '&$checked + $track': {
      backgroundColor: '#08c20b'
    }
  },
  checked: {},
  track: {}
})(Switch)

function AvailabilityControl (props) {
  const classes = useStyles()
  const { changeAvailability } = useContext(SessionContext)

  if (props.participantStatus === 'outOfOffice') {
    return (
      <div className={classes.availability}>
        <div className={classes.unavailable}>Out of Office</div>
        <div className={classes.outOfOfficeIcon}>
          <ScheduleIcon />
        </div>
      </div>
    )
  }

  function handleChangeAvailability (e) {
    if (!e) e = window.event
    e.cancelBubble = true
    if (e.stopPropagation) e.stopPropagation()
    e.preventDefault()

    changeAvailability()
    getOnboardingData('client_onboarding')
    .then(response => {
        if (response) {
          const data = JSON.parse(response.settings)
          data.learn_availability = true
          setOnboardingData('client_onboarding', data)
        }
      }
    )
  }

  return (
    <div className={classes.availability}>
      {
        props.helperText
          ? (props.participantStatus === 'available' ? (
            <div className={classes.available}>Available</div>
          ) : (
            <div className={classes.unavailable}>Unavailable</div>
          ))
          : <></>
      }
      <div
        onClick={(e) => handleChangeAvailability(e)}
        style={{ width: 60 }}
      >
        <CustomSwitch
          checked={props.participantStatus === 'available'}
          value='availability'
          inputProps={{ 'aria-label': 'available to receive incoming chats' }}
        />
      </div>
    </div>
  )
}

export default AvailabilityControl
