/* eslint-disable @typescript-eslint/naming-convention */
import Picker from 'cf-components/Pickers';
import { EmailSubscription, blockableEmptyStateTypes, builtinSubscriptionTypes } from 'classes/emailSubscriptions';
import { TextBox, Typography, Checkbox } from 'library/materialUI';
import { Modal, ModalSection } from 'library/Modal';
import TextFieldWithChips from 'library/TextFieldWithChips';
import { useReducer } from 'react';
import EmailFrequencyModalSection from './EmailFrequencyModalSection';
import * as yup from 'yup'
import { YupRequiredString, defaultErrorMsg } from 'pages/bot/flow-builder/nodes/nodeHelpers';
import { Formik, FormikActions } from 'formik';
import ModalWarning from 'components/ModalWarning';
// import { SessionContext } from 'session-context';

interface EmailSubscriptionModalProps {
  // emailSubscriptionID: number
  emailSubscription: EmailSubscription
  onClose: () => void
}

// const reportOptions = [
//   { value: 'Trending Accounts' },
//   { value: 'Potential Accounts' },
//   { value: 'Recently Seen' },
//   { value: 'Ideal Customers' }
// ]

const EmailSubscriptionSchema = yup.object().shape({
  name: YupRequiredString,
  plays: yup.mixed().when('type', {
    is: (type: string) => type === 'play',
    then: yup.array().min(1, defaultErrorMsg)
  }),
  meta: yup.object().when('type', {
    is: (type: string) => type === 'contacts',
    then: yup.object().shape({ plays: yup.array().required(defaultErrorMsg) }),
    otherwise: yup.object().when('type', {
      is: (type: string) => type === 'filters',
      then: yup.object().shape({
        dashboardFilters: yup.number().required(defaultErrorMsg),
        report: yup.string().required(defaultErrorMsg)
      })
    })
  })
})

const reducer = (state: EmailSubscription, changes: any): EmailSubscription => {
  return { ...state, ...changes }
}

export default function EmailSubscriptionModal ({ emailSubscription, onClose }: EmailSubscriptionModalProps) {
  // const { user } = useContext(SessionContext)
  // const { data, isLoading, isError } = EmailSubscription.loadOne(emailSubscriptionID)
  const [newEmailSubscription, setNewEmailSubscription] = useReducer(reducer, emailSubscription)
  // const [reportLabel, setReportLabel] = useState('')
  async function handleSubmit (action: FormikActions<EmailSubscription>) {
    const valid = await action.validateForm(newEmailSubscription)
    if (valid) {
      await EmailSubscription.save({ emailSubscription: newEmailSubscription })
      onClose()
    }
  }

  function handleAddNonCFEmail (email: string): boolean {
    return (email.includes('@') && email.includes('.'))
  }

  // useEffect(() => {
  //   if (!isLoading && !isError) {
  //     let sub = data
  //     if (!sub?.meta?.report) {
  //       sub = {
  //         ...sub,
  //         meta: {
  //           ...sub.meta,
  //           report: 'Recently Seen'
  //         }
  //       }
  //     }
  //     if (sub.cfRecipients?.length === 0 && user?.id) {
  //       sub = { ...sub, cfRecipients: [user.id] }
  //     }
  //     setNewEmailSubscription({ ...sub })
  //   }
  // }, [isLoading, isError, data]) // eslint-disable-line
  // if (isLoading) {
  //   return <div>Loading...</div>
  // }
  // if (isError || !newEmailSubscription) {
  //   return <div>Error...</div>
  // }
  const updateable = !builtinSubscriptionTypes.includes(newEmailSubscription.type)
  const showEmptyCheckbox = blockableEmptyStateTypes.includes(newEmailSubscription.type)

  return (
    <Formik
      validationSchema={EmailSubscriptionSchema}
      enableReinitialize
      initialValues={newEmailSubscription}
      validateOnChange
      validateOnBlur
      onSubmit={(values, action) => handleSubmit(action)}
    >
      {({ submitForm, errors, touched, setFieldTouched, isSubmitting }) => (
        <Modal
          open={Boolean(newEmailSubscription)}
          onHide={onClose}
          title='Email Update'
          handleSave={() => submitForm()}
          saveIcon='save'
        >
          {updateable ? (
            <ModalSection
              title='Update Name'
              subtitle='Name your email update. This name will show up in the subject line'
            >
              {errors?.name && (touched?.name || isSubmitting) && <p style={{ color: 'red', fontSize: '13px', margin: '0' }}>{errors.name}</p>}
              <TextBox
                onChange={(value: any) => setNewEmailSubscription({ name: value })}
                value={newEmailSubscription.name}
                error={!!errors.name && !!touched.name}
                onBlur={() => setFieldTouched('name', true)}
              />
            </ModalSection>
          ) : (
            <>
              <Typography variant='h1' style={{ marginBottom: 20 }}>{newEmailSubscription.name}</Typography>
              <ModalWarning
                body='This is an auto generated email for Signals users only. You can only edit who receives the email.'
              />
            </>
          )}
          {newEmailSubscription.type === 'score_summary' &&
            <>
              <ModalSection
                title='Accounts'
                subtitle='Which companies should be included in this update?'
              >
                <div>
                  <Checkbox
                    label='Potential Accounts'
                    checked={newEmailSubscription.meta.potential_accounts === 1 || newEmailSubscription.meta.potential_accounts === undefined}
                    onChange={() => {
                      if (newEmailSubscription.meta.potential_accounts === 0) setNewEmailSubscription({ meta: { ...newEmailSubscription.meta, potential_accounts: 1 } })
                      else setNewEmailSubscription({ meta: { ...newEmailSubscription.meta, potential_accounts: 0 } })
                    }}
                    disabled={newEmailSubscription.meta.known_accounts === 0}
                  />
                </div>
                <div>
                  <Checkbox
                    label='Known Accounts'
                    checked={newEmailSubscription.meta.known_accounts === 1 || newEmailSubscription.meta.known_accounts === undefined}
                    onChange={() => {
                      if (newEmailSubscription.meta.known_accounts === 0) setNewEmailSubscription({ meta: { ...newEmailSubscription.meta, known_accounts: 1 } })
                      else setNewEmailSubscription({ meta: { ...newEmailSubscription.meta, known_accounts: 0 } })
                    }}
                    disabled={newEmailSubscription.meta.potential_accounts === 0}
                  />
                </div>
              </ModalSection>
              <ModalSection
                title='Segments'
                subtitle='Which segments would you like to see score change updates from?'
              >
                <Picker
                  objectType='segments'
                  multiple
                  selection={newEmailSubscription.meta.segment_id || []}
                  setSelection={(value: any) => setNewEmailSubscription({ meta: { ...newEmailSubscription.meta, segment_id: value } })}
                  placeholder='All Segments'
                />
              </ModalSection>
              <ModalSection
                title='Score Change'
                subtitle="How much does a company's score need to change in order to show up in your email?"
              >
                <TextBox
                  onChange={(value: number) => setNewEmailSubscription({ meta: { score_shift: value } })}
                  value={newEmailSubscription.meta?.score_shift}
                  type='number'
                  defaultValue={10}
                />
                <Typography variant='body2'>We recommend a change of 10</Typography>
              </ModalSection>
            </>}
          {newEmailSubscription.type === 'contacts' &&
            <ModalSection
              title='Plays'
              subtitle='Which plays would you like to see your new propects from?'
            >
              {errors?.meta && touched.meta && <p style={{ color: 'red', fontSize: '13px', margin: '0' }}>{errors?.meta.plays}</p>}
              <Picker
                multiple
                selection={newEmailSubscription.meta?.plays as number[] || []}
                setSelection={(value: any) => setNewEmailSubscription({ meta: { plays: value } })}
                objectType='activeSequences'
                errors={!!errors.meta && !!touched.meta}
                onBlur={() => setFieldTouched('plays', true)}
                selectAll
              />
            </ModalSection>}
          {newEmailSubscription.type === 'segment_summary' &&
            <ModalSection
              title='Segments'
              subtitle='Which segments would you like to see updates from?'
            >
              {errors?.meta && touched.meta && <p style={{ color: 'red', fontSize: '13px', margin: '0' }}>{errors?.meta.segment_id}</p>}
              <Picker
                selection={newEmailSubscription.meta?.segment_id as number}
                setSelection={(value: any) => setNewEmailSubscription({ meta: { segment_id: value } })}
                objectType='segments'
                errors={!!errors.meta && !!touched.meta}
                onBlur={() => setFieldTouched('segments', true)}
              />
            </ModalSection>}
          {newEmailSubscription.type === 'filters' &&
            <>
              <ModalSection
                title='View'
                subtitle='Which filter view do you want to use?'
              >
                {errors?.meta && touched.meta && <p style={{ color: 'red', fontSize: '13px', margin: '0' }}>{errors?.meta.dashboardFilters}</p>}
                <Picker
                  selection={newEmailSubscription.meta?.dashboardFilters as number}
                  setSelection={(value: any) => setNewEmailSubscription({ meta: { ...newEmailSubscription.meta, dashboardFilters: value } })}
                  objectType='filters'
                />
              </ModalSection>
              {/* No longer applicable */}
              {/* <ModalSection
                title='Report'
                subtitle='Which report do you want to be sent?'
              >
                {errors?.meta && touched.meta && <p style={{ color: 'red', fontSize: '13px', margin: '0' }}>{errors?.meta.report}</p>}
                <Select
                  onChange={(event: any) => { setNewEmailSubscription({ meta: { ...newEmailSubscription.meta, report: event.target.value } }); setReportLabel(event.target.value) }}
                  value={newEmailSubscription.meta?.report || reportLabel}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                >
                  {reportOptions.map((option, index) => (
                    <MenuItem key={index} value={option.value}>
                      {option.value}
                    </MenuItem>
                  ))}
                </Select>
                {/* <Picker
                  selection={newEmailSubscription.meta?.searchParams || []}
                  setSelection={(value: any) => setNewEmailSubscription({ searchParams: [value] })}
                  objectType='searchParams'
                /> */}
              {/* </ModalSection> */}
            </>}
          {updateable &&
            <EmailFrequencyModalSection
              newEmailSubscription={newEmailSubscription}
              setNewEmailSubscription={setNewEmailSubscription}
              showEmptyCheckbox={showEmptyCheckbox}
            />}
          <ModalSection
            title='Recipients'
            subtitle={'Choose who you\'d like to receive this update'}
          >
            <ModalSection
              title='Signals Users'
              subtitle='Choose Signals users to send this email to'
            >
              {errors?.cfRecipients && (touched?.cfRecipients || isSubmitting) && <p style={{ color: 'red', fontSize: '13px', margin: '0' }}>{errors.cfRecipients}</p>}
              <Picker
                placeholder='Select Signals User Recipients'
                objectType='users'
                multiple
                selection={newEmailSubscription.cfRecipients || []}
                setSelection={(value: any) => setNewEmailSubscription({ cfRecipients: value })}
                errors={!!errors.cfRecipients && !!touched.cfRecipients}
                onBlur={() => setFieldTouched('cfRecipients', true)}
                loadAllProps={(newEmailSubscription.type === 'admin_daily_summary' || newEmailSubscription.type === 'client_onboarding') ? { searchParams: { queryParams: { admin: '1' } } } : undefined}
              />
            </ModalSection>
            {updateable &&
              <ModalSection
                title='Non-Signals Users'
                subtitle='Enter the email addresses of any non-Signals users'
              >
                {errors?.nonCfRecipients && touched?.nonCfRecipients && <p style={{ color: 'red', fontSize: '13px', margin: '0' }}>{errors.cfRecipients}</p>}
                <TextFieldWithChips
                  stringArray={newEmailSubscription.nonCfRecipients}
                  setStringArray={(value: string[]) => setNewEmailSubscription({ nonCfRecipients: value })}
                  blurValidation={handleAddNonCFEmail}
                />
              </ModalSection>}
          </ModalSection>
        </Modal>
      )}
    </Formik>
  )
}
