import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import { Modal, ModalSection } from 'library/Modal'
import { Checkbox } from 'library/materialUI'

const useStyles = makeStyles(theme => ({
  subsection: {
    fontSize: '14px',
    marginBottom: 10
  }
}))

function GoalPost (props) {
  if (props.name !== 'goal') {
    return (
      <Checkbox
        checked={props.state.is_goal}
        onChange={() => props.onChange({ ...props.state, is_goal: !props.state.is_goal })}
        label='Use tag in conversation goals'
      />
    )
  }
  return <></>
}

function ConversationTagsModal (props) {
  const classes = useStyles()

  function handleChange (event) {
    const name = event.target.name
    const value = event.target.value
    props.onChange({ ...props.state, [name]: value })
  }

  function save () {
    props.saveTag(
      props.state.tag_name.trim(),
      props.state.description.trim(),
      props.state.is_goal,
      props.state.id
    )
  }

  function focusEnd (e) {
    const content = e.target.value
    e.target.value = ''
    e.target.value = content
  }

  return (
    <Modal
      onHide={props.onHide}
      title={props.state.edit ? 'Edit tag' : 'Create new tag'}
      open={props.show}
      size='sm'
      saveIcon={props.state.edit ? 'save' : 'add'}
      saveBtnText={props.state.edit ? 'Save tag' : 'Create tag'}
      handleSave={save}
      saveDisabled={props.state.tag_name.trim() === ''}
    >
      <ModalSection>
        <div className={classes.subsection}>
          <TextField
            label='Tag Name'
            name='tag_name'
            fullWidth
            margin='dense'
            variant='outlined'
            value={props.state.tag_name}
            onChange={handleChange}
          />
        </div>
        <div className={classes.subsection}>
          <TextField
            label='Tag Description'
            name='description'
            fullWidth
            variant='outlined'
            multiline
            rows='2'
            placeholder='Ex: Use this tag when ...'
            value={props.state.description}
            onChange={handleChange}
            onFocus={focusEnd}
          />
        </div>
        <GoalPost name={props.name} state={props.state} onChange={props.onChange} />
      </ModalSection>
    </Modal>
  )
}

export default ConversationTagsModal
