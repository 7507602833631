
import { doGet, doPost } from './api'

interface SyncContactProps {
  personID?: number
  personIDs?: number[]
}

function syncContactToSalesforce ({ personID, personIDs }: SyncContactProps) {
  const payload: {
    person_id?: number
    person_ids?: number[]
  } = {}
  if (personID) {
    payload.person_id = personID
  } else if (personIDs) {
    payload.person_ids = personIDs
  }
  return doPost({
    path: '/api/integrations/salesforce/sync_contact',
    data: {
      id: personID,
      type: 'salesforce_sync',
      attributes: payload
    }
  }).catch(() => ({
    errors: [{ title: 'An unexpected error occured trying to sync contact' }]
  }))
}

const getSalesforceObjectMeta = (objectName: string) => {
  return doGet({ path: `api/salesforce/describe/${objectName}` })
}

const getSalesforceOppStages = () => {
  return doGet({ path: '/api/integrations/crm/opportunity_stages', extraHeaders: { 'x-integration': 'salesforce' } })
}

export { syncContactToSalesforce, getSalesforceObjectMeta, getSalesforceOppStages }
