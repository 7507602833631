import GenericObject from './genericObject'
import { useDoQuery } from './useDoQuery'

interface HighIntentCategoryList {
  list: HighIntentCategory[]
  dict: { [id: HighIntentCategory['id']]: HighIntentCategory}
}

export class HighIntentCategory extends GenericObject {
  tenantID: number
  name: string
  objectType = 'highIntentCategories'

  static loadAll (): { data: HighIntentCategoryList, isLoading: boolean } {
    return useDoQuery({ path: '/intent_pages/categories', objectClass: HighIntentCategory, useChatURL: true })
  }

  constructor ({ row }: { row: Record<string, any> }) {
    super({ row })
    const category = row.attributes
    this.tenantID = category.tenat_id
    this.name = category.name
  }
}
