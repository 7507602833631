import { doDelete, doGet, doPatch, doPost } from 'api/api'
import GenericObject from './genericObject';
import { useDoQuery } from './useDoQuery';

interface CustomSoundsList {
  list: CustomSounds[]
  dict: { [id: CustomSounds['id']]: CustomSounds}
}

export interface CustomSound {
  name: string
  link_url: string
}

export default class CustomSounds extends GenericObject {
  firstTimeVisitorSound: CustomSound
  returningVisitorSound: CustomSound
  highIntentPageVisitorSound: CustomSound
  visitorIdentifiedSound: CustomSound
  visitorFromEmailSound: CustomSound
  conversationStartedSound: CustomSound
  liveChatStartedSound: CustomSound
  calendarDropSound: CustomSound
  meetingBookedSound: CustomSound

  static loadCustomSounds (): { data: CustomSoundsList, isLoading: boolean } {
    return useDoQuery({
      path: '/custom_sounds',
      useChatURL: true,
      objectClass: CustomSounds
    })
  }

  static getCustomSoundList (): Promise<any> {
    const path = '/custom_sounds/all'
    return doGet({ path: path, useChatURL: true })
  }

  static getSignedURL (): Promise<any> {
    const path = '/custom_sounds/get_url'
    return doGet({ path: path, useChatURL: true })
  }

  static saveNewSound (sound: CustomSound) {
    const path = '/custom_sounds/add'
    const body = {
      attributes: { ...sound }
    }
    doPost({ path, data: body, useChatURL: true })
  }

  static save ({ data } : {data: CustomSounds}) : Promise<any> {
    const path = '/custom_sounds'
    const body = {
      attributes: { ...data }
    }

    if (data?.id) {
      return doPatch({ path, data: body, useChatURL: true })
    } else { return doPost({ path, data: body, useChatURL: true }) }
  }

  static delete (id: string) {
    const path = `/custom_sounds/${id}`
    return doDelete({ path, useChatURL: true })
  }

  constructor ({ row }: { row?: Record<string, any> } = {}) {
    super({ row })
    const sounds = row?.attributes
    this.firstTimeVisitorSound = sounds.first_time_visitor_sound
    this.returningVisitorSound = sounds.returning_visitor_sound
    this.highIntentPageVisitorSound = sounds.high_intent_page_visitor_sound
    this.visitorIdentifiedSound = sounds.visitor_identified_sound
    this.visitorFromEmailSound = sounds.visitor_from_email_sound
    this.conversationStartedSound = sounds.conversation_started_sound
    this.liveChatStartedSound = sounds.live_chat_started_sound
    this.calendarDropSound = sounds.calendar_drop_sound
    this.meetingBookedSound = sounds.meeting_booked_sound
  }
}

export const defaultSounds: CustomSounds = {
  firstTimeVisitorSound: { name: 'Simple Notification 1', link_url: 'simple' },
  returningVisitorSound: { name: 'Decorative Notification 1', link_url: 'decorativeOne' },
  highIntentPageVisitorSound: { name: 'Decorative Notification 2', link_url: 'decorativeTwo' },
  visitorIdentifiedSound: { name: 'Twinkle', link_url: 'twinkle' },
  visitorFromEmailSound: { name: 'Cash Register', link_url: 'register' },
  conversationStartedSound: { name: 'Trumpet Fanfare', link_url: 'trumpet' },
  liveChatStartedSound: { name: 'Video Game Level-up', link_url: 'levelUp' },
  calendarDropSound: { name: 'Game Show Music', link_url: 'gameShow' },
  meetingBookedSound: { name: 'Gong', link_url: 'gong' }
}

export interface CustomSoundAction {
  type: string
  value?: any
}

export const customSoundReducer = (state: CustomSounds, action: CustomSoundAction): CustomSounds => {
  switch (action.type) {
    case 'init':
      return { ...action.value }
    case 'firstTimeVisitor':
      return { ...state, firstTimeVisitorSound: action.value }
    case 'returningVisitor':
      return { ...state, returningVisitorSound: action.value }
    case 'highIntentVisitor':
      return { ...state, highIntentPageVisitorSound: action.value }
    case 'visitorIdentified':
      return { ...state, visitorIdentifiedSound: action.value }
    case 'visitorFromEmail':
      return { ...state, visitorFromEmailSound: action.value }
    case 'conversationStarted':
      return { ...state, conversationStartedSound: action.value }
    case 'liveChatStarted':
      return { ...state, liveChatStartedSound: action.value }
    case 'calendarDrop':
      return { ...state, calendarDropSound: action.value }
    case 'meetingBooked':
      return { ...state, meetingBookedSound: action.value }
  }
  return state
}
