import React, { useState, useEffect, useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography'

import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import CustomizedSnackbar from '../../../components/CustomizedSnackbar'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import { Link } from 'react-router-dom'
import DomoLogo from '../../../img/integrations/domo_logo.png'
import { addIntegration, INTEGRATIONS } from '../../../api/integrations'

const useStyles = makeStyles({
  headerImage: {
    height: 50,
    marginBottom: 50
  },
  wrapIcon: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 15
  },
  textField: {
    cursor: 'text',
    width: '100%',
    marginBottom: 25
  },
  container: {
    margin: 65
  },
  bodyText: {
    marginBottom: 25,
    fontSize: 16
  },
  subtitle1: {
    fontSize: 18,
    fontWeight: 600,
    marginTop: 25,
    marginBottom: 15
  },
  subtitle2: {
    fontSize: 15,
    fontWeight: 600,
    marginBottom: 10
  },
  radioButton: {
    fontWeight: 600
  },
  checkboxGroup: {
    marginLeft: 50,
    marginBottom: 25
  },
  checkbox: {
    display: 'block',
    marginBottom: -10
  },
  actionButton: {
    marginTop: 10,
    paddingLeft: 50,
    paddingRight: 50
  },
  crumb: {
    fontSize: '.8em'
  },
  typography: {
    fontSize: '1.05em'
  },
  breadcrumbs: {
    position: 'absolute',
    top: 5
  },
  crumbWrapper: {
    padding: 10
  }
})

const DOMOIntegrationPage = (props) => {
  const classes = useStyles()
  const [apiKey, setApiKey] = useState(null)

  const [snackState, setSnackState] = useState({
    open: false,
    variant: 'success',
    message: 'Your changes have been saved'
  })

  const getIntegrationSettings = useCallback(() => {
    const headers = { 'Content-Type': 'application/vnd.api+json' }
    fetch('/api/auth/api_keys?filter[label]=DOMO', {
      method: 'GET',
      headers: headers
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.data.length > 0) {
          setApiKey(response.data[0])
        } else {
          setApiKey(false)
        }
      })
  }, [])

  useEffect(() => {
    if (apiKey === null) {
      getIntegrationSettings()
    }
  }, [apiKey, getIntegrationSettings])

  if (apiKey === null) {
    return (
      <></>
    )
  }

  return (
    <>
      <Breadcrumbs aria-label='breadcrumb' classes={{ li: classes.crumb, ol: classes.crumbWrapper }}>
        <Link style={{ color: '#7A7A7A' }} to='/settings/integrations'>
          Integrations Page
        </Link>
        <Typography color='textPrimary' classes={{ root: classes.typography }}>DOMO Integration Page</Typography>
      </Breadcrumbs>
      <div className={classes.container}>
        <img src={DomoLogo} className={classes.headerImage} alt='Domo Logo' />
        <Typography variant='body2' className={classes.bodyText}>
          Integrate your Signals account with DOMO to leverage business intelligence tools.
        </Typography>
        <Divider variant='middle' />

        {apiKey ? (
          <h3>You are already connected to DOMO!</h3>
        ) : (
          <>
            <Typography variant='subtitle1' className={classes.subtitle1}>
              Connect DOMO to Signals
            </Typography>
            <Typography variant='body2' className={classes.bodyText}>
              To start using DOMO with your Signals account, click the button below to connect your accounts. Doing so authorizes Signals and DOMO to connect, allowing transfer of chat conversations, contact data, and other relevant information.
            </Typography>
            <Button
              variant='contained'
              color='primary'
              onClick={() => {
                addIntegration(INTEGRATIONS.Domo)
                window.location = 'https://www.domo.com/appstore/connectors/com.thirdparty.connector.98601c82-7944-4338-8c5a-537fbadf5eeb?origin=search'
              }}
            >Connect with DOMO
            </Button>
          </>
        )}

      </div>
      <CustomizedSnackbar state={snackState} handler={setSnackState} />
    </>
  )
}

export default DOMOIntegrationPage
