/* eslint-disable @typescript-eslint/naming-convention */
import { makeStyles } from '@material-ui/core/styles';
import { CSV } from 'classes/csv';
import { Segment } from 'classes/segments';
import { Icon, Typography } from 'library/materialUI';
import { Modal, ModalSize } from 'library/Modal';
import { useEffect, useReducer, useState } from 'react';
import ConfirmationModal, { ConfirmationModalProps } from '../modals/ConfirmationModal';
import CSVHeaderMappingStep from './CSVHeaderMappingStep';
import CSVReviewStep from './CSVReviewStep';
import CSVUploadStep from './CSVUploadStep';
import CSVConfirmStep from './CSVConfirmStep';

const useStyles = makeStyles(theme => ({
  companyNames: {
    display: 'flex',
    alignItems: 'center ',
    justifyContent: 'space-evenly'
  },
  confimationModalChildren: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    maxHeight: '200px',
    overflowY: 'scroll'
  }
}))

export type CSVStep = 'Upload' | 'Headers' | 'Review' | 'Leave Upload' | 'Confirm' | 'Info'
export type ownerObj = { id: number, name: string }
export type CompanyRow = {
  name: string,
  domain: string,
  fallback: boolean,
  owner: ownerObj,
  previous_owner: ownerObj | null,
  website: string
}
export type CSVFileAttributes = {
  CSVFile: File | string,
  headers: Array<string>,
  row_count: number,
  staged_import_id: number,
  headerMapping: HeaderMapping,
  fallbackUserId: number,
  rows: CompanyRow[],
  overrides: boolean,
  rowsWithConflicts: Record<CompanyRow['name'], conflictRow>
  invalidWebsites: JSX.Element[],
  segmentEdit: string
}

type conflictRow = {
  conflict: boolean,
  owner: ownerObj
}

export type HeaderMapping = {
  name: string,
  website: string,
  accountType?: string,
  accountOwnerEmail?: string
}

const initialState: CSVFileAttributes = {
  CSVFile: '',
  headers: [],
  row_count: 0,
  staged_import_id: 0,
  headerMapping: { name: '', website: '' },
  fallbackUserId: 0,
  rows: [],
  overrides: false,
  rowsWithConflicts: {},
  invalidWebsites: [],
  segmentEdit: 'edit'
}
interface CSVUploadModalProps {
  handleGoBack: () => void
  closeCSVModal: () => void
  open: boolean
  segment: Segment
}

const getSegmentTriggers = (CSVFileAttributes: CSVFileAttributes) => {
  const triggers: Segment['triggers'] = []
  CSVFileAttributes.rows.map(companyRow => {
    triggers.push({ filters: [{ cmp: 'eq', prop: 'name', value: companyRow.name }] })
    return null
  })
  return triggers
}

const reducer = (state: CSVFileAttributes, changes: any) => {
  return { ...state, ...changes }
}

function CSVInfoStep () {
  return (
    <div>
      <Typography variant='body2' style={{ paddingLeft: 24 }}>This could take a few minutes. If your segment doesn’t appear after about 5 minutes, contact Signals support.</Typography>
    </div>
  )
}

export default function CSVUploadModal ({ handleGoBack, closeCSVModal, open, segment }: CSVUploadModalProps) {
  const classes = useStyles()
  const [CSVTraversal, setCSVTraversal] = useState<CSVStep>('Upload')
  const [disableNextBtn, setDisableNextBtn] = useState(true)
  const [CSVFileAttributes, dispatch] = useReducer(reducer, initialState)
  const [allowInvalidWebsites, setAllowInvalidWebsites] = useState(false)
  const [allowAccountConflicts, setAllowAccountConflicts] = useState(false)
  const [confirmationModalProps, setConfirmationModalProps] = useState<ConfirmationModalProps | undefined>(undefined)
  const handleClose = () => {
    setCSVTraversal('Upload')
    setConfirmationModalProps(undefined)
    closeCSVModal()
  }
  async function apiStepMapping (step: CSVStep) {
    let CSVfunction = null
    if (step === 'Headers') {
      CSVfunction = CSV.segmentStage
    }
    if (step === 'Review') {
      CSVfunction = CSV.segmentPreview
    }
    if (typeof CSVfunction === 'function') {
      const { data: { attributes: newCSVAttributes } } = await CSVfunction(CSVFileAttributes)
      if ('rows' in newCSVAttributes) {
        const rowsWithConflicts: Record<CompanyRow['name'], conflictRow> = {}
        const invalidWebsites: JSX.Element[] = []
        newCSVAttributes.rows.map((companyRow: CompanyRow) => {
          if (companyRow.previous_owner) {
            rowsWithConflicts[companyRow.name] = { conflict: true, owner: companyRow.owner }
          }
          if (!companyRow.domain) {
            invalidWebsites.push(<div className={classes.companyNames} key={`${companyRow.name}_invalid`}><Icon icon='report' color='red' />{companyRow.name}</div>)
          }
          return null
        })
        dispatch({ rowsWithConflicts: rowsWithConflicts, invalidWebsites: invalidWebsites })
      }
      dispatch(newCSVAttributes)
    }
  }

  async function handleCSVTraversal (step: CSVStep) {
    apiStepMapping(step)
    if (step !== 'Confirm' && step !== 'Info') {
      setDisableNextBtn(true)
    }
    setCSVTraversal(step)
  }

  const handleOverrideAll = () => {
    setAllowAccountConflicts(true)
    setConfirmationModalProps(undefined)
    const newRWC = Object.create(CSVFileAttributes.rowsWithConflicts)
    const overrideNames: CompanyRow['name'][] = []
    let newRows: CompanyRow[] = []
    for (const conflict in newRWC) {
      newRWC[conflict].conflict = false
      overrideNames.push(conflict) // not all companies will have a domain.
    }
    newRows = CSVFileAttributes.rows.map((companyRow: CompanyRow) => { if (overrideNames.includes(companyRow.name)) { companyRow.owner = CSVFileAttributes.rowsWithConflicts[companyRow.name].owner } return companyRow })
    dispatch({ overrides: true, rowsWithConflicts: newRWC, rows: newRows })
  }

  function handleCreateAnyways () {
    setAllowInvalidWebsites(true)
    setConfirmationModalProps(undefined)
  }

  // done to ensure state is updated before recalling handleSubmit
  useEffect(() => {
    if (CSVFileAttributes.staged_import_id) {
      handleSubmit()
    }
    // eslint-disable-next-line
  }, [allowInvalidWebsites, allowAccountConflicts])

  async function handleSubmit () {
    if (CSVFileAttributes.invalidWebsites.length > 0 && !allowInvalidWebsites) {
      setConfirmationModalProps({
        title: 'Invalid/missing websites',
        subtitle: 'The following companies have invalid or missing websites. Fix any errors and re-upload your CSV, or continue to create your segment without them',
        dynamicButtons: [{ text: 'Create Anyway', action: () => handleCreateAnyways() }, { text: 'Go Back', action: () => setConfirmationModalProps(undefined) }],
        children: <div className={classes.confimationModalChildren}> {CSVFileAttributes.invalidWebsites.map((companyRow: JSX.Element) => { return (companyRow) })}</div>
      })
      return
    }

    let hasConflicts = false
    const rowsWithConflictNames = []
    for (const rwc in CSVFileAttributes.rowsWithConflicts) {
      if (CSVFileAttributes.rowsWithConflicts[rwc].conflict) {
        hasConflicts = true
        rowsWithConflictNames.push(<div className={classes.companyNames}><Icon icon='warning' color='#F4D451' /> {rwc} </div>)
      }
    }
    if (hasConflicts && !allowAccountConflicts) {
      setConfirmationModalProps({
        title: 'Conflicting Account Owners',
        subtitle: 'The following companies have conflicting account owners. Continue to override all with the account owner listed on the CSV or go back and choose which you\'d like to override.',
        dynamicButtons: [{ text: 'OVERRIDE ALL', action: () => handleOverrideAll() }, { text: 'CANCEL', action: () => setConfirmationModalProps(undefined) }],
        children: <div className={classes.confimationModalChildren}> {rowsWithConflictNames.map(companyName => { return (companyName) })} </div>
      })
      return
    }

    CreateSegment({ CSVFileAttributes, segment })
    handleCSVTraversal('Info')
  }

  if (CSVTraversal === 'Leave Upload') {
    handleGoBack()
    return (<></>) // this is done to ensure we always return a JSX.Element and avoid TS error
  }

  const buttonProps = {
    Upload: { nextText: '', nextAction: () => handleCSVTraversal('Headers'), backAction: () => handleCSVTraversal('Leave Upload') },
    Headers: { nextText: '', nextAction: () => handleCSVTraversal('Review'), backAction: () => handleCSVTraversal('Upload') },
    Review: { nextText: segment.id ? '' : 'Create Segment', nextAction: () => segment.id ? handleCSVTraversal('Confirm') : handleSubmit(), backAction: () => handleCSVTraversal('Headers') },
    Confirm: { nextText: 'Create Segment', nextAction: () => handleSubmit(), backAction: () => handleCSVTraversal('Review') },
    Info: { nextText: 'Got it', nextAction: () => handleClose(), backAction: () => handleClose() }
  }

  const { nextText, nextAction, backAction } = buttonProps[CSVTraversal]

  const stepMapping: Record<string, { Component: Function, modalSize?: ModalSize }> = {
    Upload: { Component: CSVUploadStep },
    Headers: { Component: CSVHeaderMappingStep },
    Review: { Component: CSVReviewStep, modalSize: 'md' },
    Confirm: { Component: CSVConfirmStep, modalSize: 'md' },
    Info: { Component: CSVInfoStep }
  }
  const { Component, modalSize } = stepMapping[CSVTraversal]

  const componentToRender = (
    <Component
      setDisableNextBtn={setDisableNextBtn}
      setCSVFileAttributes={dispatch}
      CSVFileAttributes={CSVFileAttributes}
    />
  )

  return (
    <>
      {confirmationModalProps &&
        <ConfirmationModal
          {...confirmationModalProps}
        />}
      <Modal
        open={open}
        size={modalSize}
        onHide={() => handleClose()}
        title={CSVTraversal !== 'Info' ? 'CSV Import' : <Typography variant='h1'>We’re working on building your segment.</Typography>}
        handleSave={nextAction}
        saveBtnText={nextText || 'Next'}
        saveDisabled={disableNextBtn}
        hideCancelButton={CSVTraversal === 'Info'}
        cancelBtnText='Go back'
        closeBtnFunction={backAction}
        key={1 + 'segmentModal'}
        noDividers={CSVTraversal === 'Info'}
        noPadding={CSVTraversal === 'Info'}
      >
        {componentToRender}
      </Modal>
    </>
  )
}

function CreateSegment ({ CSVFileAttributes, segment }: { CSVFileAttributes: CSVFileAttributes, segment: Segment }) {
  const newTriggers = getSegmentTriggers(CSVFileAttributes)
  const newSegment = Object.assign(segment, { triggers: newTriggers, segObjectType: 'domains' })
  const action = segment.id ? CSVFileAttributes.segmentEdit : undefined
  Segment.save(newSegment, action)
    .then((response: { data: { id: number } }) => CSV.segmentFinalize(CSVFileAttributes, response.data.id))
}
