import { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Popover from '@material-ui/core/Popover'
import Checkbox from '@material-ui/core/Checkbox'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Chip from '@material-ui/core/Chip'
import TreeMultiSelect, { useTreeItemStyles, TreeMultiSelectStyle, TreeChild } from 'cf-components/TreeMultiSelect'
import { seniorityMappings } from './buyingCommitteeHelpers'
import { getSeniorities } from 'api/buying_committee'
import { InputAdornment, Tooltip } from '@material-ui/core'
import { Icon } from 'library/materialUI'

const useStyles = makeStyles(theme => ({
  paper: {
    maxWidth: 'calc(100% - 64px)',
    width: 690,
    maxHeight: 420,
    fontSize: '.9em',
    padding: '0 12px 0 4px'
  },
  listItem: {
    width: '100%',
    cursor: 'pointer',
    fontFamily: 'Poppins, sans serif',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.04)'
    }
  },
  disabledOption: {
    color: '#909090',
    cursor: 'initial',
    pointerEvents: 'none',
    height: '2em',
    display: 'flex',
    alignItems: 'center',
    fontSize: '1rem',
    marginLeft: 12
  },
  horizontalLine: {
    width: 'calc(100% - 10px)',
    marginLeft: 10,
    borderBottom: '2px solid #D0D0D0'
  },
  chipSelectInput: {
    paddingTop: 10,
    paddingBottom: 8,
    minHeight: 32
  },
  chip: {
    marginRight: 4
  },
  allSelected: {
    color: 'rgba(0,0,0,0.4)',
    paddingTop: 6
  },
  clearIcon: {
    cursor: 'pointer',
    marginRight: 10
  }
}))

const treeItemStyles: TreeMultiSelectStyle = {
  fontSize: '.9rem'
}

export default function SenioritySelector (props: {
  values: string[]
  setValues: (values: string[]) => void
  className?: string
}): JSX.Element {
  const classes = useStyles()
  const treeItemClasses = useTreeItemStyles(treeItemStyles)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [seniorities, setSeniorities] = useState<TreeChild[]>([])

  useEffect(() => {
    getSeniorities().then((response: any) => {
      setSeniorities(response.data.map((seniority: any) => {
        return { label: seniority.attributes.name, value: seniority.attributes.value }
      }))
    })
  }, [])

  return (
    <>
      <FormControl
        fullWidth
        margin='dense'
        variant='outlined'
      >
        <InputLabel variant='outlined'>Seniority</InputLabel>
        <Select
          label='Seniority'
          value={['seniority']}
          variant='outlined'
          margin='dense'
          multiple
          displayEmpty
          inputProps={{ readOnly: true }}
          classes={{ select: classes.chipSelectInput }}
          onClick={(e) => setAnchorEl(e.currentTarget)}
          endAdornment={
            props.values.length ? (
              <InputAdornment className={classes.clearIcon} position='end'>
                <Tooltip title='Clear'>
                  <div
                    style={{ display: 'flex' }}
                    onClick={(e) => {
                      e.stopPropagation()
                      props.setValues([])
                    }}
                  >
                    <Icon icon='close' specificSize={20} color='#00000080' />
                  </div>
                </Tooltip>
              </InputAdornment>
            ) : <></>
          }
          renderValue={() => props.values.length ? props.values.map(value => (
            <Chip
              color='default'
              className={classes.chip}
              key={value}
              label={seniorityMappings[value]}
              onDelete={() => {
                const newValues = new Set(props.values)
                newValues.delete(value)
                props.setValues([...newValues])
              }}
            />
          )) : (
            <div className={classes.allSelected}>All seniorities</div>
          )}
        />
      </FormControl>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        classes={{ paper: classes.paper }}
      >
        <div>
          <div className={classes.listItem + ' ' + classes.disabledOption}>
            Select seniorities
          </div>
          <div
            className={treeItemClasses.labelRoot + ' ' + classes.listItem}
            onClick={() => props.setValues([])}
          >
            <Checkbox
              color='primary'
              checked={!props.values.length}
            />
            All titles
          </div>
          <div className={classes.horizontalLine} />
          <TreeMultiSelect
            selected={props.values}
            setSelected={props.setValues}
            items={seniorities}
            styles={treeItemStyles}
          />
        </div>
      </Popover>
    </>
  )
}
