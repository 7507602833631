import { makeStyles } from '@material-ui/core/styles';
import ModalWarning from 'components/ModalWarning';
import { Checkbox, Divider, Typography } from 'library/materialUI';
import { ModalSection } from 'library/Modal';
import { useEffect } from 'react';
import CSVCompanyList from './CSVCompanyList';
import { CSVFileAttributes } from './CSVUploadModal';

const useStyles = makeStyles(theme => ({
  rowCount: {
    color: theme.palette.primary.main,
    marginLeft: 10
  },
  center: {
    display: 'flex',
    alignItems: 'center'
  }
}))

interface CSVReviewStepProps {
  CSVFileAttributes: CSVFileAttributes
  setDisableNextBtn: (bool: boolean) => void
  setCSVFileAttributes: (any: any) => void
}

export default function CSVReviewStep ({ CSVFileAttributes, setDisableNextBtn, setCSVFileAttributes }: CSVReviewStepProps) {
  const classes = useStyles()
  const rows = CSVFileAttributes.rows
  const companiesWithConflicts = CSVFileAttributes.rowsWithConflicts
  useEffect(() => {
    if (rows.length === 0 || rows === undefined) {
      setDisableNextBtn(true)
    } else {
      setDisableNextBtn(false)
    }
  }, [rows, setDisableNextBtn])
  const modalTitle = (
    <div className={classes.center}>
      <Typography variant='h1'>Review Import</Typography>,
      <Typography className={classes.rowCount}>{CSVFileAttributes.row_count} Companies</Typography>
    </div>
  )

  const companiesWithIssues = rows.filter(companyRow => companiesWithConflicts[companyRow.name] !== undefined).sort((a, b) => { return (a.name > b.name) ? 1 : -1 })
  const companiesWithOutIssues = rows.filter(companyRow => companiesWithConflicts[companyRow.name] === undefined).sort((a, b) => { return (a.name > b.name) ? 1 : -1 })
  const companiesToDisplay = companiesWithIssues.concat(companiesWithOutIssues)

  return (
    <div style={{ minWidth: 535 }}>
      <ModalSection
        title={modalTitle}
        subtitle='The companies below will be included in your segment.'
      >
        {CSVFileAttributes.overrides &&
          <ModalWarning
            body='The account owners specified in the CSV will override the account owners for current accounts in Signals.'
          />}
        <Checkbox
          onChange={(checked: boolean) => {
            setCSVFileAttributes({ ...CSVFileAttributes, overrides: checked })
          }}
          checked={CSVFileAttributes.overrides}
          label='Override current account owners with owners specified in CSV?'
        />
        <Divider style={{ margin: '10px 0' }} />
        <div style={{ display: 'grid', alignItems: 'center', gridTemplateColumns: '46px 275px 26px 275px 70px' }}>
          <span />
          <Typography>Company Name</Typography>
          <span />
          <Typography>Account Owner</Typography>
          <span />
        </div>
        <CSVCompanyList
          rows={companiesToDisplay}
          rowsLeft={CSVFileAttributes.row_count - 10}
          // setCSVFileAttributes={setCSVFileAttributes}
          overrides={CSVFileAttributes.overrides}
          rowsWithConflicts={CSVFileAttributes.rowsWithConflicts}
        />
      </ModalSection>
    </div>
  )
}
