import React, { useState, useContext } from 'react'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined'
import Typography from '@material-ui/core/Typography'
import { Redirect } from 'react-router-dom'
import { SessionContext } from '../../../session-context'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  button: {
    backgroundColor: theme.palette.primary.light,
    color: 'white',
    width: '80%',
    alignItems: 'center',
    fontSize: '20px',
    borderRadius: 10
  },
  cancelButton: {
    backgroundColor: '#AAAAAA',
    width: '80%',
    color: 'white',
    alignItems: 'center',
    fontSize: '20px',
    borderRadius: 10
  },
  card: {
    width: 600,
    height: 600,
    marginTop: 100,
    marginLeft: 'calc(50% - 300px)',
    marginRight: '50%',
    display: 'flex',
    justifyContent: 'center'
  },
  icon: {
    transform: 'scale(4)'
  },
  cardContent: {
    height: '100%',
    width: '100%',
    position: 'relative'
  },
  iconDiv: {
    display: 'block',
    textAlign: 'center'
  },
  buttonDiv: {
    display: 'block',
    textAlign: 'center',
    marginTop: 20
  },
  bodyText: {
    marginLeft: 50,
    marginRight: 50,
    textAlign: 'center',
    marginTop: 50,
    fontSize: '20px'
  },
  bodyTitle: {
    marginLeft: 50,
    marginRight: 50,
    textAlign: 'center',
    marginTop: 50
  },
  emailText: {
    marginLeft: 50,
    marginRight: 50,
    textAlign: 'center',
    fontWeight: 'bold'
  },
  buttonGroup: {
    position: 'absolute',
    bottom: 50,
    width: '100%'
  }
}))

const ChangeEmailConfirmation = props => {
  const classes = useStyles()
  const { user } = useContext(SessionContext)
  const [emailChangeSuccessful, setEmailChangeSuccessful] = useState(false)

  const new_email = new URLSearchParams(window.location.hash.split('&')[1]).get('new_email')
  const handleConfirm = () => {
    const token = new URLSearchParams(window.location.hash.split('?')[1]).get('token')
    fetch('/api/user_email:finish', {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/vnd.api+json' },
      body: JSON.stringify({
        token: token
      })
    })
      .then(response => response.json())
      .then(response => {
        if (response.ok) {
          setEmailChangeSuccessful(true)
        }
      })
  }

  if (emailChangeSuccessful === true) {
    const redirect = '/settings/my_settings'
    return <Redirect push to={redirect} />
  }

  return (
    <>
      <Paper elevation={3} className={classes.card}>
        <div className={classes.cardContent}>
          <div className={classes.iconDiv}>
            <EmailOutlinedIcon className={classes.icon} />
          </div>
          <Typography variant='h4' className={classes.bodyTitle}>
            Email Change Confirmation
          </Typography>
          <Typography variant='h6' className={classes.bodyText}>
            You are about to change your email from:
          </Typography>
          <Typography variant='h6' className={classes.emailText}>
            {user.attributes.email}
          </Typography>
          <Typography variant='h6' className={classes.emailText}>
            to
          </Typography>
          <Typography variant='h6' className={classes.emailText}>
            {new_email}
          </Typography>
          <Typography variant='h6' className={classes.bodyText}>
            Do you wish to proceed?
          </Typography>
          <div className={classes.buttonGroup}>
            <div className={classes.buttonDiv}>
              <Button onClick={handleConfirm} className={classes.button}>
                Yes, Change Email
              </Button>
            </div>
            <div className={classes.buttonDiv}>
              <Button onClick={() => setEmailChangeSuccessful(true)} className={classes.cancelButton}>
                No, Cancel
              </Button>
            </div>
          </div>
        </div>
      </Paper>
    </>
  )
}

export default ChangeEmailConfirmation
