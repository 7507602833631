import React from 'react'
import SocialMediaLink from './SocialMediaLink'
import { makeStyles } from '@material-ui/core/styles'
import Divider from '@material-ui/core/Divider'

const useStyles = makeStyles(theme => ({
  linksContainer: {
    marginLeft: theme.spacing(5)
  }
}))

function SocialPage (props) {
  const classes = useStyles()
  const disabled = !props.editable

  let twitter = ''
  let linkedin = ''
  let facebook = ''
  let instagram = ''
  let youtube = ''

  if (props.profiles) {
    twitter = props.profiles.twitter || ''
    linkedin = props.profiles.linkedin || ''
    facebook = props.profiles.facebook || ''
    instagram = props.profiles.instagram || ''
    youtube = props.profiles.youtube || ''
  }

  return (
    <div>
      <h3>Social Media Links</h3>
      <Divider />
      <br />
      <div className={classes.linksContainer}>
        <SocialMediaLink
          name='LinkedIn'
          url={linkedin}
          disabled={disabled}
          handler={props.updateSocialLink}
        />
        <SocialMediaLink
          name='Twitter'
          url={twitter}
          disabled={disabled}
          handler={props.updateSocialLink}
        />
        <SocialMediaLink
          name='Facebook'
          url={facebook}
          disabled={disabled}
          handler={props.updateSocialLink}
        />
        <SocialMediaLink
          name='Instagram'
          url={instagram}
          disabled={disabled}
          handler={props.updateSocialLink}
        />
        <SocialMediaLink
          name='YouTube'
          url={youtube}
          disabled={disabled}
          handler={props.updateSocialLink}
        />
      </div>
    </div>
  )
}

export default SocialPage
