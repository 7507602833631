import { ClickAwayListener, Grow, Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { ReactComponent as VideoCallIcon } from 'img/icon-material-video-call.svg'
import { Button, Popper, Tooltip } from 'library/materialUI'
import { useContext, useEffect, useRef, useState } from 'react'
import zoomLogo from 'img/integrations/zoom.png'
import googleLogo from 'img/google-meet-logo.png'
import msTeamsLogo from 'img/integrations/msteams.png'
import gotoMeetingLogo from 'img/integrations/gotomeeting.png'
import { SessionContext } from 'session-context'
import TextField from '@material-ui/core/TextField'
import { MeetingSettings } from 'classes/meetingSettings'

const useStyles = makeStyles(theme => ({
  controlButton: {
    color: '#999999',
    cursor: 'pointer',
    height: '1em',
    '&:hover': {
      fill: '#975ce6'
    }
  },
  colStyle: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'center'
  },
  conferenceLink: {
    margin: 'auto',
    marginBottom: '8px',
    padding: '10px',
    fontSize: '18px',
    fontFamily: 'poppins',
    color: 'white',
    border: 'solid',
    borderWidth: '1px',
    cursor: 'pointer',
    borderColor: 'gray',
    borderRadius: '10px',
    backgroundColor: '#975ce6'
  },
  boldText: {
    fontSize: '16px',
    fontFamily: 'poppins',
    fontWeight: 'bold'
  },
  lightText: {
    fontSize: '12px',
    fontFamily: 'poppins',
    color: 'grey'
  }
}))

function ConferencingButton (props: any) {
  const classes = useStyles()
  const { user } = useContext(SessionContext)
  const userID = user.id
  const { data: meetingInfo, isLoading: settingsLoading } = MeetingSettings.load({ userID: userID })
  const anchorRef = useRef(null)
  const { dropComplete, conferenceOpen, setConferenceOpen } = props
  const [enteredLink, setEnteredLink] = useState('')
  const [meetingLink, setMeetingLink] = useState('')
  const [noMeetingInfo, setNoMeetingInfo] = useState(false)

  useEffect(() => {
    if (!settingsLoading && meetingInfo) {
      setMeetingLink(meetingInfo.meetNowUrl)
    }
  }, [settingsLoading, meetingInfo])

  const saveSettings = () => {
    setMeetingLink(enteredLink)
    const updates: Partial<MeetingSettings> = {
      id: meetingInfo.id,
      meetNowUrl: enteredLink
    }
    return MeetingSettings.save(userID, updates)
  }

  let conferencingProviderAlt = 'Zoom'
  let conferencingProviderImgSrc = zoomLogo
  if (!settingsLoading && meetingInfo) {
    const url = meetingInfo.meetNowUrl
    if (url.includes('teams')) {
      conferencingProviderImgSrc = msTeamsLogo
      conferencingProviderAlt = 'Microsoft Teams'
    } else if (url.includes('gotomeeting')) {
      conferencingProviderImgSrc = gotoMeetingLogo
      conferencingProviderAlt = 'GoToMeeting'
    } else if (url.includes('google')) {
      conferencingProviderImgSrc = googleLogo
      conferencingProviderAlt = 'Google Meet'
    }
  }

  return (
    <div id='conferencing'>
      <Tooltip title='Send Conferencing Link'>
        <VideoCallIcon
          className={classes.controlButton}
          onClick={() => setConferenceOpen(true)}
          ref={anchorRef}
        />
      </Tooltip>
      {conferenceOpen &&
        <Popper
          open={conferenceOpen}
          anchorEl={anchorRef.current}
          transition style={{ zIndex: 10 }}
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: 'center bottom'
              }}
            >
              <Paper id='menu-list-grow'>
                <ClickAwayListener onClickAway={() => setConferenceOpen(false)}>
                  {meetingLink ? (
                    <div className={classes.colStyle} style={{ width: '250px' }}>
                      <div style={{ width: '150px', height: '58px', margin: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img src={conferencingProviderImgSrc} alt={conferencingProviderAlt} style={{ width: '150px' }} />
                      </div>
                      <div className={classes.conferenceLink} onClick={() => dropComplete(meetingLink)}>
                        Send Conferencing Link
                      </div>
                    </div>
                  ) : (
                    <div className={classes.colStyle} style={{ width: '488px', padding: '20px' }}>
                      <div className={classes.boldText} style={{ marginRight: 'auto' }}>
                        Set up your "meet now" link
                      </div>
                      <div className={classes.lightText}>
                        Paste in your static meeting link from Zoom, Teams, etc. below. You can then drop it into the chat to immediately start a meeting with visitors. Learn more <a href='https://help.getsignals.ai/article/nb8udzawsa-meet-now-link' rel='noreferrer' target='_blank'>here</a>
                      </div>
                      <div>
                        <TextField
                          margin='dense'
                          label='Paste URL Here'
                          variant='outlined'
                          value={enteredLink}
                          rows={1}
                          fullWidth
                          onChange={(event) => setEnteredLink(event.target.value)}
                        />
                      </div>
                      <div style={{ marginLeft: 'auto', paddingTop: '10px' }}>
                        {!noMeetingInfo ? (
                          <Button
                            color='primary'
                            variant='contained'
                            onClick={meetingInfo ? () => saveSettings() : () => setNoMeetingInfo(true)}
                            disabled={!enteredLink}
                          >
                            Done
                          </Button>
                        ) : (
                          <div>
                            No calendar info found. Please set up your calendar in <a href='#/settings/my_settings/my_calendar'>your settings page</a> before using this feature
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>}
    </div>
  )
}

export default ConferencingButton
