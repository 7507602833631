import React from 'react'
import Modal from '../../components/Modal'

const EmailChangeMessageModal = (props) => {
  const title = 'Confirmation sent'

  return (
    <Modal
      title={title}
      onHide={props.onHide}
      open={props.open}
      saveButtonText={props.saveButtonText || 'Confirm'}
      onSubmit={props.onHide}
      size='xs'
    >
      A confirmation email was sent to <b>{props.newEmail}</b>. Check your inbox to confirm the change!
    </Modal>
  )
}

export default EmailChangeMessageModal
