
const descriptionText = 'Use this node to automatically present a calendar to a web visitor so they can book a meeting from available times on the linked calendar. Calendars can be dropped for individual agents, groups, or teams.'

const Description = (): JSX.Element => {
  return (
    <div>
      <div>
        {descriptionText}
      </div>
      <ul>
        <li><a href='#/user_management/teams' target='_blank'>Manage Teams</a></li>
        <li><a href='https://help.getsignals.ai/article/hy96c0do9e-bot-builder-and-play-runner-routing' target='_blank' rel='noreferrer'>Routing Information</a></li>
      </ul>
    </div>
  )
}

const bestPracticeText = 'Be explicit when setting up your calendar routing so that all agents in a given group/team can book meetings.'

const BestPractices = () => {
  return (
    <div>
      <div>
        {bestPracticeText}
      </div>
      <ul>
        <li>Use "Round Robin" routing for teams and groups</li>
        <li>Offering the option to receive text message reminders will increase the likelihood of the visitor providing you with their phone number (vs. asking for it earlier in the bot flow)</li>
        <li>Make sure that all agents eligible for routing set up their personal calendars</li>
        <ul>
          <li><a href='#/settings/my_settings/my_calendar' target='_blank'>My Calendar Settings</a></li>
        </ul>
      </ul>
    </div>
  )
}

export const CalendarDropDocs = {
  helpCenterLink: 'https://help.getsignals.ai/article/04w21lgfwe-calendar-invite-node',
  bestPractices: BestPractices,
  description: Description,
  videoLink: ''
}
