import { GenericObject } from './genericObject'
import { useQuery } from 'react-query'
import { LoadAllProps } from './queryHelpers'
import { useDoQuery } from './useDoQuery';
import { doGet } from 'api/api';
import { formatDate } from './classHelpers';

interface UsageLoadAllProps extends LoadAllProps {
  component: string
}
interface UsageList {
  list: Usage[]
  dict: { [id: Usage['id']]: Usage }
}

export class Usage extends GenericObject {
  objectType = 'usage'
  id: number
  tenantName: string
  purchasedThisPeriod: number
  usedThisPeriod: number
  periodStart: string
  periodEnd: string
  usedAllTime: number
  billingFrequency: string
  pricePoint: string

  static loadSummary (component: string) {
    const path = `/admin_portal/usage/${component}/summary`
    return useQuery(
      path, () => {
        return doGet({ path })
      }, {
      staleTime: 60 * 5 // 5 minutes
    }
    )
  }

  static loadAll ({ component, searchParams }: UsageLoadAllProps): { data: UsageList, isLoading: boolean } {
    const path = `/admin_portal/usage/${component}`
    return useDoQuery({
      path,
      useChatURL: false,
      objectClass: Usage,
      initialData: { list: [], dict: {} },
      searchParams
    })
  }
  constructor ({ row }: { row: Record<string, any> }) {
    super({ row })
    const usage = row.attributes
    this.id = usage.id
    this.tenantName = usage.tenant_name
    this.purchasedThisPeriod = usage.purchased_this_period
    this.usedThisPeriod = usage.used_this_period
    this.periodStart = formatDate(usage.period_start)
    this.periodEnd = formatDate(usage.period_end)
    this.usedAllTime = usage.used_all_time
    this.billingFrequency = usage.billing_frequency
    this.pricePoint = usage.price_point
  }
}
