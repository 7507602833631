import { makeStyles } from '@material-ui/core/styles'
import { useContext } from 'react'
import { Link } from 'react-router-dom'
import { IntegrationPageContext } from './IntegrationPageProvider'
import { Tab, Tabs } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  contentBackground: {
    backgroundColor: theme.palette.background.lightPurple,
    minHeight: 'calc(100% - 40px - 179px)' /* height of header and tabs */
  },
  selectedLink: {
    textDecoration: 'none',
    color: theme.palette.primary.main
  },
  unselectedLink: {
    textDecoration: 'none',
    color: 'rgba(0,0,0,0.54)'
  }
}))

export default function Content () {
  const { tabs, selectedTab, setSelectedTab } = useContext(IntegrationPageContext)
  const classes = useStyles()
  const selectedTabContent = tabs[selectedTab].components

  return (
    <>
      <Tabs value={selectedTab} indicatorColor='primary' textColor='primary'>
        {tabs.map((tab, index) => {
          const tabKey = `tab-${index}`
          return (
            <Link key={tabKey} to={tab.url} className={selectedTab === index ? classes.selectedLink : classes.unselectedLink}>
              <Tab label={tab.name} key={index} onClick={() => setSelectedTab(index)} />
            </Link>
          )
        })}
      </Tabs>
      <div className={classes.contentBackground}>
        <div style={{ padding: 20 }}>
          {selectedTabContent.map((comp, index) => {
            return (<div style={{ marginBottom: 20 }} key={index}>{comp}</div>)
          })}
        </div>
      </div>
    </>
  )
}
