const headers = {
  'Content-Type': 'application/vnd.api+json'
}

const updateZoomInfo = ({ integration }) => {
  let method = 'POST'

  if (integration.id) {
    method = 'PATCH'
  }

  return fetch('/api/integrations/zoominfo', {
    method: method,
    headers: headers,
    body: JSON.stringify({ data: integration })
  }).then((response) => response.json())
}

const getZoomInfo = () => {
  return fetch('/api/integrations/zoominfo', {
    method: 'GET',
    headers: headers
  }).then((response) => response.json())
}

export { updateZoomInfo, getZoomInfo }
