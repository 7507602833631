import { makeStyles } from '@material-ui/core/styles'
import Persona from 'classes/personas'
import ActionsMenu from 'cf-components/ActionsMenu'
import { useCallback, useContext, useEffect, useState } from 'react'
import { Icon } from 'library/materialUI'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import EditIcon from '@material-ui/icons/Edit'
import StaticChip from 'library/StaticChip'
import { capitalize } from 'library/helpers'
import StarIcon from '@material-ui/icons/Star'
import { ReactComponent as BadgeIcon } from 'img/badge_icon.svg'
import { ReactComponent as GroupConnectionIcon } from 'img/group_connection_icon.svg'
import BuyingCommitteeModal from './BuyingCommitteeModal'
import { getPersonaCounts } from 'api/buying_committee'
import { ApiContext } from 'api-context'

const useStyles = makeStyles(theme => ({
  row: {
    display: 'grid',
    gridTemplateColumns: '80px 1fr 1fr 1fr 1fr 50px',
    paddingBottom: 10,
    paddingTop: 10,
    alignItems: 'center'
  },
  rowContainer: {
    borderBottomWidth: '1px',
    borderColor: '#CBCBCB',
    borderBottomStyle: 'solid'
  },
  dataItem: {
    display: 'flex',
    justifyContent: 'flex-start',
    fontSize: '15px',
    fontFamily: 'poppins'
  },
  dataItemCentered: {
    display: 'flex',
    justifyContent: 'flex-start',
    fontSize: '15px',
    fontFamily: 'poppins',
    marginLeft: '55px'
  },
  expandedContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  expandedCategory: {
    color: theme.palette.primary.main,
    fontSize: '13px',
    fontFamily: 'poppins',
    marginLeft: '50px',
    marginRight: 'auto',
    maxWidth: '50em',
    marginBottom: '10px'
  },
  categoryName: {
    marginBottom: '4px'
  },
  chipContainer: {
    color: 'black',
    fontSize: '14px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  columnIcon: {
    fill: '#E6D7FA',
    stroke: '#E6D7FA',
    strokeWidth: 0,
    height: '19px',
    marginRight: '5px'
  }
}))

interface BuyingCommitteeRowProps {
  row: Persona
}

export default function BuyingcomitteeRow ({ row }: BuyingCommitteeRowProps) {
  const classes = useStyles()
  const { setDeleteObject } = useContext(ApiContext)
  const [expanded, setExpanded] = useState(false)
  const [buyingCommitteeModalOpen, setBuyingCommitteeModalOpen] = useState(false)
  const [csvModalOpen, setCSVModalOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [contactCount, setContactCount] = useState(undefined)
  const [domainCount, setDomainCount] = useState(undefined)
  const actions = [
    { name: 'Edit Persona', action: () => setBuyingCommitteeModalOpen(true), icon: EditIcon, tooltip: 'Edit existing persona' },
    { name: 'Delete Persona', action: (row: Persona) => setDeleteObject(row), icon: DeleteForeverIcon, tooltip: 'Remove existing persona' }
  ]

  const getCounts = useCallback(() => {
    getPersonaCounts(row.id).then((response) => {
      setContactCount(response.data.attributes.num_contacts)
      setDomainCount(response.data.attributes.num_domains)
      setIsLoading(false)
    })
  }, [row.id])

  useEffect(() => {
    if (isLoading) {
      getCounts()
    }
  }, [getCounts, isLoading])

  return (
    <>
      <div className={classes.rowContainer}>
        <div className={classes.row}>
          <div className={classes.dataItem}>
            <div style={{ marginLeft: '20px', cursor: 'pointer' }} onClick={() => setExpanded(!expanded)}>
              <Icon icon={!expanded ? 'expand' : 'collapse'} size='md' />
            </div>
          </div>
          <div className={classes.dataItem} style={{ cursor: 'pointer' }} onClick={() => setBuyingCommitteeModalOpen(true)}>
            {row.name}
          </div>
          <div className={classes.dataItemCentered}>
            {!isLoading ? contactCount : 'Loading...'}
          </div>
          <div className={classes.dataItemCentered}>
            {!isLoading ? domainCount : 'Loading...'}
          </div>
          <div />
          <div className={classes.dataItem}>
            <ActionsMenu
              actions={actions}
              row={row}
            />
          </div>
        </div>
        {expanded && (
          <div className={classes.expandedContainer}>
            <div className={classes.expandedCategory}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <BadgeIcon className={classes.columnIcon} />
                <div className={classes.categoryName}>
                  Job Titles
                </div>
              </div>
              <div className={classes.chipContainer}>
                {row.titles.map((item, index) => {
                  return (
                    <div key={index}>
                      <StaticChip value={item} />
                    </div>
                  )
                })}
                {row.excludedTitles.map((item, index) => {
                  return (
                    <div key={index}>
                      <StaticChip
                        value={'- ' + item}
                        chipColor='#E9412A40'
                        fontColor='#E9412A'
                      />
                    </div>
                  )
                })}
              </div>
            </div>
            <div className={classes.expandedCategory}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <StarIcon className={classes.columnIcon} />
                <div className={classes.categoryName}>
                  Seniorities
                </div>
              </div>
              <div className={classes.chipContainer}>
                {row.seniorities.map((item, index) => {
                  return (
                    <div key={index}>
                      <StaticChip value={capitalize(item)} />
                    </div>
                  )
                })}
              </div>
            </div>
            <div className={classes.expandedCategory}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <GroupConnectionIcon className={classes.columnIcon} />
                <div className={classes.categoryName}>
                  Departments
                </div>
              </div>
              <div className={classes.chipContainer}>
                {row.departments.map((item, index) => {
                  return (
                    <div key={index}>
                      <StaticChip value={item} />
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        )}
      </div>
      <BuyingCommitteeModal
        open={buyingCommitteeModalOpen}
        persona={row}
        onHide={() => setBuyingCommitteeModalOpen(false)}
        title='Edit Persona'
        csvModalOpen={csvModalOpen}
        setCSVModalOpen={setCSVModalOpen}
      />
    </>
  )
}
