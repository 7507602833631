import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { AvatarWithInfo, getInitials, getColor } from 'cf-components/Avatars'
import { ListItemCard } from 'cf-components/CardList'
import Table from 'cf-components/Table'
import ActionsMenu from 'cf-components/ActionsMenu'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import DeleteIcon from '@material-ui/icons/DeleteForever'
import EditIcon from '@material-ui/icons/Edit'

const useStyles = makeStyles(theme => ({
  tagsCard: {
    display: 'grid',
    gridTemplateColumns: '2fr 2fr 1fr 50px',
    padding: 15,
    gridGap: 10,
    cursor: 'pointer',
    alignItems: 'center'
  },
  headerClass: {
    display: 'grid',
    gridTemplateColumns: '2fr 2fr 1fr 50px',
    padding: '5px 15px',
    gridGap: 10
  }
}))

function ConversationTagsTable (props) {
  const editTag = props.editTag
  const handleDelete = props.handleDelete
  const classes = useStyles()

  const rows = props.rows ? props.rows.map(row => {
    const name = row.attributes.tag_name
    const description = row.attributes.description
    const initials = getInitials(name, 'T')
    return (
      {
        name: name,
        initials: initials,
        description: description,
        id: row.id,
        isGoal: row.attributes.is_goal,
        searchField: name + description
      })
  }) : null

  function TagsCard (props) {
    const color = getColor(props.index)
    const row = props.row
    const edit = () => editTag(row.name, row.description, row.isGoal, row.id)

    const actions = [
      { name: 'Edit Tag', action: edit, icon: EditIcon },
      { name: 'Delete Tag', action: () => handleDelete(row.id), icon: DeleteIcon }
    ]

    return (
      <ListItemCard>
        <div
          className={classes.tagsCard}
          onClick={() => editTag(row.name, row.description, row.isGoal, row.id)}
        >
          <div>
            <AvatarWithInfo
              title={row.name}
              initials={row.initials}
              subtitle={'Tag Id: ' + row.id}
              avatarColor={color}
              variant='rounded'
            />
          </div>
          <div>
            {row.description}
          </div>
          <div>
            {row.isGoal &&
              <div style={{ display: 'flex', alignItems: 'center', marginLeft: 25 }}>
                <CheckCircleIcon style={{ fill: 'rgb(97, 203, 188)' }} /> <div style={{ marginLeft: 10 }}>Yes</div>
              </div>}
          </div>
          <ActionsMenu
            actions={actions}
          />
        </div>
      </ListItemCard>
    )
  }

  const sortValues = [
    { label: 'Tag Name', id: 'name', header: true },
    { label: 'Description', id: 'description', header: true },
    { label: 'Conversation Goal', id: 'isGoal', header: true }
  ]

  return (
    <Table
      rows={rows}
      card={TagsCard}
      sortValues={sortValues}
      header
      headerClass={classes.headerClass}
      startingSortColumn='name'
    />
  )
}

export default ConversationTagsTable
