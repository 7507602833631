import { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Chip, Select, MenuItem, OutlinedInput, Tooltip } from '@material-ui/core'
import { KeywordGroupSelector } from './OptionsComponents'
import GroupWorkIcon from '@material-ui/icons/GroupWork'
import KeyWordGroupsModal from 'pages/settings/KeyWordGroups/KeyWordGroupsModal'
import { RichTextEditor } from 'library/richText/RichTextEditor'
import { ModalSection } from 'library/Modal'

const useStyles = makeStyles(theme => ({
  subsection: {
    marginTop: 15,
    fontSize: '14px'
  },
  divider: {
    marginTop: 5,
    marginBottom: 5
  },
  addButton: {
    padding: 8
  },
  chip: {
    margin: 5,
    cursor: 'pointer'
  },
  sectionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontWeight: 600
  },
  verticalDivider: {
    height: 28
  },
  iconButton: {
    padding: 6
  },
  selectInput: {
    padding: 8,
    paddingLeft: 10,
    borderRadius: 30
  },
  inputRoot: {
    borderRadius: 30
  },
  keywordInput: {
    padding: 6,
    borderRadius: 30
  },
  keywordSelect: {
    minHeight: 32,
    borderRadius: 30
  },
  keywordChip: {
    marginRight: 8,
    marginTop: 4
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: -4
  }
}))

function KeywordGroupsSectionOld (props) {
  const classes = useStyles()
  const [modalOpen, setModalOpen] = useState(false)
  const [modalState, setModalState] = useState({
    name: '',
    description: '',
    id: null,
    comparison: 'contains',
    keywords: [],
    edit: false
  })

  const handleChange = props.handleChange
  const handleInvalidResponse = props.editInvalidResponse
  const handleSecondResponse = props.editSecondResponse

  function editGroup ({ name, description, id, comparison, keywords }) {
    setModalState({
      name: name,
      description: description,
      id: id,
      comparison: comparison,
      keywords: keywords,
      edit: true
    })
    setModalOpen(true)
  }

  function newGroup () {
    setModalState({
      name: '',
      description: '',
      id: null,
      comparison: 'contains',
      keywords: [],
      edit: false
    })
    setModalOpen(true)
  }

  function save () {
    props.saveKeywordGroup()
    setModalOpen(false)
  }

  return (
    <div className={classes.subsection}>
      <ModalSection
        title='Keyword Groups'
      >
        <div className={classes.options}>
          {
            props.keywordOptions.map((option, index) => {
              const group = props.keywordGroupDict[option.keywordGroupID]
              return (
                <Tooltip title={group.keywords.join(', ')} placement='top' key={index}>
                  <Chip
                    label={group.name}
                    onDelete={() => props.handleKeywordDelete(option.keywordGroupID)}
                    color='primary'
                    className={classes.chip}
                    variant='outlined'
                    icon={<GroupWorkIcon />}
                    onClick={() => editGroup({ name: group.name, description: group.description, comparison: group.comparison, keywords: group.keywords, id: option.keywordGroupID })}
                  />
                </Tooltip>
              )
            })
          }
        </div>
        <div style={{ marginTop: 10, marginBottom: 15 }}>
          <KeywordGroupSelector
            handleChange={handleChange}
            keywordGroups={props.keywordGroups}
            keywordOptions={props.keywordOptions}
            createNewGroup={newGroup}
          />
        </div>
        <div className={classes.emailDiv}>
          <div style={{ marginBottom: 10, fontWeight: 600 }}>How many times should the bot prompt for a valid response?</div>
          <Select
            style={{ width: 350 }}
            variant='outlined'
            className={classes.select}
            margin='dense'
            input={<OutlinedInput classes={{ input: classes.selectInput, root: classes.inputRoot }} />}
            value={props.captureTimes}
            onChange={e => props.setCaptureTimes(e.target.value)}
          >
            <MenuItem value={1}>Only one time</MenuItem>
            <MenuItem value={2}>Up to two times</MenuItem>
            <MenuItem value={3}>Up to three times</MenuItem>
          </Select>
          <div style={{ marginBottom: 10, marginTop: 25, fontWeight: 600 }}>What should the bot say when a visitor response doesn't match any keywords?</div>
          <div style={{ height: 85 }} className='emailResponse'>
            <div className='customQuill'>
              <RichTextEditor
                message={props.invalidResponse}
                onChange={handleInvalidResponse}
                placeholder="Sorry, I didn't get that. Try something like ..."
                lines={1}
              />
            </div>
          </div>
          <div style={{ marginBottom: 10, marginTop: 25, fontWeight: 600 }}>What should the bot say after a second invalid response?</div>
          <div style={{ height: 85, marginBottom: 15 }} className='emailResponse'>
            <div className='customQuill'>
              <RichTextEditor
                message={props.secondResponse}
                onChange={handleSecondResponse}
                placeholder="Still didn't get that. Try something like ..."
                lines={1}
              />
            </div>
          </div>
        </div>
        <KeyWordGroupsModal
          open={modalOpen}
          onHide={() => setModalOpen(false)}
          saveGroup={save}
          state={modalState}
          onChange={setModalState}
        />
      </ModalSection>
    </div>
  )
}

export default KeywordGroupsSectionOld
