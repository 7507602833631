import { useState, useContext, useEffect, useCallback } from 'react'
import { SessionContext } from 'session-context'
import { groupSearch, deleteGroups } from 'api/keyword_groups'
import AppPage from 'cf-components/AppPage'
import KeyWordGroupsModal from './KeyWordGroupsModal'
import KeyWordGroupsTable from './KeyWordGroupsTable'
import DeleteModal from 'components/DeleteModal'

function KeyWordGroupsPage (props) {
  const { user } = useContext(SessionContext)
  const [chatServiceUrl] = useState(user.links.chat_service || 'localhost:8002/api/chat-service/a')
  const [modalOpen, setModalOpen] = useState(false)
  const [deleteGroupID, setDeleteGroupID] = useState(0)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [modalState, setModalState] = useState({
    name: '',
    description: '',
    id: null,
    comparison: 'contains',
    keywords: [],
    edit: false
  })
  const [groups, setGroups] = useState(null)

  function editGroup ({ name, description, id, comparison, keywords }) {
    setModalState({
      name: name,
      description: description,
      id: id,
      comparison: comparison,
      keywords: keywords,
      edit: true
    })
    setModalOpen(true)
  }

  function newGroup () {
    setModalState({
      name: '',
      description: '',
      id: null,
      comparison: 'contains',
      keywords: [],
      edit: false
    })
    setModalOpen(true)
  }

  function save () {
    loadGroups()
  }

  const loadGroups = useCallback(() => {
    groupSearch({ search: '', chatServiceUrl }).then(response => {
      setGroups(response.data)
    })
  }, [chatServiceUrl])

  useEffect(() => {
    if (!modalOpen) {
      loadGroups()
    }
  }, [loadGroups, modalOpen])

  function beginDelete () {
    deleteGroups({ group_ids: [deleteGroupID], chatServiceUrl })
      .then(response => {
        loadGroups()
        setDeleteGroupID(0)
        setDeleteModalOpen(false)
      })
  }

  const deleteMessage = 'Are you sure you want to delete this group?'
  const deleteTitle = 'Delete group'

  document.title = 'Keyword Groups | Signals'

  return (
    <AppPage
      title='Keyword Groups'
      action={newGroup}
      actionText='Create New Group'
    >
      <KeyWordGroupsTable
        rows={groups}
        editGroup={editGroup}
        handleDelete={(groupID) => {
          setDeleteGroupID(groupID)
          setDeleteModalOpen(true)
        }}
      />
      <KeyWordGroupsModal
        open={modalOpen}
        onHide={() => setModalOpen(false)}
        saveGroup={save}
        state={modalState}
        onChange={setModalState}
      />
      <DeleteModal
        open={deleteModalOpen}
        onHide={() => setDeleteModalOpen(false)}
        deleteObject={beginDelete}
        message={deleteMessage}
        title={deleteTitle}
      />
    </AppPage>
  )
}

export default KeyWordGroupsPage
