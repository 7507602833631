import { makeStyles } from '@material-ui/core/styles';
import { TextBox } from 'library/materialUI';
import { NPSContentHeader } from './NPSSettingsComponents';
import { get } from 'lodash'
import { useContext } from 'react';
import { NPSContext } from './NPSContext';
import SettingsCard from 'cf-components/SettingsCard';

const useStyles = makeStyles(theme => ({
  npsContentPage: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: 30
  },
  textBoxHeader: {
    color: '#000000BF',
    font: 'normal normal normal 20px/25px Poppins',
    marginBottom: 5
  },
  smallTextBoxHeader: {
    color: '#000000BF',
    font: 'normal normal normal 14px/25px Poppins'
  },
  ratingSection: {
    display: 'grid',
    gridTemplateAreas: `
      "question question"
      "low high"
      "complete other"
    `,
    gridTemplateColumns: '1fr 1fr',
    gridGap: 20,
    marginBottom: 30
  }
}))

type ContentTextBoxProps = {
  title: string
  path: string
  small?: boolean
  rows?: number
}
function ContentTextBox (props: ContentTextBoxProps) {
  const classes = useStyles()
  const { title, path, small, rows } = props
  const { stateHandlers } = useContext(NPSContext)
  const [state, dispatch] = stateHandlers

  const className = small ? classes.smallTextBoxHeader : classes.textBoxHeader

  return (
    <div style={{ marginBottom: 5 }}>
      <div className={className}>{title}</div>
      <TextBox
        value={get(state, path)}
        onChange={(value: string) => dispatch({ path, value })}
        rows={rows || 0}
      />
    </div>
  )
}

const ratingTypes = [
  { title: 'Question', path: 'content.question', area: 'question' },
  { title: 'Low rating text', path: 'content.lowRatingText' },
  { title: 'High rating text', path: 'content.highRatingText' },
  { title: 'Completion text', path: 'content.completionText' }
]

const promoterTypes = [
  { title: 'Header text', path: 'content.promoter.header' },
  { title: 'Body text', path: 'content.promoter.body', rows: 3 },
  { title: 'G2 offer text', path: 'content.promoter.offer' }
]

const genericFollowupTypes = [
  { title: 'Header text', section: 'header' },
  { title: 'Body text', section: 'body', rows: 4 }
]

export function NPSContent () {
  const classes = useStyles()

  return (
    <div className={classes.npsContentPage}>
      <div>
        <NPSContentHeader title='Initial Rating' />
        <div className={classes.ratingSection}>
          {ratingTypes.map((type: any, index: number) => (
            <div key={index} style={{ gridArea: type.area }}>
              <ContentTextBox
                title={type.title}
                path={type.path}
              />
            </div>
          ))}
        </div>
        <NPSContentHeader title='Written Feedback' />
        <ContentTextBox
          title='Header text'
          path='content.feedbackHeaderText'
        />
      </div>
      <div>
        <NPSContentHeader title='Follow up' />
        <SettingsCard title='Promoters (9-10)'>
          {promoterTypes.map((type, index) => (
            <ContentTextBox
              key={index}
              title={type.title}
              path={type.path}
              rows={type.rows}
              small
            />
          ))}
        </SettingsCard>
        <SettingsCard title='Passives (7-8)'>
          {genericFollowupTypes.map((type, index) => (
            <ContentTextBox
              key={index}
              title={type.title}
              path={'content.passive.' + type.section}
              rows={type.rows}
              small
            />
          ))}
        </SettingsCard>
        <SettingsCard title='Detractors (0-6)'>
          {genericFollowupTypes.map((type, index) => (
            <ContentTextBox
              key={index}
              title={type.title}
              path={'content.detractor.' + type.section}
              rows={type.rows}
              small
            />
          ))}
        </SettingsCard>
      </div>
    </div>
  )
}
