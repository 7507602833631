import React from 'react'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import DragNDrop from 'cf-components/DragNDrop'
import InformationIcon from 'img/InformationIcon.svg'
import Tooltip from '@material-ui/core/Tooltip'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import ControlPointIcon from '@material-ui/icons/ControlPoint'
import { Checkbox, IconButton } from '@material-ui/core'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import CloseIcon from '@material-ui/icons/Close';
import Picker from 'cf-components/Pickers'
import OwnerPriorityPicker from 'pages/accounts/AccountManagement/OwnerPriorityPicker'

const useStyles = makeStyles((theme) => ({
  dottedLine: {
    borderTop: '2px dotted #975ce6',
    height: 10,
    marginTop: 20,
    width: '100%'
  },
  bodyText: {
    marginBottom: 40,
    fontSize: 16
  },
  subtitle1: {
    fontSize: 18,
    fontWeight: 600,
    marginBottom: 15
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  grabbable: {
    cursor: 'grab'
  },
  rowHeader: {
    paddingBottom: 20,
    fontSize: 16,
    display: 'grid',
    gridTemplateColumns: '48px 250px 150px 450px auto 350px auto 50px',
    gridGap: 10,
    gridTemplateAreas: '"a segment b crmuser c fallback d delete"',
    maxWidth: 1100
  },
  select: {
    height: 40,
    width: 210
  }
}))

const RuleComponent = withStyles((theme: any) => ({
  grabbable: {
    cursor: 'grab'
  },
  routeField: {
    width: 46,
    marginRight: '8px',
    display: 'inline-block',
    verticalAlign: 'middle'
  },
  routeItemX: {
    display: 'inline-block',
    verticalAlign: 'top'
  },
  routeItem: {
    display: 'inline-block',
    verticalAlign: 'middle',
    minWidth: 300
  },
  dottedLineShort: {
    display: 'inline-block',
    borderTop: '2px dotted #975ce6',
    position: 'relative',
    top: 7,
    height: 10,
    width: 20,
    marginRight: 5
  },
  dottedLineFirst: {
    display: 'inline-block',
    borderTop: '2px dotted #975ce6',
    borderLeft: '2px dotted #975ce6',
    position: 'relative',
    top: 18,
    left: -12,
    height: 20,
    width: 20
  },
  dragHeader: {
    borderTop: '1px solid #dadada',
    padding: 20,
    fontWeight: 'bold'
  },
  dottedLineSecond: {
    display: 'inline-block',
    borderBottom: '2px dotted #975ce6',
    borderLeft: '2px dotted #975ce6',
    position: 'relative',
    top: -4,
    left: -12,
    height: 26,
    width: 20
  }
}))(
  function (props: any) {
    const classes = useStyles()
    const selectedFallbackOwner = props.item.segmentOwner ? props.item.segmentOwner : null
    const header = props.item.id === '0' ? 'First by:' : 'Then by:'
    return (
      <div className='no-focus-outline'>
        <div className={props.classes.dragHeader}>
          {header}
        </div>
        <div className={classes.rowHeader}>
          <DragIndicatorIcon
            className={props.classes.grabbable}
            color='primary'
          />
          <div style={{ gridArea: 'segment' }}>
            <Picker
              objectType='segments'
              placeholder='Select a Segment'
              multiple={false}
              selection={props.item.segmentID}
              setSelection={(s: any) => { props.ruleChanged(props.item.id, s, props.item.rules, selectedFallbackOwner) }}
            />
          </div>
          <div className={classes.dottedLine} />
          <div style={{ display: 'inline-block', verticalAlign: 'top' }}>
            {props.item.rules.map((rule: any, idx: number) => {
              const selectedOwnerField = rule || null
              return (
                <div key={idx} style={{ whiteSpace: 'nowrap', gridArea: 'crmuser' }}>
                  <div>
                    {idx === 0 && props.item.rules.length > 1 && (
                      <>
                        <div className={props.classes.dottedLineFirst} />
                        <div className={props.classes.routeField}>
                          <Typography variant='caption'>first by</Typography>
                        </div>
                        <div className={props.classes.dottedLineShort} />
                      </>
                    )}
                    {idx > 0 && (
                      <>
                        <div className={props.classes.dottedLineSecond} />
                        <div className={props.classes.routeField}>
                          <Typography variant='caption'>then by</Typography>
                        </div>
                        <div className={props.classes.dottedLineShort} />
                      </>
                    )}
                    <div className={props.classes.routeItem}>
                      <Picker
                        objectType='accountOwnerFields'
                        placeholder='Use Fallback Owner'
                        loadAllProps={{ searchParams: { extraHeaders: { 'x-search-type': props.searchLeads ? 'lead' : 'account' } } }}
                        multiple={false}
                        setSelection={(s: any) => {
                          const newRules = [...props.item.rules]
                          newRules[idx] = s
                          props.ruleChanged(props.item.id, props.item.segmentID, newRules, selectedFallbackOwner)
                        }}
                        selection={selectedOwnerField}
                      />
                      {props.item.rules.length === 1 && (
                        <>
                          <Button
                            variant='text'
                            onClick={() => props.ruleFieldAdded(props.item.id)}
                          >
                            <Typography variant='caption' style={{ color: 'gray' }}>
                              + add another field
                            </Typography>
                          </Button>
                        </>
                      )}
                    </div>
                    {props.item.rules.length > 1 && (
                      <div className={props.classes.routeItemX}>
                        <IconButton>
                          <CloseIcon
                            onClick={() => props.ruleFieldRemoved(props.item.id, idx)}
                            color='primary'
                          />
                        </IconButton>
                      </div>
                    )}
                  </div>
                </div>
              )
            }
            )}
          </div>
          <div className={classes.dottedLine} />
          <div style={{ display: 'inline-block', verticalAlign: 'bottom', minWidth: 300, marginLeft: 10, gridArea: 'fallback' }}>
            <Picker
              objectType='users'
              placeholder='Default to Fallback Routing'
              multiple={false}
              selection={selectedFallbackOwner}
              setSelection={(s: any) => props.ruleFallbackChanged(props.item.id, s)}
            />
          </div>
          <div style={{ display: 'inline-block', position: 'relative', top: -6, gridArea: 'delete' }}>
            <IconButton>
              <HighlightOffIcon
                onClick={() => props.ruleRemoved(props.item.id)}
                color='primary'
              />
            </IconButton>
          </div>
        </div>
      </div>
    )
  }
)

const AccountOwnerRules = ({
  syncSettings,
  label, description,
  rightLabel,
  handleChange,
  integrationName,
  allowLeads,
  providerSetSave
}: { syncSettings: any, label: string, description: string, rightLabel: string, handleChange: any, integrationName: string, allowLeads: boolean, providerSetSave?: (value: boolean) => void }) => {
  const classes = useStyles()
  const finishDrag = (items: any) => {
    const newRules = items.map((item: any) => {
      // eslint-disable-next-line
      return { segment_id: item.segmentID, rules: item.rules, segment_owner: item.segmentOwner }
    })
    if (providerSetSave) {
      providerSetSave(true)
    }
    handleChange('segment_rules', newRules)
  }

  const removeRule = (rowID: any) => {
    const newRules = [...syncSettings.segment_rules]
    newRules.splice(rowID, 1)
    if (providerSetSave) {
      providerSetSave(true)
    }
    handleChange('segment_rules', newRules)
  }

  const removeRuleField = (rowID: any, fieldID: any) => {
    const newRules = [...syncSettings.segment_rules]
    newRules[rowID].rules.splice(fieldID, 1)
    if (providerSetSave) {
      providerSetSave(true)
    }
    handleChange('segment_rules', newRules)
  }
  const addRuleField = (rowID: any) => {
    const newRules = [...syncSettings.segment_rules]
    newRules[rowID].rules.push('')
    if (providerSetSave) {
      providerSetSave(true)
    }
    handleChange('segment_rules', newRules)
  }

  /* eslint-disable */
  const changeFallbackOwner = (rowID: any, ownerID: any) => {
    const newRules = [...syncSettings.segment_rules]
    newRules[rowID].segment_owner = ownerID
    if (providerSetSave) {
      providerSetSave(true)
    }
    handleChange('segment_rules', newRules)
  }

  const addRule = () => {
    const newRules = [...syncSettings.segment_rules]
    newRules.push({ segment_id: null, rules: [''] })
    if (providerSetSave) {
      providerSetSave(true)
    }
    handleChange('segment_rules', newRules)
  }
  const setOwnerRules = (newRules: string[]) => {
    if (providerSetSave) {
      providerSetSave(true)
    }
    handleChange('owner_rules', newRules)

  }
  const segmentRuleChanged = (rowID: number, segmentID: number, rules: any, segmentOwner: any) => {
    const newRules = [...syncSettings.segment_rules]
    newRules[rowID] = {
      segment_id: segmentID,
      rules: rules,
      segment_owner: segmentOwner
    }
    if (providerSetSave) {
      providerSetSave(true)
    }
    handleChange('segment_rules', newRules)
  }
  /* eslint-enable */

  const segmentRules = syncSettings.segment_rules.map((r: any, idx: number) => {
    const rules = [...r.rules]
    if (rules.length === 0) {
      rules.push('')
    }
    return { id: `${idx}`, segmentID: r.segment_id, rules: rules, segmentOwner: r.segment_owner }
  })
  const minHeight = segmentRules.length * 116

  return (
    <>
      <div className={classes.header}>
        <Typography className={classes.subtitle1}>
          {label}
        </Typography>
      </div>
      <Typography variant='body2' className={classes.bodyText}>
        {description}
        <br />
        <br />
        {allowLeads && (
          <>
            <div style={{ color: 'black' }}>Companies without a {integrationName} account</div> <br />
            For companies that do not have an account in {integrationName}, we can use Leads to determine account owner.
            <br />

            <br />
            <Checkbox
              color='primary'
              checked={syncSettings.search_leads}
              onClick={(e: any) => { handleChange('search_leads', e.target.checked); if (providerSetSave) { providerSetSave(true) } }}
            /> Use most recent lead if there isn't an account <br />
            <br />
          </>
        )}
        <div style={{ color: 'black' }}>Segments</div><br />
        Ownership will first be assessed based on segments. If a company is in multiple segments, it will be assigned to the one listed first.
      </Typography>
      <div>
        {segmentRules.length > 0 && (
          <>
            <div className={classes.rowHeader}>
              <div style={{ gridArea: 'segment' }}>Segment</div>
              <div style={{ gridArea: 'crmuser' }}>{rightLabel}</div>
              <div style={{ gridArea: 'fallback' }}>Fallback Owner
                <span style={{ color: 'gray' }}>(Optional)
                  &nbsp;&nbsp;
                  <Tooltip title="If an owner can't be found using CRM fields, you can designate a user to be the fallback for that segment. You can also skip the CRM fields altogether and manually assign a segment to a user.">
                    <img src={InformationIcon} alt='Information Icon' />
                  </Tooltip>
                </span>
              </div>
            </div>
            <div style={{ minHeight: minHeight }}>
              <DragNDrop
                itemList={segmentRules}
                setItemList={finishDrag}
                componentProps={{
                  ruleChanged: segmentRuleChanged,
                  ruleRemoved: removeRule,
                  ruleFieldRemoved: removeRuleField,
                  ruleFieldAdded: addRuleField,
                  ruleFallbackChanged: changeFallbackOwner,
                  searchLeads: syncSettings.search_leads
                }}
                direction='vertical'
                component={RuleComponent}
                divClass='no-focus-outline'
              />
            </div>
          </>
        )}
        <div style={{ marginTop: 20 }}>
          <Button
            variant='outlined'
            color='primary'
            onClick={() => { addRule(); if (providerSetSave) { providerSetSave(true) } }}
            startIcon={<ControlPointIcon />}
          >
            Add Segment Rule
          </Button>
        </div>
        <br />
        <br />
        <b>Fallback</b><br />
        Choose how you'd like to route companies not contained in the segments above<br />
        <br />
        <OwnerPriorityPicker
          priorityList={syncSettings.owner_rules || []}
          setPriorityList={setOwnerRules}
          searchParams={{ search: syncSettings.search_leads ? 'lead' : 'account' }}
        />
        <br />
        <br />
        <b>Default Owner</b><br />
        <Typography>
          If an owner can’t be located using any of the above criteria, who would you like to designate as the owner?
        </Typography>
        <div style={{ marginTop: 10, width: '50%' }}>
          <Picker
            objectType='users'
            multiple={false}
            selection={syncSettings.fallback_user_id}
            setSelection={(s: any) => { handleChange('fallback_user_id', s); if (providerSetSave) { providerSetSave(true) } }}
          />
        </div>

      </div>
    </>
  )
}

export default AccountOwnerRules
