import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import Avatar from '@material-ui/core/Avatar'
import Divider from '@material-ui/core/Divider'
import dateFormat from 'dateformat'
import { getInitials } from 'cf-components/Avatars'
import { Modal, ModalSection } from 'cf-components/Modal'
import SaveIcon from '@material-ui/icons/Save'
import EditIcon from '@material-ui/icons/Edit'
import TextField from '@material-ui/core/TextField'
import { FilterBar, convertDateRange } from 'cf-components/FilterBar/FilterBar'
import { getBotReport, getBotOverview } from 'api/bots'
import { getSessionDateRange } from 'cf-components/FilterBar/CustomDateRanges'

const useStyles = makeStyles(theme => ({
  advancedPage: {
    padding: 20,
    display: 'grid',
    gridTemplateColumns: '400px auto',
    gridGap: 20
  },
  colorBanner: {
    height: 95,
    backgroundColor: 'rgba(151, 92, 230)',
    left: 0,
    top: 0,
    right: 0,
    position: 'absolute'
  },
  sequenceOverview: {
    padding: 30,
    position: 'relative',
    border: '1px solid rgba(151, 92, 230, .5)'
  },
  sequenceDescription: {
    padding: 15,
    border: '1px solid rgba(151, 92, 230, .5)'
  },
  avatarImage: {
    height: 120,
    width: 120,
    borderRadius: 100,
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  avatar: {
    fontSize: '64px',
    border: '1px solid #8e5ae2'
  },
  avatarColors: {
    backgroundColor: 'white',
    color: '#8e5ae2'
  },
  sequenceName: {
    marginTop: 20,
    fontSize: '1.5em',
    textAlign: 'center',
    marginBottom: 20,
    display: 'flex',
    justifyContent: 'center'
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  titleText: {
    fontSize: 20,
    marginBottom: 5
  },
  infoHeader: {
    color: '#777',
    marginTop: 20
  },
  infoBody: {
    marginTop: 3,
    color: '#333'
  },
  botSection: {
    marginTop: 20
  },
  statsCards: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
    gridGap: 20,
    marginTop: 10
  },
  sequenceCard: {
    padding: 20,
    border: '1px solid rgba(151, 92, 230, .5)',
    fontFamily: 'Poppins, sans serif',
    borderRadius: 10
  },
  statsCount: {
    marginTop: 10,
    marginBottom: 10,
    fontSize: '50px',
    color: '#8e5ae2'
  },
  statsPercent: {
    fontSize: '22px',
    color: '#777'
  },
  cardTitle: {
    fontWeight: 600
  }
}))

const defaultReport = {
  sequence_fires: 0,
  emails_captured: 0,
  live_chat_routes: 0,
  meetings_booked: 0,
  new_conversations: 0,
  primary_goals: 0,
  secondary_goals: 0
}

function BotStatsCard (props) {
  const classes = useStyles()

  const percent = props.denominator ? (props.value * 100 / props.denominator).toFixed(2) + '%' : '-----'

  return (
    <Card className={classes.sequenceCard}>
      <div className={classes.cardTitle}>
        {props.title}
      </div>
      <div className={classes.statsCount}>
        {props.value || 0}
      </div>
      <div className={classes.statsPercent}>
        {percent}
      </div>
    </Card>
  )
}

function BotStatistics (props) {
  const classes = useStyles()
  const chatServiceUrl = props.chatServiceUrl
  let startingDate = getSessionDateRange()
  startingDate = { ...startingDate, startDate: new Date(startingDate.startDate), endDate: new Date(startingDate.endDate) }
  const [dateRange, setDateRange] = useState(startingDate)
  const [report, setReport] = useState(null)

  useEffect(() => {
    const botID = props.botID
    const convertedRange = convertDateRange(dateRange)
    const startDate = convertedRange?.start_date
    const endDate = convertedRange?.end_date
    if (botID && chatServiceUrl) {
      getBotReport({ chatServiceUrl, botID, startDate, endDate })
        .then(response => setReport(response.data.attributes))
    } else {
      setReport(defaultReport)
    }
  }, [dateRange, props.botID, chatServiceUrl])

  const filterOptions = {
    filterValues: [
      { value: 'Date', label: 'Filter by Date', toggle: false }
    ],
    dateRange: dateRange,
    setDateRange: setDateRange
  }

  if (!report) {
    return <></>
  }

  return (
    <div>
      <FilterBar
        filterOptions={filterOptions}
        noPadding
      />
      <div className={classes.statsCards}>
        <BotStatsCard
          title='Bot Fires'
          value={report.prompts}
        />
        <BotStatsCard
          title='Conversations'
          value={report.conversations}
          denominator={report.prompts}
        />
        <BotStatsCard
          title='Routed to Live Chat'
          value={report.live_chats}
          denominator={report.conversations}
        />
        <BotStatsCard
          title='Emails Captured'
          value={report.emails_captured}
          denominator={report.conversations}
        />
        <BotStatsCard
          title='Meetings Booked'
          value={report.meetings_booked}
          denominator={report.conversations}
        />
        <BotStatsCard
          title='Primary Goals Reached'
          value={report.primary_goals}
          denominator={report.conversations}
        />
        <BotStatsCard
          title='Secondary Goals Reached'
          value={report.secondary_goals}
          denominator={report.conversations}
        />
      </div>
    </div>
  )
}

function BotOverviewCard (props) {
  const classes = useStyles()
  const chatServiceUrl = props.chatServiceUrl
  const botID = props.bot?.id
  const [overview, setOverview] = useState(null)

  useEffect(() => {
    if (chatServiceUrl && botID) {
      getBotOverview({ chatServiceUrl, botID })
        .then(response => {
          setOverview(response.data.attributes)
        })
    } else {
      setOverview({
        last_fired: null,
        most_frequent_bot_id: null,
        bot_fires_all_time: 0,
        bot_fires_this_week: 0
      })
    }
  }, [chatServiceUrl, botID])

  if (!(overview)) {
    return <></>
  }

  const bot = props.bot.attributes
  const lastFired = overview.last_fired ? dateFormat(overview.last_fired, 'mm/dd/yyyy', true) : 'Never Fired'
  const botCreated = dateFormat(bot.created_timestamp, 'mm/dd/yyyy')
  const botUpdated = dateFormat(bot.updated_timestamp, 'mm/dd/yyyy')
  const initials = getInitials(bot.name)

  const types = {
    flow: 'Flow',
    content_page: 'Content Landing Page',
    welcome: 'Greeter Bot',
    team_live_chat_router: 'Concierge Bot'
  }

  const type = types[bot.kind] || ''

  return (
    <Card className={classes.sequenceOverview}>
      <div className={classes.colorBanner} />
      <div style={{ position: 'relative', display: 'flex', justifyContent: 'center' }}>
        <div className={classes.companyImage}>
          <Avatar
            variant='rounded'
            style={{ height: 118, width: 118 }}
            classes={{ colorDefault: classes.avatarColors, rounded: classes.avatar }}
          >
            {initials}
          </Avatar>
        </div>
      </div>
      <div className={classes.sequenceName}>
        {bot.name}
      </div>
      <Divider />
      <div>
        <div className={classes.infoHeader}>Bot Created</div>
        <div className={classes.infoBody}>{botCreated}</div>
      </div>
      <div style={{ cursor: 'pointer' }} onClick={props.handleEditDescription}>
        <div className={classes.infoHeader} style={{ display: 'flex' }}>
          Description
          <EditIcon style={{ height: 18, width: 18, marginLeft: 10 }} />
        </div>
        <div className={classes.infoBody}>{bot.description}</div>
      </div>
      {type &&
        <div>
          <div className={classes.infoHeader}>Bot Type</div>
          <div className={classes.infoBody}>{type}</div>
        </div>}
      <div>
        <div className={classes.infoHeader}>Last Updated</div>
        <div className={classes.infoBody}>{botUpdated}</div>
      </div>
      <div>
        <div className={classes.infoHeader}>Total Fires All Time</div>
        <div className={classes.infoBody}>{overview.bot_fires_all_time}</div>
      </div>
      <div>
        <div className={classes.infoHeader}>Fires This Week</div>
        <div className={classes.infoBody}>{overview.bot_fires_this_week}</div>
      </div>
      <div>
        <div className={classes.infoHeader}>Last Fired Date</div>
        <div className={classes.infoBody}>{lastFired}</div>
      </div>
    </Card>
  )
}

function BotDescriptionModal (props) {
  const [description, setDescription] = useState(props.bot.attributes.description)

  useEffect(() => {
    if (props.bot) {
      setDescription(props.bot.attributes.description)
    }
  }, [props.bot, props.open])

  const save = () => {
    props.save(description)
    props.onHide()
  }

  return (
    <Modal
      title='Bot Description'
      onHide={props.onHide}
      open={props.open}
      size='sm'
      rightButtonProps={{
        text: 'Save',
        action: save,
        variant: 'outlined',
        icon: SaveIcon
      }}
      leftButtonProps={{
        text: 'Cancel',
        action: props.onHide,
        variant: 'text'
      }}
    >
      <ModalSection
        title='Edit Description'
      >
        <div style={{ marginTop: 10 }}>
          <TextField
            variant='outlined'
            multiline
            rows={3}
            value={description}
            onChange={(event) => setDescription(event.target.value)}
            margin='dense'
            fullWidth
            style={{ backgroundColor: 'white' }}
          />
        </div>
      </ModalSection>
    </Modal>
  )
}

function BotDetailsPage (props) {
  const classes = useStyles()
  const [modalOpen, setModalOpen] = useState(false)

  return (
    <div className={classes.advancedPage}>
      <BotOverviewCard
        bot={props.bot}
        chatServiceUrl={props.chatServiceUrl}
        handleEditDescription={() => setModalOpen(true)}
      />
      <div>
        <BotStatistics
          chatServiceUrl={props.chatServiceUrl}
          botID={props.bot.id}
        />
      </div>
      {props.bot &&
        <BotDescriptionModal
          open={modalOpen}
          onHide={() => setModalOpen(false)}
          bot={props.bot}
          save={props.saveDescription}
        />}
    </div>
  )
}

export default BotDetailsPage
