import React, { useMemo } from 'react'
import { Avatar } from 'cf-components/Avatars'
import sanitizeHTML from 'sanitize-html'
import { ConversationListStyles as useStyles } from './MobileChatStyles'
import { TimeSinceMessage, getDisplayColor } from './ConversationHelpers'

function ContactInfo (props) {
  const classes = useStyles()
  return (
    <>
      <div className={classes.contactDiv}>
        <div>
          <Avatar
            conversation={props.conversation}
            size='md'
            status={props.online ? 'available' : 'unavailable'}
          />
        </div>
        <div className={classes.contactInfo}>
          <div className={classes.topLine}>
            <div className={classes.contactName} style={{ color: '#4A4A4A' }}>{props.personName}</div>
            <TimeSinceMessage msgTime={props.msgTime} color={props.color} />
          </div>
          <div className={classes.contactCompany} style={{ color: '#7C7C7C' }}>{props.companyName}</div>
          <div className={classes.message}>
            {props.message}
          </div>
        </div>
      </div>
    </>
  )
}

function ConversationListItem (props) {
  const classes = useStyles()
  const convo = props.convo
  const body = convo?.attributes?.last_message

  const bgColor = props.status === 'closed' ? '#EEE' : '#FFF'

  const msgTime = convo.attributes.last_message_timestamp
  const status = convo.attributes.status
  const color = getDisplayColor(msgTime, props.read, status)

  const sanitizedLastBody = useMemo(() => {
    return sanitizeHTML(body, {
      allowedTags: [],
      allowedAttributes: []
    })
  }, [body])

  return (
    <div style={{ backgroundColor: bgColor }}>
      <div
        className={classes.convoDiv}
        onClick={() => props.chooseConversation(convo.id)}
      >
        <div className={classes.flagsDiv}>
          {props.read || status === 'closed' ? <></>
            : <span className={classes.dot} style={{ backgroundColor: color }} />}
        </div>
        <div className={classes.infoDiv}>
          <ContactInfo
            msgTime={msgTime}
            personName={props.personName}
            companyName={props.companyName}
            initials={props.initials}
            online={props.online}
            color={color}
            score={props.score}
            message={sanitizedLastBody}
            conversation={convo}
          />
        </div>
      </div>
    </div>
  )
}

export default ConversationListItem
