import { Tooltip } from '@material-ui/core'
import { Icon } from 'library/materialUI'

interface SourceInfoProps {
    trafficSource: string
    trafficCampaign: string
    color?: string
}

export default function TrafficSourceInfo ({ trafficSource, trafficCampaign, color = '#00000040' }: SourceInfoProps): JSX.Element {
  return (
    <>
      {(trafficSource || trafficCampaign) ? (
        <Tooltip
          title={<><b>Campaign: </b> {trafficCampaign || '---'} <br /> <b>Source: </b> {trafficSource || '---'}</>}
          placement='bottom'
        >
          <div style={{ display: 'flex', alignItems: 'center', marginLeft: 5 }}>
            <Icon icon='info' color={color} size='sm' />
          </div>
        </Tooltip>
        ) : <></>}
    </>
  )
}
