import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Checkbox,
  FormControlLabel,
  Select, MenuItem
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  menuHeader: {
    borderBottom: '1px solid #BBB',
    width: '100%',
    fontWeight: 600,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: 'rgba(0,0,0,0.87)'
  },
  eventSelect: {
    width: 250,
    height: 40,
    marginBottom: 25
  }
}))

// NOTE: DO NOT USE IN EloquaIntegrationPage.
// Right now we are not reusing this component,
// we are only using it in EloquaCustomObjectMapper.jsx

const CustomEventSettingsSelector = props => {
  const classes = useStyles()
  const currentEventMapping = props.state?.attributes?.mappings?.custom_object_mappings?.[0]?.event_mapping || []
  const externalMapping = props.externalMapping || []
  const internalFieldKey = props.eventSetting?.key
  const fieldName = props.eventSetting?.name

  let checked = false
  let externalField = null

  for (const eventMapping of currentEventMapping) {
    if (eventMapping.internal_field === internalFieldKey) {
      externalField = eventMapping.external_field
      checked = eventMapping.enabled || false
    }
  }

  const handleEventCheckbox = ({ internal_field, value }) => {
    const newEventMapping = []
    let newObjectMap = null
    for (const eventMap of currentEventMapping) {
      if (eventMap?.internal_field === internal_field) {
        newObjectMap = eventMap
      } else {
        newEventMapping.push(eventMap)
      }
    }

    if (newObjectMap === null) {
      newObjectMap = {
        type: 'cf_field',
        enabled: value,
        object_type: '',
        external_field: '',
        internal_field: internal_field
      }
    }

    newObjectMap.enabled = value

    newEventMapping.push(newObjectMap)
    props.handleEdit(newEventMapping)
  }

  const handleEventSelect = ({ internal_field, value }) => {
    const selectedMapping = externalMapping.filter(ecm => ecm.api_name === value)?.[0]
    const newEventMapping = []
    let newObjectMap = null

    if (!selectedMapping) {
      return
    }

    for (const eventMap of currentEventMapping) {
      if (eventMap?.internal_field === internal_field) {
        newObjectMap = eventMap
      } else {
        newEventMapping.push(eventMap)
      }
    }

    if (newObjectMap === null) {
      newObjectMap = {
        type: 'cf_field',
        enabled: value,
        object_type: '',
        external_field: '',
        internal_field: internal_field
      }
    }

    newObjectMap.external_field = selectedMapping.api_name
    newObjectMap.object_type = selectedMapping.object_type

    newEventMapping.push(newObjectMap)

    props.handleEdit(newEventMapping)
  }

  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            color='primary'
            checked={checked}
            onChange={(e) =>
              handleEventCheckbox({ internal_field: internalFieldKey, value: e.target.checked })}
          />
        }
        label={fieldName}
      />
      {checked
        ? (
          <div>
            <Select
              name='event_select'
              variant='outlined'
              margin='dense'
              defaultValue='preview'
              className={classes.eventSelect}
              value={externalField}
              onChange={(e) => {
                handleEventSelect(
                  { internal_field: internalFieldKey, value: e.target.value }
                )
              }}
            >
              <MenuItem disabled style={{ opacity: 1 }}>
                <div className={classes.menuHeader}>Eloqua Contact Field</div>
              </MenuItem>
              {externalMapping.map((row, index) => (
                <MenuItem key={index} value={row.api_name}>
                  {row.label}
                </MenuItem>
              ))}
            </Select>
          </div>
        ) : <></>}
    </>
  )
}

export default CustomEventSettingsSelector
