import { doPut } from 'api/api'
import { useDoQuery } from './useDoQuery'
import { GenericObject } from './genericObject'

interface Factor {
    name: string
    op: string
    value: any
}

export interface FactorGroup {
    importance: 'not_very' | 'somewhat' | 'important' | 'very' | 'extremely'
    factors: Factor[]
}

export type DealCycleInterval = 'day' | 'week' | 'month'
export default class ScoreSettings extends GenericObject {
    tenantId: number
    createdByUserId: number
    default: boolean
    factorGroups: FactorGroup[]
    dealCycleValue: number
    dealCycleInterval: DealCycleInterval
    buyingCommitteePriorities: number[]
    opportunityTypes: string[]

    static loadOne (): { data: ScoreSettings, isLoading: boolean, isError: boolean, error: any } {
        return useDoQuery({
            path: '/scores/settings',
            useChatURL: true,
            objectClass: ScoreSettings
        })
    }

    static saveSettings ({ data }: { data: Partial<ScoreSettings> }): Promise<any> {
        return doPut({
            path: '/scores/settings',
            data: { attributes: { ...data } },
            useChatURL: true
        })
    }

    constructor ({ row }: { row?: Record<string, any> } = {}) {
        super({ row })
        const settings = row?.attributes
        this.name = settings.name
        this.tenantId = settings.tenant_id
        this.createdByUserId = settings.created_by_user_id
        this.default = settings.default
        this.factorGroups = settings.factor_groups
        this.dealCycleValue = settings.deal_cycle_value
        this.dealCycleInterval = settings.deal_cycle_interval
        this.buyingCommitteePriorities = settings.buying_committee_priorities
        this.opportunityTypes = settings.opportunity_types
    }
}
