/* eslint-disable @typescript-eslint/naming-convention */
import { makeStyles } from '@material-ui/core/styles'
import { Icon } from 'library/materialUI'
import { Select } from 'cf-components/material-wrappers/MaterialComponents'
import CustomSounds from 'classes/customSounds'
import { useEffect, useState } from 'react'
import { Sound } from 'library/sounds/sound'

const useStyles = makeStyles(theme => ({
  soundItemContainer: {
    marginBottom: '20px',
    marginTop: '20px'
  },
  soundDropdownContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  soundTitle: {
    fontSize: '14px',
    marginBottom: '5px'
  }
}))

interface CustomSoundsMappingProps {
  state: any
  dispatch: (value: any) => void
  setDisabled: (value: boolean) => void
}

export default function CustomSoundsMapping ({ state, dispatch, setDisabled }: CustomSoundsMappingProps) {
  const classes = useStyles()
  const [playing, setPlaying] = useState('')
  const [currentSound, setCurrentSound] = useState<HTMLAudioElement>()
  const [customList, setCustomList] = useState([
    { value: '', label: 'Custom Sounds', disabled: true }
  ])

  const defaultList = [
    { value: '', label: 'Default Sounds', disabled: true },
    { value: 'simple', label: 'Simple Notification 1' },
    { value: 'decorativeOne', label: 'Decorative Notification 1' },
    { value: 'decorativeTwo', label: 'Decorative Notification 2' },
    { value: 'twinkle', label: 'Twinkle' },
    { value: 'register', label: 'Cash Register' },
    { value: 'trumpet', label: 'Trumpet Fanfare' },
    { value: 'levelUp', label: 'Video Game Level-up' },
    { value: 'gameShow', label: 'Game Show Music' },
    { value: 'gong', label: 'Gong' }
  ]

  const mapping = [
    { title: 'First-time Visitor', value: state?.firstTimeVisitorSound?.link_url || 'simple', label: state?.firstTimeVisitorSound?.name || 'Simple Notification 1', type: 'firstTimeVisitor' },
    { title: 'Returning Visitor', value: state?.returningVisitorSound?.link_url || 'decorativeOne', label: state?.returningVisitorSound?.name || 'Decorative Notification 1', type: 'returningVisitor' },
    { title: 'Visit on High-Intent page', value: state?.highIntentPageVisitorSound?.link_url || 'decorativeTwo', label: state?.highIntentPageVisitorSound?.name || 'Decorative Notification 2', type: 'highIntentVisitor' },
    { title: 'Visitor Identified', value: state?.visitorIdentifiedSound?.link_url || 'twinkle', label: state?.visitorIdentifiedSound?.name || 'Twinkle', type: 'visitorIdentified' },
    { title: 'Visitor from Signals email', value: state?.visitorFromEmailSound?.link_url || 'register', label: state?.visitorFromEmailSound?.name || 'Cash Register', type: 'visitorFromEmail' },
    { title: 'Conversation Started', value: state?.conversationStartedSound?.link_url || 'trumpet', label: state?.conversationStartedSound?.name || 'Trumpet Fanfare', type: 'conversationStarted' },
    { title: 'Live Chat Started', value: state?.liveChatStartedSound?.link_url || 'levelUp', label: state?.liveChatStartedSound?.name || 'Video Game Level-up', type: 'liveChatStarted' },
    { title: 'Calendar Drop', value: state?.calendarDropSound?.link_url || 'gameShow', label: state?.calendarDropSound?.name || 'Game Show Music', type: 'calendarDrop' },
    { title: 'Meeting Booked', value: state?.meetingBookedSound?.link_url || 'gong', label: state?.meetingBookedSound?.name || 'Gong', type: 'meetingBooked' }
  ]

  const getLabel = (value: string) => {
    const defaultname = defaultList.find((item) => item.value === value)?.label || ''
    if (defaultname) {
      return defaultname
    }
    const name = customList.find((item) => item.value === value)?.label || ''
    if (name) {
      return name
    }
  }

  useEffect(() => {
    CustomSounds.getCustomSoundList()
    .then((response) => {
      if (response?.data) {
        const temp = [{ value: '', label: 'Custom Sounds', disabled: true }]
        response?.data.map((item: any) => (
          temp.push({ value: item.attributes.link_url, label: item.attributes.name, disabled: false })
        ))
        setCustomList(temp)
      }
    })
  // eslint-disable-next-line
  }, [])

  return (
    <div>
      {mapping.map((item, index) => {
        return (
          <div key={index} className={classes.soundItemContainer}>
            <div className={classes.soundTitle}>
              {item.title}
            </div>
            <div className={classes.soundDropdownContainer}>
              <div style={{ width: '300px', marginRight: '12px' }}>
                <Select
                  options={customList.length > 0 ? customList.concat(defaultList) : defaultList}
                  value={item.value}
                  label=''
                  onChange={(value: string) => { dispatch({ type: item.type, value: { name: getLabel(value), link_url: value } }); setDisabled(false) }}
                  disableClear
                />
              </div>
              <div style={{ cursor: 'pointer' }}>
                <Icon
                  icon={(playing === item.title) ? 'pause' : 'playArrow'}
                  onClick={() => {
                    if (item.title === playing) {
                      setPlaying('')
                      currentSound?.pause()
                      setCurrentSound(undefined)
                    } else {
                      setPlaying(item.title)
                      setCurrentSound(Sound({ sound: item.value, onEnd: () => setPlaying('') }))
                    }
                  }}
                />
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}
