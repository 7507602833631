import { IconType } from 'library/materialUI/Icon'

export type basicMapping = {
  icon: IconType
  title: string,
}

export const notificationMapping: Record<string, basicMapping> = {
  Chrome: {
    icon: 'smChrome',
    title: 'Allow sound notifications in Chrome'
  },
  Safari: {
    icon: 'smSafari',
    title: 'Allow sound notifications in Safari'
  },
  Firefox: {
    icon: 'smFirefox',
    title: 'Allow sound notifications in Firefox'
  },
  Edge: {
    icon: 'smEdge',
    title: 'Allow sound notifications in Edge'
  },
  Brave: {
    icon: 'smBrave',
    title: 'Allow sound notifications in Brave'
  }
}
