import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { TableCell } from '@material-ui/core'
import { Link } from 'react-router-dom'
import EnhancedTable from '../../../components/EnhancedTable'

const useStyles = makeStyles(theme => ({
  percent: {
    color: 'rgba(0,0,0,0.5)',
    paddingTop: 2
  },
  botName: {
    fontSize: '1.1em',
    fontWeight: 600,
    textDecoration: 'none',
    color: 'rgba(0,0,0,0.8)'
  },
  link: {
    textDecoration: 'none'
  }
}))

const headCells = [
  { id: 'name', align: 'left', disablePadding: false, label: 'Name' },
  { id: 'prompts', align: 'center', disablePadding: false, label: 'Prompts', paddingLeft: 30 },
  { id: 'conversations', align: 'center', disablePadding: false, label: 'Conversations', paddingLeft: 30 },
  { id: 'emails', align: 'center', disablePadding: false, label: 'Emails Captured', paddingLeft: 30 },
  { id: 'meetings', align: 'center', disablePadding: false, label: 'Meetings Booked', paddingLeft: 30 },
  { id: 'primary_goals', align: 'center', disablePadding: false, label: 'Primary Goals', paddingLeft: 30 },
  { id: 'secondary_goals', align: 'center', disablePadding: false, label: 'Secondary Goals', paddingLeft: 30 }
]

function BotPerformanceTable (props) {
  const rows = props.rows.map(row => {
    let type
    if (row.attributes.kind === 'team_live_chat_router') {
      type = 'Live Chat Router Bot'
    } else if (row.attributes.kind === 'content_page') {
      type = 'Content Bot'
    } else if (row.attributes.kind === 'abe_greeting') {
      type = 'ABE Greeting Bot'
    } else {
      type = row.attributes.kind.replace('_', ' ') + ' Bot'
    }
    type = type.charAt(0).toUpperCase() + type.slice(1)
    return (
      {
        id: row.id,
        name: row.attributes.bot_name,
        type: type,
        prompts: row.attributes.prompts,
        conversations: row.attributes.conversations,
        emails: row.attributes.emails,
        meetings: row.attributes.meetings,
        primary_goals: row.attributes.primary_goals,
        secondary_goals: row.attributes.secondary_goals
      })
  })

  function CustomRow (props) {
    const classes = useStyles()
    const row = props.row
    const convoPercent = (Math.round((row.conversations / row.prompts) * 100) || 0) + '%'
    const emailPercent = (Math.round((row.emails / row.conversations) * 100) || 0) + '%'
    const meetingPercent = (Math.round((row.meetings / row.conversations) * 100) || 0) + '%'
    const primaryPercent = (Math.round((row.primary_goals / row.conversations) * 100) || 0) + '%'
    const secondaryPercent = (Math.round((row.secondary_goals / row.conversations) * 100) || 0) + '%'

    return (
      <>
        <TableCell>
          <Link to={`/bots/${row.id}`} className={classes.link}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div>
                <div style={{ fontSize: '1.1em', color: 'rgba(0,0,0,0.8)' }}>
                  {row.name}
                </div>
                <div style={{ fontSize: '0.9em', color: 'rgba(0,0,0,0.5)' }}>
                  {row.type}
                </div>
              </div>
            </div>
          </Link>
        </TableCell>
        <TableCell align='center' style={{}}>
          <div>{row.prompts}</div>
          <div className={classes.percent}>---</div>
        </TableCell>
        <TableCell align='center'>
          <div>{row.conversations}</div>
          <div className={classes.percent}>
            {convoPercent}
          </div>
        </TableCell>
        <TableCell align='center'>
          <div>{row.emails}</div>
          <div className={classes.percent}>
            {emailPercent}
          </div>
        </TableCell>
        <TableCell align='center'>
          <div>{row.meetings}</div>
          <div className={classes.percent}>
            {meetingPercent}
          </div>
        </TableCell>
        <TableCell align='center'>
          <div>{row.primary_goals}</div>
          <div className={classes.percent}>
            {primaryPercent}
          </div>
        </TableCell>
        <TableCell align='center'>
          <div>{row.secondary_goals}</div>
          <div className={classes.percent}>
            {secondaryPercent}
          </div>
        </TableCell>
      </>
    )
  }

  function columnWidths () {
    return (
      <colgroup>
        <col style={{ width: '5%' }} />
        <col style={{ width: '23%' }} />
        <col style={{ width: '12%' }} />
        <col style={{ width: '12%' }} />
        <col style={{ width: '12%' }} />
        <col style={{ width: '12%' }} />
        <col style={{ width: '12%' }} />
        <col style={{ width: '12%' }} />
      </colgroup>
    )
  }

  return (
    <EnhancedTable
      headCells={headCells}
      selected={props.selected}
      setSelected={props.setSelected}
      rows={rows}
      CustomRow={CustomRow}
      columnWidths={columnWidths}
      orderBy='prompts'
      order='desc'
      loading={props.loading}
    />
  )
}

export default BotPerformanceTable
