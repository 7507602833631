
export const OppValues = [
  { label: 'Open Opp', value: 'Open Opp' },
  { label: 'No Opp', value: 'No Opp' }
]

export const RevenueValues = [
  { label: '$1 - $999.9K', value: '1_999999' },
  { label: '$1M - $4.9M', value: '1000000_4999999' },
  { label: '$5M - $9.9M', value: '5000000_9999999' },
  { label: '$10M - $24.9M', value: '10000000_24999999' },
  { label: '$25M - $49.9M', value: '25000000_49999999' },
  { label: '$50M - $99.9M', value: '50000000_99999999' },
  { label: '$100M - $249.9M', value: '100000000_249999999' },
  { label: '$250M - $499.9M', value: '250000000_499999999' },
  { label: '$500M - $999.9M', value: '500000000_999999999' },
  { label: '$1B - $2.49B', value: '1000000000_2499999999' },
  { label: '$2.5B - $4.9B', value: '2500000000_4999999999' },
  { label: '$5B+', value: '5000000000' }
]

export const EmployeeCountValues = [
  { label: '1-9', value: '1_9' },
  { label: '10-19', value: '10_19' },
  { label: '20-49', value: '20_49' },
  { label: '50-99', value: '50_99' },
  { label: '100-249', value: '100_249' },
  { label: '250-499', value: '250_499' },
  { label: '500-999', value: '500_999' },
  { label: '1,000-4,999', value: '1000_4999' },
  { label: '5,000-9,999', value: '5000_9999' },
  { label: '10,000+', value: '100000' }
]

export const ABEScores = [
  { label: '1-9', value: '1_9' },
  { label: '10-19', value: '10_19' },
  { label: '20-29', value: '20_29' },
  { label: '30-39', value: '30_39' },
  { label: '40-49', value: '40_49' },
  { label: '50-59', value: '50_59' },
  { label: '60-69', value: '60_69' },
  { label: '70-79', value: '70_79' },
  { label: '80-89', value: '80_89' },
  { label: '90-100', value: '90_100' }
]

export const LocationData = [
  { label: 'Africa', value: 'Africa' },
  { label: 'Northern Africa', value: 'Northern Africa' },
  { label: 'Algeria', value: 'Algeria' },
  { label: 'Canary Islands', value: 'Canary Islands' },
  { label: 'Ceuta', value: 'Ceuta' },
  { label: 'Egypt', value: 'Egypt' },
  { label: 'Libya', value: 'Libya' },
  { label: 'Madeira', value: 'Madeira' },
  { label: 'Melilla', value: 'Melilla' },
  { label: 'Morocco', value: 'Morocco' },
  { label: 'Sudan', value: 'Sudan' },
  { label: 'Tunisia', value: 'Tunisia' },
  { label: 'Western Sahara', value: 'Western Sahara' },
  { label: 'Eastern Africa', value: 'Eastern Africa' },
  { label: 'Burundi', value: 'Burundi' },
  { label: 'Comoros', value: 'Comoros' },
  { label: 'Djibouti', value: 'Djibouti' },
  { label: 'Eritrea', value: 'Eritrea' },
  { label: 'Ethiopia', value: 'Ethiopia' },
  { label: 'French Southern Territories', value: 'French Souther Territories' },
  { label: 'Kenya', value: 'Kenya' },
  { label: 'Madagascar', value: 'Madagascar' },
  { label: 'Malawi', value: 'Malawi' },
  { label: 'Mauritius', value: 'Mauritius' },
  { label: 'Mayotte', value: ' Mayotte' },
  { label: 'Mozambique', value: 'Mozambique' },
  { label: 'Reunion', value: 'Reunion' },
  { label: 'Rwanda', value: 'Rwanda' },
  { label: 'Seychelles', value: 'Seychelles' },
  { label: 'Somalia', value: 'Somalia' },
  { label: 'South Sudan', value: 'South Sudan' },
  { label: 'Tanzania', value: 'Tanzania' },
  { label: 'Uganda', value: 'Uganda' },
  { label: 'Zambia', value: 'Zambia' },
  { label: 'Zimbabwe', value: 'Zimbabwe' },
  { label: 'Middle Africa', value: 'Middle Africa' },
  { label: 'Angola', value: 'Angola' },
  { label: 'Cameroon', value: 'Cameroon' },
  { label: 'Central African Republic', value: 'Central African Republic' },
  { label: 'Chad', value: 'Chad' },
  { label: 'Congo Democratic Republic of the', value: 'Congo Democratic Republic of the' },
  { label: 'Congo Republic of the', value: 'Congo Republic of the' },
  { label: 'Equatorial Guinea', value: 'Equatorial Guinea' },
  { label: 'Gabon', value: 'Gabon' },
  { label: 'São Tomé and Príncipe', value: 'São Tomé and Príncipe' },
  { label: 'Southern Africa', value: 'Southern Africa' },
  { label: 'Botswana', value: 'Botswana' },
  { label: 'Eswatini', value: 'Eswatini' },
  { label: 'Lesotho', value: 'Lesotho' },
  { label: 'Namibia', value: 'Namibia' },
  { label: 'South Africa', value: 'South Africa' },
  { label: 'Western Africa', value: 'Western Africa' },
  { label: 'Benin', value: 'Benin' },
  { label: 'Burkina Faso', value: 'Burkina Faso' },
  { label: 'Cape Verde', value: 'Cape Verde' },
  { label: 'Côte d\'Ivoire', value: 'Côte d\'Ivoire' },
  { label: 'Gambia The', value: 'Gambia The' },
  { label: 'Ghana', value: 'Ghana' },
  { label: 'Guinea', value: 'Guinea' },
  { label: 'Guinea-Bissau', value: 'Guinea-Bissau' },
  { label: 'Liberia', value: 'Liberia' },
  { label: 'Mali', value: 'Mali' },
  { label: 'Mauritania', value: 'Mauritania' },
  { label: 'Niger', value: 'Niger' },
  { label: 'Nigeria', value: 'Nigeria' },
  { label: 'Saint Helena Ascension and Tristan da Cunha', value: 'Saint Helena Ascension and Tristan de Cunha' },
  { label: 'Senegal', value: 'Senegal' },
  { label: 'Sierra Leone', value: 'Sierra Leone' },
  { label: 'Togo', value: 'Togo' },
  { label: 'Asia', value: 'Asia' },
  { label: 'Central Asia', value: 'Central Asia' },
  { label: 'Tajikistan', value: 'Tajikistan' },
  { label: 'Uzbekistan', value: 'Uzbekistan' },
  { label: 'Kazakhstan', value: 'Kazakhstan' },
  { label: 'Turkmenistan', value: 'Turkmenistan' },
  { label: 'Kyrgyzstan', value: 'Kyrgyzstan' },
  { label: 'East Asia', value: 'East Asia' },
  { label: 'China', value: 'China' },
  { label: 'Mongolia', value: 'Mongolia' },
  { label: 'North Korea', value: 'North Korea' },
  { label: 'South Korea', value: 'South Korea' },
  { label: 'Japan', value: 'Japan' },
  { label: 'Hong Kong', value: 'Hong Kong' },
  { label: 'Taiwan', value: 'Taiwan' },
  { label: 'Macau', value: 'Macau' },
  { label: 'South Asia', value: 'South Asia' },
  { label: 'Sri Lanka', value: 'Sri Lanka' },
  { label: 'Bangladesh', value: 'Bangladesh' },
  { label: 'India', value: 'India' },
  { label: 'Afghanistan', value: 'Afghanistan' },
  { label: 'Pakistan', value: 'Pakistan' },
  { label: 'Bhutan', value: 'Bhutan' },
  { label: 'Nepal', value: 'Nepal' },
  { label: 'The Maldives', value: 'The Maldives' },
  { label: 'Iran', value: 'Iran' },
  { label: 'Southeast Asia', value: 'Southeast Asia' },
  { label: 'Brunei', value: 'Brunei' },
  { label: 'Cambodia', value: 'Cambodia' },
  { label: 'Indonesia', value: 'Indonesia' },
  { label: 'Laos', value: 'Laos' },
  { label: 'Malaysia', value: 'Malaysia' },
  { label: 'Myanmar', value: 'Myanmar' },
  { label: 'Philippines', value: 'Philippines' },
  { label: 'Singapore', value: 'Singapore' },
  { label: 'Thailand', value: 'Thailand' },
  { label: 'Timor Leste', value: 'Timor Leste' },
  { label: 'Vietnam', value: 'Vietnam' },
  { label: 'Western Asia', value: 'Western Asia' },
  { label: 'Georgia (Country)', value: 'Georgia (Country)' },
  { label: 'Armenia', value: 'Armenia' },
  { label: 'Azerbaijan', value: 'Azerbaijan' },
  { label: 'Turkey', value: 'Turkey' },
  { label: 'Cyprus', value: 'Cyprus' },
  { label: 'Syria', value: 'Syria' },
  { label: 'Lebanon', value: 'Lebanon' },
  { label: 'Israel', value: 'Israel' },
  { label: 'Palestine', value: 'Palestine' },
  { label: 'Jordan', value: 'Jordan' },
  { label: 'Iraq', value: 'Iraq' },
  { label: 'Oman', value: 'Oman' },
  { label: 'Yemen', value: 'Yemen' },
  { label: 'Kuwait', value: 'Kuwait' },
  { label: 'Bahrain', value: 'Bahrain' },
  { label: 'Qatar', value: 'Qatar' },
  { label: 'Saudi Arabia', value: 'Saudi Arabia' },
  { label: 'Europe', value: 'Europe' },
  { label: 'Central and Eastern Europe', value: 'Central and Eastern Europe' },
  { label: 'Albania', value: 'Albania' },
  { label: 'Armenia', value: 'Armenia' },
  { label: 'Azerbaijan', value: 'Azerbaijan' },
  { label: 'Belarus', value: 'Belarus' },
  { label: 'Bosnia and Herzegovina', value: 'Bosnia and Herzegovina' },
  { label: 'Bulgaria', value: 'Bulgaria' },
  { label: 'Czech Republic', value: 'Czech Republic' },
  { label: 'Croatia', value: 'Croatia' },
  { label: 'Georgia (Country)', value: 'Georgia (Country)' },
  { label: 'Hungary', value: 'Hungary' },
  { label: 'Moldova', value: 'Moldova' },
  { label: 'Montenegro', value: 'Montenegro' },
  { label: 'North Macedonia', value: 'North Mecedonia' },
  { label: 'Poland', value: 'Pland' },
  { label: 'Romania', value: 'Romania' },
  { label: 'Russia', value: 'Russia' },
  { label: 'Serbia', value: 'Serbia' },
  { label: 'Slovakia', value: 'Slovakia' },
  { label: 'Slovenia', value: 'Slovenia' },
  { label: 'Ukraine', value: 'Ukraine' },
  { label: 'Northern Europe', value: 'Northern Europe' },
  { label: 'Denmark', value: 'Denmark' },
  { label: 'Estonia', value: 'Estonia' },
  { label: 'Finland', value: 'Finland' },
  { label: 'Iceland', value: 'Iceland' },
  { label: 'Latvia', value: 'Latvia' },
  { label: 'Lithuania', value: 'Lithuania' },
  { label: 'Norway', value: 'Norway' },
  { label: 'Sweden', value: 'Sweden' },
  { label: 'Southern Europe', value: 'Souther Europe' },
  { label: 'Cyprus', value: 'Cyprus' },
  { label: 'Greece', value: 'Greece' },
  { label: 'Vatican City', value: 'Vatican City' },
  { label: 'Italy', value: 'Italy' },
  { label: 'Malta', value: 'Malta' },
  { label: 'Portugal', value: 'Portugal' },
  { label: 'San Marino', value: 'San Marino' },
  { label: 'Spain', value: 'Spain' },
  { label: 'Turkey', value: 'Turkey' },
  { label: 'Western Europe', value: 'Western Europe' },
  { label: 'Andorra', value: 'Andorra' },
  { label: 'Austria', value: 'Austria' },
  { label: 'Belgium', value: 'Belgium' },
  { label: 'France', value: 'France' },
  { label: 'Germany', value: 'Germany' },
  { label: 'Ireland', value: 'Ireland' },
  { label: 'Liechtenstein', value: 'Liechtenstein' },
  { label: 'Luxembourg', value: 'Luxembourg' },
  { label: 'Monaco', value: 'Monaco' },
  { label: 'Netherlands', value: 'Netherlands' },
  { label: 'Switzerland', value: 'Switzerland' },
  { label: 'United Kingdom', value: 'United Kingdom' },
  { label: 'North America', value: 'North America' },
  { label: 'Antigua And Barbuda', value: 'Antigua And Barbuda' },
  { label: 'Bahamas', value: 'Bahamas' },
  { label: 'Barbados', value: 'Barbados' },
  { label: 'Belize', value: 'Belize' },
  { label: 'Canada', value: 'Canada' },
  { label: 'Costa Rica', value: 'Costa Rica' },
  { label: 'Cuba', value: 'Cuba' },
  { label: 'Dominica', value: 'Dominica' },
  { label: 'Dominican Republic', value: 'Dominican Republic' },
  { label: 'El Salvador', value: 'El Salvador' },
  { label: 'Grenada', value: 'Grenada' },
  { label: 'Guatemala', value: 'Guatemala' },
  { label: 'Haiti', value: 'Haiti' },
  { label: 'Honduras', value: 'Honduras' },
  { label: 'Jamaica', value: 'Jamaica' },
  { label: 'Mexico', value: 'Mexica' },
  { label: 'Nicaragua', value: 'Nicaragua' },
  { label: 'Panama', value: 'Panama' },
  { label: 'Saint Kitts And Nevis', value: 'Saint Kitts And Nevis' },
  { label: 'Saint Lucia', value: 'Saint Lucia' },
  { label: 'Saint Vincent And the Grenadines', value: 'Saint Vincent And the Grenadines' },
  { label: 'Trinidad And Tobago', value: 'Trinidad And Tobago' },
  { label: 'United States', value: 'United States' },
  { label: 'Alabama', value: 'Alabama' },
  { label: 'Alaska', value: 'Alaska' },
  { label: 'Arizona', value: 'Arizona' },
  { label: 'Arkansas', value: 'Arkansas' },
  { label: 'California', value: 'California' },
  { label: 'Colorado', value: 'Colorado' },
  { label: 'Connecticut', value: 'Connecticut' },
  { label: 'Delaware', value: 'Delaware' },
  { label: 'Florida', value: 'Florida' },
  { label: 'Georgia', value: 'Georgia' },
  { label: 'Hawaii', value: 'Hawaii' },
  { label: 'Idaho', value: 'Idaho' },
  { label: 'Illinois', value: 'Illinois' },
  { label: 'Indiana', value: 'Indiana' },
  { label: 'Iowa', value: 'Iowa' },
  { label: 'Kansas', value: 'Kansas' },
  { label: 'Kentucky', value: 'Kentucky' },
  { label: 'Louisiana', value: 'Louisiana' },
  { label: 'Maine', value: 'Maine' },
  { label: 'Maryland', value: 'Maryland' },
  { label: 'Massachusetts', value: 'Massachusetts' },
  { label: 'Michigan', value: 'Michigan' },
  { label: 'Minnesota', value: 'Minnesota' },
  { label: 'Mississippi', value: 'Mississippi' },
  { label: 'Missouri', value: 'Missouri' },
  { label: 'Montana', value: 'Montana' },
  { label: 'Nebraska', value: 'Nebraska' },
  { label: 'Nevada', value: 'Nevada' },
  { label: 'New Hampshire', value: 'New Hampshire' },
  { label: 'New Jersey', value: 'New Jersey' },
  { label: 'New Mexico', value: 'New Mexico' },
  { label: 'New York', value: 'New York' },
  { label: 'North Carolina', value: 'North Carolina' },
  { label: 'North Dakota', value: 'North Dakota' },
  { label: 'Ohio', value: 'Ohio' },
  { label: 'Oklahoma', value: 'Oklahoma' },
  { label: 'Oregon', value: 'Oregon' },
  { label: 'Pennsylvania', value: 'Pennsylvania' },
  { label: 'Rhode Island', value: 'Rhode Island' },
  { label: 'South Carolina', value: 'South Carolina' },
  { label: 'South Dakota', value: 'South Dakota' },
  { label: 'Tennessee', value: 'Tenessee' },
  { label: 'Texas', value: 'Texas' },
  { label: 'Utah', value: 'Utah' },
  { label: 'Vermont', value: 'Vermont' },
  { label: 'Virginia', value: 'Virginia' },
  { label: 'Washington', value: 'Washington' },
  { label: 'Washington D.C.', value: 'Washington D.C.' },
  { label: 'West Virginia', value: 'West Virginia' },
  { label: 'Wisconsin', value: 'Wisconsin' },
  { label: 'Wyoming', value: 'Wyoming' },
  { label: 'Oceania', value: 'Oceania' },
  { label: 'Australia', value: 'Australia' },
  { label: 'Fiji', value: 'Fiji' },
  { label: 'Kiribati', value: 'Kiribati' },
  { label: 'Marshall Islands', value: 'Marshall Islands' },
  { label: 'Micronesia', value: 'Micronesia' },
  { label: 'Nauru', value: 'Nauru' },
  { label: 'New Zealand', value: 'New Zealand' },
  { label: 'Palau', value: 'Palau' },
  { label: 'Papua New Guinea', value: 'Papua New Guinea' },
  { label: 'Samoa', value: 'Samoa' },
  { label: 'Solomon Islands', value: 'Solomon Islands' },
  { label: 'Tonga', value: 'Tonga' },
  { label: 'Tuvalu', value: 'Tuvalu' },
  { label: 'Vanuatu', value: 'Vanuatu' },
  { label: 'South America', value: 'South America' },
  { label: 'Argentina', value: 'Argentina' },
  { label: 'Bolivia', value: 'Bolivia' },
  { label: 'Brazil', value: 'Brazil' },
  { label: 'Chile', value: 'Chile' },
  { label: 'Colombia', value: 'Colombia' },
  { label: 'Ecuador', value: 'Ecuador' },
  { label: 'Guyana', value: 'Guyana' },
  { label: 'Paraguay', value: 'Paraguay' },
  { label: 'Peru', value: 'Peru' },
  { label: 'Suriname', value: 'Suriname' },
  { label: 'Uruguay', value: 'Uruguay' },
  { label: 'Venezuela', value: 'Venezuela' }
]
