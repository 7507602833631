import { makeStyles } from '@material-ui/core/styles'
import { IconType, Icon } from 'library/materialUI/Icon'
import { useContext } from 'react'
import { SessionContext } from 'session-context'
import { HelpCenterContext } from './HelpCenterContext'

const useStyles = makeStyles(theme => ({
  helpCenterCard: {
    display: 'grid',
    gridGap: 15,
    gridTemplateColumns: '50px auto 30px',
    padding: 10,
    border: '1px solid #0000101A',
    borderRadius: 10,
    marginTop: 15,
    alignItems: 'center',
    width: 330,
    cursor: 'pointer'
  },
  iconDiv: {
    backgroundColor: '#E2E2E2',
    width: 50,
    borderRadius: 10,
    height: 80,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  iconCircle: {
    backgroundColor: '#343434',
    borderRadius: 100,
    height: 40,
    width: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  title: {
    font: 'normal normal 600 18px/27px Poppins',
    color: '#000000BF'
  },
  subtitle: {
    font: 'normal normal normal 12px/18px Poppins',
    color: '#000000BF'
  },
  disabled: {
    cursor: 'not-allowed !important',
    '& > div:first-of-type': {
      backgroundColor: 'darkgrey !important'
    },
    '& > div:first-of-type > div:first-of-type': {
      backgroundColor: 'lightgrey !important'
    }
  }
}))

interface BaseCard {
  title: string
  subtitle?: string
  icon: IconType
}

interface LinkCard extends BaseCard {
  link: string
  action?: undefined
  actionIcon?: undefined
  isClosed?: boolean
}

interface ActionCard extends BaseCard {
  action: () => void
  actionIcon?: IconType
  link?: undefined
}

export type THelpCenterCard = LinkCard | ActionCard

export function HelpCenterIcon ({ icon, size }: {icon: IconType, size?: 'small'}) {
  const classes = useStyles()
  const style = size === 'small' ? { height: 50 } : {}
  return (
    <div className={classes.iconDiv} style={style}>
      <div className={classes.iconCircle}>
        <Icon icon={icon} color='white' />
      </div>
    </div>
  )
}

function ActionIcon ({ icon }: { icon?: IconType }) {
  if (icon) {
    return (
      <div>
        <Icon icon={icon} />
      </div>
    )
  } else {
    return (<></>)
  }
}

type CardProps = {
  card: THelpCenterCard
  size?: 'small'
}
export function HelpCenterCard (props: CardProps) {
  const classes = useStyles()
  const { navigateForward } = useContext(HelpCenterContext)
  const { user } = useContext(SessionContext)
  const { title, subtitle, icon, actionIcon, action, link } = props.card

  const onClick = link ? () => navigateForward(link) : action

  const actionIndicator = link ? 'rightArrow' : actionIcon
  const containerDivProps = user.attributes.assist_login && title === 'Support Chat' ? { className: `${classes.disabled} ${classes.helpCenterCard}` } : { className: classes.helpCenterCard, onClick: onClick }
  return (
    <div {...containerDivProps}>
      <HelpCenterIcon
        icon={icon}
        size={props.size}
      />
      <div>
        <div className={classes.title}>{title}</div>
        <div className={classes.subtitle}>{subtitle}</div>
      </div>
      <ActionIcon icon={actionIndicator} />
    </div>
  )
}
