/* eslint-disable @typescript-eslint/naming-convention */
import { useState, useEffect } from 'react'
import ActionsMenu from 'cf-components/ActionsMenu'
import { makeStyles } from '@material-ui/core/styles'
import { ReactComponent as FilterIcon } from 'img/filterIcon.svg'
import SearchIcon from '@material-ui/icons/Search'
import SortIcon from '@material-ui/icons/Sort'
import ExportIcon from '@material-ui/icons/CloudDownloadOutlined'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import ForwardArrowIcon from '@material-ui/icons/ArrowForwardIos'
import BackArrowIcon from '@material-ui/icons/ArrowBackIos'
import SortAscendingIcon from '@material-ui/icons/ArrowUpward'
import SortDescendingIcon from '@material-ui/icons/ArrowDownward'
import { Grow, Tooltip, Popover, MenuItem, Chip, Button, Checkbox, ListItemText } from '@material-ui/core'
import StyledSwitch from 'components/StyledSwitch'
import { DateRangeMenu, startingDateRange, last30DaysRange } from './DateRangeMenu'
import { getTeams } from 'api/teams'
import dateFormat from 'dateformat'
import { getTrafficCampaigns, getTrafficMediums } from 'api/reports'
import TreeMultiSelect from 'cf-components/TreeMultiSelect'

const useStyles = makeStyles(theme => ({
  filterBar: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px 0px 10px 20px',
    minHeight: 35,
    width: 'calc(100% - 30px)'
  },
  noPadding: {
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: 35,
    width: '100%'
  },
  filterIcons: {
    display: 'flex',
    alignItems: 'center'
  },
  iconNoColor: {
    marginRight: 19,
    cursor: 'pointer',
    '&:hover': {
      opacity: '0.8'
    }
  },
  icon: {
    marginRight: 19,
    fill: 'rgba(0, 0, 0, 0.54)',
    cursor: 'pointer',
    '&:hover': {
      fill: theme.palette.primary.main
    }
  },
  filterIconWithColor: {
    marginRight: 19,
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.8
    }
  },
  filterIcon: {
    marginRight: 15,
    stroke: 'rgba(0, 0, 0, 0.54)',
    cursor: 'pointer',
    '&:hover': {
      stroke: theme.palette.primary.main
    }
  },
  inputRootWithColor: {
    borderRadius: 30,
    color: 'currentColor'
  },
  inputRoot: {
    borderRadius: 30
  },
  inputMargin: {
    paddingTop: 8,
    paddingBottom: 8
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minWidth: 250
  },
  filterMenu: {
    paddingTop: 5,
    paddingBottom: 5
  },
  menuPaper: {
    borderRadius: 10
  },
  subMenuHeader: {
    backgroundColor: '#f8f8f8',
    display: 'flex',
    alignItems: 'center'
  },
  chip: {
    margin: '2px 10px 1px 0px'
  },
  sortMenuItem: {
    width: 250,
    paddingTop: 4,
    paddingBottom: 4,
    fontSize: '0.97rem',
    display: 'flex',
    alignItems: 'center'
  },
  sortIcon: {
    marginLeft: 10,
    fill: '#444'
  },
  controlButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  }
}))

const treeItemStyles = {
  fontSize: '.9rem'
}

type DateRange = {
  startDate: Date
  endDate: Date
}

function convertDateRange (dateRange: DateRange) {
  if (!dateRange) {
    return null
  }
  const currentStart = dateRange.startDate
  const currentEnd = dateRange.endDate
  const days = (currentEnd - currentStart) / 1000 / 60 / 60 / 24
  const priorStart = new Date(currentStart)
  priorStart.setDate(currentStart.getDate() - days)
  const averageStart = new Date(currentEnd)
  averageStart.setDate(currentEnd.getDate() - 4 * days)
  const priorEnd = new Date(currentStart).setDate(currentStart.getDate() - 1)

  return {
    start_date: dateFormat(currentStart, 'yyyy-mm-dd'),
    end_date: dateFormat(currentEnd, 'yyyy-mm-dd'),
    first_period_start: dateFormat(currentStart, 'yyyy-mm-dd'),
    first_period_end: dateFormat(currentEnd, 'yyyy-mm-dd'),
    second_period_start: dateFormat(priorStart, 'yyyy-mm-dd'),
    second_period_end: dateFormat(priorEnd, 'yyyy-mm-dd'),
    third_period_start: dateFormat(averageStart, 'yyyy-mm-dd'),
    third_period_end: dateFormat(currentEnd, 'yyyy-mm-dd')
  }
}

interface ControlButtonsProps {
  handleCancel: any
  handleApply: any
}

function ControlButtons ({ handleCancel, handleApply }: ControlButtonsProps) {
  const classes = useStyles()
  return (
    <div className={classes.controlButtons}>
      <Button
        style={{ margin: '3px 10px' }}
        onClick={handleCancel}
      >
        Cancel
      </Button>
      <Button
        color='primary'
        style={{ margin: '3px 10px' }}
        onClick={handleApply}
      >
        Apply Filter
      </Button>
    </div>
  )
}

interface ArrowIconProps {
  forward?: boolean
  back?: boolean
}

function ArrowIcon ({ forward, back }: ArrowIconProps): JSX.Element {
  if (forward) {
    return (
      <ForwardArrowIcon style={{ fill: 'rgba(0,0,0,0.54)', fontSize: '14px', marginTop: 2 }} />
    )
  } else if (back) {
    return (
      <BackArrowIcon style={{ fill: 'rgba(0,0,0,0.54)', fontSize: '14px', marginRight: 10, marginTop: 2 }} />
    )
  }
  return <></>
}

interface SubMenuHeaderProps {
  label?: string
  subMenu?: string
  handleClose: any
}

function SubMenuHeader ({ label, subMenu, handleClose }: SubMenuHeaderProps) {
  const classes = useStyles()

  if (label) {
    return (
      <MenuItem className={classes.subMenuHeader} onClick={handleClose}>
        <ArrowIcon back />
        <div>{label}</div>
      </MenuItem>
    )
  }

  return (
    <MenuItem className={classes.subMenuHeader} onClick={handleClose}>
      <ArrowIcon back />
      <div>Filter by {subMenu}</div>
    </MenuItem>
  )
}

interface FilterMenuV2Props {
  filterOptions: any
  subMenu: any
  setSubMenu: any
  handleClose: any
}

function FilterMenuV2 (props: FilterMenuV2Props) {
  const classes = useStyles()
  const filterOptions = props.filterOptions
  let subMenu = props.subMenu
  if (filterOptions.filterValues.length === 1) {
    subMenu = filterOptions.filterValues[0].value
  }
  const setSubMenu = props.setSubMenu
  const [currentFilter, setCurrentFilter] = useState<any>([])

  useEffect(() => {
    if (subMenu != null) {
      const filterValue = filterOptions.filterValues.filter((value: any) => value.type === subMenu.toLowerCase())[0].value
      setCurrentFilter(filterValue)
    } else {
      setCurrentFilter([])
    }
  }, [filterOptions, subMenu])

  const handleClose = () => {
    setSubMenu(null)
  }

  const handleCheckboxClick = (value: any) => {
    const currentIDs = currentFilter.map((value: any) => value.id)
    if (currentIDs.includes(value.id)) {
      setCurrentFilter(currentFilter.filter((t: any) => t.id !== value.id))
    } else {
      const newValueList = [...currentFilter]
      newValueList.push(value)
      setCurrentFilter(newValueList)
    }
  }

  if (subMenu == null) {
    return (
      <div className={classes.filterMenu}>
        {filterOptions.filterValues.map((filter: any, index: number) => (
          <MenuItem
            className={classes.menuItem}
            onClick={() => setSubMenu(index)}
            key={index}
          >
            <div>{filter.label}</div>
            <ArrowIcon forward />
          </MenuItem>
        ))}
      </div>
    )
  }
  const filter = filterOptions.filterValues.filter((value: any) => value.type === subMenu.toLowerCase())[0]
  const type = filter.type

  if (type === 'date') {
    return (
      <Grow in>
        <div>
          <SubMenuHeader label={filter.label} handleClose={handleClose} />
          <DateRangeMenu
            dateRange={filter.value}
            setDateRange={filter.setValue}
            handleBack={handleClose}
            handleClose={() => props.handleClose()}
          />
        </div>
      </Grow>
    )
  } else if (type === 'checkbox') {
    const IDs = currentFilter.map((value: any) => value.id)
    return (
      <Grow in>
        <div className={classes.filterMenu}>
          <SubMenuHeader label={filter.label} handleClose={handleClose} />
          {filter.values.map((value: any) => (
            <MenuItem
              className={classes.menuItem}
              key={value.id}
              style={{ paddingTop: 0, paddingBottom: 0 }}
              onClick={() => handleCheckboxClick(value)}
            >
              <Checkbox color='primary' checked={IDs.includes(value.id)} />
              <ListItemText primary={value.label} />
            </MenuItem>
          ))}
          <ControlButtons
            handleCancel={handleClose}
            handleApply={() => {
              filter.setValue(currentFilter)
            }}
          />
        </div>
      </Grow>
    )
  }
  return <></>
}

interface FilterMenuProps {
  filterOptions: any
  subMenu: any
  setSubMenu: any
  handleClose: any
  teams: any
  trafficSources: any
  setDateChange: any
}

function FilterMenu ({ filterOptions, subMenu, setSubMenu, handleClose, teams, trafficSources, setDateChange }: FilterMenuProps) {
  const classes = useStyles()
  if (filterOptions.filterValues.length === 1) {
    subMenu = filterOptions.filterValues[0].value
  }
  const [teamFilter, setTeamFilter] = useState(filterOptions.teamFilter)
  const [sourceFilter, setSourceFilter] = useState(filterOptions.sourceFilter || [])

  const handleExit = () => {
    setSubMenu(null)
    handleClose()
  }

  const handleTeamClick = (team: any) => {
    const currentIDs = teamFilter.map((t: any) => t.id)
    if (currentIDs.includes(team.id)) {
      setTeamFilter(teamFilter.filter((t: any) => t.id !== team.id))
    } else {
      const newTeamList = [...teamFilter]
      newTeamList.push({ id: team.id, name: team.attributes.name })
      setTeamFilter(newTeamList)
    }
  }

  if (filterOptions.type === 'V2') {
    return (
      <FilterMenuV2
        filterOptions={filterOptions}
        subMenu={subMenu}
        setSubMenu={setSubMenu}
        handleClose={handleClose}
      />
    )
  }

  if (subMenu === 'Date') {
    return (
      <Grow in>
        <div>
          <SubMenuHeader subMenu={subMenu} handleClose={handleClose} />
          <DateRangeMenu
            dateRange={filterOptions.dateRange}
            setDateRange={filterOptions.setDateRange}
            setDateChange={setDateChange}
            handleBack={handleClose}
            handleClose={() => handleClose()}
          />
        </div>
      </Grow>
    )
  } else if (subMenu === 'Team') {
    const teamIDs = teamFilter.map((t: any) => t.id)
    return (
      <Grow in>
        <div className={classes.filterMenu}>
          <SubMenuHeader subMenu={subMenu} handleClose={handleClose} />
          {teams && teams.map((team: any) => (
            <MenuItem
              className={classes.menuItem}
              key={team.id}
              style={{ paddingTop: 0, paddingBottom: 0 }}
              onClick={() => handleTeamClick(team)}
            >
              <Checkbox color='primary' checked={teamIDs.includes(team.id)} />
              <ListItemText primary={team.attributes.name} />
            </MenuItem>
          ))}
          <ControlButtons
            handleCancel={handleExit}
            handleApply={() => {
              filterOptions.setTeamFilter(teamFilter)
              handleExit()
            }}
          />
        </div>
      </Grow>
    )
  } else if (subMenu === 'TrafficSource') {
    return (
      <Grow in>
        <div className={classes.filterMenu}>
          <TreeMultiSelect
            selected={sourceFilter}
            setSelected={setSourceFilter}
            items={trafficSources}
            styles={treeItemStyles}
          />
          <ControlButtons
            handleCancel={handleClose}
            handleApply={() => {
              filterOptions.setSourceFilter(sourceFilter)
              handleClose()
            }}
          />
        </div>
      </Grow>
    )
  }

  return (
    <div className={classes.filterMenu}>
      {filterOptions.filterValues.map((filter: any, index: number) => (
        <MenuItem
          className={classes.menuItem}
          onClick={() => setSubMenu(filter.value)}
          key={index}
        >
          <div>{filter.label}</div>
          {filter.toggle
            ? (
              <StyledSwitch
                checked={filter.value}
                toggle={filter.toggle}
              />)
            : <ArrowIcon forward />}
        </MenuItem>
      ))}
    </div>
  )
}

interface FilterButtonProps {
  filterOptions: any
  state: any
  setState: any
  color?: string
}

function FilterButton ({ filterOptions, state, setState, color }: FilterButtonProps) {
  const classes = useStyles()
  const filterValues = filterOptions?.filterValues
  const [teams, setTeams] = useState(null)
  const [trafficSources, setTrafficSources] = useState<any>(null)
  const [dateChange, setDateChange] = useState(true)
  const start = filterOptions.dateRange ? dateFormat(filterOptions.dateRange.startDate, 'yyyy-mm-dd') : ''
  const end = filterOptions.dateRange ? dateFormat(filterOptions.dateRange.endDate, 'yyyy-mm-dd') : ''

  const handleClick = (event: any) => {
    setState({
      ...state,
      anchorEl: event.currentTarget
    })
  }

  const handleClose = () => {
    setState({
      anchorEl: null,
      subMenu: null
    })
  }

  useEffect(() => {
    if (!teams) {
      if (filterValues && filterValues.map((filter: any) => filter.value).includes('Team')) {
        getTeams()
          .then(response =>
            setTeams(response.data))
      }
    }
    if (dateChange) {
      setDateChange(false)
      if (filterValues && filterValues.map((filter: any) => filter.value).includes('TrafficSource')) {
        const sourceDict = [
          { value: 'Organic Search' },
          { value: 'Social Media', children: [{ value: 'Facebook' }, { value: 'Instagram' }, { value: 'LinkedIn' }, { value: 'TikTok' }, { value: 'Twitter' }] },
          { value: 'Paid Search', children: [{ value: 'Google Ad' }, { value: 'LinkedIn Ad' }] },
          { value: 'Campaign', children: [] },
          { value: 'Other Mediums', children: [] },
          { value: 'Direct Traffic' }
        ]
        getTrafficCampaigns({ start, end })
          .then(res => {
            sourceDict[3].children = res.data.map((data: any) => {
              return { label: data.attributes.traffic_campaign, value: data.attributes.traffic_campaign + '*' }
            })
          })
        getTrafficMediums({ start, end })
          .then(res => {
            sourceDict[4].children = res.data.map((data: any) => {
              return { value: data.attributes.traffic_medium }
            })
          })
        setTrafficSources(sourceDict)
      }
    }
  }, [filterValues, teams, start, end, dateChange])

  return (
    <>
      <Tooltip title='Filter'>
        <FilterIcon
          className={color ? classes.filterIconWithColor : classes.filterIcon}
          onClick={handleClick}
          style={{ stroke: color || undefined }}
        />
      </Tooltip>
      {state.anchorEl &&
        <Popover
          open={Boolean(state.anchorEl)}
          anchorEl={state.anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          style={{ marginTop: 10 }}
          classes={{ paper: classes.menuPaper }}
        >
          {filterOptions.customFilterMenu
            ? (
              <filterOptions.customFilterMenu
                handleClose={handleClose}
                {...filterOptions.menuProps}
              />)
            : (
              <FilterMenu
                subMenu={state.subMenu}
                setSubMenu={(val: any) => setState({ ...state, subMenu: val })}
                handleClose={handleClose}
                filterOptions={filterOptions}
                teams={teams}
                trafficSources={trafficSources}
                setDateChange={setDateChange}
              />)}
        </Popover>}
    </>
  )
}

interface SortMenuProps {
  sortOptions: any
}

function SortMenu ({ sortOptions }: SortMenuProps) {
  const classes = useStyles()
  const setSortCriteria = sortOptions.setSortCriteria
  const setSortAscending = sortOptions.setSortAscending
  const sortValues = sortOptions.sortValues
  const SortIcon = sortOptions.sortAscending ? SortAscendingIcon : SortDescendingIcon

  const handleClick = (value: any) => {
    if (value === sortOptions.sortCriteria) {
      setSortAscending(!sortOptions.sortAscending)
    } else {
      setSortCriteria(value)
    }
  }

  return (
    <div className={classes.filterMenu}>
      {sortValues.filter((option: any) => option.label).map((option: any, index: number) => (
        <MenuItem
          className={classes.sortMenuItem}
          key={index}
          onClick={() => handleClick(option.id)}
        >
          {option.label}
          {option.id === sortOptions.sortCriteria
            ? <SortIcon fontSize='small' className={classes.sortIcon} /> : <></>}
        </MenuItem>
      ))}
    </div>
  )
}

interface SortButtonProps {
  sortOptions: any
}

function SortButton ({ sortOptions }: SortButtonProps) {
  const classes = useStyles()
  const [state, setState] = useState({
    anchorEl: null,
    subMenu: null
  })

  const handleClick = (event: any) => {
    setState({
      ...state,
      anchorEl: event.currentTarget
    })
  }

  const handleClose = () => {
    setState({
      anchorEl: null,
      subMenu: null
    })
  }

  return (
    <>
      <Tooltip title='Sort'>
        <SortIcon
          className={classes.icon}
          onClick={handleClick}
          style={{ fill: state.anchorEl ? '#8e5ae2' : undefined }}
        />
      </Tooltip>
      {state.anchorEl &&
        <Popover
          open={Boolean(state.anchorEl)}
          anchorEl={state.anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          style={{ marginTop: 10 }}
          classes={{ paper: classes.menuPaper }}
        >
          <SortMenu
            sortOptions={sortOptions}
          />
        </Popover>}
    </>
  )
}

interface CollapsibleSearchBarProps {
  expanded?: boolean
  setExpanded: (expanded: boolean) => void
  search?: string
  setSearch: (search: string) => void
  searchBarWidth?: number
  autoFocus?: boolean
  color?: string
}

function CollapsibleSearchBar ({ expanded, setExpanded, search, setSearch, searchBarWidth, autoFocus, color }: CollapsibleSearchBarProps) {
  const classes = useStyles()

  function handleClickAway (_: any) {
    if (!search) {
      setExpanded(false)
    }
  }

  function handleChange (event: any) {
    setSearch(event.target.value)
  }

  if (expanded) {
    return (
      <ClickAwayListener onClickAway={handleClickAway}>
        <Grow in={expanded}>
          <TextField
            value={search}
            onChange={handleChange}
            autoFocus={autoFocus}
            margin='dense'
            variant='outlined'
            InputProps={{
              endAdornment: <InputAdornment position='end'><SearchIcon style={{ fill: color || '#8e5ae2' }} /></InputAdornment>,
              classes: {
                root: color ? classes.inputRootWithColor : classes.inputRoot,
                inputMarginDense: classes.inputMargin
              }
            }}
            style={{ margin: 0, marginRight: 5, width: searchBarWidth || 200, color: color || undefined }}
          />
        </Grow>
      </ClickAwayListener>
    )
  }

  return (
    <Tooltip title='Search'>
      <SearchIcon
        className={color ? classes.iconNoColor : classes.icon}
        onClick={() => setExpanded(true)}
        style={{ fill: color || undefined }}
      />
    </Tooltip>
  )
}

interface ActiveFiltersProps {
  filterOptions: any
  filterState: any
  setFilterState: any
}

function ActiveFilters ({ filterOptions, filterState, setFilterState }: ActiveFiltersProps) {
  const classes = useStyles()
  const filters = filterOptions
  const filterValues = filters.filterValues

  if (!filterValues) {
    return (
      <></>
    )
  }

  function handleClick (event: any, menu: any) {
    setFilterState({
      ...filterState,
      anchorEl: event.currentTarget,
      subMenu: menu
    })
  }

  let dateLabel = null
  if (filters.dateRange) {
    const startDate = dateFormat(filters.dateRange.startDate, 'mm/dd/yyyy')
    const endDate = dateFormat(filters.dateRange.endDate, 'mm/dd/yyyy')
    dateLabel = startDate + ' - ' + endDate
  }
  let dateFilter = filterValues.filter((value: any) => value.type === 'date')
  if (dateFilter.length) {
    dateFilter = dateFilter[0]
    const startDate = dateFormat(dateFilter.value.startDate, 'mm/dd/yyyy')
    const endDate = dateFormat(dateFilter.value.endDate, 'mm/dd/yyyy')
    dateLabel = startDate + ' - ' + endDate
  }

  let teamLabel = null
  if (filters.teamFilter) {
    if (filters.teamFilter.length > 1) {
      teamLabel = filters.teamFilter.length + ' teams'
    } else if (filters.teamFilter.length === 1) {
      teamLabel = filters.teamFilter[0].name
    }
  }

  let sourceLabel = null
  if (filters.sourceFilter) {
    if (filters.sourceFilter.length > 1) {
      sourceLabel = filters.sourceFilter.join(', ')
    } else if (filters.sourceFilter.length === 1) {
      sourceLabel = filters.sourceFilter[0]
    }
  }

  const chips = filterValues.filter((value: any) => Boolean(value.kind)).map((filter: any) => {
    let label = ''
    const onDelete = () => filter.setValue([])
    if (filter.value.length > 1) {
      label = filter.value.length + ' ' + filter.kind
    } else if (filter.value.length === 1) {
      label = filter.value[0].label
    }
    return ({
      label: label,
      handleDelete: onDelete
    })
  })

  return (
    <div>
      {dateLabel &&
        <Chip
          label={dateLabel}
          variant='outlined'
          color='primary'
          className={classes.chip}
          onClick={(e) => handleClick(e, 'Date')}
        />}
      {teamLabel &&
        <Chip
          label={teamLabel}
          variant='outlined'
          color='primary'
          className={classes.chip}
          onDelete={() => filters.setTeamFilter([])}
          onClick={(e) => handleClick(e, 'Team')}
        />}
      {sourceLabel &&
        <Chip
          label={sourceLabel}
          variant='outlined'
          color='primary'
          className={classes.chip}
          onDelete={() => filters.setSourceFilter([])}
          onClick={(e) => handleClick(e, 'TrafficSource')}
        />}
      {chips.filter((chip: any) => chip.label).map((chip: any, index: number) => (
        <Chip
          key={index}
          label={chip.label}
          variant='outlined'
          color='primary'
          className={classes.chip}
          onDelete={chip.handleDelete}
        />
      ))}
    </div>
  )
}

interface FilterBarProps {
  filterOptions: any
  setSearch?: any
  search?: string
  sortOptions?: any
  handleExport?: any
  exportActions?: any
  exportLabel?: string
  alwaysExpanded?: boolean
  noPadding?: boolean
  searchBarWidth?: number
  color?: string
}

function FilterBar ({ filterOptions, setSearch, search, sortOptions, handleExport, exportActions, exportLabel, alwaysExpanded, noPadding, searchBarWidth, color }: FilterBarProps) {
  const classes = useStyles()
  const [expanded, setExpanded] = useState(alwaysExpanded)
  const searchControls = {
    expanded,
    setExpanded: alwaysExpanded ? () => null : setExpanded
  }

  const [filterState, setFilterState] = useState({
    anchorEl: null,
    subMenu: null
  })

  return (
    <div className={noPadding ? classes.noPadding : classes.filterBar}>
      <div>
        {filterOptions &&
          <ActiveFilters
            filterOptions={filterOptions}
            filterState={filterState}
            setFilterState={setFilterState}
          />}
      </div>
      <div className={classes.filterIcons}>
        {setSearch &&
          <CollapsibleSearchBar
            searchBarWidth={searchBarWidth}
            autoFocus={!alwaysExpanded}
            expanded={searchControls.expanded}
            setExpanded={searchControls.setExpanded}
            search={search}
            setSearch={setSearch}
            color={color}
          />}
        {sortOptions &&
          <SortButton
            sortOptions={sortOptions}
          />}
        {handleExport &&
          <Tooltip title={exportLabel || 'Export to CSV'}>
            <ExportIcon className={classes.icon} onClick={handleExport} />
          </Tooltip>}
        {exportActions &&
          <Tooltip title={exportLabel || 'Export to CSV'}>
            <ActionsMenu actions={exportActions} icon={<ExportIcon />} />
          </Tooltip>}
        {filterOptions &&
          <FilterButton
            filterOptions={filterOptions}
            state={filterState}
            setState={setFilterState}
            color={color}
          />}
      </div>
    </div>
  )
}

export { FilterBar, startingDateRange, convertDateRange, ControlButtons, last30DaysRange, SubMenuHeader }
