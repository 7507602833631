import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import CircleLoader from 'react-spinners/CircleLoader'
import Loader from 'library/loading/Loader'

const useStyles = makeStyles(theme => ({
  loadingDiv: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    backgroundColor: 'white',
    zIndex: 10,
    display: 'grid',
    gridTemplateAreas:
    `"loader loading-sidebar"
    "entry loading-sidebar"`,
    gridTemplateColumns: 'auto minmax(290px, min(340px, 25%))',
    gridTemplateRows: 'calc(100% - 120px) 120px'
  },
  loader: {
    gridArea: 'loader',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative'
  },
  sidebar: {
    gridArea: 'loading-sidebar',
    backgroundColor: '#F4F2F7'
  },
  entry: {
    gridArea: 'entry',
    height: 'calc(100% - 30px)',
    padding: '10px 15px 20px 15px'
  },
  entryBox: {
    height: 'calc(100% - 2px)',
    borderRadius: 10,
    border: '1px solid #ddd'
  },
  topDivider: {
    position: 'absolute',
    top: 78,
    marginLeft: 20,
    marginRight: 20,
    height: 1,
    backgroundColor: '#ddd',
    width: 'calc(100% - 40px)'
  },
  initialLoadingScreen: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    backgroundColor: '#F5F5F5',
    zIndex: 10000
  },
  initialLoader: {
    display: 'flex',
    justifyContent: 'center',
    marginLeft: 15
  },
  message: {
    marginBottom: 30,
    fontSize: '25px',
    color: '#8E5AE2'
  }
}))

export function InitialLoadingScreen (props) {
  const classes = useStyles()
  const visibility = props.loading ? 'visible' : 'hidden'

  return (
    <div className={classes.initialLoadingScreen} style={{ visibility: visibility }}>
      <div>
        <div className={classes.message}>
          Initializing Chat
        </div>
        <div className={classes.initialLoader}>
          <Loader type='spinner' />
        </div>
      </div>
    </div>
  )
}

export function ActiveConversationLoading (props) {
  const classes = useStyles()

  if (props.loading) {
    return (
      <div className={classes.loadingDiv}>
        <div className={classes.loader}>
          <div className={classes.topDivider} />
          <CircleLoader
            color='#975ce6'
            size={60}
          />
        </div>
        <div className={classes.entry}>
          <div className={classes.entryBox} />
        </div>
        <div className={classes.sidebar} />
      </div>
    )
  }
  return (<></>)
}
