const defaultSettings = {
  type: 'chat_settings',
  attributes: {
    availabilities: [
      {
        days: 'Weekdays',
        start_time: '09:00:00',
        end_time: '17:00:00'
      }
    ],
    chat_setting: 'manual',
    online: false,
    offline_when_unavailable: true,
    in_office_greeting: "Hi. Let's chat",
    out_office_greeting: "We're at home. Leave us a message",
    send_type: 'bot',
    send_from_user_id: null,
    prompt_timer: 5,
    bot_name: 'Bot',
    bot_icon_url: '',
    bot_icon_primary_color: '0693E3',
    bot_icon_accent_color: '2F2F2F',
    bot_icon_id: 1,
    prompt_icon_url: '',
    prompt_icon_id: 1,
    primary_color: '0693E3',
    contrast_color: 'FFFFFF',
    button_color: '0693E3',
    button_color_same_as_primary: true,
    chat_size: 'default',
    branding: true,
    fallback_message: 'Looks like no one is available to chat. Leave us your name and email and we will get back to you',
    fallback_timer: 120,
    enable_default_greeting: true,
    hidden_when_unavailable: false,
    hidden_until_bot_fires: false,
    offline_when_team_unavailable: true,
    blocked_pages: ''
  },
  relationships: {}
}

function getReducer ({ availabiltyTypeColumnName, setDisabled }) {
  const reducer = (state, action) => {
    const currentAvailability =
      state ? state.attributes.availabilities : []
    const newTime = {
      days: 'Weekdays',
      start_time: '09:00:00',
      end_time: '17:00:00'
    }
    let newAvailability
    if (action.type !== 'initialize') {
      setDisabled(false)
    }
    switch (action.type) {
      case 'initialize':
        return action.data
      case 'changeAvailabilityType':
        return {
          ...state,
          attributes: { ...state.attributes, [availabiltyTypeColumnName]: action.value }
        }
      case 'updateSetting':
        return {
          ...state,
          attributes: { ...state.attributes, [action.name]: action.value }
        }
      case 'toggleSetting':
        return {
          ...state,
          attributes: {
            ...state.attributes,
            [action.name]: !state.attributes[action.name]
          }
        }
      case 'addTime':
        return {
          ...state,
          attributes: {
            ...state.attributes,
            availabilities: currentAvailability.concat([newTime])
          }
        }
      case 'removeTime':
        return {
          ...state,
          attributes: {
            ...state.attributes,
            availabilities: currentAvailability.filter(
              (_, i) => i !== action.index
            )
          }
        }
      case 'updateTime':
        newAvailability = JSON.parse(JSON.stringify(currentAvailability))
        newAvailability[action.index][action.name] = action.value
        return {
          ...state,
          attributes: { ...state.attributes, availabilities: newAvailability }
        }
      default:
        throw new Error()
    }
  }
  return reducer
}

export { defaultSettings, getReducer }
