import { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { HelpCenterContext } from './HelpCenterContext'
import { capitalCase } from 'change-case'

const useStyles = makeStyles(theme => ({
  breadcrumbs: {
    display: 'flex'
  },
  link: {
    cursor: 'pointer',
    font: '12px/25px Poppins',
    color: '#000000',
    marginLeft: 3
  }
}))

export function Breadcrumbs () {
  const { pathLinks, navigate } = useContext(HelpCenterContext)
  const classes = useStyles()

  return (
    <div className={classes.breadcrumbs}>
      {pathLinks.map((link, index) => {
        const lastOne = index === pathLinks.length - 1
        const styledLink = capitalCase(link)
        const name = lastOne ? styledLink : styledLink + ' > '
        return (
          <div
            key={index}
            className={classes.link}
            style={{ opacity: lastOne ? 1 : 0.5 }}
            onClick={() => navigate(pathLinks.slice(0, index + 1))}
          >
            {name}
          </div>
        )
      })}
    </div>
  )
}
