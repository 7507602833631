import { makeStyles } from '@material-ui/core/styles'
import Form from 'classes/forms'
import { Divider, Radio, Button, Typography, Icon } from 'library/materialUI'
import Tooltip from '@material-ui/core/Tooltip'
import InfoIcon from '@material-ui/icons/Info'
import Alert from '@material-ui/lab/Alert'
import { RichTextEditor } from 'library/richText/RichTextEditor'
import TextField from '@material-ui/core/TextField'
import Picker from 'library/Picker'
import Checkbox from '@material-ui/core/Checkbox'
import { useMemo, useState } from 'react'
import { GetEnabledIntegrations } from 'helpers/integrations'
import { Select, SelectOption } from 'cf-components/material-wrappers/MaterialComponents'
import { Modal } from 'library/Modal'
import LeadScoreSelect from './LeadScoreSelector'
import CFPicker from 'cf-components/Pickers'

const useStyles = makeStyles(theme => ({
  settingsContainer: {
    margin: '20px',
    border: '1px solid #D2BEF4',
    borderRadius: '10px',
    backgroundColor: '#FFFFFF',
    padding: '10px',
    width: '70vw'
  },
  settingsTitle: {
    margin: '10px',
    marginBottom: '25px',
    fontSize: '16px',
    fontWeight: 'bold'
  },
  sectionSubtitle: {
    color: '#808080',
    fontFamily: 'poppins',
    fontSize: '12px',
    marginTop: '5px',
    marginBottom: '5px'
  },
  sectionTitle: {
    fontSize: '14px',
    fontWeight: 'bold',
    margin: '10px',
    color: '#000000BF'
  },
  setting: {
    margin: '10px'
  },
  input: {
    width: '450px'
  },
  integrationInput: {
    width: '600px',
    display: 'flex'
  },
  checkboxDiv: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  checkboxLabel: {
    color: '#343434',
    fontFamily: 'poppins',
    fontSize: '14px',
    fontWeight: 'bold'
  },
  leadScoreContainer: {
    width: '80%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '10px'
  },
  radioSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginTop: '10px',
    marginBottom: '10px',
    padding: '20px',
    borderRadius: '5px',
    border: '1px solid',
    width: '600px'
  },
  integrationButton: {
    display: 'flex',
    alignItems: 'start',
    border: 'none',
    color: theme.palette.primary.alt,
    textTransform: 'lowercase',
    '&:disabled': {
      border: 'none'
    }
  }
}))

interface RemoveChatModalProps {
  open: boolean,
  onClose: () => void
  handleCanSave: () => void
}

function RemoveChatModal ({ open, onClose, handleCanSave }: RemoveChatModalProps): JSX.Element {
  return (
    <Modal
      size='xs'
      open={open}
      onHide={onClose}
      title='Remove live chat option?'
      handleSave={handleCanSave}
      closeBtn
      noFooter
      noDividers
    >
      <div>
        Live chat is not an option for follow-up actions when a form is being used on a shared device. Remove that option from your form?
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
        <Button variant='text' color='primary' onClick={onClose} style={{ marginRight: '10px' }}>
          Cancel
        </Button>
        <Button variant='contained' color='primary' onClick={handleCanSave}>
          Remove Live Chat
        </Button>
      </div>
    </Modal>
  )
}

interface FormSettingsPageProps {
  form: Form
  dispatch: any
  setCanSave: (value: boolean) => void
}

export default function FormSettingsPage ({ form, dispatch, setCanSave }: FormSettingsPageProps) {
  const classes = useStyles()
  const [enabledIntegrations, setEnabledIntegrations] = useState({
    salesforce: false,
    marketo: false,
    hubspot: false,
    sendinblue: false,
    eloqua: false
  })
  const [removeChatModal, setRemoveChatModal] = useState(false)
  const [addDelay, setAddDelay] = useState(form.emailDelay ? form.emailDelay > 0 : false)
  const [selectedDelay, setSelectedDelay] = useState(form.emailDelay || 0)
  const [integrations, setIntegrations] = useState<Record<string, Array<string>>>(form.integrationsV2 || {})
  const useCriteria = form.integrationCriteria === 'segments'

  const emailModes = [
    { value: 'single', label: 'Notify a single agent' },
    { value: 'multi', label: 'Notify a group of agents' },
    { value: 'team', label: 'Notify a team' },
    { value: 'owner', label: 'Notify account owner' }
  ]
  const delayTimes = [
    { delaySeconds: 0, name: 'No delay' },
    { delaySeconds: 60, name: '1 minute' },
    { delaySeconds: 120, name: '2 minutes' },
    { delaySeconds: 300, name: '5 minutes' },
    { delaySeconds: 600, name: '10 minutes' },
    { delaySeconds: 900, name: '15 minutes' },
    { delaySeconds: 1800, name: '30 minutes' },
    { delaySeconds: 2700, name: '45 minutes' },
    { delaySeconds: 3600, name: '1 hour' }
  ]
  const groupModes = [
    { value: 'round-robin', label: 'Round Robin' },
    { value: 'all', label: 'Send to everyone' }
  ]

  useMemo(() => {
    GetEnabledIntegrations().then((newIntegrations) => setEnabledIntegrations(newIntegrations))
  }, [])

  const handleCheckboxChange = () => {
    if (form.liveChat && !form.createNewFromNewEmail) {
      setRemoveChatModal(true);
    } else {
      dispatch({ type: 'createNewFromNewEmail', value: !form.createNewFromNewEmail });
      setCanSave(true);
    }
  }

  const availableIntegrations: SelectOption[] | { value: string; label: string }[] = []

  if (enabledIntegrations.salesforce) {
    availableIntegrations.push({ value: 'salesforce', label: 'Salesforce' })
  }
  if (enabledIntegrations.hubspot) {
    availableIntegrations.push({ value: 'hubspot', label: 'Hubspot' })
  }
  if (enabledIntegrations.sendinblue) {
    availableIntegrations.push({ value: 'sendinblue', label: 'Brevo' })
  }

  const usedIntegrations = Object.keys(integrations)
  const canAddIntegration = availableIntegrations.filter(({ value }) => !usedIntegrations.includes(value)).length > 0

  const handleAddIntegration = () => {
    if (!canAddIntegration) {
      return
    }
    const usedIntegrations = Object.keys(integrations)
    const integrationOptions = availableIntegrations.filter(({ value }) => !usedIntegrations.includes(value))
    const newIntegrations = { ...integrations, [integrationOptions[0].value]: [] }
    setIntegrations(newIntegrations)
    dispatch({ type: 'integrations', value: newIntegrations })
    setCanSave(true)
  }

  const handleRemoveIntegration = (integration: string) => {
    const newIntegrations = { ...integrations }
    delete newIntegrations[integration]
    setIntegrations(newIntegrations)
    dispatch({ type: 'integrations', value: newIntegrations })
    setCanSave(true)
  }

  return (
    <div className={classes.settingsContainer}>
      <div className={classes.settingsTitle}>
        Form Settings
      </div>
      <div className={classes.sectionTitle}>
        Submission Confirmation
      </div>
      <Divider />
      <div className={classes.setting}>
        <Radio
          label='Display a thank you message'
          value={form.displayThankYou}
          checked={form.displayThankYou}
          handleChange={() => { dispatch({ type: 'displayThankYou', value: true }); dispatch({ type: 'redirectToExternal', value: false }); setCanSave(true) }}
        />
      </div>
      <div className={classes.setting}>
        <Radio
          label='Redirect to an external URL'
          value={form.redirectToExternal}
          checked={form.redirectToExternal}
          handleChange={() => { dispatch({ type: 'redirectToExternal', value: true }); dispatch({ type: 'displayThankYou', value: false }); setCanSave(true) }}
        />
      </div>
      <div className={classes.setting}>
        {form.displayThankYou && (
          <div className={classes.input}>
            <RichTextEditor
              message={form.thankYouMessage}
              onChange={(value) => { dispatch({ type: 'thankYouMessage', value }); setCanSave(true) }}
              lines={4}
            />
          </div>
        )}
        {form.redirectToExternal && (
          <div>
            <TextField
              value={form.redirectURL}
              onChange={(event) => { dispatch({ type: 'redirectURL', value: event.target.value }); setCanSave(true) }}
              margin='dense'
              variant='outlined'
            />
          </div>
        )}
      </div>
      <div className={classes.sectionTitle} style={{ marginTop: '30px' }}>
        Submission Notification
      </div>
      <Divider />
      <div className={classes.sectionSubtitle}>
        Send an email notification when a form is submitted
      </div>
      {form?.emailSettings?.map((emailSetting, index) => (
        <div key={index}>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px', marginBottom: '20px' }}>
            <div>
              Group {index + 1}
            </div>
            <div onClick={() => { dispatch({ type: 'removeEmailSetting', index: index }); setCanSave(true) }} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
              <div>
                <Icon icon='close' onClick={() => { dispatch({ type: 'removeEmailSetting', index: index }); setCanSave(true) }} />
              </div>
              <div style={{ marginLeft: '10px', marginRight: '20px' }}>
                Remove
              </div>
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '10px' }}>
            <div className={classes.input}>
              <Select
                onChange={(value: string) => {
                  dispatch({ type: 'emailMode', value: value, index: index });
                  setCanSave(true)
                }}
                value={emailSetting.email_mode}
                options={emailModes}
              // disableClear
              />
            </div>
            {(emailSetting.email_mode === 'team' || emailSetting.email_mode === 'multi') && (
              <div style={{ marginLeft: '10px' }}>
                <Select
                  onChange={(value: string) => {
                    dispatch({ type: 'groupSend', value: value || 'all', index: index });
                    setCanSave(true)
                  }}
                  value={emailSetting.group_send || 'all'}
                  options={groupModes}
                // disableClear
                />
              </div>
            )}
          </div>
          {emailSetting.email_mode === 'multi' ? (
            <div className={classes.input}>
              <Picker
                selection={emailSetting.email_recipients || []}
                setSelection={(value: any) => { dispatch({ type: 'emailRecipients', value: value, index: index }); setCanSave(true) }}
                multiple
                objectType='users'
                label='Select recipients'
              />
            </div>
          ) : emailSetting.email_mode === 'single' ? (
            <div className={classes.input}>
              <Picker
                selection={emailSetting?.email_recipients?.[0] || ''}
                setSelection={(value: any) => { dispatch({ type: 'emailRecipients', value: value ? [value.id] : [], index: index }); setCanSave(true) }}
                objectType='users'
                label='Select recipient'
              />
            </div>
          ) : emailSetting.email_mode === 'team' ? (
            <div className={classes.input}>
              <Picker
                selection={emailSetting.team_id}
                setSelection={(value: any) => { dispatch({ type: 'teamID', value: value.id, index: index }); setCanSave(true) }}
                objectType='teams'
                label='Select Team'
              />
            </div>
          ) : <></>}
        </div>
      ))}
      {(form?.emailSettings?.length || 0) < 2 && (
        <div>
          <Button
            className={classes.integrationButton}
            onClick={() => { dispatch({ type: 'addEmailSetting' }); setCanSave(true) }}
            variant='outlined'
            color='inherit'
            style={{ marginTop: 10 }}
          >
            + add a another group
          </Button>
        </div>
      )}
      <div className={classes.sectionTitle} style={{ marginTop: '30px' }}>
        Lead Scores
      </div>
      <Divider style={{ marginBottom: 10 }} />
      {form?.leadScoreSettings?.map((leadSetting, index) => (
        <div key={index}>
          <LeadScoreSelect idx={index} allLeadSettings={form.leadScoreSettings} leadSetting={leadSetting} dispatch={dispatch} setCanSave={setCanSave} />
        </div>
      ))}
      {(form?.leadScoreSettings?.length || 0) < 3 && (
        <div>
          <Button
            className={classes.integrationButton}
            onClick={() => { dispatch({ type: 'leadScoreAdd' }); setCanSave(true) }}
            variant='outlined'
            color='inherit'
            style={{ marginTop: 10 }}
          >
            + add lead score
          </Button>
        </div>
      )}
      {/* <Typography variant='subtitle2'>Submitted the form</Typography>
      <Typography variant='subtitle1'>Automatically rank leads who submit a form by giving them a lead score</Typography>
      <ReplaceButton
        open={form.leadScoreSubmit !== null}
        label='+ add lead score'
        onClick={() => { dispatch({ type: 'leadScoreSubmit', value: 1 }); setCanSave(true) }}
      >
        <div className={classes.leadScoreContainer}>
          <LeadScoreSelector score={form.leadScoreSubmit} setScore={(score: number) => { dispatch({ type: 'leadScoreSubmit', value: score }); setCanSave(true) }} />
          <IconButton icon='close' onClick={() => { dispatch({ type: 'leadScoreSubmit', value: null }); setCanSave(true) }} />
        </div>
      </ReplaceButton>
      <Typography variant='subtitle2'>Started a chat</Typography>
      <Typography variant='subtitle1'>Automatically rank leads who start a live chat by giving them a lead score</Typography>
      <ReplaceButton
        open={form.leadScoreLiveChat !== null}
        label='+ add lead score'
        onClick={() => { dispatch({ type: 'leadScoreLiveChat', value: 2 }); setCanSave(true) }}
      >
        <div className={classes.leadScoreContainer}>
          <LeadScoreSelector score={form.leadScoreLiveChat} setScore={(score: number) => { dispatch({ type: 'leadScoreLiveChat', value: score }); setCanSave(true) }} />
          <IconButton icon='close' onClick={() => { dispatch({ type: 'leadScoreLiveChat', value: null }); setCanSave(true) }} />
        </div>
      </ReplaceButton>
      <Typography variant='subtitle2'>Booked a meeting</Typography>
      <Typography variant='subtitle1'>Automatically rank leads who book a meeting by giving them a lead score</Typography>
      <ReplaceButton
        open={form.leadScoreBookMeeting !== null}
        label='+ add lead score'
        onClick={() => { dispatch({ type: 'leadScoreBookMeeting', value: 3 }); setCanSave(true) }}
      >
        <div className={classes.leadScoreContainer}>
          <LeadScoreSelector score={form.leadScoreBookMeeting} setScore={(score: number) => { dispatch({ type: 'leadScoreBookMeeting', value: score }); setCanSave(true) }} />
          <IconButton icon='close' onClick={() => { dispatch({ type: 'leadScoreBookMeeting', value: null }); setCanSave(true) }} />
        </div>
      </ReplaceButton> */}
      <div className={classes.sectionTitle} style={{ marginTop: '30px' }}>
        Send an Email
      </div>
      <Divider />
      <div className={classes.sectionSubtitle}>
        Send a one-off email to people who submit the form.
      </div>
      <div className={classes.input}>
        <Picker
          selection={form.emailToSend}
          setSelection={(value: any) => { setCanSave(true); dispatch({ type: 'emailToSend', value: value?.id }) }}
          objectType='emails'
          label='Select an email'
        />
      </div>
      <div className={classes.checkboxDiv}>
        <Checkbox
          color='primary'
          checked={addDelay}
          name='Add Delay'
          onClick={() => {
            if (addDelay) {
              setSelectedDelay(0)
              dispatch({ type: 'emailDelay', value: 0 })
            }
            setAddDelay(!addDelay);
            setCanSave(true)
          }}
        />
        <div className={classes.checkboxLabel}>
          Add a delay before the email is sent
        </div>
      </div>
      <div>
        {addDelay && (
          <div className={classes.input}>
            <Select
              onChange={(value: number) => {
                setSelectedDelay(value);
                dispatch({ type: 'emailDelay', value: value });
                setCanSave(true);
              }}
              value={selectedDelay}
              options={delayTimes.map(({ delaySeconds, name }) => ({ value: delaySeconds, label: name }))}
            />
          </div>
        )}
      </div>
      <div className={classes.sectionTitle} style={{ marginTop: '30px' }}>
        Integrations
      </div>
      <Divider />
      <div className={classes.sectionSubtitle}>
        Send contacts to a campaign or list in one of your integrations
      </div>
      {Object.keys(integrations).map((integration, index) => {
        const listIDs = integrations[integration]
        const usedIntegrations = Object.keys(integrations)
        const integrationOptions = availableIntegrations.filter(({ value }) => !usedIntegrations.includes(value) || value === integration)
        return (
          <div key={integration} style={{ marginBottom: '20px' }}>
            <div style={{ width: '450px', display: 'flex', justifyContent: 'space-between' }}>
              <div>Integration {index + 1}</div>
              <Button
                onClick={() => handleRemoveIntegration(integration)}
                style={{ cursor: 'pointer' }}
                startIcon={<Icon icon='close' size='sm' />}
                variant='text'
              >
                REMOVE
              </Button>
            </div>
            <Typography className={classes.sectionSubtitle}>
              Select an integration and campaign/list to send form contacts to
            </Typography>
            <div className={classes.integrationInput}>
              <div style={{ width: '50%' }}>
                <Select
                  onChange={(value: string) => {
                    const newIntegrations = { ...integrations, [value]: [] }
                    delete newIntegrations[integration]
                    setIntegrations(newIntegrations)
                    dispatch({ type: 'integrations', value: newIntegrations })
                    setCanSave(true)
                  }}
                  value={integration}
                  options={integrationOptions}
                  placeholder='Select an integration'
                  disableClear={!!integration}
                />
              </div>
              <div style={{ width: '50%', marginLeft: 10 }}>
                <CFPicker
                  selection={listIDs}
                  setSelection={(e: any) => {
                    setIntegrations({ ...integrations, [integration]: e })
                    dispatch({ type: 'integrations', value: { ...integrations, [integration]: e } })
                    setCanSave(true)
                  }}
                  objectType='contactList'
                  label='Select a campaign/list'
                  loadAllProps={{
                    searchParams: {
                      extraHeaders: { 'x-integration': integration }
                    }
                  }}
                  queryBackend
                  multiple
                />
              </div>
            </div>
          </div>
        )
      })}
      <Tooltip title={canAddIntegration ? '' : 'All available integrations have been added.'}>
        <Button
          className={classes.integrationButton}
          onClick={handleAddIntegration}
          variant='outlined'
          color='inherit'
          style={{ marginTop: 10 }}
          disabled={!canAddIntegration}
        >
          + add a campaign/list
        </Button>
      </Tooltip>
      <div style={{ marginTop: '20px' }}>
        Criteria
      </div>
      <div className={classes.sectionSubtitle}>
        Configure which contacts will be sent to the above integration(s)
      </div>

      <div className={classes.radioSection} style={{ borderColor: !useCriteria ? '#9933FF' : '#D3D3D3' }}>
        <Radio
          label='Everyone who submits the form'
          value={!useCriteria}
          checked={!useCriteria}
          handleChange={() => { dispatch({ type: 'integrationCriteria', value: 'all' }); setCanSave(true) }}
        />
      </div>
      <div className={classes.radioSection} style={{ borderColor: useCriteria ? '#9933FF' : '#D3D3D3' }}>
        <Radio
          label='Only form submitters who match certain criteria'
          value={useCriteria}
          checked={useCriteria}
          handleChange={() => { dispatch({ type: 'integrationCriteria', value: 'segments' }); setCanSave(true) }}
        />
        {useCriteria && (
          <div style={{ width: '100%' }}>
            <div className={classes.sectionSubtitle}>
              Which segment(s) should be sent?
            </div>
            <Divider style={{ marginBottom: 10 }} />
            <Picker
              selection={form?.integrationSegments || []}
              setSelection={(val) => {
                dispatch({ type: 'integrationSegments', value: val })
                setCanSave(true)
              }}
              multiple
              objectType='segments'
              label='Included Segments'
            />
            <div className={classes.sectionSubtitle}>
              Which segment(s) should <i>not</i> be sent?
            </div>
            <Divider style={{ marginBottom: 10 }} />
            <Picker
              selection={form?.integrationExcludedSegments || []}
              setSelection={(val) => {
                dispatch({ type: 'integrationExcludedSegments', value: val })
                setCanSave(true)
              }}
              multiple
              objectType='segments'
              label='Excluded Segments'
            />
          </div>
        )}
      </div>

      <div className={classes.sectionTitle} style={{ marginTop: '30px' }}>
        Other Settings
      </div>
      <Divider />
      <div>
        {form.createNewFromNewEmail && (
          <Alert severity='warning'>
            Only use this form on shared devices, like an iPad at an event, while this box is checked. Placing it on your site like a normal form can result in loss of visitor activity data.
          </Alert>
        )}
      </div>
      <div className={classes.checkboxDiv}>
        <Checkbox
          color='primary'
          checked={form.createNewFromNewEmail}
          name='New Emails'
          onClick={handleCheckboxChange}
        />
        <div className={classes.checkboxLabel}>
          Use this form on a shared device.
        </div>
        <Tooltip title='Checking this box will cause visitor cookie tracking to be reset after each submission.'>
          <InfoIcon color='action' style={{ marginRight: 'auto', marginLeft: 10 }} />
        </Tooltip>
      </div>
      <div className={classes.checkboxDiv}>
        <Checkbox
          color='primary'
          checked={form.prepopulate}
          name='Prepopulate'
          onClick={() => { dispatch({ type: 'prepopulate', value: !form.prepopulate }); setCanSave(true) }}
        />
        <div className={classes.checkboxLabel}>
          Pre-populate contact fields with known values
        </div>
      </div>
      <RemoveChatModal
        open={removeChatModal}
        onClose={() => setRemoveChatModal(false)}
        handleCanSave={() => {
          dispatch({ type: 'liveChat', value: false });
          dispatch({ type: 'createNewFromNewEmail', value: !form.createNewFromNewEmail });
          setCanSave(true);
          setRemoveChatModal(false);
        }}
      />
    </div>
  )
}
