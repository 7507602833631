import { ReactNode } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'

type StyleScrollBoxProps = {
  backgroundColor: string
}

const useStyles = makeStyles<Theme, StyleScrollBoxProps, 'scrollBox'>(theme => ({
  scrollBox: ({ backgroundColor }) => ({
    overflowY: 'auto',
    background: `linear-gradient(${backgroundColor} 33%, rgba(255,255,255, 0)), linear-gradient(rgba(255,255,255, 0), ${backgroundColor} 66%) 0 100%, radial-gradient(farthest-side at 50% 0, rgba(158,158,158, 0.7), rgba(0,0,0,0)), radial-gradient(farthest-side at 50% 100%, rgba(158,158,158, 0.7), rgba(0,0,0,0)) 0 100%`,
    backgroundColor: backgroundColor,
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'local, local, scroll, scroll',
    backgroundSize: '100% 27px, 100% 27px, 100% 9px, 100% 9px'
  })
}))

export interface ScrollBoxProps {
  children: ReactNode
  className?: string
  /**
   * The ScrollBox depends on having a background color to control the state of the top/bottom gradient
   *
   * Do **NOT** use colors without 100% opacity
   */
  backgroundColor?: string
  /** Whether to display the default scroll bar or not */
  noScrollBar?: boolean
  style?: {}
}

function ScrollBox (props: ScrollBoxProps): JSX.Element {
  const classes = useStyles({ backgroundColor: props.backgroundColor || '#FFFFFF' })
  let className = classes.scrollBox
  if (props.className) className += ' ' + props.className
  if (props.noScrollBar) className += ' no-scroll'

  return (
    <div className={className} style={props.style}>
      {props.children}
    </div>
  )
}

export { ScrollBox }
