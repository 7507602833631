import { nodeMapping } from 'pages/settings/nodeMapping'
import { SectionProps } from '../HelpCenter'
import { HelpCenterCardList } from '../HelpCenterCardList'
import { SectionCenter } from '../SectionCenter'

const nodeList = ['calendar']

export const SettingsOptionsCenter = (props: SectionProps): JSX.Element => {
  return (
    <SectionCenter
      homeComponent={<HelpCenterCardList nodeMapping={nodeMapping} nodeList={nodeList} />}
      links={props.links}
      type='node'
    />
  )
}
