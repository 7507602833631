import { makeStyles } from '@material-ui/core/styles';
import { EmailSubscription } from 'classes/emailSubscriptions';
import { Button, Paper, Typography } from 'library/materialUI';
import { useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { getSearchParam } from 'helpers/strings';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main
  },
  paper: {
    width: '681px',
    height: '224px',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  padding: {
    padding: 30
  }
}))

export default function UnsubscribePage () {
  const classes = useStyles()
  const location = useLocation()
  const [token, setToken] = useState('')
  const [successfulUnsubscribe, setSuccessfulUnsubscribe] = useState(false)
  const [error, setError] = useState(false)

  if (!location.search) {
    <Redirect to='/#/' />
  } else {
    if (!token) {
      setToken(getSearchParam(location.search, 'token'))
    }
  }

  async function handleUnsubscribe () {
    const resp = await EmailSubscription.unsubscribe(token).catch(() => setError(true))
    setSuccessfulUnsubscribe(resp)
    if (!resp) {
      setError(true)
    }
  }

  const UnsubscribeContent = (
    <>
      <Typography className={classes.padding}>Are you sure you want to stop receiving this email update?</Typography>
      <Button color='primary' variant='contained' onClick={() => handleUnsubscribe()}>Unsubscribe</Button>
    </>
  )

  const EmailUnsubscribedContent = (
    <>
      <Typography className={classes.padding}>You have been unsubscribed!</Typography>
    </>
  )

  const ErrorContent = (
    <>
      <Typography className={classes.padding}>There was an error unsubscribing you. Please contact support.</Typography>
    </>
  )

  const Component = successfulUnsubscribe ? EmailUnsubscribedContent : error ? ErrorContent : UnsubscribeContent

  return (
    <div className={classes.container}>
      <Paper elevation={3} className={classes.paper}>
        {Component}
      </Paper>
    </div>
  )
}
