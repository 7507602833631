import React, { useState, useEffect } from 'react'
import { jsPlumbUtil } from 'jsplumbtoolkit'
import OptionPort from '../OptionPort'
import useNodeStyles from './NodeStyles'
import RenderEmoji from 'pages/chat/RenderEmoji'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { getCustomFields } from 'api/custom_fields'
import Divider from '@material-ui/core/Divider'
import CallToActionIcon from '@material-ui/icons/CallToAction'
import { TwitterPicker } from 'react-color'
import 'jsplumb'
import '../quill.css'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import { useId } from 'react-id-generator'
import Grid from '@material-ui/core/Grid'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles, ThemeProvider } from '@material-ui/core/styles'
import { EVENT_TYPE, Emitter } from 'emitter'
import { Modal, ModalSection } from 'library/Modal'
import theme from 'theme'
import { RadioButtons } from 'library/materialUI'

const useStyles = makeStyles(theme => ({
  buttonText: {
    textTransform: 'none'
  }
}))

function QueryParamMapRow ({ fieldRow, setFieldRow, removeFieldRow, customFields }) {
  return (
    <Grid container style={{ marginTop: 5 }} spacing={1}>
      <Grid item>
        <TextField
          variant='outlined'
          label='URL Parameter'
          value={fieldRow.query_param}
          onChange={e => setFieldRow({ ...fieldRow, query_param: e.target.value })}
        />
      </Grid>
      <Grid item>
        <ContactFieldSelect
          customFields={customFields}
          value={fieldRow.merge_param}
          setValue={val => setFieldRow({ ...fieldRow, merge_param: val })}
        />
      </Grid>
      <Grid item>
        <IconButton onClick={removeFieldRow} aria-label='remove url parameter'>
          <HighlightOffIcon style={{ color: '#975ce6' }} />
        </IconButton>
      </Grid>
    </Grid>
  )
}

function QueryParamList ({ rows, setRows, customFields }) {
  return (
    <>
      <div>
        {rows.map((i, index) => (
          <QueryParamMapRow
            fieldRow={i}
            setFieldRow={newRow => {
              const newRows = [...rows]
              newRows.splice(index, 1, newRow)
              setRows(newRows)
            }}
            key={index}
            removeFieldRow={() => {
              const newRows = [...rows]
              newRows.splice(index, 1)
              setRows(newRows)
            }}
            customFields={customFields}
          />
        ))}
      </div>
      <Button
        startIcon={<AddCircleIcon style={{ color: '#975ce6' }} />}
        style={{ color: '#975ce6' }}
        onClick={() => {
          setRows([...rows, { query_param: '', merge_param: '' }])
        }}
      >Add Parameter
      </Button>
    </>
  )
}

function CTAButtonExample (props) {
  const classes = useStyles()

  return (
    <div style={{ width: 200 }}>
      <Button
        style={{
          backgroundColor: props.color,
          color: 'white',
          width: '90%',
          marginTop: 10,
          marginBottom: 10,
          marginLeft: 'auto',
          marginRight: 'auto',
          display: 'block',
          textAlign: 'center',
          fontWeight: 'bold'
        }}
        variant='outlined'
      >
        <RenderEmoji
          text={props.label || ''}
          className={classes.buttonText}
        />
      </Button>
    </div>
  )
}

function ContactFieldSelect ({ customFields, value, setValue }) {
  const [labelId, fieldId] = useId(2)
  return (
    <FormControl>
      <InputLabel variant='outlined' id={labelId + 'ryan'}>Value</InputLabel>
      <Select
        id={fieldId}
        labelId={labelId + 'ryan'}
        variant='outlined'
        style={{ width: 200 }}
        value={value}
        onChange={e => setValue(e.target.value)}
        label='Value'
      >
        <MenuItem value='visitor.first_name'>First Name</MenuItem>
        <MenuItem value='visitor.last_name'>Last Name</MenuItem>
        <MenuItem value='visitor.email'>Email</MenuItem>
        <MenuItem value='visitor.phone'>Phone</MenuItem>
        <MenuItem value='visitor.title'>Title</MenuItem>
        <MenuItem value='visitor.lead_score'>Lead Score</MenuItem>
        <MenuItem value='visitor.company_name'>Company Name</MenuItem>
        <MenuItem value='visitor.company_industry'>Company Industry</MenuItem>
        <MenuItem value='account_owner.first_name'>Account Owner First Name</MenuItem>
        <MenuItem value='account_owner.name'>Account Owner Full Name</MenuItem>
        {customFields.map((i, index) => {
          return (
            <MenuItem key={i.id} value={i.id}>{i.attributes.label}</MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}

function MessageModal (props) {
  const [state, setState] = useState(props.state)
  const [customFields, setCustomFields] = useState(props.state)
  function cancelChanges () {
    props.onHide()
  }
  const chatServiceUrl = window.chatServiceUrl

  useEffect(() => {
    getCustomFields('people', chatServiceUrl)
      .then(response => {
        setCustomFields(response.data)
      })
  }, [chatServiceUrl])

  if (!props.open) {
    return <></>
  }

  Emitter.on(EVENT_TYPE.BROWSER_BACK, cancelChanges)

  return (
    <ThemeProvider theme={theme}>
      <Modal
        open={props.open}
        onHide={cancelChanges}
        size='md'
        handleSave={() => props.save(state)}
        title='Edit Button'
        helplink='home/bots/nodes/Button'
        helplinkLabel='Learn more about this skill'
        saveIcon='save'
      >
        <ModalSection
          title='Button Text'
          subtitle='What will the button text say?'
        >
          <TextField
            variant='outlined'
            name='label'
            fullWidth
            label='Button Text'
            value={state.label}
            onChange={e => setState({ ...state, label: e.target.value })}
            margin='dense'
          />
        </ModalSection>
        <ModalSection
          title='Button Link'
          subtitle='Where will the button take your visitors?'
        >
          <div>
            <TextField
              variant='outlined'
              name='url'
              label='Link URL'
              value={state.url}
              onChange={e => setState({ ...state, url: e.target.value })}
              style={{ width: '100%' }}
              margin='dense'
            />
          </div>
          <div style={{ marginBottom: 15 }}>
            <QueryParamList
              rows={state.prop_mapping || []}
              setRows={rows => setState({ ...state, prop_mapping: rows })}
              customFields={customFields}
            />
          </div>
          <InputLabel style={{ color: 'grey', fontSize: 14, marginBottom: 5 }}>
            Where should the link open?
          </InputLabel>
          <div style={{ marginLeft: 5 }}>
            <RadioButtons
              value={state.open_target}
              handleChange={value => setState({ ...state, open_target: value })}
              buttons={[
                { value: '_parent', label: 'Open in the current tab' },
                { value: '_blank', label: 'Open in a new tab' }
              ]}
            />
          </div>
        </ModalSection>
        <ModalSection
          title='Button Color'
          subtitle='Choose which color the button will appear as to your visitors.'
        >
          <Grid container alignItems='center' justifyContent='space-between' style={{ marginTop: 10, marginBottom: 10 }}>
            <Grid item>
              <TwitterPicker
                triangle='hide'
                color={state.color}
                onChange={(color) => {
                  setState({ ...state, color: color.hex })
                }}
              />
            </Grid>

            <Grid item>
              <CTAButtonExample
                label={state.label}
                color={state.color}
              />
            </Grid>

          </Grid>
        </ModalSection>
      </Modal>
    </ThemeProvider>
  )
}

const CTAButtonNode = props => {
  const base = props.base
  const classes = useNodeStyles(props)

  const assignKey = data => {
    data._reactKey = data._reactKey || jsPlumbUtil.uuid()
    return data._reactKey
  }

  const saveChanges = (state) => {
    if (!base.toolkit) {
      return
    }
    const node = base.getNode()
    const new_data = { ...base.state, ...state }
    base.toolkit.updateNode(node, new_data)
    props.setModalOpen(false)
  }

  return (
    <>
      <div>
        <div
          className={`${classes.dragger} ${classes.visitorInteraction}`}
        >
          <div className='node-header'>
            <div className={classes.iconDiv}><CallToActionIcon /></div>
            <div className={classes.cardTitle}>Button</div>
          </div>
          <div className='node-body' style={{ minHeight: 18 }}>
            <div style={{ width: '100%', marginLeft: 'auto', marginRight: 'auto' }}>
              <CTAButtonExample label={base.state.label} color={base.state.color} />
            </div>
            <Divider style={{ marginTop: 5 }} />
            <div>
              {base.state.ports.map((c, index) => {
                return (
                  <OptionPort
                    index={index}
                    data={c}
                    key={assignKey(c)}
                    parent={base}
                    kind='visitorInteraction'
                    label='Next'
                  />
                )
              })}
            </div>
          </div>
          <jtk-target
            scope='default'
          />
        </div>
      </div>
      <MessageModal
        open={props.modalOpen}
        onHide={() => props.setModalOpen(false)}
        save={saveChanges}
        state={base.state}
      />
    </>
  )
}

export default CTAButtonNode
