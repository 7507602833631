
const descriptionText = 'Your Signals administrator can create Conversation Tags, which can then be used to see trends in your backend data, use default reports Signals provides you and quickly filter your Chat menu.'

const Description = (): JSX.Element => {
  return (
    <div>
      <div>
        {descriptionText}
      </div>
      <ul>
        <li><a href='https://help.getsignals.ai/article/2uuwiyrnf1-01-conversation-tags' target='_blank' rel='noreferrer'>Creating conversation tags</a> </li>
      </ul>
    </div>
  )
}

const bestPracticeText = 'We highly recommend that you use the Tag Conversation in your bot whenever an important event takes place in your bot flow.  You can use them to:'

const BestPractices = () => {
  return (
    <div>
      <div>
        {bestPracticeText}
      </div>
      <ul>
        <li>Filter the chat menu</li>
        <li>Manually add tags to conversations</li>
        <li>In conversation data exports</li>
        <li>In the Reports dashboard</li>
      </ul>
    </div>
  )
}

export const TagConversationDocs = {
  helpCenterLink: 'https://help.getsignals.ai/article/23a2civrw6-tag-conversation-node',
  bestPractices: BestPractices,
  description: Description,
  videoLink: ''
}
