
const descriptionText = 'Use this skill when you would like to continue the play flow based upon the visitor reaching a certain page on your website'

function WebsiteSignalDescription () {
  return (
    <div>
      <div>
        {descriptionText}
      </div>
    </div>
  )
}

const bestPracticeText = 'This skill is great for creating a visual flow to determine all in one place when and where your chatbots will fire, when visitors will be added to marketing campaigns, when specific emails will be sent to visitors, etc.'

function WebsiteSignalBestPractices () {
  return (
    <div>
      <div>
        {bestPracticeText}
      </div>
      <ul>
        <li>Create multiple criteria branches to branch the play based upon different URL's visited</li>
        <li>Use this skill prior to using the Visitor Attributes skill to fire different bots based upon:</li>
        <ul>
          <li>Previous traffic on your website</li>
          <li>Previous bot conversations</li>
        </ul>
        <li>Use this skill prior to using the Contact Search skill to selectively search for contacts when visitors visit high intent pages (ie. pricing)</li>
      </ul>
    </div>
  )
}

export const websiteSignalDocs = {
  helpCenterLink: 'https://help.getsignals.ai/article/0k47otvwwh-company-segment-node',
  bestPractices: WebsiteSignalBestPractices,
  description: WebsiteSignalDescription,
  videoLink: ''
}
