import { makeStyles } from '@material-ui/core/styles'
import DateRangeSelector from 'cf-components/DateRangeSelector'
import { User } from 'classes/users'
import UserInviteModal from 'pages/users/UserManagement/InviteModal'
import { useContext, useEffect, useState } from 'react'
import { AdminAccountManager } from './AdminAccountManager'
import { AdminAddSalesforceNote } from './AdminAddSalesforceNote'
import { AdminContactCard } from './AdminContactCard'
import { AdminDomainManager } from './AdminDomainManager'
import { AdminIntegrationCounts } from './AdminIntegrationCounts'
import { AdminPlanInfo } from './AdminPlanInfo'
import { AdminPortalCard } from './AdminPortalCard'
import { AdminRecentLogins } from './AdminRecentLogins'
import { AdminSetupChecklist } from './AdminSetupChecklist'
import { tenantParams } from './AdminTenantPage'
import { AdminTenantSummary } from './AdminTenantSummary'
import { AdminUserInvites } from './AdminUserInvites'
import { adminGetInstallScript } from 'api/settings'
import { SessionContext } from 'session-context'

const useStyles = makeStyles(theme => ({
  topButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: 20
  },
  card: {
    marginLeft: 20,
    marginBottom: 15
  },
  value: {
    fontSize: 30,
    fontWeight: 600
  },
  pageGrid: {
    display: 'grid',
    gridTemplateColumns: '4fr 1.2fr',
    gridGap: 15
  },
  accountCardsGrid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: 15,
    marginLeft: 20
  },
  cardGrid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: 15
  },
  scriptdiv: {
    width: '100%',
    boxShadow: '0px 2px 9px #aaaaaa inset',
    margin: '24px 0px',
    display: 'inline-block'
  }
}))

interface dateTypes {
  startDate: Date
  endDate: Date
}

interface TenantOverviewProps {
  tenantId: number
  users: User[] // this needs to be updated to a User[] class
  tenantSummary: tenantParams
}

export const AdminTenantOverviewPage = ({ tenantId, users, tenantSummary }: TenantOverviewProps): JSX.Element => {
  const classes = useStyles()
  const [inviteModalOpen, setInviteModalOpen] = useState<boolean>(false)
  const [script, setScript] = useState<string>('')
  const { setSnackState } = useContext(SessionContext)

  const today = new Date()
  const defaultDateRange: dateTypes = {
    startDate: new Date(today.setDate(today.getDate() - 30)),
    endDate: new Date()
  }
  const [dateRange, setDateRange] = useState(defaultDateRange)
  const [accountManagerID, setAccountManagerID] = useState(0)
  const [pointOfContactID, setPointOfContactID] = useState('')

  useEffect(() => {
    adminGetInstallScript(tenantId)
    .then((response: any) => {
      setScript(response.data.attributes)
    })
  }, [tenantId])

  useEffect(() => {
    if (tenantSummary?.attributes) {
      const accountManager = tenantSummary.attributes.account_manager_id || 0
      const pointOfContact = tenantSummary.attributes.point_of_contact_id || 0
      setAccountManagerID(accountManager)
      setPointOfContactID(pointOfContact)
    }
  }, [tenantSummary])

  const handleDateChange = (range: dateTypes) => {
    setDateRange(range)
  }

  return (
    <div>
      <div className={classes.topButtons}>
        <AdminAddSalesforceNote tenantId={tenantId} />
        <div>
          <div>
            <DateRangeSelector
              range={dateRange}
              handleSelect={range => handleDateChange(range)}
            />
          </div>
        </div>
      </div>
      <div className={classes.pageGrid}>
        <div>
          <div className={classes.accountCardsGrid}>
            <div>
              <AdminPortalCard title='Account Manager'>
                <AdminAccountManager tenantId={tenantId} accountManagerID={accountManagerID} />
              </AdminPortalCard>
              <AdminPortalCard title='Admin Setup' removeMargin>
                <AdminSetupChecklist tenantId={tenantId} />
              </AdminPortalCard>
            </div>
            <div>
              <AdminPortalCard title='Plan Info'>
                <AdminPlanInfo tenantId={tenantId} />
              </AdminPortalCard>
              <AdminPortalCard title='Point of Contact' removeMargin>
                <AdminContactCard users={users} contact={pointOfContactID} tenantId={tenantId} />
              </AdminPortalCard>
            </div>
          </div>
          <div style={{ marginLeft: 20 }}>
            <AdminPortalCard title='Account Domains'>
              <AdminDomainManager tenantId={tenantId} />
            </AdminPortalCard>
            <AdminPortalCard title='User Invite List' action={() => setInviteModalOpen(true)}>
              <AdminUserInvites tenantId={tenantId} inviteModalOpen={inviteModalOpen} />
            </AdminPortalCard>
            <AdminPortalCard title='Most Recently Active Users'>
              <AdminRecentLogins tenantId={tenantId} users={users} />
            </AdminPortalCard>
            <div className={classes.cardGrid}>
              <AdminPortalCard title='Integration Counts'>
                <AdminIntegrationCounts tenantId={tenantId} />
              </AdminPortalCard>
              <AdminPortalCard
                title='Installation Code'
                icon='copy'
                action={() => {
                  navigator.clipboard.writeText(script)
                  setSnackState({
                    open: true,
                    message: 'Installation code copied to clipboard',
                    variant: 'success'
                  })
                }}
              >
                <div className={classes.scriptdiv}>
                  <pre style={{ width: '100%', overflowX: 'hidden' }}>
                    {script}
                  </pre>
                </div>
              </AdminPortalCard>
            </div>
          </div>
        </div>
        <AdminTenantSummary
          tenantId={tenantId}
          dateRange={dateRange}
          userLength={users.length}
        />
      </div>
      <UserInviteModal
        open={inviteModalOpen}
        close={() => setInviteModalOpen(false)}
        tenantId={tenantId}
        admin
      />
    </div>
  )
}
