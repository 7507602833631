import { GenericObject } from './genericObject';
import { LoadAllProps } from './queryHelpers';
import { useDoQuery } from './useDoQuery';

interface CadenceList {
    list: Cadence[]
    dict: { [id: Cadence['id']]: Cadence}
}

export class Cadence extends GenericObject {
    id: number
    name: string

    // _ = integration
    static loadAll (props?: LoadAllProps): { data: CadenceList, isLoading: boolean } {
        return useDoQuery({
            path: '/integrations/crm/lists?type=contacts',
            objectClass: Cadence,
            extraHeaders: { 'x-integration': 'salesloft' },
            useChatURL: false,
            searchParams: props?.searchParams
        })
    }

    // _ = personID
    static loadOne (id: number): { data: Cadence, isLoading: boolean } {
        if (id) {
            return { data: new Cadence({ row: { id: 1, attributes: { name: 'Inbound Leads' } } }), isLoading: false }
        }
        return { data: new Cadence({ row: { id: 0, attributes: { name: undefined } } }), isLoading: false }
    }

    constructor ({ row }: { row: Record <string, any> }) {
        super({ row })
        const cadence = row.attributes
        this.id = row.id
        this.name = cadence.name
    }
}
