import { ContentsProps } from 'pages/onboarding/wizard/OnboardingWizard'
import { HighIntentCategory } from 'classes/highIntentCategories'
import HighIntentCategorySection from 'pages/settings/HighIntentPages/HighIntentCategorySection'

export const intentPagesSubtitle = 'Designate which pages on your site are considered "high-intent."' +
'This will allow you to more easily set up alerts and create targeted experiences ' +
'for the pages you care about.'

export function IntentPages (props: ContentsProps) {
  const categories = HighIntentCategory.loadAll().data.list

  props.handleNextRef.current = () => undefined

  return (
    <div>
      {categories ? categories.map(category => {
        return (
          <HighIntentCategorySection
            categoryID={(category.id) as number}
            key={category.id}
            pageType={category.name}
            disableChange
          />
        )
      }) : null}
    </div>
  )
}
