
const descriptionText = 'Send a custom email to the visitor. ' +
  "Simply select an email template: if the visitor's email address is known, an email will be sent on your behalf. " +
  'Please note that you must configure your email settings before using this feature.'

function SendEmailDescription () {
  return (
    <div>
      <div>
        {descriptionText}
      </div>
      <ul>
        <li><a href='#/settings/email_settings' target='_blank'>Configure settings</a></li>
        <li><a href='#/engagement/emails/emails' target='_blank'>Create email templates</a></li>
      </ul>
    </div>
  )
}

const bestPracticeText = "This skill only works if the visitor's email is already known." +
  'It is intended for transactional emails, not for email blasts.'

function SendEmailBestPractices () {
  return (
    <div>
      <div>
        {bestPracticeText}
      </div>
      <ul>
        <li>We only send one email per user per day</li>
        <li>Use the Add to Campaign skill for email blasts and marketing drip campaigns</li>
      </ul>
    </div>
  )
}

export const sendEmailDocs = {
  helpCenterLink: 'https://help.getsignals.ai/article/50t4gh395z-plays-send-email-node',
  bestPractices: SendEmailBestPractices,
  description: SendEmailDescription,
  videoLink: 'https://youtu.be/votK4IpDjjA'
}
