const headers = {
  'Content-Type': 'application/vnd.api+json'
}

const addClearbit = ({ integration }) => {
  return fetch('/api/integrations/clearbit', {
    method: 'POST',
    headers: headers,
    body: JSON.stringify({ data: integration })
  })
}

const updateClearbit = ({ integration }) => {
  return fetch('/api/integrations/clearbit', {
    method: 'PATCH',
    headers: headers,
    body: JSON.stringify({ data: integration })
  })
}

const getClearbit = () => {
  return fetch('/api/integrations/clearbit', {
    method: 'GET',
    headers: headers
  })
}

export { getClearbit, updateClearbit, addClearbit }
