import { makeStyles } from '@material-ui/core/styles'
import { TextField, Typography, Button, FormControlLabel, Radio, Tooltip, Checkbox, Select, MenuItem } from '@material-ui/core'
import { logAttachment } from 'api/content_pages'
import { Link } from 'react-router-dom'
import Dropzone from 'react-dropzone'
import MuiDialogContent from '@material-ui/core/DialogContent'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined'
import WallpaperOutlinedIcon from '@material-ui/icons/WallpaperOutlined'
import axios from 'axios'
import ErrorIcon from '@material-ui/icons/Error'
import SettingsIcon from '@material-ui/icons/Settings'
import ColorPicker from 'pages/settings/ChatSettings/Avatars/ColorPicker'
import { ScrollBox } from 'cf-components/ScrollBox'
import QRCodeCanvas from 'qrcode.react'

const dropzoneStyles = makeStyles(theme => ({
  rightIcon: {
    marginLeft: theme.spacing(1),
    color: 'gray'
  },
  dropzone: {
    cursor: 'pointer',
    height: 170,
    width: 280,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  paragraph: {
    color: '#454545',
    fontSize: 14,
    marginBottom: 0,
    marginTop: 10
  },
  paragraph2: {
    color: 'gray',
    fontSize: 12,
    marginBottom: 10,
    marginTop: 0
  },
  content: {
    margin: 'auto',
    textAlign: 'center',
    verticalAlign: 'center'
  }
}))

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: '14px',
    backgroundColor: '#F7F7F7',
    height: '740px'
  },
  leftContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: 10,
    width: '360px',
    height: '720px'
  },
  rightContainer: {
    padding: 10,
    display: 'flex',
    flexDirection: 'column',
    width: '75%',
    height: '740px'
  },
  left: {
    display: 'flex',
    height: '100%'
  },
  right: {
    display: 'flex',
    height: '100%'
  },
  card: {
    border: '1px solid #D3D3D3',
    backgroundColor: '#FFFFFF',
    padding: '10px',
    marginBottom: '10px',
    borderRadius: '10px'
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
    marginBottom: 15
  },
  stylesContainer: {
    display: 'flex',
    marginTop: 10
  },
  subtitle: {
    color: 'gray',
    fontSize: 14,
    marginBottom: 10
  },
  subHeader: {
    fontWeight: 600
  },
  radioButtons: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    fontSize: '12px',
    color: theme.palette.primary.main
  },
  pdfIcon: {
    width: 50,
    height: 50,
    color: 'gray'
  },
  pdfSample: {
    backgroundColor: 'white',
    margin: 40,
    display: 'block',
    overflow: 'hidden',
    position: 'absolute',
    width: '620px',
    height: 'calc(100% - 30px)',
    marginTop: 30,
    boxShadow: '2px 0px 5px 0px rgba(0,0,0,0.51)'
  },
  content: {
    margin: 'auto',
    textAlign: 'center',
    verticalAlign: 'center',
    height: '100%'
  },
  defaultLogoStyles: {
    display: 'flex',
    alignItems: 'center',
    color: 'gray',
    fontSize: 18
  },
  logoPreview: {
    maxHeight: 75
  },
  pdfIconHighlighted: {
    color: '#975ce6',
    width: 50,
    height: 50
  },
  previewHeader: {
    border: '1px solid #AA88E2',
    borderWidth: '1px 1px 0px 1px',
    borderRadius: '5px 5px 0px 0px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 10,
    paddingRight: 10
  },
  botPreviewWindow: {
    width: '584px',
    minHeight: '504px',
    backgroundColor: '#FFFFFF',
    borderRadius: '5px',
    boxShadow: '0px 3px 6px #00000029',
    marginRight: '200px',
    marginLeft: '200px',
    marginTop: '20px',
    marginBottom: '33px'
  }
}))

const ImageDropZone = (props: any) => {
  const classes = dropzoneStyles()

  const handleDrop = (acceptedFile: any) => {
    props.handler(acceptedFile[0])
  }

  return (
    <div style={{ border: '2px dotted #b5b5b5', borderRadius: 5 }}>
      <Dropzone onDrop={handleDrop} disabled={props.disabled}>
        {({ getRootProps, getInputProps }) => (
          <MuiDialogContent
            dividers
            {...getRootProps()}
            className={classes.dropzone}
          >
            <input {...getInputProps()} />
            <div className={classes.content}>
              {/* <DescriptionOutlinedIcon className={classes.pdfIcon} /> */}
              {props.icon}
              <p className={classes.paragraph}>{props.title}</p>
              <p className={classes.paragraph2}>- or -</p>
              <Button variant='outlined' color='primary'>
                {props.buttonText}
                <CloudUploadIcon className={classes.rightIcon} />
              </Button>
            </div>
          </MuiDialogContent>
        )}
      </Dropzone>
    </div>
  )
}

interface ContentSettingsProps {
  pdfEnabled: boolean
  setPdfEnabled: (value: boolean) => null
  handleChange: (var1: string, var2: any) => null
  uploadPDF: any
  contentPageState: any
  currentFile: any
  logoEnabled: boolean
  setLogoEnabled: (value: boolean) => null
  currentImage: any
  setCurrentImage: any
  logoURLOn: boolean
  setLogoURLOn: (value: boolean) => null
  logoURL: string
  setLogoURL: (value: string) => null
  setReady: any
  domain: string
  subDomain: string
  setSnackState: any
  canSave: boolean
}

export default function ContentSettings ({ pdfEnabled, setPdfEnabled, handleChange, logoURLOn, setLogoURLOn, logoURL, setLogoURL, uploadPDF, contentPageState, currentFile, currentImage, setCurrentImage, logoEnabled, setLogoEnabled, setReady, domain, subDomain, setSnackState, canSave }: ContentSettingsProps) {
  const classes = useStyles()

  const uploadImage = (image: any) => {
    // eslint-disable-next-line
    logAttachment({ file: image, content_type: image.type })
      .then(response => {
        axios.put(
          response.meta.signed_upload_url,
          image,
          {
            headers: {
              'Content-type': image.type
            }
          }).then(response => {
            image.link = response?.config?.url?.split('?')[0] || ''
            setCurrentImage(image)
            setReady(false)
            handleChange('logo_image', image.link)
          })
      })
  }

  const downloadQRCode = () => {
    const qrCodeURL = document?.getElementById('qrCodeEl')
      .toDataURL('image/png')
      .replace('image/png', 'image/octet-stream')
    const ref = document.createElement('a')
    ref.href = qrCodeURL
    ref.download = 'QR_Code.png'
    document.body.appendChild(ref)
    ref.click()
    document.body.removeChild(ref)
  }

  return (
    <div>
      <ScrollBox style={{ maxHeight: '750px', backgroundColor: '#F7F7F7' }}>
        <div>
          <div className={classes.card}>
            <div>
              <Typography className={classes.title} style={{ marginBottom: 5 }}>
                Content
              </Typography>
              <Typography className={classes.subtitle}>
                Display a PDF file alongside your bot to continue the conversation on your site!
              </Typography>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                <FormControlLabel
                  labelPlacement='end'
                  label='Chatbot only'
                  className={classes.radioButtons}
                  control={
                    <Radio
                      color='primary'
                      checked={!pdfEnabled}
                      onChange={() => { setPdfEnabled(false); handleChange('bot_only', true) }}
                    />
                  }
                />
                <FormControlLabel
                  labelPlacement='end'
                  label='PDF'
                  className={classes.radioButtons}
                  control={
                    <Radio
                      color='primary'
                      checked={pdfEnabled}
                      onChange={() => { setPdfEnabled(true); handleChange('bot_only', false) }}
                    />
                  }
                />
              </div>
              <div>
                {pdfEnabled && (
                  <ImageDropZone
                    disabled={!pdfEnabled}
                    handler={uploadPDF}
                    title={contentPageState.attributes.pdf_content ? currentFile?.name : 'Drag and drop a PDF'}
                    buttonText={contentPageState.attributes.pdf_content ? 'Upload a different File' : 'Upload from Computer'}
                    icon={<DescriptionOutlinedIcon className={contentPageState.attributes.pdf_content ? classes.pdfIconHighlighted : classes.pdfIcon} />}
                  />
                )}
              </div>
            </div>
          </div>
          <div className={classes.card}>
            <div>
              <Typography className={classes.title} style={{ marginBottom: 5 }}>
                Logo
              </Typography>
              <Typography className={classes.subtitle}>
                Upload your company logo or an image to display at the top of the chat window. (Recommended size: 420x100 pixels)
              </Typography>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                <FormControlLabel
                  labelPlacement='end'
                  label='None'
                  className={classes.radioButtons}
                  control={
                    <Radio
                      color='primary'
                      checked={!logoEnabled}
                      onChange={() => {
                        handleChange('logo_image', '')
                        setLogoEnabled(false)
                      }}
                    />
                  }
                />
                <FormControlLabel
                  labelPlacement='end'
                  label='Logo'
                  className={classes.radioButtons}
                  control={
                    <Radio
                      color='primary'
                      checked={logoEnabled}
                      onChange={() => {
                        setLogoEnabled(true)
                        if (currentImage && currentImage.link) {
                          handleChange('logo_image', currentImage.link)
                        }
                      }}
                    />
                  }
                />
              </div>
              <div>
                {logoEnabled && (
                  <ImageDropZone
                    disabled={!logoEnabled}
                    handler={uploadImage}
                    title={contentPageState.attributes.logo_image ? currentImage?.name : 'Drag and drop an image here'}
                    buttonText={contentPageState.attributes.logo_image ? 'Upload a different image' : 'Upload from Computer'}
                    icon={<WallpaperOutlinedIcon className={contentPageState.attributes.logo_image ? classes.pdfIconHighlighted : classes.pdfIcon} />}
                  />
                )}
              </div>
              {logoEnabled && (
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={logoURLOn}
                        onChange={() => { setLogoURLOn(!logoURLOn); handleChange('use_logo_url', !logoURLOn) }}
                        name='urlON'
                        color='primary'
                      />
                    }
                    label='Link logo to a URL'
                    labelPlacement='end'
                    className={classes.radioButtons}
                  />
                  <div>
                    {logoURLOn && (
                      <TextField
                        label='Page URL'
                        name='page_url'
                        fullWidth
                        variant='outlined'
                        margin='dense'
                        value={logoURL}
                        onChange={e => { setLogoURL(e.target.value); handleChange('logo_url', e.target.value) }}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className={classes.card}>
            <Typography className={classes.title}>
              Background Color
            </Typography>
            {/* Make this work */}
            <Select
              style={{ width: 200, marginBottom: 25 }}
              variant='outlined'
              margin='dense'
              value={contentPageState.attributes.background_type}
              onChange={e => handleChange('background_type', e.target.value)}
            >
              <MenuItem value='solid'>Solid Color</MenuItem>
              <MenuItem value='gradient'>Gradient</MenuItem>
            </Select>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '15px' }}>
                <>
                  <ColorPicker title='Primary Color' color={contentPageState.attributes.background_color.replace('#', '')} setColor={(color: any) => handleChange('background_color', '#' + color)} />
                  {contentPageState.attributes.background_type === 'gradient' && <ColorPicker title='Secondary Color' color={contentPageState.attributes.secondary_color.replace('#', '')} setColor={(color: any) => handleChange('secondary_color', '#' + color)} />}
                </>
              </div>
            </div>
          </div>
          <div className={classes.card}>
            <Typography className={classes.title}>
              URL
            </Typography>
            <Typography className={classes.subtitle} style={{ marginBottom: '10px' }}>
              Use this link to direct visitors to your landing page. Customize it below.
            </Typography>
            <TextField
              label='Page Name (Required)'
              name='page_name'
              fullWidth
              variant='outlined'
              margin='dense'
              style={{ marginBottom: 10, marginTop: 0 }}
              value={contentPageState.attributes.page_name}
              onChange={e => {
                const pageName = e.target.value.replace(/ /g, '-')
                handleChange('page_name', pageName)
              }}
              // className={classes.fieldName}
            />
            {subDomain
              ? (
                <>
                  <Typography className={classes.subHeader} style={{ paddingTop: 20 }}>
                    Your Link:
                  </Typography>
                  <Typography style={{ maxWidth: 500, overflowX: 'auto', fontSize: '12px', color: '#00000080' }}>
                    <span>{`${domain}`}</span>/<span style={{ fontWeight: 600 }}>{`${contentPageState.attributes.page_name}`}</span>
                  </Typography>
                  <div style={{ display: 'flex', marginTop: '15px', marginBottom: '15px', alignItems: 'center' }}>
                    <Tooltip title={!contentPageState.attributes.page_name ? canSave ? 'You must save before you can generate your link' : 'You must add a page name before you can generate your link' : ''}>
                      <div>
                        <Button
                          variant='outlined'
                          disabled={!contentPageState.attributes.page_name || canSave}
                          color='primary'
                          onClick={() => {
                            navigator.clipboard.writeText(`https://${domain}/${contentPageState.attributes.page_name}`)
                            setSnackState({
                              open: true,
                              variant: 'success',
                              message: 'Link copied to clipboard!'
                            })
                          }}
                        >
                          Copy to Clipboard
                        </Button>
                      </div>
                    </Tooltip>
                    <Tooltip title='Subdomain Settings' placement='right'>
                      <Link to='/settings/chat_settings/domains' style={{ textDecoration: 'none', marginLeft: '10px', marginTop: '5px' }}>
                        <SettingsIcon style={{ color: '#343434' }} />
                      </Link>
                    </Tooltip>
                  </div>
                  <div>
                    <Tooltip title={!contentPageState.attributes.page_name ? canSave ? 'You must save before you can generate your link' : 'You must add a page name before you can generate your link' : ''}>
                      <div>
                        <Button
                          variant='outlined'
                          disabled={!contentPageState.attributes.page_name || canSave}
                          color='primary'
                          onClick={() => {
                            downloadQRCode()
                            setSnackState({
                              open: true,
                              variant: 'success',
                              message: 'Downloading QR Code!'
                            })
                          }}
                        >
                          Download QR Code
                        </Button>
                      </div>
                    </Tooltip>
                    <div style={{ display: 'none', position: 'absolute' }}>
                      <QRCodeCanvas
                        id='qrCodeEl'
                        includeMargin
                        size={350}
                        value={`https://${domain}/${contentPageState.attributes.page_name}`}
                      />
                    </div>
                  </div>
                </>
              )
              : (
                <div style={{ display: 'flex', paddingTop: 30 }}>
                  <ErrorIcon style={{ color: 'rgba(255, 188, 3)', marginRight: 10 }} />
                  <div>
                    In order to share your link, you need to set a subdomain first, <a href='/#/settings/chat_settings/domains?content-page-settings=1' target='_blank'>click here</a> to set one up!
                  </div>
                </div>)}
          </div>
          <div className={classes.card}>
            <Typography className={classes.title}>
              Page Title (optional)
            </Typography>
            <Typography className={classes.subtitle} style={{ marginBottom: '10px' }}>
              The page title will show up on a visitor’s browser tab
            </Typography>
            <TextField
              label='Title Name'
              name='page_title'
              fullWidth
              variant='outlined'
              margin='dense'
              value={contentPageState.attributes.page_title}
              onChange={e => handleChange('page_title', e.target.value)}
              // className={classes.fieldName}
            />
          </div>
        </div>
      </ScrollBox>
    </div>
  )
}
