import EmptyStateGraphic from 'img/Empty_State_Graphic.png'
import EmailStateGraphic from 'img/engagement_email_graphic.png'
import { makeStyles } from '@material-ui/core/styles'
import { useContext } from 'react'
import { ListPageContext } from '../ListPage'
import { objectMappingTypes, objectTypeMapping } from './ObjectTypeMapping'

const useStyles = makeStyles(theme => ({
  flexSection: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  noObject: {
    width: 500
  },
  selectTitle: {
    fontSize: 28
  },
  selectText: {
    width: 416,
    fontSize: 18,
    marginTop: 6,
    textAlign: 'center',
    color: theme.palette.background.mediumGrey
  }
}))

export const DefaultPane = (): JSX.Element => {
  const classes = useStyles()
  const { pageType } = useContext(ListPageContext)
  const mappedObject = objectTypeMapping[pageType as keyof objectMappingTypes]
  const emailImg = <img className={classes.noObject} src={EmailStateGraphic} alt='select one' />
  const emptyImg = <img className={classes.noObject} src={EmptyStateGraphic} alt='select one' />
  let objectType
  let stateImg
  if (mappedObject) {
    ({ objectType } = mappedObject)
  }

  if (objectType === 'email') {
    stateImg = emailImg
  } else {
    stateImg = emptyImg
  }

  return (
    <div className={classes.flexSection}>
      {stateImg}
      {objectType === 'email' ? (
        <>
          <div className={classes.selectTitle}>Select an {objectType}</div>
          <div className={classes.selectText}>Click on an {objectType} to see more details and how it’s performing, or double-click to edit</div>
        </>) : (
          <>
            <div className={classes.selectTitle}>Select a {objectType}</div>
            <div className={classes.selectText}>Click on a {objectType} to see more details and how it’s performing, or double-click to edit</div>
          </>)}
    </div>
  )
}
