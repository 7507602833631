import ModalWarning from 'components/ModalWarning'
import { ConversationContext } from 'pages/chat/agent-chat/ConversationContext'
import { useContext, useReducer, useEffect, SetStateAction, Dispatch } from 'react'
import salesloftCardStyles from './SalesloftCardStyles'
import SmallTextField from '../SmallTextField'
import { Button, Icon, Tooltip } from 'library/materialUI'
import CancelIcon from '@material-ui/icons/Cancel'
import Typography from '@material-ui/core/Typography'
import { createPerson } from 'api/cadence'
import { Select } from 'cf-components/material-wrappers/MaterialComponents'

const jobSeniorities = [
  { value: '', label: 'Set Automatically' },
  { value: 'unknown', label: 'Unknown' },
  { value: 'director', label: 'Director' },
  { value: 'executive', label: 'Executive' },
  { value: 'individual_contributor', label: 'Individual Contributor' },
  { value: 'manager', label: 'Manager' },
  { value: 'vice_president', label: 'Vice President' }
]

const timeZones = [
  { value: '', label: 'Set Automatically' },
  { value: 'Pacific/Pago_Pago', label: '(UTC-11:00) Pacific/Pago_Pago' },
  { value: 'US/Hawaii', label: '(UTC-10:00) US/Hawaii' },
  { value: 'US/Alaska', label: '(UTC-09:00) US/Alaska' },
  { value: 'US/Pacific', label: '(UTC-08:00) US/Pacific' },
  { value: 'US/Mountain', label: '(UTC-07:00) US/Mountain' },
  { value: 'US/Arizona', label: '(UTC-07:00) US/Arizona' },
  { value: 'US/Central', label: '(UTC-06:00) US/Central' },
  { value: 'US/Eastern', label: '(UTC-5:00) US/Eastern' },
  { value: 'America/Halifax', label: '(UTC-04:00) America/Halifax' },
  { value: 'America/Caracas', label: '(UTC-04:00) America/Caracas' },
  { value: 'America/St_Johns', label: '(UTC-03:30) America/St_Johns' },
  { value: 'America/Sao_Paulo', label: '(UTC-03:00) America/Sao_Paulo' },
  { value: 'America/Argentina/Buenos_Aires', label: '(UTC-03:00) America/Argentina/Buenos_Aires' },
  { value: 'Atlantic/Azores', label: '(UTC-01:00) Atlantic/Azores' },
  { value: 'Pacific/Auckland', label: '(UTC+13:00) Pacific/Auckland' },
  { value: 'Asia/Anadyr', label: '(UTC+12:00) Asia/Anadyr' },
  { value: 'Australia/Sydney', label: '(UTC+11:00) Australia/Sydney' },
  { value: 'Australia/Adelaide', label: '(UTC+10:30) Australia/Adelaide' },
  { value: 'Asia/Tokyo', label: '(UTC+09:00) Asia/Tokyo' },
  { value: 'Australia/Perth', label: '(UTC+08:00) Australia/Perth' },
  { value: 'Asia/Hong_Kong', label: '(UTC+08:00) Asia/Hong_Kong' },
  { value: 'Asia/Bangkok', label: '(UTC+07:00) Asia/Bangkok' },
  { value: 'Asia/Yangon', label: '(UTC+06:30) Asia/Yangon' },
  { value: 'Asia/Almaty', label: '(UTC+06:00) Asia/Almaty' },
  { value: 'Asia/Kathmandu', label: '(UTC+05:45) Asia/Kathmandu' },
  { value: 'Asia/Kolkata', label: '(UTC+05:30) Asia/Kolkata' },
  { value: 'Asia/Karachi', label: '(UTC+05:00) Asia/Karachi' },
  { value: 'Asia/Muscat', label: '(UTC+04:00) Asia/Muscat' },
  { value: 'Asia/Tehran', label: '(UTC+03:30) Asia/Tehran' },
  { value: 'Asia/Africa/Dar_es_Salaam', label: '(UTC+03:00) Africa/Dar_es_Salaam' },
  { value: 'Europe/Moscow', label: '(UTC+03:00) Europe/Moscow' },
  { value: 'Europe/Istanbul', label: '(UTC+03:00) Europe/Istanbul' },
  { value: 'Asia/Jerusalem', label: '(UTC+02:00) Asia/Jerusalem' },
  { value: 'Africa/Gaborone', label: '(UTC+02:00) Africa/Gaborone' },
  { value: 'Europe/Helsinki', label: '(UTC+02:00) Europe/Helsinki' },
  { value: 'Europe/Zurich', label: '(UTC+01:00) Europe/Zurich' },
  { value: 'Europe/Amsterdam', label: '(UTC+01:00) Europe/Amsterdam' },
  { value: 'Europe/London', label: '(UTC+00:00) Europe/London' }
]

const reducer = (state: any, action: any) => {
  if (action.type) {
    return {
      ...action.state,
      changes: {}
    }
  }
  return {
    ...state,
    [action.property]: action.value,
    changes: {
      ...state.changes,
      [action.property]: true
    }
  }
}

interface SalesloftWarningProps {
  cardState: string
  editMode: boolean
  setEditMode: Dispatch<SetStateAction<boolean>>
  integration: string
  setRefresh: Dispatch<SetStateAction<boolean>>
}

const personState = [
  'first_name',
  'last_name',
  'email',
  'phone',
  'title',
  'seniority',
  'city',
  'country',
  'state',
  'company_timezone'
]

function SalesloftCardWarning (props: SalesloftWarningProps): JSX.Element {
  const classes = salesloftCardStyles()
  const { saveContact, contact } = useContext(ConversationContext)
  const { cardState, editMode, setEditMode, integration, setRefresh } = props
  const [state, dispatch] = useReducer(reducer, { ...contact?.attributes, changes: {} })
  const emailWarning = 'Add an email address in the Contact info card to be able to add to Salesloft'
  const addPerson = () => {
    const saveData: any = {}
    personState.forEach((k) => {
      if (state[k] !== '') {
        saveData[k] = state[k]
      }
    })
    createPerson(saveData, integration)
      .then((_) => {
        saveContact(saveData)
        setRefresh(true)
      })
  }

  useEffect(() => {
    dispatch({ type: 'reset', state: contact?.attributes })
    // eslint-disable-next-line
  }, [contact?.attributes.first_name, contact?.attributes.last_name, contact?.attributes.phone, contact?.attributes.title])

  const basicProps = {
    state,
    dispatch,
    autoFocusID: editMode
  }
  if (cardState === 'missingEmail') {
    return (
      <div>
        <ModalWarning body={emailWarning} />
      </div>
    )
  } else if (cardState === 'error') {
    return (
      <div>
        <ModalWarning body='There was a problem looking up the related Salesloft info' type='error' />
      </div>
    )
  } else if (cardState === 'start') {
    return (
      <div>
        <ModalWarning body="We've run into an issue loading Salesloft data. Refresh to try again." />
        <Button
          style={{ width: '100%' }}
          variant='outlined'
          color='primary'
          onClick={() => setRefresh(true)}
        >
          <div style={{ display: 'flex', alignContent: 'center', marginRight: 5 }}><Icon icon='refresh' color='primary' specificSize={18} /></div> Refresh
        </Button>
      </div>
    )
  } else {
    return (
      <div>
        {editMode ? (
          <div>
            <div className={classes.actionsDiv}>
              <Tooltip title='Cancel'>
                <CancelIcon
                  color='primary'
                  className={classes.actionIcon}
                  onClick={() => setEditMode(false)}
                />
              </Tooltip>
            </div>
            <div className={classes.nameFields}>
              <SmallTextField
                {...basicProps}
                propertyID='first_name'
                label='First Name'
              />
              <SmallTextField
                {...basicProps}
                propertyID='last_name'
                label='Last Name'
              />
            </div>
            <div className={classes.field}>
              <SmallTextField
                {...basicProps}
                propertyID='email'
                label='Email'
                required
                errorMessage='Must have valid email'
              />
            </div>
            <div className={classes.field}>
              <SmallTextField
                {...basicProps}
                propertyID='phone'
                label='Phone'
              />
            </div>
            <div className={classes.field}>
              <SmallTextField
                {...basicProps}
                propertyID='title'
                label='Title'
              />
            </div>
            <div className={classes.addPersonSelectors}>
              <Select
                value={state.seniority}
                onChange={(e: any) => dispatch({ property: 'seniority', value: e })}
                options={jobSeniorities}
                label='Job Seniority'
                disableClear
              />
            </div>
            <div className={classes.nameFields}>
              <SmallTextField
                {...basicProps}
                propertyID='city'
                label='City'
              />
              <SmallTextField
                {...basicProps}
                propertyID='country'
                label='Country'
              />
              <SmallTextField
                {...basicProps}
                propertyID='state'
                label='State'
              />
            </div>
            <div className={classes.addPersonSelectors}>
              <Select
                value={state.company_timezone}
                onChange={(e: any) => { dispatch({ property: 'company_timezone', value: e }) }}
                options={timeZones}
                label='Time Zone'
                disableClear
              />
            </div>
            <div style={{ marginTop: 5 }}>
              <Button
                variant='contained'
                color='primary'
                onClick={() => {
                  addPerson()
                  setEditMode(false)
                }}
                disabled={Boolean(!state.email)}
              >
                ADD PERSON
              </Button>
            </div>
          </div>
        )
        : <Typography variant='subtitle1'>Person not yet created in Salesloft</Typography>}
      </div>
    )
  }
}

export default SalesloftCardWarning
