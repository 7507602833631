import { useContext, useReducer, useState, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { TenantDataContext } from '../tenant-data-context'
import { TextField, Select, Checkbox, ListItemText, ListSubheader, MenuItem, Tooltip } from '@material-ui/core'
import { Modal, ModalSection } from 'library/Modal'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import { Button } from 'library/materialUI'
import { IconButton } from 'library/materialUI/Button'

const useStyles = makeStyles(theme => ({
  searchDrawer: {
    padding: 10
  },
  border: {
    border: '1px solid #D3D3D3',
    borderRadius: 5,
    padding: '20px 10px 10px 20px',
    color: theme.palette.primary.main,
    fontSize: '14px',
    background: '#F5F5F5'
  },
  header: {
    color: '#656565',
    fontSize: '14px',
    paddingBottom: 5
  },
  buttons: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: 10,
    paddingTop: 10
  },
  filter: {
    marginTop: 10,
    marginBottom: 10,
    display: 'flex',
    alignItems: 'center',
    gridGap: 10,
    color: '#4A4A4A',
    '& > *': {
      flexShrink: 0
    }
  },
  objectSelect: {
    flexGrow: 1,
    flexShrink: 1,
    overflow: 'hidden',
    '& > *': {
      width: '100%'
    }
  }
}))

const MenuProps = {
  getContentAnchorEl: null // Prevents menu from jumping around (fixed in MUI v5, you can remove if we ever upgrade)
}
const MultiMenuProps = {
  getContentAnchorEl: null
}

const events = [
  { id: 'calendar_drop', name: 'Calendar Dropped' },
  { id: 'meeting_booked', name: 'Meeting Booked' },
  { id: 'email_captured', name: 'Email Captured' },
  { id: 'live_chat', name: 'Live Chat' }
]

function MultiSelectSearchable ({ option, state, updateObjects }: any) {
  const values = option.values
  const selectedValues = values.filter(v => state.includes(v.id)).map(v => v.name).join(', ')
  const [searchText, setSearchText] = useState('')
  const displayedValues = useMemo(
    () => values.filter((value) => {
      return value.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1 || state.indexOf(value.id) > -1
    }),
    [searchText, values, state]
  )

  return (
    <Select
      multiple
      value={state}
      onChange={(e) => updateObjects(e.target.value)}
      onOpen={() => setSearchText('')}
      variant='outlined'
      fullWidth
      margin='dense'
      renderValue={() => { return (selectedValues) }}
      MenuProps={MultiMenuProps}
      style={{ background: 'white' }}
    >
      <ListSubheader style={{ background: 'white' }}>
        <TextField
          size='small'
          placeholder='Type to search...'
          fullWidth
          onChange={(e) => setSearchText(e.target.value)}
          onKeyDown={(e) => {
            if (e.key !== 'Escape') {
              // Prevents autoselecting item while typing (default Select behaviour)
              e.stopPropagation()
            }
          }}
        />
      </ListSubheader>
      <MenuItem value='' disabled><em>{option.name}</em></MenuItem>

      {displayedValues.map(v => (
        <MenuItem key={v.id} value={v.id}>
          <Checkbox
            checked={state.indexOf(v.id) > -1}
            color='primary'
            style={{ padding: 5 }}
          />
          {v.name.length > 40 ? (
            <Tooltip title={v.name} placement='right'>
              <ListItemText primary={v.name} />
            </Tooltip>) : (
              <ListItemText primary={v.name} />
            )}
        </MenuItem>
      ))}
    </Select>
  )
}

function MultiSelect (props) {
  const option = props.option
  const values = option.values
  const state = props.state
  const updateObjects = props.updateObjects
  const selectedValues = values.filter(v => state.includes(v.id)).map(v => v.name).join(', ')

  return (
    <Select
      multiple
      value={state}
      onChange={(e) => updateObjects(e.target.value)}
      variant='outlined'
      fullWidth
      margin='dense'
      renderValue={() => { return (selectedValues) }}
      MenuProps={MenuProps}
      style={{ background: 'white' }}
    >
      <MenuItem value='' disabled><em>{option.name}</em></MenuItem>
      {values.map(v => (
        <MenuItem key={v.id} value={v.id}>
          <Checkbox
            checked={state.indexOf(v.id) > -1}
            color='primary'
            style={{ padding: 5 }}
          />
          <ListItemText primary={v.name} />
        </MenuItem>
      ))}
    </Select>
  )
}

function SingleSelect (props) {
  const option = props.option
  const values = option.values
  const state = props.state
  const updateObjects = props.updateObjects

  return (
    <Select
      value={state}
      onChange={(e) => updateObjects(e.target.value)}
      variant='outlined'
      fullWidth
      margin='dense'
      MenuProps={MenuProps}
      style={{ background: 'white' }}
    >
      <MenuItem value={undefined}><em>Clear {option.name}</em></MenuItem>
      {values.map((option, index) => (
        <MenuItem key={index} value={option.value}>
          <ListItemText primary={option.name} />
        </MenuItem>
      ))}
    </Select>
  )
}

function SearchBox (props) {
  const updateObjects = props.updateObjects

  return (
    <TextField
      variant='outlined'
      margin='dense'
      fullWidth
      style={{ marginTop: 0, background: 'white' }}
      value={props.state}
      onChange={(e) => updateObjects(e.target.value)}
    />
  )
}

const mapping = {
  multiselectsearch: MultiSelectSearchable,
  multiselect: MultiSelect,
  search: SearchBox,
  select: SingleSelect
}

function SearchOption (props) {
  const classes = useStyles()
  const option = props.option
  const Component = mapping[option.type]
  return (
    <div className={classes.filter}>
      <div style={{ width: 240 }}>
        <Component
          option={option}
          state={props.state}
          updateObjects={props.updateObjects}
        />
      </div>
    </div>
  )
}

function CriteriaSelect (props) {
  const value = props.value || ''
  const searchOptions = props.searchOptions
  return (
    <Select
      margin='dense'
      variant='outlined'
      value={value}
      onChange={(e) => props.updateCriteria(e.target.value)}
      displayEmpty
      style={{ width: 300, background: 'white' }}
    >
      <MenuItem value='' disabled>Filter Criteria</MenuItem>
      {searchOptions.map((option, index) => (
        <MenuItem value={option.id} key={index}>
          {option.name}
        </MenuItem>
      ))}
    </Select>
  )
}

function FilterGroup ({ filter, updateCriteria, updateObjects, removeFilter, searchOptions, state, dispatch }: { filter: any, updateCriteria: any, updateObjects: any, removeFilter: any, searchOptions: any, state: any, dispatch: any }) {
  const classes = useStyles()
  const kind = filter.kind
  let criteriaSelectText = 'in this list'
  if (['review', 'status'].includes(kind)) {
    criteriaSelectText = 'as'
  }
  const option = searchOptions[searchOptions.findIndex(o => o.id === kind)]
  return (
    <div className={classes.filter}>
      <CriteriaSelect
        value={kind}
        updateCriteria={updateCriteria}
        searchOptions={searchOptions}
      />
      {kind && <div>{criteriaSelectText}</div>}
      {kind && option &&
        <SearchOption
          option={option}
          state={state.filters[state.filters.findIndex(f => f.kind === kind)].values}
          updateObjects={updateObjects}
          dispatch={dispatch}
        />}
      <div style={{ marginLeft: 'auto' }}>
        <IconButton
          color='#9933ff'
          onClick={removeFilter}
          icon='outlinedCancel'
        />
      </div>
    </div>
  )
}

const initialState = {
  viewName: '',
  viewID: 0,
  editView: null,
  filters: [{ kind: '', values: [] }]
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'init':
      return action.data
    case 'nameChange':
      return { ...state, viewName: action.data }
    case 'newFilters':
      return { ...state, filters: action.data }
    default:
      return state
  }
}

export default function ConversationsViewModal ({ open, editView, onHide, save }: { open: boolean, editView: any, onHide: any, save: any }) {
  const classes = useStyles()
  const { users, tags, bots } = useContext(TenantDataContext)
  const userList = users ? users.list.map(u => ({ id: u.id, name: u.name })) : []
  const botList = bots ? bots.map(b => ({ id: b.id, name: b.attributes.name })) : []
  const tagList = tags ? tags.map(t => ({ id: t.id, name: t.attributes.tag_name })) : []

  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    viewName: editView ? editView.attributes.name : '',
    viewID: editView ? editView.id : 0,
    editView: editView,
    filters: editView ? editView.attributes.filters : [{ kind: '', values: [] }]
  })

  const updateCriteria = (index, value) => {
    const newList = [...state.filters]
    newList[index] = { kind: value, values: [] }
    dispatch({ type: 'newFilters', data: newList })
  }

  const updateObjects = (index, values) => {
    const newList = [...state.filters]
    newList[index] = { ...newList[index], values: values }
    dispatch({ type: 'newFilters', data: newList })
  }

  const removeFilter = (index) => {
    const newList = [...state.filters]
    newList.splice(index, 1)
    dispatch({ type: 'newFilters', data: newList })
  }

  const addFilter = () => {
    const newList = [...state.filters]
    newList.push({ kind: '', values: [] })
    dispatch({ type: 'newFilters', data: newList })
  }

  const attributes = {
    id: state.viewID,
    name: state.viewName,
    private: true,
    filters: state.filters
  }

  const reviewOptions = [
    { name: 'Reviewed', value: true },
    { name: 'Needs review', value: false }
  ]
  const statusOptions = [
    { name: 'Open', value: 'open' },
    { name: 'Closed', value: 'closed' }
  ]
  const searchOptions = [
    { id: 'visitor', name: 'Visitor name or email', type: 'search' },
    { id: 'domain', name: 'Visitor domain', type: 'search' },
    { id: 'source_domain', name: 'Source domain', type: 'search' },
    { id: 'users', name: 'Agents', type: 'multiselectsearch', values: userList },
    { id: 'bots', name: 'Bots', type: 'multiselectsearch', values: botList },
    { id: 'tag_ids', name: 'Tags', type: 'multiselectsearch', values: tagList },
    { id: 'events', name: 'Events', type: 'multiselect', values: events },
    { id: 'status', name: 'Conversation Status', type: 'select', values: statusOptions },
    { id: 'review', name: 'Review Status', type: 'select', values: reviewOptions }
  ]

  return (
    <Modal
      title={editView ? 'Edit View' : 'Create View'}
      open={open}
      onHide={onHide}
      handleSave={() => save(attributes)}
      size='md'
      saveIcon='save'
      saveDisabled={!state.viewName || state.filters.some(filter => filter.kind === '' || filter?.values.length === 0) || state?.filters.length === 0}
      saveDisabledTooltip='Please enter a view name and add at least one filter'
    >
      <ModalSection
        title='View Name'
        subtitle='Enter a name for your view'
      >
        <TextField
          value={state.viewName}
          margin='dense'
          onChange={(e) => dispatch({ type: 'nameChange', data: e.target.value })}
          variant='outlined'
          label='View Name'
          required
        />
      </ModalSection>
      <ModalSection
        title='Filters'
        subtitle='Conversations must meet ALL of the criteria listed here to be included in the group'
      >
        <div className={classes.border}>
          <div>
            Conversation Filter Group
          </div>
          {state.filters.map((filter, index) => {
            const lastOne = index === state.filters.length - 1
            return (
              <div key={index}>
                <FilterGroup
                  filter={filter}
                  updateCriteria={(value) => updateCriteria(index, value)}
                  updateObjects={(value) => updateObjects(index, value)}
                  removeFilter={() => removeFilter(index)}
                  searchOptions={searchOptions}
                  state={state}
                  dispatch={dispatch}
                />
                {!lastOne && <div>AND</div>}
              </div>
            )
          })}
          <div style={{ marginTop: 20 }}>
            <Button
              startIcon={<AddCircleIcon />}
              color='primary'
              onClick={addFilter}
              variant='text'
              style={{ color: '#9933ff' }}
            >
              Add Filter
            </Button>
          </div>
        </div>
      </ModalSection>
    </Modal>
  )
}
