import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  nodeBody: {
    fontSize: '13px'
    // borderBottom: '1px solid rgba(0,0,0,0.1)'
  }
}))

function EndBody (): JSX.Element {
  return (
    <div>
      No further actions
    </div>
  )
}

export function NodeBody ({ base, nodeMapping }: any): JSX.Element {
  const classes = useStyles()
  const kind = base.state.kind
  const Component = kind.startsWith('End') ? EndBody : nodeMapping[kind].body

  if (Component) {
    return (
      <div className={classes.nodeBody}>
        <Component
          settings={base.state.settings}
          parent={base}
        />
      </div>
    )
  }
  return (
    <div />
  )
}
