import { syncContactsToEloqua } from 'api/eloqua'
import { syncContactToHubSpot, getHubSpotId } from 'api/hubspot'
import { getIntegration, getCredentialsStatus, INTEGRATIONS, getIntegrationObjectMappings } from 'api/integrations'
import { syncContactToSalesforce } from 'api/salesforce'

/**
 * @typedef {{ [integration_name: string]: {
 * integration_object_id: string;
 * last_sync_timestamp: string;
 * }}} IntegrationObjectMappings
 */
/**
 * Get the integrationObjectMapping for a contactID
 * @param {number} contactID
 * @returns {Promise<IntegrationObjectMappings>}
 */
export const getContactIntegrationMappings = contactID => getIntegrationObjectMappings({
  chatfunnelsObjectType: 'people', chatfunnelsObjectID: contactID
}).then(res => {
  if (res.errors || !res.data?.length) {
    return {}
  }
  const updatedIntegrations = res.data.reduce((acc, cur) => {
    acc[cur.attributes.integration_name] = {
      integration_object_id: cur.attributes.integration_object_id,
      last_sync_timestamp: cur.attributes.last_sync_timestamp
    }
    return acc
  }, {})
  return updatedIntegrations
})

export const salesforce = {
  /**
   * Check if SalesForce is enabled in this tenant and authorized
   * @param {boolean} [debugMode] - Log error messages
   * @returns {Promise<boolean>}
   */
  isEnabled: debugMode => getIntegration(INTEGRATIONS.Salesforce).then(response => {
    if (!response.ok) {
      throw Error('Failed to get SalesForce Integration')
    }
    if (!response.data?.attributes?.enabled) {
      throw Error('SalesForce Integration not enabled')
    }
    return getCredentialsStatus(INTEGRATIONS.Salesforce)
  }).then(response => {
    if (!response.data.attributes.has_credentials) {
      throw Error('SalesForce Oauth does not have credentials')
    }
    return true
  }).catch(err => {
    debugMode && console.log('ERROR: ', err.message)
    return false
  }),
  /**
   * Check if SalesForce is disabled in this tenant
   * @returns {Promise<boolean>}
   */
  isDisabled: () => getIntegration(INTEGRATIONS.Salesforce).then(response => {
    if (!response.ok) {
      throw Error('Failed to get SalesForce Integration')
    }
    if (response.data?.attributes?.config?.disable_sync_from_cf) {
      return true
    }
    return false
  }).catch(() => true),
  /**
   * @param {{ personID?: number; personIDs?: number[]; }} param0 One of the two required
   * @returns {Promise<{ ok: boolean; errors?: any[]; }>}
   */
  syncContact: ({ personID, personIDs }) => syncContactToSalesforce({ personID, personIDs }),
  /**
   * Each salesforce instance has a unique url. Fetch this to be able to add links opening
   * in salesforce.
   * @returns {string}
   */
  getInstanceUrl: () => getCredentialsStatus(INTEGRATIONS.Salesforce).then(response => {
    if (!response?.data.attributes.instance_url) {
      return ''
    }
    return response.data.attributes.instance_url
  }),
  /**
   * Open a contact or account in salesforce
   * @param {string} salesforceInstanceUrl - See {@link salesforce['getInstanceUrl']}
   * @param {string} salesforceObjectID - The ID of the object in salesforce which is linked to a chatfunnels contact or account
   */
  openInSalesforce: (salesforceInstanceUrl, salesforceObjectID) => window.open(
    `${salesforceInstanceUrl}/${salesforceObjectID}`,
    '_blank'
  ),
  /**
   * Check if a user's tenant has a subscription that permits salesforce integration
   * @param {import('session-context').User} user
   * @return {boolean}
   */
  isPermitted: user => Object.prototype.hasOwnProperty.call(user.attributes.perms, 'tenant_salesforce'),
  getColoredSVG: color => {
    const imageTemplate = `<svg width="17" height="12" xmlns="http://www.w3.org/2000/svg"><path d="M7.063 1.426a2.832 2.832 0 012.049-.88 2.86 2.86 0 012.493 1.478c.43-.193.907-.3 1.409-.3 1.924 0 3.483 1.574 3.483 3.515 0 1.94-1.56 3.514-3.483 3.514-.235 0-.465-.024-.687-.069a2.546 2.546 0 01-3.336 1.048 2.906 2.906 0 01-5.4-.131 2.679 2.679 0 01-.555.058c-1.49 0-2.697-1.22-2.697-2.726 0-1.008.542-1.889 1.348-2.36a3.136 3.136 0 012.88-4.38c1.018 0 1.923.483 2.496 1.233" fill="${color}" fill-rule="evenodd"/></svg>`
    const base64Data = Buffer.from(unescape(encodeURIComponent(imageTemplate))).toString('base64')
    return `data:image/svg+xml;base64,${base64Data}`
  }
}

export const hubSpot = {
  /**
   * Check if HubSpot is enabled in this tenant and authorized
   * @param {boolean} [debugMode] - Log error messages
   * @returns {Promise<boolean>}
   */
  isEnabled: debugMode => getIntegration(INTEGRATIONS.HubSpot).then(response => {
    if (!response.ok) {
      throw Error('Failed to get HubSpot Integration')
    }
    if (!response.data?.attributes?.enabled) {
      throw Error('HubSpot Integration not enabled')
    }
    return getCredentialsStatus(INTEGRATIONS.HubSpot)
  }).then(response => {
    if (!response.data.attributes.has_credentials) {
      throw Error('HubSpot Oauth does not have credentials')
    }
    return true
  }).catch(err => {
    debugMode && console.log('ERROR: ', err.message)
    return false
  }),
  /**
   * Get the id of the tenant's hubspot instance
   * @returns {Promise<string>} hubSpotUserID
   */
  getInstanceID: () => getHubSpotId().then(res => {
    if (!res?.data?.attributes?.hub_id) {
      return null
    } else {
      return res.data.attributes.hub_id
    }
  }),
  /**
   * @param {{ personID?: number; personIDs?: number[]; }} param0 One of the two required
   * @returns {Promise<boolean>}
   */
  syncContact: ({ personID, personIDs }) => syncContactToHubSpot({ personID, personIDs }),
  /**
   * Open a contact or account in salesforce
   * @param {string} hubSpotInstanceID - See {@link hubSpot['getInstanceID']}
   * @param {string} hubSpotObjectID - The ID of the object in hubspot which is linked to a chatfunnels contact
   */
  openInHubSpot: (hubSpotInstanceID, hubSpotObjectID) => window.open('https://app.hubspot.com/contacts/' + hubSpotInstanceID + '/contact/' + hubSpotObjectID + '/', '_blank'),
  /**
   * Check if a user's tenant has a subscription that permits hubspot integration
   * @param {import('session-context').User} user
   * @return {boolean}
   */
  isPermitted: user => Object.prototype.hasOwnProperty.call(user.attributes.perms, 'tenant_hubspot'),
  getColoredSVG: color => {
    const imageTemplate = `<svg width="248" height="246" xmlns="http://www.w3.org/2000/svg"><path d="M157 156c-11.046 0-20-8.955-20-19.999 0-11.046 8.954-20.001 20-20.001s20 8.955 20 20.001c0 11.044-8.954 19.999-20 19.999m5.887-58.29V80.09c4.657-2.172 7.919-6.825 7.919-12.225v-.406c0-7.453-6.174-13.55-13.72-13.55h-.41c-7.545 0-13.718 6.097-13.718 13.55v.406c0 5.4 3.262 10.055 7.919 12.227v17.619c-6.934 1.058-13.269 3.883-18.494 8.04L83.398 68.115c.323-1.226.55-2.488.552-3.814.008-8.439-6.909-15.29-15.457-15.302C59.95 48.99 53.01 55.823 53 64.264c-.01 8.44 6.907 15.292 15.455 15.302 2.784.003 5.363-.778 7.622-2.047l48.185 37.021c-4.097 6.109-6.498 13.426-6.498 21.304 0 8.247 2.638 15.876 7.095 22.15l-14.652 14.473c-1.159-.344-2.36-.583-3.634-.583-7.022 0-12.716 5.622-12.716 12.557 0 6.937 5.694 12.559 12.716 12.559 7.024 0 12.717-5.622 12.717-12.56 0-1.254-.243-2.441-.591-3.586l14.494-14.315c6.58 4.959 14.774 7.939 23.69 7.939 21.605 0 39.117-17.298 39.117-38.634 0-19.316-14.368-35.273-33.113-38.133" fill="${color}" fill-rule="evenodd"/></svg>`
    const base64Data = Buffer.from(unescape(encodeURIComponent(imageTemplate))).toString('base64')
    return `data:image/svg+xml;base64,${base64Data}`
  }
}

export const eloqua = {
  /**
   * Check if Eloqua is enabled in this tenant and authorized
   * @param {boolean} [debugMode] - Log error messages
   * @returns {Promise<boolean>}
   */
  isEnabled: debugMode => getIntegration(INTEGRATIONS.Eloqua).then(response => {
    if (!response.ok) {
      throw Error('Failed to get Eloqua Integration')
    }
    if (!response.data?.attributes?.enabled) {
      throw Error('Eloqua Integration not enabled')
    }
    return getCredentialsStatus(INTEGRATIONS.Eloqua)
  }).then(response => {
    if (!response.data.attributes.has_credentials) {
      throw Error('Eloqua Oauth does not have credentials')
    }
    return true
  }).catch(err => {
    debugMode && console.log('ERROR: ', err.message)
    return false
  }),
  /**
   * Get the id of the tenant's Eloqua instance
   * @returns {Promise<string>} EloquaUserID
   */
  // getInstanceID: () => getHubSpotId().then(res => {
  //   if (!res?.data?.attributes?.hub_id) {
  //     return null
  //   } else {
  //     return res.data.attributes.hub_id
  //   }
  // }),
  /**
   * @param {{ personID?: number; personIDs?: number[]; }} param0 One of the two required
   * @returns {Promise<boolean>}
   */
  syncContact: ({ personID, personIDs = [] }) => syncContactsToEloqua({ personID, personIDs }).then(res => res.ok).catch(() => false),
  /**
   * Open a contact or account in salesforce
   * @param {string} hubSpotInstanceID - See {@link hubSpot['getInstanceID']}
   * @param {string} hubSpotObjectID - The ID of the object in hubspot which is linked to a chatfunnels contact
   */
  // openInEloqua: (hubSpotInstanceID, hubSpotObjectID) => window.open('https://app.hubspot.com/contacts/' + hubSpotInstanceID + '/contact/' + hubSpotObjectID + '/', '_blank'),
  /**
   * Check if a user's tenant has a subscription that permits Eloqua integration
   * @param {import('session-context').User} user
   * @return {boolean}
   */
  // TODO: Update permission for tenant_eloqua & check settings/integrations/IntegrationsList.jsx as it uses tenant_hubspot as well
  isPermitted: user => Object.prototype.hasOwnProperty.call(user.attributes.perms, 'tenant_salesforce'),
  getColoredSVG: color => {
    const imageTemplate = `<svg xmlns="http://www.w3.org/2000/svg" width="240" height="210" viewBox="170 75 240 180"><path d="M237.981 61.872c35.575 0 64.475 28.53 65.14 63.952.631 20.64 17.57 37.143 38.328 37.143 20.78 0 37.718-16.503 38.36-37.143h26.788c-.618 35.401-29.584 63.944-65.148 63.944-35.562 0-64.498-28.543-65.15-63.944-.622-20.616-17.571-37.163-38.318-37.163-20.792 0-37.708 16.547-38.34 37.163H172.82c.653-35.421 29.576-63.952 65.161-63.952" fill="#94979a"/><path d="M237.981 189.768c35.575 0 64.475-28.52 65.14-63.944.631-20.616 17.57-37.163 38.328-37.163 20.65 0 37.478 16.33 38.339 36.739l.021 141.36h26.788V125.532c-.792-35.281-29.65-63.66-65.148-63.66-35.562 0-64.498 28.53-65.15 63.952-.622 20.64-17.571 37.143-38.318 37.143-20.792 0-37.708-16.503-38.34-37.143H172.82c.653 35.424 29.576 63.944 65.161 63.944" fill="${color}"/></svg>`
    const base64Data = Buffer.from(unescape(encodeURIComponent(imageTemplate))).toString('base64')
    return `data:image/svg+xml;base64,${base64Data}`
  }
}
