import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import EditIcon from '@material-ui/icons/Edit'
import Input from '@material-ui/core/Input'

const useStyles = makeStyles(theme => ({
  titleDiv: {
    display: 'flex',
    marginLeft: 10
  },
  input: {
    color: 'rgba(0,0,0,0.65)',
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: 32
  }
}))

const EditableName = (props) => {
  const classes = useStyles()
  const [editMode, setEditMode] = useState(false)

  function remapEnterKey (event) {
    if (event.key === 'Enter') {
      setEditMode(false)
    }
  }

  if (editMode) {
    return (
      <div className={classes.titleDiv}>
        <Input
          value={props.name}
          disableUnderline
          onKeyPress={remapEnterKey}
          autoFocus
          classes={{ input: classes.input }}
          onChange={e => props.editName(e.target.value)}
          onBlur={() => setEditMode(false)}
        />
      </div>
    )
  }

  return (
    <div className={classes.titleDiv} onClick={() => setEditMode(true)}>
      <Typography variant='h5'>
        {props.name}
      </Typography>
      <div style={{ marginLeft: 10, display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        <EditIcon style={{ fontSize: '1.2em', color: '#7C7C7C' }} />
      </div>
    </div>
  )
}

export default EditableName
