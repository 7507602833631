import React, { useContext, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { components, SessionContext } from 'session-context'
import AppPage from 'cf-components/AppPage'
import { allBotTypes } from './botTypes'
import { Card, Typography } from '@material-ui/core'
import { FlowBotTemplates } from './botTemplates'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import NewBotModal from '../bot-edit/NewBotModal'
import { useHistory } from 'react-router-dom'
import { Icon, Dialog, Button } from 'library/materialUI'
import LandingPageGraphic from 'img/Landing Page Graphic.png'
import { triggerSalesBot } from 'pages/bot/InAppBot/triggerSupportBot'
import { hasComponent } from 'api/billing'
import { useQuery } from 'react-query'
import AccessControl from 'components/AccessControl'
import { FAQ_FLAG, ShareContext } from 'share-context'

const useStyles = makeStyles(theme => ({
  card: {
    borderRadius: 5,
    padding: 15,
    cursor: 'pointer',
    '&:hover': {
      transform: 'scale(1.05)',
      border: '2px solid #8E5AE2'
    },
    boxShadow: '0px 3px 6px #00000029',
    position: 'relative'
  },
  cardHeader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: '25px 0px'
  },
  cardContents: {
    fontSize: '0.9em',
    color: '#7A7A7A',
    justifyContent: 'center',
    alignItems: 'center'
  },
  cardTitle: {
    fontSize: '1.6em',
    marginBottom: 25,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center'
  },
  cardsDiv: {
    marginTop: 20,
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, 400px)',
    gridGap: 25,
    gridTemplateRows: 340
  },
  customBotsDiv: {
    minHeight: 275
  },
  templatePage: {
    display: 'grid',
    gridTemplateColumns: 'auto 430px'
  },
  paper: {
    minWidth: '648px',
    borderRadius: '10px'
  },
  title: {
    fontFamily: 'poppins',
    fontSize: '24px',
    padding: '10px'
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'poppins',
    fontSize: '14px',
    color: '#000000BF',
    opacity: '.75',
    padding: '20px'
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'flex-end',
    alignItems: 'flex-end'
  },
  learnMoreButton: {
    backgroundColor: 'white',
    color: '#00000080',
    fontSize: '14px',
    margin: '10px',
    marginBottom: '20px'
  },
  setupButton: {
    backgroundColor: theme.palette.primary.alt,
    color: 'white',
    margin: '10px',
    marginBottom: '20px',
    marginRight: '20px'
  }
}))

const breadcrumbs = [
  {
    link: '/engagement/chatbots/chatbots',
    text: 'Bot Roster'
  },
  {
    link: null,
    text: 'Bot Configuration'
  }
]

// function WarningModal (props) {
//   const classes = useStyles()
//   return (
//     <Modal
//       onHide={props.onHide}
//       open={props.open}
//       size='xs'
//       title='Setup your Subdomain!'
//       saveButtonText='Set-up Subdomain'
//       onSubmit={props.onSubmit}
//     >
//       <div className={classes.groupContainer}>
//         Hey! Before you can create your first conversational landing page you need to create a subdomain for your company.<br /><br />Example: <b>yourcompany</b>.chatfunnels.chat
//       </div>
//     </Modal>
//   )
// }

function BotTypeCard (props) {
  const classes = useStyles()
  const handleClick = props.handleClick
  const [modalOpen, setModalOpen] = useState(false)
  const { data: hasContentLandingPages = false } = useQuery('has-content-landing-pages', () => hasComponent('content-landing-pages'))

  return (
    <>
      <Card className={classes.card} elevation={2} onClick={() => props.type === 'content_page' && !hasContentLandingPages ? setModalOpen(true) : handleClick(props.type)}>
        <div className={classes.iconContainer}>
          <Icon icon={props.icon} specificSize={100} color='#343434' />
        </div>
        <div className={classes.cardHeader}>
          <div className={classes.cardTitle}>
            {props.title}
          </div>
        </div>
        <div className={classes.cardContents}>
          {props.contents}
        </div>
        {props.type === 'content_page' && !hasContentLandingPages && (
          <div onClick={() => setModalOpen(true)} style={{ position: 'absolute', padding: '5px', top: 15, right: 15, borderRadius: '50px', border: '1px solid #9933FF', color: '#9933FF', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            <div>
              <Icon icon='upArrow' color='#9933FF' size='sm' />
            </div>
            <div>
              Upgrade
            </div>
          </div>
        )}
        {props.type === 'content_page' && modalOpen && (
          <Dialog
            onClose={() => setModalOpen(false)}
            open={modalOpen}
            fullWidth
            classes={{ paper: classes.paper }}
          >
            <div>
              <img src={LandingPageGraphic} alt='landing-page-graphic' style={{ width: '648px' }} />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
              <div className={classes.title}>
                Upgrade to convert with landing page bots
              </div>
              <div className={classes.body}>
                <div>
                  With landing pages, you can:
                </div>
                <div>
                  <ul>
                    <li>Quickly and easily create simple, no-code landing pages that put visitors in touch with your team immediately.</li>
                    <li>Identify readers of your content and encourage them to stay engaged and continue the conversation without needing to gate your content.</li>
                  </ul>
                </div>
              </div>
              <div className={classes.footer}>
                {/* hidden for now until we have the proper page
                <div>
                  <Button
                    className={classes.learnMoreButton}
                    variant='contained'
                    onClick={() => null}
                  >
                    LEARN MORE
                  </Button>
                </div> */}
                <div>
                  <Button
                    className={classes.setupButton}
                    variant='contained'
                    onClick={() => triggerSalesBot()}
                  >
                    TALK TO SALES
                  </Button>
                </div>
              </div>
            </div>
          </Dialog>
        )}
      </Card>
    </>
  )
}

function BotTemplateSelection (props) {
  const classes = useStyles()
  const customBotDescription = 'Add custom skills and branches to build a bot that does anything you need'
  const handleBotClick = props.handleBotClick
  const { flags } = useContext(ShareContext)
  return (
    <div className={classes.templatePage}>
      <div>
        <Typography variant='h5'>
          Build a Funnel Bot using a premade template
        </Typography>
        <div className={classes.cardsDiv}>
          {FlowBotTemplates.map((bot, index) => {
            if (bot.id === 'homepage_faq' && !flags[FAQ_FLAG]) return <></>
            return (
              <BotTypeCard
                key={index}
                title={bot.name}
                contents={bot.description}
                type={bot.type}
                handleClick={() => handleBotClick({ kind: 'flow', template: bot.id })}
                icon={bot.icon}
              />
            )
          })}
        </div>
      </div>
      <div style={{ padding: '0px 30px', borderLeft: '1px solid #D9D9D9' }}>
        <Typography variant='h5'>
          Or start from scratch...
        </Typography>
        <div className={classes.cardsDiv}>
          <BotTypeCard
            title='Custom Bot'
            contents={customBotDescription}
            type='custom'
            handleClick={() => handleBotClick({ kind: 'flow' })}
            icon='funnelBot'
          />
        </div>
      </div>
    </div>
  )
}

function BotTypeSelection (props) {
  const classes = useStyles()
  const templateSelect = props.templateSelect
  const handleBotClick = props.handleBotClick

  if (templateSelect) {
    return (
      <BotTemplateSelection
        handleBotClick={handleBotClick}
      />
    )
  }

  return (
    <div className={classes.customBotsDiv}>
      <Typography variant='h5'>
        Select one of the following bot types:
      </Typography>
      <div className={classes.cardsDiv}>
        {props.botTypes.map((bot, index) => (
          <BotTypeCard
            key={index}
            title={bot.title}
            contents={bot.contents}
            type={bot.type}
            handleClick={() => handleBotClick({ kind: bot.type })}
            icon={bot.icon}
          />
        ))}
      </div>
    </div>
  )
}

export default function BotSelectionPage (props) {
  const [templateSelect, setTemplateSelect] = useState(null)
  const [botDetails, setBotDetails] = useState(null)
  const { user } = useContext(SessionContext)
  const history = useHistory()
  const userPerms = user?.attributes.perms

  function handleBotClick ({ kind, template }) {
    switch (kind) {
      case 'email':
        history.push('/emails/new')
        break
      case 'flow':
        if (template) {
          setBotDetails({ kind: kind, template: template })
        } else if (templateSelect) {
          setBotDetails({ kind: kind })
        } else {
          setTemplateSelect(true)
        }
        break
      default:
        setBotDetails({ kind: kind })
        break
    }
  }

  const allowedBotTypes = useMemo(() => allBotTypes.filter((bot) => {
    const requiredPerm = bot.requiredPerm
    if (!requiredPerm || userPerms[requiredPerm]) {
      return true
    }
    return false
  }), [userPerms])

  const actions = {
    actionText: 'Go Back',
    action: () => setTemplateSelect(false),
    icon: <ArrowBackIcon />
  }

  const actionProps = templateSelect ? actions : {}

  return (
    <AccessControl requiredComponent={components.CHAT}>
      <AppPage
        title='Select Bot Type'
        breadcrumbs={breadcrumbs}
        {...actionProps}
      >
        <div style={{ padding: 20 }}>
          <BotTypeSelection
            botTypes={allowedBotTypes}
            templateSelect={templateSelect}
            handleBotClick={handleBotClick}
          />
          <NewBotModal
            open={Boolean(botDetails)}
            onHide={() => setBotDetails(null)}
            botDetails={botDetails}
          />
        </div>
      </AppPage>
    </AccessControl>
  )
}
