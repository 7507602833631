import { makeStyles } from '@material-ui/core/styles'
import { IconType } from 'library/materialUI/Icon'
import { Icon, Paper, Typography, Tooltip, Button } from 'library/materialUI'
import { ContentsProps } from 'pages/onboarding/wizard/OnboardingWizard'
import { useEffect, useState } from 'react'
import { getInstallScript } from 'api/settings'
import WordpressIcon from '../images/wordpress.svg'
import ReactPlayer from 'react-player'

export const installationSubtitle = 'Add Signals to your website in order to ' +
'identify and engage with web traffic'

const useStyles = makeStyles(theme => ({
  installCard: {
    border: '1px solid #00000040',
    borderRadius: 4,
    height: 120,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  cardSection: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: 15
  },
  cardText: {
    font: 'normal normal normal 12px/25px Poppins',
    color: '#000000BF',
    marginTop: -8
  },
  wordpressInstallation: {
    padding: '20px 0px',
    display: 'grid',
    gridTemplateColumns: 'minmax(0,1fr) 50px',
    gridGap: 10
  },
  wordpressScript: {
    width: 'calc(100% - 20px)',
    padding: 10
  },
  installationInstructions: {
    font: 'normal normal normal 14px/21px Poppins',
    color: '#00000080'
  },
  // Responsive player with 16:9 aspect ratio - from react-player docs
  playerWrapper: {
    position: 'relative',
    paddingTop: '56.25%'
  },
  reactPlayer: {
    position: 'absolute',
    top: 0,
    left: 0
  }
}))

type TInstallationProps = {
  instructions: string
  videoLink: string
}

const installationTypes: Record<string, TInstallationProps> = {
  manual: {
    instructions: 'Copy and paste the code below into the global footer of your site. Need help? Check out the video below:',
    videoLink: 'https://www.youtube.com/watch?v=qPenW2UxbuU'
  },
  wordpress: {
    instructions: 'Install the Signals plugin, activate it, then paste the code below into the plugin settings page. Need help? Check out the video below:',
    videoLink: 'https://www.youtube.com/watch?v=zoA3Nae8GjI&t=3s'
  }
}

type InstallationPageProps = {
  type: string
  script: string
}

function InstallationPage ({ type, script }: InstallationPageProps) {
  const classes = useStyles()
  const installType = installationTypes[type]

  return (
    <div>
      <div className={classes.installationInstructions}>
        {installType.instructions}
      </div>
      <div className={classes.wordpressInstallation}>
        <Paper elevation={3} className={classes.wordpressScript}>
          <Typography noWrap>{script}</Typography>
        </Paper>
        <Tooltip title='Copy' placement='bottom'>
          <Button
            style={{ minWidth: 0 }}
            variant='contained'
            color='primary'
            onClick={() => {
                navigator.clipboard.writeText(script)
              }}
          >
            <Icon icon='clone' />
          </Button>
        </Tooltip>
      </div>
      <div className={classes.playerWrapper}>
        <ReactPlayer
          className={classes.reactPlayer}
          url={installType.videoLink}
          width='100%'
          height='100%'
        />
      </div>
    </div>
  )
}

type TInstallCard = {
  icon: IconType | 'wordpress'
  type: string
  tooltip: string
  title: string
}

function InstallCard ({ card }: { card: TInstallCard }) {
  const classes = useStyles()
  return (
    <div className={classes.installCard}>
      <div style={{ textAlign: 'center' }}>
        <div style={{ height: 32 }}>
          {card.icon === 'wordpress'
          ? <img src={WordpressIcon} alt='wordpress' height={24} />
            : <Icon
                icon={card.icon}
                color='primary'
                size='lg'
              />}
        </div>
        <div className={classes.cardText}>
          {card.title}
        </div>
      </div>
    </div>
  )
}

const cards: TInstallCard[] = [
  { icon: 'code', type: 'manual', title: 'Manual', tooltip: 'Manually add a javascript snippet to your site' },
  { icon: 'wordpress', type: 'wordpress', title: 'WordPress', tooltip: 'Add the Signals plugin to your Wordpress site' }
]

export function InstallationSection (props: ContentsProps) {
  const classes = useStyles()
  const [type, setType] = useState('')
  const [installScript, setInstallScript] = useState('')

  useEffect(() => {
    getInstallScript().then((response) => {
      setInstallScript(response.data.attributes.script)
    })
  }, [])

  if (type) {
    props.handleBackOverrideRef.current = () => setType('')
  } else {
    props.handleBackOverrideRef.current = null
  }

  if (type) {
    return (
      <InstallationPage
        type={type}
        script={installScript}
      />
    )
  }

  return (
    <div>
      <div className={classes.cardSection}>
        {cards.map((card, index) => (
          <div key={index} onClick={() => setType(card.type)}>
            <InstallCard
              card={card}
            />
          </div>
        ))}
      </div>
    </div>
  )
}
