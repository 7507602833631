import { Typography } from 'library/materialUI'
import { Icon, IconType } from 'library/materialUI/Icon'

type ColorTypes = 'primary' | 'inherit' | 'initial' | 'secondary' | 'textPrimary' | 'textSecondary' | 'error' | undefined
interface LabelProps {
  label: string
  endIcon?: IconType
  startIcon?: IconType
  color?: ColorTypes
  onClick?: () => void
  variant?: 'h1' | 'h2' | 'h3'| 'h4' | 'body2'
  iconSize?: 'sm' | 'md' | 'lg'
}

export default function Label ({ label, endIcon, startIcon, color, onClick, variant, iconSize } : LabelProps) {
  const displayEndIcon = endIcon ? <span style={{ marginLeft: 8 }}><Icon icon={endIcon} color={color} /></span> : undefined
  const displayStartIcon = startIcon ? <span style={{ marginRight: 8 }}><Icon icon={startIcon} color={color} size={iconSize} /></span> : undefined
  return (
    <Typography
      onClick={() => onClick ? onClick() : ({})}
      style={{ display: 'flex', alignItems: 'center', cursor: onClick ? 'pointer' : undefined }}
      color={color}
      variant={variant}
    >
      {displayStartIcon}
      {label}
      {displayEndIcon}
    </Typography>
  )
}
