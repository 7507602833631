import { useDoQuery } from './useDoQuery'
import { doPost, doPatch, doDelete } from 'api/api'
import { GenericObject } from './genericObject'
import { DateType, castToDate } from './classHelpers'

interface ConsentFormList {
  list: ConsentForm[]
  dict: { [id: ConsentForm['id']]: ConsentForm }
}

const defaultForm = {
  language: 'English',
  name: '',
  consent_message: '',
  privacy_policy_link: null,
  privacy_policy_link_text: null,
  opt_in_button: '',
  opt_out_button: '',
  opt_in_confirmation_msg: '',
  opt_out_confirmation_msg: '',
  enabled: false,
  parent_forms: []
}

export default class ConsentForm extends GenericObject {
  language: string
  consentMessage: string
  privacyPolicyLink: string
  privacyPolicyLinkText: string
  optInButtonText: string
  optOutButtonText: string
  optInConfirmationMsg: string
  optOutConfirmationMsg: string
  enabled: boolean
  readonly deletedTimestamp: DateType
  readonly parentForms: number[]

  get subtitle (): string { return this.consentMessage.substring(0, 25) + '...' }
  get searchField (): string { return this.name }

  constructor ({ row }: { row?: Record<string, any> } = {}) {
    super({ row })
    const consentForm = row?.attributes || defaultForm
    this.language = consentForm.language
    this.consentMessage = consentForm.consent_message
    this.privacyPolicyLink = consentForm.privacy_policy_link
    this.privacyPolicyLinkText = consentForm.privacy_policy_link_text
    this.optInButtonText = consentForm.opt_in_button
    this.optOutButtonText = consentForm.opt_out_button
    this.optInConfirmationMsg = consentForm.opt_in_confirmation_msg
    this.optOutConfirmationMsg = consentForm.opt_out_confirmation_msg
    this.enabled = consentForm.enabled
    this.deletedTimestamp = castToDate(consentForm.deleted_timestamp)
    this.parentForms = consentForm.parent_forms
  }

  static loadAll (): { data: ConsentFormList, isLoading: boolean } {
    return useDoQuery({
      path: '/consent_forms',
      useChatURL: true,
      objectClass: ConsentForm,
      initialData: { list: [], dict: {} }
    })
  }

  static update ({ ID, updates, changes } : {ID: ConsentForm['id'], updates: Partial<ConsentForm>, changes:Array<any>}): Promise<any> {
    const updateAttributes = ['name', 'enabled']
    const noNewForm = changes.length === 1 && updateAttributes.includes(changes[0])
    if (noNewForm) {
      const data = {
        id: ID,
        attributes: updates
      }
      return doPatch({ path: `/consent_forms/${ID}`, data, useChatURL: true })
    }

    if (updates.parentForms) {
      updates.parentForms.push(ID)
    }
    return this.save(updates)
  }

  static save (data: Partial<ConsentForm>) : Promise<any> {
    // rename attributes for backend
    delete Object.assign(data, { optInButton: data.optInButtonText }).optInButtonText
    delete Object.assign(data, { optOutButton: data.optOutButtonText }).optOutButtonText
    const body = {
      attributes: data
    }

    return doPost({ path: '/consent_forms', data: body, useChatURL: true })
  }

  delete (): Promise<any> {
    const path = `/consent_forms/${this.id}`
    return doDelete({ path: path, useChatURL: true })
  }
}
