import GenericObject from './genericObject'
import { useDoQuery } from './useDoQuery'
import { doPatch, doPut } from 'api/api'
import * as yup from 'yup'
import { YupRequiredString } from 'pages/bot/flow-builder/nodes/nodeHelpers'

export const OktaSettingsSchema = yup.object().shape({
  clientId: YupRequiredString,
  clientSecret: YupRequiredString,
  authDomain: YupRequiredString,
  companyName: YupRequiredString
})

export const defaultOktaIntegrationData = {
  clientId: '',
  clientSecret: '',
  authDomain: '',
  companyName: ''
}
export class OktaIntegrationSettings extends GenericObject {
  clientId: string
  clientSecret: string
  authDomain: string
  companyName: string

  static load = (tenantId: number | undefined): { settings: OktaIntegrationSettings, isLoading: boolean } => {
    const tId = tenantId !== undefined ? tenantId : 0
    const { data, isLoading } = useDoQuery({
      path: `/okta_settings/${tId}`,
      objectClass: OktaIntegrationSettings,
      initialData: defaultOktaIntegrationData
    })
    return { settings: data, isLoading }
  }

  static save = (settings: OktaIntegrationSettings) => {
    const path = '/okta_settings'
    const data = {
      type: 'okta_settings',
      attributes: settings
    }
    if (settings.id) {
      return doPatch({ path, data })
    }
    return doPut({ path, data })
  }

  constructor ({ row }: { row: Record<string, any> }) {
    super({ row })
    const settings = row.attributes
    this.clientId = settings.client_id
    this.clientSecret = 'contact support to change'
    this.authDomain = settings.auth_domain
    this.companyName = settings.company_name
  }
}
