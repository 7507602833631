/* eslint-disable @typescript-eslint/naming-convention */
import { useRef, useEffect, useState, useReducer } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import { User } from 'classes/users'
import { chatRoles, chatPermissions } from './userListHelpers'
import { Radio } from 'library/materialUI'

const useStyles = makeStyles(theme => ({
  hidden: {
    display: 'none'
  },
  cardInterior: {
    marginTop: 20
  },
  infoLabel: {
    fontSize: 12,
    marginLeft: 22,
    color: '#808080'
  },
  radio: {
    margin: 0,
    height: 30
  },
  formControl: {
    display: 'flex'
  },
  selectText: {
    marginTop: 0,
    fontSize: 11,
    marginLeft: 15,
    marginBottom: 25
  },
  radioCard: {
    padding: 10,
    paddingLeft: 15,
    paddingBottom: 5
  },
  card: {
    borderRadius: 5,
    border: '1px solid #d3d3d3',
    padding: 10,
    paddingLeft: 15,
    paddingBottom: 5,
    marginBottom: 10,
    '&.selected': {
      borderColor: '#9933ff'
    }
  }
}))

interface PermissionsSelectProps {
  value: number | string,
  onChange: any,
  name: string,
  label: any,
  values: { key: number | string, value: string }[]
}

function PermissionsSelect (props: PermissionsSelectProps): JSX.Element {
  const classes = useStyles()
  const inputLabel = useRef(props.label)
  const [labelWidth, setLabelWidth] = useState(0)
  useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth)
  }, [])
  return (
    <FormControl
      variant='outlined'
      margin='dense'
      className={classes.formControl}
    >
      <InputLabel ref={inputLabel} htmlFor={props.name}>
        {props.label}
      </InputLabel>
      <Select
        value={props.value}
        onChange={props.onChange}
        inputProps={{
          name: props.name,
          id: props.name
        }}
        labelWidth={labelWidth}
      >
        <MenuItem disabled value=''>
          {props.label}
        </MenuItem>
        {props.values.map((item: { key: number | string, value: string }) => (
          <MenuItem key={item.key} value={item.key}>
            {item.value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

interface UserRolesProps {
  user: User,
  changeUser: Function
}

const reducer = (state: User, changes: any) => {
  return { ...state, ...changes }
}

function UserRoles ({ user, changeUser }: UserRolesProps): JSX.Element {
  const classes = useStyles()
  const [state, dispatch] = useReducer(reducer, user)
  useEffect(() => changeUser(state), [state, changeUser])
  const currentRoleIsUser = state.roleID > 2 && state.roleID < 8

  function changePermissions (value: any): void {
    const permissionsDict: any = {
      conversations_all: {
        conversations_all: true,
        conversations_mine: true,
        conversations_unclaimed: true
      },
      conversations_unclaimed: {
        conversations_all: false,
        conversations_mine: true,
        conversations_unclaimed: true
      },
      conversations_mine: {
        conversations_all: false,
        conversations_mine: true,
        conversations_unclaimed: false
      }
    }

    const payload = permissionsDict[value]
    // ensure we don't remove the perm cf from a user's perms
    if ('cf' in user.userPerms) {
      payload.cf = user.userPerms.cf
    }
    dispatch({ userPerms: payload })
  }

  let current_perms = 'conversations_mine'
  if (state.userPerms.conversations_unclaimed) {
    current_perms = 'conversations_unclaimed'
  }
  if (state.userPerms.conversations_all) {
    current_perms = 'conversations_all'
  }

  return (
    <div>
      <div className={`${classes.card} ${state.roleID === 1 ? 'selected' : ''}`}>
        <div className={classes.radioCard}>
          <Radio label='Admin' value={state.roleID === 1} checked={state.roleID === 1} handleChange={() => dispatch({ roleID: 1 })} />
          <p className={classes.infoLabel}>
            Admins can access everything in Signals, including billing
          </p>
        </div>
      </div>
      <div className={`${classes.card} ${state.roleID === 9 ? 'selected' : ''}`}>
        <div className={classes.radioCard}>
          <Radio label='Admin - No Chat' value={state.roleID === 9} checked={state.roleID === 9} handleChange={() => dispatch({ roleID: 9 })} />
          <p className={classes.infoLabel}>
            The non-chatting Admin can access everything an Admin can access,
            minus the ability to chat with visitors and book meetings
          </p>
        </div>
      </div>
      <div className={`${classes.card} ${state.roleID === 2 ? 'selected' : ''}`}>
        <div className={classes.radioCard}>
          <Radio label='Marketer' value={state.roleID === 2} checked={state.roleID === 2} handleChange={() => dispatch({ roleID: 2 })} />
          <p className={classes.infoLabel}>
            Marketers have access to everything except billing - they can add
            and edit users, take chats, review conversations, and create and
            configure bots
          </p>
        </div>
      </div>
      <div className={`${classes.card} ${state.roleID === 3 ? 'selected' : ''}`}>
        <div className={classes.radioCard}>
          <div>
            <Radio
              label='Chat User'
              value={currentRoleIsUser}
              checked={currentRoleIsUser}
              handleChange={() => {
                if (!currentRoleIsUser) {
                  dispatch({ roleID: 3 })
                }
              }}
            />
            <p className={classes.infoLabel}>
              Chat users can access conversations, view and edit contacts and
              accounts, and leave notes
            </p>
          </div>
          {currentRoleIsUser ? (
            <div className={classes.cardInterior}>
              <PermissionsSelect
                value={state.roleID}
                onChange={(e: any) => dispatch({ roleID: e.target.value })}
                name='chat_role'
                label='Role'
                values={chatRoles.filter((item: { key: number, value: string }) => item.key > 2 && item.key < 8)}
              />
              <p className={classes.selectText}>
                *Manager roles can add users and review conversations and data
              </p>
              <PermissionsSelect
                value={current_perms}
                onChange={(e: any) => changePermissions(e.target.value)}
                name='chat_access'
                label='Chat Access'
                values={chatPermissions}
              />
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className={`${classes.card} ${state.roleID === 8 ? 'selected' : ''}`}>
        <div className={classes.radioCard}>
          <Radio label='Calendar User' value={state.roleID === 8} checked={state.roleID === 8} handleChange={() => dispatch({ roleID: 8 })} />
          <p className={classes.infoLabel}>
            Calendar Users have access to set up a calendar and meeting settings so they can have meetings scheduled for them.
          </p>
        </div>
      </div>
    </div>
  )
}

export default UserRoles
