import { useState, useEffect, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import CloseIcon from '@material-ui/icons/Close'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Card from '@material-ui/core/Card'
import Fab from '@material-ui/core/Fab'
import { EmailLayout } from './EmailLayout'
import EmailPreview from './EmailPreview'
import { Redirect } from 'react-router-dom'
import { getEmail } from 'api/emails'
import ReactDOM from 'react-dom'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import { SessionContext } from 'session-context'
import LearnMore from 'cf-components/LearnMore/LearnMore'
import AttachmentStep from './AttachmentStep'
import Email from 'classes/emails'
import { Tooltip, Typography } from '@material-ui/core'
import { Icon } from 'library/materialUI'
import Loader from 'library/loading/Loader'
import { useDoTypeQuery } from 'classes/useDoQuery'
import { EmailWizardProvider } from './EmailWizardContext'

const useStyles = makeStyles(theme => ({
  wizardPage: {
    width: '100%',
    height: '100%',
    display: 'grid',
    gridTemplateColumns: '215px auto',
    fontFamily: 'Poppins, sans serif'
  },
  sidebar: {
    borderRight: '1px solid #d3d3d3'
  },
  itemHeader: {
    fontWeight: 600,
    marginTop: 15
  },
  selected: {
    paddingLeft: 40,
    fontWeight: 600,
    fontSize: '15px',
    color: '#333'
  },
  unselected: {
    paddingLeft: 40,
    fontSize: '15px',
    color: '#999'
  },
  wizardContents: {
    padding: 20,
    display: 'grid',
    gridTemplateRows: '40px auto',
    height: 'calc(100% - 40px)'
  },
  cancelDiv: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  stepContents: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 20
  },
  detailsStep: {
    width: 650
  },
  detailsCard: {
    border: '2px solid #F0E3FF',
    padding: 20
  },
  detailsHeader: {
    marginBottom: 20,
    display: 'flex',
    justifyContent: 'center'
  },
  navButtonDiv: {
    display: 'flex',
    marginTop: 50,
    justifyContent: 'center'
  },
  navButtons: {
    width: 650,
    maxWidth: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  customEmail: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 10,
    marginBottom: 20
  },
  settingButton: {
    display: 'flex',
    cursor: 'pointer'
  },
  radioInfo: {
    marginLeft: 32
  },
  radioStyle: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: 9,
    paddingLeft: 9
  },
  radioRow: {
    marginTop: 8,
    marginBottom: 8
  },
  radioInfoDisabled: {
    marginLeft: 32,
    opacity: 0.5
  }
}))

function WizardSidebar ({ currentStep, setStep, isNew }) {
  const classes = useStyles()

  const steps = [
    { value: 'details', display: 'Details' },
    { value: 'layout', display: 'Layout' },
    { value: 'attachments', display: 'Attachments' },
    { value: 'preview', display: 'Preview' }
  ]

  return (
    <div className={classes.sidebar}>
      <List
        component='nav'
        className={classes.root}
      >
        <ListItem>
          <div className={classes.itemHeader}>
            {isNew ? 'New Email' : 'Edit Email'}
          </div>
        </ListItem>
        <List component='nav' disablePadding>
          {steps.map((step, index) => {
            const className = (currentStep === step.value) ? classes.selected : classes.unselected
            return (
              <ListItem
                button
                className={className}
                key={index}
                onClick={() => setStep(step.value)}
              >
                {step.display}
              </ListItem>
            )
          })}
        </List>
      </List>
    </div>
  )
}

function DetailsStep ({ name, setName, description, setDescription, setFromType, fromType, setFromName, fromName, setFromEmail, fromEmail }) {
  const classes = useStyles()
  const { user } = useContext(SessionContext)
  const { data: emailSettingsData, isLoading } = useDoTypeQuery({ path: '/integrations/email/setup', useChatURL: true })
  const sendFromAccountOwnerPermission = user?.attributes?.perms?.tenant_route_by_account_owner
  const [defaultSenderInfo, setDefaultSenderInfo] = useState('')

  useEffect(() => {
    if (!isLoading) {
      if (emailSettingsData?.ready_to_use) {
        setDefaultSenderInfo(`Emails will be sent from ${emailSettingsData?.default_sender_name} (${emailSettingsData?.default_sender_email_prefix}@${emailSettingsData?.sender_domain})`)
      } else if (!emailSettingsData?.ready_to_use) {
        setDefaultSenderInfo('No default sender set')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  if (isLoading) {
    <Loader type='spinner' title='Loading email details' />
  }

  return (
    <div className={classes.detailsStep}>
      <div className={classes.detailsHeader}>
        Enter a name and description of your email (internal only)
      </div>
      <Card className={classes.detailsCard}>
        <TextField
          required
          variant='outlined'
          margin='dense'
          fullWidth
          label='Name'
          style={{ marginBottom: 15 }}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          variant='outlined'
          multiline
          rows={4}
          fullWidth
          label='Description'
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </Card>
      <br /><br />
      <div className={classes.detailsHeader}>
        Choose who you would like to have this email sent from
        <LearnMore url='https://help.getsignals.ai/article/g2tmnocm82-email' />
      </div>
      <Card className={classes.detailsCard}>
        {isLoading ? (
          <Loader type='spinner' title='Loading email details' />
        ) : (
          <>
            <FormControl component='fieldset'>
              <RadioGroup
                value={fromType}
                onChange={(e) => { setFromType(e.target.value) }}
                color='primary'
              >
                <div className={classes.radioRow}>
                  <div style={{ display: 'flex', verticalAlign: 'center' }}>
                    <FormControlLabel value='default' control={<Radio className={classes.radioStyle} color='primary' />} label='Send from the default company sender' />
                    <Tooltip title='Edit default company sender'>
                      <div className={classes.settingButton} onClick={() => window.open('/#/settings/email_settings')}>
                        <Icon icon='settings' color='#00000080' specificSize={20} />
                      </div>
                    </Tooltip>
                  </div>
                  <Typography variant='body2' className={classes.radioInfo}>{defaultSenderInfo}</Typography>
                </div>
                {sendFromAccountOwnerPermission &&
                  <div className={classes.radioRow}>
                    <FormControlLabel value='account_owner' control={<Radio className={classes.radioStyle} color='primary' />} label='Send from the account owner' />
                    <Typography variant='body2' className={classes.radioInfo}>Emails will be sent from the account owner in Signals. If an account does not have an owner, the email will be sent from the default sender.</Typography>
                  </div>}
                <div className={classes.radioRow}>
                  <FormControlLabel value='other' control={<Radio className={classes.radioStyle} color='primary' />} label='Choose a different sender' />
                </div>
              </RadioGroup>
            </FormControl>
            {fromType === 'other' &&
              <div style={{ marginLeft: 30 }}>
                <div className={classes.customEmail}>
                  <TextField
                    size='small'
                    required
                    variant='outlined'
                    id='fromEmail'
                    label='Email Address'
                    value={fromEmail}
                    onChange={(e) => { setFromEmail(e.target.value) }}
                  />
                  <div style={{ marginLeft: 10 }}>
                    {emailSettingsData && (
                      <>@{emailSettingsData?.data?.attributes?.sender_domain}</>
                    )}
                  </div>
                </div>
                <div>
                  <TextField
                    size='small'
                    required
                    variant='outlined'
                    id='fromName'
                    label='Name'
                    value={fromName}
                    onChange={(e) => { setFromName(e.target.value) }}
                  />
                </div>
              </div>}
          </>
        )}
      </Card>
    </div>
  )
}

function StepContents (props) {
  const classes = useStyles()

  if (props.step === 'details') {
    return (
      <DetailsStep
        name={props.name}
        setName={props.setName}
        description={props.description}
        setDescription={props.setDescription}
        setFromType={props.setFromType}
        fromType={props.fromType}
        setFromName={props.setFromName}
        fromName={props.fromName}
        setFromEmail={props.setFromEmail}
        fromEmail={props.fromEmail}
      />
    )
  }

  if (props.step === 'layout') {
    return (
      <EmailLayout
        layout={props.layout}
        setLayout={props.setLayout}
        emailBody={props.emailBody}
        setEmailBody={props.setEmailBody}
        subject={props.subject}
        setSubject={props.setSubject}
        image={props.image}
        imageLink={props.imageLink}
        setImage={props.setImage}
        setImageLink={props.setImageLink}
        editorRef={props.editorRef}
        setPlainText={props.setPlainText}
      />
    )
  }

  if (props.step === 'preview') {
    return (
      <EmailPreview
        layout={props.layout}
        emailBody={props.emailBody}
        subject={props.subject}
        image={props.image}
        fromEmail={props.fromEmail}
        fromType={props.fromType}
        shouldSave={props.shouldSave}
        handleSave={props.handleSave}
      />
    )
  }

  if (props.step === 'attachments') {
    return (
      <AttachmentStep
        attachments={props.attachments}
        addAttachment={props.addAttachment}
        removeAttachment={props.removeAttachment}
      />
    )
  }

  return (
    <div className={classes.stepContents}>
      Wizard Contents
    </div>
  )
}

function NavButtons ({ step, setStep, handleSave, disabled, shouldSave, handleComplete }) {
  const classes = useStyles()

  const showBack = step !== 'details'
  const lastStep = step === 'preview'

  const handleBack = () => {
    if (step === 'layout') {
      setStep('details')
    } else if (step === 'attachments') {
      setStep('layout')
    } else if (step === 'preview') {
      setStep('attachments')
    }
  }

  const handleNext = () => {
    if (step === 'details') {
      setStep('layout')
    } else if (step === 'layout') {
      setStep('attachments')
    } else if (step === 'attachments') {
      setStep('preview')
    } else if (step === 'preview') {
      if (shouldSave) {
        handleSave()
      } else {
        handleComplete()
      }
    }
  }

  let nextLabel = 'Next'
  if (lastStep && shouldSave) {
    nextLabel = 'Save'
  } else if (lastStep) {
    nextLabel = 'Close'
  }

  return (
    <div className={classes.navButtons}>
      {showBack
        ? (
          <Fab
            variant='extended'
            size='small'
            style={{ width: 100 }}
            onClick={handleBack}
          >
            Back
          </Fab>)
        : <div />}
      <Fab
        variant='extended'
        size='small'
        color='primary'
        style={{ width: 100 }}
        onClick={handleNext}
        disabled={disabled}
      >
        {nextLabel}
      </Fab>
    </div>
  )
}

function EmailWizard (props) {
  const classes = useStyles()
  const [shouldSave, setShouldSave] = useState(false)
  const [step, setStep] = useState('details')
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [layout, setLayout] = useState('rich_text')
  const [subject, setSubject] = useState('')
  const [emailBody, setEmailBody] = useState('')
  const [image, setImage] = useState(null)
  const [imageLink, setImageLink] = useState('')
  const [redirect, setRedirect] = useState(null)
  const [emailID] = useState(props.match.params.email_id === 'new' ? null : props.match.params.email_id)
  const [loading, setLoading] = useState(true)
  const [saving, setSaving] = useState(false)
  const [fromType, setFromType] = useState('default')
  const [fromName, setFromName] = useState('')
  const [fromEmail, setFromEmail] = useState('')
  const [attachments, setAttachments] = useState([])
  const [isNew, setIsNew] = useState(true)
  // const { data: chatSettings } = ChatSettings.loadOne()
  const redirectPath = '/engagement/emails/emails'
  const complete = subject && emailBody && name
  const { user } = useContext(SessionContext)
  const chatServiceUrl = user.links.chat_service

  useEffect(() => {
    if (emailID) {
      getEmail({ emailID, chatServiceUrl })
        .then(response => {
          const email = response.data.attributes
          ReactDOM.unstable_batchedUpdates(() => {
            setName(email.name)
            setDescription(email.description)
            setLayout(email.kind)
            setSubject(email.subject)
            setEmailBody(email.body)
            setLoading(false)
            setImage(email.image)
            setImageLink(email.image_link)
            setFromType(email.from_type)
            setFromEmail(email.from_email)
            setFromName(email.from_name)
            setAttachments(email.attachments)
          })
          if (email.name && email.subject) {
            setIsNew(false)
          }
        })
    } else {
      setLoading(false)
    }
  }, [chatServiceUrl, emailID])

  function handleSave () {
    // this could all be one state <Email>
    setSaving(true)
    Email.save({
      data: {
        id: emailID,
        name,
        description,
        subject,
        body: emailBody,
        kind: layout,
        chatServiceUrl,
        emailID,
        image,
        imageLink,
        fromName,
        fromEmail,
        fromType,
        attachments
      }
    })
      .then(response => {
        setSaving(false)
        setShouldSave(false)
      }).catch((e) => {
        console.error('failed to save email', e)
        setSaving(false)
      })
  }

  function handleComplete () {
    if (!isNew) {
      return
    }
    Email.removeEmail(emailID, true)
  }

  function addAttachment (file) {
    setAttachments(prev => [...prev, file])
  }

  function removeAttachment (index) {
    setAttachments(prev => prev.filter((_, i) => i !== index))
  }

  function updateEmail (stateFn, value) {
    setShouldSave(true)
    stateFn(value)
  }

  if (redirect) {
    return (
      <Redirect push to={redirect} />
    )
  }

  const loaded = (emailID && !loading) || !emailID

  let disabled = false
  if (saving || loading) {
    disabled = true
  } else if (step === 'details') {
    if (!name) {
      disabled = true
    }
    if (fromType === 'other' && !(fromName && fromEmail)) {
      disabled = true
    }
  } else if (step === 'layout') {
    if (!(subject && emailBody)) {
      disabled = true
    }
  } else if (!complete) {
    disabled = true
  }

  return (
    <EmailWizardProvider emailID={emailID}>
      <div className={classes.wizardPage}>
        <WizardSidebar
          currentStep={step}
          setStep={setStep}
          isNew={isNew}
        />
        <div className={classes.wizardContents}>
          <div className={classes.cancelDiv}>
            <Button
              endIcon={<CloseIcon />}
              color='primary'
              variant='text'
              size='small'
              onClick={() => {
                handleComplete()
                setRedirect(redirectPath)
              }}
            >
              Cancel
            </Button>
          </div>
          {loaded &&
            <div>
              <div className={classes.stepContents}>
                <StepContents
                  step={step}
                  layout={layout}
                  setLayout={(value) => updateEmail(setLayout, value)}
                  subject={subject}
                  setSubject={(value) => updateEmail(setSubject, value)}
                  emailBody={emailBody}
                  setEmailBody={(value) => updateEmail(setEmailBody, value)}
                  name={name}
                  setName={(value) => updateEmail(setName, value)}
                  description={description}
                  setDescription={(value) => updateEmail(setDescription, value)}
                  fromType={fromType}
                  fromName={fromName}
                  fromEmail={fromEmail}
                  setFromType={(value) => updateEmail(setFromType, value)}
                  setFromEmail={(value) => updateEmail(setFromEmail, value)}
                  setFromName={(value) => updateEmail(setFromName, value)}
                  image={image}
                  setImage={(value) => updateEmail(setImage, value)}
                  setImageLink={(value) => updateEmail(setImageLink, value)}
                  attachments={attachments}
                  addAttachment={(value) => updateEmail(addAttachment, value)}
                  removeAttachment={(value) => updateEmail(removeAttachment, value)}
                  shouldSave={shouldSave}
                  handleSave={handleSave}
                />
              </div>
              <div className={classes.navButtonDiv}>
                <NavButtons
                  step={step}
                  setStep={setStep}
                  handleSave={handleSave}
                  disabled={disabled}
                  shouldSave={shouldSave}
                  handleComplete={() => {
                    setStep('details')
                    setRedirect(redirectPath)
                  }}
                />
              </div>
            </div>}
        </div>
      </div>
    </EmailWizardProvider>
  )
}

export default EmailWizard
