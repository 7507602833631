import { Icon, Button } from 'library/materialUI'
import { Divider, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ContentCheckbox from 'library/ContentCheckbox'
import { RichTextEditor } from 'library/richText/RichTextEditor'
import { RouteLabel } from 'library/routing/RoutingCriteria'
import Route from 'pages/bot/bot-edit/Route'
import { CalendarModal } from 'pages/bot/flow-builder/nodes/CalendarNode'
import { LiveChatModal } from 'pages/bot/flow-builder/nodes/LiveChatNode'
import { Dispatch, useReducer, useState } from 'react'
import Form, { FormAction } from 'classes/forms'
import Team from 'classes/teams'
import User from 'classes/users'
import { Email, MeetingType } from 'classes/classes'
import { Emitter, EVENT_TYPE } from 'emitter'
import Alert from '@material-ui/lab/Alert'

const useStyles = makeStyles(theme => ({
  container: {
    margin: '15px',
    padding: '20px',
    backgroundColor: '#FFFFFF',
    border: '1px solid #D3D3D3',
    borderRadius: '5px'
  },
  sectionSubtitle: {
    color: '#808080',
    fontFamily: 'poppins',
    fontSize: '14px',
    marginTop: '5px',
    marginBottom: '5px'
  },
  sectionTitle: {
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#000000BF'
  },
  subsectionTitle: {
    fontSize: '14px',
    fontWeight: 'bold'
  },
  subsectionAttributeTitle: {
    fontSize: '12px',
    color: '#808080'
  },
  editButton: {
    display: 'flex',
    width: '127px',
    height: '40px',
    fontSize: '14px',
    border: '1px solid #D3D3D3',
    borderRadius: '5px',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#E4E4E4'
    }
  },
  divider: {
    marginBottom: 10
  }
}))

interface FormCompleteState {
  routingComplete: boolean
  routedMessage: boolean
  noResponseMessage: boolean
  noResponseSettings: boolean
  meetingRouting: boolean
  meetingEmail: boolean
  meetingType: boolean
}

interface CompleteAction {
  type: string
  value: any
}

const completeStateReducer = (state: FormCompleteState, action: CompleteAction): FormCompleteState => {
  switch (action.type) {
    case 'routingSettings':
      return { ...state, routingComplete: action.value }
    case 'routedMessage':
      return { ...state, routedMessage: action.value }
    case 'noResponseMessage':
      return { ...state, noResponseMessage: action.value }
    case 'noResponseSettings':
      return { ...state, noResponseSettings: action.value }
    case 'meetingRouting':
      return { ...state, meetingRouting: action.value }
    case 'meetingEmail':
      return { ...state, meetingEmail: action.value }
    case 'meetingType':
      return { ...state, meetingType: action.value }
  }
  return state
}

interface FormOnlineContentCardProps {
  open: boolean
  setOpen: (value: boolean) => void
  formDispatch: Dispatch<FormAction>
  form: Form
  setCanSave: (value: boolean) => void
  showOfflinePrompt: boolean
  refreshMissing: () => any
}

export default function FormOnlineContentCard ({ open, setOpen, formDispatch, form, setCanSave, refreshMissing }: FormOnlineContentCardProps) {
  const classes = useStyles()
  const { data: { list: teams }, isLoading: teamsLoading } = Team.loadAll()
  const { data: { list: users }, isLoading: usersLoading } = User.loadAll({})
  const { data: { list: meetingTypes }, isLoading: meetingsLoading } = MeetingType.loadAll()
  const { data: { list: emails }, isLoading: emailsLoading } = Email.loadAll()
  const [liveChatModalOpen, setLiveChatModalOpen] = useState(false)
  const [meetingModalOpen, setMeetingModalOpen] = useState(false)
  const [completeState, updateCompleteState] = useReducer(completeStateReducer, { routingComplete: true, routedMessage: true, noResponseMessage: true, noResponseSettings: true, meetingRouting: true, meetingEmail: true, meetingType: true })

  const cardComplete = () => {
    if (!form.liveChat && !form.bookMeeting) {
      return false
    }
    if (form.liveChat) {
      if (form.chatRoutingSettings.length > 0) {
        form.chatRoutingSettings.forEach((item) => {
          if (!item.method || ((!item.objectIDs || item.objectIDs.length < 1) && item.method !== 'global_routing')) {
            return false
          }
        })
        if (form.chatTimeoutAction === 'book_meeting') {
          if (form.chatMeetingRoutingSettings.length > 0) {
            form.chatMeetingRoutingSettings.forEach((meeting) => {
              if (!meeting.method || ((!meeting.objectIDs || meeting.objectIDs.length < 1) && meeting.method !== 'global_routing')) {
                return false
              }
            })
          } else {
            return false
          }
          if (!form.chatMeetingType) {
            return false
          }
        }
      } else {
        return false
      }
    }
    if (form.bookMeeting) {
      if (form.meetingRoutingSettings.length > 0) {
        form.meetingRoutingSettings.forEach((meeting) => {
          if (!meeting.method || ((!meeting.objectIDs || meeting.objectIDs.length < 1) && meeting.method !== 'global_routing')) {
            return false
          }
        })
      } else {
        return false
      }
      if (form.meetingFollowupEmail) {
        if (!form.meetingFollowupEmailID) {
          return false
        }
      }
      if (!form.bookMeetingButtonText) {
        return false
      }
    }
    return true
  }

  Emitter.on(EVENT_TYPE.FORM_FOLLOWUP_MISSING, (payload: { type: string }) => {
    if (payload.type === 'lcRouting' || payload.type === 'lcRoutedMessage' || payload.type === 'lcNoResponseMessage' || payload.type === 'lcNoResponseSettings') {
      setOpen(true)
      setLiveChatModalOpen(true)
      if (payload.type === 'lcRouting') {
        updateCompleteState({ type: 'routingSettings', value: false })
      } else if (payload.type === 'lcRoutedMessage') {
        updateCompleteState({ type: 'routedMessage', value: false })
      } else if (payload.type === 'lcNoResponseMessage') {
        updateCompleteState({ type: 'noResponseMessage', value: false })
      } else if (payload.type === 'lcNoResponseSettings') {
        updateCompleteState({ type: 'noResponseSettings', value: false })
      }
    }
    if (payload.type === 'bmRouting' || payload.type === 'bmEmail' || payload.type === 'bmMeetingType') {
      setOpen(true)
      setMeetingModalOpen(true)
      if (payload.type === 'bmRouting') {
        updateCompleteState({ type: 'meetingRouting', value: false })
      } else if (payload.type === 'bmEmail') {
        updateCompleteState({ type: 'meetingEmail', value: false })
      } else if (payload.type === 'bmMeetingType') {
        updateCompleteState({ type: 'meetingType', value: false })
      }
    }
  })

  if (teamsLoading || usersLoading || meetingsLoading || emailsLoading) {
    return (
      <></>
    )
  }

  return (
    <div className={classes.container}>
      <div onClick={() => setOpen(!open)} style={{ display: 'flex', flexDirection: 'row', cursor: 'pointer' }}>
        <div style={{ marginRight: '10px', marginTop: '5px' }}>
          <Icon icon={!cardComplete() ? 'uncheckedRadio' : 'lightGreenCheckCircle'} />
        </div>
        <div className={classes.sectionTitle}>
          Prompt Content{form.liveChat ? ' - Agents Online' : ''}
        </div>
        <div style={{ marginLeft: 'auto', marginRight: '5px' }}>
          {open ? (
            <div>
              <Icon icon='close' />
            </div>
          ) : (
            <div className={classes.editButton}>
              Edit Settings
            </div>
          )}
        </div>
      </div>
      <div onClick={() => setOpen(!open)} className={classes.sectionSubtitle}>
        {(!cardComplete() || open) ? (
          <div>
            Customize the prompt that will be shown after form submission when agents are available to chat.
          </div>
        ) : (
          <div>
            {form.liveChat && (
              <div>
                <div className={classes.subsectionTitle} style={{ color: 'black' }}>
                  Live Chat
                </div>
                <div className={classes.subsectionAttributeTitle}>
                  Routing
                </div>
                {form.chatRoutingSettings.length > 0 && (
                  <div style={{ color: '#343434' }}>
                    {(form.chatRoutingSettings[0].method === 'single_agent' || form.chatRoutingSettings[0].method === 'group') && (
                      <b style={{ color: '#000000' }}>{form.chatRoutingSettings[0].objectIDs.map((item) => (users.find((elem) => elem.id === item))?.displayName + ' ')}</b>
                    )}
                    {(form.chatRoutingSettings[0].method === 'team') && (
                      <b style={{ color: '#000000' }}>{form.chatRoutingSettings[0].objectIDs.map((item) => teams?.find((elem) => (elem.id === item))?.name)}</b>
                    )}
                    <b style={{ color: '#000000' }}>{['group', 'team'].includes(form.chatRoutingSettings[0].method) ? '(' + form.chatRoutingSettings[0].type.replace('_', ' ') + ')' : ' '}</b>
                    {form.chatRoutingSettings.length > 1 && (
                      <>
                        then{' '}
                        {(form.chatRoutingSettings[1].method === 'single_agent' || form.chatRoutingSettings[1].method === 'group') && (
                          <b style={{ color: '#000000' }}>{form.chatRoutingSettings[1].objectIDs.map((item) => (users.find((elem) => elem.id === item))?.displayName)}</b>
                        )}
                        {(form.chatRoutingSettings[1].method === 'team') && (
                          <b style={{ color: '#000000' }}>{form.chatRoutingSettings[1].objectIDs.map((item) => teams?.find((elem) => (elem.id === item))?.name)}</b>
                        )}
                        <b style={{ color: '#000000' }}>{['group', 'team'].includes(form.chatRoutingSettings[1].method) ? ' (' + form.chatRoutingSettings[1].type.replace('_', ' ') + ')' : ' '}</b>
                      </>
                    )}
                    {form.chatTimeoutAction && (
                      <div style={{ marginTop: '10px' }}>
                        <div className={classes.subsectionAttributeTitle}>If agents fail to respond after {form.chatTimeoutSeconds} seconds</div>
                        {form.chatTimeoutAction === 'book_meeting' && (
                          <div>Let the visitor book a <b style={{ color: '#000000' }}>{meetingTypes.find((elem) => elem.id === form.chatMeetingType)?.name}</b> meeting with{' '}
                            {(form.chatRoutingSettings[0].method === 'single_agent' || form.chatRoutingSettings[0].method === 'group') && (
                              <b style={{ color: '#000000' }}>{form.chatRoutingSettings[0].objectIDs.map((item) => (users.find((elem) => elem.id === item))?.displayName + ' ')}</b>
                            )}
                            {(form.chatRoutingSettings[0].method === 'team') && (
                              <b style={{ color: '#000000' }}>{form.chatRoutingSettings[0].objectIDs.map((item) => teams?.find((elem) => (elem.id === item))?.name)}</b>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
            {form.bookMeeting && (
              <div>
                <div className={classes.subsectionTitle} style={{ color: 'black', marginTop: '20px' }}>
                  Book Meetings
                </div>
                <div className={classes.subsectionAttributeTitle}>
                  Routing
                </div>
                {form.meetingRoutingSettings.length > 0 && (
                  <div>
                    Book a <b style={{ color: '#000000' }}>{meetingTypes.find((elem) => elem.id === form.meetingType)?.name}</b> with{' '}
                    {(form.meetingRoutingSettings[0].method === 'single_agent' || form.meetingRoutingSettings[0].method === 'group') && (
                      <b style={{ color: '#000000' }}>{form.meetingRoutingSettings[0].objectIDs.map((item) => (users.find((elem) => elem.id === item))?.displayName + ' ')}</b>
                    )}
                    {(form.meetingRoutingSettings[0].method === 'team') && (
                      <b style={{ color: '#000000' }}>{form.meetingRoutingSettings[0].objectIDs.map((item) => teams?.find((elem) => (elem.id === item))?.name)}</b>
                    )}
                    <b style={{ color: '#000000' }}>{['group', 'team'].includes(form?.meetingRoutingSettings[0]?.method) ? '(' + form?.meetingRoutingSettings[0]?.type + ')' : ' '}</b>
                    {form.meetingFollowupEmail && (
                      <div>
                        <div className={classes.subsectionAttributeTitle} style={{ marginTop: '10px' }}>
                          If visitor fails to book a meeting
                        </div>
                        <div>
                          Send <b style={{ color: '#000000' }}>{emails.find((elem) => elem.id === form.meetingFollowupEmailID)?.name}</b> email as a follow-up
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
      {open && (
        <div className='form-online-tab' key={0}>
          <Typography variant='h6' style={{ marginTop: 20 }}>Visitor Options</Typography>
          <Divider className={classes.divider} />
          <Typography variant='body2'>What options should visitors have?</Typography>
          {form.createNewFromNewEmail && (
            <Alert severity='info'>
              Live chat is not an option for follow-up actions when a form is being used on a shared device.
            </Alert>
          )}
          <ContentCheckbox
            label='Include option for Live Chat'
            checked={form.liveChat}
            disabled={form.createNewFromNewEmail}
            onChange={(checked: boolean) => { formDispatch({ type: 'liveChat', value: checked }); setCanSave(true); setLiveChatModalOpen(checked) }}
          >
            <>
              <Typography variant='body2'>How should chats be routed?</Typography>
              <Divider className={classes.divider} />
              <Route
                modal={
                  <LiveChatModal
                    open={liveChatModalOpen}
                    onHide={() => { setLiveChatModalOpen(false); refreshMissing() }}
                    save={({
                      routeOptions,
                      routeMessage,
                      fallbackDelay,
                      timeoutMessage,
                      timeoutOption,
                      meetingType,
                      meetingRouteOptions,
                      textMessageReminders,
                      followUpEmail,
                      followUpEmailID,
                      mapComments,
                      contactAttribute
                    }: any) => {
                      formDispatch({ type: 'chatRoutedMessage', value: routeMessage })
                      formDispatch({ type: 'chatRoutingSettings', value: routeOptions })
                      formDispatch({ type: 'chatTimeoutSeconds', value: fallbackDelay })
                      formDispatch({ type: 'chatTimeoutMessage', value: timeoutMessage })
                      formDispatch({ type: 'chatTimeoutAction', value: timeoutOption })
                      formDispatch({ type: 'chatMeetingType', value: meetingType })
                      formDispatch({ type: 'chatMeetingRoutingSettings', value: meetingRouteOptions })
                      formDispatch({ type: 'chatMeetingTextReminder', value: textMessageReminders })
                      formDispatch({ type: 'chatMeetingFollowupEmail', value: followUpEmail })
                      formDispatch({ type: 'chatMeetingFollowupEmailID', value: followUpEmailID })
                      formDispatch({ type: 'chatMapComment', value: mapComments })
                      formDispatch({ type: 'chatContactMapAttribute', value: contactAttribute })
                      setLiveChatModalOpen(false)
                      setCanSave(true)
                      refreshMissing()
                    }}
                    fallbackDelay={form.chatTimeoutSeconds}
                    routeMessage={form.chatRoutedMessage}
                    routeOptions={form.chatRoutingSettings}
                    showTimeoutMessage
                    timeoutMessage={form.chatTimeoutMessage}
                    showTimeoutNextStep
                    timeoutOption={form.chatTimeoutAction}
                    meetingRouteOptions={form.chatMeetingRoutingSettings}
                    textMessageReminders={form.chatMeetingTextReminder}
                    followUpEmail={form.chatMeetingFollowupEmail}
                    followUpEmailID={form.chatMeetingFollowupEmailID}
                    mapComments={form.chatMapComment}
                    contactAttribute={form.chatContactMapAttribute}
                    completeState={completeState}
                  />
                }
                setModalOpen={(value) => setLiveChatModalOpen(value)}
                label={RouteLabel(form.chatRoutingSettings)}
                icon='liveChat'
              />
              <Typography variant='body2'>What should the button for this option say?</Typography>
              <Divider className={classes.divider} />
              <TextField
                variant='outlined'
                fullWidth
                value={form.liveChatButtonText}
                onChange={(e) => { formDispatch({ type: 'liveChatButtonText', value: e.target.value }); setCanSave(true) }}
              />
            </>
          </ContentCheckbox>
          <ContentCheckbox
            label='Include option for Booking Meetings'
            checked={form.bookMeeting}
            onChange={(checked: boolean) => { formDispatch({ type: 'bookMeeting', value: checked }); setCanSave(true); setMeetingModalOpen(checked) }}
          >
            <>
              <Typography variant='body2'>How should meetings be routed?</Typography>
              <Route
                modal={
                  <CalendarModal
                    key='online-meeting-modal'
                    open={meetingModalOpen}
                    onHide={() => { setMeetingModalOpen(false); refreshMissing() }}
                    save={({ meetingType, textMessageReminders, followUpEmail, emailID, routeOptions }: any) => {
                      formDispatch({ type: 'meetingRoutingSettings', value: routeOptions })
                      formDispatch({ type: 'meetingType', value: meetingType })
                      formDispatch({ type: 'meetingFollowupEmail', value: followUpEmail })
                      formDispatch({ type: 'meetingFollowupEmailID', value: emailID || null })
                      formDispatch({ type: 'meetingTextReminder', value: textMessageReminders })
                      setMeetingModalOpen(false);
                      setCanSave(true)
                      refreshMissing()
                    }}
                    meetingType={form.meetingType}
                    textMessageReminders={form.meetingTextReminder}
                    emailID={form.meetingFollowupEmailID}
                    followUpEmail={form.meetingFollowupEmail}
                    showTextMessageToggle
                    routeOptions={form.meetingRoutingSettings}
                    hideBody
                    completeState={completeState}
                  />
                }
                setModalOpen={(value) => setMeetingModalOpen(value)}
                label={RouteLabel(form.meetingRoutingSettings)}
                icon='dateRange'
              />
              <Typography variant='body2'>What should the button for this option say?</Typography>
              <TextField
                variant='outlined'
                fullWidth
                value={form.bookMeetingButtonText}
                onChange={(e) => { formDispatch({ type: 'bookMeetingButtonText', value: e.target.value }); setCanSave(true) }}
              />
            </>
          </ContentCheckbox>
          <Typography variant='h6' style={{ marginTop: 20 }}>Prompt Message</Typography>
          <Divider className={classes.divider} />
          <Typography variant='body2' style={{ marginTop: 15 }}>Customize your prompt's header</Typography>
          <TextField
            variant='outlined'
            fullWidth
            value={form.promptHeader}
            onChange={(e) => { formDispatch({ type: 'promptHeader', value: e.target.value }); setCanSave(true) }}
          />
          <Typography variant='body2' style={{ marginTop: 15 }}>Customize your prompt's message</Typography>
          <Divider className={classes.divider} />
          <RichTextEditor
            message={form.promptMessage}
            onChange={(value) => { formDispatch({ type: 'promptMessage', value }); setCanSave(true) }}
            hideImage
            lines={4}
          />
          <div style={{ marginTop: '20px', marginLeft: 'auto', marginRight: '10px', width: '60px' }}>
            <Button
              onClick={() => setOpen(false)}
            >
              Done
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}
