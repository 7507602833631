import * as CronofyElements from 'cronofy-elements'
import { Cronofy } from 'classes/cronofy'
import { deleteCronofyStatus } from 'api/calendars'

type WidgetProps = {
  cronofy: Cronofy,
  prefix?: string
}

export function loadWidgets ({ cronofy, prefix }: WidgetProps) {
  // const tz = Intl.DateTimeFormat().resolvedOptions().timeZone
  const stylePrefix = prefix || 'CalendarSync'
  CronofyElements.CalendarSync({
    element_token: cronofy.elementToken,
    target_id: 'cronofy-calendar-sync',
    authorization: cronofy.authConfig,
    styles: {
      prefix: stylePrefix
    },
    callback: () => {
      deleteCronofyStatus()
    },
    single_profile: true
  })
}
