import Typography from '@material-ui/core/Typography'
import { Card, CardContent, CardHeader, Button, Chip, Radio, Select, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Divider from '@material-ui/core/Divider'
import TimezonePicker from 'components/TimezonePicker'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'

const useStyles = makeStyles(theme => ({
  selectedCard: {
    border: '1px solid #8E5AE2',
    backgroundColor: 'rgba(142, 90, 226, 0.05)',
    minHeight: 60,
    marginBottom: 20
  },
  card: {
    border: '1px solid rgba(142, 90, 226, 0.4)',
    minHeight: 60,
    cursor: 'pointer',
    borderRadius: 10,
    backgroundColor: 'white',
    marginBottom: 20
  },
  manualCard: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 15
  },
  timezoneDiv: {
    marginTop: 20
  },
  timezonePicker: {
    marginTop: 10
  },
  hidden: {
    display: 'none'
  },
  cardInterior: {
    marginLeft: 35,
    marginTop: 45
  },
  manual: {
    float: 'left'
  },
  manualToggle: {
    float: 'right',
    marginRight: 40
  },
  radio: {
    margin: 0,
    height: 30
  },
  scheduled: {
    float: 'left',
    width: '100%'
  },
  switch: {
    marginLeft: 5
  },
  cardTitle: {
    fontSize: '17px'
  },
  cardRoot: {
    padding: '8px 24px'
  },
  selectInput: {
    padding: 8,
    paddingLeft: 10,
    borderRadius: 30
  },
  inputRoot: {
    borderRadius: 30
  },
  select: {
    marginLeft: 15,
    marginRight: 15,
    width: 180
  },
  urlLink: {
    color: theme.palette.primary.main,
    textDecoration: 'none'
  }
}))

function TimePicker (props) {
  const classes = useStyles()
  const handleTimeChange = props.handler

  return (
    <Select
      native
      value={props.value}
      variant='outlined'
      name={props.name}
      className={classes.select}
      onChange={handleTimeChange}
      margin='dense'
    >
      <option value='00:00:00'>12:00 AM</option>
      <option value='00:30:00'>12:30 AM</option>
      <option value='01:00:00'>1:00 AM</option>
      <option value='01:30:00'>1:30 AM</option>
      <option value='02:00:00'>2:00 AM</option>
      <option value='02:30:00'>2:30 AM</option>
      <option value='03:00:00'>3:00 AM</option>
      <option value='03:30:00'>3:30 AM</option>
      <option value='04:00:00'>4:00 AM</option>
      <option value='04:30:00'>4:30 AM</option>
      <option value='05:00:00'>5:00 AM</option>
      <option value='05:30:00'>5:30 AM</option>
      <option value='06:00:00'>6:00 AM</option>
      <option value='06:30:00'>6:30 AM</option>
      <option value='07:00:00'>7:00 AM</option>
      <option value='07:30:00'>7:30 AM</option>
      <option value='08:00:00'>8:00 AM</option>
      <option value='08:30:00'>8:30 AM</option>
      <option value='09:00:00'>9:00 AM</option>
      <option value='09:30:00'>9:30 AM</option>
      <option value='10:00:00'>10:00 AM</option>
      <option value='10:30:00'>10:30 AM</option>
      <option value='11:00:00'>11:00 AM</option>
      <option value='11:30:00'>11:30 AM</option>
      <option value='12:00:00'>12:00 PM</option>
      <option value='12:30:00'>12:30 PM</option>
      <option value='13:00:00'>1:00 PM</option>
      <option value='13:30:00'>1:30 PM</option>
      <option value='14:00:00'>2:00 PM</option>
      <option value='14:30:00'>2:30 PM</option>
      <option value='15:00:00'>3:00 PM</option>
      <option value='15:30:00'>3:30 PM</option>
      <option value='16:00:00'>4:00 PM</option>
      <option value='16:30:00'>4:30 PM</option>
      <option value='17:00:00'>5:00 PM</option>
      <option value='17:30:00'>5:30 PM</option>
      <option value='18:00:00'>6:00 PM</option>
      <option value='18:30:00'>6:30 PM</option>
      <option value='19:00:00'>7:00 PM</option>
      <option value='19:30:00'>7:30 PM</option>
      <option value='20:00:00'>8:00 PM</option>
      <option value='20:30:00'>8:30 PM</option>
      <option value='21:00:00'>9:00 PM</option>
      <option value='21:30:00'>9:30 PM</option>
      <option value='22:00:00'>10:00 PM</option>
      <option value='22:30:00'>10:30 PM</option>
      <option value='23:00:00'>11:00 PM</option>
      <option value='23:30:00'>11:30 PM</option>
      <option value='23:59:59'>11:59 PM</option>
    </Select>
  )
}

function HourSelector (props) {
  const classes = useStyles()

  function handleChange (event) {
    const name = event.target.name
    const value = event.target.value
    props.handler({
      type: 'updateTime',
      index: props.index,
      name: name,
      value: value
    })
  }

  return (
    <div>
      <Select
        native
        value={props.values.days}
        variant='outlined'
        name='days'
        margin='dense'
        onChange={handleChange}
        className={classes.select}
      >
        <option value='Everyday'>Everyday</option>
        <option value='Weekdays'>Weekdays</option>
        <option value='Weekends'>Weekends</option>
        <option value='Mondays'>Mondays</option>
        <option value='Tuesdays'>Tuesdays</option>
        <option value='Wednesdays'>Wednesdays</option>
        <option value='Thursdays'>Thursdays</option>
        <option value='Fridays'>Fridays</option>
        <option value='Saturdays'>Saturdays</option>
        <option value='Sundays'>Sundays</option>
      </Select>
      from
      <TimePicker
        name='start_time'
        value={props.values.start_time}
        handler={handleChange}
      />
      to
      <TimePicker
        name='end_time'
        value={props.values.end_time}
        handler={handleChange}
      />
      <IconButton
        onClick={() =>
          props.handler({ type: 'removeTime', index: props.index })}
      >
        <HighlightOffIcon style={{ fill: '#8e5ae2' }} />
      </IconButton>
    </div>
  )
}

function AvailabilityCard (props) {
  const classes = useStyles()
  const { state, dispatch } = props

  function setScheduled () {
    dispatch({ type: 'changeAvailabilityType', value: 'scheduled' })
  }

  function setManual () {
    dispatch({ type: 'changeAvailabilityType', value: 'manual' })
  }

  const scheduled = props.scheduled
  const availabilities = state.availabilities
  const online = state.online
  const autoToggle = state.offline_when_unavailable ? state.offline_when_unavailable : state.offlineWhenUnavailable
  const timezone = state.timezone
  const attempt_save = state.attempt_save ? state.attempt_save : props.saveAttempt
  const mobile_disabled = state.mobile_disabled

  return (
    <Card classes={{ root: classes.card }}>
      <CardHeader
        classes={{ title: classes.cardTitle }}
        title={props.title}
      />
      <CardContent classes={{ root: classes.cardRoot }}>
        <Card className={classes.manualCard} classes={{ root: scheduled ? classes.card : classes.selectedCard }} onClick={setManual}>
          <div className={classes.manual}>
            <FormControlLabel
              checked={!scheduled}
              control={<Radio color='primary' />}
              label='Manually set availability'
              className={classes.radio}
            />
          </div>
          <div
            className={scheduled ? classes.hidden : classes.manualToggle}
          >
            <Chip
              label={online ? 'Online' : 'Offline'}
              color={online ? 'primary' : 'default'}
            />
            <Checkbox
              color='primary'
              checked={online}
              name='online'
              onClick={() => dispatch({ type: 'toggleSetting', name: 'online' })}
            />
          </div>
        </Card>
        <Card classes={{ root: scheduled ? classes.selectedCard : classes.card }} onClick={setScheduled}>
          <CardContent>
            <div className={classes.scheduled}>
              <FormControlLabel
                checked={scheduled}
                control={<Radio color='primary' />}
                label='Set availability schedule'
                className={classes.radio}
                onClick={setScheduled}
              />
            </div>
            <div className={scheduled ? classes.cardInterior : classes.hidden}>
              <Typography variant='subtitle1'>Available for chat during these hours:</Typography>
              <div style={{ marginTop: 15 }}>
                {(availabilities.length < 1 && attempt_save) &&
                  <div style={{ color: 'red' }}>
                    Please set schedule time before you can save
                  </div>}
                {availabilities.map((availability, index) => (
                  <HourSelector
                    key={index}
                    values={availability}
                    index={index}
                    handler={dispatch}
                  />
                ))}
                <Button
                  onClick={() => dispatch({ type: 'addTime' })}
                  variant='outlined'
                  color='primary'
                  style={{ marginTop: 15 }}
                >
                  {availabilities.length < 1 ? '+ Add a time' : '+ Add another time'}
                </Button>
                <div className={classes.timezoneDiv}>
                  <Typography variant='subtitle1'>Timezone:</Typography>
                  <div className={classes.timezonePicker}>
                    <TimezonePicker value={timezone} handler={dispatch} />
                  </div>
                </div>
              </div>
              <br />
              <Divider />
              <br />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={autoToggle}
                    onChange={() => dispatch({ type: 'toggleSetting', name: 'offline_when_unavailable' })}
                    name='autoToggle'
                    color='primary'
                  />
                }
                label='Go offline when all users are set to unavailable'
              />
            </div>
          </CardContent>
        </Card>
      </CardContent>
      {props.teamPage ? <></> : (
        <>
          <CardContent style={{ padding: '0px 0px 25px 25px' }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div>
                <Checkbox
                  color='primary'
                  checked={mobile_disabled}
                  name='mobile_disabled'
                  onClick={() => dispatch({ type: 'toggleSetting', name: 'mobile_disabled' })}
                />
              </div>
              <div style={{ marginTop: '9px' }}>
                Disable chat prompt for visitors on mobile
              </div>
            </div>
          </CardContent>
          <CardContent style={{ padding: '0px 0px 25px 25px' }}>
            <div style={{ color: '#8D8D8D' }}>
              Note: This may conflict with your team availability. The availability of different teams can be found on the&nbsp;
              <a target='_blank' rel='noopener noreferrer' href='/#/user_management/teams' className={classes.urlLink}>
                Teams Page.
              </a>
            </div>
          </CardContent>
        </>
      )}
    </Card>
  )
}

export default AvailabilityCard
