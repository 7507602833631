import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Card, CardContent, Typography, TextField, Divider, Button } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  selectorCard: {
    width: 550,
    borderRadius: 10,
    margin: 50
  },
  container: {
    display: 'flex',
    justifyContent: 'center'
  },
  cardContentArea: {
    height: '100%',
    backgroundColor: 'rgba(193, 166, 237, 0.1)',
    borderRadius: 10,
    padding: 30,
    paddingTop: 20
  },
  header: {
    backgroundColor: theme.palette.primary.main
  },
  subtitle1: {
    fontSize: 20,
    fontWeight: 600,
    color: 'white',
    padding: 20
  },
  subtitle2: {
    fontSize: 15,
    fontWeight: 600,
    marginBottom: 10
  },
  subtitle3: {
    fontSize: 15,
    fontWeight: 600,
    marginTop: 20
  },
  bodyText: {
    color: '#808080',
    marginBottom: 10,
    fontSize: 15
  },
  select: {
    borderRadius: 40
  },
  labelContainer: {
    display: 'flex'
  },
  bold: {
    fontWeight: 600,
    fontSize: 15,
    display: 'block',
    marginTop: 'auto',
    marginBottom: 'auto',
    marginRight: 10,
    minWidth: 100
  },
  nextButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 20
  }
}))

const EnterTestDescription = props => {
  const classes = useStyles()
  const state = props.state

  const handleLabelChange = (name, index) => {
    const labels = state.attributes.labels

    labels[index] = name
    props.handleChange({ attr: 'labels', value: labels })
  }

  return (
    <>
      <div className={classes.container}>
        <Card className={classes.selectorCard} raised>
          <div className={classes.header}>
            <Typography variant='subtitle1' className={classes.subtitle1}>
              Test Description
            </Typography>
          </div>
          <CardContent
            className={classes.cardContentArea}
          >
            <Typography variant='body2' className={classes.bodyText}>
              Enter an identifying label for your bots.
            </Typography>
            <div className={classes.labelContainer}>
              <div className={classes.bold}>
                Original Bot:
              </div>
              <TextField
                label='Label'
                name='label'
                fullWidth
                inputProps={{
                  maxLength: 80
                }}
                variant='outlined'
                margin='dense'
                value={state.attributes.labels[0]}
                onChange={e => handleLabelChange(e.target.value, 0)}
                className={classes.fieldName}
              />
            </div>
            <div className={classes.labelContainer} style={{ paddingBottom: 15 }}>
              <div className={classes.bold}>
                Modified Bot:
              </div>
              <TextField
                label='Label'
                name='label'
                fullWidth
                inputProps={{
                  maxLength: 80
                }}
                variant='outlined'
                margin='dense'
                value={state.attributes.labels[1]}
                onChange={e => handleLabelChange(e.target.value, 1)}
                className={classes.fieldName}
              />
            </div>
            <Divider />
            <Typography variant='subtitle1' className={classes.subtitle3}>
              Description (Optional)
            </Typography>
            <TextField
              label='Insert here a brief description of your test.'
              name='description'
              fullWidth
              multiline
              rows={8}
              variant='outlined'
              margin='dense'
              value={state.attributes.description}
              onChange={e => props.handleChange({ attr: 'description', value: e.target.value })}
              className={classes.fieldName}
            />
            <div className={classes.nextButtonContainer}>
              <Button
                onClick={props.handleNext}
                className={classes.nextButton}
                variant='outlined'
                color='primary'
              >
                Next
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>
    </>
  )
}

export default EnterTestDescription
