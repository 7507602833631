import { Play } from 'classes/plays'
import { FlowBuilder } from 'library/flowBuilder/FlowBuilder'
import { nodeFactory, nodeMapping } from 'pages/plays/playBuilder/nodes/nodeMapping'
import { DashboardContainer } from 'library/dashboard/DashboardContainer'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from 'library/materialUI'
import { ActionDiv } from './RouterPage'
import { useHistory } from 'react-router-dom'
import FTUX from 'cf-components/FTUX'

const useStyles = makeStyles(theme => ({
  title: {
    font: 'normal normal 600 24px/35px Poppins',
    color: '#000010',
    marginBottom: 25
  },
  subtitle: {
    font: 'normal normal 600 18px/27px Poppins'
  },
  description: {
    font: 'normal normal normal 14px/21px Poppins',
    marginBottom: 15
  }
}))

function PlayPreview () {
  const history = useHistory()
  const { data: play, isLoading } = Play.load()

  const goToPlayRunner = () => {
    history.push('/playmaker')
  }

  if (isLoading) {
    return <></>
  }
  const initialData = {
    data: play.getFlow()
  }

  return (
    <div style={{ height: 500, position: 'relative' }} onClick={() => goToPlayRunner()}>
      <Button
        id='open-playrunner-button'
        color='primary'
        style={{ position: 'absolute', top: 20, right: 20, zIndex: 1 }}
        variant='contained'
        href='/#/playmaker'
      >
        Open PlayMaker
      </Button>
      <FlowBuilder
        object={play}
        initialData={initialData}
        nodeMapping={nodeMapping}
        nodeFactory={nodeFactory}
        preview
        objectType='plays'
      />
    </div>
  )
}

function PlayOverview () {
  const classes = useStyles()
  return (
    <div>
      <div className={classes.title}>PlayMaker</div>
      <div className={classes.subtitle}>
        Engagement Manager
      </div>
      <div className={classes.description}>
        Using bots, emails, and alerts, create targeted campaigns to engage your web traffic, expand your sales funnel, and integrate with your daily workflow.
      </div>
      <PlayPreview />
    </div>

  )
}

export function PlayDashboard () {
  const { data: play, isLoading } = Play.load()
  const list = [
    { id: 'overview', title: 'Overview' }
  ]

  const action = {
    label: 'Enable PlayMaker',
    toggle: () => play.update({ enabled: !play.enabled }),
    isToggle: true,
    checked: isLoading ? false : play.enabled
  }

  return (
    <div>
      <ActionDiv action={action} />
      <DashboardContainer
        list={list}
        paneComponent={PlayOverview}
      />
      <FTUX
        page='playrunner-dashboard'
        anchors={[{
          title: 'Welcome to the PlayMaker dashboard!',
          body: (
            <>
              Here you can see how your plays are performing
            </>
          ),
          anchorID: 'overview-dashboard-item',
          anchorPos: 'left',
          anchorOriginHorizontal: 420,
          anchorOriginVertical: 100,
          transformOriginHorizontal: 'left',
          transformOriginVertical: 'center'
        },
        {
          title: 'Build the pieces of your engagement strategy',
          body: (
            <>
              Create and manage chatbots, emails, and forms here
            </>
          ),
          anchorID: 'emails-page-title',
          anchorPos: 'top-left',
          anchorOriginHorizontal: 30,
          anchorOriginVertical: 60,
          transformOriginHorizontal: 'center',
          transformOriginVertical: 'top'
        },
        {

          title: 'Put it all together',
          body: (
            <>
              Organize and orchestrate your visitor experiences here
            </>
          ),
          anchorID: 'open-playrunner-button',
          anchorPos: 'right',
          anchorOriginHorizontal: -45,
          anchorOriginVertical: 50,
          transformOriginHorizontal: 'right',
          transformOriginVertical: 'center'
        }
        ]}
      />
    </div>
  )
}
