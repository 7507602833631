import { Icon, Popover } from 'library/materialUI'
import { makeStyles } from '@material-ui/core/styles'
import { SelectNoOutline } from 'cf-components/material-wrappers/AlternateMuiComponents'
import PieChart from 'pages/dashboard/PieChart'

const useStyles = makeStyles(theme => ({
  tableTitle: {
    color: 'black',
    fontWeight: 600
  },
  flex: {
    display: 'flex',
    alignItems: 'center'
  },
  popSize: {
    height: 'calc(100vh - 32px)',
    width: 'calc(100vw - 40px)'
  }
}))

export const FullScreenPieChart = (props: {
  open: boolean
  handleClose: any
  dataOptions: any
}): JSX.Element => {
  const classes = useStyles()
  const { graphedAttribute, graphOptions, setGraphedAttribute, transformedData, matchedDataRef, icpDataRef, icpDispatch, keysRef, graphedAttributeRef, addToICP, setFuncVars, setOpenModal, setVariableSaveFunc } = props.dataOptions

  return (
    <Popover
      open={props.open}
    >
      <div className={classes.popSize}>
        <div className={`${classes.tableTitle} ${classes.flex}`} style={{ justifyContent: 'space-between' }}>
          <div className={classes.flex} style={{ fontSize: 16 }}>
            Account Makeup By
            <SelectNoOutline
              value={graphedAttribute}
              options={graphOptions}
              onChange={(e: string) => setGraphedAttribute(e)}
            />
          </div>
          <div style={{ padding: 5 }}>
            <Icon
              icon='close'
              cursor='pointer'
              color='primary'
              onClick={props.handleClose}
              size='lg'
            />
          </div>
        </div>
        <PieChart
          id={`${graphedAttribute}_full_size_pie_chart`}
          data={transformedData}
          size={850}
          legendHeight={200}
          eventFunc={(changed: any) => {
            if (!matchedDataRef.current.includes(changed.target._dataItem.properties.category)) {
              setFuncVars({ changed, icpDataRef, graphedAttribute: graphedAttributeRef, icpDispatch, keys: keysRef })
              setOpenModal(true)
              setVariableSaveFunc(() => addToICP)
            }
          }}
        />
      </div>
    </Popover>
  )
}
