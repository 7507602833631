import { makeStyles } from '@material-ui/core/styles'
import SearchIcon from '@material-ui/icons/Search'
import InputBase from '@material-ui/core/InputBase'
import ClearIcon from '@material-ui/icons/Clear'

const useStyles = makeStyles(theme => ({
  searchInput: {
    backgroundColor: '#f2f2f2',
    color: '#7C7C7C',
    fontSize: '1.1em',
    borderRadius: 5,
    height: '2.4em',
    border: 'solid rgba(242, 242, 242, 0) 2px',
    width: '100%',
    margin: 'auto'
  },
  searchInputFocused: {
    border: 'solid #8E5AE2 2px',
    boxShadow: 'none'
  },
  searchIcon: {
    color: '#4A4A4A',
    paddingLeft: 12,
    paddingRight: 12
  },
  clearIcon: {
    paddingRight: 12,
    cursor: 'pointer'
  }
}))

interface SearchBarProps {
  search: string
  setSearch: (search: string) => void
  variant?: 'light' | 'lightRound' | 'outlined' | 'gray'
  placeholder?: string
}

function SearchBar ({ search, setSearch, variant, placeholder }: SearchBarProps) {
  const classes = useStyles()
  let style = {}

  if (variant === 'light') {
    style = {
      backgroundColor: 'white',
      boxShadow: '0px 2px 2px rgba(0,0,0,0.2)'
    }
  }
  if (variant === 'lightRound') {
    style = {
      backgroundColor: 'white',
      width: '80%',
      borderRadius: '20px',
      border: '1px solid black'
    }
  }
  if (variant === 'outlined') {
    style = {
      backgroundColor: 'white',
      borderRadius: '5px',
      border: '1px solid #B7B7B7'
    }
  }
  if (variant === 'gray') {
    style = {
      backgroundColor: '#F5F5F5',
      borderRadius: '5px'
    }
  }

  return (
    <InputBase
      startAdornment={<SearchIcon className={classes.searchIcon} />}
      endAdornment={
        search !== ''
          ? <ClearIcon className={classes.clearIcon} onClick={() => setSearch('')} />
          : <></>
      }
      className={classes.searchInput}
      classes={{ focused: classes.searchInputFocused }}
      type='text'
      value={search}
      onChange={e => setSearch(e.target.value)}
      placeholder={placeholder || 'Search'}
      style={style}
    />
  )
}

export default SearchBar
