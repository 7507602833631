import React, { useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Card, CardHeader, CardContent } from '@material-ui/core'
import { createSecondaryMetrics } from './CreateSecondaryMetrics'
import EmptyState from '../../../components/EmptyState'

const useStyles = makeStyles(theme => ({
  conversionRateCard: {
    borderRadius: 12,
    border: '1px solid rgba(0,0,0,0.2)'
  }
}))

function SecondaryMetricsChart (props) {
  const chartRef = useRef(null)
  const classes = useStyles()
  const colorList = []

  useEffect(() => {
    const id = 'secondarychart'
    const chartData = [
      {
        category: 'Emails Captured'
      },
      {
        category: 'Meetings Booked'
      },
      {
        category: 'Primary Goals Reached'
      },
      {
        category: 'Secondary Goals Reached'
      },
      {
        category: 'Routed to Live Chat'
      }
    ]
    props.rows.sort((a, b) => a.attributes.variant > b.attributes.variant ? 1 : -1).map(row => {
      colorList.push(row.id === props.originalFlowID ? '#8e5ae2' : '#fe9d10')

      const variant = row.attributes.variant
      chartData[0][variant] = row.attributes.emails_captured
      chartData[1][variant] = row.attributes.meetings_booked
      chartData[2][variant] = row.attributes.primary_goals
      chartData[3][variant] = row.attributes.secondary_goals
      chartData[4][variant] = row.attributes.live_chat_routes
      return true
    })
    const data = chartData
    if (props.rows.length > 0 && !chartRef.current) {
      const chart = createSecondaryMetrics({ data, id, colorList })
      chartRef.current = chart
    }
    return function cleanup () {
      if (chartRef.current) {
        chartRef.current.dispose()
        chartRef.current = null
      }
    }
    // eslint-disable-next-line
  }, [])

  return (
    <Card className={classes.conversionRateCard}>
      <CardHeader
        title='Key Metrics'
      />
      <CardContent id='secondary-card-content'>
        <div style={{ height: 450 }} id='secondary-graph'>
          {
            props.rows.length
              ? <div id='secondarychart' style={{ width: '100%', height: '100%' }} />
              : <EmptyState message='Not enough data...yet' />
          }
        </div>
      </CardContent>
    </Card>
  )
}

export default SecondaryMetricsChart
