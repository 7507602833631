import { Section } from './AccountICPSetup'
import { makeStyles } from '@material-ui/core/styles'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Checkbox from '@material-ui/core/Checkbox'
import ListItemText from '@material-ui/core/ListItemText'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { ICPContext } from './ICPProvider'
import { useContext } from 'react'
import { Radio } from 'library/materialUI'

const useStyles = makeStyles(theme => ({
  multipleSelect: {
    display: 'flex',
    '& > div': {
      minWidth: 'min(100%, 300px)',
      overflow: 'hidden',
      '& > *': { width: '100%' }
    }
  },
  disabled: { opacity: 0.5 },
  sectionPadding: { paddingTop: 10 }
}))

const AccountICPTypes = ({
  accountTypes,
  label,
  alt,
  setAlt,
  displayAlt
}: {
  accountTypes: string[] | undefined,
  label: string,
  alt: boolean,
  setAlt: (alt: boolean) => void,
  displayAlt: boolean
}): JSX.Element => {
  const { icpAccountSettings, icpAccountSettingsDispatch } = useContext(ICPContext)
  const classes = useStyles()

  return (
    <>
      <Section
        title='Customers'
        subtitle=''
      >
        {displayAlt && (
          <>
            <div style={{ marginBottom: '10px' }}>
              Which Hubspot property do you use to designate customers?
            </div>
            <div>
              <Radio
                label='Lifecycle Stage'
                value={!alt}
                checked={!alt}
                handleChange={() => {
                  icpAccountSettingsDispatch({ propertyType: 'type' })
                  setAlt(false)
                }}
              />
              <Radio
                label='Type'
                value={alt}
                checked={alt}
                handleChange={() => {
                  icpAccountSettingsDispatch({ propertyType: 'record_type' })
                  setAlt(true)
                }}
              />
            </div>
          </>
        )}
        <div style={{ marginBottom: '10px', marginTop: '10px' }}>
          Which {label} from your CRM do you consider to be customers?
        </div>
        <div className={classes.multipleSelect}>
          <div>
            <Select
              value={icpAccountSettings.customerAccountTypes}
              onChange={e => icpAccountSettingsDispatch({ customerAccountTypes: (e.target.value as string[]) })}
              renderValue={selected => {
                if (selected && (selected as string[]).length) return (selected as string[]).join(', ')
                return <em className={classes.disabled}>Customer Account Types</em>
              }}
              multiple
              displayEmpty
              variant='outlined'
              margin='dense'
            >
              <MenuItem disabled value=''>
                <em>Customer Account Types</em>
              </MenuItem>
              {(accountTypes || []).map(value => {
                return (
                  <MenuItem key={value} value={value}>
                    <Checkbox
                      color='primary'
                      checked={icpAccountSettings.customerAccountTypes.indexOf(value) > -1}
                    />
                    <ListItemText primary={value} />
                  </MenuItem>
                )
              })}
            </Select>
          </div>
        </div>
      </Section>
      <div className={classes.sectionPadding} />
      <Section
        title='Closed-lost'
        subtitle='Would you like to include closed-lost opportunities in your analysis?'
      >
        <FormControlLabel
          control={<Checkbox checked={icpAccountSettings.includeClosedLost} onChange={e => icpAccountSettingsDispatch({ includeClosedLost: e.target.checked })} color='primary' />}
          label='Include closed-lost opportunities'
        />
      </Section>
    </>
  )
}

export default AccountICPTypes
