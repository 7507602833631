import { makeStyles } from '@material-ui/core/styles'
import { Icon } from 'library/materialUI'
import { Divider } from '@material-ui/core'
import { useContext } from 'react'
import { AccountPageContext } from './AccountPageProvider'
import { SessionContext } from 'session-context'
import { addVote } from 'api/crowdsource'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    color: 'white',
    justifyContent: 'center',
    alignItems: 'center'
  },
  buttonStyle: {
    border: 'white',
    borderRadius: '5px',
    borderWidth: '1px',
    borderStyle: 'solid',
    marginLeft: '5px',
    marginRight: '5px',
    fontSize: '10px',
    cursor: 'pointer'
  },
  elementContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '5px',
    marginRight: '5px'
  },
  verticalDivider: {
    height: 26,
    color: 'white',
    backgroundColor: 'white'
  }
}))

interface VotingPopperProps {
  domain: string
  field: string
  value: any
}

export default function VotingPopper ({ domain, field, value }: VotingPopperProps): JSX.Element {
  const classes = useStyles()
  const { setDetailsModalOpen, setLastSelectedDetail } = useContext(AccountPageContext)
  const { setSnackState } = useContext(SessionContext)

  const handleVoteYes = () => {
    addVote({ domain: domain, field: field, vote: { value: value, isUpvote: true } })
    setSnackState({
      open: true,
      message: 'Thanks for your feedback!',
      variant: 'success'
    })
  }

  const handleVoteNo = () => {
    addVote({ domain, field, vote: { value: value, isUpvote: false } })
    setLastSelectedDetail(field)
    setDetailsModalOpen(true)
    setSnackState({
      open: true,
      message: 'Thanks for your feedback! Please enter the correct information above if you have it.',
      variant: 'success'
    })
  }

  const handleEdit = () => {
    setLastSelectedDetail(field)
    setDetailsModalOpen(true)
  }

  const handleClone = () => {
    navigator.clipboard.writeText(value)
    setSnackState({
      open: true,
      message: 'Value copied to clipboard!',
      variant: 'success'
    })
  }

  return (
    <div className={classes.container}>
      <div className={classes.elementContainer}>
        Accurate?
      </div>
      <div onClick={() => handleVoteYes()} className={classes.buttonStyle}>
        <div style={{ margin: '2px' }}>
          YES
        </div>
      </div>
      <div onClick={() => handleVoteNo()} className={classes.buttonStyle}>
        <div style={{ margin: '2px' }}>
          NO
        </div>
      </div>
      <div className={classes.elementContainer}>
        <Divider className={classes.verticalDivider} orientation='vertical' />
      </div>
      <div onClick={() => handleEdit()} style={{ cursor: 'pointer' }} className={classes.elementContainer}>
        <Icon icon='edit' size='sm' />
      </div>
      <div onClick={() => handleClone()} style={{ cursor: 'pointer' }} className={classes.elementContainer}>
        <Icon icon='clone' size='sm' />
      </div>
    </div>
  )
}
