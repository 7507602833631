import React, { useState, useRef, useEffect } from 'react'
import WifiIcon from '@material-ui/icons/Wifi'

function ConnectivityWarning (props) {
  const [unconnected, setUnconnected] = useState(false)
  const connectionRef = useRef(null)
  connectionRef.current = props.connectionStatus

  useEffect(() => {
    if (connectionRef.current !== WebSocket.OPEN) {
      setTimeout(() => {
        if (connectionRef.current !== WebSocket.OPEN) {
          setUnconnected(true)
        }
      }, 5000)
    } else {
      setUnconnected(false)
    }
  }, [props.connectionStatus])

  if (!unconnected) {
    return <></>
  }

  setTimeout(() => {
    props.scrollToBottom()
  }, 10)

  return (
    <div
      style={{
        border: '1px solid #F0706F',
        maxWidth: '350px',
        margin: 'auto',
        marginTop: 10,
        marginBottom: 20,
        padding: 10,
        display: 'grid',
        gridTemplateColumns: '35px auto',
        color: '#F53D3D',
        fontSize: '.9em'
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <WifiIcon
          style={{ height: 30, width: 30, fill: '#F0706F' }}
        />
      </div>
      <div style={{ marginLeft: 10 }}>
        Your internet connection is too weak to send or receive messages. Please check your connection.
      </div>
    </div>
  )
}

export default ConnectivityWarning
