import { makeStyles } from '@material-ui/core/styles'
import AccountDetails from 'classes/accountDetails'
import { formatDate } from 'classes/classHelpers'
import { attributeToString } from 'helpers/accountModerationHelper'
import { capitalize } from 'library/helpers'
import { PopoverDiv } from 'pages/plays/playBuilder/nodes/PopoverDiv'
import CrowdSourceStatus from './CrowdSourceStatus'
import CrowdSourceStatusDropdown from './CrowdSourceStatusDropdown'
import { useQuery } from 'react-query'
import { getUser } from 'api/users'
import { useState } from 'react'
import { Icon, Tooltip } from 'library/materialUI'

const useStyles = makeStyles(theme => ({
  dataRow: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr',
    paddingBottom: 10,
    paddingTop: 10,
    alignItems: 'center',
    fontFamily: 'poppins'
  },
  accountName: {
    fontSize: '15px',
    fontFamily: 'poppins',
    paddingLeft: 10,
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  dataItem: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: '15px',
    fontFamily: 'poppins'
  },
  divider: {
    backgroundColor: '#C6ADF1'
  },
  grayText: {
    color: '#00000080',
    fontFamily: 'poppins',
    cursor: 'pointer'
  }
}))

interface CrowdSourceRowProps {
  accountDetails: AccountDetails
  setModalOpen: (open: boolean) => void
  setModalDomain: (domain: string) => void
  setModalField: (field: string) => void
  setPrevValue: (value: string) => void
}

export default function CrowdSourceRow ({ accountDetails, setModalOpen, setModalDomain, setModalField, setPrevValue }: CrowdSourceRowProps) {
  const classes = useStyles()
  const userID = accountDetails.createdByUserID || 0
  const [status, setStatus] = useState(accountDetails.status)
  const [popoverOpen, setPopoverOpen] = useState(false)
  const { data: userData } = useQuery(`/user/${userID}`, () => {
    if (!userID) return Promise.resolve(null)
    return getUser({ ID: userID })
  }, {
    staleTime: 120000,
    retry: false,
    placeholderData: null
  })

  return (
    <div className={classes.dataRow}>
      <div className={classes.dataItem} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginLeft: '30px' }}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div>
            {capitalize(accountDetails.name)}
          </div>
          {accountDetails?.linkedin_url && (
            <div style={{ cursor: 'pointer', marginLeft: '10px', marginTop: '2px' }} onClick={() => window.open(!accountDetails.linkedin_url.includes('http') ? 'https://' + accountDetails.linkedin_url : accountDetails.linkedin_url)}>
              <Icon icon='smallLinkedIn' />
            </div>
          )}
        </div>
        <div className={classes.grayText}>
          <a href={'https://' + accountDetails.domain} target='_blank' rel='noreferrer'>{accountDetails.domain}</a>
        </div>
      </div>
      <div className={classes.dataItem} style={{ fontWeight: 'bold' }}>
        {attributeToString(accountDetails.field)}
      </div>
      <div className={classes.dataItem}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
          <div>
            {accountDetails.value || accountDetails.valueInt || accountDetails.valueTextList}
          </div>
          <Tooltip title={accountDetails?.previousValue ? 'Previous value: ' + accountDetails?.previousValue : 'No Apollo Value'}>
            <div style={{ marginLeft: '4px', marginTop: '2px' }}>
              <Icon icon='info' color='#C4C4C4' size='sm' />
            </div>
          </Tooltip>
        </div>
      </div>
      <div className={classes.dataItem}>
        <PopoverDiv
          open={popoverOpen}
          handleOpen={() => setPopoverOpen(true)}
          handleClose={() => setPopoverOpen(false)}
          popoverContents={
            <CrowdSourceStatusDropdown setStatus={(value: string) => { setStatus(value); setPopoverOpen(false) }} setModalOpen={(value: boolean) => { setModalOpen(value); setPopoverOpen(false) }} setModalDomain={setModalDomain} setModalField={setModalField} setPrevValue={setPrevValue} domain={accountDetails.domain} field={accountDetails.field} value={accountDetails.value || accountDetails.valueInt || accountDetails.valueTextList} />
          }
          divContents={
            <div>
              <CrowdSourceStatus status={status} />
            </div>
          }
        />
      </div>
      <div className={classes.dataItem}>
        {formatDate(accountDetails.createdTimestamp)}
      </div>
      <div className={classes.dataItem}>
        {userData?.name || 'Unknown'}
      </div>
      <div className={classes.dataItem}>
        {accountDetails.tenantID}
      </div>
    </div>
  )
}
