type Category = {
  color: string
  category?: string
}

export const categoryMapping: Record<string, Category> = {
  messaging: {
    color: '#EC5F8A',
    category: 'Messaging'
  },
  capture: {
    color: '#4972CC',
    category: 'Data Capture'
  },
  routing: {
    color: '#49CDA2',
    category: 'Routing'
  },
  internal: {
    color: '#EC5F8A',
    category: 'Internal'
  },
  branching: {
    color: '#E76033',
    category: 'Attributes'
  },
  signals: {
    color: '#9933FF',
    category: 'Buying Signals'
  },
  start: {
    color: '#81AF20'
  }
}
