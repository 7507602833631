import React from 'react'
import desertIcon from 'img/desertIcon.svg'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  emptyStateMessage: {
    textAlign: 'center',
    font: 'normal normal bold 16px/22px Poppins',
    letterSpacing: 0,
    color: '#A690CE',
    opacity: 1,
    whiteSpace: 'pre-wrap',
    marginTop: 30
  },
  svg: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 5,
    height: 400,
    marginTop: 40
  }
}))

function NoActivity (props) {
  const classes = useStyles()
  const iFrame = props.iFrame || false

  return (
    <div>
      {!iFrame && (
        <div className={classes.svg}>
          <img src={desertIcon} alt='Empty state' />
        </div>
      )}
      <div className={classes.emptyStateMessage}>
        <p>There hasn’t been any activity from </p>
        <p style={{ marginTop: -10 }}>this account in the last {props.days || '30 days'}.</p>
      </div>
    </div>
  )
}

export default NoActivity
