/* eslint-disable @typescript-eslint/naming-convention */
import { useEffect, useState } from 'react'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import InputAdornment from '@material-ui/core/InputAdornment'
import Typography from '@material-ui/core/Typography'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { Modal, ModalSection } from 'library/Modal'
import Button from '@material-ui/core/Button'
import zoomLogo from 'img/integrations/zoom.png'
import msTeamsLogo from 'img/integrations/msteams.png'
import gotoMeetingLogo from 'img/integrations/gotomeeting.png'
import { Checkbox, Icon, Radio } from 'library/materialUI'
import { WarningRounded } from '@material-ui/icons'
import Edit from '@material-ui/icons/Edit'

const useStyles = makeStyles((theme) => ({
  textField: {
    cursor: 'text',
    width: '100%',
    marginTop: 10
  },
  groupContainer: {
    backgroundColor: 'rgba(193, 166, 237, 0.1)',
    padding: 10,
    borderRadius: 10,
    paddingBottom: 25
  },
  buttonAndTitleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  subtitle1: {
    fontSize: 18,
    fontWeight: 600,
    marginBottom: 15
  },
  subtitle2: {
    fontSize: 15,
    fontWeight: 600,
    marginBottom: 10
  },
  errorMessage: {
    color: 'red',
    marginTop: 10
  },
  bodyText: {
    marginBottom: 10,
    fontSize: '14px'
  },
  menuHeader: {
    borderBottom: '1px solid #BBB',
    width: '100%',
    fontWeight: 600,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: 'rgba(0,0,0,0.87)'
  },
  boldText: {
    marginBottom: 10,
    fontSize: '16px',
    fontWeight: 'bold'
  },
  conferencingContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: '5px',
    padding: '10px',
    border: '1px solid #D3D3D3'
  }
}))

const leadTimes = [
  { value: 0, name: 'No Lead Time' },
  { value: 15, name: '15 minutes' },
  { value: 30, name: '30 minutes' },
  { value: 45, name: '45 minutes' },
  { value: 60, name: '60 minutes' },
  { value: 90, name: '90 minutes' },
  { value: 120, name: '2 hours' },
  { value: 180, name: '3 hours' },
  { value: 240, name: '4 hours' },
  { value: 300, name: '5 hours' },
  { value: 360, name: '6 hours' },
  { value: 420, name: '7 hours' },
  { value: 480, name: '8 hours' },
  { value: 720, name: '12 hours' },
  { value: 1440, name: '24 hours' },
  { value: 2880, name: '48 hours' }

]

const bufferTimes = [
  { value: 0, name: 'No Buffer' },
  { value: 15, name: '15 minutes' },
  { value: 30, name: '30 minutes' },
  { value: 45, name: '45 minutes' },
  { value: 60, name: '1 hour' },
  { value: 75, name: '75 minutes' },
  { value: 90, name: '90 minutes' },
  { value: 105, name: '105 minutes' },
  { value: 120, name: '2 hours' }
]

const MeetingSettingsModal = (props: any) => {
  const classes = useStyles()
  const { lead_time, location_url, buffer_time, description, conferencing_type, meet_now_url } = props.meetingInfo.attributes
  const { open, onSubmit, onHide, onChange } = props
  const conferencingProvider = props.conferencingProvider
  const setupConferencingHandler = props.setupConferencingHandler
  const missingConferencing = props.missingConferencing
  const [usePersonalLink, setUsePersonalLink] = useState(false)
  const handleUsePersonalLink = () => {
    setUsePersonalLink(!usePersonalLink)
    if (!usePersonalLink) {
      onChange({ type: 'meet_now_url', data: location_url })
    }
  }

  useEffect(() => {
    if (usePersonalLink && location_url) {
      onChange({ type: 'meet_now_url', data: location_url })
    }
    // eslint-disable-next-line
  }, [usePersonalLink, location_url])

  if (!props.meetingInfo) {
    return <></>
  }

  let conferencingProviderAlt = ''
  let conferencingProviderImgSrc
  if (conferencingProvider === 'zoom') {
    conferencingProviderImgSrc = zoomLogo
    conferencingProviderAlt = 'Zoom'
  } else if (conferencingProvider === 'ms_teams') {
    conferencingProviderImgSrc = msTeamsLogo
    conferencingProviderAlt = 'Microsoft Teams'
  } else if (conferencingProvider === '') {
    conferencingProviderImgSrc = gotoMeetingLogo
    conferencingProviderAlt = 'GoToMeeting'
  }

  return (
    <>
      <Modal
        title='Meeting Settings'
        onHide={() => {
          onHide()
        }}
        open={open}
        handleSave={onSubmit}
        saveIcon='save'
        helplink='https://help.getsignals.ai/article/wgr5xzf4ea-my-calendar'
        helplinkLabel='Learn more about these settings'
      >
        <ModalSection
          title='Email Signature'
          subtitle='Insert your email signature below'
        >
          <TextField
            margin='dense'
            label='Signature'
            multiline
            variant='outlined'
            value={description}
            rows={4}
            fullWidth
            onChange={(e) =>
              onChange({ type: 'description', data: e.target.value })}
          />
        </ModalSection>
        <ModalSection
          title='Conferencing Provider'
          subtitle='Connect your conferencing provider to automatically generate new meeting links or paste in your personal meeting link.'
        >
          <div>
            <div>
              <Radio
                checked={conferencing_type === 'cronofy'}
                handleChange={(value) => {
                  onChange({ type: 'conferencing_type', data: value })
                }}
                value='cronofy'
                label='Auto-generate links (recommended)'
              />
            </div>
            <div style={{ marginLeft: 22 }}>
              <Typography variant='body2' className={classes.bodyText}>
                Automatically create Zoom, GoToMeeting, or Microsoft Teams events in the calendar invitations
              </Typography>
              {conferencing_type === 'cronofy' ? (
                <>
                  <div className={classes.conferencingContainer} style={{ backgroundColor: conferencingProvider ? '#81AF201A' : 'inherit' }}>
                    {conferencingProvider && <Icon icon='lightGreenCheckCircle' />}
                    <img src={conferencingProviderImgSrc} alt={conferencingProviderAlt} style={{ width: '150px' }} />
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Button
                        onClick={setupConferencingHandler}
                        variant='outlined'
                        color='primary'
                        style={{ textTransform: 'none' }}
                        startIcon={conferencingProvider ? <Edit /> : null}
                      >{conferencingProvider ? 'Edit Conference Provider' : '+ Add Conferencing Provider'}
                      </Button>
                    </div>
                  </div>
                  <Typography variant='body1' style={{ marginLeft: '10px', color: 'red' }}>
                    {!conferencingProvider && <div style={{ display: 'flex', alignItems: 'center' }}><WarningRounded color='error' /> You must have a conferencing provider</div>}
                  </Typography>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
          <br />
          <div>
            <Radio
              checked={conferencing_type === 'url'}
              handleChange={(value) => {
                onChange({ type: 'conferencing_type', data: value })
              }}
              value='url'
              label='Use personal meeting link'
            />
          </div>
          <div style={{ marginLeft: 22 }}>
            <Typography variant='body2' className={classes.bodyText}>
              Insert your URL for Zoom, Teams, etc
            </Typography>
            {conferencing_type === 'url' ? (
              <>
                <TextField
                  fullWidth
                  className={classes.textField}
                  color='primary'
                  variant='outlined'
                  type='text'
                  value={location_url}
                  onChange={(e) =>
                    onChange({ type: 'location_url', data: e.target.value })}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <Icon icon='addLink' color='primary' />
                      </InputAdornment>
                    )
                  }}
                />
              </>
            ) : (
              <></>
            )}
          </div>
        </ModalSection>
        <ModalSection
          title='"Meet Now" Link'
          subtitle='Meeting link that you can drop into chats to immediately start a meeting with a visitor you are chatting with'
        >
          <TextField
            margin='dense'
            label='Meet Now Link'
            variant='outlined'
            value={meet_now_url}
            disabled={(conferencing_type === 'url' && usePersonalLink)}
            rows={1}
            fullWidth
            onChange={(e) =>
              onChange({ type: 'meet_now_url', data: e.target.value })}
          />
          <Typography variant='body1' style={{ marginLeft: '10px', color: 'red' }}>
            {missingConferencing && <div style={{ display: 'flex', alignItems: 'center' }}><WarningRounded color='error' /> You must have a conferencing link</div>}
          </Typography>
          {conferencing_type === 'url' && (
            <Checkbox
              checked={usePersonalLink}
              onChange={() => handleUsePersonalLink()}
              label='Same as personal meeting link'
            />
          )}
        </ModalSection>
        <ModalSection
          title='Buffer Time'
          subtitle='The minimum amount of time between when one meeting ends and when a new meeting can be scheduled'
        >
          <Select
            name='buffer_time'
            variant='outlined'
            margin='dense'
            fullWidth
            defaultValue={0}
            value={buffer_time}
            onChange={(bufferTime) =>
              props.onChange({
                type: 'buffer_time',
                data: bufferTime.target.value
              })}
          >
            <MenuItem disabled style={{ opacity: 1 }}>
              <div className={classes.menuHeader}>Buffer Time</div>
            </MenuItem>
            {bufferTimes.map((time, index) => (
              <MenuItem key={index} value={time.value}>
                {time.name}
              </MenuItem>
            ))}
          </Select>
        </ModalSection>
        <ModalSection
          title='Lead Time'
          subtitle='The minimum amount of time between the current time and when next the possible meeting can occur'
        >
          <Select
            name='lead_time'
            variant='outlined'
            margin='dense'
            fullWidth
            defaultValue={0}
            value={lead_time}
            onChange={(leadTime) =>
              props.onChange({
                type: 'lead_time',
                data: leadTime.target.value
              })}
          >
            <MenuItem disabled style={{ opacity: 1 }}>
              <div className={classes.menuHeader}>Lead Time</div>
            </MenuItem>
            {leadTimes.map((time, index) => (
              <MenuItem key={index} value={time.value}>
                {time.name}
              </MenuItem>
            ))}
          </Select>
        </ModalSection>
      </Modal>
    </>
  )
}

export default MeetingSettingsModal

export const meetingSettingsMapping = {
  icon: 'calendar' as const,
  title: 'Meeting Settings',
  color: 'orange',
  helpCenterLink: 'home/settings/Calendar'
}
