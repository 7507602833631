import { Select } from 'cf-components/MuiComponents'
import { Card, CardHeader, CardContent } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CompanyAvilableThumbnail from 'img/company_available_thumbnail.png'
import CompanyUnavilableThumbnail from 'img/company_unavailable_thumbnail.png'
import BotVisibleThumbnail from 'img/bot_visible_thumbnail.png'
import Picker from 'cf-components/Pickers'
import { Button } from 'library/materialUI'

const useStyles = makeStyles(theme => ({
  cardRoot: {
    padding: '8px 24px'
  },
  enableUsersCheckBox: {
    display: 'inline-flex',
    marginTop: 15
  },
  cardTitle: {
    fontSize: '17px'
  },
  card: {
    border: '1px solid rgba(142, 90, 226, 0.4)',
    minHeight: 60,
    cursor: 'pointer',
    borderRadius: 10,
    backgroundColor: 'white',
    marginBottom: 20
  },
  imageContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: 24,
    height: '100%'
  },
  subText: {
    color: 'grey',
    fontSize: 14
  },
  pickerContainer: {
    maxWidth: '260px',
    marginTop: '15px'
  }
}))

const visibilityOptions = [
  { value: 'always', label: 'Always display the chat prompt' },
  { value: 'available', label: 'Display the prompt during company availability or when bot fires' },
  { value: 'bot', label: 'Only display the prompt when the bot fires' }
]

const getThumbnail = (visibilityOption) => {
  switch (visibilityOption) {
    case 'always':
      return (
        <>
          <div>
            <img src={CompanyAvilableThumbnail} style={{ height: 125, marginRight: 20 }} alt='Always display thumbnail' />
          </div>
        </>
      )
    case 'available':
      return (
        <>
          <div>
            <div>
              <img src={CompanyAvilableThumbnail} style={{ height: 125, marginRight: 20 }} alt='Company available thumbnail' />
            </div>
            <div style={{ textAlign: 'center', color: '#985CE6', fontSize: 14 }}>
              COMPANY AVAILABLE
            </div>
          </div>
          <div>
            <div>
              <img src={CompanyUnavilableThumbnail} style={{ height: 125 }} alt='Company unavailable thumbnail' />
            </div>
            <div style={{ textAlign: 'center', color: '#985CE6', fontSize: 14 }}>
              COMPANY UNAVAILABLE
            </div>
          </div>
        </>
      )
    case 'bot':
      return (
        <>
          <div>
            <img src={BotVisibleThumbnail} style={{ height: 125, marginRight: 20 }} alt='Bot visible thumbnail' />
          </div>
        </>
      )
    default:
      return (
        <></>
      )
  }
}

const ChatIconDisplaySettings = props => {
  const classes = useStyles()
  const dispatch = props.dispatch
  const promptVisibility = props.promptVisibility
  const inOfficeBotID = props.state.attributes.in_office_greeting_bot_id
  const outOfficeBotID = props.state.attributes.out_office_greeting_bot_id
  const error = props.state.attributes.attempt_save

  return (
    <div style={{ position: 'relative' }}>
      <Card classes={{ root: classes.card }}>
        <CardHeader
          classes={{ title: classes.cardTitle }}
          title={props.title}
        />
        <CardContent classes={{ root: classes.cardRoot }}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div>
              <div className={classes.subText}>
                Configure when you want the chat icon to appear.
              </div>
              <div className='select'>
                <Select
                  value={promptVisibility}
                  onChange={e => dispatch({ type: 'updateSetting', name: 'prompt_visibility', value: e.target.value })}
                  variant='rounded'
                  label='--Select an option below--'
                  options={visibilityOptions}
                />
              </div>
              <div style={{ marginTop: '15px' }}>
                {promptVisibility === 'available' ? (
                  <>
                    <div className={classes.subText}>
                      Select the bot that will be fired when a visitor clicks the prompt on pages without a designated bot
                    </div>
                    {error && (
                      <div style={{ color: 'red' }}>
                        Make sure to select which bot to fire
                      </div>
                    )}
                    <div className={classes.pickerContainer}>
                      <Picker
                        selection={inOfficeBotID}
                        setSelection={(value) => dispatch({ type: 'updateSetting', name: 'in_office_greeting_bot_id', value: value })}
                        objectType='bots'
                      />
                    </div>
                    {inOfficeBotID && (
                      <Button
                        style={{ fontSize: 12, color: 'black', marginTop: 5, borderRadius: 50 }}
                        variant='outlined'
                        size='small'
                        onClick={() => window.open('/#/bots/' + inOfficeBotID)}
                      >
                        Edit in Bot Builder
                      </Button>
                    )}
                  </>
                ) : promptVisibility === 'always' && (
                  <>
                    <div className={classes.subText}>
                      Select the bots that will be fired when a visitor clicks the prompt on pages without a designated bot
                    </div>
                    {error && (
                      <div style={{ color: 'red' }}>
                        Make sure to select which bots to fire
                      </div>
                    )}
                    <div className={classes.pickerContainer}>
                      <div className={classes.subText}>
                        During company availability:
                      </div>
                      <Picker
                        selection={inOfficeBotID}
                        setSelection={(value) => dispatch({ type: 'updateSetting', name: 'in_office_greeting_bot_id', value: value })}
                        objectType='bots'
                      />
                    </div>
                    {inOfficeBotID && (
                      <Button
                        style={{ fontSize: 12, color: 'black', marginTop: 5, borderRadius: 50 }}
                        variant='outlined'
                        size='small'
                        onClick={() => window.open('/#/bots/' + inOfficeBotID)}
                      >
                        Edit in Bot Builder
                      </Button>
                    )}
                    <div className={classes.pickerContainer}>
                      <div className={classes.subText}>
                        Outside of company availability:
                      </div>
                      <Picker
                        selection={outOfficeBotID}
                        setSelection={(value) => dispatch({ type: 'updateSetting', name: 'out_office_greeting_bot_id', value: value })}
                        objectType='bots'
                      />
                    </div>
                    {outOfficeBotID && (
                      <Button
                        style={{ fontSize: 12, color: 'black', marginTop: 5, borderRadius: 50 }}
                        variant='outlined'
                        size='small'
                        onClick={() => window.open('/#/bots/' + outOfficeBotID)}
                      >
                        Edit in Bot Builder
                      </Button>
                    )}
                  </>
                )}
              </div>
            </div>
            <div style={{ height: '100%', marginLeft: 'auto', marginRight: 0 }}>
              <div className={classes.imageContainer}>
                {getThumbnail(promptVisibility)}
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  )
}

export default ChatIconDisplaySettings
