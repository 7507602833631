import { useState, useEffect } from 'react'
import { makeStyles, ThemeProvider } from '@material-ui/core/styles'
import { jsPlumbUtil } from 'jsplumbtoolkit'
import { getTags, createTag } from 'api/tags'
import OptionPort from '../OptionPort'
import Divider from '@material-ui/core/Divider'
import useNodeStyles from './NodeStyles'
import { Tooltip } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/DoneOutline'
import AttributeSelect from '../AttributeSelect'
import TextField from '@material-ui/core/TextField'
import IntegrationPlanBasicPicker from './IntegrationPlanBasicPicker'
import ConversationTagsModal from 'pages/settings/Tags/ConversationTagsModal'
import Chip from '@material-ui/core/Chip'
import { truncateString } from 'library/helpers'

import '../quill.css'
import { EVENT_TYPE, Emitter } from 'emitter'
import { Modal, ModalSection } from 'library/Modal'
import { Select } from 'cf-components/material-wrappers/MaterialComponents'
import theme from 'theme'
import { Checkbox, Icon } from 'library/materialUI'

const useStyles = makeStyles(theme => ({
  subtitle: {
    color: '#999',
    fontFamily: 'Poppins, sans serif',
    fontSize: '12px'
  },
  divider: {
    marginTop: 10,
    marginBottom: 10
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: 0,
    marginLeft: 5
  },
  chip: {
    marginRight: 8,
    marginTop: -4,
    backgroundColor: '#DD64E7',
    height: 25
  },
  input: {
    padding: 6,
    borderRadius: 30
  },
  inputRoot: {
    borderRadius: 30
  },
  select: {
    height: 24,
    borderRadius: 30,
    paddingLeft: 10
  },
  goalTypeDiv: {
    color: '#4c4c4c',
    fontWeight: 600,
    fontFamily: 'Poppins, sans serif',
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  miniSelectInput: {
    padding: 8,
    paddingLeft: 10,
    borderRadius: 30
  },
  miniSelect: {
    fontFamily: 'Poppins, sans serif',
    fontSize: '14px',
    color: '#5C5C5C'
  },
  plusIcon: {
    marginLeft: '15px',
    cursor: 'pointer'
  }
}))

function TagSelect (props) {
  return (
    <div style={{ width: '100%' }}>
      <Select
        value={props.value}
        onChange={props.handleChange}
        displayEmpty
        options={props.tags.map(tag => ({ value: tag.id, label: tag.label }))}
        placeholder='Select a tag'
      />
    </div>
  )
}

function RenderTag (props) {
  const chipClasses = useStyles()

  if (!props.tag) {
    return <div />
  } else {
    return (
      <div className={chipClasses.chips} style={{ height: 5 }}>
        <Chip
          color='primary'
          label={truncateString(props.tag, 19, true)}
          className={chipClasses.chip}
        />
      </div>
    )
  }
}

function GoalModal (props) {
  const classes = useStyles()
  const [label, setLabel] = useState(props.label)
  const [goalType, setGoalType] = useState(props.goalType)
  const [tagID, setTagID] = useState(props.tagID || '')
  const [tenantTags, setTenantTags] = useState(props.tenantTags)
  const [setFields, setSetFields] = useState(props.setFields || [])
  const shouldDoAttributes = (setFields || []).length > 0
  const [integrationPlanId, setIntegrationPlanId] = useState(props.integrationPlanId || null)
  const [shouldDoIntegrationPlan, setShouldDoIntegrationPlan] = useState(Boolean(props.integrationPlanId))
  const [hasAdvancedIntegration, setHasAdvancedIntegration] = useState(false)

  useEffect(() => {
    fetch('/api/auth/me', {
      method: 'GET',
      headers: { 'Content-Type': 'application/vnd.api+json' }
    })
      .then(response => response.json())
      .then(response => {
        if (response.data && response.data.id) {
          if (response.data.attributes.perms.tenant_salesforce_advanced) {
            setHasAdvancedIntegration(true)
          }
        }
      })
  }, [])

  useEffect(() => {
    setLabel(props.label)
    setGoalType(props.goalType)
    setSetFields(props.setFields || [])
  }, [props.label, props.goalType, props.setFields])

  useEffect(() => {
    setTenantTags(props.tenantTags)
  }, [props.tenantTags])

  function cancelChanges () {
    props.onHide()
    setLabel(props.label)
    setGoalType(props.goalType)
  }

  if (!tenantTags || !props.open) {
    return <></>
  }

  Emitter.on(EVENT_TYPE.BROWSER_BACK, cancelChanges)

  return (
    <ThemeProvider theme={theme}>
      <Modal
        open={props.open}
        onHide={cancelChanges}
        handleSave={() => props.save({ label, goalType, tagID, setFields, integrationPlanId })}
        title='Conversation Goal'
        helplink='home/bots/nodes/ConversationGoal'
        helplinkLabel='Learn more about this skill'
        saveIcon='save'
      >
        <ModalSection
          subtitle='Is this a primary or secondary goal?'
        >
          <Select
            value={goalType}
            onChange={setGoalType}
            options={[{ value: 'primary', label: 'Primary' }, { value: 'secondary', label: 'Secondary' }]}
            disableClear
          />
        </ModalSection>
        <ModalSection
          subtitle='Select an optional tag to associate with this goal'
        >
          <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <TagSelect
              tags={tenantTags}
              value={tagID}
              handleChange={(value) => setTagID(value)}
            />
            <Tooltip title='Create new tag'>
              <div onClick={props.handleOpenNewTagModal} className={classes.plusIcon}>
                <Icon icon='addBox' size='md' />
              </div>
            </Tooltip>
          </div>
        </ModalSection>
        <ModalSection>
          <Checkbox
            checked={shouldDoAttributes}
            onChange={(checked) => {
              if (checked) {
                setSetFields([{ field: '', value: '' }])
              } else {
                setSetFields([])
              }
            }}
            name='setContactAttribute'
            color='primary'
            label='Set a contact attribute'
          />
          <br />
          {setFields.map((field, i) => (
            <div key={i} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div style={{ flex: 1 }}>
                <AttributeSelect
                  type='people'
                  value={field.field.attribute}
                  setAttribute={(val) => {
                    const newField = { ...field, field: val }
                    const newSetFields = [...setFields]
                    newSetFields.splice(i, 1, newField)
                    setSetFields(newSetFields)
                  }}
                />
              </div>
              <div style={{ flex: 1, marginLeft: 10 }}>
                <TextField
                  variant='outlined'
                  placeholder='Value'
                  size='small'
                  value={field.value}
                  onChange={(e) => {
                    const newField = { ...field, value: e.target.value }
                    const newSetFields = [...setFields]
                    newSetFields.splice(i, 1, newField)
                    setSetFields(newSetFields)
                  }}
                />
              </div>
            </div>
          ))}
        </ModalSection>
        <ModalSection>
          {hasAdvancedIntegration && (
            <div>
              <Checkbox
                checked={shouldDoIntegrationPlan}
                onChange={(checked) => {
                  if (checked) {
                    setShouldDoIntegrationPlan(true)
                  } else {
                    setShouldDoIntegrationPlan(false)
                  }
                }}
                name='setContactAttribute'
                color='primary'
                label='Use an integration'
              />
              <br />
              {shouldDoIntegrationPlan && (
                <IntegrationPlanBasicPicker setIntegrationPlanId={setIntegrationPlanId} integrationPlanId={integrationPlanId} />
              )}

            </div>
          )}
        </ModalSection>
      </Modal>
    </ThemeProvider>
  )
}

const GoalNode = props => {
  const [chatServiceUrl, setServiceUrl] = useState(null)
  const [tenantTags, setTenantTags] = useState([])
  const [createTagModalOpen, setCreateTagModalOpen] = useState(false)
  const [token, setToken] = useState(null)
  const base = props.base
  const classes = useNodeStyles(props)
  const [modalState, setModalState] = useState({
    tag_name: '',
    description: '',
    is_goal: true
  })

  const handleCreateTag = (tag_name, description, is_goal, id = null) => {
    createTag({ tag_name, description, is_goal, token, chatServiceUrl, id }).then(response => {
      if (token && chatServiceUrl) {
        getTags({ token, chatServiceUrl }).then(response => {
          setTenantTags(response.data.filter(tag => tag.attributes.is_goal === true).map(tag => ({ id: tag.id, label: tag.attributes.tag_name })))
        })
        setCreateTagModalOpen(false)
        setModalState({
          tag_name: '',
          description: '',
          is_goal: true
        })
      }
    })
  }

  const openCreateTagModal = () => {
    setCreateTagModalOpen(true)
  }

  useEffect(() => {
    const headers = {
      'Content-Type': 'application/vnd.api+json'
    }
    fetch('/api/auth/token', {
      method: 'GET',
      headers: headers
    })
      .then(response => response.json())
      .then(account_response => {
        setToken(account_response.token)
      })
  }, [])

  useEffect(() => {
    fetch('/api/auth/me', {
      method: 'GET',
      headers: { 'Content-Type': 'application/vnd.api+json' }
    })
      .then(response => response.json())
      .then(response => {
        if (response.data && response.data.id) {
          setServiceUrl(response.data.links.chat_service || 'localhost:8002/api/chat-service/a')
        }
      })
  }, [])

  useEffect(() => {
    if (token && chatServiceUrl) {
      getTags({ token, chatServiceUrl }).then(response => {
        setTenantTags(response.data.filter(tag => tag.attributes.is_goal === true).map(tag => ({ id: tag.id, label: tag.attributes.tag_name })))
      })
    }
  }, [token, chatServiceUrl, props.modalOpen])

  const assignKey = data => {
    data._reactKey = data._reactKey || jsPlumbUtil.uuid()
    return data._reactKey
  }

  const saveChanges = ({ label, goalType, tagID, setFields, integrationPlanId }) => {
    if (!base.toolkit) {
      return
    }
    const node = base.getNode()
    const new_data = { ...base.state, label: label, goalType: goalType, tagID: tagID, setFields: setFields, integrationPlanId: integrationPlanId }
    base.toolkit.updateNode(node, new_data)
    props.setModalOpen(false)
  }

  const type = base.state.goalType.charAt(0).toUpperCase() + base.state.goalType.slice(1)
  let label = base.state.label
  if (base.state.tagID) {
    const tags = tenantTags.filter(t => t.id === base.state.tagID)
    if (tags.length) {
      label = tags[0].label
    }
  }
  const body = type + ' Goal: '

  return (
    <>
      <div>
        <div
          className={`${classes.dragger} ${classes.backgroundAction}`}
        >
          <div className='node-header'>
            <div className={classes.iconDiv}><CheckIcon /></div>
            <div className={classes.cardTitle}>Conversation Goal</div>
          </div>
          <div className='node-body' style={{ minHeight: 18 }}>
            <div style={{ display: 'flex' }}>
              {body}
              <RenderTag tag={label} />
            </div>
            <Divider style={{ marginTop: 5 }} />
            <div>
              {base.state.ports.map((c, index) => {
                return (
                  <OptionPort
                    index={index}
                    data={c}
                    key={assignKey(c)}
                    parent={base}
                    kind='backgroundAction'
                    label='Next'
                  />
                )
              })}
            </div>
          </div>
          <jtk-target
            scope='default'
          />
        </div>
      </div>
      <GoalModal
        open={props.modalOpen}
        onHide={() => props.setModalOpen(false)}
        save={saveChanges}
        label={base.state.label}
        tenantTags={tenantTags}
        goalType={base.state.goalType}
        tagID={base.state.tagID}
        setFields={base.state.setFields}
        integrationPlanId={base.state.integrationPlanId}
        handleOpenNewTagModal={openCreateTagModal}
      />
      <ConversationTagsModal
        show={createTagModalOpen}
        state={modalState}
        name={props.type}
        onChange={setModalState}
        saveTag={handleCreateTag}
        onHide={() => setCreateTagModalOpen(false)}
      />
    </>
  )
}

export default GoalNode
