import { NPSResponse } from 'classes/npsResponse'
import { makeStyles } from '@material-ui/core/styles'
import { jsPlumbUtil } from 'jsplumbtoolkit'
import { chartMapping } from 'library/charts/Chart'
import { Card } from 'library/materialUI'
import { useEffect, useRef, useState } from 'react'
import { Select } from 'cf-components/material-wrappers/MaterialComponents'

const useStyles = makeStyles(theme => ({
  charts: {
    height: 350,
    display: 'grid',
    gridTemplateColumns: '2fr 5fr',
    gridGap: 20
  },
  chartCard: {
    padding: 20
  },
  chartTitle: {
    color: '#000000BF',
    font: 'normal normal 600 18px/27px Poppins',
    marginBottom: 10
  },
  dataCards: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    gridGap: 20,
    marginBottom: 20
  },
  dataCard: {
    padding: 10
  },
  dataTitle: {
    color: '#000000CC',
    font: 'normal normal normal 14px/18px Poppins',
    marginBottom: 15
  },
  dataValue: {
    color: '#000000',
    font: 'normal normal 600 30px/30px Poppins'
  }
}))

type ChartCardProps = {
  isLoading: boolean
  data: any
  type: keyof typeof chartMapping
  label?: string
  title: string
}
function ChartCard (props: ChartCardProps) {
  const classes = useStyles()
  const { isLoading, data, type, label } = props
  const chartRef = useRef<any>(null)
  const [id] = useState(jsPlumbUtil.uuid())

  useEffect(() => {
    if (!isLoading) {
      const chart = chartMapping[type]({ data, id, label })
      chartRef.current = chart
      return () => {
        if (chartRef.current) {
          chartRef.current.dispose()
        }
      }
    }
    // eslint-disable-next-line
  }, [id, isLoading, data])

  return (
    <Card className={classes.chartCard}>
      <div className={classes.chartTitle}>{props.title}</div>
      <div id={id} style={{ height: 'calc(100% - 20px)' }} />
    </Card>
  )
}

function NPSPieChart ({ days }: { days: number }) {
  const { data, isLoading } = NPSResponse.getPieChart(days)
  return (
    <ChartCard
      data={data.data}
      isLoading={isLoading}
      type='pieChart'
      label={data?.score?.toString()}
      title='NPS'
    />
  )
}

function NPSBarChart ({ days }: { days: number }) {
  const { data, isLoading } = NPSResponse.getBarChart(days)
  return (
    <ChartCard
      data={data.data}
      isLoading={isLoading}
      type='barChart'
      title='Responses by Score'
    />
  )
}

type NPSDataPointProps = {
  title: string
  value: string
}
function NPSDataPoint (props: NPSDataPointProps) {
  const classes = useStyles()
  return (
    <Card className={classes.dataCard}>
      <div className={classes.dataTitle}>
        {props.title}
      </div>
      <div className={classes.dataValue}>
        {props.value}
      </div>
    </Card>
  )
}

const dataCards = [
  { title: 'Total Shown', key: 'totalShown' },
  { title: 'Responses', key: 'totalResponses' },
  { title: 'Response Rate', key: 'responseRate' },
  { title: 'Qualitative Responses', key: 'qualitativeResponses' }
]

const dateOptions = [
  { value: 7, label: 'Last Week' },
  { value: 30, label: 'Last 30 days' },
  { value: 60, label: 'Last 60 days' },
  { value: 90, label: 'Last 90 days' },
  { value: 10000, label: 'All time' }
]

export function NPSOverview () {
  const classes = useStyles()
  const [days, setDays] = useState(90)
  const { data, isLoading } = NPSResponse.getSummary(days)

  return (
    <div>
      <div style={{ width: 300, marginBottom: 20 }}>
        <Select
          value={days}
          options={dateOptions}
          onChange={(value: number) => setDays(value)}
          disableClear
        />
      </div>
      <div className={classes.dataCards}>
        {dataCards.map((card, index) => {
          const value = isLoading ? '' : data.data[card.key]
          return (
            <NPSDataPoint
              value={value}
              title={card.title}
              key={index}
            />
          )
        })}
      </div>
      <div className={classes.charts}>
        <NPSPieChart
          days={days}
        />
        <NPSBarChart
          days={days}
        />
      </div>
    </div>
  )
}
