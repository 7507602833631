import { makeStyles } from '@material-ui/core/styles'

const salesloftCardStyles = makeStyles(theme => ({
    cardBody: {
      paddingTop: 5,
      marginLeft: 5,
      cursor: 'default'
    },
    cardTitle: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: 5
    },
    cadSubDiv: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 10
    },
    cadenceSubtitle: {
      color: '#985CE6',
      marginRight: 5
    },
    cadenceSelect: {
      marginBottom: 10
    },
    cadenceButtons: {
      display: 'flex',
      flexDirection: 'row-reverse'
    },
    actionIcon: {
      display: 'flex',
      justifyContent: 'center',
      height: 18,
      width: 24,
      cursor: 'pointer',
      marginRight: 5
    },
    actionCtn: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      '& .left': {
        display: 'flex',
        alignItems: 'center',
        paddingRight: 4,
        gap: '8px'
      },
      '& .right': {
        display: 'flex',
        alignItems: 'center'
      },
      copyIconContainer: {
        cursor: 'pointer'
      }
    },
    actionsDiv: {
      position: 'absolute',
      top: 8,
      right: 8,
      display: 'flex'
    },
    cadenceRow: {
      display: 'flex',
      alignItems: 'center'
    },
    cadenceItem: {
      marginLeft: 5,
      height: 35,
      borderBottom: '1px solid #C5C5C5',
      width: '100%',
      paddingBottom: 5
    },
    cadenceItemNoBorder: {
      marginLeft: 5,
      height: 35,
      width: '100%',
      paddingBottom: 5
    },
    cadenceItemUpperRow: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between'
    },
    personActivity: {
      maxHeight: 150,
      overflowY: 'scroll'
    },
    nameFields: {
      display: 'grid',
      gridTemplateColumns: '4fr 5fr',
      gridGap: 8,
      alignItems: 'center',
      marginTop: 12
    },
    field: {
      marginTop: 12
    },
    addPersonSelectors: {
      marginTop: 10,
      marginBottom: 10
    },
    iconInfoSpacing: {
      marginLeft: 3,
      marginRight: 3
    }
}))

export default salesloftCardStyles
