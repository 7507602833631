import { makeStyles } from '@material-ui/core/styles'
import { FactorGroup } from 'classes/scoreSettings'
import { Icon } from 'library/materialUI'
import { PopoverDiv } from 'pages/plays/playBuilder/nodes/PopoverDiv'
import { useState } from 'react'
import FactorGroupSelector from './GroupSelector'
import ScoringCriteriaPicker from './ScoringCriteriaPicker'
import { factorOperatorToString, factorToString, getIcon, selectorStructure } from './SelectorHelper'
import ImportanceSlider, { Importance } from './ImportanceSlider'
import { ModalSection } from 'library/Modal'

const useStyles = makeStyles(theme => ({
  oppTypeHeader: {
    fontSize: '20px',
    fontFamily: 'poppins',
    color: theme.palette.primary.main,
    fontWeight: 'bold'
  },
  innerText: {
    fontSize: '14px',
    fontFamily: 'poppins',
    color: '#00000080'
  },
  groupContainer: {
    backgroundColor: '#F7F7F7',
    display: 'flex',
    flexDirection: 'row',
    margin: '15px',
    borderRadius: '5px'
  },
  factorContainer: {
    backgroundColor: '#EDE5F8',
    maxHeight: '30px',
    margin: '5px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '5px',
    cursor: 'pointer',
    overflow: 'visible'
  },
  factorIcon: {
    margin: '5px',
    marginLeft: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  factorName: {
    fontSize: '12px',
    fontFamily: 'poppins',
    fontWeight: 'bold',
    margin: '2px'
  },
  factorValue: {
    fontSize: '12px',
    fontFamily: 'poppins',
    margin: '2px'
  },
  importanceText: {
    fontSize: '14px',
    fontFamily: 'poppins',
    color: '#00000080'
  }
}))

interface SingalsScoringStep4Props {
  factors: FactorGroup[]
  setFactors: (factors: FactorGroup[]) => void
}

export default function SignalsScoringStep4 ({ factors, setFactors }: SingalsScoringStep4Props): JSX.Element {
  const classes = useStyles()
  const [editOpen, setEditOpen] = useState(false)

  const handleRemoveFactorGroup = (index: number) => {
    const newFactors = [...factors]
    newFactors.splice(index, 1)
    setFactors(newFactors)
  }

  const handleRemoveFactor = (groupIndex: number, index: number) => {
    const newFactors = [...factors]
    newFactors[groupIndex].factors.splice(index, 1)
    if (newFactors[groupIndex].factors.length === 0) {
      handleRemoveFactorGroup(groupIndex)
    } else {
      setFactors(newFactors)
    }
  }

  const handleChangeImportance = (index: number, value: Importance) => {
    const newFactors = [...factors]
    newFactors[index].importance = value
    setFactors(newFactors)
  }

  return (
    <ModalSection>
      <div className={classes.oppTypeHeader}>
        Factors
      </div>
      <div className={classes.innerText} style={{ marginTop: '12px' }}>
        Which factors should positively affect an account's Signals score?
      </div>
      <div>
        {factors?.length > 0 && factors.map((factorGroup, index) => {
          const outerIndex = index
          return (
            <div key={index} className={classes.groupContainer}>
              <div style={{ marginRight: 'auto', marginLeft: '5px' }}>
                {factorGroup.factors.map((factor, index) => {
                  return (
                    <div key={index} style={{ display: 'flex', flexDirection: 'row', margin: '10px' }}>
                      <div className={classes.factorContainer}>
                        <PopoverDiv
                          doneButton
                          popoverContents={
                            <ScoringCriteriaPicker type={factor.name} factorGroupIndex={outerIndex} factorIndex={index} factors={factors} setFactors={setFactors} />
                          }
                          divContents={
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                              <div className={classes.factorIcon}>
                                <Icon icon={getIcon(factor.name)} size='sm' color='black' />
                              </div>
                              <div className={classes.factorName} style={{ margin: '5px' }}>
                                {factorToString(factor.name)}
                              </div>
                              <div className={classes.factorValue} style={{ marginRight: '5px' }}>
                                {factorOperatorToString(factor.op, factor.name)}
                              </div>
                              {factor.name !== 'icp_match' && factor.name !== 'open_opportunity' &&
                                <div className={classes.factorValue} style={{ marginRight: '2px' }}>
                                  {factor.value}
                                </div>}
                            </div>
                          }
                        />
                        <div onClick={() => handleRemoveFactor(outerIndex, index)} className={classes.factorValue} style={{ margin: '8px' }}>
                          <Icon icon='close' color='primary' size='md' />
                        </div>
                      </div>
                      <div className={classes.factorContainer}>
                        {index + 1 === factorGroup.factors.length ? (
                          <PopoverDiv
                            popoverContents={
                              <FactorGroupSelector handleSelect={() => setEditOpen(false)} selectorGroups={selectorStructure} factors={factors} setFactors={setFactors} addToFactor={outerIndex} />
                            }
                            divContents={
                              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '5px' }}>
                                <Icon icon='add' color='primary' size='sm' />
                              </div>
                            }
                          />
                        ) : (
                          <div className={classes.factorValue}>
                            and
                          </div>
                        )}
                      </div>
                    </div>
                  )
                })}
                <div style={{ marginLeft: '15px', marginRight: 'auto', marginBottom: '10px', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                  <div className={classes.importanceText}>
                    Importance
                  </div>
                  <ImportanceSlider index={index} importance={factorGroup.importance} onChange={handleChangeImportance} />
                </div>
              </div>
              <div onClick={() => handleRemoveFactorGroup(index)} style={{ width: '45px', margin: '10px', alignSelf: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Icon icon='outlinedCancel' color='primary' cursor='pointer' />
              </div>
            </div>
          )
        })}
      </div>
      <PopoverDiv
        popoverContents={
          <FactorGroupSelector handleSelect={() => setEditOpen(false)} selectorGroups={selectorStructure} factors={factors} setFactors={setFactors} />
        }
        divContents={
          <div onClick={() => setEditOpen(true)} className={classes.factorContainer} style={{ width: '120px' }}>
            <div style={{ fontSize: '13px', fontWeight: 'bold' }}>
              Add Factor
            </div>
            <div style={{ marginTop: '5px', marginLeft: '5px' }}>
              <Icon icon='add' color='#9933FF' />
            </div>
          </div>
        }
        open={editOpen}
        handleClose={() => setEditOpen(false)}
        handleOpen={() => setEditOpen(true)}
      />
    </ModalSection>
  )
}
