import SkeletonLoader, { mapping, SkeletonSize } from './SkeletonLoader'

interface SkeletonTableLoaderProps {
  rowCount: number
  types: (keyof typeof mapping)[]
  size?: SkeletonSize
  columns: string
  justifyCenter?: boolean
}

export default function SkeletonTableLoader ({ rowCount, types, size = 'md', columns, justifyCenter }: SkeletonTableLoaderProps) {
  const rowArray = Array.from(Array(rowCount).keys())
  return (
    <>
      {rowArray.map((index) => {
        return (
          <div
            key={index}
            style={{
              display: 'grid',
              margin: '10px 5px',
              padding: 10,
              gridTemplateColumns: columns,
              borderBottom: '1px solid #e7e7e7',
              justifyItems: justifyCenter ? 'center' : ''
            }}
          >
            {types.map((type, index) =>
              <SkeletonLoader key={`skeletonloader-${index}-${type}`} type={type} size={size} />
            )}
          </div>
        )
      })}
    </>
  )
}
