import { TableProps, TableProvider } from './TableProvider';
import { CardList } from './CardList'
import FilterBar from './FilterBar';

export function Table<Class> (props: TableProps<Class>): JSX.Element {
  return (
    <TableProvider {...props}>
      <div style={{ padding: '10px 20px' }}>
        {!props.tableOnly && <FilterBar />}
        <CardList />
      </div>
    </TableProvider>
  )
}
