import { useCallback, useEffect, useState } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Checkbox from '@material-ui/core/Checkbox'
import TreeView from '@material-ui/lab/TreeView'
import MuiTreeItem from '@material-ui/lab/TreeItem'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import { intersection } from 'lodash'
import { truncateString } from 'library/helpers'
import { ScrollBox } from './ScrollBox'
import { Button, Tooltip } from 'library/materialUI'

export interface TreeChild {
  label?: string
  value: string | number
  children?: TreeChild[]
}

export interface TreeMultiSelectStyle {
  fontSize?: number | string
  padding?: number | string
}

export interface TreeMultiSelectProps {
  items: TreeChild[]
  selected: string[]
  setSelected: (values: string[]) => void
  excluded?: string[]
  setExcluded?: (values: string[]) => void
  styles?: TreeMultiSelectStyle
  selectAll?: boolean
  expandAll?: boolean
  truncateLength?: number
}

export default function TreeMultiSelect ({
  selected, setSelected, ...props
}: TreeMultiSelectProps): JSX.Element {
  const classes = useTreeItemStyles(props.styles || {})
  const [expanded, setExpanded] = useState<string[]>([])
  const [allSelected, setAllSelected] = useState(false)
  const onNodeSelect = useCallback(({ toAdd = [], toDelete = [] }: {
    [k in 'toAdd' | 'toDelete']?: string[]
  }): void => {
    const nextSelected = [...selected].filter(item => !toDelete.includes(item)).concat(toAdd)
    setSelected([...new Set(nextSelected)])
  }, [setSelected, selected])

  const onExclude = useCallback(({ toAdd = [], toDelete = [] }: {
    [k in 'toAdd' | 'toDelete']?: string[]
  }): void => {
    if (props.setExcluded === undefined) return
    const nextExcluded = [...props.excluded].filter(item => !toDelete.includes(item)).concat(toAdd)
    props.setExcluded([...new Set(nextExcluded)])
    // eslint-disable-next-line
  }, [props.setExcluded, props.excluded])

  const onNodeSelectAll = useCallback((): void => {
    if (allSelected) {
      setSelected([])
      setAllSelected(false)
      return
    }
    const allItems = props.items.map(item => item.value)
    setSelected(allItems)
    setAllSelected(true)
  }, [setSelected, props.items, allSelected])

  const getAllExpandables = (items: TreeChild[]): string[] => {
    const expandables: string[] = []
    items.forEach(item => {
      if (item.children) {
        expandables.push(item.value.toString())
        expandables.push(...getAllExpandables(item.children))
      }
    })
    return expandables
  }

  useEffect(() => {
    if (props.expandAll) {
      setExpanded(getAllExpandables(props.items))
    } else {
      setExpanded([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.expandAll])

  return (
    <TreeView
      defaultCollapseIcon={<ExpandLessIcon />}
      defaultExpandIcon={<ExpandMoreIcon />}
      expanded={expanded}
      onNodeToggle={(event, nodes) => setExpanded(nodes)}
    >
      {props.selectAll && props.items.length > 0 &&
        <div>
          <TreeItem
            item={{
              label: 'SELECT ALL',
              value: 'select-all'
            }}
            onSelect={onNodeSelectAll}
            selected={selected}
            classes={classes}
            isSelected={allSelected}
            checkbox
          />
          <div style={{ width: '90%', height: 0, marginLeft: 'auto', marginRight: 'auto', border: '1px solid #0000000D' }} />
        </div>}
      <ScrollBox style={{ maxHeight: 250 }}>
        {props.items.map(item => (
          <div key={item.value}>
            <TreeItem
              key={item.value}
              item={item}
              selected={selected}
              excluded={props.excluded}
              classes={classes}
              onSelect={onNodeSelect}
              onExclude={onExclude}
              truncateLength={props.truncateLength}
              checkbox={props.selectAll || !props.excluded}
            />
          </div>
        )
        )}
      </ScrollBox>
    </TreeView>
  )
}

export const useTreeItemStyles = makeStyles<Theme, TreeMultiSelectStyle, 'root' | 'labelRoot' | 'whiteBg' | 'whiteBgHover' | 'iconContainer'>(theme => ({
  root: {
    padding: props => props.padding
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    height: '2em',
    paddingRight: '1em',
    fontSize: props => props.fontSize
  },
  whiteBg: {
    backgroundColor: '#FFFFFF!important'
  },
  whiteBgHover: {
    backgroundColor: '#FFFFFF!important',
    paddingLeft: 0,
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.04)!important'
    }
  },
  iconContainer: {
    color: theme.palette.primary.main
  }
}))

const getAllChildrenValues = (children: TreeChild[]): string[] => children.reduce((acc, cur) => {
  acc.push(cur.value)
  if (cur.children) {
    acc.push(...getAllChildrenValues(cur.children))
  }
  return acc
}, [] as string[])

function TreeItem ({ item, selected, onSelect, classes, truncateLength, ...props }: {
  item: TreeChild
  isSelected?: boolean
  isExcluded?: boolean
  selected: string[]
  excluded?: string[]
  onSelect: (v: { [k in 'toAdd' | 'toDelete']?: string[] }) => void
  onExclude?: (v: { [k in 'toAdd' | 'toDelete']?: string[] }) => void
  classes: ReturnType<typeof useTreeItemStyles>
  truncateLength?: number
  checkbox?: boolean
}): JSX.Element {
  // const hasChildren = useMemo(() => Boolean(item.children && item.children.length), [item.children])
  // const directChildrenValues = useMemo(() => item.children ? item.children.map(({ value }) => value) : [], [item.children])
  // const childrenValues = useMemo(() => item.children ? getAllChildrenValues(item.children) : [], [item.children])
  const hasChildren = Boolean(item.children && item.children.length)
  const directChildrenValues = item.children ? item.children.map(({ value }) => value) : []
  const childrenValues = item.children ? getAllChildrenValues(item.children) : []
  /**
   * Whether the option is selected or not
   *
   * A child value does not need to be in the selected values if their parent is.
   * So we are able to force selection status
   */
  // const isSelected = useMemo(() => props.isSelected || selected.includes(item.value), [props.isSelected, selected, item.value])
  // const hasSelectedChild = useMemo(() => !isSelected && hasChildren && selected.some(v => childrenValues.includes(v)), [isSelected, hasChildren, childrenValues, selected])
  const isSelected = (props.isSelected || selected.includes(item.value))
  const isExcluded = (props.isExcluded || props.excluded?.includes(item.value))
  const hasSelectedChild = (!isSelected && hasChildren && selected.some(v => childrenValues.includes(v)))
  const handleSelect = ({ toAdd = [], toDelete = [] }: {
    [k in 'toAdd' | 'toDelete']?: string[]
  } = {}): void => {
    if (isSelected) {
      if (hasChildren && toDelete.length) {
        /** The children values that are being unselected */
        const intersectingValues = intersection(directChildrenValues, toDelete)
        if (intersectingValues.length) {
          // Add all children not being unselected
          toAdd = toAdd.concat(directChildrenValues).filter(v => !intersectingValues.includes(v))
          // Select values that are children to be unselected
          toDelete = toDelete.filter(v => !intersectingValues.includes(v))
        }
      }
      toDelete = toDelete.concat(item.value)
    } else if (hasChildren && toAdd.length) {
      /** The children who are selected */
      const intersectingValues = intersection(selected.concat(toAdd), directChildrenValues)
      if (intersectingValues.length === directChildrenValues.length) {
        // Remove the current children and add the parent value
        toAdd = selected.concat(toAdd).filter(v => !childrenValues.includes(v)).concat(item.value)
        // Unselect all the children
        toDelete = toDelete.concat(childrenValues)
      }
    }
    onSelect({ toAdd, toDelete })
  }

  const handleIncludeExclude = (action: 'include' | 'exclude', value: string | number) => {
    if (action === 'include') {
      onSelect({ toAdd: [value] })
      if (props.onExclude) props.onExclude({ toDelete: [value] })
    } else {
      if (props.onExclude) props.onExclude({ toAdd: [value] })
      onSelect({ toDelete: [value] })
    }
  }

  const labelContent = hasChildren ? (
    <div
      className={classes.labelRoot}
      onClick={() => {
        handleSelect(isSelected ? {
          toDelete: [item.value]
        } : {
          toAdd: [item.value],
          toDelete: childrenValues
        })
      }}
    >
      <Checkbox
        color='primary'
        indeterminate={hasSelectedChild}
        checked={isSelected || hasSelectedChild}
      />
      {item.label || item.value}
    </div>
  ) : (
    <div
      onClick={() => {
        if (isSelected) {
          handleSelect({ toDelete: [item.value] })
        } else if (props.checkbox) {
          handleSelect({ toAdd: [item.value] })
        } else {
          handleIncludeExclude('include', item.value)
        }
      }}
    >
      <div
        className={classes.labelRoot}
      >
        {props.checkbox && (
          <Checkbox
            color='primary'
            checked={isSelected}
          />
        )}
        {truncateString((item.label || item.value.toString()), truncateLength || 21, true)}
      </div>
      {!props.checkbox && (
        <div>
          <Button
            variant='text'
            onClick={(event) => {
              event.stopPropagation()
              handleIncludeExclude('include', item.value)
            }}
            style={{
              textTransform: 'capitalize',
              fontSize: '0.8rem',
              opacity: isSelected ? 1 : (isExcluded ? 0.5 : 0.75)
            }}
          >
            Include
          </Button>
          <span style={{ opacity: 0.75 }}>|</span>
          <Button
            variant='text'
            onClick={(event) => {
              event.stopPropagation()
              handleIncludeExclude('exclude', item.value)
            }}
            style={{
              textTransform: 'capitalize',
              fontSize: '0.8rem',
              opacity: isExcluded ? 1 : (isSelected ? 0.5 : 0.75)
            }}
          >
            Exclude
          </Button>
        </div>
      )}
    </div>
  )

  return (
    <MuiTreeItem
      key={item.value}
      nodeId={String(item.value)}
      label={labelContent}
      classes={{
        root: classes.root,
        content: classes.whiteBg,
        selected: classes.whiteBg,
        label: classes.whiteBgHover,
        iconContainer: classes.iconContainer
      }}
    >
      {item.children?.map((c, index) => (
        String(c.label).length >= (truncateLength || 21) ? (
          <Tooltip key={index} title={c.label || ''}>
            <div>
              <TreeItem
                key={c.value}
                item={c}
                onSelect={handleSelect}
                selected={selected}
                isSelected={isSelected}
                classes={classes}
                truncateLength={truncateLength}
                checkbox={props.checkbox}
                excluded={props.excluded}
                onExclude={props.onExclude}
              />
            </div>
          </Tooltip>) : (
            <TreeItem
              key={c.value}
              item={c}
              onSelect={handleSelect}
              selected={selected}
              isSelected={isSelected}
              classes={classes}
              truncateLength={truncateLength}
              checkbox={props.checkbox}
              excluded={props.excluded}
              onExclude={props.onExclude}
            />
        )
      ))}
    </MuiTreeItem>
  )
}

export const mappingReducer = (children: TreeChild[]): Record<string, string> => children.reduce((acc, cur) => {
  acc[cur.value] = cur.label || cur.value
  if (cur.children) {
    return { ...acc, ...mappingReducer(cur.children) }
  }
  return acc
}, {} as Record<string, string>)
