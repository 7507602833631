/* eslint-disable @typescript-eslint/naming-convention */
import EditContactForm from './EditContactForm'
import { Formik } from 'formik'
import * as Yup from 'yup'
import CustomField from 'classes/customFields'
import { Modal } from 'library/Modal'

interface EditContactModalProps {
  person: any
  customFields: CustomField[]
  savePerson: (values: any) => void
  onHide: () => void
  open: boolean
}

function EditContactModal (props: EditContactModalProps) {
  // const phoneRegEx = /(^[+][0-9]{1,4}[-\s.]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$)|(^[+][0-9]{1,4}$)/

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Please enter a valid email address')
      .required('Email address is required'),
    // phone: Yup.string()
    //   .matches(phoneRegEx, 'Phone number is invalid'),
    // mobile_phone: Yup.string()
    //   .matches(phoneRegEx, 'Phone number is invalid'),
    // other_phone: Yup.string()
    //   .matches(phoneRegEx, 'Phone number is invalid'),
    other_email: Yup.string()
      .email('Please enter a valid email address')
  })

  let startingValues = {}

  if (props.customFields.length === 1 && !props.customFields[0]) {
    return <></>
  }

  if (props.person == null) {
    startingValues = {
      honorific: '--None--',
      first_name: '',
      last_name: '',
      phone: '',
      mobile_phone: '',
      email: '',
      title: '',
      description: '',
      other_email: '',
      other_phone: '',
      company_name: '',
      company_website: '',
      company_industry: '',
      company_employee_count: '',
      company_revenue: '',
      custom_fields: {},
      city: '',
      state: '',
      country: '',
      bio: '',
      role: '',
      seniority: '',
      linkedin_handle: '',
      company_sector: '',
      company_phone: '',
      company_description: '',
      company_location: '',
      company_timezone: '',
      company_twitter_handle: '',
      company_tech: [],
      company_linkedin_handle: '',
      avatar_url: '',
      company_avatar_url: '',
      facebook_handle: '',
      twitter_handle: '',
      instagram_handle: ''
    }
    for (const field of props.customFields) {
      startingValues.custom_fields[field.id] = ''
    }
  } else {
    startingValues = {
      honorific: props.person.honorific === '' ? '--None--' : props.person.honorific,
      first_name: props.person.first_name,
      last_name: props.person.last_name,
      phone: props.person.phone,
      mobile_phone: props.person.mobile_phone,
      email: props.person.email,
      title: props.person.title,
      description: props.person.description,
      other_email: props.person.other_email,
      other_phone: props.person.other_phone,
      company_name: props.person.company_name,
      company_website: props.person.company_website,
      company_industry: props.person.company_industry,
      company_employee_count: props.person.company_employee_count,
      company_revenue: props.person.company_revenue,
      custom_fields: {},
      city: props.person.city,
      state: props.person.state,
      country: props.person.country,
      bio: props.person.bio,
      role: props.person.role,
      seniority: props.person.seniority,
      linkedin_handle: props.person.linkedin_handle,
      company_sector: props.person.company_sector,
      company_phone: props.person.company_phone,
      company_description: props.person.company_description,
      company_location: props.person.company_location,
      company_timezone: props.person.company_timezone,
      company_twitter_handle: props.person.company_twitter_handle,
      company_tech: props.person.company_tech || [],
      company_linkedin_handle: props.person.company_linkedin_handle,
      avatar_url: props.person.avatar_url,
      company_avatar_url: props.person.company_avatar_url,
      facebook_handle: props.person.facebook_handle,
      twitter_handle: props.person.twitter_handle,
      instagram_handle: props.person.instagram_handle
    }
    for (const f of props.customFields) {
      if (f.id in props.person.custom_fields) {
        startingValues.custom_fields[f.id] = props.person.custom_fields[f.id]
      } else {
        startingValues.custom_fields[f.id] = ''
      }
    }
  }

  const title = props.person == null ? 'Create Contact' : 'Edit Contact'
  return (
    <Formik
      initialValues={startingValues}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm }) => {
        props.savePerson(values)
        if (props.person == null) {
          resetForm()
        }
      }}
    >{({ submitForm, resetForm, isValid }) =>
    (
      <Modal
        title={title}
        onHide={() => {
          if (props.person == null) {
            resetForm()
          }
          props.onHide()
        }}
        open={props.open}
        handleSave={submitForm}
        size='md'
        saveIcon='save'
        saveDisabled={!isValid}
      >
        <EditContactForm
          customFields={props.customFields}
        />
      </Modal>
    )}
    </Formik>
  )
}

export default EditContactModal
