import { makeStyles } from '@material-ui/core/styles'
import { TextBox } from 'cf-components/material-wrappers/MaterialComponents'
import { RadioButtons } from 'library/materialUI'

const useStyles = makeStyles(theme => ({
  popupContainer: {
    width: '313px'
  },
  innerContainer: {
    margin: '20px'
  }
}))

interface IsComparatorProps {
  value: string
  setValue: (value: string) => void
  operator: string
  setOperator: (value: string) => void
}

export default function IsComparator ({ value, setValue, operator, setOperator }: IsComparatorProps): JSX.Element {
  const classes = useStyles()
  const isComparisons = [
    { value: 'eq', label: 'is' },
    { value: 'neq', label: 'is not' }
  ]

  return (
    <div>
      <div className={classes.innerContainer}>
        <RadioButtons
          buttons={isComparisons}
          handleChange={(value: string) => setOperator(value)}
          value={operator}
        />
      </div>
      <div style={{ margin: '10px' }}>
        <TextBox
          value={value?.toString()}
          onChange={(value: string) => setValue(value)}
        />
      </div>
    </div>
  )
}
