/* eslint-disable @typescript-eslint/naming-convention */
import salesloftCardStyles from './SalesloftCardStyles'
import { Icon, IconType } from 'library/materialUI/Icon'
import Typography from '@material-ui/core/Typography'
import { Tooltip } from 'library/materialUI'
import { Accordion } from 'cf-components/material-wrappers/Accordion'

const salesloftIconMapping: Record<string, IconType> = {
  call: 'salesloftCall',
  phone: 'salesloftCall',
  meeting: 'salesloftMeeting',
  meeting_held: 'salesloftMeeting',
  meeting_canceled: 'salesloftMeetingCancel',
  task: 'salesloftTask',
  note: 'salesloftNote',
  email: 'salesloftEmail',
  requested_email: 'salesloftEmailSent',
  sent_email: 'salesloftEmailSent',
  received_email: 'salesloftEmailReceived',
  email_reply: 'salesloftEmailReply',
  added_to_cadence: 'salesloftCadence',
  message_conversation: 'salesloftText',
  success: 'salesloftSuccess',
  completed_action: 'salesloftCadence',
  other: 'salesloftTask',
  dnc_event: 'salesloftDoNotContact'
}

function SalesloftActivity (activity: any): JSX.Element {
  const classes = salesloftCardStyles()
  const title = activity.name ? activity.name : activity.type.charAt(0).toUpperCase() + activity.type.substring(1).replaceAll('_', ' ')
  const date = activity.due_on
  const description = activity.cadence_name
  const type = activity.type
  const details = activity.details || 'No step details'
  let icon = salesloftIconMapping[type]
  if (icon === undefined) {
    icon = 'salesloftCadence'
  }
  const counts = activity.action_counts
  if (type === 'email' || type === 'phone' || type === 'note' || type === 'received_email' || type === 'other' || type === 'meeting') {
    return (
      <div style={{ borderBottom: '1px solid #C5C5C5' }}>
        <Accordion
          style={{ boxShadow: 'none' }}
          headerStyle={{ padding: 0 }}
          header={
            <>
              <div style={{ width: 32 }}>
                <Icon icon={icon} specificSize={32} />
              </div>
              <div className={classes.cadenceItemNoBorder}>
                <div className={classes.cadenceItemUpperRow}>
                  <Typography variant='h5'>
                    {title.length > 11 ? (
                      <Tooltip title={title} enterDelay={500}>
                        <div>
                          {title.substring(0, 11) + '...'}
                        </div>
                      </Tooltip>
                    ) : title}
                  </Typography>
                  <Typography variant='subtitle1'>
                    {date}
                  </Typography>
                </div>
                <Typography variant='subtitle1'>
                  {description.length > 24 ? (
                    <div style={{ display: 'flex' }}>
                      <Tooltip title={description} enterDelay={500}>
                        <div>
                          {description.substring(0, 24) + '...'}
                        </div>
                      </Tooltip>
                    </div>
                  ) : description}
                </Typography>
              </div>
            </>
          } contents={
            <Typography variant='subtitle1'>
              {details}
            </Typography>
          }
        />
      </div>
    )
  } else if (type === 'call') {
    return (
      <div style={{ borderBottom: '1px solid #C5C5C5' }}>
        <Accordion
          style={{ boxShadow: 'none' }}
          headerStyle={{ padding: 0 }}
          header={
            <>
              <div style={{ width: 32 }}>
                <Icon icon={icon} specificSize={32} />
              </div>
              <div className={classes.cadenceItemNoBorder}>
                <div className={classes.cadenceItemUpperRow}>
                  <Typography variant='h5'>
                    {title.length > 11 ? (
                      <Tooltip title={title} enterDelay={500}>
                        <div>
                          {title.substring(0, 11) + '...'}
                        </div>
                      </Tooltip>
                    ) : title}
                  </Typography>
                  <Typography variant='subtitle1'>
                    {date}
                  </Typography>
                </div>
                <Typography variant='subtitle1'>
                  {description.length > 24 ? (
                    <div style={{ display: 'flex' }}>
                      <Tooltip title={description} enterDelay={500}>
                        <div>
                          {description.substring(0, 24) + '...'}
                        </div>
                      </Tooltip>
                    </div>
                  ) : description}
                </Typography>
              </div>
            </>
          } contents={
            <Typography variant='subtitle1'>
              <div>
                Disposition: {activity.disposition}
              </div>
              <div>
                Sentiment: {activity.sentiment}
              </div>
              <div>
                {activity.details}
              </div>
            </Typography>
          }
        />
      </div>
    )
  } else if (type === 'sent_email') {
    return (
      <div className={classes.cadenceRow}>
        <div style={{ width: 32 }}>
          <Icon icon={icon} specificSize={32} />
        </div>
        <div className={classes.cadenceItem}>
          <div className={classes.cadenceItemUpperRow}>
            <Typography variant='h5'>
              {title.length > 11 ? (
                <Tooltip title={title} enterDelay={500}>
                  <div>
                    {title.substring(0, 11) + '...'}
                  </div>
                </Tooltip>
              ) : title}
            </Typography>
            <Typography variant='subtitle1'>
              {date}
            </Typography>
          </div>
          <div style={{ display: 'flex' }}>
            <Tooltip title='Views' placement='top'>
              <div>
                <Icon icon='salesloftViews' />
              </div>
            </Tooltip>
            <div className={classes.iconInfoSpacing}>{counts.views}</div>
            <Tooltip title='Clicks' placement='top'>
              <div>
                <Icon icon='salesloftClicks' />
              </div>
            </Tooltip>
            <div className={classes.iconInfoSpacing}>{counts.clicks}</div>
            <Tooltip title='Replies' placement='top'>
              <div>
                <Icon icon='salesloftReplies' />
              </div>
            </Tooltip>
            <div className={classes.iconInfoSpacing}>{counts.replies}</div>
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className={classes.cadenceRow}>
      <div style={{ width: 32 }}>
        <Icon icon={icon} specificSize={32} />
      </div>
      <div className={classes.cadenceItem}>
        <div className={classes.cadenceItemUpperRow}>
          <Typography variant='h5'>
            {title.length > 12 ? (
              <Tooltip title={title} enterDelay={500}>
                <div>
                  {title.substring(0, 12) + '...'}
                </div>
              </Tooltip>
            ) : title}
          </Typography>
          <Typography variant='subtitle1'>
            {date}
          </Typography>
        </div>
        <Typography variant='subtitle1'>
          {description.length > 24 ? (
            <div style={{ display: 'flex' }}>
              <Tooltip title={description} enterDelay={500}>
                <div>
                  {description.substring(0, 24) + '...'}
                </div>
              </Tooltip>
            </div>
          ) : description}
        </Typography>
      </div>
    </div>
  )
}

function SalesloftActivityBody (props: any): JSX.Element {
  return (
    <div>
      {props.activities.map((activity: any, index: number) => {
        return (
          <div key={index} style={{ marginBottom: 5 }}>
            <SalesloftActivity {...activity} />
          </div>
        )
      })}
    </div>
  )
}

export default SalesloftActivityBody
