import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import SaveIcon from '@material-ui/icons/Save'
import Modal from 'components/Modal'
// import StyledSwitch from 'components/StyledSwitch'

const useStyles = makeStyles(theme => ({
  menuBar: {
    height: 37,
    width: '100%',
    borderBottom: '1px solid rgba(0,0,0,0.2)',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  button: {
    marginRight: 10
  }
}))

function SaveModal (props) {
  const handleSave = props.save
  const warningText = props.warningText
  return (
    <Modal
      open={props.open}
      onHide={props.onHide}
      saveButtonText='Save Anyway'
      onSubmit={handleSave}
      showBack
      backText='Cancel'
      title='Warning'
      onBack={props.onHide}
    >
      <p>
        {warningText}
      </p>
      <p>
        Are you sure you want to continue?
      </p>
    </Modal>
  )
}

function BotEditTab (props) {
  const classes = useStyles()
  const [modalOpen, setModalOpen] = useState(false)
  const [warningText, setWarningText] = useState('You currently have an empty filter group in your audience criteria. This means that the bot will fire for all visitors on all pages of your site.')

  function saveBot () {
    if (props.values) {
      if (props.values.triggers) {
        const emptyFilters = props.values.triggers.filter(t => t.filters.length === 0)
        if (emptyFilters.length > 0) {
          setModalOpen(true)
          setWarningText('You currently have an empty filter group in your audience criteria. This means that the bot will fire for all visitors on all pages of your site.')
        } else if (props.funnelLinkError) {
          setModalOpen(true)
          setWarningText('You currently have two bots with the same FunnelLink, saving your changes will remove the FunnelLink from your other bot.')
        } else {
          props.save()
        }
      }
    } else {
      props.save()
    }
  }

  const disabled = props.disabled || Boolean(props.activeTest)
  // const handlePublish = props.publishBot

  return (
    <>
      <div className={classes.menuBar}>
        <Button
          startIcon={<SaveIcon />}
          color='primary'
          className={classes.button}
          onClick={saveBot}
          disabled={disabled}
        >
          Save
        </Button>
        {/* <StyledSwitch
          checked={props.enabled}
          toggle={handlePublish}
        /> */}
      </div>
      <div style={{ padding: 20, overflowY: 'auto', height: 'calc(100% - 80px)' }}>
        {props.children}
      </div>
      <SaveModal
        onHide={() => setModalOpen(false)}
        open={modalOpen}
        warningText={warningText}
        save={() => {
          setModalOpen(false)
          props.save()
        }}
      />
    </>
  )
}

export default BotEditTab
