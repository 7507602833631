import React, { useRef, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import FlowSurface from '../../bot/flow-builder/surface/FlowSurface'
import { getFlow } from 'api/bots'
import SelectAppearance from '../test_steps/SelectAppearance'

const useStyles = makeStyles(theme => ({
  botEditor: {
    height: '100%'
  },
  criteriaSelector: {
    padding: 30
  }
}))

const SelectFlow = props => {
  const classes = useStyles()
  const toolkitInstanceRef = useRef(null)
  const flowRef = props.flowRef
  const saveRef = props.saveRef
  const state = props.state
  const [oldFlow, setOldFlow] = useState()
  const [flow, setFlow] = useState()
  const surfaceRef = useRef(null)
  const chatServiceUrl = props.chatServiceUrl

  // Appearance handle submit
  const handleAppearanceChange = ({ attr, value }) => {
    const new_flow = flow
    new_flow[attr] = value

    props.handleChange({ attr: 'new_flow', value: new_flow })
  }

  useEffect(() => {
    const loadRefs = (flowObj) => {
      if (state.attributes.test_category === 'flow') {
        flowRef.current = flowObj
        setTimeout(() => {
          toolkitInstanceRef.current.load(flowRef.current.flow)
        }, 50)
      }
    }

    const initialFlow = state.attributes.old_flow_id

    if (state.attributes.new_flow) {
      const flowObj = state.attributes.new_flow
      setFlow(flowObj)
      loadRefs(flowObj)
      getFlow({ flowID: initialFlow, chatServiceUrl })
        .then(response => {
          const oldFlow = response.data.attributes
          setOldFlow(oldFlow)
        })
    } else {
      getFlow({ flowID: initialFlow, chatServiceUrl })
        .then(response => {
          const flowObj = response.data.attributes
          setFlow(flowObj)
          setOldFlow(flowObj)
          loadRefs(flowObj)
        })
    }
  }, [chatServiceUrl, flowRef, state.attributes.new_flow, state.attributes.old_flow_id, state.attributes.test_category])

  if (state.attributes.test_category === 'flow') {
    return (
      <>
        <div className={classes.botEditor}>
          <FlowSurface
            save={props.save}
            saveRef={saveRef}
            surfaceRef={surfaceRef}
            toolkitInstanceRef={toolkitInstanceRef}
            chatServiceUrl={chatServiceUrl}
          />
        </div>
      </>
    )
  }

  if (state.attributes.test_category === 'appearance') {
    return (
      <SelectAppearance promptSizes={props.promptSizes} handleChange={handleAppearanceChange} state={props.state} oldFlow={oldFlow} />
    )
  }

  return <></>
}

export default SelectFlow
