import { makeStyles } from '@material-ui/core/styles';
import { Fields } from 'cf-components/table/CardContents';
import { defaultSearchParams, SearchParams } from 'classes/queryHelpers';
import { FilterComponentProps } from './FilterButton';
import { getStartingDate } from 'helpers/dates';
import { useEffect, useState } from 'react';
import DateRangeMenu, { DateObj } from 'library/filter/dates/DateRangeMenu';
import dateFormat from 'dateformat';
import { format, parseISO } from 'date-fns';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  linkStyle: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
}))

export const getInitialState = (fields: Fields, initialState?: SearchParams) => {
  if (initialState) {
    return initialState
  } else {
    const fieldNames = fields.map(f => f.type !== 'dynamic' ? f.name as string : undefined)
    const sortColumn = fieldNames[0]
    const state = { ...defaultSearchParams }
    state.sortColumn = sortColumn
    state.filters = [[]]
    state.pageNumber = 1
    return state
  }
}

export const toggleSortOrder = (order?: 'asc' | 'desc') => {
  if (order === 'asc') {
    return 'desc'
  } else {
    return 'asc'
  }
}

export function DateAddedFilterValue (date: Date) {
  return dateFormat(date, 'mmm dd, yyyy')
}

export function DateAddedFilter (props: FilterComponentProps) {
  const initialDates = getStartingDate('object') as DateObj
  const startingValue = props.initialValues ? { startDate: parseISO(props.initialValues[0]), endDate: parseISO(props.initialValues[1]) } : initialDates
  const [selected, setSelected] = useState<DateObj>(startingValue)
  const [apply, setApply] = useState(true)
  const handleSetSelected = (selectedObject: { startDate: Date, endDate: Date }) => {
    setSelected(selectedObject)
    if (selectedObject.startDate !== selectedObject.endDate) {
      setApply(true)
    }
  }

  useEffect(() => {
    if (apply) {
      props.filterRef.current = selected.endDate && selected.startDate ? { field: 'created_timestamp', operator: 'between', value: [format(selected.startDate, 'yyyy-MM-dd'), format(selected.endDate, 'yyyy-MM-dd')] } : null
      setApply(false)
    }
    // eslint-disable-next-line
  }, [apply])

  return (
    <DateRangeMenu
      dateRange={selected}
      setDateRange={(selected: DateObj) => handleSetSelected(selected)}
    />
  )
}

export function DisplayLink ({ field, link, children }: { field?: string | number, link: string, children: any }): JSX.Element {
  const classes = useStyles()
  if (field) {
    return (
      <Link className={classes.linkStyle} to={link} target='_blank'>
        {children}
      </Link>
    )
  }
  return <div>{children}</div>
}
