
const descriptionText = 'Use this bot template to create a bot which asks visitors a question and then routes them to a specific team for live chat based upon their answer.'

const Description = (): JSX.Element => {
  return (
    <div>
      <div>
        {descriptionText}
      </div>
      <ul>
        <li>Welcomes the visitor to your site and/or asks a question</li>
        <li>Displays answers as buttons for the visitor to click</li>
        <li>Routes an agent in for live chat based upon the visitor's selection</li>
        <ul>
          <li><a href='https://help.getsignals.ai/article/hy96c0do9e-bot-builder-and-play-runner-routing' target='_blank' rel='noreferrer'>How routing works</a></li>
        </ul>
      </ul>
    </div>
  )
}

const bestPracticeText = 'When routing to teams make sure that team and user availability settings are properly configured for all agents.'

const BestPractices = () => {
  return (
    <div>
      <div>
        {bestPracticeText}
      </div>
      <ul>
        <li><a href='#/user_management/teams' target='_blank'>Team Management</a></li>
        <li>Update your personal availability and out of office settings from the user pop-out in the left hand corner of the screen</li>
      </ul>
    </div>
  )
}

export const conciergeBotDocs = {
  helpCenterLink: '',
  bestPractices: BestPractices,
  description: Description,
  videoLink: ''
}
