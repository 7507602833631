import { getCategories, getURLS } from 'api/high-intent'
import { Select } from 'cf-components/material-wrappers/MaterialComponents'
import { TextBox } from 'library/materialUI'
import { useCallback, useEffect, useState } from 'react'
import { EmailQueryStringSettings } from './EmailQueryStringSettings'

interface MQLContactCreationRulesProps {
  settings: EmailQueryStringSettings
  dispatch: any
  setCanSave: (value: boolean) => void
}

export default function MQLContactCreationRules ({ settings, dispatch, setCanSave }: MQLContactCreationRulesProps) {
  const [categories, setCategories] = useState([])
  const [highIntentList, setHighIntentList] = useState([])
  const [loadingHighIntentPages, setLoadingHighIntentPages] = useState(false)

  const loadCategories = useCallback(() => {
    getCategories()
      .then(response => {
        setCategories(response.data)
      })
  }, [])

  const eventList = [
    { value: 'page_views', label: 'Pages Viewed' },
    { value: 'time_on_site', label: 'Time on site' },
    { value: 'high_intent_page', label: 'High-intent page visit' }
  ]

  useEffect(() => {
    loadCategories()
  // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (categories) {
      setLoadingHighIntentPages(true)
      const promises = categories.map(category =>
        getURLS(parseInt(category.id)).then(response => {
          return response.data.map(item => ({ value: item.id || '', label: item.attributes.page_url || '' }))
        })
      )

      Promise.all(promises).then(results => {
        const result = results.flat()
        setHighIntentList(result)
        setLoadingHighIntentPages(false)
      })
    }
  }, [categories])

  return (
    <div style={{ display: 'flex', marginTop: '20px', alignItems: 'center' }}>
      <div style={{ marginRight: '20px' }}>
        Create contact when
      </div>
      <div style={{ marginRight: '20px', width: '225px' }}>
        <Select
          options={eventList}
          value={settings.creationRuleType}
          onChange={(value: string) => {
            dispatch({ type: 'setCreationRuleType', value })
            setCanSave(true)
          }}
          width='100%'
        />
      </div>
      {settings.creationRuleType === 'high_intent_page' && (
        <>
          <div style={{ marginRight: '20px' }}>
            on
          </div>
          <div style={{ width: '250px', marginRight: '20px' }}>
            <Select
              options={highIntentList}
              value={loadingHighIntentPages ? [] : settings.highIntentPageIds}
              onChange={(value: number[]) => {
                dispatch({ type: 'setHighIntentPageIds', value })
                setCanSave(true)
              }}
              width='100%'
              multiple
            />
          </div>
        </>
      )}
      <div style={{ marginRight: '20px' }}>
        {settings.creationRuleType === 'high_intent_page' ? 'is longer than' : 'is greater than'}
      </div>
      <div style={{ width: '100px', marginRight: '20px' }}>
        <TextBox
          type='number'
          value={settings.threshold}
          onChange={(value: number) => {
            dispatch({ type: 'setThreshold', value })
            setCanSave(true)
          }}
        />
      </div>
      <div>
        {settings.creationRuleType === 'page_views' ? 'pages' : 'seconds'}
      </div>
    </div>
  )
}
