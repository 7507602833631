import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'

export function CreatePieChart ({ data, id, label }: any) {
  am4core.addLicense('CH213476655')
  am4core.useTheme(am4themes_animated)

  const chart = am4core.create(id, am4charts.PieChart);

  chart.data = data

  const pieSeries = chart.series.push(new am4charts.PieSeries());
  pieSeries.dataFields.value = 'value';
  pieSeries.dataFields.category = 'category';
  pieSeries.slices.template.stroke = am4core.color('#fff');
  pieSeries.slices.template.strokeOpacity = 1;

  if (label) {
    chart.innerRadius = 80
    const innerLabel = chart.seriesContainer.createChild(am4core.Label);
    innerLabel.text = label;
    innerLabel.horizontalCenter = 'middle';
    innerLabel.verticalCenter = 'middle';
    innerLabel.fontSize = 45;
  }

  // This creates initial animation
  pieSeries.hiddenState.properties.opacity = 1;
  pieSeries.hiddenState.properties.endAngle = -90;
  pieSeries.hiddenState.properties.startAngle = -90;
  pieSeries.labels.template.disabled = true

  chart.hiddenState.properties.radius = am4core.percent(0);
}
