import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Card, CardContent, Typography, Divider, Button } from '@material-ui/core'
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined'
import { format } from 'date-fns'
import SaveIcon from '@material-ui/icons/Save'

const useStyles = makeStyles(theme => ({
  selectorCard: {
    width: 550,
    borderRadius: 10,
    margin: 50
  },
  container: {
    display: 'flex',
    justifyContent: 'center'
  },
  cardContentArea: {
    height: '100%',
    backgroundColor: 'rgba(193, 166, 237, 0.1)',
    borderRadius: 10,
    marginBottom: 20,
    padding: 30,
    paddingTop: 0
  },
  header: {
    backgroundColor: theme.palette.primary.main
  },
  subtitle1: {
    fontSize: 20,
    fontWeight: 600,
    color: 'white',
    padding: 20
  },
  subtitle2: {
    fontSize: 15,
    fontWeight: 600,
    marginBottom: 10
  },
  bodyText: {
    marginBottom: 25,
    fontSize: 16
  },
  select: {
    borderRadius: 40
  },
  bold: {
    fontSize: 18,
    fontWeight: 600,
    marginTop: 0,
    marginBottom: 0
  },
  regular: {
    fontSize: 15,
    color: '#959595',
    marginTop: 8
  },
  row: {
    paddingTop: 20,
    display: 'flex',
    justifyContent: 'space-between'
  },
  description: {
    width: 'calc(100% - 130px)'
  },
  editButton: {
    height: 'fit-content'
  },
  finishButton: {
    marginTop: 20
  }
}))

const TestSumamry = props => {
  const classes = useStyles()
  const state = props.state
  const durationCriteria = props.durationCriteria
  const successMetrics = props.successMetrics
  let botDict = {}
  props.bots.map(bot => (botDict = { ...botDict, [bot.id]: { ...bot } }))

  const parseDuration = (criteria, start, end) => {
    let text = ''

    if (!(start instanceof Date)) {
      start = format(new Date(Date.parse(start)), 'PPP p')
    } else {
      start = format(start, 'PPP p')
    }

    const durationCriteriaText = durationCriteria.filter(c => c.value === criteria)[0].name
    text = text + durationCriteriaText
    text = text + ' set to begin on ' + start
    if (criteria === 'time_based') {
      if (!(end instanceof Date)) {
        end = format(new Date(Date.parse(end)), 'PPP p')
      } else {
        end = format(end, 'PPP p')
      }

      text = text + ' and set to end on ' + end
    }

    return text
  }

  return (
    <>
      <div className={classes.container}>
        <Card className={classes.selectorCard} raised>
          <div className={classes.header}>
            <Typography variant='subtitle1' className={classes.subtitle1}>
              Test Summary
            </Typography>
          </div>
          <CardContent
            className={classes.cardContentArea}
          >
            <div className={classes.row}>
              <div className={classes.description}>
                <p className={classes.bold}>Bot: </p>
                <p className={classes.regular}>{botDict[state.attributes.object_id].attributes.name}</p>
              </div>
              <div>
                <Button
                  variant='outlined'
                  className={classes.editButton}
                  color='primary'
                  onClick={() => props.jumpToStep(0)}
                  startIcon={<CreateOutlinedIcon />}
                > Edit
                </Button>
              </div>
            </div>
            <Divider />
            <div className={classes.row}>
              <div className={classes.description}>
                <p className={classes.bold}>Description: </p>
                <p className={classes.regular}>{state.attributes.description}</p>
              </div>
              <div>
                <Button
                  variant='outlined'
                  color='primary'
                  className={classes.editButton}
                  startIcon={<CreateOutlinedIcon />}
                  onClick={() => props.jumpToStep(6)}
                > Edit
                </Button>
              </div>
            </div>
            <Divider />
            <div className={classes.row}>
              <div className={classes.description}>
                <p className={classes.bold}>Success Metric: </p>
                <p className={classes.regular}>{successMetrics.filter(s => s.value === state.attributes.success_metric)[0].name}</p>
              </div>
              <div>
                <Button
                  variant='outlined'
                  color='primary'
                  className={classes.editButton}
                  startIcon={<CreateOutlinedIcon />}
                  onClick={() => props.jumpToStep(2)}
                > Edit
                </Button>
              </div>
            </div>
            <Divider />
            <div className={classes.row}>
              <div className={classes.description}>
                <p className={classes.bold}>Duration: </p>
                <p className={classes.regular}>{parseDuration(state.attributes.duration_criteria, state.attributes.start_time, state.attributes.end_time)}</p>
              </div>
              <Button
                variant='outlined'
                color='primary'
                className={classes.editButton}
                startIcon={<CreateOutlinedIcon />}
                onClick={() => props.jumpToStep(3)}
              > Edit
              </Button>
            </div>
            <Divider />
            <div className={classes.row}>
              <div className={classes.description}>
                <p className={classes.bold}>Auto-Promote: </p>
                <p className={classes.regular}>{state.attributes.auto_promote ? 'Activated' : 'Manually Promote'}</p>
              </div>
              <Button
                variant='outlined'
                color='primary'
                onClick={() => props.jumpToStep(5)}
                className={classes.editButton}
                startIcon={<CreateOutlinedIcon />}
              > Edit
              </Button>
            </div>
            <Divider />
            <div>
              <Button
                onClick={props.handleSave}
                className={classes.finishButton}
                variant='outlined'
                color='primary'
                fullWidth
                startIcon={<SaveIcon />}
                disabled={!state.attributes.object_id}
              >
                Finish
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>
    </>
  )
}

export default TestSumamry
