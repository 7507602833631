import { makeStyles } from '@material-ui/core/styles';
import { Switch, TextBox } from 'library/materialUI';
import { useContext } from 'react';
import { NPSContext } from './NPSContext';
import { NPSSettingsHeader } from './NPSSettingsComponents';
import { get } from 'lodash'

const useStyles = makeStyles(theme => ({
  trigger: {
    display: 'grid',
    gridTemplateColumns: '600px 80px',
    gridGap: 20,
    alignItems: 'center',
    color: '#000000BF',
    font: 'normal normal normal 14px/25px Poppins',
    padding: '5px 10px'
  }
}))

type NPSTriggerProps = {
  title: string
  path: string
}
function NPSTrigger (props: NPSTriggerProps) {
  const classes = useStyles()
  const { stateHandlers } = useContext(NPSContext)
  const { title, path } = props
  const [state, dispatch] = stateHandlers

  return (
    <div className={classes.trigger}>
      <div>
        {title}
      </div>
      <div style={{ width: 80 }}>
        <TextBox
          value={get(state, path)}
          onChange={(value: number) => dispatch({ path, value })}
          type='number'
        />
      </div>
    </div>
  )
}

export function NPSTriggers () {
  const { stateHandlers } = useContext(NPSContext)
  const [state, dispatch] = stateHandlers
  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: 50 }}>
        <div>
          <Switch
            label='Show NPS Prompt'
            checked={state.enabled}
            handleChange={(value: boolean) => dispatch({ path: 'enabled', value })}
          />
        </div>
      </div>
      <div>
        <NPSSettingsHeader
          title='Frequency'
          subtitle='Set the feedback cycle interval for users who respond to the NPS survey'
        />
        <div>
          <NPSTrigger
            title='After the user submits the NPS survey, after how many days should they see it again?'
            path='triggers.frequency'
          />
          <NPSTrigger
            title='If a user clicks ask me later, after how many days should they see it again?'
            path='triggers.askAgainDelay'
          />
        </div>
      </div>
      <div style={{ marginTop: 20 }}>
        <NPSSettingsHeader
          title='Behavior'
          subtitle='Set the trigger behavior when a user is eligible to see the NPS survey'
        />
        <div>
          <NPSTrigger
            title='How many pages should a user navigate in a session before seeing the NPS survey?'
            path='triggers.pageCount'
          />
          <NPSTrigger
            title='What is the time delay on a page (in seconds) before the NPS survey pops up?'
            path='triggers.promptDelay'
          />
          <NPSTrigger
            title='How many sessions should a user have in the product before seeing the survey?'
            path='triggers.sessionCount'
          />
        </div>
      </div>
    </div>
  )
}
