import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { useCallback, useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { INTEGRATIONS, addIntegration, getAPIKeys } from '../../../api/integrations'
import CustomizedSnackbar from '../../../components/CustomizedSnackbar'
import ZapierLogo from '../../../img/zapier-integration-logo.png'
import { ShareContext } from 'share-context'

const useStyles = makeStyles({
  headerImage: {
    height: 50,
    marginBottom: 50
  },
  wrapIcon: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 15
  },
  textField: {
    cursor: 'text',
    width: '100%',
    marginBottom: 25
  },
  container: {
    margin: 65
  },
  bodyText: {
    marginBottom: 25,
    fontSize: 16
  },
  subtitle1: {
    fontSize: 18,
    fontWeight: 600,
    marginTop: 25,
    marginBottom: 15
  },
  subtitle2: {
    fontSize: 15,
    fontWeight: 600,
    marginBottom: 10
  },
  radioButton: {
    fontWeight: 600
  },
  checkboxGroup: {
    marginLeft: 50,
    marginBottom: 25
  },
  checkbox: {
    display: 'block',
    marginBottom: -10
  },
  actionButton: {
    marginTop: 10,
    paddingLeft: 50,
    paddingRight: 50
  },
  crumb: {
    fontSize: '.8em'
  },
  typography: {
    fontSize: '1.05em'
  },
  breadcrumbs: {
    position: 'absolute',
    top: 5
  },
  crumbWrapper: {
    padding: 10
  }
})

const ZapierIntegrationPage = (props) => {
  const classes = useStyles()
  const [apiKey, setApiKey] = useState(null)
  const { flags } = useContext(ShareContext)
  const [showBeta, setShowBeta] = useState(false)

  useEffect(() => {
    if (flags['beta:zapier']) {
      setShowBeta(true)
    }
  }, [flags])

  const [snackState, setSnackState] = useState({
    open: false,
    variant: 'success',
    message: 'Your changes have been saved'
  })

  const getZapierIntegrationSettings = useCallback(() => {
    getAPIKeys('Zapier')
      .then((response) => {
        if (response.data.length > 0) {
          setApiKey(response.data[0])
        } else {
          setApiKey(false)
        }
      })
  }, [])

  useEffect(() => {
    if (apiKey === null) {
      getZapierIntegrationSettings()
    }
  }, [apiKey, getZapierIntegrationSettings])

  if (apiKey === null) {
    return (
      <></>
    )
  }

  return (
    <>
      <Breadcrumbs aria-label='breadcrumb' classes={{ li: classes.crumb, ol: classes.crumbWrapper }}>
        <Link style={{ color: '#7A7A7A' }} to='/settings/integrations'>
          Integrations Page
        </Link>
        <Typography color='textPrimary' classes={{ root: classes.typography }}>Zapier Integration Page</Typography>
      </Breadcrumbs>
      <div className={classes.container}>
        <img src={ZapierLogo} className={classes.headerImage} alt='Zapier Logo' />
        <Typography variant='body2' className={classes.bodyText}>
          Integrate your Signals account with Zapier to automate connections to hundreds of other systems with easy-to-use graphical configuration!
        </Typography>
        <Divider variant='middle' />

        {apiKey ? (
          <h3>You are already connected to Zapier!</h3>
        ) : (
          <>
            <Typography variant='subtitle1' className={classes.subtitle1}>
              Connect Zapier to Signals
            </Typography>
            <Typography variant='body2' className={classes.bodyText}>
              To start using Zapier with your Signals account, click the button below to connect your accounts. Doing so authorizes Signals and Zapier to connect, allowing transfer of chats, contact data, and other relavant information.
            </Typography>
            <Button
              variant='contained'
              color='primary'
              onClick={() => {
                addIntegration(INTEGRATIONS.Zapier).then(() => {
                  window.location = 'https://zapier.com/apps/chatfunnels/integrations'
                })
              }}
            >Connect with Zapier
            </Button>
          </>
        )}
        {showBeta && (
          <>
            <div>
              <br />
              <br />
              <Button
                variant='contained'
                color='secondary'
                onClick={() => {
                  addIntegration(INTEGRATIONS.Zapier).then(() => {
                    window.location = 'https://zapier.com/developer/public-invite/99896/5fedda66b7148001395b7e9da6720770/'
                  })
                }}
              >Use Beta Zapier Integration
              </Button>
            </div>
          </>
        )}
      </div>
      <CustomizedSnackbar state={snackState} handler={setSnackState} />
    </>
  )
}

export default ZapierIntegrationPage
