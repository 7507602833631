import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  TextField,
  Typography,
  Card
} from '@material-ui/core'
import { getTrialOffer, saveTrialOffer } from '../../api/admin_only/chargify_offers'
import AppPage from '../../cf-components/AppPage'

const useStyles = makeStyles(theme => ({
  container: {
    padding: 20,
    borderRadius: 10
  },
  subtitle: {
    marginBottom: 10,
    fontSize: 14
  },
  sectionTitle: {
    fontSize: 16,
    fontWeight: 600,
    marginBottom: 5
  },
  card: {
    padding: 15,
    margin: 20
  }

}))

const defaultOffer = {
  offer_id: '',
  product_id: ''
}

const TrialSettingsPage = props => {
  const classes = useStyles()
  const [trialOffer, setTrialOffer] = useState(defaultOffer)
  const [disabled, setDisabled] = useState(true)

  const handleChange = ({ attr, value }) => {
    setTrialOffer({
      ...trialOffer,
      attributes: {
        ...trialOffer.attributes,
        [attr]: value
      }
    })
    setDisabled(false)
  }

  const save = () => {
    saveTrialOffer(trialOffer)
      .then(response => {
        setTrialOffer(response.data)
        setDisabled(true)
      })
  }

  const saveProps = {
    disabled: disabled,
    save: save,
    saveText: 'Save Offer',
    validPaths: 'trial'
  }

  useEffect(() => {
    getTrialOffer()
      .then(
        response => {
          setTrialOffer(response.data)
        }
      )
  }, [])

  return (
    <>
      <AppPage
        title='Trial Offer'
        saveProps={saveProps}
        padding={0}
      >
        <div className={classes.container} style={{ display: 'flex', justifyContent: 'center' }}>
          <Card className={classes.card}>
            <div style={{ display: 'flex' }}>
              <div style={{ marginRight: 50, width: 300 }}>
                <Typography variant='subtitle1' className={classes.sectionTitle}>
                  Chargify's Offer Id:
                </Typography>
                <Typography className={classes.subtitle}>
                  Insert here the chargify offer id of the offer you want to use for the trial.
                </Typography>
                <TextField
                  variant='outlined'
                  fullWidth margin='dense'
                  value={trialOffer?.attributes?.offer_id}
                  onChange={(e) => handleChange({ attr: 'offer_id', value: e.target.value })}
                />
              </div>
              <div style={{ width: 300 }}>
                <Typography variant='subtitle1' className={classes.sectionTitle}>
                  Chargify's Product Id:
                </Typography>
                <Typography className={classes.subtitle}>
                  Insert here the chargify product id tied to the offer on the left.
                </Typography>
                <TextField
                  variant='outlined'
                  fullWidth margin='dense'
                  value={trialOffer?.attributes?.product_id}
                  onChange={(e) => handleChange({ attr: 'product_id', value: e.target.value })}
                />
              </div>
            </div>
          </Card>
        </div>
      </AppPage>
    </>
  )
}
export default TrialSettingsPage
