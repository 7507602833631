import { makeStyles } from '@material-ui/core/styles'
import Loader from 'library/loading/Loader'

const useStyles = makeStyles(theme => ({
  loadingScreenContainer: {
    position: 'relative',
    zIndex: 10,
    width: '100%',
    height: '100%'
  },
  loadingScreen: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  pageCtn: {
    height: 'inherit',
    minHeight: 'inherit',
    width: 'max-content',
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    paddingBottom: '20%'
  }
}))

interface LoadingScreenProps {
  loading: boolean
}

function LoadingScreen ({ loading }: LoadingScreenProps) {
  const classes = useStyles()

  if (loading) {
    return (
      <div className={classes.loadingScreenContainer}>
        <div className={classes.loadingScreen}>
          <Loader type='spinner' size='xlg' />
        </div>
      </div>
    )
  } else {
    return (
      <></>
    )
  }
}

export function PageLoader () {
  const classes = useStyles()
    return (
      <div className={classes.pageCtn}>
        <Loader type='spinner' size='xlg' />
      </div>
    )
  }

export default LoadingScreen
