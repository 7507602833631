import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themes_material from '@amcharts/amcharts4/themes/material'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'

function createPieChart ({ data, id, setVisible }) {
  am4core.addLicense('CH213476655')
  am4core.useTheme(am4themes_material)
  am4core.useTheme(am4themes_animated)
  const chart = am4core.create(id, am4charts.PieChart)
  // chart.hiddenState.properties.opacity = 0;

  chart.data = data

  const series = chart.series.push(new am4charts.PieSeries())
  series.dataFields.value = 'value'
  series.dataFields.category = 'name'
  series.slices.template.cornerRadius = 6

  series.hiddenState.properties.endAngle = -90

  // chart.legend = new am4charts.Legend()

  chart.events.on('ready', () => {
    setVisible('visible')
  })

  chart.exporting.menu = new am4core.ExportMenu()

  return chart
}

export { createPieChart }
