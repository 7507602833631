import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import { states, provinces, asia, africa, americas, europe, oceania } from './GeoLocations'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import CancelIcon from '@material-ui/icons/Cancel'

const useStyles = makeStyles(theme => ({
  field: {
    backgroundColor: '#FFFFFF'
  },
  menuItem: {
    paddingTop: 3,
    paddingBottom: 3,
    paddingLeft: 25,
    fontSize: '.95em'
  },
  menuHeaderItem: {
    cursor: 'default',
    '&:hover': {
      backgroundColor: '#FFF'
    },
    paddingTop: 2,
    paddingBottom: 2
  },
  menuClearItem: {
    cursor: 'default',
    '&:hover': {
      backgroundColor: '#CCC'
    },
    padding: 0,
    backgroundColor: '#CCC'
  },
  menuHeader: {
    borderBottom: '1px solid #BBB',
    width: '100%',
    fontWeight: 600,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}))

const ITEM_HEIGHT = 80
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 7.5 + ITEM_PADDING_TOP,
      width: 250
    }
  },
  MenuListProps: {
    style: {
      paddingTop: 0
    }
  },
  getContentAnchorEl: null,
  variant: 'menu',
  anchorOrigin: {
    vertical: 'center',
    horizontal: 'left'
  },
  transformOrigin: {
    vertical: 'center',
    horizontal: 'left'
  }
}

function ProvinceSelect ({
  field,
  form: { setFieldValue, setFieldTouched },
  ...props
}) {
  const classes = useStyles()

  return (
    <FormControl
      variant='outlined'
      margin='dense'
    >
      <Select
        {...field}
        inputProps={{
          name: props.name,
          id: props.id
        }}
        multiple
        onBlur={() => setFieldTouched(field.name)}
        onChange={e => setFieldValue(field.name, e.target.value)}
        className={classes.field}
        displayEmpty
        MenuProps={MenuProps}
      >
        <MenuItem value='' disabled>Select province</MenuItem>
        {provinces.map((province, index) => (
          <MenuItem key={index} value={province} classes={{ root: classes.menuItem }}>{province}</MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

function StateSelect ({
  field,
  form: { setFieldValue, setFieldTouched },
  ...props
}) {
  const classes = useStyles()

  return (
    <FormControl
      variant='outlined'
      margin='dense'
    >
      <Select
        {...field}
        inputProps={{
          name: props.name,
          id: props.id
        }}
        multiple
        onBlur={() => setFieldTouched(field.name)}
        onChange={e => setFieldValue(field.name, e.target.value)}
        className={classes.field}
        displayEmpty
        MenuProps={MenuProps}
      >
        <MenuItem value='' disabled>Select state</MenuItem>
        {states.map((state, index) => (
          <MenuItem key={index} value={state} classes={{ root: classes.menuItem }}>{state}</MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

function CountrySelect ({
  field,
  form: { setFieldValue, setFieldTouched },
  ...props
}) {
  const classes = useStyles()

  function handleHeaderClick (e) {
    if (!e) e = window.event
    e.cancelBubble = true
    if (e.stopPropagation) e.stopPropagation()
    e.preventDefault()
  }

  return (
    <FormControl
      variant='outlined'
      margin='dense'
    >
      <Select
        {...field}
        inputProps={{
          name: props.name,
          id: props.id
        }}
        multiple
        onBlur={() => setFieldTouched(field.name)}
        onChange={e => setFieldValue(field.name, e.target.value)}
        className={classes.field}
        displayEmpty
        MenuProps={MenuProps}
      >
        {field.value.length
          ? (
            <MenuItem classes={{ root: classes.menuClearItem }}>
              <div
                className={classes.menuHeader}
                onClick={(e) => {
                  handleHeaderClick(e)
                  const value = []
                  setFieldValue(field.name, value)
                }}
              >
                <Button color='primary' startIcon={<CancelIcon />}>Clear all ({field.value.length})</Button>
              </div>
            </MenuItem>)
          : <div />}
        <MenuItem classes={{ root: classes.menuHeaderItem }}>
          <div
            className={classes.menuHeader}
            onClick={(e) => {
              handleHeaderClick(e)
              const value = field.value.concat(americas)
              setFieldValue(field.name, value)
            }}
          >
            <div>Americas</div>
            <Button color='primary' startIcon={<AddIcon />}>Add all {americas.length}</Button>
          </div>
        </MenuItem>
        {americas.map((country, index) => (
          <MenuItem key={index} value={country} classes={{ root: classes.menuItem }}>{country}</MenuItem>
        ))}
        <MenuItem classes={{ root: classes.menuHeaderItem }}>
          <div
            className={classes.menuHeader}
            onClick={(e) => {
              handleHeaderClick(e)
              const value = field.value.concat(asia)
              setFieldValue(field.name, value)
            }}
          >
            <div>Asia</div>
            <Button color='primary' startIcon={<AddIcon />}>Add all {asia.length}</Button>
          </div>
        </MenuItem>
        {asia.map((country, index) => (
          <MenuItem key={index} value={country} classes={{ root: classes.menuItem }}>{country}</MenuItem>
        ))}
        <MenuItem classes={{ root: classes.menuHeaderItem }}>
          <div
            className={classes.menuHeader}
            onClick={(e) => {
              handleHeaderClick(e)
              const value = field.value.concat(europe)
              setFieldValue(field.name, value)
            }}
          >
            <div>Europe</div>
            <Button color='primary' startIcon={<AddIcon />}>Add all {europe.length}</Button>
          </div>
        </MenuItem>
        {europe.map((country, index) => (
          <MenuItem key={index} value={country} classes={{ root: classes.menuItem }}>{country}</MenuItem>
        ))}
        <MenuItem classes={{ root: classes.menuHeaderItem }}>
          <div
            className={classes.menuHeader}
            onClick={(e) => {
              handleHeaderClick(e)
              const value = field.value.concat(africa)
              setFieldValue(field.name, value)
            }}
          >
            <div>Africa</div>
            <Button color='primary' startIcon={<AddIcon />}>Add all {africa.length}</Button>
          </div>
        </MenuItem>
        {africa.map((country, index) => (
          <MenuItem key={index} value={country} classes={{ root: classes.menuItem }}>{country}</MenuItem>
        ))}
        <MenuItem classes={{ root: classes.menuHeaderItem }}>
          <div
            className={classes.menuHeader}
            onClick={(e) => {
              handleHeaderClick(e)
              const value = field.value.concat(oceania)
              setFieldValue(field.name, value)
            }}
          >
            <div>Oceania</div>
            <Button color='primary' startIcon={<AddIcon />}>Add all {oceania.length}</Button>
          </div>
        </MenuItem>
        {oceania.map((country, index) => (
          <MenuItem key={index} value={country} classes={{ root: classes.menuItem }}>{country}</MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export {
  StateSelect,
  ProvinceSelect,
  CountrySelect
}
