import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import SearchIcon from '@material-ui/icons/Search'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import { Grow, Tooltip } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  icon: {
    marginRight: 19,
    fill: 'rgba(0, 0, 0, 0.54)',
    cursor: 'pointer',
    '&:hover': {
      fill: theme.palette.primary.main
    }
  },
  inputRoot: {
    borderRadius: 30
  },
  inputMargin: {
    paddingTop: 8,
    paddingBottom: 8
  }
}))

export default function CollapsibleSearchBar (props) {
  const classes = useStyles()
  const [expanded, setExpanded] = useState(props.expanded || false)

  const search = props.search
  const setSearch = props.setSearch

  function handleClickAway (e) {
    if (!search) {
      setExpanded(false)
    }
  }

  function handleChange (event) {
    setSearch(event.target.value)
  }

  if (expanded) {
    return (
      <ClickAwayListener onClickAway={handleClickAway}>
        <Grow in={expanded}>
          <TextField
            value={search}
            onChange={handleChange}
            autoFocus
            margin='dense'
            variant='outlined'
            InputProps={{
              endAdornment: <InputAdornment position='end'><SearchIcon style={{ fill: '#8e5ae2' }} /></InputAdornment>,
              classes: {
                root: classes.inputRoot,
                inputMarginDense: classes.inputMargin
              }
            }}
            style={{ margin: 0, marginRight: 5, width: props.searchBarWidth || 200 }}
          />
        </Grow>
      </ClickAwayListener>
    )
  }

  return (
    <Tooltip title='Search'>
      <SearchIcon className={classes.icon} onClick={() => setExpanded(true)} />
    </Tooltip>
  )
}
