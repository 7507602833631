const headers = {
  'Content-Type': 'application/vnd.api+json'
}

const getHubSpotUsers = () => {
  return fetch('/api/hubspot/users', {
    method: 'GET',
    headers: headers
  }).then((response) => response.json())
}

const syncContactToHubSpot = ({ personID, personIDs }) => {
  const body = {
    data: {
      attributes: {},
      type: 'hubspot_sync',
      id: personID
    }
  }
  if (personID) {
    body.data.attributes.person_id = personID
  } else if (personIDs) {
    body.data.attributes.person_ids = personIDs
  }
  return fetch('/api/integrations/hubspot/sync_contact', {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(body)
  }).then(response => response.status)
}

const getHubSpotContactLists = () => {
  return fetch('/api/hubspot/contact_lists', {
    method: 'GET',
    headers: headers
  }).then((response) => response.json())
}

const getHubSpotId = () => {
  return fetch('/api/hubspot/hubspot_id', {
    method: 'GET',
    headers: headers
  }).then((response) => response.json())
}

const getHubSpotProperties = () => {
  return fetch('/api/hubspot/properties', {
    method: 'GET',
    headers: headers
  }).then((response) => response.json())
}

export { getHubSpotProperties, getHubSpotContactLists, getHubSpotUsers, syncContactToHubSpot, getHubSpotId }
