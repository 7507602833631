import React, { useEffect, useRef, useState } from 'react'
import { getFAQTimeline } from 'api/reports'
import Loader from 'library/loading/Loader'
import { createLineChart } from './CreateLineChart'
import FullScreenView from '../../reports/FullScreenView'
import EmptyState from 'components/EmptyState'
import { convertDateRange } from 'cf-components/FilterBar/FilterBar'

function LineChart (props) {
  const chartRef = useRef(null)

  const [visibility, setVisibility] = useState('hidden')
  const [fullScreenVisibility, setFullScreenVisibility] = useState('hidden')
  const [chartData, setChartData] = useState(null)
  const handleClose = props.exitFullScreen

  useEffect(() => {
    setVisibility('hidden')

    if (props.dateValues) {
      if (chartRef.current) {
        chartRef.current.dispose()
      }
      getFAQTimeline({ attributes: convertDateRange(props.dateValues) }).then(response => {
        const id = 'linechart'
        const data = response.data.attributes.chart_data
        setChartData(data)
        if (data.length > 0 && data[0].total) {
          const chart = createLineChart({ data, id, setVisible: setVisibility })
          chartRef.current = chart
        } else {
          setTimeout(() => setVisibility('visible'), 500)
        }
      })
    }
  }, [props.dateValues])

  return (
    <>
      <div
        style={{
          display: visibility === 'visible' ? 'none' : 'block',
          ...props.loaderStyles
        }}
      >
        <Loader type='spinner' />
      </div>
      <div style={{ height: 560, visibility: visibility }}>
        {chartData && chartData.length > 0 && chartData[0].total
          ? <div id='linechart' style={{ width: '100%', height: '100%' }} />
          : <EmptyState message='No FAQs in Selected Period' />}
      </div>
      <FullScreenView
        createChart={() => createLineChart({ data: chartData, id: 'fullScreenChart', setVisible: setFullScreenVisibility })}
        open={props.fullScreen}
        onClose={handleClose}
        filter={props.filter}
        visibility={fullScreenVisibility}
        setVisibility={setFullScreenVisibility}
        title='FAQs Over Time'
      />
    </>
  )
}

export default LineChart
