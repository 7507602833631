import { ReactNode, useContext } from 'react'
import { ApiContext } from 'api-context'
import { FlowBuilderContext } from '../FlowBuilderProvider'
import { makeStyles } from '@material-ui/core/styles'
import HistoryIcon from '@material-ui/icons/History'
import DeleteIcon from '@material-ui/icons/Delete'
import SettingsIcon from '@material-ui/icons/Settings'
import { Emitter, EVENT_TYPE } from '../helpers/EventEmitter'
import { SaveMenu } from './SaveMenu'
import { SaveButton, CircularButton } from 'cf-components/CircularButtons'
import { validateNodes } from './saveHelpers'
import { FlowOption } from 'library/flowBuilder/FlowBuilder'
import { VersionMenu } from './VersionMenu'

const useStyles = makeStyles(theme => ({
  menu: {
    position: 'absolute',
    top: 10,
    right: 15,
    zIndex: 10,
    display: 'grid',
    gridGap: 15
  },
  optionButton: {
    display: 'flex',
    padding: 5,
    borderRadius: 50,
    marginRight: 20,
    cursor: 'pointer'
  },
  controlIcon: {
    fill: theme.palette.primary.main
  }
}))

type button = {
  icon: any
  label: string
  showLabel?: boolean
  action: (event: any) => void
  disabled?: boolean
}

type ButtonProps = {
  button: button
}

export function OptionButton (props: ButtonProps): JSX.Element {
  return (
    <CircularButton
      {...props.button}
    />
  )
}

type OptionsMenuProps = {
  options: FlowOption[]
  canSave: boolean
  history: any
}

type ButtonWrapperProps = {
  options: FlowOption[]
  type: FlowOption
  children: ReactNode
}

function ButtonWrapper ({ options, type, children }: ButtonWrapperProps) {
  if (options.includes(type)) {
    return (
      <>
        {children}
      </>
    )
  } else {
    return (<></>)
  }
}

export function OptionsMenu ({ options, canSave, history }: OptionsMenuProps): JSX.Element {
  const { setDeleteObject } = useContext(ApiContext)
  const { object, toolkit, save, edit } = useContext(FlowBuilderContext)
  const classes = useStyles()
  let flows = { dict: {}, list: [] }
  if (object.getFlows) {
    const { data } = object.getFlows()
    flows = data
  }

  const handleValidation = (target: any, publish: boolean): boolean => {
    const { invalid, unconnected } = validateNodes(toolkit)
    if (invalid.length || unconnected.length) {
      Emitter.emit(EVENT_TYPE.INVALID_NODES, {
        toolkitID: toolkit.id,
        target: target,
        invalid,
        unconnected,
        publish,
        zIndex: 1300
      })
      return false
    }
    return true
  }

  const handleVersions = (): void => {
    Emitter.emit(EVENT_TYPE.VERSION_HISTORY, {
      toolkitID: toolkit.id,
      draftID: object.draftID,
      publishedID: object.publishedID,
      versions: flows.list,
      menuOpen: true,
      sequenceID: object.id
    })
  }

  return (
    <div className={classes.menu} style={{ gridTemplateColumns: `repeat(${options.length}, 1fr)` }}>
      <ButtonWrapper options={options} type='delete'>
        <OptionButton button={{ icon: DeleteIcon, label: 'Archive', action: () => setDeleteObject(object), showLabel: true }} />
      </ButtonWrapper>
      <ButtonWrapper options={options} type='edit'>
        <OptionButton button={{ icon: SettingsIcon, label: 'Edit Details', action: edit, showLabel: true }} />
      </ButtonWrapper>
      <ButtonWrapper options={options} type='version'>
        <OptionButton button={{ icon: HistoryIcon, label: 'Version History', action: () => handleVersions(), showLabel: true }} />
      </ButtonWrapper>
      <ButtonWrapper options={options} type='save'>
        <div id='play-save-button'>
          <SaveButton
            save={() => save(false)}
            title='Save'
            disabled={!canSave}
            handleValidation={(target: any) => handleValidation(target, false)}
            history={history}
          />
        </div>
      </ButtonWrapper>
      <ButtonWrapper options={options} type='publish'>
        <SaveButton
          variant='publish'
          save={() => save(true)}
          disabled={!canSave}
          handleValidation={(event: any) => handleValidation(event, true)}
          history={history}
        />
      </ButtonWrapper>
      <SaveMenu />
      <VersionMenu />
    </div>
  )
}
