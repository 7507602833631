
const descriptionText = 'Use this bot template when your goal is to help visitors book product demo appointments. The flow for this bot includes the following:'

const Description = (): JSX.Element => {
  return (
    <div>
      <div>
        {descriptionText}
      </div>
      <ul>
        <li>Greeting and question with the following responses:</li>
        <ul>
          <li>Definitely</li>
          <ul>
            <li>Asks qualifying questions to determine fit</li>
            <li>If the visitor is a good fit for your company, the bot sends the visitor a calendar invite</li>
          </ul>
          <li>Nah</li>
          <ul>
            <li>Asks a different question to prompt the user to:</li>
            <ul>
              <li>book a meeting (reroute to the qualifying questions)</li>
              <li>chat with an agent (if available)</li>
              <li>leave a message (asks for the visitor's email)</li>
            </ul>
          </ul>
        </ul>
      </ul>
    </div>
  )
}

const bestPracticeText = 'Use this node when you suspect that holding a meeting with visitors is the best way to interact, but where visitors may be hesitant to follow through on the first prompt. Be sure to customize the text on the questions to match your desired tone. Also, be sure to verify that your agents have configured their calendars in Signals, so all agents can book meetings.'

const BestPractices = () => {
  return (
    <div>
      <div>
        {bestPracticeText}
      </div>
      <ul>
        <li><a href='#/settings/my_settings/my_calendar' target='_blank'>Calendar Settings</a></li>
      </ul>
    </div>
  )
}

export const secondNetBotDocs = {
  helpCenterLink: '',
  bestPractices: BestPractices,
  description: Description,
  videoLink: ''
}
