import { styled } from '@material-ui/core/styles'
import { Icon, Tooltip } from 'library/materialUI'
import {
  FormControlLabel,
  FormControl,
  Radio as MuiRadio,
  RadioProps as MuiRadioProps,
  RadioGroup
} from '@material-ui/core'
export { RadioGroup } from '@material-ui/core'

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  width: 'auto'
}))

const StyledMuiRadio = styled(MuiRadio)(({ theme }) => ({
  padding: 4,
  color: 'primary',
  '&.Mui-checked': {
    color: '#9933FF'
  }
}))

export type RadioProps = MuiRadioProps & {
  label: string
  value: unknown
  checked?: boolean
  handleChange?: (value: string) => void
  tooltipText?: string
}

export const Radio = ({ value, label, checked, handleChange, tooltipText, disabled = false }: RadioProps): JSX.Element => (
  <div style={{ display: 'flex', flexDirection: 'row' }}>
    <div>
      <StyledFormControlLabel
        control={
          <StyledMuiRadio
            checked={checked}
            onChange={(e) => {
              if (handleChange) {
                handleChange(e.target.value)
              }
            }}
          />
        }
        value={value}
        label={label}
        disabled={disabled}
      />
    </div>
    {tooltipText && (
      <Tooltip title={tooltipText} placement='right-end'>
        <div style={{ marginTop: '6px' }}>
          <Icon icon='info' size='sm' color='#0000004D' />
        </div>
      </Tooltip>
    )}
  </div>
)

export type RadioButtonsProps = {
  value: unknown
  handleChange: (value: any) => void
  buttons: RadioProps[]
  disabled?: boolean
}

export function RadioButtons ({ value, buttons, handleChange, disabled }: RadioButtonsProps): JSX.Element {
  return (
    <FormControl component='fieldset'>
      <RadioGroup
        value={value}
        onChange={(e) => handleChange(e.target.value)}
      >
        {buttons.map((button, index) => (
          <Radio
            key={index}
            {...button}
            disabled={disabled}
          />
        ))}
      </RadioGroup>
    </FormControl>
  )
}
