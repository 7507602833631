import { NodeSettings } from './NodeSettings'

type SectionComponentProps = {
  links: string[]
  sectionMapping?: Record<string, any>
  type?: string
}

export function SectionComponent ({ links, sectionMapping, type }: SectionComponentProps) {
  const section = links[1]
  const SectionComponent: any = sectionMapping ? sectionMapping[section] : NodeSettings
  const remainingLinks = links.slice(1)
  return (
    <SectionComponent
      links={remainingLinks}
      type={type}
    />
  )
}
