import { makeStyles } from '@material-ui/core/styles'
import { IntegrationObjectMappings } from './integration-helpers'
import SalesForceButton from './SalesForceButton'
import HubSpotButton from './HubSpotButton'
import EloquaButton from './EloquaButton'

const useStyles = makeStyles(theme => ({
  integrationCtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: '4px',
    width: 71,
    '&.left': { justifyContent: 'flex-start' }
  }
}))

export default function ContactSyncButtons (props: {
  contact: Record<string, any>
  integrationObjectMappings: IntegrationObjectMappings
  refreshContactIntegrationMappings: (contactID: number) => void
  /**
   * This component has a set width. Because of that, you can choose whether you want
   * the sync buttons to float horizontally
   */
  side?: 'left'|'right'
}): JSX.Element {
  const classes = useStyles()

  return (
    <div className={classes.integrationCtn + ' ' + props.side}>
      <HubSpotButton
        contact={props.contact}
        integrationObjectMappings={props.integrationObjectMappings}
        refreshContactIntegrationMappings={props.refreshContactIntegrationMappings}
      />
      <SalesForceButton
        contact={props.contact}
        integrationObjectMappings={props.integrationObjectMappings}
        refreshContactIntegrationMappings={props.refreshContactIntegrationMappings}
      />
      <EloquaButton
        contact={props.contact}
        integrationObjectMappings={props.integrationObjectMappings}
        refreshContactIntegrationMappings={props.refreshContactIntegrationMappings}
      />
    </div>
  )
}
