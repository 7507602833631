import React from 'react'
import { BasePortComponent } from 'jsplumbtoolkit-react'
import Chip from '@material-ui/core/Chip'
import 'jsplumb'

class OptionPort extends BasePortComponent {
  render () {
    let color = 'rgba(221,100,231,0.85)'
    const width = 210
    const margin = this.props.index ? 4 : 0
    if (this.props.kind === 'agentConnection') {
      color = 'rgba(61, 227, 150, 0.85)'
    } else if (this.props.kind === 'visitorInteraction') {
      color = 'rgba(101, 138, 229, 0.85)'
    } else if (this.props.kind === 'backgroundAction') {
      color = '#DD64E7'
    } else if (this.props.kind === 'flowSequence') {
      color = '#2B4177'
    } else if (this.props.kind === 'localSequence') {
      color = '#B14172'
    }
    let label = this.state.label || this.props.label
    if (!label) {
      label = 'Successfully captured ' + this.props.type
      if (this.state.id === 'invalid') {
        label = 'Failed to capture ' + this.props.type
      }
    }
    return (
      <div
        className={['noportdrag']}
        style={{ marginTop: margin, width: width, position: 'relative' }}
        data-port-id={this.state.id}
      >
        <Chip
          style={{ backgroundColor: color, width: width, borderRadius: 4 }}
          color='primary'
          label={label}
        />
        {/*
        This was for the new cursor styles, but it was breaking some functionality
        <div
          className='node-port'
          data-port-id={this.state.id}
        > */}
        <jtk-source
          port-id={this.state.id}
          scope='default'
          className='node-port'
        />
        <jtk-target
          scope='default'
        />
        {/* </div> */}
      </div>
    )
  }
};

export default OptionPort
