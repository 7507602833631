import { useDoQuery } from './useDoQuery'
import { GenericObject } from './genericObject'

interface AccountTypeList {
  list: AccountType[]
  dict: { [id: AccountType['id']]: AccountType }
}

const initialState = {
  label: '',
  default: false
}

export class AccountType extends GenericObject {
  name: string
  default: boolean

  static loadAll (): { data: AccountTypeList, isLoading: boolean } {
    return useDoQuery({ path: '/api/integrations/crm/accounts/fields/type/options', objectClass: AccountType, initialData: { list: [], dict: {} } })
  }

  constructor ({ row }: { row?: Record<string, any> }) {
    super({ row })
    const accountType = row?.attributes || initialState
    this.name = accountType.label
    this.default = accountType.default
  }
}
