import AppPage from 'cf-components/AppPage'
import { Card, CardContent, Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Icon } from 'library/materialUI'
import { useEffect, useState } from 'react'
import { SignalsScoringModal } from './SignalsScoringModal'
import { Modal } from 'library/Modal'
import { saveSettings } from 'api/user_display_settings'
import ConfirmationContent from 'cf-components/ConfirmationContent'
import { DisplaySettings } from 'classes/displaySettings'
import ScoringIntroModal from './ScoringIntroModal'
import ScoringUpgradeModal from './ScoringUpgradeModal'
import { useQuery } from 'react-query'
import { hasComponent } from 'api/billing'
import { useHistory } from 'react-router-dom'
import { getIntegrationFeatures } from 'api/integrations'

const useStyles = makeStyles(theme => ({
  header: {
    fontSize: '14px',
    fontFamily: 'poppins',
    color: 'black',
    marginBottom: '15px'
  },
  innerText: {
    fontSize: '14px',
    fontFamily: 'poppins',
    color: '#00000080'
  },
  freeHeader: {
    width: '100%',
    height: '58px',
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  iconStyle: {
    borderRadius: '20px',
    height: '20px',
    width: '20px',
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '10px'
  }
}))

export function SignalsScoringPage (): JSX.Element {
  const classes = useStyles()
  const [modalOpen, setModalOpen] = useState(false)
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false)
  const [introOpen, setIntroOpen] = useState(false)
  const { settings, isLoading: settingsIsLoading } = DisplaySettings.load()
  const [salesModalOpen, setSalesModalOpen] = useState(false)
  const [integration, setIntegration] = useState('')
  const history = useHistory()
  const { data: hasScoringComponent = false, isLoading: isLoadingComponents } = useQuery('has-scoring-component', () => hasComponent('score-insights'))

  useEffect(() => {
    getIntegrationFeatures().then((res) => {
      const settings = res.data.find((row: any) => row.id === 'crm.abe')
      if (settings) {
        const currentIntegration = settings.attributes.integration.name
        setIntegration(currentIntegration)
      }
    })
  }, [])

  useEffect(() => {
    if (!settingsIsLoading && !isLoadingComponents) {
      if (!settings?.scoreIntro && hasScoringComponent) {
        saveSettings({ kind: 'score_intro', settings: { 'score-intro-modal': { complete: false } }, post: false })
        setIntroOpen(true)
      } else {
        if (!hasScoringComponent) {
          return
        }
        setIntroOpen(!settings.scoreIntro.settings['score-intro-modal'].complete)
      }
    }
    // eslint-disable-next-line
  }, [settingsIsLoading])

  const handleCloseIntro = () => {
    saveSettings({ kind: 'score_intro', settings: { 'score-intro-modal': { complete: true } }, post: false })
    setIntroOpen(false)
  }

  return (
    <AppPage
      title='Signals Scoring'
      extraTitleInfo={
        <Tooltip title='Powered by Artificial Intelligence'>
          <div style={{ marginTop: '-15px' }}>
            <Icon icon='aiSparkles' />
          </div>
        </Tooltip>
      }
    >
      <ScoringIntroModal open={introOpen} onHide={handleCloseIntro} onSetup={() => setModalOpen(true)} />
      <SignalsScoringModal modalOpen={modalOpen} setModalOpen={setModalOpen} finishStep={() => setConfirmationModalOpen(true)} />
      <ScoringUpgradeModal open={salesModalOpen} onHide={() => setSalesModalOpen(false)} />
      <Modal
        open={confirmationModalOpen}
        onHide={() => setConfirmationModalOpen(false)}
        title=''
        handleSave={() => null}
        hideCancelButton
        hideSaveButton
        closeBtn
        noDividers
      >
        <ConfirmationContent
          title="We're scoring your accounts!"
          info="This could take up to 30 minutes to complete. We'll notify you when it's complete!"
        />
      </Modal>
      {!isLoadingComponents && !hasScoringComponent && (
        <div className={classes.freeHeader}>
          <div className={classes.iconStyle}>
            <Icon icon='upArrow' size='sm' color='#9933FF' />
          </div>
          <div>
            Upgrade for a customizable, AI-powered Signals score
          </div>
          <div>
            <Button
              color='primary'
              variant='contained'
              style={{ marginLeft: '20px' }}
              onClick={() => setSalesModalOpen(true)}
            >
              Talk To Sales
            </Button>
          </div>
        </div>
      )}
      {!isLoadingComponents && hasScoringComponent && (
        <div style={{ padding: '20px' }}>
          <Card style={{ marginBottom: 20 }}>
            <CardContent>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                <div>
                  <div className={classes.header}>
                    Configure your Signals Score
                  </div>
                  <div className={classes.innerText}>
                    Help us get an idea of the factors you care about
                  </div>
                </div>
                <div style={{ marginLeft: 'auto', marginRight: '10px' }}>
                  <Button
                    color='primary'
                    variant='contained'
                    onClick={() => setModalOpen(true)}
                  >
                    {!settingsIsLoading && settings?.scoreIntro?.settings['score-intro-modal']?.complete ? 'Update Score' : 'Configure your score'}
                  </Button>
                </div>
              </div>
            </CardContent>
          </Card>
          <Card>
            <CardContent>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                <div>
                  <div className={classes.header}>
                    CRM Sync
                  </div>
                  <div className={classes.innerText}>
                    Configure how the score will be synced to your CRM
                  </div>
                </div>
                <div style={{ marginLeft: 'auto', marginRight: '10px' }}>
                  <Button
                    color='primary'
                    variant='contained'
                    onClick={() => history.push(`/settings/${integration}`)}
                  >
                    Configure Score Sync Rules
                  </Button>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
      )}
    </AppPage>
  )
}
