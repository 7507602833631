import clsx from 'clsx'
import { Icon } from 'library/materialUI'
import { makeStyles } from '@material-ui/core/styles'
import { useState } from 'react'

const useStyles = makeStyles(theme => ({
    expandDiv: {
        display: 'flex',
        marginBottom: 6,
        marginTop: 6,
        cursor: 'pointer',
        paddingLeft: 12
    },
    buttonHover: {
      backgroundColor: 'white',
      color: '#9933FF'
    },
    buttonWrap: {
      width: 25,
      height: 25,
      borderRadius: '100%'
    },
    buttonOpen: {
      transform: 'translate(140px, 0px) rotate(90deg)',
      transition: theme.transitions.create('transform', {
          easing: theme.transitions.easing.easeIn,
          duration: theme.transitions.duration.enteringScreen
      })
    },
    buttonClose: {
      transform: 'translate(0px, 0px) rotate(-90deg)',
      transition: theme.transitions.create('transform', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.leavingScreen
      })
    }
}))

type ExpandButtonProps = {
  isOpen: boolean
  onClick: () => void
  color?: string
  hoverColor?: string
}

export function ExpandButton (props: ExpandButtonProps) {
  const classes = useStyles()
  const color = props.color ? props.color : 'black'
  const hoverColor = props.hoverColor ? props.hoverColor : '#9933FF'
  const [isHovered, setIsHovered] = useState(false)

  return (
    <div
      className={classes.expandDiv}
      onClick={() => {
        props.onClick()
      }}
      onMouseOver={() => setIsHovered(true)}
      onMouseOut={() => setIsHovered(false)}
    >
      <div
        className={
          clsx(classes.buttonWrap, {
            [classes.buttonOpen]: props.isOpen,
            [classes.buttonClose]: !props.isOpen,
            [classes.buttonHover]: isHovered
          })
        }
      >
        <Icon icon='expand' color={isHovered ? hoverColor : color} />
      </div>
    </div>
  )
}
