import React from 'react'
import Avatar from '@material-ui/core/Avatar'
import RenderEmoji from '../../RenderEmoji'
import { MessageStyles as useStyles } from '../MobileChatStyles'
import DateFormat from 'components/DateFormat'
import './message.css'
import { removeHtmlTags } from 'helpers/strings'

function VisitorMessage (props) {
  const classes = useStyles()
  const body = removeHtmlTags(props.message)

  const initial = props.initials ? props.initials[0] : 'S'

  return (
    <div className={classes.messageContainer}>
      <div>
        <Avatar className={classes.avatar}>{initial}</Avatar>
      </div>
      <div className='message-bubble tri-right left-top'>
        <RenderEmoji
          className={classes.messageText}
          text={body}
        />
      </div>
      <div className={classes.timestamp}>
        <DateFormat
          dateToFormat={props.timestamp}
          format='h:MM TT'
        />
      </div>
    </div>
  )
}

function AgentMessage (props) {
  const classes = useStyles()

  return (
    <div className={classes.messageContainer} style={{ justifyContent: 'flex-end' }}>
      <div className={classes.timestamp}>
        <DateFormat
          dateToFormat={props.timestamp}
          format='h:MM TT'
        />
      </div>
      <div className='agent-bubble tri-right right-top'>
        <RenderEmoji
          className={classes.messageText}
          text={props.message}
          rich='true'
        />
      </div>
      <div>
        <Avatar className={classes.avatar} src={props.avatarUrl} />
      </div>
    </div>
  )
}

const getDisplayInfo = (state, firstMessage) => {
  let visitor = false
  const pid = firstMessage.relationships.from_participant.data.id
  const participant = state.participants[pid]
  if (!participant) {
    visitor = true
    return { name: 'Site Visitor', avatarUrl: '', participant: {} }
  }
  let name = participant.agent_name
  let avatarUrl = participant.avatar_url
  if (participant.kind === 'visitor') {
    visitor = true
    const a = state.conversation.attributes
    if (a.name) {
      name = a.name
    } else if (a.email) {
      name = a.email.split('@')[0]
    } else {
      name = 'Site Visitor'
    }
    avatarUrl = a.contact_avatar
  } else if (participant.kind === 'bot') {
    name = participant.agent_name || 'Bot'
  }
  return { name, avatarUrl, participant, visitor }
}

function MobileMessageGroup (props) {
  const rows = props.messages
  const firstMessage = rows[0]
  const state = props.state

  const { avatarUrl, visitor } = getDisplayInfo(state, firstMessage)

  return (
    <div>
      {rows.map((row, index) => {
        if (visitor) {
          return (
            <VisitorMessage
              timestamp={row.attributes.created_timestamp}
              message={row.attributes.body}
              avatarUrl={avatarUrl}
              key={index}
            />
          )
        } else {
          return (
            <AgentMessage
              timestamp={row.attributes.created_timestamp}
              message={row.attributes.body}
              avatarUrl={avatarUrl}
              key={index}
            />
          )
        }
      })}
    </div>
  )
}

export default MobileMessageGroup
