import { ObjectAvatar } from 'cf-components/table/TableAvatars';
import { Checkbox } from 'library/materialUI';
import { makeStyles } from '@material-ui/core/styles';
import Loader from 'library/loading/Loader'
import Company from 'classes/companies';
import { ScrollBox } from 'cf-components/ScrollBox';
import { IconButton } from 'library/materialUI/Button';

const useStyles = makeStyles(theme => ({
  listItem: {
    border: '1px solid rgba(0,0,0,0.12)',
    whiteSpace: 'nowrap'
  },
  checkBox: {
    paddingRight: 20
  },
  listItemHeight: {
    height: 45,
    '& div>a>div>div>div': {
      height: 30,
      width: 30
    },
    '& div>a>div>div:nth-child(2)': {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    }
  },
  list: {
    paddingTop: 0,
    maxHeight: 500,
    overflowY: 'scroll'
  },
  preventOverflow: {
    '& div>div:nth-child(2)>h3': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      width: '85%'
    }
  },
  width: {
    width: 350
  },
  overrideContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '85%'
  }
}))
interface CompanyCardListProps {
  rows: Array<Company>
  selectedList?: Array<Record<any, any>>
  onChange?: (obj: Company) => void
  variant?: 'small'
  isLoading?: boolean
  onRemove?: (obj: Company) => void
}

export default function CompanyCardList ({ rows, selectedList, onChange, variant, isLoading, onRemove } : CompanyCardListProps) {
  const classes = useStyles()
  const showCheckBox = (selectedList !== undefined && onChange !== undefined)
  const showRemove = (onRemove !== undefined)
  const cardStyle = variant === 'small' ? `${classes.listItem} ${classes.listItemHeight}` : classes.listItem

  if (isLoading !== undefined && isLoading) {
    return (<Loader type='spinner' />)
  }

  return (
    <ScrollBox
      className={classes.list}
      noScrollBar
    >
      {rows.map((company, index) => {
        return (
          <div
            style={{ display: 'flex', width: '100%', alignItems: 'center' }}
            key={`${company.name}_${index}_listitem`}
            className={cardStyle}
          >
            {showCheckBox &&
              <div className={classes.checkBox}>
                <Checkbox
                  key={`${index}_Checkbox`}
                  onChange={() => onChange(company)}
                  checked={(selectedList.filter(displayCompany => { if (displayCompany.domain === company.domain) { return displayCompany } return false })).length > 0}
                />
              </div>}
            <ObjectAvatar
              key={`${index}_${company.domain}_Avatar`}
              row={company}
              onClick={() => ({})}
              container={classes.overrideContainer}
            />
            {showRemove && (
              <IconButton
                icon='close'
                onClick={() => onRemove(company)}
                toolTip='Remove from segment'
              />
            )}
          </div>
        )
      })}
    </ScrollBox>
  )
}
