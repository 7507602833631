import { ApiContext } from 'api-context'
import { capitalCase } from 'change-case'
import { CSVDownload } from 'classes/csvdownload'
import FormSubmission from 'classes/formSubmission'
import Form from 'classes/forms'
import { AppendFilters, parameterizeFilters, SearchParams } from 'classes/queryHelpers'
import DateFormat from 'components/DateFormat'
import { Emitter, EVENT_TYPE } from 'emitter'
import { downloadCSV } from 'helpers/csv'
import FilterMenuNoTable from 'library/filter/FilterMenuNoTable'
import SkeletonTableLoader from 'library/loading/skeleton/SkeletonTableLoader'
import { Checkbox } from 'library/materialUI'
import { FilterComponentProps } from 'library/table/FilterButton'
import { DateAddedFilter, DateAddedFilterValue } from 'library/table/tableHelpers'
import IntegrationModal from 'pages/people/IntegrationModal'
import { useContext, useEffect, useState } from 'react'

function SubmissionStatusFilter ({ filterRef, initialValues }: FilterComponentProps) {
  const selected = initialValues || ['complete']
  if (!filterRef?.current) {
    filterRef.current = { field: 'status', operator: 'in', value: selected }
  }
  const [showPartial, setShowPartial] = useState<boolean>(filterRef?.current?.value?.includes('started'))
  const [showComplete, setShowComplete] = useState<boolean>(filterRef?.current?.value?.includes('complete'))

  function createFilter (isSet: boolean, type: string) {
    if (!filterRef?.current?.value) {
      filterRef.current.value = []
    }
    if (isSet) {
      filterRef.current.value = [...filterRef.current.value, type]
    } else {
      filterRef.current.value = filterRef.current.value.filter((value: string) => value !== type)
    }
    if (filterRef.current.value.length === 0) {
      filterRef.current.value = ['complete']
    }
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Checkbox
        label='Show completed submissions'
        checked={showComplete}
        onChange={() => {
          createFilter(!showComplete, 'complete')
          setShowComplete(!showComplete)
        }}
      />
      <Checkbox
        label='Show incomplete submissions'
        checked={showPartial}
        onChange={() => {
          createFilter(!showPartial, 'started')
          setShowPartial(!showPartial)
        }}
      />
    </div>
  )
}
/* eslint-disable */
const followUpMap: Record<string, string> = {
  no_prompt: 'Was not prompted',
  prompt: 'Was prompted',
  meeting_booked: 'Booked a meeting',
  calendar_dropped: 'Meeting not booked',
  live_chat: 'Had a live chat',
  live_chat_failover: 'Live chat not responded to'
}
/* eslint-enable */
function ActionFilter ({ filterRef, initialValues }: FilterComponentProps) {
  const selected = initialValues || ['no_prompt', 'prompt', 'meeting_booked', 'calendar_dropped', 'live_chat', 'live_chat_failover']
  if (!filterRef?.current) {
    filterRef.current = { field: 'follow_up', operator: 'in', value: selected }
  }
  const [noPrompt, setNoPrompt] = useState<boolean>(filterRef?.current?.value?.includes('no_prompt'))
  const [prompt, setPrompt] = useState<boolean>(filterRef?.current?.value?.includes('prompt'))
  const [bookedMeeting, setBookedMeeting] = useState<boolean>(filterRef?.current?.value?.includes('meeting_booked'))
  const [noMeeting, setNoMeeting] = useState<boolean>(filterRef?.current?.value?.includes('calendar_dropped'))
  const [liveChat, setLiveChat] = useState<boolean>(filterRef?.current?.value?.includes('live_chat'))
  const [noLiveChat, setNoLiveChat] = useState<boolean>(filterRef?.current?.value?.includes('live_chat_failover'))

  function createFilter (isSet: boolean, type: string) {
    if (!filterRef?.current?.value) filterRef.current.value = []
    if (isSet) {
      filterRef.current.value = [...filterRef.current.value, type]
    } else {
      filterRef.current.value = filterRef.current.value.filter((value: string) => value !== type)
    }
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Checkbox
        label={followUpMap.no_prompt}
        checked={noPrompt}
        onChange={() => {
          createFilter(!noPrompt, 'no_prompt')
          setNoPrompt(!noPrompt)
        }}
      />
      <Checkbox
        label={followUpMap.prompt}
        checked={prompt}
        onChange={() => {
          createFilter(!prompt, 'prompt')
          setPrompt(!prompt)
        }}
      />
      <Checkbox
        label={followUpMap.meeting_booked}
        checked={bookedMeeting}
        onChange={() => {
          createFilter(!bookedMeeting, 'meeting_booked')
          setBookedMeeting(!bookedMeeting)
        }}
      />
      <Checkbox
        label={followUpMap.calendar_dropped}
        checked={noMeeting}
        onChange={() => {
          createFilter(!noMeeting, 'calendar_dropped')
          setNoMeeting(!noMeeting)
        }}
      />
      <Checkbox
        label={followUpMap.live_chat}
        checked={liveChat}
        onChange={() => {
          createFilter(!liveChat, 'live_chat')
          setLiveChat(!liveChat)
        }}
      />
      <Checkbox
        label={followUpMap.live_chat_failover}
        checked={noLiveChat}
        onChange={() => {
          createFilter(!noLiveChat, 'live_chat_failover')
          setNoLiveChat(!noLiveChat)
        }}
      />
    </div>
  )
}

interface FormSubmissionTableProps {
  form: Form
}
const mywindow = window as any
const exportCSV = async (searchParams: SearchParams) => {
  const exportClass = 'FormSubmission'
  Emitter.emit(EVENT_TYPE.CSV_DOWNLOAD, { csvIsDownloading: true })
  const resp = await CSVDownload.download({ type: exportClass, searchParams })
  Emitter.emit(EVENT_TYPE.CSV_DOWNLOAD, { csvIsDownloading: false })
  const date = new Date().toString()
  const csvType = exportClass.toLowerCase() + 's'
  const filename = 'signals_' + csvType + '_' + DateFormat({ dateToFormat: date, format: 'isoDate' }) + '.csv'
  downloadCSV(filename, resp)
}

/* eslint-disable */
const filterOptions = {
  created_timestamp: { title: 'Date Submitted', menu: DateAddedFilter, field: 'created_timestamp', filterValue: DateAddedFilterValue },
  follow_up: {
    title: 'Follow-up Events',
    menu: ActionFilter,
    field: 'follow_up',
    filterValue: (value: string) => {
      if (followUpMap[value]) return followUpMap[value] + '\n'
      else return capitalCase(value.replaceAll('_', ' ')) + '\n'
    }
  },
  status: {
    title: 'Incomplete Submissions',
    menu: SubmissionStatusFilter,
    field: 'status',
    filterValue: (value: string) => {
      switch (value) {
        case 'complete': return 'Complete\n'
        case 'started': return 'Incomplete\n'
        default:
          return value + '\n'
      }
    }
  }
}
/* eslint-enable */

export default function FormSubmissionTable ({ form }: FormSubmissionTableProps): JSX.Element {
  const { setDeleteObject } = useContext(ApiContext)
  const [integrationModal, setIntegrationModal] = useState<number | null>(null)
  const [searchParams, setSearchParams] = useState<SearchParams>({
    filters: [[
      { field: 'form_id', value: form.id, operator: 'eq' },
      { field: 'status', value: ['complete'], operator: 'eq' }
    ]],
    sortColumn: 'created_timestamp',
    sortOrder: 'desc'
  })
  const [filterAnchor, setFilterAnchor] = useState<HTMLElement | null>(null)
  const addFilters = (filters: any) => {
    const newFilters = AppendFilters(searchParams.filters || [], filters)
    setSearchParams({ ...searchParams, filters: newFilters })
    const ele = document.getElementById('form-submissions-body-filters')
    if (ele && ele.hasAttribute('value')) {
      ele.setAttribute('value', JSON.stringify(newFilters))
    }
    mywindow.htmx.trigger('#form-submissions-body-form', 'submit')
  }
  const tableEventListener = (e: any) => {
    console.log('tableEvent', e)
    const tableID = e.detail.tableID
    const btn = e.detail.btn
    const action = e.detail.action
    const ids: Array<string> = []
    if (tableID === 'form-submissions-body') {
      switch (action) {
        case 'filter':
          setFilterAnchor(document.getElementById(btn))
          break
        case 'export':
          Array.from(document.getElementsByClassName('rowcheckbox')).forEach((checkbox: any) => {
            if (checkbox.checked) {
              ids.push(checkbox.value)
            }
          })
          if (ids.length === 0) {
            exportCSV(searchParams)
          } else {
            const exportParams = { ...searchParams, filters: AppendFilters(searchParams.filters || [], [[{ field: 'fs.id', value: ids, operator: 'in' }]]) }
            exportCSV(exportParams)
          }
          break
        default:
          console.warn('Unknown table action', action)
      }
    } else {
      console.log('different table event: ', tableID)
    }
  }
  const rowEventListener = (e: any) => {
    console.log('rowEvent', e)
    const tableID = e.detail.tableID
    const rowID = e.detail.rowID
    const rowAction = e.detail.action
    if (tableID === 'form-submissions-body') {
      switch (rowAction) {
        case 'add-to-integration':
          setIntegrationModal(rowID)
          break
        case 'delete':
          mywindow.deleteRow = e.detail.tr
          setDeleteObject(new FormSubmission({ row: { id: rowID } }))
          break
        default:
          console.warn('Unknown row action', rowAction)
      }
    } else {
      console.log('different table event: ', tableID)
    }
  }
  Emitter.on(EVENT_TYPE.DATA_CHANGED, (data: any) => {
    if (data?.eventURL.includes('forms/submission') && data?.method === 'DELETE') {
      if (mywindow.deleteRow) {
        console.log('swapping row', mywindow.deleteRow)
        mywindow.htmx.swap(
          mywindow.deleteRow,
          '',
          {
            swapDelay: 1000,
            swapStyle: 'outerHTML'
          })
        mywindow.deleteRow = null
      } else {
        console.log('no row anchor')
      }
    } else {
      console.log('no match', data)
    }
  })

  useEffect(() => {
    try {
      /*
      mywindow.htmx.logger = function (elt: any, event: any, data: any) {
        if (console) {
          //console.log('INFO:', event, elt, data);
        }
      }
      */
      mywindow.addEventListener('tableEvent', tableEventListener);
      mywindow.addEventListener('rowEvent', rowEventListener);
    } catch (e) {
      console.log(e)
    }
    return () => {
      mywindow.removeEventListener('tableEvent', tableEventListener)
      mywindow.removeEventListener('rowEvent', rowEventListener)
    }
    // eslint-disable-next-line
  }, [searchParams])

  const url = parameterizeFilters({ path: `/api/core/web/forms/${form.id}/submissions`, searchParams })
  return (
    <>
      <FilterMenuNoTable
        filterOptions={filterOptions}
        anchorEl={filterAnchor}
        setAnchorEl={setFilterAnchor}
        handleFilter={(filters: any) => {
          addFilters(filters)
        }}
        clearFilters={() => setSearchParams({ ...searchParams, filters: [] })}
        searchParams={searchParams}
      />
      <div style={{ backgroundColor: 'white' }}>
        <div
          id='form-submissions'
          hx-get={url}
          hx-trigger='revealed'
          hx-indicator='.form-submissions-loader'
        >
          <div style={{ marginTop: 30 }}>
            <SkeletonTableLoader
              rowCount={8}
              types={['squareAvatar&Label', 'name&Subtitle', 'number', 'smLabel', 'number', 'smLabel', 'number', 'avatar']}
              columns='80px 300px 1fr 1fr 1fr 1fr 120px 80px'
            />
          </div>
        </div>
      </div>
      {integrationModal && (
        <IntegrationModal
          contactID={integrationModal}
          onClose={() => setIntegrationModal(null)}
        />)}
    </>
  )
}
