import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import { FilterBar, convertDateRange } from 'cf-components/FilterBar/FilterBar'
import { getSequenceReport } from 'api/sequences'
import { getSessionDateRange } from 'cf-components/FilterBar/CustomDateRanges'

const useStyles = makeStyles(theme => ({
  statsCards: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
    gridGap: 20,
    marginTop: 10
  },
  sequenceCard: {
    padding: 20,
    border: '1px solid rgba(151, 92, 230, .5)',
    fontFamily: 'Poppins, sans serif',
    borderRadius: 10
  },
  statsCount: {
    marginTop: 10,
    marginBottom: 10,
    fontSize: '50px',
    color: '#8e5ae2'
  },
  statsPercent: {
    fontSize: '22px',
    color: '#777'
  },
  cardTitle: {
    fontWeight: 600
  }
}))

const defaultReport = {
  sequence_fires: 0,
  emails_captured: 0,
  live_chat_routes: 0,
  meetings_booked: 0,
  new_conversations: 0,
  primary_goals: 0,
  secondary_goals: 0
}

function SequenceStatsCard (props) {
  const classes = useStyles()

  const percent = props.denominator ? (props.value * 100 / props.denominator).toFixed(2) + '%' : '-----'

  return (
    <Card className={classes.sequenceCard}>
      <div className={classes.cardTitle}>
        {props.title}
      </div>
      <div className={classes.statsCount}>
        {props.value || 0}
      </div>
      <div className={classes.statsPercent}>
        {percent}
      </div>
    </Card>
  )
}

function SequenceStatistics (props) {
  const classes = useStyles()
  const botValues = props.bots.map(bot => (
    { id: bot.id, label: bot.attributes.name }
  ))
  let startingDate = getSessionDateRange()
  startingDate = { ...startingDate, startDate: new Date(startingDate.startDate), endDate: new Date(startingDate.endDate) }
  const [dateRange, setDateRange] = useState(startingDate)
  const [botFilter, setBotFilter] = useState(botValues)
  const [report, setReport] = useState(null)

  useEffect(() => {
    const sequenceID = props.sequenceID
    const convertedRange = convertDateRange(dateRange)
    const startDate = convertedRange?.start_date
    const endDate = convertedRange?.end_date
    const botIDs = botFilter.map(bot => bot.id)
    if (sequenceID) {
      getSequenceReport({ sequenceID, startDate, endDate, botIDs })
        .then(response => setReport(response.data.attributes))
    } else {
      setReport(defaultReport)
    }
  }, [botFilter, dateRange, props.sequenceID])

  const filterOptions = {
    filterValues: [
      { label: 'Filter by Date', type: 'date', value: dateRange, setValue: setDateRange },
      { label: 'Filter by Bot', type: 'checkbox', value: botFilter, setValue: setBotFilter, values: botValues, kind: 'bots' }
    ],
    type: 'V2'
  }

  if (!report) {
    return <></>
  }

  return (
    <div>
      <FilterBar
        filterOptions={filterOptions}
        noPadding
      />
      <div className={classes.statsCards}>
        <SequenceStatsCard
          title='Sequence Fires'
          value={report.sequence_fires}
        />
        <SequenceStatsCard
          title='Routed to Live Chat'
          value={report.live_chat_routes}
          denominator={report.sequence_fires}
        />
        <SequenceStatsCard
          title='Emails Captured'
          value={report.emails_captured}
          denominator={report.sequence_fires}
        />
        <SequenceStatsCard
          title='Meetings Booked'
          value={report.meetings_booked}
          denominator={report.sequence_fires}
        />
        <SequenceStatsCard
          title='Primary Goals Reached'
          value={report.primary_goals}
          denominator={report.sequence_fires}
        />
        <SequenceStatsCard
          title='Secondary Goals Reached'
          value={report.secondary_goals}
          denominator={report.sequence_fires}
        />
      </div>
    </div>
  )
}

export default SequenceStatistics
