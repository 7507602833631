import { useState, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { ReactComponent as TrophyIcon } from 'img/trophy.svg'
import Tooltip from '@material-ui/core/Tooltip'
import FlowPreviewModal from 'pages/bot/flow-builder/surface/FlowPreviewModal'
import { ListItemCard, CardListHeader } from 'cf-components/CardList'
import { AvatarWithInfo } from 'cf-components/Avatars'

const useStyles = makeStyles(theme => ({
  testOverviewCard: {
    padding: 12,
    display: 'grid',
    gridTemplateColumns: '5fr 2fr 3fr 2fr 2fr 2fr',
    gridGap: 15,
    alignItems: 'center'
  },
  statsDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  headerClass: {
    display: 'grid',
    gridTemplateColumns: '5fr 2fr 3fr 2fr 2fr 2fr',
    gridGap: 15,
    alignItems: 'center',
    fontSize: '14px',
    marginBottom: 5
  },
  versionDiv: {
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    width: '100%'
  }
}))

function TestStatsTable (props) {
  const winningFlow = props.winningFlow
  const originalFlowID = props.originalFlowID
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [pageNumber, setPageNumber] = useState(1)
  const classes = useStyles()
  const [previewFlowID, setPreviewFlowID] = useState(0)
  const rows = useMemo(() => props.rows.map(row => {
    const variant = row.id === originalFlowID ? 'Original Version' : 'Modified Version'
    const initials = row.id === originalFlowID ? 'A' : 'B'
    const color = row.id === originalFlowID ? '#8e5ae2' : '#fe9d10'
    const conversionRate = (row.attributes.conversion_rate * 100).toFixed(2) + '%'
    const improvement = row.attributes.improvement ? (row.attributes.improvement * 100).toFixed(2) + '%' : '---'
    const winProbability = row.attributes.win_probability ? Math.round(row.attributes.win_probability * 100) + '%' : '---'
    const probabilityColor = row.attributes.win_probability > 0.5 ? 'green' : 'rgb(240, 112, 111)'
    const improvementColor = row.attributes.improvement && (row.attributes.improvement < 0 ? 'rgb(240, 112, 111)' : 'green')
    return ({
      id: row.id,
      label: row.attributes.variant,
      variant: variant,
      initials: initials,
      color: color,
      sessions: row.attributes.sessions,
      success_metric: row.attributes.success_metric,
      botPrompts: row.attributes.bot_prompts,
      conversions: row.attributes.conversions,
      conversionRate: conversionRate,
      improvement: improvement,
      winProbability: winProbability,
      improvementColor: improvementColor,
      probabilityColor: probabilityColor
    })
  }), [props.rows, originalFlowID])

  function TestOverviewCard (props) {
    const row = props.row
    const classes = useStyles()

    return (
      <ListItemCard>
        <div className={classes.testOverviewCard}>
          <div
            className={classes.versionDiv}
            onClick={() => setPreviewFlowID(row.id)}
          >
            <AvatarWithInfo
              title={row.variant}
              initials={row.initials}
              subtitle={row.label}
              avatarColor={row.color}
              variant='rounded'
            />
            {row.id === winningFlow &&
              <div>
                <Tooltip title='Winning Version'>
                  <TrophyIcon
                    style={{ fill: 'gold', marginRight: 10 }}
                  />
                </Tooltip>
              </div>}
          </div>
          <div className={classes.statsDiv}>
            {row.botPrompts}
          </div>
          <div className={classes.statsDiv}>
            {row.conversions}
          </div>
          <div className={classes.statsDiv}>
            {row.conversionRate}
          </div>
          <div className={classes.statsDiv} style={{ color: row.improvementColor }}>
            {row.improvement}
          </div>
          <div className={classes.statsDiv} style={{ color: row.probabilityColor }}>
            {row.winProbability}
          </div>
        </div>
      </ListItemCard>
    )
  }

  let conversion_title = 'Primary Conversions'
  if (rows.length) {
    conversion_title = rows[0].success_metric
  }

  const columns = [
    { id: 'variant', label: '' },
    { id: 'bot_prompts', label: 'Prompts' },
    { id: 'conversions', label: conversion_title },
    { id: 'conversion_rate', label: 'Conversion' },
    { id: 'improvement', label: 'Improvement' },
    { id: 'win_probability', label: 'Win Prob.' }
  ]

  return (
    <div>
      <CardListHeader
        headerClass={classes.headerClass}
        disableNext
        columns={columns}
        centerColumns
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
      />
      {rows.map((row, index) => (
        <TestOverviewCard
          row={row}
          key={index}
        />
      ))}
      <FlowPreviewModal
        open={previewFlowID > 0}
        close={() => setPreviewFlowID(0)}
        flowID={previewFlowID}
        chatServiceUrl={props.chatServiceUrl}
      />
    </div>
  )
}

export default TestStatsTable
