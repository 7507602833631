import { doGet, doPost } from './api'

export const getListOfAccountTypes = (): Promise<Record<string, any>> => doGet({
  path: '/accounts/icp/account_types',
  useChatURL: true
})

export const getCRMAccountTypes = (objectType: string, field: string): Promise<Record<string, any>> => doGet({
  path: `/integrations/crm/${objectType}/fields/${field}/options`,
  useChatURL: false
})

export const getICPGuess = (customerTypes: string[], propertyType: string): Promise<any> => doPost({ path: '/api/integrations/enrich/icp', data: { attributes: { customer_types: customerTypes, property_type: propertyType } } })

export const getICP = (): Promise<any> => doGet({
  path: '/accounts/icp/get',
  useChatURL: true
}).catch(err => {
  return { error: err }
})
