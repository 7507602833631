
const descriptionText = "Use this skill when you want to perform certain actions based on the visitor's location."

function geographyBranchingDescription () {
  return (
    <div>
      <div>
        {descriptionText}
      </div>
    </div>
  )
}

const bestPracticeText = 'Use this skill with other nodes to inform agents of visitor activity.'

function geographyBranchingBestPractices () {
  return (
    <div>
      <div>
        {bestPracticeText}
      </div>
      <ul>
        <li>Use with the Alert Agent skill to alert an agent in charge of sales for a geographic region</li>
        <li>Use with the Add to Campaign skill to add the visitor to campaigns associated with a geographic location</li>
      </ul>
    </div>
  )
}

export const geographyBranchingDocs = {
  helpCenterLink: 'https://help.getsignals.ai/article/0k47otvwwh-company-segment-node',
  bestPractices: geographyBranchingBestPractices,
  description: geographyBranchingDescription,
  videoLink: ''
}
