import { Button, Icon, Typography } from 'library/materialUI'
import { makeStyles } from '@material-ui/core/styles'
import { IconButton } from 'library/materialUI/Button'

const useStyles = makeStyles(theme => ({
  style: {
    display: 'block',
    padding: '15px',
    borderRadius: '5px',
    fontSize: '12px',
    marginBottom: '10px',
    width: 'fit-content',
    boxShadow: '0px 10px 15px #00000014'
  }
}))

interface MessageDivProps {
    open: boolean
    setOpen: (open: boolean) => void
    body: string | JSX.Element
    type?: 'error' | 'warning' | 'upgrade' | 'info'
    helpLink?: string
    helpButtonText?: string
    helpAction?: () => void
    dismissable?: boolean
}

const messageDivMapping = {
  error: {
    background: '#FFF3F1',
    icon: <Icon icon='error' size='md' color='#EA422A' />
  },
  warning: {
    background: '#FEF9E5',
    icon: <Icon icon='warning' size='md' color='#F4D451' />
  },
  upgrade: {
    background: '#9933FF1A',
    icon: <div style={{ borderRadius: 20, backgroundColor: '#9933FF', alignItems: 'center', justifyContent: 'center' }}><Icon icon='upArrow' size='md' color='white' /></div>
  },
  info: {
    background: '#EDF1FA',
    icon: <Icon icon='info' size='md' color='#547BD0' />
  }
}

export default function MessageDiv ({ open, setOpen, body, type, helpLink, helpButtonText, helpAction, dismissable }: MessageDivProps): JSX.Element {
  const classes = useStyles()
  const mapType = messageDivMapping[type || 'info']
  if (!open) return <></>
  return (
    <div className={classes.style} style={{ background: mapType.background }}>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: helpLink ? '10px' : '0px' }}>
        <Typography variant='body1' style={{ display: 'flex', alignItems: 'flex-start', fontSize: 14, paddingLeft: 5 }}>{mapType.icon}<div style={{ marginLeft: 5 }}>{body}</div></Typography>
        {(helpLink || helpAction) && (
          <Button
            variant='text'
            onClick={() => {
              if (helpAction) {
                helpAction()
              } else {
                window.open(helpLink)
              }
            }}
            style={{ marginLeft: 'auto' }}
          >
            {helpButtonText || 'CLICK HERE'}
          </Button>
        )}
        {dismissable && (
          <IconButton icon='close' onClick={() => setOpen(false)} color='#505050' size='lg' />
        )}
      </div>
    </div>
  )
}
