import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import InputBase from '@material-ui/core/InputBase'
import { Divider, Paper } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  paper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    border: '1px solid #ccc',
    borderRadius: '5px',
    marginTop: 10,
    boxShadow: 'none',
    padding: '5px 0px'
  },
  verticalDivider: {
    height: 28
  },
  iconButton: {
    padding: 6
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    color: 'rgba(0,0,0,0.87)',
    fontSize: '14px',
    paddingTop: 3,
    paddingLeft: 5
  }
}))

function ButtonTextField (props) {
  const classes = useStyles()
  const [label, setLabel] = useState('')

  function addButton () {
    props.addButton(label)
    setLabel('')
  }

  function remapEnterKey (event) {
    if (event.key === 'Enter') {
      addButton()
    }
  }

  const placeholder = props.placeholder ? props.placeholder : 'Type button text and hit enter...'

  return (
    <Paper className={classes.paper}>
      <InputBase
        className={classes.input}
        placeholder={placeholder}
        margin='dense'
        value={label}
        onChange={e => setLabel(e.target.value)}
        onKeyPress={remapEnterKey}
      />
      <Divider className={classes.verticalDivider} orientation='vertical' />
    </Paper>
  )
}

export default ButtonTextField
