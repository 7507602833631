import { useContext, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import AppPage from 'cf-components/AppPage'
import { Icon } from 'library/materialUI/Icon'
import { Button } from 'library/materialUI'
import ScraperModal from './ScraperModal'
import ScraperSummary from 'classes/scraperSummary'
import Loader from 'library/loading/Loader'
import { ApiContext } from 'api-context'
import { Emitter, EVENT_TYPE } from 'emitter'

const useStyles = makeStyles(theme => ({
  container: {
    margin: '20px',
    padding: '20px',
    backgroundColor: '#FFFFFF',
    border: '1px solid #D3D3D3',
    borderRadius: '5px',
    justifyContent: 'center'
  },
  iconDiv: {
    marginRight: '20px',
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    backgroundColor: '#343434',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  title: {
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#343434'
  },
  lightText: {
    fontSize: '14px',
    color: '#808080'
  },
  listHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  divStyle: {
    marginBottom: '20px',
    marginTop: '20px'
  },
  buttonStyle: {
    marginLeft: 'auto',
    marginRight: '10px'
  }
}))

const mywindow = window as any

export default function AIContentPage () {
  const classes = useStyles()
  const { setDeleteObject } = useContext(ApiContext)
  const [modalOpen, setModalOpen] = useState(false)

  const url = '/api/core/web/scraper_summary/list'

  useEffect(() => {
    try {
      mywindow.htmx.logger = function (elt: any, event: any, data: any) {
        if (console) {
          console.log('INFO:', event, elt, data);
        }
      }
      const ids = ['htmx-table']
      for (const id of ids) {
        const d = document.getElementById(id)
        mywindow.htmx.process(d)
        mywindow._hyperscript.processNode(d)
      }
      mywindow.addEventListener('rowEvent', function (e: any) {
        const tableID = e.detail.tableID
        const rowID = e.detail.rowID
        const rowAction = e.detail.action
        if (tableID === 'scraper-summary-list-body') {
          switch (rowAction) {
            case 'refresh':
              ScraperSummary.doScrape(rowID)
              break
            case 'delete':
              mywindow.deleteRow = e.detail.tr
              setDeleteObject(new ScraperSummary({ row: { id: rowID } }))
              break
            default:
              console.warn('Unknown row action', rowAction)
          }
        } else {
          console.log('different table event: ', tableID)
        }
      });
    } catch (e) {
      console.log(e)
    }
    // eslint-disable-next-line
  }, [])

  Emitter.on(EVENT_TYPE.DATA_CHANGED, (data: any) => {
    if (data?.eventURL.includes('scraper_summar')) {
      if (data?.method === 'DELETE') {
        if (mywindow.deleteRow) {
          mywindow.htmx.swap(
            mywindow.deleteRow,
            '',
            {
              swapDelay: 1000,
              swapStyle: 'outerHTML'
            })
          mywindow.deleteRow = null
        } else {
          console.log('no row anchor')
        }
      } else if (data?.method === 'POST') {
        mywindow.htmx.trigger('#scraper-summary-list', 'revealed')
      }
    } else {
      console.log('no match', data)
    }
  })

  return (
    <AppPage
      title='Content for AI'
      padding={10}
    // saveProps={saveProps}
    >
      <div className={classes.container}>
        <div className={classes.lightText}>
          Sync your website and other content to train Signals AI for things like Signals Score, AI Chatbots, and more.
        </div>
      </div>
      <div className={classes.container}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div className={classes.iconDiv}>
            <Icon icon='webPage' size='md' color='white' />
          </div>
          <div>
            <div className={classes.title}>
              Websites
            </div>
            <div className={classes.lightText}>
              Train the AI on any public website. Sites will be synced every 30 days.
            </div>
          </div>
          <div className={classes.buttonStyle}>
            <Button
              variant='outlined'
              size='medium'
              onClick={() => setModalOpen(true)}
            >
              Add Site
            </Button>
          </div>
        </div>
        <div id='htmx-table' style={{ backgroundColor: 'white', marginTop: '20px' }}>
          <div
            id='scraper-summary-list'
            hx-get={url}
            hx-trigger='revealed'
            hx-indicator='.scraper-summary-list-loader'
          >
            <div style={{ margin: 300 }}>
              <Loader size='lg' />
            </div>
          </div>
        </div>
      </div>
      <div>
        <ScraperModal open={modalOpen} setOpen={setModalOpen} />
      </div>
    </AppPage>
  )
}
