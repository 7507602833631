import { makeStyles, Theme } from '@material-ui/core/styles'
import { Avatar, getInitials } from 'cf-components/Avatars'
import { OktaIntegrationSettings } from 'classes/oktaIntegrationSettings'
import { useMediaQuery } from 'helpers/MediaQueries/matches'
import Loader from 'library/loading/Loader'
import { Typography } from 'library/materialUI'
import { useState } from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import OktaBtn from './SSOButtons/OktaBtn'

interface StyleProp {
  widthSize: number
}

const useStyles = makeStyles<Theme, StyleProp>(() => ({
  textGreeting: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 20,
    '& >p': {
      fontSize: 20
    }
  },
  iconAndGreeting: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  container: {
    marginTop: 100
  },
  signInText: {
    marginTop: 35,
    marginBottom: 15
  },
  signInTextAndBtnContainer: {
    width: props => props.widthSize
  }
}))

export default function OktaLoginPage () {
  const matches = useMediaQuery('xsm');
  const classes = useStyles({ widthSize: matches ? 340 : 400 })
  const location = useLocation()
  const [tenantId, setTenantID] = useState(0)
  if (location.hash && !tenantId) {
    setTenantID(parseInt(location.hash.replace('#', '')))
  }
  const { settings, isLoading } = OktaIntegrationSettings.load(tenantId)
  const initials = getInitials(settings.companyName, settings.companyName)

  if (!location.hash) {
    return (
      <Redirect to='#/login' />
    )
  }

  if (!settings || isLoading) {
    return (
      <div>
        <Loader type='spinner' />
      </div>
    )
  }

  return (
    <div className={classes.container}>
      <div className={classes.iconAndGreeting}>
        <Avatar
          avatarUrl={`/api/logo-service/logo/${settings.authDomain}.com.png`}
          size='lg'
          initials={initials}
          status={undefined}
          user={undefined}
          contact={undefined}
          participantID={undefined}
          backgroundColor={undefined}
          color={undefined}
          variant={undefined}
          alt={undefined}
          useLogos={undefined}
          domain={undefined}
          conversation={undefined}
        />
        <div className={classes.textGreeting}>
          <Typography>{`Welcome, ${settings.companyName}!`}</Typography>
          <h3> &#x1F44B;</h3>
        </div>
      </div>
      <div className={classes.signInTextAndBtnContainer}>
        <div className={classes.signInText}>
          <Typography variant='h1'>Sign In</Typography>
          <Typography variant='subtitle1'>Engage your ideal customer using Signals</Typography>
        </div>
        <OktaBtn
          tenantId={tenantId}
        />
      </div>
    </div>
  )
}
