import { useDoQuery } from './useDoQuery'
import { GenericObject } from './genericObject'

interface CRMRecordTypeList {
  list: CRMRecordType[]
  dict: { [id: CRMRecordType['id']]: CRMRecordType }
}

const initialState = {
  label: '',
  default: false
}

export class CRMRecordType extends GenericObject {
  name: string
  default: boolean

  static loadAll (): { data: CRMRecordTypeList, isLoading: boolean } {
    return useDoQuery({ path: '/api/integrations/crm/accounts/fields/record_type/options', objectClass: CRMRecordType })
  }

  constructor ({ row }: { row?: Record<string, any> }) {
    super({ row })
    const crmRecordType = row?.attributes || initialState
    this.name = crmRecordType.label
    this.default = crmRecordType.default
  }
}
