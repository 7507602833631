import { makeStyles } from '@material-ui/core/styles'
import Link from 'cf-components/Link'
import Persona from 'classes/personas'
import DragNDrop from 'cf-components/DragNDrop'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import { Checkbox, Icon } from 'library/materialUI'
import { useEffect, useState } from 'react'
import { ModalSection } from 'library/Modal'

const useStyles = makeStyles(theme => ({
  oppTypeHeader: {
    fontSize: '20px',
    fontFamily: 'Poppins',
    color: theme.palette.primary.main,
    fontWeight: 'bold'
  },
  innerText: {
    fontSize: '14px',
    fontFamily: 'Poppins',
    color: '#00000080'
  },
  link: {
    color: theme.palette.primary.main,
    fontSize: '14px',
    fontFamily: 'Poppins'
  },
  personaItem: {
    borderTop: 'solid',
    borderTopWidth: '1px',
    borderTopColor: '#00000020'
  },
  rowItem: {
    marginRight: '30px'
  },
  numItem: {
    marginRight: '30px',
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    fontSize: '14px',
    marginTop: '3px'
  },
  grabbable: {
    cursor: 'grab',
    color: 'black'
  }
}))

interface PersonaRow {
  idx: number
  numId: number
  id: string
}
interface SignalsScoringStep3Props {
  buyingCommitteePriorities: number[]
  setBuyingCommitteePriorities: (value: number[]) => void
}

interface PersonaItemProps {
  item: PersonaRow
  personaList: Persona[]
}

function PersonaItem ({ item, personaList }: PersonaItemProps): JSX.Element {
  const classes = useStyles()
  const name = personaList.find(persona => persona.id === item.numId)?.name
  return (
    <div className={classes.personaItem}>
      <div style={{ display: 'flex', flexDirection: 'row', margin: '10px' }}>
        <div className={classes.rowItem}>
          <DragIndicatorIcon
            className={classes.grabbable}
            color='primary'
          />
        </div>
        <div className={classes.numItem}>
          {item.idx + 1}
        </div>
        <div className={classes.rowItem}>
          {name}
        </div>
      </div>
    </div>
  )
}

export default function SignalsScoringStep3 ({ buyingCommitteePriorities, setBuyingCommitteePriorities }: SignalsScoringStep3Props): JSX.Element {
  const classes = useStyles()
  const { data: personas, isLoading } = Persona.loadAll()
  const minHeight = buyingCommitteePriorities.length * 45
  const [currentList, setCurrentList] = useState<number[]>([])
  const handleReorder = (s: PersonaRow[]) => {
    const newList = s.map((row: PersonaRow) => row.numId)
    setCurrentList(newList)
    setBuyingCommitteePriorities([...newList])
  }

  const handleUpdatePriorities = () => {
    return (buyingCommitteePriorities.map((persona: number, index: number) => {
      return {
        idx: index,
        numId: persona,
        id: 'persona-' + persona
      }
    }))
  }

  const [priorities, setPriorities] = useState(handleUpdatePriorities())

  useEffect(() => {
    if (isLoading) return
    setCurrentList(personas.list.map(persona => persona.id as number))
    if (buyingCommitteePriorities.length < 1) {
      setBuyingCommitteePriorities(personas.list.map(persona => persona.id as number))
    }
    // eslint-disable-next-line
  }, [isLoading, personas])

  useEffect(() => {
    setPriorities(handleUpdatePriorities())
    // eslint-disable-next-line
  }, [buyingCommitteePriorities])

  return (
    <ModalSection>
      <div className={classes.oppTypeHeader}>
        Buying committee
      </div>
      <div className={classes.innerText} style={{ marginTop: '12px' }}>
        Rank your personas from most important to least important in your buying process.
      </div>
      <Checkbox
        label='Weight all personas the same'
        checked={buyingCommitteePriorities.length === 0}
        onChange={() => {
          if (buyingCommitteePriorities.length === 0) {
            setBuyingCommitteePriorities(currentList)
          } else {
            setBuyingCommitteePriorities([])
          }
        }}
      />
      <div style={{ minHeight: minHeight, marginTop: '15px', marginBottom: '15px', borderBottomColor: '#00000020', borderBottomStyle: 'solid', borderBottomWidth: '1px' }}>
        <DragNDrop
          itemList={priorities}
          setItemList={handleReorder}
          direction='vertical'
          component={PersonaItem}
          componentProps={{ personaList: personas.list }}
          divClass='no-focus-outline'
        />
      </div>
      {(buyingCommitteePriorities.length > 0) && (
        <div>
          <Link to='/contact_management/buying_committee' style={{ textDecoration: 'none', marginRight: 10 }} target='_blank'>
            <div className={classes.link} style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ marginRight: '5px' }}>
                view, edit, or add new personas
              </div>
              <div>
                <Icon icon='launch' />
              </div>
            </div>
          </Link>
        </div>
      )}
    </ModalSection>
  )
}
