import { useDoQuery } from './useDoQuery'
import { snakeCase } from 'change-case'
import { SearchParams } from './queryHelpers'

type ObjectType = 'bot' | 'play' | 'email' | 'tenant'

export interface ClusteredColumnChartProps {
  object: ObjectType
  reportType: 'clusteredColumn' | 'pieChart'
  columns: string[]
  column?: string
  category: 'name' | 'period'
  timePeriod?: 'week' | 'month'
  objectID?: string | number
  skipWeekends?: boolean
  admin?: boolean
  tenantID?: number
}

export interface LineChartProps {
  object: ObjectType
  reportType: 'lineChart'
  category: string
  column: string
  columns?: undefined
  timePeriod: 'day' | 'week' | 'month'
  objectID?: number
  skipWeekends?: boolean
  admin?: boolean
  tenantID?: number
}
export interface SparkLineChartProps {
  object: ObjectType
  reportType: 'sparkLineChart'
  category: string
  column: string
  columns?: undefined
  timePeriod: 'day' | 'week' | 'month'
  objectID?: number
  skipWeekends?: boolean
  admin?: boolean
  tenantID?: number
}

export interface SummaryProps {
  object: ObjectType
  reportType: 'summary'
  category: string
  timePeriod?: undefined
  columns?: undefined
  column?: string
  objectID?: number
  skipWeekends?: boolean
  admin?: boolean
  tenantID?: number
}

type LoadParams = {
  reportProps: ClusteredColumnChartProps | LineChartProps | SummaryProps | SparkLineChartProps
  filters: any
}

type LoadFunction = ({ reportProps, filters }: LoadParams) => { data: Record<string, any>, isLoading: boolean }

export class Report {
  data: Record<string, any>

  static load: LoadFunction = ({ reportProps, filters }) => {
    const { object, reportType, columns, column, category, timePeriod, skipWeekends } = reportProps
    const params = new URLSearchParams()
    if (columns) {
      params.set('columns', JSON.stringify(columns.map((c: string) => snakeCase(c))))
    }
    if (column) {
      params.set('column', column)
    }
    if (skipWeekends) {
      params.set('skip_weekends', skipWeekends.toString())
    }
    params.set('category', category)
    if (timePeriod) {
      params.set('period', timePeriod)
    }
    if (reportProps.objectID) {
      params.set('object_id', reportProps.objectID.toString())
    }
    let path = `/reporting/${snakeCase(reportType)}/${object}?` + params.toString()
    if (reportProps.admin) {
      path = `/admin_portal/${reportProps.tenantID}${path}`
    }
    const searchParams: SearchParams = { filters }
    const { data, isLoading } = useDoQuery({ useChatURL: true, path, objectClass: Report, searchParams })
    return { data: data?.data || {}, isLoading }
  }

  constructor ({ row }: { row: Record<string, any> }) {
    const original = row.attributes.data
    this.data = original
  }
}
