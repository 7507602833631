import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import { makeStyles } from '@material-ui/core/styles'
import { Avatar } from 'cf-components/Avatars'

const useStyles = makeStyles(theme => ({
  table: {
    display: 'grid',
    gridTemplateColumns: '2fr 1fr 1fr'
  },
  stat: {
    borderTop: 'thin solid #D3D3D3',
    padding: 15,
    alignItems: 'center',
    textAlign: 'center'
  },
  header: {
    height: 60,
    paddingBottom: 4,
    alignItems: 'center'
  },
  center: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '30%'
  }
}))

function PerformanceChart (props) {
  const classes = useStyles()

  if (!props.data) {
    return (
      <></>
    )
  }

  let sizes = '4fr 3fr 3fr 3fr'
  if (props.data.length === 2) {
    sizes = '2fr 1fr 1fr'
  }
  if (props.data.length === 1) {
    sizes = '2fr 1fr'
  }

  return (
    <div style={{ display: 'grid', gridTemplateColumns: sizes }}>
      <div>
        <div className={classes.header} />
        <div className={classes.stat}> CONVERSATIONS </div>
        <div className={classes.stat}> RESPONSE RATE </div>
        <div className={classes.stat}> RESPONSE TIME </div>
        <div className={classes.stat}> CHAT LENGTH </div>
        <div className={classes.stat}> HRS AVAILABLE </div>
      </div>
      {props.data.map(row => (
        <div key={row.id}>
          {row.user.id === props.user.id
            ? (
              <div>
                <div className={classes.header}>
                  <Tooltip title={row.user.attributes.name} position='right'>
                    <div className={classes.center}>
                      <Avatar
                        user={row.user}
                        size='md'
                      />
                    </div>
                  </Tooltip>
                </div>
                <div className={classes.stat} style={{ backgroundColor: '#F5F5F5' }}> {row.conversations} </div>
                <div className={classes.stat} style={{ backgroundColor: '#F5F5F5' }}> {row.responseRate} </div>
                <div className={classes.stat} style={{ backgroundColor: '#F5F5F5' }}> {row.responseTime} </div>
                <div className={classes.stat} style={{ backgroundColor: '#F5F5F5' }}> {row.conversationLength} </div>
                <div className={classes.stat} style={{ backgroundColor: '#F5F5F5' }}> {row.timeAvailable} </div>
              </div>
            ) : (
              <div>
                <div className={classes.header}>
                  <Tooltip title={row.user.attributes.name} position='right'>
                    <div className={classes.center}>
                      <Avatar
                        user={row.user}
                        size='md'
                      />
                    </div>
                  </Tooltip>
                </div>
                <div className={classes.stat}> {row.conversations} </div>
                <div className={classes.stat}> {row.responseRate} </div>
                <div className={classes.stat}> {row.responseTime} </div>
                <div className={classes.stat}> {row.conversationLength} </div>
                <div className={classes.stat}> {row.timeAvailable} </div>
              </div>
            )}
        </div>
      )
      )}
    </div>
  )
}

export default PerformanceChart
