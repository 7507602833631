import { Dialog } from 'library/materialUI'
import { makeStyles, ThemeProvider } from '@material-ui/core/styles'
import theme from 'theme'

const useStyles = makeStyles(theme => ({
  dialogContents: {
    height: '100vh',
    width: '100%'
  }
}))

type FlowEditorModalProps = {
  children: React.ReactNode
}

export function FlowEditorModal (props: FlowEditorModalProps) {
  const classes = useStyles()
  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open
        maxWidth='xl'
        fullWidth
        id='flow-builder'
      >
        <div className={classes.dialogContents}>
          {props.children}
        </div>
      </Dialog>
    </ThemeProvider>
  )
}
