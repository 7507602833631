import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Dialog, IconButton, DialogContent } from '@material-ui/core'
import PieChart from './PieChart'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'

const useStyles = makeStyles(theme => ({
  modalHeader: {
    marginBottom: 5,
    color: '#575757',
    fontSize: 24,
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: 600
  }
}))

const PieChartModal = props => {
  const title = props.title || ''
  const classes = useStyles()
  return (
    <Dialog
      fullWidth
      maxWidth='lg'
      open={props.open}
      onClose={props.onHide}
    >
      <DialogContent>
        <div>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div className={classes.modalHeader}>
              {title}
            </div>
            <IconButton onClick={props.onHide} color='primary'>
              <HighlightOffIcon fontSize='large' />
            </IconButton>
          </div>
          <div style={{ height: 600, padding: '100px 0px' }}>
            <PieChart
              size={600}
              id={props.id}
              data={props.data}
            />
          </div>
        </div>
      </DialogContent>

    </Dialog>
  )
}

export default PieChartModal
