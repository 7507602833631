import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import { green } from '@material-ui/core/colors'
import Fab from '@material-ui/core/Fab'
import CheckIcon from '@material-ui/icons/Check'
import SaveIcon from '@material-ui/icons/Save'
import CusomizedSnackbar from 'components/CustomizedSnackbar'
import UnsavedChangesModal from 'cf-components/UnsavedChangesHistoryModal'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative'
  },
  fab: {
    color: 'white',
    backgroundColor: theme.palette.primary.alt,
    '&:hover': {
      backgroundColor: '#6941A1'
    }
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700]
    }
  },
  fabProgress: {
    color: green[500],
    position: 'absolute',
    top: -5,
    left: -5,
    zIndex: 1
  }
}))

function CircularLoadingButton (props) {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [snackState, setSnackState] = useState({
    open: false,
    variant: 'success',
    message: 'Your settings have been saved'
  })
  const { disabled, save, validPaths, reset } = props.saveProps

  const [error, setError] = useState(props.saveProps.error)

  const buttonClassname = clsx({
    [classes.fab]: !success,
    [classes.buttonSuccess]: success
  })

  function completeSave () {
    setLoading(false)
    setSuccess(true)
    setSnackState({
      open: true,
      variant: 'success',
      message: 'Your settings have been saved'
    })
    setTimeout(() => {
      setSuccess(false)
    }, 5000)
  }

  function resetButton () {
    setLoading(false)
    setSuccess(false)
    setError(false)
    setSnackState({
      open: true,
      variant: 'error',
      message: 'Error while attempting to save changes'
    })
  }

  useEffect(() => {
    if (disabled && loading) {
      setTimeout(() => {
        completeSave()
      }, 1000)
    }
  // eslint-disable-next-line
  }, [disabled])

  useEffect(() => {
    if (error) {
      resetButton()
    }
  }, [error])

  const handleButtonClick = () => {
    if (!loading) {
      setLoading(true)
      setSuccess(false)
      if (!save()) {
        setLoading(false)
        setSuccess(false)
      }
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <Fab
          aria-label='save'
          color='primary'
          className={buttonClassname}
          onClick={handleButtonClick}
          disabled={disabled && !success && !loading}
          size='small'
        >
          {success ? <CheckIcon /> : <SaveIcon />}
        </Fab>
        {loading && <CircularProgress size={50} className={classes.fabProgress} />}
      </div>
      <CusomizedSnackbar state={snackState} handler={setSnackState} />
      <UnsavedChangesModal
        save={handleButtonClick}
        disabled={disabled}
        validPaths={validPaths}
        reset={reset}
      />
    </div>
  )
}

export { CircularLoadingButton }
