import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Card, CardContent, CardHeader } from '@material-ui/core'
import PieChart from './PieChart'
import LineChart from './LineChart'
import DateFormat from 'dateformat'
import { getFAQOverview, FAQExport } from 'api/reports'
import FullscreenIcon from '@material-ui/icons/Fullscreen'
import IconButton from '@material-ui/core/IconButton'
import { FilterBar, convertDateRange } from 'cf-components/FilterBar/FilterBar'
import { track } from 'userpilot.js'
import { getSessionDateRange } from 'cf-components/FilterBar/CustomDateRanges'

const useStyles = makeStyles(theme => ({
  dashboardContents: {
    padding: '5px 20px 20px 20px'
  },
  table: {
    fontFamily: 'Poppins, sans serif',
    color: '#4C4C4C'
  },
  tableHeader: {
    display: 'grid',
    gridTemplateColumns: 'repeat(6, 1fr)',
    backgroundColor: 'rgba(0,0,0,0.05)',
    fontSize: '15px',
    border: '1px solid rgb(224,224,224)',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10
  },
  tableBody: {
    display: 'grid',
    gridTemplateColumns: 'repeat(6, 1fr)',
    border: '1px solid rgb(224,224,224)',
    borderTop: 'none',
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10
  },
  headCell: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    padding: 8,
    borderRight: '1px solid rgb(224,224,224)'
  },
  bodyCell: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    padding: 10,
    fontSize: '20px',
    borderRight: '1px solid rgb(224,224,224)'
  },
  card: {
    height: 630,
    border: '1px solid rgba(142,90,226,0.5)'
  },
  cardContent: {
    height: 512,
    paddingTop: 30,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  cardHeader: {
    paddingBottom: 0
  },
  charts: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridGap: 20,
    marginTop: 20
  },
  graphContent: {
    width: '100%',
    marginBottom: 15
  }
}))

function downloadCSV (filename, data) {
  const blob = new Blob([data], { type: 'text/csv' })
  const elem = window.document.createElement('a')
  elem.href = window.URL.createObjectURL(blob)
  elem.download = filename
  document.body.appendChild(elem)
  elem.click()
  document.body.removeChild(elem)
}

function FAQPage () {
  const classes = useStyles()
  let startingDate = getSessionDateRange()
  startingDate = { ...startingDate, startDate: new Date(startingDate.startDate), endDate: new Date(startingDate.endDate) }
  const [dateRange, setDateRange] = useState(startingDate)
  const [dashboardData, setDashboardData] = useState(null)
  const [fullLineChart, setFullLineChart] = useState(false)
  const [fullPieChart, setFullPieChart] = useState(false)

  useEffect(() => {
    track('FAQ Report Viewed')
  })

  const categories = [
    'FAQ Prompts',
    'Questions Asked',
    'Answers Given',
    'Confirmed Resolutions',
    'Routed to Live Chat',
    'Unknown Outcome'
  ]

  const dataTypes = [
    'prompts',
    'questions',
    'responses',
    'resolutions',
    'routes',
    'unknown'
  ]

  const loaderStyles = {
    inlineSize: 'max-content',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 1000,
    height: 560,
    iconSize: 30
  }

  useEffect(() => {
    if (dateRange) {
      getFAQOverview({ attributes: convertDateRange(dateRange) }).then(response => {
        setDashboardData(response.data.attributes)
      })
    }
  }, [dateRange])

  function beginExport () {
    FAQExport({ attributes: convertDateRange(dateRange) }).then(response => {
      const date = new Date().toString()
      const filename = 'FAQ_Stats_' + DateFormat(date, 'isoDate') + '.csv'
      downloadCSV(filename, response)
      track('FAQ Export Downloaded')
    })
  }

  const filterOptions = {
    filterValues: [
      { value: 'Date', label: 'Filter by Date', toggle: false }
    ],
    dateRange: dateRange,
    setDateRange: setDateRange
  }

  return (
    <div>
      <FilterBar
        filterOptions={filterOptions}
        handleExport={beginExport}
      />
      {dashboardData && (
        <div className={classes.dashboardContents}>
          <div className={classes.table}>
            <div className={classes.tableHeader}>
              {
                categories.map((c, index) => (
                  <div key={index} className={classes.headCell} style={{ borderRight: index === 5 ? 'none' : null }}>
                    {c}
                  </div>
                ))
              }
            </div>
            <div className={classes.tableBody}>
              {dataTypes.map((d, index) => (
                <div key={index} className={classes.bodyCell} style={{ borderRight: index === 5 ? 'none' : null }}>
                  {dashboardData[d] || '-'}
                </div>
              ))}
            </div>
          </div>
          <div className={classes.charts}>
            <div>
              <Card className={classes.card}>
                <CardHeader
                  title='FAQ Answers Given'
                  subheader=''
                  className={classes.cardHeader}
                  action={
                    <IconButton onClick={() => setFullPieChart(true)}>
                      <FullscreenIcon />
                    </IconButton>
                  }
                />
                <CardContent className={classes.cardContent}>
                  <div className={classes.graphContent}>
                    <PieChart
                      loaderStyles={loaderStyles}
                      dateValues={dateRange}
                      fullScreen={fullPieChart}
                      exitFullScreen={() => setFullPieChart(false)}
                    />
                  </div>
                </CardContent>
              </Card>
            </div>
            <div>
              <Card className={classes.card}>
                <CardHeader
                  title='FAQs Over Time'
                  subheader=''
                  className={classes.cardHeader}
                  action={
                    <IconButton onClick={() => setFullLineChart(true)}>
                      <FullscreenIcon />
                    </IconButton>
                  }
                />
                <CardContent className={classes.cardContent}>
                  <div className={classes.graphContent}>
                    <LineChart
                      loaderStyles={loaderStyles}
                      dateValues={dateRange}
                      fullScreen={fullLineChart}
                      exitFullScreen={() => setFullLineChart(false)}
                    />
                  </div>
                </CardContent>
              </Card>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default FAQPage
