import { jsPlumbToolkit, Node, jsPlumbUtil, Surface } from 'jsplumbtoolkit'
import { Emitter, EVENT_TYPE } from './EventEmitter'
import { Toolkit } from './toolkit'

export interface selectionProps {
  type: string
  object?: any
  selection: Node[]
  nodeIDs?: string[]
  toolkit: Toolkit
}

interface nodePickerProps {
  source?: any
  startPort?: any
  left: number
  top: number
  event: any
  toolkit: Toolkit
}

export function dragEdgeToSpace ({ source, startPort, left, top, event, toolkit }: nodePickerProps): void {
  const clientTop = event.clientY
  const clientLeft = event.clientX
  const anchorDiv = document.createElement('div')
  anchorDiv.id = 'popover-anchor-div'
  anchorDiv.style.cssText = 'position:fixed; top: ' + clientTop + 'px; left: ' + clientLeft + 'px;'
  document.body.appendChild(anchorDiv)

  if (source) {
    const sourceNodeID = source.offsetParent.offsetParent.getAttribute('data-jtk-node-id')
    const portID = source.getAttribute('data-port-id')
    const pid = sourceNodeID + '.' + portID
    source = toolkit.getPort(pid)
  } else if (startPort) {
    source = startPort
  }

  Emitter.emit(EVENT_TYPE.DROP_EDGE, {
    toolkitID: toolkit.id,
    data: {
      anchorEl: anchorDiv,
      source,
      left,
      top
    }
  })
}

export function sendSelectionEvent ({ type, object, selection, toolkit }: selectionProps): void {
  Emitter.emit(EVENT_TYPE.NODE_SELECTION, {
    type: type,
    object: object,
    selection: selection,
    nodeIDs: selection.map(node => node.id),
    toolkit
  })
}

type selectionPayload = {
  kind: string
}

type selectionOptionProps = {
  payload: selectionPayload
  toolkit: jsPlumbToolkit
}

export function handleAddingFromEdge ({ payload, toolkit, surface }: any): void {
  const params = payload.params
  const startPort = params.edge.source
  const event = params.e
  toolkit.removeEdge(params.edge)
  const { left, top } = surface.mapEventLocation(event)
  setTimeout(() => {
    const element = document.elementFromPoint(event.clientX, event.clientY)
    if (element && element.className === 'jtk-surface') {
      dragEdgeToSpace({ startPort, left: left - 100, top: top - 50, event, toolkit })
    }
  }, 10)
}

export function handleSelectionOption ({ payload, toolkit }: selectionOptionProps): void {
  const kind = payload.kind
  switch (kind) {
    case 'deleteSelection':
      toolkit.removeFromSelection(toolkit.getNode('start'))
      toolkit.removeFromSelection(toolkit.getNode('exit'))
      toolkit.remove(toolkit.getSelection())
      sendSelectionEvent({ type: 'clear', selection: [], toolkit })
      break
    case 'cloneSelection':
      cloneSelection(toolkit)
      break
    case 'selectDescendents': {
      const node = toolkit.getSelection().getNodes()[0]
      selectDescendents(node, toolkit, [node.id])
      sendSelectionEvent({ type: 'select', selection: toolkit.getSelection().getNodes(), toolkit })
      break
    }
  }
}

function selectDescendents (node: Node, toolkit: jsPlumbToolkit, nodeIDs: string[]): void {
  if (node.objectType === 'Node') {
    const ports = node.getPorts()
    for (const port of ports) {
      const edges = port.getSourceEdges()
      for (const edge of edges) {
        const n: any = edge.target
        if (!nodeIDs.includes(n.id)) {
          nodeIDs.push(n.id)
          toolkit.addToSelection(n)
          selectDescendents(n, toolkit, nodeIDs)
        }
      }
    }
  }
}

function cloneSelection (toolkit: jsPlumbToolkit): void {
  toolkit.removeFromSelection(toolkit.getNode('start'))
  toolkit.removeFromSelection(toolkit.getNode('exit'))
  const nodes = toolkit.getSelection().getNodes()
  const nodeIDs = nodes.map(n => n.id)
  const newNodes = []
  const nodeMapping: Record<string, string> = {}
  for (const node of nodes) {
    const data = { ...node.data }
    data.left = data.left + 200
    data.top = data.top + 70
    const newID = jsPlumbUtil.uuid()
    data.id = newID
    data.isCopy = true
    const newNode = toolkit.addNode(data)
    nodeMapping[node.id] = newID
    newNodes.push(newNode)
  }
  for (const n of nodes) {
    const ports = n.getPorts()
    for (const port of ports) {
      const edges = port.getSourceEdges()
      for (const edge of edges) {
        const targetNode = edge.target
        const newNode = toolkit.getNode(nodeMapping[n.id])
        const source = newNode.getPort(port.id)
        if (nodeIDs.includes(targetNode.id)) {
          const target = nodeMapping[targetNode.id]
          const data = {
            id: jsPlumbUtil.uuid(),
            type: 'common'
          }
          toolkit.addEdge({ source, target, data })
        }
      }
    }
  }
  toolkit.clearSelection()
  toolkit.setSelection(newNodes)
  sendSelectionEvent({ type: 'select', selection: newNodes, toolkit })
}

export function pasteSelection (surface: Surface): void {
  const toolkit = surface.getToolkit()
  const nodes = (window as any).copiedNodes
  if (!nodes) {
    return
  }

  const pan = surface.getViewportCenter()
  let baseX, baseY

  const nodeIDs = nodes.map((n: Node) => n.id)
  const newNodes = []
  const nodeMapping: Record<number, any> = {}

  for (const node of nodes) {
    const data = { ...node.data }
    if (!baseX) {
      baseX = pan[0] - node.data.left
      baseY = pan[1] - node.data.top
    }
    data.left = node.data.left + baseX
    data.top = node.data.top + baseY
    const newID = jsPlumbUtil.uuid()
    data.id = newID
    data.isCopy = true
    const newNode = toolkit.addNode(data)
    nodeMapping[node.id] = newID
    newNodes.push(newNode)
  }

  for (const n of nodes) {
    const ports = n.getPorts()
    for (const port of ports) {
      const edges = port.getSourceEdges()
      for (const edge of edges) {
        const targetNode = edge.target
        const newNode = toolkit.getNode(nodeMapping[n.id])
        const source = newNode.getPort(port.id)
        if (nodeIDs.includes(targetNode.id)) {
          const target = nodeMapping[targetNode.id]
          const data = {
            id: jsPlumbUtil.uuid(),
            type: 'common'
          }
          toolkit.addEdge({ source, target, data })
        }
      }
    }
  }

  toolkit.clearSelection()
  toolkit.setSelection(newNodes)
}
