import { makeStyles } from '@material-ui/core/styles';
import { Icon } from 'library/materialUI';
import { useContext } from 'react';
import { TableContext } from './TableProvider';
import { PopoverDiv } from 'pages/plays/playBuilder/nodes/PopoverDiv'

const useStyles = makeStyles((theme) => ({
  iconDiv: {
    cursor: 'pointer',
    display: 'flex'
  },
  container: {
    '&:hover': { backgroundColor: '#F5F5F5' }
  }
}))

interface CSVDownloadProps {
  subMenuContents?: any
}

export default function CSVDownload ({ subMenuContents }: CSVDownloadProps) {
  const { handleCSVDownload } = useContext(TableContext)
  const classes = useStyles()
  return (
    <div className={classes.iconDiv}>
      {subMenuContents ? (
        <PopoverDiv
          popoverContents={
            <div>
              {subMenuContents}
            </div>
          }
          divContents={
            <div className={classes.container} style={{ display: 'flex', flexDirection: 'row', cursor: 'pointer', margin: '5px', width: '116px', height: '40px', alignItems: 'center', justifyContent: 'center', border: '1px solid #D3D3D3', borderRadius: '5px' }}>
              <Icon
                icon='outlinedCloud'
              />
              <div style={{ fontFamily: 'poppins', fontSize: '14px', marginLeft: '8px' }}>Export</div>
            </div>
          }
        />
      ) : (
        <div className={classes.iconDiv}>
          <div className={classes.container} onClick={() => handleCSVDownload()} style={{ display: 'flex', flexDirection: 'row', cursor: 'pointer', margin: '5px', width: '116px', height: '40px', alignItems: 'center', justifyContent: 'center', border: '1px solid #D3D3D3', borderRadius: '5px' }}>
            <Icon
              icon='outlinedCloud'
            />
            <div style={{ fontFamily: 'poppins', font: '14px', marginLeft: '8px' }}>Export</div>
          </div>
        </div>
      )}
    </div>
  )
}
