import { track } from 'userpilot.js'

const headers = {
  'Content-Type': 'application/vnd.api+json'
}

function replySearch ({ search }) {
  const getUrl = window.location
  const baseUrl = getUrl.protocol + '//' + getUrl.host + '/' + getUrl.pathname.split('/')[1]

  const url = new URL(baseUrl + 'api/saved_replies:search')
  const params = {}
  if (search) {
    params['filter[search]'] = search
  }

  Object.keys(params).forEach(key =>
    url.searchParams.append(key, params[key])
  )

  return fetch(url, {
    method: 'GET',
    headers: headers
  })
    .then(response => response.json())
}

function saveReply ({ title, content, categories, visibility, id }) {
  let method = 'POST'
  let path = '/api/saved_replies'

  if (id != null) {
    method = 'PATCH'
    path = '/api/saved_replies/' + id
  }

  const body = {
    data: {
      type: 'saved_replies',
      attributes: {
        title: title,
        content: content,
        categories: categories,
        visibility: visibility
      },
      relationships: {}
    }
  }
  track('Saved Reply Created')
  return fetch(path, {
    method: method,
    headers: headers,
    body: JSON.stringify(body)
  })
    .then(response => response.json())
}

function getReplies () {
  const request = fetch('/api/saved_replies', {
    method: 'GET',
    headers: headers
  })
    .then(response => response.json())
  return request
}

function deleteReplies ({ replyIDs }) {
  const method = 'DELETE'
  const path = '/api/saved_replies'
  const body = JSON.stringify({
    data: {
      attributes: {
        reply_ids: replyIDs
      }
    }
  })
  return fetch(path, {
    method: method,
    headers: headers,
    body: body
  })
}

function deleteReply ({ replyID }) {
  const method = 'DELETE'
  const path = '/api/saved_replies/' + replyID

  const body = {
    data: {
      type: 'saved_replies',
      id: replyID
    }
  }

  return fetch(path, {
    method: method,
    headers: headers,
    body: JSON.stringify(body)
  })
    .then(response => response.json())
}

function updatePriorities ({ priorities }) {
  const method = 'POST'
  const path = '/api/saved_reply_priority'
  const body = {
    data: {
      type: 'saved_reply_priorities',
      attributes: {
        priorities: priorities
      }
    }
  }
  return fetch(path, {
    method: method,
    headers: headers,
    body: JSON.stringify(body)
  })
    .then(response => response.json())
}

export { saveReply, getReplies, deleteReply, deleteReplies, replySearch, updatePriorities }
