import { doPost, doGet } from './api'

const getContentPagesByBotId = ({ botID }) => {
  return doGet({
    useChatURL: true,
    path: `/content_pages?filter[bot_id]=${botID}`
  })
}

const logAttachment = ({ file, content_type }) => {
  return doPost({
    path: '/content_pages/attachments',
    useChatURL: true,
    data: {
      type: 'content_landing_attachments',
      attributes: {
        file_name: file.name,
        size: file.size,
        media_type: file.type,
        content_type
      }
    }
  })
}

export { logAttachment, getContentPagesByBotId }
