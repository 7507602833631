import { makeStyles } from '@material-ui/core/styles'
import { ReactNode } from 'react'

const useStyles = makeStyles(theme => ({
  title: {
    color: '#9933FF',
    font: 'normal normal bold 20px/30px Poppins',
    marginBottom: 15
  },
  subtitle: {
    color: '#00000080',
    font: 'normal normal normal 14px/21px Poppins',
    marginBottom: 15
  },
  wizardSection: {
    width: 450,
    padding: '15px 35px',
    borderBottom: '1px solid #00000026'
  }
}))

type WizardTitleProps = {
  title: string
  subtitle: string
}

function WizardTitle ({ title, subtitle }: WizardTitleProps) {
  const classes = useStyles()

  return (
    <div>
      <div className={classes.title}>
        {title}
      </div>
      <div className={classes.subtitle}>
        {subtitle}
      </div>
    </div>
  )
}

type WizardSectionProps = {
  title: string
  subtitle: string
  children: ReactNode
}

export function WizardSection (props: WizardSectionProps) {
  const classes = useStyles()

  return (
    <div className={classes.wizardSection}>
      <WizardTitle
        title={props.title}
        subtitle={props.subtitle}
      />
      {props.children}
    </div>
  )
}
