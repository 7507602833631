import { InputAdornment } from '@material-ui/core';
import { TextFieldProps } from '@material-ui/core/TextField';
import { Icon, IconButton, TextField } from 'library/materialUI';
import { useState } from 'react';

export default function PasswordTextField ({ value, label, variant, onChange, fullWidth, size, classes } : TextFieldProps) {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  return (
    <TextField
      label={label}
      variant={variant}
      value={value}
      type={showPassword ? 'text' : 'password'}
      onChange={onChange}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            <IconButton
              aria-label='toggle password visibility'
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {showPassword ? <Icon icon='showPassword' /> : <Icon icon='hidePassword' />}
            </IconButton>
          </InputAdornment>
        )
      }}
      fullWidth={fullWidth}
      size={size}
      classes={classes}
    />
  )
}
