
const descriptionText = 'Use this bot template to create a standard homepage bot to interact with first time visitors who come to the homepage of your website.'

const Description = (): JSX.Element => {
  return (
    <div>
      <div>
        {descriptionText}
      </div>
      <ul>
        <li>Asks to show the user a demo. Default responses:</li>
        <ul>
          <li>Yes please!</li>
          <ul>
            <li>Asks qualifying questions to determine fit</li>
            <li>If the visitor is a good fit for your company, the bot sends the visitor a calendar invite</li>
          </ul>
          <li>I'd like to chat with a human first</li>
          <ul>
            <li>Attempts to route the visitor to live chat</li>
            <li>If no agents are available, attempts to book a meeting</li>
          </ul>
        </ul>
      </ul>
    </div>
  )
}

const bestPracticeText = 'Verify that your agents have configured their calendars in Signals, so all agents can book meetings. Also, be sure to customize your qualifying questions and responses within the bot flow.'

const BestPractices = () => {
  return (
    <div>
      <div>
        {bestPracticeText}
      </div>
      <ul>
        <li><a href='#/settings/my_settings/my_calendar' target='_blank'>Calendar Settings</a></li>
      </ul>
    </div>
  )
}

export const homepageBotDocs = {
  helpCenterLink: '',
  bestPractices: BestPractices,
  description: Description,
  videoLink: ''
}
