import { useEffect, useState, useReducer } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Chip, Switch,
  Breadcrumbs,
  Typography,
  Button,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox
} from '@material-ui/core'
import CustomizedSnackbar from 'components/CustomizedSnackbar'
import DelayDropDown from './DelayDropDown'
import { getReducer } from './Reducers/integrationReducer'
import IntegrationMapping from './IntegrationMapping'
import { Link } from 'react-router-dom'
import {
  INTEGRATIONS,
  getIntegration,
  updateIntegration,
  addIntegrationSetting,
  getCredentialsStatus
} from 'api/integrations'
import { getExternalFields } from 'api/eloqua'
import EloquaLogo from 'img/eloqua.png'
import EloquaOAuth from './OAuth/EloquaOAuth'
import EmailDomainFilterSelector from './EmailDomainFilterSelector'
import LoadingScreen from 'cf-components/LoadingScreen'
import EloquaCustomObjectMapper from './Eloqua/EloquaCustomObjectMapper'
import { setCRMContactIntegrationMapping } from 'api/crm_contacts'
import Divider from '@material-ui/core/Divider'
import { Icon } from 'library/materialUI'

const useStyles = makeStyles((theme) => ({
  headerImage: {
    height: 65,
    marginBottom: 30
  },
  menuHeader: {
    borderBottom: '1px solid #BBB',
    width: '100%',
    fontWeight: 600,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: 'rgba(0,0,0,0.87)'
  },
  disabledHeaderImage: {
    height: 65,
    marginBottom: 30,
    filter: 'grayscale(100%)'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  manualToggle: {
    marginRight: 40,
    marginTop: 20
  },
  wrapIcon: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 15
  },
  textField: {
    cursor: 'text',
    width: '100%',
    marginBottom: 25,
    marginRight: 10
  },
  container: {
    padding: 65,
    minWidth: 920
  },
  bodyText: {
    marginBottom: 25,
    fontSize: 16
  },
  subtitle1: {
    fontSize: 18,
    fontWeight: 600,
    marginBottom: 15
  },
  subtitle2: {
    fontSize: 15,
    fontWeight: 600,
    marginBottom: 10
  },
  groupContainer: {
    backgroundColor: 'rgba(193, 166, 237, 0.1)',
    padding: 30,
    borderRadius: 10,
    marginBottom: 20
  },
  apiKeyField: {
    display: 'flex'
  },
  visibilityButton: {
    height: 56,
    marginTop: -1
  },
  expandingCard: {
    backgroundColor: 'rgba(193, 166, 237, 0.1)',
    borderRadius: 10,
    marginBottom: 20
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  },
  cardHeader: {
    widht: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: 10
  },
  headerTitle: {
    paddingLeft: 20,
    fontSize: 24,
    fontWeight: 'bold',
    paddingTop: 10
  },
  connectedIcon: {
    color: 'rgba(0, 135, 7, 0.7)'
  },
  disconnectedIcon: {
    color: 'rgba(255, 188, 3)'
  },
  crumb: {
    fontSize: '.8em'
  },
  typography: {
    fontSize: '1.05em'
  },
  breadcrumbs: {
    position: 'absolute',
    top: 5
  },
  crumbWrapper: {
    padding: 10
  },
  eventSelect: {
    width: 250,
    height: 40,
    marginBottom: 25
  },
  dottedLine: {
    borderTop: '2px dotted #975ce6',
    height: 10,
    marginTop: 20,
    width: '100%'
  },
  eventSettingRow: {
    display: 'flex',
    alignContent: 'center'
  },
  colorPrimary: {
    color: theme.palette.success.main
  },
  greenTrackColor: {
    backgroundColor: `${theme.palette.success.main} !important`
  }
}))

const initialValues = {
  type: 'integrations',
  attributes: {
    integration_name: INTEGRATIONS.Eloqua,
    enabled: false,
    config: { delay_seconds: 0, contact_custom_object_id: '' },
    mappings: {
      mapping: [
        {
          type: 'cf_field',
          data_type: 'str',
          overwrite: false,
          object_type: 'ContactField',
          external_field: '100002',
          internal_field: 'first_name'
        },
        {
          type: 'cf_field',
          data_type: 'str',
          overwrite: false,
          object_type: 'ContactField',
          external_field: '100003',
          internal_field: 'last_name'
        },
        {
          type: 'cf_field',
          data_type: 'str',
          overwrite: false,
          object_type: 'ContactField',
          external_field: '100001',
          internal_field: 'email'
        }
      ]
    },
    event_settings: {
      conversations: {
        enabled: false,
        external_field: '',
        object_type: ''
      },
      meetings_booked: {
        enabled: false,
        external_field: '',
        object_type: ''
      }
    }
  }
}

const allEventSettings = [
  { key: 'conversations', name: 'Conversations' },
  { key: 'meetings_booked', name: 'Meetings Booked' }
]

const EventSettingsSelector = props => {
  const classes = useStyles()
  const eventSetting = props.eventSetting
  const state = props.state
  const dispatch = props.dispatch
  const externalContactMapping = props.externalContactMapping?.attributes?.lead?.fields || []

  const checked = state.attributes.event_settings?.[eventSetting.key]?.enabled
  const eloquaField = state.attributes.event_settings?.[eventSetting.key]?.external_field

  const handleEventCheckbox = (value) => {
    const currentEventSettings = state.attributes.event_settings?.[eventSetting.key] || {}
    const newSettings = { ...currentEventSettings }
    newSettings.enabled = value
    dispatch({ type: 'eventSettings', name: eventSetting.key, value: newSettings })
  }

  const handleEventSelect = ({ value }) => {
    const selectedMapping = externalContactMapping.filter(ecm => ecm.api_name === value)?.[0]

    if (!selectedMapping) {
      return
    }

    const currentEventSettings = state.attributes.event_settings?.[eventSetting.key] || {}
    const newSettings = { ...currentEventSettings }
    newSettings.external_field = selectedMapping.api_name
    newSettings.object_type = selectedMapping.object_type

    dispatch({ type: 'eventSettings', name: eventSetting.key, value: newSettings })
  }

  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            color='primary'
            checked={checked}
            onChange={(e) =>
              handleEventCheckbox(e.target.checked)}
          />
        }
        label={eventSetting.name}
      />
      {checked
        ? (
          <div>
            <Select
              name='event_select'
              variant='outlined'
              margin='dense'
              defaultValue='preview'
              className={classes.eventSelect}
              value={eloquaField}
              onChange={(e) =>
                handleEventSelect({ value: e.target.value })}
            >
              <MenuItem disabled style={{ opacity: 1 }}>
                <div className={classes.menuHeader}>Eloqua Contact Field</div>
              </MenuItem>
              {externalContactMapping.map((row, index) => (
                <MenuItem key={index} value={row.api_name}>
                  {row.label}
                </MenuItem>
              ))}
            </Select>
          </div>
        ) : <></>}
    </>
  )
}

const EloquaIntegrationPage = props => {
  const classes = useStyles()
  const [hasEloquaConnection, setHasEloquaConnection] = useState(null)
  const [externalContactMapping, setExternalContactMapping] = useState()
  const [loading, setLoading] = useState()
  // const [externalAccountMapping, setExternalAccountMapping] = useState()
  // const [integrationSettings, setIntegrationSettings] = useState(initialValues)
  const [state, dispatch] = useReducer(getReducer(), null)
  const [snackState, setSnackState] = useState({
    open: false,
    variant: 'success',
    message: 'Your changes have been saved'
  })

  const toggleIntegration = async (checked) => {
    const newState = { ...state }
    newState.attributes.enabled = checked
    save(newState)
  }

  const save = (integrationState) => {
    if (!integrationState?.id) {
      integrationState = state
    }

    updateIntegration({ integration: integrationState })
      .then((response) => {
        dispatch({ type: 'initialize', data: response.data })
        setSnackState({
          open: true,
          variant: 'success',
          message: 'Your changes have been saved'
        })
      })
    setCRMContactIntegrationMapping(INTEGRATIONS.Eloqua, state.attributes.mappings.mapping)
  }

  useEffect(() => {
    getCredentialsStatus(INTEGRATIONS.Eloqua)
      .then((response) => {
        setHasEloquaConnection(response.data.attributes.has_credentials)
      })
  }, [])

  useEffect(() => {
    if (hasEloquaConnection) {
      setLoading(true)
      getExternalFields('contact').then(response => {
        if (response.data) {
          setLoading(false)
          setExternalContactMapping({ attributes: { lead: response.data.attributes } })
        }
      })
      getIntegration(INTEGRATIONS.Eloqua).then(response => {
        if (response.data) {
          dispatch({ type: 'initialize', data: response.data })
        } else {
          dispatch({ type: 'initialize', data: initialValues })
          addIntegrationSetting({ integration: initialValues }).then(response => {
            dispatch({ type: 'initialize', data: response.data })
          })
        }
      })
    }
  }, [hasEloquaConnection])

  if (loading) {
    return (
      <LoadingScreen loading={loading} />
    )
  }

  if (hasEloquaConnection === false) {
    return (
      <>
        <Breadcrumbs aria-label='breadcrumb' classes={{ li: classes.crumb, ol: classes.crumbWrapper }}>
          <Link style={{ color: '#7A7A7A' }} to='/settings/integrations'>
            Integrations Page
          </Link>
          <Typography color='textPrimary' classes={{ root: classes.typography }}>Eloqua Integration Page</Typography>
        </Breadcrumbs>
        <div className={classes.container}>
          <div className={classes.header}>
            <img
              alt='Eloqua Logo'
              src={EloquaLogo}
              className={classes.headerImage}
            />
          </div>
          <EloquaOAuth
            name='Eloqua Integration OAuth'
            oauth_start_url='/api/eloqua/oauth'
          />
        </div>
      </>
    )
  }

  if (state === null) {
    return (
      <>
        Loading
      </>
    )
  }

  return (
    <>
      <Breadcrumbs aria-label='breadcrumb' classes={{ li: classes.crumb, ol: classes.crumbWrapper }}>
        <Link style={{ color: '#7A7A7A' }} to='/settings/integrations'>
          Integrations Page
        </Link>
        <Typography color='textPrimary' classes={{ root: classes.typography }}>Eloqua Integration Page</Typography>
      </Breadcrumbs>
      <div className={classes.container}>
        <div className={classes.header}>
          <img
            alt='Eloqua Logo'
            src={EloquaLogo}
            className={
              state.attributes.enabled
                ? classes.headerImage
                : classes.disabledHeaderImage
            }
          />
          {state ? (
            <div className={classes.manualToggle}>
              <Chip
                label={
                  state.attributes.enabled
                    ? 'Integration: On'
                    : 'Integration: Off'
                }
                color={
                  state.attributes.enabled ? 'primary' : 'default'
                }
                className={classes.chip}
              />
              <Switch
                checked={state.attributes.enabled}
                onChange={(e) => toggleIntegration(e.target.checked)}
                name='master_toggle'
                color={state.attributes.enabled ? 'primary' : 'secondary'}
                classes={{
                  thumb: state.attributes.enabled ? classes.colorPrimary : '',
                  track: state.attributes.enabled ? classes.greenTrackColor : ''
                }}
              />
            </div>
          ) : (
            <></>
          )}
          <Button
            startIcon={<Icon icon='save' />}
            onClick={() => save(state)}
            color='#9933FF'
          >
            Save
          </Button>
        </div>
        <div className={classes.groupContainer}>
          <Typography variant='subtitle1' className={classes.subtitle1}>
            Synchronization Settings
          </Typography>
          <Typography variant='body2' className={classes.bodyText}>
            Select the amount of time you want to delay after the conversation closes before synchronizing the data.
          </Typography>
          <Typography variant='subtitle2' className={classes.subtitle2}>
            Delay time:
          </Typography>
          <DelayDropDown
            handleEdit={(e) =>
              dispatch({ type: 'config', name: 'delay_seconds', value: e.target.value })}
            value={
              state?.attributes?.config?.delay_seconds || 0
            }
          />
          <br />
          <Typography variant='subtitle2' className={classes.subtitle2}>
            Domain Filtering
          </Typography>
          <EmailDomainFilterSelector
            // handleEdit={(e) => console.log(e)}
            filters={state?.attributes?.config?.domain_filters}
            handleEdit={(e) => dispatch({ type: 'config', name: 'domain_filters', value: e.target.value })}
            resetFilterList={(e) => dispatch({ type: 'config', name: 'domain_filters', value: [] })}
          />
        </div>
        {/* <div className={classes.groupContainer}>
          <Typography variant='subtitle1' className={classes.subtitle1}>
            Contact Event Settings
          </Typography>
          <Typography variant='body2' className={classes.bodyText} style={{ marginBottom: 5 }}>
            Sync the following Signals events to a Contact Field in Eloqua. Select which contact fields in Eloqua you want to map the following events to.
          </Typography>
          <Typography variant='body2' className={classes.bodyText}>
            <b>Important: </b>The field in Eloqua must be of type 'Large Text box' in order for the events to sync correctly.
          </Typography>
          {allEventSettings.map((eventSetting, index) => (
            <div key={index}>
              <EventSettingsSelector
                dispatch={dispatch}
                externalContactMapping={externalContactMapping}
                eventSetting={eventSetting}
                state={state}
              />
            </div>
          ))}
        </div> */}
        <div className={classes.groupContainer}>
          <IntegrationMapping
            currentMapping={
              state.attributes.mappings
                .mapping
            }
            forObject='people'
            integrationSettings={state}
            externalMappingFields={externalContactMapping}
            integrationName='Eloqua'
            title='Contact Field Mapping'
            handleEdit={(data) => {
              dispatch({ type: 'mappings', name: 'mapping', value: data })
            }}
          />
          <br />
          <br />
          <Typography variant='subtitle1' className={classes.subtitle1}>
            Contact Event Settings
          </Typography>
          <Typography variant='body2' className={classes.bodyText} style={{ marginBottom: 5 }}>
            Sync the following Signals events to a Contact Field in Eloqua. Select which contact fields in Eloqua you want to map the following events to.
          </Typography>
          <Typography variant='body2' className={classes.bodyText}>
            <b>Important: </b>The field in Eloqua must be of type 'Large Text box' in order for the events to sync correctly.
          </Typography>
          {allEventSettings.map((eventSetting, index) => (
            <div key={index}>
              <EventSettingsSelector
                dispatch={dispatch}
                externalContactMapping={externalContactMapping}
                eventSetting={eventSetting}
                state={state}
              />
            </div>
          ))}
        </div>
        <div className={classes.groupContainer}>
          <Typography variant='subtitle1' className={classes.subtitle1}>
            Custom Objects
          </Typography>
          <Typography variant='body2' className={classes.bodyText} style={{ marginBottom: 5 }}>
            If the same custom object is used for both organic and prospected contacts the mapping will be the same for both
          </Typography>
          <Typography variant='subtitle2' className={classes.subtitle2}>
            Organic Contact Custom Object
          </Typography>
          <Typography variant='body2' className={classes.bodyText} style={{ marginBottom: 5 }}>
            Sync Signals contact information to your Eloqua Custom Fields! Select which custom object in Eloqua you want to map the following fields to.
          </Typography>
          <EloquaCustomObjectMapper
            id={state?.attributes?.config?.organic_object}
            state={state}
            handleAdd={(data) => {
              dispatch({ type: 'mappingAdd', name: 'custom_object_mappings', value: data })
            }}
            handleDelete={(data) => {
              dispatch({ type: 'mappingDelete', name: 'custom_object_mappings', value: data })
            }}
            handleEdit={(data) => {
              dispatch({ type: 'mappingEdit', name: 'custom_object_mappings', value: data })
            }}
            handleConfig={(data) => {
              dispatch({ type: 'config', name: 'organic_object', value: data })
            }}
          />
          <Divider />
          <Typography variant='subtitle2' className={classes.subtitle2}>
            Prospected Contact Custom Object
          </Typography>
          <Typography variant='body2' className={classes.bodyText} style={{ marginBottom: 5 }}>
            Sync prospected Signals contact information to your Eloqua Custom Fields!
          </Typography>
          <EloquaCustomObjectMapper
            id={state?.attributes?.config?.prospect_object}
            state={state}
            handleAdd={(data) => {
              dispatch({ type: 'mappingAdd', name: 'custom_object_mappings', value: data })
            }}
            handleDelete={(data) => {
              dispatch({ type: 'mappingDelete', name: 'custom_object_mappings', value: data })
            }}
            handleEdit={(data) => {
              dispatch({ type: 'mappingEdit', name: 'custom_object_mappings', value: data })
            }}
            handleConfig={(data) => {
              dispatch({ type: 'config', name: 'prospect_object', value: data })
            }}
            disableEvents
          />
        </div>
        <Button
          className={classes.actionButton}
          color='primary'
          onClick={save}
          variant='contained'
        >
          Save
        </Button>
      </div>
      <CustomizedSnackbar state={snackState} handler={setSnackState} />
    </>
  )
}

export default EloquaIntegrationPage
