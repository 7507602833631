import { Select } from 'cf-components/material-wrappers/MaterialComponents';
import { formatRevenue } from 'helpers/numbers';
import { Typography } from 'library/materialUI';
import { useEffect, useState } from 'react';

const revenueOptions = [
  { label: '50K', value: 50000 },
  { label: '100K', value: 100000 },
  { label: '250K', value: 250000 },
  { label: '500K', value: 500000 },
  { label: '750K', value: 750000 },
  { label: '1M', value: 1000000 },
  { label: '2M', value: 2000000 },
  { label: '3M', value: 3000000 },
  { label: '4M', value: 4000000 },
  { label: '5M', value: 5000000 },
  { label: '10M', value: 10000000 },
  { label: '25M', value: 25000000 },
  { label: '50M', value: 50000000 },
  { label: '100M', value: 100000000 },
  { label: '250M', value: 250000000 },
  { label: '500M', value: 500000000 },
  { label: '1B', value: 1000000000 },
  { label: '5B', value: 5000000000 },
  { label: '10B', value: 10000000000 },
  { label: '100B', value: 100000000000 },
  { label: '500B', value: 500000000000 },
  { label: '1T', value: 1000000000000 }
]

type lowAndHighArray = [number, number]

interface RevenueSliderProps {
  setSelection: (array: lowAndHighArray) => void
  currentSelection: [string, string] | [string]
  noLabels?: boolean
  allowZero?: boolean
}

export default function RevenueSlider ({ setSelection, currentSelection, noLabels, allowZero }: RevenueSliderProps) {
  const startingValue: lowAndHighArray = currentSelection.length === 2 ? [Number(currentSelection[0]), Number(currentSelection[1])] : (allowZero ? [0, 0] : [10000000, 500000000])
  const [revenueLabel, setRevenueLabel] = useState(`${formatRevenue(startingValue[0])} - ${formatRevenue(startingValue[1])}`)
  const [value, setValue] = useState<lowAndHighArray>(startingValue)
  const [revenue1InFocus, setRevenue1InFocus] = useState(false)
  const [revenue2InFocus, setRevenue2InFocus] = useState(false)

  const handleChange = (newValue: number, index: number) => {
    if (newValue < 50000) {
      newValue = 50000
    } else if (newValue > 100000000000000) {
      newValue = 100000000000000
    }
    if (index === 0) {
      setValue([newValue, value[1]])
    } else {
      setValue([value[0], newValue])
    }
  }

  useEffect(() => {
    if (value[0] && value[1] && (value[0].toString() !== currentSelection[0] || value[1].toString() !== currentSelection[1])) {
      setSelection(value)
    }
    setRevenueLabel(`${formatRevenue(value[0])} - ${formatRevenue(value[1])}`)
    // eslint-disable-next-line
  }, [value])

  useEffect(() => {
    if (!currentSelection.length && allowZero) setValue([0, 0])
    // eslint-disable-next-line
  }, [currentSelection])

  return (
    <div>
      {!noLabels && revenueLabel}
      <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        <Select
          freeSolo
          inputType='text'
          onChange={(e: string) => handleChange(e, 0)}
          onBlur={(e: any) => {
            handleChange(e.target.value, 0)
            setRevenue1InFocus(false)
          }}
          onFocus={() => setRevenue1InFocus(true)}
          onKeyPress={(e: any) => { if (!/[0-9]/.test(e.key)) e.preventDefault(); }}
          value={revenue1InFocus ? String(value[0]) : value[0].toLocaleString()}
          options={revenueOptions}
          disableClear
          key='revenue1'
          filterOptions={false}
          width='100%'
        />
        <Typography style={{ paddingLeft: 10, paddingRight: 10 }}>to</Typography>
        <Select
          freeSolo
          inputType='text'
          onChange={(e: string) => handleChange(e, 1)}
          onBlur={(e: any) => {
            handleChange(e.target.value, 1)
            setRevenue2InFocus(false)
          }}
          onFocus={() => setRevenue2InFocus(true)}
          onKeyPress={(e: any) => { if (!/[0-9]/.test(e.key)) e.preventDefault(); }}
          value={revenue2InFocus ? String(value[1]) : value[1].toLocaleString()}
          options={revenueOptions}
          disableClear
          key='revenue2'
          filterOptions={false}
          width='100%'
        />
      </div>
    </div>
  )
}
