// import Dialog from '@material-ui/core/Dialog'
import { useReducer, useEffect } from 'react'
import { Modal, ModalSection } from 'library/Modal';
import CriteriaBuilder from 'library/CriteriaBuilder'
import { africa, americas, asia, europe, oceania, provinces, states } from 'pages/bot/criteria/GeoLocations'

interface GlobalRoutingRulesModalProps {
  ruleID: string
  ruleName: string
  state: any
  dispatch: (value: any) => any
  open: boolean
  setOpen: (value: boolean) => void
  setCanSave: (value: boolean) => void
}

interface routingAction {
  type: string
  ruleName?: string
  id?: string
  groupIndex?: number
  filterIndex?: number
  value: any
}

export const routingReducer = (state: any, action: routingAction) => {
  const newState = { ...state }
  const ruleName = action.ruleName
  switch (action.type) {
    // Outer rules functions:
    case 'set':
      return action.value
    case 'setRule':
      newState.rules.forEach((rule: any, idx: number) => {
        if (rule.id === action.id) {
          newState.rules[idx].name = action.value.name
          newState.rules[idx].groups = [...action.value.groups]
        }
      })
      return newState
    // Two cases per merging the two reducers
    case 'replace':
    case 'setRules':
      newState.rules = action.value
      return newState
    case 'ruleFilters':
      newState.rules.forEach((rule: any) => {
        if (rule.name === ruleName) {
          rule.groups = action.value
        }
      })
      return newState
    case 'ruleMethod':
      newState.rules.forEach((rule: any) => {
        if (rule.name === ruleName) {
          rule.method = action.value
        }
      })
      return newState
    case 'ruleObjectIDs':
      newState.rules.forEach((rule: any) => {
        if (rule.name === ruleName) {
          rule.objectIDs = action.value
        }
      })
      return newState
    case 'globalFallback':
      newState.globalFallback = action.value
      return newState
    case 'globalFallbackMethod':
      newState.globalFallback.method = action.value
      return newState
    case 'globalFallbackObjectIDs':
      newState.globalFallback.objectIDs = action.value
      return newState
    default:
      return state
  }
}

interface localAction {
  type: string
  id?: string
  groupIndex?: number
  filterIndex?: number
  value: any
}

function localReducer (state: any, action: localAction) {
  const newState = { ...state }
  switch (action.type) {
    case 'set':
      return action.value
    case 'criteriaName':
      newState.name = action.value
      return newState
    case 'ruleFilters':
      newState.groups = action.value
      return newState
    case 'addGroup':
      if (newState.groups && newState.groups.length > 0) {
        newState.groups.push(
          {
            name: 'Filter Group ' + (newState.groups.length + 1),
            filters: [{ prop: '', cmp: '', value: '' }]
          })
      } else {
        newState.groups = [{ name: 'Filter Group 1', filters: [{ prop: '', cmp: '', value: '' }] }]
      }
      return newState
    case 'addFilter':
      if (!newState.groups[action?.groupIndex || 0].filters) {
        newState.groups[action?.groupIndex || 0].filters = []
      }
      newState.groups[action?.groupIndex || 0].filters.push({ prop: '', cmp: '', value: '' })
      return newState
    case 'deleteGroup':
      newState.groups.splice(action.groupIndex, 1)
      return newState
    case 'deleteFilter':
      newState.groups[action?.groupIndex || 0].filters.splice(action.filterIndex, 1)
      return newState
    case 'prop':
      newState.groups[action?.groupIndex || 0].filters[action?.filterIndex || 0].prop = action.value
      return newState
    case 'cmp':
      newState.groups[action?.groupIndex || 0].filters[action?.filterIndex || 0].cmp = action.value
      return newState
    case 'val':
      newState.groups[action?.groupIndex || 0].filters[action?.filterIndex || 0].value = action.value
      return newState
    // Inner filter functions
    case 'name':
      newState.groups[action?.groupIndex || 0].name = action.value
      return newState
    default:
      return state
  }
}

export default function GlobalRoutingRulesModal ({ ruleID, ruleName, state, dispatch, open, setOpen, setCanSave }: GlobalRoutingRulesModalProps) {
  const [localState, localDispatch] = useReducer(localReducer, { name: ruleName, groups: [] })
  useEffect(() => {
    let rule: any = { groups: [] }
    state?.rules.forEach((r: any) => {
      if (r.name === ruleName) {
        rule = { groups: [...r.groups], name: r.name }
      }
    })
    localDispatch({ type: 'set', value: rule })
  }, [state, ruleName])

  const globalRoutingOptions = [
    {
      value: 'account_segment',
      label: 'Account Segment',
      compList: [
        { value: 'in', label: 'in' },
        { value: 'not_in', label: 'not in' }
      ],
      group: 'Company Segments',
      options: [],
      usePicker: true,
      pickerObj: 'segments',
      multi: false
    },
    {
      value: 'company_location',
      label: 'HQ Location',
      compList: [
        { value: 'eq', label: 'is' }
      ],
      group: 'Company Information',
      options: ['known', 'unknown'],
      usePicker: false,
      pickerObj: '',
      multi: false
    },
    {
      value: 'country',
      label: 'Country',
      compList: [
        { value: 'in', label: 'in' },
        { value: 'not_in', label: 'not in' }
      ],
      group: 'Visitor Location',
      options: americas.concat(asia, africa, europe, oceania),
      userPicker: false,
      pickerObj: '',
      multi: true
    },
    {
      value: 'us_state',
      label: 'US state',
      compList: [
        { value: 'in', label: 'in' },
        { value: 'not_in', label: 'not in' }
      ],
      group: 'Visitor Location',
      options: states,
      userPicker: false,
      pickerObj: '',
      multi: true
    },
    {
      value: 'canadian_province',
      label: 'Canadian province',
      compList: [
        { value: 'in', label: 'in' },
        { value: 'not_in', label: 'not in' }
      ],
      group: 'Visitor Location',
      options: provinces,
      userPicker: false,
      pickerObj: '',
      multi: true
    }
  ]

  function saveRule () {
    dispatch({ type: 'setRule', value: localState, id: ruleID })
    setCanSave(true)
    setOpen(false)
  }

  return (
    <div>
      <Modal
        onHide={() => setOpen(false)}
        disableBackdropClick
        disableEscClick
        handleSave={() => saveRule()}
        open={open}
        size='md'
        title='Routing Rule'
      >
        <ModalSection>
          <CriteriaBuilder
            ruleName={ruleName}
            nameSectionLabel='Enter a name for your rule'
            nameTooltip='This name will appear as the title for this rule. Choose something concise but descriptive.'
            filterSectionLabel='Add visitor and/or company filters'
            filterTooltip='Visitors must meet ALL of a group’s criteria to be included in the group, and must match at least one group’s criteria to match this rule.'
            state={localState}
            dispatch={localDispatch}
            routingMap={globalRoutingOptions}
          />
        </ModalSection>
      </Modal>
    </div>
  )
}
