import Card from 'library/materialUI/Card'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from 'library/materialUI'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { useContext, useEffect } from 'react'
import { TabContext } from './IntegrationPage/TabProvider'
import { IntegrationPageContext } from './IntegrationPage/IntegrationPageProvider'

const useStyles = makeStyles((theme) => ({
  subtitle1: {
    fontSize: 18,
    fontWeight: 600,
    marginBottom: 15
  },
  subtitle2: {
    fontSize: 15,
    fontWeight: 600,
    marginBottom: 10
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  checkboxes: {
    display: 'flex'
  },
  bodyText: {
    marginBottom: 30,
    fontSize: 16
  }
}))

export interface EventSetting {
  label: string | JSX.Element
  enabled: boolean
}

interface EventSettingsCardProps {
  save: any
  handleEdit: any
  integrationSettings: any
  title: string
  description: string
  enabledMap: Record<string, EventSetting>
}

export default function EventSettingsCard ({ title, description, enabledMap, save, handleEdit, integrationSettings }: EventSettingsCardProps) {
  const classes = useStyles()
  const { setCanSave, shouldSave } = useContext(TabContext)
  const { handleSaved } = useContext(IntegrationPageContext)

  useEffect(() => {
    if (shouldSave) {
      save()
      handleSaved()
    }
  })

  const setEventSettings = (checked: boolean, event: string) => {
    const data = integrationSettings.attributes.event_settings

    data[event] = { enabled: checked }

    setCanSave(true)
    handleEdit({ type: 'event_settings', data })
  }

  return (
    <Card
      content={
        <>
          <div className={classes.header}>
            <Typography className={classes.subtitle1}>
              {title}
            </Typography>
          </div>
          <Typography variant='body2' className={classes.bodyText}>
            {description}
          </Typography>
          {Object.entries(enabledMap).map(([key, value]) => {
            return (
              <FormControlLabel
                key={key}
                className={classes.checkboxes}
                control={
                  <Checkbox
                    color='primary'
                    checked={integrationSettings.attributes.event_settings?.[key]?.enabled}
                    onChange={(e) => setEventSettings(e.target.checked, key)}
                  />
                }
                label={value.label}
              />
            )
          })}
        </>
      }
    />
  )
}
