/* eslint-disable @typescript-eslint/naming-convention */
import { GetIntegrationCredentials, IntegrationSettings } from 'classes/integrationCredentials'
import { Integration } from 'classes/integrations'
import Loader from 'library/loading/Loader'
import { useLocation } from 'react-router-dom'
import OauthIntegrationPage from 'pages/OauthIntegration'
import { createContext, Dispatch, ReactNode, SetStateAction, useEffect, useState } from 'react'
import { FullIntegrationsList, integrationNames } from '../IntegrationsList'

type IntegrationType = {
  key: string
  path: string
  name: string
  icon: string
  description: string
  required_perm: string[] | string
  category: string
  flag?: string
  appLogo?: string
}

interface IntegrationPageValues {
  integration: IntegrationType
  integrationSettings: Integration
  isEnabled: boolean
  handleToggleEnable: () => void
  tabs: IntegrationTab[]
  saveDisabled: boolean
  selectedTab: number
  setSelectedTab: Dispatch<SetStateAction<number>>
  handleSave: () => void
  handleSaved: () => void
  learnMoreLink?: string
}

export const IntegrationPageContext = createContext<IntegrationPageValues>({
  integration: {
    key: '',
    path: '',
    name: '',
    icon: '',
    description: '',
    required_perm: [],
    category: ''
  },
  integrationSettings: {
    id: 0,
    objectType: 'integration',
    createdTimestamp: new Date(),
    createdDate: '',
    createdByUserID: 0,
    subtitle: '',
    searchField: '',
    name: '',
    enabled: false,
    config: {
      owner_rules: ['meeting_scheduled', 'last_routed', 'closed_conversation'],
      fallback_user_id: 1
    },
    mappings: {
      route_mapping: [],
      user_mapping: [],
      mapping: [
        {
          type: 'cf_field',
          data_type: 'str',
          overwrite: false,
          object_type: 'lead',
          external_field: 'FirstName',
          internal_field: 'first_name'
        },
        {
          type: 'cf_field',
          data_type: 'str',
          overwrite: false,
          object_type: 'lead',
          external_field: 'LastName',
          internal_field: 'last_name'
        },
        {
          type: 'cf_field',
          data_type: 'str',
          overwrite: false,
          object_type: 'lead',
          external_field: 'Phone',
          internal_field: 'phone'
        },
        {
          type: 'cf_field',
          data_type: 'str',
          overwrite: false,
          object_type: 'lead',
          external_field: 'Email',
          internal_field: 'email'
        },
        {
          type: 'cf_field',
          data_type: 'str',
          overwrite: false,
          object_type: 'lead',
          external_field: 'Company',
          internal_field: 'company_name'
        }
      ]
    },
    eventSettings: {
      transcript: {
        enabled: false
      },
      meeting_booked: {
        enabled: false
      },
      map_contact: {
        enabled: false
      }
    }
  },
  isEnabled: false,
  handleToggleEnable: () => undefined,
  tabs: [],
  saveDisabled: false,
  selectedTab: 0,
  setSelectedTab: () => undefined,
  handleSave: () => undefined,
  handleSaved: () => undefined,
  learnMoreLink: undefined
})
export type IntegrationTab = {
  name: string
  url: string
  components: ReactNode[]
}

interface Intergration {
  key: string
  path: string
  name: string
  icon: string
  description: string
  required_perm: string
  category: string
  flag: string
  appLogo: string
}
type IntegrationName = keyof typeof integrationNames
export interface IntegrationPageProps {
  integration: IntegrationName
  tabs: IntegrationTab[]
  oauth?: boolean
  saveDisabled: boolean,
  setShouldSave: Dispatch<SetStateAction<boolean>>
  learnMoreLink?: string
}

interface IntegrationPageProviderProps extends IntegrationPageProps {
  children: ReactNode
}

export default function IntegrationPageProvider ({ integration, tabs, children, oauth, saveDisabled, setShouldSave, learnMoreLink }: IntegrationPageProviderProps) {
  const location = useLocation()
  const index = tabs.map(tab => tab.url).indexOf(location.pathname)
  const defaultTab = index === -1 ? 0 : index
  const [selectedTab, setSelectedTab] = useState(defaultTab)
  const [integrationSettings, setIntegrationSettings] = useState<IntegrationSettings>({ integration_name: '', enabled: false })
  const i = FullIntegrationsList.find((int) => int.name === integration)
  if (!i) {
    throw new Error('IntegrationPageProvider - integration name invalid')
  }
  // TODO properly type FullIntegrationList
  const { oauthComp, checkingOauth } = HandleOauthandSettings({ integration: i as Intergration, oauth, setIntegrationSettings })

  const handleSave = () => {
    setShouldSave(true)
  }

  const handleSaved = () => {
    setShouldSave(false)
  }

  const handleToggleEnable = () => {
    const newAttrs = integrationSettings
    if (newAttrs) {
      newAttrs.enabled = !integrationSettings.enabled
      Integration.update(newAttrs)
    }
  }
  const integrationPageContent = checkingOauth ? <Loader type='spinner' size='lg' title={`Loading ${i.name}...`} /> : oauthComp || children
  return (
    <IntegrationPageContext.Provider value={{
      integration: i,
      integrationSettings: integrationSettings,
      isEnabled: integrationSettings.enabled,
      handleToggleEnable,
      tabs,
      saveDisabled,
      selectedTab,
      setSelectedTab,
      handleSave,
      handleSaved,
      learnMoreLink
    }}
    >
      {integrationPageContent}
    </IntegrationPageContext.Provider>
  )
}
interface HandleOauthProps {
  integration: Intergration,
  oauth?: boolean
  setIntegrationSettings: Dispatch<SetStateAction<IntegrationSettings>>
}

interface HandleOauthResult {
  oauthComp?: JSX.Element
  checkingOauth: boolean
}

function HandleOauthandSettings ({ integration, oauth, setIntegrationSettings }: HandleOauthProps): HandleOauthResult {
  const { data, isLoading } = GetIntegrationCredentials(integration.name)
  const { data: IntData, isLoading: IntIsLoading } = Integration.loadOne(integration.name.toLowerCase())

  useEffect(() => {
    if (!IntIsLoading) {
      if (typeof IntData === 'undefined') {
        Integration.create(integration.name.toLowerCase())
      } else {
        setIntegrationSettings(IntData)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [IntIsLoading])

  if (!isLoading) {
    if (!data.has_credentials && oauth) {
      const oauthComp = (
        <OauthIntegrationPage
          name={`${integration.name} Integration OAuth`}
          integration={integration.name}
          icon={integration.icon}
          description={`You are one step away from linking your Signals account with ${integration.name}!`}
        />
      )
      return { oauthComp, checkingOauth: isLoading }
    } else if (data.has_credentials) {
      return { oauthComp: undefined, checkingOauth: isLoading }
    } else {
      return { oauthComp: undefined, checkingOauth: isLoading }
    }
  }
  return { oauthComp: undefined, checkingOauth: (isLoading || IntIsLoading) }
}
