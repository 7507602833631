import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Checkbox, Select, ListItemText, MenuItem } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  bodyText: {
    marginBottom: 25,
    fontSize: 16
  },
  subtitle1: {
    fontSize: 18,
    fontWeight: 600,
    marginBottom: 15
  },
  subtitle2: {
    fontSize: 15,
    fontWeight: 600,
    marginBottom: 10
  },
  delaySelect: {
    width: 250,
    height: 40
  },
  menuHeader: {
    borderBottom: '1px solid #BBB',
    width: '100%',
    fontWeight: 600,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: 'rgba(0,0,0,0.87)'
  }
}))

const domains = [
  { value: 'spam', name: 'Spam Domains' },
  { value: 'personal', name: 'Personal Domains' },
  { value: 'blocked', name: 'Blocked Domains' }
]

const EmailDomainFilterSelector = (props) => {
  const classes = useStyles()
  const values = props.filters || []

  const renderFilterList = values => {
    const domainNames = []
    for (const value of values) {
      const name = domains.filter(d => d.value === value)[0]?.name
      domainNames.push(name)
    }

    if (!domainNames.length) {
      return 'No Filters Selected'
    }

    return domainNames.join(', ')
  }

  return (
    <>
      <div>
        <Select
          name='email_domain'
          variant='outlined'
          margin='dense'
          className={classes.delaySelect}
          value={values}
          defaultValue={[]}
          onChange={props.handleEdit}
          multiple
          displayEmpty
          renderValue={renderFilterList}
        >
          <MenuItem disabled style={{ opacity: 1 }}>
            <div className={classes.menuHeader}>Domain Filters</div>
          </MenuItem>
          {domains.map((row, index) => (
            <MenuItem key={index} value={row.value}>
              <Checkbox color='primary' checked={values.includes(row.value)} />
              <ListItemText primary={row.name} />
            </MenuItem>
          ))}
        </Select>
      </div>
    </>
  )
}

export default EmailDomainFilterSelector
