import { nodeMapping as playNodeMapping, nodeType } from '../plays/playBuilder/nodes/nodeMapping'
import { nodeMapping as botNodeMapping, nodeType as botNodeType } from '../bots/docs/nodeMapping'
import { nodeMapping as settingsNodeMapping } from 'pages/settings/nodeMapping'
import { sendEmailDocs } from '../plays/playBuilder/nodes/sendEmail/SendEmailDocs'
import { QuestionDocs } from '../bots/docs/QuestionDocs'
import { AddToCampaignDocs } from '../bots/docs/AddToCampaignDocs'
import { AlertAgentDocs } from 'pages/bots/docs/AlertAgentDocs'
import { ButtonDocs } from 'pages/bots/docs/ButtonDocs'
import { CalendarDropDocs } from 'pages/bots/docs/CalendarDropDocs'
import { ConversationGoalDocs } from 'pages/bots/docs/ConversationGoalDocs'
import { ConversationStatusDocs } from 'pages/bots/docs/ConversationStatusDocs'
import { EmailCaptureDocs } from 'pages/bots/docs/EmailCaptureDocs'
import { FAQDocs } from 'pages/bots/docs/FAQDocs'
import { LeadScoreDocs } from 'pages/bots/docs/LeadScoreDocs'
import { LiveChatDocs } from 'pages/bots/docs/LiveChatDocs'
import { MessageDocs } from 'pages/bots/docs/MessageDocs'
import { PhoneCaptureDocs } from 'pages/bots/docs/PhoneCaptureDocs'
import { RateConversationDocs } from 'pages/bots/docs/RateConversationDocs'
import { SendEmailDocs } from 'pages/bots/docs/SendEmailDocs'
import { TagConversationDocs } from 'pages/bots/docs/TagConversationDocs'
import { fireBotDocs } from 'pages/plays/playBuilder/nodes/fireBot/BotDocs'
import { alertAgentDocs } from 'pages/plays/playBuilder/nodes/alertAgent/AlertDocs'
import { contactSearchDocs } from 'pages/plays/playBuilder/nodes/contactSearch/ContactSearchDocs'
import { addToCampaignDocs } from 'pages/plays/playBuilder/nodes/addToCampaign/CampaignEnrollmentDocs'
import { geographyBranchingDocs } from 'pages/plays/playBuilder/nodes/geography/GeographyDocs'
import { delayDocs } from 'pages/plays/playBuilder/nodes/delay/DelayDocs'
// import { availabilityBranchningDocs } from 'pages/plays/playBuilder/nodes/availabilityBranching/AvailabilityBranchingDocs'
// import { visitorBranchingDocs } from 'pages/plays/playBuilder/nodes/visitorBranching/VisitorBranchingDocs'
import { websiteSignalDocs } from 'pages/plays/playBuilder/nodes/urlBranching/websiteSignalDocs'
import { segmentBranchingDocs } from 'pages/plays/playBuilder/nodes/segmentBranching/SegmentBranchingDocs'
import { playOverviewDocs } from './plays/PlaysOverView'
import { botOverviewDocs } from './bots/BotsOverview'
import { nodeMappingData } from 'library/flowBuilder/FlowBuilder'
import { basicMapping, botTemplateMapping, playTemplateMapping } from './TemplateMapping'
import { matchesICPDocs } from './plays/templates/MatchesICPDocs'
// import { retargetVisitorDocs } from './plays/templates/RetargetVisitorDocs'
import { OpenOpsDocs } from './plays/templates/OpenOpsDocs'
import { currentCustomerDocs } from './plays/templates/CurrentCustomerDocs'
import { homepageBotDocs } from './bots/templates/HomepageDocs'
import { secondNetBotDocs } from './bots/templates/SecondNetDocs'
import { retargetingBotDocs } from './bots/templates/RetargetingBotDocs'
import { homepageFAQBotDocs } from './bots/templates/HomepageFAQDocs'
import { demoBotDocs } from './bots/templates/DemoDocs'
import { liveChatBotDocs } from './bots/templates/LiveChatTemplateDocs'
import { conciergeBotDocs } from './bots/templates/ConciergeDocs'
import { conversationalContentDocs } from './bots/templates/ConversationalContentDocs'
import { ABELiveChatBotDocs } from './bots/templates/ABELIveChatDocs'
// import { conversationScoreDocs } from './plays/templates/ConversationScoreDocs'
// import { eventAttendeeDocs } from './plays/templates/EventAttendeeDocs'
import { CalendarDocs } from '../settings/HelpCenterDocs/CalendarDocs'
import { sectionMapping as settingSectionMapping } from 'pages/helpCenter/settings/SettingsHome'
import { salesloftDocs } from 'pages/plays/playBuilder/nodes/integration/SalesloftDocs'
import { createAccountDocs } from 'pages/plays/playBuilder/nodes/addAccount/AddAccountDocs'
import { ChromeNotificationsDocs } from 'pages/settings/HelpCenterDocs/ChromeNotificationDocs'
import { SafariNotificationsDocs } from 'pages/settings/HelpCenterDocs/SafariNotificationDoc'
import { FirefoxNotificationsDocs } from 'pages/settings/HelpCenterDocs/FirefoxNotificationDoc'
import { EdgeNotificationsDocs } from 'pages/settings/HelpCenterDocs/EdgeNotificationDoc'
import { BraveNotificationsDocs } from 'pages/settings/HelpCenterDocs/BraveNotificationDoc'
type NodeDocsMapping = Record<nodeType | botNodeType, any>

const playNodesDocsMapping: NodeDocsMapping = {
  SendEmail: sendEmailDocs,
  FireBot: fireBotDocs,
  AlertAgent: alertAgentDocs,
  ContactSearch: contactSearchDocs,
  AddToCampaign: addToCampaignDocs,
  // AvailabilityBranching: availabilityBranchningDocs,
  Delay: delayDocs,
  GeographyBranching: geographyBranchingDocs,
  // VisitorBranching: visitorBranchingDocs,
  WebsiteSignal: websiteSignalDocs,
  SegmentBranching: segmentBranchingDocs,
  Salesloft: salesloftDocs,
  CreateAccount: createAccountDocs
}

const playOverviewDocsMapping: NodeDocsMapping = {
  playsOverview: playOverviewDocs
}

const playTemplatesDocsMapping: NodeDocsMapping = {
  // Retarget: retargetVisitorDocs,
  // ConversationScore: conversationScoreDocs,
  // Events: eventAttendeeDocs,
  ICP: matchesICPDocs,
  OpenOps: OpenOpsDocs,
  CurrentCustomer: currentCustomerDocs
}

/**
 * verify that all object keys are unique across the three objects when inserting into new keys
 */
export const playDocsMapping: NodeDocsMapping = { ...playNodesDocsMapping, ...playOverviewDocsMapping, ...playTemplatesDocsMapping }

const botNodesDocsMapping: NodeDocsMapping = {
  Question: QuestionDocs,
  AddToCampaign: AddToCampaignDocs,
  AlertAgent: AlertAgentDocs,
  Button: ButtonDocs,
  Calendar: CalendarDropDocs,
  ConversationGoal: ConversationGoalDocs,
  ConversationStatus: ConversationStatusDocs,
  EmailCapture: EmailCaptureDocs,
  FAQ: FAQDocs,
  LeadScore: LeadScoreDocs,
  LiveChat: LiveChatDocs,
  Message: MessageDocs,
  PhoneCapture: PhoneCaptureDocs,
  RateConversation: RateConversationDocs,
  SendEmail: SendEmailDocs,
  TagConversation: TagConversationDocs
}

const botOverviewDocsMapping: NodeDocsMapping = {
  botsOverview: botOverviewDocs
}

const botTemplatesDocsMapping: NodeDocsMapping = {
  Homepage: homepageBotDocs,
  SecondNet: secondNetBotDocs,
  Retargeting: retargetingBotDocs,
  HomepageFAQ: homepageFAQBotDocs,
  Demo: demoBotDocs,
  LiveChatTemplate: liveChatBotDocs,
  Concierge: conciergeBotDocs,
  ConversationalContent: conversationalContentDocs,
  ABELiveChat: ABELiveChatBotDocs
}

/**
 * verify that all object keys are unique across the three objects when inserting into new keys
 */
export const botDocsMapping: NodeDocsMapping = { ...botNodesDocsMapping, ...botOverviewDocsMapping, ...botTemplatesDocsMapping }

const playsOverview: NodeDocsMapping = {
  playsOverview: {
    icon: 'play',
    title: 'Plays Overview'
  }
}

/** nodeMapping is filtered and reduced so that only the 'icon' and 'title'
 * keys are returned for each of the nodes that are being mapped
*/
const filteredPNM: Record<string, Partial<nodeMappingData>> = {}
for (const property in playNodeMapping) {
  const propertyObject: any = { ...playNodeMapping[property] }
  const filtered = { title: propertyObject.title, icon: propertyObject.icon }
  filteredPNM[property] = filtered
}

/** the mapping keys for each of the spread objects should be unique
 * see the playNodeMapping at ../plays/playBuilder/nodes/nodeMapping
 * and the playTemplateMapping at ./TemplateMapping
 * to view all mapping names
*/
export const playMapping: NodeDocsMapping = { ...filteredPNM, ...playTemplateMapping, ...playsOverview }

const botsOverview: NodeDocsMapping = {
  botsOverview: {
    icon: 'cursor',
    title: 'Bots Overview'
  }
}

/** nodeMapping is filtered and reduced so that only the 'icon' and 'title'
 * keys are returned for each of the nodes that are being mapped
*/

const filteredBNM: Record<string, basicMapping> = {}
for (const property in botNodeMapping) {
  const propertyObject: any = { ...botNodeMapping[property] }
  const filtered = { title: propertyObject.title, icon: propertyObject.icon }
  filteredBNM[property] = filtered
}

/** the mapping keys for each of the spread objects should be unique
 * see the botNodeMapping at ../bots/botBuilder/nodes/nodeMapping
 * and the botTemplateMapping at ./TemplateMapping
 * to view all mapping names
*/
export const botMapping: NodeDocsMapping = { ...filteredBNM, ...botTemplateMapping, ...botsOverview }

const notificationSettings: NodeDocsMapping = {
  Chrome: ChromeNotificationsDocs,
  Safari: SafariNotificationsDocs,
  Firefox: FirefoxNotificationsDocs,
  Edge: EdgeNotificationsDocs,
  Brave: BraveNotificationsDocs
}

export const settingOptionsDocsMapping = {
  calendar: CalendarDocs
}

const filteredSNM: Record<string, basicMapping> = {}
for (const property in settingsNodeMapping) {
  const propertyObject: any = { ...settingsNodeMapping[property] }
  const filtered = { title: propertyObject.title, icon: propertyObject.icon }
  filteredSNM[property] = filtered
}

export const settingsMapping: NodeDocsMapping = { ...settingOptionsDocsMapping, ...settingSectionMapping, ...notificationSettings }
