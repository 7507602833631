import { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles'
import { PopoverDiv } from '../nodes/PopoverDiv';
import { Icon, List, ListItem } from 'library/materialUI';
import { CriteriaContext } from './CriteriaContext';

const useStyles = makeStyles(theme => ({
  divContents: {
    marginTop: 1,
    height: 19,
    marginRight: 2,
    fontWeight: 600
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: 30
  }
}))

type DivContentsProps = {
  buttonText: string
}

function DivContents (props: DivContentsProps) {
  const classes = useStyles()

  return (
    <>
      <div className={classes.divContents}>
        {props.buttonText}
      </div>
      <Icon icon='add' color='#975ce6' />
    </>
  )
}

function PopoverContents () {
  const classes = useStyles()
  const { selectOptions, addNewFilter } = useContext(CriteriaContext)

  return (
    <div>
      <List>
        {selectOptions.map((option, index) => (
          <ListItem
            button
            key={index}
            onClick={() => addNewFilter(option.value)}
          >
            <div className={classes.listItem}>
              <div style={{ paddingRight: 10 }}>
                <Icon icon={option.icon} color='primary' />
              </div>
              <div>
                {option.label}
              </div>
            </div>
          </ListItem>
        ))}
      </List>
    </div>
  )
}

type AddFilterButtonProps = {
  type?: 'new' | 'divider'
}

export function AddFilterButton (props: AddFilterButtonProps) {
  const { classes, selectOptions, addNewFilter } = useContext(CriteriaContext)

  if (props.type === 'divider') {
    return (
      <div className={classes.filterDiv}>
        and
      </div>
    )
  } else if (selectOptions.length === 1) {
    const option = selectOptions[0]
    return (
      <div
        className={classes.filterDiv}
        onClick={() => addNewFilter(option.value)}
      >
        <DivContents buttonText='' />
      </div>
    )
  }

  const buttonText = props.type === 'new' ? 'Select Filter' : ''

  return (
    <PopoverDiv
      divContents={<DivContents buttonText={buttonText} />}
      popoverContents={<PopoverContents />}
      divClass={classes.filterDiv}
    />
  )
}
