import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Divider from '@material-ui/core/Divider'
import SecondaryMetricsTable from './SecondaryMetricsTable'
import SecondaryMetricsChart from './SecondaryMetricsChart'

const useStyles = makeStyles(theme => ({
  secondaryMetricsPage: {
    marginTop: 50
  },
  title: {
    fontWeight: 600,
    fontSize: '20px'
  },
  tableDiv: {
    marginTop: 30,
    marginBottom: 30
  }
}))

function SecondaryMetricsPage (props) {
  const classes = useStyles()

  return (
    <div className={classes.secondaryMetricsPage}>
      <div className={classes.title} id='secondary-metrics'>
        SECONDARY METRICS
      </div>
      <Divider />
      <div className={classes.tableDiv}>
        <SecondaryMetricsTable
          originalFlowID={props.originalFlowID}
          rows={props.rows}
          primaryMetric={props.primaryMetric}
        />
      </div>
      <div className={classes.chartDiv}>
        <SecondaryMetricsChart
          rows={props.rows}
          originalFlowID={props.originalFlowID}
        />
      </div>
    </div>
  )
}

export default SecondaryMetricsPage
