import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  popupContainer: {
    width: '313px'
  },
  innerContainer: {
    margin: '20px'
  }
}))

export default function NoActionComparator (): JSX.Element {
  const classes = useStyles()

  return (
    <div>
      <div className={classes.innerContainer}>
        <Typography variant='body1'>This factor will be scored based on quantity. No action is required.</Typography>
      </div>
    </div>
  )
}
