import { makeStyles } from '@material-ui/core/styles'
import { Icon } from 'library/materialUI'
import { useEffect } from 'react'

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    height: '60px',
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#EA422A',
    color: 'white'
  },
  buttonDiv: {
    height: '40px',
    borderRadius: '5px',
    border: '1px solid #FFFFFF',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 'auto',
    marginRight: '20px',
    cursor: 'pointer'
  }
}))

interface FormErrorBarProps {
  checklistMapping: any[]
  checklist: any
  triggerMissing: (value: string) => void
  close: () => void
}

export default function FormErrorBar ({ checklistMapping, checklist, triggerMissing, close }: FormErrorBarProps) {
  const classes = useStyles()
  const missingItems: any[] = []
  const indexList: string[] = []
  for (const property in checklist) {
    if (!checklist[property]) {
      indexList.push(property)
    }
  }
  checklistMapping.forEach((item) => {
    if (indexList.includes(item.name)) {
      missingItems.push(item)
    }
  })

  useEffect(() => {
    if (missingItems.length < 1) {
      close()
    }
    // eslint-disable-next-line
  }, [missingItems])

  return (
    <div className={classes.container}>
      <div style={{ justifySelf: 'flex-start', alignSelf: 'center', marginLeft: '20px' }}>
        {missingItems.length} incomplete setup items remaining
      </div>
      <div style={{ marginLeft: 'auto', marginRight: '10px', alignSelf: 'center' }}>
        <div onClick={() => triggerMissing(missingItems[0].name)} className={classes.buttonDiv}>
          <div style={{ marginLeft: '20px', marginRight: '10px' }}>
            JUMP TO NEXT
          </div>
          <div style={{ marginRight: '20px' }}>
            <Icon icon='rightArrow' size='md' />
          </div>
        </div>
      </div>
      <div onClick={() => close()} style={{ justifySelf: 'flex-end', alignSelf: 'center', marginRight: '20px', cursor: 'pointer' }}>
        <Icon icon='close' size='md' />
      </div>
    </div>
  )
}
