import { nodeMappingData } from 'library/flowBuilder/FlowBuilder'
import { ModalContentsProps } from 'library/flowBuilder/nodes/NodeModal'
import { categoryMapping } from '../nodeCategories'
import { branchingReducer } from '../../branching/reducer'
import { BranchingModal, BranchingNodeSchema } from '../../branching/BranchingModal'

function WebsiteSignalModal ({ state, dispatch }: ModalContentsProps): JSX.Element {
  return (
    <BranchingModal
      state={state}
      dispatch={dispatch}
      branchType='url'
      subtitle='Select visitor signals to listen for. You can then trigger actions based on these signals.'
    />
  )
}

function BranchingBody (): JSX.Element {
  return (
    <div>
      Listen for buying signals
    </div>
  )
}

export const WebsiteSignalMapping: nodeMappingData = {
  icon: 'websiteSignal',
  title: 'Website Signal',
  body: BranchingBody,
  modal: WebsiteSignalModal,
  modalReducer: branchingReducer,
  modalSize: 'md',
  schema: BranchingNodeSchema,
  helpCenterLink: 'home/plays/skills/URLBranching',
  ...categoryMapping.signals
}
