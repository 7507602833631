import { GenericObject } from 'classes/genericObject'
import { doGet, doPut } from 'api/api'
import { QueryLinkMapping } from './LinkMapItem'

export class EmailQueryStringSettings extends GenericObject {
    tenantId: number
    fieldMappings: QueryLinkMapping[]
    creationRuleType: string
    highIntentPageIds: number[]
    threshold: number
    integrations: string[]
    campaignIds: string[]
    createdTimestamp: Date | null
    updatedTimestamp: Date | null

    constructor ({ row }: { row?: Record<string, any> } = {}) {
        super({ row })
        const settings = row?.data?.attributes || {}
        this.name = 'Email Query String Settings'
        this.tenantId = settings?.tenant_id || null
        this.fieldMappings = settings?.field_mappings?.length ? settings.field_mappings : [{ signalsFieldID: 'email', emailField: '', canDelete: false, canEdit: false }]
        this.creationRuleType = settings?.creation_rule_type || 'time_on_site'
        this.highIntentPageIds = settings?.high_intent_page_ids || []
        this.threshold = settings?.threshold !== undefined ? settings?.threshold : 5
        this.integrations = settings?.integrations || []
        this.campaignIds = settings?.campaign_ids || []
        this.createdTimestamp = settings?.created_timestamp ? new Date(settings.created_timestamp) : null
        this.updatedTimestamp = settings?.updated_timestamp ? new Date(settings.updated_timestamp) : null
    }

    static save (data: EmailQueryStringSettings): Promise<void> {
        const path = '/link_tracking_settings'
        const body = {
            type: 'link_tracking_settings',
            attributes: { ...data }
        }

        return doPut({ path, data: body, useChatURL: true })
    }

    static async loadSettings (): Promise<EmailQueryStringSettings> {
        const path = '/link_tracking_settings';
        const data = await doGet({ path, useChatURL: true });
        return new EmailQueryStringSettings({ row: data });
    }
}

interface settingsAction {
    type: string
    value: any
}

export const queryStringSettingsReducer = (state: EmailQueryStringSettings, action: settingsAction): EmailQueryStringSettings => {
    switch (action.type) {
        case 'setFieldMappings':
            return { ...state, fieldMappings: action.value }
        case 'setCreationRuleType':
            return { ...state, creationRuleType: action.value }
        case 'setHighIntentPageIds':
            return { ...state, highIntentPageIds: action.value }
        case 'setThreshold':
            return { ...state, threshold: action.value }
        case 'setIntegrations':
            return { ...state, integrations: action.value }
        case 'setCampaignIds':
            return { ...state, campaignIds: action.value }
        case 'loadSettings':
            return action.value
        default:
            return state
    }
}
