
const descriptionText = "This template is utilized to manage your interactions with a visitor if the company they work for matches your company's Ideal Customer Profile (ICP). This play flow offers the following functionality:"

const Description = (): JSX.Element => {
  return (
    <div>
      <div>
        {descriptionText}
      </div>
      <ul>
        <li>Searches for contacts at the company according to your predetermined Buying Committee Personas</li>
        <li>Sends an email to the visitor that came to your site (if their email is known)</li>
        {/* <li>Initiates a predetermined bot conversation if the email link is clicked</li> */}
        <li>Sends a second email to the visitor offering a free trial</li>
      </ul>
    </div>
  )
}

const bestPracticeText = 'Only use this template if the ICP and Buying Committee Personas have already been set up for your company. Email and bot customization may be desired to provide prospective clients with the best experience.'

const BestPractices = () => {
  return (
    <div>
      <div>
        {bestPracticeText}
      </div>
      <ul>
        <li><a href='#/account_management/icp' target='_blank'>Set up/edit ICP and Buying Committee Personas</a></li>
        <li><a href='#/engagement/emails/emails' target='_blank'>Add/update emails</a></li>
        <li><a href='#/engagement/chatbots/chatbots' target='_blank'>Add/update bots</a></li>
      </ul>
    </div>
  )
}

export const matchesICPDocs = {
  helpCenterLink: '',
  bestPractices: BestPractices,
  description: Description,
  videoLink: ''
}
