import { makeStyles } from '@material-ui/core/styles'
import { Icon } from 'library/materialUI'
import { useEffect } from 'react'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    border: '1px solid',
    borderColor: '#00000040',
    borderRadius: '5px',
    width: '80px',
    height: '35px',
    margin: '10px'
  },
  addButton: {
    height: '17px',
    cursor: 'pointer',
    alignSelf: 'flex-start'
  }
}))

interface NumberSelectorProps {
  value: number
  setValue: (value: number) => void
}

export default function NumberSelector ({ value, setValue }: NumberSelectorProps): JSX.Element {
  const classes = useStyles()

  useEffect(() => {
    if (value < 1) {
      setValue(1)
    }
  // eslint-disable-next-line
  }, [value])

  return (
    <div className={classes.container}>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '15px' }}>
        {value}
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 'auto', marginRight: '5px' }}>
        <div className={classes.addButton} onClick={() => setValue(value + 1)}>
          <Icon icon='collapse' size='sm' />
        </div>
        <div className={classes.addButton} onClick={() => setValue(value - 1)}>
          <Icon icon='expand' size='sm' />
        </div>
      </div>
    </div>
  )
}
