
const descriptionText = 'This template is utilized to manage your interactions with a site visitor if the company they work for already has an open opportunity established with you as determined within your CRM software.'

const Description = (): JSX.Element => {
  return (
    <div>
      <div>
        {descriptionText}
      </div>
      <ul>
        <li>Fires a custom bot when the visitor comes to your website</li>
        <li>After a set period of time following the bot fire, an email is then sent to the visitor</li>
        <ul>
          <li>This email is sent even if the visitor does not interact with the bot</li>
        </ul>
        <li>A second email is sent to the visitor offering a free trial</li>
      </ul>
    </div>
  )
}

const bestPracticeText = 'Only use this template if a user with the proper permissions has already set up an integration with your CRM software. Email and bot customization may be desired to provide prospective clients with the best experience.'

const BestPractices = () => {
  return (
    <div>
      <div>
        {bestPracticeText}
      </div>
      <ul>
        <li><a href='#/settings/integrations' target='_blank'>Manage Integrations</a></li>
        <li><a href='#/engagement/emails/emails' target='_blank'>Add/update emails</a></li>
        <li><a href='#/engagement/chatbots/chatbots' target='_blank'>Add/update bots</a></li>
      </ul>
    </div>
  )
}

export const OpenOpsDocs = {
  helpCenterLink: '',
  bestPractices: BestPractices,
  description: Description,
  videoLink: ''
}
