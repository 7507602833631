import React, { useState, useEffect, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import EditAlertModal from '../AccountManagement/Alerts/EditAlertModal'
import { Redirect } from 'react-router-dom'
import Loader from 'library/loading/Loader'
import desertIcon from 'img/desertIcon.svg'
import { createIntegrationAccount, getAccountConversationCount, getRecentConversations, updateAccount, deleteAccount } from 'api/account_management'
import { getUser } from 'api/users'
import DeleteModal from 'components/DeleteModal'
import { RecentConversationsCard } from 'pages/dashboard/RecentConversationsCard'
import EditAccountModal from 'pages/accounts/EditAccountModal'
import AccountHeader from './AccountHeader'
import AccountContacts from './AccountContacts'
import AccountActivity from './AccountActivity'
import PotentialAccountModal from 'pages/dashboard/PotentialAccountModal'
import Account from 'classes/accounts'
import AccountPageProvider from './AccountPageProvider'
import DomainDataModal from './DomainDetails/DomainDataModal'
import { CRMContext } from 'crm-context'
import FormSubmissionModal from 'pages/forms/FormSubmissionModal'
import AccountAbout from './AccountAbout'
import AccountInsightsCard from './AccountInsightsCard'
import { ShareContext } from 'share-context'

const useStyles = makeStyles(theme => ({
  loadingScreen: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexFlow: 'column nowrap'
  },
  emptyIcon: {
    maxWidth: '100%',
    minWidth: '70%',
    height: 400,
    '& > img': { width: '100%' }
  },
  emptyMessages: {
    font: 'normal normal bold 16px/22px Poppins',
    color: theme.palette.primary.main,
    textAlign: 'center'
  },
  accountDashboard: {
    padding: 20,
    backgroundColor: '#F5F5F5',
    minHeight: 'calc(100vh - 40px)',
    fontFamily: 'poppins',
    overflowX: 'hidden',
    display: 'flex',
    flexDirection: 'row'
  },
  dashLeft: {
    width: '33%',
    display: 'flex',
    flexDirection: 'column',
    marginRight: '10px'
  },
  dashRight: {
    width: '66%',
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '10px'
  },
  card: {
    marginBottom: '20px'
  }
  // right: {
  //   gridArea: 'right',
  //   display: 'grid',
  //   gridGap: 20
  // },
  // left: {
  //   gridArea: 'left',
  //   display: 'grid',
  //   gridGap: 20
  // }
}))

function AccountPage ({ match }) {
  const { crmIntegrationName } = useContext(CRMContext)
  const [account, setAccount] = useState(undefined)
  const [editAlert, setEditAlert] = useState(false)
  const [numConversations, setNumConversations] = useState(null)
  const [accountOwner, setAccountOwner] = useState(null)
  const [conversations, setConversations] = useState(null)
  const [editModalOpen, setEditModalOpen] = useState(false)
  const [potentialAccountModalOpen, setPotentialAccountModalOpen] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [redirect, setRedirect] = useState(null)
  const [snoozeBool, setSnoozeBool] = useState(false)
  const accountID = match.params.account_id
  const classes = useStyles()
  const [submissionModalOpen, setSubmissionModalOpen] = useState(false)
  const [submissionID, setSubmissionID] = useState()
  const [contact, setContact] = useState()
  const { data: acc, isLoading } = Account.loadOne(accountID)
  const loadAccount = () => {
    if (acc) {
      const temp_company = acc
      if (temp_company.snoozedUntilTimestamp) {
        const today = Date.now()
        const snoozedDate = temp_company.snoozedUntilTimestamp * 1000
        if (today < snoozedDate) {
          setSnoozeBool(true)
        }
      }
      setAccount(temp_company)
      if (temp_company.ownerUserId) {
        getUser({ ID: temp_company.ownerUserId })
          .then(response => {
            setAccountOwner(response.data)
          })
      }
    } else {
      setAccount(null)
    }
  }
  const { flags } = useContext(ShareContext)

  const handleSubmitPotentialAccount = (accountState, additionalFields) => {
    updateAccount({ account: accountState })
      .then(response => {
        if (response.data && crmIntegrationName) {
          createIntegrationAccount({ domain: response.data.attributes.domain, additionalFields }).then(() => {
            setPotentialAccountModalOpen(false)
            loadAccount()
          })
        } else {
          setPotentialAccountModalOpen(false)
          loadAccount()
        }
      })
  }

  useEffect(() => {
    if (!isLoading) {
      loadAccount()
      getAccountConversationCount({ accountID })
        .then(response => {
          if (!response?.data) {
            throw Error('Conversations not found')
          }
          setNumConversations(response?.data?.attributes || 0)
        }).catch(() => {
          setNumConversations(0)
        })
      getRecentConversations({ accountID })
        .then(response => {
          if (!response?.data) {
            throw Error('Conversations not found')
          }
          setConversations(response.data)
        }).catch(() => {
          setConversations(null)
        })
    }
    // eslint-disable-next-line
  }, [isLoading])

  if (redirect) {
    return <Redirect to={redirect} push />
  } else if (account === undefined || isLoading) {
    return (
      <div className={classes.loadingScreen}>
        <Loader type='spinner' size='xlg' />
      </div>
    )
  } else if (account === null) {
    return (
      <div className={classes.loadingScreen}>
        <div className={classes.emptyIcon}>
          <img src={desertIcon} alt='Not found' />
        </div>
        <div className={classes.emptyMessages}>
          <div>Ooops!</div>
          <div>We seem to have misplaced this account</div>
        </div>
      </div>
    )
  }

  const handleDelete = () => {
    deleteAccount({ accountID })
      .then(() => {
        setDeleteModalOpen(false)
        setRedirect('/account_management/accounts')
      })
  }

  const save = (values) => {
    const attributes = values
    const account = {
      id: accountID,
      type: 'accounts',
      attributes: attributes
    }
    updateAccount({ account })
      .then(account_response => {
        loadAccount()
        setEditModalOpen(false)
      })
  }

  const handleSnooze = () => {
    if (snoozeBool) {
      const accountObject = { id: accountID, attributes: { snoozed_until_timestamp: null } }
      updateAccount({ account: accountObject })
        .then(() => {
          setSnoozeBool(!snoozeBool)
        })
    } else {
      const snoozeForNinetyDays = new Date()
      snoozeForNinetyDays.setDate(snoozeForNinetyDays.getDate() + 90)

      const accountObject = { id: accountID, attributes: { snoozed_until_timestamp: snoozeForNinetyDays } }
      updateAccount({ account: accountObject })
        .then(() => {
          setSnoozeBool(!snoozeBool)
        })
    }
  }

  return (
    <AccountPageProvider
      id={accountID}
    >
      <div style={{ backgroundColor: '#F5F5F5' }}>
        <div style={{ padding: '20px' }}>
          <AccountHeader
            account={account}
            numConversations={numConversations}
            accountOwner={accountOwner}
            addAlert={() => setEditAlert(true)}
            addAccount={() => setPotentialAccountModalOpen(true)}
            editAccount={() => setEditModalOpen(true)}
            deleteAccount={() => setDeleteModalOpen(true)}
            snoozeAccount={handleSnooze}
            snoozed={snoozeBool}
          />
        </div>
        {flags['ai-summaries'] && (
          <div style={{ width: '100%', padding: 20, paddingBottom: 0 }}>
            <AccountInsightsCard domain={account?.domain} />
          </div>
        )}
        <div className={classes.accountDashboard}>
          <div className={classes.dashLeft}>
            <div className={classes.card}>
              <AccountAbout account={account} />
            </div>
            <div className={classes.card}>
              <AccountContacts
                accountID={accountID}
              />
            </div>
            <div className={classes.card}>
              <RecentConversationsCard
                conversations={conversations}
              />
            </div>
          </div>
          <div className={classes.dashRight}>
            <div className={classes.card}>
              <AccountActivity
                setContact={setContact}
                setSubmissionID={setSubmissionID}
                setSubmissionModalOpen={setSubmissionModalOpen}
                accountName={account?.name}
                accountID={accountID}
                domain={account?.domain}
              />
            </div>
            {/* <div className={classes.card}>
              <ActivitySummary
                accountID={accountID}
              />
            </div> */}
          </div>
          {/* <div style={{ gridArea: 'details' }}>
            <AccountDetailsCard
              account={account}
            />
          </div> */}
          <EditAccountModal
            open={editModalOpen}
            accountID={accountID}
            onHide={() => setEditModalOpen(false)}
            saveAccount={save}
          />
          <PotentialAccountModal
            domain={account?.domain}
            account={account || {}}
            open={potentialAccountModalOpen}
            onHide={() => setPotentialAccountModalOpen(false)}
            setPotentialAccountModalOpen={setPotentialAccountModalOpen}
            handleSubmit={handleSubmitPotentialAccount}
          />
          <EditAlertModal
            open={editAlert}
            onHide={() => setEditAlert(false)}
            isGlobal
            domain={account?.domain}
          />
          <DeleteModal
            open={deleteModalOpen}
            onHide={() => setDeleteModalOpen(false)}
            deleteObject={handleDelete}
            message='Are you sure you want to delete this account? This action cannot be undone.'
            title='Delete Account'
          />
          <DomainDataModal
            company={account}
            onSave={() => console.log('save')}
          />
          <FormSubmissionModal
            open={submissionModalOpen}
            setOpen={setSubmissionModalOpen}
            submissionID={submissionID || 0}
            contactName={contact}
          />
        </div>
      </div>
    </AccountPageProvider>
  )
}

export default AccountPage
