/* eslint-disable @typescript-eslint/naming-convention */
import { salesLoftContactDefaultMapping, IntegrationMappingCls } from 'classes/IntegrationMapping'
import Loader from 'library/loading/Loader'
import Card from 'library/materialUI/Card'
import { isEqual } from 'lodash'
import { useContext, useEffect, useState } from 'react'
import IntegrationMapping from '../IntegrationMapping'
import { IntegrationPageContext } from '../IntegrationPage/IntegrationPageProvider'
import { TabContext } from '../IntegrationPage/TabProvider'
import { useMappingState, HandleSave } from './mappingHook'

const SL = 'salesloft'
// couldn't find endpoint that would easily give this info
// see https://developers.salesloft.com/docs/api/people-create
const externalFields = [
  { api_name: 'last_name', label: 'Last Name', data_type: 'str' },
  { api_name: 'first_name', label: 'First Name', data_type: 'str' },
  { api_name: 'phone', label: 'Phone', data_type: 'str' },
  { api_name: 'email_address', label: 'Email', data_type: 'str' },
  { api_name: 'title', label: 'Title', data_type: 'str' },
  { api_name: 'city', label: 'City', data_type: 'str' },
  { api_name: 'state', label: 'State', data_type: 'str' },
  { api_name: 'country', label: 'Country', data_type: 'str' },
  { api_name: 'job_seniority', label: 'Job Seniority', data_type: 'str' },
  { api_name: 'locale', label: 'Timezone', data_type: 'str' },
  { api_name: 'secondary_email_address', label: 'Alternate Email', data_type: 'str' },
  { api_name: 'personal_email_address', label: 'Personal Email', data_type: 'str' },
  { api_name: 'phone_extension', label: 'Phone Extension', data_type: 'str' },
  { api_name: 'mobile_phone', label: 'Mobile Phone', data_type: 'str' },
  { api_name: 'home_phone', label: 'Home Phone', data_type: 'str' },
  { api_name: 'linkedin_url', label: 'LinkedIn URL', data_type: 'str' },
  { api_name: 'work_city', label: 'Work City', data_type: 'str' },
  { api_name: 'work_state', label: 'Work State', data_type: 'str' },
  { api_name: 'work_country', label: 'Work Country', data_type: 'str' },
  { api_name: 'person_company_name', label: 'Company Name', data_type: 'str' },
  { api_name: 'person_company_website', label: 'Company Website', data_type: 'str' },
  { api_name: 'person_company_industry', label: 'Company Industry', data_type: 'str' },
  { api_name: 'job_seniorty', label: 'Job Seniority', data_type: 'str' },
  // { api_name: 'do_not_contact', label: 'Do Not Contact', data_type: 'bool' },
  // { api_name: 'eu_resident', label: 'EU Resident', data_type: 'bool' },
  { api_name: 'personal_website', label: 'Personal Website', data_type: 'str' },
  { api_name: 'twitter_handle', label: 'Twitter Handle', data_type: 'str' }
  // { api_name: 'auto_tag_date', label: 'Auto Tag Date', data_type: 'bool' }

]

export default function PeopleMappingCard () {
  const { data, isLoading } = IntegrationMappingCls.loadOne('contacts', SL)
  const { setCanSave, shouldSave } = useContext(TabContext)
  const { handleSaved } = useContext(IntegrationPageContext)
  const [mappingState, setMappingState] = useState<IntegrationMappingCls>({ mapping: salesLoftContactDefaultMapping })
  useMappingState<IntegrationMappingCls>({ isLoading, setMappingState, data, mappingState, setCanSave })
  HandleSave<IntegrationMappingCls>({ mappingState, shouldSave, saveFunction: (mapping) => IntegrationMappingCls.save('contacts', mapping.mapping, SL), handleSaved })
  HandleInitSave({ data, mappingState })
  if (isLoading) {
    return (
      <Loader
        type='spinner'
      />
    )
  }
  return (
    <>
      <Card
        content={(
          <IntegrationMapping
            currentMapping={mappingState.mapping}
            externalMappingFields={externalFields}
            forObject='people'
            integrationName=''
            title='People Mapping'
            handleEdit={(mapping) => setMappingState({ mapping })}
            disableOverwrite={undefined}
            typeMapping={undefined}
            mappedGroups={undefined}
          />
        )}
      />
    </>
  )
}

function HandleInitSave ({ data, mappingState }: { data: IntegrationMappingCls, mappingState: IntegrationMappingCls }) {
  useEffect(() => {
    if (data && 'mapping' in data && data.mapping.length === 0 && isEqual(mappingState.mapping, salesLoftContactDefaultMapping)) {
      IntegrationMappingCls.save('contacts', mappingState.mapping, SL)
    }
  }, [data, mappingState])
}
