import { makeStyles } from '@material-ui/core/styles'
import Form, { FormAction } from 'classes/forms'
import { Icon, Checkbox, Button } from 'library/materialUI'
import { Divider, Typography } from '@material-ui/core'
import Picker from 'library/Picker'
import { Dispatch } from 'react'
import { TwitterPicker } from 'react-color'

const useStyles = makeStyles(theme => ({
  container: {
    margin: '15px',
    padding: '20px',
    backgroundColor: '#FFFFFF',
    border: '1px solid #D3D3D3',
    borderRadius: '5px'
  },
  sectionSubtitle: {
    color: '#808080',
    fontFamily: 'poppins',
    fontSize: '14px',
    marginTop: '5px',
    marginBottom: '5px'
  },
  sectionTitle: {
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#000000BF'
  },
  editButton: {
    display: 'flex',
    width: '127px',
    height: '40px',
    fontSize: '14px',
    border: '1px solid #D3D3D3',
    borderRadius: '5px',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#E4E4E4'
    }
  },
  divider: {
    marginBottom: 10
  }
}))

interface FormPromptAppearanceCardProps {
  open: boolean
  setOpen: (value: boolean) => void
  formDispatch: Dispatch<FormAction>
  form: Form
  chatPrimaryColor?: string
  setCanSave: (value: boolean) => void
}

export default function FormPromptAppearanceCard ({ open, setOpen, formDispatch, form, setCanSave, chatPrimaryColor }: FormPromptAppearanceCardProps) {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <div onClick={() => setOpen(!open)} style={{ display: 'flex', flexDirection: 'row', cursor: 'pointer' }}>
        <div className={classes.sectionTitle}>
          Prompt Appearance
        </div>
        <div style={{ marginLeft: 'auto', marginRight: '5px' }}>
          {open ? (
            <div>
              <Icon icon='close' />
            </div>
          ) : (
            <div className={classes.editButton}>
              Edit Settings
            </div>
          )}
        </div>
      </div>
      <div onClick={() => setOpen(!open)} className={classes.sectionSubtitle}>
        Customize the appearance of the prompt
      </div>
      {open && (
        <div>
          <Typography variant='h6' style={{ marginTop: 30 }}>Button Color</Typography>
          <Divider className={classes.divider} />
          <Typography variant='body2'>What color should the buttons be?</Typography>
          {chatPrimaryColor && (
            <Checkbox
              label='Same as primary chat color'
              checked={form.buttonSameAsPrimary}
              onChange={(checked: boolean) => {
                if (checked) {
                  formDispatch({ type: 'buttonColor', value: '#' + chatPrimaryColor })
                  formDispatch({ type: 'buttonSameAsPrimary', value: true })
                } else {
                  formDispatch({ type: 'buttonColor', value: '' })
                  formDispatch({ type: 'buttonSameAsPrimary', value: false })
                }
                setCanSave(true)
              }}
            />
          )}
          <TwitterPicker
            triangle='hide'
            color={form?.buttonColor || chatPrimaryColor}
            onChange={(color: any) => {
              formDispatch({ type: 'buttonColor', value: color.hex });
              if (form.buttonSameAsPrimary && chatPrimaryColor !== color.hex) {
                formDispatch({ type: 'buttonSameAsPrimary', value: false })
              }
              setCanSave(true)
            }}
          />
          <Typography variant='h6' style={{ marginTop: 30 }}>Agent Profile Pictures</Typography>
          <Divider className={classes.divider} />
          <Typography variant='body2'>Would you like to display agent profile pictures on the prompt?</Typography>
          <Checkbox
            label='Show agent profile pictures'
            checked={form.showAgentProfiles}
            onChange={(checked: boolean) => { formDispatch({ type: 'showAgentProfiles', value: checked }); setCanSave(true) }}
          />
          {form.showAgentProfiles && (
            <Picker
              selection={form.agentProfiles}
              setSelection={(values) => { formDispatch({ type: 'agentProfiles', value: values.slice(-3) }); setCanSave(true) }}
              multiple
              objectType='users'
              placeholder='Select agents'
            />
          )}
          <div style={{ marginTop: '20px', marginLeft: 'auto', marginRight: '10px', width: '60px' }}>
            <Button
              onClick={() => setOpen(false)}
            >
              Done
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}
