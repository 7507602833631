const account_types = [
  'Contact',
  'Lead',
  'Prospect',
  'Opportunity',
  'Customer',
  'Lost'
]

const revenue_values = [
  '$1 - $999.9K',
  '$1M - $4.9M',
  '$5M - $9.9M',
  '$10M - $24.9M',
  '$25M - $49.9M',
  '$50M - $99.9M',
  '$100M - $249M',
  '$250M - $499M',
  '$500M - $999M',
  '$1B - $2.49B',
  '$2.5B - $4.9B',
  '$5B+'
]

/**
 * Get the revenue range for a specific value
 * @param {number} revenueValue
 * @returns {string} The revenue range
 */
export const getRevenueRange = revenueValue => {
  if (!revenueValue) {
    return 'Unknown'
  }
  if (revenueValue < 1000000) {
    return revenue_values[0]
  }
  if (revenueValue < 5000000) {
    return revenue_values[1]
  }
  if (revenueValue < 10000000) {
    return revenue_values[2]
  }
  if (revenueValue < 25000000) {
    return revenue_values[3]
  }
  if (revenueValue < 50000000) {
    return revenue_values[4]
  }
  if (revenueValue < 100000000) {
    return revenue_values[5]
  }
  if (revenueValue < 250000000) {
    return revenue_values[6]
  }
  if (revenueValue < 500000000) {
    return revenue_values[7]
  }
  if (revenueValue < 1000000000) {
    return revenue_values[8]
  }
  if (revenueValue < 2500000000) {
    return revenue_values[9]
  }
  if (revenueValue < 5000000000) {
    return revenue_values[10]
  }
  return revenue_values[11]
}

/**
 * Get the revenue range for display and value for sorting
 * @param {number | string | undefined} revenueValue
 * @returns {{ value: number; display: string; }}
 */
export const cleanRevenueRange = revenueValue => {
  const value = parseInt(revenueValue)
  const display = isNaN(value) ? '---' : getRevenueRange(value)
  return { value: value || 0, display }
}

const employee_counts = [
  '1-9',
  '10-19',
  '20-49',
  '50-99',
  '100-249',
  '250-499',
  '500-999',
  '1,000-1,499',
  '1,500-1,999',
  '2,000-2,499',
  '2,500-4,999',
  '5,000-9,999',
  '10,000+'
]

/**
 * Get the employee range for a specific value
 * @param {number} employeeCount
 * @returns {string} The employee range
 */
export const getEmployeeRange = employeeCount => {
  if (!employeeCount) {
    return null
  } else if (employeeCount < 10) {
    return employee_counts[0]
  } else if (employeeCount < 20) {
    return employee_counts[1]
  } else if (employeeCount < 50) {
    return employee_counts[2]
  } else if (employeeCount < 100) {
    return employee_counts[3]
  } else if (employeeCount < 250) {
    return employee_counts[4]
  } else if (employeeCount < 500) {
    return employee_counts[5]
  } else if (employeeCount < 1000) {
    return employee_counts[6]
  } else if (employeeCount < 1500) {
    return employee_counts[7]
  } else if (employeeCount < 2000) {
    return employee_counts[8]
  } else if (employeeCount < 2500) {
    return employee_counts[9]
  } else if (employeeCount < 5000) {
    return employee_counts[10]
  } else if (employeeCount < 10000) {
    return employee_counts[11]
  }
  return employee_counts[12]
}

/**
 * Get the revenue range for display and value for sorting
 * @param {number | string | undefined} employeeCount
 * @returns {{ value: number; display: string; }}
 */
export const cleanEmployeeRange = employeeCount => {
  const value = parseInt(employeeCount)
  const display = isNaN(value) ? '---' : getEmployeeRange(value)
  return { value: value || 0, display }
}

const industries = [
  'Accommodation and Food Services',
  'Administration and Business Support',
  'Agriculture, Forestry, Fishing, and Hunting',
  'Arts, Entertainment, and Recreation',
  'Construction',
  'Educational Services',
  'Finance and Insurance',
  'Healthcare and Social Assistance',
  'Information',
  'Internet (Including Saas/Mobile)',
  'Manufacturing',
  'Mining',
  'Other Services',
  'Professional, Scientific, and Technical Services',
  'Real Estate and Rental and Leasing',
  'Retail Trade',
  'Transportation and Warehousing',
  'Utilities',
  'Wholesale Trade'
]

export { account_types, revenue_values, employee_counts, industries }
