/* eslint-disable @typescript-eslint/naming-convention */
import { makeStyles } from '@material-ui/core/styles'
import { parameterizeFilters, SearchParams } from 'classes/queryHelpers'
import { Emitter, EVENT_TYPE } from 'emitter'
import { getSettings, saveSettings } from 'api/user_display_settings'
import { ICP } from 'classes/icp'
import { Segment } from 'classes/segments'
import DeleteModal from 'components/DeleteModal'
import ICPBanner from 'library/banners/IcpBanner'
import SkeletonLabel from 'library/loading/skeleton/SkeletonLabel'
import SkeletonTableLoader from 'library/loading/skeleton/SkeletonTableLoader'
import { defaultSegment } from 'pages/segments/segmentHelpers'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import SegmentModal from './SegmentModal'

const mywindow = window as any

const useStyles = makeStyles(theme => ({
  tabPlaceholder: {
    width: 200,
    marginTop: 5,
    marginBottom: 5,
    marginLeft: 10,
    marginRight: 20
  }
}))

export interface SegmentListPageProps {
  objectType: Segment['objectType']
  segmentModalOpen: boolean
  setSegmentModalOpen: Dispatch<SetStateAction<boolean>>
}

export default function SegmentListPage ({ objectType, segmentModalOpen, setSegmentModalOpen }: SegmentListPageProps): JSX.Element {
  const classes = useStyles()
  const searchParams: SearchParams = {
    filters: [[{ field: 'object_type', operator: 'eq', value: 'domains' }]]
  }
  const [deleteSegmentID, setDeleteSegmentID] = useState<number | undefined>(undefined)
  const [showBanner, setShowBanner] = useState<boolean | null>(null)
  const { data: icp, isLoading } = ICP.get()
  const url = parameterizeFilters({ path: '/api/core/web/segments', searchParams })
  const rowEventListener = (e: any) => {
    const tableID = e.detail.tableID
    const rowAction = e.detail.action
    const rowID = e.detail.rowID
    if (tableID === 'segments-body') {
      switch (rowAction) {
        case 'delete':
          setDeleteSegmentID(parseInt(rowID))
          break
      }
    }
  }
  useEffect(() => {
    if (!isLoading && showBanner === null) {
      getSettings({ kind: 'icp_setup', sid: undefined })
        .then((value) => {
          const attributes = value.data.attributes
          const isCreated = attributes.created_timestamp !== null
          const hasICP = !!icp?.id
          if (!isCreated) {
            saveSettings({ kind: 'icp_setup', settings: { segment_page_banner: { complete: hasICP } }, post: true })
            setShowBanner(!hasICP)
          } else {
            const settings = attributes.settings
            const pageBanner = settings.segment_page_banner
            const showBan = pageBanner.complete || hasICP
            setShowBanner(!showBan)
          }
        })
    }
  }, [isLoading, showBanner, icp])
  useEffect(() => {
    Emitter.on(EVENT_TYPE.DATA_CHANGED, (payload: any) => {
      if (payload.eventURL.includes('segment')) {
        mywindow.htmx.trigger('#segments', 'dataUpdate')
      }
    })
    try {
      mywindow.addEventListener('rowEvent', rowEventListener)
    } catch (e) {
      console.log(e)
    }
    return () => {
      mywindow.removeEventListener('rowEvent', rowEventListener)
    }
    // eslint-disable-next-line
  }, [])

  const removeSegment = (segmentID?: Segment['id']): void => {
    if (!segmentID) {
      return
    }
    const segment = new Segment({ row: { id: segmentID, attributes: {} } })
    segment.delete().then(() => {
      setDeleteSegmentID(undefined)
    })
  }

  return (
    <>
      {showBanner && <ICPBanner />}
      <div style={{ backgroundColor: 'white' }}>
        <div
          id='segments'
          hx-get={url}
          hx-trigger='revealed,paramChange,dataUpdate'
        >
          <div style={{ display: 'flex', justifyContent: 'right', padding: 10 }}>
            <div className={classes.tabPlaceholder} style={{ margin: 10 }}> <SkeletonLabel size='full' /> </div>
          </div>
          <SkeletonTableLoader
            rowCount={10}
            types={['squareAvatar&Label', 'number', 'smLabel', 'smLabel']}
            columns='2fr 1fr 1fr 1fr 1fr 50px 50px'
            justifyCenter
          />
        </div>
      </div>
      <SegmentModal
        segment={defaultSegment}
        objectType={objectType}
        open={segmentModalOpen}
        setSegmentModalOpen={setSegmentModalOpen}
      />
      <DeleteModal
        open={Boolean(deleteSegmentID)}
        onHide={() => setDeleteSegmentID(undefined)}
        deleteObject={() => removeSegment(deleteSegmentID)}
        message='Are you sure you want to delete this segment?'
        title='Delete Segment'
      />
    </>
  )
}
