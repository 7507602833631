import { useState } from 'react'
import { Dialog, Button } from 'library/materialUI'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  contents: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center'
  },
  dialog: {
    padding: 20
  },
  paper: {
    borderRadius: 15
  },
  details: {
    color: 'white'
  },
  sectionTitle: {
    font: 'normal normal bold 22px/30px Poppins',
    marginBottom: 15,
    textAlign: 'center'
  },
  subtitle: {
    font: 'normal normal normal 16px/22px Poppins',
    marginBottom: 15
  },
  primaryButton: {
    textTransform: 'none',
    width: '50%'
  },
  secondaryButton: {
    marginRight: 20,
    textTransform: 'none',
    width: '50%'
  },
  link: {
    color: '#9933ff',
    marginTop: 10
  }
}))

type ModalProps = {
  open: boolean
  onClose: () => void
}

export function SnapshotIntroWizardModal (props: ModalProps) {
  const classes = useStyles()
  const [page, setPage] = useState(0)

  type ModalContents = {
    title: string
    subtitle: string
    primaryButtonText: any
    secondaryButtonText?: any
    primaryButtonAction: any
    secondaryButtonAction?: any
    link?: string
    linkLabel?: string
  }

  const items: ModalContents[] = [
    {
      title: 'Welcome to Signals Snapshot!',
      subtitle: 'Let\'s get you set up. It\'ll take just a few minutes to set up your profile and get everything up and running.',
      primaryButtonText: 'Get Started',
      primaryButtonAction: () => {
        setPage(1)
      }
    },
    {
      title: 'Install Script to Site',
      subtitle: 'The Signal tracking script has not been installed on your website. Install it to begin tracking activity.',
      primaryButtonText: 'Next',
      primaryButtonAction: () => {
        setPage(2)
      },
      secondaryButtonText: 'Back',
      secondaryButtonAction: () => {
        setPage(0)
      },
      link: '/settings/installation',
      linkLabel: 'Installation Page'
    },
    {
      title: 'Connect & Sync your CRM',
      subtitle: 'Signals Snapshot requires that you connect your CRM. This allows you access to Signal\'s vast source of account information.',
      primaryButtonText: 'Close',
      primaryButtonAction: () => {
        props.onClose()
      },
      secondaryButtonText: 'Back',
      secondaryButtonAction: () => {
        setPage(1)
      }
    }
  ]

  return (
    <Dialog
      open={props.open}
      maxWidth='xs'
      classes={{ paper: classes.paper }}
    >
      <div className={classes.dialog}>
        <div className={classes.sectionTitle}>{items[page].title}</div>
        <div className={classes.subtitle}>
          <div>{items[page].subtitle}</div>
          {items[page].link && (
            <div className={classes.link}>
              <Link to={items[page].link} target='_blank'>
                {items[page].linkLabel}
              </Link>
            </div>
          )}
        </div>
        <div className={classes.contents}>
          {items[page].secondaryButtonText && (
            <Button
              className={classes.secondaryButton}
              onClick={items[page].secondaryButtonAction}
              variant='outlined'
            >
              {items[page].secondaryButtonText}
            </Button>
          )}
          <Button
            className={classes.primaryButton}
            onClick={items[page].primaryButtonAction}
            variant='contained'
            style={{ width: items[page].secondaryButtonText ? '50%' : '100%' }}
          >
            {items[page].primaryButtonText}
          </Button>
        </div>
      </div>
    </Dialog>
  )
}
