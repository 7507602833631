import { useState, useEffect, Fragment } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { getFeatureFlags } from 'api/feature_flags'
import SearchBar from 'components/SearchBar'
import Popover from '@material-ui/core/Popover'
import QuestionIcon from '@material-ui/icons/LiveHelp'
import CallToActionIcon from '@material-ui/icons/CallToAction'
import ChatIcon from '@material-ui/icons/Chat'
import EmailIcon from '@material-ui/icons/Email'
import BranchIcon from '@material-ui/icons/CallSplit'
import TagIcon from '@material-ui/icons/LocalOffer'
import StarIcon from '@material-ui/icons/Star'
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic'
import CalendarIcon from '@material-ui/icons/DateRange'
import PhoneIcon from '@material-ui/icons/PhoneAndroid'
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer'
import FinishedIcon from '@material-ui/icons/AssignmentTurnedIn'
import ThumbsUpDownIcon from '@material-ui/icons/ThumbsUpDown'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import CheckIcon from '@material-ui/icons/DoneOutline'
import CloseIcon from '@material-ui/icons/Close'
import Divider from '@material-ui/core/Divider'
import { useDebounce } from 'use-debounce'
import EmptyState from 'components/EmptyState'
import { ReactComponent as SequenceIcon } from 'img/PurpleSequenceIcon.svg'
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { getIntegrationFeatures } from 'api/integrations'
import { Icon, Tooltip } from 'library/materialUI'

const useStyles = makeStyles(theme => ({
  visitorInteraction: {
    fill: '#658AE5'
  },
  backgroundAction: {
    fill: '#DD64E7'
  },
  agentConnection: {
    fill: '#3de396'
  },
  endOfFlow: {
    fill: '#4A4A4A'
  },
  nodeDiv: {
    display: 'grid',
    gridTemplateColumns: '30px auto',
    gridGap: 10,
    alignItems: 'center',
    padding: '7px 15px',
    fontFamily: 'Poppins, sans serif',
    color: 'rgba(0, 0, 0, 0.54)',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.1)'
    }
  },
  header: {
    fontWeight: 600,
    color: 'rgba(0, 0, 0, 0.54)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '15px 15px 5px 15px'
  },
  nodeList: {
    maxHeight: 260,
    overflowY: 'auto',
    overflowX: 'hidden',
    minHeight: 80
  },
  subHeader: {
    fontWeight: 600,
    color: 'rgba(0, 0, 0, 0.54)',
    padding: '5px 15px'
  },
  searchDiv: {
    padding: '5px 10px 10px 10px',
    width: 245,
    display: 'flex',
    alignItems: 'center'
  },
  nodeName: {
    paddingBottom: 3,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  tabRoot: {
    minWidth: 130
  }
}))

const botNodes = [
  { name: 'Question', icon: QuestionIcon, class: 'visitorInteraction', type: 'options', category: 'Essentials' },
  { name: 'Message', icon: ChatIcon, class: 'visitorInteraction', type: 'chat', category: 'Essentials' },
  { name: 'Live Chat', icon: HeadsetMicIcon, class: 'agentConnection', type: 'live_chat', category: 'Essentials' },
  { name: 'Calendar Invite', icon: CalendarIcon, class: 'agentConnection', type: 'calendar', category: 'Essentials' },
  { name: 'Conditional Branching', icon: BranchIcon, class: 'backgroundAction', type: 'conditional', category: 'Essentials' },
  { name: 'Email Capture', icon: EmailIcon, class: 'visitorInteraction', type: 'email', category: 'Data Capture' },
  { name: 'Phone Capture', icon: PhoneIcon, class: 'visitorInteraction', type: 'phone', category: 'Data Capture' },
  { name: 'Conversation Rating', icon: ThumbsUpDownIcon, class: 'visitorInteraction', type: 'conversation_rating', category: 'Conversation' },
  { name: 'FAQ', icon: QuestionAnswerIcon, class: 'visitorInteraction', type: 'faq', category: 'Other' },
  { name: 'Set Lead Score', icon: StarIcon, class: 'backgroundAction', type: 'lead_score', category: 'Other' },
  { name: 'Conversation Goal', icon: FinishedIcon, class: 'backgroundAction', type: 'goal', category: 'Conversation' },
  { name: 'Tag Conversation', icon: TagIcon, class: 'backgroundAction', type: 'tag', category: 'Conversation' },
  { name: 'Close Conversation', icon: CheckIcon, class: 'backgroundAction', type: 'conversation_status', category: 'Conversation' },
  { name: 'Alert Agent', icon: NotificationImportantIcon, class: 'agentConnection', type: 'alert_agent', category: 'Other' },
  { name: 'Send Email', icon: EmailIcon, class: 'backgroundAction', type: 'send_email', category: 'Other' },
  { name: 'Create Contact', icon: PersonAddIcon, class: 'backgroundAction', type: 'create_contact', category: 'Other', requiredIntegrationFeature: 'crm.create_contact' },
  { name: 'Salesloft', icon: () => Icon({ icon: 'salesloft' }), class: 'backgroundAction', type: 'salesloft', category: 'Other' },
  { name: 'Button', icon: CallToActionIcon, class: 'visitorInteraction', type: 'cta_button', category: 'Other' },
  { name: 'Bot Finished', icon: CheckCircleIcon, class: 'endOfFlow', type: 'end_flow', category: 'Other' }
]

const categories = [
  'Essentials',
  'Data Capture',
  'Conversation',
  'Other'
]

function NodeRow (props) {
  const classes = useStyles()
  const node = props.node
  const addNodeFromEdge = props.addNodeFromEdge
  const nodeData = props.nodeData
  const onClick = () => addNodeFromEdge({
    type: node.type,
    source: nodeData.source,
    left: nodeData.left,
    top: nodeData.top,
    data: node.data
  })
  return (
    <div
      className={classes.nodeDiv}
      onClick={onClick}
    >
      {node.type === 'sequence'
        ? <node.icon style={{ height: 24, width: 24, stroke: '#2B4177' }} />
        : <node.icon className={classes[node.class]} />}
      {node.type === 'sequence' && node.name.length > 20 ? (
        <Tooltip title={node.name} placement='right'>
          <div className={classes.nodeName}>
            {node.name}
          </div>
        </Tooltip>
      ) : (
        <div className={classes.nodeName}>
          {node.name}
        </div>)}
    </div>
  )
}

function NodeList (props) {
  const [loading, setLoading] = useState(true)
  const classes = useStyles()
  const addNodeFromEdge = props.addNodeFromEdge
  const nodeData = props.nodeData
  const search = props.search
  const tabNodes = props.tab ? props.sequences : botNodes
  const [nodes, setNodes] = useState(tabNodes)
  let filteredNodes = tabNodes

  if (search) {
    filteredNodes = tabNodes.filter(n => {
      const searchTerm = search.toLowerCase()
      if (n.name.toLowerCase().includes(searchTerm)) {
        return true
      }
      if (n.category.toLowerCase().includes(searchTerm)) {
        return true
      }
      return false
    })
  }
  useEffect(() => {
    if (loading) {
      getFeatureFlags().then((flags) => {
        // not using session context because of the challenges w/ jsplumb's React
        getIntegrationFeatures().then((response) => {
          const features = response.data.filter(f => {
            const allowMultiple = f.attributes.allow_multiple
            if (allowMultiple) {
              return Boolean(f.attributes.integrations && f.attributes.integrations.length > 0)
            } else {
              return Boolean(f.attributes.integration?.name)
            }
          }).map(f => {
            return f.id
          })
          const n = nodes.filter(i => {
            if (i.requiredIntegrationFeature) {
              return features.includes(i.requiredIntegrationFeature)
            } else if (i.name.toLowerCase() === 'salesloft' && !flags?.salesloft) {
              return false
            } else {
              return true
            }
          })
          setLoading(false)
          setNodes([...n])
        }, (err) => {
          setLoading(false)
          console.error('failed to get features', err)
        })
      })
    }
  }, [nodes, loading])
  if (loading) {
    return (<></>)
  }

  if (props.search) {
    return (
      <div className={classes.nodeList}>
        <div className={classes.subHeader}>
          Search Results
        </div>
        {filteredNodes.length
          ? filteredNodes.map((node, index) => <NodeRow node={node} key={index} addNodeFromEdge={addNodeFromEdge} nodeData={nodeData} />)
          : (
            <div style={{ padding: '10px 0px' }}>
              <EmptyState message='No matching results' />
            </div>)}
      </div>
    )
  } else {
    if (props.tab) {
      return (
        <div className={classes.nodeList}>
          {props.sequences.map((node, index) => <NodeRow node={node} key={index} addNodeFromEdge={addNodeFromEdge} nodeData={nodeData} />)}
        </div>
      )
    } else {
      return (
        <div className={classes.nodeList}>
          {categories.map((c, index) => (
            <Fragment key={index}>
              <div className={classes.subHeader}>
                {c}
              </div>
              {nodes.filter(n => n.category === c).map((node, index) => <NodeRow node={node} key={index} addNodeFromEdge={addNodeFromEdge} nodeData={nodeData} />)}
            </Fragment>
          ))}
        </div>
      )
    }
  }
}

function NodePickerPopover (props) {
  const classes = useStyles()
  const [search, setSearch] = useState('')
  const [tab, setTab] = useState(0)
  const [debouncedSearch] = useDebounce(search, 300)
  const open = Boolean(props.nodeData)
  const id = open ? 'simple-popover' : undefined
  const addNodeFromEdge = props.addNodeFromEdge
  const nodeData = props.nodeData

  const sequences = props.sequences.map(sequence => ({
    name: sequence.name,
    icon: SequenceIcon,
    class: 'sequence',
    type: 'sequence',
    category: 'Sequences',
    data: {
      title: sequence.name,
      description: sequence.description,
      parentSequenceID: sequence.id,
      sequenceFlowID: sequence.sequence_flow_id,
      ports: sequence.ports,
      trackTemplate: true
    }
  }))

  useEffect(() => {
    setTab(0)
  }, [open])

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={props.nodeData?.anchorEl}
      onClose={() => { props.onClose(); setSearch('') }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
    >
      <div style={{ width: 265, height: 415 }}>
        <div className={classes.header}>
          <div>Insert Skill</div>
          <CloseIcon onClick={props.onClose} style={{ cursor: 'pointer' }} />
        </div>
        <div className={classes.searchDiv}>
          <SearchBar search={search} setSearch={setSearch} />
        </div>
        <Divider />
        <Tabs
          value={tab}
          onChange={(e, val) => setTab(val)}
          variant='fullWidth'
          indicatorColor='primary'
          textColor='primary'
        >
          <Tab label='Bot Skills' classes={{ root: classes.tabRoot }} />
          <Tab label='Sequences' classes={{ root: classes.tabRoot }} />
        </Tabs>
        <NodeList
          search={debouncedSearch}
          nodeData={nodeData}
          addNodeFromEdge={addNodeFromEdge}
          sequences={sequences}
          tab={tab}
        />
      </div>
    </Popover>
  )
}

export default NodePickerPopover
