import { contactSearch, getLiveViewOld as getLiveView } from 'api/contacts'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { MapParticipantList } from '../live-view/LiveViewPage'
import useInterval from 'use-interval'
import LiveViewChatBox from '../live-view/LiveViewChatBox'
import { makeStyles } from '@material-ui/core/styles'
import { RichTextEditor } from 'library/richText/RichTextEditor'
import SendIcon from '@material-ui/icons/Send'
import { ReactComponent as InnerChatIcon } from 'img/Icon material-rate-review.svg'
import { initiateConversation } from 'api/conversations'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { SavedReply } from 'classes/savedReplies'
import { ScrollBox } from 'cf-components/ScrollBox'
import { Divider, Fab, MenuItem, Menu, Checkbox, ListItemText, Chip } from '@material-ui/core'
import FilterListIcon from '@material-ui/icons/FilterList'
import { TenantDataContext } from '../agent-chat/tenant-data-context'

const useStyles = makeStyles(theme => ({
  mobileMessageEntryDiv: {
    boxShadow: '0px -5px 4px #88888850',
    position: 'absolute',
    width: '100%',
    height: '77px',
    bottom: 0
  },
  mobileTextEntry: {
    backgroundColor: '#88888820',
    borderRadius: '30px',
    fontSize: '16px',
    color: 'black',
    textAlign: 'center'
  },
  buttons: {
    fontSize: '12px',
    boxShadow: 'none',
    textTransform: 'none',
    color: 'white',
    maxHeight: 24
  },
  replyRow: {
    display: 'grid',
    gridTemplateColumns: 'auto 50px',
    gridGap: 10,
    alignItems: 'center',
    fontSize: '14px',
    fontFamily: 'poppins',
    padding: '5px',
    paddingTop: '12px',
    paddingBottom: '12px'
  },
  replyText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
    fontFamily: 'poppins'
  },
  filterChip: {
    marginRight: 4,
    maxWidth: '100%',
    backgroundColor: '#F0E3FF',
    fontSize: 12,
    color: theme.palette.primary.main,
    height: 'unset',
    '& > span': {
      paddingRight: 8
    },
    '& > svg': {
      color: 'inherit',
      width: 16,
      height: 16
    }
  },
  actionIcon: {
    height: 20,
    cursor: 'pointer'
  },
  menuItem: {
    padding: '3px 8px',
    fontSize: '12px'
  }
}))

function InsertButton (props: any) {
  const classes = useStyles()
  const insertReply = props.insertReply
  const handleInsert = () => {
    const reply = props.contents.replace('<p>', '').replace('</p>', '')
    insertReply(reply)
  }

  return (
    <Fab
      variant='extended'
      size='small'
      className={classes.buttons}
      color='primary'
      onClick={() => handleInsert()}
    >
      Insert
    </Fab>
  )
}

function ReplyRow (props: any) {
  const classes = useStyles()
  const insertReply = props.insertReply
  const contents = props.contents
  return (
    <div>
      <div
        className={classes.replyRow}
      >
        <div className={classes.replyText}>
          {props.title}
        </div>
        <InsertButton insertReply={insertReply} contents={contents} />
      </div>
      <Divider />
    </div>
  )
}

function Filter (props: any) {
  const classes = useStyles()
  const filters = props.filters
  const handleChange = props.handleChange
  const { replyCategories: categories } = useContext(TenantDataContext)
  const [anchorEl, setAnchorEl] = useState<EventTarget | null>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: Event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  if (!categories?.length) {
    return <></>
  }

  return (
    <>
      {!filters.length ? <></> : (
        <Chip
          className={classes.filterChip}
          label={filters.length + ' filter' + (filters.length === 1 ? '' : 's')}
          onDelete={() => handleChange('reset')}
          deleteIcon={<CloseIcon />}
        />
      )}
      <div>
        <FilterListIcon
          color='primary'
          onClick={(e: any) => handleClick(e)}
          className={classes.actionIcon}
        />
        <Menu
          id='long-menu'
          anchorEl={anchorEl as Element}
          keepMounted
          open={open}
          onClose={handleClose}
        >
          <MenuItem value='' className={classes.menuItem}>Filter By</MenuItem>
          {
            categories.map((c, index) => (
              <MenuItem value={c} key={index} className={classes.menuItem} onClick={() => handleChange(c)}>
                <Checkbox checked={filters.indexOf(c) > -1} color='primary' style={{ padding: 4 }} />
                <ListItemText primary={c} />
              </MenuItem>
            ))
          }
        </Menu>
      </div>
    </>
  )
}

function SavedReplyList (props: any) {
  const setMessage = props.setMessage
  const replies = props.replies
  const insertReply = (value: any) => setMessage(value)
  const [filters, setFilters] = useState([])

  function handleChange (value: any) {
    if (value === 'reset') {
      setFilters([])
    } else if (filters.indexOf(value) > -1) {
      setFilters([...filters].filter(f => f !== value))
    } else {
      setFilters([...filters, value])
    }
  }

  const filteredReplies = [].concat(replies).filter(r =>
    filters.length === 0 || (r.categories &&
      (r.categories.filter(
        value => filters.indexOf(value) !== -1)).length > 0))

  return (
    <div style={{ padding: '5px' }}>
      <div style={{ marginRight: '17px', paddingTop: '10px', paddingBottom: '10px', textAlign: 'right' }}>
        <Filter
          replies={replies}
          handleChange={handleChange}
          filters={filters}
        />
      </div>
      <ScrollBox>
        {filteredReplies.map(row => (
          <ReplyRow
            title={row.name}
            key={row.id}
            contents={row.content}
            insertReply={insertReply}
          />
        ))}
      </ScrollBox>
    </div>
  )
}

function SavedRepliesButton (props: any) {
  const setRepliesWindow = props.setRepliesWindow

  return (
    <div
      style={{ width: '32px', height: '32px', borderRadius: '16px', backgroundColor: '#9933FF', display: 'flex', alignItems: 'center' }}
      onClick={() => setRepliesWindow(true)}
    >
      <InnerChatIcon style={{ marginLeft: '8px' }} />
    </div>
  )
}

export function QuickChatPage () {
  const editorRef = useRef(null)
  const classes = useStyles()
  const linkParam = useRouteMatch('/quick-chat/:participantId')
  const participantId = linkParam?.params?.participantId
  const [participant, setParticipant] = useState<{ id: number, currentUrl: string, recentConversationTimestamp?: string, recentConversationID?: number } | null>(null)
  const history = useHistory()
  const [seekParticipant, setSeekParticipant] = useState(false)
  const [timeOut, setTimeOut] = useState(false)
  const [repliesWindow, setRepliesWindow] = useState(false)
  let hasRecentConversation = false
  const [message, setMessage] = useState('')
  const { data: replies, isLoading } = SavedReply.loadAll()
  function sendMessage () {
    const visitorParticipantID = participant?.id
    const startUrl = participant?.currentUrl
    initiateConversation({ message, visitorParticipantID, startUrl })
      .then(response => {
        const link = '/#/chat/' + response.data.id
        window.open(link, '_self')
      })
    setMessage('')
  }

  const refreshParticipant = useCallback(() => {
    setTimeout(() => {
      getLiveView()
        .then(response => {
          if (response.data) {
            const liveViewParticipant = response.data.filter((row: any) => row.id === parseInt(participantId))
            if (liveViewParticipant.length > 0) {
              const contactIDs = response.data.map((row: any) => row.relationship?.is_person?.data?.id).filter(id => id)
              if (contactIDs.length) {
                contactSearch({ contactIDs }).then(contactResponse => {
                  let contactDict = {}
                  contactResponse.data.map(
                    (contact: any) => (contactDict = { ...contactDict, [contact.id]: { ...contact } })
                  )
                  const tempParticipant = MapParticipantList(liveViewParticipant, contactDict)
                  if (tempParticipant) {
                    setParticipant(tempParticipant[0])
                  }
                })
              } else {
                const tempParticipant = MapParticipantList(liveViewParticipant, {})
                if (tempParticipant) {
                  setParticipant(tempParticipant[0])
                }
              }
            }
          }
        })
    }, 1000)
  }, [participantId])

  useEffect(() => {
    if (!participant) {
      setSeekParticipant(true)
    } else {
      setSeekParticipant(false)
    }
  }, [setSeekParticipant, participant])

  useInterval(() => {
    if (seekParticipant) {
      refreshParticipant()
    }
  }, 2000)

  useInterval(() => {
    if (!participant) {
      setTimeOut(true)
    }
  }, 20000)

  if (!participant) {
    if (timeOut) {
      return (
        <div style={{ fontFamily: 'poppins', marginTop: '35%', display: 'flex', flexDirection: 'column', textAlign: 'center', justifyContent: 'center' }}>
          <div>
            Looks like this visitor is no longer on your site...
          </div>
          <div style={{ color: '#9933FF', marginTop: '5px' }} onClick={() => window.open('#/chat', '_self')}>
            Return to Chat
          </div>
        </div>
      )
    }

    return (
      <div style={{ fontFamily: 'poppins', marginTop: '35%', display: 'flex', textAlign: 'center', justifyContent: 'center' }}>
        <div>
          Loading Quick Chat
        </div>
      </div>
    )
  } else {
    const recentConversationTimestamp = participant.recentConversationTimestamp
    const recentConversationID = participant.recentConversationID
    if (recentConversationTimestamp) {
      const now = new Date()
      const recent = new Date(recentConversationTimestamp)
      const minutesSinceConversation = (now.valueOf() - recent.valueOf()) / 1000 / 60
      if (minutesSinceConversation < 60) {
        hasRecentConversation = true
      }
    }
    return (
      <>
        {repliesWindow ? (
          <div>
            <div style={{ backgroundColor: '#9933FF', padding: '16px 8px', display: 'grid', gridTemplateColumns: '200px auto 50px', gridGap: 15, alignItems: 'center' }}>
              <div style={{ fontFamily: 'poppins', fontSize: '19px', color: 'white', marginLeft: '10px' }}>
                Saved Replies
              </div>
              <div />
              <IconButton
                aria-label='close'
                onClick={() => setRepliesWindow(false)}
                style={{ color: '#FFF' }}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <div>
              {!isLoading && (
                <SavedReplyList setMessage={setMessage} replies={replies.list} />
              )}
            </div>
          </div>
        ) : (
          <LiveViewChatBox
            participant={participant}
            close={() => history.push('/chat')}
            mobile
            mobileSetRepliesWindow={setRepliesWindow}
          />
        )}
        <div className={classes.mobileMessageEntryDiv}>
          {hasRecentConversation && (
            <div style={{ marginTop: '5px', fontFamily: 'poppins', textAlign: 'center', fontSize: '12px', color: 'grey' }}>
              Visitor is already in a conversation
            </div>
          )}
          {!hasRecentConversation && (
            <div style={{ display: 'grid', gridTemplateColumns: '32px auto 5%', gridGap: 10, marginTop: '17px', marginLeft: '10px' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <SavedRepliesButton setRepliesWindow={setRepliesWindow} />
              </div>
              <div className={classes.mobileTextEntry}>
                <RichTextEditor
                  message={message}
                  editorRef={editorRef}
                  onChange={(value) => setMessage(value)}
                  noToolbar
                  placeholder='Type your message...'
                />
              </div>
              <div
                style={{ display: 'flex', alignItems: 'center' }}
                onClick={() => sendMessage()}
              >
                <SendIcon
                  style={{ fill: '#9933FF' }}
                />
              </div>
            </div>
          )}
          {hasRecentConversation && (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '5px', marginBottom: '10px' }}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontFamily: 'poppins', height: '40px', width: '80%', borderRadius: '4px', backgroundColor: '#9933FF', color: 'white' }} onClick={() => window.open('#/chat/' + recentConversationID, '_self')}>
                VIEW CONVERSATION
              </div>
            </div>
          )}
        </div>
      </>
    )
  }
}
