import { doGet, doDelete } from './api'

function getCronofyStatus () {
  return doGet({
    path: '/api/integrations/cronofy/status',
    extraHeaders: { cache: 'no-store' }
  })
}

function deleteCronofyStatus () {
  return doDelete({ path: '/api/integrations/cronofy/status' })
}

function getCalendarEvents ({ admin }) {
  let url = '/api/integrations/cronofy/my_upcoming_events'
  if (admin) {
    url += '?all_users=true'
  }

  return doGet({
    path: url
  })
}

function getCalendarEventsLog ({ admin }) {
  let path = '/calendar_events/events'

  if (admin) {
    path += '?all_users=true'
  }

  return doGet({
    useChatURL: true,
    path
  })
}

export { getCronofyStatus, deleteCronofyStatus, getCalendarEvents, getCalendarEventsLog }
