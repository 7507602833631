import React, { useState, useEffect, useContext } from 'react'
import AppPage from 'cf-components/AppPage'
import TestList from './TestList'
import { getBotTests, deleteBotTest } from 'api/bot_tests'
import { components, SessionContext } from 'session-context'
import CreateTestModal from './CreateTestModal'
import { Redirect } from 'react-router-dom'
import EditEndCriteriaModal from './EditEndCriteriaModal'
import AccessControl from 'components/AccessControl'
import { ShareContext } from 'share-context'

const BotTestingPage = (props) => {
  const { user } = useContext(SessionContext)
  const [createModalOpen, setCreateTestModalOpen] = useState(false)
  const [editEndCriteriaModalOpen, setEditEndCriteriaModalOpen] = useState(false)
  const [endCriteriaData, setEndCriteriaData] = useState({})
  const [botTests, setBotTests] = useState(null)
  const [redirect, setRedirect] = useState()
  const [name, setName] = useState('')
  const [testType, setTestType] = useState('')
  const [showArchived, setShowArchived] = useState(false)
  const chatServiceUrl = user.links.chat_service
  const { flags } = useContext(ShareContext)

  function toggleArchived () {
    if (showArchived) {
      setShowArchived(false)
    } else {
      setShowArchived(true)
    }
  }

  useEffect(() => {
    getBotTests({ chatServiceUrl, includeDeleted: 'any' })
      .then(response => {
        setBotTests(response.data)
      })
  }, [chatServiceUrl])

  const startCreateNewTest = () => {
    setCreateTestModalOpen(true)
  }

  const startEditEndCriteria = ({ id, durationCriteria, startTime, endTime, name }) => {
    const newEndCriteria = { id: id, durationCriteria: durationCriteria, startTime: startTime, endTime: endTime, name: name }
    setEndCriteriaData({ ...newEndCriteria })
    setEditEndCriteriaModalOpen(true)
  }

  const startEditTest = (testID, name, type) => {
    setRedirect(`/bot_tests/${testID}`)
    setName(name)
    setTestType(type)
  }

  const deleteTest = (testID) => {
    deleteBotTest({ chatServiceUrl, testID })
      .then(() => {
        getBotTests({ chatServiceUrl, includeDeleted: 'any' })
          .then(response => {
            setBotTests(response.data)
          })
      })
  }

  const filterOptions = {
    filterValues: [
      { value: showArchived, label: 'Show Archived Tests', toggle: toggleArchived }
    ]
  }

  let rows = botTests
  if (!showArchived && botTests) {
    rows = botTests.filter(test => test.attributes.deleted_timestamp == null)
  }

  if (redirect) {
    const url = redirect + '?name=' + name + '&type=' + testType
    return <Redirect push to={url} />
  }

  document.title = 'Bot Testing | Signals'

  if (!flags['bot-testing']) {
    return <div />
  }

  return (
    <AccessControl requiredComponent={components.BOT_TESTS}>
      <AppPage
        title='Bot Testing'
        action={startCreateNewTest}
        actionText='New Test'
      >
        <TestList
          rows={rows}
          onDelete={deleteTest}
          onEdit={startEditTest}
          onEditEndCriteria={startEditEndCriteria}
          filterOptions={filterOptions}
        />
        <CreateTestModal
          open={createModalOpen}
          onHide={() => setCreateTestModalOpen(false)}
          onSubmit={() => setRedirect('/bot_tests/new')}
          setName={setName}
          setTestType={setTestType}
          chatServiceUrl={chatServiceUrl}
        />
        <EditEndCriteriaModal
          open={editEndCriteriaModalOpen}
          onHide={() => setEditEndCriteriaModalOpen(false)}
          endCriteriaData={endCriteriaData}
          setEndCriteriaData={setEndCriteriaData}
          onDone={() => setEditEndCriteriaModalOpen(false)}
        />
      </AppPage>
    </AccessControl>
  )
}

export default BotTestingPage
