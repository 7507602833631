import { useReducer } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Modal } from 'library/Modal'
import AvatarEditor from 'react-avatar-editor'

const useStyles = makeStyles(() => ({
  photoEditorContent: {
    margin: 'auto',
    width: '100%',
    textAlign: 'center',
    verticalAlign: 'center'
  },
  controls: {
    display: 'flex',
    margin: 'auto'
  },
  inputSlider: {
    verticalAlign: 'center',
    marginLeft: 5
  }
}))

const initialState = {
  scale: 1,
  position: { x: 0.5, y: 0.5 },
  rotate: 0,
  height: 220,
  width: 220,
  border: 5
}

const PhotoEditor = ({ handleSave, onHide, open, editor, photo }) => {
  const classes = useStyles()
  const [state, dispatch] = useReducer(reducer, initialState)

  function reducer (state, action) {
    switch (action.type) {
      case 'update':
        return { ...state, [action.name]: action.value }
      default:
        throw new Error()
    }
  }

  const handlePositionChange = position => {
    dispatch({ type: 'update', name: 'position', value: position })
  }

  const handleScale = e => {
    const scale = parseFloat(e.target.value)
    dispatch({ type: 'update', name: 'scale', value: scale })
  }

  return (
    <Modal
      open={open}
      title='Edit Icon'
      size='xs'
      saveBtnText='Save'
      onHide={() => {
        onHide()
        dispatch({ type: 'update', name: 'scale', value: 1 })
        dispatch({ type: 'update', name: 'position', value: { x: 0.5, y: 0.5 } })
      }}
      handleSave={handleSave}
      saveIcon='save'
    >
      <div className={classes.photoEditorContent}>
        <AvatarEditor
          ref={editor}
          image={photo}
          width={state.width}
          height={state.height}
          position={state.position}
          rotate={parseFloat(state.rotate)}
          border={state.border}
          scale={state.scale}
          borderRadius={220}
          onPositionChange={handlePositionChange}
        />
        <br />
        <div className={classes.controls}>
          <div className={classes.controls}>
            <span style={{ fontWeight: 'bold' }}>ZOOM</span>
            <input
              name='scale'
              type='range'
              onChange={handleScale}
              min='0.8'
              max='2'
              step='0.01'
              defaultValue='1'
              className={classes.inputSlider}
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default PhotoEditor
