import { useDoQuery } from './useDoQuery'
import { doPatch } from '../api/api'

interface AgentList {
  list: Agent[]
  dict: {[id: Agent['id']]: Agent}
}

export class Agent {
  id: number
  userID: number
  availability: string
  name: string
  outOfOffice: Array<any>

  constructor ({ row }: { row: Record<string, any> }) {
    const agent = row.attributes
    this.id = row?.id
    this.name = agent?.agent_name || ''
    this.availability = agent?.status || null
    this.userID = row?.relationships.is_user.data.id
    this.outOfOffice = agent?.out_of_office_instances
  }

  static loadAll (): { data: AgentList, isLoading: boolean } {
    return useDoQuery({ path: '/participants', useChatURL: true, objectClass: Agent })
  }

  static update (ID: Agent['id'] | null, status: string) : Promise<any> {
    const path = `/participants/${ID}`
    const data = {
      type: 'participants',
      attributes: {
        status: status
      }
    }
    return doPatch({ path, data, useChatURL: true })
  }
}
