import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActionArea from '@material-ui/core/CardActionArea'
import MemoryIcon from '@material-ui/icons/Memory'
import Tooltip from '@material-ui/core/Tooltip'

const useStyles = makeStyles(theme => ({
  integrationCard: {
    width: 285,
    height: 220,
    margin: 15,
    marginTop: 30,
    marginBottom: 30
  },

  icon: {
    width: 40,
    height: 40
  },
  description: {
    height: 80,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitLineClamp: '2',
    paddingBottom: 5
  },
  enabled: {
    color: '#08c20b'
  },
  disabled: {
    color: 'gray'
  },
  disabledCard: {
    opacity: '0.75',
    filter: 'grayscale(100%)',
    width: 285,
    margin: 15,
    marginTop: 30,
    marginBottom: 30
  },
  disabledText: {
    border: '1px solid #ccccff',
    textAlign: 'center',
    padding: 5
  },
  whiteSpace: {
    height: 20
  }
}))

const IntegrationCard = (props) => {
  const classes = useStyles()
  const integration = props.integration
  const activeIntegrations = props.activeIntegrations
  const disabled = props.disabled
  let connected

  if (activeIntegrations.length > 0) {
    connected = activeIntegrations.includes(integration.key)
  }

  return (
    <>
      <Card className={disabled ? classes.disabledCard : classes.integrationCard} raised>
        <CardActionArea onClick={disabled ? () => { } : props.onClick} style={{ height: '100%' }}>
          <CardContent>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <img src={integration.icon} className={classes.icon} alt='Integration Partner Logo' />
              <Tooltip title={connected ? 'Connected' : 'Not Connected'} placement='right'>
                <MemoryIcon className={connected ? classes.enabled : classes.disabled} style={{ fontSize: 40 }} />
              </Tooltip>
            </div>
            <Typography gutterBottom variant='h6' style={{ paddingTop: 10 }}>
              {integration.name}
            </Typography>
            <Typography display='block' gutterBottom variant='body2' className={classes.description}>
              {integration.description}
            </Typography>
            {disabled
              ? (
                <div className={classes.disabledText}>
                  Not Included in your plan
                </div>
              )
              : <div className={classes.whiteSpace} />}
          </CardContent>
        </CardActionArea>
      </Card>
    </>
  )
}

export default IntegrationCard
