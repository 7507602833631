import { Button, Icon } from 'library/materialUI'
import { makeStyles } from '@material-ui/styles'
import { ButtonProps } from '@material-ui/core/Button'

const useStyles = makeStyles(theme => ({
  startIcon: {
    marginRight: 0
  },
  label: {
    marginLeft: 2
  },
  root: {
    minWidth: 0,
    padding: 5,
    marginRight: 5
  }
}))

interface CloseButtonProps extends ButtonProps {
  handleClose: () => void
  iconColor?: string
  variant?: 'text' | 'outlined' | 'contained' | undefined
}
export function CloseButton ({ handleClose, variant = 'contained', iconColor = 'white' }: CloseButtonProps) {
  const classes = useStyles()

  return (
    <Button
      variant={variant}
      color='primary'
      size='small'
      startIcon={<Icon icon='close' color={iconColor} />}
      onClick={handleClose}
      classes={{
        startIcon: classes.startIcon,
        label: classes.label,
        root: classes.root
      }}
    />
  )
}
