import { makeStyles } from '@material-ui/core/styles'
import { Icon } from './materialUI/Icon'
import { Select } from 'cf-components/material-wrappers/MaterialComponents'
import Picker, { PickerObjectType } from 'cf-components/Pickers'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    padding: '20px',
    flexDirection: 'column',
    borderRadius: '5px',
    backgroundColor: '#FBFAFE',
    border: '1px solid #C4ACEE'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '10px'
  },
  filterName: {
    fontSize: '16px',
    color: '#000000BF',
    fontWeight: 'bold'
  },
  filters: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column'
  },
  filterContainer: {
    display: 'flex',
    marginRight: '10px',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginTop: '20px'
  },
  removeButton: {
    cursor: 'pointer',
    marginLeft: '10px'
  },
  selectDiv: {
    width: '100%',
    marginRight: '10px',
    marginLeft: '10px',
    maxWidth: '200px'
  },
  addCondition: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    color: '#343434',
    marginTop: '20px',
    fontSize: '14px',
    fontWeight: 'bold',
    maxWidth: '180px',
    '&:hover': {
      backgroundColor: '#E3E3E3'
    },
    borderRadius: '5px',
    padding: '10px'
  }
}))

const comparisonMapping = {
  eq: 'is',
  ne: 'is not',
  in: 'in',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  not_in: 'not in',
  gt: 'greater than',
  lt: 'less than',
  gte: 'greater than or equal',
  lte: 'less than or equal'
}

interface CriteriaGroupProps {
  name: string
  ruleName: string
  filters: { prop: string, cmp: string, value: string }[]
  dispatch: (value: any) => any
  routingMap: {
    value: string,
    label: string,
    compList: { value: string, label: string }[]
    group: string,
    options: string[],
    usePicker: boolean,
    pickerObj: PickerObjectType,
    multi: boolean
  }[]
  groupIndex: number
}

export default function CriteriaGroup ({ name, ruleName, filters, dispatch, routingMap, groupIndex }: CriteriaGroupProps) {
  const classes = useStyles()
  const groupList = routingMap.map(group => group.group)
  let finalOptions: any[] = []
  groupList.forEach(group => {
    if (finalOptions.find(option => option.value === group)) return
    finalOptions = [
      ...finalOptions,
      { value: group, label: group, disabled: true },
      ...routingMap
        .filter(filter => filter.group === group)
        .map(filter => ({ value: filter.value, label: filter.label }))
    ]
  })

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div className={classes.filterName}>
          {name}
        </div>
        <div onClick={() => dispatch({ type: 'deleteGroup', ruleName: ruleName, groupIndex: groupIndex })} className={classes.removeButton}>
          <Icon icon='delete' size='md' color='#343434' />
        </div>
      </div>
      <div className={classes.filters}>
        {filters?.map((filter, index) => {
          const curFilter = routingMap.find(group => group.value === filter.prop)
          return (
            <div key={index} className={classes.filters}>
              <div className={classes.filterContainer}>
                <div className={classes.selectDiv}>
                  <Select
                    value={filter.prop}
                    options={finalOptions}
                    onChange={(value: string) => { dispatch({ type: 'prop', ruleName: ruleName, groupIndex: groupIndex, filterIndex: index, value: value }) }}
                  />
                </div>
                <div className={classes.selectDiv} style={{ maxWidth: 120 }}>
                  <Select
                    value={filter.cmp}
                    options={curFilter?.compList.map(comp => ({ value: comp.value, label: comparisonMapping[comp.value] })) || []}
                    onChange={(value: string) => { dispatch({ type: 'cmp', ruleName: ruleName, groupIndex: groupIndex, filterIndex: index, value: value }) }}
                  />
                </div>
                <div className={classes.selectDiv}>
                  {routingMap.find(group => group.value === filter.prop)?.usePicker ? (
                    <Picker
                      objectType={curFilter?.pickerObj}
                      placeholder='Select Segment'
                      selection={filter.value}
                      setSelection={(value: any) => { dispatch({ type: 'val', ruleName: ruleName, groupIndex: groupIndex, filterIndex: index, value: value }) }}
                      multiple={curFilter?.multi || false}
                    />
                  ) : (
                    <div>
                      <Select
                        value={curFilter?.multi ? (Array.isArray(filter.value) ? filter.value : [filter.value]) : filter.value}
                        options={curFilter?.options.map(option => ({ value: option, label: option })) || []}
                        onChange={(value: any) => { dispatch({ type: 'val', ruleName: ruleName, groupIndex: groupIndex, filterIndex: index, value: value }) }}
                        multiple={curFilter?.multi || false}
                      />
                    </div>
                  )}
                </div>
                <div
                  onClick={() => dispatch({ type: 'deleteFilter', ruleName: ruleName, groupIndex: groupIndex, filterIndex: index })}
                  className={classes.removeButton}
                >
                  <Icon icon='outlinedCancel' size='md' color='#343434' />
                </div>
              </div>
              {index !== filters.length - 1 && (
                <div style={{ marginTop: '20px' }}>
                  AND
                </div>
              )}
            </div>
          )
        })}
      </div>
      <div onClick={() => dispatch({ type: 'addFilter', ruleName: ruleName, groupIndex: groupIndex })} className={classes.addCondition}>
        <div style={{ marginRight: '10px' }}>
          <Icon icon='circleAdd' size='md' color='#343434' />
        </div>
        <div>
          Add "AND" Condition
        </div>
      </div>
    </div>
  )
}
