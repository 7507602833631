import { doPatch } from 'api/api'
import { BlockedPage } from 'pages/settings/BlockedPages/BlockedPages'
import { useDoQuery } from './useDoQuery'

export class ChatSettings {
  domains: string[]
  excludedDomains: string[]
  blockedPages: BlockedPage[]
  packetCode: string
  buttonColor?: string
  primaryColor?: string

  static loadOne (): { data: ChatSettings, isLoading: boolean } {
    return useDoQuery({ useChatURL: true, path: '/chat_settings', objectClass: ChatSettings })
  }

  static update (updates: Partial<ChatSettings>, silent = false) {
    const path = '/chat_settings'
    const data = {
      type: 'chat_settings',
      attributes: updates
    }
    return doPatch({ path, data, useChatURL: true, silent: silent })
  }

  constructor ({ row }: { row: Record<string, any> }) {
    const settings = row.attributes
    this.domains = settings.domains
    this.excludedDomains = settings.excluded_domains
    this.blockedPages = settings.blocked_pages
    this.packetCode = settings.packet_code
    this.buttonColor = settings.button_color
    this.primaryColor = settings.primary_color
  }
}
