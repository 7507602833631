import { makeStyles } from '@material-ui/core/styles'
import { Card, Icon, Popper } from 'library/materialUI'
import { useContext } from 'react'
import { FollowupScreen } from './FollowupScreen'
import { NPSScore } from './NPSScore'
import { NPSPromptContext, NPSPromptContextProvider } from './NPSPromptContext'
import { ErrorBoundary } from 'react-error-boundary'
import Loader from 'library/loading/Loader'

const useStyles = makeStyles(theme => ({
  npsPrompt: {
    width: 570,
    padding: '15px 20px 20px 20px'
  },
  confirmationSnack: {
    padding: '20px 30px',
    font: 'normal normal 600 16px/25px Poppins',
    display: 'flex'
  }
}))

function NPSPromptContents () {
  const { score, resID } = useContext(NPSPromptContext)

  if (score !== null && resID) {
    return (
      <FollowupScreen />
    )
  } else if (score !== null && !resID) {
    return (
      <div style={{ height: '167px', width: '610px' }}>
        <Loader type='spinner' />
      </div>
    )
  } else {
    return (
      <NPSScore />
    )
  }
}

function NPSConfirmation ({ message }: { message: string }) {
  const classes = useStyles()
  return (
    <Card>
      <div className={classes.confirmationSnack}>
        <Icon icon='check' color='#55AB58' />
        <div style={{ marginLeft: 8 }}>
          {message}
        </div>
      </div>
    </Card>
  )
}

export function NPSPrompt () {
  const classes = useStyles()

  return (
    <ErrorBoundary fallback={<></>}>
      <NPSPromptContextProvider>
        <NPSPromptContext.Consumer>
          {value => (
            <Popper
              open={value.open}
              placement='bottom'
              style={{ left: '50%', bottom: 20, top: undefined, transform: 'translateX(-50%)', zIndex: 1000 }}
            >
              {value.confirmationMessage
                ? <NPSConfirmation message={value.confirmationMessage} />
                : (
                  <Card>
                    <div className={classes.npsPrompt}>
                      <NPSPromptContents />
                    </div>
                  </Card>)}
            </Popper>
          )}
        </NPSPromptContext.Consumer>
      </NPSPromptContextProvider>
    </ErrorBoundary>
  )
}
