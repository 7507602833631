import React from 'react'
import { Modal, ModalSection } from 'library/Modal'
import theme from 'theme'

function DeleteModal (props) {
  const title = props.title

  const message = props.message || 'Are you sure you want to delete this ' + props.object + '?'

  return (
    <Modal
      title={title}
      onHide={props.onHide}
      open={props.open}
      saveBtnText={props.saveButtonText || 'Delete'}
      handleSave={props.deleteObject}
      saveIcon='delete'
      saveBtnColor={theme.palette.error.main}
      size='xs'
    >
      <ModalSection>
        <div>{message}</div>
      </ModalSection>
    </Modal>
  )
}

export default DeleteModal
