import { Modal, ModalSection } from 'cf-components/Modal';
import Team from 'classes/teams';
import AddTeamMateIcon from '@material-ui/icons/PersonAdd'
import CreateNewIcon from '@material-ui/icons/AddBox'
import { isEqual } from 'lodash';
import { useReducer } from 'react';
import Picker from 'cf-components/Pickers';
import { TextBox } from 'cf-components/material-wrappers/MaterialComponents';

interface TeamModalProps {
  createTeam?: boolean
  onHide: () => void
  team: Team
}

const reducer = (state: Team, changes: any) => {
  return { ...state, ...changes }
}

export function TeamModal (props: TeamModalProps) : JSX.Element {
  const { createTeam, onHide, team } = props
  const [state, dispatch] = useReducer(reducer, team)
  const title = createTeam ? 'Create New Team' : 'Add Team Members'
  const saveText = createTeam ? 'CREATE' : 'ADD AGENTS'
  const saveIcon = createTeam ? CreateNewIcon : AddTeamMateIcon
  const disabled = createTeam ? (!state.name || state.teamMemberIDs.length === 0) : isEqual(team.teamMemberIDs, state.teamMemberIDs)

  const saveTeam = (): void => {
    Team.save(state).then(() => onHide())
  }

  const leftButtonProps = {
    text: 'CANCEL',
    action: props.onHide,
    variant: 'text' as const
  }

  const rightButtonProps = {
    text: saveText,
    action: saveTeam,
    icon: saveIcon,
    disabled: disabled
  }

  const teamNameSection = (
    <ModalSection
      title='Team Name'
      subtitle='Enter a name for your new team.'
    >
      <TextBox
        value={state.name}
        onChange={(value: string) => dispatch({ name: value })}
      />
    </ModalSection>
  )

  return (
    <Modal
      title={title}
      onHide={onHide}
      open
      size='sm'
      mode='plain'
      rightButtonProps={rightButtonProps}
      leftButtonProps={leftButtonProps}
    >
      {createTeam
      ? teamNameSection
        : null}
      <ModalSection
        title='Add Agents to Team'
        subtitle='Select one or more agents from the list below to add this team.'
      >
        <Picker
          selection={state.teamMemberIDs}
          setSelection={(value: any) => dispatch({ teamMemberIDs: value })}
          objectType='users'
          multiple
        />
      </ModalSection>
    </Modal>
  )
}
