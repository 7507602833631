import { Dialog, Button } from 'library/materialUI'
import ScoringIntroBanner from 'img/scoring-intro-banner.svg'
import { makeStyles } from '@material-ui/core/styles'
import { saveSettings } from 'api/user_display_settings'

const useStyles = makeStyles(theme => ({
  containerDiv: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  imageDiv: {
    width: '648px',
    height: '299px'
  },
  title: {
    fontFamily: 'poppins',
    fontSize: '24px',
    paddingBottom: '10px',
    fontWeight: 'bold'
  },
  body: {
    display: 'flex',
    flexDirection: 'row',
    fontFamily: 'poppins',
    fontSize: '14px',
    color: '#000000BF',
    opacity: '.75',
    paddingBottom: '10px'
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'flex-end',
    alignItems: 'flex-end'
  },
  paper: {
    minWidth: '648px',
    borderRadius: '10px'
  },
  noThanksButton: {
    backgroundColor: 'white',
    color: '#00000080',
    fontSize: '14px',
    margin: '10px',
    marginBottom: '20px'
  },
  setupButton: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    margin: '10px',
    marginBottom: '20px',
    marginRight: '20px'
  },
  iconDiv: {
    marginRight: '5px',
    marginTop: '2px'
  }
}))

interface ScoringIntroductionModalProps {
  open: boolean
  onHide: () => void
  onSetup: () => void
}

export default function ScoringIntroductionModal ({ open, onHide, onSetup }: ScoringIntroductionModalProps) {
  const classes = useStyles()

  const handleClose = () => {
    onHide()
  }

  const handleNoThanks = () => {
    onHide()
    saveSettings({ kind: 'score_intro', settings: { 'score-intro-modal': { complete: true } }, post: false })
  }

  const handleSetup = () => {
    onHide()
    saveSettings({ kind: 'score_intro', settings: { 'score-intro-modal': { complete: true } }, post: false })
    onSetup()
  }

  return (
    <div>
      {open && (
        <Dialog
          onClose={handleClose}
          open={open}
          fullWidth
          classes={{ paper: classes.paper }}
        >
          <div className={classes.containerDiv}>
            <div className={classes.imageDiv}>
              <img src={ScoringIntroBanner} alt='scoring_intro_banner' style={{ width: '648px' }} />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', margin: '30px' }}>
              <div className={classes.title}>
                Configure your Signals score
              </div>
              <div className={classes.body}>
                Tell us about your Average Deal Cycle, Intent, & Fit Factors, and more so that your Signals Score can help you know which accounts are:
              </div>
              <div className={classes.body}>
                <ul>
                  <li>the best fit</li>
                  <li>displaying the most intent</li>
                  <li>most likely to become a customer</li>
                </ul>
              </div>
            </div>
            <div className={classes.footer}>
              <div>
                <Button
                  className={classes.noThanksButton}
                  variant='contained'
                  onClick={() => handleNoThanks()}
                >
                  NO THANKS
                </Button>
              </div>
              <div>
                <Button
                  className={classes.setupButton}
                  variant='contained'
                  onClick={() => handleSetup()}
                >
                  SET IT UP NOW
                </Button>
              </div>
            </div>
          </div>
        </Dialog>
      )}
    </div>
  )
}
