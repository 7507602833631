import React, { useRef } from 'react';
import ReactQuill from 'react-quill';
import { EditorProps } from './RichTextEditor';

function SimpleTextEditor (props: EditorProps) {
  const ref = useRef<any>(null)
  const editorRef = props.editorRef || ref
  const style: { [k: string]: any } = {}
  const theme = 'bubble'

  const onChange = (str: string) => {
    if (str !== props.message) {
      props.onChange(str)
    }
  }

  const keyPressed = (event: any) => {
    if (!props.onEnter) { return }
    if (event.key === 'Enter' && event.shiftKey) {
      return
    }
    if (event.key === 'Enter') {
      props.onEnter()
    }
  }

  return (
    <div>
      <ReactQuill
        ref={editorRef}
        modules={{ toolbar: false }}
        value={props.message}
        onChange={onChange}
        placeholder={props.placeholder}
        onKeyUp={(e) => keyPressed(e)}
        style={style}
        theme={theme}
      />
    </div>
  )
}

export default React.memo(SimpleTextEditor)
