import { createContext, useState, useEffect } from 'react';
import {
  Emitter,
  EVENT_TYPE
} from 'emitter'

interface IHelpCenterContext {
  pathLinks: string[]
  navigateForward: (path: string) => void
  navigateBack: () => void
  navigate: (fullPath: string[]) => void
  customProps?: { [key: string]: any}
}

export const HelpCenterContext = createContext<IHelpCenterContext>({
  pathLinks: [],
  navigateBack: () => undefined,
  navigateForward: () => undefined,
  navigate: () => undefined,
  customProps: {}
})

type HelpCenterProps = {
  children: any
  openHelpCenter: () => void
  closeHelpCenter: () => void
}

export function HelpCenterContextProvider ({ children, openHelpCenter, closeHelpCenter }: HelpCenterProps): JSX.Element {
  const [pathLinks, setPathLinks] = useState(['home'])
  const [customProps, setCustomProps] = useState({})

  useEffect(() => {
    Emitter.on(EVENT_TYPE.HELP_CENTER, (payload: any) => {
      setPathLinks(payload.path.split('/'))
      if (payload.open) {
        closeHelpCenter()
      } else {
        openHelpCenter()
      }
      if (payload.customProps) {
        setCustomProps(payload.customProps)
      }
    })
    Emitter.on(EVENT_TYPE.NOTIFICATIONS, () => {
      closeHelpCenter()
    })
    // eslint-disable-next-line
  }, [])

  const navigateForward = (path: string) => {
    const newLinks = [...pathLinks]
    newLinks.push(path)
    setPathLinks(newLinks)
  }
  const navigateBack = () => {
    const newLinks = [...pathLinks]
    newLinks.pop()
    setPathLinks(newLinks)
  }
  const navigate = (fullPath: string[]) => {
    setPathLinks(fullPath)
  }

  return (
    <HelpCenterContext.Provider value={{
      pathLinks,
      navigateBack,
      navigateForward,
      navigate,
      customProps
    }}
    >
      {children}
    </HelpCenterContext.Provider>
  )
}
