import { useDoQuery } from './useDoQuery'
import { DateType, castToDate } from './classHelpers'

interface FieldMeta {
    value: any
    lastUpdated: DateType
}
const fields = [
    'annual_revenue',
    'city',
    'country',
    'industry',
    'last_funding_amount',
    'last_funding_date',
    'name',
    'number_of_employees',
    'phone',
    'state',
    'technologies',
    'total_funding'
]

export default class CompanyFieldMeta {
    fieldMeta: Record<string, FieldMeta>
    static loadOne (domain: string): { data: CompanyFieldMeta, isLoading: boolean, error?: Error } {
        return useDoQuery({ path: `/companies/${domain}/field_meta`, useChatURL: true, objectClass: CompanyFieldMeta })
    }
    constructor ({ row }: { row?: Record<string, any> }) {
        this.fieldMeta = {}
        for (const field of fields) {
            this.fieldMeta[field] = row?.attributes?.[field]
            if (this.fieldMeta[field]) {
                this.fieldMeta[field].lastUpdated = castToDate(row?.attributes[field].last_updated)
            }
        }
    }
}
