import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Select from '@material-ui/core/Select'
import { SessionContext } from 'session-context'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import { Chip, Switch } from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import FormControl from '@material-ui/core/FormControl'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import {
  patchCredentials,
  getCredentialsStatus,
  updateIntegration,
  INTEGRATIONS,
  getIntegration
} from 'api/integrations'
import Picker from 'cf-components/Pickers'
import GoogleAdsIcon from 'img/google_ads_logo.svg'
import GoogleAdsLogo from 'img/google-ads.png'
import GoogleAdsOAuthBtn from 'img/btn_google_signin_dark_normal_web.png'
import GoogleAdsOAuthPressedBtn from 'img/btn_google_signin_dark_pressed_web.png'
import { useContext, useEffect, useState, useReducer } from 'react'
import { Link } from 'react-router-dom'
import OauthIntegrationPage from '../../OauthIntegration'
import StarIcon from '@material-ui/icons/Star'

const useStyles = makeStyles({
  header: {
    height: 100,
    display: 'flex',
    justifyContent: 'space-between'
  },
  manualToggle: {
    marginRight: 40,
    marginTop: 20
  },
  menuItem: {
    minHeight: 32,
    display: 'flex',
    alignItems: 'center',
    margin: 0,
    padding: 0
  },
  icon: {
    marginTop: 4,
    marginLeft: 4
  },
  dottedLine: {
    borderTop: '2px dotted #975ce6',
    height: 10,
    marginTop: 20,
    width: '100%'
  },
  wrapIcon: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 15
  },
  textField: {
    cursor: 'text',
    width: '100%',
    marginBottom: 25
  },
  container: {
    margin: 65
  },
  groupContainer: {
    backgroundColor: 'rgba(193, 166, 237, 0.1)',
    padding: 15,
    borderRadius: 10,
    marginBottom: 20
  },
  bodyText: {
    marginBottom: 25,
    fontSize: 16
  },
  radioButton: {
    fontWeight: 600
  },
  checkboxGroup: {
    marginLeft: 50,
    marginBottom: 25
  },
  checkbox: {
    display: 'block',
    marginBottom: -10
  },
  actionButton: {
    marginTop: 10,
    paddingLeft: 50,
    paddingRight: 50
  },
  crumb: {
    fontSize: '.8em'
  },
  typography: {
    fontSize: '1.05em'
  },
  breadcrumbs: {
    position: 'absolute',
    top: 5
  },
  crumbWrapper: {
    padding: 10
  },
  list1: {
    marginBottom: 15
  },
  list2: {
    marginTop: 10
  },
  shiftRight: {
    marginLeft: 25
  },
  groupTitle: {
    color: '#7b797c',
    marginBottom: 10
  },
  actionPicker: {
    width: 300,
    margin: 10
  },
  groupDescription: {
    margin: 10,
    color: '#7b797c'
  },
  cfAction: {
    textAlign: 'right'
  },
  mappingGrid: {
    display: 'grid',
    gridTemplateColumns: '180px 250px 300px',
    gridGap: 10,
    gridTemplateAreas: '"cf_action dottedline ads_action"'
  },
  star: {
    fill: 'gold'
  }
})

const NStarIcon = (props: { stars: number }) => {
  const classes = useStyles()
  const stars = []
  const n = props.stars
  for (let i = 0; i < n; i++) {
    stars.push(<StarIcon key={i} className={classes.star} />)
  }
  return <>{stars}</>
}

const scores = [
  { key: 0, name: 'All (Default)', icon: <></> },
  { key: 1, name: 'One Star', icon: <NStarIcon stars={1} /> },
  { key: 2, name: 'Two Stars', icon: <NStarIcon stars={2} /> },
  { key: 3, name: 'Three Stars', icon: <NStarIcon stars={3} /> }
]

type State = {
  hasConnection: boolean
  viewingCustomerID?: string
  loginCustomerID?: string
  minScore: number
  conversionMapping: {
    conversation: any
    emailCaptured: any
    meetingBooked: any
    phoneCaptured: any
  }
  savedCredentials: boolean
  dirtyCredentials: boolean
  dirtySettings: boolean
  enabled: boolean
}

const initialState: State = {
  enabled: false,
  hasConnection: false,
  savedCredentials: false,
  dirtyCredentials: false,
  dirtySettings: false,
  minScore: 0,
  conversionMapping: {
    conversation: null,
    emailCaptured: null,
    meetingBooked: null,
    phoneCaptured: null
  }
}
type Action = {
  data?: any,
  type: string
}
const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'init': {
      const minScore = action.data?.config?.minScore || 0
      const enabled = action.data?.enabled || false
      const conversionMapping = action.data?.config?.conversionMapping || initialState.conversionMapping
      return {
        ...state,
        dirtySettings: false,
        dirtyCredentials: false,
        enabled,
        minScore,
        conversionMapping
      }
    }
    case 'mapConversion': {
      const conversionMapping = { ...state.conversionMapping, [action.data.prop]: action.data.value }
      return { ...state, conversionMapping, dirtySettings: true }
    }
    case 'connected':
      return { ...state, hasConnection: true, loginCustomerID: action.data.loginCustomerID, viewingCustomerID: action.data.viewingCustomerID }
    case 'set': {
      let dirtyCredentials = state.dirtyCredentials
      let dirtySettings = state.dirtySettings
      if (action.data.prop === 'viewingCustomerID' || action.data.prop === 'loginCustomerID') {
        dirtyCredentials = true
      } else {
        dirtySettings = true
      }
      return { ...state, [action.data.prop]: action.data.value, dirtyCredentials, dirtySettings }
    }
  }
  return { ...state }
}
const defaultCredSettings = {
  viewingCustomerID: null,
  loginCustomerID: null
}
const GoogleAdsIntegrationPage = () => {
  const classes = useStyles()
  const { saveFailed, saveSuccessful } = useContext(SessionContext)
  const [state, dispatch] = useReducer(reducer, initialState)
  const [loading, setLoading] = useState(true)
  const [saving, setSaving] = useState(false)
  const [credSettings, setCredSettings] = useState(defaultCredSettings)
  const toggleIntegration = (checked: boolean) => {
    setSaving(true)
    updateIntegration({
      integration: {
        attributes: {
          /* eslint-disable */
          integration_name: INTEGRATIONS.GoogleAds,
          enabled: checked,
          config: { delay_seconds: 0 }
          /* eslint-enable */
        }
      }
    }).then(() => {
      setSaving(false)
    })
  }

  const handleSubmit = (event: any) => {
    event.preventDefault()
    const promises = []
    if (state.dirtyCredentials) {
      promises.push(
        patchCredentials('google-ads', [
          {
            attributes: {
              name: 'loginCustomerID',
              value: state.loginCustomerID,
              secret: false
            }
          },
          {
            attributes: {
              name: 'viewingCustomerID',
              value: state.viewingCustomerID,
              secret: false
            }
          }
        ]).then(() => {
          dispatch({ type: 'set', data: { prop: 'savedCredentials', value: true } })
        })
      )
    }
    if (state.dirtySettings) {
      promises.push(
        updateIntegration({
          integration: {
            attributes: {
              /* eslint-disable */
              integration_name: INTEGRATIONS.GoogleAds,
              enabled: state.enabled,
              config: {
                delay_seconds: 0,
                minScore: state.minScore,
                conversionMapping: state.conversionMapping
              }
              /* eslint-enable */
            }
          }
        })
      )
    }
    setSaving(true)
    Promise.all(promises).then(() => {
      saveSuccessful()
    }).catch(() => {
      saveFailed()
    }).finally(() => {
      setSaving(false)
    })
  }

  useEffect(() => {
    getCredentialsStatus('google-ads')
      .then((response) => {
        setLoading(false)
        if (response.data.attributes.has_credentials) {
          setCredSettings(response.data.attributes.settings)
          dispatch({ type: 'connected', data: response.data.attributes.settings })
        }
      })
  }, [state.savedCredentials])
  useEffect(() => {
    if (!saving) {
      getIntegration(INTEGRATIONS.GoogleAds).then((r: any) => {
        if (r.ok) {
          dispatch({
            type: 'init',
            data: r.data.attributes
          })
        }
      })
    }
  }, [saving])

  if (loading) {
    return <></>
  }

  if (!state.hasConnection) {
    return (
      <OauthIntegrationPage
        name='Google Ads Integration OAuth'
        integration='google-ads'
        icon={GoogleAdsIcon}
        oauthBtn={GoogleAdsOAuthBtn}
        oauthPressedBtn={GoogleAdsOAuthPressedBtn}
        description='You are one step away from linking your Signals account with Google Ads! Enabling this integration will allow you to tie Signals events to Google Ads conversions for visitors you get through Google Ads.'
      />
    )
  }

  return (
    <>
      <Breadcrumbs aria-label='breadcrumb' classes={{ li: classes.crumb, ol: classes.crumbWrapper }}>
        <Link style={{ color: '#7A7A7A' }} to='/settings/integrations'>
          Integrations Page
        </Link>
        <Typography color='textPrimary' classes={{ root: classes.typography }}>Google Ads Integration Page</Typography>
      </Breadcrumbs>
      <div className={classes.container}>
        <div className={classes.header}>
          <img src={GoogleAdsLogo} style={{ height: 300, position: 'relative', top: -92 }} alt='Google Ads Logo' />
          {state ? (
            <div className={classes.manualToggle}>
              <Chip
                label={
                  state.enabled
                    ? 'Integration: On'
                    : 'Integration: Off'
                }
                color={
                  state.enabled ? 'primary' : 'default'
                }
              />
              <Switch
                checked={state.enabled}
                onChange={(e) => toggleIntegration(e.target.checked)}
                name='master_toggle'
              />
            </div>
          ) : (
            <></>
          )}
        </div>
        <Typography variant='body2' className={classes.bodyText}>
          Integrate your Signals account with Google Ads to track conversions in your chatbot conversations
        </Typography>
        <Divider style={{ margin: 20 }} variant='middle' />
        <div className={classes.groupContainer}>
          <Typography variant='h1' className={classes.groupTitle}>
            Setup
          </Typography>
          <Typography variant='subtitle2'>
            Manager Account Customer ID
          </Typography>
          <TextField
            required
            variant='outlined'
            className={classes.textField}
            value={state.loginCustomerID}
            onChange={(e) => {
              dispatch({ type: 'set', data: { prop: 'loginCustomerID', value: e.target.value } })
            }}
          />
          <Typography variant='subtitle2'>
            Viewing Customer ID
          </Typography>
          <TextField
            required
            variant='outlined'
            className={classes.textField}
            value={state.viewingCustomerID}
            onChange={(e) => {
              dispatch({ type: 'set', data: { prop: 'viewingCustomerID', value: e.target.value } })
            }}
          />
        </div>
        {credSettings.viewingCustomerID && credSettings.loginCustomerID && (
          <>
            <div className={classes.groupContainer}>
              <Typography variant='subtitle2'>
                Minimum Criteria
              </Typography>
              <div>
                <Select
                  name='min_lead_score'
                  variant='outlined'
                  margin='dense'
                  defaultValue='preview'
                  value={state.minScore}
                  onChange={(event: any) => {
                    dispatch({ type: 'set', data: { prop: 'minScore', value: event.target.value } })
                  }}
                >
                  <MenuItem value='preview' disabled className={classes.menuItem}>
                    -- Min lead score --
                  </MenuItem>
                  {scores.map((score, index) => (
                    <MenuItem key={index} value={score.key}>
                      <div className={classes.menuItem}>
                        <div>{score.name}</div>
                        <div className={classes.icon}>{score.icon}</div>
                      </div>
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>
            <div className={classes.groupContainer}>
              <Typography variant='h1' className={classes.groupTitle}>
                Conversion Action Mapping
              </Typography>
              <Typography className={classes.groupDescription}>
                Map Signals actions to the corresponding conversion actions. Conversions will only be counted for visitors coming from ads.
              </Typography>
              <Typography variant='subtitle2'>
                Conversation
              </Typography>
              <div className={classes.actionPicker}>
                <Picker
                  objectType='conversionActions'
                  selection={state.conversionMapping.conversation}
                  loadAllProps={{ searchParams: { extraHeaders: { 'x-integration': 'google-ads' } } }}
                  setSelection={(value: any) => {
                    dispatch({ type: 'mapConversion', data: { prop: 'conversation', value } })
                  }}
                />
              </div>
              <Typography variant='subtitle2'>
                Email Captured
              </Typography>
              <div className={classes.actionPicker}>
                <Picker
                  objectType='conversionActions'
                  selection={state.conversionMapping.emailCaptured}
                  loadAllProps={{ searchParams: { extraHeaders: { 'x-integration': 'google-ads' } } }}
                  setSelection={(value: any) => {
                    dispatch({ type: 'mapConversion', data: { prop: 'emailCaptured', value } })
                  }}
                />
              </div>
              {/*
              <Typography variant='subtitle2'>
                Phone Captured
              </Typography>
              <div className={classes.actionPicker}>
                <Picker
                  objectType='conversionActions'
                  selection={state.conversionMapping.phoneCaptured}
                  loadAllProps={{ searchParams: { extraHeaders: { 'x-integration': 'google-ads' } } }}
                  setSelection={(value: any) => {
                    dispatch({ type: 'mapConversion', data: { prop: 'phoneCaptured', value } })
                  }}
                />
              </div>
              */}
              <Typography variant='subtitle2'>
                Meeting Booked
              </Typography>
              <div className={classes.actionPicker}>
                <Picker
                  objectType='conversionActions'
                  selection={state.conversionMapping.meetingBooked}
                  loadAllProps={{ searchParams: { extraHeaders: { 'x-integration': 'google-ads' } } }}
                  setSelection={(value: any) => {
                    dispatch({ type: 'mapConversion', data: { prop: 'meetingBooked', value } })
                  }}
                />
              </div>
            </div>
          </>
        )}

        <form onSubmit={handleSubmit}>
          <FormControl component='fieldset'>
            <Button
              className={classes.actionButton}
              type='submit'
              color='primary'
              variant='contained'
              disabled={(!state.dirtyCredentials && !state.dirtySettings) || saving}
            >
              Save
            </Button>
          </FormControl>
        </form>
      </div>
    </>
  )
}

export default GoogleAdsIntegrationPage
