import { useState, createContext, useContext } from 'react'
import { getAllTags } from 'api/tags'
import { getAgents } from 'api/participants'
import { useQuery } from 'react-query'
import { getSettings } from 'api/settings'
import { getBots } from 'api/bots'
import { getReplies } from 'api/saved_replies'
import { User } from 'classes/classes'
import { SessionContext } from 'session-context'

/**
 * @typedef {Object} Tag
 * @property {number} id
 * @property {{ [k : string]: any }} attributes
 */
/**
 * @typedef {Object} TenantDataContextData
 * @property {any[]} users
 * @property {Tag[]} tags
 * @property {any[]} bots
 * @property {{[k: string]: any}} agentDict
 * @property {{[k: string]: Tag}} tagDict
 * @property {boolean} loadingTags
 * @property {{[k in 'primaryColor'|'buttonColor'|'textColor']: string}} settings
 * @property {any} get
 * @property {{[k: string]: any}[]} replies
 * @property {{[k: string]: any}[]} replyCategories
 * @property {() => Promise<{ replies: TenantDataContextData['replies'], replyCategories: TenantDataContextData['replyCategories'] }>} refetchReplies
 */

/** @type {React.Context<TenantDataContextData>} */
const TenantDataContext = createContext({})

function TenantDataProvider (props) {
  const chatServiceUrl = window.chatServiceUrl
  const [tagDict, setTagDict] = useState({})

  const { user } = useContext(SessionContext)

  const { data: agentDict } = useQuery('agents', () => getAgents({ chatServiceUrl })
    .then(response => {
      const agentList = response.data
      const users = {}
      const participants = {}
      for (const a of agentList) {
        users[a.relationships.is_user.data.id] = a.id
        participants[a.id] = a.attributes
      }
      return { users, participants }
    }), { staleTime: 120000 })

  const { data: tags, isLoading } = useQuery('tags', () => getAllTags({ chatServiceUrl })
    .then(response => {
      const tagList = response.data.sort((a, b) => a.attributes.tag_name > b.attributes.tag_name)
      const dict = {}
      for (const t of tagList) {
        dict[t.id] = t.attributes
      }
      setTagDict(dict)
      return tagList
    }), { staleTime: 30000 }
  )
  const loadingTags = isLoading

  const { data: users } = User.loadAll()

  const { data: bots } = useQuery('bots-list', () => getBots()
    .then(response => response.data), { staleTime: 120000 }
  )

  const { data: settings } = useQuery('settings', () => getSettings()
    .then(response => {
      const primaryColor = response.data.attributes.primary_color
      const buttonColor = (response.data.attributes.button_color || response.data.attributes.primary_color)
      const textColor = response.data.attributes.contrast_color
      return {
        primaryColor, buttonColor, textColor
      }
    }), { staleTime: 120000 })

  const {
    data: { replies, replyCategories } = { replies: [], replyCategories: [] },
    refetch: refetchReplies
  } = useQuery('replies',
    () => getReplies().then(response => {
      const replies = response.data
      let replyCategories = [].concat.apply([], replies.filter(r =>
        r.attributes.categories != null).map(r => r.attributes.categories))

      replyCategories = Array.from(new Set(replyCategories))
      return { replies, replyCategories }
    }),
    { staleTime: 120000 }
  )

  const getBotList = () => {
    return bots
  }

  const getUserList = () => {
    return users
  }

  const get = {
    bots: getBotList,
    users: getUserList
  }

  const activeParticipantID = agentDict?.users[user.id]

  return (
    <TenantDataContext.Provider value={{ users, tags, bots, agentDict, loadingTags, tagDict, settings, get, replies, replyCategories, refetchReplies, activeParticipantID }}>
      {props.children}
    </TenantDataContext.Provider>
  )
}

export { TenantDataContext, TenantDataProvider }
