import React from 'react'
import { Checkbox, ListItemText, Radio } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import Chip from '@material-ui/core/Chip'
import MenuItem from '@material-ui/core/MenuItem'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import TextField from '@material-ui/core/TextField'

const useStyles = makeStyles(theme => ({
  componentRoot: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  formControl: {
    marginBottom: 12,
    marginTop: 12,
    display: 'flex'
  },
  questionHeader: {
    marginBottom: 5
  },
  grayedOut: {
    color: 'gray'
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  leftRadioLabel: {
    width: 80,
    paddingTop: 35,
    textAlign: 'right',
    paddingRight: 15
  },
  radioLabel: {
    paddingTop: 35,
    paddingLeft: 15
  },
  invalid: {
    color: 'red'
  },
  textFieldRoot: {
    display: 'flex'
  },
  numericText: {
    width: 180,
    alignSelf: 'flex-end',
    marginLeft: 5
  },
  textQuestion: {
    flexGrow: 2
  },
  radioButtons: {
    margin: 0
  }
}))

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 5.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
}

function SurveyNumericTextField ({
  field,
  form: { touched, errors, setFieldValue },
  ...props
}) {
  const classes = useStyles()
  return (
    <div className={classes.textFieldRoot}>
      <h3 className={classes.textQuestion}>{props.question}</h3>
      <div>
        <TextField
          type='text'
          {...field}
          id={props.id}
          name={props.name}
          error={errors[props.id] && touched[props.id]}
          variant='outlined'
          margin='dense'
          className={classes.numericText}
          InputProps={{
            startAdornment:
              props.startAdornment != null ? (
                <InputAdornment position='start'>
                  {props.startAdornment}
                </InputAdornment>
              ) : (
                ''
              ),
            endAdornment:
              props.endAdornment != null ? (
                <InputAdornment position='start'>
                  {props.endAdornment}
                </InputAdornment>
              ) : (
                ''
              )
          }}
        />
      </div>
    </div>
  )
}

function SurveyDropdown ({
  field,
  form: { touched, errors, setFieldValue, setFieldTouched },
  ...props
}) {
  const classes = useStyles()

  const inputLabel = React.useRef(null)
  const [labelWidth, setLabelWidth] = React.useState(0)
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth)
  }, [])

  return (
    <div>
      <h3 className={classes.questionHeader}>{props.question}</h3>
      <FormControl
        variant='outlined'
        className={classes.formControl}
        margin='dense'
      >
        <InputLabel ref={inputLabel} htmlFor={props.name}>
          {props.label}
        </InputLabel>
        <Select
          {...field}
          labelWidth={labelWidth}
          inputProps={{
            name: props.name,
            id: props.id
          }}
          MenuProps={MenuProps}
          onChange={e => setFieldValue(field.name, e.target.value)}
          onBlur={() => setFieldTouched(field.name)}
          error={errors[props.id] && touched[props.id]}
        >
          <MenuItem disabled value=''>
            {props.label}
          </MenuItem>
          {props.values.map(name => (
            <MenuItem key={name} value={name}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}

function SurveyMultipleDropdown ({
  field,
  form: { touched, errors, setFieldValue, setFieldTouched },
  ...props
}) {
  const classes = useStyles()

  return (
    <div>
      <h3 className={classes.questionHeader}>{props.question}</h3>
      <FormControl className={classes.formControl}>
        <Select
          {...field}
          placeholder={props.label}
          displayEmpty
          multiple
          inputProps={{
            name: props.name,
            id: props.id
          }}
          MenuProps={MenuProps}
          onChange={e => setFieldValue(field.name, e.target.value)}
          onBlur={() => setFieldTouched(field.name)}
          error={errors[props.id] && touched[props.id]}
          input={<Input id={props.id} name={props.id} />}
          renderValue={selected => {
            if (selected.length === 0) {
              return <div className={classes.grayedOut}>{props.label}</div>
            }
            return (
              <div className={classes.chips}>
                {selected.map(value => (
                  <Chip key={value} label={value} className={classes.chip} />
                ))}
              </div>
            )
          }}
        >
          <MenuItem disabled value=''>
            {props.label + ' (Select all that apply)'}
          </MenuItem>
          {props.values.map(name => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={field.value.indexOf(name) > -1} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}

function SurveyHorizontalRadio ({ field, form, ...props }) {
  const classes = useStyles()

  return (
    <div>
      <h3 className={classes.questionHeader}>{props.question}</h3>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div className={classes.formControl}>
          <div className={classes.leftRadioLabel}>{props.leftLabel}</div>
          {props.values.map(val => (
            <FormControlLabel
              key={val}
              label={val}
              labelPlacement='top'
              className={classes.radioButtons}
              control={
                <Radio
                  {...field}
                  checked={field.value === val}
                  name={props.id}
                  value={val}
                />
              }
            />
          ))}
          <div className={classes.radioLabel}>{props.rightLabel}</div>
        </div>
      </div>
    </div>
  )
}

function isNumber (evt) {
  evt = (evt) || window.event
  const charCode = (evt.which) ? evt.which : evt.keyCode
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    evt.preventDefault()
    return false
  }
  return true
}

function SurveyIntegerInput ({
  field,
  form: { touched, errors, setFieldValue },
  ...props
}) {
  const classes = useStyles()

  return (
    <div className={classes.textFieldRoot}>
      <h3 className={classes.textQuestion}>{props.question}</h3>
      <div>
        <TextField
          type='number'
          {...field}
          id={props.id}
          error={errors[props.id] && touched[props.id]}
          name={props.name}
          variant='outlined'
          margin='dense'
          onKeyPress={e => isNumber(e)}
          className={classes.field}
          InputProps={{
            startAdornment:
          props.startAdornment != null ? (
            <InputAdornment position='start'>
              {props.startAdornment}
            </InputAdornment>
          ) : (
            ''
          ),
            endAdornment:
          props.endAdornment != null ? (
            <InputAdornment position='start'>
              {props.endAdornment}
            </InputAdornment>
          ) : (
            ''
          )
          }}
        />
      </div>
    </div>
  )
}

export {
  SurveyDropdown,
  SurveyMultipleDropdown,
  SurveyNumericTextField,
  SurveyHorizontalRadio,
  SurveyIntegerInput
}
