import { useReducer, useState } from 'react'
import { Modal } from 'library/Modal'
import BetterBaseNode from './baseNode/BaseNode'
import { jsPlumbToolkit, Node } from 'jsplumbtoolkit'
import { nodeMappingData, getOrderedPorts } from '../FlowBuilder'
import { FlowEditorModal } from './FlowEditorModal'
import { Emitter, EVENT_TYPE } from 'emitter'
import { Emitter as EventEmitter, EVENT_TYPE as EVENT_EMITTER_TYPE } from '../canvas/helpers/EventEmitter'
import UnsavedChangesModal from 'cf-components/UnsavedChangesModal'

export interface ModalProps {
  open: boolean
  onHide: () => void
  node: BetterBaseNode
  cancel: () => void
  helpCenterLink?: string
}

export interface ModalContentsProps<SettingsType = Record<string, any>> {
  settings: SettingsType
  ports: { id: string, label: string, type: string }[]
  updateSettings: (changes: Partial<SettingsType>) => void
  updatePorts: (ports: any) => void
  state: any
  dispatch: any
  saveChanges: () => void
  cancel: () => void
}

const reducer = (state: any, changes: any): any => {
  return { ...state, ...changes }
}

export function NodeModal (props: ModalProps): JSX.Element {
  if (!props.open) {
    return <></>
  }

  Emitter.on(EVENT_TYPE.BROWSER_BACK, props.onHide)

  return (
    <OpenModal
      {...props}
    />
  )
}

function handlePorts ({ node, toolkit, state }: { node: Node, toolkit: jsPlumbToolkit, state: any }): void {
  const currentPorts = node.getPorts()
  const portIDs = currentPorts.map((p: any) => p.id)
  const newPortIDs = state.ports.map((p: any) => p.id)
  for (const portID of portIDs) {
    if (!newPortIDs.includes(portID)) {
      toolkit.removePort(node.id, portID)
      if (currentPorts.map(p => p.id).includes(portID)) {
        toolkit.removePort(node.id, portID)
      }
    }
  }
  for (const port of state.ports) {
    if (!portIDs.includes(port.id)) {
      toolkit.addNewPort(node.id, 'default', port)
    } else {
      const currentPortObject = currentPorts.filter(p => p.id === port.id)[0]
      const data = { ...port }
      toolkit.updatePort(currentPortObject, data)
    }
  }
}

export function OpenModal (props: ModalProps): JSX.Element {
  const node = props.node.getNode()
  const toolkit = props.node.toolkit
  const nodeMapping = window.flowBuilder.nodeMapping
  const kind = node.data.kind
  const [stateChanged, setStateChanged] = useState(false)
  const [unsavedChangesModalOpen, setUnsavedChangesModalOpen] = useState(false)
  const stateReducer: (state: any, changes: any) => any = (state: any, changes: any) => {
    setStateChanged(true)
    if (nodeMapping[kind].modalReducer) {
      return nodeMapping[kind].modalReducer(state, changes)
    }
    return reducer(state, changes)
  }
  const [state, dispatch] = useReducer(stateReducer, node.data)
  const data: nodeMappingData = nodeMapping[kind]
  const Contents = data.modal
  const saveDisabled = data.schema && !data.schema.isValidSync(state)

  const updateSettings = (changes: any): void => {
    dispatch({ settings: { ...state.settings, ...changes } })
  }

  const updatePorts = (ports: any): void => {
    const portOrder = ports.map((p: any) => p.id)
    dispatch({ ports, portOrder })
  }

  const saveChanges = (): void => {
    if (toolkit) {
      setTimeout(() => {
        const newData = {
          ...state
        }
        toolkit.updateNode(node, newData)
        handlePorts({ node, toolkit, state })
        if (kind !== 'Sequence') {
          EventEmitter.emit(EVENT_EMITTER_TYPE.NODE_DATA_UPDATED, { node: node, toolkitRefID: toolkit.id })
          props.onHide()
        }
      })
    }
  }

  const cancelFn = (): void => {
    if (stateChanged) {
      setUnsavedChangesModalOpen(true)
    } else {
      props.cancel()
    }
  }

  const sortedPorts = getOrderedPorts(state.ports, state.portOrder)

  const ModalComponent = props.node.state.type === 'sequence' ? FlowEditorModal : Modal

  if (Contents) {
    return (
      <>
        <ModalComponent
          open={props.open}
          onHide={cancelFn}
          title={data.title}
          handleSave={saveChanges}
          saveDisabled={saveDisabled}
          size={data.modalSize}
          helplink={props.helpCenterLink}
          helplinkLabel='Learn more about this skill'
          saveIcon='save'
        >
          <Contents
            state={state}
            dispatch={dispatch}
            settings={state.settings}
            ports={sortedPorts}
            updatePorts={updatePorts}
            updateSettings={updateSettings}
            saveChanges={saveChanges}
            cancel={props.onHide}
          />
        </ModalComponent>
        <UnsavedChangesModal
          modalOpen={unsavedChangesModalOpen}
          setModalOpen={setUnsavedChangesModalOpen}
          save={saveChanges}
          cancelAction={props.cancel}
          saveDisabledState={saveDisabled}
          itemName='skill'
        />
      </>
    )
  }

  return <></>
}
