import Card from 'library/materialUI/Card'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from 'library/materialUI'
import DelayDropDown from '../DelayDropDown'
import { useContext, useEffect } from 'react'
import { TabContext } from '../IntegrationPage/TabProvider'
import { IntegrationPageContext } from '../IntegrationPage/IntegrationPageProvider'

const useStyles = makeStyles((theme) => ({
  subtitle1: {
    fontSize: 18,
    fontWeight: 600,
    marginBottom: 15
  },
  subtitle2: {
    fontSize: 15,
    fontWeight: 600,
    marginBottom: 10
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  bodyText: {
    marginBottom: 30,
    fontSize: 16
  }
}))

interface SyncDelayCardProps {
  save: any
  handleEdit: any
  integrationSettings: any
}

export default function SyncDelayCard ({ save, handleEdit, integrationSettings }: SyncDelayCardProps) {
  const classes = useStyles()
  const { setCanSave, shouldSave } = useContext(TabContext)
  const { handleSaved } = useContext(IntegrationPageContext)

  useEffect(() => {
    if (shouldSave) {
      save()
      handleSaved()
    }
  })

  return (
    <Card
      content={
        <>
          <div className={classes.header}>
            <Typography className={classes.subtitle1}>
              Sync Delay
            </Typography>
          </div>
          <Typography variant='body2' className={classes.bodyText}>
            Select the amount of time you want to delay after the conversation closes before synchronizing the data. Set a delay if you’re syncing to multiple integrations to avoid duplicates.
          </Typography>
          <Typography variant='subtitle2' className={classes.subtitle2}>
            Delay time:
          </Typography>
          <DelayDropDown
            handleEdit={(e: any) => { handleEdit({ type: 'delay_seconds', data: e.target.value }); setCanSave(true) }}
            value={integrationSettings.attributes.config.delay_seconds || 0}
          />
        </>
      }
    />
  )
}
