import { useCallback, useEffect, useState } from 'react'
import AppPage from 'cf-components/AppPage'
import HighIntentCategorySection from './HighIntentCategorySection'
import { makeStyles } from '@material-ui/core/styles'
import { HighIntentCategoryModal } from './HighIntentCategoryModal'
import { addCategory, deleteCategory, updateCategory, getCategories } from 'api/high-intent'

export function HighIntent () {
    const [addModalOpen, setAddModalOpen] = useState(false)
    const [categoryTypes, setCategoryTypes] = useState<any[]>([])

    const loadCategories = useCallback(() => {
      getCategories()
        .then(response => {
          setCategoryTypes(response.data)
        })
    }, [])

    function removeCategory (id: number) {
      return deleteCategory(id)
        .then(() => {
          setCategoryTypes(categoryTypes.filter(value => value.attributes.id !== id))
          handleClose()
        })
    }

    function newCategory (pageType: string) {
      return addCategory(pageType)
        .then(response => {
          setCategoryTypes(categoryTypes.concat(response.data))
          handleClose()
        })
    }

    function changeCategory (id: number, pageType: string) {
      return updateCategory(id, pageType)
        .then(response => response)
    }

    const useStyles = makeStyles(theme => ({
      categoriesHolder: {
          overflow: 'visible',
          marginTop: 30,
          marginLeft: 30,
          marginRight: 30,
          backgroundColor: '#F8F8F8',
          boxShadow: 'none'
        },
        setupTitle: {
          marginBottom: 24,
          fontSize: 20,
          fontWeight: 'bold',
          fontFamily: 'Poppins'
        },
        setUpDescription: {
          fontSize: 16,
          fontFamily: 'Poppins'
        }
      }))
    const classes = useStyles();

    const handleClose = () => {
      setAddModalOpen(false)
    }

    useEffect(() => {
      if (!addModalOpen) {
        loadCategories()
      }
    }, [loadCategories, addModalOpen])

    return (
      <AppPage
        title='High-Intent Pages'
        actionText='Add High-Intent Page Category'
        action={() => setAddModalOpen(true)}
      >
        <HighIntentCategoryModal
          open={addModalOpen}
          onHide={handleClose}
          onSubmit={newCategory}
        />
        <div className={classes.categoriesHolder}>
          <div className={classes.setupTitle}>
            High-Intent Page Setup
          </div>
          <div className={classes.setUpDescription}>
            Specify which pages on your site are considered high-intent for easier targeting with alerts and plays
          </div>
          <div>
            {categoryTypes ? categoryTypes.map(type => {
              return (
                <HighIntentCategorySection
                  categoryID={type.attributes.id}
                  key={type.attributes.id}
                  pageType={type.attributes.name}
                  deleteCategory={removeCategory}
                  changeCategory={changeCategory}
                />
              )
            }) : null}
          </div>
        </div>
      </AppPage>
    )
}
