import { useState, useContext, useReducer, useEffect } from 'react'
import MainPage from './MainPage'
import ConversationView from './conversation-view/ConversationView'
import { ConversationWindowContext } from '../agent-chat/conversation-window-context'
import { SessionContext } from 'session-context'
import { getConversationEvents } from 'api/conversations'
import { usePostageSocket } from 'components/PostageSocket'
import { useHistory } from 'react-router-dom'
// import ActionsView from './actions-view/ActionsView'
// import { useDebounce } from 'use-debounce'

const reducer = (state, action) => {
  switch (action.type) {
    case 'refreshEvents':
      return {
        ...state,
        events: action.events,
        participants: action.participants,
        typing: action.typing
      }
    case 'appendEvent':
      return {
        ...state,
        events: [...state.events, action.value]
      }
    case 'appendTyping':
      return {
        ...state,
        typing: [...state.typing, {
            participant_id: action.value.relationships.from_participant.data.id,
            created_timestamp: action.value.attributes.created_timestamp,
            participant_name: action.value.attributes.participant_name,
            participant_picture: action.value.relationships.from_participant.data.avatar_url
        }]
      }
    case 'removeTyping':
      return {
        ...state,
        typing: state.typing.filter(event => event.participant_id !== action.value.relationships.from_participant.data.id)
      }
    default:
      break
  }
}

const refreshConversationEvents = ({ dispatch, conversationID }) => {
  return getConversationEvents({ conversationID })
    .then(response => {
      const participantDict = {}
      for (const p of response.included) {
        participantDict[p.id] = { ...p.attributes, id: p.id }
      }
      dispatch({
        type: 'refreshEvents',
        events: response.data,
        participants: participantDict,
        typing: response.meta.typing
      })
    })
}

const initialState = {
  events: [],
  participants: [],
  typing: []
}

function SelectedConversation (props) {
  const [state, dispatch] = useReducer(reducer, initialState)
  const conversationID = props?.conversation?.id

  useEffect(() => {
    if (conversationID) {
      refreshConversationEvents({ dispatch, conversationID })
    }
  }, [conversationID])

  usePostageSocket((msg) => {
    if (msg.type === 'conversation_events') {
      if (msg.relationships.conversation.data.id === conversationID) {
        const pid = msg.relationships.from_participant.data.id
        if (state.participants[pid]) {
          dispatch({ type: 'appendEvent', value: msg })
          if (msg.attributes.kind === 'halt' || msg.attributes.kind === 'chat') {
            dispatch({ type: 'removeTyping', value: msg })
          } else if (msg.attributes.kind === 'type') {
            dispatch({ type: 'appendTyping', value: msg })
          }
        } else {
          refreshConversationEvents({ dispatch, conversationID })
        }
      }
    }
  })

  return (
    <ConversationView
      user={props.user}
      participant={props.participant}
      goBack={props.goBack}
      conversation={props.conversation}
      state={state}
    />
  )
}

function MobileAgentChat (props) {
  const history = useHistory()
  const [search, setSearch] = useState('')
  // const [debouncedSearch] = useDebounce(search, 500)

  const { state, selectConversation } = useContext(ConversationWindowContext)
  const { user, participant } = useContext(SessionContext)

  const chooseConversation = (id) => {
    selectConversation(id)
  }

  useEffect(() => {
    if (state.activeConversationID && state.conversationList) {
      chooseConversation(state.activeConversationID)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.activeConversationID, state.conversationList])

  let activeConversation
  if (state.conversationList && state.activeConversationID) {
    activeConversation = state.conversationList.filter(c => c.id === state.activeConversationID)[0]
  }

  if (activeConversation) {
    return (
      <SelectedConversation
        user={user}
        participant={participant}
        conversation={activeConversation}
        goBack={() => {
          history.push('/chat/')
        }}
      />
    )
  }

  return (
    <MainPage
      activeConversationId={state.activeConversationID}
      chooseConversation={chooseConversation}
      conversationList={state.conversationList}
      search={search}
      setSearch={setSearch}
      readReceipts={state.readReceipts}
      morePages={false}
      user={user}
    />
  )
}

export default MobileAgentChat
