import { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { doPost, doGet, doPatch } from 'api/api'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import InfoIcon from '@material-ui/icons/Info'
import GoogleAnalyticsImage from '../../../img/google_analytics.png'
import { addIntegration, INTEGRATIONS } from '../../../api/integrations'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import { Link } from 'react-router-dom'
import { Tooltip } from '@material-ui/core'
import ga_menu from '../../../img/integrations/GA_menu_options.jpg'

const useStyles = makeStyles({
  headerImage: {
    height: 50,
    marginBottom: 50
  },
  wrapIcon: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 15
  },
  textField: {
    cursor: 'text',
    width: '100%',
    marginBottom: 25
  },
  container: {
    margin: 65
  },
  bodyText: {
    marginBottom: 25,
    fontSize: 16
  },
  subtitle1: {
    fontSize: 18,
    fontWeight: 600,
    marginTop: 25,
    marginBottom: 15
  },
  subtitle2: {
    fontSize: 15,
    fontWeight: 600,
    marginBottom: 10
  },
  subtitle3: {
    fontSize: 17,
    fontWeight: 600
  },
  radioButton: {
    fontWeight: 600
  },
  checkboxGroup: {
    marginLeft: 50,
    marginBottom: 25
  },
  checkbox: {
    display: 'block',
    marginBottom: -10
  },
  actionButton: {
    marginTop: 10,
    paddingLeft: 50,
    paddingRight: 50
  },
  crumb: {
    fontSize: '.8em'
  },
  typography: {
    fontSize: '1.05em'
  },
  breadcrumbs: {
    position: 'absolute',
    top: 5
  },
  crumbWrapper: {
    padding: 10
  },
  list1: {
    marginBottom: 15
  },
  list2: {
    marginTop: 10
  },
  shiftRight: {
    marginLeft: 25
  },
  infoIcon: {
    width: 15,
    height: 15,
    color: '#A9A9A9',
    marginLeft: 5,
    verticalAlign: 'middle'
  },
  howToImage: {
    width: 225,
    marginBottom: 25,
    marginLeft: 50
  }
})

const GoogleAnalyticsIntegrationPage = (props) => {
  const classes = useStyles()

  const [state, setState] = useState({
    button_clicked: false,
    chat_started: false,
    email_captured: false,
    first_interaction: false,
    meeting_booked: false,
    message_received: false,
    message_sent: false,
    phone_number_captured: false,
    playbook_clicked: false,
    playbook_dismissed: false,
    playbook_fired: false
  })
  const [trackingID, setTrackingID] = useState()
  const [measurementID, setMeasurementID] = useState()
  const [registered, setRegistered] = useState(null)
  const [trackingIDError, setTrackingIDError] = useState(false)
  const [measurementIDError, setMeasurementIDError] = useState(false)

  useEffect(() => {
    doGet({
      useChatURL: true,
      path: '/integrations/google_analytics'
    }).then((response) => {
      const atts = response.data.attributes
      if (!atts.default) {
        setRegistered(true)
        setState(atts.events)
      }
      setTrackingID(atts.google_analytics_tracking_id || '')
      setMeasurementID(atts.google_analytics_measurement_id || '')
    })
  }, [])

  const handleSubmit = (event) => {
    event.preventDefault()
    if (registered) {
      updateSettings()
    } else {
      createSettings()
    }
    addIntegration(INTEGRATIONS.GoogleAnalytics)
  }

  const createSettings = () => {
    doPost({
      useChatURL: true,
      path: '/integrations/google_analytics',
      data: {
        type: 'google_analytics_settings',
        attributes: {
          google_analytics_tracking_id: trackingID,
          google_analytics_measurement_id: measurementID,
          events: state
        }
      }
    }).then(() => {
      setRegistered(true)
    })
  }

  const updateSettings = () => {
    return doPatch({
      useChatURL: true,
      path: '/integrations/google_analytics',
      data: {
        type: 'google_analytics_settings',
        attributes: {
          google_analytics_tracking_id: trackingID,
          google_analytics_measurement_id: measurementID,
          events: state
        }
      }
    })
  }

  return (
    <>
      <Breadcrumbs aria-label='breadcrumb' classes={{ li: classes.crumb, ol: classes.crumbWrapper }}>
        <Link style={{ color: '#7A7A7A' }} to='/settings/integrations'>
          Integrations Page
        </Link>
        <Typography color='textPrimary' classes={{ root: classes.typography }}>Google Analytics Integration Page</Typography>
      </Breadcrumbs>
      <div className={classes.container}>
        <img src={GoogleAnalyticsImage} className={classes.headerImage} alt='Google Analytics Logo' />
        <Typography variant='body2' className={classes.bodyText}>
          Automatically send Signals data to Google Analytics to easily
          compare Signals to other sources and gain a better understanding
          of your website traffic!
        </Typography>
        <Divider variant='middle' />
        <Typography variant='subtitle1' className={classes.subtitle1}>
          How It Works
        </Typography>
        <Typography variant='body2' className={classes.bodyText}>
          Events are sent in realtime from Signals to Google Analytics and contain the following event information:
        </Typography>
        <ul className={classes.list1}>
          <li className={classes.list1}>
            <Typography display='inline' variant='subtitle3' className={classes.subtitle3}>
              Category:
            </Typography>
            &nbsp;&nbsp;Always set to 'Signals'
          </li>
          <li className={classes.list1}>
            <Typography display='inline' variant='subtitle3' className={classes.subtitle3}>
              Action:
            </Typography>
            &nbsp;&nbsp;Multiple options are utilized including...
            <ul className={classes.list1}>
              <li className={classes.list2}>Bot Prompt
                <Tooltip title='This action is sent when a bot sends a prompt to a visitor on your website' placement='right'>
                  <InfoIcon className={classes.infoIcon} />
                </Tooltip>
              </li>
              <li className={classes.list2}>Email Captured
                <Tooltip title='This action is sent when a visitor provides you with their email address via the bot flow' placement='right'>
                  <InfoIcon className={classes.infoIcon} />
                </Tooltip>
              </li>
              <li className={classes.list2}>Meeting Booked
                <Tooltip title='This action is sent when a visitor books a meeting with one of your agents' placement='right'>
                  <InfoIcon className={classes.infoIcon} />
                </Tooltip>
              </li>
            </ul>
            <strong className={classes.shiftRight}>...And More!</strong>
          </li>
          <li className={classes.list1}>
            <Typography display='inline' variant='subtitle3' className={classes.subtitle3}>
              Label:
            </Typography>
            &nbsp;&nbsp;Contains information related to the Event Action
          </li>
        </ul>
        <Typography variant='body2' className={classes.bodyText} style={{ marginTop: 25 }}>
          Events can be accessed within Google Analytics by clicking on the Realtime or Behavior sections in the Google Analytics sidebar
        </Typography>
        <img src={ga_menu} className={classes.howToImage} alt='how to' />
        <Divider variant='middle' />
        <Typography variant='subtitle1' className={classes.subtitle1}>
          Tracking Info
        </Typography>
        <Typography variant='body2' className={classes.bodyText}>
          Enter the Google Analytics tracking ID that you would like to use to start tracking Signals events
        </Typography>
        <Typography variant='subtitle2' className={classes.subtitle2}>Tracking ID (for Universal Analytics)</Typography>
        <TextField
          required
          variant='outlined'
          placeholder='UA_123456-78'
          error={trackingIDError}
          className={classes.textField}
          value={trackingID}
          onChange={(e) => {
            if (e.target.value.length < 1) {
              // setTrackingIDError(true)
            } else {
              setTrackingIDError(false)
            }
            setTrackingID(e.target.value)
          }}
        />
        <Typography variant='subtitle2' className={classes.subtitle2}>Measurement ID (for Google Analytics 4)</Typography>
        <TextField
          required
          variant='outlined'
          placeholder='G-XXXXXXXXXX'
          error={measurementIDError}
          className={classes.textField}
          value={measurementID}
          onChange={(e) => {
            if (e.target.value.length < 1) {
              // setTrackingIDError(true)
            } else {
              setMeasurementIDError(false)
            }
            setMeasurementID(e.target.value)
          }}
        />

        <form onSubmit={handleSubmit}>
          <FormControl component='fieldset'>
            <Button
              className={classes.actionButton}
              type='submit'
              color='primary'
              variant='contained'
            >
              Save
            </Button>
          </FormControl>
        </form>
      </div>
    </>
  )
}

export default GoogleAnalyticsIntegrationPage
