import { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { patchBotTest } from 'api/bot_tests'
import { Select, MenuItem, Divider } from '@material-ui/core'
import { useQueryClient } from 'react-query'
import Modal from 'components/Modal'

const useStyles = makeStyles(theme => ({
  divider: {
    marginTop: 5,
    marginBottom: 10
  }
}))

function EndTestModal (props) {
  const classes = useStyles()
  const [value, setValue] = useState(props.defaultFlow)
  const chatServiceUrl = props.chatServiceUrl
  const id = props.test.id
  const queryClient = useQueryClient()

  function saveTest () {
    const attributes = {
      winning_flow_id: parseInt(value),
      status: props.status === 'pending' ? 'completed' : 'canceled',
      end_time: new Date()
    }
    patchBotTest({ chatServiceUrl, id, attributes })
      .then(() => {
        queryClient.invalidateQueries('test' + id)
        props.onHide()
      })
  }

  const handleChange = (event) => {
    setValue(event.target.value)
  }

  const testFlows = props.test.attributes.flows
  let flows = Object.keys(testFlows).map(f => ({ id: f, label: testFlows[f].label }))

  if (props.stats) {
    flows = props.stats.sort((a, b) => b.attributes.win_probability - a.attributes.win_probability)
      .map(flow => ({ id: flow.id, label: flow.attributes.variant }))
  }

  return (
    <Modal
      open={props.open}
      onHide={props.onHide}
      onSubmit={saveTest}
      title='End Bot Test'
      saveButtonText='Confirm'
      size='xs'
    >
      <div>
        <div>
          When you click confirm, the test will end and all traffic will be directed to the following bot:
        </div>
        <Divider className={classes.divider} />
        <Select
          value={value}
          onChange={handleChange}
          margin='dense'
          variant='outlined'
          fullWidth
        >
          <MenuItem value='' disabled>Select winning bot</MenuItem>
          {flows.map((flow, index) => (
            <MenuItem value={flow.id} key={index}>{flow.label}</MenuItem>
          ))}
        </Select>

      </div>
    </Modal>
  )
}

export default EndTestModal
