import { makeStyles } from '@material-ui/core/styles'
import { useEffect, useState } from 'react'
import BooleanComparator from './BooleanComparator'
import ContainsComparator from './ContainsComparator'
import IsComparator from './IsComparator'
import MatchComparator from './MatchComparator'
import NumericComparator from './NumericComparator'
import NoActionComparator from './NoActionComparator'
import { CONTACTS_CREATED, CONVERSATIONS, EMAIL_CAPTURES, HIGH_INTENT_PAGE_VISITS, ICP_MATCH, LIVE_CHATS, MEETINGS_BOOKED, OPEN_OPPORTUNITY, PAGE_VIEWS, PHONE_CAPTURES, SCROLL_PRECENTAGE, SITE_VISITS, TIME_ON_PAGE, TIME_ON_SITE, TITLE_DEPTH, TRAFFIC_SOURCE, UNIQUE_VISITORS, URL_VISITS } from './SelectorHelper'

const useStyles = makeStyles(theme => ({
  popupContainer: {
    width: '313px'
  },
  innerContainer: {
    margin: '20px'
  }
}))

interface ScoringCriteriaPickerProps {
  type: string
  factorGroupIndex: number
  factorIndex: number
  factors: any[]
  setFactors: (value: any[]) => void
}

export default function ScoringCriteriaPicker ({ type, factorGroupIndex, factorIndex, factors, setFactors }: ScoringCriteriaPickerProps): JSX.Element {
  const classes = useStyles()
  const [operator, setOperator] = useState(factors[factorGroupIndex].factors[factorIndex].op)
  const [value, setValue] = useState<any>(factors[factorGroupIndex].factors[factorIndex].value)

  useEffect(() => {
    const newFactors = [...factors]
    newFactors[factorGroupIndex].factors[factorIndex].op = operator
    setFactors(newFactors)
    // eslint-disable-next-line
  }, [operator])

  useEffect(() => {
    const newFactors = [...factors]
    newFactors[factorGroupIndex].factors[factorIndex].value = value
    setFactors(newFactors)
    // eslint-disable-next-line
  }, [value])

  let content = <></>
  if (type === URL_VISITS) {
    content = <ContainsComparator value={value} setValue={setValue} operator={operator} setOperator={setOperator} />
  } else if (type === TIME_ON_SITE || type === TIME_ON_PAGE) {
    content = <NumericComparator value={value} setValue={setValue} operator={operator} setOperator={setOperator} />
  } else if (type === SCROLL_PRECENTAGE) {
    content = <NumericComparator value={value} setValue={setValue} operator={operator} setOperator={setOperator} limit={100} />
  } else if (type === ICP_MATCH) {
    content = <MatchComparator operator={operator} setOperator={setOperator} />
  } else if (type === TRAFFIC_SOURCE) {
    content = <IsComparator value={value} setValue={setValue} operator={operator} setOperator={setOperator} />
  } else if (type === OPEN_OPPORTUNITY) {
    content = <BooleanComparator operator={operator} setOperator={setOperator} />
  } else if (type === HIGH_INTENT_PAGE_VISITS || type === PAGE_VIEWS || type === LIVE_CHATS || type === CONTACTS_CREATED || type === MEETINGS_BOOKED || type === SITE_VISITS || type === EMAIL_CAPTURES || type === PHONE_CAPTURES || type === CONVERSATIONS || type === TITLE_DEPTH || type === UNIQUE_VISITORS) {
    content = <NoActionComparator />
  }

  return (
    <div className={classes.popupContainer}>
      {content}
    </div>
  )
}
