import { makeStyles } from '@material-ui/core/styles'
import { Card, CardContent, Typography, Button, TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Tooltip from '@material-ui/core/Tooltip'

const useStyles = makeStyles(theme => ({
  selectorCard: {
    width: 550,
    borderRadius: 10,
    margin: 50
  },
  container: {
    display: 'flex',
    justifyContent: 'center'
  },
  cardContentArea: {
    height: '100%',
    backgroundColor: 'rgba(193, 166, 237, 0.1)',
    borderRadius: 10,
    padding: 30,
    paddingTop: 20
  },
  header: {
    backgroundColor: theme.palette.primary.main
  },
  subtitle1: {
    fontSize: 20,
    fontWeight: 600,
    color: 'white',
    padding: 20
  },
  subtitle2: {
    fontSize: 15,
    fontWeight: 600,
    marginBottom: 10
  },
  bodyText: {
    color: '#808080',
    marginBottom: 10,
    fontSize: 15
  },
  select: {
    borderRadius: 40
  },
  nextButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 20
  }
}))

const SelectBot = props => {
  const classes = useStyles()
  const state = props.state
  const bots = props.bots
  .filter(bot => bot.attributes.kind !== 'form')
  .sort((a, b) => b.attributes.is_active - a.attributes.is_active)
  return (
    <>
      <div className={classes.container}>
        <Card className={classes.selectorCard} raised>
          <div className={classes.header}>
            <Typography variant='subtitle1' className={classes.subtitle1}>
              Select Bot
            </Typography>
          </div>
          <CardContent
            className={classes.cardContentArea}
          >
            <Typography variant='body2' className={classes.bodyText}>
              This bot will be the base for the test.
            </Typography>
            <Typography variant='subtitle2' className={classes.subtitle2}>
              Select a Bot:
            </Typography>
            {bots &&
              <div>
                <Autocomplete
                  options={bots}
                  value={bots.filter(b => b.id === state.attributes.object_id)[0]}
                  fullWidth
                  classes={{ inputRoot: classes.select }}
                  autoHighlight
                  getOptionDisabled={(option) => !option.attributes.is_active && option.attributes.kind !== 'content_page'}
                  onChange={(event, bot) => {
                    if (!bot) {
                      props.setBotTestState({
                        ...state,
                        attributes: {
                          ...state.attributes,
                          object_type: null,
                          object_id: null,
                          old_flow_id: null
                        }
                      })
                    } else {
                      const botSelected = bot
                      const labelName = botSelected.attributes.name
                      const old_flow_id = botSelected.attributes.flow_id
                      props.setBotTestState({
                        ...state,
                        attributes: {
                          ...state.attributes,
                          object_type: 'bot',
                          object_id: botSelected.id,
                          old_flow_id: old_flow_id,
                          labels: [labelName + ' - A', labelName + ' - B'],
                          new_flow: null
                        }
                      })
                    }
                  }}
                  getOptionLabel={(option) => option.attributes.name}
                  renderOption={(option) => (
                    <Tooltip title={option.attributes.is_active ? '' : 'This bot is not active. To start an A/B test on this bot, activate it by placing it into an active play in your PlayMaker.'}>
                      <div>
                        {option.attributes.name}
                      </div>
                    </Tooltip>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Bot Name'
                      variant='outlined'
                      margin='dense'
                      className={classes.select}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password' // disable autocomplete and autofill
                      }}
                      style={{ borderRadius: 40 }}
                    />
                  )}
                />
              </div>}
            <div className={classes.nextButtonContainer}>
              <Button
                onClick={props.handleNext}
                className={classes.nextButton}
                variant='outlined'
                color='primary'
                disabled={!state.attributes.object_id}
              >
                Next
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>
    </>
  )
}

export default SelectBot
