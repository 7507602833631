import React, { useState } from 'react'
import { dismissNotification, resolveNotification } from './api/notifications'
import { usePostageSocket } from 'components/PostageSocket'
import { Notification } from 'classes/notifications'

interface NotificationProviderInterface {
  notifications: Notification[],
  resolve: (notificationID: number) => void,
  dismiss: (notificationID: number) => void,
  newNotifications: Notification[],
  dismissAll: (notificationIDList: number[]) => void
  hide: boolean
  setHide: (value: boolean) => void
}
const NotificationContext = React.createContext<NotificationProviderInterface>({
  notifications: [],
  resolve: () => undefined,
  dismiss: () => undefined,
  newNotifications: [],
  dismissAll: () => undefined,
  hide: false,
  setHide: () => undefined
})

function NotificationProvider ({ children } : { children?: JSX.Element | JSX.Element[] }) {
  const [hide, setHide] = useState<boolean>(false)
  const { data: notificationsData, refetch, isLoading } = Notification.loadAll()
  const notifications = notificationsData.list
  let newNotifications: Notification[] = []
  if (!isLoading) {
    newNotifications = notifications?.filter((notification) => {
      const eDate = new Date(notification.createdTimestamp as Date)
      return notification.resolvedTimestamp === null && (Date.now() - eDate.getTime()) < 1000 * 3600 * 24 * 3 && notification.dismissedTimestamp === null && notification.app
    })
  }
  usePostageSocket((msg: any) => {
    if (msg.type === 'notifications') {
      setHide(false)
      refetch()
    }
  })

  function resolve (notificationID: number) {
    resolveNotification(notificationID).then(() => {
      refetch()
    })
  }

  function dismiss (notificationID: number) {
    dismissNotification(notificationID).then(() => {
      refetch()
    })
  }

  async function dismissAll (notificaitonIDList: number[]) {
    await Notification.dismissAll(notificaitonIDList)
    refetch()
  }

  const providerValues: NotificationProviderInterface = {
    notifications: notifications,
    resolve: resolve,
    dismiss: dismiss,
    newNotifications: newNotifications,
    dismissAll: dismissAll,
    hide: hide,
    setHide: setHide
  }

  return (
    <NotificationContext.Provider value={providerValues}>
      {children}
    </NotificationContext.Provider>
  )
}

export { NotificationContext, NotificationProvider }
