import React, { useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Modal, ModalSection } from 'library/Modal'
import { FormControlLabel, Radio } from '@material-ui/core'
import { updateBotTest } from 'api/bot_tests'
import { format } from 'date-fns'
import {
  KeyboardDatePicker
  , KeyboardTimePicker
} from '@material-ui/pickers'

const useStyles = makeStyles(theme => ({
  radio: {
    display: 'flex',
    width: 130
  },
  errorMessage: {
    color: 'red'
  }
}))

const EditEndCriteriaModal = props => {
  const classes = useStyles()
  const [endTimeError, setEndTimeError] = useState('')
  const endCriteriaData = props.endCriteriaData || {}

  const formatTime = (date) => {
    const formattedDate = format(new Date(Date.parse(date)), 'PPP p')
    return formattedDate
  }

  const handleEndTime = (type, time) => {
    const startTime = new Date(endCriteriaData.startTime)
    const endTime = new Date(endCriteriaData.endTime)
    if (time instanceof Date && !isNaN(time)) {
      if (type === 'date') {
        if (startTime > time) {
          setEndTimeError('End date must be greater than start date.')
          handleEdit({ attr: 'endTime', value: time })
        } else if ((time.getTime() - startTime.getTime()) / (1000 * 3600 * 24) > 91) {
          setEndTimeError('Tests cannot be scheduled to run for more than 90 days')
          handleEdit({ attr: 'endTime', value: time })
        } else {
          setEndTimeError()
          handleEdit({ attr: 'endTime', value: time })
        }
      }
      if (type === 'time' && startTime) {
        const hours = time.getHours()
        const minutes = time.getMinutes()

        const newTime = new Date(endTime.setHours(hours, minutes))
        if (startTime > newTime) {
          setEndTimeError('End date must be greater than start date.')
          handleEdit({ attr: 'endTime', value: newTime })
        } else {
          setEndTimeError()
          handleEdit({ attr: 'endTime', value: newTime })
        }
      }
    } else {
      setEndTimeError('Invalid date')
    }
  }

  const handleEdit = ({ attr, value }) => {
    props.setEndCriteriaData({
      ...endCriteriaData,
      [attr]: value
    })
  }

  const saveEditCriteria = () => {
    let endTime
    if (endCriteriaData.endTime) {
      endTime = new Date(endCriteriaData.endTime)
    }

    const testObj = {
      id: endCriteriaData.id,
      type: 'bot-tests',
      attributes: {
        end_time: endTime,
        duration_criteria: endCriteriaData.durationCriteria

      }
    }

    updateBotTest({ state: testObj })
      .then(response => {
        props.onDone()
      })
  }

  return (
    <>
      <Modal
        title='Edit End Criteria'
        open={props.open}
        onHide={props.onHide}
        saveDisabled={endTimeError}
        saveIcon='save'
        handleSave={saveEditCriteria}
      >
        <ModalSection
          subtitle={`Editing end criteria for: ${endCriteriaData.name}`}
        >
          <FormControlLabel
            control={
              <Radio
                color='primary'
                checked={endCriteriaData.durationCriteria === 'statistical_significance'}
                onChange={(e) => {
                  if (e.target.checked) {
                    handleEdit({ attr: 'durationCriteria', value: 'statistical_significance' })
                  }
                }}
              />
            }
            label='Automatic'
            className={classes.radio}
          />
          <FormControlLabel
            control={
              <Radio
                color='primary'
                checked={endCriteriaData.durationCriteria === 'time_based'}
                onChange={(e) => {
                  if (e.target.checked) {
                    handleEdit({ attr: 'durationCriteria', value: 'time_based' })
                  }
                }}
              />
            }
            label='Time Based'
            className={classes.radio}
          />
          {endCriteriaData.durationCriteria === 'time_based' &&
          (
            <>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
                <div style={{ display: 'block' }}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant='inline'
                    disablePast
                    format='MM/dd/yyyy'
                    style={{ width: 200 }}
                    margin='dense'
                    error={Boolean(endTimeError)}
                    minDate={new Date(Date.now())}
                    id='start-date-picker-inline'
                    label='End Date'
                    value={endCriteriaData.endTime}
                    onChange={e => handleEndTime('date', e)}
                    autoOk
                    KeyboardButtonProps={{
                      'aria-label': 'change end date'
                    }}
                  />
                  {endTimeError
                    ? (
                      <div className={classes.errorMessage}>
                        {endTimeError}
                      </div>
                    )
                    : <></>}
                </div>
                <div style={{ display: 'block' }}>
                  <KeyboardTimePicker
                    autoOk
                    label='End Time'
                    style={{ width: 200 }}
                    error={Boolean(endTimeError)}
                    value={endCriteriaData.endTime}
                    onChange={e => handleEndTime('time', e)}
                  />
                  {endTimeError
                    ? (
                      <div className={classes.errorMessage}>
                        {endTimeError}
                      </div>
                    ) : <></>}
                </div>
              </div>
              <div style={{ fontWeight: 100, fontStyle: 'italic', fontSize: 14, marginTop: 10 }}>
                {` * Your test started on ${formatTime(new Date(endCriteriaData.startTime))}`}
              </div>
            </>
          )}
        </ModalSection>
      </Modal>
    </>
  )
}

export default EditEndCriteriaModal
