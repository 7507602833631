import { useContext } from 'react';
import { NPSPromptContext } from './NPSPromptContext';
import { FeedbackScreen } from './FeedbackScreen';

// const g2Link = 'https://www.g2.com/contributor/chatfunnels-g2?secure%5Bpage_id%5D=chatfunnels-g2&secure%5Brewards%5D=true&secure%5Btoken%5D=952ac453130848eba7a95d5a13056275ba0d38f7e176b7dd13adaec68a1c3851'

export function FollowupScreen () {
  const { settings, goBack, score } = useContext(NPSPromptContext)

  let content = settings.content.detractor
  // let buttons: NPSPromptButtonProps[] = []
  if (score && score > 8) {
    content = settings.content.promoter
    // buttons = content.offer ? [{ buttonText: content.offer, onClick: () => { window.open(g2Link); closePrompt() } }] : []
  } else if (score && score > 6) {
    content = settings.content.passive
    // buttons = []
  }

  return (
    <FeedbackScreen
      score={score || 0}
      header={content.header}
      body={content.body}
      goBack={() => goBack()}
    />
  )
}
