import React, { useState, useEffect } from 'react'
import { NotificationSettings } from 'library/routing/NotificationSettings'
import { makeStyles, ThemeProvider } from '@material-ui/core/styles'
import useNodeStyles from './NodeStyles'
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant'
import OptionPort from '../OptionPort'
import { jsPlumbUtil } from 'jsplumbtoolkit'
import { Divider } from '@material-ui/core'
import '../quill.css'
import { EVENT_TYPE, Emitter } from 'emitter'
import { RoutingSelector } from 'library/routing/RoutingCriteria'
import { Modal, ModalSection } from 'library/Modal'
import theme from 'theme'
import { Checkbox } from 'library/materialUI'

const useStyles = makeStyles(theme => ({
  sectionHeader: {
    fontSize: '15px',
    fontWeight: 600,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  form: {
    fontFamily: 'Poppins, sans serif'
  },
  divider: {
    marginTop: 5,
    marginBottom: 10
  },
  notificationCheckbox: {
    display: 'flex',
    fontWeight: 500,
    alignItems: 'center'
  },
  questionText: {
    textAlign: 'left',
    font: 'normal normal normal 11px/15px Poppins',
    letterSpacing: 0,
    color: '#989898',
    opacity: 1,
    marginBottom: 10
  }
}))

function AlertAgentModal (props) {
  const classes = useStyles()
  const [routeByAccountOwnerPermission, setRouteByAccountOwnerPermission] = useState()
  const [notification, setNotification] = useState(props.notification)
  const [routeOptions, setRouteOptions] = useState(props.routeOptions)
  const [alertUnavailableAgents, setAlertUnavailableAgents] = useState(props.alertUnavailableAgents)
  const [notificationSettings, setNotificationSettings] = useState({
    title: notification?.title,
    description: notification?.message
  })

  function cancelChanges () {
    props.onHide()
    setRouteOptions(props.routeOptions)
    setNotification(props.notification)
    setAlertUnavailableAgents(props.alertUnavailableAgents)
  }

  function save () {
    props.save({
      routeOptions,
      notification: { title: notificationSettings.title, message: notificationSettings.description },
      alertUnavailableAgents
    })
  }

  const updateSettings = (newSettings) => {
    const notification = newSettings.notification
    setNotificationSettings({ ...notificationSettings, ...notification })
  }

  useEffect(() => {
    if (props.open) {
      fetch('/api/auth/me', {
        method: 'GET',
        headers: { 'Content-Type': 'application/vnd.api+json' }
      })
        .then(response => response.json())
        .then(response => {
          if (response.data && response.data.id) {
            if (response.data.attributes.perms.tenant_route_by_account_owner) {
              setRouteByAccountOwnerPermission(true)
            }
          }
        })
    }
  }, [props.open])

  Emitter.on(EVENT_TYPE.BROWSER_BACK, cancelChanges)

  return (
    <ThemeProvider theme={theme}>
      <Modal
        open={props.open}
        onHide={cancelChanges}
        handleSave={save}
        title='Alert Agent'
        helplink='home/bots/nodes/AlertAgent'
        helplinkLabel='Learn more about this skill'
        saveIcon='save'
      >
        <ModalSection
          title='Routing Criteria'
        >
          <Checkbox
            color='primary'
            checked={alertUnavailableAgents}
            onChange={(value) => setAlertUnavailableAgents(value)}
            label='Alert unavailable agents'
          />
        </ModalSection>
        <div className={classes.form}>
          <RoutingSelector
            routeOptions={routeOptions}
            handleChange={setRouteOptions}
            permissions={{ routeByAccountOwner: routeByAccountOwnerPermission }}
            alertAgent
            routeTypeOptions
            subtitle='Select the agent(s) you would like to alert about this conversation.'
          />
          <NotificationSettings
            notification={notificationSettings}
            handleChange={(notification) => updateSettings({ notification })}
          />
        </div>
      </Modal>
    </ThemeProvider>
  )
}

function AlertAgentNode (props) {
  const base = props.base
  const classes = useNodeStyles(props)

  const assignKey = data => {
    data._reactKey = data._reactKey || jsPlumbUtil.uuid()
    return data._reactKey
  }

  const saveChanges = ({ routeOptions, notification, alertUnavailableAgents }) => {
    if (!base.toolkit) {
      return
    }
    const node = base.getNode()
    const newData = {
      ...base.state,
      routeOptions: routeOptions,
      notification: notification,
      alertUnavailableAgents: alertUnavailableAgents
    }

    base.toolkit.updateNode(node, newData)
    props.setModalOpen(false)
  }

  return (
    <>
      <div>
        <div
          className={`${classes.dragger} ${classes.agentConnection}`}
        >
          <div className='node-header'>
            <div className={classes.iconDiv}><NotificationImportantIcon /></div>
            <div className={classes.cardTitle}>Alert Agent</div>
          </div>
          <div className='node-body'>
            <div>Notify agent of conversation</div>
            <Divider style={{ marginTop: 5 }} />
            <div>
              {base.state.ports.map((c, index) => {
                return (
                  <OptionPort
                    index={index}
                    data={c}
                    key={assignKey(c)}
                    parent={base}
                    kind='agentConnection'
                  />
                )
              })}
            </div>
          </div>
          <jtk-target
            scope='default'
          />
        </div>
      </div>
      <AlertAgentModal
        open={props.modalOpen}
        onHide={() => props.setModalOpen(false)}
        save={saveChanges}
        alertUnavailableAgents={base.state.alertUnavailableAgents}
        routeOptions={base.state.routeOptions}
        notification={base.state.notification}
      />
    </>
  )
}

export default AlertAgentNode
