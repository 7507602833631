import { TextField, Typography } from 'library/materialUI';
import { useEffect, useState } from 'react';

interface ComparatorSelectorProps {
  setValue: (value: [number] | [number, number]) => void
  currentValue: [number] | [number, number]
  cmp: string
  max: number
  min: number
}

export default function ComparatorSelector ({ setValue, currentValue, cmp, max = 100, min = 0 }: ComparatorSelectorProps) {
  const startingValue = currentValue[0] ? currentValue : [Number(max / 2)]
  const [curValue, setCurValue] = useState<[number] | [number, number]>(startingValue)

  const handleMinMax = (newValue: number) => {
    if (newValue > max) {
      return max
    } else if (newValue < min) {
      return min
    } else {
      return newValue
    }
  }

  const handleRangeChange = (newValue: number, index: number) => {
    newValue = handleMinMax(newValue)
    if (index === 0) {
      setCurValue([newValue, curValue[1]])
    } else {
      setCurValue([curValue[0], newValue])
    }
  }

  useEffect(() => {
    if (cmp !== 'between') {
      setValue([curValue[0]])
    } else if (cmp === 'between' && curValue[1]) {
      if (curValue[0] > curValue[1]) {
        setValue([curValue[1], curValue[0]])
      } else {
        setValue(curValue)
      }
    } else {
      setValue([min, max])
    }
    // eslint-disable-next-line
  }, [curValue, cmp])

  useEffect(() => {
    if (cmp === 'between' && !curValue[1]) {
      setCurValue([min, max])
    } else if (cmp !== 'between' && !curValue[0]) {
      setCurValue([max / 2])
    } else if (cmp !== 'between' && curValue[0]) {
      setCurValue([curValue[0]])
    }
    // eslint-disable-next-line
  }, [cmp])

  if (cmp === 'between') {
    return (
      <div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <TextField
            type='number'
            variant='outlined'
            onChange={(e) => handleRangeChange(Number(e.target.value), 0)}
            onBlur={(e: any) => handleRangeChange(Number(e.target.value), 0)}
            value={String(curValue[0])}
            key='range1'
            InputProps={{
              inputProps: {
                min: min,
                max: max
              }
            }}
            error={curValue[0] > curValue[1]}
          />
          <Typography style={{ paddingLeft: 10, paddingRight: 10 }}>and</Typography>
          <TextField
            type='number'
            variant='outlined'
            onChange={(e) => handleRangeChange(Number(e.target.value), 1)}
            onBlur={(e: any) => handleRangeChange(Number(e.target.value), 1)}
            value={String(curValue[1])}
            key='range2'
            InputProps={{
              inputProps: {
                min: min,
                max: max
              }
            }}
            error={curValue[0] > curValue[1]}
          />
        </div>
        {curValue[0] > curValue[1] && <Typography style={{ marginTop: 5, color: 'red', fontSize: 12 }} variant='body2'>Your maximum must be greater than your minimum. Fix the values or click "done" to automatically swap them</Typography>}
        <Typography style={{ marginTop: 10 }} variant='body2'>Values must be between {min} and {max}</Typography>
      </div>
    )
  }
  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <TextField
          type='number'
          variant='outlined'
          onChange={(e) => setCurValue([handleMinMax(Number(e.target.value))])}
          onBlur={(e: any) => setCurValue([handleMinMax(Number(e.target.value))])}
          value={String(curValue)}
          key='value'
          InputProps={{
            inputProps: {
              min: min,
              max: max
            }
          }}
        />
      </div>
      <Typography style={{ marginTop: 10 }} variant='body2'>Value must be between {min} and {max}</Typography>
    </div>
  )
}
