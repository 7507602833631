import { Port } from 'jsplumbtoolkit';
import { IconType } from 'library/materialUI/Icon';
import { ModalSize } from 'library/Modal';
import { FlowBuilderProvider, FlowType, SaveFunction } from './canvas/FlowBuilderProvider';
import { FlowSurface } from './canvas/FlowSurface';
import { SessionProvider } from 'session-context';

export const getOrderedPorts = (ports: Port[], portOrder: string[]): Port[] => {
  const sortedPorts = [...ports]
  const samePorts = portOrder && ports.map(p => p.id).sort().join(',') === [...portOrder].sort().join(',')
  if (samePorts) {
    ports.forEach(function (p) {
      sortedPorts[portOrder.indexOf(p.id)] = p
    })
  }
  return sortedPorts
}

export type NodeData = {
  id: string
  type: string
  kind: string
  ports: NodePort[]
  settings: Record<string, any>
}

export type NodeConfig = Pick<NodeData, 'ports' | 'settings'>

export type NodeFactory = (type: string, data: NodeData, callback: any) => void

export type FlowOption = 'save' | 'publish' | 'version' | 'delete' | 'edit'

const defaultFlowOptions: FlowOption[] = ['save', 'publish', 'version', 'delete', 'edit']
export interface FlowBuilderProps {
  save?: SaveFunction
  editDetails?: () => void
  edited?: boolean
  dataRef?: any
  object: any
  initialData: any
  history?: any
  nodeMapping: Record<string, nodeMappingData>
  nodeFactory: NodeFactory
  objectType: FlowType
  flowOptions?: FlowOption[]
  preview?: boolean
  altColor?: boolean
  withinPlayrunner?: boolean
}

type menuOption = 'edit' | 'clone' | 'delete'

type NodePort = {
  id: string
  label: string
  type?: string
}

export type nodeMappingData = {
  icon: IconType
  title: string
  color: string
  header?: any
  modal?: any
  modalReducer?: (state: any, changes: any) => any
  body?: any
  category?: string
  menuOptions?: menuOption[]
  schema?: any
  modalSize?: ModalSize,
  completionText?: string
  helpCenterLink?: string
  hidden?: boolean
}

export function FlowBuilder (props: FlowBuilderProps): JSX.Element {
  return (
    <SessionProvider>
      <FlowBuilderProvider {...props}>
        <FlowSurface
          flowOptions={props.flowOptions || defaultFlowOptions}
          preview={props.preview}
          altColor={props.altColor}
          objectType={props.objectType}
          history={props.history}
          outsideEdit={props.edited}
          withinPlayrunner={props.withinPlayrunner}
        />
      </FlowBuilderProvider>
    </SessionProvider>
  )
}
