import { useDoTypeQuery } from './useDoQuery'

export interface IntegrationCredentials {
  has_credentials: number,
  oauth_start_url: string,
}
const getPath = (integration: string) => `/credentials/${integration}/info`

export function GetIntegrationCredentials (integration: string) {
  const { data, isLoading } = useDoTypeQuery<IntegrationCredentials>({
    path: getPath(integration)
  })
  return { data: data as IntegrationCredentials, isLoading }
}
