import { FormControlLabel, Checkbox, Card, CardHeader, CardContent } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import allowVisitorsInfo from 'img/allowVisitorsInfo.png'
import InformationIcon from 'library/InformationIcon'
import Picker from 'cf-components/Pickers'

const useStyles = makeStyles(theme => ({
    cardRoot: {
      padding: '2px 24px'
    },
    enableUsersCheckBox: {
      display: 'inline-flex',
      marginTop: 15
    },
    cardTitle: {
      fontSize: '17px'
    },
    card: {
      border: '1px solid rgba(142, 90, 226, 0.4)',
      minHeight: 60,
      cursor: 'pointer',
      borderRadius: 10,
      backgroundColor: 'white',
      marginBottom: 20
    }
  }))

  const VisitorConversationsSettings = (props: any) => {
    const classes = useStyles()
    const dispatch = props.dispatch
    const disableNewConversation = props.disableNewConversation
    const defaultBotProfileId = props.defaultBotProfileId
    const handleChange = (newId: number) => {
        dispatch({ type: 'updateSetting', name: 'default_bot_profile_id', value: newId })
    }

  return (
    <div style={{ position: 'relative' }}>
      <Card classes={{ root: classes.card }}>
        <CardHeader
          classes={{ title: classes.cardTitle }}
          title={props.title}
        />
        <CardContent classes={{ root: classes.cardRoot }}>
          <div style={{ color: 'grey', fontSize: 14 }}>
            This is the chatbot that fires when a visitor starts a conversation.
          </div>
          <div className={classes.enableUsersCheckBox}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={!disableNewConversation}
                  onChange={() => dispatch({ type: 'toggleSetting', name: 'disable_new_conversation' })}
                  value='disable_new_conversation'
                  color='primary'
                />
              }
              label='Allow visitors to start new conversations'
            />
            <div style={{ paddingTop: 11, marginLeft: -12 }}>
              <InformationIcon
                img={<img src={allowVisitorsInfo} alt='Company unavailable thumbnail' style={{ maxWidth: '100%' }} />}
                message='If a page of your website doesn’t have a bot, users will be able to click on the prompt icon and start a conversation. On pages that have bots, your default bot(s) will fire, but the user can start a new conversation by clicking the plus button on the top right of the chat.'
              />
            </div>
          </div>
          <div style={{ width: '20%', marginTop: '10px' }}>
            <Picker
              selection={defaultBotProfileId}
              setSelection={(value: number) => handleChange(value)}
              objectType='bots'
            />
          </div>
        </CardContent>
      </Card>
    </div>
    )
  }

  export default VisitorConversationsSettings
