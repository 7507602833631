/* eslint-disable @typescript-eslint/naming-convention */
import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Button } from '@material-ui/core'
import { validateInstall } from 'api/settings'
import { TextBox } from 'cf-components/material-wrappers/MaterialComponents'
import StatusCard from 'cf-components/StatusCard'
import AccessControl from 'components/AccessControl'
import { components } from 'session-context'

const useStyles = makeStyles(() => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  manualToggle: {
    marginRight: 50
  },
  switch: {
    marginLeft: 5
  },
  chip: {
    width: 150,
    marginLeft: 40
  },
  outerdiv: {
    margin: 16
  },
  formDiv: {
    marginTop: 20,
    display: 'flex'
  },
  urlDiv: {
    width: '800px'
  },
  buttonDiv: {
    marginTop: 2,
    marginLeft: 10
  }
}))

interface LocationResult {
  approved_domain: boolean
  install_detected: boolean
  install_valid: boolean
  location: string
}

interface ValidateResult {
  visitor_chat_enabled: boolean
  bots_enabled: boolean
  location_results: LocationResult[]
  prompt_setting: string
}

const TroubleshootingPage = () => {
  const classes = useStyles()
  const [url, setURL] = useState<string>('')
  const [validating, setValidating] = useState(false)
  const [validateResult, setValidateResult] = useState<ValidateResult>()
  const [locationResult, setLocationResult] = useState<LocationResult>()
  useEffect(() => {
    if (url && validating) {
      validateInstall({ url }).then((res: any) => {
        setValidateResult(res.data.attributes)
        if (res.data.attributes.location_results.length === 1) {
          setLocationResult(res.data.attributes.location_results[0])
        }
        setValidating(false)
      })
    } else {
      setValidating(false)
    }
  }, [url, validating])

  let fireTitle = ''
  let fireMessage = ''
  if (validateResult) {
    switch (validateResult.prompt_setting) {
      case 'always':
        fireTitle = 'Prompt will always display'
        break
      case 'available':
        fireTitle = 'Prompt will not always display'
        fireMessage = 'Prompt will only display if a bot fires or if there is company availability'
        break
      case 'bot':
        fireTitle = 'Prompt will not always display'
        fireMessage = 'Prompt will only display if a bot fires'
        break
    }
  }
  return (
    <div style={{ padding: 20 }}>
      <div className={classes.header}>
        <Typography variant='h6'>Installation Troubleshooting</Typography>
      </div>
      <div className={classes.outerdiv}>
        <Typography>
          If your bots aren't firing as expected, paste the URL of the page that the bot
          is on in the field below and ensure that the following steps have all been completed.
        </Typography>
        <div className={classes.formDiv}>
          <div className={classes.urlDiv}>
            <TextBox
              value={url}
              label='Paste URL here'
              onChange={(e: string) => {
                setURL(e)
              }}
            />
          </div>
          <div className={classes.buttonDiv}>
            <Button
              variant='contained'
              color='primary'
              disabled={validating || url === ''}
              onClick={() => {
                if (!url.startsWith('http')) {
                  setURL('https://' + url)
                }
                setValidating(true)
              }}
            >
              Check Page
            </Button>
          </div>
        </div>
        {validateResult && !validating &&
          <>
            <StatusCard
              variant={locationResult && locationResult.approved_domain ? 'pass' : 'fail'}
              title='Approved domain'
              message={locationResult && locationResult.approved_domain ? '' : 'It looks like the given domain is not on your approved list.'}
              link='/#/settings/chat_settings/domains'
            />
            <StatusCard
              variant={locationResult && locationResult.install_detected ? 'pass' : 'fail'}
              title='Code is installed'
              message={locationResult && locationResult.install_detected ? '' : 'It looks like the script has not been installed at the given location.'}
              link='/#/settings/installation/manual'
            />
            <StatusCard
              variant={locationResult && locationResult.install_valid ? 'pass' : 'fail'}
              title='Code matches exactly'
              message={locationResult && locationResult.install_valid ? '' : 'We\'ve detected the script on your site, but characters have been added and/or removed. Please delete the script from your page and then copy and paste it back in, making sure not to alter it.'}
              link='/#/settings/installation/manual'
            />
            <AccessControl requiredComponent={components.CHAT}>
              <StatusCard
                variant={validateResult.bots_enabled ? 'pass' : 'fail'}
                title='Bots are enabled'
                message={validateResult.bots_enabled ? '' : 'It looks like you don\'t have any bots enabled. You will need to do that before anything will fire.'}
                link='/#/engagement/chatbots/chatbots'
              />
            </AccessControl>
            <AccessControl requiredComponent={components.CHAT}>
              <StatusCard
                variant={validateResult.visitor_chat_enabled ? 'pass' : 'fail'}
                title='Visitor chat is turned on'
                message={validateResult.visitor_chat_enabled ? '' : 'You\'ll need to flip the master switch before your bots will fire'}
                link='/#/settings/installation/manual'
              />
            </AccessControl>
            <AccessControl requiredComponent={components.CHAT}>
              <StatusCard
                variant={validateResult.prompt_setting === 'always' ? 'pass' : 'warning'}
                title={fireTitle}
                message={fireMessage}
                link='/#/settings/chat_settings/chat_availability'
              />
            </AccessControl>
          </>}
      </div>
    </div>
  )
}

export default TroubleshootingPage
