
const descriptionText = 'This node will ask the web visitor to rate their chat experience with your bot.  This allows you to learn more about what your bot and your chat agents are doing well and how you can make things even better for your web visitors!'

const Description = (): JSX.Element => {
  return (
    <div>
      <div>
        {descriptionText}
      </div>
      {/* <ul>
        <li>whatever we want</li>
        <li>to put in a list</li>
      </ul> */}
    </div>
  )
}

const bestPracticeText = 'Ensure that you appropriately link the node outputs to their appropriate bot flows.  The Conversation Rating node has three outputs: dissatisfied rating, neutral rating and satisfied rating, and correspond to ratings as follows: '

const BestPractices = () => {
  return (
    <div>
      <div>
        {bestPracticeText}
      </div>
      <ul>
        <li>Dissatisfied rating: 1-2</li>
        <li>Neutral rating: 3 </li>
        <li>Satisfied rating: 4-5</li>
      </ul>
    </div>
  )
}

export const RateConversationDocs = {
  helpCenterLink: 'https://help.getsignals.ai/article/hdv7aexosq-conversation-rating-node',
  bestPractices: BestPractices,
  description: Description,
  videoLink: ''
}
