// We should use the MaterialComponents.tsx wrappers whenever possible in order to maintain consistency.
// If it is deemed necessary to support a different style component that wrapper can be placed and used from this file

import { makeStyles } from '@material-ui/core/styles'
import { Select as MuiSelect, MenuItem, Checkbox, ListItemText, FormControl } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  formControlComponent: {
    margin: 0,
    marginLeft: 5
  },
  selectComponent: {
    marginTop: 4,
    fontWeight: 600,
    color: 'black'
  }
}))

/**
 * @typedef {Object} SelectOption
 * @property {any} value - typically a string or number
 * @property {string} label - the text rendered in the dropdown box
 */
interface SelectOption {
  value: any
  label: string
}

/**
 * @typedef {Object} SelectProps
 * @property {any} onChange - a function
 * @property {any} value - typically a string or number equal to one of the values passed in the options array
 * @property {SelectOption[]} options - an array of objects each containing value and label keys {@link SelectOption}
 * @property {string} placeholder - optional - placeholder text
 * @property {boolean} multiple - optional - set to true if multiple values can be selected from the dropdown menu
 */
export interface SelectProps {
  onChange: any
  value: any
  options: SelectOption[]
  placeholder?: string
  multiple?: boolean
  style?: any
}

const getValue = (option: SelectOption, prop: string): string => {
  if (typeof option === 'string') {
    return option
  } else {
    return option[prop as keyof SelectOption]
  }
}

function renderValue (selection: string[], options: SelectOption[], placeholder?: string): string {
  if (!selection.length) {
    return placeholder || ''
  } else {
    let labels = selection
    if (options[0].value) {
      labels = selection.map(s => options.filter(o => o.value === s)[0].label)
    }
    return (
      labels.join(', ')
    )
  }
}

function MultiSelect (props: SelectProps): JSX.Element {
  const classes = useStyles()
  return (
    <FormControl variant='standard' margin='dense' className={classes.formControlComponent}>
      <MuiSelect
        variant='standard'
        margin='dense'
        value={props.value}
        displayEmpty
        onChange={(e: any) => props.onChange(e.target.value)}
        renderValue={(selected: any) => renderValue(selected, props.options, props.placeholder)}
        multiple
        className={classes.selectComponent}
        style={props.style}
      >
        <MenuItem disabled value=''>{props.placeholder}</MenuItem>
        {props.options.map((option: SelectOption, index: number) => {
          const value = getValue(option, 'value')
          return (
            <MenuItem value={value} key={index} style={{ paddingBottom: 3, paddingTop: 3 }}>
              <Checkbox
                checked={props.value.indexOf(value) > -1}
                color='primary'
                style={{ padding: 5, marginRight: 5 }}
              />
              <ListItemText primary={getValue(option, 'label')} />
            </MenuItem>
          )
        })}
      </MuiSelect>
    </FormControl>
  )
}

function SingleSelect (props: SelectProps): JSX.Element {
  const classes = useStyles()
  return (
    <FormControl variant='standard' margin='dense' className={classes.formControlComponent}>
      <MuiSelect
        variant='standard'
        margin='dense'
        value={props.value}
        displayEmpty
        onChange={(e: any) => props.onChange(e.target.value)}
        className={classes.selectComponent}
        style={props.style}
      >
        <MenuItem disabled value=''>{props.placeholder}</MenuItem>
        {props.options.map((option: SelectOption, index: number) => {
          const value = getValue(option, 'value')
          const label = getValue(option, 'label')
          return (
            <MenuItem value={value} key={index}>{label}</MenuItem>
          )
        })}
      </MuiSelect>
    </FormControl>
  )
}

/**
 * An alternate to our standard Select wrapper which styles a dropdown menu without an outline, InputLabel or fullWidth, and styled (text is bolder and black, and margins are changed) so that it can appear in line with header text
 *
 * @param props: {@link SelectProps}
 *
 * Required properties:
 * -  value -> typically a string or number
 * -  options -> {@link SelectOption} array with value and label keys
 * -  onChange -> a function
 *
 * Optional properties:
 * -  multiple -> boolean defining if multiple selections allowed
 * -  placeholder -> string
 *
 * @returns JSX.Element containing FormControl, Select, and MenuItem components
 */
export function SelectNoOutline (props: SelectProps): JSX.Element {
  if (props.multiple) {
    return (
      <MultiSelect
        {...props}
      />
    )
  } else {
    return (
      <SingleSelect
        {...props}
      />
    )
  }
}
