import { useEffect, useReducer } from 'react'
import { BaseNodeComponent } from 'jsplumbtoolkit-react'
import { Card } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/core/styles'
import { Emitter, EVENT_TYPE } from '../../canvas/helpers/EventEmitter'
import { NodeModal } from '../NodeModal'
import { QueryClientProvider } from 'react-query'
import { queryClient } from 'App'
import { NodeMenu } from '../NodeMenu'
import theme from 'theme'
import { handleSelection, cloneNode, getShadow } from './helpers'
import { EndNodeIndicator } from './NodeComponents'
import { NodeContents } from './NodeContents'

const initialState = {
  hover: false,
  selected: false,
  modalOpen: false,
  unconnected: false,
  invalid: false,
  isNew: false
}

const reducer = (state, changes) => {
  return { ...state, ...changes }
}

function BaseNode (props) {
  const nodeMapping = window.flowBuilder.nodeMapping
  const [state, dispatch] = useReducer(reducer, initialState)
  const nodeState = props.base.state
  const nodeID = nodeState.id
  const kind = nodeState.kind
  const mapping = nodeMapping[kind]
  const pointerEvents = props.base.toolkit?.preview ? 'none' : 'auto'

  useEffect(() => {
    if (kind === 'Sequence') {
      if (state.modalOpen) {
        window.sequenceNode = props.base
      } else {
        window.sequenceNode = null
      }
    }
    // eslint-disable-next-line
  }, [state.modalOpen])

  useEffect(() => {
    const selectionCallback = (payload) => {
      if (payload.toolkit.id === props.base.toolkit.id) {
        const isSelected = payload.nodeIDs.includes(nodeID)
        dispatch({ selected: isSelected })
      }
    }
    const problemNodeCallback = (payload) => {
      const isUnconnected = payload.unconnected.includes(nodeID)
      const isInvalid = payload.invalid.includes(nodeID)
      dispatch({ unconnected: isUnconnected, invalid: isInvalid })
    }
    const nodeAddedCallback = (payload) => {
      const isNew = payload.nodeID === nodeID && payload.isNew
      if (isNew && nodeState.type !== 'sequence') {
        dispatch({ modalOpen: true, isNew: true })
      }
    }
    Emitter.on(EVENT_TYPE.NODE_SELECTION, selectionCallback)
    Emitter.on(EVENT_TYPE.PROBLEM_NODES, problemNodeCallback)
    Emitter.on(EVENT_TYPE.NODE_ADDED, nodeAddedCallback)

    return () => {
      Emitter.remove(EVENT_TYPE.NODE_SELECTION, selectionCallback)
      Emitter.remove(EVENT_TYPE.PROBLEM_NODES, problemNodeCallback)
      Emitter.remove(EVENT_TYPE.NODE_ADDED, nodeAddedCallback)
    }
    // eslint-disable-next-line
  }, [nodeID])

  const handleClick = (event) => {
    if (event.ctrlKey || event.metaKey) {
      handleSelection(props.base)
    }
  }

  const cancel = () => {
    const isNew = state.isNew
    dispatch({ modalOpen: false, hover: false, isNew: false })
    if (isNew) {
      props.base.toolkit.removeNode(nodeID)
    }
  }

  const boxShadow = getShadow(state)

  return (
    <div className='base-node' style={{ pointerEvents }}>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <div>
            <Card
              onDoubleClick={() => dispatch({ modalOpen: true })}
              onMouseEnter={() => dispatch({ hover: true })}
              onMouseLeave={() => dispatch({ hover: false })}
              onClick={handleClick}
              style={{ boxShadow, width: 226 }}
            >
              <NodeMenu
                mapping={mapping}
                open={state.hover}
                removeNode={() => props.base.toolkit.removeNode(nodeID)}
                editNode={() => dispatch({ modalOpen: true })}
                cloneNode={() => cloneNode(props.base)}
              />
              <NodeContents
                base={props.base}
                state={state}
              />
              <jtk-target
                scope='default'
              />
              <NodeModal
                open={state.modalOpen}
                onHide={() => dispatch({ modalOpen: false, hover: false, isNew: false })}
                node={props.base}
                cancel={cancel}
                helpCenterLink={mapping.helpCenterLink}
              />
            </Card>
            <EndNodeIndicator
              completionText={mapping.completionText}
            />
          </div>
        </QueryClientProvider>
      </ThemeProvider>
    </div>
  )
}

class BetterBaseNode extends BaseNodeComponent {
  render () {
    return (
      <BaseNode base={this} />
    )
  }
}

export default BetterBaseNode
