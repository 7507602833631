import GenericObject from './genericObject'
import { useDoQuery } from './useDoQuery'
import { LoadAllProps } from './queryHelpers'

interface ContactListArray {
    list: ContactList[]
    dict: { [id: ContactList['id']]: ContactList }
}

export class ContactList extends GenericObject {
    name: string
    objectType = 'contactList'

    static loadAll (props?: LoadAllProps): { data: ContactListArray, isLoading: boolean } {
        // const extraHeaders = {
        //    'x-integration': integration.searchParams.search
        // }
        return useDoQuery({
            path: '/integrations/crm/lists?type=contacts',
            objectClass: ContactList,
            useChatURL: false,
            searchParams: props?.searchParams,
            extraHeaders: props?.searchParams?.extraHeaders
        })
    }

    constructor ({ row }: { row: Record<string, any> }) {
        super({ row })
        const contactList = row.attributes
        this.name = contactList.name
    }
}
