import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { AvatarWithInfo, getInitials, getColor } from 'cf-components/Avatars'
import { ListItemCard } from 'cf-components/CardList'
import Chip from '@material-ui/core/Chip'
import Table from 'cf-components/Table'
import ActionsMenu from 'cf-components/ActionsMenu'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/DeleteForever'

const useStyles = makeStyles(theme => ({
  chip: {
    marginTop: 8,
    marginBottom: 8,
    marginRight: 10
  },
  keywordCard: {
    display: 'grid',
    gridTemplateColumns: '2fr 1fr 1fr 2fr 50px',
    padding: 15,
    alignItems: 'center',
    cursor: 'pointer'
  },
  headerClass: {
    display: 'grid',
    gridTemplateColumns: '2fr 1fr 1fr 2fr 50px',
    padding: '5px 15px',
    alignItems: 'center'
  }
}))

function KeyWordGroupsTable (props) {
  const editGroup = props.editGroup
  const handleDelete = props.handleDelete
  const classes = useStyles()

  const rows = props.rows ? props.rows.map(row => {
    const name = row.attributes.name
    const initials = getInitials(name, 'G')
    const keywords = row.attributes.keywords
    return (
      {
        name: name,
        initials: initials,
        description: row.attributes.description,
        id: row.id,
        keywords: keywords,
        comparison: row.attributes.comparison,
        keywordCount: keywords.length,
        searchField: name
      })
  }) : null

  function KeyWordPreview (props) {
    const classes = useStyles()

    return (
      <div style={{ height: 48, overflow: 'hidden' }}>
        {props.keywords.map((keyword, index) => (
          <Chip
            label={keyword}
            key={index}
            color='primary'
            className={classes.chip}
            variant='outlined'
          />
        ))}
      </div>
    )
  }

  function KeywordCard (props) {
    const row = props.row
    const color = getColor(props.index)
    let comparison = 'Contains'

    const edit = () => {
      editGroup({
        name: row.name,
        description: row.description,
        id: row.id,
        comparison: row.comparison,
        keywords: row.keywords
      })
    }

    if (row.comparison === 'equals') {
      comparison = 'Equals'
    }
    if (row.comparison === 'similar') {
      comparison = 'Similar to'
    }

    const actions = [
      { name: 'Edit Group', action: edit, icon: EditIcon },
      { name: 'Delete Group', action: () => handleDelete(row.id), icon: DeleteIcon }
    ]

    return (
      <ListItemCard>
        <div
          className={classes.keywordCard}
          onClick={edit}
        >
          <div>
            <AvatarWithInfo
              title={row.name}
              initials={row.initials}
              subtitle='Keyword Group'
              avatarColor={color}
              variant='rounded'
            />
          </div>
          <div>
            {row.description}
          </div>
          <div>
            {comparison}
          </div>
          <div>
            <KeyWordPreview keywords={row.keywords} />
          </div>
          <ActionsMenu
            actions={actions}
          />
        </div>
      </ListItemCard>
    )
  }

  const sortValues = [
    { label: 'Name', id: 'name', header: true },
    { label: 'Description', id: 'description', header: true },
    { label: 'Comparison', id: 'comparison', header: true },
    { label: 'Keywords', id: 'keywordCount', header: true }
  ]

  return (
    <Table
      rows={rows}
      card={KeywordCard}
      sortValues={sortValues}
      header
      headerClass={classes.headerClass}
      startingSortColumn='name'
    />
  )
}

export default KeyWordGroupsTable
