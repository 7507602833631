import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { Dialog, DialogTitle, DialogContent, Typography, Divider, Tabs, Tab } from '@material-ui/core'
import { ModalButton, ModalButtonProps } from 'cf-components/Modal'
import { reverse } from 'lodash'
import { Icon, LabeledCheckbox, Tooltip } from 'library/materialUI'
import { CheckboxProps } from 'cf-components/material-wrappers/MaterialComponents'
import { Button, IconButton } from './materialUI/Button'
import { EVENT_TYPE, Emitter } from 'emitter'
import { IconType } from './materialUI/Icon'

const useStyles = makeStyles(theme => ({
  title: {
    color: '#4C4C4C',
    fontWeight: 600
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'right',
    padding: '16px 24px'
  },
  dynamicBtnFooter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'right',
    padding: '14px 0px',
    width: '98%'
  },
  backBtn: {
    whiteSpace: 'nowrap',
    textTransform: 'none',
    marginLeft: 10,
    width: '125px !important',
    // marginRight: 'auto',
    height: 36.5,
    whiteSpace: 'nowrap',
    fontFamily: 'poppins'
  },
  extended: {
    width: '125px !important',
    marginLeft: '10px',
    whiteSpace: 'nowrap',
    textTransform: 'none',
    borderRadius: 10
  },
  button: {
    whiteSpace: 'nowrap',
    textTransform: 'none',
    padding: '5px 12px',
    marginLeft: 10,
    fontSize: 14,
    fontFamily: 'poppins',
    backgroundColor: theme.palette.primary.alt,
    minWidth: 'fit-content',
    color: 'white',
    '&:hover': {
      backgroundColor: '#6941A1'
    },
    '&:disabled': {
      backgroundColor: '#E2E2E2'
    },
    borderRadius: '5px !important'
  },
  cancelButton: {
    whiteSpace: 'nowrap',
    textTransform: 'none',
    backgroundColor: 'white',
    color: '#343434',
    boxShadow: 'none',
    fontFamily: 'poppins',
    borderRadius: '5px !important',
    padding: '5px 12px',
    marginRight: 10,
    minWidth: 80
  },
  leftButton: {
    whiteSpace: 'nowrap',
    textTransform: 'none',
    backgroundColor: 'white',
    color: '#343434',
    boxShadow: 'none',
    fontFamily: 'poppins',
    borderRadius: '5px !important',
    padding: '5px 12px',
    marginRight: 'auto',
    minWidth: 80
  },
  divider: {
    marginBottom: 5
  },
  boldDivider: {
    marginBottom: 5,
    background: theme.palette.primary.main,
    height: '2px'
  },
  section: {
    marginTop: 5,
    marginBottom: 15,
    border: '1px solid #D3D3D3',
    borderRadius: 5,
    backgroundColor: 'white',
    padding: '20px 20px'
  },
  header: {
    marginBottom: 5
  },
  dialog: {
    fontFamily: theme.typography.fontFamily
  },
  mediumModal: {
    maxWidth: 800
  },
  floatingButton: {
    bottom: 18,
    left: 20,
    position: 'absolute',
    cursor: 'pointer'
  },
  noPadding: {
    padding: 0
  },
  subtitle: {
    fontSize: 14,
    color: 'grey',
    padding: '0px 24px',
    marginTop: '-16px'
  },
  flexContent: {
    display: 'flex',
    justifyContent: 'center'
  },
  tabsIndicator: {
    backgroundColor: '#9933ff'
  }
}))

export type dynamicButton = ModalButtonProps | CheckboxProps | undefined
export type dynamicButtonArray = [dynamicButton, dynamicButton, dynamicButton] | [dynamicButton, dynamicButton] | [dynamicButton] | []
export type ModalSize = 'sm' | 'md' | 'lg' | 'xs'
type ModalSectionVariants = 'bold'
export interface ModalProps {
  open: boolean
  onHide: () => void
  title?: string | JSX.Element
  children?: any
  saveDisabled?: boolean
  saveDisabledTooltip?: string
  handleSave: () => void
  helplink?: string
  helplinkLabel?: string
  size?: ModalSize
  /**
   * [0] - right button of right side grouping,
   * [1] - left button of right side grouping,
   * [2] - left button of left side
   */
  dynamicButtons?: dynamicButtonArray
  noDividers?: boolean
  noPadding?: boolean
  noFooter?: boolean
  flexContent?: boolean
  subtitle?: string
  noCloseBtn?: boolean
  saveBtnText?: string
  cancelBtnText?: string
  hideCancelButton?: boolean
  hideSaveButton?: boolean
  disableBackdropClick?: boolean
  disableEscClick?: boolean
  closeBtnFunction?: () => void
  centerTitle?: boolean
  saveIcon?: IconType
  tabLabels?: string[]
  tab?: number
  handleTabChange?: () => void
  saveIconPosition?: 'start' | 'end' | undefined
  cancelIcon?: IconType
  cancelIconPosition?: 'start' | 'end' | undefined
  leftBtnText?: string
  leftBtnFunction?: () => void
  leftBtnIcon?: IconType
  saveBtnColor?: string
  footerContents?: JSX.Element
}

export interface ModalSectionProps {
  title?: string | JSX.Element
  subtitle?: string
  children?: React.ReactNode
  variant?: ModalSectionVariants
  divider?: boolean
  titleType?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  borderColor?: string
}

export function ModalSection ({ title, subtitle, children, variant, divider = false, titleType = 'h2', borderColor = undefined }: ModalSectionProps): JSX.Element {
  const classes = useStyles()
  let titleComp = <Typography variant={titleType}>{title}</Typography>
  let dividerStyle = classes.divider
  if (variant === 'bold') {
    titleComp = <Typography style={{ fontWeight: 'bold' }}>{title} </Typography>
    dividerStyle = classes.boldDivider
  }
  return (
    <div className={classes.section} style={{ borderColor: borderColor }}>
      {(title || subtitle) &&
        <div className={classes.header}>
          {titleComp}
          {divider && <Divider className={dividerStyle} />}
          {Boolean(subtitle) &&
            <Typography variant='body2'>
              {subtitle}
            </Typography>}
        </div>}
      {children}
    </div>
  )
}

export function HelpLink ({ helplink, className, helplinkLabel }: { helplink?: string, className?: string, helplinkLabel?: string }): JSX.Element {
  const classes = useStyles()
  if (helplink) {
    return (
      <div
        onClick={() => {
          if (helplink.startsWith('http')) {
            window.open(helplink, '_blank')
          } else {
            Emitter.emit(EVENT_TYPE.HELP_CENTER, { path: helplink })
          }
        }}
        className={className || classes.floatingButton}
        style={{ color: '#9933ff', cursor: 'pointer', display: 'flex', alignItems: 'center' }}
      >
        <Icon icon='help' color='#9933ff' specificSize={20} />
        <div style={{ marginLeft: 5, fontSize: 14 }}>
          {helplinkLabel}
        </div>
      </div>
    )
  } else {
    return <></>
  }
}

function GetDynamicButtonsArray (dynamicButtonArray: dynamicButtonArray | undefined): JSX.Element | undefined {
  const classes = useStyles()
  if (dynamicButtonArray) {
    const reversedBtnArray = reverse([...dynamicButtonArray])
    return (
      <div className={classes.dynamicBtnFooter}>
        {reversedBtnArray.map((dynamicButton: dynamicButton, index: number) => {
          const classMapping = reversedBtnArray.length === 2 ? [classes.backBtn, classes.button] : [classes.backBtn, classes.cancelButton, classes.button]
          if (dynamicButton !== undefined) {
            if ('checked' in dynamicButton) {
              return (
                <LabeledCheckbox
                  key={index}
                  {...dynamicButton}
                  className={classMapping[index]}
                />
              )
            } else {
              return (
                <ModalButton
                  key={index}
                  {...dynamicButton}
                  className={classMapping[index]}
                />
              )
            }
          }
          return null
        })}
      </div>
    )
  }
}

export function Modal (props: ModalProps): JSX.Element {
  const classes = useStyles()
  const size = props.size || 'sm'
  const hideCancelButton = props.hideCancelButton === undefined ? false : props.hideCancelButton
  const hideSaveButton = props.hideSaveButton === undefined ? false : props.hideSaveButton
  const handleSave = props.handleSave
  const dynamicButtonArray = GetDynamicButtonsArray(props.dynamicButtons)
  const dividers = !props.noDividers
  const noPadding = props.noPadding ? classes.noPadding : undefined
  const flexContent = props.flexContent ? classes.flexContent : undefined
  const dialogClass = props.noPadding ? `${classes.noPadding} ${classes.dialog}` : classes.dialog
  const handleClose = (_event: any, reason: string): void => {
    if (props.disableEscClick && reason === 'escapeKeyDown') {
      return
    }
    if (props.disableBackdropClick && reason === 'backdropClick') {
      return
    }
    props.onHide()
  }

  return (
    <Dialog
      onClose={handleClose}
      open={props.open}
      fullWidth
      maxWidth={size}
      className={dialogClass}
      classes={{ paperWidthMd: classes.mediumModal }}
    >
      <div style={{ display: 'flex', justifyContent: props.centerTitle ? 'center' : 'space-between', alignItems: 'center' }}>
        {props.title &&
          <DialogTitle className={classes.title}>
            {props.title}
          </DialogTitle>}
        {!props.noCloseBtn && (
          <div style={{ float: 'right' }}>
            <IconButton
              color='primary'
              size='md'
              icon='close'
              onClick={() => props.onHide()}
            />
          </div>
        )}
      </div>
      {props.subtitle &&
        <DialogTitle className={classes.subtitle}>
          {props.subtitle}
        </DialogTitle>}
      {props.tabLabels && (
        <div>
          <Divider />
          <Tabs
            value={props.tab}
            onChange={props.handleTabChange}
            indicatorColor='primary'
            textColor='primary'
            classes={{ indicator: classes.tabsIndicator }}
          >
            {props.tabLabels.map((label, index) => (
              <Tab
                key={index}
                label={label}
                style={{
                  textTransform: 'none',
                  color: props.tab === index ? '#9933ff' : 'inherit'
                }}
              />
            ))}
          </Tabs>
        </div>
      )}
      {props.children &&
        <DialogContent
          style={{ backgroundColor: '#F5F5F5' }}
          dividers={dividers}
          className={clsx(noPadding, flexContent)}
          classes={{ dividers: noPadding }}
        >
          {props.children}
        </DialogContent>}
      {!props.noFooter && (dynamicButtonArray ||
        <div className={classes.footer}>
          {props.footerContents && (
            <div style={{ marginRight: 50, width: '100%' }}>
              {props.footerContents}
            </div>
          )}
          {props.leftBtnText && (
            <Button
              className={classes.leftButton}
              variant='outlined'
              size='small'
              onClick={props.leftBtnFunction}
              startIcon={props.leftBtnIcon && <Icon icon={props.leftBtnIcon} color='black' size='sm' />}
            >
              {props.leftBtnText}
            </Button>
          )}
          <HelpLink helplink={props.helplink} helplinkLabel={props.helplinkLabel} />
          {hideCancelButton !== true && (
            <Button
              className={classes.cancelButton}
              variant='outlined'
              size='small'
              onClick={props.closeBtnFunction ? props.closeBtnFunction : props.onHide}
              startIcon={props.cancelIcon && !(props.cancelIconPosition === 'end') ? <Icon icon={props.cancelIcon} color='black' size='sm' /> : ''}
              endIcon={props.cancelIcon && props.cancelIconPosition === 'end' ? <Icon icon={props.cancelIcon} color='black' size='sm' /> : ''}
            >
              {props.cancelBtnText || 'Cancel'}
            </Button>)}
          {hideSaveButton !== true && (
            props.saveDisabledTooltip && props.saveDisabled ? (
              <Tooltip title={props.saveDisabledTooltip}>
                <span>
                  <Button
                    style={{ color: props.saveBtnColor || 'primary' }}
                    color='primary'
                    variant='contained'
                    size='small'
                    className={classes.button}
                    onClick={handleSave}
                    disabled={props.saveDisabled}
                    startIcon={props.saveIcon && !(props.saveIconPosition === 'end') ? <Icon icon={props.saveIcon} color={props.saveDisabled ? '#b3b3b3' : 'white'} size='sm' /> : ''}
                    endIcon={props.saveIcon && props.saveIconPosition === 'end' ? <Icon icon={props.saveIcon} color={props.saveDisabled ? '#b3b3b3' : 'white'} size='sm' /> : ''}
                  >
                    {props.saveBtnText || 'Save'}
                  </Button>
                </span>
              </Tooltip>
            ) : (
              <Button
                style={{ backgroundColor: props.saveBtnColor || 'primary' }}
                color='primary'
                variant='contained'
                size='small'
                className={classes.button}
                onClick={handleSave}
                disabled={props.saveDisabled}
                startIcon={props.saveIcon && !(props.saveIconPosition === 'end') ? <Icon icon={props.saveIcon} color={props.saveDisabled ? '#b3b3b3' : 'white'} size='sm' /> : ''}
                endIcon={props.saveIcon && props.saveIconPosition === 'end' ? <Icon icon={props.saveIcon} color={props.saveDisabled ? '#b3b3b3' : 'white'} size='sm' /> : ''}
              >
                {props.saveBtnText || 'Save'}
              </Button>
            ))}
        </div>)}
    </Dialog>
  )
}
