import Skeleton from '@material-ui/lab/Skeleton';
import { SkeletonSize } from './SkeletonLoader';

interface SkeletonLabelProps {
  size: SkeletonSize
}

const sizeMapping = {
  sm: { height: '1em', width: '3em' },
  md: { height: '1.5em', width: '5em' },
  lg: { height: '2em', width: '7.25em' },
  full: { height: '2em', width: '100%' }
}

export default function SkeletonLabel ({ size } : SkeletonLabelProps) {
  const { height, width } = sizeMapping[size]
  return (
    <Skeleton height={height} width={width} variant='text' />
  )
}
