import { makeStyles, ThemeProvider } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import Fab from '@material-ui/core/Fab'
import theme from '../../../theme'
import InformationIcon from 'library/InformationIcon'
import { Button } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: '#FFF'
  },
  title: {
    color: '#4C4C4C',
    fontWeight: 600
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    padding: 14
  },
  button: {
    textTransform: 'none',
    width: '125px !important'
  },
  cancelButton: {
    textTransform: 'none',
    width: '125px !important',
    marginLeft: 20,
    backgroundColor: 'white',
    border: '1px solid rgba(0,0,0,0.5)',
    boxShadow: 'none'
  },
  floatingButton: {
    top: 15,
    right: 0,
    position: 'absolute',
    borderRadius: 3
  }
}))

function Modal (props) {
  const classes = useStyles()
  const saveButtonText = props.saveButtonText || 'Save and Close'
  const size = props.size || 'sm'
  const hideFooter = props.hideFooter
  const handleSave = props.save
  const cancelText = props.cancelText || 'Cancel'
  const tooltipText = props.tooltipText || 'Click to learn more about how this node works.'
  const footerClass = props.footerClass

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        onClose={props.onHide}
        open={props.open}
        fullWidth
        maxWidth={size}
      >
        <MuiDialogTitle className={classes.title} classes={{ root: props.titleClass }}>
          {props.title}
        </MuiDialogTitle>
        {props.helpLink
          ? (
            <Button
              className={classes.floatingButton}
              onClick={() => { window.open(props.helpLink, '_blank') }}
            >
              <InformationIcon
                message={tooltipText}
              />
            </Button>
          )
          : <></>}
        <MuiDialogContent dividers classes={{ dividers: props.contentClass }}>
          {props.children}
        </MuiDialogContent>

        <div className={footerClass ? `${footerClass} ${classes.footer}` : classes.footer}>
          {!hideFooter ? (
            <>
              <Fab
                color='primary'
                variant='extended'
                size='small'
                className={classes.button}
                onClick={handleSave}
                disabled={props.disabled}
              >
                {saveButtonText}
              </Fab>
              <Fab
                className={classes.cancelButton}
                size='small'
                variant='extended'
                onClick={props.onHide}
              >
                {cancelText}
              </Fab>
            </>
          ) : <></>}

        </div>
      </Dialog>
    </ThemeProvider>
  )
}

export default Modal
