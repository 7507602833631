import { Select, LabeledCheckbox } from 'cf-components/material-wrappers/MaterialComponents'
import { ModalSection } from 'library/Modal'
import { ModalContentsProps } from 'library/flowBuilder/nodes/NodeModal'
import { Email } from 'classes/classes'
import Picker from 'cf-components/Pickers'
import * as yup from 'yup'
import { Button, Icon } from 'library/materialUI'
import { Typography } from '@material-ui/core'
import Loader from 'library/loading/Loader'
import { useCallback, useEffect, useState } from 'react'
import { getEmailSettingsV2 } from 'api/emails'
import WarningDiv from '../WarningDiv'
import ModalWarning from 'components/ModalWarning'
import { getIntegrationFeature } from 'api/integrations'
import { AddIntegrationSection } from '../integration/IntegrationNode'
import { IntegrationBodyRow } from '../integration/IntegrationBodyRow'

const delayOptions = [
  { value: 1, label: '1 hour' },
  { value: 2, label: '2 hours' },
  { value: 4, label: '4 hours' },
  { value: 24, label: '1 day' },
  { value: 48, label: '2 days' },
  { value: 72, label: '3 days' },
  { value: 168, label: '1 week' }
]

const initialIntegrations = {
  salesforce: [],
  marketo: [],
  hubspot: [],
  sendinblue: [],
  eloqua: []
}

const initialActiveIntegrations = {
  salesforce: false,
  marketo: false,
  hubspot: false,
  sendinblue: false,
  eloqua: false
}

export const EmailNodeSchema = yup.object().shape({
  settings: yup.object({
    emailID: yup.number()
  })
})

export function EmailModal ({ settings, updateSettings }: ModalContentsProps): JSX.Element {
  const delaySend = settings.delaySend
  const [emailSettings, setEmailSettings] = useState<any | null>(null)
  const [availableIntegrations, setAvailableIntegrations] = useState<any[]>([])
  const [integrationEntryList, setIntegrationEntryList] = useState<any[]>([])

  const checkAdditionalIntegrations = useCallback(() => {
    if (!settings?.integrations) {
      settings.integrations = initialIntegrations
      settings.activeIntegrations = initialActiveIntegrations
    }
    const integrations = Object.entries(settings.integrations)
    setIntegrationEntryList(integrations.filter((value: any) => value[1].length !== 0))
  }, [settings])

  const getAvailable = useCallback(() => {
    getIntegrationFeature('crm.create_contact').then((res: any) => {
      const available = res.data.attributes.available_integrations.filter((integration: any) => integration.enabled)
      const enabled = available.map((integration: any) => { return { value: integration.name, label: integration.name.charAt(0).toUpperCase() + integration.name.slice(1) } })
      setAvailableIntegrations(enabled)
    })
  }, [])

  const setAdditionalIntegration = (integration: string, listIDs: any[]) => {
    settings.integrations[integration] = listIDs
    settings.activeIntegrations[integration] = true
    settings.title = 'Integrations'
    if (Object.values(settings.integrations).every(integration => integration.length === 0)) {
      settings.title = 'Send Email'
    }
  }

  useEffect(() => {
    getEmailSettingsV2().then((resp: any) => {
      setEmailSettings(resp)
    }).catch((err: Error) => {
      if (err.message.includes('not_found')) {
        setEmailSettings({})
      }
    })
  })

  useEffect(() => {
    checkAdditionalIntegrations()
    getAvailable()
  }, [settings.activeIntegrations, checkAdditionalIntegrations, getAvailable])

  if (emailSettings === null) {
    return (
      <Loader type='spinner' />
    )
  }

  if (Object.keys(emailSettings).length === 0) {
    return (
      <WarningDiv
        title='Email'
        notSetup
        btnRedirect='/#/settings/email_settings'
      />
    )
  }
  const warningBody = 'To receive an email, a visitor\'s email address must have been captured before entering this current play.'

  return (
    <div>
      <ModalWarning body={warningBody} />
      <Typography variant='subtitle1'>
        Send a one-off email to self-identified site visitors or buying committee contacts looked up using a prospect skill
      </Typography>
      <ModalSection
        title='Email Template'
        subtitle='Which email would you like to send?'
      >
        <Picker
          selection={settings.emailID}
          setSelection={(value: any) => updateSettings({ emailID: value })}
          objectType='emails'
        />
        {settings.emailID ? (
          <Button
            style={{ fontSize: 12, color: 'black', marginTop: 5, borderRadius: 50 }}
            variant='outlined'
            size='small'
            onClick={() => window.open('/#/emails/edit/' + settings.emailID)}
          >
            <div style={{ marginRight: '5px' }}>
              <Icon icon='edit' />
            </div>
            <div style={{ marginTop: '2px' }}>
              Edit Email
            </div>
          </Button>
        ) : (
          <Button
            style={{ fontSize: 12, color: 'black', marginTop: 5, borderRadius: 50 }}
            variant='outlined'
            size='small'
            onClick={() => window.open('/#/emails/new')}
          >
            <div style={{ marginRight: '5px' }}>
              <Icon icon='add' />
            </div>
            <div style={{ marginTop: '2px' }}>
              Create a new email
            </div>
          </Button>)}
        <div style={{ paddingTop: 10 }}>
          <LabeledCheckbox
            label='Add a delay before the email is sent'
            checked={delaySend}
            onChange={(value: boolean) => updateSettings({ delaySend: value })}
          />
          {delaySend &&
            <div style={{ width: '50%' }}>
              <Select
                value={settings.delayHours}
                onChange={(value: number) => updateSettings({ delayHours: value })}
                options={delayOptions}
                placeholder='Select delay period'
              />
            </div>}
        </div>
      </ModalSection>
      <AddIntegrationSection
        integrationEntryList={integrationEntryList}
        setIntegrationEntryList={setIntegrationEntryList}
        availableIntegrations={availableIntegrations}
        setAdditionalIntegration={setAdditionalIntegration}
      />
    </div>
  )
}

export function SendEmailBody ({ settings }: any): JSX.Element {
  const emailID = settings.emailID
  const { data: emails } = Email.loadAll()
  const email = emails.dict[emailID]
  const name = email?.name
  const message = name ? 'Send ' + name + ' email' : 'No email selected'
  const checkAdditionalIntegrations = () => {
    if (!settings?.integrations) {
      settings.integrations = initialIntegrations
      settings.activeIntegrations = initialActiveIntegrations
    }
    const allIntegrations = Object.entries(settings.integrations)
    return allIntegrations.filter((value: any) => value[1].length !== 0)
  }
  const integrations = checkAdditionalIntegrations()
  if (integrations.length !== 0) {
    return (
      <div>
        <div style={{ display: 'flex' }}>
          <Icon icon='email' color='#4972CC' size='lg' />
          <div style={{ marginLeft: '14px' }}>
            <div style={{ font: 'normal normal normal 13px/20px Poppins' }}>Send Email</div>
            <b>{name}</b>
          </div>
        </div>
        {integrations.map((integration: any, index: number) => {
          if (integration[1].length !== 0) {
            return (
              <IntegrationBodyRow
                key={index}
                integration={integration[0]}
                listIDs={integration[1]}
              />
            )
          }
          return <div key={index} />
        })}
      </div>
    )
  }
  return (
    <div style={{ minHeight: '20px' }}>
      {message}
    </div>
  )
}
