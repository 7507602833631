import { makeStyles } from '@material-ui/core/styles'
import { Button, Icon } from 'library/materialUI'
import Dropzone from 'react-dropzone'

const useStyles = makeStyles(theme => ({
  rightIcon: {
    marginLeft: theme.spacing(1),
    color: 'gray'
  },
  photoIcon: {
    width: 50,
    height: 50,
    color: 'gray'
  },
  dropZone: {
    cursor: 'pointer',
    display: 'flex'
  },
  paragraph: {
    color: '#5F5F5F',
    fontSize: '15px',
    marginBottom: 0,
    marginTop: 10
  },
  paragraph2: {
    color: '#8B8B8B',
    fontSize: '13px',
    marginBottom: 10,
    marginTop: 5
  },
  content: {
    textAlign: 'center',
    padding: 40,
    border: '2px dashed #BFBFBF'
  }
}))

type DropZoneProps = {
  handler: (file: any) => void
}

export function PhotoDropZone (props: DropZoneProps) {
  const classes = useStyles()

  const handleDrop = (acceptedFile: any) => {
    props.handler(acceptedFile[0])
  }

  return (
    <Dropzone onDrop={handleDrop}>
      {({ getRootProps, getInputProps }) => (
        <div className={classes.dropZone} {...getRootProps()}>
          <input {...getInputProps()} />
          <div className={classes.content}>
            <Icon icon='addPhoto' color='gray' size='lg' />
            <p className={classes.paragraph}>Drag an image here</p>
            <p className={classes.paragraph2}>-or-</p>
            <Button
              variant='outlined'
              color='primary'
              endIcon={<Icon icon='upload' />}
              size='small'
            >
              Upload from computer
            </Button>
          </div>
        </div>
      )}
    </Dropzone>
  )
}
