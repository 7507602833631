import React, { useEffect, useState, useCallback, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Modal } from 'cf-components/Modal'
import { Avatar, getInitials } from 'cf-components/Avatars'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select'
import Loader from 'library/loading/Loader'
import { getIntegrationFields } from 'api/integrations'
import Picker from 'cf-components/Pickers'
import { Link } from 'react-router-dom'
import { CRMContext } from 'crm-context'

const useStyles = makeStyles(theme => ({
  accountOwner: {
    width: 200,
    marginLeft: 20,
    marginTop: 14
  },
  attribute: {
    display: 'grid',
    gridTemplateRows: '1fr 1fr',
    paddingBottom: 2,
    borderBottom: '1px solid #d5d5d5',
    fontFamily: 'Poppins, sans serif'
  },
  AttributeDropdown: {
    display: 'grid',
    gridTemplateRows: '2fr 3fr',
    paddingBottom: 2,
    fontFamily: 'Poppins, sans serif'
  },
  saveButton: {
    marginRight: 20,
    marginTop: 14,
    float: 'right'
  },
  attributeName: {
    color: '#404040',
    fontWeight: 600,
    paddingRight: 50,
    paddingBottom: 4,
    fontSize: '15px'
  },
  attributeValue: {
    color: '#4A4A4A',
    fontSize: '14px',
    maxWidth: '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  subHeading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
    height: 40,
    margin: '0px 0px 15px 0px',
    backgroundColor: 'rgba(0,0,0,.03)',
    border: '1px solid rgba(0,0,0,.125)'
  },
  subDetails: {
    width: '96%',
    paddingTop: 10,
    padding: '10px 10px 0px 15px'
  },
  information: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: 20
  },
  companyHeader: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid #A1A1A1',
    paddingBottom: 15,
    marginBottom: 15
  },
  loadingScreen: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexFlow: 'column nowrap'
  }
}))

// TODO: figure out a way to handle this w/o a hard-coded list
const requiredFieldsToIgnore = ['Name', 'OwnerId', 'name', 'hubspot_owner_id']

function CRMFieldFactory (field, handleEdit) {
  const styles = {
    color: '#009EDB'
  }
  if (!field) {
    return <></>
  }

  const dataType = field.data_type || 'string'
  const labelName = field.label
  const apiName = field.api_name

  if (dataType === 'string') {
    return (
      <AttributeTextField
        styles={styles}
        name={`${labelName}*`}
        attr={apiName}
        value=''
        handleEdit={handleEdit}
      />
    )
  }

  if (['bool', 'boolean', 'true-false'].includes(dataType)) {
    return (
      <BooleanDropdown
        styles={styles}
        name={`${labelName}*`}
        attr={apiName}
        value={0}
        handleEdit={handleEdit}
      />
    )
  }
}

function BooleanDropdown (props) {
  const classes = useStyles()
  const { color = '#404040' } = props.styles || {}
  const [selectedObject, setSelectedObject] = useState(null)

  const value = selectedObject || ''

  return (
    <div className={classes.AttributeDropdown}>
      <div className={classes.attributeName} style={{ color: color }}>
        {props.name}
      </div>
      <Select
        fullWidth
        variant='outlined'
        value={value}
        onChange={(e) => {
          const booleanText = e.target.value
          let bool
          if (!booleanText || booleanText === 'false') {
            bool = false
          } else if (booleanText === 'true') {
            bool = true
          }
          setSelectedObject(booleanText)
          props.handleEdit({ attr: props.attr, value: bool })
        }}
        margin='dense'
      >
        <MenuItem disabled value=''>Select an option</MenuItem>
        <MenuItem value='true' style={{ height: 40 }}>
          True
        </MenuItem>
        <MenuItem value='false' style={{ height: 40 }}>
          False
        </MenuItem>
      </Select>
    </div>
  )
}

function AttributeTextField (props) {
  const classes = useStyles()
  const { color = '#404040' } = props.styles || {}
  const [name, setName] = useState(props.value)

  return (
    <div className={classes.AttributeDropdown}>
      <div className={classes.attributeName} style={{ color: color }}>
        {props.name}
      </div>
      <TextField
        fullWidth
        variant='outlined'
        onChange={e => {
          setName(e.target.value)
          props.handleEdit({ attr: props.attr, value: e.target.value })
        }}
        value={name}
        margin='dense'
        style={{ marginBottom: 0, marginTop: 0 }}
      />
    </div>
  )
}

function AttributeDropdown (props) {
  const classes = useStyles()
  const [selectedObject, setSelectedObject] = useState(props.value)

  const value = selectedObject || ''
  const options = props.options
  if (value !== '' && options.indexOf(value) === -1) {
    options.unshift(value)
  }

  return (
    <div className={classes.AttributeDropdown}>
      <div className={classes.attributeName}>
        {props.name}
      </div>
      <Select
        fullWidth
        variant='outlined'
        value={value}
        onChange={(e) => {
          setSelectedObject(e.target.value)
          props.handleEdit({ attr: props.attr, value: e.target.value })
        }}
        margin='dense'
      >
        <MenuItem disabled value=''>Select an option</MenuItem>
        {options.map(id => {
          if (props.accountOwner) {
            const user = props.users[id]
            const name = user.attributes.name
            return (
              <MenuItem key={id} value={id} style={{ height: 40 }}>
                {name}
              </MenuItem>
            )
          }
          return (
            <MenuItem key={id} value={id} style={{ height: 40 }}>
              {id}
            </MenuItem>
          )
        })}
      </Select>
    </div>
  )
}

function PotentialAccountModal (props) {
  const { crmIntegrationName } = useContext(CRMContext)
  const domain = props.domain
  const accLoading = props.accLoading
  const classes = useStyles()
  const [initials, setInitials] = useState(getInitials('UA'))
  const [account, setAccount] = useState(props.account || {})
  const [users, setUsers] = useState([])
  const [validUserIds, setValidUserIds] = useState([])
  const [requiredFields, setRequiredFields] = useState([])
  const [additionalFieldsObject, setAdditionalFieldsObject] = useState({})
  const [saving, setSaving] = useState(false)
  const [crmError, setCRMError] = useState(false)
  // const { user, isLoading } = useContext(SessionContext)
  if (account && !account.website) {
    if (!account.website) {
      account.website = account.domain
    }
  }

  const appendRequiredFieldsToState = useCallback((fields) => {
    const additionalFields = {}
    for (const field of fields) {
      const apiFieldName = field.api_name
      if (requiredFieldsToIgnore.includes(apiFieldName)) {
        continue
      } else {
        additionalFields[apiFieldName] = null
      }
    }
    setAdditionalFieldsObject(additionalFields)
  }, [])
  useEffect(() => {
    if (props.account) {
      setAccount(props.account)
    }
  }, [props.account])
  useEffect(() => {
    if (account) {
      setInitials(getInitials(account.name))
    }
  }, [account])

  useEffect(() => {
    if (crmIntegrationName) {
      getIntegrationFields(crmIntegrationName, 'accounts')
        .then(response => {
          const fields = []
          response.data.forEach((f) => {
            if (f.attributes.required) {
              fields.push(f.attributes)
            }
          })
          appendRequiredFieldsToState(fields)
          setRequiredFields(fields)
        }).catch((e) => {
          setCRMError(true)
        })
    }
  }, [appendRequiredFieldsToState, crmIntegrationName])

  useEffect(() => {
    const headers = {
      'Content-Type': 'application/vnd.api+json'
    }
    fetch('/api/users?include=calendar_status', {
      method: 'GET',
      headers: headers
    })
      .then(response => response.json())
      .then(user_response => {
        let userDict = {}
        user_response.data.map(user => (userDict = { ...userDict, [user.id]: { ...user } }))

        setUsers(userDict)
        const user_ids = Object.keys(userDict).map(Number)
        setValidUserIds(user_ids)
      })
  }, [])

  function handleEditAdditionalField ({ attr, value }) {
    setAdditionalFieldsObject({ ...additionalFieldsObject, [attr]: value })
  }

  function isDisabled () {
    if (saving || !account?.name || !account?.website || !account.owner_user_id) {
      return true
    }

    const reqFields = additionalFieldsObject || {}
    for (const key of Object.keys(reqFields)) {
      if (reqFields[key] === null || reqFields[key] === '') {
        return true
      }
    }

    return false
  }
  function handleEdit (change) {
    const current = account
    current[change.attr] = change.value
    setAccount({ ...current })
  }

  function saveAccount () {
    setSaving(true)
    props.handleSubmit(account, additionalFieldsObject)
    if (props.setAccountAdded) {
      const temp_arr = props.accountAdded
      temp_arr.push(account.domain)
      props.setAccountAdded(temp_arr)
    }
    setSaving(false)
  }

  const disabled = isDisabled()

  let modalSaveText = 'Create Account'
  if (crmIntegrationName) {
    modalSaveText = 'Create Account in ' + crmIntegrationName
  }

  return (
    <Modal
      title='Potential New Account'
      onHide={props.onHide}
      open={props.open}
      size='md'
      noPadding
      rightButtonProps={{
        text: modalSaveText,
        action: saveAccount,
        disabled: disabled
      }}
    >
      {crmError ? (<>Failed to load fields from CRM. Please make sure your configured CRM is setup and connected to Signals. <Link to='/settings/integrations'>See Integrations</Link></>) : (
        <>
          {accLoading ? (
            <div style={{ marginTop: '200px', marginBottom: '200px' }} className={classes.loadingScreen}>
              <Loader type='spinner' size='lg' />
            </div>
          ) : account.domain && (
            <div className={classes.subDetails}>
              <div style={{ marginBottom: 25 }} elevation={3}>
                <div className={classes.companyHeader}>
                  <Avatar
                    participantID={account.id}
                    initials={initials}
                    size='md'
                    avatarUrl={`/api/logo-service/logo/${domain}.png`}
                    variant='square'
                    alt={initials}
                  />
                  <div style={{ marginLeft: 10 }}>
                    <div style={{ color: '#4A4A4A', fontSize: 25, fontWeight: 500 }}>{account.name}</div>
                  </div>
                </div>
              </div>
              <div className={classes.subHeading}>Account Information</div>
              <div className={classes.information}>
                <AttributeTextField
                  name='Name*'
                  value={account.name}
                  attr='name'
                  handleEdit={handleEdit}
                />
                <AttributeTextField
                  name='Website*'
                  value={account.website}
                  attr='website'
                  handleEdit={handleEdit}
                />
                <AttributeDropdown
                  name='Account Owner*'
                  // value={user.id}
                  options={validUserIds}
                  attr='owner_user_id'
                  users={users}
                  accountOwner
                  handleEdit={handleEdit}
                />
                <div className={classes.AttributeDropdown}>
                  <div className={classes.attributeName} style={{ color: '#404040' }}>
                    Account Type
                  </div>
                  <Picker
                    selection={account.accountType}
                    setSelection={(value) => handleEdit({ attr: 'accountType', value: value })}
                    objectType='accountTypes'
                    label=''
                  />
                </div>
                {requiredFields.map((field, index) => {
                  const apiFieldName = field.api_name
                  if (requiredFieldsToIgnore.includes(apiFieldName)) {
                    return <React.Fragment key={index} />
                  }

                  return (
                    <div key={index}>
                      {CRMFieldFactory(field, handleEditAdditionalField)}
                    </div>
                  )
                })}
              </div>
            </div>
          )}
        </>)}
    </Modal>
  )
}

export default PotentialAccountModal
