import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import Extension from '@material-ui/icons/Extension'
import { useLocation } from 'react-router-dom'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import Snackbar from '@material-ui/core/Snackbar'
import { parse } from 'query-string'
import Card from '@material-ui/core/Card'
import { CardHeader } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import HubSpotIcon from 'img/hubspot_clear.png'
import ChatFunnelsIcon from 'img/Logo_purple_transparent_BG.png' // chatfunnels.png'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center'
  },
  card: {
    width: 350,
    height: 375,
    marginTop: 200,
    borderRadius: 10
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  logo: {
    height: 50
  },
  logoWrapper: {
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
    marginTop: 25
  },
  button: {
    position: 'absolute',
    bottom: 40,
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%'
  },
  cardContent: {
    height: '100%',
    position: 'relative',
    margin: 20
  },
  description: {
    textAlign: 'center',
    fontSize: 15,
    marginTop: 25
  }
}))

const oauth_mappings = {
  access_denied: 'OAuth Error: Access was denied. Please try again.',
  immediate_unsuccessful: 'OAuth Error: Please try again.',
  expired_token: 'OAuth Error: Your token has expired. Please try again.'
}

function oauth_message (input_message) {
  const attempt = oauth_mappings[input_message]
  if (!input_message) {
    return ''
  }
  if (!attempt) {
    return 'Unknown error. Please try again'
  }
  return attempt
}

const HubSpotOAuth = (props) => {
  const classes = useStyles()
  const location = useLocation()
  const query_string = parse(location.search)
  const oauthError = oauth_message(query_string.error)
  const [oauthMessage, setOauthMessage] = useState(oauthError)

  function performOauth (event) {
    window.location.href = props.oauthUrl
  }

  function onMessageClose (event) {
    setOauthMessage(false)
  }

  return (
    <div className={classes.container}>
      <Card className={classes.card} raised>
        <div className={classes.cardContent}>
          <div className={classes.logoContainer}>
            <div className={classes.logoWrapper}>
              <img src={ChatFunnelsIcon} className={classes.logo} alt='Signals Logo' />
              <img src={HubSpotIcon} className={classes.logo} alt='HubSpot Logo' />
            </div>
          </div>
          <CardHeader title={props.name} style={{ textAlign: 'center' }}>
            <Extension />
          </CardHeader>
          <Divider />
          <div>
            <div>
              <Typography variant='body2' className={classes.description}>
                You are one step away from linking your Signals account
                with HubSpot CRM!
              </Typography>
              <Button
                variant='outlined'
                color='primary'
                className={classes.button}
                onClick={performOauth}
              >
                Start activation
              </Button>
            </div>

          </div>
        </div>
      </Card>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        aria-describedby='client-snackbar'
        action={[
          <IconButton
            key='close'
            aria-label='Close'
            color='inherit'
            onClick={onMessageClose}
          >
            <CloseIcon className={classes.icon} />
          </IconButton>
        ]}
        open={oauthMessage}
        autoHideDuration={6000}
        ContentProps={{
          'aria-describedby': 'message-id'
        }}
        message={<span id='message-id'>{oauthError}</span>}
      />
    </div>
  )
}

export default HubSpotOAuth
