import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CircularProgress from '@material-ui/core/CircularProgress'
import Divider from '@material-ui/core/Divider'
import Fab from '@material-ui/core/Fab'
import InputAdornment from '@material-ui/core/InputAdornment'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Stepper from '@material-ui/core/Stepper'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import HelpIcon from '@material-ui/icons/Help'
import { postEmailDomain, sendDNSEmail, updateEmailSettings, verifyEmailSetup } from 'api/emails'
import CustomizedSnackbar from 'components/CustomizedSnackbar'
import InputSuggestion from 'library/InputSuggestion'
import { useContext, useState } from 'react'
import Loader from 'library/loading/Loader'
import { SessionContext } from 'session-context'
import Modal from '../../../components/Modal'

const useStyles = makeStyles(theme => ({
  stepper: {
    backgroundColor: 'inherit',
    width: 700
  },
  stepperDiv: {
    display: 'flex',
    justifyContent: 'center'
  },
  settingsCard: {
    border: `1px solid ${theme.palette.primary.main}`,
    width: '100%',
    maxWidth: 600,
    padding: 15
  },
  contents: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 15
  },
  cardTitle: {
    fontSize: '18px',
    color: '#333'
  },
  divider: {
    marginTop: 10,
    marginBottom: 10
  },
  cardContents: {
    fontSize: '15px',
    color: '#444',
    lineHeight: '22px'
  },
  navigationDiv: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 20
  },
  cardDiv: {
    width: '100%',
    maxWidth: 632
  },
  cardHelpLink: {
    float: 'right',
    marginTop: -5,
    color: theme.palette.primary.main
  },
  senderInfo: {
    paddingRight: 20,
    marginTop: 10
  },
  dnsFields: {
    display: 'grid',
    gridTemplateColumns: '3fr 4fr 4fr',
    gridColumnGap: 30,
    gridRowGap: 8,
    color: '#AAA',
    fontSize: '11px'
  },
  copyBox: {
    display: 'grid',
    gridTemplateColumns: 'auto 30px',
    gridGap: 8,
    whiteSpace: 'nowrap'
  },
  copyButton: {
    cursor: 'pointer',
    padding: 6,
    backgroundColor: theme.palette.primary.main,
    height: 20,
    borderRadius: 5,
    boxShadow: '0px 3px 6px #00000029'
  },
  copyText: {
    fontSize: '13px',
    marginLeft: 5
  },
  verifyButtonDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 30,
    marginBottom: 15
  },
  verificationMessage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 25,
    fontSize: '14px'
  },
  unverifiedMessage: {
    color: '#D96F6F',
    display: 'flex',
    alignItems: 'center'
  },
  verifiedMessage: {
    color: '#93DE90',
    display: 'flex',
    alignItems: 'center'
  },
  verificationIcon: {
    fontSize: '1.3em',
    marginRight: 5
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -10,
    marginLeft: -10
  },
  errorMessage: {
    color: 'rgb(240, 112, 111)',
    marginTop: 5,
    height: 22
  },
  inputText: {
    color: '#4A4A4A',
    fontSize: '14px'
  }
}))

function CopyBox (props) {
  const classes = useStyles()
  return (
    <div className={classes.copyBox}>
      <Card style={{ padding: 5 }}>
        <div className={classes.copyText}>
          {props.value}
        </div>
      </Card>
      <div
        className={classes.copyButton}
        onClick={() => {
          props.setSnackState({
            open: true,
            variant: 'success',
            message: 'Value copied to clipboard!'
          })
          navigator.clipboard.writeText(props.value)
        }}
      >
        <FileCopyIcon
          style={{ width: '.8em', height: '0.8em', fill: 'white' }}
        />
      </div>
    </div>
  )
}

function NavigationButton (props) {
  const classes = useStyles()
  return (
    <div style={{ position: 'relative' }}>
      <Fab
        variant='extended'
        color='primary'
        style={{ width: 110 }}
        size='small'
        disabled={props.disabled || props.loading}
        onClick={props.onClick}
      >
        {props.label}
      </Fab>
      {props.loading && <CircularProgress size={20} className={classes.buttonProgress} />}
    </div>
  )
}

function SettingsCard (props) {
  const classes = useStyles()
  return (
    <Card className={classes.settingsCard}>
      <div className={classes.cardTitle}>
        {props.title}
        {props.helpLink && <Button className={classes.cardHelpLink} href={props.helpLink} target='_blank' startIcon={<HelpIcon />}>View Helpdoc</Button>}
      </div>
      <Divider className={classes.divider} />
      <div className={classes.cardContents}>
        {props.children}
      </div>
    </Card>
  )
}

function VerificationMessage (props) {
  const classes = useStyles()

  if (props.loading) {
    return (
      <div style={{ marginTop: 5 }}>
        <Loader type='spinner' />
      </div>
    )
  } else if (props.verified) {
    return (
      <div className={classes.verifiedMessage}><CheckIcon className={classes.verificationIcon} /> Your information has been verified!</div>
    )
  } else if (props.verified == null) {
    return (
      <></>
    )
  } else {
    return (
      <div className={classes.unverifiedMessage}><CloseIcon className={classes.verificationIcon} /> Your information could not be verified.</div>
    )
  }
}

function DNSCard (props) {
  const classes = useStyles()
  const message = 'Copy the Domain Name System (DNS) information below and paste it into the DNS settings in your domain registration provider (i.e. GoDaddy, Namecheap, Squarespace, Bluehost, etc.)'
  const verifyInstructions = 'Click "Verify" after the information above has been entered into your domain registration provider to verify the connection.'
  const domainData = props.settings?.attributes.postmark_domain_data
  const domain = props.settings?.attributes?.sender_domain || ''
  const [loading, setLoading] = useState(false)
  const [verified, setVerified] = useState((domainData && domainData.DKIMVerified && domainData.ReturnPathDomainVerified) || null)
  const [snackState, setSnackState] = useState({
    open: false,
    variant: 'success',
    message: 'Value copied to clipboard!'
  })
  const [modalOpen, setModalOpen] = useState(false)
  const [email, setEmail] = useState('')
  const [emailMessage, setEmailMessage] = useState('')

  function handleVerify () {
    setLoading(true)
    verifyEmailSetup()
      .then(response => {
        const data = response.data.meta
        if (data.valid_dkim && data.valid_return_path) {
          setVerified(true)
        } else {
          setVerified(false)
        }
        setLoading(false)
      })
  }

  let host = domainData?.DKIMPendingHost || domainData?.DKIMHost || ''
  let returnPath = domainData?.ReturnPathDomain || ''
  if (host.endsWith(domain)) {
    host = host.slice(0, -domain.length - 1)
  }
  if (returnPath.endsWith(domain)) {
    returnPath = returnPath.slice(0, -domain.length - 1)
  }
  const dkimValue = domainData?.DKIMPendingTextValue || domainData?.DKIMTextValue || ''

  function handleEmailSend () {
    setModalOpen(false)
    sendDNSEmail(email, emailMessage, host, dkimValue, returnPath, domainData?.ReturnPathDomainCNAMEValue)
    setSnackState(
      {
        open: true,
        variant: 'success',
        message: `An email has been sent to ${email}. Don’t forget to return to this page to finish the setup once your team member has added the DNS records.`
      }
    )
  }

  return (
    <div className={classes.cardDiv}>
      <SettingsCard
        title='DNS'
        helpLink='https://help.getsignals.ai/article/v546w294or-email-settings#accessing_email_settings'
      >
        <div>
          <p>
            {message}
          </p>
          {domainData &&
            <>
              <div className={classes.dnsFields}>
                <div>Record Type</div>
                <div>Name</div>
                <div>Content</div>
                <CopyBox value='TXT' setSnackState={setSnackState} />
                <CopyBox value={host} setSnackState={setSnackState} />
                <CopyBox value={dkimValue} setSnackState={setSnackState} />
                <CopyBox value='CNAME' setSnackState={setSnackState} />
                <CopyBox value={returnPath} setSnackState={setSnackState} />
                <CopyBox value={domainData.ReturnPathDomainCNAMEValue} setSnackState={setSnackState} />
              </div>
              <p>
                {verifyInstructions}
              </p>
              <div className={classes.verifyButtonDiv}>
                <Button
                  variant='outlined'
                  color='primary'
                  size='small'
                  onClick={handleVerify}
                >
                  Verify
                </Button>
              </div>
              <div className={classes.verificationMessage}>
                <VerificationMessage
                  verified={verified}
                  loading={loading}
                />
              </div>
            </>}
        </div>
      </SettingsCard>
      <div onClick={() => setModalOpen(true)} style={{ color: '#9933FF', marginTop: '10px', textDecorationLine: 'underline', cursor: 'pointer' }}>
        Need someone else on your team to set this up for you?
      </div>
      <div className={classes.navigationDiv}>
        <NavigationButton
          label='Back'
          onClick={props.onBack}
        />
      </div>
      {modalOpen && (
        <Modal
          title='Send DNS Information'
          onHide={() => setModalOpen(false)}
          open={modalOpen}
          saveButtonText='Send Email'
          onSubmit={() => handleEmailSend()}
          size='sm'
        >
          <div style={{ display: 'flex', fontWeight: 'bold', fontFamily: 'poppins', flexDirection: 'column', marginBottom: '10px', fontSize: '16px' }}>Need someone else on your team to set this up for you?</div>
          <div style={{ display: 'flex', fontFamily: 'poppins', flexDirection: 'column', color: '#808080', marginBottom: '10px', fontSize: '14px' }}>Send them an email with everything they need to get things up and running.</div>
          <div>
            <TextField
              variant='outlined'
              margin='dense'
              label='Person’s email address'
              value={email}
              onChange={e => setEmail(e.target.value)}
              InputProps={{ classes: { input: classes.inputText }, style: { width: 550 } }}
            />
          </div>
          <div>
            <TextField
              variant='outlined'
              margin='dense'
              label='Message (optional)'
              multiline
              rows={4}
              value={emailMessage}
              onChange={e => setEmailMessage(e.target.value)}
              InputProps={{ classes: { input: classes.inputText }, style: { width: 550 } }}
            />
          </div>
        </Modal>
      )}
      <CustomizedSnackbar state={snackState} handler={setSnackState} />
    </div>
  )
}

function DomainCard (props) {
  const { user } = useContext(SessionContext)
  const userEmail = user?.attributes?.email || ''
  const userDomain = userEmail.includes('@') ? userEmail.split('@')[1] : ''
  const [showSuggestion, setShowSuggestion] = useState(userDomain !== '')
  const classes = useStyles()
  const message = "Enter your organization's domain below. For example, if your email address at your organization is johndoe@mydomain.com, your domain would be mydomain.com."

  return (
    <div className={classes.cardDiv}>
      <SettingsCard
        title='Domain'
        helpLink='https://help.getsignals.ai/article/v546w294or-email-settings#accessing_email_settings'
      >
        <div>
          <p>
            {message}
          </p>
          {showSuggestion && !props.domain && (
            <InputSuggestion
              onAccept={() => { props.setDomain(userDomain); setShowSuggestion(false) }}
              onIgnore={() => { setShowSuggestion(false) }}
              body={<>It looks like your email domain might be <b>{userDomain}</b>. Want to use that?</>}
            />
          )}
          <TextField
            variant='outlined'
            fullWidth
            label='Domain'
            margin='dense'
            value={props.domain}
            onChange={(e) => props.setDomain(e.target.value)}
          />
        </div>
      </SettingsCard>
      <div className={classes.navigationDiv}>
        <div />
        <NavigationButton
          label='Next'
          disabled={!props.domain.includes('.')}
          onClick={props.onNext}
          loading={props.posting}
        />
      </div>
    </div>
  )
}

function SenderCard (props) {
  const classes = useStyles()
  const message = 'Choose which email prefix and name you would like to have your emails sent from.'
  const settings = props.settings
  const [name, setName] = useState(settings?.default_sender_name)
  const [emailPrefix, setEmailPrefix] = useState(settings?.default_sender_email_prefix || '')

  const errorMessage = emailPrefix.includes('@') ? 'The email prefix should only include the portion of the address before the @ sign' : ''

  return (
    <div className={classes.cardDiv}>
      <SettingsCard
        title='Sender Information'
        helpLink='https://help.getsignals.ai/article/v546w294or-email-settings#accessing_email_settings'
      >
        <div>
          <p>
            {message}
          </p>
          <div className={classes.senderInfo}>
            <TextField
              label='Email Address'
              variant='outlined'
              margin='dense'
              value={emailPrefix}
              onChange={(e) => setEmailPrefix(e.target.value)}
              InputProps={{
                endAdornment: <InputAdornment position='end'>{'@' + props.domain}</InputAdornment>
              }}
            /> <br />
            <TextField
              label='Name'
              variant='outlined'
              margin='dense'
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className={classes.errorMessage}>
            {errorMessage}
          </div>
        </div>
      </SettingsCard>
      <div className={classes.navigationDiv}>
        <NavigationButton
          label='Back'
          onClick={props.onBack}
        />
        <NavigationButton
          label='Next'
          onClick={() => props.onSave({ name, emailPrefix })}
          disabled={emailPrefix.includes('@') || !emailPrefix || !name}
          loading={props.posting}
        />
      </div>
    </div>
  )
}

function EmailSetup (props) {
  const classes = useStyles()

  const attributes = props.settings?.attributes
  const [activeStep, setActiveStep] = useState(0)
  const [domain, setDomain] = useState(attributes?.sender_domain || '')
  const [posting, setPosting] = useState(false)
  const setSettings = props.setSettings

  const steps = ['Domain', 'Sender', 'DNS']

  function handleSave ({ name, emailPrefix }) {
    if (attributes?.sender_domain === domain) {
      setActiveStep(2)
    } else if (!props.settings) {
      setPosting(true)
      postEmailDomain({ domain, name, emailPrefix })
        .then(response => {
          setSettings(response.data)
          setPosting(false)
          setActiveStep(2)
        }).catch(error => {
          console.error({ domainerr: error })
          setPosting(false)
        })
    } else if (attributes?.sender_domain !== domain) {
      setPosting(true)
      updateEmailSettings({ domain, name, emailPrefix })
        .then(response => {
          setSettings(response.data)
          setPosting(false)
          setActiveStep(2)
        })
    }
  }

  function getStepContent (stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <DomainCard
            domain={domain}
            setDomain={setDomain}
            onNext={() => { setActiveStep(1) }}
          />
        )
      case 1:
        return (
          <SenderCard
            domain={domain}
            onBack={() => setActiveStep(0)}
            onSave={handleSave}
            posting={posting}
            settings={attributes}
          />
        )
      case 2:
        return (
          <DNSCard
            onBack={() => setActiveStep(1)}
            settings={props.settings}
            verifySetup={props.verifySetup}
          />
        )
      default:
        return 'Unknown stepIndex'
    }
  }

  return (
    <div>
      <div className={classes.stepperDiv}>
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          className={classes.stepper}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>
      <div className={classes.contents}>
        {getStepContent(activeStep)}
      </div>
    </div>
  )
}

export { CopyBox, EmailSetup }
