import { useState, useRef, useContext, useEffect } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { JsPlumbToolkitSurfaceComponent } from 'jsplumbtoolkit-react'
import ReactDOM from 'react-dom'
import clsx from 'clsx'
import { jsPlumbUtil } from 'jsplumbtoolkit'
import 'jsplumb/css/jsplumbtoolkit-defaults.css'
import './surface.css'
import { getSequenceTemplates } from 'api/sequences'
import Modal from 'components/Modal'
import { initializeSurface } from './surfaceHelpers'
import { initializeToolkit, getView, renderParams, mouseOverElement } from './flowBuilderHelpers'
import { isNodeConnected, isNodeValid } from '../nodes/nodeHelpers'
import NodePickerPopover from './NodePickerPopover'
import SideControlsBar from './SideControlsBar'
import SelectionMenu from './SelectionMenu'
import BotSaveMenu from './BotSaveMenu'
import { DragDropDrawer, DrawerToggleButton } from './DragDropDrawer'
import { EVENT_TYPE, Emitter } from 'emitter'
import { ShareContext } from 'share-context'

const drawerWidth = 300

const useStyles = makeStyles(theme => ({
  wrap: {
    '& .jtk-surface': {
      overflowY: 'auto',
      height: '100%',
      width: '100%'
    },
    width: '100%',
    marginLeft: 0,
    flexGrow: 1,
    height: '100%',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  wrapShift: {
    '& .jtk-surface': {
      overflowY: 'auto',
      height: '100%',
      width: '100%'
    },
    width: `calc(100% - ${drawerWidth + 2}px)`,
    marginLeft: drawerWidth + 2,
    flexGrow: 1,
    height: '100%',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  editor: {
    display: 'flex',
    width: '100%',
    height: '100%'
  }
}))

function FlowSurface (props) {
  const { flags } = useContext(ShareContext)
  const view = getView(props.toolkitInstanceRef, { flags })
  const containerDivRef = useRef(null)
  const classes = useStyles()
  const dragDropContainerRef = useRef(null)
  const modeRef = useRef(null)
  const [drawerOpen, setDrawerOpen] = useState(true)
  const [nodeData, setNodeData] = useState(null)
  const [visibility, setVisibility] = useState('hidden')
  const [surfaceLoaded, setSurfaceLoaded] = useState(false)
  const [selection, setSelection] = useState(null)
  const [sequences, setSequences] = useState([])
  const [saveAttempts, setSaveAttempts] = useState(0)
  const [problemNodes, setProblemNodes] = useState([])
  const [invalidNodes, setInvalidNodes] = useState([])
  const [modalOpen, setModalOpen] = useState(false)
  const handleNodeConnectionRef = useRef(null)
  const handleNodeRemovalRef = useRef(null)
  const handleNodeUpdatedRef = useRef(null)
  /** @type {React.MutableRefObject<import('jsplumbtoolkit').jsPlumbToolkit>} */
  const toolkitInstanceRef = props.toolkitInstanceRef
  const dataLoaded = props.dataLoaded

  function handleNodeRemoval (node) {
    const problemIDs = problemNodes.map(node => node.id)
    const invalidIDs = invalidNodes.map(node => node.id)
    if (problemIDs.includes(node.id)) {
      let newProblemNodes = [...problemNodes]
      newProblemNodes = newProblemNodes.filter(n => n.id !== node.id)
      setProblemNodes(newProblemNodes)
    }
    if (invalidIDs.includes(node.id)) {
      let newInvalidNodes = [...invalidNodes]
      newInvalidNodes = newInvalidNodes.filter(n => n.id !== node.id)
      setInvalidNodes(newInvalidNodes)
    }
  }

  handleNodeRemovalRef.current = handleNodeRemoval

  const handleNodeConnection = (parent, target, type) => {
    if (parent.id === 'start') {
      Emitter.emit(EVENT_TYPE.SET_FIRST_NODE, {
        firstID: target.id
      })
    }
    if (problemNodes.length && type === 'connect') {
      let newProblemNodes = [...problemNodes]
      let updates = 0
      const problemIDs = problemNodes.map(node => node.id)
      if (problemIDs.includes(parent.id)) {
        if (isNodeConnected(parent)) {
          newProblemNodes = newProblemNodes.filter(node => node.id !== parent.id)
          updates++
        }
      }
      if (problemIDs.includes(target.id)) {
        if (isNodeConnected(target)) {
          newProblemNodes = newProblemNodes.filter(node => node.id !== target.id)
          updates++
        }
      }
      if (updates) {
        setProblemNodes(newProblemNodes)
      }
    } else if (problemNodes.length) {
      const newProblemNodes = [...problemNodes]
      let updates = 0
      const problemIDs = problemNodes.map(node => node.id)
      if (!problemIDs.includes(parent.id)) {
        if (!isNodeConnected(parent)) {
          newProblemNodes.push(parent)
          updates++
        }
      }
      if (!problemIDs.includes(target.id)) {
        if (!isNodeConnected(target)) {
          newProblemNodes.push(target)
          updates++
        }
      }
      if (updates) {
        setProblemNodes(newProblemNodes)
      }
    }
  }

  handleNodeConnectionRef.current = handleNodeConnection

  const handleNodeUpdated = node => {
    const invalidNodeIndex = invalidNodes.indexOf(node)
    if (~invalidNodeIndex) {
      if (isNodeValid(node)) {
        const newInvalidNodes = [...invalidNodes]
        newInvalidNodes.splice(invalidNodeIndex, 1)
        setInvalidNodes(newInvalidNodes)
      }
    }
  }

  handleNodeUpdatedRef.current = handleNodeUpdated

  useEffect(() => {
    if (toolkitInstanceRef.current && dataLoaded) {
      const toolkit = toolkitInstanceRef.current
      toolkit.bind('dataUpdated', function (params) {
        if (!props.isRefreshingRef?.current) {
          props.setCanSave(true)
        }
      })
      toolkit.bind('nodeRemoved', function (params) {
        handleNodeRemovalRef.current(params.node)
      })
      toolkit.bind('nodeUpdated', params => {
        if (!props.isRefreshingRef?.current && params.updates) {
          handleNodeUpdatedRef.current(params.node)
        }
      })
    }
    // eslint-disable-next-line
  }, [dataLoaded])

  if (!toolkitInstanceRef.current) {
    initializeToolkit({ toolkitInstanceRef, handleNodeConnectionRef })
  }

  window.setSelection = (s) => {
    if (s.getNodes().length) {
      setSelection({ ...s })
    } else {
      setSelection(null)
    }
  }

  useEffect(() => {
    getSequenceTemplates()
      .then(response => setSequences(response.data.map(t => ({ ...t.attributes, id: t.id }))))
  }, [])

  useEffect(() => {
    if (props.surfaceRef.current && !surfaceLoaded) {
      initializeSurface(
        props.surfaceRef.current,
        setNodeData,
        toolkitInstanceRef.current,
        () => setSelection(null)
      )
      setSurfaceLoaded(true)
      setTimeout(() => {
        props.surfaceRef.current.setZoom(0.7)
        props.surfaceRef.current.zoomToFitIfNecessary()
        setVisibility('visible')
      }, 500)
    }
  }, [props.surfaceRef, toolkitInstanceRef, surfaceLoaded])

  function checkForSelection () {
    if (modeRef.current === 'select') {
      modeRef.current = null
      const selection = toolkitInstanceRef.current.getSelection()
      const nodeCount = selection.getNodeCount()
      if (nodeCount) {
        setSelection(selection)
      }
    }
  }

  function addNodeFromEdge ({ type, source, left, top, data }) {
    handleClose()
    const nodeData = data || {}
    toolkitInstanceRef.current.addFactoryNode(type, nodeData,
      function (newNode) {
        const id = jsPlumbUtil.uuid()
        const data = {
          id: id,
          type: 'common'
        }
        toolkitInstanceRef.current.addEdge({ source, target: newNode, data })
        props.surfaceRef.current.setPosition(newNode, left, top)
      }
    )
  }

  const handleClose = () => {
    setNodeData(null)
    const el = document.getElementById('popover-anchor-div')
    if (el) {
      el.remove()
    }
  }

  function toggleDrawer () {
    if (drawerOpen) {
      setDrawerOpen(false)
    } else {
      setDrawerOpen(true)
    }
  }

  if (props.preview) {
    if (drawerOpen) {
      setDrawerOpen(false)
    }
    return (
      <div className={classes.editor} id='editor' style={{ position: 'relative' }}>
        <div
          ref={containerDivRef}
          className={clsx(classes.wrap, { [classes.wrapShift]: drawerOpen })}
          id='surface-container'
          style={{ visibility: props.hidden ? 'hidden' : visibility }}
        >
          <JsPlumbToolkitSurfaceComponent
            renderParams={renderParams}
            toolkit={toolkitInstanceRef.current}
            view={view}
            ref={(c) => { if (c) { props.surfaceRef.current = c.surface } }}
          />
        </div>
      </div>
    )
  }

  function getIssueNodes () {
    const nodes = toolkitInstanceRef.current.getNodes()
    const unconnectedNodes = []
    const invalidNodes = []
    for (const node of nodes) {
      if (node.id === 'start') {
        continue
      } else if (node.id === 'output') {
        continue
      }

      if (!isNodeValid(node)) {
        invalidNodes.push(node)
      }

      const parents = node.getTargetEdges().length
      if (!parents) {
        mouseOverElement(node.id)
        unconnectedNodes.push(node)
        continue
      }
      const ports = node.getPorts()
      for (const port of ports) {
        const edges = port.getEdges()
        if (port.id === 'again') { continue }
        if (!edges.length && ports.length > 1) {
          mouseOverElement(node.id)
          unconnectedNodes.push(node)
          break
        } else if (!edges.length) {
          mouseOverElement(node.id)
          unconnectedNodes.push(node)
        }
      }
    }
    return { unconnectedNodes, invalidNodes }
  }

  function saveBot (values) {
    const { unconnectedNodes, invalidNodes } = getIssueNodes()

    if (!unconnectedNodes.length && !invalidNodes.length) {
      props.save(values)
    } else {
      ReactDOM.unstable_batchedUpdates(() => {
        setModalOpen(values)
        setProblemNodes(unconnectedNodes)
        setInvalidNodes(invalidNodes)
      })
    }
  }

  if (props.saveRef) {
    props.saveRef.current = saveBot
  }

  const showSequence = !props.sequence

  const id = props.sequence ? 'surface-container-sequence' : 'surface-container'

  return (
    <>
      <NodePickerPopover
        nodeData={nodeData}
        onClose={handleClose}
        addNodeFromEdge={addNodeFromEdge}
        sequences={sequences}
        showSequence={showSequence}
      />
      <div className={classes.editor} id='editor' style={{ position: 'relative' }}>
        <DragDropDrawer
          surface={props.surfaceRef.current}
          dragDropContainerRef={dragDropContainerRef}
          flags={flags}
          open={drawerOpen}
          showSequence={showSequence}
        />
        <div
          ref={containerDivRef}
          className={clsx(classes.wrap, { [classes.wrapShift]: drawerOpen })}
          id={id}
          style={{ visibility: props.hidden ? 'hidden' : visibility, position: 'relative' }}
        >
          <SelectionMenu
            selection={selection}
            toolkit={toolkitInstanceRef.current}
            clearSelection={() => setSelection(null)}
            undoRedo={props.surfaceRef.current?.undoManager}
            setSelection={setSelection}
          />
          <BotSaveMenu
            problemNodes={problemNodes}
            invalidNodes={invalidNodes}
            surface={props.surfaceRef.current}
            saveAttempts={saveAttempts}
          />
          <JsPlumbToolkitSurfaceComponent
            renderParams={renderParams}
            toolkit={toolkitInstanceRef.current}
            view={view}
            ref={(c) => {
              if (c) {
                props.surfaceRef.current = c.surface
              }
            }}
          />
          <DrawerToggleButton
            toggleDrawer={toggleDrawer}
            drawerOpen={drawerOpen}
          />
        </div>
        <SideControlsBar
          surface={props.surfaceRef.current}
          toolkit={toolkitInstanceRef.current}
          showSequence={showSequence}
          setFullScreen={props.setFullScreen}
          fullScreen={props.fullScreen}
          modeRef={modeRef}
          checkForSelection={checkForSelection}
          setSelection={setSelection}
          sequence={props.sequence}
        />
        <NodeIssuesModal
          onSubmit={() => {
            window.showHighlights = false
            props.save(modalOpen)
            setModalOpen(false)
          }}
          saveText='Save Anyway'
          open={modalOpen !== false}
          onHide={() => setModalOpen(false)}
          onBack={() => {
            window.showHighlights = true
            const issueNodes = [...problemNodes, ...invalidNodes]
            for (const node of issueNodes) {
              mouseOverElement(node.id)
            }
            setSaveAttempts(saveAttempts + 1)
            setModalOpen(false)
            props.surfaceRef.current.centerOn(issueNodes[0])
          }}
          invalidNodes={invalidNodes.length}
          unconnectedNodes={problemNodes.length}
          backText={
            (problemNodes.length + invalidNodes.length) > 1 ? 'View Skills' : 'View Skill'
          }
        />
      </div>
    </>
  )
}

const NodeIssuesModal = withStyles(theme => ({
  unconnected: {
    color: '#FD5E5C',
    fontWeight: 'bold',
    padding: '.5rem 0'
  },
  invalid: {
    color: '#FC8724',
    fontWeight: 'bold',
    padding: '.5rem 0'
  },
  errorCtn: {
    margin: '.75rem 0'
  }
}))(
  function ({ classes, ...props }) {
    const combinedNum = props.unconnectedNodes + props.invalidNodes
    return (
      <Modal
        title='Warning'
        onHide={props.onHide}
        open={props.open}
        saveButtonText={props.saveText}
        showBack
        onBack={props.onBack}
        backText={props.backText}
        onSubmit={props.onSubmit}
        size='xs'
      >
        <div>Your bot flow has {combinedNum} skill{combinedNum > 1 ? 's' : ''} with the following errors:</div>
        <div className={classes.errorCtn}>
          {props.unconnectedNodes ? <div className={classes.unconnected}>{props.unconnectedNodes} unconnected skill{props.unconnectedNodes > 1 ? 's' : ''}</div> : <></>}
          {props.invalidNodes ? <div className={classes.invalid}>{props.invalidNodes} skill{props.invalidNodes > 1 ? 's' : ''} with incomplete settings</div> : <></>}
        </div>
        <div>Please update each skill so the bot behaves as expected</div>
      </Modal>
    )
  }
)

export default FlowSurface
