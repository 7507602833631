import { makeStyles } from '@material-ui/core/styles'
import { TextBox } from 'cf-components/material-wrappers/MaterialComponents'
import { RadioButtons } from 'library/materialUI'

const useStyles = makeStyles(theme => ({
  popupContainer: {
    width: '313px'
  },
  innerContainer: {
    margin: '20px'
  }
}))

interface NumericComparatorProps {
  value: number
  setValue: (value: number) => void
  operator: string
  setOperator: (value: string) => void
  limit?: number
}

export default function NumericComparator ({ value, setValue, operator, setOperator, limit }: NumericComparatorProps): JSX.Element {
  const classes = useStyles()
  const numericComparisons = [
    { value: 'gt', label: '> greater than' },
    { value: 'gte', label: '>= greater than or equal to' },
    { value: 'lt', label: '< less than' },
    { value: 'lte', label: '<= less than or equal to' },
    { value: 'eq', label: '= is equal to' },
    { value: 'neq', label: '!= is not equal to' }
  ]

  return (
    <div>
      <div className={classes.innerContainer}>
        <RadioButtons
          buttons={numericComparisons}
          handleChange={(value: string) => setOperator(value)}
          value={operator}
        />
      </div>
      <div style={{ margin: '10px' }}>
        <TextBox
          type='number'
          value={value?.toString()}
          onChange={(v: number) => {
            if (v < 0) {
              setValue(0)
              return
            } else if (limit && v > limit) {
              return
            }
            setValue(v)
          }}
        />
      </div>
    </div>
  )
}
