import { makeStyles } from '@material-ui/core/styles'
import { TextBox, Typography } from 'library/materialUI'
import RevenueSlider from 'pages/plays/playBuilder/branching/selectors/RevenueSlider'
import SliderSelector from 'pages/plays/playBuilder/branching/selectors/SliderSelector'
import { useEffect, useState } from 'react'

const useStyles = makeStyles(theme => ({
  main: {
    width: '100%',
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: '10px',
    backgroundColor: 'white'
  },
  revenue: {
    width: '100%',
    height: '100%'
  },
  typography: {
    paddingLeft: 10,
    paddingRight: 10
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: '12px',
    marginRight: '12px',
    fontSize: '15px',
    alignItems: 'center'
  }
}))

interface RangeSelectProps {
  fieldType: string
  setSelected: (values: string[]) => void
  selected: string[]
}

interface FilterRange {
  min: string
  max: string
}

/**
 * Selected should never exceed a length of 2 for range filters
 */
export default function RangeSelect ({ fieldType, setSelected, selected }: RangeSelectProps) {
  const classes = useStyles()
  const capped = fieldType === 'engagement_score' ? !!fieldType : false
  const [range, setRange] = useState<FilterRange>({ min: selected[0] || '', max: selected[1] || '' })

  useEffect(() => {
    if (range.min && range.max) {
      if (capped) {
        if (parseInt(range.max) > 100) {
          setRange({ ...range, max: '100' })
        }
      }
      if (parseInt(range.min) < 0) {
        setRange({ ...range, min: '0' })
      }
      setSelected([range.min, range.max])
    }
    // eslint-disable-next-line
  }, [range])

  useEffect(() => {
    if (!selected.length && !['annual_revenue', 'number_of_employees'].includes(fieldType)) setRange({ min: '', max: '' })
    // eslint-disable-next-line
  }, [selected])

  return (
    <>
      {fieldType === 'annual_revenue' ? (
        <div className={classes.revenue}>
          <RevenueSlider
            currentSelection={selected}
            setSelection={(values: number[]) => setSelected([values[0].toString(), values[1].toString()])}
            noLabels
            allowZero
          />
        </div>
      ) : (
        fieldType === 'number_of_employees' ? (
          <SliderSelector
            currentSelection={selected}
            setSelection={(values: number[]) => setSelected([values[0].toString(), values[1].toString()])}
            noLabels
            allowZero
          />
        ) : (
          <div className={classes.main}>
            <div className={classes.row}>
              <div>
                <TextBox
                  value={range.min}
                  onChange={(value: string) => setRange({ ...range, min: value })}
                />
              </div>
              <Typography className={classes.typography}>to</Typography>
              <div>
                <TextBox
                  value={range.max}
                  onChange={(value: string) => setRange({ ...range, max: value })}
                />
              </div>
            </div>
          </div>
        ))}
    </>
  )
}
