import { useReducer, createContext, useContext } from 'react'
import { SessionContext } from 'session-context'
import MobileDetect from 'mobile-detect'

const ConversationParametersContext = createContext({})

function reducer (state, action) {
  switch (action.type) {
    case 'getMore':
      return { ...state, limit: state.limit + 30 }
    case 'setConversationFilter':
      return {
        ...state,
        conversationFilters: action.value
      }
    default:
      break
  }
}

const filterNames = {
  my_open_conversations: 'My open conversations',
  my_conversations: 'My conversations',
  open_conversations: 'All open conversations',
  all_conversations: 'All conversations'
}

function ConversationParametersProvider (props) {
  const { user } = useContext(SessionContext)
  const md = new MobileDetect(window.navigator.userAgent)
  const mobile = !!md.phone()

  let conversationFilter = window.localStorage.getItem('searchFilter')
  if (!filterNames[conversationFilter]) {
    conversationFilter = (user.attributes.role === 1) ? 'all_conversations' : 'my_open_conversations'
  }
  if (mobile) {
    conversationFilter = 'all_conversations'
  }
  const initialState = {
    page: 1,
    limit: 30,
    conversationFilters: {
      kind: 'default',
      values: { filter: conversationFilter, displayName: filterNames[conversationFilter] }
    }
  }
  const [state, dispatch] = useReducer(reducer, initialState)

  const setFilter = (value, kind, displayName) => {
    dispatch({ type: 'setConversationFilter', value: { kind: kind, values: { filter: value, displayName: displayName } } })
    if (kind === 'default') {
      window.localStorage.setItem('searchFilter', value)
    }
  }
  const getMore = () => {
    dispatch({ type: 'getMore' })
  }

  const parameters = {
    state: state,
    setFilter: setFilter,
    getMore: getMore
  }

  return (
    <ConversationParametersContext.Provider value={{ parameters }}>
      {props.children}
    </ConversationParametersContext.Provider>
  )
}

export { ConversationParametersContext, ConversationParametersProvider }
