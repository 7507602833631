import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TrackingEvent from 'components/TrackingEvent'
import CustomExpansionPanel from 'components/CustomExpansionPanel'
import dateFormat from 'dateformat'
import EmptyState from 'components/EmptyState'

const useStyles = makeStyles(theme => ({
  activityTab: {
    paddingTop: 0,
    height: '100%'
  },
  activityHeader: {
    padding: 10
  },
  activityBody: {
    height: 'calc(100% - 80px)',
    overflowY: 'auto'
  },
  tableCell: {
    cursor: 'pointer',
    padding: 10
  },
  control: {
    padding: 10,
    minWidth: 140
  },
  filterSelect: {
    color: '#4A4A4A',
    fontWeight: 500,
    fontSize: '16px'
  },
  controlsDiv: {
    display: 'grid',
    gridTemplateColumns: '3fr 2fr'
  },
  icon: {
    paddingRight: 8
  },
  noteContent: {
    fontFamily: 'Poppins, sans serif',
    fontSize: '14px',
    padding: '5px 10px'
  },
  noteIcons: {
    color: '#8E5AE2',
    height: 18,
    width: 18
  },
  iconButton: {
    marginRight: 5
  }
}))

function ContactActivity (props) {
  const classes = useStyles()

  function handleEventClick (event) {
    if (event.attributes.kind === 'form_submission') {
      props.setSubmissionID(event.attributes.id)
      props.setSubmissionModalOpen(true)
    }
    if (event.attributes.kind === 'page') {
      window.open(event.attributes.url.split('?')[0])
    }
    if (event.attributes.kind === 'contact_created') {
      props.openDetailsTab()
    } else if (event.attributes.kind === 'conversation_started') {
      window.open('/#/chat/' + event.id)
    }
  }

  const noteEvents = props.notes.map(note => {
    return ({
      type: 'participant_events',
      id: note.id,
      attributes: {
        created_timestamp: note.attributes.created_timestamp,
        kind: 'note',
        content: note.attributes.content,
        created_by_user_id: note.relationships.created_by.data.id
      }
    })
  })

  let filteredEvents = props.trackingEvents.concat(noteEvents.filter(note => note.attributes.content !== '')).sort(
    (a, b) =>
      a.attributes.created_timestamp < b.attributes.created_timestamp
  )

  let emptyMessage = 'No activity history...'
  if (props.filter === 'pages') {
    filteredEvents = filteredEvents.filter(evt => evt.attributes.kind === 'page')
    emptyMessage = 'No page views to display...'
  } else if (props.filter === 'notes') {
    filteredEvents = filteredEvents.filter(evt => evt.attributes.kind === 'note')
    emptyMessage = 'No notes to display...'
  }

  const empty = filteredEvents.length === 0
  const groups = []

  if (!empty) {
    groups[0] = []
    let y = 0
    let lastDate = new Date(filteredEvents[0].attributes.created_timestamp)

    for (const evt of filteredEvents) {
      const date = new Date(evt.attributes.created_timestamp)
      if (date.getMonth() !== lastDate.getMonth() || date.getFullYear() !== lastDate.getFullYear()) {
        y = y + 1
        groups[y] = []
      }
      groups[y].push(evt)
      lastDate = date
    }
  }

  return (
    <>
      <div className={classes.activityTab}>
        <div className={classes.activityBody}>
          {empty
            ? (<EmptyState message={emptyMessage} />)
            : (
              <>
                {groups.map(monthGroup => {
                  const date = new Date(monthGroup[0].attributes.created_timestamp)
                  const displayDate = dateFormat(date, 'mmmm yyyy')
                  return (
                    <CustomExpansionPanel key={date} title={'Activity History - ' + displayDate}>
                      <Table className={classes.table} size='small'>
                        <TableBody>
                          {monthGroup.map(event => {
                            const kind = event.attributes.kind
                            return (
                              <TableRow key={`${event.id}_${kind}`}>
                                <TableCell cursor='pointer'>
                                  <div className={classes.tableCell} onClick={() => handleEventClick(event)}>
                                    <TrackingEvent event={event} />
                                  </div>
                                </TableCell>
                              </TableRow>
                            )
                          })}
                        </TableBody>
                      </Table>
                    </CustomExpansionPanel>
                  )
                })}
              </>)}
        </div>
      </div>
    </>
  )
}

export default ContactActivity
