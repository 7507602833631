import React, { useEffect, useRef } from 'react'
import MobileMessageGroup from './MobileMessageGroup'
import { groupEvents } from 'pages/chat/agent-chat/groupEvents'
import Divider from '@material-ui/core/Divider'
import { MessageStyles as useStyles } from '../MobileChatStyles'
import dateFormat from 'dateformat'
import ConversationMeta from 'pages/chat/agent-chat/ConversationMeta'
import CalendarDrop from '../../agent-chat/CalendarDrop'

function MobileDateDivider (props) {
  const classes = useStyles()
  const date = dateFormat(props.date, 'dddd, mmmm dd')

  return (
    <div className={classes.dateContainer}>
      <div className={classes.dividerContainer}>
        <div className={classes.dividerDiv}>
          <Divider />
        </div>
      </div>
      <div className={classes.dateDiv}>
        {date}
      </div>
      <div className={classes.dividerContainer}>
        <div className={classes.dividerDiv}>
          <Divider />
        </div>
      </div>
    </div>
  )
}

function MessageGroupDisplay (props) {
  const group = props.group
  if (group.type === 'dateDivider') {
    return (
      <MobileDateDivider
        date={group.date}
      />
    )
  }
  if (group.type === 'events' && group.eventGroup === 'messages') {
    return (
      <MobileMessageGroup
        state={props.state}
        messages={group.events.messages}
      />
    )
  }
  if (group.type === 'events' && group.eventGroup === 'meta') {
    return (
      <ConversationMeta
        group={group}
      />
    )
  }
  if (group.type === 'events' && group.eventGroup === 'calendar') {
    return (
      <CalendarDrop
        group={group}
        state={props.state}
      />
    )
  }

  return (
    <></>
  )
}

function scrollToBottom (el) {
  el.scrollTop = el.scrollHeight - el.clientHeight
}

function MobileMessageDisplay (props) {
  const classes = useStyles()
  const state = props.state
  const events = state.events
  const conversation = props.conversation
  const { groups, displayEvents } = groupEvents({ events })
  const displayRef = useRef(null)

  useEffect(() => {
    setTimeout(() => {
      scrollToBottom(displayRef.current)
    }, 10)
  }, [displayEvents.length])

  return (
    <div className={classes.messages}>
      <div
        className={classes.messageDisplay}
        ref={displayRef}
      >
        {groups.map((group, index) => (
          <MessageGroupDisplay
            key={index}
            state={{ ...props.state, conversation: conversation }}
            group={group}
          />
        ))}
      </div>
    </div>
  )
}

export default MobileMessageDisplay
