import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import Avatar from '@material-ui/core/Avatar'
import Divider from '@material-ui/core/Divider'
import SequenceStatistics from './SequenceStatistics'
import dateFormat from 'dateformat'
import { getInitials } from 'cf-components/Avatars'
import { Modal, ModalSection } from 'cf-components/Modal'
import SaveIcon from '@material-ui/icons/Save'
import EditIcon from '@material-ui/icons/Edit'
import TextField from '@material-ui/core/TextField'

const useStyles = makeStyles(theme => ({
  advancedPage: {
    padding: 20,
    display: 'grid',
    gridTemplateColumns: '400px auto',
    gridGap: 20
  },
  colorBanner: {
    height: 95,
    backgroundColor: 'rgba(151, 92, 230)',
    left: 0,
    top: 0,
    right: 0,
    position: 'absolute'
  },
  sequenceOverview: {
    padding: 30,
    position: 'relative',
    border: '1px solid rgba(151, 92, 230, .5)'
  },
  sequenceDescription: {
    padding: 15,
    border: '1px solid rgba(151, 92, 230, .5)'
  },
  avatarImage: {
    height: 120,
    width: 120,
    borderRadius: 100,
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  avatar: {
    fontSize: '64px',
    border: '1px solid #8e5ae2'
  },
  avatarColors: {
    backgroundColor: 'white',
    color: '#8e5ae2'
  },
  sequenceName: {
    marginTop: 20,
    fontSize: '1.5em',
    textAlign: 'center',
    marginBottom: 20,
    display: 'flex',
    justifyContent: 'center'
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  titleText: {
    fontSize: 20,
    marginBottom: 5
  },
  infoHeader: {
    color: '#777',
    marginTop: 20
  },
  infoBody: {
    marginTop: 3,
    color: '#333'
  },
  botSection: {
    marginTop: 20
  }
}))

function SequenceOverviewCard (props) {
  const classes = useStyles()

  if (!(props.sequence && props.overview && props.bots)) {
    return <></>
  }

  const sequence = props.sequence.attributes
  const overview = props.overview.attributes
  const lastFired = overview.last_fired ? dateFormat(overview.lastFired, 'mm/dd/yyyy') : 'Never Fired'
  const sequenceCreated = dateFormat(sequence.created_timestamp, 'mm/dd/yyyy')
  const initials = getInitials(sequence.name)

  let mostFrequentBot = 'None'
  if (overview.most_frequent_bot_id) {
    const bots = props.bots.filter(bot => bot.id === overview.most_frequent_bot_id)
    if (bots.length) {
      mostFrequentBot = bots[0].attributes.name
    }
  }

  return (
    <Card className={classes.sequenceOverview}>
      <div className={classes.colorBanner} />
      <div style={{ position: 'relative', display: 'flex', justifyContent: 'center' }}>
        <div className={classes.companyImage}>
          <Avatar
            variant='rounded'
            style={{ height: 118, width: 118 }}
            classes={{ colorDefault: classes.avatarColors, rounded: classes.avatar }}
          >
            {initials}
          </Avatar>
        </div>
      </div>
      <div className={classes.sequenceName}>
        {sequence.name}
      </div>
      <Divider />
      <div>
        <div className={classes.infoHeader}>Sequence Created</div>
        <div className={classes.infoBody}>{sequenceCreated}</div>
      </div>
      <div style={{ cursor: 'pointer' }} onClick={props.handleEditDescription}>
        <div className={classes.infoHeader} style={{ display: 'flex' }}>
          Description
          <EditIcon style={{ height: 18, width: 18, marginLeft: 10 }} />
        </div>
        <div className={classes.infoBody}>{sequence.description}</div>
      </div>
      <div>
        <div className={classes.infoHeader}>Number of Bots</div>
        <div className={classes.infoBody}>{props.bots.length}</div>
      </div>
      <div>
        <div className={classes.infoHeader}>Total Fires All Time</div>
        <div className={classes.infoBody}>{overview.sequence_fires_all_time}</div>
      </div>
      <div>
        <div className={classes.infoHeader}>Fires This Week</div>
        <div className={classes.infoBody}>{overview.sequence_fires_this_week}</div>
      </div>
      <div>
        <div className={classes.infoHeader}>Last Fired Date</div>
        <div className={classes.infoBody}>{lastFired}</div>
      </div>
      <div>
        <div className={classes.infoHeader}>Most Frequent Bot</div>
        <div className={classes.infoBody}>{mostFrequentBot}</div>
      </div>
    </Card>
  )
}

function SequenceDescriptionModal (props) {
  const [description, setDescription] = useState(props.sequence.attributes.description)

  useEffect(() => {
    if (props.sequence) {
      setDescription(props.sequence.attributes.description)
    }
  }, [props.sequence, props.open])

  const save = () => {
    props.save(description)
    props.onHide()
  }

  return (
    <Modal
      title='Sequence Description'
      onHide={props.onHide}
      open={props.open}
      size='sm'
      rightButtonProps={{
        text: 'Save',
        action: save,
        variant: 'outlined',
        icon: SaveIcon
      }}
      leftButtonProps={{
        text: 'Cancel',
        action: props.onHide,
        variant: 'text'
      }}
    >
      <ModalSection
        title='Edit Description'
      >
        <div style={{ marginTop: 10 }}>
          <TextField
            variant='outlined'
            multiline
            rows={3}
            value={description}
            onChange={(event) => setDescription(event.target.value)}
            margin='dense'
            fullWidth
            style={{ backgroundColor: 'white' }}
          />
        </div>
      </ModalSection>
    </Modal>
  )
}

function SequenceAdvancedPage (props) {
  const classes = useStyles()
  const [modalOpen, setModalOpen] = useState(false)

  return (
    <div className={classes.advancedPage}>
      <SequenceOverviewCard
        bots={props.bots}
        overview={props.overview}
        sequence={props.sequence}
        handleEditDescription={() => setModalOpen(true)}
      />
      <div>
        {props.bots &&
          <SequenceStatistics
            bots={props.bots}
            sequenceID={props.sequenceID}
          />}
      </div>
      {props.sequence &&
        <SequenceDescriptionModal
          open={modalOpen}
          onHide={() => setModalOpen(false)}
          sequence={props.sequence}
          save={props.saveDescription}
        />}
    </div>
  )
}

export default SequenceAdvancedPage
