import { queryClient } from 'App'
import { ICP, ICPFilters, initialICPFilters } from 'classes/icp'
import { createContext, Dispatch, MutableRefObject, ReactNode, SetStateAction, useEffect, useReducer, useCallback, useState } from 'react'
import { icpFiltersToSegments, singleIcpReducer, useSimpleReducer, validICPFilters } from './accountICPHelpers'

export const initialStateICPSetup: {
  hasCRM: boolean
  skipCRM: boolean
  includeClosedLost: boolean
  customerAccountTypes: string[]
  propertyType: string
} = {
  hasCRM: false,
  skipCRM: false,
  includeClosedLost: false,
  customerAccountTypes: [],
  propertyType: 'type'
}
interface ICPValues {
  isLoading: boolean
  isError: boolean
  error?: Error
  icp: ICP
  icpFilters: ICPFilters<'formatted'>
  icpFiltersDispatch: Dispatch<Partial<ICPFilters<'formatted'>>>
  localICP: ICP
  dirty: boolean
  activeStep: number
  setActiveStep: Dispatch<SetStateAction<number>>
  infoModalOpen?: boolean
  setInfoModalOpen: Dispatch<SetStateAction<boolean>>
  setLocalICP: Dispatch<Partial<ICP>>
  saveICP: () => void
  resetICP: () => void
  icpAccountSettingsDispatch: Dispatch<Partial<typeof initialStateICPSetup>>
  icpAccountSettings: typeof initialStateICPSetup
}
export const ICPContext = createContext<ICPValues>({
  activeStep: 0,
  setActiveStep: () => undefined,
  dirty: false,
  icp: new ICP({}),
  icpFilters: initialICPFilters,
  icpFiltersDispatch: () => ({}),
  isLoading: true,
  error: undefined,
  isError: false,
  localICP: new ICP({}),
  setLocalICP: () => ({}),
  infoModalOpen: false,
  setInfoModalOpen: () => undefined,
  saveICP: () => undefined,
  resetICP: () => undefined,
  icpAccountSettings: initialStateICPSetup,
  icpAccountSettingsDispatch: () => ({})
})

interface ICPProviderProps {
  children: ReactNode
  setDisabled: Dispatch<boolean>
  saveICPRef: MutableRefObject<Function | null>
  resetICPRef: MutableRefObject<Function | null>
}

const reducer = (state: ICP, changes: Partial<ICP>) => {
  return { ...state, ...changes } as ICP
}

export function ICPProvider ({ children, setDisabled, saveICPRef, resetICPRef }: ICPProviderProps): JSX.Element {
  const { data: icp, isLoading, error, isError } = ICP.get()
  const [localICP, setLocalICP] = useReducer(reducer, new ICP({}))
  const [icpAccountSettings, icpAccountSettingsDispatch] = useSimpleReducer(initialStateICPSetup)
  const [icpFilters, icpFiltersDispatch] = useReducer(singleIcpReducer, initialICPFilters)
  const [infoModalOpen, setInfoModalOpen] = useState(false)
  const [activeStep, setActiveStep] = useState(0)
  const [dirty, setDirty] = useState(false)

  const compareRangeFilter = useCallback((field: 'annualRevenue' | 'employeeCount'): boolean => {
    let filterMatch = true
    const savedFilter = icp.filters[0][field] || { gt: 0, lt: initialICPFilters[field].lt }
    const localFilter = icpFilters[field] || { gt: 0, lt: initialICPFilters[field].lt }
    if (savedFilter.gt !== localFilter.gt || savedFilter.lt !== localFilter.lt) {
      filterMatch = false
    }
    return filterMatch
  }, [icp, icpFilters])

  const compareArrayFilter = useCallback((field: 'industry' | 'state' | 'country' | 'technologies'): boolean => {
    let filterMatch = true
    const savedValues = icp.filters[0][field] || []
    const localValues = icpFilters[field] || []
    const filtered1 = savedValues.filter(element => localValues.includes(element));
    const filtered2 = localValues.filter(element => savedValues.includes(element));
    if (filtered1.length !== savedValues.length || filtered2.length !== localValues.length) {
      filterMatch = false
    }
    return filterMatch
  }, [icp, icpFilters])

  useEffect(() => {
    if (!isLoading) {
      setLocalICP(icp)
      if (icp && icp.filters.length > 0) {
        icpFiltersDispatch(icp.filters[0])
      }
    }
  }, [isLoading, icp])
  useEffect(() => {
    let filterMatch = true
    let icpMatch = true
    if (icp) {
      if (icp.requiredFields.industry !== localICP.requiredFields.industry ||
        icp.requiredFields.location !== localICP.requiredFields.location ||
        icp.requiredFields.annual_revenue !== localICP.requiredFields.annual_revenue ||
        icp.requiredFields.employee_count !== localICP.requiredFields.employee_count ||
        icp.requiredFields.technologies !== localICP.requiredFields.technologies
      ) {
        icpMatch = false
      } else if (icp.requiredMatchCount !== localICP.requiredMatchCount) {
        icpMatch = false
      }
      if (icp.filters && icp.filters.length > 0) {
        Object.keys(initialICPFilters).forEach((field) => {
          switch (field) {
            case 'rawData':
              break
            case 'annualRevenue':
            case 'employeeCount':
              filterMatch = filterMatch && compareRangeFilter(field)
              break
            case 'industry':
            case 'state':
            case 'country':
            case 'technologies':
              filterMatch = filterMatch && compareArrayFilter(field)
              break
          }
        })
      }
      setActiveStep(2)
    } else {
      if (validICPFilters(icpFilters)) {
        filterMatch = false
      }
    }
    setDisabled(filterMatch && icpMatch)
    setDirty(!filterMatch || !icpMatch)
  }, [icpFilters, icp, setDisabled, localICP, compareArrayFilter, compareRangeFilter])

  const resetICP = (): void => {
    if (icp) {
      setLocalICP(icp)
    } else {
      setLocalICP(new ICP({}))
    }
    if (icp && icp.filters.length > 0) {
      icpFiltersDispatch(icp.filters[0])
    } else {
      icpFiltersDispatch(initialICPFilters)
    }
  }

  const saveICP = (): void => {
    const segments = icpFiltersToSegments(icpFilters)
    const icp = localICP
    icp.filters = [icpFilters]

    ICP.upsert({
      segments,
      accountTypes: icpAccountSettings,
      icp
    }).then(() => {
      setDisabled(true)
      setInfoModalOpen(true)
    })
  }
  if (!isLoading && !isError && icp && icp.filters.length === 0) {
    icp.filters = [initialICPFilters]
  }

  saveICPRef.current = saveICP
  resetICPRef.current = () => queryClient.invalidateQueries('icp')

  return (
    <ICPContext.Provider
      value={{
        icp,
        icpFilters,
        icpFiltersDispatch,
        isLoading,
        error,
        isError,
        dirty,
        activeStep,
        setActiveStep,
        localICP,
        infoModalOpen,
        setInfoModalOpen,
        setLocalICP,
        saveICP,
        resetICP,
        icpAccountSettingsDispatch,
        icpAccountSettings
      }}
    >
      {children}
    </ICPContext.Provider>
  )
}
