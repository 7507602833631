import { nodeMappingData } from 'library/flowBuilder/FlowBuilder'
import { ModalContentsProps } from 'library/flowBuilder/nodes/NodeModal'
import { categoryMapping } from '../nodeCategories'
import { branchingReducer } from '../../branching/reducer'
import { BranchingModal, BranchingNodeSchema } from '../../branching/BranchingModal'

function GeographyBranchingModal ({ state, dispatch }: ModalContentsProps): JSX.Element {
  return (
    <BranchingModal
      state={state}
      dispatch={dispatch}
      branchType='geography'
      subtitle='Use these filter criteria to create branching visitor experiences. A visitor must match all selected criteria in order to follow a branch.'
    />
  )
}

function BranchingBody (): JSX.Element {
  return (
    <div>
      Branch on visitor location
    </div>
  )
}

export const geographyBranchingMapping: nodeMappingData = {
  icon: 'world',
  title: 'Visitor Location',
  body: BranchingBody,
  modal: GeographyBranchingModal,
  modalReducer: branchingReducer,
  modalSize: 'md',
  schema: BranchingNodeSchema,
  helpCenterLink: 'home/plays/skills/GeographyBranching',
  ...categoryMapping.branching
}
