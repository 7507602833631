import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Form } from 'formik'
import { AttributeSelector as AccountInput } from 'components/AttributeFields'
import { account_types, revenue_values, employee_counts, industries } from './definedFields'

const useStyles = makeStyles(theme => ({
  infoDiv: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr'
  },
  subHeading: {
    color: '#BFA2F0',
    marginBottom: 15
  },
  form: {
    maxHeight: '50vh'
  }
}))

function EditAccountForm (props) {
  const classes = useStyles()

  return (
    <Form className={classes.form}>
      <div className={classes.subHeading}>
        CONTACT INFORMATION
      </div>
      <div className={classes.infoDiv}>
        <AccountInput name='name' label='Company Name' type='text' />
        <AccountInput name='website' label='Website' type='text' />
        <AccountInput name='phone' label='Phone' type='phone' />
        <AccountInput name='fax' label='Fax' type='phone' />
        <AccountInput name='address_street' label='Street Address' type='text' />
        <AccountInput name='address_city' label='City' type='text' />
        <AccountInput name='address_state' label='State' type='text' />
        <AccountInput name='address_postal_code' label='Zip' type='text' />
      </div>
      <div className={classes.subHeading}>
        ACCOUNT INFORMATION
      </div>
      <div className={classes.infoDiv}>
        <AccountInput name='account_type' label='Account Type' type='dropdown' values={account_types} />
        <AccountInput name='industry' label='Industry' type='dropdown' values={industries} />
        <AccountInput name='employee_count' label='Employee Count' type='dropdown' values={employee_counts} />
        <AccountInput name='annual_revenue' label='Annual Revenue' type='dropdown' values={revenue_values} />
      </div>
      <div className={classes.description}>
        <AccountInput name='description' label='Description' type='description' />
      </div>
    </Form>
  )
}

export default EditAccountForm
