import { makeStyles } from '@material-ui/core/styles'
import EmptyBox from 'img/EmptyBox.svg'

const useStyles = makeStyles(theme => ({
  emptyState: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '80%',
    color: '#C6ADF1',
    fontFamily: 'poppins, sans serif'
  },
  svg: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 5
  }
}))

function EmptyState (props) {
  const classes = useStyles()

  return (
    <div className={classes.emptyState}>
      <div>
        <div className={classes.svg}>
          <img src={EmptyBox} alt='Empty state' />
        </div>
        <div>
          {props.message}
        </div>
      </div>
    </div>
  )
}

export default EmptyState
