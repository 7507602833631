/* eslint-disable @typescript-eslint/naming-convention */
import { ObjectAvatar } from 'cf-components/table/TableAvatars'
import { User } from 'classes/users'
import Loader from 'library/loading/Loader'
import { Typography } from 'library/materialUI'
import { CompanyRow, ownerObj } from './CSVUploadModal'

export type CompanyRowWithPreviousOwner = Omit<CompanyRow, 'previous_owner'> & { previous_owner: ownerObj }

interface DisplayAccountOwnersProps {
  row: CompanyRowWithPreviousOwner
  overrides: boolean
}

export default function DisplayAccountOwners ({ row, overrides }: DisplayAccountOwnersProps) {
  const { data: ownerOnCSV, isLoading: ownerIsLoading, isError, error } = User.loadOne(row.owner.id)

  const { data: existingOwner, isLoading: existingIsLoading, isError: exIsError, error: exError } = User.loadOne(row.previous_owner.id)
  if (exIsError && !exError.message.includes('not_found')) {
    return <Typography>Error, could not load existing owner</Typography>
  } else if (isError && !error.message.includes('not_found')) {
    return <Typography>Error, could not load csv owner</Typography>
  }

  if (ownerIsLoading || existingIsLoading) {
    return (
      <Loader type='spinner' />
    )
  }
  const newOwner = overrides ? ownerOnCSV : existingOwner

  return (
    <div style={{ backgroundColor: 'white', borderRadius: 10, boxShadow: '0px 3px 6px #00000029', padding: 10 }}>
      <div>
        <Typography style={{ margin: 5 }}>Owner after submitting</Typography>
        {newOwner ? (
          <ObjectAvatar
            type='User'
            row={newOwner}
            style={{ marginBottom: 10 }}
          />
        ) : <Typography style={{ margin: 5 }}>No Owner</Typography>}
      </div>
      <div>
        <Typography style={{ margin: 5 }}>Previous Owner</Typography>
        {existingOwner ? (
          <ObjectAvatar
            type='User'
            row={existingOwner}
            style={{ marginBottom: 10 }}
          />
        ) : <Typography style={{ margin: 5 }}>No Owner</Typography>}
      </div>
    </div>
  )
}
