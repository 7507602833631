import InfoIcon from '@material-ui/icons/Info'
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip'
import { Typography } from './materialUI'
import theme from 'theme'

interface ImgTextProps {
  message: string
  image?: string
}

function ImgText ({ message, image }: ImgTextProps) {
  return (
    <div>
      <div>
        {message}
      </div>
      {image}
    </div>
  )
}

interface InformationIconProps extends Omit<TooltipProps, 'title' | 'children'> {
  img?: string
  message: string
  color?: string
  placement?: any
  learnMore?: boolean
  link?: string
  inline?: boolean
}

export default function InformationIcon ({ img, message, color, placement, learnMore, link, inline }: InformationIconProps) {
  return (
    <Tooltip title={<ImgText image={img} message={message} />} style={{ width: 1300 }} placement={placement || 'right'}>
      <div
        style={inline ? {
          width: 'fit-content',
          cursor: 'pointer',
          display: 'inline-flex',
          verticalAlign: 'middle'
        } : {
          width: 'fit-content',
          cursor: 'pointer',
          display: 'flex'
        }}
        onClick={link ? () => window.open(link) : () => undefined}
      >
        <InfoIcon fontSize='small' style={{ fill: color || `${theme.palette.primary.main}` }} />
        {learnMore && <Typography style={{ marginLeft: 8 }}>LEARN MORE</Typography>}
      </div>
    </Tooltip>
  )
}
