import { useQuery } from 'react-query';
import { doPatch, doPost } from 'api/api'
import { DateType } from './classHelpers'
import { GenericObject } from './genericObject'
import { LoadAllProps, parameterizeFilters, SearchParams } from './queryHelpers'
import { useDoQuery, handleResponse, useDoInfiniteQuery, infiniteQueryResult } from './useDoQuery'
import { Segment } from './segments';

export interface CompanyList {
  list: Company[]
  dict: { [id: Company['domain']]: Company }
  meta: { total: number, page?: { total?: number } }
}

interface GetSegmentMembershipProps {
  filters: Segment['triggers']
  pageSize?: number
  search?: string
}

type OppStatus = 'Open Opp' | 'No Opp'
export default class Company extends GenericObject {
  domain: string
  industry: string
  website: string
  linkedIn: string
  phone: string
  description: string
  alexaRanking: number
  technologies: Array<string>
  annualRevenue: number
  city: string
  state: string
  country: string
  countryCode: string
  zip: string
  numberOfEmployees: number
  logoUrl: string
  updatedTimestamp: DateType
  recentContacts: number
  opportunityStatus: OppStatus
  engagementScore: number
  snoozedUntilTimestamp: DateType | null
  latitude: number
  longitude: number
  vistors: number
  accountType: string
  twitterURL: string
  facebookURL: string
  totalFunding: number
  lastFundingAmount: number
  lastFundingDate: string

  get link (): string { return `accounts/${this.domain}` }
  get avatarUrl (): string { return this.logoUrl }

  static loadAll (props?: LoadAllProps): { data: CompanyList, isLoading: boolean } {
    const path = '/companies'
    return useDoQuery({
      path,
      useChatURL: true,
      objectClass: Company,
      initialData: { list: [], dict: {} },
      searchParams: props?.searchParams
    })
  }

  static loadAllInfinite (props?: LoadAllProps): infiniteQueryResult {
    const path = '/companies'
    return useDoInfiniteQuery({
      path,
      useChatURL: true,
      objectClass: Company,
      searchParams: props?.searchParams,
      keepPreviousData: true
    })
  }

  static update (domain: Company['domain'], updates: Partial<Company>): Promise<any> {
    const path = `/companies/${domain}`
    const data = {
      attributes: updates,
      type: 'company',
      id: domain
    }
    return doPatch({ path: path, useChatURL: true, data: data })
  }

  static snoozeAccount (company: Record<any, any>): Promise<any> {
    if (!company.snoozedUntilTimestamp) {
      const date = new Date()
      const daysToAdd = 90
      company.snoozedUntilTimestamp = new Date(date.setDate(date.getDate() + daysToAdd))
    } else {
      company.snoozedUntilTimestamp = null
    }
    return Company.update(company.domain, { snoozedUntilTimestamp: company.snoozedUntilTimestamp })
  }

  static get_segment_membership ({ filters, pageSize, search }: GetSegmentMembershipProps): { data: CompanyList, isLoading: boolean } {
    const searchParams: SearchParams = { pageSize: pageSize, search: search, pageNumber: 1 }
    const path = parameterizeFilters({ searchParams, path: '/companies/segment_membership' })
    const objectClass = Company
    const payload = { attributes: filters }

    const queryFunction = (): Promise<any> => {
      return doPost({
        path: path,
        data: payload,
        useChatURL: true,
        skipDataConvert: true
      })
        .then(response => handleResponse(response, objectClass))
        .then(response => {
          return response
        })
    }
    const js: string[] = filters.map(filter => { return (JSON.stringify(filter.filters.filter((f) => f.prop !== ''))) })
    const queryParams = path.split('?')[1]
    const key = ['companies.segment_membership', { filters: js.join() }, queryParams]
    const { data, isFetched, isFetching } = useQuery(key, queryFunction, {
      staleTime: 120000,
      retry: false,
      placeholderData: { list: [], meta: { total: 0 } }
    })

    const isLoading = isFetching && !isFetched
    return { data, isLoading }
  }

  constructor ({ row }: Record<string, any>) {
    super({ row })
    const company: Record<string, any> = row.attributes
    this.domain = company.domain
    this.website = company.website
    this.description = company.description
    this.industry = company.industry
    this.linkedIn = company.linked_in
    this.technologies = company.technologies
    this.phone = company.phone
    this.alexaRanking = company.alexa_ranking
    this.annualRevenue = company.annual_revenue
    this.city = company.city
    this.state = company.state
    this.country = company.country
    this.countryCode = company.country_code
    this.zip = company.zip
    this.numberOfEmployees = company.number_of_employees
    this.logoUrl = company.logo_url
    this.updatedTimestamp = company.updated_timestamp
    this.recentContacts = company.recent_contacts
    this.opportunityStatus = company.opportunity_status
    this.engagementScore = company.engagement_score
    this.snoozedUntilTimestamp = company.snoozed_until_timestamp
    this.latitude = company.latitude
    this.longitude = company.longitude
    this.vistors = company.visitors
    this.accountType = company.account_type
    this.twitterURL = company.twitter_url
    this.facebookURL = company.facebook_url
    this.totalFunding = company.total_funding
    this.lastFundingAmount = company.last_funding_amount
    this.lastFundingDate = company.last_funding_date
  }
}
