import React, { useState } from 'react'
import { makeStyles, createTheme } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/styles'
import DeleteIcon from '@material-ui/icons/Delete'
import { ReactComponent as SequenceIcon } from 'img/PurpleSequenceIcon.svg'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import SelectAllIcon from '@material-ui/icons/SelectAll'
import { canCreateSequence, convertToSequence, unsequence } from '../sequences/sequenceFunctions'
import SequenceConfigModal from '../sequences/SequenceConfigModal'
import UnsequenceIcon from '@material-ui/icons/ScatterPlot'
import { jsPlumbUtil } from 'jsplumbtoolkit'
import { builderTabSelected } from './surfaceHelpers'

const useStyles = makeStyles(theme => ({
  selectionMenu: {
    position: 'absolute',
    width: 'calc(100% - 50px)',
    backgroundColor: 'rgba(151,92,230,0.9)',
    padding: '10px 25px',
    color: 'white',
    zIndex: 10,
    display: 'flex',
    fontWeight: 600,
    fontSize: '1.1em',
    alignItems: 'center'
  },
  buttonDiv: {
    marginLeft: 30
  }
}))

const buttonTheme = createTheme({
  palette: {
    primary: {
      main: '#FFF',
      contrastText: '#8e5ae2'
    }
  }
})

function selectDescendents (node, toolkit, nodeIDs) {
  const ports = node.getPorts()
  for (const port of ports) {
    const edges = port.getSourceEdges()
    for (const edge of edges) {
      const n = edge.target
      if (!nodeIDs.includes(n.id)) {
        nodeIDs.push(n.id)
        toolkit.addToSelection(n)
        selectDescendents(n, toolkit, nodeIDs)
      }
    }
  }
}

function cloneSelection (selection, toolkit) {
  const nodes = selection.getNodes()
  const nodeIDs = nodes.map(n => n.id)
  const newNodes = []
  const nodeMapping = {}
  for (const node of nodes) {
    const data = { ...node.data }
    data.left = data.left + 200
    data.top = data.top + 70
    const newID = jsPlumbUtil.uuid()
    data.id = newID
    const newNode = toolkit.addNode(data)
    nodeMapping[node.id] = newID
    newNodes.push(newNode)
  }
  for (const n of nodes) {
    const ports = n.getPorts()
    for (const port of ports) {
      const edges = port.getSourceEdges()
      for (const edge of edges) {
        const targetNode = edge.target
        const newNode = toolkit.getNode(nodeMapping[n.id])
        const source = newNode.getPort(port.id)
        if (nodeIDs.includes(targetNode.id)) {
          const target = nodeMapping[targetNode.id]
          const data = {
            id: jsPlumbUtil.uuid(),
            type: 'common'
          }
          toolkit.addEdge({ source, target, data })
        }
      }
    }
  }

  toolkit.clearSelection()
  toolkit.setSelection(newNodes)
}

function cannotDelete (nodes) {
  for (const node of nodes) {
    if (node.id === 'start' || node.id === 'output') {
      return true
    }
  }
  return false
}

function SelectionMenu (props) {
  const classes = useStyles()
  const toolkit = props.toolkit
  const [modalOpen, setModalOpen] = useState(false)

  if (!props.selection) {
    return <></>
  }

  const builderTab = builderTabSelected()

  if (!builderTab) {
    return <></>
  }

  const sequenceEligible = canCreateSequence(props.selection)

  const nodeCount = props.selection.getNodeCount()
  let showDescendantsButton = false
  const noDelete = cannotDelete(props.selection.getNodes())

  let message = nodeCount + ' skills selected'
  if (nodeCount === 1) {
    message = '1 node selected'
  }

  let canUnsequence = false

  if (nodeCount === 1) {
    const node = props.selection.getNodes()[0]
    if (node.data.type === 'sequence') {
      canUnsequence = true
    }
    const allEdgesLength = node.getAllEdges().length
    const targetEdgesLength = node.getTargetEdges().length
    if (allEdgesLength > targetEdgesLength) {
      showDescendantsButton = true
    }
  }

  function createSequence ({ title, description, trackTemplate }) {
    convertToSequence({ toolkit, title, description, trackTemplate }).then((res) => {
      if (res === true) {
        props.clearSelection()
        setModalOpen(false)
        window.sequenceModal = false
      }
    })
  }

  return (
    <ThemeProvider theme={buttonTheme}>
      <div
        className={classes.selectionMenu}
      >
        <div style={{ width: 150 }}>
          {message}
        </div>
        <div className={classes.buttonDiv}>
          <Button
            size='small'
            variant='contained'
            color='primary'
            startIcon={<DeleteIcon />}
            onClick={() => {
              toolkit.removeFromSelection(toolkit.getNode('start'))
              toolkit.remove(props.selection)
              props.clearSelection()
            }}
            disabled={noDelete}
          >
            Delete
          </Button>
          {!canUnsequence &&
            <Button
              size='small'
              variant='contained'
              color='primary'
              startIcon={<SequenceIcon style={{ height: 20, width: 20 }} />}
              style={{ marginLeft: 20 }}
              disabled={!sequenceEligible}
              onClick={() => {
                setModalOpen(true)
                window.sequenceModal = true
              }}
            >
              Sequence
            </Button>}
          <Button
            size='small'
            variant='contained'
            color='primary'
            startIcon={<FileCopyIcon />}
            style={{ marginLeft: 20 }}
            onClick={() => {
              toolkit.removeFromSelection(toolkit.getNode('start'))
              const selection = toolkit.getSelection()
              cloneSelection(selection, toolkit)
            }}
            disabled={noDelete}
          >
            Clone
          </Button>
          {showDescendantsButton &&
            <Button
              size='small'
              variant='contained'
              color='primary'
              startIcon={<SelectAllIcon />}
              style={{ marginLeft: 20 }}
              onClick={() => {
                const node = toolkit.getSelection().getNodes()[0]
                selectDescendents(node, toolkit, [node.id])
                const selection = toolkit.getSelection()
                props.setSelection({ ...selection })
              }}
            >
              Select Descendants
            </Button>}
          {canUnsequence &&
            <Button
              size='small'
              variant='contained'
              color='primary'
              startIcon={<UnsequenceIcon />}
              style={{ marginLeft: 20 }}
              onClick={() => {
                unsequence({ toolkit, setSelection: props.setSelection })
              }}
            >
              Unsequence
            </Button>}
        </div>
      </div>
      <SequenceConfigModal
        open={modalOpen}
        onHide={() => {
          setModalOpen(false)
          window.sequenceModal = false
        }}
        title=''
        description=''
        save={createSequence}
        isTemplate={false}
        isNew
      />
    </ThemeProvider>
  )
}

export default SelectionMenu
