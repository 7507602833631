import { makeStyles } from '@material-ui/core/styles'
import Form, { FormAction } from 'classes/forms'
import { Icon, Button } from 'library/materialUI'
import { Divider, TextField, Typography } from '@material-ui/core'
import ContentCheckbox from 'library/ContentCheckbox'
import { RichTextEditor } from 'library/richText/RichTextEditor'
import { RouteLabel } from 'library/routing/RoutingCriteria'
import Route from 'pages/bot/bot-edit/Route'
import { CalendarModal } from 'pages/bot/flow-builder/nodes/CalendarNode'
import { Dispatch, useReducer, useState } from 'react'
import Team from 'classes/teams'
import User from 'classes/users'
import { Email, MeetingType } from 'classes/classes'
import { Emitter, EVENT_TYPE } from 'emitter'

const useStyles = makeStyles(theme => ({
  container: {
    margin: '15px',
    padding: '20px',
    backgroundColor: '#FFFFFF',
    border: '1px solid #D3D3D3',
    borderRadius: '5px'
  },
  sectionSubtitle: {
    color: '#808080',
    fontFamily: 'poppins',
    fontSize: '14px',
    marginTop: '5px',
    marginBottom: '5px'
  },
  sectionTitle: {
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#000000BF'
  },
  subsectionTitle: {
    fontSize: '14px',
    fontWeight: 'bold'
  },
  subsectionAttributeTitle: {
    fontSize: '12px',
    color: '#808080'
  },
  editButton: {
    display: 'flex',
    width: '127px',
    height: '40px',
    fontSize: '14px',
    border: '1px solid #D3D3D3',
    borderRadius: '5px',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#E4E4E4'
    }
  },
  divider: {
    marginBottom: 10
  }
}))

interface FormCompleteState {
  meetingRouting: boolean
  meetingEmail: boolean
  meetingType: boolean
}

interface CompleteAction {
  type: string
  value: any
}

const completeStateReducer = (state: FormCompleteState, action: CompleteAction): FormCompleteState => {
  switch (action.type) {
    case 'meetingRouting':
      return { ...state, meetingRouting: action.value }
    case 'meetingEmail':
      return { ...state, meetingEmail: action.value }
    case 'meetingType':
      return { ...state, meetingType: action.value }
  }
  return state
}

interface FormOfflineContentCardProps {
  open: boolean
  setOpen: (value: boolean) => void
  formDispatch: Dispatch<FormAction>
  form: Form
  setCanSave: (value: boolean) => void
  refreshMissing: () => any
}

export default function FormOfflineContentCard ({ open, setOpen, formDispatch, form, setCanSave, refreshMissing }: FormOfflineContentCardProps) {
  const classes = useStyles()
  const [offlineModalOpen, setOfflineModalOpen] = useState(false)
  const { data: { list: teams }, isLoading: teamsLoading } = Team.loadAll()
  const { data: { list: users }, isLoading: usersLoading } = User.loadAll({})
  const { data: { list: meetingTypes }, isLoading: meetingsLoading } = MeetingType.loadAll()
  const { data: { list: emails }, isLoading: emailsLoading } = Email.loadAll()
  const [completeState, updateCompleteState] = useReducer(completeStateReducer, { meetingRouting: true, meetingEmail: true, meetingType: true })

  const cardComplete = () => {
    if (!form.offlineHeader) {
      return false
    }
    if (!form.offlineMessage) {
      return false
    }
    if (form.offlineBookMeeting) {
      if (form.offlineMeetingRoutingSettings.length > 0) {
        form.offlineMeetingRoutingSettings.forEach((item) => {
          if (!item.method || !item.objectIDs || item.objectIDs.length < 1) {
            return false
          }
        })
      } else {
        return false
      }
      if (form.offlineFollowupEmail) {
        if (!form.offlineFollowupEmailID) {
          return false
        }
      }
      if (!form.offlineMeetingType) {
        return false
      }
    } else {
      return false
    }
    return true
  }

  Emitter.on(EVENT_TYPE.FORM_FOLLOWUP_MISSING, (payload: { type: string }) => {
    if (payload.type === 'obmRouting' || payload.type === 'obmEmail' || payload.type === 'obmMeetingType') {
      setOpen(true)
      setOfflineModalOpen(true)
      if (payload.type === 'obmRouting') {
        updateCompleteState({ type: 'meetingRouting', value: false })
      } else if (payload.type === 'obmEmail') {
        updateCompleteState({ type: 'meetingEmail', value: false })
      } else if (payload.type === 'obmMeetingType') {
        updateCompleteState({ type: 'meetingType', value: false })
      }
    }
  })

  if (teamsLoading || usersLoading || meetingsLoading || emailsLoading) {
    return (
      <></>
    )
  }

  return (
    <div className={classes.container}>
      <div onClick={() => setOpen(!open)} style={{ display: 'flex', flexDirection: 'row', cursor: 'pointer' }}>
        <div style={{ marginRight: '10px', marginTop: '5px' }}>
          <Icon icon={!cardComplete() ? 'uncheckedRadio' : 'lightGreenCheckCircle'} />
        </div>
        <div className={classes.sectionTitle}>
          Prompt content - Agents Offline
        </div>
        <div style={{ marginLeft: 'auto', marginRight: '5px' }}>
          {open ? (
            <div>
              <Icon icon='close' />
            </div>
          ) : (
            <div className={classes.editButton}>
              Edit Settings
            </div>
          )}
        </div>
      </div>
      <div onClick={() => setOpen(!open)} className={classes.sectionSubtitle}>
        {((!form.offlineBookMeeting) || open) ? (
          <div>
            Customize the prompt that will be shown after form submission when nobody is available to chat.
          </div>
        ) : (
          <div>
            <div className={classes.subsectionTitle} style={{ color: '#343434' }}>
              Book Meetings
            </div>
            <div className={classes.subsectionAttributeTitle}>
              Routing
            </div>
            {form.offlineMeetingRoutingSettings.length > 0 && (
              <div>
                Book a <b style={{ color: '#000000' }}>{meetingTypes.find((elem) => elem.id === form.offlineMeetingType)?.name}</b> with{' '}
                {(form.offlineMeetingRoutingSettings[0].method === 'single_agent' || form.offlineMeetingRoutingSettings[0].method === 'group') && (
                  <b style={{ color: '#000000' }}>{form.offlineMeetingRoutingSettings[0].objectIDs.map((item) => (users.find((elem) => elem.id === item))?.displayName + ' ')}</b>
                )}
                {(form.offlineMeetingRoutingSettings[0].method === 'team') && (
                  <b style={{ color: '#000000' }}>{form.offlineMeetingRoutingSettings[0].objectIDs.map((item) => teams?.find((elem) => (elem.id === item))?.name)}</b>
                )}
                <b style={{ color: '#000000' }}>{['group', 'team'].includes(form?.offlineMeetingRoutingSettings[0]?.method) ? '(' + form?.offlineMeetingRoutingSettings[0]?.type + ')' : ' '}</b>
                {form.offlineFollowupEmail && (
                  <div>
                    <div className={classes.subsectionAttributeTitle} style={{ marginTop: '10px' }}>
                      If visitor fails to book a meeting
                    </div>
                    <div>
                      Send <b style={{ color: '#000000' }}>{emails.find((elem) => elem.id === form.offlineFollowupEmailID)?.name}</b> email as a follow-up
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
      {open && (
        <div className='form-offline-tab' key={1}>
          <Typography variant='body2' style={{ marginTop: 15 }}>Customize your prompt's header</Typography>
          <Divider className={classes.divider} />
          <TextField
            variant='outlined'
            fullWidth
            value={form.offlineHeader}
            onChange={(e) => { formDispatch({ type: 'offlineHeader', value: e.target.value }); setCanSave(true) }}
          />
          <Typography variant='body2' style={{ marginTop: 15 }}>Customize your prompt's message</Typography>
          <Divider className={classes.divider} />
          <RichTextEditor
            message={form.offlineMessage}
            onChange={(value) => { formDispatch({ type: 'offlineMessage', value }); setCanSave(true) }}
            hideImage
            lines={4}
          />
          <Typography variant='h6' style={{ marginTop: 20 }}>Visitor Options</Typography>
          <Divider className={classes.divider} />
          <Typography variant='body2'>What options should visitors have?</Typography>
          <ContentCheckbox
            label='Include option for Live Chat'
            checked={false}
            onChange={() => null}
            disabled
          >
            <></>
          </ContentCheckbox>
          <ContentCheckbox
            label='Include option for Booking Meetings'
            checked={form.offlineBookMeeting}
            onChange={(checked: boolean) => { formDispatch({ type: 'offlineBookMeeting', value: checked }); setCanSave(true); setOfflineModalOpen(checked) }}
          >
            <>
              <Typography variant='body2'>How should meetings be routed?</Typography>
              <Route
                modal={
                  <CalendarModal
                    key='offline-meeting-modal'
                    open={offlineModalOpen}
                    onHide={() => { setOfflineModalOpen(false); refreshMissing() }}
                    save={({ meetingType, textMessageReminders, followUpEmail, emailID, routeOptions }: any) => {
                      formDispatch({ type: 'offlineMeetingRoutingSettings', value: routeOptions })
                      formDispatch({ type: 'offlineMeetingType', value: meetingType })
                      formDispatch({ type: 'offlineFollowupEmail', value: followUpEmail })
                      formDispatch({ type: 'offlineFollowupEmailID', value: emailID || null })
                      formDispatch({ type: 'offlineTextReminder', value: textMessageReminders })
                      setOfflineModalOpen(false)
                      setCanSave(true)
                      refreshMissing()
                    }}
                    meetingType={form.offlineMeetingType}
                    textMessageReminders={form.offlineTextReminder}
                    emailID={form.offlineFollowupEmailID}
                    followUpEmail={form.offlineFollowupEmail}
                    showTextMessageToggle
                    routeOptions={form.offlineMeetingRoutingSettings}
                    hideBody
                    completeState={completeState}
                  />
                }
                setModalOpen={(value) => setOfflineModalOpen(value)}
                label={RouteLabel(form.offlineMeetingRoutingSettings)}
                icon='dateRange'
              />
              <Typography variant='body2'>What should the button for this option say?</Typography>
              <TextField
                variant='outlined'
                fullWidth
                value={form.offlineMeetingButtonText}
                onChange={(e) => { formDispatch({ type: 'offlineMeetingButtonText', value: e.target.value }); setCanSave(true) }}
              />
            </>
          </ContentCheckbox>
          <div style={{ marginTop: '20px', marginLeft: 'auto', marginRight: '10px', width: '60px' }}>
            <Button
              onClick={() => setOpen(false)}
            >
              Done
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}
