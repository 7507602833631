import { Modal } from 'library/Modal';

type CancellationModalProps = {
  open: boolean
  onClose: () => void
  cancelSubscription: () => void
  renewalDate: string
  planName: string
}
export function CancellationModal (props: CancellationModalProps) {
  const subtitle = `Canceling your plan will take effect on ${props.renewalDate} and you'll lose access to Signals ${props.planName}.`
  return (
    <Modal
      open={props.open}
      onHide={props.onClose}
      handleSave={props.cancelSubscription}
      title='Are you sure you want to go?'
      subtitle={subtitle}
      saveBtnText='Cancel Plan'
      cancelBtnText='Keep Subscription'
      saveBtnColor='#EA422A'
      noCloseBtn
    />
  )
}
