
const descriptionText = 'The Bot Builder is a powerful tool that can be used to customize the user experience for visitors who interact with your bots. When used alongside the PlayMaker, the Bot Builder can:'

const Description = (): JSX.Element => {
  return (
    <div>
      <div>
        {descriptionText}
      </div>
      <ul>
        <li>Provide the user with custom content based upon their previous history</li>
        <li>Provide custom routing for visitors</li>
        <ul>
          <li>When available, the same agent who previously worked with the visitor can be routed to a chat</li>
          <li>Team, Group, and Individual routing also available</li>
        </ul>
        <li>Send custom emails based upon the bot interaction</li>
        <li>Provide visitors with desired content at the click of a button</li>
      </ul>
    </div>
  )
}

const bestPracticeText = 'The bot builder works best when fully integrated into the Signals experience. Some nodes will not function properly without enabling integrations or setting up agent calendars.'

const BestPractices = () => {
  return (
    <div>
      <div>
        {bestPracticeText}
      </div>
      <ul>
        <li>Use plays and bots together to so that you always know when and where each of your bots will fire</li>
        <li>Automatically direct your bot flows based upon availability of agents for live chat</li>
        <ul>
          <li><a href='#/user_management/users' target='_blank'>User Settings</a> (not accessible by all users)</li>
          <li><a href='#/settings/my_settings/my_calendar' target='_blank'>My Calendar Settings</a></li>
        </ul>
        <li>Add integrations to enhance your experience</li>
        <ul>
          <li><a href='#/settings/integrations' target='_blank'>Integration Settings</a></li>
        </ul>
      </ul>
    </div>
  )
}

export const botOverviewDocs = {
  helpCenterLink: 'https://help.getsignals.ai/category/1ch1mban4u-bots',
  bestPractices: BestPractices,
  description: Description,
  videoLink: ''
}
