const headers = {
  'Content-Type': 'application/vnd.api+json'
}

function getToken ({ authHeader }) {
  let newHeaders = headers
  if (authHeader) {
    newHeaders = { ...headers, ...authHeader }
  }
  return fetch('/api/auth/token', {
    method: 'GET',
    headers: newHeaders
  })
    .then(response => response.json())
}

function checkEmailAvailability (email) {
  return fetch('/api/auth/email_availability', {
    method: 'POST',
    headers: { 'Content-Type': 'application/vnd.api+json' },
    body: JSON.stringify({ email: email })
  })
    .then((response) => response.json())
}

function signup (full_name, email, password) {
  return fetch('/api/v2/auth/signup', {
    method: 'POST',
    headers: { 'Content-Type': 'application/vnd.api+json' },
    body: JSON.stringify({
      data: {
        attributes: {
          full_name: full_name,
          email: email,
          password: password
        }
      }
    })
  })
    .then(response => response.json())
}

function devSignup (full_name, email, password) {
  return fetch('/api/v2/auth/signup', {
    method: 'POST',
    headers: { 'Content-Type': 'application/vnd.api+json' },
    body: JSON.stringify({
      data: {
        attributes: {
          full_name: full_name,
          email: email,
          password: password,
          dev_registration: true
        }
      }
    })
  })
    .then(response => response.json())
    .catch(() => ({
      errors: [{ title: 'An unexpected error occured trying create an account' }]
    }))
}

function resendVerificationEmail (email) {
  return fetch('/api/auth/email_verification:resend', {
    method: 'POST',
    headers: headers,
    body: JSON.stringify({
      data: {
        attributes: {
          email: email
        }
      }
    })
  })
    .then(response => response.json())
}

export { getToken, resendVerificationEmail, signup, checkEmailAvailability, devSignup }
