import BotSelectionPage from './BotCreation/BotSelectionPage'
import BotEditPage from './bot-edit/BotEditPage'

export default function BotPage (props) {
  const botID = props.match.params.bot_id

  if (botID === 'new') {
    return (
      <BotSelectionPage />
    )
  } else {
    return (
      <BotEditPage
        botID={botID}
        history={props.history}
      />
    )
  }
}
