import { useEffect, useContext, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ConfirmationModal from 'library/modals/ConfirmationModal'
import RichTextSVG from './richText.svg'
import LogoSVG from './logo.svg'
import BannerSVG from './banner.svg'
import Card from '@material-ui/core/Card'
import TextField from '@material-ui/core/TextField'
import Dropzone from 'react-dropzone'
import PhotoIcon from '@material-ui/icons/AddPhotoAlternateOutlined'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import Button from '@material-ui/core/Button'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import { RichTextEditor } from 'library/richText/RichTextEditor'
import { Icon } from 'library/materialUI'
import MessageDiv from 'library/divs/MessageDiv'
import { EmailWizardContext } from './EmailWizardContext'

const useStyles = makeStyles(theme => ({
  imageDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  layoutsDiv: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'space-between'
  },
  layoutName: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#646464',
    fontSize: '14px'
  },
  emailLayoutSection: {
    width: 650
  },
  layoutHeader: {
    display: 'flex',
    justifyContent: 'center'
  },
  detailsCard: {
    border: '2px solid #F0E3FF',
    padding: 20
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
    color: 'gray',
    fontSize: '1.7em'
  },
  photoIcon: {
    width: 45,
    height: 45,
    color: 'gray'
  },
  dropzone: {
    cursor: 'pointer',
    height: '100%',
    display: 'flex'
  },
  paragraph: {
    color: 'gray',
    fontSize: 16,
    marginBottom: 0,
    marginTop: 5
  },
  paragraph2: {
    color: 'gray',
    fontSize: 12,
    marginBottom: 10,
    marginTop: 0
  },
  content: {
    margin: 'auto',
    width: '50%',
    textAlign: 'center',
    verticalAlign: 'center'
  },
  logoDiv: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 15
  },
  imgIcons: {
    position: 'relative',
    display: 'flex',
    top: 15,
    textAlign: 'right'
  },
  imgIcon: {
    margin: '2px',
    border: '1px solid rgba(0,0,0,0.08)',
    boxShadow: '-1px 1px rgba(0,0,0,0.2)',
    height: 30,
    width: 30,
    backgroundColor: '#954BE8',
    borderRadius: '50%',
    color: 'white',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))

function LayoutButton (props) {
  const classes = useStyles()
  const layout = props.layout
  const border = props.selected ? '2px solid #954BE8' : '2px solid #F0E3FF'
  const color = props.selected ? '#954BE8' : '#646464'
  const fontWeight = props.selected ? 600 : 500
  const imageStyle = {
    borderRadius: 10,
    padding: 10,
    border: border,
    boxShadow: '2px 2px 6px #00000029',
    cursor: 'pointer'
  }
  const textStyle = {
    color: color,
    fontWeight: fontWeight
  }
  return (
    <div className={classes.imageDiv} onClick={props.handleSelect}>
      <div>
        <img
          alt={layout.name}
          src={layout.svg}
          height={100}
          style={imageStyle}
        />
        <div className={classes.layoutName} style={textStyle}>
          {layout.name}
        </div>
      </div>
    </div>
  )
}

const emailLayouts = [
  { name: 'Rich Text', svg: RichTextSVG, value: 'rich_text' },
  { name: 'Logo', svg: LogoSVG, value: 'logo' },
  { name: 'Banner', svg: BannerSVG, value: 'banner' }
]

function LayoutSection (props) {
  const classes = useStyles()
  return (
    <div className={classes.layoutsDiv}>
      {emailLayouts.map((layout, index) => (
        <LayoutButton
          key={index}
          layout={layout}
          selected={props.layout === layout.value}
          handleSelect={() => props.setLayout(layout.value)}
        />
      ))}
    </div>
  )
}

const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    // eslint-disable-next-line
    const reader = new FileReader()
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
    reader.readAsDataURL(file)
  })
}

function LogoDropzone (props) {
  const classes = useStyles()
  if (props.layout === 'rich_text') {
    return (
      <></>
    )
  } else {
    const handleDrop = acceptedFile => {
      getBase64(acceptedFile[0]).then(image => {
        props.handler({ image })
      })
    }

    if (props.image) {
      let width = 100
      let iconWidth = 90
      if (props.layout === 'banner') {
        width = 600
        iconWidth = 254
      }
      return (
        <div className={classes.logoDiv}>
          <div className={classes.imgIcons} style={{ left: `calc(${iconWidth}px)` }}>
            <div
              className={classes.imgIcon}
              onClick={() => props.showModal(true)}
            >
              <Icon icon='addLink' />
            </div>
            {props.imageLink && (
              <div
                className={classes.imgIcon}
                onClick={() => props.handler({ link: null })}
              >
                <Icon icon='removeLink' />
              </div>
            )}
            <div
              className={classes.imgIcon}
              onClick={() => props.handler({ image: null })}
            >
              <DeleteForeverIcon />
            </div>
          </div>
          <img src={props.image} width={width} alt='Logo' />
        </div>
      )
    }

    return (
      <Dropzone onDrop={handleDrop}>
        {({ getRootProps, getInputProps }) => (
          <div
            {...getRootProps()}
            className={classes.dropzone}
          >
            <input {...getInputProps()} />
            <div className={classes.content}>
              <PhotoIcon className={classes.photoIcon} />
              <p className={classes.paragraph}>Drag a photo here</p>
              <p className={classes.paragraph2}>-or-</p>
              <Button variant='contained' size='small' style={{ fontSize: '10px' }}>
                Upload from computer
                <CloudUploadIcon className={classes.rightIcon} />
              </Button>
            </div>
          </div>
        )}
      </Dropzone>
    )
  }
}

function EmailLayout (props) {
  const classes = useStyles()
  const [showModal, setShowModal] = useState(false)
  const [imgLink, setImageLink] = useState(props.imageLink || '')
  const { dismissHyperlinkInfo, dismissedHyperlinkInfo } = useContext(EmailWizardContext)

  function editText (value) {
    props.setEmailBody(value)
  }
  function imgHandler ({ image, link }) {
    if (image !== undefined) {
      props.setImage(image)
    }
    if (link !== undefined) {
      props.setImageLink(link)
    }
  }
  const confirmationModalProps = {
    title: 'Logo link',
    subtitle: '',
    dynamicButtons: [
      {
        text: 'Save',
        color: 'primary',
        disabled: false,
        action: () => {
          props.setImageLink(imgLink)
          setShowModal(false)
        }
      },
      {
        text: 'Cancel',
        action: () => setShowModal(false)
      }
    ],
    children: <TextField
      variant='outlined'
      label='Link'
      margin='dense'
      fullWidth
      value={imgLink}
      onChange={(e) => setImageLink(e.target.value)}
              />
  }

  useEffect(() => {
    if (props.emailBody.includes('href')) {
      dismissHyperlinkInfo(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.emailBody])

  return (
    <div className={classes.emailLayoutSection}>
      <div className={classes.layoutHeader}>
        Choose a layout to get started
      </div>
      <div>
        <LayoutSection
          layout={props.layout}
          setLayout={props.setLayout}
        />
        <div style={{ marginTop: 25 }}>
          <MessageDiv
            open={!dismissedHyperlinkInfo}
            setOpen={(isOpen) => dismissHyperlinkInfo(!isOpen)}
            type='info'
            body='Be sure to link to your website so that recipients have a clear path to learn more about your company.'
            dismissable
          />
          <Card className={classes.detailsCard}>
            <TextField
              variant='outlined'
              label='Subject'
              margin='dense'
              fullWidth
              value={props.subject}
              onChange={(e) => props.setSubject(e.target.value)}
            />
            <LogoDropzone
              layout={props.layout}
              handler={imgHandler}
              image={props.image}
              showModal={setShowModal}
              imageLink={props.imageLink}
            />
            {showModal &&
              <ConfirmationModal
                {...confirmationModalProps}
              />}
            <div style={{ marginTop: 20 }}>
              <RichTextEditor
                lines={12}
                message={props.emailBody}
                onChange={editText}
                placeholder='Enter email body here...'
                showMergeFields
              />
            </div>
          </Card>
        </div>
      </div>
    </div>
  )
}

export { EmailLayout, getBase64 }
