/* eslint-disable @typescript-eslint/naming-convention */
import * as yup from 'yup'
import { ModalContentsProps } from 'library/flowBuilder/nodes/NodeModal';
import { defaultErrorMsg } from 'pages/bot/flow-builder/nodes/nodeHelpers';
import { useEffect, useState } from 'react';
import { getIntegration } from 'api/integrations';
import WarningDiv from '../WarningDiv';
import { ModalSection } from 'library/Modal';
import { Select } from 'cf-components/material-wrappers/MaterialComponents';
import CheckBox from '@material-ui/core/Checkbox';
import { Typography } from '@material-ui/core';
import Picker from 'cf-components/Pickers';
import { InputSelector } from 'components/CustomFieldEntries';
import { hasComponent } from 'api/billing';
import { useQuery } from 'react-query';
import ModalWarning from 'components/ModalWarning';
import { triggerSalesBot } from 'pages/bot/InAppBot/triggerSupportBot';
import { EditLinkButton } from '../EditLinkButton';

export const CreateAccountSchema = yup.object().shape({
  settings: yup.object({
    integration: yup.string().required(defaultErrorMsg),
    attribute: yup.object({
      id: yup.string(),
      type: yup.string(),
      name: yup.string(),
      value: yup.string(),
      options: yup.array().of(yup.string()),
      validID: yup.boolean()
    })
  })
});

const crm_integrations = [{ label: 'Salesforce', value: 'salesforce' }]

export function CreateAccountModal ({ settings, updateSettings }: ModalContentsProps) {
  const [displayWarning, setDisplayWarning] = useState(true)
  const [enabled, setEnabled] = useState(false)
  const { data: hasCreateAccountComponent } = useQuery('has-create-account-component', () => hasComponent('playrunner-create-accounts'))

  useEffect(() => {
    getIntegration('salesforce').then((resp: any) => {
      if (resp.ok) {
        setDisplayWarning(false)
      }
      if (resp.data.attributes.enabled) {
        setEnabled(true)
      }
    })
  })

  if (!hasCreateAccountComponent) {
    return (
      <ModalWarning
        body='Upgrade to automatically create accounts in your CRM'
        type='upgrade'
        helpAction={() => triggerSalesBot()}
      />
    )
  }

  if (displayWarning || !enabled) {
    return (
      <div>
        <div style={{ marginBottom: 10 }}>
          <WarningDiv
            title={settings.integration.charAt(0).toUpperCase() + settings.integration.slice(1)}
            notSetup={displayWarning}
            btnRedirect='/#/settings/salesforce'
          />
        </div>
        <Select
          value={settings.integration}
          onChange={(e: any) => updateSettings({ integration: e })}
          options={crm_integrations}
          placeholder='Select Integration'
          disableClear
        />
      </div>
    )
  }

  return (
    <div>
      <Typography variant='body2' style={{ marginBottom: 20 }}>Create a new account for visiting companies</Typography>
      <ModalSection
        title='Integration'
        subtitle='Which integration do you want to create accounts in?'
      >
        <Select
          value={settings.integration}
          onChange={(e: any) => updateSettings({ integration: e })}
          options={crm_integrations}
          placeholder='Select Integration'
        />
        <div style={{ display: 'flex', alignItems: 'center', marginTop: 10, marginBottom: 10 }}>
          <CheckBox
            color='primary'
            checked={settings.attribute?.id}
            onClick={() => {
              if (!settings.attribute?.id) {
                updateSettings({ attribute: { id: ' ', name: '', value: '' } })
              } else {
                updateSettings({ attribute: { id: null, name: null, value: null } })
              }
            }}
          />
          <Typography variant='body1'>Add property/attribute to the account</Typography>
        </div>
        {(settings.attribute?.id) && (
          <div>
            <Picker
              selection={settings.attribute?.id}
              setSelection={(cf: any) => {
                updateSettings({
                  attribute: {
                    ...settings.attribute,
                    id: cf?.id,
                    name: cf?.name || '',
                    type: cf?.kind,
                    options: cf?.options || [],
                    validID: true,
                    value: null
                  }
                })
              }}
              objectType='customFields'
              label='Select a property/attribute'
              loadAllProps={{ searchParams: { queryParams: { for_object: 'accounts' } } }}
              returnFull
            />
            <div style={{ marginTop: 5 }}>
              <EditLinkButton
                text='Edit Account Custom Fields'
                link='/#/account_management/custom_fields'
              />
            </div>
            {settings.attribute.validID && (
              <div style={{ marginTop: 10 }}>
                <Typography variant='body2' style={{ marginBottom: 5 }}>What value do you want to be assigned to this attribute?</Typography>
                <InputSelector
                  value={settings.attribute?.value}
                  onChange={(v: any) => {
                    updateSettings({ attribute: { ...settings.attribute, value: v } })
                  }}
                  type={settings.attribute?.type}
                  label={settings.attribute?.name}
                  options={settings.attribute?.options}
                />
              </div>
            )}
          </div>
        )}
      </ModalSection>
    </div>
  )
}

export function CreateAccountBody ({ settings }: any): JSX.Element {
  return (
    <div>
      <Typography variant='body1'>Create account in <b>{settings.integration.charAt(0).toUpperCase() + settings.integration.slice(1)}</b></Typography>
    </div>
  )
}
