import { IconType } from 'library/materialUI/Icon'

export type basicMapping = {
  icon: IconType
  title: string
}

export const botTemplateMapping: Record<string, basicMapping> = {
  Homepage: {
    icon: 'webPage',
    title: 'Homepage'
  },
  SecondNet: {
    icon: 'calendar',
    title: 'Second Net'
  },
  Retargeting: {
    icon: 'calendar',
    title: 'Retargeting'
  },
  HomepageFAQ: {
    icon: 'questionAnswer',
    title: 'Homepage + FAQ'
  },
  Demo: {
    icon: 'calendar',
    title: 'Demo'
  },
  LiveChatTemplate: {
    icon: 'liveChat',
    title: 'Live Chat'
  },
  Concierge: {
    icon: 'liveChat',
    title: 'Concierge'
  },
  ConversationalContent: {
    icon: 'module',
    title: 'Conversational Content'
  },
  ABELiveChat: {
    icon: 'liveChat',
    title: 'ABE Live Chat'
  }
}

export const playTemplateMapping: Record<string, basicMapping> = {
  Retarget: {
    icon: 'cursor',
    title: 'Retarget Visitor'
  },
  ICP: {
    icon: 'addContact',
    title: 'Matches ICP'
  },
  OpenOps: {
    icon: 'cta',
    title: 'Open Ops'
  },
  CurrentCustomer: {
    icon: 'goal',
    title: 'Current Customer'
  },
  ConversationScore: {
    icon: 'star',
    title: 'Conversation Score'
  },
  Events: {
    icon: 'liveHelp',
    title: 'Event Attendee'
  }
}
