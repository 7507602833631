
const descriptionText = 'This node is used to capture a web visitor\'s email address, which is then automatically saved to that visitor\'s Signals contact information.'

const Description = (): JSX.Element => {
  return (
    <div>
      <div>
        {descriptionText}
      </div>
      <ul>
        <li>Allows for control of which domains can be captured and what to do when an invalid/unwated email address is input.</li>
      </ul>
    </div>
  )
}

const bestPracticeText = 'This node is commonly paired with the Calendar Invite node to attempt to schedule a meeting with the visitor when needed.'

const BestPractices = () => {
  return (
    <div>
      <div>
        {bestPracticeText}
      </div>
      {/* <ul>
        <li>whatever we want</li>
        <li>to put in this list</li>
      </ul> */}
    </div>
  )
}

export const EmailCaptureDocs = {
  helpCenterLink: 'https://help.getsignals.ai/article/acn5vhnhdu-email-capture-node',
  bestPractices: BestPractices,
  description: Description,
  videoLink: ''
}
