import Card from 'library/materialUI/Card'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from 'library/materialUI'
import { useContext, useEffect } from 'react'
import { TabContext } from '../IntegrationPage/TabProvider'
import { IntegrationPageContext } from '../IntegrationPage/IntegrationPageProvider'
import Picker from 'cf-components/Pickers'

const useStyles = makeStyles((theme) => ({
  subtitle1: {
    fontSize: 18,
    fontWeight: 600,
    marginBottom: 15
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  subtitle2: {
    fontSize: 15,
    fontWeight: 600,
    marginBottom: 10
  },
  select: {
    width: 400,
    height: 40
  },
  menuHeader: {
    borderBottom: '1px solid #BBB',
    width: '100%',
    fontWeight: 600,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: 'rgba(0,0,0,0.87)'
  },
  bodyText: {
    marginBottom: 30,
    fontSize: 16
  },
  warningText: {
    fontSize: 14
  }
}))

interface ContactEnrollmentCardProps {
  save: any
  handleEdit: any
  integrationSettings: any
}

export default function ContactEnrollmentCard ({ save, handleEdit, integrationSettings }: ContactEnrollmentCardProps) {
  const classes = useStyles()
  const { setCanSave, shouldSave } = useContext(TabContext)
  const { handleSaved } = useContext(IntegrationPageContext)

  const selectedList = integrationSettings.attributes.config?.static_list_id ? integrationSettings.attributes.config?.static_list_id.toString() : ''

  useEffect(() => {
    if (shouldSave) {
      save()
      handleSaved()
    }
  })

  return (
    <Card
      content={
        <>
          <div className={classes.header}>
            <Typography className={classes.subtitle1}>
              Default List
            </Typography>
          </div>
          <Typography variant='body2' className={classes.bodyText}>
            Automatically enroll all new Contacts in the following static list in HubSpot
          </Typography>
          <Typography variant='subtitle2' className={classes.subtitle2}>
            HubSpot Static Lists
          </Typography>
          <div className={classes.select}>
            <Picker
              selection={selectedList}
              setSelection={(e: any) => {
                handleEdit({
                  type: 'static_list_id',
                  data: e
                })
                setCanSave(true)
              }}
              objectType='contactList'
              label='Select a static list'
              loadAllProps={{
                searchParams: {
                  extraHeaders: { 'x-integration': 'hubspot' }
                }
              }}
              queryBackend
            />
          </div>
        </>
      }
    />
  )
}
