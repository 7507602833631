
const descriptionText = 'Use messages to send custom content to your visitors. When utilized correctly, messages inserted into the bot flow are a powerful tool that can help elicit emotion and engage your visitors.'

const Description = (): JSX.Element => {
  return (
    <div>
      {descriptionText}
    </div>
  )
}

const bestPracticeText = "If messages are too long, or if the bot sends too many messages at once, it can be hard for the visitor to follow the flow of the conversation, and on small screens the text can quickly scroll out of the visitor's field of view."

const BestPractices = () => {
  return (
    <div>
      <div>
        {bestPracticeText}
      </div>
      <div style={{ marginTop: 20 }}>Do: </div>
      <ul>
        <li>Keep messages short and sweet</li>
        <li>Use messages to help emotionally engage visitors</li>
      </ul>
      <div style={{ marginTop: 20 }}>Don't: </div>
      <ul>
        <li>Send lots of messages at once</li>
      </ul>
    </div>
  )
}

export const MessageDocs = {
  helpCenterLink: 'https://help.getsignals.ai/article/poj4ppaj4y-message-node',
  bestPractices: BestPractices,
  description: Description,
  videoLink: ''
}
