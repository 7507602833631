import { useState, cloneElement, useEffect } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { Beforeunload } from 'react-beforeunload'
import ReactDOM from 'react-dom'
import { Modal, ModalSection } from 'library/Modal'

export function UnsavedModal (props) {
  function navigateToNextLocation () {
    props.history.block(() => { return true })
    props.history.push(props.nextLocation.pathname)
  }

  return (
    <Modal
      open={props.open}
      onHide={props.onHide}
      title='Unsaved Changes'
      handleSave={() => {
        props.save()
        props.onHide()
      }}
      closeBtnFunction={navigateToNextLocation}
      cancelBtnText='Leave Without Saving'
      saveButtonText='Save Changes'
      size='xs'
      saveIcon='save'
    >
      <ModalSection>
        <div style={{ fontFamily: 'Poppins', fontSize: 14 }}>
          You currently have unsaved changes. If you leave now, those changes will be lost.
        </div>
      </ModalSection>
    </Modal>
  )
}

function BotForm (props) {
  const [modalOpen, setModalOpen] = useState(false)
  const [nextLocation, setNextLocation] = useState(null)
  const bot = props.bot
  const history = props.history
  const activeTest = bot?.attributes?.active_test_id

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required('Please enter a name for your bot')
  })

  const breadcrumbs = [
    { link: '/engagement/chatbots/chatbots', text: 'Bot Roster' },
    { link: null, text: 'Bot Configuration' }
  ]

  // Clicking an extra time when opening a bot selects all the text on the page
  // This useEffect clears the selection
  useEffect(() => {
    if (window.getSelection) {
      if (window.getSelection().empty) { // Chrome
        window.getSelection().empty()
      } else if (window.getSelection().removeAllRanges) { // Firefox
        window.getSelection().removeAllRanges()
      }
    }
  }, [])

  return (
    <Formik
      enableReinitialize
      initialValues={props.initialValues}
      validationSchema={validationSchema}
      onSubmit={props.save}
    >{({ setFieldValue, values, isSubmitting, submitForm, dirty, resetForm }) => {
      function submit () {
        submitForm()
        resetForm(values)
        props.setCanSave(false)
      }
      const disabled = !props.canSave && !dirty

      if (!disabled) {
        history.block((nextRoute) => {
          if (nextRoute.pathname.includes('/bots/')) {
            return true
          }

          if (!activeTest) {
            ReactDOM.unstable_batchedUpdates(() => {
              setModalOpen(true)
              setNextLocation(nextRoute)
            })
            return false
          }
        })
      } else {
        history.block(() => {
          return true
        })
      }

      return (
        <Beforeunload onBeforeunload={event => {
          if (!disabled) { event.preventDefault() }
        }}
        >
          <Form>
            {cloneElement(props.children, {
              values: values,
              submit: submit,
              breadcrumbs: breadcrumbs,
              setFieldValue: setFieldValue,
              disabled: disabled
            })}
          </Form>
          <UnsavedModal
            open={modalOpen}
            onHide={() => setModalOpen(false)}
            nextLocation={nextLocation}
            save={submit}
            history={history}
            isSubmitting={isSubmitting}
          />
        </Beforeunload>
      )
    }}
    </Formik>
  )
}

export default BotForm
