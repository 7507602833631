import { useState } from 'react'
import { Dialog, Button, Icon } from 'library/materialUI'
import { makeStyles } from '@material-ui/core/styles'
import SegmentImage from './images/segment.png'
import IdentifyImage from './images/identify.png'
import PersonalizeImage from './images/personalize.png'
import AppendImage from './images/append.png'
import Carousel from 'react-material-ui-carousel'
import Logo from './images/fullNameLogo.svg'

const useStyles = makeStyles(theme => ({
  contents: {
    display: 'grid',
    gridTemplateColumns: '360px 360px',
    height: 300,
    marginTop: 40,
    marginBottom: 50,
    gridGap: 25,
    alignItems: 'center'
  },
  imageDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  dialog: {
    padding: 20
  },
  paper: {
    borderRadius: 15
  },
  details: {
    color: 'white'
  },
  sectionTitle: {
    font: 'normal normal bold 22px/30px Poppins',
    marginBottom: 15
  },
  subtitle: {
    font: 'normal normal normal 16px/22px Poppins',
    height: 65,
    marginBottom: 15
  },
  button: {
    color: 'white',
    backgroundColor: '#FFFFFF40',
    '&:hover': {
      backgroundColor: '#FFFFFF40'
    }
  },
  navButtonProps: {
    top: 'calc(76% - 20px) !important',
    backgroundColor: 'transparent'
  },
  navButtonWrapperProps: {
    top: 'calc(92% - 30px) !important',
    height: 1,
    '&:hover': {
      '& button:hover': {
        '&[aria-label="Next"]': {
          borderRadius: 0,
          backgroundColor: 'transparent'
        },
        '&[aria-label="Previous"]': {
          borderRadius: 0,
          backgroundColor: 'transparent'
        }
      }
    }
  }
}))

type ModalContents = {
  image: any
  title: string
  subtitle: string
  color: string
  imageWidth?: number
}

const items: ModalContents[] = [
  {
    title: 'Segment',
    subtitle: 'Group your accounts for better targeting based on the attributes you care about',
    color: '#FD9038',
    image: SegmentImage
  },
  {
    title: 'Identify',
    subtitle: 'See which companies are visiting your site, and when and how often they are visiting',
    color: '#985CE6',
    image: IdentifyImage
  },
  {
    title: 'Append',
    subtitle: 'Sit back while we gather contacts from your target accounts based on rules and segments you create',
    color: '#49CDA2',
    image: AppendImage
  },
  {
    title: 'Personalize & Engage',
    subtitle: 'Customize and organize bots, emails, and alerts into targeted campaigns to most effectively target specific segments',
    color: '#53B7C5',
    image: PersonalizeImage,
    imageWidth: 150
  }
]

type ModalProps = {
  open: boolean
  onClose: () => void
}

export function IntroWizardModal (props: ModalProps) {
  const classes = useStyles()
  const [color, setColor] = useState('#FD9038')
  const getStartedBtn = (
    <Button
      className={classes.button}
      onClick={props.onClose}
    >
      Get Started
    </Button>
  )
  return (
    <Dialog
      open={props.open}
      maxWidth='md'
      classes={{ paper: classes.paper }}
    >
      <div className={classes.dialog} style={{ backgroundColor: color }}>
        <div><img src={Logo} width={140} alt='Signals logo' /></div>
        <Carousel
          fullHeightHover={false}
          indicatorContainerProps={{
            style: {
              position: 'absolute',
              bottom: 10
            }
          }}
          autoPlay={false}
          activeIndicatorIconButtonProps={{ style: { color: 'white' } }}
          indicatorIconButtonProps={{ style: { color: '#FFFFFF40' } }}
          animation='fade'
          onChange={(now: number, _previous: number) => setColor(items[now].color)}
          NextIcon={color === '#53B7C5' ? getStartedBtn : <Icon icon='arrowRightWithLine' />}
          PrevIcon={color === '#FD9038' ? null : <Icon icon='arrowBack' />}
          navButtonsAlwaysVisible
          navButtonsProps={{ className: classes.navButtonProps }}
          navButtonsWrapperProps={{ className: classes.navButtonWrapperProps }}
        >
          {items.map((item, index) => {
          return (
            <div className={classes.contents} key={index} style={{ backgroundColor: color }}>
              <div className={classes.imageDiv}>
                <img src={item.image} width={item.imageWidth || 360} alt='Preview' />
              </div>
              <div className={classes.details}>
                <div className={classes.sectionTitle}>
                  {item.title}
                </div>
                <div className={classes.subtitle}>
                  {item.subtitle}
                </div>
              </div>
            </div>
          )
          })}
        </Carousel>
      </div>
    </Dialog>
  )
}
