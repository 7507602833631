import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  default: {
    fontSize: '14px'
  },
  subtitle: {
    fontWeight: 400,
    fontSize: '14px',
    fontFamily: theme.typography.fontFamily,
    color: 'rgba(0,0,0,0.54)'
  },
  cardTitle: {
    fontSize: '20px',
    color: 'rgb(0,0,0)',
    fontWeight: 600
  },
  cardData: {
    fontSize: '30px',
    lineHeight: '30px',
    color: 'rgb(0,0,0)',
    fontWeight: 600
  },
  cardDataText: {
    fontSize: '18px'
  },
  finePrint: {
    letterSpacing: 0,
    color: '#989898',
    fontSize: '10px'
  }
}))

type variant = 'default' | 'subtitle' | 'cardData' | 'cardDataText' | 'cardTitle' | 'finePrint'

type Color = keyof typeof colorMapping | undefined

type TextProps = {
  text: string
  variant: variant
  color?: Color
  cursor?: 'pointer'
}

const colorMapping = {
  error: '#FF5D5C',
  warning: '#FD9038'
}

const getStyle = (props: TextProps): any => {
  const style: Record<string, any> = {}
  if (props.color) {
    const color = colorMapping[props.color]
    style.color = color
  }
  if (props.cursor) {
    style.cursor = props.cursor
  }
  return style
}

export function Text (props: TextProps): JSX.Element {
  const classes = useStyles()
  const style = getStyle(props)

  return (
    <div
      className={classes[props.variant]}
      style={style}
    >
      {props.text}
    </div>
  )
}
