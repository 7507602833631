import { Typography } from 'library/materialUI'
import EdgeStep1_1 from 'img/edge_notif_1_1.png'
import EdgeStep1_2 from 'img/edge_notif_1_2.png'
import EdgeStep2 from 'img/edge_notif_2.png'
import EdgeStep3 from 'img/edge_notif_3.png'
import EdgeStep4 from 'img/edge_notif_4.png'
import EdgeStep5 from 'img/edge_notif_5.png'

const EdgeNotificationsDescription = (): JSX.Element => {
  return (
    <div style={{ fontFamily: 'Poppins', fontSize: '14px' }}>
      <Typography variant='h2' style={{ marginBottom: '20px' }}>To allow sound notifications in Edge, do the following:</Typography>
      <Typography variant='h2' style={{ marginBottom: '20px' }}>1. Click the … icon in the top right of the browser menu bar and click “Settings” to open the settings page.</Typography>
      <img src={EdgeStep1_1} alt='step_1_1' />
      <img src={EdgeStep1_2} alt='step_1_2' />
      <Typography variant='h2' style={{ marginBottom: '20px', marginTop: '20px' }}>2. On the settings page, click “Cookies and Site Permissions” in the menu on the left</Typography>
      <img src={EdgeStep2} alt='step_2' />
      <Typography variant='h2' style={{ marginBottom: '20px', marginTop: '20px' }}>3. Scroll down until you find "Media autoplay" and click it to open your autoplay permissions</Typography>
      <img src={EdgeStep3} alt='step_3' />
      <Typography variant='h2' style={{ marginBottom: '20px', marginTop: '20px' }}>4. Click the "Add" button to add a site to your permissions list</Typography>
      <img src={EdgeStep4} alt='step_4' />
      <Typography variant='h2' style={{ marginBottom: '20px', marginTop: '20px' }}>5. Enter "app.chatfunnels.com" in the site field and click "Add"</Typography>
      <img src={EdgeStep5} alt='step_5' />
    </div>
  )
}

export const EdgeNotificationsDocs = {
  helpCenterLink: 'https://help.getsignals.ai/article/rigkx7qxcj-allow-sound-notifications-for-new-messages',
  description: EdgeNotificationsDescription,
  videoLink: '',
  icon: 'smEdge',
  title: 'Allow sound notifications in Edge'
}
