import { makeStyles } from '@material-ui/core/styles'
import { Modal } from 'cf-components/Modal'

const useStyles = makeStyles(theme => ({
  modalContents: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: 50,
    fontFamily: 'Poppins, sans serif'
  },
  shortcut: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '15px',
    color: '#555',
    marginBottom: 15
  },
  shortcutButton: {
    padding: '5px 12px',
    border: '1px solid #aaa',
    backgroundColor: '#eee',
    borderRadius: 3,
    fontWeight: 600,
    marginLeft: 5,
    marginRight: 5
  },
  shortcutKeys: {
    display: 'flex',
    alignItems: 'center'
  },
  action: {
    width: 150
  }
}))

type modalProps = {
  onHide: () => void
  open: boolean
}

export function ShortcutsModal (props: modalProps): JSX.Element {
  const classes = useStyles()

  return (
    <Modal
      title='Keyboard Shortcuts'
      onHide={props.onHide}
      open={props.open}
      size='md'
      noButton
      mode='plain'
    >
      <div className={classes.modalContents}>
        <div>
          <div className={classes.shortcut}>
            <div className={classes.action}>
              Copy
            </div>
            <div className={classes.shortcutKeys}>
              <div className={classes.shortcutButton}>
                CTRL
              </div>
              +
              <div className={classes.shortcutButton}>
                C
              </div>
            </div>
          </div>
          <div className={classes.shortcut}>
            <div className={classes.action}>
              Paste
            </div>
            <div className={classes.shortcutKeys}>
              <div className={classes.shortcutButton}>
                CTRL
              </div>
              +
              <div className={classes.shortcutButton}>
                V
              </div>
            </div>
          </div>
          <div className={classes.shortcut}>
            <div className={classes.action}>
              Delete Selection
            </div>
            <div className={classes.shortcutKeys}>
              <div className={classes.shortcutButton}>
                DEL
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className={classes.shortcut}>
            <div className={classes.action}>
              Select Node
            </div>
            <div className={classes.shortcutKeys}>
              <div className={classes.shortcutButton}>
                CTRL
              </div>
              +
              <div className={classes.shortcutButton}>
                Click
              </div>
            </div>
          </div>
          <div className={classes.shortcut}>
            <div className={classes.action}>
              Select Multiple
            </div>
            <div className={classes.shortcutKeys}>
              <div className={classes.shortcutButton}>
                SHIFT
              </div>
              +
              <div className={classes.shortcutButton}>
                Drag
              </div>
            </div>
          </div>
          <div className={classes.shortcut}>
            <div className={classes.action}>
              Select All
            </div>
            <div className={classes.shortcutKeys}>
              <div className={classes.shortcutButton}>
                CTRL
              </div>
              +
              <div className={classes.shortcutButton}>
                A
              </div>
            </div>
          </div>
          <div className={classes.shortcut}>
            <div className={classes.action}>
              Unselect All
            </div>
            <div className={classes.shortcutKeys}>
              <div className={classes.shortcutButton}>
                CTRL
              </div>
              +
              <div className={classes.shortcutButton}>
                D
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}
