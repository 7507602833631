/* eslint @typescript-eslint/naming-convention: "off" */
import * as yup from 'yup'
import { yupToFormErrors } from 'formik'

export const defaultErrorMsg = 'This is a required field'
/** Creates an easy yup when condition */
const yupWhenEquals = (property: Parameters<yup.AnySchema['when']>[0], equals: any, thenYupSchema: yup.AnySchema): yup.AnySchema => yup.mixed().when(property, {
  is: (propValue: string) => propValue === equals,
  then: thenYupSchema
})
export const YupRequiredRichText = yup.string().trim().required(defaultErrorMsg).min(1, defaultErrorMsg).test({
  message: defaultErrorMsg,
  test: (value = '') => {
    try {
      const newValue = value.replace(/<[^>]+>/g, '').trim()
      return !!newValue.length
    } catch (e) {
      return false
    }
  }
})
export const YupRequiredString = yup.string().required(defaultErrorMsg).min(1, defaultErrorMsg)
const methodsWithoutIDs = ['account_owner', 'last_routed', 'global_routing']
const YupRouteOptions = yup.array().of(yup.object({
  method: yup.string().required(defaultErrorMsg),
  type: yup.string(),
  objectIDs: yup.mixed().when('method', {
    is: (method: string) => !methodsWithoutIDs.includes(method),
    then: yup.array().of(yup.number()).required(defaultErrorMsg).min(1, defaultErrorMsg)
  })
}))

export const CalendarSchema = yup.object().shape({
  body: YupRequiredString,
  meeting_type: yup.number().required(defaultErrorMsg),
  text_message_reminders: yup.boolean(),
  follow_up_email: yup.boolean(),
  email_id: yup.mixed().when('follow_up_email', {
    is: true,
    then: yup.number().required(defaultErrorMsg)
  }),
  routeOptions: YupRouteOptions
})

export const QuestionSchema = yup.object().shape({
  responseType: yup.string().required(defaultErrorMsg),
  body: YupRequiredRichText,
  buttons: yup.mixed().when('responseType', {
    is: (responseType: string) => responseType === 'buttons',
    then: yup.array().of(yup.object()).min(1, defaultErrorMsg)
  }),
  mapAttribute: yup.boolean(),
  attribute: yup.string().nullable().when('mapAttribute', {
    is: true,
    then: yup.string().required(defaultErrorMsg)
  }),
  keywordOptions: yupWhenEquals('responseType', 'keywords', yup.array().of(yup.object()).min(1, defaultErrorMsg)),
  captureTimes: yupWhenEquals('responseType', 'keywords', yup.number().required(defaultErrorMsg).min(1, defaultErrorMsg)),
  invalidResponse: yupWhenEquals('responseType', 'keywords', YupRequiredRichText),
  secondResponse: yupWhenEquals('responseType', 'keywords', YupRequiredRichText)
})

export const MessageSchema = yup.object().shape({
  body: YupRequiredRichText
})

export const LiveChatSchema = yup.object().shape({
  routeOptions: YupRouteOptions,
  fallbackDelay: yup.number().required(defaultErrorMsg).min(1, defaultErrorMsg)
})

export const ConditionalSchema = yup.object().shape({
  ports: yup.array().of(yup.object({
    id: yup.string(),
    label: yup.string(),
    filters: yup.mixed().when('id', {
      is: (id: string) => id !== 'default',
      then: yup.array().min(1, defaultErrorMsg)
    })
  }))
})

export const EmailCaptureSchema = yup.object().shape({
  body: YupRequiredRichText,
  unwanted: yup.object({
    spam: yup.boolean(),
    blocked: yup.boolean(),
    personal: yup.boolean()
  }),
  captureTimes: yup.number().required(defaultErrorMsg).min(1, defaultErrorMsg),
  invalidResponse: YupRequiredRichText,
  secondResponse: yup.string().when('captureTimes', {
    is: (captureTimes: number) => captureTimes > 1,
    then: YupRequiredRichText
  }),
  unwantedResponse: yup.string().when('unwanted', (unwanted, schema) => {
    if (unwanted.spam || unwanted.blocked || unwanted.personal) {
      return YupRequiredRichText
    }
    return schema
  })
})

export const PhoneCaptureSchema = yup.object().shape({
  body: YupRequiredRichText,
  captureTimes: yup.number().required(defaultErrorMsg).min(1, defaultErrorMsg),
  invalidResponse: YupRequiredRichText,
  secondResponse: YupRequiredRichText,
  country: yup.string().required(defaultErrorMsg).min(1, defaultErrorMsg)
})

export const ConversationRatingSchema = yup.object().shape({
  ratingPrompt: YupRequiredString,
  askFeedback: yup.boolean(),
  feedbackPrompt: yup.string().when('askFeedback', {
    is: true,
    then: YupRequiredString
  }),
  ratingType: yup.string().required(defaultErrorMsg)
})

export const ConversationGoalSchema = yup.object().shape({
  goalType: YupRequiredString,
  tagID: yup.lazy(value => {
    if (value === '') {
      return yup.mixed().notRequired()
    }
    return yup.number().notRequired()
  }),
  setFields: yup.lazy(value => {
    if (Array.isArray(value) && value.length > 0) {
      return yup.array().of(yup.object({
        field: yup.mixed().notRequired(),
        value: YupRequiredString
      })).min(1, defaultErrorMsg)
    }
    return yup.mixed().notRequired()
  }),
  integrationPlanId: yup.number().notRequired().nullable()
})

export const ConversationTagSchema = yup.object().shape({
  tags: yup.array().of(yup.number()).min(1, defaultErrorMsg)
})

export const ConversationStatusSchema = yup.object().shape({
  status: YupRequiredString
})

export const FAQSchema = yup.object().shape({
  body: yup.string().required(defaultErrorMsg),
  failureMessage: YupRequiredRichText,
  retryMessage: YupRequiredRichText,
  tags: yup.array().of(yup.string())
})

export const SetLeadScoreSchema = yup.object().shape({
  score: yup.number().required(defaultErrorMsg)
})

export const AlertAgentSchema = yup.object().shape({
  alertUnavailableAgents: yup.boolean(),
  notification: yup.object({
    message: yup.string(),
    title: YupRequiredRichText
  }).required(),
  routeOptions: YupRouteOptions
})

export const SendEmailSchema = yup.object().shape({
  emailID: yup.number().required(defaultErrorMsg),
  captureEmail: yup.boolean(),
  sendDelay: yup.lazy(value => {
    if (typeof value === 'number') {
      return yup.number().min(1, defaultErrorMsg).required(defaultErrorMsg)
    }
    return yup.string().max(0, defaultErrorMsg)
  })
})

export const ButtonSchema = yup.object().shape({
  label: YupRequiredString,
  url: YupRequiredString,
  open_target: YupRequiredString,
  color: YupRequiredString,
  prop_mapping: yup.array().of(yup.object({
    query_param: YupRequiredString,
    merge_param: YupRequiredString
  }))
})

const nodeValidationSchemas = {
  calendar: CalendarSchema,
  chat: MessageSchema,
  options: QuestionSchema,
  faq: FAQSchema,
  lead_score: SetLeadScoreSchema,
  email: EmailCaptureSchema,
  tag: ConversationTagSchema,
  goal: ConversationGoalSchema,
  conversation_status: ConversationStatusSchema,
  alert_agent: AlertAgentSchema,
  live_chat: LiveChatSchema,
  route: LiveChatSchema,
  phone: PhoneCaptureSchema,
  conversation_rating: ConversationRatingSchema,
  send_email: SendEmailSchema,
  conditional: ConditionalSchema,
  cta_button: ButtonSchema
}

type NodeTypes = keyof typeof nodeValidationSchemas

interface Node<Type extends (NodeTypes | unknown) = unknown> {
  data: {
    body: string
    type: Type extends NodeTypes ? Type : NodeTypes
    [key: string]: any
  }
  getTargetEdges: () => any[]
  getPorts: () => any[]
}

// type Node2<Type extends NodeTypes = NodeTypes> = jsPlumbToolkit.Node & {
//   data: { type: Type extends NodeTypes ? Type : NodeTypes }
// }

export function isNodeConnected<T extends Node> (node: T): boolean {
  const targetEdges = node.getTargetEdges()
  if (!targetEdges.length) {
    return false
  }
  const ports = node.getPorts().filter(p => p.data.type !== 'target')
  for (const port of ports) {
    const edges = port.getAllEdges()
    if (port.id === 'again') { continue }
    if (!edges.length) {
      return false
    }
  }
  return true
}

export function getNodeErrors<T extends Node> (node: T): Partial<(typeof nodeValidationSchemas[T['data']['type']])['fields']> | null {
  if (nodeValidationSchemas[node.data.type]) {
    try {
      nodeValidationSchemas[node.data.type].validateSync(node.data)
    } catch (e) {
      const formErrors = yupToFormErrors(e)
      return formErrors
    }
  }
  return null
}

export function isNodeValid<T extends Node> (node: T): boolean {
  if (nodeValidationSchemas[node.data.type]) {
    return nodeValidationSchemas[node.data.type].isValidSync(node.data)
  }
  return true
}
