import { useDoQuery } from './useDoQuery'
import { GenericObject } from './genericObject'
import { LoadAllProps } from './queryHelpers'

interface TechnologiesList {
  list: Technologies[]
  dict: { [id: Technologies['id']]: Technologies }
}

const initialState = {
  name: 'NAME NOT FOUND'
}

export class Technologies extends GenericObject {
  name: string
  group: string

  static loadAll (props?: LoadAllProps): { data: TechnologiesList, isLoading: boolean } {
    const title = props?.searchParams?.search
    let path = '/integrations/enrich/technology'
    if (title) {
      path += `?search=${title}`
    }
    return useDoQuery({
      path: path,
      objectClass: Technologies,
      searchParams: props?.searchParams,
      keepPreviousData: true
    })
  }

  constructor ({ row }: { row?: Record<string, any> }) {
    super({ row })
    const technology = row?.attributes || initialState
    this.name = technology.name
    this.group = technology.group
  }
}
