import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied'
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied'

const emptyStyles = makeStyles(theme => ({
  emptyIcon: {
    color: '#999999',
    height: 30,
    width: 30
  },
  outerDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
  },
  iconDiv: {
    display: 'flex',
    justifyContent: 'center'
  },
  displayText: {
    paddingTop: 5,
    fontFamily: 'Poppins, sans serif',
    fontWeight: 500,
    fontSize: '13px',
    color: '#999999'
  }
}))

function EmptyState (props) {
  const classes = emptyStyles()

  return (
    <div className={classes.outerDiv}>
      <div>
        <div className={classes.iconDiv}>
          {props.sad
            ? (
              <SentimentDissatisfiedIcon
                className={classes.emptyIcon}
              />)
            : (
              <SentimentSatisfiedIcon
                className={classes.emptyIcon}
              />)}
        </div>
        <div className={classes.displayText}>
          {props.message}
        </div>
      </div>
    </div>
  )
}

export default EmptyState
