import React, { useEffect, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import BounceLoader from 'react-spinners/RiseLoader'
import theme from 'theme'
import { createActivityChart } from './createActivityChart'
import Popper from '@material-ui/core/Popper'
import LanguageIcon from '@material-ui/icons/Language'
import EmailIcon from '@material-ui/icons/Email'
import Avatar from '@material-ui/core/Avatar'
import MeetingIcon from '@material-ui/icons/DateRange'
import LiveChatIcon from '@material-ui/icons/HeadsetMic'
import PersonIcon from '@material-ui/icons/Person'
import PhoneIcon from '@material-ui/icons/PhoneAndroid'
import MoneyIcon from '@material-ui/icons/AttachMoney'
import { ReactComponent as BotIcon } from 'img/botIcon.svg'
import desertIcon from 'img/desertIcon.svg'
import dateFormat from 'dateformat'

const useStyles = makeStyles(theme => ({
  tooltip: {
    backgroundColor: '#FAF7FF',
    padding: 8,
    boxShadow: '0px 3px 6px #00000029',
    fontFamily: 'Poppins, sans serif'
  },
  tooltipContents: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: 6
  },
  statsRow: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '12px'
  },
  icon: {
    height: 16,
    width: 16
  },
  avatar: {
    height: 22,
    width: 22,
    marginRight: 5
  },
  emptyStateMessage: {
    textAlign: 'center',
    font: 'normal normal bold 16px/22px Poppins',
    letterSpacing: 0,
    color: '#A690CE',
    opacity: 1,
    whiteSpace: 'pre-wrap',
    marginTop: 30
  },
  svg: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 5,
    height: 400,
    marginTop: 40
  }
}))

const loaderStyles = {
  inlineSize: 'max-content',
  marginLeft: 'auto',
  marginRight: 'auto',
  height: 540,
  iconSize: 30,
  alignItems: 'center'
}

function StatsRow (props) {
  const classes = useStyles()
  const statText = props.statText || ''
  const backgroundColor = props.backgroundColor || 'white'
  const icon = props.icon
  const value = props.value

  if (!value) {
    return <></>
  }

  return (
    <div className={classes.statsRow}>
      <Avatar
        className={classes.avatar}
        style={{ backgroundColor: backgroundColor }}
      >
        {icon}
      </Avatar>
      <div>
        {statText}
      </div>
    </div>
  )
}

function AccountActivityTooltip (props) {
  const classes = useStyles()

  const withDate = props.tooltipData
  const data = props.tooltipData?.summarized_data

  if (!data) {
    return <></>
  }

  const pageViews = data.page_views === 1 ? '1 page viewed' : data.page_views + ' pages viewed'
  const emailsCaptured = data.emails_captured === 1 ? '1 email captured' : data.emails_captured + ' emails captured'
  const botChats = data.conversations === 1 ? '1 chat with bot' : data.conversations + ' chats with bot'
  const meetings = data.meetings === 1 ? '1 meeting booked' : data.meetings + ' meetings booked'
  const liveChats = data.live_chats === 1 ? '1 live chat' : data.live_chats + ' live chats'
  const calendarDrops = data.calendar_drops === 1 ? '1 calendar drop' : data.calendar_drops + ' calendar drops'
  const phonesCaptured = data.phones_captured === 1 ? '1 phone captured' : data.phones_captured + ' phones captured'
  const contacts = data.contact_captured === 1 ? '1 contact created' : data.contact_captured + ' contacts created'
  const opps = data.opportunities_created === 1 ? '1 opp created' : data.opportunities_created + ' opps created'
  const siteVisits = data.site_visits === 1 ? '1 site visit' : data.site_visits + ' site visits'
  const date = new Date(withDate.date)
  date.setHours(date.getHours() + 12)

  return (
    <div className={classes.tooltip}>
      <div style={{ fontSize: '13px', marginBottom: 6, fontWeight: 600 }}>
        {dateFormat(date, 'mmm dd')}
      </div>
      <div className={classes.tooltipContents}>
        <StatsRow
          statText={pageViews}
          value={data.page_views}
          icon={<LanguageIcon className={classes.icon} />}
          backgroundColor='#FD9039'
        />
        <StatsRow
          statText={siteVisits}
          value={data.site_visits}
          icon={<LanguageIcon className={classes.icon} />}
          backgroundColor='#FD9039'
        />
        <StatsRow
          statText={emailsCaptured}
          value={data.emails_captured}
          icon={<EmailIcon className={classes.icon} />}
          backgroundColor='#DC67CE'
        />
        <StatsRow
          statText={botChats}
          value={data.conversations}
          icon={(
            <BotIcon
              className={classes.icon} style={{ transform: 'scale(1.3)', fill: 'white' }}
            />)}
          backgroundColor='#F56A48'
        />
        <StatsRow
          statText={meetings}
          value={data.meetings}
          icon={(<MeetingIcon className={classes.icon} />)}
          backgroundColor='#A367DC'
        />
        <StatsRow
          statText={liveChats}
          value={data.live_chats}
          icon={(<LiveChatIcon className={classes.icon} />)}
          backgroundColor='#DC6888'
        />
        <StatsRow
          statText={calendarDrops}
          value={data.calendar_drops}
          icon={(<MeetingIcon className={classes.icon} />)}
        />
        <StatsRow
          statText={contacts}
          value={data.contact_captured}
          icon={(<PersonIcon className={classes.icon} />)}
          backgroundColor='#6771DC'
        />
        <StatsRow
          statText={phonesCaptured}
          value={data.phones_captured}
          icon={(<PhoneIcon className={classes.icon} />)}
          backgroundColor='#DC67CE'
        />
        <StatsRow
          statText={opps}
          value={data.opportunities_created}
          icon={(<MoneyIcon className={classes.icon} />)}
          backgroundColor='#68B8DC'
        />
      </div>
    </div>
  )
}

function AccountMap (props) {
  const chartRef = useRef(null)
  const [visibility, setVisibility] = useState('hidden')
  const [anchorEl, setAnchorEl] = useState(null)
  const [tooltipData, setTooltipData] = useState(null)
  const data = props.data
  const classes = useStyles()

  useEffect(() => {
    if (data) {
      setVisibility('hidden')

      if (chartRef.current) {
        chartRef.current.dispose()
      }
      const id = 'account-activity'
      const chart = createActivityChart({ data, id, setVisibility, setAnchorEl, setTooltipData })
      chartRef.current = chart
    }
    // eslint-disable-next-line
  }, [data])

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  if (data?.length === 0) {
    return (
      <div>
        <div className={classes.svg}>
          <img src={desertIcon} alt='Empty state' />
        </div>
        <div className={classes.emptyStateMessage}>
          <p>There hasn’t been any activity from </p>
          <p style={{ marginTop: -10 }}>this account in the last 30 days.</p>
        </div>
      </div>
    )
  }

  return (
    <>
      <div
        style={{
          display: visibility === 'visible' ? 'none' : 'flex',
          ...loaderStyles
        }}
      >
        <BounceLoader color={theme.palette.primary.main} />
      </div>
      <div style={{ height: 540, paddingTop: 10, visibility: visibility }}>
        <div id='account-activity' style={{ width: '100%', height: '100%' }} />
        <Popper
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
        >
          <AccountActivityTooltip
            tooltipData={tooltipData}
          />
        </Popper>
      </div>
    </>
  )
}

export default AccountMap
