import React, { useState, useEffect, useCallback, useContext } from 'react'
import MeetingTypesTable from './MeetingTypesTable'
import MeetingTypesModal from './MeetingTypesModal'
import CusomizedSnackbar from 'components/CustomizedSnackbar'
import { getMeetingTypes, updateMeetingType, getMeetingType, deleteMeetingType } from 'api/meetings'
import DeleteModal from 'components/DeleteModal'
import AppPage from 'cf-components/AppPage'
import { SessionContext } from 'session-context'
import { queryClient } from 'App'

const initialValues = {
  attributes: {
    name: 'New Meeting type',
    length_in_minutes: 15,
    reminder_lead_time: 15,
    description: '',
    lead_time: 60,
    allow_in_agent_pages: true,
    allow_global_access: true
  }
}

const getInitial = (isManager) => {
  if (isManager) {
    return initialValues
  } else {
    return {
      attributes: {
        ...initialValues.attributes,
        allow_global_access: false
      }
    }
  }
}

const MeetingTypesPage = (props) => {
  const { user } = useContext(SessionContext)
  const [addModalOpen, setAddModalOpen] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [selected, setSelected] = useState([])
  const [meetingType, setMeetingType] = useState(getInitial(user.attributes.perms.manager))
  const [selectedMeetingTypeId, setSelectedMeetingTypeId] = useState()
  const [rows, setRows] = useState(null)
  const [snackState, setSnackState] = useState({
    open: false,
    variant: 'success',
    message: 'Your settings have been saved'
  })

  const handleChange = ({ type, data }) => {
    setMeetingType({
      ...meetingType,
      attributes: {
        ...meetingType.attributes,
        [type]: data
      }
    })
  }

  const getTypes = useCallback(() => {
    getMeetingTypes().then((response) => {
      setRows(response.data)
    })
  }, [])

  const save = () => {
    updateMeetingType({ meetingType: meetingType }).then(
      () => {
        getTypes()
        setAddModalOpen(false)
        setMeetingType(getInitial(user.attributes.perms.manager))
        setSnackState({
          open: true,
          variant: 'success',
          message: 'Your settings have been saved'
        })
        queryClient.invalidateQueries('meeting_types')
      }
    )
  }

  const remove = () => {
    deleteMeetingType({ meeting_type_id: selectedMeetingTypeId })
      .then(() => {
        setDeleteModalOpen(false)
        setSelectedMeetingTypeId()
        setSnackState({
          open: true,
          variant: 'success',
          message: 'Your settings have been saved'
        })
        getTypes()
      })
  }

  const handleEdit = (meeting_type_id) => {
    getMeetingType({ meeting_type_id })
      .then(response => {
        setMeetingType(response.data)
        setAddModalOpen(true)
      })
  }

  const handleDelete = (meeting_type_id) => {
    setSelectedMeetingTypeId(meeting_type_id)
    setDeleteModalOpen(true)
  }

  const handleClose = () => {
    setAddModalOpen(false)
    setMeetingType(getInitial(user.attributes.perms.manager))
  }

  useEffect(() => {
    getTypes()
  }, [getTypes])

  useEffect(() => {
    if (window.location.href.includes('?new')) {
      setAddModalOpen(true)
    }
  }, [])

  document.title = 'Meetings | Signals'

  return (
    <AppPage
      title='Meetings'
      actionText='Add Meeting'
      action={() => setAddModalOpen(true)}
    >
      <MeetingTypesTable
        rows={rows}
        onEdit={handleEdit}
        onDelete={handleDelete}
        selected={selected}
        setSelected={setSelected}
      />
      <MeetingTypesModal
        meetingType={meetingType}
        open={addModalOpen}
        onHide={handleClose}
        onSubmit={save}
        handleChange={handleChange}
        myUser={user}
      />
      <DeleteModal
        open={deleteModalOpen}
        onHide={() => setDeleteModalOpen(false)}
        deleteObject={remove}
        message='Are you sure you want to delete this meeting type?'
        title='Delete Meeting Type'
      />
      <CusomizedSnackbar state={snackState} handler={setSnackState} />
    </AppPage>
  )
}

export default MeetingTypesPage
