import { SectionProps } from './HelpCenter'
import { NodeSettings } from './NodeSettings'
import { SectionCenter } from './SectionCenter'

const overviewSelection = {
  BotsOverview: NodeSettings,
  PlaysOverview: NodeSettings
}

export const OverviewsHome = (props: SectionProps): JSX.Element => {
  const Component = props.links[0] === 'botsOverview' ? overviewSelection.BotsOverview : overviewSelection.PlaysOverview
  return (
    <SectionCenter
      homeComponent={<Component links={props.links} />}
      links={props.links}
      type='overview'
    />
  )
}
