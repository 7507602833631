import React, { useState, useEffect } from 'react'
import { Select } from 'cf-components/material-wrappers/MaterialComponents'

function SalesforceCampaignPicker ({ campaignId, setCampaignId }) {
  const [campaigns, setCampaigns] = useState(null)

  useEffect(() => {
    const headers = {
      'Content-Type': 'application/vnd.api+json'
    }
    fetch('/api/salesforce/campaigns', {
      method: 'GET',
      headers: headers
    })
      .then((response) => response.json())
      .then((response) => {
        setCampaigns(response.data.attributes.campaigns)
      })
  }, [])

  if (!campaigns) {
    return <>Loading...</>
  }

  return (
    <Select
      name='integration_plan_sf_campaign'
      placeholder='Choose a Salesforce campaign'
      value={campaignId}
      onChange={(value) => setCampaignId(value)}
      options={campaigns.map((campaign) => ({ value: campaign.Id, label: campaign.Name }))}
    />
  )
}

export default function IntegrationPlanBasicPicker ({ setIntegrationPlanId, integrationPlanId }) {
  let defaultPlanState
  if (integrationPlanId) {
    defaultPlanState = {}
  } else {
    defaultPlanState = {}
  }
  const [integrationPlan, setIntegrationPlan] = useState(defaultPlanState)

  useEffect(() => {
    if (integrationPlanId) {
      fetch(`/api/integration_plans/${integrationPlanId}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/vnd.api+json' }
      })
        .then(response => response.json())
        .then(response => {
          if (response.data && response.data.id) {
            setIntegrationPlan({ ...response.data.attributes.settings })
          }
        })
    }
  }, [integrationPlanId])

  function createIntegrationPlan ({ settings, integration_system }) {
    fetch('/api/integration_plans', {
      method: 'POST',
      headers: { 'Content-Type': 'application/vnd.api+json' },
      body: JSON.stringify({ data: { attributes: { settings: settings, integration_system: integration_system } } })
    })
      .then(response => response.json())
      .then(response => {
        if (response.data && response.data.id) {
          setIntegrationPlanId(response.data.id)
        }
      })
  }

  return (
    <div>
      <div style={{ color: '#808080' }}>
        Add contacts to a Salesforce campaign:
      </div>
      <div>
        <SalesforceCampaignPicker
          setCampaignId={(newCampaignId) => {
            createIntegrationPlan({
              integration_system: 'salesforce',
              settings: {
                add_campaign: true,
                add_campaign_id: newCampaignId

              }
            })
          }}
          campaignId={integrationPlan.add_campaign_id}
        />
      </div>

    </div>
  )
}
