import { useContext, useState, useEffect, createContext } from 'react'
import { SessionContext } from 'session-context'
import { useQueryClient } from 'react-query'
import { Emitter, EVENT_TYPE } from 'emitter'
import DeleteModal from 'cf-components/DeleteModal'
import CSVDownloadSnackbar from 'library/csvDownload/CSVDownloadSnackbar'
import { capitalCase } from 'change-case'
import { useHistory } from 'react-router-dom'

/** @type { React.Context<{ setDeleteObject, snackState, setSnackState }>} */
export const ApiContext = createContext({})

const listTypes = ['bots', 'saved_replies']

const getUpdatedList = ({ currentData, ID, attributes }) => {
  if (currentData) {
    const newObject = currentData.dict[ID]
    for (const attr in attributes) {
      newObject[attr] = attributes[attr]
    }
    const newList = [...currentData.list]
    const index = newList.map(i => i.id).indexOf(ID)
    newList[index] = newObject
    const newData = {
      ...currentData,
      list: newList,
      dict: {
        ...currentData.dict,
        ID: newObject
      }
    }
    return newData
  }
}

export function ApiProvider (props) {
  const queryClient = useQueryClient()
  const history = useHistory()
  const [deleteObject, setDeleteObject] = useState(null)
  const { saveFailed, saveSuccessful, displayError } = useContext(SessionContext)
  const [downloadState, setDownloadState] = useState({ open: false, type: 'filters' })

  useEffect(() => {
    Emitter.on(EVENT_TYPE.DATA_CHANGED, (payload) => {
      const key = payload.path.substr(1).split('/')[0]
      queryClient.invalidateQueries(key)
      const method = payload.method
      if (window.hideSnackBar) {
        return
      }
      if (['PATCH', 'PUT', 'DELETE'].includes(method) && !payload.silent) {
        const msg = (method === 'DELETE' ? 'Delete' : 'Save') + ' successful!'
        saveSuccessful(msg)
      }
    })
    Emitter.on(EVENT_TYPE.API_ERROR, (payload) => {
      const method = payload.method
      const message = payload.message
      const requestID = payload.requestID
      const requestURL = payload.requestURL
      const status = payload.status
      if (['PATCH', 'PUT'].includes(method)) {
        saveFailed('Save unsuccessful ' + message, requestID, requestURL, status)
      } else if (method === 'POST' && message) {
        displayError(message, requestID, requestURL, status)
      }
    })
    Emitter.on(EVENT_TYPE.API_SUCCESS, (payload) => {
      if (payload.message) {
        saveSuccessful(payload.message)
      }
    })
    Emitter.on(EVENT_TYPE.OPTIMISTIC_UPDATE, (payload) => {
      const data = payload.data
      if (listTypes.includes(data?.type)) {
        queryClient.setQueryData(data?.type, currentData =>
          getUpdatedList({ currentData, ID: data.id, attributes: data.attributes }))
      }
    })
    Emitter.on(EVENT_TYPE.CSV_DOWNLOAD, (payload) => {
      if (payload.type) {
        setDownloadState({ open: payload.csvIsDownloading, type: payload.type })
      } else {
        setDownloadState({ open: payload.csvIsDownloading, type: downloadState.type })
      }
    })
    // eslint-disable-next-line
  }, [queryClient])

  const objectType = deleteObject?.objectType
  const objectString = objectType ? capitalCase(objectType) : ''

  return (
    <ApiContext.Provider value={{ setDeleteObject }}>
      <CSVDownloadSnackbar open={downloadState.open} type={downloadState.type} />
      {Boolean(deleteObject) &&
        <DeleteModal
          title={'Delete ' + objectString}
          object={objectString.toLowerCase()}
          open={Boolean(deleteObject)}
          onHide={() => setDeleteObject(null)}
          confirmAction={() => {
            deleteObject.delete().then(({ path }) => { if (path) { history.push(path) } })
            setDeleteObject(null)
          }}
          deleteObject={deleteObject}
        />}
      {props.children}
    </ApiContext.Provider>
  )
}
