import { makeStyles } from '@material-ui/core/styles'
import { Cronofy } from 'classes/cronofy'
import { MeetingSettings } from 'classes/meetingSettings'
import { reducer } from 'library/helpers'
import { Button, Icon, Radio, TextBox } from 'library/materialUI'
import { useContext, useReducer } from 'react'
import { SessionContext } from 'session-context'
import { ContentsProps } from '../wizard/OnboardingWizard'
import zoomLogo from 'img/integrations/zoom.png'
import msTeamsLogo from 'img/integrations/msteams.png'
import goToMeetingLogo from 'img/integrations/gotomeeting.png'
import { camelCase } from 'change-case'

export const conferencingSubtitle = 'Choose how you want to handle conferencing for booked meetings'

const useStyles = makeStyles(theme => ({
  subtitle: {
    color: '#00000080',
    font: 'normal normal normal 12px/18px Poppins',
    padding: '5px 15px 10px 15px'
  }
}))

type ConferencingState = {
  conferencingType: 'cronofy' | 'url'
  provider: string | null
  userID: number
  settingsID: number | null
  locationURL: string
}

type SetupProps = {
  initialSettings: ConferencingState
  cronofy: Cronofy
  handleNextRef: any
}

const logos = {
  msTeams: msTeamsLogo,
  zoom: zoomLogo,
  goTo: goToMeetingLogo
}
type LogoType = keyof typeof logos | ''

type ConferencingSetupProps = {
  type: LogoType
  visible: boolean
  handleConferencingSetup: () => void
}

function ConferencingConnection (props: ConferencingSetupProps) {
  if (!props.visible) {
    return (<></>)
  }
  if (!props.type) {
    return (
      <div style={{ marginLeft: 20 }}>
        <Button
          variant='contained'
          startIcon={<Icon icon='add' />}
          color='primary'
          size='small'
          onClick={props.handleConferencingSetup}
        >
          Add conferencing provider
        </Button>
      </div>
    )
  }
  return (
    <img
      src={logos[props.type]}
      alt='Conferencing provider'
      width={200}
    />
  )
}

function ConferencingSetup (props: SetupProps) {
  const classes = useStyles()
  const [state, dispatch] = useReducer(reducer, props.initialSettings)
  const useCronofy = state.conferencingType === 'cronofy'

  const setConferencingType = (value: string) => { dispatch({ conferencingType: value }) }

  const saveSettings = () => {
    const updates: Partial<MeetingSettings> = {
      conferencingType: state.conferencingType,
      id: state.settingsID,
      locationURL: state.locationURL
    }
    return MeetingSettings.save(state.userID, updates)
  }

  const handleConferencingSetup = () => {
    saveSettings()
      .then(() => {
        window.location.assign(props.cronofy.conferenceRedirectURL)
      })
  }

  props.handleNextRef.current = saveSettings

  return (
    <div>
      <div>
        <Radio
          label='Auto-generate links'
          value='cronofy'
          checked={useCronofy}
          handleChange={setConferencingType}
        />
        <div className={classes.subtitle}>
          Automatically generate Zoom, GoToMeeting, or Microsoft Teams events for the meeting invitation
        </div>
        <ConferencingConnection
          type={camelCase(props.cronofy.conferencingProviderType || '') as LogoType}
          visible={useCronofy}
          handleConferencingSetup={handleConferencingSetup}
        />
      </div>
      <div style={{ marginTop: 10 }}>
        <Radio
          label='Use existing link'
          value='url'
          checked={!useCronofy}
          handleChange={setConferencingType}
        />
        <div className={classes.subtitle}>
          Insert your URL for Zoom, Teams, etc.
        </div>
        <div style={{ display: useCronofy ? 'none' : 'block', marginLeft: 20 }}>
          <TextBox
            value={state.locationURL}
            onChange={(value: string) => dispatch({ locationURL: value })}
            label='Meeting URL'
          />
        </div>
      </div>
    </div>
  )
}

export function ConferencingProvider (props: ContentsProps) {
  const { userObject } = useContext(SessionContext)
  window.sessionStorage.setItem('onboardingStep', 'conferencing')
  const { data: settings, isLoading: settingsLoading } = MeetingSettings.load({ userID: userObject.id })
  const { data: cronofy, isLoading: cronofyLoading } = Cronofy.load()

  if (settingsLoading || cronofyLoading) {
    return (
      <div />
    )
  }

  const initialSettings: ConferencingState = {
    userID: userObject.id,
    settingsID: null,
    conferencingType: 'cronofy',
    provider: null,
    locationURL: ''
  }

  if (settings) {
    initialSettings.conferencingType = settings.conferencingType
    initialSettings.provider = cronofy.conferencingProviderType
    initialSettings.settingsID = settings.id
    initialSettings.locationURL = settings.locationURL
  }

  return (
    <ConferencingSetup
      initialSettings={initialSettings}
      cronofy={cronofy}
      handleNextRef={props.handleNextRef}
    />
  )
}
