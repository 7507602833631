import LanguageIcon from '@material-ui/icons/Language'
import MeetingIcon from '@material-ui/icons/DateRange'
import LiveChatIcon from '@material-ui/icons/HeadsetMic'
import { ReactComponent as BotIcon } from 'img/botIcon.svg'
import VisitorIcon from '@material-ui/icons/PermIdentity'
import CaledarDropIcon from '@material-ui/icons/CalendarToday'
import TodayIcon from '@material-ui/icons/Today'
import EventIcon from '@material-ui/icons/Event'
import PhoneIcon from '@material-ui/icons/PhoneAndroid'
import MoneyIcon from '@material-ui/icons/AttachMoney'
import EmailIcon from '@material-ui/icons/Email'
import NearMe from '@material-ui/icons/NearMe'
import PersonAddRounded from '@material-ui/icons/PersonAddRounded'
import { ReactComponent as FormsIcon } from 'img/forms_white.svg'

const contactMostRelevant = contactActivity => {
  const eventList = []
  contactActivity.forEach(activity => {
    eventList.push(activity.kind)
  })

  if (eventList.includes('page')) {
    return 'pageViews'
  }
  if (eventList.includes('conversation_started')) {
    return 'conversations'
  }
  if (eventList.includes('contact_created')) {
    return 'visitors'
  }
  if (eventList.includes('note')) {
    return 'forms'
  }
  if (eventList.includes('meeting_booked')) {
    return 'meetingsBooked'
  }
  if (eventList.includes('form_submission')) {
    return 'forms'
  }
}

const getSummary = activityRawData => {
  const summaryArray = []
  const pageViews = activityRawData?.page_views || 0
  const conversations = activityRawData?.conversations || 0
  const liveChats = activityRawData?.live_chats || 0
  const meetings = activityRawData?.meetings_booked || 0
  const emailsCaptured = activityRawData?.emails_captured || 0
  const phonesCaptured = activityRawData?.phones_captured || 0
  const siteVisits = activityRawData?.site_visits || 0
  const formSubmissions = activityRawData?.form_submissions || 0

  let mostRelevantEvent = ''
  let text = ''
  let relevantEventText = ''

  if (siteVisits > 0) {
    text = siteVisits > 1 ? ' site visits' : ' site visit'
    relevantEventText = siteVisits + text
    summaryArray.push({ count: siteVisits, text })
    mostRelevantEvent = 'pageViews'
  }

  if (pageViews > 0) {
    text = pageViews > 1 ? ' pages viewed' : ' page viewed'
    relevantEventText = pageViews + text
    summaryArray.push({ count: pageViews, text })
    mostRelevantEvent = 'pageViews'
  }

  if (conversations > 0) {
    text = conversations > 1 ? ' bot chats' : ' bot chat'
    relevantEventText = conversations + text
    summaryArray.push({ count: conversations, text })
    mostRelevantEvent = 'conversations'
  }

  if (phonesCaptured > 0) {
    text = phonesCaptured > 1 ? ' phones captured' : ' phone captured'
    relevantEventText = phonesCaptured + text
    summaryArray.push({ count: phonesCaptured, text })
    mostRelevantEvent = 'phonesCaptured'
  }

  if (emailsCaptured > 0) {
    text = emailsCaptured > 1 ? ' emails captured' : ' email captured'
    relevantEventText = emailsCaptured + text
    summaryArray.push({ count: emailsCaptured, text })
    mostRelevantEvent = 'emailsCaptured'
  }

  if (liveChats > 0) {
    text = liveChats > 1 ? ' live chats' : ' live chat'
    relevantEventText = liveChats + text
    summaryArray.push({ count: liveChats, text })
    mostRelevantEvent = 'liveChats'
  }

  if (meetings > 0) {
    text = meetings > 1 ? ' meetings booked' : ' meeting booked'
    relevantEventText = meetings + text
    summaryArray.push({ count: meetings, text })
    mostRelevantEvent = 'meetingsBooked'
  }

  if (formSubmissions > 0) {
    text = formSubmissions > 1 ? ' form submissions' : ' form submission'
    relevantEventText = formSubmissions + text
    summaryArray.push({ count: formSubmissions, text })
    mostRelevantEvent = 'forms'
  }

  return { summaryItems: summaryArray, mostRelevantEvent, relevantEventText }
}

const activities = {
  pageViews: {
    title: 'Page Views',
    color: '#FD9039',
    icon: LanguageIcon
  },
  visitors: {
    title: 'Visitors',
    color: '#6771DC',
    icon: VisitorIcon
  },
  conversations: {
    title: 'Bot Conversations',
    color: '#F56A48',
    icon: BotIcon
  },
  liveChats: {
    title: 'Live Chats',
    color: '#DC6888',
    icon: LiveChatIcon
  },
  calendarDrops: {
    title: 'Calendars Dropped',
    color: 'rgba(163, 103, 220, 0.8)',
    icon: CaledarDropIcon
  },
  meetingsBooked: {
    title: 'Meetings Booked',
    color: '#A367DC',
    icon: MeetingIcon
  },
  firstSeen: {
    title: 'First Site Visit',
    color: '#39AFFC',
    icon: TodayIcon,
    allTime: true
  },
  lastSeen: {
    title: 'Last Site Visit',
    color: '#61CCBC',
    icon: EventIcon,
    allTime: true
  },
  emailsCaptured: {
    title: 'Emails Captured',
    color: '#6771DC',
    icon: EmailIcon
  },
  phonesCaptured: {
    title: 'Phones Captured',
    color: '#DC67CE',
    icon: PhoneIcon
  },
  opportunities: {
    title: 'Opportunities Generated',
    color: '#68B8DC',
    icon: MoneyIcon
  },
  forms: {
    title: 'Form Submitted',
    color: '#63AFCD',
    icon: FormsIcon
  },
  siteVisits: {
    title: 'Site Visits',
    color: '#A3DC03',
    icon: NearMe
  },
  firstSource: {
    title: 'First Source',
    color: '#39AFFC',
    icon: LanguageIcon,
    allTime: true
  },
  lastSource: {
    title: 'Last Source',
    color: '#61CCBC',
    icon: LanguageIcon,
    allTime: true
  },
  contactCreated: {
    title: 'Contact Created',
    color: '#6771DC',
    icon: PersonAddRounded,
    allTime: true
  }
}

export { contactMostRelevant, getSummary, activities }
