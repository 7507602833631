import React, { useContext, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import SearchIcon from '@material-ui/icons/Search'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import { useDebounce } from 'use-debounce/lib'
import { TableContext } from './TableProvider'

const useStyles = makeStyles(theme => ({
  icon: {
    marginRight: 19,
    fill: 'rgba(0, 0, 0, 0.54)',
    cursor: 'pointer'
  },
  inputRoot: {
    borderRadius: '5px',
    height: '40px'
  },
  inputMargin: {
    paddingTop: 8,
    paddingBottom: 8
  }
}))

// This needs to be renamed. It is no longer descriptive, but we want to change everywhere this is changed to no longer be collapsible.
export default function CollapsibleSearchBar () {
  const classes = useStyles()
  const { handleSearch } = useContext(TableContext)
  const [search, setSearch] = useState('')
  const [debouncedSearch] = useDebounce(search, 500)

  useEffect(() => {
    handleSearch(debouncedSearch)
    // eslint-disable-next-line
  }, [debouncedSearch])

  return (
    <TextField
      value={search}
      onChange={(event: any) => setSearch(event.target.value)}
      margin='dense'
      variant='outlined'
      InputProps={{
        startAdornment: <InputAdornment position='start'><SearchIcon style={{ fill: '#343434' }} /></InputAdornment>,
        classes: {
          root: classes.inputRoot,
          inputMarginDense: classes.inputMargin
        }
      }}
      style={{ margin: 0, marginLeft: 5, marginRight: 5, width: search.length > 15 ? 400 : 200, transition: 'width 0.5s' }}
    />
  )
}
