import { useState } from 'react'
import { Page } from 'library/page/Page'
import { FlowBuilder } from 'library/flowBuilder/FlowBuilder'
import { Play } from 'classes/plays'
import { nodeMapping, nodeFactory } from '../playBuilder/nodes/nodeMapping'
import { SaveFunction } from 'library/flowBuilder/canvas/FlowBuilderProvider'

export function PlayPage (props: any): JSX.Element {
  const playID = props.match.params.playID
  const history = props.history
  if (playID === 'new') {
    return (
      <PlayEditor
        play={new Play()}
      />
    )
  }

  const { data: play, isLoading } = Play.load()
  if (isLoading) {
    return <></>
  }
  return (
    <PlayEditor
      play={play}
      history={history}
    />
  )
}

function PlayEditor ({ play, history }: any): JSX.Element {
  const [state] = useState(play)

  const savePlay: SaveFunction = ({ data, publish }) => {
    state.edges = data.edges
    state.nodes = data.nodes
    return Play.save({ play: state, publish })
  }

  const initialData = {
    data: play.getFlow()
  }

  const handleBack = () => {
    history.push('/engagement/playmaker/dashboard')
  }

  return (
    <Page
      title={{ name: 'PlayMaker' }}
      tabs={[]}
      back={handleBack}
    >
      <FlowBuilder
        save={savePlay}
        object={state}
        objectType='plays'
        initialData={initialData}
        nodeMapping={nodeMapping}
        nodeFactory={nodeFactory}
        flowOptions={['publish']}
        history={history}
        // flowOptions={['save', 'publish']}
      />
    </Page>
  )
}
