import { useState, useEffect, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import { isEmpty } from 'lodash'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { upsertFeatureAnnouncement } from 'api/admin_only/notifications'
import { getTenants } from 'api/admin_only/tenants'
import { getRoles } from 'api/users'
import { SessionContext } from 'session-context'
import EmptyState from 'cf-components/EmptyState'
import { Modal, ModalSection } from 'library/Modal'
import { RichTextEditor } from 'library/richText/RichTextEditor'
import { Select } from 'cf-components/material-wrappers/MaterialComponents'

const useStyles = makeStyles(theme => ({
  textField: {},
  filterCtn: {
    borderRadius: 10,
    border: '1px solid #d3d3d3',
    backgroundColor: '#F5F5F5',
    padding: '10px 15px 2px'
  },
  filterText: {
    color: theme.palette.primary.main,
    fontSize: '.9rem'
  },
  spacingQuill: {
    '&.disabled': {
      pointerEvents: 'none'
    },
    '& > .quill': {
      display: 'flex',
      flexFlow: 'column nowrap',
      height: 120
    }
  },
  filterGroup: {
    marginTop: 10,
    marginBottom: 10,
    display: 'flex',
    alignItems: 'center',
    gridGap: 10,
    color: '#4A4A4A',
    '& > *': {
      flexShrink: 0
    }
  },
  criteriaSelect: {
    flexBasis: 300,
    backgroundColor: 'white'
  },
  valuesSelect: {
    flexGrow: 1,
    flexShrink: 1,
    overflow: 'hidden',
    '& > *': {
      width: '100%'
    }
  }
}))

const UserFilterSchema = Yup.object().shape({
  kind: Yup.string().trim()
    .min(1)
    .required(),
  user_filters: Yup.array()
})

const FeatureAnnouncementSchema = Yup.object().shape({
  title: Yup.string().trim()
    .min(1)
    .required(),
  user_filters: Yup.array()
    .of(UserFilterSchema)
})

/**
 * @typedef {import('api/admin_only/notifications').AttributesFA} AttributesFA
 * @typedef {import('api/admin_only/notifications').FeatureAnnouncement<AttributesFA>} FeatureAnnouncement
 */

/** @type {AttributesFA} */
const defaultValues = {
  title: '',
  body: '',
  user_filters: [],
  action_link: '',
  link_label: ''
}

/** @type {TextField} */
const Field = props => (
  <TextField
    variant='outlined'
    color='primary'
    margin='dense'
    {...props}
  />
)

const filterTypes = [
  { display: 'Tenants', value: 'tenants' },
  { display: 'User roles', value: 'user_roles' },
  { display: 'Users with permissions', value: 'user_perms' }
]

/**
 * @param {{
 * open: boolean;
 * onClose: (reloadAnnouncements?: boolean;) => void;
 * announcement?: FeatureAnnouncement;
 * }} props
 */
export default function AdminAnnouncementsModal ({ announcement = { attributes: {} }, onClose, open }) {
  const classes = useStyles()
  const chatServiceUrl = window.chatServiceUrl
  const { user } = useContext(SessionContext)
  const [tenants, setTenants] = useState([])
  const [userRoles, setUserRoles] = useState([])
  const [userPerms, setUserPerms] = useState([])

  /** @type {{ [type: string]: (typeof filterTypes[number])[] }} */
  const filterValuesMap = {
    tenants: tenants,
    user_roles: userRoles,
    user_perms: userPerms
  }

  useEffect(() => {
    getTenants().then(res => {
      setTenants(res.data.map(tenant => ({ value: tenant.id, display: tenant.attributes.name })))
    })
    getRoles().then(res => {
      setUserRoles(res.data.map(role => ({ value: role.id, display: role.attributes.role_name })))
    })
  }, [chatServiceUrl])

  const currentUserPerms = user?.attributes?.perms
  useEffect(() => {
    if (currentUserPerms) {
      setUserPerms(Object.keys(currentUserPerms).sort().map(key => ({ value: key, display: key })))
    }
  }, [currentUserPerms])

  if (!open) {
    return <></>
  }

  const isPublished = !!announcement?.attributes?.published_timestamp
  const isNew = isEmpty(announcement.attributes)
  const baseURL = window.location.href.split('admin_portal')[0].replace('beta', 'app')
  const initialValues = isNew ? { ...defaultValues, action_link: baseURL } : announcement.attributes

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(attributes, { setSubmitting }) => {
        const values = isNew ? { attributes } : { id: announcement.id, attributes }
        upsertFeatureAnnouncement(values).then(res => {
          setSubmitting(false)
          if (res.ok) {
            onClose(true)
          }
        })
      }}
      validationSchema={FeatureAnnouncementSchema}
    >
      {({ values, setFieldValue, isSubmitting, submitForm, isValid }) => {
        const disabled = isSubmitting || !isValid || isPublished
        return (
          <Modal
            title='Feature Announcement'
            open
            onHide={() => onClose()}
            handleSave={submitForm}
            saveDisabled={disabled}
            saveIcon='save'
            size='md'
          >
            <Form>
              <ModalSection title='Announcement Info'>
                <Field
                  className={classes.textField}
                  value={values.title}
                  placeholder='Title *'
                  required
                  fullWidth
                  onChange={e => setFieldValue('title', e.target.value)}
                  disabled={isPublished}
                />
                <Field
                  className={classes.textField}
                  value={values.action_link}
                  placeholder='Main link'
                  fullWidth
                  onChange={e => setFieldValue('action_link', e.target.value)}
                  helperText='Main link for the notification. Other links can be embedded in the body.'
                  disabled={isPublished}
                />
              </ModalSection>
              <ModalSection
                title='Message body'
                subtitle='What question would you like the bot to ask the visitor?'
              >
                <div className={classes.spacingQuill + ' customQuill' + (isPublished ? ' disabled' : '')}>
                  <RichTextEditor
                    message={values.body}
                    onChange={value => setFieldValue('body', value)}
                    placeholder='You now have the ability to create Feature Announcements! See ...'
                    lines={3}
                  />
                </div>
              </ModalSection>
              <ModalSection
                title='Filters'
                subtitle='If you do not include any filters, ALL users will be notified.'
              >
                <div className={classes.filterCtn}>
                  <div className={classes.filterText}>Notify all users with</div>
                  {!values.user_filters.length ? (
                    <EmptyState
                      message='Notifying all users'
                    />
                  ) : (
                    values.user_filters.map((filter, index) => {
                      const lastOne = index === values.user_filters.length - 1

                      const valuesList = filterValuesMap[filter.kind]

                      return (
                        <div key={index}>
                          <div className={classes.filterGroup}>
                            <Select
                              width='40%'
                              value={filter.kind}
                              placeholder='Filter Criteria'
                              onChange={value => setFieldValue(`user_filters[${index}]`, { kind: value, values: [] })}
                              disabled={isPublished}
                              options={
                                filterTypes.map(type => ({ value: type.value, label: type.display }))
                              }
                            />
                            {!valuesList ? <div className={classes.valuesSelect} /> : (
                              <>
                                <div>in this list</div>
                                <div className={classes.valuesSelect}>
                                  <Select
                                    value={filter.values}
                                    onChange={value => setFieldValue(`user_filters[${index}].values`, value)}
                                    multiple
                                    disabled={isPublished}
                                    options={
                                      valuesList.map(value => ({ value: value.value, label: value.display }))
                                    }
                                  />
                                </div>
                              </>
                            )}
                            {isPublished || (
                              <IconButton
                                type='button'
                                style={{ color: '#9933ff' }}
                                onClick={() => {
                                  const newFilters = [...values.user_filters]
                                  newFilters.splice(index, 1)
                                  setFieldValue('user_filters', newFilters)
                                }}
                              >
                                <HighlightOffIcon />
                              </IconButton>
                            )}
                          </div>
                          {!lastOne && <div className={classes.filterText}>AND</div>}
                        </div>
                      )
                    })
                  )}
                  {isPublished || (
                    <Button
                      startIcon={<AddCircleIcon />}
                      type='button'
                      style={{ textTransform: 'none', color: '#9933ff' }}
                      onClick={() => setFieldValue(`user_filters[${values.user_filters.length}]`, { kind: '', values: [] })}
                    >
                      Add Criteria
                    </Button>
                  )}
                </div>
              </ModalSection>
            </Form>
          </Modal>
        )
      }}
    </Formik>
  )
}
