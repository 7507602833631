import { snakeCase } from 'change-case'

export const EQUALS = 'eq'
export const NOT_EQUALS = 'ne'
export const GREATER_THAN = 'gt'
export const GREATER_THAN_OR_EQUAL = 'gte'
export const LESS_THAN = 'lt'
export const LESS_THAN_OR_EQUAL = 'lte'
export const IN = 'in'
export const CONTAINS = 'contains'
export const NOT_CONTAINS = '!contains'
export const IS_NOT = 'is not'
export const IS = 'is'
export const OF_ANY = 'of any'
export const JSONB_CONTAINS = 'jsonb contains'
export const JSONB_INTERSECT = 'jsonb intersect'
export const INCLUDES = 'includes'
export const BETWEEN = 'between'
export const NOT_IN = 'not_in'
export const NOT_INCLUDES = 'not_includes'

export type FilterComp = typeof EQUALS |
  typeof NOT_EQUALS |
  typeof GREATER_THAN |
  typeof GREATER_THAN_OR_EQUAL |
  typeof LESS_THAN |
  typeof LESS_THAN_OR_EQUAL |
  typeof IN |
  typeof CONTAINS |
  typeof NOT_CONTAINS |
  typeof IS_NOT |
  typeof IS |
  typeof OF_ANY |
  typeof JSONB_CONTAINS |
  typeof JSONB_INTERSECT |
  typeof INCLUDES |
  typeof BETWEEN |
  typeof NOT_IN |
  typeof NOT_INCLUDES

const INCLUDE_TO_EXCLUDE: any = {
  [EQUALS]: NOT_EQUALS,
  [CONTAINS]: NOT_CONTAINS,
  [IS]: IS_NOT,
  [IN]: NOT_IN,
  [INCLUDES]: NOT_INCLUDES
}

const EXCLUDE_TO_INCLUDE: any = {
  [NOT_EQUALS]: EQUALS,
  [NOT_CONTAINS]: CONTAINS,
  [IS_NOT]: IS,
  [NOT_IN]: IN,
  [NOT_INCLUDES]: INCLUDES
}

export interface QueryFilter {
  field: string
  operator: FilterComp
  value: any
}

export const EXCLUDEDFILTERCOMP = [NOT_EQUALS, NOT_CONTAINS, IS_NOT, NOT_IN, NOT_INCLUDES]
export const INCLUDEDFILTERCOMP = [EQUALS, CONTAINS, IS, IN, INCLUDES, OF_ANY, JSONB_CONTAINS, JSONB_INTERSECT, BETWEEN, GREATER_THAN, GREATER_THAN_OR_EQUAL, LESS_THAN, LESS_THAN_OR_EQUAL]

export type QueryFilterGroup = QueryFilter[]
export type QueryFilterList = QueryFilter[][]

export function AppendFilters (filters: QueryFilter[][], newFilters: any): QueryFilter[][] {
  let result: QueryFilter[][] = []
  if (filters?.length && newFilters.length) {
    result = filters
    const valueExists = filters.length ? filters[0].filter((filter) => filter.field === newFilters[0][0].field) : null
    if (valueExists && valueExists.length > 0) {
      valueExists[0].value = newFilters[0][0].value
    } else {
      result[0].push(...newFilters[0])
    }
  } else {
    if (newFilters.length) {
      result = [...newFilters]
    } else {
      result = [newFilters]
    }
  }
  return result
}
export function RemoveFilter (filters: QueryFilter[][], filter: string) {
  let newFilters: QueryFilterList = [[]]
  if (filters && filter.length) {
    newFilters = [filters[0].filter((f: QueryFilter) => f.field !== filter)]
  }
  return newFilters
}

export interface SearchParams {
  search?: string
  sortColumn?: string
  sortOrder?: 'asc' | 'desc'
  pageNumber?: number
  pageSize?: number,
  filters?: QueryFilter[][]
  queryParams?: Record<string, string>
  customSort?: string // used for creating order by strings such as name = "ICP", created_timestamp DESC
  extraHeaders?: Record<string, string>
  ids?: Array<any>
}

export const defaultSearchParams: SearchParams = {
  search: '',
  sortColumn: '',
  sortOrder: 'asc',
  pageNumber: 1,
  pageSize: 20,
  filters: [],
  queryParams: {}
}
export interface LoadAllProps {
  searchParams?: SearchParams
}

type ParameterizeProps = {
  path: string
  searchParams: SearchParams
}

export function parameterizeFilters ({ searchParams, path }: ParameterizeProps) {
  const params = new URLSearchParams()
  if (searchParams.search) {
    params.set('search', searchParams.search)
  }
  if (searchParams.sortColumn) {
    const sortColumn = snakeCase(searchParams.sortColumn)
    const sortOrder = searchParams.sortOrder || 'asc'
    params.set(`sort[${sortColumn}]`, sortOrder)
  }
  if (searchParams.pageNumber) {
    const pageSize = searchParams.pageSize || 10
    params.set('page[number]', (searchParams.pageNumber).toString())
    params.set('page[size]', pageSize.toString())
  }
  if (searchParams.ids && searchParams.ids.length > 0) {
    params.set('ids', searchParams.ids.join(','))
  }
  if (searchParams.filters) {
    const filters = JSON.stringify(searchParams.filters)
    params.set('filters', filters)
  }
  if (searchParams.customSort) {
    const custom_sort = JSON.stringify(searchParams.customSort)
    params.set('custom_sort', custom_sort)
  }
  if (searchParams.queryParams) {
    for (const key in searchParams.queryParams) {
      params.set(key, searchParams.queryParams[key])
    }
  }
  if (path.includes('?')) {
    return path + '&' + params.toString()
  }
  return path + '?' + params.toString()
}

export function keyExtraHeaders ({ extraHeaders }: any): string[] {
  const headerKeys = []
  for (const [key, value] of Object.entries(extraHeaders)) {
    headerKeys.push(key + ':' + value)
  }
  return headerKeys
}

export function getOppositeOperator (operator: FilterComp): FilterComp {
  if (INCLUDE_TO_EXCLUDE[operator]) return INCLUDE_TO_EXCLUDE[operator]
  else if (EXCLUDE_TO_INCLUDE[operator]) return EXCLUDE_TO_INCLUDE[operator]
  return operator
}

const operatorDisplay: Record<FilterComp, string> = {
  [EQUALS]: 'Equals',
  [NOT_EQUALS]: 'Does not equal',
  [GREATER_THAN]: '>',
  [GREATER_THAN_OR_EQUAL]: '>=',
  [LESS_THAN]: '<',
  [LESS_THAN_OR_EQUAL]: '<=',
  [IN]: 'In',
  [CONTAINS]: 'Contains',
  [NOT_CONTAINS]: 'Does not contain',
  [IS_NOT]: 'Is not',
  [IS]: 'Is',
  [OF_ANY]: 'Of any',
  [JSONB_CONTAINS]: 'Contains',
  [JSONB_INTERSECT]: 'Contains',
  [INCLUDES]: 'Includes',
  [BETWEEN]: 'Between',
  [NOT_IN]: 'Not in',
  [NOT_INCLUDES]: 'Not includes'
}

export function getFilterDisplay (filter: QueryFilter): string {
  switch (filter.operator) {
    case BETWEEN:
      return `${operatorDisplay[filter.operator]} ${filter.value[0]} and ${filter.value[1]}`
    case IN:
    case NOT_IN:
    case INCLUDES:
    case NOT_INCLUDES:
      return `${operatorDisplay[filter.operator]} ${filter.value.join(', ')}`
    default:
      return `${operatorDisplay[filter.operator]} ${filter.value}`
  }
}
