import GenericObject from './genericObject';
import { useDoQuery } from './useDoQuery';

const path = '/api/integrations/cronofy/status'

export class Cronofy extends GenericObject {
  active: boolean
  elementToken: string
  authConfig: any
  conferenceRedirectURL: string
  conferencingProviderType: string

  static load () {
    return useDoQuery({ path, objectClass: Cronofy })
  }

  constructor ({ row }: { row: Record<string, any> }) {
    super({ row })
    const attributes = row.attributes
    this.active = attributes.active
    this.elementToken = attributes.element_token
    this.authConfig = attributes.auth_config
    this.conferenceRedirectURL = attributes.conference_redirect_url
    this.conferencingProviderType = attributes.conferencing_provider_type
  }
}
