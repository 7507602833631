import sanitizeHTML from 'sanitize-html'
import { makeStyles } from '@material-ui/core/styles'

export const getDisplayName = (contact) => {
  const attributes = contact.attributes
  let name
  if (attributes.first_name && attributes.last_name) {
    name = attributes.first_name + ' ' + attributes.last_name
  } else if (attributes.first_name) {
    name = attributes.first_name
  } else if (attributes.last_name) {
    name = attributes.last_name
  } else if (attributes.email) {
    name = attributes.email.split('@')[0]
  } else {
    name = 'Site Visitor'
  }
  return name
}

function getStyle ({ style, status, hover, active, selected }) {
  let bgColor = status === 'closed' ? '#EEF1F1' : '#FFF'
  if (hover) {
    bgColor = '#D5D5D5'
  }
  if (active || selected) {
    bgColor = '#4A4A4A'
  }

  const rowHeight = 86

  const myStyle = { ...style, backgroundColor: bgColor, height: rowHeight }

  const textColor = active || selected ? '#FFF' : '#3B3B3B'

  return { myStyle, textColor }
}

function getBody (conversation) {
  const body = conversation.attributes.last_message
  const sanitizedLastBody = sanitizeHTML(body, {
    allowedTags: [],
    allowedAttributes: []
  })
  return sanitizedLastBody
}

function getDisplayColor (msgTime, read, status) {
  const now = new Date()
  const diffMs = now - new Date(msgTime)
  const minutes = diffMs / 60000

  let color = '#7C7C7C'

  if (minutes < 0.5) {
    color = '#EF8849'
  } else if (minutes > 0.5 && !read && status === 'open') {
    color = '#FF5D5C'
  }

  return color
}

function getVisitorInfo (conversation) {
  const a = conversation.attributes
  const visitorID = conversation.relationships.for_participant.data.id || 0
  let visitorName = 'Site Visitor'
  if (a.name || a.email) {
    visitorName = a.name || a.email
  }
  const visitorInfo = a.company_name || a.location
  return { visitorName, visitorInfo, visitorID }
}

const conversationItemStyles = makeStyles(theme => ({
  convoDiv: {
    cursor: 'pointer',
    paddingRight: 5,
    display: 'grid',
    gridTemplateColumns: '20px auto',
    gridTemplateRows: 'auto auto',
    gridTemplateAreas: `"flags info"
      "flags message"`,
    borderBottom: 'solid rgba(0, 0, 0, .125) 2px',
    height: 'calc(100% - 2px)'
  },
  flagsDiv: {
    gridArea: 'flags',
    display: 'flex',
    justifyContent: 'center',
    paddingRight: 8
  },
  infoDiv: {
    gridArea: 'info',
    paddingTop: '3%',
    paddingBottom: '3%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  messageDiv: {
    gridArea: 'message',
    fontSize: '.9em',
    color: '#3B3B3B',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    paddingBottom: '3%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  messageBody: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    marginTop: 1,
    height: 'max-content'
  },
  dot: {
    height: 7,
    width: 7,
    borderRadius: '50%',
    display: 'inline-block'
  }
}))

const contactStyles = makeStyles(theme => ({
  contactDiv: {
    display: 'flex',
    width: '100%'
  },
  contactInfo: {
    paddingLeft: 10,
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'auto 60px',
    gridTemplateRows: 'auto auto',
    gridGap: 2,
    justifyContent: 'space-between',
    gridTemplateAreas:
      `"name time"
    "company score"`
  },
  contactName: {
    fontWeight: 600,
    fontSize: '1em',
    paddingBottom: 2,
    gridArea: 'name',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  contactCompany: {
    fontWeight: 500,
    fontSize: '.9em',
    gridArea: 'company',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  time: {
    fontSize: '.9em',
    color: '#7C7C7C',
    gridArea: 'time',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  reviewed: {
    fontSize: '.9em',
    color: '#7C7C7C',
    display: 'flex'
  }
}))

export { getStyle, getBody, getDisplayColor, conversationItemStyles, contactStyles, getVisitorInfo }
