
const descriptionText = 'The Live Chat Node determines when and to whom your bot will route conversations with web visitors. If you use the chat feature with your bots, you’ll use the Live Chat Node!'

const Description = (): JSX.Element => {
  return (
    <div>
      <div>
        {descriptionText}
      </div>
      {/* <ul>
        <li>whatever we want</li>
        <li>to put in a list</li>
      </ul> */}
    </div>
  )
}

const bestPracticeText = 'If an agent gets routed into a conversation, the bot flow will end. If an agent doesn\'t get routed in, the bot can take two different paths noted below. Make sure to configure these as desired.'

const BestPractices = () => {
  return (
    <div>
      <div>
        {bestPracticeText}
      </div>
      <ul>
        <li>No one available</li>
        <li>No one responds: The bot will move onto this path after waiting the amount of time you chose within the settings.</li>
      </ul>
    </div>
  )
}

export const LiveChatDocs = {
  helpCenterLink: 'https://help.getsignals.ai/article/5h92pvqniu-live-chat-node',
  bestPractices: BestPractices,
  description: Description,
  videoLink: ''
}
