import { useState, MouseEventHandler } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import Popover from '@material-ui/core/Popover'
import IconButton from '@material-ui/core/IconButton'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { MaterialIcon, Color } from 'cf-components/common-types/material-ui'
import './components.css'
import { Tooltip } from 'library/materialUI'
import { Icon, IconType } from 'library/materialUI/Icon'

const useStyles = makeStyles(theme => ({
  popoverRoot: {
    overflow: 'visible',
    marginTop: 30,
    backgroundColor: 'rgba(0,0,0,0)',
    boxShadow: 'none'
  },
  menuItem: {
    display: 'grid',
    gridTemplateColumns: 'auto 30px',
    gridGap: 25,
    width: '100%',
    alignItems: 'center'
  }
}))

export interface ActionsMenuProps {
  actions: ActionsMenuAction[]
  className?: string
  hover?: boolean
  color?: Color
  iconHeight?: number
  iconWidth?: number
  onClose?: () => void
  onClick?: () => void
  mouseLeave?: boolean
}

export interface ActionsMenuAction {
  name: string
  action: (row: any) => void
  icon: MaterialIcon | IconType
  disabled?: boolean
  color?: string
  specificSize?: number | string
  tooltip?: string | ((row: any) => string)
}

export default function ActionsMenu (props: any): JSX.Element {
  const [anchorEl, setAnchorEl] = useState<EventTarget & HTMLButtonElement | null>(null)
  const open = Boolean(anchorEl)
  const classes = useStyles()
  const icon = props.icon || <MoreVertIcon />

  const handleDivClick = (e: Event): void => {
    if (!e) e = window.event as Event
    e.cancelBubble = true
    if (e.stopPropagation) e.stopPropagation()
    e.preventDefault()
  }

  const handleClick: MouseEventHandler<HTMLButtonElement> = (event): void => {
    if (props.onClick) {
      props.onClick()
    }
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (): void => {
    setAnchorEl(null)
    if (props.onClose) {
      props.onClose()
    }
  }

  if (props.hover === false) {
    return <></>
  }

  const getMenuItem = (row: any, action: Record<any, any>, index: number) => {
    const icon = action.icon && (typeof action.icon === 'string' ? <Icon icon={action.icon} color={action.color} specificSize={action.specificSize || '.9em'} /> : <action.icon style={{ marginRight: 10, fill: action.color || '#8E5AE2', height: '.9em', width: '.9em' }} />)
    const display = typeof action.display === 'function' ? action.display(row) : typeof action.display === 'boolean' ? action.display : true
    const disabled = typeof action.disabled === 'function' ? action.disabled(row) : typeof action.disabled === 'boolean' ? action.disabled : false
    const title = typeof action.tooltip === 'function' ? action.tooltip(row) : action.tooltip
    const menuItem = display
    ? (
      <Tooltip key={index} title={title || ''}>
        <div>
          <MenuItem button onClick={() => { action.action(row); handleClose() }} disabled={disabled}>
            <div className={classes.menuItem}>
              {action.name} {icon}
            </div>
          </MenuItem>
        </div>
      </Tooltip>
    )
    : <></>

    return menuItem
  }

  if (props.actions.length === 1) {
    const action = props.actions[0]
    const actionIcon = action.icon && (typeof action.icon === 'string' ? <Icon icon={action.icon} color={action.color} /> : <action.icon style={{ marginRight: 10, fill: action.color || '#8E5AE2', height: '.9em', width: '.9em' }} />)
    return (
      <Tooltip title={action.icon ? action.name : ''}>
        <div>
          <IconButton style={{ height: 40, width: 40 }} onClick={() => action.action(props.row)} color={props.color}>
            {actionIcon || action.name}
          </IconButton>
        </div>
      </Tooltip>
    )
  }

  return (
    <div onClick={e => handleDivClick(e as unknown as Event)}>
      <IconButton style={{ height: props.iconHeight || 40, width: props.iconWidth || 40 }} onClick={handleClick} color={props.color}>
        {icon}
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        classes={{ paper: classes.popoverRoot }}
        onMouseLeave={() => handleClose()}
      >
        <div className='triangle' />
        <div className='actions-menu'>
          {props.actions.map((action: any, index: number) => (
            getMenuItem(props.row, action, index)
          ))}
        </div>
      </Popover>
    </div>
  )
}
