import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { parseISO } from 'date-fns'
import { Avatar } from 'cf-components/Avatars'
import dateFormat from 'dateformat'
import DateRangeIcon from '@material-ui/icons/DateRange'
import CalendarCompleteIcon from '@material-ui/icons/EventAvailable'

const useStyles = makeStyles(theme => ({
  dropDetails: {
    width: 'calc(100% - 20px)',
    minHeight: 45,
    padding: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center'
  },
  dropTitle: {
    display: 'flex',
    alignItems: 'center'
  },
  completeDetails: {
    padding: 10
  },
  dropCard: {
    border: '2px solid rgba(0,0,0,0.5)',
    width: 230,
    borderRadius: 10,
    textAlign: 'center',
    padding: 10,
    fontSize: '14px'
  },
  dropCompleteDate: {
    marginTop: 5,
    marginBottom: 5
  },
  agentAvatarDiv: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 8
  },
  outerDrop: {
    width: 280,
    backgroundColor: 'white',
    boxShadow: '0px 0px 7px 0px rgba(0,0,0,0.68)',
    borderRadius: 10,
    marginBottom: 20,
    marginLeft: 60
  },
  dropHeader: {
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 10,
    paddingRight: 10,
    height: 40,
    justifyContent: 'space-between'
  },
  icon: {
    fontSize: '22px'
  },
  dropCompleteTime: {
    fontSize: '12px'
  },
  booked: {
    backgroundColor: theme.palette.calendarDrop.booked
  },
  notBooked: {
    backgroundColor: theme.palette.calendarDrop.notBooked
  }
}))

function CalendarDrop (props) {
  const classes = useStyles()
  const state = props.state
  const events = state.events
  const calendarDrop = props.group.events.calendar[0]
  const data = calendarDrop.attributes.data
  const duration = data.duration
  const meetingType = data.meeting_type

  const completedDrops = useMemo(() => {
    return events.filter(event => {
      return event.attributes.kind === 'drop_complete'
    }).reduce((map, event) => {
      map[event.attributes.data.drop_id] = event
      return map
    }, {})
  }, [events])

  const getDropHeader = () => {
    if (completeEvent) {
      return `${classes.dropHeader} ${classes.booked}`
    } else {
      return `${classes.dropHeader} ${classes.notBooked}`
    }
  }

  const agentName = data.agent_name || 'you'

  const completeEvent = completedDrops[calendarDrop.id]

  if (completeEvent) {
    const startDate = parseISO(completeEvent.attributes.data.start)
    const endDate = parseISO(completeEvent.attributes.data.end)
    const completeDate = dateFormat(startDate, 'dddd, mmmm dS')
    const startTime = dateFormat(startDate, 'shortTime')
    const endTime = dateFormat(endDate, 'h:MM TT Z')
    return (
      <div className={classes.outerDrop}>
        <div className={getDropHeader()}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <CalendarCompleteIcon className={classes.icon} />
            <span style={{ paddingLeft: 10 }}>{meetingType} Scheduled!</span>
          </div>
        </div>
        <div className={classes.completeDetails}>
          <div className={classes.dropCard}>
            <div className={classes.agentAvatarDiv}>
              <Avatar
                avatarUrl={data.avatar_url}
                size='md'
              />
            </div>
            <div>Meeting with {agentName}</div>
            <div className={classes.dropCompleteDate}>{completeDate}</div>
            <div className={classes.dropCompleteTime}>{startTime} - {endTime}</div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={classes.outerDrop}>
      <div className={getDropHeader()}>
        <div className={classes.dropTitle}>
          <DateRangeIcon className={classes.icon} />
          <span style={{ paddingLeft: 10 }}>{meetingType}</span>
        </div>
        <div>
          {duration} min
        </div>
      </div>
      <div className={classes.dropDetails}>
        <div className={classes.dropCard}>
          <div className={classes.agentAvatarDiv}>
            <Avatar
              avatarUrl={data.avatar_url}
              size='md'
            />
          </div>
          <div>
            Calendar shown for {data.name}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CalendarDrop
