import { Theme, makeStyles } from '@material-ui/core/styles'
import { Accordion, AccordionDetails, AccordionSummary } from 'cf-components/Accordion'
import { Typography, Icon } from 'library/materialUI'
import { useState } from 'react'

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    margin: '15px'
  },
  title: {
    display: 'flex',
    height: '50px',
    padding: '0 30px',
    alignItems: 'center',
    backgroundColor: '#D3D3D3',
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
    border: 'none'
  },
  accordionStyle: {
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px'
  },
  accordionOpen: {
    borderRadius: '10px'
  },
  disabledStyle: {
    backgroundColor: '#F5F5F5'
  },
  cardBody: {
    padding: '10px 30px',
    border: '1px solid #D3D3D3',
    borderBottomLeftRadius: '10px',
    borderBottomRightRadius: '10px'
  }
}))

interface StyleCardProps {
  title: string
  children: React.ReactNode
  disabled?: boolean
  defaultExpanded?: boolean
}

export default function StyleCard ({ title, children, disabled, defaultExpanded }: StyleCardProps): JSX.Element {
  const classes = useStyles()
  const [expanded, setExpanded] = useState<boolean>(defaultExpanded || false)
  return (
    <div className={classes.card}>
      <Accordion
        classes={{
          root: classes.accordionStyle,
          expanded: classes.accordionOpen,
          disabled: classes.disabledStyle
        }}
        square
        expanded={expanded && !disabled}
        disabled={disabled}
      >
        <AccordionSummary
          className={classes.title}
          expandIcon={<Icon icon='expand' />}
          onClick={() => setExpanded(!expanded)}
        >
          <Typography variant='h1'>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.cardBody}>
          {children}
        </AccordionDetails>
      </Accordion>
    </div>
  )
}
