/* eslint-disable @typescript-eslint/naming-convention */
import { Filter, Segment, SegmentFilter, SegmentFilterValue } from 'classes/segments';
import { createContext, Dispatch, SetStateAction, useMemo, useState } from 'react';
import { cloneDeep, values } from 'lodash'
import { makeStyles } from '@material-ui/core/styles';
import { filterSelectorMapping } from './SegmentCriteriaButton';
import { formatRevenue } from 'helpers/numbers';
import { Icon } from 'library/materialUI/Icon';
import { User } from 'classes/users';

const useStyles = makeStyles(theme => ({
  filterBtn: {
    backgroundColor: '#EDE5F8',
    fontSize: '13px',
    width: 'fit-content',
    maxWidth: 550,
    padding: '5px 10px',
    borderRadius: 5,
    marginRight: 2,
    cursor: 'pointer',
    display: 'f20lex',
    alignItems: 'center',
    marginTop: 5,
    color: theme.palette.primary.main
  },
  addBtn: {
    backgroundColor: '#EDE5F8',
    fontSize: 20,
    minWidth: 38,
    maxWidth: 38,
    height: '65%',
    padding: '5px 10px',
    borderRadius: 5,
    cursor: 'pointer',
    marginTop: 5,
    color: theme.palette.primary.main
  },
  andText: {
    backgroundColor: '#EDE5F8',
    fontSize: 15,
    minWidth: 38,
    minHeight: 33,
    padding: '0px 2px',
    borderRadius: 5,
    cursor: 'pointer',
    marginTop: 5,
    color: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center'
  }
}))

interface DynamicSegmentContextInterface {
  triggers: Segment['triggers']
  crmOnly: boolean
  saveTriggers: (triggers: Segment['triggers']) => void
  editFilter: (idx: number, filter: any) => void
  determineActionButton: (filters: Filter[], currentIndex: number) => JSX.Element
  handleDeleteTrigger: (index: number) => void
  getStartingBtnValue: (segmentTrigger: Filter) => void
  changeCRMOnly: (crmOnly: boolean) => void
  setTriggers: Dispatch<SetStateAction<SegmentFilter[]>>
  setAlternateLabels: (alternateLabels: string[]) => void
  setAlternateProps: (alternateProps: string[]) => void
  resetTriggers: () => void
}

export const DynamicSegmentContext = createContext<DynamicSegmentContextInterface>({
  triggers: [{ filters: [] }],
  crmOnly: false,
  saveTriggers: () => undefined,
  editFilter: () => undefined,
  determineActionButton: () => <></>,
  handleDeleteTrigger: () => undefined,
  getStartingBtnValue: () => undefined,
  changeCRMOnly: () => undefined,
  setTriggers: () => undefined,
  setAlternateLabels: () => undefined,
  setAlternateProps: () => undefined,
  resetTriggers: () => undefined
})

interface DynamicSegmentProviderProps {
  segment: Segment
  save: (values: {
    triggers: Segment['triggers']
  }) => void
  children: JSX.Element | JSX.Element[]
  initialSegment?: Segment
}

const compareMapping: Record<string, string> = {
  not_in: 'is not',
  eq: 'is',
  in: 'is',
  ne: 'is not',
  gt: 'is greater than',
  lt: 'is less than',
  'jsonb intersect': 'includes',
  'jsonb contains': 'contains'
}

const isLast = (idx: number, crmIdx: number, length: number) => {
  if (crmIdx === length - 1) {
    return idx === length - 2
  }
  return idx === length - 1
}

const crmIndex = (filters: Filter[]) => {
  return filters.findIndex((f) => f.prop === 'is_crm_account')
}

const generateLabel = (cmp: string, value: SegmentFilterValue, prop: string, users: User[], alternateLabels: string[] = [], alternateProps: string[] = []) => {
  let label = prop
  if (label.includes('crm_txt_field') || label.includes('crm_num_field')) {
    alternateProps.forEach((_, index) => {
      if (prop === alternateProps[index]) {
        label = alternateLabels[index]
      }
    })
  } else {
    Object.keys(filterSelectorMapping).forEach((k) => {
      if (prop === filterSelectorMapping[k].filter.prop) {
        label = filterSelectorMapping[k].option.label
      }
    })
  }
  const prefix = label.replace(/([A-Z])/g, ' $1').replace(/^./, function (str: string) { return str.toUpperCase(); })
  const compareText = cmp in compareMapping ? compareMapping[cmp] : cmp
  let btnLabel = prefix + ' ' + compareText + ' '

  if (Array.isArray(value)) {
    if (cmp === 'between') {
      value.map((string: any, index: number) => {
        if (index <= 1) {
          let newString = string
          if (prop === 'annual_revenue') { newString = formatRevenue(string).toString() }
          btnLabel = btnLabel + newString + ' and '
        } return null
      })
    } else if (prop === 'owner_user_id') {
      const selectedUserNames: string[] = []
      users.map(user => { if ((value as number[]).includes(user.id as number)) { selectedUserNames.push(user.name) } return null })
      let userString = ''
      selectedUserNames.map((userName, index) => { if (index < 2) { userString = userString.concat(userName + ' or ') } return null })
      btnLabel = btnLabel + userString
    } else if (cmp === 'jsonb contains') {
      value.map((string: any, index: number) => { if (index <= 1) { btnLabel = btnLabel + string + ' and ' } return null })
    } else {
      value.map((string: any, index: number) => { if (index <= 1) { btnLabel = btnLabel + string + ' or ' } return null })
    }
    btnLabel = btnLabel.slice(0, -4)
    if (value.length > 2) {
      const moreText = ` and ${value.length - 2} more`
      btnLabel = btnLabel + moreText
    }
  } else if (prop === 'number') {
    const valueToStr = (!!value).toString()
    btnLabel = btnLabel + valueToStr
  } else if (prop === 'is_open_opportunity') {
    if (cmp === 'eq') {
      btnLabel = prefix + ' IS TRUE'
    } else {
      btnLabel = prefix + ' IS FALSE'
    }
  } else {
    btnLabel = btnLabel + value
  }
  return btnLabel
}

export function DynamicSegmentProvider ({ segment, save, children, initialSegment }: DynamicSegmentProviderProps) {
  const [triggers, setTriggers] = useState(cloneDeep(segment.triggers))
  const [alternateLabels, setAlternateLabels] = useState<string[]>([])
  const [alternateProps, setAlternateProps] = useState<string[]>([])
  const classes = useStyles()
  const { data: users } = User.loadAll()

  useMemo(() => {
    const filters = triggers[0].filters
    if (filters.length === 0 || (filters.length === 1 && filters[0].prop === 'is_crm_account')) {
      filters.push({ cmp: '', prop: '', value: '' })
    }
  }, [triggers])

  let crmOnly = false
  segment.triggers[0].filters.forEach((f) => {
    if (f.prop === 'is_crm_account' && f.value === 1) {
      crmOnly = true
    }
  })

  const changeCRMOnly = (crmOnly: boolean) => {
    let found = false
    const cpy = [...segment.triggers]
    cpy[0].filters.forEach((f, idx) => {
      if (f.prop === 'is_crm_account') {
        found = true
        if (crmOnly) {
          f.value = 1
        } else {
          cpy[0].filters.splice(idx, 1)
        }
      }
    })
    if (!found && crmOnly) {
      cpy[0].filters.push({ prop: 'is_crm_account', cmp: 'is', value: 1 })
    }
    setTriggers(cpy)
  }

  const andLabel = (
    <div className={classes.andText}>and</div>
  )

  const getStartingBtnValue = (segmentTrigger: Filter) => {
    if (segmentTrigger.cmp !== '') {
      return { text: generateLabel(segmentTrigger.cmp, segmentTrigger.value, segmentTrigger.prop, users.list, alternateLabels, alternateProps), startIcon: null, endIcon: <Icon icon='close' />, class: classes.filterBtn }
    }
    return { text: 'ADD FILTER CRITERIA', startIcon: <Icon icon='add' color='primary' />, endIcon: null, class: classes.filterBtn }
  }

  const handleAddTrigger = () => {
    const newTriggers = [...triggers]
    newTriggers[0].filters.push({ cmp: '', value: '', prop: '' })
    setTriggers(newTriggers)
  }

  const saveTriggers = (triggers: Segment['triggers']) => {
    save({ triggers: triggers })
  }

  const handleEditTrigger = (index: number, filter: Filter) => {
    const newTriggers = [...triggers]
    newTriggers[0].filters[index] = filter
    setTriggers(newTriggers)
  }

  const handleDeleteTrigger = (index: number) => {
    const newTriggers = [...triggers]
    newTriggers[0].filters.splice(index, 1)
    newTriggers[0].filters = [...newTriggers[0].filters]
    setTriggers(newTriggers)
  }

  const editFilter = (idx: number, filter: any) => {
    handleEditTrigger(idx, { cmp: filter.cmp, value: filter.value, prop: filter.prop })
  }

  const determineActionButton = (filters: Filter[], currentIndex: number) => {
    const isLastElement = isLast(currentIndex, crmIndex(filters), filters.length)
    const filterIsGood = !values(filters[currentIndex]).includes('')
    const nextFilterIsGood = isLastElement ? false : !values(filters[currentIndex + 1]).includes('')
    if (filterIsGood && isLastElement) {
      handleAddTrigger();
    }
    if (!isLastElement && nextFilterIsGood) {
      return andLabel
    }

    return <></>
  }

  const resetTriggers = () => {
    if (initialSegment) {
      setTriggers(cloneDeep(initialSegment.triggers) || [{ filters: [] }])
    }
  }

  const contextValues: DynamicSegmentContextInterface = {
    triggers,
    crmOnly,
    saveTriggers,
    editFilter,
    determineActionButton,
    handleDeleteTrigger,
    getStartingBtnValue,
    changeCRMOnly,
    setTriggers,
    setAlternateLabels,
    setAlternateProps,
    resetTriggers
  }

  return (
    <DynamicSegmentContext.Provider value={contextValues}>
      {children}
    </DynamicSegmentContext.Provider>
  )
}
