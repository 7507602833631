import { useState, useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { green } from '@material-ui/core/colors'
import CircularProgress from '@material-ui/core/CircularProgress'
import clsx from 'clsx'
import Fab from '@material-ui/core/Fab'
import CheckIcon from '@material-ui/icons/Check'
import SaveIcon from '@material-ui/icons/Save'
import { ReactComponent as PublishIcon } from 'img/rocket_launch.svg'
import Tooltip from '@material-ui/core/Tooltip'
import { Emitter, EVENT_TYPE } from 'emitter'
import UnsavedChangesHistoryModal from './UnsavedChangesHistoryModal'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'relative',
    color: theme.palette.primary.main
  },
  buttonSuccess: {
    height: 40,
    minWidth: 156,
    minHeight: 0,
    backgroundColor: green[500],
    borderRadius: '50px',
    color: 'white',
    '&:hover': {
      backgroundColor: green[700]
    }
  },
  button: {
    backgroundColor: 'white',
    height: 40,
    minWidth: 156,
    minHeight: 0,
    borderRadius: '50px'
  },
  fabProgress: {
    color: green[500],
    position: 'absolute',
    top: 8,
    left: 30,
    zIndex: 1
  }
}))

type CircularButtonProps = {
  label?: string
  showLabel?: boolean
  icon: any
  action: (event: any) => void
  disabled?: boolean
}

function Button (props: any): JSX.Element {
  const classes = useStyles()
  const Icon = props.icon

  return (
    <div className={classes.wrapper}>
      <Fab
        className={classes.button}
        onClick={props.action}
        disabled={props.disabled}
        size='small'
        color='inherit'
      >
        <Icon style={{ marginLeft: '5px', marginRight: '5px', color: '#9933FF' }} /><div style={{ fontFamily: 'poppins', fontSize: '14px', textTransform: 'capitalize' }}>{props.showLabel && props.label}</div>
      </Fab>
    </div>
  )
}

export function CircularButton (props: CircularButtonProps): JSX.Element {
  if (props.label) {
    return (
      <Tooltip title={props.label}>
        <div>
          <Button {...props} />
        </div>
      </Tooltip>
    )
  } else {
    return (
      <Button {...props} />
    )
  }
}

type LoadingButtonProps = {
  disabled?: boolean
  save: any
  variant?: 'publish'
  title?: string
  handleValidation?: (target: any) => boolean
  history?: any
}

export function SaveButton (props: LoadingButtonProps): JSX.Element {
  const classes = useStyles()
  const [state, setState] = useState({ loading: false, success: false })
  const loadingRef = useRef(false)
  loadingRef.current = state.loading
  const { disabled, save } = props
  const saveButtonRef = useRef(null)

  const buttonClassname = clsx({
    [classes.buttonSuccess]: state.success,
    [classes.button]: !state.success
  })

  const handleButtonClick = (event: any): void => {
    let valid = true
    if (props.handleValidation) {
      valid = props.handleValidation(saveButtonRef.current)
    }
    if (valid && !state.loading) {
      setState({ loading: true, success: false })
      save(event)
    }
  }

  useEffect(() => {
    Emitter.on(EVENT_TYPE.DATA_CHANGED, () => {
      if (loadingRef.current) {
        setTimeout(() => {
          setState({ loading: false, success: true })
        }, 1000)
        setTimeout(() => {
          setState({ loading: false, success: false })
        }, 5000)
      }
    })
  }, [])

  let Icon = SaveIcon
  if (props.variant === 'publish') {
    Icon = PublishIcon
  }

  return (
    <div className={classes.wrapper} id={`${props.variant}-button`} ref={saveButtonRef}>
      <Fab
        className={buttonClassname}
        onClick={handleButtonClick}
        disabled={disabled && !state.success && !state.loading}
        size='small'
        color='inherit'
      >
        {state.success ? <CheckIcon /> : (state.loading ? <CircularProgress size={25} className={classes.fabProgress} /> : <Icon style={{ color: disabled ? '#808080' : '#9933FF' }} />)} <div style={{ marginLeft: '5px', textTransform: 'capitalize' }}>{props.title || 'Publish'}</div>
      </Fab>
      {props.history ? (
        <UnsavedChangesHistoryModal
          save={handleButtonClick}
          disabled={disabled}
          validPaths={['#######']}
          history={props.history}
        />)
        : <></>}
    </div>
  )
}
