/* eslint-disable @typescript-eslint/naming-convention */
import { CSV } from 'classes/csv'
import { CSVFile } from 'components/CSVs/CSVFile'
import CSVHeaderStep from 'components/CSVs/CSVHeaderStep'
import CSVUploadStep from 'components/CSVs/CSVUploadStep'
import Loader from 'library/loading/Loader'
import { Modal, ModalSize } from 'library/Modal'
import ConfirmationModal, { ConfirmationModalProps } from 'library/modals/ConfirmationModal'
import { CSVStep } from 'library/segments/CSVUploadModal'
import { useReducer, useState } from 'react'

interface PersonaCSVUploadProps {
  handleGoBack: () => void
  closeCSVModal: () => void
  open: boolean
  setTitles: (values: Record<string, string[]>) => void
}

const initialState: CSVFile = {
  file: '',
  headers: [],
  row_count: 0,
  staged_import_id: 0,
  headerMapping: { titles: { value: '', required: false }, excludedTitles: { value: '', required: false } },
  rows: []
}

const reducer = (state: CSVFile, changes: any) => {
  return { ...state, ...changes }
}

export default function PersonaCSVUploadModal ({ handleGoBack, closeCSVModal, open, setTitles }: PersonaCSVUploadProps) {
  const [CSVTraversal, setCSVTraversal] = useState<CSVStep>('Upload')
  const [disableNextBtn, setDisableNextBtn] = useState(true)
  const [CSVFileAttributes, dispatch] = useReducer(reducer, initialState)
  const [confirmationModalProps, setConfirmationModalProps] = useState<ConfirmationModalProps | undefined>(undefined)

  const handleClose = () => {
    setCSVTraversal('Upload')
    setConfirmationModalProps(undefined)
    closeCSVModal()
  }

  async function apiStepMapping (step: CSVStep) {
    let CSVFunction = null
    if (step === 'Headers') {
      setConfirmationModalProps({
        title: 'Getting title data from your CSV',
        subtitle: '',
        dynamicButtons: [],
        children: (
          <div style={{ height: '100%', width: '100%' }}>
            <Loader
              type='spinner'
              size='md'
              title={['You can still remove edit your imported titles after we finish', 'You can be more specific by adding parenthesis around a title', 'Still loading...', 'Make sure your persona is not too broad', 'You can use your new persona in PlayMaker Contact Lookup!']}
            />
          </div>
        )
      })
      CSVFunction = CSV.stage
    }
    if (typeof CSVFunction === 'function') {
      const { data: { attributes: newCSVAttributes } } = await CSVFunction(CSVFileAttributes)
      dispatch(newCSVAttributes)
      setConfirmationModalProps(undefined)
    }
  }

  async function handleCSVTraversal (step: CSVStep) {
    apiStepMapping(step)
    setDisableNextBtn(true)
    setCSVTraversal(step)
  }

  async function handleSubmit () {
    setConfirmationModalProps({
      title: 'Importing your titles',
      subtitle: '',
      dynamicButtons: [],
      children: (
        <div style={{ height: '100%', width: '100%' }}>
          <Loader
            type='spinner'
            size='md'
            title={['You can still remove edit your imported titles after we finish', 'You can be more specific by adding parenthesis around a title', 'Still loading...', 'Make sure your persona is not too broad', 'You can use your new persona in PlayMaker Contact Lookup!']}
          />
        </div>
      )
    })
    CSV.finalize(CSVFileAttributes)
      .then((response: any) => {
        setTitles(response.data.attributes.columns)
        handleClose()
      }).catch((_: Error) => {
        handleClose()
      })
  }

  if (CSVTraversal === 'Leave Upload') {
    handleGoBack()
    return (<></>)
  }

  const buttonProps = {
    Upload: { nextText: '', nextAction: () => handleCSVTraversal('Headers'), backAction: () => handleCSVTraversal('Leave Upload') },
    Headers: { nextText: 'Upload', nextAction: () => handleSubmit(), backAction: () => handleCSVTraversal('Upload') }
  }

  const { nextText, nextAction, backAction } = buttonProps[CSVTraversal]

  const stepMapping: Record<string, { Component: JSX.Element, modalSize?: ModalSize }> = {
    Upload: {
      Component: (
        <CSVUploadStep
          CSVFile={CSVFileAttributes}
          setCSVFile={dispatch}
          setDisableNextBtn={setDisableNextBtn}
          infoMessage='Your upload must include a column containing job titles'
          subtitle='Upload a CSV containing the titles you want to add to your persona.'
          extraInfo='For an exact match, make sure your title is in quotes. Example: “Marketing Manager”'
        />
      )
    },
    Headers: {
      Component: (
        <CSVHeaderStep
          CSVFile={CSVFileAttributes}
          setCSVFile={dispatch}
          setDisableNextBtn={setDisableNextBtn}
          mapping={[
            { type: 'segment', data_type: 'string', value: 'Titles to include' },
            { type: 'segment', data_type: 'string', value: 'Titles to exclude' }
          ]}
        />
      )
    }
  }

  const { Component, modalSize } = stepMapping[CSVTraversal]

  return (
    <>
      {confirmationModalProps &&
        <ConfirmationModal
          {...confirmationModalProps}
        />}
      <Modal
        open={open}
        size={modalSize}
        onHide={() => handleClose()}
        title='CSV Import'
        handleSave={nextAction}
        saveDisabled={disableNextBtn}
        saveBtnText={nextText || 'Next'}
        cancelBtnText='Go back'
        closeBtnFunction={backAction}
        key={1 + 'personaModal'}
        saveIcon={nextText === 'Upload' ? 'upload' : undefined}
      >
        {Component}
      </Modal>
    </>
  )
}
