import React from 'react'
import { Modal, ModalSection } from 'library/Modal'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { Tooltip } from 'library/materialUI/Tooltip'
import { Checkbox, RadioButtons } from 'library/materialUI'

const useStyles = makeStyles((theme) => ({
  meetingForm: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column'
  },
  field: {
    marginBottom: 20
  },
  formControl: {
    '& label': {
      backgroundColor: 'white'
    }
  },
  invalidInput: {
    color: 'red',
    fontSize: 12,
    marginTop: '-15px',
    marginBottom: 15
  },
  textField: {
    cursor: 'text',
    width: '100%'
  },
  groupContainer: {
    backgroundColor: 'rgba(193, 166, 237, 0.1)',
    padding: 10,
    borderRadius: 10,
    marginBottom: 20
  },
  subtitle1: {
    fontSize: 18,
    fontWeight: 600
  },
  bodyText: {
    marginBottom: 10,
    fontSize: '14px'
  },
  menuHeader: {
    borderBottom: '1px solid #BBB',
    width: '100%',
    fontWeight: 600,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: 'rgba(0,0,0,0.87)'
  }
}))

const reminderTimes = [
  { value: 0, name: 'No Reminder' },
  { value: 15, name: '15 minutes before' },
  { value: 30, name: '30 minutes before' },
  { value: 60, name: '1 hour before' },
  { value: 120, name: '2 hours before' },
  { value: 720, name: '12 hours before' },
  { value: 1440, name: '1 day before' }
]

const leadTimes = [
  { value: 0, name: 'No Lead Time' },
  { value: 15, name: '15 minutes' },
  { value: 30, name: '30 minutes' },
  { value: 45, name: '45 minutes' },
  { value: 60, name: '60 minutes' },
  { value: 90, name: '90 minutes' },
  { value: 120, name: '2 hours' },
  { value: 180, name: '3 hours' },
  { value: 240, name: '4 hours' },
  { value: 300, name: '5 hours' },
  { value: 360, name: '6 hours' },
  { value: 420, name: '7 hours' },
  { value: 480, name: '8 hours' },
  { value: 720, name: '12 hours' },
  { value: 1440, name: '24 hours' },
  { value: 2880, name: '48 hours' }

]

const durationTimes = [
  { value: 15, name: '15 minutes' },
  { value: 30, name: '30 minutes' },
  { value: 45, name: '45 minutes' },
  { value: 60, name: '60 minutes' }
]

const MeetingTypesModal = (props) => {
  const meetingType = props.meetingType
  const isManager = props.myUser.attributes.perms.manager
  const classes = useStyles()
  const checkboxText = 'Allow use on agent profile pages.'

  return (
    <Modal
      title='Meeting Type'
      onHide={() => {
        props.onHide()
      }}
      open={props.open}
      handleSave={props.onSubmit}
      saveIcon='add'
      saveBtnText='Create Meeting'
    >
      <ModalSection
        title='Meeting Name'
        subtitle='Enter a name for your meeting. This will be visible to visitors.'
      >
        <TextField
          className={classes.textField}
          margin='dense'
          color='primary'
          variant='outlined'
          type='text'
          value={meetingType.attributes.name}
          placeholder='Meeting name'
          onChange={(e) =>
            props.handleChange({ type: 'name', data: e.target.value })}
        />
      </ModalSection>
      <ModalSection
        title='Meeting Duration'
        subtitle='Select the default length of this meeting'
      >
        <Select
          name='length_in_minutes'
          margin='dense'
          variant='outlined'
          fullWidth
          defaultValue='preview'
          className={classes.select}
          value={meetingType.attributes.length_in_minutes}
          onChange={(duration) =>
            props.handleChange({
              type: 'length_in_minutes',
              data: duration.target.value
            })}
        >
          {durationTimes.map((time, index) => (
            <MenuItem key={index} value={time.value}>
              {time.name}
            </MenuItem>
          ))}
        </Select>
      </ModalSection>
      <ModalSection
        title='Reminder Time'
        subtitle='You will be reminded on your calendar prior to the meeting'
      >
        <Select
          name='reminder_lead_time'
          variant='outlined'
          margin='dense'
          fullWidth
          defaultValue='preview'
          className={classes.select}
          value={meetingType.attributes.reminder_lead_time}
          onChange={(reminder) =>
            props.handleChange({
              type: 'reminder_lead_time',
              data: reminder.target.value
            })}
        >
          {reminderTimes.map((time, index) => (
            <MenuItem key={index} value={time.value}>
              {time.name}
            </MenuItem>
          ))}
        </Select>
      </ModalSection>
      <ModalSection
        title='Lead Time'
        subtitle='The minimum amount of time between when a meeting is booked and when it can take place.'
      >
        <Select
          name='lead_time'
          variant='outlined'
          margin='dense'
          fullWidth
          defaultValue='preview'
          className={classes.select}
          value={meetingType.attributes.lead_time}
          onChange={(leadTime) =>
            props.handleChange({
              type: 'lead_time',
              data: leadTime.target.value
            })}
        >
          {leadTimes.map((time, index) => (
            <MenuItem key={index} value={time.value}>
              {time.name}
            </MenuItem>
          ))}
        </Select>
      </ModalSection>
      <ModalSection
        title='Meeting Description'
        subtitle='Add details, notes, and preparation items that will be visible to everyone who gets an invitation.'
      >
        <TextField
          className={classes.textField}
          color='primary'
          rows='3'
          multiline
          placeholder='Description'
          variant='outlined'
          type='text'
          value={meetingType.attributes.description}
          onChange={(e) =>
            props.handleChange({ type: 'description', data: e.target.value })}
        />
      </ModalSection>
      <ModalSection
        title='Visibility'
      >
        <Typography variant='body2' style={{ marginTop: 10 }}>
          Who should be able to see and use this meeting type?
        </Typography>
        <Tooltip title='Only editable if your role is that of a manager and above' placement='top-start'>
          <div style={{ marginLeft: 20 }}>
            <RadioButtons
              buttons={[
                { value: true, label: 'Available company-wide' },
                { value: false, label: 'Private to me' }
              ]}
              value={meetingType.attributes.allow_global_access}
              handleChange={(value) => props.handleChange({ type: 'allow_global_access', data: value === 'true' })}
              disabled={!isManager}
            />
          </div>
        </Tooltip>
        <Typography variant='body2' style={{ marginTop: 10 }}>
          Should users be able to use this meeting type on their agent profile pages?
        </Typography>
        <Tooltip title='Only editable if your role is that of a manager and above' placement='top-start'>
          <div style={{ marginLeft: 20 }}>
            <Checkbox
              label={checkboxText}
              checked={meetingType.attributes.allow_in_agent_pages}
              disabled={!isManager}
              onChange={(value) => props.handleChange({ type: 'allow_in_agent_pages', data: value })}
            />
          </div>
        </Tooltip>
      </ModalSection>
    </Modal>
  )
}

export default MeetingTypesModal
