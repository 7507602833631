import React from 'react'
import LearnMoreSvg from './learn-more.svg'
import Tooltip from '@material-ui/core/Tooltip'

export default function LearnMore ({ url, tooltip }) {
  return (
    <Tooltip title={tooltip || 'Learn More'}>
      <a
        href={url}
        target='_blank'
        rel='noopener noreferrer'
        style={{
          display: 'flex',
          marginLeft: 5,
          marginRight: 3,
          alignItems: 'center'
        }}
      >
        <img
          src={LearnMoreSvg}
          alt='Learn more'
          style={{
            display: 'inline-block',
            verticalAlign: 'text-bottom',
            height: '1em'
          }}
        />
      </a>
    </Tooltip>
  )
}
