import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import IconButton from '@material-ui/core/IconButton'
import Snackbar from '@material-ui/core/Snackbar'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import CachedIcon from '@material-ui/icons/Cached'
import CloseIcon from '@material-ui/icons/Close'
import EditIcon from '@material-ui/icons/Edit'
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert'
import { reEvaluateSegment } from 'api/segments'
import { getUser } from 'api/users'
import AppPage from 'cf-components/AppPage'
import EmptyState from 'cf-components/EmptyState'
import { PageLoader } from 'cf-components/LoadingScreen'
import { SignalsScoreHistory } from 'cf-components/SignalsScoreHistory'
import { CSVDownload } from 'classes/csvdownload'
import { AppendFilters, parameterizeFilters, SearchParams } from 'classes/queryHelpers'
import { Filter, Segment } from 'classes/segments'
import DateFormat from 'components/DateFormat'
import dateFormat from 'dateformat'
import { Emitter, EVENT_TYPE } from 'emitter'
import { downloadCSV } from 'helpers/csv'
import SkeletonLabel from 'library/loading/skeleton/SkeletonLabel'
import SkeletonTableLoader from 'library/loading/skeleton/SkeletonTableLoader'
import CSVUploadModal from 'library/segments/CSVUploadModal'
import { isEqual } from 'lodash'
import { MouseEventHandler, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { SessionContext } from 'session-context'
import EditAlertModal from '../accounts/AccountManagement/Alerts/EditAlertModal'
import { DynamicSegmentProvider } from './DynamicSegmentContext'
import { accountCustomFilters } from './segmentAccountHelpers'
import { contactCustomFilters } from './segmentContactHelpers'
import SegmentCriteriaEditor from './SegmentCriteriaEditor'
import { capitalizeFirstLetter } from './segmentHelpers'
import { SegmentSetupModal } from './SegmentSetupModal'
import ProspectModal from 'library/modals/ProspectModal'

const mywindow = window as any
const exportCSV = async (searchParams: SearchParams) => {
  const exportClass = 'Account'
  Emitter.emit(EVENT_TYPE.CSV_DOWNLOAD, { csvIsDownloading: true })
  const resp = await CSVDownload.download({ type: exportClass, searchParams })
  Emitter.emit(EVENT_TYPE.CSV_DOWNLOAD, { csvIsDownloading: false })
  const date = new Date().toString()
  const csvType = exportClass.toLowerCase() + 's'
  const filename = 'signals_' + csvType + '_' + DateFormat({ dateToFormat: date, format: 'isoDate' }) + '.csv'
  downloadCSV(filename, resp)
}

const useStyles = makeStyles(theme => ({
  loadingCtn: { marginTop: '20vh' },
  tabPlaceholder: {
    width: 200,
    marginTop: 5,
    marginBottom: 5,
    marginLeft: 10,
    marginRight: 20
  },
  cardsCtn: {
    display: 'flex',
    padding: 20,
    paddingBottom: 0,
    gap: '12px',
    '& > div': {
      height: 'inherit',
      padding: '10px 14px'
    }
  },
  segmentDetailsCard: {
    backgroundColor: '#fafafa',
    flexGrow: 1
  },
  detailsSplitCtn: {
    display: 'grid',
    gridTemplateColumns: '1fr minmax(180px, 30%)',
    gap: '8px',
    '& > div': {
      height: 'inherit',
      padding: '2px 4px'
    }
  },
  cardTitle: {
    fontSize: '1.1rem',
    fontWeight: 600,
    color: '#484848',
    paddingBottom: 8
  },
  cardPurpleTitle: {
    fontWeight: 600
  },
  attributesCtn: {
    whiteSpace: 'nowrap',
    flexBasis: '30%',
    flexShrink: 0,
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'center'
  },
  attributeRow: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '8px',
    padding: '2px 4px',
    borderBottom: `1px solid ${theme.palette.primary.light}`,
    '&:last-child': { borderBottom: 'none' },
    '& > div:first-child': {
      fontWeight: 600
    },
    '& > div:last-child': {
      color: '#3B3B3B',
      fontSize: '.9rem'
    }
  },
  descriptionCard: {
    display: 'flex',
    flexFlow: 'column nowrap'
  },
  descriptionCtn: {
    flexGrow: 1
  },
  editDescription: {
    height: '100%',
    position: 'relative'
  },
  editDescriptionIcon: {
    position: 'absolute',
    top: 0,
    right: 0,
    fontSize: '1.2em',
    color: 'rgb(124, 124, 124)',
    pointerEvents: 'none'
  },
  emptyDescription: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    pointerEvents: 'none'
  },
  editDescriptionField: {
    height: '100%',
    '& > div': {
      padding: 0,
      lineHeight: 'inherit',
      color: 'inherit',
      height: 'inherit',
      '& > div': {
        height: 'inherit',
        lineHeight: 'inherit',
        padding: 0,
        '& > textarea': {
          minHeight: '4rem',
          paddingRight: 20
        }
      }
    }
  },
  numObjectsCard: {
    flex: '0 0 auto',
    color: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexFlow: 'column nowrap',
    fontWeight: 600,
    '& > div:first-child': { fontSize: '1.6rem' },
    '& > div:last-child': { letterSpacing: 1 }
  },
  menuPaper: {
    marginTop: 30
  },
  menuItemRoot: {
    display: 'grid',
    gridTemplateColumns: '1fr 30px',
    gridGap: 25,
    width: '100%',
    alignItems: 'center',
    '& > svg': {
      color: theme.palette.primary.main
    }
  }
}))

const directToICP = () => {
  window.open('/#/account_management/icp')
}

export interface SegmentDefaultPageProps {
  objectType: Segment['objectType']
  segmentID: Segment['id']
  count?: number
  isLoadingCount?: boolean
  /** The list of accounts or contacts along with the search & export */
  children: ReactNode
}

export default function SegmentDefaultPage (props: SegmentDefaultPageProps): JSX.Element {
  const searchParams: SearchParams = {
    filters: [[{ field: 'segments', operator: 'includes', value: [parseInt(props.segmentID)] }]]
  }
  const classes = useStyles()
  const [alertDomain, setAlertDomain] = useState<string>('')
  const [alertModalOpen, setAlertModalOpen] = useState(false)
  const { user } = useContext(SessionContext)
  const chatServiceUrl = user?.links.chat_service
  const [refreshLoading, setRefreshLoading] = useState(false)
  const [showRefreshAlert, setShowRefreshAlert] = useState(false)
  const [segmentCriteriaModalOpen, setSegmentCriteriaModalOpen] = useState(false)
  const [csvUploadModalOpen, setCSVUploadModalOpen] = useState(false)
  const queryClient = useQueryClient()
  const [currDomain, setCurrDomain] = useState('')
  const [scoreAnchorEl, setScoreAnchorEl] = useState<HTMLElement | null>(null)
  const [historyOpen, setHistoryOpen] = useState(true)
  const [currScore, setCurrScore] = useState(0)
  const [lookupProcessing, setLookupProcessing] = useState(false)
  const [selectedAccounts, setSelectedAccounts] = useState<string[]>([])
  const [lookupModalOpen, setLookupModalOpen] = useState(false)
  const { data: segment, isLoading } = Segment.loadOne(props.segmentID)
  const { data: createdByUser } = useQuery(['users', segment?.createdByUserID, 'info'], () => getUser({
    ID: segment?.createdByUserID
  }).then(res => res.data), {
    staleTime: 120000,
    retry: false,
    enabled: Boolean(segment?.createdByUserID)
  })
  const handleAlert = (domain: string) => {
    setAlertDomain(domain)
    setAlertModalOpen(true)
  }

  const breadcrumbs = useMemo(() => [{
    link: `/${props.objectType.slice(0, -1)}_management/segments`,
    text: 'Segments'
  }, {
    link: null,
    text: 'Segment Page'
  }], [props.objectType])

  const segmentType = segment?.segmentType
  const appPageSubTitle: Capitalize<Segment['segmentType']> | undefined = useMemo(() => segmentType ? capitalizeFirstLetter(segmentType) : undefined, [segmentType])

  const tableEventListener = (e: any) => {
    const tableID = e.detail.tableID
    const action = e.detail.action
    const ids: Array<string> = []
    if (tableID === 'accounts-body') {
      switch (action) {
        case 'export':
          Array.from(document.getElementsByClassName('rowcheckbox')).forEach((checkbox: any) => {
            if (checkbox.checked) {
              ids.push(checkbox.value)
            }
          })
          if (ids.length === 0) {
            exportCSV(searchParams)
          } else {
            const exportParams = { ...searchParams, filters: AppendFilters(searchParams.filters || [], [[{ field: 'id', value: ids, operator: 'in' }]]) }
            exportCSV(exportParams)
          }
          break
        case 'contactLookup':
          if (!lookupProcessing) {
            setLookupProcessing(true)
            Array.from(mywindow.htmx.findAll(
              mywindow.htmx.find('#' + tableID),
              '.rowcheckbox'
            )).forEach((checkbox: any) => {
              if (checkbox.checked) {
                console.log(checkbox.value)
                ids.push(checkbox.value)
              }
            })
            setSelectedAccounts(ids)
            if (ids.length !== 0) {
              setLookupModalOpen(true)
            }
            setTimeout(() => {
              setLookupProcessing(false)
            }, 1000)
          }
          break
        default:
          console.warn('Unknown table action', action)
      }
    }
  }
  const rowEventListener = (e: any) => {
    const tableID = e.detail.tableID
    const rowAction = e.detail.action
    const rowID = e.detail.rowID
    const anchorDiv = document.getElementById(e.detail.anchor)
    if (tableID === 'accounts-body') {
      switch (rowAction) {
        case 'alert':
          handleAlert(rowID)
          break
        case 'scoreHoverCancel':
          setHistoryOpen(false)
          break
        case 'scoreHover':
          if (anchorDiv) {
            setScoreAnchorEl(anchorDiv)
            setCurrDomain(rowID)
            setHistoryOpen(true)
            setCurrScore(e.detail.score)
          }
          break
        default:
        // console.warn('Unknown row action', rowAction)
      }
    }
  }

  useEffect(() => {
    try {
      /*
      mywindow.htmx.logger = function (elt: any, event: any, data: any) {
        if (console) {
          console.log('INFO:', event, elt, data)
        }
      }
      */
      mywindow.addEventListener('tableEvent', tableEventListener)
      mywindow.addEventListener('rowEvent', rowEventListener)
    } catch (e) {
      console.log(e)
    }
    return () => {
      mywindow.removeEventListener('tableEvent', tableEventListener)
      mywindow.removeEventListener('rowEvent', rowEventListener)
    }
    // eslint-disable-next-line
  }, [currDomain])
  const refreshSegment = useCallback(() => {
    setRefreshLoading(true)
    reEvaluateSegment({ segmentID: props.segmentID }).then(() => {
      setTimeout(() => {
        queryClient.invalidateQueries(['segments', props.segmentID, 'objects'])
        queryClient.invalidateQueries(['segments', props.segmentID, 'count'])
        setRefreshLoading(false)
        setShowRefreshAlert(true)
      }, 3000)
    })
  }, [props.segmentID, queryClient])

  const appPageAction = useCallback<MouseEventHandler<HTMLButtonElement>>(() => {
    setSegmentCriteriaModalOpen(true)
  }, [])

  const [modalSegment, setModalSegment] = useState<Segment>(new Segment({}))

  if (segment === undefined) {
    return (
      <AppPage
        title='segment'
        breadcrumbs={breadcrumbs}
        subtitle='Segment Type: '
      >
        <div className={classes.loadingCtn}>
          <PageLoader />
        </div>
      </AppPage>
    )
  } else if (!isLoading && segment === null) {
    return (
      <AppPage
        title='segment'
        breadcrumbs={breadcrumbs}
        subtitle='Segment Type: '
      >
        <div className={classes.loadingCtn}>
          <EmptyState
            sad
            message='Segment not found'
          />
        </div>
      </AppPage>
    )
  }
  if (isLoading) {
    return (<></>)
  } else if (!modalSegment.id && segment.id) {
    setModalSegment(Segment.clone(segment))
  }

  function handleSave (values: {
    name?: Segment['name']
    description?: Segment['description']
    triggers?: Segment['triggers']
  }): void {
    let action
    if (segment) {
      const patch = { id: segment.id }
      if (values.name !== undefined) {
        patch.name = values.name
        action = 'simple'
      }
      if (values.description !== undefined) {
        patch.description = values.description
        action = 'simple'
      }
      if (values.triggers !== undefined) {
        const newTriggers: Segment['triggers'] = []
        values.triggers.forEach((segmentFilter) => {
          const filters: Filter[] = []
          const newFilters = { filters }
          segmentFilter.filters.forEach((filter) => {
            if (filter.prop !== '') {
              newFilters.filters.push(filter)
            }
          })
          newTriggers.push(newFilters)
        })
        patch.triggers = newTriggers
      }
      setSegmentCriteriaModalOpen(false)
      Segment.save(patch, action).then(() => {
        if (values.triggers !== undefined) {
          refreshSegment()
        }
      })
    }
  }
  const editableTitle = !segment.isICP

  const segmentSave = (values: {
    triggers: Segment['triggers']
  }) => {
    const updateTriggers = values.triggers !== undefined && !isEqual(values.triggers, modalSegment.triggers)
    if (updateTriggers) {
      modalSegment.triggers[0].filters = [...values.triggers[0].filters.filter((f) => f.prop !== '')]
    }
    Segment.save(modalSegment)
    setSegmentCriteriaModalOpen(false)
    refreshSegment()
  }
  const action = segment.isICP ? directToICP : appPageAction
  const title = segment.isICP ? segment.ICPTitle : segment.name
  const url = parameterizeFilters({ path: '/api/core/web/accounts', searchParams })

  return (
    <>
      <AppPage
        title={title}
        saveName={(name: string) => {
          name = (name || '').trim()
          if (name !== segment.name) {
            handleSave({ name })
          }
        }}
        editableTitle={editableTitle}
        breadcrumbs={breadcrumbs}
        subtitle={'Segment Type: ' + appPageSubTitle}
        action={action}
        actionText='Edit Segment'
        icon={refreshLoading ? <CachedIcon /> : <EditIcon />}
        disabled={refreshLoading}
      >
        <div className={classes.cardsCtn}>
          <Card className={classes.segmentDetailsCard}>
            <div className={classes.cardTitle}>
              Segment Details
            </div>
            <div className={classes.detailsSplitCtn}>
              <div className={classes.descriptionCard}>
                <div className={classes.cardPurpleTitle}>
                  Description
                </div>
                <div className={classes.descriptionCtn}>
                  <EditableDescription
                    value={segment.description}
                    onSave={description => handleSave({ description })}
                  />
                </div>
              </div>
              <div className={classes.attributesCtn}>
                <div className={classes.attributeRow}>
                  <div>Date Created</div>
                  <div>{dateFormat(segment.createdTimestamp?.toDateString(), 'shortDate')}</div>
                </div>
                <div className={classes.attributeRow}>
                  <div>Created By</div>
                  <div>{createdByUser ? (createdByUser.attributes.name || createdByUser.attributes.email) : 'Unknown User'}</div>
                </div>
                <div className={classes.attributeRow}>
                  <div>Last Updated</div>
                  <div>{dateFormat(segment.updatedTimestamp?.toDateString(), 'shortDate')}</div>
                </div>
              </div>
            </div>
          </Card>
        </div>
        <div style={{ backgroundColor: 'white' }}>
          <div
            id='accounts'
            hx-vals='{"noFilterButton":true}'
            hx-get={url}
            hx-trigger='revealed,paramChange'
          >
            <div style={{ display: 'flex', justifyContent: 'right', padding: 10 }}>
              <div className={classes.tabPlaceholder} style={{ margin: 10 }}> <SkeletonLabel size='full' /> </div>
              <div className={classes.tabPlaceholder} style={{ margin: 10 }}> <SkeletonLabel size='full' /> </div>
              <div className={classes.tabPlaceholder} style={{ margin: 10 }}> <SkeletonLabel size='full' /> </div>
            </div>
            <SkeletonTableLoader
              rowCount={10}
              types={['squareAvatar&Label', 'smLabel', 'number', 'smLabel', 'name&Subtitle', 'avatar']}
              columns='2fr 1fr 1fr 1fr 1fr 50px 50px'
              justifyCenter
            />
          </div>
        </div>
        {currDomain && (
          <SignalsScoreHistory
            score={currScore}
            placement='left'
            domain={currDomain}
            anchorEl={scoreAnchorEl}
            open={historyOpen}
          />
        )}
        {lookupModalOpen && <ProspectModal onHide={() => setLookupModalOpen(false)} selectedAccounts={selectedAccounts} />}
      </AppPage>
      {
        segment.uiVersion === 'v1' && (
          <SegmentCriteriaEditor
            triggers={segment.triggers}
            label='Edit Segment'
            editStaticSegmentWarning={segmentType === 'static'}
            open={segmentCriteriaModalOpen}
            onHide={() => setSegmentCriteriaModalOpen(false)}
            save={handleSave}
            chatServiceUrl={chatServiceUrl}
            customFilters={segment.segObjectType === 'contacts' ? contactCustomFilters : accountCustomFilters}
            segmentObjectType={capitalizeFirstLetter(segment.segObjectType)}
          />
        )
      }
      {
        segment.uiVersion === 'v2' && (
          <DynamicSegmentProvider segment={modalSegment} save={segmentSave} initialSegment={segment}>
            <SegmentSetupModal
              open={segmentCriteriaModalOpen}
              onBack={() => setSegmentCriteriaModalOpen(false)}
              goBackText='Cancel'
              segment={modalSegment}
              editTriggers={(updated: Segment) => setModalSegment(updated)}
              onHide={() => setSegmentCriteriaModalOpen(false)}
              save={segmentSave}
              openCSVModal={() => { setSegmentCriteriaModalOpen(false); setCSVUploadModalOpen(true) }}
            />
            <>
              {csvUploadModalOpen &&
                <CSVUploadModal
                  handleGoBack={() => { setCSVUploadModalOpen(false); setSegmentCriteriaModalOpen(true) }}
                  closeCSVModal={() => setCSVUploadModalOpen(false)}
                  open={csvUploadModalOpen}
                  segment={modalSegment}
                />}
            </>
          </DynamicSegmentProvider>
        )
      }
      <EditAlertModal
        open={alertModalOpen}
        onHide={() => { setAlertDomain(''); setAlertModalOpen(false) }}
        domain={alertDomain}
        isGlobal
      />
      <Snackbar open={showRefreshAlert}>
        <Alert
          severity='info'
          action={
            <>
              <IconButton
                size='small'
                aria-label='close'
                color='inherit'
                onClick={() => setShowRefreshAlert(false)}
              >
                <CloseIcon fontSize='small' />
              </IconButton>
              <Button
                size='small'
                variant='outlined'
                aria-label='refresh'
                style={{ color: 'white' }}
                onClick={() => window.location.reload()}
              >
                Refresh
              </Button>
            </>
          }
        >
          Your segment is refreshing in the background! Some records may not appear immediately.
        </Alert>
      </Snackbar>
    </>
  )
}

const Alert = ({ style, ...props }: Omit<AlertProps, 'elevation' | 'filled'>): JSX.Element => (
  <MuiAlert
    variant='filled'
    elevation={6}
    style={{ alignItems: 'center', ...style }}
    {...props}
  />
)

function EditableDescription (props: {
  value: string | null
  onSave: (value: string) => void
}): JSX.Element {
  const classes = useStyles()
  const propsValue = props.value?.trim() || undefined
  const [value, setValue] = useState<string | undefined>(propsValue)

  useEffect(() => setValue(propsValue), [propsValue])

  const onBlur = (): void => {
    const newValue = value?.trim() || undefined
    if (newValue !== propsValue) {
      props.onSave(newValue || '')
    } else {
      setValue(newValue)
    }
  }

  return (
    <div className={classes.editDescription}>
      <div className={classes.editDescriptionField}>
        <TextField
          multiline
          fullWidth
          InputProps={{ disableUnderline: true }}
          value={value || ''}
          onChange={e => setValue(e.target.value)}
          onFocus={() => value || setValue('')}
          onBlur={onBlur}
        />
      </div>
      <EditIcon
        className={classes.editDescriptionIcon}
        onClick={() => value || setValue('')}
      />
      {value === undefined && (
        <div className={classes.emptyDescription}>
          <EmptyState
            sad
            message='Click to add a description'
          />
        </div>
      )}
    </div>
  )
}
