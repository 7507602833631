function getReducer () {
  const reducer = (state, action) => {
    switch (action.type) {
      case 'initialize':
        return action.data
      case 'integrationSettings':
        return {
          ...state,
          attributes: {
            ...state.attributes,
            [action.name]: action.value
          }
        }
      case 'mappings':
        return {
          ...state,
          attributes: {
            ...state.attributes,
            mappings: {
              ...state.attributes.mappings,
              [action.name]: action.value
            }
          }
        }
      case 'mappingAdd':
        return {
          ...state,
          attributes: {
            ...state.attributes,
            mappings: {
              ...state.attributes.mappings,
              [action.name]: state.attributes.mappings[action.name] ? state.attributes.mappings[action.name].concat(action.value) : action.value
            }
          }
        }
      case 'mappingDelete':
        return {
          ...state,
          attributes: {
            ...state.attributes,
            mappings: {
              ...state.attributes.mappings,
              [action.name]: state.attributes.mappings[action.name] ? state.attributes.mappings[action.name].filter(m => m?.id !== action.value) : []
            }
          }
        }
      case 'mappingEdit':
        return {
          ...state,
          attributes: {
            ...state.attributes,
            mappings: {
              ...state.attributes.mappings,
              [action.name]: state.attributes.mappings[action.name] ? state.attributes.mappings[action.name].filter(m => m?.id !== action.value[0].id).concat(action.value) : action.value
            }
          }
        }
      case 'eventSettings':
        return {
          ...state,
          attributes: {
            ...state.attributes,
            event_settings: {
              ...state.attributes.event_settings,
              [action.name]: action.value
            }
          }
        }
      case 'config':
        return {
          ...state,
          attributes: {
            ...state.attributes,
            config: {
              ...state.attributes.config,
              [action.name]: action.value
            }
          }
        }
      case 'externalKeys':
        return {
          ...state,
          attributes: {
            ...state.attributes,
            external_keys: {
              ...state.attributes.external_keys, [action.name]: action.value
            }
          }
        }
      default:
        throw new Error()
    }
  }
  return reducer
}

export { getReducer }
