import { Select } from 'cf-components/material-wrappers/MaterialComponents'
import { dropdownFields } from 'pages/settings/Integrations/MappingRow'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '41vw',
    marginLeft: '50px',
    position: 'relative'
  },
  dotted: {
    border: 'none',
    borderTop: '2px dotted #9933FF',
    color: '#FFFFFF',
    backgroudColor: '#FFFFFF',
    height: '1px',
    width: '100%',
    marginLeft: '10px',
    marginRight: '10px'
  },
  customInput: {
    height: '40px',
    width: '225px'
  }
}))

export interface QueryLinkMapping {
  signalsFieldID: string
  emailField: string
  canDelete: boolean
  canEdit: boolean
}

interface LinkMapItemProps {
  signalsFieldID: string
  emailField: string
  canDelete?: boolean
  canEdit?: boolean
  handleRemove: () => void
  handleEdit: (value: any) => void
  customFields?: Array<{ attributes: { slug: string, label: string, kind: string } }>
  // state
  // dispatch
}

export default function LinkMapItem ({ signalsFieldID, emailField, canDelete = true, canEdit = true, handleRemove, handleEdit, customFields = [] }: LinkMapItemProps) {
  const classes = useStyles()
  const formattedCustomFields = customFields.map(field => ({
    value: field.attributes.slug,
    label: field.attributes.label,
    kind: field.attributes.kind,
    group: 'Custom Fields'
  }))
  const options = [...dropdownFields, ...formattedCustomFields]

  const handleSignalsFieldChange = (value: string) => {
    handleEdit({ signalsFieldID: value, emailField: emailField, canDelete: canDelete, canEdit: canEdit })
  }

  const handleEmailFieldChange = (value: string) => {
    handleEdit({ signalsFieldID: signalsFieldID, emailField: value, canDelete: canDelete, canEdit: canEdit })
  }

  return (
    <div className={classes.container}>
      {canDelete && (
        <div onClick={() => handleRemove()} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', left: -40, cursor: 'pointer' }}>
          <HighlightOffIcon color='primary' />
        </div>
      )}
      <div style={{ minWidth: '225px', display: 'flex' }}>
        {canEdit ? (
          <Select
            options={options}
            value={signalsFieldID}
            onChange={(value: string) => handleSignalsFieldChange(value)}
            disableClear={!canDelete}
            width='100%'
          />
        ) : (
          <TextField
            variant='outlined'
            margin='dense'
            value={signalsFieldID}
            className={classes.customInput}
            onChange={() => null}
            disabled
          />
        )}
      </div>
      <div className={classes.dotted} />
      <div>
        <TextField
          variant='outlined'
          margin='dense'
          value={emailField}
          className={classes.customInput}
          onChange={(event) => handleEmailFieldChange(event.target.value)}
        />
      </div>
    </div>
  )
}
