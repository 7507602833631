import { makeStyles } from '@material-ui/core/styles'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Button from '@material-ui/core/Button'
import BlockIcon from '@material-ui/icons/Block'
import StarIcon from '@material-ui/icons/Star'
import StarBorderIcon from '@material-ui/icons/StarBorder'

interface LeadScoreSelectorProps {
  score: number | null
  setScore (score: number): void
}

const useStyles = makeStyles(theme => ({
  leadScoreButton: {
    borderRadius: 30,
    height: 35
  },
  leadScoreIcon: {
    height: '.75em',
    width: '.75em',
    fill: '#5C5C5C'
  },
  selectedStarIcon: {
    height: '.75em',
    width: '.75em',
    fill: 'gold'
  },
  subtitle: {
    color: '#999',
    fontFamily: 'Poppins, sans serif',
    fontSize: '12px',
    marginBottom: 10
  }
}))

export default function LeadScoreSelector ({ score, setScore }: LeadScoreSelectorProps): JSX.Element {
  const classes = useStyles()
  return (
    <ButtonGroup
      fullWidth
    >
      <Button classes={{ root: classes.leadScoreButton }} onClick={() => setScore(0)}>
        <BlockIcon style={{ height: '.65em', width: '.65em', fill: score === 0 ? '#F0706F' : '#5C5C5C' }} />
      </Button>
      <Button onClick={() => setScore(1)}>
        {
          score === 1
            ? <StarIcon classes={{ root: classes.selectedStarIcon }} />
            : <StarBorderIcon classes={{ root: classes.leadScoreIcon }} />
        }
      </Button>
      <Button onClick={() => setScore(2)}>
        {
          score === 2
            ? (
              <>
                <StarIcon classes={{ root: classes.selectedStarIcon }} />
                <StarIcon classes={{ root: classes.selectedStarIcon }} />
              </>
            )
            : (
              <>
                <StarBorderIcon classes={{ root: classes.leadScoreIcon }} />
                <StarBorderIcon classes={{ root: classes.leadScoreIcon }} />
              </>)
        }
      </Button>
      <Button classes={{ root: classes.leadScoreButton }} onClick={() => setScore(3)}>
        {
          score === 3
            ? (
              <>
                <StarIcon classes={{ root: classes.selectedStarIcon }} />
                <StarIcon classes={{ root: classes.selectedStarIcon }} />
                <StarIcon classes={{ root: classes.selectedStarIcon }} />
              </>
            )
            : (
              <>
                <StarBorderIcon classes={{ root: classes.leadScoreIcon }} />
                <StarBorderIcon classes={{ root: classes.leadScoreIcon }} />
                <StarBorderIcon classes={{ root: classes.leadScoreIcon }} />
              </>
            )
        }
      </Button>
    </ButtonGroup>
  )
}
