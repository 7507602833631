import { makeStyles, styled } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  link: {
    textDecoration: 'none',
    color: '#444444',
    width: '100%',
    height: '100%',
    fontWeight: 600
  }
}))

export default function CustomLink (props) {
  const classes = useStyles()

  return (
    <Link to={props.to} className={classes.link} target={props.target}>
      {props.children}
    </Link>
  )
}

export const HiddenLink = styled(Link)({
  textDecoration: 'none',
  color: 'inherit'
})
