
const descriptionText = 'The Conversation Goal node allows you to specify when a web visitor performs a key action within your bot, such as providing an email, booking a demo, subscribing to your newsletter, etc.  Use conversation goals to:'

const Description = (): JSX.Element => {
  return (
    <div>
      <div>
        {descriptionText}
      </div>
      <ul>
        <li>Inform yourself regarding bot performance</li>
        <li>Set the success metric when performing A/B testing on your bots</li>
        <li>Tag visitor conversations</li>
        <ul>
          <li><a href='#/settings/conversation_tags' target='_blank'>Manage Tags</a></li>
        </ul>
      </ul>
    </div>
  )
}

const bestPracticeText = 'Create descriptive tags that reflect the specific goals being met'

const BestPractices = () => {
  return (
    <div>
      <div>
        {bestPracticeText}
      </div>
      <ul>
        <li>Only utilize these unique tags when primary/secondary goals are met</li>
        <li>Regularly evaluate bot effectiveness based upon conversation goals being met</li>
        <li>Update and iterate on bot flows based upon the goal success rate to provide the best possible experience for your visitors</li>
      </ul>
    </div>
  )
}

export const ConversationGoalDocs = {
  helpCenterLink: 'https://help.getsignals.ai/article/3o6vbq9s8k-conversation-goal-node',
  bestPractices: BestPractices,
  description: Description,
  videoLink: ''
}
