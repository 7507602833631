import { makeStyles } from '@material-ui/core/styles'
import { RadioButtons } from 'library/materialUI'

const useStyles = makeStyles(theme => ({
  popupContainer: {
    width: '313px'
  },
  innerContainer: {
    margin: '20px'
  }
}))

interface BooleanComparatorProps {
  operator: string
  setOperator: (value: string) => void
}

export default function BooleanComparator ({ operator, setOperator }: BooleanComparatorProps): JSX.Element {
  const classes = useStyles()
  const booleanComparisons = [
    { value: 'eq', label: 'is true' },
    { value: 'neq', label: 'is false' }
  ]

  return (
    <div>
      <div className={classes.innerContainer}>
        <RadioButtons
          buttons={booleanComparisons}
          handleChange={(value: string) => setOperator(value)}
          value={operator}
        />
      </div>
    </div>
  )
}
