/* eslint-disable @typescript-eslint/naming-convention */
import Card from 'library/materialUI/Card'
import { FormControlLabel, Card as MuiCard } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Checkbox, Icon, Radio, Typography } from 'library/materialUI'
import { Select } from 'cf-components/material-wrappers/MaterialComponents'
import { useContext, useEffect, useState } from 'react'
import { TabContext } from './IntegrationPage/TabProvider'
import { IntegrationPageContext } from './IntegrationPage/IntegrationPageProvider'
import { Segment } from 'classes/segments'
import { IntegrationMappingCls } from 'classes/IntegrationMapping'
import Loader from 'library/loading/Loader'

const useStyles = makeStyles((theme) => ({
  selectedCard: {
    border: '1px solid #9933FF',
    backgroundColor: 'rgba(142, 90, 226, 0.05)',
    marginBottom: 10,
    padding: 20,
    width: 550,
    cursor: 'pointer'
  },
  normalCard: {
    border: '1px solid #E0E0E0',
    marginBottom: 10,
    padding: 20,
    width: 550,
    cursor: 'pointer'
  },
  subtitle1: {
    fontSize: 18,
    fontWeight: 600,
    marginBottom: 15
  },
  subtitle2: {
    fontSize: 17,
    marginTop: 30
  },
  subtitle3: {
    fontSize: 17
  },
  checkboxes: {
    display: 'flex',
    marginBottom: 20,
    marginTop: 10
  },
  bodyText: {
    marginBottom: 10,
    fontSize: 16
  },
  warningText: {
    fontSize: 14,
    marginLeft: 5
  }
}))

interface ScoreSyncingCardProps {
  save: any
  handleEdit: any
  integrationSettings: any
  integrationName: string
  externalFields: ExternalField[] | SalesforceExternalFields
  setFocusMapping: any
}

type ExternalField = {
  value?: string
  api_name: string
  label: string
  data_type: string
  object_type?: string
}

type SalesforceExternalFields = {
  attributes: { lead: { fields: ExternalField[] } }
}

export default function ScoreSyncingCard ({ save, handleEdit, integrationSettings, integrationName, externalFields = [], setFocusMapping }: ScoreSyncingCardProps) {
  const classes = useStyles()
  const { setCanSave, shouldSave } = useContext(TabContext)
  const { handleSaved } = useContext(IntegrationPageContext)
  const [syncBySegment, setSyncBySegment] = useState(integrationSettings.attributes.config.sync_by_segment || false);
  const [segmentsToSync, setSegmentsToSync] = useState(integrationSettings.attributes.config.segments_to_sync || []);
  const [segmentsNotToSync, setSegmentsNotToSync] = useState(integrationSettings.attributes.config.segments_not_to_sync || []);

  useEffect(() => {
    if (shouldSave) {
      save()
      handleSaved()
    }
  })

  useEffect(() => {
    handleEdit({ type: 'segments_to_sync', data: segmentsToSync })
    // eslint-disable-next-line
  }, [segmentsToSync])

  useEffect(() => {
    handleEdit({ type: 'segments_not_to_sync', data: segmentsNotToSync })
    // eslint-disable-next-line
  }, [segmentsNotToSync])

  const { data: crmAccountMapping, isLoading } = IntegrationMappingCls.loadOne('crm_account', integrationSettings.attributes.integration_name)

  if ('attributes' in externalFields) {
    externalFields = (externalFields as SalesforceExternalFields).attributes.lead.fields
  }
  const numericFields: ExternalField[] = (externalFields as ExternalField[]).map((ef) => { ef.value = ef.api_name; return ef })
  numericFields.filter((field) => field.data_type === 'number')
  numericFields.sort((a: { label: string }, b: { label: string }) => a.label.localeCompare(b.label))

  const { data: segments } = Segment.loadAll({ searchParams: { sortColumn: 'name' } })
  const segmentList = segments.list.map(segment => ({ label: segment.name, value: segment.id }))

  return (
    <Card
      content={
        <>
          <Typography className={classes.subtitle1}>
            Signals Score Syncing
          </Typography>
          <Typography variant='subtitle1' className={classes.bodyText}>
            Set rules for when the Signals Score should be synced to accounts that are already in your CRM. Score will also be synced for newly created accounts if your Signals plan includes the Account Creation add-on. The score will be synced daily.
          </Typography>
          {isLoading ? <Loader type='spinner' /> : (
            <div>
              <FormControlLabel
                className={classes.checkboxes}
                control={
                  <Checkbox
                    color='primary'
                    checked={integrationSettings.attributes.config.sync_signals_score}
                    onChange={(value) => {
                      handleEdit({ type: 'sync_signals_score', data: value })
                      setCanSave(true)
                    }}
                  />
                }
                label={<Typography style={{ fontWeight: 600 }}>Sync Signals Score to {integrationName}</Typography>}
              />
              <Typography variant='body1' className={classes.subtitle3}>
                CRM Mapping
              </Typography>
              <Typography variant='subtitle1' className={classes.bodyText}>
                Which {integrationName} field should the Signals Score be mapped to? This will need to be a numeric field.
              </Typography>
              {!crmAccountMapping?.mapping.find(item => (item.internal_field === 'signals_score' && item.external_field)) ? (
                <div style={{ backgroundColor: '#FEF9E5', display: 'flex', alignItems: 'center', marginBottom: 10, width: 'fit-content', padding: 15 }}>
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                    <Icon icon='warning' size='sm' color='#F4D451' />
                    <Typography className={classes.warningText}>
                      Signals Score is not currently mapped and won't be synced to {integrationName} until it's mapped.
                    </Typography>
                  </div>
                </div>)
                : (
                  <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                    <Icon icon='checkCircle' size='md' color='#81AF20' />
                    <Typography variant='body1' style={{ marginLeft: 5 }}>Signals Score has been mapped to {integrationName}</Typography>
                  </div>
                )}
              <div>
                <Button
                  variant='outlined'
                  onClick={() => {
                    setFocusMapping(true)
                    setTimeout(() => {
                      setFocusMapping(false)
                    }, 100)
                  }}
                  style={{ width: 'fit-content', marginRight: 10 }}
                >
                  {crmAccountMapping?.mapping.find(item => (item.internal_field === 'signals_score' && item.external_field)) ? 'Update Signals Score Mapping' : 'Map Signals Score Field'}
                </Button>
              </div>
            </div>)}
          <Typography variant='body1' className={classes.subtitle2}>
            Score Sync Criteria
          </Typography>
          <Typography variant='subtitle1' className={classes.bodyText}>
            What criteria must a company meet in order for their score to be synced to your CRM?
          </Typography>
          <div>
            <MuiCard
              className={!syncBySegment ? classes.selectedCard : classes.normalCard}
              onClick={() => {
                handleEdit({ type: 'sync_by_segment', data: false });
                setSyncBySegment(false);
                setCanSave(true);
              }}
            >
              <Radio
                checked={!syncBySegment}
                label='Sync scores for all existing accounts'
                value={syncBySegment}
              />
            </MuiCard>
          </div>
          <div>
            <MuiCard
              className={syncBySegment ? classes.selectedCard : classes.normalCard}
              onClick={() => {
                handleEdit({ type: 'sync_by_segment', data: true });
                setSyncBySegment(true);
                setCanSave(true);
              }}
            >
              <>
                <Radio
                  checked={syncBySegment}
                  label='Only sync scores for existing accounts in certain segments'
                  value={syncBySegment}
                />
                {syncBySegment && (
                  <>
                    <Typography style={{ marginBottom: 5, marginTop: 20 }}>Which segment(s) should be synced?</Typography>
                    <div>
                      <Select
                        onChange={(value) => setSegmentsToSync(value)}
                        value={segmentsToSync}
                        multiple
                        placeholder='Included Segments'
                        options={segmentList.filter(segment => !segmentsNotToSync.includes(segment.value))}
                      />
                    </div>
                    <Typography style={{ marginBottom: 5, marginTop: 20 }}>Which segment(s) should not be synced?</Typography>
                    <div>
                      <Select
                        onChange={(value) => setSegmentsNotToSync(value)}
                        value={segmentsNotToSync}
                        multiple
                        placeholder='Excluded Segments'
                        options={segmentList.filter(segment => !segmentsToSync.includes(segment.value))}
                      />
                    </div>
                  </>
                )}
              </>
            </MuiCard>
          </div>
        </>
      }
    />
  )
}
