import React from 'react'
import { TextField, Popover, InputAdornment } from '@material-ui/core'

const defaultAnchorOrigin = {
  vertical: 'bottom',
  horizontal: 'center'
}
const defaultTransformOrigin = {
  vertical: 'top',
  horizontal: 'center'
}

const PopoverTextField = props => {
  const StartAdornmentIcon = props.startAdornmentIcon
  const anchorOriginProps = props.anchorOriginProps || defaultAnchorOrigin
  const transformOriginProps = props.transformOriginProps || defaultTransformOrigin

  const remapEnterKey = (event) => {
    if (event.key === 'Enter') {
      event.target.blur()
      event.preventDefault()
      props.onClose()
    }
  }

  const inputProps = StartAdornmentIcon ? {
    startAdornment: <InputAdornment position='start'><StartAdornmentIcon style={{ fill: '#AAAAAA' }} /></InputAdornment>
  } : null

  return (
    <Popover
      open={Boolean(props.anchorEl)}
      anchorEl={props.anchorEl}
      onBlur={props.onBlur}
      onClose={props.onClose}
      anchorOrigin={anchorOriginProps}
      transformOrigin={transformOriginProps}
      onKeyPress={remapEnterKey}
    >
      <TextField
        autoFocus
        onChange={props.onChange}
        variant='outlined'
        placeholder={props.placeholder}
        margin='dense' style={{ margin: 0 }}
        InputProps={inputProps}
        value={props.value}
      />
    </Popover>
  )
}

export default PopoverTextField
