import { BillingProvider } from 'billing-context';
import AppPage from 'cf-components/AppPage';
import InvoicesPage from 'pages/billing_v2/InvoicesPage';
import { MyPlan } from './MyPlan';

export function BillingOverview () {
  const tabs = [
    { name: 'My Plan & Usage', url: '/settings/subscriptions/plan' },
    { name: 'Invoices', url: '/settings/subscriptions/invoices' }
  ]

  return (
    <BillingProvider>
      <AppPage
        title='Billing'
        tabs={tabs}
        padding={20}
      >
        <MyPlan />
        <InvoicesPage />
      </AppPage>
    </BillingProvider>
  )
}
