import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
// import am4themes_animated from "@amcharts/amcharts4/themes/animated";

// am4core.useTheme(am4themes_animated);

function createHeatMap ({ data, id, setVisible }) {
  am4core.addLicense('CH213476655')

  if (!document.getElementById(id)) {
    return
  }

  const chart = am4core.create(id, am4charts.XYChart)
  chart.maskBullets = false

  const xAxis = chart.xAxes.push(new am4charts.CategoryAxis())

  xAxis.renderer.labels.template.fontSize = '12px'
  xAxis.renderer.labels.template.fill = '#7C7C7C'

  const yAxis = chart.yAxes.push(new am4charts.CategoryAxis())

  xAxis.dataFields.category = 'weekday'
  yAxis.dataFields.category = 'hour'

  xAxis.renderer.grid.template.disabled = true
  xAxis.renderer.minGridDistance = 40

  yAxis.renderer.grid.template.disabled = true
  yAxis.renderer.inversed = true
  yAxis.renderer.minGridDistance = 30

  yAxis.renderer.labels.template.fontSize = '13px'
  yAxis.renderer.labels.template.fill = '#4C4C4C'

  const series = chart.series.push(new am4charts.ColumnSeries())
  series.dataFields.categoryX = 'weekday'
  series.dataFields.categoryY = 'hour'
  series.dataFields.value = 'conversations'
  series.sequencedInterpolation = true
  series.defaultState.transitionDuration = 3000

  const bgColor = new am4core.InterfaceColorSet().getFor('background')

  const columnTemplate = series.columns.template
  columnTemplate.strokeWidth = 1
  columnTemplate.strokeOpacity = 0.2
  columnTemplate.stroke = bgColor
  columnTemplate.tooltipText = "{weekday}, {hour}: {value.workingValue.formatNumber('#.')}"
  columnTemplate.width = am4core.percent(100)
  columnTemplate.height = am4core.percent(100)

  series.heatRules.push({
    target: columnTemplate,
    property: 'fill',
    min: am4core.color(bgColor),
    max: chart.colors.getIndex(0)
  })

  // heat legend
  const heatLegend = chart.bottomAxesContainer.createChild(am4charts.HeatLegend)
  heatLegend.width = am4core.percent(100)
  heatLegend.series = series
  heatLegend.valueAxis.renderer.labels.template.fontSize = 9
  heatLegend.valueAxis.renderer.minGridDistance = 30
  heatLegend.valueAxis.min = 0
  heatLegend.valueAxis.maxPrecision = 0

  chart.data = data

  chart.events.on('ready', () => {
    setVisible('visible')
  })
  return chart
}

export { createHeatMap }
