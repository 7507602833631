/* eslint-disable @typescript-eslint/naming-convention */
import { makeStyles } from '@material-ui/core/styles'
import { Checkbox, MenuItem, Typography, Select } from '@material-ui/core'
import Card from 'library/materialUI/Card'
import { useContext, useState } from 'react'
import { IntegrationPageContext } from '../IntegrationPage/IntegrationPageProvider'
import { TabContext } from '../IntegrationPage/TabProvider'
import { GetIntegrationUsers, IntegrationUsers } from 'classes/integrationUsers'
import Loader from 'library/loading/Loader'
import ModalWarning from 'components/ModalWarning'
import { HandleSave } from './ownershipHook'
import { Integration } from 'classes/integrations'

const useStyles = makeStyles(theme => ({
  groupContainer: {
    padding: 30,
    borderRadius: 10,
    marginBottom: 20
  },
  subtitle1: {
    fontSize: 18,
    fontWeight: 600,
    marginBottom: 15
  },
  bodyText: {
    marginBottom: 25,
    fontSize: 16
  },
  checkboxes: {
    display: 'flex',
    alignItems: 'center'
  },
  select: {
    height: 40,
    width: 300
  },
  menuHeader: {
    borderBottom: '1px solid #BBB',
    width: '100%',
    fontWeight: 600,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: 'rgba(0,0,0,0.87)'
  },
  menuItem: {
    paddingTop: 3,
    paddingBottom: 3,
    paddingLeft: 25,
    fontSize: '.95em'
  }
}))

const ownerRulesSettings = [
  { key: 'meeting_scheduled', description: 'User who scheduled a meeting' },
  { key: 'last_routed', description: 'User who was last routed' },
  {
    key: 'closed_conversation',
    description: 'User who closed the conversation'
  }
]

type ContactOwnershipCardProps = {
  description: string
  warning?: string
}

export default function ContactOwnershipCard (props: ContactOwnershipCardProps): JSX.Element {
  const { integrationSettings, handleSaved } = useContext(IntegrationPageContext)
  const { setCanSave, shouldSave } = useContext(TabContext)
  const [newSettings, setNewSettings] = useState(integrationSettings)
  const [ownerRules, setOwnerRules] = useState(integrationSettings.config?.owner_rules || [])
  const [priority, setPriority] = useState<number[]>(ownerRulesSettings.filter(s => ownerRules.includes(s.key)).map(s => ownerRulesSettings.indexOf(s)))
  const [defaultOwner, setDefaultOwner] = useState(integrationSettings.config?.fallback_contact_owner)
  const classes = useStyles()
  const title = integrationSettings.name?.charAt(0).toUpperCase() + integrationSettings.name?.substring(1)
  const { data: intUsers, isLoading: intUsersIsLoading } = GetIntegrationUsers<IntegrationUsers>(integrationSettings.name)

  const save = () => {
    setNewSettings({
      ...newSettings,
      config: {
        ...newSettings.config,
        owner_rules: ownerRules,
        fallback_contact_owner: defaultOwner
      },
      subtitle: '',
      searchField: ''
    })
  }

  HandleSave({
    shouldSave,
    saveFunction: () => {
      save()
      Integration.update({
        ...newSettings,
        config: {
          ...newSettings.config,
          owner_rules: ownerRules,
          fallback_contact_owner: defaultOwner
        },
        subtitle: '',
        searchField: ''
      })
    },
    handleSaved
  })
  return (
    <Card
      content={
        <div className={classes.groupContainer}>
          <Typography variant='subtitle1' className={classes.subtitle1}>
            {title} Owner
          </Typography>
          <Typography variant='body2' className={classes.bodyText}>
            {props.description}
          </Typography>
          {props.warning ? <ModalWarning body={props.warning} /> : <></>}
          <div style={{ marginBottom: 10 }}>
            {ownerRulesSettings.map((setting, index) => (
              <div key={index} className={classes.checkboxes}>
                <Checkbox
                  color='primary'
                  checked={ownerRules.includes(
                    setting.key
                  )}
                  onChange={(e) => {
                    setCanSave(true)
                    if (e.target.checked) {
                      setOwnerRules(ownerRules.concat([setting.key]))
                      setPriority(priority.concat([index]).sort())
                    } else {
                      setOwnerRules(ownerRules.filter((s: any) => s !== setting.key))
                      setPriority(priority.filter(p => p !== index).sort())
                    }
                  }}
                />
                <Typography style={{ fontWeight: 700, marginRight: 5 }}>{priority.includes(index) ? priority.indexOf(index) + 1 : undefined}</Typography>
                {setting.description}
              </div>
            ))}
          </div>
          <Typography variant='body2' className={classes.bodyText}>
            If an owner can’t be located, which {title} user would you like to designate as the owner?
          </Typography>
          {intUsersIsLoading ? <Loader type='spinner' />
          : (

            <Select
              variant='outlined'
              margin='dense'
              defaultValue
              className={classes.select}
              value={defaultOwner}
              onChange={(e) => {
                setCanSave(true)
                setDefaultOwner(e.target.value)
              }}
              renderValue={(selected: any) => { return <div>{selected.name}</div> }}
            >
              <MenuItem disabled style={{ opacity: 1 }}>
                <div className={classes.menuHeader}>{title} Users</div>
              </MenuItem>
              {intUsers.map((integrationUser: any, index: number) => {
                const values = 'attributes' in integrationUser ? integrationUser.attributes : integrationUser
                return (
                  <MenuItem key={index} className={classes.menuItem} value={values}>
                    {values?.name}
                  </MenuItem>
                )
              })}
            </Select>
          )}
        </div>
      }
    />
  )
}
