import React, { useEffect, useRef, useState } from 'react'
import { getFlow } from 'api/bots'
import { getSequenceFlow } from 'api/sequences'
import FlowSurface from './FlowSurface'

function sanitizeFlow (flow) {
  let edges = flow.data.edges
  edges = edges.map(edge => ({ ...edge, data: { ...edge.data, id: 'preview-' + edge.data.id }, source: 'preview-' + edge.source, target: 'preview-' + edge.target }))
  let nodes = flow.data.nodes
  nodes = nodes.map(node => ({ ...node, id: 'preview-' + node.id }))
  flow.data.edges = edges
  flow.data.nodes = nodes
  return flow
}

function FlowPreview (props) {
  const surfaceRef = useRef(null)
  const dataRef = useRef(null)
  const toolkitInstanceRef = useRef(null)
  const [flow, setFlow] = useState(null)
  const [dataLoaded, setDataLoaded] = useState(false)
  const flowID = props.flowID

  useEffect(() => {
    if (flowID) {
      if (props.sequence) {
        getSequenceFlow({ sequenceFlowID: flowID })
          .then(response => {
            setFlow(response.attributes.flow)
          })
      } else {
        getFlow({ flowID })
          .then(response => {
            const cleanFlow = sanitizeFlow(response.data.attributes.flow)
            setFlow(cleanFlow)
          })
      }
    }
  }, [flowID, props.sequence])

  useEffect(() => {
    if (flow && toolkitInstanceRef.current && surfaceRef.current && !dataLoaded) {
      setTimeout(() => {
        dataRef.current = flow
        toolkitInstanceRef.current.load(dataRef.current)
        setDataLoaded(true)
        surfaceRef.current.setZoom(0.7)
        surfaceRef.current.zoomToFitIfNecessary()
      }, 10)
    }
  }, [dataLoaded, flow])

  return (
    <div style={{ height: '100%', width: '100%', position: 'relative' }}>
      {/* <div style={{ height: '100%', width: '100%', position: 'absolute', backgroundColor: 'rgba(0,0,0,0.01)', zIndex: 1000000 }} /> */}
      <FlowSurface
        surfaceRef={surfaceRef}
        toolkitInstanceRef={toolkitInstanceRef}
        preview
      />
    </div>
  )
}

export default FlowPreview
