import { useState, useEffect } from 'react'
import { jsPlumbUtil } from 'jsplumbtoolkit'
import GenericPort from '../GenericPort'
import EditModal from '../EditModal'
// import RichTextEditor from '../../../../components/RichTextEditor'
import useNodeStyles from './NodeStyles'
import RenderEmoji from '../../../../pages/chat/RenderEmoji'
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn'
import '../../../../../node_modules/react-quill/dist/quill.snow.css'
import 'jsplumb'
import '../quill.css'
import { EVENT_TYPE, Emitter } from 'emitter'

function MessageModal (props) {
  const [text, setText] = useState(props.body)
  useEffect(() => {
    setText(props.body)
  }, [props.body])

  // function editText (value, delta, source, editor) {
  //   setText(value)
  // }

  function cancelChanges () {
    props.onHide()
    setText(props.body)
  }

  if (!props.open) {
    return <></>
  }

  // const toolbar = {
  //   container: [
  //     ['bold', 'italic', 'underline'],
  //     [{ list: 'ordered' }, { list: 'bullet' }],
  //     ['link', 'image'],
  //     ['clean']
  //   ]
  // }

  Emitter.on(EVENT_TYPE.BROWSER_BACK, cancelChanges)

  return (
    <EditModal
      open={props.open}
      onHide={cancelChanges}
      save={() => props.save(text)}
      title='Edit Message'
    >
      <div style={{ height: 160 }} className='customQuill'>
        {/* <RichTextEditor
          html={text}
          onChange={editText}
          placeholder='Type your message here...'
          style={{
            height: 100
          }}
          toolbar={toolbar}
        /> */}
      </div>
    </EditModal>
  )
}

const TextCaptureNode = props => {
  const modalOpen = props.modalOpen
  const setModalOpen = props.setModalOpen
  const base = props.base
  const classes = useNodeStyles(props)

  const assignKey = data => {
    data._reactKey = data._reactKey || jsPlumbUtil.uuid()
    return data._reactKey
  }

  const saveChanges = (message) => {
    if (!base.toolkit) {
      return
    }
    const node = base.getNode()
    const new_data = { ...base.state, body: message }
    base.toolkit.updateNode(node, new_data)
    setModalOpen(false)
  }

  return (
    <>
      <div>
        <div
          className={`${classes.dragger} ${classes.visitorInteraction}`}
        >
          <div className='node-header'>
            <div className={classes.iconDiv}><KeyboardReturnIcon /></div>
            <div className={classes.cardTitle}>Text Capture</div>
          </div>
          <div className='node-body' style={{ minHeight: 18 }}>
            <RenderEmoji
              text={base.state.body}
              rich='true'
            />
          </div>
          <jtk-target
            scope='default'
          />
        </div>
        {base.state.ports.map(c => (
          <GenericPort
            data={c}
            key={assignKey(c)}
            parent={base}
            color='#658AE5'
          />
        ))}
      </div>
      <MessageModal
        open={modalOpen}
        onHide={() => setModalOpen(false)}
        save={saveChanges}
        body={base.state.body}
      />
    </>
  )
}

export default TextCaptureNode
