const defaultFlow = {
  type: 'flows',
  attributes: {
    flow: {
      nodes: []
    },
    targeting: {
      triggers: [],
      use_triggers: false,
      trigger_on: 'selected_criteria'
    },
    frequency: 'every time',
    template: {
      teams: [{
        team_id: null,
        user_ids: [],
        method: 'single_agent',
        label: 'Click here',
        welcome: 'Routing you to an agent now',
        away: 'Sorry, no one is available to chat right now'
      }],
      routeOptions: { route_method: 'single_agent', type: 'round_robin', objectIDs: null },
      welcome_message: 'Welcome to our site!'
    },
    prompt_style: 'big',
    participant: {
      agent_name: 'Bot',
      avatar_url: ''
    }
  }
}

const defaultAbeFlow = {
  type: 'flows',
  attributes: {
    flow: {
      nodes: []
    },
    targeting: {
      triggers: [
        {
          cause: { kind: 'delay', value: 0 },
          enabled: true,
          filters: [
            { cmp: 'eq', value: 'available', prop: 'account_owner_availability', editable: false }
          ]
        }
      ],
      use_triggers: true,
      trigger_on: 'selected_criteria'
    },
    frequency: 'every time',
    template: {
      teams: [],
      welcome_message: 'Welcome to our site!'
    },
    prompt_style: 'big',
    participant: {
      agent_name: 'Bot',
      avatar_url: ''
    }
  }
}

const defaultBot = {
  type: 'bots',
  attributes: {
    kind: '',
    name: 'New Bot',
    fragment: '',
    click_selector: '',
    enabled: false
  }
}

function getContentPageBotValues ({ bot, flow, botType, botParticipant, contentPagesDict }) {
  let last_bot_delay = 0
  let last_url_matches = ''
  let last_availability_criteria = 'both'
  const use_triggers = false
  let trigger_on = 'selected_criteria'

  const contentPage = contentPagesDict[bot.id]

  if (flow.attributes.targeting.trigger_on) {
    trigger_on = flow.attributes.targeting.trigger_on
  }
  if (flow && flow.attributes.template.availability_criteria) {
    last_availability_criteria = flow.attributes.template.availability_criteria
  }
  if (flow && flow.attributes.template.hasOwnProperty('bot_delay')) {
    last_bot_delay = flow.attributes.template.bot_delay
  }
  if (flow && flow.attributes.template.hasOwnProperty('url_matches')) {
    last_url_matches = flow.attributes.template.url_matches
  }

  for (const team of flow.attributes.template.teams) {
    if (!team.route_method) {
      team.route_method = 'round_robin'
    }
  }

  const participant = flow.attributes.participant
  const agentName = participant ? participant.agent_name : botParticipant.attributes.agent_name
  const avatarUrl = participant ? participant.avatar_url : botParticipant.attributes.avatar_url
  let archive = false

  if (bot.attributes.deleted_timestamp) {
    archive = true
  }

  const defaultResponse = {
    name: bot.attributes.name,
    kind: bot.attributes.kind || botType,
    enabled: bot.attributes.enabled,
    fragment: bot.attributes.fragment,
    click_selector: bot.attributes.click_selector,
    welcome_message: flow.attributes.template.welcome_message,
    prompt_style: flow.attributes.prompt_style,
    teams: flow.attributes.template.teams,
    url_matches: last_url_matches,
    bot_delay: last_bot_delay,
    triggers: flow.attributes.targeting.triggers,
    use_triggers: use_triggers,
    trigger_on: trigger_on,
    participant_agent_name: agentName,
    participant_avatar_url: avatarUrl,
    availability_criteria: last_availability_criteria,
    archive: archive,
    route_method: flow.attributes.template.route_method || 'round_robin',
    participant_id: flow.attributes.participant_id || botParticipant.id,
    frequency: flow.attributes.frequency,
    is_content_page: true
  }

  if (!contentPage) {
    const defaultContentPage = {
      page_title: '',
      background_color: '#B1C3D8',
      secondary_color: '#FFFFFF',
      background_type: 'solid',
      bot_only: false,
      use_logo_url: false,
      logo_url: '',
      qr_code: '',
      pdf_content: '',
      logo_image: '',
      theme: 'light',
      page_name: ''
    }

    const newDict = Object.assign({}, defaultResponse, defaultContentPage)
    return newDict
  }

  return ({
    ...defaultResponse,
    content_page_id: contentPage.id,
    page_title: contentPage.attributes.title,
    background_color: contentPage.attributes.background_color,
    background_type: contentPage.attributes.background_type,
    secondary_color: contentPage.attributes.secondary_color,
    bot_only: contentPage.attributes.bot_only,
    use_logo_url: contentPage.attributes.use_logo_url,
    logo_url: contentPage.attributes.logo_url,
    qr_code: contentPage.attributes.qr_code,
    pdf_content: contentPage.attributes.pdf_content,
    logo_image: contentPage.attributes.logo_image,
    theme: contentPage.attributes.theme,
    page_name: contentPage.attributes.page_name
  })
}

function getAbeGreetingStartingValues ({ bot, flow, botType, botParticipant }) {
  let last_bot_delay = 0
  let last_url_matches = ''
  let last_availability_criteria = 'both'
  const use_triggers = false
  let trigger_on = 'selected_criteria'
  if (flow.attributes.targeting.trigger_on) {
    trigger_on = flow.attributes.targeting.trigger_on
  }
  if (flow && flow.attributes.template.availability_criteria) {
    last_availability_criteria = flow.attributes.template.availability_criteria
  }
  if (flow && flow.attributes.template.hasOwnProperty('bot_delay')) {
    last_bot_delay = flow.attributes.template.bot_delay
  }
  if (flow && flow.attributes.template.hasOwnProperty('url_matches')) {
    last_url_matches = flow.attributes.template.url_matches
  }

  for (const team of flow.attributes.template.teams) {
    if (!team.route_method) {
      team.route_method = 'round_robin'
    }
  }

  const participant = flow.attributes.participant
  const agentName = participant ? participant.agent_name : botParticipant.attributes.agent_name
  const avatarUrl = participant ? participant.avatar_url : botParticipant.attributes.avatar_url
  return ({
    name: bot.attributes.name,
    kind: bot.attributes.kind || botType,
    enabled: bot.attributes.enabled,
    fragment: bot.attributes.fragment,
    click_selector: bot.attributes.click_selector,
    welcome_message: flow.attributes.template.welcome_message,
    prompt_style: flow.attributes.prompt_style,
    teams: flow.attributes.template.teams,
    url_matches: last_url_matches,
    bot_delay: last_bot_delay,
    triggers: flow.attributes.targeting.triggers,
    use_triggers: use_triggers,
    trigger_on: trigger_on,
    participant_agent_name: agentName,
    participant_avatar_url: avatarUrl,
    availability_criteria: last_availability_criteria,
    route_method: flow.attributes.template.route_method || 'round_robin',
    participant_id: flow.attributes.participant_id || botParticipant.id,
    frequency: flow.attributes.frequency,
    participant_selection_method: flow.attributes.participant_selection_method || 'account_owner'
  })
}

function getStartingValues ({ bot, flow, botType, botParticipant }) {
  let last_bot_delay = 0
  let last_url_matches = ''
  let last_availability_criteria = 'both'
  const use_triggers = false
  let trigger_on = 'selected_criteria'
  if (flow.attributes.targeting.trigger_on) {
    trigger_on = flow.attributes.targeting.trigger_on
  }
  if (flow && flow.attributes.template.availability_criteria) {
    last_availability_criteria = flow.attributes.template.availability_criteria
  }
  if (flow && flow.attributes.template.hasOwnProperty('bot_delay')) {
    last_bot_delay = flow.attributes.template.bot_delay
  }
  if (flow && flow.attributes.template.hasOwnProperty('url_matches')) {
    last_url_matches = flow.attributes.template.url_matches
  }

  for (const team of flow.attributes.template.teams) {
    if (!team.route_method) {
      team.route_method = 'round_robin'
    }
  }

  const participant = flow.attributes.participant
  const agentName = participant ? participant.agent_name : botParticipant.attributes.agent_name
  const avatarUrl = participant ? participant.avatar_url : botParticipant.attributes.avatar_url

  return ({
    name: bot.attributes.name,
    kind: bot.attributes.kind || botType,
    enabled: bot.attributes.enabled,
    fragment: bot.attributes.fragment,
    click_selector: bot.attributes.click_selector,
    welcome_message: flow.attributes.template.welcome_message,
    prompt_style: flow.attributes.prompt_style,
    teams: flow.attributes.template.teams,
    url_matches: last_url_matches,
    bot_delay: last_bot_delay,
    triggers: flow.attributes.targeting.triggers,
    use_triggers: use_triggers,
    trigger_on: trigger_on,
    participant_agent_name: agentName,
    participant_avatar_url: avatarUrl,
    availability_criteria: last_availability_criteria,
    routeOptions: flow.attributes.template.routeOptions || { route_method: 'single_agent', type: 'round_robin', objectIDs: null },
    route_method: flow.attributes.template.route_method || 'round_robin',
    participant_id: flow.attributes.participant_id || botParticipant.id,
    frequency: flow.attributes.frequency,
    participant_selection_method: flow.attributes.template.participant_selection_method || 'default',
    mobile_prompt_style: flow.attributes.mobile_prompt_style
  })
}

export { defaultFlow, defaultBot, defaultAbeFlow, getStartingValues, getContentPageBotValues, getAbeGreetingStartingValues }
