import SettingsCard from 'cf-components/SettingsCard'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import SLUsageImage1 from 'img/SLUsage1.png'
import SLUsageImage2 from 'img/SLUsage2.png'
import SLUsageImage3 from 'img/SLUsage3.png'
import SLUsageImage4 from 'img/SLUsage4.png'
import SLUsageImage5 from 'img/SLUsage5.png'

const useStyles = makeStyles((theme) => ({
  subtitle1: {
    fontSize: 18,
    fontWeight: 600,
    marginBottom: 15
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  bodyText: {
    marginBottom: 30,
    fontSize: 16
  },
  lineHeader: {
    marginTop: '10px',
    fontSize: '16px',
    color: 'black'
  },
  lineItem: {
    display: 'flex',
    flexDireciton: 'row',
    marginTop: '10px'
  },
  image: {
    marginTop: '10px'
  }
}))

export default function UsageCard () {
  const classes = useStyles()

  return (
    <SettingsCard
      title='How to use it'
      subtitle=''
    >
      <Typography variant='body2' className={classes.bodyText}>
        <div className={classes.lineHeader}>
          Agent Chat
        </div>
        <div className={classes.lineItem}>
          1. First, add the Salesloft card to your sidebar
        </div>
        <div className={classes.image}>
          <img src={SLUsageImage1} alt='usage-1' />
        </div>
        <div className={classes.lineItem}>
          2. If you know someone's email address, you can then create a person in Salesloft, add them to a cadence, create a task, and view any upcoming steps and past activity
        </div>
        <div className={classes.lineHeader}>
          PlayMaker
        </div>
        <div className={classes.lineItem}>
          1. Use the Salesloft skill to add prospects or return visitors to cadences or remove them from cadences, and set rules for how people will be assigned owners in Salesloft.
        </div>
        <div className={classes.image}>
          <img src={SLUsageImage2} alt='usage-2' />
        </div>
        <div className={classes.lineItem}>
          2. Use the Salesloft Cadence criteria in the Visitor Attributes branching skill to create a targeted experience for visitors who are already enrolled in certain cadences.
        </div>
        <div className={classes.image}>
          <img src={SLUsageImage3} alt='usage-3' />
        </div>
        <div className={classes.lineHeader}>
          Bot Builder
        </div>
        <div className={classes.lineItem}>
          1. Use the Salesloft skill to add visitors to cadences or remove them from cadences, and set rules for how people will be assigned owners in Salesloft.
        </div>
        <div className={classes.image}>
          <img src={SLUsageImage4} alt='usage-4' />
        </div>
        <div className={classes.lineItem}>
          2. Use the Salesloft Cadence criteria in the Conditional Branching skill to create a targeted experience for visitors who are already enrolled in certain cadences.
        </div>
        <div className={classes.image}>
          <img src={SLUsageImage5} alt='usage-5' />
        </div>
      </Typography>
    </SettingsCard>
  )
}
