import { makeStyles } from '@material-ui/core/styles'
import { Select } from 'cf-components/material-wrappers/MaterialComponents'
import { Icon } from 'library/materialUI'
import { useEffect, useState } from 'react'
import NumberSelector from './NumberSelector'
import { DealCycleInterval } from 'classes/scoreSettings'
import { ModalSection } from 'library/Modal'

const useStyles = makeStyles(theme => ({
  oppTypeHeader: {
    fontSize: '20px',
    fontFamily: 'Poppins',
    color: theme.palette.primary.main,
    fontWeight: 'bold'
  },
  innerText: {
    fontSize: '14px',
    fontFamily: 'Poppins',
    color: '#00000080'
  },
  selector: {
    maxHeight: 35
  },
  cycleDisplay: {
    fontSize: '16px',
    fontWeight: 'bold',
    fontFamily: 'Poppins',
    color: 'black'
  },
  iconButton: {
    marginLeft: '10px',
    cursor: 'pointer'
  }
}))

interface SingalsScoringStep2Props {
  dealCycleGrain: DealCycleInterval
  setDealCycleGrain: (value: DealCycleInterval) => void
  dealCycleValue: number
  setDealCycleValue: (value: number) => void
}

export default function SignalsScoringStep2 ({ dealCycleGrain, setDealCycleGrain, dealCycleValue, setDealCycleValue }: SingalsScoringStep2Props): JSX.Element {
  const classes = useStyles()
  const [editing, setEditing] = useState(false)
  const [tempGrain, setTempGrain] = useState<DealCycleInterval>(dealCycleGrain)
  const [tempValue, setTempValue] = useState(dealCycleValue)
  const grainOptions = [
    { value: 'day', label: 'Days' },
    { value: 'week', label: 'Weeks' },
    { value: 'month', label: 'Months' }
  ]

  const handleApply = () => {
    setEditing(false)
  }

  const handleCancel = () => {
    setDealCycleGrain(tempGrain)
    setDealCycleValue(tempValue)
    setEditing(false)
  }

  useEffect(() => {
    if (editing) {
      setTempGrain(dealCycleGrain)
      setTempValue(dealCycleValue)
    }
    // eslint-disable-next-line
  }, [editing])

  return (
    <ModalSection>
      <div className={classes.oppTypeHeader}>
        Average deal cycle
      </div>
      <div className={classes.innerText} style={{ marginTop: '12px' }}>
        Based on our assessment, the length of your average deal cycle is:
      </div>
      <div style={{ marginTop: '10px' }}>
        {!editing ? (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div className={classes.cycleDisplay}>
              {dealCycleValue} {dealCycleGrain}{dealCycleValue > 1 ? 's' : ''}
            </div>
            <div onClick={() => setEditing(true)} className={classes.iconButton}>
              <Icon icon='edit' />
            </div>
          </div>
        ) : (
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
            <div>
              <NumberSelector value={dealCycleValue} setValue={setDealCycleValue} />
            </div>
            <div style={{ width: '194px' }}>
              <Select
                options={grainOptions}
                value={dealCycleGrain}
                onChange={(value: DealCycleInterval) => setDealCycleGrain(value)}
                placeholder='Select Date Grain'
              />
            </div>
            <div onClick={() => handleApply()} className={classes.iconButton}>
              <Icon icon='check' color='green' />
            </div>
            <div onClick={() => handleCancel()} className={classes.iconButton}>
              <Icon icon='close' color='black' />
            </div>
          </div>
        )}
      </div>
    </ModalSection>
  )
}
