import React, { useState } from 'react'
import { makeStyles, ThemeProvider } from '@material-ui/core/styles'
import { jsPlumbUtil } from 'jsplumbtoolkit'
import Divider from '@material-ui/core/Divider'
import useNodeStyles from './NodeStyles'
import ThumbsUpDownIcon from '@material-ui/icons/ThumbsUpDown'
import theme from '../../../../theme'
import TextField from '@material-ui/core/TextField'
import OptionPort from '../OptionPort'
import { Emitter, EVENT_TYPE } from 'emitter'
import { Modal, ModalSection } from 'library/Modal'
import { Checkbox, RadioButtons } from 'library/materialUI'

const useStyles = makeStyles(theme => ({
  ratingModal: {
    fontFamily: 'Poppins, sans serif',
    padding: 20,
    height: 'calc(100% - 40px)',
    overflowY: 'auto'
  },
  prompt: {
    fontSize: '14px',
    color: 'rgba(0,0,0,0.5)',
    marginBottom: 20
  },
  sectionHeader: {
    fontSize: '14px',
    display: 'flex',
    color: '#808080',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 15
  },
  label: {
    fontSize: '.9rem'
  },
  radio: {
    padding: 7
  },
  checkboxLabel: {
    fontSize: '14px',
    fontWeight: 600
  },
  contentClass: {
    padding: 0,
    height: 360,
    overflowY: 'hidden'
  }
}))

function ConversationRatingModal (props) {
  const classes = useStyles()
  const [ratingType, setRatingType] = useState(props.ratingType)
  const [askFeedback, setAskFeedback] = useState(props.askFeedback)
  const [ratingPrompt, setRatingPrompt] = useState(props.ratingPrompt)
  const [feedbackPrompt, setFeedbackPrompt] = useState(props.feedbackPrompt)
  const [endMessage] = useState(props.endMessage)

  const RatingTypes = [
    { value: 'emojis', label: 'Emojis' },
    { value: 'five_stars', label: '5 Stars' }
  ]

  Emitter.on(EVENT_TYPE.BROWSER_BACK, props.onHide)

  return (
    <Modal
      open={props.open}
      onHide={props.onHide}
      handleSave={() => props.save({ ratingType, askFeedback, ratingPrompt, feedbackPrompt, endMessage })}
      title='Conversation Rating'
      helplink='home/bots/nodes/RateConversation'
      helplinkLabel='Learn more about this skill'
      saveIcon='save'
    >
      <ModalSection
        subtitle='Ask visitors for feedback on their chat experience for this conversation'
      >
        <div className={classes.sectionHeader}>
          Rating prompt
        </div>
        <TextField
          margin='dense'
          fullWidth
          variant='outlined'
          placeholder='Please rate your conversation with ...'
          value={ratingPrompt}
          onChange={(e) => setRatingPrompt(e.target.value)}
          autoFocus
        />
        <div className={classes.sectionHeader}>
          Select which type of rating you'd like to display
        </div>
        <div style={{ marginLeft: 5 }}>
          <RadioButtons
            value={ratingType}
            handleChange={(value) => setRatingType(value)}
            buttons={RatingTypes.map((type) => ({ value: type.value, label: type.label }))}
          />
        </div>
        <div className={classes.sectionHeader}>
          <Checkbox
            checked={askFeedback}
            onChange={(value) => setAskFeedback(value)}
            label='Ask visitor for detailed feedback after rating'
          />
        </div>
        <TextField
          margin='dense'
          fullWidth
          variant='outlined'
          placeholder="We'd love to hear any feedback about your experience!"
          style={{ marginTop: 0, display: askFeedback ? 'flex' : 'none', marginBottom: 20 }}
          value={feedbackPrompt}
          onChange={(e) => setFeedbackPrompt(e.target.value)}
        />
      </ModalSection>
    </Modal>
  )
}

const ConversationRatingNode = props => {
  const base = props.base
  const classes = useNodeStyles(props)

  const assignKey = data => {
    data._reactKey = data._reactKey || jsPlumbUtil.uuid()
    return data._reactKey
  }

  const saveChanges = ({ ratingType, askFeedback, ratingPrompt, feedbackPrompt, endMessage }) => {
    if (!base.toolkit) {
      return
    }
    const node = base.getNode()
    const new_data =
    {
      ...base.state,
      ratingType: ratingType,
      askFeedback: askFeedback,
      ratingPrompt: ratingPrompt,
      feedbackPrompt: feedbackPrompt,
      endMessage: endMessage
    }
    base.toolkit.updateNode(node, new_data)
    props.setModalOpen(false)
  }

  return (
    <ThemeProvider theme={theme}>
      <div>
        <div
          className={`${classes.dragger} ${classes.visitorInteraction}`}
        >
          <div className='node-header'>
            <div className={classes.iconDiv}><ThumbsUpDownIcon /></div>
            <div className={classes.cardTitle}>Conversation Rating</div>
          </div>
          <div className='node-body'>
            <p>
              {base.state.ratingPrompt || 'Prompt for rating...'}
            </p>
            <Divider style={{ marginTop: 5 }} />
            <div>
              {base.state.ports.map((c, index) => {
                return (
                  <OptionPort
                    index={index}
                    data={c}
                    key={assignKey(c)}
                    parent={base}
                    kind='visitorInteraction'
                  />
                )
              })}
            </div>
          </div>
          <jtk-target
            scope='default'
          />
        </div>
      </div>
      <ConversationRatingModal
        open={props.modalOpen}
        onHide={() => props.setModalOpen(false)}
        save={saveChanges}
        ratingType={base.state.ratingType}
        askFeedback={base.state.askFeedback}
        ratingPrompt={base.state.ratingPrompt}
        feedbackPrompt={base.state.feedbackPrompt}
        endMessage={base.state.endMessage}
      />
    </ThemeProvider>
  )
}

export default ConversationRatingNode
