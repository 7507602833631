import { makeStyles } from '@material-ui/core/styles'
import { useEffect, useState } from 'react'

const useStyles = makeStyles(theme => ({
  buttonDiv: {
    display: 'flex',
    flexDirection: 'column',
    height: '91px',
    width: '115px',
    border: '1px solid grey',
    borderLeftColor: 'rgba(0,0,0,0)',
    justifyContent: 'center',
    textAlign: 'center',
    cursor: 'pointer',
    padding: 5
  },
  first: {
    display: 'flex',
    flexDirection: 'column',
    height: '91px',
    width: '115px',
    border: '1px solid grey',
    justifyContent: 'center',
    textAlign: 'center',
    cursor: 'pointer',
    padding: 5
  },
  selected: {
    display: 'flex',
    flexDirection: 'column',
    height: '89px',
    width: '113px',
    border: '3px solid #975ce6',
    borderLeftWidth: '2px',
    justifyContent: 'center',
    textAlign: 'center',
    cursor: 'pointer',
    padding: 5
  },
  firstSelected: {
    display: 'flex',
    flexDirection: 'column',
    height: '89px',
    width: '113px',
    border: '3px solid #975ce6',
    justifyContent: 'center',
    textAlign: 'center',
    cursor: 'pointer',
    padding: 5
  },
  textStyle: {
    fontSize: '18px',
    fontFamily: 'poppins'
  },
  extraTextStyle: {
    fontSize: '10px',
    color: 'red'
  },
  colStyle: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  rowStyle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  }
  }))

export interface BoxButtonProps {
  id?: number
  extraText?: string
  text: string
  selected: boolean
  handleSelect?: any
  buttonState?: any
}

export interface BoxButtonRowProps {
  offers: any
  multiSelect?: boolean
  direction?: 'horizontal' | 'vertical'
  setPlanType?: any
  defaultPlan?: number
}

export function BoxButtonRow (props: BoxButtonRowProps) {
  // const multiSelect = props.multiSelect
  const direction = props.direction
  const classes = useStyles()
  const offers = props.offers
  const defaultPlan = props.defaultPlan || 1
  const [buttonState, setButtonState] = useState(offers.map((value: any) => (value.offerType === defaultPlan)))
  const setPlanType = props.setPlanType
  const styleUse = direction === 'horizontal' ? classes.rowStyle : classes.colStyle
  const handleSelect = (toggleIndex: number) => {
    const tempList = new Array(buttonState.length)
    tempList.fill(false)
    tempList[toggleIndex] = true
    setButtonState(tempList)
    setPlanType(offers[toggleIndex].id)
  }

  return (
    <div className={styleUse}>
      {offers.map((value: any, index: any) => (
        <BoxButton
          key={index}
          id={index}
          extraText=''
          text={value.name}
          selected={buttonState[index]}
          handleSelect={handleSelect}
          buttonState={buttonState}
        />
        ))}
      {offers.length < 1 && (
        <div>
          No offers available
        </div>
      )}
    </div>
  )
}

function BoxButton (props: BoxButtonProps) {
  const id = props.id || 0
  const extraText = props.extraText || ''
  const text = props.text
  const [selected, setSelected] = useState(props.selected)
  const buttonState = props.buttonState
  const classes = useStyles()
  const handleClick = () => {
    props.handleSelect(id)
  }

  useEffect(() => {
    setSelected(buttonState[id])
  }, [buttonState, id])

  return (
    <div className={selected ? (id === 0 ? classes.firstSelected : classes.selected) : (id === 0 ? classes.first : classes.buttonDiv)} onClick={() => handleClick()}>
      <div className={classes.extraTextStyle}>
        {extraText}
      </div>
      <div className={classes.textStyle}>
        {text}
      </div>
    </div>
  )
}
