import GenericObject from './genericObject'
import { useDoQuery } from './useDoQuery'
import { doPut } from 'api/api'
import { pick } from 'lodash'

const defaultData = {
  triggers: {},
  audience: {},
  pages: {},
  content: {}
}

const dbProps = ['audience', 'content', 'pages', 'triggers', 'enabled', 'adminUserIDs']

export class NPSSettings extends GenericObject {
  triggers: any
  audience: any
  pages: any
  content: any
  enabled: boolean
  adminUserIDs: number[]
  shouldShowPrompt?: boolean

  static load = (): { settings: NPSSettings, isLoading: boolean } => {
    const { data, isLoading } = useDoQuery({
      path: '/nps/settings',
      objectClass: NPSSettings,
      initialData: defaultData
    })
    return { settings: data, isLoading }
  }

  static save = (settings: NPSSettings) => {
    const path = `/nps/settings/${settings.id}`
    const data = {
      type: 'nps_settings',
      attributes: { ...pick(settings, dbProps) }
    }
    return doPut({ path, data })
  }

  constructor ({ row }: { row: Record<string, any> }) {
    super({ row })
    const settings = row.attributes
    this.triggers = settings.triggers
    this.audience = settings.audience
    this.pages = settings.pages
    this.content = settings.content
    this.enabled = settings.enabled
    this.shouldShowPrompt = settings.should_show_prompt
    this.adminUserIDs = settings.admin_user_ids
  }
}
