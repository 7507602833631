const Description = (): JSX.Element => {
  return (
    <div>
      Use this skill to automatically create account records in your chosen integration for the companies that your website visitors work at.
    </div>
  )
}

const HowItWorksPara1 = 'Account records will be created for a visitor’s company when an account doesn’t already exist and the visitor matches the criteria preceding the Create Account skill'
const howItWorksPara2 = 'You can also optionally add an attribute to the account record for easy organization in your integration'

const HowItWorks = (): JSX.Element => {
  return (
    <div>
      <ul>
        <li style={{ marginBottom: 10 }}>{HowItWorksPara1}</li>
        <li style={{ marginBottom: 10 }}>{howItWorksPara2}</li>
      </ul>
    </div>
  )
}

const bestPracticeText1 = 'Use Branching skills before your Create Account skill to be more selective about which accounts are created and keep your CRM as free from clutter as possible. Think about factors you find important when creating accounts, like visiting certain pages or matching certain criteria such as intent (Signals Score) or fit (Ideal Customer Profile)'
const bestPracticeText2 = 'Use attributes to keep track of which companies were automatically created by Signals, which visited certain pages, and much more'

const BestPractices = (): JSX.Element => {
  return (
    <div>
      <ul>
        <li style={{ marginBottom: 10 }}>{bestPracticeText1}</li>
        <li style={{ marginBottom: 10 }}>{bestPracticeText2}</li>
      </ul>
    </div>
  )
}

export const createAccountDocs = {
  bestPractices: BestPractices,
  howItWorks: HowItWorks,
  description: Description
}
