import { doGet, doPatch } from 'api/api'

export function getScriptSettings () {
    const path = '/admin_portal/tenants/script_settings'
    return doGet({ path, useChatURL: true })
}

export function saveScriptSettings ({ attributes }) {
    const body = {
        attributes: attributes
    }
    const path = '/admin_portal/tenants/script_settings'
    return doPatch({ path, useChatURL: true, data: body })
}
