
const descriptionText = 'The Set Lead Score node allows you to automatically set the “lead score” for the web visitor, allowing you to see which leads are good, better or best for your business.'

const Description = (): JSX.Element => {
  return (
    <div>
      <div>
        {descriptionText}
      </div>
      {/* <ul>
        <li>whatever we want</li>
        <li>to put in a list</li>
      </ul> */}
    </div>
  )
}

const bestPracticeText = 'You need to place your Set Lead Score node after the web visitor’s information is saved within Signals as a Signals contact.  This occurs automatically with a successful Email Capture node or if you use the “map response to contact attribute” feature in conjuction with the Question node.  If there is not a Signals contact entry for the web visitor and you use the Set Lead Score node, the lead score will not be set for the web visitor.'

const BestPractices = () => {
  return (
    <div>
      <div>
        {bestPracticeText}
      </div>
      {/* <ul>
        <li>whatever we want</li>
        <li>to put in this list</li>
      </ul> */}
    </div>
  )
}

export const LeadScoreDocs = {
  helpCenterLink: 'https://help.getsignals.ai/article/5nehrp911o-set-lead-score-node',
  bestPractices: BestPractices,
  description: Description,
  videoLink: ''
}
