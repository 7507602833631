import { useDoQuery } from './useDoQuery'
import { GenericObject } from './genericObject'
import { LoadAllProps } from './queryHelpers'

interface TLDList {
    list: TLD[]
    dict: { [id: TLD['id']]: TLD }
}

const initialState = {
    name: 'NAME NOT FOUND'
}

export class TLD extends GenericObject {
    name: string
    category: string

    static loadAll (props?: LoadAllProps): { data: TLDList, isLoading: boolean } {
        const title = props?.searchParams?.search
        let path = '/integrations/enrich/tld'
        if (title) {
            path += `?search=${title}`
        }
        return useDoQuery({
            path: path,
            objectClass: TLD,
            searchParams: props?.searchParams,
            keepPreviousData: true
        })
    }

    constructor ({ row }: { row?: Record<string, any> }) {
        super({ row })
        const tld = row?.attributes || initialState
        this.name = tld.domain
        this.category = tld.description
    }
}
