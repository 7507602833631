import { makeStyles } from '@material-ui/core/styles'
import { useEffect, useState } from 'react'
import { ChargifyOffer } from 'classes/chargifyOffer'
import { ReactComponent as GreenCheckIcon } from 'img/icon-material-check.svg'
import { ReactComponent as RedXIcon } from 'img/icon-red-x.svg'
import Divider from '@material-ui/core/Divider'

const useStyles = makeStyles(theme => ({
    rowStyle: {
        display: 'flex',
        flexDirection: 'row',
        textAlign: 'left',
        paddingLeft: '20px',
        paddingBottom: '20px'
    },
    planInfo: {
        backgroundColor: '#975ce6',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '411px',
        borderRadius: '10px'
      },
      innerPlanInfo: {
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        width: '80%',
        alignSelf: 'center',
        borderRadius: '10px',
        textAlign: 'left'
      }
  }))

export default function PricingInformation (props: any) {
    const classes = useStyles()
    const { data: offers } = ChargifyOffer.loadAll()
    // const planType = props.planType === 1 ? 'Starter' : (props.planType === 2 ? 'Team' : (props.planType === 3 ? 'Company' : (props.planType === 4 && 'Premium')))
    // const curPlanType = props.curPlanType
    const annual = props.annual
    const [planInfo, setPlanInfo] = useState(offers.list.find((offer) => (offer.id === props.planType)))
    const [planType, setPlanType] = useState(planInfo?.name)

    useEffect(() => {
      if (props.planType) {
        setPlanInfo(offers.list.find((offer) => (offer.id === props.planType)))
        setPlanType(planInfo?.name)
      }
    }, [props.planType, planInfo?.name, offers])

    return (
      <>
        {planInfo && (
          <div className={classes.planInfo}>
            <div className={classes.innerPlanInfo} style={{ maxHeight: '719px', overflow: 'auto' }}>
              <div style={{ padding: '20px', color: '#975ce6' }}>
                {planType}
              </div>
              {planInfo && (
                <div className={classes.rowStyle} style={{ fontSize: '30px', fontWeight: 'bold' }}>
                  ${Math.round(annual ? planInfo.annualCostInCents / 100 : planInfo.monthlyCostInCents / 100)}
                  <div style={{ fontSize: '14px', paddingTop: '15px' }}>
                    {annual ? '/year' : '/month'}
                  </div>
                </div>
              )}
              <Divider />
              <div style={{ paddingTop: '20px', paddingLeft: '12px', paddingBottom: '20px' }}>
                {planInfo?.benefits.map((benefit, index) => (
                  <div key={index} style={{ display: 'flex', flexDirection: 'row', padding: '8px' }}><GreenCheckIcon style={{ paddingRight: '10px' }} /><div>{benefit}</div></div>
                ))}
                {planInfo?.missingBenefits.map((missing, index) => (
                  <div key={index} style={{ display: 'flex', flexDirection: 'row', padding: '8px' }}><RedXIcon style={{ paddingRight: '13px' }} /><div>{missing}</div></div>
                ))}
              </div>
            </div>
          </div>
        )}
      </>
    )
  }
