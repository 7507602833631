import React from 'react'
import { Button, Tooltip, IconButton } from '@material-ui/core'
import FilterSelector from 'pages/bot/criteria/FilterSelector'
import { FieldArray } from 'formik'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import { makeStyles } from '@material-ui/core/styles'
import DeleteIcon from '@material-ui/icons/DeleteOutline'
import ConditionalDivider from 'cf-components/ConditionalDivider'

const useStyles = makeStyles(theme => ({
  filterGroup: {
    border: '1px solid rgba(142,90,226,0.5)',
    backgroundColor: 'rgba(142,90,226,0.03)',
    borderRadius: 10
  },
  header: {
    display: 'flex',
    margin: '5px 5px 10px 15px',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  title: {
    fontSize: '0.95em',
    fontFamily: 'Poppins',
    color: 'rgba(0,0,0,0.75)',
    fontWeight: 600
  },
  icon: {
    marginRight: 10
  },
  iconButton: {
    padding: 8
  }
}))

const SegmentFilterGroup = props => {
  const classes = useStyles()
  const chatServiceUrl = props.chatServiceUrl
  const customFilters = props.customFilters

  return (
    <FieldArray
      name={`triggers[${props.index}].filters`}
      render={arrayHelpers => (
        <div className={classes.filterGroup}>
          <div className={classes.header}>
            <div className={classes.title}>
              {'Filter Group ' + (props.index + 1)}
            </div>
            <div>
              <Tooltip title='Delete filter group' placement='bottom'>
                <IconButton
                  className={classes.iconButton}
                  onClick={props.handleRemoveFilterGroup}
                >
                  <DeleteIcon color='primary' />
                </IconButton>
              </Tooltip>
            </div>
          </div>
          <div>
            {props.trigger.filters.map((filter, index) => (
              <div key={index}>
                <FilterSelector
                  customFilters={customFilters}
                  filter={filter}
                  removeFilter={() => arrayHelpers.remove(index)}
                  chatServiceUrl={chatServiceUrl}
                  propPrefix={`triggers[${props.index}].filters[${index}]`}
                />
                <ConditionalDivider
                  operator='and'
                  filterLen={props.trigger.filters.length}
                  index={index}
                />
              </div>
            ))}
            <Button
              className={classes.icon}
              startIcon={<AddCircleIcon />}
              color='primary'
              onClick={() => {
                arrayHelpers.push({
                  prop: '',
                  cmp: '',
                  value: []
                })
              }}
            >
              Add Criteria
            </Button>
          </div>
        </div>
      )}
    />
  )
}

export default SegmentFilterGroup
