import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import useNodeStyles from './NodeStyles'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'

const useStyles = makeStyles(theme => ({
  endOfFlowDiv: {
    marginTop: 15,
    backgroundColor: '#FF5D5C',
    color: 'white',
    fontWeight: 600,
    padding: 7,
    display: 'flex',
    justifyContent: 'center',
    borderRadius: 5
  }
}))

const EndFlowNode = props => {
  const classes = useNodeStyles(props)

  const localClasses = useStyles()

  return (
    <>
      <div>
        <div
          className={`${classes.dragger} ${classes.endOfFlow}`}
        >
          <div className='node-header'>
            <div className={classes.iconDiv}><CheckCircleIcon /></div>
            <div className={classes.cardTitle}>Bot Finished</div>
          </div>
          <div className='node-body'>
            No further bot actions
          </div>
          <jtk-target scope='default' />
        </div>
        <div className={localClasses.endOfFlowDiv}>
          END OF BOT FLOW
        </div>
      </div>
    </>
  )
}

export default EndFlowNode
