import { useDoQuery } from './useDoQuery'
import { DateType, castToDate } from './classHelpers'
import { chatRoles } from 'pages/users/UserManagement/userListHelpers'
import { GenericObject } from './genericObject'
import { doDelete, doGet, doPatch, doPost } from '../api/api'
import axios from 'axios'
import { queryClient } from 'App'
import { LoadAllProps } from './queryHelpers'

interface UserList {
  list: User[]
  dict: { [id: User['id']]: User }
}

export class User extends GenericObject {
  avatarUrl: string
  displayName: string
  email: string
  phone: string
  calendarStatus: string
  roleID: number
  tenantID: number
  readonly lastActivityDate: DateType
  readonly lastSessionActivityDate: DateType
  readonly lastLoginDate: DateType
  totalLogins: number
  mfa: boolean
  objectType = 'user'
  permissions: { [k: string]: boolean }
  availability: string | null
  outOfOffice: Array<any>
  participantID: number | null
  userPerms: { [k: string]: boolean }
  agentProfilePageHandle: string | null
  displayAgentProfilePage: boolean
  notificationSettings: Record<string, any>

  get role (): string { return chatRoles[this.roleID].value }
  get subtitle (): string { return this.email }
  get searchField (): string { return this.name + this.role }
  get link (): string { return `/users/${this.id}` }
  get toggleOn (): boolean { return this.availability === 'available' }
  get calendarIsLinked (): boolean { return this.calendarStatus === 'linked' }
  get calendarIsLinkedAndHasPerms (): boolean { return this.calendarIsLinked && this.permissions.book_meetings }
  get calendarAccess (): string { if (!this.permissions.book_meetings) { return 'noMeetingPermission' } else { return this.calendarStatus } }
  get currentlyOutOfOffice (): boolean {
    return this.outOfOffice.find(({ attributes }) => {
      const startDate = new Date(attributes.start_date)
      if (startDate > new Date()) {
        return false
      }
      const endDate = new Date(attributes.end_date)
      if (endDate < new Date()) {
        return false
      }
      return true
    })
  }
  get availabilityValues (): string {
    if (!this.permissions.send_chats) {
      return 'noChatPermission'
    } else if (this.currentlyOutOfOffice) {
      return 'OutOfOffice'
    } else {
      return 'toggle'
    }
  }
  get availabilitySort (): number {
    if (!this.permissions.send_chats) {
      return 3
    } else if (this.currentlyOutOfOffice) {
      return 2
    } else if (this.availability === 'available') {
      return 0
    } else {
      return 1
    }
  }
  get firstName (): string {
    return this.name.split(' ', 1)[0]
  }

  static loadAll (loadAllProps: LoadAllProps | undefined): { data: UserList, isLoading: boolean } {
    if (loadAllProps?.searchParams?.queryParams?.admin === '1') {
      return useDoQuery({ path: '/users/tenant/admins', objectClass: User })
    }
    return useDoQuery({ path: '/users', objectClass: User })
  }

  static loadOne (ID: User['id']): { data: User, isLoading: boolean, isError: boolean, error: Error } {
    return useDoQuery({ path: `/users/${ID}?include_deleted=1`, objectClass: User })
  }

  static loadMe (): { data: User, isLoading: boolean } {
    return useDoQuery({ path: '/users/me', objectClass: User })
  }

  static loadTenantSpecific (tenantID: any): { data: UserList, isLoading: boolean } {
    return useDoQuery({ path: `/api/admin_portal/users?tenant_id=${tenantID}`, objectClass: User })
  }

  static loadAdminOnly (): { data: UserList, isLoading: boolean } {
    return useDoQuery({ path: '/users/tenant/admins', objectClass: User })
  }

  static update (ID: User['id'], updates: any, silent?: boolean): Promise<any> {
    const path = `/users/${ID}`
    const data = {
      type: 'users',
      attributes: updates
    }
    return doPatch({ path, data, silent })
  }

  static removeUserFromTeams ({ ID }: { ID: User['id'] }): Promise<any> {
    const path = '/delete_user_from_teams'
    const body = {
      id: ID
    }
    return doPatch({ path: path, data: body, useChatURL: true })
  }

  static outOfOfficeChange ({ ID, type, data }: { ID: any, type: string, data?: any }): Promise<any> | undefined {
    let path
    let body
    switch (type) {
      case 'post':
        path = '/user_out_of_office'
        body = {
          ...data
        }
        return doPost({ path: path, data: body, useChatURL: true })
      case 'update':
        path = `/user_out_of_office/${data.id}`
        body = {
          ...data
        }
        return doPatch({ path: path, data: body, useChatURL: true })
      case 'delete':
        path = `/user_out_of_office/${ID}`
        return doDelete({ path: path, useChatURL: true })
    }
  }

  static postPhoto ({ ID, img }: { ID: number, img: any }) {
    const path = `/api/profile_pictures/${ID}`
    const data = { type: 'profile_pictures' }
    return doPost({ path, data })
      .then(urlResponse => {
        queryClient.invalidateQueries('users')
        const signedURL = urlResponse.meta.signed_url
        axios.put(signedURL, img, {
          headers: {
            'Content-type': 'image/png',
            'x-goog-acl': 'public-read'
          }
        })
      })
  }

  static linkedInOauth () {
    const path = '/api/linkedin/oauth'
    return doGet({ path }).then(response => {
      window.location.href = response.oauth_start_url
    })
  }

  delete (): Promise<any> {
    const path = '/users'
    const body = {
      attributes: {
        userIds: [this.id]
      }
    }
    return doDelete({ path: path, data: body })
  }

  constructor ({ row }: { row: Record<string, any> }) {
    super({ row })
    const user = row.attributes
    const calendarStatus = user.calendar_sync_status ? 'linked' : 'never'
    this.name = user.name || user.email
    this.displayName = user.display_name
    this.avatarUrl = user.profile_picture_url
    this.email = user.email
    this.phone = user.phone
    this.calendarStatus = calendarStatus
    this.roleID = user.role
    this.tenantID = row.relationships.tenant.data.id
    this.lastActivityDate = castToDate(user.last_activity)
    this.lastSessionActivityDate = castToDate(user.last_session_activity)
    this.lastLoginDate = castToDate(user.last_login)
    this.totalLogins = user.total_logins || 0
    this.mfa = Boolean(user.mfa_phone || user.mfa_email)
    this.permissions = user?.perms || {}
    this.availability = 'available'
    this.outOfOffice = []
    this.participantID = null
    this.userPerms = user.user_perms || {}
    this.agentProfilePageHandle = user.agent_profile_page_handle
    this.displayAgentProfilePage = user.display_agent_profile_page
    this.notificationSettings = user.notification_settings
  }
}

export default User
