import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Card, CardContent, Typography, Select, MenuItem, Button, FormControl, InputLabel } from '@material-ui/core'
import './wizard.css'

const useStyles = makeStyles(theme => ({
  selectorCard: {
    width: 550,
    borderRadius: 10,
    margin: 50
  },
  container: {
    display: 'flex',
    justifyContent: 'center'
  },
  cardContentArea: {
    height: '100%',
    backgroundColor: 'rgba(193, 166, 237, 0.1)',
    borderRadius: 10,
    padding: 30,
    paddingTop: 20
  },
  header: {
    backgroundColor: theme.palette.primary.main
  },
  subtitle1: {
    fontSize: 20,
    fontWeight: 600,
    color: 'white',
    padding: 20
  },
  subtitle2: {
    fontSize: 15,
    fontWeight: 600,
    marginBottom: 10
  },
  bodyText: {
    color: '#808080',
    marginBottom: 10,
    fontSize: 15
  },
  select: {
    borderRadius: 40
  },
  menuHeader: {
    borderBottom: '1px solid #BBB',
    width: '100%',
    fontWeight: 600,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: 'rgba(0,0,0,0.87)'
  },
  nextButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 20
  }
}))

const SelectSuccessMetric = props => {
  const classes = useStyles()
  const state = props.state
  const successMetrics = props.successMetrics
  const flowRef = props.flowRef

  for (const node of flowRef.current.flow.data.nodes) {
    if (node.type === 'goal') {
      if (node.goalType === 'primary' && !successMetrics.some(sm => sm.value === 'primary')) {
        successMetrics.push({ value: 'primary', name: 'Primary Goals' })
      }
      if (node.goalType === 'secondary' && !successMetrics.some(sm => sm.value === 'secondary')) {
        successMetrics.push({ value: 'secondary', name: 'Secondary Goals' })
      }
    }
  }

  return (
    <>
      <div className={classes.container}>
        <Card className={classes.selectorCard} raised>
          <div className={classes.header}>
            <Typography variant='subtitle1' className={classes.subtitle1}>
              Select Success Metric
            </Typography>
          </div>
          <CardContent
            className={classes.cardContentArea}
          >
            <Typography variant='body2' className={classes.bodyText}>
              Select a metric to test within your bot.
            </Typography>
            <Typography variant='subtitle2' className={classes.subtitle2}>
              Choose Your Success Metric
            </Typography>
            <div className='select'>
              <FormControl variant='outlined' fullWidth margin='dense'>
                <InputLabel id='success-metric'>Success Metric</InputLabel>
                <Select
                  labelId='success-metric'
                  id='success-metric'
                  label='Success Metric'
                  name='delay_time'
                  variant='outlined'
                  classes={{ outlined: classes.select }}
                  style={{ borderRadius: 40 }}
                  value={state.attributes.success_metric}
                  onChange={e => props.handleChange({ attr: 'success_metric', value: e.target.value })}
                >
                  <MenuItem disabled style={{ opacity: 1 }}>
                    <div className={classes.menuHeader}>Success Metrics</div>
                  </MenuItem>
                  {successMetrics.map((metric, index) => (
                    <MenuItem key={index} value={metric.value}>
                      {metric.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className={classes.nextButtonContainer}>
              <Button
                onClick={props.handleNext}
                className={classes.nextButton}
                variant='outlined'
                color='primary'
                disabled={!state.attributes.success_metric}
              >
                Next
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>
    </>
  )
}

export default SelectSuccessMetric
