import { CenteredPromptButton, NPSFeedbackHeader } from './NPSComponents';
import { makeStyles } from '@material-ui/core/styles'
import { TextBox } from 'library/materialUI';
import { useContext, useState } from 'react';
import { NPSPromptContext } from './NPSPromptContext';

const useStyles = makeStyles(theme => ({
  feedbackContainer: {
    padding: '10px 30px'
  },
  feedbackButton: {
    padding: '20px 30px 0px 30px',
    marginBottom: -15
  },
  title: {
    font: 'normal normal 600 16px/25px Poppins',
    color: '#000000',
    textAlign: 'center',
    marginBottom: 5
  },
  subtitle: {
    font: 'normal normal normal 14px/21px Poppins',
    color: '#00000080',
    textAlign: 'center',
    marginBottom: 20
  }
}))

type FeedbackScreenProps = {
  header: string
  body: string
  score: number
  goBack: () => void
}
export function FeedbackScreen ({ header, body, goBack }: FeedbackScreenProps) {
  const classes = useStyles()
  const { leaveFeedback } = useContext(NPSPromptContext)
  const [feedback, setFeedback] = useState('')
  return (
    <div>
      <NPSFeedbackHeader
        goBack={goBack}
        message=''
      />
      <div className={classes.title}>
        {header}
      </div>
      <div className={classes.subtitle}>
        {body}
      </div>
      <div className={classes.feedbackContainer}>
        <TextBox
          rows={3}
          value={feedback}
          onChange={(value: string) => setFeedback(value)}
        />
        <div className={classes.feedbackButton}>
          <CenteredPromptButton
            buttonText='Submit'
            onClick={() => leaveFeedback(feedback)}
            color='primary'
          />
        </div>
      </div>
    </div>
  )
}
