import { useEffect, useState, ReactNode, useCallback, useMemo, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Button from '@material-ui/core/Button'
import PuffLoader from 'react-spinners/PuffLoader'
import { useQueryClient } from 'react-query'
import { isEqual } from 'lodash'
import 'rc-slider/assets/index.css'
import { getCRMAccountTypes, getICPGuess } from 'api/icp'
import theme from 'theme'
import AccountICPHub from './AccountICPHub'
import AccountICPTypes from './AccountICPTypes'
import { ICPFilters, initialICPFilters } from 'classes/icp'
import { ICPContext } from './ICPProvider'
import { getEmployeeIndex, getRevenueIndex, validICPFilters } from './accountICPHelpers'
import { CRMContext } from 'crm-context'

const useStyles = makeStyles(theme => ({
  contentCtn: {
    color: '#383838',
    fontSize: 14
  },
  stepperStyling: {
    background: 'none',
    padding: '0 0 24px',
    margin: 'auto',
    maxWidth: 750
  },
  loadingCtn: {
    display: 'flex',
    justifyContent: 'center',
    padding: '20px 0'
  },
  loadingMsg: {
    color: 'rgba(0,0,0,0.5)',
    fontSize: '1.8em',
    fontWeight: 600,
    margin: '0 auto 20px',
    maxWidth: '17.4em',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center'
  },
  em12: { fontSize: '1.2em' },
  accountsCtn: {
    border: `1px solid ${theme.palette.primary.light}`,
    borderRadius: 10,
    padding: '10px 30px',
    maxHeight: '40vh',
    '& > div:first-child': {
      fontWeight: 600,
      fontSize: '1.1em',
      marginBottom: 14
    }
  },
  accountCtn: {
    marginTop: 8
  },
  purpleBold: {
    color: theme.palette.primary.main,
    fontWeight: 600
  },
  btnCtn: {
    '& > *:not(:first-child)': {
      marginLeft: 12
    }
  },
  staticBtnCtn: {
    position: 'absolute',
    top: 30,
    right: 0,
    display: 'flex',
    justifyContent: 'space-between',
    width: 'calc(100% - 80px)',
    margin: '0px 40px'
  },
  section: {
    padding: '10px 0'
  },
  sectionTitle: {
    fontSize: 15,
    fontWeight: 600,
    marginBottom: 5
  },
  sectionSubtitle: {
    marginBottom: 10,
    fontSize: 14
  }
}))

const steps = ['Account Types', 'Ideal Customer Profile']
const textForSteps = ['Run analysis', 'Save']
const icpCalcMinimumDuration = 8000
const icpCalculatingTexts = ['Analyzing your accounts to create a unique profile for you…', 'Using magic and science to find your ideal matches…', 'Finalizing your Ideal Customer Profile (ICP)…']

export default function AccountICPSetup ({ skipCRM, onCancel: propsOnCancel }: {
  onCancel: () => void
  skipCRM: boolean
}): JSX.Element {
  const { icpAccountSettings, icp, icpFilters, icpFiltersDispatch, saveICP, activeStep, setActiveStep } = useContext(ICPContext)
  const [calculating, setCalculating] = useState(false)
  const classes = useStyles()
  const queryClient = useQueryClient()
  useEffect(() => {
    setActiveStep(skipCRM ? 1 : 0)
  }, [skipCRM, setActiveStep])

  const onBack = useCallback((): void => {
    if (activeStep < 1 || skipCRM) {
      propsOnCancel()
    } else {
      setActiveStep(activeStep - 1)
    }
  }, [propsOnCancel, activeStep, setActiveStep, skipCRM])

  const onNext = useCallback((): void => {
    if (activeStep === 0) {
      setCalculating(true)
      Promise.all([
        getICPGuess(icpAccountSettings.customerAccountTypes, icpAccountSettings.propertyType)
        .then(res => {
          const resICPFilters = res.data.attributes
          let low = 0
          let nextRevenueRange: ICPFilters<'formatted'>['annualRevenue'] = initialICPFilters.annualRevenue
          if (resICPFilters.annual_revenue) {
            const revenueLow = resICPFilters.annual_revenue.min
            const revenueHigh = resICPFilters.annual_revenue.max
            low = getRevenueIndex(revenueLow)
            nextRevenueRange = { gt: low === 0 ? 0 : low - 1, lt: getRevenueIndex(revenueHigh) }
          }
          let nextEmployeeRange: ICPFilters<'formatted'>['employeeCount'] = initialICPFilters.employeeCount
          if (resICPFilters.employee_count) {
            const employeeLow = resICPFilters.employee_count.min
            const employeeHigh = resICPFilters.employee_count.max
            low = getEmployeeIndex(employeeLow)
            nextEmployeeRange = { gt: low === 0 ? 0 : low - 1, lt: getEmployeeIndex(employeeHigh) }
          }
          const newState = {
            industry: resICPFilters.industry || initialICPFilters.industry,
            state: resICPFilters.state || initialICPFilters.state,
            country: resICPFilters.location || initialICPFilters.country,
            annualRevenue: nextRevenueRange,
            employeeCount: nextEmployeeRange,
            technologies: resICPFilters.technologies || initialICPFilters.technologies
          }
          return newState
        }),
        new Promise(resolve => setTimeout(resolve, icpCalcMinimumDuration))
      ]).then((newState) => {
        if (!icp || icp.filters.length === 0 || !isEqual(newState, icp.filters[0])) {
          icpFiltersDispatch(newState[0])
        }
        setActiveStep(activeStep + 1)
        setCalculating(false)
      })
    } else if (activeStep === 1) {
      saveICP()
    }
    setActiveStep(activeStep + 1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep, setActiveStep, icpFilters, icpFiltersDispatch, queryClient, icpAccountSettings, saveICP])

  /** Whether the 'Next' button is disabled or not */
  const disabled = useMemo(() => {
    switch (activeStep) {
      case 0:
        return !icpAccountSettings.customerAccountTypes.length
      case 1:
        return !validICPFilters(icpFilters)
      default: return false
    }
  }, [activeStep, icpFilters, icpAccountSettings.customerAccountTypes.length])

  return (
    <>
      {skipCRM ? (
        <div style={{ minHeight: 40 }}>&nbsp;</div>
      ) : (
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          classes={{ root: classes.stepperStyling }}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      )}
      <div className={classes.contentCtn}>
        <AccountICPSection
          skipCRM={skipCRM}
          calculating={calculating}
        />
      </div>
      {activeStep < 2 &&
        <div className={activeStep > 0 ? classes.staticBtnCtn : classes.btnCtn}>
          <Button
            onClick={activeStep > 0 ? onBack : onNext}
            disabled={activeStep > 0 ? activeStep > 1 : disabled}
            variant={activeStep > 0 ? 'outlined' : 'contained'}
            color={activeStep > 0 ? 'default' : 'primary'}
          >
            {activeStep > 0 ? 'Back' : textForSteps[activeStep] || 'Create'}
          </Button>
          <Button
            onClick={activeStep > 0 ? onNext : onBack}
            disabled={activeStep > 0 ? disabled : false}
            variant={activeStep > 0 ? 'contained' : 'text'}
            color={activeStep > 0 ? 'primary' : 'default'}
          >
            {activeStep < 1 ? 'Cancel' : textForSteps[activeStep] || 'Create'}
          </Button>
        </div>}
    </>
  )
}

function AccountICPSection ({ skipCRM, calculating }: { skipCRM: boolean, calculating: boolean }): JSX.Element {
  const { activeStep } = useContext(ICPContext)
  const classes = useStyles()
  const [icpCalculatingText, setIcpCalculatingText] = useState(icpCalculatingTexts[0])
  const [altTypeOn, setAltTypeOn] = useState(false)
  const [accountTypes, setAccountTypes] = useState()
  const [accountAltTypes, setAccountAltTypes] = useState()
  const { crmIntegrationName } = useContext(CRMContext)

  useEffect(() => {
    getCRMAccountTypes('accounts', 'type').then(res => {
      const list = res.data.map((type: any) => type.id)
      setAccountTypes(list)
    })
    getCRMAccountTypes('accounts', 'record_type').then(res => {
      const list = res.data.map((type: any) => type.id)
      setAccountAltTypes(list)
    })
  }, [])

  useEffect(() => {
    if (activeStep === 1 && !skipCRM) {
      setIcpCalculatingText(icpCalculatingTexts[0])
      setTimeout(() => setIcpCalculatingText(icpCalculatingTexts[1]), icpCalcMinimumDuration / 3)
      setTimeout(() => setIcpCalculatingText(icpCalculatingTexts[2]), icpCalcMinimumDuration / 1.5)
    }
  }, [activeStep, setIcpCalculatingText, skipCRM])

  const loading = useMemo(() => {
    switch (activeStep) {
      case 0:
        return !accountTypes ? 'Gathering your Customer Data' : undefined
      case 1:
        return calculating && !skipCRM ? icpCalculatingText : undefined
      case 2:
        return 'Creating your ICP segment'
      default: return undefined
    }
  }, [activeStep, accountTypes, icpCalculatingText, skipCRM, calculating])

  if (loading) {
    return (
      <>
        <div className={classes.loadingCtn}>
          <PuffLoader
            color={theme.palette.primary.main}
            size={80}
          />
        </div>
        <div className={classes.loadingMsg}>{loading}</div>
      </>
    )
  }
  switch (activeStep) {
    case 0:
      return (
        <>
          <Section>
            With your CRM connected, we'll analyze your accounts and put together a profile of criteria that meets your ideal customer. We'll analyze past and current accounts from the account types you select below.
          </Section>
          {/* TODO: Add condition for when they have no customer types */}
          <AccountICPTypes accountTypes={altTypeOn ? accountAltTypes : accountTypes} label={(altTypeOn || crmIntegrationName !== 'hubspot') ? 'account types' : 'Lifecycle Stages'} alt={altTypeOn} setAlt={setAltTypeOn} displayAlt={crmIntegrationName === 'hubspot'} />
        </>
      )
    case 1:
      return (
        <>
          <Section>
            <AccountICPHub
              skipCRM={skipCRM}
            />
          </Section>
        </>
      )
    default:
      return <></>
  }
}

export function Section (props: {
  title?: string
  subtitle?: string
  children?: ReactNode
}): JSX.Element {
  const classes = useStyles()

  return (
    <div className={classes.section}>
      {props.title && (
        <div className={classes.sectionTitle}>
          {props.title}
        </div>
      )}
      {props.subtitle && (
        <div className={classes.sectionSubtitle}>
          {props.subtitle}
        </div>
      )}
      {props.children}
    </div>
  )
}
