import { makeStyles } from '@material-ui/core/styles'
import { DisplaySettings } from 'classes/displaySettings'
import { Button, Icon } from 'library/materialUI'
import { Emitter, EVENT_TYPE } from 'emitter'

const useStyles = makeStyles(theme => ({
  inAppWarningIcon: {
    position: 'relative',
    display: 'inline-block',
    top: '5px'
  },
  inAppWarningBtn: {
    color: 'white',
    border: '1px solid white',
    marginLeft: '10px'
  },
  inAppWarning: {
    padding: '10px',
    width: '100%',
    minHeight: '40px',
    color: 'gray',
    textAlign: 'center',
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    flexDirection: 'row',
    alignItem: 'center',
    justifyContent: 'center'
  },
  closeButton: {
    position: 'absolute',
    right: '15px',
    top: '16px',
    color: 'white',
    cursor: 'pointer'
  },
  text: {
    color: 'white',
    fontSize: '14px',
    fontFamily: 'poppins'
  }
}))

export default function AudioNotificationPermissionAlert () {
  const classes = useStyles()

  const handleClose = () => {
    DisplaySettings.update({ page: 'alert_banner', type: 'sound_alert', settings: { state: 'prompt' } })
  }

  const handleLearnMore = () => {
    Emitter.emit(EVENT_TYPE.HELP_CENTER, { path: 'home/settings/notifications' })
  }

  return (
    <div className={classes.inAppWarning}>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
        <div style={{ marginRight: '5px' }}>
          <Icon icon='warning' color='#F3D450' />
        </div>
        <div className={classes.text}>
          Signals needs your permission to enable audio notifications
        </div>
        <div>
          <Button
            className={classes.inAppWarningBtn}
            onClick={() => handleLearnMore()}
          >
            LEARN HOW
          </Button>
        </div>
      </div>
      <div onClick={() => handleClose()} className={classes.closeButton}>
        <Icon icon='close' color='white' />
      </div>
    </div>
  )
}
