import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Card, Select, MenuItem } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos'
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardIos'

const useStyles = makeStyles(theme => ({
  pageCount: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '14px'
  },
  paginationRow: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  paginationCard: {
    display: 'grid',
    gridTemplateColumns: '2.7fr 1fr .8fr',
    gridGap: 10,
    padding: '5px 15px',
    minWidth: '20%'
  },
  arrowsDiv: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  arrowIcons: {
    fontSize: '1rem',
    fill: '#4C4C4C'
  }
}))

export default function PaginationCard (props) {
  const classes = useStyles()
  const { state, dispatch, totalCount } = props
  const rowsPerPage = state.rowsPerPage
  const pageNumber = state.pageNumber

  const startingValue = totalCount ? (pageNumber - 1) * rowsPerPage + 1 : 0
  let endingValue = pageNumber * rowsPerPage
  endingValue = endingValue > totalCount ? totalCount : endingValue

  const countLabel = startingValue + '-' + endingValue + ' of ' + totalCount
  const disableBack = startingValue === 1
  const disableNext = endingValue >= totalCount

  return (
    <div className={classes.paginationRow}>
      <Card className={classes.paginationCard}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ marginBottom: 3, fontSize: '15px' }}>Rows per page:</div>
          <Select
            disableUnderline
            style={{ marginLeft: 10 }}
            value={rowsPerPage}
            onChange={(e) => { dispatch({ type: 'setRowsPerPage', value: e.target.value }) }}
          >
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={25}>25</MenuItem>
          </Select>
        </div>
        <div className={classes.pageCount}>{countLabel}</div>
        <div className={classes.arrowsDiv}>
          <ArrowBackIcon
            className={classes.arrowIcons}
            onClick={() => { if (!disableBack) { dispatch({ type: 'decrementPageNumber' }) } }}
            style={{ fill: disableBack ? '#aaa' : '#333', cursor: disableBack ? null : 'pointer', marginRight: 5 }}
          />
          <ArrowForwardIcon
            className={classes.arrowIcons}
            onClick={() => { if (!disableNext) { dispatch({ type: 'incrementPageNumber' }) } }}
            style={{ fill: disableNext ? '#aaa' : '#333', cursor: disableNext ? null : 'pointer' }}
          />
        </div>
      </Card>
    </div>
  )
}
