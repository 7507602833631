import { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { ListPageContext } from '../ListPage'
import { Avatar } from 'library/materialUI'
import { User } from 'classes/classes'
import { formatDate } from 'classes/classHelpers'

const useStyles = makeStyles(theme => ({
  editedBy: {
    padding: '5px 10px',
    borderRadius: 10,
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content'
  },
  editedText: {
    color: '#000000BF',
    font: 'normal normal normal 14px/21px Poppins',
    marginLeft: '10px',
    marginRight: '5px'
  },
  section: {
    margin: '10px',
    display: 'flex',
    flexDirection: 'column'
  },
  info: {
    border: '1px solid #D3D3D3',
    height: '45px',
    borderRadius: '5px',
    backgroundColor: '#FFFFFF',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  title: {
    color: '#00000080',
    fontSize: '12px',
    fontWeight: 'bold',
    marginBottom: '5px'
  }
}))

type EditedByProps = {
  user: User
  date: string
}

function EditedBy (props: EditedByProps) {
  const classes = useStyles()
  const { user, date } = props

  if (!user) {
    return (
      <div className={classes.editedBy}>
        <div className={classes.editedText}>
          {date}
        </div>
      </div>
    )
  }

  const editedText = date + ' by '

  return (
    <div className={classes.editedBy}>
      <Avatar
        src={user.avatarUrl}
        size='sm'
      />
      <div className={classes.editedText}>
        {editedText}
      </div>
      <div style={{ fontWeight: 'bold' }}>
        {user.name}
      </div>
    </div>
  )
}

export function FileHistoryDetails () {
  const classes = useStyles()
  const { object } = useContext(ListPageContext)
  const { data: users } = User.loadAll()

  const editedUserID = object.updatedUserID || object.createdByUserID
  const editedTimestamp = object.updatedTimestamp || object.createdTimestamp

  return (
    <div style={{ marginBottom: '20px' }}>
      <div style={{ display: 'flex' }}>
        <div className={classes.section}>
          <div className={classes.title}>
            CREATED
          </div>
          <div className={classes.info}>
            <EditedBy
              user={users.dict[object.createdByUserID]}
              date={object.createdDate}
            />
          </div>
        </div>
        <div className={classes.section}>
          <div className={classes.title}>
            LAST EDITED
          </div>
          <div className={classes.info}>
            <EditedBy
              user={users.dict[editedUserID]}
              date={formatDate(editedTimestamp)}
            />
          </div>
        </div>
      </div>
      {/* <Description description={object.description} /> */}
    </div>
  )
}
