import { useDoQuery } from './useDoQuery'
import { GenericObject } from './genericObject'
import { doDelete } from 'api/api'

interface MeetingTypeList {
  list: MeetingType[]
  dict: {[id: MeetingType['id']]: MeetingType}
}

export class MeetingType extends GenericObject {
  objectType = 'meeting'
  static loadAll (): { data: MeetingTypeList, isLoading: boolean } {
    return useDoQuery({
      path: '/meeting_types',
      objectClass: MeetingType,
      useChatURL: true
    })
  }

  static create (): void {
    window.open('/#/settings/meeting_types?new')
  }

  delete (): Promise<any> {
    const path = `/meeting_types/${this.id}`
    return doDelete({ path, useChatURL: true })
  }

  constructor ({ row }: { row?: Record<string, any> } = {}) {
    super({ row })
  }
}
