import React from 'react'
import { Card, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  card: {
    border: '1px solid rgba(142,90,226,0.5)',
    marginBottom: 15
  },
  explanation: {
    color: 'rgba(0,0,0,0.6)',
    fontSize: '0.9em',
    marginBottom: 40,
    marginRight: 20,
    marginLeft: 15
  },
  listItem: {
    margin: '8px 0px'
  },
  title: {
    marginTop: 15,
    marginLeft: 15
  }
}))

function ExplanationCard (props) {
  const classes = useStyles()

  const bullets = [
    'Configure the size of your prompt and how frequently you’d like visitors to see it.',
    (
      <div key={1}>
        Trigger your bot based on criteria like URL, segment, location, and more
        <Link style={{ marginLeft: 5, marginRight: 5 }} to='/playmaker' target='_blank'>
          using PlayMaker,
        </Link>
        or use the code below to trigger the bot when a visitor clicks a specific button on your site.
      </div>
    )
  ]

  return (
    <Card className={classes.card}>
      <div className={classes.title}>
        <Typography variant='subtitle1'>
          HOW IT WORKS
        </Typography>
      </div>
      <div className={classes.explanation}>
        <ul>
          {bullets.map((item, index) => (
            <li className={classes.listItem} key={index}>
              {item}
            </li>
          ))}
        </ul>
      </div>
    </Card>
  )
}

export default ExplanationCard
