import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import './quill.css'
import { getAllMeetingInfo } from 'api/meetings'
import { getTeam } from 'api/teams'
import Team from 'classes/teams'
import { Icon } from 'library/materialUI'

const useStyles = makeStyles(theme => ({
  subtitle: {
    color: '#999',
    fontFamily: 'Poppins, sans serif',
    fontSize: '12px'
  },
  divider: {
    marginTop: 10,
    marginBottom: 10
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    marginRight: 8
  },
  input: {
    padding: 6,
    borderRadius: 30
  },
  inputRoot: {
    borderRadius: 30
  },
  select: {
    height: 32,
    borderRadius: 30
  }
}))

const TeamPicker = props => {
  const classes = useStyles(props)
  const { data, isLoading, isError } = Team.loadAll()
  const [activeteam, setactiveteam] = useState('')

  useEffect(() => {
    if (props.kind === 'calendar') {
      if (props.value) {
        fetch('/api/users?include=calendar_status', {
          method: 'GET',
          headers: { 'Content-Type': 'application/vnd.api+json' }
        })
          .then(response => response.json())
          .then(response => {
            let userDict = {}
            response.data.map(user => (userDict = { ...userDict, [user.id]: { ...user } }))
            const hasCalendar = response.included.filter(user => user.attributes.has_calendar === 'True').map(user => user.id)

            getTeam({ teamID: props.value })
              .then(result => {
                const onTeam = result.data.relationships.teammembers.data.map(member => member.id)
                getAllMeetingInfo()
                  .then(response => {
                    const usersWithMeetingInfo = response.data.map(reponse => reponse.attributes.user_id)
                    const validUsers = usersWithMeetingInfo.filter(user_id => hasCalendar.includes(user_id))
                    const usersOnTeamWithMeetingInfo = validUsers.filter(user_id => onTeam.includes(user_id))
                    if (usersOnTeamWithMeetingInfo.length < onTeam.length) {
                      setactiveteam('At least one of the teammembers does not have their calendar set up.')
                    } else {
                      setactiveteam('')
                    }
                  })
              })
          })
      }
    }
  }, [props.kind, props.value])

  if (!isLoading && isError) {
    return (
      <div style={{ height: 42 }}><Icon icon='warning' />There was an error while loading your teams.</div>
    )
  }

  if (isLoading || !data) {
    return (
      <div style={{ height: 42 }} />
    )
  }

  return (
    <div className='select'>
      <Select
        fullWidth
        variant='outlined'
        value={props.value}
        onChange={(e) => props.setSelectedTeamId(e.target.value)}
        margin='dense'
        displayEmpty
      >
        <MenuItem value='' disabled>Select a team</MenuItem>
        {data.list.map(i => (
          <MenuItem key={i.id} value={i.id} style={{ height: 40 }}>{i.name}</MenuItem>
        ))}
      </Select>

      <div className={classes.subtitle} style={{ marginTop: 5, marginBottom: 5 }}>
        {activeteam}
      </div>
    </div>
  )
}

export default TeamPicker
