import React, { useState, useEffect, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import FreeTrialForm from './FreeTrialForm'
import SignalsLogo from 'img/Signals_Wordmark_Purple.svg'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useLocation, Redirect } from 'react-router-dom'
import CircuitBackground from 'img/circuit.png'
import IvantiIcon from 'img/ivanti.png'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import { SessionContext } from 'session-context'
import { signup, devSignup } from 'api/auth'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'relative',
    background: `linear-gradient(rgba(255,255,255,.8), rgba(255,255,255,.8)), url(${CircuitBackground})`,
    height: '100%',
    backgroundSize: 'contain'
  },
  card: {
    paddingBottom: 50,
    paddingTop: 50,
    borderRadius: 10,
    marginTop: 15,
    display: 'flex',
    maxWidth: 950,
    marginBottom: 100,
    marginRight: 30,
    marginLeft: 30
  },
  logo: {
    height: 65,
    marginTop: 25
  },
  carousel: {
    height: '100%',
    width: '32%',
    paddingLeft: 50,
    paddingRight: 50
  },
  formContainer: {
    width: '50%',
    paddingLeft: 20,
    paddingRight: 20
  },
  formContainerMobile: {
    width: '100%',
    paddingLeft: 40,
    paddingRight: 40
  },
  textField: {
    width: '100%',
    marginTop: 15
  },
  title: {
    fontSize: 30,
    textAlign: 'center',
    fontWeight: 100
  },
  mobileTitle: {
    fontSize: 24,
    textAlign: 'center',
    fontWeight: 100
  },
  confirmationTitle: {
    fontSize: 36,
    textAlign: 'center',
    marginTop: 100,
    marginBottom: 25,
    fontFamily: theme.typography.fontFamily
  },
  divider: {
    width: 2,
    backgroundColor: '#e6e6e6',
    marginTop: 50,
    marginBottom: 50
  },
  partnerImage: {
    display: 'block',
    height: 'auto',
    maxWidth: '100%',
    maxHeight: '100%',
    marginTop: 70
  },
  quoteStyles: {
    fontSize: 32,
    color: '#8841e6',
    textAlign: 'end',
    marginBottom: 50
  },
  authorName: {
    color: '#8841e6',
    textAlign: 'end',
    fontSize: 24
  },
  authorRole: {
    color: '#8841e6',
    textAlign: 'end',
    fontSize: 20,
    fontStyle: 'italic'

  },
  authorCompany: {
    color: '#8841e6',
    textAlign: 'end'
  }
}))

function useQuery () {
  return new URLSearchParams(useLocation().search)
}

const FreeTrialPage = (props) => {
  const devMode = props.dev || false
  const classes = useStyles()
  const width = 'auto'
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState()
  const query = useQuery()
  const small = useMediaQuery('(max-width: 1000px)')
  const { setUser } = useContext(SessionContext)
  const [loggedIn, setLoggedIn] = useState(false)

  const completeRegistration = values => {
    setLoading(true)
    if (devMode) {
      devSignup(values.full_name, values.email, values.password)
        .then(response => {
          if (response?.data?.id) {
            setTimeout(() => {
              setUser(response.data)
              setLoggedIn(true)
              setLoading(false)
            }, 4000)
          } else if (response.errors) {
            console.log(response.error)
          }
        })
    } else {
      signup(values.full_name, values.email, values.password)
        .then(response => {
          if (response?.data?.id) {
            setTimeout(() => {
              setUser(response.data)
              setLoggedIn(true)
              setLoading(false)
            }, 4000)
          } else if (response.errors) {
            console.log(response.error)
          }
        })
    }
  }

  const myStyle = { ...props.style, width: width }

  useEffect(() => {
    const queryEmail = query.get('email')
    if (queryEmail) {
      setEmail(queryEmail)
    }
  }, [query])

  if (loggedIn) {
    const profileRoute = '/'
    return <Redirect to={profileRoute} />
  }

  return (
    <>
      <div className={classes.container}>
        <div>
          <img src={SignalsLogo} className={classes.logo} alt='Signals Logo' />
        </div>
        <Card raised className={classes.card} style={myStyle}>
          <div className={small ? classes.formContainerMobile : classes.formContainer}>
            <div className={small ? classes.mobileTitle : classes.title}>Get Started for Free!</div>
            <Container><Typography style={{ textAlign: 'center' }}>10 day free trial</Typography></Container>
            <FreeTrialForm onSubmit={completeRegistration} small={small} email={email} loading={loading} devMode={devMode} />
          </div>
          {small
            ? <></>
            : (
              <>
                <div className={classes.divider} />
                <div className={classes.carousel}>
                  <div className={classes.quoteStyles}>
                    "Signals helped us generate over $1.8m in pipeline... in the last 90 days."
                  </div>
                  <div className={classes.authorName}>
                    Thomas Heile
                  </div>
                  <div className={classes.authorRole}>
                    Demand Gen Manager
                  </div>
                  <div className={classes.authorCompany}>
                    <img src={IvantiIcon} height={30} alt='Ivanti Logo' />
                  </div>
                  <div className={classes.slider} />
                </div>
              </>
            )}
        </Card>
      </div>
    </>
  )
}

export default FreeTrialPage
