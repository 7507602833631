import { useState } from 'react';
import { Accordion as MuiAccordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

type ControlledAccordionProps = {
  header: JSX.Element
  contents: JSX.Element
  expanded: boolean
  handleChange: (expanded: boolean) => void
  hideExpandIcon?: boolean
  style?: CSSProperties
  headerStyle?: CSSProperties
}

type DefaultAccordionProps = {
  header: JSX.Element
  contents: JSX.Element
  defaultOpen?: boolean
  hideExpandIcon?: boolean
  outsideChange?: () => void
  style?: CSSProperties
  headerStyle?: CSSProperties
}

type AccordionProps = ControlledAccordionProps | DefaultAccordionProps

function ControlledAccordion (props: ControlledAccordionProps): JSX.Element {
  const handleChange = (_event: any, isExpanded: boolean): void => {
    props.handleChange(isExpanded)
  }

  const expandIcon = props.hideExpandIcon ? null : <ExpandMore />

  return (
    <MuiAccordion
      expanded={props.expanded}
      onChange={handleChange}
      style={props.style ? props.style : {}}
    >
      <AccordionSummary
        expandIcon={expandIcon}
        style={props.headerStyle ? props.headerStyle : {}}
      >
        {props.header}
      </AccordionSummary>
      <AccordionDetails style={{ overflowWrap: 'anywhere' }}>
        {props.contents}
      </AccordionDetails>
    </MuiAccordion>
  )
}

function UncontrolledAccordion (props: DefaultAccordionProps): JSX.Element {
  const [expanded, setExpanded] = useState(props.defaultOpen || false)

  const handleChange = (isExpanded: boolean): void => {
    setExpanded(isExpanded)
    if (props.outsideChange) {
      props.outsideChange()
    }
  }

  return (
    <ControlledAccordion
      expanded={expanded}
      handleChange={handleChange}
      {...props}
    />
  )
}

export function Accordion (props: AccordionProps): JSX.Element {
  if ('expanded' in props) {
    return (
      <ControlledAccordion
        {...props}
      />
    )
  } else {
    return (
      <UncontrolledAccordion
        {...props}
      />
    )
  }
}
