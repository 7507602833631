import { jsPlumbUtil, Port } from 'jsplumbtoolkit'
import { arrayMoveImmutable } from 'array-move'
import { sentenceCase } from 'change-case'

type Dict = Record<string, any>
type Filter = {
  prop: string
  cmp: string
  value: any
}

type ConditionalPort = {
  id: string
  label: string
  filters?: Filter[][] | [[]]
}

const toggleFallbackBranch = (state: any) => {
  const currentStatus = state.settings.includeFallback
  let newState = {}
  if (state.ports.map((p: ConditionalPort) => p.id).includes('default')) {
    newState = handleRemovePort(state, 'default')
  } else {
    const port = { id: 'default', label: 'Everyone else' }
    newState = handleAddPort(state, port)
  }
  const newSettings = { ...state.settings, includeFallback: !currentStatus }
  return ({ ...newState, settings: newSettings })
}

// Used by Plays in Playrunner
const addNewBranch = (state: any) => {
  const id = jsPlumbUtil.uuid()
  const port = { id, label: 'Branch Label', filters: [[]] }
  return (handleAddPort(state, port))
}

const handleAddPort = (state: any, port: ConditionalPort) => {
  const ports = [...state.ports]
  ports.push(port)
  const portOrder = [...state.portOrder]
  const position = portOrder.includes('default') ? portOrder.length - 1 : portOrder.length
  portOrder.splice(position, 0, port.id)
  return { ...state, ports, portOrder }
}

const handleRemovePort = (state: any, portID: string) => {
  const newPorts = state.ports.filter((p: Port) => p.id !== portID)
  const portOrder = [...state.portOrder.filter((id: string) => id !== portID)]
  return { ...state, ports: newPorts, portOrder }
}

const handleReorderPorts = (state: any, portID: string, direction: 'up' | 'down') => {
  const index = state.portOrder.indexOf(portID)
  const newIndex = direction === 'up' ? index - 1 : index + 1
  const newOrder = arrayMoveImmutable(state.portOrder, index, newIndex)
  return { ...state, portOrder: newOrder }
}

const getCurrentFilters = (state: any, action: any) => {
  const activePort = state.ports.filter((p: Port) => p.id === action.id)[0]
  const remainingPorts = [...state.ports.filter((p: Port) => p.id !== action.id)]
  const filters = [...activePort.filters]
  return { activePort, filters, remainingPorts }
}

const handleRemoveOrGroup = (state: any, action: any) => {
  const { activePort, filters, remainingPorts } = getCurrentFilters(state, action)
  filters.splice(action.groupIndex, 1)
  const newPorts = [{ ...activePort, filters }, ...remainingPorts]
  return { ...state, ports: newPorts }
}

const handleAddOrGroup = (state: any, action: any) => {
  const { activePort, filters, remainingPorts } = getCurrentFilters(state, action)
  filters.push([])
  const newPorts = [{ ...activePort, filters }, ...remainingPorts]
  return { ...state, ports: newPorts }
}

const handleEditFilter = (state: any, action: any) => {
  const { activePort, filters, remainingPorts } = getCurrentFilters(state, action)
  const activeFilters = action.groupIndex === undefined ? [] : [...filters[action.groupIndex]]
  let label = activePort.label
  if (action.prop) {
    activeFilters[action.filterIndex].prop = action.prop
  }
  activeFilters[action.filterIndex].cmp = action.cmp
  activeFilters[action.filterIndex].value = action.value
  filters.splice(action.groupIndex, 1, activeFilters)

  if (action.groupIndex === 0 && action.filterIndex === 0) {
    if (label === 'Branch Label' && action.label) {
      label = sentenceCase(action.label)
    }
  }

  const newPorts = [{ ...activePort, filters, label }, ...remainingPorts]

  return { ...state, ports: newPorts }
}

const handlePortEdit = (state: any, action: any) => {
  const { activePort, filters, remainingPorts } = getCurrentFilters(state, action)
  const activeFilters = action.groupIndex === undefined ? [] : [...filters[action.groupIndex]]
  if (action.type === 'addNewFilter') {
    activeFilters.push({ prop: action.prop, cmp: '', value: '' })
  } else if (action.type === 'deleteFilter') {
    activeFilters.splice(action.filterIndex, 1)
  } else if (action.type === 'label') {
    const newPorts = [{ ...activePort, label: action.value }, ...remainingPorts]
    return { ...state, ports: newPorts }
  }
  filters.splice(action.groupIndex, 1, activeFilters)
  const newPorts = [{ ...activePort, filters }, ...remainingPorts]
  return { ...state, ports: newPorts }
}

export const branchingReducer = (state: Dict, action: Dict): any => {
  switch (action.type) {
    case 'toggleFallback':
      return (toggleFallbackBranch(state))
    case 'addBranch':
      return (addNewBranch(state))
    case 'removePort':
      return (handleRemovePort(state, action.id))
    case 'reorder':
      return (handleReorderPorts(state, action.id, action.direction))
    case 'orGroup':
      return (handleAddOrGroup(state, action))
    case 'removeOrGroup':
      return (handleRemoveOrGroup(state, action))
    case 'editFilter':
      return (handleEditFilter(state, action))
    default:
      return (handlePortEdit(state, action))
  }
}
