import { makeStyles } from '@material-ui/core/styles'
import { Select } from 'cf-components/material-wrappers/MaterialComponents'
import { OpportunityType } from 'classes/opportunityTypes'
import { ModalSection } from 'library/Modal'
import Loader from 'library/loading/Loader'

const useStyles = makeStyles(theme => ({
  oppTypeHeader: {
    fontSize: '20px',
    fontFamily: 'Poppins',
    color: theme.palette.primary.main,
    fontWeight: 'bold'
  },
  innerText: {
    fontSize: '14px',
    fontFamily: 'Poppins',
    color: '#00000080'
  },
  selector: {
    maxHeight: 35
  }
}))

interface SingalsScoringStep1Props {
  oppTypes: string[]
  setOppTypes: (value: string[]) => void
}

export default function SignalsScoringStep1 ({ oppTypes, setOppTypes }: SingalsScoringStep1Props): JSX.Element {
  const classes = useStyles()
  const { data: oppTypeList, isLoading, isError, error } = OpportunityType.loadAll()
  let content = <></>
  if (isLoading) {
    content = <div><Loader type='spinner' /></div>
  } else if (isError) {
    content = <div>Could not load opportunity types: {error.message}</div>
  } else {
    const oppListFinal = oppTypeList.list?.map((oppType: any) => {
      return { value: oppType.id, label: oppType.name }
    })
    content = (
      <Select
        multiple
        options={oppListFinal}
        value={oppTypes}
        onChange={(value: string[]) => setOppTypes(value)}
        placeholder='Select Opp Type(s)'
      />
    )
  }

  return (
    <ModalSection>
      <div className={classes.oppTypeHeader}>
        Opportunity types
      </div>
      <div className={classes.innerText} style={{ marginTop: '12px' }}>
        Select the opportunity types that you associate with new business in your CRM:
      </div>
      <div style={{ marginTop: '10px' }}>
        {content}
      </div>
    </ModalSection>
  )
}
