import React, { useReducer, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import TextField from '@material-ui/core/TextField'
import { sendSetupCode, enableMFA } from 'api/mfa'
import { Modal, ModalSection } from 'library/Modal'
import { Radio } from 'library/materialUI'

const useStyles = makeStyles(theme => ({
  radioCtn: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    width: 'max-content'
  },
  inputCtn: {
    marginTop: 8
  }
}))

/**
 * @param {string} email
 * @returns {boolean}
 */
export function validEmail (email) {
  if (!email) return false
  return /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(email.toLowerCase())
}
/**
 * @param {string} phoneNumber
 * @returns {boolean}
 */
export function validPhoneNumber (phoneNumber) {
  if (!phoneNumber) return false
  return /^\d{10,}$/.test(phoneNumber)
}

/**
 * @template {{ type: 'phone'|'email'; value: string; }} T
 * @param {T} state
 * @param {Partial<T>} options
 * @returns {T}
 */
const reducer = (state, options) => ({ ...state, ...options })

export default function SetupMFA ({ user, reloadUser, open, onHide }) {
  const classes = useStyles()
  const [inputState, inputDispatch] = useReducer(reducer, { type: 'phone', value: user.attributes.phone || '' })
  const [setupCode, setSetupCode] = useState(undefined)
  const [setupErrorMessage, setSetupErrorMessage] = useState('')
  const [setupCodeInput, setSetupCodeInput] = useState('')
  const [attemptedEnable, setAttemptedEnable] = useState(false)

  const validInputValue = inputState.type === 'email' ? validEmail(inputState.value) && !setupErrorMessage : validPhoneNumber(inputState.value) && !setupErrorMessage

  const saveSetupCode = () => {
    sendSetupCode({ [inputState.type]: inputState.value }).then(verificationCode => {
      setSetupCode(verificationCode)
    }, (err) => {
      const parts = err.message.split('bad_request:|:Bad Request -')
      if (parts.length > 1) {
        setSetupErrorMessage('Could not send mfa, ' + parts[1])
      } else {
        setSetupErrorMessage('Could not send mfa, please try again')
      }
    })
  }

  const saveEnableMFA = () => {
    if (setupCode === setupCodeInput) {
      enableMFA({ [inputState.type]: inputState.value }).then(res => {
        onHide()
        reloadUser()
        setSetupCodeInput('')
        setSetupCode(undefined)
      })
    } else {
      setAttemptedEnable(true)
    }
  }

  let helperText = setupErrorMessage
  if (helperText === '' && !validInputValue) {
    helperText = 'Please enter a valid '
    if (inputState.type === 'phone') {
      helperText += 'Cellphone Number'
    } else {
      helperText += 'Email Address'
    }
  }

  return (
    <Modal
      open={open}
      onHide={onHide}
      size='sm'
      title='Multi-factor Authentication'
      handleSave={!setupCode ? saveSetupCode : saveEnableMFA}
      saveBtnText={!setupCode ? 'Send setup code' : 'Enable MFA'}
      saveDisabled={!setupCode ? !validInputValue : !setupCodeInput.length || attemptedEnable}
      saveIcon={!setupCode ? 'send' : 'save'}
    >
      {!setupCode ? (
        <ModalSection
          title='Setup Multi-factor Authentication'
          subtitle='Choose how you would like to receive your security codes:'
        >
          <div className={classes.radioCtn} onClick={() => inputState.type !== 'phone' && inputDispatch({ type: 'phone', value: user.attributes.phone || '' })}>
            <Radio
              checked={inputState.type === 'phone'}
              color='primary'
              label='Text'
            />
          </div>
          <div className={classes.radioCtn} onClick={() => inputState.type !== 'email' && inputDispatch({ type: 'email', value: user.attributes.email || '' })}>
            <Radio
              checked={inputState.type === 'email'}
              color='primary'
              label='Email'
            />
          </div>
          <div className={classes.inputCtn}>
            <TextField
              style={{ minWidth: '100%' }}
              autoComplete={inputState.type === 'phone' ? 'tel' : 'email'}
              label={inputState.type === 'phone' ? 'Cellphone Number' : 'Email Address'}
              value={inputState.value}
              onChange={e => {
                setSetupErrorMessage('')
                inputDispatch({ value: e.target.value.trim() })
              }}
              error={!validInputValue}
              helperText={helperText}
              variant='outlined'
            />
          </div>
        </ModalSection>
      ) : (
        <ModalSection
          title='Message sent!'
          subtitle={`Enter the code you received via ${inputState.type === 'phone' ? 'text message' : 'email'} to proceed.`}
        >
          <div className={classes.inputCtn}>
            <TextField
              style={{ minWidth: '100%' }}
              label='MFA code'
              value={setupCodeInput}
              onChange={e => {
                setAttemptedEnable(false)
                setSetupCodeInput(e.target.value.trim())
              }}
              error={attemptedEnable}
              helperText={attemptedEnable && 'Invalid MFA code'}
              variant='outlined'
            />
          </div>
        </ModalSection>
      )}
    </Modal>
  )
}
