import React, { useEffect, useState, useCallback, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ZoomInIcon from '@material-ui/icons/ZoomIn'
import ZoomOutIcon from '@material-ui/icons/ZoomOut'
import FilterCenterFocusIcon from '@material-ui/icons/FilterCenterFocus'
import Tooltip from '@material-ui/core/Tooltip'
import PanIcon from '@material-ui/icons/OpenWith'
import SelectIcon from '@material-ui/icons/SelectAll'
import FullscreenIcon from '@material-ui/icons/Fullscreen'
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit'
import KeyboardIcon from '@material-ui/icons/Keyboard'
import { Modal } from 'cf-components/Modal'
import { jsPlumbUtil } from 'jsplumbtoolkit'
import { builderTabSelected } from './surfaceHelpers'

const useStyles = makeStyles(theme => ({
  sideControlsBar: {
    height: 'calc(100% - 14px)',
    padding: 7,
    borderLeft: '1px solid rgba(0,0,0,0.2)',
    display: 'grid',
    gridTemplateRows: 'auto auto 100px'
  },
  controlButton: {
    backgroundColor: 'rgba(0,0,0,0.06)',
    display: 'flex',
    padding: 5,
    borderRadius: 5,
    marginBottom: 20,
    border: '1px solid rgba(0,0,0,0.12)',
    cursor: 'pointer'
  },
  topButton: {
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
    marginBottom: 3
  },
  bottomButton: {
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0
  },
  controlIcon: {
    fill: 'rgba(0,0,0,0.6)'
  },
  controlIconDisabled: {
    fill: 'rgba(0,0,0,0.2)'
  },
  modalContents: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: 50,
    fontFamily: 'Poppins, sans serif'
  },
  shortcut: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '15px',
    color: '#555',
    marginBottom: 15
  },
  shortcutButton: {
    padding: '5px 12px',
    border: '1px solid #aaa',
    backgroundColor: '#eee',
    borderRadius: 3,
    fontWeight: 600,
    marginLeft: 5,
    marginRight: 5
  },
  shortcutKeys: {
    display: 'flex',
    alignItems: 'center'
  },
  action: {
    width: 150
  }
}))

function ShortcutsModal (props) {
  const classes = useStyles()

  return (
    <Modal
      title='Keyboard Shortcuts'
      onHide={props.onHide}
      open={props.open}
      size='md'
      noButton
      mode='plain'
    >
      <div className={classes.modalContents}>
        <div>
          <div className={classes.shortcut}>
            <div className={classes.action}>
              Copy
            </div>
            <div className={classes.shortcutKeys}>
              <div className={classes.shortcutButton}>
                CTRL
              </div>
              +
              <div className={classes.shortcutButton}>
                C
              </div>
            </div>
          </div>
          <div className={classes.shortcut}>
            <div className={classes.action}>
              Paste
            </div>
            <div className={classes.shortcutKeys}>
              <div className={classes.shortcutButton}>
                CTRL
              </div>
              +
              <div className={classes.shortcutButton}>
                V
              </div>
            </div>
          </div>
          <div className={classes.shortcut}>
            <div className={classes.action}>
              Delete Selection
            </div>
            <div className={classes.shortcutKeys}>
              <div className={classes.shortcutButton}>
                DEL
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className={classes.shortcut}>
            <div className={classes.action}>
              Select Multiple
            </div>
            <div className={classes.shortcutKeys}>
              <div className={classes.shortcutButton}>
                SPACE
              </div>
              +
              <div className={classes.shortcutButton}>
                Drag
              </div>
            </div>
          </div>
          <div className={classes.shortcut}>
            <div className={classes.action}>
              Select All
            </div>
            <div className={classes.shortcutKeys}>
              <div className={classes.shortcutButton}>
                CTRL
              </div>
              +
              <div className={classes.shortcutButton}>
                A
              </div>
            </div>
          </div>
          <div className={classes.shortcut}>
            <div className={classes.action}>
              Unselect All
            </div>
            <div className={classes.shortcutKeys}>
              <div className={classes.shortcutButton}>
                CTRL
              </div>
              +
              <div className={classes.shortcutButton}>
                D
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

function FullScreenButton (props) {
  const classes = useStyles()

  const el = document.documentElement
  el.onfullscreenchange = () => props.setFullScreen(!props.fullScreen)

  const clickHandler = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen()
    } else {
      const el = document.documentElement
      el.requestFullscreen(el)
    }
  }

  const SizeIcon = props.fullScreen ? FullscreenExitIcon : FullscreenIcon

  if (props.setFullScreen) {
    return (
      <Tooltip title='Full Screen' placement='left'>
        <div
          className={classes.controlButton}
          onClick={clickHandler}
          style={{ marginBottom: 5 }}
        >
          <SizeIcon
            className={classes.controlIcon}
          />
        </div>
      </Tooltip>
    )
  }

  return <></>
}

function pasteSelection (toolkit, surface, setSelection, isSequence) {
  const nodes = window.copiedNodes
  if (!nodes) {
    return
  }

  const pan = surface.getViewportCenter()
  let baseX, baseY

  const nodeIDs = nodes.map(n => n.id)
  const newNodes = []
  const nodeMapping = {}
  if (isSequence) {
    const sequences = nodes.filter(n => n.data.type === 'sequence')
    if (sequences.length) {
      return
    }
  }
  for (const node of nodes) {
    const data = { ...node.data }
    if (!baseX) {
      baseX = pan[0] - node.data.left
      baseY = pan[1] - node.data.top
    }
    data.left = node.data.left + baseX
    data.top = node.data.top + baseY
    const newID = jsPlumbUtil.uuid()
    data.id = newID
    const newNode = toolkit.addNode(data)
    nodeMapping[node.id] = newID
    newNodes.push(newNode)
  }
  for (const n of nodes) {
    const ports = n.getPorts()
    for (const port of ports) {
      const edges = port.getSourceEdges()
      for (const edge of edges) {
        const targetNode = edge.target
        const newNode = toolkit.getNode(nodeMapping[n.id])
        const source = newNode.getPort(port.id)
        if (nodeIDs.includes(targetNode.id)) {
          const target = nodeMapping[targetNode.id]
          const data = {
            id: jsPlumbUtil.uuid(),
            type: 'common'
          }
          toolkit.addEdge({ source, target, data })
        }
      }
    }
  }

  toolkit.clearSelection()
  toolkit.setSelection(newNodes)
  if (newNodes.length) {
    setSelection(toolkit.getSelection())
  }
}

function SideControlsBar (props) {
  const classes = useStyles()
  // const [canUndo, setCanUndo] = useState(false)
  // const [canRedo, setCanRedo] = useState(false)
  const [surfaceMode, setSurfaceMode] = useState('pan')
  const [modalOpen, setModalOpen] = useState(false)
  const spaceBarRef = useRef(null)
  const surface = props.surface
  const toolkit = props.toolkit

  const builderTab = builderTabSelected()

  const handleKeyDown = useCallback(e => {
    // if (e.ctrlKey && e.key === 'z') {
    //   surface.undoManager.undo()
    // }
    // if (window.sequenceNode && !props.sequence) {
    //   return
    // }
    if (builderTab) {
      const el = document.activeElement
      if (el.type === 'text' || el.contentEditable === 'true') {
        return
      }
      if ((e.ctrlKey || e.metaKey) && e.key === 'a') {
        e.preventDefault()
        const nodes = toolkit.getNodes().filter(n => n.data.id !== 'start')
        toolkit.setSelection(nodes)
        if (nodes.length) {
          props.setSelection(toolkit.getSelection())
        }
      }
      if ((e.ctrlKey || e.metaKey) && e.key === 'd') {
        e.preventDefault()
        toolkit.clearSelection()
        props.setSelection(null)
      }
      if ((e.ctrlKey || e.metaKey) && e.key === 'c') {
        toolkit.removeFromSelection(toolkit.getNode('start'))
        toolkit.removeFromSelection(toolkit.getNode('output'))
        const selectedNodes = toolkit.getSelection().getNodes()
        window.copiedNodes = [...selectedNodes]
      }
      if ((e.ctrlKey || e.metaKey) && e.key === 'v') {
        pasteSelection(toolkit, surface, props.setSelection, props.sequence)
      }
      if (e.keyCode === 46) {
        toolkit.removeFromSelection(toolkit.getNode('start'))
        toolkit.removeFromSelection(toolkit.getNode('output'))
        toolkit.remove(toolkit.getSelection())
        props.setSelection(null)
      }
      if (e.keyCode === 32 && spaceBarRef.current !== 'down' && !window.sequenceModal) {
        spaceBarRef.current = 'down'
        surface.setMode('select')
        props.setSelection(null)
        document.removeEventListener('keydown', handleKeyDown)
      }
    }
  }, [builderTab, props, surface, toolkit])

  const handleKeyUp = useCallback(e => {
    // if (e.ctrlKey && e.key === 'y') {
    //   surface.undoManager.redo()
    // }
    if (e.keyCode === 32 && !window.sequenceModal) {
      spaceBarRef.current = 'up'
      surface.setMode('pan')
      document.addEventListener('keydown', handleKeyDown)
    }
  }, [handleKeyDown, surface])

  useEffect(() => {
    if (surface) {
      document.addEventListener('keydown', handleKeyDown)
      document.addEventListener('keyup', handleKeyUp)
      document.addEventListener('mouseup', props.checkForSelection)
    }
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
      document.removeEventListener('keyup', handleKeyUp)
      document.removeEventListener('mouseup', props.checkForSelection)
    }
  }, [handleKeyDown, handleKeyUp, props.checkForSelection, surface])

  useEffect(() => {
    if (surface) {
      // eslint-disable-next-line
      // surface.undoManager = new jsPlumbToolkitUndoRedo({
      //   toolkit: toolkit,
      //   surface: surface,
      //   compound: true,
      //   onChange: (mgr, undoCount, redoCount) => {
      //     setCanUndo(undoCount > 0)
      //     setCanRedo(redoCount > 0)
      //   }
      // })
      surface.bind('modeChanged', (e) => {
        setSurfaceMode(e)
        if (e === 'select') {
          props.modeRef.current = 'select'
        }
      })
    }
  }, [props.modeRef, surface, toolkit])

  return (
    <div className={classes.sideControlsBar}>
      <div>
        <Tooltip title='Zoom To Fit' placement='left'>
          <div
            className={classes.controlButton}
            onClick={() => {
              surface.setZoom(0.7)
              surface.zoomToFitIfNecessary()
            }}
          >
            <FilterCenterFocusIcon
              className={classes.controlIcon}
            />
          </div>
        </Tooltip>
        {/* <Tooltip title='Undo' placement='left'>
          <div
            className={`${classes.controlButton} ${classes.topButton}`}
            onClick={() => surface.undoManager.undo()}
          >
            <UndoIcon
              className={canUndo ? classes.controlIcon : classes.controlIconDisabled}
            />
          </div>
        </Tooltip>
        <Tooltip title='Redo' placement='left'>
          <div
            className={`${classes.controlButton} ${classes.bottomButton}`}
            onClick={() => surface.undoManager.redo()}
          >
            <RedoIcon
              className={canRedo ? classes.controlIcon : classes.controlIconDisabled}
            />
          </div>
        </Tooltip> */}
        <Tooltip title='Zoom In' placement='left'>
          <div
            className={`${classes.controlButton} ${classes.topButton}`}
            onClick={() => surface.nudgeZoom(0.1)}
          >
            <ZoomInIcon
              className={classes.controlIcon}
            />
          </div>
        </Tooltip>
        <Tooltip title='Zoom Out' placement='left'>
          <div
            className={`${classes.controlButton} ${classes.bottomButton}`}
            onClick={() => surface.nudgeZoom(-0.1)}
          >
            <ZoomOutIcon
              className={classes.controlIcon}
            />
          </div>
        </Tooltip>
        <Tooltip title='Pan Mode' placement='left'>
          <div
            className={`${classes.controlButton} ${classes.topButton}`}
            onClick={() => surface.setMode('pan')}
          >
            <PanIcon
              className={surfaceMode === 'pan' ? classes.controlIcon : classes.controlIconDisabled}
            />
          </div>
        </Tooltip>
        <Tooltip title='Select Mode' placement='left'>
          <div
            className={`${classes.controlButton} ${classes.bottomButton}`}
            onClick={() => surface.setMode('select')}
          >
            <SelectIcon
              className={surfaceMode === 'select' ? classes.controlIcon : classes.controlIconDisabled}
            />
          </div>
        </Tooltip>
      </div>
      <div />
      <div>
        <Tooltip title='Keyboard Shortcuts' placement='left'>
          <div
            className={classes.controlButton}
            onClick={() => setModalOpen(true)}
          >
            <KeyboardIcon
              className={classes.controlIcon}
            />
          </div>
        </Tooltip>
        <FullScreenButton
          fullScreen={props.fullScreen}
          setFullScreen={props.setFullScreen}
        />
      </div>
      <ShortcutsModal
        open={modalOpen}
        onHide={() => setModalOpen(false)}
      />
    </div>
  )
}

export default SideControlsBar
