import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles'
import PanMode from '@material-ui/icons/PanTool';
import ZoomIn from '@material-ui/icons/ZoomIn'
import ZoomOut from '@material-ui/icons/ZoomOut'
import ZoomToFit from '@material-ui/icons/FilterCenterFocus'
import Fullscreen from '@material-ui/icons/Fullscreen'
import KeyboardIcon from '@material-ui/icons/Keyboard'
import Tooltip from '@material-ui/core/Tooltip'
import SelectMode from '@material-ui/icons/SelectAll'
import { Emitter, EVENT_TYPE } from '../helpers/EventEmitter'
import { Fab, Paper } from '@material-ui/core';
import { ShortcutsModal } from './ShortcutsModal';
import { Button } from 'library/materialUI';
import { triggerSupportBot } from 'pages/bot/InAppBot/triggerSupportBot';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close'
import { Play } from 'classes/plays';

const useStyles = makeStyles(theme => ({
  menu: {
    position: 'absolute',
    bottom: 20,
    right: 0,
    zIndex: 10,
    display: 'flex'
  },
  controlButton: {
    display: 'flex',
    padding: 5,
    borderRadius: 5,
    marginRight: 20,
    cursor: 'pointer'
  },
  leftButton: {
    marginRight: 3,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0
  },
  rightButton: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0
  },
  controlIcon: {

  },
  helpButton: {
    position: 'absolute',
    bottom: 80,
    right: 50,
    zIndex: 10,
    display: 'flex'
  },
  buttonContent: {
    padding: '24px',
    paddingTop: '28px',
    paddingBottom: '28px',
    fontFamily: 'poppins',
    textTransform: 'none',
    borderRadius: '28px'
  },
  helpModal: {
    backgroundColor: 'white',
    width: 300,
    height: 230,
    borderRadius: '10px',
    boxShadow: '2px 4px 6px #CCCCCC',
    fontFamily: 'poppins',
    fontSize: '14px',
    paddingLeft: '20px',
    paddingRight: '20px'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: '#FFF'
  }
}))

type button = {
  event: string
  icon: any
  label: string
  style?: string
}

type ButtonProps = {
  button: button
}

type SurfaceControlMenuProps = {
  withinPlayrunner?: boolean
}

const buttons: button[] = [
  { event: 'shortcuts', icon: KeyboardIcon, label: 'Keyboard Shortcuts' },
  { event: 'zoomToFit', icon: ZoomToFit, label: 'Zoom To Fit' },
  { event: 'zoomIn', icon: ZoomIn, label: 'Zoom In', style: 'left' },
  { event: 'zoomOut', icon: ZoomOut, label: 'Zoom Out', style: 'right' },
  { event: 'panMode', icon: PanMode, label: 'Pan Mode', style: 'left' },
  { event: 'selectMode', icon: SelectMode, label: 'Select Mode', style: 'right' },
  { event: 'fullScreen', icon: Fullscreen, label: 'Fullscreen' }
]

const handleFullScreen = () => {
  if (document.fullscreenElement) {
    document.exitFullscreen()
  } else {
    const el: any = document.documentElement
    el.requestFullscreen(el)
  }
}

function ControlButton ({ button }: ButtonProps): JSX.Element {
  const classes = useStyles()
  const Icon = button.icon
  const style = button.style
  const buttonClass = style ? (style === 'left' ? classes.leftButton : classes.rightButton) : null
  return (
    <Paper
      className={`${classes.controlButton} ${buttonClass}`}
      onClick={() => {
        if (button.event === 'fullScreen') {
          handleFullScreen()
        } else {
          Emitter.emit(EVENT_TYPE.SURFACE_CONTROL, { kind: button.event })
        }
      }}
    >
      <Tooltip title={button.label}>
        <Icon className={classes.controlIcon} />
      </Tooltip>
    </Paper>
  )
}

export function SurfaceControlsMenu ({ withinPlayrunner }: SurfaceControlMenuProps): JSX.Element {
  const [modalOpen, setModalOpen] = useState(false)
  const classes = useStyles()
  const [helpModal, setHelpModal] = useState(false)
  const [displayHelp, setDisplayHelp] = useState(true)
  const { data: play, isLoading } = Play.load()

  useEffect(() => {
    Emitter.on(EVENT_TYPE.SURFACE_CONTROL, (payload: any) => {
      const kind = payload.kind
      if (kind === 'shortcuts') {
        setModalOpen(true)
      }
    })
  }, [])

  useEffect(() => {
    if (!isLoading && play.enabled) {
      setDisplayHelp(false)
    }
  }, [isLoading, play.enabled])

  return (
    <>
      {displayHelp
      ? (
        <div className={classes.helpButton}>
          {!helpModal
          ? (
            <Fab
              className={classes.buttonContent}
              color='primary'
              size='large'
              variant='extended'
              onClick={() => setHelpModal(true)}
            >
              Need help setting up PlayMaker?
            </Fab>
          ) : (
            <div className={classes.helpModal}>
              <div>
                <p style={{ fontSize: '20px' }}>We can help!<IconButton aria-label='close' className={classes.closeButton} style={{ color: 'black' }} onClick={() => setHelpModal(false)}><CloseIcon /></IconButton></p>
                To request help, you can start a live chat below.
              </div>
              <Button style={{ position: 'absolute', bottom: '20px' }} color='primary' variant='contained' onClick={() => triggerSupportBot()}>
                Request a live chat
              </Button>
            </div>
          )}
        </div>
      )
      : <></>}
      <div className={classes.menu}>
        {buttons.map((button, index) => {
          if (withinPlayrunner && (button.event === 'selectMode')) {
            return <></>
          }
          return (
            <ControlButton
              key={index}
              button={button}
            />
          )
        })}
        <ShortcutsModal
          open={modalOpen}
          onHide={() => setModalOpen(false)}
        />
      </div>
    </>
  )
}
