import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import Dropzone from 'react-dropzone'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import FileIcon from '@material-ui/icons/InsertDriveFile'
import CloseIcon from '@material-ui/icons/Close'
import { Modal } from 'cf-components/Modal'
import { getBase64 } from './EmailLayout'
import ModalWarning from 'components/ModalWarning'

const useStyles = makeStyles(theme => ({
  attachment: {
    padding: '20px 15px',
    border: '2px solid #F0E3FF',
    borderRadius: 4,
    boxShadow: '0px 1px 1px #00000029',
    color: '#333333',
    fontSize: '15px',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 15
  },
  attachmentList: {
    width: 600
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 20,
    justifyContent: 'center'
  },
  addAttachmentButton: {
    marginTop: 15
  },
  fileName: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 20,
    fontSize: 18
  },
  closeIcon: {
    fontSize: '1.3em',
    marginLeft: 15,
    cursor: 'pointer'
  },
  fileSize: {
    marginTop: 10,
    fontSize: '15px'
  },
  // Dropzone styles
  rightIcon: {
    marginLeft: theme.spacing(1),
    color: 'gray'
  },
  fileIcon: {
    width: 60,
    height: 60,
    color: 'gray'
  },
  dropzone: {
    cursor: 'pointer',
    height: 280,
    display: 'flex'
  },
  paragraph: {
    color: 'gray',
    fontSize: 20,
    marginBottom: 0,
    marginTop: 10
  },
  paragraph2: {
    color: 'gray',
    fontSize: 13,
    marginBottom: 10,
    marginTop: 0
  },
  content: {
    margin: 'auto',
    width: '50%',
    textAlign: 'center',
    verticalAlign: 'center'
  }
}))

export function AttachmentDropzone (props) {
  const classes = useStyles()

  const handleDrop = acceptedFile => {
    getBase64(acceptedFile[0]).then(content => {
      props.handler(acceptedFile[0], content)
    })
  }

  return (
    <Dropzone onDrop={handleDrop}>
      {({ getRootProps, getInputProps }) => (
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <div className={classes.content}>
            <FileIcon className={classes.fileIcon} />
            <p className={classes.paragraph}>Drag a file here</p>
            <p className={classes.paragraph2}>-or-</p>
            <Button variant='contained' size='small'>
              Upload from computer
              <CloudUploadIcon className={classes.rightIcon} />
            </Button>
          </div>
        </div>
      )}
    </Dropzone>
  )
}

function UploadedFile (props) {
  const classes = useStyles()
  let size = props.size + ' B'
  if (props.size > 1000000) {
    size = (props.size / 1000000).toFixed(1) + ' MB'
  } else if (props.size > 1000) {
    size = (props.size / 1000).toFixed(1) + ' KB'
  }

  return (
    <>
      {props.size > 10000000 && (
        <ModalWarning
          body='Attachments larger than 10 MB can cause your emails to not be delivered. Please upload a smaller attachment.'
          type='error'
        />
      )}
      <div className={classes.content}>
        <FileIcon className={classes.fileIcon} />
        <div className={classes.fileName}>
          <div>{props.name}</div>
          <CloseIcon
            color='primary'
            onClick={props.removeFile}
            className={classes.closeIcon}
          />
        </div>
        <div className={classes.fileSize}>
          {size}
        </div>
      </div>
    </>
  )
}

function ModalContents (props) {
  if (props.file) {
    return (
      <UploadedFile
        name={props.file.name}
        removeFile={props.removeFile}
        size={props.file.size}
      />
    )
  } else {
    return (
      <AttachmentDropzone
        handler={props.handler}
      />
    )
  }
}

export function AttachmentModal (props) {
  const [file, setFile] = useState(null)

  const cancel = () => {
    props.onHide()
    setFile(null)
  }

  const removeFile = () => {
    setFile(null)
  }

  const save = () => {
    props.addAttachment(file)
    setFile(null)
    props.onHide()
  }

  const uploadFile = (file, content) => {
    setFile({
      name: file.name,
      content: content,
      contentType: file.type,
      size: file.size
    })
  }

  if (!props.open) {
    return <></>
  }

  return (
    <Modal
      open={props.open}
      onHide={props.onHide}
      size='sm'
      title='Add Attachment'
      rightButtonProps={{
        text: 'Add Attachment',
        action: save,
        variant: 'contained',
        disabled: !file || file.size > 10000000,
        icon: AttachFileIcon
      }}
      leftButtonProps={{
        text: 'Cancel',
        action: cancel
      }}
    >
      <ModalContents
        handler={uploadFile}
        file={file}
        removeFile={removeFile}
      />
    </Modal>
  )
}

function Attachment (props) {
  const classes = useStyles()
  return (
    <div className={classes.attachment} style={{ width: props.width || 570 }}>
      <div style={{ height: 24 }}>
        {props.name}
      </div>
      {props.delete &&
        <CloseIcon
          color='primary'
          onClick={props.delete}
          style={{ cursor: 'pointer' }}
        />}
    </div>
  )
}

export function AttachmentList (props) {
  const list = props.attachmentList
  if (list.length) {
    return (
      list.map((item, index) => (
        <Attachment
          name={item.name}
          delete={() => props.removeAttachment(index)}
          key={index}
          width={props?.width}
        />
      ))
    )
  } else {
    return (
      <Attachment
        name='No attachments'
      />
    )
  }
}

function AttachmentStep (props) {
  const [modalOpen, setModalOpen] = useState(false)
  const classes = useStyles()

  return (
    <div className={classes.attachmentList}>
      <div className={classes.title}>
        Add attachments to your email
      </div>
      <div>
        <AttachmentList
          attachmentList={props.attachments}
          removeAttachment={props.removeAttachment}
        />
      </div>
      <div className={classes.addAttachmentButton}>
        <Button
          color='primary'
          startIcon={<AttachFileIcon />}
          onClick={() => setModalOpen(true)}
        >
          Add attachment
        </Button>
      </div>
      <AttachmentModal
        open={modalOpen}
        onHide={() => setModalOpen(false)}
        addAttachment={props.addAttachment}
      />
    </div>
  )
}

export default AttachmentStep
