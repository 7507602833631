import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'

function am4themes_myTheme (target) {
  if (target instanceof am4core.ColorSet) {
    target.list = [
      am4core.color('rgb(103, 183, 220)')
    ]
  }
}

function createPieChart ({ data, id, setVisibility, orderVertically = false, legendHeight = undefined, eventFunc = undefined }) {
  am4core.addLicense('CH213476655')
  am4core.useTheme(am4themes_myTheme)
  am4core.useTheme(am4themes_animated)

  if (!document.getElementById(id)) {
    return
  }

  const chart = am4core.create(id, am4charts.PieChart)

  chart.data = data

  const series = chart.series.push(new am4charts.PieSeries())
  series.dataFields.value = 'count'
  series.dataFields.category = 'category'

  series.slices.template.stroke = am4core.color('#fff')
  series.slices.template.strokeOpacity = 1

  // This creates initial animation
  series.hiddenState.properties.opacity = 1
  series.hiddenState.properties.endAngle = -90
  series.hiddenState.properties.startAngle = -90
  series.ticks.template.disabled = true
  series.alignLabels = false
  series.labels.template.dataItem = data.dataItem || undefined
  series.labels.template.html = '<svg class=\'MuiSvgIcon-root\' focusable=\'false\' viewBox=\'-6 -12 40 40\' aria-hidden=\'true\'><path d=\'{dataItem}\'></path></svg>' || ''
  series.labels.template.radius = am4core.percent(-30)
  series.labels.template.fill = am4core.color('white')

  chart.hiddenState.properties.radius = am4core.percent(0)

  chart.legend = new am4charts.Legend()
  chart.legend.maxHeight = legendHeight || 100
  chart.legend.scrollable = true
  chart.legend.valueLabels.template.align = 'right'
  chart.legend.valueLabels.template.textAlign = 'end'
  if (!orderVertically) {
    chart.legend.position = 'right'
    chart.legend.maxHeight = legendHeight || 200
  }

  chart.events.on('ready', () => {
    setVisibility('visible')
  })

  if (eventFunc) {
    series.slices.template.events.on('hit', eventFunc, this)
  }

  return chart
}

export { createPieChart }
