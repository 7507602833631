import { makeStyles } from '@material-ui/core/styles'
import { useEffect, useState } from 'react';
import { jsPlumbUtil } from 'jsplumbtoolkit';
import { Popover } from 'library/materialUI';

const useStyles = makeStyles(theme => ({
  buttonDiv: {
    display: 'flex',
    justifyContent: 'center',
    height: '40px',
    alignItems: 'center',
    borderTopColor: '#00000020',
    borderTopWidth: '1px',
    borderTopStyle: 'solid',
    fontSize: '14px',
    fontFamily: 'poppins',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    cursor: 'pointer'
  }
}))

type PopoverDivProps = {
  popoverContents: JSX.Element
  divContents: JSX.Element
  divClass?: any
  open?: boolean
  handleClose?: (_event: any, reason: string) => void
  handleOpen?: () => void
  doneButton?: boolean
}

export function PopoverDiv (props: PopoverDivProps) {
  const classes = useStyles()
  const [id] = useState(jsPlumbUtil.uuid())
  const [open, setOpen] = useState(false)
  const [render, setRender] = useState(false)
  const anchorEl = document.getElementById(id)

  useEffect(() => {
    setRender(true)
  }, [open])

  const closePopover = () => {
    setOpen(false)
  }
  const openPopover = () => setOpen(true)
  const handleClose = props.handleClose || closePopover
  const handleOpen = props.handleOpen || openPopover

  const popoverOpen = props.open === undefined ? open : props.open && render

  return (
    <div>
      <div
        id={id}
        onClick={handleOpen}
        className={props.divClass}
      >
        {props.divContents}
      </div>
      <Popover
        open={popoverOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        {props.popoverContents}
        {props.doneButton && (
          <div className={classes.buttonDiv} onClick={() => closePopover()}>
            DONE
          </div>
        )}
      </Popover>
    </div>
  )
}
