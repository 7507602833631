import { makeStyles } from '@material-ui/core/styles'
import { AvatarLink } from 'cf-components/table/TableAvatars'
import { Avatar, Tooltip } from 'library/materialUI'
import { IconType, Icon } from 'library/materialUI/Icon'
import { useContext } from 'react'
import { ListPageContext } from '../ListPage'

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  avatarDisplay: {
    display: 'grid',
    gridTemplateColumns: '50px auto',
    gridGap: 15
  },
  objectName: {
    fontSize: '22px',
    fontWeight: 600,
    color: '#000000BF',
    lineHeight: '28px'
  },
  controlIcon: {
    cursor: 'pointer',
    marginRight: 15,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '90px',
    height: '40px',
    border: '1px solid #D3D3D3',
    borderRadius: '5px'
  }
}))

function ObjectAvatar () {
  const classes = useStyles()
  const { object } = useContext(ListPageContext)

  return (
    <AvatarLink link={object.link}>
      <div className={classes.avatarDisplay}>
        <Avatar
          name={object.name}
          variant='rounded'
          background='#9933FF'
          size='lg'
        />
        <div>
          <div className={classes.objectName}>
            {object.name}
          </div>
          <div>
            {object.subtitle}
          </div>
        </div>
      </div>
    </AvatarLink>
  )
}

type ControlType = {
  icon: IconType
  action: (object: any) => void
  tooltip: string
}

function ObjectControls (props: { objectType: string }) {
  const { object } = useContext(ListPageContext)
  const classes = useStyles()
  const { objectType } = props

  const controls: ControlType[] = [
    // { icon: 'clone', action: () => console.log('Clone'), tooltip: `Clone ${objectType}` },
    { icon: 'edit', action: (object: any) => window.open('#' + object.link, '_self'), tooltip: `Edit ${objectType}` }
    // needs to be updated so that if a bot, play, etc is currently in use a warning will be displayed instead of automatically deleting
    // Sequence.delete() can be used for plays
    // { icon: 'delete', action: () => console.log('Delete'), tooltip: `Delete ${objectType}` }
  ]

  return (
    <div style={{ display: 'flex' }}>
      {controls.map((control: ControlType, index: number) => (
        <Tooltip title={control.tooltip} key={index}>
          <div
            className={classes.controlIcon}
            onClick={() => control.action(object)}
          >
            <Icon
              icon={control.icon}
              color='#000000BF'
              size='sm'
            />
            <div style={{ marginLeft: '12px' }}>
              Edit
            </div>
          </div>
        </Tooltip>
      ))}
    </div>
  )
}

export function ObjectHeader (props: { objectType: string }) {
  const classes = useStyles()

  return (
    <div className={classes.header}>
      <div>
        <ObjectAvatar />
      </div>
      <div>
        <ObjectControls objectType={props.objectType} />
      </div>
    </div>
  )
}
