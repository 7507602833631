import Form, { formReducer, FormField } from 'classes/forms'
import { ChatSettings } from 'classes/chatSettings'
import { makeStyles } from '@material-ui/core/styles'
import { Snackbar, IconButton, SnackbarContent } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import WarningIcon from '@material-ui/icons/Warning'
import InfoIcon from '@material-ui/icons/Info'
import { useState, useReducer, useEffect, useContext } from 'react'
import FormBuilder from './forms/FormBuilder'
import FormPreview from './forms/FormPreview'
import { Button, Icon, Typography } from 'library/materialUI'
import { Modal } from 'library/Modal'
import AppPage from 'cf-components/AppPage'
import FormSettingsPage from './forms/FormSettingsPage'
import FormFieldBar, { FieldInfo } from './forms/FormFieldBar'
import FormStylesBar from './forms/FormStylesBar'
import { random } from 'lodash'
import { UnsavedModal } from './bot/bot-edit/BotForm'
import FormFollowupPage from './forms/FormFollowupPage'
import { SessionContext } from 'session-context'
import FormSubmissionTable from './forms/FormSubmissionTable'
import { FormMissingItemsModal } from './forms/FormMissingItemsModal'
import { Emitter, EVENT_TYPE } from 'emitter'
import FormErrorBar from './forms/FormErrorBar'
import { useQuery } from 'react-query'
import { hasComponent } from 'api/billing'

const useStyles = makeStyles(theme => ({
  header: {
    height: '112px',
    display: 'flex',
    border: '1px solid #00000028'
  },
  title: {
    margin: '20px',
    fontFamily: 'Poppins',
    fontSize: '20px'
  },
  main: {
    display: 'flex',
    flexDirection: 'row',
    height: 'calc(100% - 114px)'
  },
  menuBar: {
    height: 40,
    width: '100%',
    borderBottom: '1px solid rgba(0,0,0,0.2)',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  menuButton: {
    display: 'flex',
    alignItems: 'center'
  },
  scriptDiv: {
    height: '400px',
    width: '90%',
    boxShadow: '0px 2px 9px #aaaaaa inset',
    margin: '24px 0px',
    display: 'inline-block',
    padding: '0px 25px 0px 20px',
    overflow: 'auto'
  },
  copyButton: {
    backgroundColor: '#9933FF',
    color: 'white'
  },
  selectionCell: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: '5px',
    paddingRight: '5px',
    margin: '5px',
    height: '34px',
    fontSize: '14px',
    fontFamily: 'poppins',
    borderRadius: '17px',
    cursor: 'pointer'
  },
  snackbar: {
    backgroundColor: '#547BD0',
    color: theme.palette.secondary.main
  }
}))

interface EmbedFormModalProps {
  packetCode: string
  form: Form,
  open: boolean,
  onClose: () => void
  setFormExported: (value: Date) => void
  warningModal: boolean
  setWarningModal: (value: boolean) => void
  setChangesMade: (value: boolean) => void
}

function EmbedFormModal ({ packetCode, form, open, onClose, setFormExported, warningModal, setWarningModal, setChangesMade }: EmbedFormModalProps): JSX.Element {
  const classes = useStyles()
  const [showCopied, setShowCopied] = useState(false)
  const handleClose = () => {
    onClose();
    setWarningModal(false)
  };
  return (
    <Modal
      size='lg'
      open={open}
      onHide={handleClose}
      title={warningModal ? 'Warning' : 'Embed Form'}
      handleSave={() => undefined}
      closeBtn
      noFooter
    >
      <div>
        <Typography variant='body1'>
          {warningModal
            ? 'The form code has been updated. It must now be replaced on your site with this new code for your changes to take effect.'
            : 'To embed this form, copy and paste the code below into the HTML code on your website. The styling of the form will conform to your site’s styling.'}
        </Typography>
        <div className={classes.scriptDiv}>
          <pre>{Form.generateScript(form, packetCode, true)}</pre>
        </div>
        <Button
          variant='contained'
          color='primary'
          onClick={() => {
            navigator.clipboard.writeText(Form.generateScript(form, packetCode, false)).then(
              () => {
                setShowCopied(true)
                setFormExported(new Date())
                setTimeout(() => setChangesMade(false), 500)
                setTimeout(() => setShowCopied(false), 3000)
              }
            )
          }}
          style={{ display: 'block' }}
        >
          Copy to Clipboard
        </Button>
        {showCopied && (
          <Typography style={{ marginTop: 20, color: '#81AF20' }} variant='body1'>
            Copied to Clipboard
          </Typography>
        )}
      </div>
    </Modal>
  )
}

interface ChooseFieldsModalProps {
  dispatch: any
  open: boolean
  onClose: () => void
  setCanSave: (value: boolean) => void
}
const defaults = ['first_name', 'last_name', 'email', 'title', 'subtitle']

const options = [
  { label: 'Header', value: 'title', icon: 'hIcon', included: false, kind: 'title' },
  { label: 'Paragraph', value: 'subtitle', icon: 'textFields', included: false, kind: 'subtitle' },
  { label: 'Full Name', value: 'full_name', icon: 'person', included: false, kind: 'text' },
  { label: 'First Name', value: 'first_name', icon: 'person', included: false, kind: 'text' },
  { label: 'Last Name', value: 'last_name', icon: 'person', included: false, kind: 'text' },
  { label: 'Email Address', value: 'email', icon: 'email', included: false, kind: 'text' },
  { label: 'Phone Number', value: 'phone', icon: 'phone', included: false, kind: 'text' },
  { label: 'Company Name', value: 'name', icon: 'industryIcon', included: false, kind: 'text' },
  { label: 'Company Size', value: 'company_employee_count', icon: 'employeeIcon', included: false, kind: 'numeric' },
  { label: 'Job Title', value: 'title', icon: 'work', included: false, kind: 'text' }
].map((o) => { return { ...o, included: defaults.includes(o.value) } })

function ChooseFieldsModal ({ open, dispatch, onClose, setCanSave }: ChooseFieldsModalProps) {
  const classes = useStyles()
  const [optionState, setOptionState] = useState(options)

  const handleSelect = (index: number) => {
    const newOptions = [...optionState]
    newOptions[index].included = !newOptions[index].included
    setOptionState(newOptions)
  }

  const handleGetStarted = () => {
    optionState.forEach((item) => {
      if (item.included) {
        const newFormField: FormField = {
          id: random(1, 10000, false),
          fieldType: item.kind === 'title' || item.kind === 'subtitle' ? item.kind : 'field',
          fieldID: item.value,
          fieldLabel: item.kind === 'title' ? 'This is a sample header' : item.kind === 'subtitle' ? 'This is a rich text area, you can add whatever copy you like' : item.label,
          placeholder: '',
          helperText: '',
          isRequired: false,
          isHidden: false,
          options: [],
          blockEmail: false,
          spamUnwanted: false,
          nonBusinessUnwanted: false,
          blockedDomainsUnwanted: false,
          sourceType: 'contact',
          inputType: item.kind,
          hiddenDefault: ''
        }
        dispatch({ type: 'addRow', value: [newFormField] })
        setCanSave(true)
      }
    })
    options.forEach((o) => { o.included = defaults.includes(o.value) })
    setOptionState(options)
    onClose()
  }

  return (
    <Modal
      size='sm'
      open={open}
      onHide={onClose}
      title='Choose some fields'
      handleSave={() => null}
      dynamicButtons={
        [{ text: 'GET STARTED', action: () => handleGetStarted() }]
      }
    >
      <div>
        <div style={{ color: '#00000080' }}>
          Add a few fields to your form to get started.
        </div>
        <div style={{ display: 'flex', flexFlow: 'row wrap' }}>
          {optionState.map((item, index) => {
            return (
              <div key={index} onClick={() => handleSelect(index)} className={classes.selectionCell} style={{ backgroundColor: item.included ? '#D9E7BC' : '#EBEBEB' }}>
                {item.included && (
                  <Icon icon='check' size='sm' />
                )}
                <div style={{ marginLeft: '2px', marginRight: '2px' }}>
                  <Icon icon={item.icon} size='sm' />
                </div>
                <div style={{ marginLeft: '2px', marginRight: '2px' }}>
                  {item.label}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </Modal>
  )
}

interface FormEditorProps {
  formData: Form
  history: any
  id: number
}

export default function FormEditor ({ formData, history, id }: FormEditorProps) {
  const classes = useStyles()
  const { data: chatSettings, isLoading } = ChatSettings.loadOne()
  const [embedFormModalOpen, setEmbedFormModalOpen] = useState(false)
  const formID = id
  const [form, dispatch] = useReducer(formReducer, formData)
  const [fieldModal, setFieldModal] = useState(false)
  const [canSave, setCanSave] = useState(false)
  const [unsavedModalOpen, setUnsavedModalOpen] = useState(false)
  const [missingItemsModal, setMissingItemsModal] = useState(false)
  const [nextLocation, setNextLocation] = useState(null)
  const [prevForm, setPrevForm] = useState({ ...form })
  const { user } = useContext(SessionContext)
  const [changesMade, setChangesMade] = useState(false)
  const [snackbarDismissed, setSnackbarDismissed] = useState(false)
  const [snackbarExpired, setSnackbarExpired] = useState(false)
  const [formExported, setFormExported] = useState<Date | null>(form.formExported ? new Date(form.formExported) : null)
  const [warningModal, setWarningModal] = useState(false)
  const [missingItems, setMissingItems] = useState([])
  const [showBar, setShowBar] = useState(false)
  const { data: hasFollowUpAccess = false, isLoading: isLoadingComponents } = useQuery('has-follow-up-access', () => hasComponent('intelligent-forms'))
  const triggerMissing = (type: string) => {
    setMissingItemsModal(false)
    history.push(`/forms/${id}/followup`)
    Emitter.emit(EVENT_TYPE.FORM_FOLLOWUP_MISSING, { type: type })
  }

  const checklistMapping = [
    { name: 'lcRouting', label: 'Live Chat - Incomplete Routing', group: 'online', groupLabel: 'Prompt Content - Agents Online' },
    { name: 'lcRoutedMessage', label: 'Live Chat - Incomplete “Agent is Routed” Messaging', group: 'online', groupLabel: 'Prompt Content - Agents Online' },
    { name: 'lcNoResponseMessage', label: 'Live Chat - Incomplete “Agent Fails to Respond” Messaging', group: 'online', groupLabel: 'Prompt Content - Agents Online' },
    { name: 'lcNoResponseSettings', label: 'Live Chat - Incomplete “Agent Fails to Respond" Settings', group: 'online', groupLabel: 'Prompt Content - Agents Online' },
    { name: 'bmRouting', label: 'Booking Meetings - Incomplete Routing', group: 'online', groupLabel: 'Prompt Content - Agents Online' },
    { name: 'bmMeetingType', label: 'Booking Meetings - Meeting Type not selected', group: 'online', groupLabel: 'Prompt Content - Agents Online' },
    { name: 'bmEmail', label: 'Booking Meetings - Follow-up email not selected', group: 'online', groupLabel: 'Prompt Content - Agents Online' },
    { name: 'obmRouting', label: 'Booking Meetings - Incomplete Routing', group: 'offline', groupLabel: 'Prompt Content - Agents Offline' },
    { name: 'obmMeetingType', label: 'Booking Meetings - Meeting Type not selected', group: 'offline', groupLabel: 'Prompt Content - Agents Offline' },
    { name: 'obmEmail', label: 'Booking Meetings - Follow-up email not selected', group: 'offline', groupLabel: 'Prompt Content - Agents Offline' }
  ]

  if (canSave) {
    history.block((nextRoute: any) => {
      if (nextRoute.pathname.includes('/forms/') && !nextRoute.pathname.includes('/forms/forms')) {
        return true
      } else {
        setUnsavedModalOpen(true)
        setNextLocation(nextRoute)
        return false
      }
    })
  } else {
    history.block(() => {
      return true
    })
  }

  const [isDragging, setIsDragging] = useState(false)
  const [dragInfo, setDragInfo] = useState<FieldInfo>()

  const tabs = [
    { name: 'Builder', url: `/forms/${formID}` },
    { name: 'Follow-Up', url: `/forms/${formID}/followup`, icon: !hasFollowUpAccess ? <Icon icon='upArrowCircle' size='sm' /> : null },
    { name: 'Settings', url: `/forms/${formID}/settings` },
    { name: 'Design', url: `/forms/${formID}/design` },
    { name: 'Submissions', url: `/forms/${formID}/submissions`, bgColor: 'white' }
  ]

  const checkMissingItems = () => {
    const checklist = {
      lcRouting: true,
      lcRoutedMessage: true,
      lcNoResponseMessage: true,
      lcNoResponseSettings: true,
      bmRouting: true,
      bmMeetingType: true,
      bmEmail: true,
      obmRouting: true,
      obmMeetingType: true,
      obmEmail: true
    }
    if (form.followUp) {
      if (form.liveChat) {
        if (form.chatRoutingSettings.length > 0) {
          form.chatRoutingSettings.forEach((route) => {
            if (!route.method || !route.objectIDs || route.objectIDs.length < 1 || !route.objectIDs[0]) {
              checklist.lcRouting = false
            }
            if (route.method === 'global_routing') {
              checklist.lcRouting = true
            }
          })
        } else {
          checklist.lcRouting = false
        }
        if (!form.chatRoutedMessage || form.chatRoutedMessage === '<p><br></p>') {
          checklist.lcRoutedMessage = false
        }

        if (!form.chatTimeoutMessage || form.chatTimeoutMessage === '<p><br></p>') {
          checklist.lcNoResponseMessage = false
        }
        if (!form.chatTimeoutSeconds) {
          checklist.lcNoResponseSettings = false
        }
      }

      if (form.bookMeeting) {
        if (form.meetingRoutingSettings.length > 0) {
          form.meetingRoutingSettings.forEach((route) => {
            if (!route.method || !route.objectIDs || route.objectIDs.length < 1 || !route.objectIDs[0]) {
              checklist.bmRouting = false
            }
            if (route.method === 'global_routing') {
              checklist.bmRouting = true
            }
          })
        } else {
          checklist.bmRouting = false
        }
        if (!form.meetingType) {
          checklist.bmMeetingType = false
        }
        if (form.meetingFollowupEmail) {
          if (!form.meetingFollowupEmailID) {
            checklist.bmEmail = false
          }
        }
      }

      if (form.offlineBookMeeting) {
        if (form.offlineMeetingRoutingSettings.length > 0) {
          form.offlineMeetingRoutingSettings.forEach((route) => {
            if (!route.method || !route.objectIDs || route.objectIDs.length < 1 || !route.objectIDs[0]) {
              checklist.obmRouting = false
            }
            if (route.method === 'global_routing') {
              checklist.obmRouting = true
            }
          })
        } else {
          checklist.obmRouting = false
        }
        if (!form.offlineMeetingType) {
          checklist.obmMeetingType = false
        }
        if (form.offlineFollowupEmail) {
          if (!form.offlineFollowupEmailID) {
            checklist.obmEmail = false
          }
        }
      }
    }
    return checklist
  }

  const handleSaveExported = () => {
    const dataToSave = { ...prevForm, formExported: formExported }
    Form.save({ data: dataToSave, silent: true })
    setPrevForm({ ...prevForm, formExported: formExported })
  }

  const save = () => {
    form.updatedByUserID = user.id
    form.formExported = formExported ? formExported.toISOString() : null
    Form.save({ data: form })
    setCanSave(false)
    setWarningModal(changesMade)
    setPrevForm({ ...form })
  }

  const undoChanges = () => {
    form.formLayout = prevForm.formLayout
    dispatch({ type: 'replaceForm', value: form })
    setChangesMade(false)
  }

  const handleSnackbarClose = () => {
    setSnackbarDismissed(true)
  }

  const refreshMissing = () => {
    const checklist = checkMissingItems()
    setMissingItems(checklist)
    return checklist
  }

  const handleSave = () => {
    const checklist = refreshMissing()
    let incomplete = false
    for (const property in checklist) {
      if (!checklist[property]) {
        setMissingItemsModal(true)
        incomplete = true
        break
      }
    }
    if (incomplete) {
      setShowBar(true)
      return
    }
    setShowBar(false)
    save()
  }

  const breadcrumbs = [
    { link: '/engagement/forms/forms', text: <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}><div><Icon icon='arrowBack' /></div></div> }
  ]

  useEffect(() => {
    if (((form?.formLayout?.length || 0) < 1)) {
      setFieldModal(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (formExported && form.formLayout !== prevForm.formLayout) {
      setChangesMade(true)
    }
    // eslint-disable-next-line
  }, [form])

  useEffect(() => {
    if (formExported !== prevForm.formExported) {
      handleSaveExported()
    }
    // eslint-disable-next-line
  }, [formExported])

  useEffect(() => {
    if (warningModal) {
      setEmbedFormModalOpen(true);
    }
  }, [warningModal])

  useEffect(() => {
    if (form) {
      refreshMissing()
    }
    // eslint-disable-next-line
  }, [form])

  useEffect(() => {
    if (changesMade && !snackbarDismissed) {
      setSnackbarExpired(false)
      setTimeout(() => setSnackbarExpired(true), 10000)
    }
    // eslint-disable-next-line
  }, [changesMade])

  if (isLoading) {
    return <></>
  }

  return (
    <AppPage
      title={form.name}
      saveName={(name: string) => {
        dispatch({ type: 'name', value: name })
        setCanSave(true)
      }}
      editableTitle={Boolean(form)}
      tabs={tabs}
      breadcrumbs={breadcrumbs}
      sameRowBreadCrumbs
    >
      <div>
        <div className={classes.menuBar}>
          {changesMade && (
            <Button
              className={classes.menuButton}
              variant='text'
              startIcon={<WarningIcon style={{ color: '#F4D451' }} />}
              color='primary'
              onClick={() => setWarningModal(true)}
            >
              Form code out of date
            </Button>
          )}
          <Button
            className={classes.menuButton}
            variant='text'
            startIcon={<Icon icon='code' size='sm' />}
            color='primary'
            style={{ marginLeft: 10 }}
            onClick={() => setEmbedFormModalOpen(true)}
          >
            EMBED FORM
          </Button>
          <Button
            className={classes.menuButton}
            variant='text'
            startIcon={<Icon icon='save' size='sm' />}
            color='primary'
            style={{ marginLeft: 10, marginRight: 10 }}
            onClick={() => handleSave()}
            disabled={!canSave}
          >
            SAVE
          </Button>
        </div>
        {showBar && (
          <FormErrorBar
            checklist={missingItems}
            checklistMapping={checklistMapping}
            triggerMissing={triggerMissing}
            close={() => setShowBar(false)}
          />
        )}
        <div className={classes.main}>
          <FormFieldBar form={form} dispatch={dispatch} setIsDragging={setIsDragging} setDragInfo={setDragInfo} setCanSave={setCanSave} />
          <FormBuilder form={form} dispatch={dispatch} insertDrag={isDragging} insertInfo={dragInfo} setCanSave={setCanSave} />
          <Snackbar
            open={changesMade && !snackbarDismissed && !snackbarExpired}
            className={classes.snackbar}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          >
            <SnackbarContent
              message={
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  <InfoIcon fontSize='small' style={{ marginRight: '5px' }} />
                  Changes made under the "Build" tab require you to replace the form code on your site
                </span>
              }
              action={
                <>
                  <Button color='secondary' size='small' variant='outlined' className={classes.snackbar} onClick={undoChanges}>
                    UNDO
                  </Button>
                  <IconButton size='small' aria-label='close' color='inherit' onClick={handleSnackbarClose}>
                    <CloseIcon fontSize='small' />
                  </IconButton>
                </>
              }
              className={classes.snackbar}
            />
          </Snackbar>
        </div>
        <EmbedFormModal packetCode={chatSettings?.packetCode} form={form} open={embedFormModalOpen} onClose={() => setEmbedFormModalOpen(false)} setFormExported={setFormExported} warningModal={warningModal} setWarningModal={setWarningModal} setChangesMade={setChangesMade} />
        <div>
          <ChooseFieldsModal dispatch={dispatch} open={fieldModal} onClose={() => setFieldModal(false)} setCanSave={setCanSave} />
        </div>
        <div>
          <UnsavedModal
            open={unsavedModalOpen}
            onHide={() => setUnsavedModalOpen(false)}
            nextLocation={nextLocation}
            save={() => handleSave()}
            history={history}
          />
        </div>
        <div>
          <FormMissingItemsModal
            checklist={missingItems}
            open={missingItemsModal}
            onHide={() => setMissingItemsModal(false)}
            checkMissing={() => checkMissingItems()}
            checklistMapping={checklistMapping}
            triggerMissing={triggerMissing}
          />
        </div>
      </div>
      <div style={{ height: 'calc((100vh - 119px) - 0px)' }}>
        <div className={classes.menuBar}>
          <Button
            className={classes.menuButton}
            variant='text'
            startIcon={<Icon icon='code' size='sm' />}
            color='primary'
            onClick={() => setEmbedFormModalOpen(true)}
          >
            EMBED FORM
          </Button>
          <Button
            className={classes.menuButton}
            variant='text'
            startIcon={<Icon icon='save' size='sm' />}
            color='primary'
            style={{ marginLeft: 10, marginRight: 10 }}
            onClick={() => handleSave()}
            disabled={!canSave}
          >
            SAVE
          </Button>
        </div>
        {showBar && (
          <FormErrorBar
            checklist={missingItems}
            checklistMapping={checklistMapping}
            triggerMissing={triggerMissing}
            close={() => setShowBar(false)}
          />
        )}
        <FormFollowupPage form={form} formDispatch={dispatch} setCanSave={setCanSave} chatPrimaryColor={chatSettings.buttonColor} refreshMissing={refreshMissing} hasFollowUpAccess={hasFollowUpAccess} isLoadingComponents={isLoadingComponents} />
      </div>
      <div>
        <div className={classes.menuBar}>
          <Button
            className={classes.menuButton}
            variant='text'
            startIcon={<Icon icon='code' size='sm' />}
            color='primary'
            onClick={() => setEmbedFormModalOpen(true)}
          >
            EMBED FORM
          </Button>
          <Button
            className={classes.menuButton}
            variant='text'
            startIcon={<Icon icon='save' size='sm' />}
            color='primary'
            style={{ marginLeft: 10, marginRight: 10 }}
            onClick={() => handleSave()}
            disabled={!canSave}
          >
            SAVE
          </Button>
        </div>
        {showBar && (
          <FormErrorBar
            checklist={missingItems}
            checklistMapping={checklistMapping}
            triggerMissing={triggerMissing}
            close={() => setShowBar(false)}
          />
        )}
        <FormSettingsPage form={form} dispatch={dispatch} setCanSave={setCanSave} />
      </div>
      <div>
        <div className={classes.menuBar}>
          {changesMade && (
            <Button
              className={classes.menuButton}
              variant='text'
              startIcon={<WarningIcon style={{ color: '#F4D451' }} />}
              color='primary'
              onClick={() => setWarningModal(true)}
            >
              Form code out of date
            </Button>
          )}
          <Button
            className={classes.menuButton}
            variant='text'
            startIcon={<Icon icon='code' size='sm' />}
            color='primary'
            style={{ marginLeft: 10 }}
            onClick={() => setEmbedFormModalOpen(true)}
          >
            EMBED FORM
          </Button>
          <Button
            className={classes.menuButton}
            variant='text'
            startIcon={<Icon icon='save' size='sm' />}
            color='primary'
            style={{ marginLeft: 10, marginRight: 10 }}
            onClick={() => handleSave()}
            disabled={!canSave}
          >
            SAVE
          </Button>
        </div>
        {showBar && (
          <FormErrorBar
            checklist={missingItems}
            checklistMapping={checklistMapping}
            triggerMissing={triggerMissing}
            close={() => setShowBar(false)}
          />
        )}
        <div className={classes.main}>
          <FormStylesBar form={form} dispatch={dispatch} setCanSave={setCanSave} />
          <FormPreview
            form={form}
            chatPrimaryColor={chatSettings.primaryColor}
          />
          <Snackbar
            open={changesMade && !snackbarDismissed}
            className={classes.snackbar}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          >
            <SnackbarContent
              message={
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  <InfoIcon fontSize='small' style={{ marginRight: '5px' }} />
                  Changes made under the "Design" tab require you to replace the form code on your site
                </span>
              }
              action={
                <>
                  <Button color='secondary' size='small' variant='outlined' className={classes.snackbar} onClick={undoChanges}>
                    UNDO
                  </Button>
                  <IconButton size='small' aria-label='close' color='inherit' onClick={handleSnackbarClose}>
                    <CloseIcon fontSize='small' />
                  </IconButton>
                </>
              }
              className={classes.snackbar}
            />
          </Snackbar>
        </div>
        <EmbedFormModal packetCode={chatSettings?.packetCode} form={form} open={embedFormModalOpen} onClose={() => setEmbedFormModalOpen(false)} setFormExported={setFormExported} warningModal={warningModal} setWarningModal={setWarningModal} setChangesMade={setChangesMade} />
        <div>
          <ChooseFieldsModal dispatch={dispatch} open={fieldModal} onClose={() => setFieldModal(false)} setCanSave={setCanSave} />
        </div>
        <div>
          <UnsavedModal
            open={unsavedModalOpen}
            onHide={() => setUnsavedModalOpen(false)}
            nextLocation={nextLocation}
            save={() => handleSave()}
            history={history}
          />
        </div>
        <div>
          <FormMissingItemsModal
            checklist={missingItems}
            open={missingItemsModal}
            onHide={() => setMissingItemsModal(false)}
            checkMissing={() => checkMissingItems()}
            checklistMapping={checklistMapping}
            triggerMissing={triggerMissing}
          />
        </div>
      </div>
      <div>
        <FormSubmissionTable form={form} />
      </div>
    </AppPage>
  )
}
