import { useState } from 'react'
import 'jsplumb'
import { BaseNodeComponent } from 'jsplumbtoolkit-react'
import { jsPlumbUtil } from 'jsplumbtoolkit'
import useCustomCompareEffect from 'use-custom-compare-effect'
import { isEqual } from 'lodash'
import OptionsNode from './OptionsNode'
import FaqNode from './FaqNode'
import StartNode from './StartNode'
import MessageNode from './MessageNode'
import LeadScoreNode from './LeadScoreNode'
import EmailCaptureNode from './EmailCaptureNode'
import GoalNode from './GoalNode'
import EndFlowNode from './EndFlowNode'
import TagNode from './TagNode'
import ConditionalNode from './ConditionalNode'
import { RouteNode } from './RouteNode'
import { LiveChatNode } from './LiveChatNode'
import AlertAgentNode from './AlertAgentNode'
import { CalendarNode } from './CalendarNode'
import SendEmailNode from './SendEmailNode'
import CreateContactNode from './CreateContactNode'
import CTAButtonNode from './CTAButtonNode'
import PhoneNode from './PhoneNode'
import TextCaptureNode from './TextCaptureNode'
import SequenceNode from '../sequences/SequenceNode'
import SequenceOutputNode from '../sequences/SequenceOutputNode'
import ConversationStatusNode from './ConversationStatusNode'
import ConversationRatingNode from './ConversationRatingNode'
import DeleteIcon from '@material-ui/icons/DeleteForever'
import EditIcon from '@material-ui/icons/Edit'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import useStyles from './NodeStyles'
import { isNodeConnected, isNodeValid } from './nodeHelpers'
import SalesloftNode from './SalesloftNode'
import { QueryClientProvider } from 'react-query'
import { queryClient } from 'App'
import { ShareProvider } from 'share-context'

function GetNode (props) {
  if (props.type === 'options') {
    return (
      <OptionsNode base={props.base} {...props} />
    )
  } else if (props.type === 'faq') {
    return (
      <FaqNode base={props.base} {...props} />
    )
  } else if (props.type === 'start') {
    return (
      <StartNode base={props.base} {...props} />
    )
  } else if (props.type === 'chat') {
    return (
      <MessageNode base={props.base} {...props} />
    )
  } else if (props.type === 'lead_score') {
    return (
      <LeadScoreNode base={props.base} {...props} />
    )
  } else if (props.type === 'email') {
    return (
      <EmailCaptureNode base={props.base} {...props} />
    )
  } else if (props.type === 'tag') {
    return (
      <TagNode base={props.base} {...props} />
    )
  } else if (props.type === 'route') {
    return (
      <RouteNode base={props.base} {...props} />
    )
  } else if (props.type === 'live_chat') {
    return (
      <LiveChatNode base={props.base} {...props} />
    )
  } else if (props.type === 'alert_agent') {
    return (
      <AlertAgentNode base={props.base} {...props} />
    )
  } else if (props.type === 'calendar') {
    return (
      <CalendarNode base={props.base} {...props} />
    )
  } else if (props.type === 'phone') {
    return (
      <PhoneNode base={props.base} {...props} />
    )
  } else if (props.type === 'create_contact') {
    return (
      <CreateContactNode base={props.base} {...props} />
    )
  } else if (props.type === 'send_email') {
    return (
      <SendEmailNode base={props.base} {...props} />
    )
  } else if (props.type === 'text_capture') {
    return (
      <TextCaptureNode base={props.base} {...props} />
    )
  } else if (props.type === 'conversation_status') {
    return (
      <ConversationStatusNode base={props.base} {...props} />
    )
  } else if (props.type === 'conversation_rating') {
    return (
      <ConversationRatingNode base={props.base} {...props} />
    )
  } else if (props.type === 'goal') {
    return (
      <GoalNode base={props.base} {...props} />
    )
  } else if (props.type === 'end_flow') {
    return (
      <EndFlowNode base={props.base} {...props} />
    )
  } else if (props.type === 'conditional') {
    return (
      <ConditionalNode base={props.base} {...props} />
    )
  } else if (props.type === 'sequence') {
    return (
      <SequenceNode base={props.base} {...props} />
    )
  } else if (props.type === 'sequence_output') {
    return (
      <SequenceOutputNode base={props.base} {...props} />
    )
  } else if (props.type === 'cta_button') {
    return (
      <CTAButtonNode base={props.base} {...props} />
    )
  } else if (props.type === 'salesloft') {
    return (
      <SalesloftNode base={props.base} {...props} />
    )
  }
}

function NodeWrapper (props) {
  const classes = useStyles()
  const [hover, setHover] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [invalid, setInvalid] = useState(false)
  const handleClone = props.cloneNode

  const handleRemove = () => {
    const tk = props.base.toolkit
    props.removeNode()
    window.setSelection(tk.getSelection())
  }

  const node = props.base && props.base.getNode()
  const nodeData = node?.data ? { ...node.data } : undefined
  const connectionStatus = node && isNodeConnected(node)

  useCustomCompareEffect(() => {
    if (nodeData) {
      setInvalid(!isNodeValid({ data: nodeData }))
    }
  }, [nodeData], ([cur], [old]) => {
    if (typeof cur === 'object' && typeof old === 'object') {
      if (cur.type === 'end_flow') {
        return true
      }
      return isEqual(cur, old)
    }
    return cur === old
  })

  let style = {}
  if (window.showHighlights) {
    style.borderRadius = 10
    if (!connectionStatus) {
      style.boxShadow = '0px 0px 10px 15px #FF5D5C'
    } else if (invalid) {
      style.boxShadow = '0px 0px 10px 15px #FF9038'
    } else {
      style = {}
    }
  }

  const handleClick = (event, value) => {
    if (event.ctrlKey || event.metaKey) {
      const node = props.base.getNode()
      const tk = props.base.toolkit
      const selection = tk.getSelection()
      const nodeIDs = selection.getNodes().map(node => node.id)
      if (nodeIDs.includes(node.id)) {
        tk.removeFromSelection(node)
      } else {
        tk.addToSelection(node)
      }
      window.setSelection(tk.getSelection())
    }
  }

  const handleDoubleClick = (event, value) => {
    if (!event.ctrlKey) {
      setModalOpen(true)
    }
  }

  return (
    <div
      className='base-node'
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      id={props.base.state.id}
      onClick={handleClick}
      onDoubleClick={handleDoubleClick}
    >
      {hover && !props.base.state.id.includes('preview') ? (
        <div className={classes.hoverButtonDiv}>
          <div
            className={classes.hoverButton}
            onClick={handleRemove}
          >
            <DeleteIcon style={{ fill: '#7C7C7C' }} />
          </div>
          {props.type !== 'end_flow' && props.type !== 'conversation_status' ? (
            <div
              className={classes.hoverButton}
              style={{ marginTop: 10 }}
              onClick={() => setModalOpen(true)}
            >
              <EditIcon style={{ fill: '#7C7C7C' }} />
            </div>
          ) : <></>}
          <div
            className={classes.hoverButton}
            style={{ marginTop: 10 }}
            onClick={handleClone}
          >
            <FileCopyIcon style={{ fill: '#7C7C7C' }} />
          </div>
        </div>
      ) : <></>}
      <div style={style}>
        <GetNode
          type={props.type}
          base={props.base}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          invalid={invalid}
          {...props}
        />
      </div>
    </div>
  )
}

const defaultPortIDs = ['default', 'resolved', 'again', 'live-chat', 'valid', 'invalid']

class BetterBaseNode extends BaseNodeComponent {
  render () {
    if (this.state.type === 'start' || this.state.type === 'sequence_output') {
      return (
        <GetNode type={this.state.type} base={this} {...this.props} />
      )
    }

    function cloneNode (node) {
      const toolkitNode = node.toolkit.getNode(node.state.id)
      const data = JSON.parse(JSON.stringify(toolkitNode.data))
      if (toolkitNode.data.kind !== 'AvailabilityBranching') {
        data.ports.forEach((port) => {
          const matches = port.id.match('.+-.+-.+-.+-.+')
          if (defaultPortIDs.includes(port.id) || matches === null) {
            // if it's not a UUID, keep it the same
          } else {
            // Otherwise give all ports a new id
            const uniqueID = jsPlumbUtil.uuid()
            if (data.port_order.includes(port.id)) {
              data.port_order[data.port_order.indexOf(port.id)] = uniqueID
            }
            data?.buttons?.forEach((button) => {
              if (button.id === port.id) {
                button.id = uniqueID
              }
            })
            port.id = uniqueID
          }
        })
      }
      data.left = data.left + 200
      data.top = data.top + 70
      data.id = jsPlumbUtil.uuid()
      data.isCopy = true
      node.toolkit.addNode(data)
    }

    return (
      <QueryClientProvider client={queryClient}>
        <ShareProvider>
          <NodeWrapper
            type={this.state.type}
            base={this}
            cloneNode={() => cloneNode(this)}
            addNode={() => this.toolkit.addNode()}
            removeNode={() => this.toolkit.removeNode(this.getNode())}
            {...this.props}
          />
        </ShareProvider>
      </QueryClientProvider>
    )
  }
};

export default BetterBaseNode
