import * as React from 'react';
import { styled } from '@material-ui/styles';
import Switch, { SwitchProps } from '@material-ui/core/Switch';

export const LargeToggle = styled((props: SwitchProps) => (
  <Switch disableRipple {...props} />
  ))(({ theme }) => ({
    width: 64,
    height: 35,
    padding: 0,
    color: 'primary',
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(30px)',
        color: '#fff',
        opacity: 1,
        '& + .MuiSwitch-track': {
          backgroundColor: 'primary',
          opacity: 1,
          border: 0
        }
      }
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 30,
      height: 30
    },
    '& .MuiSwitch-track': {
      borderRadius: 35 / 2,
      backgroundColor: '#975ce6',
      opacity: 1
    }
  }));
