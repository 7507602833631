import { Icon } from 'library/materialUI/Icon'
import { Button } from 'library/materialUI/Button'
import { useEffect, useState } from 'react'
import { getUserInvites, resendUserInvite } from 'api/admin_only/tenants'
import { makeStyles } from '@material-ui/core/styles'
import { roleMap, roleTypes } from './AdminUserListPage'
import { Divider } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  tableHeader: {
    display: 'grid',
    gridTemplateColumns: '3fr 2fr 1fr 1fr',
    gridGap: 5,
    marginBottom: 10,
    fontSize: 12,
    textAlign: 'center'
  },
  centered: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))

interface userInviteParams {
  attributes: any, // types need to be updated
  id: string
}

export const AdminUserInvites = (props: { tenantId: number | null, inviteModalOpen: boolean }): JSX.Element => {
  const classes = useStyles()
  const tenantId = props.tenantId
  const [sending, setSending] = useState(false)
  const [userInvites, setUserInvites] = useState<userInviteParams[] | []>([])

  const resendInvite = (id: number) => {
    setSending(true)
    resendUserInvite(id).then((response) => {
      setSending(false)
      if (response === true) {
        alert('Your invite has been sent')
      }
    })
  }

  useEffect(() => {
    getUserInvites(tenantId)
      .then(response => {
        setUserInvites(response.data)
      })
  }, [tenantId, props.inviteModalOpen])

  return (
    <div style={{ minHeight: 100 }}>
      <div className={classes.tableHeader}>
        <div>USER</div>
        <div>ROLE</div>
        <div>EXPIRED</div>
      </div>
      {userInvites.length > 0 ? userInvites.map((u, index) => {
        const expired = new Date(u.attributes.expired_timestamp)
        const expiredDate = expired.toLocaleDateString()
        return (
          <div key={index}>
            <div className={classes.tableHeader} style={{ fontSize: 16 }}>
              <div className={classes.centered}>
                {u.attributes.email}
              </div>
              <div className={classes.centered}>
                {roleMap[u.attributes.role_id as keyof roleTypes]}
              </div>
              <div className={classes.centered}>
                {expiredDate}
              </div>
              <Button
                startIcon={<Icon icon='send' />}
                color='primary'
                disabled={sending}
                onClick={() => resendInvite(parseInt(u.id))}
                style={{ minWidth: 120 }}
              >
                Resend
              </Button>
            </div>
            {index + 1 < userInvites.length && <Divider />}
          </div>
        )
      }) : (<div>None Pending</div>)}
    </div>
  )
}
