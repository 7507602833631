import { Cronofy } from 'classes/cronofy'
import { MeetingSettings } from 'classes/meetingSettings'
import User from 'classes/users'
import { loadWidgets } from 'library/calendar/cronofy'
import { useEffect } from 'react'
import { ContentsProps } from '../wizard/OnboardingWizard'
import CalendarConfirmation from './CalendarConfirmation'
import { Typography, IconButton, Icon, Popper, Paper } from 'library/materialUI'

const WarningPopper = ({ title, subtitle, anchorEl, setAnchorEl }: { title: string, subtitle: string, anchorEl: HTMLElement | undefined, setAnchorEl: Function | undefined }) => {
  const handleClose = () => {
    setAnchorEl && setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'warning-popper' : undefined;

  return (
    <div>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement='top-start'
        style={{ zIndex: 1500 }}
      >
        <Paper style={{ padding: 15, marginBottom: 10 }}>
          <IconButton style={{ float: 'right' }} size='small' onClick={handleClose}>
            <Icon icon='close' />
          </IconButton>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Icon icon='warning' color='gold' />
            <Typography style={{ paddingLeft: 8 }} variant='h6'>{title}</Typography>
          </div>
          <Typography variant='subtitle1'>{subtitle}</Typography>
        </Paper>
      </Popper>
    </div>
  )
}

export const calendarSubtitle = 'Connect your calendar provider to Signals. ' +
'This will allow you to book meetings with site visitors.'

export function CalendarConnection ({ handleNextRef, next, warningAnchorEl, setWarningAnchorEl }: ContentsProps) {
  const { data: user } = User.loadMe()
  const { data: cronofy, isLoading } = Cronofy.load()
  const calendarConfirmed = window.sessionStorage.getItem('onboardingConfirmation') === 'calendar'
  const Component = calendarConfirmed ? <CalendarConfirmation next={next} /> : <div id='cronofy-calendar-sync' />
  // Preload meeting settings so state is consistent for conferencing setup
  MeetingSettings.load({ userID: user.id as number })

  window.sessionStorage.setItem('onboardingStep', 'calendar')

  useEffect(() => {
    if (!isLoading) {
      const URI = cronofy.authConfig.redirect_uri
      cronofy.authConfig.redirect_uri = URI
      loadWidgets({ cronofy, prefix: 'ModalCalendar' })
    }
  // eslint-disable-next-line
  }, [isLoading])

  handleNextRef.current = null

  return (
    <div>
      {Component}
      <WarningPopper
        title='Are you sure you want to skip this step?'
        subtitle={'Meetings can\'t be booked with you until your calendar is connected.'}
        anchorEl={warningAnchorEl}
        setAnchorEl={setWarningAnchorEl}
      />
    </div>
  )
}
