
const descriptionText = 'The Phone Capture node is a great tool available to you as a bot builder.  It allows you to ask the web visitor for their phone number and then automatically saves the phone number they enter as contact information for the web visitor.  It also allows you to configure what occurs when the web visitor inputs an invalid phone number.'

const Description = (): JSX.Element => {
  return (
    <div>
      <div>
        {descriptionText}
      </div>
      {/* <ul>
        <li>whatever we want</li>
        <li>to put in a list</li>
      </ul> */}
    </div>
  )
}

const bestPracticeText = 'After you’ve configured your Phone Capture node’s settings, ensure that you have properly configured flows for when the bot successfully captures a phone number and if the bot does not capture a number.'

const BestPractices = () => {
  return (
    <div>
      <div>
        {bestPracticeText}
      </div>
      {/* <ul>
        <li>whatever we want</li>
        <li>to put in this list</li>
      </ul> */}
    </div>
  )
}

export const PhoneCaptureDocs = {
  helpCenterLink: 'https://help.getsignals.ai/article/mqd4x04rji-phone-capture-node',
  bestPractices: BestPractices,
  description: Description,
  videoLink: ''
}
