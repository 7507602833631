import { useDoQuery } from './useDoQuery'
import { doPost, doPatch, doDelete } from 'api/api'
import { GenericObject } from './genericObject'
import { DateType, castToDate } from './classHelpers'
import { LoadAllProps } from './queryHelpers'

interface PersonasList {
  list: Persona[]
  dict: { [id: Persona['id']]: Persona }
  ids: number[]
}

interface personasLoadAllProps extends LoadAllProps {
  includeDeleted?: boolean
}

const DB_PROPS = ['name', 'departments', 'titles', 'excludedTitles', 'seniorities'] as const

const transformPersonasList = (data: Pick<PersonasList, 'list' | 'dict'>): PersonasList => ({
  ...data,
  ids: Object.keys(data.dict).map(id => parseInt(id))
})

export class Persona extends GenericObject {
  readonly tenantID: number
  objectType = 'persona'
  departments: string[]
  titles: string[]
  seniorities: string[]
  excludedTitles: string[]
  readonly createdByUserID: number
  readonly updatedByUserID: number
  readonly updatedTimestamp: DateType
  readonly deletedTimestamp: DateType

  constructor ({ row }: { row: Record<string, any> }) {
    super({ row })
    this.tenantID = row?.attributes?.tenant_id
    this.departments = row?.attributes?.departments || []
    this.titles = row?.attributes?.titles || []
    this.seniorities = row?.attributes?.seniorities || []
    this.excludedTitles = row?.attributes?.excluded_titles || []
    this.createdByUserID = row?.attributes?.created_by_user_id
    this.updatedByUserID = row?.attributes?.updated_by_user_id
    this.updatedTimestamp = row?.attributes?.updated_timestamp ? castToDate(row.attributes.updated_timestamp) : new Date()
    this.deletedTimestamp = row?.attributes?.deleted_timestamp ? castToDate(row.attributes.deleted_timestamp) : new Date()
  }

  static loadAll (props?: personasLoadAllProps): { data: PersonasList, isLoading: boolean } {
    let path = '/personas'
    if (props?.includeDeleted) {
      path += '?include_deleted=true'
    }
    return useDoQuery({
      path,
      useChatURL: true,
      objectClass: Persona,
      initialData: { list: [], dict: {}, ids: [] } as PersonasList,
      handleCustomResponse: transformPersonasList,
      searchParams: props?.searchParams
    })
  }

  static loadOne (id: Persona['id']): { data: Persona, isLoading: boolean } {
    return useDoQuery({ path: `/personas/${id}`, useChatURL: true, objectClass: Persona })
  }

  static save (persona: Partial<Persona>, dbProps: readonly (typeof DB_PROPS)[number][] = DB_PROPS): Promise<Persona> {
    const attributes = dbProps.reduce((acc, cur) => {
      return { ...acc, [cur]: persona[cur] }
    }, {} as Record<string, any>)

    const data = { attributes }
    return (persona.id ? doPatch<{ data: Record<string, any> }>({
      path: `/personas/${persona.id}`, useChatURL: true, data
    }) : doPost<{ data: Record<string, any> }>({
      path: '/personas', useChatURL: true, data
    })).then(res => {
      const row = res.data
      return new Persona({ row })
    })
  }

  delete (): Promise<Persona> {
    return Persona._deleteID(this.id)
  }

  static deleteIDs (...ids: Persona['id'][]): Promise<Persona[]> {
    return Promise.all(ids.map(id => Persona._deleteID(id)))
  }

  private static _deleteID (id: Persona['id']): Promise<Persona> {
    return doDelete({
      path: `/personas/${id}`,
      useChatURL: true
    }).then(res => {
      const row = res.data
      return new Persona({ row })
    })
  }
}

export default Persona
