import { makeStyles } from '@material-ui/core/styles'
import { Icon, Tooltip } from 'library/materialUI'
import { Fragment, useContext } from 'react'
import { ListPageContext } from '../ListPage'
import SkeletonLoader from 'library/loading/skeleton/SkeletonLoader'

const useStyles = makeStyles(theme => ({
  statList: {
    display: 'flex',
    paddingBottom: 30,
    flexWrap: 'wrap'
  },
  statName: {
    color: '#00000080',
    fontSize: '12px',
    textTransform: 'uppercase',
    paddingTop: 5,
    display: 'flex'
  },
  valueContainer: {
    display: 'flex'
  },
  statValue: {
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#000000BF',
    paddingTop: 5
  },
  stat: {
    display: 'flex',
    padding: '5px',
    backgroundColor: '#FFFFFF',
    border: '1px solid #D3D3D3',
    borderRadius: '5px',
    width: '210px',
    height: '70px',
    margin: '10px',
    alignItems: 'center'
  },
  iconContainer: {
    width: '50px',
    height: '50px',
    backgroundColor: '#F5F5F5',
    display: 'flex',
    alingItems: 'center',
    justifyContent: 'center',
    borderRadius: '10px',
    marginRight: '20px',
    marginLeft: '10px',
    flexShrink: 0
  }
}))

type StatItemProps = {
  name: string
  value: string
  tooltip?: string
  icon?: string
  subvalue?: string
}

function StatItem (props: StatItemProps) {
  const classes = useStyles()

  return (
    <div className={classes.stat}>
      <div className={classes.iconContainer}>
        {props?.icon && (
          <Icon icon={props.icon} color='#808080' />
        )}
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <div className={classes.statName}>
          <div style={{ marginRight: '5px', fontWeight: 'bold' }}>
            {props.name}
          </div>
          {props.tooltip && (
            <Tooltip title={props.tooltip}>
              <div>
                <Icon icon='info' />
              </div>
            </Tooltip>
          )}
        </div>
        <div className={classes.valueContainer}>
          <div className={classes.statValue}>
            {props.value}
          </div>
          <div style={{ fontSize: '14px', color: '#808080', alignSelf: 'flex-end', justifySelf: 'flex-end', marginLeft: 'auto', marginRight: '10px' }}>
            {props.subvalue || '-'}
          </div>
        </div>
      </div>
    </div>
  )
}

export function ObjectStats (props: { items: { name: string, value: string, tooltip?: string, icon?: string, subvalue?: string }[] }) {
  const classes = useStyles()
  const { objectClass, object } = useContext(ListPageContext)
  const { items } = props
  const { data: objectStats, isLoading } = objectClass.loadStats(object.id)

  if (isLoading) {
    return (
      <div className={classes.statList}>
        {items.map((item, index) => {
          return (
            <Fragment key={index}>
              <div className={classes.stat}>
                <SkeletonLoader type='label' size='md' />
                <SkeletonLoader type='label' size='sm' />
              </div>
            </Fragment>
          )
        })}
      </div>
    )
  }

  return (
    <div className={classes.statList}>
      {items.map((item, index) => {
        return (
          <Fragment key={index}>
            <StatItem
              name={item.name}
              value={objectStats[item.value]}
              tooltip={item.tooltip}
              icon={item.icon}
              subvalue={item?.subvalue ? objectStats[item?.subvalue] : '-'}
            />
          </Fragment>
        )
      })}
    </div>
  )
}
