import React from 'react'
import { MenuItem, Button, IconButton, Select } from '@material-ui/core'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import ControlPointIcon from '@material-ui/icons/ControlPoint'
import { AccountOwnerField } from 'classes/accountOwnerFields'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import DragNDrop from 'cf-components/DragNDrop'
import { withStyles } from '@material-ui/core/styles'
import { SearchParams } from 'classes/queryHelpers'
import { OpportunityOwnerField } from 'classes/opportunityOwnerFields'
import ModalWarning from 'components/ModalWarning'
interface OwnerRowItem {
  idx: number
  id: string
  name: string
}
interface OwnerRowProps {
  item: OwnerRowItem
  fields: OwnerRowItem[]
  inUse: string[]
  classes: any
  setOwnerRule: any
  removeField: any
}

const OwnerRow = withStyles(() => ({
  grabbable: {
    cursor: 'grab',
    marginRight: 40
  },
  dragHeader: {
    borderTop: '1px solid #dadada',
    padding: 20,
    fontWeight: 'bold'
  }
}))(
  function (props: OwnerRowProps) {
    const header = props.item.idx === 0 ? 'First by:' : 'Then by:'
    const item = props.item
    const selectedValue = item.id ? item.id : null
    const fields = props.fields.filter((f: OwnerRowItem) => {
      return selectedValue === f.id || !props.inUse.includes(f.id)
    })
    return (
      <div className='no-focus-outline' key={item.idx}>
        <div style={{ fontWeight: 'bold', marginTop: 15, marginBottom: 15 }}>
          {header}
        </div>
        <div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <DragIndicatorIcon
              className={props.classes.grabbable}
              color='primary'
            />
            <div>
              <Select
                style={{ width: 200 }}
                placeholder='Select a CRM Field'
                variant='outlined'
                margin='dense'
                value={selectedValue}
                onChange={(e) => props.setOwnerRule(props.item.idx, e.target.value)}
              >
                {fields.map((field: OwnerRowItem, index: number) => (
                  <MenuItem key={index} value={field.id}>{field.name}</MenuItem>
                ))}
              </Select>
            </div>
            <div style={{ marginRight: 10 }}>
              <IconButton onClick={() => props.removeField(props.item.idx)}>
                <HighlightOffIcon
                  color='primary'
                />
              </IconButton>
            </div>
          </div>
        </div>
      </div>
    )
  }
)

interface OwnerPriorityPickerProps {
  priorityList: string[]
  setPriorityList: any
  searchParams: SearchParams
}

const OwnerPriorityPicker = (props: OwnerPriorityPickerProps) => {
  const { data: accountData, isLoading: accountIsLoading, isError: isAccountError, error: accountError } = AccountOwnerField.loadAll({ searchParams: props.searchParams })
  const { data: oppData, isLoading: oppIsLoading, isError: isOppError } = OpportunityOwnerField.loadAll({ searchParams: {} })
  if (accountIsLoading || oppIsLoading) {
    return (<></>)
  }
  if (isAccountError && isOppError) {
    return (
      <ModalWarning
        type='error'
        body={'There was an issue gathering your owner fields: ' + accountError.message}
      />
    )
  }
  const fields = accountData.list.concat(oppData.list)
  const priorityList = props.priorityList || ['']
  const setPriorityList = props.setPriorityList

  const addField = () => {
    if (priorityList.length < fields.length) {
      const newList = priorityList
      newList.push('')
      setPriorityList([...newList])
    }
  }

  const setOwnerRule = (index: number, value: string) => {
    const newList: any = priorityList
    const ownerObj = fields.filter(f => f.id === value)?.[0]

    if (ownerObj) {
      newList[index] = ownerObj.id
    }
    setPriorityList([...newList])
  }

  const removeField = (index: number) => {
    priorityList.splice(index, 1)
    setPriorityList([...priorityList])
  }
  const finishDrag = (s: OwnerRowItem[]) => {
    const newList = s.map((row: OwnerRowItem) => row.name)
    setPriorityList([...newList])
  }
  const minHeight = priorityList.length * 116
  const priorities = priorityList.map((pl: string, idx: number) => {
    return {
      idx: idx,
      id: pl,
      name: pl
    }
  })

  return (
    <>
      <div>
        <div style={{ minHeight: minHeight }}>
          <DragNDrop
            itemList={priorities}
            setItemList={finishDrag}
            componentProps={{
              fields: fields,
              inUse: priorityList,
              setOwnerRule: setOwnerRule,
              removeField: removeField
            }}
            direction='vertical'
            component={OwnerRow}
            divClass='no-focus-outline'
          />
        </div>
        <Button variant='outlined' color='primary' onClick={addField} style={{ marginTop: 20 }} startIcon={<ControlPointIcon />}>
          Add Field
        </Button>
      </div>
    </>
  )
}

export default OwnerPriorityPicker
