export interface SelectItem {
  name: string
  value: any
}

export interface SelectorGroup {
  name: string
  items: SelectItem[]
}

interface GroupSelectorProps {
  selectorGroups: SelectorGroup[]
  factors: any[]
  setFactors: (factors: any[]) => void
  handleSelect: () => void
  addToFactor?: number
}

export default function FactorGroupSelector ({ selectorGroups, factors, setFactors, handleSelect, addToFactor = -1 }: GroupSelectorProps): JSX.Element {
  const handleAddFactorGroup = (factorType: string) => {
    setFactors([...factors, { importance: 'important', factors: [{ name: factorType, op: null, value: null }] }])
    handleSelect()
  }
  const handleAddFactor = (factorIndex: number, factorType: string) => {
    const newFactors = [...factors]
    newFactors[factorIndex].factors.push({ name: factorType, op: null, value: null })
    setFactors(newFactors)
    handleSelect()
  }
  return (
    <div style={{ overflow: 'auto' }}>
      {selectorGroups.map((group, index) => {
        return (
          <div key={index}>
            <div style={{ backgroundColor: '#E8E8E8', padding: '10px', fontFamily: 'poppins' }}>
              {group.name}
            </div>
            {group.items.map((item, index) => {
              return (
                <div key={index} style={{ margin: '10px', marginLeft: '25px', cursor: 'pointer', fontFamily: 'poppins' }} onClick={() => (addToFactor >= 0) ? handleAddFactor(addToFactor, item.value) : handleAddFactorGroup(item.value)}>
                  {item.name}
                </div>
              )
            })}
          </div>
        )
      })}
    </div>
  )
}
