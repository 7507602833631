import { doGet, doPatch } from './api'

export interface ChatSettings {
  auto_close_conversations_no_agent_only: boolean
  auto_close_interval_in_minutes: number
  availabilities: Array<any>,
  bot_name: string,
  bot_icon_url: string,
  bot_icon_primary_color: string,
  bot_icon_accent_color: string,
  bot_icon_id: number,
  prompt_icon_url: string,
  prompt_icon_id: number,
  primary_color: string,
  contrast_color: string,
  button_color: string,
  button_color_same_as_primary: boolean,
  chat_size: string,
  branding: boolean,
  chat_setting: string,
  content_subdomain: string,
  conversation_balance_threshold: number,
  default_bot_profile_id: number,
  disable_new_conversation: boolean,
  domains: Array<string>,
  enable_default_greeting: boolean,
  fallback_message: string,
  fallback_timer: number,
  in_office_greeting: string,
  in_office_greeting_bot_id: number,
  master_toggle: true,
  offline_when_team_unavailable: true,
  offline_when_unavailable: false,
  online: true,
  out_office_greeting: string,
  out_office_greeting_bot_id: number,
  packet_code: string,
  prompt_size: string,
  prompt_timer: number,
  prompt_visibility: string,
  send_from_user_id: number,
  send_type: string,
  timezone: string,
  consent_form_require_locations: Array<string>,
  tenant_id: number,
  // This 'attempt_save' value is not actually part of what we expect to get from the Database, we have this to help with validation on the availability page.
  attempt_save: boolean
}

function getChatSettings ({ authHeader }: {authHeader: Record<'Authorization', 'Bearer {sid}'>}): Promise<any> {
  return doGet({ path: '/chat_settings', useChatURL: true, extraHeaders: authHeader })
}

function saveChatSettings ({ state }: { state: ChatSettings }): Promise<any> {
  // const performPatch = !!state.tenant_id
  const path = '/chat_settings'

  const body = {
    type: 'chat_settings',
    attributes: { ...state }
  }

  // if (performPatch) {
  return doPatch({ path: path, useChatURL: true, data: body })
  // }
  // return doPost({ path: path, useChatURL: true, data: body })
}

function getChatPromptIconUrl (): Promise<any> {
  return doGet({ path: '/chat_settings/prompt_icon_url', useChatURL: true })
}

const chatSettingsInitialState = {
  auto_close_conversations_no_agent_only: false,
  auto_close_interval_in_minutes: 480,
  availabilities: [],
  bot_name: 'Bot',
  bot_icon_url: '',
  bot_icon_primary_color: '0693E3',
  bot_icon_accent_color: '2F2F2F',
  bot_icon_id: 1,
  prompt_icon_url: '',
  prompt_icon_id: 1,
  primary_color: '0693E3',
  contrast_color: 'FFFFFF',
  button_color: '0693E3',
  button_color_same_as_primary: true,
  chat_size: 'default',
  branding: true,
  chat_setting: 'manual',
  content_subdomain: null,
  conversation_balance_threshold: null,
  default_bot_profile_id: null,
  disable_new_conversation: false,
  domains: [],
  enable_default_greeting: false,
  fallback_message: '',
  fallback_timer: 300,
  in_office_greeting: "Hi. Let's chat",
  in_office_greeting_bot_id: 34,
  master_toggle: true,
  offline_when_team_unavailable: true,
  offline_when_unavailable: false,
  online: true,
  out_office_greeting: "Sorry, we're not available. Please leave us a message and we will get back to you.",
  out_office_greeting_bot_id: 34,
  packet_code: '35b57512c9ad8815b8fae8ad2d925dcc5c87f55a',
  prompt_size: null,
  prompt_timer: null,
  prompt_visibility: 'always',
  send_from_user_id: null,
  send_type: 'bot',
  timezone: 'US/Eastern',
  attempt_save: false
}

export { getChatSettings, saveChatSettings, getChatPromptIconUrl, chatSettingsInitialState }
