import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import Extension from '@material-ui/icons/Extension'
import CloseIcon from '@material-ui/icons/Close'
import { Card, CardHeader, Divider, IconButton, TextField, Snackbar } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { parse } from 'query-string'
import ZendeskLogo from 'img/zendesk_logo.svg'
import ChatFunnelsIcon from 'img/Logo_purple_transparent_BG.png' // chatfunnels.png'
import Modal from 'components/Modal'
import {
  INTEGRATIONS,
  getCredentialsStatus,
  setCredentials
} from 'api/integrations'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center'
  },
  card: {
    width: 350,
    height: 375,
    marginTop: 200,
    borderRadius: 10
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  logo: {
    height: 50
  },
  logoWrapper: {
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
    marginTop: 25
  },
  button: {
    position: 'absolute',
    bottom: 40,
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%'
  },
  cardContent: {
    height: '100%',
    position: 'relative',
    margin: 20
  },
  description: {
    textAlign: 'center',
    fontSize: 15,
    marginTop: 25
  }
}))

const oauthMappings = {
  access_denied: 'OAuth Error: access was denied. Please try again.',
  immediate_unsuccessful: 'OAuth Error: please try again.'
}

function oauthMessage (inputMessage) {
  const attempt = oauthMappings[inputMessage]
  if (!inputMessage) {
    return ''
  }
  if (!attempt) {
    return 'Unknown error. Please try again'
  }
  return attempt
}

const ZendeskOAuth = (props) => {
  const classes = useStyles()
  const query_string = parse(window.location.search)
  const [snackMessage, setSnackMessage] = useState(oauthMessage(query_string.error))
  const [subdomain, setSubdomain] = useState(props.subdomain || '')
  const [subdomainRequired, setSubdomainRequired] = useState(false)

  function performOauth (event) {
    if (!subdomain) {
      setSubdomainRequired(true)
      return
    }
    updateSubdomain(subdomain).then(() => {
      getCredentialsStatus(INTEGRATIONS.Zendesk)
        .then((js) => {
          if (js.data) {
            window.location.href = js.data.attributes.oauth_start_url
          } else {
            setSubdomainRequired(true)
          }
        }, (err) => {
          setSnackMessage(err.message)
        }
        )
    }, (err) => {
      setSnackMessage(err.message)
    })
  }

  function updateSubdomain (sub) {
    return setCredentials(INTEGRATIONS.Zendesk, [
      {
        attributes: {
          name: 'subdomain',
          value: sub,
          secret: false
        }
      }
    ])
  }

  function onMessageClose (event) {
    setSnackMessage(false)
  }

  return (
    <div className={classes.container}>
      <Card className={classes.card} raised>
        <div className={classes.cardContent}>
          <div className={classes.logoContainer}>
            <div className={classes.logoWrapper}>
              <img src={ChatFunnelsIcon} className={classes.logo} alt='Signals Logo' />
              <img src={ZendeskLogo} className={classes.logo} alt='Zendesk Logo' />
            </div>
          </div>
          <CardHeader title={props.name} style={{ textAlign: 'center' }}>
            <Extension />
          </CardHeader>
          <Divider />
          <div>
            <div>
              <div className={classes.container}>
                <Typography variant='body1' className={classes.description}>
                  You are one step away from linking your Signals account
                  with Zendesk CRM!
                </Typography>
              </div>
              <div className={classes.container}>
                <TextField
                  size='small'
                  fullWidth
                  variant='outlined'
                  placeholder='Zendesk subdomain ...'
                  value={subdomain}
                  onChange={(e) => setSubdomain(e.target.value)}
                  autoFocus
                />
              </div>
              <div>
                <Button
                  variant='outlined'
                  color='primary'
                  className={classes.button}
                  onClick={performOauth}
                >
                  Start activation
                </Button>
              </div>
            </div>

          </div>
        </div>
      </Card>
      <Modal
        title='Subdomain Required'
        open={Boolean(subdomainRequired)}
        onHide={() => setSubdomainRequired(false)}
        onSubmit={() => setSubdomainRequired(false)}
        saveButtonText='Ok'
        size='xs'
      >
        <div style={{ padding: 15 }}>We need your Zendesk subdomain in order to enable the Zendesk integration.</div>
      </Modal>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        aria-describedby='client-snackbar'
        action={[
          <IconButton
            key='close'
            aria-label='Close'
            color='inherit'
            onClick={onMessageClose}
          >
            <CloseIcon className={classes.icon} />
          </IconButton>
        ]}
        open={Boolean(snackMessage)}
        autoHideDuration={6000}
        ContentProps={{
          'aria-describedby': 'message-id'
        }}
        message={<span id='message-id'>{snackMessage}</span>}
      />
    </div>
  )
}

export default ZendeskOAuth
