import { Chip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import dateFormat from 'dateformat'

export default function ActiveFilters (props) {
    const useStyles = makeStyles(theme => ({
        chip: {
            margin: '2px 10px 1px 0px'
          }
    }))

    const classes = useStyles()
    const filters = props.filterOptions
    const filterValues = filters.filterValues

    if (!filterValues) {
      return (
        <></>
      )
    }

    function handleClick (event, menu) {
      props.setFilterState({
        ...props.filterState,
        anchorEl: event.currentTarget,
        subMenu: menu
      })
    }

    let dateLabel = null
    if (filters.dateRange) {
      const startDate = dateFormat(filters.dateRange.startDate, 'mm/dd/yyyy')
      const endDate = dateFormat(filters.dateRange.endDate, 'mm/dd/yyyy')
      dateLabel = startDate + ' - ' + endDate
    }
    let dateFilter = filterValues.filter(value => value.type === 'date')
    if (dateFilter.length) {
      dateFilter = dateFilter[0]
      const startDate = dateFormat(dateFilter.value.startDate, 'mm/dd/yyyy')
      const endDate = dateFormat(dateFilter.value.endDate, 'mm/dd/yyyy')
      dateLabel = startDate + ' - ' + endDate
    }

    let teamLabel = null
    if (filters.teamFilter) {
      if (filters.teamFilter.length > 1) {
        teamLabel = filters.teamFilter.length + ' teams'
      } else if (filters.teamFilter.length === 1) {
        teamLabel = filters.teamFilter[0].name
      }
    }

    const chips = filterValues.filter(value => Boolean(value.kind)).map(filter => {
      let label = ''
      const onDelete = () => filter.setValue([])
      if (filter.value.length > 1) {
        label = filter.value.length + ' ' + filter.kind
      } else if (filter.value.length === 1) {
        label = filter.value[0].label
      }
      return ({
        label: label,
        handleDelete: onDelete
      })
    })

    return (
      <div>
        {dateLabel &&
          <Chip
            label={dateLabel}
            variant='outlined'
            color='primary'
            className={classes.chip}
            onClick={(e) => handleClick(e, 'Date')}
          />}
        {teamLabel &&
          <Chip
            label={teamLabel}
            variant='outlined'
            color='primary'
            className={classes.chip}
            onDelete={() => filters.setTeamFilter([])}
            onClick={(e) => handleClick(e, 'Team')}
          />}
        {chips.filter(chip => chip.label).map((chip, index) => (
          <Chip
            key={index}
            label={chip.label}
            variant='outlined'
            color='primary'
            className={classes.chip}
            onDelete={chip.handleDelete}
          />
        ))}
      </div>
    )
  }
