import { useState, useEffect, useRef } from 'react'
import { jsPlumbUtil } from 'jsplumbtoolkit'
import OptionPort from '../OptionPort'
import useNodeStyles from '../nodes/NodeStyles'
import { ReactComponent as SequenceIcon } from 'img/SequenceIcon.svg'
import SequenceFlowModal from './SequenceFlowModal'
import { getSequenceFlow, getSequenceTemplate, saveSequenceTemplate } from 'api/sequences'
import 'jsplumb'

const SequenceNode = props => {
  const base = props.base
  const classes = useNodeStyles(props)
  const restoreVersionRef = useRef(null)
  const [sequenceFlow, setSequenceFlow] = useState(null)
  const [sequenceTemplate, setSequenceTemplate] = useState(null)
  const [sequenceFlowID, setSequenceFlowID] = useState(null)
  const [sequenceName, setSequenceName] = useState(props?.data?.title || '...loading')
  useEffect(() => {
    if (props?.data?.title) {
      setSequenceName(props.data.title)
    }
  }, [props?.data?.title])

  const assignKey = data => {
    data._reactKey = data._reactKey || jsPlumbUtil.uuid()
    return data._reactKey
  }

  useEffect(() => {
    if (props.modalOpen) {
      window.sequenceNode = base
    } else {
      window.sequenceNode = null
    }
  }, [base, props.modalOpen])

  useEffect(() => {
    if (!props.modalOpen) {
      const sequenceID = base.state.parentSequenceID
      if (sequenceID) {
        getSequenceTemplate({ sequenceID })
          .then(response => {
            setSequenceTemplate(response.data)
            if (base.state.trackTemplate) {
              setSequenceFlowID(response.data.attributes.sequence_flow_id)
            } else {
              setSequenceFlowID(base.state.sequenceFlowID)
            }
          })
      } else {
        setSequenceFlowID(base.state.sequenceFlowID)
      }
    }
    // eslint-disable-next-line
  }, [props.modalOpen])

  useEffect(() => {
    if (sequenceTemplate) {
      if (base.state.trackTemplate) {
        setSequenceFlowID(sequenceTemplate.attributes.sequence_flow_id)
      } else {
        setSequenceFlowID(base.state.sequenceFlowID)
      }
      if (sequenceTemplate?.attributes?.name) {
        setSequenceName(sequenceTemplate.attributes.name)
      }
    }
  }, [base.state.sequenceFlowID, base.state.trackTemplate, sequenceTemplate])

  useEffect(() => {
    if (sequenceFlowID && !props.modalOpen) {
      getSequenceFlow({ sequenceFlowID })
        .then(response => {
          setSequenceFlow(response.attributes.flow)
        })
    }
  }, [props.modalOpen, sequenceFlowID])

  function restoreFlow (flowID) {
    getSequenceFlow({ sequenceFlowID: flowID })
      .then(response => {
        setSequenceFlow(response.attributes.flow)
        const nodes = response.attributes.flow.data.nodes
        const output = nodes.filter(n => n.id === 'output')[0]
        const ports = output.ports.map(p => ({ id: p.id, label: p.label }))
        const sequenceAttributes = sequenceTemplate?.attributes
        saveSequenceTemplate({
          flowID,
          ports,
          title: sequenceAttributes.name,
          description: sequenceAttributes.description,
          sequenceID: sequenceTemplate?.id
        }).then((response) => {
          setSequenceTemplate(response.data)
          restoreVersionRef.current()
        })
      })
  }

  const local = !base.state.trackTemplate
  const subtitle = local ? 'Local Sequence' : 'Global Sequence'

  return (
    <div>
      <div
        className={`${classes.dragger} ${local ? classes.localSequence : classes.flowSequence}`}
        onDoubleClick={() => props.setModalOpen(true)}
      >
        <div className='node-header' style={{ justifyContent: 'space-between', paddingRight: 10 }}>
          <div style={{ display: 'flex', height: '100%' }}>
            <div className={classes.iconDiv}><SequenceIcon /></div>
            <div className={classes.cardTitle} style={{ display: 'flex', alignItems: 'center' }}>
              <div>
                <div
                  style={{
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    width: 175
                  }}
                >
                  {base?.state?.title || sequenceName}
                </div>
                <div style={{
                  fontWeight: 500,
                  fontSize: '0.75em'
                }}
                >
                  {subtitle}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='node-body' style={{ minHeight: 18 }}>
          {base.state.ports.map((c, index) => {
            return (
              <OptionPort
                index={index}
                data={c}
                key={assignKey(c)}
                parent={base}
                kind={local ? 'localSequence' : 'flowSequence'}
              />
            )
          })}
        </div>
        <jtk-target
          scope='default'
        />
      </div>
      {sequenceFlowID && props.modalOpen &&
        <SequenceFlowModal
          open={props.modalOpen}
          close={() => props.setModalOpen(false)}
          sequenceFlow={sequenceFlow}
          sequenceNode={base}
          sequenceTitle={base.state.title}
          sequenceDescription={base.state.description}
          sequenceFlowID={sequenceFlowID}
          sequenceTemplate={sequenceTemplate}
          ports={base.state.ports}
          restoreFlow={restoreFlow}
          restoreVersionRef={restoreVersionRef}
        />}
    </div>
  )
}

export default SequenceNode
