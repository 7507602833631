import React, { useState, useContext } from 'react'
import { SessionContext } from 'session-context'
import { withStyles } from '@material-ui/core/styles'
import SearchIcon from '@material-ui/icons/Search'
import InputBase from '@material-ui/core/InputBase'
import ClearIcon from '@material-ui/icons/Clear'
import { MobileAgentChatStyles as useStyles } from './MobileChatStyles'
import ConversationList from './ConversationList'
import { Drawer, Avatar, Badge, Divider } from '@material-ui/core'
// import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline'
// import SortIcon from '@material-ui/icons/Sort'
import MenuIcon from '@material-ui/icons/Menu'
import MissingAvatar from 'img/agent-profile-large-invite.svg'
import Switch from '@material-ui/core/Switch'

// const StyledMenuItem = withStyles(theme => ({
//   root: {
//     minHeight: 20,
//     '&:focus': {
//       backgroundColor: '#AA88E2',
//       '&:hover': {
//         backgroundColor: '#AA88E2'
//       }
//     },
//     '&:hover': {
//       backgroundColor: '#F0E3FF'
//     }
//   }
// }))(MenuItem)

const CustomSwitch = withStyles({
  switchBase: {
    '&$checked': {
      color: '#61CBBC'
    },
    '&$checked + $track': {
      backgroundColor: '#61CBBC'
    }
  },
  checked: {},
  track: {}
})(Switch)

function ChatHeader (props) {
  const classes = useStyles()

  return (
    <div className={classes.header}>
      <MenuIcon className={classes.menuIcon} onClick={() => props.openDrawer()} />
      <InputBase
        startAdornment={<SearchIcon className={classes.searchIcon} />}
        endAdornment={
          props.search !== ''
            ? <ClearIcon className={classes.clearIcon} onClick={() => props.setSearch('')} />
            : <></>
        }
        className={classes.searchInput}
        classes={{ focused: classes.searchInputFocused }}
        type='text'
        value={props.search}
        onChange={e => props.setSearch(e.target.value)}
        placeholder='Search'
      />
    </div>
  )
}

// function FilterDiv (props) {
//   const classes = useStyles()

//   const sortLabel = props.sortCriteria === 'newest' ? 'NEWEST FIRST' : 'OLDEST FIRST'

//   function toggleSort () {
//     if (props.sortCriteria === 'newest') {
//       props.setSortCriteria('oldest')
//     } else {
//       props.setSortCriteria('newest')
//     }
//   }

//   return (
//     <div className={classes.filterControls}>
//       <div className={classes.filterDiv}>
//         <ChatBubbleOutlineIcon className={classes.chatIcon} />
//         <Select
//           value={props.searchFilter}
//           onChange={e => {
//             props.setSearchFilter(e.target.value)
//           }}
//           className={classes.filterSelect}
//           disableUnderline
//           inputProps={{ classes: { root: classes.inputText } }}
//           classes={{ icon: classes.selectIcon }}
//         >
//           <StyledMenuItem value='mine_open'>My open conversations</StyledMenuItem>
//           <StyledMenuItem value='mine'>My conversations</StyledMenuItem>
//           <StyledMenuItem value='all_open'>All open conversations</StyledMenuItem>
//           <StyledMenuItem value='all'>All conversations</StyledMenuItem>
//         </Select>
//       </div>
//       <div className={classes.sortDiv} onClick={toggleSort}>
//         <div className={classes.sortText}>{sortLabel}</div>
//         <SortIcon className={classes.sortIcon} />
//       </div>
//     </div>
//   )
// }

function MainPage (props) {
  const classes = useStyles()
  const [drawerOpen, setDrawerOpen] = useState(false)
  const { changeAvailability, participant } = useContext(SessionContext)
  // const searchFilter = props.searchFilter
  // const setSearchFilter = props.setSearchFilter
  // const sortCriteria = props.sortCriteria
  // const setSortCriteria = props.setSortCriteria
  const search = props.search
  const setSearch = props.setSearch
  const participantAvailability = participant?.attributes?.status
  const available = participantAvailability === 'available'
  const cls = available ? classes.onlineBadge : classes.offlineBadge

  function handleChangeAvailability (e) {
    if (!e) e = window.event
    e.cancelBubble = true
    if (e.stopPropagation) e.stopPropagation()
    e.preventDefault()

    changeAvailability()
  }

  return (
    <div className={classes.mainPage}>
      <ChatHeader search={search} setSearch={setSearch} openDrawer={() => setDrawerOpen(true)} />
      {/* <FilterDiv searchFilter={searchFilter} setSearchFilter={setSearchFilter} sortCriteria={sortCriteria} setSortCriteria={setSortCriteria} /> */}
      <div style={{ height: 'calc(100% - 132px)' }}>
        <ConversationList
          activeConversationId={props.activeConversationId}
          chooseConversation={props.chooseConversation}
          conversationList={props.conversationList}
          readReceipts={props.readReceipts}
          morePages={props.morePages}
        />
      </div>
      <Drawer anchor='left' open={drawerOpen} onClose={() => setDrawerOpen(false)} classes={{ paper: classes.drawer }}>
        <div className={classes.drawerDiv}>
          <Badge
            overlap='circle'
            badgeContent=''
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            classes={{ badge: cls }}
          >
            {(props.user.attributes.profile_picture_url)
              ? <Avatar className={classes.avatar} src={props.user.attributes.profile_picture_url} />
              : <Avatar className={classes.avatar} src={MissingAvatar} />}
          </Badge>
          <div style={{ marginTop: 10, fontWeight: 500, fontSize: '20px', letterSpacing: 1, color: '#4C4C4C', marginBottom: 10 }}>
            {props.user.attributes.name}
          </div>
          <Divider />
          <div style={{ marginTop: 15 }}>
            <div>
              Chat Status
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div
                onClick={(e) => handleChangeAvailability(e)}
                style={{ width: 60, marginLeft: -10 }}
              >
                <CustomSwitch
                  checked={available}
                  value='availability'
                  inputProps={{ 'aria-label': 'available to receive incoming chats' }}
                />
              </div>
              <div style={{ color: available ? '#61CBBC' : '#9B9B9B' }}>
                {available ? 'Available' : 'Unavailable'}
              </div>
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  )
}

export default MainPage
