/* eslint-disable @typescript-eslint/naming-convention */
import { useState } from 'react'
import { Modal } from 'library/Modal'
import { User } from 'classes/users'
import { UserOutOfOffice } from './UserOutOfOffice'
import { dateHelper } from 'cf-components/DateRangeSelector'
import { startOfDay, endOfWeek } from 'date-fns'

type modalProps = {
  user: User
  onHide: () => void
}

interface userOutOfOfficeType {
  id?: number
  attributes: {
    start_date: string
    end_date: string
    rotation_availability: { livechat: boolean; calendarDrops: boolean }
    user_id: User['id']
  }
}

export function UserOutOfOfficeModal (props: modalProps): any {
  const fallbackStartDate = startOfDay(new Date())
  const fallbackEndDate = startOfDay(endOfWeek(fallbackStartDate))
  const initialState = { attributes: { user_id: props.user.id, rotation_availability: { liveChat: false, calendarDrops: false }, start_date: fallbackStartDate, end_date: fallbackEndDate } }
  const firstSelection = props.user.outOfOffice.length > 0 ? props.user.outOfOffice[0] : initialState
  const [currentSelection, setCurrentSelection] = useState<userOutOfOfficeType>(firstSelection)
  const [updates, setUpdates] = useState<userOutOfOfficeType | undefined>(firstSelection)
  const type = currentSelection?.id ? 'update' : 'post'
  const [updateType, setUpdateType] = useState<'update' | 'post'>(type)
  const editOutOfOffice = (values: userOutOfOfficeType): void => {
    let userOutOfOfficeObj: userOutOfOfficeType
    if (values) {
      userOutOfOfficeObj = { attributes: { ...values.attributes } }
    } else {
      setUpdates(values)
      return
    }
    if (values.id) {
      userOutOfOfficeObj.id = values.id
    }
    setUpdates(userOutOfOfficeObj)
  }

  const formatUpdates = (): userOutOfOfficeType => {
    const formattedUpdates = { ...updates }
    if (formattedUpdates.attributes && typeof formattedUpdates.attributes.start_date === typeof String()) {
      formattedUpdates.attributes.start_date = (formattedUpdates.attributes.start_date).split('T')[0]
      formattedUpdates.attributes.end_date = (formattedUpdates.attributes.end_date).split('T')[0]
    }

    if (formattedUpdates.attributes && typeof formattedUpdates.attributes.start_date !== String()) {
      formattedUpdates.attributes.start_date = dateHelper.dateToString(new Date(formattedUpdates.attributes.start_date), 'yyyy-mm-dd')
      formattedUpdates.attributes.end_date = dateHelper.dateToString(new Date(formattedUpdates.attributes.end_date), 'yyyy-mm-dd')
    }

    return formattedUpdates as userOutOfOfficeType
  }

  const submitForm = (): void => {
    const index = props.user.outOfOffice.findIndex((instance: any) => instance.id === updates?.id)
    if (index > -1) {
      props.user.outOfOffice[index] = updates
    } else {
      props.user.outOfOffice.push(updates)
    }

    const formattedUpdates = formatUpdates()
    User.outOfOfficeChange({ ID: props.user.id, type: updateType, data: formattedUpdates })?.then(() => props.onHide())
  }

  const onDelete = (): void => {
    const index = props.user.outOfOffice.findIndex((instance: any) => instance.id === updates?.id)
    props.user.outOfOffice.splice(index, 1)
    User.outOfOfficeChange({ ID: currentSelection.id, type: 'delete' })?.then(() => props.onHide())
  }

  const isNew = updateType === 'post'
  const deleteOrCancel = isNew ? props.onHide : onDelete
  const saveButtonText = isNew ? 'Save' : 'Update'
  const cancelButtonText = isNew ? 'Cancel' : 'Delete'

  return (
    <Modal
      open
      title='Out of Office'
      onHide={props.onHide}
      size='md'
      closeBtnFunction={deleteOrCancel}
      cancelBtnText={cancelButtonText}
      saveBtnText={saveButtonText}
      handleSave={submitForm}
      saveIcon='save'
      saveDisabled={updates === firstSelection && type !== 'post'}
    >
      <UserOutOfOffice
        user={props.user}
        editOutOfOffice={editOutOfOffice}
        setCurrentSelection={setCurrentSelection}
        currentSelection={currentSelection}
        initialState={initialState}
        setUpdateType={setUpdateType}
      />
    </Modal>
  )
}
