import { useReducer } from 'react'
import AvatarEditor from 'react-avatar-editor'
import { makeStyles } from '@material-ui/core/styles'
import { reducer } from 'library/helpers'

const useStyles = makeStyles(theme => ({
  editor: {
    width: 'max-content'
  },
  inputSlider: {
    verticalAlign: 'center',
    marginLeft: 5
  },
  controls: {
    display: 'flex',
    marginTop: 5,
    justifyContent: 'center'
  }
}))

type PhotoEditorProps = {
  editorRef: any
  photo: any
}

export function PhotoEditor (props: PhotoEditorProps) {
  const classes = useStyles()

  const [state, dispatch] = useReducer(reducer, {
    scale: 1,
    position: { x: 0.5, y: 0.5 },
    rotate: 0,
    height: 220,
    width: 220,
    border: 5
  })

  const handleScale = (e: any) => {
    const scale = parseFloat(e.target.value)
    dispatch({ scale })
  }

  return (
    <div className={classes.editor}>
      <AvatarEditor
        ref={props.editorRef}
        image={props.photo}
        width={state.width}
        height={state.height}
        position={state.position}
        rotate={parseFloat(state.rotate)}
        border={state.border}
        scale={state.scale}
        borderRadius={220}
        onPositionChange={(position: any) => dispatch({ position })}
        crossOrigin='anonymous'
      />
      <div className={classes.controls}>
        <span style={{ fontWeight: 'bold' }}>ZOOM</span>
        <input
          name='scale'
          type='range'
          onChange={handleScale}
          min='1'
          max='2'
          step='0.01'
          defaultValue='1'
          className={classes.inputSlider}
        />
      </div>
    </div>
  )
}
