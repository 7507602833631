import { GenericObject } from './genericObject'
import { LoadAllProps } from './queryHelpers'
import { useDoQuery } from './useDoQuery'

interface ApolloCreditUsuageList {
    list: ApolloCreditUsuage[]
    dict: { [id: ApolloCreditUsuage['date']]: ApolloCreditUsuage }
}

export class ApolloCreditUsuage extends GenericObject {
    date: string
    prospectLookup: number
    contactEnrichment: number
    totalUsed: number
    objectType = 'ApolloUsageHistory'

    get total (): string { return (this.prospectLookup + this.contactEnrichment).toString() }

    static loadAll (props: LoadAllProps): { data: ApolloCreditUsuageList, isLoading: boolean } {
        return useDoQuery({
            path: '/component_usage/apollo_usage_history',
            objectClass: ApolloCreditUsuage,
            refetchInterval: 120000,
            useChatURL: false,
            searchParams: props.searchParams
        })
    }

    static getName () : string {
      return 'ApolloUsageHistory'
    }

    constructor ({ row }: { row: Record<string, any> }) {
        super({ row })
        const usage = row.attributes
        this.date = usage.date
        this.prospectLookup = usage.prospect_used
        this.contactEnrichment = usage.enrichment_used
        this.totalUsed = usage.total_used
    }
}
