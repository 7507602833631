import { makeStyles } from '@material-ui/core/styles'
import AvatarGroup from '@material-ui/lab/AvatarGroup'
import { Avatar } from 'cf-components/Avatars'
import { getVisitorInfo } from './conversationHelpers'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
    typingIndicator: {
      postion: 'absolute',
      bottom: 0
    },
    typingDiv: {
      height: 40,
      display: 'flex',
      marginLeft: 20,
      alignItems: 'center'
    },
    typingContents: {
      paddingLeft: 7,
      fontSize: '12px',
      fontStyle: 'italic'
    },
    avatar: {
      border: '1px solid white',
      borderRadius: '100%'
    }
  }))

function TypingIndicator (props: any) {
  const classes = useStyles()
  const conversation = props.conversation
  const typing = props.typing
  const eventParticipantID = typing.length > 0 ? typing[0].participant_id : 0
  const eventParticipantName = typing.length > 0 ? typing[0].participant_name : ''
  const { visitorName, visitorID } = getVisitorInfo(conversation)
  const manyTypers = typing.length > 1
  let name = ''
  if (eventParticipantID === visitorID) {
    name = visitorName
  } else {
    name = eventParticipantName || 'Other Agent'
  }
  if (typing.length > 0) {
    return (
      <div className={classes.typingIndicator}>
        <div className={classes.typingDiv}>
          <AvatarGroup max={2} classes={{ avatar: classes.avatar }}>
            {typing.map((event: any, index: number) => (
              <div key={index}>
                <Avatar
                  avatarUrl={event.participant_picture}
                  participantID={event.participant_id}
                  size='sm'
                />
              </div>)
            )}
          </AvatarGroup>
          <Typography variant='subtitle1' className={classes.typingContents}>
            {manyTypers ? (name + ' + ' + (typing.length - 1) + ' are ') : name + ' is '} typing...
          </Typography>
        </div>
      </div>
    )
  }

  return (
    <></>
  )
}

export default TypingIndicator
