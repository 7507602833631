import { useQuery } from 'react-query'
import { doGet } from './api'

export const getSchema = (obj: string, field: string, search?: string) => {
  const path = `/schema/${obj}/${field}`
  if (search) {
    const params = new URLSearchParams()
    params.set('search', search)
    return doGet({ path: path + '?' + params.toString(), useChatURL: true })
  }
  return doGet({ path, useChatURL: true })
}

export const useSchema = (obj: string, field: string, search?: string) => {
  if (!search) {
    search = ''
  }
  return useQuery(
    [`schema.${obj}.${field}.${search}`],
    () => {
      return getSchema(obj, field, search).then((response) => response.data.attributes.options)
    },
    {
      staleTime: 120000,
      retry: (failureCount, _) => {
        return failureCount < 3
      }
    }
  )
}
