import Skeleton from '@material-ui/lab/Skeleton';
import { SkeletonSize } from './SkeletonLoader';

interface SkeletonAvatarProps {
  size: SkeletonSize
  variant?: 'circle' | 'rect'
}

const avatarSizeMapping = {
  sm: '1em',
  md: '2.5em',
  lg: '3.5em'
}

export default function SkeletonAvatar ({ size = 'md', variant = 'circle' } : SkeletonAvatarProps) {
  const mappedSize = avatarSizeMapping[size]
  return (
    <Skeleton variant={variant} height={mappedSize} width={mappedSize} />
  )
}
