/* eslint-disable @typescript-eslint/naming-convention */
import { makeStyles } from '@material-ui/core/styles'
import FTUX, { FTUXAnchor } from 'cf-components/FTUX'
import { DisplaySettings } from 'classes/displaySettings'
import { useEffect, useState } from 'react'
import { Dialog, Button } from 'library/materialUI'
import ModalImage from 'img/agent-chat-modal-img.png'
import OnboardingPrompt from './OnboardingPrompt'
import NavSidebarGif from 'img/Navigation Sidebar.gif'
import AvailabilityGif from 'img/Availabilty1.gif'
import ConvoDetailsGif from 'img/Conversation Details.gif'
import AddCardGif from 'img/Add a Card.gif'
import CreateReplyGif from 'img/Create a Saved Reply.gif'
import ConferencingLinkGif from 'img/Conferencing Link.gif'
import NavigateOrganizeGif from 'img/Navigate and Organize.gif'
import OrganizeChatsGif from 'img/Organize your Chats.gif'
import SavedRepliesGif from 'img/Saved Replies.gif'
import SFStep1 from 'img/Step1-CROPPED.gif'
import SFStep2 from 'img/Step2-CROPPED.gif'
import SFStep3 from 'img/Step3-CROPPED.gif'
import SFStep4 from 'img/Step4-CROPPED.gif'
import LearnMoreLink from './LearnMoreLink'

const useStyles = makeStyles(theme => ({
  containerDiv: {
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'poppins'
  },
  welcomeImage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '299px',
    backgroundColor: '#EFE6F8'
  },
  welcomeTitle: {
    fontSize: '24px',
    marginLeft: '46px',
    marginRight: '46px',
    marginTop: '20px',
    marginBottom: '10px'
  },
  welcomeMessage: {
    fontSize: '14px',
    marginLeft: '46px',
    marginRight: '46px',
    color: '#000000BF',
    opacity: '0.75'
  },
  welcomeFooter: {
    height: '75px',
    marginLeft: 'auto'
  },
  welcomeButton: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    marginRight: '14px',
    marginTop: '10px'
  },
  grayText: {
    color: '#00000080',
    fontFamily: 'poppins'
  },
  purpleText: {
    color: theme.palette.primary.main,
    fontFamily: 'poppins',
    fontWeight: 'bold'
  }
}))

const defaultState = {
  welcome: { complete: false },
  nav: { complete: false },
  availability: { complete: false },
  conversation_history: { complete: false },
  internal_notes: { complete: false },
  conversation_details: { complete: false },
  saved_replies: { complete: false },
  conferencing: { complete: false },
  calendar_drop: { complete: false },
  salesloft_card: { complete: false }
}

interface promptListType {
  anchorID: string
  xOffset: number
  yOffset: number
  updateFunction: () => void
  anchors: FTUXAnchor[],
  visible: boolean,
  closingFunction?: () => void
}

interface AgentChatOnboardingProps {
  setDrawerOpen: (value: boolean) => void
  setCardSelectOpen: () => void
  setConferenceOpen: () => void
  setCalendarOpen: (value: boolean) => void
  activeConversation: number
  otherModalOpen: boolean
}

export default function AgentChatOnboarding ({ activeConversation, setDrawerOpen, setCardSelectOpen, setCalendarOpen, otherModalOpen }: AgentChatOnboardingProps): JSX.Element {
  const classes = useStyles()
  const { settings, isLoading } = DisplaySettings.load()
  const [modalOpen, setModalOpen] = useState(true)
  const [hidePrompts, setHidePrompts] = useState(false)
  const promptList: promptListType[] = [
    {
      anchorID: 'nav-sidebar',
      xOffset: 120,
      yOffset: 0,
      updateFunction: () => { DisplaySettings.update({ page: 'ftux', type: 'agent-chat', settings: { ...settings.ftux.settings['agent-chat'], nav: { complete: true } } }) },
      closingFunction: () => setDrawerOpen(false),
      anchors: [{
        title: 'Navigation sidebar',
        body: (
          <div>
            <div>
              <span className={classes.grayText}>Click this button to open the chat navigation sidebar.</span>
            </div>
            <div style={{ marginTop: '15px' }}>
              <img src={NavSidebarGif} alt='sidebar-gif' width={233} height={132} />
            </div>
            <LearnMoreLink link='https://help.getsignals.ai/article/4z1cxf7fgm-default-views' />
          </div>
        ),
        anchorID: 'nav-sidebar',
        anchorPos: 'left',
        anchorOriginHorizontal: 50,
        anchorOriginVertical: 'top',
        transformOriginHorizontal: 'left',
        transformOriginVertical: 'top',
        arrowOffset: '15px',
        additionalEffect: () => setDrawerOpen(true)
      }, {
        title: 'Navigate and organize',
        body: (
          <div>
            <div className={classes.grayText}>
              Here you can search, filter, and organize your conversations.
            </div>
            <div style={{ marginTop: '15px' }}>
              <img src={NavigateOrganizeGif} alt='nag-org-gif' width={233} />
            </div>
            <LearnMoreLink link='https://help.getsignals.ai/article/72mij3pp7i-search-and-review' />
          </div>
        ),
        anchorID: 'left-menu-bar',
        anchorPos: 'left',
        anchorOriginHorizontal: 300,
        anchorOriginVertical: 'top',
        transformOriginHorizontal: 'left',
        transformOriginVertical: 'top',
        arrowOffset: '15px'
      }, {
        title: 'Organize your chats',
        body: (
          <div>
            <div className={classes.grayText}>
              You can also create custom chat views based on filter criteria like agents involved, chatbots engaged, review status, and conversation status.
            </div>
            <div style={{ marginTop: '15px' }}>
              <img src={OrganizeChatsGif} alt='org-chats-gif' width={233} />
            </div>
            <LearnMoreLink link='https://help.getsignals.ai/article/tqidgzr3qa-customizing-conversation-views' />
          </div>
        ),
        anchorID: 'nav-3',
        anchorPos: 'bottom-left',
        anchorOriginHorizontal: 'left',
        anchorOriginVertical: -452,
        transformOriginHorizontal: 'right',
        transformOriginVertical: 'top',
        arrowOffset: '68px'
      }],
      visible: !settings?.ftux?.settings['agent-chat']?.nav?.complete
    },
    {
      anchorID: 'availability',
      xOffset: 150,
      yOffset: 10,
      updateFunction: () => DisplaySettings.update({ page: 'ftux', type: 'agent-chat', settings: { ...settings.ftux.settings['agent-chat'], availability: { complete: true } } }),
      anchors: [{
        title: 'Availability',
        body: (
          <div>
            <div>
              <span className={classes.grayText}>Be sure to turn on your availability. Bots will not assign conversations to you unless you're marked available</span>
            </div>
            <div style={{ marginTop: '15px' }}>
              <img src={AvailabilityGif} alt='availability-gif' width={233} height={204} />
            </div>
            <LearnMoreLink link='https://help.getsignals.ai/article/x4ufxao4i1-08-other-tools' />
          </div>
        ),
        anchorID: 'availability',
        anchorPos: 'left',
        anchorOriginHorizontal: 220,
        anchorOriginVertical: 0,
        transformOriginHorizontal: 'left',
        transformOriginVertical: 'top',
        arrowOffset: '460px'
      }],
      visible: !settings?.ftux?.settings['agent-chat']?.availability?.complete && activeConversation !== null
    },
    {
      anchorID: 'conversation_history',
      xOffset: -40,
      yOffset: 0,
      updateFunction: () => DisplaySettings.update({ page: 'ftux', type: 'agent-chat', settings: { ...settings.ftux.settings['agent-chat'], conversation_history: { complete: true } } }),
      anchors: [{
        title: 'Conversation History',
        body: (
          <div>
            <div>
              <span className={classes.grayText}>When you open a conversation, gain context by reading through previous interactions and messages.</span>
            </div>
            <LearnMoreLink link='https://help.getsignals.ai/article/59cwv1ibdf-conversation-review' />
          </div>
        ),
        anchorID: 'conversation_history',
        anchorPos: 'right',
        anchorOriginHorizontal: -400,
        anchorOriginVertical: 100,
        transformOriginHorizontal: 'left',
        transformOriginVertical: 'top',
        arrowOffset: '1px'
      }],
      visible: !settings?.ftux?.settings['agent-chat']?.conversation_history?.complete && activeConversation !== null
    },
    {
      anchorID: 'internal_notes',
      xOffset: 600,
      yOffset: -20,
      updateFunction: () => DisplaySettings.update({ page: 'ftux', type: 'agent-chat', settings: { ...settings.ftux.settings['agent-chat'], internal_notes: { complete: true } } }),
      anchors: [{
        title: 'Internal notes',
        body: (
          <div>
            <div>
              <span className={classes.grayText}>Notes that are center-aligned and italicized are only visible to you and not the person you’re chatting with</span>
            </div>
          </div>
        ),
        anchorID: 'conversation_history',
        anchorPos: 'right',
        anchorOriginHorizontal: -350,
        anchorOriginVertical: 80,
        transformOriginHorizontal: 'left',
        transformOriginVertical: 'top',
        arrowOffset: '20px'
      }],
      visible: !settings?.ftux?.settings['agent-chat']?.internal_notes?.complete && activeConversation !== null
    },
    {
      anchorID: 'conversation_details',
      xOffset: -65,
      yOffset: 50,
      updateFunction: () => DisplaySettings.update({ page: 'ftux', type: 'agent-chat', settings: { ...settings.ftux.settings['agent-chat'], conversation_details: { complete: true } } }),
      anchors: [{
        title: 'Conversation Details',
        body: (
          <div>
            <div>
              <span className={classes.grayText}>This sidebar gives you quick access to information about your site visitors and tools to help you engage with them.</span>
            </div>
            <div style={{ marginTop: '15px' }}>
              <img src={ConvoDetailsGif} alt='convo-details-gif' width={233} height={158} />
            </div>
            <LearnMoreLink link='https://help.getsignals.ai/article/miiw5nrhro-customizing-conversation-details' />
          </div>
        ),
        anchorID: 'conversation_details',
        anchorPos: 'right',
        anchorOriginHorizontal: -370,
        anchorOriginVertical: 50,
        transformOriginHorizontal: 'left',
        transformOriginVertical: 'top',
        arrowOffset: '1px'
      }, {
        title: 'Customize Sidebar',
        body: (
          <div>
            <div>
              <span className={classes.grayText}>Click "Edit Cards" in the top right to customize the sidebar to your needs.</span>
            </div>
            <LearnMoreLink link='https://help.getsignals.ai/article/miiw5nrhro-customizing-conversation-details' />
          </div>
        ),
        anchorID: 'conversation_details',
        anchorPos: 'right',
        anchorOriginHorizontal: -130,
        anchorOriginVertical: -56,
        transformOriginHorizontal: 'left',
        transformOriginVertical: 'top',
        arrowOffset: '1px',
        additionalEffect: setCardSelectOpen
      }, {
        title: 'Add a card',
        body: (
          <div>
            <div>
              <span className={classes.grayText}>Click a card to pin or remove it from your sidebar. You can also drag your pinned cards to re-arrange them.</span>
            </div>
            <div style={{ marginTop: '15px' }}>
              <img src={AddCardGif} alt='add-card-gif' width={233} height={216} />
            </div>
            <LearnMoreLink link='https://help.getsignals.ai/article/miiw5nrhro-customizing-conversation-details' />
          </div>
        ),
        anchorID: 'conversation_details_3',
        anchorPos: 'right',
        anchorOriginHorizontal: -360,
        anchorOriginVertical: 50,
        transformOriginHorizontal: 'left',
        transformOriginVertical: 'top',
        arrowOffset: '1px'
      }],
      visible: !settings?.ftux?.settings['agent-chat']?.conversation_details?.complete && activeConversation !== null
    },
    {
      anchorID: 'saved_replies',
      xOffset: -48,
      yOffset: -30,
      updateFunction: () => DisplaySettings.update({ page: 'ftux', type: 'agent-chat', settings: { ...settings.ftux.settings['agent-chat'], saved_replies: { complete: true } } }),
      anchors: [{
        title: 'Create a saved reply',
        body: (
          <div>
            <div>
              <span className={classes.grayText}>Click here to save a message and easily re-use it in the future.</span>
            </div>
            <div style={{ marginTop: '15px' }}>
              <img src={CreateReplyGif} alt='create-reply-gif' width={233} height={46} />
            </div>
            <LearnMoreLink link='https://help.getsignals.ai/article/mqdcg568ku-saved-replies' />
          </div>
        ),
        anchorID: 'saved_replies',
        anchorPos: 'bottom',
        anchorOriginHorizontal: -238,
        anchorOriginVertical: -340,
        transformOriginHorizontal: 'left',
        transformOriginVertical: 'top',
        arrowOffset: '1px'
      },
      {
        title: 'Saved Replies',
        body: (
          <div>
            <div>
              <span className={classes.grayText}>Find your saved replies here in the Conversation Details sidebar. We've provided you with a few to get started</span>
            </div>
            <LearnMoreLink link='https://help.getsignals.ai/article/g3gusaar89-saved-replies' />
          </div>
        ),
        anchorID: 'saved_replies_2',
        anchorPos: 'right',
        anchorOriginHorizontal: -350,
        anchorOriginVertical: 0,
        transformOriginHorizontal: 'left',
        transformOriginVertical: 'top',
        arrowOffset: '1px'
      },
      {
        title: 'Insert a reply',
        body: (
          <div>
            <div>
              <span className={classes.grayText}>Inserting a reply like “helpful hello” allows you to quickly let the visitor know you’re reviewing the chat and will be able to help them shortly.</span>
            </div>
            <div style={{ marginTop: '15px' }}>
              <img src={SavedRepliesGif} alt='saved-reply-gif' width={233} />
            </div>
          </div>
        ),
        anchorID: 'saved_replies_2',
        anchorPos: 'right',
        anchorOriginHorizontal: -350,
        anchorOriginVertical: 0,
        transformOriginHorizontal: 'left',
        transformOriginVertical: 'top',
        arrowOffset: '1px'
      },
      {
        title: 'Review and send',
        body: (
          <div>
            <div>
              <span className={classes.grayText}>The message will be inserted here, and you can hit "send" to send your reply when you're ready</span>
            </div>
          </div>
        ),
        anchorID: 'saved_replies_3',
        anchorPos: 'bottom-left',
        anchorOriginHorizontal: 80,
        anchorOriginVertical: -290,
        transformOriginHorizontal: 'left',
        transformOriginVertical: 'top',
        arrowOffset: '1px'
      }],
      visible: !settings?.ftux?.settings['agent-chat']?.saved_replies?.complete && activeConversation !== null
    },
    {
      anchorID: 'conferencing',
      xOffset: -51,
      yOffset: -30,
      updateFunction: () => DisplaySettings.update({ page: 'ftux', type: 'agent-chat', settings: { ...settings.ftux.settings['agent-chat'], conferencing: { complete: true } } }),
      anchors: [{
        title: 'Conferencing Link',
        body: (
          <div>
            <div>
              <span className={classes.grayText}>Quickly transition from chat to video conferecing by dropping a meeting link from Zoom, Teams, etc. into the chat. Click the button to set yours up</span>
            </div>
            <div style={{ marginTop: '15px' }}>
              <img src={ConferencingLinkGif} alt='conferencing-link-gif' width={233} />
            </div>
            <LearnMoreLink link='https://help.getsignals.ai/article/nb8udzawsa-meet-now-link' />
          </div>
        ),
        anchorID: 'conferencing',
        anchorPos: 'bottom',
        anchorOriginHorizontal: -238,
        anchorOriginVertical: -520,
        transformOriginHorizontal: 'left',
        transformOriginVertical: 'top',
        arrowOffset: '1px'
      }],
      visible: !settings?.ftux?.settings['agent-chat']?.conferencing?.complete && activeConversation !== null
    },
    {
      anchorID: 'calendar_drop',
      xOffset: -48,
      yOffset: -30,
      updateFunction: () => DisplaySettings.update({ page: 'ftux', type: 'agent-chat', settings: { ...settings.ftux.settings['agent-chat'], calendar_drop: { complete: true } } }),
      anchors: [{
        title: 'Calendar drops',
        body: (
          <div>
            <div>
              <span className={classes.grayText}>Drop a calendar into the chat for a visitor to book a meeting with you or someone else on your team. Click the button to drop your calendar into the chat</span>
            </div>
            <LearnMoreLink link='https://help.getsignals.ai/article/304lrgzdto-03-calendar-drop' />
          </div>
        ),
        anchorID: 'calendar_drop',
        anchorPos: 'bottom',
        anchorOriginHorizontal: -238,
        anchorOriginVertical: -360,
        transformOriginHorizontal: 'left',
        transformOriginVertical: 'top',
        arrowOffset: '1px',
        additionalEffect: () => setCalendarOpen(true)
      },
      {
        title: 'Agent and meeting type',
        body: (
          <div>
            <div>
              <span className={classes.grayText}>Here you'll select the teammate whose calendar will be shown, and the type of meeting you'd like to book.</span>
            </div>
            <LearnMoreLink link='https://help.getsignals.ai/article/l8sywy70ih-01-meeting-types' />
          </div>
        ),
        anchorID: 'calendar-2',
        anchorPos: 'left',
        anchorOriginHorizontal: 430,
        anchorOriginVertical: 0,
        transformOriginHorizontal: 'left',
        transformOriginVertical: 'top',
        arrowOffset: '80px',
        additionalEffect: () => setCalendarOpen(false)
      },
      {
        title: 'Shown calendars',
        body: (
          <div>
            <div>
              <span className={classes.grayText}>When a calendar is dropped, you'll be able to see the agent and meeting details in the chat. The calendar will turn green when a meeting is successfully scheduled.</span>
            </div>
            <LearnMoreLink link='https://help.getsignals.ai/article/l8sywy70ih-01-meeting-types' />
          </div>
        ),
        anchorID: 'conversation_history',
        anchorPos: 'right',
        anchorOriginHorizontal: -470,
        anchorOriginVertical: 0,
        transformOriginHorizontal: 'left',
        transformOriginVertical: 'top',
        arrowOffset: '80px'
      }],
      visible: !settings?.ftux?.settings['agent-chat']?.calendar_drop?.complete && activeConversation !== null
    },
    {
      anchorID: 'salesloft_card',
      xOffset: -60,
      yOffset: -16,
      updateFunction: () => DisplaySettings.update({ page: 'ftux', type: 'agent-chat', settings: { ...settings.ftux.settings['agent-chat'], salesloft_card: { complete: true } } }),
      anchors: [{
        title: 'Add people to Salesloft',
        body: (
          <div>
            <div>
              <span className={classes.grayText}>If you know a visitor's email address, you can quickly hand them off to Salesloft without leaving Signals.</span>
            </div>
            <div style={{ marginTop: '15px' }}>
              <img src={SFStep1} alt='sf-step-1-gif' width={233} height={407} />
            </div>
            {/* Add links for all of the salesloft prompts once we have them
             <LearnMoreLink link='https://help.getsignals.ai/' /> */}
          </div>
        ),
        anchorID: 'salesloft_card',
        anchorPos: 'right',
        anchorOriginHorizontal: -360,
        anchorOriginVertical: -20,
        transformOriginHorizontal: 'left',
        transformOriginVertical: 'top',
        arrowOffset: '1px'
      },
      {
        title: 'Salesloft Activity',
        body: (
          <div>
            <div>
              <span className={classes.grayText}>If a visitor's already in Salesloft, you'll see their cadence enrollment, plus any upcoming steps and past activity here to give you added context.</span>
            </div>
            <div style={{ marginTop: '15px' }}>
              <img src={SFStep2} alt='sf-step-2-gif' width={233} height={333} />
            </div>
            {/* <LearnMoreLink link='https://help.getsignals.ai/' /> */}
          </div>
        ),
        anchorID: 'salesloft_card',
        anchorPos: 'right',
        anchorOriginHorizontal: -360,
        anchorOriginVertical: -20,
        transformOriginHorizontal: 'left',
        transformOriginVertical: 'top',
        arrowOffset: '1px'
      },
      {
        title: 'Cadence Enrollment',
        body: (
          <div>
            <div>
              <span className={classes.grayText}>You can add people to or remove them from cadences here too.</span>
            </div>
            <div style={{ marginTop: '15px' }}>
              <img src={SFStep3} alt='sf-step-3-gif' width={233} height={177} />
            </div>
            {/* <LearnMoreLink link='https://help.getsignals.ai/' /> */}
          </div>
        ),
        anchorID: 'salesloft_card',
        anchorPos: 'right',
        anchorOriginHorizontal: -360,
        anchorOriginVertical: -20,
        transformOriginHorizontal: 'left',
        transformOriginVertical: 'top',
        arrowOffset: '1px'
      },
      {
        title: 'Create Tasks',
        body: (
          <div>
            <div>
              <span className={classes.grayText}>Or create a one-off task to remind yourself to follow-up with someone</span>
            </div>
            <div style={{ marginTop: '15px' }}>
              <img src={SFStep4} alt='sf-step-4-gif' width={233} height={184} />
            </div>
            {/* <LearnMoreLink link='https://help.getsignals.ai/' /> */}
          </div>
        ),
        anchorID: 'salesloft_card',
        anchorPos: 'right',
        anchorOriginHorizontal: -360,
        anchorOriginVertical: -20,
        transformOriginHorizontal: 'left',
        transformOriginVertical: 'top',
        arrowOffset: '1px'
      }],
      visible: !settings?.ftux?.settings['agent-chat']?.salesloft_card?.complete && activeConversation !== null
    }
  ]

  if (!isLoading && settings.ftux) {
    if (!settings.ftux.settings['agent-chat']) {
      DisplaySettings.update({ page: 'ftux', type: 'agent-chat', settings: { ...defaultState } })
    }
  } else if (!isLoading && !settings) {
    DisplaySettings.create({ page: 'ftux', type: 'agent-chat', settings: { ...defaultState } })
  }

  useEffect(() => {
    if (!isLoading && settings?.ftux?.settings['agent-chat']) {
      if (settings.ftux.settings['agent-chat'].welcome.complete) {
        setModalOpen(false)
      }
    }
  }, [settings, isLoading])

  const handleGetStarted = () => {
    setModalOpen(false)
    DisplaySettings.update({ page: 'ftux', type: 'agent-chat', settings: { ...settings.ftux.settings['agent-chat'], welcome: { complete: true } } })
  }

  return (
    <>
      {modalOpen && (
        <Dialog
          onClose={() => (setModalOpen(false))}
          open={modalOpen}
          fullWidth
          maxWidth='md'
        >
          <div className={classes.containerDiv}>
            <div className={classes.welcomeImage}>
              <div style={{ marginTop: '10px' }}>
                <img src={ModalImage} alt='welcome' />
              </div>
            </div>
            <div className={classes.welcomeTitle}>
              Welcome to agent chat!
            </div>
            <div className={classes.welcomeMessage}>
              This is where you will find conversations that visitors have started with your chatbots.
              Click on the hotspots in this example chat to learn a bout the features available.
            </div>
            <div className={classes.welcomeFooter}>
              <Button
                className={classes.welcomeButton}
                onClick={() => handleGetStarted()}
                variant='contained'
              >
                Get Started
              </Button>
            </div>
          </div>
        </Dialog>
      )}
      {!modalOpen && !otherModalOpen && promptList.map((prompt, index) => {
        return (
          <div key={index}>
            <OnboardingPrompt
              anchorID={prompt.anchorID}
              xOffset={prompt.xOffset}
              yOffset={prompt.yOffset}
              visible={prompt.visible}
              hidden={hidePrompts}
              setHidden={setHidePrompts}
            >
              <FTUX
                page='agent-chat'
                anchors={prompt.anchors}
                updateFunction={prompt.updateFunction}
                closingFunction={prompt.closingFunction || undefined}
                dim
              />
            </OnboardingPrompt>
          </div>
        )
      })}
    </>
  )
}
