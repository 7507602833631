
const descriptionText = 'Use this bot template to create a landing page allowing visitors to interact with a bot while viewing custom content. In order to work correctly this bot needs:'

const Description = (): JSX.Element => {
  return (
    <div>
      <div>
        {descriptionText}
      </div>
      <ul>
        <li>A pdf to display for the visitor</li>
        <li>A custom logo or image to display in the chat window</li>
        <li>A page title (Optional)</li>
      </ul>
    </div>
  )
}

const bestPracticeText = 'Your bot flow can be as simple or elaborate as you would like. Click on the information button in the top right of each bot builder node (when in edit mode) for tips and information regarding individual node use.'

const BestPractices = () => {
  return (
    <div>
      <div>
        {bestPracticeText}
      </div>
    </div>
  )
}

export const conversationalContentDocs = {
  helpCenterLink: '',
  bestPractices: BestPractices,
  description: Description,
  videoLink: ''
}
