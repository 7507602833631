export const revenueRanges: { label: string, value: string }[] = [
  { label: '$1 - $999.9K', value: '1_999999' },
  { label: '$1M - $4.9M', value: '1000000_4999999' },
  { label: '$5M - $9.9M', value: '5000000_9999999' },
  { label: '$10M - $24.9M', value: '10000000_24999999' },
  { label: '$25M - $49.9M', value: '25000000_49999999' },
  { label: '$50M - $99.9M', value: '50000000_99999999' },
  { label: '$100M - $249.9M', value: '100000000_249999999' },
  { label: '$250M - $499.9M', value: '250000000_499999999' },
  { label: '$500M - $999.9M', value: '500000000_999999999' },
  { label: '$1B - $2.49B', value: '1000000000_2499999999' },
  { label: '$2.5B - $4.9B', value: '2500000000_4999999999' },
  { label: '$5B+', value: '5000000000' }
]

export const fromName = (name: string) => {
  let revenue = revenueRanges[0]
  revenueRanges.forEach((r) => {
    if (r.label === name) {
      revenue = r
    }
  })
  return revenue
}

interface RevenueRangeList {
  list: RevenueRange[]
  dict: { [id: RevenueRange['id']]: RevenueRange }
}

export class RevenueRange {
  id: string
  name: string

  constructor ({ value, label }: { value: string; label: string; }) {
    this.name = label
    this.id = value
  }

  static loadAll (): { data: RevenueRangeList, isLoading: boolean } {
    const data = serialize()
    return { data: data, isLoading: false }
  }
}

const serialize = (): any => {
  const revenueList: any = []
  const revenueDict: any = {}
  revenueRanges.map(revenue => {
    revenueDict[revenue.value] = new RevenueRange(revenue)
    revenueList.push(new RevenueRange(revenue))
    return null
  })
  return { list: revenueList, dict: revenueDict }
}
