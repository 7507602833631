import { Select } from 'cf-components/material-wrappers/MaterialComponents';
import { Sequence } from 'classes/sequences';
import { TextBox } from 'library/materialUI';
import { Modal, ModalSection } from 'library/Modal';
import { useQueryClient } from 'react-query'
import { useReducer } from 'react';

const initialState = {
  name: '',
  description: '',
  template: '',
  isReusable: true
}

const options = [
  { value: 'matches_icp', label: 'Matches ICP' },
  { value: 'open_ops', label: 'Open Opportunities' },
  { value: 'current_customer', label: 'Current Customers' }
]

const reducer = (state: any, changes: any) => {
  return { ...state, ...changes }
}

export function NewPlayModal (props: any) {
  const [state, dispatch] = useReducer(reducer, initialState)
  const queryClient = useQueryClient()
  const history = props.history
  const newTab = props.newTab

  const savePlay = () => {
    const sequence = { ...state }
    Sequence.save({ sequence, objectType: 'plays' })
      .then((sequence: any) => {
        queryClient.setQueryData(['sequences', sequence.id], sequence)
        if (newTab) {
          window.open(`/#/plays/${sequence.id}`)
          props.setOpen(false)
        } else {
          history.push(`/plays/${sequence.id}`)
        }
      })
  }

  return (
    <Modal
      open={props.open}
      title='New Play'
      onHide={props.onHide}
      handleSave={savePlay}
      saveDisabled={!state.name}
    >
      <ModalSection title='Name'>
        <TextBox
          value={state.name}
          onChange={(value: string) => dispatch({ name: value })}
        />
      </ModalSection>
      <ModalSection title='Description'>
        <TextBox
          rows={2}
          value={state.description}
          onChange={(value: string) => dispatch({ description: value })}
        />
      </ModalSection>
      <ModalSection title='Template'>
        <Select
          options={options}
          value={state.template}
          onChange={(value: string) => dispatch({ template: value })}
        />
      </ModalSection>
    </Modal>
  )
}
