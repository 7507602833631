import GenericObject from './genericObject'
import { LoadAllProps } from './queryHelpers'
import { useDoQuery } from './useDoQuery'

interface ConversionActionArray {
    list: ConversionAction[]
    dict: { [id: ConversionAction['id']]: ConversionAction }
}

export class ConversionAction extends GenericObject {
    name: string
    objectType = 'conversionAction'

    static loadAll (props?: LoadAllProps): { data: ConversionActionArray, isLoading: boolean } {
        return useDoQuery({
            path: '/integrations/ads/conversions/actions',
            objectClass: ConversionAction,
            searchParams: props?.searchParams,
            extraHeaders: props?.searchParams?.extraHeaders
        })
    }

    constructor ({ row }: { row: Record<string, any> }) {
        super({ row })
        const conversionAction = row.attributes
        this.name = conversionAction.name
    }
}

interface conversionSetting {
    conversionID: string

}

export class AdSettings extends GenericObject {
    conversionMapping: {
        meetingBooked: conversionSetting
        conversation: conversionSetting
        emailCaptured: conversionSetting
    }
    objectType = 'conversionSettings'

    static load (integration: any): { data: ConversionActionArray, isLoading: boolean } {
        const extraHeaders = {
            'x-integration': integration.searchParams.search
        }
        return useDoQuery({ path: '/integrations/ads/settings', objectClass: ConversionAction, extraHeaders })
    }

    constructor ({ row }: { row: Record<string, any> }) {
        super({ row })
        const settings = row.attributes
        this.conversionMapping = settings.mapping
        this.conversionMapping = {
            meetingBooked: {
                conversionID: ''
            },
            conversation: {
                conversionID: ''
            },
            emailCaptured: {
                conversionID: ''
            }
        }
    }
}
