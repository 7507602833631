import { useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import MuiExpansionPanel from '@material-ui/core/Accordion'
import MuiExpansionPanelSummary from '@material-ui/core/AccordionSummary'
import MuiExpansionPanelDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const ExpansionPanel = withStyles({
  root: {
    boxShadow: 'none',
    paddingTop: 20,
    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&:before': {
      display: 'none'
    },
    '&$expanded': {
      margin: 'auto'
    }
  },
  expanded: {}
})(MuiExpansionPanel)

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderTop: '1px solid rgba(0, 0, 0, .125)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    fontFamily: 'Poppins, sans serif',
    fontSize: '14px',
    fontWeight: 600,
    minHeight: 40,
    height: 40,
    '&$expanded': {
      minHeight: 40,
      height: 40
    }
  },
  content: {
    '&$expanded': {
      margin: '12px 0'
    }
  },
  expanded: {}
})(MuiExpansionPanelSummary)

const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    paddingTop: 0,
    paddingBottom: 0,
    border: 0
  }
}))(MuiExpansionPanelDetails)

function CustomExpansionPanel (props) {
  const [expanded, setExpanded] = useState(true)

  return (
    <ExpansionPanel
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
    >
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        {props.title}
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        {props.children}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}

export default CustomExpansionPanel
