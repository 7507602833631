import { makeStyles } from '@material-ui/core/styles'
import { Tooltip } from '@material-ui/core'
import { parameterizeFilters, QueryFilter } from 'classes/queryHelpers'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import { useState } from 'react'
import { SummaryCompanies } from 'classes/summaryCompanies'
import SkeletonNumber from 'library/loading/skeleton/SkeletonNumber'
import { DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd'
import ActionsMenu, { ActionsMenuAction } from 'cf-components/ActionsMenu'
import { CompanyCallSummary } from 'classes/companyCallSummary'

const useStyles = makeStyles(theme => ({
  tab: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    textAlign: 'center',
    width: '220px',
    color: 'black',
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    fontFamily: 'poppins',
    fontSize: '14px',
    marginRight: '18px',
    cursor: 'pointer',
    padding: '12px 10px'
  },
  tabContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    textAlign: 'center'
  }
}))

interface DashboardTabHeaderProps {
  viewID: number
  title: string | JSX.Element
  tooltipText: string
  index: number
  filters: QueryFilter[][]
  currentTab: number
  handleClick: (tabID: number) => void
  provided: DraggableProvided
  snapshot: DraggableStateSnapshot
  objectClass: typeof SummaryCompanies | typeof CompanyCallSummary
  actions?: ActionsMenuAction[]
}

export default function DashboardTabHeader ({ viewID, title, tooltipText, index, filters, currentTab, handleClick, provided, snapshot, actions }: DashboardTabHeaderProps): JSX.Element {
  const innerID = `summaryCompanies-count-${viewID}`
  const classes = useStyles()
  // Drag states
  const [hovering, setHovering] = useState(-1)
  const [menuOpen, setMenuOpen] = useState(-1)
  const handleMouseLeave = (index: number) => {
    if (hovering === index) {
      setHovering(-1)
    }
  }
  const isHovered = (index: number): boolean => {
    return hovering === index
  }
  const isMenuOpen = (index: number): boolean => {
    return menuOpen === index
  }

  const url = parameterizeFilters({ path: `/api/core/web/companies/summaries/${viewID}/count`, searchParams: { filters } })

  return (
    <div ref={provided.innerRef} onMouseEnter={() => { setHovering(index) }} onMouseLeave={() => handleMouseLeave(index)} {...provided.draggableProps} {...provided.dragHandleProps} key={index} className={classes.tabContainer}>
      {snapshot.isDragging}
      <Tooltip key={index} title={isMenuOpen(index) || !isHovered(index) ? '' : tooltipText}>
        <div className={classes.tab} style={{ backgroundColor: viewID === currentTab ? 'white' : '#E7E7E7', borderStyle: viewID === currentTab ? 'solid' : undefined, borderColor: '#D3D3D3', borderWidth: '1px 1px 0px 1px' }} onClick={() => handleClick(viewID)}>
          <div style={{ display: isHovered(index) ? 'flex' : 'none', height: '100%', marginRight: '10px', justifyContent: 'center', alignItems: 'center', cursor: 'grab' }}>
            <DragIndicatorIcon />
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginRight: '10px',
              color: viewID === currentTab ? 'black' : 'grey',
              flexWrap: 'wrap',
              minWidth: 0
            }}
          >
            <div
              style={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap'
              }}
            >
              {title}
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 'bold' }}>
            <div
              id={innerID}
              hx-get={url}
              hx-trigger='revealed'
              hx-swap='innerHTML'
            >
              <SkeletonNumber size='sm' />
            </div>
          </div>
          {actions && (
            <div
              style={{
                display: isHovered(index) ? 'flex' : 'none',
                height: '100%',
                marginLeft: '10px',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer'
              }}
            >
              <ActionsMenu
                row={viewID}
                actions={actions}
                hover={isHovered(index)}
                iconHeight={20}
                iconWidth={20}
                onClose={() => {
                  setMenuOpen(-1)
                  setHovering(-1)
                }}
                onClick={() => setMenuOpen(index)}
                mouseLeave
              />
            </div>)}
        </div>
      </Tooltip>
    </div>
  )
}
