import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themes_material from '@amcharts/amcharts4/themes/material'

function createLineChart ({ data, id, setVisible }) {
  am4core.addLicense('CH213476655')
  am4core.useTheme(am4themes_material)
  const chart = am4core.create(id, am4charts.XYChart)
  const dateAxis = chart.xAxes.push(new am4charts.DateAxis())
  const valueAxis = chart.yAxes.push(new am4charts.ValueAxis())

  valueAxis.min = 0
  dateAxis.startLocation = 0.5
  dateAxis.endLocation = 0.5

  for (let i = 0; i < data.length; i++) {
    createSeries(data[i].name, data[i].counts)
  }

  function createSeries (name, counts) {
    const series = chart.series.push(new am4charts.LineSeries())
    series.dataFields.valueY = 'value'
    series.dataFields.dateX = 'date'
    series.name = name
    series.stacked = true
    series.fillOpacity = 0.75

    const segment = series.segments.template
    segment.strokeWidth = 3
    segment.interactionsEnabled = true

    const hoverState = segment.states.create('hover')
    hoverState.properties.strokeWidth = 3

    const dimmed = segment.states.create('dimmed')
    dimmed.properties.stroke = am4core.color('#dadada')
    dimmed.properties.strokeWidth = 1
    dimmed.properties.fillOpacity = 0.4

    const defaultState = segment.states.create('default')
    defaultState.properties.strokeWidth = 3

    segment.events.on('over', function (event) {
      processOver(event.target.parent.parent.parent)
    })

    segment.events.on('out', function (event) {
      processOut(event.target.parent.parent.parent)
    })

    series.data = counts

    return series
  }

  chart.legend = new am4charts.Legend()
  chart.legend.position = 'right'
  chart.legend.scrollable = true
  chart.legend.itemContainers.template.strokeWidth = 4
  chart.legend.itemContainers.template.opacity = 1

  const legendItem = chart.legend.itemContainers.template
  legendItem.interactionsEnabled = true

  const legendHover = legendItem.states.create('hover')
  legendHover.properties.strokeWidth = 6
  legendHover.properties.fontWeight = 'bolder'
  legendHover.properties.opacity = 1

  const legendDefault = legendItem.states.create('default')
  legendDefault.properties.strokeWidth = 4
  legendDefault.properties.fontWeight = 'normal'
  legendDefault.properties.opacity = 1

  const legendDimmed = legendItem.states.create('dimmed')
  legendDimmed.properties.strokeWidth = 1
  legendDimmed.properties.fontWeight = 'normal'
  legendDimmed.properties.opacity = 0.3

  chart.legend.itemContainers.template.events.on('over', function (event) {
    processOver(event.target.dataItem.dataContext)
  })

  chart.legend.itemContainers.template.events.on('out', function (event) {
    processOut(event.target.dataItem.dataContext)
  })

  function processOver (hoveredSeries) {
    hoveredSeries.toFront()

    hoveredSeries.segments.each(function (segment) {
      segment.setState('hover')
    })

    chart.legend.itemContainers.each(function (container) {
      container.setState('hover')
    })

    chart.legend.itemContainers.each(function (container) {
      if (container.dataItem.dataContext !== hoveredSeries) {
        container.setState('dimmed')
      }
    })

    chart.series.each(function (series) {
      if (series !== hoveredSeries) {
        series.segments.each(function (segment) {
          segment.setState('dimmed')
        })
        series.bulletsContainer.setState('dimmed')
      }
    })
  }

  function processOut (hoveredSeries) {
    chart.series.each(function (series) {
      series.segments.each(function (segment) {
        segment.setState('default')
      })
      series.bulletsContainer.setState('default')
    })

    chart.legend.itemContainers.each(function (container) {
      container.setState('default')
    })
  }

  chart.events.on('ready', () => {
    if (setVisible) {
      setVisible('visible')
    }
  })

  chart.exporting.menu = new am4core.ExportMenu()

  return chart
}

export { createLineChart }
