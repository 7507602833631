import { SectionProps } from '../HelpCenter'
import { HelpCenterCardList } from '../HelpCenterCardList'
import { SectionCenter } from '../SectionCenter'
import { playTemplateMapping } from '../TemplateMapping'

const nodeList = ['ICP', 'OpenOps', 'CurrentCustomer'] // add back in later /*, 'Retarget', 'ConversationScore', 'Events'*/

export const PlayTemplatesCenter = (props: SectionProps): JSX.Element => {
  return (
    <SectionCenter
      homeComponent={<HelpCenterCardList nodeMapping={playTemplateMapping} nodeList={nodeList} />}
      links={props.links}
      type='template'
    />
  )
}
