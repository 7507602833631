import ScraperSummary from 'classes/scraperSummary'
import { jsPlumbUtil } from 'jsplumbtoolkit'
import { Modal, ModalSection } from 'library/Modal'
import { TextBox } from 'library/materialUI'
import { useState } from 'react'

interface ScraperModalProps {
  open: boolean
  setOpen: (value: boolean) => void
}

export default function ScraperModal ({ open, setOpen }: ScraperModalProps) {
  const [url, setUrl] = useState('')

  const handleSave = () => {
    const scrapeSum = {
      url,
      scrapeID: jsPlumbUtil.uuid()
    }
    ScraperSummary.save({ data: scrapeSum })
    setOpen(false)
  }

  return (
    <Modal
      open={open}
      title='Add Websites'
      onHide={() => setOpen(false)}
      handleSave={() => handleSave()}
      saveBtnText='Save Websites'
      saveDisabled={false}
      saveIcon='add'
    >
      <ModalSection>
        <div style={{ fontSize: '15px', color: '#000000BF', fontWeight: 'bold', marginBottom: '10px' }}>
          Enter the URL of the content you want to sync
        </div>
        <div style={{ fontSize: '14px', color: '#808080', marginBottom: '10px' }}>
          Enter a top-level domain like https://example.com to sync your whole site, or specific paths like https://example.com/products to only sync specific subdirectories or pages.
        </div>
        <div>
          <TextBox
            value={url}
            onChange={(value: string) => setUrl(value)}
            label=''
            placeholder='Type a URL and hit enter...'
          />
        </div>
      </ModalSection>
    </Modal>
  )
}
