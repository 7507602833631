import { useDoQuery } from './useDoQuery'
import { doPut, doDelete } from 'api/api'
import { DateType, castToDate } from './classHelpers'
import { GenericObject } from './genericObject'
import { pick } from 'lodash'
import { LoadAllProps } from './queryHelpers'

interface PlayList {
  list: Play[]
  dict: { [id: Play['id']]: Play }
}

const startNode = {
  id: 'start',
  type: 'basic',
  kind: 'Start',
  ports: [
    { id: 'default', label: 'START HERE', filters: { prop: '', cmp: '', value: '' } }
  ]
}

const defaultPlay = {
  edges: [],
  nodes: [startNode],
  name: 'New Play',
  triggers: [],
  enabled: false
}

const dbProps = ['edges', 'nodes']

type LoadPlayProps = {
  ID: Play['id']
  draft: boolean
}

type SaveProps = {
  play: Play
  publish?: boolean
}
type SavePlayFunction = ({ play, publish }: SaveProps) => Promise<any>
export class Play extends GenericObject {
  createdTimestamp: DateType
  updatedTimestamp: DateType
  edges: any[]
  nodes: any[]
  flowID: number
  draftFlowID: number
  enabled: boolean
  objectType = 'play'

  get subtitle (): string { return '' }
  get searchField (): string { return this.name }
  get link (): string { return `/plays/${this.id}` }

  static loadAll (props?: LoadAllProps): { data: PlayList, isLoading: boolean } {
    return useDoQuery({
      useChatURL: true,
      path: '/plays',
      objectClass: Play,
      searchParams: props?.searchParams
    })
  }

  static loadOne ({ ID, draft }: LoadPlayProps): { data: Play, isLoading: boolean } {
    const path = draft ? `/plays/${ID}/draft` : `/plays/${ID}`
    return useDoQuery({ useChatURL: true, path, objectClass: Play })
  }

  static load (): { data: Play, isLoading: boolean } {
    const path = '/plays'
    return useDoQuery({ useChatURL: true, path, objectClass: Play })
  }

  update (updates: Partial<Play>): Promise<any> {
    const path = '/plays'

    const data = {
      type: 'plays',
      id: this.id,
      attributes: updates
    }
    return doPut({ path, data, useChatURL: true })
  }

  static save: SavePlayFunction = ({ play, publish }) => {
    const attributes = pick(play, dbProps)
    const data = {
      type: 'plays',
      id: play.id,
      attributes
    }

    const path = publish ? '/plays:publish' : '/plays'
    return doPut({ path, data, useChatURL: true })
  }

  delete (): Promise<any> {
    const path = `/plays/${this.id}`
    return doDelete({ path: path, useChatURL: true }).then(() => ({ path: '/play_management' }))
  }

  getFlow (): { nodes: any, edges: any } {
    const nodes = this.nodes
    const edges = this.edges
    return { nodes: [...nodes], edges: [...edges] }
  }

  constructor ({ row, state }: { row?: Record<string, any>, state?: Record<string, any> } = {}) {
    super({ row })
    const attributes = row?.attributes || state || defaultPlay
    this.name = attributes.name
    this.createdTimestamp = castToDate(attributes.created_timestamp)
    this.updatedTimestamp = castToDate(attributes.updated_timestamp)
    this.edges = attributes.edges
    this.nodes = attributes.nodes
    this.flowID = attributes.flow_id
    this.draftFlowID = attributes.draft_flow_id
    this.enabled = attributes.enabled
    this.delete = this.delete.bind(this)
  }
}
