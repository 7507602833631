import { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from 'library/materialUI/Button'
import { Link } from 'react-router-dom'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { Formik, Form } from 'formik'
import MFASetupForm from './MFASetupForm'
import { resendVerificationEmail } from 'api/auth'
import SSOButtons from './SSOButtons/SSOButtons'
import LoginTitle from './LoginTitle'
import { Paper, Typography } from 'library/materialUI'
import MobileDetect from 'mobile-detect'
import Loader from 'library/loading/Loader'

const useStyles = makeStyles(theme => ({
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  submit: {
    width: '100%',
    borderRadius: 5,
    marginTop: 25,
    marginBottom: 10,
    backgroundColor: '#9933FF',
    color: 'white'
  },
  grid: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  rightGrid: {
    display: 'flex',
    justifyContent: 'right'
  },
  errorMessage: {
    fontSize: 12,
    color: 'red',
    display: 'flex'
  },
  helperText: {
    fontSize: 12
  },
  btnDiv: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  inputField: {
    width: '100%'
  },
  signIn: {
    minWidth: 360,
    padding: 50
  },
  mobile: {
    padding: 50
  },
  links: {
    textDecoration: 'none',
    color: '#9933FF'
  },
  basicText: {
    margin: '5px 0px'
  }
}))

const VerificationEmailDiv = props => {
  const classes = useStyles()
  const [verificationEmailSent, setVerificationEmailSent] = useState(false)
  const type = props.type
  const message = props.message
  const handleSendVerification = () => {
    setVerificationEmailSent(true)
    props.handleSendVerificationEmail()
  }

  if (!message) {
    return <></>
  }

  if (type === 'verification') {
    if (verificationEmailSent) {
      return (
        <div className={classes.helperText}>
          Email verification sent. Check your mailbox!
        </div>
      )
    } else {
      return (
        <div className={classes.errorMessage}>
          Please check your email for a verification email or
          <p
            style={{
              margin: '0px 3px',
              textDecoration: 'underline',
              cursor: 'pointer'
            }}
            onClick={handleSendVerification}
          >
            click here
          </p>
          to send a new one
        </div>
      )
    }
  } else {
    return (
      <div className={classes.errorMessage}>
        {message}
      </div>
    )
  }
}

const MFAForm = props => {
  const classes = useStyles()
  return (
    <Formik
      initialValues={{ email: '', password: '' }}
      onSubmit={(values, actions) => props.onSubmit(values, actions)}
      render={({ handleChange, status, errors, setFieldValue, values }) => (
        <Form className={classes.form}>
          <Typography variant='subtitle2'>Enter the MFA code sent to you via email/text </Typography>
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            id='mfa_code'
            label='MFA Code'
            name='mfa_code'
            autoFocus
            onChange={handleChange}
            error={!!((status && status.mfa_code))}
            helperText={(status && status.mfa_code) ? status.mfa_code : undefined}
          />
          <FormControlLabel
            control={
              <Checkbox
                color='primary'
                checked={values?.remember_device || false}
                onChange={e => setFieldValue('remember_device', e.target.checked)}
              />
            }
            id='remember_device'
            name='remember_device'
            label='Remember this device for 30 days'
            classes={{ label: classes.checkboxLabel }}
          />
          <div>
            <Button
              type='submit'
              fullWidth
              variant='contained'
              color='primary'
              className={classes.submit}
            >
              Sign In
            </Button>
          </div>
          <Link to='/reset-password/' variant='body2' className={classes.links}>
            Forgot your password?
          </Link>
        </Form>
      )}
    />
  )
}

const LoginForm = props => {
  const classes = useStyles()

  return (
    <Formik
      initialValues={{ email: '', password: '' }}
      onSubmit={(values, actions) => {
        values.email = values.email.trim()
        values.password = values.password.trim()
        props.onSubmit(values, actions)
      }}
      render={({ handleChange, status, errors, values, isSubmitting }) => {
        let errorMessage = status?.password || null
        const errorType = status?.type || null

        const handleSendVerificationEmail = () => {
          resendVerificationEmail(values.email).then(response => {
            if (!response.ok) {
              console.log(response)
            }
          })
        }

        if (props.ssoErrors) {
          errorMessage = props.ssoErrors
        }

        return (
          <Form className={classes.form}>
            <TextField
              variant='outlined'
              margin='normal'
              required
              id='email'
              label='Email Address'
              name='email'
              autoComplete='email'
              autoFocus
              className={classes.inputField}
              value={values.email}
              onChange={handleChange}
            />
            <TextField
              variant='outlined'
              margin='normal'
              required
              name='password'
              label='Password'
              type='password'
              id='password'
              className={classes.inputField}
              value={values.password}
              autoComplete='current-password'
              onChange={handleChange}
            />
            <div>
              <Link to='/reset-password/' variant='body2' className={classes.links}>
                Forgot your password?
              </Link>
            </div>
            <VerificationEmailDiv
              type={errorType}
              handleSendVerificationEmail={handleSendVerificationEmail}
              message={errorMessage}
            />
            <div className={classes.btnDiv}>
              {isSubmitting ? (
                <Loader type='spinner' />
              ) : (
                <Button
                  type='submit'
                  variant='contained'
                  className={classes.submit}
                >
                  Sign In
                </Button>
              )}
            </div>
          </Form>
        )
      }}
    />
  )
}

const MultipleTenantsForm = (props) => {
  const classes = useStyles()
  return (
    <Formik
      initialValues={{ email: props.emailPassword.email, password: props.emailPassword.password, tenant_id: undefined }}
      onSubmit={(values, actions) => props.onSubmit(values, actions)}
      render={({ errors, setFieldValue, values }) => (
        <Form className={classes.form}>
          {props.tenants.map(tenant => {
            return (
              <Button
                type='submit'
                fullWidth
                variant='contained'
                color='primary'
                className={classes.submit}
                onClick={() => setFieldValue('tenant_id', tenant.id)}
                key={tenant.id}
              >
                {tenant.name}
              </Button>
            )
          })}
        </Form>
      )}
    />
  )
}

const ActiveSessionWarning = (props) => {
  const classes = useStyles()
  return (
    <Formik
      initialValues={{ email: props.emailPassword.email, password: props.emailPassword.password, ignoreSession: false }}
      onSubmit={(values, actions) => props.onSubmit(values, actions)}
      render={({ errors, setFieldValue, values }) => (
        <Form className={classes.form}>
          <Typography className={classes.basicText} variant='body1'>Another user from your organization currently has an active session.</Typography>
          <Typography className={classes.basicText} variant='body1'>Logging in will end their session, would you like to continue?</Typography>
          <Button
            type='submit'
            fullWidth
            variant='contained'
            color='primary'
            className={classes.submit}
            onClick={() => setFieldValue('ignoreSession', true)}
          >
            Continue to login
          </Button>
        </Form>
      )}
    />
  )
}

const LoginSideBar = props => {
  const classes = useStyles()
  const md = new MobileDetect(window.navigator.userAgent)
  const mobile = !!md.phone()
  let container = (
    <>
      <LoginForm onSubmit={props.onSubmit} ssoErrors={props.ssoErrors} />
      <SSOButtons />
    </>
  )

  if (props.multipleTenants.length > 0) {
    container = (
      <MultipleTenantsForm onSubmit={props.onSubmit} tenants={props.multipleTenants} emailPassword={props.emailPassword} />
    )
  } else if (props.mfaSetup) {
    container = (
      <MFASetupForm onSubmit={props.onLoginMFASubmit} mfaCoupon={props.mfaCoupon} />
    )
  } else if (props.mfaCoupon) {
    container = (
      <MFAForm onSubmit={props.onMFASubmit} />
    )
  } else if (props.activeSession) {
    container = (
      <ActiveSessionWarning onSubmit={props.onSubmit} emailPassword={props.emailPassword} />
    )
  }

  return (
    <Paper>
      <div className={mobile ? classes.mobile : classes.signIn}>
        <LoginTitle
          multiTenantLength={props.multipleTenants.length}
          tenantBundle={props.tenantBundle.l}
        />
        {container}
      </div>
    </Paper>
  )
}

export default LoginSideBar
