import { useState, useMemo, useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import TextField, { TextFieldProps } from '@material-ui/core/TextField'
import Chip from '@material-ui/core/Chip'
import Popper from '@material-ui/core/Popper'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { useDebounce } from 'use-debounce'
import { useQuery } from 'react-query'
import { jobTitleSearch } from 'api/buying_committee'
import StaticChip from 'library/StaticChip'
import { Tooltip } from 'library/materialUI'

const useStyles = makeStyles(theme => ({
  chip: {
    marginRight: 4,
    marginTop: 4,
    marginBottom: 2
  },
  textField: {
    paddingLeft: '14px!important',
    minHeight: 50
  }
}))

export default function JobTitleSelector ({ values, setValues, title, placeholder, isExcluded }: {
  values: string[]
  setValues: (values: string[]) => void
  title?: string
  placeholder?: string
  isExcluded?: boolean
}): JSX.Element {
  const classes = useStyles()
  const [search, setSearch] = useState('')
  const [debouncedSearch] = useDebounce(search, 500)
  const { data: autoCompleteTitles = [] } = useQuery(['jobTitle', 'search', debouncedSearch], ({
    queryKey
  }) => jobTitleSearch(queryKey[2]).then(res => res.data), {
    retry: false,
    keepPreviousData: true
  })

  const cleanedAutoCompleteTitles = useMemo(() => autoCompleteTitles.map(v => v.attributes.name), [autoCompleteTitles])

  const trimmedSearch = useMemo(() => search.trim(), [search])

  const options = useMemo(() => {
    if (trimmedSearch) {
      return [...new Set([trimmedSearch, ...cleanedAutoCompleteTitles])]
    } else {
      return cleanedAutoCompleteTitles
    }
  }, [cleanedAutoCompleteTitles, trimmedSearch])

  const remapEnterKey = useCallback<Required<TextFieldProps>['onKeyDown']>(event => {
    if (event.key === 'Enter') {
      if (trimmedSearch) {
        const cleanedSearch = trimmedSearch.toLowerCase()
        if (!values.includes(cleanedSearch)) {
          setValues([...values, trimmedSearch])
        }
      }
    }
  }, [values, setValues, trimmedSearch])

  return (
    <FormControl
      fullWidth
      margin='dense'
      variant='outlined'
    >
      <Autocomplete
        multiple
        onChange={(_, newValue) => setValues(newValue)}
        value={values}
        onInputChange={(_, newInputValue) => setSearch(newInputValue)}
        filterSelectedOptions
        options={options}
        limitTags={10}
        placeholder={placeholder || 'All Titles'}
        PopperComponent={PopperTopPlacement}
        classes={{
          inputRoot: classes.textField
        }}
        renderTags={values => {
          if (!values.length) {
            return <em>All Titles</em>
          }
          return (
              values.map(v => {
                return isExcluded ? (
                  <StaticChip
                    chipColor='#FDEDEC'
                    closeColor='#EA422A'
                    fontColor='#EA422A'
                    key={v}
                    value={v}
                    variant='solid'
                    canClose
                    onDelete={() => {
                      const newValues = new Set(values)
                      newValues.delete(v)
                      setValues([...newValues])
                    }}
                  />
                ) : (
                  <Tooltip title={v.length > 25 ? v : ''}>
                    <Chip
                      color='default'
                      className={classes.chip}
                      key={v}
                      label={v.length > 25 ? v.substring(0, 23) + '...' : v}
                      onDelete={() => {
                        const newValues = new Set(values)
                        newValues.delete(v)
                        setValues([...newValues])
                      }}
                    />
                  </Tooltip>
                )
              })
          )
        }}
        renderInput={params => (
          <TextField
            {...params}
            variant='outlined'
            margin='dense'
            label={title || 'Job Titles'}
            placeholder={placeholder || 'Job Titles'}
            InputLabelProps={{ shrink: true }}
            onKeyPress={remapEnterKey}
          />
        )}
      />
    </FormControl>
  )
}

const PopperTopPlacement = (props: any): JSX.Element => (
  <Popper
    {...props}
    placement='top'
  />
)
