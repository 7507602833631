import { useState, useContext, useEffect } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Chip from '@material-ui/core/Chip'
import Tooltip from '@material-ui/core/Tooltip'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import CloseIcon from '@material-ui/icons/Close'
import SearchIcon from '@material-ui/icons/Search'
import AddIcon from '@material-ui/icons/Add'
import ReactDOM from 'react-dom'
import { useQueryClient } from 'react-query'
import { useDebounce } from 'use-debounce'
import { patchConversation } from 'api/conversations'
import { getTags, saveTag } from 'api/tags'
import { SessionContext } from 'session-context'
import { ConversationContext } from '../../ConversationContext'
import { TenantDataContext } from '../../tenant-data-context'
import EmptyState from 'cf-components/EmptyState'
import { ScrollBox } from 'cf-components/ScrollBox'
import CustomizedSnackbar from 'components/CustomizedSnackbar'
import BaseCard from './BaseCard'
import ConversationTagsModal from 'pages/settings/Tags/ConversationTagsModal'

const useStyles = makeStyles(theme => ({
  tagChip: {
    margin: 1,
    maxWidth: '100%',
    backgroundColor: '#F0E3FF',
    color: theme.palette.primary.main,
    '& > span': {
      paddingRight: 8
    },
    '& > svg': {
      color: 'inherit',
      width: 16,
      height: 16
    }
  },
  chipListCtn: {
    maxHeight: 120,
    overflowY: 'auto'
  },
  chipListChip: {
    cursor: 'pointer',
    overflow: 'hidden',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    gap: '4px',
    borderRadius: 4,
    marginBottom: 2,
    color: '#3B3B3B',
    fontSize: 12,
    transition: theme.transitions.create('background-color', {
      duration: theme.transitions.duration.shortest
    }),
    '&:last-child': {
      marginBottom: 0
    },
    '&:hover': {
      backgroundColor: 'rgb(151 92 230 / 18%)'
    },
    '& > div': {
      whiteSpace: 'nowrap',
      maxWidth: 'calc(100% - 28px)',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    '& > svg': {
      width: 18,
      height: 18
    }
  },
  inputRoot: {
    borderRadius: 30,
    '&.MuiOutlinedInput-adornedEnd': {
      paddingRight: 10
    }
  },
  inputMargin: {
    paddingTop: 8,
    paddingBottom: 8
  }
}))

/**
 * @template {any} T
 * @typedef {[T, React.Dispatch<React.SetStateAction<T>>]} useStateType
 */

export default function TagsCard (props) {
  const classes = useStyles()
  const queryClient = useQueryClient()
  const { user } = useContext(SessionContext)
  const chatServiceUrl = user?.links.chat_service
  const { conversation } = useContext(ConversationContext)
  const { tags, tagDict, loadingTags } = useContext(TenantDataContext)
  const conversationID = conversation.id
  const [currentTags, setCurrentTags] = useState({})
  const currentTagEntries = Object.entries(currentTags)
  const [search, setSearch] = useState('')
  const [debouncedSearch] = useDebounce(search.toLowerCase(), 500)
  const [tagList, setTagList] = useState(tags)
  const [snackState, setSnackState] = useState({ open: false, message: '', err: '' })
  /** @type {useStateType<{ tag_name: string; description: string; is_goal: boolean; }>} */
  const [modalState, setModalState] = useState({ tag_name: search, description: '', is_goal: false })
  const [modalShow, setModalShow] = useState(false)

  useEffect(() => {
    if (!loadingTags) {
      const newTagList = tags.filter(tag => {
        // Check if tag is already selected for this conversation
        if (currentTags[tag.id]) {
          return false
        }
        // Check if a tag is deleted
        if (tag.attributes.deleted_timestamp) {
          return false
        }
        // Check if tag name is in search bar
        if (debouncedSearch) {
          return tag.attributes.tag_name.toLowerCase().includes(debouncedSearch)
        }
        return true
      }).sort((a, b) => {
        if (a.attributes.tag_name < b.attributes.tag_name) {
          return -1
        }
        if (a.attributes.tag_name > b.attributes.tag_name) {
          return 1
        }
        return 0
      })
      setTagList(newTagList)
    }
  }, [currentTags, tags, debouncedSearch, loadingTags])

  useEffect(() => {
    if (conversationID && !loadingTags) {
      getTags({ chatServiceUrl, conversationID }).then(tags => {
        if (tags && tags.data) {
          const dict = {}
          for (const t of tags.data) {
            dict[t.id] = t.attributes
          }
          setCurrentTags(dict)
        }
      })
    }
  }, [conversationID, chatServiceUrl, loadingTags])

  const handleRemoveTag = id => {
    const newCurrentTags = { ...currentTags }
    delete newCurrentTags[id]
    setCurrentTags(newCurrentTags)

    const data = {
      relationships: {
        tags: Object.keys(newCurrentTags).map(id => ({
          id: parseInt(id),
          type: 'tags'
        }))
      }
    }
    patchConversation({ conversationID, data })
  }

  const handleAddTag = id => {
    const newCurrentTags = {
      ...currentTags,
      [id]: tagDict[id]
    }
    setCurrentTags(newCurrentTags)

    const data = {
      relationships: {
        tags: currentTagEntries.map(([id]) => ({
          id: parseInt(id),
          type: 'tags'
        })).concat({
          id,
          type: 'tags'
        })
      }
    }
    patchConversation({ conversationID, data })
  }

  const handleSaveTag = (tag_name, description, is_goal, id = null) => {
    saveTag({ tag_name, description, is_goal, id, chatServiceUrl }).then(res => {
      if (res.errors) {
        const newSnackState = { open: true, message: 'There was an error while trying to save your settings', err: '' }
        if (res.errors?.length && res.errors[0].detail) {
          newSnackState.err = res.errors[0].detail
        }
        setSnackState(newSnackState)
      } else {
        queryClient.invalidateQueries('tags')
        setModalShow(false)
        setSnackState({
          open: true,
          variant: 'success',
          message: 'Tag created'
        })
      }
    })
  }

  const EndAdornment = (user.attributes.perms?.manager && search.length) ? (
    <Tooltip title='Create new tag'>
      <InputAdornment
        position='end' style={{ cursor: 'pointer' }}
        onClick={() => ReactDOM.unstable_batchedUpdates(() => {
          setModalState({ tag_name: search, description: '', is_goal: false })
          setModalShow(true)
        })}
      >
        <AddIcon color='primary' />
      </InputAdornment>
    </Tooltip>
  ) : (
    <InputAdornment position='end'>
      <SearchIcon color='primary' />
    </InputAdornment>
  )
  if (loadingTags || !tagList) {
    return <></>
  }

  return (
    <>
      <BaseCard
        title='Conversation Tags'
        id={props.id}
      >
        <div>
          {currentTagEntries.map(([id, attributes]) => (
            <TagChip
              key={id}
              tag={{ attributes, id: parseInt(id) }}
              onDelete={handleRemoveTag}
            />
          ))}
        </div>
        <div style={{ margin: '4px 0' }}>
          <TextField
            value={search}
            fullWidth
            onChange={e => setSearch(e.target.value)}
            autoFocus={props.autoFocus}
            margin='dense'
            variant='outlined'
            InputProps={{
              endAdornment: EndAdornment,
              classes: {
                root: classes.inputRoot,
                inputMarginDense: classes.inputMargin
              }
            }}
            style={{ margin: '0 5' }}
          />
        </div>
        <ScrollBox className={classes.chipListCtn}>
          {tagList.length ? (
            tagList.map(tag => (
              <div
                key={tag.id}
                className={classes.chipListChip}
                onClick={() => handleAddTag(tag.id)}
              >
                <AddCircleIcon
                  fontSize='small'
                  color='primary'
                />
                <div>{tag.attributes.tag_name}</div>
              </div>
            ))
          ) : (
            <div className={classes.noTagsFoundBottom}>
              <EmptyState
                sad
                message='No Tags Found'
              />
            </div>
          )}
        </ScrollBox>
      </BaseCard>
      <ConversationTagsModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        state={modalState}
        onChange={setModalState}
        saveTag={handleSaveTag}
      />
      <CustomizedSnackbar state={snackState} handler={setSnackState} />
    </>
  )
}

const TagTooltip = withStyles(theme => ({
  tooltip: {
    '& > div:first-child': {
      fontWeight: 'bold'
    },
    '& > div:last-child': {
      fontStyle: 'italic'
    }
  }
}))(Tooltip)

/**
 * @param {{tag: any; onDelete: (tagId: number) => void;}} props
 */
function TagChip (props) {
  const classes = useStyles()
  const tag = props.tag

  return (
    <TagTooltip
      title={
        <>
          <div>{tag.attributes.tag_name}</div>
          <div>{tag.attributes.description}</div>
        </>
      }
    >
      <Chip
        className={classes.tagChip}
        label={tag.attributes.tag_name}
        onDelete={() => props.onDelete(tag.id)}
        deleteIcon={<CloseIcon />}
      />
    </TagTooltip>
  )
}
