import { useDoQuery } from './useDoQuery'
import { GenericObject } from './genericObject'
import { doDelete, doPut, doPost } from 'api/api'
import { LoadAllProps, SearchParams } from './queryHelpers'

interface FilterViewList {
  list: FilterView[]
  dict: {[id: FilterView['id']]: FilterView}
}

export default class FilterView extends GenericObject {
  name: string
  tenantId: number
  createdByUserId: number
  searchParams: SearchParams
  description: string
  objectType = 'Filter View'
  // excludeFilters: Record<string, any>

  static loadOne (id: number): { data: FilterView, isLoading: boolean } {
    return useDoQuery({ path: `/filter_views/${id}`, useChatURL: true, objectClass: FilterView })
  }

  static loadAll (loadAllProps?: LoadAllProps): { data: FilterViewList, isLoading: boolean } {
    return useDoQuery({
      useChatURL: true,
      path: '/filter_views',
      objectClass: FilterView,
      searchParams: loadAllProps?.searchParams
    })
  }

  static saveView ({ data } : {data: FilterView}) : Promise<any> {
    let path = '/filter_views'
    const body = {
      attributes: {
        ...data,
        search_params: {
          page: {
            size: data.searchParams.pageSize,
            number: data.searchParams.pageNumber
          },
          filters: data.searchParams.filters,
          sorts: [
            {
              field: data.searchParams.sortColumn,
              asc: data.searchParams.sortOrder === 'asc'
            }
          ]
        }
      }
    }
    if (data?.id) {
      path += `/${data.id}`
      return doPut({ path: path, data: body, useChatURL: true })
    } else {
      return doPost({ path: path, data: body, useChatURL: true })
    }
  }

  delete () {
    const path = `/filter_views/${this.id}`
    return doDelete({ path: path, useChatURL: true })
  }

  copy () {
    return new FilterView({
      row: {
        attributes: {
          name: `Copy of ${this.name}`,
          description: this.description,
          tenant_id: this.tenantId,
          created_by_user_id: this.createdByUserId,
          search_params: {
            page: {
              size: this.searchParams.pageSize,
              number: this.searchParams.pageNumber
            },
            filters: this.searchParams.filters,
            sorts: [
              {
                field: this.searchParams.sortColumn,
                asc: this.searchParams.sortOrder === 'asc'
              }
            ]
          }
        }
      }
    })
  }

  constructor ({ row }: { row?: Record<string, any> } = {}) {
    super({ row })
    const view = row?.attributes
    this.name = view.name
    this.tenantId = view.tenant_id
    this.createdByUserId = view.created_by_user_id
    this.searchParams = {
      pageSize: view.search_params?.page?.size,
      pageNumber: view.search_params?.page?.number,
      filters: view.search_params?.filters,
      sortColumn: view.search_params?.sorts[0]?.field,
      sortOrder: view.search_params?.sorts[0]?.asc ? 'asc' : 'desc'
    }
    this.description = view.description || ''
    // this.searchParams = view.filters
    // this.excludeFilters = view.exclude_filters
  }
}
