import { makeStyles } from '@material-ui/core/styles'
import { Card } from 'cf-components/material-wrappers/Card'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { Typography } from '@material-ui/core'
import ErrorIcon from '@material-ui/icons/Error'
import CancelIcon from '@material-ui/icons/Cancel'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'

const useStyles = makeStyles(theme => ({
  outer: {
    marginTop: 15,
    marginBottom: 15
  },
  icon: {
    display: 'flex',
    float: 'left',
    marginTop: 1
  },
  message: {
    paddingLeft: 30,
    margin: 5
  },
  popout: {
    float: 'right',
    cursor: 'pointer'
  }
}))

type variant = 'pass' | 'fail' | 'warning'

type CardProps = {
  variant: variant
  title: string
  message?: string
  link?: string
}

function StatusCard (props: CardProps) {
  const classes = useStyles()

  return (
    <div className={classes.outer}>
      <Card
        variant={props.variant}
        contents={
          <>
            <div className={classes.icon}>
              {props.variant === 'pass' &&
                <CheckCircleIcon className={classes.icon} style={{ fill: 'rgb(85,171,88)', height: '19px' }} />}
              {props.variant === 'fail' &&
                <CancelIcon className={classes.icon} style={{ fill: 'rgb(219, 46, 46)', height: '19px' }} />}
              {props.variant === 'warning' &&
                <ErrorIcon className={classes.icon} style={{ fill: 'rgba(255, 188, 3)', height: '19px' }} />}

            </div>
            <div><b>{props.title}</b>
              {props.link &&
                <div className={classes.popout}>
                  <a href={props.link} target='_blank' rel='noreferrer'>
                    <OpenInNewIcon className={classes.icon} style={{ height: '19px' }} />
                  </a>
                </div>}
            </div>
            <div className={classes.message}>
              {props.message ? (<Typography>{props.message}</Typography>) : ''}
            </div>
          </>
        }
      />
    </div>
  )
}

export default StatusCard
