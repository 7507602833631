import { makeStyles } from '@material-ui/core/styles'
import { Icon } from './materialUI'
import React from 'react'

const useStyles = makeStyles(theme => ({
  categorySuggestion: {
    padding: '14px',
    display: 'flex',
    height: 'auto | 40px',
    verticalAlign: 'center',
    width: '100%',
    background: `${theme.palette.background.lightPurple}`,
    border: `1px solid ${theme.palette.primary.alt}`,
    borderRadius: '5px',
    marginBottom: 10,
    alignItems: 'center',
    fontFamily: 'poppins'
  },
  categorySuggestionText: {
    textAlign: 'left',
    paddingLeft: 10,
    font: 'normal normal normal 12px/18px Poppins'
  },
  categorySuggestionURL: {
    textAlign: 'left',
    lineHeight: '50px',
    font: 'normal normal normal 12px/18px Poppins'
  },
  suggestionSelect: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    marginRight: '10px'
  },
  suggestionCheck: {
    color: `${theme.palette.success.main}`,
    marginRight: 10,
    '&:hover': {
      cursor: 'pointer'
    }
  },
  suggestionClose: {
    color: '#505050',
    marginRight: 10,
    '&:hover': {
      cursor: 'pointer'
    }
  }
}))

interface InputSuggestionProps {
  padding?: string
  body?: JSX.Element
  onIgnore?: () => void
  onAccept?: () => void
}

export default function InputSuggestion (props: InputSuggestionProps): JSX.Element {
  const classes = useStyles()
  const style = props.padding ? { padding: props.padding } : {}
  return (
    <>
      <div
        className={classes.categorySuggestion}
        style={style}
      >
        <div>
          {props.body}
        </div>
        <div className={classes.suggestionSelect}>
          <div className={classes.suggestionCheck}>
            <Icon icon='check' onClick={props.onAccept} />
          </div>
          <div className={classes.suggestionClose}>
            <Icon icon='close' onClick={props.onIgnore} />
          </div>
        </div>
      </div>
    </>
  )
}
