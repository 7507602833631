import { Tooltip } from '@material-ui/core'
import { Button, Icon } from 'library/materialUI'
import { useState } from 'react'
import { makeStyles } from '@material-ui/styles'
interface CopyIconButtonProps {
  content: string
  disabled: boolean
}

const useStyles = makeStyles((theme) => ({
  startIcon: {
    marginRight: 0,
    color: 'white',
    '& *>path': {
      fill: '#ffffff'
    },
    '& > svg': {
      viewBox: '0 0 15 17',
      width: 20,
      height: 20
    },
    padding: 5

  },
  label: {
    marginLeft: 2
  },
  root: {
    minWidth: 0,
    padding: 5
  }
}))

export function CopyIconButton ({ content, disabled }: CopyIconButtonProps): JSX.Element {
  const copy = 'Copy'
  const copied = 'Copied to clipboard'
  const [copyLabel, setCopyText] = useState(copy)
  const classes = useStyles()
  const copyText = (content: any) => {
    navigator.clipboard.writeText(content)
    setCopyText(copied)
    setTimeout(() => setCopyText(copy), 1500)
  }

  return (
    <Tooltip title={copyLabel} placement='bottom'>
      <Button
        color='primary'
        variant='contained'
        onClick={() => copyText(content)}
        startIcon={<Icon icon='copy' />}
        style={{ cursor: 'pointer', height: 40 }}
        classes={{
          startIcon: classes.startIcon,
          label: classes.label,
          root: classes.root
        }}
        disabled={disabled}
      />
    </Tooltip>
    )
}
