import React, { useEffect, useState, useRef } from 'react'
import DashboardCard from './DashboardCard'
import BounceLoader from 'react-spinners/RiseLoader'
import theme from 'theme'
import { createTimelineChart } from './createTimelineChart'
import { getAccountSummaryTimeline } from 'api/account_management'

const loaderStyles = {
  inlineSize: 'max-content',
  marginLeft: 'auto',
  marginRight: 'auto',
  height: 400,
  iconSize: 30,
  alignItems: 'center'
}

function SummaryTimeline (props) {
  const chartRef = useRef(null)
  const data = props.data
  const [visibility, setVisibility] = useState('hidden')

  useEffect(() => {
    if (data) {
      setVisibility('hidden')
      const id = props.id

      if (chartRef.current) {
        chartRef.current.data = data
        setVisibility('visible')
      } else {
        const chart = createTimelineChart({
          data,
          id,
          setVisibility
        })
        chartRef.current = chart
      }
    }
  }, [data, props.id])

  return (
    <>
      <div
        style={{
          display: visibility === 'visible' ? 'none' : 'flex',
          ...loaderStyles
        }}
      >
        <BounceLoader
          color={theme.palette.primary.main}
        />
      </div>
      <div style={{ visibility: visibility, height: 400 }}>
        <div id={props.id} style={{ width: '100%', height: '100%' }} />
      </div>
    </>
  )
}

function SummaryTimelineCard () {
  const [chartData, setChartData] = useState(null)

  useEffect(() => {
    getAccountSummaryTimeline()
      .then(response => setChartData(response.data.attributes.chart_data))
  }, [])

  return (
    <DashboardCard
      title='Summary Timeline - Unique Companies on Site'
      noScrollbox
    >
      <div style={{ height: 400 }}>
        <SummaryTimeline
          data={chartData}
          id='account-summary-timeline'
        />
      </div>
    </DashboardCard>
  )
}

export default SummaryTimelineCard
