
const descriptionText = 'With the FAQ functionality, your Signals administrator can create an answer bank for common questions web visitors might ask.'

const Description = (): JSX.Element => {
  return (
    <div>
      <div>
        {descriptionText}
      </div>
      {/* <ul>
        <li>whatever we want</li>
        <li>to put in a list</li>
      </ul> */}
    </div>
  )
}

const bestPracticeText = 'Some things to keep in mind while using this node:'

const BestPractices = () => {
  return (
    <div>
      <div>
        {bestPracticeText}
      </div>
      <ul>
        <li>If there are only 3-5 common questions web visitors seek answers for, consider using a Question node with buttons instead.</li>
        <li>We strongly recommend that you not use an FAQ node where web visitors would ask questions that are uncommon or not routine.</li>
        <li>Use your <a href='https://help.getsignals.ai/article/zgop9p4hnp-03-missed-topics' target='_blank' rel='noreferrer'>FAQ missed topic dashboard</a> and your <a href='https://help.getsignals.ai/article/smyt0ibdby-02-dashboard' target='_blank' rel='noreferrer'>FAQ dashboard</a> to review and improve your FAQ node's performance</li>
      </ul>
    </div>
  )
}

export const FAQDocs = {
  helpCenterLink: 'https://help.getsignals.ai/article/xoj9ozntma-faq-node',
  bestPractices: BestPractices,
  description: Description,
  videoLink: ''
}
