import { makeStyles } from '@material-ui/core/styles'
import { TextField } from '@material-ui/core'
import { Button, Icon } from 'library/materialUI'
import { FilterSelector } from './CriteriaPicker'

const useStyles = makeStyles(theme => ({
  input: {
    fontSize: '15px'
  },
  filterGroup: {
    backgroundColor: '#F8F8F8',
    padding: '5px 15px',
    borderRadius: 5,
    marginBottom: 10,
    display: 'flex',
    justifyContent: 'space-between'
  },
  orText: {
    color: theme.palette.primary.main,
    paddingLeft: 10,
    marginBottom: -5
  }
}))

export function BranchingGroup (props: any) {
  const classes = useStyles()
  const port = props.port

  if (!port.filters) {
    return (
      <></>
    )
  }

  const showOrButton = props.branchType !== 'url'

  const groupCount = port.filters.length

  return (
    <div className={classes.filterGroup}>
      <div style={{ width: '100%' }}>
        <div style={{ width: 220, marginBottom: 5, fontSize: '15px' }}>
          <div style={{ width: '80%', float: 'left' }}>
            <TextField
              value={port.label}
              onChange={(event: any) => props.editLabel(port.id, event.target.value)}
              onClick={(event: any) => { if (event.target.value === 'Branch Label') { props.editLabel(port.id, '') } }}
              onBlur={(event: any) => { if (event.target.value === '') { props.editLabel(port.id, 'Branch Label') } }}
              placeholder='Branch Label'
              InputProps={{ classes: { input: classes.input } }}
            />
          </div>
        </div>
        <div style={{ clear: 'both' }}>
          {port.filters.map((group: any, groupIndex: number) => {
            const addNewFilter = (filterProp: string) => {
              props.addFilter(port.id, filterProp, groupIndex)
            }

            const editFilter = (index: number, cmp: string, value: any, label: string) => {
              props.editFilter(port.id, index, cmp, value, groupIndex, label)
            }

            const deleteFilter = (index: number, filterOptions: any[]) => {
              props.deleteFilter(port.id, index, groupIndex, filterOptions)
            }

            const removeOrGroup = () => props.removeOrGroup(groupIndex)

            const showOrDivider = Boolean(groupIndex < groupCount - 1)

            return (
              <div key={groupIndex}>
                <FilterSelector
                  filters={group}
                  addNewFilter={addNewFilter}
                  editFilter={editFilter}
                  deleteFilter={deleteFilter}
                  removeOrGroup={removeOrGroup}
                  groupIndex={groupIndex}
                  branchType={props.branchType}
                  setCreateModalOpen={props.setCreateModalOpen}
                  oneFilter={port.filters.length === 1}
                />
                {showOrDivider && <div className={classes.orText}>OR</div>}
              </div>
            )
          })}
        </div>
        {showOrButton &&
          <div style={{ marginTop: 7 }}>
            <Button
              variant='text'
              startIcon={<Icon icon='add' />}
              color='primary'
              size='small'
              onClick={props.addOrGroup}
              disabled={!port.filters[port.filters?.length - 1]?.length}
              style={{ textTransform: 'none' }}
            >
              Add "OR" condition
            </Button>
          </div>}
      </div>
      <div style={{ margin: 'auto' }}>
        {props.first ? <div style={{ height: 30 }} />
          : (
            <div onClick={() => props.movePort('up')}>
              <Icon icon='upArrow' color='alt' cursor='pointer' />
            </div>)}
        <div onClick={props.removePort}>
          <Icon icon='outlinedCancel' color='alt' cursor='pointer' />
        </div>
        {props.last ? <div style={{ height: 30 }} />
          : (
            <div onClick={() => props.movePort('down')}>
              <Icon icon='downArrow' color='alt' cursor='pointer' />
            </div>)}
      </div>
    </div>
  )
}
