import { doPost, doDelete, doPut, doPatch, doGet } from '../api'
const baseURL = '/api/admin_portal/feature_announcements'

/**
 * @typedef {Object} AttributesStampsFA
 * @property {string} title
 * @property {string} body
 * @property {string} action_link
 * @property {string} link_label
 * @property {{ kind: string; values: any[] }[]} user_filters
 * @property {Date} published_timestamp
 * @property {Date} created_timestamp
 * @property {Date} updated_timestamp
 * @property {Date} deleted_timestamp
 */
/**
 * @typedef {Omit<AttributesStampsFA, 'published_timestamp'|'created_timestamp'|'updated_timestamp'|'deleted_timestamp'>} AttributesFA
 */
/**
 * @template {AttributesFA} T
 * @typedef {Object} FeatureAnnouncement
 * @property {number} id
 * @property {T} attributes
 */

/**
 * @returns {Promise<{
 * data?: FeatureAnnouncement<AttributesStampsFA>[];
 * ok?: boolean;
 * errors?: any[];
 * meta?: { page: { total: number; }; };
 * }>}
 */
export const getFeatureAnnouncements = ({ sortBy = 'created_timestamp', pageSize, pageNum, includeDeleted, sortAscending }) => {
  const path = '/api/admin_portal/feature_announcements'
  const params = new URLSearchParams()

  if (sortBy) {
    if (sortAscending) {
      params.set('sort', sortBy)
    } else {
      params.set('sort', '-' + sortBy)
    }
  }
  if (pageSize) {
    params.set('page[size]', pageSize)
  }
  if (pageNum) {
    params.set('page[number]', pageNum)
  }
  if (pageNum) {
    params.set('page[number]', pageNum)
  }
  if (includeDeleted) {
    params.set('filter[include_deleted]', true)
  }

  return doGet({
    path: path + '?' + params.toString()
  })
}

/**
 * @param {{
 * id?: number;
 * attributes: Partial<AttributesFA>;
 * }} featureAnnouncement
 * @returns {Promise<{ ok?: boolean; data?: FeatureAnnouncement<AttributesStampsFA>; }>}
 */
export const upsertFeatureAnnouncement = featureAnnouncement => {
  const payload = {
    path: baseURL,
    data: featureAnnouncement
  }
  if (!featureAnnouncement.id) {
    return doPost(payload)
  } else {
    return doPatch(payload)
  }
}

/**
 * @param {FeatureAnnouncement<any>['id']} featureAnnouncementID
 */
export const publishFeatureAnnouncement = featureAnnouncementID => {
  return doPut({
    path: `${baseURL}/${featureAnnouncementID}/publish`
  })
}

/**
 * @param {FeatureAnnouncement<any>['id']} featureAnnouncementID
 * @returns {Promise<{ ok: boolean; data: { id: FeatureAnnouncement<any>['id'] } }>}
 */
export const deleteFeatureAnnouncement = featureAnnouncementID => {
  return doDelete({
    path: `${baseURL}/${featureAnnouncementID}`
  })
}

/**
 * @param {FeatureAnnouncement<any>['id']} featureAnnouncementID
 * @returns {Promise<{ ok: boolean; data: { id: FeatureAnnouncement<any>['id'] } }>}
 */
export const revokeFeatureAnnouncement = featureAnnouncementID => {
  return doPut({
    path: `${baseURL}/${featureAnnouncementID}/revoke`
  })
}
