import { useDoQuery } from './useDoQuery'
import { doDelete, doPatch, doPost, doPut } from 'api/api'
import GenericObject from './genericObject'
import { pick } from 'lodash'
import { LoadAllProps } from './queryHelpers'

interface SequenceList {
  list: Sequence[]
  dict: { [id: Sequence['id']]: Sequence }
}

const dbProps = ['name', 'description', 'edges', 'nodes', 'ports', 'isReusable', 'template']

export class Sequence extends GenericObject {
  name: string
  objectType = 'play'
  isReusable: boolean
  description: string
  template?: string
  edges: any[]
  nodes: any[]
  ports: any[]
  draftID: number
  publishedID: number
  isActive: boolean

  get subtitle (): string { return this.description }
  get link (): string { return `/plays/${this.id}` }

  static loadAll (props?: LoadAllProps): { data: SequenceList, isLoading: boolean } {
    return useDoQuery({
      useChatURL: true,
      path: '/v2/sequences',
      objectClass: Sequence,
      searchParams: props?.searchParams
    })
  }

  static loadOne (ID: Sequence['id']): { data: Sequence, isLoading: boolean, isError: boolean, error: any } {
    return useDoQuery({ useChatURL: true, path: `/v2/sequences/${ID}`, objectClass: Sequence })
  }

  static save: any = ({ sequence, publish, objectType }: any) => {
    const attributes = pick(sequence, dbProps)
    const data = {
      type: 'sequences',
      id: sequence.id,
      attributes: { ...attributes, objectType }
    }
    if (sequence.id && sequence.isReusable) {
      const path = publish ? `/v2/sequences/${sequence.id}:publish` : `/v2/sequences/${sequence.id}`
      return doPut({ path, data, useChatURL: true }).then(response => { return new Sequence({ row: response.data }) })
    } else {
      const path = '/v2/sequences'
      return doPost({ path, data, useChatURL: true }).then(response => {
        return new Sequence({ row: response.data })
      })
    }
  }

  delete () {
    const path = `/v2/sequences/${this.id}`
    return doDelete({ path, useChatURL: true })
  }

  getFlow (): { nodes: any, edges: any } {
    const nodes = this.nodes
    const edges = this.edges
    if (!edges) {
      return { nodes: [...nodes], edges: [] }
    }
    return { nodes: [...nodes], edges: [...edges] }
  }

  getFlows (): { data: Sequence[], isLoading: boolean } {
    const path = `/v2/sequences/${this.id}/flows`
    return useDoQuery({ useChatURL: true, path, objectClass: Sequence })
  }

  static restoreFlow (publishedID: Sequence['publishedID'], sequenceID: Sequence['id']): Promise<{ data: Sequence[] }> {
    const path = `/v2/sequences/${sequenceID}/restore`
    const data = {
      attributes: { published_flow_id: publishedID }
    }
    return doPatch({ path, data, useChatURL: true })
  }

  constructor ({ row }: { row: Record<string, any> }) {
    super({ row })
    const sequence = row.attributes
    this.name = sequence.name
    this.edges = sequence.edges
    this.nodes = sequence.nodes
    this.ports = sequence.ports
    // this.objectType = sequence.objectType
    this.isReusable = sequence.is_reusable
    this.description = sequence.description
    this.draftID = sequence.draft_flow_id
    this.publishedID = sequence.published_flow_id
    this.isActive = sequence.is_active || false
  }
}
