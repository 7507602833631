import { makeStyles } from '@material-ui/core/styles'
import { useEffect, useState } from 'react'
import { saveTenant } from 'api/admin_only/tenants'
import Picker from 'cf-components/Pickers'

const useStyles = makeStyles(theme => ({
  selectMargin: {
    marginBottom: 10
  }
}))

interface accountManagerProps {
  tenantId: number | null
  accountManagerID: number
}

export const AdminAccountManager = (props: accountManagerProps): JSX.Element => {
  const classes = useStyles()
  const [accountManager, setAccountManager] = useState(props.accountManagerID)

  useEffect(() => {
    setAccountManager(props.accountManagerID)
  }, [props.accountManagerID])

  const handleChange = (val: number) => {
    const attributes = { account_manager_id: val, is_admin: true } // eslint-disable-line
    saveTenant({ tenantID: props.tenantId, attributes: attributes })
      .then(() => {
        setAccountManager(val)
      })
  }

  return (
    <div className={classes.selectMargin}>
      <Picker
        objectType='users'
        selection={accountManager}
        setSelection={(val: any) => handleChange(val)}
      />
    </div>
  )
}
