import { makeStyles } from '@material-ui/core/styles'
import Picker from 'cf-components/Pickers'
import { EditableChip } from 'library/EditableChip'
import { RadioButtons } from 'library/materialUI'
import ButtonTextField from 'library/textFields/ButtonTextField'
import { useContext, useState } from 'react'
import { NPSContext } from './NPSContext'
import { NPSSettingsHeader } from './NPSSettingsComponents'

const useStyles = makeStyles(theme => ({
  exclusionSection: {
    width: 500,
    marginTop: 20,
    font: 'normal normal normal 14px/25px Poppins'
  },
  urlChips: {
    display: 'flex'
  }
}))

const pageButtons = [
  { label: 'All pages', value: 'all' },
  { label: 'Exclude pages', value: 'exclude' }
]

const audienceButtons = [
  { label: 'All users', value: 'all' },
  { label: 'Exclude users', value: 'exclude' }
]

export function NPSTargeting () {
  const classes = useStyles()
  const { stateHandlers } = useContext(NPSContext)
  const [state, dispatch] = stateHandlers
  const [label, setLabel] = useState('')

  return (
    <div>
      <div>
        <NPSSettingsHeader
          title='Page'
          subtitle='On which page should the NPS survey be triggered?'
        />
        <div style={{ padding: '5px 20px 20px 20px' }}>
          <RadioButtons
            buttons={pageButtons}
            value={state.pages.exclude ? 'exclude' : 'all'}
            handleChange={(value: string) => {
              dispatch({ path: 'pages.exclude', value: value !== 'all' })
            }}
          />
          {state.pages.exclude && (
            <div className={classes.exclusionSection}>
              <div>Exclude pages containing the following keywords:</div>
              <ButtonTextField
                label={label}
                setLabel={setLabel}
                addButton={() => dispatch({ path: 'pages.keywords', operator: 'push', value: label })}
                placeholder='Type URL keyword and hit enter...'
              />
              <div className={classes.urlChips}>
                {state.pages.keywords.map((keyword: string, index: number) => (
                  <EditableChip
                    key={index}
                    value={keyword}
                    handleRemove={() => dispatch({ path: 'pages.keywords', operator: 'remove', value: keyword })}
                    handleChange={(value) => dispatch({ path: `pages.keywords[${index}]`, value })}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
      <div>
        <NPSSettingsHeader
          title='Audience'
          subtitle='For which users should the NPS survey trigger?'
        />
        <div style={{ padding: '5px 20px 20px 20px' }}>
          <RadioButtons
            buttons={audienceButtons}
            value={state.audience.exclude ? 'exclude' : 'all'}
            handleChange={(value: string) => {
              dispatch({ path: 'audience.exclude', value: value !== 'all' })
            }}
          />
          {state.audience.exclude && (
            <div className={classes.exclusionSection}>
              <div>Exclude users from the following tenants:</div>
              <Picker
                objectType='tenants'
                selection={state.audience.excludedTenants}
                setSelection={(value: any) => dispatch({ path: 'audience.excludedTenants', value })}
                multiple
              />
            </div>
          )}
        </div>
      </div>
      <div>
        <NPSSettingsHeader
          title='Email Reports'
          subtitle='Which users should be notified when a new score comes in?'
        />
        <div style={{ padding: '5px 20px 20px 20px', width: 500 }}>
          <Picker
            objectType='users'
            selection={state.adminUserIDs}
            setSelection={(value: any) => dispatch({ path: 'adminUserIDs', value })}
            multiple
          />
        </div>
      </div>
    </div>
  )
}
