import { makeStyles } from '@material-ui/core/styles'
import { Input } from '@material-ui/core'
import CancelRoundedIcon from '@material-ui/icons/CancelRounded'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'

const useStyles = makeStyles(theme => ({
  editableChip: {
    height: '1.1876em',
    padding: '7px 3px',
    color: 'white',
    backgroundColor: '#d3d3d3',
    borderRadius: 16,
    alignItems: 'center',
    margin: 5,
    display: 'flex'
  },
  buttonTextContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 5
  },
  chip: {
    fontSize: '13px',
    color: 'black',
    marginTop: 1
  }
}))

function getWidthOfInput (value: string): number {
  const div = document.createElement('div')
  div.id = 'button-div'
  div.innerHTML = value.endsWith(' ') ? value + 's' : value
  div.style.cssText = 'position:absolute; font-size: 13px; font-family: Poppins; visibility: hidden; padding: 5px; font-weight: 600'
  document.body.appendChild(div)
  const width = div.offsetWidth
  document.body.removeChild(div)
  return width + 25
}

type EditableChipProps = {
  value: string
  handleChange: (value: string) => void
  handleRemove: () => void
  draggable?: boolean
}

export function EditableChip (props: EditableChipProps): JSX.Element {
  const classes = useStyles()
  const extra = props.draggable ? 12 : -18
  const width = getWidthOfInput(props.value) + extra

  return (
    <div className={classes.editableChip} style={{ width: width + 12 }}>
      {props.draggable &&
        <DragIndicatorIcon style={{ display: 'flex', paddingLeft: 2, fontSize: '1.3rem' }} />}
      <div className={classes.buttonTextContainer} style={{ width }}>
        <Input
          value={props.value}
          disableUnderline
          onChange={e => props.handleChange(e.target.value)}
          fullWidth
          className={classes.chip}
        />
      </div>
      <CancelRoundedIcon
        style={{ marginTop: -1, fontSize: '1.2rem', cursor: 'pointer', color: '#a6a6a6' }}
        onClick={props.handleRemove}
      />
    </div>
  )
}
