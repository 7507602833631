
const descriptionText = 'Use this bot template to create a bot which will proactively send a message from the agent who is the account owner (when available) if a visitor from one of your owned accounts is on your site. This bot:'

const Description = (): JSX.Element => {
  return (
    <div>
      <div>
        {descriptionText}
      </div>
      <ul>
        <li>Welcomes the visitor to your site</li>
        <li>Routes the account owner to live chat if the visitor interacts with the bot</li>
      </ul>
    </div>
  )
}

const bestPracticeText = 'Proactively set account owners to be sure that the most relevent agents are in contact with your visitors.'

const BestPractices = () => {
  return (
    <div>
      <div>
        {bestPracticeText}
      </div>
      <ul>
        <li><a href='#/account_management/accounts' target='_blank'>Account Management</a></li>
      </ul>
    </div>
  )
}

export const ABELiveChatBotDocs = {
  helpCenterLink: '',
  bestPractices: BestPractices,
  description: Description,
  videoLink: ''
}
