import { ClusteredColumnChartProps } from 'classes/report'
import { DefaultPane } from './DefaultPane'

interface objectMapping {
  objectType: string
  noObject: any
  recentActivityTitle?: string
  showActivities?: boolean
  stats?: {
    name: string
    value: string
    tooltip?: string
    icon?: string
    subvalue?: string
  }[]
  chart?: ClusteredColumnChartProps
}

export interface objectMappingTypes {
  chatbots: objectMapping
  playrunner: objectMapping
  emails?: objectMapping
  sequences?: objectMapping
  forms?: objectMapping
}

const botItems = [
  { name: 'prompts', value: 'prompts', icon: 'showPassword' },
  { name: 'conversations', value: 'conversations', icon: 'chat', subvalue: 'conversationRate' },
  { name: 'emails captured', value: 'emailsCaptured', icon: 'send', subvalue: 'emailRate' },
  { name: 'meetings booked', value: 'meetingsBooked', icon: 'dateRange', subvalue: 'meetingBookedRate' },
  { name: 'agents routed', value: 'liveChats', icon: 'liveChat', subvalue: 'routeRate' },
  { name: 'primary goals', value: 'primaryGoals', icon: 'star', subvalue: 'primaryGoalRate' }
  // { name: 'engagement rate', value: 'engagementRate', icon: 'goal' }
]

const formItems = [
  { name: 'page views', value: 'pageViews', tooltip: 'Page views are counted whena  visitor views the page containing the form', icon: 'showPassword' },
  { name: 'submissions', value: 'submissions', icon: 'send', subvalue: 'submissionRate' },
  { name: 'prompts', value: 'prompts', tooltip: 'Prompts are counted when visitors submit a form and meet the criteria to be presented with the option to book a meeting and/or begin a live chat', icon: 'announcement', subvalue: 'promptRate' },
  { name: 'live chats', value: 'liveChats', icon: 'liveChat', subvalue: 'liveChatRate' },
  { name: 'meetings booked', value: 'meetingsBooked', icon: 'dateRange', subvalue: 'meetingBookedRate' }
  // { name: 'submission rate', value: 'submissionRate' }
]

const sequenceItems = [
  { name: 'sequence fires', value: 'sequence_fires', icon: 'showPassword' },
  { name: 'emails captured', value: 'emails_captured', icon: 'alternateEmail' },
  { name: 'meetings booked', value: 'meetings_booked', icon: 'dateRange' },
  { name: 'agents routed', value: 'live_chat_routes', icon: 'liveChat' },
  { name: 'primary goals', value: 'primary_goals', icon: 'checkMark' },
  { name: 'secondary goals', value: 'secondary_goals', icon: 'checkMark' }
]

const emailItems = [
  { name: 'sent', value: 'sent', icon: 'send' },
  { name: 'delivered', value: 'delivered', icon: 'deliveredIcon', subvalue: 'deliveredRate' },
  { name: 'opened', value: 'opened', icon: 'showPassword', subvalue: 'openedRate' },
  { name: 'clicked', value: 'clicked', icon: 'clickedIcon', subvalue: 'clickedRate' }
]

// const botChart: ClusteredColumnChartProps = {
//   object: 'bot',
//   reportType: 'clusteredColumn',
//   columns: ['conversations', 'emailsCaptured', 'meetingsBooked'],
//   category: 'period',
//   timePeriod: 'week',
//   objectID: undefined
// }

// const playItems = [
//   { name: 'starts', value: 'starts' },
//   { name: 'completed', value: 'completed' },
//   { name: 'completion percent', value: 'completionPercent' }
// ]

export const objectTypeMapping: objectMappingTypes = {
  chatbots: {
    objectType: 'bot',
    stats: botItems,
    showActivities: true,
    // chart: botChart,
    noObject: DefaultPane
  },
  playrunner: {
    objectType: 'play',
    showActivities: false,
    // stats: playItems,
    noObject: DefaultPane
  },
  emails: {
    objectType: 'email',
    stats: emailItems,
    showActivities: true,
    noObject: DefaultPane
  },
  sequences: {
    objectType: 'sequence',
    stats: sequenceItems,
    showActivities: true,
    // state: sequenceChart,
    noObject: DefaultPane
  },
  forms: {
    objectType: 'form',
    recentActivityTitle: 'Recent Submissions',
    stats: formItems,
    showActivities: true,
    noObject: DefaultPane
  }
}
