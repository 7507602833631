import { ReactNode, useMemo, useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemText from '@material-ui/core/ListItemText'
import Slider, { createSliderWithTooltip } from 'rc-slider'
import 'rc-slider/assets/index.css'
import { InputAdornment, ListSubheader, TextField, Checkbox } from '@material-ui/core'
import { Icon } from 'library/materialUI'
import BackendPicker from 'library/picker/backendPicker'

const Range = createSliderWithTooltip(Slider.Range)
const PickerMap: Record<string, 'technologies' | 'industries' | 'tld'> = {
  Technologies: 'technologies',
  Industry: 'industries'
}

const ICPField = withStyles(theme => ({
  multipleSelect: {
    display: 'flex',
    height: '50px',
    maxWidth: '100%',
    width: '100%',
    '&.range': {
      padding: 8,
      paddingBottom: 24,
      width: 'calc(100% - 24px)',
      '& .cf-padded-tip': {
        width: 'max-content',
        height: 20,
        position: 'relative',
        '&.cf-padded-tip-hidden': { display: 'none' },
        '& > .cf-padded-tip-content': {
          position: 'relative',
          '& > .cf-padded-tip-inner': {
            background: '#808080',
            color: '#FFFFFF',
            borderRadius: 4,
            padding: 3,
            fontSize: '0.9em'
          }
        }
      }
    },
    '& > div': {
      width: '100%',
      '& > *': { width: '100%' }
    }
  },
  disabled: {
    opacity: 0.5
  },
  displayValue: {
    opacity: 0.9,
    fontStyle: 'italic',
    paddingRight: 2
  },
  range: {
    '& > div.rc-slider-track': {
      backgroundColor: theme.palette.primary.main
    },
    '& > div.rc-slider-handle': {
      borderColor: theme.palette.primary.main,
      zIndex: 1,
      '&.rc-slider-handle-dragging': {
        borderColor: theme.palette.primary.main,
        boxShadow: `0 0 3px 4px ${theme.palette.primary.main}`
      }
    },
    '& .rc-slider-dot-active': {
      borderColor: theme.palette.primary.main
    },
    '& .rc-slider-mark-text': {
      fontSize: '0.6vw'
    }
  },
  searchBar: {
    height: 40,
    width: '100%',
    backgroundColor: '#FFFFFF'
  }
}))(
  (props: {
    classes: import('@material-ui/styles').ClassNameMap
    icon: import('@material-ui/core/OverridableComponent').OverridableComponent<import('@material-ui/core').SvgIconTypeMap>
    title: string
    value?: string[]
    range?: Record<string, any>
    backend?: boolean
    displayValue?: string
    setValue?: (value: string[]) => void
    valueOptions?: string[]
    required?: boolean
    setRequired?: () => void
  }): JSX.Element => {
    const classes = props.classes
    const [search, setSearch] = useState('')

    const searchText = (option: string, search: string) => {
      return option.toLowerCase().indexOf(search.toLowerCase()) > -1
    }

    const valueOptions = useMemo(() => props.valueOptions?.filter(option => searchText(option, search)),
      [search, props.valueOptions]
    )
    let disableRequired = props?.value?.length === 0
    let requiredChecked = props.required

    let children = (
      <div className={classes.multipleSelect}>
        <div>
          <Select
            value={props.value || []}
            onChange={e => props.setValue!((e.target.value as string[]) || [])}
            renderValue={selected => {
              if (selected && (selected as string[]).length) return (selected as string[]).join(', ')
              return <em className={classes.disabled}>No Restriction</em>
            }}
            multiple
            displayEmpty
            variant='outlined'
            margin='dense'
            MenuProps={{
              variant: 'menu',
              autoFocus: false,
              disableAutoFocusItem: true,
              disableEnforceFocus: true,
              disableAutoFocus: true,
              getContentAnchorEl: null
            }}
          >
            <ListSubheader>
              <TextField
                className={classes.searchBar}
                size='medium'
                autoFocus
                placeholder='Type to search...'
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <Icon icon='search' color='#975ce6' />
                    </InputAdornment>
                  )
                }}
                onChange={(e) => setSearch(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key !== 'Escape') {
                    e.stopPropagation()
                  }
                }}
              />
            </ListSubheader>
            <MenuItem disabled value=''>
              <em>{props?.title}</em>
            </MenuItem>
            {valueOptions?.map(value => {
              return (
                <MenuItem key={value} value={value}>
                  <Checkbox
                    color='primary'
                    checked={(props.value || []).indexOf(value) > -1}
                  />
                  <ListItemText primary={value} />
                </MenuItem>
              )
            })}
          </Select>
        </div>
      </div>
    )

    if (props.range) {
      disableRequired = props.range.value[0] === props.range.min && props.range.value[1] === props.range.max
      children = (
        <div className={classes.multipleSelect + ' range'}>
          <div>
            <Range
              {...props.range}
              tipProps={{ prefixCls: 'cf-padded-tip' }}
              className={classes.range}
            />
          </div>
        </div>
      )
    } else if (props.backend) {
      children = (
        <div>
          <BackendPicker
            title={props.title}
            objectType={PickerMap[props.title]}
            selection={props.value}
            setSelection={(value: any) => props.setValue!(value)}
            placeholder='No Restriction'
            isSelect
          />
        </div>
      )
    }
    requiredChecked = requiredChecked && !disableRequired
    setTimeout(() => {
      if (!requiredChecked && props?.required && props.setRequired) {
        props.setRequired()
      }
    }, 100)

    return (
      <div style={{ display: 'flex', padding: '5px' }}>
        <ICPFieldTitle
          title={props.title}
          displayValue={props.displayValue}
          icon={props.icon}
        >
          {children}
        </ICPFieldTitle>
        <Checkbox
          checked={requiredChecked}
          onChange={() => props.setRequired ? props.setRequired() : ({})}
          color='primary'
          disableRipple
          disabled={disableRequired}
        />
      </div>
    )
  }
)

export default ICPField

export const ICPFieldTitle = withStyles(theme => ({
  ctn: {
    display: 'grid',
    gridTemplateColumns: '40px 1fr',
    flexBasis: '45%',
    flexGrow: 1,
    padding: '5px 0'
  },
  rightCtn: {
    display: 'flex',
    flexFlow: 'column nowrap',
    overflow: 'hidden',
    width: '90%'
  },
  icon: {
    color: '#E6D7FA',
    margin: '0 auto',
    marginRight: 4,
    fontSize: '2.2em'
  },
  titleCtn: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  title: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: '1.2em',
    paddingBottom: 8
  },
  displayValue: {
    opacity: 0.9,
    fontStyle: 'italic',
    paddingRight: 2
  }
}))(
  (props: {
    children?: ReactNode
    classes: import('@material-ui/styles').ClassNameMap
    icon: import('@material-ui/core/OverridableComponent').OverridableComponent<import('@material-ui/core').SvgIconTypeMap>
    title: string
    displayValue?: string
  }) => {
    const classes = props.classes
    return (
      <div className={classes.ctn}>
        <props.icon className={classes.icon} />
        <div className={classes.rightCtn}>
          <div className={classes.titleCtn}>
            <div className={classes.title}>
              {props.title}
            </div>
            {props.displayValue ? (
              <div className={classes.displayValue}>
                {props.displayValue}
              </div>
            ) : <></>}
          </div>
          {props.children}
        </div>
      </div>
    )
  }
)
