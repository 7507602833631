import { Checkbox, Icon, Tooltip, Typography } from 'library/materialUI'
import { makeStyles } from '@material-ui/core/styles'
import { TableContext } from 'library/table/TableProvider'
import { useContext, useEffect, useState } from 'react'
import { ScrollBox } from 'cf-components/ScrollBox'
import FilterBar from 'library/table/FilterBar'
import { SearchParams } from 'classes/queryHelpers'
import useVisibility from 'cf-components/useVisibility'
import SkeletonTableLoader from 'library/loading/skeleton/SkeletonTableLoader'
import Loader from 'library/loading/Loader'
import SkeletonLoader from 'library/loading/skeleton/SkeletonLoader'
import TableListRow from './TableListRow'

const useStyles = makeStyles(theme => ({
  newRowsLoader: {
    height: '40px',
    margin: '30px'
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '12px',
    borderBottomWidth: '1px',
    borderColor: '#CBCBCB',
    borderBottomStyle: 'solid',
    paddingRight: 20,
    position: 'sticky',
    top: 0,
    backgroundColor: '#f8f8f8',
    zIndex: 2,
    width: 'fit-content',
    minWidth: 'calc(100% - 20px)'
  },
  headerScroll: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 'calc(100% - 400px)'
  },
  headItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    textTransform: 'uppercase',
    fontSize: '14px',
    fontFamily: 'poppins',
    color: '#00000080',
    paddingLeft: 20,
    paddingRight: 20
  },
  headerItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    textTransform: 'uppercase',
    fontSize: '14px',
    fontFamily: 'poppins',
    color: '#00000080',
    paddingLeft: 40,
    paddingRight: 20
  },
  firstHeaderItem: {
    display: 'flex',
    width: 400,
    flexDirection: 'row',
    alignItems: 'center',
    textTransform: 'uppercase',
    fontSize: '14px',
    fontFamily: 'poppins',
    color: '#00000080',
    cursor: 'pointer',
    paddingTop: 20,
    paddingBottom: 20,
    borderRight: '1px solid #CBCBCB',
    position: 'sticky',
    left: 0,
    backgroundColor: '#f8f8f8',
    zIndex: 3
  }
}))

interface TableListProps {
  selected?: string[]
  setSelected?: (value: any) => void
  maxHeight?: string
  disableSnoozeFilter?: boolean
  noTotal?: boolean
}

export default function TableList<Class> ({ selected, setSelected, noTotal = false, maxHeight = '87vh' }: TableListProps) {
  const classes = useStyles()
  const {
    rows,
    fields,
    totalRows,
    isLoading,
    isLoadingCount,
    handleSort,
    handlePageSize,
    searchParams,
    setSelectedParams,
    objectClass,
    actions

  } = useContext(TableContext)
  const [allSelected, setAllSelected] = useState(false)
  const [localRows, setLocalRows] = useState([])
  const [localTotal, setLocalTotal] = useState(localRows.length)
  const headField = fields[0]

  const handleSelectAll = (val: boolean) => {
    if (setSelected) {
      setSelected([])
    }
    if (!val) {
      setSelectedParams({} as SearchParams)
    }
    setAllSelected(val)
  }
  useEffect(() => {
    if (!isLoading) {
      setLocalRows(rows)
    }
    if (!isLoadingCount) {
      setLocalTotal(totalRows)
    }
  }, [isLoading, isLoadingCount, rows, totalRows])

  const expandPage = () => {
    handlePageSize(localRows.length + 20)
  }

  const [visible, visibleRef] = useVisibility<HTMLDivElement>()

  useEffect(() => {
    if (visible && localTotal !== localRows.length) {
      expandPage()
    }
    // eslint-disable-next-line
  }, [visible, isLoading, isLoadingCount])

  useEffect(() => {
    if (selected) {
      const currSelected = [{ field: objectClass.selectedField, operator: 'in', value: selected }]
      const modifiedSearchParams = {
        filters: [
          currSelected
        ],
        pageNumber: 1,
        pageSize: selected?.length,
        search: ''
      } as SearchParams
      setSelectedParams(modifiedSearchParams)
    }
    // eslint-disable-next-line
  }, [selected])
  const ignoreSelect = setSelected === undefined

  return (
    // eslint-disable-next-line
    <div style={{ display: 'flex', flexDirection: 'column', maxHeight: maxHeight }}>
      <div style={{ display: 'flex', flexDirection: 'row', borderBottomWidth: '1px', borderColor: '#CBCBCB', borderBottomStyle: 'solid' }}>
        {!noTotal && (
          <div style={{ marginLeft: '20px', marginTop: '20px', marginBottom: '20px', color: '#00000080' }}>
            <Typography style={{ display: 'flex', alignItems: 'center' }}>
              Showing {localRows?.length} of {isLoadingCount ? <span style={{ marginLeft: 5 }}><SkeletonLoader type='number' size='md' /></span> : localTotal}
            </Typography>
          </div>
        )}
        <div style={{ marginLeft: 'auto' }}>
          <div style={{ padding: '10px 20px' }}>
            <FilterBar />
          </div>
        </div>
      </div>
      <ScrollBox style={{ overflowX: 'auto' }}>
        <div className={classes.header}>
          <Tooltip title={headField?.tooltip || ''}>
            <div className={classes.firstHeaderItem}>
              {setSelected && (
                <div style={{ marginLeft: 20 }} onClick={() => handleSelectAll(!allSelected)}>
                  <Checkbox
                    checked={allSelected}
                    onChange={() => undefined}
                  />
                </div>
              )}
              <div
                style={{ cursor: headField.sort === false ? 'default' : 'pointer' }}
                onClick={() => {
                  if (headField.sort === false) return
                  handleSort(String(headField.name))
                }}
                className={classes.headItem}
              >
                {headField.label || String(headField.name).toUpperCase()} {searchParams.sortColumn === headField.name && <div style={{ display: 'flex', alignItems: 'center', marginTop: '2px', marginLeft: '5px' }}><Icon icon={searchParams.sortOrder === 'desc' ? 'downArrow' : 'upArrow'} size='sm' /></div>}
              </div>
            </div>
          </Tooltip>
          <div className={classes.headerScroll}>
            {fields.map((field, index) => {
              if (index === 0) return null
              if (field?.noDisplay) return null
              return (
                <Tooltip key={index} title={field?.tooltip || ''}>
                  <div
                    style={{ minWidth: field?.minWidth ? field.minWidth : 100, cursor: field.sort === false ? 'default' : 'pointer' }}
                    onClick={() => {
                      if (field.sort === false) return
                      handleSort(String(field.name))
                    }}
                    className={classes.headerItem}
                  >
                    {field.label || String(field.name).toUpperCase()} {searchParams.sortColumn === field.name && <div style={{ display: 'flex', alignItems: 'center', marginTop: '2px', marginLeft: '5px' }}><Icon icon={searchParams.sortOrder === 'desc' ? 'downArrow' : 'upArrow'} size='sm' /></div>}
                  </div>
                </Tooltip>
              )
            })}
          </div>
        </div>
        {isLoading && localRows.length === 0 ? (
          <SkeletonTableLoader
            rowCount={8}
            types={['squareAvatar&Label', 'name&Subtitle', 'number', 'smLabel', 'number', 'smLabel', 'number', 'avatar']}
            columns='80px 300px 1fr 1fr 1fr 1fr 120px 80px'
            justifyCenter
          />) : localRows?.map((item: Class, index: number) => (
            <TableListRow<Class> key={index} row={item} fields={fields} actions={actions} selected={selected} setSelected={setSelected} allSelected={allSelected} ignoreSelect={ignoreSelect} />
          ))}
        {localRows.length > 0 && localRows.length < localTotal && (
          <div key='loader-row' className={classes.newRowsLoader} ref={visibleRef}>
            <Loader type='spinner' size='md' />
          </div>
        )}
      </ScrollBox>
    </div>
  )
}
