import { useEffect, useState } from 'react'
import SocialPage from './SocialMediaPage'
import ContactInfo from './ContactInfo'
import CustomizedSnackbar from 'components/CustomizedSnackbar'
import AgentProfileControl from './AgentProfileControl'
import { getMeetingTypes } from 'api/meetings'
import { components } from 'session-context'
import AccessControl from 'components/AccessControl'

function MyProfile (props) {
  const state = props.state
  const dispatch = props.dispatch
  const [meetingTypes, setMeetingTypes] = useState({})
  const [reloadMeetings, setReloadMeetings] = useState(false)
  const chatServiceUrl = props.chatServiceUrl
  const meetingSettingsError = props.meetingSettingsError

  const [snackState, setSnackState] = useState({
    open: false,
    variant: 'success',
    message: 'Your settings have been saved'
  })

  function updateContactInfo (name, value) {
    dispatch({ type: 'updateInfo', name: name, value: value })
  }

  function updateSocialLink (name, value) {
    dispatch({ type: 'updateSocialProfile', name: name, value: value })
  }

  function updateProfilePicture (value) {
    dispatch({ type: 'updateInfo', name: 'profile_picture_url', value: value })
  }

  function updateAgentProfileSettings (name, value) {
    dispatch({ type: 'updateAgentProfileSettings', name: name, value: value })
  }

  useEffect(() => {
    getMeetingTypes({ chatServiceUrl, agent_pages_only: true })
      .then(response => {
        const meetingDict = Object.assign({}, ...response.data.map(mt => ({ [mt.id]: mt })))
        setMeetingTypes(meetingDict)
      })
  }, [chatServiceUrl, reloadMeetings])

  useEffect(() => {
    if (props.user) {
      const data = props.user.attributes
      dispatch({ type: 'initialize', info: data })
    }
    // eslint-disable-next-line
  }, [props.user])

  if (!state) {
    return <></>
  }

  return (
    <div>
      <ContactInfo
        info={state}
        updateContactInfo={updateContactInfo}
        updateProfilePicture={updateProfilePicture}
        editable
        user_id={props.user.id}
      />

      <br />
      <SocialPage
        profiles={state.social_media_profiles}
        updateSocialLink={updateSocialLink}
        editable
      />
      <br />
      <AccessControl requiredComponent={components.AGENT_PROFILE_PAGES}>
        <AgentProfileControl
          info={state}
          user={props.user}
          updateContactInfo={updateContactInfo}
          updateAgentProfileSettings={updateAgentProfileSettings}
          meetingTypes={meetingTypes}
          meetingSettingsError={meetingSettingsError}
          onCopy={() => {
            setSnackState({
              open: true,
              variant: 'success',
              message: 'Copied to clipboard!'
            })
          }}
          reloadMeetings={reloadMeetings}
          setReloadMeetings={setReloadMeetings}
        />
      </AccessControl>
      <CustomizedSnackbar state={snackState} handler={setSnackState} />
    </div>
  )
}

export default MyProfile
