import { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Account from 'classes/accounts'
import { format } from 'date-fns'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { ContactInfoAvatar } from 'cf-components/Avatars'
import { getSegmentsFromDomain } from 'api/segments'
import { Icon, Tooltip } from 'library/materialUI'
import { Divider } from '@material-ui/core'
import { SignalsScoreGear } from 'cf-components/SignalsScoreGear'
import OppStatusText from 'pages/dashboard/OppStatusText'
import { DisplayLink } from 'library/table/tableHelpers'

const useStyles = makeStyles(theme => ({
  chatBox: {
    height: '100%',
    backgroundColor: 'white',
    width: 450,
    fontFamily: 'Poppins, sans serif',
    display: 'grid',
    gridTemplateRows: '68px calc(100% - 68px)'
  },
  header: {
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px 10px 10px 25px',
    color: 'white',
    alignItems: 'center',
    fontSize: '20px',
    fontWeight: 600
  },
  body: {
    padding: 20,
    overflowY: 'auto'
  },
  visitorAvatar: {
    marginBottom: 30,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  icon: {
    color: '#8E5AE2',
    height: 24,
    width: 24
  },
  contactButton: {
    height: 24,
    width: 24,
    '&$disabled': {
      backgroundColor: '#7AC9FF'
    }
  },
  infoItem: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 8
  },
  itemTitle: {
    color: theme.palette.primary.main,
    marginRight: 50
  },
  primary: {
    color: theme.palette.primary.main
  },
  itemData: {
    textAlign: 'right'
    // Space by around 50px from title
  },
  messageDiv: {
    marginTop: 20,
    padding: 0
  },
  buttonDiv: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 15
  },
  savedReplies: {
    height: '325px',
    width: '275px'
  },
  replyList: {
    maxHeight: 325
  },
  replyRow: {
    display: 'grid',
    gridTemplateColumns: 'auto 50px',
    gridGap: 10,
    alignItems: 'center',
    fontSize: '14px',
    fontFamily: 'poppins',
    padding: '5px',
    paddingTop: '12px',
    paddingBottom: '12px'
  },
  replyText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
    fontFamily: 'poppins'
  },
  buttons: {
    fontSize: '12px',
    boxShadow: 'none',
    textTransform: 'none',
    color: 'white',
    maxHeight: 24
  }
}))

function LiveViewChatBox (props) {
  const classes = useStyles()
  const fmat = 'MM/dd/yyyy'
  const domain = props?.participant?.domain
  const [accountType, setAccountType] = useState('')
  const [segments, setSegments] = useState('')
  const { data: acc, isLoading } = Account.loadOneByDomain(props?.participant?.domain)
  const [loadingSegments, setLoadingSegments] = useState(false)
  const mobile = props.mobile || false

  useEffect(() => {
    if (domain && !loadingSegments) {
      getSegmentsFromDomain(domain).then((response) => {
        if (response.data.length > 0) {
          const segmentNames = response.data.map(segment => segment.attributes.name)
          setSegments(segmentNames.join(', '))
        } else {
          setSegments('No segments')
        }
        setLoadingSegments(false)
      })
      setLoadingSegments(true)
    } else {
      setSegments('No segments')
    }
    // eslint-disable-next-line
  }, [domain])

  if (props.participant) {
    if (accountType === '') {
      if (!isLoading) {
        const account = acc
        if (account) {
          setAccountType(account.accountType)
        }
      }
    }
    return (
      <div className={classes.chatBox} style={mobile ? { width: '100%' } : { width: '450px' }}>
        <div className={classes.header}>
          <div>
            Visitor Information
          </div>
          <IconButton onClick={() => props.close()}>
            <CloseIcon style={{ fill: 'white' }} />
          </IconButton>
        </div>
        <div className={classes.body}>
          <div className={classes.visitorAvatar}>
            <ContactInfoAvatar
              contact={props.participant.person}
              participantID={props.participant.id}
              size='lg'
            />
            <SignalsScoreGear score={props.participant.signalsScore || 0} domain={props?.participant?.domain} specificSize={75} />
          </div>
          <div>
            <div className={classes.infoItem}>
              <div className={classes.itemTitle}>
                Company
              </div>
              <div className={classes.itemData}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <DisplayLink field={props.participant.domain} link={'/accounts/' + props.participant.domain}>
                    {props.participant.company || props.participant.sixSenseName || props.participant.domain}
                  </DisplayLink>
                  {props.participant.isICP && (
                    <Tooltip title='This company matches your Ideal Customer Profile (ICP)'>
                      <div>
                        <Icon icon='icp' />
                      </div>
                    </Tooltip>
                  )}
                </div>
              </div>
            </div>
            <Divider style={{ marginBottom: '6px' }} />
            <div className={classes.infoItem}>
              <div className={classes.itemTitle}>
                Opportunity Status
              </div>
              <div className={classes.itemData}>
                <OppStatusText status={props.participant.opportunityStatus} />
              </div>
            </div>
            <Divider style={{ marginBottom: '6px' }} />
            <div className={classes.infoItem}>
              <div className={classes.itemTitle}>
                Title
              </div>
              <div className={classes.itemData}>
                {props.participant.person?.attributes.title || 'Title Unknown'}
                {props.participant?.fromBuyingCommittee &&
                  <Tooltip title={`This person is a member of your buying committee in your ${props.participant.person?.attributes?.persona} persona.`}>
                    <div style={{ marginLeft: '5px' }}>
                      <Icon icon='buyingCommittee' />
                    </div>
                  </Tooltip>}
              </div>
            </div>
            <Divider style={{ marginBottom: '6px' }} />
            <div className={classes.infoItem}>
              <div className={classes.itemTitle}>
                Account Type
              </div>
              <div className={classes.itemData}>
                {accountType}
              </div>
            </div>
            <Divider style={{ marginBottom: '6px' }} />
            <div className={classes.infoItem}>
              <div className={classes.itemTitle}>
                Segments
              </div>
              <div className={classes.itemData}>
                {loadingSegments ? <div>...loading</div> : segments}
              </div>
            </div>
            <Divider style={{ marginBottom: '6px' }} />
            <div className={classes.infoItem}>
              <div className={classes.itemTitle}>
                Current Page
              </div>
              <div className={classes.itemData}>
                <a href={props.participant.currentUrl} target='_blank' rel='noreferrer' className={classes.primary}>
                  {props.participant.currentPage}
                </a>
              </div>
            </div>
            <Divider style={{ marginBottom: '6px' }} />
            <div className={classes.infoItem}>
              <div className={classes.itemTitle}>
                Time on Site
              </div>
              <div className={classes.itemData}>
                {props.participant.timeOnSite}
              </div>
            </div>
            <Divider style={{ marginBottom: '6px' }} />
            <div className={classes.infoItem}>
              <div className={classes.itemTitle}>
                Total Pages Viewed
              </div>
              <div className={classes.itemData}>
                {props.participant.totalPagesViewed}
              </div>
            </div>
            <Divider style={{ marginBottom: '6px' }} />
            <div className={classes.infoItem}>
              <div className={classes.itemTitle}>
                Conversations to Date
              </div>
              <div className={classes.itemData}>
                {props.participant.conversationsToDate}
              </div>
            </div>
            <Divider style={{ marginBottom: '6px' }} />
            <div className={classes.infoItem}>
              <div className={classes.itemTitle}>
                Site Visits
              </div>
              <div className={classes.itemData}>
                {props.participant.totalVisits}
              </div>
            </div>
            <Divider style={{ marginBottom: '6px' }} />
            <div className={classes.infoItem}>
              <div className={classes.itemTitle}>
                First Visit
              </div>
              <div className={classes.itemData}>
                {props?.participant?.firstSeen ? format(new Date(props.participant.firstSeen), fmat) : ''}
              </div>
            </div>
            <Divider style={{ marginBottom: '6px' }} />
            <div className={classes.infoItem}>
              <div className={classes.itemTitle}>
                Traffic Source
              </div>
              <div className={classes.itemData}>
                {props.participant.trafficSource}
              </div>
            </div>
            <Divider style={{ marginBottom: '6px' }} />
            <div className={classes.infoItem}>
              <div className={classes.itemTitle}>
                Location
              </div>
              <div className={classes.itemData}>
                {props.participant.location}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <></>
  )
}

export default LiveViewChatBox
