import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    height: '100%'
  },
  tab: {
    height: '100%'
  },
  appBar: {
    transform: 'none'
  },
  paper: {
    boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2)',
    marginTop: -15
  },
  wrap: {
    '& .jtk-surface': {
      width: '100%',
      height: '800px'
    }
  },
  dragDrop: {
    cursor: 'grab',
    '&:active': {
      cursor: 'grabbing'
    }
  },
  dragger: {
    display: 'block',
    width: '250px',
    fontSize: '.9em',
    backgroundColor: 'white',
    borderRadius: '10px',
    overflow: 'hidden',
    boxShadow: '0px 0px 10px 1px rgba(0,0,0,0.75)',
    fontFamily: 'Poppins, sans serif',
    '& .node-header': {
      display: 'flex',
      minHeight: 50,
      color: 'white',
      alignItems: 'center',
      paddingLeft: 15,
      fontWeight: 600,
      fontSize: '1.1em'
    },
    '& .node-body': {
      padding: '10px 20px',
      color: '#7C7C7C',
      minHeight: 18,
      '& p': {
        margin: 0,
        overflow: 'hidden',
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical'
      }
    }
  },
  cardTitle: {
    marginLeft: 10
  },
  iconDiv: {
    display: 'flex',
    alignItems: 'center'
  },
  backgroundAction: {
    '& .node-header': {
      backgroundColor: '#DD64E7'
    }
  },
  visitorInteraction: {
    '& .node-header': {
      backgroundColor: '#658AE5'
    }
  },
  agentConnection: {
    '& .node-header': {
      backgroundColor: '#3de396'
    }
  },
  flowSequence: {
    '& .node-header': {
      backgroundColor: '#2B4177'
    }
  },
  localSequence: {
    '& .node-header': {
      backgroundColor: '#B14172'
    }
  },
  endOfFlow: {
    '& .node-header': {
      backgroundColor: '#4A4A4A'
    }
  },
  startDragger: {
    display: 'block',
    width: 250,
    height: '40px',
    backgroundColor: '#6CD3C0',
    color: 'white',
    textAlign: 'center',
    fontWeight: 'bold',
    lineHeight: '40px'
  },
  startNodeOuter: {
    width: 250,
    height: '90px'
  },
  startPort: {
    marginTop: '5px',
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundColor: '#63D5C4',
    height: '32px',
    width: '32px',
    borderRadius: '16px'
  },
  hoverButtonDiv: {
    position: 'absolute',
    top: -5,
    left: -15,
    zIndex: 2000
  },
  hoverButton: {
    border: '1px solid rgba(0,0,0,0.08)',
    borderRadius: 50,
    boxShadow: '-1px 1px rgba(0,0,0,0.2)',
    backgroundColor: '#FFFFFF',
    height: 30,
    width: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer'
  }
}))

export default useStyles
