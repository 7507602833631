/* eslint-disable @typescript-eslint/naming-convention */
import { useMemo, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Icon, Button } from 'library/materialUI'
import { DashboardFilterAttributes, filterAttribute } from '../DashboardHelper'
import { ScrollBox } from 'cf-components/ScrollBox'
import { Drawer, ClickAwayListener, Typography } from '@material-ui/core'
import { QueryFilter, QueryFilterGroup } from 'classes/queryHelpers'
import FilterSection from './FilterSection'

const useStyles = makeStyles(theme => ({
  filterPopup: {
    width: '375px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    justifyContent: 'top',
    backgroundColor: 'white',
    borderRadius: '10px',
    boxShadow: '0px 3px 6px #00000029',
    overflow: 'auto'
  },
  popupHeader: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: '16px',
    fontFamily: 'poppins',
    fontWeight: 'bold',
    marginLeft: '21px',
    marginRight: '21px',
    marginTop: '18px',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  popupBody: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    justifyContent: 'center',
    marginLeft: '16px'
  },
  divider: {
    height: '2px',
    width: 'maxContent',
    backgroundColor: '#00000030',
    margin: '18px'
  },
  popupItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: '14px',
    fontFamily: 'poppins',
    margin: '5px',
    whiteSpace: 'nowrap',
    marginRight: '21px',
    cursor: 'pointer'
  },
  popupFooter: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '12px',
    fontFamily: 'poppins',
    marginLeft: '15px',
    marginRight: '15px',
    marginBottom: '31px',
    whiteSpace: 'nowrap'
  }
}))

interface FilterDrawerProps {
  open: boolean
  setOpen: (value: boolean) => void
  filters: QueryFilter[]
  setFilters: (filterGroup: QueryFilter[]) => void
}
/**
 * Filter drawer should only be incharge of the filter state and should not care
 * what the filter is being saved or used for. Only will handle one filter group.
 */
export default function FilterDrawer ({ open, setOpen, filters, setFilters }: FilterDrawerProps) {
  const classes = useStyles()
  const secondAnchor = useRef(null)
  const filterOptions = DashboardFilterAttributes()

  const [filterCount, setFilterCount] = useState(filters.filter(filter => filter.field !== 'id').length)
  const cleared = useMemo(() => filterCount === 0, [filterCount])

  const handleSetFiltersOpen = (value: boolean) => {
    if (!value) {
      setOpen(false)
    } else {
      setOpen(value)
    }
  }

  const handleClearFilters = () => {
    const newFilters = filters.filter(filter => filter.field === 'id')
    setFilters(newFilters)
    setFilterCount(0)
  }

  return (
    <Drawer
      anchor='right'
      open={open}
      onClose={() => handleSetFiltersOpen(false)}
    >
      <ClickAwayListener
        onClickAway={() => {
          handleSetFiltersOpen(false)
        }}
      >
        <div className={classes.filterPopup}>
          <div className={classes.popupHeader}>
            <div>
              Filters
            </div>
            <div style={{ cursor: 'pointer' }}>
              <Icon icon='close' onClick={() => handleSetFiltersOpen(false)} />
            </div>
          </div>
          <div className={classes.popupHeader}>
            <Typography variant='subtitle2'>
              {filterCount !== 1 && <div>{filterCount} filters applied</div>}
              {filterCount === 1 && <div>{filterCount} filter applied</div>}
            </Typography>
            {filterCount > 0 && (
              <Button variant='text' onClick={() => handleClearFilters()} style={{ textTransform: 'capitalize' }}>
                <Icon icon='close' size='md' />
                Clear all
              </Button>
            )}
          </div>
          <div className={classes.divider} />
          <ScrollBox noScrollBar>
            <div className={classes.popupBody} ref={secondAnchor}>
              {Object.entries(filterOptions).map((option: [string, filterAttribute], index: number) => (
                <FilterSection
                  key={index}
                  attribute={option[1]}
                  filters={filters}
                  cleared={cleared}
                  editFilters={(filterGroup: QueryFilterGroup) => {
                    const newFilters = filters.filter(filter => filter.field !== option[1].dbKey).concat(filterGroup)
                    setFilters(newFilters)
                    let count = 0
                    newFilters.forEach(filter => {
                      if (filter.field !== 'id') {
                        if (Array.isArray(filter.value)) {
                          count += filter.value.length
                        } else {
                          count++
                        }
                      }
                    })
                    setFilterCount(count)
                  }}
                />
              ))}
            </div>
          </ScrollBox>
        </div>
      </ClickAwayListener>
    </Drawer>
  )
}
