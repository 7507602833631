import React, { useState, useEffect, useCallback } from 'react'
import DashboardCard from './DashboardCard'
import { getAgentReport } from 'api/reports'
import { last30DaysRange, convertDateRange } from 'cf-components/FilterBar/FilterBar'
import { getUsers } from 'api/users'
import PerformanceTable from './PerformanceTable'

function convertTime (s) {
  if (s === null) {
    return '---'
  }
  s = parseInt(s)
  if (s < 10) {
    s = '0' + s
  }
  if (s < 60) {
    return '0:' + s
  }
  const mins = parseInt(s / 60)
  let secs = (s - 60 * mins)
  if (secs < 10) {
    secs = '0' + secs
  }
  if (mins < 60) {
    return parseInt(mins) + ':' + secs
  }
  const hours = parseInt(mins / 60)
  let m = (mins - 60 * hours)
  if (m < 10) {
    m = '0' + m
  }
  if (hours < 24) {
    return parseInt(hours) + ':' + m + ':' + secs
  } else {
    return '> 24 hr'
  }
}

function sortAgents (firstAgent, secondAgent) {
  if (firstAgent.conversations > secondAgent.conversations) {
    return 1
  }
  if (firstAgent.conversations < secondAgent.conversations) {
    return -1
  }
  if (firstAgent.responseRate > secondAgent.responseRate) {
    return 1
  }
  if (firstAgent.responseRate < secondAgent.responseRate) {
    return -1
  }
  if (firstAgent.responseTime !== '---' && secondAgent.responseTime !== '---') {
    if (firstAgent.responseTime < secondAgent.responseTime) {
      return 1
    }
    if (firstAgent.responseTime > secondAgent.responseTime) {
      return -1
    }
  } else if (firstAgent.responseTime !== '---') {
    return 1
  } else if (secondAgent.responseTime !== '---') {
    return -1
  }
  if (firstAgent.conversationLength !== '---' && secondAgent.conversationLength !== '---') {
    if (firstAgent.conversationLength < secondAgent.conversationLength) {
      return 1
    }
    if (firstAgent.conversationLength > secondAgent.conversationLength) {
      return -1
    }
  } else if (firstAgent.conversationLength !== '---') {
    return 1
  } else if (secondAgent.conversationLength !== '---') {
    return -1
  }
  if (firstAgent.timeAvailable > secondAgent.timeAvailable) {
    return 1
  }
  if (firstAgent.timeAvailable < secondAgent.timeAvailable) {
    return -1
  }
  return 0
}

function PerformanceCard (props) {
  const [agentReport, setAgentReport] = useState(null)
  const [users, setUsers] = useState(null)
  const chatServiceUrl = props.chatServiceUrl
  const convertedRange = convertDateRange(last30DaysRange)
  const start = convertedRange?.start_date
  const end = convertedRange?.end_date

  const loadReport = useCallback(() => {
    getAgentReport({ chatServiceUrl, start, end })
      .then(response => {
        const flat = response.data.filter(i => (Object.keys(users).map(id => parseInt(id))).includes(i.id)).map(i => {
          const data = i.attributes
          const convos = data.conversations || 0
          const rate = data.response_rate ? Math.round(data.response_rate * 100, 2) + '%' : '---'
          return {
            id: i.id,
            user: users[i.id],
            agentName: users[i.id].attributes.name,
            agentEmail: users[i.id].attributes.email,
            conversations: convos,
            sortResponseRate: data.response_rate,
            responseRate: rate,
            sortResponseTime: data.avg_response_time,
            responseTime: convertTime(data.avg_response_time),
            sortConversationLength: data.avg_conversation_length,
            conversationLength: convertTime(data.avg_conversation_length),
            timeAvailable: Math.round(data.minutes_available / 60)
          }
        })
        const sorted = flat.sort((a, b) => sortAgents(b, a))
        let finalAgentReport = flat
        if (sorted.length > 3) {
          finalAgentReport = sorted.splice(0, 3)
          const filteredReports = finalAgentReport.filter(agent => agent.user.id === props.user.id)
          if (filteredReports.length === 0) {
            const shortenedReport = finalAgentReport.splice(0, 2)
            finalAgentReport = shortenedReport.concat(sorted.filter(agent => agent.user.id === props.user.id))
          }
        } else {
          finalAgentReport = sorted
        }
        setAgentReport(finalAgentReport)
      })
  }, [chatServiceUrl, end, props.user.id, start, users])

  useEffect(() => {
    getUsers()
      .then(response => {
        let userDict = {}
        response.data.map(
          user => (userDict = { ...userDict, [user.id]: user })
        )
        setUsers(userDict)
      })
  }, [])

  useEffect(() => {
    if (start && end && users) {
      loadReport()
    }
  }, [users, loadReport, start, end])

  return (
    <DashboardCard
      title='Chat Performance Ranking - Last 30 Days'
      noScrollbox
    >
      <div style={{ height: 340 }}>
        <PerformanceTable
          data={agentReport}
          user={props.user}
        />
      </div>
    </DashboardCard>
  )
}

export default PerformanceCard
