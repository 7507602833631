import { castToDate, formatDate } from './classHelpers'

export class RecentActivity {
  avatar: string
  timestamp: string
  summary: string
  name: string
  url: string

  constructor ({ avatar, summary, timestamp, name, url }: { row: Record<string, any> }) {
    this.avatar = avatar
    this.timestamp = formatDate(castToDate(timestamp), 'mmmm dd - hh:MM TT')
    this.summary = summary
    this.name = name
    this.url = url
  }
}
