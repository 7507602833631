import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from 'cf-components/Table'
import { ListItemCard } from 'cf-components/CardList'
import { AvatarWithInfo, getColor, getInitials } from 'cf-components/Avatars'
import dateFormat from 'dateformat'

const useStyles = makeStyles(theme => ({
  question: {
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden'
  },
  topicCard: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 2fr 1fr',
    padding: 15,
    alignItems: 'center',
    gridGap: 20
  },
  headerClass: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 2fr 1fr',
    padding: '5px 15px',
    gridGap: 20
  }
}))

function FAQTable (props) {
  const classes = useStyles()
  const onExport = props.handleExport
  const rows = props.rows.map((row, index) => {
    const lastAsked = dateFormat(row.last_asked, 'mmm dd, yyyy')
    const initials = getInitials(row.name, 'T')
    return (
      {
        name: row.name.charAt(0).toUpperCase() + row.name.slice(1),
        initials: initials,
        count: row.count,
        id: index,
        sampleQuestion: row.sample_question,
        lastAsked: lastAsked,
        searchField: row.name + row.sample_question
      })
  })

  function TopicCard (props) {
    const row = props.row
    const color = getColor(props.index)

    return (
      <ListItemCard>
        <div className={classes.topicCard}>
          <div>
            <AvatarWithInfo
              title={row.name}
              initials={row.initials}
              subtitle='FAQ Topic'
              avatarColor={color}
              variant='rounded'
            />
          </div>
          <div>
            {row.count}
          </div>
          <div>
            {row.sampleQuestion}
          </div>
          <div>
            {row.lastAsked}
          </div>
        </div>
      </ListItemCard>
    )
  }

  const sortValues = [
    { label: 'Topic', id: 'name', header: true },
    { label: 'Search Count', id: 'count', header: true },
    { label: 'Sample Question', id: 'response', header: true },
    { label: 'Created Date', id: 'createdDate', header: true }
  ]

  return (
    <Table
      rows={rows}
      card={TopicCard}
      sortValues={sortValues}
      header
      headerClass={classes.headerClass}
      startingSortColumn='name'
      filterOptions={props.filterOptions}
      handleExport={onExport}
    />
  )
}

export default FAQTable
