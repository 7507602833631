import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'

function createLineChart ({ data, id, setVisible }) {
  am4core.addLicense('CH213476655')

  if (!document.getElementById(id)) {
    return
  }

  const chart = am4core.create(id, am4charts.XYChart)

  chart.data = data

  // Set input format for the dates
  chart.dateFormatter.inputDateFormat = 'yyyy-MM-dd'

  // Create axes
  const dateAxis = chart.xAxes.push(new am4charts.DateAxis())
  const valueAxis = chart.yAxes.push(new am4charts.ValueAxis())

  valueAxis.min = 0
  valueAxis.maxPrecision = 0

  // Create series
  const series = chart.series.push(new am4charts.LineSeries())
  series.dataFields.valueY = 'count'
  series.dataFields.dateX = 'date'
  series.tooltipText = '{count}'
  series.strokeWidth = 2
  series.minBulletDistance = 15

  // Drop-shaped tooltips
  series.tooltip.background.cornerRadius = 20
  series.tooltip.background.strokeOpacity = 0
  series.tooltip.pointerOrientation = 'vertical'
  series.tooltip.label.minWidth = 40
  series.tooltip.label.minHeight = 40
  series.tooltip.label.textAlign = 'middle'
  series.tooltip.label.textValign = 'middle'

  // Make bullets grow on hover
  const bullet = series.bullets.push(new am4charts.CircleBullet())
  bullet.circle.strokeWidth = 2
  bullet.circle.radius = 4
  bullet.circle.fill = am4core.color('#fff')

  const bullethover = bullet.states.create('hover')
  bullethover.properties.scale = 1.3

  // Make a panning cursor
  chart.cursor = new am4charts.XYCursor()
  chart.cursor.behavior = 'panXY'
  chart.cursor.xAxis = dateAxis
  chart.cursor.snapToSeries = series

  dateAxis.keepSelection = true

  chart.events.on('ready', () => {
    setVisible('visible')
  })

  return chart
}

export { createLineChart }
