import { doGet } from 'api/api';
import GenericObject from './genericObject';
import { LoadAllProps, SearchParams } from './queryHelpers';
import { SummaryCompaniesList } from './summaryCompanies';
import { useDoQuery, useDoTypeQuery } from './useDoQuery';

export class CompanyCallSummary extends GenericObject {
  objectType = 'companyCallSummary'
  domain: string
  name: string
  disposition: string
  lastCallTimestamp: string
  lastVisitTimestamp: string
  lastAction: string
  lastCalledFirstName: string
  lastCalledLastName: string
  lastCaller: string
  score: number
  isICP: boolean

  get link (): string { return `/accounts/${this.domain}` }

  static loadAll (props?: LoadAllProps): { data: SummaryCompaniesList, isLoading: boolean } {
    return useDoQuery({
      path: '/core/companies/summaries/calls',
      searchParams: props?.searchParams,
      objectClass: CompanyCallSummary,
      keepPreviousData: true
    })
  }

  static loadCount (props?: LoadAllProps): { data: number | number[], isLoading: boolean } {
    return useDoTypeQuery({
      path: '/core/companies/summaries/calls/count',
      searchParams: props?.searchParams,
      keepPreviousData: true
    })
  }

  static download (searchParams?: SearchParams) {
    return doGet({ path: '/core/csv_download/CompanyCallSummary', searchParams, returnText: true })
  }

  static getSortOptions () {
    return [
      { label: 'Last Call', value: 'last_call_timestamp' },
      { label: 'Last Visit', value: 'last_visit_timestamp' },
      { label: 'Last Action', value: 'last_action' },
      { label: 'Last Called First Name', value: 'last_called_first_name' },
      { label: 'Last Called Last Name', value: 'last_called_last_name' },
      { label: 'Last Caller', value: 'last_caller' },
      { label: 'Score', value: 'score' }
    ]
  }

  constructor ({ row }: { row: Record<string, any> }) {
    super({ row })
    const summary = row.attributes
    this.domain = summary.domain
    this.name = summary.name
    this.disposition = summary.disposition
    this.lastCallTimestamp = summary.last_call_timestamp
    this.lastVisitTimestamp = summary.last_visit_timestamp
    this.lastAction = summary.last_action
    this.lastCalledFirstName = summary.contact_first_name
    this.lastCalledLastName = summary.contact_last_name
    this.lastCaller = summary.last_caller
    this.score = summary.score
    this.isICP = summary.company_is_icp
  }
}
