import { useDoQuery } from './useDoQuery'
import { GenericObject } from './genericObject'
import { LoadAllProps } from './queryHelpers'

interface OpportunityOwnerFieldList {
  list: OpportunityOwnerField[]
  dict: { [id: OpportunityOwnerField['id']]: OpportunityOwnerField }
}

const initialState = {
  label: '',
  default: false
}

export class OpportunityOwnerField extends GenericObject {
  name: string
  default: boolean

  static loadAll (props?: LoadAllProps): { data: OpportunityOwnerFieldList, isLoading: boolean, isError: boolean, error: Error } {
    const { data, isLoading, isError, error } = useDoQuery({ path: '/api/integrations/crm/opportunities/fields/user/options', objectClass: OpportunityOwnerField })
    if ((isLoading || props?.searchParams?.search === 'lead') && !isError) {
        return { data, isLoading, isError, error }
    }
    let filtered = []
    if (!isError) {
      filtered = data.list.filter((item: any) => !item.name.startsWith('lead.'))
    }
    return {
      data: {
        list: filtered,
        dict: data?.dict || {}
      },
      isLoading,
      isError,
      error
    }
  }

  constructor ({ row }: { row?: Record<string, any> }) {
    super({ row })
    const accountType = row?.attributes || initialState
    this.name = accountType.label
    this.default = accountType.default
  }
}
