import { useEffect, useReducer, useRef, useState } from 'react'
import { Dialog } from 'library/materialUI'
import { WizardSection } from './WizardSection'
import { WizardFooter } from './WizardFooter'
import { ModalHeader } from './ModalHeader'
import { reducer } from 'library/helpers'
import { getCronofyStatus } from 'api/calendars'

declare global {
  interface Window { hideSnackBar: boolean }
}

export type WizardSectionType = {
  title: string
  subtitle: string
  contents: any
}

export type ContentsProps = {
  handleNextRef: any
  handleBackOverrideRef: any
  disabled: boolean
  setDisabled: (value: boolean) => void
  next: () => void
  warningAnchorEl?: HTMLElement
  setWarningAnchorEl?: (value: HTMLElement) => void
}

type WizardState = {
  open: boolean
  step: number
}

type OnboardingWizardProps = {
  steps: string[]
  sections: Record<string, WizardSectionType>
  initialState: WizardState
  title: string
  handleFinish: () => void
}

export function OnboardingWizard (props: OnboardingWizardProps) {
  const [state, dispatch] = useReducer(reducer, props.initialState)
  const [nextButtonAnchor, setNextButtonAnchor] = useState<any | null>(null);
  const [calendarWarning, setCalendarWarning] = useState(true)
  const [disabled, setDisabled] = useState(false)
  window.hideSnackBar = state.open
  const handleNextRef = useRef<() => void>(null)
  const handleBackOverrideRef = useRef<() => void>(null)
  const stepCount = props.steps.length

  const sectionName = props.steps[state.step]
  const section = props.sections[sectionName]
  const Contents = section.contents

  const next = (event: Event) => {
    if (sectionName === 'calendar' && calendarWarning && event) {
      setNextButtonAnchor(event?.currentTarget);
      setCalendarWarning(false)
      return
    }

    setDisabled(false)
    const lastStep = state.step === stepCount - 1
    const newState = lastStep ? { open: false } : { step: state.step + 1 }
    if (handleNextRef.current) {
      handleNextRef.current()
    }
    if (lastStep) {
      props.handleFinish()
      window.location.reload()
    }
    dispatch(newState)
  }

  const back = () => {
    setDisabled(false)
    if (handleBackOverrideRef.current) {
      handleBackOverrideRef.current()
    } else {
      if (handleNextRef.current) {
        handleNextRef.current()
      }
      dispatch({ step: state.step - 1 })
    }
  }

  useEffect(() => {
    getCronofyStatus().then((response) => {
      if (response?.data?.attributes?.active) {
        setCalendarWarning(false)
      }
    })
  }, [])

  return (
    <Dialog open={state.open}>
      <ModalHeader
        title={props.title}
        step={state.step + 1}
        stepCount={props.steps.length}
      />
      <WizardSection
        title={section.title}
        subtitle={section.subtitle}
      >
        <div>
          <Contents
            handleNextRef={handleNextRef}
            handleBackOverrideRef={handleBackOverrideRef}
            disabled={disabled}
            setDisabled={setDisabled}
            next={next}
            warningAnchorEl={nextButtonAnchor}
            setWarningAnchorEl={setNextButtonAnchor}
          />
        </div>
      </WizardSection>
      <WizardFooter
        step={state.step + 1}
        stepCount={props.steps.length}
        next={next}
        back={back}
        disabled={disabled}
      />
    </Dialog>
  )
}
