import { ReactComponent as WarningIcon } from 'img/Warning.svg'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Icon, Typography } from 'library/materialUI'

const useStyles = makeStyles(theme => ({
  style: {
    display: 'block',
    padding: '10px',
    borderRadius: '5px',
    fontSize: '12px',
    marginBottom: '10px'
  }
}))

interface ModalWarningProps {
  body: string
  type?: 'error' | 'warning' | 'upgrade'
  helpLink?: string
  helpButtonText?: string
  helpAction?: () => void
}

export default function ModalWarning (props: ModalWarningProps): JSX.Element {
  const classes = useStyles()
  const isError = props.type === 'error'
  const isUpgrade = props.type === 'upgrade'

  if (isUpgrade) {
    return (
      <div className={classes.style} style={{ background: '#9933FF1A' }}>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: props.helpLink ? '10px' : '0px' }}>
          <div style={{ borderRadius: 20, backgroundColor: '#9933FF', alignItems: 'center', justifyContent: 'center' }}>
            <Icon icon='upArrow' size='md' color='white' />
          </div>
          <Typography variant='body1' style={{ fontSize: 14, paddingLeft: 5 }}><b>{props.body}</b></Typography>
          {(props.helpLink || props.helpAction) && (
            <Button
              variant='text'
              onClick={() => {
                if (props.helpAction) {
                  props.helpAction()
                } else {
                  window.open(props.helpLink)
                }
              }}
              style={{ marginLeft: 'auto' }}
            >
              {props.helpButtonText || 'UPGRADE'}
            </Button>
          )}
        </div>
      </div>
    )
  }

  return (
    <div className={classes.style} style={{ background: isError ? '#FDEDEC' : '#FEF9E5' }}>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: props.helpLink ? '10px' : '0px' }}>
        <WarningIcon
          width='30px'
          height='14px'
          style={{ paddingRight: '5px' }}
          fill={isError ? '#EA422A' : '#F4D451'}
        />
        <Typography variant='body1' style={{ fontSize: 12 }}>{props.body}</Typography>
      </div>
      {props.helpLink && (
        <Button
          variant='outlined'
          color='primary'
          onClick={() => window.open(props.helpLink)}
        >
          {props.helpButtonText || 'Click Here'}
        </Button>
      )}
    </div>
  )
}
