import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Integration } from 'classes/integrations';
import ModalWarning from 'components/ModalWarning';
import { Button, Icon } from 'library/materialUI';

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  defaultProfileImage: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '50%',
    overflow: 'hidden',
    border: 1,
    marginRight: 10
  }
}))

type DefaultOwnerProps = {
  integration: string
  link: string | '_blank'
}

function DefaultOwnerSection (props: DefaultOwnerProps): JSX.Element {
  const { data: integration, isLoading } = Integration.loadOne(props.integration)
  const classes = useStyles()
  return (
    <div>
      <div className={classes.header}>
        <Typography variant='h2'>
          Default Owner:
        </Typography>
        <Button
          startIcon={<Icon icon='settings' />}
          color='primary'
          variant='text'
          onClick={() => window.open(props.link)}
        >
          Edit Settings
        </Button>
      </div>
      <Typography variant='body1'>
        If an account owner can’t be located using the above criteria, they will be assigned to the default owner:
      </Typography>
      {isLoading ? <></>
      : (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {integration.config?.fallback_contact_owner ? (
            <>
              <div className={classes.defaultProfileImage}><Icon icon='missingAvatar' specificSize={60} /></div>
              <Typography variant='h2'>{integration.config.fallback_contact_owner.name}</Typography>
            </>
          )
          : <ModalWarning body='No default owner is set. The Owner will be whoever set up Salesloft. If this is the wrong functionality then click Edit Settings and set a default owner.' />}
        </div>
      )}
    </div>
  )
}

export default DefaultOwnerSection
