import { useContext, useEffect, useState } from 'react'
import Picker from 'cf-components/Pickers'
import { makeStyles } from '@material-ui/core/styles'
import { TableContext } from 'library/table/TableProvider'
import useVisibility from 'cf-components/useVisibility'
import SkeletonTableLoader from 'library/loading/skeleton/SkeletonTableLoader'
import Loader from 'library/loading/Loader'
import { Icon, Typography } from 'library/materialUI'
import FilterBar from 'library/table/FilterBar'
import AccountDetails from 'classes/accountDetails'
import { ScrollBox } from 'cf-components/ScrollBox'
import CrowdSourceRow from './CrowdSourceRow'
import { BasicModal } from 'cf-components/Modal'
import { TextBox } from 'cf-components/material-wrappers/MaterialComponents'
import { addVote, reviewVotes } from 'api/crowdsource'

const useStyles = makeStyles(theme => ({
  newRowsLoader: {
    height: '40px',
    margin: '30px'
  },
  header: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr',
    marginTop: '20px',
    paddingBottom: '20px',
    alignItems: 'center',
    fontSize: '12px',
    borderBottomWidth: '1px',
    borderColor: '#CBCBCB',
    borderBottomStyle: 'solid'
  },
  headerItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    textAlign: 'center',
    textTransform: 'uppercase',
    fontSize: '14px',
    fontFamily: 'poppins',
    color: '#00000080',
    cursor: 'pointer'
  },
  firstHeaderItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    textAlign: 'center',
    textTransform: 'uppercase',
    fontSize: '14px',
    fontFamily: 'poppins',
    color: '#00000080',
    cursor: 'pointer',
    marginLeft: '30px'
  }
}))

export default function CrowdSourceDataList () {
  const classes = useStyles()
  const { rows, totalRows, isLoading, setNoExport, handleSort, handlePageSize, searchParams } = useContext(TableContext)
  const [localRows, setLocalRows] = useState(rows)
  const [localTotal, setLocalTotal] = useState(totalRows)
  const [modalOpen, setModalOpen] = useState(false)
  const [modalDomain, setModalDomain] = useState('')
  const [modalField, setModalField] = useState('')
  const [modalValue, setModalValue] = useState('')
  const [prevValue, setPrevValue] = useState('')
  const columnHeaders = [
    { label: 'COMPANY', column: 'company_name', tooltip: '' },
    { label: 'FIELD', column: 'field', tooltip: '' },
    { label: 'VALUE', column: 'value', tooltip: '' },
    { label: 'STATUS', column: 'status', tooltip: '' },
    { label: 'DATE SUBMITTED', column: 'createdTimestamp', tooltip: '' },
    { label: 'SUBMITTER NAME', column: 'createdByUserID', tooltip: '' },
    { label: 'TENANT ID', column: 'tenantID', tooltip: '' }
  ]

  const expandPage = () => {
    handlePageSize(localRows.length + 20)
  }

  const [visible, visibleRef] = useVisibility<HTMLDivElement>()

  const handleSubmit = () => {
    addVote({
      domain: modalDomain,
      field: modalField,
      vote: { value: modalValue, isUpvote: true, submission: true }
    })
    reviewVotes({ domain: modalDomain, field: modalField, approval: { value: prevValue, approval: 'denied' } })
    setModalOpen(false)
    window.location.reload()
  }
  useEffect(() => {
    setNoExport(true)
  }, []) // eslint-disable-line

  useEffect(() => {
    if (visible && localTotal !== localRows.length) {
      expandPage()
    }
    // eslint-disable-next-line
  }, [visible, isLoading])

  useEffect(() => {
    if (!isLoading) {
      setLocalTotal(totalRows)
      setLocalRows(rows)
    }
  }, [isLoading, rows, totalRows])

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex', flexDirection: 'row', borderBottomWidth: '1px', borderColor: '#CBCBCB', borderBottomStyle: 'solid' }}>
        <div style={{ marginLeft: '20px', marginTop: '20px', marginBottom: '20px', color: '#00000080' }}>
          <Typography>
            Showing {localRows?.length} of {localRows?.length}
          </Typography>
        </div>
        <div style={{ marginLeft: 'auto' }}>
          <div style={{ padding: '10px 20px' }}>
            <FilterBar />
          </div>
        </div>
      </div>
      <div className={classes.header}>
        {columnHeaders.map((header, index) => {
          return (
            <div
              key={index}
              onClick={() => handleSort(header.column)}
              className={index === 0 ? classes.firstHeaderItem : classes.headerItem}
            >
              {header.label} {searchParams.sortColumn === header.column && <div style={{ marginTop: '2px', marginLeft: '5px' }}><Icon icon={searchParams.sortOrder === 'desc' ? 'downArrow' : 'upArrow'} size='sm' /></div>}
            </div>
          )
        })}
      </div>
      <ScrollBox>
        {isLoading && localRows.length === 0 ? (
          <SkeletonTableLoader
            rowCount={8}
            justifyCenter
            columns='1fr 1fr 1fr 1fr 1fr 1fr 1fr'
            types={['name&Subtitle', 'smLabel', 'number', 'smLabel', 'smLabel', 'smLabel', 'smLabel']}
          />) : localRows?.map((item: AccountDetails, index: number) => (
            <div key={index}>
              <CrowdSourceRow accountDetails={item} setModalOpen={setModalOpen} setModalDomain={setModalDomain} setModalField={setModalField} setPrevValue={setPrevValue} />
            </div>
          ))}
        {localRows.length > 0 && localRows.length < localTotal && (
          <div key='loader-row' className={classes.newRowsLoader} ref={visibleRef}>
            <Loader type='spinner' />
          </div>
        )}
      </ScrollBox>
      {modalOpen &&
        <BasicModal
          title='Edit Submission'
          confirmButtonText='SAVE'
          open={modalOpen}
          onHide={() => setModalOpen(false)}
          confirmAction={() => handleSubmit()}
          cancelButtonText='CANCEL'
          message={
            <div>
              What would you like to update the submission to?
              <div style={{ marginTop: '5px' }}>
                {modalField === 'industry' ? (
                  <Picker
                    selection={modalValue}
                    setSelection={(value: any) => {
                      setModalValue(value)
                    }}
                    objectType='industries'
                  />
                ) : (
                  <TextBox
                    value={modalValue}
                    onChange={(val: string) => { setModalValue(val) }}
                  />
                )}
              </div>
            </div>
          }
          confirmVariant='contained'
        />}
    </div>
  )
}
