import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Popover from '@material-ui/core/Popover'
import Typography from '@material-ui/core/Typography'
import { getCustomFields } from 'api/custom_fields'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import Fab from '@material-ui/core/Fab'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { Button, Icon } from 'library/materialUI'

const useStyles = makeStyles(theme => ({
  popoverMenu: {
    width: 300,
    height: 225,
    fontFamily: 'Poppins, sans serif',
    display: 'grid',
    gridTemplateRows: '35px 190px'
  },
  menuHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 10,
    paddingRight: 15,
    borderBottom: '1px solid #AAA',
    color: theme.palette.primary.main
  },
  menuBody: {
    height: '100%',
    overflow: 'auto'
  },
  menuText: {
    fontSize: '13px',
    marginTop: 10,
    marginBottom: 25,
    color: 'rgba(0,0,0,0.75)'
  },
  button: {
    textTransform: 'none',
    width: '125px !important'
  },
  buttonsDiv: {
    display: 'grid',
    gridGap: 10,
    gridTemplateColumns: '1fr 1fr'
  },
  cancelButton: {
    textTransform: 'none',
    width: '125px !important',
    backgroundColor: 'white',
    border: '1px solid rgba(0,0,0,0.5)',
    boxShadow: 'none'
  },
  defaultMenu: {
    padding: 10
  },
  menuItem: {
    padding: '4px 10px',
    fontSize: '15px'
  },
  mergeFieldBtn: {
    width: '35%',
    marginBottom: 10,
    marginRight: 10
  }
}))

const contactAttributes = [
  { label: 'First Name', value: 'first_name' },
  { label: 'Last Name', value: 'last_name' },
  { label: 'Email', value: 'email' },
  { label: 'Title', value: 'title' },
  { label: 'Company Name', value: 'company_name' },
  { label: 'Company Industry', value: 'company_industry' }
]

function MergeFieldMenu (props) {
  const classes = useStyles()
  const [attribute, setAttribute] = useState(null)
  const [fallback, setFallback] = useState('')

  const handleChange = (event) => {
    setFallback(event.target.value)
  }

  function remapEnterKey (event) {
    if (event.key === 'Enter') {
      handleClick()
    }
  }

  const handleClick = () => {
    const prefix = attribute.prefix || 'visitor'
    const mergeField = '{{ ' + prefix + '.' + attribute.value + ' or "' + fallback + '" }}'
    props.action(mergeField)
    props.onHide()
  }

  if (attribute) {
    return (
      <div className={classes.popoverMenu}>
        <div className={classes.menuHeader}>
          {attribute.label}
          <ArrowBackIcon
            style={{ cursor: 'pointer' }}
            onClick={() => setAttribute(null)}
          />
        </div>
        <div className={classes.menuBody}>
          <div className={classes.defaultMenu}>
            <TextField
              onChange={handleChange}
              value={fallback}
              color='primary'
              variant='outlined'
              margin='dense'
              fullWidth
              label='Default Value'
              onKeyPress={remapEnterKey}
            />
            <div className={classes.menuText}>
              The default value will display when a contact doesn't have a known value
            </div>
            <div className={classes.buttonsDiv}>
              <Fab
                color='primary'
                variant='extended'
                size='small'
                className={classes.button}
                onClick={handleClick}
                disabled={!fallback}
              >
                Insert
              </Fab>
              <Fab
                className={classes.cancelButton}
                size='small'
                variant='extended'
                onClick={props.onHide}
              >
                Cancel
              </Fab>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={classes.popoverMenu}>
      <div className={classes.menuHeader}>
        Visitor Attributes
      </div>
      <div>
        {props.options.map((option, index) => (
          <MenuItem
            key={index}
            onClick={() => setAttribute(option)}
            className={classes.menuItem}
          >
            {option.label}
          </MenuItem>
        ))}
      </div>
    </div>
  )
}

function MergeFieldsButton (props) {
  const [anchorEl, setAnchorEl] = useState(null)
  const [customFields, setCustomFields] = useState([])
  const classes = useStyles()

  useEffect(() => {
    getCustomFields('people')
      .then(response => {
        setCustomFields(response.data)
      })
  }, [])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  const customFieldOptions = customFields.map(field => ({
    label: field.attributes.label,
    value: field.id
  }))

  let options = contactAttributes.concat(customFieldOptions)
  if (props.accountOwner) {
    options = options.concat([
      { label: 'Account Owner First Name', value: 'first_name', prefix: 'account_owner' },
      { label: 'Account Owner Name', value: 'name', prefix: 'account_owner' }
    ])
  }
  if (props.calendarAgent) {
    options = options.concat([
      { label: 'Calendar Agent profile page', value: 'profile_page', prefix: 'calendar_agent' },
      { label: 'Calendar Agent Email Signature', value: 'email_signature', prefix: 'calendar_agent' },
      { label: 'Calendar Agent Company', value: 'company_name', prefix: 'calendar_agent' }
    ])
  }
  if (props.alerts) {
    options.unshift(
      { label: 'Full Name', value: 'full_name' }
    )
  }

  const displayButton = <Button className={classes.mergeFieldBtn} onClick={handleClick} variant='outlined' color='primary' endIcon={<Icon icon='arrowDropDown' />} size='small'>Insert Merge Field</Button>
  const displayTitle = <div onClick={handleClick}><Typography>{'{...}'}</Typography></div>
  const display = props.button ? displayButton : displayTitle
  return (
    <div className={props.className ? props.className : ''}>
      {display}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <MergeFieldMenu
          action={props.action}
          options={options}
          onHide={() => setAnchorEl(null)}
        />
      </Popover>
    </div>
  )
}

export default MergeFieldsButton
