import Card from 'library/materialUI/Card'
import IntegrationMapping from '../IntegrationMapping'
import Loader from 'library/loading/Loader'
import { IntegrationMappingCls } from 'classes/IntegrationMapping'
import { useContext, useEffect } from 'react'
import { TabContext } from '../IntegrationPage/TabProvider'
import { IntegrationPageContext } from '../IntegrationPage/IntegrationPageProvider'

const contactGroups = [{ value: 'contacts', name: 'Contact Fields' }]

interface AccountFieldMappingCardProps {
  save: any
  accountExternalFields: any
  focusMapping: any
}

export default function AccountFieldMappingCard ({ save, accountExternalFields, focusMapping }: AccountFieldMappingCardProps) {
  const { data: crmAccountMapping, isLoading } = IntegrationMappingCls.loadOne('crm_account', 'salesforce')
  const { shouldSave } = useContext(TabContext)
  const { handleSaved } = useContext(IntegrationPageContext)

  useEffect(() => {
    if (shouldSave) {
      save()
      handleSaved()
    }
  })

  return (
    <Card
      content={
        <>
          {isLoading ? <Loader type='spinner' />
          : <IntegrationMapping
              currentMapping={crmAccountMapping.mapping}
              externalMappingFields={accountExternalFields}
              forObject='accounts'
              integrationName='Salesforce'
              title='Account Field Mapping'
              handleEdit={(data: any) => { IntegrationMappingCls.save('crm_account', data, 'salesforce') }}
              defaultFieldEnabled
              mappedGroups={contactGroups}
              focusMapping={focusMapping}
            />}
        </>
      }
    />
  )
}
