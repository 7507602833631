import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  dimOverlay: {
    position: 'fixed',
    backgroundColor: '#00000080',
    display: 'block',
    zIndex: -2
  }
}))

interface DimHighlightProps {
  anchorID: string
}

export default function DimHighlight ({ anchorID }: DimHighlightProps): JSX.Element {
  const classes = useStyles()
  const target = document.getElementById(anchorID)
  const targetRect = target?.getBoundingClientRect()
  const topVal = (targetRect?.top || 0)
  const leftVal = (targetRect?.left || 0)
  const targetWidth = targetRect?.width || 0
  const targetHeight = targetRect?.height || 0

  return (
    <>
      <div className={classes.dimOverlay} style={{ top: 0, bottom: window.innerHeight - topVal, right: 0, left: 0 }} />
      <div className={classes.dimOverlay} style={{ top: topVal, bottom: window.innerHeight - topVal - targetHeight, right: window.innerWidth - leftVal, left: 0 }} />
      <div className={classes.dimOverlay} style={{ top: topVal, bottom: window.innerHeight - topVal - targetHeight, right: 0, left: leftVal + targetWidth }} />
      <div className={classes.dimOverlay} style={{ top: topVal + targetHeight, bottom: 0, right: 0, left: 0 }} />
      <div style={{ position: 'fixed', display: 'block', zIndex: -2, top: topVal, left: leftVal, width: targetWidth, height: targetHeight }} />
    </>
  )
}
