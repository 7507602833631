import { makeStyles } from '@material-ui/core/styles'
import { ReactComponent as FilterIcon } from 'img/filterIcon.svg'
import { useContext, useState } from 'react';
import FilterMenu from 'library/filter/FilterMenu';
import { TableContext } from './TableProvider';

const useStyles = makeStyles(theme => ({
  icon: props => ({
    marginRight: props.iconOnly ? 0 : 5,
    cursor: 'pointer'
  }),
  menuPaper: {
    boderRadius: 10
  },
  container: {
    '&:hover': { backgroundColor: '#F5F5F5' },
    margin: '5px'
  }
}))

export default function FilterBarIcon (props: any) {
  const classes = useStyles(props)
  const [anchorEl, setAnchorEl] = useState(null)
  const { filterOptions } = useContext(TableContext)
  const Icon = FilterIcon

  if (Object.keys(filterOptions).length === 0 && (!props.listFilterProps?.filterOptions || Object.keys(props.listFilterProps.filterOptions).length === 0)) {
    return <></>
  }

  return (
    <div>
      <div className={classes.container}>
        <div onClick={(event: any) => setAnchorEl(event.currentTarget)} style={{ display: 'flex', flexDirection: 'row', cursor: 'pointer', width: props.iconOnly ? '40px' : '99px', height: '40px', alignItems: 'center', justifyContent: 'center', border: '1px solid #D3D3D3', borderRadius: '5px' }}>
          <Icon
            className={classes.icon}
            color='primary'
          />
          {!props.iconOnly && <div style={{ fontFamily: 'poppins', fontSize: '14px' }}>Filter</div>}
        </div>
      </div>
      {anchorEl &&
        <FilterMenu
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          listFilterProps={props.listFilterProps}
          placement={props.placement}
        />}
    </div>
  )
}
