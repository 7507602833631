import Form, { FormAction } from 'classes/forms'
import { makeStyles } from '@material-ui/core/styles'
import FormFollowupCard from './FormFollowupCard'
import FollowUpPreview from './FollowUpPreview'
import FormTargetingCard from './FormTargetingCard'
import { Dispatch, useEffect, useState } from 'react'
import { ScrollBox } from 'cf-components/ScrollBox'
import FormOnlineContentCard from './FormOnlineContentCard'
import FormOfflineContentCard from './FormOfflineContentCard'
import FormPromptAppearanceCard from './FormPromptAppearanceCard'
import { Button, Icon } from 'library/materialUI'
import { triggerSalesBot } from 'pages/bot/InAppBot/triggerSupportBot'
import FollowUpGif from 'img/Form-Combined.gif'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    height: '95%'
  },
  section: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    borderRadius: '5px'
  },
  scrollArea: {
    height: '100%'
  },
  toggleDiv: {
    display: 'flex',
    flexDirection: 'row',
    justifySelf: 'flex-start',
    marginTop: '20px'
  },
  onlineButton: {
    backgroundColor: '#FFFFFF',
    border: '1px solid #D3D3D3',
    borderRadius: '5px 0px 0px 5px',
    cursor: 'pointer',
    height: '40px',
    paddingRight: '10px',
    paddingLeft: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  offlineButton: {
    backgroundColor: '#FFFFFF',
    border: '1px solid #D3D3D3',
    borderRadius: '0px 5px 5px 0px',
    cursor: 'pointer',
    height: '40px',
    paddingRight: '10px',
    paddingLeft: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  banner: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F4E9FE',
    padding: '15px',
    marginBottom: '10px'
  }
}))

interface FormFollowupPageProps {
  form: Form
  formDispatch: Dispatch<FormAction>
  setCanSave: (value: boolean) => void
  chatPrimaryColor?: string
  refreshMissing: () => any
  hasFollowUpAccess?: boolean
  isLoadingComponents?: boolean
}

export default function FormFollowupPage ({ form, formDispatch, setCanSave, chatPrimaryColor, refreshMissing, hasFollowUpAccess, isLoadingComponents }: FormFollowupPageProps): JSX.Element {
  const classes = useStyles()
  const [onlineCardOpen, setOnlineCardOpen] = useState(false)
  const [offlineCardOpen, setOfflineCardOpen] = useState(false)
  const [appearanceCardOpen, setAppearanceCardOpen] = useState(false)
  const [showOfflinePrompt, setShowOfflinePrompt] = useState<boolean>(false)
  const [targetCardOpen, setTargetCardOpen] = useState(false)
  const [offlineEnabled, setOfflineEnabled] = useState(form.liveChat)

  useEffect(() => {
    setOfflineEnabled(form.liveChat)
    if (!form.liveChat) {
      setShowOfflinePrompt(false)
    }
  }, [form.liveChat])

  useEffect(() => {
    if (onlineCardOpen) {
      setShowOfflinePrompt(false)
    }
  }, [onlineCardOpen])

  useEffect(() => {
    if (offlineCardOpen) {
      setShowOfflinePrompt(true)
    }
  }, [offlineCardOpen])

  if (!hasFollowUpAccess) {
    return (
      <div>
        <div className={classes.banner}>
          <div>
            <Icon icon='upArrowCircle' size='sm' />
          </div>
          <div style={{ marginLeft: '20px' }}>
            Upgrade to fast-track form submitters to live chat and meeting scheduling
          </div>
          <div style={{ marginLeft: '30px' }}>
            <Button variant='outlined' onClick={() => triggerSalesBot()}>Talk To Sales</Button>
          </div>
        </div>
        <div className={classes.container}>
          <div className={classes.section}>
            <FormFollowupCard form={form} dispatch={formDispatch} setCanSave={setCanSave} disabled />
          </div>
          <div className={classes.section} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '15px', border: '1px solid #D3D3D3', position: 'relative', padding: 30 }}>
            <img src={FollowUpGif} alt='follow up gif' />
          </div>
        </div>
      </div>
    )
  }

  if (!isLoadingComponents && hasFollowUpAccess) {
    return (
      <div className={classes.container}>
        <div className={classes.section}>
          <ScrollBox className={classes.scrollArea} noScrollBar backgroundColor='inherit'>
            <FormFollowupCard form={form} dispatch={formDispatch} setCanSave={setCanSave} />
            {form.followUp && (
              <div>
                <div>
                  <FormTargetingCard open={targetCardOpen} setOpen={setTargetCardOpen} form={form} dispatch={formDispatch} setCanSave={setCanSave} />
                </div>
                <div>
                  <FormOnlineContentCard
                    open={onlineCardOpen}
                    setOpen={setOnlineCardOpen}
                    setCanSave={setCanSave}
                    formDispatch={formDispatch}
                    form={form}
                    showOfflinePrompt={showOfflinePrompt}
                    refreshMissing={refreshMissing}
                  />
                </div>
                {offlineEnabled && (
                  <div>
                    <FormOfflineContentCard
                      open={offlineCardOpen}
                      setOpen={setOfflineCardOpen}
                      setCanSave={setCanSave}
                      formDispatch={formDispatch}
                      form={form}
                      refreshMissing={refreshMissing}
                    />
                  </div>
                )}
                <div>
                  <FormPromptAppearanceCard
                    open={appearanceCardOpen}
                    setOpen={setAppearanceCardOpen}
                    setCanSave={setCanSave}
                    formDispatch={formDispatch}
                    form={form}
                    chatPrimaryColor={chatPrimaryColor}
                  />
                </div>
              </div>
            )}
          </ScrollBox>
        </div>
        <div className={classes.section} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '15px', border: '1px solid #D3D3D3', position: 'relative' }}>
          <div className={classes.toggleDiv}>
            <div onClick={() => setShowOfflinePrompt(false)} className={classes.onlineButton} style={{ color: showOfflinePrompt ? 'black' : '#9933FF' }}>
              Online Prompt
            </div>
            <div onClick={() => setShowOfflinePrompt(true)} className={classes.offlineButton} style={{ color: showOfflinePrompt ? '#9933FF' : 'black' }}>
              Offline Prompt
            </div>
          </div>
          <FollowUpPreview form={form} showOfflinePrompt={showOfflinePrompt} />
        </div>
      </div>
    )
  }
}
