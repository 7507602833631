import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Fab from '@material-ui/core/Fab'
import ConversationStarted from '../../../img/conversation-started.svg'
import PageViewed from '../../../img/page-viewed.svg'
import { Tooltip } from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import dateFormat from 'dateformat'
import { Icon } from 'library/materialUI'

const useStyles = makeStyles(theme => ({
  container: {
    padding: '0px 20px 0px 20px',
    height: 'calc(100% - 40px)',
    width: 'calc(100% - 40px)',
    overflowY: 'auto'
  },
  information: {
    width: '100%'
  },
  eventSummary: {
    display: 'flex',
    width: 'calc(100% - 20px)',
    justifyContent: 'space-between',
    padding: 10,
    alignItems: 'center',
    fontFamily: 'Poppins, sans serif',
    fontWeight: 500,
    fontSize: '18px',
    color: '#7C7C7C'
  },
  visual: {
    display: 'flex',
    alignItems: 'center'
  },
  iconDiv: {
    height: 42
  },
  iconImage: {
    height: 20,
    width: 20
  },
  eventIcon: {
    height: 42,
    width: 42,
    border: '2px solid #8E5AE2',
    '&$disabled': {
      backgroundColor: '#FFF'
    }
  },
  disabled: {
  },
  label: {
    paddingLeft: 15
  },
  count: {
    paddingRight: 10
  },
  header: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderTop: '1px solid rgba(0, 0, 0, .125)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    fontFamily: 'Poppins, sans serif',
    fontSize: '14px',
    fontWeight: 600,
    minHeight: 40,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 20
  },
  sourceText: {
    display: 'flex',
    alignItems: 'center'
  }
}))

function EventDisplay (props) {
  const classes = useStyles()
  const text = props.text === null ? '---' : props.text

  return (
    <div className={classes.eventSummary}>
      <div className={classes.visual}>
        <div className={classes.iconDiv}>
          <Fab
            disabled
            classes={{
              root: classes.eventIcon,
              disabled: classes.disabled
            }}
            style={{ border: '2px solid ' + props.color }}
          >
            {props.imgSrc
              ? <img src={props.imgSrc} className={classes.iconImage} alt='Event' />
              : props.icon}
          </Fab>
        </div>
        <div className={classes.label}>
          {props.label}
        </div>
      </div>
      <div className={classes.count}>
        {text}
      </div>
    </div>
  )
}

export function SourceInfo (props) {
  const classes = useStyles()
  return (
    <div className={classes.sourceText}>
      <div style={{ marginRight: 10 }}>
        {props.source.referrer ? props.source.referrer : null}
      </div>
      {(props.source.traffic_source || props.source.traffic_campaign) ? (
        <Tooltip
          title={<><b>Campaign: </b> {props.source.traffic_campaign ? props.source.traffic_campaign : '---'} <br /> <b>Source: </b> {props.source.traffic_source ? props.source.traffic_source : '---'}</>}
          placement='bottom'
        >
          <div className={classes.sourceText}>
            <Icon icon='info' color='#00000040' size='sm' />
          </div>
        </Tooltip>
        ) : <></>}
    </div>
  )
}

function ContactSummary (props) {
  const classes = useStyles()

  const firstSeen = props.summaryDetails.firstSeen ? dateFormat(props.summaryDetails.firstSeen, 'mm/dd/yyyy') : null
  const lastSeen = props.summaryDetails.lastSeen ? dateFormat(props.summaryDetails.lastSeen, 'mm/dd/yyyy') : null
  const firstSource = props.summaryDetails.firstSource ? <SourceInfo source={props.summaryDetails.firstSource} /> : '---'
  const lastSource = props.summaryDetails.lastSource ? <SourceInfo source={props.summaryDetails.lastSource} /> : '---'

  return (
    <>
      <div className={classes.header}>
        Contact Activity Summary
      </div>
      <div className={classes.container}>
        <div className={classes.information}>
          <div style={{ cursor: 'pointer' }} onClick={() => props.showSelection('pages')}>
            <EventDisplay label='Page views' color='#39AFFF' imgSrc={PageViewed} text={props.summaryDetails.pageViews} />
          </div>
          <Divider />
          <div style={{ cursor: 'pointer' }} onClick={() => props.showSelection('conversations')}>
            <EventDisplay label='Conversations' color='#8E5AE2' imgSrc={ConversationStarted} text={props.summaryDetails.conversations} />
          </div>
          <Divider />
          <EventDisplay label='Meetings Booked' color='#8E5AE2' icon={<Icon icon='eventAvailable' color='#8E5AE2' />} text={props.meetings} />
          <Divider />
          <EventDisplay label='First seen' color='#61CBBC' icon={<Icon icon='event' color='#61CBBC' />} text={firstSeen} />
          <Divider />
          <EventDisplay label='First source' color='#61CBBC' icon={<Icon icon='trafficSource' color='#61CBBC' />} text={firstSource} />
          <Divider />
          <EventDisplay label='Last seen' color='#39AFFF' icon={<Icon icon='event' color='#39AFFF' />} text={lastSeen} />
          <Divider />
          <EventDisplay label='Last source' color='#39AFFF' icon={<Icon icon='trafficSource' color='#39AFFF' />} text={lastSource} />
        </div>
      </div>
    </>
  )
}

export default ContactSummary
