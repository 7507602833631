import { useContext, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { SessionContext } from 'session-context'
import { TenantDataProvider } from './tenant-data-context'
import { ConversationParametersProvider } from './conversation-parameters-context'
import { ConversationWindowProvider } from './conversation-window-context'
import FilterDrawer from './filter-drawer/FilterDrawer'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import MobileDetect from 'mobile-detect'
import ChatPage from './ChatPage'
import clsx from 'clsx'

const drawerWidth = 260

const useStyles = makeStyles(theme => ({
  wrap: {
    width: '100%',
    marginLeft: 0,
    flexGrow: 1,
    height: '100%',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    position: 'relative'
  },
  wrapShift: {
    width: `calc(100% - ${drawerWidth + 2}px)`,
    marginLeft: drawerWidth + 2,
    flexGrow: 1,
    height: '100%',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    position: 'relative'
  },
  toggleButton: {
    position: 'absolute',
    zIndex: 1300,
    backgroundColor: '#F2EBFF',
    display: 'flex',
    top: 7,
    padding: 1,
    cursor: 'pointer'
  },
  toggleOpen: {
    borderTopRightRadius: 15,
    borderBottomRightRadius: 15,
    left: 1
  },
  toggleClose: {
    borderBottomLeftRadius: 15,
    borderTopLeftRadius: 15,
    left: -26
  }
}))

function DrawerToggleButton (props) {
  const classes = useStyles()
  const drawerOpen = props.drawerOpen
  const handleToggle = props.handleToggle

  const buttonClass = drawerOpen ? classes.toggleClose : classes.toggleOpen

  if (props.mobile) {
    return <></>
  }

  return (
    <div
      id='nav-sidebar'
      className={`${classes.toggleButton} ${buttonClass}`}
      onClick={handleToggle}
    >
      {drawerOpen
        ? <ChevronLeftIcon color='primary' />
        : <ChevronRightIcon color='primary' />}
    </div>
  )
}

function AgentChat (props) {
  const [drawerOpen, setDrawerOpen] = useState(false)
  const { user } = useContext(SessionContext)
  const chatServiceUrl = user.links.chat_service
  window.chatServiceUrl = chatServiceUrl

  const classes = useStyles()

  const handleToggle = () => {
    setDrawerOpen(!drawerOpen)
  }

  const md = new MobileDetect(window.navigator.userAgent)
  const mobile = !!md.phone()

  return (
    <TenantDataProvider>
      <ConversationParametersProvider>
        <ConversationWindowProvider>
          <div id='agent-chat-window' style={{ position: 'relative' }}>
            <div className={clsx(classes.wrap, { [classes.wrapShift]: drawerOpen })}>
              <DrawerToggleButton
                handleToggle={handleToggle}
                drawerOpen={drawerOpen}
                mobile={mobile}
              />
              <ChatPage
                mobile={mobile}
                setDrawerOpen={setDrawerOpen}
              />
            </div>
            <FilterDrawer open={drawerOpen} />
          </div>
        </ConversationWindowProvider>
      </ConversationParametersProvider>
    </TenantDataProvider>
  )
}

export default AgentChat
