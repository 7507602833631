import { FormControlLabel, Switch as MuiSwitch, SwitchProps as MuiSwitchProps } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { ReactNode } from 'react'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  label: {
    fontSize: '14px'
  },
  labelStart: {
    marginLeft: 0,
    marginRight: '-11px',
    flexDirection: 'row-reverse'
  },
  switchGreen: {
    '&.Mui-checked': {
      color: '#08c20b'
    },
    '&.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#08c20b'
    }
  },
  switchPurple: {
    '&.Mui-checked': {
      color: '#9933FF'
    },
    '&.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#9933FF'
    }
  }
}))

interface SwitchProps extends MuiSwitchProps {
  handleChange (value: any): any
  label?: ReactNode
  labelPlacement?: 'start' | 'end' | 'top' | 'bottom'
  type?: 'green' | 'purple'
}

export const Switch = ({ checked, handleChange, label, classes, color = 'primary', labelPlacement, disabled }: SwitchProps): JSX.Element => {
  const pageClasses = useStyles()
  const colorClass = clsx({
    [pageClasses.switchGreen]: color === 'secondary',
    [pageClasses.switchPurple]: color === 'primary'
  })

  if (!label) {
    return (
      <MuiSwitch
        checked={checked}
        onChange={(e) => handleChange(e.target.checked)}
        classes={{ ...classes, switchBase: colorClass }}
        disabled={disabled}
      />
    )
  }

  return (
    <FormControlLabel
      label={label}
      labelPlacement={labelPlacement || 'start'}
      classes={{ label: pageClasses.label, labelPlacementStart: pageClasses.labelStart }}
      control={
        <MuiSwitch
          checked={checked}
          onChange={(e) => handleChange(e.target.checked)}
          classes={{ ...classes, switchBase: colorClass }}
          disabled={disabled}
        />
      }
    />
  )
}
