/* eslint-disable @typescript-eslint/naming-convention */
import { EmailSubscription, TypeSearchParams } from 'classes/emailSubscriptions';
import { ModalSection } from 'library/Modal';
import { Checkbox, TextBox, Typography } from 'library/materialUI';
import { Dispatch, useContext } from 'react';
import EmailFrequencyModalSection from 'library/modals/emailSubscriptionModal/EmailFrequencyModalSection';
import { makeStyles } from '@material-ui/core/styles'
import Picker from 'library/Picker';
import TextFieldWithChips from 'library/TextFieldWithChips';
import { SearchParams } from 'classes/queryHelpers';
import { SessionContext } from 'session-context';
import ModalWarning from 'components/ModalWarning';
import Loader from 'library/loading/Loader';

const useStyles = makeStyles(theme => ({
  sectionHeader: {
    color: theme.palette.primary.main,
    fontWeight: 700,
    marginBottom: 10
  },
  accountCheckbox: {
    display: 'block',
    marginTop: 10,
    marginBottom: 10
  }
}))

interface SignalsScoringStep6Props {
  emailSub: EmailSubscription
  createEmail: boolean
  setEmailSub: Dispatch<any>
  setCreateEmail: (createEmail: boolean) => void
}

export default function SignalsScoringStep6 ({ emailSub, createEmail, setEmailSub, setCreateEmail }: SignalsScoringStep6Props): JSX.Element {
  const classes = useStyles()
  const { user } = useContext(SessionContext)
  const searchParams: SearchParams = TypeSearchParams(user.id, 'score_summary')
  searchParams.extraHeaders = { 'no-admin-check': 'true' }
  const { data: { list: currentEmailSubs }, isLoading } = EmailSubscription.loadAll({ searchParams: searchParams })

  return (
    <>
      <ModalSection>
        <Typography className={classes.sectionHeader} variant='h1'>Email Updates</Typography>
        <Typography style={{ marginBottom: 20 }} variant='body2'>Receive regular email updates about companies with a significant change in their Signals score</Typography>
        {isLoading ? (
          <Loader type='spinner' title='Checking for already existing email updates' />
        ) : (
          (currentEmailSubs.length > 0) && (
            <ModalWarning
              body='You already have an email update(s) for score updates. You can click below to look at your existing email updates.'
              helpButtonText='Edit Email Update(s)'
              helpLink='/#/settings/my_settings/email_updates'
            />
          )
        )}
        <Checkbox
          label='Create email update for trending companies'
          checked={createEmail}
          onChange={() => {
            setCreateEmail(!createEmail)
          }}
        />
      </ModalSection>
      {createEmail && (
        <div>
          <ModalSection
            title='Update Name'
            subtitle='Name your email update. This name will show up in the subject line'
          >
            {/* {errors?.name && (touched?.name || isSubmitting) && <p style={{ color: 'red', fontSize: '13px', margin: '0' }}>{errors.name}</p>} */}
            <TextBox
              onChange={(value: any) => setEmailSub({ name: value })}
              value={emailSub.name}
              // onBlur={() => setFieldTouched('name', true)}
            />
          </ModalSection>
          <ModalSection
            title='Accounts'
            subtitle='Which companies should be included in this update?'
          >
            <div>
              <Checkbox
                label='Potential Accounts'
                checked={emailSub.meta.potential_accounts === 1 || emailSub.meta.potential_accounts === undefined}
                onChange={() => {
                  if (emailSub.meta.potential_accounts === 0) setEmailSub({ meta: { ...emailSub.meta, potential_accounts: 1 } })
                  else setEmailSub({ meta: { ...emailSub.meta, potential_accounts: 0 } })
                }}
                disabled={emailSub.meta.known_accounts === 0}
              />
            </div>
            <div>
              <Checkbox
                label='Known Accounts'
                checked={emailSub.meta.known_accounts === 1 || emailSub.meta.known_accounts === undefined}
                onChange={() => {
                  if (emailSub.meta.known_accounts === 0) setEmailSub({ meta: { ...emailSub.meta, known_accounts: 1 } })
                  else setEmailSub({ meta: { ...emailSub.meta, known_accounts: 0 } })
                }}
                disabled={emailSub.meta.potential_accounts === 0}
              />
            </div>
          </ModalSection>
          <ModalSection
            title='Segments'
            subtitle='Which segments would you like to see score change updates from?'
          >
            <Picker
              objectType='segments'
              multiple
              selection={emailSub.meta.segment_id || []}
              setSelection={(value: any) => setEmailSub({ meta: { ...emailSub.meta, segment_id: value } })}
              placeholder='All Segments'
            />
          </ModalSection>
          <ModalSection
            title='Score Change'
            subtitle='How much does a company’s score need to change in order to show up in your email?'
          >
            <TextBox
              onChange={(value: number) => setEmailSub({ meta: { ...emailSub.meta, score_shift: value } })}
              value={emailSub.meta.score_shift}
              type='number'
            />
            <Typography variant='body2'>We recommend a change of 10</Typography>
          </ModalSection>
          <EmailFrequencyModalSection
            newEmailSubscription={emailSub}
            setNewEmailSubscription={setEmailSub}
          />
          <ModalSection
            title='Recipients'
            subtitle={'Choose who you\'d like to receive this update'}
          >
            <ModalSection
              title='Signals Users'
              subtitle='Choose Signals users to send this email to'
            >
              {/* {errors?.cfRecipients && (touched?.cfRecipients || isSubmitting) && <p style={{ color: 'red', fontSize: '13px', margin: '0' }}>{errors.cfRecipients}</p>} */}
              <Picker
                objectType='users'
                multiple
                selection={emailSub.cfRecipients || []}
                setSelection={(value: any) => setEmailSub({ cfRecipients: value })}
                // errors={!!errors.cfRecipients && !!touched.cfRecipients}
                // onBlur={() => setFieldTouched('cfRecipients', true)}
              />
            </ModalSection>
            <ModalSection
              title='Non-Signals Users'
              subtitle='Enter the email addresses of any non-Signals users'
            >
              {/* {errors?.nonCfRecipients && touched?.nonCfRecipients && <p style={{ color: 'red', fontSize: '13px', margin: '0' }}>{errors.cfRecipients}</p>} */}
              <TextFieldWithChips
                stringArray={emailSub.nonCfRecipients}
                setStringArray={(value: string[]) => setEmailSub({ nonCfRecipients: value })}
              />
            </ModalSection>
          </ModalSection>
        </div>
      )}
    </>
  )
}
