
const descriptionText = 'Add prospected contacts or return visitors to a cadence in Salesloft or remove them from one they\'re already enrolled in.'

const Description = (): JSX.Element => {
  return (
    <div>
      <div>
        {descriptionText}
      </div>
    </div>
  )
}

const BestPractices = () => {
  return (
    <div>
      <ul>
        <li>Choose whether you'd like to add someone to a cadence or simply remove them from one.</li>
        <li>Select your cadence.</li>
        <li>If you want people to be removed from other cadences before being enrolled in the selected one, check the checkbox to “allow contacts already enrolled in cadences to be re-enrolled into new ones”.</li>
        <li>You can then set exceptions. For example, if you have a higher priority cadence that you don’t want people to be removed from, you can leave them untouched.</li>
        <li>Set up your criteria for determining the Salesloft Owner. We recommend assigning by account owner first, and then setting a fallback for people who may not yet have an owner. Anyone not matching these criteria will be assigned to the default owner you designated in the integration settings.</li>
      </ul>
    </div>
  )
}

export const salesloftDocs = {
  helpCenterLink: 'https://help.getsignals.ai/',
  bestPractices: BestPractices,
  description: Description
  // videoLink: 'https://www.youtube.com/watch?v=bcZotkwMYHE&list=PL6SHkPxzWDamLhKs_lWXmkwP3W5y9yQlQ&index=3'
}
