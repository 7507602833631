import { useDoQuery } from './useDoQuery'
import { doPost } from 'api/api'
import { DateType, castToDate } from './classHelpers'

interface UserInviteList {
  list: UserInvite[]
  dict: { [id: UserInvite['id']]: UserInvite }
}

const permissionsDict: any = {
  conversations_all: {
    conversations_all: true,
    conversations_mine: true,
    conversations_unclaimed: true
  },
  conversations_unclaimed: {
    conversations_all: false,
    conversations_mine: true,
    conversations_unclaimed: true
  },
  conversations_mine: {
    conversations_all: false,
    conversations_mine: true,
    conversations_unclaimed: false
  }
}

export class UserInvite {
  readonly id: number
  email: string
  invitedByUser: number | null
  createdByUser: number
  expiredTimestamp: DateType
  invitationDate: DateType
  deletedTimestamp: DateType
  roleID: number
  teamIDs: number[]
  permissions: Record<string, boolean>

  get searchField (): string { return this.email }

  constructor ({ row }: { row?: Record<string, any> } = {}) {
    const userInvite = row?.attributes
    this.id = parseInt(row?.id)
    this.email = userInvite?.email || ''
    this.invitedByUser = parseInt(row?.relationships.invited_by?.data.id)
    this.createdByUser = userInvite?.created_user_id
    this.expiredTimestamp = castToDate(userInvite?.expired_timestamp)
    this.invitationDate = castToDate(userInvite?.created_timestamp)
    this.deletedTimestamp = castToDate(userInvite?.canceled_timestamp)
    this.roleID = userInvite.role_id
    this.teamIDs = userInvite.team_ids
    this.permissions = userInvite.permissions
  }

  static permToDict (perm: string): Record<string, boolean> {
    return permissionsDict[perm]
  }

  static loadAll (): { data: UserInviteList, isLoading: boolean } {
    return useDoQuery({ path: '/user_invites', objectClass: UserInvite })
  }

  static resend (ID: UserInvite['id'], email: UserInvite['email']): Promise<any> {
    const path = `/user_invites/${ID}/resend`
    const data = {
      id: ID,
      attributes: {
        email: email
      }
    }
    return doPost({ path, data })
  }

  static delete (ID: UserInvite['id']): Promise<any> {
    const path = `/user_invites/${ID}/cancel`
    const data = {
      id: ID
    }
    return doPost({ path, data })
  }

  static save (userInvite: Partial<UserInvite & { tenantID: number }>, tenantID?: number): Promise<any> {
    const path = tenantID ? '/admin_portal/user_invites' : '/user_invites'
    if (tenantID) {
      userInvite.tenantID = tenantID
    }
    const data = {
      type: 'user_invitations',
      attributes: userInvite
    }

    return doPost({ path, data }).then(response => {
      if (response.ok) {
        return new UserInvite({ row: response.data })
      } else {
        return response.errors
      }
    })
  }
}
