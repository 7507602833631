import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Chip, Switch, Card, CardHeader, CardContent, Collapse, IconButton, Typography } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'

const useStyles = makeStyles(theme => ({
  card: {
    border: '1px solid rgba(142, 90, 226, 0.4)',
    minHeight: 60,
    borderRadius: 10,
    backgroundColor: 'white'
  },
  switchWarning: {
    fontStyle: 'italic',
    color: '#4c4c4c'
  },
  cardAlwaysExpandend: {
    border: '1px solid rgba(142, 90, 226, 0.4)',
    minHeight: 60,
    borderRadius: 10,
    backgroundColor: 'white'
  },
  manualToggle: {
    marginLeft: 10,
    marginTop: 10
  },
  cardTitle: {
    fontSize: '17px'
  },
  cardSubtitle: {
    fontSize: '14px'
  },
  cardRoot: {
    padding: '5px 24px',
    color: '#4c4c4c'
  },
  headerDiv: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '5px 0px',
    cursor: 'pointer'
  },
  headerDivAlwaysExpanded: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '5px 0px'
  }
}))

function SettingsCard (props) {
  const classes = useStyles()
  const [expanded, setExpanded] = useState(props.expanded || false)

  const handleExpand = () => {
    setExpanded(!expanded)
  }

  return (
    <Card classes={props.alwaysExpanded ? { root: classes.cardAlwaysExpandend } : { root: classes.card }} style={{ marginBottom: 20 }}>
      {props.featureSwitch ? (
        <div className={classes.manualToggle}>
          <Chip
            label={props.enabled ? 'Feature: On' : 'Feature: Off'}
            color={props.enabled ? 'primary' : 'default'}
            className={classes.chip}
          />
          <Switch
            name='master_toggle'
            className={classes.switch}
            checked={props.enabled}
            onChange={(e) => props.toggleFeature(e.target.checked)}
          />
          {props.switchMessage ? (<Typography className={classes.switchWarning}>{props.switchMessage}</Typography>) : (<></>)}
        </div>
      ) : (<></>)}
      <div className={props.alwaysExpanded ? classes.headerDivAlwaysExpanded : classes.headerDiv} onClick={handleExpand}>
        <CardHeader
          classes={{ title: classes.cardTitle, subheader: classes.cardSubtitle }}
          title={props.title}
          subheader={props.subtitle}
        />
        {props.alwaysExpanded ? (
          <></>
        ) : (
          <div style={{ marginRight: 10 }}>
            <IconButton onClick={handleExpand}>
              {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </div>
        )}

      </div>
      {props.alwaysExpanded ? (
        <CardContent classes={{ root: classes.cardRoot }}>
          {props.children}
        </CardContent>
      ) : (
        <Collapse in={expanded} timeout='auto' unmountOnExit>
          <CardContent classes={{ root: classes.cardRoot }}>
            {props.children}
          </CardContent>
        </Collapse>)}
    </Card>
  )
}

export default SettingsCard
