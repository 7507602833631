import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import LiveViewChatBox from './LiveViewChatBox'

const useStyles = makeStyles(theme => ({
  drawerContents: {
    width: 450,
    overflowX: 'hidden',
    overflowY: 'auto',
    height: '100%'
  },
  drawerToggleButton: {
    position: 'fixed',
    bottom: 115,
    border: '1px solid rgba(0,0,0,0.1)',
    display: 'flex',
    padding: 8,
    paddingLeft: 4,
    borderTopRightRadius: 50,
    borderBottomRightRadius: 50,
    boxShadow: '2px 2px 3px #AAA',
    backgroundColor: 'white',
    cursor: 'pointer'
  }
}))

function DrawerToggleButton (props) {
  const classes = useStyles()

  const handleToggle = props.toggleDrawer

  return (
    <div
      className={classes.drawerToggleButton}
      onClick={handleToggle}
    >
      {props.drawerOpen
        ? <ChevronLeftIcon />
        : <ChevronRightIcon />}
    </div>
  )
}

function LiveViewDrawer (props) {
  const classes = useStyles(props)

  let position = 'inherit'
  if (props.open) {
    position = 'fixed'
  }

  return (
    <Drawer
      open={props.open}
      onClose={() => { }}
      PaperProps={{ style: { position: position } }}
      BackdropProps={{ style: { position: 'absolute' } }}
      ModalProps={{
        container: document.getElementById('live-view-page'),
        style: { position: 'absolute' }
      }}
      variant='persistent'
      anchor='right'
      style={{ position: 'absolute' }}
    >
      <div style={{ width: 450, overflow: 'hidden', height: '100%' }}>
        <div
          className={classes.drawerContents}
        >
          <LiveViewChatBox
            participant={props.participant}
            close={props.close}
          />
        </div>
      </div>
    </Drawer>
  )
};

export { LiveViewDrawer, DrawerToggleButton }
