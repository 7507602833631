import { doPatch } from 'api/api';
import { DateType } from './classHelpers';
import GenericObject from './genericObject';
import { useDoQuery } from './useDoQuery';

export class Tenant extends GenericObject {
  onboardingCompleteTimestamp: DateType
  onboardingIntegrations: string[]

  static load (): { data: Tenant, isLoading: boolean } {
    return useDoQuery({ path: '/tenants/me', objectClass: Tenant })
  }

  static loadAll = () => {
    return useDoQuery({
      path: '/tenants',
      objectClass: Tenant
    })
  }

  static update (ID: number, updates: Partial<Tenant>) {
    const path = `/tenants/${ID}`
    const data = {
      type: 'tenants',
      id: ID,
      attributes: updates
    }
    return doPatch({ path, data })
  }

  constructor ({ row }: { row: Record<string, any> }) {
    super({ row })
    const tenant = row.attributes
    this.onboardingCompleteTimestamp = tenant.onboarding_complete_timestamp
    this.onboardingIntegrations = tenant.onboarding_integrations
  }
}
