import { makeStyles } from '@material-ui/core/styles';
import { Icon, Typography } from 'library/materialUI';

const useStyles = makeStyles(theme => ({
  container: {
    width: 316,
    backgroundColor: theme.palette.primary.main,
    padding: 15
  }
}))

export const typeMapping = {
  conversations: 'Tip: Change the filters to get more targeted data',
  accounts: 'Tip: Accounts can be uploaded directly to a static segment',
  filters: 'Tip: Filters set before exporting will be reflected in the CSV',
  error: 'There was an error with your download'
}
interface SnackbarContentProps {
  onClose: () => void
  title: string
  type: keyof typeof typeMapping
}

export default function SnackbarContent ({ onClose, title, type } : SnackbarContentProps) {
  const classes = useStyles()
  const tip = typeMapping[type]
  return (
    <div className={classes.container}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex' }}>
          <Icon icon={type === 'error' ? 'warning' : 'checkCircle'} color='white' />
          <Typography style={{ paddingLeft: 5, paddingRight: 80, color: 'white', fontWeight: 'bold' }}>
            {title}
          </Typography>
        </div>
        <div style={{ cursor: 'pointer' }}>
          <Icon icon='close' color='white' onClick={() => onClose()} />
        </div>
      </div>
      <div>
        <Typography style={{ color: 'white', paddingTop: 10 }}>
          {tip}
        </Typography>
      </div>
    </div>
  )
}
