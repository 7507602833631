const headers = {
  'Content-Type': 'application/vnd.api+json'
}

function groupSearch ({ search, chatServiceUrl }) {
  const url = new URL(`https://${chatServiceUrl}/keyword_groups:search`)
  const params = {}
  if (search) {
    params['filter[search]'] = search
  }

  Object.keys(params).forEach(key =>
    url.searchParams.append(key, params[key])
  )

  return fetch(url, {
    method: 'GET',
    headers: headers
  })
    .then(response => response.json())
}

function deleteGroups ({ chatServiceUrl, group_ids }) {
  const method = 'DELETE'
  const url = new URL(`https://${chatServiceUrl}/keyword_groups`)
  const body = JSON.stringify({
    data: {
      attributes: {
        group_ids: group_ids
      }
    }
  })
  return fetch(url, {
    method: method,
    headers: headers,
    body: body
  })
}

export { groupSearch, deleteGroups }
