import { Typography } from 'library/materialUI'
import ChromeStep1 from 'img/chrome_sound_notif_1.png'
import ChromeStep2 from 'img/chrome_sound_notif_2.png'
import ChromeStep3 from 'img/chrome_sound_notif_3.png'

const ChromeNotificationsDescription = (): JSX.Element => {
  return (
    <div style={{ fontFamily: 'Poppins', fontSize: '14px' }}>
      <Typography variant='h2' style={{ marginBottom: '20px' }}>To allow sound notifications in Chrome, do the following:</Typography>
      <Typography variant='h2' style={{ marginBottom: '20px' }}>1. In the Chrome URL bar, click the lock icon next ot where it says "app.chatfunnels.com"</Typography>
      <img src={ChromeStep1} alt='step_1' />
      <Typography variant='h2' style={{ marginBottom: '20px', marginTop: '20px' }}>2. In the menu that appears, click "Site Settings"</Typography>
      <img src={ChromeStep2} alt='step_2' />
      <Typography variant='h2' style={{ marginBottom: '20px', marginTop: '20px' }}>3. On the settings page, find the permissions for Sound and make sure to click the dropdown and select "Allow"</Typography>
      <img src={ChromeStep3} alt='step_3' />
    </div>
  )
}

export const ChromeNotificationsDocs = {
  helpCenterLink: 'https://help.getsignals.ai/article/rigkx7qxcj-allow-sound-notifications-for-new-messages',
  description: ChromeNotificationsDescription,
  videoLink: '',
  icon: 'smChrome',
  title: 'Allow sound notifications in Chrome'
}
