import { Typography } from 'library/materialUI'
import BraveStep1 from 'img/brave_notif_1.png'
import BraveStep2 from 'img/brave_notif_2.png'
import BraveStep3 from 'img/brave_notif_3.png'

const BraveNotificationsDescription = (): JSX.Element => {
  return (
    <div style={{ fontFamily: 'Poppins', fontSize: '14px' }}>
      <Typography variant='h2' style={{ marginBottom: '20px' }}>To allow sound notifications in Brave, do the following:</Typography>
      <Typography variant='h2' style={{ marginBottom: '20px' }}>1. With Signals open, click the lock icon in the Brave URL bar next to where it says “app.chatfunnels.com”</Typography>
      <img src={BraveStep1} alt='step_1' />
      <Typography variant='h2' style={{ marginBottom: '20px', marginTop: '20px' }}>2. In the menu that appears, click “Site Settings”</Typography>
      <img src={BraveStep2} alt='step_2' />
      <Typography variant='h2' style={{ marginBottom: '20px', marginTop: '20px' }}>3. On the settings page, find the permissions for Sound and make sure to click the dropdown and select “Allow”</Typography>
      <img src={BraveStep3} alt='step_3' />
    </div>
  )
}

export const BraveNotificationsDocs = {
  helpCenterLink: 'https://help.getsignals.ai/article/rigkx7qxcj-allow-sound-notifications-for-new-messages',
  description: BraveNotificationsDescription,
  videoLink: '',
  icon: 'smBrave',
  title: 'Allow sound notifications in Brave'
}
