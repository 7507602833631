import { useState } from 'react'
import Button from '@material-ui/core/Button'
import EditFormModal from './EditFormModal'
import ConsentForm from 'classes/consentForms'
import { Table, Actions, Fields } from 'cf-components/table/Table'

const fields : Fields<ConsentForm> = [
  { label: 'Consent Form', type: 'text', name: 'name' },
  { label: 'Created', type: 'date', name: 'createdDate' },
  { label: 'Consent Message', type: 'text', name: 'subtitle', sort: false },
  { label: 'Enabled', type: 'toggle', name: 'enabled', handleToggle: ({ ID: id, value: enabled, row }) => ConsentForm.update({ ID: id, updates: { name: row.name, enabled: enabled }, changes: ['enabled'] }), sort: false }
]

export default function ConsentFormTable () : JSX.Element {
  const [editConsentForm, setEditConsentForm] = useState<ConsentForm | null>(null)
  const { data: consentForms } = ConsentForm.loadAll()
  const actions: Actions = [
    { type: 'edit', name: 'Edit Consent Form', action: row => setEditConsentForm(row) },
    { type: 'delete' }
]

  const openModal = () : void => {
    setEditConsentForm(new ConsentForm())
  }
  return (
    <>
      <Table
        rows={consentForms.list}
        fields={fields}
        actions={actions}
        columnStyles='1fr 1fr 2fr 1fr'
        noFilter
      />
      <br />
      <Button
        style={{ marginLeft: 20 }}
        onClick={openModal}
        variant='outlined'
        color='primary'
      >Add Form
      </Button>
      {editConsentForm
      ? <EditFormModal
          newForm={editConsentForm}
          onHide={() => setEditConsentForm(null)}
        /> : null}
    </>
  );
}
