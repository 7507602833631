import { doGet, doPatch, doPost } from './api'

const headers = {
  'Content-Type': 'application/vnd.api+json'
}

function getMyParticipant ({ chatServiceUrl, authHeader }) {
  let newHeaders = headers
  if (authHeader) {
    newHeaders = { ...headers, ...authHeader }
  }
  return fetch(
    `https://${chatServiceUrl}/participants:me`,
    {
      method: 'GET',
      headers: newHeaders,
      cache: 'no-store'
    }
  ).then(response => response.json())
}

function getAgents ({ chatServiceUrl }) {
  const url = new URL(`https://${chatServiceUrl}/participants`)
  return fetch(url, {
    method: 'GET',
    headers: headers
  })
    .then(response => response.json())
}

function getConversationCount ({ participantID, chatServiceUrl }) {
  const body = JSON.stringify({
    data: {
      type: 'conversation_counts_search',
      attributes: {
        participant_ids: [participantID]
      }
    }
  })
  return fetch(`https://${chatServiceUrl}/conversation_counts:search`, {
    method: 'POST',
    headers: headers,
    body: body
  })
    .then(response => response.json())
}

function getParticipant ({ participantID, chatServiceUrl, includes }) {
  const baseUrl = `https://${chatServiceUrl}/participants/${participantID}`

  const url = new URL(baseUrl)
  const params = {}
  if (includes) {
    params.include = includes.join(',')
  }

  Object.keys(params).forEach(key =>
    url.searchParams.append(key, params[key])
  )

  return fetch(url, {
    method: 'GET',
    headers: headers
  })
    .then(response => response.json())
}

export function getParticipantData ({ participantID }) {
  const path = `/participants/${participantID}/data`
  return doGet({ path, useChatURL: true })
}

export function saveParticipantData ({ state, participantID }) {
  const body = {
    type: 'participants',
    attributes: state
  }

  const path = `/participants/${participantID}/data`
  return doPatch({ path, data: body, useChatURL: true })
}

export function getActivityHistory ({ chatServiceUrl, participantID }) {
  const url = `https://${chatServiceUrl}/participants/${participantID}/activity_history`
  return fetch(url, {
    method: 'GET',
    headers: headers
  })
    .then(response => response.json())
}

export function getCompanySummary ({ chatServiceUrl, participantID }) {
  const url = `https://${chatServiceUrl}/participants/${participantID}/company_data`
  return fetch(url, {
    method: 'GET',
    headers: headers
  }).then(response => response.json())
}

export function gdprDeleteContact ({ email }) {
  const url = '/participants/gdpr/delete_request'
  const body = {
   attributes: { email: email }
  }

  return doPatch({ path: url, data: body, useChatURL: true })
}

export function generateParticipantReport ({ email }) {
  const url = '/participants/gdpr/create_report'
  const body = {
    attributes: { email: email }
  }

 return doPost({ path: url, data: body, useChatURL: true, returnText: true })
}

export { getAgents, getConversationCount, getMyParticipant, getParticipant }
