import { makeStyles } from '@material-ui/core/styles'
import { Icon } from 'library/materialUI'
import { TableContext } from 'library/table/TableProvider'
import { useContext } from 'react'

const useStyles = makeStyles((theme) => ({
  filterValueText: {
    fontSize: '12px',
    marginLeft: '25px',
    display: 'inline-flex',
    color: theme.palette.primary.main,
    whiteSpace: 'nowrap',
    alignContent: 'center',
    alignItems: 'center',
    width: 170,
    justifyContent: 'space-between'
  },
  closeBtnDiv: {
    height: '20px',
    width: '20px',
    display: 'inline'
  }
}))
interface GetFilerValuesProps {
  filterValues: number[]
  filterValue?: Function
  transformer?: Function // use this if your transformer is using react hooks (e.g. useQuery)
  filterField: string
  propsClearFilter?: Function
}
export default function GetFilterValues ({ filterValues, filterValue, filterField, propsClearFilter, transformer }: GetFilerValuesProps) {
  const { clearFilter } = useContext(TableContext)
  const valueTransformer = filterValue
  const classes = useStyles()
  const displayValue = (typeof valueTransformer === 'object' || valueTransformer instanceof Function) ? filterValues.map((value, k) => {
    let newValue
    if (typeof valueTransformer === 'object') {
      newValue = valueTransformer[value]
      if (typeof newValue === 'object') {
        if ('name' in newValue) {
          newValue = newValue.name
        }
      }
      return (<div key={k}>{newValue} <br /></div>)
    } else {
      return (<div key={k}>{valueTransformer(value)} <br /></div>)
    }
  }) : transformer instanceof Function ? transformer(filterValues) : null

  const handleClose = (e: any) => {
    clearFilter(filterField)
    if (propsClearFilter) {
      propsClearFilter(filterField)
    }
    e.stopPropagation()
  }

  return (
    <div className={classes.filterValueText}>
      <div>
        {displayValue}
      </div>
      <div className={classes.closeBtnDiv}>
        <Icon
          onClick={(e: any) => handleClose(e)}
          icon='close'
          color='primary'
          size='md'
        />
      </div>
    </div>
  )
}
