import AppPage from 'cf-components/AppPage'
import EmailTypeSelection from './EmailTypeSelection'
import AccessControl from 'components/AccessControl'
import { components } from 'session-context'

export default function EmailSelectionPage (): JSX.Element {
  const breadcrumbs = [
    {
      link: '/engagement/emails/emails',
      text: 'Email Roster'
    },
    {
      link: null,
      text: 'Email Configuration'
    }
  ]

  return (
    <AccessControl requiredComponent={components.EMAILS}>
      <AppPage
        title='Select Email Type'
        breadcrumbs={breadcrumbs}
      >
        <div style={{ padding: 20 }}>
          <EmailTypeSelection />
        </div>
      </AppPage>
    </AccessControl>
  )
}
