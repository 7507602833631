import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import InputBase from '@material-ui/core/InputBase'
import { Divider, IconButton, Chip, Paper, Select, MenuItem, Checkbox } from '@material-ui/core'
import ListItemText from '@material-ui/core/ListItemText'
import DeleteIcon from '@material-ui/icons/HighlightOff'
import AddBoxIcon from '@material-ui/icons/AddBox'

const useStyles = makeStyles(theme => ({
  deleteIcon: {
    fill: 'white'
  },
  paper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    border: '1px solid #ccc',
    borderRadius: '8px',
    marginTop: 10,
    boxShadow: 'none'
  },
  verticalDivider: {
    height: 28
  },
  iconButton: {
    padding: 6
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    color: 'rgba(0,0,0,0.87)',
    fontSize: '13px',
    paddingTop: 3,
    paddingLeft: 5
  }
}))

const ITEM_HEIGHT = 40
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 5.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
}

function KeywordGroupSelector (props) {
  const classes = useStyles()
  const handleCreate = props.createNewGroup

  return (
    <Paper className={classes.paper}>
      <Select
        multiple
        fullWidth
        displayEmpty
        value={props.keywordOptions.map(option => option.keywordGroupID)}
        onChange={props.handleChange}
        MenuProps={MenuProps}
        renderValue={() => (<div style={{ marginLeft: 15, color: '#7C7C7C', fontSize: '14px' }}>Select an existing group or click the plus to add a new group</div>)}
        disableUnderline
      >
        <MenuItem disabled value=''>-- Add an existing keyword group --</MenuItem>
        {props.keywordGroups.map((group) => (
          <MenuItem key={group.id} value={group.id} style={{ height: 40 }}>
            <Checkbox checked={props.keywordOptions.map(option => option.keywordGroupID).indexOf(group.id) > -1} color='primary' />
            <ListItemText primary={group.attributes.name + ' (' + group.attributes.description + ')'} />
          </MenuItem>
        ))}
      </Select>
      <Divider className={classes.verticalDivider} orientation='vertical' />
      <IconButton
        color='primary'
        className={classes.iconButton}
        onClick={handleCreate}
      >
        <AddBoxIcon />
      </IconButton>
    </Paper>
  )
}

function ButtonTextField (props) {
  const classes = useStyles()

  const [label, setLabel] = useState('')

  function addButton () {
    props.addButton(label)
    setLabel('')
  }

  function remapEnterKey (event) {
    if (event.key === 'Enter') {
      addButton()
    }
  }

  return (
    <Paper className={classes.paper}>
      <InputBase
        className={classes.input}
        placeholder='Enter Button Text...'
        margin='dense'
        value={label}
        onChange={e => setLabel(e.target.value)}
        onKeyPress={remapEnterKey}
      />
      <Divider className={classes.verticalDivider} orientation='vertical' />
      <IconButton
        color='primary'
        className={classes.iconButton}
        onClick={addButton}
      >
        <AddBoxIcon />
      </IconButton>
    </Paper>
  )
}

function DeletableChip (props) {
  const classes = useStyles()
  const { onDelete, value } = props
  return (
    onDelete ? (
      <Chip
        value={value}
        deleteIcon={
          <div onMouseDown={(event) => {
            if (!props.disabled && event.button === 0) {
              event.stopPropagation()
              onDelete(value)
            }
          }}
          >
            <DeleteIcon
              className={classes.deleteIcon}
              style={{ margin: '0px 5px 0px 0px' }}
            />
          </div>
        }
        {...props}
      />
    ) : (<Chip {...props} />)
  )
}

export { DeletableChip, ButtonTextField, KeywordGroupSelector }
