import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

const useStyles = makeStyles(theme => ({
  bodyText: {
    marginBottom: 25,
    fontSize: 16
  },
  subtitle1: {
    fontSize: 18,
    fontWeight: 600,
    marginBottom: 15
  },
  subtitle2: {
    fontSize: 15,
    fontWeight: 600,
    marginBottom: 10
  },
  delaySelect: {
    width: 250,
    height: 40
  },
  menuHeader: {
    borderBottom: '1px solid #BBB',
    width: '100%',
    fontWeight: 600,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: 'rgba(0,0,0,0.87)'
  }
}))

const delayTimes = [
  { delay_seconds: 0, name: 'No delay' },
  { delay_seconds: 60, name: '1 minute' },
  { delay_seconds: 120, name: '2 minutes' },
  { delay_seconds: 300, name: '5 minutes' },
  { delay_seconds: 600, name: '10 minutes' },
  { delay_seconds: 900, name: '15 minutes' },
  { delay_seconds: 1800, name: '30 minutes' },
  { delay_seconds: 2700, name: '45 minutes' },
  { delay_seconds: 3600, name: '1 hour' }
]

const DelayDropDown = (props) => {
  const classes = useStyles()
  const value = props.value

  return (
    <>
      <div>
        <Select
          name='delay_time'
          variant='outlined'
          margin='dense'
          defaultValue='preview'
          className={classes.delaySelect}
          value={value}
          onChange={props.handleEdit}
        >
          <MenuItem disabled style={{ opacity: 1 }}>
            <div className={classes.menuHeader}>Delay Time</div>
          </MenuItem>
          {delayTimes.map((row, index) => (
            <MenuItem key={index} value={row.delay_seconds}>
              {row.name}
            </MenuItem>
          ))}
        </Select>
      </div>
    </>
  )
}

export default DelayDropDown
