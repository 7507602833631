import { Select } from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'
import { Fields } from 'cf-components/table/CardContents'
import { Actions } from 'cf-components/table/Table'
import { CSVDownload } from 'classes/csvdownload'
import { defaultSearchParams, QueryFilterList, SearchParams, QueryFilter, AppendFilters, RemoveFilter } from 'classes/queryHelpers'
import DateFormat from 'components/DateFormat'
import { Emitter, EVENT_TYPE } from 'emitter'
import { downloadCSV } from 'helpers/csv'
import { capitalize, toCapitalizedWords } from 'library/helpers'
import { createContext, ReactNode, useEffect, useMemo, useReducer, useState } from 'react'
import { getInitialState, toggleSortOrder } from './tableHelpers'
import { FilterOptions } from './FilterOption'

type Tab = {
  name: (total: string) => JSX.Element | string
  searchParamFilters: QueryFilter[][]
}
interface TableValues {
  fields: Fields
  rows: any
  totalRows: number
  isLoading: boolean
  isLoadingCount: boolean
  style: any
  gridTemplateColumns: string
  searchParams: SearchParams
  filterOptions: FilterOptions
  handleSort: (column: string) => void
  handleSearch: (value: string) => void
  handleFilter: (value: QueryFilterList) => void
  handlePageSize: (value: number) => void
  handleNewParams: (value: SearchParams) => void
  handleCSVDownload: () => void
  objectClass: any
  total: number,
  actions: any,
  getNextPage: () => void,
  hasNextPage: boolean,
  filterOptionValues: Record<any, any>
  clearFilters: () => void
  clearFilter: (value: string) => void
  noEmail: boolean
  noExport: boolean
  specialSortComponent: JSX.Element | null
  filterComponent: JSX.Element | undefined
  setSelectedParams: (value: SearchParams) => void
  tabs: Tab[]
  setCustomClass: any
  setNoExport: any
  setCustomParams: any
}

export const TableContext = createContext<TableValues>({
  fields: [],
  rows: [],
  totalRows: 0,
  isLoading: false,
  isLoadingCount: false,
  style: '',
  gridTemplateColumns: '',
  searchParams: defaultSearchParams,
  filterOptions: {},
  handleSort: () => undefined,
  handleSearch: () => undefined,
  handleFilter: () => undefined,
  handlePageSize: () => undefined,
  handleNewParams: () => undefined,
  handleCSVDownload: () => undefined,
  objectClass: '',
  total: 0,
  actions: [],
  getNextPage: () => undefined,
  hasNextPage: false,
  filterOptionValues: {},
  clearFilters: () => undefined,
  clearFilter: () => undefined,
  noEmail: false,
  noExport: false,
  specialSortComponent: null,
  filterComponent: undefined,
  setSelectedParams: () => undefined,
  tabs: [],
  setNoExport: () => undefined,
  setCustomClass: () => undefined,
  setCustomParams: () => undefined
})

export interface TableProps<Class> {
  // tableOnly: boolean
  ObjectClass: any
  fields: Fields<Class>
  initialSearchParams?: SearchParams
  filterOptions?: FilterOptions
  filterComponent?: JSX.Element
  actions: Actions<Class>
  gridTemplateColumns: string
  noEmail?: boolean
  noExport?: boolean
  noSort?: boolean
  noSearch?: boolean
  specialSort?: string[]
  tabs?: Tab[]
  component?: any
  extraLoadProps?: any
}

interface TableProviderProps<Class> extends TableProps<Class> {
  children: ReactNode
}

const reducer = (state: SearchParams, action: { type: string, value?: any }): SearchParams => {
  switch (action.type) {
    case 'sort':
      return {
        ...state,
        sortOrder: action.value === state.sortColumn ? toggleSortOrder(state.sortOrder) : state.sortOrder,
        sortColumn: action.value,
        pageNumber: 1
      }
    case 'search':
      return {
        ...state,
        search: action.value
      }
    case 'filter':
      return {
        ...state,
        filters: AppendFilters(state.filters || [], action.value)
      }
    case 'clearAllFilters':
      return {
        ...state,
        filters: []
      }
    case 'clearFilter':
      return {
        ...state,
        filters: RemoveFilter(state.filters || [], action.value)
      }
    case 'page[size]':
      return {
        ...state,
        pageSize: action.value
      }
    case 'new':
      return action.value
  }
  return state
}

export function TableProvider<Class> (props: TableProviderProps<Class>): JSX.Element {
  const [searchParams, dispatch] = useReducer(reducer, getInitialState(props.fields, props.initialSearchParams))
  const { data, getNextPage, isLoading, hasNextPage, hasFetchedNextPage, setHasFetchedNextPageToFalse } = props.ObjectClass.loadAll(props.component ? { component: (props.component), searchParams } : { searchParams: searchParams }, props.extraLoadProps ? props.extraLoadProps : undefined)
  const [csvIsDownloading, setCsvIsDownloading] = useState(false)
  const [csv, setCSV] = useState(null)
  const [filterOptionValues, setFilterOptionValues] = useState({})
  const className = data?.list?.length > 0 ? capitalize(data?.list[0]?.objectType) : ''
  const { data: totalRows, isLoading: isLoadingCount } = props.ObjectClass.loadCount ? props.ObjectClass.loadCount(props.component ? { component: (props.component), searchParams } : { searchParams: searchParams }, props.extraLoadProps ? props.extraLoadProps : undefined) : { data: data?.meta?.page?.total, isLoading: false }
  const [scroll, setScroll] = useState(0)
  const [selectedParams, setSelectedParams] = useState<SearchParams>()
  const [customClass, setCustomClass] = useState<string>()
  const [noExport, setNoExport] = useState<boolean>(props.noExport || false)
  const [customParams, setCustomParams] = useState<SearchParams>()
  const fields = useMemo(() => props.fields.map(f => (
    { ...f, label: f.label || toCapitalizedWords(f.name as string) }
  )), [props.fields])
  const handleSort = (column: string) => dispatch({ type: 'sort', value: column })
  const handleSearch = (value: string) => dispatch({ type: 'search', value })
  const handleFilter = (value: any) => dispatch({ type: 'filter', value })
  const handlePageSize = (value: number) => dispatch({ type: 'page[size]', value })
  const clearFilters = () => dispatch({ type: 'clearAllFilters' })
  const clearFilter = (value: string) => dispatch({ type: 'clearFilter', value })
  const handleNewParams = (value: SearchParams) => dispatch({ type: 'new', value })
  const handleCSVDownload = props.ObjectClass.download
    ? async function () {
      setCsvIsDownloading(true)
      const params = customParams !== undefined ? customParams : (selectedParams !== undefined && selectedParams.filters && selectedParams?.filters[0][0].value.length > 0) ? selectedParams : searchParams
      const resp = await props.ObjectClass.download(params, props.extraLoadProps)
      setCSV(resp)
    }
    : async function () {
      setCsvIsDownloading(true)
      const resp = await CSVDownload.download({ type: customClass || className, searchParams: customParams !== undefined ? customParams : (selectedParams !== undefined && selectedParams.filters && selectedParams?.filters[0][0].value.length > 0) ? selectedParams : searchParams })
      setCSV(resp)
    }

  const specialSortOptions = props.specialSort ? props.specialSort.map(sortValue => {
    return <MenuItem key={sortValue} value={sortValue}>{sortValue}</MenuItem>
  }) : []

  const specialSortComponent = specialSortOptions.length > 0 ? (
    <Select
      onChange={(event: any) => { if (event.target.value) { handleSort(event.target.value) } }}
      value={searchParams.sortColumn}
    >
      {specialSortOptions}
    </Select>
  ) : null

  useEffect(() => {
    const newObj: Record<string, any> = {}
    if (searchParams) {
      if (searchParams.filters) {
        searchParams.filters.forEach((filter) => {
          filter.forEach((queryFilter) => {
            newObj[queryFilter.field] = queryFilter.value
          })
        })
      }
      setFilterOptionValues(newObj)
    } // eslint-disable-next-line
  }, [searchParams.filters, searchParams])

  useEffect(() => {
    setCsvIsDownloading(false)
    Emitter.emit(EVENT_TYPE.CSV_DOWNLOAD, { csvIsDownloading: false })
    if (csv) {
      const date = new Date().toString()
      const exportClass = (customClass?.toLowerCase() || className.toLowerCase()) + 's'
      const csvType = exportClass
      const filename = 'signals_' + csvType + '_' + DateFormat({ dateToFormat: date, format: 'isoDate' }) + '.csv'
      downloadCSV(filename, csv)
      setCSV(null)
    }
  }, [csv, customClass, className])

  useEffect(() => {
    if (csvIsDownloading) {
      Emitter.emit(EVENT_TYPE.CSV_DOWNLOAD, { csvIsDownloading })
    }
  }, [csvIsDownloading])

  const mainPageDiv = document.getElementById('main-pages')
  useEffect(() => {
    if (!isLoading && mainPageDiv !== null && hasFetchedNextPage) {
      mainPageDiv.scrollTop = scroll
      setHasFetchedNextPageToFalse()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainPageDiv, isLoading, hasFetchedNextPage, scroll])

  const handleGetNextPage = () => {
    if (mainPageDiv) {
      setScroll(mainPageDiv.scrollTop)
    }
    getNextPage()
  }

  return (
    <TableContext.Provider value={{
      fields,
      rows: data?.list,
      totalRows,
      isLoading,
      isLoadingCount,
      style: { gridTemplateColumns: props.gridTemplateColumns },
      gridTemplateColumns: props.gridTemplateColumns,
      searchParams,
      filterOptions: props.filterOptions || {},
      handleSort,
      handleSearch,
      handleFilter,
      handlePageSize,
      handleNewParams,
      handleCSVDownload,
      objectClass: props.ObjectClass,
      total: totalRows,
      actions: props.actions,
      getNextPage: handleGetNextPage,
      hasNextPage: hasNextPage,
      filterOptionValues: filterOptionValues,
      clearFilters,
      clearFilter,
      noEmail: props.noEmail || false,
      noExport,
      specialSortComponent,
      filterComponent: props.filterComponent,
      setSelectedParams,
      tabs: props.tabs || [],
      setCustomClass,
      setNoExport,
      setCustomParams
    }}
    >
      {props.children}
    </TableContext.Provider>
  )
}
