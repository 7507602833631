import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Fab from '@material-ui/core/Fab'
import EditableName from 'components/EditableName'

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 'calc(100% - 55px)',
    color: 'rgba(0, 0, 0, 0.8)',
    alignItems: 'center',
    padding: '30px 30px 15px 25px',
    boxShadow: '0px 0px 1px 0px rgba(0,0,0,0.5)',
    height: 40
  },
  actionDiv: {
    display: 'flex',
    alignItems: 'center'
  },
  actionText: {
    color: 'rgba(0,0,0, 0.65)',
    marginRight: 15
  },
  backActionText: {
    color: 'rgba(0,0,0, 0.65)',
    marginLeft: 15
  },
  fab: {
    backgroundColor: '#AA88E2',
    color: 'white'
  },
  title: {
    fontSize: 24,
    fontWeight: 100
  }
}))

function TestPageHeader (props) {
  const classes = useStyles()
  const steps = props.steps
  const activeStep = props.activeStep

  return (
    <div className={classes.header}>
      <div className={classes.actionDiv}>
        <Fab
          size='small'
          color='primary'
          aria-label={props.backActionText}
          onClick={() => props.backAction()}
          classes={{ root: classes.fab }}
          disabled={activeStep === 0 || activeStep == null}
        >
          {props.backIcon}
        </Fab>
        <div className={classes.backActionText}>
          <Typography variant='h6'>
            {props.backActionText}
          </Typography>
        </div>
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div className={classes.title}>
          Test Name:
        </div>
        <EditableName name={props.title} editName={(value) => props.handleChange({ attr: 'name', value: value })} />
      </div>
      <div className={classes.actionDiv}>
        <div className={classes.actionText}>
          <Typography variant='h6'>
            {props.actionText}
          </Typography>
        </div>
        <Fab
          size='small'
          color='primary'
          aria-label={props.actionText}
          onClick={activeStep === steps.length - 1 ? props.save : props.action}
          classes={{ root: classes.fab }}
          disabled={props.disabled}
        >
          {activeStep === steps.length - 1 ? props.finishIcon : props.icon}
        </Fab>
      </div>
    </div>
  )
}

export default TestPageHeader
