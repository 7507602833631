import WarningRoundedIcon from '@material-ui/icons/WarningRounded'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CancelIcon from '@material-ui/icons/Cancel'
import ErrorIcon from '@material-ui/icons/Error'
import ScheduleIcon from '@material-ui/icons/Schedule'

const calendarValues = {
  linked: {
    icon: CheckCircleIcon,
    style: { color: 'rgba(0, 135, 7, 0.7)', paddingLeft: 14 },
    tooltip: 'User\'s calendar is linked successfully'
  },
  never: {
    icon: CancelIcon,
    style: { color: 'rgba(219, 46, 46, 0.7)', paddingLeft: 14 },
    tooltip: 'User has never linked their calendar'
  },
  meetingInfoMissing: {
    icon: ErrorIcon,
    style: { color: 'rgba(255, 188, 3)', paddingLeft: 14 },
    tooltip: 'Meeting settings are missing.'
  },
  noMeetingPermission: {
    icon: CancelIcon,
    style: { color: 'rgba(219, 46, 46, 0.7)', paddingLeft: 14 },
    tooltip: 'User does not have permission to book meetings'
  }
}

const mfaValues = {
  true: {
    icon: CheckCircleIcon,
    style: { color: 'rgba(0, 135, 7, 0.7)', paddingLeft: 0 },
    tooltip: 'User has set up MFA'
  },
  false: {
    icon: ErrorIcon,
    style: { color: 'rgba(255, 188, 3)', paddingLeft: 0 },
    tooltip: 'User has not yet set up MFA'
  }
}

const availabilityIcon = {
  never: {
    icon: WarningRoundedIcon,
    style: { color: '#AAA', paddingLeft: 17 },
    tooltip: 'User has never logged into chat'
  },
  noChatPermission: {
    icon: WarningRoundedIcon,
    style: { color: 'rgba(255, 188, 3)', paddingLeft: 17 },
    tooltip: 'User does not have permission to send chats'
  },
  OutOfOffice: {
    icon: ScheduleIcon,
    style: { color: '#8e5ae2', paddingLeft: 17 },
    tooltip: 'User is out of the office'
  }
}

export { availabilityIcon, mfaValues, calendarValues }
