import { doPut } from 'api/api'
import { GenericObject } from './genericObject'
import { useDoQuery } from './useDoQuery'

export class RoutingGroup {
  filters: { prop: string, cmp: string, value: string }[]
  constructor ({ row }: { row: Record<string, any> }) {
    this.filters = row.filters
  }
}

export class RoutingRule {
  id: string
  name: string
  method: string
  objectIDs?: number[]
  groups: RoutingGroup[]
  constructor ({ row }: { row: Record<string, any> }) {
    this.id = row.id || crypto.randomUUID()
    this.name = row.name
    this.method = row.method
    this.objectIDs = row.object_ids
    this.groups = row.groups
  }
}
export class GlobalFallback {
  method?: string
  objectIDs?: number[]
  filters?: { prop: string, cmp: string, value: string }[]
  constructor ({ row }: { row: Record<string, any> }) {
    this.method = row?.method
    this.objectIDs = row?.object_ids
    this.filters = row?.filters
  }
}

export class RoutingRules extends GenericObject {
  rules: RoutingRule[]
  globalFallback: GlobalFallback

  static loadOne (): { data: RoutingRules; isLoading: boolean } {
    return useDoQuery({
      useChatURL: true,
      path: '/routing/rules/global',
      objectClass: RoutingRules
    })
  }

  static update (rules: RoutingRule[], fallbackRule: GlobalFallback) {
    const path = '/routing/rules/global'
    const data = {
      attributes: {
        rules: rules
          .filter((rule) => rule.groups && rule.groups.length > 0)
          .map((rule) => {
            return {
              id: rule.id,
              name: rule.name,
              object_ids: rule.objectIDs,
              method: rule.method,
              groups: rule.groups
            }
          }),
        global_fallback: { object_ids: fallbackRule?.objectIDs, method: fallbackRule?.method }
      }
    }
    return doPut({ path, data, useChatURL: true })
  }

  constructor ({ row }: { row: Record<string, any> }) {
    super({ row })
    this.rules = row.attributes.rules.map((rule: any) => new RoutingRule({ row: rule }))
    this.globalFallback = new GlobalFallback({ row: row.attributes.global_fallback })
  }
}
