import { makeStyles } from '@material-ui/core/styles'
import Card from 'library/materialUI/Card'
import AccountOwnerRules from '../AccountOwnerRules'
import { useContext, useEffect } from 'react'
import { TabContext } from '../IntegrationPage/TabProvider'
import { IntegrationPageContext } from '../IntegrationPage/IntegrationPageProvider'
import { Button, Typography } from 'library/materialUI'

const useStyles = makeStyles((theme) => ({
  subtitle1: {
    fontSize: 18,
    fontWeight: 600,
    marginBottom: 15
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  bodyText: {
    marginBottom: 20,
    fontSize: 16
  }
}))

interface OwnershipCardProps {
  save: any
  allowABE: boolean
  abmSyncSettings: any
  handleABMSettingsChange: any
}

export default function OwnershipCard ({ save, allowABE, abmSyncSettings, handleABMSettingsChange }: OwnershipCardProps) {
  const classes = useStyles()
  const { setCanSave, shouldSave } = useContext(TabContext)
  const { handleSaved } = useContext(IntegrationPageContext)

  useEffect(() => {
    if (shouldSave) {
      save()
      handleSaved()
    }
  })

  return (
    <Card
      content={
        <>
          {allowABE ? (
            <AccountOwnerRules
              integrationName='Hubspot'
              allowLeads={false}
              syncSettings={abmSyncSettings}
              handleChange={handleABMSettingsChange}
              label='Account Ownership Rules'
              description='Configure how your organization will determine the account owner.'
              rightLabel='Hubspot User Field'
              providerSetSave={setCanSave}
            />
          ) : (
            <>
              <div className={classes.header}>
                <Typography className={classes.subtitle1}>
                  Hubspot not set as ABE Account/Opportunity Sync integration
                </Typography>
              </div>
              <Typography variant='body2' className={classes.bodyText}>
                To set ownership rules, you must have HubSpot set as your ABE Account/Opportunity Sync integration
              </Typography>
              <Button href='#/settings/integrations/settings' variant='outlined'>GO TO INTEGRATION SETTINGS</Button>
            </>
          )}
        </>
      }
    />
  )
}
