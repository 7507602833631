import React from 'react'
import { Button } from '@material-ui/core'
import FunnelPup from 'img/funnel_pup.svg'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  container: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  errorBody: {
    alignSelf: 'center',
    textAlign: 'center'
  },
  funnelPup: {
    height: 350
  },
  errorTitle: {
    fontSize: 32,
    color: theme.palette.primary.light,
    fontWeight: 400
  },
  errorSubtitle: {
    fontSize: 16,
    marginTop: 5,
    color: '#777'
  },
  button: {
    marginTop: 15
  }
}))

const ErrorHandler = ({ error, resetErrorBoundary }) => {
  const classes = useStyles()
  console.error(error)
  return (
    <div className={classes.container}>
      <div className={classes.errorBody}>
        <div>
          <img src={FunnelPup} className={classes.funnelPup} alt='Error' />
        </div>
        <div className={classes.errorTitle}>
          Oops!
        </div>
        <div className={classes.errorSubtitle}>
          Something went wrong. Please try again.
        </div>
        <div>
          <Button
            className={classes.button}
            variant='contained'
            color='primary'
            onClick={resetErrorBoundary}
          >Try again
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ErrorHandler
