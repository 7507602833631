import { makeStyles } from '@material-ui/core/styles'
import { SectionProps } from '../HelpCenter'
import { SectionCenter } from '../SectionCenter'
import { useContext, useEffect, useState } from 'react'
import { HelpCenterContext } from '../HelpCenterContext'
import { HelpCenterCard, THelpCenterCard } from '../HelpCenterCard'
import { HistoryItem } from './HistoryItem'
import { getCases } from 'api/integrations'
import { User } from 'classes/users'
import { Emitter, EVENT_TYPE } from 'emitter'

const useStyles = makeStyles(theme => ({
  headerText: {
    font: 'normal normal 600 24px/35px Poppins',
    color: '#000000BF'
  }
}))

interface HistoryHomeProps {
  cardList: THelpCenterCard[]
}

export function HistoryHome ({ cardList }: HistoryHomeProps): JSX.Element {
  const classes = useStyles()
  const { navigateBack } = useContext(HelpCenterContext)
  return (
    <div>
      <div className={classes.headerText} onClick={navigateBack}>
        Support History
      </div>
      <div>
        {cardList?.map((card: THelpCenterCard, index: number) => (
          <HelpCenterCard key={index} card={card} />
        ))}
      </div>
    </div>
  )
}

export function HistoryCenter (props: SectionProps) {
  const { customProps } = useContext(HelpCenterContext)
  const { data: user } = User.loadMe()
  const [cards, setCards] = useState<THelpCenterCard[]>([])
  const [sectionMapping, setSectionMapping] = useState({})

  useEffect(() => {
    getCases(user.email)
      .then((response) => {
        if (response) {
          response.forEach((c: any) => {
            setCards((prev: any) => [...prev, {
              title: c.subject,
              subtitle: `Status: ${c.status}`,
              icon: c.type === 'bug' ? 'reportProblem' : 'lightBulbWhite',
              link: c.subject,
              isClosed: c.is_closed
            }])
            setSectionMapping((prev: any) => ({ ...prev, [c.subject]: HistoryItem.bind(null, { title: c.subject, status: c.status, isClosed: c.isClosed, type: c.type, caseNumber: c.caseNumber, description: c.description, createdDate: c.createdDate, caseHistory: c.history }) }))
          })
        }
      })
  }, [user])

  useEffect(() => {
    if (customProps?.pathOnLoad) {
      for (const mapping in sectionMapping) {
        if (mapping === customProps?.pathOnLoad) {
          Emitter.emit(EVENT_TYPE.HELP_CENTER, { path: `home/feedback/Support History/${customProps.pathOnLoad}` })
          customProps.pathOnLoad = null;
          return
        }
      }
    }
    // eslint-disable-next-line
  }, [sectionMapping])

  return (
    <SectionCenter
      homeComponent={<HistoryHome cardList={cards} />}
      links={props.links}
      sectionMapping={sectionMapping}
    />
  )
}
