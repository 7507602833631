import { Card } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { ActionButton } from 'library/materialUI'
import { IconType } from 'library/materialUI/Icon'

const useStyles = makeStyles(theme => ({
  accountCards: {
    marginBottom: 15
  },
  cardTitle: {
    fontSize: 24,
    fontWeight: 600,
    marginBottom: 20
  },
  cardContent: {
    padding: 15
  },
  halfWidth: {
    width: '50%'
  },
  removeMargin: {
    margin: 0
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}))

interface cardProps {
  title: string
  halfWidth?: boolean
  removeMargin?: boolean
  children: JSX.Element
  action?: () => void
  icon?: IconType
}

export const AdminPortalCard = (props: cardProps): JSX.Element => {
  const classes = useStyles()
  return (
    <Card raised className={`${classes.accountCards} ${props.halfWidth ? classes.halfWidth : undefined}`}>
      <div className={classes.cardContent}>
        <div className={classes.flex}>
          <div className={`${classes.cardTitle} ${props.removeMargin ? classes.removeMargin : undefined}`}>{props.title}</div>
          {props.action && <ActionButton action={{ action: props.action, icon: props.icon ? props.icon : '' }} />}
        </div>
        {props.children}
      </div>
    </Card>
  )
}
