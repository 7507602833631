import { makeStyles, withStyles } from '@material-ui/core/styles'
import { Badge } from 'library/materialUI'
import { useEffect, useState } from 'react'

const useStyles = makeStyles(theme => ({
  badge: ({
    height: '20px',
    minWidth: '20px',
    padding: 0,
    backgroundColor: '#7DD461',
    boxShadow: '0 0 0 2px #7DD461',
    cursor: 'pointer'
  }),
  pos: {
    position: 'absolute'
  }
}))

const PulseBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: '#7DD461',
    color: 'white',
    fontSize: '18px',
    fontFamily: 'poppins',
    '&::after': {
      position: 'absolute',
      top: -2,
      left: -2,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '2px solid currentColor',
      content: '""'
    }
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(0.8)',
      opacity: 1
    },
    '100%': {
      transform: 'scale(1.8)',
      opacity: 0
    }
  }
}))(Badge)

interface OnboardingPromptProps {
  anchorID: any,
  xOffset: number,
  yOffset: number,
  children: React.ReactElement<any, any>
  visible: boolean
  hidden: boolean
  setHidden: (value: boolean) => void
}

export default function OnboardingPrompt ({ anchorID, xOffset, yOffset, children, visible, hidden, setHidden }: OnboardingPromptProps): JSX.Element {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const target = document.getElementById(anchorID)
  const targetRect = target?.getBoundingClientRect()
  const topVal = (targetRect?.top || 0) + yOffset
  const leftVal = (targetRect?.left || 0) + xOffset
  const [time, setTime] = useState(1)

  useEffect(() => {
    const interval = setInterval(() => setTime(time * -1), 500)
    return () => {
      clearInterval(interval)
    }
  })

  useEffect(() => {
    if (!visible) {
      setHidden(false)
    } else {
      setHidden(open)
    }
  // eslint-disable-next-line
  }, [open, visible])

  return (
    <>
      {(visible) && (
        <div style={{ position: 'absolute', top: topVal, left: leftVal, zIndex: 1500 }}>
          {(!open && !hidden) && (
            <PulseBadge
              overlap='circular'
              badgeContent='?'
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              classes={{ badge: classes.badge }}
              onClick={() => setOpen(true)}
            />)}
          {open && children}
        </div>
      )}
    </>
  )
}
