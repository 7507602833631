/* eslint-disable @typescript-eslint/naming-convention */
import { useContext, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import MeetingsCard from './MeetingsCard'
import SegmentsCard from './SegmentsCard'
import { getAccountPieCharts } from 'api/account_management'
import PieChartCard from './PieChartCard'
import SummaryTimelineCard from './SummaryTimelineCard'
import PieChartModal from './PieChartModal'
import DashboardTabCard from './DashboardTabCard'
import PerformanceCard from './PerformanceCard'
import { MyRecentConversationsCard } from './RecentConversationsCard'
import { components, SessionContext } from 'session-context'
import AccessControl from 'components/AccessControl'

const useStyles = makeStyles(theme => ({
  dashboardCards: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateAreas: `
    "timeline timeline"
    "charts charts"
    "left right"`,
    gridGap: 20
  },
  column: {
    display: 'grid',
    gridGap: 20
  },
  charts: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridGap: 20
  },
  dashboardCard: {
    marginBottom: '20px'
  }
}))

interface ABEDashboardProps {
  chatServiceUrl: string
  modalOpen: string | null
  setPotentialAccountModalOpen: (value: boolean) => void
  setModalDomain: (value: string) => void
  accountAdded: string[]
}

function PieCharts (props: any) {
  const chartData = props.chartData
  const setTopIndustriesModalOpen = props.setTopIndustriesModalOpen
  const setSizesModalOpen = props.setSizesModalOpen
  const setTopRevenuesModalOpen = props.setTopRevenuesModalOpen

  return (
    <div style={{ display: 'flex', flexDirection: 'row', gap: '20px' }}>
      <div style={{ width: '100%' }}>
        <PieChartCard
          title='Top Visitor Industries'
          data={chartData.industry_data}
          id='industry-pie-chart'
          onFullScreen={() => setTopIndustriesModalOpen(true)}
        />
      </div>
      <div style={{ width: '100%' }}>
        <PieChartCard
          title='Top Visitor Company Sizes'
          data={chartData.employee_data}
          id='employee-pie-chart'
          onFullScreen={() => setSizesModalOpen(true)}
        />
      </div>
      <div style={{ width: '100%' }}>
        <PieChartCard
          title='Top Visitor Company Revenues'
          data={chartData.revenue_data}
          id='revenue-pie-chart'
          onFullScreen={() => setTopRevenuesModalOpen(true)}
        />
      </div>
    </div>

  )
}

function SegmentsAndMeetings (props: any) {
  const chatServiceUrl = props.chatServiceUrl

  return (
    <div style={{ display: 'flex', flexDirection: 'row', gap: '20px' }}>
      <AccessControl requiredComponent={components.ACCOUNTS_TABLE}>
        <div id='segment-card' style={{ width: '100%' }}>
          <SegmentsCard
            chatServiceUrl={chatServiceUrl}
          />
        </div>
      </AccessControl>
      <AccessControl requiredComponent={components.CALENDAR}>
        <div id='meeting-card' style={{ width: '100%' }}>
          <MeetingsCard
            chatServiceUrl={chatServiceUrl}
            admin
          />
        </div>
      </AccessControl>
    </div>
  )
}

function PerformanceAndRecent (props: any) {
  const user = props.user
  const chatServiceUrl = props.chatServiceUrl

  return (
    <div style={{ display: 'flex', flexDirection: 'row', gap: '20px' }}>
      <div id='performance-card' style={{ width: '100%' }}>
        <PerformanceCard user={user} />
      </div>
      <div id='conversation-card' style={{ width: '100%' }}>
        <MyRecentConversationsCard
          chatServiceUrl={chatServiceUrl}
        />
      </div>
    </div>
  )
}

function ABEDashboard ({ chatServiceUrl, setPotentialAccountModalOpen, setModalDomain, accountAdded }: ABEDashboardProps) {
  const classes = useStyles()
  const mywindow = window as any
  const { user } = useContext(SessionContext)
  const [chartData, setChartData] = useState({
    employee_data: null,
    industry_data: null,
    revenue_data: null
  })

  // Modals
  const [topIndustriesModalOpen, setTopIndustriesModalOpen] = useState(false)
  const [topSizesModalOpen, setSizesModalOpen] = useState(false)
  const [topRevenuesModalOpen, setTopRevenuesModalOpen] = useState(false)

  // For dashboard card-moving to be implemented later
  // const [dashboardOrder, setDashboardOrder] = useState(
  //   ['TabCard', 'SummaryTimeline', 'PieCharts', 'SegmentsAndMeetings', 'PerformanceAndRecent']
  // )

  useEffect(() => {
    getAccountPieCharts()
      .then(response => setChartData(response.data.attributes))
  }, [])

  useEffect(() => {
    try {
      const ids = ['ip-lookup-banner']
      for (const id of ids) {
        const d = document.getElementById(id)
        mywindow.htmx.process(d)
      }
    } catch (e) {
      console.log(e)
    }
  })

  // For dashboard card-moving to be implemented later:
  // function DashboardCard (props: any) {
  //   const cardToRender = props.cardToRender
  //   if (cardToRender === 'TabCard') {
  //     return (
  //       <DashboardTabCard
  //         curTab={curTab}
  //         setCurTab={setCurTab}
  //         setModalOpen={setModalOpen}
  //         dashboardFilters={dashboardFilters}
  //       />
  //     )
  //   } else if (cardToRender === 'SummaryTimeline') {
  //     return (
  //       <SummaryTimelineCard />
  //     )
  //   } else if (cardToRender === 'PieCharts') {
  //     return (
  //       <PieCharts
  //         chartData={chartData}
  //         setTopIndustriesModalOpen={setTopIndustriesModalOpen}
  //         setSizesModalOpen={setSizesModalOpen}
  //         setTopRevenuesModalOpen={setTopRevenuesModalOpen}
  //       />
  //     )
  //   } else if (cardToRender === 'SegmentsAndMeetings') {
  //     return (
  //       <SegmentsAndMeetings
  //         chatServiceUrl={chatServiceUrl}
  //       />
  //     )
  //   } else if (cardToRender === 'PerformanceAndRecent') {
  //     return (
  //       <PerformanceAndRecent
  //         user={user}
  //         chatServiceUrl={chatServiceUrl}
  //       />
  //     )
  //   } else {
  //     return (
  //       <></>
  //     )
  //   }
  // }

  document.title = 'Dashboard | Signals'

  return (
    <div>
      {/* For dashboard card-moving to be implemented later
      {dashboardOrder.map((card, index) => {
        return (
          <div key={index} className={classes.dashboardCard}>
            <DashboardCard
              cardToRender={card}
            />
          </div>
        )
      })} */}
      <div
        id='ip-lookup-banner'
        hx-get='/api/core/web/missing_lookups'
        hx-trigger='load'
      />
      <div className={classes.dashboardCard}>
        <DashboardTabCard
          setPotentialAccountModalOpen={setPotentialAccountModalOpen}
          setModalDomain={setModalDomain}
          accountAdded={accountAdded}
        />
      </div>
      <div id='summary-timeline' className={classes.dashboardCard}>
        <SummaryTimelineCard />
      </div>
      <div id='top-categories' className={classes.dashboardCard}>
        <PieCharts
          chartData={chartData}
          setTopIndustriesModalOpen={setTopIndustriesModalOpen}
          setSizesModalOpen={setSizesModalOpen}
          setTopRevenuesModalOpen={setTopRevenuesModalOpen}
        />
      </div>
      <div className={classes.dashboardCard}>
        <SegmentsAndMeetings
          chatServiceUrl={chatServiceUrl}
        />
      </div>
      <AccessControl requiredComponent={components.CHAT}>
        <div className={classes.dashboardCard}>
          <PerformanceAndRecent
            user={user}
            chatServiceUrl={chatServiceUrl}
          />
        </div>
      </AccessControl>
      <div>
        {/* Rewrite the below component to be more dynamic */}
        <PieChartModal
          title='Top Visitor Company Industries'
          data={chartData.industry_data}
          id='industry-pie-chart-full'
          open={topIndustriesModalOpen}
          onHide={() => setTopIndustriesModalOpen(false)}
        />
        <PieChartModal
          title='Top Visitor Company Sizes'
          data={chartData.employee_data}
          id='employee-pie-chart-full'
          open={topSizesModalOpen}
          onHide={() => setSizesModalOpen(false)}
        />
        <PieChartModal
          title='Top Visitor Company Revenues'
          data={chartData.revenue_data}
          id='revenue-pie-chart-full'
          open={topRevenuesModalOpen}
          onHide={() => setTopRevenuesModalOpen(false)}
        />
      </div>
      <br />
      <div style={{ textAlign: 'right', fontSize: 12 }}>
        Powered by 6sense
      </div>
    </div>
  )
}

export default ABEDashboard
