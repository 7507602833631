import { makeStyles, Theme } from '@material-ui/core/styles'
import InformationIcon from 'library/InformationIcon'
import { Button, Icon, Switch } from 'library/materialUI'
import { useContext } from 'react'
import IntegrationsBreadCrumbs from '../IntegrationsBreadcrumbs'
import { IntegrationPageContext } from './IntegrationPageProvider'

const useStyles = makeStyles<Theme>(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginRight: 10,
    marginLeft: 10
  },
  rightSideContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    width: 600,
    position: 'relative',
    left: 'auto',
    right: '40px'
  },
  colorPrimary: {
    color: theme.palette.success.main
  },
  greenTrackColor: {
    backgroundColor: `${theme.palette.success.main} !important`
  },
  integrationStateDiv: {
    borderRadius: 20,
    padding: '7px 15px',
    color: theme.palette.text.white
  },
  integrationStateOnColor: {
    backgroundColor: theme.palette.success.main
  },
  integrationStateOffColor: {
    backgroundColor: theme.palette.background.mediumGrey
  },
  imgStyle: {
    margin: '10px',
    width: 150
  }
}))

export default function PageHeader () {
  const { integration: { name, appLogo }, isEnabled, handleToggleEnable, saveDisabled, handleSave, learnMoreLink } = useContext(IntegrationPageContext)
  const classes = useStyles()
  const integrationState = isEnabled ? 'On' : 'Off'
  const integrationStateDivClass = isEnabled ? `${classes.integrationStateDiv} ${classes.integrationStateOnColor}` : `${classes.integrationStateDiv} ${classes.integrationStateOffColor}`
  return (
    <div>
      <IntegrationsBreadCrumbs pageName={`${name} Integration Page`} />
      <div className={classes.container}>
        <div>
          <img className={classes.imgStyle} src={appLogo} alt={`${name} logo`} />
        </div>
        <div className={classes.rightSideContainer}>
          {learnMoreLink && (
            <InformationIcon
              message='click here to learn more'
              color='grey'
              learnMore
              link={learnMoreLink}
            />
          )}
          <Switch
            checked={isEnabled}
            handleChange={() => handleToggleEnable()}
            label={<div className={integrationStateDivClass}>Integration: {integrationState} </div>}
            color={isEnabled ? 'primary' : 'secondary'}
            classes={{
              thumb: isEnabled ? classes.colorPrimary : '',
              track: isEnabled ? classes.greenTrackColor : ''
            }}
          />
          <Button
            startIcon={<Icon icon='save' />}
            onClick={() => handleSave()}
            disabled={saveDisabled}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  )
}
