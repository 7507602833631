import React, { useState, useEffect, useContext, useCallback } from 'react'
import AppPage from 'cf-components/AppPage'
import FAQPage from './FAQPage'
import FAQDashboard from './FAQDashboard'
import FAQTopics from './FAQTopics'
import { SessionContext } from 'session-context'
import { saveFAQ, faqSearch, deleteFAQs, getFAQ } from 'api/faq'
import ReactDOM from 'react-dom'

function FAQManagement (props) {
  const { user } = useContext(SessionContext)
  const [chatServiceUrl] = useState(user.links.chat_service || 'localhost:8002/api/chat-service/a')
  const [modalOpen, setModalOpen] = useState(false)
  const [faqs, setFAQs] = useState(null)
  const [deleteFAQID, setDeleteFAQID] = useState(0)
  const [deleteFAQModalOpen, setDeleteFAQModalOpen] = useState(false)
  const [modalState, setModalState] = useState({
    name: '',
    questions: [],
    id: null,
    response: '',
    edit: false,
    tags: [],
    keywords: [],
    excluded: [],
    tab: 0
  })

  function editFAQ ({ name, questions, id, response, tags, keywords, excluded }) {
    const faq_id = id
    getFAQ({ faq_id }).then(response => {
      const faq = response.data.attributes
      setModalState({
        name: faq.name,
        questions: faq.questions,
        id: id,
        response: faq.response,
        edit: true,
        tags: faq.tags,
        keywords: faq.keywords,
        excluded: faq.excluded_keywords,
        tab: 0
      })
      setModalOpen(true)
    })
  }

  function newFAQ () {
    setModalState({
      name: '',
      questions: [],
      id: null,
      response: '',
      edit: false,
      tags: [],
      keywords: [],
      excluded: [],
      tab: 0
    })
    setModalOpen(true)
  }

  function save ({ name, questions, response, id, tags, keywords, excluded }) {
    saveFAQ({ name, questions, response, tags, keywords, excluded, id, chatServiceUrl })
      .then(() => {
        loadFAQs()
        setModalOpen(false)
      })
  }

  const loadFAQs = useCallback(() => {
    faqSearch({ search: '', chatServiceUrl }).then(response => {
      setFAQs(response.data)
    })
  }, [chatServiceUrl])

  useEffect(() => {
    loadFAQs()
  }, [loadFAQs])

  function beginDelete () {
    deleteFAQs({ faq_ids: [deleteFAQID], chatServiceUrl })
      .then(response => {
        loadFAQs()
        ReactDOM.unstable_batchedUpdates(() => {
          setDeleteFAQID(0)
          setDeleteFAQModalOpen(false)
        })
      })
  }

  document.title = 'FAQ Management | Signals'

  return (
    <AppPage
      title='FAQ Management'
      tabs={[
        { name: 'FAQs', url: '/settings/faq_management/faqs', action: newFAQ, actionText: 'Add FAQ' },
        { name: 'Dashboard', url: '/settings/faq_management/dashboard' },
        { name: 'Missed Topics', url: '/settings/faq_management/missed_topics' }
      ]}
    >
      <FAQPage
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        faqs={faqs}
        modalState={modalState}
        setModalState={setModalState}
        beginDelete={beginDelete}
        save={save}
        editFAQ={editFAQ}
        deleteModalShow={deleteFAQModalOpen}
        setDeleteFAQID={(FAQID) => {
          setDeleteFAQID(FAQID)
          setDeleteFAQModalOpen(true)
        }}
        setDeleteFAQModalOpen={setDeleteFAQModalOpen}
      />
      <FAQDashboard />
      <FAQTopics
        chatServiceUrl={chatServiceUrl}
      />
    </AppPage>
  )
}

export default FAQManagement
