/* eslint-disable @typescript-eslint/naming-convention */
import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import Extension from '@material-ui/icons/Extension'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import Snackbar from '@material-ui/core/Snackbar'
import { parse } from 'query-string'
import Card from '@material-ui/core/Card'
import { CardHeader } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ChatFunnelsIcon from 'img/Logo_purple_transparent_BG.png' // chatfunnels.png'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import { isArray } from 'lodash'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center'
  },
  card: {
    width: 350,
    height: 375,
    marginTop: 200,
    borderRadius: 10
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  logo: {
    height: 50
  },
  logoWrapper: {
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
    marginTop: 25
  },
  button: {
    position: 'absolute',
    bottom: 40,
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%'
  },
  cardContent: {
    height: '100%',
    position: 'relative',
    margin: 20
  },
  description: {
    textAlign: 'center',
    fontSize: 15,
    marginTop: 25
  }
}))

type OauthMappingType = keyof typeof oauthMappings
const oauthMappings = {
  access_denied: 'Oauth Error: access was denied. Please try again.',
  immediate_unsuccessful: 'Oauth Error: please try again.'
}

const oauthErrorMessage = (inputMessage: string | string[] | null | undefined) => {
  let attempt = ''
  if (isArray(inputMessage)) {
    return ''
  }
  if (!inputMessage) {
    return ''
  }
  if (inputMessage in oauthMappings) {
    const msg = inputMessage as OauthMappingType
    attempt = oauthMappings[msg]
  }
  if (!attempt) {
    return 'Unknown error. Please try again'
  }
  return attempt
}

interface OAuthPageInterface {
  integrationLogo: string
  integrationName: string
  oauthUrl: string
  title: string
}

const OAuthPage = ({ integrationLogo, integrationName, oauthUrl, title }: OAuthPageInterface) => {
  const classes = useStyles()
  const queryString = parse(window.location.search)
  const OAuthError = oauthErrorMessage(queryString.error)
  const [oauthMessage, setOauthMessage] = useState(OAuthError)

  function performOauth (_: any) {
    window.location.href = oauthUrl
  }

  function onMessageClose (_: any) {
    setOauthMessage('')
  }

  return (
    <div className={classes.container}>
      <Card className={classes.card} raised>
        <div className={classes.cardContent}>
          <div className={classes.logoContainer}>
            <div className={classes.logoWrapper}>
              <img src={ChatFunnelsIcon} className={classes.logo} alt='Signals Logo' />
              <img src={integrationLogo} className={classes.logo} alt={`${integrationName} Logo`} />
            </div>
          </div>
          <CardHeader title={title} style={{ textAlign: 'center' }}>
            <Extension />
          </CardHeader>
          <Divider />
          <div>
            <div>
              <Typography variant='body2' className={classes.description}>
                You are one step away from linking your Signals account
                with {integrationName}!
              </Typography>
              <Button
                variant='outlined'
                color='primary'
                className={classes.button}
                onClick={performOauth}
              >
                Start activation
              </Button>
            </div>

          </div>
        </div>
      </Card>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        aria-describedby='client-snackbar'
        action={[
          <IconButton
            key='close'
            aria-label='Close'
            color='inherit'
            onClick={onMessageClose}
          >
            <CloseIcon />
          </IconButton>
        ]}
        open={Boolean(oauthMessage)}
        autoHideDuration={6000}
        ContentProps={{
          'aria-describedby': 'message-id'
        }}
        message={<span id='message-id'>{OAuthError}</span>}
      />
    </div>
  )
}

export default OAuthPage
