/* eslint-disable @typescript-eslint/naming-convention */
import { makeStyles } from '@material-ui/core/styles'
import OnboardingPromptMapper, { promptListType } from 'cf-components/OnboardingPromptMapper'
import { DisplaySettings } from 'classes/displaySettings'
// import FilterDropdownImg from 'img/filter-dropdown-onboarding.gif'
// import FilterViewStep2 from 'img/create-view-onboarding.gif'
// import FilterViewStep3 from 'img/default-view-onboarding.gif'
// import FilterViewStep4 from 'img/email-update-onboarding.gif'
// import DashboardRow2 from 'img/potential-accounts-onboarding.gif'
// import DashboardRow3 from 'img/signals-score-onboarding.gif'

const useStyles = makeStyles(theme => ({
  grayText: {
    color: '#00000080',
    fontFamily: 'poppins'
  },
  purpleText: {
    color: theme.palette.primary.main,
    fontFamily: 'poppins',
    fontWeight: 'bold'
  }
}))

export default function DashboardOnboarding () {
  const classes = useStyles()
  const { settings } = DisplaySettings.load()
  const defaultState = {
    new_dashboard_tabs: { complete: false },
    add_view_button: { complete: false },
    edit_view_button: { complete: false },
    email_update_button: { complete: false },
    dashboard_row: { complete: false },
    exporting_accounts: { complete: false },
    summary_timeline: { complete: false },
    top_categories: { complete: false },
    segment_card: { complete: false },
    meeting_card: { complete: false },
    performance_card: { complete: false },
    conversation_card: { complete: false }
  }

  const promptList: promptListType[] = [
    {
      anchorID: 'dashboard-tabs',
      xOffset: 0,
      yOffset: -10,
      updateFunction: () => { DisplaySettings.update({ page: 'ftux', type: 'dashboard-onboarding', settings: { ...settings.ftux.settings['dashboard-onboarding'], new_dashboard_tabs: { complete: true } } }) },
      anchors: [
        {
          title: 'Pinned Views',
          body: (
            <div>
              <div>
                <span className={classes.grayText}>Your pinned views allow you to see different groupings of accounts on your dashboard. Click and drag to reorder your pinned views.</span>
              </div>
            </div>
          ),
          anchorID: 'dashboard-tabs',
          anchorPos: 'left',
          anchorOriginHorizontal: 'right',
          anchorOriginVertical: 'top',
          transformOriginHorizontal: 'left',
          transformOriginVertical: 'top',
          arrowOffset: '15px'
        }
      ],
      visible: !settings?.ftux?.settings['dashboard-onboarding']?.new_dashboard_tabs?.complete
    },
    {
      anchorID: 'add_view_button',
      xOffset: 0,
      yOffset: -20,
      updateFunction: () => { DisplaySettings.update({ page: 'ftux', type: 'dashboard-onboarding', settings: { ...settings.ftux.settings['dashboard-onboarding'], add_view_button: { complete: true } } }) },
      anchors: [{
        title: 'Filter your dashboard',
        body: (
          <div>
            <div>
              <span className={classes.grayText}>Filter what you see on your dashboard by either creating a new view, or by duplicating and adding filters to an existing view.</span>
            </div>
          </div>
        ),
        anchorID: 'add_view_button',
        anchorPos: 'right',
        anchorOriginHorizontal: -45,
        anchorOriginVertical: -30,
        transformOriginHorizontal: 'right',
        transformOriginVertical: 'top',
        arrowOffset: '30px'
      }],
      visible: !settings?.ftux?.settings['dashboard-onboarding']?.add_view_button?.complete
    },
    {
      anchorID: 'edit_views_button',
      xOffset: 0,
      yOffset: -20,
      updateFunction: () => { DisplaySettings.update({ page: 'ftux', type: 'dashboard-onboarding', settings: { ...settings.ftux.settings['dashboard-onboarding'], edit_view_button: { complete: true } } }) },
      anchors: [{
        title: 'Manage your views',
        body: (
          <div>
            <div>
              <span className={classes.grayText}>Find views you’ve already created here, and add or remove them from your dashboard.</span>
            </div>
          </div>
        ),
        anchorID: 'edit_views_button',
        anchorPos: 'right',
        anchorOriginHorizontal: -45,
        anchorOriginVertical: -30,
        transformOriginHorizontal: 'right',
        transformOriginVertical: 'top',
        arrowOffset: '30px'
      }],
      visible: !settings?.ftux?.settings['dashboard-onboarding']?.edit_view_button?.complete
    },
    {
      anchorID: 'email_update_button',
      xOffset: -20,
      yOffset: -10,
      updateFunction: () => { DisplaySettings.update({ page: 'ftux', type: 'dashboard-onboarding', settings: { ...settings.ftux.settings['dashboard-onboarding'], email_update_button: { complete: true } } }) },
      anchors: [{
        title: 'Email Updates',
        body: (
          <div>
            <div>
              <span className={classes.grayText}>Get regular email updates about companies that are visiting your website and match your filters by clicking the “Create email update” button.</span>
            </div>
          </div>
        ),
        anchorID: 'email_update_button',
        anchorPos: 'left',
        anchorOriginHorizontal: 230,
        anchorOriginVertical: 'top',
        transformOriginHorizontal: 'left',
        transformOriginVertical: 'top',
        arrowOffset: '10px'
      }],
      visible: !settings?.ftux?.settings['dashboard-onboarding']?.email_update_button?.complete
    },
    {
      anchorID: 'dashboard-row',
      xOffset: -20,
      yOffset: 20,
      updateFunction: () => { DisplaySettings.update({ page: 'ftux', type: 'dashboard-onboarding', settings: { ...settings.ftux.settings['dashboard-onboarding'], dashboard_row: { complete: true } } }) },
      anchors: [{
        title: 'Visiting Companies',
        body: (
          <div>
            <div>
              <span className={classes.grayText}>Companies that visit your website will show up in this table.</span>
            </div>
          </div>
        ),
        anchorID: 'dashboard-row',
        anchorPos: 'top',
        anchorOriginHorizontal: 50,
        anchorOriginVertical: 110,
        transformOriginHorizontal: 'right',
        transformOriginVertical: 'top',
        arrowOffset: '55px'
      }
      // {
      //   title: 'Add Potential Accounts',
      //   body: (
      //     <div>
      //       <div style={{ marginTop: '15px' }}>
      //         <img src={DashboardRow2} alt='dashboard-row-2' width={243} />
      //       </div>
      //       <div>
      //         <span className={classes.grayText}>Potential Accounts are companies that aren’t yet in your CRM. To add a potential account to your CRM, click '+' and finish filling out the information in the window that appears.</span>
      //       </div>
      //     </div>
      //     ),
      //   anchorID: 'dashboard-row-2',
      //   anchorPos: 'top',
      //   anchorOriginHorizontal: 50,
      //   anchorOriginVertical: 70,
      //   transformOriginHorizontal: 'right',
      //   transformOriginVertical: 'top',
      //   arrowOffset: '55px'
      // },
      // {
      //   title: 'Signals Score',
      //   body: (
      //     <div>
      //       <div style={{ marginTop: '15px' }}>
      //         <img src={DashboardRow3} alt='dashboard-row-3' width={243} />
      //       </div>
      //       <div>
      //         <span className={classes.grayText}>Companies with higher Signals scores are displaying higher levels of intent. Hover over a company’s score to see reasons for the score. You can also customize your score <a href='#/settings/signals_score' target='_blank'>here</a>.</span>
      //       </div>
      //     </div>
      //     ),
      //   anchorID: 'dashboard-row-3',
      //   anchorPos: 'right',
      //   anchorOriginHorizontal: -55,
      //   anchorOriginVertical: -30,
      //   transformOriginHorizontal: 'right',
      //   transformOriginVertical: 'top',
      //   arrowOffset: '255px'
      // },
      // {
      //   title: 'Snoozing Accounts',
      //   body: (
      //     <div>
      //       <div>
      //         <span className={classes.grayText}>Closed lost opportunities, or other companies you want to temporarily remove from your dashboard can be snoozed by clicking this button on the right hand side of the screen and clicking “Snooze for 90 days”.</span>
      //       </div>
      //     </div>
      //     ),
      //   anchorID: 'dashboard-row-4',
      //   anchorPos: 'right',
      //   anchorOriginHorizontal: -50,
      //   anchorOriginVertical: -30,
      //   transformOriginHorizontal: 'right',
      //   transformOriginVertical: 'top',
      //   arrowOffset: '55px'
      // }
    ],
      visible: !settings?.ftux?.settings['dashboard-onboarding']?.dashboard_row?.complete
    },
    {
      anchorID: 'exporting-accounts',
      xOffset: 45,
      yOffset: -20,
      updateFunction: () => { DisplaySettings.update({ page: 'ftux', type: 'dashboard-onboarding', settings: { ...settings.ftux.settings['dashboard-onboarding'], exporting_accounts: { complete: true } } }) },
      anchors: [{
        title: 'Export Accounts and Contacts',
        body: (
          <div>
            <div>
              <span className={classes.grayText}>Export the full list of accounts on your dashboard or a list of all of the contacts from those accounts by clicking “Export”. You can also select specific accounts to export only their information.</span>
            </div>
          </div>
        ),
        anchorID: 'exporting-accounts',
        anchorPos: 'right',
        anchorOriginHorizontal: -50,
        anchorOriginVertical: -50,
        transformOriginHorizontal: 'right',
        transformOriginVertical: 'top',
        arrowOffset: '55px'
      }],
      visible: !settings?.ftux?.settings['dashboard-onboarding']?.exporting_accounts?.complete
    },
    {
      anchorID: 'summary-timeline',
      xOffset: 520,
      yOffset: 27,
      updateFunction: () => { DisplaySettings.update({ page: 'ftux', type: 'dashboard-onboarding', settings: { ...settings.ftux.settings['dashboard-onboarding'], summary_timeline: { complete: true } } }) },
      anchors: [{
        title: 'Summary Timeline',
        body: (
          <div>
            <div>
              <span className={classes.grayText}>The Summary Timeline shows the number of companies that have visited your website over the last 30 days. Hover over a day to see the number of companies that visited your website on that day.</span>
            </div>
          </div>
        ),
        anchorID: 'summary-timeline',
        anchorPos: 'bottom',
        anchorOriginHorizontal: 450,
        anchorOriginVertical: -370,
        transformOriginHorizontal: 'right',
        transformOriginVertical: 'top',
        arrowOffset: '55px'
      },
      {
        title: 'Live View',
        body: (
          <div>
            <div>
              <span className={classes.grayText}>See who’s on your site right now and preemptively start conversations with them.</span>

            </div>
          </div>
        ),
        anchorID: 'summary-timeline-2',
        anchorPos: 'right',
        anchorOriginHorizontal: -50,
        anchorOriginVertical: -40,
        transformOriginHorizontal: 'right',
        transformOriginVertical: 'top',
        arrowOffset: '55px'
      }],
      visible: !settings?.ftux?.settings['dashboard-onboarding']?.summary_timeline?.complete
    },
    {
      anchorID: 'top-categories',
      xOffset: 0,
      yOffset: 0,
      updateFunction: () => { DisplaySettings.update({ page: 'ftux', type: 'dashboard-onboarding', settings: { ...settings.ftux.settings['dashboard-onboarding'], top_categories: { complete: true } } }) },
      anchors: [{
        title: 'Top Visitor Categories',
        body: (
          <div>
            <div>
              <span className={classes.grayText}>See which industries, company sizes, and revenue ranges are visiting your site the most.</span>
            </div>
          </div>
        ),
        anchorID: 'top-categories',
        anchorPos: 'bottom',
        anchorOriginHorizontal: 450,
        anchorOriginVertical: -270,
        transformOriginHorizontal: 'right',
        transformOriginVertical: 'top',
        arrowOffset: '55px'
      }],
      visible: !settings?.ftux?.settings['dashboard-onboarding']?.top_categories?.complete
    },
    {
      anchorID: 'segment-card',
      xOffset: 250,
      yOffset: 28,
      updateFunction: () => { DisplaySettings.update({ page: 'ftux', type: 'dashboard-onboarding', settings: { ...settings.ftux.settings['dashboard-onboarding'], segment_card: { complete: true } } }) },
      anchors: [{
        title: 'Company Segments',
        body: (
          <div>
            <div>
              <span className={classes.grayText}>See at a glance the number of companies, conversations, and meetings from accounts in each of your segments.</span>
            </div>
          </div>
        ),
        anchorID: 'segment-card',
        anchorPos: 'bottom',
        anchorOriginHorizontal: 450,
        anchorOriginVertical: -295,
        transformOriginHorizontal: 'right',
        transformOriginVertical: 'top',
        arrowOffset: '55px'
      }],
      visible: !settings?.ftux?.settings['dashboard-onboarding']?.segment_card?.complete
    },
    {
      anchorID: 'meeting-card',
      xOffset: 245,
      yOffset: 28,
      updateFunction: () => { DisplaySettings.update({ page: 'ftux', type: 'dashboard-onboarding', settings: { ...settings.ftux.settings['dashboard-onboarding'], meeting_card: { complete: true } } }) },
      anchors: [{
        title: 'Meetings',
        body: (
          <div>
            <div>
              <span className={classes.grayText}>See your upcoming meetings booked through Signals</span>
            </div>
          </div>
        ),
        anchorID: 'meeting-card',
        anchorPos: 'bottom',
        anchorOriginHorizontal: 450,
        anchorOriginVertical: -250,
        transformOriginHorizontal: 'right',
        transformOriginVertical: 'top',
        arrowOffset: '55px'
      }],
      visible: !settings?.ftux?.settings['dashboard-onboarding']?.meeting_card?.complete
    },
    {
      anchorID: 'performance-card',
      xOffset: 310,
      yOffset: 28,
      updateFunction: () => { DisplaySettings.update({ page: 'ftux', type: 'dashboard-onboarding', settings: { ...settings.ftux.settings['dashboard-onboarding'], performance_card: { complete: true } } }) },
      anchors: [{
        title: 'Chat Performance',
        body: (
          <div>
            <div>
              <span className={classes.grayText}>See how your chat performance ranks against others on your team.</span>
            </div>
          </div>
        ),
        anchorID: 'performance-card',
        anchorPos: 'bottom',
        anchorOriginHorizontal: 450,
        anchorOriginVertical: -262,
        transformOriginHorizontal: 'right',
        transformOriginVertical: 'top',
        arrowOffset: '55px'
      }],
      visible: !settings?.ftux?.settings['dashboard-onboarding']?.performance_card?.complete
    },
    {
      anchorID: 'conversation-card',
      xOffset: 260,
      yOffset: 28,
      updateFunction: () => { DisplaySettings.update({ page: 'ftux', type: 'dashboard-onboarding', settings: { ...settings.ftux.settings['dashboard-onboarding'], conversations_card: { complete: true } } }) },
      anchors: [{
        title: 'Recent Conversations',
        body: (
          <div>
            <div>
              <span className={classes.grayText}>Get quick access to recent conversations you’ve been a part of. Click a conversation to open it.</span>
            </div>
          </div>
        ),
        anchorID: 'conversation-card',
        anchorPos: 'bottom',
        anchorOriginHorizontal: 450,
        anchorOriginVertical: -300,
        transformOriginHorizontal: 'right',
        transformOriginVertical: 'top',
        arrowOffset: '55px'
      }],
      visible: !settings?.ftux?.settings['dashboard-onboarding']?.conversations_card?.complete
    }
  ]

  return (
    <OnboardingPromptMapper defaultState={defaultState} promptList={promptList} pageName='dashboard-onboarding' dimmed />
  )
}
