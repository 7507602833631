export const locations = [
  { value: 1, label: 'Visitors in the European Union' },
  { value: 2, label: 'Visitors in Canada' },
  { value: 3, label: 'Visitors in California' }
]

interface LocationList {
  list: Locations[]
  dict: { [id: Locations['id']]: Locations }
}

export class Locations {
  name: string
  id: number

  constructor ({ value, label }: { value: number; label: string; }) {
    this.name = label
    this.id = value
  }

  static loadAll (): { data: LocationList, isLoading: boolean } {
    const data = serialize()
    return { data: data, isLoading: false }
  }
}

const serialize = (): any => {
  const locationsList: any = []
  const locationsDict: any = {}
  locations.forEach(location => {
    locationsDict[location.value] = new Locations(location)
    locationsList.push(new Locations(location))
  })
  return { list: locationsList, dict: locationsDict }
}
