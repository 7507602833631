import { hasComponent } from 'api/billing';
import Card from 'library/materialUI/Card';
import { useQuery } from 'react-query';
import { makeStyles } from '@material-ui/core/styles'
import { Icon, Typography } from 'library/materialUI';
import ModalWarning from 'components/ModalWarning';
import { triggerSalesBot } from 'pages/bot/InAppBot/triggerSupportBot';

const useStyles = makeStyles((theme) => ({
  subtitle1: {
    fontSize: 18,
    fontWeight: 600,
    marginBottom: 15
  },
  subtitle2: {
    fontSize: 15,
    fontWeight: 600,
    marginBottom: 10
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  bodyText: {
    marginBottom: 30,
    fontSize: 16
  },
  select: {
    width: 250,
    height: 40
  },
  star: {
    fill: 'gold'
  },
  icon: {
    marginTop: 4,
    marginLeft: 4
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    margin: 0,
    padding: 0
  },
  lineItem: {
    display: 'flex',
    flexDireciton: 'row',
    marginTop: '10px'
  }
}))

export default function CreateAccountCard ({ integration }: { integration: string }): JSX.Element {
  const classes = useStyles()
  const { data: hasCreateAccountComponent } = useQuery('has-create-account-component', () => hasComponent('playrunner-create-accounts'))
  const integrationName = integration.charAt(0).toUpperCase() + integration.slice(1)
  return (
    <Card
      content={
        <>
          <div className={classes.header}>
            <Typography className={classes.subtitle1}>Create/Sync accounts in {integrationName} </Typography>
          </div>
          <Typography variant='body2' className={classes.bodyText}>
            Existing {integrationName === 'Hubspot' ? 'company records' : 'accounts'} will be updated and new {integrationName === 'Hubspot' ? 'company records' : 'accounts'} will be created in {integrationName} when visitors from companies without an existing {integrationName === 'Hubspot' ? 'company records' : 'accounts'} enter plays containing “Create Account” skills.
          </Typography>
          {!hasCreateAccountComponent ? (
            <div style={{ width: 600 }}>
              <ModalWarning
                body={'Upgrade to automatically create accounts in ' + integrationName}
                type='upgrade'
                helpAction={() => triggerSalesBot()}
              />
            </div>
          ) : (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Icon icon='checkCircle' size='md' color='#81AF20' />
              <Typography variant='body1' style={{ marginLeft: 5 }}>Looks like you're all set to add the "Create Account" skill within PlayMaker</Typography>
            </div>
          )}
        </>
      }
    />
  )
}
