import { createContext, Dispatch, ReactNode, SetStateAction } from 'react';

interface TabValues {
  shouldSave: boolean
  setCanSave: Dispatch<SetStateAction<boolean>>
}

export const TabContext = createContext<TabValues>({
  shouldSave: false,
  setCanSave: () => undefined
})
export interface TabProviderProps {
  shouldSave: boolean
  setCanSave: Dispatch<SetStateAction<boolean>>
  children: ReactNode
}

export default function TabProvider ({ children, shouldSave, setCanSave } : TabProviderProps) {
  return (
    <TabContext.Provider
      value={
        {
          shouldSave,
          setCanSave
        }
      }
    >
      {children}
    </TabContext.Provider>
  )
}
