import React, { useState, useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { logAttachment } from 'api/content_pages'
import Dropzone from 'react-dropzone'
import CustomizedSnackbar from 'components/CustomizedSnackbar'
import MuiDialogContent from '@material-ui/core/DialogContent'
import axios from 'axios'
import CircularProgress from '@material-ui/core/CircularProgress'
import MockBrowserContainer from './MockBrowserContainer.tsx'
import LogoImg from 'img/Logo_gradient_transparent_BG.png'
import { Icon } from 'library/materialUI/Icon'
import SendIcon from '@material-ui/icons/Send'
import ContentSettings from './ContentSettings.tsx'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: '14px',
    backgroundColor: '#F7F7F7',
    height: '740px'
  },
  leftContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: 10,
    width: '360px',
    height: '720px'
  },
  rightContainer: {
    padding: 10,
    display: 'flex',
    flexDirection: 'column',
    width: '906px', // 75%',
    height: '740px'
  },
  left: {
    display: 'flex',
    height: '100%'
  },
  right: {
    display: 'flex',
    height: '100%'
  },
  card: {
    border: '1px solid #D3D3D3',
    backgroundColor: '#FFFFFF',
    padding: '10px',
    marginBottom: '10px',
    borderRadius: '10px'
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
    marginBottom: 15
  },
  stylesContainer: {
    display: 'flex',
    marginTop: 10
  },
  subtitle: {
    color: 'gray',
    fontSize: 14,
    marginBottom: 10
  },
  subHeader: {
    fontWeight: 600
  },
  radioButtons: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    fontSize: '12px',
    color: theme.palette.primary.main
  },
  pdfIcon: {
    width: 50,
    height: 50,
    color: 'gray'
  },
  pdfSample: {
    backgroundColor: 'white',
    margin: 40,
    display: 'block',
    overflow: 'hidden',
    position: 'absolute',
    width: '620px',
    height: 'calc(100% - 30px)',
    marginTop: 30,
    boxShadow: '2px 0px 5px 0px rgba(0,0,0,0.51)'
  },
  content: {
    margin: 'auto',
    textAlign: 'center',
    verticalAlign: 'center',
    height: '100%'
  },
  defaultLogoStyles: {
    display: 'flex',
    alignItems: 'center',
    color: 'gray',
    fontSize: 18
  },
  logoPreview: {
    maxHeight: 75
  },
  pdfIconHighlighted: {
    color: '#975ce6',
    width: 50,
    height: 50
  },
  previewHeader: {
    border: '1px solid #AA88E2',
    borderWidth: '1px 1px 0px 1px',
    borderRadius: '5px 5px 0px 0px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 10,
    paddingRight: 10
  },
  botPreviewWindow: {
    width: '584px',
    minHeight: '504px',
    backgroundColor: '#FFFFFF',
    borderRadius: '5px',
    boxShadow: '0px 3px 6px #00000029',
    marginRight: '200px',
    marginLeft: '200px',
    marginTop: '20px',
    marginBottom: '33px'
  }
}))

const IFramePreviewPanel = props => {
  const handleDrop = acceptedFile => {
    props.handler(acceptedFile[0])
  }

  return (
    <>
      <Dropzone onDrop={handleDrop}>
        {({ getRootProps, getInputProps }) => (
          <MuiDialogContent
            dividers
            {...getRootProps()}
            style={{ backgroundColor: props.backgroundColor, position: 'absolute', overflow: 'hidden', zIndex: 2, height: '70%', width: '8 0px' }}
          >
            <div style={{ height: '100%', display: 'flex', justifyContent: 'center', visibility: props.ready ? 'hidden' : 'visible' }}>
              <div style={{ display: 'block', margin: 'auto', paddingBottom: 50 }}>
                <div>
                  <CircularProgress size={100} style={{ display: 'block', margin: 'auto' }} />
                  <div style={{ textAlign: 'center', color: '#975ce6', fontSize: 20, marginTop: 20 }}>
                    Loading Preview
                  </div>
                </div>
              </div>
            </div>
          </MuiDialogContent>
        )}
      </Dropzone>
      <div style={{ visibility: props.ready ? 'visible' : 'hidden', backgroundImage: `linear-gradient(to bottom right, #${props.contentPageState.attributes.background_color.replace('#', '')}, #${props.contentPageState.attributes.background_type === 'gradient' ? props.contentPageState.attributes.secondary_color.replace('#', '') : props.contentPageState.attributes.background_color.replace('#', '')}`, position: 'relative', overflow: 'hidden', height: '100%' }}>
        <iframe
          title='Content Page'
          ref={props.reference}
          src={`https://${props.domain}/__preview`}
          id='preview-iframe'
          height='125%'
          width='125%'
          style={{ pointerEvents: 'none', transformOrigin: '0 0', overflow: 'auto', transform: 'scale(0.8)' }}
        />
      </div>
    </>
  )
}

const ContentPageManagement = props => {
  const classes = useStyles()
  const contentPageState = { attributes: { ...props.values } }
  const subDomain = props.subDomain || 'SUBDOMAIN'
  const paramsRef = useRef({ pdf_content: props.values.pdf_content, logo_image: props.values.logo_image, background_color: props.values.background_color, secondary_color: props.values.secondary_color })
  const [pdfEnabled, setPdfEnabled] = useState(!contentPageState.attributes.bot_only)
  const [currentFile, setCurrentFile] = useState(null)
  const [currentImage, setCurrentImage] = useState(contentPageState?.attributes?.logo_image)
  const [logoEnabled, setLogoEnabled] = useState(true)
  const iframeRef = useRef()
  const [ready, setReady] = useState(false)
  const [logoURLOn, setLogoURLOn] = useState(contentPageState.attributes.use_logo_url || false)
  const [logoURL, setLogoURL] = useState(contentPageState.attributes.logo_url || '')
  const canSave = props.canSave
  const [snackState, setSnackState] = useState({
    open: false,
    variant: 'success',
    message: 'Snippet copied to clipboard!'
  })

  let domain = `${subDomain}.chatfunnels.dev:12346`
  if (window.location.hostname === 'beta.chatfunnels.com') {
    // the /0 suffix will all the content-page service to send requests to beta instead of app
    domain = `${subDomain}.chatfunnels.chat/0`
  } else if (window.location.hostname === 'app.chatfunnels.com') {
    domain = `${subDomain}.chatfunnels.chat`
  }

  const refreshIframe = () => {
    iframeRef.current.contentWindow.postMessage({
      command: 'refresh'
    }, '*')
  }

  const handleChange = (attr, value) => {
    props.setFieldValue(attr, value)

    if (attr === 'pdf_content' || attr === 'logo_image' || attr === 'background_color' || attr === 'secondary_color' || attr === 'background_type' || attr === 'bot_only') {
      const params = {
        pdf_content: contentPageState.attributes.pdf_content,
        logo_image: contentPageState.attributes.logo_image,
        background_color: contentPageState.attributes.background_color,
        secondary_color: contentPageState.attributes.secondary_color,
        background_type: contentPageState.attributes.background_type,
        bot_only: contentPageState.attributes.bot_only,
        content_page_id: contentPageState.attributes.content_page_id?.toString()
      }

      params[attr] = value
      paramsRef.current[attr] = value
      if (!contentPageState.attributes.bot_only) {
        iframeRef.current.contentWindow.postMessage({
          command: 'update_iframe',
          params: params
        }, '*')
      }
    }
  }

  const uploadPDF = file => {
    logAttachment({ file })
      .then(response => {
        const signed_url = response.meta.signed_upload_url
        axios.put(signed_url, file, {
          headers: {
            'Content-type': 'application/pdf'
          }
        }).then(response => {
          file.link = response.config.url.split('?')[0]
          setCurrentFile(file)
          handleChange('pdf_content', file.link)
        })
      })
  }

  useEffect(() => {
    const messageHandler = (message) => {
      if (message.source !== iframeRef.current?.contentWindow) {
        return
      }
      const data = message.data
      if (data.command === 'ready') {
        const params = {
          pdf_content: contentPageState.attributes.pdf_content,
          logo_image: contentPageState.attributes.logo_image,
          background_color: contentPageState.attributes.background_color,
          secondary_color: contentPageState.attributes.secondary_color,
          background_type: contentPageState.attributes.background_type,
          bot_only: contentPageState.attributes.bot_only,
          content_page_id: contentPageState.attributes.content_page_id?.toString()
        }

        iframeRef.current.contentWindow.postMessage({
          command: 'initialize_iframe',
          params: params
        }, '*')
      }
    }

    window.addEventListener('message', messageHandler)
    setTimeout(() => {
      setReady(true)
    }, 1000)
    return () => {
      window.removeEventListener('message', messageHandler)
    }
  }, [contentPageState.attributes.pdf_content, contentPageState.attributes.logo_image, contentPageState.attributes.background_color, contentPageState.attributes.secondary_color, contentPageState.attributes.content_page_id, contentPageState.attributes.background_type, contentPageState.attributes.bot_only])

  return (
    <>
      <CustomizedSnackbar state={snackState} handler={setSnackState} />
      <div>
        <div className={classes.container}>
          <div className={classes.leftContainer}>
            <ContentSettings
              pdfEnabled={pdfEnabled}
              setPdfEnabled={setPdfEnabled}
              handleChange={handleChange}
              uploadPDF={uploadPDF}
              contentPageState={contentPageState}
              currentFile={currentFile}
              logoEnabled={logoEnabled}
              setLogoEnabled={setLogoEnabled}
              currentImage={currentImage}
              setCurrentImage={setCurrentImage}
              logoURLOn={logoURLOn}
              setLogoURLOn={setLogoURLOn}
              logoURL={logoURL}
              setLogoURL={setLogoURL}
              setReady={setReady}
              domain={domain}
              subDomain={props.subDomain}
              setSnackState={setSnackState}
              canSave={canSave}
            />
          </div>
          <div className={classes.rightContainer}>
            <MockBrowserContainer pageName={contentPageState.attributes.page_title} pageURL={`https://${domain}/${contentPageState.attributes.page_name}`} refreshIFrame={pdfEnabled ? refreshIframe : undefined}>
              {pdfEnabled && !contentPageState.attributes.bot_only && (
                <IFramePreviewPanel reference={iframeRef} ready={ready} handler={uploadPDF} domain={domain} contentPageState={contentPageState} />
              )}
              {!pdfEnabled && contentPageState.attributes.bot_only && (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '557px', backgroundImage: `linear-gradient(to bottom right, #${contentPageState.attributes.background_color.replace('#', '')}, #${contentPageState.attributes.background_type === 'gradient' ? contentPageState.attributes.secondary_color.replace('#', '') : contentPageState.attributes.background_color.replace('#', '')}` }}>
                  <div className={classes.botPreviewWindow}>
                    {logoEnabled && (
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '75px', borderBottom: 'solid 2px #E2E2E2' }}>
                        {currentImage?.link && (
                          contentPageState?.attributes?.logo_image
                          ? <img src={contentPageState.attributes.logo_image} alt='logo' style={{ maxHeight: 60, maxWidth: 420 }} />
                          : <div style={{ fontSize: '14px', padding: '4px', paddingLeft: '15px', paddingRight: '15px', fontWeight: 'bold' }}>[ Upload your logo ]</div>
                        )}
                      </div>
                    )}
                    <div style={{ display: 'flex', flexDirection: 'column', margin: '10px', marginTop: !logoEnabled ? '82px' : '5px' }}>
                      <div style={{ color: '#00000080', marginLeft: '48px', fontSize: '10px' }}>
                        Signals Bot
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ marginLeft: '20px', marginRight: '20px', marginDown: '20px', marginTop: '2px' }}>
                          <img src={LogoImg} alt='logo' style={{ width: '22px', height: '22px' }} />
                        </div>
                        <div style={{ background: '#F0F0F0', borderRadius: '10px', fontSize: '12px', fontFamily: 'poppins', padding: '10px', maxWidth: '280px' }}>
                          Hi there! I'm a chatbot. Do you have any questions I can help you with?
                        </div>
                      </div>
                      <div style={{ marginTop: '20px', alignSelf: 'flex-end', background: '#9933FF', borderRadius: '10px', color: 'white', padding: '10px', fontSize: '12px' }}>
                        Yes I have questions
                      </div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '75px', marginTop: '210px', borderTop: 'solid 2px #E2E2E2' }}>
                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '35px', width: '100%', margin: '20px 28px 20px 28px', border: '1px solid #D3D3D3', borderRadius: '5px', background: '#FFFFFF' }}>
                        <div style={{ fontSize: '12px', color: '#00000066', marginLeft: '10px', marginRight: 'auto' }}>
                          Type your message here...
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginLeft: 'auto', marginRight: '10px' }}>
                          <Icon icon='smiley' color='#999999' />
                          <SendIcon style={{ color: '#999999', marginLeft: '10px' }} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </MockBrowserContainer>
          </div>
        </div>
      </div>
    </>
  )
}

export default ContentPageManagement
