import { useEffect, useState, useRef } from 'react'
import { Report, ClusteredColumnChartProps, LineChartProps, SparkLineChartProps } from 'classes/report'
import { jsPlumbUtil } from 'jsplumbtoolkit'
import { CreateClustedColumnChart } from './ClusteredColumn'
import { QueryFilterList } from 'classes/queryHelpers'
import { CreatePieChart } from './PieChart'
import { CreateLineChart } from './LineChart'
import { CreateSparkLineChart } from './SparkLineChart'
import { CreateBarChart } from './BarChart'

type ChartProps = {
  reportProps: ClusteredColumnChartProps | LineChartProps | SparkLineChartProps
  filters: QueryFilterList
}

export const chartMapping = {
  clusteredColumn: CreateClustedColumnChart,
  pieChart: CreatePieChart,
  lineChart: CreateLineChart,
  sparkLineChart: CreateSparkLineChart,
  barChart: CreateBarChart
}

export function Chart (props: ChartProps) {
  const chartRef = useRef<any>(null)
  const { reportProps, filters } = props
  const [id] = useState(jsPlumbUtil.uuid())
  const { data, isLoading } = Report.load({ reportProps, filters })

  useEffect(() => {
    if (!isLoading) {
      const chart = chartMapping[reportProps.reportType]({ data, id })
      chartRef.current = chart
      return () => {
        if (chartRef.current) {
          chartRef.current.dispose()
        }
      }
    }
    // eslint-disable-next-line
  }, [id, isLoading, data])

  return (
    <div id={id} style={{ height: '100%' }} />
  )
}
