const getSortedRows = (rows, sortCriteria, sortAscending) => {
  const sortedRows = rows.sort((a, b) => {
    if (a[sortCriteria] === null && b[sortCriteria] === null) {
      return 0
    } else if (a[sortCriteria] === null) {
      return sortAscending ? -1 : 1
    } else if (b[sortCriteria] === null) {
      return sortAscending ? -1 : 1
    }
    if (a[sortCriteria] < b[sortCriteria]) {
      return sortAscending ? -1 : 1
    } else if (a[sortCriteria] > b[sortCriteria]) {
      return sortAscending ? 1 : -1
    }
    return 0
  })
  return sortedRows
}

export const getFilteredRows = (rows, state) => {
  const { sortCriteria, sortAscending, search, filterCriteria } = state
  // TODO: use SearchParams and backend deleted filtering
  rows = rows.filter(row => !row.deletedTimestamp)
  // TODO: use SearchParams and backend search filtering
  rows = rows.filter(row => row.searchField.toLowerCase().includes(search.toLowerCase()))
  // TODO: use SearchParams and backend for sorting
  if (filterCriteria) {
    const field = filterCriteria.field
    const values = filterCriteria.value
    rows = rows.filter(row => values.includes(row[field]))
  }
  rows = getSortedRows(rows, sortCriteria, sortAscending)
  return rows
}
