import Link from 'cf-components/Link';
import { Breadcrumbs, Typography } from 'library/materialUI';
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  crumb: {
    fontSize: '.8em'
  },
  crumbWrapper: {
    padding: 10
  },
  typography: {
    fontSize: '1.05em'
  }
}))

interface IntegrationBreadCrumbsProps {
  pageName: string
}

export default function IntegrationsBreadCrumbs ({ pageName } : IntegrationBreadCrumbsProps) {
  const classes = useStyles()

  return (
    <Breadcrumbs aria-label='breadcrumb' classes={{ li: classes.crumb, ol: classes.crumbWrapper }}>
      <Link style={{ color: '#7A7A7A' }} to='/settings/integrations'>
        Integrations Page
      </Link>
      <Typography color='textPrimary' classes={{ root: classes.typography }}>{pageName}</Typography>
    </Breadcrumbs>
  )
}
