import { makeStyles } from '@material-ui/core/styles'
import dateFormat from 'dateformat'

const useStyles = makeStyles(theme => ({
  customTooltip: {
    color: '#505050',
    background: '#FFFFFF',
    boxShadow: '0px 3px 10px #00000071',
    borderRadius: 6,
    opacity: 1,
    padding: 10
  },
  tooltipRow: {
    paddingTop: 2,
    paddingBottom: 2
  }
}))

interface StatsTooltipProps {
  id: any
  stats: any
}

export const StatsTooltip = (props: StatsTooltipProps): JSX.Element => {
  const classes = useStyles()
  const stats = props.stats
  if (stats) {
    const lastConvo = stats.last_conversation ? dateFormat(stats.last_conversation, 'mmm dd, yyyy') : 'Never'
    const lastEdit = stats.last_edit ? dateFormat(stats.last_edit, 'mmm dd, yyyy') : 'Never'
    return (
      <div className={classes.customTooltip}>
        <div className={classes.tooltipRow}><b>Conversations:</b> {stats.conversations}</div>
        <div className={classes.tooltipRow}><b>Last Chat:</b> {lastConvo}</div>
        <div className={classes.tooltipRow}><b>Meetings Booked:</b> {stats.meetings_booked}</div>
        <div className={classes.tooltipRow}><b>Bots Created:</b> {stats.bots_created}</div>
        <div className={classes.tooltipRow}><b>Bots Edited:</b> {stats.bots_edited}</div>
        <div className={classes.tooltipRow}><b>Last Bot Edit:</b> {lastEdit}</div>
      </div>
    )
  } else {
    return (
      <></>
    )
  }
}
