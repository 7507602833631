import EventEmitter from 'eventemitter3'

enum EVENT_TYPE {
  DATA_CHANGED = 'DATA_CHANGED',
  OPTIMISTIC_UPDATE = 'OPTIMISTIC_UPDATE',
  HELP_CENTER = 'HELP_CENTER',
  NOTIFICATIONS = 'NOTIFICATIONS',
  CLOSE_DRAWER = 'CLOSE_DRAWER',
  CSV_DOWNLOAD = 'CSV_DOWNLOAD',
  API_ERROR = 'API_ERROR',
  API_SUCCESS = 'API_SUCCESS',
  SALESLOST_TASK = 'SALESLOFT_TASK',
  CLOSE_GEAR_POPUPS = 'CLOSE_GEAR_POPUPS',
  BROWSER_BACK = 'BROWSER_BACK',
  FORM_FOLLOWUP_MISSING = 'FORM_FOLLOWUP_MISSING',
  FULLSCREEN = 'FULLSCREEN'
}

const eventEmitter = new EventEmitter()

const Emitter = {
  on: (event: EVENT_TYPE, fn: any) => eventEmitter.on(event, fn),
  once: (event: EVENT_TYPE, fn: any) => eventEmitter.once(event, fn),
  off: (event: EVENT_TYPE, fn: any) => eventEmitter.off(event, fn),
  emit: (event: EVENT_TYPE, payload: object) => eventEmitter.emit(event, payload)
}

Object.freeze(Emitter)

export { Emitter, EVENT_TYPE }
