import { makeStyles } from '@material-ui/core/styles'
import { Select } from 'cf-components/material-wrappers/MaterialComponents'
import LeadScoreSelector from 'library/contacts/LeadScoreSelector'
import { IconButton } from 'library/materialUI/Button'
import { useEffect } from 'react'

const useStyles = makeStyles(theme => ({
  leadScoreContainer: {
    width: '80%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '10px',
    marginLeft: '10px',
    marginRight: '5px'
  },
  input: {
    width: '450px'
  }
}))

interface LeadScoreSelectProps {
    idx: number
    allLeadSettings: any
    leadSetting: any
    dispatch: (value: any) => void
    setCanSave: (value: any) => void
}

export default function LeadScoreSelect ({ idx, allLeadSettings, leadSetting, dispatch, setCanSave }: LeadScoreSelectProps) {
    const classes = useStyles()
    const scoreTypes = [
      { value: 'lead_score_submit', label: 'Submitted the form', disabled: allLeadSettings.some((setting: any) => setting.type === 'lead_score_submit') },
      { value: 'lead_score_live_chat', label: 'Started a chat', disabled: allLeadSettings.some((setting: any) => setting.type === 'lead_score_live_chat') },
      { value: 'lead_score_book_meeting', label: 'Booked a meeting', disabled: allLeadSettings.some((setting: any) => setting.type === 'lead_score_book_meeting') }
    ]

    function getDefaultScore (value: string) {
      switch (value) {
        case 'lead_score_submit':
          return 1
        case 'lead_score_live_chat':
          return 2
        case 'lead_score_book_meeting':
          return 3
        default:
          return -1
      }
    }

    useEffect(() => {
      if (leadSetting.type && leadSetting.score === undefined) {
        dispatch({ type: 'updateLeadScore', value: { type: leadSetting.type, score: 1 }, index: idx })
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
      <div style={{ display: 'flex', marginTop: '10px' }}>
        <div className={classes.input}>
          <Select
            onChange={(value: string) => {
              dispatch({ type: 'updateLeadScore', value: { type: value, score: leadSetting.score !== -1 ? leadSetting.score : getDefaultScore(value) }, index: idx });
              setCanSave(true)
            }}
            value={leadSetting.type}
            options={scoreTypes}
          // disableClear
          />
        </div>
        {(leadSetting.score || leadSetting.score === 0) && (
          <div className={classes.leadScoreContainer}>
            <LeadScoreSelector score={leadSetting.score} setScore={(score: number) => { dispatch({ type: 'updateLeadScore', value: { type: leadSetting.type, score: score }, index: idx }); setCanSave(true) }} />
            <IconButton icon='close' onClick={() => { dispatch({ type: 'leadRemove', index: idx }); setCanSave(true) }} />
          </div>
        )}
      </div>
    )
}
