import { useState, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Drawer } from 'library/materialUI'
import { HelpCenterHome } from './HelpCenterHome'
import { PlaysCenter } from './plays/PlaysHome'
import { SectionComponent } from './SectionComponent'
import { HelpCenterContext, HelpCenterContextProvider } from './HelpCenterContext'
import { Breadcrumbs } from './Breadcrumbs'
import { ScrollBox } from 'cf-components/ScrollBox'
import { DrawerHeader } from 'library/flowBuilder/canvas/SettingsDrawer'
import { BotsCenter } from './bots/BotsHome'
import { Emitter, EVENT_TYPE } from 'emitter'
import { SettingsCenter } from './settings/SettingsHome'
import { FeedbackCenter } from './feedback/FeedbackHome'

const useStyles = makeStyles(theme => ({
  drawerContents: {
    padding: 15,
    width: 370
  }
}))

const sectionMapping = {
  plays: PlaysCenter,
  bots: BotsCenter,
  settings: SettingsCenter,
  feedback: FeedbackCenter
}

export type SectionProps = {
  links: string[]
  type?: string
}

function HelpCenterContents () {
  const { pathLinks } = useContext(HelpCenterContext)

  if (pathLinks.length === 1) {
    return (
      <HelpCenterHome />
    )
  }

  return (
    <SectionComponent
      sectionMapping={sectionMapping}
      links={pathLinks}
    />
  )
}

function HelpCenter () {
  return (
    <div>
      <Breadcrumbs />
      <HelpCenterContents />
    </div>
  )
}

export function HelpCenterDrawer () {
  const [open, setOpen] = useState(false)
  const classes = useStyles()
  return (
    <Drawer
      open={open}
      onClose={() => setOpen(false)}
      anchor='right'
      variant='persistent'
      style={{ zIndex: 12000 }}
    >
      <DrawerHeader
        title='Help Center'
        handleClose={() => {
          Emitter.emit(EVENT_TYPE.CLOSE_DRAWER, {})
          setOpen(false)
        }}
      />
      <ScrollBox noScrollBar>
        <div className={classes.drawerContents}>
          <HelpCenterContextProvider
            openHelpCenter={() => setOpen(true)}
            closeHelpCenter={() => setOpen(false)}
          >
            <HelpCenter />
          </HelpCenterContextProvider>
        </div>
      </ScrollBox>
    </Drawer>
  )
}
