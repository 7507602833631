import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Link from '@material-ui/core/Link'
import {
  INTEGRATIONS
} from 'api/integrations'

const useStyles = makeStyles(theme => ({
  chatContainer: {
    marginLeft: '60px'
  },
  link: {
    cursor: 'pointer'
  },
  eventContainer: {
    paddingBottom: 0,
    marginBottom: 15,
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    fontSize: '12px',
    color: '#555555',
    fontStyle: 'italic'
  },
  timeSent: {
    fontSize: '12px',
    color: '#929292',
    marginLeft: 10
  }
}))

function CreateContact (props) {
  const classes = useStyles()
  // const ts = props.group.events.create_contact[0].attributes.created_timestamp
  const data = props.group.events.create_contact[0].attributes.data
  let integration = data.integration
  const keys = Object.keys(INTEGRATIONS)
  for (const k in keys) {
    if (INTEGRATIONS[keys[k]] === integration) {
      integration = keys[k]
      break
    }
  }
  if (!integration) {
    integration = data.crmURL
  }
  // name attr came later, show id for older chats
  const listIdentifer = 'listName' in data ? data.listName : data.listID
  return (
    <div className={classes.eventContainer}>
      Created contact (
      {data.crmURL ? (
        <Link
          className={classes.link}
          target='_blank'
          underline='hover'
          href={data.crmURL}
          variant='outlined' rel='noreferrer'
        >
          {data.crmID}
        </Link>
      ) : (
        <>{data.crmID}</>
      )}
      ) in {integration}
      {data.listID ? (
        <>, added to list {listIdentifer}</>
      ) : (
        <></>
      )}
      {data.timeSent ? (
        <> - {data.timeSent}</>
      ) : (
        <></>
      )}
    </div>
  )
}

export default CreateContact
