import OktaName from 'img/okta-name.png'
import { Button, Divider, TextField, Typography } from 'library/materialUI';
import { useMemo, useReducer } from 'react';
import User from 'classes/users';
import { CopyIconButton } from 'library/buttons/CopyIconButton';
import PasswordTextField from 'library/textFields/PasswordTextField';
import { defaultOktaIntegrationData, OktaIntegrationSettings, OktaSettingsSchema } from 'classes/oktaIntegrationSettings';
import IntegrationsBreadCrumbs from './IntegrationsBreadcrumbs';
import { Formik } from 'formik';
import Loader from 'library/loading/Loader'
import FormikError from 'library/typography/FormikError';
import { integrationUseStyles } from './IntegrationPageStyles';

const reducer = (state: any, changes: any) => {
  return { ...state, ...changes }
}

export default function OktaIntegrationPage () {
  const classes = integrationUseStyles()
  const { settings, isLoading } = OktaIntegrationSettings.load(undefined)
  const [integrationSettings, dispatch] = useReducer(reducer, defaultOktaIntegrationData)
  const { data: user } = User.loadMe()
  const loginURL = `https://${window.location.hostname}/#/login/okta#` + user.tenantID
  const loginDisplay = integrationSettings.id ? loginURL : 'Complete Okta set up'

  useMemo(() => {
    if (!isLoading && settings?.id) {
      dispatch(settings)
    }
  }, [settings, isLoading])

  if (isLoading) {
    return (
      <div>
        <Loader type='spinner' />
      </div>
    )
  }

  return (
    <>
      <IntegrationsBreadCrumbs
        pageName='SSO Okta'
      />
      <div className={classes.container}>
        <div style={{ display: 'flex', marginBottom: 50, alignItems: 'center' }}>
          <img src={OktaName} alt='okta' style={{ width: '20%' }} />
        </div>
        <Typography variant='body2' className={classes.bodyText}>
          Okta's Signals integration helps to maintain high security standards,
          allows for a central hub for your organizations IT assets,
          and helps your users manage access to Signals.
        </Typography>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Divider
            className={classes.divider}
          />
          <Formik
            validationSchema={OktaSettingsSchema}
            enableReinitialize
            initialValues={integrationSettings}
            validateOnChange
            validateOnBlur
            onSubmit={() => OktaIntegrationSettings.save(integrationSettings)}
          >
            {({ submitForm, errors }) => (
              <div>
                <div className={`${classes.backgroundDiv} ${classes.marginBottom}`}>
                  <Typography variant='h1' style={{ marginBottom: 10 }}>
                    Your Company Login page
                  </Typography>
                  <Typography variant='subtitle2' className={classes.marginBottom}>
                    Once your okta integration is set up, users from your organization must log in from this link. We recommend making it easily
                    accessible to your Signals users and encouraging them to bookmark it.
                  </Typography>
                  <div className={classes.center}>
                    <TextField
                      value={loginDisplay}
                      variant='outlined'
                      disabled
                      fullWidth
                      size='small'
                    />
                    <div style={{ marginLeft: 20 }}>
                      <CopyIconButton
                        content={loginDisplay}
                        disabled={!integrationSettings.id}
                      />
                    </div>
                  </div>
                </div>
                <div className={classes.backgroundDiv}>
                  <Typography variant='h1' style={{ marginBottom: 10 }}>
                    Setup
                  </Typography>
                  <Typography variant='subtitle2' className={classes.subtitle2}>
                    Client Id
                  </Typography>
                  {'clientId' in errors && <FormikError errorText={errors.clientId} />}
                  <TextField
                    onChange={(value: any) => dispatch({ clientId: value.target.value as string })}
                    value={integrationSettings.clientId}
                    variant='outlined'
                    size='small'
                    fullWidth
                  />
                  <Typography variant='subtitle2' className={classes.subtitle2}>
                    Client Domain
                  </Typography>
                  {'authDomain' in errors && <FormikError errorText={errors.authDomain} />}
                  <TextField
                    onChange={(value: any) => dispatch({ authDomain: value.target.value })}
                    value={integrationSettings.authDomain}
                    variant='outlined'
                    size='small'
                    fullWidth
                  />
                  <Typography variant='subtitle2' className={classes.subtitle2}>
                    Client Secret
                  </Typography>
                  {'clientSecret' in errors && <FormikError errorText={errors.clientSecret} />}
                  <PasswordTextField
                    onChange={(value: any) => dispatch({ clientSecret: value.target.value })}
                    value={integrationSettings.clientSecret}
                    type='password'
                    variant='outlined'
                    size='small'
                    fullWidth
                  />
                  <Typography variant='subtitle2' className={classes.subtitle2}>
                    Company Name
                  </Typography>
                  {'companyName' in errors && <FormikError errorText={errors.companyName} />}
                  <TextField
                    onChange={(value: any) => dispatch({ companyName: value.target.value })}
                    value={integrationSettings.companyName}
                    variant='outlined'
                    size='small'
                    fullWidth
                  />
                </div>
                <Button
                  className={classes.actionButton}
                  type='submit'
                  color='primary'
                  variant='contained'
                  onClick={() => submitForm()}
                  style={{ marginBottom: 50, width: 50 }}
                >
                  Save
                </Button>
              </div>)}
          </Formik>
        </div>
      </div>
    </>
  )
}
