import { makeStyles } from '@material-ui/core/styles';

export const integrationUseStyles = makeStyles(theme => ({
  container: {
    margin: 45
  },
  headerImage: {
    height: 125
  },
  bodyText: {
    marginBottom: 25,
    fontSize: 16
  },
  actionButton: {
    marginTop: 10,
    paddingLeft: 50,
    paddingRight: 50
  },
  subtitle2: {
    fontSize: 15,
    fontWeight: 600,
    marginBottom: 10
  },
  backgroundDiv: {
    backgroundColor: theme.palette.background.lightPurple,
    padding: 20
  },
  center: {
    display: 'flex',
    alignItems: 'center'
  },
  divider: {
    marginBottom: 20,
    height: '2px',
    width: '95%'
  },
  marginBottom: {
    marginBottom: 15
  },
  input: {
    background: 'white'
  }
}))
