
const descriptionText = "The Alert Agent skill can be used to alert an agent, a group of agents, or a team about a visitor's interactions on your website."

const Description = (): JSX.Element => {
  return (
    <div>
      <div>
        {descriptionText}
      </div>
      <ul>
        <li><a href='#/user_management/teams' target='_blank'>Manage Teams</a></li>
        <li><a href='https://help.getsignals.ai/article/hy96c0do9e-bot-builder-and-play-runner-routing' target='_blank' rel='noreferrer'>Routing Information</a></li>
      </ul>
    </div>
  )
}

const bestPracticeText = 'Use this skill when you want your agents to know about specific traffic that is occurring on your website, where actionable follow-up can be taken by the notified agent.'

const BestPractices = () => {
  return (
    <div>
      <div>
        {bestPracticeText}
      </div>
      <ul>
        <li>Whenever possible route to the account owner</li>
        <li>
          Only alert agents when there are specific actions that can be
          taken to follow up on the visitor's interaction with your website
        </li>
        <li>
          NOTE: Alerts can be set via this skill or within a bot's Alert
          Agent skill. It is important to keep track of whether alerts will
          be managed within bots, or if they will be managed at the play
          level. Decide this early on and keep to whatever convention was
          chosen in order to help make managing your alerts easy.
        </li>
      </ul>
    </div>
  )
}

export const alertAgentDocs = {
  helpCenterLink: 'https://help.getsignals.ai/article/s7z55h4cw9-plays-alert-agent-node',
  bestPractices: BestPractices,
  description: Description,
  videoLink: 'https://youtu.be/hm0mrhaHTmw'
}
