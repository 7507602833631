import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Select, MenuItem, OutlinedInput } from '@material-ui/core'
import './components.css'

const useStyles = makeStyles(theme => ({
  selectInput: {
    padding: 8,
    paddingLeft: 10,
    borderRadius: 30
  },
  inputRoot: {
    borderRadius: 30
  },
  select: {
    fontFamily: 'Poppins, sans serif',
    color: '#5C5C5C',
    borderRadius: 30
  }
}))

function RoundedSelect (props) {
  const classes = useStyles()

  return (
    <div className='select'>
      <Select
        style={{ width: props.width || 200, lineHeight: props.lineHeight, fontSize: props.fontSize || '1em', borderRadius: 30, height: props.height || 'unset' }}
        variant='outlined'
        className={classes.select}
        classes={{ outlined: classes.select }}
        margin='dense'
        input={<OutlinedInput classes={{ input: classes.selectInput, root: classes.inputRoot }} />}
        value={props.value}
        onChange={e => props.setValue(e.target.value)}
      >
        {props.label &&
          <MenuItem
            style={{ fontSize: props.fontSize || '1em', padding: props.padding || 4 }}
            disabled
            value=''
          >
            {props.label}
          </MenuItem>}
        {
          props.options.map((option, index) => (
            <MenuItem
              key={index}
              value={option.value}
              style={{ fontSize: props.fontSize || '1em', padding: props.padding || 4 }}
            >
              {option.name}
            </MenuItem>
          ))
        }
      </Select>
    </div>
  )
}

export default RoundedSelect
