import { makeStyles } from '@material-ui/core/styles';
import { EditableChip } from 'library/EditableChip';
import ButtonTextField from 'library/textFields/ButtonTextField';
import { useState } from 'react';

const useStyles = makeStyles(theme => ({
  benefitChipDiv: {
    display: 'flex',
    flexFlow: 'wrap'
  }
}))

function BenefitChip (props: any) {
  const benefit = props.benefit

  return (
    <EditableChip
      value={benefit}
      handleRemove={() => props.removeBenefit(benefit)}
      handleChange={(value) => props.editBenefit(props.index, value)}
    />
  )
}

type BenefitsEditorProps = {
  benefits: string[]
  handleSave: (value: any) => any
}
export function BenefitsEditor ({ benefits, handleSave }: BenefitsEditorProps) {
  const classes = useStyles()
  const [label, setLabel] = useState('')

  const removeBenefit = (label: string) => {
    const newBenefits = benefits.filter(benefit => benefit !== label)
    handleSave(newBenefits)
  }

  const editBenefit = (index: number, label: string) => {
    const newBenefits = [...benefits]
    newBenefits[index] = label
    handleSave(newBenefits)
  }

  const addBenefit = (label: string) => {
    const newBenefits = benefits.concat(label)
    handleSave(newBenefits)
  }

  return (
    <div>
      <div className={classes.benefitChipDiv}>
        {benefits.map((benefit, index) => (
          <BenefitChip
            key={index}
            index={index}
            benefit={benefit}
            removeBenefit={removeBenefit}
            editBenefit={editBenefit}
          />
        ))}
      </div>
      <ButtonTextField
        addButton={addBenefit}
        label={label}
        setLabel={setLabel}
        placeholder='Type benefit and hit enter...'
      />
    </div>
  )
}
