import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Divider from '@material-ui/core/Divider'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import SaveIcon from '@material-ui/icons/Save'
import { Card, CardHeader, CardContent } from '@material-ui/core'
import Collapse from '@material-ui/core/Collapse'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { CopyBox } from './EmailSetup'
import { BasicModal, Modal } from 'cf-components/Modal'
import CustomizedSnackbar from 'components/CustomizedSnackbar'
import { updateEmailSettings } from 'api/emails'
import InputAdornment from '@material-ui/core/InputAdornment'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  settingsPage: {
    padding: 20
  },
  header: {
    color: '#000',
    fontWeight: 600,
    fontSize: '16px'
  },
  divider: {
    marginTop: 10,
    marginBottom: 10
  },
  section: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 35
  },
  textField: {
    width: 300
  },
  card: {
    border: '1px solid rgba(142,90,226,0.5)',
    marginBottom: 15,
    backgroundColor: 'rgba(142,90,226,0.01)',
    overflow: 'visible !important',
    width: 900,
    maxWidth: '100%'
  },
  cardHeader: {
    cursor: 'pointer',
    padding: 10
  },
  cardTitle: {
    fontSize: '1.1em'
  },
  cardAction: {
    margin: 0
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  },
  dnsFields: {
    display: 'grid',
    gridTemplateColumns: '3fr 4fr 4fr',
    gridColumnGap: 30,
    gridRowGap: 8,
    color: '#AAA',
    fontSize: '11px'
  }
}))

function SectionHeader (props) {
  const classes = useStyles()

  return (
    <div className={classes.header}>
      {props.title}
      <Divider className={classes.divider} />
    </div>
  )
}

function DNSCard (props) {
  const classes = useStyles()
  const [expanded, setExpanded] = useState(props.expanded || false)
  const [snackState, setSnackState] = useState({
    open: false,
    variant: 'success',
    message: 'Value copied to clipboard!'
  })

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  const domainData = props.settings.postmark_domain_data
  const domain = props.settings?.sender_domain || ''
  let host = domainData?.DKIMPendingHost || domainData?.DKIMHost || ''
  const dkimValue = domainData?.DKIMPendingTextValue || domainData?.DKIMTextValue || ''
  let returnPath = domainData.ReturnPathDomain || ''
  if (host.endsWith(domain)) {
    host = host.slice(0, -domain.length - 1)
  }
  if (returnPath.endsWith(domain)) {
    returnPath = returnPath.slice(0, -domain.length - 1)
  }

  return (
    <Card className={classes.card}>
      <CardHeader
        title='DNS Info'
        className={classes.cardHeader}
        classes={{ title: classes.cardTitle, action: classes.cardAction }}
        onClick={handleExpandClick}
        action={
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded
            })}
            aria-expanded={expanded}
            aria-label='show more'
          >
            <ExpandMoreIcon />
          </IconButton>
        }
      />
      <Collapse in={expanded} timeout='auto' unmountOnExit>
        <CardContent>
          <div className={classes.dnsFields}>
            <div>Record Type</div>
            <div>Name</div>
            <div>Content</div>
            <CopyBox value='TXT' setSnackState={setSnackState} />
            <CopyBox value={host} setSnackState={setSnackState} />
            <CopyBox value={dkimValue} setSnackState={setSnackState} />
            <CopyBox value='CNAME' setSnackState={setSnackState} />
            <CopyBox value={returnPath} setSnackState={setSnackState} />
            <CopyBox value={domainData.ReturnPathDomainCNAMEValue} setSnackState={setSnackState} />
          </div>
        </CardContent>
      </Collapse>
      <CustomizedSnackbar state={snackState} handler={setSnackState} />
    </Card>
  )
}

function SetupComplete (props) {
  const classes = useStyles()
  const [settings, setSettings] = useState(props.settings)
  const domain = settings.sender_domain
  const chatServiceUrl = props.chatServiceUrl
  const [domainModalOpen, setDomainModalOpen] = useState(false)
  const [senderModalOpen, setSenderModalOpen] = useState(false)
  const [emailPrefix, setEmailPrefix] = useState(settings.default_sender_email_prefix)
  const [name, setName] = useState(settings.default_sender_name)

  function saveSenderInfo () {
    updateEmailSettings({ chatServiceUrl, name, emailPrefix })
      .then(response => {
        setSettings(response.data.attributes)
        setSenderModalOpen(false)
      })
  }

  return (
    <div className={classes.settingsPage}>
      <SectionHeader title='Domain' />
      <div className={classes.section}>
        <TextField
          disabled
          variant='outlined'
          margin='dense'
          value={domain}
          className={classes.textField}
        />
        <IconButton style={{ marginLeft: 15 }} onClick={() => setDomainModalOpen(true)}>
          <EditIcon />
        </IconButton>
      </div>
      <SectionHeader title='Default Company Sender' />
      <div className={classes.section}>
        <TextField
          disabled
          variant='outlined'
          margin='dense'
          value={settings.default_sender_email_prefix + '@' + domain}
          className={classes.textField}
          label='Email Address'
        />
        <TextField
          disabled
          variant='outlined'
          margin='dense'
          value={settings.default_sender_name}
          style={{ marginLeft: 15 }}
          label='Name'
        />
        <IconButton style={{ marginLeft: 15 }} onClick={() => setSenderModalOpen(true)}>
          <EditIcon />
        </IconButton>
      </div>
      <SectionHeader title='DNS' />
      <div className={classes.section}>
        <DNSCard
          settings={settings}
        />
      </div>
      <BasicModal
        title='Change Domain'
        confirmButtonText='Set Up Now'
        message='If you would like to change your domain, you will be required to go through the Email Set Up Wizard again.'
        open={domainModalOpen}
        onHide={() => setDomainModalOpen(false)}
        confirmAction={props.resetDomain}
      />
      <Modal
        title='Edit Default Company Sender'
        open={senderModalOpen}
        onHide={() => setSenderModalOpen(false)}
        size='sm'
        rightButtonProps={{
          text: 'Save',
          action: saveSenderInfo,
          variant: 'outlined',
          icon: SaveIcon
        }}
        leftButtonProps={{
          text: 'Cancel',
          action: props.onHide,
          variant: 'text'
        }}
      >
        <div>
          <TextField
            variant='outlined'
            margin='dense'
            value={emailPrefix}
            onChange={(e) => setEmailPrefix(e.target.value)}
            style={{ width: 420 }}
            label='Email Prefix'
            InputProps={{
              endAdornment: <InputAdornment position='end'>{'@' + domain}</InputAdornment>
            }}
          />
          <TextField
            variant='outlined'
            margin='dense'
            value={name}
            onChange={(e) => setName(e.target.value)}
            label='Name'
            className={classes.textField}
            style={{ marginTop: 20 }}
          />
        </div>
      </Modal>
    </div>
  )
}

export default SetupComplete
