import { Surface } from 'jsplumbtoolkit'
import { pasteSelection, sendSelectionEvent } from './toolkitHelpers'

type handlerProps = {
  surface: Surface,
  event: KeyboardEvent
}

const handleDeleteSelection = ({ surface, event }: handlerProps): void => {
  const toolkit = surface.getToolkit()
  toolkit.removeFromSelection(toolkit.getNode('start'))
  toolkit.removeFromSelection(toolkit.getNode('exit'))
  toolkit.remove(toolkit.getSelection())
  sendSelectionEvent({ type: 'clear', selection: [], toolkit })
}

const handleSelectAll = ({ surface, event }: handlerProps): void => {
  const toolkit = surface.getToolkit()
  event.preventDefault()
  toolkit.setSelection(toolkit.getNodes())
}

const handleDeselectAll = ({ surface, event }: handlerProps): void => {
  const toolkit = surface.getToolkit()
  event.preventDefault()
  toolkit.clearSelection()
  sendSelectionEvent({ type: 'clear', selection: [], toolkit })
}

const handleCopySelection = ({ surface, event }: handlerProps): void => {
  const toolkit = surface.getToolkit()
  const selectedNodes = toolkit.getSelection().getNodes()
    .filter(node => node.id !== 'start' && node.id !== 'exit')
  window.copiedNodes = [...selectedNodes]
}

const shouldHandleSelection = () => {
  const sequenceEditor = document.activeElement.parentElement.id === 'flow-builder'
  const baseEditor = !['INPUT', 'TEXTAREA', 'DIV'].includes(document.activeElement.tagName)
  return sequenceEditor || baseEditor
}

const handleKeyDown = (event: KeyboardEvent): void => {
  const surface: Surface = event.currentTarget.flowBuilderSurface
  const ctrlClick = event.ctrlKey || event.metaKey
  const key = event.key
  const props = { surface, event }

  const shouldHandle = shouldHandleSelection()

  if (shouldHandle) {
    event.stopPropagation()
    if (key === 'Shift') {
      if (window.flowBuilder.mode !== 'select' && !window.flowBuilder.shift) {
        window.flowBuilder.shift = true
        window.flowBuilder.mode = 'select'
        surface.setMode('select')
      }
    }
    if (key === 'Delete') {
      handleDeleteSelection(props)
    }
    if (ctrlClick) {
      if (key === 'a') {
        handleSelectAll(props)
      } else if (key === 'd') {
        handleDeselectAll(props)
      } else if (key === 'c') {
        handleCopySelection(props)
      } else if (key === 'v') {
        pasteSelection(surface)
      }
    }
  }
}

const handleSelectionComplete = (surface) => {
  if (shouldHandleSelection() && window.flowBuilder.mode === 'select') {
    const toolkit = surface.getToolkit()
    surface.setMode('pan')
    window.flowBuilder.mode = 'pan'
    const selection = toolkit.getSelection().getNodes()
    sendSelectionEvent({ type: 'select', selection, toolkit })
  }
}

const handleKeyUp = (e: KeyboardEvent): void => {
  const surface: Surface = e.currentTarget.flowBuilderSurface
  if (event.key === 'Shift') {
    window.flowBuilder.shift = false
    handleSelectionComplete(surface)
  }
}

const handleMouseUp = (e: MouseEvent): void => {
  const surface: Surface = e.currentTarget.flowBuilderSurface
  handleSelectionComplete(surface)
}

export const addEventHandlers = (surface: Surface): void => {
  const element = document.activeElement
  element.flowBuilderSurface = surface
  element.addEventListener('keydown', handleKeyDown)
  element.addEventListener('keyup', handleKeyUp)
  element.addEventListener('mouseup', handleMouseUp)
  return element
}

export const removeEventHandlers = (element) => {
  element.removeEventListener('keydown', handleKeyDown)
  element.removeEventListener('keyup', handleKeyUp)
  element.removeEventListener('mouseup', handleMouseUp)
}
