import { makeStyles } from '@material-ui/core/styles'
import { Icon } from 'library/materialUI/Icon'
import { Drawer, Button } from 'library/materialUI'
import { useState } from 'react'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 500,
    height: '100%',
    backgroundColor: 'white'
  },
  drawerOpen: {
    transition: theme.transitions.create('height', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    height: 70,
    transition: theme.transitions.create('height', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowY: 'hidden'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#F5F5F5',
    padding: 10
  },
  startIcon: {
    marginRight: 0
  },
  label: {
    marginLeft: 2
  },
  root: {
    minWidth: 0,
    padding: 5,
    marginRight: 5
  },
  contents: {
    padding: 10
  }
}))

type SettingsOrientation = 'left' | 'right'

type SettingsDrawerProps = {
  open: boolean
  handleClose: () => void
  handleSave?: () => void
  title: string
  children: React.ReactNode
  orientation?: SettingsOrientation | 'left'
  setWidth: string
  minimize?: boolean
  clickAwayClose?: boolean
  stickyHeader?: boolean
}

export function DrawerHeader ({ title, handleClose, handleSave, minimize, minimized, setMinimized }: { title: string, handleClose: () => void, handleSave?: () => void, minimize?: boolean, minimized: boolean, setMinimized: (value: boolean) => void }): JSX.Element {
  const classes = useStyles()

  return (
    <div className={classes.header}>
      <div style={{ color: '#343434', fontStyle: 'Poppins', fontSize: 16 }}>
        {title}
      </div>
      <div>
        {minimize ? (
          <Button
            variant='text'
            size='small'
            startIcon={<Icon icon='minimize' color='#343434' />}
            onClick={() => setMinimized(!minimized)}
            classes={{
              startIcon: classes.startIcon,
              label: classes.label,
              root: classes.root
            }}
          />
        ) : <></>}
        <Button
          variant='text'
          size='small'
          startIcon={<Icon icon='close' color='#343434' />}
          onClick={handleClose}
          classes={{
            startIcon: classes.startIcon,
            label: classes.label,
            root: classes.root
          }}
        />
        {handleSave &&
          <Button
            variant='text'
            size='small'
            startIcon={<Icon icon='save' color='#343434' />}
            onClick={handleSave}
            classes={{
              startIcon: classes.startIcon,
              label: classes.label,
              root: classes.root
            }}
          >
            Save
          </Button>}
      </div>
    </div>
  )
}

export function SettingsDrawer (props: SettingsDrawerProps) {
  const classes = useStyles()
  const [minimized, setMinimized] = useState(false)
  return (
    <ClickAwayListener onClickAway={props.clickAwayClose ? () => props.handleClose() : () => null}>
      <Drawer
        open={props.open}
        variant='persistent'
        PaperProps={{ style: { position: 'absolute', zIndex: 1202, width: props.setWidth || 350, height: minimized ? 50 : '100%', overflowY: minimized ? 'hidden' : 'auto', marginLeft: props.orientation !== 'left' ? 'auto' : undefined } }}
        anchor={minimized ? 'bottom' : props.orientation}
      >
        <div style={{ backgroundColor: '#F5F5F5', zIndex: 1500, position: props.stickyHeader ? 'sticky' : 'static', top: '0px' }}>
          <DrawerHeader
            title={props.title}
            handleClose={props.handleClose}
            minimize={props.minimize}
            minimized={minimized}
            setMinimized={setMinimized}
          />
        </div>
        <div className={classes.contents}>
          {props.children}
        </div>
      </Drawer>
    </ClickAwayListener>
  )
}
