/* eslint-disable @typescript-eslint/naming-convention */
import { Email } from 'classes/classes';

// Make sure icon is a valid IconType
export const EmailTemplates = [
  {
    id: 'Whitepaper Play',
    name: 'Whitepaper Play',
    description: 'Send buying committee contacts a whitepaper or other piece of content to get them to visit your site.',
    type: 'template',
    email: new Email({
      row: {
        attributes: {
          name: 'Whitepaper Template',
          description: 'Template for sending out whitepapers to contacts found through the Signals Contact Lookup skill.',
          from_type: 'default',
          layout: '',
          subject: 'The Blueprint for [SOLVING X PROBLEM]',
          body: '<p>Hi {{ visitor.first_name or “there” }}!</p><br><p>[SOLVING PROBLEM ADDRESSED IN YOUR WHITEPAPER] can be difficult. But what if there was a guide to help you?</p><br><p>Introducing our latest whitepaper, "[WHITEPAPER/GUIDE NAME]” Tailored for leaders like you, it [BRIEF OVERVIEW OF THE SOLUTIONS COVERED IN THE WHITEPAPER].</p><br><p>[BRIEF ONE SENTENCE TAGLINE/SOLUTION STATEMENT], <u>access the report here</u> (*hyperlink your whitepaper here*).</p>',
          kind: 'rich_text',
          from_name: '',
          from_email: '',
          attachments: []
        }
      }
    }),
    icon: 'description'
  },
  {
    id: 'Event Invite Play',
    name: 'Event Invite Play',
    description: 'Invite buying committee contacts to a webinar, summit, or other event to get them to visit your site.',
    type: 'template',
    email: new Email({
      row: {
        attributes: {
          name: 'Event Invite Template',
          description: 'Template for inviting contacts from the contact lookup play to online events.',
          from_type: 'default',
          layout: '',
          subject: 'Join [# OF ESTIMATED ATTENDEES] other leaders at [EVENT NAME]',
          body: '<p>Hi {{ visitor.first_name or "there" }},</p><br><p>Register (*w/hyper link*) for [EVENT NAME]. [BRIEF DESCRIPTION OF EVENT].</p><br><p>Event Details:</p><p>📅 Date: [DATE & TIME]</p><p>🎪 Event: [EVENT NAME]</p><p>💰 Price: [EVENT COST]</p><br><p>Why Attend:</p><p>• [REASON 1]</p><p>• [REASON 2]</p><p>• [REASON 3]</p><br><p>Don’t miss out on this chance to stay ahead of the curve. Register today and join us on [DATE]. We hope to see. you there!</p>',
          kind: 'rich_text',
          from_name: '',
          from_email: '',
          attachments: []
        }
      }
    }),
    icon: 'insertInvitation'
  },
  {
    id: 'Case Study Play',
    name: 'Case Study Play',
    description: 'Send buying committee contacts a link to a case study or customer success story to get them to visit your site.',
    type: 'template',
    email: new Email({
      row: {
        attributes: {
          name: 'Case Study Template',
          description: 'Template for sending out case studies to contacts found through the Signals Contact Lookup skill.',
          from_type: 'default',
          layout: '',
          subject: 'How [WELL-KNOWN CUSTOMER] used [YOUR COMPANY] to [SOLVE PAIN POINT]',
          body: '<p>{{ visitor.first_name or “Hello” }},</p><br><p>Struggling with [PAIN POINT]? You’re not alone. Even [CUSTOMER] once struggled with it.</p><br><p>Upon leveraging [YOUR PRODUCT] though, [CUSTOMER] [ADDRESSED THEIR PAIN POINT], resulting in significant improvements. They achieved [X RESULTS], and an impressive Y% growth in [CLICKS, SALES, ETC.].</p><br><p>Eager to see how [YOUR PRODUCT] can similarly benefit your organization?</p><br><p>Explore this and other success stories <u>HERE<u> (*w/ hyperlink)</p>',
          kind: 'rich_text',
          from_name: '',
          from_email: '',
          attachments: []
        }
      }
    }),
    icon: 'task'
  },
  {
    id: 'Company Intro Play',
    name: 'Company Intro Play',
    description: 'Give buying committee contacts a quick intro to the pain points your company solves to get them to visit your site.',
    type: 'template',
    email: new Email({
      row: {
        attributes: {
          name: 'Company Intro Template',
          description: 'Template for introducing your company to contacts found through the Signals Contact Lookup skill.',
          from_type: 'default',
          layout: '',
          subject: 'Struggling with [PAIN POINT]?',
          body: '<p>Hi {{ visitor.first_name or "there" }},</p><br><p>[PAIN POINT] can be a significant hurdle for companies like yours, leading to [NEGATIVE EFFECT(S) OF PAIN POINT]. Sound familiar?</p><br><p>If so, [YOUR COMPANY] could be a great fit for {{ visitor.company_name or “your company” }}.</p><br><p>We help companies like yours [ADDRESS PAIN POINT] with:</p><p>• [SOLUTION 1: RESULT]</p><p>• [SOLUTION 2: RESULT]</p><p>• [SOLUTION 3: RESULT]</p><br><p>Learn more at [YOURWEBSITE.COM] (*w/hyperlink*)</p>',
          kind: 'rich_text',
          from_name: '',
          from_email: '',
          attachments: []
        }
      }
    }),
    icon: 'wave'
  },
  {
    id: 'Meeting Not Booked',
    name: 'Meeting Not Booked',
    description: 'This email contains a link to book a meeting to be sent to site visitors who get to a calendar drop but don\'t book a meeting',
    type: 'template',
    email: new Email({
      row: {
        attributes: {
          name: 'Meeting Not Booked',
          description: 'To be sent to anyone that gets to a calendar drop but doesn\'t book a demo',
          from_type: 'account_owner',
          layout: '',
          subject: 'Finish booking your meeting!',
          body: '<p>Hi {{ visitor.first_name or there}},</p><p><br></p><p>You were so close to scheduling that demo! We want to make sure that you have the chance to see how {{ calendar_agent.company_name or we }} can work for you and {{ visitor.company_name or your business }}.</p><p><br></p><p>Schedule that demo by clicking <a href="{{ calendar_agent.profile_page }}" rel="noopener noreferrer" target="_blank">HERE </a>👈</p><p><br></p><p>Thanks,</p><p><br></p><p>{{ calendar_agent.email_signature or "" }}</p>',
          kind: 'rich_text',
          from_name: '',
          from_email: '',
          attachments: []
        }
      }
    }),
    icon: 'eventBusy'
  }
]
