import Bot from 'classes/bots'
import Email from 'classes/emails'
import Form from 'classes/forms'
import { useContext, useEffect, useState } from 'react'
import { ListPageContext } from '../ListPage'
import { makeStyles } from '@material-ui/core/styles'
import { Play } from 'classes/plays'
import { Button, Divider } from 'library/materialUI'
import { BotSequence } from 'classes/botSequences'
import ObjectTableRow from './ObjectTableRow'
import { ArrowDownward, ArrowUpward, ExpandLess, ExpandMore } from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: '#FFFFFF',
    border: '1px solid #D3D3D3',
    borderRadius: '5px',
    padding: '20px',
    marginBottom: '20px'
  },
  header: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column'
  },
  title: {
    fontSize: '20px',
    justifySelf: 'flex-start',
    marginBottom: '10px'
  },
  link: {
    color: theme.palette.primary.alt,
    fontSize: '14px',
    justifySelf: 'flex-end',
    marginLeft: 'auto',
    marginRight: '5px'
  },
  column: {
    display: 'flex',
    fontSize: '12px',
    color: '#808080',
    marginTop: '20px',
    alignItems: 'center'
  }
}))

const schemaMapping = [
  { label: 'Recent Submissions', seeAllLink: '/submissions', seeAllLabel: 'See all submissions', type: Form, schema: ['Name', 'Company', 'Submission Date', 'Last Action'], schemaConfig: '1fr 1fr 1fr 1fr' },
  { label: 'Recent Conversations', seeAllLink: '', seeAllLabel: 'See all conversations', type: Bot, schema: ['Name', 'Company', 'Conversation Date'], schemaConfig: '1fr 1fr 1fr' },
  { label: 'Bots', seeAllLink: '', seeAllLabel: '', type: BotSequence, schema: ['Name', 'Status', 'Last Fired', 'Fires This Week', 'Fires All-time'], schemaConfig: '1fr 1fr 1fr 1fr 1fr' },
  { label: 'Recent Recipients', seeAllLink: '', seeAllLabel: '', type: Email, schema: ['Name', 'Bot', 'Sent Date', 'Delivered', 'Opened', 'Clicked'], sortSchema: { Name: 'contact_id', Bot: 'bot_id', 'Sent Date': 'sent_timestamp', Delivered: 'delivered_timestamp', Opened: 'opened_timestamp', Clicked: 'clicked_timestamp' }, schemaConfig: '3fr 2fr 2fr 1fr 1fr 1fr' },
  { label: '', seeAllLink: '', seeAllLabel: '', type: Play, schema: [], schemaConfig: '' }
]

export default function ObjectTable () {
  const classes = useStyles()
  const { object, objectClass } = useContext(ListPageContext)
  const [data, setData] = useState([])
  const [sortField, setSortField] = useState<string>('name')
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc')
  const [sortedColumn, setSortedColumn] = useState<{ field: string, direction: 'asc' | 'desc' } | null>(null)
  const [showAll, setShowAll] = useState<boolean>(false)
  const [numRowsShown, setNumRowsShown] = useState(5)

  // const { data, isLoading, isError, error } = objectClass.loadRecentActivities(object.id)
  const mapping = schemaMapping.find((map) => map.type === objectClass)

  useEffect(() => {
    if (objectClass !== Play) {
      objectClass?.getRosterData(object.id)
        .then((response: any) => {
          if (response) {
            if (typeof response === 'object' && response.data) {
              setData(response.data)
            } else {
              setData(response)
            }
          }
        })
    }
    // eslint-disable-next-line
  }, [object])

  const handleSort = (field: string) => {
    if (!mapping?.sortSchema) return
    if (sortedColumn?.field === field) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')
      setSortedColumn({ field, direction: sortDirection === 'asc' ? 'desc' : 'asc' })
    } else {
      setSortField(mapping.sortSchema[field])
      setSortedColumn({ field, direction: sortDirection })
    }
  }

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const bottom = e.currentTarget.scrollHeight - e.currentTarget.scrollTop === e.currentTarget.clientHeight
    if (bottom) {
      setNumRowsShown(prev => prev + 25)
    }
  }

  const sortedData = [...data].sort((a, b) => {
    const aValue = a[sortField]
    const bValue = b[sortField]
    if (aValue === null || aValue === undefined) return sortDirection === 'asc' ? -1 : 1
    if (bValue === null || bValue === undefined) return sortDirection === 'asc' ? 1 : -1
    if (aValue < bValue) {
      return sortDirection === 'asc' ? -1 : 1
    }
    if (aValue > bValue) {
      return sortDirection === 'asc' ? 1 : -1
    }
    return 0
  })

  const displayData = showAll ? sortedData : sortedData.slice(0, 5)

  if (!data) {
    return <></>
  }

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div className={classes.title}>
          {mapping?.label}
        </div>
        {sortedData.length > 5 && (
          <div style={{ color: '#00000080' }}>
            Showing {Math.min(numRowsShown, displayData.length)} of {sortedData.length}
          </div>
        )}
        <div className={classes.link}>
          {mapping?.type === Form && (
            <div onClick={() => window.open('/#/forms/' + String(object.id) + mapping?.seeAllLink)} style={{ cursor: 'pointer' }}>
              {mapping?.seeAllLabel}
            </div>
          )}
        </div>
      </div>
      <div className={classes.column} style={{ display: 'grid', gridTemplateColumns: mapping?.schemaConfig, cursor: mapping?.sortSchema ? 'pointer' : '' }}>
        {mapping?.schema.map((item, index) => {
          return (
            <div key={index} style={{ display: 'flex', alignItems: 'center' }} onClick={() => { if (mapping?.sortSchema) handleSort(item) }}>
              {item}
              {sortedColumn?.field === item && sortedColumn?.direction === 'asc' && <ArrowUpward color='inherit' fontSize='small' />}
              {sortedColumn?.field === item && sortedColumn?.direction === 'desc' && <ArrowDownward color='inherit' fontSize='small' />}
            </div>
          )
        })}
      </div>
      <div style={{ marginTop: '20px', marginBottom: '20px' }}>
        <Divider />
      </div>
      <div style={{ overflowY: 'auto', maxHeight: '420px' }} onScroll={handleScroll}>
        {displayData.slice(0, numRowsShown).map((attributes: any) => (
          <div key={attributes.id}>
            <ObjectTableRow attributes={attributes} schemaConfig={mapping?.schemaConfig || ''} type={mapping?.type} />
          </div>
        ))}
      </div>
      {sortedData.length > 5 && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            variant='text'
            onClick={() => {
              showAll ? setNumRowsShown(5) : setNumRowsShown(prev => prev + 25)
              setShowAll(!showAll)
            }}
          >
            {showAll ? 'Show Less' : 'Show More'}
            {showAll ? <ExpandLess /> : <ExpandMore />}
          </Button>
        </div>
      )}
    </div>
  )
}
