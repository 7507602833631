import { makeStyles } from '@material-ui/core/styles'
import { getOrderedPorts } from 'library/flowBuilder/FlowBuilder'
import { ModalSection } from 'library/Modal'
import { LabeledCheckbox, Button, Icon } from 'library/materialUI'
import { BranchingGroup } from '../branching/BranchingGroup'
import * as yup from 'yup'
import SegmentModal from 'pages/segments/SegmentModal'
import { Segment } from 'classes/segments'
import { useState } from 'react'

const useStyles = makeStyles(theme => ({
  explanationText: {
    fontSize: '14px',
    marginBottom: 15
  }
}))

const FilterSchema = yup.object().shape({
  prop: yup.string().min(1),
  cmp: yup.string().min(1),
  value: yup.mixed().when('cmp', {
    is: 'in',
    then: yup.array().min(1),
    otherwise: yup.mixed().when('prop', {
      is: 'url',
      then: yup.array().min(1),
      otherwise: yup.mixed().when('cmp', {
        is: (cmp: string) => cmp === 'gt' || cmp === 'lt' || cmp === 'eq',
        then: yup.number().min(0),
        otherwise: yup.string().min(1)
      })
    })
  })
})

export const PortSchema = yup.object().shape({
  id: yup.string(),
  label: yup.string().min(1),
  filters: yup.mixed().when('id', {
    is: (id: string) => id !== 'default',
    then: yup.array().of(yup.array().of(FilterSchema).min(1))
  })
})

export const BranchingNodeSchema = yup.object().shape({
  ports: yup.array().of(PortSchema).min(1)
})

type BranchType = 'bot' | 'url' | 'segment' | 'geography' | 'integration'

type BranchingModalProps = {
  state: any
  dispatch: any
  branchType: BranchType
  subtitle: string
}

export function BranchingModal ({ state, dispatch, branchType, subtitle }: BranchingModalProps): JSX.Element {
  const classes = useStyles()

  const [createModalOpen, setCreateModalOpen] = useState(false)

  const isValid = BranchingNodeSchema.isValidSync(state)

  const disabled = state.ports?.length ? !isValid : false

  const addFilter = (portID: number, filterProp: string, groupIndex: number) => {
    dispatch({ type: 'addNewFilter', id: portID, prop: filterProp, groupIndex })
  }

  const editFilter = (portID: number, filterIndex: number, cmp: string, value: any, groupIndex: number, label: string) => {
    dispatch({ type: 'editFilter', id: portID, filterIndex, cmp, value, groupIndex, label })
  }

  const deleteFilter = (portID: number, filterIndex: number, groupIndex: number) => {
      dispatch({ type: 'deleteFilter', id: portID, filterIndex, groupIndex })
  }

  const editLabel = (portID: number, label: string) => {
    dispatch({ type: 'label', id: portID, value: label })
  }

  const sortedPorts = getOrderedPorts(state.ports, state.portOrder)

  const { data: segments } = Segment.loadAll({
    searchParams: {
      filters: [[
        { field: 'object_type', operator: 'eq', value: 'domains' },
        { field: 'name', operator: 'ne', value: 'ICP' }
      ]]
    }
  })

  return (
    <div>
      <div className={classes.explanationText}>
        {subtitle}
      </div>
      <div>
        <ModalSection
          title='Branches'
        >
          <div style={{ marginTop: 10, marginBottom: 10 }}>
            <div>
              {sortedPorts.filter((p: any) => p.id !== 'default').map((p: any, index: number) => {
              const addOrGroup = () => dispatch({ type: 'orGroup', id: p.id })
              const removePort = () => dispatch({ type: 'removePort', id: p.id })
              const movePort = (direction: 'up' | 'down') => dispatch({ type: 'reorder', id: p.id, direction })
              const removeOrGroup = (groupIndex: number) => dispatch({ type: 'removeOrGroup', id: p.id, groupIndex })
              return (
                <BranchingGroup
                  port={p}
                  key={index}
                  addFilter={addFilter}
                  editFilter={editFilter}
                  deleteFilter={deleteFilter}
                  editLabel={editLabel}
                  addOrGroup={addOrGroup}
                  removePort={removePort}
                  movePort={movePort}
                  removeOrGroup={removeOrGroup}
                  first={index === 0}
                  last={index === sortedPorts.filter(p => p.id !== 'default').length - 1}
                  branchType={branchType}
                  setCreateModalOpen={setCreateModalOpen}
                />
              )
              })}
            </div>
            <div>
              <Button
                variant='outlined'
                color='primary'
                startIcon={<Icon icon='add' />}
                size='small'
                onClick={() => dispatch({ type: 'addBranch' })}
                disabled={disabled}
              >
                New Branch
              </Button>
            </div>
          </div>
          <LabeledCheckbox
            label="Include a fallback path for visitors who don't meet the criteria"
            checked={state.settings.includeFallback}
            onChange={() => dispatch({ type: 'toggleFallback' })}
          />
        </ModalSection>
      </div>
      <SegmentModal
        setSegmentModalOpen={setCreateModalOpen}
        currentSegments={segments.list}
        objectType='dynamic'
        open={createModalOpen}
      />
    </div>
  )
}
