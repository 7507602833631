import { IconType } from 'library/materialUI/Icon'

export const OPEN_OPPORTUNITY = 'open_opportunity'
export const ICP_MATCH = 'icp_match'
export const CONTACTS_CREATED = 'contacts_created'
export const MEETINGS_BOOKED = 'meetings_booked'
export const HIGH_INTENT_PAGE_VISITS = 'high_intent_page_visits'
export const LIVE_CHATS = 'live_chats'
export const PAGE_VIEWS = 'page_views'
export const TIME_ON_SITE = 'time_on_site'
export const TIME_ON_PAGE = 'time_on_page'
export const TRAFFIC_SOURCE = 'traffic_source'
export const URL_VISITS = 'url_visits'
export const SITE_VISITS = 'site_visits'
export const EMAIL_CAPTURES = 'email_captures'
export const PHONE_CAPTURES = 'phone_captures'
export const CONVERSATIONS = 'conversations'
export const SCROLL_PRECENTAGE = 'scroll_percent'
export const TITLE_DEPTH = 'title_depth'
export const UNIQUE_VISITORS = 'unique_visitors'

export function getIcon (factor: string): IconType {
  switch (factor) {
    case TRAFFIC_SOURCE:
      return 'smallPath'
    case ICP_MATCH:
      return 'smallICP'
    case URL_VISITS:
      return 'smallPointer'
    case HIGH_INTENT_PAGE_VISITS:
      return 'smallPointer'
    case PAGE_VIEWS:
      return 'smallPointer'
    case TIME_ON_SITE:
      return 'smallPointer'
    case LIVE_CHATS:
      return 'smallPlaymaker'
    case CONTACTS_CREATED:
      return 'smallPlaymaker'
    case MEETINGS_BOOKED:
      return 'smallPlaymaker'
    case OPEN_OPPORTUNITY:
      return 'smallPlaymaker'
  }
  return 'smallPointer'
}

export function importanceToString (importance: string): string {
  switch (importance) {
    case 'not_very':
      return 'Not Very Important'
    case 'somewhat':
      return 'Somewhat Important'
    case 'important':
      return 'Important'
    case 'very':
      return 'Very Important'
    case 'extremely':
      return 'Extremely Important'
    default:
      return ''
  }
}

export function factorToString (factor: string): string {
  switch (factor) {
    case URL_VISITS:
      return 'URL Visits'
    case HIGH_INTENT_PAGE_VISITS:
      return 'High Intent Page Visits'
    case PAGE_VIEWS:
      return 'Page Views'
    case MEETINGS_BOOKED:
      return 'Number of Meetings Booked'
    case TIME_ON_SITE:
      return 'Time on Site (Seconds)'
    case TIME_ON_PAGE:
      return 'Time on Page (Seconds)'
    case OPEN_OPPORTUNITY:
      return 'Open Opportunity'
    case ICP_MATCH:
      return 'ICP Match'
    case TRAFFIC_SOURCE:
      return 'Traffic Source'
    case LIVE_CHATS:
      return 'Number of Live Chats'
    case CONTACTS_CREATED:
      return 'Number of Contacts Created'
    case SITE_VISITS:
      return 'Site Visits'
    case EMAIL_CAPTURES:
      return 'Email Captures'
    case PHONE_CAPTURES:
      return 'Phone Captures'
    case CONVERSATIONS:
      return 'Any Conversations'
    case SCROLL_PRECENTAGE:
      return 'Scroll on Page (%)'
    case TITLE_DEPTH:
      return 'Number of Unique Titles'
    case UNIQUE_VISITORS:
      return 'Number of Unique Visitors'
    default:
      return ''
  }
}

export function factorOperatorToString (operator: string, factor: string): string {
  let pre = ''
  switch (factor) {
    case TIME_ON_SITE:
      pre = 'is '
      break
    case TIME_ON_PAGE:
      pre = 'is '
      break
    case OPEN_OPPORTUNITY:
      pre = 'is '
      break
    case ICP_MATCH:
      pre = 'is '
      break
    case TRAFFIC_SOURCE:
      pre = 'is '
      break
    default:
      pre = ''
      // pre = 'is '
      break
  }
  let first = ''
  switch (operator) {
    case 'any':
      first = 'any'
      break
    case 'eq':
      if (factor === ICP_MATCH) {
        first = 'complete match'
        break
      }
      if (factor === OPEN_OPPORTUNITY) {
        first = 'true'
        break
      }
      first = ''
      break
    case 'neq':
      if (factor === OPEN_OPPORTUNITY) {
        first = 'false'
        break
      }
      first = 'not'
      break
    case 'gt':
      first = 'greater than'
      break
    case 'gte':
      first = 'greater than or equal to'
      break
    case 'lt':
      first = 'less than'
      break
    case 'lte':
      first = 'less than or equal to'
      break
    case 'in':
      first = 'is one of'
      break
    case 'not_in':
      first = 'is not one of'
      break
    case 'includes':
      first = 'includes'
      break
    case 'not_includes':
      first = 'does not include'
      break
    default:
      first = ''
      // first = 'is'
      break
  }
  return pre + first
}

export const selectorStructure = [
  {
    name: 'Web Activity',
    items: [
      {
        name: 'URL visit',
        value: URL_VISITS
      },
      {
        name: 'Site visits',
        value: SITE_VISITS
      },
      {
        name: 'High-Intent page visit',
        value: HIGH_INTENT_PAGE_VISITS
      },
      {
        name: 'Page views',
        value: PAGE_VIEWS
      },
      {
        name: 'Time on site (seconds)',
        value: TIME_ON_SITE
      },
      {
        name: 'Time on page (seconds)',
        value: TIME_ON_PAGE
      },
      {
        name: 'Scroll percentage',
        value: SCROLL_PRECENTAGE
      },
      {
        name: 'Unique visitors',
        value: UNIQUE_VISITORS
      }
    ]
  },
  {
    name: 'Engagement',
    items: [
      {
        name: 'Contacts created',
        value: CONTACTS_CREATED
      },
      {
        name: 'Email captures',
        value: EMAIL_CAPTURES
      },
      {
        name: 'Phone captures',
        value: PHONE_CAPTURES
      },
      {
        name: 'Conversations',
        value: CONVERSATIONS
      },
      {
        name: 'Live chats',
        value: LIVE_CHATS
      },
      {
        name: 'Meetings booked',
        value: MEETINGS_BOOKED
      }
    ]
  },
  {
    name: 'Profile Match',
    items: [
      {
        name: 'ICP Match',
        value: ICP_MATCH
      },
      {
        name: 'Unique Titles',
        value: TITLE_DEPTH
      }
    ]
  },
  {
    name: 'Traffic Source',
    items: [
      {
        name: 'Came from source',
        value: TRAFFIC_SOURCE
      }
    ]
  },
  {
    name: 'Opportunity Information',
    items: [
      {
        name: 'In an open opportunity',
        value: OPEN_OPPORTUNITY
      }
    ]
  }
]
