import { makeStyles } from '@material-ui/core/styles'
import CollapsibleSearchBar from './CollapsibleSearchBar'
import SortButton from './SortButton'
import ActiveFilters from './ActiveFilters'
import FilterButton from './FilterButton'
import ExportIcon from '@material-ui/icons/CloudDownloadOutlined'
import { React, useState, useContext } from 'react'
import { SessionContext } from 'session-context'
import { Tooltip } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  filterBar: {
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: 35
  },
  filterIcons: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    marginRight: 19,
    fill: 'rgba(0, 0, 0, 0.54)',
    cursor: 'pointer',
    '&:hover': {
      fill: theme.palette.primary.main
    }
  }
}))

export default function FilterBar (props) {
  const classes = useStyles()
  const { state, dispatch, fields } = props
  const sortFields = fields.filter(f => f.sort !== false)
  const { user } = useContext(SessionContext)
  const chatServiceUrl = user.links.chat_service
  const [filterState, setFilterState] = useState({
    anchorEl: null,
    subMenu: null
  })
  return (
    <div className={classes.filterBar}>
      <div>
        {props.filterOptions &&
          <ActiveFilters
            filterOptions={props.filterOptions}
            filterState={filterState}
            setFilterState={setFilterState}
          />}
      </div>
      <div className={classes.filterIcons}>
        <CollapsibleSearchBar
          search={state.search}
          setSearch={(value) => dispatch({ type: 'search', value: value })}
        />
        <SortButton
          sortCriteria={state.sortCriteria}
          sortAscending={state.sortAscending}
          sort={(value) => dispatch({ type: 'sort', value: value })}
          sortFields={sortFields}
        />
        {props.handleExport &&
          <Tooltip title={props.exportLabel || 'Export to CSV'}>
            <ExportIcon className={classes.icon} onClick={props.handleExport} />
          </Tooltip>}
        {props.filterOptions &&
          <FilterButton
            filterOptions={props.filterOptions}
            chatServiceUrl={chatServiceUrl}
            state={filterState}
            setState={setFilterState}
          />}
      </div>
    </div>
  )
}
