declare global {
  interface Window { ChatFunnels: any; }
}

function triggerInAppBot (botID: number) {
  window.ChatFunnels._internal.loadBot(botID)
}

export {
  triggerInAppBot
}
