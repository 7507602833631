
export const allBotTypes = [
  {
    type: 'flow',
    title: 'Funnel Bot',
    contents: 'This bot has all the bells and whistles. Use it to send messages, ask questions, tag conversations, share calendars, and more.',
    icon: 'funnelBot',
    requiredPerm: null,
    requiredFlag: null
  },
  {
    type: 'welcome',
    title: 'Live Chat',
    contents: 'This bot can send a welcome message to site visitors. Any responses will automatically be routed to live chat.',
    icon: 'liveChatBot',
    requiredPerm: null,
    requiredFlag: null
  },
  {
    type: 'team_live_chat_router',
    title: 'Concierge Bot',
    contents: 'This bot can ask visitors a question and then route them to a specific team for live chat based on their answer.',
    icon: 'conciergeBot',
    requiredPerm: null,
    requiredFlag: null
  },
  {
    type: 'content_page',
    title: 'Conversational Landing Page',
    contents: 'Create a landing page that contains a standalone chat window, or display a piece of content next to a chat drawer.',
    icon: 'conversationalContentPageIcon',
    requiredPerm: 'tenant_content_landing_pages',
    requiredFlag: 'content_pages'
  },
  {
    type: 'email',
    title: 'Email',
    contents: 'This will allow you to send emails to multiple recipients on a triggered or scheduled send.',
    icon: 'email',
    requiredPerm: null,
    requiredFlag: null
  },
  {
    type: 'abe_greeting',
    title: 'ABE Live Chat',
    contents: 'If someone from your owned accounts is on your site, this bot will allow you to send a message to them from the account owner if they\'re available.',
    icon: 'target',
    requiredPerm: null,
    requiredFlag: null
  }
]
