import { makeStyles } from '@material-ui/core/styles'
import { Icon } from 'library/materialUI'

const useStyles = makeStyles(theme => ({
  displayContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: '15px',
    marginRight: '15px',
    width: '100%',
    overflow: 'hidden',
    marginTop: '25px'
  },
  otherStep: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#9E9E9E',
    fontSize: '14px',
    color: 'white',
    minWidth: 25,
    minHeight: 25,
    borderRadius: 25,
    cursor: 'pointer'
  },
  currentStep: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.alt,
    fontSize: '14px',
    color: 'white',
    minWidth: 25,
    minHeight: 25,
    borderRadius: 25,
    cursor: 'pointer'
  },
  stepText: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
    fontSize: '12px',
    alignItems: 'center',
    textAlign: 'center'
  },
  horizontalRightDivider: {
    width: '100%',
    height: '1px',
    marginTop: 'auto',
    marginBottom: 'auto',
    marginLeft: '5px',
    borderColor: '#00000080',
    borderTopWidth: '1px',
    borderTopStyle: 'solid'
  },
  horizontalLeftDivider: {
    width: '100%',
    height: '1px',
    marginTop: 'auto',
    marginBottom: 'auto',
    marginRight: '5px',
    borderColor: '#00000080',
    borderTopWidth: '1px',
    borderTopStyle: 'solid'
  }
}))

interface SignalsScoringStepDisplayProps {
  step: number
  setStep: (step: number) => void
}

export function SignalsScoringStepDisplay ({ step, setStep }: SignalsScoringStepDisplayProps) {
  const classes = useStyles()
  const stepMapping = [
    { label: 'Opportunity Types' },
    { label: 'Deal Cycle' },
    { label: 'Buying Committee' },
    { label: 'Factors' },
    { label: 'Summary' },
    { label: 'Email Updates' }
  ]

  return (
    <div className={classes.displayContainer}>
      <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
        {stepMapping.map((stepItem, index) => (
          <div key={index} style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center' }}>
            {index !== 0 && (
              <div className={classes.horizontalLeftDivider} />
            )}
            <div onClick={() => setStep(index + 1)} className={(index + 1) <= step ? classes.currentStep : classes.otherStep} style={{ marginLeft: index === 0 ? '40%' : 0, marginRight: index === 5 ? '40%' : 0 }}>
              {step < index + 2 ? index + 1 : <Icon icon='check' size='sm' />}
            </div>
            {index < 5 && (
              <div className={classes.horizontalRightDivider} />
            )}
          </div>
        ))}
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', width: '100%', marginTop: '5px' }}>
        {stepMapping.map((stepItem, index) => (
          <div key={index} className={classes.stepText}>
            {stepItem.label}
          </div>
        ))}
      </div>
    </div>
  )
}
