import { makeStyles } from '@material-ui/core/styles'
import { Checkbox } from './materialUI'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
    container: {
        borderRadius: 5,
        padding: 10,
        marginTop: 10,
        marginBottom: 10
    },
    selected: {
        border: '1px solid #9933FF'
    },
    unselected: {
        border: '1px solid #D3D3D3'
    },
    contentContainer: {
      paddingLeft: 10
    }
}))

interface ContentCheckboxProps {
    label: string
    checked: boolean
    onChange: (checked: boolean) => void
    children: JSX.Element
    disabled?: boolean
    noBorder?: boolean
}

export default function ContentCheckbox ({ label, checked, onChange, children, disabled, noBorder }: ContentCheckboxProps): JSX.Element {
  const classes = useStyles()
  return (
    <div
      className={clsx({
        [classes.container]: true,
        [classes.selected]: checked && !noBorder,
        [classes.unselected]: !checked && !noBorder
      })}
    >
      <Checkbox checked={checked} onChange={onChange} label={label} disabled={disabled} />
      <div className={classes.contentContainer}>{checked && children}</div>
    </div>
  )
}
