import { Modal, ModalSection } from 'library/Modal'

interface UnsavedChangesModalProps {
  modalOpen: any
  setModalOpen: (b: boolean) => any
  save: () => any
  cancelAction: () => any
  saveDisabledState?: boolean
  itemName?: string
  modalBody?: string
}

function UnsavedChangesModal (props: UnsavedChangesModalProps) {
  const itemName = props.itemName || 'page'
  let modalText = `You currently have unsaved changes on this ${itemName}. If you leave now, those changes will be lost.`
  if (props.modalBody) {
    modalText = props.modalBody
  }
  if (props.saveDisabledState) {
    modalText = `You currently have unsaved changes on this ${itemName} that can’t be saved because the ${itemName} is incomplete. If you leave now, those changes will be lost.`
  }

  return (
    <Modal
      title='Unsaved Changes'
      saveBtnText={props.saveDisabledState ? 'Continue editing' : 'Save Changes'}
      open={props.modalOpen}
      onHide={() => props.setModalOpen(false)}
      handleSave={() => {
        if (!props.saveDisabledState) {
          props.save()
        }
        props.setModalOpen(false)
      }}
      saveIcon={props.saveDisabledState ? 'edit' : 'save'}
      cancelBtnText='Leave without saving'
      closeBtnFunction={props.cancelAction}
      size='xs'
    >
      <ModalSection>
        {modalText}
      </ModalSection>
    </Modal>
  )
}

export default UnsavedChangesModal
