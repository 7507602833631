/* eslint-disable @typescript-eslint/naming-convention */
import { CardContent, Container } from '@material-ui/core'
import { CheckCircle } from '@material-ui/icons'
import { setSnoozeTimestamp } from 'api/warning_email_settings'
import { Button, Card } from 'library/materialUI'
import { useEffect, useState } from 'react'

export default function WarningEmailSnoozePage (): any {
  const [snoozeType, setSnoozeType] = useState<string>()
  const [snoozeDuration, setSnoozeDuration] = useState<string>()

  const handleSnooze = (): void => {
    const snoozeUntilDate = new Date()
    snoozeUntilDate.setDate(snoozeUntilDate.getDate() + 7)
    snoozeUntilDate.toISOString()
    setSnoozeDuration('duration_week')
    snoozeType && setSnoozeTimestamp(snoozeType, snoozeUntilDate)
  }
  const handleDismiss = (): void => {
    const distantFuture = new Date()
    distantFuture.setDate(distantFuture.getDate() + (365 * 60))
    distantFuture.toISOString()
    setSnoozeDuration('duration_forever')
    snoozeType && setSnoozeTimestamp(snoozeType, distantFuture)
  }
  const handleUndoSnooze = (): void => {
    const rn = new Date()
    rn.setDate(rn.getDate() - 1)
    rn.toISOString()
    setSnoozeDuration('duration_none')
    snoozeType && setSnoozeTimestamp(snoozeType, rn)
  }

  document.title = 'Email Warning Snoozed - Signals'

  useEffect(() => {
    const url = window.location.href
    if (url.includes('uninstalled_script')) {
      setSnoozeType('uninstalled_script')
    } else if (url.includes('hubspot_disconnected')) {
      setSnoozeType('hubspot_disconnected')
    } else if (url.includes('salesforce_disconnected')) {
      setSnoozeType('salesforce_disconnected')
    } else if (url.includes('salesloft_disconnected')) {
      setSnoozeType('salesloft_disconnected')
    } else if (url.includes('sendinblue_disconnected')) {
      setSnoozeType('sendinblue_disconnected')
    } else if (url.includes('eloqua_disconnected')) {
      setSnoozeType('eloqua_disconnected')
    } else if (url.includes('marketo_disconnected')) {
      setSnoozeType('marketo_disconnected')
    }

    if (url.includes('duration_week')) {
      setSnoozeDuration('duration_week')
    } else if (url.includes('duration_forever')) {
      setSnoozeDuration('duration_forever')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (snoozeType) {
      if (snoozeDuration === 'duration_week') {
        handleSnooze()
      } else if (snoozeDuration === 'duration_forever') {
        handleDismiss()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [snoozeType])

  return (
    <>
      <div style={{ height: '100%', width: '100%', backgroundColor: '#f6f2f8' }}>
        <Container style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Card style={{ padding: 24, width: 650 }}>
            <CardContent style={{ textAlign: 'center', padding: 12 }}>
              <CheckCircle fontSize='large' style={{ color: 'green' }} />
              {snoozeDuration === 'duration_week' &&
                <>
                  <h2>This email has been snoozed for the next 7 days.</h2>
                  <Button variant='outlined' style={{ margin: 5, color: 'black', borderColor: 'gray' }} onClick={handleUndoSnooze}>Undo Snooze</Button>
                  <Button variant='outlined' style={{ margin: 5, color: 'black', borderColor: 'gray' }} onClick={handleDismiss}>Permanently Dismiss</Button>
                </>}
              {
              snoozeDuration === 'duration_forever' &&
                <>
                  <h2>This email has been permanently dismissed.</h2>
                  <Button variant='outlined' style={{ margin: 5, color: 'black', borderColor: 'gray' }} onClick={handleUndoSnooze}>Undo Dismiss</Button>
                  <Button variant='outlined' style={{ margin: 5, color: 'black', borderColor: 'gray' }} onClick={handleSnooze}>Snooze for 7 days instead</Button>
                </>
              } {
              snoozeDuration === 'duration_none' &&
                <>
                  <h2>This email is no longer snoozed.</h2>
                  <Button variant='outlined' style={{ margin: 5, color: 'black', borderColor: 'gray' }} onClick={handleSnooze}>Snooze for 7 days</Button>
                  <Button variant='outlined' style={{ margin: 5, color: 'black', borderColor: 'gray' }} onClick={handleDismiss}>Permanently Dismiss</Button>
                </>
              }
            </CardContent>
          </Card>
        </Container>
      </div>
    </>
  )
}
