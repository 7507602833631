import React, { useEffect, useRef, useState } from 'react'
import { getConversationSummary } from 'api/reports'
import { createSummaryChart } from './charts/CreateSummaryChart.js'
import Loader from 'library/loading/Loader'
import { convertDateRange } from 'cf-components/FilterBar/FilterBar'

function ConversationSummary (props) {
  const chartRef = useRef(null)
  const [visibility, setVisibility] = useState('hidden')

  useEffect(() => {
    // This is so the loading icon shows up when switching filters
    setVisibility('hidden')

    const attributes = convertDateRange(props.dateValues)
    attributes.source_filter = props.sourceFilter

    if (props.dateValues) {
      if (chartRef.current) {
        chartRef.current.dispose()
      }
      getConversationSummary({ attributes }).then(
        response => {
          const id = 'chartdiv'
          const chart = createSummaryChart({
            data: response.data.attributes.chart_data,
            labels: props.labels,
            id,
            setVisible: setVisibility
          })
          chartRef.current = chart
        }
      )
    }
    return (
      () => {
        if (chartRef.current) {
          chartRef.current.dispose()
        }
      }
    )
  }, [props.dateValues, props.labels, props.sourceFilter])

  return (
    <>
      <div
        style={{
          display: visibility === 'visible' ? 'none' : 'block',
          ...props.loaderStyles
        }}
      >
        <Loader type='spinner' />
      </div>
      <div style={{ height: 370 }}>
        <div id='chartdiv' style={{ width: '100%', height: '100%' }} />
      </div>
    </>
  )
}

export default ConversationSummary
