import SalesloftIFrame from './SalesloftIFrame'
import { useLocation } from 'react-router-dom'

export default function SalesloftIFrameWindow () {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const type = queryParams.get('type')
  const id = queryParams.get('id') || ''

  return (
    <div>
      <SalesloftIFrame type={type || ''} id={id} />
    </div>
  )
}
