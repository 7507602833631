import { Modal, ModalSection } from 'library/Modal'
import { makeStyles } from '@material-ui/core/styles'
import { useReducer } from 'react'
import { reducer } from 'library/helpers'
import { TextBox } from 'library/materialUI'
import { BenefitsEditor } from './BenefitsEditor'
import { ChargifyOffer } from 'classes/chargifyOffer'
import { Select } from 'cf-components/material-wrappers/MaterialComponents'

const useStyles = makeStyles(theme => ({
  offerDetailsGrid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: 15,
    marginBottom: 15
  },
  deleteButton: {
    maxHeight: '40px',
    borderRadius: '5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer'
  }
}))

const offerTypes = [
  { value: 1, label: 'Starter Plan' },
  { value: 2, label: 'Team Plan' },
  { value: 3, label: 'Company Plan' },
  { value: 4, label: 'Premium Plan' }
]

interface OfferModalProps {
  onHide: any
  offer: ChargifyOffer
}

export function ChargifyOfferModal (props: OfferModalProps) {
  const classes = useStyles()
  const [state, dispatch] = useReducer(reducer, props.offer)

  const saveOffer = () => {
    ChargifyOffer.save(state).then(() => props.onHide())
  }

  const deleteOffer = () => {
    ChargifyOffer.delete(state.id).then(() => props.onHide())
  }

  return (
    <Modal
      open
      title='Edit Offer'
      handleSave={saveOffer}
      onHide={props.onHide}
      saveIcon='save'
    >
      <div>
        <ModalSection
          title='Offer Info'
        >
          <div className={classes.offerDetailsGrid}>
            <Select
              value={state.offerType}
              options={offerTypes}
              onChange={(value: number) => dispatch({ offerType: value })}
              placeholder='Plan Type'
            />
            <TextBox
              value={state.name}
              onChange={(value: string) => dispatch({ name: value })}
              placeholder='Offer Name'
            />
            <TextBox
              value={state.annualOfferID}
              onChange={(value: string) => dispatch({ annualOfferID: value })}
              placeholder='Annual Offer ID'
            />
            <TextBox
              value={state.annualCostInCents}
              onChange={(value: string) => dispatch({ annualCostInCents: value })}
              placeholder='Annual Cost in Cents'
            />
            <TextBox
              value={state.monthlyOfferID}
              onChange={(value: string) => dispatch({ monthlyOfferID: value })}
              placeholder='Monthly Offer ID'
            />
            <TextBox
              value={state.monthlyCostInCents}
              onChange={(value: string) => dispatch({ monthlyCostInCents: value })}
              placeholder='Monthly Cost in Cents'
            />
          </div>
        </ModalSection>
        <ModalSection
          title='Benefits'
        >
          <BenefitsEditor
            benefits={state.benefits}
            handleSave={(value: string[]) => dispatch({ benefits: value })}
          />
        </ModalSection>
        <ModalSection
          title="What's Missing?"
        >
          <BenefitsEditor
            benefits={state.missingBenefits}
            handleSave={(value: string[]) => dispatch({ missingBenefits: value })}
          />
        </ModalSection>
        <div>
          <div className={classes.deleteButton} onClick={() => deleteOffer()}>
            Delete Offer
          </div>
        </div>
      </div>
    </Modal>
  )
}
