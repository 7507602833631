function getAllTags ({ token, chatServiceUrl }) {
  const headers = {
    'Content-Type': 'application/vnd.api+json',
    Authorization: `Bearer ${token}`
  }
  const url = new URL(`https://${chatServiceUrl}/tags?include=deleted`)
  return fetch(url, {
    method: 'GET',
    headers: headers
  })
    .then(response => response.json())
}

/**
 * @param {{token: any, chatServiceUrl: string, conversationID?: number;}} param0
 */
function getTags ({ token, chatServiceUrl, conversationID }) {
  const headers = {
    'Content-Type': 'application/vnd.api+json',
    Authorization: `Bearer ${token}`
  }
  const url = new URL(`https://${chatServiceUrl}/tags`)

  if (conversationID) {
    const params = {}
    params['filter[conversation_id]'] = conversationID

    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
  }

  return fetch(url, {
    method: 'GET',
    headers: headers
  })
    .then(response => response.json())
}

function saveTag ({ tag_name, description, is_goal, token, chatServiceUrl, id }) {
  const headers = {
    'Content-Type': 'application/vnd.api+json',
    Authorization: `Bearer ${token}`
  }
  let method, url

  if (id == null) {
    url = new URL(`https://${chatServiceUrl}/tags`)
    method = 'POST'
  } else {
    method = 'PATCH'
    url = new URL(`https://${chatServiceUrl}/tags/` + id)
  }

  const body = {
    data: {
      type: 'tags',
      attributes: {
        tag_name: tag_name,
        description: description,
        is_goal: is_goal
      },
      relationships: {}
    }
  }

  return fetch(url, {
    method: method,
    headers: headers,
    body: JSON.stringify(body)
  })
    .then(response => response.json())
}

function createTag ({ tag_name, description, is_goal, token, chatServiceUrl }) {
  const headers = {
    'Content-Type': 'application/vnd.api+json',
    Authorization: `Bearer ${token}`
  }
  const url = new URL(`https://${chatServiceUrl}/tags`)
  const body = {
    data: {
      type: 'tags',
      attributes: {
        tag_name: tag_name,
        description: description,
        is_goal: is_goal
      },
      relationships: {}
    }
  }

  return fetch(url, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(body)
  })
    .then(response => response.json())
}

function deleteTags ({ tag_ids, token, chatServiceUrl }) {
  const headers = {
    'Content-Type': 'application/vnd.api+json',
    Authorization: `Bearer ${token}`
  }
  const method = 'DELETE'
  const path = new URL(`https://${chatServiceUrl}/tags`)
  const body = JSON.stringify({
    data: {
      attributes: {
        tag_ids: tag_ids
      }
    }
  })
  return fetch(path, {
    method: method,
    headers: headers,
    body: body
  })
}

function tagSearch ({ search, token, chatServiceUrl }) {
  const headers = {
    'Content-Type': 'application/vnd.api+json',
    Authorization: `Bearer ${token}`
  }

  const url = new URL(`https://${chatServiceUrl}/tags:search`)
  const params = {}
  if (search) {
    params['filter[search]'] = search
  }

  Object.keys(params).forEach(key =>
    url.searchParams.append(key, params[key])
  )

  return fetch(url, {
    method: 'GET',
    headers: headers
  })
    .then(response => response.json())
}

function batchTagConversations ({ chatServiceUrl, conversationIDs, tagIDs, token }) {
  const headers = {
    'Content-Type': 'application/vnd.api+json',
    Authorization: `Bearer ${token}`
  }

  const url = new URL(`https://${chatServiceUrl}/conversations:add_tags`)
  const body = {
    data: {
      type: 'tags',
      attributes: {
        conversation_ids: conversationIDs,
        tag_ids: tagIDs
      },
      relationships: {}
    }
  }

  return fetch(url, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(body)
  })
    .then(response => response.json())
}

export { getTags, createTag, deleteTags, tagSearch, saveTag, getAllTags, batchTagConversations }
