import { doGet } from 'api/api'
import { useDoTypeQuery } from './useDoQuery'

export interface IntegrationUsers {
  email: string
  id: string
  name: string
}

const getPath = '/integrations/crm/users'

export function GetIntegrationUsers<T> (integration: string) {
  const { data, isLoading, isError, error } = useDoTypeQuery<T>({
    path: getPath,
    extraHeaders: { 'x-integration': integration }
  })
  return { data: data as T[], isLoading, isError, error }
}

export function SelectConvert (user: IntegrationUsers) {
  return { label: user.name, value: user.id }
}

export function GetMappedIntegrationUserID (integration: string, userID: number) {
  return doGet({ path: `/api/integrations/${integration}/${userID}` })
}
