import React, { useRef } from 'react'
import { makeStyles, ThemeProvider } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import Slide from '@material-ui/core/Slide'
import FlowPreview from './FlowPreview'
import Button from '@material-ui/core/Button'
import RestoreIcon from '@material-ui/icons/Restore'
import theme from 'theme'

const useStyles = makeStyles(theme => ({
  paper: {
    margin: 0
  },
  paperFullWidth: {
    width: '100%'
  },
  paperScrollPaper: {
    maxHeight: '100%'
  },
  dialogContents: {
    height: '100vh',
    width: '100%',
    display: 'grid',
    gridTemplateRows: '60px auto'
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '12px 25px',
    borderBottom: '1px solid rgba(0,0,0,0.2)'
  },
  title: {
    fontFamily: 'Poppins, sans serif',
    fontWeight: 600,
    color: 'rgba(0,0,0,0.65)',
    fontSize: '1.4em'
  },
  buttons: {
    display: 'flex'
  },
  button: {
    marginRight: 20
  }
}))

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

function PreviewHeader (props) {
  const classes = useStyles()
  const handleClose = props.close
  const handleRestore = props.restoreFlow

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.header}>
        <div className={classes.title}>
          {props.title || 'Edit Sequence'}
        </div>
        <div style={{ display: 'flex' }}>
          {handleRestore ? (
            <div className={classes.buttons}>
              <Button
                startIcon={<RestoreIcon />}
                variant='outlined'
                className={classes.button}
                color='primary'
                onClick={handleRestore}
              >
                Restore Flow
              </Button>
            </div>) : <></>}
          <div className={classes.buttons}>
            <Button
              variant='outlined'
              className={classes.button}
              color='primary'
              onClick={handleClose}
            >
              Exit Preview
            </Button>
          </div>
        </div>
      </div>
    </ThemeProvider>
  )
}

function FlowPreviewModal (props) {
  const classes = useStyles()
  const dataRef = useRef(null)
  const handleClose = props.close
  const title = props.sequence ? 'Sequence Flow Preview' : 'Bot Flow Preview'

  return (
    <Dialog
      open={props.open}
      TransitionComponent={Transition}
      // onEntered={() => {}} deprecated
      maxWidth='xl'
      fullWidth
      onClose={handleClose}
      classes={{
        paper: classes.paper,
        paperFullWidth: classes.paperFullWidth,
        paperScrollPaper: classes.paperScrollPaper
      }}
    >
      <div className={classes.dialogContents}>
        <PreviewHeader
          title={title}
          close={props.close}
          restoreFlow={props.restoreFlow}
        />
        <div style={{ backgroundColor: 'rgba(0,0,0,0.7)' }}>
          <FlowPreview
            flowID={props.flowID}
            dataRef={dataRef}
            sequence={props.sequence}
          />
        </div>
      </div>
    </Dialog>
  )
}

export default FlowPreviewModal
