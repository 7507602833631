const oceania = [
  'American Samoa',
  'Australia',
  'Christmas Island',
  'Cocos (Keeling) Islands',
  'Cook Islands',
  'Fiji',
  'French Polynesia',
  'Guam',
  'Heard Island and McDonald Islands',
  'Jersey',
  'Kiribati',
  'Marshall Islands',
  'Micronesia (Federated States of)',
  'Nauru',
  'New Caledonia',
  'New Zealand',
  'Niue',
  'Norfolk Island',
  'Northern Mariana Islands',
  'Palau',
  'Papua New Guinea',
  'Pitcairn',
  'Samoa',
  'Solomon Islands',
  'Tokelau',
  'Tonga',
  'Tuvalu',
  'United States Minor Outlying Islands',
  'Vanuatu',
  'Wallis and Futuna'
]

const africa = [
  'Algeria',
  'Angola',
  'Benin',
  'Botswana',
  'British Indian Ocean Territory (the)',
  'Burkina Faso',
  'Burundi',
  'Cabo Verde',
  'Cameroon',
  'Central African Republic (the)',
  'Chad',
  'Comoros',
  'Congo',
  'Congo (the Democratic Republic of the)',
  "Côte d'Ivoire",
  'Djibouti',
  'Egypt',
  'Equatorial Guinea',
  'Eritrea',
  'Eswatini',
  'Ethiopia',
  'French Southern Territories',
  'Gabon',
  'Gambia',
  'Ghana',
  'Guinea',
  'Guinea-Bissau',
  'Kenya',
  'Lesotho',
  'Liberia',
  'Libya',
  'Madagascar',
  'Malawi',
  'Mali',
  'Mauritania',
  'Mauritius',
  'Mayotte',
  'Morocco',
  'Mozambique',
  'Namibia',
  'Niger',
  'Nigeria',
  'Réunion',
  'Rwanda',
  'Saint Helena, Ascension and Tristan da Cunha',
  'Sao Tome and Principe',
  'Senegal',
  'Seychelles',
  'Sierra Leone',
  'Somalia',
  'South Africa',
  'South Sudan',
  'Sudan (the)',
  'Tanzania, United Republic of',
  'Togo',
  'Tunisia',
  'Uganda',
  'Western Sahara',
  'Zambia',
  'Zimbabwe'
]

const europe = [
  'Åland Islands',
  'Albania',
  'Andorra',
  'Austria',
  'Belarus',
  'Belgium',
  'Bosnia and Herzegovina',
  'Bulgaria',
  'Croatia',
  'Czechia',
  'Denmark',
  'Estonia',
  'Faroe Islands',
  'Finland',
  'France',
  'Germany',
  'Gibraltar',
  'Greece',
  'Guernsey',
  'Holy See',
  'Hungary',
  'Iceland',
  'Ireland',
  'Isle of Man',
  'Italy',
  'Latvia',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Malta',
  'Moldova (the Republic of)',
  'Monaco',
  'Montenegro',
  'Netherlands (the)',
  'Norway',
  'Poland',
  'Portugal',
  'Republic of North Macedonia',
  'Romania',
  'Russian Federation (the)',
  'San Marino',
  'Serbia',
  'Spain',
  'Svalbard and Jan Mayen',
  'Slovakia',
  'Slovenia',
  'Sweden',
  'Switzerland',
  'Ukraine',
  'United Kingdom of Great Britain and Northern Ireland'
]

const asia = [
  'Afghanistan',
  'Armenia',
  'Azerbaijan',
  'Bahrain',
  'Bangladesh',
  'Bhutan',
  'Brunei Darussalam',
  'Cambodia',
  'China',
  'Cyprus',
  'Georgia (Country)',
  'Hong Kong',
  'India',
  'Indonesia',
  'Iran (Islamic Republic of)',
  'Iraq',
  'Israel',
  'Japan',
  'Jordan',
  'Kazakhstan',
  "Korea (the Democratic People's Republic of)",
  'Korea (the Republic of)',
  'Kuwait',
  'Kyrgyzstan',
  "Lao People's Democratic Republic (the)",
  'Lebanon',
  'Macao',
  'Malaysia',
  'Maldives',
  'Mongolia',
  'Myanmar',
  'Nepal',
  'Oman',
  'Pakistan',
  'Palestine, State of',
  'Philippines (the)',
  'Qatar',
  'Saudi Arabia',
  'Singapore',
  'Sri Lanka',
  'Syrian Arab Republic',
  'Taiwan',
  'Tajikistan',
  'Thailand',
  'Timor-Leste',
  'Turkey',
  'Turkmenistan',
  'United Arab Emirates (the)',
  'Uzbekistan',
  'Viet Nam',
  'Yemen'
]

const americas = [
  'United States',
  'Anguilla',
  'Antigua and Barbuda',
  'Argentina',
  'Aruba',
  'Bahamas',
  'Barbados',
  'Belize',
  'Bermuda',
  'Bolivia (Plurinational State of)',
  'Bonaire, Sint Eustatius and Saba',
  'Bouvet Island',
  'Brazil',
  'Canada',
  'Cayman Islands (the)',
  'Chile',
  'Colombia',
  'Costa Rica',
  'Cuba',
  'Curaçao',
  'Dominica',
  'Dominican Republic (the)',
  'Ecuador',
  'El Salvador',
  'Falkland Islands (the) [Malvinas]',
  'French Guiana',
  'Greenland',
  'Grenada',
  'Guadeloupe',
  'Guatemala',
  'Guyana',
  'Haiti',
  'Honduras',
  'Jamaica',
  'Martinique',
  'Mexico',
  'Montserrat',
  'Nicaragua',
  'Panama',
  'Paraguay',
  'Peru',
  'Puerto Rico',
  'Saint Barthélemy',
  'Saint Kitts and Nevis',
  'Saint Lucia',
  'Saint Martin (French part)',
  'Saint Pierre and Miquelon',
  'Saint Vincent and the Grenadines',
  'Sint Maarten (Dutch part)',
  'South Georgia and the South Sandwich Islands',
  'Suriname',
  'Trinidad and Tobago',
  'Turks and Caicos Islands (the)',
  'Uruguay',
  'Venezuela (Bolivarian Republic of)',
  'Virgin Islands (British)',
  'Virgin Islands (U.S.)'
]

const provinces = [
  'Alberta',
  'British Columbia',
  'Manitoba',
  'New Brunswick',
  'Newfoundland and Labrador',
  'Northwest Territories',
  'Nova Scotia',
  'Nunavut',
  'Ontario',
  'Prince Edward Island',
  'Quebec',
  'Saskatchewan',
  'Yukon'
]

const states = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming'
]

export { states, provinces, americas, asia, africa, europe, oceania }
