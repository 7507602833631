import ABEDashboard from './ABEDashboard'

interface DashboardContentsProps {
  chatServiceUrl: string
  modalOpen: string | null
  mode: string
  setPotentialAccountModalOpen: (value: boolean) => void
  setModalDomain: (value: string) => void
  accountAdded: string[]
}

export default function DashboardContents ({ chatServiceUrl, modalOpen, setPotentialAccountModalOpen, setModalDomain, accountAdded }: DashboardContentsProps) {
    return (
      <ABEDashboard
        chatServiceUrl={chatServiceUrl}
        modalOpen={modalOpen}
        setPotentialAccountModalOpen={setPotentialAccountModalOpen}
        setModalDomain={setModalDomain}
        accountAdded={accountAdded}
      />
    )
}
