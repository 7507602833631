import React, { useState, useEffect } from 'react'
import { jsPlumbUtil } from 'jsplumbtoolkit'
import { ThemeProvider } from '@material-ui/core/styles'
import OptionPort from '../OptionPort'
import useNodeStyles from './NodeStyles'
import RenderEmoji from 'pages/chat/RenderEmoji'
import Divider from '@material-ui/core/Divider'
import PhoneIcon from '@material-ui/icons/PhoneAndroid'
import CountryCodeSelector from '../CountryCodeSelector'
import PropTypes from 'prop-types'
import 'jsplumb'
import { RichTextEditor } from 'library/richText/RichTextEditor'
import { EVENT_TYPE, Emitter } from 'emitter'
import { Modal, ModalSection } from 'library/Modal'
import theme from 'theme'
import { Select } from 'cf-components/material-wrappers/MaterialComponents'

function TabPanel (props) {
  const { children, value, index } = props

  return (
    <div
      hidden={value !== index}
    >
      {children}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

function PhoneCaptureModal (props) {
  const [tab, setTab] = useState(0)
  const [body, setBody] = useState(props.body)
  const [invalidResponse, setInvalidResponse] = useState(props.invalidResponse)
  const [secondResponse, setSecondResponse] = useState(props.secondResponse)
  const [captureTimes, setCaptureTimes] = useState(props.captureTimes)
  const [country, setCountry] = useState(props.country)

  useEffect(() => {
    setBody(props.body)
  }, [props.body])

  function editText (value, delta, source, editor) {
    setBody(value)
  }

  function editInvalidResponse (value, delta, source, editor) {
    setInvalidResponse(value)
  }

  function editSecondResponse (value, delta, source, editor) {
    setSecondResponse(value)
  }

  function cancelChanges () {
    props.onHide()
    setBody(props.body)
  }

  const handleTabChange = (event, newValue) => {
    setTab(newValue)
  }

  const handleSelectChange = (value) => {
    setCaptureTimes(value)
  }

  if (!props.open) {
    return <></>
  }

  Emitter.on(EVENT_TYPE.BROWSER_BACK, cancelChanges)

  return (
    <ThemeProvider theme={theme}>
      <Modal
        open={props.open}
        onHide={cancelChanges}
        handleSave={() => {
          props.save({ body, captureTimes, invalidResponse, secondResponse, country })
          setTab(0)
        }}
        title='Phone Capture'
        helplink='home/bots/nodes/PhoneCapture'
        helplinkLabel='Learn more about this skill'
        tabLabels={['Basic', 'Advanced']}
        tab={tab}
        handleTabChange={handleTabChange}
        saveIcon='save'
      >
        <TabPanel value={tab} index={0}>
          <ModalSection
            title='Bot Message'
            subtitle='How would you like the bot to ask for the visitor’s phone number?'
          >
            <div className='customQuill'>
              <RichTextEditor
                message={body}
                onChange={editText}
                placeholder='What is your phone number...'
                lines={3}
                showMergeFields
              />
            </div>
          </ModalSection>
          <ModalSection
            subtitle='What is the default country code?'
          >
            <CountryCodeSelector country={country} setCountry={setCountry} />
          </ModalSection>
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <ModalSection
            subtitle='How many times should the bot ask for a valid phone number?'
          >
            <Select
              value={captureTimes}
              onChange={handleSelectChange}
              options={[{ value: 1, label: 'Only one time' }, { value: 2, label: 'Up to two times' }, { value: 3, label: 'Up to three times' }]}
            />
          </ModalSection>
          <ModalSection
            subtitle='What should the bot say when a visitor responds with an invalid phone number?'
          >
            <div className='customQuill'>
              <RichTextEditor
                message={invalidResponse}
                onChange={editInvalidResponse}
                placeholder="Sorry, I didn't get that. Please enter a valid phone number..."
                lines={3}
              />
            </div>
          </ModalSection>
          <ModalSection
            subtitle='What should the bot say after a second invalid phone number?'
          >
            <div className='customQuill'>
              <RichTextEditor
                message={secondResponse}
                onChange={editSecondResponse}
                placeholder="Still didn't get that. Please enter a valid phone number..."
                lines={3}
              />
            </div>
          </ModalSection>
        </TabPanel>
      </Modal>
    </ThemeProvider>
  )
}

const PhoneCaptureNode = props => {
  const base = props.base
  const classes = useNodeStyles(props)
  const assignKey = data => {
    data._reactKey = data._reactKey || jsPlumbUtil.uuid()
    return data._reactKey
  }

  const saveChanges = ({ body, captureTimes, invalidResponse, secondResponse, country }) => {
    if (!base.toolkit) {
      return
    }
    const node = base.getNode()
    const new_data =
    {
      ...base.state,
      body: body,
      captureTimes: captureTimes,
      invalidResponse: invalidResponse,
      secondResponse: secondResponse,
      country: country
    }
    base.toolkit.updateNode(node, new_data)
    props.setModalOpen(false)
  }

  return (
    <>
      <div>
        <div
          className={`${classes.dragger} ${classes.visitorInteraction}`}
        >
          <div className='node-header'>
            <div className={classes.iconDiv}><PhoneIcon /></div>
            <div className={classes.cardTitle}>Phone Capture</div>
          </div>
          <div className='node-body'>
            <RenderEmoji
              text={base.state.body === '' ? 'Enter phone capture message...' : base.state.body}
              rich='true'
            />
            <Divider style={{ marginTop: 10 }} />
            {base.state.ports.map((c, index) => {
              return (
                <OptionPort
                  index={index}
                  kind='visitorInteraction'
                  type='phone'
                  data={c}
                  key={assignKey(c)}
                  parent={base}
                />
              )
            })}
          </div>
          <jtk-target
            scope='default'
          />
        </div>
      </div>
      <PhoneCaptureModal
        open={props.modalOpen}
        onHide={() => props.setModalOpen(false)}
        save={saveChanges}
        body={base.state.body}
        invalidResponse={base.state.invalidResponse}
        secondResponse={base.state.secondResponse}
        captureTimes={base.state.captureTimes}
        country={base.state.country}
      />
    </>
  )
}

export default PhoneCaptureNode
