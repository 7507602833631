import React, { useState, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles(theme => ({
  modalText: {
    paddingLeft: 10,
    padding: 20,
    fontSize: 20
  },
  button: {
    fontSize: 16,
    paddingLeft: 30,
    paddingRight: 30
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}))

// This button passes the setter "setLoading", which will allow you to
// set it to False once the loading is finished

// Example of call: onClick={(e, setLoading) => handleClick(e, row.id, setLoading)}

const LoadingButton = (props) => {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const Icon = props.icon
  const buttonText = props.buttonText
  const variant = props.variant || 'outlined'
  const size = props.size || 'medium'
  const btnRef = useRef()

  return (
    <>
      <div className={classes.wrapper}>
        <Button
          startIcon={Icon ? <Icon /> : <></>}
          variant={variant}
          ref={btnRef}
          disabled={props.disabled}
          color={loading ? 'secondary' : 'primary'}
          className={classes.button}
          size={size}
          onClick={(e) => {
            if (btnRef.current.disabled === false) {
              props.onClick(e, setLoading)
            }
            if (btnRef.current) {
              btnRef.current.setAttribute('disabled', 'disabled')
            }
          }}
        >
          {buttonText}
          {loading && (
            <CircularProgress
              size={24}
              color='secondary'
              className={classes.buttonProgress}
            />
          )}
        </Button>
      </div>
    </>
  )
}

export default LoadingButton
