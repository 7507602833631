import React, { useEffect, useRef, useState } from 'react'
import { getConversationTags } from '../../api/reports'
import Loader from 'library/loading/Loader'
import { createTagsChart } from './charts/CreateTagsChart'
import { convertDateRange } from 'cf-components/FilterBar/FilterBar'
import EmptyState from 'components/EmptyState'

function TagsChart (props) {
  const chartRef = useRef(null)

  const [visibility, setVisibility] = useState('hidden')
  const [empty, setEmpty] = useState(false)

  useEffect(() => {
    // This is so the loading icon shows up when switching filters
    setVisibility('hidden')

    if (props.dateValues) {
      if (chartRef.current) {
        chartRef.current.dispose()
      }
      const attributes = convertDateRange(props.dateValues)
      attributes.source_filter = props.sourceFilter
      getConversationTags({ attributes }).then(response => {
        const id = 'tagschart'
        const data = response.data.attributes.chart_data
        if (data.length === 0) {
          setEmpty(true)
        } else {
          setEmpty(false)
        }
        const chart = createTagsChart({ data, id, setVisible: setVisibility })
        chartRef.current = chart
      })
    }
    return (
      () => {
        if (chartRef.current) {
          chartRef.current.dispose()
        }
      }
    )
  }, [props.dateValues, props.sourceFilter])

  return (
    <>
      <div
        style={{
          display: visibility === 'visible' ? 'none' : 'block',
          ...props.loaderStyles
        }}
      >
        <Loader type='spinner' />
      </div>
      <div style={{ height: 360, visibility: visibility, position: 'relative' }}>
        <div id='tagschart' style={{ width: '100%', height: '100%', position: 'absolute', visibility: empty ? 'hidden' : null }} />
        {empty ? (
          <div style={{ position: 'absolute', width: '100%', height: '100%', visibility: empty ? null : 'hidden' }}>
            <EmptyState message='No conversations tagged' />
          </div>
        )
        : <></>}
      </div>
    </>
  )
}

export default TagsChart
