import { Card as MuiCard } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Text } from './TextComponent'

const useStyles = makeStyles(theme => ({
  card: {
    padding: 15
    // height: 'calc(100% - 30px)'
  },
  cardTitle: {
    marginBottom: 10
  }
}))

type variant = 'default' | 'pass' | 'fail' | 'warning'

export type CardProps = {
  contents: JSX.Element
  variant?: variant
  title?: string
  padding?: number
}

function CardHeader (props: CardProps): JSX.Element {
  const classes = useStyles()
  if (!props.title) {
    return <></>
  }

  return (
    <div className={classes.cardTitle}>
      <Text text={props.title} variant='cardTitle' />
    </div>
  )
}

const colorMapping = {
  pass: '#e7f0e7',
  fail: '#f7e6e3',
  warning: '#f7f7e3'
}

const getStyle = (props: CardProps): any => {
  const style: Record<string, any> = {}
  switch (props.variant) {
    case 'fail':
    case 'pass':
    case 'warning':
      style.backgroundColor = colorMapping[props.variant]
      break
  }
  return style
}

export function Card (props: CardProps): JSX.Element {
  const classes = useStyles()
  const style = getStyle(props)
  const padding = props.padding === 0 ? 0 : props.padding ? props.padding : 15
  style.padding = padding

  return (
    <MuiCard className={classes.card} style={{ ...style, height: `calc(100% - ${2 * padding}px)` }}>
      <CardHeader
        {...props}
      />
      <div>
        {props.contents}
      </div>
    </MuiCard>
  )
}
