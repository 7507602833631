import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

/**
 * Create a sortable by dragging list
 *
 * **IMPORTANT:** If you want to add margin/padding between the list items,
 * you must set padding on the base ctn of the component you pass into
 * props.component. See pages/chat/agent-chat/sidebar/Sidebar.jsx for an example.
 *
 * @param {any} props
 */
function DragNDrop (props) {
  const itemList = props.itemList
  const setItemList = props.setItemList
  const Component = props.component

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  function onDragEnd (result) {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const items = reorder(
      itemList,
      result.source.index,
      result.destination.index
    )

    setItemList(items)
  }

  const direction = props.direction || 'horizontal'

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId='droppable' direction={direction}>
        {(provided, snapshot) => (
          <div
            className={props.divClass}
            ref={provided.innerRef}
          >
            {
              itemList.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index} isDragDisabled={props.disabled || false}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className='no-focus-outline'
                    >
                      <Component
                        item={item}
                        {...props.componentProps}
                      />
                      {provided.placeholder}
                    </div>
                  )}
                </Draggable>
              ))
            }
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default DragNDrop
