import { useState, useContext, useEffect, Fragment } from 'react'
import { Select, FormControlLabel, MenuItem, FormHelperText, FormControl } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useQuery } from 'react-query'
import { getAllMeetingInfo, getMeetingTypes } from 'api/meetings'
import { SessionContext } from 'session-context'
import { Modal, ModalSection } from 'library/Modal'
import AccessControl from 'components/AccessControl'
import { Checkbox } from 'library/materialUI'

const useStyles = makeStyles(theme => ({
  instructions: {
    fontSize: '.9em',
    '&:not(span)': {
      marginBottom: 8
    },
    color: 'grey'
  },
  checkLabel: {
    fontSize: '.9em'
  },
  divider: {
    marginTop: 15
  }
}))

const getUserList = () => {
  const headers = {
    'Content-Type': 'application/vnd.api+json'
  }
  return fetch('/api/users?include=calendar_status', {
    method: 'GET',
    headers: headers,
    cache: 'no-store'
  })
    .then(response => response.json())
    .then(user_response => {
      let userDict = {}
      user_response.data.map(user => (userDict = { ...userDict, [user.id]: { ...user } }))
      const hasCalendar = user_response.included.filter(user => user.attributes.has_calendar === 'True').map(user => user.id)
      return { users: userDict, hasCalendar }
    })
}

const getValidUserIds = ({ hasCalendar }) => {
  let usersWithMeetingInfo
  return getAllMeetingInfo()
    .then(response => {
      usersWithMeetingInfo = response.data.map(response => response.attributes.user_id)
      const validUsers = usersWithMeetingInfo.filter(user_id => hasCalendar.includes(user_id))
      return validUsers
    })
}

function CalendarDropModal (props) {
  const classes = useStyles()
  const { user } = useContext(SessionContext)
  const [userID, setUserID] = useState(user?.id || '')
  const [selectedMeetingTypeID, setSelectedMeetingTypeID] = useState('')
  const [textMessageReminders, setTextMessageReminders] = useState(true)
  const [meetingTypeError] = useState()

  const { data: meetingTypes = [] } = useQuery('calendarDropMeetingTypes', () => getMeetingTypes().then(r => r.data), { staleTime: 15000 })
  const { data: { users, hasCalendar } = { users: [] } } = useQuery('calendarDropUserList', () => getUserList(), { staleTime: 15000 })
  const { data: validUserIds = [userID] } = useQuery('calendarDropValidUserIds', () => getValidUserIds({
    hasCalendar
  }), { staleTime: 15000, enabled: Boolean(hasCalendar?.length) })

  useEffect(() => {
    // Do we need this?? It should be ok just in the useState(user.id)?instructions
  }, [meetingTypes])

  useEffect(() => {
    if (!user.attributes.perms.tenant_text_message_meeting_reminders) {
      setTextMessageReminders(false)
    }
  }, [user.attributes.perms.tenant_text_message_meeting_reminders])

  const dropCalendar = () => {
    props.dropCalendar(userID, selectedMeetingTypeID, textMessageReminders)
    props.onHide()
  }

  const selectedID = (props.open && validUserIds?.includes(userID)) ? userID : ''

  return (
    <Modal
      title='Calendar Drop'
      open={props.open}
      onHide={() => props.onHide()}
      size='xs'
      saveBtnText='Drop calendar'
      handleSave={dropCalendar}
      saveDisabled={!selectedID || !selectedMeetingTypeID}
    >
      <div id='calendar-2'>
        <ModalSection>
          <div className={classes.instructions}>
            Select a teammate's calendar from the list below
          </div>
          <FormControl>
            <Select
              variant='outlined'
              fullWidth
              margin='dense'
              value={selectedID}
              onChange={e => setUserID(e.target.value)}
            >
              <MenuItem disabled value=''>
                Select a teammate's calendar
              </MenuItem>
              {validUserIds?.map(id => {
                const user = users[id]
                if (!user) {
                  return <Fragment key={id} />
                }
                const name = user.attributes.name
                return (
                  <MenuItem key={id} value={id}>
                    {name}
                  </MenuItem>
                )
              })}
            </Select>
            <FormHelperText>
              * If a teammate doesn't appear in the list, they need to add a calendar
            </FormHelperText>
          </FormControl>
          <div className={classes.divider} />
          <div className={classes.instructions}>
            Select a meeting type from the list below
          </div>
          <Select
            variant='outlined'
            fullWidth
            margin='dense'
            value={selectedMeetingTypeID}
            defaultValue={selectedMeetingTypeID}
            error={meetingTypeError}
            onChange={e => setSelectedMeetingTypeID(e.target.value)}
          >
            <MenuItem disabled value=''>
              Select a meeting type
            </MenuItem>
            {meetingTypes.map(meetingType => (
              <MenuItem key={meetingType.id} value={meetingType.id}>
                {meetingType.attributes.name}
              </MenuItem>
            ))}
          </Select>
          {meetingTypeError ? <FormHelperText error>Please select a value</FormHelperText> : <></>}
          <AccessControl requiredPermission='tenant_text_message_meeting_reminders'>
            <FormControlLabel
              label='Prompt visitor for text message reminders'
              classes={{ label: classes.checkLabel }}
              style={{ marginTop: 10 }}
              control={
                <Checkbox
                  checked={textMessageReminders}
                  onChange={(e) => setTextMessageReminders(e)}
                  name='autoToggle'
                  color='primary'
                />
              }
            />
          </AccessControl>
        </ModalSection>

      </div>
    </Modal>
  )
}

export default CalendarDropModal
