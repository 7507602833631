import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import EditModal from '../EditModal'
import { TextField, Divider, FormControlLabel, Switch } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  subsection: {
    marginTop: 15
  },
  divider: {
    marginTop: 5,
    marginBottom: 5
  },
  sectionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '14px',
    fontWeight: 600
  },
  label: {
    fontWeight: 600,
    fontSize: '14px'
  },
  advancedDetails: {
    fontSize: '14px',
    padding: '5px 15px'
  },
  warning: {
    color: 'red',
    fontSize: '14px',
    padding: '5px 15px'
  }
}))

function SequenceConfigModal (props) {
  const classes = useStyles()
  const [title, setTitle] = useState(props.title)
  const [description, setDescription] = useState(props.description)
  const [trackTemplate, setTrackTemplate] = useState(props.trackTemplate)

  useEffect(() => {
    setTitle(props.title)
    setDescription(props.description)
    setTrackTemplate(props.trackTemplate)
  }, [props.title, props.description, props.trackTemplate])

  function cancelChanges () {
    props.onHide()
    setTitle(props.title)
    setDescription(props.description)
  }

  if (!props.open) {
    return <></>
  }

  const showAdvanced = props.isNew || props.isTemplate
  const trackingLabel = props.isNew ? 'Create global sequence' : 'Track global sequence'
  const details = props.isNew
    ? 'You can make this sequence reusable by creating a global sequence. The global sequence can then be used in any of your other bots.'
    : 'This sequence is tracking a global sequence. When tracking is enabled, this sequence will always use the latest version of the global sequence.'

  const showWarning = !trackTemplate && !props.isNew

  return (
    <EditModal
      open={props.open}
      onHide={cancelChanges}
      save={() => props.save({ title, description, trackTemplate })}
      title='Sequence Configuration'
    >
      <div style={{ fontFamily: 'Poppins, sans serif' }}>
        <div className={classes.sectionHeader}>
          Basic Details
        </div>
        <Divider className={classes.divider} />
        <div style={{ paddingTop: 10 }}>
          <TextField
            fullWidth
            onChange={(e) => setTitle(e.target.value)}
            variant='outlined'
            margin='dense'
            label='Sequence Title'
            value={title}
          />
        </div>
        <div style={{ marginTop: 20 }}>
          <TextField
            fullWidth
            multiline
            rows={2}
            onChange={(e) => setDescription(e.target.value)}
            margin='dense'
            label='Sequence Description'
            variant='outlined'
            value={description}
          />
        </div>
        {showAdvanced &&
          <div className={classes.subsection}>
            <div className={classes.sectionHeader}>
              Advanced
              <FormControlLabel
                label={trackingLabel}
                labelPlacement='start'
                classes={{ label: classes.label }}
                control={
                  <Switch
                    checked={trackTemplate}
                    onChange={(e) => setTrackTemplate(e.target.checked)}
                    color='primary'
                  />
                }
              />
            </div>
            <Divider style={{ marginBottom: 5 }} />
            <div className={classes.advancedDetails}>
              {details}
            </div>
            <div className={classes.warning} style={{ display: showWarning ? 'block' : 'none' }}>
              Warning: this action can not be undone. Once you stop tracking the global sequence, you will not have the option to track it again later.
            </div>
          </div>}
      </div>
    </EditModal>
  )
}

export default SequenceConfigModal
