import { makeStyles } from '@material-ui/core/styles'
import { Divider, Icon, Tooltip } from './materialUI'
import TextField from '@material-ui/core/TextField'
import CriteriaGroup from './CriteriaGroup'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    padding: '20px',
    flexDirection: 'column'
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '10px'
  },
  sectionContainer: {
    marginBottom: '10px',
    marginTop: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  sectionLabel: {
    fontSize: '14px',
    marginRight: '10px'
  },
  divider: {
    marginTop: '10px',
    marginBottom: '10px'
  },
  addCondition: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    color: '#343434',
    marginTop: '20px',
    fontSize: '14px',
    fontWeight: 'bold',
    maxWidth: '180px',
    '&:hover': {
      backgroundColor: '#E3E3E3'
    },
    borderRadius: '5px',
    padding: '10px'
  }
}))

interface CriteriaBuilderProps {
  title?: string
  ruleName: string
  titleTooltip?: string
  nameSectionLabel?: string
  nameTooltip?: string
  filterSectionLabel?: string
  filterTooltip?: string
  state: any
  dispatch: (value: any) => any
  routingMap: any
}

export default function CriteriaBuilder ({ title, ruleName, titleTooltip = undefined, nameSectionLabel = 'Enter a name for your criteria', nameTooltip, filterSectionLabel = 'Add filters', filterTooltip, state, dispatch, routingMap }: CriteriaBuilderProps) {
  const classes = useStyles()
  const groups = state?.groups || []
  const ruleID = state?.id

  return (
    <div className={classes.container}>
      {title && (
        <>
          <div className={classes.title}>
            <div style={{ fontSize: '18px', fontFamily: 'poppins' }}>
              {title}
            </div>
            <div>
              {titleTooltip && (
                <Tooltip title={titleTooltip}>
                  <div>
                    <Icon icon='infoIcon' size='sm' color='#808080' />
                  </div>
                </Tooltip>
              )}
            </div>
          </div>
          <div>
            <Divider className={classes.divider} />
          </div>
        </>
      )}
      <div className={classes.sectionContainer}>
        <div className={classes.sectionLabel}>
          {nameSectionLabel}
        </div>
        {nameTooltip && (
          <Tooltip title={nameTooltip}>
            <div>
              <Icon icon='infoIcon' size='sm' color='#808080' />
            </div>
          </Tooltip>
        )}
      </div>
      <div>
        <Divider className={classes.divider} />
      </div>
      <div>
        <TextField
          value={state.name}
          onChange={(event) => {
            dispatch({ type: 'criteriaName', value: event.target.value, id: ruleID })
          }}
          margin='dense'
          variant='outlined'
        />
      </div>
      <div className={classes.sectionContainer}>
        <div className={classes.sectionLabel}>
          {filterSectionLabel}
        </div>
        {filterTooltip && (
          <Tooltip title={filterTooltip}>
            <div>
              <Icon icon='infoIcon' size='sm' color='#808080' />
            </div>
          </Tooltip>
        )}
      </div>
      <Divider className={classes.divider} />
      {groups?.map((group: any, index: number) => {
        return (
          <div key={index}>
            <CriteriaGroup
              key={index}
              groupIndex={index}
              ruleName={ruleName}
              name={`Filter Group ${index + 1}`}
              filters={group.filters}
              dispatch={dispatch}
              routingMap={routingMap}
            />
            {index !== groups.length - 1 && (
              <div style={{ display: 'flex', marginTop: '10px', marginBottom: '10px', width: '100%', alignItems: 'center' }}>
                <div style={{ width: '100%' }}>
                  <Divider className={classes.divider} />
                </div>
                <div style={{ margin: '5px' }}>
                  OR
                </div>
                <div style={{ width: '100%' }}>
                  <Divider className={classes.divider} />
                </div>
              </div>
            )}
          </div>
        )
      })}
      <div onClick={() => { dispatch({ type: 'addGroup', ruleName: ruleName }) }} className={classes.addCondition}>
        <div style={{ marginRight: '10px' }}>
          <Icon icon='circleAdd' size='md' color='#343434' />
        </div>
        <div>
          Add "OR" Condition
        </div>
      </div>
    </div>
  )
}
