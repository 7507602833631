import { makeStyles } from '@material-ui/core/styles';
import { ClickAwayListener, Divider, Icon, Popper, Typography } from 'library/materialUI';
import { iconMapping } from 'library/materialUI/Icon';
import { TableContext } from 'library/table/TableProvider';
import { useContext, useState } from 'react';
import FilterOptionMenu from './FilterOptionMenu';
import GetFilterValues from './GetFilterValues';
import { PopperPlacementType } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  filterPopup: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    justifyContent: 'top',
    backgroundColor: 'white',
    borderRadius: '10px',
    boxShadow: '0px 3px 6px #00000029',
    paddingBottom: '10px'
  },
  spacer: {
    width: '100%'
  },
  popupHeader: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: '16px',
    fontFamily: 'poppins',
    fontWeight: 'bold',
    marginLeft: '21px',
    marginRight: '21px',
    marginTop: '18px',
    justifyContent: 'space-between'
  },
  popupBody: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    justifyContent: 'center',
    marginLeft: '16px',
    cursor: 'pointer',
    paddingBottom: '10px',
    marginRight: '16px'
  },
  filterMenuRow: {
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '10px'
  }
}))

export type Filter = {
  name: string,
  values: any,
  icon: keyof typeof iconMapping,
}

interface FilterMenuProps {
  anchorEl: any
  setAnchorEl: (any: any) => void
  listFilterProps?: any
  placement?: PopperPlacementType
}

export default function FilterMenu ({ anchorEl, setAnchorEl, listFilterProps, placement }: FilterMenuProps) {
  const classes = useStyles()
  const [secondPopupAnchor, setSecondPopupAnchor] = useState<HTMLElement | null>(null)
  const [openFilter, setOpenFilter] = useState('')
  const { filterOptions, filterOptionValues, clearFilters, searchParams, handleFilter } = useContext(TableContext)
  const listFilter = (listFilterProps?.filterOptions && Object.keys(listFilterProps.filterOptions).length > 0)

  const createFilterList = (filterOptionValues: any) => {
    const htmlList = []
    for (const [key, filter] of Object.entries(listFilter ? listFilterProps.filterOptions : filterOptions)) {
      const row = (
        <div key={key + 'filterRow'}>
          <div className={classes.filterMenuRow} onClick={() => setOpenFilter(key)}>
            <Typography>{filter.title}</Typography>
            <Icon icon='arrowForward' color='black' size='sm' />
          </div>
          {(filterOptionValues[filter.field] || (listFilterProps?.initialValues && listFilterProps.initialValues[filter.field])) &&
            <div key={key + 'filtervaluesouter'}>
              <GetFilterValues
                key={key + 'filtervaluesinner'}
                filterValues={filterOptionValues[filter.field] || listFilterProps?.initialValues[filter.field]}
                filterValue={filter.filterValue}
                transformer={filter.transformer}
                filterField={filter.field}
                propsClearFilter={listFilterProps?.clearFilter}
              />
            </div>}
        </div>
      )
      htmlList.push(row)
    }
    return htmlList
  }

  const handleClickAway = () => {
    setAnchorEl(null)
    setSecondPopupAnchor(null)
  }

  return (
    <Popper
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      placement='bottom-end'
      style={{ zIndex: 1000 }}
    >
      <ClickAwayListener onClickAway={() => handleClickAway()}>
        <div>
          <div className={classes.filterPopup}>
            <div className={classes.popupHeader}>
              <Typography>
                Filters
              </Typography>
              <Typography
                color='primary'
                variant='caption'
                style={{ cursor: 'pointer', marginLeft: 30 }}
                onClick={() => {
                  clearFilters()
                  if (listFilterProps?.clearAllFilters) listFilterProps.clearAllFilters()
                }}
              >Clear All
              </Typography>
            </div>
            <Divider />
            <div className={classes.popupBody} onClick={(event) => setSecondPopupAnchor(event.currentTarget)}>
              {createFilterList(filterOptionValues)}
            </div>
          </div>
          {openFilter && secondPopupAnchor &&
            <FilterOptionMenu
              openFilter={openFilter}
              secondAnchor={secondPopupAnchor}
              setSecondAnchor={() => setSecondPopupAnchor(null)}
              listFilterProps={listFilterProps}
              filterOptions={filterOptions}
              placement={placement}
              searchParams={searchParams}
              handleFilter={handleFilter}
            />}
        </div>
      </ClickAwayListener>
    </Popper>
  )
}
