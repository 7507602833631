import { CSSProperties } from 'react'
import Avatar from '@material-ui/core/Avatar'
import EditIcon from '@material-ui/icons/Edit'
import ChatIcon from '@material-ui/icons/Chat'
import TodayIcon from '@material-ui/icons/Today'
import EmailIcon from '@material-ui/icons/Email'
import FinishedIcon from '@material-ui/icons/AssignmentTurnedIn'
import StartIcon from '@material-ui/icons/PlayCircleFilled'
import ExitIcon from '@material-ui/icons/ExitToApp'
import PlayArrow from '@material-ui/icons/PlayArrow'
import Pause from '@material-ui/icons/Pause'
import InsertInvitation from '@material-ui/icons/InsertInvitation';
import EventBusy from '@material-ui/icons/EventBusy';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer'
import LiveHelpIcon from '@material-ui/icons/LiveHelp'
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic'
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid'
import TagIcon from '@material-ui/icons/LocalOffer'
import CancelIcon from '@material-ui/icons/Cancel'
import SmileyIcon from '@material-ui/icons/Mood'
import CallToActionIcon from '@material-ui/icons/CallToAction'
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import NotificationsIcon from '@material-ui/icons/Notifications'
import SearchIcon from '@material-ui/icons/Search'
import ScheduleIcon from '@material-ui/icons/Schedule'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import CallSplitIcon from '@material-ui/icons/CallSplit'
import CloseIcon from '@material-ui/icons/Close'
import NearMeIcon from '@material-ui/icons/NearMe'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import BlockIcon from '@material-ui/icons/Block'
import StarIcon from '@material-ui/icons/Star'
import StarBorderIcon from '@material-ui/icons/StarBorder'
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck'
import LanguageIcon from '@material-ui/icons/Language'
import DateRangeIcon from '@material-ui/icons/DateRange'
import DataUsageIcon from '@material-ui/icons/DataUsage'
import AccountBoxIcon from '@material-ui/icons/AccountBox'
import PieChartIcon from '@material-ui/icons/PieChart'
import AccountTreeIcon from '@material-ui/icons/AccountTree'
import FindInPageIcon from '@material-ui/icons/FindInPage'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import Save from '@material-ui/icons/Save'
import ViewModuleIcon from '@material-ui/icons/ViewModule'
import PublicIcon from '@material-ui/icons/Public'
import DeleteIcon from '@material-ui/icons/DeleteForever'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import CheckIcon from '@material-ui/icons/Check'
import SnoozeIcon from '@material-ui/icons/Snooze'
import ChatBubbleIcon from '@material-ui/icons/ChatBubble'
import DoneOutlineIcon from '@material-ui/icons/DoneOutline'
import SettingsIcon from '@material-ui/icons/Settings'
import BarChartIcon from '@material-ui/icons/BarChart'
import ContactsIcon from '@material-ui/icons/Contacts'
import BusinessIcon from '@material-ui/icons/Business'
import LockOpenIcon from '@material-ui/icons/LockOpen'
import MinimizeIcon from '@material-ui/icons/Minimize';
import UsersIcon from '@material-ui/icons/People'
import HelpIcon from '@material-ui/icons/Help'
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows'
import TextsmsIcon from '@material-ui/icons/Textsms'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import SendIcon from '@material-ui/icons/Send'
import CodeIcon from '@material-ui/icons/Code'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ArrowDropDown from '@material-ui/icons/ArrowDropDown'
import ArrowDropDownCircle from '@material-ui/icons/ArrowDropDownCircle'
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive'
import PlaylistAdd from '@material-ui/icons/PlaylistAdd'
import CompareArrows from '@material-ui/icons/CompareArrows'
import LocationOn from '@material-ui/icons/LocationOn'
import AttachMoney from '@material-ui/icons/AttachMoney'
import MuiIcon from '@material-ui/core/Icon'
import Description from '@material-ui/icons/Description';
import MarkunreadMailboxIcon from '@material-ui/icons/MarkunreadMailbox'
import DraftsIcon from '@material-ui/icons/Drafts'
import AnnouncementIcon from '@material-ui/icons/Announcement'
import OktaLogo from 'img/Dev_Logo-02_Large.png'
import theme from 'theme'
import FullscreenIcon from '@material-ui/icons/Fullscreen'
import FullscreenExit from '@material-ui/icons/FullscreenExit'
import PersonIcon from '@material-ui/icons/Person'
import TrendingUpIcon from '@material-ui/icons/TrendingUp'
import TrendingDownIcon from '@material-ui/icons/TrendingDown'
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat'
import Refresh from '@material-ui/icons/Refresh'
import Message from '@material-ui/icons/Message'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import PhoneIcon from '@material-ui/icons/Phone'
import WarningIcon from '@material-ui/icons/Warning'
import ErrorIcon from '@material-ui/icons/Error'
import ErrorOutlinedIcon from '@material-ui/icons/ErrorOutline'
import SalesforceIcon from 'img/salesforce_icon.png'
import HubSpotIcon from 'img/hubspot.png'
import HubspotClear from 'img/hubspot_clear.png'
import MarketoIcon from 'img/marketo_icon.png'
import EloquaLogo from 'img/eloqua_logo.png'
import BrevoLogo from 'img/brevo_logo.png'
import MemoryIcon from '@material-ui/icons/Memory'
import ExpandMore from '@material-ui/icons/ExpandMore'
import ExpandLess from '@material-ui/icons/ExpandLess'
import InsertDriveFile from '@material-ui/icons/InsertDriveFile'
import Replay from '@material-ui/icons/Replay'
import EventAvailableIcon from '@material-ui/icons/EventAvailable'
import EventIcon from '@material-ui/icons/Event'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import ArrowForward from '@material-ui/icons/ArrowForward'
import CallMade from '@material-ui/icons/CallMade'
import Cached from '@material-ui/icons/Cached'
import CloudDownload from '@material-ui/icons/CloudDownload'
import InfoOutlined from '@material-ui/icons/InfoOutlined'
import Undo from '@material-ui/icons/Undo'
import Report from '@material-ui/icons/Report'
import Domain from '@material-ui/icons/Domain'
import SearchRounded from '@material-ui/icons/SearchRounded'
import PersonAddRounded from '@material-ui/icons/PersonAddRounded'
import RemoveCircle from '@material-ui/icons/RemoveCircle'
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos'
import Link from '@material-ui/icons/Link'
import LinkOff from '@material-ui/icons/LinkOff'
import AddAPhoto from '@material-ui/icons/AddAPhoto'
import Restore from '@material-ui/icons/Restore'
import VolumeUp from '@material-ui/icons/VolumeUp'
import VolumeOff from '@material-ui/icons/VolumeOff'
import Laptop from '@material-ui/icons/Laptop'
import Launch from '@material-ui/icons/Launch'
import InfoIcon from '@material-ui/icons/Info'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import ThumbDownIcon from '@material-ui/icons/ThumbDown'
import ThumbsUpDownIcon from '@material-ui/icons/ThumbsUpDown'
import TimerOutlined from '@material-ui/icons/TimerOutlined'
import FacebookIcon from '@material-ui/icons/Facebook'
import InstagramIcon from '@material-ui/icons/Instagram'
import WorkIcon from '@material-ui/icons/Work'
import BadgeIcon from '@material-ui/icons/Label'
import TextIcon from '@material-ui/icons/Notes'
import TextFields from '@material-ui/icons/TextFields'
import RadioChecked from '@material-ui/icons/RadioButtonChecked'
import CheckedBox from '@material-ui/icons/CheckBox'
import Calendar from '@material-ui/icons/CalendarToday'
import Timeline from '@material-ui/icons/Timeline'
import CircleAdd from '@material-ui/icons/ControlPoint'
import AlternateEmail from '@material-ui/icons/AlternateEmail'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import SalesloftLogo from 'img/Salesloft_Logo.png'
import SalesloftLogo2 from 'img/salesloft_transparent.png'
import SmallChromeIcon from 'img/chrome_logo_sm.png'
import SmallFirefoxIcon from 'img/firefox_logo_sm.png'
import SmallSafariIcon from 'img/safari_logo_sm.png'
import SmallEdgeIcon from 'img/edge_logo_sm.png'
import SmallBraveIcon from 'img/brave_logo_sm.png'
import SlackIcon from 'img/slack_icon.png'
import SlackIconBlack from 'img/slack_icon_black.svg'
import TeamsIcon from 'img/ms_teams_logo.png'
import TeamsIconBlack from 'img/ms_teams_icon_black.svg'
import { ReactComponent as SalesloftAssignmentAdd } from 'img/salesloft_assignment_add.svg'
import { ReactComponent as ProspectToContact } from 'img/prospectToContact.svg'
import { ReactComponent as SalesloftCadence } from 'img/salesloft_cadence.svg'
import { ReactComponent as SalesloftCall } from 'img/salesloft_call.svg'
import { ReactComponent as SalesloftClicks } from 'img/salesloft_clicks_icon.svg'
import { ReactComponent as SalesloftCreateTask } from 'img/salesloft_create_task.svg'
import { ReactComponent as SalesloftDoNotContact } from 'img/salesloft_do_not_contact.svg'
import { ReactComponent as SalesloftEmail } from 'img/salesloft_email.svg'
import { ReactComponent as SalesloftEmailRecieved } from 'img/salesloft_email_received.svg'
import { ReactComponent as SalesloftEmailReply } from 'img/salesloft_email_reply.svg'
import { ReactComponent as SalesloftEmailSend } from 'img/salesloft_email_sent.svg'
import { ReactComponent as SalesloftLinkedin } from 'img/salesloft_linkedin.svg'
import { ReactComponent as SalesloftMeetingCancel } from 'img/salesloft_meeting_cancel.svg'
import { ReactComponent as SalesloftMeeting } from 'img/salesloft_meeting.svg'
import { ReactComponent as SalesloftNote } from 'img/salesloft_note.svg'
import { ReactComponent as SalesloftReplies } from 'img/salesloft_replies_icon.svg'
import { ReactComponent as SalesloftSuccess } from 'img/salesloft_success.svg'
import { ReactComponent as SalesloftTask } from 'img/salesloft_task.svg'
import { ReactComponent as SalesloftText } from 'img/salesloft_text_message.svg'
import { ReactComponent as SalesloftViews } from 'img/salesloft_views_icon.svg'
import { ReactComponent as AutoAwesome } from 'img/auto_awesome.svg'
import { ReactComponent as AutoAwesomeYellow } from 'img/auto_awesome_yellow.svg'
import { ReactComponent as MoneySearchIcon } from 'img/MoneySearchIcon.svg'
import { ReactComponent as PinIcon } from 'img/icon-simple-pinboard.svg'
import { ReactComponent as CopyIcon } from 'img/icon-feather-copy.svg'
import { ReactComponent as TrafficSourceIcon } from 'img/traffic_source.svg'
import { ReactComponent as TrafficSourceSmall } from 'img/trafficSourceSmall.svg'
import { ReactComponent as altBotHead } from 'img/awesome-robot.svg'
import { ReactComponent as CorporateFare } from 'img/corporateIcon.svg'
import { ReactComponent as FunnelIcon } from 'img/ionic-ios-funnel.svg'
import { ReactComponent as DollarFunnelIcon } from 'img/awesome-funnel-dollar.svg'
import { ReactComponent as AtIcon } from 'img/awesome-at.svg'
import { ReactComponent as MousePointerIcon } from 'img/awesome-mouse-pointer.svg'
import { ReactComponent as Icp } from 'img/icp.svg'
import { ReactComponent as IcpCircle } from 'img/icp-circle.svg'
import { ReactComponent as PlayMakerIcon } from 'img/playrunner-icon.svg'
import { ReactComponent as LogoIcon } from 'img/new_logo.svg'
import { ReactComponent as BotIcon } from 'img/botIcon.svg'
import { ReactComponent as TestingBeakerIcon } from 'img/testing_beaker_icon.svg'
import { ReactComponent as TrashIcon } from 'img/icon-material-delete.svg'
import { ReactComponent as FilterIcon } from 'img/filterIcon.svg'
import { ReactComponent as EmailSubscribe } from 'img/emailSubscribe.svg'
import { ReactComponent as DownArrow } from 'img/down_arrow.svg'
import { ReactComponent as ThreeSixty } from 'img/360_icon.svg'
import { ReactComponent as ListIcon } from 'img/Icon material-playlist-add.svg'
import { ReactComponent as CRMAttributesIcon } from 'img/Icon material-import-export.svg'
import { ReactComponent as SegmentIcon } from 'img/Icon material-pie-chart.svg'
import { ReactComponent as IndustryIcon } from 'img/Icon material-business.svg'
import { ReactComponent as LocationIcon } from 'img/Icon material-location-on.svg'
import { ReactComponent as RevenueIcon } from 'img/Icon metro-dollar2.svg'
import { ReactComponent as EmployeeIcon } from 'img/Icon material-people.svg'
import { ReactComponent as FacebookLogo } from 'img/Icon ionic-logo-facebook.svg'
import { ReactComponent as LinkedInLogo } from 'img/Icon ionic-logo-linkedin.svg'
import { ReactComponent as TwitterLogo } from 'img/Icon ionic-logo-twitter.svg'
import { ReactComponent as AddCircle } from 'img/Icon material-add-circle.svg'
import { ReactComponent as CheckCircle } from 'img/Icon material-check-circle.svg'
import { ReactComponent as AddBox } from 'img/Icon material-add-box.svg'
import { ReactComponent as SmallHelp } from 'img/Icon ionic-md-help-circle.svg'
import { ReactComponent as DefaultProfile } from 'img/fancy_profile.svg'
import { ReactComponent as CFMissingAvatar } from 'img/purple-avatar.svg'
import { ReactComponent as PlayMakerIconPurple } from 'img/playRunnerSymbol.svg'
import { ReactComponent as SearchContact } from 'img/search_contact.svg'
import { ReactComponent as AddIntegration } from 'img/addIntegration.svg'
import { ReactComponent as BarsSearch } from 'img/BarsSearchBlack.svg'
import { ReactComponent as LightBulb } from 'img/material-lightbulb-outline.svg'
import { ReactComponent as SmallArrows } from 'img/small-arrows.svg'
import { ReactComponent as SmallICP } from 'img/small-icp.svg'
import { ReactComponent as SmallPlaymaker } from 'img/small-playrunner.svg'
import { ReactComponent as SmallPointer } from 'img/small-pointer.svg'
import { ReactComponent as SmallPath } from 'img/small-path.svg'
import { ReactComponent as SignalsVerified } from 'img/verified_icon.svg'
import { ReactComponent as SequenceIcon } from 'img/SequenceIcon.svg'
import { ReactComponent as SmallLinkedIn } from 'img/Icon simple-linkedin.svg'
import { ReactComponent as OutlinedCloud } from 'img/outlined-cloud.svg'
import { ReactComponent as CreateAccountIcon } from 'img/create_account_icon.svg'
import { ReactComponent as CreateEmailUpdateIcon } from 'img/CreateEmailUpdate.svg'
import { ReactComponent as PlayMakerIconGray } from 'img/play_runner_gray.svg'
import { ReactComponent as PlayMakerIconBlack } from 'img/play_runner_black.svg'
import { ReactComponent as TaskIcon } from 'img/task_black.svg'
import { ReactComponent as WaveIcon } from 'img/waving_hand_black.svg'
import { ReactComponent as FaqBotIcon } from 'img/faq_bot.svg'
import { ReactComponent as SecondNetIcon } from 'img/second_net_bot.svg'
import { ReactComponent as HomePageBotIcon } from 'img/homepage_bot.svg'
import { ReactComponent as TargetIcon } from 'img/arrow_target.svg'
import { ReactComponent as FunnelBotIcon } from 'img/funnel_bot.svg'
import { ReactComponent as LiveChatBotIcon } from 'img/live_chat_bot.svg'
import { ReactComponent as ConciergeBotIcon } from 'img/concierge_bot.svg'
import { ReactComponent as ConversationalContentBotIcon } from 'img/content_bot.svg'
import { ReactComponent as OpenOppIcon } from 'img/Icon open-target.svg'
import { ReactComponent as BuyingCommitteeIcon } from 'img/buying-committee.svg'
import { ReactComponent as BuyingCommitteeRoundIcon } from 'img/buying-committee-round.svg'
import { ReactComponent as HighSignalsScoreIcon } from 'img/high-signals-score.svg'
// Going to need new icons for these eventually that match the new primary color
import { ReactComponent as RouteIcon } from 'img/RouteIcon.svg'
import { ReactComponent as ChatAlertIcon } from 'img/ChatAlertIcon.svg'
import { ReactComponent as CalendarIcon } from 'img/CalendarIcon.svg'
import { ReactComponent as FolloweeIcon } from 'img/FolloweeIcon.svg'
import { ReactComponent as NoteIcon } from 'img/NoteIcon.svg'
import { ReactComponent as MegaPhoneIcon } from 'img/MegaPhoneIcon.svg'
import { ReactComponent as SalesforceCloud } from 'img/SalesForce_Cloud.svg'
import { ReactComponent as ConversationalContentPageIcon } from 'img/conversational_landing_page_icon.svg'
import { ReactComponent as XIcon } from 'img/x_logo.svg'
import { ReactComponent as FormsIcon } from 'img/forms_icon.svg'
import { ReactComponent as NumericIcon } from 'img/tag.svg'
import { ReactComponent as HIcon } from 'img/h.svg'
import { ReactComponent as MixedWordmark } from 'img/Signals_WordMark_Mixed.svg'
import { ReactComponent as FactCheckLarge } from 'img/fact_check_black_lg.svg'
import { ReactComponent as UncheckedRadio } from 'img/unchecked-radio.svg'
import { ReactComponent as LightGreenCheckCircle } from 'img/green-check-circle.svg'
import { ReactComponent as FactCheck } from 'img/fact_check.svg'
import { salesforce } from 'pages/people/ContactPage/integrations/integration-helpers'
import { ReactComponent as ExclusionIcon } from 'img/exclusion_chat.svg'
import { ReactComponent as NoLiveChat } from 'img/crossed_live_chat.svg'
import { ReactComponent as UpArrowCircle } from 'img/up-arrow-circle.svg'
import { ReactComponent as FeedIcon } from 'img/feed.svg'
import { ReactComponent as IncompleteIcon } from 'img/incomplete-icon.svg'
import { ReactComponent as FormSubmitted } from 'img/form_submitted.svg'
import { ReactComponent as OutboundFill } from 'img/outbound_fill.svg'
import { ReactComponent as ArrowCircleRight } from 'img/arrow_circle_right_fill.svg'
import { ReactComponent as ExpandCircleRight } from 'img/expand_circle_right_fill.svg'
import { ReactComponent as OutboundFillWhite } from 'img/outbound_fill_white.svg'
import { ReactComponent as ArrowCircleRightWhite } from 'img/arrow_circle_right_fill_white.svg'
import { ReactComponent as ExpandCircleRightWhite } from 'img/expand_circle_right_fill_white.svg'
import { ReactComponent as MatArrowForward } from 'img/material-arrow-forward.svg'
import { ReactComponent as KeyboardArrowRight } from 'img/material-keyboard-arrow-right.svg'
import { ReactComponent as IQLIcon } from 'img/iql_icon.svg'
import { ReactComponent as AlignHorizontalRight } from 'img/align_horizontal_right.svg'
import { ReactComponent as AlignHorizontalCenter } from 'img/align_horizontal_center.svg'
import { ReactComponent as AlignHorizontalLeft } from 'img/align_horizontal_left.svg'
import { ReactComponent as AlignVerticalCenter } from 'img/align_vertical_center.svg'
import { ReactComponent as ClickedIcon } from 'img/web_traffic_FILL.svg'
import { ReactComponent as DeliveredIcon } from 'img/mark_email_read.svg'
import { ReactComponent as AudioFile } from 'img/audio_file.svg'
import { ReactComponent as SimpleSalesforceIcon } from 'img/Icon simple-salesforce.svg'
import { ReactComponent as EditSquare } from 'img/edit_square_black.svg'
import { ReactComponent as AISparkles } from 'img/ai_sparkles.svg'
import { ReactComponent as AISparklesSmall } from 'img/ai_sparkles_small.svg'
import { ReactComponent as AISparklesWhite } from 'img/ai_sparkles_white.svg'
import { ReactComponent as LightBulbWhite } from 'img/light_bulb_white.svg'
import { ReactComponent as ReportProblem } from 'img/report_problem.svg'
import { ReactComponent as NoticeIcon } from 'img/notice-icon.svg'
import { ReactComponent as QuestionIcon } from 'img/question-icon.svg'
import { ReactComponent as CheckListIcon } from 'img/checklist.svg'
// Playmaker signals
import { ReactComponent as WebsiteSignalIcon } from 'img/website_signal.svg'
import { ReactComponent as EmailSignalIcon } from 'img/email_signal.svg'
import { ReactComponent as FormSignalIcon } from 'img/form_signal.svg'
import { ReactComponent as BotSignalIcon } from 'img/bot_signal.svg'
import { ReactComponent as IntegrationSignalIcon } from 'img/integration_signal.svg'
import { ReactComponent as travelExplore } from 'img/travelExplore.svg'
import { ReactComponent as travelExploreBlack } from 'img/travelExploreBlack.svg'
import { ReactComponent as salesforceCloudLarge } from 'img/SalesforceCloudLarge.svg'
import { ReactComponent as salesforceCloudLargeGray } from 'img/SalesforceCloudLargeGray.svg'
import { ReactComponent as hubspotLarge } from 'img/HubSpotLarge.svg'
import { ReactComponent as hubspotLargeGray } from 'img/HubSpotLargeGray.svg'
import { ReactComponent as AcitivtySummaryIcon } from 'img/activity_summary.svg'
import { ReactComponent as PersonSearchIcon } from 'img/person_search.svg'
import { ReactComponent as LookupNodeIcon } from 'img/ContactLookup.svg'
import { ReactComponent as RecordVoiceOverIcon } from 'img/record_voice_over.svg'
import { ReactComponent as CallIcon } from 'img/call_icon.svg'
import { ReactComponent as VisitCursor } from 'img/visit-cursor.svg'

export const iconMapping = {
  facebookLogo: FacebookLogo, // Logos for use in the accounts page additional info
  linkedinLogo: LinkedInLogo, // """"
  twitterLogo: TwitterLogo, // """"
  slackIconBlack: SlackIconBlack, // Slack icon used in event notifications onboarding
  slack: SlackIcon, // Slack's logo
  teamsIconBlack: TeamsIconBlack, // Teams icon used in event notifications onboarding
  teams: TeamsIcon, // Teams' logo
  addLink: Link,
  removeLink: LinkOff,
  incomplete: IncompleteIcon,
  employeeIcon: EmployeeIcon, // Icons for use in the filter view picker categories
  revenueIcon: RevenueIcon, // """"
  locationIcon: LocationIcon, // """"
  industryIcon: IndustryIcon, // """"
  segmentIcon: SegmentIcon, // """"
  crmAttributesIcon: CRMAttributesIcon, // """"
  downwardArrow: DownArrow, // Drop down arrow, though I think we have this already somewhere here
  listIcon: ListIcon, // List icon for the filter view picker
  filterIcon: FilterIcon, // Filter icon
  threeSixty: ThreeSixty, // arrow in a circle icon
  instagram: InstagramIcon, // Instagram logo
  description: Description, // Description icon
  insertInvitation: InsertInvitation, // Calendar with a plus sign in the lower right corner
  eventBusy: EventBusy, // Calendar with a slash through it
  chat: ChatIcon, // chat bubble with "text" in it
  chatBubble: ChatBubbleIcon, // chat bubble with zero text
  contacts: ContactsIcon,
  accounts: BusinessIcon,
  edit: EditIcon, // edit pencil
  delete: DeleteIcon, // trashcan with an x in the center
  clone: FileCopyIcon, // symbol that looks like a duplicate page with a folded corner
  calendar: TodayIcon, // calendar with a square in the upper left corner
  email: EmailIcon, // closed letter
  finished: FinishedIcon, // tag symbol with a checkmark in it
  start: StartIcon, // circle with a play icon pointing right at the center
  exit: ExitIcon, // outlined rectangle with arrow inside pointing right
  questionAnswer: QuestionAnswerIcon, // two overlapping chat bubbles
  liveHelp: LiveHelpIcon, // question mark inside a chat bubble
  liveChat: HeadsetMicIcon, // headphones with attached mic
  phone: PhoneAndroidIcon, // phone icon
  tag: TagIcon, // angled pricetag
  cancel: CancelIcon, // circle outline with an x in the center
  smiley: SmileyIcon, // smiley face
  cta: CallToActionIcon, // solid rectangle with transparent bar at the bottom
  link: OpenInNewIcon, // rectangle outline with arrow pointing towards upper right hand corner
  notification: NotificationsIcon, // bell icon
  schedule: ScheduleIcon, // analog clock
  search: SearchIcon, // magnifying glass
  addContact: PersonAddIcon, // person with plus symbol on the side
  branch: CallSplitIcon, // arrow pointing up which splits into two arrows
  close: CloseIcon, // an X
  cursor: NearMeIcon, // gps arrow pointing towards upper right corner
  add: AddIcon, // +
  remove: RemoveIcon, // -
  outlinedCancel: CancelOutlinedIcon, // circle outline with an x in the center
  downArrow: ArrowDownwardIcon, // down arrow
  upArrow: ArrowUpwardIcon, // up arrow
  leftArrow: ChevronLeftIcon, // left facing chevron
  rightArrow: ChevronRightIcon, // right facing chevron
  block: BlockIcon, // circle outline with a slash through the middle
  star: StarIcon, // solid star
  starBorder: StarBorderIcon, // star outline
  goal: PlaylistAddCheckIcon, // three "text" bars with a checkmark in the corner
  webPage: LanguageIcon, // circle with four horizontal and vertical curvilinear lines passing through it
  dateRange: DateRangeIcon, // calendar with three dots at the top
  contactBox: AccountBoxIcon, // square with a person's head and shoulders in the center
  pieChart: PieChartIcon, // circle with lines to look like a pie chart
  barChart: BarChartIcon,
  play: AccountTreeIcon, // three squares connected by a branching line
  helpDocs: FindInPageIcon, // document icon with magnifying glass in the center
  save: Save, // floppy disc icon
  module: ViewModuleIcon, // six squares in a 3x2 grid
  world: PublicIcon, // globe icon
  check: CheckIcon, // checkmark
  snooze: SnoozeIcon, // alarm clock with a z in the center
  checkMark: DoneOutlineIcon, // checkmark outline
  settings: SettingsIcon, // gear icon
  send: SendIcon, // right facing triangle with a notch on the left side
  mailbox: MarkunreadMailboxIcon, // mailbox with raised flag
  emailOpen: DraftsIcon, // opened letter
  alternateEmail: AlternateEmail, // @ symbol
  lock: LockOpenIcon,
  minimize: MinimizeIcon,
  users: UsersIcon,
  help: HelpIcon,
  addPhoto: AddAPhoto,
  code: CodeIcon,
  upload: CloudUploadIcon,
  download: CloudDownload,
  desktop: DesktopWindowsIcon,
  text: TextsmsIcon,
  linkedin: LinkedInIcon,
  facebook: FacebookIcon,
  arrowBack: ArrowBackIcon,
  arrowDropDown: ArrowDropDown,
  playArrow: PlayArrow,
  pause: Pause,
  notificationsActive: NotificationsActiveIcon,
  openOpp: OpenOppIcon,
  playmaker: PlayMakerIcon,
  logo: LogoIcon,
  botHead: BotIcon,
  beaker: TestingBeakerIcon,
  trash: TrashIcon,
  filter: FilterIcon,
  fullScreen: FullscreenIcon, // partial outline of a square
  fullScreenExit: FullscreenExit, // partial outline square that is collapsed
  oktaLogo: OktaLogo,
  person: PersonIcon, // outline of a person with no neck
  trendingUp: TrendingUpIcon, // angled graph line with arrow point and upward trajectory
  trendingDown: TrendingDownIcon, // angled graph line with arrow point and downward trajectory
  trendingFlat: TrendingFlatIcon, // straight, thin arrow pointing to the right
  funnel: FunnelIcon, // funnel pointing down
  dollarFunnel: DollarFunnelIcon, // funnel with a dollar symbol at its right side
  at: AtIcon, // @ symbol
  pointer: MousePointerIcon, // mouse arrow pointer
  checkCircle: CheckCircleIcon, // circle with checkmark inside
  botHeadAlt: altBotHead, // bot head with smaller antenna and rounder corners than the other bot icon
  phoneHandset: PhoneIcon, // landline phone handset
  warning: WarningIcon, // triangle with an exclamation point
  error: ErrorIcon, // circle with an exclamation point
  errorOutlined: ErrorOutlinedIcon, // circle with an exclamation point outline
  salesforce: SalesforceIcon,
  salesforceCloud: SalesforceCloud,
  marketo: MarketoIcon,
  hubspot: HubSpotIcon,
  hubspotClear: HubspotClear,
  brevo: BrevoLogo,
  sendinblue: BrevoLogo,
  eloqua: EloquaLogo,
  salesloft: SalesloftLogo,
  salesloftAssign: SalesloftAssignmentAdd,
  salesloftCadence: SalesloftCadence,
  salesloftCall: SalesloftCall,
  salesloftClicks: SalesloftClicks,
  salesloftCreatTask: SalesloftCreateTask,
  salesloftDoNotContact: SalesloftDoNotContact,
  salesloftEmailReceived: SalesloftEmailRecieved,
  salesloftEmailReply: SalesloftEmailReply,
  salesloftEmailSent: SalesloftEmailSend,
  salesloftEmail: SalesloftEmail,
  salesloftLinkedin: SalesloftLinkedin,
  salesloftMeetingCancel: SalesloftMeetingCancel,
  salesloftMeeting: SalesloftMeeting,
  salesloftNote: SalesloftNote,
  salesloftReplies: SalesloftReplies,
  salesloftSuccess: SalesloftSuccess,
  salesloftTask: SalesloftTask,
  salesloftText: SalesloftText,
  salesloftViews: SalesloftViews,
  memory: MemoryIcon,
  refresh: Refresh,
  listAdd: PlaylistAdd,
  industry: CorporateFare,
  crm: CompareArrows,
  location: LocationOn,
  money: AttachMoney,
  expand: ExpandMore,
  collapse: ExpandLess,
  file: InsertDriveFile,
  info: InfoOutlined,
  replay: Replay,
  undo: Undo,
  report: Report,
  pin: PinIcon,
  copy: CopyIcon,
  icp: Icp,
  icpCircle: IcpCircle,
  emailSubscribe: EmailSubscribe,
  searchIconRounded: SearchRounded,
  personAddIconRounded: PersonAddRounded,
  trafficSource: TrafficSourceIcon,
  trafficSourceSmall: TrafficSourceSmall,
  event: EventIcon,
  eventAvailable: EventAvailableIcon,
  removeCircle: RemoveCircle,
  arrowForward: ArrowForwardIos,
  showPassword: Visibility,
  hidePassword: VisibilityOff,
  moneySearch: MoneySearchIcon,
  restore: Restore,
  addCircle: AddCircle,
  greenCheckCircle: CheckCircle,
  volumeUp: VolumeUp,
  volumeOff: VolumeOff,
  addBox: AddBox,
  arrowRightWithLine: ArrowForward,
  salesloftLogo: SalesloftLogo2,
  smallHelp: SmallHelp,
  message: Message,
  defaultProfile: DefaultProfile,
  missingAvatar: CFMissingAvatar,
  laptop: Laptop,
  playMakerPurple: PlayMakerIconPurple,
  launch: Launch,
  domain: Domain,
  prospectToContact: ProspectToContact,
  userSearch: SearchContact,
  addIntegration: AddIntegration,
  dataUsage: DataUsageIcon,
  autoAwesome: AutoAwesome,
  autoAwesomeYellow: AutoAwesomeYellow,
  infoIcon: InfoIcon,
  barsSearch: BarsSearch,
  lightBulb: LightBulb,
  thumbUp: ThumbUpIcon,
  thumbDown: ThumbDownIcon,
  thumbUpDown: ThumbsUpDownIcon,
  smallArrows: SmallArrows,
  smallICP: SmallICP,
  smallPlaymaker: SmallPlaymaker,
  smallPointer: SmallPointer,
  smallPath: SmallPath,
  verified: SignalsVerified,
  sequenceIcon: SequenceIcon,
  smallLinkedIn: SmallLinkedIn,
  outlinedCloud: OutlinedCloud,
  createAccount: CreateAccountIcon,
  smChrome: SmallChromeIcon,
  smFirefox: SmallFirefoxIcon,
  smSafari: SmallSafariIcon,
  smEdge: SmallEdgeIcon,
  smBrave: SmallBraveIcon,
  timer: TimerOutlined,
  createEmailUpdate: CreateEmailUpdateIcon,
  playMakerGray: PlayMakerIconGray,
  playMakerBlack: PlayMakerIconBlack,
  task: TaskIcon,
  wave: WaveIcon,
  target: TargetIcon,
  faqBot: FaqBotIcon,
  homepageBot: HomePageBotIcon,
  secondNet: SecondNetIcon,
  funnelBot: FunnelBotIcon,
  conversationalBot: ConversationalContentBotIcon,
  liveChatBot: LiveChatBotIcon,
  conciergeBot: ConciergeBotIcon,
  route: RouteIcon,
  chatAlert: ChatAlertIcon,
  notifyCalendar: CalendarIcon,
  followee: FolloweeIcon,
  note: NoteIcon,
  megaPhone: MegaPhoneIcon,
  buyingCommittee: BuyingCommitteeIcon,
  buyingCommitteeBubble: BuyingCommitteeRoundIcon,
  // icpBubble: ICPBubbleIcon,
  highSignalsScore: HighSignalsScoreIcon,
  xSocial: XIcon,
  formsIcon: FormsIcon,
  conversationalContentPageIcon: ConversationalContentPageIcon,
  work: WorkIcon,
  badge: BadgeIcon,
  textIcon: TextIcon,
  textFields: TextFields,
  dropDownCircle: ArrowDropDownCircle,
  radioChecked: RadioChecked,
  checkedBox: CheckedBox,
  numericIcon: NumericIcon,
  hIcon: HIcon,
  blankCalendar: Calendar,
  factCheckLarge: FactCheckLarge,
  mixedWordmark: MixedWordmark,
  uncheckedRadio: UncheckedRadio,
  lightGreenCheckCircle: LightGreenCheckCircle,
  factCheck: FactCheck,
  announcement: AnnouncementIcon,
  exclusion: ExclusionIcon,
  noLiveChat: NoLiveChat,
  upArrowCircle: UpArrowCircle,
  timeline: Timeline,
  feedIcon: FeedIcon,
  formSubmitted: FormSubmitted,
  circleAdd: CircleAdd,
  callMade: CallMade,
  cached: Cached,
  keyboardArrowRight: KeyboardArrowRight,
  outboundFill: OutboundFill,
  arrowCircleRight: ArrowCircleRight,
  expandCircleRight: ExpandCircleRight,
  outboundFillWhite: OutboundFillWhite,
  arrowCircleRightWhite: ArrowCircleRightWhite,
  expandCircleRightWhite: ExpandCircleRightWhite,
  matArrowForward: MatArrowForward,
  iql: IQLIcon,
  horizontalRight: AlignHorizontalRight,
  horizontalCenter: AlignHorizontalCenter,
  horizontalLeft: AlignHorizontalLeft,
  verticalCenter: AlignVerticalCenter,
  clickedIcon: ClickedIcon,
  deliveredIcon: DeliveredIcon,
  audioFile: AudioFile,
  simpleSalesforce: SimpleSalesforceIcon,
  editSquare: EditSquare,
  aiSparkles: AISparkles,
  aiSparklesSmall: AISparklesSmall,
  aiSparklesWhite: AISparklesWhite,
  lightBulbWhite: LightBulbWhite,
  reportProblem: ReportProblem,
  noticeIcon: NoticeIcon,
  questionIcon: QuestionIcon,
  checkListIcon: CheckListIcon,
  attachFile: AttachFileIcon,
  // Playmaker signals
  websiteSignal: WebsiteSignalIcon,
  emailSignal: EmailSignalIcon,
  formSignal: FormSignalIcon,
  botSignal: BotSignalIcon,
  integrationSignal: IntegrationSignalIcon,
  travelExplore: travelExplore,
  travelExploreBlack: travelExploreBlack,
  salesforceCloudLarge: salesforceCloudLarge,
  salesforceCloudLargeGray: salesforceCloudLargeGray,
  hubspotLarge: hubspotLarge,
  hubspotLargeGray: hubspotLargeGray,
  activitySummary: AcitivtySummaryIcon,
  organicPerson: RecordVoiceOverIcon,
  contactLookup: PersonSearchIcon,
  contactLookupNode: LookupNodeIcon,
  call: CallIcon,
  visitCursor: VisitCursor
}

export type IconType = keyof typeof iconMapping

const fontSizes = {
  xxlg: '5em',
  xl: '3em',
  lg: '2em',
  md: '1.5em',
  sm: '1em',
  xs: '.5em'
}

export type IconSizes = keyof typeof fontSizes

type IconProps = {
  icon: IconType
  variant?: 'contained' | 'not-contained' | undefined
  color?: string
  size?: IconSizes
  onClick?: any
  cursor?: 'pointer'
  imgStyle?: CSSProperties
  specificSize?: number | string
  circular?: boolean
  upgrade?: boolean
  fontSize?: string
}

export function Icon (props: IconProps): JSX.Element {
  // const classes = useStyles()

  const height = props.size === 'sm' ? 15 : 24
  const color = (props.color === 'primary' ? theme.palette.primary.main : props.color === 'alt' ? theme.palette.primary.alt : props.color)

  if (props.icon === 'salesforceCloud') {
    return (
      <img style={{ height: (props.specificSize - 5) || (height - 5), width: props.specificSize || height }} src={salesforce.getColoredSVG('#0092D8')} alt='Salesforce Cloud' />
    )
  } else if (props.icon === 'logo') {
    return (
      <LogoIcon style={{ fill: props.color || 'white', height }} />
    )
  } else if (props.icon === 'beaker') {
    return (
      <MuiIcon>
        <TestingBeakerIcon style={{ fill: color, transform: 'scale(1.4)', position: 'relative', top: 4 }} />
      </MuiIcon>
    )
  } else if (props.icon === 'botHead') {
    return (
      <MuiIcon>
        <BotIcon
          style={{ marginBottom: 5, fill: color, transform: 'scale(1.4)' }}
        />
      </MuiIcon>
    )
  } else if (props.icon === 'playmaker') {
    return (
      <MuiIcon>
        <PlayMakerIcon style={{ fill: color }} />
      </MuiIcon>
    )
  }

  const Component = iconMapping[props.icon]

  if (props.variant === 'contained') {
    return (
      <Avatar
        style={{ color: 'white', backgroundColor: props.color, height: 50, width: 50 }}
        onClick={props.onClick}
      >
        <Component />
      </Avatar>
    )
  } else if (props.icon === 'oktaLogo') {
    return (
      <MuiIcon style={{ height: 27, width: 46 }}>
        <img src={iconMapping[props.icon]} style={props.imgStyle} alt='Okta Logo' />
      </MuiIcon>
    )
  }

  // Icons used in play runner integration skills
  if (props.icon === 'salesforce' || props.icon === 'hubspot' || props.icon === 'marketo' || props.icon === 'brevo' || props.icon === 'sendinblue' || props.icon === 'eloqua' || props.icon === 'salesloft') {
    return (
      <MuiIcon style={{ height: 22, width: 22 }}>
        <img src={iconMapping[props.icon]} style={{ height: 22, width: 22 }} alt='Integration Logo' />
      </MuiIcon>
    )
  }

  if (props.icon === 'salesloftLogo') {
    return (
      <MuiIcon style={{ marginLeft: '5px', marginBottom: '3px', height: 25, width: 16 }}>
        <img src={iconMapping[props.icon]} style={{ height: 20, width: 16 }} alt='Salesloft Logo' />
      </MuiIcon>
    )
  }

  if (props.icon === 'slackIconBlack') {
    return (
      <MuiIcon style={{ fontSize: '3.2em', transform: 'translate(-1%, -27%)' }}>
        <img src={iconMapping[props.icon]} alt='Slack Logo' />
      </MuiIcon>
    )
  }

  if (props.icon === 'teamsIconBlack') {
    return (
      <MuiIcon style={{ fontSize: '2.0em', transform: 'translate(-12%, -25%)' }}>
        <img src={iconMapping[props.icon]} alt='Teams Logo' />
      </MuiIcon>
    )
  }

  if (props.icon === 'smChrome' || props.icon === 'smSafari' || props.icon === 'smFirefox' || props.icon === 'smEdge' || props.icon === 'smBrave') {
    return (
      <MuiIcon style={{ marginLeft: '4px', marginBottom: '3px', height: 25, width: 25 }}>
        <img src={iconMapping[props.icon]} style={{ height: 20, width: 20 }} alt={props.icon} />
      </MuiIcon>
    )
  }

  // This is just for any png images
  if (typeof iconMapping[props.icon] === 'string') {
    return (
      <img src={iconMapping[props.icon]} style={{ height: props.specificSize || height, width: props.specificSize || height, color: color }} alt={props.icon} />
    )
  }

  const fontSize = props.fontSize || fontSizes[props.size || 'md']
  return (
    <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
      <Component
        style={props.specificSize ? { color, fill: color, fontSize, cursor: props.cursor, width: props.specificSize, height: props.specificSize } : { color, fill: color, fontSize, cursor: props.cursor }}
        onClick={props.onClick}
      />
      {props.upgrade && (
        <div style={{ position: 'absolute', top: (height / 2) - 2, left: (height / 2), display: 'flex', borderRadius: 20, height: (height / 2), width: (height / 2), backgroundColor: '#9933FF', justifyContent: 'center', alignItems: 'center' }}>
          <ArrowUpwardIcon style={{ color: 'white', height: ((height / 2) - 2), width: ((height / 2) - 2) }} />
        </div>
      )}
    </div>
  )
}
