import { makeStyles } from '@material-ui/core/styles'
import { Icon } from 'library/materialUI'
import SLAccountCard from './SLAccountCard'
import SLContactCard from './SLContactCard'

const useStyles = makeStyles(theme => ({
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '100%',
    maxHeight: '320px',
    minHeight: '320px',
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: '5px',
    opacity: 1
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    maxWidth: '330px',
    maxHeight: '64px',
    fontSize: '16px',
    margin: '18px'
  },
  bodyContainer: {
    overflowX: 'hidden',
    overflowY: 'auto'
  }
}))

interface SLIFrameProps {
  type: string
  id: any
}

export default function SalesloftIFrame ({ type, id }: SLIFrameProps) {
  const classes = useStyles()

  return (
    <div className={classes.cardContainer}>
      <div className={classes.headerContainer}>
        <div>
          <Icon icon='logo' color='#9933FF' size='lg' />
        </div>
        <div style={{ marginLeft: '10px' }}>
          {type === 'contact' ? 'Contact Activity' : 'Account Activity - Last 30 Days'}
        </div>
      </div>
      <div className={classes.bodyContainer}>
        {type === 'contact' ? (
          <SLContactCard personID={id} />
        ) : type === 'account' && (
          <SLAccountCard accountID={id} />
        )}
      </div>
    </div>
  )
}
