import { makeStyles } from '@material-ui/core/styles'
import BlockedPageRow from './BlockedPageRow'
import { BlockedPage } from './BlockedPages'
import { Button } from 'library/materialUI/Button';
import AddIcon from '@material-ui/icons/Add'

const useStyles = makeStyles(theme => ({
  header: {
    fontSize: 20,
    fontWeight: 'bold',
    fontFamily: 'poppins'
  },
  description: {
    fontSize: 16,
    fontFamily: 'poppins',
    marginTop: '20px'
  },
  categoryButtonContainer: {
    display: 'flex',
    border: 'none',
    marginBottom: '20px'
  },
  categoryButtonIcon: {
    marginRight: 5,
    backgroundColor: '#985CE6',
    borderRadius: '100%',
    color: 'white'
  }
}))

interface BlockedPageListProps {
  state: any
  dispatch: any
}

export default function BlockedPageList ({ state, dispatch }: BlockedPageListProps) {
  const classes = useStyles()
  const pageList = JSON.parse(state?.attributes?.blocked_pages || '{}')?.list || []

  const addEntryField = () => {
    const newPageList = [...pageList]
    newPageList.push({ url: '', comparison: 'in' })
    dispatch({ type: 'updateSetting', name: 'blocked_pages', value: JSON.stringify({ list: newPageList }) })
  }

  return (
    <div>
      <div className={classes.header}>
        Blocked Pages
      </div>
      <div className={classes.description}>
        Specify any pages on your site that you don’t ever want the chat prompt to appear on. On these pages, (1) new conversations will not be initiated, and (2) the chat window will be closed for existing conversations
      </div>
      {pageList && pageList?.map((page: BlockedPage, index: number) => (
        <div key={index}>
          <BlockedPageRow comparison={page.comparison} currentUrl={page.url} pageList={pageList} dispatch={dispatch} />
        </div>
      ))}
      <div style={{ marginTop: '10px' }}>
        <Button
          className={classes.categoryButtonContainer}
          onClick={addEntryField}
          variant='outlined'
          color='primary'
        >
          <AddIcon
            className={classes.categoryButtonIcon}
          />
          Add URL
        </Button>
      </div>
    </div>
  )
}
