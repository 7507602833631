import { useState, useContext, useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { SessionContext } from 'session-context'
import { getCronofyStatus, deleteCronofyStatus } from 'api/calendars'
import { loadCronofyWidgets } from './Cronofy'
import { Button, Card } from '@material-ui/core'
import MeetingSettingsModal from './MeetingSettingsModal'
import { updateMeetingInfo } from 'api/meetings'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import Typography from '@material-ui/core/Typography'
import WarningIcon from '@material-ui/icons/Warning'
import { useLocation } from 'react-router-dom'
import './calendar.css'
import useInterval from 'use-interval'
import { getOnboardingData, setOnboardingData } from 'api/onboarding'

const useStyles = makeStyles((theme) => ({
  meetingInfoArea: {
    height: 40,
    padding: 10,
    paddingRight: 20,
    paddingLeft: 20
  },
  meetingInfoCard: {
    borderRadius: 10,
    marginBottom: 25,
    backgroundColor: '#f7f7f7'
  },
  cardText: {
    fontSize: 18,
    fontWeight: 600,
    margin: 0,
    marginLeft: 10
  }
}))

function CalendarPage (props) {
  const classes = useStyles()
  const { user } = useContext(SessionContext)
  const [cronofyStatus, setCronofyStatus] = useState(null)
  const [meetingSettingsModalOpen, setMeetingSettingsModalOpen] = useState(false)
  const [missingConferencing, setMissingConferencing] = useState(false)
  const meetingSettingsError = props.meetingSettingsError
  const setMeetingSettingError = props.setMeetingSettingError
  const conferencingProviderError = props.conferencingProviderError
  const setConferencingProviderError = props.setConferencingProviderError
  const meetingInfo = props.meetingInfo
  const setMeetingInfo = props.setMeetingInfo
  const location = useLocation()

  let cronofyStatusID = null
  if (cronofyStatus) {
    cronofyStatusID = cronofyStatus.id
  }

  const handleSubmit = () => {
    if (meetingInfo.attributes.meet_now_url === '') {
      setMissingConferencing(true)
      return
    }
    Promise.all([
      updateMeetingInfo({
        user_id: user.id,
        meetingInfo: meetingInfo
      }).then((response) => {
        setMeetingInfo(response.data)
        setMeetingSettingError(false)
      }),
      getOnboardingData('client_onboarding')
      .then(response => {
        if (response) {
          const data = JSON.parse(response.settings)
          data.connect_calendar = true
          setOnboardingData('client_onboarding', data)
        }
      }
    )
    ]).catch(() => {
      setMeetingSettingError(true)
    }).finally(() => {
      setMeetingSettingsModalOpen(false)
    })
  }

  const handleEdit = ({ type, data }) => {
    setMeetingInfo({
      ...meetingInfo,
      attributes: {
        ...meetingInfo.attributes,
        [type]: data
      }
    })
  }

  const setupConferencingHandler = () => {
    // save first because we won't be coming back and need the conferencing_type to be saved here
    updateMeetingInfo({
      user_id: user.id,
      meetingInfo: meetingInfo
    }).then((response) => {
      window.location.assign(cronofyStatus.attributes.conference_redirect_url)
      setMissingConferencing(false)
      setConferencingProviderError(false)
    })
  }

  // TODO: /api/integrations/cronofy/status
  // TODO: This will tell us if we need to show a "sync calendar" button or a "remove sync" button

  useEffect(() => {
    getCronofyStatus().then((response) => {
      setCronofyStatus(response.data)
    })
  }, [user.id])

  function disconnectCalendar () {
    deleteCronofyStatus()
    setCronofyStatus(null)
  }

  useEffect(() => {
    if (cronofyStatus) {
      loadCronofyWidgets({
        cronofyStatus,
        disconnectCalendar,
        availabilityCallback: (e) => {
          if (e && e?.notification?.type === 'availability_rule_edited') {
            setTimeout(() => {
              const clickEvent = new MouseEvent('click', {
                view: window,
                bubbles: true,
                cancelable: true
              })
              document.querySelector('.custom-name__submit').dispatchEvent(clickEvent)
            }, 200)
          }
        }
      })
    }
  }, [cronofyStatusID, cronofyStatus])

  const hasSavedAvailabilityRef = useRef(false)

  useInterval(() => {
    const availabilitySubmitEl = document.querySelector('.custom-name__submit')
    const loadingEl = document.querySelector('.custom-name__loading-wrapper')
    if (!loadingEl && availabilitySubmitEl && !hasSavedAvailabilityRef.current) {
      hasSavedAvailabilityRef.current = true
      setTimeout(() => {
        const clickEvent = new MouseEvent('click', {
          view: window,
          bubbles: true,
          cancelable: true
        })
        document.querySelector('.custom-name__submit').dispatchEvent(clickEvent)
      }, 0)
    }
  }, 2000)

  let availabilityStyle = 'none'
  if (cronofyStatus && cronofyStatus.attributes.active) {
    availabilityStyle = 'block'
  }

  const display = location.pathname === '/settings/my_settings/my_calendar' ? 'block' : 'none'
  let setupCardBackgroundColor = null
  let setupCardText = 'Meeting Settings'
  if (meetingSettingsError) {
    setupCardText = 'Meeting Settings - not yet complete!'
    setupCardBackgroundColor = '#fe9d10'
  } else if (conferencingProviderError) {
    setupCardText = 'Conferencing Provider - not set up!'
    setupCardBackgroundColor = '#fe9d10'
  }

  return (
    <>
      {cronofyStatus?.attributes?.active ? (
        <Card className={classes.meetingInfoCard} style={{ display: display, backgroundColor: setupCardBackgroundColor }}>
          <div
            className={classes.meetingInfoArea}
            onClick={() => setMeetingSettingsModalOpen(true)}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center'
              }}
            >
              {meetingSettingsError
                ? <WarningIcon style={{ color: 'rgba(245, 221, 66)' }} />
                : <CheckCircleOutlineIcon style={{ color: 'rgba(0, 135, 7, 0.7)' }} />}
              <p className={classes.cardText}>{setupCardText}</p>
              <Button
                style={{ marginLeft: '15px', color: '#000000' }}
                onClick={() => setMeetingSettingsModalOpen(true)}
                variant='outlined'
              >{meetingSettingsError ? 'Click Here to configure' : 'Edit'}
              </Button>
            </div>
          </div>
        </Card>
      ) : (
        <div style={{ padding: 10 }}><Typography>Choose your calendar provider to connect to Signals.</Typography></div>
      )}
      <div style={{ padding: 10 }}>
        <div id='cronofy-calendar-sync' style={{ display: display }} />
        <div
          id='cronofy-availability-rules'
          style={{ display: availabilityStyle }}
        />
      </div>
      <MeetingSettingsModal
        open={meetingSettingsModalOpen}
        onSubmit={handleSubmit}
        onHide={() => setMeetingSettingsModalOpen(false)}
        meetingInfo={meetingInfo}
        onChange={handleEdit}
        setupConferencingHandler={setupConferencingHandler}
        conferencingProvider={cronofyStatus?.attributes?.conferencing_provider_type}
        helpLink='https://help.getsignals.ai/article/wgr5xzf4ea-02-my-calendar#meeting_settings'
        missingConferencing={missingConferencing}
      />
    </>
  )
}

export default CalendarPage
