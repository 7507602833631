/* eslint-disable @typescript-eslint/naming-convention */
import { KeyboardDatePicker } from '@material-ui/pickers'
import { Select } from 'cf-components/material-wrappers/MaterialComponents'
import { ModalSection } from 'cf-components/Modal'
import { GetIntegrationUsers, GetMappedIntegrationUserID, IntegrationUsers, SelectConvert } from 'classes/integrationUsers'
import { Button, RadioButtons, TextBox } from 'library/materialUI'
import { useContext, useEffect, useReducer, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import SalesloftReminder from './SalesloftReminder'
import { Task, taskSchema } from 'classes/task'
import { SessionContext } from 'session-context'

const useStyles = makeStyles(theme => ({
  error: {
    color: 'red'
  },
  person: {
    font: '700 16px Poppins'
  }
}))

const initialState = new Task({
  row: {
    attributes: {
      subject: '',
      task_type: 'general',
      person_id: '',
      due_date: new Date(Date.now()).toISOString(),
      current_state: 'scheduled',
      remind_at: '',
      user_id: '',
      description: ''
    }
  }
})

type SalesloftCreateTaskProps = {
  personName: string
  personID: string
  onSave: () => void
}

const reducer = (state: any, changes: any) => {
    return { ...state, ...changes }
}

export default function SalesloftCreateTask (props: SalesloftCreateTaskProps): JSX.Element {
  const classes = useStyles()
  const { user } = useContext(SessionContext)
  const { data: intUsers, isLoading, isError } = GetIntegrationUsers<IntegrationUsers>('salesloft')
  const [users, setUsers] = useState<any>([])
  const [checked, setChecked] = useState<boolean>(false)
  const currentDate = new Date(Date.now())
  currentDate.setDate(currentDate.getDate() + 7)
  currentDate.setUTCHours(0, 0, 0, 0)
  const [task, setTask] = useReducer(reducer, { ...initialState, dueDate: currentDate.toISOString() })
  const dueDateError = false

  useEffect(() => {
    if (!checked) {
      GetMappedIntegrationUserID('salesloft', user.id).then(response => {
        setChecked(true)
        setTask({ userID: response.crm_user_id })
      })
    }
    if (!isLoading && !isError) {
      setUsers(intUsers.map((user: IntegrationUsers) => SelectConvert(user)))
    }
    if (props.personID !== '') {
      setTask({ personID: props.personID })
    }
  }, [isLoading, intUsers, user.id, props.personID, checked, isError])

  return (
    <div>
      <ModalSection
        title='Subject'
      >
        <TextBox
          value={task.subject}
          onChange={(value: string) => setTask({ subject: value })}
          placeholder='Enter Subject Name'
        />
      </ModalSection>
      <ModalSection
        title='Task Type'
      >
        <RadioButtons
          value={task.taskType}
          handleChange={(value) => {
            if (value === 'call') {
              setTask({ subject: 'Call ' + props.personName })
            } else if (value === 'email') {
              setTask({ subject: 'Email ' + props.personName })
            }
            setTask({ taskType: value })
          }}
          buttons={[
            { label: 'General', value: 'general' },
            { label: 'Call', value: 'call' },
            { label: 'Email', value: 'email' }
          ]}
        />
      </ModalSection>
      <ModalSection
        title='Related Person'
      >
        <div className={classes.person}>{props.personName}</div>
      </ModalSection>
      <ModalSection
        title='Due Date'
      >
        <KeyboardDatePicker
          disableToolbar
          variant='inline'
          disablePast
          format='MM/dd/yyyy'
          margin='dense'
          error={dueDateError}
          minDate={new Date(Date.now())}
          id='salesloft-due-date-picker'
          value={task.dueDate}
          onChange={e => setTask({ dueDate: e?.toISOString() })}
          autoOk
          KeyboardButtonProps={{
            'aria-label': 'change due date'
          }}
        />
        {dueDateError ? (
          <div className={classes.error}>
            Must be future date
          </div>
        ) : <></>}
      </ModalSection>
      <ModalSection
        title='Reminder Time'
      >
        <SalesloftReminder
          reminder={task.remindAt}
          setReminder={setTask}
          dueDate={task.dueDate}
        />
      </ModalSection>
      <ModalSection
        title='Assignee'
      >
        {isLoading || users.length === 0 ? <></> : (
          <Select
            value={task.userID}
            options={users}
            onChange={(intUser: any) => {
              if (!intUser) {
                setTask({ userID: '' })
              } else {
                setTask({ userID: intUser })
              }
            }}
          />
        )}
      </ModalSection>
      {task.taskType !== 'general' ? <></> : (
        <ModalSection
          title='Notes'
        >
          <TextBox
            value={task.description}
            onChange={(value: string) => setTask({ description: value })}
            rows={3}
          />
        </ModalSection>
      )}
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant='contained'
          onClick={() => {
            Task.create(task, 'salesloft')
            props.onSave()
          }}
          color='primary'
          disabled={!taskSchema.isValidSync(task)}
        >
          Create Task
        </Button>
      </div>
    </div>
  )
}
