const demoFlow = {
  flow: {
    data: {
      edges: [
        {
          data: {
            id: 'af9de520-9101-45f1-b42d-114b6f6e508d',
            type: 'common'
          },
          source: 'start.default',
          target: 'c8ebaf35-c533-44fb-bfd0-e74e6f9ba10d'
        },
        {
          data: {
            id: 'd55279d1-fbe1-44e6-a7a6-ad24b78b4519',
            type: 'common'
          },
          source: 'c8ebaf35-c533-44fb-bfd0-e74e6f9ba10d.default',
          target: 'bcbae539-7f19-4430-8d40-406eab2b3e8b'
        },
        {
          data: {
            id: '286b59bb-a2ae-4aa3-9ab0-92759a0a4f9b',
            type: 'common'
          },
          source: 'bcbae539-7f19-4430-8d40-406eab2b3e8b.default',
          target: '6b4d2751-67ce-4091-8f9d-40681084f4f1'
        },
        {
          data: {
            id: 'f884d0f9-ded7-4f76-a80c-c9db514e3384',
            type: 'common'
          },
          source: '6b4d2751-67ce-4091-8f9d-40681084f4f1.92a63356-56b5-437a-9db7-f0c3ee13544e',
          target: '53e963fa-fe87-45e8-91ed-bdfb83e33dd1'
        },
        {
          data: {
            id: '33e7af6f-e6e2-4fb7-b789-f463f775be2f',
            type: 'common'
          },
          source: '6b4d2751-67ce-4091-8f9d-40681084f4f1.4f961190-fab9-475a-98e0-aea6e7dc6088',
          target: '53e963fa-fe87-45e8-91ed-bdfb83e33dd1'
        },
        {
          data: {
            id: '3de61324-53fa-4bac-be2d-d8577fc432bb',
            type: 'common'
          },
          source: '6b4d2751-67ce-4091-8f9d-40681084f4f1.39aa26f0-afbc-480a-8b9f-577f17b9d2f9',
          target: 'd1c2a28b-777f-479f-be0d-11cb0244e255'
        },
        {
          data: {
            id: '212b2eeb-a0b4-48b0-bf42-9cdb31964072',
            type: 'common'
          },
          source: 'd1c2a28b-777f-479f-be0d-11cb0244e255.default',
          target: '88798417-1b43-416d-906d-f6b2f195e226'
        },
        {
          data: {
            id: '90f7e686-8d57-4b0e-92a4-bf815a3638a2',
            type: 'common'
          },
          source: '88798417-1b43-416d-906d-f6b2f195e226.default',
          target: 'c74f1798-2275-4f71-83c4-46ab15fa49d1'
        },
        {
          data: {
            id: 'aee6a93a-d13e-4d6b-8c14-583b84719192',
            type: 'common'
          },
          source: '53e963fa-fe87-45e8-91ed-bdfb83e33dd1.5c532312-42e4-403e-abe3-b8fa660b4977',
          target: 'd1c2a28b-777f-479f-be0d-11cb0244e255'
        },
        {
          data: {
            id: 'ab71eba3-4ff9-4d5b-9dc9-3b36bb9cf430',
            type: 'common'
          },
          source: '53e963fa-fe87-45e8-91ed-bdfb83e33dd1.8aa40423-105a-4e00-bb65-ffdd7753ea66',
          target: '589292fd-55a5-432d-84b9-0532b7c705c3'
        },
        {
          data: {
            id: '7890df88-f8e0-4e5c-8cdc-24f2a61b727d',
            type: 'common'
          },
          source: '53e963fa-fe87-45e8-91ed-bdfb83e33dd1.0c8a8076-3fbe-4e81-9e4b-5a18f29f8560',
          target: '589292fd-55a5-432d-84b9-0532b7c705c3'
        },
        {
          data: {
            id: 'b7eb8ceb-8a41-4ffd-99ba-787bdbe0dbe8',
            type: 'common'
          },
          source: '589292fd-55a5-432d-84b9-0532b7c705c3.invalid',
          target: 'd1c2a28b-777f-479f-be0d-11cb0244e255'
        },
        {
          data: {
            id: 'f3e4eac5-ddaf-4936-b042-7deb79157b61',
            type: 'common'
          },
          source: '589292fd-55a5-432d-84b9-0532b7c705c3.valid',
          target: 'f88b925d-cf6c-43fd-a6ac-0a93603568de'
        },
        {
          data: {
            id: '5ca7f3f1-4278-4145-ac29-04224e5c5240',
            type: 'common'
          },
          source: 'f88b925d-cf6c-43fd-a6ac-0a93603568de.default',
          target: '18102029-1a51-48ca-b1d0-059bf219a3a8'
        },
        {
          data: {
            id: '3c5eaf7a-8212-4cb1-9ab2-8ce618af15db',
            type: 'common'
          },
          source: '18102029-1a51-48ca-b1d0-059bf219a3a8.complete',
          target: '1012b63c-bfe3-48c2-a127-8e6ecb7a48ca'
        },
        {
          data: {
            id: '27db6f6d-aa76-48a9-9547-602edb4ca1e9',
            type: 'common'
          },
          source: '1012b63c-bfe3-48c2-a127-8e6ecb7a48ca.default',
          target: '0fee5f73-c7ae-4ddf-8609-a44ff848b9ec'
        },
        {
          data: {
            id: '517a0a6c-ba7c-4753-ac7a-4f6984352ef8',
            type: 'common'
          },
          source: '0fee5f73-c7ae-4ddf-8609-a44ff848b9ec.7751ec23-0a88-476f-9633-81a7939db001',
          target: 'e45c7f76-cc2f-41e8-8125-475fde011458'
        },
        {
          data: {
            id: '823505e0-2543-4208-9749-da0335930d91',
            type: 'common'
          },
          source: '0fee5f73-c7ae-4ddf-8609-a44ff848b9ec.6eba749d-eda4-4eab-9be4-fe1b71113c08',
          target: '790aade8-dcad-408b-8a8d-c8a69f9323fd'
        },
        {
          data: {
            id: '05ce08f4-e7bd-44cb-a13c-0a4f8eb9b943',
            type: 'common'
          },
          source: '790aade8-dcad-408b-8a8d-c8a69f9323fd.default',
          target: 'ae867d7b-98da-45d3-b758-aef4b650597c'
        },
        {
          data: {
            id: '80555056-aaaa-4e90-a112-dcd74cd164e1',
            type: 'common'
          },
          source: 'e45c7f76-cc2f-41e8-8125-475fde011458.response',
          target: 'ae867d7b-98da-45d3-b758-aef4b650597c'
        },
        {
          data: {
            id: '03dbe6aa-ad85-4bbb-b285-0ee8284f24c7',
            type: 'common'
          },
          source: 'ae867d7b-98da-45d3-b758-aef4b650597c.default',
          target: '0b30888a-9401-4ef0-86a3-0cd9deae3146'
        },
        {
          data: {
            id: '38214e73-17a3-4f9e-8fa7-296a4ac64ad1',
            type: 'common'
          },
          source: '0b30888a-9401-4ef0-86a3-0cd9deae3146.default',
          target: '2612a588-7775-4e59-bd51-810f83c11e94'
        }
      ],
      nodes: [
        {
          id: 'start',
          top: 0,
          left: 0,
          type: 'start',
          ports: [
            {
              id: 'default'
            }
          ]
        },
        {
          id: 'c8ebaf35-c533-44fb-bfd0-e74e6f9ba10d',
          top: 160,
          body: '<p>Hey there! Let\'s get your personalized [or video] demo set up. I just need to answer [3] questions.</p>',
          left: 0,
          type: 'chat',
          ports: [
            {
              id: 'default',
              _reactKey: '9ea1d48c-d39a-44eb-ac91-5ed1f293b1c3'
            }
          ]
        },
        {
          id: 'bcbae539-7f19-4430-8d40-406eab2b3e8b',
          top: 400,
          body: '',
          left: 0,
          type: 'alert_agent',
          ports: [
            {
              id: 'default',
              label: 'Next',
              _reactKey: '43d887c4-a6b2-411c-82a3-f539518c110c'
            }
          ],
          notification: {
            title: 'New Conversation!',
            message: 'URGENT: You\'ve been routed to a conversation in Signals with {{ visitor.first_name or "SIG_FNAME" }} {{ visitor.last_name or "SIG_LNAME" }} from {{ visitor.company_name or "SIG_COMP_NAME" }}. Be quick! They won\'t wait around forever!'
          },
          routeOptions: [
            {
              type: 'round_robin',
              method: 'single_agent',
              objectIDs: null
            }
          ],
          alertUnavailableAgents: false
        },
        {
          id: '6b4d2751-67ce-4091-8f9d-40681084f4f1',
          top: 630,
          body: '<p><em>[Qualifying Question #1 (industry, integration, company name, etc)]</em></p>',
          left: 0,
          type: 'options',
          ports: [
            {
              id: '92a63356-56b5-437a-9db7-f0c3ee13544e',
              label: 'Qualifying Response',
              _reactKey: '807bb8a6-8996-4f2a-a12b-3d1d3f196ca8'
            },
            {
              id: '4f961190-fab9-475a-98e0-aea6e7dc6088',
              label: 'Qualifying Response',
              _reactKey: '0933f731-8559-4a5b-b545-93f109b8c6af'
            },
            {
              id: '39aa26f0-afbc-480a-8b9f-577f17b9d2f9',
              label: 'Disqualifying Response',
              _reactKey: '7861e0e2-7d07-4ff2-8394-5a315e06a6ec'
            }
          ],
          buttons: [
            {
              id: '92a63356-56b5-437a-9db7-f0c3ee13544e',
              label: 'Qualifying Response',
              _reactKey: '807bb8a6-8996-4f2a-a12b-3d1d3f196ca8'
            },
            {
              id: '4f961190-fab9-475a-98e0-aea6e7dc6088',
              label: 'Qualifying Response',
              _reactKey: '0933f731-8559-4a5b-b545-93f109b8c6af'
            },
            {
              id: '39aa26f0-afbc-480a-8b9f-577f17b9d2f9',
              label: 'Disqualifying Response',
              _reactKey: '7861e0e2-7d07-4ff2-8394-5a315e06a6ec'
            }
          ],
          captureTimes: 3,
          mapAttribute: false,
          responseType: 'buttons',
          keywordOptions: [],
          secondResponse: 'Still didn\'t get that. Go ahead and try again',
          invalidResponse: 'Sorry, I didn\'t understand that. Please try again'
        },
        {
          id: '53e963fa-fe87-45e8-91ed-bdfb83e33dd1',
          top: 980,
          body: '<p><em>[Qualifying Question #2 (company name, role, etc)]</em></p>',
          left: 0,
          type: 'options',
          ports: [
            {
              id: '0c8a8076-3fbe-4e81-9e4b-5a18f29f8560',
              label: 'Qualifying Response',
              _reactKey: '8f7429f0-20e2-40f5-a201-9fb4a9431e95'
            },
            {
              id: '8aa40423-105a-4e00-bb65-ffdd7753ea66',
              label: 'Qualifying Response',
              _reactKey: 'c7c49645-4bac-4bf6-a054-d6c3e40a3a47'
            },
            {
              id: '5c532312-42e4-403e-abe3-b8fa660b4977',
              label: 'Disqualifying Response',
              _reactKey: '980876c8-a6c2-47dc-8cbd-09f22eaba9b9'
            }
          ],
          buttons: [
            {
              id: '0c8a8076-3fbe-4e81-9e4b-5a18f29f8560',
              label: 'Qualifying Response',
              _reactKey: '8f7429f0-20e2-40f5-a201-9fb4a9431e95'
            },
            {
              id: '8aa40423-105a-4e00-bb65-ffdd7753ea66',
              label: 'Qualifying Response',
              _reactKey: 'c7c49645-4bac-4bf6-a054-d6c3e40a3a47'
            },
            {
              id: '5c532312-42e4-403e-abe3-b8fa660b4977',
              label: 'Disqualifying Response',
              _reactKey: '980876c8-a6c2-47dc-8cbd-09f22eaba9b9'
            }
          ],
          captureTimes: 3,
          mapAttribute: false,
          responseType: 'buttons',
          keywordOptions: [],
          secondResponse: 'Still didn\'t get that. Go ahead and try again',
          invalidResponse: 'Sorry, I didn\'t understand that. Please try again'
        },
        {
          id: 'd1c2a28b-777f-479f-be0d-11cb0244e255',
          top: 1010,
          body: '<p><span style=\'color: rgb(0, 0, 0);\'>Sorry, but based on this info, it looks like we may not be a good fit for your team. But please check out our </span><span style=\'color: rgb(131, 65, 135);\'>[Blog,Content Link] </span><span style=\'color: rgb(0, 0, 0);\'>for access to</span><span style=\'color: rgb(131, 65, 135);\'> [description]</span><span style=\'color: rgb(0, 0, 0);\'>. We hope you find the info helpful!</span></p>',
          left: 520,
          type: 'chat',
          ports: [
            {
              id: 'default',
              _reactKey: 'd29c48f7-ddf2-43cd-9db4-7a7438562fa5'
            }
          ]
        },
        {
          id: '88798417-1b43-416d-906d-f6b2f195e226',
          top: 1270,
          body: '',
          left: 520,
          type: 'conversation_status',
          ports: [
            {
              id: 'default',
              _reactKey: '60466aa4-7a85-473f-beeb-c204296b62ae'
            }
          ],
          status: 'auto-close',
          closedMessage: ''
        },
        {
          id: 'c74f1798-2275-4f71-83c4-46ab15fa49d1',
          top: 1500,
          body: '',
          left: 520,
          type: 'end_flow',
          ports: []
        },
        {
          id: '589292fd-55a5-432d-84b9-0532b7c705c3',
          top: 1350,
          body: 'Can I get your email address?',
          left: 0,
          type: 'email',
          ports: [
            {
              id: 'invalid',
              label: 'Failed to capture email',
              _reactKey: '6cff6147-d4d5-496e-8dd7-27ce84615962'
            },
            {
              id: 'valid',
              label: 'Successfully captured email',
              _reactKey: '5b8d8801-f21f-409a-abeb-046e462f7957'
            }
          ],
          unwanted: {
            spam: false,
            blocked: false,
            personal: false
          },
          captureTimes: 3,
          secondResponse: 'Please enter a valid business email',
          invalidResponse: 'Please enter a valid email',
          unwantedResponse: 'Please enter a business email'
        },
        {
          id: 'f88b925d-cf6c-43fd-a6ac-0a93603568de',
          top: 1630,
          body: '',
          left: 0,
          tags: [],
          type: 'tag',
          ports: [
            {
              id: 'default',
              _reactKey: 'afc0cf1a-eff4-40b3-8f8a-f1e1fbaa002f'
            }
          ]
        },
        {
          id: '18102029-1a51-48ca-b1d0-059bf219a3a8',
          top: 1860,
          body: '',
          left: 0,
          type: 'calendar',
          ports: [
            {
              id: 'complete',
              label: 'Meeting Scheduled',
              _reactKey: 'b89d0bef-8dc0-4801-bb19-d8735e8bdfdd'
            }
          ],
          method: 'single_agent',
          team_id: '',
          email_id: '',
          user_ids: [],
          route_type: '',
          meeting_type: '',
          routeOptions: [
            {
              type: '',
              method: 'single_agent',
              objectIDs: []
            }
          ],
          follow_up_email: false,
          text_message_reminders: true
        },
        {
          id: '1012b63c-bfe3-48c2-a127-8e6ecb7a48ca',
          top: 2080,
          body: '',
          left: 0,
          tags: [],
          type: 'tag',
          ports: [
            {
              id: 'default',
              _reactKey: '132ed6ae-ec85-4a92-8209-b4bd2840203b'
            }
          ]
        },
        {
          id: '0fee5f73-c7ae-4ddf-8609-a44ff848b9ec',
          top: 2320,
          body: '<p>Would you like to leave notes for the meeting?</p>',
          left: 0,
          type: 'options',
          ports: [
            {
              id: '7751ec23-0a88-476f-9633-81a7939db001',
              label: 'Yes please',
              _reactKey: '7fa783b3-445d-4176-a5d0-67b6225405d1'
            },
            {
              id: '6eba749d-eda4-4eab-9be4-fe1b71113c08',
              label: 'No thanks',
              _reactKey: '5e3cfb48-ac95-444f-b3d5-529d1e880edd'
            }
          ],
          buttons: [
            {
              id: '7751ec23-0a88-476f-9633-81a7939db001',
              label: 'Yes please',
              _reactKey: '7fa783b3-445d-4176-a5d0-67b6225405d1'
            },
            {
              id: '6eba749d-eda4-4eab-9be4-fe1b71113c08',
              label: 'No thanks',
              _reactKey: '5e3cfb48-ac95-444f-b3d5-529d1e880edd'
            }
          ],
          captureTimes: 3,
          mapAttribute: false,
          responseType: 'buttons',
          keywordOptions: [],
          secondResponse: 'Still didn\'t get that. Go ahead and try again',
          invalidResponse: 'Sorry, I didn\'t understand that. Please try again'
        },
        {
          id: 'e45c7f76-cc2f-41e8-8125-475fde011458',
          top: 2660,
          body: '<p>Great! Please type your notes</p>',
          left: -250,
          type: 'options',
          ports: [
            {
              id: 'response',
              label: 'Visitor Response',
              _reactKey: '8c2769f5-e8f4-4517-b953-447e5010f041'
            }
          ],
          buttons: [],
          captureTimes: 3,
          mapAttribute: false,
          responseType: 'free_response',
          keywordOptions: [],
          secondResponse: 'Still didn\'t get that. Go ahead and try again',
          invalidResponse: 'Sorry, I didn\'t understand that. Please try again'
        },
        {
          id: '790aade8-dcad-408b-8a8d-c8a69f9323fd',
          top: 2660,
          body: '<p>That\'s all!</p>',
          left: 180,
          type: 'chat',
          ports: [
            {
              id: 'default',
              _reactKey: '050717d9-bcd5-47fa-b7c1-c4ff932a3315'
            }
          ]
        },
        {
          id: 'ae867d7b-98da-45d3-b758-aef4b650597c',
          top: 2910,
          body: '<p>We\'re looking forward to your demo!</p>',
          left: -10,
          type: 'chat',
          ports: [
            {
              id: 'default',
              _reactKey: '392df015-d83b-4b85-b8c7-8a6c55078542'
            }
          ]
        },
        {
          id: '0b30888a-9401-4ef0-86a3-0cd9deae3146',
          top: 3140,
          body: '',
          left: -10,
          type: 'conversation_status',
          ports: [
            {
              id: 'default',
              _reactKey: '40c55d3f-b3f9-45a3-bd96-02837443e49a'
            }
          ],
          status: 'auto-close',
          closedMessage: ''
        },
        {
          id: '2612a588-7775-4e59-bd51-810f83c11e94',
          top: 3340,
          body: '',
          left: -10,
          type: 'end_flow',
          ports: []
        }
      ],
      ports: []
    }
  },
  targeting: {
    triggers: [],
    trigger_on: 'all_visitors',
    use_triggers: false
  },
  frequency: 'every time',
  template: {
    kind: 'flow',
    teams: [
      {
        away: 'Sorry, no one is available to chat right now',
        label: 'Click here',
        method: 'single_agent',
        team_id: null,
        welcome: 'Routing you to an agent now',
        user_ids: [],
        route_method: 'round_robin'
      }
    ],
    bot_delay: 0,
    url_matches: '',
    welcome_message: 'Welcome to our site!',
    availability_criteria: 'both'
  },
  prompt_style: 'big'
}

export default demoFlow
