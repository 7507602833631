import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Checkbox from '@material-ui/core/Checkbox'
import EnhancedTableHead from './EnhancedTableHead'
import EmptyState from './EmptyState'
import Loader from 'library/loading/Loader'
import { TableContainer } from '@material-ui/core'

function desc (a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function stableSort (array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map(el => el[0])
}

function getSorting (order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy)
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: 'calc(100vh - 150px)'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    border: '1px solid rgba(0,0,0,0.2)',
    borderRadius: 12,
    overflowX: 'auto',
    height: '100%'
  },
  table: {
    minWidth: 750,
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    overflow: 'hidden'
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  },
  loadingDiv: {
    height: 40
  },
  rowClass: {
    '&:hover': {
      boxShadow: '0px 1px 1px 0px rgba(0,0,0,0.5)'
    }
  },
  highlightRowClass: {
    backgroundColor: '#daf2e1'
  }
}))

function LoadingIndicator (props) {
  const classes = useStyles()

  if (props.loading) {
    return (
      <TableRow style={{ position: 'relative', height: 70 }}>
        <td className={classes.loadingDiv} colSpan={props.columns}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Loader type='spinner' />
          </div>
        </td>
      </TableRow>
    )
  }

  if (props.empty) {
    return (
      <TableRow style={{ position: 'relative', height: 70 }}>
        <td className={classes.loadingDiv} colSpan={props.columns}>
          <EmptyState
            message={props.emptyMessage}
          />
        </td>
      </TableRow>
    )
  }
}

export default function EnhancedTable (props) {
  const classes = useStyles()
  const [order, setOrder] = React.useState(props.order || 'asc')
  const [orderBy, setOrderBy] = React.useState(props.orderBy || 'name')
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)

  const selected = props.selected || []
  const setSelected = props.setSelected
  const headCells = props.headCells
  const CustomRow = props.CustomRow
  const rows = props.rows
  const hideCheckbox = props.hideCheckbox

  const highlightCallback = props.highlightCallback

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === 'desc'
    setOrder(isDesc ? 'asc' : 'desc')
    setOrderBy(property)
  }

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.id)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }
    if (!hideCheckbox) {
      setSelected(newSelected)
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  useEffect(() => {
    setPage(0)
  }, [rows.length])

  const isSelected = name => selected.indexOf(name) !== -1

  function EnhancedTableRow (props) {
    const classes = useStyles()
    const [hover, setHover] = useState(false)
    const row = props.row
    const isItemSelected = isSelected(row.id)
    const labelId = `enhanced-table-checkbox-${row.id}`
    return (
      <TableRow
        hover
        onClick={event => handleClick(event, row.id)}
        role='checkbox'
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={row.id}
        selected={isItemSelected}
        className={props.highlight ? classes.highlightRowClass : classes.rowClass}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        {props.hideCheckbox
          ? <></>
          : (
            <TableCell padding='checkbox'>
              <Checkbox
                checked={isItemSelected}
                inputProps={{ 'aria-labelledby': labelId }}
                color='primary'
              />
            </TableCell>)}
        <CustomRow row={row} hover={hover} />
      </TableRow>
    )
  }

  return (
    <div className={classes.root} style={{ height: props?.height ? props.height : undefined }}>
      <Paper className={classes.paper}>
        <TableContainer style={{ height: props?.height ? props.height : '93%' }}>
          <Table
            className={classes.table}
            aria-labelledby='tableTitle'
            size='small'
            aria-label='enhanced table'
            stickyHeader
          >
            <props.columnWidths />
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              headCells={headCells}
              hideCheckbox={hideCheckbox}
            />
            <TableBody style={{ overflowY: 'auto' }}>
              {props.loading || rows.length === 0
                ? (
                  <LoadingIndicator
                    loading={props.loading}
                    empty={rows.length === 0}
                    columns={props.hideCheckbox ? headCells.length : headCells.length + 1}
                    emptyMessage={props.emptyMessage || 'Nothing to display'}
                  />)
                : stableSort(rows, getSorting(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <EnhancedTableRow
                      key={index}
                      row={row}
                      hideCheckbox={props.hideCheckbox}
                      highlight={highlightCallback ? highlightCallback(row) : false}
                    />
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div style={{ display: props.hidePagination ? 'none' : null }}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component='div'
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </Paper>
    </div>
  )
}
