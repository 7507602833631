import Card from 'library/materialUI/Card'
import { makeStyles } from '@material-ui/core/styles'
import { Icon, Typography } from 'library/materialUI'
import { useContext, useEffect } from 'react'
import { TabContext } from '../IntegrationPage/TabProvider'
import { IntegrationPageContext } from '../IntegrationPage/IntegrationPageProvider'
import Picker from 'cf-components/Pickers'

const useStyles = makeStyles((theme) => ({
  subtitle1: {
    fontSize: 18,
    fontWeight: 600,
    marginBottom: 15
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  subtitle2: {
    fontSize: 15,
    fontWeight: 600,
    marginBottom: 10
  },
  campaignSelect: {
    width: 500,
    height: 40
  },
  menuHeader: {
    borderBottom: '1px solid #BBB',
    width: '100%',
    fontWeight: 600,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: 'rgba(0,0,0,0.87)'
  },
  bodyText: {
    marginBottom: 30,
    fontSize: 16
  },
  warningText: {
    fontSize: 14
  }
}))

interface DefaultCampaignCardProps {
  save: any
  handleEdit: any
  integrationSettings: any
}

export default function DefaultCampaignCard ({ save, handleEdit, integrationSettings }: DefaultCampaignCardProps) {
  const classes = useStyles()
  const { setCanSave, shouldSave } = useContext(TabContext)
  const { handleSaved } = useContext(IntegrationPageContext)

  useEffect(() => {
    if (shouldSave) {
      save()
      handleSaved()
    }
  })

  return (
    <Card
      content={
        <>
          <div className={classes.header}>
            <Typography className={classes.subtitle1}>
              Default Campaign
            </Typography>
          </div>
          <Typography variant='body2' className={classes.bodyText}>
            Automatically enroll new Leads & Contacts in the following campaign
          </Typography>
          {!integrationSettings.attributes.config.campaign && (
            <div style={{ backgroundColor: '#FEF9E5', height: '40px', display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: '10px' }}>
                <Icon icon='warning' size='sm' color='#F4D451' />
                <Typography style={{ marginLeft: '5px' }} className={classes.warningText}>
                  Select a default campaign to ensure that your Leads & Contacts are synced properly
                </Typography>
              </div>
            </div>
          )}
          <Typography variant='subtitle2' className={classes.subtitle2}>
            Campaign Name
          </Typography>
          <div style={{ width: 400 }}>
            <Picker
              selection={integrationSettings.attributes.config.campaign}
              setSelection={(campaign) => {
                handleEdit({ type: 'campaign', data: campaign }); setCanSave(true)
              }}
              objectType='contactList'
              label='Select a campaign/list'
              loadAllProps={{ searchParams: { extraHeaders: { 'x-integration': integrationSettings.attributes.integration_name } } }}
              queryBackend
            />
          </div>
        </>
      }
    />
  )
}
