import { makeStyles } from '@material-ui/core/styles'
import Fab from '@material-ui/core/Fab'
import { useContext } from 'react'
import { SessionContext } from 'session-context'

const useStyles = makeStyles(theme => ({
    title: {
        fontSize: '28px',
        fontWeight: 'bold',
        fontFamily: 'poppins',
        display: 'flex',
        justifyContent: 'center'
      },
      messageText: {
        fontSize: '20px',
        fontFamily: 'poppins',
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center'
      },
      buttonDiv: {
        display: 'flex',
        justifyContent: 'center'
      },
      buttonContent: {
        paddingRight: '80px',
        paddingLeft: '80px',
        fontSize: '20px',
        fontFamily: 'poppins',
        textTransform: 'none',
        borderRadius: '28px'
      }
  }))

export default function FreeTrialEnded (props: any) {
    const classes = useStyles()
    const { user } = useContext(SessionContext)
    const setModalStage = (stage: number) => {
      props.setModalStage(stage)
    }

    return (
      <>
        <div className={classes.title} style={{ paddingTop: '100px' }}>
          Your subscription has expired!
        </div>
        <div className={classes.messageText} style={{ paddingTop: '70px', paddingBottom: '70px' }}>
          {user.attributes.perms.semi_admin ? (
            <>
              Don't worry, we have multiple different plans to help you keep up <br />
              engaging with your customers. Upgrade now to keep using Signals
            </>
          ) : (
            <>
              It looks like your subscription has expired! Contact an administrator <br />
              to reactivate your subscription, or click the help button at the top right <br />
              corner to speak to a support agent if you believe this has happened by mistake
            </>
          )}
        </div>
        <div className={classes.buttonDiv} style={{ paddingBottom: '130px' }}>
          {user.attributes.perms.semi_admin && (
            <Fab
              className={classes.buttonContent}
              color='primary'
              size='large'
              variant='extended'
              onClick={() => setModalStage(1)}
            >
              See Plans
            </Fab>
          )}
        </div>
      </>
    )
  }
