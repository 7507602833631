import { makeStyles } from '@material-ui/core/styles'
import { useState } from 'react'
import { DashboardList } from './DashboardList'
import { IconType } from 'library/materialUI/Icon'
import { ObjectPaneContainer } from 'library/listPage/ObjectPaneContainer'

const useStyles = makeStyles(theme => ({
  objectListPage: {
    display: 'grid',
    gridTemplateColumns: '400px auto',
    height: '100%'
  },
  listSection: {
    borderRight: '1px solid #0000001A'
  }
}))

type ListItem = {
  id: string
  title: string
  value?: any
  icon?: IconType
}

type DashboardProps = {
  list: ListItem[]
  paneComponent: any
}

export function DashboardContainer (props: DashboardProps) {
  const classes = useStyles()
  const [selectedID, setSelectedID] = useState('overview')

  const select = (ID: string) => {
    setSelectedID(ID)
  }

  const Component = props.paneComponent

  return (
    <div style={{ height: '100%' }}>
      <div className={classes.objectListPage}>
        <div className={classes.listSection}>
          <DashboardList
            select={select}
            selectedID={selectedID}
            list={props.list}
          />
        </div>
        <ObjectPaneContainer>
          <Component selectedID={selectedID} />
        </ObjectPaneContainer>
      </div>
    </div>
  )
}
