import { Select } from 'cf-components/material-wrappers/MaterialComponents';
import { useState } from 'react';
import Picker from 'cf-components/Pickers';

export default function AdditionalIntegrationSection (props: any) {
  const [integration, setIntegration] = useState(props.integration)
  const [listIDs, setListIDs] = useState(props.listIDs)
  const availableIntegrations = props.availableIntegrations

  const handleSetIntegration = (e: string) => {
    if (props.setIntegration) {
      props.setIntegration(e)
    }
    if (!e && integration) {
      setListIDs([])
      props.setAdditionalIntegration(integration, [])
    }
    setIntegration(e)
  }

  const handleSetListID = (e: string[]) => {
    if (props.setListID) {
      setListIDs(e)
      props.setListID(integration, e)
    } else {
      setListIDs(e)
      props.setAdditionalIntegration(integration, e)
    }
  }

  return (
    <>
      <p>Which additional integration would you like to send your personas to?</p>
      <Select
        value={integration}
        onChange={(e: any) => handleSetIntegration(e)}
        options={availableIntegrations}
        placeholder='Select Integration'
      />
      {integration
        ? (
          <div>
            <p>Which additional campaign/list should the contact(s) be added to?</p>
            <Picker
              selection={listIDs}
              setSelection={(e: any) => handleSetListID(e)}
              objectType='contactList'
              label='Select a campaign/list'
              loadAllProps={{
                searchParams: {
                  extraHeaders: { 'x-integration': integration }
                }
              }}
              multiple
              queryBackend
            />
          </div>)
        : <></>}
    </>
  )
}
