import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'

function createTagsChart ({ data, id, setVisible }) {
  am4core.addLicense('CH213476655')

  if (!document.getElementById(id)) {
    return
  }

  const chart = am4core.create(id, am4charts.XYChart)
  chart.padding(40, 40, 40, 40)

  const categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis())
  categoryAxis.renderer.grid.template.location = 0
  categoryAxis.dataFields.category = 'name'
  categoryAxis.renderer.minGridDistance = 1
  categoryAxis.renderer.inversed = true
  categoryAxis.renderer.grid.template.disabled = true
  categoryAxis.renderer.maxWidth = 200
  categoryAxis.maxWidth = 200
  categoryAxis.renderer.fontSize = '12px'

  const valueAxis = chart.xAxes.push(new am4charts.ValueAxis())
  valueAxis.min = 0

  const series = chart.series.push(new am4charts.ColumnSeries())
  series.dataFields.categoryY = 'name'
  series.dataFields.valueX = 'count'
  series.columns.template.strokeOpacity = 0
  series.columns.template.column.cornerRadiusBottomRight = 5
  series.columns.template.column.cornerRadiusTopRight = 5

  series.columns.template.column.tooltipHTML = '<span style="color: #ffffff;">{valueX}</span>'

  // as by default columns of the same series are of the same color, we add adapter which takes colors from chart.colors color set
  series.columns.template.adapter.add('fill', function (fill, target) {
    return chart.colors.getIndex(target.dataItem.index)
  })

  categoryAxis.sortBySeries = series
  chart.data = data
  chart.data = data.map(dataPt => {
    if (dataPt.name.length > 25) {
      dataPt.name = dataPt.name.slice(0, 23).trim() + '...'
    }
    return dataPt
  })

  chart.events.on('ready', () => {
    setVisible('visible')
  })

  return chart
}

export { createTagsChart }
