import React, { useEffect, useState, useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { getInstallScript, getSettings, saveSettings } from 'api/settings'
import Modal from 'components/Modal'
import WordpressInstallationPage from './WordPressInstallationPage'
import ManualInstallationPage from './ManualInstallationPage'
import TroubleshootingPage from './TroubleshootingPage'
import AppPage from 'cf-components/AppPage'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
  textField: {
    cursor: 'text',
    width: '100%',
    marginBottom: 25,
    marginTop: 10
  },
  groupContainer: {
    backgroundColor: 'rgba(193, 166, 237, 0.1)',
    padding: 10,
    borderRadius: 10,
    marginBottom: 20
  },
  subtitle1: {
    fontSize: 18,
    fontWeight: 600,
    marginBottom: 15
  },
  subtitle2: {
    fontSize: 15,
    fontWeight: 600,
    marginBottom: 10
  }
}))

function UserPage () {
  const [installScript, setInstallScript] = useState(null)
  const [state, setState] = useState()
  const [tempValue, setTempValue] = useState()
  const [warningModalOpen, setWarningModalOpen] = useState(false)
  const classes = useStyles()

  const getChatSettings = useCallback(() => {
    getSettings().then((response) => {
      setState(response.data)
    })
  }, [])

  useEffect(() => {
    getInstallScript().then((response) => {
      setInstallScript(response.data.attributes.script)
    })
  }, [])

  const handleChange = (event) => {
    setWarningModalOpen(true)
    setTempValue(event.target.checked)
  }

  const handleCancel = () => {
    setWarningModalOpen(false)
  }

  const submitChange = () => {
    const new_state = {
      ...state,
      attributes: { ...state.attributes, master_toggle: tempValue }
    }
    setState(new_state)
    saveSettings({ state: new_state })
    setWarningModalOpen(false)
  }

  useEffect(() => {
    getChatSettings()
  }, [getChatSettings])

  document.title = 'Installation | Signals'

  return (
    <AppPage
      title='Installation'
      tabs={[
        { name: 'Manual', url: '/settings/installation/manual' },
        { name: 'WordPress', url: '/settings/installation/wordpress' },
        { name: 'Troubleshooting', url: '/settings/installation/troubleshooting' }
      ]}
    >
      <ManualInstallationPage
        scriptContents={installScript}
        handleChange={handleChange}
      />
      <WordpressInstallationPage
        scriptContents={installScript}
        handleChange={handleChange}
      />
      <TroubleshootingPage
        scriptContents={installScript}
        handleChange={handleChange}
      />
      <Modal
        open={warningModalOpen}
        onHide={() => {
          setWarningModalOpen(false)
        }}
        saveButtonText='Cancel'
        onSubmit={handleCancel}
        showBack
        backText={tempValue ? 'Yes, turn Signals on' : 'Yes, turn Signals off'}
        title='Warning'
        onBack={submitChange}
      >
        <div className={classes.groupContainer}>
          <Typography style={{ padding: 15 }} className={classes.subtitle2}>
            {tempValue
              ? 'You are about to activate Signals! If you proceed, this will enable the Signals button to appear on any site where Signals is installed, and enable any bots or live-chat funationality that has been configured.'
              : 'You are about to disable Signals! If you proceed, this will disable all bots and live-chat functionality on any of your sites where Signals is installed, and will hide the Signals button.'}
          </Typography>
          <br />
          <Typography style={{ paddingLeft: 15 }} className={classes.subtitle2}>
            Are you sure you want to continue?
          </Typography>
        </div>
      </Modal>
    </AppPage>
  )
}

export default UserPage
