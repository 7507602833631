import { makeStyles } from '@material-ui/core/styles'

const MobileAgentChatStyles = makeStyles(theme => ({
  header: {
    backgroundColor: theme.palette.primary.main,
    padding: 19,
    display: 'flex',
    alignItems: 'center'
  },
  menuIcon: {
    fill: 'white',
    fontSize: '30px',
    marginRight: 15
  },
  drawer: {
    width: '60%'
  },
  drawerDiv: {
    padding: 20
  },
  avatar: {
    height: 60,
    width: 60
  },
  onlineBadge: {
    backgroundColor: '#61CBBC',
    minWidth: '1em',
    width: '1em',
    height: '1em',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`
  },
  offlineBadge: {
    backgroundColor: '#9B9B9B',
    minWidth: '1em',
    width: '1em',
    height: '1em',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`
  },
  searchInput: {
    backgroundColor: 'rgba(255,255,255,0.1)',
    color: '#FFF',
    fontSize: '1.1em',
    borderRadius: 25,
    height: '2.4em',
    width: 'calc(100% - 4px)',
    margin: 'auto'
  },
  searchInputFocused: {
    backgroundColor: '#f2f2f2',
    color: '#7C7C7C',
    fontSize: '1.1em',
    borderRadius: 25,
    height: '2.4em',
    width: '100%',
    border: 'solid #8E5AE2 2px'
  },
  searchIcon: {
    color: '#BBB',
    paddingLeft: 12,
    paddingRight: 12
  },
  clearIcon: {
    paddingRight: 12,
    cursor: 'pointer'
  },
  filterControls: {
    display: 'grid',
    gridTemplateColumns: '2fr 1fr',
    boxShadow: '0px 1.5px 14.5px rgba(59, 74, 116, 0.135473)'
  },
  filterDiv: {
    padding: '10px 20px 10px 15px',
    display: 'flex',
    alignItems: 'center'
  },
  sortDiv: {
    padding: '10px 15px',
    borderLeft: '1px solid rgba(0,0,0,0.1)',
    height: 32,
    textAlign: 'center'
  },
  mainPage: {
    fontFamily: 'Poppins, sans serif',
    overflowY: 'hidden'
  },
  chatIcon: {
    width: '.5em',
    fill: '#7C7C7C',
    marginRight: 10
  },
  inputText: {
    color: 'rgba(0,0,0,0.5)',
    fontSize: '.9em'
  },
  selectIcon: {
    fill: '#22CCD6'
  },
  sortText: {
    fontSize: '.6em'
  },
  sortIcon: {
    fill: '#22CCD6',
    width: '.7em',
    height: '.7em'
  }
}))

const ConversationListStyles = makeStyles(theme => ({
  avatar: {
    height: '2.5em',
    width: '2.5em',
    fontWeight: 'bold',
    fontSize: '1.2em',
    lineHeight: '22px',
    backgroundColor: '#14D2B8'
  },
  avatarImage: {
    backgroundColor: '#FFF',
    borderRadius: 100
  },
  contactDiv: {
    display: 'flex',
    width: '100%'
  },
  contactInfo: {
    paddingLeft: 10,
    width: '100%'
  },
  topLine: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  contactName: {
    fontWeight: 600,
    fontSize: '.8em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  contactCompany: {
    fontSize: '.7em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  time: {
    fontSize: '.7em',
    color: '#7C7C7C'
  },
  convoDiv: {
    cursor: 'pointer',
    width: 'calc(100% - 10px)',
    paddingRight: 5,
    display: 'grid',
    gridTemplateColumns: '20px auto',
    gridTemplateRows: 'auto auto',
    gridTemplateAreas: `"flags info"
      "flags message"`,
    borderBottom: 'solid rgba(0, 0, 0, .05) 1px'
  },
  infoDiv: {
    gridArea: 'info',
    paddingTop: '3%',
    paddingBottom: '3%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  dot: {
    height: 7,
    width: 7,
    borderRadius: '50%',
    display: 'inline-block'
  },
  message: {
    color: '#6C7B8A',
    fontSize: '.7em',
    opacity: 0.6,
    marginTop: 5,
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical'
  },
  conversationList: {
    marginTop: 8,
    height: '100%',
    overflowY: 'auto',
    marginBottom: 20
  }
}))

const ConversationStyles = makeStyles(theme => ({
  header: {
    backgroundColor: theme.palette.primary.main,
    padding: '16px 8px',
    display: 'grid',
    gridTemplateColumns: '20px auto 20px',
    gridGap: 15,
    alignItems: 'center'
  },
  arrow: {
    fill: '#FFF',
    width: '.9em'
  },
  menu: {
    fill: '#FFF',
    fontSize: '2.3em'
  },
  badgeOnline: {
    backgroundColor: '#61CBBC',
    minWidth: '.8em',
    height: '.8em',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    padding: 0
  },
  badgeOffline: {
    backgroundColor: '#9B9B9B',
    minWidth: '.8em',
    height: '.8em',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    padding: 0
  },
  avatarRoot: {
    fontSize: '1em'
  },
  avatar: {
    height: '48px',
    width: '48px',
    fontWeight: 'bold',
    fontSize: '1.5em',
    backgroundColor: '#FFF',
    color: theme.palette.primary.main
  },
  avatarImage: {
    backgroundColor: theme.palette.primary.main
  },
  blankAvatar: {
    height: '48px',
    width: '48px',
    backgroundColor: '#FFF'
  },
  contactName: {
    fontWeight: 500,
    fontSize: '19px',
    lineHeight: '15px',
    color: '#FFF',
    letterSpacing: 2,
    textTransform: 'uppercase'
  },
  subInfo: {
    color: '#FFF',
    marginTop: '.2em',
    opacity: 0.8
  },
  messageEntryDiv: {
    padding: '15px 20px',
    boxShadow: '0px -5px 4px #88888850'
  },
  textEntry: {
    borderRadius: 30,
    backgroundColor: 'rgba(255,255,255,0.2)',
    '&:focus': {
      backgroundColor: 'white'
    }
  },
  chatConversationDiv: {
    display: 'grid',
    gridTemplateRows: '80px auto min-content',
    height: 'calc(var(--vh, 1vh) * 100)'
  },
  replyRow: {
    display: 'grid',
    gridTemplateColumns: 'auto 50px',
    gridGap: 10,
    alignItems: 'center',
    fontSize: '14px',
    fontFamily: 'poppins',
    padding: '5px',
    paddingTop: '12px',
    paddingBottom: '12px'
  },
  replyText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
    fontFamily: 'poppins'
  },
  buttons: {
    fontSize: '12px',
    boxShadow: 'none',
    textTransform: 'none',
    color: 'white',
    maxHeight: 24
  },
  filterChip: {
    marginRight: 4,
    maxWidth: '100%',
    backgroundColor: '#F0E3FF',
    fontSize: 12,
    color: theme.palette.primary.main,
    height: 'unset',
    '& > span': {
      paddingRight: 8
    },
    '& > svg': {
      color: 'inherit',
      width: 16,
      height: 16
    }
  },
  actionIcon: {
    height: 20,
    cursor: 'pointer'
    // marginRight: 5
  },
  menuItem: {
    padding: '3px 8px',
    fontSize: '12px'
  }
}))

const MessageStyles = makeStyles(theme => ({
  messageContainer: {
    display: 'flex',
    marginBottom: 10
  },
  messageBubble: {
    background: 'linear-gradient(164.6deg, #2DC9EB 0%, #14D2B8 100%)',
    padding: 10,
    color: 'white',
    fontSize: '14px',
    marginRight: 5,
    borderRadius: 15
  },
  timestamp: {
    fontSize: '9px',
    color: '#9C9C9C',
    minWidth: 45,
    display: 'flex',
    alignItems: 'flex-end'
  },
  avatar: {
    backgroundColor: '#F1F1F1',
    height: 25,
    width: 25,
    fontSize: '16px',
    marginRight: 5,
    color: '#14D2B8'
  },
  dateContainer: {
    paddingBottom: 0,
    marginBottom: '1em',
    display: 'flex',
    justifyContent: 'center',
    top: 0
  },
  dividerContainer: {
    flex: 1
  },
  dividerDiv: {
    paddingTop: 6,
    backgroundColor: 'white',
    height: 1
  },
  dateDiv: {
    fontFamily: 'Poppins, sans serif',
    fontSize: '10px',
    color: '#555555',
    paddingLeft: 10,
    paddingRight: 10,
    backgroundColor: 'white'
  },
  messageDisplayDiv: {
    // height: "calc(100% - 40px)",
    overflowY: 'auto',
    padding: 20
  },
  messages: {
    height: '100%',
    overflowY: 'auto'
  }
}))

export { MobileAgentChatStyles, ConversationListStyles, ConversationStyles, MessageStyles }
