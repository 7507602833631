import { useContext } from 'react'
import { ActiveConversation } from './ActiveConversation'
import { ConversationWindowContext } from './conversation-window-context'
import SelectedConversations from './SelectedConversations'
import { getConversationFromList } from 'api/conversations'
import { useQuery, useQueryClient } from 'react-query'

function ConversationView ({ open, setOpen, conferenceOpen, setConferenceOpen, calendarOpen, setCalendarOpen, refresh, activeConversationQueryKey }) {
  const { state, clearSelection, selectConversation } = useContext(ConversationWindowContext)
  const { selectedConversations, activeConversationID } = state
  const queryClient = useQueryClient()

  const { data: conversation } = useQuery(activeConversationQueryKey, () => getConversationFromList({
    activeConversationID
  }).then(response => response.data), { staleTime: 15000, enabled: !!activeConversationID })

  const refreshConversation = () => {
    refresh()
    queryClient.invalidateQueries(activeConversationQueryKey)
    queryClient.invalidateQueries('filter-counts')
  }

  if (activeConversationID) {
    return (
      <ActiveConversation
        open={open}
        setOpen={setOpen}
        conferenceOpen={conferenceOpen}
        setConferenceOpen={setConferenceOpen}
        calendarOpen={calendarOpen}
        setCalendarOpen={setCalendarOpen}
        conversation={conversation}
        refreshConversation={refreshConversation}
        selectConversation={selectConversation}
      />
    )
  }
  return (
    <SelectedConversations
      selectedConversations={selectedConversations}
      handleCancel={clearSelection}
      refresh={refresh}
    />
  )
}

export default ConversationView
