import { makeStyles } from '@material-ui/core/styles'
import { List, ListItem, Icon } from 'library/materialUI'

const useStyles = makeStyles(theme => ({
  list: {
    padding: 0
  },
  listItem: {
    borderBottom: '1px solid #0000001A',
    color: '#000000BF'
  },
  listItemSelected: {
    borderBottom: '1px solid #0000001A',
    color: 'white',
    backgroundColor: '#333399BF !important'
  },
  listItemContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: 60
  },
  listItemTitle: {
    font: 'normal normal normal 12px/15px Poppins',
    paddingLeft: 10
  },
  listItemHeader: {
    display: 'flex',
    alignItems: 'center'
  },
  listItemValue: {
    font: 'normal normal normal 21px/21px Poppins',
    fontWeight: 600,
    marginTop: 5
  },
  largeTitle: {
    font: 'normal normal 600 16px/25px Poppins'
  }
}))

function ItemHeader (props) {
  const classes = useStyles()
  const object = props.object

  if (object.icon) {
    return (
      <div className={classes.listItemHeader}>
        <Icon icon={object.icon} />
        <div className={classes.listItemTitle}>
          {object.title}
        </div>
      </div>
    )
  } else {
    return (
      <div className={classes.largeTitle}>
        {object.title}
      </div>
    )
  }
}

function Item (props) {
  const classes = useStyles()
  const object = props.object
  return (
    <div className={classes.listItemContainer}>
      <div>
        <ItemHeader object={object} />
        {object.value && <div className={classes.listItemValue}>{object.value}</div>}
      </div>
      <div>
        <Icon icon='rightArrow' />
      </div>
    </div>
  )
}

export function DashboardList (props) {
  const classes = useStyles()
  return (
    <List className={classes.list}>
      {props.list.map((object, index) => (
        <ListItem
          id={`${object.id}-dashboard-item`}
          key={index}
          className={classes.listItem}
          classes={{ selected: classes.listItemSelected }}
          onClick={() => props.select(object.id)}
          selected={props.selectedID === object.id}
          button
        >
          <Item
            object={object}
            selected={props.selectedID === object.id}
          />
        </ListItem>
      ))}
    </List>
  )
}
