import { useState, useEffect, useContext } from 'react'
import { SessionContext } from 'session-context'
import Banner from 'pages/trial/FreeTrialBanner'
import { getBillingSubscription } from 'api/billing'

export function FreeTrialBanner () {
  const { user } = useContext(SessionContext)
  const [billing, setBilling] = useState(null)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    getBillingSubscription(1)
      .then(response => {
        if (response.data?.attributes?.state === 'trialing') {
          setOpen(true)
        }
        setBilling(response.data)
      })
      .catch(() => {
        setOpen(false)
      })
  }, [user.relationships.tenant.data.id])

  return (
    <Banner
      trialBanner={open}
      closeBanner={() => setOpen(false)}
      billing={billing}
    />
  )
}
