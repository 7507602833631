import React, { useState, useEffect } from 'react'
import { makeStyles, ThemeProvider } from '@material-ui/core/styles'
import { jsPlumbUtil } from 'jsplumbtoolkit'
import OptionPort from '../OptionPort'
import useNodeStyles from './NodeStyles'
import Divider from '@material-ui/core/Divider'
import BlockIcon from '@material-ui/icons/Block'
import StarIcon from '@material-ui/icons/Star'
import { EVENT_TYPE, Emitter } from 'emitter'
import LeadScoreSelector from 'library/contacts/LeadScoreSelector'
import { Modal, ModalSection } from 'library/Modal'
import theme from 'theme'

const useStyles = makeStyles(theme => ({
  leadScoreButton: {
    borderRadius: 30,
    height: 35
  },
  leadScoreIcon: {
    height: '.75em',
    width: '.75em',
    fill: '#5C5C5C'
  },
  selectedStarIcon: {
    height: '.75em',
    width: '.75em',
    fill: 'gold'
  },
  subtitle: {
    color: '#999',
    fontFamily: 'Poppins, sans serif',
    fontSize: '12px',
    marginBottom: 10
  }
}))

function LeadScoreDisplay (props) {
  const classes = useStyles()
  if (props.score === 0) {
    return (
      <>
        <BlockIcon style={{ height: '.65em', width: '.65em', fill: '#F0706F', marginTop: 2 }} />
      </>
    )
  } else {
    return (
      <>
        {[...Array(props.score)].map((value, index) => (<StarIcon classes={{ root: classes.selectedStarIcon }} key={index} />))}
      </>
    )
  }
}

function LeadScoreModal (props) {
  const [score, setScore] = useState(props.score)

  useEffect(() => {
    setScore(props.score)
  }, [props.score])

  function cancelChanges () {
    props.onHide()
    setScore(props.score)
  }

  Emitter.on(EVENT_TYPE.BROWSER_BACK, cancelChanges)

  return (
    <ThemeProvider theme={theme}>
      <Modal
        open={props.open}
        onHide={cancelChanges}
        handleSave={() => props.save(score)}
        title='Set Lead Score'
        helplink='home/bots/nodes/LeadScore'
        helplinkLabel='Learn more about this skill'
        saveIcon='save'
      >
        <ModalSection
          subtitle='Automatically rank leads by giving them a lead score.'
        >
          <LeadScoreSelector score={score} setScore={setScore} />
        </ModalSection>
      </Modal>
    </ThemeProvider>
  )
}

const LeadScoreNode = props => {
  const base = props.base
  const classes = useNodeStyles(props)

  const assignKey = data => {
    data._reactKey = data._reactKey || jsPlumbUtil.uuid()
    return data._reactKey
  }

  const saveChanges = (score) => {
    if (!base.toolkit) {
      return
    }
    const node = base.getNode()
    const new_data = { ...base.state, score: score }
    base.toolkit.updateNode(node, new_data)
    props.setModalOpen(false)
  }

  return (
    <>
      <div>
        <div
          className={`${classes.dragger} ${classes.backgroundAction}`}
        >
          <div className='node-header'>
            <div className={classes.iconDiv}><StarIcon /></div>
            <div className={classes.cardTitle}>Set Lead Score</div>
          </div>
          <div className='node-body'>
            <div style={{ display: 'flex' }}>
              <div>
                Mark contact as:
              </div>
              {base.state.score !== undefined
                ? (
                  <div style={{ display: 'flex', marginLeft: 5, marginTop: 1 }}>
                    <LeadScoreDisplay score={base.state.score} />
                  </div>
                ) : (<></>)}
            </div>
            <Divider style={{ marginTop: 5 }} />
            <div>
              {base.state.ports.map((c, index) => {
                return (
                  <OptionPort
                    index={index}
                    data={c}
                    key={assignKey(c)}
                    parent={base}
                    kind='backgroundAction'
                    label='Next'
                  />
                )
              })}
            </div>
          </div>
          <jtk-target scope='default' />
        </div>
      </div>
      <LeadScoreModal
        open={props.modalOpen}
        onHide={() => props.setModalOpen(false)}
        save={saveChanges}
        score={base.state.score}
      />
    </>
  )
}

export default LeadScoreNode
