
const descriptionText = 'Create a contact for the visitor in your CRM, and add the visitor to a pre-determined marketing campaign when they reach this node in the bot flow.'

const Description = (): JSX.Element => {
  return (
    <div>
      <div>
        {descriptionText}
      </div>
      <ul>
        <li><a href='#/contact_management/contacts' target='_blank'>Manage CF Contacts</a></li>
        <li>Manage campaigns within software for your CRM</li>
      </ul>
    </div>
  )
}

const bestPracticeText = 'Similar to the Add to Campaign node in the PlayMaker. The Bot Builder node fires based on specific interaction with the visitor through designated bots.'

const BestPractices = () => {
  return (
    <div>
      <div>
        {bestPracticeText}
      </div>
      <ul>
        <li>Use this node when you want to add a user to a campaign based upon their responses to bot prompts</li>
        <li>Don't use this node based upon the visitor's web traffic, use the PlayMaker instead</li>
      </ul>
    </div>
  )
}

export const AddToCampaignDocs = {
  helpCenterLink: 'https://help.getsignals.ai/category/rcns060bgn-funnel-bot-builder',
  bestPractices: BestPractices,
  description: Description,
  videoLink: ''
}
