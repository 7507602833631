import { makeStyles } from '@material-ui/core/styles'
import { useContext } from 'react'
import { SectionProps } from '../HelpCenter'
import { HelpCenterCard, THelpCenterCard } from '../HelpCenterCard'
import { HelpCenterContext } from '../HelpCenterContext'
import { OverviewsHome } from '../OverviewsHome'
import { SectionCenter } from '../SectionCenter'
import { BotNodesCenter } from './BotNodesHome'
import { BotTemplatesCenter } from './BotTemplatesHome'

const useStyles = makeStyles(theme => ({
  headerText: {
    font: 'normal normal 600 24px/35px Poppins',
    color: '#000000BF'
  }
}))

const BotsHome = (): JSX.Element => {
  const classes = useStyles()
  const { navigateBack } = useContext(HelpCenterContext)

  const cards: THelpCenterCard[] = [
    { title: 'Bot Builder Overview', icon: 'cursor', link: 'botsOverview' },
    { title: 'Template Types', icon: 'chat', link: 'botTemplates' },
    { title: 'Skill Types', icon: 'module', link: 'nodes' }
  ]

  return (
    <div>
      <div className={classes.headerText} onClick={navigateBack}>
        Building Bots
      </div>
      <div>
        {cards.map((card: THelpCenterCard, index: number) => (
          <HelpCenterCard key={index} card={card} />
        ))}
      </div>
    </div>
  )
}

const sectionMapping = {
  nodes: BotNodesCenter,
  botsOverview: OverviewsHome,
  botTemplates: BotTemplatesCenter
}

export const BotsCenter = (props: SectionProps): JSX.Element => {
  return (
    <SectionCenter
      homeComponent={<BotsHome />}
      sectionMapping={sectionMapping}
      links={props.links}
    />
  )
}
