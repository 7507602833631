import audio from 'assets/audio_alert.wav'
import twoTone from 'assets/two_tone.wav'

const PageTitleNotification = {
  Vars: {
    OriginalTitle: 'Signals',
    Interval: null
  },
  On: function (notification, intervalSpeed) {
    const _this = this
    _this.Vars.Interval = setInterval(function () {
      window.document.title = (_this.Vars.OriginalTitle === window.document.title)
        ? notification
        : _this.Vars.OriginalTitle
    }, (intervalSpeed) || 500)
  },
  Off: function () {
    clearInterval(this.Vars.Interval)
    this.Vars.Interval = null
    document.title = this.Vars.OriginalTitle
  }
}

let visibilityChange
if (typeof document.hidden !== 'undefined') {
  visibilityChange = 'visibilitychange'
} else if (typeof document.msHidden !== 'undefined') {
  visibilityChange = 'msvisibilitychange'
} else if (typeof document.webkitHidden !== 'undefined') {
  visibilityChange = 'webkitvisibilitychange'
}

document.addEventListener(visibilityChange, handleVisibilityChange, false)

function handleVisibilityChange () {
  PageTitleNotification.Off()
}

export function soundAlert (type) {
  let snd, notification
  if (type === 'message') {
    snd = new Audio(audio)
    notification = 'New Message!'
  } else if (type === 'conversation') {
    snd = new Audio(twoTone)
    notification = 'New Conversation!'
  }
  snd.play().catch(e => {
    if (!e.message.includes('interact with the document first')) {
      console.log('failed to play alert sound', { err: e })
    }
  })

  if (document.hidden) {
    if (!PageTitleNotification.Vars.Interval) {
      PageTitleNotification.On(notification)
    }
  }
}
