import React from 'react'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  textBox: {
    marginTop: 0,
    marginBottom: 0
  }
}))

function FormTextField ({ field, form: { touched, errors }, ...props }) {
  const classes = useStyles()
  return (
    <TextField
      {...field}
      id={props.id}
      name={props.id}
      variant='outlined'
      label={props.label}
      fullWidth={props.fullWidth}
      className={classes.textBox}
      error={errors[props.id] && touched[props.id]}
    />
  )
}

export default FormTextField
