import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Link from '@material-ui/core/Link'
import Button from '@material-ui/core/Button'
import RenderEmoji from '../RenderEmoji'
import DateFormat from 'components/DateFormat'
import { getVisitorInfo } from './conversationHelpers'
import { ConversationContext } from './ConversationContext'

const useStyles = makeStyles(theme => ({
  ctaButton: {
    color: 'white',
    marginBottom: 10,
    fontWeight: 'bold'
  },
  chatContainer: {
    marginLeft: '60px'
  },
  buttonText: {
    textTransform: 'none'
  },
  eventContainer: {
    paddingBottom: 0,
    marginBottom: '1.4em',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    fontFamily: 'Poppins, sans serif',
    fontSize: '.9em',
    color: '#555555',
    fontStyle: 'italic'
  }
}))

function CTAButton (props) {
  const classes = useStyles()
  const cta = props.cta.data
  const target = cta?.open_target || '_parent'
  const label = cta?.label || 'empty :('
  const color = cta?.color || ''
  const url = cta?.url.split('?', 1)[0] || ''
  return (
    <div className={classes.chatContainer}>
      <Button
        component={Link}
        target={target}
        rel='noopener'
        href={url}
        style={{
          backgroundColor: color
        }}
        className={classes.ctaButton}
        variant='outlined'
      >
        <RenderEmoji
          text={label}
          className={classes.buttonText}
        />
      </Button>
    </div>
  )
}

function ButtonPush (props) {
  const classes = useStyles()
  const cta = props.cta
  const { conversation } = useContext(ConversationContext)
  const { visitorName } = conversation ? getVisitorInfo(conversation) : ''
  const message = visitorName + ' clicked button - '
  return (
    <div className={classes.eventContainer}>
      {message}
      <DateFormat dateToFormat={cta.created_timestamp} format='shortTime' />
    </div>
  )
}

function CTAEvent (props) {
  const events = props.group.events.cta
  return (
    <>{events.map((e, k) => {
      const data = e.attributes
      if (data.kind === 'button_push') {
        return (<ButtonPush key={k} cta={data} />)
      }
      return (<CTAButton key={k} cta={data} />)
    })}
    </>
  )
}

export default CTAEvent
