import { getTenantSalesforceID, saveTenantSalesforceID } from 'api/admin_only/tenants'
import { queryClient } from 'App'
import { BasicModal } from 'cf-components/Modal'
import { CRMAccount } from 'classes/crmAccount'
import Loader from 'library/loading/Loader'
import { Button, TextBox } from 'library/materialUI'
import { useEffect, useState } from 'react'

interface AdminAddSalesforceNoteProps {
  tenantId: number
}

export const AdminAddSalesforceNote = ({ tenantId }: AdminAddSalesforceNoteProps): JSX.Element => {
  const [openModal, setOpenModal] = useState(false)
  const [accountID, setAccountID] = useState('')
  const { data, isLoading, isError, error } = CRMAccount.loadOne(accountID)

  useEffect(() => {
    if (tenantId) {
      getTenantSalesforceID(tenantId)
        .then(response => {
          const id = response.data.attributes.salesforce_id
          if (id) {
            setAccountID(id)
            queryClient.invalidateQueries(['crm', 'accounts'])
          }
        })
    }
  }, [tenantId])

  if (isLoading) {
    return <Loader />
  } else if (isError) {
    console.error('unable to load CRM account', error)
    return <>Unable to load CRM account</>
  }

  const account = data.list[0]

  const handleClick = (id: string) => {
    if (id) {
      window.open(account.link)
    } else {
      setOpenModal(true)
    }
  }

  const handleSave = (id: string) => {
    if (id) {
      saveTenantSalesforceID({ tenantID: tenantId, accountID: id })
        .then(() => {
          setOpenModal(false)
          window.open(`https://na133.salesforce.com/${id}`)
        })
    }
  }

  const message = (
    <div>
      <div style={{ marginBottom: 10 }}>Please enter the desired Salesforce account ID in the box below in order to link this tenant to a Salesforce account.</div>
      <TextBox
        value={accountID}
        onChange={(val: string) => setAccountID(val)}
      />
    </div>
  )

  return (
    <div>
      <Button
        color='primary'
        onClick={() => handleClick(accountID)}
        variant='contained'
      >
        View Account in Salesforce
      </Button>
      <BasicModal
        message={message}
        title='Salesforce Account Not Found'
        open={openModal}
        onHide={() => setOpenModal(false)}
        confirmButtonText='SUBMIT'
        confirmAction={() => handleSave(accountID)}
      />
    </div>
  )
}
