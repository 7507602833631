import React, { useEffect, useState } from 'react'
import { jsPlumbUtil } from 'jsplumbtoolkit'
import { makeStyles } from '@material-ui/core/styles'
import useNodeStyles from './NodeStyles'
import Divider from '@material-ui/core/Divider'
import OptionPort from '../OptionPort'
import EditModal from '../EditModal'
import { Icon, RadioButtons } from 'library/materialUI'
import Picker from 'cf-components/Pickers'
import { Checkbox, Typography } from '@material-ui/core'
import ModalWarning from 'components/ModalWarning'
import { Cadence } from 'classes/classes'
import { routeOptionsSchema, RoutingSelector } from 'library/routing/RoutingCriteria'
import DefaultOwnerSection from '../DefaultOwnerSection'
import { getIntegration } from 'api/integrations'
import { EVENT_TYPE, Emitter } from 'emitter'

const useStyles = makeStyles(theme => ({
  subtitle: {
    color: '#444',
    fontFamily: 'Poppins, sans serif',
    fontSize: '14px',
    marginBottom: 5
  },
  warning: {
    color: '#FF0000',
    fontFamily: 'Poppins, sans serif',
    fontSize: '12px',
    marginBottom: 5
  },
  sectionHeader: {
    fontSize: '15px',
    fontWeight: 600,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  divider: {
    marginTop: 10,
    marginBottom: 10
  },
  exceptionCheckbox: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 5
  },
  contentClass: {
    fontFamily: 'Poppins'
  }
}))

const searchParams = { search: 'salesloft' }

function SalesloftModal (props: any) {
  const [exceptionCadences, setExceptionCadences] = useState(props.exceptions || [])
  const [allowReEnroll, setAllowReEnroll] = useState(props.hasExceptions || false)
  const [cadenceId, setCadenceID] = useState(props.cadenceID)
  const [action, setAction] = useState(props.adding || 'add')
  const [routeOptions, setRouteOptions] = useState(props.routeOptions || [{ method: 'account_owner', objectIDs: [], type: 'round_robin' }])
  const classes = useStyles()
  const warningBody = 'Make sure the visitor\'s email is known before this skill or else the visitor will not be added to Salesloft'

  if (!props.open) {
    return <></>
  }

  Emitter.on(EVENT_TYPE.BROWSER_BACK, props.onHide)

  return (
    <EditModal
      open={props.open}
      onHide={props.onHide}
      save={() => {
        props.save({ cadenceId, action, allowReEnroll, exceptionCadences, routeOptions })
      }}
      title='Salesloft Add/Remove from Cadence'
      helpLink='https://help.getsignals.ai/article/uginlbo0yi-sales-loft-bot-skill'
      contentClass={classes.contentClass}
      disabled={!routeOptionsSchema.isValidSync(routeOptions)}
    >
      <div>
        <ModalWarning body={warningBody} />
        <div className={classes.subtitle}>
          Add or remove visitors from a Salesloft cadence
        </div>
        <div style={{ marginBottom: 15 }}>
          <RadioButtons
            value={action}
            handleChange={(value) => {
              setAction(value)
              if (value === 'remove') {
                setAllowReEnroll(false)
                setExceptionCadences([])
              }
            }}
            buttons={[
              { label: 'Add to cadence', value: 'add' },
              { label: 'Remove from cadence', value: 'remove' }
            ]}
          />
        </div>
        <div className={classes.sectionHeader}>
          Cadence
        </div>
        <Divider className={classes.divider} />
        <div style={{ marginBottom: 10 }}>
          Which cadence should the visitor(s) be {action === 'remove' ? 'removed from' : 'added to'}?
        </div>
        <div className={classes.divider}>
          <Picker
            selection={cadenceId}
            setSelection={e => {
              setCadenceID(e)
            }}
            objectType='cadence'
            label='Select a cadence'
            loadAllProps={{ searchParams: searchParams }}
          />
        </div>
        {action === 'remove' ? <></>
        : (
          <>
            <div className={classes.exceptionCheckbox}>
              <Checkbox
                color='primary'
                style={{ padding: 4, marginBottom: -2, marginLeft: 5 }}
                checked={allowReEnroll}
                onChange={(e) => {
                  if (!e.target.checked) {
                    setExceptionCadences([])
                  }
                  setAllowReEnroll(e.target.checked)
                }}
              />
              Allow contacts already enrolled in cadences to be re-enrolled into new ones
            </div>
            {allowReEnroll ? (
              <div>
                <div className={classes.sectionHeader}>
                  Exceptions
                </div>
                <Divider className={classes.divider} />
                <Typography variant='subtitle1'>
                  Visitors enrolled in the following cadences will remain in their current cadence and will not be re-enrolled in the cadence above.
                </Typography>
                <div className={classes.divider}>
                  <Picker
                    selection={exceptionCadences}
                    setSelection={e => {
                      setExceptionCadences(e)
                    }}
                    objectType='cadence'
                    placeholder='Exception Cadences'
                    loadAllProps={{ searchParams: searchParams }}
                    multiple
                  />
                </div>
              </div>) : <></>}
            <div style={{ marginTop: 15 }}>
              <RoutingSelector
                title='Ownership Criteria'
                description='How should the Salesloft Owner be determined?'
                routeOptions={routeOptions}
                handleChange={setRouteOptions}
                actionText='assign'
                limit={2}
              />
            </div>
          </>)}
        <Divider className={classes.divider} />
        <DefaultOwnerSection
          integration='salesloft'
          link='/#/settings/salesloft'
        />
      </div>
    </EditModal>
  )
}

const SalesloftNode = (props: any) => {
  const base = props.base
  const classes = useNodeStyles(props)
  const [displayWarning, setDisplayWarning] = useState(true)
  useEffect(() => {
    if (displayWarning) {
      getIntegration('salesloft').then((res: any) => {
        if (res?.data?.attributes?.enabled) {
          setDisplayWarning(false)
        }
      })
    }
  })

  const assignKey = (data: any) => {
    data._reactKey = data._reactKey || jsPlumbUtil.uuid()
    return data._reactKey
  }
  const { data } = Cadence.loadAll()

  const saveChanges = ({ cadenceId, action, allowReEnroll, exceptionCadences, routeOptions }: any) => {
    if (!base.toolkit) {
      return
    }
    const node = base.getNode()
    if (action === 'remove') {
      const newData = {
        ...base.state,
        cadenceId: cadenceId,
        action: action,
        allowReEnroll: false,
        exceptionCadences: [],
        routeOptions: []
      }
      base.toolkit.updateNode(node, newData)
    } else {
      const newData = {
        ...base.state,
        cadenceId: cadenceId,
        action: action,
        allowReEnroll: allowReEnroll,
        exceptionCadences: exceptionCadences,
        routeOptions: routeOptions
      }
      base.toolkit.updateNode(node, newData)
    }
    props.setModalOpen(false)
  }
  if (displayWarning) {
    return (
      <div>
        <div
          className={`${classes.dragger} ${classes.backgroundAction}`}
        >
          <div className='node-header'>
            <div className={classes.iconDiv}><Icon icon='salesloft' /></div>
            <div className={classes.cardTitle}>Salesloft</div>
          </div>
          <div className='node-body' style={{ minHeight: 18 }}>
            <ModalWarning body='Salesloft is not enabled' />
          </div>
          <jtk-target
            scope='default'
          />
        </div>
        <jtk-target
          scope='default'
        />
      </div>
    )
  }
  return (
    <>
      <div>
        <div
          className={`${classes.dragger} ${classes.backgroundAction}`}
        >
          <div className='node-header'>
            <div className={classes.iconDiv}><Icon icon='salesloft' /></div>
            <div className={classes.cardTitle}>Salesloft</div>
          </div>
          <div className='node-body' style={{ minHeight: 18 }}>
            <div>{base.state.action === 'add' || base.state.action === undefined ? 'Add visitor to' : 'Remove visitor from'} {data?.list.find(cadence => cadence.id === base.state.cadenceId)?.name || 'cadence'} in Salesloft</div>
            <Divider style={{ marginTop: 5 }} />
            {base.state.ports.map((c: any, index: number) => {
              return (
                <OptionPort
                  index={index}
                  data={c}
                  key={assignKey(c)}
                  parent={base}
                  kind='backgroundAction'
                  label='Next'
                  type='salesloft'
                />
              )
            })}
          </div>
          <jtk-target
            scope='default'
          />
        </div>
        <jtk-target
          scope='default'
        />
      </div>
      <SalesloftModal
        open={props.modalOpen}
        onHide={() => {
          props.setModalOpen(false)
        }}
        save={saveChanges}
        cadenceID={base.state.cadenceId}
        adding={base.state.action}
        exceptions={base.state.exceptionCadences}
        hasExceptions={base.state.allowReEnroll}
        routeOptions={base.state.routeOptions}
      />
    </>
  )
}

export default SalesloftNode
