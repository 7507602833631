import { Actions, Fields } from 'cf-components/table/Table'
import AccountDetails from 'classes/accountDetails'
import { TableProvider } from 'library/table/TableProvider'
import CrowdSourceDataList from './CrowdSourceDataList'

export default function CrowdSourceModerationTable () {
  const fields: Fields<AccountDetails> = [
    { name: 'companyName', type: 'text' },
    { name: 'field', type: 'text' },
    { name: 'value', type: 'text' },
    { name: 'createdTimestamp', type: 'text' },
    { name: 'createdByUserID', type: 'text' },
    { name: 'tenantID', type: 'text' }
  ]
  const actions: Actions<any> = []
  const templateColumns = '1fr 1fr 1fr 1fr 1fr 1fr 1fr'
  return (
    <div>
      <TableProvider<AccountDetails>
        ObjectClass={AccountDetails}
        fields={fields}
        actions={actions}
        noEmail
        gridTemplateColumns={templateColumns}
      >
        <CrowdSourceDataList />
      </TableProvider>
    </div>
  )
}
