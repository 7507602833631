import { makeStyles } from '@material-ui/core/styles'
import { Icon, Typography } from 'library/materialUI'

const useStyles = makeStyles(theme => ({
  contentBody: {
    display: 'block',
    textAlign: 'center',
    padding: '20px 0'
  },
  contentRow: {
    marginTop: 10
  }
}))

interface ConfirmationContentProps {
  title: string
  info: string
}

export default function ConfirmationContent ({ title, info }: ConfirmationContentProps): JSX.Element {
  const classes = useStyles()
  return (
    <div className={classes.contentBody}>
      <Icon icon='checkCircle' color='#81AF20' specificSize={50} />
      <Typography className={classes.contentRow} style={{ fontWeight: 700 }} variant='h1'>{title}</Typography>
      <Typography className={classes.contentRow} variant='body2'>{info}</Typography>
    </div>
  )
}
