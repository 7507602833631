import { makeStyles } from '@material-ui/core/styles'
import Form from 'classes/forms'
import { Segment } from 'classes/segments'
import { Button, Divider, Icon, Radio } from 'library/materialUI'
import Picker from 'library/Picker'
import { useEffect, useState } from 'react'

const useStyles = makeStyles(theme => ({
  container: {
    margin: '15px',
    padding: '20px',
    backgroundColor: '#FFFFFF',
    border: '1px solid #D3D3D3',
    borderRadius: '5px'
  },
  sectionSubtitle: {
    color: '#808080',
    fontFamily: 'poppins',
    fontSize: '14px',
    marginTop: '5px',
    marginBottom: '5px'
  },
  sectionTitle: {
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#000000BF'
  },
  radioSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginTop: '10px',
    marginBottom: '10px',
    padding: '20px',
    borderRadius: '5px',
    border: '1px solid'
  },
  editButton: {
    display: 'flex',
    width: '127px',
    height: '40px',
    fontSize: '14px',
    border: '1px solid #D3D3D3',
    borderRadius: '5px',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#E4E4E4'
    }
  }
}))

interface FormTargetingCardProps {
  open: boolean
  setOpen: (value: boolean) => void
  form: Form
  dispatch: any
  setCanSave: (value: boolean) => void
}

export default function FormTargetingCard ({ open, setOpen, form, dispatch, setCanSave }: FormTargetingCardProps) {
  const classes = useStyles()
  const useCriteria = form.targeting === 'criteria'
  const [includedList, setIncludedList] = useState<string[]>([])
  const [excludedList, setExcludedList] = useState<string[]>([])
  const { data: segments, isLoading } = Segment.loadAll()

  const handleDone = () => {
    setOpen(false)
    if (form.targeting === null) {
      form.targeting = 'all'
    }
  }

  useEffect(() => {
    if (segments.list) {
      const list = segments.list
      const included: string[] = []
      const excluded: string[] = []
      list.forEach((item) => {
        if (form.targetSegmentList.includes(item.id)) {
          included.push(item.name)
        }
        if (form.targetExcludedSegmentList.includes(item.id)) {
          excluded.push(item.name)
        }
      })
      setIncludedList(included)
      setExcludedList(excluded)
    }
    // eslint-disable-next-line
  }, [form.targetSegmentList, form.targetExcludedSegmentList])

  if (isLoading) {
    return <></>
  }

  return (
    <div className={classes.container}>
      <div onClick={() => setOpen(!open)} style={{ display: 'flex', flexDirection: 'row', cursor: 'pointer' }}>
        <div style={{ marginRight: '10px', marginTop: '5px' }}>
          <Icon icon={form.targeting === null ? 'uncheckedRadio' : 'lightGreenCheckCircle'} />
        </div>
        <div className={classes.sectionTitle}>
          Targeting
        </div>
        <div style={{ marginLeft: 'auto', marginRight: '5px' }}>
          {open ? (
            <div>
              <Icon icon='close' />
            </div>
          ) : (
            <div className={classes.editButton}>
              Edit Settings
            </div>
          )}
        </div>
      </div>
      <div onClick={() => setOpen(!open)} className={classes.sectionSubtitle}>
        {(form.targeting === null || open) ? (
          <div>
            Configure which visitors will see this prompt after submitting the form
          </div>
        ) : form.targeting === 'all' ? (
          <div>
            Show to everyone who submits the form
          </div>
        ) : form.targeting === 'criteria' ? (
          <div>
            {form.targetSegmentList.length > 0 && (
              <div>
                Show only to visitors in <b style={{ color: '#000000' }}>{includedList.toString().replaceAll(',', ', ')}</b> segment{includedList.length > 1 ? 's' : ''}
              </div>
            )}
            {form.targetExcludedSegmentList.length > 0 && (
              <div>
                Exclude visitors in <b style={{ color: '#000000' }}>{excludedList.toString().replaceAll(',', ', ')}</b> segment{excludedList.length > 1 ? 's' : ''}
              </div>
            )}
          </div>
        ) : (
          <></>
        )}
      </div>
      {open && (
        <div>
          <div className={classes.radioSection} style={{ borderColor: !useCriteria ? '#9933FF' : '#D3D3D3' }}>
            <Radio
              label='Show to everyone who submits the form'
              value={!useCriteria}
              checked={!useCriteria}
              handleChange={() => { dispatch({ type: 'targeting', value: 'all' }); setCanSave(true) }}
            />
          </div>
          <div className={classes.radioSection} style={{ borderColor: useCriteria ? '#9933FF' : '#D3D3D3' }}>
            <Radio
              label='Show only to form submitters who match certain criteria'
              value={useCriteria}
              checked={useCriteria}
              handleChange={() => { dispatch({ type: 'targeting', value: 'criteria' }); setCanSave(true) }}
            />
            {useCriteria && (
              <div>
                <div style={{ width: '100%', margin: '10px', marginRight: '20px' }}>
                  <div className={classes.sectionSubtitle}>
                    Which segment(s) should see the prompt?
                  </div>
                  <Divider />
                  <Picker
                    selection={form?.targetSegmentList || []}
                    setSelection={(val) => dispatch({ type: 'targetSegmentList', value: val })}
                    multiple
                    objectType='segments'
                    label='Included Segments'
                  />
                </div>
                <div style={{ width: '100%', margin: '10px', marginRight: '20px', marginTop: '20px' }}>
                  <div className={classes.sectionSubtitle}>
                    Which segment(s) should <i>not</i> see the prompt?
                  </div>
                  <Divider />
                  <Picker
                    selection={form?.targetExcludedSegmentList || []}
                    setSelection={(val) => dispatch({ type: 'targetExcludedSegmentList', value: val })}
                    multiple
                    objectType='segments'
                    label='Excluded Segments'
                  />
                </div>
              </div>
            )}
          </div>
          <div style={{ marginTop: '20px', marginLeft: 'auto', marginRight: '10px', width: '60px' }}>
            <Button
              onClick={() => handleDone()}
            >
              Done
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}
