import React from 'react'
import { Select, OutlinedInput } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  select: {
    marginLeft: 15,
    marginRight: 15,
    width: 300
  }
}))

function TimezonePicker (props) {
  const classes = useStyles()

  function handleChange (event) {
    const value = event.target.value
    props.handler({ type: 'updateSetting', value: value, name: 'timezone' })
  }

  return (
    <Select
      native
      value={props.value}
      className={classes.select}
      input={<OutlinedInput />}
      onChange={handleChange}
      margin='dense'
    >
      <option value='US/Alaska'>US/Alaska</option>
      <option value='US/Arizona'>US/Arizona</option>
      <option value='US/Central'>US/Central</option>
      <option value='US/Eastern'>US/Eastern</option>
      <option value='US/Hawaii'>US/Hawaii</option>
      <option value='US/Mountain'>US/Mountain</option>
      <option value='US/Pacific'>US/Pacific</option>
      <option value='US/Hawaii'>US/Hawaii</option>
    </Select>
  )
}

export default TimezonePicker
