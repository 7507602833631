import { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import FilterMenu from './FilterMenu'
import { Tooltip, Popover } from '@material-ui/core'
import { getTeams } from 'api/teams'
import { ReactComponent as FilterIcon } from 'img/filterIcon.svg'

export default function FilterButton (props) {
  const useStyles = makeStyles(theme => ({
    filterMenu: {
      paddingTop: 5,
      paddingBottom: 5
    },
    filterIcon: {
      marginRight: 15,
      stroke: 'rgba(0, 0, 0, 0.54)',
      cursor: 'pointer',
      '&:hover': {
        stroke: theme.palette.primary.main
      }
    },
    menuPaper: {
      borderRadius: 10
    }
  }))

  const classes = useStyles()
  const chatServiceUrl = props.chatServiceUrl
  const filterOptions = props.filterOptions
  const filterValues = filterOptions?.filterValues
  const [teams, setTeams] = useState(null)

  const handleClick = (event) => {
    props.setState({
      ...props.state,
      anchorEl: event.currentTarget
    })
  }

  const handleClose = () => {
    props.setState({
      anchorEl: null,
      subMenu: null
    })
  }

  useEffect(() => {
    if (!teams) {
      if (chatServiceUrl && filterValues && filterValues.map(filter => filter.value).includes('Team')) {
        getTeams({ chatServiceUrl })
          .then(response =>
            setTeams(response.data))
      }
    }
  }, [chatServiceUrl, filterValues, teams])

  return (
    <>
      <Tooltip title='Filter'>
        <FilterIcon
          className={classes.filterIcon}
          onClick={handleClick}
          style={{ stroke: props.state.anchorEl ? '#8e5ae2' : null }}
        />
      </Tooltip>
      {props.state.anchorEl &&
        <Popover
          open={Boolean(props.state.anchorEl)}
          anchorEl={props.state.anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          style={{ marginTop: 10 }}
          classes={{ paper: classes.menuPaper }}
        >
          {filterOptions.customFilterMenu
            ? (
              <filterOptions.customFilterMenu
                handleClose={handleClose}
                {...filterOptions.menuProps}
              />)
            : (
              <FilterMenu
                subMenu={props.state.subMenu}
                setSubMenu={(val) => props.setState({ ...props.state, subMenu: val })}
                handleClose={handleClose}
                filterOptions={props.filterOptions}
                teams={teams}
              />)}
        </Popover>}
    </>
  )
}
