import { ClickAwayListener, Drawer } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ActionsMenu from 'cf-components/ActionsMenu'
import { Button, Icon, Popover } from 'library/materialUI'
import { useContext, useState } from 'react'
import { VersionHistoryState } from './VersionMenu'
import RestoreIcon from '@material-ui/icons/Restore'
import { FlowBuilder } from 'library/flowBuilder/FlowBuilder'
import { nodeFactory, nodeMapping } from 'pages/plays/playBuilder/nodes/nodeMapping'
import { FlowBuilderContext } from '../FlowBuilderProvider'
import { User } from 'classes/classes'
import { Sequence } from 'classes/sequences'

const useStyles = makeStyles(theme => ({
  drawer: {
    margin: '20px 10px 10px 20px',
    width: 250
  },
  versions: {
    paddingTop: 20,
    marginBottom: 20
  },
  flex: {
    display: 'flex',
    alignItems: 'center'
  },
  title: {
    fontSize: 16,
    fontWeight: 600
  },
  current: {
    fontSize: 10,
    color: theme.palette.primary.main,
    margin: '5px 0 5px'
  },
  agent: {
    fontSize: 10,
    color: '#00000080', // theme.palette.background.mediumGrey,
    marginTop: 5
  },
  line: {
    height: 1,
    width: 250,
    backgroundColor: '#00000026' // theme.palette.background.lightGrey
  },
  pointer: {
    cursor: 'pointer'
  },
  previewTitle: {
    fontSize: 23,
    fontWeight: 500,
    margin: '0 30px 0 20px'
  },
  icon: {
    height: 18,
    width: 18,
    marginRight: 5
  },
  position: {
    position: 'absolute',
    zIndex: 1000000,
    left: 100,
    top: 100
  },
  previewHeader: {
    padding: 20,
    width: 'calc(100vw - 384px)',
    borderBottom: '1px solid #00000026', // ${theme.palette.background.lightGrey}`,
    boxShadow: '0px 5px 3px -3px #00000026' // ${theme.palette.background.lightGrey}`
  },
  close: {
    width: '100%',
    textAlign: 'right'
  }
}))

export const VersionHistoryDrawer = (props: {
  state: VersionHistoryState
  hideMenu: any
  save: any
}): JSX.Element => {
  const classes = useStyles()
  const [currentID, setCurrentID] = useState(props.state.draftID)
  const { object } = useContext(FlowBuilderContext)
  const currentIndex = props.state.versions.findIndex((el) => el.id === currentID)
  const users = User.loadAll()
  const tenantUsers: { [key: string]: User } = users.data.dict

  let initialData, dateString, timeString
  if (currentID) {
    initialData = {
      data: {
        nodes: [...props.state.versions[currentIndex].nodes],
        edges: [...props.state.versions[currentIndex].edges]
      }
    }
    dateString = props.state.versions[currentIndex].createdTimestamp?.toLocaleDateString()
    timeString = props.state.versions[currentIndex].createdTimestamp?.toLocaleTimeString()
  }

  const handleRestore = async (flow: Sequence) => {
    if (props.state.sequenceID) {
      await Sequence.restoreFlow(flow.id, props.state.sequenceID)
      window.location.reload()
    }
  }

  const actions = [
    { name: 'Restore', action: handleRestore, icon: RestoreIcon }
  ]

  return (
    <>
      {currentID &&
        <Popover
          open={Boolean(currentID)}
          style={{ width: 'calc(100% - 312px)' }}
        >
          <ClickAwayListener onClickAway={() => setCurrentID(null)}>
            <div style={{ width: '100%', height: '100%' }}>
              <div className={`${classes.flex} ${classes.previewHeader}`}>
                <Icon
                  icon='leftArrow'
                  size='lg'
                  onClick={() => setCurrentID(null)}
                  cursor='pointer'
                />
                <div className={classes.previewTitle}>{dateString} {timeString}</div>
                <Button
                  size='small'
                  variant='contained'
                  color='primary'
                  disabled={props.state.publishedID === props.state.versions[currentIndex].id}
                  onClick={() => { handleRestore(props.state.versions[currentIndex]); setCurrentID(null) }}
                >
                  <RestoreIcon className={classes.icon} />
                  RESTORE THIS VERSION
                </Button>
              </div>
              <div style={{ height: 'calc(100vh - 105px)' }}>
                <FlowBuilder
                  object={object}
                  initialData={initialData}
                  nodeMapping={nodeMapping}
                  nodeFactory={nodeFactory}
                  objectType='sequences'
                  preview
                  altColor
                />
              </div>
            </div>
          </ClickAwayListener>
        </Popover>}
      <Drawer
        open={props.state.menuOpen}
        anchor='right'
        onClose={props.hideMenu}
      >
        <div className={classes.drawer}>
          <div className={classes.close}>
            <Icon
              icon='close'
              size='md'
              onClick={() => props.hideMenu()}
              cursor='pointer'
              color='primary'
            />
          </div>
          {props.state.versions.sort((a, b) => {
            if (a.id > b.id) {
              return -1
            } else {
              return 1
            }
          }).map(version => {
            const versionDateString = version.createdTimestamp?.toLocaleDateString()
            const versionTimeString = version.createdTimestamp?.toLocaleTimeString()
            const id: string = version.createdByUserID.toString()
            const currentUser = tenantUsers[id]?.name || 'Unknown or Deleted User'

            return (
              <div style={{ backgroundColor: currentID === version.id ? '#F1E9F8' : '#FFFFFF' }} key={version.id}>
                <div className={`${classes.versions} ${classes.flex}`} style={{ justifyContent: 'space-between' }}>
                  <div onClick={() => setCurrentID(version.id)} className={classes.pointer}>
                    <div className={classes.title}>{versionDateString} {versionTimeString}</div>
                    {version.id === props.state.publishedID ? <div className={classes.current}>Current Published</div> : version.id === props.state.draftID ? <div className={classes.current}>Current Draft</div> : <></>}
                    <div className={classes.agent}>{currentUser}</div>
                  </div>
                  <ActionsMenu actions={actions} row={version} />
                </div>
                <div className={classes.line} />
              </div>
            )
          })}
        </div>
      </Drawer>
    </>
  )
}
