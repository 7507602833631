import SkeletonAvatar from './SkeletonAvatar'
import SkeletonLabel from './SkeletonLabel'
import SkeletonNameIconAndSubtitle from './SkeletonNameIcon&Subtitle'
import SkeletonNumber from './SkeletonNumber'

interface SkeletonLoaderProps {
  type: keyof typeof mapping
  size: SkeletonSize
  variant?: SkeletonVariant | 'circle'
}

export type SkeletonSize = 'sm' | 'md' | 'lg' | 'full'
export type SkeletonVariant = 'circle' | 'rect'

const flexStart = { display: 'flex', justifyContent: 'flex-start', width: '100%', alignItems: 'center' }
const flexCenter = { display: 'flex', alignItems: 'center' }

export const mapping = {
  avatar: (size: SkeletonSize) => <SkeletonAvatar size={size} />,
  'nameIcon&Subtitle': (size: SkeletonSize) => <SkeletonNameIconAndSubtitle size={size} />,
  number: (size: SkeletonSize) => <SkeletonNumber size={size} />,
  'avatarNameIcon&Subtitle': (size: SkeletonSize, variant?: SkeletonVariant | 'circle') => <div style={flexStart}><div style={{ marginRight: variant === 'rect' ? 5 : 0 }}><SkeletonAvatar size={size} variant={variant} /></div> <SkeletonNameIconAndSubtitle size={size} variant={variant} /></div>,
  'squareAvatar&Label': (size: SkeletonSize) => <div style={flexStart}><div style={{ marginRight: 10 }}><SkeletonAvatar size={size} variant='rect' /></div> <SkeletonLabel size={size} /> </div>,
  label: (size: SkeletonSize) => <SkeletonLabel size={size} />,
  'name&Subtitle': () => <div><SkeletonLabel size='sm' /><SkeletonLabel size='md' /></div>,
  smLabel: () => <div style={flexCenter}><SkeletonLabel size='sm' /></div>
}

export default function SkeletonLoader ({ type, size = 'md', variant = 'circle' }: SkeletonLoaderProps) {
  const Component = mapping[type]
  return Component(size, variant)
}
