import { castToDate, DateType, formatDate } from './classHelpers'

export class GenericObject {
  readonly id: number | string
  readonly createdTimestamp: DateType
  readonly createdDate: string
  readonly createdByUserID: number
  readonly updatedTimestamp: DateType
  readonly lastEdited: string
  name: string
  objectType = 'row'

  get subtitle (): string { return '' }
  get searchField (): string { return this.name }

  constructor ({ row }: { row?: Record<string, any> } = {}) {
    this.id = row?.id
    this.name = row?.attributes?.name || ''
    this.createdTimestamp = castToDate(row?.attributes?.created_timestamp)
    this.createdDate = formatDate(this.createdTimestamp)
    this.createdByUserID = row?.attributes?.created_by_user_id
    this.updatedTimestamp = row?.attributes?.updated_timestamp ? castToDate(row?.attributes.updated_timestamp) : this.createdTimestamp
    this.lastEdited = formatDate(this.updatedTimestamp)
  }
}

export default GenericObject
