import AppPage from 'cf-components/AppPage'
import { useEffect, useReducer, useState } from 'react'
import { getReducer } from '../ChatSettings/settingsHelpers'
import BlockedPageList from './BlockedPageList'
import PageChecker from './PageChecker'
import { getSettings, saveSettings } from 'api/settings'
export interface BlockedPage {
  comparison: string
  url: string
}

export default function BlockedPagesPage () {
  const [disabled, setDisabled] = useState(true)
  const [error, setError] = useState(false)
  const [state, dispatch] = useReducer(getReducer({ availabiltyTypeColumnName: 'chat_setting', setDisabled }), null)
  const tabs = [
    { name: 'PAGES', url: '/settings/blocked_pages/pages' },
    { name: 'PAGE CHECKER', url: '/settings/blocked_pages/page_checker' }
  ]

  function save () {
    setError(false)
    saveSettings({ state })
      .then(settingsResponse => {
        if ('data' in settingsResponse) {
          setDisabled(true)
        }
      })
    return true
  }

  useEffect(() => {
    getSettings().then(settingsResponse => {
      if ('data' in settingsResponse) {
        dispatch({ type: 'initialize', data: settingsResponse.data })
      } else {
        dispatch({ type: 'initialize', data: defaultSettings })
      }
      setDisabled(true)
    })
  }, [])

  const saveProps = {
    disabled: disabled,
    save: save,
    saveText: 'Save Settings',
    validPaths: 'chat_settings',
    error: error
  }

  return (
    <AppPage
      title='Blocked Pages'
      tabs={tabs}
      padding={20}
      saveProps={saveProps}
    >
      <BlockedPageList state={state} dispatch={dispatch} />
      <PageChecker state={state} />
    </AppPage>
  )
}
