import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import ChatIcon from '@material-ui/icons/Chat'
import Button from '@material-ui/core/Button'
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import { makeStyles } from '@material-ui/core/styles'

const variantIcon = {
  info: ChatIcon
}

const useStyles1 = makeStyles(theme => ({
  info: {
    backgroundColor: '#FD9038'
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  message: {
    display: 'flex',
    alignItems: 'center'
  },
  button: {
    color: '#FFFFFF'
  }
}))

function MySnackbarContentWrapper (props) {
  const classes = useStyles1()
  const { className, message, handleClaim, variant, dismiss, ...other } = props
  const Icon = variantIcon[variant]

  return (
    <SnackbarContent
      className={clsx(classes[variant], className)}
      aria-describedby='client-snackbar'
      message={
        <span id='client-snackbar' className={classes.message}>
          <Icon className={clsx(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <Button className={classes.button} onClick={handleClaim} key='claim'>
          Claim
        </Button>,
        <Button className={classes.button} onClick={dismiss} key='dismiss'>
          Dismiss
        </Button>
      ]}
      {...other}
    />
  )
}

MySnackbarContentWrapper.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired
}

export default function RouteSnackBar (props) {
  const open = props.open

  const handleClaim = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    props.claimRoute(props.route)
  }

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        open={open}
        autoHideDuration={30000}
        style={{ marginTop: 30 }}
      >
        <MySnackbarContentWrapper
          handleClaim={handleClaim}
          variant='info'
          message='New conversation'
          dismiss={props.dismiss}
        />
      </Snackbar>
    </div>
  )
}
