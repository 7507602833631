import { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Popover from '@material-ui/core/Popover'
import Checkbox from '@material-ui/core/Checkbox'
import Select from '@material-ui/core/Select'
import Chip from '@material-ui/core/Chip'
import { getLocations } from 'api/locations'
import TreeMultiSelect, { useTreeItemStyles, TreeMultiSelectStyle } from 'cf-components/TreeMultiSelect'
import { ScrollBox } from './ScrollBox'

const useStyles = makeStyles(theme => ({
  paper: {
    maxWidth: 'calc(100% - 64px)',
    width: 690,
    maxHeight: 420,
    fontSize: '.9em',
    padding: '0 12px 0 4px'
  },
  listItem: {
    width: '100%',
    cursor: 'pointer',
    fontFamily: 'Poppins, sans serif',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.04)'
    }
  },
  disabledOption: {
    color: '#909090',
    cursor: 'initial',
    pointerEvents: 'none',
    height: '2em',
    display: 'flex',
    alignItems: 'center',
    fontSize: '1rem',
    marginLeft: 12
  },
  horizontalLine: {
    width: 'calc(100% - 10px)',
    marginLeft: 10,
    borderBottom: '2px solid #D0D0D0'
  },
  chipSelectInput: {
    paddingTop: 4,
    paddingBottom: 4,
    minHeight: 32
  },
  chip: {
    marginRight: 5,
    marginBottom: 5
  },
  allSelected: {
    color: 'rgba(0,0,0,0.4)',
    paddingTop: 6
  },
  selectMenu: {
    whiteSpace: 'normal',
    maxHeight: 80
  }
}))

const treeItemStyles: TreeMultiSelectStyle = {
  fontSize: '.9rem'
}

export default function GeographySelector (props: {
  values: string[]
  setValues: (values: string[]) => void
  className?: string
  emptyText?: string
  fullWidth?: boolean
  multiple?: boolean
}): JSX.Element {
  const classes = useStyles()
  const treeItemClasses = useTreeItemStyles(treeItemStyles)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const { data: locations = [] } = getLocations()

  return (
    <>
      <Select
        fullWidth={props.fullWidth}
        value={[]}
        variant='outlined'
        margin='dense'
        multiple
        displayEmpty
        inputProps={{ readOnly: true }}
        classes={{ select: classes.chipSelectInput + (props.className ? ' ' + props.className : ''), selectMenu: classes.selectMenu }}
        onClick={(e) => setAnchorEl(e.currentTarget)}
        renderValue={() => {
          return (
            <ScrollBox style={{ maxHeight: 80 }} noScrollBar>
              {props.values.length ? props.values.map(value => (
                <Chip
                  color='primary'
                  className={classes.chip}
                  key={value}
                  label={value}
                  onDelete={() => {
                    const newValues = new Set(props.values)
                    newValues.delete(value)
                    props.setValues([...newValues])
                  }}
                />
              )) : (
                <div className={classes.allSelected}>
                  {!props.emptyText ? 'No restriction' : props.emptyText}
                </div>)}
            </ScrollBox>
          )
        }}
      />
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        classes={{ paper: classes.paper }}
      >
        <div>
          <div className={classes.listItem + ' ' + classes.disabledOption}>
            Select locations
          </div>
          <div
            className={treeItemClasses.labelRoot + ' ' + classes.listItem}
            onClick={() => props.setValues([])}
          >
            <Checkbox
              color='primary'
              checked={!props.values.length}
            />
            No restriction
          </div>
          <div className={classes.horizontalLine} />
          <TreeMultiSelect
            selected={props.values}
            setSelected={props.setValues}
            items={locations}
            styles={treeItemStyles}
          />
        </div>
      </Popover>
    </>
  )
}
