import React, { useContext, useState } from 'react'
import { Select, MenuItem, InputLabel, FormControl } from '@material-ui/core'
import { BillingContext } from '../../billing-context'
import AddIcon from '@material-ui/icons/Add'
import { addCreditCard } from 'api/billing'
import ChargifyForm from 'pages/billing_v2/ChargifyForm'

export default function PaymentMethodSelector (props: any) {
  const { payments, refetchPayments } = useContext(BillingContext)
  const selectedPaymentMethodId = props.selectedPaymentMethodId
  const [newCard, setNewCard] = useState(false)

  const formatPaymentName = (payment: any) => {
    const fullName = payment?.attributes?.first_name + ' ' + payment?.attributes?.last_name || 'Payment Method'
    const maskedNumber = payment?.attributes?.masked_card_number
    return `${fullName} (${maskedNumber})`
  }

  const handleAddPaymentMethod = (token: string): void => {
    addCreditCard(token)
      .then(response => {
        if (response.data) {
          setNewCard(false)
          props.onCloseModal()
          refetchPayments()
        }
      })
    }

  const handlePaymentSelect = (event: any) => {
    if (event.target.value !== undefined) {
      props.onSelectPaymentMethodId(event.target.value)
    }
  }

  if (payments === null) {
    return <div>Loading payments</div>
  }

  return (
    <>
      {newCard ? <ChargifyForm onAddPaymentMethod={handleAddPaymentMethod} /> : (
        <FormControl variant='outlined' fullWidth>
          <InputLabel>Payment Method</InputLabel>
          <Select
            label='Payment Methods'
            name='type'
            variant='outlined'
            fullWidth
            value={selectedPaymentMethodId}
            onChange={handlePaymentSelect}
            margin='dense'
            defaultValue='preview'
          >
            <MenuItem value='preview' disabled>Select a payment method</MenuItem>
            {payments.map((option: any, index: any) => (
              <MenuItem key={index} value={option.id}>{formatPaymentName(option)}</MenuItem>
            ))}
            <MenuItem onClick={() => setNewCard(true)}>
              <div style={{ display: 'flex' }}>
                Add Payment Method
                <AddIcon color='primary' />
              </div>
            </MenuItem>
          </Select>
        </FormControl>
      )}
    </>
  )
}
