import { Sequence } from 'classes/sequences'
import { useState } from 'react'
import { Page } from 'library/page/Page'
import { FlowBuilder } from 'library/flowBuilder/FlowBuilder'
import { nodeMapping, nodeFactory } from '../playBuilder/nodes/nodeMapping'
import { SettingsDrawer } from 'library/SettingsDrawer'
import { ModalSection } from 'library/Modal'
import { TextBox, Tooltip } from 'library/materialUI'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  itemActive: {
    display: 'flex',
    alignItems: 'center',
    color: '#81AF20',
    fontWeight: 0,
    fontSize: '12px',
    marginLeft: 10,
    backgroundColor: '#E6EFD2',
    borderRadius: 5,
    padding: 5,
    cursor: 'default'
  },
  itemInactive: {
    display: 'flex',
    alignItems: 'center',
    color: '#868686',
    fontWeight: 0,
    marginLeft: 10,
    fontSize: '12px',
    backgroundColor: '#E5E5E5',
    borderRadius: 5,
    padding: 5,
    cursor: 'default'
  }
}))

export function SequencePage (props: any): JSX.Element {
  const sequenceID = props.match.params.sequenceID
  const history = props.history
  const { data: sequence, isLoading, isError, error } = Sequence.loadOne(sequenceID)

  if (isLoading) {
    return (
      <div />
    )
  } else if (isError) {
    console.error('unable to load play sequence', { error })
    return (
      <div>Unable to load the Play. Please refresh the page and try again. If the problem persists, please contact our support team.</div>
    )
  }

  return (
    <SequenceEditor sequence={sequence} history={history} />
  )
}

function SequenceEditor ({ sequence, history }: any): JSX.Element {
  const classes = useStyles()
  const [state] = useState(sequence)
  const [titleName, setTitleName] = useState(sequence.name)
  const [description, setDescription] = useState(sequence.description)
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [settingsChanged, setSettingsChanged] = useState(false)

  const initialData = {
    data: {
      nodes: [...state.nodes],
      edges: [...state.edges]
    }
  }

  const handleBack = () => {
    history.push('/engagement/playmaker/plays')
  }

  const save = ({ data }: any) => {
    const exitNode = data.nodes.filter((node: any) => node.id === 'exit')[0]
    const ports = exitNode.ports.map((p: any) => ({ id: p.id, label: p.label }))
    const newSequence = { ...sequence, edges: data.edges, nodes: data.nodes, ports, name: titleName, description: description }
    return Sequence.save({ sequence: newSequence, objectType: 'plays' })
  }
  let extra = <></>
  if (sequence.isActive) {
    extra = <Tooltip title='This play is currently active. If you want to deactivate it, remove it from your PlayMaker.'><div className={classes.itemActive}>Active</div></Tooltip>
  } else {
    extra = <Tooltip title='This play is currently inactive. If you want to activate it, add it to your PlayMaker.'><div className={classes.itemInactive}>Inactive</div></Tooltip>
  }

  return (
    <Page
      title={{ name: titleName, extra }}
      subtitle={description}
      tabs={[]}
      back={handleBack}
    >
      <SettingsDrawer
        title='Play Settings'
        open={drawerOpen}
        handleClose={() => setDrawerOpen(false)}
      >
        <ModalSection
          title='Play Title'
        >
          <TextBox
            value={titleName}
            onChange={(value: string) => {
              setTitleName(value)
              setSettingsChanged(true)
            }}
            autoFocus
          />
        </ModalSection>
        <ModalSection
          title='Description'
        >
          <TextBox
            value={description}
            onChange={(value: string) => {
              setDescription(value)
              setSettingsChanged(true)
            }}
            rows={20}
          />
        </ModalSection>
      </SettingsDrawer>
      <FlowBuilder
        save={save}
        editDetails={() => setDrawerOpen(true)}
        object={state}
        objectType='sequences'
        initialData={initialData}
        nodeMapping={nodeMapping}
        nodeFactory={nodeFactory}
        flowOptions={['edit', 'version', 'save']}
        // altColor
        history={history}
        edited={settingsChanged}
      />
    </Page>
  )
}
