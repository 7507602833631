import { makeStyles } from '@material-ui/core/styles'
import { Button, Typography, Icon } from 'library/materialUI'
import { Select, SelectOption } from 'cf-components/material-wrappers/MaterialComponents'
import { getContactLists } from 'api/crm_contacts'
import { useEffect, useMemo, useState } from 'react'
import { GetEnabledIntegrations } from 'helpers/integrations'
import { EmailQueryStringSettings } from './EmailQueryStringSettings'

const useStyles = makeStyles(theme => ({
  sectionSubtitle: {
    color: '#808080',
    fontFamily: 'poppins',
    fontSize: '12px',
    marginTop: '5px',
    marginBottom: '5px'
  },
  input: {
    width: '450px'
  }
}))

interface IntegrationPickerProps {
  settings: EmailQueryStringSettings
  dispatch: any
  setCanSave: (value: boolean) => void
}

export default function IntegrationPicker ({ settings, dispatch, setCanSave }: IntegrationPickerProps) {
  const classes = useStyles()
  const [integrationLists, setIntegrationLists] = useState<string[][]>(settings.integrations.map(() => []));
  const [enabledIntegrations, setEnabledIntegrations] = useState({
    salesforce: false,
    marketo: false,
    hubspot: false,
    sendinblue: false,
    eloqua: false
  })

  useEffect(() => {
    fetchContactLists(settings.integrations)
  }, [settings.integrations])

  const fetchContactLists = async (integrations: string[]) => {
    const promises = integrations.map((integration) =>
      integration !== null ? getContactLists({ integration }).catch(_error => ({ data: [] })) : Promise.resolve({ data: [] })
    )
    const results = await Promise.all(promises)
    const newIntegrationLists = results.map((res) =>
      res.data.map((row: any) => ({ value: row.id, label: row.attributes.name }))
    );
    setIntegrationLists(newIntegrationLists)
  };

  function handleAddIntegration () {
    dispatch({ type: 'setIntegrations', value: [...settings.integrations, ''] })
    dispatch({ type: 'setCampaignIds', value: [...settings.campaignIds, ''] })
  }

  function handleRemoveIntegration (index: number) {
    dispatch({ type: 'setCampaignIds', value: settings.campaignIds.filter((_, i) => i !== index) })
    dispatch({ type: 'setIntegrations', value: settings.integrations.filter((_, i) => i !== index) })
    setCanSave(true)
  }

  const availableIntegrations: SelectOption[] | { value: string; label: string }[] = []

  if (enabledIntegrations.salesforce) {
    availableIntegrations.push({ value: 'salesforce', label: 'Salesforce' })
  }
  if (enabledIntegrations.hubspot) {
    availableIntegrations.push({ value: 'hubspot', label: 'Hubspot' })
  }
  if (enabledIntegrations.sendinblue) {
    availableIntegrations.push({ value: 'sendinblue', label: 'Brevo' })
  }

  useMemo(() => {
    GetEnabledIntegrations().then((newIntegrations) => setEnabledIntegrations(newIntegrations))
  }, [])

  return (
    <div>
      {settings.integrations.map((integration, index) => (
        <div key={index} style={{ marginBottom: '20px' }}>
          <div style={{ width: '450px', display: 'flex', justifyContent: 'space-between' }}>
            <div>Integration {index + 1}</div>
            <Button
              onClick={() => handleRemoveIntegration(index)}
              style={{ cursor: 'pointer' }}
              startIcon={<Icon icon='close' size='sm' />}
              variant='text'
            >
              REMOVE
            </Button>
          </div>
          <Typography className={classes.sectionSubtitle}>
            Select an integration to send form submitters to
          </Typography>
          <div className={classes.input}>
            <Select
              onChange={(value: string) => {
                dispatch({ type: 'setIntegrations', value: settings.integrations.map((integration, i) => i === index ? value : integration) })
                setCanSave(true);
              }}
              value={integration}
              options={availableIntegrations}
              placeholder='Select an integration'
              disableClear={!integration}
            />
          </div>
          <Typography className={classes.sectionSubtitle}>
            Select a campaign/list to send form submitters to
          </Typography>
          <div className={classes.input}>
            <Select
              onChange={(value: string) => {
                dispatch({ type: 'setCampaignIds', value: settings.campaignIds.map((campaignID, i) => i === index ? value : campaignID) })
                setCanSave(true)
              }}
              value={settings.campaignIds[index] || ''}
              options={integrationLists[index] || []}
              placeholder='Select a campaign'
              disableClear={!settings.campaignIds[index]}
            />
          </div>
        </div>
      ))}
      <Typography style={{ color: '#9933FF', cursor: 'pointer', marginTop: '10px' }} onClick={handleAddIntegration}>
        + add a campaign/list
      </Typography>
    </div>
  )
}
