import SkeletonTableLoader from 'library/loading/skeleton/SkeletonTableLoader'
import { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles'
import SkeletonLabel from 'library/loading/skeleton/SkeletonLabel';
const useStyles = makeStyles(theme => ({
  tabPlaceholder: {
    width: 200,
    marginTop: 5,
    marginBottom: 5,
    marginLeft: 10,
    marginRight: 20
  }
}))
interface EmailSubscriptionTableProps {
  openModal: (id: number) => void
  deleteRow: (id: number) => void
}
const mywindow = window as any

export default function EmailSubscriptionTable ({ openModal, deleteRow }: EmailSubscriptionTableProps) {
  const classes = useStyles()
  useEffect(() => {
    try {
      /*
      mywindow.htmx.logger = function (elt: any, event: any, data: any) {
        if (console) {
          console.log('INFO:', event, elt, data);
        }
      }
        */

      // Start observing the target node for configured mutations
      mywindow.addEventListener('rowEvent', function (e: any) {
        const tableID = e.detail.tableID
        const rowID = e.detail.rowID
        const rowAction = e.detail.action
        if (tableID === 'email-updates-body') {
          switch (rowAction) {
            case 'delete':
              deleteRow(rowID)
              break
            case 'edit':
              openModal(rowID)
              break
            default:
              console.log('Unknown action: ', e.detail)
          }
        }
      });
    } catch (e) {
      console.log(e)
    }
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <div style={{ backgroundColor: 'white' }}>
        <div
          hx-get='/api/core/web/settings/email_updates'
          hx-trigger='revealed'
        >
          <div>
            <div style={{ display: 'flex', justifyContent: 'right', padding: 10 }}>
              <div className={classes.tabPlaceholder} style={{ margin: 10 }}> <SkeletonLabel size='full' /> </div>
              <div className={classes.tabPlaceholder} style={{ margin: 10 }}> <SkeletonLabel size='full' /> </div>
            </div>
            <SkeletonTableLoader
              rowCount={7}
              types={['label', 'label', 'label', 'squareAvatar&Label', 'avatar']}
              columns='2fr 1fr 1fr 1fr 50px'
              justifyCenter
            />
          </div>
        </div>
      </div>
    </>
  )
}
