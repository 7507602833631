import React, { useRef, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import LoadingButton from './LoadingButton'

const useStyles = makeStyles(theme => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: '#FFF'
  },
  title: {
    backgroundColor: theme.palette.primary.main,
    color: 'white'
  },
  twoButtonFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 14
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: 14
  }
}))

function Modal (props) {
  const classes = useStyles()

  function closeModal () {
    props.onHide()
  }

  useEffect(() => {
    if (props.open) {
      saveButtonRef.current = true
      onBackButtonRef.current = true
    }
  }, [props.open])

  const saveButtonText = props.saveButtonText || 'Save'
  const size = props.size || 'sm'
  const backText = props.backText || 'Back'

  const showBack = props.showBack
  const loadingButton = props.loadingButton
  const variant = props.variant || 'contained'
  const noButton = props.noButton || false
  const saveButtonRef = useRef(true)
  const onBackButtonRef = useRef(true)

  return (
    <Dialog
      onClose={props.onHide}
      open={props.open}
      fullWidth
      maxWidth={size}
    >
      <MuiDialogTitle className={classes.title}>
        {props.title}
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={closeModal}
        >
          <CloseIcon />
        </IconButton>
      </MuiDialogTitle>
      <MuiDialogContent dividers style={props.noPadding ? { padding: 0 } : null}>
        {props.children}
      </MuiDialogContent>
      {noButton
        ? <></>
        : (
          <div className={showBack ? classes.twoButtonFooter : classes.footer}>
            {showBack
              ? (
                <Button
                  onClick={() => {
                    if (onBackButtonRef.current) {
                      onBackButtonRef.current = false
                      props.onBack()
                    }
                  }}
                >
                  {backText}
                </Button>)
              : <></>}
            {noButton
              ? <></>
              : loadingButton
                ? (
                  <LoadingButton
                    buttonText={saveButtonText}
                    onClick={props.onSubmit}
                    disabled={props.disabled}
                    variant={variant}
                  />)
                : (
                  <Button
                    variant='contained'
                    type='submit'
                    color='primary'
                    onClick={() => {
                      if (saveButtonRef.current || props.allowClick) {
                        saveButtonRef.current = false
                        props.onSubmit()
                      }
                    }}
                    ref={props.buttonRef}
                    disabled={props.disabled}
                    size='medium'
                  >
                    {saveButtonText}
                  </Button>)}
          </div>)}
    </Dialog>
  )
}

export default Modal
