import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import { useContext, useState } from 'react'
import { Button, Icon } from 'library/materialUI'
import { AttachmentList, AttachmentModal } from 'pages/bot/email/AttachmentStep'
import { User } from 'classes/users'
import { HelpCenterContext } from '../HelpCenterContext'
import { addCase } from 'api/integrations'

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: '20px'
  },
  headerText: {
    fontSize: '24px',
    color: '#000000BF',
    marginBottom: '10px',
    fontWeight: 'bold'
  },
  subText: {
    color: '#00000080',
    fontSize: '12px'
  },
  textField: {
    marginTop: '5px'
  }
}))

export const ReportBug = (): JSX.Element => {
  const classes = useStyles()
  const { data: user } = User.loadMe()
  const [attachments, setAttachments] = useState<any[]>([])
  const [attachmentModalOpen, setAttachmentModalOpen] = useState(false)
  const [subject, setSubject] = useState('')
  const [description, setDescription] = useState('')
  const [submitted, setSubmitted] = useState(false)
  const { navigate } = useContext(HelpCenterContext)

  const handleSubmit = () => {
    const data = {
      attributes: {
        subject: subject || 'Bug Report',
        description: description,
        status: 'in-progress',
        contactId: String(user.id),
        contactEmail: user.email,
        type: 'bug',
        isClosed: false,
        attachments: attachments,
        firstName: user.firstName,
        lastName: user.name.split(' ')[1]
      }
    }
    addCase(data)
    setSubmitted(true)
  }

  function addAttachment (file: any) {
    setAttachments(prev => [...prev, file])
  }

  function removeAttachment (index: number) {
    setAttachments(prev => prev.filter((_, i) => i !== index))
  }

  return (
    <div className={classes.container}>
      {!submitted ? (
        <div>
          <div className={classes.headerText}>
            Tell us about the problem
          </div>
          <div className={classes.subText}>
            Please provide as many details as possible. What were you doing when the issue happened? Include a screenshot when possible.
          </div>
          <div className={classes.textField}>
            <TextField
              placeholder='Subject'
              variant='outlined'
              margin='dense'
              fullWidth
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
            />
          </div>
          <div className={classes.textField}>
            <TextField
              placeholder='Description'
              variant='outlined'
              margin='dense'
              fullWidth
              multiline
              minRows={3}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
          {attachments.length > 0 && (
            <div style={{ marginTop: '10px' }}>
              <AttachmentList
                attachmentList={attachments}
                removeAttachment={removeAttachment}
                width='90%'
              />
            </div>
          )}
          <div onClick={() => setAttachmentModalOpen(true)} style={{ display: 'flex', alignItems: 'center', marginTop: '10px', marginLeft: '10px', cursor: 'pointer' }}>
            <div>
              <Icon icon='attachFile' />
            </div>
            <div style={{ fontSize: '14px', marginLeft: '15px' }}>
              Add an attachment
            </div>
          </div>
          <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop: '20px' }}>
            <Button
              variant='contained'
              color='primary'
              size='medium'
              onClick={() => handleSubmit()}
            >
              Submit
            </Button>
          </div>
          {attachmentModalOpen && (
            <AttachmentModal
              open={attachmentModalOpen}
              onHide={() => setAttachmentModalOpen(false)}
              addAttachment={addAttachment}
            />
          )}
        </div>
      ) : (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', marginTop: '30%' }}>
          <div style={{ marginBottom: '5px' }}>
            <Icon icon='checkCircle' color='green' size='lg' />
          </div>
          <div className={classes.headerText}>
            Thanks for your feedback!
          </div>
          <div className={classes.subText} style={{ textAlign: 'center' }}>
            You can view the status of your cases in the Feedback History section of the Help Center.
          </div>
          <div style={{ marginTop: '30px' }}>
            <Button
              variant='contained'
              color='primary'
              size='medium'
              onClick={() => navigate(['home', 'feedback', 'Support History'])}
            >
              View Cases
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}
