import { doGet, doPost } from './api'

export const getLookupSettings = () => {
  return doGet({ useChatURL: false, path: '/api/core/settings/lookup' })
}

export const updateLookupSettings = ({ settings }: { settings: any }) => {
  let contactLimit = 5
  if (settings.settings.contactLimit) {
    contactLimit = parseInt(settings.settings.contactLimit)
  }
  settings.settings.contactLimit = contactLimit
  return doPost({ path: '/api/core/settings/lookup', data: { attributes: settings } })
}
