import { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { BasePortComponent } from 'jsplumbtoolkit-react'
import { Icon, IconButton } from 'library/materialUI'

const useStyles = makeStyles(theme => ({
  port: {
    marginTop: 10,
    width: 210,
    position: 'relative'
  },
  portConnection: {
    position: 'absolute',
    borderRadius: 50,
    height: 16,
    width: 16,
    border: '1px solid white',
    backgroundColor: '#333399',
    top: 6,
    left: -6
  },
  inputWrapper: {
    backgroundColor: '#333399',
    height: 32,
    borderRadius: 2,
    paddingLeft: 20,
    display: 'grid',
    gridTemplateColumns: 'auto 40px',
    gridGap: 5,
    alignItems: 'center'
  },
  input: {
    backgroundColor: '#333399',
    color: '#fff',
    fontSize: '14px',
    border: 'none',
    outline: 'none',
    width: '100%',
    height: 25,
    userSelect: 'text'
  }
}))

function PortWrapper (props) {
  const classes = useStyles()
  const [hover, setHover] = useState(false)
  const base = props.base

  return (
    <div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div className={classes.port}>
        <div className={classes.portConnection}>
          {/* This used to contain the jtk-target component, now it's only here for visual purposes as jtk-target was moved to the whole port instead of just this circle */}
        </div>
        <div className={classes.inputWrapper} data-port-id={base.state.id}>
          <input
            id='outputPort'
            onChange={(e) => base.props.updateLabel(base, e.target.value)}
            value={base.state.label}
            placeholder='Click to edit'
            className={classes.input}
            onKeyPress={(e) => {
              if (e.key === 'Enter') { document.activeElement.blur() }
            }}
          />
          <div style={{ cursor: 'pointer', visibility: hover ? 'visible' : 'hidden' }}>
            <IconButton
              style={{ padding: 3 }}
              onClick={() => base.props.removePort(base.state.id)}
            >
              <Icon icon='delete' color='white' size='sm' />
            </IconButton>
          </div>
          <jtk-target
            port-id={base.state.id}
            scope='default'
          />
        </div>
      </div>
    </div>
  )
}

export class SequenceExitPort extends BasePortComponent {
  render () {
    return (
      <PortWrapper
        base={this}
      />
    )
  }
}
