import 'jsplumb'
import useNodeStyles from './NodeStyles'

const StartNode = (props) => {
  const classes = useNodeStyles(props)
  const default_port = props.base.state.ports[0]
  return (
    <div className={classes.startNodeOuter}>
      <div className={classes.startDragger}>
        Start Here
      </div>
      <div
        className={classes.startPort}
        data-port-id={default_port.id}
      >
        <jtk-source
          port-id={default_port.id}
          scope='default'
        />
      </div>
    </div>
  )
}

export default StartNode
