import { makeStyles } from '@material-ui/core/styles'
import { Avatar, Divider } from '@material-ui/core'
import { useEffect, useState } from 'react'
// import User from 'classes/users'
import { getRecentLogins } from 'api/admin_only/tenants'
import { roleMap, roleTypes } from './AdminUserListPage'
import FormattedDate from 'components/DateFormat'
import { User } from 'classes/users'

const useStyles = makeStyles(theme => ({
  tableGrid: {
    display: 'grid',
    gridTemplateColumns: '2fr 1fr 1fr 1fr',
    gridGap: 5
  },
  tableHeader: {
    marginBottom: 10,
    fontSize: 12,
    textAlign: 'center'
  },
  centered: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  nameGrid: {
    display: 'grid',
    gridTemplateColumns: '1fr 4fr',
    gridGap: 10
  },
  rowMargins: {
    marginTop: 5,
    marginBottom: 5
  }
}))

interface loginTypes {
  name: string
  timestamp: string
  role: number
  userID: number
}

// interface userProps {
//   attributes: any
// }

interface loginProps {
  tenantId: number | null
  users: User[] // eventually needs to be User[] class
}

export const AdminRecentLogins = (props: loginProps): JSX.Element => {
  const classes = useStyles()
  const tenantId = props.tenantId
  const users = props.users
  const [recentLogins, setRecentLogins] = useState<loginTypes[]>([])

  useEffect(() => {
    if (tenantId) {
      getRecentLogins(tenantId)
        .then((response: any) => {
          const loginArray: loginTypes[] = response.data.map((el: any) => {
            const attributes = el.attributes
            const loginObject: loginTypes = {
              name: attributes.name,
              timestamp: attributes.created_timestamp,
              role: attributes.role,
              userID: attributes.user_id
            }
            return loginObject
          })
          setRecentLogins(loginArray)
        })
    }
  }, [tenantId])

  return (
    <>
      <div className={`${classes.tableGrid} ${classes.tableHeader}`}>
        <div>NAME</div>
        <div>DATE</div>
        <div>TIME</div>
        <div>ROLE</div>
      </div>
      {(users.length > 0 && recentLogins.length > 0) && recentLogins.map((el: loginTypes, idx: number) => {
        // User class needs to be used eventually
        const user = users.filter((e: any) => {
          return e.id === el.userID
        })
        const date = FormattedDate({ dateToFormat: el.timestamp, format: 'yyyy/mm/dd' })
        const time = FormattedDate({ dateToFormat: el.timestamp, format: 'h:MM TT' })
        return (
          <div key={idx}>
            <div className={`${classes.tableGrid} ${classes.rowMargins}`}>
              <div className={classes.nameGrid}>
                <Avatar
                  src={user[0].avatarUrl}
                  style={{ height: 50, width: 50 }}
                />
                <div className={classes.centered} style={{ justifyContent: 'space-between' }}>{el.name}</div>
              </div>
              <div className={classes.centered}>{date}</div>
              <div className={classes.centered}>{time}</div>
              <div className={classes.centered}>{roleMap[el.role.toString() as keyof roleTypes]}</div>
            </div>
            {idx < 4 && <Divider />}
          </div>
        )
      })}
    </>
  )
}
