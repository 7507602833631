import { useState, MutableRefObject } from 'react'
import Team from 'classes/teams'
import User from 'classes/users'
import { Table, Actions, Fields } from 'cf-components/table/Table'
import CreateTeamModal from './CreateTeamModal'

const fields: Fields<Team> = [
  { name: 'name', type: 'avatar' },
  { name: 'createdTimestamp', type: 'dateTime', label: 'Date Created' },
  { name: 'availabilityType', type: 'text', label: 'Availability' },
  { name: 'teamMemberIDs', type: 'avatarGroup', label: 'Team Members', objectType: User, sort: false }
]

const actions: Actions<Team> = [
  { type: 'delete' }
]

export default function TeamListPage (props: {
  actionRef: MutableRefObject<any>
}): JSX.Element {
  const [createModalOpen, setCreateModalOpen] = useState(false)
  const { data: teams, isLoading: isTeamsLoading } = Team.loadAll()

  props.actionRef.current = () => setCreateModalOpen(true)

  return (
    <>
      <div>
        <Table
          rows={teams.list}
          isLoading={isTeamsLoading}
          fields={fields}
          actions={actions}
          columnStyles='3fr 1fr 1fr 2fr'
        />
      </div>
      <CreateTeamModal
        open={createModalOpen}
        onHide={() => setCreateModalOpen(false)}
      />
    </>
  )
}
