
const descriptionText = 'The Alert Agent Node can be used to alert an agent, a group of agents, or a team about a conversation.'

// routing information url needs to be updated
const Description = (): JSX.Element => {
  return (
    <div>
      <div>
        {descriptionText}
      </div>
      <ul>
        <li><a href='#/user_management/teams' target='_blank'>Manage Teams</a></li>
        <li><a href='https://help.getsignals.ai/article/hy96c0do9e-bot-builder-and-play-runner-routing' target='_blank' rel='noreferrer'>Routing Information</a></li>
      </ul>
    </div>
  )
}

const bestPracticeText = "Use this node when you want your agents to know about a conversation taking place, but you don't want to route them in for a live chat with the visitor."

const BestPractices = () => {
  return (
    <div>
      <div>
        {bestPracticeText}
      </div>
      <ul>
        <li>Be proactive in determining who gets alerted</li>
        <li>Alert agents whom you want to actively view the conversation</li>
        <li>Route to "All Agents" when you want an entire group or team to see the conversation</li>
      </ul>
    </div>
  )
}

export const AlertAgentDocs = {
  helpCenterLink: 'https://help.getsignals.ai/article/rrdja4adcj-alert-agent-node',
  bestPractices: BestPractices,
  description: Description,
  videoLink: 'https://youtu.be/hm0mrhaHTmw'
}
