import { makeStyles } from '@material-ui/core/styles'
import InputBase from '@material-ui/core/InputBase'
import { Divider, Paper } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  paper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    border: '1px solid #ccc',
    borderRadius: 4,
    boxShadow: 'none'
  },
  verticalDivider: {
    height: 28
  },
  iconButton: {
    padding: 6
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    color: 'rgba(0,0,0,0.87)',
    fontSize: '13px',
    paddingTop: 3,
    paddingLeft: 5
  }
}))

interface ButtonTextFieldProps {
  label: string
  setLabel: (someString: string) => void
  addButton: (label: string) => void
  placeholder?: string
}

function ButtonTextField ({ label, setLabel, addButton, placeholder = 'Type button text and hit enter...' }: ButtonTextFieldProps) {
  const classes = useStyles()

  function handleAddButton () {
    if (label === '') {
      return
    }
    addButton(label)
    setLabel('')
  }

  function remapEnterKey (event: any) {
    if (event.key === 'Enter') {
      handleAddButton()
    }
  }

  return (
    <Paper className={classes.paper}>
      <InputBase
        className={classes.input}
        placeholder={placeholder}
        margin='dense'
        value={label}
        onChange={e => setLabel(e.target.value)}
        onKeyPress={remapEnterKey}
      />
      <Divider className={classes.verticalDivider} orientation='vertical' />
    </Paper>
  )
}

export default ButtonTextField
