import { useContext, useEffect, useReducer, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Persona from 'classes/personas'
import { Modal, ModalSection } from 'library/Modal'
import { Checkbox, Icon, MenuItem, Select, Typography } from 'library/materialUI'
import CSVDropZone from 'library/segments/CSVDropZone'
import Loader from 'library/loading/Loader'
import { CRMContext } from 'crm-context'
import { getCRMAccountTypes } from 'api/icp'
import { ListItemText } from '@material-ui/core'
import { generatePersonas } from 'api/buying_committee'
import BuyingCommitteeModal from './BuyingCommitteeModal'
import StaticChip from 'library/StaticChip'
import { truncateString } from 'library/helpers'
import _ from 'lodash'

const useStyles = makeStyles(theme => ({
  selectedDiv: {
    background: '#FFFFFF',
    color: theme.palette.primary.main,
    border: '1px #343434 solid',
    borderRadius: 5,
    padding: 20,
    cursor: 'pointer',
    width: '50%'
  },
  notSelectedDiv: {
    background: '#FFFFFF',
    color: theme.palette.primary.main,
    border: '1px #D3D3D3 solid',
    borderRadius: 5,
    padding: 20,
    cursor: 'pointer',
    width: '50%'
  },
  personaBlock: {
    width: '100%',
    marginBottom: 20,
    border: '1px solid #D3D3D3',
    padding: 20,
    borderRadius: 5,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  personasContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap'
  },
  errorBlock: {
    background: '#FDEBE9',
    padding: 10,
    borderRadius: 5,
    display: 'flex'
  },
  chipContainer: {
    display: 'flex',
    marginBottom: 20,
    alignItems: 'center'
  }
}))

const personaReducer = (state: { departmentPersonas: Persona[], functionPersonas: Persona[], seniorityPersonas: Persona[] }, changes: Partial<{ departmentPersonas: Persona[], functionPersonas: Persona[], seniorityPersonas: Persona[] }>): any => {
  return { ...state, ...changes }
}

const startState = {
  departmentPersonas: [],
  functionPersonas: []
}

export type PreviewStates = 'start' | 'refresh' | 'empty' | 'not_found' | 'out_of_requests' | 'run' | 'loading'

type GeneratedPersonas = {
  departmentPersonas: any[]
  functionPersonas: any[]
  seniorityPersonas: any[]
}

export default function AIBuyingCommitteeModal ({ onHide: propsOnHide, ...props }: {
  open: boolean
  onHide: () => void
  canAddAmount: number
}): JSX.Element {
  const classes = useStyles()
  const [personasState, dispatch] = useReducer(personaReducer, startState)
  const [modalPage, setModalPage] = useState<number>(0)
  const [analysisType, setAnalysisType] = useState<string>('')
  const [includeClosedLost, setIncludeClosedLost] = useState<boolean>(false)
  const [isloading, setIsLoading] = useState<boolean>(true)
  const [accountTypes, setAccountTypes] = useState<string[]>([])
  const [selectedAccountTypes, setSelectedAccountTypes] = useState<string[]>([])
  const [csvHeaders, setCSVHeaders] = useState<string[]>([])
  const [jobTitleField, setJobTitleField] = useState<string>('')
  const [selectedPersona, setSelectedPersona] = useState<Persona | null>(null)
  const [openEditModal, setOpenEditModal] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  const [generatedPersonas, setGeneratedPersonas] = useState<GeneratedPersonas>({ departmentPersonas: [], functionPersonas: [], seniorityPersonas: [] })
  const [personasToSave, setPersonasToSave] = useState<Persona[]>([])
  const [organizeBy, setOrganizeBy] = useState<string>('department')
  const { crmIntegrationName } = useContext(CRMContext)
  // const crmIntegrationName = ''
  const crmConnected = Boolean(crmIntegrationName)

  const [file, setFile] = useState<File | null>(null)
  const [csvString, setCSVString] = useState<string>('')

  useEffect(() => {
    getCRMAccountTypes('accounts', 'type').then(res => {
      const list = res.data.map((type: any) => type.id)
      setAccountTypes(list)
    })
  }, [])

  useEffect(() => {
    console.log(`selectedAccountTypes: ${selectedAccountTypes}`)
    console.log(`includeClosedLost: ${includeClosedLost}`)
  }, [selectedAccountTypes, includeClosedLost])

  useEffect(() => {
    console.log(`titleColumn: ${jobTitleField}`)
  }, [jobTitleField])

  useEffect(() => {
    if (file) {
      const reader = new FileReader()
      reader.onload = (e) => {
        const csv = e?.target?.result as string
        const headers = csv.split('\n')[0].split(',')
        setCSVHeaders(headers)
        const titleColumn = csv.split('\n').map(row => row.split(',')[headers.indexOf(jobTitleField)]).join(',')
        setCSVString(titleColumn)
      }
      reader.readAsText(file)
    }
  }, [file, jobTitleField])

  useEffect(() => {
    if (selectedPersona && selectedPersona.name) {
      setOpenEditModal(true)
    }
  }, [selectedPersona])

  useEffect(() => {
    if (generatedPersonas.departmentPersonas.length || generatedPersonas.functionPersonas.length || generatedPersonas.seniorityPersonas.length) {
      if (generatedPersonas.departmentPersonas.length && !generatedPersonas.departmentPersonas[0].titles.length) {
        setError(generatedPersonas.departmentPersonas[0].name)
      } else if (generatedPersonas.functionPersonas.length && !generatedPersonas.functionPersonas[0].titles.length) {
        setError(generatedPersonas.functionPersonas[0].name)
      } else if (generatedPersonas.seniorityPersonas.length && !generatedPersonas.seniorityPersonas[0].titles.length) {
        setError(generatedPersonas.seniorityPersonas[0].name)
      } else {
        setError('')
        const departmentPersonas = generatedPersonas.departmentPersonas.map(persona => {
          const convertedPersonas = new Persona({ attributes: {} })
          convertedPersonas.name = persona.name
          convertedPersonas.titles = persona.titles
          convertedPersonas.departments = persona.departments
          return convertedPersonas
        })
        const functionPersonas = generatedPersonas.functionPersonas.map(persona => {
          const convertedPersonas = new Persona({ attributes: {} })
          convertedPersonas.name = persona.name
          convertedPersonas.titles = persona.titles
          return convertedPersonas
        })
        const seniorityPersonas = generatedPersonas.seniorityPersonas.map(persona => {
          const convertedPersonas = new Persona({ attributes: {} })
          convertedPersonas.name = persona.name
          convertedPersonas.titles = persona.titles
          convertedPersonas.seniorities = persona.seniorities
          return convertedPersonas
        })
        dispatch({ departmentPersonas, functionPersonas, seniorityPersonas })
      }
    } else {
      setError('We were unable to find any titles in your contacts for the selected types. Consider expanding your selection and trying again.')
    }
    setIsLoading(false)
  }, [generatedPersonas])

  useEffect(() => {
    switch (organizeBy) {
      case 'department':
        setPersonasToSave(personasState.departmentPersonas)
        break
      case 'function':
        setPersonasToSave(personasState.functionPersonas)
        break
      case 'seniority':
        setPersonasToSave(personasState.seniorityPersonas)
        break
      default:
        setPersonasToSave([...personasState.departmentPersonas, ...personasState.functionPersonas, ...personasState.seniorityPersonas])
    }
  }, [organizeBy, personasState])

  const deletePersona = (persona: Persona, pList: 'departmentPersonas' | 'functionPersonas' | 'seniorityPersonas') => {
    const newList = personasState[pList].filter((p: Persona) => p.name !== persona.name)
    if (pList === 'departmentPersonas') {
      dispatch({ departmentPersonas: newList, functionPersonas: personasState.functionPersonas, seniorityPersonas: personasState.seniorityPersonas })
    } else if (pList === 'functionPersonas') {
      dispatch({ departmentPersonas: personasState.departmentPersonas, functionPersonas: newList, seniorityPersonas: personasState.seniorityPersonas })
    } else {
      dispatch({ departmentPersonas: personasState.departmentPersonas, functionPersonas: personasState.functionPersonas, seniorityPersonas: newList })
    }
  }

  const cancelButtonFunction = () => {
    if (modalPage === 0) {
      resetModal()
    } else {
      if (modalPage === 4 && analysisType === 'integration') {
        setModalPage(1)
      } else if (modalPage === 2) {
        setModalPage(0)
      } else {
        setModalPage(modalPage - 1)
      }
    }
  }

  const nextButtonFunction = () => {
    const generateAndSetPersonas = (attributes: { integrationAccountTypes?: string[]; includeClosedlost?: boolean; csv?: string; jobTitleColumn?: string }) => {
      setModalPage(4)
      setIsLoading(true)
      generatePersonas({ attributes }).then(res => {
        console.log(res)
        setGeneratedPersonas(res.data?.attributes)
      })
    }

    if (modalPage === 0) {
      setModalPage(analysisType === 'integration' ? 1 : 2)
    } else if (modalPage === 1) {
      generateAndSetPersonas({
        integrationAccountTypes: selectedAccountTypes,
        includeClosedlost: includeClosedLost
      })
    } else if (modalPage === 3) {
      generateAndSetPersonas({
        csv: csvString,
        jobTitleColumn: jobTitleField
      })
    } else if (modalPage === 4) {
      personasToSave.forEach((persona: Persona) => Persona.save(persona))
      propsOnHide()
      setModalPage(0)
    } else {
      setModalPage(modalPage + 1)
    }
  }

  const editState = (state: Persona) => {
    dispatch({ departmentPersonas: personasState.departmentPersonas.map((p: Persona) => p.name === state.name ? state : p), functionPersonas: personasState.functionPersonas.map((p: Persona) => p.name === state.name ? state : p), seniorityPersonas: personasState.seniorityPersonas.map((p: Persona) => p.name === state.name ? state : p) })
  }

  const resetModal = () => {
    propsOnHide()
    setModalPage(0)
    setIsLoading(true)
    setSelectedAccountTypes([])
    setIncludeClosedLost(false)
    setCSVHeaders([])
    setJobTitleField('')
    setCSVString('')
    setGeneratedPersonas({ departmentPersonas: [], functionPersonas: [], seniorityPersonas: [] })
    dispatch(startState)
  }

  const personaBlock = (persona: Persona, personaList: 'departmentPersonas' | 'functionPersonas' | 'seniorityPersonas') => {
    return (
      <div className={classes.personaBlock}>
        <div style={{ display: 'flex', width: '90%', flexDirection: 'column' }}>
          <Typography variant='h2'>{persona.name}</Typography>
          <Typography variant='body2'>Titles: {truncateString(persona.titles.join(', '), 75, true, true)}</Typography>
        </div>
        <div style={{ display: 'flex' }}>
          <div style={{ alignItems: 'center', marginRight: 10, cursor: 'pointer' }}>
            <Icon icon='edit' onClick={() => setSelectedPersona(persona)} color='#808080' />
          </div>
          <div style={{ alignItems: 'center', cursor: 'pointer' }}>
            <Icon icon='delete' onClick={() => deletePersona(persona, personaList)} color='#808080' />
          </div>
        </div>
      </div>
    )
  }

  const crmOrCsv = () => {
    return (
      <div style={{ display: 'flex' }}>
        <div
          className={analysisType === 'integration' ? classes.selectedDiv : classes.notSelectedDiv}
          style={{ marginRight: 5 }}
          onClick={() => {
            if (crmIntegrationName) setAnalysisType('integration')
          }}
        >
          {crmConnected ? (
            <Icon icon={crmIntegrationName === 'salesforce' ? 'salesforceCloudLarge' : 'hubspotLarge'} />
          ) : (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Icon icon='salesforceCloudLargeGray' />
                <div style={{ marginRight: 10 }} />
                <Icon icon='hubspotLargeGray' />
              </div>
              <div style={{ borderRadius: 5, background: '#E2E2E2', padding: 5, textAlign: 'center' }}>
                Not connected
              </div>
            </div>
          )}
          <Typography variant='h2' style={{ marginTop: 10 }}> Analyze {_.startCase(crmIntegrationName) || 'CRM'} Contacts </Typography>
          <Typography> Generate personas based on the job titles of contacts already in {_.startCase(crmIntegrationName) || 'your CRM'}. </Typography>
        </div>
        <div
          className={analysisType === 'csv' ? classes.selectedDiv : classes.notSelectedDiv}
          onClick={() => setAnalysisType('csv')}
          style={{ marginLeft: 5 }}
        >
          <Icon icon='file' size='lg' color='primary' />
          <Typography variant='h2' style={{ marginTop: 10 }}> Upload a CSV </Typography>
          <Typography> Generate personas based on the job titles of contacts exported from another platform. </Typography>
        </div>
      </div>
    )
  }

  const accountTypesPage = () => {
    return (
      <ModalSection
        title='Analysis filters'
        titleType='h1'
      >
        <Typography variant='body2' style={{ marginBottom: 20 }}> We'll analyze your contacts and put together buying committees that match the contacts that are currently associated with your customer accounts. </Typography>
        <Typography variant='h2' style={{ marginBottom: 5 }}> Customers </Typography>
        <Typography variant='body2'> Which account types from {_.startCase(crmIntegrationName)} do you consider to be customers? </Typography>
        <div style={{ marginBottom: 20 }}>
          <Select
            value={selectedAccountTypes}
            onChange={e => setSelectedAccountTypes((e.target.value as string[]))}
            renderValue={selected => {
              if (selected && (selected as string[]).length) return (selected as string[]).join(', ')
              return 'Select Account Types'
            }}
            multiple
            variant='outlined'
            margin='dense'
            displayEmpty
            placeholder='Select Account Types'
            fullWidth
          >
            {(accountTypes || []).map(value => {
              return (
                <MenuItem key={value} value={value}>
                  <Checkbox
                    checked={selectedAccountTypes.indexOf(value) > -1}
                    onChange={() => {
                      if (selectedAccountTypes.indexOf(value) > -1) {
                        setSelectedAccountTypes(selectedAccountTypes.filter(type => type !== value))
                      } else {
                        setSelectedAccountTypes([...selectedAccountTypes, value])
                      }
                    }}
                  />
                  <ListItemText primary={value} />
                </MenuItem>
              )
            })}
          </Select>
        </div>
        <Typography variant='h2' style={{ marginBottom: 5 }}> Closed-Lost Opportunities </Typography>
        <Typography variant='body2'> If you have a limited number of contacts related to customer accounts, including closed-lost opportunities can provide better results. </Typography>
        <Checkbox label='Include closed-lost opportunities' onChange={() => setIncludeClosedLost(!includeClosedLost)} checked={includeClosedLost} />
      </ModalSection>
    )
  }

  const csvPage = () => {
    return (
      <ModalSection
        title='Upload a CSV'
        subtitle='Upload a CSV containing titles you want to add to your personas'
      >
        <div style={{ display: 'flex', alignItems: 'center', background: '#EAEFFA', padding: 10 }}>
          <div style={{ marginRight: 10 }}>
            <Icon icon='info' color='#547BD0' size='sm' />
          </div>
          Your upload must include a column containing job titles
        </div>
        <CSVDropZone
          CSVFile={file as File}
          handleDrop={(file: any) => {
            setFile(file)
            setJobTitleField('')
          }}
        />
      </ModalSection>
    )
  }

  const jobTitleFieldPage = () => {
    return (
      <ModalSection
        title='Confirm Job Title Field'
        subtitle='Select the header on your CSV that corresponds with the contacts’ job titles.'
      >
        <Select
          value={jobTitleField}
          onChange={e => setJobTitleField(e.target.value as string)}
          variant='outlined'
          margin='dense'
          displayEmpty
          placeholder='Select Job Title Field'
          fullWidth
        >
          {csvHeaders.map(header => (
            <MenuItem key={header} value={header}>
              {header}
            </MenuItem>
          ))}
        </Select>
      </ModalSection>
    )
  }

  const generatedPersonasPage = () => {
    return (
      <>
        {isloading ? (
          <ModalSection>
            <div style={{ padding: 100 }}>
              <Loader type='spinner' title='Analyzing your contacts' size='lg' />
            </div>
          </ModalSection>
        ) : (
          <ModalSection
            title='Suggested Personas'
            titleType='h1'
          >
            <Typography variant='body2' style={{ marginBottom: 20 }}> We've grouped the titles found in {analysisType === 'integration' ? _.startCase(crmIntegrationName) : 'your CSV'} into the following personas:</Typography>
            {error ? (
              <div className={classes.errorBlock}>
                <div style={{ marginRight: 10 }}>
                  <Icon icon='errorOutlined' color='#EA422A' />
                </div>
                <Typography variant='body2'>{error}</Typography>
              </div>
            ) : (
              <>
                <div className={classes.chipContainer}>
                  <Typography variant='body1' style={{ marginRight: 10 }}>Organize by:</Typography>
                  <div onClick={() => setOrganizeBy('department')} style={{ cursor: 'pointer' }}>
                    <StaticChip value='Department' chipColor={organizeBy === 'department' ? '#343434' : '#E2E2E2'} fontColor={organizeBy === 'department' ? '#FFFFFF' : '#343434'} variant='solid' />
                  </div>
                  <div onClick={() => setOrganizeBy('function')} style={{ cursor: 'pointer' }}>
                    <StaticChip value='Buying Committee function' chipColor={organizeBy === 'function' ? '#343434' : '#E2E2E2'} fontColor={organizeBy === 'function' ? '#FFFFFF' : '#343434'} variant='solid' />
                  </div>
                  <div onClick={() => setOrganizeBy('seniority')} style={{ cursor: 'pointer' }}>
                    <StaticChip value='Seniority' chipColor={organizeBy === 'seniority' ? '#343434' : '#E2E2E2'} fontColor={organizeBy === 'seniority' ? '#FFFFFF' : '#343434'} variant='solid' />
                  </div>
                  <div onClick={() => setOrganizeBy('all')} style={{ cursor: 'pointer' }}>
                    <StaticChip value='All' chipColor={organizeBy === 'all' ? '#343434' : '#E2E2E2'} fontColor={organizeBy === 'all' ? '#FFFFFF' : '#343434'} variant='solid' />
                  </div>
                </div>
                <div className={classes.personasContainer}>
                  {(organizeBy === 'department' || organizeBy === 'all') && (
                    personasState.departmentPersonas.map((persona: Persona) => (
                      personaBlock(persona, 'departmentPersonas')
                    ))
                  )}
                  {(organizeBy === 'function' || organizeBy === 'all') && (
                    personasState.functionPersonas.map((persona: Persona) => (
                      personaBlock(persona, 'functionPersonas')
                    ))
                  )}
                  {(organizeBy === 'seniority' || organizeBy === 'all') && (
                    personasState.seniorityPersonas.map((persona: Persona) => (
                      personaBlock(persona, 'seniorityPersonas')
                    ))
                  )}
                </div>
              </>
            )}
          </ModalSection>
        )}
      </>
    )
  }

  const pages = [
    crmOrCsv,
    accountTypesPage,
    csvPage,
    jobTitleFieldPage,
    generatedPersonasPage
  ]

  const renderPage = () => {
    return pages[modalPage]()
  }

  return (
    <>
      <Modal
        title='AI Persona Creation'
        open={props.open}
        onHide={() => {
          resetModal()
        }}
        size='md'
        saveDisabled={
          (analysisType === '') ||
          (jobTitleField === '' && modalPage === 3) ||
          (selectedAccountTypes.length === 0 && modalPage === 1) ||
          (file === null && modalPage === 2) ||
          Boolean((error || isloading) && modalPage === 4) ||
          (personasToSave.length === 0 && modalPage === 4) ||
          (personasToSave.length > props.canAddAmount && modalPage === 4)
        }
        saveDisabledTooltip={personasToSave.length > props.canAddAmount && modalPage === 4 ? 'You have too many personas to save' : ''}
        saveIcon={modalPage === 4 ? 'add' : 'arrowRightWithLine'}
        saveBtnText={modalPage === 4 ? 'Create Personas' : 'Next'}
        saveIconPosition={modalPage === 4 ? 'start' : 'end'}
        cancelIcon={modalPage === 0 ? undefined : 'arrowBack'}
        cancelBtnText={modalPage === 0 ? 'Cancel' : 'Back'}
        closeBtnFunction={() => cancelButtonFunction()}
        handleSave={() => nextButtonFunction()}
      >
        {renderPage()}
      </Modal>

      {selectedPersona && (
        <BuyingCommitteeModal
          open={openEditModal}
          onHide={() => {
            setSelectedPersona(null)
            setOpenEditModal(false)
          }}
          persona={selectedPersona}
          title='Edit Persona'
          dontSave
          saveState={editState}
        />
      )}
    </>
  )
}
