import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Card, CardContent, Typography, Select, MenuItem } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  selectorCard: {
    width: 450,
    borderRadius: 10,
    margin: 50
  },
  container: {
    display: 'flex',
    justifyContent: 'center'
  },
  cardContentArea: {
    height: '100%',
    backgroundColor: 'rgba(193, 166, 237, 0.1)',
    borderRadius: 10,
    marginBottom: 20,
    padding: 30,
    paddingTop: 20
  },
  header: {
    backgroundColor: theme.palette.primary.main
  },
  subtitle1: {
    fontSize: 20,
    fontWeight: 600,
    color: 'white',
    padding: 20
  },
  subtitle2: {
    fontSize: 15,
    fontWeight: 600,
    marginBottom: 10
  },
  bodyText: {
    color: '#808080',
    marginBottom: 10,
    fontSize: 15
  },
  select: {
    borderRadius: 40
  },
  menuHeader: {
    borderBottom: '1px solid #BBB',
    width: '100%',
    fontWeight: 600,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: 'rgba(0,0,0,0.87)'
  },
  smallText: {
    color: '#808080',
    marginBottom: 10,
    fontSize: 13,
    marginTop: '-10px'
  }
}))

const SelectAppearance = props => {
  const classes = useStyles()
  const state = props.state
  const promptSizes = props.promptSizes

  let prompt = 'mini'

  if (state.attributes.new_flow) {
    prompt = state.attributes.new_flow.prompt_style
  } else if (props.flow) {
    prompt = props.flow.prompt_style
  }

  return (
    <>
      <div className={classes.container}>
        <Card className={classes.selectorCard} raised>
          <div className={classes.header}>
            <Typography variant='subtitle1' className={classes.subtitle1}>
              Select Appearance
            </Typography>
          </div>
          <CardContent
            className={classes.cardContentArea}
          >
            <Typography variant='body2' className={classes.bodyText}>
              Select a prompt size for your bot.
            </Typography>
            <Typography variant='subtitle2' className={classes.subtitle2}>
              Prompt Sizes
            </Typography>
            <Typography variant='body2' className={classes.smallText}>
              Your base bot uses: ( {props.oldFlow ? promptSizes.filter(ps => ps.value === props.oldFlow.prompt_style)[0].name : ''} )
            </Typography>
            <div>
              <Select
                name='prompt_size'
                variant='outlined'
                margin='dense'
                fullWidth
                className={classes.select}
                value={prompt}
                onChange={e => props.handleChange({ attr: 'prompt_style', value: e.target.value })}
              >
                <MenuItem disabled style={{ opacity: 1 }}>
                  <div className={classes.menuHeader}>Prompt Sizes</div>
                </MenuItem>
                {promptSizes.map((prompt, index) => (
                  <MenuItem key={index} value={prompt.value}>
                    {prompt.name}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </CardContent>
        </Card>
      </div>
    </>
  )
}

export default SelectAppearance
