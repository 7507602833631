import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import { parse } from 'query-string'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 345
  },
  media: {
    height: 140
  },
  icon: {
    paddingLeft: '20px',
    paddingTop: '20px'
  }
}))

function OAuthSetup (props) {
  const classes = useStyles()
  const query_string = parse(props.location.search)
  const [clientInfo, setClientInfo] = useState(null)
  const [authorizeButtonEnabled, setAuthorizeButtonEnabled] = useState(false)

  function authorize () {
    fetch('/api/oauth/setup', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        data: {
          attributes: {
            client_id: query_string.client_id,
            response_type: query_string.response_type,
            redirect_uri: query_string.redirect_uri,
            state: query_string.state
          }
        }
      })
    })
      .then(response => response.json())
      .then(response => {
        const redirect_url = response.data.attributes.redirect_url
        window.location.assign(redirect_url)
      })
  }

  useEffect(() => {
    function client_info () {
      fetch(`/api/oauth/client_info?client_id=${query_string.client_id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(response => response.json())
        .then(response => {
          setClientInfo(response)
        })
    }
    client_info()
    setAuthorizeButtonEnabled(false)
    setTimeout(() => {
      setAuthorizeButtonEnabled(true)
    }, 4000)
  }, [query_string.client_id])

  if (!clientInfo) {
    return <></>
  }

  return (
    <Card className={classes.root}>
      <CardActionArea>
        <CardContent>
          <Typography gutterBottom variant='h5' component='h2'>
            {clientInfo.data.attributes.label} Connection Setup
          </Typography>

          <Typography variant='body2' color='textSecondary' component='p'>
            Are you sure you want to authorize <b>{clientInfo.data.attributes.label}</b> to connect to your Signals instance?
            This will allow {clientInfo.data.attributes.label} access to the information contained in your chat conversations
            and contact records, and will also grant {clientInfo.data.attributes.label} access to the Signals API.
          </Typography>

        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button
          onClick={authorize}
          color='primary'
          variant='contained'
          disabled={!authorizeButtonEnabled}
        >Authorize {clientInfo.data.attributes.label}
        </Button>
      </CardActions>
    </Card>
  )
}

export default OAuthSetup
