import React, { useEffect, useState, useReducer } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import SettingsCard from 'cf-components/SettingsCard'
import {
  Chip, Switch,
  Breadcrumbs,
  Typography,
  Button
} from '@material-ui/core'
import CustomizedSnackbar from 'components/CustomizedSnackbar'
import TextField from '@material-ui/core/TextField'
import { Link } from 'react-router-dom'
import {
  INTEGRATIONS,
  getIntegration,
  updateIntegration,
  addIntegrationSetting,
  getCredentialsStatus,
  setCredentials,
  getIntegrationFeature
} from 'api/integrations'
import {
  getCRMContactIntegrationFields,
  setCRMContactIntegrationMapping,
  getCRMContactIntegrationMapping
} from 'api/crm_contacts'
import BrevoName from 'img/brevo_name.png'
import IntegrationMapping from './IntegrationMapping'

const useStyles = makeStyles((theme) => ({
  headerImage: {
    height: 65,
    marginBottom: 30
  },
  menuHeader: {
    borderBottom: '1px solid #BBB',
    width: '100%',
    fontWeight: 600,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: 'rgba(0,0,0,0.87)'
  },
  disabledHeaderImage: {
    height: 65,
    marginBottom: 30,
    filter: 'grayscale(100%)'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  manualToggle: {
    marginRight: 40,
    marginTop: 20
  },
  wrapIcon: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 15
  },
  textField: {
    cursor: 'text',
    width: '100%',
    marginBottom: 25,
    marginRight: 10
  },
  container: {
    padding: 65,
    minWidth: 920
  },
  bodyText: {
    marginBottom: 25,
    fontSize: 16
  },
  subtitle1: {
    fontSize: 18,
    fontWeight: 600,
    marginBottom: 15
  },
  subtitle2: {
    fontSize: 15,
    fontWeight: 600,
    marginBottom: 10
  },
  groupContainer: {
    backgroundColor: 'rgba(193, 166, 237, 0.1)',
    padding: 30,
    borderRadius: 10,
    marginBottom: 20
  },
  apiKeyField: {
    display: 'flex'
  },
  visibilityButton: {
    height: 56,
    marginTop: -1
  },
  expandingCard: {
    backgroundColor: 'rgba(193, 166, 237, 0.1)',
    borderRadius: 10,
    marginBottom: 20
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  },
  cardHeader: {
    widht: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: 10
  },
  headerTitle: {
    paddingLeft: 20,
    fontSize: 24,
    fontWeight: 'bold',
    paddingTop: 10
  },
  connectedIcon: {
    color: 'rgba(0, 135, 7, 0.7)'
  },
  disconnectedIcon: {
    color: 'rgba(255, 188, 3)'
  },
  crumb: {
    fontSize: '.8em'
  },
  typography: {
    fontSize: '1.05em'
  },
  breadcrumbs: {
    position: 'absolute',
    top: 5
  },
  crumbWrapper: {
    padding: 10
  },
  dottedLine: {
    borderTop: '2px dotted #975ce6',
    height: 10,
    marginTop: 20,
    width: '100%'
  },
  eventSettingRow: {
    display: 'flex',
    alignContent: 'center'
  }
}))

const API_KEY_PLACEHOLDER = 'api key placeholder text, not a real key'
const initialState = {
  integrationSettings: {
    type: 'integrations',
    attributes: {
      integration_name: INTEGRATIONS.Sendinblue,
      enabled: false,
      config: {}
    }
  },
  contactMapping: [],
  createContactSwitchMessage: '',
  contactFields: [],
  createContact: false,
  knowledgebase: false,
  apiKey: '',
  dirtySettings: false,
  dirtyMapping: false,
  dirtyAPIKey: false
}
const reducer = (state, action) => {
  switch (action.type) {
    case 'initialize': {
      let createContactEnabled = state.createContact
      if (!action.data.attributes.enabled) {
        createContactEnabled = false
      }
      return { ...state, integrationSettings: action.data, loading: false, createContact: createContactEnabled }
    }
    case 'saved-settings':
      return { ...state, dirtyAPIKey: false, dirtySettings: false, dirtyMapping: false }
    case 'init-apiKey':
      return { ...state, apiKey: action.data }
    case 'apiKey':
      return { ...state, apiKey: action.data, dirtyAPIKey: true }
    case 'init-create_contact': {
      const enabled = Boolean(action.data.integration && action.data.integration.name === 'sendinblue')
      let switchMessage = ''
      if (!enabled && action.data.integration) {
        switchMessage = `(enabling Brevo will disable ${action.data.integration.label})`
      }
      return { ...state, createContact: enabled, createContactSwitchMessage: switchMessage }
    }
    case 'disable-create_contact':
      return { ...state, createContact: false, dirtySettings: true }
    case 'enable-create_contact':
      return { ...state, createContact: true, dirtySettings: true }
    case 'fields-loaded':
      return { ...state, contactFields: action.data, loading: false }
    case 'mapping-loaded':
      return { ...state, contactMapping: action.data }
    case 'saving':
      return { ...state, loading: true }
    case 'add-done':
      return { ...state, adding: false }
    case 'adding-field':
      return { ...state, adding: true }
    case 'mapping-change':
      return { ...state, contactMapping: action.data, dirtyMapping: true }
    default:
      return state
  }
}

const SendinblueIntegrationPage = props => {
  const classes = useStyles()
  const [state, dispatch] = useReducer(reducer, initialState)
  const [snackState, setSnackState] = useState({
    open: false,
    variant: 'success',
    message: 'Your changes have been saved'
  })

  const toggleFeature = (feature, checked) => {
    if (!state.integrationSettings.attributes.enabled) {
      setSnackState({
        open: true,
        variant: 'error',
        message: 'You must enable the integration in order to enable the feature'
      })
      return
    }
    if (checked) {
      dispatch({ type: `enable-${feature}` })
    } else {
      dispatch({ type: `disable-${feature}` })
    }
  }
  const toggleIntegration = async (checked) => {
    const integrationSettings = { ...state.integrationSettings }
    integrationSettings.attributes.enabled = checked
    save(integrationSettings)
  }

  const saveSettings = () => {
    const promises = []
    if (state.dirtyAPIKey) {
      promises.push(setCredentials(INTEGRATIONS.Sendinblue, [{
        attributes: {
          name: 'access_token',
          value: state.apiKey,
          secret: true
        }
      }]))
    }
    if (state.dirtyMapping) {
      promises.push(setCRMContactIntegrationMapping(INTEGRATIONS.Sendinblue, state.contactMapping))
    }
    // if (state.dirtySettings) {
    //   let integration = null
    //   if (state.createContact) {
    //     integration = {
    //       name: INTEGRATIONS.Sendinblue
    //     }
    //   }
    //   // promises.push(updateIntegrationFeatures([{
    //   //   id: 'crm.create_contact',
    //   //   attributes: {
    //   //     integration: integration
    //   //   }
    //   // }]))
    // }
    if (promises.length > 0) {
      Promise.all(promises).then(() => {
        setSnackState({
          open: true,
          variant: 'success',
          message: 'Your settings have been saved'
        })
        dispatch({ type: 'saved-settings' })
      }, (err) => {
        setSnackState({
          open: true,
          message: 'There was an error while trying to save your settings.',
          err: err.message
        })
      })
    }
  }

  const save = (integrationSettings) => {
    updateIntegration({ integration: integrationSettings })
      .then((response) => {
        dispatch({ type: 'initialize', data: response.data })
      }).then(() => {
        setSnackState({
          open: true,
          variant: 'success',
          message: 'Your changes have been saved'
        })
      })
  }

  useEffect(() => {
    if (!state.integrationSettings.attributes.enabled) {
      return
    }
    getIntegrationFeature('crm.create_contact').then((resp) => {
      dispatch({ type: 'init-create_contact', data: { integration: resp.data.attributes.integration } })
    }, (err) => {
      setSnackState({
        open: true,
        message: 'Could not load crm create contact feature settings.',
        err: err.message
      })
    })
  }, [state.integrationSettings.attributes.enabled])

  useEffect(() => {
    if (state.dirtySettings) {
      return
    }
    getCRMContactIntegrationMapping()
      .then((response) => {
        dispatch({ type: 'mapping-loaded', data: response.data.attributes.mapping })
      }, (err) => {
        setSnackState({
          open: true,
          message: 'Could not load contact field mapping.',
          err: err.message
        })
      })
  }, [state.dirtySettings])

  useEffect(() => {
    if (state.dirtySettings) {
      return
    }
    getCredentialsStatus(INTEGRATIONS.Sendinblue)
      .then((response) => {
        if (!response.data.attributes.has_credentials) {
          dispatch({ type: 'init-apiKey', data: '' })
        } else {
          dispatch({ type: 'init-apiKey', data: API_KEY_PLACEHOLDER })
          getCRMContactIntegrationFields()
            .then((response) => {
              if (response.data) {
                const fields = response.data.map(f => {
                  return {
                    api_name: f.attributes.api_name,
                    label: f.attributes.label,
                    data_type: f.attributes.data_type,
                    crm_type: f.attributes.crm_type
                  }
                })
                dispatch({ type: 'fields-loaded', data: fields })
              }
            }, (err) => {
              setSnackState({
                open: true,
                message: 'Could not load contact fields.',
                err: err.message
              })
            })
        }
      }, (err) => {
        setSnackState({
          open: true,
          message: 'Could not load credential status.',
          err: err.message
        })
      })
  }, [state.dirtySettings])

  useEffect(() => {
    getIntegration(INTEGRATIONS.Sendinblue)
      .then(response => {
        if (response.data) {
          dispatch({ type: 'initialize', data: response.data })
        } else {
          addIntegrationSetting({ integration: initialState.integrationSettings }).then(
            (response) => {
              dispatch({ type: 'initialize', data: response.data })
            })
        }
      })
  }, [])

  if (state === null) {
    return (
      <>
        Loading
      </>
    )
  }

  return (
    <>
      <Breadcrumbs aria-label='breadcrumb' classes={{ li: classes.crumb, ol: classes.crumbWrapper }}>
        <Link style={{ color: '#7A7A7A' }} to='/settings/integrations'>
          Integrations Page
        </Link>
        <Typography color='textPrimary' classes={{ root: classes.typography }}>Brevo Integration Page</Typography>
      </Breadcrumbs>
      <div className={classes.container}>
        <div className={classes.header}>
          <img
            alt='Brevo Logo'
            src={BrevoName}
            className={
              state.integrationSettings.attributes.enabled
                ? classes.headerImage
                : classes.disabledHeaderImage
            }
          />
          {state ? (
            <>
              <div className={classes.manualToggle}>
                <Chip
                  label={
                    state.integrationSettings.attributes.enabled
                      ? 'Integration: On'
                      : 'Integration: Off'
                  }
                  color={
                    state.integrationSettings.attributes.enabled ? 'primary' : 'default'
                  }
                  className={classes.chip}
                />
                <Switch
                  checked={state.integrationSettings.attributes.enabled}
                  onChange={(e) => toggleIntegration(e.target.checked)}
                  name='master_toggle'
                  className={classes.switch}
                />
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
        <SettingsCard
          title='API Settings'
          subtitle='To enable your Brevo integration, you need to provide your API key'
        >
          <div style={{ marginBottom: 20 }} className={classes.apiKeyField}>
            <TextField
              className={classes.textField}
              required
              label='Brevo API Key'
              color='primary'
              variant='outlined'
              type='password'
              value={state.apiKey}
              onFocus={(e) => {
                if (e.target.value === API_KEY_PLACEHOLDER) {
                  dispatch({ type: 'apiKey', data: '' })
                }
              }}
              onChange={(e) => {
                dispatch({ type: 'apiKey', data: e.target.value })
              }}
            />
          </div>
        </SettingsCard>
        <SettingsCard
          title='Contact Creation'
          subtitle='Enabling this feature allows you to create new contacts and optionally add them to a list using a Signals bot. If a contact with the same email address already exists in Brevo, we will attempt to add the contact to the given list if specified'
          toggleFeature={(f) => toggleFeature('create_contact', f)}
          switchMessage={state.createContactSwitchMessage}
          featureSwitch
          enabled={state.createContact}
        >
          <div className={classes.groupContainer}>
            <IntegrationMapping
              disableOverwrite
              currentMapping={state.contactMapping}
              externalMappingFields={state.contactFields}
              forObject='people'
              integrationName='Brevo'
              handleEdit={(data) => dispatch({ type: 'mapping-change', data: data })}
            />
            <br />
          </div>
        </SettingsCard>
        <Button
          className={classes.actionButton}
          color='primary'
          variant='contained'
          onClick={saveSettings}
          disabled={!state.dirtySettings && !state.dirtyAPIKey && !state.dirtyMapping}
        >
          Save
        </Button>
      </div>
      <CustomizedSnackbar state={snackState} handler={setSnackState} />
    </>
  )
}

export default SendinblueIntegrationPage
