import UnsavedChangesHistoryModal from 'cf-components/UnsavedChangesHistoryModal'
import { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { IntegrationPageContext } from './IntegrationPageProvider'

export default function UnsavedChanges () {
  const { handleSave, saveDisabled } = useContext(IntegrationPageContext)
  const history = useHistory()
  return (
    <UnsavedChangesHistoryModal
      save={handleSave}
      disabled={saveDisabled}
      validPaths={['#######']}
      history={history}
    />
  )
}
