import React, { useEffect, useState } from 'react'
import { SurfaceDropComponent } from 'jsplumbtoolkit-react-drop'
import useStyles from '../nodes/NodeStyles'
import QuestionIcon from '@material-ui/icons/LiveHelp'
import ChatIcon from '@material-ui/icons/Chat'
import EmailIcon from '@material-ui/icons/Email'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import BranchIcon from '@material-ui/icons/CallSplit'
import TagIcon from '@material-ui/icons/LocalOffer'
import StarIcon from '@material-ui/icons/Star'
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic'
import CalendarIcon from '@material-ui/icons/DateRange'
import PhoneIcon from '@material-ui/icons/PhoneAndroid'
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer'
import FinishedIcon from '@material-ui/icons/AssignmentTurnedIn'
import ThumbsUpDownIcon from '@material-ui/icons/ThumbsUpDown'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant'
import CheckIcon from '@material-ui/icons/DoneOutline'
import Divider from '@material-ui/core/Divider'
import CallToActionIcon from '@material-ui/icons/CallToAction'
import {
  getIntegrationFeature
} from 'api/integrations'
import { Icon } from 'library/materialUI/Icon'

function SalesloftIcon () {
  return (
    <Icon icon='salesloft' />
  )
}

function Dragger (props) {
  const classes = useStyles(props)

  return (
    <div
      data-node-type={props.dataNodeType}
      title='Drag to add new skill'
      className={`${classes.dragger} ${props.class} ${classes.dragDrop} dragger`}
      style={{ marginBottom: 20, zIndex: 1500, position: 'relative' }}
    >
      <div className='node-header'>
        <div className={classes.iconDiv}><props.icon /></div>
        <div className={classes.cardTitle}>{props.title}</div>
      </div>
      <div className='node-body' style={{ minHeight: 42 }}>{props.body}</div>
    </div>
  )
}

const OptionsDragger = (props) => {
  const classes = useStyles(props)

  const title = 'Question'
  const body = 'Ask your site visitors a simple question'

  if ((title + ' ' + body).toLowerCase().includes(props.search)) {
    return (
      <Dragger
        dataNodeType='options'
        title={title}
        body={body}
        class={classes.visitorInteraction}
        icon={QuestionIcon}
      />
    )
  }
  return (<></>)
}

const FaqDragger = (props) => {
  const classes = useStyles(props)

  const title = 'FAQ'
  const body = "Handle visitors' frequently asked questions"

  if ((title + ' ' + body).toLowerCase().includes(props.search)) {
    return (
      <Dragger
        dataNodeType='faq'
        title={title}
        body={body}
        class={classes.visitorInteraction}
        icon={QuestionAnswerIcon}
      />
    )
  }
  return (<></>)
}

const LiveChatDragger = (props) => {
  const classes = useStyles(props)

  const title = 'Live Chat'
  const body = 'Route an agent for live chat'

  if ((title + ' ' + body).toLowerCase().includes(props.search)) {
    return (
      <Dragger
        dataNodeType='live_chat'
        title={title}
        body={body}
        class={classes.agentConnection}
        icon={HeadsetMicIcon}
      />
    )
  }
  return (<></>)
}

const AlertAgentDragger = (props) => {
  const classes = useStyles(props)

  const title = 'Alert Agent'
  const body = 'Notify an agent about this conversation'

  if ((title + ' ' + body).toLowerCase().includes(props.search)) {
    return (
      <Dragger
        dataNodeType='alert_agent'
        title={title}
        body={body}
        class={classes.agentConnection}
        icon={NotificationImportantIcon}
      />
    )
  }
  return (<></>)
}

const ConversationStatusDragger = (props) => {
  const classes = useStyles(props)

  const title = 'Close Conversation'
  const body = 'End the bot flow and close the conversation'

  if ((title + ' ' + body).toLowerCase().includes(props.search)) {
    return (
      <Dragger
        dataNodeType='conversation_status'
        title={title}
        body={body}
        class={classes.backgroundAction}
        icon={FinishedIcon}
      />
    )
  }
  return (<></>)
}

const ChatDragger = (props) => {
  const classes = useStyles(props)

  const title = 'Message'
  const body = 'Insert a message or phrase'

  if ((title + ' ' + body).toLowerCase().includes(props.search)) {
    return (
      <Dragger
        dataNodeType='chat'
        title={title}
        body={body}
        class={classes.visitorInteraction}
        icon={ChatIcon}
      />
    )
  }
  return (<></>)
}

const CTAButtonDragger = (props) => {
  const classes = useStyles(props)

  const title = 'Button'
  const body = 'Show a Button that links to another URL.'

  if ((title + ' ' + body).toLowerCase().includes(props.search)) {
    return (
      <Dragger
        dataNodeType='cta_button'
        title={title}
        body={body}
        class={classes.visitorInteraction}
        icon={CallToActionIcon}
      />
    )
  }
  return (<></>)
}

const EmailDragger = (props) => {
  const classes = useStyles(props)

  const title = 'Email Capture'
  const body = "Collect the visitor's email address"

  if ((title + ' ' + body).toLowerCase().includes(props.search)) {
    return (
      <Dragger
        dataNodeType='email'
        title={title}
        body={body}
        class={classes.visitorInteraction}
        icon={EmailIcon}
      />
    )
  }
  return (<></>)
}

const ConditionDragger = (props) => {
  const classes = useStyles(props)

  const title = 'Conditional Branching'
  const body = 'Branch based on conditions'

  if ((title + ' ' + body).toLowerCase().includes(props.search)) {
    return (
      <div id='sl_bot_branching'>
        <Dragger
          dataNodeType='conditional'
          title={title}
          body={body}
          class={classes.backgroundAction}
          icon={BranchIcon}
        />
      </div>
    )
  }
  return (<></>)
}

const TagDragger = (props) => {
  const classes = useStyles(props)

  const title = 'Tag Conversation'
  const body = 'Add tags to conversation'

  if ((title + ' ' + body).toLowerCase().includes(props.search)) {
    return (
      <Dragger
        dataNodeType='tag'
        title={title}
        body={body}
        class={classes.backgroundAction}
        icon={TagIcon}
      />
    )
  }
  return (<></>)
}

const LeadScoreDragger = (props) => {
  const classes = useStyles(props)

  const title = 'Set Lead Score'
  const body = "Set the visitor's lead score"

  if ((title + ' ' + body).toLowerCase().includes(props.search)) {
    return (
      <Dragger
        dataNodeType='lead_score'
        title={title}
        body={body}
        class={classes.backgroundAction}
        icon={StarIcon}
      />
    )
  }
  return (<></>)
}

const GoalDragger = (props) => {
  const classes = useStyles(props)

  const title = 'Conversation Goal'
  const body = 'Conversation has reached a goal'

  if ((title + ' ' + body).toLowerCase().includes(props.search)) {
    return (
      <Dragger
        dataNodeType='goal'
        title={title}
        body={body}
        class={classes.backgroundAction}
        icon={CheckIcon}
      />
    )
  }
  return (<></>)
}

const CalendarDragger = (props) => {
  const classes = useStyles(props)

  const title = 'Calendar Invite'
  const body = 'Schedule a calendar meeting'

  if ((title + ' ' + body).toLowerCase().includes(props.search)) {
    return (
      <Dragger
        dataNodeType='calendar'
        title={title}
        body={body}
        class={classes.agentConnection}
        icon={CalendarIcon}
      />
    )
  }
  return (<></>)
}

const PhoneDragger = (props) => {
  const classes = useStyles(props)

  const title = 'Phone Capture'
  const body = "Capture the visitor's phone number"

  if ((title + ' ' + body).toLowerCase().includes(props.search)) {
    return (
      <Dragger
        dataNodeType='phone'
        title={title}
        body={body}
        class={classes.visitorInteraction}
        icon={PhoneIcon}
      />
    )
  }
  return (<></>)
}

const ConversationRatingDragger = (props) => {
  const classes = useStyles(props)

  const title = 'Conversation Rating'
  const body = 'Ask visitors for feedback on their chat experience'

  if ((title + ' ' + body).toLowerCase().includes(props.search)) {
    return (
      <Dragger
        dataNodeType='conversation_rating'
        title={title}
        body={body}
        class={classes.visitorInteraction}
        icon={ThumbsUpDownIcon}
      />
    )
  }
  return (<></>)
}

const CreateContactDragger = (props) => {
  const classes = useStyles(props)
  const [enabled, setEnabled] = useState(false)
  useEffect(() => {
    getIntegrationFeature('crm.create_contact').then((response) => {
      const integration = response.data?.attributes?.integration?.name
      if (integration) {
        setEnabled(true)
      }
    }, (err) => {
      console.error(err)
    })
  }, [])

  const title = 'Create Contact'
  const body = 'Create a contact and add to a list in your chosen integration'

  if (enabled && (title + ' ' + body).toLowerCase().includes(props.search)) {
    return (
      <Dragger
        dataNodeType='create_contact'
        title={title}
        body={body}
        class={classes.backgroundAction}
        icon={PersonAddIcon}
      />
    )
  }
  return (<></>)
}

const SalesloftDragger = (props) => {
  const classes = useStyles(props)
  // const [enabled, setEnabled] = useState(false)

  const title = 'Salesloft'
  const body = 'Enroll or unenroll visitor into Salesloft cadence'

  return (
    <div id='sl_bot_skill'>
      <Dragger
        dataNodeType='salesloft'
        title={title}
        body={body}
        class={classes.backgroundAction}
        icon={SalesloftIcon}
      />
    </div>
  )
}

const SendEmailDragger = (props) => {
  const classes = useStyles(props)

  const title = 'Send Email'
  const body = 'Send an email to the visitor'

  if ((title + ' ' + body).toLowerCase().includes(props.search)) {
    return (
      <Dragger
        dataNodeType='send_email'
        title={title}
        body={body}
        class={classes.backgroundAction}
        icon={EmailIcon}
      />
    )
  }
  return (<></>)
}

const EndFlowDragger = (props) => {
  const classes = useStyles(props)

  const title = 'Bot Finished'
  const body = 'No further bot actions'

  if ((title + ' ' + body).toLowerCase().includes(props.search)) {
    return (
      <Dragger
        dataNodeType='end_flow'
        title={title}
        body={body}
        class={classes.endOfFlow}
        icon={CheckCircleIcon}
      />
    )
  }
  return (<></>)
}

const DraggerHeader = (props) => {
  if (props.search) {
    return (<></>)
  }

  return (
    <div style={{ width: 250, fontSize: '1.2em', color: 'rgba(0,0,0,0.6)' }}>
      {props.title}
      <Divider style={{ marginTop: 5, marginBottom: 10 }} />
    </div>
  )
}

class DragDropNodeSource extends SurfaceDropComponent {
  render () {
    const sf = this.props.flags?.salesloft
    const faq = this.props.flags?.faq
    return (
      <div className='node-tray'>
        <DraggerHeader title='Essentials' search={this.props.search} />
        <OptionsDragger search={this.props.search} />
        <ChatDragger search={this.props.search} />
        <LiveChatDragger search={this.props.search} />
        <CalendarDragger search={this.props.search} />
        <ConditionDragger search={this.props.search} />
        <DraggerHeader title='Data Capture' search={this.props.search} />
        <EmailDragger search={this.props.search} />
        <PhoneDragger search={this.props.search} />
        <DraggerHeader title='Conversation' search={this.props.search} />
        <ConversationRatingDragger search={this.props.search} />
        <GoalDragger search={this.props.search} />
        <TagDragger search={this.props.search} />
        <ConversationStatusDragger search={this.props.search} />
        <DraggerHeader title='Other' search={this.props.search} />
        {faq ? <FaqDragger search={this.props.search} /> : <></>}
        <LeadScoreDragger search={this.props.search} />
        <AlertAgentDragger search={this.props.search} />
        <SendEmailDragger search={this.props.search} />
        <CreateContactDragger search={this.props.search} />
        {sf ? <SalesloftDragger search={this.props.search} /> : <></>}
        <CTAButtonDragger search={this.props.search} />
        <EndFlowDragger search={this.props.search} />
      </div>
    )
  }
}

export { DragDropNodeSource, Dragger }
