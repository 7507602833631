import { jsPlumbUtil } from 'jsplumbtoolkit'

function removeInvalidEdges (flow) {
  let edges = flow.data.edges
  const nodeIDs = flow.data.nodes.map(node => node.id)
  edges = edges.filter(edge => nodeIDs.includes(edge.target) || edge.target.includes('exit') || edge.target.includes('output'))
  for (const edge of edges) {
    if (!Object.prototype.hasOwnProperty.call(edge, 'data')) {
      const id = jsPlumbUtil.uuid()
      const data = {
        id: id,
        type: 'common'
      }
      edge.data = data
    }
  }
  flow.data.edges = edges
  return flow
}

function cleanFlow (flow) {
  if (!flow) {
    return flow
  }
  flow = removeInvalidEdges(flow)
  if (!Object.prototype.hasOwnProperty.call(flow.data, 'ports')) {
    flow.data.ports = []
  }
  return flow
}

export { cleanFlow }
