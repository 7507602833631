import { makeStyles } from '@material-ui/core/styles'
import Form from 'classes/forms'
import { Switch } from 'library/materialUI'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    margin: '15px',
    padding: '20px',
    backgroundColor: '#FFFFFF',
    border: '1px solid #D3D3D3',
    borderRadius: '5px'
  },
  sectionSubtitle: {
    color: '#808080',
    fontFamily: 'poppins',
    fontSize: '14px',
    marginTop: '5px',
    marginBottom: '5px'
  },
  sectionTitle: {
    fontSize: '20px',
    fontWeight: 'bold',
    marginBottom: '10px',
    color: '#000000BF'
  },
  checkboxDiv: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  checkboxLabel: {
    color: '#343434',
    fontFamily: 'poppins',
    fontSize: '14px',
    fontWeight: 'bold'
  }
}))

interface FormFollowupCardProps {
  form: Form
  dispatch: any
  setCanSave: (value: boolean) => void
  disabled?: boolean
}

export default function FormFollowupCard ({ form, dispatch, setCanSave, disabled }: FormFollowupCardProps) {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <div>
        <div className={classes.sectionTitle}>
          Follow-up Actions
        </div>
        <div className={classes.sectionSubtitle}>
          Offer the option to start a live chat or book a meeting to everyone who submits a form, or be more selective and offer them only to people who match certain criteria.
        </div>
      </div>
      <div className={classes.checkboxDiv}>
        <Switch
          checked={form.followUp}
          handleChange={(value: boolean) => { dispatch({ type: 'followUp', value }); setCanSave(true) }}
          color='secondary'
          disabled={disabled}
        />
      </div>
    </div>
  )
}
