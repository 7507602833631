import { useState } from 'react'
import { Table, Actions, Fields } from 'cf-components/table/Table'
import AppPage from 'cf-components/AppPage'
import { SavedReply } from 'classes/savedReplies'
import { SavedReplyModal } from './ReplyModal'

export default function SavedRepliesPage (): JSX.Element {
  const { data: replies, isLoading } = SavedReply.loadAll()
  const [editReply, setEditReply] = useState<SavedReply | null>(null)

  const fields: Fields<SavedReply> = [
    { name: 'priority', type: 'priority', changePriority: SavedReply.updatePriority, count: replies.list.length },
    { name: 'name', type: 'avatar', onClick: row => setEditReply(row) },
    { name: 'categoryText', type: 'text', label: 'Categories' },
    { name: 'visibilityText', type: 'text', label: 'Visibility' },
    { name: 'createdByUserID', type: 'userAvatar', label: 'Created By' }
  ]

  const actions: Actions<SavedReply> = [
    { type: 'edit', name: 'Edit Reply', action: row => setEditReply(row) },
    { type: 'delete' }
  ]

  document.title = 'Saved Replies | Signals'

  return (
    <AppPage
      title='Saved Replies'
      actionText='Create Reply'
      action={() => setEditReply(new SavedReply())}
    >
      <Table
        rows={replies.list}
        isLoading={isLoading}
        fields={fields}
        columnStyles='100px 4fr 2fr 2fr 3fr'
        actions={actions}
      />
      {editReply &&
        <SavedReplyModal
          reply={editReply}
          onHide={() => setEditReply(null)}
          categories={replies.categories}
        />}
    </AppPage>
  )
}
