import { useMemo } from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemText from '@material-ui/core/ListItemText'
import Select from '@material-ui/core/Select'
import Checkbox from '@material-ui/core/Checkbox'
import User from 'classes/users'
import Team from 'classes/teams'

function UserAndTeamPicker ({ selectedTargets, setSelectedTargets, ...props }) {
  const { data: { list: usersList } } = User.loadAll()
  const { data: { list: teamsList } } = Team.loadAll()
  const selectOptions = useMemo(() => ({
    users: usersList.map(user => ({ type: 'users', id: user.id, name: user.name || user.email })),
    teams: teamsList.map(team => ({ type: 'teams', id: team.id, name: team.name }))
  }), [usersList, teamsList])
  const accountOwnerOpt = { type: 'ao', id: 1, name: 'Account Owner' }
  selectOptions.ac = accountOwnerOpt

  const handleChange = e => {
    const updatedTargets = {}
    e.target.value.forEach(opt => {
      if (!updatedTargets[opt.type]) {
        updatedTargets[opt.type] = []
      }
      updatedTargets[opt.type].push(opt.id)
    })
    // this is here to remove account owner, most likely a better way
    if (updatedTargets.ao && updatedTargets.ao.length > 1) {
      delete updatedTargets.ao
    }
    setSelectedTargets(updatedTargets)
  }

  const values = [].concat(...Object.values(selectOptions)).filter(opt => selectedTargets[opt.type]?.includes(opt.id))

  return (
    <Select
      fullWidth
      variant='outlined'
      value={values}
      multiple
      margin='dense'
      onChange={e => handleChange(e)}
      displayEmpty
      renderValue={selected => selected.length === 0 ? 'Select agents and/or teams' : selected.map(opt => opt.name).join(', ')}
      className={props.className}
      classes={props.classes}
    >
      <MenuItem value='' disabled>Account Owner</MenuItem>
      <MenuItem value={accountOwnerOpt} key={'ao-' + 1}>
        <Checkbox checked={values.some(val => val.type === 'ao')} color='primary' />
        <ListItemText primary='Account Owner' />
      </MenuItem>
      <MenuItem value='' disabled>Agents</MenuItem>
      {selectOptions.users.map(opt =>
      (
        <MenuItem key={'user-' + opt.id} value={opt}>
          <Checkbox checked={values.includes(opt)} color='primary' />
          <ListItemText primary={opt.name} />
        </MenuItem>
      ))}
      <MenuItem value='' disabled>Teams</MenuItem>
      {selectOptions.teams.map(opt => (
        <MenuItem key={'team-' + opt.id} value={opt}>
          <Checkbox checked={values.includes(opt)} color='primary' />
          <ListItemText primary={opt.name} />
        </MenuItem>
      ))}
    </Select>
  )
}

export default UserAndTeamPicker
