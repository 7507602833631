
const descriptionText = 'This template is utilized to manage your interactions with a site visitor if the company they work for is already a paying customer.'

const Description = (): JSX.Element => {
  return (
    <div>
      <div>
        {descriptionText}
      </div>
      <ul>
        <li>If they are currently on your site</li>
        <ul>
          <li>Fires a support bot</li>
        </ul>
        <li>If they have not visited your site recently</li>
        <ul>
          <li>Sends a customer outreach email</li>
          {/* <li>Fires a bot to welcome them back if they click on the email link</li> */}
        </ul>
      </ul>
    </div>
  )
}

const bestPracticeText = 'If using this template, be sure to update its settings to verify that the play is running optimaly.'

const BestPractices = () => {
  return (
    <div>
      <div>
        {bestPracticeText}
      </div>
      <ul>
        <li><a href='#/engagement/emails/emails' target='_blank'>Add/update emails</a></li>
        <li><a href='#/engagement/chatbots/chatbots' target='_blank'>Add/update bots</a></li>
      </ul>
    </div>
  )
}

export const currentCustomerDocs = {
  helpCenterLink: '',
  bestPractices: BestPractices,
  description: Description,
  videoLink: ''
}
