import { TextField } from '@material-ui/core'
import { RefObject } from 'react'
import { useStyles } from './styles'

type TextBoxType = 'number' | undefined
export interface TextBoxProps {
  onChange: any
  onSelect?: () => void
  value: string | number
  label?: string
  rows?: number
  type?: TextBoxType
  autoFocus?: boolean
  helperText?: string
  error?: boolean
  id?: string
  inputRef?: RefObject<any>
  placeholder?: string
  class?: any
  onEnter?: () => void
  onTab?: () => void
  onBlur?: (e: any) => void
  defaultValue?: string | number
}

const isNumber = (evt: any): boolean => {
  evt = (evt) || window.event
  const charCode = (evt.which) ? evt.which : evt.keyCode
  const numPad = charCode >= 96 && charCode <= 106
  const numberKeys = charCode >= 48 && charCode <= 57
  const isNumber = numberKeys || numPad
  if (charCode > 31 && (!isNumber)) {
    evt.preventDefault()
    return false
  }
  return true
}

type HandleKeyPressProps = {
  event: KeyboardEvent
  type: TextBoxType
  onEnter?: () => void
  onTab?: () => void
}

const handleKeyPress = ({ event, type, onEnter, onTab }: HandleKeyPressProps) => {
  if (event.key === 'Enter' && onEnter) {
    onEnter()
  } else if (event.key === 'Tab' && onTab) {
    event.preventDefault()
    onTab()
  } else if (type === 'number') {
    return (isNumber(event))
  }
}

export function TextBox (props: TextBoxProps): JSX.Element {
  const classes = useStyles()
  const { type, onEnter, onTab } = props
  return (
    <TextField
      id={props.id}
      type={props.type}
      error={props.error}
      label={props.label}
      helperText={props.helperText}
      placeholder={props.placeholder}
      inputRef={props.inputRef}
      onSelect={props.onSelect}
      fullWidth
      margin='dense'
      variant='outlined'
      value={props.value}
      onChange={(e: any) => props.onChange(e.target.value)}
      onKeyDown={(event: any) => handleKeyPress({ event, type, onEnter, onTab })}
      className={classes.muiComponent}
      rows={props.rows}
      multiline={Boolean(props.rows)}
      autoFocus={Boolean(props.autoFocus)}
      inputProps={props.class || undefined}
      onBlur={(e:any) => {
        return (
          props.onBlur ? props.onBlur(e) : console.log
        )
      }}
      defaultValue={props.defaultValue}
    />
  )
}
