import { useState, useEffect, Fragment, useContext } from 'react'
import WarningIcon from '@material-ui/icons/Warning'
import { makeStyles } from '@material-ui/core/styles'
import { PageLoader } from 'cf-components/LoadingScreen'
import SettingsCard from 'cf-components/SettingsCard'
import AccountICPSetup from './AccountICPSetup'
import AccountICPHub from './AccountICPHub'
import {
  getIntegrationFeature,
  FEATURES
} from 'api/integrations'
import Typography from '@material-ui/core/Typography'
import { cleanErrorMsg } from 'api/api'
import { ICPContext } from './ICPProvider'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { Modal } from 'library/Modal'
import { Button, Icon } from 'library/materialUI'

const useStyles = makeStyles(theme => ({
  warningCtn: {
    display: 'flex',
    alignItems: 'flex-end',
    borderRadius: 10,
    padding: '10px 12px',
    border: '1px solid',
    color: theme.palette.warning.main,
    borderColor: theme.palette.warning.main,
    marginBottom: 14,
    backgroundColor: theme.palette.warning.light,
    fontSize: '0.9rem'
  },
  pageCtn: {
    margin: '20px 40px 0',
    color: theme.palette.text.primary
  },
  list: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    paddingLeft: 20,
    '& li': {
      marginTop: 12
    },
    '& span': {
      color: theme.palette.text.primary,
      fontWeight: 'normal'
    }
  },
  section: {
    paddingBottom: 20
  },
  card: {
    marginBottom: 20,
    borderRadius: 10,
    boxShadow: '0 1px 1px #00000062'
  },
  cardTitle: {
    fontSize: '1.2em',
    color: '#5F5F5F',
    fontWeight: 600,
    marginBottom: 12
  },
  personaCtn: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 10,
    padding: '18px 30px 12px',
    maxWidth: 600,
    marginBottom: 12
  },
  dividerLine: {
    borderBottom: `1px solid ${theme.palette.primary.light}`,
    margin: '24px 12px'
  },
  subTitle: {
    color: '#727272'
  },
  checkMark: {
    height: 20,
    width: 20,
    marginRight: 10
  },
  saved: {
    color: '#55AB58'
  },
  needsSave: {
    color: theme.palette.grey[300]
  },
  centerDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  titleExpand: {
    width: '100%'
  },
  saveICP: {
    marginLeft: 20
  }
}))

export default function AccountICPPage (): JSX.Element {
  const classes = useStyles()
  const {
    isLoading,
    icp,
    error,
    infoModalOpen,
    setInfoModalOpen
  } = useContext(ICPContext)

  const cardTitle = (cardText: string): JSX.Element => {
    return (
      <div className={classes.centerDiv}>
        <div className={classes.centerDiv}>
          <CheckCircleIcon className={`${classes.checkMark} ${classes.saved}`} />
          {cardText}
        </div>
      </div>
    )
  }

  if (isLoading) {
    return <PageLoader />
  }

  if (error) {
    if (!(error.message.toUpperCase().includes('NOT FOUND'))) {
      console.log(error)
      const msg = cleanErrorMsg(error.message) || 'Error loading icp'
      return (
        <>{msg}</>
      )
    }
  }

  if (!icp || (icp as unknown as { list: any[], dict: Record<any, any> }).list) {
    return <SetupICPPage />
  }

  return (
    <>
      <div className={classes.pageCtn}>
        <SettingsCard
          title={cardTitle('Ideal Customer Profile'/*, icpNeedsSave */)}
          subtitle="This is your company's ICP."
          expanded
        >
          <AccountICPHub
            skipCRM={icp.skipCRM}
          />
        </SettingsCard>
        <Modal
          open={infoModalOpen || false}
          onHide={() => setInfoModalOpen(false)}
          handleSave={() => setInfoModalOpen(false)}
          title='Your Ideal Customer Profile Saved'
          size='sm'
          hideCancelButton
          closeBtn
          saveBtnText='Sounds good!'
          noDividers
        >
          <Typography variant='body2'>
            It will take a few minutes before you begin to see changes to your dashboard, but segmenting companies will happen immediately.
          </Typography>
        </Modal>
      </div>
    </>
  )
}

function SetupICPPage (): JSX.Element {
  const classes = useStyles()
  const [showSetup, setShowSetup] = useState(false)
  const [skipCRM, setSkipCRM] = useState(false)
  const [loading, setLoading] = useState(true)
  const [hasCRM, setHasCRM] = useState(false)

  useEffect(() => {
    getIntegrationFeature(FEATURES.CRMABE)
      .then(response => {
        setLoading(false)
        if (response.data.attributes.integration) {
          setHasCRM(true)
        }
      })
  }, [])

  if (loading) {
    return <></>
  }

  if (showSetup) {
    return (
      <div className={classes.pageCtn}>
        <AccountICPSetup
          skipCRM={skipCRM}
          onCancel={() => setShowSetup(false)}
        />
      </div>
    )
  }
  return (
    <div className={classes.pageCtn}>
      <div className={classes.section}>
        {!hasCRM && (
          <div>
            <div style={{ display: 'inline-block' }}>
              <div className={classes.warningCtn}>
                <WarningIcon />
                <Typography style={{ marginLeft: 10 }}> It looks like you have not connected your CRM. You can continue to configure your ICP manually if you don't have a CRM or it's a CRM not yet supported by Signals.  </Typography>
              </div>
            </div>
          </div>
        )}
        When you choose to set up your Ideal Customer Profile (ICP), we will tell you who your ideal accounts are. From there, you can determine who the most important contacts from those accounts are. After that you'll be able to easily enroll those accounts or contacts into your campaigns.
      </div>
      <div className={classes.section}>
        <ol className={classes.list}>
          <li>ICP — <span>With your CRM connected, we'll analyze your current and past customers and put together a profile of criteria that meets your ideal customer.</span></li>
          <li>Buying Committee — <span>Choose the contact titles (e.g. VP of Marketing, CEO) you're interested in speaking to from your ICP accounts.</span></li>
          <li>Campaigns — <span>We will automatically put your ICP accounts and Buying Committee contacts into segments. You can enroll those segments into email campaigns using PlayMaker.</span></li>
        </ol>
      </div>
      {hasCRM ? (
        <div style={{ marginRight: 10, display: 'inline-block' }}>
          <Button
            color='primary'
            variant={hasCRM ? 'contained' : 'outlined'}
            onClick={() => {
              setShowSetup(true)
              setSkipCRM(false)
            }}
            style={{ textTransform: 'none' }}
            startIcon={<Icon icon='aiSparklesWhite' />}
          >
            Analyze current customers using AI
          </Button>
        </div>
      ) : (
        <div style={{ marginRight: 10, display: 'inline-block' }}>
          <Button
            color='primary'
            variant='contained'
            onClick={() => { window.location.href = '/#/settings/integrations' }}
            style={{ textTransform: 'none' }}
          >
            Set up CRM
          </Button>
        </div>
      )}
      <Button
        color='primary'
        variant='outlined'
        onClick={() => {
          setShowSetup(true)
          setSkipCRM(true)
        }}
        style={{ textTransform: 'none' }}
      >
        Create ICP manually
      </Button>
    </div>
  )
}
