import { useDoQuery } from './useDoQuery'
import { GenericObject } from './genericObject'
import { doDelete, doGet, doPatch, doPost } from 'api/api'
import { DateType } from './classHelpers'

interface ScraperSummaryList {
  list: ScraperSummary[]
  dict: { [id: ScraperSummary['id']]: ScraperSummary }
}

export default class ScraperSummary extends GenericObject {
  url: string
  scrapeID: string
  createdTimestamp: DateType
  updatedTimestamp: DateType
  deletedTimestamp: DateType

  static loadAll (): { data: ScraperSummaryList, isLoading: boolean } {
    return useDoQuery({
      path: '/scraper_summary',
      objectClass: ScraperSummary,
      useChatURL: true
    })
  }

  static save ({ data, silent = false }: { data: ScraperSummary, silent: boolean }): Promise<any> {
    let path = '/scraper_summary'
    const body = {
      attributes: { ...data }
    }

    if (data?.id) {
      path += `/${data.id}`
      return doPatch({ path, data: body, useChatURL: true, silent })
    } else { return doPost({ path, data: body, useChatURL: true, silent }) }
  }

  delete (): Promise<any> {
    const path = `/scraper_summary/${this.id}`
    return doDelete({ path, useChatURL: true })
  }

  static doScrape (id: number) {
    const path = `/api/core/scrape/${id}`
    return doGet({ path, useChatURL: false })
  }

  constructor ({ row }: { row: Record<string, any> }) {
    super({ row })
    const scraper = row.attributes
    this.url = scraper?.url
    this.scrapeID = scraper?.scrape_id
    this.createdTimestamp = scraper?.created_timestamp
    this.updatedTimestamp = scraper?.updated_timestamp
    this.deletedTimestamp = scraper?.deleted_timestamp
  }
}
