import { makeStyles } from '@material-ui/core/styles'
import Loader from 'library/loading/Loader'
import { Icon } from 'library/materialUI'
// import { IconType } from 'library/materialUI/Icon'
import { LinearProgress } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { getTenantChecklist } from 'api/admin_only/tenants'

const useStyles = makeStyles(theme => ({
  setupList: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '5px 20px 5px 0'
  },
  setupCounter: {
    textAlign: 'right',
    color: theme.palette.primary.main,
    marginBottom: 10
  },
  linearProgress: {
    marginBottom: 10
  }
}))

interface setupTypes {
  name: string
  value: string
}

const setupList: setupTypes[] = [
  { name: 'Code Installed', value: 'installed' },
  { name: 'Chat Appearance Customized', value: 'appearance' },
  { name: 'Chat Availability Set', value: 'availability' },
  { name: 'Company Profile Set Up', value: 'profile' },
  { name: 'Team Members Invited', value: 'invites' },
  { name: 'Built a Bot', value: 'bot_built' },
  { name: 'First Bot Live', value: 'bot_live' },
  { name: 'Connected to CRM', value: 'crm' },
  { name: 'Setup ICP', value: 'icp' },
  { name: 'Turned on PlayMaker', value: 'playrunner' }
]

interface progressProps {
  value: number
}

interface checklistTypes {
  appearance: boolean
  availability: boolean
  bot_built: boolean // eslint-disable-line
  bot_live: boolean // eslint-disable-line
  crm: boolean
  icp: boolean
  installed: boolean
  invites: boolean
  playrunner: boolean
  profile: boolean
  count: number
}

const defaultChecklist: checklistTypes = {
  appearance: false,
  availability: false,
  bot_built: false, // eslint-disable-line
  bot_live: false, // eslint-disable-line
  crm: false,
  icp: false,
  installed: false,
  invites: false,
  playrunner: false,
  profile: false,
  count: 0
}

export const AdminSetupChecklist = (props: { tenantId: number | null }): JSX.Element => {
  const classes = useStyles()
  const tenantId = props.tenantId
  const [setupChecklist, setSetupChecklist] = useState(defaultChecklist)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    getTenantChecklist(tenantId)
      .then(response => {
        const attributes = response.data.attributes
        const count = Object.values(attributes).reduce((a: number, item: any) => a + (item === true ? 1 : 0), 0)
        const checklist = { ...attributes, count: count }
        setSetupChecklist(checklist)
        setLoading(false)
      })
  }, [tenantId])

  const LinearProgressWithLabel = (props: progressProps): JSX.Element => {
    return (
      <div>
        <div className={classes.setupCounter}>{props.value}/10</div>
        <div className={classes.linearProgress}>
          <LinearProgress
            variant='determinate'
            color='primary'
            value={props.value * 10}
          />
        </div>
      </div>
    )
  }

  return (
    <div>
      {loading ? (<> <Loader type='spinner' /> </>) : (
        <>
          <LinearProgressWithLabel value={setupChecklist.count} />
          {setupList.map((i, e) => {
            const checklistBool = setupChecklist[i.value as keyof checklistTypes]
            return (
              <div className={classes.setupList} key={e}>
                <div>{i.name}</div>
                <Icon icon={checklistBool ? 'checkCircle' : 'cancel'} color={checklistBool ? '#2FB182' : '#DA2F58'} />
              </div>
            )
          })}
        </>
      )}
    </div>
  )
}
