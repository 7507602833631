import { makeStyles } from '@material-ui/core/styles'
import { Icon } from 'library/materialUI'

const useStyles = makeStyles(theme => ({
  awaiting: {
    display: 'flex',
    flexDirection: 'row',
    color: '#DB9F44',
    margin: '5px'
  },
  approve: {
    display: 'flex',
    flexDirection: 'row',
    color: '#81AF20',
    margin: '5px'
  },
  deny: {
    display: 'flex',
    flexDirection: 'row',
    color: '#EA422A',
    margin: '5px'
  },
  updated: {
    display: 'flex',
    flexDirection: 'row',
    color: '#547BD0',
    margin: '5px'
  },
  statusItem: {
    marginRight: '5px'
  }
}))

interface CrowdSourceStatusProps {
  status: 'awaiting_review' | 'approved' | 'denied' | 'updated'
}

export default function CrowsSourceStatus ({ status }: CrowdSourceStatusProps) {
  const classes = useStyles()
  const classType = status === 'awaiting_review' ? classes.awaiting : status === 'approved' ? classes.approve : status === 'denied' ? classes.deny : classes.updated

  return (
    <div className={classType} style={{ cursor: 'pointer' }}>
      <div className={classes.statusItem} style={{ marginTop: '2px' }}>
        <Icon size='sm' icon={status === 'awaiting_review' ? 'info' : status === 'approved' ? 'thumbUp' : status === 'denied' ? 'thumbDown' : 'edit'} color={status === 'awaiting_review' ? '#DB9F44' : status === 'approved' ? '#81AF20' : status === 'denied' ? '#EA422A' : '#547BD0'} />
      </div>
      <div className={classes.statusItem}>
        {status === 'awaiting_review' ? 'Awaiting Review' : status === 'approved' ? 'Approved' : status === 'denied' ? 'Denied' : 'Updated'}
      </div>
      <div className={classes.statusItem}>
        <Icon icon='expand' color='black' />
      </div>
    </div>
  )
}
