import React from 'react'
import ConversationListItem from './ConversationListItem'
import { ConversationListStyles as useStyles } from './MobileChatStyles'
import EmptyState from 'components/EmptyState'
import { getDisplayName } from 'pages/chat/agent-chat/conversationHelpers'

function isOnline (last_presence) {
  const now = new Date()
  const diffMs = now - new Date(last_presence)
  if (diffMs < 65000) {
    return true
  }
  return false
}

function ConversationList (props) {
  const classes = useStyles()

  if (!props.conversationList) {
    return (
      <div>Loading</div>
    )
  }

  if (!props.conversationList.length) {
    return (
      <EmptyState message='No conversations' />
    )
  }

  return (
    <div className={classes.conversationList}>
      {props.conversationList.map(convo => {
        const name = getDisplayName(convo)
        const score = convo.attributes.lead_score
        const online = isOnline(convo.attributes.last_presence_timestamp)
        const status = convo.attributes.status
        const active = props.activeConversationId === convo.id
        const companyName = convo.attributes.company_name || convo.attributes.location

        return (
          <ConversationListItem
            convo={convo}
            key={convo.id}
            chooseConversation={props.chooseConversation}
            personName={name}
            companyName={companyName}
            online={online}
            status={status}
            active={active}
            read={active || props.readReceipts[convo.id]}
            score={score}
          />
        )
      })}
    </div>
  )
}

export default ConversationList
