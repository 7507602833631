import { useContext, useState } from 'react'
import { SessionContext } from 'session-context'
import { makeStyles } from '@material-ui/core/styles'
import ExplanationCard from './ExplanationCard'
import { Card, CardHeader, CardContent, Select, MenuItem, Divider, Typography, Avatar } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import Collapse from '@material-ui/core/Collapse'
import clsx from 'clsx'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import BotPreview from '../BotPreview'
import MissingAvatar from 'img/agent-profile-large-invite.svg'
import RoutingDiv from './RoutingDiv'
import { RichTextEditor } from 'library/richText/RichTextEditor'

const useStyles = makeStyles(theme => ({
  messagePage: {
    display: 'grid',
    gridTemplateColumns: 'auto 400px',
    width: '100%',
    gridGap: 15
  },
  card: {
    border: '1px solid rgba(142,90,226,0.5)',
    marginBottom: 15,
    backgroundColor: 'rgba(142,90,226,0.01)',
    overflow: 'visible !important'
  },
  header: {
    cursor: 'pointer',
    padding: 10
  },
  cardTitle: {
    fontSize: '1.1em'
  },
  cardAction: {
    margin: 0
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  },
  subHeading: {
    margin: '10px 20px 20px 10px'
  },
  divider: {
    backgroundColor: theme.palette.primary.main,
    marginBottom: 10
  },
  content: {
    display: 'grid',
    gridTemplateColumns: 'auto 400px',
    borderTop: '1px solid rgba(0,0,0,0.1)',
    gridGap: 20
  },
  previewDiv: {
    backgroundColor: 'rgba(142,90,226,0.08)',
    height: 560,
    padding: 20
  },
  settings: {
    backgroundColor: 'white',
    padding: 20
  },
  cardContentRoot: {
    padding: 0,
    '&:last-child': {
      paddingBottom: 0
    }
  }
}))

function MessageCard (props) {
  const classes = useStyles()
  const [expanded, setExpanded] = useState(true)

  const values = props.values
  const setFieldValue = props.setFieldValue
  const bot = props.botParticipant
  const allAgents = props.users.filter(user => Object.keys(props.agentsDict).map(n => parseInt(n)).includes(user.id))
  const possibleAgents = allAgents.filter((user) => user.attributes.perms.send_chats)
  const agentName = props.agentName
  const avatarUrl = props.avatarUrl

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  function editText (value, delta, source, editor) {
    props.setFieldValue('welcome_message', value)
  }

  return (
    <Card className={classes.card}>
      <CardHeader
        title='Message Settings'
        className={classes.header}
        classes={{ title: classes.cardTitle, action: classes.cardAction }}
        onClick={handleExpandClick}
        action={
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded
            })}
            aria-expanded={expanded}
            aria-label='show more'
          >
            <ExpandMoreIcon />
          </IconButton>
        }
      />
      <Collapse in={expanded} timeout='auto' unmountOnExit>
        <CardContent classes={{ root: classes.cardContentRoot }}>
          <div className={classes.content}>
            <div className={classes.settings}>
              <Typography variant='subtitle1'>
                Set your bot's welcome message
              </Typography>
              <Divider className={classes.divider} />
              <div style={{ height: 140 }}>
                <RichTextEditor
                  showMergeFields
                  message={values.welcome_message || ''}
                  onChange={editText}
                  placeholder='Welcome message...'
                />
              </div>
              {props.botType !== 'abe_greeting' && (
                <>
                  <div style={{ marginTop: 30 }}>
                    <Typography variant='subtitle1'>
                      Who should the message be sent from?
                    </Typography>
                    <Divider className={classes.divider} />
                    <Select
                      value={values.participant_id}
                      displayEmpty
                      margin='dense'
                      fullWidth
                      className={classes.select}
                      variant='outlined'
                      onChange={e => {
                        setFieldValue('participant_id', e.target.value)
                      }}
                    >
                      <MenuItem value='' disabled>Select an agent below</MenuItem>
                      <MenuItem value={props.botParticipant.id}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Avatar alt='User avatar' src={bot.attributes.avatar_url} style={{ height: 30, width: 30 }} />
                          <div style={{ marginLeft: 10 }}>{bot.attributes.agent_name}</div>
                        </div>
                      </MenuItem>
                      {possibleAgents.map((user, index) => (
                        <MenuItem value={props.agentsDict[user.id]} key={index}>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Avatar alt='User avatar' src={user.attributes.profile_picture_url || MissingAvatar} style={{ height: 30, width: 30 }} />
                            <div style={{ marginLeft: 10 }}>{user.attributes.name}</div>
                          </div>
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                  <RoutingDiv
                    values={values}
                    teams={props.teams}
                    setFieldValue={setFieldValue}
                    botType={props.botType}
                  />
                </>
              )}

            </div>
            <div className={classes.previewDiv}>
              <BotPreview
                message={values.welcome_message === '' ? '<p>Welcome message...</p>' : values.welcome_message}
                avatarUrl={avatarUrl}
                agentName={agentName}
                size={values.prompt_style}
                buttons={values.teams}
                router={props.botType === 'team_live_chat_router'}
              />
            </div>
          </div>
        </CardContent>
      </Collapse>
    </Card>
  )
}

function MessagePage (props) {
  const classes = useStyles()
  const { user } = useContext(SessionContext)
  const values = props.values
  const setFieldValue = props.setFieldValue

  const bullets = props.bullets || [
    'Your welcome message is the first message the bot sends to visitors',
    'It can come directly from the bot, or you can have it come from one of your agents for a more personal touch',
    'Once a visitor responds to a message, one of your agents will be routed in for live chat according to the rules you specify in the routing section'
  ]

  if (!(props.botParticipant && props.users && props.usersDict)) {
    return <></>
  }

  let agentName, avatarUrl

  if (props.participiantSelectionMethod === 'account_owner') {
    agentName = user.attributes.name
    avatarUrl = user.attributes.profile_picture_url
  } else if (props.botParticipant.id === values.participant_id) {
    agentName = props.botParticipant.attributes.agent_name
    avatarUrl = props.botParticipant.attributes.avatar_url
  } else {
    const user = props.users.filter(user => user.id === props.usersDict[values.participant_id])[0]
    agentName = user.attributes.name
    avatarUrl = user.attributes.profile_picture_url || MissingAvatar
  }

  return (
    <div className={classes.messagePage}>
      <div>
        <MessageCard
          values={values}
          setFieldValue={setFieldValue}
          users={props.users}
          agentsDict={props.agentsDict}
          botParticipant={props.botParticipant}
          agentName={agentName}
          avatarUrl={avatarUrl}
          botType={props.botType}
        />
      </div>
      <div>
        <ExplanationCard bullets={bullets} />
      </div>
    </div>
  )
}

export default MessagePage
