import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import { ErrorMessage } from 'formik'
import { InputAdornment } from '@material-ui/core'
import { Typography, Icon } from 'library/materialUI'

const useStyles = makeStyles(theme => ({
  invalidInput: {
    color: 'red',
    fontSize: 12,
    height: 20,
    display: 'flex'
  },
  passwordRequirements: {
    fontSize: 16,
    height: 'auto',
    marginTop: 16
  },
  topTextBox: {
    marginBottom: 8,
    marginTop: 0
  },
  bottomTextBox: {
    marginTop: 16,
    marginBottom: 4
  },
  icon: {
    marginRight: 8
  },
  textBoxContainer: {
    display: 'flex'
  },
  visibility: {
    cursor: 'pointer'
  },
  listItem: {
    fontSize: 16,
    marginBottom: 8
  },
  criteriaContainer: {
    display: 'flex',
    marginTop: 8,
    alignItems: 'center'
  },
  list: {
    marginTop: 8
  }
}))

const passwordCriteria = [
  'An uppercase letter',
  'A lowercase letter',
  'A number',
  'A special character'
]

function PasswordConfirmationBlock (props) {
  const classes = useStyles()
  const [lengthCheck, setLengthCheck] = useState(false)
  const [complexityCheck, setComplexityCheck] = useState(false)

  useEffect(() => {
    setLengthCheck(props.password && props.password.length >= 10)
    if (props.password) {
      let count = 0
      if (/[A-Z]/.test(props.password)) count++
      if (/[a-z]/.test(props.password)) count++
      if (/[0-9]/.test(props.password)) count++
      if (/[!@#$%^&*]/.test(props.password)) count++
      setComplexityCheck(count >= 2)
    } else {
      setComplexityCheck(false)
    }
  }, [props.password])

  return (
    <>
      <TextField
        variant='outlined'
        margin='normal'
        fullWidth
        id='password'
        label={props.textLabel || 'Password'}
        name='password'
        type={props.visible ? 'text' : 'password'}
        error={props.passwordError}
        autoFocus={props.autoFocus}
        onChange={props.handleChange}
        onBlur={e => {
          props.handleBlur(e)
          props.setFocused(false)
        }}
        onFocus={() => props.setFocused(true)}
        className={classes.topTextBox}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              {props.visible ? (
                <VisibilityIcon
                  onClick={() => props.setVisibility(false)}
                  className={classes.visibility}
                />
              ) : (
                <VisibilityOffIcon
                  onClick={() => props.setVisibility(true)}
                  className={classes.visibility}
                />
              )}
            </InputAdornment>
          )
        }}
      />
      {props.passwordError && (
        <div className={classes.invalidInput}>
          <ErrorMessage name='password' component='div' />
        </div>
      )}
      <div className={classes.passwordRequirements}>
        <Typography variant='h2'>Your password must:</Typography>
        <div className={classes.criteriaContainer}>
          <div className={classes.icon}>
            <Icon icon={lengthCheck ? 'lightGreenCheckCircle' : 'uncheckedRadio'} specificSize={15} />
          </div>
          <Typography variant='body1'>Be at least 10 characters long</Typography>
        </div>
        <div className={classes.criteriaContainer}>
          <div className={classes.icon}>
            <Icon icon={complexityCheck ? 'lightGreenCheckCircle' : 'uncheckedRadio'} specificSize={15} />
          </div>
          <Typography variant='body1'>Include at least two of the following:</Typography>
        </div>
        <ul className={classes.list}>
          {passwordCriteria.map((criteria, index) => (
            <li key={index} className={classes.listItem}>
              <Typography variant='body1'>{criteria}</Typography>
            </li>
          ))}
        </ul>
      </div>
    </>
  )
}

export default PasswordConfirmationBlock
