import AppPage from 'cf-components/AppPage'
import EmailQueryStringContent from './EmailQueryStringContent'
import { useState, useReducer, useEffect } from 'react'
import { EmailQueryStringSettings, queryStringSettingsReducer } from './EmailQueryStringSettings'

export default function EmailQueryStringPage () {
  const [loading, setLoading] = useState(true)
  const [canSave, setCanSave] = useState(false)
  const [settings, dispatch] = useReducer(queryStringSettingsReducer, new EmailQueryStringSettings({ row: {} }))

  useEffect(() => {
    async function loadSettings () {
      const loadedSettings = await EmailQueryStringSettings.loadSettings()
      if (!loadedSettings?.tenantId) {
        setCanSave(true)
      }
      dispatch({ type: 'loadSettings', value: loadedSettings })
      setLoading(false)
    }
    loadSettings()
  }, [])

  async function save () {
    try {
      await EmailQueryStringSettings.save(settings)
      setCanSave(false)
      return true
    } catch (error) {
      return false
    }
  }

  const saveProps = {
    disabled: !canSave,
    save: save,
    saveText: 'Save Settings'
  }

  return (
    <AppPage
      title='Email Query String'
      padding={20}
      saveProps={saveProps}
      isLoading={loading}
    >
      <EmailQueryStringContent setCanSave={setCanSave} settings={settings} dispatch={dispatch} />
    </AppPage>
  )
}
