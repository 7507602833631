import React, { useState, useEffect } from 'react'
import { jsPlumbUtil } from 'jsplumbtoolkit'
import { makeStyles, ThemeProvider } from '@material-ui/core/styles'
import OptionPort from '../OptionPort'
import useNodeStyles from './NodeStyles'
import Divider from '@material-ui/core/Divider'
import EmailIcon from '@material-ui/icons/Email'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { getEmails } from 'api/emails'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { Button } from '@material-ui/core'
import 'jsplumb'
import '../quill.css'
import { Emitter, EVENT_TYPE } from 'emitter'
import Picker from 'cf-components/Pickers'
import { Checkbox, Icon } from 'library/materialUI'
import { Modal, ModalSection } from 'library/Modal'
import theme from 'theme'

const useStyles = makeStyles(theme => ({
  subtitle: {
    color: '#444',
    fontFamily: 'Poppins, sans serif',
    fontSize: '14px',
    marginBottom: 5
  },
  divider: {
    marginTop: 10,
    marginBottom: 10
  },
  checkboxLabel: {
    fontSize: '14px',
    color: '#444',
    fontFamily: 'Poppins, sans serif'
  },
  questionText: {
    textAlign: 'left',
    fontFamily: 'Poppins',
    color: 'grey',
    fontSize: 14
  }
}))

const delayOptions = [
  { name: '5 minutes', value: 5 },
  { name: '15 minutes', value: 15 },
  { name: '1 hour', value: 60 },
  { name: '2 hours', value: 120 },
  { name: '24 hours', value: 1440 }
]

function SendEmailModal (props) {
  const classes = useStyles()
  const [emailID, setEmailID] = useState(props.emailID)
  const [sendDelay, setSendDelay] = useState(props.sendDelay || '')
  const [captureEmail, setCaptureEmail] = useState(Boolean(props.captureEmail))

  if (!props.open) {
    return <></>
  }

  Emitter.on(EVENT_TYPE.BROWSER_BACK, props.onHide)

  return (
    <ThemeProvider theme={theme}>
      <Modal
        open={props.open}
        onHide={props.onHide}
        handleSave={() => {
          props.save({ emailID, sendDelay, captureEmail })
        }}
        title='Send Email'
        helplink='home/bots/nodes/SendEmail'
        helplinkLabel='Learn more about this skill'
        saveIcon='save'
      >
        <ModalSection
          subtitle='Select an email to send to visitors'
        >
          <div>
            <Picker
              selection={emailID}
              setSelection={(value) => setEmailID(value)}
              objectType='emails'
            />
          </div>
          {emailID && (
            <Button
              style={{ fontSize: 12, color: 'gray', marginTop: 10, borderRadius: 50 }}
              variant='outlined'
              size='small'
              onClick={() => window.open(`/#/emails/edit/${emailID}`)}
              endIcon={<Icon icon='edit' size='sm' color='primary' />}
            >
              Edit selected email
            </Button>
          )}
        </ModalSection>
        <ModalSection>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  color='primary'
                  checked={typeof sendDelay === 'number'}
                  onChange={(e) => setSendDelay(typeof sendDelay === 'number' ? '' : 0)}
                />
              }
              label='Add a delay before the email is sent'
              classes={{ label: classes.checkboxLabel }}
            />
          </div>
          <div style={{ display: typeof sendDelay === 'number' ? 'block' : 'none' }}>
            <div>
              <Select
                fullWidth
                variant='outlined'
                value={sendDelay}
                onChange={(e) => setSendDelay(e.target.value)}
                displayEmpty
                margin='dense'
              >
                <MenuItem value={0} style={{ height: 40 }} disabled>Select a delay</MenuItem>
                {delayOptions.map((delay, index) => (
                  <MenuItem value={delay.value} key={index}>{delay.name}</MenuItem>
                ))}
              </Select>
            </div>
          </div>
        </ModalSection>
        <ModalSection>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  color='primary'
                  checked={captureEmail}
                  onChange={(checked) => setCaptureEmail(checked)}
                />
              }
              label='Capture email if unknown'
              classes={{ label: classes.checkboxLabel }}
            />
          </div>
          <div className={classes.questionText}>
            If the visitor's email is unknown this will send a message asking for their email. For more advanced email capture settings please use the email capture node. (Bot will say, "Can I get your email address?")
          </div>
        </ModalSection>
      </Modal>
    </ThemeProvider>
  )
}

const SendEmailNode = props => {
  const base = props.base
  const classes = useNodeStyles(props)
  const [chatServiceUrl, setChatServiceUrl] = useState(null)
  const [emails, setEmails] = useState([])
  const assignKey = data => {
    data._reactKey = data._reactKey || jsPlumbUtil.uuid()
    return data._reactKey
  }

  const saveChanges = ({ emailID, sendDelay, captureEmail }) => {
    if (!base.toolkit) {
      return
    }
    const node = base.getNode()
    const newData =
    {
      ...base.state,
      emailID: emailID,
      sendDelay: sendDelay,
      captureEmail: captureEmail,
      unwanted: {
        spam: true,
        blocked: true
      }
    }
    base.toolkit.updateNode(node, newData)
    props.setModalOpen(false)
  }

  useEffect(() => {
    fetch('/api/auth/me', {
      method: 'GET',
      headers: { 'Content-Type': 'application/vnd.api+json' }
    })
      .then(response => response.json())
      .then(response => {
        if (response.data && response.data.id) {
          setChatServiceUrl(response.data.links.chat_service || 'localhost:8002/api/chat-service/a')
        }
      })
  }, [])

  useEffect(() => {
    if (chatServiceUrl) {
      getEmails({ chatServiceUrl }).then(response => {
        setEmails(response.data.map((email) => ({ name: email.attributes.name, value: email.id })))
      })
    }
  }, [chatServiceUrl])

  return (
    <>
      <div>
        <div
          className={`${classes.dragger} ${classes.backgroundAction}`}
        >
          <div className='node-header'>
            <div className={classes.iconDiv}><EmailIcon /></div>
            <div className={classes.cardTitle}>Send an Email</div>
          </div>
          <div className='node-body'>
            <div>Send Email</div>
            <Divider style={{ marginTop: 5 }} />
            {base.state.ports.map((c, index) => {
              return (
                <OptionPort
                  index={index}
                  data={c}
                  key={assignKey(c)}
                  parent={base}
                  kind='backgroundAction'
                  type='email'
                />
              )
            })}
          </div>
          <jtk-target
            scope='default'
          />
        </div>
        <jtk-target
          scope='default'
        />
      </div>
      <SendEmailModal
        open={props.modalOpen}
        onHide={() => props.setModalOpen(false)}
        save={saveChanges}
        sendDelay={base.state.sendDelay}
        captureEmail={base.state.captureEmail}
        emailID={base.state.emailID}
        emails={emails}
      />
    </>
  )
}

export default SendEmailNode
