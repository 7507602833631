import homepageBot from './homepageBot'
import bookDemo from './secondNet'
import retargeting from './retargetingBot'
import homepageFAQ from './homepageFAQ'
import demoBot from './demoBot'
// import pricingBot from './pricingBot'
// import eventRegistration from './eventRegistration'

const FlowBotTemplates = [
  {
    id: 'homepage',
    name: 'Homepage Bot',
    description: 'This is a great bot to put on your home page where you have lots of low-intent traffic',
    type: 'template',
    flow: homepageBot,
    icon: 'homepageBot'
  },
  {
    id: 'second_net',
    name: 'Book a Demo',
    description: 'Use this bot to supplement your form on your demo booking pages by acting as a second net',
    type: 'template',
    flow: bookDemo,
    icon: 'secondNet'
  },
  {
    id: 'retargeting',
    name: 'Retargeting Bot',
    description: 'Use this bot specifically for people who have already been to your site and interacted with a bot',
    type: 'template',
    flow: retargeting,
    icon: 'target'
  },
  {
    id: 'homepage_faq',
    name: 'Homepage + FAQ',
    description: 'This is a great bot to put on your home page where you have lots of low-intent traffic',
    type: 'template',
    flow: homepageFAQ,
    icon: 'faqBot'
  },
  {
    id: 'demo',
    name: 'Demo Bot',
    description: 'Replace your form with a bot by firing this bot when a visitor clicks your “Book a Demo” button',
    type: 'template',
    flow: demoBot,
    icon: 'dateRange'
  }
  // {
  //   id: 'pricing',
  //   name: 'Pricing Bot',
  //   description: 'Pricing Bot',
  //   type: 'template',
  //   flow: pricingBot,
  //   icon: DemoBotIcon
  // },
  // {
  //   id: 'event',
  //   name: 'Event Registration',
  //   description: 'Event Registration',
  //   type: 'template',
  //   flow: eventRegistration,
  //   icon: DemoBotIcon
  // }
]

export { FlowBotTemplates }
