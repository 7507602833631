import { useEffect, useContext, useReducer } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Icon from '@material-ui/core/Icon'
import Popper from '@material-ui/core/Popper'
import MenuItem from '@material-ui/core/MenuItem'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import CircularProgress from '@material-ui/core/CircularProgress'
import Tooltip from '@material-ui/core/Tooltip'
import CustomizedSnackbar from 'components/CustomizedSnackbar'
import { SessionContext } from 'session-context'
import { salesforce } from './integration-helpers'

const STATUS = {
  Never: 0,
  Updated: 1,
  Behind: 2,
  Disabled: 3
}

const useStyles = makeStyles(theme => ({
  buttons: {
    fontFamily: 'Poppins, sans serif',
    fontSize: 10,
    height: 23,
    width: 21,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 500,
    cursor: 'pointer'
  },
  buttonProgress: {
    position: 'absolute',
    top: 2,
    left: 2
  },
  ctn: {
    position: 'relative'
  },
  imageIcon: {
    height: 14,
    width: 21
  },
  iconRoot: {
    height: 24,
    display: 'flex',
    alignItems: 'center'
  },
  menuItem: {
    fontFamily: 'Poppins, sans serif',
    fontWeight: 500,
    fontSize: '10px',
    lineHeight: '14px'
  }
}))

/**
 * @template {typeof defaultState} T the state
 * @param {T} state
 * @param {Partial<T>} action
 */
const reducer = (state, action) => {
  return { ...state, ...action }
}

/**
 * @type {{
 * integrationEnabled: boolean
 * updateStatus: typeof STATUS[keyof typeof STATUS]
 * hubSpotObjectID?: string
 * hubSpotInstanceID?: string
 * anchorEl: React.MouseEvent['currentTarget'] | null
 * loading: boolean
 * syncing: boolean
 * snackState: Parameters<CustomizedSnackbar>[0]['state']
 * toolTipOpen: boolean
 * }}
 */
const defaultState = {
  integrationEnabled: false,
  updateStatus: STATUS.Never,
  anchorEl: null,
  loading: true,
  syncing: false,
  snackState: { open: false, variant: 'success', message: '' },
  toolTipOpen: false,
  syncDisabled: false
}

/**
 * @param {{
 * contact: Object;
 * integrationObjectMappings: import('./integration-helpers').IntegrationObjectMappings;
 * refreshContactIntegrationMappings: (contactID: number) => void;
 * }} props
 */
export default function SalesForceButton (props) {
  const classes = useStyles()
  const { contact, integrationObjectMappings, refreshContactIntegrationMappings } = props
  const { user } = useContext(SessionContext)
  const [state, dispatch] = useReducer(reducer, defaultState)
  const salesForceObjectMappings = integrationObjectMappings.salesforce

  function handleClick (event) {
    dispatch({ anchorEl: state.anchorEl ? null : event.currentTarget })
  }

  useEffect(() => {
    let mounted = true
    if (salesforce.isPermitted(user)) {
      // Get salesforce integration setting
      salesforce.isEnabled().then(status => {
        if (mounted) {
          dispatch({ loading: status, integrationEnabled: status })
          if (status) {
            salesforce.getInstanceUrl().then(instanceUrl => {
              if (mounted) {
                dispatch({ loading: false, salesforceInstanceUrl: instanceUrl })
              }
            })
            salesforce.isDisabled().then(disabled => {
              dispatch({ syncDisabled: disabled })
            })
          } else {
            if (mounted) {
              dispatch({ syncDisabled: true })
            }
          }
        }
      })
    }
    return () => {
      mounted = false
    }
  }, [user])

  useEffect(() => {
    if (state.integrationEnabled && salesForceObjectMappings && contact) {
      dispatch({
        contactSalesforceID: salesForceObjectMappings.integration_object_id,
        updateStatus: salesForceObjectMappings.last_sync_timestamp >= contact.attributes.updated_timestamp ? STATUS.Updated : STATUS.Behind,
        syncing: false
      })
    } else {
      dispatch({ updateStatus: STATUS.Never, syncing: false })
    }
  }, [state.integrationEnabled, contact, salesForceObjectMappings])

  const syncToSalesforce = () => {
    dispatch({ syncing: true, anchorEl: null })
    salesforce.syncContact({ personID: contact.id }).then(res => {
      if (res.ok) {
        if (state.updateStatus === STATUS.Never) {
          refreshContactIntegrationMappings(contact.id)
        }
        dispatch({
          syncing: false,
          updateStatus: STATUS.Updated,
          snackState: {
            open: true,
            variant: 'success',
            message: 'Contact successfully synced to Salesforce'
          }
        })
      } else if (res.errors) {
        const error = res.errors?.[0] || { title: 'There was an error syncing to Salesforce' }
        const errorText = error.title
        const errorDetail = error.detail
        dispatch({
          syncing: false,
          snackState: {
            open: true,
            variant: 'error',
            message: errorText,
            detail: errorDetail
          }
        })
      }
    })
  }

  if (!contact) {
    return <></>
  }

  const allActions = {
    view: {
      actionText: 'View in Salesforce',
      handleAction: () => {
        dispatch({ anchorEl: null })
        salesforce.openInSalesforce(state.salesforceInstanceUrl, state.contactSalesforceID)
      }
    },
    sync: { actionText: 'Sync to Salesforce', handleAction: () => syncToSalesforce() }
  }

  let color = 'rgb(184,184,184)' // Grey
  let actions = []
  let tooltip = 'Salesforce is disabled or syncs are not allowed. Contact your administrator to allow Signals data to be synced to Salesforce.'
  const buttonStyle = {}
  buttonStyle.cursor = 'default'
  if (state.updateStatus === STATUS.Updated) {
    actions = [allActions.view]
    color = 'rgb(90, 240, 115)' // Green
    tooltip = 'Salesforce'
    buttonStyle.cursor = 'pointer'
  } else if (state.updateStatus === STATUS.Behind) {
    if (state.syncDisabled) {
      color = 'rgb(90, 240, 115)' // Green
      actions = [allActions.view]
    } else {
      color = '#fe9d10' // Orange
      actions = [allActions.view, allActions.sync]
    }
    tooltip = 'Salesforce'
    buttonStyle.cursor = 'pointer'
  } else if (state.updateStatus === STATUS.Never && !state.syncDisabled) {
    color = '#fe9d10' // Orange
    actions = [allActions.sync]
    tooltip = 'Salesforce'
    buttonStyle.cursor = 'pointer'
  }

  if (state.loading) {
    buttonStyle.cursor = 'default'
  }

  const coloredSalesforceSrc = salesforce.getColoredSVG(color)

  return (
    <>
      <CustomizedSnackbar
        autoHideDuration={state.snackState.variant === 'success' ? 3000 : 6000}
        state={state.snackState}
        handler={snackState => dispatch({ snackState })}
      />
      <div className={classes.ctn}>
        <Tooltip
          title={tooltip}
          open={state.toolTipOpen && !state.anchorEl}
          onOpen={() => dispatch({ toolTipOpen: true })}
          onClose={() => dispatch({ toolTipOpen: false })}
        >
          <div
            className={classes.buttons}
            style={buttonStyle}
            onClick={handleClick}
          >
            <Icon className={classes.iconRoot}>
              <img
                className={classes.imageIcon}
                src={coloredSalesforceSrc}
                alt='Salesforce cloud'
              />
            </Icon>
          </div>
        </Tooltip>
        {(state.loading || state.syncing) ? <CircularProgress size={16} className={classes.buttonProgress} thickness={8} /> : <></>}
        <Popper open={!!state.anchorEl} anchorEl={state.anchorEl} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom'
              }}
            >
              <Paper id='menu-list-grow'>
                {actions.map((action, index) => (
                  <ClickAwayListener
                    key={index}
                    onClickAway={() => dispatch({ anchorEl: null })}
                  >
                    <MenuItem
                      onClick={action.handleAction}
                      className={classes.menuItem}
                      dense
                    >
                      {action.actionText}
                    </MenuItem>
                  </ClickAwayListener>
                ))}
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </>
  )
}
