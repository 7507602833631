import { makeStyles } from '@material-ui/core/styles'
import { Card } from '@material-ui/core'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import { CardContents } from 'cf-components/table/CardContents'
import EmptyState from 'cf-components/EmptyState'
import { useContext, useEffect, useRef, useState } from 'react'
import { TableContext } from './TableProvider'
import useVisibility from 'cf-components/useVisibility'
import { ScrollBox } from 'cf-components/ScrollBox'
import Loader from 'library/loading/Loader'

const useStyles = makeStyles(theme => ({
  newRowsLoader: {
    height: '40px',
    margin: '30px'
  },
  card: {
    marginBottom: 12,
    padding: 15,
    display: 'grid',
    alignItems: 'center'
  },
  loadingDiv: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 70,
    height: 90
  },
  cardList: {
    padding: '0px 20px 20px 20px'
  },
  headerClass: {
    display: 'grid',
    padding: '5px 15px'
  },
  headerDiv: {
    display: 'flex'
  }
}))

function CardListHeader (): JSX.Element {
  const classes = useStyles()
  const { fields, style, handleSort, searchParams } = useContext(TableContext)

  if (!fields) {
    return <></>
  }

  const Icon = searchParams.sortOrder === 'desc' ? ArrowUpwardIcon : ArrowDownwardIcon

  return (
    <div className={classes.headerClass} style={style}>
      {fields.map((field, index) => {
        const sort = field.sort !== false
        return (
          <div
            key={index}
            className={classes.headerDiv}
            style={{ cursor: sort ? 'pointer' : 'auto' }}
            onClick={sort ? () => handleSort(field.name as string) : undefined}
          >
            {field.label}
            <Icon
              fontSize='small'
              style={{
                marginLeft: 5,
                fill: '#7c7c7c',
                visibility: (searchParams.sortColumn === field.name) ? 'visible' : 'hidden'
              }}
            />
          </div>
        )
      })}
    </div>
  )
}

export function CardList (): JSX.Element {
  const classes = useStyles()
  const { isLoading, rows, style, handlePageSize, total } = useContext(TableContext)
  const [localRows, setLocalRows] = useState([])
  const [localTotal, setLocalTotal] = useState(0)
  const length = localRows?.length > 0
  const [pageSize, setPageSize] = useState(20)
  const scrollRef = useRef(document.getElementById('lastScrollPoint'))
  const executeScroll = () => scrollRef?.current?.scrollIntoView()
  const [visible, visibleRef] = useVisibility<HTMLDivElement>()
  const getMore = () => {
    handlePageSize(pageSize + 12)
    setPageSize(pageSize + 12)
  }
  useEffect(() => {
    if (!isLoading) {
      setLocalRows(rows)
      setLocalTotal(total)
    }
  }, [isLoading, rows, total])
  HandleLoadMore({ visible, rows, total: localTotal, getMore, executeScroll })

  if (isLoading && !length) {
    return (
      <div className={classes.loadingDiv}>
        <Loader type='spinner' />
      </div>
    )
  }

  if (length) {
    return (
      <div style={{ paddingTop: 10 }}>
        <CardListHeader />
        <div>
          <ScrollBox>
            {localRows.map((row: any, index: number) => {
              return (
                <Card
                  id={index === (rows.length % 12 === 0 ? localRows.length - 12 : localRows.length - (localRows.length % 12)) ? 'lastScrollPoint' : undefined}
                  key={row.id}
                  className={classes.card}
                  style={style}
                >
                  <CardContents
                    key={row.id + 'card_contents'}
                    row={row}
                  />
                </Card>
              )
            })}
            {localRows.length > 0 && localRows.length < localTotal && (
              <div key='loader-row' className={classes.newRowsLoader} ref={visibleRef}>
                <Loader type='spinner' />
              </div>
            )}
          </ScrollBox>
        </div>
      </div>
    )
  }

  return (
    <div style={{ marginTop: 100 }}>
      <EmptyState
        message='No Records Found'
        sad
      />
    </div>
  )
}

function HandleLoadMore ({ visible, rows, total, getMore, executeScroll }: { visible: boolean, rows: any[], total: number, getMore: () => void, executeScroll: () => void }) {
  useEffect(() => {
    if (visible && rows.length !== total) {
      getMore()
    }
    const timer = setTimeout(() => {
      executeScroll()
    }, 2000);
    return () => clearTimeout(timer)
    // eslint-disable-next-line
  }, [visible])
}
