const homePageFlow = {
  flow: {
      data: {
          edges: [
          {
              data: {
              id: 'f3757b14-d913-4ece-b847-69ef7560ac1b',
              type: 'common'
              },
              source: 'start.default',
              target: '36b72810-03cf-4d55-bcb0-221bd8eb9ea2'
          },
          {
              data: {
              id: '3c3d428d-8544-4613-81a6-abbe52798804',
              type: 'common'
              },
              source: '36b72810-03cf-4d55-bcb0-221bd8eb9ea2.913d87ed-c3cf-4500-8883-ad69954f6da3',
              target: '1b3a4903-51a8-49d9-9b86-2df48af65368'
          },
          {
              data: {
              id: '39fdb72e-abd1-4769-b249-72bed02afbbf',
              type: 'common'
              },
              source: '1b3a4903-51a8-49d9-9b86-2df48af65368.default',
              target: '5bdbcec4-14a0-4794-834e-a9511ed3134a'
          },
          {
              data: {
              id: '3844d212-5891-4f04-8b2c-1a101dd0681a',
              type: 'common'
              },
              source: '36b72810-03cf-4d55-bcb0-221bd8eb9ea2.3ea5755d-1f11-4a9f-923f-ba2b16596c3f',
              target: '2f04dfaa-13a2-46bd-81fb-f4b6127ebf02'
          },
          {
              data: {
              id: '3b6af440-4afc-4e32-b1fc-720a949a0072',
              type: 'common'
              },
              source: '5bdbcec4-14a0-4794-834e-a9511ed3134a.3aba955e-c8af-4f1a-9376-902d77281002',
              target: '2f04dfaa-13a2-46bd-81fb-f4b6127ebf02'
          },
          {
              data: {
              id: 'cafd415b-a922-4544-98f9-75fd2310c3cf',
              type: 'common'
              },
              source: '2f04dfaa-13a2-46bd-81fb-f4b6127ebf02.default',
              target: 'bea7ee35-0a46-4c29-a2e4-d24d2a539cf1'
          },
          {
              data: {
              id: '8c3674ad-24a9-4788-ace2-74134c117e3d',
              type: 'common'
              },
              source: 'bea7ee35-0a46-4c29-a2e4-d24d2a539cf1.default',
              target: 'b5da5eca-17c3-44b7-a613-eb8e3c0795d6'
          },
          {
              data: {
              id: '06e9738c-809d-4b02-88d5-344aeed63d3f',
              type: 'common'
              },
              source: 'b5da5eca-17c3-44b7-a613-eb8e3c0795d6.bcbae0e0-502e-463c-b8df-4720acdb69ca',
              target: 'c97f583f-f783-4018-9529-cdec9c9016ba'
          },
          {
              data: {
              id: 'cc224bee-d12f-4639-99d1-b43b1d9c1b0c',
              type: 'common'
              },
              source: 'c97f583f-f783-4018-9529-cdec9c9016ba.invalid',
              target: '766aa259-6d3e-420b-bd63-198c2f16a190'
          },
          {
              data: {
              id: '49e66c98-11ba-4ef3-932a-b0213856f9ce',
              type: 'common'
              },
              source: '766aa259-6d3e-420b-bd63-198c2f16a190.default',
              target: 'a160a6b6-7443-4da7-8d24-b836952eaada'
          },
          {
              data: {
              id: '50979cfc-af18-454c-a282-3dc175ac65b6',
              type: 'common'
              },
              source: 'a160a6b6-7443-4da7-8d24-b836952eaada.default',
              target: '92a2cf86-b48c-484f-a575-ac1b30be874c'
          },
          {
              data: {
              id: 'efa46b03-6c75-4794-99d9-ed1ba047cc5f',
              type: 'common'
              },
              source: 'c97f583f-f783-4018-9529-cdec9c9016ba.valid',
              target: '6fb78d3e-b196-4a88-936a-a6dcda2a8f39'
          },
          {
              data: {
              id: 'c4fc794b-e833-4ef9-b626-f6a9088ee354',
              type: 'common'
              },
              source: '6fb78d3e-b196-4a88-936a-a6dcda2a8f39.default',
              target: '645d57cd-f41e-4e8c-9b20-152caa6603f5'
          },
          {
              data: {
              id: 'b44bf37e-82f4-4eef-ad0e-c1e610b0f801',
              type: 'common'
              },
              source: '645d57cd-f41e-4e8c-9b20-152caa6603f5.complete',
              target: '687331ba-3ad5-4001-8b72-69fddae82f91'
          },
          {
              data: {
              id: 'a60e26c8-e289-42f0-b2b4-6521192d475c',
              type: 'common'
              },
              source: '687331ba-3ad5-4001-8b72-69fddae82f91.default',
              target: 'c678fdb5-bf69-41c8-acee-fc2bc716c087'
          },
          {
              data: {
              id: '1a418a79-6e99-41e3-ac6f-3d4cd7454344',
              type: 'common'
              },
              source: 'c678fdb5-bf69-41c8-acee-fc2bc716c087.default',
              target: 'ae15e577-2c74-4585-9372-849baf7ac749'
          },
          {
              data: {
              id: '6830611e-a8df-4269-be07-82a850510069',
              type: 'common'
              },
              source: 'ae15e577-2c74-4585-9372-849baf7ac749.response',
              target: '13976e26-0b64-4dbc-9e1b-5935d878f4e5'
          },
          {
              data: {
              id: '51b581c7-48bc-4afc-a634-27082d56bfe3',
              type: 'common'
              },
              source: '13976e26-0b64-4dbc-9e1b-5935d878f4e5.default',
              target: '8ee92227-99e3-4608-b7f8-3a34906a4d74'
          },
          {
              data: {
              id: '81eaefb1-275e-4230-839a-c38028112524',
              type: 'common'
              },
              source: '8ee92227-99e3-4608-b7f8-3a34906a4d74.default',
              target: 'ffdf46eb-940e-4f2b-90df-aa48961374a8'
          }
          ],
          nodes: [
          {
              id: 'start',
              top: 0,
              left: 0,
              type: 'start',
              ports: [
              {
                  id: 'default'
              }
              ]
          },
          {
              id: '36b72810-03cf-4d55-bcb0-221bd8eb9ea2',
              top: 210,
              body: '<p>Call to action. Ask a question that will hook your audience</p><p><br></p><p>Example: Hey there! Welcome to {company_name} How can I help?</p><p><br></p><p>Tip!: Customize the buttons for your visitors. If you have agents you can offer live chat, if there are pain points your product solves those can be great buttons to provide your agents with more context or better direct the site visitor.</p>',
              left: 0,
              type: 'options',
              ports: [
              {
                  id: '3ea5755d-1f11-4a9f-923f-ba2b16596c3f',
                  label: 'Demo',
                  _reactKey: '750271f7-8cca-40d8-b182-4c57c79ea0eb'
              },
              {
                  id: '913d87ed-c3cf-4500-8883-ad69954f6da3',
                  label: 'Pain points',
                  _reactKey: '75402086-3ae4-4870-9186-c2ff89c612ca'
              }
              ],
              buttons: [
              {
                  id: '3ea5755d-1f11-4a9f-923f-ba2b16596c3f',
                  label: 'Demo',
                  _reactKey: '750271f7-8cca-40d8-b182-4c57c79ea0eb'
              },
              {
                  id: '913d87ed-c3cf-4500-8883-ad69954f6da3',
                  label: 'Pain points',
                  _reactKey: '75402086-3ae4-4870-9186-c2ff89c612ca'
              }
              ],
              captureTimes: 3,
              mapAttribute: false,
              responseType: 'buttons',
              keywordOptions: [],
              secondResponse: 'Still didn\'t get that. Go ahead and try again',
              invalidResponse: 'Sorry, I didn\'t understand that. Please try again'
          },
          {
              id: '1b3a4903-51a8-49d9-9b86-2df48af65368',
              top: 830,
              body: '<p>Provide context/content for pain points</p>',
              left: 0,
              type: 'chat',
              ports: [
              {
                  id: 'default',
                  _reactKey: 'c50bb97e-2da5-4580-bfa0-bcbe6f1d5f89'
              }
              ]
          },
          {
              id: '5bdbcec4-14a0-4794-834e-a9511ed3134a',
              top: 1230,
              body: '<p>What  else can I help you with?</p>',
              left: 0,
              type: 'options',
              ports: [
              {
                  id: 'd9ac5d30-30a7-42eb-91c6-9bb39b1e1b15',
                  label: 'Talk to an expert',
                  _reactKey: '05eab8ef-f457-4855-a77d-302e646a3b49'
              },
              {
                  id: '3aba955e-c8af-4f1a-9376-902d77281002',
                  label: 'Book a demo',
                  _reactKey: '981f85ab-28c3-4076-a951-d74920e18faf'
              }
              ],
              buttons: [
              {
                  id: 'd9ac5d30-30a7-42eb-91c6-9bb39b1e1b15',
                  label: 'Talk to an expert',
                  _reactKey: '05eab8ef-f457-4855-a77d-302e646a3b49'
              },
              {
                  id: '3aba955e-c8af-4f1a-9376-902d77281002',
                  label: 'Book a demo',
                  _reactKey: '981f85ab-28c3-4076-a951-d74920e18faf'
              }
              ],
              captureTimes: 3,
              mapAttribute: false,
              responseType: 'buttons',
              keywordOptions: [],
              secondResponse: 'Still didn\'t get that. Go ahead and try again',
              invalidResponse: 'Sorry, I didn\'t understand that. Please try again'
          },
          {
              id: '2f04dfaa-13a2-46bd-81fb-f4b6127ebf02',
              top: 510,
              body: '<p><span style=\'color: rgb(0, 0, 0);\'>I just need you to answer a few questions, so I can [</span><em>find you the best sales rep</em><span style=\'color: rgb(0, 0, 0);\'>]</span></p>',
              left: -880,
              type: 'chat',
              ports: [
              {
                  id: 'default',
                  _reactKey: 'e1994245-ea34-4832-9ed6-bd58087ba7ee'
              }
              ]
          },
          {
              id: 'bea7ee35-0a46-4c29-a2e4-d24d2a539cf1',
              top: 760,
              body: '',
              left: -880,
              type: 'alert_agent',
              ports: [
              {
                  id: 'default',
                  label: 'Next',
                  _reactKey: '980fea70-2c3d-4717-adb8-aa50b941199b'
              }
              ],
              notification: {
              title: 'Book Demo',
              message: 'Site visitor booking demo'
              },
              routeOptions: [
              {
                  type: 'round_robin',
                  method: 'single_agent',
                  objectIDs: null
              }
              ],
              alertUnavailableAgents: false
          },
          {
              id: 'b5da5eca-17c3-44b7-a613-eb8e3c0795d6',
              top: 1010,
              body: '<p>Quantifying Question #1 (name, description of question type, industry, integration, )</p>',
              left: -880,
              type: 'options',
              ports: [
              {
                  id: 'bcbae0e0-502e-463c-b8df-4720acdb69ca',
                  label: 'Qualifying Option',
                  _reactKey: '2beef247-96ca-4447-9bb0-0e6d1148d422'
              }
              ],
              buttons: [
              {
                  id: 'bcbae0e0-502e-463c-b8df-4720acdb69ca',
                  label: 'Qualifying Option',
                  _reactKey: '2beef247-96ca-4447-9bb0-0e6d1148d422'
              }
              ],
              attribute: 'full_name',
              captureTimes: 3,
              mapAttribute: true,
              responseType: 'buttons',
              keywordOptions: [],
              secondResponse: 'Still didn\'t get that. Go ahead and try again',
              invalidResponse: 'Sorry, I didn\'t understand that. Please try again'
          },
          {
              id: 'c97f583f-f783-4018-9529-cdec9c9016ba',
              top: 1280,
              body: '<p><span style=\'color: rgb(0, 0, 0);\'>What\'s the best email address to send your calendar invite?</span></p>',
              left: -880,
              type: 'email',
              ports: [
              {
                  id: 'invalid',
                  label: 'Failed to capture email',
                  _reactKey: '8b14dc10-bc09-4074-95b4-a30e396f16e7'
              },
              {
                  id: 'valid',
                  label: 'Successfully captured email',
                  _reactKey: '5c153347-cfc4-40c8-9133-c23a39563d21'
              }
              ],
              unwanted: {
              spam: true,
              blocked: true,
              personal: true
              },
              captureTimes: 3,
              secondResponse: '<p><span style=\'color: rgb(0, 0, 0);\'>Last try; what\'s your </span>[<em>work</em>]<span style=\'color: rgb(0, 0, 0);\'> email address?</span></p>',
              invalidResponse: '<p>I don\'t recognize that as a valid email address. Please try again</p>',
              unwantedResponse: '<p>Please provide a valid business email</p>'
          },
          {
              id: '766aa259-6d3e-420b-bd63-198c2f16a190',
              top: 1640,
              body: '<p>Looks like you\'re not ready to book a demo. In the meantime, Feel free to check out <a href=\'https://beta.chatfunnels.com/Enter%20link%20here...\' rel=\'noopener noreferrer\' target=\'_blank\'>our resources</a> for useful insights on [<em>business value</em>].</p>',
              left: -670,
              type: 'chat',
              ports: [
              {
                  id: 'default',
                  _reactKey: '0ff5669b-8807-4ccf-9241-aabdb2100c2b'
              }
              ]
          },
          {
              id: 'a160a6b6-7443-4da7-8d24-b836952eaada',
              top: 1910,
              body: '',
              left: -670,
              type: 'conversation_status',
              ports: [
              {
                  id: 'default',
                  _reactKey: 'c88ea088-f775-4dae-9b19-678b961fbdee'
              }
              ],
              status: 'auto-close',
              closedMessage: ''
          },
          {
              id: '92a2cf86-b48c-484f-a575-ac1b30be874c',
              top: 2150,
              body: '',
              left: -670,
              type: 'end_flow',
              ports: []
          },
          {
              id: '6fb78d3e-b196-4a88-936a-a6dcda2a8f39',
              top: 1550,
              body: '',
              left: -1210,
              tags: [],
              type: 'tag',
              ports: [
              {
                  id: 'default',
                  _reactKey: '449ee9c7-0fcf-4b79-8746-2cfa4dd69da4'
              }
              ]
          },
          {
              id: '645d57cd-f41e-4e8c-9b20-152caa6603f5',
              top: 1750,
              body: '<p><span style=\'color: rgb(0, 0, 0);\'>Great! Pick a time below to book a meeting with </span>[<em>Team or Individual</em>]</p>',
              left: -1210,
              type: 'calendar',
              ports: [
              {
                  id: 'complete',
                  label: 'Meeting Scheduled',
                  _reactKey: 'e97c1aac-381e-4445-a5b2-55da4a23543a'
              }
              ],
              method: 'single_agent',
              team_id: '',
              email_id: '',
              user_ids: [],
              route_type: '',
              meeting_type: '',
              routeOptions: [
              {
                  type: '',
                  method: 'single_agent',
                  objectIDs: []
              }
              ],
              follow_up_email: false,
              text_message_reminders: false
          },
          {
              id: '687331ba-3ad5-4001-8b72-69fddae82f91',
              top: 1940,
              body: '',
              left: -1210,
              tags: [],
              type: 'tag',
              ports: [
              {
                  id: 'default',
                  _reactKey: '22bdf4fd-d3be-4505-8fa6-e1f04ad17f50'
              }
              ]
          },
          {
              id: 'c678fdb5-bf69-41c8-acee-fc2bc716c087',
              top: 2190,
              body: '<p>That\'s all!</p>',
              left: -1210,
              type: 'chat',
              ports: [
              {
                  id: 'default',
                  _reactKey: '429b280d-8331-4b4b-ab95-7bd0d0b0c2f3'
              }
              ]
          },
          {
              id: 'ae15e577-2c74-4585-9372-849baf7ac749',
              top: 2440,
              body: '<p><span style=\'color: rgb(0, 0, 0);\'>We\'re looking forward to your demo! Feel free to leave a message for the sales rep below.</span></p>',
              left: -1210,
              type: 'options',
              ports: [
              {
                  id: 'response',
                  label: 'Visitor Response',
                  _reactKey: '1354eb71-d899-4f71-a1fa-5d1017c929ed'
              }
              ],
              buttons: [],
              captureTimes: 3,
              mapAttribute: false,
              responseType: 'free_response',
              keywordOptions: [],
              secondResponse: 'Still didn\'t get that. Go ahead and try again',
              invalidResponse: 'Sorry, I didn\'t understand that. Please try again'
          },
          {
              id: '13976e26-0b64-4dbc-9e1b-5935d878f4e5',
              top: 2700,
              body: '<p><span style=\'color: rgb(0, 0, 0);\'>Great, I\'ll pass that along!</span></p>',
              left: -1210,
              type: 'chat',
              ports: [
              {
                  id: 'default',
                  _reactKey: 'b3184b89-ace0-443a-b4af-ff6946ce6e70'
              }
              ]
          },
          {
              id: '8ee92227-99e3-4608-b7f8-3a34906a4d74',
              top: 2940,
              body: '',
              left: -1210,
              type: 'conversation_status',
              ports: [
              {
                  id: 'default',
                  _reactKey: '4ab5bbe5-020f-4b10-aa87-1a2060e99280'
              }
              ],
              status: 'open',
              closedMessage: ''
          },
          {
              id: 'ffdf46eb-940e-4f2b-90df-aa48961374a8',
              top: 3190,
              body: '',
              left: -1210,
              type: 'end_flow',
              ports: []
          }
          ],
          ports: []
      }
  },
  targeting: { triggers: [], triggerOn: 'all_visitors', useTriggers: false },
  frequency: 'every time',
  template: {
      kind: 'flow',
      teams: [
          {
          away: 'Sorry, no one is available to chat right now',
          label: 'Click here',
          method: 'single_agent',
          teamId: null,
          welcome: 'Routing you to an agent now',
          userIds: [],
          routeMethod: 'round_robin'
          }
      ],
      botDelay: 0,
      urlMatches: '',
      welcomeMessage: 'Welcome to our site!',
      availabilityCriteria: 'both'
      },
  promptStyle: 'big'
}

export default homePageFlow
