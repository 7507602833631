import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { TableCell } from '@material-ui/core'
import { Link } from 'react-router-dom'
import EnhancedTable from '../../../components/EnhancedTable'

const useStyles = makeStyles(theme => ({
  percent: {
    color: 'rgba(0,0,0,0.5)',
    paddingTop: 2
  },
  botName: {
    fontSize: '1.1em',
    fontWeight: 600,
    textDecoration: 'none',
    color: 'rgba(0,0,0,0.8)'
  },
  link: {
    textDecoration: 'none'
  }
}))

const headCells = [
  { id: 'name', align: 'left', disablePadding: false, label: 'Form Name' },
  { id: 'page_views', align: 'center', disablePadding: false, label: 'Page Views', paddingLeft: 30 },
  { id: 'submissions', align: 'center', disablePadding: false, label: 'Submissions', paddingLeft: 30 },
  { id: 'prompts', align: 'center', disablePadding: false, label: 'Prompts', paddingLeft: 30 },
  { id: 'live_chats', align: 'center', disablePadding: false, label: 'Live Chats', paddingLeft: 30 },
  { id: 'meetings_booked', align: 'center', disablePadding: false, label: 'Meetings Booked', paddingLeft: 30 }
]

function FormPerformanceTable (props) {
  const rows = props.rows.map(row => {
    return (
      {
        id: row.id,
        name: row.attributes.name,
        page_views: row.attributes.page_views,
        prompts: row.attributes.prompts,
        meetings_booked: row.attributes.meetings_booked,
        live_chats: row.attributes.live_chats,
        submissions: row.attributes.submissions,
        has_followup: row.attributes.has_followup
      })
  })

  function CustomRow (props) {
    const classes = useStyles()
    const row = props.row
    const submissionPercent = row.has_followup ? (Math.round((row.submissions / row.page_views) * 100) || 0) + '%' : '---'
    const promptPercent = row.has_followup ? (Math.round((row.prompts / row.submissions) * 100) || 0) + '%' : '---'
    const liveChatPercent = row.has_followup ? (Math.round((row.live_chats / row.prompts) * 100) || 0) + '%' : '---'
    const meetingPercent = row.has_followup ? (Math.round((row.meetings_booked / row.prompts) * 100) || 0) + '%' : '---'

    return (
      <>
        <TableCell>
          <Link to={`/forms/${row.id}`} className={classes.link}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div>
                <div style={{ fontSize: '1.1em', color: 'rgba(0,0,0,0.8)' }}>
                  {row.name}
                </div>
              </div>
            </div>
          </Link>
        </TableCell>
        <TableCell align='center' style={{}}>
          <div>{row.page_views}</div>
          <div className={classes.percent}>---</div>
        </TableCell>
        <TableCell align='center'>
          <div>{row.submissions}</div>
          <div className={classes.percent}>
            {submissionPercent}
          </div>
        </TableCell>
        <TableCell align='center'>
          <div>{row.prompts}</div>
          <div className={classes.percent}>
            {promptPercent}
          </div>
        </TableCell>
        <TableCell align='center'>
          <div>{row.live_chats}</div>
          <div className={classes.percent}>
            {liveChatPercent}
          </div>
        </TableCell>
        <TableCell align='center'>
          <div>{row.meetings_booked}</div>
          <div className={classes.percent}>
            {meetingPercent}
          </div>
        </TableCell>
      </>
    )
  }

  function columnWidths () {
    return (
      <colgroup>
        <col style={{ width: '5%' }} />
        <col style={{ width: '23%' }} />
        <col style={{ width: '12%' }} />
        <col style={{ width: '12%' }} />
        <col style={{ width: '12%' }} />
        <col style={{ width: '12%' }} />
        <col style={{ width: '12%' }} />
      </colgroup>
    )
  }

  return (
    <EnhancedTable
      headCells={headCells}
      selected={props.selected}
      setSelected={props.setSelected}
      rows={rows}
      CustomRow={CustomRow}
      columnWidths={columnWidths}
      orderBy='page_views'
      order='desc'
      loading={props.loading}
    />
  )
}

export default FormPerformanceTable
