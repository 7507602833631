import { useEffect, useContext, useReducer, useMemo, MouseEventHandler, CSSProperties } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Icon from '@material-ui/core/Icon'
import Popper from '@material-ui/core/Popper'
import MenuItem from '@material-ui/core/MenuItem'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import CircularProgress from '@material-ui/core/CircularProgress'
import Tooltip from '@material-ui/core/Tooltip'
import CustomizedSnackbar from 'components/CustomizedSnackbar'
import { SessionContext } from 'session-context'
import { eloqua, IntegrationObjectMappings } from './integration-helpers'

const STATUS = {
  Never: 0,
  Updated: 1,
  Behind: 2
}

const useStyles = makeStyles(theme => ({
  buttons: {
    fontFamily: 'Poppins, sans serif',
    fontSize: 10,
    height: 23,
    width: 21,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 500,
    cursor: 'pointer'
  },
  buttonProgress: {
    position: 'absolute',
    top: 2,
    left: 2
  },
  ctn: {
    position: 'relative'
  },
  imageIcon: {
    height: 18
  },
  iconRoot: {
    height: 24,
    display: 'flex',
    alignItems: 'center'
  },
  menuItem: {
    fontFamily: 'Poppins, sans serif',
    fontWeight: 500,
    fontSize: '10px',
    lineHeight: '14px'
  }
}))

/**
 * @template {typeof defaultState} T the state
 * @param {T} state
 * @param {Partial<T>} action
 */
function reducer<T extends typeof defaultState> (state: T, action: Partial<T>): T {
  return { ...state, ...action }
}

const defaultState: {
  integrationEnabled: boolean
  updateStatus: typeof STATUS[keyof typeof STATUS]
  eloquaObjectID?: string
  eloquaInstanceID?: string
  anchorEl: React.MouseEvent['currentTarget'] | null
  loading: boolean
  syncing: boolean
  snackState: Parameters<typeof CustomizedSnackbar>[0]['state']
  toolTipOpen: boolean
} = {
  integrationEnabled: false,
  updateStatus: STATUS.Never,
  anchorEl: null,
  loading: false,
  syncing: false,
  snackState: { open: false, variant: 'success', message: '' },
  toolTipOpen: false
}

export default function EloquaButton (props: {
  contact: Record<string, any>
  integrationObjectMappings: IntegrationObjectMappings
  refreshContactIntegrationMappings: (contactID: number) => void
}): JSX.Element {
  const classes = useStyles()
  const { contact, integrationObjectMappings, refreshContactIntegrationMappings } = props
  const { user } = useContext(SessionContext)
  const [state, dispatch] = useReducer(reducer, defaultState)
  const objectMappings = integrationObjectMappings.eloqua

  const handleClick: MouseEventHandler<HTMLDivElement> = event => {
    dispatch({ anchorEl: state.anchorEl ? null : event.currentTarget })
  }

  useEffect(() => {
    let mounted = true
    if (eloqua.isPermitted(user)) {
      // Get Eloqua integration setting
      eloqua.isEnabled().then(status => {
        if (mounted) {
          dispatch({ loading: false, integrationEnabled: status })
          // dispatch({ loading: status, integrationEnabled: status })
          // if (status) {
          //   eloqua.getInstanceID().then(hubSpotInstanceID => {
          //     if (mounted) {
          //       dispatch({ loading: false, hubSpotInstanceID })
          //     }
          //   })
          // }
        }
      })
    }
    return () => { mounted = false }
  }, [user])

  useEffect(() => {
    if (state.integrationEnabled && objectMappings && contact) {
      dispatch({
        eloquaObjectID: objectMappings.integration_object_id,
        updateStatus: objectMappings.last_sync_timestamp >= contact.attributes.updated_timestamp ? STATUS.Updated : STATUS.Behind,
        syncing: false
      })
    } else {
      dispatch({ updateStatus: STATUS.Never, syncing: false })
    }
  }, [state.integrationEnabled, contact, objectMappings])

  const syncToEloqua = () => {
    dispatch({ syncing: true, anchorEl: null })
    eloqua.syncContact({ personID: contact.id }).then(status => {
      if (status) {
        if (state.updateStatus === STATUS.Never) {
          refreshContactIntegrationMappings(contact.id)
        }
        dispatch({
          syncing: false,
          updateStatus: STATUS.Updated,
          snackState: {
            open: true,
            variant: 'success',
            message: 'Contact successfully synced to Eloqua'
          }
        })
      } else {
        dispatch({
          syncing: false,
          snackState: {
            open: true,
            variant: 'error',
            message: 'There was an error syncing to Eloqua'
          }
        })
      }
    })
  }

  const allActions = {
    view: {
      actionText: 'Up-to-Date with Eloqua', // TODO: Change this to 'View in Eloqua' once viewing is built
      handleAction: () => {
        dispatch({ anchorEl: null })
        // eloqua.openInEloqua(state.eloquaInstanceID, state.eloquaObjectID)
      }
    },
    sync: { actionText: 'Sync to Eloqua', handleAction: () => syncToEloqua() }
  }

  let color = '#fe9d10' // Orange
  let actions: typeof allActions[keyof typeof allActions][] = []
  if (state.updateStatus === STATUS.Updated) {
    color = 'rgb(90, 240, 115)' // Green
  } else if (state.updateStatus === STATUS.Behind) {
    actions = [/* allActions.view,  */allActions.sync]
  } else if (state.updateStatus === STATUS.Never) {
    actions = [allActions.sync]
  }

  const coloredEloquaSrc = useMemo(() => eloqua.getColoredSVG(color), [color])
  const buttonStyle: CSSProperties = useMemo(() => ({ cursor: state.loading ? 'default' : undefined }), [state.loading])

  if (!state.integrationEnabled || !contact) {
    return <></>
  }

  return (
    <>
      <CustomizedSnackbar
        autoHideDuration={state.snackState.variant === 'success' ? 3000 : 6000}
        state={state.snackState}
        handler={snackState => dispatch({ snackState })}
      />
      <div className={classes.ctn}>
        <Tooltip
          title='Eloqua'
          open={state.toolTipOpen && !state.anchorEl}
          onOpen={() => dispatch({ toolTipOpen: true })}
          onClose={() => dispatch({ toolTipOpen: false })}
        >
          <div
            className={classes.buttons}
            style={buttonStyle}
            onClick={handleClick}
          >
            <Icon className={classes.iconRoot}>
              <img
                className={classes.imageIcon}
                src={coloredEloquaSrc}
                alt='Eloqua Logo'
              />
            </Icon>
          </div>
        </Tooltip>
        {(state.loading || state.syncing) ? <CircularProgress size={16} className={classes.buttonProgress} thickness={8} /> : <></>}
        <Popper open={!!state.anchorEl} anchorEl={state.anchorEl} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom'
              }}
            >
              <Paper id='menu-list-grow'>
                {actions.map((action, index) => (
                  <ClickAwayListener
                    key={index}
                    onClickAway={() => dispatch({ anchorEl: null })}
                  >
                    <MenuItem
                      onClick={action.handleAction}
                      className={classes.menuItem}
                      dense
                    >
                      {action.actionText}
                    </MenuItem>
                  </ClickAwayListener>
                ))}
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </>
  )
}
