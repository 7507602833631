import { makeStyles } from '@material-ui/core/styles'
import Fab from '@material-ui/core/Fab'
import PricingInformation from './PricingInformation'
import { ReactComponent as YellowLockIcon } from 'img/icon-material-lock.svg'
import { useContext } from 'react'
import { SessionContext } from 'session-context'

const useStyles = makeStyles(theme => ({
    rowStyle: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        textAlign: 'center'
    },
    title: {
        fontSize: '28px',
        fontWeight: 'bold',
        fontFamily: 'poppins',
        display: 'flex',
        justifyContent: 'center'
    },
    messageText: {
        fontSize: '20px',
        fontFamily: 'poppins',
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center'
    },
    buttonDiv: {
        display: 'flex',
        justifyContent: 'center'
    },
    reactivate: {
        paddingRight: '92px',
        paddingLeft: '92px',
        fontSize: '20px',
        fontFamily: 'poppins',
        textTransform: 'none',
        borderRadius: '28px'
    },
    seeOther: {
        paddingRight: '68px',
        paddingLeft: '68px',
        fontSize: '20px',
        fontFamily: 'poppins',
        textTransform: 'none',
        borderRadius: '28px',
        border: '0px',
        color: '#975ce6'
    }
  }))

export default function AccountOnHoldState (props: any) {
    const classes = useStyles()
    const { user } = useContext(SessionContext)
    const setModalStage = (stage: number) => {
      props.setModalStage(stage)
    }
    return (
      <div className={classes.rowStyle}>
        <div style={{ paddingRight: '30px', width: '460px' }}>
          <div style={{ display: 'flex', paddingTop: '200px', justifyContent: 'center', textAlign: 'center' }}>
            <YellowLockIcon />
          </div>
          <div className={classes.title} style={{ paddingTop: '20px', paddingBottom: '20px' }}>
            Your account is on hold
          </div>
          <div className={classes.messageText}>
            {user.attributes.perms.semi_admin ? (
              <>
                Your account is currently on HOLD. See your plan
                details and reactivate your account to start using
                Signals again.
              </>
            ) : (
              <>
                It looks like your subscription has beeen put on hold! Contact an administrator <br />
                to reactivate your subscription, or click the help button at the top right <br />
                corner to speak to a support agent if you believe this has happened by mistake
              </>
            )}
          </div>
          <div className={classes.buttonDiv} style={{ paddingBottom: '230px', paddingTop: '50px' }}>
            {user.attributes.perms.semi_admin && (
              <Fab
                className={classes.reactivate}
                color='primary'
                size='large'
                variant='extended'
                onClick={() => setModalStage(1)}
              >
                Reactivate
              </Fab>
            )}
          </div>
        </div>
        <PricingInformation planType={1} />
      </div>
    )
  }
