import React, { useState, useReducer } from 'react'
import { makeStyles } from '@material-ui/styles'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Radio from '@material-ui/core/Radio'
import TextField from '@material-ui/core/TextField'
import { sendLoginSetupCode, validateAndEnableMFA } from 'api/mfa'
import { validEmail, validPhoneNumber } from 'pages/users/MfaModals'

const useStyles = makeStyles(theme => ({
  radioCtn: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    width: 'max-content'
  },
  inputCtn: {
    marginTop: 8
  }
}))

/**
 * @template {{ type: 'phone'|'email'; value: string; touched: boolean; }} T
 * @param {T} state
 * @param {Partial<T>} options
 * @returns {T}
 */
const reducer = (state, options) => ({ ...state, ...options })

const MFASetupForm = props => {
  const classes = useStyles()
  const [inputState, inputDispatch] = useReducer(reducer, { type: 'phone', value: '', touched: false })
  const [setupCode, setSetupCode] = useState(undefined)
  const [setupCodeInput, setSetupCodeInput] = useState('')
  const [attemptedEnable, setAttemptedEnable] = useState(false)
  const mfaCoupon = props.mfaCoupon
  const validInputValue = inputState.type === 'email' ? validEmail(inputState.value) : validPhoneNumber(inputState.value)

  const buttonFields = !setupCode ? {
    text: 'Send setup code',
    action: () => {
      sendLoginSetupCode({
        mfa_coupon: mfaCoupon,
        ['mfa_' + inputState.type]: inputState.value
      }).then(res => {
        setSetupCode(res.code)
      })
    },
    disabled: !validInputValue
  } : {
    text: 'Enable MFA',
    action: () => {
      if (setupCode === setupCodeInput) {
        validateAndEnableMFA({
          mfa_code: setupCodeInput,
          mfa_coupon: mfaCoupon,
          ['mfa_' + inputState.type]: inputState.value
        }).then(res => {
          setSetupCodeInput('')
          props.onSubmit({
            mfa_code: setupCode, mfa_coupon: mfaCoupon, remember_device: false
          }, () => { })
        })
      } else {
        setAttemptedEnable(true)
      }
    },
    disabled: !setupCodeInput.length || attemptedEnable
  }

  return (
    <div style={{ width: '100%' }}>
      {setupCode ? (
        <div style={{ padding: 20, paddingTop: 30 }}>
          <Typography>Enter the code you received via {inputState.type === 'phone' ? 'text' : 'email'} to proceed.</Typography>
          <br />
          <TextField
            fullWidth
            label='MFA code'
            value={setupCodeInput}
            variant='outlined'
            onChange={e => {
              setAttemptedEnable(false)
              setSetupCodeInput(e.target.value.trim())
            }}
            error={attemptedEnable}
            helperText={attemptedEnable && 'Invalid MFA code'}
          />
          <br />
          <br />
          <Button
            onClick={buttonFields.action}
            fullWidth
            variant='contained'
            color='primary'
            disabled={buttonFields.disabled}
          >
            {buttonFields.text}
          </Button>
        </div>
      ) : (
        <div style={{ padding: 10, paddingTop: 30 }}>
          <Typography>Set up text-based multi-factor authentication in order to log in. <br /><br />Choose how you would like to receive your security codes.</Typography>
          <br />
          <div className={classes.radioCtn} onClick={() => inputState.type !== 'phone' && inputDispatch({ type: 'phone', value: '', touched: false })}>
            <Radio
              checked={inputState.type === 'phone'}
              color='primary'
            />
            Text
          </div>
          <div className={classes.radioCtn} onClick={() => inputState.type !== 'email' && inputDispatch({ type: 'email', value: '', touched: false })}>
            <Radio
              checked={inputState.type === 'email'}
              color='primary'
            />
            Email
          </div>
          <div className={classes.inputCtn}>
            <TextField
              fullWidth
              variant='outlined'
              autoComplete={inputState.type === 'phone' ? 'tel' : 'email'}
              label={inputState.type === 'phone' ? 'Cellphone Number' : 'Email Address'}
              value={inputState.value}
              onChange={e => inputDispatch({ value: e.target.value.trim() })}
              onBlur={() => inputDispatch({ touched: true })}
              error={inputState.touched && !validInputValue}
              helperText={inputState.touched && !validInputValue && `Please enter a valid ${inputState.type === 'phone' ? 'Cellphone Number' : 'Email Address'}`}
            />
          </div>
          <br />
          <br />
          <Button
            onClick={buttonFields.action}
            fullWidth
            variant='contained'
            color='primary'
            disabled={buttonFields.disabled}
          >
            {buttonFields.text}
          </Button>
        </div>
      )}
    </div>
  )
}

export default MFASetupForm
