import { useContext, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ExplanationCard from './ExplanationCard'
import { Card, CardHeader, CardContent, Typography, Divider, MenuItem, Select, Tooltip } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import Collapse from '@material-ui/core/Collapse'
import clsx from 'clsx'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Select as NewSelect } from 'cf-components/material-wrappers/MaterialComponents'
import MessageDiv from 'library/divs/MessageDiv'
import { Link } from 'react-router-dom'
import { Icon } from 'library/materialUI'
import { SessionContext } from 'session-context'

const useStyles = makeStyles(theme => ({
  messagePage: {
    display: 'grid',
    gridTemplateColumns: 'auto 400px',
    width: '100%',
    gridGap: 15
  },
  card: {
    border: '1px solid rgba(142,90,226,0.5)',
    marginBottom: 15,
    overflow: 'visible !important'
  },
  header: {
    cursor: 'pointer',
    padding: 10
  },
  cardTitle: {
    fontSize: '1.1em'
  },
  cardAction: {
    margin: 0
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  },
  subHeading: {
    margin: '10px 20px 20px 10px'
  },
  divider: {
    backgroundColor: theme.palette.primary.main,
    marginBottom: 10
  },
  content: {
    display: 'grid',
    gridTemplateColumns: 'auto 400px',
    borderTop: '1px solid rgba(0,0,0,0.1)',
    gridGap: 20
  },
  cardContentRoot: {
    padding: 0,
    '&:last-child': {
      paddingBottom: 0
    }
  },
  settings: {
    backgroundColor: 'white',
    padding: 20
  },
  errorMessage: {
    display: 'flex',
    padding: 5
  },
  codeDiv: {
    padding: 10,
    width: 700,
    border: '1px solid #D3D3D3',
    borderRadius: 5,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textWrap: 'nowrap',
    marginRight: 10
  },
  copyButton: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    padding: 10,
    borderRadius: 5,
    cursor: 'pointer'
  }
}))

const promptStyles = [
  { value: 'icon', label: 'No Pop-up (Chat Prompt Icon Only)' },
  { value: 'mini', label: 'Mini Pop-up' },
  { value: 'big', label: 'Large Pop-up' },
  { value: 'open', label: 'Full Chat' }
]

type propmtValues = 'mini' | 'big' | 'open' | 'icon'

interface SettingsCardProps {
  values: any
  setFieldValue: (type: string, value: any) => void
  botID: number
}

function SettingsCard ({ values, setFieldValue, botID }: SettingsCardProps) {
  const classes = useStyles()
  const [expanded, setExpanded] = useState(true)
  const { setSnackState } = useContext(SessionContext)

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  const generateCode = (botID: number) => {
    return `
      <script>
        const buttonList=[/* Insert buttons by their ids here */]
        buttonList.forEach(function(id) {
            const elem = document.getElementById(id)
            if (elem) {
              elem.addEventListener("click", () => {
                if (window.ChatFunnels) {
                  window.ChatFunnels._internal.loadBot(${botID})
                }
              })
            }
          }
        )
      </script>
    `
  }

  return (
    <Card className={classes.card}>
      <CardHeader
        title='Bot Settings'
        className={classes.header}
        classes={{ title: classes.cardTitle, action: classes.cardAction }}
        onClick={handleExpandClick}
        action={
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded
            })}
            aria-expanded={expanded}
            aria-label='show more'
          >
            <ExpandMoreIcon />
          </IconButton>
        }
      />
      <Collapse in={expanded} timeout='auto' unmountOnExit>
        <CardContent classes={{ root: classes.cardContentRoot }}>
          <div className={classes.settings}>
            <Typography variant='subtitle1'>
              Choose your bot's prompt size
            </Typography>
            <Divider className={classes.divider} />
            <Typography>Desktop</Typography>
            <NewSelect
              onChange={(val: propmtValues) => setFieldValue('prompt_style', val)}
              value={values.prompt_style}
              options={promptStyles}
              disableClear
            />
            <Typography style={{ marginTop: 25 }}>Mobile</Typography>
            <NewSelect
              onChange={(val: propmtValues) => setFieldValue('mobile_prompt_style', val)}
              value={values.mobile_prompt_style}
              options={promptStyles}
              disableClear
            />
            <div style={{ marginTop: 40 }}>
              <Typography variant='subtitle1'>
                Bot Activation Frequency
              </Typography>
              <Divider className={classes.divider} />
              <div className={classes.subHeading}>
                <Typography variant='subtitle2'>
                  Choose how often the bot will automatically activate using the Audience criteria.
                </Typography>
              </div>
              <Select
                value={values.frequency}
                displayEmpty
                margin='dense'
                fullWidth
                onChange={e => setFieldValue('frequency', e.target.value)}
                variant='outlined'
                inputProps={{
                  name: 'frequency',
                  id: 'frequency'
                }}
              >
                <MenuItem value='every time'>Every matching page load</MenuItem>
                <MenuItem value='session'>One time per session (30 minutes)</MenuItem>
                <MenuItem value='once'>Only once per visitor</MenuItem>
              </Select>
            </div>
            <div style={{ marginTop: 40 }}>
              <Typography variant='subtitle1'>
                Fire Bot on Button Click
              </Typography>
              <Divider className={classes.divider} />
              <div className={classes.subHeading}>
                <Typography variant='subtitle2'>
                  Copy and paste the code below as the destination for a button on your site to fire this bot when the button is clicked.
                </Typography>
                <MessageDiv
                  open
                  setOpen={() => undefined}
                  type='info'
                  body={(
                    <div>
                      The
                      <Link to='/settings/installation/manual' target='_blank' style={{ marginLeft: 5, marginRight: 5, color: 'rgba(0,0,0,0.75)' }}>
                        Signals installation script
                      </Link>
                      must also be installed on the page in order for your bot to fire.
                    </div>
                  )}
                />
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div className={classes.codeDiv}>
                  {generateCode(botID)}
                </div>
                <Tooltip title='Copy code to Clipboard'>
                  <div
                    className={classes.copyButton}
                    onClick={() => {
                      navigator.clipboard.writeText(generateCode(botID)).then(
                        () => {
                          setSnackState({
                            open: true,
                            variant: 'success',
                            message: 'Code copied to clipboard!'
                          })
                        }
                      )
                    }}
                  >
                    <Icon icon='clone' color='white' specificSize={22} />
                  </div>
                </Tooltip>
              </div>
            </div>
          </div>
        </CardContent>
      </Collapse>
    </Card>
  )
}

interface SettingsPageProps {
  values: any
  setFieldValue: any
  botID: number
}

function SettingsPage ({ values, setFieldValue, botID }: SettingsPageProps) {
  const classes = useStyles()

  return (
    <div className={classes.messagePage}>
      <div>
        <SettingsCard
          values={values}
          setFieldValue={setFieldValue}
          botID={botID}
        />
      </div>
      <div>
        <ExplanationCard />
      </div>
    </div>
  )
}

export default SettingsPage
