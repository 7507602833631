/* eslint-disable @typescript-eslint/naming-convention */
import AppPage from 'cf-components/AppPage'
import { makeStyles } from '@material-ui/core/styles'
import CustomSounds, { CustomSound, customSoundReducer, defaultSounds } from 'classes/customSounds'
import { useEffect, useReducer, useState } from 'react'
import CustomSoundsMapping from './CustomSoundsMapping'
import SoundDropzone from './SoundDropzone'
import axios from 'axios'
import { Button, Divider, Icon } from 'library/materialUI'
import { Sound } from 'library/sounds/sound'
import { Modal, ModalSection } from 'library/Modal'

const useStyles = makeStyles(theme => ({
  container: {
    margin: '20px',
    padding: '40px',
    backgroundColor: '#FFFFFF',
    border: '1px solid #D3D3D3',
    borderRadius: '5px'
  },
  subtitle: {
    fontSize: '16px'
  },
  newSound: {
    color: theme.palette.primary.alt,
    fontSize: '12px',
    cursor: 'pointer'
  },
  customSoundList: {
    border: '1px solid #D3D3D3',
    borderRadius: '5px',
    padding: '20px',
    display: 'flex',
    flexDirection: 'column'
  },
  listHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  divStyle: {
    marginBottom: '20px',
    marginTop: '20px'
  }
}))

export default function CustomSoundsPage () {
  const classes = useStyles()
  const [disabled, setDisabled] = useState(true)
  const [state, dispatch] = useReducer(customSoundReducer, defaultSounds)
  const { data: sounds, isLoading } = CustomSounds.loadCustomSounds()
  const [uploadSound, setUploadSound] = useState<File>()
  const [uploadError, setUploadError] = useState(false)
  const [uploadModal, setUploadModal] = useState(false)
  const [customSoundList, setCustomSoundList] = useState<any[]>([])
  const [trigger, setTrigger] = useState(false)
  const [playing, setPlaying] = useState('')
  const [currentSound, setCurrentSound] = useState<HTMLAudioElement>()

  function save () {
    CustomSounds.save({ data: state })
      .then(settingsResponse => {
        if ('data' in settingsResponse) {
          setDisabled(true)
        }
      })
    setUploadSound(undefined)
    return true
  }

  function handleUploadSound () {
    // Upload sound
    if (uploadSound) {
      CustomSounds.getSignedURL()
        .then((response) => {
          const newSound: CustomSound = {
            name: uploadSound?.name || 'File',
            link_url: response
          }
          CustomSounds.saveNewSound(newSound)
          axios.put(response, uploadSound, {
            headers: { 'Content-type': 'audio/mpeg' }
          })
          setUploadSound(undefined)
        })
    }
  }

  useEffect(() => {
    if (!isLoading && sounds.list[0]) {
      dispatch({ type: 'init', value: sounds.list[0] })
    }
  }, [sounds, isLoading])

  useEffect(() => {
    CustomSounds.getCustomSoundList()
      .then((response) => {
        if (response?.data) {
          const temp: any[] = []
          response?.data.map((item: any) => (
            temp.push({ id: item.attributes.id, label: item.attributes.name, link: item.attributes.link_url })
          ))
          setCustomSoundList(temp)
        }
      })
  }, [uploadSound, uploadModal, trigger])

  const saveProps = {
    disabled: disabled,
    save: save,
    saveText: 'Save Settings'
  }

  if (isLoading) {
    return <></>
  }

  return (
    <AppPage
      title='Live View Map Sounds'
      padding={20}
      saveProps={saveProps}
    >
      <div className={classes.container}>
        <div className={classes.subtitle}>
          Customize the sound effects that play on the Live View map when certain events happen. Changing sounds will also change them for everyone else at your organization.
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ justifySelf: 'flex-start' }}>
            <CustomSoundsMapping state={state} dispatch={dispatch} setDisabled={setDisabled} />
          </div>
          <div style={{ alignSelf: 'flex-start', marginLeft: 'auto', marginRight: 'auto', marginTop: '25px', width: '500px' }}>
            <div className={classes.customSoundList}>
              <div className={classes.listHeader}>
                <div>
                  Your Custom Sounds
                </div>
                <div>
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={() => setUploadModal(true)}
                    style={{ display: 'block' }}
                  >
                    Upload New Sound
                  </Button>
                </div>
              </div>
              <div className={classes.divStyle}>
                <Divider />
              </div>
              <div>
                {customSoundList?.map((item, index) => (
                  <div key={index}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                      <div>
                        {item.label}
                      </div>
                      <div style={{ display: 'flex' }}>
                        <div style={{ marginRight: '20px', cursor: 'pointer' }}>
                          <Icon
                            icon={(playing === item.label) ? 'pause' : 'playArrow'}
                            onClick={() => {
                              if (item.label === playing) {
                                setPlaying('')
                                currentSound?.pause()
                                setCurrentSound(undefined)
                              } else {
                                setPlaying(item.label)
                                setCurrentSound(Sound({ sound: item.link, onEnd: () => setPlaying('') }))
                              }
                            }}
                          />
                        </div>
                        <div onClick={() => { CustomSounds.delete(item.id); setTrigger(!trigger) }} style={{ cursor: 'pointer' }}>
                          <Icon icon='delete' />
                        </div>
                      </div>
                    </div>
                    {index !== customSoundList.length - 1 && (
                      <div className={classes.divStyle}>
                        <Divider />
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
            {uploadModal && (
              <Modal
                onHide={() => setUploadModal(false)}
                handleSave={() => { handleUploadSound(); setUploadModal(false) }}
                saveDisabled={!uploadSound}
                saveIcon='add'
                open={uploadModal}
                title='Upload Custom Sounds'
                saveBtnText='Add Sound'
                size='sm'
              >
                <ModalSection>
                  {!uploadSound ? (
                    <SoundDropzone setSound={setUploadSound} setDisabled={setDisabled} setUploadError={setUploadError} />
                  ) : (
                    <div style={{ fontSize: '20px', fontWeight: 'bold', height: '220px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      {uploadSound.name}
                    </div>
                  )}
                  {uploadError && (
                    <div style={{ textAlign: 'center', color: 'red', marginTop: '20px' }}>
                      Please upload a valid .mp3 file
                    </div>
                  )}
                </ModalSection>
              </Modal>
            )}
          </div>
        </div>
      </div>
    </AppPage>
  )
}
