import { jsPlumbUtil } from 'jsplumbtoolkit'

const defaultHeaders = {
  'Content-Type': 'application/vnd.api+json'
}

const sendEvent = ({ conversationID, attributes, chatServiceUrl }) => {
  const promise = fetch(`https://${chatServiceUrl}/conversations/${conversationID}/events`, {
    method: 'POST',
    headers: defaultHeaders,
    cache: 'no-store',
    body: JSON.stringify({
      data: {
        type: 'conversation_events',
        attributes: attributes
      }
    })
  })
    .then(response => response.json())
  return promise
}

const sendTypingEvent = ({ conversationID, chatServiceUrl, kind, avatarURL }) => {
  return sendEvent({
    conversationID: conversationID,
    attributes: {
      body: '',
      kind: kind,
      avatar_url: avatarURL
    },
    chatServiceUrl: chatServiceUrl
  })
}

const sendCalendarDrop = ({ conversationID, chatServiceUrl, userID, meetingTypeID, textMessageReminders, followUpEmail, emailID }) => {
  return sendEvent({
    conversationID: conversationID,
    attributes: {
      data: {
        default: true,
        user_id: userID,
        meeting_type_id: meetingTypeID,
        text_message_reminders: textMessageReminders,
        follow_up_email: followUpEmail,
        email_id: emailID
      },
      kind: 'calendar_drop'
    },
    chatServiceUrl: chatServiceUrl
  })
}

const sendMeetingButton = ({ conversationID, chatServiceUrl, meetingUrl }) => {
  const id = 'joinmeeting.' + jsPlumbUtil.uuid()
  return sendEvent({
    conversationID: conversationID,
    attributes: {
      data: {
        id: id,
        url: ((meetingUrl.includes('http') || meetingUrl.includes('https')) ? meetingUrl : 'https://' + meetingUrl),
        color: '#9900EF',
        label: 'Join Video Call',
        open_target: '_blank'
      },
      kind: 'cta_button'
    },
    chatServiceUrl: chatServiceUrl
  })
}

export { sendEvent, sendTypingEvent, sendCalendarDrop, sendMeetingButton }
