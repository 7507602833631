import { Actions, Fields } from 'cf-components/table/Table'
import Persona from 'classes/personas'
import { TableProvider } from 'library/table/TableProvider'
import BuyingCommitteeList from './BuyingCommitteeList'
import { Tooltip } from '@material-ui/core'
import { Icon, Popover, MenuItem } from 'library/materialUI'

interface BuyingCommitteeTableProps {
  showPersonaMenu: boolean
  setShowPersonaMenu: (value: boolean) => void
  setPersonaModalOpen: (value: boolean) => void
  setAiPersonaModalOpen: (value: boolean) => void
  anchorEl: HTMLElement | null
}

export default function BuyingCommitteeTable (props: BuyingCommitteeTableProps): JSX.Element {
  const fields: Fields<Persona> = [
    { name: 'name', type: 'avatar' }
  ]
  const actions: Actions<Persona> = []
  const templateColumns = '80px 1fr 1fr 1fr 2fr 80px'

  return (
    <div>
      {props.showPersonaMenu && (
        <Popover
          open={props.showPersonaMenu}
          onClose={() => props.setShowPersonaMenu(false)}
          anchorEl={props.anchorEl}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          style={{ marginTop: 10 }}
        >
          <MenuItem
            onClick={() => {
              props.setPersonaModalOpen(true);
              props.setShowPersonaMenu(false);
            }}
            style={{ padding: '15px' }}
          >
            Create new persona manually
          </MenuItem>
          <MenuItem
            onClick={() => {
              props.setAiPersonaModalOpen(true);
              props.setShowPersonaMenu(false);
            }}
            style={{ padding: '15px' }}
          >
            Auto-create personas using AI
            <Tooltip title='Powered by Artificial Intelligence'>
              <div style={{ marginTop: -10 }}>
                <Icon icon='aiSparkles' />
              </div>
            </Tooltip>
          </MenuItem>
        </Popover>
      )}
      <TableProvider<Persona>
        ObjectClass={Persona}
        fields={fields}
        actions={actions}
        noEmail
        gridTemplateColumns={templateColumns}
        noExport
      >
        <BuyingCommitteeList />
      </TableProvider>
    </div>
  )
}
