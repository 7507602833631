import React, { useState, useEffect } from 'react'
import axios from 'axios'
import {
  Card,
  Divider,
  FormControl,
  Select,
  MenuItem,
  OutlinedInput,
  ListItemText,
  Checkbox,
  IconButton,
  Input
} from '@material-ui/core'
import Dropzone from 'react-dropzone'
import { makeStyles } from '@material-ui/core/styles'
import MuiDialogContent from '@material-ui/core/DialogContent'
import { getCompanyInfo, updateCompanyInfo, logCompanyAttachment } from 'api/company_profile'
import AppPage from 'cf-components/AppPage'
import EditIcon from '@material-ui/icons/Edit'
import briefcaseIcon from 'img/briefcaseIcon.svg'
import CurrencyInput from 'components/CurrencyInput'
import Loader from 'library/loading/Loader'
import ColorPopOver from 'library/ColorPopOver'

const dropzoneStyles = makeStyles(theme => ({
  dropzone: {
    cursor: 'pointer',
    height: 204,
    width: 204,
    display: 'flex',
    padding: 0,
    '&:first-child': {
      paddingTop: 0
    }
  }
}))

const useStyles = makeStyles(theme => ({
  contents: {
    height: 'calc(100% - 85px)',
    display: 'flex'
  },
  companyBio: {
    margin: 20,
    border: '1px solid rgba(151, 92, 230, .5)',
    padding: 30,
    position: 'relative',
    borderRadius: 5
  },
  companyInfo: {
    margin: 20,
    padding: 25,
    paddingTop: 20,
    borderRadius: 5
  },
  companyName: {
    marginTop: 10,
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center'
  },
  basicInfoTitle: {
    color: '#888888',
    marginTop: 10
  },
  colorBanner: {
    height: 150,
    backgroundColor: 'rgba(151, 92, 230)',
    left: 0,
    top: 0,
    right: 0,
    position: 'absolute'
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  titleText: {
    fontSize: 20,
    marginBottom: 5
  },
  subtitle: {
    fontSize: 16,
    color: '#777777',
    marginBottom: 10
  },
  text: {
    fontSize: 16
  },
  formControl: {
    marginTop: '-4px',
    marginBottom: 0,
    paddingTop: 0
  },
  pill: {
    width: 'fit-content',
    padding: '4px 12px',
    whiteSpace: 'no-wrap',
    borderRadius: 25,
    marginRight: 3,
    fontSize: 15,
    marginBottom: 2
  },
  colorPickerDiv: {
    width: 276,
    margin: 'auto',
    marginTop: 10
  },
  colorBox: {
    height: 25,
    width: 25,
    borderRadius: 4,
    border: '1px solid black',
    marginRight: 10
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  multiSelectContainer: {
    minHeight: 35,
    display: 'flex',
    alignItems: 'baseline',
    marginBottom: 10,
    flexWrap: 'wrap'
  },
  input: {
    paddingTop: 0
  },
  centeredInput: {
    fontSize: 26,
    textAlign: 'center',
    paddingTop: 0,
    fontWeight: 500
  },
  floatingEditButton: {
    borderRadius: 50,
    height: 40,
    width: 40,
    backgroundColor: '#FFFFFF',
    display: 'flex',
    justifyContent: 'center',
    margin: 20,
    top: 0,
    right: 0,
    position: 'absolute',
    boxShadow: '2px 4px 5px 1px rgba(0,0,0,0.3)',
    cursor: 'pointer',
    zIndex: 10
  },
  companyImage: {
    height: 150,
    width: 150,
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  editCompanyImage: {
    height: 150,
    width: 150,
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  imageContainer: {
    backgroundColor: '#FFFFFF',
    objectFit: 'contain',
    height: 200,
    width: 200,
    display: 'flex',
    alignItems: 'center',
    borderRadius: 150,
    overflow: 'hidden'
  },
  // colorEditButton: {
  //   cursor: 'pointer',
  //   border: '1px solid',
  //   borderRadius: 5,
  //   padding: 5,
  //   display: 'flex'
  // },
  // colorDisplay: {
  //   borderRadius: 5,
  //   padding: 5,
  //   display: 'flex'
  // },
  loading: {
    display: 'flex',
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))

const businessTypes = ['B2B', 'B2C', 'Hybrid']

const industries = [
  'Accounting & Finance',
  'Aviation & Aerospace',
  'Healthcare',
  'Automotive',
  'Banking',
  'News Media',
  'Business Supplies & Equipment',
  'Real Estate',
  'Construction',
  'Consumer Electronics',
  'Agriculture',
  'Computer - Hardware',
  'Computer - Software',
  'Military & Defence',
  'Education',
  'Manufacturing',
  'Government',
  'Insurance',
  'Hospitality & Tourism ',
  'Energy',
  'Food',
  'Mining',
  'Entertainment',
  'E-commerce ',
  'Pharmaceutical ',
  'Consumer Goods',
  'Philanthropy / Non-profit',
  'Transportation',
  'Telecommincations'
]

const weeks = [
  { text: '1 week', value: 1 },
  { text: '2 weeks', value: 2 },
  { text: '3 weeks', value: 3 },
  { text: '4 weeks', value: 4 },
  { text: '5 weeks', value: 5 },
  { text: '6 weeks', value: 6 },
  { text: '7 weeks', value: 7 },
  { text: '8 weeks', value: 8 },
  { text: '9 weeks', value: 9 },
  { text: '10 weeks', value: 10 }
]

const companySizes = [
  '1 - 10 Employees',
  '11 - 20 Employees',
  '21 - 50 Employees',
  '51 - 100 Employees',
  '100 - 500 Employees',
  '500+ Employees'
]

const salesRegions = [
  'North America',
  'Europe',
  'Asia',
  'South America',
  'Africa',
  'Middle East',
  'Australia/Oceania'
]

// const chat_purposes = [
//   'Sales',
//   'Marketing',
//   'Customer Support',
//   'Customer Success',
//   'Other'
// ]

const startingValues = {
  attributes: {
    company_name: 'Your Company',
    business_type: 'B2B',
    bio: '',
    industry: '',
    company_logo_url: '',
    client_industries: [],
    average_deal_size: 0,
    // goals: [],
    sales_cycle_in_weeks: '1',
    website: '',
    founded: '',
    headquarters: '',
    company_size: '1 - 10 Employees',
    sales_regions: [],
    company_primary_color: '#AAAAAA',
    company_contrast_color: '#FFFFFF'
  }
}

// const ColorPopOver = props => {
//   const classes = useStyles()
//   const [anchorEl, setAnchorEl] = React.useState(null)
//   const value = props.value
//   const additionalInfoEdit = props.additionalInfoEdit

//   const handleClick = (event) => {
//     if (additionalInfoEdit) {
//       setAnchorEl(event.currentTarget)
//     }
//   }

//   const handleClose = () => {
//     setAnchorEl(null)
//   }

//   const open = Boolean(anchorEl)
//   return (
//     <>
//       <div className={additionalInfoEdit ? classes.colorEditButton : classes.colorDisplay} onClick={handleClick}>
//         <div style={{ height: 20, width: 20, backgroundColor: value, marginRight: 10, border: '1px solid', borderRadius: 4 }} />
//         <div>
//           {value}
//         </div>
//       </div>
//       <Popover
//         open={open}
//         anchorEl={anchorEl}
//         onClose={handleClose}
//         anchorOrigin={{
//           vertical: 'bottom',
//           horizontal: 'center'
//         }}
//         transformOrigin={{
//           vertical: 'top',
//           horizontal: 'center'
//         }}
//       >
//         <ChromePicker disableAlpha color={value} onChange={(color) => props.onChange(color.hex)} />
//       </Popover>
//     </>
//   )
// }

const ImageSelector = props => {
  const classes = dropzoneStyles()

  const handleDrop = acceptedFile => {
    props.handler(acceptedFile[0])
  }

  return (
    <div style={{ borderRadius: 100, position: 'absolute' }}>
      <Dropzone onDrop={handleDrop} disabled={props.disabled} style={{ borderRadius: 100, width: 200, height: 200 }}>
        {({ getRootProps, getInputProps }) => (
          <MuiDialogContent
            classes={{
              root: classes.dropzone,
              input: classes.roundedInput
            }}
            {...getRootProps()}
          >
            <input {...getInputProps()} />
          </MuiDialogContent>
        )}
      </Dropzone>
    </div>
  )
}

const EditableField = props => {
  const classes = useStyles()
  const editMode = props.editMode
  const handleChange = props.handleChange
  const state = props.state
  const attrName = props.attrName
  const caps = props.caps

  return (
    <div style={{ minHeight: props.minHeight || 40, display: 'flex', alignItems: 'baseline' }}>
      {editMode ? (
        <Input
          placeholder={props.placeholder}
          classes={{ input: classes.input }}
          value={state.attributes[attrName]}
          onChange={handleChange}
        />
      ) : caps
        ? (
          <div>
            {state.attributes[attrName].toUpperCase()}
          </div>
        ) : (
          <div>
            {state.attributes[attrName]}
          </div>

        )}
    </div>
  )
}

const convertLocaleCurrencyToInt = (currencyString) => {
  const noDecimals = currencyString.split('.')[0]
  const noSign = noDecimals.replace('$', '')
  const integer = parseFloat(noSign.replace(/,/g, ''))
  return integer
}

const convertToRgba = (hex) => {
  hex = hex.slice(1)
  const bigint = parseInt(hex, 16)
  const r = (bigint >> 16) & 255
  const g = (bigint >> 8) & 255
  const b = bigint & 255

  return `rgba(${r},${g},${b},0.5)`
}

const convertIntToDollars = (dollars) => {
  if (!dollars) {
    return '$0'
  }

  dollars = dollars.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD'
  })

  return dollars
}

function IntakeSurvey (props) {
  const classes = useStyles()
  const [companyInfo, setCompanyInfo] = useState(startingValues)
  const [additionalInfoEdit, setAdditionalInfoEdit] = useState(false)
  const [bioEdit, setBioEdit] = useState(false)
  const [mainInfoEdit, setMainInfoEdit] = useState(false)
  const [disabled, setDisabled] = useState(true)
  const [loading, setLoading] = useState(true)

  const disableEdit = () => {
    setAdditionalInfoEdit(false)
    setBioEdit(false)
    setMainInfoEdit(false)
  }

  const handleChange = ({ attr, value }) => {
    setDisabled(false)
    setCompanyInfo({
      ...companyInfo,
      attributes: {
        ...companyInfo.attributes,
        [attr]: value
      }

    })
  }

  const uploadImage = image => {
    logCompanyAttachment({ file: image })
      .then(response => {
        const signed_url = response.meta.signed_upload_url
        axios.put(signed_url, image, {
          headers: {
            'Content-type': 'image/png',
            'x-goog-acl': 'public-read'
          }
        }).then(response => {
          image.url = response.config.url.split('?')[0]
          handleChange({ attr: 'company_logo_url', value: image.url })
        })
      })
  }

  const save = values => {
    updateCompanyInfo(companyInfo)
      .then(
        response => {
          if (response.ok) {
            setCompanyInfo(response.data)
            setDisabled(true)
            disableEdit()
          }
        }
      )
  }

  useEffect(() => {
    getCompanyInfo()
      .then(response => {
        if (response.ok) {
          setCompanyInfo(response.data)
        }
        setLoading(false)
      })
  }, [])

  if (!companyInfo || !companyInfo.attributes) {
    return <></>
  }

  const saveProps = {
    disabled: disabled,
    save: save,
    saveText: 'Save',
    validPaths: 'company_profile'
  }

  document.title = 'Company Profile | Signals'

  if (loading) {
    return (
      <AppPage
        title='Company Profile'
        padding={0}
        saveProps={saveProps}
      >
        <div className={classes.loading}>
          <Loader type='spinner' title='Loading your company profile' size='lg' />
        </div>
      </AppPage>
    )
  }

  return (
    <AppPage
      title='Company Profile'
      padding={0}
      saveProps={saveProps}
    >
      <div className={classes.contents}>
        <div style={{ minWidth: 300, width: '25%' }}>
          <Card className={classes.companyBio} style={{ border: `1px solid ${convertToRgba(companyInfo.attributes.company_primary_color)}` }}>
            <div className={classes.colorBanner} style={{ backgroundColor: companyInfo.attributes.company_primary_color }} />
            <div className={classes.floatingEditButton}>
              <IconButton onClick={(() => setMainInfoEdit(!mainInfoEdit))}>
                <EditIcon style={{ padding: 5, fill: mainInfoEdit && companyInfo.attributes.company_primary_color }} />
              </IconButton>
            </div>
            <div style={{ position: 'relative', display: 'flex', justifyContent: 'center' }}>
              <ImageSelector
                handler={uploadImage}
                editMode={mainInfoEdit}
              />
              <div
                style={{
                  border: mainInfoEdit ? '2px dotted black' : `2px solid ${convertToRgba(companyInfo.attributes.company_primary_color)}`
                }}
                className={classes.imageContainer}
              >
                <img
                  style={{
                    objectFit: 'contain'
                  }}
                  src={companyInfo.attributes.company_logo_url || briefcaseIcon}
                  className={mainInfoEdit ? classes.editCompanyImage : classes.companyImage}
                  alt='Company Logo'
                />
              </div>
            </div>
            <div className={classes.companyName}>
              <div style={{ minHeight: 80 }}>
                {mainInfoEdit ? (
                  <>
                    <Input
                      autoFocus
                      classes={{ input: classes.centeredInput }}
                      value={companyInfo.attributes.company_name}
                      onChange={(e) => handleChange({ attr: 'company_name', value: e.target.value })}
                    />
                    <FormControl margin='dense' className={classes.formControl}>
                      <Select
                        value={companyInfo.attributes.business_type}
                        style={{ marginTop: 10, marginLeft: 20 }}
                        onChange={(e) => handleChange({ attr: 'business_type', value: e.target.value })}
                      >
                        {businessTypes.map((item, index) => (
                          <MenuItem value={item} key={index}>
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </>

                ) : (
                  <>
                    <div style={{ fontSize: 26, fontWeight: 500 }}>
                      {companyInfo.attributes.company_name}
                    </div>
                    <div style={{ fontSize: 18, fontWeight: 300 }}>
                      {companyInfo.attributes.business_type}
                    </div>
                  </>
                )}
              </div>
            </div>
            <Divider style={{ marginBottom: 20 }} />
            <div style={{ fontSize: 16, fontWeight: 300 }}>
              About your company:
            </div>
            <div className={classes.basicInfoTitle}>
              Industry
            </div>
            <EditableField
              state={companyInfo}
              editMode={mainInfoEdit}
              minHeight={30}
              placeholder='i.e: Marketing'
              attrName='industry'
              handleChange={(e) => handleChange({ attr: 'industry', value: e.target.value })}
            />
            <div className={classes.basicInfoTitle}>
              Founded
            </div>
            <EditableField
              state={companyInfo}
              editMode={mainInfoEdit}
              minHeight={30}
              placeholder='i.e: 2015'
              attrName='founded'
              handleChange={(e) => handleChange({ attr: 'founded', value: e.target.value })}
            />
            <div className={classes.basicInfoTitle}>
              Website
            </div>
            <EditableField
              state={companyInfo}
              editMode={mainInfoEdit}
              minHeight={30}
              placeholder='i.e: www.company.com'
              attrName='website'
              handleChange={(e) => handleChange({ attr: 'website', value: e.target.value })}
            />
            <div className={classes.basicInfoTitle}>
              Headquarters:
            </div>
            <EditableField
              state={companyInfo}
              editMode={mainInfoEdit}
              minHeight={30}
              placeholder='i.e: Austin, TX'
              attrName='headquarters'
              handleChange={(e) => handleChange({ attr: 'headquarters', value: e.target.value })}
            />
            <div className={classes.basicInfoTitle}>
              Company Size
            </div>
            <div style={{ minHeight: 30 }}>
              {mainInfoEdit
                ? (
                  <FormControl margin='dense' className={classes.formControl}>
                    <Select
                      value={companyInfo.attributes.company_size}
                      style={{ marginTop: 0 }}
                      onChange={(e) => handleChange({ attr: 'company_size', value: e.target.value })}
                    >
                      {companySizes.map((item, index) => (
                        <MenuItem value={item} key={index}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : (
                  <div className={classes.value}>
                    {companyInfo.attributes.company_size}
                  </div>

                )}

            </div>
          </Card>
        </div>
        <div style={{ width: '70%' }}>
          <Card className={classes.companyInfo} style={{ border: `1px solid ${convertToRgba(companyInfo.attributes.company_primary_color)}` }}>
            <div className={classes.title}>
              <div className={classes.titleText}>
                Company Bio
              </div>
              <IconButton onClick={() => {
                setBioEdit(!bioEdit)
              }}
              >
                <EditIcon style={{ fill: bioEdit && companyInfo.attributes.company_primary_color }} />
              </IconButton>
            </div>
            <Divider style={{ marginBottom: 10 }} />
            <div style={{ minHeight: 50 }}>
              {bioEdit ? (
                <FormControl variant='outlined' margin='dense' fullWidth style={{ marginTop: 0 }}>
                  <OutlinedInput
                    placeholder='Insert a small description of your company and what your company does.'
                    value={companyInfo.attributes.bio}
                    onChange={(e) => handleChange({ attr: 'bio', value: e.target.value })}
                    labelWidth={0}
                    multiline
                    rows={3}
                  />
                </FormControl>
              ) : (
                <div className={classes.text}>
                  {companyInfo.attributes.bio || <span style={{ color: '#b7b7b7' }}>Insert a small description of your company and what your company does.</span>}
                </div>
              )}
            </div>
          </Card>
          <Card className={classes.companyInfo} style={{ border: `1px solid ${convertToRgba(companyInfo.attributes.company_primary_color)}` }}>
            <div className={classes.title}>
              <div className={classes.titleText}>
                Additional Info
              </div>
              <IconButton onClick={() => {
                if (additionalInfoEdit) {
                  setAdditionalInfoEdit(false)
                } else {
                  setAdditionalInfoEdit(true)
                }
              }}
              >
                <EditIcon style={{ fill: additionalInfoEdit && companyInfo.attributes.company_primary_color }} />
              </IconButton>
            </div>
            <Divider style={{ marginBottom: 10 }} />
            <div>
              <div>
                <div className={classes.subtitle}>
                  Target Industries:
                </div>
                <div className={classes.multiSelectContainer}>
                  {additionalInfoEdit
                    ? (
                      <FormControl margin='dense' className={classes.formControl}>
                        <Select
                          label='Industries'
                          multiple
                          value={companyInfo.attributes.client_industries}
                          onChange={(e) => handleChange({ attr: 'client_industries', value: e.target.value })}
                          renderValue={(selected) => (
                            <div className={classes.chips}>
                              {selected.map((value, index) => (
                                <div key={index} className={classes.pill} style={{ backgroundColor: companyInfo.attributes.company_primary_color, color: companyInfo.attributes.company_contrast_color, display: 'inline-block' }}>
                                  {value}
                                </div>
                              ))}
                            </div>
                          )}
                        >
                          {industries.map((item, index) => (
                            <MenuItem value={item} key={index}>
                              <Checkbox
                                style={{ color: companyInfo.attributes.company_primary_color }}
                                checked={companyInfo.attributes.client_industries.includes(item)}
                              />
                              <ListItemText primary={item} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    ) : (
                      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {companyInfo.attributes.client_industries.map((ci, index) => (
                          <div key={index} className={classes.pill} style={{ backgroundColor: companyInfo.attributes.company_primary_color, color: companyInfo.attributes.company_contrast_color }}>
                            {ci}
                          </div>
                        ))}
                      </div>
                    )}
                </div>
                <div className={classes.subtitle}>
                  Average Deal Size:
                </div>
                <div style={{ minHeight: 50 }}>
                  {additionalInfoEdit
                    ? (
                      <CurrencyInput
                        onChange={(e) => {
                          handleChange({ attr: 'average_deal_size', value: convertLocaleCurrencyToInt(e.target.value) })
                        }}
                        value={companyInfo.attributes.average_deal_size || 0}
                      />
                    ) : (
                      <div className={classes.text}>
                        {convertIntToDollars(companyInfo.attributes.average_deal_size)} USD
                      </div>

                    )}
                </div>
                <div className={classes.subtitle}>
                  Average Sales Cycle (In weeks):
                </div>
                <div className={classes.multiSelectContainer}>
                  {additionalInfoEdit
                    ? (
                      <FormControl margin='dense' className={classes.formControl}>
                        <Select
                          label='Industries'
                          value={companyInfo.attributes.sales_cycle_in_weeks}
                          style={{ marginTop: 0 }}
                          onChange={(e) => handleChange({ attr: 'sales_cycle_in_weeks', value: e.target.value })}
                          renderValue={(selected) => (
                            <div>
                              <div className={classes.pill} style={{ backgroundColor: companyInfo.attributes.company_primary_color, color: companyInfo.attributes.company_contrast_color, display: 'inline-block' }}>
                                {selected} week(s)
                              </div>
                            </div>
                          )}
                        >
                          {weeks.map((item, index) => (
                            <MenuItem value={item.value} key={index}>
                              <ListItemText primary={item.text} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    ) : (
                      <div className={classes.pill} style={{ backgroundColor: companyInfo.attributes.company_primary_color, color: companyInfo.attributes.company_contrast_color }}>
                        {companyInfo.attributes.sales_cycle_in_weeks} week(s)
                      </div>
                    )}
                </div>
              </div>
              <div>
                <div className={classes.subtitle}>
                  Regions
                </div>
                <div className={classes.multiSelectContainer}>
                  {additionalInfoEdit ? (
                    <FormControl margin='dense' className={classes.formControl}>
                      <Select
                        label='Industries'
                        multiple
                        value={companyInfo.attributes.sales_regions}
                        onChange={(e) => handleChange({ attr: 'sales_regions', value: e.target.value })}
                        renderValue={(selected) => (
                          <div className={classes.chips}>
                            {selected.map((value, index) => (
                              <div key={index} className={classes.pill} style={{ backgroundColor: companyInfo.attributes.company_primary_color, color: companyInfo.attributes.company_contrast_color, display: 'inline-block' }}>
                                {value}
                              </div>
                            ))}
                          </div>
                        )}
                      >
                        {salesRegions.map((item, index) => (
                          <MenuItem value={item} key={index}>
                            <Checkbox
                              style={{ color: companyInfo.attributes.company_primary_color }}
                              checked={companyInfo.attributes.sales_regions.includes(item)}
                            />
                            <ListItemText primary={item} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  ) : (
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                      {companyInfo.attributes.sales_regions.map((cr, index) => (
                        <div key={index} className={classes.pill} style={{ backgroundColor: companyInfo.attributes.company_primary_color, color: companyInfo.attributes.company_contrast_color }}>
                          {cr}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <div className={classes.subtitle}>
                  Primary Color / Contrast Color
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ marginRight: 20 }}>
                    <ColorPopOver
                      additionalInfoEdit={additionalInfoEdit}
                      onChange={(color) => handleChange({ attr: 'company_primary_color', value: color })}
                      value={companyInfo.attributes.company_primary_color}
                    />
                  </div>
                  <div>
                    <ColorPopOver
                      additionalInfoEdit={additionalInfoEdit}
                      onChange={(color) => handleChange({ attr: 'company_contrast_color', value: color })}
                      value={companyInfo.attributes.company_contrast_color}
                    />
                  </div>
                </div>
                <br />
              </div>
            </div>
          </Card>
        </div>
      </div>
    </AppPage>
  )
}

export default IntakeSurvey
