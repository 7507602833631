import { useContext, useReducer } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { LabeledCheckbox, TextBox } from 'library/materialUI'
import { ContentsProps } from '../wizard/OnboardingWizard'
import { reducer } from 'library/helpers'
import User from 'classes/users'
import { UserContext } from './UserContext'

const useStyles = makeStyles(theme => ({
  checkboxDiv: {
    marginTop: 10
  }
}))

export const userNameSubtitle = 'Enter your first name, last name, and a display name.' +
  ' The display name will appear in the messenger and in calendar invitations.'

export function UserNameSection (props: ContentsProps) {
  const { user } = useContext(UserContext)
  const classes = useStyles()
  const [state, dispatch] = useReducer(reducer, {
    name: user.name,
    displayName: user.displayName ? user.displayName : user.name,
    useFullName: user.displayName ? user.name === user.displayName : true
  })

  const handleChange = (property: string, value: string | boolean) => {
    const name = property === 'name' ? value : state.name
    if (property === 'displayName') {
      dispatch({ useFullName: false, displayName: value })
    } else if (state.useFullName || property === 'useFullName') {
      dispatch({ [property]: value, displayName: name })
    } else {
      dispatch({ [property]: value })
    }
  }

  props.handleNextRef.current = () => {
    if (!(state.name === user.name && state.displayName === user.displayName)) {
      const updates = { name: state.name, displayName: state.displayName }
      User.update(user.id, updates)
    }
  }

  return (
    <div>
      <TextBox
        value={state.name}
        onChange={(value: string) => handleChange('name', value)}
        label='Full name'
      />
      <div className={classes.checkboxDiv}>
        <LabeledCheckbox
          label='Use full name as display name'
          checked={state.useFullName}
          onChange={(value: boolean) => handleChange('useFullName', value)}
        />
      </div>
      {!state.useFullName &&
        <div style={{ marginTop: 15 }}>
          <TextBox
            value={state.displayName}
            onChange={(value: string) => handleChange('displayName', value)}
            label='Display name'
          />
        </div>}
    </div>
  )
}
