import { doPost } from './api'
/**
 * @param {{
 * phone?: string;
 * email?: string;
 * }}
 * @return {Promise<string>}
 */
export function sendSetupCode ({ phone, email }) {
  const attributes = {}
  if (phone) {
    attributes.mfa_phone = phone
  }
  if (email) {
    attributes.mfa_email = email
  }

  return doPost({
    path: '/auth/mfa_setup',
    data: {
      type: 'mfa_setup',
      attributes
    }
  }).then(response => response.data.attributes.confirm_code)
}

/**
 * @param {{
 * phone?: string;
 * email?: string;
 * }}
 */
export function enableMFA ({ phone, email }) {
  const attributes = {}
  if (phone) {
    attributes.mfa_phone = phone
  }
  if (email) {
    attributes.mfa_email = email
  }

  return doPost({
    path: '/auth/mfa_enable',
    data: {
      type: 'mfa_enable',
      attributes
    }
  })
}

export function disableMFA () {
  return doPost({
    path: '/auth/mfa_enable',
    data: {
      type: 'mfa_enable',
      attributes: {}
    }
  })
}

/**
 * @param {{
 * mfa_phone?: string;
 * mfa_email?: string;
 * mfa_code: string;
 * mfa_coupon: string;
 * }} attributes
 */
export function validateAndEnableMFA (attributes) {
  return doPost({
    path: '/api/auth/mfa_setup_code_validate',
    data: {
      type: 'mfa_enable',
      attributes
    }
  })
}

/**
 * @param {{
 * mfa_phone?: string;
 * mfa_email?: string;
 * mfa_coupon: string;
 * }} attributes
 */
export function sendLoginSetupCode (attributes) {
  return doPost({
    path: '/api/auth/mfa_login_setup',
    data: {
      type: 'mfa_setup',
      attributes
    }
  })
}
