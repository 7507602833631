import { queryClient } from 'App'
import { Modal, ModalSection } from 'cf-components/Modal'
import { User } from 'classes/users'

interface modalProps {
  onHide: () => void
  updates: {
    roleID?: number,
    permissions?: object
    userID: User['id']
  }
  acceptChange: () => void
}

export function AdminNoChatModal (props: modalProps) : any {
  const { updates, onHide, acceptChange } = props

  const accept = ():void => {
    const data = { roleID: updates.roleID, permissions: updates.permissions }
    User.removeUserFromTeams({ ID: updates.userID })
    User.update(updates.userID, data).then(() => {
      onHide()
      acceptChange()
      queryClient.invalidateQueries('users')
    })
  }

  const rightButtonProps = {
    text: 'Continue',
    action: accept
  }

  return (
    <Modal
      open
      title='Confirm Permissions Change'
      onHide={props.onHide}
      size='sm'
      mode='plain'
      rightButtonProps={rightButtonProps}
    >
      <ModalSection>
        <div>
          <p style={{ marginTop: 0 }}>
            This action will automatically remove the user from all teams that they are a member of.
          </p>
          <p>
            We advise that a bot audit be performed to verify this user is not included in live chat or calendar routing.
          </p>
          <p style={{ marginBottom: 0 }}>
            Would you like to continue?
          </p>
        </div>
      </ModalSection>
    </Modal>
  )
}
