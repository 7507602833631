import { getContact } from 'api/contacts'
import { loadTrackingEvents } from 'api/tracking_events'
import ContactSideCard from 'pages/people/ContactPage/ContactSideCard'
import { useContext, useEffect, useState } from 'react'
import { SessionContext } from 'session-context'
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'

interface SLContactProps {
  personID: number | undefined
}

export default function SLContactCard ({ personID }: SLContactProps) {
  const { user } = useContext(SessionContext)
  const chatServiceUrl = user?.links.chat_service || 'localhost:8002/api/chat-service/a'
  const [person, setPerson] = useState(null)
  const [trackingEvents, setTrackingEvents] = useState([])
  const [token, setToken] = useState(null)
  const [pageViews, setPageViews] = useState(0)
  const [numConversations, setNumConversations] = useState(0)
  const [firstSource, setFirstSource] = useState(null)
  const [lastSource, setLastSource] = useState(null)
  const [mainCardTab, setMainCardTab] = useState(0)
  const location = useLocation()
  const sid = queryString.parse(location.search).sid
  const authHeader = { Authorization: `Bearer ${sid}` }

  useEffect(() => {
    const headers = {
      'Content-Type': 'application/vnd.api+json',
      ...authHeader
    }

    fetch('/api/auth/token', {
      method: 'GET',
      headers: headers
    })
      .then(response => response.json())
      .then(accountResponse => {
        setToken(accountResponse.token || sid)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (personID && personID > 0) {
      getContact({
        personID,
        includes: ['account', 'created_by_user', 'meetings'],
        contactID: undefined,
        authHeader
      }).then(person => {
        setPerson(person)
      })
    } else {
      setPerson(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personID])

  useEffect(() => {
    if (token) {
      loadTrackingEvents({ chatServiceUrl, token, contactList: [personID] }).then(
        response => {
          setTrackingEvents(response.data)
          setPageViews(response.meta.num_page_views)
          setNumConversations(response.meta.num_conversations)
          setFirstSource(response.meta.first_source)
          setLastSource(response.meta.last_source)
        }
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  return (
    <div>
      <ContactSideCard
        person={person}
        trackingEvents={trackingEvents}
        pageViews={pageViews}
        numConversations={numConversations}
        firstSource={firstSource}
        lastSource={lastSource}
        convoTabOpen={mainCardTab === 1}
        openConvoTab={() => setMainCardTab(1)}
        openDetailsTab={() => setMainCardTab(0)}
        addNote={() => null}
        deleteNote={() => null}
        editNote={() => null}
        notes={[]}
        openEditNoteModal={() => null}
        openNoteModal={() => null}
        iframe
      />
    </div>
  )
}
