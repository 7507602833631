import { Grow, MenuItem, Checkbox, ListItemText, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useState, useEffect } from 'react'
import ForwardArrowIcon from '@material-ui/icons/ArrowForwardIos'
import BackArrowIcon from '@material-ui/icons/ArrowBackIos'
import { DateRangeMenu } from 'cf-components/FilterBar/DateRangeMenu'
import StyledSwitch from 'components/StyledSwitch'

const useStyles = makeStyles(theme => ({
  subMenuHeader: {
    backgroundColor: '#f8f8f8',
    display: 'flex',
    alignItems: 'center'
  },
  controlButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minWidth: 250
  },
  filterMenu: {
    paddingTop: 5,
    paddingBottom: 5
  }
}))

function SubMenuHeader (props) {
  const classes = useStyles()

  if (props.label) {
    return (
      <MenuItem className={classes.subMenuHeader} onClick={props.handleClose}>
        <ArrowIcon back />
        <div>{props.label}</div>
      </MenuItem>
    )
  }

  return (
    <MenuItem className={classes.subMenuHeader} onClick={props.handleClose}>
      <ArrowIcon back />
      <div>Filter by {props.subMenu}</div>
    </MenuItem>
  )
}

function ControlButtons (props) {
  const classes = useStyles()
  return (
    <div className={classes.controlButtons}>
      <Button
        style={{ margin: '3px 10px' }}
        onClick={props.handleCancel}
      >
        Cancel
      </Button>
      <Button
        color='primary'
        style={{ margin: '3px 10px' }}
        onClick={props.handleApply}
      >
        Apply Filter
      </Button>
    </div>
  )
}

function ArrowIcon (props) {
  if (props.forward) {
    return (
      <ForwardArrowIcon style={{ fill: 'rgba(0,0,0,0.54)', fontSize: '14px', marginTop: 2 }} />
    )
  } else if (props.back) {
    return (
      <BackArrowIcon style={{ fill: 'rgba(0,0,0,0.54)', fontSize: '14px', marginRight: 10, marginTop: 2 }} />
    )
  }
}

function FilterMenuV2 (props) {
  const classes = useStyles()
  const filterOptions = props.filterOptions
  let subMenu = props.subMenu
  if (filterOptions.filterValues.length === 1) {
    subMenu = filterOptions.filterValues[0].value
  }
  const setSubMenu = props.setSubMenu
  const [currentFilter, setCurrentFilter] = useState([])

  useEffect(() => {
    if (subMenu != null) {
      const filterValue = filterOptions.filterValues[subMenu].value
      setCurrentFilter(filterValue)
    } else {
      setCurrentFilter([])
    }
  }, [filterOptions, subMenu])

  const handleClose = () => {
    setSubMenu(null)
  }

  const handleCheckboxClick = value => {
    const currentIDs = currentFilter.map(value => value.id)
    if (currentIDs.includes(value.id)) {
      setCurrentFilter(currentFilter.filter(t => t.id !== value.id))
    } else {
      const newValueList = [...currentFilter]
      newValueList.push(value)
      setCurrentFilter(newValueList)
    }
  }

  if (subMenu == null) {
    return (
      <div className={classes.filterMenu}>
        {filterOptions.filterValues.map((filter, index) => (
          <MenuItem
            className={classes.menuItem}
            onClick={() => setSubMenu(index)}
            key={index}
          >
            <div>{filter.label}</div>
            <ArrowIcon forward />
          </MenuItem>
        ))}
      </div>
    )
  }

  const filter = filterOptions.filterValues[subMenu]
  const type = filter.type

  if (type === 'date') {
    return (
      <Grow in>
        <div>
          <SubMenuHeader label={filter.label} handleClose={handleClose} />
          <DateRangeMenu
            dateRange={filter.value}
            setDateRange={filter.setValue}
            handleBack={handleClose}
            handleClose={() => props.handleClose()}
          />
        </div>
      </Grow>
    )
  } else if (type === 'checkbox') {
    const IDs = currentFilter.map(value => value.id)
    return (
      <Grow in>
        <div className={classes.filterMenu}>
          <SubMenuHeader label={filter.label} handleClose={handleClose} />
          {filter.values.map((value) => (
            <MenuItem
              className={classes.menuItem}
              key={value.id}
              style={{ paddingTop: 0, paddingBottom: 0 }}
              onClick={() => handleCheckboxClick(value)}
            >
              <Checkbox color='primary' checked={IDs.includes(value.id)} />
              <ListItemText primary={value.label} />
            </MenuItem>
          ))}
          <ControlButtons
            handleCancel={handleClose}
            handleApply={() => {
              filter.setValue(currentFilter)
            }}
          />
        </div>
      </Grow>
    )
  }
}

export default function FilterMenu (props) {
  const classes = useStyles()
  const filterOptions = props.filterOptions
  let subMenu = props.subMenu
  if (filterOptions.filterValues.length === 1) {
    subMenu = filterOptions.filterValues[0].value
  }
  const setSubMenu = props.setSubMenu
  const [teamFilter, setTeamFilter] = useState(filterOptions.teamFilter)

  const handleClose = () => {
    setSubMenu(null)
    props.handleClose()
  }

  const handleTeamClick = team => {
    const currentIDs = teamFilter.map(t => t.id)
    if (currentIDs.includes(team.id)) {
      setTeamFilter(teamFilter.filter(t => t.id !== team.id))
    } else {
      const newTeamList = [...teamFilter]
      newTeamList.push({ id: team.id, name: team.attributes.name })
      setTeamFilter(newTeamList)
    }
  }

  if (filterOptions.type === 'V2') {
    return (
      <FilterMenuV2
        {...props}
      />
    )
  }

  if (subMenu === 'Date') {
    return (
      <Grow in>
        <div>
          <SubMenuHeader subMenu={subMenu} handleClose={handleClose} />
          <DateRangeMenu
            dateRange={filterOptions.dateRange}
            setDateRange={filterOptions.setDateRange}
            handleBack={handleClose}
            handleClose={() => props.handleClose()}
          />
        </div>
      </Grow>
    )
  } else if (subMenu === 'Team') {
    const teamIDs = teamFilter.map(t => t.id)
    return (
      <Grow in>
        <div className={classes.filterMenu}>
          <SubMenuHeader subMenu={subMenu} handleClose={handleClose} />
          {props.teams && props.teams.map((team) => (
            <MenuItem
              className={classes.menuItem}
              key={team.id}
              style={{ paddingTop: 0, paddingBottom: 0 }}
              onClick={() => handleTeamClick(team)}
            >
              <Checkbox color='primary' checked={teamIDs.includes(team.id)} />
              <ListItemText primary={team.attributes.name} />
            </MenuItem>
          ))}
          <ControlButtons
            handleCancel={handleClose}
            handleApply={() => {
              filterOptions.setTeamFilter(teamFilter)
              handleClose()
            }}
          />
        </div>
      </Grow>
    )
  }

  return (
    <div className={classes.filterMenu}>
      {filterOptions.filterValues.map((filter, index) => (
        <MenuItem
          className={classes.menuItem}
          onClick={() => setSubMenu(filter.value)}
          key={index}
        >
          <div>{filter.label}</div>
          {filter.toggle
            ? (
              <StyledSwitch
                checked={filter.value}
                toggle={filter.toggle}
              />)
            : <ArrowIcon forward />}
        </MenuItem>
      ))}
    </div>
  )
}
