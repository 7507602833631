import { Actions, Fields } from 'cf-components/table/Table';
import TableList from 'cf-components/table/TableList';
import { TableProvider } from 'library/table/TableProvider';
import { ApolloCreditUsuage } from 'classes/apolloCreditUsage';
import { Modal } from 'library/Modal';

interface ApolloCreditModalProps {
  closeModal: () => void
}

export default function ContactCreditUsageHistoryModal ({ closeModal }: ApolloCreditModalProps) {
  const fields: Fields<ApolloCreditUsuage> = [
    { sort: false, name: 'date', type: 'text', label: 'Date' },
    { sort: false, name: 'prospectLookup', type: 'text', label: 'Used for Prospect Look-up' },
    { sort: false, name: 'contactEnrichment', type: 'text', label: 'Used for Contact Enrichment' },
    { sort: false, name: 'total', type: 'text', label: 'Total Used' }
  ]
  const actions: Actions<ApolloCreditUsuage> = []
  const templateColumns = '1fr 1fr 1fr 1fr'

  return (
    <Modal
      open
      onHide={() => closeModal()}
      title='Contact Credit Usage History'
      handleSave={() => undefined}
      size='lg'
      hideSaveButton
      cancelBtnText='Close'
    >
      <TableProvider<ApolloCreditUsuage>
        ObjectClass={ApolloCreditUsuage}
        fields={fields}
        actions={actions}
        noEmail
        noExport
        specialSort={['Monthly', 'Weekly', 'Daily']}
        gridTemplateColumns={templateColumns}
        initialSearchParams={{
          filters: [],
          sortColumn: 'Monthly',
          pageNumber: 1,
          sortOrder: 'asc',
          pageSize: 20
        }}
      >
        <TableList<ApolloCreditUsuage> noTotal />
      </TableProvider>
    </Modal>
  )
}
