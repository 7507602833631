import { BasePortComponent } from 'jsplumbtoolkit-react'
import { Chip } from '@material-ui/core'
import { AddCircle } from '@material-ui/icons'
import { EVENT_TYPE, Emitter } from '../canvas/helpers/EventEmitter'

export class BasePort extends BasePortComponent {
  render () {
    const width = 210
    const margin = this.props.index ? 4 : 0
    const liveState = this.props.data
    let connected = true
    const toolkit = this.parent?.toolkit
    let port
    let node

    if (toolkit) {
      node = toolkit.getNode(this.parent.state.id)
      port = node.getPort(liveState.id)
      if (port) {
        const edges = port.getEdges()
        if (edges.length === 0) {
          connected = false
        }
      }
    }
    return (
      <div
        className={['noportdrag']}
        style={{ marginTop: margin, width: width, position: 'relative' }}
        data-port-id={liveState.id}
      >
        <Chip
          style={liveState.type !== 'integration' ? { backgroundColor: this.props.color, width: width, borderRadius: 2, height: 25 } : { backgroundColor: 'white', width: width, border: '1px solid #49CDA2', borderRadius: 2, height: 25, color: '#49CDA2' }}
          label={liveState.label}
          color='primary'
        />
        <div
          className='node-port'
          data-port-id={liveState.id}
        >
          <jtk-target
            scope='default'
          />
          <jtk-source
            port-id={liveState.id}
            scope='default'
            className='node-port'
          />
        </div>
        {!connected &&
          <div
            onClick={(e) => {
              if (port && toolkit && node) {
                Emitter.emit(EVENT_TYPE.DROP_EDGE, { data: { anchorEl: e.currentTarget, source: port, top: node.data.top + 200, left: node.data.left + 250 }, toolkitID: toolkit.id })
              }
            }}
            style={{
              position: 'absolute',
              cursor: 'pointer',
              top: 4,
              right: -8,
              backgroundColor: 'white',
              height: 16,
              width: 16,
              borderRadius: '100%'
            }}
          >
            <AddCircle style={{ position: 'absolute', color: '#5A7FE1', height: 16, width: 16 }} />
          </div>}
      </div>
    )
  }
}
