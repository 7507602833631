import { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Input from '@material-ui/core/Input'
import FilterSelector from '../../criteria/FilterSelector'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import ConditionalDivider from 'cf-components/ConditionalDivider'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'
import DeleteIcon from '@material-ui/icons/DeleteOutline'
import ActionsMenu from 'cf-components/ActionsMenu'
import { Formik, FieldArray } from 'formik'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { EVENT_TYPE, Emitter } from 'emitter'
import { Modal, ModalSection } from 'library/Modal'
import { Button } from 'library/materialUI'

const useStyles = makeStyles(theme => ({
  contentClass: {
    overflowY: 'auto',
    height: 260,
    backgroundColor: theme.palette.background.extraLightGrey
  },
  titleClass: {
    backgroundColor: theme.palette.background.extraLightGrey,
    paddingLeft: 40
  },
  divider: {
    marginTop: 2,
    marginBottom: 5
  },
  input: {
    marginTop: 5,
    borderRadius: 6,
    border: `${theme.palette.background.mediumGrey} 1px solid`,
    paddingLeft: 10,
    color: theme.palette.text.primary
  },
  subtitle: {
    fontSize: 14,
    fontWeight: 600
  },
  filters: {
    backgroundColor: theme.palette.background.lightGrey,
    border: `${theme.palette.primary.main} 1px solid`,
    borderRadius: 8,
    marginTop: 5,
    minHeight: 100
  },
  icon: {
    marginRight: 10
  },
  groupHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '5px 5px 0px 15px'
  },
  buttonPadding: {
    paddingLeft: 15
  },
  error: {
    border: 'red 1px solid'
  },
  errorText: {
    color: 'red',
    fontSize: 10,
    marginLeft: 20,
    marginTop: 2
  }
}))

const tempFilter = [{
  prop: '',
  cmp: '',
  value: []
}]

const FilterGroup = (props) => {
  const classes = useStyles()

  const actions = [
    ...[{ name: 'Duplicate', icon: FileCopyOutlinedIcon, action: () => props.duplicateFilterGroup() }],
    ...[{ name: 'Delete', icon: DeleteIcon, action: () => props.removeFilterGroup() }]
  ]

  return (
    <FieldArray
      name={`allGroups[${props.index}]`}
      render={arrayHelpers => (
        <div className={`${classes.filters} ${classes.margins}`}>
          <div className={classes.groupHeader}>
            <div>Visitor Filter Group</div>
            <ActionsMenu
              color='primary'
              actions={actions}
            />
          </div>
          {props.filters.map((filter, idx) => (
            <div key={idx}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <FilterSelector
                  filter={filter}
                  chatServiceUrl={props.chatServiceUrl}
                  removeFilter={() => arrayHelpers.remove(idx)}
                  propPrefix={`allGroups[${props.index}][${idx}]`}
                  propFilter={prop => {
                    return prop.group === 'availability'
                  }}
                  customFilters={{ filterGroups: [{ value: 'availability', name: 'Availability' }] }}
                />
                <ConditionalDivider
                  operator='and'
                  filterLen={props.filters.length}
                  index={idx}
                />
              </MuiPickersUtilsProvider>
            </div>
          ))}
          <Button
            variant='text'
            onClick={() => arrayHelpers.push(tempFilter[0])}
            className={classes.buttonPadding}
            style={{ textTransform: 'none', color: '#9933FF', marginBottom: 10 }}
          >
            <AddCircleIcon className={classes.icon} />
            Add Filter
          </Button>
        </div>
      )}
    />
  )
}

const FAQConditionModal = (props) => {
  const classes = useStyles()
  const openModal = props.openModal
  const [name, setName] = useState(props.condition.name || '')
  const [nameError, setNameError] = useState(false)
  const [filterError, setFilterError] = useState(false)
  const allGroups = props.condition.filters || [tempFilter]

  const makeChanges = (type, values = {}) => {
    // if values.allGroups has data in it the structure will be as follows:
    // [
    //   [
    //     {
    //       prop: 'filter criteria',
    //       cmp: 'filter comparison',
    //       value: ['filter value','or another value']
    //     },
    //     {...additional filters if they exist}
    //   ],
    //   [...additional filter groups if they exist]
    // ]

    const notEmptyGroups = values.allGroups?.map((element) => {
      const notEmptyCriteria = element.filter((el) => {
        if (el.cmp && el.prop && el.value) {
          return true
        }
        return false
      })
      if (notEmptyCriteria.length > 0) {
        return notEmptyCriteria
      }
      return undefined
    }).filter((element) => element !== undefined)

    let condition = {}
    if (type === 'save') {
      if (name && notEmptyGroups.length > 0) {
        condition = { name: name, filters: notEmptyGroups }
        setFilterError(false)
        props.saveCancel(condition, props.editIndex, type)
      } else {
        if (!name) {
          setNameError(true)
        }
        if (notEmptyGroups.length === 0) {
          setFilterError(true)
        }
      }
    } else {
      props.saveCancel(condition, props.editIndex, type)
    }
  }

  const handleInputChange = (val) => {
    if (val.length > 0) {
      setNameError(false)
    } else {
      setNameError(true)
    }
    setName(val)
  }

  Emitter.on(EVENT_TYPE.BROWSER_BACK, () => makeChanges('cancel'))

  return (
    <Formik
      initialValues={{ allGroups: allGroups }}
      onSubmit={(values) => makeChanges('save', values)}
      render={({ values, submitForm }) => {
        return (
          <Modal
            open={openModal}
            onHide={() => makeChanges('cancel')}
            handleSave={submitForm}
            size='md'
            title='Live Chat Availability'
            helplink='https://help.getsignals.ai/article/xoj9ozntma-faq-node'
            helplinkLabel='Learn more about this skill'
            saveIcon='save'
          >
            <ModalSection
              title='Condition Name'
              subtitle='Enter a name for your condition'
            >
              <Input
                className={nameError ? `${classes.input} ${classes.margins} ${classes.error}` : `${classes.input} ${classes.margins}`}
                disableUnderline
                placeholder='Condition Name'
                value={name}
                onChange={(e) => handleInputChange(e.target.value)}
                style={{ width: '100%' }}
              />
              {nameError && (<div className={classes.errorText}>The condition must be given a name</div>)}
            </ModalSection>
            <ModalSection
              title='Filters'
              subtitle='Visitors must meet ALL of the criteria listed here to be included in the group'
            >
              <FieldArray
                name='allGroups'
                render={arrayHelpers => (
                  <div>
                    {values.allGroups.map((filters, idx) => (
                      <div key={idx}>
                        <FilterGroup
                          index={idx}
                          filters={filters}
                          duplicateFilterGroup={() => arrayHelpers.push([...filters])}
                          removeFilterGroup={() => arrayHelpers.remove(idx)}
                          chatServiceUrl={props.chatServiceUrl}
                        />
                        <ConditionalDivider
                          operator='or'
                          filterLen={values.allGroups.length}
                          index={idx}
                        />
                      </div>
                    ))}
                    <div className={classes.margins} style={{ marginTop: 20 }}>
                      <Button
                        color='primary'
                        variant='outlined'
                        onClick={() => arrayHelpers.push(tempFilter)}
                        style={{ textTransform: 'none' }}
                      >
                        Add Filter Group
                      </Button>
                    </div>
                  </div>
                )}
              />
              {filterError && (<div className={classes.errorText}>A non empty filter must be defined</div>)}
            </ModalSection>
          </Modal>
        )
      }}
    />
  )
}

export default FAQConditionModal
