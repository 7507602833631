import UseCfQuery from 'use-cf-query'
import { doGet } from './api'
import { TreeChild } from 'cf-components/TreeMultiSelect'

export const getLocations = (): any => UseCfQuery<TreeChild[]>(['locations'], () => doGet({
  path: '/locations',
  useChatURL: true
}).then(res => res.data), {
  staleTime: 1200000
})

export const getCountries = (): Promise<any> => {
  return doGet({
    path: '/locations/countries',
    useChatURL: true
  })
}

export const getTerritories = (country: string): Promise<any> => {
  return doGet({
    path: `/locations/territories/${country}`,
    useChatURL: true
  })
}
