import { Button, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import { useHistory } from 'react-router-dom'

const FreeTrialBanner = props => {
  const billing = props.billing
  const history = useHistory()

  if (!billing) {
    return <></>
  }
  let expirationInDays
  if (billing.attributes?.expires_timestamp) {
    const expires = new Date(billing.attributes?.expires_timestamp)
    const today = new Date()
    const timeDiff = expires - today
    expirationInDays = Math.floor(timeDiff / (1000 * 60 * 60 * 24))
  }

  let trialBannerMessage = ''
  if (expirationInDays) {
    if (expirationInDays === 1) {
      trialBannerMessage = `Your free trial will expire in ${expirationInDays} day. Click here to upgrade your plan. 👉`
    } else {
      trialBannerMessage = `Your free trial will expire in ${expirationInDays} days. Click here to upgrade your plan. 👉`
    }
  }
  if (expirationInDays < 1) {
    trialBannerMessage = 'Your free trial has expired. Click here to upgrade it!  👉'
  }
  return (
    <div style={{ display: props.trialBanner ? 'flex' : 'none', backgroundColor: '#fd5c5c', justifyContent: 'space-between', color: 'white', fontSize: 14, padding: 5, alignItems: 'center' }}>
      <div />
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div>
          <InfoOutlinedIcon fontSize='small' style={{ fill: 'white' }} />
        </div>
        <div style={{ marginLeft: 5, fontWeight: 500 }}>
          {trialBannerMessage}
        </div>
        <div style={{ marginLeft: 10, padding: 2 }}>
          <Button
            style={{ backgroundColor: '#55ab58', color: 'white', borderRadius: 20, padding: '3px 20px' }}
            onClick={() => history.push({
              pathname: '/settings/subscriptions/plan'
            })}
          >
            UPGRADE NOW
          </Button>
        </div>
      </div>
      <div>
        <IconButton onClick={() => props.closeBanner(false)} size='small'>
          <CloseIcon fontSize='small' style={{ fill: 'white' }} />
        </IconButton>
      </div>
    </div>
  )
}

export default FreeTrialBanner
