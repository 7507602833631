import { Dispatch, useContext, useEffect, useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import ReplayIcon from '@material-ui/icons/Replay'
import BusinessIcon from '@material-ui/icons/Business'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import LaptopIcon from '@material-ui/icons/Laptop'
import EditIcon from '@material-ui/icons/Edit'
import PeopleIcon from '@material-ui/icons/People'
import { revenueRangeConfig, employeeRangeConfig, employeeTextMap, getEmployeeDisplay, getRevenueDisplay, revenueTextMap, handleSetRequiredFields, getRequiredCount, getMaxRequired } from './accountICPHelpers'
import ICPField, { ICPFieldTitle } from './AccountICPField'
import { ScrollBox } from 'cf-components/ScrollBox'
import { Divider, Typography } from 'library/materialUI'
import { Select } from 'cf-components/material-wrappers/MaterialComponents'
import InformationIcon from 'library/InformationIcon'
import { ICPFilters, ICPRequiredFields } from 'classes/icp'
import { ICPContext } from './ICPProvider'
import Checkbox from '@material-ui/core/Checkbox'

const ICPCriteriaOptions = [
  { value: 1, label: '1 of 5 criteria', disabled: false },
  { value: 2, label: '2 of 5 criteria', disabled: false },
  { value: 3, label: '3 of 5 criteria', disabled: false },
  { value: 4, label: '4 of 5 criteria', disabled: false },
  { value: 5, label: '5 of 5 criteria', disabled: false }
]

const requiredMatchInfoIcon = (
  <>
    <span style={{ paddingLeft: 10 }} />
    <InformationIcon
      message='The minimum number of criteria that on account must match to be a part of your ICP'
      color='grey'
    />
  </>
)

export const IcpEditCard = withStyles(theme => ({
  card: {
    fontSize: '.8em'
  },
  topRow: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  cardTitle: {
    fontSize: '1.2em',
    fontWeight: 600
  },
  editButton: {
    position: 'relative',
    top: -4,
    right: -4
  },
  fieldsCtn: {
    display: 'flex',
    flexFlow: 'column nowrap'
  },
  iconButtonHolder: {
    height: 30,
    width: 30
  },
  divider: {
    margin: '10px 0'
  }
}))((props: {
  companyName: string
  icpFieldOptions: ICPFilters<'formatted'>
  classes: import('@material-ui/styles').ClassNameMap
}): JSX.Element => {
  const classes = props.classes
  const [requiredOptions, setRequiredOptions] = useState(ICPCriteriaOptions)
  const {
    resetICP,
    setLocalICP,
    localICP,
    dirty,
    icpFilters,
    icpFiltersDispatch
  } = useContext(ICPContext)
  let newCount = localICP.requiredMatchCount
  const maxRequired = getMaxRequired(icpFilters)
  if (maxRequired < newCount) {
    newCount = maxRequired
  }

  HandleDisableRequiredOptions(
    { setRequiredOptions, requiredFields: localICP.requiredFields, requiredOptions, maxRequired }
  )

  const handleChange = (type: keyof ICPFilters | 'reset', value: string[] | number[]): void => {
    if (type === 'industry' || type === 'country' || type === 'technologies') {
      icpFiltersDispatch({ [type]: value })
      setLocalICP({ requiredMatchCount: newCount })
    } else if (type === 'reset') {
      resetICP()
    } else {
      icpFiltersDispatch({ [type]: { gt: value[0], lt: value[1] } })
    }
  }

  const _handleSetRequiredFields = (requiredField: keyof ICPRequiredFields) => {
    handleSetRequiredFields(setLocalICP, localICP, requiredField)
  }

  return (
    <div className={classes.card}>
      <div className={classes.topRow}>
        <div className={classes.cardTitle}>Ideal Customer Profile for {props.companyName}:</div>
        {dirty ? (
          <Tooltip title='Reset'>
            <IconButton className={classes.editButton} color='primary' onClick={() => handleChange('reset', [])} size='small' aria-label='reset form'>
              <ReplayIcon />
            </IconButton>
          </Tooltip>
        ) : <div className={classes.iconButtonHolder} />}
      </div>
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography>CRITERIA</Typography>
          <Typography>REQUIRED</Typography>
        </div>
        <Divider className={classes.divider} />
      </div>
      <div className={classes.fieldsCtn}>
        <ICPField
          title='Industry'
          icon={BusinessIcon}
          value={icpFilters.industry}
          valueOptions={props.icpFieldOptions.industry}
          setValue={(value => handleChange('industry', value))}
          required={localICP.requiredFields.industry}
          setRequired={() => _handleSetRequiredFields('industry')}
          backend
        />
        <Divider />
        <ICPField
          title='Location'
          icon={LocationOnIcon}
          value={icpFilters.country}
          valueOptions={props.icpFieldOptions.country}
          setValue={(value => handleChange('country', value))}
          required={localICP.requiredFields.location}
          setRequired={() => _handleSetRequiredFields('location')}
        />
        <Divider />
        <ICPField
          title='Technologies'
          icon={LaptopIcon}
          value={icpFilters.technologies}
          backend
          setValue={(value => handleChange('technologies', value))}
          required={localICP.requiredFields.technologies}
          setRequired={() => _handleSetRequiredFields('technologies')}
        />
        <div style={{ display: 'flex', alignItems: 'center', paddingLeft: 27 }}>
          <Checkbox
            color='primary'
            checked={(localICP.filterComparators?.technologies === 'has_all')}
            onClick={(e) => {
              if ((e.target as HTMLInputElement).checked) {
                setLocalICP({ filterComparators: { technologies: 'has_all' } })
              } else {
                setLocalICP({ filterComparators: { technologies: 'has_one' } })
              }
            }}
          />
          <Typography variant='body2'>Match all technologies</Typography>
        </div>
        <Divider />
        <ICPField
          title='Annual Revenue' icon={AttachMoneyIcon}
          displayValue={getRevenueDisplay([icpFilters.annualRevenue?.gt || 0, icpFilters.annualRevenue?.lt || 12])}
          range={{
            ...revenueRangeConfig,
            value: [icpFilters.annualRevenue?.gt || 0, icpFilters.annualRevenue?.lt || 12],
            tipFormatter: (value: number) => revenueTextMap[value],
            onChange: ([gt, lt]: [number, number]) => handleChange('annualRevenue', [gt, lt])
          }}
          required={localICP.requiredFields.annual_revenue}
          setRequired={() => _handleSetRequiredFields('annual_revenue')}
        />
        <Divider />
        <ICPField
          title='Employee Count' icon={PeopleIcon}
          displayValue={getEmployeeDisplay([icpFilters.employeeCount?.gt || 0, icpFilters.employeeCount?.lt || 13])}
          range={{
            ...employeeRangeConfig,
            value: [(icpFilters.employeeCount || {}).gt || 0, (icpFilters.employeeCount || {}).lt || 13],
            tipFormatter: (value: number) => employeeTextMap[value],
            onChange: ([gt, lt]: [number, number]) => handleChange('employeeCount', [gt, lt])
          }}
          required={localICP.requiredFields.employee_count}
          setRequired={() => _handleSetRequiredFields('employee_count')}
        />
      </div>
      <Divider className={classes.divider} />
      <div>
        <div style={{ display: 'flex' }}>
          <Typography style={{ display: 'flex' }}>Required to match </Typography>
          {requiredMatchInfoIcon}
        </div>
        <Select
          onChange={(value: number) => {
            setLocalICP({ requiredMatchCount: value })
          }}
          value={newCount}
          options={requiredOptions}
          disableClear
        />
      </div>
    </div>
  )
})

export const IcpViewCard = withStyles(theme => ({
  card: {
    fontSize: '.8em'
  },
  topRow: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  cardTitle: {
    fontSize: '1.2em',
    fontWeight: 600
  },
  editButton: {
    position: 'relative',
    top: -4,
    right: -4
  },
  disabled: {
    opacity: 0.7
  },
  fieldsCtn: {
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'space-around',
    color: theme.palette.text.primary,
    fontSize: '1.1em'
  },
  accountsCtn: {
    border: `1px solid ${theme.palette.primary.light}`,
    borderRadius: 10,
    padding: '10px 30px',
    maxHeight: '565px',
    '& > div:first-child': {
      fontWeight: 600,
      fontSize: '1.1em'
    }
  },
  iconButtonHolder: {
    height: 30,
    width: 30
  }
}))((props: {
  classes: import('@material-ui/styles').ClassNameMap
  companyName: string
  editICP: () => void
}): JSX.Element => {
  const { icpFilters } = useContext(ICPContext)
  const classes = props.classes
  return (
    <div className={classes.card}>
      <div className={classes.topRow}>
        <div className={classes.cardTitle}>Ideal Customer Profile for {props.companyName}:</div>
        <Tooltip title='Edit'>
          <IconButton className={classes.editButton} color='primary' onClick={() => props.editICP()} size='small' aria-label='edit'>
            <EditIcon />
          </IconButton>
        </Tooltip>
      </div>
      <ScrollBox className={classes.accountsCtn} backgroundColor='rgb(249 246 253)' noScrollBar>
        <div className={classes.fieldsCtn}>
          <ICPFieldTitle
            title='Industry' icon={BusinessIcon}
          >
            {(icpFilters.industry || []).join(', ') || <em className={classes.disabled}>No Restriction</em>}
          </ICPFieldTitle>
          <ICPFieldTitle
            title='Location' icon={LocationOnIcon}
          >
            {(icpFilters.country || []).join(', ') || <em className={classes.disabled}>No Restriction</em>}
          </ICPFieldTitle>
          <ICPFieldTitle
            title='Technologies' icon={LaptopIcon}
          >
            {(icpFilters.technologies || []).join(', ') || <em className={classes.disabled}>No Restriction</em>}
          </ICPFieldTitle>
          <ICPFieldTitle
            title='Annual Revenue' icon={AttachMoneyIcon}
          >
            {getRevenueDisplay([icpFilters.annualRevenue?.gt || 0, icpFilters.annualRevenue?.lt || 12])}
          </ICPFieldTitle>
          <ICPFieldTitle
            title='Employee Count' icon={PeopleIcon}
          >
            {getEmployeeDisplay([icpFilters.employeeCount?.gt || 0, icpFilters.employeeCount?.lt || 10])}
          </ICPFieldTitle>
        </div>
      </ScrollBox>
    </div>
  )
})

function HandleDisableRequiredOptions (
  { setRequiredOptions, requiredFields, requiredOptions, maxRequired }: {
    setRequiredOptions: Dispatch<typeof ICPCriteriaOptions>,
    requiredFields: ICPRequiredFields,
    requiredOptions: typeof ICPCriteriaOptions,
    maxRequired: number
  }) {
  useEffect(() => {
    const requiredCount = getRequiredCount(requiredFields)
    const newOptions = requiredOptions.map((opt) => {
      if (opt.value < requiredCount || opt.value > maxRequired) {
        opt.disabled = true
      } else {
        opt.disabled = false
      }
      return opt
    })
    setRequiredOptions(newOptions)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requiredFields, maxRequired])
}
