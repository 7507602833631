import { useQuery, useInfiniteQuery } from 'react-query'
import { doGet } from 'api/api'
import { keyExtraHeaders, parameterizeFilters, SearchParams } from './queryHelpers'
import { useState } from 'react'
import { isArray } from 'lodash'

interface queryProps {
  useChatURL?: boolean
  single?: boolean
  path: string
  objectClass: any
  handleCustomResponse?: any,
  initialData?: any,
  searchParams?: SearchParams
  extraHeaders?: any
  refetchInterval?: number
  keepPreviousData?: boolean
}

export interface queryResult<T> {
  data: T,
  isLoading: boolean,
  isError: boolean,
  refetch: () => void,
  error: Error
}

export interface infiniteQueryResult extends queryResult<any> {
  getNextPage: () => void
  hasNextPage: any
  isFetchingNextPage: any
  hasFetchedNextPage: boolean
  setHasFetchedNextPageToFalse: () => void
}

const isSingleObject = (key: string[], single: boolean): Boolean => {
  if (single) {
    return true
  }
  const lastItem = key[key.length - 1]
  return !isNaN(Number(lastItem))
}

const handleListResponse = (data: any, ObjectClass: any, meta: any): any => {
  const list = data.map((row: any) => { const obj = new ObjectClass({ row }); return obj })
  const dict: any = {}
  for (const item of list) { dict[item.id] = item }
  return ({ list, dict, meta })
}

export const handleResponse = (response: any, ObjectClass: any): any => {
  if (!ObjectClass) {
    return response
  }
  const data = response.data
  const meta = response.meta
  if (Array.isArray(data)) {
    return handleListResponse(data, ObjectClass, meta)
  } else {
    return new ObjectClass({ row: data })
  }
}

export function useDoQuery<T> (parameters: queryProps): queryResult<T> {
  const defaultData = {
    list: [],
    dict: {},
    meta: { total: 0 }
  }
  const { useChatURL, objectClass, handleCustomResponse, initialData, searchParams, extraHeaders, refetchInterval, keepPreviousData } = parameters
  let path = parameters.path
  if (searchParams) {
    path = parameterizeFilters({ searchParams, path })
  }

  let key = path.substr(1).split('/')
  key = key.slice(0, -1).concat(key.slice(-1)[0].split('?'))

  if (extraHeaders) {
    key = key.concat(keyExtraHeaders({ extraHeaders }))
  }
  const queryFunction = (): Promise<any> => doGet({ path, useChatURL, extraHeaders })
    .then(response => handleResponse(response, objectClass))
    .then(response => {
      if (handleCustomResponse) {
        return handleCustomResponse(response)
      }
      return response
    })

  const fallbackData = isSingleObject(key, parameters.single || false) ? null : defaultData
  const placeholder = initialData === undefined ? fallbackData : initialData
  const { data, isFetched, isFetching, refetch, isError, error } = useQuery(key, queryFunction, {
    staleTime: 120000,
    retry: false,
    placeholderData: placeholder,
    refetchInterval: refetchInterval,
    keepPreviousData: keepPreviousData
  })

  const isLoading = isFetching && !isFetched
  const typedError = error as Error
  return { data, isLoading, refetch, isError, error: typedError }
}
export type DataList = { dict: Record<string, any>, list: Array<any>, meta: any }

export const useDoInfiniteQuery = ({ useChatURL, objectClass, handleCustomResponse, searchParams, extraHeaders, path, keepPreviousData }: queryProps): infiniteQueryResult => {
  const [pageSize, setPageSize] = useState(searchParams?.pageSize || 12)
  const [hasFetchedNextPage, setHasFetchedNextPage] = useState(false)

  let Newpath = path
  if (searchParams) {
    searchParams.pageSize = pageSize
    Newpath = parameterizeFilters({ searchParams, path: Newpath })
  }

  let key = Newpath.substr(1).split('/')
  key = key.slice(0, -1).concat(key.slice(-1)[0].split('?'))

  if (extraHeaders) {
    key = key.concat(keyExtraHeaders({ extraHeaders }))
  }
  const queryFunction = (): Promise<any> => doGet({ path: Newpath, useChatURL, extraHeaders })
    .then(response => handleResponse(response, objectClass))
    .then(response => {
      if (handleCustomResponse) {
        return handleCustomResponse(response)
      } else return response
    }).catch((err: any) => {
      return { error: err, isError: true, isLoading: false }
    })

  const {
    data,
    error,
    isError,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isFetched
  } = useInfiniteQuery(
    key,
    queryFunction,
    {
      getNextPageParam: (pages) => pages.list.length < pages.meta?.total,
      initialData: { pages: [{ list: [], dict: {}, meta: { total: 0 } }], pageParams: [] },
      keepPreviousData: keepPreviousData
    }
  )

  const getNextPage = () => {
    setPageSize(pageSize + 12)
    setHasFetchedNextPage(true)
  }
  const setHasFetchedNextPageToFalse = () => {
    setHasFetchedNextPage(false)
  }
  const isLoading = isFetching && !isFetched
  const typedError = error as Error
  return {
    data,
    error: typedError,
    isError,
    getNextPage,
    hasNextPage,
    isLoading,
    isFetchingNextPage,
    refetch: () => undefined,
    hasFetchedNextPage,
    setHasFetchedNextPageToFalse
  }
}

type QPSansObject = Omit<queryProps, 'objectClass'>
type QRSansRefetch<T> = {
  data: T[] | T
  isLoading: boolean
  isError: boolean
  error: Error
}
type TypedData<T> = { data: { attributes: T } }
export function useDoTypeQuery<T> (props: QPSansObject): QRSansRefetch<T> {
  const { data, isLoading, isError, error } = useDoQuery({ ...props, objectClass: undefined })
  if (typeof data === 'undefined' || !data || isError || isLoading) {
    return { data: [], isLoading, isError, error }
  }
  if (data.length > 0 && isArray(data)) {
    const returnList: T[] = []
    data.forEach((obj: any) => {
      if (isTypedData(obj)) {
        returnList.push(obj.data.attributes)
      } else {
        returnList.push(obj.data)
      }
    })
    return { data: returnList, isLoading, isError, error }
  }
  if (isTypedData(data)) {
    const typedData = instanceOfJsonResp<T>(data)
    return { data: typedData.data.attributes, isLoading, isError, error }
  }
  if (isNoAttr(data)) {
    return { data: data.data, isLoading, isError, error }
  }
  const dataList = data?.list || []
  return { data: dataList, isLoading, isError, error }
}

function instanceOfJsonResp<T> (object: any): TypedData<T> {
  const check1 = 'data' in object
  let check2 = false
  if (check1) {
    check2 = 'attributes' in object.data
  }
  return check2 ? object : { data: { attributes: {} } };
}

function isTypedData (object: any): boolean {
  const check1 = 'data' in object
  let check2 = false
  if (check1) {
    check2 = 'attributes' in object.data
  }
  return check2
}

function isNoAttr (object: any): boolean {
  const check1 = 'data' in object
  return check1
}
