/* eslint-disable @typescript-eslint/naming-convention */
import {
  FilterTextValue,
  FilterOccurrenceSelect,
  FilterNumericValue,
  FilterLeadScoreSelect,
  FilterMeetingTypeSelect,
  FilterLocation,
  FilterSingleBotNameSelect
} from 'pages/bot/criteria/FilterProperties'
import { CountrySelect, StateSelect } from 'pages/bot/criteria/GeographyFilters'

const filterGroups = [
  { value: 'contact_attributes', name: 'Contact Attributes' },
  { value: 'raw_events', name: 'Raw Events' },
  { value: 'bot_events', name: 'Bot Events' }
]

const contactComps = [
  { value: 'eq', name: 'equals' },
  { value: 'ne', name: 'does not equal' },
  { value: 'contains', name: 'contains' },
  { value: 'does_not_contain', name: 'does not contain' },
  { value: 'is_known', name: 'is known' },
  { value: 'not_known', name: 'is not known' }
]

const filterProps = [
  // Contact Values
  { value: 'first_name', name: 'First name', group: 'contact_attributes' },
  { value: 'last_name', name: 'Last name', group: 'contact_attributes' },
  { value: 'phone', name: 'Phone', group: 'contact_attributes' },
  { value: 'email', name: 'Email', group: 'contact_attributes' },
  { value: 'lead_score', name: 'Lead score', group: 'contact_attributes' },
  { value: 'title', name: 'Title', group: 'contact_attributes' },
  { value: 'company_name', name: 'Company name', group: 'contact_attributes' },
  { value: 'location', name: 'Location', group: 'contact_attributes' },
  { value: 'city', name: 'City', group: 'contact_attributes' },
  // { value: 'us_state', name: 'State', group: 'contact_attributes' },
  // { value: 'country', name: 'Country', group: 'contact_attributes' },
  { value: 'role', name: 'Role', group: 'contact_attributes' },
  { value: 'seniority', name: 'Seniority', group: 'contact_attributes' },
  // Events
  { value: 'bot_conversation_held', name: 'Bot conversation', group: 'bot_events' },
  { value: 'primary_goal_achieved', name: 'Primary goal', group: 'bot_events' },
  { value: 'secondary_goal_achieved', name: 'Secondary goal', group: 'bot_events' },
  // Raw events
  { value: 'meeting_booked', name: 'Meeting booked', group: 'raw_events' },
  { value: 'live_chat', name: 'Chat with agent', group: 'raw_events' },
  { value: 'n_visits', name: 'Number of visits', group: 'raw_events' },
  { value: 'n_pages_viewed', name: 'Number of page views', group: 'raw_events' },
  { value: 'url_viewed', name: 'URLs visited', group: 'raw_events' }

]

const filterComps = {
  first_name: contactComps,
  last_name: contactComps,
  phone: contactComps,
  email: contactComps,
  company_name: contactComps,
  title: contactComps,
  lead_score: [
    { value: 'gt', name: 'greater than' },
    { value: 'lt', name: 'less than' },
    { value: 'eq', name: 'equals' }
  ],
  city: contactComps,
  location: [
    { value: 'in', name: 'in' },
    { value: 'not_in', name: 'not in' }
  ],
  us_state: [{ value: 'in', name: 'in' },
    { value: 'not_in', name: 'not in' }
  ],
  country: [
    { value: 'in', name: 'in' },
    { value: 'not_in', name: 'not in' }
  ],
  role: contactComps,
  seniority: contactComps,
  bot_fired: [{ value: 'occurrence', name: 'has' }],
  bot_dismissed: [{ value: 'occurrence', name: 'has' }],
  bot_conversation_held: [
    { value: 'with', name: 'with' }
  ],
  bot_email_captured: [{ value: 'occurrence', name: 'has' }],
  bot_meeting_booked: [{ value: 'occurrence', name: 'has' }],
  bot_calendar_dropped: [{ value: 'occurrence', name: 'has' }],
  bot_phone_captured: [{ value: 'occurrence', name: 'has' }],
  bot_live_chat_with_agent: [{ value: 'occurrence', name: 'has' }],
  primary_goal_achieved: [{ value: 'occurrence', name: 'has' }],
  secondary_goal_achieved: [{ value: 'occurrence', name: 'has' }],
  meeting_booked: [{ value: 'occurrence', name: 'has' }],
  phone_captured: [{ value: 'occurrence', name: 'has' }],
  live_chat: [{ value: 'occurrence', name: 'has' }],
  n_visits: [
    { value: 'gt', name: 'greater than' },
    { value: 'lt', name: 'less than' },
    { value: 'eq', name: 'equals' }
  ],
  n_pages_viewed: [
    { value: 'gt', name: 'greater than' },
    { value: 'lt', name: 'less than' },
    { value: 'eq', name: 'equals' }
  ],
  failed_to_book_meeting: [{ value: 'from', name: 'from' }],
  url_viewed: [
    { value: 'eq', name: 'equals' },
    { value: 'ne', name: 'does not equal' },
    { value: 'contains', name: 'contains' },
    { value: 'does_not_contain', name: 'does not contain' }
  ]
}

const filterValues = {
  first_name: FilterTextValue,
  last_name: FilterTextValue,
  phone: FilterTextValue,
  email: FilterTextValue,
  company_name: FilterTextValue,
  lead_score: FilterLeadScoreSelect,
  title: FilterTextValue,
  city: FilterTextValue,
  us_state: StateSelect,
  country: CountrySelect,
  role: FilterTextValue,
  seniority: FilterTextValue,
  bot_fired: FilterOccurrenceSelect,
  bot_dismissed: FilterOccurrenceSelect,
  bot_conversation_held: FilterSingleBotNameSelect,
  bot_email_captured: FilterOccurrenceSelect,
  bot_meeting_booked: FilterOccurrenceSelect,
  bot_calendar_dropped: FilterOccurrenceSelect,
  bot_phone_captured: FilterOccurrenceSelect,
  bot_live_chat_with_agent: FilterOccurrenceSelect,
  primary_goal_achieved: FilterOccurrenceSelect,
  secondary_goal_achieved: FilterOccurrenceSelect,
  meeting_booked: FilterOccurrenceSelect,
  phone_captured: FilterOccurrenceSelect,
  live_chat: FilterOccurrenceSelect,
  n_visits: FilterNumericValue,
  n_pages_viewed: FilterNumericValue,
  failed_to_book_meeting: FilterMeetingTypeSelect,
  location: FilterLocation,
  url_viewed: FilterTextValue
}

export const contactCustomFilters = {
  filterProps,
  filterValues,
  filterComps,
  filterGroups
}
