import CustomField from 'classes/customFields';
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField';
import { KeyboardDatePicker } from '@material-ui/pickers/DatePicker';
import MuiPickersUtilsProvider from '@material-ui/pickers/MuiPickersUtilsProvider';
import DateFnsUtils from '@date-io/date-fns'
import PhoneInput from 'material-ui-phone-number'
import { Typography } from '@material-ui/core';
import { Select } from 'cf-components/material-wrappers/MaterialComponents';

const useStyles = makeStyles(theme => ({
  textBox: {
    margin: 0,
    width: '100%'
  },
  contactInput: {
    width: '100%',
    fontFamily: 'Poppins, sans serif'
  },
  inputField: {
    display: 'grid',
    gridTemplateColumns: '2fr 5fr',
    paddingRight: 20
  },
  multiLine: {
  },
  multiLineInput: {
    width: '100%',
    fontFamily: 'Poppins, sans serif',
    paddingTop: 10
  },
  inputLabel: {
    color: '#7C7C7C',
    fontSize: '13px',
    paddingTop: 5
  },
  inputText: {
    padding: '10px 12px 10px 12px',
    color: '#4A4A4A',
    fontSize: '14px'
  },
  errorDiv: {
    height: 20,
    display: 'flex',
    alignItems: 'center',
    fontSize: '10px',
    color: 'red'
  },
  dropDown: {
    width: '100%',
    fontFamily: 'Poppins, sans serif',
    color: '#4A4A4A',
    fontSize: '14px'
  },
  phoneInput: {
    width: 'calc(100% - 24px)',
    fontFamily: 'Poppins, sans serif',
    color: '#4A4A4A',
    fontSize: '14px',
    padding: '7px 11px 7px 11px',
    border: '1px solid rgba(0,0,0,.125)',
    borderRadius: '4px'
  },
  calendarIcon: {
    color: 'red',
    padding: 0
  },
  descriptionField: {
    display: 'grid',
    gridTemplateColumns: '123px auto',
    paddingRight: 20
  },
  deleteIcon: {
    fill: 'white'
  },
  chip: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    marginBottom: 5,
    marginRight: 5
  }
}))

function DropDownEntry ({ values, options, onChange, label }: { values: string[], options: string[], label: string, onChange: (value: any) => void }): JSX.Element {
  return (
    <div style={{ width: '100%' }}>
      <Select
        value={values}
        onChange={(value: any) => onChange(value)}
        options={options.map((name: any, _: number) => ({ value: name, label: name }))}
        label={label}
      />
    </div>
    )
}

function MultiDropDownEntry ({ values, options, onChange, label }: { values: string[], options: string[], label: string, onChange: (value: any) => void }): JSX.Element {
  return (
    <div style={{ width: '100%' }}>
      <Select
        value={values}
        onChange={(values: any) => onChange(values)}
        options={options.map((name: any, _: number) => ({ value: name, label: name }))}
        multiple
        label={label}
      />
    </div>
  )
}

function TextEntry ({ value, onChange, label }: {value: string, label: string, onChange: (value: any) => void }): JSX.Element {
  const classes = useStyles()
  return (
    <div style={{ width: '100%' }}>
      <TextField
        variant='outlined'
        margin='dense'
        value={value}
        onChange={e => onChange(e.target.value)}
        className={classes.textBox}
        InputProps={{ classes: { input: classes.inputText } }}
        label={label}
      />
    </div>
  )
}

function numericInput (event: any) {
  let key = event.keyCode || event.which
  key = String.fromCharCode(key)

  const regex = /[0-9-]/
  if (!regex.test(key)) {
    event.preventDefault()
  }
}

function NumericEntry ({ value, onChange, label }: {value: number, label: string, onChange: (value: any) => void }): JSX.Element {
  const classes = useStyles()
  return (
    <div style={{ width: '100%' }}>
      <TextField
        variant='outlined'
        margin='dense'
        value={value}
        onChange={e => onChange(e.target.value)}
        className={classes.textBox}
        InputProps={{ classes: { input: classes.inputText } }}
        onKeyPress={numericInput}
        label={label}
      />
      <Typography variant='body2' style={{ fontSize: 12, marginTop: 5 }}>Only numbers are valid inputs</Typography>
    </div>
  )
}

function DateEntry ({ value, onChange, label }: {value: string, onChange: (value: any) => void, label: string }): JSX.Element {
  const classes = useStyles()
  return (
    <div style={{ width: '100%' }}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          onChange={date => onChange(date)}
          className={classes.dropDown}
          format='MM/dd/yyyy'
          value={value}
          InputProps={{ classes: { input: classes.inputText } }}
          label={label}
        />
      </MuiPickersUtilsProvider>
    </div>
  )
}

function DateTimeEntry ({ value, onChange, label }: {value: string, onChange: (value: any) => void, label: string }): JSX.Element {
  return (
    <div style={{ width: '100%' }}>
      <TextField
        id='datetime-local'
        label={label}
        type='datetime-local'
        value={value}
        onChange={e => onChange(e.target.value)}
        InputLabelProps={{
          shrink: true
        }}
        inputProps={{ step: 1 }}
        style={{ width: '100%' }}
      />
    </div>
  )
}

function PhoneEntry ({ value, onChange }: {value: string, onChange: (value: any) => void }): JSX.Element {
  const classes = useStyles()
  return (
    <div style={{ width: '100%' }}>
      <PhoneInput
        value={value}
        onChange={(value: any) => { onChange(value) }}
        defaultCountry='us'
        disableAreaCodes
        variant='outlined'
        className={classes.textBox}
        InputProps={{ classes: { input: classes.inputText } }}
        regions={['north-america', 'south-america', 'central-america', 'europe', 'asia']}
      />
    </div>
  )
}

interface InputSelectorProps {
  type: string
  label: string
  options: string[]
  onChange: (value: any) => void
  value: any
}

function InputSelector ({ type, options, onChange, value, label }: InputSelectorProps): JSX.Element {
  switch (type) {
    case 'text':
      return (<TextEntry value={value} onChange={onChange} label={label} />)
    case 'phone':
      return (<PhoneEntry value={value} onChange={onChange} />)
    case 'date':
      return (<DateEntry value={value} onChange={onChange} label={label} />)
    case 'date-time':
      return (<DateTimeEntry value={value} onChange={onChange} label={label} />)
    case 'numeric':
      return (<NumericEntry value={value} onChange={onChange} label={label} />)
    case 'dropdown' || 'radio':
      return (<DropDownEntry values={value} options={options} onChange={onChange} label={label} />)
    case 'description':
      return (<TextEntry value={value} onChange={onChange} label={label} />)
    case 'true-false':
      return (
        <DropDownEntry
          values={value}
          options={['true', 'false']}
          onChange={(v: any) => {
            if (v === 'true') onChange(true)
            else onChange(false)
          }}
          label={label}
        />
      )
    case 'multi':
      if (!Array.isArray(value)) {
        value = []
      }
      return (<MultiDropDownEntry values={value} options={options} onChange={onChange} label={label} />)
    default:
      return <div>Invalid type</div>
  }
}

interface CustomFieldEntriesProps {
  customField: CustomField
  onChange: (value: any) => void
  value: any
}

function CustomFieldEntries ({ customField, onChange, value }: CustomFieldEntriesProps): JSX.Element {
  const type = customField.kind
  const label = customField.label
  const options = customField.options

  return (
    <InputSelector
      value={value}
      onChange={onChange}
      type={type}
      label={label}
      options={options}
    />
  )
}

interface CustomFieldDisplayProps {
  customField: CustomField
  value: any
}

function CustomFieldDisplay ({ customField, value }: CustomFieldDisplayProps): string {
  const type = customField.kind

  let displayValue = value

  if (type === 'true-false') {
    displayValue = value ? 'True' : 'False'
  } else if (type === 'date') {
    displayValue = new Date(value).toLocaleDateString()
  } else if (type === 'date-time') {
    displayValue = new Date(value).toLocaleString()
  } else if (type === 'multi') {
    displayValue = value.join(', ')
  }

  return displayValue
}

export { CustomFieldEntries, InputSelector, CustomFieldDisplay }
