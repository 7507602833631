import { Dispatch, SetStateAction, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Chip from '@material-ui/core/Chip'
import Button from '@material-ui/core/Button'
import Grow from '@material-ui/core/Grow'
import dateFormat from 'dateformat'
import Popover from '@material-ui/core/Popover'
import { DateRange, DefinedRange } from 'react-date-range'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import { SubMenuHeader } from './FilterBar/FilterBar'
import {
  addDays,
  // subDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  isSameDay
} from 'date-fns'

const useStyles = makeStyles(theme => ({
  dateRangePicker: {
    width: 365
  },
  datePopover: {
    marginTop: 10,
    borderRadius: 10,
    width: 600
  },
  dateControlButtonCtn: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  dateControlButton: {
    margin: '3px 10px'
  },
  definedRange: {
    marginTop: 60,
    width: 180
  }
}))

interface dateTypes {
  startDate: Date
  endDate: Date
}

interface dateLabelType {
  range: () => dateTypes
  label: string
}

interface DateRangeSelectorProps {
  label?: string
  selectText?: string
  cancelText?: string
  range: dateTypes
  handleSelect: (range: dateTypes) => void
}

interface DateRangePlus extends DateRangeSelectorProps {
  dateAnchorEl: Element | null
  setDateAnchorEl: Dispatch<SetStateAction<Element | null>>
}

const staticRangeHandler = {
  range: {},
  isSelected (range: dateTypes) {
    const definedRange = this.range()
    return (
      isSameDay(range.startDate, definedRange.startDate) &&
      isSameDay(range.endDate, definedRange.endDate)
    )
  }
}

function createStaticRanges (ranges: dateLabelType[]) {
  return ranges.map(range => ({ ...staticRangeHandler, ...range }))
}

const defineds = {
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1))
}

const futureStaticRanges = createStaticRanges([{
  label: 'This Week',
  range: () => ({
    startDate: defineds.startOfToday,
    endDate: defineds.endOfWeek
  })
}, {
  label: 'Last Week',
  range: () => ({
    startDate: startOfWeek(addDays(new Date(), -7)),
    endDate: endOfWeek(addDays(new Date(), -7))
  })
}, {
  label: 'Last 7 days',
  range: () => ({
    startDate: startOfDay(addDays(new Date(), -7)),
    endDate: defineds.startOfToday
  })
}, {
  label: 'Last 30 days',
  range: () => ({
    startDate: startOfDay(addDays(new Date(), -30)),
    endDate: defineds.startOfToday
  })
}])

export const dateHelper = {
  /**
   * Convert date string to date object
   * @param {string} dateStr the date with year, month, and day **ONLY** i.e. '2021-08-31'
   */
  stringToDate: (dateStr: string) => {
    const [year, month, day] = dateStr.split('-').map((num: string) => parseInt(num, 10))
    const returnDate = new Date()
    returnDate.setFullYear(year, month - 1, day)
    return returnDate
  },
  /**
   * Convert date to date string
   * @param {Date} dateObj the date to transform
   * @param {string} [format] the format (or mask) for the return string
   * @param {boolean} [format] convert local to UTC
   */
  dateToString: (dateObj: Date, format = 'yyyy-mm-dd', utc = false) =>
    dateFormat(dateObj, format, utc)
}

/**
 * @typedef {Object} StartEndDate
 * @property {Date | string} startDate
 * @property {Date | string} endDate
 */

/**
 * @typedef {Object} DateRangeSelectorProps
 * @property {string} [label]
 * @property {string} [selectText]
 * @property {string} [cancelText]
 * @property {StartEndDate} range
 * @property {(range: StartEndDate) => void} handleSelect
 */

/**
 * @param {DateRangeSelectorProps & { dateAnchorEl, setDateAnchorEl }} props
 */
export function DateRangeSelectorPopover (props: DateRangePlus) {
  const classes = useStyles()
  const { dateAnchorEl, setDateAnchorEl } = props
  const [dateRange, setDateRange] = useState(props.range)
  useMemo(() => {
    setDateRange(props.range)
  }, [props.range])

  const handleSelect = () => {
    props.handleSelect(dateRange)
    setDateAnchorEl(null)
  }
  const handleCancel = () => {
    setDateAnchorEl(null)
    setDateRange(props.range)
  }

  return (
    <Popover
      open={Boolean(dateAnchorEl)}
      anchorEl={dateAnchorEl}
      onClose={handleCancel}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      className={classes.datePopover}
    >
      <Grow in>
        <div>
          <SubMenuHeader
            label={props.label || 'Select dates'}
            onClick={handleCancel}
            className={classes.dateRangePicker}
            handleClose={handleCancel}
          />
          <div>
            <div style={{ display: 'flex' }}>
              <DefinedRange
                onChange={({ range1 }: any) => setDateRange(range1)}
                ranges={[dateRange]}
                inputRanges={[]}
                staticRanges={futureStaticRanges}
                className={classes.definedRange}
              />
              <DateRange
                editableDateInputs
                onChange={({ range1 }: any) => setDateRange(range1)}
                moveRangeOnFirstSelection={false}
                ranges={[dateRange]}
                scroll={{ enabled: true }}
                className={classes.dateRangePicker}
              />
            </div>
            <div className={classes.dateControlButtonCtn}>
              <Button
                className={classes.dateControlButton}
                onClick={handleCancel}
              >
                {props.cancelText || 'Cancel'}
              </Button>
              <Button
                color='primary'
                className={classes.dateControlButton}
                onClick={handleSelect}
              >
                {props.selectText || 'Select'}
              </Button>
            </div>
          </div>
        </div>
      </Grow>
    </Popover>
  )
}

/**
 * @param {DateRangeSelectorProps} props
 */
export default function DateRangeSelector (props: DateRangeSelectorProps) {
  const ogRange = props.range || {
    startDate: new Date(),
    endDate: new Date()
  }
  ogRange.startDate = new Date(ogRange.startDate)
  ogRange.endDate = new Date(ogRange.endDate)
  const [dateAnchorEl, setDateAnchorEl] = useState<Element | null>(null)

  const handleSelect = (range: dateTypes) => {
    props.handleSelect(range)
  }

  const chipLabel = dateHelper.dateToString(ogRange.startDate, 'mm/dd/yyyy') + ' - ' + dateHelper.dateToString(ogRange.endDate, 'mm/dd/yyyy')

  return (
    <>
      <Chip
        label={chipLabel}
        variant='outlined'
        color='primary'
        onClick={e => setDateAnchorEl(e.currentTarget)}
      />
      <DateRangeSelectorPopover
        range={ogRange}
        dateAnchorEl={dateAnchorEl}
        setDateAnchorEl={setDateAnchorEl}
        handleSelect={handleSelect}
      />
    </>
  )
}
