import { makeStyles } from '@material-ui/core/styles'
import { DisplaySettings } from 'classes/displaySettings'
import { Button, Icon } from 'library/materialUI'
import { Emitter, EVENT_TYPE } from 'emitter'

const useStyles = makeStyles(theme => ({
  inAppWarningIcon: {
    position: 'relative',
    display: 'inline-block',
    top: '5px'
  },
  inAppWarningBtn: {
    color: '#483A00',
    backgroundColor: '#FDF8E1',
    border: '1px solid black',
    marginLeft: '10px',
    marginRight: '10px',
    marginTop: '15px',
    boxShadow: 'none'
  },
  inAppWarning: {
    padding: '10px',
    width: '100%',
    minHeight: '40px',
    color: 'gray',
    textAlign: 'center',
    backgroundColor: '#FDF8E1',
    display: 'flex',
    flexDirection: 'column',
    alignItem: 'center',
    justifyContent: 'center'
  },
  closeButton: {
    position: 'absolute',
    right: '15px',
    top: '34px',
    color: '#000000BF',
    cursor: 'pointer'
  },
  text: {
    color: '#483A00',
    fontSize: '14px',
    fontFamily: 'poppins'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))

export default function AudioNotificationPrompt () {
  const classes = useStyles()

  const handleSnooze = () => {
    const now = new Date(Date.now())
    const weekLater = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 7)
    DisplaySettings.update({ page: 'alert_banner', type: 'sound_alert_snoozed', settings: { timestamp: weekLater.toISOString() } })
  }

  const handleDismiss = () => {
    const now = new Date(Date.now())
    const permanent = new Date(now.getFullYear() + 60, now.getMonth(), now.getDate())
    DisplaySettings.update({ page: 'alert_banner', type: 'sound_alert_snoozed', settings: { timestamp: permanent.toISOString() } })
  }

  const handleLearnMore = () => {
    Emitter.emit(EVENT_TYPE.HELP_CENTER, { path: 'home/settings/notifications' })
  }

  return (
    <div className={classes.inAppWarning}>
      <div className={classes.row}>
        <div style={{ marginRight: '5px' }}>
          <Icon icon='warning' color='#F3D450' />
        </div>
        <div className={classes.text}>
          We strongly recommend enabling audio notifications if you’ll be using Signals chat on this computer - we don’t want you to miss any conversations!
        </div>
      </div>
      <div className={classes.row}>
        <Button
          className={classes.inAppWarningBtn}
          variant='outlined'
          onClick={() => handleLearnMore()}
        >
          LEARN HOW
        </Button>
        <Button
          className={classes.inAppWarningBtn}
          variant='outlined'
          onClick={() => {
            handleSnooze()
          }}
        >
          ASK ME AGAIN LATER
        </Button>
        <Button
          className={classes.inAppWarningBtn}
          variant='outlined'
          onClick={() => {
            handleDismiss()
          }}
        >
          DON'T ASK ME AGAIN
        </Button>
      </div>
      <div onClick={() => handleSnooze()} className={classes.closeButton}>
        <Icon icon='close' />
      </div>
    </div>
  )
}
