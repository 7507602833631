import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'
import CustomizedSnackbar from 'components/CustomizedSnackbar'
import {
  Paper,
  Typography,
  Button,
  Tooltip
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  instructions: {
    margin: 20
  },
  instruction: {
    margin: 10
  },
  wordpressInstallation: {
    display: 'flex',
    height: 70,
    padding: 15
  },
  scriptdiv: {
    boxShadow: '0px 2px 9px #aaaaaa inset',
    margin: '24px 0px',
    display: 'inline-block',
    padding: '0px 25px 0px 20px'
  },
  wordpressScript: {
    width: 500,
    padding: 10,
    marginRight: 10
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    height: 40
  },
  manualToggle: {
    marginRight: 50
  },
  chip: {
    width: 150,
    marginLeft: 40
  },
  switch: {
    marginLeft: 5
  }
}))

const WordPressInstallationPage = (props) => {
  const scriptContents = props.scriptContents
  const classes = useStyles()
  const [snackState, setSnackState] = useState({
    open: false,
    variant: 'success',
    message: 'Snippet copied to clipboard!'
  })

  return (
    <div style={{ padding: 20 }}>
      <div className={classes.header}>
        <Typography variant='h6'>WordPress Installation</Typography>
      </div>
      <div className={classes.instructions}>
        <Typography style={{ marginBottom: 15 }}>
          Setting up your Signals account with WordPress is easy! Just
          follow these steps:
        </Typography>
        <Typography className={classes.instruction}>
          1. Go to your WordPress admin page.
        </Typography>
        <Typography className={classes.instruction}>
          2. On the toolbar on the left, click on the 'Plugins' menu.
        </Typography>
        <Typography className={classes.instruction}>
          3. Click on the 'Add New' button next to the 'Plugins' title.
        </Typography>
        <Typography className={classes.instruction}>
          4. Search for the Signals plugin in the marketplace and install
          it.
        </Typography>
        <Typography className={classes.instruction}>
          5. Once installed, activate it, and click on the Signals menu
          located on your settings menu.
        </Typography>
        <Typography className={classes.instruction}>
          6. Copy the snippet below and paste it into the Signals plugin
          settings page.
        </Typography>
        <div className={classes.wordpressInstallation}>
          <Paper elevation={3} className={classes.wordpressScript}>
            <Typography noWrap>{scriptContents}</Typography>
          </Paper>
          <Tooltip title='Copy' placement='bottom'>
            <Button
              variant='contained'
              color='primary'
              onClick={() => {
                navigator.clipboard.writeText(scriptContents)
                setSnackState({
                  open: true,
                  variant: 'success',
                  message: 'Snippet copied to clipboard!'
                })
              }}
            >
              <FileCopyOutlinedIcon style={{ marginRight: 3 }} />
            </Button>
          </Tooltip>
        </div>
        <Typography>7. Save your settings, and enjoy!</Typography>
      </div>
      <CustomizedSnackbar state={snackState} handler={setSnackState} />
    </div>
  )
}

export default WordPressInstallationPage
