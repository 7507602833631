import React, { useEffect, /* useReducer,  */useState, useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import EditIcon from '@material-ui/icons/Edit'
import SendIcon from '@material-ui/icons/Send'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import ClearIcon from '@material-ui/icons/Clear'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'
import dateFormat from 'dateformat'
import { getFeatureAnnouncements, deleteFeatureAnnouncement, publishFeatureAnnouncement, revokeFeatureAnnouncement } from 'api/admin_only/notifications'
import AppPage from 'cf-components/AppPage'
import { FilterBar } from 'cf-components/FilterBar/FilterBar'
import { CardList, ListItemCard } from 'cf-components/CardList'
import ActionsMenu from 'cf-components/ActionsMenu'
import DeleteModal from 'cf-components/DeleteModal'
import AdminAnnouncementsModal from './AdminAnnouncementsModal'

/**
 * @typedef {import('api/admin_only/notifications').AttributesFA} AttributesFA
 * @typedef {import('api/admin_only/notifications').AttributesStampsFA} AttributesStampsFA
 * @typedef {import('api/admin_only/notifications').FeatureAnnouncement<AttributesStampsFA>} FeatureAnnouncement
 */

const tableColumns = '3fr 2fr 2fr 2fr 28px'
const useStyles = makeStyles(theme => ({
  pageCtn: {
    padding: 20
  },
  headerClass: {
    display: 'grid',
    gridTemplateColumns: tableColumns,
    padding: '0 14px 10px'
  },
  announcementCard: {
    display: 'grid',
    gridTemplateColumns: tableColumns,
    padding: '10px 14px',
    alignItems: 'center'
  }
}))

export default function AdminAnnouncementsPage (props) {
  const classes = useStyles()
  const [announcements, setAnnouncements] = useState([])
  const [modalData, setModalData] = useState(undefined)
  const [publishing, setPublishing] = useState(false)
  const [deleteAnnouncementID, setDeleteAnnouncementID] = useState(undefined)
  const [publishAnnouncementID, setPublishAnnouncementID] = useState(undefined)
  const [revokeAnnouncementID, setRevokeAnnouncementID] = useState(undefined)
  const [totalCount, setTotalCount] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [pageNumber, setPageNumber] = useState(1)
  // const [search, setSearch] = useState('')
  const [sortCriteria, setSortCriteria] = useState('updated_timestamp')
  const [sortAscending, setSortAscending] = useState(false)

  const fetchFeatureAnnouncements = useCallback(() => {
    getFeatureAnnouncements({
      sortBy: sortCriteria,
      pageNum: pageNumber,
      pageSize: rowsPerPage,
      sortAscending
    }).then(res => {
      if (res.ok) {
        const baseIndex = (pageNumber - 1) * rowsPerPage
        setAnnouncements(res.data.map((item, index) => ({ ...item, index: baseIndex + index })))
        setTotalCount(res.meta.page.total)
      }
    })
  }, [pageNumber, rowsPerPage, sortAscending, sortCriteria])

  useEffect(() => {
    fetchFeatureAnnouncements()
  }, [fetchFeatureAnnouncements])
  useEffect(() => {
    if (publishing && publishAnnouncementID) {
      publishFeatureAnnouncement(publishAnnouncementID).then(() => {
        setPublishAnnouncementID(null)
        fetchFeatureAnnouncements()
      })
    }
  }, [publishing]) // eslint-disable-line

  const baseOptions = {
    sortCriteria,
    setSortCriteria,
    sortAscending,
    setSortAscending
  }
  const sortValues = [
    { label: 'Title', id: 'title', header: true },
    { label: 'Publish Date', id: 'published_timestamp', header: true },
    { label: 'Created Date', id: 'created_timestamp', header: true },
    { label: 'Updated Date', id: 'updated_timestamp', header: true }
  ]
  const sortOptions = { ...baseOptions, sortValues }
  const cardListHeaderProps = {
    ...baseOptions,
    columns: sortValues.filter(({ header }) => header),
    headerClass: classes.headerClass
  }
  const handleClose = (reloadList = false) => {
    if (reloadList) {
      fetchFeatureAnnouncements()
    }
    setModalData(null)
  }

  return (
    <>
      <AppPage
        title='Announcements'
        actionText='New Announcement'
        action={() => setModalData({})}
      >
        <FilterBar
          sortOptions={sortOptions}
        />
        <div className={classes.cardListCtn}>
          <CardList
            card={AnnouncementCard}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            pagination
            rows={announcements}
            totalCount={totalCount}
            sortOptions={sortOptions}
            header
            headerProps={cardListHeaderProps}
            cardProps={{ openAnnouncement: announcement => setModalData(announcement), classes, deleteID: setDeleteAnnouncementID, publishID: setPublishAnnouncementID, revokeID: setRevokeAnnouncementID }}
          />
        </div>
      </AppPage>
      <AdminAnnouncementsModal
        announcement={modalData}
        open={!!modalData}
        onClose={handleClose}
      />
      <DeleteModal
        title='Delete Announcement'
        message='Are you sure you want to delete this Announcement?'
        open={!!deleteAnnouncementID}
        onHide={() => setDeleteAnnouncementID(null)}
        confirmAction={() => {
          deleteFeatureAnnouncement(deleteAnnouncementID).then(() => {
            setDeleteAnnouncementID(null)
            fetchFeatureAnnouncements()
          })
        }}
      />
      <DeleteModal
        title='Publish Announcement'
        message='Are you sure you want to publish? Once published you cannot edit or change this Announcement.'
        open={!!publishAnnouncementID}
        onHide={() => setPublishAnnouncementID(null)}
        confirmButtonText='Publish'
        confirmIcon={SendIcon}
        disableAll={publishing}
        confirmAction={() => {
          setPublishing(true)
        }}
      />
      <DeleteModal
        title='Revoke Announcement'
        message='Are you sure you want to revoke this Announcement.'
        open={!!revokeAnnouncementID}
        onHide={() => setRevokeAnnouncementID(null)}
        confirmButtonText='Revoke'
        confirmIcon={RemoveCircleIcon}
        confirmAction={() => {
          revokeFeatureAnnouncement(revokeAnnouncementID).then(() => {
            setRevokeAnnouncementID(null)
            fetchFeatureAnnouncements()
          })
        }}
      />
    </>
  )
}

/**
 * @param {{
 * row: FeatureAnnouncement;
 * openAnnouncement: (announcement: FeatureAnnouncement) => void;
 * deleteID: (id: FeatureAnnouncement['id']) => void;
 * publishID: (id: FeatureAnnouncement['id']) => void;
 * revokeID: (id: FeatureAnnouncement['id']) => void;
 * classes: ReturnType<useStyles>;
 * }} props
 */
function AnnouncementCard ({ row, openAnnouncement, classes, deleteID, publishID, revokeID }) {
  const isDeleted = !!row.attributes.deleted_timestamp
  const isPublished = !!row.attributes.published_timestamp

  const actions = [{
    name: isPublished ? 'View' : 'Edit', icon: EditIcon, action: () => openAnnouncement(row)
  }]

  if (!isDeleted && !isPublished) {
    actions.push({
      name: 'Publish', icon: SendIcon, action: () => publishID(row.id)
    }, {
      name: 'Delete', icon: DeleteForeverIcon, action: () => deleteID(row.id)
    })
  } else if (isPublished) {
    actions.push({
      name: 'Revoke', icon: RemoveCircleIcon, action: () => revokeID(row.id)
    })
  }

  return (
    <ListItemCard>
      <div className={classes.announcementCard}>
        <div>{row.attributes.title}</div>
        {isDeleted ? (
          <div>
            <Tooltip title='Deleted'>
              <ClearIcon color='error' />
            </Tooltip>
          </div>
        ) : (
          <div>{isPublished ? (dateFormat(row.attributes.published_timestamp, 'mmm dd, yyyy')) : '---'}</div>
        )}
        <div>{dateFormat(row.attributes.created_timestamp, 'mmm dd, yyyy')}</div>
        <div>{dateFormat(row.attributes.updated_timestamp, 'mmm dd, yyyy')}</div>
        <div>
          <ActionsMenu actions={actions} />
        </div>
      </div>
    </ListItemCard>
  )
}
