import React, { useState, useEffect, useContext } from 'react'
import { Modal } from 'cf-components/Modal'
import { makeStyles } from '@material-ui/core/styles'
import dateFormat from 'dateformat'
import { Divider, Button, TextField } from '@material-ui/core'
import UserPicker from '../../bot/flow-builder/UserPicker'
import AddIcon from '@material-ui/icons/Add'
import TurnedInNotIcon from '@material-ui/icons/TurnedInNot'
import ClearIcon from '@material-ui/icons/Clear'
import CheckIcon from '@material-ui/icons/Check'
import UnresolvedIcon from '@material-ui/icons/NotInterested'
import { TenantDataContext } from 'pages/chat/agent-chat/tenant-data-context'

const useStyles = makeStyles((theme) => ({
  noteAuthor: {
    marginBottom: 5
  },
  noteBody: {
    paddingTop: 10,
    fontStyle: 'italic'
  },
  noteWrapper: {
    padding: 20,
    marginBottom: 20,
    backgroundColor: '#feff9c',
    boxShadow: '6px 6px 12px -8px rgba(0,0,0,0.29)'
  },
  container: {
    padding: 20,
    backgroundColor: '#f2ebfc',
    borderRadius: 10
  },
  bodyText: {
    marginTop: 5,
    marginBottom: 10,
    fontSize: 12
  },
  subtitle1: {
    fontSize: 15,
    fontWeight: 600,
    marginBottom: 5
  },
  subtitle2: {
    fontSize: 12,
    fontWeight: 600
  },
  noteContainer: {
    maxHeight: 300,
    overflowY: 'auto',
    width: '100%'
  },
  noteDate: {
    color: '#808080',
    fontSize: '12px'
  },
  editButtons: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  agentSelect: {
    display: 'grid',
    gridTemplateColumns: '5fr 2fr',
    gridGap: 15,
    alignItems: 'center',
    paddingBottom: 10
  },
  divider: {
    marginTop: 5,
    marginBottom: 10
  }
}))

function Note (props) {
  const classes = useStyles()
  const note = props.note
  const agentID = note.attributes.created_by_participant_id
  const { users } = useContext(TenantDataContext)
  const user = users.dict[agentID]
  const createdDate = dateFormat(note.attributes.created_timestamp, 'mmm dd, yyyy h:MM TT')

  return (
    <div className={classes.noteWrapper}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'end' }}>
        <div>
          <div className={classes.noteAuthor}>{user?.name}</div>
          <div className={classes.noteDate}>{createdDate}</div>
        </div>
      </div>
      <Divider />
      <div className={classes.noteBody}>{note.attributes.body}</div>
    </div>
  )
}

function EditSection (props) {
  const classes = useStyles()
  const [noteBody, setNoteBody] = useState('')

  const cancel = () => {
    setNoteBody('')
    props.exitEditMode()
  }

  if (props.editMode) {
    return (
      <div>
        <TextField
          variant='outlined'
          value={noteBody}
          fullWidth
          margin='dense'
          multiline
          placeholder='Type your reply...'
          rows='3'
          onChange={e => setNoteBody(e.target.value)}
          style={{ marginBottom: 15 }}
        />
        <div className={classes.editButtons}>
          <Button
            startIcon={<ClearIcon />}
            color='primary'
            variant='outlined'
            style={{ borderRadius: 20, marginRight: 15 }}
            onClick={cancel}
          >
            Cancel
          </Button>
          <Button
            startIcon={<TurnedInNotIcon />}
            color='primary'
            variant='outlined'
            style={{ borderRadius: 20 }}
            onClick={() => props.createNote(noteBody)}
          >
            Save
          </Button>
        </div>
      </div>
    )
  } else {
    return (
      <div className={classes.editButtons}>
        <Button
          startIcon={<AddIcon />}
          color='primary'
          variant='outlined'
          style={{ borderRadius: 20 }}
          onClick={props.enterEditMode}
        >
          Add Reply
        </Button>
      </div>
    )
  }
}

export default function NotesModal (props) {
  const classes = useStyles()
  const [editMode, setEditMode] = useState(false)
  const [selectedUserIds, setSelectedUserIds] = useState([])
  const note = props.note

  const createNote = (noteBody) => {
    props.createNote({ noteBody, taggedUserIDs: [], parentNoteID: props.note.id })
      .then(response => setEditMode(false))
  }

  useEffect(() => {
    if (note && note.attributes.tagged_user_ids) {
      setSelectedUserIds(note.attributes.tagged_user_ids)
    }
  }, [note])

  const replies = props.replies || []

  if (!note) {
    return <></>
  }

  const resolved = note.attributes.resolved_timestamp
  const action = () => {
    const status = resolved ? 'unresolved' : 'resolved'
    props.handleStatus(note.id, status)
  }

  return (
    <Modal
      open={props.open}
      onHide={props.onHide}
      size='sm'
      title='Note Details'
      mode='plain'
      rightButtonProps={{
        text: resolved ? 'Set Unresolved' : 'Resolve',
        action: action,
        variant: 'contained',
        icon: resolved ? UnresolvedIcon : CheckIcon
      }}
      leftButtonProps={{
        text: 'Delete Note',
        action: props.deleteNote
      }}
    >
      <div className={classes.agentSelect}>
        <UserPicker
          selectedUserIds={selectedUserIds}
          setSelectedUserIds={setSelectedUserIds}
          autocomplete
        />
        <Button
          variant='outlined'
          color='primary'
          onClick={() => props.handleTagging(note.id, selectedUserIds)}
          style={{ marginTop: 5 }}
        >
          Tag Agents
        </Button>
      </div>
      <Note
        note={props.note}
      />
      {Boolean(replies.length) && (
        <div>
          <div>Replies</div>
          <Divider className={classes.divider} />
          <div className={classes.replies}>
            {replies.map((note, index) => (
              <Note
                key={index}
                note={note}
              />
            ))}
          </div>
        </div>)}
      <EditSection
        editMode={editMode}
        enterEditMode={() => setEditMode(true)}
        exitEditMode={() => setEditMode(false)}
        createNote={createNote}
      />
    </Modal>
  )
}
