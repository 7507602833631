
const descriptionText = 'Create a contact for the visitor in your CRM and add the visitor to a pre-determined marketing campaign when they reach this skill in the play flow.'

const Description = (): JSX.Element => {
  return (
    <div>
      <div>
        {descriptionText}
      </div>
      <ul>
        <li><a href='#/contact_management/contacts' target='_blank'>Manage CF Contacts</a></li>
        <li>Manage campaigns within software for your CRM</li>
      </ul>
    </div>
  )
}

const bestPracticeText = 'Similar to the Add to Campaign skill in the Bot Builder, except this PlayMaker skill does not require bot interaction to fire.'

const BestPractices = () => {
  return (
    <div>
      <div>
        {bestPracticeText}
      </div>
      <ul>
        <li>Use this node when you want to add a visitor to a campaign based upon their page views or other non bot related behavior</li>
        <li>Don't use this node based upon the visitor's interaction with bots. Use the Bot Builder node within the individual bots instead</li>
      </ul>
    </div>
  )
}

export const addToCampaignDocs = {
  helpCenterLink: 'https://help.getsignals.ai/article/ny3yho3pwn-plays-add-to-campaign-node',
  bestPractices: BestPractices,
  description: Description,
  videoLink: 'https://www.youtube.com/watch?v=j1alh3D9WF0&list=PL6SHkPxzWDamLhKs_lWXmkwP3W5y9yQlQ&index=5'
}
