import { Typography } from '@material-ui/core';
import { ModalSection } from 'library/Modal';
import { RadioButtons } from 'library/materialUI';
import { CSVFileAttributes } from './CSVUploadModal';

interface CSVConfirmStepProps {
  CSVFileAttributes: CSVFileAttributes
  setCSVFileAttributes: (any: any) => void
}

export default function CSVConfirmStep ({ CSVFileAttributes, setCSVFileAttributes }: CSVConfirmStepProps) {
  return (
    <div style={{ maxWidth: 800 }}>
      <ModalSection
        title={<Typography variant='h1'>Keep or Replace Accounts</Typography>}
        subtitle='Would you like to keep or replace the accounts already in this segment?'
      >
        <RadioButtons
          value={CSVFileAttributes.segmentEdit}
          handleChange={(value) => setCSVFileAttributes({ ...CSVFileAttributes, segmentEdit: value })}
          buttons={[
            { value: 'edit', label: 'Keep existing accounts in this segment and add the accounts from this CSV' },
            { value: 'replace', label: 'Remove existing accounts in this segment and replace them with the accounts from this CSV' }
          ]}
        />
      </ModalSection>
    </div>
  )
}
