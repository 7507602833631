import Card from 'library/materialUI/Card'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from 'library/materialUI'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { useContext, useEffect } from 'react'
import { TabContext } from '../IntegrationPage/TabProvider'
import { IntegrationPageContext } from '../IntegrationPage/IntegrationPageProvider'

const useStyles = makeStyles((theme) => ({
  subtitle1: {
    fontSize: 18,
    fontWeight: 600,
    marginBottom: 15
  },
  subtitle2: {
    fontSize: 15,
    fontWeight: 600,
    marginBottom: 10
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  checkboxes: {
    display: 'block'
  },
  bodyText: {
    marginBottom: 30,
    fontSize: 16
  }
}))

interface SyncOptionsCardProps {
  save: any
  handleEdit: any
  integrationSettings: any
}

export default function SyncOptionsCard ({ save, handleEdit, integrationSettings }: SyncOptionsCardProps) {
  const classes = useStyles()
  const { setCanSave, shouldSave } = useContext(TabContext)
  const { handleSaved } = useContext(IntegrationPageContext)

  useEffect(() => {
    if (shouldSave) {
      save()
      handleSaved()
    }
  })

  return (
    <Card
      content={
        <>
          <div className={classes.header}>
            <Typography className={classes.subtitle1}>
              Sync Options
            </Typography>
          </div>
          <Typography variant='body2' className={classes.bodyText}>
            Sync Signals data to Salesforce? When checkmark is unchecked Salesforce data will only be synced to Signals, not the other way around.
          </Typography>
          <FormControlLabel
            className={classes.checkboxes}
            control={
              <Checkbox
                color='primary'
                checked={!integrationSettings.attributes.config.disable_sync_from_cf}
                onChange={(e) => {
                  handleEdit({ type: 'disable_sync_from_cf', data: !e.target.checked }); setCanSave(true)
                }}
              />
            }
            label='Allow Signals to send data back to Salesforce'
          />
        </>
      }
    />
  )
}
