import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  title: {
    font: 'normal normal normal 20px/25px Poppins',
    color: '#000000BF'
  },
  subtitle: {
    font: 'normal normal normal 14px/25px Poppins',
    color: '#00000080'
  },
  contentHeader: {
    color: '#000000BF',
    font: 'normal normal 600 24px/25px Poppins',
    paddingBottom: 10,
    borderBottom: '1px solid #00000033',
    marginBottom: 20
  }
}))

type NPSSettingsHeaderProps = {
  title: string
  subtitle: string
}
export function NPSSettingsHeader (props: NPSSettingsHeaderProps) {
  const classes = useStyles()

  return (
    <div style={{ marginBottom: 10 }}>
      <div className={classes.title}>
        {props.title}
      </div>
      <div className={classes.subtitle}>
        {props.subtitle}
      </div>
    </div>
  )
}

export function NPSContentHeader ({ title }: {title: string}) {
  const classes = useStyles()

  return (
    <div className={classes.contentHeader}>
      {title}
    </div>
  )
}
