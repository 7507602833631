// import { makeStyles } from '@material-ui/core/styles';
import Company from 'classes/companies';
import { Typography } from 'library/materialUI';
import SearchBar from 'library/search/SearchBar';
import { useContext, useState } from 'react';
import Loader from 'library/loading/Loader'
import CompanyCardList from './CompanyCardList';
import { DynamicSegmentContext } from './DynamicSegmentContext';

// const useStyles = makeStyles(theme => ({
//   textDiv: {
//     height: 40,
//     justifyContent: 'center',
//     alignItems: 'center',
//     display: 'flex'
//   }
// }))

export default function SegmentDisplay () {
  // const classes = useStyles()
  const { triggers } = useContext(DynamicSegmentContext)
  const [companyNameSearch, SetCompanyNameSearch] = useState('')
  const { data: { list: companies }, isLoading } = Company.get_segment_membership({ filters: triggers, pageSize: 15, search: companyNameSearch })

  if (isLoading) {
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
        <Loader type='spinner' />
      </div>
    )
  }

  // const remainingCompaniesNotDisplayed = isLoadingCount ? 0 : totalCompanies > 8 ? Math.round((totalCompanies - 8) / 10) * 10 : 0
  // const typeText = remainingCompaniesNotDisplayed === 1 ? 'Company' : 'Companies'
  return (
    <div style={{ width: '50%' }}>
      <div style={{ display: 'flex', justifyContent: 'center', height: 55, border: '1px solid rgba(0,0,0,0.12)', paddingLeft: 10, paddingRight: 10 }}>
        <SearchBar
          searchString={companyNameSearch}
          setSearchString={SetCompanyNameSearch}
        />
      </div>
      <div style={{ marginLeft: 10 }}>
        {companies.length === 0
        ? <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: 470, flexDirection: 'column' }}> <Typography variant='h2'>No Companies to Display</Typography> <Typography variant='subtitle1'>Try adding, removing or altering your criteria</Typography> </div>
        : (
          <>
            <CompanyCardList rows={companies} />
            {/* {remainingCompaniesNotDisplayed > 0 && (
              <div className={classes.textDiv}>
                <Typography align='center' color='primary'>And about {remainingCompaniesNotDisplayed} other {typeText}</Typography>
              </div>
            )} */}
          </>
        )}
      </div>
    </div>
  )
}
