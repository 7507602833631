
const descriptionText = 'Use this bot template to create a standard live chat bot to facilitate communication between visitors and agents. This bot:'

const Description = (): JSX.Element => {
  return (
    <div>
      <div>
        {descriptionText}
      </div>
      <ul>
        <li>Welcomes the visitor to your site</li>
        <li>Routes an agent to live chat if the visitor interacts with the bot</li>
        <ul>
          <li><a href='https://help.getsignals.ai/article/hy96c0do9e-bot-builder-and-play-runner-routing' target='_blank' rel='noreferrer'>How routing works</a></li>
        </ul>
      </ul>
    </div>
  )
}

const bestPracticeText = 'Make sure that your company, team, and user availability settings are properly configured so that agents can be effectively routed into live chat.'

const BestPractices = () => {
  return (
    <div>
      <div>
        {bestPracticeText}
      </div>
      <ul>
        <li><a href='#/settings/chat_settings/chat_availability' target='_blank'>Company Availability</a></li>
        <li><a href='#/user_management/teams' target='_blank'>Team Management</a></li>
        <li>Update your personal availability and out of office settings from the user pop-out in the left hand corner of the screen</li>
      </ul>
    </div>
  )
}

export const liveChatBotDocs = {
  helpCenterLink: '',
  bestPractices: BestPractices,
  description: Description,
  videoLink: ''
}
