import { useContext } from 'react'
import { ApiContext } from 'api-context'
import ActionsMenu from 'cf-components/ActionsMenu'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import EditIcon from '@material-ui/icons/Edit'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded'
type ActionType = 'delete' | 'clone' | 'edit' | 'add'
type ActionFunction<Class> = (row: Class) => void
type BaseRow<Class> = { name: string, icon?: any, action: ActionFunction<Class>, disabled?: boolean | ActionFunction<Class>, tooltip?: string | ActionFunction<Class>, display?: boolean | ActionFunction<Class> }
type TypedRow<Class> = { type: ActionType, name?: string, action?: ActionFunction<Class>, disabled?: ActionFunction<Class> | boolean, tooltip?: ActionFunction<Class> | string, display?: boolean | ActionFunction<Class> }
type ActionRow<Class = any> = BaseRow<Class> | TypedRow<Class>

export type Actions<Class = any> = ActionRow<Class>[]

export function ActionsComponent<Class = any> (props: { actions: ActionRow<Class>[], row: any }): JSX.Element {
  const { setDeleteObject } = useContext(ApiContext)

  const typeMappings: Record<ActionType, any> = {
    delete: {
      name: 'Delete',
      icon: DeleteForeverIcon,
      action: (objectRow: any) => setDeleteObject(objectRow)
    },
    clone: {
      name: 'Clone',
      icon: FileCopyIcon
    },
    edit: {
      name: 'Edit',
      icon: EditIcon
    },
    add: {
      name: 'Add',
      icon: AddCircleRoundedIcon
    }
  }

  const convertActionRow = (row: ActionRow<Class>): ActionRow<Class> => {
    if ('type' in row) {
      const mapping = typeMappings[row.type]
      return ({
        name: row.name || mapping.name,
        icon: mapping.icon,
        action: row.action || mapping.action,
        disabled: row?.disabled || false,
        tooltip: row?.tooltip || '',
        display: row.display
      })
    }
    return row
  }

  const actions = props.actions.map(action => convertActionRow(action))

  if (actions.length === 0) {
    return <></>
  }

  return (
    <ActionsMenu
      actions={actions}
      row={props.row}
    />
  )
}
