import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import Popover from '@material-ui/core/Popover'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Icon } from 'library/materialUI'
import { useContext, useEffect, useReducer, useState } from 'react'
import { DisplaySettings } from 'classes/displaySettings'
import DimHighlight from './DimHighlight'
import { SessionContext } from 'session-context'

const useStyles = makeStyles(theme => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(0),
    top: theme.spacing(0),
    color: '#FFF'
  },
  dot: {
    margin: '5px',
    marginTop: '13px',
    height: '10px',
    width: '10px',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '50%',
    display: 'inline-block'
  },
  lightDot: {
    opacity: 0.3
  },
  popoverRoot: {
    overflow: 'visible',
    backgroundColor: 'rgba(0,0,0,0)',
    boxShadow: 'none',
    zIndex: 1500
  },
  box: {
    minWidth: '150px',
    minHeight: '75px',
    maxWidth: '310px',
    backgroundColor: 'white',
    borderRadius: '10px',
    position: 'relative',
    float: 'left',
    boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)'
  },
  boxLeftArrow: {
    '&:after': {
      content: '""',
      position: 'absolute',
      left: '-15px',
      top: (props?: FTUXAnchor) => {
        if (props?.arrowOffset) {
          return props.arrowOffset
        }
        return '20px'
      },
      borderTop: '10px solid transparent',
      borderRight: '15px solid white',
      borderLeft: 'none',
      borderBottom: '10px solid transparent'
    }
  },
  boxRightArrow: {
    left: '25px',
    '&:after': {
      content: '""',
      position: 'absolute',
      right: '-15px',
      top: '45px',
      borderTop: '10px solid transparent',
      borderRight: 'none',
      borderLeft: '15px solid white',
      borderBottom: '10px solid transparent'
    }
  },
  boxTopLeftArrow: {
    left: '40px',
    top: '-55px',
    marginTop: '40px',
    '&:after': {
      content: '""',
      position: 'absolute',
      left: '28px',
      top: '-15px',
      borderTop: 'none',
      borderRight: '10px solid transparent',
      borderLeft: '10px solid transparent',
      borderBottom: '15px solid white'
    }
  },
  boxTopArrow: {
    left: '40px',
    top: '-55px',
    marginTop: '40px',
    '&:after': {
      content: '""',
      position: 'absolute',
      right: '8px',
      top: '-15px',
      borderTop: 'none',
      borderRight: '10px solid transparent',
      borderLeft: '10px solid transparent',
      borderBottom: '15px solid white'
    }
  },
  boxTopRightArrow: {
    left: '40px',
    top: '-55px',
    marginTop: '40px',
    '&:after': {
      content: '""',
      position: 'absolute',
      right: '8px',
      top: '-15px',
      borderTop: 'none',
      borderRight: '10px solid transparent',
      borderLeft: '10px solid transparent',
      borderBottom: '15px solid white'
    }
  },
  boxBottomArrow: {
    '&:after': {
      content: '""',
      position: 'absolute',
      right: '50px',
      bottom: '-15px',
      borderTop: '15px solid white',
      borderRight: '10px solid transparent',
      borderLeft: '10px solid transparent',
      borderBottom: 'none'
    }
  },
  boxBottomLeftArrow: {
    '&:after': {
      content: '""',
      position: 'absolute',
      left: '50px',
      bottom: '-15px',
      borderTop: '15px solid white',
      borderRight: '10px solid transparent',
      borderLeft: '10px solid transparent',
      borderBottom: 'none'
    }
  },
  stepText: {
    fontFamily: 'poppins',
    color: '#0000004D',
    marginTop: '5px'
  }
}))

type VerticalOrigin = 'top' | 'bottom' | 'center' | number
type HorizontalOrigin = 'left' | 'right' | 'center' | number

export interface FTUXAnchor {
  header?: JSX.Element
  headerOffset?: number
  title: string | JSX.Element
  body: JSX.Element
  anchorID: string
  anchorPos: string
  className?: string
  anchorOriginHorizontal: HorizontalOrigin
  anchorOriginVertical: VerticalOrigin
  transformOriginHorizontal: HorizontalOrigin
  transformOriginVertical: VerticalOrigin
  arrowOffset?: string
  additionalEffect?: () => void
}

export interface FTUXProps {
  page: string
  anchors: FTUXAnchor[]
  updateFunction?: () => void
  closingFunction?: () => void
  dim?: boolean
}
export interface FTUXState {
  anchors: FTUXAnchor[]
  currentAnchorIdx: number
  currentAnchor?: FTUXAnchor
  anchorEl?: any
  done: boolean
}
const initialState: FTUXState = {
  anchors: [],
  currentAnchorIdx: 0,
  done: false,
  anchorEl: null
}
interface FTUXAction {
  type: string
  value?: any
  anchor?: FTUXAnchor
  anchors?: FTUXAnchor[]
}

const reducer = (state: FTUXState, action: FTUXAction) => {
  switch (action.type) {
    case 'done':
      return { ...state, currentAnchor: undefined, done: true }
    case 'close':
      return { ...state, currentAnchor: undefined }
    case 'set-anchors':
      if (action.anchors && action.anchors.length > 0) {
        const anchor = action.anchors[0]
        return { ...state, anchors: action.anchors, currentAnchor: anchor, anchorEl: document.getElementById(anchor.anchorID) }
      }
      return { ...state }
    case 'advance':
      if (state.currentAnchorIdx < state.anchors.length - 1) {
        const anchor = state.anchors[state.currentAnchorIdx + 1]
        return { ...state, currentAnchorIdx: state.currentAnchorIdx + 1, currentAnchor: anchor, anchorEl: document.getElementById(anchor.anchorID) }
      }
      return { ...state }
    case 'previous':
      if (state.currentAnchorIdx > 0) {
        const anchor = state.anchors[state.currentAnchorIdx - 1]
        return { ...state, currentAnchorIdx: state.currentAnchorIdx - 1, currentAnchor: anchor, anchorEl: document.getElementById(anchor.anchorID) }
      }
      return { ...state }
  }
  return { ...state }
}
export default function FTUX ({ page, anchors, updateFunction, closingFunction, dim }: FTUXProps): JSX.Element {
  const [state, dispatch] = useReducer(reducer, initialState)
  const open = Boolean(state.anchorEl)
  const [render, setRender] = useState(false)
  const classes = useStyles(state.currentAnchor)
  const { settings, isLoading } = DisplaySettings.load()
  const { user } = useContext(SessionContext)

  useEffect(() => {
    if (!state.done && anchors.length > 0 && (state.anchors.length === 0 || !state.anchorEl)) {
      dispatch({ type: 'set-anchors', anchors: anchors })
    }
  }, [anchors, state.done, state.anchors.length, state.anchorEl])

  const handleDivClick = (e: Event): void => {
    if (!e) e = window.event as Event
    const target = e.target as HTMLElement
    e.cancelBubble = true
    if (e.stopPropagation) e.stopPropagation()
    if (target.tagName.toLowerCase() !== 'a') {
      e.preventDefault()
    }
  }
  const handleBack = (): void => {
    dispatch({ type: 'close' })
    setTimeout(() => {
      dispatch({ type: 'previous' })
    }, 100)
  }
  const handleNext = (): void => {
    setRender(false)
    setTimeout(() => setRender(true), 350)
    if (currentAnchor.additionalEffect) {
      currentAnchor.additionalEffect()
    }
    dispatch({ type: 'close' })
    setTimeout(() => {
      dispatch({ type: 'advance' })
    }, 100)
  }

  const handleDone = (): void => {
    if (closingFunction) {
      closingFunction()
    }
    dispatch({ type: 'done' })
    if (updateFunction) {
      updateFunction()
    } else {
      DisplaySettings.update({ page: 'ftux', type: page, settings: { complete: true } })
    }
  }
  if (!state.currentAnchor || isLoading) {
    return <></>
  }
  if (!settings.ftux) {
    DisplaySettings.create({ page: 'ftux', type: page, settings: { complete: false } })
  } else if (settings.ftux.settings[page] && settings.ftux.settings[page].complete && page !== 'agent-chat') {
    return <></>
  }
  const currentAnchor = state.currentAnchor
  const more = state.currentAnchorIdx < anchors.length - 1
  const last = state.currentAnchorIdx === anchors.length - 1
  const getBoxClass = (): any => {
    switch (currentAnchor.anchorPos) {
      case 'right':
        return `${classes.box} ${classes.boxRightArrow}`
      case 'bottom':
        return `${classes.box} ${classes.boxBottomArrow}`
      case 'top-right':
        return `${classes.box} ${classes.boxTopRightArrow}`
      case 'top-left':
        return `${classes.box} ${classes.boxTopLeftArrow}`
      case 'bottom-left':
        return `${classes.box} ${classes.boxBottomLeftArrow}`
      case 'top':
        return `${classes.box} ${classes.boxTopArrow}`
      case 'left':
      default:
        return `${classes.box} ${classes.boxLeftArrow}`
    }
  }

  setTimeout(() => setRender(true), 350)

  if (!render || user?.attributes?.assist_login) {
    return <></>
  }

  return (
    <>
      <div onClick={e => handleDivClick(e as unknown as Event)}>
        <Popover
          open={open}
          anchorEl={state.anchorEl}
          onClose={handleDone}
          transitionDuration={0}
          anchorOrigin={{ horizontal: currentAnchor.anchorOriginHorizontal, vertical: currentAnchor.anchorOriginVertical }}
          transformOrigin={{ horizontal: currentAnchor.transformOriginHorizontal, vertical: currentAnchor.transformOriginVertical }}
          classes={{ paper: classes.popoverRoot }}
        >
          <div className={getBoxClass()}>
            {currentAnchor.header}
            <MuiDialogTitle>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div>
                  {currentAnchor.title}
                </div>
                <div onClick={() => handleDone()} style={{ cursor: 'pointer', marginLeft: 'auto' }}>
                  <Icon icon='close' />
                </div>
              </div>
            </MuiDialogTitle>
            <MuiDialogContent>
              {currentAnchor.body}
              <br />
              <br />
              <div style={{ float: 'left' }}>
                <div className={classes.stepText}>
                  Step {state.currentAnchorIdx + 1} of {state.anchors.length}
                </div>
              </div>
              <div style={{ textAlign: 'right', marginBottom: '12px', float: 'right' }}>
                {state.currentAnchorIdx > 0 && (
                  <Button
                    color='primary'
                    variant='outlined'
                    onClick={handleBack}
                  >
                    Back
                  </Button>
                )}
                &nbsp;
                {more && (
                  <Button
                    color='primary'
                    variant='contained'
                    onClick={handleNext}
                  >
                    Next
                  </Button>
                )}
                &nbsp;
                {last && (
                  <Button
                    color='primary'
                    variant='contained'
                    onClick={handleDone}
                  >
                    GOT IT
                  </Button>
                )}
                {(dim) && (
                  <DimHighlight
                    anchorID={currentAnchor.anchorID}
                  />
                )}
              </div>
            </MuiDialogContent>
          </div>
        </Popover>
      </div>
    </>
  )
}
