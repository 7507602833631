import { Surface, Node, jsPlumbUtil } from 'jsplumbtoolkit'
import { Emitter, EVENT_TYPE } from './EventEmitter'
import { Toolkit } from './toolkit'
import { handleSelectionOption, sendSelectionEvent, handleAddingFromEdge, dragEdgeToSpace } from './toolkitHelpers'

const integrationTypes = ['Salesforce', 'Marketo', 'Eloqua', 'Hubspot', 'Sendinblue', 'SendEmail', 'Salesloft']

export function initializeSurface (surface: Surface, toolkit: Toolkit): void {
  const isNotValidEdge = (edge: any) => {
    const sourcePort = edge.source
    const sourceNode = sourcePort.getNode()
    if (sourceNode.data.id === edge.target.data.id) return true
    return ((edge.source.data.type === 'integration' && !integrationTypes.includes(edge.target.data.kind)) || (edge.target.data.kind === 'Start'))
  }

  const getNextID = (ports: any[]) => {
    const IDs = ports.map(p => parseInt(p.id.replace('exit', ''))).filter(id => !isNaN(id))
    IDs.push(0)
    return IDs.sort()[IDs.length - 1] + 1
  }

  const shouldHavePort = (edge: any) => {
    if (edge.source.data.type === 'integration' && integrationTypes.includes(edge.target.data.kind)) {
      toolkit.removePort(edge.target.id, 'default')
    } else if (edge.source.data.type !== 'integration' && integrationTypes.includes(edge.target.data.kind) && edge.target.data.ports.length < 1) {
      toolkit.addPort(edge.target, { id: 'default', label: 'Next', type: 'basic' })
      toolkit.updateNode(edge.target, edge.target.data)
    } else if (edge.target.data.kind === 'SequenceExit') {
      const targetPorts = edge.target.getPorts()
      const exitNum = getNextID(targetPorts)
      const newPort = toolkit.addPort(edge.target, { id: 'exit' + exitNum, label: 'Exit ' + exitNum, type: 'default' })
      toolkit.updateNode(edge.target, edge.target.data)
      setTimeout(() => {
        toolkit.addEdge({ source: edge.source, target: newPort, data: { id: jsPlumbUtil.uuid(), type: 'default' } })
      }, 50)
      setTimeout(() => {
        toolkit.removeEdge(edge.data.id)
      }, 10)
    }
  }

  const shouldRemovePortAfterDelete = (edge: any) => {
    if (edge.source.data.type !== 'integration' && integrationTypes.includes(edge.target.data.kind)) {
      toolkit.removePort(edge.target.id, 'default')
    }
  }

  const updatePortEdges = (ports: any) => {
    ports.forEach((port: any) => {
      const sourceEdge = port.getSourceEdges()[0]
      if (!sourceEdge) return
      toolkit.removeEdge(sourceEdge)
      toolkit.addEdge(sourceEdge)
    })
  }

  Emitter.on(EVENT_TYPE.SURFACE_CONTROL, (payload: any) => {
    const kind = payload.kind
    switch (kind) {
      case 'zoomToFit':
        surface.setZoom(0.7)
        surface.zoomToFitIfNecessary()
        break
      case 'zoomIn':
        surface.nudgeZoom(0.1)
        break
      case 'zoomOut':
        surface.nudgeZoom(-0.1)
        break
      case 'panMode':
        window.flowBuilder.mode = 'pan'
        surface.setMode('pan')
        break
      case 'selectMode':
        window.flowBuilder.mode = 'select'
        surface.setMode('select')
        break
      case 'fullScreen':
        if (document.fullscreenElement) {
          document.exitFullscreen()
        } else {
          const el: any = document.documentElement
          el.requestFullscreen(el)
        }
        break
    }
  })

  Emitter.on(EVENT_TYPE.ADD_FROM_EDGE, (payload: any) => {
    handleAddingFromEdge({ payload, toolkit, surface })
  })

  Emitter.on(EVENT_TYPE.SELECTION_OPTION, (payload: any) => {
    if (toolkit.id === payload.toolkitID) {
      handleSelectionOption({ payload, toolkit })
    }
  })

  Emitter.on(EVENT_TYPE.EDGE_DIRECT_REMOVE, ({ toolkitRefID, edge }: any) => {
    if (toolkit.id === toolkitRefID) {
      shouldRemovePortAfterDelete(edge)
      const sourcePort = edge.source
      const sourceNode = sourcePort.getNode()
      toolkit.updateNode(sourceNode, sourceNode.data)
    }
  })

  Emitter.on(EVENT_TYPE.NODE_DATA_UPDATED, ({ toolkitRefID, node }: any) => {
    if (toolkit.id === toolkitRefID) {
      const nodePorts = node.getPorts()
      updatePortEdges(nodePorts)
    }
  })

  surface.bind('connectionAborted', (info: any, event: any) => {
    const source = info.source
    const left = info.target.offsetLeft
    const top = info.target.offsetTop
    setTimeout(() => {
      const element = document.elementFromPoint(event.clientX, event.clientY)
      if (element && element.className === 'jtk-surface') {
        dragEdgeToSpace({ source, left, top, event, toolkit })
      }
    }, 10)
  })

  surface.bind('zoom', (params: any) => {
    const styleID = 'builder-dragger-styles'
    let style = document.getElementById(styleID)
    if (!style) {
      style = document.createElement('style')
      style.id = styleID
      document.getElementsByTagName('head')[0].appendChild(style)
    }
    style.innerHTML = `.katavorio-clone-drag.jtk-drag { transform: scale(${params.zoom}); transform-origin: top left; }`
  })

  surface.bind('canvasClick', () => {
    window.flowBuilder.mode = 'pan'
    toolkit.clearSelection()
    sendSelectionEvent({ type: 'clear', selection: [], toolkit })
  })

  surface.bind('canvasDblClick', (event: MouseEvent) => {
    const { left, top } = surface.mapEventLocation(event)
    dragEdgeToSpace({ left, top, event, toolkit })
  })

  toolkit.bind('select', ({ obj, selection }: any) => {
    if (window.flowBuilder.mode === 'pan') {
      sendSelectionEvent({ type: 'select', object: obj, selection: selection.getNodes(), toolkit })
    }
  })

  toolkit.bind('deselect', ({ obj, selection }: any) => {
    if (window.flowBuilder.mode === 'pan') {
      sendSelectionEvent({ type: 'deselect', object: obj, selection: selection.getNodes(), toolkit })
    }
  })

  toolkit.bind('nodeRemoved', ({ node }: any) => {
    const selection = toolkit.getSelection()
    sendSelectionEvent({ type: 'deselect', object: node, selection: selection.getNodes(), toolkit })
  })

  toolkit.bind('nodeAdded', ({ node, data }: any) => {
    if (window.flowBuilder.dataLoaded) {
      setTimeout(() => {
        Emitter.emit(EVENT_TYPE.NODE_ADDED, { nodeID: node.id, isNew: !data.isCopy })
      }, 10)
    }
  })

  // Whenever an edge is added check if the source and target are compatible
  toolkit.bind('edgeAdded', ({ edge }: any) => {
    if (isNotValidEdge(edge)) {
      setTimeout(() => {
        toolkit.removeEdge(edge.data.id)
      }, 10)
      return
    }
    shouldHavePort(edge)
    const sourcePort = edge.source
    const sourceNode = sourcePort.getNode()
    toolkit.updateNode(sourceNode, sourceNode.data)
  })

  toolkit.bind('dataLoadStart', () => {
    window.flowBuilder.dataLoaded = false
  })

  toolkit.bind('dataLoadEnd', () => {
    window.flowBuilder.dataLoaded = true
    const nodes = toolkit.getNodes()
    nodes.forEach((node: any) => {
      toolkit.updateNode(node, node.data)
    })
  })

  toolkit.bind('nodeUpdated', ({ node }: { node: Node }): void => {
    Emitter.emit(EVENT_TYPE.NODE_UPDATED, { nodeID: node.id })
  })
}
