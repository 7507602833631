import SettingsCard from 'cf-components/SettingsCard'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import SLSetupImage1 from 'img/SLSetup1.png'
import SLSetupImage2 from 'img/SLSetup2.png'
import SLSetupImage3 from 'img/SLSetup3.png'

const useStyles = makeStyles((theme) => ({
  subtitle1: {
    fontSize: 18,
    fontWeight: 600,
    marginBottom: 15
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  bodyText: {
    marginBottom: 30,
    fontSize: 16
  },
  lineHeader: {
    marginTop: '10px',
    fontSize: '16px',
    color: 'black'
  },
  lineItem: {
    display: 'flex',
    flexDireciton: 'row',
    marginTop: '10px'
  },
  image: {
    marginTop: '10px'
  }
}))

export default function SetupCard () {
  const classes = useStyles()

  return (
    <SettingsCard
      title='How to set it up'
      subtitle=''
    >
      <Typography variant='body2' className={classes.bodyText}>
        <div className={classes.lineHeader}>
          1. Configure Ownership
        </div>
        <div className={classes.lineItem}>
          Under the People tab, set up your rules for ownership and select a Salesloft User to be the default owner for any people not covered by your ownership rules
        </div>
        <div className={classes.image}>
          <img src={SLSetupImage1} alt='setup-1' />
        </div>
        <div className={classes.lineHeader}>
          2. Map people fields
        </div>
        <div className={classes.lineItem}>
          Under the People tab, map all of the fields you'd like to send over to Salesloft when you add a person. Name and email address are required
        </div>
        <div className={classes.image}>
          <img src={SLSetupImage2} alt='setup-2' />
        </div>
        <div className={classes.lineHeader}>
          3. Map users
        </div>
        <div className={classes.lineItem}>
          Under the Users tab, make sure to map your Signals users to the corresponding user in Salesloft
        </div>
        <div className={classes.image}>
          <img src={SLSetupImage3} alt='setup-3' />
        </div>
      </Typography>
    </SettingsCard>
  )
}
