import { makeStyles } from '@material-ui/core/styles'
import { useState } from 'react'
import Card from 'library/materialUI/Card'
import { Dialog, Typography } from 'library/materialUI'
import { Business, PersonAdd } from '@material-ui/icons'
import MapContactPropertiesImg from 'img/integrations/hubspot/hubspot_demo_map_contact_properties.png'
import MapCompanyPropertiesImg from 'img/integrations/hubspot/hubspot_demo_map_company_properties.png'
import MapUsersImg from 'img/integrations/hubspot/hubspot_demo_map_users.png'
import DefineOwnershipImg from 'img/integrations/hubspot/hubspot_demo_define_ownership.png'
import Playmaker1Img from 'img/integrations/hubspot/hubspot_demo_playrunner1.png'
import Playmaker2Img from 'img/integrations/hubspot/hubspot_demo_playrunner2.png'
import Playmaker3Img from 'img/integrations/hubspot/hubspot_demo_playrunner3.png'
import BotBuilderImg from 'img/integrations/hubspot/hubspot_demo_botbuilder.png'
import Dashboard1Img from 'img/integrations/hubspot/hubspot_demo_dashboard1.png'
import Dashboard2Img from 'img/integrations/hubspot/hubspot_demo_dashboard2.png'
import ChatImg from 'img/integrations/hubspot/hubspot_demo_chat.png'
import IcpImg from 'img/integrations/hubspot/hubspot_demo_icp.png'
import SegmentsImg from 'img/integrations/hubspot/hubspot_demo_segments.png'

const useStyles = makeStyles((theme) => ({
  subtitle1: {
    fontSize: 18,
    fontWeight: 600,
    marginBottom: 15
  },
  bulletNumbers: {
    fontSize: 16,
    fontWeight: 600,
    marginBottom: 5
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  bodyText: {
    marginBottom: 20,
    fontSize: 16
  },
  iconText: {
    fontSize: 16,
    verticalAlign: 'middle',
    display: 'flex'
  },
  icon: {
    color: theme.palette.primary.main,
    paddingRight: 10,
    display: 'inline'
  }
}))

const ImagePreviewer = ({ src, alt }: {src: string, alt: string}) => {
  const [open, setOpen] = useState(false)

  return (
    <div>
      <span style={{ display: 'inline-block' }}>
        <img src={src} alt={alt} onClick={() => setOpen(true)} style={{ cursor: 'zoom-in', width: '50%' }} />
      </span>
      <Dialog
        open={open}
        onClose={() => { setOpen(false) }}
        maxWidth='lg'
        fullWidth
      >
        <div style={{ width: '100%', height: '100%' }}>
          <img src={src} alt={alt} style={{ width: '100%', height: '100%' }} />
        </div>
      </Dialog>
    </div>
  )
}

export default function GetStartedCards () {
  const classes = useStyles()

  return (
    <>
      <div style={{ marginBottom: 20 }}>
        <Card
          content={
            <>
              <div className={classes.header}>
                <Typography className={classes.subtitle1}>
                  What the integration does
                </Typography>
              </div>
              <Typography variant='body2' className={classes.bodyText}>
                Signals' integration with HubSpot enables a two-way sync, including:
              </Typography>
              <div className={classes.iconText}>
                <PersonAdd className={classes.icon} />
                <Typography variant='body2' className={classes.bodyText}>
                  Sending Contacts gathered by Signals (either through bot conversations or Contact Lookup) to HubSpot
                </Typography>
              </div>
              <div className={classes.iconText}>
                <Business style={{ marginRight: 10 }} />
                <Typography variant='body2' className={classes.bodyText}>
                  Sync Company information (including name, domain, deal stage, company owner, type, and lifecycle stage) to Signals for segmenting and agent routing
                </Typography>
              </div>
            </>
          }
        />
      </div>
      <div style={{ marginBottom: 20 }}>
        <Card
          content={
            <>
              <div className={classes.header}>
                <Typography className={classes.subtitle1}>
                  How to set it up
                </Typography>
              </div>
              <Typography variant='subtitle2' className={classes.bulletNumbers}>
                1. Configure Sync Settings:
              </Typography>
              <Typography variant='body2' className={classes.bodyText}>
                Under the Sync Settings tab, configure the details of how you'd like contacts to be synced to HubSpot
              </Typography>
              <Typography variant='subtitle2' className={classes.bulletNumbers}>
                2. Map Contact properties:
              </Typography>
              <Typography variant='body2' className={classes.bodyText}>
                Under the Contacts tab, map all of the properties you'd like to send over to HubSpot when you send a Contact. Name and email address are required
              </Typography>
              <ImagePreviewer src={MapContactPropertiesImg} alt='map-contact-properties' />
              <Typography variant='subtitle2' className={classes.bulletNumbers}>
                3. Map Company properties:
              </Typography>
              <Typography variant='body2' className={classes.bodyText}>
                Under the Companies tab, map all of the properties you'd like to send over to Signals when you create a Company. Name and domain are required
              </Typography>
              <ImagePreviewer src={MapCompanyPropertiesImg} alt='map-company-properties' />
              <Typography variant='subtitle2' className={classes.bulletNumbers}>
                4. Map users:
              </Typography>
              <Typography variant='body2' className={classes.bodyText}>
                Under the Users tab, make sure to map your Signals users to the corresponding user in HubSpot
              </Typography>
              <ImagePreviewer src={MapUsersImg} alt='map-users' />
              <Typography variant='subtitle2' className={classes.bulletNumbers}>
                5. Define Ownership rules:
              </Typography>
              <Typography variant='body2' className={classes.bodyText}>
                Decide how you'd like company ownership to be configured within Signals. This will affect things like lead routing, alerts and booking meetings
              </Typography>
              <ImagePreviewer src={DefineOwnershipImg} alt='define-ownership' />
            </>
          }
        />
      </div>
      <div style={{ marginBottom: 20 }}>
        <Card
          content={
            <>
              <div className={classes.header}>
                <Typography className={classes.subtitle1}>
                  How to use it
                </Typography>
              </div>
              <Typography variant='subtitle2' className={classes.bulletNumbers}>
                PlayMaker
              </Typography>
              <Typography variant='body2' className={classes.bodyText}>
                1. Use the HubSpot skill to send discovered contacts and returning visitors to HubSpot lists
              </Typography>
              <ImagePreviewer src={Playmaker1Img} alt='playmaker1' />
              <Typography variant='body2' className={classes.bodyText}>
                2. Automatically create a company record for comapnies that match certain criteria using the Create Account skill
              </Typography>
              <ImagePreviewer src={Playmaker2Img} alt='playmaker2' />
              <Typography variant='body2' className={classes.bodyText}>
                3. Send alerts based on company ownder in HubSpot
              </Typography>
              <ImagePreviewer src={Playmaker3Img} alt='playmaker3' />
              <Typography variant='subtitle2' className={classes.bulletNumbers}>
                Bot Builder
              </Typography>
              <Typography variant='body2' className={classes.bodyText}>
                1. Send alerts, route agents to chat, and allow visitors to book meetings with your agents based on the company owner in HubSpot
              </Typography>
              <ImagePreviewer src={BotBuilderImg} alt='bot-builder' />
              <Typography variant='subtitle2' className={classes.bulletNumbers}>
                Dashboard
              </Typography>
              <Typography variant='body2' className={classes.bodyText}>
                1. See HubSpot data like company type, opp data, and more for companies that are visiting your website
              </Typography>
              <ImagePreviewer src={Dashboard1Img} alt='dashboard1' />
              <Typography variant='body2' className={classes.bodyText}>
                2. Easily create company records in HubSpot for companies that visit your site
              </Typography>
              <ImagePreviewer src={Dashboard2Img} alt='dashboard2' />
              <Typography variant='subtitle2' className={classes.bulletNumbers}>
                Chat
              </Typography>
              <Typography variant='body2' className={classes.bodyText}>
                1. Manually create contact records for contacts that weren’t automatically created
              </Typography>
              <ImagePreviewer src={ChatImg} alt='chat' />
              <Typography variant='subtitle2' className={classes.bulletNumbers}>
                ICP
              </Typography>
              <Typography variant='body2' className={classes.bodyText}>
                1. Use Signals to look at similarities across your customers to help determine your Ideal Customer Profile
              </Typography>
              <ImagePreviewer src={IcpImg} alt='icp' />
              <Typography variant='subtitle2' className={classes.bulletNumbers}>
                Segments
              </Typography>
              <Typography variant='body2' className={classes.bodyText}>
                1. Segment companies based on the HubSpot properties you care about
              </Typography>
              <ImagePreviewer src={SegmentsImg} alt='segments' />
            </>
          }
        />
      </div>
    </>
  )
}
