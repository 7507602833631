import Bot from 'classes/bots'
import { BotSequence } from 'classes/botSequences'
import Email from 'classes/emails'
import Form from 'classes/forms'
import { Play } from 'classes/plays'
import { Divider, Icon, Typography } from 'library/materialUI'
import { getContact } from 'api/contacts'
import { makeStyles } from '@material-ui/core/styles'
import { formatDate } from 'classes/classHelpers'
import { useEffect, useState } from 'react'
import { Avatar, getInitials } from 'cf-components/Avatars'
import { getBotType, truncateString } from 'library/helpers'
import Check from '@material-ui/icons/Check'
import Remove from '@material-ui/icons/Remove'
import Link from 'cf-components/Link'
import { getBot } from 'api/bots'

const useStyles = makeStyles(theme => ({
  avatarDisplay: {
    display: 'grid',
    gridTemplateColumns: '50px auto',
    gridGap: 15
  },
  itemActive: {
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    color: '#81AF20',
    fontWeight: 0,
    fontSize: '12px',
    marginLeft: 10,
    backgroundColor: '#E6EFD2',
    borderRadius: 5,
    padding: 5
  },
  itemInactive: {
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    color: '#868686',
    fontWeight: 0,
    marginLeft: 10,
    fontSize: '12px',
    backgroundColor: '#E5E5E5',
    borderRadius: 5,
    padding: 5
  },
  timeDiv: {
    fontSize: '13px',
    color: '#808080'
  },
  dateDiv: {
    fontSize: '14px'
  }
}))

interface ObjectTableRowProps {
  attributes: any
  type: any
  schemaConfig: string
}

export default function ObjectTableRow ({ attributes, type, schemaConfig }: ObjectTableRowProps) {
  const classes = useStyles()
  const [contact, setContact] = useState()
  const [bot, setBot] = useState()
  const initials = getInitials(attributes.name, 'A')

  useEffect(() => {
    if (attributes?.bot_id) {
      getBot({
        botID: attributes.bot_id,
        ID: undefined
      }).then(bot => {
        setBot(bot.data.attributes)
      })
    }

    if (attributes.contact_id === undefined) return
    getContact({
      personID: undefined,
      contactID: attributes.contact_id,
      includes: ['account', 'created_by_user'],
      authHeader: undefined
    }).then(person => {
      setContact(person)
    })
  }, [attributes])

  return (
    <div>
      {type === Form && (
        <div>
          <div style={{ display: 'grid', gridTemplateColumns: schemaConfig, alignItems: 'center', justifyContent: 'center' }}>
            <div className={classes.avatarDisplay}>
              <Avatar
                contact={contact}
                initials={initials}
              />
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div>
                  {attributes.name}
                </div>
                <div style={{ fontSize: '12px', color: '#00000080' }}>
                  {attributes.title || 'No Title Found'}
                </div>
              </div>
            </div>
            <div>
              <div className={classes.avatarDisplay}>
                <Avatar
                  domain={attributes.domain}
                  initials={getInitials(attributes.company_name, 'A')}
                />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div>
                    {attributes.company_name}
                  </div>
                  <div style={{ fontSize: '12px', color: '#00000080' }}>
                    {attributes.domain || 'No Domain Found'}
                  </div>
                </div>
              </div>
            </div>
            <div>
              {formatDate(attributes.created_timestamp)}
            </div>
            <div>
              {attributes.follow_up === 'prompt' ? (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Icon icon='announcement' />
                  <div style={{ marginLeft: '15px' }}>
                    Prompt shown, nothing selected
                  </div>
                </div>
              ) : attributes.follow_up === 'failed_live_chat' ? (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Icon icon='noLiveChat' />
                  <div style={{ marginLeft: '15px' }}>
                    Agent(s) didn't respond to chat
                  </div>
                </div>
              ) : attributes.follow_up === 'live_chat' ? (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Icon icon='liveChat' />
                  <div style={{ marginLeft: '15px' }}>
                    Live chat
                  </div>
                </div>
              ) : attributes.follow_up === 'calendar_dropped' ? (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Icon icon='eventBusy' />
                  <div style={{ marginLeft: '15px' }}>
                    Calendar shown, meeting not booked
                  </div>
                </div>
              ) : attributes.follow_up === 'meeting_booked' ? (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Icon icon='dateRange' />
                  <div style={{ marginLeft: '15px' }}>
                    Meeting booked
                  </div>
                </div>
              ) : <></>}
            </div>
          </div>
        </div>
      )}
      {type === Bot && (
        <div style={{ display: 'grid', gridTemplateColumns: schemaConfig }}>
          <div className={classes.avatarDisplay}>
            <Avatar
              contact={contact}
              initials={initials}
            />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div>
                {attributes.name}
              </div>
              <div style={{ fontSize: '12px', color: '#00000080' }}>
                {attributes.title || 'No Title Found'}
              </div>
            </div>
          </div>
          <div>
            <div className={classes.avatarDisplay}>
              <Avatar
                domain={attributes.domain}
                initials={getInitials(attributes.company_name, 'A')}
              />
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div>
                  {attributes.company_name}
                </div>
                <div style={{ fontSize: '12px', color: '#00000080' }}>
                  {attributes.domain || 'No Domain Found'}
                </div>
              </div>
            </div>
          </div>
          <div>
            {formatDate(attributes.created_timestamp)}
          </div>
        </div>
      )}
      {type === BotSequence && (
        <div style={{ display: 'grid', gridTemplateColumns: schemaConfig }}>
          <div className={classes.avatarDisplay}>
            <Avatar
              initials={getInitials(attributes.name, 'B')}
            />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div>
                {attributes.name}
              </div>
              <Typography variant='body2'>
                {getBotType(attributes.kind)}
              </Typography>
            </div>
          </div>
          <div>
            {attributes.is_active ? (
              <div className={classes.itemActive}>
                Active
              </div>
            ) : (
              <div className={classes.itemInactive}>
                Inactive
              </div>
            )}
          </div>
          <div>
            {attributes.last_fired ? formatDate(attributes.last_fired) : 'Never'}
          </div>
          <div>
            {attributes.fires_this_week}
          </div>
          <div>
            {attributes.total_fires}
          </div>
        </div>
      )}
      {type === Email && (
        <div>
          <div style={{ display: 'grid', gridTemplateColumns: schemaConfig, alignItems: 'center', justifyContent: 'center' }}>
            <Link to={`/contacts/${attributes.contact_id}`}>
              <div className={classes.avatarDisplay}>
                <Avatar
                  contact={contact}
                  initials={initials}
                />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div>
                    {truncateString((contact?.attributes?.preferred_name || (contact?.attributes?.first_name + ' ' + contact?.attributes?.last_name)), 30, true)}
                  </div>
                  <div style={{ fontSize: '12px', color: '#808080' }}>
                    {truncateString((contact?.attributes?.email || 'No Email Found'), 30, true)}
                  </div>
                </div>
              </div>
            </Link>
            <div>
              <Link to={`/bots/${bot?.id}`}>
                {truncateString(bot?.name, 21, true)}
              </Link>
            </div>
            <div>
              <div className={classes.dateDiv}>
                {formatDate(attributes.sent_timestamp, 'mmm dd, yyyy')}
              </div>
              <div className={classes.timeDiv}>
                {formatDate(attributes.sent_timestamp, 'h:MM TT (Z)')}
              </div>
            </div>
            <div>
              {attributes.delivered_timestamp ? (
                <Check style={{ fill: '#81AF20' }} />
              ) : (
                <Remove style={{ fill: '#1F1F1F' }} />
              )}
            </div>
            <div>
              {attributes.opened_timestamp ? (
                <Check style={{ fill: '#81AF20' }} />
              ) : (
                <Remove style={{ fill: '#1F1F1F' }} />
              )}
            </div>
            <div>
              {attributes.clicked_timestamp ? (
                <Check style={{ fill: '#81AF20' }} />
              ) : (
                <Remove style={{ fill: '#1F1F1F' }} />
              )}
            </div>
          </div>
        </div>
      )}
      {type === Play && (
        <div />
      )}
      <div style={{ marginTop: '20px', marginBottom: '20px' }}>
        <Divider />
      </div>
    </div>
  )
}
