import { ObjectHeader } from './ObjectHeader'
import { ObjectStats } from './ObjectStats'
import { FileHistoryDetails } from './DescriptionSection'
import { makeStyles } from '@material-ui/core/styles'
import ObjectTable from './ObjectTable'

const useStyles = makeStyles(theme => ({
  header: {
    marginBottom: 40
  }
}))

export const ObjectDataContainer = (props: {
  objectType: string
  showActivities: boolean | undefined
  stats?: { name: string, value: string }[]
}): JSX.Element => {
  const classes = useStyles()

  const { objectType, stats, showActivities } = props
  const objectTypeCaps = objectType.charAt(0).toUpperCase() + objectType.slice(1)

  return (
    <div>
      <div className={classes.header}>
        <ObjectHeader objectType={objectTypeCaps} />
      </div>
      {stats &&
        <div>
          <ObjectStats items={stats} />
        </div>}
      <div>
        <FileHistoryDetails />
        {showActivities &&
          <ObjectTable />}
      </div>
    </div>
  )
}
