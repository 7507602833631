import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import {
  Checkbox,
  Select,
  IconButton,
  MenuItem,
  Button,
  TextField
} from '@material-ui/core'
import ControlPointIcon from '@material-ui/icons/ControlPoint'

const useStyles = makeStyles((theme) => ({
  bodyText: {
    marginBottom: 40,
    fontSize: 16
  },
  subtitle1: {
    fontSize: 18,
    fontWeight: 600,
    marginBottom: 15
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  rowHeader: {
    alignItems: 'center',
    paddingBottom: 20,
    fontSize: 16,
    display: 'grid',
    gridTemplateColumns: '48px 410px 150px',
    gridGap: 10,
    gridTemplateAreas: '"cb fields required"',
    maxWidth: 1100
  },
  row: {
    display: 'grid',
    gridTemplateColumns: '48px 410px 150px',
    gridGap: 10,
    maxWidth: 1100,
    alignItems: 'center',
    paddingBottom: 20
  }
}))

const IntegrationEnabledFields = (props) => {
  const classes = useStyles()
  const integrationName = props.integrationName || 'Integration'

  const setRequired = (id, required) => {
    props.setRequired(id, required)
  }

  const fields = props.fields || []
  const enabledFields = fields.filter(f => f.attributes.enabled)
  const availableFieldsCount = fields.filter(f => !f.attributes.enabled).length
  if (props.adding) {
    enabledFields[enabledFields.length] = {
      id: 'select',
      attributes: {
        required: false,
        always_required: false
      }
    }
  }

  return (
    <>
      <Typography variant='body2' className={classes.bodyText}>
        Select which {integrationName} fields are enabled and required.
      </Typography>
      <div>
        <div className={classes.rowHeader}>
          <div style={{ gridArea: 'fields' }}>Enabled Fields</div>
          <div style={{ gridArea: 'required' }}>Required</div>
        </div>
        {enabledFields.map((row, index) => {
          const availableFields = fields.filter(f => f.attributes.enabled === false || f.id === row.id)
          return (
            <div key={index} className={classes.row}>
              {row.attributes.always_required ? <div />
                : (
                  <IconButton
                    id={row.id}
                    onClick={() => props.removeField(row.id)}
                  >
                    <HighlightOffIcon color='primary' />
                  </IconButton>
                )}
              {row.attributes.always_required
                ? (
                  <TextField
                    className={classes.textField}
                    name={row.id}
                    value={row.attributes.name}
                    color='primary'
                    disabled
                    variant='outlined'
                  />)
                : (
                  <Select
                    autoWidth
                    variant='outlined'
                    margin='dense'
                    defaultValue='select'
                    className={classes.select}
                    value={row.id}
                    onChange={(m) => {
                      const newValue = m.target.value
                      const oldValue = row.id
                      props.setNewFieldValue(newValue, oldValue)
                    }}
                  >
                    <MenuItem value='select' disabled>
                      <div
                        className={classes.menuHeader}
                        style={{
                          borderBottom: 'none',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between'
                        }}
                      >
                        <div style={{ fontSize: '1.05em' }}>Select a field to add</div>
                      </div>
                    </MenuItem>
                    {availableFields.map((field, index) => {
                      return (
                        <MenuItem value={field.id} key={index}>
                          <div className={classes.menuHeader}>{field.attributes.name}</div>
                        </MenuItem>
                      )
                    })}
                  </Select>)}
              {row.id === 'select' ? <div />
                : (
                  <div>
                    <Checkbox
                      name={row.id}
                      checked={row.attributes.required}
                      disabled={row.attributes.always_required}
                      color='primary'
                      onClick={(e) => {
                        setRequired(e.target.name, e.target.checked)
                      }}
                    />
                  </div>)}
            </div>
          )
        })}
        <div style={{ marginTop: 20 }}>
          <Button
            variant='outlined'
            color='primary'
            disabled={availableFieldsCount === 0}
            onClick={props.addField}
            startIcon={<ControlPointIcon />}
          >
            Add Field
          </Button>
          &nbsp;
        </div>
      </div>
    </>
  )
}

export default IntegrationEnabledFields
