import { makeStyles } from '@material-ui/core/styles'
import Fab from '@material-ui/core/Fab'
import { BoxButtonRow } from './BoxButtonRow'
import PricingInformation from './PricingInformation'
import { useState } from 'react'
import { LargeToggle } from './LargeToggle'
import { ChargifyOffer } from 'classes/chargifyOffer'
import Loader from 'library/loading/Loader'

const useStyles = makeStyles(theme => ({
    rowStyle: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        textAlign: 'center'
    },
    title: {
        fontSize: '28px',
        fontWeight: 'bold',
        fontFamily: 'poppins',
        display: 'flex',
        justifyContent: 'center'
      },
      planTitle: {
        fontSize: '32px',
        fontWeight: 'bold',
        fontFamily: 'poppins',
        display: 'flex',
        justifyContent: 'center'
      },
      buttonContent: {
        paddingRight: '80px',
        paddingLeft: '80px',
        fontSize: '20px',
        fontFamily: 'poppins',
        textTransform: 'none',
        borderRadius: '28px'
      },
      switch: {
        paddingRight: '24px',
        paddingLeft: '24px'
      }
  }))

export default function ChoosePlan (props: any) {
    const classes = useStyles()
    const [annual, setAnnual] = useState(props.annual)
    const [plan, setPlan] = useState(props.defaultPlan || 1)
    const parentSetPlanType = props.parentSetPlanType
    const parentSetAnnual = props.parentSetAnnual
    const curPlan = props.curPlanType
    const { data: offers, isLoading } = ChargifyOffer.loadAll()
    const currentOffers = annual ? offers.list.filter((offer) => offer.billingCycle === 'annual') : offers.list.filter((offer) => offer.billingCycle === 'monthly')
    const setModalStage = (stage: number) => {
      props.setModalStage(stage)
      parentSetPlanType(plan)
    }
    const setAllAnnual = () => {
      const toggled = !annual
      setAnnual(toggled)
      parentSetAnnual(toggled)
    }

    if (isLoading) return (<Loader type='spinner' title='Loading offer data...' />)

    return (
      <div className={classes.rowStyle}>
        <div style={{ paddingRight: '30px', width: '460px' }}>
          <div className={classes.planTitle} style={{ paddingTop: '200px' }}>
            Choose a Plan
          </div>
          <div className={classes.title} style={{ paddingTop: '20px', paddingBottom: '20px' }}>
            <div>Annual</div><div className={classes.switch}><LargeToggle onChange={() => setAllAnnual()} color='primary' checked={!annual} /></div> Monthly
          </div>
          <div>
            <BoxButtonRow
              offers={currentOffers}
              direction='horizontal'
              setPlanType={setPlan}
              defaultPlan={props.defaultPlan}
            />
          </div>
          <div style={{ paddingTop: '65px', paddingBottom: '180px' }}>
            <Fab
              className={classes.buttonContent}
              color='primary'
              size='large'
              variant='extended'
              onClick={curPlan === plan ? () => null : () => { setModalStage(2) }}
              disabled={curPlan === plan || offers.list.length < 1}
            >
              {curPlan >= plan ? (curPlan > plan ? 'Downgrade Plan' : 'Already on plan') : 'Select this plan'}
            </Fab>
          </div>
        </div>
        <PricingInformation planType={plan} annual={annual} curPlanType={curPlan} />
      </div>
    )
  }
