import { useState, useEffect, useCallback, useRef } from 'react'
import theme from 'theme'
import { jsPlumbUtil } from 'jsplumbtoolkit'
import { groupSearch } from 'api/keyword_groups'
import OptionPort from '../OptionPort'
import { Divider, Select, MenuItem, Input } from '@material-ui/core'
import { ThemeProvider, makeStyles } from '@material-ui/core/styles'
import CancelRoundedIcon from '@material-ui/icons/CancelRounded'
import useNodeStyles from './NodeStyles'
import QuestionIcon from '@material-ui/icons/LiveHelp'
import AlertIcon from '@material-ui/icons/Warning'
import Alert from '@material-ui/lab/Alert'
import Collapse from '@material-ui/core/Collapse'
import RenderEmoji from 'pages/chat/RenderEmoji'
import KeywordGroupsSectionOld from './KeywordGroupsSectionOld'
import AttributeSelect from '../AttributeSelect'
import Checkbox from 'library/materialUI/Checkbox'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import '../quill.css'
import 'jsplumb'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import { RichTextEditor } from 'library/richText/RichTextEditor'
import ButtonTextField from 'library/textFields/ButtonTextField'
import { EVENT_TYPE, Emitter } from 'emitter'
import { getBot } from 'api/bots.js'
import { Modal, ModalSection } from 'library/Modal'

const useStyles = makeStyles(theme => ({
  form: {
    fontFamily: 'Poppins'
  },
  subsection: {
    marginTop: 15,
    fontSize: '14px',
    fontWeight: 600
  },
  divider: {
    marginTop: 5,
    marginBottom: 5
  },
  options: {
    display: 'flex',
    marginTop: 20,
    flexDirection: 'column',
    maxHeight: 210,
    overflowY: 'auto'
  },
  deleteIcon: {
    fill: 'white'
  },
  sectionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  buttonText: {
    padding: 0
  },
  buttonRoot: {
    padding: '2px 5px',
    fontSize: '13px',
    lineHeight: 1
  },
  responseTypeDiv: {
    fontFamily: 'Poppins',
    fontSize: '13px',
    color: '#4C4C4C',
    marginBottom: 10,
    marginTop: 15,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  selectInput: {
    padding: 8,
    paddingLeft: 10,
    borderRadius: 30
  },
  inputRoot: {
    borderRadius: 30
  },
  editableChip: {
    height: '1.1876em',
    padding: '7px 3px',
    color: 'white',
    backgroundColor: theme.palette.primary.alt,
    borderRadius: 16,
    alignItems: 'center'
  },
  chip: {
    fontSize: '13px',
    color: 'white',
    fontWeight: 600
  },
  select: {
    fontFamily: 'Poppins',
    fontSize: '14px',
    color: '#5C5C5C',
    borderRadius: 30
  },
  contentClass: {
    padding: 20,
    height: 360,
    overflowY: 'auto'
  },
  checkboxLabel: {
    fontSize: '14px',
    fontWeight: 600
  },
  missingOptions: {
    backgroundColor: '#F0706F',
    color: '#FFF',
    display: 'flex',
    justifyContent: 'center',
    marginTop: 8,
    fontWeight: 600,
    padding: 3,
    borderRadius: 3
  },
  questionText: {
    textAlign: 'left',
    font: 'normal normal normal 11px/15px Poppins',
    letterSpacing: 0,
    color: '#989898',
    opacity: 1,
    marginTop: -10,
    marginBottom: 15
  },
  buttonTextContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 5
  },
  defaultPrompt: {
    fontFamily: 'poppins',
    fontSize: '16px',
    lineHeight: '21px',
    maxHeight: '100px',
    maxWidth: '340px',
    overflow: 'hidden'
  },
  miniPrompt: {
    borderRadius: '10px',
    fontFamily: 'poppins',
    fontSize: '20px',
    lineHeight: '21px',
    marginLeft: '15px',
    maxHeight: '80px',
    maxWidth: '225px',
    overflow: 'hidden',
    display: ['inline-block', 'flex'],
    alignItems: 'center',
    minHeight: '32px',
    padding: '10px 16px',
    '& p': {
      margin: 0,
      maxHeight: '63px'
    }
  }
}))

function getWidthOfInput (value) {
  const div = document.createElement('div')
  div.id = 'button-div'
  div.innerHTML = value.endsWith(' ') ? value + 's' : value
  div.style.cssText = 'position:absolute; font-size: 13px; font-family: Poppins, sans serif; visibility: hidden; padding: 12px; font-weight: 600'
  document.body.appendChild(div)
  const width = div.offsetWidth
  document.body.removeChild(div)
  return width + 25
}

function EditableChip (props) {
  const classes = useStyles()

  return (
    <div className={classes.editableChip} style={{ width: getWidthOfInput(props.value) + 12, margin: 5, display: 'flex' }}>
      <DragIndicatorIcon style={{ display: 'flex', paddingLeft: 2, fontSize: '1.3rem' }} />
      <div className={classes.buttonTextContainer} style={{ width: getWidthOfInput(props.value) }}>
        <Input
          value={props.value}
          disableUnderline
          onChange={e => props.setValue(props.index, e.target.value)}
          fullWidth
          className={classes.chip}
        />
      </div>
      <CancelRoundedIcon
        style={{ marginTop: -1, fontSize: '1.2rem', cursor: 'pointer' }}
        onClick={() => {
          props.setButtons(options => options.filter(option => option.id !== props.option.id))
          props.setOptions(options => options.filter(option => option.id !== props.option.id))
        }}
      />
    </div>
  )
}

function OptionsModal (props) {
  const classes = useStyles()
  const [text, setText] = useState(props.body)
  const [, setOptions] = useState(props.ports)
  const [responseType, setResponseType] = useState(props.responseType || 'buttons')
  const [invalidResponse, setInvalidResponse] = useState(props.invalidResponse || '')
  const [secondResponse, setSecondResponse] = useState(props.secondResponse || '')
  const [captureTimes, setCaptureTimes] = useState(props.captureTimes || 3)
  const [buttons, setButtons] = useState(props.buttons || props.ports)
  const [keywordOptions, setKeywordOptions] = useState(props.keywordOptions || [])
  const [attribute, setAttribute] = useState({ attribute: props.attribute || 'first_name' })
  const [buttonLabel, setButtonLabel] = useState('')
  const [mapAttribute, setMapAttribute] = useState(props.mapAttribute ? props.mapAttribute : false)
  const promptStyle = props.botMeta?.prompt_style || 'big'
  const promptSizeRef = useRef(null)
  const isPromptOverflowing = props.isPromptOverflowing
  const setIsPromptOverflowing = props.setIsPromptOverflowing

  useEffect(() => {
    if (!promptSizeRef.current) return

    // Once the user starts typing anything, scroll height is 1 more than client height
    // So, we add a tolerance of 1 to check if the prompt is overflowing
    const tolerance = 1
    setIsPromptOverflowing(promptSizeRef.current.scrollHeight > promptSizeRef.current.clientHeight + tolerance)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text])

  function editInvalidResponse (value, delta, source, editor) {
    setInvalidResponse(value)
  }

  function editSecondResponse (value, delta, source, editor) {
    setSecondResponse(value)
  }

  useEffect(() => {
    setText(props.body)
  }, [props.body])

  useEffect(() => {
    setOptions(props.ports)
  }, [props.ports])

  function editText (value, delta, source, editor) {
    setText(value)
  }

  function editOption (index, label) {
    const newButton = buttons[index]
    newButton.label = label
    setButtons([
      ...buttons.slice(0, index),
      newButton,
      ...buttons.slice(index + 1)
    ])
  }

  const handleChange = (event) => {
    const ids = event.target.value
    if (ids.length > keywordOptions.length) {
      const currentIDs = keywordOptions.map(option => option.keywordGroupID)
      const id = ids.filter(id => !currentIDs.includes(id))[0]
      const newOptions = keywordOptions.concat({
        id: jsPlumbUtil.uuid(),
        keywordGroupID: id,
        label: props.keywordGroupDict[id].name
      })
      setKeywordOptions(newOptions)
    } else {
      setKeywordOptions(options => options.filter(option => ids.includes(option.keywordGroupID)))
    }
  }

  function cancelChanges () {
    props.onHide()
    setText(props.body)
    setButtons(props.buttons)
  }

  const handleKeywordDelete = (value) => {
    setKeywordOptions(options => options.filter(option => option.keywordGroupID !== value))
  }

  function addOption (label) {
    if (label === '') {
      return
    }
    const newOptions = buttons.concat({
      id: jsPlumbUtil.uuid(),
      label: label
    })
    setButtons(newOptions)
  }

  if (!props.open) {
    return <></>
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  function onDragEnd (result) {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const items = reorder(
      buttons,
      result.source.index,
      result.destination.index
    )

    setButtons(items)
  }

  function saveChanges () {
    let newOptions
    if (buttonLabel) {
      newOptions = buttons.concat({
        id: jsPlumbUtil.uuid(),
        label: buttonLabel
      })
      setButtons(newOptions)
      setButtonLabel('')
    } else {
      newOptions = buttons
    }
    props.save({
      message: text,
      buttons: newOptions,
      keywordOptions,
      responseType,
      invalidResponse,
      secondResponse,
      captureTimes,
      attribute: attribute.attribute,
      mapAttribute
    })
  }

  Emitter.on(EVENT_TYPE.BROWSER_BACK, cancelChanges)

  return (
    <>
      <ThemeProvider theme={theme}>
        <div
          ref={promptSizeRef}
          dangerouslySetInnerHTML={{ __html: text }}
          className={promptStyle !== 'open' && (promptStyle === 'big' ? classes.defaultPrompt : classes.miniPrompt)}
          style={{ visibility: 'hidden' }}
        />
        <Modal
          open={props.open}
          onHide={cancelChanges}
          handleSave={saveChanges}
          title='Edit Question'
          helplink='home/bots/nodes/Question'
          helplinkLabel='Learn more about this skill'
          saveIcon='save'
        >
          <ModalSection
            title='Question'
            subtitle='What question would you like the bot to ask the visitor?'
          >
            {!props.isContentBot && (
              <Collapse in={props.isFirstNode && isPromptOverflowing}>
                <Alert severity='warning'>Your message may be too long to fully appear in the bot prompt on some devices and could be partially cut off. To avoid having your message cut off, consider shortening your message.</Alert>
              </Collapse>
            )}
            <div style={{ height: 140 }} className='customQuill'>
              <RichTextEditor
                message={text}
                onChange={editText}
                lines={3}
                placeholder='Type your question here...'
                showMergeFields
              />
            </div>
          </ModalSection>
          <ModalSection
            title='Responses'
            subtitle='How can visitors respond to this question?'
          >
            <div className='select'>
              <Select
                style={{ width: '100%' }}
                variant='outlined'
                classes={{ outlined: classes.select }}
                margin='dense'
                value={responseType}
                onChange={e => setResponseType(e.target.value)}
              >
                <MenuItem value='buttons'>Buttons</MenuItem>
                <MenuItem value='keywords'>Keywords</MenuItem>
                <MenuItem value='free_response'>Free response</MenuItem>
              </Select>
            </div>
          </ModalSection>
          {responseType === 'buttons' ? (
            <ModalSection
              title='Buttons'
              subtitle='What responses do you want to let the visitor choose from?'
            >
              <div className={classes.subsection}>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId='droppable' direction='vertical'>
                    {(provided, snapshot) => (
                      <div
                        className={classes.options}
                        ref={provided.innerRef}
                      >
                        {
                          buttons.map((option, index) => (
                            <Draggable key={option.id} draggableId={option.id} index={index}>
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <EditableChip
                                    key={option.id}
                                    index={index}
                                    value={option.label}
                                    setValue={editOption}
                                    setButtons={setButtons}
                                    setOptions={setOptions}
                                    option={option}
                                  />
                                </div>
                              )}
                            </Draggable>
                          ))
                        }
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
                <div>
                  <ButtonTextField
                    addButton={addOption}
                    label={buttonLabel}
                    setLabel={setButtonLabel}
                  />
                </div>
              </div>
            </ModalSection>
          ) : (
            responseType === 'keywords'
              ? (
                <KeywordGroupsSectionOld
                  handleChange={handleChange}
                  keywordGroupDict={props.keywordGroupDict}
                  handleKeywordDelete={handleKeywordDelete}
                  setCaptureTimes={setCaptureTimes}
                  captureTimes={captureTimes}
                  invalidResponse={invalidResponse}
                  editInvalidResponse={editInvalidResponse}
                  secondResponse={secondResponse}
                  editSecondResponse={editSecondResponse}
                  keywordGroups={props.keywordGroups}
                  keywordOptions={keywordOptions}
                  loadKeywordGroups={props.loadKeywordGroups}
                  saveKeywordGroup={props.saveKeywordGroup}
                />
              )
              : <></>
          )}
          <ModalSection
            title='Mapping'
            subtitle='Would you like to map this response to a contact attribute?'
          >
            <div>
              <Checkbox
                color='primary'
                checked={mapAttribute}
                onChange={(e) => setMapAttribute(e)}
                label='Map response to contact attribute'
              />
            </div>
            <div style={{ marginBottom: 25, display: mapAttribute ? 'block' : 'none' }}>
              <AttributeSelect
                type='people'
                value={attribute.attribute}
                setAttribute={setAttribute}
              />
            </div>
          </ModalSection>
        </Modal>
      </ThemeProvider>
    </>
  )
}

const OptionsNode = (props) => {
  const [chatServiceUrl, setServiceUrl] = useState(null)
  const [groups, setGroups] = useState([])
  const [groupDict, setGroupDict] = useState(null)
  const base = props.base
  const [isFirstNode, setIsFirstNode] = useState(false)
  const [isPromptOverflowing, setIsPromptOverflowing] = useState(false)
  const nodePromptSizeRef = useRef(null)
  const promptStyle = base.state.botMeta?.prompt_style || 'big'
  const botID = props?.data?.botMeta?.bot_id
  const [isContentBot, setIsContentBot] = useState(false)

  useEffect(() => {
    if (isFirstNode && botID) {
      getBot({ botID }).then(botResponse => {
        if (botResponse.data.attributes.kind === 'content_page') {
          setIsContentBot(true)
        }
      })
    }
  }, [botID, isFirstNode])

  useEffect(() => {
    if (!nodePromptSizeRef.current) return
    setIsPromptOverflowing(nodePromptSizeRef.current.scrollHeight > nodePromptSizeRef.current.clientHeight)
  }, [base.state.body, promptStyle, nodePromptSizeRef])

  const assignKey = (data) => {
    data._reactKey = data._reactKey || jsPlumbUtil.uuid()
    return data._reactKey
  }

  Emitter.on(EVENT_TYPE.SET_FIRST_NODE, (payload) => {
    if (base?.state?.id === payload?.firstID) {
      setIsFirstNode(true)
    } else {
      setIsFirstNode(false)
    }
  })

  useEffect(() => {
    fetch('/api/auth/me', {
      method: 'GET',
      headers: { 'Content-Type': 'application/vnd.api+json' }
    }).then(response => response.json())
      .then(response => {
        if (response.data && response.data.id) {
          setServiceUrl(response.data.links.chat_service || 'localhost:8002/api/chat-service/a')
        }
      })
  }, [])

  const loadKeywordGroups = useCallback(() => {
    groupSearch({ chatServiceUrl }).then(response => {
      setGroups(response.data)
      let groupDict = {}
      response.data.map(
        group => (groupDict = { ...groupDict, [group.id]: group.attributes })
      )
      setGroupDict(groupDict)
    })
  }, [chatServiceUrl])

  function saveKeywordGroup () {
    loadKeywordGroups()
  }

  useEffect(() => {
    if (chatServiceUrl && groupDict === null) {
      loadKeywordGroups()
    }
  }, [chatServiceUrl, groupDict, loadKeywordGroups])

  const saveChanges = useCallback(({ message, buttons, keywordOptions, responseType, invalidResponse, secondResponse, captureTimes, attribute, mapAttribute }) => {
    let newOptions = [...keywordOptions].concat({ id: 'invalid', label: 'Invalid Response' })

    if (responseType === 'free_response') {
      newOptions = [{ id: 'response', label: 'Visitor Response' }]
    }

    const ports = responseType === 'buttons' ? buttons : newOptions

    const new_ids = ports.map(port => port.id)

    setTimeout(() => {
      base.toolkit.batch(() => {
        const node = base.getNode()
        const oldPorts = node.getPorts()
        const new_data =
        {
          ...base.state,
          body: message,
          buttons: buttons,
          keywordOptions: keywordOptions,
          responseType: responseType,
          invalidResponse: invalidResponse,
          secondResponse: secondResponse,
          captureTimes: captureTimes,
          attribute: attribute,
          mapAttribute: mapAttribute
        }
        base.toolkit.updateNode(node, new_data)
        const port_ids = oldPorts.map(i => i.id)
        for (const port_id of port_ids) {
          if (!(new_ids.includes(port_id))) {
            base.toolkit.removePort(node, port_id)
          }
        }
        for (const port of ports) {
          if (!(port_ids.includes(port.id))) {
            base.addNewPort('default', port)
          }
        }
      })
    }, 0)
    base.toolkit.batch(() => {
      props.setModalOpen(false)
    })
  }, [base, props])

  const classes = useNodeStyles(props)
  const optionClasses = useStyles()

  const ports = base.state.responseType === 'buttons' ? base.state.buttons : base.state.ports

  return (
    <>
      <div>
        <div
          className={`${classes.dragger} ${classes.visitorInteraction}`}
        >
          <div className='node-header'>
            <div className={classes.iconDiv}><QuestionIcon /></div>
            <div className={classes.cardTitle}>Question</div>
            {isFirstNode && isPromptOverflowing && !isContentBot ? <AlertIcon className={classes.iconDiv} style={{ color: 'yellow', marginLeft: 'auto', marginRight: '10px' }} /> : null}
          </div>
          <jtk-target
            scope='default'
          />
          <div className='node-body' style={{ minHeight: 36 }}>
            <RenderEmoji
              text={base.state.body === '' ? 'Enter a question...' : base.state.body}
              rich='true'
            />
            <Divider style={{ marginTop: 5 }} />
            <div>
              {ports.map((c, index) => {
                return (
                  <OptionPort
                    index={index}
                    data={c}
                    key={assignKey(c)}
                    parent={base}
                    kind='visitorInteraction'
                  />
                )
              })}
              {ports.length === 0
                ? (
                  <div className={optionClasses.missingOptions}>
                    Add options to continue
                  </div>)
                : <></>}
            </div>
          </div>
        </div>
      </div>
      <OptionsModal
        open={props.modalOpen}
        onHide={() => props.setModalOpen(false)}
        save={saveChanges}
        body={base.state.body}
        ports={base.state.ports}
        keywordOptions={base.state.keywordOptions}
        responseType={base.state.responseType}
        invalidResponse={base.state.invalidResponse}
        secondResponse={base.state.secondResponse}
        captureTimes={base.state.captureTimes}
        buttons={base.state.buttons}
        keywordGroups={groups}
        keywordGroupDict={groupDict}
        loadKeywordGroups={loadKeywordGroups}
        saveKeywordGroup={saveKeywordGroup}
        attribute={base.state.attribute}
        mapAttribute={base.state.mapAttribute}
        isFirstNode={isFirstNode}
        isPromptOverflowing={isPromptOverflowing}
        setIsPromptOverflowing={setIsPromptOverflowing}
        botMeta={base.state.botMeta}
        isContentBot={isContentBot}
      />
      {/* <div
        ref={nodePromptSizeRef}
        dangerouslySetInnerHTML={{ __html: base.state.body }}
        className={promptStyle !== 'open' && (promptStyle === 'big' ? classes.defaultPrompt : classes.miniPrompt)}
        style={promptStyle !== 'open' && (promptStyle === 'big' ? { visibility: 'hidden', position: 'absolute', maxHeight: '100px', maxWidth: '199px', fontSize: '12px', lineHeight: '21px' } : { visibility: 'hidden', position: 'absolute', fontSize: '20px', lineHeight: '21px', marginLeft: '15px', maxHeight: '80px', maxWidth: '225px', overflow: 'hidden', display: ['inline-block', 'flex'], alignItems: 'center', minHeight: '32px', padding: '10px 16px' })}
      /> */}
    </>
  )
}

export default OptionsNode
