import React, { useState, useContext } from 'react'
import { ConversationStyles as useStyles } from '../MobileChatStyles'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import IconButton from '@material-ui/core/IconButton'
import { Avatar } from 'cf-components/Avatars'
import { getDisplayName } from 'pages/chat/agent-chat/conversationHelpers'
import MobileMessageDisplay from './MobileMessageDisplay'
import MobileMessageEntry from './MobileMessageEntry'
import CloseIcon from '@material-ui/icons/Close'
import { SavedReply } from 'classes/savedReplies'
import { Divider, Fab, MenuItem, Menu, Checkbox, ListItemText, Chip } from '@material-ui/core'
import { ScrollBox } from 'cf-components/ScrollBox'
import FilterListIcon from '@material-ui/icons/FilterList'
import { TenantDataContext } from '../../agent-chat/tenant-data-context'
import { ConversationWindowContext } from 'pages/chat/agent-chat/conversation-window-context'

function VisitorContactInfo (props) {
  const classes = useStyles()
  const { isOnline } = useContext(ConversationWindowContext)
  const conversation = props.conversation
  const name = getDisplayName(conversation)
  const companyName = conversation.attributes.company_name || 'Unknown'
  const location = conversation.attributes.location
  const online = isOnline(conversation)

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ marginRight: 15 }}>
        <Avatar
          conversation={conversation}
          status={online ? 'available' : 'unavailable'}
          size='md'
        />
      </div>
      <div className={classes.contactInfo}>
        <div>
          <div className={classes.contactName}>{name}</div>
        </div>
        <div className={classes.subInfo} style={{ fontSize: '13px' }}>
          {companyName}
        </div>
        <div className={classes.subInfo} style={{ fontSize: '11px' }}>
          {location}
        </div>
      </div>
    </div>
  )
}

function InsertButton (props) {
  const classes = useStyles()
  const insertReply = props.insertReply
  const handleInsert = () => {
    const reply = props.contents.replace('<p>', '').replace('</p>', '')
    insertReply(reply)
  }

  return (
    <Fab
      variant='extended'
      size='small'
      className={classes.buttons}
      color='primary'
      onClick={() => handleInsert()}
    >
      Insert
    </Fab>
  )
}

function ReplyRow (props) {
  const classes = useStyles()
  const insertReply = props.insertReply
  const contents = props.contents
  return (
    <div>
      <div
        className={classes.replyRow}
      >
        <div className={classes.replyText}>
          {props.title}
        </div>
        <InsertButton insertReply={insertReply} contents={contents} />
      </div>
      <Divider />
    </div>
  )
}

function Filter (props) {
  const classes = useStyles()
  const filters = props.filters
  const handleChange = props.handleChange
  const { replyCategories: categories } = useContext(TenantDataContext)
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  if (!categories.length) {
    return <></>
  }

  return (
    <>
      {!filters.length ? <></> : (
        <Chip
          className={classes.filterChip}
          label={filters.length + ' filter' + (filters.length === 1 ? '' : 's')}
          onDelete={() => handleChange('reset')}
          deleteIcon={<CloseIcon />}
        />
      )}
      <div>
        <FilterListIcon
          color='primary'
          onClick={handleClick}
          className={classes.actionIcon}
        />
        <Menu
          id='long-menu'
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}
        >
          <MenuItem value='' className={classes.menuItem}>Filter By</MenuItem>
          {
            categories.map((c, index) => (
              <MenuItem value={c} key={index} className={classes.menuItem} onClick={() => handleChange(c)}>
                <Checkbox checked={filters.indexOf(c) > -1} color='primary' style={{ padding: 4 }} />
                <ListItemText primary={c} classes={{ primary: classes.labelText }} />
              </MenuItem>
            ))
          }
        </Menu>
      </div>
    </>
  )
}

function SavedReplyList (props) {
  const setMessage = props.setMessage
  const replies = props.replies
  const insertReply = (value) => setMessage(value)
  const [filters, setFilters] = useState([])

  function handleChange (value) {
    if (value === 'reset') {
      setFilters([])
    } else if (filters.indexOf(value) > -1) {
      setFilters([...filters].filter(f => f !== value))
    } else {
      setFilters([...filters, value])
    }
  }

  const filteredReplies = [].concat(replies).filter(r =>
    filters.length === 0 || (r.categories &&
      (r.categories.filter(
        value => filters.indexOf(value) !== -1)).length > 0))

  return (
    <div style={{ padding: '5px' }}>
      <div style={{ marginRight: '17px', paddingTop: '10px', paddingBottom: '10px', textAlign: 'right' }}>
        <Filter
          replies={replies}
          handleChange={handleChange}
          filters={filters}
        />
      </div>
      <ScrollBox>
        {filteredReplies.map(row => (
          <ReplyRow
            title={row.name}
            key={row.id}
            contents={row.content}
            insertReply={insertReply}
          />
        ))}
      </ScrollBox>
    </div>
  )
}

function ConversationView (props) {
  const classes = useStyles()
  const [repliesWindow, setRepliesWindow] = useState(false)
  const { data: replies, isLoading } = SavedReply.loadAll()
  const [message, setMessage] = useState('')
  const state = props.state
  const conversation = props.conversation

  return (
    <div className={classes.chatConversationDiv}>
      {!repliesWindow ? (
        <>
          <div className={classes.header}>
            <IconButton onClick={props.goBack}>
              <ArrowBackIcon className={classes.arrow} />
            </IconButton>
            <VisitorContactInfo
              conversation={conversation}
            />
          </div>
          <MobileMessageDisplay
            state={state}
            conversation={conversation}
          />
          <MobileMessageEntry
            user={props.user}
            state={state}
            participant={props.participant}
            conversation={conversation}
            setRepliesWindow={setRepliesWindow}
            message={message}
            setParentMessage={setMessage}
          />
        </>
      ) : (
        <>
          <div style={{ backgroundColor: '#9933FF', padding: '16px 8px', display: 'grid', gridTemplateColumns: '200px auto 50px', gridGap: 15, alignItems: 'center' }}>
            <div style={{ fontFamily: 'poppins', fontSize: '19px', color: 'white', marginLeft: '10px' }}>
              Saved Replies
            </div>
            <div />
            <IconButton
              aria-label='close'
              onClick={() => setRepliesWindow(false)}
              style={{ color: '#FFF' }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div>
            {!isLoading && (
              <SavedReplyList
                setMessage={(e) => {
                  setMessage(e)
                  setRepliesWindow(false)
                }}
                replies={replies.list}
              />
            )}
          </div>
          <MobileMessageEntry
            user={props.user}
            conversation={props.conversation}
            setRepliesWindow={setRepliesWindow}
            message={message}
            setParentMessage={setMessage}
            state={state}
            participant={props.participant}
          />
        </>
      )}
    </div>
  )
}

export default ConversationView
