import User from 'classes/users';
import { Icon } from 'library/materialUI';
import { ModalSection } from 'library/Modal';
import Picker from 'library/Picker';
import { useMemo } from 'react';
import CSVDropZone from './CSVDropZone';
import { CSVFileAttributes } from './CSVUploadModal';

interface CSVUploadStepProps {
  setDisableNextBtn: (boolean: boolean) => void
  setCSVFileAttributes: ({ CSVFile, fallbackUserId }: { CSVFile?: File, fallbackUserId?: number }) => void
  CSVFileAttributes: CSVFileAttributes
}

export default function CSVUploadStep ({ setDisableNextBtn, setCSVFileAttributes, CSVFileAttributes } : CSVUploadStepProps) {
  const CSVFile = CSVFileAttributes.CSVFile
  const fallbackOwner = CSVFileAttributes.fallbackUserId

  useMemo(() => {
    if (!!fallbackOwner && CSVFile) {
      setDisableNextBtn(false)
    } else {
      setDisableNextBtn(true)
    }
  }, [fallbackOwner, CSVFile, setDisableNextBtn])

  return (
    <>
      <ModalSection title='Step 1: Upload a CSV' subtitle="Upload a CSV containing a list of the companies you'd like to include in your segment">
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div style={{ display: 'flex', width: '97%', backgroundColor: 'rgba(153, 51, 255, 0.15)' }}>
            <div style={{ margin: '0px 5px' }}><Icon icon='info' color='primary' size='sm' /></div>
            <div style={{ color: '#985ce6', textAlign: 'left', letterSpacing: 0, font: 'normal normal normal 12px/18px Poppins' }}>"Website" and "Name" fields are required. "Account Owner Email" and "Account Type" are optional</div>
          </div>
        </div>
        <CSVDropZone
          CSVFile={CSVFile as File}
          handleDrop={(file:any) => { setCSVFileAttributes({ CSVFile: file }) }}
        />
      </ModalSection>
      <ModalSection title='Step 2 Choose a Fallback Owner' subtitle='What user should own these accounts in case a Signals user is not found in the export?'>
        <Picker
          selection={fallbackOwner}
          setSelection={(value: User) => { if (value) { setCSVFileAttributes({ fallbackUserId: value.id as number }) } else { setCSVFileAttributes({ fallbackUserId: 0 }) } }}
          objectType='users'
          label='Select a Default Account Owner'
        />
      </ModalSection>
    </>
  )
}
