const secondNetFlow = {
  flow: {
    data: {
      edges: [
        {
          data: {
            id: 'e8f8f125-2a48-419b-bb55-791dce35379e',
            type: 'common'
          },
          source: 'start.default',
          target: 'f64beef5-357c-4941-b00f-f66c559b7587'
        },
        {
          data: {
            id: 'bfdffaf6-0034-4d5a-8c74-deaa1771687b',
            type: 'common'
          },
          source: 'f64beef5-357c-4941-b00f-f66c559b7587.b200338e-901b-4e62-8ee2-e4ebc4a7db6b',
          target: 'f7a54153-28b4-4bd7-acef-2f5234c8bc9f'
        },
        {
          data: {
            id: '2b6eb0dd-a67f-447f-baf2-819f00f78460',
            type: 'common'
          },
          source: 'f64beef5-357c-4941-b00f-f66c559b7587.18b47cfd-277e-4412-8267-406e50ae4754',
          target: '1eae8acf-c8b8-4d89-9669-36ed29d166f1'
        },
        {
          data: {
            id: '36ccdd25-75b9-4064-997b-d2ec857df24b',
            type: 'common'
          },
          source: 'f7a54153-28b4-4bd7-acef-2f5234c8bc9f.default',
          target: '8bd4a686-ef22-4a31-824a-a5b6c951b963'
        },
        {
          data: {
            id: '8b6d49ea-96b5-4c21-a480-0d9914c44fb2',
            type: 'common'
          },
          source: 'f7a54153-28b4-4bd7-acef-2f5234c8bc9f.2a93f209-696f-4ae2-9aac-a4c6a44390f6',
          target: 'd080bfa9-618c-4a59-bdaa-07ef63d11907'
        },
        {
          data: {
            id: '6fd7cd36-fa2c-45c8-a472-d48ccb608c22',
            type: 'common'
          },
          source: '1eae8acf-c8b8-4d89-9669-36ed29d166f1.default',
          target: '0eccaac1-4379-4567-b1c5-9a5f1eb504b7'
        },
        {
          data: {
            id: '21ac1837-b9bb-4201-aa4f-b6c3142297c8',
            type: 'common'
          },
          source: '8bd4a686-ef22-4a31-824a-a5b6c951b963.d49fd593-b89b-4fdb-b242-47318be206af',
          target: '8515cf55-f4b2-4da7-9c31-aa1cc6cef7d8'
        },
        {
          data: {
            id: '36e04000-35cb-46ba-8a45-837fc18bd325',
            type: 'common'
          },
          source: '8515cf55-f4b2-4da7-9c31-aa1cc6cef7d8.default',
          target: '0eccaac1-4379-4567-b1c5-9a5f1eb504b7'
        },
        {
          data: {
            id: '10a00d7c-3a40-4b7f-b794-e518360521fc',
            type: 'common'
          },
          source: '8bd4a686-ef22-4a31-824a-a5b6c951b963.5d3ef417-0ba8-40ec-9b55-147572b850a0',
          target: '137666e7-6278-4d22-b3c6-04ffe17891ad'
        },
        {
          data: {
            id: '9ae7ca37-15df-460f-9835-c994e20ab8ea',
            type: 'common'
          },
          source: 'd080bfa9-618c-4a59-bdaa-07ef63d11907.951a6301-509b-4c73-9157-f8fbf8968df4',
          target: '137666e7-6278-4d22-b3c6-04ffe17891ad'
        },
        {
          data: {
            id: 'e5d19505-4ac4-4664-afb5-b3b9b3069a87',
            type: 'common'
          },
          source: 'd080bfa9-618c-4a59-bdaa-07ef63d11907.56512367-ab94-4037-9e03-4cdfc3490609',
          target: '1d124d6e-1d35-4d1f-915f-93990e1aff81'
        },
        {
          data: {
            id: 'a451ef11-df5d-4b5f-a772-d31dd8f986ae',
            type: 'common'
          },
          source: 'd080bfa9-618c-4a59-bdaa-07ef63d11907.2225894a-ed61-4821-b641-a3d030e3b869',
          target: '8c8d3596-3828-4ce8-89c1-7eba063b07d1'
        },
        {
          data: {
            id: '18b95c80-fb93-407c-9d67-8d533d90c731',
            type: 'common'
          },
          source: '0eccaac1-4379-4567-b1c5-9a5f1eb504b7.response',
          target: '41f48eda-522b-479b-b72a-4a1abb53795f'
        },
        {
          data: {
            id: '858ed585-f74b-48b2-b7b4-3f6c9e69a111',
            type: 'common'
          },
          source: '41f48eda-522b-479b-b72a-4a1abb53795f.5cece357-7515-4d2f-bb1d-5fa6500460a1',
          target: '48924d04-35af-470a-b099-f6b646a7b01f'
        },
        {
          data: {
            id: 'd99b9496-160d-4b48-940f-b154d7ae8d70',
            type: 'common'
          },
          source: '41f48eda-522b-479b-b72a-4a1abb53795f.184fd1e2-0253-4110-a4d9-7440151af95a',
          target: '48924d04-35af-470a-b099-f6b646a7b01f'
        },
        {
          data: {
            id: 'a6e41059-e1fc-4d2e-bb18-a66a5ef8966b',
            type: 'common'
          },
          source: '137666e7-6278-4d22-b3c6-04ffe17891ad.response',
          target: 'f0de4a8e-f1c5-4462-b9dc-cc91b1715c2a'
        },
        {
          data: {
            id: 'ddc2cdad-ab29-44fa-94bd-dd9dd8782b0b',
            type: 'common'
          },
          source: '48924d04-35af-470a-b099-f6b646a7b01f.default',
          target: '8fae90d6-bf29-4706-b7e9-132b9fbed362'
        },
        {
          data: {
            id: '7df75a0c-f926-4bb1-980f-f951a9c850b5',
            type: 'common'
          },
          source: 'f0de4a8e-f1c5-4462-b9dc-cc91b1715c2a.invalid',
          target: '18d48302-115d-4c08-a3e7-0698ace59c46'
        },
        {
          data: {
            id: '341301aa-af79-4ea8-8504-2900f81e64d4',
            type: 'common'
          },
          source: '18d48302-115d-4c08-a3e7-0698ace59c46.invalid',
          target: 'f0de4a8e-f1c5-4462-b9dc-cc91b1715c2a'
        },
        {
          data: {
            id: '737722b0-3a5c-47d3-b1e8-f41b62d5c879',
            type: 'common'
          },
          source: 'f0de4a8e-f1c5-4462-b9dc-cc91b1715c2a.valid',
          target: '9578be72-431b-4080-8427-6370351cafeb'
        },
        {
          data: {
            id: 'ecf17b6f-f068-4c11-87b1-0092a3219b52',
            type: 'common'
          },
          source: '18d48302-115d-4c08-a3e7-0698ace59c46.valid',
          target: '0abc6214-68fc-4182-bfe0-2d8939883217'
        },
        {
          data: {
            id: '7d05f80c-91ba-4f02-9602-7c45c10e2e27',
            type: 'common'
          },
          source: '9578be72-431b-4080-8427-6370351cafeb.default',
          target: '3f47e296-c211-4217-b2e8-01b8f2d2c5d6'
        },
        {
          data: {
            id: 'c8d1aab3-cf1c-48aa-89c3-b09dc2ea2856',
            type: 'common'
          },
          source: '0abc6214-68fc-4182-bfe0-2d8939883217.default',
          target: '3f47e296-c211-4217-b2e8-01b8f2d2c5d6'
        },
        {
          data: {
            id: 'c3c2c194-5be7-4fcc-9862-8274a8f5d26c',
            type: 'common'
          },
          source: '3f47e296-c211-4217-b2e8-01b8f2d2c5d6.default',
          target: '05d70e9c-8589-459d-a284-f45096cd0b06'
        },
        {
          data: {
            id: 'ac7b6f2a-3d1e-4bdc-9cef-9038ad1914dd',
            type: 'common'
          },
          source: '8fae90d6-bf29-4706-b7e9-132b9fbed362.valid',
          target: 'd750fed8-603b-458a-8205-b1f31d1f4ef6'
        },
        {
          data: {
            id: '3dd6c434-de6f-4706-82f3-be933d8faa0b',
            type: 'common'
          },
          source: '8fae90d6-bf29-4706-b7e9-132b9fbed362.invalid',
          target: '64fe2345-3eb0-434b-9d35-5e802a4c25de'
        },
        {
          data: {
            id: 'cf0a97be-140e-4bec-ba66-460f5ca44acd',
            type: 'common'
          },
          source: '64fe2345-3eb0-434b-9d35-5e802a4c25de.default',
          target: 'ccc10d74-29a7-4e46-99eb-57beab2ba1ca'
        },
        {
          data: {
            id: '39a3e320-2b91-46e4-b645-6e5fce6f055c',
            type: 'common'
          },
          source: 'd750fed8-603b-458a-8205-b1f31d1f4ef6.default',
          target: 'f2521722-74ce-4649-8148-666add9be8cd'
        },
        {
          data: {
            id: 'a0f4c54b-742f-4170-b51c-1861f04dd2e9',
            type: 'common'
          },
          source: 'f2521722-74ce-4649-8148-666add9be8cd.complete',
          target: '020dd5d8-b7ae-48b7-98d4-6403f45deb4e'
        },
        {
          data: {
            id: '4082e93d-6868-4f96-a022-01fbbc7a7033',
            type: 'common'
          },
          source: '020dd5d8-b7ae-48b7-98d4-6403f45deb4e.default',
          target: '193d2987-e621-4fc1-949a-b00c13a99544'
        },
        {
          data: {
            id: 'da55764b-6835-4ffc-b0ba-8defbb90d64d',
            type: 'common'
          },
          source: '193d2987-e621-4fc1-949a-b00c13a99544.response',
          target: 'a55bb0ca-2b79-4acd-a01c-f057d328e7f0'
        },
        {
          data: {
            id: 'ad670424-afa1-4d9c-9ce8-9bf69d3ba6a5',
            type: 'common'
          },
          source: '1d124d6e-1d35-4d1f-915f-93990e1aff81.away',
          target: '05e870d5-b912-4e7c-83f3-57687b084529'
        },
        {
          data: {
            id: 'e43a02b5-f570-4229-bc0a-31c2872d1d28',
            type: 'common'
          },
          source: '1d124d6e-1d35-4d1f-915f-93990e1aff81.no_response',
          target: '05e870d5-b912-4e7c-83f3-57687b084529'
        },
        {
          data: {
            id: '5c9832c9-07fd-4d4d-bc52-659a6681f064',
            type: 'common'
          },
          source: '8c8d3596-3828-4ce8-89c1-7eba063b07d1.default',
          target: '318038fb-6e9e-4105-8869-027ac96e471b'
        },
        {
          data: {
            id: '33734b3e-4bf3-4de1-90f3-18bbcf6fb9b2',
            type: 'common'
          },
          source: '05e870d5-b912-4e7c-83f3-57687b084529.default',
          target: '318038fb-6e9e-4105-8869-027ac96e471b'
        },
        {
          data: {
            id: 'a9e0fc1c-1942-4907-899b-d29fa1a72c49',
            type: 'common'
          },
          source: '318038fb-6e9e-4105-8869-027ac96e471b.response',
          target: '1ec82b86-a39e-4444-b9a2-a2df4f11bb3c'
        },
        {
          data: {
            id: '0a269348-41fc-48d9-827c-53f001df14a8',
            type: 'common'
          },
          source: '1ec82b86-a39e-4444-b9a2-a2df4f11bb3c.3c3c6e20-2513-42e7-ad4f-184a5eed7b59',
          target: '64a1cce0-9cd3-49f2-9c4d-d7fccb595de8'
        },
        {
          data: {
            id: 'b9d9f27c-9850-4c2e-b526-30288562e644',
            type: 'common'
          },
          source: '1ec82b86-a39e-4444-b9a2-a2df4f11bb3c.680c137d-a313-4b96-afcf-91539f1ef18a',
          target: '64a1cce0-9cd3-49f2-9c4d-d7fccb595de8'
        },
        {
          data: {
            id: 'c8e15eca-b735-418f-8cb4-8e43a699bf1f',
            type: 'common'
          },
          source: '64a1cce0-9cd3-49f2-9c4d-d7fccb595de8.default',
          target: '72544117-744c-4505-96eb-8db939081fa0'
        },
        {
          data: {
            id: '4ea8457c-7cc3-454b-a533-1ca50ea7c669',
            type: 'common'
          },
          source: '72544117-744c-4505-96eb-8db939081fa0.invalid',
          target: '6745bea0-1264-44dd-a0b6-734dd5a810fb'
        },
        {
          data: {
            id: 'a87a7251-24f2-4397-838d-28646787ca40',
            type: 'common'
          },
          source: '6745bea0-1264-44dd-a0b6-734dd5a810fb.default',
          target: 'dd11b6bf-65e2-4701-bb76-e3c03b914e5c'
        },
        {
          data: {
            id: 'ee70f820-6a0a-44f5-9e2f-2f1aca9fd30a',
            type: 'common'
          },
          source: '72544117-744c-4505-96eb-8db939081fa0.valid',
          target: 'c2473184-ba7a-4047-b41d-2b19c5feef3c'
        },
        {
          data: {
            id: 'c06cb7a3-645b-424c-9b5d-bf61849336ef',
            type: 'common'
          },
          source: 'c2473184-ba7a-4047-b41d-2b19c5feef3c.default',
          target: 'ab6103d2-71e2-46bb-9e3d-f386e270244c'
        },
        {
          data: {
            id: '68934be1-1f85-43f2-80f8-0953036de020',
            type: 'common'
          },
          source: 'ab6103d2-71e2-46bb-9e3d-f386e270244c.complete',
          target: '2d15d646-94f7-485d-8d90-c87ca59bb7f2'
        },
        {
          data: {
            id: '6d11504c-918b-49b0-b9c2-f1a01f0f5d9f',
            type: 'common'
          },
          source: '2d15d646-94f7-485d-8d90-c87ca59bb7f2.default',
          target: '623ef6e8-3dde-4b4d-b291-f85c1c8f3c9f'
        },
        {
          data: {
            id: '17ef4535-a5d2-485c-af76-b41ea6a9b091',
            type: 'common'
          },
          source: '623ef6e8-3dde-4b4d-b291-f85c1c8f3c9f.response',
          target: '126f915b-33d8-4e73-9f54-5a58f1da5253'
        }
      ],
      nodes: [
        {
          id: 'start',
          top: 0,
          left: 0,
          type: 'start',
          ports: [
            {
              id: 'default'
            }
          ]
        },
        {
          id: 'f64beef5-357c-4941-b00f-f66c559b7587',
          top: 220,
          body: '<p><span style=\'color: rgb(0, 0, 0);\'>Hey there! Want to skip the form and schedule a demo right now?</span></p>',
          left: 0,
          type: 'options',
          ports: [
            {
              id: '18b47cfd-277e-4412-8267-406e50ae4754',
              label: 'Let\'s do it!',
              _reactKey: '9e7dddb8-40b8-42b2-9961-5ba754b4863f'
            },
            {
              id: 'b200338e-901b-4e62-8ee2-e4ebc4a7db6b',
              label: 'No thanks',
              _reactKey: '0d12f29f-3509-493a-943e-b4eff1d3d42d'
            }
          ],
          buttons: [
            {
              id: '18b47cfd-277e-4412-8267-406e50ae4754',
              label: 'Let\'s do it!',
              _reactKey: '9e7dddb8-40b8-42b2-9961-5ba754b4863f'
            },
            {
              id: 'b200338e-901b-4e62-8ee2-e4ebc4a7db6b',
              label: 'No thanks',
              _reactKey: '0d12f29f-3509-493a-943e-b4eff1d3d42d'
            }
          ],
          captureTimes: 3,
          mapAttribute: false,
          responseType: 'buttons',
          keywordOptions: [],
          secondResponse: 'Still didn\'t get that. Go ahead and try again',
          invalidResponse: 'Sorry, I didn\'t understand that. Please try again'
        },
        {
          id: 'f7a54153-28b4-4bd7-acef-2f5234c8bc9f',
          top: 630,
          body: '',
          left: 330,
          type: 'conditional',
          ports: [
            {
              id: 'default',
              label: 'Fallback Path',
              _reactKey: 'c768d6a9-d6f6-425d-adcc-69822e2896c8'
            },
            {
              id: '2a93f209-696f-4ae2-9aac-a4c6a44390f6',
              label: 'Agents Online',
              filters: [
                {
                  cmp: 'available',
                  prop: 'team',
                  value: 3
                }
              ],
              _reactKey: 'c0ce21e9-bd96-4216-a936-308b983a08d1'
            }
          ],
          port_order: [
            'default',
            '2a93f209-696f-4ae2-9aac-a4c6a44390f6'
          ]
        },
        {
          id: '1eae8acf-c8b8-4d89-9669-36ed29d166f1',
          top: 1070,
          body: '<p>Great! Just <em>[number of qualifying questions]</em> questions so I can get you a demo.</p>',
          left: -490,
          type: 'chat',
          ports: [
            {
              id: 'default',
              _reactKey: '48987b38-bffa-41a9-876f-7d55dfc87e8c'
            }
          ]
        },
        {
          id: '8bd4a686-ef22-4a31-824a-a5b6c951b963',
          top: 1000,
          body: '<p>No problem. What would you like to do?</p>',
          left: 80,
          type: 'options',
          ports: [
            {
              id: 'd49fd593-b89b-4fdb-b242-47318be206af',
              label: 'Book a time for later',
              _reactKey: '4f1924a7-0275-4081-b739-24da2ce4dc4d'
            },
            {
              id: '5d3ef417-0ba8-40ec-9b55-147572b850a0',
              label: 'Leave a message',
              _reactKey: 'e7474264-7c2c-4917-886b-33b72031c030'
            }
          ],
          buttons: [
            {
              id: 'd49fd593-b89b-4fdb-b242-47318be206af',
              label: 'Book a time for later',
              _reactKey: '4f1924a7-0275-4081-b739-24da2ce4dc4d'
            },
            {
              id: '5d3ef417-0ba8-40ec-9b55-147572b850a0',
              label: 'Leave a message',
              _reactKey: 'e7474264-7c2c-4917-886b-33b72031c030'
            }
          ],
          captureTimes: 3,
          mapAttribute: false,
          responseType: 'buttons',
          keywordOptions: [],
          secondResponse: 'Still didn\'t get that. Go ahead and try again',
          invalidResponse: 'Sorry, I didn\'t understand that. Please try again'
        },
        {
          id: 'd080bfa9-618c-4a59-bdaa-07ef63d11907',
          top: 1000,
          body: '<p>No problem. What would you like to do?</p>',
          left: 660,
          type: 'options',
          ports: [
            {
              id: '2225894a-ed61-4821-b641-a3d030e3b869',
              label: 'Book a time for later',
              _reactKey: '11907343-6a8a-444d-aeb5-7bd9edd9f462'
            },
            {
              id: '56512367-ab94-4037-9e03-4cdfc3490609',
              label: 'Chat with an agent',
              _reactKey: '76c99f00-e9a7-4d97-a8bb-940353f2d9be'
            },
            {
              id: '951a6301-509b-4c73-9157-f8fbf8968df4',
              label: 'Leave a message',
              _reactKey: '512ebc95-9bf5-4ee5-b695-f78bdd645100'
            }
          ],
          buttons: [
            {
              id: '2225894a-ed61-4821-b641-a3d030e3b869',
              label: 'Book a time for later',
              _reactKey: '11907343-6a8a-444d-aeb5-7bd9edd9f462'
            },
            {
              id: '56512367-ab94-4037-9e03-4cdfc3490609',
              label: 'Chat with an agent',
              _reactKey: '76c99f00-e9a7-4d97-a8bb-940353f2d9be'
            },
            {
              id: '951a6301-509b-4c73-9157-f8fbf8968df4',
              label: 'Leave a message',
              _reactKey: '512ebc95-9bf5-4ee5-b695-f78bdd645100'
            }
          ],
          captureTimes: 3,
          mapAttribute: false,
          responseType: 'buttons',
          keywordOptions: [],
          secondResponse: 'Still didn\'t get that. Go ahead and try again',
          invalidResponse: 'Sorry, I didn\'t understand that. Please try again'
        },
        {
          id: '0eccaac1-4379-4567-b1c5-9a5f1eb504b7',
          top: 1430,
          body: '<p><em>[Qualifying Question #1 (name, industry, integration, company, etc)]</em></p>',
          left: -490,
          type: 'options',
          ports: [
            {
              id: 'response',
              label: 'Visitor Response',
              _reactKey: '2f7bead2-4ce4-41f0-b772-1b594ce0be8f'
            }
          ],
          buttons: [],
          attribute: 'full_name',
          captureTimes: 3,
          mapAttribute: true,
          responseType: 'free_response',
          keywordOptions: [],
          secondResponse: 'Still didn\'t get that. Go ahead and try again',
          invalidResponse: 'Sorry, I didn\'t understand that. Please try again'
        },
        {
          id: '8515cf55-f4b2-4da7-9c31-aa1cc6cef7d8',
          top: 1380,
          body: '<p>Sure! Just <em>[number of qualifying questions]</em> questions so I can get that set up for you.</p>',
          left: -110,
          type: 'chat',
          ports: [
            {
              id: 'default',
              _reactKey: 'a79ba417-6ec4-47f8-96fb-6764a06db94c'
            }
          ]
        },
        {
          id: '137666e7-6278-4d22-b3c6-04ffe17891ad',
          top: 1400,
          body: '<p>Sounds good, you can type your message below. 👇</p>',
          left: 380,
          type: 'options',
          ports: [
            {
              id: 'response',
              label: 'Visitor Response',
              _reactKey: 'baeb33b1-e9ca-47cf-9b53-3ff0db70ba52'
            }
          ],
          buttons: [],
          captureTimes: 3,
          mapAttribute: false,
          responseType: 'free_response',
          keywordOptions: [],
          secondResponse: 'Still didn\'t get that. Go ahead and try again',
          invalidResponse: 'Sorry, I didn\'t understand that. Please try again'
        },
        {
          id: '1d124d6e-1d35-4d1f-915f-93990e1aff81',
          top: 1400,
          body: '',
          left: 1000,
          type: 'live_chat',
          ports: [
            {
              id: 'away',
              label: 'No one available',
              _reactKey: '1aeb0a95-bb25-47ca-9d6f-aff0729a1b03'
            },
            {
              id: 'no_response',
              label: 'No one responds',
              _reactKey: 'a441ed21-08cb-43a6-84ae-ef856a9f0d96'
            }
          ],
          routeMessage: '<p>Bringing in an agent now. Type any questions you have below!</p>',
          routeOptions: [
            {
              type: 'round_robin',
              method: 'single_agent',
              objectIDs: null
            }
          ],
          fallbackDelay: 30
        },
        {
          id: '8c8d3596-3828-4ce8-89c1-7eba063b07d1',
          top: 1100,
          body: '<p>Sure! Just <em>[number of qualifying questions]</em> questions so I can get that set up for you.</p>',
          left: 1610,
          type: 'chat',
          ports: [
            {
              id: 'default',
              _reactKey: '6b491ddd-a174-4e5c-86bb-60621909bfb3'
            }
          ]
        },
        {
          id: '41f48eda-522b-479b-b72a-4a1abb53795f',
          top: 1680,
          body: '<p><em>Qualifying question #2 </em></p><p><br></p><p>tip: If you are getting too many unqualified leads booking meetings add more questions. Recommendation keep questions around 4-5. If you also want to ask for their phone number just check the box under the calendar invite to, \'Prompt visitor for text reminder\'</p>',
          left: -490,
          type: 'options',
          ports: [
            {
              id: '5cece357-7515-4d2f-bb1d-5fa6500460a1',
              label: 'Button 1',
              _reactKey: '3455d426-e995-49f1-9ab7-7d7abf0d6a6b'
            },
            {
              id: '184fd1e2-0253-4110-a4d9-7440151af95a',
              label: 'Button 2',
              _reactKey: 'ae47b3c6-a1c7-447c-81e5-813ae9b968b3'
            }
          ],
          buttons: [
            {
              id: '5cece357-7515-4d2f-bb1d-5fa6500460a1',
              label: 'Button 1',
              _reactKey: '3455d426-e995-49f1-9ab7-7d7abf0d6a6b'
            },
            {
              id: '184fd1e2-0253-4110-a4d9-7440151af95a',
              label: 'Button 2',
              _reactKey: 'ae47b3c6-a1c7-447c-81e5-813ae9b968b3'
            }
          ],
          captureTimes: 3,
          mapAttribute: false,
          responseType: 'buttons',
          keywordOptions: [],
          secondResponse: 'Still didn\'t get that. Go ahead and try again',
          invalidResponse: 'Sorry, I didn\'t understand that. Please try again'
        },
        {
          id: '48924d04-35af-470a-b099-f6b646a7b01f',
          top: 1970,
          body: '',
          left: -490,
          type: 'alert_agent',
          ports: [
            {
              id: 'default',
              label: 'Next',
              _reactKey: 'cef6f1ce-7b12-4aec-8baa-ee07998a7fa6'
            }
          ],
          notification: {
            title: 'Conversation Alert',
            message: 'Site visitor is looking to book a demo! '
          },
          routeOptions: [
            {
              type: 'round_robin',
              method: 'single_agent',
              objectIDs: null
            }
          ],
          alertUnavailableAgents: false
        },
        {
          id: 'f0de4a8e-f1c5-4462-b9dc-cc91b1715c2a',
          top: 1680,
          body: '<p><span style=\'color: rgb(0, 0, 0);\'>So we can reach back out to you, can we get your email address?</span></p>',
          left: 380,
          type: 'email',
          ports: [
            {
              id: 'invalid',
              label: 'Failed to capture email',
              _reactKey: 'afbcffe3-89f9-4f0d-9acd-a823644b7b9c'
            },
            {
              id: 'valid',
              label: 'Successfully captured email',
              _reactKey: 'd5ebda54-d9fe-40a4-8462-f4b241263a36'
            }
          ],
          unwanted: {
            spam: true,
            blocked: true,
            personal: false
          },
          captureTimes: 3,
          secondResponse: '<p>Please enter a valid business email</p>',
          invalidResponse: '<p>Please enter a valid email</p>',
          unwantedResponse: '<p>Please enter a business email</p>'
        },
        {
          id: '8fae90d6-bf29-4706-b7e9-132b9fbed362',
          top: 2170,
          body: '<p>What\'s the best email address to send your calendar invite?</p>',
          left: -490,
          type: 'email',
          ports: [
            {
              id: 'invalid',
              label: 'Failed to capture email',
              _reactKey: '840ead0b-8919-43ac-bc37-4719cea4b7d2'
            },
            {
              id: 'valid',
              label: 'Successfully captured email',
              _reactKey: '4b7ed7ed-4030-421f-a614-02c1155a4bd5'
            }
          ],
          unwanted: {
            spam: true,
            blocked: false,
            personal: true
          },
          captureTimes: 3,
          secondResponse: '<p>Please enter a valid business email</p>',
          invalidResponse: '<p>Sorry, that didn\'t work. Try again?</p>',
          unwantedResponse: '<p>I need a business email please.</p>'
        },
        {
          id: '18d48302-115d-4c08-a3e7-0698ace59c46',
          top: 1770,
          body: '<p>Sorry, that\'s still not working, let\'s try a phone number instead.</p>',
          left: 40,
          type: 'phone',
          ports: [
            {
              id: 'invalid',
              label: 'Failed to capture phone',
              _reactKey: '97f8bd92-b74a-4c88-8a4a-db3f423dd116'
            },
            {
              id: 'valid',
              label: 'Successfully captured phone',
              _reactKey: '7375e390-34d6-429c-8764-910f71a1962b'
            }
          ],
          country: 'US',
          captureTimes: 3,
          secondResponse: '<p>Still didn\'t get that. Please enter a valid number</p>',
          invalidResponse: '<p>Sorry, I didn\'t get that. Please enter a valid phone number</p>'
        },
        {
          id: '9578be72-431b-4080-8427-6370351cafeb',
          top: 1950,
          body: '',
          left: 380,
          tags: [],
          type: 'tag',
          ports: [
            {
              id: 'default',
              _reactKey: '260ba908-7386-40d9-898c-86f4497c2309'
            }
          ]
        },
        {
          id: '0abc6214-68fc-4182-bfe0-2d8939883217',
          top: 2040,
          body: '',
          left: 40,
          tags: [],
          type: 'tag',
          ports: [
            {
              id: 'default',
              _reactKey: 'a14f1d4f-9d9c-4316-a9ee-9a0c820a2c5b'
            }
          ]
        },
        {
          id: '3f47e296-c211-4217-b2e8-01b8f2d2c5d6',
          top: 2170,
          body: '<p>Thanks! We\'ll get back to you <em>[insert estimated time].</em></p>',
          left: 380,
          type: 'chat',
          ports: [
            {
              id: 'default',
              _reactKey: 'e1406b1e-07ff-494c-890b-c0b7f54a4280'
            }
          ]
        },
        {
          id: '05d70e9c-8589-459d-a284-f45096cd0b06',
          top: 2400,
          body: '',
          left: 380,
          type: 'end_flow',
          ports: []
        },
        {
          id: 'd750fed8-603b-458a-8205-b1f31d1f4ef6',
          top: 2400,
          body: '',
          left: -720,
          tags: [],
          type: 'tag',
          ports: [
            {
              id: 'default',
              _reactKey: 'dc26926b-889d-4959-bc08-d54cc9925271'
            }
          ]
        },
        {
          id: '64fe2345-3eb0-434b-9d35-5e802a4c25de',
          top: 2480,
          body: '<p><span style=\'color: rgb(0, 0, 0);\'>Looks like you\'re not ready to book a demo. In the meantime, feel free to checkout ou</span><span style=\'color: rgb(76, 76, 76);\'>r</span><em style=\'color: rgb(76, 76, 76);\'> [Blog, Content link]</em><span style=\'color: rgb(76, 76, 76);\'> page for </span><em style=\'color: rgb(76, 76, 76);\'>[description].</em></p>',
          left: -300,
          type: 'chat',
          ports: [
            {
              id: 'default',
              _reactKey: '71a0ab66-a75f-4e6c-99d8-e466bc8781d4'
            }
          ]
        },
        {
          id: 'ccc10d74-29a7-4e46-99eb-57beab2ba1ca',
          top: 2700,
          body: '',
          left: -300,
          type: 'end_flow',
          ports: []
        },
        {
          id: 'f2521722-74ce-4649-8148-666add9be8cd',
          top: 2610,
          body: '<p>Got it! Last step, just pick a time below that works best for you 👇</p>',
          left: -720,
          type: 'calendar',
          ports: [
            {
              id: 'complete',
              label: 'Meeting Scheduled',
              _reactKey: 'bef4f1f8-87c7-4cf1-8f5e-4f6e0b13aa1a'
            }
          ],
          method: 'single_agent',
          team_id: '',
          email_id: '',
          user_ids: [],
          route_type: '',
          meeting_type: '',
          routeOptions: [
            {
              type: '',
              method: 'single_agent',
              objectIDs: []
            }
          ],
          follow_up_email: false,
          text_message_reminders: false
        },
        {
          id: '020dd5d8-b7ae-48b7-98d4-6403f45deb4e',
          top: 2820,
          body: '',
          left: -720,
          tags: [],
          type: 'tag',
          ports: [
            {
              id: 'default',
              _reactKey: '3f4794ed-b25a-4ffa-971e-a71c4aaad2c3'
            }
          ]
        },
        {
          id: '193d2987-e621-4fc1-949a-b00c13a99544',
          top: 3040,
          body: '<p><span style=\'color: rgb(0, 0, 0);\'>We are looking forward to it! Please type any additional information you would like our specialist to know about your project.</span></p>',
          left: -720,
          type: 'options',
          ports: [
            {
              id: 'response',
              label: 'Visitor Response',
              _reactKey: 'c3ebc113-1372-4eba-903d-d8010f75743c'
            }
          ],
          buttons: [],
          captureTimes: 3,
          mapAttribute: false,
          responseType: 'free_response',
          keywordOptions: [],
          secondResponse: 'Still didn\'t get that. Go ahead and try again',
          invalidResponse: 'Sorry, I didn\'t understand that. Please try again'
        },
        {
          id: 'a55bb0ca-2b79-4acd-a01c-f057d328e7f0',
          top: 3270,
          body: '',
          left: -720,
          type: 'end_flow',
          ports: []
        },
        {
          id: '05e870d5-b912-4e7c-83f3-57687b084529',
          top: 1730,
          body: '<p><span style=\'color: rgba(0, 0, 0, 0.87);\'>Our experts are currently unavailable, but you can schedule a time 👍</span></p>',
          left: 1000,
          type: 'chat',
          ports: [
            {
              id: 'default',
              _reactKey: '4a1a4f64-805e-410d-94ef-dae37ddaabd1'
            }
          ]
        },
        {
          id: '318038fb-6e9e-4105-8869-027ac96e471b',
          top: 1820,
          body: '<p><em>[Qualifying Question #1 (name, industry, integration, company, etc)]</em></p>',
          left: 1610,
          type: 'options',
          ports: [
            {
              id: 'response',
              label: 'Visitor Response',
              _reactKey: '869f8276-1d97-4c9c-bfb7-af4ab9d2b98b'
            }
          ],
          buttons: [],
          attribute: 'full_name',
          captureTimes: 3,
          mapAttribute: true,
          responseType: 'free_response',
          keywordOptions: [],
          secondResponse: 'Still didn\'t get that. Go ahead and try again',
          invalidResponse: 'Sorry, I didn\'t understand that. Please try again'
        },
        {
          id: '1ec82b86-a39e-4444-b9a2-a2df4f11bb3c',
          top: 2100,
          body: '<p><em>Qualifying question #2 </em></p><p><br></p><p>tip: If you are getting too many unqualified leads booking meetings add more questions. Recommendation keep questions around 4-5. If you also want to ask for their phone number just check the box under the calendar invite to, \'Prompt visitor for text reminder\'</p>',
          left: 1610,
          type: 'options',
          ports: [
            {
              id: '3c3c6e20-2513-42e7-ad4f-184a5eed7b59',
              label: 'Button 1',
              _reactKey: '49e1007d-e2b3-4912-842c-134d0c2f25d9'
            },
            {
              id: '680c137d-a313-4b96-afcf-91539f1ef18a',
              label: 'Button 2',
              _reactKey: '9563f35e-808f-4447-94b6-54537e2b6cab'
            }
          ],
          buttons: [
            {
              id: '3c3c6e20-2513-42e7-ad4f-184a5eed7b59',
              label: 'Button 1',
              _reactKey: '49e1007d-e2b3-4912-842c-134d0c2f25d9'
            },
            {
              id: '680c137d-a313-4b96-afcf-91539f1ef18a',
              label: 'Button 2',
              _reactKey: '9563f35e-808f-4447-94b6-54537e2b6cab'
            }
          ],
          captureTimes: 3,
          mapAttribute: false,
          responseType: 'buttons',
          keywordOptions: [],
          secondResponse: 'Still didn\'t get that. Go ahead and try again',
          invalidResponse: 'Sorry, I didn\'t understand that. Please try again'
        },
        {
          id: '64a1cce0-9cd3-49f2-9c4d-d7fccb595de8',
          top: 2450,
          body: '',
          left: 1610,
          type: 'alert_agent',
          ports: [
            {
              id: 'default',
              label: 'Next',
              _reactKey: '7e98eb2b-3acd-415e-8dd2-0233e2ab6e9c'
            }
          ],
          notification: {
            title: 'Conversation Alert',
            message: ''
          },
          routeOptions: [
            {
              type: 'round_robin',
              method: 'single_agent',
              objectIDs: null
            }
          ],
          alertUnavailableAgents: false
        },
        {
          id: '72544117-744c-4505-96eb-8db939081fa0',
          top: 2680,
          body: '<p>What\'s the best email address to send your calendar invite?</p>',
          left: 1610,
          type: 'email',
          ports: [
            {
              id: 'invalid',
              label: 'Failed to capture email',
              _reactKey: '79e64d25-8bd0-46c3-afde-0ade1423dd97'
            },
            {
              id: 'valid',
              label: 'Successfully captured email',
              _reactKey: 'ceef53ae-65b5-408a-be77-69db380ab24d'
            }
          ],
          unwanted: {
            spam: true,
            blocked: false,
            personal: true
          },
          captureTimes: 3,
          secondResponse: '<p>Please enter a valid business email</p>',
          invalidResponse: '<p>Sorry, that didn\'t work. Try again?</p>',
          unwantedResponse: '<p>I need a business email please.</p>'
        },
        {
          id: '6745bea0-1264-44dd-a0b6-734dd5a810fb',
          top: 2950,
          body: '<p><span style=\'color: rgb(0, 0, 0);\'>Looks like you\'re not ready to book a demo. In the meantime, feel free to checkout ou</span><span style=\'color: rgb(76, 76, 76);\'>r</span><em style=\'color: rgb(76, 76, 76);\'> [Blog, Content link]</em><span style=\'color: rgb(76, 76, 76);\'> page for </span><em style=\'color: rgb(76, 76, 76);\'>[description].</em></p>',
          left: 1860,
          type: 'chat',
          ports: [
            {
              id: 'default',
              _reactKey: 'de65e759-283b-4736-97dd-6ca33d391f0b'
            }
          ]
        },
        {
          id: 'dd11b6bf-65e2-4701-bb76-e3c03b914e5c',
          top: 3190,
          body: '',
          left: 1860,
          type: 'end_flow',
          ports: []
        },
        {
          id: 'c2473184-ba7a-4047-b41d-2b19c5feef3c',
          top: 2870,
          body: '',
          left: 1260,
          tags: [],
          type: 'tag',
          ports: [
            {
              id: 'default',
              _reactKey: 'e75e2856-2e52-467d-85a1-99a9800beb14'
            }
          ]
        },
        {
          id: 'ab6103d2-71e2-46bb-9e3d-f386e270244c',
          top: 3090,
          body: '<p>Got it! Last step, just pick a time below that works best for you 👇</p>',
          left: 1260,
          type: 'calendar',
          ports: [
            {
              id: 'complete',
              label: 'Meeting Scheduled',
              _reactKey: '53ccf0e0-831f-426d-8748-91e99a982dc9'
            }
          ],
          method: 'single_agent',
          team_id: '',
          email_id: '',
          user_ids: [],
          route_type: '',
          meeting_type: '',
          routeOptions: [
            {
              type: '',
              method: 'single_agent',
              objectIDs: []
            }
          ],
          follow_up_email: false,
          text_message_reminders: false
        },
        {
          id: '2d15d646-94f7-485d-8d90-c87ca59bb7f2',
          top: 3300,
          body: '',
          left: 1260,
          tags: [],
          type: 'tag',
          ports: [
            {
              id: 'default',
              _reactKey: '61f684be-713f-47f5-86f4-6268625a59ac'
            }
          ]
        },
        {
          id: '623ef6e8-3dde-4b4d-b291-f85c1c8f3c9f',
          top: 3520,
          body: '<p><span style=\'color: rgb(0, 0, 0);\'>We are looking forward to it! Please type any additional information you would like our specialist to know about your project.</span></p>',
          left: 1260,
          type: 'options',
          ports: [
            {
              id: 'response',
              label: 'Visitor Response',
              _reactKey: 'a3807462-de66-4ca3-a461-221300c604b0'
            }
          ],
          buttons: [],
          captureTimes: 3,
          mapAttribute: false,
          responseType: 'free_response',
          keywordOptions: [],
          secondResponse: 'Still didn\'t get that. Go ahead and try again',
          invalidResponse: 'Sorry, I didn\'t understand that. Please try again'
        },
        {
          id: '126f915b-33d8-4e73-9f54-5a58f1da5253',
          top: 3750,
          body: '',
          left: 1260,
          type: 'end_flow',
          ports: []
        }
      ],
      ports: []
    }
  },
  targeting: { triggers: [], triggerOn: 'all_visitors', useTriggers: false },
  frequency: 'every time',
  template: {
    kind: 'flow',
    teams: [
      {
        away: 'Sorry, no one is available to chat right now',
        label: 'Click here',
        method: 'single_agent',
        team_id: null,
        welcome: 'Routing you to an agent now',
        user_ids: [],
        route_method: 'round_robin'
      }
    ],
    bot_delay: 0,
    url_matches: '',
    welcome_message: 'Welcome to our site!',
    availability_criteria: 'both'
  },
  prompt_style: 'big'
}

export default secondNetFlow
