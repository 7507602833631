import User from 'classes/users';
import { TextBox } from 'library/materialUI';
import { useContext, useState } from 'react';
import { ContentsProps } from '../wizard/OnboardingWizard';
import { UserContext } from './UserContext';

export const phoneNumberSubtitle = 'Enter a phone number that can receive text messages. ' +
'We use your phone number for multi-factor authentication and text alerts if enabled.'

export function PhoneNumber (props: ContentsProps) {
  const { user } = useContext(UserContext)
  const [number, setNumber] = useState(user.phone)

  props.handleNextRef.current = () => {
    if (number !== user.phone) {
      const updates = { phone: number }
      User.update(user.id, updates)
    }
  }

  return (
    <div>
      <TextBox
        value={number}
        onChange={(value: string) => setNumber(value)}
        label='Phone Number'
      />
    </div>
  )
}
