import { useEffect, useState } from 'react'
import { Select } from 'cf-components/material-wrappers/MaterialComponents'
import Picker from 'cf-components/Pickers'

interface IntegrationSelectorProps {
  settings: any
  updateIntegration: (value: any) => void
  availableIntegrations: any[]
}

export default function IntegrationSelector ({ settings, updateIntegration, availableIntegrations }: IntegrationSelectorProps) {
  const [integration, setIntegration] = useState(settings.name)
  const [listIDs, setListIDs] = useState(settings.listIDs)
  const [refresh, setRefresh] = useState(false)

  const handleSetIntegration = (e: string) => {
    setIntegration(e)
    updateIntegration({ name: e, listIDs: [] })
    setListIDs([])
    setRefresh(true)
  }

  const handleSetListID = (e: string[]) => {
    setListIDs(e)
    updateIntegration({ name: integration, listIDs: e })
  }

  useEffect(() => {
    if (refresh) {
      setIntegration(settings.name)
      setListIDs(settings.listIDs)
      setRefresh(false)
    }
  }, [settings, refresh])

  return (
    <div>
      <div>
        Which integration should contacts be added to?
      </div>
      <div style={{ marginTop: '5px' }}>
        <Select
          value={integration}
          onChange={(e: any) => handleSetIntegration(e)}
          options={availableIntegrations}
          placeholder='Select Integration'
        />
      </div>
      {integration && !refresh && (
        <div>
          <div style={{ marginTop: '10px', marginBottom: '5px' }}>
            Which campaign/list should the contact(s) be added to?
          </div>
          <Picker
            selection={listIDs}
            setSelection={(e: any) => handleSetListID(e)}
            objectType='contactList'
            label='Select a campaign/list'
            loadAllProps={{
              searchParams: {
                extraHeaders: { 'x-integration': integration }
              }
            }}
            multiple
            queryBackend
          />
        </div>
      )}
    </div>
  )
}
