import { doGet, doPut } from './api'

function getContactLists ({ integration }) {
  const extraHeaders = {}
  if (integration) {
    extraHeaders['x-integration'] = integration.toLowerCase()
  }
  return doGet({ path: '/integrations/crm/lists?type=contacts', extraHeaders })
}

function getCRMContactIntegrationMapping (fields) {
  return doGet({ path: '/integrations/crm/mapping/contacts' })
}

function setCRMContactIntegrationMapping (integration, fields) {
  const extraHeaders = {}
  if (integration) {
    extraHeaders['x-integration'] = integration
  }
  return doPut({
    path: '/integrations/crm/mapping/contacts',
    data: {
      type: 'contacts_mapping',
      attributes: {
        mapping: fields
      }
    },
    extraHeaders
  })
}

function getCRMContactIntegrationFields () {
  return doGet({ path: '/integrations/crm/contacts/fields' })
}

export {
  getContactLists,
  getCRMContactIntegrationFields,
  getCRMContactIntegrationMapping,
  setCRMContactIntegrationMapping
}
