import { makeStyles } from '@material-ui/core/styles'
import { Button } from 'library/materialUI'

const useStyles = makeStyles(theme => ({
    button: {
      marginTop: 2,
      color: 'black',
      // border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: '100px',
      height: '30px'
    },
    text: {
      font: 'normal 10px/20px Poppins',
      color: 'black'
    }
}))

export interface EditLinkButtonProps {
  text: string | JSX.Element
  link: string
}

export function EditLinkButton (props: EditLinkButtonProps): JSX.Element {
  const classes = useStyles()
  return (
    <Button className={classes.button} onClick={() => window.open(props.link)} variant='outlined'>
      <p className={classes.text}>{props.text}</p>
    </Button>
  )
}
