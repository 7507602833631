import Avatar from '@material-ui/core/Avatar'
import { makeStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { getActivityHistory } from 'api/participants'
import { Accordion, AccordionDetails, AccordionSummary } from 'cf-components/Accordion'
import { usePostageSocket } from 'components/PostageSocket'
import dateFormat from 'dateformat'
import { activities, getSummary } from 'pages/accounts/AccountDashboard/ActivityHelpers'
import { SourceInfo } from 'pages/people/ContactPage/ContactSummary'
import { useContext } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import BaseCard from './BaseCard'
import { ConversationContext } from '../../ConversationContext'

const useStyles = makeStyles(theme => ({
  activityHeader: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '32px 55px auto',
    gridGap: 10
  },
  smallAvatar: {
    height: 24,
    width: 24
  },
  avatar: {
    height: 32,
    width: 32
  },
  avatarDiv: {
    marginRight: 15
  },
  activitySummary: {
    marginLeft: 25,
    display: 'flex',
    alignItems: 'center'
  },
  activityDetail: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '13px',
    padding: 7,
    borderBottom: '1px solid rgba(0,0,0,0.2)',
    cursor: 'pointer'
  },
  detailsView: {
    width: '100%'
  },
  allHistory: {
    maxHeight: '300px',
    overflowY: 'auto',
    overflowX: 'hidden'
  }
}))

const ActivityIcon = props => {
  const classes = useStyles()
  const activity = activities[props.event]

  const Icon = activity?.icon
  const color = activity?.color
  const iconSize = props.size === 'sm' ? 18 : 28

  return (
    <div className={classes.avatarDiv}>
      <Avatar
        style={{ backgroundColor: color }}
        className={props.size === 'sm' ? classes.smallAvatar : classes.avatar}
      >
        <Icon style={{ fill: 'white', height: iconSize }} />
      </Avatar>
    </div>
  )
}

const ActivityDetail = props => {
  const classes = useStyles()
  let trafficInfo = null
  if (props.referrer) {
    trafficInfo = <div style={{ display: 'flex', color: '#44444480' }}><div style={{ marginRight: 5 }}>Source:</div> <SourceInfo source={props.referrer} /></div>
  }
  return (
    <div className={classes.activityDetail} onClick={() => window.open(props.url, '_blank')}>
      <ActivityIcon event={props.event} size='sm' />
      <div style={{ display: 'grid' }}>
        <div>
          {props.text}
        </div>
        {trafficInfo}
      </div>
    </div>
  )
}

const DetailedView = props => {
  const classes = useStyles()
  const details = props.details
  return (
    <div className={classes.detailsView}>
      {details.page_views.map((pv, index) => {
        const url = pv.url
        const count = pv.distinct_visitors
        const text = count > 1 ? `${pv.title} - ${count} visitors` : pv.title
        const referrer = pv.referrer ? { referrer: pv.referrer, traffic_source: pv.traffic_source, traffic_campaign: pv.traffic_campaign } : null
        return (
          <ActivityDetail
            event='pageViews'
            text={text}
            url={url}
            referrer={referrer}
            key={index}
          />
        )
      })}
      {details.conversations.map((c, index) => {
        const url = `/#/chat/${c.id}`
        let event = 'conversations'
        let text = `Conversation with bot (${c.name})`
        if (c.live_chat) {
          event = 'liveChats'
          text = `Conversation with agent (${c.agent_name})`
        } else if (c.emails_captured) {
          event = 'emailsCaptured'
          text = `Email captured in conversation with bot (${c.name})`
        }
        return (
          <ActivityDetail
            event={event}
            text={text}
            url={url}
            key={index}
          />
        )
      })}
    </div>
  )
}

function ActivityDropdown (props) {
  const classes = useStyles()
  const activityData = props.activityData || {}
  const date = new Date(activityData?.date)
  const { mostRelevantEvent, relevantEventText } = getSummary(activityData?.summarized_data)

  return (
    <Accordion defaultExpanded={props.defaultExpanded}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon style={{ height: 20 }} />}
        style={{ padding: 5, minHeight: 32 }}
      >
        <div className={classes.activityHeader}>
          <ActivityIcon event={mostRelevantEvent} />
          <div className={classes.cardTitle}>
            {dateFormat(date, 'mmm d')}
          </div>
          <div>
            {relevantEventText}
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails style={{ padding: 8 }}>
        <DetailedView
          details={activityData.details}
        />
      </AccordionDetails>
    </Accordion>
  )
}

function ActivityHistoryContents (props) {
  const classes = useStyles()
  const history = props.history
  if (history) {
    return (
      <div className={classes.allHistory}>
        {history.map((activity, index) => (
          <ActivityDropdown
            key={index}
            activityData={activity}
            defaultExpanded={history.length < 4}
          />
        ))}
      </div>
    )
  }
  return (
    <div>
      Loading
    </div>
  )
}

function ActivityHistory (props) {
  const { conversation } = useContext(ConversationContext)
  const participantID = conversation?.relationships.for_participant.data.id
  const queryClient = useQueryClient()

  const chatServiceUrl = window.chatServiceUrl
  const { data: history } = useQuery(participantID + '/activity-history', () => getActivityHistory({ chatServiceUrl, participantID })
    .then(response => response.data.attributes.activity_history), { staleTime: 60000 }
  )
  usePostageSocket((msg) => {
    if (msg.type === 'conversation_events') {
      if (['capture', 'chat'].includes(msg.attributes.kind) && msg.relationships.conversation.data.id === conversation?.id) {
        queryClient.invalidateQueries(participantID + '/activity-history')
      }
    }
  })

  return (
    <BaseCard
      title='Activity History'
      id={props.id}
    >
      <ActivityHistoryContents
        history={history}
      />
    </BaseCard>
  )
}

export default ActivityHistory
