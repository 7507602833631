import { makeStyles } from '@material-ui/core/styles'
import { TextField, Typography } from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { Button } from 'library/materialUI'
import BackendPicker from 'library/picker/backendPicker'
import { useEffect, useState } from 'react'
import CountryStateSelector from 'cf-components/LocationSelector'
import Picker from 'cf-components/Pickers'

const useStyles = makeStyles(theme => ({
  editContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  editField: {
    width: '100%',
    marginRight: 20
  }
}))

interface DomainDetailEditProps {
  detailName: string
  type: 'text' | 'number' | 'date' | 'list' | 'location' | 'industry'
  onAdd: (value: string | number | string[]) => Promise<boolean>
  initialValue?: string | number | string[]
}

const typeMap = {
  industry: 'string',
  text: 'string',
  number: 'string',
  date: 'string',
  list: 'object',
  location: 'string'
}

export default function DomainDetailEdit ({ detailName, type, onAdd, initialValue }: DomainDetailEditProps): JSX.Element {
  const classes = useStyles()
  const [detailValue, setDetailValue] = useState<string | number | string[]>(initialValue || (type === 'list' ? [] : ''))

  useEffect(() => {
    if (type === 'date') {
      setDetailValue(initialValue ? new Date(initialValue as string).toISOString() : new Date().toISOString())
    } else if (type === 'list') {
      setDetailValue(initialValue || [])
    } else {
      setDetailValue(initialValue || '')
    }
  }, [detailName, initialValue, type])
  const resetField = () => {
    if (type === 'date') {
      setDetailValue(new Date().toISOString())
    } else if (type === 'list') {
      setDetailValue([])
    } else {
      setDetailValue('')
    }
  }

  const textEntryField: JSX.Element = (
    <TextField
      className={classes.editField}
      variant='outlined'
      value={detailValue}
      onChange={(e) => setDetailValue(e.target.value)}
      placeholder={'Enter ' + detailName + '...'}
    />
  )
  const numberEntryField: JSX.Element = (
    <TextField
      className={classes.editField}
      variant='outlined'
      type='number'
      value={detailValue}
      onChange={(e) => setDetailValue(e.target.value)}
      placeholder={'Enter ' + detailName + '...'}
    />
  )

  const dateEntryField: JSX.Element = (
    <KeyboardDatePicker
      value={detailValue}
      format='MM/dd/yyyy'
      onChange={(date: MaterialUiPickersDate) => {
        if (date !== null && isFinite(date as any)) {
          setDetailValue(date.toISOString())
        } else {
          setDetailValue('')
        }
      }}
    />
  )

  const industryField: JSX.Element = (
    <div style={{ width: '100%', marginRight: 20 }}>
      <Picker
        objectType='industries'
        selection={detailValue as string}
        setSelection={(value: any) => {
          setDetailValue(value)
        }}
      />
    </div>
  )

  const backendEntryField: JSX.Element = (
    <div style={{ width: '100%', marginRight: 20 }}>
      <BackendPicker
        title={detailName}
        objectType='technologies'
        selection={detailValue as string[]}
        setSelection={(value: any) => setDetailValue(value)}
        placeholder={'Select ' + detailName + '...'}
        isSelect
        truncateLength={35}
      />
    </div>
  )

  const locationEntryField: JSX.Element = (
    <div>
      <CountryStateSelector
        setCountryValue={(value: string) => {
          setDetailValue(value)
        }}
        setTerritoryValue={(value: string) => {
          if (!value) {
            setDetailValue(detailValue)
          }
          setDetailValue(detailValue + '/' + value)
        }}
      />
    </div>
  )

  let entryField: JSX.Element = <></>

  switch (type) {
    case 'text':
      entryField = textEntryField
      break
    case 'number':
      entryField = numberEntryField
      break
    case 'date':
      entryField = dateEntryField
      break
    case 'list':
      entryField = backendEntryField
      break
    case 'industry':
      entryField = industryField
      break
    case 'location':
      entryField = locationEntryField
      break
    default:
      entryField = textEntryField
      break
  }

  if (typeof detailValue !== typeMap[type] && type !== 'industry') {
    return <></>
  }

  return (
    <div>
      <Typography variant='h2' style={{ marginBottom: 10 }}>Add {detailName}</Typography>
      <div className={classes.editContainer}>
        {entryField}
        <Button
          variant='contained'
          color='primary'
          onClick={() => onAdd(detailValue).then(() => resetField())}
          disabled={(type === 'list' && (detailValue as string[]).length === 0) || (type !== 'list' && !detailValue)}
          style={{ textTransform: 'none' }}
          size='large'
        >
          Submit
        </Button>
      </div>
      <Typography variant='body2'>* Your submission will be reviewed for accuracy before going into effect</Typography>
    </div>
  )
}
