import { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  fadeIn: {
    transition: 'opacity .5s ease'
  },
  fadeOut: {
    opacity: 0,
    transition: 'opacity .5s ease'
  }
}))

interface InteractiveTooltipProps {
  anchorRef: any,
  xOffset: number,
  yOffset: number,
  position: 'left' | 'right'
  children: React.ReactElement<any, any>,
  open: boolean,
  color: string,
  setDisplay: (value: boolean) => void
}

export default function InteractiveTooltip ({ anchorRef, xOffset, yOffset, position, children, color, open, setDisplay }: InteractiveTooltipProps) {
  const classes = useStyles()
  const [fade, setFade] = useState({ state: classes.fadeOut })
  const [hovering, setHovering] = useState(false)
  const yVal = (anchorRef?.current?.offsetTop || 0) + yOffset
  const xVal = (anchorRef?.current?.offsetLeft || 0) + xOffset + (position === 'right' ? anchorRef?.current?.offsetWidth : 0)

  const handleTransitionEnd = () => {
    if (fade.state === classes.fadeOut) {
      setDisplay(false)
    }
  }

  useEffect(() => {
    if (open) {
      setFade({ state: classes.fadeIn })
    } else if (hovering) {
      setFade({ state: classes.fadeIn })
    } else {
      setFade({ state: classes.fadeOut })
    }
    // eslint-disable-next-line
  }, [open, hovering])

  return (
    <div onTransitionEnd={() => handleTransitionEnd()} onMouseEnter={() => setHovering(true)} onMouseLeave={() => setHovering(false)} className={fade.state} style={{ borderRadius: '5px', backgroundColor: color, position: 'absolute', top: yVal, left: xVal, zIndex: 1500, padding: '5px' }}>
      {children}
    </div>
  )
}
