/* eslint-disable @typescript-eslint/naming-convention */
import { useState } from 'react'
import { Modal, ModalSection } from 'library/Modal'
import { User } from 'classes/users'
import UserRoles from './UserRoles'
import { ObjectAvatar } from 'cf-components/table/TableAvatars'
import { AdminNoChatModal } from './AdminNoChatModal'

interface modalProps {
  user: User
  onHide: () => void
}

export function UserPermissionModal (props: modalProps): any {
  const [updates, setUpdates] = useState(props.user)
  const [noChatRole, setNoChatRole] = useState(false)
  const changeUser = (state: User) : void => {
    setUpdates(state)
  }
  const saveUser = (): void => {
    if (updates && updates.roleID === 9) {
      setNoChatRole(true)
    }
    if (updates && updates.roleID < 9) {
    User.update(props.user.id, updates)
      .then(() =>
        props.onHide()
      )
    }
  }

  const avatar = ObjectAvatar({ row: props.user })

  return (
    <Modal
      open
      title='Set User Role'
      onHide={props.onHide}
      size='md'
      saveIcon='save'
      handleSave={saveUser}
      saveDisabled={!updates}
    >
      <ModalSection
        title={avatar as object}
      >
        <UserRoles
          user={props.user}
          changeUser={changeUser}
        />
        {noChatRole &&
          <AdminNoChatModal
            onHide={() => setNoChatRole(false)}
            updates={{ ...updates, userID: props.user.id }}
            acceptChange={props.onHide}
          />}
      </ModalSection>
    </Modal>
  )
}

export default UserPermissionModal
