import { Tooltip } from '@material-ui/core';

export function numberWithCommas (x: number): string {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function toCapitalizedWords (name: string): string {
  if (!name) {
    return ''
  }
  const words = name.match(/[A-Za-z][a-z]*/g) || []

  return words.map(capitalize).join(' ')
}

export function capitalize (word: string): string {
  return word.charAt(0).toUpperCase() + word.substring(1)
}

export function getPosition (string: string, subString: string, index: number) {
  return string.split(subString, index).join(subString).length;
}

export const reducer = (state: any, changes: any) => {
  return { ...state, ...changes }
}

export function truncateString (string: string, length: number, ellipses: boolean, inline = false): string | JSX.Element {
  if (!string) {
    return ''
  }
  if (string.length >= length) {
    let newStr = string.substring(0, length - 3)
    if (ellipses) {
      newStr += '...'
    }
    return <Tooltip title={string} style={{ display: inline ? 'inline-block' : 'block' }}><div>{newStr}</div></Tooltip>
  } else {
    return string
  }
}

export function getBotType (kind: string): string {
  let type = kind + ' Bot'
  if (kind === 'team_live_chat_router') {
    type = 'Live Chat Router Bot'
  } else if (kind === 'content_page') {
    type = 'Conversational Landing Page'
  } else if (kind === 'abe_greeting') {
    type = 'ABE Greeting'
  }
  type = type.charAt(0).toUpperCase() + type.slice(1)
  return type
}

export function getColor (index: number) {
  let color = '#EE6754'

  switch (index % 11) {
    case 0:
      break
    case 1:
      color = '#EC7F5D'
      break
    case 2:
      color = '#FEA660'
      break
    case 3:
      color = '#F5DC72'
      break
    case 4:
      color = '#B5E233'
      break
    case 5:
      color = '#6CD7B5'
      break
    case 6:
      color = '#74C5D1'
      break
    case 7:
      color = '#86C6E0'
      break
    case 8:
      color = '#6C8FD6'
      break
    case 9:
      color = '#F080A1'
      break
    default:
      color = '#AF63FD'
  }
  return color
}
