import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Card, CardContent, Typography, FormControl, Button } from '@material-ui/core'
import {
  KeyboardDatePicker
  , KeyboardTimePicker
} from '@material-ui/pickers'

const useStyles = makeStyles(theme => ({
  selectorCard: {
    width: 550,
    borderRadius: 10,
    margin: 50
  },
  container: {
    display: 'flex',
    justifyContent: 'center'
  },
  cardContentArea: {
    height: '100%',
    backgroundColor: 'rgba(193, 166, 237, 0.1)',
    borderRadius: 10,
    padding: 30,
    paddingTop: 20
  },
  header: {
    backgroundColor: theme.palette.primary.main
  },
  subtitle1: {
    fontSize: 20,
    fontWeight: 600,
    color: 'white',
    padding: 20
  },
  subtitle2: {
    fontSize: 15,
    fontWeight: 600,
    marginBottom: 10
  },
  bodyText: {
    color: '#808080',
    marginBottom: 10,
    fontSize: 15
  },
  timePicker: {
    display: 'block',
    margin: 10
  },
  datePicker: {
    display: 'block',
    margin: 10,
    gridRowStart: 1,
    gridRowEnd: 2
  },
  singleTimePicker: {
    margin: 10,
    gridRowStart: 2,
    gridRowEnd: 3
  },
  selectors: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridGap: 30,
    gridAutoRows: '50px'
  },
  nextButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 50
  },
  nextButtonContainer_v2: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  errorMessage: {
    color: 'red'
  }
}))

const SelectSchedule = props => {
  const classes = useStyles()
  const state = props.state
  const startTimeError = props.startTimeError
  const endTimeError = props.endTimeError
  const setEndTimeError = props.setEndTimeError
  const setStartTimeError = props.setStartTimeError

  const handleEndTime = (type, time) => {
    if (time instanceof Date && !isNaN(time)) {
      if (type === 'date') {
        if (state.attributes.start_time > time) {
          setEndTimeError('End date must be greater than start date')
          props.handleChange({ attr: 'end_time', value: time })
        } else if ((time.getTime() - state.attributes.start_time.getTime()) / (1000 * 3600 * 24) > 91) {
          setEndTimeError('Tests cannot be scheduled to run for more than 90 days')
          props.handleChange({ attr: 'end_time', value: time })
        } else {
          setEndTimeError()
          setStartTimeError()
          props.handleChange({ attr: 'end_time', value: time })
        }
      }
      if (type === 'time' && state.attributes.start_time) {
        const hours = time.getHours()
        const minutes = time.getMinutes()

        const newTime = new Date(state.attributes.end_time.setHours(hours, minutes))
        if (state.attributes.start_time > newTime) {
          setEndTimeError('End date must be greater than start date')
          props.handleChange({ attr: 'end_time', value: newTime })
        } else {
          setEndTimeError()
          setStartTimeError()
          props.handleChange({ attr: 'end_time', value: newTime })
        }
      }
    } else {
      setEndTimeError('Invalid date')
    }
  }

  const handleStartTime = (type, time) => {
    if (time instanceof Date && !isNaN(time)) {
      if (type === 'date') {
        if (state.attributes.duration_criteria === 'time_based' && time > state.attributes.end_time) {
          setStartTimeError('Start date must happen before the end date')
          props.handleChange({ attr: 'start_time', value: time })
        } else {
          setEndTimeError()
          setStartTimeError()
          props.handleChange({ attr: 'start_time', value: time })
        }
      }

      if (type === 'time' && state.attributes.start_time) {
        const hours = time.getHours()
        const minutes = time.getMinutes()

        const newTime = new Date(state.attributes.start_time.setHours(hours, minutes))

        if (state.attributes.duration_criteria === 'time_based' && newTime > state.attributes.end_time) {
          setStartTimeError('Start date must happen before the end date')
          props.handleChange({ attr: 'start_time', value: newTime })
        } else {
          setEndTimeError()
          setStartTimeError()
          props.handleChange({ attr: 'start_time', value: newTime })
        }
      }
    } else {
      setStartTimeError('Invalid date')
    }
  }

  if (!state) {
    return <></>
  }

  if (!state.attributes.duration_criteria) {
    return 'You need to complete the previous step'
  }

  if (state.attributes.duration_criteria === 'statistical_significance') {
    return (
      <>
        <div className={classes.container}>
          <Card className={classes.selectorCard} raised>
            <div className={classes.header}>
              <Typography variant='subtitle1' className={classes.subtitle1}>
                Select a start date
              </Typography>
            </div>
            <CardContent
              className={classes.cardContentArea}
            >
              <Typography variant='body2' className={classes.bodyText}>
                Set a start time for your test. The time is based on the company time zone and can be overridden manually. If statistical significance has not been reached after 90-days the test will end.
              </Typography>
              <div className={classes.datePicker}>
                <KeyboardDatePicker
                  disableToolbar
                  variant='inline'
                  disablePast
                  format='MM/dd/yyyy'
                  margin='dense'
                  error={startTimeError}
                  minDate={new Date(Date.now())}
                  id='start-date-picker-inline'
                  label='Choose start date'
                  value={state.attributes.start_time}
                  onChange={e => handleStartTime('date', e)}
                  autoOk
                  KeyboardButtonProps={{
                    'aria-label': 'change start date'
                  }}
                />
                {startTimeError
                  ? (
                    <div className={classes.errorMessage}>
                      {startTimeError}
                    </div>
                  )
                  : <></>}
              </div>
              <div className={classes.timePicker}>
                <FormControl
                  variant='outlined'
                  margin='dense'
                >
                  <KeyboardTimePicker
                    autoOk
                    label='Time'
                    error={startTimeError}
                    value={state.attributes.start_time}
                    onChange={e => handleStartTime('time', e)}
                  />
                  {startTimeError
                    ? (
                      <div className={classes.errorMessage}>
                        {startTimeError}
                      </div>
                    )
                    : <></>}
                </FormControl>
              </div>
              <div className={classes.nextButtonContainer_v2}>
                <Button
                  onClick={props.handleNext}
                  className={classes.nextButton}
                  variant='outlined'
                  color='primary'
                  disabled={startTimeError || endTimeError}
                >
                  Next
                </Button>
              </div>
            </CardContent>
          </Card>
        </div>
      </>
    )
  }

  if (state.attributes.duration_criteria === 'time_based') {
    return (
      <>
        <div className={classes.container}>
          <Card className={classes.selectorCard} raised>
            <div className={classes.header}>
              <Typography variant='subtitle1' className={classes.subtitle1}>
                Select a start and end date
              </Typography>
            </div>
            <CardContent
              className={classes.cardContentArea}
            >
              <Typography variant='body2' className={classes.bodyText}>
                Set a start and end time for your test. The time is based on the company time zone and can be overridden manually. Tests cannot be scheduled for more than 90 days.
              </Typography>
              <div className={classes.selectors}>
                <div className={classes.datePicker}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant='inline'
                    disablePast
                    format='MM/dd/yyyy'
                    margin='dense'
                    id='start-date-picker-inline'
                    label='Choose start date'
                    minDate={new Date(Date.now())}
                    value={state.attributes.start_time}
                    error={startTimeError}
                    onChange={e => handleStartTime('date', e)}
                    autoOk
                    KeyboardButtonProps={{
                      'aria-label': 'change start date'
                    }}
                  />
                </div>
                <div className={classes.datePicker}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant='inline'
                    format='MM/dd/yyyy'
                    disablePast
                    margin='dense'
                    id='end-date-picker-inline'
                    label='Choose end date'
                    error={endTimeError}
                    maxDate={new Date(state.attributes.start_time).setDate(state.attributes.start_time.getDate() + 90)}
                    value={state.attributes.end_time}
                    onChange={e => handleEndTime('date', e)}
                    autoOk
                    KeyboardButtonProps={{
                      'aria-label': 'change end date'
                    }}
                  />
                </div>
                <div className={classes.singleTimePicker}>
                  <FormControl
                    variant='outlined'
                    margin='dense'
                  >
                    <KeyboardTimePicker
                      autoOk
                      label='Time'
                      error={startTimeError}
                      value={state.attributes.start_time}
                      onChange={e => handleStartTime('time', e)}
                    />
                    {startTimeError
                      ? (
                        <div className={classes.errorMessage}>
                          {startTimeError}
                        </div>
                      )
                      : <></>}
                  </FormControl>
                </div>
                <div className={classes.singleTimePicker}>
                  <FormControl
                    variant='outlined'
                    margin='dense'
                  >
                    <KeyboardTimePicker
                      autoOk
                      label='Time'
                      error={endTimeError}
                      value={state.attributes.end_time}
                      onChange={e => handleEndTime('time', e)}
                    />
                    {endTimeError
                      ? (
                        <div className={classes.errorMessage}>
                          {endTimeError}
                        </div>
                      ) : <></>}
                  </FormControl>
                </div>
              </div>
              <div className={classes.nextButtonContainer}>
                <Button
                  onClick={props.handleNext}
                  className={classes.nextButton}
                  variant='outlined'
                  disabled={startTimeError || endTimeError}
                  color='primary'
                >
                  Next
                </Button>
              </div>
            </CardContent>
          </Card>
        </div>
      </>
    )
  }
}

export default SelectSchedule
