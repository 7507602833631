import { track } from 'userpilot.js'
import { doGet, doPost, doPut } from './api'

function getTicketFields (): Promise<any> {
  const path = '/api/integrations/ticketing/fields'
  return doGet({ path: path })
}

function createTicket (ticketData: any): Promise<any> {
  const path = '/api/integrations/ticketing/tickets'
  const data = {
    type: 'ticket',
    attributes: ticketData,
    relationships: {}
  }
  track('Ticket Created')
  return doPost({ path: path, data: data })
}

function setTicketingIntegrationFields (fields: any): Promise<any> {
  return doPut({ path: '/api/integrations/ticketing/fields', data: fields.data })
}

function getTicketingIntegrationFields (): Promise<any> {
  return doGet({ path: '/api/integrations/ticketing/fields?all' })
}

export {
  createTicket,
  getTicketFields,
  getTicketingIntegrationFields,
  setTicketingIntegrationFields
}
