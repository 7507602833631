import { sendCancelledEmail } from 'api/billing'
import { Radio, RadioGroup, TextField, Typography } from 'library/materialUI'
import { Modal, ModalSection } from 'library/Modal'
import { useContext, useState } from 'react'
import { SessionContext } from 'session-context'

interface WhyCancelModalProps {
  open: boolean
  setOpen: (open: boolean) => void
}

export default function WhyCancelModal ({ open, setOpen }: WhyCancelModalProps) {
  const [reason, setReason] = useState('')
  const [otherReason, setOtherReason] = useState('')
  const [comments, setComments] = useState('')
  const { tenantDomain } = useContext(SessionContext)

  const sendCancelReasons = () => {
    sendCancelledEmail({ reason, otherReason, comments, tenantDomain })
    setOpen(false)
  }
  return (
    <Modal
      title='What made you cancel?'
      open={open}
      onHide={() => sendCancelReasons()}
      handleSave={() => sendCancelReasons()}
      saveDisabled={!reason || (reason === 'other' && !otherReason)}
      noCloseBtn
    >
      <ModalSection>
        <RadioGroup value={reason} onChange={(e) => setReason(e.target.value)}>
          <Radio value='No longer needed' label='No longer needed' />
          <Radio value="Didn't work as expected" label="Didn't work as expected" />
          <Radio value='Too expensive' label='Too expensive' />
          <Radio value='Too difficult' label='Too difficult' />
          <Radio value='other' label='Other' />
        </RadioGroup>
        <TextField
          variant='outlined'
          value={otherReason}
          disabled={reason !== 'other'}
          onChange={(e) => setOtherReason(e.target.value)}
          style={{ width: 200 }}
        />
        <Typography variant='h3' style={{ marginTop: 10, marginBottom: 5 }}>Comments</Typography>
        <TextField
          variant='outlined'
          fullWidth
          minRows={5}
          multiline
          value={comments}
          onChange={(e) => setComments(e.target.value)}
        />
      </ModalSection>
    </Modal>
  )
}
