import React, { useState } from 'react'
import { Modal, ModalSection } from 'library/Modal'
import TextField from '@material-ui/core/TextField'
import UserPicker from '../../bot/flow-builder/UserPicker'

function AddNoteModal (props) {
  const [noteBody, setNoteBody] = useState('')
  const [taggedUserIDs, setTaggedUserIDs] = useState([])

  const reset = () => {
    setNoteBody('')
    setTaggedUserIDs([])
  }

  const handleSubmit = () => {
    props.addNote({ noteBody, taggedUserIDs })
    reset()
  }

  return (
    <Modal
      title='Add a Note'
      onHide={() => {
        props.onHide()
        reset()
      }}
      open={props.open}
      handleSave={handleSubmit}
      saveBtnText='Add Note'
      saveIcon='add'
    >
      <div>
        <ModalSection
          title='Note Contents'
        >
          <TextField
            variant='outlined'
            value={noteBody}
            placeholder='Type your note here'
            fullWidth
            margin='dense'
            multiline
            rows='5'
            onChange={e => setNoteBody(e.target.value)}
            style={{ marginBottom: 15 }}
          />
        </ModalSection>
        <ModalSection
          title='Tag Agents (Optional)'
          subtitle='Agents participating in the conversation will also get notified.'
        >
          <UserPicker
            selectedUserIds={taggedUserIDs}
            setSelectedUserIds={setTaggedUserIDs}
            autocomplete
          />
        </ModalSection>
      </div>
    </Modal>
  )
}

export default AddNoteModal
