import { makeStyles } from '@material-ui/core/styles'
import SortButton from 'cf-components/table/filter-bar/SortButton'
import { useContext } from 'react'
import CollapsibleSearchBar from './CollapsibleSearchBar'
import CSVDownload from './CSVDownload'
import EmailNotification from './EmailNotification'
import FilterButton from './FilterButton'
import { TableContext } from './TableProvider'
import { EmailSubscription } from 'classes/emailSubscriptions'

const useStyles = makeStyles(theme => ({
  filterBar: {
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: 35,
    minWidth: '100%'
  },
  filterIcons: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center'
  }
}))

interface FilterBarProps {
  subMenuContents?: any
  predefinedEmail?: EmailSubscription
}

export default function FilterBar ({ subMenuContents, predefinedEmail }: FilterBarProps) {
  const classes = useStyles()
  const { fields, searchParams, handleSort, specialSortComponent, filterComponent, noEmail, noExport } = useContext(TableContext)
  const sortFields = fields.filter(f => f.sort !== false)
  const specialComponent = specialSortComponent ? <div style={{ width: 150 }}>{specialSortComponent}</div> : null
  return (
    <div className={classes.filterBar}>
      {specialComponent}
      {!specialComponent && (
        <div className={classes.filterIcons}>
          {!noEmail && <EmailNotification predefinedEmail={predefinedEmail} />}
          {filterComponent || <FilterButton />}
          <SortButton
            sortCriteria={searchParams.sortColumn}
            sortAscending={searchParams.sortOrder === 'asc'}
            sort={(value: string) => handleSort(value)}
            sortFields={sortFields}
          />
          {!noExport && <div id='exporting-accounts'><CSVDownload subMenuContents={subMenuContents} /></div>}
          <CollapsibleSearchBar />
        </div>
      )}
    </div>
  )
}
