import { makeStyles } from '@material-ui/core/styles'
import { Icon, InputBase } from 'library/materialUI'
import { useEffect, useState } from 'react'

const useStyles = makeStyles(theme => ({
  searchInput: {
    backgroundColor: '#f2f2f2',
    color: '#7C7C7C',
    fontSize: '1.1em',
    borderRadius: 5,
    height: '2.4em',
    border: 'solid rgba(242, 242, 242, 0) 2px',
    width: '100%',
    margin: 'auto'
  },
  searchInputFocused: {
    boxShadow: 'none',
    backgroundColor: '#f2f2f2',
    width: '100%',
    borderRadius: '5px',
    border: '2px solid #9933FF'
  },
  searchIcon: {
    color: '#4A4A4A',
    paddingLeft: 12,
    paddingRight: 12
  },
  clearIcon: {
    paddingRight: 12,
    cursor: 'pointer'
  }
}))

interface SearhBarProps {
  searchString: string
  setSearchString: (searchString: string) => void
}

export default function SearchBar ({ searchString, setSearchString }: SearhBarProps) {
  const [localSearchStr, setLocalSearchStr] = useState(searchString)
  const classes = useStyles()

  useEffect(() => {
    setTimeout(() => setSearchString(localSearchStr), 500)
  }, [localSearchStr, setSearchString])

  return (
    <InputBase
      startAdornment={<Icon icon='search' />}
      endAdornment={
        searchString !== ''
          ? <div style={{ cursor: 'pointer' }}><Icon icon='close' onClick={() => { setSearchString(''); setLocalSearchStr('') }} /></div>
          : <></>
      }
      className={classes.searchInput}
      classes={{ focused: classes.searchInputFocused }}
      type='text'
      value={localSearchStr}
      onChange={e => setLocalSearchStr(e.target.value)}
      placeholder='Search'
      autoFocus
    />
  )
}
