import { Typography } from 'library/materialUI'
import SafariStep1 from 'img/safari_notif_1.png'
import SafariStep2 from 'img/safari_notif_2.png'

const SafariNotificationsDescription = (): JSX.Element => {
  return (
    <div style={{ fontFamily: 'Poppins', fontSize: '14px' }}>
      <Typography variant='h2' style={{ marginBottom: '20px' }}>To allow sound notifications in Safari, do the following:</Typography>
      <Typography variant='h2' style={{ marginBottom: '20px' }}>1. With Signals open, click “Safari” in the top menu and then click “Settings for app.chatfunnels.com”</Typography>
      <img src={SafariStep1} alt='step_1' />
      <Typography variant='h2' style={{ marginBottom: '20px', marginTop: '20px' }}>2. In the menu that appears below the URL bar, select “Allow All Auto-Play” in the dropdown beside Auto-Play</Typography>
      <img src={SafariStep2} alt='step_2' />
    </div>
  )
}

export const SafariNotificationsDocs = {
  helpCenterLink: 'https://help.getsignals.ai/article/rigkx7qxcj-allow-sound-notifications-for-new-messages',
  description: SafariNotificationsDescription,
  videoLink: '',
  icon: 'smSafari',
  title: 'Allow sound notifications in Safari'
}
