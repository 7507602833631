
const descriptionText = 'Use this skill in order to conditionally follow a play flow based upon the visitor matching a pre-defined segment.'

function SegmentBranchingDescription () {
  return (
    <div>
      <div>
        {descriptionText}
      </div>
      <ul>
        <li><a href='#/account_management/segments' target='_blank'>View Segments</a></li>
      </ul>
    </div>
  )
}

const bestPracticeText = "Be sure to define whether visitors who don't match a segment(s) will follow a fallback path in the flow."

function SegmentBranchingBestPractices () {
  return (
    <div>
      <div>
        {bestPracticeText}
      </div>
      <ul>
        <li>If a very specific visitor is desired for the play, no fallback may be desired</li>
        <li>On low traffic pages, a fallback path may be desired to better utlilize play capabilities for all visitors</li>
      </ul>
    </div>
  )
}

export const segmentBranchingDocs = {
  helpCenterLink: 'https://help.getsignals.ai/article/0k47otvwwh-company-segment-node',
  bestPractices: SegmentBranchingBestPractices,
  description: SegmentBranchingDescription,
  videoLink: 'https://youtu.be/9Gq_tx8o0NM'
}
