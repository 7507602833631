import React from 'react'
import Switch from '@material-ui/core/Switch'
import { withStyles } from '@material-ui/styles'

const CustomSwitch = withStyles({
  switchBase: {
    '&$checked': {
      color: 'white'
    },
    '&$checked + $track': {
      backgroundColor: '#416F20'
    }
  },
  checked: {},
  track: {}
})(Switch)

function StyledSwitch (props) {
  return (
    <CustomSwitch
      checked={props.checked}
      onChange={props.toggle}
    />
  )
}

export default StyledSwitch
