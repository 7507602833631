/* eslint-disable @typescript-eslint/naming-convention */
import salesloftCardStyles from './SalesloftCardStyles'
import Picker from 'cf-components/Pickers'
import { Button, Icon, Tooltip } from 'library/materialUI'
import { IconType } from 'library/materialUI/Icon'
import { useCallback, useContext, useEffect, useState } from 'react'
import BaseCard from '../BaseCard'
import Typography from '@material-ui/core/Typography'
import EditIcon from '@material-ui/icons/Edit'
import { OpenInNew } from '@material-ui/icons'
import CancelIcon from '@material-ui/icons/Cancel'
import CustomizedSnackbar from 'components/CustomizedSnackbar'
import { ScrollBox } from 'cf-components/ScrollBox'
import EmptyState from 'cf-components/EmptyState'
import { ConversationContext } from '../../../ConversationContext'
import { addContactToCadence, getActions, getCadenceMemberships, getSalesloftPerson, removeContactFromCadence } from 'api/cadence'
import Loader from 'library/loading/Loader'
import CadenceActivityBody from './SalesloftActivity'
import { ContactList } from 'classes/contactList'
import SalesloftCardWarning from './SalesloftCardWarning'
import { Emitter, EVENT_TYPE } from 'emitter'
import { Integration } from 'classes/integrations'

interface SalesloftProps {
  id: string
  personID: number
}

interface SalesloftTitleProps {
  cadenceIntegration: IconType
}

interface SalesloftCardBodyProps {
  cadenceIntegration: string
  editMode: boolean
  personID: string
  cadences: any[]
  memberships: any[]
  setEditMode: (_: boolean) => void
  save: (cadenceID: number) => void
  remove: (cadenceID: number) => void
}

function SalesloftCardTitle (props: SalesloftTitleProps): JSX.Element {
  const classes = salesloftCardStyles()
  return (
    <div className={classes.cardTitle}>
      <Icon icon={props.cadenceIntegration} />
      <div style={{ marginLeft: 5 }}>{props.cadenceIntegration.charAt(0).toUpperCase() + props.cadenceIntegration.slice(1)}</div>
    </div>
  )
}

/**
 *
 * @param props CadenceCardBodyProps
 * This card will be updated whenever it receives an event from Salesloft (possibly other integrations).
 * Salesloft allows the use of webhooks whenever an event happens in Salesloft this will be useful for keeping
 * data synced and live.
 */

function SalesloftCardBody (props: SalesloftCardBodyProps): JSX.Element {
  const classes = salesloftCardStyles()
  const { editMode, setEditMode, cadenceIntegration, save, remove, personID, cadences, memberships } = props
  const [currentCadence, setCurrentCadence] = useState<any | null>(null)
  const [actions, setActions] = useState([])
  const [activity, setActivity] = useState([])
  const [currentCadences, setCurrentCadences] = useState<any>([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    if (personID) {
      getActions(personID, cadenceIntegration)
        .then((response) => {
          setActions(response.data.attributes.upcoming)
          setActivity(response.data.attributes.past)
          setLoading(false)
        })
    }
  }, [personID, cadenceIntegration])
  useEffect(() => {
    if (cadences.length > 0 && memberships.length > 0) {
      setCurrentCadences(cadences.filter(c => memberships.map(m => m.cadence.id).includes(c.id)))
    }
  }, [setCurrentCadences, cadences, memberships])
  if (loading) {
    return (
      <Loader type='spinner' />
    )
  }
  if (editMode) {
    return (
      <div className={classes.cardBody}>
        <div className={classes.actionsDiv}>
          <Tooltip title='Cancel'>
            <CancelIcon
              color='primary'
              className={classes.actionIcon}
              onClick={() => {
                setEditMode(false)
              }}
            />
          </Tooltip>
        </div>
        <div className={classes.cadenceSubtitle}>
          Cadence:
        </div>
        <div className={classes.cadenceSelect}>
          <Picker
            selection={currentCadence}
            setSelection={(e: any) => {
              setCurrentCadence(e)
            }}
            objectType='contactList'
            loadAllProps={{ searchParams: { search: cadenceIntegration } }}
            placeholder='Select Cadence'
          />
        </div>

        <div className={classes.cadenceButtons}>
          {currentCadence && !currentCadences.map((c: any) => c.id).includes(currentCadence) ? (
            <Button
              variant='contained'
              color='primary'
              onClick={() => {
                setEditMode(false)
                save(currentCadence)
              }}
            >
              ADD
            </Button>
          ) : <></>}
          {currentCadence && currentCadences.map((c: any) => c.id).includes(currentCadence) ? (
            <Button
              variant='contained'
              color='inherit'
              onClick={() => {
                setCurrentCadences(currentCadences.filter((c: any) => c.id !== currentCadence))
                setEditMode(false)
                remove(currentCadence)
              }}
            >
              REMOVE
            </Button>
          ) : <></>}
        </div>
      </div>
    )
  }
  return (
    <div className={classes.cardBody}>
      <div className={classes.cadSubDiv}>
        <div className={classes.cadenceSubtitle}>
          Cadences:
        </div>
        <Typography variant='h4'>
          {currentCadences.length > 0 ? <>{currentCadences[0].name.length > 20 ? <Tooltip title={currentCadences[0].name}><div>{currentCadences[0].name.substring(0, 20) + '...'}</div></Tooltip> : currentCadences[0].name}</> : 'Not Enrolled'}
          {currentCadences.length > 1 ? ' (+' + (currentCadences.length - 1) + ')' : ''}
        </Typography>
      </div>
      <div>
        <div className={classes.cadenceSubtitle} style={{ marginBottom: 5 }}>
          Activity
        </div>
        {(actions.length !== 0 || activity.length !== 0) ? (
          <ScrollBox noScrollBar style={{ maxHeight: 250 }}>
            <div>
              <Typography variant='subtitle1' style={{ paddingBottom: 5 }}>
                UPCOMING
              </Typography>
              <CadenceActivityBody
                activities={actions}
              />
            </div>
            <div>
              <Typography variant='subtitle1' style={{ paddingBottom: 5 }}>
                PAST
              </Typography>
              <CadenceActivityBody
                activities={activity}
              />
            </div>
          </ScrollBox>
        ) : (
          <EmptyState
            sad
            message='No Activity Yet'
          />
        )}
      </div>
    </div>
  )
}

interface SalesloftActionsProps {
  editMode: boolean
  setEditMode: (editMode: boolean) => void
  cardState: string
  integration: string
  personID: string
  personName: string
  isLoading: boolean
}

function SalesloftActions (props: SalesloftActionsProps): JSX.Element {
  const classes = salesloftCardStyles()
  const { editMode, setEditMode, cardState, integration, personID, personName, isLoading } = props

  if (isLoading) {
    return <></>
  }

  return (
    <div className={classes.actionCtn}>
      {(!editMode && cardState === 'complete') && (
        <div style={{ display: 'flex' }}>
          <Tooltip title='Create Task'>
            <div id='salesloft_task' className={classes.actionIcon} onClick={() => Emitter.emit(EVENT_TYPE.SALESLOST_TASK, { personName: personName, personID: personID })}>
              <Icon icon='salesloftCreatTask' color='primary' specificSize={18} />
            </div>
          </Tooltip>
          <Tooltip title='Open in salesloft'>
            <OpenInNew
              color='primary'
              className={classes.actionIcon}
              onClick={() => window.open(`https://app.salesloft.com/app/people/${personID}`, '_blank')}
            />
          </Tooltip>
          <Tooltip title='Edit Cadence'>
            {/* Added div with ID for agent chat onboarding for salesloft - do not remove please - */}
            <div id='salesloft_edit'>
              <EditIcon
                color='primary'
                className={classes.actionIcon}
                onClick={() => setEditMode(true)}
              />
            </div>
          </Tooltip>
        </div>
      )}
      {(!editMode && cardState !== 'complete' && cardState !== 'error' && cardState !== 'start') && (
        <div>
          <Tooltip title={'Create person in ' + integration}>
            <div
              className={classes.actionIcon}
              onClick={() => {
                if (cardState !== 'missingEmail') {
                  setEditMode(true)
                }
              }}
            >
              <Icon icon='add' color={cardState === 'missingPerson' ? 'primary' : '#9C9C9C'} specificSize={18} />
            </div>
          </Tooltip>
        </div>
      )}
    </div>
  )
}

export default function SalesloftCard (props: SalesloftProps): JSX.Element {
  const cadenceIntegration = 'salesloft'
  const [cadenceMemberships, setCadenceMemberships] = useState([])
  const [editMode, setEditMode] = useState(false)
  const [loading, setLoading] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [snackState, setSnackState] = useState({
    open: false,
    variant: 'success',
    message: 'Added to cadence!'
  })
  const { conversation, contact } = useContext(ConversationContext)
  const participantID = conversation?.relationships.for_participant.data.id
  const [personID, setPersonID] = useState('')
  const [personName, setPersonName] = useState('')
  const [cardState, setCardState] = useState('start')
  const { data: cadences } = ContactList.loadAll({ searchParams: { search: cadenceIntegration } })
  const { data: slIntegration, isLoading } = Integration.loadOne('salesloft')

  const cadenceInfo = useCallback(() => {
    setLoading(true)
    getSalesloftPerson(participantID).then(response => {
      if (response.data.attributes.not_found === 'missing_person') {
        setCardState('missingPerson')
      } else if (response.data.attributes.not_found === 'missing_email') {
        setCardState('missingEmail')
      } else {
        setPersonID(response.data.id)
        setPersonName(response.data.attributes.first_name + ' ' + response.data.attributes.last_name)
        getCadenceMemberships(response.data.id, cadenceIntegration)
          .then(res => {
            setCadenceMemberships(res.data)
          })
        setCardState('complete')
      }
      setLoading(false)
      setRefresh(false)
    }).catch((err) => {
      console.error('failed to load salesloft person', err)
      setCardState('error')
      setLoading(false)
      setRefresh(false)
    })
    setCardState('start')
  }, [participantID, cadenceIntegration])

  const save = (cadence: any) => {
    addContactToCadence(personID, cadenceIntegration, cadence)
      .then(_ => {
        setSnackState({ ...snackState, open: true, message: 'Added to cadence!' })
        setRefresh(true)
      })
  }

  const remove = (cadence: any) => {
    removeContactFromCadence(personID, cadenceIntegration, cadence)
      .then(_ => {
        setSnackState({ ...snackState, open: true, message: 'Removed from cadence!' })
        setRefresh(true)
      })
  }

  useEffect(() => {
    if (!refresh && !loading && !isLoading) {
      setRefresh(true)
    }
    // eslint-disable-next-line
  }, [isLoading, participantID, contact?.attributes.email])

  useEffect(() => {
    if (refresh && !loading && slIntegration?.enabled) {
      cadenceInfo()
    }
    // eslint-disable-next-line
  }, [cadenceInfo, refresh, loading])

  if (isLoading || !slIntegration?.enabled) {
    return <></>
  }

  return (
    <div id='salesloft_card'>
      <BaseCard
        title={<SalesloftCardTitle cadenceIntegration={cadenceIntegration} />}
        id={props.id}
        allowOverflow
        actions={(
          <SalesloftActions
            editMode={editMode}
            setEditMode={setEditMode}
            cardState={cardState}
            integration={cadenceIntegration}
            personID={personID}
            personName={personName}
            isLoading={loading}
          />
        )}
      >
        {loading ? <Loader type='spinner' title={'Loading ' + cadenceIntegration.charAt(0).toUpperCase() + cadenceIntegration.substring(1) + ' data'} />
          : (
            <>
              {cardState === 'complete' ? (
                <SalesloftCardBody
                  cadenceIntegration={cadenceIntegration}
                  editMode={editMode}
                  setEditMode={setEditMode}
                  save={save}
                  remove={remove}
                  personID={personID}
                  cadences={cadences.list}
                  memberships={cadenceMemberships}
                />
              ) : (
                <SalesloftCardWarning
                  cardState={cardState}
                  editMode={editMode}
                  setEditMode={setEditMode}
                  integration={cadenceIntegration}
                  setRefresh={setRefresh}
                />
              )}
            </>)}
        <CustomizedSnackbar state={snackState} handler={setSnackState} />
      </BaseCard>
    </div>
  )
}
