import { Typography } from 'library/materialUI';

interface LoginTitleProps {
  multipleTenantsLength: number
  tenantBundle?: any
}

export default function LoginTitle ({ multipleTenantsLength, tenantBundle } : LoginTitleProps) {
  const titleText = multipleTenantsLength > 0 ? 'Choose Account' : tenantBundle
  ? `Sign in - ${tenantBundle}`
  : null
  let titleTextComponent
  if (titleText) {
    titleTextComponent = (
      <Typography component='h1' variant='h5'>
        {titleText}
      </Typography>
    )
  } else {
    titleTextComponent = (
      <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
        <Typography variant='h1'>Sign In</Typography>
        <Typography variant='subtitle1'>Engage with your customer using Signals</Typography>
      </div>
    )
  }
  return (titleTextComponent)
}
