import { NodeHeader } from './NodeComponents'
import { NodeBody } from '../NodeBody'
import { BasePort } from '../BasePort'
import { assignKey } from './helpers'
import { getOrderedPorts } from 'library/flowBuilder/FlowBuilder'

type PortsProps = {
  base: any
  mapping: any
}

function Ports (props: PortsProps) {
  const nodeState = props.base.state
  const sortedPorts = getOrderedPorts(nodeState.ports, nodeState.portOrder)
  let color = ''
  if (nodeState.settings?.title === 'Integrations') { // If multiple integrations change port color from mapping
    color = '#9933FF'
  } else {
    color = props.mapping.color
  }
  return (
    <div>
      {sortedPorts.map((p, index) => {
        return (
          <div
            key={index}
          >
            {/* {p.id === 'default' && nodeState.kind === 'ContactSearch'
              ? (
                <div style={{ display: 'flex', color: '#A7A7A7', font: 'normal normal normal Poppins', textAlign: 'center', margin: '10px 0 0', alignItems: 'center', justifyContent: 'center', fontSize: '13px' }}>
                  Visitors
                </div>
              )
              : <></>} */}
            <BasePort
              // eslint-disable-next-line
              // @ts-ignore
              index={index}
              data={p}
              key={assignKey(p)}
              parent={props.base}
              color={color}
            />
          </div>
        )
      })}
    </div>
  )
}

type NodeContentsProps = {
  base: any
  state: any
}

export function NodeContents (props: NodeContentsProps) {
  const nodeMapping = window.flowBuilder.nodeMapping
  const kind = props.base.state.kind
  const mapping = nodeMapping[kind]

  return (
    <>
      <NodeHeader
        base={props.base}
        state={props.state}
        mapping={mapping}
        nodeMapping={nodeMapping}
      />
      <div style={{ padding: '0px 8px 8px 8px' }}>
        <div style={{ padding: 5 }}>
          <NodeBody
            base={props.base}
            nodeMapping={nodeMapping}
          />
        </div>
        <Ports
          base={props.base}
          mapping={mapping}
        />
      </div>
    </>
  )
}
