import { Modal, ModalSection } from 'library/Modal'
import SimpleNavBar, { SubSections } from 'library/navigation/SimpleNavBar'
import Loader from 'library/loading/Loader'
import { SessionContext } from 'session-context'
import DetailOptions from './DetailOptions'
import { makeStyles } from '@material-ui/core/styles'
import { useContext, useEffect, useState } from 'react'
import DomainDetailEdit from './DomainDetailEdit'
import ModalWarning from 'components/ModalWarning'
import Account from 'classes/accounts'
import CompanyFieldMeta from 'classes/companyFieldMeta'
import { Icon, Typography } from 'library/materialUI'
import { AccountPageContext } from '../AccountPageProvider'
import { addVote } from 'api/crowdsource'

interface DomainDataModalProps {
  company: Account
  open?: boolean
  onHide?: () => void
}

const useStyles = makeStyles(theme => ({
  mainBody: {
    width: '100%',
    padding: 35
  }
}))

/*
  Have seperate functions that return the JSX for each component
*/

function getComponent (component: string, lastUpdated: any, onAdd: (value: string | number | string[]) => Promise<boolean>, current?: Account): JSX.Element {
  switch (component) {
    case 'name':
      return (
        <ModalSection title='Company Name' titleType='h1'>
          <div style={{ marginBottom: 30, marginTop: 10 }}>
            <DetailOptions detailName='Company Name' current={current?.name} updatedTimestamp={lastUpdated} />
          </div>
          <DomainDetailEdit
            detailName='Company Name'
            type='text'
            onAdd={onAdd}
          />
        </ModalSection>
      )
    case 'account_type':
      return (
        <ModalSection title='Account Type' titleType='h1'>
          <div style={{ display: 'flex', padding: 10, marginBottom: 30, marginTop: 10, backgroundColor: '#F5F5F5', borderRadius: 5 }}>
            <Icon icon='infoIcon' color='#9933FF' /><Typography style={{ marginLeft: 10 }} variant='body1'> Account type is configured in your CRM. To edit this, <a href={current?.crmLink}>open this account in your CRM</a></Typography>
          </div>
        </ModalSection>
      )
    case 'phone':
      return (
        <ModalSection title='Phone Number' titleType='h1'>
          <div style={{ marginBottom: 30, marginTop: 10 }}>
            <DetailOptions detailName='Phone Number' current={current?.phone} updatedTimestamp={lastUpdated} />
          </div>
          <DomainDetailEdit
            detailName='Phone Number'
            type='text'
            onAdd={onAdd}
          />
        </ModalSection>
      )
    case 'annual_revenue':
      return (
        <ModalSection title='Annual Revenue' titleType='h1'>
          <div style={{ marginBottom: 30, marginTop: 10 }}>
            <DetailOptions detailName='Annual Revenue' current={current?.revenueRange} updatedTimestamp={lastUpdated} />
          </div>
          <DomainDetailEdit
            detailName='Annual Revenue'
            type='number'
            onAdd={onAdd}
          />
        </ModalSection>
      )
    case 'location':
      return (
        <ModalSection title='HQ Location' titleType='h1'>
          <div style={{ marginBottom: 30, marginTop: 10 }}>
            <DetailOptions detailName='HQ Location' current={current?.country + (current?.addressState ? '/' + current?.addressState : '')} updatedTimestamp={lastUpdated} />
          </div>
          <DomainDetailEdit
            detailName='HQ Location'
            type='location'
            onAdd={onAdd}
          />
        </ModalSection>
      )
    case 'city':
      return (
        <ModalSection title='Location City' titleType='h1'>
          <div style={{ marginBottom: 30, marginTop: 10 }}>
            <DetailOptions detailName='City' current={current?.addressCity} updatedTimestamp={lastUpdated} />
          </div>
          <DomainDetailEdit
            detailName='City (optional)'
            type='text'
            onAdd={onAdd}
          />
        </ModalSection>
      )
    case 'industry':
      return (
        <ModalSection title='Industry' titleType='h1'>
          <div style={{ marginBottom: 30, marginTop: 10 }}>
            <DetailOptions detailName='Industry' current={current?.industry} updatedTimestamp={lastUpdated} />
          </div>
          <DomainDetailEdit
            detailName='Industry'
            type='industry'
            onAdd={onAdd}
          />
        </ModalSection>
      )
    case 'number_of_employees':
      return (
        <ModalSection title='Employee Count' titleType='h1'>
          <div style={{ marginBottom: 30, marginTop: 10 }}>
            <DetailOptions detailName='Employee Count' current={current?.employeeRange} updatedTimestamp={lastUpdated} />
          </div>
          <DomainDetailEdit
            detailName='Employee Count'
            type='number'
            onAdd={onAdd}
          />
        </ModalSection>
      )
    case 'technologies':
      return (
        <ModalSection title='Company Tech' titleType='h1'>
          <div style={{ marginBottom: 30, marginTop: 10 }}>
            <DetailOptions detailName='Company Tech' current={current?.technologies} updatedTimestamp={lastUpdated} />
          </div>
          <DomainDetailEdit
            detailName='Company Tech'
            type='list'
            onAdd={onAdd}
            initialValue={current?.technologies}
          />
        </ModalSection>
      )
    case 'total_funding':
      return (
        <ModalSection title='Total Funding' titleType='h1'>
          <div style={{ marginBottom: 30, marginTop: 10 }}>
            <DetailOptions detailName='Total Funding' current={current?.revenueRange} updatedTimestamp={lastUpdated} />
          </div>
          <DomainDetailEdit
            detailName='Total Funding'
            type='number'
            onAdd={onAdd}
          />
        </ModalSection>
      )
    case 'last_funding_date':
      return (
        <ModalSection title='Last Funding Date' titleType='h1'>
          <div style={{ marginBottom: 30, marginTop: 10 }}>
            <DetailOptions detailName='Last Funding Date' current={current?.lastFundingDate} updatedTimestamp={lastUpdated} />
          </div>
          <DomainDetailEdit
            detailName='Last Funding Date'
            type='date'
            onAdd={onAdd}
            initialValue={current?.lastFundingDate || new Date().toISOString()}
          />
        </ModalSection>
      )
    case 'last_funding_amount':
      return (
        <ModalSection title='Last Funding Amount' titleType='h1'>
          <div style={{ marginBottom: 30, marginTop: 10 }}>
            <DetailOptions detailName='Last Funding Amount' current={current?.lastFundingAmount} updatedTimestamp={lastUpdated} />
          </div>
          <DomainDetailEdit
            detailName='Last Funding Amount'
            type='number'
            onAdd={onAdd}
            initialValue={current?.lastFundingDate || new Date().toISOString()}
          />
        </ModalSection>
      )
    default:
      return (
        <ModalSection title='Oops...' titleType='h1'>
          <ModalWarning body='It looks like we are not taking submissions at the moment for this detail' />
        </ModalSection>
      )
  }
}

export default function DomainDataModal ({ company, open, onHide }: DomainDataModalProps): JSX.Element {
  const classes = useStyles()
  const { saveSuccessful } = useContext(SessionContext)
  const [currentComponent, setCurrentComponent] = useState<string>('name')
  const { detailsModalOpen, setDetailsModalOpen, lastSelectedDetail } = useContext(AccountPageContext)
  const { data: meta, isLoading } = CompanyFieldMeta.loadOne(company?.domain)

  useEffect(() => {
    setCurrentComponent(lastSelectedDetail || 'name')
  }, [lastSelectedDetail])

  const domainModalSubsections: SubSections = {
    'Basic Info': [
      { key: 'name', sectionName: 'Company Name', action: (key: string) => { setCurrentComponent(key) } },
      { key: 'account_type', sectionName: 'Account Type', action: (key: string) => { setCurrentComponent(key) } },
      { key: 'phone', sectionName: 'Phone Number', action: (key: string) => { setCurrentComponent(key) } }
    ],
    'Firmographic Data': [
      { key: 'annual_revenue', sectionName: 'Annual Revenue', action: (key: string) => { setCurrentComponent(key) } },
      { key: 'location', sectionName: 'HQ Location', action: (key: string) => { setCurrentComponent(key) } },
      { key: 'city', sectionName: 'City (optional)', action: (key: string) => { setCurrentComponent(key) } },
      { key: 'industry', sectionName: 'Industry', action: (key: string) => { setCurrentComponent(key) } },
      { key: 'number_of_employees', sectionName: 'Employee Count', action: (key: string) => { setCurrentComponent(key) } },
      { key: 'technologies', sectionName: 'Company Tech', action: (key: string) => { setCurrentComponent(key) } }
    ],
    Funding: [
      { key: 'total_funding', sectionName: 'Total Funding', action: (key: string) => { setCurrentComponent(key) } },
      { key: 'last_funding_date', sectionName: 'Last Funding Date', action: (key: string) => { setCurrentComponent(key) } },
      { key: 'last_funding_amount', sectionName: 'Last Funding Amount', action: (key: string) => { setCurrentComponent(key) } }
    ]
  }

  return (
    <Modal
      open={open || detailsModalOpen}
      onHide={onHide || (() => setDetailsModalOpen(false))}
      handleSave={() => console.log('save')}
      title='Edit Account Details'
      noPadding
      size='md'
      hideSaveButton
      cancelBtnText='Close'
    >
      {isLoading ? (
        <div style={{ height: '200px' }}>
          <Loader />
        </div>
      ) : (
        <div style={{ display: 'flex' }}>
          <div style={{ backgroundColor: 'white' }}>
            <SimpleNavBar
              navTitle='Account Details'
              menu={domainModalSubsections}
              startSection={lastSelectedDetail || 'Company Name'}
            />
          </div>
          <div className={classes.mainBody}>
            {getComponent(
              currentComponent,
              meta?.fieldMeta[currentComponent]?.lastUpdated,
              (value: string | number | string[]): Promise<boolean> => {
                if (currentComponent === 'annual_revenue' || currentComponent === 'number_of_employees' || currentComponent === 'total_funding' || currentComponent === 'last_funding_amount') {
                  value = parseInt(value as string)
                }
                return addVote({
                  domain: company.domain,
                  field: currentComponent,
                  vote: { value, isUpvote: true, submission: true }
                }).then(() => {
                  saveSuccessful('Thank you! Your suggestion has been recorded and will be reviewed soon.')
                  return true
                })
              },
              company)}
          </div>
        </div>
      )}
    </Modal>
  )
}
