import { Sequence } from './sequences'
import { useDoQuery } from './useDoQuery'

interface ActiveSequenceList {
  list: ActiveSequences[]
  dict: { [id: ActiveSequences['id']]: ActiveSequences }
}

export class ActiveSequences extends Sequence {
  constructor ({ row }: { row: Record<string, any>}) {
    super({ row })
  }

  static loadAll (): { data: ActiveSequenceList, isLoading: boolean} {
    return useDoQuery({
      useChatURL: true,
      path: '/v2/sequences/active_sequences',
      objectClass: ActiveSequences
    })
  }
}
