import { NPSResponse } from 'classes/npsResponse'
import { Table, Fields } from 'cf-components/table/Table'

export function NPSResponses () {
  const { data, isLoading } = NPSResponse.loadAll()

  const fields: Fields<NPSResponse> = [
    { name: 'score', type: 'npsScore' },
    { name: 'name', type: 'avatar' },
    { name: 'createdTimestamp', type: 'dateTime', label: 'Date Submitted' },
    { name: 'feedback', type: 'text', label: 'Feedback' }
  ]

  return (
    <div style={{ margin: -20, marginBottom: 0 }}>
      <Table
        rows={data.list}
        isLoading={isLoading}
        fields={fields}
        columnStyles='1fr 3fr 2fr 5fr'
        sortColumn='createdTimestamp'
        sortAscending={false}
      />
    </div>
  )
}
