import Picker from 'cf-components/Pickers'
import { ModalSection } from 'library/Modal'
import { ModalContentsProps } from 'library/flowBuilder/nodes/NodeModal'
import { Bot } from 'classes/classes'
import { Button, ButtonGroup, TextField } from 'library/materialUI'
import * as yup from 'yup'
import { ChangeEvent } from 'react'
import { Grid, InputAdornment, Slider } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  buttonActive: {
    color: theme.palette.primary.alt,
    fontWeight: 'bold'
  }
}))

export const BotNodeSchema = yup.object().shape({
  settings: yup.object({
    botID: yup.number(),
    delayType: yup.string(),
    delay: yup.number().min(0),
    scrollPercent: yup.number().min(0).max(100)
  })
})

export function BotModal ({ settings, updateSettings }: ModalContentsProps): JSX.Element {
  const classes = useStyles()
  const handleClick = (id: number) => {
    if (id) {
      window.open('/#/bots/' + id)
    } else {
      window.open('/#/bot_management/bots')
    }
  }
  return (
    <div>
      <ModalSection
        title='Select a Bot'
        subtitle='Choose a conversation flow for this visitor.'
      >
        <Picker
          selection={settings.botID}
          setSelection={(value: string | number) => updateSettings({ botID: value })}
          objectType='bots'
          loadAllProps={{ searchParams: { filters: [[{ field: 'kind', operator: 'ne', value: 'content_page' }]] } }}
          sortOptions
        />
        {settings.botID ? (
          <Button
            style={{ fontSize: 12, color: 'black', marginTop: 5, borderRadius: 50 }}
            variant='outlined'
            size='small'
            onClick={() => handleClick(settings.botID)}
          >
            Edit in Bot Builder
          </Button>
        ) : <></>}
      </ModalSection>
      <ModalSection
        title='Delay Settings'
      >
        <ButtonGroup>
          <Button
            className={settings.delayType === 'time' ? classes.buttonActive : undefined}
            onClick={() => updateSettings({ delayType: 'time' })}
          >
            Time Delay
          </Button>
          <Button
            className={settings.delayType === 'scroll' ? classes.buttonActive : undefined}
            onClick={() => updateSettings({ delayType: 'scroll' })}
          >
            Scroll Percentage
          </Button>
        </ButtonGroup>
        {
          settings.delayType === 'time'
            ? (
              <ModalSection
                subtitle='How many seconds before the bot prompts the visitor?'
              >
                <div style={{ width: '30%' }}>
                  <TextField
                    type='number'
                    variant='outlined'
                    error={(!settings.delay && settings.delay !== 0) || settings.delay < 0}
                    helperText={(!settings.delay && settings.delay !== 0) || settings.delay < 0 ? 'Must be a number greater than or equal to 0' : ''}
                    value={settings.delay}
                    onChange={(e) => updateSettings({ delay: e.target.value })}
                    margin='dense'
                    InputProps={{
                      endAdornment: <InputAdornment position='end'>seconds</InputAdornment>
                    }}
                  />
                </div>
              </ModalSection>
            ) : (
              <ModalSection
                subtitle='How far down the page should the visitor scroll before the bot is triggered?'
              >
                <Grid container spacing={2} alignItems='center'>
                  <Grid item xs>
                    <Slider
                      value={settings.scrollPercent}
                      onChange={(event: ChangeEvent<{}>, value: number | number[]) => {
                        updateSettings({ scrollPercent: value as number })
                      }}
                    />
                  </Grid>
                  <Grid item xs='auto'>
                    <TextField
                      type='number'
                      error={settings.scrollPercent < 0 || settings.scrollPercent > 100}
                      helperText={settings.scrollPercent < 0 || settings.scrollPercent > 100 ? 'Must be a number between 0 and 100' : ''}
                      value={settings.scrollPercent}
                      onChange={(e) => updateSettings({ scrollPercent: e.target.value })}
                      onBlur={() => {
                        if (settings.scrollPercent < 0) {
                          updateSettings({ scrollPercent: (0) })
                        } else if (settings.scrollPercent > 100) {
                          updateSettings({ scrollPercent: (100) })
                        }
                      }}
                      InputProps={{
                        endAdornment: <InputAdornment position='end'>%</InputAdornment>
                      }}
                      inputProps={{
                        step: 10,
                        min: 0,
                        max: 100,
                        type: 'number'
                      }}
                    />
                  </Grid>
                </Grid>
              </ModalSection>
            )
        }
      </ModalSection>
    </div>
  )
}

export function FireBotBody ({ settings }: any): JSX.Element {
  const botID = settings.botID
  const { data: bots } = Bot.loadAll()
  const bot = bots?.dict[botID]
  const name = bot?.name
  const message = name || ''
  return (
    <div style={{ minHeight: '20px' }}>
      {message}
    </div>
  )
}
