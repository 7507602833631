import { Select, TextBox } from 'cf-components/material-wrappers/MaterialComponents'
import { useEffect, useState } from 'react'
import CancelRounded from '@material-ui/icons/CancelRounded';
import { BlockedPage } from './BlockedPages';

interface BlockedPageRowProps {
  comparison: string
  currentUrl: string
  pageList: any
  dispatch: any
}

export default function BlockedPageRow ({ comparison, currentUrl, pageList, dispatch }: BlockedPageRowProps): JSX.Element {
  const [comparisonType, setComparisonType] = useState(comparison)
  const [url, setUrl] = useState(currentUrl)

  const comparisonOptions = [
    { value: 'in', label: 'Contains' },
    { value: 'not in', label: 'Does Not Contain' },
    { value: 'is', label: 'Exact Match' }
  ]

  const deletePageCondition = () => {
    dispatch({ type: 'updateSetting', name: 'blocked_pages', value: JSON.stringify({ list: pageList.filter((page: BlockedPage) => page.url !== url) }) })
  }

  const updatePageCondition = () => {
    const newPageList = [...pageList]
    const index = newPageList.findIndex((page: BlockedPage) => page.url === currentUrl)
    newPageList[index] = { url: url, comparison: comparisonType }
    dispatch({ type: 'updateSetting', name: 'blocked_pages', value: JSON.stringify({ list: newPageList }) })
  }

  useEffect(() => {
    updatePageCondition()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comparisonType, url])

  return (
    <div style={{ display: 'flex', flexDirection: 'row', marginTop: '20px', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
      <div style={{ width: '225px' }}>
        <Select
          value={comparisonType}
          onChange={(value: any) => setComparisonType(value)}
          options={comparisonOptions}
          placeholder='Comparison Type'
        />
      </div>
      <div style={{ marginLeft: '20px', width: '100%', marginRight: '10px' }}>
        <TextBox
          value={url}
          onChange={(value: string) => setUrl(value)}
        />
      </div>
      <CancelRounded
        // className={classes.urlDeleteIcon}
        cursor='pointer'
        color='primary'
        onClick={() => {
          deletePageCondition()
        }}
      />
    </div>
  )
}
