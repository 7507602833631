import { Typography } from 'library/materialUI';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import DropDownLoader from './custom/DropDownLoader';

interface LoaderProps {
  type?: keyof typeof loaderMapping
  title?: string | string[]
  size?: 'sm' | 'md' | 'lg' | 'xlg'
}

const loaderMapping = {
  spinner: CircularProgress,
  dropdown: DropDownLoader
}

const loaderSizing = {
  sm: {
    width: '.25em',
    height: '1em',
    radius: 3,
    margin: 3
  },
  md: {
    width: '.5em',
    height: '2.5em',
    radius: 6,
    margin: 6
  },
  lg: {
    width: '1em',
    height: '5em',
    radius: 9,
    margin: 9
  },
  xlg: {
    width: '2em',
    height: '10em',
    radius: 12,
    margin: 12
  }
}

const fontSizes: Record<string, number> = {
  sm: 12,
  md: 16,
  lg: 20,
  xlg: 24
}

type LoadSize = {
  width: string | undefined
  height: string | undefined
  radius: number
  margin: number
}

// With the new style changes this should only be the spinner type and we'll just need size
// to change according to the usage
export default function Loader ({ title, type = 'spinner', size = 'sm' }: LoaderProps) {
  const [label, setLabel] = useState('')
  const [index, setIndex] = useState(0)
  const Loader = loaderMapping[type] as any
  const loaderSize: LoadSize = loaderSizing[size]
  const fontSize = fontSizes[size]
  HandleRotatingLabel(setLabel, setIndex, index, label, title)
  if (type === 'spinner') {
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%', flexDirection: 'column', color: '#8E5AE2' }}>
        <CircularProgress color='inherit' size={loaderSize.height} />
        {label && <Typography color='primary' style={{ marginBottom: 5, fontSize: fontSize }}>{label}</Typography>}
      </div>
    )
  }
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%', flexDirection: 'column' }}>
      {label && <Typography color='primary' style={{ marginBottom: 5, fontSize: fontSize }}>{label}</Typography>}
      <Loader color='#8E5AE2' {...loaderSize} />
    </div>
  )
}

function HandleRotatingLabel (setLabel: Dispatch<SetStateAction<string>>, setIndex: Dispatch<SetStateAction<number>>, index: number, label: string, title?: string[] | string) {
  const ia = Array.isArray(title)
  const getNewIndex = (title: string[]) => index + 1 > title.length ? 0 : index + 1
  useEffect(() => {
    if (title) {
      if (ia && label) {
        const newIndex = getNewIndex(title)
        setTimeout(() => {
          setLabel(title[index])
          setIndex(newIndex)
        }, 5000)
      } else if (ia && !label) {
        const newIndex = getNewIndex(title)
        setLabel(title[index])
        setIndex(newIndex)
      }
      if (!ia && !label) {
        setLabel(title)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index])
}
