import { useRef, useContext, useState } from 'react'
import { FixedSizeList } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'
import NoConversation from '../NoConversation'
import Conversation from './ConversationItem'
import Button from '@material-ui/core/Button'
import { useHotkeys } from 'react-hotkeys-hook'
import { makeStyles } from '@material-ui/core/styles'
import { ConversationParametersContext } from './conversation-parameters-context'
import { ConversationWindowContext } from './conversation-window-context'
import Loader from 'library/loading/Loader'

const useStyles = makeStyles(theme => ({
  loadMoreDiv: {

  },
  loadMoreButton: {
    fontSize: '1em',
    padding: '18px 8px'
  },
  loadingOverlay: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0
  }
}))

const Row = function ({ data, index, style }) {
  const classes = useStyles()
  const { parameters } = useContext(ConversationParametersContext)
  const { isOnline } = useContext(ConversationWindowContext)
  const [loading, setLoading] = useState(false)
  const {
    conversationList,
    selectedConversations,
    readReceipts,
    conversationListMeta
  } = data.state
  const conversation = conversationList[index]
  if (!conversation) {
    if (conversationListMeta.more) {
      return (
        <div className={classes.loadMoreDiv} style={style}>
          {loading ? (
            <Loader type='spinner' />
          ) : (
            <Button
              fullWidth
              color='primary'
              className={classes.loadMoreButton}
              onClick={() => { parameters.getMore(); setLoading(true) }}
            >
              Load More
            </Button>
          )}
        </div>
      )
    } else {
      return (<></>)
    }
  }
  const active = data.activeConversationID === conversation.id
  const online = isOnline(conversation)

  const selected = selectedConversations.map(c => c.id).includes(conversation.id)
  const read = active || readReceipts[conversation.id]

  return (
    <Conversation
      conversation={conversation}
      active={active}
      selected={selected}
      style={style}
      handleSelect={data.selectSingleConversation}
      handleAddToSelection={data.addToSelection}
      showCheckBox={selectedConversations.length > 0}
      read={read}
      online={online}
    />
  )
}

const getSelectedConversations = ({ startID, endID, list }) => {
  const ids = list.map(c => c.id)
  const startIndex = ids.indexOf(startID)
  const endIndex = ids.indexOf(endID)
  const indices = endIndex > startIndex ? [startIndex, endIndex] : [endIndex, startIndex]
  const conversations = list.slice(indices[0], indices[1] + 1)
  return conversations
}

function ConversationList (props) {
  const state = props.state
  const activeConversationID = props.activeConversationID
  const dispatch = props.dispatch
  const rowHeight = 86
  const lastClickedIDRef = useRef(null)

  const activeConvoIndex = state.conversationList?.findIndex((currentValue) => {
    if (currentValue.id === props.activeConversationID) {
      return true
    }
    return false
  })

  function selectSingleConversation (id) {
    lastClickedIDRef.current = id
    const length = state.selectedConversations.length
    const idSelected = state.selectedConversations.map(c => c.id).includes(id)
    let clearSelection = false
    if (length > 1 || (length === 1 && !idSelected)) {
      clearSelection = true
    }
    dispatch({ type: 'selectConversation', value: id, clearSelection })
  }

  function addToSelection ({ conversation, shift }) {
    const cid = conversation.id
    if (shift) {
      const startID = lastClickedIDRef.current
      const conversations = getSelectedConversations({ startID, endID: cid, list: state.conversationList })
      dispatch({ type: 'addToSelection', value: conversations })
    } else {
      if (state.selectedConversations.map(c => c.id).includes(cid)) {
        dispatch({ type: 'removeFromSelection', value: cid })
      } else {
        dispatch({ type: 'addToSelection', value: [conversation] })
      }
    }
    lastClickedIDRef.current = cid
  }

  useHotkeys('up', () => {
    if (activeConversationID) {
      const newConvoId = state.conversationList[activeConvoIndex - 1]?.id
      if (newConvoId) {
        selectSingleConversation(newConvoId)
      }
    }
  }, {}, [state.conversationList, activeConvoIndex])

  useHotkeys('down', () => {
    if (activeConversationID) {
      const newConvoId = state.conversationList[activeConvoIndex + 1]?.id
      if (newConvoId) {
        selectSingleConversation(newConvoId)
      }
    }
  }, {}, [state.conversationList, activeConvoIndex])

  if (!state.conversationList) {
    return <></>
  }

  if (!state.conversationList.length) {
    return <NoConversation message='No open conversations.' />
  }

  return (
    <AutoSizer>
      {({ height, width }) => (
        <FixedSizeList
          height={height}
          itemData={{ state, activeConversationID, selectSingleConversation, addToSelection, lastClickedIDRef }}
          itemCount={state.conversationList.length + 1}
          itemSize={rowHeight}
          width={width}
        >
          {Row}
        </FixedSizeList>)}
    </AutoSizer>
  )
}

export default ConversationList
