import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import DateFormat from 'components/DateFormat'
import EmailPerformanceTable from './EmailPerformanceTable'
import { getEmailPerformance } from 'api/reports'
import { downloadCSV } from 'components/downloadCSV'
import { FilterBar, convertDateRange } from 'cf-components/FilterBar/FilterBar'
import { track } from 'userpilot'
import { getSessionDateRange } from 'cf-components/FilterBar/CustomDateRanges'

const useStyles = makeStyles(theme => ({
  controls: {
    display: 'grid',
    gridTemplateColumns: '350px auto',
    height: 48,
    gridGap: 20
  },
  tableDiv: {
    padding: '5px 20px 20px 20px'
  },
  controlButtons: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    height: 48
  },
  icon: {
    color: '#7A7A7A',
    height: 18,
    width: 18,
    paddingRight: 10
  },
  filterClass: {
    display: 'flex'
  },
  switchLabel: {
    color: '#5C5C5C',
    fontSize: '14px'
  }
}))

function EmailPerformancePage (props) {
  const classes = useStyles()
  let startingDate = getSessionDateRange()
  startingDate = { ...startingDate, startDate: new Date(startingDate.startDate), endDate: new Date(startingDate.endDate) }
  const [dateRange, setDateRange] = useState(startingDate)
  const [rows, setRows] = useState([])
  const [selected, setSelected] = useState([])
  const [loading, setLoading] = useState(true)
  const [search, setSearch] = useState('')
  const showArchived = props.showArchived

  useEffect(() => {
    track('Bot Performance Viewed')
  })

  useEffect(() => {
    if (dateRange) {
      setLoading(true)
      getEmailPerformance({ attributes: convertDateRange(dateRange) }).then(response => {
        setRows(response?.data ? Object.values(response.data) : [])
        setLoading(false)
      })
    }
  }, [dateRange])

  function exportEmailPerformance () {
    const date = new Date().toString()
    const filename = 'CF_email_performance_' + DateFormat(date, 'isoDate') + '.csv'
    const headers = ['Email Name', 'Email ID', 'Sent', 'Delivered', 'Opened', 'Clicked']

    let csvContent = headers.join(',') + '\n'
    let filteredRows = selected.length ? rows.filter(row => selected.includes(row.id)) : rows
    if (!showArchived) {
      filteredRows = filteredRows.filter(email => !email.deleted_timestamp)
    }
    csvContent += filteredRows.map(row =>
      [row.name, row.id, row.sent, row.delivered, row.opened, row.clicked]).map(row =>
        row.join(',')).join('\n')
    downloadCSV(filename, csvContent)
  }

  const filterOptions = {
    filterValues: [
      { value: 'Date', label: 'Filter by Date', toggle: false }
    ],
    dateRange: dateRange,
    setDateRange: setDateRange
  }

  return (
    <div>
      <FilterBar
        search={search}
        setSearch={setSearch}
        filterOptions={filterOptions}
        handleExport={exportEmailPerformance}
      />
      <div className={classes.tableDiv}>
        <EmailPerformanceTable
          rows={rows ? rows.filter(
            email => email.name.toLowerCase().includes(search.toLowerCase())
          ).filter(
            email => { if (showArchived) { return true } else return !email.deleted_timestamp }
          ) : []}
          selected={selected}
          setSelected={setSelected}
          loading={loading}
        />
      </div>
    </div>
  )
}

export default EmailPerformancePage
