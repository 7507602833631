import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { getMyRecentConversations } from 'api/dashboard'
import DashboardCard from './DashboardCard'
import dateFormat from 'dateformat'
import RenderEmoji from 'pages/chat/RenderEmoji'
import Divider from '@material-ui/core/Divider'
import { Avatar, getInitials } from 'cf-components/Avatars'
import Link from 'cf-components/Link'
import EmptyBox from 'cf-components/EmptyBox'
import Loader from 'library/loading/Loader'
import { getColor } from 'library/helpers'

const useStyles = makeStyles(theme => ({
  conversation: {
    display: 'grid',
    gridTemplateColumns: '40px auto',
    gridGap: 15,
    fontSize: '13px',
    paddingTop: 10,
    paddingBottom: 10,
    cursor: 'pointer',
    paddingRight: 10
  },
  visitorName: {
    fontSize: '14px',
    color: '#af91e3',
    fontWeight: 600
  },
  details: {
    fontSize: '12px',
    color: '#575757',
    wordBreak: 'break-word'
  },
  upperDetails: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  messageBody: {
    '& p': {
      margin: 0
    },
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    maxHeight: 17,
    marginRight: 120
  },
  divider: {
    backgroundColor: '#D3D3D3'
  },
  loadingDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
  }
}))

function Conversation (props) {
  const classes = useStyles()
  const convo = props.conversation
  const attributes = convo.attributes
  const contactID = convo.relationships.for_contact.data.id
  const participantID = convo.relationships.for_participant.data.id
  const message = attributes.last_message
  const timestamp = attributes.last_message_timestamp

  const lastItem = props.index === props.count - 1

  const avatarUrl = attributes.avatar_url
  let name = 'Site Visitor'
  let initials = null
  if (contactID) {
    if (attributes.first_name || attributes.last_name) {
      name = attributes.first_name + ' ' + attributes.last_name
    } else if (attributes.email) {
      name = attributes.email
    }
    initials = getInitials(name, 'SV')
  }

  const contactLink = contactID ? `/contacts/${contactID}` : `/chat/${convo.id}`

  const color = getColor(props.index)

  return (
    <>
      <div className={classes.conversation} style={lastItem ? { paddingBottom: 0 } : props.index === 0 ? { paddingTop: 0 } : null}>
        <Link to={contactLink} target='_blank'>
          <div style={{ height: 40 }}>
            <Avatar
              participantID={contactID || participantID}
              initials={initials}
              size='md'
              avatarUrl={avatarUrl}
              backgroundColor={color}
              color='white'
            />
          </div>
        </Link>
        <Link to={`/chat/${convo.id}`} target='_blank'>
          <div className={classes.upperDetails}>
            <div className={classes.visitorName}>
              {name}
            </div>
            <div className={classes.details}>
              {dateFormat(timestamp, 'mmm dd - hh:MM TT')}
            </div>
          </div>
          <div className={classes.details}>
            <RenderEmoji
              text={message}
              rich
              className={classes.messageBody}
            />
          </div>
        </Link>
      </div>
      {(!lastItem) && <Divider className={classes.divider} />}
    </>
  )
}

function RecentConversations (props) {
  const classes = useStyles()
  const conversations = props.conversations

  if (!conversations) {
    return (
      <div className={classes.loadingDiv}>
        <Loader type='spinner' />
      </div>
    )
  } else if (!conversations.length) {
    return (
      <EmptyBox
        message='No conversations yet'
      />
    )
  }

  const conversationList = conversations

  return (
    <div>
      {conversationList.map((c, index) => (
        <Conversation
          key={c.id}
          conversation={c}
          index={index}
          count={conversationList.length}
        />
      ))}
    </div>
  )
}

function RecentConversationsCard (props) {
  return (
    <DashboardCard
      title='Recent Conversations'
      childOverflow
    >
      <div style={{ overflowY: 'auto' }}>
        <RecentConversations
          conversations={props.conversations}
        />
      </div>
    </DashboardCard>
  )
}

function MyRecentConversationsCard (props) {
  const [conversations, setConversations] = useState(null)

  useEffect(() => {
    getMyRecentConversations()
      .then(response => {
        setConversations(response.data)
      })
  }, [])

  return (
    <RecentConversationsCard
      conversations={conversations}
    />
  )
}

export { MyRecentConversationsCard, RecentConversationsCard }
