import { ContactList } from 'classes/classes';
import { Icon } from 'library/materialUI';

export function IntegrationBodyRow (props: any): JSX.Element {
  let integration = props.integration
  if (props.integration === 'sendinblue') {
    integration = 'brevo'
  }
  const searchParams = {
    extraHeaders: { 'x-integration': integration }
  }
  const { data, isLoading } = ContactList.loadAll({ searchParams })

  const list = data.list.filter((row: any) => props.listIDs.includes(row.id))

  return (
    <div>
      {isLoading
        ? <></>
        : (
          <div style={{ display: 'flex' }}>
            <div style={{ borderRadius: 5 }}><Icon icon={integration} /></div>
            <div style={{ marginLeft: '10px' }}>
              <div style={{ font: 'normal normal normal 13px/20px Poppins' }}>{integration.charAt(0).toUpperCase() + integration.slice(1)}</div>
              <b><i>{(list.length !== 0) ? list?.map((item: any) => item.name).join(' & ') : undefined}</i></b>
            </div>
          </div>)}
    </div>
  )
}
