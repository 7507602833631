function dragEdgeToSpace (source, left, top, originalEvent, setNodeData, toolkit) {
  const clientTop = originalEvent.clientY
  const clientLeft = originalEvent.clientX
  const anchorDiv = document.createElement('div')
  anchorDiv.id = 'popover-anchor-div'
  anchorDiv.style.cssText = 'position:fixed; top: ' + clientTop + 'px; left: ' + clientLeft + 'px;'
  document.body.appendChild(anchorDiv)

  const sourceNodeID = source.offsetParent.getAttribute('data-jtk-node-id')
  const portID = source.getAttribute('data-port-id')
  const pid = sourceNodeID + '.' + portID
  const sourcePort = toolkit.getPort(pid)

  setNodeData({
    anchorEl: anchorDiv,
    source: sourcePort,
    left,
    top
  })
}

function handleConnectionAborted (source, left, top, originalEvent, setNodeData, toolkit) {
  setTimeout(() => {
    const element = document.elementFromPoint(originalEvent.clientX, originalEvent.clientY)
    if (element.className === 'jtk-surface') {
      dragEdgeToSpace(source, left, top, originalEvent, setNodeData, toolkit)
    }
  }, 10)
}

function initializeSurface (surface, setNodeData, toolkit, hideSelectionMenu) {
  surface.bind('connectionAborted', (info, originalEvent) => {
    handleConnectionAborted(info.source, info.target.offsetLeft, info.target.offsetTop, originalEvent, setNodeData, toolkit)
  })
  surface.bind('canvasClick', (e) => {
    toolkit.clearSelection()
    document.activeElement.blur()
    hideSelectionMenu()
  })
}

function builderTabSelected () {
  const pages = ['audience', 'settings', 'details', 'traversal']

  for (const page of pages) {
    if (window.location.hash.includes(page)) {
      return false
    }
  }

  return true
}

export { initializeSurface, builderTabSelected }
