import { useDoQuery } from './useDoQuery'
import { GenericObject } from './genericObject'
import { LoadAllProps } from './queryHelpers'

interface AccountOwnerFieldList {
  list: AccountOwnerField[]
  dict: { [id: AccountOwnerField['id']]: AccountOwnerField }
}

const initialState = {
  label: '',
  default: false
}

export class AccountOwnerField extends GenericObject {
  name: string
  default: boolean

  static loadAll (props?: LoadAllProps): { data: AccountOwnerFieldList, isLoading: boolean, isError: boolean, error: Error } {
    const { data, isLoading, isError, error } = useDoQuery({ path: '/api/integrations/crm/accounts/fields/user/options', objectClass: AccountOwnerField })
    let searchLeads = false
    if (props?.searchParams?.extraHeaders) {
      searchLeads = props.searchParams.extraHeaders['x-search-type'] === 'lead'
    }
    if ((isLoading || searchLeads) && !isError) {
      return { data, isLoading, isError, error }
    }
    let filtered = []
    if (!isError) {
      filtered = data.list.filter((item: any) => !item.name.startsWith('lead.'))
    }
    return {
      data: {
        list: filtered,
        dict: data?.dict || {}
      },
      isLoading,
      isError,
      error
    }
  }

  constructor ({ row }: { row?: Record<string, any> }) {
    super({ row })
    const accountType = row?.attributes || initialState
    this.name = accountType.label
    this.default = accountType.default
  }
}
