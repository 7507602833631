import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { TableCell } from '@material-ui/core'
import { Link } from 'react-router-dom'
import EnhancedTable from '../../../../components/EnhancedTable'

const useStyles = makeStyles(theme => ({
  percent: {
    color: 'rgba(0,0,0,0.5)',
    paddingTop: 2
  },
  botName: {
    fontSize: '1.1em',
    fontWeight: 600,
    textDecoration: 'none',
    color: 'rgba(0,0,0,0.8)'
  },
  link: {
    textDecoration: 'none'
  }
}))

const headCells = [
  { id: 'name', align: 'left', disablePadding: false, label: 'Email Name' },
  { id: 'sent', align: 'center', disablePadding: false, label: 'Sent', paddingLeft: 30 },
  { id: 'delivered', align: 'center', disablePadding: false, label: 'Delivered', paddingLeft: 30 },
  { id: 'opened', align: 'center', disablePadding: false, label: 'Opened', paddingLeft: 30 },
  { id: 'clicked', align: 'center', disablePadding: false, label: 'Clicked', paddingLeft: 30 }
]

function EmailPerformanceTable (props) {
  const rows = props.rows.map(row => {
    return (
      {
        id: row.id,
        name: row.name,
        sent: row.sent,
        delivered: row.delivered,
        opened: row.opened,
        clicked: row.clicked
      })
  })

  function CustomRow (props) {
    const classes = useStyles()
    const row = props.row
    const deliveredPercent = (Math.round((row.delivered / row.sent) * 100) || 0) + '%'
    const openedPercent = (Math.round((row.opened / row.delivered) * 100) || 0) + '%'
    const clickedPercent = (Math.round((row.clicked / row.opened) * 100) || 0) + '%'

    return (
      <>
        <TableCell>
          <Link to={`/emails/edit/${row.id}`} className={classes.link}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div>
                <div style={{ fontSize: '1.1em', color: 'rgba(0,0,0,0.8)' }}>
                  {row.name}
                </div>
                <div style={{ fontSize: '0.9em', color: 'rgba(0,0,0,0.5)' }}>
                  {row.type}
                </div>
              </div>
            </div>
          </Link>
        </TableCell>
        <TableCell align='center' style={{}}>
          <div>{row.sent}</div>
          <div className={classes.percent}>---</div>
        </TableCell>
        <TableCell align='center'>
          <div>{row.delivered}</div>
          <div className={classes.percent}>
            {deliveredPercent}
          </div>
        </TableCell>
        <TableCell align='center'>
          <div>{row.opened}</div>
          <div className={classes.percent}>
            {openedPercent}
          </div>
        </TableCell>
        <TableCell align='center'>
          <div>{row.clicked}</div>
          <div className={classes.percent}>
            {clickedPercent}
          </div>
        </TableCell>
      </>
    )
  }

  function columnWidths () {
    return (
      <colgroup>
        <col style={{ width: '5%' }} />
        <col style={{ width: '23%' }} />
        <col style={{ width: '18%' }} />
        <col style={{ width: '18%' }} />
        <col style={{ width: '18%' }} />
        <col style={{ width: '18%' }} />
      </colgroup>
    )
  }

  return (
    <EnhancedTable
      headCells={headCells}
      selected={props.selected}
      setSelected={props.setSelected}
      rows={rows}
      CustomRow={CustomRow}
      columnWidths={columnWidths}
      orderBy='prompts'
      order='desc'
      loading={props.loading}
    />
  )
}

export default EmailPerformanceTable
