import { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Card, CardContent, IconButton, MenuItem } from '@material-ui/core'
import { patchBotTest } from 'api/bot_tests'
import StatusIcon from '@material-ui/icons/FiberManualRecord'
import dateFormat from 'dateformat'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import PauseTestIcon from '@material-ui/icons/PauseCircleFilled'
import CancelTestIcon from '@material-ui/icons/Cancel'
import StartTestIcon from '@material-ui/icons/PlayCircleOutline'
import Popover from '@material-ui/core/Popover'
import { useQueryClient } from 'react-query'
import PublishIcon from '@material-ui/icons/Publish'

const useStyles = makeStyles(theme => ({
  detailsCard: {
    borderRadius: 12,
    border: '1px solid rgba(0,0,0,0.2)',
    width: 310,
    minWidth: 310
  },
  cardStatusDiv: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '16px'
  },
  cardDetailsDiv: {
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.65)',
    padding: 5
  },
  sectionHeader: {
    fontWeight: 600,
    color: 'rgba(0, 0, 0, 0.75)'
  },
  cardSection: {
    marginTop: 8
  },
  expandMore: {
    marginLeft: 10,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  },
  actionMenuItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 170
  }
}))

const statusColors = {
  paused: 'gold',
  active: 'green',
  canceled: 'red',
  completed: '#8E5AE2',
  scheduled: 'orange'
}

const statusActions = {
  active: [
    { name: 'PAUSE TEST', status: 'paused', icon: PauseTestIcon, iconColor: 'gold' },
    { name: 'END TEST', status: 'canceled', icon: CancelTestIcon, iconColor: 'rgb(240, 112, 111)' }
  ],
  scheduled: [
    { name: 'START TEST', status: 'active', icon: StartTestIcon, iconColor: 'green' },
    { name: 'END TEST', status: 'canceled', icon: CancelTestIcon, iconColor: 'rgb(240, 112, 111)' }
  ],
  paused: [
    { name: 'RESUME TEST', status: 'active', icon: StartTestIcon, iconColor: 'green' },
    { name: 'END TEST', status: 'canceled', icon: CancelTestIcon, iconColor: 'rgb(240, 112, 111)' }
  ],
  canceled: [],
  completed: [],
  pending: []
}

function TestStatusPoppover (props) {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const chatServiceUrl = props.chatServiceUrl
  const queryClient = useQueryClient()

  const handleClick = (event) => {
    const el = document.getElementById('status-icon')
    setAnchorEl(el)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  function updateTestStatus (id, status) {
    const attributes = {
      status: status
    }
    if (status === 'active') {
      const startTime = new Date(props.test.attributes.start_time)
      const now = new Date()
      if (startTime > now) { attributes.start_time = now }
    }
    if (status === 'canceled') {
      props.showModal()
    } else {
      patchBotTest({ chatServiceUrl, id, attributes }).then(() => {
        queryClient.invalidateQueries('test' + id)
      })
    }
  }

  const open = Boolean(anchorEl)

  const actions = statusActions[props.status]

  if (props.status === 'pending') {
    return (
      <IconButton
        onClick={() => props.showModal()}
        style={{ padding: 0, marginLeft: 10 }}
      >
        <PublishIcon />
      </IconButton>
    )
  }

  if (!actions.length) {
    return <></>
  }

  return (
    <div className={classes.expandMore}>
      <IconButton onClick={handleClick} style={{ padding: 0 }}>
        <ExpandMoreIcon />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <div style={{ padding: '10px 0px' }}>
          {actions.map((action, index) => (
            <MenuItem
              key={index}
              className={classes.actionMenuItem}
              onClick={() => {
                updateTestStatus(props.test.id, action.status)
                handleClose()
              }}
            >
              {action.name}
              <action.icon style={{ fill: action.iconColor }} />
            </MenuItem>
          ))}
        </div>
      </Popover>
    </div>
  )
}

function TestStatus (props) {
  const classes = useStyles()
  const status = props.status
  const color = statusColors[status]

  let displayStatus = status.toUpperCase()
  if (status === 'pending') {
    displayStatus = 'CHOOSE A WINNER'
  } else if (status === 'canceled') {
    displayStatus = 'Ended'
  }

  return (
    <div className={classes.cardStatusDiv}>
      <StatusIcon style={{ marginRight: 5, marginTop: 2, fill: color }} id='status-icon' />
      <div id='status-div'>
        {displayStatus}
      </div>
      <TestStatusPoppover
        status={status}
        test={props.test}
        chatServiceUrl={props.chatServiceUrl}
        showModal={props.showModal}
      />
    </div>
  )
}

function getRecommendation (rows, status, originalFlowID) {
  if (status === 'completed') {
    if (rows.length > 1) {
      for (const row of rows) {
        const winProbability = row.attributes.win_probability
        if (winProbability > 0.95) {
          const variant = row.id === originalFlowID ? 'The original version' : 'The modified version'
          return variant + ' was selected as the winning bot for this test'
        }
      }
    }
  } else {
    if (rows.length > 1) {
      for (const row of rows) {
        const winProbability = row.attributes.win_probability
        if (winProbability > 0.95) {
          const variant = row.id === originalFlowID ? 'The original version' : 'The modified version'
          return variant + ' should be promoted'
        }
      }
    }
    return "We don't have enough data to select a winner"
  }
}

function TestDetailsCard (props) {
  const classes = useStyles()
  const test = props.test.attributes
  const status = props.status
  const originalFlowID = props.originalFlowID

  const endDate = new Date(test.end_time)
  const updateDate = new Date(test.updated_timestamp)
  const startDate = new Date(test.start_time)
  let startDisplayDate = dateFormat(startDate, 'mm/dd/yyyy')
  if (startDate > endDate) {
    startDisplayDate = '  ----'
  }

  const durationCriteria = test.duration_criteria
  let endTitle = 'END DATE'

  let endDisplayDate = dateFormat(endDate, 'mm/dd/yyyy')
  if (durationCriteria === 'time_based' && (status === 'active' || status === 'paused')) {
    endTitle = 'SCHEDULED EXPIRATION'
  } else if (durationCriteria === 'statistical_significance' && (status === 'active' || status === 'paused')) {
    endTitle = 'END CONDITION'
    endDisplayDate = 'Statistical significance'
  }

  if (durationCriteria === 'statistical_significance' && (status === 'completed' || status === 'pending')) {
    endDisplayDate = dateFormat(updateDate, 'mm/dd/yyyy')
  }

  let recommendationTitle = 'RECOMMENDATION: '
  let recommendation = getRecommendation(props.rows, props.status, originalFlowID)

  if (props.status === 'completed') {
    recommendationTitle = 'OUTCOME: '
  }

  if (test.winning_flow_id) {
    let winningVariant = 'The original version'
    if (test.winning_flow_id > originalFlowID) {
      winningVariant = 'The modified version'
    }
    recommendationTitle = 'OUTCOME: '
    recommendation = winningVariant + ' was selected as the winning bot for this test'
  }

  return (
    <Card className={classes.detailsCard}>
      <CardContent style={{ padding: 15 }}>
        <TestStatus
          test={props.test}
          chatServiceUrl={props.chatServiceUrl}
          showModal={props.showModal}
          status={props.status}
        />
        <div className={classes.cardDetailsDiv}>
          <div className={classes.cardSection}>
            <span className={classes.sectionHeader}>{recommendationTitle}</span> {recommendation}
          </div>
          <div className={classes.cardSection}>
            <span className={classes.sectionHeader}>START DATE:</span> {startDisplayDate}
          </div>
          <div className={classes.cardSection}>
            <span className={classes.sectionHeader}>{endTitle}:</span> {endDisplayDate}
          </div>
          <div className={classes.cardSection}>
            <span className={classes.sectionHeader}>DESCRIPTION:</span> {test.description}
          </div>
        </div>
      </CardContent>
    </Card>

  )
}

export default TestDetailsCard
