import { useState, useMemo } from 'react'
import Alert from 'classes/alerts'
import { Table, Actions, Fields } from 'cf-components/table/Table'
import EditAlertModal from './EditAlertModal'

export default function AlertsList ({ ...props }: {
  personal?: boolean
}): JSX.Element {
  const { data: alerts, isLoading } = Alert.loadAll(props.personal)
  const [editAlert, setEditAlert] = useState<Alert | null>(null)

  const fields: Fields<Alert> = useMemo(() => [
    { name: 'name', type: 'avatar', onClick: row => setEditAlert(row) },
    { name: 'description', type: 'text', label: 'Description' },
    { name: 'domain', type: 'text', label: 'Domain' },
    { name: 'createdTimestamp', type: 'createdAndEdited', label: 'Created Date' },
    { name: 'enabled', type: 'toggle', label: 'Enabled', handleToggle: ({ ID: id, value: enabled }) => Alert.save({ id, enabled }, ['enabled']) }
  ], [setEditAlert])

  const actions: Actions<Alert> = useMemo(() => [
    { type: 'edit', name: 'Edit Alert', action: row => setEditAlert(row) },
    { type: 'delete' }
  ], [setEditAlert])

  return (
    <>
      <Table
        rows={alerts.list}
        isLoading={isLoading}
        fields={fields}
        actions={actions}
        columnStyles='2fr 2fr 2fr 2fr 100px'
      />
      <EditAlertModal
        open={Boolean(editAlert)}
        onHide={() => setEditAlert(null)}
        alert={editAlert!}
        isGlobal
      />
    </>
  )
}
