import { getCompanyInfo } from 'api/company_profile'
import { Modal } from 'library/Modal'
import { useEffect, useState } from 'react'

interface AfterCancellationModalProps {
  open: boolean
  setOpen: (value: boolean) => void
  planName: string
  endDate: string
}

export default function AfterCancellationModal ({ open, setOpen, planName, endDate }: AfterCancellationModalProps) {
  const [companyInfo, setCompanyInfo] = useState('Your')

  useEffect(() => {
    if (open) {
      getCompanyInfo().then((res) => {
        if (res.data?.attributes?.company_name) {
          setCompanyInfo(res.data.attributes.company_name + ', your')
        }
      })
    }
  }, [open])

  return (
    <Modal
      open={open}
      title='Plan Cancelled'
      subtitle={`${companyInfo} subscription has been canceled and you will no longer be billed.\n You will continue to have access to ${planName} until the end of your current billing cycle on: ${endDate}.`}
      onHide={() => setOpen(false)}
      handleSave={() => setOpen(false)}
      hideSaveButton
      cancelBtnText='Close'
      noCloseBtn
    />
  )
}
