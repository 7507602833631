import { Button as MuiButton, ButtonProps as MuiButtonProps, Fab, Tooltip } from '@material-ui/core'
import { Icon, IconSizes, IconType } from './Icon'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  iconButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    borderRadius: '20px',
    padding: '5px',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.12)'
    }
  },
  closeButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    borderRadius: '20px',
    padding: '5px',
    marginRight: '10px',
    '&:hover': {
      backgroundColor: '#F0F0F0'
    }
  },
  actionButton: {
    color: 'white',
    backgroundColor: theme.palette.primary.alt,
    '&:hover': {
      backgroundColor: '#6941A1'
    }
  },
  button: {
    color: 'white',
    backgroundColor: theme.palette.primary.alt,
    '&:hover': {
      backgroundColor: '#6941A1'
    },
    '&.Mui-disabled': {
      '&:hover': {
        borderColor: '#E0E0E0'
      },
      pointerEvents: 'auto'
    }
  }
}))

interface ButtonProps extends MuiButtonProps {
  disabledToolTip?: string
}

export function Button ({ variant = 'contained', color = 'primary', ...props }: ButtonProps): JSX.Element {
  const classes = useStyles()
  if (props.disabledToolTip && props.disabled) {
    return (
      <Tooltip title={props.disabledToolTip}>
        <MuiButton
          variant={variant}
          color={color}
          {...props}
          classes={variant === 'contained' ? {
            root: classes.button
          } : undefined}
        />
      </Tooltip>
    )
  }
  return (
    <MuiButton
      variant={variant}
      color={color}
      {...props}
      classes={variant === 'contained' ? {
        root: classes.button
      } : undefined}
    />
  )
}

export type Action = {
  action: () => void
  icon?: IconType | ''
  label?: string
}

export type ActionButtonProps = {
  action: Action
  noBackground?: boolean
  tootltip?: string
  disabled?: boolean
}

export function ActionButton (props: ActionButtonProps): JSX.Element {
  const icon = props.action.icon || 'add'
  const classes = useStyles()
  const fabButton = (
    <Fab
      size='small'
      className={props.noBackground ? undefined : classes.actionButton}
      color={props.noBackground ? undefined : '#AA44FF'}
      onClick={props.action.action}
      disabled={props.disabled}
    >
      <Icon icon={icon} color={props.noBackground ? undefined : 'white'} />
    </Fab>
  )
  return (
    props.tootltip ? (
      <Tooltip title={props.tootltip}>
        <span>
          {fabButton}
        </span>
      </Tooltip>
    ) : (
      fabButton
    )
  )
}

export type IconButtonProps = {
  icon: IconType
  onClick: () => void
  toolTip?: string
  color?: string
  size?: IconSizes
}

export function IconButton (props: IconButtonProps): JSX.Element {
  const toolTip = props.toolTip || ''
  const classes = useStyles()
  const size = props.size || 'md'

  return (
    <Tooltip title={toolTip}>
      <div
        className={props.icon === 'close' ? classes.closeButton : classes.iconButton}
        onClick={props.onClick}
      >
        <Icon icon={props.icon} color={props.color || 'primary'} size={size} />
      </div>
    </Tooltip>
  )
}
