import { useContext, useReducer, useState, useEffect, useMemo } from 'react'
import { ConversationParametersContext } from '../conversation-parameters-context'
import { makeStyles } from '@material-ui/core/styles'
import { TenantDataContext } from '../tenant-data-context'
import { TextField, Select, Checkbox, ListItemText, ListSubheader, MenuItem, Button, Tooltip } from '@material-ui/core'
import Loader from 'library/loading/Loader'

const useStyles = makeStyles(theme => ({
  searchDrawer: {
    padding: 10
  },
  header: {
    color: '#656565',
    fontSize: '14px',
    paddingBottom: 5
  },
  searchOption: {
    marginBottom: 10
  },
  buttons: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: 10,
    paddingTop: 10
  }
}))

const initialState = {
  visitor: '',
  domain: '',
  source_domain: '',
  user_ids: [],
  bot_ids: [],
  tag_ids: [],
  events: [],
  status: undefined,
  review: undefined
}

const events = [
  { id: 'calendar_drop', name: 'Calendar Dropped' },
  { id: 'meeting_booked', name: 'Meeting Booked' },
  { id: 'email_captured', name: 'Email Captured' },
  { id: 'live_chat', name: 'Live Chat' }
]

const MenuProps = {
  getContentAnchorEl: null, // Prevents menu from jumping around (fixed in MUI v5, you can remove if we ever upgrade)
  PaperProps: {
    style: {
      maxHeight: 360,
      width: 240
    }
  }
}

const MultiMenuProps = {
  getContentAnchorEl: null,
  PaperProps: {
    style: {
      maxHeight: 360,
      maxWidth: 600
    }
  }
}

function MultiSelectSearchable (props) {
  const option = props.option
  const values = option.values
  const state = props.state
  const dispatch = props.dispatch
  const selectedValues = values.filter(v => state.includes(v.id)).map(v => v.name).join(', ')
  const [searchText, setSearchText] = useState('')
  const displayedValues = useMemo(
    () => values.filter((value) => {
      return value.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1 || state.indexOf(value.id) > -1
    }),
      [searchText, values, state]
  )

  return (
    <Select
      multiple
      value={state}
      onChange={(e) => dispatch({ kind: option.id, value: e.target.value })}
      onOpen={() => setSearchText('')}
      variant='outlined'
      fullWidth
      margin='dense'
      renderValue={() => { return (selectedValues) }}
      MenuProps={MultiMenuProps}
    >
      <ListSubheader style={{ background: 'white' }}>
        <TextField
          size='small'
          placeholder='Type to search...'
          fullWidth
          onChange={(e) => setSearchText(e.target.value)}
          onKeyDown={(e) => {
            if (e.key !== 'Escape') {
              // Prevents autoselecting item while typing (default Select behaviour)
              e.stopPropagation()
            }
          }}
        />
      </ListSubheader>
      <MenuItem value='' disabled><em>{option.name}</em></MenuItem>

      {displayedValues.map(v => (
        <MenuItem key={v.id} value={v.id}>
          <Checkbox
            checked={state.indexOf(v.id) > -1}
            color='primary'
            style={{ padding: 5 }}
          />
          {v.name.length > 40 ? (
            <Tooltip title={v.name} placement='right'>
              <ListItemText primary={v.name} />
            </Tooltip>) : (
              <ListItemText primary={v.name} />)}
        </MenuItem>
      ))}
    </Select>
  )
}

function MultiSelect (props) {
  const option = props.option
  const values = option.values
  const state = props.state
  const dispatch = props.dispatch
  const selectedValues = values.filter(v => state.includes(v.id)).map(v => v.name).join(', ')

  return (
    <Select
      multiple
      value={state}
      onChange={(e) => dispatch({ kind: option.id, value: e.target.value })}
      variant='outlined'
      fullWidth
      margin='dense'
      renderValue={() => { return (selectedValues) }}
      MenuProps={MenuProps}
    >
      <MenuItem value='' disabled><em>{option.name}</em></MenuItem>
      {values.map(v => (
        <MenuItem key={v.id} value={v.id}>
          <Checkbox
            checked={state.indexOf(v.id) > -1}
            color='primary'
            style={{ padding: 5 }}
          />
          <ListItemText primary={v.name} />
        </MenuItem>
      ))}
    </Select>
  )
}

function SingleSelect (props) {
  const option = props.option
  const values = option.values
  const state = props.state
  const dispatch = props.dispatch

  return (
    <Select
      value={state}
      onChange={(e) => dispatch({ kind: option.id, value: e.target.value })}
      variant='outlined'
      fullWidth
      margin='dense'
      MenuProps={MenuProps}
    >
      <MenuItem value={undefined}><em>Clear {option.name}</em></MenuItem>
      {values.map((option, index) => (
        <MenuItem key={index} value={option.value}>
          <ListItemText primary={option.name} />
        </MenuItem>
      ))}
    </Select>
  )
}

function SearchBox (props) {
  const option = props.option
  const dispatch = props.dispatch

  return (
    <TextField
      variant='outlined'
      margin='dense'
      fullWidth
      style={{ marginTop: 0 }}
      value={props.state}
      onChange={(e) => dispatch({ kind: option.id, value: e.target.value })}
    />
  )
}

const mapping = {
  multiselectsearch: MultiSelectSearchable,
  multiselect: MultiSelect,
  search: SearchBox,
  select: SingleSelect
}

function SearchOption (props) {
  const classes = useStyles()
  const option = props.option
  const Component = mapping[option.type]
  return (
    <div className={classes.searchOption}>
      <div className={classes.header}>
        {option.name}
      </div>
      <div style={{ width: 240 }}>
        <Component
          option={option}
          state={props.state}
          dispatch={props.dispatch}
        />
      </div>
    </div>
  )
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'reinitialize':
      return initialState
    default:
      return {
        ...state,
        [action.kind]: action.value
      }
  }
}

export default function SearchMode (props) {
  const classes = useStyles()
  const { bots, users, tags } = useContext(TenantDataContext)
  const { parameters } = useContext(ConversationParametersContext)
  const [state, dispatch] = useReducer(reducer, parameters?.state?.conversationFilters?.values?.displayName === 'Custom Search' ? parameters.state.conversationFilters.values.filter : initialState)
  const [updateSearch, setUpdateSearch] = useState(false)

  const userList = users ? users.list.map(u => ({ id: u.id, name: u.name })) : []
  const botList = bots ? bots.map(b => ({ id: b.id, name: b.attributes.name })) : []
  const tagList = tags ? tags.map(t => ({ id: t.id, name: t.attributes.tag_name })) : []
  const reviewOptions = [
    { name: 'Reviewed', value: true },
    { name: 'Needs review', value: false }
  ]
  const statusOptions = [
    { name: 'Open', value: 'open' },
    { name: 'Closed', value: 'closed' }
  ]

  const searchOptions = [
    { id: 'visitor', name: 'Visitor name or email', type: 'search' },
    { id: 'domain', name: 'Visitor domain', type: 'search' },
    { id: 'source_domain', name: 'Source domain', type: 'search' },
    { id: 'user_ids', name: 'Agents', type: 'multiselectsearch', values: userList },
    { id: 'bot_ids', name: 'Bots', type: 'multiselectsearch', values: botList },
    { id: 'tag_ids', name: 'Tags', type: 'multiselectsearch', values: tagList },
    { id: 'events', name: 'Events', type: 'multiselect', values: events },
    { id: 'status', name: 'Conversation Status', type: 'select', values: statusOptions },
    { id: 'review', name: 'Review Status', type: 'select', values: reviewOptions }
  ]

  const handleSearch = () => {
    const value = state
    const kind = 'search'
    const displayName = 'Custom Search'
    parameters.setFilter(value, kind, displayName)
  }

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (updateSearch) {
      handleSearch()
      setUpdateSearch(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateSearch])

  useEffect(() => {
    setLoading(false)
  }, [state])

  return (
    <div className={classes.searchDrawer}>
      <div>
        {searchOptions.map((option, index) => (
          <SearchOption
            key={index}
            option={option}
            state={state[option.id]}
            dispatch={dispatch}
          />
        ))}
      </div>
      <div className={classes.buttons}>
        <Button
          variant='outlined'
          onClick={() => {
            dispatch({ type: 'reinitialize' })
            setUpdateSearch(true)
          }}
        >
          Clear
        </Button>
        {loading ? (
          <div style={{ color: 'white' }}>
            <Loader />
          </div>
        ) : (
          <Button
            variant='contained'
            color='primary'
            onClick={handleSearch}
          >
            Search
          </Button>
        )}
      </div>
    </div>
  )
}
