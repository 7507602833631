import { Icon } from 'library/materialUI';
import { ModalSection } from 'library/Modal';
import CSVDropZone from 'library/segments/CSVDropZone';
import { useMemo } from 'react';
import { CSVFile } from './CSVFile';

interface CSVUploadStepProps {
  CSVFile: CSVFile
  setCSVFile: ({ file }: { file?: File}) => void
  setDisableNextBtn: (bool: boolean) => void
  infoMessage?: string
  subtitle?: string
  extraInfo?: string
}

export default function CSVUploadStep ({ CSVFile, setCSVFile, setDisableNextBtn, infoMessage, subtitle, extraInfo }: CSVUploadStepProps) {
  const file = CSVFile.file
  const message = infoMessage || 'Make sure to double check your CSV before continuing'

  useMemo(() => {
    if (file) {
      setDisableNextBtn(false)
    } else {
      setDisableNextBtn(true)
    }
  }, [file, setDisableNextBtn])

  return (
    <ModalSection
      title='Upload a CSV' subtitle={subtitle || 'Upload a CSV containing the necessary data for your operation'}
    >
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div style={{ display: 'flex', alignItems: 'center', width: '97%', backgroundColor: 'rgba(153, 51, 255, 0.15)', padding: 5 }}>
          <div style={{ display: 'flex', margin: '0px 5px' }}><Icon icon='info' color='primary' size='sm' /></div>
          <div style={{ color: '#985ce6', textAlign: 'left', letterSpacing: 0, font: 'normal normal normal 12px/18px Poppins' }}>{message}</div>
        </div>
      </div>
      <CSVDropZone
        CSVFile={file as File}
        handleDrop={(file:any) => { setCSVFile({ file: file }) }}
      />
      {extraInfo && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div style={{ display: 'flex', width: '97%' }}>
            <div style={{ display: 'flex', margin: '0px 5px' }}><Icon icon='lightBulb' size='sm' /></div>
            <div style={{ color: '#00000080', textAlign: 'left', letterSpacing: 0, font: 'normal normal normal 12px/18px Poppins' }}>{extraInfo}</div>
          </div>
        </div>
      )}
    </ModalSection>
  )
}
