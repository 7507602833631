
const descriptionText = 'Use this bot template specifically for people who have already been to your site and interacted with a bot.'

const Description = (): JSX.Element => {
  return (
    <div>
      <div>
        {descriptionText}
      </div>
      <ul>
        <li>Welcomes the visitor back</li>
        <li>Asks the visitor if they are ready to book a demo with the following responses:</li>
        <ul>
          <li>Definitely</li>
          <ul>
            <li>Asks qualifying questions to determine fit</li>
            <li>If the visitor is a good fit for your company, the bot sends the visitor a calendar invite</li>
          </ul>
          <li>Nah</li>
          <ul>
            <li>Asks a different question to prompt the user to:</li>
            <ul>
              <li>book a meeting (reroute to the qualifying questions)</li>
              <li>chat with an agent (if available)</li>
              <li>leave a message (asks for the visitor's email)</li>
            </ul>
          </ul>
        </ul>
      </ul>
    </div>
  )
}

const bestPracticeText = 'Based upon your specific needs and desires this template may be customized to enhance its effectiveness when interacting with repeat visitors. Some examples include:'

const BestPractices = () => {
  return (
    <div>
      <div>
        {bestPracticeText}
      </div>
      <ul>
        <li>Add an alert agent node to the beginning of the bot flow</li>
        <li>Add additional answers to questions in order to customize the user experience</li>
        <li>Allow live chat routing earlier in the bot flow</li>
      </ul>
    </div>
  )
}

export const retargetingBotDocs = {
  helpCenterLink: '',
  bestPractices: BestPractices,
  description: Description,
  videoLink: ''
}
