import { AccessTime } from '@material-ui/icons'
import { KeyboardTimePicker } from '@material-ui/pickers'
import { Icon } from 'library/materialUI'
import { useState } from 'react'

type SalesloftReminderProps = {
    reminder: any
    setReminder: (value: any) => void
    dueDate: string
}

/**
 * Currently this is very dependant on the a due date that will be passed into this function
 * @param props Properties sent in from parent salesloft add task function. Make sure that function creates a due date
 * @returns Reminder jsx element
 */
export default function SalesloftReminder (props: SalesloftReminderProps): JSX.Element {
  const [editMode, setEditMode] = useState(false)
  if (editMode) {
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <KeyboardTimePicker
            value={props.reminder}
            onChange={e => { props.setReminder({ remindAt: e?.toISOString() }) }}
            keyboardIcon={<AccessTime />}
            autoOk
          />
          <div
            style={{ display: 'flex', cursor: 'pointer' }}
            onClick={() => {
              props.setReminder({ remindAt: '' })
              setEditMode(false)
            }}
          >
            <Icon icon='cancel' color='#9933FF' />
          </div>
        </div>
      )
  }
  return (
    <div
      style={{ display: 'flex', cursor: 'pointer' }}
      onClick={() => {
      const date = new Date(props.dueDate)
      date.setHours(8, 0, 0, 0)
      props.setReminder({ remindAt: date.toISOString() })
      setEditMode(true)
      }}
    >
      <Icon icon='notification' color='#9933FF' />
      <div style={{ color: '#9933FF', marginLeft: 7 }}>Set Task Reminder</div>
    </div>
  )
}
