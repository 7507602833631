import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'

export function CreateSparkLineChart ({ data, id }: any) {
    am4core.addLicense('CH213476655')
    am4core.useTheme(am4themes_animated)

    const chart = am4core.create(id, am4charts.XYChart);
    chart.padding(0, 0, 0, 0);
    chart.data = data

    const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.disabled = true
    dateAxis.renderer.labels.template.disabled = true
    dateAxis.cursorTooltipEnabled = false

    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.grid.template.disabled = true
    valueAxis.renderer.baseGrid.disabled = true
    valueAxis.renderer.labels.template.disabled = true

    // Create series
    const series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = 'value'
    series.dataFields.dateX = 'date'
    series.strokeWidth = 2
    series.tensionX = 0.8
    series.tooltipText = '{valueY}'

    const bullet = series.bullets.push(new am4charts.CircleBullet());
    bullet.circle.opacity = 0
    bullet.circle.propertyFields.opacity = 'opacity'
    bullet.circle.radius = 3
}
