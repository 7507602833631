/* global CronofyElements */

import { track } from 'userpilot'

function loadCronofyWidgets ({ cronofyStatus, disconnectCalendar, availabilityCallback }) {
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone
  let element_token
  if (cronofyStatus && cronofyStatus.attributes.element_token) {
    element_token = cronofyStatus.attributes.element_token
  }
  let auth_config
  if (cronofyStatus) {
    auth_config = cronofyStatus.attributes.auth_config
  }
  CronofyElements.CalendarSync({
    element_token: element_token,
    target_id: 'cronofy-calendar-sync',
    authorization: auth_config,
    styles: {
      prefix: 'CalendarSync'
    },
    callback: notification => {
      disconnectCalendar()
    },
    single_profile: true
  })

  if (cronofyStatus.attributes.active) {
    CronofyElements.AvailabilityRules({
      element_token: element_token,
      target_id: 'cronofy-availability-rules',
      availability_rule_id: '',
      config: {
        start_time: '08:00',
        end_time: '18:00',
        duration: 30,
        logs: 'error'
      },
      styles: {
        colors: {
          available: '#285C05',
          unavailable: '#F0F0F0',
          hairline: '#777777'
        },
        prefix: 'custom-name'
      },
      tzid: tz,
      callback: (e) => {
        e?.notification?.type === 'availability_rule_saved' && track('Calendar Availability Saved')
        availabilityCallback && availabilityCallback(e)
      }
    })
  }
}

export { loadCronofyWidgets }
