import { FilterComponentProps } from 'library/table/FilterButton'

import { useState } from 'react'
import { Checkbox, Icon, LabeledCheckbox, Tooltip, Typography } from 'library/materialUI'
import { Sequence } from 'classes/sequences'
import Form from 'classes/forms'
import TreeSelect from 'library/filter/TreeSelect'
import Persona from 'classes/personas'
import { DateAddedFilter, DateAddedFilterValue } from 'library/table/tableHelpers'
import { FilterOptions } from 'library/table/FilterOption'

export const fromProspectIcon = (
  <Tooltip
    title='Contact came from Contact Lookup'
  >
    <div style={{ backgroundColor: '#49CDA2', borderRadius: 20, height: 25, width: 25, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Icon icon='contactLookup' color='white' size='sm' />
    </div>
  </Tooltip>
)

export const organicIcon = (
  <Tooltip
    title='Contact filled out a form or self-identified to a chatbot'
  >
    <div style={{ backgroundColor: '#3CA9FD', borderRadius: 20, height: 25, width: 25, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Icon icon='organicPerson' color='white' size='sm' />
    </div>
  </Tooltip>
)

export const iqlIcon = (
  <Tooltip
    title='Contact clicked through from a tracked email link'
  >
    <div>
      <Icon icon='iql' size='sm' />
    </div>
  </Tooltip>
)

function LeadScoreCheckbox (props: any) {
  const scoreArray = Array.from(Array(props.score).keys())

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Checkbox
        checked={props.selected}
        onChange={props.onChange}
      />
      {scoreArray.map((value, index) => (
        <Icon icon='star' key={index} color='#FFDB3B' />
      ))}
    </div>
  )
}

function LeadScoreFilter (props: FilterComponentProps) {
  const [selected, setSelected] = useState(props.initialValues || [])

  const toggleSelection = (value: any) => {
    if (selected.includes(value)) {
      setSelected([...selected].filter(s => s !== value))
    } else {
      setSelected([...selected, value])
    }
  }

  props.filterRef.current = selected.length ? { field: 'lead_score', operator: 'in', value: selected } : null

  return (
    <div>
      {[3, 2, 1].map((value, index) => (
        <LeadScoreCheckbox
          score={value}
          key={index}
          selected={selected.includes(value)}
          onChange={() => toggleSelection(value)}
        />
      ))}
    </div>
  )
}

function LeadScoreFilterValue (value: number) {
  const starIcon = <Icon icon='star' color='gold' />
  return Array(value).fill(starIcon)
}

function ContactTypeFilterValue (value: string) {
  return value
}

function ContactTypeFilter (props: FilterComponentProps) {
  const [selected, setSelected] = useState(props.initialValues || [])
  const operator = selected.length === 1 ? 'eq' : 'in'
  props.filterRef.current = selected.length ? { field: 'source', operator: operator, value: selected } : null

  const handleCheck = (checked: boolean, type: string) => {
    if (checked) {
      if (!selected.includes(type)) {
        if (type === 'organic') {
          setSelected([...selected, type, 'form'])
        } else {
          setSelected([...selected, type])
        }
      }
    } else {
      setSelected(selected.filter((item: any) => item !== type))
    }
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <LabeledCheckbox
        label={<div style={{ display: 'flex' }}>{fromProspectIcon}<Typography style={{ paddingLeft: 10 }}>Prospected</Typography></div>}
        onChange={(checked: boolean) => {
          handleCheck(checked, 'play')
          handleCheck(checked, 'prospect')
        }}
        checked={selected.includes('prospect')}
      />
      <LabeledCheckbox
        label={<div style={{ display: 'flex' }}>{organicIcon}<Typography style={{ paddingLeft: 10 }}>Organically Collected</Typography></div>}
        onChange={(checked: boolean) => {
          handleCheck(checked, 'organic')
        }}
        checked={selected.includes('organic')}
      />
      <LabeledCheckbox
        label={<div style={{ display: 'flex' }}>{iqlIcon}<Typography style={{ paddingLeft: 10 }}>Intent-Qualified Leads</Typography></div>}
        onChange={(checked: boolean) => handleCheck(checked, 'iql')}
        checked={selected.includes('iql')}
      />
    </div>
  )
}

function BuyingComitteeFilter (props: FilterComponentProps) {
  const [selected, setSelected] = useState(props.initialValues || [])
  props.filterRef.current = selected.length ? { field: 'persona_id', operator: 'in', value: selected } : null

  return (
    <TreeSelect
      objectType='personas'
      selection={selected}
      setSelection={(values) => setSelected(values)}
    />
  )
}

function BuyingComitteeTransformer (values: number[]) {
  const { data } = Persona.loadAll()
  return values.map((value, k) => (<div key={k}>{data.dict[value]?.name}</div>))
}

function OriginFormFilter (props: FilterComponentProps) {
  const [selected, setSelected] = useState(props.initialValues || [])
  props.filterRef.current = selected.length ? { field: 'form_id', operator: 'in', value: selected } : null

  return (
    <TreeSelect
      objectType='forms'
      selection={selected}
      setSelection={setSelected}
    />
  )
}

function OriginPlayFilter (props: FilterComponentProps) {
  const [selected, setSelected] = useState(props.initialValues || [])
  props.filterRef.current = selected.length ? { field: 'play_id', operator: 'in', value: selected } : null

  return (
    <TreeSelect
      objectType='sequences'
      selection={selected}
      setSelection={setSelected}
    />
  )
}

function OriginPlayTransformer (values: number[]) {
  const data = Sequence.loadAll()
  return values.map((value, k) => (<div key={k}>{data.data.dict[value]?.name}</div>))
}

function OriginFormTransformer (values: number[]) {
  const data = Form.loadAll()
  return values.map((value, k) => (<div key={k}>{data.data.dict[value]?.name}</div>))
}

export const filterOptions: FilterOptions = {
  createdTimestamp: { title: 'Date Added', menu: DateAddedFilter, field: 'created_timestamp', filterValue: DateAddedFilterValue },
  playID: { title: 'Origin Play', menu: OriginPlayFilter, field: 'play_id', transformer: OriginPlayTransformer },
  formID: { title: 'Origin Form', menu: OriginFormFilter, field: 'form_id', transformer: OriginFormTransformer },
  leadScore: { title: 'Lead Score', menu: LeadScoreFilter, field: 'lead_score', filterValue: LeadScoreFilterValue },
  contactType: { title: 'Contact Type', menu: ContactTypeFilter, field: 'contact_type', filterValue: ContactTypeFilterValue },
  title: { title: 'Buying Committee', menu: BuyingComitteeFilter, field: 'persona_id', transformer: BuyingComitteeTransformer }
}
