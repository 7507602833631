import { nodeMappingData } from 'library/flowBuilder/FlowBuilder'
import { ModalContentsProps } from 'library/flowBuilder/nodes/NodeModal'
import { NotificationSettings } from 'library/routing/NotificationSettings'
import { routeOptionsSchema, RoutingSelector } from 'library/routing/RoutingCriteria'
import * as yup from 'yup'
import { categoryMapping } from '../nodeCategories'

export const AlertNodeSchema = yup.object().shape({
  settings: yup.object({
    routeOptions: routeOptionsSchema,
    notification: yup.object({
      title: yup.string().min(1),
      description: yup.string().min(1)
    })
  })
})

export function AlertModal ({ settings, updateSettings }: ModalContentsProps): JSX.Element {
  return (
    <div>
      <RoutingSelector
        routeOptions={settings.routeOptions}
        handleChange={(routeOptions: any) => updateSettings({ routeOptions })}
        routeTypeOptions
        alertAgent
      />
      <NotificationSettings
        notification={settings.notification}
        handleChange={(notification: any) => updateSettings({ notification })}
      />
    </div>
  )
}

export function AlertNodeBody ({ settings }: ModalContentsProps) : JSX.Element {
  const alertTitle = settings.notification.title

  return (
    <div>
      {alertTitle}
    </div>
  )
}

export const alertMapping: nodeMappingData = {
  icon: 'notification',
  title: 'Alert Agent',
  modal: AlertModal,
  body: AlertNodeBody,
  schema: AlertNodeSchema,
  helpCenterLink: 'home/plays/skills/AlertAgent',
  ...categoryMapping.internal
}
