import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import useInterval from 'use-interval'

const useStyles = makeStyles(theme => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: '#FFF'
  },
  title: {
    backgroundColor: theme.palette.primary.main,
    color: 'white'
  },
  twoButtonFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 14
  }
}))

function Modal (props) {
  const classes = useStyles()
  const [expiration, setExpiration] = useState(null)
  const [lastTimestamp, setLastTimestamp] = useState(null)

  function closeModal () {
    props.onHide()
  }

  useEffect(() => {
    if (props.open) {
      const expiration = new Date()
      expiration.setSeconds(expiration.getSeconds() + 60)
      setExpiration(expiration)
      setLastTimestamp(new Date())
    } else {
      setExpiration(null)
      setLastTimestamp(null)
    }
  }, [props.open])

  useInterval(() => {
    if (expiration) {
      if (expiration - lastTimestamp < 1000) {
        props.logOut()
      } else {
        const timestamp = new Date()
        setLastTimestamp(timestamp)
      }
    }
  }, 1000)

  function refresh () {
    props.refreshSession()
    closeModal()
  }

  const seconds = Math.floor((expiration - lastTimestamp) / 1000)

  const message = 'Your session will expire in ' + seconds + ' seconds due to inactivity. Do you want to extend the session?'

  if (!props.open) {
    return <></>
  }

  return (
    <Dialog
      onClose={props.onHide}
      open={props.open}
      fullWidth
      maxWidth='xs'
    >
      <MuiDialogTitle className={classes.title}>
        Session Timeout
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={closeModal}
        >
          <CloseIcon />
        </IconButton>
      </MuiDialogTitle>
      <MuiDialogContent dividers style={props.noPadding ? { padding: 0 } : null}>
        <div>
          {message}
        </div>
      </MuiDialogContent>
      <div className={classes.twoButtonFooter}>
        <Button
          color='primary'
          size='medium'
          onClick={props.logOut}
        >
          Log Off
        </Button>
        <Button
          variant='contained'
          type='submit'
          color='primary'
          size='medium'
          onClick={refresh}
        >
          Stay Logged In
        </Button>
      </div>
    </Dialog>
  )
}

export default Modal
