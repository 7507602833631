import { doPatch, doPost } from 'api/api';
import GenericObject from './genericObject';
import { useDoQuery } from './useDoQuery';

export class MeetingSettings extends GenericObject {
  bufferTime: number
  leadTime: number
  conferencingType: string
  locationURL: string
  userID: number
  meetNowUrl: string

  static load ({ userID }: { userID: number}): { data: MeetingSettings, isLoading: boolean } {
    return useDoQuery({ path: `/meeting_info/${userID}`, objectClass: MeetingSettings, useChatURL: true })
  }

  static save (userID: number, updates: Partial<MeetingSettings>) {
    const path = `/meeting_info/${userID}`
    const data = {
      type: 'meeting_settings',
      id: userID,
      attributes: updates
    }
    if (updates.id) {
      return doPatch({ path, data, useChatURL: true })
    } else {
      return doPost({ path, data, useChatURL: true })
    }
  }

  constructor ({ row }: { row: Record<string, any> }) {
    super({ row })
    const attributes = row.attributes
    this.bufferTime = attributes.buffer_time
    this.conferencingType = attributes.conferencing_type
    this.leadTime = attributes.lead_time
    this.locationURL = attributes.location_url
    this.userID = attributes.user_id
    this.meetNowUrl = attributes.meet_now_url
  }
}
