import AppPage from 'cf-components/AppPage'
import Paper from 'library/page/PageSection'
import { useState, useEffect, useReducer } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import DragNDrop from 'cf-components/DragNDrop'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import { Typography } from '@material-ui/core'
import { RouteOption, route } from 'library/routing/RoutingCriteria'
import Button from '@material-ui/core/Button'
import { RoutingRules } from 'classes/routingRules'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import ControlPointIcon from '@material-ui/icons/ControlPoint'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import MessageDiv from 'library/divs/MessageDiv'
import Loader from 'library/loading/Loader'
import GlobalRoutingRulesModal, { routingReducer } from './GlobalRoutingRulesModal'
import { Checkbox } from 'library/materialUI'

const useStyles = makeStyles(theme => ({
  rowHeader: {
    paddingBottom: 20,
    fontSize: 16,
    display: 'grid',
    gridTemplateColumns: '48px 1.75fr 1fr auto auto 50px',
    gridGap: 10,
    gridTemplateAreas: '"a segment b teamuser c delete"',
    maxWidth: 1100
  },
  dottedLine: {
    borderTop: '2px dotted #975ce6',
    height: 10,
    marginTop: 20,
    width: '100%'
  }
}))

const RoutingRuleComponent = withStyles((theme: any) => ({
  grabbable: {
    cursor: 'grab'
  },
  dragHeader: {
    borderTop: '1px solid #dadada',
    padding: 20,
    fontWeight: 'bold'
  },
  routeItemX: {
    display: 'inline-block',
    verticalAlign: 'top'
  },
  routeItem: {
    display: 'inline-block',
    verticalAlign: 'middle'
  },
  ruleChip: {
    backgroundColor: '#E0E0E0',
    color: '#000000BF',
    marginRight: 10,
    marginBottom: 10,
    height: '32px',
    borderRadius: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))(
  function (props: any) {
    const classes = useStyles()
    const header = props.item.id === '0' ? 'First by:' : 'Then by:'

    return (
      <div className='no-focus-outline'>
        <div className={props.classes.dragHeader}>
          {header}
        </div>
        <div className={classes.rowHeader}>
          <DragIndicatorIcon
            className={props.classes.grabbable}
            color='primary'
          />
          <div style={{ gridArea: 'segment' }}>
            <div
              onClick={() => {
                props.setModalOpen(true);
                props.setRuleID(props?.item?.rules[0]?.id)
                props.setRuleName(props?.item?.rules[0]?.name)
              }}
              className={props.classes.ruleChip}
            >
              {props?.item?.rules[0]?.name}
            </div>
          </div>
          <div className={classes.dottedLine} />
          <div style={{ display: 'inline-block', verticalAlign: 'top' }}>
            {props.item.rules.map((rule: route, index: number) => {
              return (
                <div key={index} style={{ whiteSpace: 'nowrap', gridArea: 'teamuser' }}>
                  <div>
                    {index > 0 && (
                      <Typography variant='caption' style={{ display: 'flex', marginBottom: 5, marginTop: 5, color: 'gray' }}>Then</Typography>
                    )}
                    <div className={props.classes.routeItem}>
                      <RouteOption
                        exclude={['group', 'last_routed', 'global_routing']}
                        route={rule}
                        index={index}
                        handleChangeProperty={({ value, property }: any) => {
                          if (property === 'method') {
                            props.item.rules[index].method = value
                          } else {
                            props.item.rules[index].objectIDs = value
                          }
                          props.ruleChanged()
                        }}
                        actionText='Add Rule'
                        inline
                      />
                      {props.item.rules.length === 1 && (
                        <Button
                          variant='text'
                          onClick={() => props.ruleFieldAdded(props.item.id)}
                        >
                          <Typography variant='caption' style={{ color: 'gray' }}>
                            + add fallback
                          </Typography>
                        </Button>
                      )}
                    </div>
                    {props.item.rules.length > 1 && (
                      <IconButton>
                        <CloseIcon
                          onClick={() => props.ruleFieldRemoved(props.item.id, index === 1)}
                          color='#808080'
                        />
                      </IconButton>
                    )}
                  </div>
                </div>
              )
            })}
          </div>
          <div style={{ display: 'flex', alignItems: props.item.rules.length > 1 ? 'center' : 'flex-start', position: 'relative', gridArea: 'delete', marginLeft: 50 }}>
            <div>
              <IconButton>
                <HighlightOffIcon
                  onClick={() => props.ruleRemoved(props.item.id)}
                  color='primary'
                />
              </IconButton>
            </div>
          </div>
        </div>
      </div>
    )
  }
)

export default function GlobalRoutingRulesPage (): JSX.Element {
  const classes = useStyles()
  const [globalRoutingSettings, dispatch] = useReducer(routingReducer, { rules: [] })
  const { data, isLoading } = RoutingRules.loadOne()
  const [canSave, setCanSave] = useState(false)
  const [counter, setCounter] = useState(0)
  const [ready, setReady] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [ruleID, setRuleID] = useState('')
  const [ruleName, setRuleName] = useState('')
  const minHeight = globalRoutingSettings?.rules?.length * 116

  const finishDrag = (items: any) => {
    const newRules: any[] = []
    items.forEach((item: any) => {
      item.rules.forEach((rule: any) => {
        newRules.push({
          id: rule.id,
          name: rule.name,
          method: rule.method,
          objectIDs: rule.objectIDs,
          groups: rule.groups
        })
      })
    })
    if (!canSave) {
      setCanSave(true)
    }
    dispatch({ type: 'setRules', value: newRules })
  }

  useEffect(() => {
    if (!isLoading && data?.rules && !ready) {
      dispatch({ type: 'set', value: data })
    }
    if (!isLoading && !ready) {
      setReady(true)
    }
  }, [data, isLoading, ready])

  const addRule = () => {
    const newRules = [...globalRoutingSettings.rules]
    const ruleNumber = (new Set(newRules.map((rule: any) => rule.id))).size
    newRules.push({
      id: crypto.randomUUID(),
      name: 'Route Group ' + String(ruleNumber + 1),
      method: 'single_agent',
      objectIDs: [],
      groups: [{ filters: [] }]
    })
    if (!canSave) {
      setCanSave(true)
    }
    dispatch({ type: 'setRules', value: newRules })
  }

  const removeRule = (id: string) => {
    const newRules = [...globalRoutingSettings.rules].filter((rule: any) => rule.id !== id)
    if (!canSave) {
      setCanSave(true)
    }
    dispatch({ type: 'setRules', value: newRules })
  }

  const removeRuleField = (id: string, fallback: boolean) => {
    const offset = fallback ? 1 : 0
    const ruleIndex = globalRoutingSettings.rules.findIndex((rule: any) => rule.id === id) + offset
    const ruleName = globalRoutingSettings.rules[ruleIndex].name
    const totalRules = globalRoutingSettings.rules.filter((rule: any) => rule.id === id).length
    const newRules = [
      ...globalRoutingSettings.rules.slice(0, ruleIndex),
      ...globalRoutingSettings.rules.slice(ruleIndex + 1)
    ]
    if (!fallback && totalRules > 1) {
      newRules[ruleIndex].name = ruleName
    }

    if (!canSave) {
      setCanSave(true)
    }
    dispatch({ type: 'setRules', value: newRules })
  }

  const addRuleField = (id: string) => {
    const newRules = [...globalRoutingSettings.rules]
    const ruleIndex = newRules.findIndex((rule: any) => rule.id === id) + 1
    const insertRule = [
      ...newRules.slice(0, ruleIndex),
      {
        id,
        name: newRules[ruleIndex - 1].name,
        method: 'single_agent',
        objectIDs: [],
        groups: [{ filters: [] }]
      },
      ...newRules.slice(ruleIndex)
    ]
    if (!canSave) {
      setCanSave(true)
    }
    dispatch({ type: 'setRules', value: insertRule })
  }

  const getMappedRulesByName = () => {
    const mappedRules: any = []
    globalRoutingSettings?.rules?.forEach((rule: any) => {
      const ruleIndex = mappedRules.findIndex((r: any) => r.id === rule.id)
      if (ruleIndex === -1) {
        mappedRules.push({ id: rule.id, name: ruleName, rules: [rule] })
      } else {
        mappedRules[ruleIndex].rules.push(rule)
      }
    })
    return mappedRules
  }

  return (
    <AppPage
      title='Global Routing Rules'
      actionText='Save Settings'
      saveProps={{
        disabled: !canSave,
        save: () => {
          setCanSave(false)
          RoutingRules.update(globalRoutingSettings.rules, globalRoutingSettings.globalFallback)
        },
        saveText: 'Save Settings'
      }}
    >
      <Paper
        title='Segments'
        subtitle='Determine who will be routed for alerts, live chat, and meetings based on segments'
      >
        {isLoading ? (
          <Loader />
        ) : (
          <div>
            <div style={{ width: 'fit-content' }}>
              <MessageDiv
                open={globalRoutingSettings?.rules?.length > 0}
                setOpen={() => undefined}
                type='info'
                body='Be sure to set up fallbacks in routing skills for companies that don’t fall into the segments below.'
              />
            </div>
            {globalRoutingSettings.rules.length > 0 && (
              <>
                <div className={classes.rowHeader}>
                  <div style={{ gridArea: 'segment' }}>Visitor Criteria</div>
                  <div style={{ gridArea: 'teamuser' }}>Signals Team/User</div>
                  <div style={{ gridArea: 'delete' }} />
                </div>
                <div style={{ minHeight: minHeight }}>
                  <DragNDrop
                    itemList={getMappedRulesByName()}
                    setItemList={finishDrag}
                    componentProps={{
                      ruleChanged: () => {
                        setCounter(counter + 1) // force re-render
                        setCanSave(true)
                      },
                      ruleRemoved: removeRule,
                      ruleFieldRemoved: removeRuleField,
                      ruleFieldAdded: addRuleField,
                      modalOpen: modalOpen,
                      setModalOpen: setModalOpen,
                      setRuleName: setRuleName,
                      setRuleID: setRuleID
                    }}
                    direction='vertical'
                    component={RoutingRuleComponent}
                    divClass='no-focus-outline'
                  />
                </div>
              </>
            )}
            <div style={{ marginTop: 20 }}>
              <Button
                variant='outlined'
                color='primary'
                onClick={() => { addRule(); if (!canSave) setCanSave(true) }}
                startIcon={<ControlPointIcon />}
              >
                Add Routing Rule
              </Button>
            </div>
          </div>
        )}
        <div>
          <GlobalRoutingRulesModal
            ruleID={ruleID}
            ruleName={ruleName}
            state={globalRoutingSettings}
            dispatch={dispatch}
            open={modalOpen}
            setOpen={setModalOpen}
            setCanSave={setCanSave}
          />
        </div>
      </Paper>
      <Paper
        title='Global Fallback'
        subtitle="Choose how you'd like to route visitors not contained in the criteria above"
      >
        <Checkbox
          checked={!!globalRoutingSettings?.globalFallback?.method}
          onChange={(value: boolean) => { dispatch({ type: 'globalFallbackMethod', value: value ? 'single_agent' : null }); setCanSave(true) }}
          label='Set global routing fallback'
        />
        {globalRoutingSettings?.globalFallback?.method && (
          <div style={{ display: 'flex' }}>
            <RouteOption
              exclude={['group', 'last_routed', 'global_routing']}
              route={globalRoutingSettings.globalFallback}
              index={0}
              handleChangeProperty={({ value, property }: any) => {
                setCanSave(true)
                if (property === 'method') {
                  dispatch({ type: 'globalFallbackMethod', value })
                } else {
                  dispatch({ type: 'globalFallbackObjectIDs', value })
                }
              }}
              actionText='Add Rule'
              inline
            />
            <IconButton>
              <CloseIcon
                onClick={() => {
                  dispatch({ type: 'globalFallbackMethod', value: null }); setCanSave(true);
                }}
                color='primary'
              />
            </IconButton>
          </div>
        )}
      </Paper>
    </AppPage>
  )
}
