import { makeStyles } from '@material-ui/core/styles'
import { CloseButton } from 'library/buttons/CloseButton'
import { Button, Icon } from 'library/materialUI'
import { ReactNode, useContext } from 'react'
import { NPSPromptContext } from './NPSPromptContext'

const useStyles = makeStyles(theme => ({
  activeStep: {
    height: 7,
    width: 80,
    backgroundColor: '#000010',
    borderRadius: 100,
    margin: '0px 5px'
  },
  inactiveStep: {
    height: 7,
    width: 40,
    backgroundColor: '#00001040',
    borderRadius: 100
  },
  steps: {
    display: 'flex',
    marginBottom: 5
  },
  actionButton: {
    font: 'normal normal normal 12px/18px Poppins',
    backgroundColor: '#00001014',
    padding: '5px 10px',
    borderRadius: 8,
    cursor: 'pointer'
  },
  header: {
    display: 'grid',
    gridTemplateColumns: '1fr 130px 1fr',
    alignItems: 'center',
    marginBottom: 20
  },
  promptButton: {
    textTransform: 'none',
    borderRadius: 10,
    fontSize: '13px'
  },
  feedbackHeader: {
    display: 'grid',
    gridTemplateColumns: '1fr auto 1fr',
    alignItems: 'center',
    marginBottom: 20
  },
  headerMessage: {
    font: 'normal normal 600 16px/25px Poppins'
  }
}))

type NPSHeaderProps = {
  firstSlot: ReactNode
  secondSlot: ReactNode
  step: number
}

export function NPSHeader (props: NPSHeaderProps) {
  const classes = useStyles()

  return (
    <div className={classes.header}>
      <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
        {props.firstSlot}
      </div>
      <NPSProgressIndicator step={props.step} />
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {props.secondSlot}
      </div>
    </div>
  )
}

type NPSActionButtonProps = {
  text: string
  onClick: () => void
}

export function NPSActionButton (props: NPSActionButtonProps) {
  const classes = useStyles()

  return (
    <div
      className={classes.actionButton}
      onClick={props.onClick}
    >
      {props.text}
    </div>
  )
}

export function NPSProgressIndicator ({ step }: { step: number }) {
  const classes = useStyles()
  const firstClass = step === 1 ? classes.activeStep : classes.inactiveStep
  const secondClass = step === 1 ? classes.inactiveStep : classes.activeStep
  return (
    <div className={classes.steps}>
      <div className={firstClass} />
      <div className={secondClass} />
    </div>
  )
}

type NPSFeedbackHeaderProps = {
  goBack: () => void
  message?: string
}
export function NPSFeedbackHeader (props: NPSFeedbackHeaderProps) {
  const classes = useStyles()
  const { closePrompt } = useContext(NPSPromptContext)

  return (
    <div className={classes.feedbackHeader}>
      <div>
        <Icon
          icon='arrowBack'
          onClick={props.goBack}
          cursor='pointer'
        />
      </div>
      <div className={classes.headerMessage}>{props.message}</div>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <CloseButton handleClose={closePrompt} />
      </div>
    </div>
  )
}

type SecondScreenHeaderProps = {
  goBack: () => void
}
export function SecondScreenHeader (props: SecondScreenHeaderProps) {
  const { closePrompt } = useContext(NPSPromptContext)
  return (
    <NPSHeader
      step={2}
      firstSlot={<NPSActionButton text='Change my score' onClick={props.goBack} />}
      secondSlot={<CloseButton handleClose={closePrompt} />}
    />
  )
}

export type NPSPromptButtonProps = {
  color?: 'primary'
  buttonText: string
  onClick: () => void
  disabled?: boolean
}
export function NPSPromptButton (props: NPSPromptButtonProps) {
  const classes = useStyles()
  const style = props.color === 'primary' ? {} : { backgroundColor: 'white' }
  return (
    <Button
      style={style}
      className={classes.promptButton}
      variant={props.color === 'primary' ? 'contained' : 'outlined'}
      fullWidth
      color={props.color}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.buttonText}
    </Button>
  )
}

export function CenteredPromptButton (props: NPSPromptButtonProps) {
  return (
    <div style={{ width: '65%', margin: 'auto', marginBottom: 8 }}>
      <NPSPromptButton {...props} />
    </div>
  )
}
