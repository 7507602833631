import { useState, useEffect, useRef } from 'react'
import FlowSurface from '../surface/FlowSurface'

function SequenceEditor (props) {
  const surfaceRef = useRef(null)
  const toolkitInstanceRef = props.toolkitInstanceRef
  const isRefreshingRef = useRef(false)
  const [dataLoaded, setDataLoaded] = useState(false)

  function refreshSequence () {
    isRefreshingRef.current = true
    setDataLoaded(false)
    toolkitInstanceRef.current.clear()
    props.dataRef.current = null
  }

  if (props.refreshSequenceRef) {
    props.refreshSequenceRef.current = refreshSequence
  }

  function restoreVersion (flowID) {
    isRefreshingRef.current = true
    setDataLoaded(false)
    toolkitInstanceRef.current.clear()
    props.dataRef.current = null
  }

  if (props.restoreVersionRef) {
    props.restoreVersionRef.current = restoreVersion
  }

  useEffect(() => {
    if (props.sequenceFlow && toolkitInstanceRef.current && surfaceRef.current && !dataLoaded) {
      setTimeout(() => {
        props.dataRef.current = props.sequenceFlow
        toolkitInstanceRef.current.load(props.dataRef.current)
        setDataLoaded(true)
        isRefreshingRef.current = false
        surfaceRef.current.setZoom(0.7)
        surfaceRef.current.zoomToFitIfNecessary()
      }, 10)
    }
  }, [props.sequenceFlow, props.dataRef, dataLoaded, toolkitInstanceRef])

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <FlowSurface
        surfaceRef={surfaceRef}
        toolkitInstanceRef={toolkitInstanceRef}
        sequence
        isRefreshingRef={isRefreshingRef}
        dataLoaded={dataLoaded}
        saveRef={props.saveRef}
        save={props.save}
        setCanSave={props.setCanSave}
      />
    </div>
  )
}

export default SequenceEditor
