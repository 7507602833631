import { ReactNode, createContext, useState } from 'react'

interface EmailWizardValues {
  emailID: number
  dismissedHyperlinkInfo: boolean
  dismissHyperlinkInfo: (value: boolean) => void
}

export const EmailWizardContext = createContext<EmailWizardValues>({
  emailID: 0,
  dismissedHyperlinkInfo: false,
  dismissHyperlinkInfo: () => undefined
})

export function EmailWizardProvider (props: { children: ReactNode, emailID: number }): JSX.Element {
  const [dismissedHyperlinkInfo, dismissHyperlinkInfo] = useState(false)

  return (
    <EmailWizardContext.Provider
      value={{
        emailID: props.emailID,
        dismissedHyperlinkInfo,
        dismissHyperlinkInfo
      }}
    >
      {props.children}
    </EmailWizardContext.Provider>
  )
}
