import { makeStyles } from '@material-ui/core/styles';
import { ClickAwayListener, Popper, Tooltip, Typography } from '@material-ui/core';
import { useContext, useState } from 'react';
import FilterView from 'classes/filterView';
import { Icon } from 'library/materialUI';
import { ApiContext } from 'api-context';
import ConnectAndSellLogo from 'img/ConnectAndSellLogo.png'
import { CONNECT_AND_SELL_TAB, RECENTLY_SEEN_TAB, TRENDING_ACCOUNTS_TAB } from './DashboardTabCard';
import { ShareContext } from 'share-context';

const useStyles = makeStyles((theme) => ({
  filterViewPopup: {
    width: '350px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    justifyContent: 'top',
    backgroundColor: 'white',
    borderRadius: '10px',
    boxShadow: '0px 3px 6px #00000029',
    padding: '10px'
  },
  filterViewRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: '10px'
  },
  optionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  menuButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.alt
    },
    marginLeft: '5px'
  }
}))

interface FilterViewRowProps {
  view: FilterView
  tabOrder: number[]
  handleTabOrder: (tab: number) => void
  createEmailSub: ({ type, viewID, viewName }: { type: string, viewID: number, viewName: string }) => void
  setHovering: (hovering: number | null) => void
  isHovered: (index: number) => boolean
  editView: (view: FilterView) => void
  customView?: boolean
}

function FilterViewRow ({ view, tabOrder, handleTabOrder, createEmailSub, setHovering, isHovered, editView, customView }: FilterViewRowProps): JSX.Element {
  const classes = useStyles()
  const length = tabOrder.length
  const { setDeleteObject } = useContext(ApiContext)
  const { crmIntegration } = useContext(ShareContext)
  let tooltipWarning = ''
  let options: JSX.Element[] = []
  if (length >= 5 && !tabOrder.includes(view.id as number)) {
    tooltipWarning = 'You’ve hit the limit of pinned views. If you’d like to pin this view, first unpin another view.'
  } else if (length === 1 && tabOrder.includes(view.id as number)) {
    tooltipWarning = 'You must have at least one pinned view.'
  } else if (crmIntegration === 'none' && (view.id === TRENDING_ACCOUNTS_TAB || view.id === RECENTLY_SEEN_TAB)) {
    tooltipWarning = 'You must have Salesforce or Hubspot connected to use this view.'
  }

  const EmailUpdateComponent: JSX.Element = (
    <Tooltip title='Create Email Update' placement='bottom'>
      <div className={classes.menuButton} onClick={() => createEmailSub({ type: 'filters', viewID: (view.id as number), viewName: view.name.toUpperCase() })}>
        <Icon icon='createEmailUpdate' color='currentColor' specificSize={18} />
      </div>
    </Tooltip>
  )

  const EditViewComponent: JSX.Element = (
    <Tooltip title='Edit View' placement='bottom'>
      <div className={classes.menuButton} onClick={() => editView(view)}>
        <Icon icon='edit' specificSize={18} />
      </div>
    </Tooltip>
  )

  const DuplicateViewComponent: JSX.Element = (
    <Tooltip title='Duplicate View' placement='bottom'>
      <div
        className={classes.menuButton}
        onClick={() => {
          editView(view.copy())
        }}
      >
        <Icon icon='clone' color='currentColor' specificSize={18} />
      </div>
    </Tooltip>
  )

  const DeleteViewComponent: JSX.Element = (
    <Tooltip title='Delete View' placement='bottom'>
      <div
        className={classes.menuButton}
        onClick={() => {
          if (tabOrder.includes(view.id as number)) {
            handleTabOrder(view.id as number)
          }
          setDeleteObject(view)
        }}
      >
        <Icon icon='close' specificSize={18} />
      </div>
    </Tooltip>
  )

  if (customView) {
    options = [EmailUpdateComponent, EditViewComponent, DuplicateViewComponent, DeleteViewComponent]
  } else if (view.id !== CONNECT_AND_SELL_TAB) {
    options = [EmailUpdateComponent, DuplicateViewComponent]
  }

  return (
    <div className={classes.filterViewRow} key={view.id}>
      <Tooltip
        title={tooltipWarning}
        placement='left'
      >
        <div
          className={classes.optionsContainer}
          style={{ cursor: length >= 5 && !tabOrder.includes(view.id as number) ? 'default' : 'pointer' }}
          onClick={() => {
            if (crmIntegration === 'none' && (view.id === TRENDING_ACCOUNTS_TAB || view.id === RECENTLY_SEEN_TAB)) return
            if (length < 5 || tabOrder.includes(view.id as number)) {
              handleTabOrder(view.id as number)
            }
          }}
          onMouseEnter={() => setHovering(view.id as number)}
          onMouseLeave={() => setHovering(null)}
        >
          <div style={{ marginRight: 10 }}>
            {tabOrder.includes(view.id as number) ? <Icon icon='pin' color='#9933FF' />
              : (
                <div>
                  {isHovered(view.id as number) ? <Icon icon='pin' color='#C4C4C4' /> : <></>}
                </div>)}
          </div>
          <div>
            {view.id === CONNECT_AND_SELL_TAB ? (
              <img src={ConnectAndSellLogo} alt='ConnectAndSell' style={{ height: 20 }} />
            ) : (
              <Typography variant='body1'>
                {view.name}
              </Typography>)}
          </div>
        </div>
      </Tooltip>
      <div className={classes.optionsContainer}>
        {options.map((option, index) => (
          <div key={index}>
            {option}
          </div>
        ))}
      </div>
    </div>
  )
}

interface FilterViewMenuProps {
  anchorEl: HTMLElement | null
  setAnchorEl: (element: HTMLElement | null) => void
  filterViews: FilterView[]
  tabOrder: number[]
  handleTabOrder: (tab: number) => void
  createEmailSub: ({ type, viewID, viewName }: { type: string, viewID: number, viewName: string }) => void
  editView: (view: FilterView) => void
}

export default function FilterViewMenu ({ anchorEl, setAnchorEl, filterViews, tabOrder, handleTabOrder, createEmailSub, editView }: FilterViewMenuProps): JSX.Element {
  const classes = useStyles()
  const [hovering, setHovering] = useState<number | null>(null)
  const defaultViews = filterViews.filter(view => (view.id as number) <= 0)
  const views = filterViews.filter(view => (view.id as number) > 0)

  const isHovered = (index: number) => hovering === index

  return (
    <Popper
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      placement='bottom-start'
      style={{ zIndex: 1000 }}
    >
      <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
        <div>
          <div className={classes.filterViewPopup}>
            {views.length > 0 && (
              <>
                <Typography style={{ marginBottom: 10 }} variant='h5'>
                  Custom Views
                </Typography>
                {views.map((view: FilterView) => (
                  <FilterViewRow
                    key={view.id}
                    view={view}
                    tabOrder={tabOrder}
                    handleTabOrder={handleTabOrder}
                    createEmailSub={createEmailSub}
                    setHovering={setHovering}
                    isHovered={isHovered}
                    editView={editView}
                    customView
                  />
                ))}
              </>
            )}
            <Typography style={{ marginBottom: 10, marginTop: 15 }} variant='h5'>
              Default Views
            </Typography>
            {defaultViews.map((view: FilterView) => (
              <FilterViewRow
                key={view.id}
                view={view}
                tabOrder={tabOrder}
                handleTabOrder={handleTabOrder}
                createEmailSub={createEmailSub}
                setHovering={setHovering}
                isHovered={isHovered}
                editView={editView}
              />
            ))}
          </div>
        </div>
      </ClickAwayListener>
    </Popper>
  )
}
