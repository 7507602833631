import { FullIntegrationsList } from 'pages/settings/Integrations/IntegrationsList'
import { makeStyles } from '@material-ui/core/styles'
import { Icon } from 'library/materialUI'
import { useCallback, useEffect, useState } from 'react'
import { getTenantIntegrations } from 'api/integrations'

const useStyles = makeStyles(theme => ({
  flex: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  icon: {
    width: 40,
    margin: '8px 15px'
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '1fr 3fr 2fr',
    gridGap: 5
  },
  subHeader: {
    fontSize: 12,
    marginBottom: 15,
    textAlign: 'center',
    fontWeight: 600
  },
  card: {
    height: 300,
    overflowY: 'auto'
  }
}))

// interface mappingTypes {
//   name: string
//   value: string
// }

// const integrationMapping: mappingTypes[] = [
//   { name: '6sense', value: 'sixsense' },
//   { name: 'Clearbit', value: 'clearbit' },
//   { name: 'Domo', value: 'domo' },
//   { name: 'Eloqua', value: 'eloqua' },
//   { name: 'Google Analytics', value: 'google_analytics' },
//   { name: 'HelpDocs', value: 'helpdocs' },
//   { name: 'HubSpot', value: 'hubspot' },
//   { name: 'Marketo', value: 'marketo' },
//   { name: 'Salesforce', value: 'salesforce' },
//   { name: 'Sendinblue', value: 'sendinblue' },
//   { name: 'Slack', value: 'slack' },
//   { name: 'Teams', value: 'teams' },
//   { name: 'Zapier', value: 'zapier' },
//   { name: 'Zendesk', value: 'zendesk' },
//   { name: 'ZoomInfo', value: 'zoominfo' },
//   { name: 'Okta', value: 'okta' },
//   { name: 'Google Ads', value: 'google_ads' }
// ]

interface integrationTyping {
  attributes: {
    enabled: boolean,
    integration_name: string // eslint-disable-line
  }
}

export const AdminIntegrationCounts = ({ tenantId } : { tenantId?: number }): JSX.Element => {
  const classes = useStyles()
  const [activeIntegrations, setActiveIntegrations] = useState<string[]>([])
  const sortedIntegrations = FullIntegrationsList.sort((a, b) => {
    return a.name > b.name ? 1 : -1
  })

  const parseIntegrations = useCallback((integrationList: integrationTyping[]) => {
    const activeIntegrationNames: string[] = []

    if (sortedIntegrations.length === 0) {
      return []
    }

    integrationList.forEach((integration: integrationTyping) => {
      integration.attributes.enabled && activeIntegrationNames.push(integration.attributes.integration_name)
    })

    return activeIntegrationNames
  }, [sortedIntegrations.length])

  useEffect(() => {
    if (tenantId) {
      getTenantIntegrations(tenantId)
        .then(response => {
          const active = parseIntegrations(response.data)
          setActiveIntegrations(active)
        })
    }
  }, [parseIntegrations, tenantId])

  return (
    <div className={classes.card}>
      <div className={classes.grid}>
        <div />
        <div className={classes.subHeader}>INTEGRATION NAME</div>
        <div className={classes.subHeader}>ENABLED</div>
      </div>
      {sortedIntegrations.map((el, idx) => {
        const isEnabled = activeIntegrations.includes(el.key)
        return (
          <div key={idx} className={classes.grid}>
            <img className={classes.icon} src={el.icon} alt={`${el.name} icon`} />
            <div className={classes.flex}>{el.name}</div>
            <div className={classes.flex}>
              <Icon icon={isEnabled ? 'checkCircle' : 'cancel'} color={isEnabled ? '#2FB182' : '#DA2F58'} />
            </div>
          </div>
        )
      })}
    </div>
  )
}
