import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'
import { capitalCase } from 'change-case'

export function CreateClustedColumnChart ({ data, id }: any) {
  am4core.addLicense('CH213476655')
  am4core.useTheme(am4themes_animated)

  const chart = am4core.create(id, am4charts.XYChart)
  chart.colors.step = 2;

  chart.legend = new am4charts.Legend()
  chart.legend.position = 'bottom'
  chart.legend.paddingBottom = 20
  chart.legend.labels.template.maxWidth = 95

  const xAxis = chart.xAxes.push(new am4charts.CategoryAxis())
  xAxis.dataFields.category = 'category'
  xAxis.renderer.cellStartLocation = 0.1
  xAxis.renderer.cellEndLocation = 0.9
  xAxis.renderer.grid.template.location = 0;

  const yAxis = chart.yAxes.push(new am4charts.ValueAxis());
  yAxis.min = 0;

  function createSeries (value: string, name: string) {
    const series = chart.series.push(new am4charts.ColumnSeries())
    series.dataFields.valueY = value
    series.dataFields.categoryX = 'category'
    series.name = name
    series.columns.template.tooltipText = '{name}: {valueY}'
    series.columns.template.hoverOnFocus = true

    series.events.on('hidden', arrangeColumns);
    series.events.on('shown', arrangeColumns);

    return series;
  }

  chart.data = data

  for (const key in data[0]) {
    if (key !== 'category') {
      const title = capitalCase(key)
      createSeries(key, title)
    }
  }

  function arrangeColumns () {
    const series = chart.series.getIndex(0);

    const w = 1 - xAxis.renderer.cellStartLocation - (1 - xAxis.renderer.cellEndLocation);
    if (series && series.dataItems.length > 1) {
      const x0 = xAxis.getX((series as any).dataItems.getIndex(0), 'categoryX');
      const x1 = xAxis.getX((series as any).dataItems.getIndex(1), 'categoryX');
      const delta = ((x1 - x0) / chart.series.length) * w;
      if (am4core.isNumber(delta)) {
        const middle = chart.series.length / 2;

        let newIndex = 0;
        chart.series.each(function (series) {
          if (!series.isHidden && !series.isHiding) {
            series.dummyData = newIndex;
            newIndex++;
          } else {
            series.dummyData = chart.series.indexOf(series);
          }
        })
        const visibleCount = newIndex;
        const newMiddle = visibleCount / 2;

        chart.series.each(function (series) {
          const trueIndex = chart.series.indexOf(series);
          const newIndex = series.dummyData;

          const dx = (newIndex - trueIndex + middle - newMiddle) * delta

          series.animate({ property: 'dx', to: dx }, series.interpolationDuration, series.interpolationEasing);
          series.bulletsContainer.animate({ property: 'dx', to: dx }, series.interpolationDuration, series.interpolationEasing);
        })
      }
    }
  }

  return chart
}
