import { doPost } from './api'

export interface BugReport {
    user_message: string,
    error_message: string,
    request_id: string,
    request_url: string,
    date_of_event: string,
}

function sendBugReport (state: BugReport): Promise<any> {
    const path = '/notifications/bug_report'

    const body = {
        type: 'bug_report',
        attributes: { ...state }
    }
    return doPost({ path: path, data: body, silent: true })
}

export { sendBugReport }
