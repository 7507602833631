import { useEffect } from 'react'

interface HandleSaveProps {
  shouldSave: boolean,
  saveFunction: () => void
  handleSaved: () => void
}

export function HandleSave ({ shouldSave, saveFunction, handleSaved } : HandleSaveProps) {
  const handleSave = () => {
    saveFunction()
  }

  useEffect(() => {
    if (shouldSave) {
      handleSave()
      handleSaved()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldSave])
}
