import { useContext, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { FlowBuilderContext } from '../canvas/FlowBuilderProvider'
import { NodeSource } from './NodeSource'
import { ScrollBox } from 'cf-components/ScrollBox'

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 260,
    height: '100%',
    backgroundColor: '#FBF9FD'
  }
}))

const dataGenerator = (el: any): any => {
  const type = el.getAttribute('node-type')
  if (type === 'sequence') {
    return {
      type: 'sequence',
      kind: 'Sequence',
      settings: {
        name: el.getAttribute('sequence-name'),
        description: el.getAttribute('sequence-description'),
        sequenceID: parseInt(el.getAttribute('sequence-id'))
      },
      ports: JSON.parse(el.getAttribute('sequence-ports'))
    }
  }
  return {
    type: 'basic',
    kind: el.getAttribute('node-type'),
    ports: []
  }
}

function NodeSourceWrapper (props: any): JSX.Element {
  const { surface } = useContext(FlowBuilderContext)
  const containerRef = props.containerRef
  if (surface) {
    return (
      <NodeSource
        surface={surface as any}
        selector='.dragger'
        container={containerRef.current || document.createElement('div')}
        dataGenerator={dataGenerator}
        allowDropOnCanvas
        allowDropOnEdge
        allowDropOnGroup
        groupIdentifier={null}
        typeGenerator={null}
      />
    )
  } else {
    return (
      <div />
    )
  }
}

export function NodeDrawer (): JSX.Element {
  const containerRef = useRef<HTMLDivElement>(null)
  const classes = useStyles()

  return (
    <ScrollBox noScrollBar>
      <div
        ref={containerRef}
        className={classes.drawer}
      >
        <NodeSourceWrapper
          containerRef={containerRef}
        />
      </div>
    </ScrollBox>
  )
}
