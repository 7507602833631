import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { deleteFields, saveCustomField } from 'api/custom_fields'
import DeleteModal from './DeleteModal'
import CustomFieldsModal from './CustomFieldsModal'
import Table from 'cf-components/Table'
import { ListItemCard } from 'cf-components/CardList'
import { AvatarWithInfo, getColor, getInitials } from 'cf-components/Avatars'
import ActionsMenu from 'cf-components/ActionsMenu'
import DeleteIcon from '@material-ui/icons/DeleteForever'
import EditIcon from '@material-ui/icons/Edit'
import CustomField, { CustomFieldObjects } from 'classes/customFields'
import { queryClient } from 'App'
import { Icon } from 'library/materialUI'

const useStyles = makeStyles(theme => ({
  tableDiv: {
    padding: '0px 20px 20px 20px'
  },
  customFieldCard: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 2fr 1fr 50px',
    gridGap: 10,
    alignItems: 'center',
    padding: 15,
    cursor: 'pointer'
  },
  header: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 2fr 1fr 50px',
    gridGap: 10,
    padding: '5px 10px'
  },
  aHeader: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 2fr 50px',
    gridGap: 10,
    padding: '5px 10px'
  },
  aCustomFieldCard: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 2fr 50px',
    gridGap: 10,
    alignItems: 'center',
    padding: 15,
    cursor: 'pointer'
  },
  icon: {
    cursor: 'default',
    height: 30,
    width: 30
  }
}))

const customFieldMapping = {
  people: 'Contact',
  accounts: 'Account'
}

interface CustomFieldsPageProps {
  forObject: CustomFieldObjects
}

function CustomFieldsPage ({ forObject }: CustomFieldsPageProps) {
  const classes = useStyles()
  const [deleteFieldID, setDeleteFieldID] = useState(0)
  const [editField, setEditField] = useState(null)
  const { data: customFields, isLoading } = CustomField.loadAll(undefined, forObject)

  function beginDelete () {
    deleteFields({ fieldIds: [deleteFieldID] }).then(() => {
      queryClient.invalidateQueries('custom_fields')
      setDeleteFieldID(0)
    })
  }

  function saveField (values: any) {
    saveCustomField({ values }).then(_ => {
      queryClient.invalidateQueries('custom_fields')
      setDeleteFieldID(0)
    })
  }

  let rows: any = []

  if (!isLoading) {
    rows = customFields ? customFields.list.map((row) => {
      const label = row.label
      const description = row.description
      const initials = getInitials(row.label, 'CF')
      return (
        {
          label: label,
          type: row.kind,
          description: description,
          id: row.id,
          slug: row.slug,
          options: row.options,
          initials: initials,
          searchField: label + ' ' + description,
          edit: row.edit
        })
    }) : null
  }

  interface CustomFieldCardProps {
    row: any
    index: number
    forObject: CustomFieldObjects
  }

  function CustomFieldCard (props: CustomFieldCardProps) {
    const classes = useStyles()
    const row = props.row
    const color = getColor(props.index)
    const subtitle = 'Custom ' + customFieldMapping[props.forObject] + ' Field'

    const actions = [
      { name: 'Delete Custom Field', action: () => setDeleteFieldID(row.id), icon: DeleteIcon },
      { name: 'Edit Custom Field', action: () => setEditField(row), icon: EditIcon }
    ]

    return (
      <ListItemCard>
        <div
          className={props.forObject === 'people' ? classes.customFieldCard : classes.aCustomFieldCard}
          onClick={() => setEditField(row)}
        >
          <AvatarWithInfo
            title={row.label}
            subtitle={subtitle}
            avatarColor={color}
            variant='rounded'
            initials={row.initials}
          />
          <div>
            {row.type}
          </div>
          <div>
            {row.description}
          </div>
          {props.forObject === 'people' &&
            <div style={{ display: 'flex', width: '105px', justifyContent: 'center' }}>
              <Icon icon={row.edit ? 'checkCircle' : 'cancel'} color={row.edit ? '#448B2B' : '#CF2A2A'} />
            </div>}
          <ActionsMenu
            actions={actions}
          />
        </div>
      </ListItemCard>
    )
  }

  const sortValues = [
    { label: 'Label', id: 'label', header: true },
    { label: 'Type', id: 'type', header: true },
    { label: 'Description', id: 'description', header: true }
  ]
  if (forObject === 'people') sortValues.push({ label: 'Edits Allowed', id: 'edit', header: true })

  return (
    <div>
      <Table
        rows={rows}
        sortValues={sortValues}
        card={CustomFieldCard}
        cardProps={{ forObject: forObject }}
        header
        headerClass={forObject === 'people' ? classes.header : classes.aHeader}
      />
      <DeleteModal
        open={Boolean(deleteFieldID)}
        onHide={() => setDeleteFieldID(0)}
        deleteObject={beginDelete}
        message='Are you sure you want to delete this field?'
        title='Delete Custom Field'
      />
      <CustomFieldsModal
        open={Boolean(editField)}
        onHide={() => setEditField(null)}
        save={saveField}
        field={editField}
      />
    </div>
  )
}

export default CustomFieldsPage
