import { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { getFlow, saveBotDetails, updateBot } from 'api/bots'
import { getUsers } from 'api/users'
import AppPage from 'cf-components/AppPage'
import FlowBuilder from 'pages/bot/flow-builder/surface/FlowBuilder.jsx'
import BotDetailsPage from './BotDetailsPage'
import BotEditTab from './BotEditTab'
import SettingsPage from './SettingsPage'
import { getTabs } from './botConfig'
import { Tooltip } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  itemActive: {
    display: 'flex',
    alignItems: 'center',
    color: '#81AF20',
    fontWeight: 0,
    fontSize: '12px',
    marginLeft: 10,
    backgroundColor: '#E6EFD2',
    borderRadius: 5,
    padding: 5,
    cursor: 'default'
  },
  itemInactive: {
    display: 'flex',
    alignItems: 'center',
    color: '#868686',
    fontWeight: 0,
    marginLeft: 10,
    fontSize: '12px',
    backgroundColor: '#E5E5E5',
    borderRadius: 5,
    padding: 5,
    cursor: 'default'
  }
}))

function FlowBotPage (props) {
  const classes = useStyles()
  const [users, setUsers] = useState([])
  const values = props.values
  const setFieldValue = props.setFieldValue
  const flow = props.flow
  const flowID = props.flowID
  const bot = props.bot
  const chatServiceUrl = props.chatServiceUrl
  const botID = props.botID
  const botType = props.botType
  const disabled = props.disabled
  const submit = props.submit
  const publishBot = props.publishBot
  const enabled = bot.attributes.enabled
  const activeTest = props.activeTest

  function saveDescription (description) {
    if (botID) {
      saveBotDetails({ chatServiceUrl, description, botID })
        .then(response => {
          props.setBot(response.data)
        })
    } else {
      props.setBot(prevState => ({
        ...prevState,
        attributes: {
          ...prevState.attributes,
          description: description
        }
      }))
    }
  }

  function restoreFlow (flowID) {
    getFlow({ flowID }).then(response => {
      props.setFlow(response.data)
      props.setFlowID(response.data.id)
      const attributes = {
        flow_id: response.data.id
      }
      updateBot({ botID, chatServiceUrl, attributes })
      setTimeout(() => {
        props.setCanSave(false)
      }, 50)
    })
  }

  useEffect(() => {
    getUsers().then(response => {
      setUsers(response.data)
    })
  }, [])

  function saveBot () {
    submit()
  }

  const tabs = getTabs(botID, botType)
  let activeDiv = <></>
  if (bot.attributes.is_active) {
    activeDiv = <Tooltip title='This chatbot is actively firing on your site. If you want to deactivate it, remove it from your PlayMaker.'><div className={classes.itemActive}>Active</div></Tooltip>
  } else {
    activeDiv = <Tooltip title='This chatbot is not actively firing on your site. To activate it, create a play containing this chatbot and place it in your PlayMaker.'><div className={classes.itemInactive}>Inactive</div></Tooltip>
  }

  return (
    <AppPage
      title={values.name}
      saveName={(name) => setFieldValue('name', name)}
      breadcrumbs={props.breadcrumbs}
      editableTitle={Boolean(bot)}
      tabs={tabs}
      extraTitleInfo={activeDiv}
    >
      <FlowBuilder
        values={values}
        setFieldValue={setFieldValue}
        dataRef={props.flowBuilderDataRef}
        flow={flow}
        save={saveBot}
        publishBot={publishBot}
        enabled={enabled}
        disabled={disabled}
        setCanSave={props.setCanSave}
        botID={botID}
        flowID={flowID}
        chatServiceUrl={chatServiceUrl}
        users={users}
        restoreFlow={restoreFlow}
        activeTest={activeTest}
        archived={props.archived}
      />
      <BotEditTab
        save={saveBot}
        publishBot={publishBot}
        values={values}
        disabled={disabled}
        activeTest={activeTest}
        enabled={enabled}
      >
        <SettingsPage
          values={values}
          setFieldValue={setFieldValue}
          botID={botID}
        />
      </BotEditTab>
      <BotDetailsPage
        bot={bot}
        chatServiceUrl={chatServiceUrl}
        saveDescription={saveDescription}
      />
    </AppPage>
  )
}

export default FlowBotPage
