import { SectionComponent } from './SectionComponent'

type SectionCenterProps = {
  homeComponent: JSX.Element
  links: string[]
  sectionMapping?: Record<string, any>
  type?: string
}

export function SectionCenter ({ links, sectionMapping, homeComponent, type }: SectionCenterProps): JSX.Element {
  if (links.length === 1) {
    return (
      homeComponent
    )
  } else {
    return (
      <SectionComponent
        sectionMapping={sectionMapping}
        links={links}
        type={type}
      />
    )
  }
}
