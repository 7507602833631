import { useContext, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { SessionContext } from '../../session-context'
import TextField from '@material-ui/core/TextField'
import Divider from '@material-ui/core/Divider'
import ProfilePicture from './ProfilePicture'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import ChangeEmailModal from './ChangeEmailModal'
import { saveEmail } from '../../api/users'
import EmailChangeMessageModal from './EmailChangeMessageModal'
import { chatRoles } from './UserManagement/userListHelpers'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    marginLeft: theme.spacing(5)
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: 10,
    width: 400
  },
  contactFields: {
    marginLeft: theme.spacing(6),
    marginTop: 3
  },
  imageField: {
    marginTop: theme.spacing(1)
  },
  fileInput: {
    width: 150,
    color: 'transparent'
  },
  editButton: {
    marginTop: '5px'
  }
}))

function ContactInfo (props) {
  const classes = useStyles()
  const { user } = useContext(SessionContext)
  const [changeEmailModalOpen, setChangeEmailModalOpen] = useState(false)
  const [messageModalOpen, setMessageModalOpen] = useState(false)
  const [newEmail, setNewEmail] = useState()
  const [emailError, setEmailError] = useState()

  const handleChange = name => event => {
    const value = event.target.value
    props.updateContactInfo(name, value)
  }

  function changeEmail (new_email, new_email_confirmation) {
    const user_id = user.id
    const for_user_id = props.user_id
    setNewEmail(new_email)
    saveEmail({ user_id, for_user_id, new_email, new_email_confirmation })
      .then(response => response.json())
      .then(response => {
        if (response.ok) {
          setChangeEmailModalOpen(false)
          setMessageModalOpen(true)
        } else {
          setEmailError(response.errors[0].detail)
        }
      })
  }

  const userRole = props.info.role
  let canEditEmail = false
  let userRoleName
  chatRoles.map((role) => { if (role.key === userRole) { userRoleName = role.value } return null })

  if (userRole === 1 || user.id === props.user_id) {
      canEditEmail = true
  }

  return (
    <div>
      <h3>Personal Info</h3>
      <Divider />
      <br />
      <div className={classes.container}>
        <div className={classes.imageField}>
          <ProfilePicture
            url={props.info.profile_picture_url}
            updateProfilePicture={props.updateProfilePicture}
            editable={props.editable}
            user_id={props.user_id}
          />
        </div>
        <div className={classes.contactFields}>
          <TextField
            id='name'
            label='Full Name'
            className={classes.textField}
            value={props.info.name}
            onChange={props.editable ? handleChange('name') : () => {}}
            margin='dense'
            variant='outlined'
          />
          <br />
          <TextField
            id='email-address'
            label='Email'
            disabled
            className={classes.textField}
            value={props.info.email}
            onChange={props.editable ? handleChange('email') : () => {}}
            margin='dense'
            variant='outlined'
          />
          {canEditEmail
            ? (
              <IconButton className={classes.editButton} onClick={() => setChangeEmailModalOpen(true)}>
                <EditIcon />
              </IconButton>
            )
            : <></>}
          <br />
          <TextField
            id='phone'
            label='Phone'
            className={classes.textField}
            value={props.info.phone}
            onChange={props.editable ? handleChange('phone') : () => {}}
            margin='dense'
            variant='outlined'
          />
          <br />
          <TextField
            id='role'
            label='Role'
            className={classes.textField}
            value={userRoleName}
            disabled
            margin='dense'
            variant='outlined'
          />
          <ChangeEmailModal
            open={changeEmailModalOpen}
            onHide={() => setChangeEmailModalOpen(false)}
            saveButtonText='Update Email'
            changeEmail={changeEmail}
            errors={emailError}
          />
          <EmailChangeMessageModal
            open={messageModalOpen}
            onHide={() => setMessageModalOpen(false)}
            saveButtonText='Got it!'
            newEmail={newEmail}
          />
        </div>
      </div>
    </div>
  )
}

export default ContactInfo
