const botHeadSvg = `
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 274.37 220.65">
    <defs>
        <style>.cls-1{fill:#fff;}</style>
    </defs>
    <path class="cls-1" d="M52.5,109.7A7.27,7.27,0,0,0,45.25,117v26.34a7.27,7.27,0,0,0,7.25,7.25h6V109.7Z"/>
    <path class="cls-1" d="M221.88,109.7a7.28,7.28,0,0,1,7.25,7.25v26.34a7.27,7.27,0,0,1-7.25,7.25h-6V109.7Z"/>
    <path class="cls-1" d="M192.87,72.25H141.32V64.1a15.9,15.9,0,1,0-8.27,0v8.15H81.5A18.39,18.39,0,0,0,63.17,90.58v78.88A18.39,18.39,0,0,0,81.5,187.79H192.87a18.39,
    18.39,0,0,0,18.33-18.33V90.58A18.39,18.39,0,0,0,192.87,72.25Zm-31.56,35a9.24,9.24,0,1,1-9.24,9.23A9.24,9.24,0,0,1,161.31,107.27Zm-48.25,0a9.24,9.24,0,1,1-9.23,9.23A9.23,
    9.23,0,0,1,113.06,107.27ZM172.23,157A4.25,4.25,0,0,1,168,161.2H106.38a4.26,4.26,0,0,1-4.24-4.24h0a4.25,4.25,0,0,1,4.24-4.23H168a4.24,4.24,0,0,1,4.23,4.23Z"/>
</svg>`

const botHeadb64 = btoa(unescape(encodeURIComponent(botHeadSvg)))

const botHead = `data:image/svg+xml;base64,${botHeadb64}`

const siteVisitSvg = `
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="-2.5 -2.5 30 30">
    <defs>
      <style>.a,.c{fill:none;}.a{stroke:#fff;stroke-width:2px;}.b{stroke:none;}</style>
    </defs>
    <g transform="translate(-299 -120)">
      <g class="a" transform="translate(299 120)">
        <circle class="b" cx="12.5" cy="12.5" r="12.5"/>
        <circle class="c" cx="12.5" cy="12.5" r="11.5"/>
      </g>
      <line class="a" x2="20" transform="translate(301.5 128.5)"/>
      <line class="a" x2="22" transform="translate(300.5 136.5)"/>
      <path class="a" d="M-2239.845,121.016s-10.08,10.663,0,23.092" transform="translate(2551.345)"/>
      <path class="a" d="M-2244.325,121.016s9.944,10.663,0,23.092" transform="translate(2556.325)"/>
    </g>
  </svg>`

const siteVisitb64 = btoa(unescape(encodeURIComponent(siteVisitSvg)))

const siteVisit = `data:image/svg+xml;base64,${siteVisitb64}`

const liveChatSvg = `
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24.226" viewBox="-3 -3 30 30">
    <defs>
      <style>.a{fill:#fff;}.b{fill:none;stroke:#fff;stroke-width:2px;}</style>
    </defs>
    <g transform="translate(-300 -223)">
      <path class="a" d="M2190,17.447l0,0a9.78,9.78,0,0,1-3-6.945,9.819,9.819,0,0,1,3.514-7.425,13.244,13.244,0,0,1,16.971,0A9.819,9.819,0,0,1,2211,10.5a9.771,9.771,0,0,1-3,6.944l0-4.875a6.845,6.845,0,0,
      0,.5-2.569c0-4.411-4.262-8-9.5-8s-9.5,3.589-9.5,8a6.846,6.846,0,0,0,.5,2.567v4.881Z" transform="translate(-1887 223)"/>
      <rect class="a" width="7" height="10" rx="1" transform="translate(300 234)"/><rect class="a" width="7" height="10" rx="1" transform="translate(317 234)"/>
      <path class="b" d="M-2224.648,240.642s1.015,2.2,0,2.808-7.434.5-7.434.5" transform="translate(2547.082 2.258)"/>
    </g>
  </svg>`

const liveChatb64 = btoa(unescape(encodeURIComponent(liveChatSvg)))

const liveChat = `data:image/svg+xml;base64,${liveChatb64}`

const newContactSvg = `
  <svg xmlns="http://www.w3.org/2000/svg" width="18.617" height="21.467" viewBox="-4 -2 26 30">
    <defs>
      <style>.a{fill:#fff;}</style>
    </defs>
    <g transform="translate(-302.192 -389.918)">
      <ellipse class="a" cx="4.913" cy="4.913" rx="4.913" ry="4.913" transform="translate(306.614 389.918)"/>
      <rect class="a" width="18.617" height="4.368" rx="1" transform="translate(302.192 407.017)"/>
      <path class="a" d="M2203.441-40.173h-14.32A4.946,4.946,0,0,1,2187-43.995c0-3.312,4.163-6.006,9.281-6.006s9.281,2.694,9.281,6.006a4.947,4.947,0,0,1-2.121,3.821Z" transform="translate(-1884.754 451.558)"/>
    </g>
  </svg>`

const newContactb64 = btoa(unescape(encodeURIComponent(newContactSvg)))

const newContact = `data:image/svg+xml;base64,${newContactb64}`

const meetingSvg = `
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="-4 -4 32 32">
    <defs>
      <style>.a,.d{fill:none;}.a{stroke:#fff;stroke-width:2px;}.b{fill:#fff;}.c{stroke:none;}</style>
    </defs>
    <g transform="translate(-300 -337)">
      <g class="a" transform="translate(300 340)">
        <rect class="c" width="24" height="21" rx="3"/>
        <rect class="d" x="1" y="1" width="22" height="19" rx="2"/>
      </g>
      <rect class="b" width="3" height="4" transform="translate(304 337)"/>
      <rect class="b" width="3" height="4" transform="translate(317 337)"/>
      <rect class="b" width="22" height="6" transform="translate(301 341)"/>
      <rect class="b" width="3" height="3" transform="translate(305 349)"/>
      <rect class="b" width="3" height="3" transform="translate(311 349)"/>
      <rect class="b" width="3" height="3" transform="translate(317 349)"/>
    </g>
  </svg>`

const meetingb64 = btoa(unescape(encodeURIComponent(meetingSvg)))

const meeting = `data:image/svg+xml;base64,${meetingb64}`

const opportunitySvg = `
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="34" viewBox="-4 -10 25 56">
    <defs>
      <style>.a{fill:#fff;font-size:25px;font-family:OpenSans-Bold, Poppins;font-weight:700;}</style>
    </defs>
    <g transform="translate(-305 -437)">
      <text class="a" transform="translate(305 464)">
        <tspan x="0" y="0">$</tspan>
      </text>
    </g>
  </svg>`

const opportunityb64 = btoa(unescape(encodeURIComponent(opportunitySvg)))

const opportunity = `data:image/svg+xml;base64,${opportunityb64}`

export { botHead, siteVisit, liveChat, newContact, meeting, opportunity }
