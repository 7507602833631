import { Icon } from 'library/materialUI';
import { useContext, useState } from 'react';
import { TableContext } from './TableProvider';
import { EmailSubscription, initialState, typeMapping } from 'classes/emailSubscriptions';
import EmailSubscriptionModal from 'library/modals/emailSubscriptionModal/EmailSubscriptionModal';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  iconDiv: {
    display: 'flex',
    cursor: 'pointer'
  },
  iconContents: {
    display: 'flex',
    flexDirection: 'row',
    cursor: 'pointer',
    marginLeft: '10px',
    marginRight: '10px',
    margin: '5px',
    width: '215px',
    height: '40px',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid #D3D3D3',
    borderRadius: '5px',
    '&:hover': { backgroundColor: '#F5F5F5' }
  }
}))

interface EmailNotificationProps {
  predefinedEmail?: EmailSubscription
}

export default function EmailNotification ({ predefinedEmail }: EmailNotificationProps): JSX.Element {
  const { objectClass, noEmail } = useContext(TableContext)
  const [emailSub, setEmailSub] = useState<EmailSubscription | null>(null)
  const emailSubtype = objectClass.getName ? objectClass.getName().toLowerCase() : ''
  const classes = useStyles()

  const createEmailSub = (type: typeof typeMapping) => {
    if (predefinedEmail) {
      setEmailSub(predefinedEmail)
      return
    }
    const newInitialState = Object.assign(initialState, { type: type })
    setEmailSub(new EmailSubscription(newInitialState))
  }

  if (noEmail) {
    return (
      <></>
    )
  }

  return (
    <div className={classes.iconDiv}>
      <div id='email_update_button' className={classes.iconContents} onClick={() => createEmailSub(emailSubtype)}>
        <Icon icon='createEmailUpdate' size='lg' />
        <div style={{ fontFamily: 'poppins', fontSize: '14px', marginLeft: '8px' }}>Create email update</div>
      </div>
      {emailSub &&
        <EmailSubscriptionModal
          emailSubscription={emailSub}
          onClose={() => setEmailSub(null)}
        />}
    </div>
  )
}
