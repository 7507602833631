import React from 'react'
import { Select, OutlinedInput, MenuItem, Chip, Checkbox, ListItemText, FormControl } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import './components.css'
/*
  Select
  Multiple Select
  TextField
  Rounded Select
  Settings Card
  Checkbox
  Loading Button
*/

const useStyles = makeStyles(theme => ({
  select: {
    fontFamily: 'Poppins, sans serif',
    fontSize: '14px',
    color: '#4c4c4c'
  },
  selectInput: {
    padding: 8,
    paddingLeft: 10,
    borderRadius: 30
  },
  inputRoot: {
    borderRadius: 30
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: -5
  },
  chip: {
    marginTop: 5,
    marginRight: 5
  },
  outlinedSelect: {
    padding: '6px 32px 7px 5px',
    minHeight: 32
  }
}))

function FormikSelect ({ field, setFieldValue, setFieldTouched, errors, touched, label, values, ...props }) {
  const classes = useStyles()

  if (props.variant === 'multiple') {
    return (
      <FormControl style={{ width: '100%' }}>
        <Select
          {...field}
          style={{ minHeight: 45 }}
          classes={{ outlined: classes.outlinedSelect }}
          placeholder={props.label}
          displayEmpty
          multiple
          variant='outlined'
          margin='dense'
          fullWidth
          onChange={e => {
            setFieldValue(field.name, e.target.value)
          }}
          onBlur={() => setFieldTouched(field.name)}
          error={errors[props.id] && touched[props.id]}
          renderValue={selected => {
            if (selected.length === 0) {
              return (<div className={classes.grayedOut}> {props.label} </div>)
            }
            return (
              <div className={classes.chips}>
                {selected.map(value => (
                  <Chip
                    key={value}
                    label={values.filter(val => val.id === value)[0].label}
                    className={classes.chip}
                    color='primary'
                  />
                ))}
              </div>
            )
          }}
        >
          <MenuItem disabled value=''>
            {label}
          </MenuItem>
          {values.map((value, index) => {
            const id = value.id
            return (
              <MenuItem key={id} value={id}>
                <Checkbox
                  color='primary'
                  checked={field.value.indexOf(id) > -1}
                />
                <ListItemText primary={value.label} />
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
    )
  }
}

function CFSelect (props) {
  const classes = useStyles()

  if (props.variant === 'rounded') {
    return (
      <Select
        value={props.value}
        onChange={props.onChange}
        variant='outlined'
        margin='dense'
        classes={{ root: classes.select }}
        input={<OutlinedInput classes={{ input: classes.selectInput, root: classes.inputRoot }} />}
        style={props.style}
      >
        <MenuItem disabled value=''>{props.label}</MenuItem>
        {props.options.map((option, index) => (
          <MenuItem value={option.value} key={index}>{option.label}</MenuItem>
        ))}
      </Select>
    )
  }
  return <></>
}

export { CFSelect as Select, FormikSelect }
