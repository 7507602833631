import React, { useEffect, useState, useReducer } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import BaseCard from './BaseCard'
import CustomizedSnackbar from 'components/CustomizedSnackbar'
import { Tooltip, Button, TextField, Checkbox, Select, MenuItem, FormControlLabel, FormControl, InputLabel } from '@material-ui/core'
import { getTicketFields, createTicket } from 'api/tickets'
import { getCredentialsStatus, getIntegrationFeature } from 'api/integrations'
import Link from '@material-ui/core/Link'
import Popout from 'img/popout.png'

const useStyles = makeStyles(theme => ({
  card: {
    overflowY: 'auto',
    maxHeight: '300px'
  },
  headerImage: {
    height: 16
  },
  actionButton: {
    marginTop: 8,
    paddingLeft: 35,
    paddingRight: 35
  },
  actionAddButton: {
    marginTop: 0,
    paddingLeft: 50,
    paddingRight: 50
  },
  textField: {
    cursor: 'text',
    width: '100%',
    marginBottom: 25,
    marginRight: 10
  },
  menuHeader: {
    borderBottom: '1px solid #BBB',
    width: '100%',
    fontWeight: 600,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: 'rgba(0,0,0,0.87)'
  }
}))

const initialState = {
  ticketFields: [],
  ticketData: {},
  disabledFeature: true,
  disabledMessage: '',
  saving: false,
  adding: false,
  integrationName: '',
  newTicketLink: '',
  integrationURL: ''
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'initialize':
      return action.data
    case 'adding':
      return { ...state, adding: action.adding }
    case 'saving':
      return { ...state, saving: true }
    case 'saveComplete':
      return { ...state, saving: false }
    case 'disabledFeature':
      return { ...state, disabledFeature: true }
    case 'enable':
      return {
        ...state,
        ticketFields: action.fields,
        disabledFeature: false,
        integrationName: action.label,
        integrationURL: action.url
      }
    case 'saveDone':
      return { ...state, adding: false, ticketData: {}, newTicketLink: action.url }
    case 'fieldEdited': {
      const td = { ...state.ticketData }
      td[action.field] = action.value
      return { ...state, ticketData: td }
    }
    default:
      return state
  }
}

function AddTicket (props) {
  const classes = useStyles()
  const [state, dispatch] = useReducer(reducer, initialState)
  const [snackState, setSnackState] = useState({
    open: false,
    variant: 'success',
    message: 'Your changes have been saved',
    link: ''
  })
  const setAdding = (adding) => {
    dispatch({ type: 'adding', adding: adding })
  }

  useEffect(() => {
    getIntegrationFeature('ticketing')
      .then((response) => {
        if (response.data && response.data.attributes) {
          const settings = response.data.attributes
          getTicketFields().then(f => {
            getCredentialsStatus(settings.integration.name).then((res) => {
              dispatch({ type: 'enable', fields: f.data, label: settings.integration.label, url: res.data.attributes.instance_url })
            }, (err) => {
              setSnackState({
                open: true,
                message: 'Could not get integration instance url.',
                err: err.message
              })
              dispatch({ type: 'disabledFeature' })
            })
          }, (err) => {
            const status = err.message.split(':|:')[0]
            if (status !== 'not_found' && status !== 'bad_request') {
              // not_found / bad_request indicate that either the feature is disabled or the tenant does not have credentials configured
              setSnackState({
                open: true,
                message: 'Could not get ticket fields.',
                err: err.message
              })
            }
            dispatch({ type: 'disabledFeature' })
          })
        }
      }, (err) => {
        setSnackState({
          open: true,
          message: 'Could not load feature settings.',
          err: err.message
        })
      })
  }, [])

  function save () {
    for (let i = 0; i < state.ticketFields.length; i++) {
      const tf = state.ticketFields[i]
      if (tf.attributes.required || tf.attributes.always_required) {
        if (!state.ticketData[tf.id]) {
          setSnackState({
            open: true,
            variant: 'error',
            message: 'Please complete all required fields. ' + tf.attributes.name + ' is required.'
          })
          return
        }
      }
    }
    dispatch({ type: 'saving' })
    createTicket(state.ticketData).then((res) => {
      dispatch({ type: 'saveDone', url: res.data.attributes.url })
      setSnackState({
        open: true,
        variant: 'success',
        message: 'Your ticket has been created, click here to open it in a new window',
        link: res.data.attributes.url
      })
    }, (err) => {
      dispatch({ type: 'saveComplete' })
      setSnackState({
        open: true,
        message: 'We were unable to create your ticket.',
        err: err.message
      })
    })
  }
  function editField (f) {
    dispatch({ type: 'fieldEdited', field: f.target.id, value: f.target.value })
  }
  function selectChange (f) {
    dispatch({ type: 'fieldEdited', field: f.id, value: f.value })
  }
  return (
    <BaseCard
      title={state.integrationName}
      className={classes.card}
      id={props.id}
      actions={state.integrationURL ? (
        <Link
          className={classes.link}
          underline='none'
          target='_blank'
          href={state.integrationURL} rel='noreferrer'
        >
          <img
            alt='test'
            src={Popout}
            className={classes.headerImage}
          />
        </Link>
      ) : (
        <></>
      )}
    >
      {state.adding ? (
        <div>
          <div style={
            { overflow: 'auto', maxHeight: '300px' }
          }
          >
            {state.ticketFields.map((tf, index) => {
              if (!tf.attributes.enabled) {
                return ''
              }
              const fieldType = tf.attributes.type
              const fieldLabel = tf.attributes.name
              const required = tf.attributes.required || tf.attributes.always_required
              const fieldName = tf.id
              const fieldOptions = tf.attributes.options
              if (!state.ticketData[tf.id]) {
                if (fieldType === 'multi-select') {
                  state.ticketData[tf.id] = []
                } else {
                  state.ticketData[tf.id] = ''
                }
              }
              const options = { all: fieldOptions }
              let optionGroups = ['all']
              switch (fieldType) {
                case 'checkbox':
                  return (
                    <FormControlLabel
                      key={index}
                      control={<Checkbox onChange={editField} id={fieldName} value='1' color='primary' disabled={state.saving} />}
                      label={fieldLabel}
                    />
                  )
                case 'date':
                  return (
                    <TextField
                      id={fieldName}
                      key={index}
                      label={fieldLabel}
                      type='date'
                      required={required}
                      className={classes.textField}
                      InputLabelProps={{ shrink: true }}
                      color='primary'
                      variant='outlined'
                      onChange={editField}
                      value={state.ticketData[tf.id]}
                    />
                  )
                case 'multi-select':
                case 'select':
                  if (!fieldOptions) {
                    return ''
                  }
                  if (fieldName === 'assignee') {
                    optionGroups = ['Group', 'User']
                    for (let i = 0; i < fieldOptions.length; i++) {
                      const fieldOption = fieldOptions[i]
                      if (!options[fieldOption.type]) {
                        options[fieldOption.type] = []
                      }
                      options[fieldOption.type][options[fieldOption.type].length] = fieldOption
                    }
                  }
                  return (
                    <FormControl
                      key={index}
                      variant='outlined'
                      fullWidth
                      margin='dense'
                      className={classes.formControl}
                    >
                      <InputLabel><em>{fieldLabel}</em></InputLabel>
                      <Select
                        name={fieldName}
                        id={fieldName}
                        variant='outlined'
                        label={fieldLabel}
                        required={required}
                        multiple={fieldType === 'multi-select'}
                        margin='dense'
                        fullWidth
                        disabled={state.saving}
                        defaultValue='none'
                        className={classes.select}
                        value={state.ticketData[tf.id]}
                        onChange={(dd) =>
                          selectChange({
                            id: dd.target.name,
                            value: dd.target.value
                          })}
                      >
                        <MenuItem value='none'>
                          <div
                            style={{ fontSize: '1.05em', borderBottom: 'none' }}
                            className={classes.menuHeader}
                          >
                            None
                          </div>
                        </MenuItem>
                        {optionGroups.map((og, index) => {
                          let group = ''
                          if (og !== 'all') {
                            group = (
                              <MenuItem disabled style={{ opacity: 1 }}>
                                <div className={classes.menuHeader}>{og}</div>
                              </MenuItem>
                            )
                          }
                          og = og.toLowerCase()

                          const opts = options[og].map((opt, index) => {
                            return (
                              <MenuItem key={fieldName + index} value={opt.value}>
                                {opt.label}
                              </MenuItem>
                            )
                          })
                          return [group, opts]
                        })}
                      </Select>
                    </FormControl>
                  )
                case 'creditcard':
                case 'decimal':
                case 'numeric':
                case 'regex':
                case 'multi-line':
                case 'text':
                  return (
                    <TextField
                      id={fieldName}
                      key={index}
                      required={required}
                      label={fieldLabel}
                      disabled={state.saving}
                      className={classes.textField}
                      multiline={fieldType === 'multi-line'}
                      rows={fieldType === 'multi-line' ? 5 : 1}
                      color='primary'
                      variant='outlined'
                      onChange={editField}
                      value={state.ticketData[tf.id]}
                    />
                  )
                default:
                  return ''
              }
            })}
          </div>
          <div>
            <Button
              className={classes.actionButton}
              color='primary'
              onClick={save}
              variant='contained'
              disabled={state.saving}
            >
              Submit ticket
            </Button>
            <Button
              className={classes.actionButton}
              color='primary'
              onClick={() => setAdding(false)}
            >
              Cancel
            </Button>
          </div>
        </div>
      ) : (
        <Tooltip title={state.disabledMessage}>
          <div>
            <Button
              className={classes.actionAddButton}
              color='primary'
              onClick={() => setAdding(true)}
              variant='contained'
              disabled={state.disabledFeature}
            >
              + Create a new ticket
            </Button>
          </div>
        </Tooltip>
      )}
      <CustomizedSnackbar state={snackState} link={state.newTicketLink} handler={setSnackState} />
    </BaseCard>
  )
}

export default AddTicket
