import { useDoQuery } from './useDoQuery'
import { GenericObject } from './genericObject'
import { castToDate, DateType } from './classHelpers'
import { doPatch, doPost } from 'api/api'

interface KeywordGroupsList {
  list: KeywordGroups[]
  dict: { [id: KeywordGroups['id']]: KeywordGroups }
}

const initialState = {
  name: '',
  description: '',
  comparison: '',
  keywords: [],
  include_punctuation: false
}

export class KeywordGroups extends GenericObject {
  description: string
  comparison: string
  keywords: string[]
  createdTimestamp: DateType
  includePunctuation: boolean

  static loadAll (): { data: KeywordGroupsList, isLoading: boolean } {
    return useDoQuery({ path: '/keyword_groups:search', objectClass: KeywordGroups, useChatURL: true })
  }

  static save ({ data }: { data: KeywordGroups }): Promise<any> {
    let path = '/keyword_groups'
    const body = {
      attributes: { ...data }
    }

    if (data?.id) {
      path += `/${data.id}`
      return doPatch({ path: path, data: body, useChatURL: true })
    } else { return doPost({ path: path, data: body, useChatURL: true }) }
  }

  constructor ({ row }: { row?: Record<string, any> }) {
    super({ row })
    const keywordGroup = row?.attributes || initialState
    this.name = keywordGroup.name
    this.description = keywordGroup.description
    this.comparison = keywordGroup.comparison
    this.keywords = keywordGroup.keywords
    this.createdTimestamp = castToDate(keywordGroup.created_timestamp)
    this.includePunctuation = keywordGroup.include_punctuation
  }
}

export default KeywordGroups
