export const paymentSubtitle = 'Enter your payment information here to continue using Signals without ' +
"interruption after your trial ends. We won't charge you until your trial is over."

export function PaymentPage () {
  return (
    <div>
      Payment Page
    </div>
  )
}
