import { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ImageIcon from '@material-ui/icons/Image'
import EditIcon from '@material-ui/icons/Edit'
import ClearIcon from '@material-ui/icons/Clear'
import Avatar from '@material-ui/core/Avatar'
import Link from '@material-ui/core/Link'
import Badge from '@material-ui/core/Badge'
import base64 from 'base64-js'
import { useDropzone } from 'react-dropzone'
import PhotoEditor from './PhotoEditor'

const useStyles = makeStyles(() => ({
  dropzone: {
    height: '75px',
    display: 'flex',
    flexDirection: 'column',
    border: '1px dashed #808080',
    borderRadius: '4px',
    padding: '16px',
    margin: '16px 0px',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center'
  },
  dropzoneImageIcon: {
    margin: 'auto',
    textAlign: 'center',
    verticalAlign: 'center',
    color: '#808080'
  },
  paragraph: {
    color: 'gray',
    fontSize: 14,
    margin: 0
  },
  paragraph2: {
    color: 'gray',
    fontSize: 12,
    margin: 0
  },
  avatar: {
    height: 60,
    width: 60,
    margin: 0,
    objectFit: 'cover',
    borderRadius: '1000px',
    pointerEvents: 'none'
  }
}))

const PhotoDropzone = ({ photo, setPhoto, iconIndex, setIconIndex, backgroundColor, editor, setDefaultIcon }) => {
  const classes = useStyles()
  const [editorOpen, setEditorOpen] = useState(false)
  const { getRootProps, getInputProps } = useDropzone({
    acceptedFiles: 'image/*',
    maxFiles: 1,
    onDrop: acceptedFiles => {
      const fileReader = new FileReader()
      fileReader.onload = (e) => {
        const { result } = e.target
        if (result) {
          setPhoto({
            file: result,
            editedFile: null,
            preview: result
          })
        }
      }
      if (/image\/*/.test(acceptedFiles[0].type)) {
        fileReader.readAsDataURL(acceptedFiles[0])
        setEditorOpen(true)
        setIconIndex(-1)
      } else {
        alert('You need to use an image file.')
      }
    }
  })

  const onHide = () => {
    setEditorOpen(false)
  }

  const handleSave = () => {
    setEditorOpen(false)
    setPhoto({
      file: photo.file,
      editedFile: base64.toByteArray(editor.current.getImageScaledToCanvas().toDataURL('image/png').replace(/^data:image\/(png|jpg);base64,/, '')),
      preview: editor.current.getImageScaledToCanvas().toDataURL('image/png')
    })
  }

  if (photo.preview) {
    return (
      <div className={classes.dropzone}>
        <Badge
          color='primary'
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          badgeContent={
            <ClearIcon onClick={() => { setPhoto({ file: null, editedFile: null, preview: '' }); setIconIndex(0) }} style={{ cursor: 'pointer', fontSize: 12, color: 'white' }} />
          }
        >
          <Badge
            color='primary'
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            badgeContent={
              <EditIcon onClick={() => setEditorOpen(true)} style={{ cursor: 'pointer', fontSize: 12, color: 'white' }} />
            }
          >
            <div onClick={() => setIconIndex(-1)} className={classes.avatarClick}>
              <Avatar
                src={photo.preview}
                className={classes.avatar}
                style={{ boxShadow: (iconIndex === -1 ? '0px 3px 3px rgba(0,0,0,0.2), 0px 1px 15px -3px rgba(0,0,0,0.4), 0px 0px 0px 4px #F4F2F7, 0px 0px 0px 8px #9933FF' : '0px 3px 3px rgba(0,0,0,0.2), 0px 1px 15px -3px rgba(0,0,0,0.4)'), background: backgroundColor }}
              />
            </div>
          </Badge>
        </Badge>
        <PhotoEditor handleSave={handleSave} onHide={onHide} open={editorOpen} editor={editor} photo={photo.file} />
      </div>
    )
  } else {
    return (
      <div className={classes.dropzone} {...getRootProps()}>
        <input {...getInputProps()} />
        <ImageIcon fontSize='large' className={classes.dropzoneImageIcon} />
        <p className={classes.paragraph}>Drag & drop an image Here</p>
        <p className={classes.paragraph2}>or <Link target='_blank' rel='noreferrer' style={{ cursor: 'pointer' }}>browse files</Link> on your computer</p>
      </div>
    )
  }
}

export default PhotoDropzone
