/* eslint-disable @typescript-eslint/naming-convention */
import { useMemo, useState } from 'react'
import DateRangeSelector, { dateHelper, StartEndDate } from 'cf-components/DateRangeSelector'
import { Select } from 'cf-components/material-wrappers/MaterialComponents'
import { OutOfOfficeIcon } from './UserWarningIcons'
import dateFormat from 'dateformat'
import { User } from 'classes/users'
import { ModalSection } from 'library/Modal'
import { Checkbox } from 'library/materialUI'

function UserOutOfOfficeSelect (props: any): any {
  const { setOutOfOffice, outOfOffice, initialState, handleChange, setUpdateType } = props

  const allData = useMemo(() => {
    return props.allData
  }, [props.allData])

  function handleSelect (id: number): void {
    let instanceToSet
    if (id === 0) {
      instanceToSet = initialState
      handleChange({ data: instanceToSet, type: 'new' })
      setUpdateType('post')
    } else {
      instanceToSet = allData.find((instance: any) => instance.id === id)
      setUpdateType('update')
    }

    setOutOfOffice(instanceToSet)
  }

  const options = [{
    value: 0,
    label: 'New Out of Office'
  }].concat(allData.map((instance: any) => {
    const range = {
      startDate: typeof instance.attributes.start_date === Date() ? instance.attributes.start_date : dateHelper.stringToDate(instance.attributes.start_date),
      endDate: typeof instance.attributes.end_date === Date() ? instance.attributes.end_date : dateHelper.stringToDate(instance.attributes.end_date)
    }
    const label = dateHelper.dateToString(range.startDate, 'mm/dd/yyyy') + ' - ' + dateHelper.dateToString(range.endDate, 'mm/dd/yyyy')
    return { value: instance.id, label }
  }))

  return (
    <Select
      value={outOfOffice.id ? outOfOffice.id : 0}
      onChange={(value: number) => handleSelect(value)}
      placeholder='Select out of office'
      options={options}
      disableClear
    />
  )
}

interface UserOutOfOfficeProps {
  user: User
  editOutOfOffice: Function
  setCurrentSelection: Function
  currentSelection: any
  initialState: any
  setUpdateType: Function
}

export function UserOutOfOffice (props: UserOutOfOfficeProps): any {
  const { editOutOfOffice, setCurrentSelection, currentSelection, initialState, setUpdateType } = props
  const allData = props.user.outOfOffice
  const [focusOutOfOfficeModalData, setFocusOutOfOfficeModalData] = useState(currentSelection)

  function handleChange ({ type, range, data }: { type: string, range?: StartEndDate, data?: any }): void {
    let updates
    switch (type) {
      case 'new':
        updates = data
        break
      case 'dateRange':
        updates = {
          ...focusOutOfOfficeModalData,
          attributes: {
            ...focusOutOfOfficeModalData.attributes,
            start_date: range?.startDate,
            end_date: range?.endDate
          }
        }
        break
      case 'calendarDrop':
        updates = {
          ...focusOutOfOfficeModalData,
          attributes: {
            ...focusOutOfOfficeModalData.attributes,
            rotation_availability: {
              liveChat: focusOutOfOfficeModalData.attributes.rotation_availability.liveChat, calendarDrops: !focusOutOfOfficeModalData.attributes.rotation_availability.calendarDrops
            }
          }
        }
        break
      case 'liveChat':
        updates = {
          ...focusOutOfOfficeModalData,
          attributes: {
            ...focusOutOfOfficeModalData.attributes,
            rotation_availability: {
              calendarDrops: focusOutOfOfficeModalData.attributes.rotation_availability.calendarDrops, liveChat: !focusOutOfOfficeModalData.attributes.rotation_availability.liveChat
            }
          }
        }
        break
    }
    setFocusOutOfOfficeModalData(updates)
    editOutOfOffice(updates)
  }

  const dateRange = {
    startDate: typeof focusOutOfOfficeModalData.attributes.start_date === 'string'
      ? dateHelper.stringToDate(focusOutOfOfficeModalData.attributes.start_date) : focusOutOfOfficeModalData.attributes.start_date,
    endDate: typeof focusOutOfOfficeModalData.attributes.end_date === 'string'
      ? dateHelper.stringToDate(focusOutOfOfficeModalData.attributes.end_date) : focusOutOfOfficeModalData.attributes.end_date
  }

  return (
    <div>
      {currentSelection?.id &&
        <ModalSection
          subtitle='Schedule or edit out of office'
        >
          <UserOutOfOfficeSelect
            initialState={initialState}
            allData={allData}
            setOutOfOffice={setFocusOutOfOfficeModalData}
            outOfOffice={focusOutOfOfficeModalData}
            handleChange={handleChange}
            setCurrentSelection={setCurrentSelection}
            editOutOfOffice={editOutOfOffice}
            setUpdateType={setUpdateType}
          />
        </ModalSection>}
      <ModalSection>
        <div style={{ color: 'grey' }}>
          <div>Mark an agent as ‘out of office’ to temporarily keep them from getting routed into conversations and/or having their calendar dropped. This can be useful if an agent is:</div>
          <ul>
            <li>On vacation</li>
            <li>Out sick</li>
            <li>On a leave of absence</li>
          </ul>
        </div>
      </ModalSection>
      <ModalSection>
        <div style={{ color: 'grey' }}>When will the agent be out of office?</div>
        <div style={{ margin: '12px 0' }}>
          <DateRangeSelector
            label='Out of office dates'
            range={dateRange}
            handleSelect={range => {
              handleChange({ type: 'dateRange', range: range })
            }}
          />
        </div>
      </ModalSection>
      <ModalSection>
        <div style={{ color: 'grey' }}>What rotations should the agent be taken out of during this time?</div>
        <div>
          <Checkbox
            checked={!focusOutOfOfficeModalData.attributes.rotation_availability.liveChat}
            onChange={() => {
              handleChange({ type: 'liveChat' })
            }}
            label={<div>Live chats</div>}
          />
        </div>
        <div>
          <Checkbox
            checked={!focusOutOfOfficeModalData.attributes.rotation_availability.calendarDrops}
            onChange={() => {
              handleChange({ type: 'calendarDrop' })
            }}
            label={<div>Calendar drops</div>}
          />
        </div>
      </ModalSection>
    </div>
  )
}

/**
 * This will create a little OutOfOffice should one exist
 * @param {{outOfOffice: any; action: (e) => void;}} props
 */
export function OutOfOfficeDisplay (props: any): JSX.Element {
  const outOfOffice = props.outOfOffice

  if (!outOfOffice) {
    return <></>
  }

  const dateRange = {
    startDate: dateHelper.stringToDate(outOfOffice.attributes.start_date),
    endDate: dateHelper.stringToDate(outOfOffice.attributes.end_date)
  }
  const text = dateFormat(dateRange.startDate, 'mmm dd, yyyy') + ' - ' + dateFormat(dateRange.endDate, 'mmm dd, yyyy')
  return (
    <OutOfOfficeIcon text={text} action={props.action} />
  )
}
