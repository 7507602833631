import GenericObject from './genericObject'
import * as yup from 'yup'
import { doPost } from 'api/api'

export const taskSchema = yup.object({
  subject: yup.string().required(),
  personID: yup.string().required(),
  userID: yup.string().required(),
  taskType: yup.string().required(),
  dueDate: yup.string().required(),
  remindAt: yup.string(),
  description: yup.string(),
  currentState: yup.string().required()
})

export class Task extends GenericObject {
  subject: string
  personID: string
  userID: string
  taskType: string
  dueDate: string
  currentState: string
  remindAt: string
  description: string

  constructor ({ row }: { row?: Record<string, any> } = {}) {
    super({ row })
    const attributes = row?.attributes
    this.subject = attributes?.subject
    this.personID = attributes?.person_id
    this.userID = attributes?.user_id
    this.taskType = attributes?.task_type
    this.dueDate = attributes?.due_date
    this.currentState = attributes?.current_state
    this.remindAt = attributes?.remind_at
    this.description = attributes?.description
  }

  static create (task: Task, integration: string): any {
    const path = '/integrations/crm/tasks'
    const attributes = { ...task }
    const data = {
      type: 'task',
      id: 0,
      attributes: attributes
    }
    const extraHeaders = {
      'x-integration': integration
    }
    return doPost({ path, data, extraHeaders })
  }
}
