import { Box, Fade, Tooltip } from '@material-ui/core'
import { Icon } from 'library/materialUI'
import { useState } from 'react'

export function CopyIconButton (props: any): JSX.Element {
  const content = props.content
  const [showVerification, setShowVerification] = useState(false)

  const copyText = (content: any) => {
    navigator.clipboard.writeText(content)
    setShowVerification(true)
    setTimeout(() => setShowVerification(false), 1500)
  }

  return (
    <>
      <Tooltip
        title='Copied to clipboard'
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 600 }}
        open={showVerification}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        placement='right'
      >
        <Box>
          <Tooltip title='Copy' placement='bottom'>
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => copyText(content)}
            >
              <Icon icon='copy' />
            </div>
          </Tooltip>
        </Box>
      </Tooltip>
    </>
  )
}
