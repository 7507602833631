import { React, useEffect, useReducer } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Modal, ModalSection } from 'library/Modal'
import { Select, MenuItem, FormControl, TextField, Tooltip } from '@material-ui/core'
import ButtonTextField from '../../../components/ButtonTextField'
import { KeywordGroups } from 'classes/keywordGroups'
import { Checkbox } from 'library/materialUI'
import { EditableChip } from 'library/EditableChip'

const useStyles = makeStyles(theme => ({
  subsection: {
    fontSize: '14px',
    fontWeight: 600
  },
  divider: {
    marginTop: 5,
    marginBottom: 5
  },
  keywords: {
    display: 'flex',
    flexFlow: 'wrap'
  },
  chip: {
    margin: 5
  },
  deleteIcon: {
    fill: 'white'
  },
  sectionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  emailForm: {
    fontFamily: 'Poppins, sans serif'
  },
  metaDetails: {
    display: 'grid',
    gridTemplateColumns: '1fr 2fr',
    gridGap: 20,
    marginBottom: 20
  }
}))

const reducer = (state, changes) => {
  return { ...state, ...changes }
}

function KeyWordGroupsModal (props) {
  const classes = useStyles()
  const { onHide } = props
  const [state, dispatch] = useReducer(reducer, props.state || {
    name: '', keywords: [], description: '', comparison: 'contains', createdTimestamp: new Date(), edit: false, includePunctuation: false
  })
  useEffect(() => {
    if (props.open) {
      dispatch({ ...props.state })
    }
  }, [props.open, props.state])

  // Call this instead of simply calling KeywordGroups.save(state) directly in the
  // on submit because the state will be read as the initial value (undefined)
  // This ensures we have the updated state and pass it in properly to save
  const DoSave = () => {
    KeywordGroups.save({ data: state }).then(_ => {
      if (props.saveGroup) {
        props.saveGroup()
      }
      onHide()
    }
    )
  }

  function addOption (label) {
    if (label === '') {
      return
    }
    const newOptions = state.keywords.concat(label)
    dispatch({ keywords: newOptions })
  }

  const handleDelete = optionToDelete => () => {
    const newOptions = state.keywords.filter(option => option !== optionToDelete)
    dispatch({ keywords: newOptions })
  }

  function handleChange (event) {
    const name = event.target.name
    const value = event.target.value
    switch (name) {
      case 'name':
        dispatch({ name: value })
        break
      case 'description':
        dispatch({ description: value })
        break
      case 'comparison':
        dispatch({ comparison: value })
        break
      default:
        break
    }
  }

  let saveButtonText = 'Create Group'
  let saveIcon = 'add'

  const disabled = state.name === '' || state.keywords.length === 0

  let title = 'New Keyword Group'
  if (state.edit) {
    title = 'Edit Keyword Group'
    saveButtonText = 'Save Group'
    saveIcon = 'save'
  }

  return (
    <Modal
      title={title}
      onHide={onHide}
      open={props.open}
      handleSave={DoSave}
      size='md'
      saveBtnText={saveButtonText}
      saveDisabled={disabled}
      saveIcon={saveIcon}
    >
      <ModalSection
        title='Group info'
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <TextField
            margin='dense'
            label='Group Name'
            value={state.name}
            onChange={handleChange}
            variant='outlined'
            fullWidth
            name='name'
          />
          <TextField
            margin='dense'
            label='Description'
            value={state.description}
            onChange={handleChange}
            variant='outlined'
            fullWidth
            name='description'
          />
        </div>
      </ModalSection>
      <ModalSection
        title='Keywords'
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div className={classes.subsection}>
            <div className={classes.keywords}>
              {
                state.keywords.map((option, index) => {
                  return (
                    <EditableChip
                      key={index}
                      value={option}
                      handleRemove={handleDelete(option)}
                      handleChange={value => {
                        const newOptions = state.keywords
                        newOptions[index] = value
                        dispatch({ keywords: newOptions })
                      }}
                    />
                  )
                })
              }
            </div>
            <div>
              <ButtonTextField addButton={addOption} placeholder='Type a keyword and hit enter...' />
            </div>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
            <div>
              Recognize responses that
            </div>
            <div style={{ width: 200, marginLeft: 10, marginRight: 10 }}>
              <FormControl variant='outlined' fullWidth margin='dense'>
                <Select
                  labelId='select-id'
                  margin='dense'
                  value={state.comparison}
                  onChange={handleChange}
                  name='comparison'
                >
                  <MenuItem disabled value=''>--Comparison Type--</MenuItem>
                  <MenuItem value='contains'>contain</MenuItem>
                  <MenuItem value='equals'>equal</MenuItem>
                  {/* <MenuItem value="similar">are similar to</MenuItem> */}
                </Select>
              </FormControl>
            </div>
            <div>
              any of these keywords.
            </div>
          </div>

          <Tooltip
            title='Including punctuation is viable for comparing domains or other forms of language that includes symbols (i.e .com, .org, etc.), but is not suggested for general keyword identification'
            placement='right'
          >
            <div style={{ width: 'fit-content' }}>
              <Checkbox
                label='Include punctuation'
                checked={state.includePunctuation}
                onChange={e => dispatch({ includePunctuation: e })}
              />
            </div>
          </Tooltip>
        </div>
      </ModalSection>
    </Modal>
  )
}

export default KeyWordGroupsModal
