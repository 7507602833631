import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Formik, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import Button from '@material-ui/core/Button'
import { InputAdornment, TextField } from '@material-ui/core'
import ErrorIcon from '@material-ui/icons/ErrorOutline'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'

const useStyles = makeStyles((theme) => ({
  invalidInput: {
    color: 'red',
    fontSize: 10,
    margin: '10px 0px',
    display: 'flex',
    alignItems: 'center',
    height: 20
  },
  passwordRequirements: {
    fontSize: 10,
    margin: '10px 0px'
  },
  topTextBox: {
    marginBottom: 8,
    marginTop: 0
  },
  bottomTextBox: {
    marginTop: 16,
    marginBottom: 4
  },
  errorIcon: {
    marginRight: 5,
    fontSize: 16,
    height: 20
  },
  visibility: {
    cursor: 'pointer'
  }
}))

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .trim()
    .required('Password is required')
    .min(10, 'Password is too short'),
  confirmPassword: Yup.string()
    .trim()
    .min(10, 'Passwords must match')
    .oneOf([Yup.ref('password')], 'Passwords must match')
    .required('Passwords must match')
})

const PasswordForm = props => {
  const classes = useStyles()
  const [focused, setFocused] = useState(false)
  const [visible, setVisibility] = useState(false)

  return (
    <>
      <Formik
        initialValues={{ password: '', confirmPassword: '' }}
        onSubmit={props.onSubmit}
        validationSchema={validationSchema}
        render={({ handleChange, values, errors, handleBlur, submitForm, touched }) => {
          function trimPasswords () {
            values.password = values.password.trim()
            values.confirmPassword = values.confirmPassword.trim()
            submitForm()
          }

          const passwordError = errors.password && touched.password && !focused
          const confirmationError = errors.confirmPassword && touched.confirmPassword && !focused
          const showRequirements = Object.keys(errors).length === 0 || focused || (!errors.password && !touched.confirmPassword)

          return (
            <Form>
              <TextField
                variant='outlined'
                margin='normal'
                fullWidth
                id='password'
                label='Password'
                name='password'
                type={visible ? 'text' : 'password'}
                error={passwordError}
                onChange={handleChange}
                onFocus={() => setFocused(true)}
                className={classes.topTextBox}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      {visible ? (
                        <VisibilityIcon
                          onClick={() => setVisibility(false)}
                          className={classes.visibility}
                        />
                      ) : (
                        <VisibilityOffIcon
                          onClick={() => setVisibility(true)}
                          className={classes.visibility}
                        />
                      )}
                    </InputAdornment>
                  )
                }}
              />

              <TextField
                variant='outlined'
                margin='normal'
                fullWidth
                name='confirmPassword'
                label='Confirm password'
                id='confirmPassword'
                type={visible ? 'text' : 'password'}
                error={confirmationError}
                onChange={handleChange}
                onBlur={e => {
                  handleBlur(e)
                  setFocused(false)
                }}
                onFocus={() => setFocused(true)}
                className={classes.bottomTextBox}
              />
              {showRequirements ? (
                <div className={classes.passwordRequirements}>
                  Password must be at least 10 characters and may contain letters,
                  numbers, and symbols
                </div>
              ) : (
                <div className={classes.invalidInput}>
                  <ErrorIcon className={classes.errorIcon} />
                  {passwordError ? (
                    <ErrorMessage name='password' component='div' />
                  ) : (
                    <ErrorMessage name='confirmPassword' component='div' />
                  )}
                </div>
              )}
              <Button
                type='button'
                fullWidth
                variant='contained'
                color='primary'
                className={classes.submit}
                onClick={trimPasswords}
              >
                Register
              </Button>
            </Form>
          )
        }}
      />
    </>
  )
}

export default PasswordForm
