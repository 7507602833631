import { doPost, doGet, doPatch } from './api'

function getSettings () {
  return doGet({ path: '/chat_settings', useChatURL: true })
}

function getInstallScript () {
  return doGet({ path: '/chat_settings/install/script', useChatURL: true })
}

function adminGetInstallScript (tenantID: number) {
  return doGet({ path: `/admin_portal/tenants/${tenantID}/script`, useChatURL: true })
}

function validateInstall ({ url }: { url: string }) {
  return doPost({
    path: '/chat_settings/install/validate',
    useChatURL: true,
    data: {
      attributes: {
        install_location: url
      }
    }
  })
}

function saveSettings ({ state }: { state: any }) {
  const path = '/chat_settings'
  if ('id' in state) {
    return doPatch({ useChatURL: true, path, data: state })
  } else {
    return doPost({ useChatURL: true, path, data: state })
  }
}

export { getSettings, saveSettings, getInstallScript, validateInstall, adminGetInstallScript }
