import React, { useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Slide from '@material-ui/core/Slide'
import { Card, CardHeader, CardContent } from '@material-ui/core'
import Loader from 'library/loading/Loader'

const useStyles = makeStyles(theme => ({
  cardContent: {
    paddingTop: 5,
    height: 'calc(100% - 83px)',
    paddingBottom: 10
  },
  cardHeader: {
    paddingBottom: 0
  },
  card: {
    height: '100%'
  }
}))

const loaderStyles = {
  inlineSize: 'max-content',
  marginLeft: 'auto',
  marginRight: 'auto',
  height: '100%',
  iconSize: 30,
  alignItems: 'center'
}

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

export default function FullScreenDialog (props) {
  const classes = useStyles()
  const fullScreenRef = useRef(null)
  const visibility = props.visibility

  function closeModal () {
    fullScreenRef.current.dispose()
    props.setVisibility(false)
    props.onClose()
  }

  function setChart () {
    const chart = props.createChart()
    fullScreenRef.current = chart
  }

  return (
    <Dialog open={props.open} TransitionComponent={Transition} onEntered={setChart} maxWidth='xl' fullWidth onClose={closeModal}>
      <div style={{ height: '80vh' }}>
        <Card className={classes.card}>
          <CardHeader
            title={props.title}
            subheader={props.filter}
            className={classes.cardHeader}
            action={
              <IconButton onClick={closeModal}>
                <CloseIcon />
              </IconButton>
            }
          />
          <CardContent className={classes.cardContent}>
            <div
              style={{
                display: visibility === 'visible' ? 'none' : 'flex',
                ...loaderStyles
              }}
            >
              <Loader type='spinner' />
            </div>
            <div id='fullScreenChart' style={{ width: '100%', height: '100%', visibility: visibility }} />
          </CardContent>
        </Card>
      </div>
    </Dialog>
  )
}
