import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import FormTextField from './FormTextField'
import { Formik, Form, FastField, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import Button from '@material-ui/core/Button'
import { Checkbox, FormControlLabel } from '@material-ui/core'
import { checkEmailAvailability } from 'api/auth'

const validationSchema = Yup.object().shape({
  full_name: Yup.string()
    .required('Enter valid name')
    .min(4, 'Please enter a valid name'),
  email: Yup.string()
    .required('Enter valid email address')
    .email('Enter a valid email address')
    .test('spam', 'Please enter a business email', function (value) {
      if (isValidMailAddress(value)) {
        return true
      } else {
        return false
      }
    })
})

const devValidationSchema = Yup.object().shape({
  full_name: Yup.string()
    .required('Enter valid name')
    .min(4, 'Please enter a valid name'),
  email: Yup.string()
    .required('Enter valid email address')
    .email('Enter a valid email address')
})

const useStyles = makeStyles((theme) => ({
  form: {
    paddingRight: 50,
    paddingLeft: 50,
    marginTop: 40
  },
  submitButton: {
    width: '100%',
    height: 40
  },
  invalidInput: {
    color: 'red',
    fontSize: 12,
    height: 15
  },
  mobileForm: {
  }
}))

const isValidMailAddress = (email) => {
  // eslint-disable-next-line
  const match = /^\w+[-\.\+\w]*@(\w+[-\.\w]*?\.\w{2,4})$/.exec(email)
  if (!match) return false

  if (window.location.hostname === 'local.chatfunnels.dev') {
    return true
  }

  const forbiddenDomains = [
    'me.com',
    'mailinator.com',
    'yahoo.com',
    'aol.com',
    'outlook.com',
    'icloud.com',
    'hotmail.com',
    'gmx.us',
    'gmail.com',
    'comcast.net',
    'bolt.com',
    'ymail.com',
    'email.com',
    'live.com',
    'msn.com',
    'yahoo.fr',
    'yahoo.de',
    'mail.ru',
    'hotmail.co.uk',
    'yahoo.co.uk',
    'rocketmail.com',
    'mail.com',
    'yahoo.com.au',
    'yahoo.ie',
    'gmx.net',
    'hotmail.fi',
    'webmail.co.za',
    'yahoo.com.mx',
    'netscape.net',
    'comcast.net'
  ]

  if (forbiddenDomains.indexOf(match[1].toLowerCase()) >= 0) return false

  return true
}

const EmailForm = props => {
  const classes = useStyles()
  const [termsAccepted, setTermsAccepted] = useState(false)
  const [apiErrors, setAPIErrors] = useState([])
  const devMode = props.devMode

  const schema = devMode ? devValidationSchema : validationSchema

  const sendEmailConfirmation = (values) => {
    checkEmailAvailability(values.email)
      .then((response) => {
        if (response.ok) {
          props.onSubmit(values)
        } else {
          if (response.errors) {
            setAPIErrors(response.errors)
          }
        }
      })
  }

  return (
    <Formik
      initialValues={{ email: props.email || '', full_name: '' }}
      onSubmit={(values) => {
        sendEmailConfirmation(values)
      }}
      validationSchema={schema}
      enableReinitialize
      render={({ handleChange, errors, touched, values }) => (
        <Form>
          <div style={{ marginBottom: 5 }}>
            <FastField
              component={FormTextField}
              id='full_name'
              name='full_name'
              label='Full Name'
              fullWidth
            />
            <div className={classes.invalidInput}>
              <ErrorMessage name='full_name' component='div' />
            </div>
          </div>
          <div>
            <FastField
              component={FormTextField}
              id='email'
              name='email'
              label='Your Business Email'
              fullWidth
            />
          </div>
          {apiErrors.map((error, index) => (<div key={index} className={classes.invalidInput}>{error}</div>))}
          <div className={classes.invalidInput}>
            <ErrorMessage name='email' component='div' />
          </div>
          <FormControlLabel
            control={
              <Checkbox
                checked={termsAccepted}
                onChange={(e) => setTermsAccepted(e.target.checked)}
                name='autoToggle'
                color='primary'
              />
            }
            label={
              <div style={{ fontSize: 12 }}>
                I Agree to the <a target='_blank' rel='noopener noreferrer' href='https://chatfunnels.com/terms-and-conditions/'>Terms and Conditions</a>
              </div>
            }
          />
          <Button
            className={classes.submitButton}
            variant='contained'
            color='primary'
            type='submit'
            disabled={!termsAccepted || errors.email || !values.email}
          >
            Get Started
          </Button>
        </Form>
      )}
    />
  )
}

export default EmailForm
