import { useDoQuery } from './useDoQuery'
import { GenericObject } from './genericObject'
import { doDelete, doPatch, doPost } from 'api/api'
import { DateType } from './classHelpers'
import { getEmailSends } from 'api/emails'

interface EmailList {
  list: Email[]
  dict: {[id: Email['id']]: Email}
}

const initialValues = {
  description: '',
  layout: '',
  subect: '',
  body: '',
  kind: 'rich_text',
  from_type: '',
  from_name: '',
  from_email: '',
  attachments: []
}

export class EmailSummary extends GenericObject {
  sent: number
  delivered: number
  opened: number
  clicked: number
  bot: number

  get deliveredRate (): string {
    if (!this.sent || !this.delivered) {
      return '0.00%'
    }
    const rate = ((this.delivered / this.sent) * 100).toFixed(2).toString() + '%'
    return rate
  }

  get openedRate (): string {
    if (!this.delivered || !this.opened) {
      return '0.00%'
    }
    const rate = ((this.opened / this.delivered) * 100).toFixed(2).toString() + '%'
    return rate
  }

  get clickedRate (): string {
    if (!this.opened || !this.clicked) {
      return '0.00%'
    }
    const rate = ((this.clicked / this.opened) * 100).toFixed(2).toString() + '%'
    return rate
  }

  constructor ({ row }: { row: Record<string, any> }) {
    super({ row })
    const summary = row.attributes
    this.sent = summary.total_sent || 0
    this.delivered = summary.total_delivered || 0
    this.opened = summary.total_opened || 0
    this.clicked = summary.total_clicked || 0
    this.bot = summary.bot_id
  }
}
export default class Email extends GenericObject {
  description: string
  layout: string
  subject: string
  body: string
  kind: 'rich_text' | 'logo' | 'banner'
  fromType: 'account_owner' | 'default'
  fromName: string
  fromEmail: string
  lastEditedTimestamp: DateType
  updatedUserID: number
  attachments: Array<any> | null
  isActive: boolean
  objectType = 'email'

  get link (): string { return `/emails/edit/${this.id}` }

  static loadAll (): { data: EmailList, isLoading: boolean } {
    return useDoQuery({
      path: '/emails',
      objectClass: Email,
      useChatURL: true
    })
  }

  static loadStats (emailID: number): { data: any, isLoading: boolean } {
    return useDoQuery({ useChatURL: true, path: `/reports/emails/${emailID}/details`, objectClass: EmailSummary })
  }

  static getRosterData (id: number): Promise<any> {
    return getEmailSends({ emailID: id })
  }

  static save ({ data } : {data: Email}) : Promise<any> {
    let path = '/emails'
    const body = {
      attributes: { ...data }
    }

    if (data?.id) {
      path += `/${data.id}`
      return doPatch({ path, data: body, useChatURL: true })
    } else { return doPost({ path, data: body, useChatURL: true }) }
  }

  static create (): void {
    window.open('/#/emails/new')
  }

  async clone (): Promise<string> {
    const path = `/emails/${this.id}:clone`
    return doPost({ path, useChatURL: true }).then((response: any) => {
      return '/emails/edit/' + response.data.id
    })
  }

  static removeEmail (id: number, silent: boolean): Promise<any> {
    const path = `/emails/${id}`
    return doDelete({ path, useChatURL: true, silent })
  }

  delete () {
    const path = `/emails/${this.id}`
    return doDelete({ path, useChatURL: true })
  }

  constructor ({ row }: { row?: Record<string, any> } = {}) {
    super({ row })
    const email = row?.attributes || initialValues
    this.description = email.description
    this.layout = email.layout
    this.subject = email.subject
    this.body = email.body
    this.kind = email.kind
    this.fromType = email.from_type
    this.fromName = email.from_name
    this.fromEmail = email.from_email
    this.lastEditedTimestamp = email.updated_timestamp
    this.updatedUserID = email.updated_by_user_id
    this.attachments = email.attachments
    this.isActive = email.is_active
  }
}
