import { useContext, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import BaseCard from './BaseCard'
import { useLocation } from 'react-router-dom'
import { getNotes, addNote, deleteNote, updateNote } from 'api/conversation_notes'
import { useQuery, useQueryClient } from 'react-query'
import { TenantDataContext } from '../../tenant-data-context'
import { ConversationContext } from '../../ConversationContext'
import dateFormat from 'dateformat'
import Avatar from '@material-ui/core/Avatar'
import Badge from '@material-ui/core/Badge'
import AddNoteModal from 'pages/chat/notes/AddNoteModal'
import AddIcon from '@material-ui/icons/Add'
import NotesModal from 'pages/chat/notes/NotesModal'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'

const useStyles = makeStyles(theme => ({
  noteDisplay: {
    display: 'grid',
    gridTemplateColumns: '40px auto',
    alignItems: 'center',
    gridGap: 10,
    marginBottom: 15
  },
  noteDetails: {
    fontSize: '13px'
  },
  noteMeta: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: 'auto 85px',
    gridGap: 10
  },
  agentName: {
    color: theme.palette.primary.main,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    fontWeight: 600
  },
  timestamp: {
    fontSize: '9px',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  actionIconHolder: {
    display: 'flex',
    alignItems: 'center'
  },
  actionIcon: {
    height: 20,
    padding: 0,
    cursor: 'pointer'
  },
  noteItem: {
    cursor: 'pointer'
  },
  noteBody: {
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical'
  },
  checkedIcon: {
    fill: '#308446',
    height: '.8em',
    paddingLeft: 5,
    paddingBottom: 5
  },
  emptyState: {
    display: 'flex',
    justifyContent: 'center',
    margin: 10,
    paddingTop: 10
  }
}))

function useQuerySearch () {
  return new URLSearchParams(useLocation().search)
}

function NoteAvatar (props) {
  const classes = useStyles()
  const agent = props.agent
  const resolved = props.resolved
  const avatarUrl = agent.avatarUrl

  if (resolved) {
    return (
      <Badge
        overlap='circle'
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        badgeContent={
          <CheckCircleIcon className={classes.checkedIcon} />
        }
      >
        <Avatar
          src={avatarUrl}
        />
      </Badge>
    )
  }

  return (
    <Avatar
      src={avatarUrl}
    />
  )
}

function NoteDisplay (props) {
  const classes = useStyles()
  const note = props.note
  const attributes = note.attributes
  const participantID = attributes.created_by_participant_id
  const { users } = useContext(TenantDataContext)
  const user = users.dict[participantID]

  return (
    <div className={classes.noteItem}>
      <div className={classes.timestamp}>
        {dateFormat(attributes.created_timestamp, 'mmm dd - h:MM TT')}
      </div>
      <div className={classes.noteDisplay}>
        <NoteAvatar
          agent={user}
          resolved={Boolean(note.attributes.resolved_timestamp)}
        />
        <div className={classes.noteDetails}>
          <div className={classes.agentName}>
            {user.name}
          </div>
          <div className={classes.noteBody}>
            {attributes.body}
          </div>
        </div>
      </div>
    </div>
  )
}

function CardContents (props) {
  const classes = useStyles()
  const [note, setNote] = useState(null)
  const notes = props.state?.parentNotes
  const childrenDictionary = props.state?.childrenDictionary
  const replies = note ? childrenDictionary[note.id] : []
  const query = useQuerySearch()
  const confirmDelete = () => {
    props.confirmDelete(note.id).then(() => setNote(null))
  }
  const handleStatus = (noteID, status) => {
    props.handleStatus(noteID, status).then(() => setNote(null))
  }

  useEffect(() => {
    const note_id = query.get('note_id')
    if (note_id && notes) {
      const note = notes.filter(n => n.id === parseInt(note_id))[0]
      if (note) {
        setNote(note)
        window.location.hash = window.location.hash.split('?')[0]
      }
    }
  }, [notes, query])

  if (!notes) {
    return <div />
  }
  if (!notes.length) {
    return (
      <div className={classes.emptyState}>
        No notes for this conversation
      </div>
    )
  }
  if (notes) {
    return (
      <div>
        {notes.map((n, index) => (
          <div onClick={() => setNote(n)} key={index}>
            <NoteDisplay
              note={n}
            />
          </div>
        ))}
        <NotesModal
          open={Boolean(note)}
          note={note}
          onHide={() => setNote(null)}
          replies={replies}
          createNote={props.createNote}
          deleteNote={confirmDelete}
          handleTagging={props.handleTagging}
          handleStatus={handleStatus}
        />
      </div>
    )
  }
}

function NotesCard (props) {
  const classes = useStyles()
  const { conversation } = useContext(ConversationContext)
  const [modalOpen, setModalOpen] = useState(false)
  const conversationID = conversation.id
  const chatServiceUrl = window.chatServiceUrl
  const queryClient = useQueryClient()

  const { data: state } = useQuery(conversationID + '/notes', () => getNotes({ chatServiceUrl, conversationID })
    .then(response => {
      const parentNotes = response.data.filter(note => !note.attributes.parent_note_id)
      const children = response.data.filter(note => !!note.attributes.parent_note_id)
      const childrenDictionary = {}
      for (const child of children) {
        const parentID = child.attributes.parent_note_id
        if (parentID in childrenDictionary) {
          childrenDictionary[parentID].push(child)
        } else {
          childrenDictionary[parentID] = [child]
        }
      }
      return {
        parentNotes,
        childrenDictionary
      }
    }), { staleTime: 60000 }
  )

  const refresh = () => queryClient.invalidateQueries(conversationID + '/notes')

  const handleTagging = (noteID, selectedIDs) => {
    const attributes = {
      tagged_user_ids: selectedIDs
    }
    updateNote({ noteID, chatServiceUrl, attributes })
      .then(() => refresh())
  }

  const handleStatus = (noteID, status) => {
    const attributes = {
      status: status
    }
    return updateNote({ noteID, chatServiceUrl, attributes })
      .then(() => refresh())
  }

  const confirmDelete = (noteID) => {
    return deleteNote({ noteID, chatServiceUrl })
      .then(() => refresh())
  }

  const createNote = ({ noteBody, taggedUserIDs, parentNoteID }) => {
    return addNote({ conversationID, noteBody, taggedUserIDs, chatServiceUrl, parentNoteID })
      .then(() => refresh())
  }

  return (
    <BaseCard
      title='Notes'
      id={props.id}
      actions={
        <div className={classes.actionIconHolder}>
          <AddIcon
            color='primary'
            className={classes.actionIcon}
            onClick={() => setModalOpen(true)}
          />
        </div>
      }
    >
      <CardContents
        state={state}
        createNote={createNote}
        confirmDelete={confirmDelete}
        handleTagging={handleTagging}
        handleStatus={handleStatus}
      />
      <AddNoteModal
        open={modalOpen}
        onHide={() => setModalOpen(false)}
        addNote={({ noteBody, taggedUserIDs }) => {
          createNote({ noteBody, taggedUserIDs })
          setModalOpen(false)
        }}
      />
    </BaseCard>
  )
}

export default NotesCard
