import { nodeMappingData } from 'library/flowBuilder/FlowBuilder'
import { categoryMapping } from './nodeCategories'

export type nodeType = keyof typeof nodeMapping

export const nodeMapping: Record<string, nodeMappingData> = {
  Start: {
    icon: 'start',
    title: 'Bot Entry',
    color: categoryMapping.start.color
  },
  Question: {
    icon: 'liveHelp',
    title: 'Question',
    color: categoryMapping.messaging.color
  },
  AddToCampaign: {
    icon: 'addContact',
    title: 'Add to Campaign',
    color: categoryMapping.internal.color
  },
  AlertAgent: {
    icon: 'notification',
    title: 'Alert Agent',
    color: categoryMapping.internal.color
  },
  FAQ: {
    icon: 'questionAnswer',
    title: 'FAQ',
    color: categoryMapping.messaging.color
  },
  Button: {
    icon: 'cta',
    title: 'Button',
    color: categoryMapping.messaging.color
  },
  Message: {
    icon: 'message',
    title: 'Message',
    color: categoryMapping.messaging.color
  },
  EmailCapture: {
    icon: 'email',
    title: 'Email Capture',
    color: categoryMapping.capture.color
  },
  PhoneCapture: {
    icon: 'phone',
    title: 'Phone Capture',
    color: categoryMapping.capture.color
  },
  Calendar: {
    icon: 'calendar',
    title: 'Calendar Invite',
    color: categoryMapping.capture.color
  },
  ConversationStatus: {
    icon: 'finished',
    title: 'Conversation Status',
    color: categoryMapping.internal.color
  },
  ConversationGoal: {
    icon: 'check',
    title: 'Conversation Goal',
    color: categoryMapping.internal.color
  },
  LiveChat: {
    icon: 'liveChat',
    title: 'Live Chat',
    color: categoryMapping.internal.color
  },
  RateConversation: {
    icon: 'thumbUpDown',
    title: 'Conversation Rating',
    color: categoryMapping.internal.color
  },
  TagConversation: {
    icon: 'tag',
    title: 'Tag Conversation',
    color: categoryMapping.internal.color
  },
  SendEmail: {
    icon: 'email',
    title: 'Send Email',
    color: categoryMapping.internal.color
  },
  LeadScore: {
    icon: 'star',
    title: 'Set Lead Score',
    color: categoryMapping.internal.color
  },
  Sequence: {
    icon: 'play',
    title: 'Sequence',
    color: categoryMapping.internal.color
  },
  SequenceExit: {
    icon: 'play',
    title: 'Sequence Exit',
    color: categoryMapping.internal.color
  },
  EndFlow: {
    icon: 'exit',
    title: 'End Flow',
    color: categoryMapping.internal.color
  }
}
