import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import DeleteIcon from '@material-ui/icons/DeleteForever'
import EditIcon from '@material-ui/icons/Edit'
import AssessmentIcon from '@material-ui/icons/Assessment'
import Table from 'cf-components/Table'
import Link from 'cf-components/Link'
import { ListItemCard } from 'cf-components/CardList'
import { AvatarWithInfo, getInitials, getColor } from 'cf-components/Avatars'
import ActionsMenu from 'cf-components/ActionsMenu'
import dateFormat from 'dateformat'
import { Redirect } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  testCard: {
    display: 'grid',
    gridTemplateColumns: '2fr 1fr 1fr 3fr 50px',
    alignItems: 'center',
    gridGap: 25,
    cursor: 'pointer',
    padding: 15,
    fontSize: '0.875rem',
    fontWeight: 500
  },
  headerClass: {
    display: 'grid',
    gridTemplateColumns: '2fr 1fr 1fr 3fr 50px',
    alignItems: 'center',
    padding: '5px 15px',
    gridGap: 25
  },
  statusDiv: {
    borderRadius: 20,
    fontWeight: 600,
    fontSize: '0.875rem',
    border: 'solid 1px',
    padding: 5,
    textAlign: 'center',
    width: 100,
    paddingRight: 20,
    paddingLeft: 20,
    marginLeft: -20
  },
  descriptionDiv: {
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
    maxHeight: 40
  },
  descriptionWrapper: {
    display: 'inline',
    verticalAlign: 'middle'
  }
}))

function getStatus (test) {
  let status = test.status
  const now = new Date()
  const endDate = new Date(test.end_time)
  const startDate = new Date(test.start_time)
  const winningFlowID = test.winning_flow_id
  const criteria = test.duration_criteria

  if (startDate > now && status === 'active') {
    status = 'scheduled'
  }
  if (now > endDate && status === 'active' && criteria === 'time_based') {
    status = 'completed'
  }
  if (status === 'completed' && !winningFlowID) {
    status = 'pending'
  }
  if (test.status === 'canceled') {
    status = 'ended'
  }
  if (test.deleted_timestamp) {
    status = 'archived'
  }

  return status.charAt(0).toUpperCase() + status.slice(1)
}

const statusColors = {
  Paused: 'rgba(230,200,60, 1.0)',
  Active: 'rgba(0,128,0,0.7)',
  Ended: 'rgba(255,0,0,0.7)',
  Completed: 'rgba(142,90,226,1.0)',
  Scheduled: 'rgba(255, 165, 0, 1.0)',
  Pending: 'rgb(0,0,0)',
  Archived: 'rgba(255,0,0,0.7)'
}

const testTypeDict = {
  ab: 'A/B Test'
}

function TestList (props) {
  const classes = useStyles()

  const handleEditCriteria = (id, durationCriteria, startTime, endTime, name) => {
    props.onEditEndCriteria({ id, durationCriteria, startTime, endTime, name })
  }

  const handleEdit = (id, name, type) => {
    props.onEdit(id, name, type)
  }

  const handleDelete = (id) => {
    props.onDelete(id)
  }

  function TestCard (props) {
    const row = props.row
    const color = getColor(props.index)
    const [redirect, setRedirect] = useState(null)
    const subtitle = testTypeDict[row.type]

    const actions = [
      { name: 'View Report', action: () => setRedirect(`/bot_tests/dashboard/${row.id}`), icon: AssessmentIcon }
    ]

    if (row.status !== 'Archived') {
      actions.push({ name: 'Archive', action: () => handleDelete(row.id), icon: DeleteIcon })
    }

    if (row.status === 'Active' || row.status === 'Scheduled') {
      actions.push({
        name: 'Edit End Criteria',
        action: () => {
          handleEditCriteria(row.id, row.durationCriteria, row.startTime, row.endTime, row.name)
        },
        icon: EditIcon
      })
    }

    if (row.status === 'scheduled') {
      actions.push({
        name: 'Edit',
        action: () => {
          handleEdit(row.id, row.name, row.test_type)
        },
        icon: EditIcon
      })
    }

    if (redirect) {
      return <Redirect push to={redirect} />
    }

    return (
      <ListItemCard>
        <Link to={`/bot_tests/dashboard/${row.id}`}>
          <div className={classes.testCard}>
            <div style={{ minWidth: 120 }}>
              <AvatarWithInfo
                title={row.name}
                initials={row.initials}
                subtitle={subtitle}
                avatarColor={color}
                variant='rounded'
              />
            </div>
            <div
              className={classes.statusDiv}
              style={{ color: statusColors[row.status] }}
            >
              {row.status}
            </div>
            <div>
              {dateFormat(row.createdDate, 'mmmm dd, yyyy')}
            </div>
            <div className={classes.descriptionWrapper}>
              <div className={classes.descriptionDiv}>
                {row.description}
              </div>
            </div>
            <ActionsMenu
              actions={actions}
            />
          </div>
        </Link>
      </ListItemCard>
    )
  }

  const sortValues = [
    { label: 'Name', id: 'name', header: true },
    { label: 'Status', id: 'status', header: true },
    { label: 'Created', id: 'createdDate', header: true },
    { label: 'Description', id: 'description', header: true }
  ]

  const rows = props.rows ? props.rows.map(row => {
    const test = row.attributes
    const name = test.name
    const initials = getInitials(name, 'T')
    const status = getStatus(test)
    const createdDate = test.created_timestamp

    return {
      id: row.id,
      name: name,
      initials: initials,
      status: status,
      createdDate: createdDate,
      description: test.description,
      searchField: name,
      type: test.test_type,
      durationCriteria: test.duration_criteria || '',
      startTime: test.start_time,
      endTime: test.end_time || null
    }
  }) : null

  return (
    <Table
      rows={rows}
      card={TestCard}
      sortValues={sortValues}
      header
      headerClass={classes.headerClass}
      startingSortColumn='createdDate'
      filterOptions={props.filterOptions}
      sortAscending={false}
    />
  )
}

export default TestList
