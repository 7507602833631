/* eslint no-empty-pattern: 0 */ // --> OFF
import FormControl from '@material-ui/core/FormControl'
import { revenueRanges } from 'classes/revenueRanges'
import { employeeRanges } from 'classes/employeeRanges'
import FormHelperText from '@material-ui/core/FormHelperText'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import RemoveIcon from '@material-ui/icons/HighlightOff'
import StarIcon from '@material-ui/icons/Star'
import { TimePicker } from '@material-ui/pickers'
import { getBots } from 'api/bots'
import { getCustomFields } from 'api/custom_fields'
import { getMeetingTypes } from 'api/meetings'
import { getSegments } from 'api/segments'
import { getTeams } from 'api/teams'
import GeographySelector from 'cf-components/GeographySelector'
import Picker from 'cf-components/Pickers'
import CurrencyInput from 'components/CurrencyInput'
import { useCallback, useContext, useEffect, useReducer, useState } from 'react'
import { useQuery } from 'react-query'
import { SessionContext } from 'session-context'

const useStyles = makeStyles(theme => ({
  field: props => ({
    backgroundColor: props.backgroundColor || '#FFFFFF',
    minWidth: '145px',
    width: '100%',
    height: 'max-content',
    '& input': { textOverflow: 'ellipsis' }
  }),
  formControlField: {
    width: '100%'
  },
  adornment: {
    color: theme.palette.primary.main,
    fontWeight: 600
  },
  menuHeader: {
    borderBottom: '1px solid #BBB',
    width: '100%',
    fontWeight: 600,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: 'rgba(0,0,0,0.87)'
  },
  menuItem: {
    paddingTop: 3,
    paddingBottom: 3,
    paddingLeft: 25,
    fontSize: '.95em'
  },
  star: {
    fill: 'gold',
    fontSize: '1rem'
  },
  leadScoreMenuItem: {
    display: 'flex',
    alignItems: 'center',
    margin: 0,
    padding: 0
  },
  anotherFieldCtn: {
    position: 'relative',
    '& .MuiInputAdornment-positionEnd': { marginLeft: 0 }
  },
  orText: {
    position: 'absolute',
    color: theme.palette.primary.main,
    left: 0,
    fontSize: 14,
    top: '50%',
    transform: 'translate(-100%, -50%)',
    paddingRight: 12
  },
  adornmentButton: {
    padding: 0
  }
}))

const ITEM_HEIGHT = 80
const ITEM_PADDING_TOP = 8
// put this as MenuProps after every <Select> with the prop of multiple to keep from jumping around when selecting stuff
const MultiSelectMenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 7.5 + ITEM_PADDING_TOP,
      width: 250
    }
  },
  MenuListProps: {
    style: {
      paddingTop: 0
    }
  },
  getContentAnchorEl: null,
  variant: 'menu',
  anchorOrigin: {
    vertical: 'center',
    horizontal: 'left'
  },
  transformOrigin: {
    vertical: 'center',
    horizontal: 'left'
  }
}

function FilterAccountFieldSelect ({
  field,
  form: { setFieldValue, setFieldTouched, values },
  ...props
}) {
  const classes = useStyles(props.styles)

  const [customFields, setCustomFields] = useState(null)

  useEffect(() => {
    let mounted = true
    getCustomFields('accounts').then(response => {
      const customFields = response.data.map(f => (
        { value: f.attributes.slug, name: f.attributes.label }
      ))
      if (mounted) setCustomFields(customFields)
    })
    return () => { mounted = false }
  }, [props.type])

  useEffect(() => {
    if (field.value) {
      // This is to remove this way of comparing account attributes
      // This converts it to the new way of prop being like 'accounts_annual_revenue'
      setFieldValue(props.propPrefix + '.prop', 'accounts_' + field.value)
    }
  }, [field.value, props.propPrefix, setFieldValue])

  const standardFields = [
    { value: 'account_type', name: 'Account type' },
    { value: 'name', name: 'Account name' },
    { value: 'annual_revenue', name: 'Annual revenue' },
    { value: 'address_city', name: 'city' },
    { value: 'domain', name: 'Domain' },
    { value: 'employee_count', name: 'Employee count' },
    { value: 'industry', name: 'Industry' },
    { value: 'address_state', name: 'State' }
  ]

  const menuItems = standardFields.concat(customFields || [])

  return (
    <FormControl
      variant='outlined'
      margin='dense'
      fullWidth
    >
      <InputLabel htmlFor={props.name}>
        Attribute
      </InputLabel>
      <Select
        {...field}
        inputProps={{
          name: props.name,
          id: props.id
        }}
        label='Attribute'
        onBlur={() => setFieldTouched(field.name)}
        onChange={e => setFieldValue(field.name, e.target.value)}
        className={classes.field}
        MenuProps={MultiSelectMenuProps}
      >
        <MenuItem value='' disabled>Select an Account attribute</MenuItem>
        {menuItems.map(prop => (
          <MenuItem value={prop.value} key={prop.value}>{prop.name}</MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

function FilterContactFieldSelect ({
  field,
  form: { setFieldValue, setFieldTouched, values },
  ...props
}) {
  const classes = useStyles(props.styles)

  const [customFields, setCustomFields] = useState(null)

  useEffect(() => {
    let mounted = true
    getCustomFields('people').then(response => {
      const customFields = response.data.map(f => (
        { value: f.attributes.slug, name: f.attributes.label }
      ))
      if (mounted) setCustomFields(customFields)
    })
    return () => { mounted = false }
  }, [props.type])

  useEffect(() => {
    if (field.value) {
      // This is to remove this way of comparing contact attributes
      // This converts it to the new way of prop being like 'contacts_first_name'
      setFieldValue(props.propPrefix + '.prop', 'contacts_' + field.value)
    }
  }, [field.value, props.propPrefix, setFieldValue])

  const standardFields = [
    { value: 'email', name: 'Email' },
    { value: 'first_name', name: 'First Name' },
    { value: 'last_name', name: 'Last Name' },
    { value: 'company_name', name: 'Company Name' },
    { value: 'company_industry', name: 'Industry' },
    { value: 'phone', name: 'Phone' },
    { value: 'lead_score', name: 'Lead Score' },
    { value: 'city', name: 'City' },
    { value: 'state', name: 'State' },
    { value: 'description', name: 'Description' },
    { value: 'company_website', name: 'Company Website' },
    { value: 'company_employee_count', name: 'Company Employee Count' },
    { value: 'company_revenue', name: 'Company Revenue' },
    { value: 'title', name: 'Title' }
  ]

  const menuItems = standardFields.concat(customFields || [])

  return (
    <FormControl
      variant='outlined'
      margin='dense'
      fullWidth
    >
      <InputLabel htmlFor={props.name}>
        Attribute
      </InputLabel>
      <Select
        {...field}
        inputProps={{
          name: props.name,
          id: props.id
        }}
        label='Attribute'
        onBlur={() => setFieldTouched(field.name)}
        onChange={e => setFieldValue(field.name, e.target.value)}
        className={classes.field}
      >
        <MenuItem value='' disabled>Select a Contact attribute</MenuItem>
        {menuItems.map(prop => (
          <MenuItem value={prop.value} key={prop.value}>{prop.name}</MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

function FilterPropSelect ({
  field,
  form: { setFieldValue, setFieldTouched, values, ...form },
  ...props
}) {
  const classes = useStyles(props.styles)

  const FilterGroups = [
    { value: 'web_page', name: 'Web Page' },
    { value: 'demographics', name: 'Demographics' },
    { value: 'visitor_behavior', name: 'Visitor Behavior' },
    { value: 'availability', name: 'Availability' },
    { value: 'visitor_information', name: 'Visitor Information' },
    { value: 'contact_information', name: 'Contact Information' },
    { value: 'custom_contact_fields', name: 'Custom Contact Fields' },
    { value: 'account_information', name: 'Account Information' },
    { value: 'custom_account_fields', name: 'Custom Account Fields' },
    { value: 'all_segments', name: 'Segments' },
    { value: 'cadence', name: 'Cadences ' }
  ]

  const filterGroups = props.customFilterGroups || FilterGroups

  function changeSelection (event) {
    let value = ''
    if (['interacted_with_bots', 'us_state', 'country', 'canadian_province', 'day_of_week'].includes(event.target.value)) {
      value = []
    }
    const filter = {
      cmp: '',
      value: value,
      prop: event.target.value
    }

    const filterName = props.propPrefix
    setFieldValue(filterName, filter)
  }

  const menuItems = props.menuItems

  let label = 'Filter '
  if (field.value?.startsWith('contacts_')) {
    label += 'Contact\'s'
  } else if (field.value?.startsWith('accounts_')) {
    label += 'Account\'s'
  } else {
    label += 'Criteria'
  }

  return (
    <FormControl
      variant='outlined'
      margin='dense'
    >
      <InputLabel htmlFor={props.name}>
        {label}
      </InputLabel>
      <Select
        {...field}
        inputProps={{
          name: props.id,
          id: props.id
        }}
        labelWidth={105}
        onBlur={() => setFieldTouched(field.name)}
        onChange={changeSelection}
        renderValue={val => {
          if (!val) { return }
          const displayItem = props?.menuItems?.find(({ value }) => value === val)
          if (displayItem) {
            return displayItem.name
          }
          const displayName = filterGroups.find(e => e.value === val)
          return displayName ? displayName.name : val
        }}
        className={classes.field}
        disabled={props.disabled}
      >
        <MenuItem value='' disabled>Select a filter criteria</MenuItem>
        {filterGroups.map((group, index) => ([
          <MenuItem disabled style={{ opacity: 1 }} key={index} value=''>
            <div className={classes.menuHeader}>
              {group.name}
            </div>
          </MenuItem>,
          menuItems.filter(item => item.group === group.value).map(prop => (
            <MenuItem value={prop.value} key={prop.value} className={classes.menuItem}>
              {prop.name}
            </MenuItem>))
        ]))}
      </Select>
      {field.value === 'url_path' && (
        <FormHelperText>e.g. 'home/pricing#features'</FormHelperText>
      )}
      {field.value === 'url' && (
        <FormHelperText>e.g. 'example.com/home#about'</FormHelperText>
      )}
    </FormControl>
  )
}

function FilterCompSelect ({
  field,
  form: { setFieldValue, setFieldTouched },
  ...props
}) {
  const classes = useStyles(props.styles)

  const oldComparisonGroups = [
    { value: 'starts_with', name: 'starts with' },
    { value: 'ends_with', name: 'ends with' }
  ]

  const menuItems = props.menuItems || []
  useEffect(() => {
    if (menuItems.length === 1) {
      setFieldValue(field.name, menuItems[0].value)
    }
  }, [props.propName]) // eslint-disable-line

  return (
    <FormControl
      variant='outlined'
      margin='dense'
      fullWidth={props.fullWidth}
    >
      <InputLabel htmlFor={props.name}>
        Comparison
      </InputLabel>
      <Select
        {...field}
        inputProps={{
          name: props.name,
          id: props.id
        }}
        labelWidth={90}
        onBlur={() => setFieldTouched(field.name)}
        onChange={e => setFieldValue(field.name, e.target.value)}
        renderValue={val => {
          if (!val) { return }
          const displayItem = menuItems.find(({ value }) => value === val)
          if (displayItem) {
            return displayItem.name
          }
          const displayName = oldComparisonGroups.find(e => e.value === val)
          return displayName ? displayName.name : val
        }}
        className={classes.field}
        disabled={props.disabled || menuItems.length < 2}
      >
        <MenuItem value='' disabled>Select a comparison</MenuItem>
        {menuItems.map(prop => (
          <MenuItem value={prop.value} key={prop.value}>{prop.name}</MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

/**
 * @param {string[]} state
 * @param {{ index: number; error: string|null }[]} changes
 * @returns {string[]}
 */
function errorReducer (state, changes) {
  const newState = [...state]
  changes.forEach(({ index, error }) => {
    newState[index] = error
  })
  return newState
}

function FilterMultipleTextValues ({
  field: { value, onChange, ...field },
  form: { setFieldValue, ...form },
  validatorFunction,
  ...props
}) {
  const classes = useStyles(props.styles)
  const [values, setValues] = useState([''])
  const [errors, dispatchErrors] = useReducer(errorReducer, [])

  useEffect(() => {
    let valueArr = value
    if (!value) {
      valueArr = ['']
    } else if (!Array.isArray(value)) {
      valueArr = [value, '']
    } else if (value[value.length - 1]) {
      valueArr = [...value, '']
    }
    setValues(valueArr)
  }, [value])

  useEffect(() => {
    if (validatorFunction) {
      const formErrors = values.map((val, i) => {
        const error = validatorFunction(val)
        return { index: i, error }
      })
      dispatchErrors(formErrors)
    }
  }, [values, validatorFunction])

  const handleChange = (index, value) => {
    const valueArr = [...values]
    valueArr[index] = value
    setValues(valueArr)
  }

  const updateForm = (newValues = [...values]) => {
    newValues = newValues.filter(value => !!value)
    const lastIndex = newValues.length - 1
    if (lastIndex > 1 && !newValues[lastIndex]) {
      newValues.splice(lastIndex, 1)
    }
    setFieldValue(field.name, newValues)
  }

  const handleBlur = _index => {
    updateForm()
  }

  const removeItem = index => {
    const newValues = [...values]
    newValues.splice(index, 1)
    updateForm(newValues)
  }

  return (
    <div>
      <div>
        <FormControl error={Boolean(errors[0])} className={classes.formControlField}>
          <TextField
            type='text'
            value={values[0]}
            onChange={e => handleChange(0, e.target.value)}
            onBlur={() => handleBlur(0)}
            variant='outlined'
            margin='dense'
            className={classes.field}
          />
          {Boolean(errors[0]) && (
            <FormHelperText>{errors[0]}</FormHelperText>
          )}
        </FormControl>
      </div>
      {values.slice(1).map((value, index) => {
        const valueIndex = index + 1
        const lastItem = (valueIndex + 1) === values.length
        return (
          <AnotherTextField
            key={index}
            value={value}
            onBlur={() => handleBlur(valueIndex)}
            onChange={e => handleChange(valueIndex, e.target.value)}
            removeItem={() => removeItem(valueIndex)}
            lastItem={lastItem}
            error={errors[valueIndex]}
          />
        )
      })}
    </div>
  )
}

/** @type {TextField} */
function AnotherTextField ({ removeItem, lastItem, error, ...props }) {
  const classes = useStyles(props.styles)
  return (
    <div className={classes.anotherFieldCtn}>
      <div className={classes.orText}>OR</div>
      <FormControl error={Boolean(error)} className={classes.formControlField}>
        <TextField
          {...props}
          type='text'
          className={classes.field}
          variant='outlined'
          margin='dense'
          placeholder='Add another'
          InputProps={lastItem ? undefined : {
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  className={classes.adornmentButton}
                  onClick={removeItem}
                  tabIndex='-1'
                  color='primary'
                >
                  <RemoveIcon />
                </IconButton>
              </InputAdornment>
            )
          }}
          error={Boolean(error)}
        />
        {Boolean(error) && (
          <FormHelperText>{error}</FormHelperText>
        )}
      </FormControl>
    </div>
  )
}

function FilterTextValue ({
  field: { value, onChange, ...field },
  form: { setFieldValue, ...form },
  ...props
}) {
  const classes = useStyles(props.styles)
  const [stateValue, setStateValue] = useState('')

  useEffect(() => {
    setStateValue(value)
  }, [value])

  const updateForm = () => {
    setFieldValue(field.name, stateValue)
  }

  return (
    <TextField
      type='text'
      {...field}
      value={stateValue}
      onChange={e => setStateValue(e.target.value)}
      onBlur={() => updateForm()}
      variant='outlined'
      margin='dense'
      className={classes.field}
    />
  )
}

function isNumber (evt) {
  evt = (evt) || window.event
  const charCode = (evt.which) ? evt.which : evt.keyCode
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    evt.preventDefault()
    return false
  }
  return true
}

function FilterDayOfWeek ({
  field,
  form: { setFieldValue, setFieldTouched },
  ...props
}) {
  const daysOfWeek = [
    { id: 'monday', value: 'Monday' },
    { id: 'tuesday', value: 'Tuesday' },
    { id: 'wednesday', value: 'Wednesday' },
    { id: 'thursday', value: 'Thursday' },
    { id: 'friday', value: 'Friday' },
    { id: 'saturday', value: 'Saturday' },
    { id: 'sunday', value: 'Sunday' }
  ]

  const classes = useStyles(props.styles)

  return (
    <FormControl
      variant='outlined'
      margin='dense'
    >
      <Select
        {...field}
        inputProps={{
          name: props.name,
          id: props.id
        }}
        onBlur={() => setFieldTouched(field.name)}
        onChange={e => setFieldValue(field.name, e.target.value)}
        className={classes.field}
        multiple
        MenuProps={MultiSelectMenuProps}
        displayEmpty
      >
        <MenuItem value='' disabled>Select a day</MenuItem>
        {
          daysOfWeek.map((day, index) => (
            <MenuItem value={day.id} key={index}>{day.value}</MenuItem>
          ))
        }
      </Select>
    </FormControl>
  )
}

function FilterNumericValue ({
  field: { value, onChange, ...field },
  form: { setFieldValue, ...form },
  ...props
}) {
  const classes = useStyles(props.styles)
  const [stateValue, setStateValue] = useState('')

  useEffect(() => {
    setStateValue(value || 0)
    if (value === '') {
      setFieldValue(field.name, 0)
    }
  }, [value, setFieldValue, field.name])

  const updateForm = () => {
    setFieldValue(field.name, stateValue || 0)
  }

  return (
    <TextField
      type='number'
      {...field}
      placeholder='0'
      onBlur={() => updateForm()}
      value={stateValue}
      onChange={e => {
        if (e.target.value < 0) e.target.value = 0
        setStateValue(e.target.value)
      }}
      variant='outlined'
      margin='dense'
      onKeyPress={e => isNumber(e)}
      className={classes.field}
    />
  )
}

function FilterPercentageValue ({
  field,
  form: { },
  ...props
}) {
  const classes = useStyles(props.styles)
  const handleBlur = field.onBlur

  const regExp = /[a-zA-Z]/g

  return (
    <TextField
      type='text'
      value={field.value}
      onBlur={handleBlur}
      onChange={(event) => {
        if (regExp.test(event.target.value)) {
          return
        }
        field.onChange(event)
      }}
      id={props.id}
      name={props.name}
      variant='outlined'
      margin='dense'
      onKeyPress={e => isNumber(e)}
      className={classes.field}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end' className={classes.adornment}>
            <p className={classes.adornment}>%</p>
          </InputAdornment>
        )
      }}
    />
  )
}

function FilterAvailabilitySelect ({
  field,
  form: { setFieldValue, setFieldTouched },
  ...props
}) {
  const classes = useStyles(props.styles)

  return (
    <FormControl
      variant='outlined'
      margin='dense'
    >
      <Select
        {...field}
        inputProps={{
          name: props.name,
          id: props.id
        }}
        onBlur={() => setFieldTouched(field.name)}
        onChange={e => setFieldValue(field.name, e.target.value)}
        className={classes.field}
        disabled={props.disabled}
      >
        <MenuItem value='' disabled>Select availability</MenuItem>
        <MenuItem value='available'>Available</MenuItem>
        <MenuItem value='unavailable'>Unavailable</MenuItem>
      </Select>
    </FormControl>
  )
}

function FilterBotNameSelect ({
  field,
  form: { setFieldValue, setFieldTouched },
  ...props
}) {
  const classes = useStyles(props.styles)
  const [bots, setBots] = useState([])

  useEffect(() => {
    getBots().then(response => {
      setBots(response.data)
    })
  }, [])

  return (
    <FormControl
      variant='outlined'
      margin='dense'
    >
      <Select
        {...field}
        inputProps={{
          name: props.name,
          id: props.id
        }}
        onBlur={() => setFieldTouched(field.name)}
        onChange={e => setFieldValue(field.name, e.target.value)}
        className={classes.field}
        multiple
        MenuProps={MultiSelectMenuProps}
        displayEmpty
      >
        <MenuItem value='' disabled>Select bot name</MenuItem>
        {
          bots.map((bot, index) => (
            <MenuItem value={bot.id} key={index}>{bot.attributes.name}</MenuItem>
          ))
        }
      </Select>
    </FormControl>
  )
}

function FilterTeamSelect ({
  field,
  form: { setFieldValue, setFieldTouched },
  ...props
}) {
  const classes = useStyles(props.styles)
  const [teams, setTeams] = useState([])

  useEffect(() => {
    getTeams().then(response => {
      setTeams(response.data)
    })
  }, [])

  return (
    <FormControl
      variant='outlined'
      margin='dense'
    >
      <Select
        {...field}
        inputProps={{
          name: props.name,
          id: props.id
        }}
        onBlur={() => setFieldTouched(field.name)}
        onChange={e => setFieldValue(field.name, e.target.value)}
        className={classes.field}
        displayEmpty
        MenuProps={MultiSelectMenuProps}
      >
        <MenuItem value='' disabled>Select team name</MenuItem>
        {
          teams.map((team, index) => (
            <MenuItem value={team.id} key={index}>{team.attributes.name}</MenuItem>
          ))
        }
      </Select>
    </FormControl>
  )
}

function FilterKnownUnknown ({
  field,
  form: { setFieldValue, setFieldTouched },
  ...props
}) {
  const classes = useStyles(props.styles)

  return (
    <FormControl
      variant='outlined'
      margin='dense'
    >
      <Select
        {...field}
        inputProps={{
          name: props.name,
          id: props.id
        }}
        onBlur={() => setFieldTouched(field.name)}
        onChange={e => setFieldValue(field.name, e.target.value)}
        className={classes.field}
      >
        <MenuItem value='' disabled>Select status</MenuItem>
        <MenuItem value='known'>known</MenuItem>
        <MenuItem value='unknown'>unknown</MenuItem>
      </Select>
    </FormControl>
  )
}

function FilterEventTimeframe ({
  field,
  form: { setFieldValue, setFieldTouched },
  ...props
}) {
  const classes = useStyles(props.styles)

  return (
    <FormControl
      variant='outlined'
      margin='dense'
    >
      <Select
        {...field}
        inputProps={{
          name: props.name,
          id: props.id
        }}
        onBlur={() => setFieldTouched(field.name)}
        onChange={e => setFieldValue(field.name, e.target.value)}
        className={classes.field}
      >
        <MenuItem value='' disabled>Select period</MenuItem>
        <MenuItem value='ever'>ever</MenuItem>
        <MenuItem value={7}>last 7 days</MenuItem>
        <MenuItem value={30}>last 30 days</MenuItem>
        <MenuItem value={90}>last 90 days</MenuItem>
        <MenuItem value={180}>last 6 months</MenuItem>
      </Select>
    </FormControl>
  )
}

function FilterLanguagePrefix ({
  field,
  form: { setFieldValue, setFieldTouched },
  ...props
}) {
  const classes = useStyles(props.styles)

  return (
    <FormControl
      variant='outlined'
      margin='dense'
    >
      <Select
        {...field}
        inputProps={{
          name: props.name,
          id: props.id
        }}
        onBlur={() => setFieldTouched(field.name)}
        onChange={e => setFieldValue(field.name, e.target.value)}
        className={classes.field}
      >
        <MenuItem value='' disabled>Select language</MenuItem>
        <MenuItem value='en'>English</MenuItem>
        <MenuItem value='es'>Spanish</MenuItem>
        <MenuItem value='zh'>Chinese</MenuItem>
        <MenuItem value='ar'>Arabic</MenuItem>
        <MenuItem value='hi'>Hindi</MenuItem>
        <MenuItem value='ms'>Malay</MenuItem>
        <MenuItem value='ru'>Russian</MenuItem>
        <MenuItem value='bn'>Bengali</MenuItem>
        <MenuItem value='pt'>Portuguese</MenuItem>
        <MenuItem value='fr'>French</MenuItem>
      </Select>
    </FormControl>
  )
}

function FilterCurrentTime ({
  field,
  form: { setFieldValue, setFieldTouched },
  ...props
}) {
  const { chatSettings } = useContext(SessionContext)
  return (
    <FormControl
      variant='outlined'
      margin='dense'
    >
      <TimePicker
        autoOk
        label='Time'
        value={field.value}
        onChange={e => setFieldValue(field.name, e)}
      />
      <div style={{ marginTop: 5 }}>{chatSettings.timezone}</div>
    </FormControl>
  )
}

function FilterDeviceType ({
  field,
  form: { setFieldValue, setFieldTouched },
  ...props
}) {
  const classes = useStyles(props.styles)

  return (
    <FormControl
      variant='outlined'
      margin='dense'
    >
      <Select
        {...field}
        inputProps={{
          name: props.name,
          id: props.id
        }}
        onBlur={() => setFieldTouched(field.name)}
        onChange={e => setFieldValue(field.name, e.target.value)}
        className={classes.field}
      >
        <MenuItem value='' disabled>Select device type</MenuItem>
        <MenuItem value='desktop'>Desktop</MenuItem>
        <MenuItem value='mobile'>Mobile</MenuItem>
        <MenuItem value='tablet'>Tablet</MenuItem>
        <MenuItem value='ios'>iOS</MenuItem>
        <MenuItem value='android'>Android</MenuItem>
      </Select>
    </FormControl>
  )
}

function FilterOccurrenceSelect ({
  field,
  form: { setFieldValue, setFieldTouched },
  ...props
}) {
  const classes = useStyles(props.styles)

  return (
    <FormControl
      variant='outlined'
      margin='dense'
    >
      <Select
        {...field}
        inputProps={{
          name: props.name,
          id: props.id
        }}
        onBlur={() => setFieldTouched(field.name)}
        onChange={e => setFieldValue(field.name, e.target.value)}
        className={classes.field}
      >
        <MenuItem value='' disabled>Select value</MenuItem>
        <MenuItem value='occurred'>occurred</MenuItem>
        <MenuItem value='not_occurred'>not occurred</MenuItem>
      </Select>
    </FormControl>
  )
}

function FilterLeadScoreSelect ({
  field,
  form: { setFieldValue, setFieldTouched },
  ...props
}) {
  const classes = useStyles(props.styles)
  const NStarIcon = (props) => {
    const classes = useStyles(props.styles)

    const stars = []
    const n = props.stars
    for (let i = 0; i < n; i++) {
      stars.push(<StarIcon key={i} className={classes.star} />)
    }

    return <>{stars}</>
  }

  const scores = [
    { key: 1, name: 'one star', icon: <NStarIcon stars={1} /> },
    { key: 2, name: 'two stars', icon: <NStarIcon stars={2} /> },
    { key: 3, name: 'three stars', icon: <NStarIcon stars={3} /> }
  ]

  return (
    <FormControl
      variant='outlined'
      margin='dense'
    >
      <Select
        {...field}
        inputProps={{
          name: props.name,
          id: props.id
        }}
        className={classes.field}
        onChange={e => setFieldValue(field.name, e.target.value)}
        onBlur={() => setFieldTouched(field.name)}
      >
        <MenuItem value='preview' disabled>
          Lead Score
        </MenuItem>
        {scores.map((score, index) => (
          <MenuItem key={index} value={score.key}>
            <div className={classes.leadScoreMenuItem}>
              <div>{score.name}</div>
              <div style={{ marginLeft: 5 }}>{score.icon}</div>
            </div>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

function FilterSegments ({
  field,
  form: { setFieldValue, setFieldTouched, values },
  ...props
}) {
  const classes = useStyles(props.styles)
  const { data: segments = [] } = useQuery(['segments', 'list', { include: ['connection_count'], objectType: 'contacts' }], () => getSegments({
    objectType: 'contacts', includes: ['connection_count']
  }).then(res => res.data))

  if (!field.value) {
    field.value = []
  } else if (!Array.isArray(field.value)) {
    field.value = [field.value]
  }

  return (
    <FormControl
      variant='outlined'
      margin='dense'
    >
      <Select
        {...field}
        inputProps={{
          name: props.name,
          id: props.id
        }}
        onBlur={() => setFieldTouched(field.name)}
        onChange={e => setFieldValue(field.name, e.target.value)}
        className={classes.field}
        multiple
        MenuProps={MultiSelectMenuProps}
      >
        <MenuItem value='' disabled>Select Segment</MenuItem>
        {segments.map((segment, index) => (
          <MenuItem value={segment.id} key={index}>{segment.attributes?.name}</MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export function FilterLocation ({
  field: { value, ...field },
  form: { setFieldValue },
  ...props
}) {
  const classes = useStyles(props.styles)

  const setValue = useCallback(value => {
    if (!value || !value.length) {
      value = ''
    }
    setFieldValue(field.name, value)
  }, [field.name, setFieldValue])

  return (
    <FormControl
      variant='outlined'
      margin='dense'
    >
      <GeographySelector
        emptyText='Select locations'
        className={classes.field}
        values={value || []}
        setValues={setValue}
      />
    </FormControl>
  )
}

function FilterMeetingTypeSelect ({
  field,
  form: { setFieldValue, setFieldTouched, values },
  ...props
}) {
  const classes = useStyles(props.styles)

  const [meetingTypes, setMeetingTypes] = useState([])

  useEffect(() => {
    getMeetingTypes().then(response => {
      const nonDeletedMeetingTypes = response.data
      setMeetingTypes(nonDeletedMeetingTypes)
    })
  }, [props.type])

  return (
    <FormControl
      variant='outlined'
      margin='dense'
    >
      <Select
        {...field}
        inputProps={{
          name: props.name,
          id: props.id
        }}
        onBlur={() => setFieldTouched(field.name)}
        onChange={e => setFieldValue(field.name, e.target.value)}
        className={classes.field}
      >
        <MenuItem value='' disabled>Select Meeting Type</MenuItem>
        <MenuItem value='any'>* Any Meeting *</MenuItem>
        {meetingTypes.map((meeting, index) => (
          <MenuItem value={meeting.id} key={index}>{meeting.attributes?.name}</MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

function FilterSingleBotNameSelect ({
  field,
  form: { setFieldValue, setFieldTouched },
  ...props
}) {
  const classes = useStyles(props.styles)
  const [bots, setBots] = useState([])

  useEffect(() => {
    getBots().then(response => {
      const availableBots = response.data.filter(b => b.attributes.deleted_timestamp === null)
      setBots(availableBots)
    })
  }, [])

  return (
    <FormControl
      variant='outlined'
      margin='dense'
    >
      <Select
        {...field}
        inputProps={{
          name: props.name,
          id: props.id
        }}
        onBlur={() => setFieldTouched(field.name)}
        onChange={e => setFieldValue(field.name, e.target.value)}
        className={classes.field}
        displayEmpty
      >
        <MenuItem value='' disabled>Select bot name</MenuItem>
        {bots.map((bot, index) => (
          <MenuItem value={bot.id} key={index}>{bot.attributes.name}</MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

function FilterAccounts ({
  field,
  form: { setFieldValue, setFieldTouched },
  ...props
}) {
  const classes = useStyles(props.styles)

  return (
    <FormControl
      variant='outlined'
      margin='dense'
    >
      <Select
        {...field}
        inputProps={{
          name: props.name,
          id: props.id
        }}
        onBlur={() => setFieldTouched(field.name)}
        onChange={e => setFieldValue(field.name, e.target.value)}
        className={classes.field}
      >
        <MenuItem value='' disabled>Select Occurrence</MenuItem>
        <MenuItem value='true'>True</MenuItem>
      </Select>
    </FormControl>
  )
}

const setToOneValue = (accountSegmentArray) => {
  if (accountSegmentArray.length > 1) {
    return accountSegmentArray[accountSegmentArray.length - 1]
  }
  return accountSegmentArray
}

function FilterSalesloftCadence ({
  field,
  form: { setFieldValue, setFieldTouched }
}) {
  return (
    <FormControl
      variant='outlined'
      margin='dense'
    >
      <Picker
        selection={field.value}
        setSelection={(value) => {
          setFieldTouched(field.name)
          setFieldValue(field.name, value)
        }}
        objectType='cadence'
      />
    </FormControl>
  )
}

function FilterAccountSegments ({
  field,
  form: { setFieldValue, setFieldTouched },
  ...props
}) {
  const classes = useStyles(props.styles)
  const { data: segments = [] } = useQuery(['segments', 'list', { include: ['connection_count'], objectType: 'domains' }], () => getSegments({
    objectType: 'domains', includes: ['connection_count']
  }).then(res => res.data))

  if (!field.value) {
    field.value = []
  } else if (!Array.isArray(field.value)) {
    field.value = [field.value]
  }

  return (
    <FormControl
      variant='outlined'
      margin='dense'
    >
      <Select
        {...field}
        inputProps={{
          name: props.name,
          id: props.id
        }}
        onBlur={() => setFieldTouched(field.name)}
        onChange={e => setFieldValue(field.name, setToOneValue(e.target.value))}
        className={classes.field}
        multiple
        MenuProps={MultiSelectMenuProps}
      >
        <MenuItem value='' disabled>Select Segment</MenuItem>
        {segments.map(segment => (
          <MenuItem value={segment.id} key={segment.id}>{segment.attributes?.name}</MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

function FilterCurrency ({
  field,
  form: { setFieldValue, setFieldTouched, values },
  ...props
}) {
  const convertLocaleCurrencyToInt = (currencyString) => {
    const noDecimals = currencyString.split('.')[0]
    const noSign = noDecimals.replace('$', '')
    const integer = parseFloat(noSign.replace(/,/g, ''))
    return integer
  }

  const styles = {
    border: '1px solid #ccc',
    fontSize: 18,
    bordeRadius: 5,
    padding: 7
  }

  return (
    <FormControl
      variant='outlined'
      margin='dense'
    >
      <CurrencyInput
        customStyles={styles}
        value={field.value || 0}
        onChange={e => setFieldValue(field.name, convertLocaleCurrencyToInt(e.target.value))}
      />
    </FormControl>
  )
}
function FilterOpportunityTypes ({
  field,
  form: { setFieldValue, setFieldTouched }
}) {
  return (
    <FormControl
      variant='outlined'
      margin='dense'
    >
      <Picker
        multiple
        selection={field.value}
        setSelection={(value) => {
          setFieldTouched(field.name)
          setFieldValue(field.name, value)
        }}
        objectType='opportunityTypes'
      />
    </FormControl>
  )
}

function FilterOpportunityStages ({
  field,
  form: { setFieldValue, setFieldTouched }
}) {
  return (
    <FormControl
      variant='outlined'
      margin='dense'
    >
      <Picker
        multiple
        selection={field.value}
        setSelection={(value) => {
          setFieldTouched(field.name)
          setFieldValue(field.name, value)
        }}
        objectType='opportunityStages'
      />
    </FormControl>
  )
}

function FilterIndustries ({
  field,
  form: { setFieldValue, setFieldTouched }
}) {
  return (
    <FormControl
      variant='outlined'
      margin='dense'
    >
      <Picker
        multiple
        selection={field.value}
        setSelection={(value) => {
          setFieldTouched(field.name)
          setFieldValue(field.name, value)
        }}
        objectType='industries'
      />
    </FormControl>
  )
}
function FilterEmployees ({
  field,
  form: { setFieldValue, setFieldTouched }
}) {
  return (
    <FormControl
      variant='outlined'
      margin='dense'
    >
      <Picker
        multiple
        selection={field.value}
        setSelection={(value) => {
          // We don't want to support gaps in selected employees, so if
          // they leave a gap, we'll automatically select the ranges
          // between their selected min and max
          const selected = []
          if (value.length > 0) {
            let first = ''
            let last = ''
            employeeRanges.forEach(r => {
              value.forEach(v => {
                if (v === r.value && first === '') {
                  first = v
                }
                if (v === r.value) {
                  last = v
                }
              })
            })
            let firstIdx = -1
            let lastIdx = -1
            employeeRanges.forEach((r, idx) => {
              let pushed = false
              if (r.value === first) {
                firstIdx = idx
                selected.push(r.value)
                pushed = true
              }
              if (r.value === last) {
                lastIdx = idx
                selected.push(r.value)
                pushed = true
              }
              if (firstIdx >= 0 && lastIdx === -1 && !pushed) {
                selected.push(r.value)
              }
            })
          }
          setFieldTouched(field.name)
          setFieldValue(field.name, selected)
        }}
        objectType='employeeRanges'
      />
    </FormControl>
  )
}

function FilterRevenues ({
  field,
  form: { setFieldValue, setFieldTouched }
}) {
  return (
    <FormControl
      variant='outlined'
      margin='dense'
    >
      <Picker
        multiple
        selection={field.value}
        setSelection={(value) => {
          // We don't want to support gaps in selected revenue, so if
          // they leave a gap, we'll automatically select the revenues
          // between their selected min and max
          const selected = []
          if (value.length > 0) {
            let first = ''
            let last = ''
            revenueRanges.forEach(r => {
              value.forEach(v => {
                if (v === r.value && first === '') {
                  first = v
                }
                if (v === r.value) {
                  last = v
                }
              })
            })
            let firstIdx = -1
            let lastIdx = -1
            revenueRanges.forEach((r, idx) => {
              let pushed = false
              if (r.value === first) {
                firstIdx = idx
                selected.push(r.value)
                pushed = true
              }
              if (r.value === last) {
                lastIdx = idx
                selected.push(r.value)
                pushed = true
              }
              if (firstIdx >= 0 && lastIdx === -1 && !pushed) {
                selected.push(r.value)
              }
            })
          }
          setFieldTouched(field.name)
          setFieldValue(field.name, selected)
        }}
        objectType='revenueRanges'
      />
    </FormControl>
  )
}
function FilterCRMRecordTypes ({
  field,
  form: { setFieldValue, setFieldTouched }
}) {
  return (
    <FormControl
      variant='outlined'
      margin='dense'
    >
      <Picker
        multiple
        selection={field.value}
        setSelection={(value) => {
          setFieldTouched(field.name)
          setFieldValue(field.name, value)
        }}
        objectType='crmRecordTypes'
      />
    </FormControl>
  )
}

function FilterAccountTypes ({
  field,
  form: { setFieldValue, setFieldTouched }
}) {
  return (
    <FormControl
      variant='outlined'
      margin='dense'
    >
      <Picker
        multiple
        selection={field.value}
        setSelection={(value) => {
          setFieldTouched(field.name)
          setFieldValue(field.name, value)
        }}
        objectType='accountTypes'
      />
    </FormControl>
  )
}

function FilterTrueOrFalse ({
  field,
  form: { setFieldValue, setFieldTouched },
  ...props
}) {
  const classes = useStyles(props.styles)

  return (
    <FormControl
      variant='outlined'
      margin='dense'
    >
      <Select
        {...field}
        inputProps={{
          name: props.name,
          id: props.id
        }}
        onBlur={() => setFieldTouched(field.name)}
        onChange={e => setFieldValue(field.name, e.target.value)}
        className={classes.field}
      >
        <MenuItem value='' disabled>Select true or false</MenuItem>
        <MenuItem value={1}>true</MenuItem>
        <MenuItem value={0}>false</MenuItem>
      </Select>
    </FormControl>
  )
}

function FilterTrue ({
  field,
  form: { setFieldValue, setFieldTouched },
  ...props
}) {
  const classes = useStyles(props.styles)

  return (
    <FormControl
      variant='outlined'
      margin='dense'
    >
      <Select
        {...field}
        inputProps={{
          name: props.name,
          id: props.id
        }}
        onBlur={() => setFieldTouched(field.name)}
        onChange={e => setFieldValue(field.name, e.target.value)}
        className={classes.field}
      >
        <MenuItem value={1}>True</MenuItem>
      </Select>
    </FormControl>
  )
}

export {
  FilterAccountFieldSelect,
  FilterAccounts,
  FilterAccountSegments,
  FilterAccountTypes,
  FilterAvailabilitySelect,
  FilterBotNameSelect,
  FilterCompSelect,
  FilterContactFieldSelect,
  FilterCurrency,
  FilterCurrentTime,
  FilterCRMRecordTypes,
  FilterDayOfWeek,
  FilterDeviceType,
  FilterEventTimeframe,
  FilterIndustries,
  FilterKnownUnknown,
  FilterLanguagePrefix,
  FilterLeadScoreSelect,
  FilterMeetingTypeSelect,
  FilterMultipleTextValues,
  FilterNumericValue,
  FilterOccurrenceSelect,
  FilterOpportunityStages,
  FilterOpportunityTypes,
  FilterPercentageValue,
  FilterPropSelect,
  FilterRevenues,
  FilterEmployees,
  FilterSegments,
  FilterSingleBotNameSelect,
  FilterTeamSelect,
  FilterTextValue,
  FilterTrue,
  FilterTrueOrFalse,
  FilterSalesloftCadence
}
