import AppPage from 'cf-components/AppPage'
import CrowdSourceModerationTable from './CrowdSourceModerationTable'

export default function AdminModerationpage () {
  return (
    <AppPage
      title='Account Detail Moderation'
      padding={0}
    >
      <div>
        <CrowdSourceModerationTable />
      </div>
    </AppPage>
  )
}
