import React, { useState } from 'react'
import { MenuItem, Popover } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import IconButton from '@material-ui/core/IconButton'
import './components.css'

const useStyles = makeStyles(theme => ({
  popoverRoot: {
    overflow: 'visible',
    marginTop: 30,
    backgroundColor: 'rgba(0,0,0,0)',
    boxShadow: 'none'
  },
  menuItem: {
    display: 'grid',
    gridTemplateColumns: 'auto 30px',
    gridGap: 25,
    width: '100%'
  }
}))

function ActionsMenu (props) {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const classes = useStyles()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <IconButton style={{ height: 40, width: 40 }} onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        classes={{ paper: classes.popoverRoot }}
      >
        <div className='triangle' />
        <div className='actions-menu'>
          {props.actions.map((action, index) => (
            <MenuItem key={index} onClick={() => { action.action(); handleClose() }}>
              <div className={classes.menuItem}>
                {action.name}
                <action.icon style={{ marginRight: 10, fill: action.color || '#8E5AE2', height: '.9em', width: '.9em' }} />
              </div>
            </MenuItem>
          ))}
        </div>
      </Popover>

    </div>
  )
}

export default ActionsMenu
