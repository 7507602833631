import FilterMenuNoTable from 'library/filter/FilterMenuNoTable'
import { filterOptions } from './ContactTableFilters'
import IntegrationModal from './IntegrationModal'
import { useEffect, useState } from 'react'
import { Contact } from 'classes/contacts'
import { AppendFilters, parameterizeFilters, SearchParams } from 'classes/queryHelpers'
import { Emitter, EVENT_TYPE } from 'emitter'
import { CSVDownload } from 'classes/csvdownload'
import DateFormat from 'components/DateFormat'
import { downloadCSV } from 'helpers/csv'
import { EmailSubscription, initialState } from 'classes/emailSubscriptions'
import EmailSubscriptionModal from 'library/modals/emailSubscriptionModal/EmailSubscriptionModal'
import { Modal, ModalSection } from 'library/Modal'
import SkeletonLabel from 'library/loading/skeleton/SkeletonLabel'
import SkeletonTableLoader from 'library/loading/skeleton/SkeletonTableLoader'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  tabPlaceholder: {
    width: 200,
    marginTop: 5,
    marginBottom: 5,
    marginLeft: 10,
    marginRight: 20
  }
}))

const mywindow = window as any
const exportCSV = async (searchParams: SearchParams) => {
  const exportClass = 'Contact'
  Emitter.emit(EVENT_TYPE.CSV_DOWNLOAD, { csvIsDownloading: true })
  const resp = await CSVDownload.download({ type: exportClass, searchParams })
  Emitter.emit(EVENT_TYPE.CSV_DOWNLOAD, { csvIsDownloading: false })
  const date = new Date().toString()
  const csvType = exportClass.toLowerCase() + 's'
  const filename = 'signals_' + csvType + '_' + DateFormat({ dateToFormat: date, format: 'isoDate' }) + '.csv'
  downloadCSV(filename, resp)
}

export default function ContactTable (): JSX.Element {
  const classes = useStyles()
  const [integrationModal, setIntegrationModal] = useState<number | null>(null)
  const [filterAnchor, setFilterAnchor] = useState<HTMLElement | null>(null)
  const [emailSub, setEmailSub] = useState<EmailSubscription | null>(null)
  const [deleteObject, setDeleteObject] = useState<any | null>(null)
  const [searchParams, setSearchParams] = useState<SearchParams>({
    sortColumn: 'created_timestamp',
    sortOrder: 'desc'
  })
  const handleContactDelete = () => {
    Contact.deleteContact(deleteObject.id).then(() => {
      if (mywindow.deleteRow) {
        mywindow.htmx.swap(
          mywindow.deleteRow,
          '',
          {
            swapDelay: 1000,
            swapStyle: 'outerHTML'
          })
        mywindow.deleteRow = null
      }
      setDeleteObject(null)
    })
  }
  const tableEventListener = (e: any) => {
    console.log('tableEvent', e)
    const tableID = e.detail.tableID
    const btn = e.detail.btn
    const action = e.detail.action
    const ids: Array<string> = []
    if (tableID === 'contacts-body') {
      switch (action) {
        case 'filter':
          setFilterAnchor(document.getElementById(btn))
          break
        case 'export':
          Array.from(document.getElementsByClassName('rowcheckbox')).forEach((checkbox: any) => {
            if (checkbox.checked) {
              console.log(checkbox.value)
              ids.push(checkbox.value)
            }
          })
          if (ids.length === 0) {
            exportCSV(searchParams)
          } else {
            const exportParams = { ...searchParams, filters: AppendFilters(searchParams.filters || [], [[{ field: 'id', value: ids, operator: 'in' }]]) }
            exportCSV(exportParams)
          }
          break
        case 'email':
          setEmailSub(new EmailSubscription(Object.assign(initialState, { type: 'contacts' })))
          break
        default:
          console.warn('Unknown table action', action)
      }
    } else {
      console.log('different table event: ', tableID)
    }
  }
  const rowEventListener = (e: any) => {
    console.log('rowEvent', e)
    const tableID = e.detail.tableID
    const rowID = e.detail.rowID
    const rowAction = e.detail.action
    const rowDiv = document.getElementById(`mainCol${rowID}`)?.cloneNode(true)
    if (tableID === 'contacts-body') {
      switch (rowAction) {
        case 'add-to-integration':
          setIntegrationModal(rowID)
          break
        case 'delete':
          mywindow.deleteRow = e.detail.tr
          setDeleteObject({ id: rowID, display: rowDiv })
          break
        default:
          console.warn('Unknown row action', rowAction)
      }
    } else {
      console.log('different table event: ', tableID)
    }
  }
  const addFilters = (filters: any) => {
    const newFilters = AppendFilters(searchParams.filters || [], filters)
    setSearchParams({ ...searchParams, filters: newFilters })
    const ele = document.getElementById('contacts-body-filters')
    if (ele && ele.hasAttribute('value')) {
      ele.setAttribute('value', JSON.stringify(newFilters))
    }
    mywindow.htmx.trigger('#contacts-body-form', 'submit')
  }
  const clearFilters = () => {
    setSearchParams({ ...searchParams, filters: [] })
    const ele = document.getElementById('contacts-body-filters')
    if (ele && ele.hasAttribute('value')) {
      ele.setAttribute('value', '[]')
    }
    mywindow.htmx.trigger('#contacts-body-form', 'submit')
  }

  useEffect(() => {
    try {
      mywindow.htmx.logger = function (elt: any, event: any, data: any) {
        if (console) {
          console.log('INFO:', event, elt, data)
        }
      }
      mywindow.addEventListener('tableEvent', tableEventListener)
      mywindow.addEventListener('rowEvent', rowEventListener)
    } catch (e) {
      console.log(e)
    }
    return () => {
      mywindow.removeEventListener('tableEvent', tableEventListener)
      mywindow.removeEventListener('rowEvent', rowEventListener)
    }
    // eslint-disable-next-line
  }, [searchParams])

  const url = parameterizeFilters({ path: '/api/core/web/contacts', searchParams })

  return (
    <>
      <FilterMenuNoTable
        filterOptions={filterOptions}
        anchorEl={filterAnchor}
        setAnchorEl={setFilterAnchor}
        handleFilter={(filters: any) => {
          addFilters(filters)
        }}
        clearFilters={clearFilters}
        searchParams={searchParams}
      />
      <div style={{ backgroundColor: 'white' }}>
        <div
          id='contacts'
          hx-get={url}
          hx-trigger='revealed'
          hx-indicator='.contacts-loader'
        >
          <div>
            <div style={{ display: 'flex', justifyContent: 'right', padding: 10 }}>
              <div className={classes.tabPlaceholder} style={{ margin: 10 }}> <SkeletonLabel size='full' /> </div>
              <div className={classes.tabPlaceholder} style={{ margin: 10 }}> <SkeletonLabel size='full' /> </div>
              <div className={classes.tabPlaceholder} style={{ margin: 10 }}> <SkeletonLabel size='full' /> </div>
            </div>
            <SkeletonTableLoader
              rowCount={10}
              types={['squareAvatar&Label', 'smLabel', 'number', 'smLabel', 'name&Subtitle', 'avatar']}
              columns='2fr 1fr 1fr 1fr 1fr 50px 50px'
              justifyCenter
            />
          </div>
        </div>
      </div>
      {integrationModal && (
        <IntegrationModal
          contactID={integrationModal}
          onClose={() => setIntegrationModal(null)}
        />
      )}
      {emailSub && (
        <EmailSubscriptionModal
          emailSubscription={emailSub}
          onClose={() => setEmailSub(null)}
        />
      )}
      <Modal
        open={!!deleteObject}
        onHide={() => setDeleteObject(null)}
        title='Delete Contact'
        handleSave={handleContactDelete}
        saveBtnText='Delete'
        saveBtnColor='red'
        saveIcon='delete'
      >
        <ModalSection>
          <p>Are you sure you want to delete {deleteObject?.name || 'this contact'}?</p>
          {deleteObject?.display && <div ref={ref => ref?.appendChild(deleteObject.display)} />}
        </ModalSection>
      </Modal>
    </>
  )
}

// import { Contact } from 'classes/contacts'
// import { filterOptions } from './ContactTableFilters'
// import TableList from 'cf-components/table/TableList';
// import { TableProvider } from 'library/table/TableProvider';
// import { Actions, Fields } from 'cf-components/table/Table'
// import { useState } from 'react'
// import { Icon } from 'library/materialUI';
// import { ReactComponent as AddIntegration } from 'img/addIntegration.svg'
// import IntegrationModal from './IntegrationModal'
// import { Sequence } from 'classes/sequences'
// import Form from 'classes/forms'
// import { GroupedAvatars } from 'cf-components/table/TableAvatars'
// import { truncateString } from 'library/helpers';

// function Origin (props: any): JSX.Element {
//   const source = props.row.source
//   const sourceID = props.row.sourceID

//   const { data: origin } = source === 'prospect' && sourceID ? Sequence.loadOne(sourceID) : source === 'form' && sourceID ? Form.loadOne(sourceID) : source === '' ? { data: { name: 'Tracked Email' } } : { data: { name: '---' } }
//   const textValue = origin?.name ? origin.name : '---'
//   return (
//     <div style={{
//       maxWidth: '160px',
//       overflow: 'hidden',
//       whiteSpace: 'nowrap',
//       textOverflow: 'ellipsis',
//       display: 'flex'
//     }}
//     >
//       {source === 'prospect' && (<Icon size='sm' icon='playMakerBlack' />)}
//       {source === 'form' && (<Icon size='sm' icon='factCheck' />)}
//       {source === 'mql' && (<Icon size='sm' icon='email' />)}
//       &nbsp;{truncateString(textValue, 16, true)}&nbsp;
//     </div>
//   )
// }

// function Title (props: any): JSX.Element {
//   return (
//     <div style={{
//       maxWidth: 160,
//       overflow: 'hidden'
//     }}
//     >
//       {truncateString(props.value, 35, true)}
//     </div>
//   )
// }

// export function AddedToComponent (props: any): JSX.Element {
//   let component = <p>---</p>
//   if (props.value.length > 0) {
//     component = (
//       <GroupedAvatars
//         objects={props.value}
//         color='white'
//       />
//     )
//   }

//   return (
//     <div>{component}</div>
//   )
// }

// export default function ContactTable (): JSX.Element {
//   const [integrationModal, setIntegrationModal] = useState<number | null>(null)
//   const fields: Fields<Contact> = [
//     { name: 'name', type: 'avatar' },
//     { name: 'title', type: 'custom', minWidth: 160, component: Title },
//     { name: 'domain', label: 'Company', type: 'companyAvatar', minWidth: 250 },
//     { name: 'leadScore', type: 'leadScore' },
//     { name: 'createdTimestamp', label: 'Date Added', type: 'date' },
//     { name: 'lastSeen', label: 'Last Seen', type: 'date' },
//     { name: 'playID', label: 'Origin', type: 'custom', component: Origin, minWidth: 160 },
//     { name: 'insightIntegrations', label: 'Added To', type: 'custom', component: AddedToComponent, sort: false }
//   ]

//   const actions: Actions<Contact> = [
//     { name: 'Add to Integration', action: (row: Contact) => setIntegrationModal(row.id as number), icon: AddIntegration },
//     { type: 'delete', name: 'Delete Contact' }
//   ]

//   const [selectedContacts, setSelectedContacts] = useState([])

//   const templateColumns = '50px'
//   return (
//     <>
//       {integrationModal &&
//         <IntegrationModal
//           contactID={integrationModal}
//           onClose={() => setIntegrationModal(null)}
//         />}
//       <TableProvider<Contact>
//         ObjectClass={Contact}
//         fields={fields}
//         actions={actions}
//         gridTemplateColumns={templateColumns}
//         filterOptions={filterOptions}
//         initialSearchParams={{
//           filters: [],
//           sortColumn: 'created_timestamp',
//           pageNumber: 1,
//           sortOrder: 'desc',
//           pageSize: 12
//         }}
//       >
//         <TableList<Contact> selected={selectedContacts} setSelected={setSelectedContacts} />
//       </TableProvider>
//     </>
//   )
// }
