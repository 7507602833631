import React, { useState, useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Modal, ModalSection } from 'library/Modal'
import { Input, TextField, Select, MenuItem, Checkbox, ListItemText } from '@material-ui/core'
import ButtonTextField from 'components/ButtonTextField'
import { RichTextEditor } from 'library/richText/RichTextEditor'
import CancelIcon from '@material-ui/icons/Cancel'

import PropTypes from 'prop-types'
import { EditableChip } from 'library/EditableChip'

const useStyles = makeStyles(theme => ({
  divider: {
    marginTop: 5
  },
  sectionHeader: {
    color: 'rgba(0,0,0,0.75)',
    fontSize: '14px',
    fontWeight: 600,
    marginTop: 15,
    marginBottom: 15
  },
  sectionTitle: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  helpIcon: {
    cursor: 'pointer',
    fontSize: '1.3em'
  },
  tooltip: {
    fontSize: '12px'
  },
  question: {
    fontSize: '14px',
    marginBottom: 10,
    display: 'flex',
    color: theme.palette.primary.main,
    alignItems: 'center'
  },
  FAQModal: {
    fontFamily: 'Poppins, sans serif'
  },
  questionsDiv: {
    padding: '15px 15px 0px 35px',
    maxHeight: 135,
    overflowY: 'auto'
  },
  chip: {
    marginRight: 10,
    marginBottom: 10
  },
  contentClass: {
    padding: 0,
    borderTop: 'none',
    height: 360,
    overflowY: 'hidden'
  },
  keywords: {
    display: 'flex',
    marginTop: 8,
    flexFlow: 'wrap'
  },
  smallChip: {
    margin: 5
  },
  deleteIcon: {
    fill: 'white'
  }
}))

function TabPanel (props) {
  const { children, value, index } = props
  const classes = useStyles()

  return (
    <div
      hidden={value !== index}
      className={classes.tabDiv}
    >
      {children}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

function FAQModal (props) {
  const classes = useStyles()
  const displayRef = useRef(null)

  const [modalState, setModalState] = useState(props.state)

  function scrollToBottom (el) {
    el.scrollTop = el.scrollHeight - el.clientHeight
  }

  useEffect(() => setModalState(props.state), [props.state])

  function addQuestion (question) {
    if (question === '') {
      return
    }
    const newQuestions = modalState.questions.concat(question)
    setModalState({ ...modalState, questions: newQuestions })
  }

  const handleTabChange = (event, newValue) => {
    setModalState({ ...modalState, tab: newValue })
  }

  function addTag (tag) {
    if (tag === '') {
      return
    }
    const newTags = modalState.tags.concat(tag)
    setModalState({ ...modalState, tags: newTags })
  }

  function addKeyword (label) {
    if (label === '') {
      return
    }
    const newOptions = modalState.keywords.concat(label)
    setModalState({ ...modalState, keywords: newOptions })
  }

  function addExclusion (label) {
    if (label === '') {
      return
    }
    const newOptions = modalState.excluded.concat(label)
    setModalState({ ...modalState, excluded: newOptions })
  }

  function editResponse (value, delta, source, editor) {
    setModalState({ ...modalState, response: value })
  }

  function handleDelete (index) {
    const newArray = [...modalState.questions]
    newArray.splice(index, 1)
    setModalState({ ...modalState, questions: newArray })
  };

  function handleChange (event) {
    const name = event.target.name
    const value = event.target.value
    setModalState({ ...modalState, [name]: value })
  }

  function handleQuestionChange (value, index) {
    const newQuestions = modalState.questions.slice()
    newQuestions[index] = value
    setModalState({ ...modalState, questions: newQuestions })
  }

  const deleteKeyword = optionToDelete => () => {
    setModalState({ ...modalState, keywords: modalState.keywords.filter(option => option !== optionToDelete) })
  }

  const deleteExclusion = optionToDelete => () => {
    setModalState({ ...modalState, excluded: modalState.excluded.filter(option => option !== optionToDelete) })
  }

  const editKeyWord = (index, value) => {
    const newKeywords = [...modalState.keywords]
    newKeywords[index] = value
    setModalState({ ...modalState, keywords: newKeywords })
  }

  const editExclusion = (index, value) => {
    const newExclusions = [...modalState.excluded]
    newExclusions[index] = value
    setModalState({ ...modalState, excluded: newExclusions })
  }

  useEffect(() => {
    if (displayRef.current) {
      scrollToBottom(displayRef.current)
    }
  }, [modalState.questions.length])

  const state = modalState
  const tab = state.tab

  const disabled = state.name === '' || state.questions.length === 0 || state.response === '' || state.response === '<p><br></p>'

  let saveButtonText = 'Create FAQ'
  let title = 'New FAQ'
  if (state.edit) {
    title = 'Edit FAQ'
    saveButtonText = 'Save FAQ'
  }

  let allTags = []
  if (props.tags) {
    allTags = Array.from(props.tags).concat(state.tags)
    allTags = [...new Set(allTags)]
  }

  const name = state.name
  const questions = state.questions
  const response = state.response
  const tags = state.tags
  const id = state.id
  const keywords = state.keywords
  const excluded = state.excluded

  return (
    <Modal
      title={title}
      onHide={props.onHide}
      open={props.open}
      handleSave={() => props.saveFAQ({ name, questions, response, tags, id, keywords, excluded })}
      size='sm'
      saveBtnText={saveButtonText}
      saveDisabled={disabled}
      saveIcon={state.edit ? 'save' : 'add'}
      tabLabels={['Basic', 'Advanced']}
      tab={tab}
      handleTabChange={handleTabChange}
    >
      <div className={classes.FAQModal}>
        <TabPanel value={tab} index={0}>
          <ModalSection
            title='Title'
            subtitle='Give your FAQ a title'
          >
            <TextField
              variant='outlined'
              margin='dense'
              placeholder='FAQ Title'
              fullWidth
              name='name'
              onChange={handleChange}
              value={state.name}
            />
          </ModalSection>
          <ModalSection
            title='Questions'
            subtitle='Enter several different ways that a visitor might ask the question. For best results, enter at least 5 questions.'
          >
            <div className={classes.questionsDiv} ref={displayRef}>
              {state.questions.map((q, index) => {
                return (
                  <div key={index} className={classes.question}>
                    <CancelIcon
                      color='primary'
                      className={classes.helpIcon}
                      style={{ marginRight: 5 }}
                      onClick={() => handleDelete(index)}
                    />
                    {(index + 1) + ': '}
                    <Input
                      value={q}
                      disableUnderline
                      color='primary'
                      style={{ marginLeft: 10, fontSize: '14px', color: '#8e5ae2', width: 800, padding: 0, height: 20 }}
                      onChange={e => handleQuestionChange(e.target.value, index)}
                    />
                  </div>
                )
              })}
            </div>
            <ButtonTextField addButton={addQuestion} placeholder='Type a question and hit enter...' />
          </ModalSection>
          <ModalSection
            title='Response'
            subtitle='The bot will respond with this response when a visitor types something similar to one of the above questions.'
          >
            <RichTextEditor
              message={state.response}
              onChange={editResponse}
              style={{ height: 80 }}
              textSize
              align='left'
              placeholder='Enter response text here...'
              lines={3}
            />
          </ModalSection>
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <ModalSection
            title='Tags'
            subtitle='The bot will use these tags to determine how to respond to a visitor’s question. For example, add geography-based tags to provide answers to similar questions tailored to their location.'
          >
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridGap: 20 }}>
              <Select
                variant='outlined'
                name='tags'
                fullWidth
                margin='dense'
                style={{ marginTop: 10, maxWidth: 530, overflowX: 'hidden', height: 41 }}
                value={state.tags}
                multiple
                renderValue={(selected) => selected.length === 0 ? 'Select FAQ Tags' : selected.join(', ')}
                displayEmpty
                onChange={handleChange}
              >
                <MenuItem disabled value=''>Select FAQ Tags</MenuItem>
                {
                  allTags.map((t, index) => (
                    <MenuItem value={t} key={index}>
                      <Checkbox checked={state.tags.indexOf(t) > -1} color='primary' />
                      <ListItemText primary={t} />
                    </MenuItem>
                  ))
                }
              </Select>
              <ButtonTextField placeholder='Type a new tag and hit enter...' addButton={addTag} />
            </div>
          </ModalSection>
          <ModalSection
            title='Keywords'
            subtitle='Include keywords to ensure that visitors will see this FAQ when certain words are included in their question.'
          >
            <div className={classes.keywords}>
              {
                state.keywords.map((option, index) => {
                  return (
                    <EditableChip
                      key={index}
                      value={option}
                      handleRemove={deleteKeyword(option)}
                      handleChange={value => editKeyWord(index, value)}
                    />
                  )
                })
              }
            </div>
            <div style={{ marginTop: 5 }}>
              <ButtonTextField placeholder='Type new keyword and hit enter...' addButton={addKeyword} />
            </div>
          </ModalSection>
          <ModalSection
            title='Keywords to Exclude'
            subtitle='Exclude keywords to ensure that visitors will NOT see this FAQ when certain words are included in their question.'
          >
            <div className={classes.keywords}>
              {
                state.excluded.map((option, index) => {
                  return (
                    <EditableChip
                      key={index}
                      value={option}
                      handleRemove={deleteExclusion(option)}
                      handleChange={value => editExclusion(index, value)}
                    />
                  )
                })
              }
            </div>
            <div style={{ marginTop: 5 }}>
              <ButtonTextField placeholder='Type a new keyword and hit enter...' addButton={addExclusion} />
            </div>
          </ModalSection>
        </TabPanel>
      </div>
    </Modal>
  )
}

export default FAQModal
