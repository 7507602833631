import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Button } from '@material-ui/core'
import CustomizedSnackbar from '../../../components/CustomizedSnackbar'

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  scriptdiv: {
    boxShadow: '0px 2px 9px #aaaaaa inset',
    margin: '24px 0px',
    display: 'inline-block',
    padding: '0px 25px 0px 20px'
  },
  outerdiv: {
    margin: 16
  },
  manualToggle: {
    marginRight: 50
  },
  chip: {
    width: 150,
    marginLeft: 40
  },
  switch: {
    marginLeft: 5
  }
}))

const ManualInstallationPage = (props) => {
  const scriptContents = props.scriptContents
  const classes = useStyles()
  const [showVerification, setShowVerification] = useState(false)
  const [snackState, setSnackState] = useState({
    open: false,
    variant: 'success',
    message: 'Snippet copied to clipboard!'
  })

  return (
    <div style={{ padding: 20 }}>
      <div className={classes.header}>
        <Typography variant='h6'>Manual Installation</Typography>
      </div>
      <div className={classes.outerdiv}>
        <Typography style={{ width: '80%' }}>
          To begin using Signals, add the code below to the &lt;header&gt; or &lt;footer&gt; tags on any webpage you want to track, or to the global header/footer if you want to track all pages on your site.
        </Typography>
        <br />
        <Typography style={{ width: '80%' }}>
          If included in your plan, chatbots can only appear on pages with the code. Install it in the footer to ensure bots load last, reducing page load delays.
        </Typography>
        <div className={classes.scriptdiv}>
          <pre>{scriptContents}</pre>
        </div>
        <div>
          <Button
            variant='contained'
            color='primary'
            onClick={() => {
              navigator.clipboard.writeText(scriptContents)
              setShowVerification(true)
              setTimeout(() => setShowVerification(false), 1500)
            }}
          >
            Copy to Clipboard
          </Button>
        </div>

        <div
          style={{ display: showVerification ? 'flex' : 'none' }}
          className={classes.copiedVerification}
        >
          <div className={classes.outerdiv}>
            <Typography variant='subtitle2' color='primary'>
              Copied to clipboard
            </Typography>
          </div>
        </div>
      </div>
      <CustomizedSnackbar state={snackState} handler={setSnackState} />
    </div>
  )
}

export default ManualInstallationPage
