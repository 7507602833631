import { Card, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { EmailTemplates } from './emailTemplate'
// eslint-disable-next-line
import { default as EmailClass } from 'classes/emails'
import { useHistory } from 'react-router'
import { Icon, IconType } from 'library/materialUI/Icon'
const useStyles = makeStyles(theme => ({
  card: {
    borderRadius: 5,
    padding: 20,
    cursor: 'pointer',
    '&:hover': {
      transform: 'scale(1.05)',
      border: '2px solid #8E5AE2'
    },
    boxShadow: '0px 3px 6px #00000029'
  },
  cardHeader: {
    display: 'flex',
    justifyContent: 'center'
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: '25px 0px'
  },
  cardContents: {
    fontSize: '1em',
    color: '#7A7A7A'
  },
  cardTitle: {
    fontSize: '1.75em',
    marginBottom: 25
  },
  cardsDiv: {
    marginTop: 20,
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, 400px)',
    gridGap: 25,
    gridTemplateRows: 340
  },
  templatePage: {
    display: 'grid',
    gridTemplateColumns: 'auto 430px'
  }
}))

interface EmailTypeCardProps {
  handleClick: (type: string) => any
  type: string
  icon: IconType // React.ReactElement<SvgIconProps>
  title: string
  contents: any
}

function EmailTypeCard (props: EmailTypeCardProps): JSX.Element {
  const classes = useStyles()
  const { handleClick, type, icon } = props
  return (
    <Card className={classes.card} elevation={2} onClick={() => handleClick(type)}>
      <div className={classes.iconContainer}>
        <Icon icon={icon} specificSize={80} color='#343434' />
      </div>
      <div className={classes.cardHeader}>
        <div className={classes.cardTitle}>
          {props.title}
        </div>
      </div>
      <div className={classes.cardContents}>
        {props.contents}
      </div>
    </Card>
  )
}

export default function EmailTypeSelection (): JSX.Element {
  const classes = useStyles()
  const history = useHistory()
  const customEmailTemplateDescription = 'Create your own custom email to be sent'

  async function handleEmailClick ({ template }: { template?: EmailClass }): Promise<any> {
    let email
    if (template) {
      email = await EmailClass.save({ data: template })
    } else {
      email = await EmailClass.save({ data: new EmailClass({}) })
    }
    const emailId = email.data.id
    history.push(`/emails/edit/${emailId}`)
  }

  return (
    <div className={classes.templatePage}>
      <div>
        <Typography variant='h5'>
          Build an Email using a premade template
        </Typography>
        <div className={classes.cardsDiv}>
          {EmailTemplates.map((email, index) => {
            return (
              <EmailTypeCard
                key={index}
                title={email.name}
                contents={email.description}
                type={email.type}
                handleClick={() => handleEmailClick({ template: EmailTemplates[index].email })}
                icon={email.icon}
              />
            )
          })}
        </div>
      </div>
      <div style={{ padding: '0px 30px', borderLeft: '1px solid #D9D9D9' }}>
        <Typography variant='h5'>
          Or start from scratch...
        </Typography>
        <div className={classes.cardsDiv}>
          <EmailTypeCard
            title='Custom Email'
            contents={customEmailTemplateDescription}
            type='custom'
            handleClick={() => handleEmailClick({})}
            icon='email'
          />
        </div>
      </div>
    </div>
  )
}
