import IntegrationPageProvider, { IntegrationPageProps } from './IntegrationPageProvider'
import PageHeader from './PageHeader'
import Content from './Content'
import UnsavedChanges from './UnsavedChanges'

export default function IntegrationPage (props: IntegrationPageProps) {
  return (
    <IntegrationPageProvider {...props}>
      <PageHeader />
      <Content />
      <UnsavedChanges />
    </IntegrationPageProvider>
  )
}
