import { ModalSizes } from './Modal'
import { SearchParams } from 'classes/queryHelpers'
import Loader from 'library/loading/Loader'
import { ObjectDependency, ObjectDependencyChildTypes, objectDependencyChildTypesList } from 'classes/objectDependency'
import User from 'classes/users'
import { OverridableComponent } from '@material-ui/core/OverridableComponent'
import { SvgIconTypeMap } from '@material-ui/core'
import ObjectDependencyMessage from 'library/objectDepenency/ObjectDependencyMessage'
import { Modal, ModalSection } from 'library/Modal'
import theme from 'theme'

interface DeleteModalProps {
  title: string
  object: string
  open: boolean
  onHide: () => void
  confirmAction: () => void
  deleteObject?: User
  message?: string
  confirmButtonText?: string
  cancelButtonText?: string
  cancelAction?: () => void
  confirmIcon?: OverridableComponent<SvgIconTypeMap>
  size?: ModalSizes
  noButton?: boolean
  disableAll?: boolean
}

const failToFindFilter = {
  filters: [
    [
      { field: 'parent_type', operator: 'eq' as const, value: 'return nothing' }
    ]
  ]
}

export default function DeleteModal (props: DeleteModalProps) {
  const deleteObject = props.deleteObject
  const isObjectDependency = objectDependencyChildTypesList.includes(props.object as ObjectDependencyChildTypes)
  const searchParams: SearchParams = deleteObject && isObjectDependency
    ? {
      filters: [
        [
          { field: 'child_id', operator: 'eq', value: deleteObject.id },
          { field: 'child_type', operator: 'eq', value: props.object }
        ]
      ]
    }
    : failToFindFilter
  const { data, isLoading, error } = ObjectDependency.loadAll({ searchParams })
  let newMessage: string | JSX.Element = props.message || 'Are you sure you want to delete this ' + props.object + '?'
  if (deleteObject && deleteObject?.name) {
    newMessage = 'Are you sure you want to delete ' + deleteObject.name + '?'
  }

  if (isLoading) {
    newMessage = (
      <Loader />
    )
  }

  if (error) {
    newMessage = 'There was an error with getting this ' + props.object + '\'s relationship data'
  }

  if (!error && !isLoading) {
    if (data.list.length) {
      newMessage = (
        <ObjectDependencyMessage
          objectDependencyList={data.list}
          objectType={props.object as ObjectDependencyChildTypes}
        />
      )
    }
  }
  const hasObjectDependencies = data ? data.list.length > 0 : false
  const disabled = (isLoading || !!error || hasObjectDependencies)
  const title = props.title || 'Delete'
  const confirmText = props.confirmButtonText || 'Delete'
  const cancelText = props.cancelButtonText || 'Cancel'

  return (
    <Modal
      title={title}
      saveBtnText={confirmText}
      cancelBtnText={cancelText}
      closeBtnFunction={props.cancelAction || props.onHide}
      handleSave={props.confirmAction}
      saveDisabled={disabled || props.disableAll}
      saveIcon='delete'
      open={props.open}
      size={props.size || 'xs'}
      onHide={props.onHide}
      saveBtnColor={theme.palette.error.main}
    >
      <ModalSection>
        {newMessage}
      </ModalSection>
    </Modal>
  )
}
