import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import StarIcon from '@material-ui/icons/Star'
import dateFormat from 'dateformat'
import BlockIcon from '@material-ui/icons/Block'
import { TenantDataContext } from './tenant-data-context'
import { ErrorBoundary } from 'react-error-boundary'
import { FormSubmission } from 'classes/forms'
import { ConversationContext } from './ConversationContext'

const useStyles = makeStyles(theme => ({
  blockIcon: {
    width: 16,
    height: 16,
    fill: '#F0706F'
  },
  eventContainer: {
    paddingBottom: 0,
    marginBottom: 15,
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    fontSize: '12px',
    color: '#555555',
    fontStyle: 'italic'
  },
  star: {
    fill: 'gold',
    width: 16,
    height: 16
  },
  dataContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: 'fit-content',
    padding: 20,
    border: '1px solid #D3D3D3',
    borderRadius: 5,
    margin: 20
  },
  dataRow: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: 180,
    width: '100%',
    marginTop: 10,
    marginBottom: 10
  },
  dataValue: {
    display: 'flex',
    width: 'fit-content',
    flexDirection: 'column',
    alignItems: 'flex-start'
  }
}))

const componentMapping = {
  routing: RoutingMessages,
  status: StatusMessages,
  tagging: TaggingMessages,
  leadScore: LeadScore,
  ratingComplete: RatingcompleteMessages,
  quickDraw: QuickDrawMessages,
  formSubmission: FormSubmissionMessage
}

const joinNames = (userNameList) => {
  let joinedNames = ''
  if (userNameList.length > 1) {
    const lastName = userNameList.pop()

    if (userNameList.length >= 2) {
      const remaining = userNameList.length - 1
      const visibleList = userNameList.slice(0, 2)
      joinedNames = visibleList.join(', ')
      if (remaining < 2) {
        joinedNames = joinedNames + ', and ' + remaining + ' other '
      } else {
        joinedNames = joinedNames + ', and ' + remaining + ' others '
      }
    } else {
      joinedNames = userNameList.join(', ')
      joinedNames = joinedNames + ', and ' + lastName
    }
  } else {
    joinedNames = userNameList[0] || '[User]'
  }
  return joinedNames
}

function FormSubmissionMessage (props) {
  const classes = useStyles()
  const events = props.events
  const event = events[events.length - 1]
  const { data: submission, isLoading } = FormSubmission.loadOne(event.attributes.data.submission_id)
  const visitorName = event.attributes.participant_name === '' ? 'Site Visitor' : event.attributes.participant_name
  const { conversation } = useContext(ConversationContext)
  const formName = conversation.attributes.form_name ? 'the ' + conversation.attributes.form_name : 'a'

  if (isLoading) {
    return <></>
  }

  const submissionData = Object.entries(submission.data).map(([key, value], index) => {
    return (
      <div className={classes.dataValue} key={index}>
        <div style={{ display: 'flex', color: '#00000080', fontSize: 12, marginBottom: 5 }}>{key.replaceAll('_', ' ').toUpperCase()}</div>
        {value}
      </div>
    )
  })

  const message = visitorName + ' submitted ' + formName + ' form'

  return (
    <div>
      <div className={classes.eventContainer}>
        {message} - {props.time}
      </div>
      <div className={classes.dataContainer}>
        {submissionData.map((data, index) => {
          if (index % 2 === 0) {
            return (
              <div className={classes.dataRow} key={index}>
                {data}
                {submissionData[index + 1]}
              </div>
            )
          }
          return <React.Fragment key={index} />
        })}
      </div>
    </div>
  )
}

function RatingcompleteMessages (props) {
  const classes = useStyles()
  const events = props.events
  const event = events[events.length - 1]
  const rating = event.attributes?.data?.rating

  if (rating === null || typeof rating === 'undefined') { return <></> }

  const message = 'The visitor gave this conversation a rating of ' + rating + ' out of 5'

  return (
    <div className={classes.eventContainer}>
      {message}
    </div>
  )
}

function QuickDrawMessages (props) {
  const classes = useStyles()
  const { users } = useContext(TenantDataContext)
  if (!users?.list) {
    return <></>
  }
  const events = props.events
  const event = events[events.length - 1]
  const alertedAgentUserIDs = event.attributes?.data?.alerted_agents
  const alertedAgentNames = users.list.filter(u => {
    if (alertedAgentUserIDs) {
      return alertedAgentUserIDs.includes(u.id)
    } else return false
  }).map(u => u.name)

  if (alertedAgentNames.length === 0) { return <></> }
  const message = alertedAgentNames.join(', ') + ' received a quick draw alert'

  return (
    <div className={classes.eventContainer}>
      {message} - {props.time}
    </div>
  )
}

function RoutingMessages (props) {
  const classes = useStyles()
  const { agentDict } = useContext(TenantDataContext)
  const participants = agentDict.participants
  const events = props.events
  const joins = events.filter(e => e.attributes.kind === 'join')
  const parts = events.filter(e => e.attributes.kind === 'part')
  const alerts = events.filter(e => e.attributes.kind === 'alert')

  const messages = []
  const joinUsers = []
  let joinSnippet = ''
  const partSnippet = ' left the conversation '
  const partUsers = []
  const alertUsers = []
  const alertSnippet = ' received a conversation alert '
  const joinVerb = joins.length > 1 ? 'were' : 'was'
  for (const join of joins) {
    const from = join.attributes.data.from
    const pid = join.relationships.from_participant.data.id
    if (participants[pid]) {
      joinUsers.push(participants[pid]?.agent_name)
    }
    if (from === 'agent') {
      if (join.attributes.data.from_participant_id === join.relationships.from_participant.data.id) {
        joinSnippet = ' joined the conversation '
      } else {
        joinSnippet = ` ${joinVerb} added to the conversation `
      }
    } else if (from === 'route') {
      joinSnippet = ` ${joinVerb} routed to the conversation `
    } else {
      joinSnippet = ' joined the conversation '
    }
  }

  for (const part of parts) {
    const partID = part.relationships.from_participant.data.id
    partUsers.push(participants[partID].agent_name)
  }

  for (const alert of alerts) {
    const alertID = alert.relationships.from_participant.data.id
    if (participants[alertID]) {
      alertUsers.push(participants[alertID]?.agent_name)
    }
  }

  if (joins.length && joinUsers.length) {
    const names = joinNames(joinUsers)
    const message = names + joinSnippet
    messages.push(message)
  }

  if (parts.length && partUsers.length) {
    const names = joinNames(partUsers)
    const message = names + partSnippet
    messages.push(message)
  }

  if (alerts.length && alertUsers.length) {
    const names = joinNames(alertUsers)
    const message = names + alertSnippet
    messages.push(message)
  }

  return (
    <div>
      {messages.map((message, index) => (
        <div className={classes.eventContainer} key={index}>
          {`${message} - ${props.time}`}
        </div>
      ))}
    </div>
  )
}

function StatusMessages (props) {
  const classes = useStyles()
  const events = props.events.filter(e => e.attributes.kind === 'close')
  if (!events.length) {
    return <></>
  }

  const event = events[events.length - 1]
  const closingType = event?.attributes?.data?.closing_type
  const agentName = event.attributes.participant_name || 'an agent'

  let text = ''
  if (closingType === 'auto_close') {
    text = `Conversation was automatically closed - ${props.time}`
  } else if (closingType === 'bot' || closingType === 'agent') {
    text = `Conversation was closed by ${agentName} - ${props.time}`
  } else {
    text = `Conversation was closed - ${props.time}`
  }

  return (
    <div className={classes.eventContainer}>
      {text}
    </div>
  )
}

function TaggingMessages (props) {
  const classes = useStyles()
  const { tagDict } = useContext(TenantDataContext)
  const events = props.events
  const event = events[events.length - 1]
  const tagsAdded = events.filter(e => e.attributes.kind === 'tag')
    .map(e => { return '"' + tagDict[e.attributes.data.tag_id]?.tag_name + '"' })
  const tagsRemoved = events.filter(e => e.attributes.kind === 'untag')
    .map(e => { return '"' + tagDict[e.attributes.data.tag_id]?.tag_name + '"' })

  const agentName = event.attributes.participant_name

  const messages = []
  if (tagsAdded.length) {
    const addedIDs = tagsAdded.join(', ')
    const noun = tagsAdded.length > 1 ? 'tags' : 'tag'
    const message = `${addedIDs} ${noun} added by ${agentName} - ${props.time}`
    messages.push(message)
  }
  if (tagsRemoved.length) {
    const removedIDs = tagsRemoved.join(', ')
    const noun = tagsRemoved.length > 1 ? 'tags' : 'tag'
    const message = `${removedIDs} ${noun} removed by ${agentName} - ${props.time}`
    messages.push(message)
  }

  return (
    <div>
      {messages.map((message, index) => (
        <div className={classes.eventContainer} key={index}>
          {message}
        </div>
      ))}
    </div>
  )
}

const LeadScoreIcon = (props) => {
  const classes = useStyles()

  if (props.stars === 0) {
    return <div style={{ marginLeft: 5 }}><BlockIcon className={classes.blockIcon} /></div>
  }

  const stars = []
  const n = props.stars
  for (let i = 0; i < n; i++) {
    stars.push(<StarIcon key={i} className={classes.star} />)
  }

  return <div style={{ marginLeft: 5 }}>{stars}</div>
}

function LeadScore (props) {
  const classes = useStyles()
  const events = props.events
  const event = events[events.length - 1]
  const leadScore = event.attributes?.data.lead_score
  const agentName = event.attributes.participant_name
  const message = `${agentName} set the lead score to `

  return (
    <div className={classes.eventContainer}>
      {message}
      <LeadScoreIcon stars={leadScore} />
      <div style={{ padding: '0px 6px' }}>{' - '}</div>
      {props.time}
    </div>
  )
}

const ConversationMetaFallback = () => {
  return (<></>)
}

function ConversationMeta (props) {
  const events = props.group.events

  const messageGroups = []
  for (const type in events) {
    const activities = events[type]
    const length = activities.length
    const activity = events[type][length - 1]
    const timestamp = activity.attributes.created_timestamp
    messageGroups.push({ type, timestamp, activities })
  }
  return (
    <div>
      {messageGroups.sort((a, b) => a.created_timestamp - b.created_timestamp)
        .map((group, index) => {
          const Component = componentMapping[group.type]
          const time = dateFormat(group.timestamp, 'shortTime')
          return (
            <ErrorBoundary
              key={index}
              FallbackComponent={ConversationMetaFallback}
              onReset={() => {
                window.location.reload()
              }}
            >
              <Component
                events={group.activities}
                time={time}
              />
            </ErrorBoundary>
          )
        })}
    </div>
  )
}

export default ConversationMeta
