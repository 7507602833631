import { doPost } from 'api/api'
import { camelCase } from 'lodash'
import { DateType, castToDate } from './classHelpers'
import { GenericObject } from './genericObject'
import { LoadAllProps } from './queryHelpers'
import { useDoQuery } from './useDoQuery'

interface NotificationList {
  list: Notification[]
  dict: { [id: Notification['id']]: Notification }
}

export type NotificationTypes = 'routes' | 'meetings' | 'alerts' | 'calendars' | 'notes' | 'followees' | 'featureAnnouncement' | 'botTests'

export class Notification extends GenericObject {
  tenantId: number
  userId: number
  notificationType: NotificationTypes
  title: string
  body: string
  actionLink: string
  linkLabel: string
  objectId: number
  resolvedTimestamp: DateType
  dismissedTimestamp: DateType
  sms: boolean
  desktop: boolean
  email: boolean
  app: boolean
  external: Record<string, string>
  participantId: number

  static loadAll (props?: LoadAllProps): { data: NotificationList, isLoading: boolean, refetch: () => void } {
    return useDoQuery({
      path: '/notifications',
      objectClass: Notification,
      searchParams: props?.searchParams,
      refetchInterval: 120000,
      initialData: { list: [], dict: {} }
    })
  }

  static dismissAll (ids: number[]): {} {
    const payload = {
      attributes: {
        notification_ids: ids
      }
    }
    return doPost({ path: '/api/notifications/bulk_dismiss', data: payload })
  }

  constructor ({ row }: { row: Record<string, any> }) {
    super({ row })
    const notification = row.attributes
    this.tenantId = notification.tenant_id
    this.userId = notification.user_id
    this.notificationType = camelCase(notification.notification_type) as NotificationTypes
    this.title = notification.title
    this.body = notification.body
    this.actionLink = notification.action_link
    this.linkLabel = notification.link_label
    this.objectId = notification.object_id
    this.resolvedTimestamp = castToDate(notification.resolved_timestamp)
    this.dismissedTimestamp = castToDate(notification.dismissed_timestamp)
    this.sms = notification.sms
    this.desktop = notification.desktop
    this.email = notification.email
    this.app = notification.app
    this.external = notification.external
    this.participantId = notification.participant_id
  }
}
