import { TreeChild } from 'cf-components/TreeMultiSelect'
import { doGet, doPost, doPut, doDelete } from './api'
import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'

function addCategory (pageType: string): Promise<any> {
  const path = '/intent_pages/categories'
  const data = {
    type: 'intent_page_category',
    attributes: {
      name: pageType
    },
    relationships: {}
  }
  return doPost({ path, data, useChatURL: true })
}

function updateCategory (id: number, pageType: string): Promise<any> {
  const path = `/intent_pages/categories/${id}`
  const data = {
    type: 'intent_page_category',
    attributes: {
      name: pageType
    }
  }

  return doPut({ path, data, useChatURL: true })
}

function postURL (categoryID: number, pageURL: string): Promise<any> {
  const path = `/intent_pages/categories/${categoryID}/url`
  const data = {
    type: 'intent_page_url',
    attributes: {
      page_url: pageURL
    },
    relationships: {}
  }

  return doPost({ path, data, useChatURL: true })
}

function updateURL (id: number, categoryID: number, pageURL: string): Promise<any> {
  const path = `/intent_pages/categories/${categoryID}/url/${id}`
  const data = {
    type: 'intent_page_url',
    attributes: {
      page_url: pageURL
    }
  }

  return doPut({ path, data, useChatURL: true })
}

function deleteURL (id: number, categoryID: number): Promise<any> {
  return doDelete({ path: `/intent_pages/categories/${categoryID}/url/${id}`, useChatURL: true })
}

function deleteCategory (id: number): Promise<any> {
  return doDelete({ path: `/intent_pages/categories/${id}`, useChatURL: true })
}

function getURLS (categoryID: number): Promise<any> {
  return doGet({ path: `/intent_pages/categories/${categoryID}/urls`, useChatURL: true })
}

function getCategories (): Promise<any> {
  return doGet({ path: '/intent_pages/categories', useChatURL: true })
}

function getSuggestions (categoryID: number): Promise<any> {
  return doGet({ path: `/intent_pages/categories/${categoryID}/suggestions`, useChatURL: true })
}

function getHighIntent (): { data: TreeChild[], isLoading: boolean } {
  const [highIntentList, setHighIntentList] = useState<TreeChild[]>([])
  const [loadingIntentPages, setLoadingIntentPages] = useState(true)
  const { data: categories, isLoading: loadingCategories } = useQuery(
    'filter-categories',
    () => getCategories().then(response => response.data),
    {
      staleTime: 120000,
      retry: (failureCount, _) => failureCount < 3
    }
  )
  useEffect(() => {
    if (!loadingCategories && categories) {
      const result: any[] = []
      categories.forEach((category: any) => {
        const value = category.attributes.name
        const temp: TreeChild[] = []
        if (category.attributes.urls) {
          category.attributes.urls.forEach((url: any) => {
            temp.push({ value: url.id, label: url.page_url })
          })
        }
        result.push({ value: value, children: temp })
      })
      setHighIntentList(result)
      setLoadingIntentPages(false)
    }
  }, [loadingCategories, categories])
  return { data: highIntentList, isLoading: loadingIntentPages }
}

export { addCategory, updateURL, deleteURL, getURLS, postURL, deleteCategory, updateCategory, getCategories, getSuggestions, getHighIntent }
