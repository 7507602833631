import Modal from 'components/Modal'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  container: {
    margin: '20px',
    padding: '20px',
    backgroundColor: '#FFFFFF',
    border: '1px solid #D3D3D3',
    borderRadius: '5px'
  },
  header: {
    fontSize: '16px'
  },
  sectionTitle: {
    fotnSize: '14px',
    fontWeight: 'bold'
  }
}))

interface FormMissingItemsModalProps {
  checklist: any
  open: boolean
  onHide: () => void
  checkMissing: () => void
  checklistMapping: any[]
  triggerMissing: (value: string) => void
}

export function FormMissingItemsModal ({ checklist, open, onHide, checklistMapping, triggerMissing }: FormMissingItemsModalProps) {
  const classes = useStyles()
  const missingOnlineItems: any[] = []
  const missingOfflineItems: any[] = []
  const indexList: string[] = []
  for (const property in checklist) {
    if (!checklist[property]) {
      indexList.push(property)
    }
  }
  checklistMapping.forEach((item) => {
    if (indexList.includes(item.name)) {
      if (item.group === 'online') {
        missingOnlineItems.push(item)
      } else if (item.group === 'offline') {
        missingOfflineItems.push(item)
      }
    }
  })
  return (
    <Modal
      open={open}
      onHide={onHide}
      title='Warning'
      onSubmit={() => {
        if (missingOnlineItems.length > 0) {
          triggerMissing(missingOnlineItems[0].name)
        } else if (missingOfflineItems.length > 0) {
          triggerMissing(missingOfflineItems[0].name)
        }
        onHide()
      }}
      saveButtonText='View Incomplete Items'
      size='sm'
    >
      <div className={classes.container}>
        <div className={classes.header}>
          Your form prompt has incomplete settings
        </div>
        <div style={{ marginTop: '20px', marginBottom: '20px' }}>
          {missingOnlineItems.length > 0 && (
            <div style={{ marginBottom: '10px' }}>
              <div className={classes.sectionTitle}>
                Prompt Content - Agents Online
              </div>
              {missingOnlineItems.map((item, index) => {
                return (
                  <div key={index} onClick={() => triggerMissing(item.name)} style={{ color: '#EA422A', cursor: 'pointer', marginLeft: '12px', marginTop: '10px' }}>
                    {item.label}
                  </div>
                )
              })}
            </div>
          )}
        </div>
        <div>
          {missingOfflineItems.length > 0 && (
            <div>
              <div className={classes.sectionTitle}>
                Prompt Content - Agents Offline
              </div>
              {missingOfflineItems.map((item, index) => {
                return (
                  <div key={index} onClick={() => triggerMissing(item.name)} style={{ color: '#EA422A', cursor: 'pointer', marginLeft: '12px', marginTop: '10px' }}>
                    {item.label}
                  </div>
                )
              })}
            </div>
          )}
        </div>
        <div>
          Please complete the setup so that your form behaves as expected
        </div>
      </div>
    </Modal>
  )
}
