const chatRoles = [
  { key: 0, value: '' },
  { key: 1, value: 'Admin' },
  { key: 2, value: 'Marketer' },
  { key: 3, value: 'Sales Development Rep' },
  { key: 4, value: 'Account Executive' },
  { key: 5, value: 'Customer Support Rep' },
  { key: 6, value: 'Sales Manager' },
  { key: 7, value: 'Customer Support Manager' },
  { key: 8, value: 'Calendar User' },
  { key: 9, value: 'Admin No Chat' }
]

const chatPermissions = [
  { key: 'conversations_all', value: 'Access to all conversations' },
  {
    key: 'conversations_mine',
    value: 'Access to their own conversations only'
  },
  {
    key: 'conversations_unclaimed',
    value: 'Access to their own conversations and unclaimed ones'
  }
]

const headCells = [
  { id: 'name', align: 'left', disablePadding: true, label: 'User' },
  { id: 'role', align: 'left', disablePadding: false, label: 'Role' },
  { id: 'joined', align: 'left', disablePadding: false, label: 'Joined Date' },
  { id: 'cronofy_sync', align: 'left', disablePadding: false, label: 'Calendar' },
  { id: 'mfa', align: 'left', disablePadding: false, label: 'MFA' },
  { id: 'availability', align: 'left', disablePadding: false, label: 'Availability' },
  { id: 'permissions', align: 'right', disablePadding: false, label: 'Permissions' }
]

export { chatRoles, chatPermissions, headCells }
