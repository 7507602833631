import { makeStyles } from '@material-ui/core/styles'
import { Divider } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  divider: {
    marginBottom: 10
  },
  sectionSubtitle: {
    color: '#808080',
    fontFamily: 'poppins',
    fontSize: '14px',
    marginTop: '5px',
    marginBottom: '5px'
  },
  sectionTitle: {
    fontSize: '20px',
    fontWeight: 'bold',
    marginBottom: '10px',
    color: '#000000BF'
  },
  container: {
    margin: '15px',
    padding: '20px',
    backgroundColor: '#FFFFFF',
    border: '1px solid #D3D3D3',
    borderRadius: '5px'
  }
}))

interface PaperProps {
  children: React.ReactNode
  title: string
  subtitle?: string
  divider?: boolean
}

export default function Paper ({ children, title, subtitle, divider }: PaperProps): JSX.Element {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <div className={classes.sectionTitle}>
        {title}
      </div>
      {subtitle && <div className={classes.sectionSubtitle}>{subtitle}</div>}
      {divider && <div className={classes.divider}><Divider /></div>}
      {children}
    </div>
  )
}
