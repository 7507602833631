
const descriptionText = "Use this skill to determine when to search for contact information on the company that a visitor's IP address is associated with."

const Description = (): JSX.Element => {
  return (
    <div>
      <div>
        {descriptionText}
      </div>
      <ul>
        <li>Define a buying committee to determine search eligibility based upon:</li>
        <ul>
          <li>Job Title</li>
          <li>Seniority</li>
          <li>Department</li>
        </ul>
        <li>First set up your <a href='#/account_management/icp' target='_blank'>ICP</a> before defining your buying committee</li>
      </ul>
    </div>
  )
}

const howItWorksPara1 = 'All contacts will be added to your Signals contact list.'
const howItWorksPara2 = 'You can connect this skill to an integration skill to add the contacts to that particular integration, as well as choosing which campaign/list you would like to add it to.'
const howItWorksPara3 = 'If you do not have a CRM, it will only add the contacts to your Signals contacts.'
const howItWorksPara4 = 'If you have a CRM and you do not add an integration skill, it will send the contacts to the default campaign you have set up through your CRM integration settings.'

const HowItWorks = () => {
  return (
    <div>
      <div style={{ marginBottom: 10 }}>
        {howItWorksPara1}
      </div>
      <div style={{ marginBottom: 10 }}>
        {howItWorksPara2}
      </div>
      <div style={{ marginBottom: 10 }}>
        {howItWorksPara3}
      </div>
      <div>
        {howItWorksPara4}
      </div>
    </div>
  )
}

const bestPracticeText = 'If you experience large amounts of traffic, or if larger companies frequent your site'

const BestPractices = () => {
  return (
    <div>
      <div>
        {bestPracticeText}
      </div>
      <ul>
        <li>Be specific when determining buying committee criteria to improve the applicability of the contacts returned</li>
        <li>Limit the number of contacts returned from individual companies</li>
      </ul>
    </div>
  )
}

export const contactSearchDocs = {
  helpCenterLink: 'https://help.getsignals.ai/article/lh2e2351ri-plays-contact-search-node',
  bestPractices: BestPractices,
  howItWorks: HowItWorks,
  description: Description,
  videoLink: 'https://youtu.be/yPuH0eo81fg'
}
