import React from 'react';
import AppPage from 'cf-components/AppPage'
import UsageProspectsPage from './UsageProspectsPage'

export default function AdminUsagePage () {
  const tabs = [
    { name: 'PROSPECTS', url: '/admin_portal/usage/prospects' },
    { name: '6SENSE', url: '/admin_portal/usage/6sense' },
    { name: 'TEXT ALERTS', url: '/admin_portal/usage/twilio' },
    { name: 'EMAILS', url: '/admin_portal/usage/emails' }
  ]

  return (
    <AppPage
      title='Usage'
      padding={0}
      tabs={tabs}
    >
      <UsageProspectsPage component='contact_lookups' />
      <UsageProspectsPage component='reverse_ip' />
      <UsageProspectsPage component='sms' />
      <UsageProspectsPage component='email' />
    </AppPage>
  )
}
