import { NPSResponse } from 'classes/npsResponse'
import { NPSSettings } from 'classes/npsSettings'
import { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import { usePostageSocket } from 'components/PostageSocket'
import { SessionContext } from 'session-context'

interface INPSPromptContext {
  settings: NPSSettings
  score: number | null
  resID: number | string | null
  setScore: (score: number) => void
  leaveFeedback: (feedback: string) => void
  goBack: () => void
  open: boolean
  closePrompt: () => void
  confirmationMessage: string
  confirmMeeting: (id: string) => void
  goToHelpDocs: () => void
}

const shouldFireOnPage = (settings: NPSSettings) => {
  const url = window.location.href
  for (const keyword of settings.pages.keywords) {
    if (url.includes(keyword)) {
      return false
    }
  }
  return true
}

export const NPSPromptContext = createContext<INPSPromptContext>({} as any)

type NPSPromptContextProps = {
  children: ReactNode
}
export function NPSPromptContextProvider ({ children }: NPSPromptContextProps) {
  const { user } = useContext(SessionContext)
  const { settings, isLoading } = NPSSettings.load()
  const [response, setResponse] = useState<NPSResponse>(new NPSResponse())
  const [confirmation, setConfirmation] = useState('')
  const [open, setOpen] = useState(false)
  const [timeElapsed, setTimeElapsed] = useState(false)
  const goBack = () => setScore(null)
  const url = window.location.href
  window.hideSnackBar = open

  const getResponse = () => {
    return response
  }
  usePostageSocket((msg: any) => {
    setTimeout(() => {
      const r = getResponse()
      if (!r.id && !r.score && msg?.attributes?.data_type === 'nps_response' && msg?.attributes?.method === 'POST') {
        setOpen(false)
      }
    }, 2000)
  })

  useEffect(() => {
    if (!isLoading) {
      const timeout = settings.triggers.promptDelay * 1000
      setTimeout(() => {
        setTimeElapsed(true)
      }, timeout)
    }
    // eslint-disable-next-line
  }, [isLoading])

  useEffect(() => {
    if (timeElapsed && settings.shouldShowPrompt) {
      if (shouldFireOnPage(settings) && !user?.attributes?.assist_login) {
        setTimeout(() => {
          setOpen(true)
        }, 1000)
      } else if (open) {
        setOpen(false)
      }
    }
    // eslint-disable-next-line
  }, [settings, timeElapsed, url])

  useEffect(() => {
    if (confirmation) {
      setTimeout(() => {
        setOpen(false)
      }, 3000)
    }
  }, [confirmation])

  const closePrompt = () => {
    response.save().then(() => setOpen(false))
  }

  const confirmMeeting = (id: string) => {
    response.meetingID = id
    response.save().then(() => setConfirmation('Your meeting has been scheduled!'))
  }

  const leaveFeedback = (feedback: string) => {
    response.feedback = feedback
    response.save().then(() => setConfirmation('Thank you for your feedback!'))
  }

  const setScore = (value: number | null) => {
    response.score = value
    setResponse({ ...response, score: value })
    response.save().then(newResponse => setResponse(newResponse))
  }

  const goToHelpDocs = () => {
    window.open('https://help.getsignals.ai', '_blank')
    response.clickedHelpdocsLink = true
    response.save().then(newResponse => setResponse(newResponse))
  }

  return (
    <NPSPromptContext.Provider
      value={{
        settings,
        score: response.score,
        resID: response.id,
        setScore,
        goBack,
        open,
        closePrompt,
        leaveFeedback,
        confirmationMessage: confirmation,
        confirmMeeting,
        goToHelpDocs
      }}
    >
      {!isLoading && children}
    </NPSPromptContext.Provider>
  )
}
