import React from 'react'
import { Modal, ModalSection } from 'cf-components/Modal'
import DateFormat from 'dateformat'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  accountRow: {
    display: 'grid',
    gridTemplateColumns: '2fr 3fr 3fr',
    alignItems: 'center',
    fontSize: 14,
    gridGap: 30,
    margin: '10px 0px'
  },
  gridHeader: {
    display: 'grid',
    gridTemplateColumns: '2fr 3fr 3fr',
    alignItems: 'center',
    fontWeight: 600,
    gridGap: 30
  }
}))

const downloadCSV = (filename, data) => {
  const encodedUri = encodeURI(data)
  const elem = window.document.createElement('a')
  elem.setAttribute('href', encodedUri)
  elem.setAttribute('download', filename)
  document.body.appendChild(elem)
  elem.click()
  document.body.removeChild(elem)
}

const FailedToSyncModal = props => {
  const nonSyncedAccountsMeta = props.nonSyncedAccountsMeta
  const classes = useStyles()

  if (!nonSyncedAccountsMeta?.accounts_without_website) {
    return <></>
  }

  const nonSyncedAccounts = nonSyncedAccountsMeta?.accounts_without_website || []

  const handleDownloadCSV = () => {
    const date = new Date().toString()
    const filename = 'Non_Synced_Accounts_' + DateFormat(date, 'isoDate') + '.csv'
    const accountsWithoutWebsite = nonSyncedAccountsMeta?.accounts_without_website
    const rows = []
    rows.push(['CRM_Account_Id', 'Account_Name', 'Url'])
    for (const account of accountsWithoutWebsite) {
      const row = []
      row.push(account.crm_id)
      row.push(account.name)
      row.push(account.crm_link)
      rows.push(row)
    }

    const csvContent = 'data:text/csv;charset=utf-8,' +
      rows.map(e => e.join(',')).join('\n')
    downloadCSV(filename, csvContent)
  }
  const handleOpenCRM = (crm_link) => {
    window.open(
      crm_link
    )
  }

  return (
    <Modal
      title='Failed to Sync'
      open={props.open}
      onHide={props.onHide}
      size='sm'
      rightButtonProps={{
        text: 'Download List',
        action: handleDownloadCSV,
        disabled: false
      }}
    >
      <ModalSection
        subtitle={'We were not able to sync the following accounts from your CRM because they did not have a \'Website\' attribute. Signals uses the website to determine the domain of the account.'}
      >
        <div style={{ marginTop: 20 }}>
          <div className={classes.gridHeader}>
            <div>
              Name
            </div>
            <div>
              Account ID
            </div>
            <div />
          </div>
          {nonSyncedAccounts.map((account, index) => (
            <div key={index} className={classes.accountRow}>
              <div style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                {account.name}
              </div>
              <div>
                {account.crm_id}
              </div>
              <div>
                <Button variant='outlined' color='primary' onClick={() => handleOpenCRM(account.crm_link)}>Go to record in CRM</Button>
              </div>
            </div>
          ))}
        </div>
      </ModalSection>

    </Modal>
  )
}

export default FailedToSyncModal
