/* eslint-disable @typescript-eslint/naming-convention */
import * as yup from 'yup'
import Picker from 'cf-components/Pickers';
import { ModalContentsProps } from 'library/flowBuilder/nodes/NodeModal'
import { ModalSection } from 'library/Modal'
import { LabeledCheckbox, RadioButtons } from 'library/materialUI'
import { defaultErrorMsg } from 'pages/bot/flow-builder/nodes/nodeHelpers';
import { useEffect, useState } from 'react';
import { getIntegration } from 'api/integrations'
import WarningDiv from '../WarningDiv';
import { Cadence } from 'classes/classes';
import { routeOptionsSchema, RoutingSelector } from 'library/routing/RoutingCriteria'
import DefaultOwnerSection from 'pages/bot/flow-builder/DefaultOwnerSection';
import FTUX from 'cf-components/FTUX';
// import LearnMoreLink from 'cf-components/LearnMoreLink';
import { makeStyles } from '@material-ui/core/styles'
import { DisplaySettings } from 'classes/displaySettings'

export const SalesloftSchema = yup.object().shape({
  settings: yup.object({
      cadenceId: yup.number().required(defaultErrorMsg),
      action: yup.string().required(defaultErrorMsg),
      allowReEnroll: yup.boolean().notRequired(),
      exceptionCadences: yup.array().when('allowReEnroll', {
        is: (allowReEnroll: boolean) => allowReEnroll === true,
        then: yup.array().min(1, defaultErrorMsg)
      }),
      routeOptions: routeOptionsSchema
      // firstMethod: yup.string().required(defaultErrorMsg),
      // firstValue: yup.mixed(),
      // thenMethodList: yup.array().notRequired(),
      // thenValueList: yup.array()
  })
})

const useStyles = makeStyles(theme => ({
  grayText: {
    color: '#00000080',
    fontFamily: 'poppins'
  },
  purpleText: {
    color: theme.palette.primary.main,
    fontFamily: 'poppins',
    fontWeight: 'bold'
  }
}))

export function SalesloftModal ({ settings, updateSettings }: ModalContentsProps): JSX.Element {
  const classes = useStyles()
  const [displayWarning, setDisplayWarning] = useState(true)
  const [enabled, setEnabled] = useState(false)
  const [routeOptions, setRouteOptions] = useState(settings.routeOptions || [{ method: 'account_owner', objectIDs: [], type: 'round_robin' }])
  const { settings: dispSettings } = DisplaySettings.load()
  const updateFunction = () => { DisplaySettings.update({ page: 'ftux', type: 'salesloft-playrunner', settings: { ...dispSettings.ftux.settings['salesloft-playrunner'], help_center: { complete: true } } }) }

  useEffect(() => {
    if (routeOptions) {
      updateSettings({ routeOptions: routeOptions })
    }
  // eslint-disable-next-line
  }, [routeOptions])

  useEffect(() => {
    getIntegration('salesloft').then((res: any) => {
      if (res.ok) {
        setDisplayWarning(false)
      }
      if (settings.mainIntegration !== '' && res.ok) {
        if (res.data.attributes.integration_settings ? res.data.attributes.integration_settings.enabled : res.data.attributes.enabled) {
          setEnabled(true)
        }
      }
    })
  }, [settings.mainIntegration])

  useEffect(() => {
    if (settings.action === 'remove') {
      updateSettings({ allowReEnroll: false, exceptionCadences: [] })
    }
  // eslint-disable-next-line
  }, [settings.action])

  if (displayWarning || !enabled) {
    return (
      <WarningDiv
        title={settings.title}
        notSetup={displayWarning}
      />
    )
  }

  return (
    <div>
      <ModalSection
        title='Action'
        subtitle='Add or remove visitors or prospects from a Salesloft cadence'
      >
        <div id='sl_help_center' />
        <RadioButtons
          value={settings.action}
          handleChange={(value: string) => updateSettings({ action: value })}
          buttons={[
            { label: 'Add to cadence', value: 'add', tooltipText: 'Prospect or contact will be added to the selected Salesloft cadence' },
            { label: 'Remove from cadence', value: 'remove', tooltipText: 'Prospect or contact will be removed from the selected Salesloft cadence' }
          ]}
        />
      </ModalSection>
      <ModalSection
        title='Cadence'
        subtitle='Which cadence should the contact(s) be added to?'
      >
        <Picker
          selection={settings.cadenceId}
          setSelection={(e: any) => {
            updateSettings({ cadenceId: e })
          }}
          objectType='cadence'
          label='Select a cadence'
          loadAllProps={{
            searchParams: {
              extraHeaders: {
                'x-integration': 'salesloft'
              }
            }
          }}
          queryBackend
        />
        {settings.action === 'add' && (
          <div style={{ marginTop: '5px' }}>
            <LabeledCheckbox
              label='Allow contacts already enrolled in cadences to be re-enrolled into new ones'
              checked={settings.allowReEnroll}
              onChange={(value: boolean) => updateSettings({ allowReEnroll: value })}
            />
            {settings.allowReEnroll && (
              <ModalSection
                title='Exceptions'
                subtitle='Visitors enrolled in the following cadences will remain int heir current cadence and will not be re-enrolled in tthe cadence above'
              >
                <Picker
                  multiple
                  selection={settings.exceptionCadences}
                  setSelection={(value: any) => {
                    updateSettings({ exceptionCadences: value })
                  }}
                  objectType='cadence'
                  label='Exception Cadences'
                />
              </ModalSection>
            )}
            <div>
              <RoutingSelector
                title='Ownership Criteria'
                description='How should the Salesloft Owner be determined?'
                routeOptions={routeOptions}
                handleChange={setRouteOptions}
                actionText='assign'
                limit={2}
              />
            </div>
            <ModalSection>
              <DefaultOwnerSection
                integration='salesloft'
                link='/#/settings/salesloft'
              />
            </ModalSection>
          </div>
        )}
      </ModalSection>
      {!dispSettings.ftux.settings['salesloft-playrunner']?.help_center && (
        <FTUX
          page='salesloft-playrunner'
          anchors={[{
            title: 'Help Center',
            body: (
              <div>
                <div>
                  <span className={classes.grayText}>Click this purple information icon to open the Help Center and learn how to best use the Salesloft skill.</span>
                </div>
                {/* <LearnMoreLink link='https://help.getsignals.ai/' /> */}
              </div>
            ),
            anchorID: 'sl_help_center',
            anchorPos: 'left',
            anchorOriginHorizontal: 585,
            anchorOriginVertical: -142,
            transformOriginHorizontal: 'left',
            transformOriginVertical: 'top',
            arrowOffset: '30px'
          }
          ]}
          updateFunction={updateFunction}
        />
      )}
    </div>
  )
}

export function SalesloftBody ({ settings }: any): JSX.Element {
  const { data, isLoading } = Cadence.loadAll()

  if (isLoading) {
    return <>Loading</>
  }

  const curCadence = data.list.find((cadence: Cadence) => cadence.id === settings.cadenceId)
  const exceptions = settings.exceptionCadences.length
  let exceptionText = ''
  if (settings.action === 'add') {
    if (exceptions > 0) {
      exceptionText = exceptionText + data.list.find((cadence: Cadence) => cadence.id === settings.exceptionCadences[0])?.name || ''
    }
    if (exceptions === 2) {
      exceptionText = exceptionText + ' or ' + data.list.find((cadence: Cadence) => cadence.id === settings.exceptionCadences[1])?.name || ''
    }
    if (exceptions >= 3) {
      exceptionText = exceptionText + ', ' + (data.list.find((cadence: Cadence) => cadence.id === settings.exceptionCadences[1])?.name || '') + ', + ' + (exceptions - 2) + ' other'
    }
    if (exceptions > 3) {
      exceptionText = exceptionText + 's'
    }
  }

  return (
    <div>
      {settings.cadenceId && (
        <div>
          {settings.action === 'remove' ? 'Remove from' : 'Enroll into'} <span style={{ fontWeight: 'bold' }}>{curCadence?.name}</span> {exceptions > 0 && 'unless already enrolled in '}
          <span style={{ fontWeight: 'bold' }}>{exceptionText}</span>
        </div>
      )}
    </div>
  )
}
