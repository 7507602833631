import GenericObject from './genericObject';
import { useDoQuery } from './useDoQuery';

interface availableIntegration {
  name: string
  label: string
  enabled: boolean
}

interface featureIntegration {
  name: string
  label: string
}

export class CRMFeature extends GenericObject {
  label: string
  availableIntegrations: availableIntegration[]
  integration: featureIntegration

  static loadOne (featureName: string): { data: CRMFeature, isLoading: boolean } {
    return useDoQuery({
      path: `/integrations/features/${featureName}`,
      objectClass: CRMFeature
    })
  }

  constructor ({ row }: { row: Record<string, any> }) {
    super({ row })
    const feature = row.attributes
    this.label = feature.feature_label
    this.availableIntegrations = feature.available_integrations
    this.integration = feature.integration
  }
}
