
const headers = {
  'Content-Type': 'application/vnd.api+json'
}

function getSettings ({ kind, sid = undefined }) {
  let endpoint = '/api/user_display_settings'
  let newHeaders = headers
  if (kind) {
    endpoint = endpoint + `/${kind}`
  }
  if (sid) {
    newHeaders = { ...headers, Authorization: `bearer ${sid}` }
  }
  return fetch(endpoint, {
    method: 'GET',
    headers: newHeaders,
    cache: 'no-store'
  })
    .then(response => response.json())
}

function saveSettings ({ kind, settings, post }) {
  const method = post ? 'POST' : 'PATCH'
  const path = `/api/user_display_settings/${kind}`
  const body = {
    data: {
      type: 'user_display_settings',
      attributes: {
        settings: settings
      }
    }
  }

  return fetch(path, {
    method: method,
    headers: headers,
    body: JSON.stringify(body)
  }).then(response => {
    return response.json()
  })
}

export { getSettings, saveSettings }
