import { Checkbox } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Icon } from 'library/materialUI/Icon'
import { handleSelection } from './helpers'

const useStyles = makeStyles(theme => ({
  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  nodeHeader: {
    display: 'grid',
    gridTemplateColumns: '40px auto',
    minHeight: 42,
    gridGap: 5,
    alignItems: 'center',
    padding: '2px 8px 0px 8px',
    borderBottom: '1px solid #e0e0e0'
  },
  endOfFlowDiv: {
    marginTop: 12,
    backgroundColor: '#FF5D5C',
    color: 'white',
    fontWeight: 600,
    padding: 2,
    display: 'flex',
    justifyContent: 'center',
    borderRadius: 2,
    position: 'absolute',
    width: 222
  }
}))

export function EndNodeIndicator (props: any) {
  const classes = useStyles()

  if (props.completionText) {
    return (
      <div className={classes.endOfFlowDiv}>
        {props.completionText}
      </div>
    )
  } else {
    return <></>
  }
}

export function NodeHeader ({ base, state, mapping, nodeMapping }: any) {
  const classes = useStyles()
  const kind = base.state.kind
  const Component = kind === 'Sequence' ? nodeMapping[kind].header : undefined
  let title = mapping.title
  let style = {}
  let color = mapping.color
  let icon = mapping.icon
  const baseState = base.state
  if (baseState.type === 'sequence') {
    title = baseState.settings.name
    style = { color: 'white', backgroundColor: mapping.color }
    color = 'white'
  }

  if (baseState.settings?.title === 'Integrations') {
    title = baseState.settings.title
    style = { color: 'white', backgroundColor: '#9933FF' }
    color = 'white'
    icon = 'memory'
  }

  if (Component) {
    return (
      <div className={classes.nodeHeader} style={style}>
        <div className={classes.iconWrapper}>
          <NodeIcon
            base={base}
            state={state}
            icon={icon}
            color={color}
          />
        </div>
        <Component
          settings={base.state.settings}
        />
      </div>
    )
  }
  return (
    <div className={classes.nodeHeader} style={style}>
      <div className={classes.iconWrapper}>
        <NodeIcon
          base={base}
          state={state}
          icon={icon}
          color={color}
        />
      </div>
      <div>{title}</div>
    </div>
  )
}

export function NodeIcon ({ base, state, icon, color }: any) {
  const checkboxClick = (e: any) => {
    if (!e) e = window.event
    e.cancelBubble = true
    if (e.stopPropagation) e.stopPropagation()
    e.preventDefault()
    handleSelection(base)
  }

  if (state && (state.hover || state.selected)) {
    return (
      <Checkbox
        color='primary'
        checked={state.selected}
        onClick={checkboxClick}
        onDoubleClick={checkboxClick}
        style={{ padding: 6 }}
      />
    )
  }

  return (
    <Icon
      icon={icon}
      color={color}
      size='md'
    />
  )
}
