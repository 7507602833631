import React, { useState, useEffect, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import AppPage from 'cf-components/AppPage'
import { EmailSetup } from './EmailSetup'
import SetupComplete from './SetupComplete'
import Fab from '@material-ui/core/Fab'
import { getEmailSettings, updateEmailSettings } from 'api/emails'
import { SessionContext } from 'session-context'

const useStyles = makeStyles(theme => ({
  setupPage: {
    padding: 20
  },
  setupDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 30
  }
}))

function BeginSetupPage (props) {
  const classes = useStyles()

  return (
    <div className={classes.setupPage}>
      <div className={classes.setupDiv}>
        You need to set up a few things before you can begin sending emails
      </div>
      <div className={classes.setupDiv}>
        <Fab
          variant='extended'
          color='primary'
          size='small'
          style={{ width: 125, marginTop: 20 }}
          onClick={props.onClick}
        >
          Set Up Now
        </Fab>
      </div>
    </div>
  )
}

function EmailSettingsPage (props) {
  const [started, setStarted] = useState(false)
  const [settings, setSettings] = useState(null)
  const [loading, setLoading] = useState(true)
  const { user } = useContext(SessionContext)
  const chatServiceUrl = user.links.chat_service

  function resetDomain () {
    setStarted(true)
    setSettings({ ...settings, attributes: { ...settings.attributes, ready_to_use: false } })
  }

  useEffect(() => {
    getEmailSettings()
      .then(response => {
        setSettings(response?.data)
        setLoading(false)
      })
  }, [chatServiceUrl])

  function updateSettings ({ emailPrefix, name }) {
    updateEmailSettings({ chatServiceUrl, name, emailPrefix })
      .then(response => setSettings(response.data))
  }

  function getContent () {
    if (settings?.attributes?.ready_to_use) {
      return (
        <SetupComplete
          settings={settings.attributes}
          chatServiceUrl={chatServiceUrl}
          resetDomain={resetDomain}
        />
      )
    } else if (loading) {
      return <></>
    } else if (settings || started) {
      return (
        <EmailSetup
          setSettings={setSettings}
          settings={settings}
          updateSettings={updateSettings}
        />
      )
    } else {
      return (
        <BeginSetupPage
          onClick={() => setStarted(true)}
        />
      )
    }
  }

  document.title = 'Email Settings | Signals'

  return (
    <AppPage
      title='Email Settings'
    >
      {getContent()}
    </AppPage>
  )
}

export default EmailSettingsPage
