/* eslint-disable @typescript-eslint/naming-convention */
import { makeStyles } from '@material-ui/core/styles'
import Slider, { createSliderWithTooltip } from 'rc-slider'

const TooltipSlider = createSliderWithTooltip(Slider)

const useStyles = makeStyles(theme => ({
  multipleSelect: {
    display: 'flex',
    maxWidth: '100%',
    width: '100%',
    '&.range': {
      padding: 8,
      width: 'calc(100% - 24px)',
      '& .cf-padded-tip': {
        width: 'max-content',
        height: 20,
        position: 'relative',
        '&.cf-padded-tip-hidden': { display: 'none' },
        '& > .cf-padded-tip-content': {
          position: 'relative',
          '& > .cf-padded-tip-inner': {
            background: '#808080',
            color: '#FFFFFF',
            borderRadius: 4,
            padding: 3,
            fontSize: '0.9em'
          }
        }
      }
    },
    '& > div': {
      width: '100%',
      '& > *': { width: '100%' }
    }
  },
  range: {
    '& > div.rc-slider-track': {
      backgroundColor: theme.palette.primary.main
    },
    '& > div.rc-slider-handle': {
      borderColor: theme.palette.primary.main,
      zIndex: 1,
      '&.rc-slider-handle-dragging': {
        borderColor: theme.palette.primary.main,
        boxShadow: `0 0 3px 4px ${theme.palette.primary.main}`
      }
    },
    '& .rc-slider-dot-active': {
      borderColor: theme.palette.primary.main
    },
    '& .rc-slider-mark-text': {
      fontSize: '0.6vw'
    }
  }
}))

export type Importance = 'not_very' | 'somewhat' | 'important' | 'very' | 'extremely'

interface ImportanceSliderProps {
  index: number
  importance: Importance
  onChange: (index: number, value: Importance) => void
}

const importanctTextMap: Record<number, string> = {
  0: 'Not Very Important',
  1: 'Somewhat Important',
  2: 'Important',
  3: 'Very Important',
  4: 'Extremely Important'
}

const importanceNumberMap: Record<Importance, number> = {
  not_very: 0,
  somewhat: 1,
  important: 2,
  very: 3,
  extremely: 4
}

const importanceMap: Record<number, Importance> = {
  0: 'not_very',
  1: 'somewhat',
  2: 'important',
  3: 'very',
  4: 'extremely'
}

const importanceRangeConfig = {
  min: 0,
  max: 4,
  dots: true,
  defaultValue: 2,
  pushable: 1
}

export default function ImportanceSlider ({ index, importance, onChange }: ImportanceSliderProps): JSX.Element {
  const classes = useStyles()

  return (
    <div style={{ width: '300px', display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
      -
      <div className={classes.multipleSelect + ' range'}>
        <div>
          <TooltipSlider
            {...importanceRangeConfig}
            tipProps={{ prefixCls: 'cf-padded-tip' }}
            tipFormatter={(value: number) => importanctTextMap[value]}
            value={importanceNumberMap[importance]}
            className={classes.range}
            onChange={(value: number) => { onChange(index, importanceMap[value]) }}
          />
        </div>
      </div>
      +
    </div>
  )
}
