import { useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Card, CardHeader, CardContent } from '@material-ui/core'
import { createConversionTimeline } from './CreateConversionTimeline'
import EmptyState from 'components/EmptyState'

const useStyles = makeStyles(theme => ({
  conversionRateCard: {
    borderRadius: 12,
    border: '1px solid rgba(0,0,0,0.2)'
  },
  cardContent: {
    height: 450
  }
}))

const colorList = [
  '#975ce6',
  '#fe9d10'
]

function ConversionTimeline (props) {
  const chartRef = useRef(null)
  const classes = useStyles()
  let message = ''

  useEffect(() => {
    if (props.chartData?.length) {
      const id = 'goalchart'
      const data = props.chartData.sort((a, b) => a.name > b.name ? 1 : -1)
      const a = props.chartData[0]
      const b = props.chartData[1]
      const startDate = props.startDate
      const aMax = Math.max(...a.rates.map(s => s.average))
      const bMax = Math.max(...b.rates.map(s => s.average))
      if (data.length > 1 && data[0].rates.length > 3) {
        if ((aMax || bMax) && !chartRef.current) {
          const chart = createConversionTimeline({ data, id, colorList, startDate })
          chartRef.current = chart
        }
      }
    }
    return function cleanup () {
      if (chartRef.current) {
        chartRef.current.dispose()
        chartRef.current = null
      }
    }
    // eslint-disable-next-line
  }, [])

  if (props.chartData.length > 1) {
    const a = props.chartData[0]
    const b = props.chartData[1]

    if (a.rates.length < 4) {
      return <></>
    }
    const aMax = Math.max(...a.rates.map(s => s.average))
    const bMax = Math.max(...b.rates.map(s => s.average))
    if (!aMax && !bMax) {
      message = 'No conversions yet'
    }
  } else {
    message = 'No conversions yet'
  }

  return (
    <Card className={classes.conversionRateCard}>
      <CardHeader
        title='Conversion Rate Over Time'
      />
      <CardContent id='conversion-card-content'>
        {message ? (
          <EmptyState message={message} />
        ) : (
          <div className={classes.cardContent} id='conversion-graph'>
            <div id='goalchart' style={{ width: '100%', height: '100%' }} />
          </div>
        )}
      </CardContent>
    </Card>
  )
}

export default ConversionTimeline
