import Autocomplete from '@material-ui/lab/Autocomplete/Autocomplete';
import { Chip, TextField } from './materialUI';

interface TextFieldWithChipsProps {
  stringArray: string[]
  setStringArray: (value: string[]) => void
  blurValidation?: (value: string) => boolean
}

export default function TextFieldWithChips ({ stringArray, setStringArray, blurValidation }: TextFieldWithChipsProps) {
  return (
    <div>
      <Autocomplete
        multiple
        id='tags-filled'
        options={stringArray}
        freeSolo
        value={stringArray}
        renderTags={(value: string[], getTagProps) =>
          value.map((option: string, index: number) => (
            <Chip
              key={option}
              variant='default'
              label={option}
              {...getTagProps({ index })}
            />
          ))}
        onBlur={(event) => {
          if (event.target.value === '') return
          if (!blurValidation) return
          if (!blurValidation(event.target.value)) return
          setStringArray(stringArray.concat([event.target.value]))
        }}
        onChange={(event, value) => setStringArray(value)}
        renderInput={(params) => (
          <TextField
            {...params}
            variant='outlined'
          />
        )}
      />
    </div>
  );
}
