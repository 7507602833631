import { useState, MutableRefObject } from 'react'
import CustomizedSnackbar from 'components/CustomizedSnackbar'
import DeleteIcon from '@material-ui/icons/DeleteForever'
import SendIcon from '@material-ui/icons/Send'
import { Table } from 'cf-components/table/Table'
import { UserInvite } from 'classes/userInvites'
import { Fields } from 'cf-components/table/CardContents'
import { Actions } from 'cf-components/table/ActionsComponent'
import UserInviteModal from './InviteModal'

interface UserInviteListProps {
  actionRef: MutableRefObject<any>
}

function UserInviteList (props: UserInviteListProps): JSX.Element {
  const { actionRef } = props
  const { data: invites } = UserInvite.loadAll()
  const pendingInvites = invites.list
  const [snackState, setSnackState] = useState({ open: false, variant: 'success', message: 'You don\'t have enough licenses' })
  const [editUserInvite, setUserInviteModalData] = useState(false)
  actionRef.current = () => setUserInviteModalData(true)

  const controlSnack = (response: any, type?: any): void => {
    let variant = snackState.variant
    let message = snackState.message
    if (type === 'cancel') {
      message = 'The invitation has been canceled'
    }
    if (response.ok && type === 'resend') {
      message = 'Your invitation has been resent'
    }
    if (!response.ok && type === 'resend') {
      message = 'Uh oh, the message didn\'t get resent'
      variant = 'error'
    }
    setSnackState({
      open: true,
      variant: variant,
      message: message
    })
  }

  const resendInvite = (row: UserInvite): void => {
    UserInvite.resend(row.id, row.email).then(resp => controlSnack(resp, 'resend'))
  }

  const cancelInvite = (row: UserInvite): void => {
    UserInvite.delete(row.id).then(resp => controlSnack(resp, 'cancel'))
  }

  const actions: Actions = [
    { name: 'Resend Invite', action: (row: UserInvite) => resendInvite(row), icon: SendIcon },
    { name: 'Cancel Invite', action: (row: UserInvite) => cancelInvite(row), icon: DeleteIcon }
  ]

  const fields: Fields<UserInvite> = [
    { name: 'email', type: 'text', label: 'Email' },
    { name: 'invitationDate', type: 'date', label: 'Invitation Date' },
    { name: 'invitedByUser', type: 'userAvatar', label: 'Invited By' }
  ]

  return (
    <>
      <Table
        rows={pendingInvites}
        fields={fields}
        columnStyles='1.5fr 1fr 1fr 50px'
        actions={actions}
      />
      <CustomizedSnackbar
        state={snackState as any}
        handler={setSnackState as any}
      />
      <UserInviteModal
        open={editUserInvite}
        close={() => setUserInviteModalData(false)}
      />
    </>
  )
}

export default UserInviteList
