import AppPage from 'cf-components/AppPage'
import { Table, Fields } from 'cf-components/table/Table'
import { ChargifyOffer } from 'classes/chargifyOffer'
import { useMemo, useState } from 'react'
import { ChargifyOfferModal } from './ChargifyOfferModal'

export function ChargifyOfferList () {
  const { data: offers, isLoading } = ChargifyOffer.loadAll()
  const [editOffer, setEditOffer] = useState<ChargifyOffer | null>(null)
  const displayOffers = useMemo(() => {
    return offers.list.map(offer => {
      offer.name = `${offer.name} (${offer.billingCycle})`
      return offer
    })
  }, [offers])

  const fields: Fields<ChargifyOffer> = [
    { name: 'name', type: 'avatar', onClick: row => setEditOffer(row) }
  ]

  return (
    <AppPage
      title='Available Offers'
      action={() => setEditOffer(new ChargifyOffer())}
      actionText='Add Offer'
      padding={20}
    >
      <div>
        <Table
          rows={displayOffers}
          fields={fields}
          columnStyles='1fr'
          isLoading={isLoading}
        />
        {editOffer &&
          <ChargifyOfferModal
            offer={editOffer}
            onHide={() => setEditOffer(null)}
          />}
      </div>
    </AppPage>
  )
}
