import { doDelete, doGet, doPost, doPut } from './api'

export interface SLPersonResponseAttributes {
  first_name: string
  last_name: string
  email_address: string
  phone: string
  not_found: string
}

export interface SLPersonResponse<IsPartial extends boolean = false> {
  id: IsPartial extends false ? string : (string | undefined)
  attributes: IsPartial extends false ? SLPersonResponseAttributes : Partial<SLPersonResponseAttributes>
}

export const getSalesloftPerson = (participantID: number): Promise<{ data: SLPersonResponse }> => {
  return doGet({
    path: `/cadence/person/${participantID}`,
    useChatURL: true
  })
}

export const createPerson = (contact: any, integration: string) => {
  return doPost({ data: { attributes: contact }, path: '/api/integrations/crm/contacts', extraHeaders: { 'x-integration': integration } })
}

export const getActions = (personID: string, integration: string) => {
  return doGet({
    path: `/api/integrations/crm/actions?person_id=${personID}&next_page_token=0`,
    extraHeaders: { 'x-integration': integration }
  })
}

export const getCadenceMemberships = (personID: string, integration: string) => {
  return doGet({
    path: `/api/integrations/crm/cadence_membership?person_id=${personID}&next_page_token=0`,
    extraHeaders: { 'x-integration': integration }
  })
}

export const addContactToCadence = (personID: string, integration: string, cadenceID: string) => {
  return doPut({
    path: `/api/integrations/crm/lists/${cadenceID}/contacts/${personID}`,
    extraHeaders: { 'x-integration': integration }
  })
}

export const removeContactFromCadence = (personID: string, integration: string, cadenceID: string) => {
  return doDelete({
    path: `/api/integrations/crm/lists/${cadenceID}/contacts/${personID}`,
    extraHeaders: { 'x-integration': integration }
  })
}
