import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { ListItemCard, ListItemStackedData } from 'cf-components/CardList'
import { AvatarWithInfo, getInitials, getColor } from 'cf-components/Avatars'
import dateFormat from 'dateformat'
import Table from 'cf-components/Table'
import Link from 'cf-components/Link'

const useStyles = makeStyles(theme => ({
  botCard: {
    display: 'grid',
    padding: 15,
    cursor: 'pointer',
    gridTemplateColumns: '2fr 1fr 2fr',
    alignItems: 'center',
    gridGap: 10
  },
  headerClass: {
    display: 'grid',
    padding: '5px 15px',
    gridTemplateColumns: '2fr 1fr 2fr',
    alignItems: 'center',
    gridGap: 10
  },
  status: {
    border: '1px solid #888',
    display: 'inline-block',
    padding: '5px 10px',
    borderRadius: '3',
    fontWeight: 600,
    width: 60
  }
}))

function SequenceBotList (props) {
  const classes = useStyles()

  const rows = props.bots && props.botStats ? props.bots.map((row, index) => {
    const name = row.attributes.name
    const initials = getInitials(name, 'B')
    const botStats = props.botStats
    let lastFired = 'Never Fired'
    let totalFires = 0
    let weeklyFires = 0
    if (row.id in botStats) {
      lastFired = dateFormat(botStats[row.id].last_fired, 'mmm dd, yyyy')
      totalFires = botStats[row.id].total_fires
      weeklyFires = botStats[row.id].fires_this_week
    }
    let status = 'Disabled'
    if (row.attributes.deleted_timestamp) {
      status = 'Archived'
    } else if (row.attributes.enabled) {
      status = 'Enabled'
    }
    let type = 'Flow Bot'
    if (row.attributes.kind === 'content_page') {
      type = 'Content Page Bot'
    }
    return (
      {
        name: name,
        initials: initials,
        searchField: name,
        totalFires: totalFires,
        weeklyFires: weeklyFires,
        lastFired: lastFired,
        type: type,
        status: status,
        id: row.id
      })
  }) : null

  function SequenceBotCard (props) {
    const row = props.row
    const color = getColor(props.index)

    const botStats = [
      { title: 'LAST FIRED', value: row.lastFired },
      { title: 'THIS WEEK', value: row.weeklyFires },
      { title: 'ALL TIME', value: row.totalFires }
    ]

    let statusColor = '#888'
    if (row.status === 'Enabled') {
      statusColor = 'rgba(0, 135, 7, 0.7)'
    } else if (row.status === 'Archived') {
      statusColor = 'rgb(240, 112, 111)'
    }

    return (
      <ListItemCard>
        <Link to={`/bots/${row.id}`} target='_blank'>
          <div className={classes.botCard}>
            <div>
              <AvatarWithInfo
                title={row.name}
                initials={row.initials}
                subtitle={row.type}
                avatarColor={color}
                variant='rounded'
              />
            </div>
            <div>
              <div className={classes.status} style={{ color: statusColor, borderColor: statusColor }}>
                {row.status}
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: 20 }}>
              <ListItemStackedData
                items={botStats}
              />
            </div>
          </div>
        </Link>
      </ListItemCard>
    )
  }

  const sortValues = [
    { label: 'Name', id: 'name', header: true },
    { label: 'Status', id: 'status', header: true },
    { label: 'Total Fires', id: 'totalFires', header: false },
    { label: 'Weekly Fires', id: 'weeklyFires', header: false },
    { label: 'Last Fired', id: 'lastFired', header: false }
  ]

  return (
    <Table
      rows={rows}
      card={SequenceBotCard}
      sortValues={sortValues}
      startingSortColumn='totalFires'
      sortAscending={false}
      header
      headerClass={classes.headerClass}
    />
  )
}

export default SequenceBotList
