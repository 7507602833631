import { doDelete, doGet, doPatch, doPost } from './api'

function getCustomFields (forObject) {
  const path = '/custom_fields?for_object=' + forObject
  return doGet({ path, useChatURL: true })
}

function createCustomField ({ values, forObject }) {
  return doPost({
    path: '/custom_fields',
    data: {
      type: 'custom_fields',
      attributes: {
        label: values.label,
        kind: values.type,
        description: values.description,
        for_object: forObject,
        options: values.options,
        edit: values.edit
      }
    },
    useChatURL: true
  })
}

function saveCustomField ({ values }) {
  const path = `/custom_fields/${values.slug}`
  const data = {
    type: 'custom_fields',
    attributes: {
      label: values.label,
      kind: values.type,
      description: values.description,
      options: values.options,
      edit: values.edit
    }
  }
  return doPatch({ path, data, useChatURL: true })
}

function deleteFields ({ fieldIds }) {
  const path = '/custom_fields'
  const body = {
    attributes: {
      field_ids: fieldIds
    }
  }
  return doDelete({ path, data: body, useChatURL: true })
}

export { getCustomFields, createCustomField, deleteFields, saveCustomField }
