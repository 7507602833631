import { useState, useEffect } from 'react'
import FlowBuilder from 'pages/bot/flow-builder/surface/FlowBuilder.jsx'
import BotEditTab from './BotEditTab'
import { getFlow, updateBot, saveBotDetails } from 'api/bots'
import { getUsers } from 'api/users'
import { getTabs } from './botConfig'
import AppPage from 'cf-components/AppPage'
import ContentPageManagement from 'pages/content_pages/ContentPageManagement'
import BotDetailsPage from './BotDetailsPage'

function ConversationalContentPage (props) {
  const [users, setUsers] = useState([])
  const values = props.values
  const setFieldValue = props.setFieldValue
  const flow = props.flow
  const flowID = props.flowID
  const bot = props.bot
  const chatServiceUrl = props.chatServiceUrl
  const botID = props.botID
  const botType = props.botType
  const disabled = props.disabled
  const submit = props.submit
  const publishBot = props.publishBot
  const enabled = bot.attributes.enabled
  const activeTest = bot.attributes?.active_test_id

  function saveDescription (description) {
    if (botID) {
      saveBotDetails({ chatServiceUrl, description, botID })
        .then(response => {
          props.setBot(response.data)
        })
    } else {
      props.setBot(prevState => ({
        ...prevState,
        attributes: {
          ...prevState.attributes,
          description: description
        }
      }))
    }
  }

  function restoreFlow (flowID) {
    getFlow({ flowID }).then(response => {
      props.setFlow(response.data)
      props.setFlowID(response.data.id)
      const attributes = {
        flow_id: response.data.id
      }
      updateBot({ botID, chatServiceUrl, attributes })
      setTimeout(() => {
        props.setCanSave(false)
      }, 50)
    })
  }

  useEffect(() => {
    getUsers().then(response => {
      setUsers(response.data)
    })
  }, [])

  const tabs = getTabs(botID, botType)

  return (
    <AppPage
      title={values.name}
      saveName={(name) => setFieldValue('name', name)}
      breadcrumbs={props.breadcrumbs}
      editableTitle={Boolean(bot)}
      tabs={tabs}
    >
      <FlowBuilder
        values={values}
        setFieldValue={setFieldValue}
        dataRef={props.flowBuilderDataRef}
        flow={flow}
        save={submit}
        publishBot={publishBot}
        enabled={enabled}
        disabled={disabled}
        setCanSave={props.setCanSave}
        botID={botID}
        flowID={flowID}
        chatServiceUrl={chatServiceUrl}
        users={users}
        restoreFlow={restoreFlow}
        activeTest={activeTest}
        archived={props.archived}
      />
      <BotEditTab
        save={submit}
        publishBot={publishBot}
        values={values}
        disabled={disabled}
        enabled={enabled}
      >
        <ContentPageManagement
          values={values}
          setFieldValue={setFieldValue}
          subDomain={props.subdomain}
          bot={bot}
          disabled={disabled}
          flow={flow}
          botParticipant={props.botParticipant}
          flowBuilderDataRef={props.flowBuilderDataRef}
          setFlowID={props.setFlowID}
          setBot={props.setBot}
          canSave={props.canSave}
        />
      </BotEditTab>
      <BotDetailsPage
        bot={bot}
        chatServiceUrl={chatServiceUrl}
        saveDescription={saveDescription}
      />
    </AppPage>
  )
}

export default ConversationalContentPage
