import { makeStyles } from '@material-ui/core/styles'
import { useContext } from 'react'
import { NPSActionButton, NPSHeader } from './NPSComponents'
import { NPSPromptContext } from './NPSPromptContext'

const useStyles = makeStyles(theme => ({
  scores: {
    display: 'grid',
    gridTemplateColumns: 'repeat(11, 1fr)',
    justifyContent: 'space-around',
    padding: '0px 15px'
  },
  scoreButton: {
    cursor: 'pointer',
    borderRadius: 8,
    height: 38,
    width: 38,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#F6F2F8',
    border: '1px solid #9933FF',
    justifyContent: 'center',
    margin: 'auto',
    font: 'normal normal 600 14px/22px Poppins'
  },
  prompt: {
    font: 'normal normal 600 15px/22px Poppins',
    marginBottom: 20,
    textAlign: 'center'
  },
  options: {
    font: 'normal normal normal 13px/21px Poppins',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '12px 20px 0px 20px'
  }
}))

// colors aren't currently used, but at some point we may make it
// optional to have colors for the scores
export const npsScoreColors = [
  'rgba(234,66,42,1)',
  'rgba(234,66,42,0.8)',
  'rgba(234,66,42,0.6)',
  'rgba(234,66,42,0.5)',
  'rgba(234,66,42,0.4)',
  'rgba(234,66,42,0.3)',
  'rgba(234,66,42,0.2)',
  'rgba(244,212,81,0.3)',
  'rgba(244,212,81,0.5)',
  'rgba(129,175,32,0.7)',
  'rgba(129,175,32,1)'
]

type ScoreButtonProps = {
  value: number
  handleClick: () => void
}
function ScoreButton (props: ScoreButtonProps) {
  const classes = useStyles()

  return (
    <div
      className={classes.scoreButton}
      onClick={props.handleClick}
    >
      {props.value}
    </div>
  )
}

export function NPSScore () {
  const classes = useStyles()
  const { settings, setScore, closePrompt } = useContext(NPSPromptContext)

  return (
    <div>
      <NPSHeader
        step={1}
        firstSlot={<div />}
        secondSlot={<NPSActionButton text='Ask me later' onClick={closePrompt} />}
      />
      <div className={classes.prompt}>
        {settings.content.question}
      </div>
      <div className={classes.scores}>
        {npsScoreColors.map((_, index) => (
          <ScoreButton
            value={index}
            key={index}
            handleClick={() => setScore(index)}
          />
        ))}
      </div>
      <div className={classes.options}>
        <div>
          0 - {settings.content.lowRatingText}
        </div>
        <div>
          10 - {settings.content.highRatingText}
        </div>
      </div>
    </div>
  )
}
