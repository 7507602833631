import React, { useState, useEffect, useContext, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import { SessionContext } from 'session-context'
import SelectBot from './test_steps/SelectBot'
import SelectSuccessMetric from './test_steps/SelectSuccessMetric'
import SelectDuration from './test_steps/SelectDuration'
import SelectSchedule from './test_steps/SelectSchedule'
import SelectAutoPromotion from './test_steps/SelectAutoPromotion'
import SelectFlow from './test_steps/SelectFlow'
import TestSummary from './test_steps/TestSummary'
import { updateBotTest, getBotTest, getBotTests } from 'api/bot_tests'
import { getBots } from 'api/bots'
import TestPageHeader from './TestPageHeader'
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined'
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined'
import SaveIcon from '@material-ui/icons/Save'
import { Redirect } from 'react-router-dom'
import EnterTestDescription from './test_steps/EnterTestDescription'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%'
  },
  container: {
    width: '100%',
    height: 'calc(100% - 200px)'
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}))

const steps = [
  'Select a bot',
  'Edit the bot',
  'Select success criteria',
  'Select duration',
  'Set schedule',
  'Promotion',
  'A brief description',
  'Summary'
]

const now = new Date(Date.now())
const future = new Date(Date.now())

future.setDate(future.getDate() + 7)
now.setHours(now.getHours() + Math.round(now.getMinutes() / 60))
future.setHours(future.getHours() + Math.round(future.getMinutes() / 60))
now.setMinutes(0, 0, 0)
future.setMinutes(0, 0, 0)

const initialValues = {
  attributes: {
    name: '',
    status: '',
    test_type: '',
    duration_criteria: '',
    success_metric: '',
    auto_promote: true,
    test_category: 'flow',
    start_time: now,
    end_time: future,
    object_id: null,
    success_metric_tag_id: null,
    old_flow_id: 0,
    new_flow: null,
    object_type: '',
    description: '',
    labels: ['Bot A', 'Bot B']
  }
}

const promptSizes = [
  { value: 'mini', name: 'Mini Prompt' },
  { value: 'open', name: 'Full Chat' },
  { value: 'big', name: 'Large Pop-Up' }
]

const requiredStepDict = {
  0: 'object_id',
  2: 'success_metric',
  3: 'duration_criteria',
  6: 'labels'
}

const notRequiredSteps = [1, 4, 5, 7]

const successMetrics = [
  { value: 'first_visitor_interaction', name: 'Conversations' },
  { value: 'email_captured', name: 'Emails Captured' },
  { value: 'meeting_booked', name: 'Meetings Booked' }
]

const durationCriteria = [
  { value: 'time_based', name: 'Time-based', tooltip: 'Schedule a starting and ending time for the test to run' },
  { value: 'statistical_significance', name: 'Automatic', tooltip: 'Signals will automatically select the best bot after a significant statistical difference is detected' }
]

const BotTestEdit = (props) => {
  const classes = useStyles()
  const [activeStep, setActiveStep] = useState(null)
  const [botTestState, setBotTestState] = useState(initialValues)
  const [bots, setBots] = useState(null)
  const [startTimeError, setStartTimeError] = useState()
  const [endTimeError, setEndTimeError] = useState()
  const [redirect, setRedirect] = useState()
  const [disabled, setDisabled] = useState(false)
  const { user } = useContext(SessionContext)
  const chatServiceUrl = user.links.chat_service
  const flowRef = useRef(null)
  const formRef = useRef(null)
  const saveRef = useRef(null)

  const handleChange = ({ attr, value }) => {
    setBotTestState({
      ...botTestState,
      attributes: {
        ...botTestState.attributes,
        [attr]: value
      }
    })
  }

  const save = () => {
    updateBotTest({ state: botTestState })
    setRedirect('/bot_tests')
  }

  const flowSave = () => {
    handleChange({ attr: 'new_flow', value: flowRef.current })
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return <SelectBot setBotTestState={setBotTestState} state={botTestState} chatServiceUrl={chatServiceUrl} bots={bots} handleNext={handleNext} />
      case 1:
        return <SelectFlow state={botTestState} chatServiceUrl={chatServiceUrl} saveRef={saveRef} save={flowSave} flowRef={flowRef} handleChange={handleChange} formRef={formRef} promptSizes={promptSizes} />
      case 2:
        return <SelectSuccessMetric handleChange={handleChange} state={botTestState} successMetrics={successMetrics} handleNext={handleNext} flowRef={flowRef} />
      case 3:
        return <SelectDuration handleChange={handleChange} state={botTestState} durationCriteria={durationCriteria} handleNext={handleNext} />
      case 4:
        return (
          <SelectSchedule
            handleChange={handleChange}
            state={botTestState}
            handleNext={handleNext}
            startTimeError={startTimeError}
            setStartTimeError={setStartTimeError}
            endTimeError={endTimeError}
            setEndTimeError={setEndTimeError}
          />
        )
      case 5:
        return <SelectAutoPromotion handleChange={handleChange} state={botTestState} handleNext={handleNext} />
      case 6:
        return <EnterTestDescription state={botTestState} handleChange={handleChange} handleNext={handleNext} />
      case 7:
        return (
          <TestSummary
            handleChange={handleChange}
            state={botTestState} bots={bots}
            successMetrics={successMetrics}
            durationCriteria={durationCriteria}
            jumpToStep={jumpToStep}
            handleSave={save}
          />
        )
      default:
        return 'Unknown stepIndex'
    }
  }

  const jumpToStep = (step) => {
    setActiveStep(step)
  }

  const handleNext = () => {
    if (activeStep === 1) {
      if (botTestState.attributes.test_category === 'audience') {
        formRef.current()
          .then(() => {
            setActiveStep((prevActiveStep) => prevActiveStep + 1)
          })
      }
      if (botTestState.attributes.test_category === 'flow') {
        saveRef.current()
      }
      if (botTestState.attributes.test_category === 'appearance') {
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleReset = () => {
    setActiveStep(0)
  }

  const parseURL = url => {
    const id = url.split('/')[2].split('?')[0]
    const hashes = url.slice(url.indexOf('?') + 1).split('&')
    const params = {}
    hashes.map(hash => {
      const [key, val] = hash.split('=')
      params[key] = decodeURIComponent(val)
      return 1
    })

    if (id) {
      params.id = id
    }
    return params
  }

  useEffect(() => {
    getBotTests({ chatServiceUrl })
      .then(response => {
        const botTests = response.data
        const botIdsUsedInTests = botTests.filter(bt => bt.attributes.status === 'active').map(active_bt => active_bt.attributes.object_id)
        getBots()
          .then(response => {
            let activeBots = response.data.filter(bot => bot.attributes.deleted_timestamp === null)
            activeBots = activeBots.filter(ab => !botIdsUsedInTests.includes(ab.id))
            setBots(activeBots)
          })
      })
  }, [chatServiceUrl])

  useEffect(() => {
    if (bots) {
      const queryParams = parseURL(window.location.hash)
      if (queryParams.id && !isNaN(parseInt(queryParams.id))) {
        getBotTest({ chatServiceUrl, testID: queryParams.id })
          .then(response => {
            setBotTestState(response.data)
            setActiveStep(0)
          })
      } else if (bots.length > 0 && queryParams.bot_id && !isNaN(parseInt(queryParams.bot_id))) {
        setActiveStep(1)
        const botSelected = bots.filter(b => b.id === parseInt(queryParams.bot_id))[0]
        const labelName = botSelected.attributes.name
        const old_flow_id = botSelected.attributes.flow_id
        initialValues.attributes.name = labelName + ' Test'
        initialValues.attributes.test_type = 'ab'
        initialValues.attributes.object_type = 'bot'
        initialValues.attributes.object_id = botSelected.id
        initialValues.attributes.old_flow_id = old_flow_id
        initialValues.attributes.labels = [labelName + ' - A', labelName + ' - B']
        initialValues.attributes.new_flow = null
        setBotTestState(initialValues)
      } else {
        initialValues.attributes.name = queryParams.name === '' ? 'New Test' : queryParams.name
        initialValues.attributes.test_type = queryParams.type
        setBotTestState(initialValues)
        setActiveStep(0)
      }
    }
  }, [bots, chatServiceUrl])

  useEffect(() => {
    const isDisabled = () => {
      if (!botTestState.attributes[requiredStepDict[activeStep]] && !notRequiredSteps.includes(activeStep)) {
        setDisabled(true)
      } else if ((startTimeError || endTimeError) && activeStep === 4) {
        setDisabled(true)
      } else {
        setDisabled(false)
      }
    }

    if (botTestState) {
      isDisabled()
    }
  }, [activeStep, botTestState, endTimeError, startTimeError])

  if (redirect) {
    return <Redirect push to={redirect} />
  }

  return (
    <div className={classes.root}>
      <TestPageHeader
        title={botTestState.attributes.name}
        actionText='Next'
        action={() => handleNext()}
        backActionText='Back'
        backAction={() => handleBack()}
        icon={<ArrowForwardOutlinedIcon />}
        backIcon={<ArrowBackOutlinedIcon />}
        finishIcon={<SaveIcon />}
        activeStep={activeStep}
        steps={steps}
        handleChange={handleChange}
        save={save}
        disabled={disabled}
      />
      <Divider />
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label, index) => (
          <Step
            key={label}
          >
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Divider />
      <div className={classes.container}>
        {activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}>All steps completed</Typography>
            <Button onClick={handleReset}>Reset</Button>
          </div>
        ) : (Boolean(activeStep != null) &&
          <div style={{ height: '100%', width: '100%' }}>
            {getStepContent(activeStep)}
          </div>
        )}
      </div>
    </div>
  )
}

export default BotTestEdit
