import { createTheme, responsiveFontSizes } from '@material-ui/core/styles'

let theme = createTheme({
  palette: {
    primary: {
      main: '#343434',
      alt: '#9933FF',
      light: '#AA88E2',
      contrastText: '#FFFFFF'
    },
    secondary: {
      main: '#FFFFFF'
    },
    text: {
      primary: '#2F2F2F',
      white: '#FFFFFF'
    },
    warning: {
      main: '#f4d451',
      light: '#fef9e5'
    },
    success: {
      main: '#81AF20'
    },
    error: {
      main: '#EA422A',
      light: '#E9412A'
    },
    background: {
      extraLightGrey: '#f7f7f7',
      lightGrey: '#F0F0F0',
      mediumGrey: '#8f8f8f',
      lightPurple: '#F6F2F8',
      warning: '#F3D45033'
    },
    calendarDrop: {
      notBooked: '#7F7F7F',
      booked: '#81AF20'
    }
  },
  typography: {
    fontFamily: 'Poppins',
    custom: {
      color: 'red'
    },
    h1: {
      fontSize: '1.3em',
      color: 'rgba(0,0,0,0.75)'
    },
    h2: {
      fontSize: '1em',
      color: 'rgb(0,0,0)'
    },
    h3: {
      fontSize: '1em',
      color: 'rgba(0,0,0,0.9)',
      textTransform: 'uppercase'
    },
    h4: {
      fontSize: '.9em',
      color: 'rgba(0,0,0,0.5)',
      textTransform: 'uppercase'
    },
    h5: {
      color: 'rgba(0,0,0,0.65)',
      fontWeight: 600,
      fontSize: '0.9em'
    },
    h6: {
      fontWeight: 600,
      fontSize: '1em'
    },
    subtitle1: {
      color: 'rgba(0,0,0,0.5)',
      fontSize: '.8em'
    },
    body1: {
      color: 'rgba(0,0,0,0.75)'
    },
    body2: {
      color: '#7f7f7f'
    }
  }
})

theme = responsiveFontSizes(theme)

export default theme
