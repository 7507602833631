import React, { useState, useEffect } from 'react'
import { Select, MenuItem, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { getCustomObjects, getCustomObjectFields } from 'api/eloqua'
import IntegrationMapping from '../IntegrationMapping'
import CustomEventSettingsSelector from './CustomEventSettingsSelector'

const useStyles = makeStyles((theme) => ({
  objectSelect: {
    width: 250,
    height: 40,
    marginBottom: 25
  },
  bodyText: {
    marginBottom: 25,
    fontSize: 16
  },
  subtitle1: {
    fontSize: 18,
    fontWeight: 600,
    marginBottom: 15
  }
}))

const eventSettings = [
  { key: 'conversations', name: 'Conversations' },
  { key: 'meetings_booked', name: 'Meetings Booked' }
]

const EloquaCustomObjectMapper = props => {
  const state = props.state
  const objectMappings = state?.attributes?.mappings?.custom_object_mappings
  const customObject = objectMappings.find(object => object.id === props.id) || { mapping: [] }
  const currentMapping = customObject.mapping || []
  // This is the custom object type id

  const [customObjectId, setCustomObjectId] = useState(customObject?.id || null)
  const [customObjectTypes, setCustomObjectTypes] = useState([])
  const [externalObjectMapping, setExternalObjectMapping] = useState()

  const handleMappingEdit = (mapping) => {
    const newCustomObjectState = { ...customObject, mapping: mapping }
    let completeState = [...objectMappings]
    completeState = [newCustomObjectState]
    props.handleEdit(completeState)
  }

  const handleEventMappingEdit = (mappingObject) => {
    const newCustomObjectState = { ...customObject, event_mapping: mappingObject }
    let completeState = [...objectMappings]
    completeState = [newCustomObjectState]
    props.handleEdit(completeState)
  }

  const handleObjectEdit = (customObjectId) => {
    setCustomObjectId(customObjectId)
    if (customObjectId !== customObject.id) {
      props.handleDelete(customObject.id)
      props.handleAdd([{ id: customObjectId, type: 'contact', mapping: [] }])
      props.handleConfig(customObjectId)
    }
  }

  useEffect(() => {
    getCustomObjects()
      .then(response => {
        setCustomObjectTypes(response.data)
      })
  }, [])

  useEffect(() => {
    // Fetch fields from API through datafetch
    if (customObjectId) {
      getCustomObjectFields(customObjectId).then(response => {
        if (response.data) {
          setExternalObjectMapping({ attributes: { lead: response.data.attributes } })
        } else {
          console.log('Error')
        }
      })

      console.log('Getting custom object fields for id', customObjectId)
    }
  }, [customObjectId])

  const classes = useStyles()
  return (
    <div>
      <Select
        name='event_select'
        variant='outlined'
        margin='dense'
        value={customObjectId}
        defaultValue='preview'
        className={classes.objectSelect}
        onChange={(e) =>
          handleObjectEdit(e.target.value)}
      >
        <MenuItem disabled style={{ opacity: 1 }}>
          <div className={classes.menuHeader}>Eloqua Custom Object Type</div>
        </MenuItem>
        {customObjectTypes.map((row, index) => (
          <MenuItem key={index} value={row.id}>
            {row.attributes?.name}
          </MenuItem>
        ))}
      </Select>
      {customObjectId && (
        <>
          <IntegrationMapping
            currentMapping={currentMapping}
            integrationSettings={state}
            forObject='people'
            externalMappingFields={externalObjectMapping}
            integrationName='Eloqua'
            handleEdit={(data) => {
              handleMappingEdit(data)
            }}
          />
          <br />
          <br />
          {props.disableEvents ? <></> : (
            <div className={classes.groupContainer}>
              <Typography variant='subtitle1' className={classes.subtitle1}>
                Custom Object Event Settings
              </Typography>
              <Typography variant='body2' className={classes.bodyText} style={{ marginBottom: 5 }}>
                Sync the following Signals events. Select which contact fields in Eloqua you want to map the following events to.
              </Typography>
              <Typography variant='body2' className={classes.bodyText}>
                <b>Important: </b>The field in Eloqua must be of type 'Large Text box' in order for the events to sync correctly.
              </Typography>
              {eventSettings.map((eventSetting, index) => (
                <div key={index}>
                  <CustomEventSettingsSelector
                    handleEdit={(mappingObject) => {
                      handleEventMappingEdit(mappingObject) // Single object that will go in the event_mapping array
                    }}
                    externalMapping={externalObjectMapping?.attributes?.lead?.fields || []}
                    state={state}
                    eventSetting={eventSetting}
                  />
                </div>
              ))}
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default EloquaCustomObjectMapper
