const headers = {
  'Content-Type': 'application/vnd.api+json'
}

const isAuthenticated = ({ integration }) => {
  const method = 'POST'

  return fetch('/api/marketo/auth', {
    method: method,
    headers: headers,
    body: JSON.stringify({ data: integration })
  }).then((response) => response.json())
}

const updateMarketo = ({ integration }) => {
  let method = 'POST'

  if (integration.id) {
    method = 'PATCH'
  }

  return fetch('/api/integrations/marketo', {
    method: method,
    headers: headers,
    body: JSON.stringify({ data: integration })
  }).then((response) => response.json())
}

const getMarketo = () => {
  return fetch('/api/integrations/marketo/old', {
    method: 'GET',
    headers: headers
  }).then((response) => response.json())
}

const getMarketoFields = () => {
  return fetch('/api/marketo/lead_description', {
    method: 'GET',
    headers: headers
  }).then((response) => response.json())
}

export { getMarketo, updateMarketo, getMarketoFields, isAuthenticated }
