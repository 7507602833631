import { jsPlumbUtil, Node } from 'jsplumbtoolkit'

type OutputNode = {
  id: string
  type: 'sequenceExit'
  nextOutputID: number
  ports: {id: string, label: string}[]
  left: number
  top: number
  kind: string
}

type NodeDict = Record<string, string>
type PortsArray = {
  id: string
  target: string
  label: string
}[]

type EdgeArray = {
  source: string
  target: string
  data: Record<string, any>
}[]

export type SequenceContext = {
  firstNode: Node
  coordinates: {
    top: number
    left: number
  }
  nodeArray: Partial<Node>[]
  outgoingPorts: PortsArray
  incomingPortIDs: string[]
  edgeArray: EdgeArray
  sequenceID?: number
  name?: string
  description?: string
}

export const handleSequence = (nodes: Node[]) => {
  const nodeIDs = nodes.map(n => n.id)
  const nodeDict: Record<string, string> = {}

  for (const n of nodes) {
    nodeDict[n.id] = jsPlumbUtil.uuid()
  }

  const context = transformNodes(nodes, nodeIDs, nodeDict)
  if (!context) {
    return null
  }

  const { firstNode, coordinates, nodeArray, outgoingPorts, edgeArray } = context

  const outputNode: OutputNode = {
    id: 'exit',
    type: 'sequenceExit',
    nextOutputID: outgoingPorts.length + 1,
    ports: [],
    left: coordinates.left + 400,
    top: coordinates.top + 150,
    kind: 'SequenceExit'
  }

  for (const outputPort of outgoingPorts) {
    outputNode.ports.push({
      id: outputPort.id,
      label: outputPort.label
    })
  }
  nodeArray.push(outputNode)
  const left = firstNode.data.left
  const top = firstNode.data.top

  const startNode = {
    id: 'start',
    top: top - 160,
    left: left,
    type: 'basic',
    kind: 'Start',
    ports: [
      { id: 'default', label: 'Start Here' }
    ]
  }

  nodeArray.push(startNode)

  const edgeID = jsPlumbUtil.uuid()
  const startData = {
    id: edgeID,
    type: 'common'
  }

  const startEdge = {
    source: 'start.default',
    target: nodeDict[firstNode.id],
    data: startData
  }

  edgeArray.push(startEdge)

  return (context)
}

type TransformFunction = (nodes: Node[], nodeIDs: string[], nodeDict: NodeDict) => SequenceContext | null

const transformNodes: TransformFunction = (nodes, nodeIDs, nodeDict) => {
  const nodeArray = []
  let edgeArray: EdgeArray = []

  const coordinates = {
    left: 0,
    top: 0
  }

  let firstNode: Node | null = null
  const incomingPortIDs = []
  const outgoingPorts = []
  let exit = 0

  for (const node of nodes) {
    nodeArray.push({ ...node.data, id: nodeDict[node.id] })
    const left = node.data.left
    const top = node.data.top
    if (!coordinates.left || !coordinates.top) {
      coordinates.left = left
      coordinates.top = top
    } else {
      if (left > coordinates.left) { coordinates.left = left }
      if (top > coordinates.top) { coordinates.top = top }
    }
    const incomingEdges = node.getTargetEdges()
    if (!incomingEdges.length) {
      if (firstNode && firstNode !== node) {
        return null
      } else {
        firstNode = node
      }
    }
    for (const edge of incomingEdges) {
      const parentNode = (edge.source as any).getNode().id
      if (!nodeIDs.includes(parentNode)) {
        if (firstNode && firstNode !== node) {
          return null
        } else {
          firstNode = node
          incomingPortIDs.push(parentNode + '.' + edge.source.id)
        }
      }
    }

    const ports = node.getPorts()

    for (const p of ports) {
      const edges = (p as any).getAllEdges()
      const formattedEdges = []
      for (const e of edges) {
        const source = nodeDict[node.id] + '.' + p.id
        const target = nodeDict[e.target.id]
        const originalTarget = e.target.id
        const id = jsPlumbUtil.uuid()
        const data = {
          id: id,
          type: 'common'
        }
        if (nodeIDs.includes(originalTarget)) {
          formattedEdges.push({ source, target, data })
        } else {
          exit += 1
          const label: string = p.data.label ? p.data.label : 'Exit' + exit
          outgoingPorts.push({
            id: 'exit' + exit,
            target: originalTarget,
            label
          })
          formattedEdges.push({
            source,
            target: 'exit.exit' + exit,
            data
          })
        }
      }
      edgeArray = edgeArray.concat(formattedEdges)
    }
  }

  if (!firstNode) { return null }

  return {
    firstNode,
    coordinates,
    nodeArray,
    outgoingPorts,
    edgeArray,
    incomingPortIDs
  }
}
