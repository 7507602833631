import { useState, useEffect } from 'react'
import { jsPlumbUtil } from 'jsplumbtoolkit'
import { ThemeProvider, makeStyles } from '@material-ui/core/styles'
import OptionPort from '../OptionPort'
import useNodeStyles from './NodeStyles'
import RenderEmoji from '../../../../pages/chat/RenderEmoji'
import Divider from '@material-ui/core/Divider'
import EmailIcon from '@material-ui/icons/Email'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import PropTypes from 'prop-types'
import 'jsplumb'
import '../quill.css'
import { RichTextEditor } from 'library/richText/RichTextEditor'
import { Icon, Checkbox } from 'library/materialUI'
import { EVENT_TYPE, Emitter } from 'emitter'
import { Modal, ModalSection } from 'library/Modal'
import theme from 'theme'
import { Select } from 'cf-components/material-wrappers/MaterialComponents'

const useStyles = makeStyles(theme => ({
  checkbox: {
    padding: 4,
    marginRight: 5
  },
  emailDiv: {
    fontFamily: 'Poppins, sans serif',
    fontSize: '13px',
    color: '#4C4C4C',
    display: 'flex'
  },
  advancedDiv: {
    fontFamily: 'Poppins, sans serif',
    fontSize: '13px',
    color: '#4C4C4C'
  },
  label: {
    fontSize: '13px'
  },
  contentClass: {
    padding: 0,
    borderTop: 'none',
    height: 360,
    overflowY: 'hidden'
  },
  titleClass: {
    paddingBottom: 0
  },
  paper: {
    marginTop: -5
  },
  input: {
    padding: 8,
    paddingLeft: 10,
    borderRadius: 30
  },
  inputRoot: {
    borderRadius: 30
  },
  select: {
    width: '100%',
    fontFamily: 'Poppins, sans serif',
    fontSize: '14px',
    color: '#5C5C5C'
  },
  questionText: {
    textAlign: 'left',
    font: 'normal normal normal 11px/15px Poppins',
    letterSpacing: 0,
    color: '#989898',
    opacity: 1,
    marginTop: -10
  },
  linkText: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: '12px',
    fontWeight: 'bold',
    marginLeft: 'auto'
  }
}))

function TabPanel (props) {
  const { children, value, index } = props

  return (
    <div
      hidden={value !== index}
    >
      {children}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

function EmailCaptureModal (props) {
  const classes = useStyles()
  const [tab, setTab] = useState(0)
  const [text, setText] = useState(props.body)
  const [invalidResponse, setInvalidResponse] = useState(props.invalidResponse)
  const [unwantedResponse, setUnwantedResponse] = useState(props.unwantedResponse)
  const [secondResponse, setSecondResponse] = useState(props.secondResponse)
  const [captureTimes, setCaptureTimes] = useState(props.captureTimes)
  const [unwanted, setUnwanted] = useState(props.unwanted)

  useEffect(() => {
    setText(props.body)
  }, [props.body])

  function editText (value, delta, source, editor) {
    setText(value)
  }

  function editInvalidResponse (value, delta, source, editor) {
    setInvalidResponse(value)
  }

  function editUnwantedResponse (value, delta, source, editor) {
    setUnwantedResponse(value)
  }

  function editSecondResponse (value, delta, source, editor) {
    setSecondResponse(value)
  }

  function cancelChanges () {
    props.onHide()
    setText(props.body)
  }

  const handleCheck = name => value => {
    setUnwanted({ ...unwanted, [name]: value })
  }

  const handleTabChange = (event, newValue) => {
    setTab(newValue)
  }

  const handleSelectChange = (value) => {
    setCaptureTimes(value)
  }

  if (!props.open) {
    return <></>
  }

  const { spam, personal, blocked } = unwanted

  Emitter.on(EVENT_TYPE.BROWSER_BACK, cancelChanges)

  return (
    <ThemeProvider theme={theme}>
      <Modal
        open={props.open}
        onHide={cancelChanges}
        handleSave={() => {
          props.save({ message: text, captureTimes, unwanted, invalidResponse, unwantedResponse, secondResponse })
          setTab(0)
        }}
        title='Email Capture'
        helplink='home/bots/nodes/EmailCapture'
        helplinkLabel='Learn more about this skill'
        tabLabels={['Basic', 'Advanced']}
        tab={tab}
        handleTabChange={handleTabChange}
        saveIcon='save'
      >
        <TabPanel value={tab} index={0}>
          <ModalSection
            title='Bot Message'
            subtitle='How would you like the bot to ask for the visitor’s email address?'
          >
            <div style={{ height: 125 }} className='customQuill'>
              <RichTextEditor
                message={text}
                onChange={editText}
                placeholder='What is your email...'
                lines={2}
                showMergeFields
              />
            </div>
          </ModalSection>
          <ModalSection
            title='Unwanted Email Addresses'
            subtitle='Which types of email addresses are unwanted?'
          >
            <div className={classes.emailDiv}>
              <FormControl component='fieldset'>
                <FormGroup>
                  <Checkbox checked={spam} onChange={handleCheck('spam')} label='Spam domains' />
                  <Checkbox checked={personal} onChange={handleCheck('personal')} label='Non-business email' />
                  <Checkbox checked={blocked} onChange={handleCheck('blocked')} label='Blocked domains' />
                </FormGroup>
              </FormControl>
              <div onClick={() => window.open('/#/settings/email_management/spam_domains')} className={classes.linkText}>
                <div style={{ marginTop: '1px', marginRight: '5px' }}>
                  <Icon icon='settings' size='sm' />
                </div>
                <div style={{ cursor: 'pointer' }}>
                  Edit Settings
                </div>
              </div>
            </div>
          </ModalSection>
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <ModalSection
            subtitle='How many times should the bot ask for a valid email address?'
          >
            <div className='select'>
              <Select
                value={captureTimes}
                onChange={handleSelectChange}
                options={[
                  { value: 1, label: 'Only one time' },
                  { value: 2, label: 'Up to two times' },
                  { value: 3, label: 'Up to three times' }
                ]}
                disableClear
              />
            </div>
          </ModalSection>
          <ModalSection
            subtitle='what would you like to say when a visitor responds with an invalid email address?'
          >
            <div style={{ height: 110 }} className='emailResponse'>
              <div className='customQuill'>
                <RichTextEditor
                  message={invalidResponse}
                  onChange={editInvalidResponse}
                  placeholder='Please enter a valid email...'
                  lines={2}
                />
              </div>
            </div>
          </ModalSection>
          {(spam || personal || blocked) && (
            <ModalSection
              subtitle='What would you like to say when a visitor responds with an unwanted email address?'
            >
              <div style={{ height: 110 }} className='emailResponse'>
                <div className='customQuill'>
                  <RichTextEditor
                    message={unwantedResponse}
                    onChange={editUnwantedResponse}
                    placeholder='Please enter a business email...'
                    lines={2}
                  />
                </div>
              </div>
            </ModalSection>
          )}
          {(captureTimes > 1) && (
            <ModalSection
              subtitle='What would you like to say after a second unwanted or invalid email?'
            >
              <div style={{ height: 110 }} className='emailResponse'>
                <div className='customQuill'>
                  <RichTextEditor
                    message={secondResponse}
                    onChange={editSecondResponse}
                    placeholder='Please enter a valid business email...'
                    lines={2}
                  />
                </div>
              </div>
            </ModalSection>
          )}
        </TabPanel>
      </Modal>
    </ThemeProvider>
  )
}

const EmailCaptureNode = props => {
  const base = props.base
  const classes = useNodeStyles(props)
  const assignKey = data => {
    data._reactKey = data._reactKey || jsPlumbUtil.uuid()
    return data._reactKey
  }

  const saveChanges = ({ message, unwanted, captureTimes, invalidResponse, unwantedResponse, secondResponse }) => {
    if (!base.toolkit) {
      return
    }
    const node = base.getNode()
    const new_data =
    {
      ...base.state,
      body: message,
      unwanted: unwanted,
      captureTimes: captureTimes,
      invalidResponse: invalidResponse,
      unwantedResponse: unwantedResponse,
      secondResponse: secondResponse
    }
    base.toolkit.updateNode(node, new_data)
    props.setModalOpen(false)
  }

  return (
    <>
      <div>
        <div
          className={`${classes.dragger} ${classes.visitorInteraction}`}
        >
          <div className='node-header'>
            <div className={classes.iconDiv}><EmailIcon /></div>
            <div className={classes.cardTitle}>Email Capture</div>
          </div>
          <div className='node-body'>
            <RenderEmoji
              text={base.state.body === '' ? 'Enter email capture message...' : base.state.body}
              rich='true'
            />
            <Divider style={{ marginTop: 5 }} />
            {base.state.ports.map((c, index) => {
              return (
                <OptionPort
                  index={index}
                  data={c}
                  key={assignKey(c)}
                  parent={base}
                  kind='visitorInteraction'
                  type='email'
                />
              )
            })}
          </div>
          <jtk-target
            scope='default'
          />
        </div>
        <jtk-target
          scope='default'
        />
      </div>
      <EmailCaptureModal
        open={props.modalOpen}
        onHide={() => props.setModalOpen(false)}
        save={saveChanges}
        body={base.state.body}
        unwanted={base.state.unwanted}
        invalidResponse={base.state.invalidResponse}
        unwantedResponse={base.state.unwantedResponse}
        secondResponse={base.state.secondResponse}
        captureTimes={base.state.captureTimes}
      />
    </>
  )
}

export default EmailCaptureNode
