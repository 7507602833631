import Loader from 'library/loading/Loader'
import { ModalSection } from 'library/Modal';
import { createHeaders } from 'pages/segments/segmentHelpers';
import MappingRow, { MappingField } from 'pages/settings/Integrations/MappingRow';
import { CSVFileAttributes, HeaderMapping } from './CSVUploadModal';
/* eslint-disable @typescript-eslint/naming-convention */
interface CSVHeaderMappingStepProps {
  CSVFileAttributes: CSVFileAttributes
  setCSVFileAttributes: ({ headerMapping }: { headerMapping: HeaderMapping }) => void
  setDisableNextBtn: (boolean: boolean) => void
}

const keyMapping = {
  0: 'name',
  1: 'website',
  2: 'account_type',
  3: 'account_owner_email'
}

const mapping = [
  { type: 'segment', data_type: 'string', value: 'Name' },
  { type: 'segment', data_type: 'string', value: 'Website' },
  { type: 'segment', data_type: 'string', value: 'Account Type', optional: true },
  { type: 'segment', data_type: 'string', value: 'Account Owner Email', optional: true }
]

export default function CSVHeaderMappingStep ({ CSVFileAttributes, setCSVFileAttributes, setDisableNextBtn }: CSVHeaderMappingStepProps) {
  const headerMapping = CSVFileAttributes.headerMapping
  const headerOptions = createHeaders(CSVFileAttributes.headers)
  const noHeaderOptions = headerOptions.length === 0

  const editMapping = ({ field, index, value }: { field: string, index: 0 | 1 | 2 | 3, value: string }) => {
    if (field === 'data_type') {
      const newHeaderMapping = Object.assign(headerMapping, { [keyMapping[index]]: value })
      setCSVFileAttributes({ headerMapping: newHeaderMapping })
    }
  }

  if (!!headerMapping.name && !!headerMapping.website) {
    setDisableNextBtn(false)
  }

  return (
    <ModalSection title='Confirm CSV Fields' subtitle='Ensure that each Signals field is mapped to the corresponding header from your CSV'>
      <div style={{ display: 'grid', alignItems: 'center', gridTemplateColumns: '150px auto 150px' }}>
        <p>Signals Field</p>
        <span />
        <p>CSV Header</p>
      </div>
      {noHeaderOptions
        ? <Loader type='spinner' />
        : mapping.map((row: any, index: number) => (
          <MappingRow
            key={index}
            index={index}
            row={row}
            deleteMapping={() => undefined}
            editMapping={(value: MappingField) => editMapping({ field: value.field, index: value.index as 0 | 1 | 2 | 3, value: value.value as string })}
            externalFields={headerOptions}
            customFields={[]}
            typeMapping={[]}
            mappedGroups={[]}
            disableOverwrite
            customClass
          />
        ))}
    </ModalSection>
  )
}
