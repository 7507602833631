import { useContext, useState, useReducer, useRef } from 'react'
import XIcon from '@material-ui/icons/HighlightOff'
import CustomizedSnackbar from 'components/CustomizedSnackbar'
import DeleteModal from 'components/DeleteModal'
import { makeStyles } from '@material-ui/core/styles'
import { ConversationParametersContext } from '../conversation-parameters-context'
import { SessionContext } from 'session-context'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import EditIcon from '@material-ui/icons/Edit'
import ConversationsViewModal from './ConversationsViewModal'
import { createConversationView, getConversationViews, getFilterCounts, deleteConversationView, updateConversationView } from 'api/conversations'
import { useQuery, useQueryClient } from 'react-query'
import Button from '@material-ui/core/Button'
import { Typography, ClickAwayListener } from '@material-ui/core'
import Popover from '@material-ui/core/Popover'
import { triggerSupportBot } from 'pages/bot/InAppBot/triggerSupportBot'
import { DisplaySettings } from 'classes/displaySettings'
import { Icon } from 'library/materialUI'
import { ConversationWindowContext } from '../conversation-window-context'

const useStyles = makeStyles(theme => ({
  contents: {
    paddingTop: 5,
    display: 'grid',
    gridTemplateRows: 'auto 40px'
  },
  filterOption: {
    padding: 6,
    paddingLeft: 10,
    marginBottom: 5,
    color: '#6a6a6a',
    fontSize: '14px',
    borderBottomRightRadius: 15,
    borderTopRightRadius: 15,
    marginRight: 10,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  filterOptionEditMode: {
    cursor: 'default'
  },
  selected: {
    backgroundColor: '#F2EBFF',
    color: theme.palette.primary.main,
    fontWeight: 600
  },
  control: {
    marginLeft: 8,
    cursor: 'pointer'
  },
  xicon: {
    color: '#F0706F',
    fontSize: '18px',
    cursor: 'pointer'
  },
  controls: {
    padding: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderTop: 'solid rgba(0, 0, 0, .125) 1px'
  },
  twoButtonControls: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: 10,
    alignItems: 'center',
    borderTop: 'solid rgba(0, 0, 0, .125) 1px'
  },
  icon: {
    marginRight: 8,
    fontSize: '18px',
    cursor: 'pointer'
  },
  buttonIcon: {
    marginRight: 0
  },
  helpMenu: {
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'poppins',
    margin: '5px',
    width: '250px'
  },
  helpMenuItem: {
    display: 'flex',
    flexDirection: 'row',
    margin: '5px',
    cursor: 'pointer'
  }
}))

const convertFilterCounts = (value) => {
  if (value > 10000) {
    return '10,000+'
  } else if (value > 5000) {
    return '5,000+'
  } else if (value > 1000) {
    return '1,000+'
  } else if (value > 500) {
    return '500+'
  } else if (value > 100) {
    return '100+'
  } else if (value > 50) {
    return '50+'
  } else if (value > 25) {
    return '25+'
  } else {
    return value
  }
}

const baseOptions = [
  { id: 'my_open_conversations', name: 'My open conversations', kind: 'default' },
  { id: 'my_conversations', name: 'My conversations', kind: 'default' }
]

function FilterOption (props) {
  const classes = useStyles()
  const { clearSelectedConversation } = useContext(ConversationWindowContext)
  const option = props.option
  return (
    <div
      className={`${classes.filterOption} ${props.selected ? classes.selected : null} ${props.editMode ? classes.filterOptionEditMode : null}`}
      onClick={() => {
        props.setConversationFilter(option.id, option.kind, option.name)
        clearSelectedConversation()
      }}
    >
      <div>
        {option.name}
      </div>
      <div>
        {convertFilterCounts(props.count)}
        {option.kind === 'view' && props.editMode ? (
          <>
            <EditIcon className={classes.icon} onClick={() => props.editView(option.id)} />
            <XIcon className={classes.xicon} onClick={() => props.deleteView(option.id)} />
          </>
        ) : (<></>)}
      </div>
    </div>
  )
}

const initialState = {
  deleteViewID: 0,
  editView: null,
  modalOpen: false,
  editMode: false
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'init':
      return action.data
    case 'created':
      return { ...state, modalOpen: false }
    case 'updated':
      return { ...state, modalOpen: false, editView: null }
    case 'creating':
      return { ...state, modalOpen: true, editView: null }
    case 'editMode':
      return { ...state, editMode: true }
    case 'doneEditing':
      return { ...state, editMode: false }
    case 'editing':
      return { ...state, modalOpen: true, editView: action.data }
    case 'deleting':
      return { ...state, deleteViewID: action.data }
    case 'deleted':
      return { ...state, deleteViewID: 0 }
    case 'appendEvent':
      return {
        ...state,
        events: [...state.events, action.value]
      }
    default:
      break
  }
}

function ViewButtons (props) {
  const classes = useStyles()
  const dispatch = props.dispatch

  if (!props.visible) {
    return <></>
  }

  if (props.editMode) {
    return (
      <div className={classes.controls}>
        <Button
          onClick={() => dispatch({ type: 'doneEditing' })}
          color='primary'
          size='small'
        >
          Done
        </Button>
      </div>
    )
  }

  return (
    <div id='nav-3' className={classes.twoButtonControls}>
      <Button
        startIcon={<AddCircleIcon className={classes.icon} />}
        onClick={() => { dispatch({ type: 'creating' }) }}
        color='primary'
        size='small'
        classes={{ startIcon: classes.buttonIcon }}
      >
        Create View
      </Button>
      <Button
        startIcon={<EditIcon className={classes.icon} />}
        onClick={() => dispatch({ type: 'editMode' })}
        color='primary'
        size='small'
        classes={{ startIcon: classes.buttonIcon }}
      >
        Edit Views
      </Button>
    </div>
  )
}

function ChatModeFilters () {
  const classes = useStyles()
  const [state, dispatch] = useReducer(reducer, initialState)
  const [snackState, setSnackState] = useState({
    open: false,
    variant: 'success',
    message: 'Your changes have been saved'
  })
  const { user } = useContext(SessionContext)
  const { parameters } = useContext(ConversationParametersContext)
  const filters = parameters.state.conversationFilters
  const defaultFilter = filters.values.filter
  const queryClient = useQueryClient()
  const deleteMessage = 'Are you sure you want to delete this view?'
  const deleteTitle = 'Delete view'
  const allConversationsPermission = user.attributes?.perms?.conversations_all || false
  const [helpOpen, setHelpOpen] = useState(false)
  const anchorRef = useRef(null)

  function _getFiltersByPermission () {
    let filters = [...baseOptions]
    if (allConversationsPermission) {
      const conversationsAllFilters = [
        { id: 'open_conversations', name: 'All open conversations', kind: 'default' },
        { id: 'all_conversations', name: 'All conversations', kind: 'default' }
      ]
      filters = filters.concat(conversationsAllFilters)
    }
    return filters
  }

  function _getViewsByPermission (defaultOptions) {
    let filters = [...defaultOptions]
    if (allConversationsPermission) {
      filters = views ? filters.concat(views.map(v => ({ id: v.id, name: v.attributes.name, kind: 'view' }))) : filters
    }
    return filters
  }

  function beginDelete () {
    deleteConversationView({ id: state.deleteViewID }).then(() => {
      dispatch({ type: 'deleted' })
      queryClient.invalidateQueries('conversation-views')
      allConversationsPermission ? parameters.setFilter('open_conversations', 'default', 'All open conversations') : parameters.setFilter('my_open_conversations', 'default', 'My open conversations')
    }, (err) => {
      console.error('failed to delete view', err)
      dispatch({ type: 'deleted' })
      setSnackState({
        open: true,
        variant: 'error',
        message: 'Failed to delete view: ' + err.message
      })
    })
  }

  const { data: views } = useQuery('conversation-views', () => getConversationViews().then(res => res.data))

  const { data: filterCounts } = useQuery(['filter-counts'], () => getFilterCounts().then(res => res.data.attributes))

  const saveView = (attributes) => {
    if (attributes.id) {
      updateConversationView({ attributes, id: attributes.id })
        .then((response) => {
          dispatch({ type: 'updated' })
          queryClient.invalidateQueries('conversation-views')
          setSnackState({
            open: true,
            variant: 'success',
            message: 'Your changes have been saved'
          })
          parameters.setFilter(attributes.id, 'view', attributes.name)
        }, (err) => {
          console.error('failed to update view', err)
          setSnackState({
            open: true,
            variant: 'error',
            message: 'Failed to update view: ' + err.message
          })
        })
    } else {
      createConversationView({ attributes })
        .then(response => {
          dispatch({ type: 'created' })
          queryClient.invalidateQueries('conversation-views')
        })
    }
  }

  const defaultOptions = _getFiltersByPermission()
  const allOptions = _getViewsByPermission(defaultOptions)

  return (
    <div className={classes.contents}>
      <div>
        {allOptions.map((option, index) => {
          const showDefaultGroup = state.editMode && index === 0
          const showCustomGroup = state.editMode && index === defaultOptions.length
          const count = filterCounts ? filterCounts[option.id] : ''
          return (
            <div key={index}>
              {showDefaultGroup ? (<Typography variant='h6'>&nbsp;Default Views</Typography>) : (<></>)}
              {showCustomGroup ? (<Typography variant='h6'>&nbsp;Custom Views</Typography>) : (<></>)}
              <FilterOption
                option={option}
                selected={option.id === defaultFilter}
                setConversationFilter={parameters.setFilter}
                editMode={state.editMode}
                deleteView={(id) => dispatch({ type: 'deleting', data: id })}
                editView={(id) => {
                  const v = views.find(v => v.id === id)
                  if (v) {
                    dispatch({ type: 'editing', data: v })
                  }
                }}
                count={count}
              />
            </div>
          )
        })}
        <div style={{ cursor: 'pointer', margin: '5px', fontFamily: 'poppins' }} onClick={() => setHelpOpen(true)} ref={anchorRef}>
          Help
        </div>
        <Popover
          open={helpOpen}
          anchorEl={anchorRef.current}
        >
          <ClickAwayListener onClickAway={() => setHelpOpen(false)}>
            <div className={classes.helpMenu}>
              <div className={classes.helpMenuItem}>
                <div>
                  <a style={{ textDecoration: 'none', color: 'black' }} href='https://help.getsignals.ai/article/vkd5n9au27-chat-overview' target='_blank' rel='noopener noreferrer'>View Helpdocs</a>
                </div>
                <div style={{ marginLeft: 'auto' }}>
                  <Icon icon='helpDocs' size='md' color='#9933FF' />
                </div>
              </div>
              <div
                onClick={() => {
                  DisplaySettings.update({
                  page: 'ftux',
                  type: 'agent-chat',
                  settings: {
                    nav: { complete: false },
                    welcome: { complete: false },
                    availability: { complete: false },
                    conferencing: { complete: false },
                    calendar_drop: { complete: false },
                    saved_replies: { complete: false },
                    internal_notes: { complete: false },
                    conversation_details: { complete: false },
                    conversation_history: { complete: false }
                    }
                  })
                    window.location.reload()
              }} className={classes.helpMenuItem}
              >
                <div>
                  Take the feature tour
                </div>
                <div style={{ marginLeft: 'auto' }}>
                  <Icon icon='help' size='md' color='#9933FF' />
                </div>
              </div>
              <div onClick={() => triggerSupportBot()} className={classes.helpMenuItem}>
                <div>
                  Chat with support
                </div>
                <div style={{ marginLeft: 'auto' }}>
                  <Icon icon='message' size='md' color='#9933FF' />
                </div>
              </div>
            </div>
          </ClickAwayListener>
        </Popover>
      </div>
      <ViewButtons
        visible={allConversationsPermission}
        editMode={state.editMode}
        dispatch={dispatch}
      />
      {state.modalOpen ? (
        <ConversationsViewModal
          open={state.modalOpen}
          onHide={() => dispatch({ type: 'created' })}
          editView={state.editView}
          save={saveView}
        />
      ) : (
        <></>
      )}
      <DeleteModal
        open={Boolean(state.deleteViewID)}
        onHide={() => dispatch({ type: 'deleted' })}
        deleteObject={beginDelete}
        message={deleteMessage}
        title={deleteTitle}
      />
      <CustomizedSnackbar state={snackState} handler={setSnackState} />
    </div>
  )
}

export default ChatModeFilters
