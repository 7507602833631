import GenericObject from './genericObject'
import { LoadAllProps } from './queryHelpers'
import { useDoQuery } from './useDoQuery'

interface CRMFieldList {
  list: CRMField[]
  dict: { [id: CRMField['id']]: CRMField }
}

const validFieldTypes = [
  'picklist',
  'number',
  'checkbox',
  'int',
  'double',
  'currency',
  'boolean',
  'true-false'
]

const invalidCRMTypes = [
  'id',
  'phone'
]

const invalidFieldNames = [
  'Type',
  'Name',
  'NumberOfEmployees',
  'AnnualRevenue',
  'IsDeleted',
  'Industry',
  'Id'
]

// Note* CRMField.id is the fields api_name and CRMField.name is the fields label
export class CRMField extends GenericObject {
  apiName: string
  label: string
  crmType: string
  signalsType: string
  disabled: boolean
  active: any

  get type (): string { return this.crmType }
  get disabledMessage (): string {
    if (invalidFieldNames.includes(this.apiName)) {
      return 'The field ' + this.label + ' has been disabled because it does not make sense to segment on this field or is already an option in segments'
    } else if (this.crmType === 'id') {
      return 'Fields of the type ' + this.crmType + ' do not make sense for segmentation as they are all unique'
    } else if (invalidCRMTypes.includes(this.crmType)) {
      return 'Fields of the type ' + this.crmType + ' is not supported by Signals because of data complexity'
    } else if (!validFieldTypes.includes(this.signalsType)) {
      return 'Fields with the data type ' + this.signalsType + ' are not supported by Signals'
    }
    return 'This field is not supported by Signals'
  }

  static loadAll (props: LoadAllProps): { data: CRMFieldList, isLoading: boolean } {
    let integration = ''
    if (props?.searchParams?.extraHeaders) {
      integration = props.searchParams.extraHeaders['x-integration'] || 'salesforce'
    }
    if (!props?.searchParams?.sortColumn || integration === '') {
      return { data: { list: [], dict: {} }, isLoading: false }
    }
    const object = props.searchParams.sortColumn
    return useDoQuery({ path: `/crm_schema/${integration}/${object}/fields`, objectClass: CRMField, useChatURL: true })
  }

  constructor ({ row }: { row?: Record<string, any> }) {
    super({ row })
    const crmField = row?.attributes
    this.name = crmField.label
    this.apiName = crmField.api_name
    this.label = crmField.label
    this.crmType = crmField.crm_type
    this.signalsType = crmField.data_type
    this.disabled = !validFieldTypes.includes(this.signalsType) || invalidFieldNames.includes(crmField.api_name) || invalidCRMTypes.includes(crmField.crm_type)
    this.active = crmField.is_active
  }
}
