const headers = {
  'Content-Type': 'application/vnd.api+json'
}

function updateIntegration ({ integration }) {
  const integrationName = integration.attributes.integration_name

  if (!integrationName) {
    return
  }

  let method = 'POST'
  let url = ' /api/integrations/messaging/integration_settings'
  if (integration.id) {
    url = `${url}/${integrationName}`
    method = 'PATCH'
  }

  return fetch(url, {
    method: method,
    headers: headers,
    body: JSON.stringify({ data: integration })
  })
    .then((response) => response.json())
}

const getMessagingService = (integrationName) => {
  let url = '/api/integrations/messaging/integration_settings'

  if (integrationName) {
    url = `${url}/${integrationName}`
  } else {
    url = `${url}`
  }

  return fetch(url, {
    method: 'GET',
    headers: headers
  })
    .then((response) => response.json())
}

export { getMessagingService, updateIntegration }
