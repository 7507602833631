import { doPost } from './api'

const headers = {
  'Content-Type': 'application/vnd.api+json'
}

export const getExternalFields = (dataType: string): Promise<any> => {
  return fetch(`/api/eloqua/${dataType}/describe`, {
    method: 'GET',
    headers: headers
  }).then(response => response.json())
}

export const getCustomObjects = (): Promise<any> => {
  return fetch('/api/eloqua/custom_objects', {
    method: 'GET',
    headers: headers
  }).then(response => response.json())
}

export const getCustomObjectFields = (customObjectId: string): Promise<any> => {
  return fetch(`/api/eloqua/custom_objects/${customObjectId}/describe`, {
    method: 'GET',
    headers: headers
  }).then(response => response.json())
}

/**
 * Sync contact(s) to Eloqua
 *
 * @param personID The id of the contacts to sync
 * @param personIDs The ids of the contacts to sync
 * @returns Dictionary where the keys are contactIds **AS STRINGS** and the values are the eloquaIds
 */
export const syncContactsToEloqua = ({ personID, personIDs }: { personID: number, personIDs: number[] }): Promise<{
  ok: boolean
  data: { [contactId: string]: string }
}> => {
  let ids: number[] = []
  if (personID) {
    ids = [personID]
  } else if (personIDs) {
    ids = personIDs
  }
  return doPost<{ ok: boolean; data: { eloqua_contact_ids: { [contactId: string]: string } } }>({
    path: '/api/integrations/eloqua/sync_contact',
    data: { attributes: { person_ids: ids } }
  }).then(res => ({ ok: res.ok, data: res.data.eloqua_contact_ids }))
}
