import React, { useEffect, useState, useReducer } from 'react'
import { useQueryClient } from 'react-query'
import SettingsCard from 'cf-components/SettingsCard'
import { makeStyles } from '@material-ui/core/styles'
import {
  Chip, Switch,
  Breadcrumbs,
  Typography,
  Button
} from '@material-ui/core'
import CustomizedSnackbar from 'components/CustomizedSnackbar'
import { Link } from 'react-router-dom'
import {
  INTEGRATIONS,
  getCredentialsStatus,
  getIntegration,
  updateIntegration,
  addIntegrationSetting,
  updateIntegrationFeatures,
  getIntegrationFeature
} from 'api/integrations'
import {
  getTicketingIntegrationFields,
  setTicketingIntegrationFields
} from 'api/tickets'
import ZendeskLogo from 'img/zendesk_logo.svg'
import ZendeskOAuth from './OAuth/ZendeskOAuth'
import IntegrationEnabledFields from './IntegrationEnabledFields'

const useStyles = makeStyles((theme: any) => ({
  headerImage: {
    height: 65,
    marginBottom: 30
  },
  menuHeader: {
    borderBottom: '1px solid #BBB',
    width: '100%',
    fontWeight: 600,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: 'rgba(0,0,0,0.87)'
  },
  disabledHeaderImage: {
    height: 65,
    marginBottom: 30,
    filter: 'grayscale(100%)'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  manualToggle: {
    marginRight: 40,
    marginTop: 20
  },
  wrapIcon: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 15
  },
  textField: {
    cursor: 'text',
    width: '100%',
    marginBottom: 25,
    marginRight: 10
  },
  container: {
    padding: 65,
    minWidth: 920
  },
  bodyText: {
    marginBottom: 25,
    fontSize: 16
  },
  subtitle1: {
    fontSize: 18,
    fontWeight: 600,
    marginBottom: 15
  },
  subtitle2: {
    fontSize: 15,
    fontWeight: 600,
    marginBottom: 10
  },
  groupContainer: {
    backgroundColor: 'rgba(193, 166, 237, 0.1)',
    padding: 30,
    borderRadius: 10,
    marginBottom: 20
  },
  apiKeyField: {
    display: 'flex'
  },
  visibilityButton: {
    height: 56,
    marginTop: -1
  },
  expandingCard: {
    backgroundColor: 'rgba(193, 166, 237, 0.1)',
    borderRadius: 10,
    marginBottom: 20
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  },
  cardHeader: {
    widht: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: 10
  },
  headerTitle: {
    paddingLeft: 20,
    fontSize: 24,
    fontWeight: 'bold',
    paddingTop: 10
  },
  connectedIcon: {
    color: 'rgba(0, 135, 7, 0.7)'
  },
  disconnectedIcon: {
    color: 'rgba(255, 188, 3)'
  },
  crumb: {
    fontSize: '.8em'
  },
  typography: {
    fontSize: '1.05em'
  },
  breadcrumbs: {
    position: 'absolute',
    top: 5
  },
  crumbWrapper: {
    padding: 10
  },
  dottedLine: {
    borderTop: '2px dotted #975ce6',
    height: 10,
    marginTop: 20,
    width: '100%'
  },
  eventSettingRow: {
    display: 'flex',
    alignContent: 'center'
  }
}))

type State = {
  integrationSettings: any,
  kbSwitchMessage: string,
  ticketingSwitchMessage: string,
  activeIntegration: any,
  ticketFields: any[],
  adding: boolean,
  loading: boolean,
  hasConnection: any,
  knowledgebase: boolean,
  dirtyKnowledgebase: boolean,
  ticketing: boolean,
  dirtyTicketing: boolean,
  dirtyFields: boolean,
}
type Action = {
  type: string,
  data?: any
}

const initialState: State = {
  integrationSettings: {
    type: 'integrations',
    attributes: {
      integration_name: INTEGRATIONS.Zendesk, // eslint-disable-line
      enabled: false,
      config: {}
    }
  },
  kbSwitchMessage: '',
  ticketingSwitchMessage: '',
  activeIntegration: null,
  ticketFields: [],
  adding: false,
  loading: true,
  hasConnection: null,
  knowledgebase: false,
  dirtyKnowledgebase: false,
  ticketing: false,
  dirtyTicketing: false,
  dirtyFields: false
}
const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'initialize': {
      let kbEnabled = state.knowledgebase
      let ticketingEnabled = state.ticketing
      if (!action.data.attributes.enabled) {
        kbEnabled = false
        ticketingEnabled = false
      }
      return { ...state, integrationSettings: action.data, loading: false, ticketing: ticketingEnabled, knowledgebase: kbEnabled }
    }
    case 'fields-loaded':
      return { ...state, ticketFields: action.data, loading: false }
    case 'saving':
      return { ...state, loading: true }
    case 'connection-status':
      return { ...state, hasConnection: action.data }
    case 'field-changed':
      return { ...state, ticketFields: action.data, dirtyFields: true }
    case 'add-done':
      return { ...state, adding: false }
    case 'adding-field':
      return { ...state, adding: true }
    case 'saved-settings':
      return { ...state, dirtyTicketing: false, dirtyKnowledgebase: false, loading: false, ticketingSwitchMessage: '', kbSwitchMessage: '', dirtyFields: false }
    case 'save-error':
      return { ...state, loading: false }
    case 'init-knowledgebase': {
      const enabled = Boolean(action.data.integration && action.data.integration.name === 'zendesk')
      let switchMessage = ''
      if (!enabled && action.data.integration) {
        switchMessage = `(enabling Zendesk will disable ${action.data.integration.label})`
      }
      return { ...state, knowledgebase: enabled, kbSwitchMessage: switchMessage }
    }
    case 'disable-knowledgebase':
      return { ...state, knowledgebase: false, dirtyKnowledgebase: true }
    case 'enable-knowledgebase':
      return { ...state, knowledgebase: true, dirtyKnowledgebase: true }
    case 'init-ticketing': {
      const enabled = Boolean(action.data.integration && action.data.integration.name === 'zendesk')
      let switchMessage = ''
      if (!enabled && action.data.integration) {
        switchMessage = `(enabling Zendesk will disable ${action.data.integration.label})`
      }
      return { ...state, ticketing: enabled, ticketingSwitchMessage: switchMessage }
    }
    case 'disable-ticketing':
      return { ...state, ticketing: false, dirtyTicketing: true }
    case 'enable-ticketing':
      return { ...state, ticketing: true, dirtyTicketing: true }
    default:
      return state
  }
}

const defaultSnackState: any = {
  open: false,
  variant: 'success',
  message: 'Your changes have been saved',
  err: ''
}

const ZendeskIntegrationPage = (): JSX.Element => {
  const classes = useStyles()
  const queryCache = useQueryClient()
  const [state, dispatch] = useReducer(reducer, initialState)
  const [snackState, setSnackState] = useState(defaultSnackState)
  const addNewField = (): void => {
    dispatch({ type: 'adding-field' })
  }
  const removeField = (id: string): void => {
    const fields = [...state.ticketFields]
    for (let i = 0; i < fields.length; i++) {
      if (fields[i].id === id) {
        fields[i].attributes.enabled = false
      }
    }
    dispatch({ type: 'field-changed', data: fields })
  }
  const setNewFieldValue = (newValue: string, oldValue: string): void => {
    let oldIdx = -1
    let newIdx = -1
    const fields = [...state.ticketFields]
    for (let i = 0; i < fields.length; i++) {
      if (fields[i].id === newValue) {
        newIdx = i
      } else if (fields[i].id === oldValue) {
        oldIdx = i
      }
    }
    if (newIdx > -1) {
      fields[newIdx].attributes.enabled = true
      if (oldIdx > -1) {
        fields[newIdx].attributes.required = fields[oldIdx].attributes.required
      }
    }
    if (oldIdx > -1) {
      fields[oldIdx].attributes.enabled = false
    }
    dispatch({ type: 'field-changed', data: fields })
    dispatch({ type: 'add-done' })
  }

  const setFieldRequired = (id: string, required: boolean): void => {
    const fields = [...state.ticketFields]
    for (let i = 0; i < fields.length; i++) {
      if (fields[i].id === id) {
        fields[i].attributes.required = required
      }
    }
    dispatch({ type: 'field-changed', data: fields })
  }

  const toggleFeature = (feature: string, checked: boolean): void => {
    if (checked) {
      dispatch({ type: `enable-${feature}` })
    } else {
      dispatch({ type: `disable-${feature}` })
    }
  }

  const toggleIntegration = async (checked: boolean): Promise<void> => {
    const integrationSettings = { ...state.integrationSettings }
    integrationSettings.attributes.enabled = checked
    save(integrationSettings)
  }
  const saveSettings = (): void => {
    dispatch({ type: 'saving' })
    const promises = []
    if (state.dirtyKnowledgebase) {
      let integration = null
      if (state.knowledgebase) {
        integration = {
          name: INTEGRATIONS.Zendesk
        }
      }
      promises.push(updateIntegrationFeatures([{
        id: 'knowledgebase',
        attributes: {
          integration: integration
        }
      }]))
    }
    if (state.dirtyFields && state.ticketing) {
      const fields = []
      for (let i = 0; i < state.ticketFields.length; i++) {
        const tf = state.ticketFields[i]
        if (tf.attributes.enabled) {
          fields[fields.length] = {
            id: tf.id,
            type: tf.type,
            attributes: {
              required: tf.attributes.required
            }
          }
        }
      }
      promises.push(setTicketingIntegrationFields({ data: fields }))
    }
    if (state.dirtyTicketing) {
      let integration = null
      if (state.ticketing) {
        integration = {
          name: INTEGRATIONS.Zendesk
        }
      }

      promises.push(updateIntegrationFeatures([{
        id: 'ticketing',
        attributes: {
          integration: integration
        }
      }]))
    }
    if (promises.length > 0) {
      Promise.all(promises).then(() => {
        setSnackState({
          open: true,
          variant: 'success',
          message: 'Your settings have been saved'
        })
        queryCache.invalidateQueries('ticketing')
        dispatch({ type: 'saved-settings' })
      }, (err) => {
        setSnackState({
          open: true,
          message: 'There was an error while trying to save your settings.',
          err: err.message
        })
        dispatch({ type: 'save-error' })
      })
    }
  }

  const save = (integrationState: any): void => {
    dispatch({ type: 'saving' })
    if (!integrationState?.id) {
      integrationState = state
    }
    updateIntegration({ integration: integrationState })
      .then((response: any) => {
        dispatch({ type: 'initialize', data: response.data })
        setSnackState({
          open: true,
          variant: 'success',
          message: 'Your changes have been saved'
        })
      })
  }
  useEffect(() => {
    if (!state.integrationSettings.attributes.enabled) {
      return
    }
    getIntegrationFeature('ticketing').then((resp: any) => {
      dispatch({ type: 'init-ticketing', data: { integration: resp.data.attributes.integration } })
    }, (err: Error) => {
      setSnackState({
        open: true,
        message: 'Could not load ticketing feature settings.',
        err: err.message
      })
    })
  }, [state.integrationSettings.attributes.enabled])
  useEffect(() => {
    if (!state.integrationSettings.attributes.enabled) {
      return
    }
    getIntegrationFeature('knowledgebase').then((resp: any) => {
      dispatch({ type: 'init-knowledgebase', data: { integration: resp.data.attributes.integration } })
    }, (err: Error) => {
      setSnackState({
        open: true,
        message: 'Could not load knowledgebase feature settings',
        err: err.message
      })
    })
  }, [state.integrationSettings.attributes.enabled])

  useEffect(() => {
    getCredentialsStatus(INTEGRATIONS.Zendesk)
      .then((js: any) => {
        const hasCredentials = js.data.attributes.has_credentials
        dispatch({ type: 'connection-status', data: hasCredentials })
        if (hasCredentials) {
          getTicketingIntegrationFields()
            .then((response: any) => {
              if (response.data) {
                dispatch({ type: 'fields-loaded', data: response.data })
              }
            }, (err: Error) => {
              setSnackState({
                open: true,
                message: 'Could not load ticket fields.',
                err: err.message
              })
            })
        }
      }, (err: Error) => {
        console.error('failed to get credentials status', err)
        setSnackState({
          open: true,
          message: 'Could not load credential status.',
          err: err.message
        })
      })
  }, [])

  useEffect(() => {
    getCredentialsStatus(INTEGRATIONS.Zendesk)
      .then((js: any) => {
        const hasCredentials = js.data.attributes.has_credentials
        dispatch({ type: 'connection-status', data: hasCredentials })
      }, (err: Error) => {
        console.error('failed to get credentials status', err)
        setSnackState({
          open: true,
          variant: 'error',
          message: err.message
        })
      })
  }, [state.ticketing, state.dirtyTicketing, state.hasConnection])

  useEffect(() => {
    if (!state.ticketing || state.dirtyTicketing || !state.hasConnection) {
      return
    }
    getTicketingIntegrationFields()
      .then((response: any) => {
        if (response.data) {
          dispatch({ type: 'fields-loaded', data: response.data })
        }
      }, (err: Error) => {
        setSnackState({
          open: true,
          variant: 'error',
          message: err.message
        })
      })
  }, [state.ticketing, state.dirtyTicketing, state.hasConnection])

  useEffect(() => {
    getIntegration(INTEGRATIONS.Zendesk)
      .then((response: any) => {
        if (response.data) {
          dispatch({ type: 'initialize', data: response.data })
        } else {
          addIntegrationSetting({ integration: initialState.integrationSettings }).then(
            (response: any) => {
              dispatch({ type: 'initialize', data: response.data })
            })
        }
      })
  }, [])

  if (state === null) {
    return (
      <>
        Loading
      </>
    )
  }

  if (state.hasConnection === false) {
    return (
      <ZendeskOAuth
        name='Zendesk Integration OAuth'
      />
    )
  }

  return (
    <>
      <Breadcrumbs aria-label='breadcrumb' classes={{ li: classes.crumb, ol: classes.crumbWrapper }}>
        <Link style={{ color: '#7A7A7A' }} to='/settings/integrations'>
          Integrations Page
        </Link>
        <Typography color='textPrimary' classes={{ root: classes.typography }}>Zendesk Integration Page</Typography>
      </Breadcrumbs>
      <div className={classes.container}>
        <div className={classes.header}>
          <img
            alt='Zendesk Logo'
            src={ZendeskLogo}
            className={
              state.integrationSettings.attributes.enabled
                ? classes.headerImage
                : classes.disabledHeaderImage
            }
          />
          {state ? (
            <div className={classes.manualToggle}>
              <Chip
                label={
                  state.integrationSettings.attributes.enabled
                    ? 'Integration: On'
                    : 'Integration: Off'
                }
                color={
                  state.integrationSettings.attributes.enabled ? 'primary' : 'default'
                }
              />
              <Switch
                checked={state.integrationSettings.attributes.enabled}
                onChange={(e) => toggleIntegration(e.target.checked)}
                name='master_toggle'
              />
            </div>
          ) : (
            <></>
          )}
        </div>
        <SettingsCard
          title='Ticketing'
          subtitle='Enabling this feature allows you to create new tickets in Zendesk'
          toggleFeature={(f: boolean) => toggleFeature('ticketing', f)}
          switchMessage={state.ticketingSwitchMessage}
          featureSwitch
          enabled={state.ticketing}
          alwaysExpanded={!(state.ticketing && !state.dirtyTicketing)}
        >
          {state.ticketing && !state.dirtyTicketing ? (
            <div className={classes.groupContainer}>
              <IntegrationEnabledFields
                integrationName='Zendesk'
                fields={state.ticketFields}
                setRequired={setFieldRequired}
                removeField={removeField}
                setNewFieldValue={setNewFieldValue}
                adding={state.adding}
                addField={addNewField}
              />
              <br />
            </div>
          ) : (
            <></>
          )}
        </SettingsCard>
        <SettingsCard
          title='Knowledgebase'
          subtitle='Enabling this feature allows you to use Zendesk in your FAQ bots as an FAQ source'
          toggleFeature={(f: boolean) => toggleFeature('knowledgebase', f)}
          featureSwitch
          switchMessage={state.kbSwitchMessage}
          enabled={state.knowledgebase}
          alwaysExpanded
        />
        <Button
          color='primary'
          onClick={saveSettings}
          disabled={!state.dirtyKnowledgebase && !state.dirtyTicketing && !state.dirtyFields}
          variant='contained'
        >
          Save
        </Button>
      </div>
      <CustomizedSnackbar state={snackState} handler={setSnackState} />
    </>
  )
}

export default ZendeskIntegrationPage
