
const descriptionText = 'The CTA Button node can be used to provide your web visitors with a button they can click. It will take them to a certain webpage, either on your website or a different website.'

const Description = (): JSX.Element => {
  return (
    <div>
      <div>
        {descriptionText}
      </div>
    </div>
  )
}

const bestPracticeText = 'Use this node to give visitors easy access to content that would otherwise be difficult to find'

const BestPractices = () => {
  return (
    <div>
      <div>
        {bestPracticeText}
      </div>
      <ul>
        <li>Open the link in the current tab to allow the user to continue to access the bot and view the linked content concurrently</li>
        <li>Use the button to access content such as PDF's</li>
        <li>Limit clicks when possible by placing brief content directly within the bot flow.</li>
      </ul>
    </div>
  )
}

export const ButtonDocs = {
  helpCenterLink: 'https://help.getsignals.ai/article/aknvotkk67-button-node',
  bestPractices: BestPractices,
  description: Description,
  videoLink: ''
}
