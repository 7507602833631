import { AvatarWithInfo } from 'cf-components/Avatars'
import { Icon } from 'library/materialUI'
import { makeStyles } from '@material-ui/core/styles'
import { Select } from 'cf-components/material-wrappers/MaterialComponents'
import { useEffect, useState } from 'react'
import { saveTenant } from 'api/admin_only/tenants'
import { User } from 'classes/users'

const useStyles = makeStyles(theme => ({
  phoneDiv: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 20,
    marginBottom: 20,
    paddingLeft: 15
  },
  phoneNumber: {
    marginLeft: 25
  },
  card: {
    minHeight: 140
  },
  edit: {
    width: '100%',
    textAlign: 'right'
  }
}))

interface contactCardProps {
  tenantId: number | null
  contact: number | string
  users: User[] // User[] eventually needs to be an array of users once the endpoint has been updated
}

export const AdminContactCard = (props: contactCardProps): JSX.Element => {
  const classes = useStyles()
  const [pointOfContactID, setPointOfContactID] = useState(props.contact)

  const selectUsers = props.users.map((el: User) => { // type needs to be updated eventually to User
    return { label: el.name, value: el.id }
  })

  useEffect(() => {
    setPointOfContactID(props.contact)
  }, [props.contact])

  const handleChange = (val: number) => {
    const attributes = { point_of_contact_id: val, is_admin: true } // eslint-disable-line
    saveTenant({ tenantID: props.tenantId, attributes: attributes })
      .then(() => {
        setPointOfContactID(val)
      })
  }

  if (props.users.length === 0) {
    return (
      <div className={classes.card}>
        Tenant has no users yet
      </div>
    )
  }

  const pointOfContact = props.users.find((el: any) => {
    return el.id === pointOfContactID
  })

  if (!pointOfContactID || !pointOfContact) {
    return (
      <div className={classes.card} style={{ marginTop: 20 }}>
        <Select
          onChange={(val: number) => handleChange(val)}
          value={pointOfContactID}
          options={selectUsers}
          label='Select a Contact'
          disableClear
        />
      </div>
    )
  }

  const phone = pointOfContact.phone

  return (
    <div className={classes.card}>
      <div className={classes.edit}>
        <Icon icon='edit' color='primary' onClick={() => setPointOfContactID('')} />
      </div>
      <AvatarWithInfo
        subTextStyle={{ maxWidth: 600 }}
        user={pointOfContact}
        title={pointOfContact.displayName}
        subtitle={pointOfContact.email}
        size='lg'
      />
      <div className={classes.phoneDiv}>
        <Icon icon='phoneHandset' size='lg' />
        <div className={classes.phoneNumber}>
          {phone || 'None available'}
        </div>
      </div>
    </div>
  )
}
