import Card from 'library/materialUI/Card'
import IntegrationMapping from '../IntegrationMapping'
import Loader from 'library/loading/Loader'
import { IntegrationMappingCls } from 'classes/IntegrationMapping'
import { useContext, useEffect } from 'react'
import { TabContext } from '../IntegrationPage/TabProvider'
import { IntegrationPageContext } from '../IntegrationPage/IntegrationPageProvider'

const contactGroups = [{ value: 'contacts', name: 'Contact Fields' }]
interface ContactFieldMappingCardProps {
  save: any
  handleEdit: any
  integrationSettings: any
  contactExternalFields: any
}

export default function ContactFieldMappingCard ({ save, contactExternalFields }: ContactFieldMappingCardProps) {
  const { data: crmContactMapping, isLoading } = IntegrationMappingCls.loadOne('contacts', 'hubspot')
  const { shouldSave } = useContext(TabContext)
  const { handleSaved } = useContext(IntegrationPageContext)

  useEffect(() => {
    if (shouldSave) {
      save()
      handleSaved()
    }
  })

  return (
    <Card
      content={
        <>
          {isLoading
            ? <Loader type='spinner' />
            : (
              <div>
                <IntegrationMapping
                  integrationName='HubSpot'
                  title='Contact Property Mapping'
                  currentMapping={crmContactMapping.mapping}
                  externalMappingFields={contactExternalFields}
                  forObject='people'
                  handleEdit={(data: any) => { IntegrationMappingCls.save('contacts', data, 'hubspot') }}
                  disableOverwrite={undefined}
                  mappedGroups={contactGroups}
                />
              </div>)}
        </>
      }
    />
  )
}
