
const descriptionText = 'The PlayMaker is a powerful tool that can be used to automate portions of your marketing and sales processes.  Some of the customization options available include:'

const Description = (): JSX.Element => {
  return (
    <div>
      <div>
        {descriptionText}
      </div>
      <ul>
        <li>Customize content for visitors to engage with based upon their previous history</li>
        <ul>
          <li>Fire specific bots</li>
          <li>Send customized emails</li>
        </ul>
        <li>Alert individual agents based upon unique criteria</li>
        <li>Enroll visitors into marketing campaigns</li>
        <li>Search for contacts at the visitor's company</li>
      </ul>
    </div>
  )
}

const bestPracticeText = "When setting up your plays, aim for as much specificity as possible.  For example, you don't want to alert an agent to a visitor on site if there is no benefit to doing so."

const BestPractices = () => {
  return (
    <div>
      <div>
        {bestPracticeText}
      </div>
      <ul>
        <li>Use branching nodes when possible to filter visitors</li>
        <li>Chain multiple nodes together to further differentiate between visitors</li>
        <li>Use a combination of bots, emails, and marketing campaigns to increase your likelihood of interacting directly with your visitors</li>
      </ul>
    </div>
  )
}

export const playOverviewDocs = {
  helpCenterLink: 'https://help.getsignals.ai/article/4hht4uof4j-play-runner-overview',
  bestPractices: BestPractices,
  description: Description,
  videoLink: ''
}
