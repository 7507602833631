import { makeStyles } from '@material-ui/core/styles'
import { Accordion, AccordionDetails, AccordionSummary, Button, Tooltip } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { QueryFilterGroup } from 'classes/queryHelpers'
import { ConvertDashboardFilterToQueryFilters, ConvertQueryFiltersToDashboardFilter, DashboardFilter, filterAttribute } from '../DashboardHelper'
import { Icon } from 'library/materialUI'
import DashboardFilterSelect from './DashboardFilterSelect'
import { Key, useEffect, useReducer, useState } from 'react'
import FilterChip from './FilterChip'
import { useDebounce } from 'use-debounce'

const useStyles = makeStyles(theme => ({
  filterPopup: {
    width: '375px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    justifyContent: 'top',
    backgroundColor: 'white',
    borderRadius: '10px',
    boxShadow: '0px 3px 6px #00000029',
    overflow: 'auto'
  },
  popupHeader: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: '16px',
    fontFamily: 'poppins',
    fontWeight: 'bold',
    marginLeft: '21px',
    marginRight: '21px',
    marginTop: '18px',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  popupBody: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    justifyContent: 'center',
    marginLeft: '16px'
  },
  divider: {
    height: '2px',
    width: 'maxContent',
    backgroundColor: '#00000030',
    margin: '18px'
  },
  popupItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: '14px',
    fontFamily: 'poppins',
    margin: '5px',
    whiteSpace: 'nowrap',
    marginRight: '21px',
    cursor: 'pointer'
  },
  filterValueList: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    minHeight: '40px',
    maxHeight: '400px',
    width: '100%',
    overflowY: 'auto',
    marginBottom: '10px'
  },
  setFilter: {
    fontFamily: 'poppins',
    fontSize: '12px',
    color: 'black'
  },
  accordionDetails: {
    paddingTop: 0
  }
}))

interface FilterSectionProps {
  key?: Key | null | undefined
  attribute: filterAttribute
  filters: QueryFilterGroup
  cleared: boolean
  editFilters: (filters: QueryFilterGroup) => void
}

const dashboardFilterReducer = (state: DashboardFilter, action: any) => {
  switch (action.type) {
    case 'setIncluded':
      return { ...state, included: action.value }
    case 'setExcluded':
      return { ...state, excluded: action.value }
    case 'switchIncluded':
      return { ...state, included: state.included.filter(v => v !== action.value), excluded: [...state.excluded, action.value] }
    case 'switchExcluded':
      return { ...state, excluded: state.excluded.filter(v => v !== action.value), included: [...state.included, action.value] }
    case 'clear':
      return { ...state, included: [], excluded: [] }
    default:
      return state
  }
}

export default function FilterSection ({ key, attribute, filters, cleared, editFilters }: FilterSectionProps): JSX.Element {
  const classes = useStyles()
  const sectionFilters = filters.filter(filter => filter.field === attribute.dbKey)
  const [dashboardFilter, dispatch] = useReducer(dashboardFilterReducer, ConvertQueryFiltersToDashboardFilter(sectionFilters, attribute.dbKey, attribute.type))
  const [initialLoad, setInitialLoad] = useState(true)
  const [expanded, setExpanded] = useState(false)
  const [search, setSearch] = useState<string>('')
  const [debouncedSearch] = useDebounce(search, 500)
  const { data: items, isLoading } = attribute?.getItems ? attribute.getItems(debouncedSearch) : { data: [], isLoading: false }

  function handleEditFilter (values: string[], excluded: boolean) {
    if (excluded) {
      dispatch({ type: 'setExcluded', value: values })
    } else {
      dispatch({ type: 'setIncluded', value: values })
    }
  }

  function removeFilter (value: string, excluded: boolean) {
    if (dashboardFilter.type === 'range' || dashboardFilter.type === 'calendar') {
      dispatch({ type: 'clear' })
      return
    }
    if (excluded) {
      dispatch({ type: 'setExcluded', value: dashboardFilter.excluded.filter((v: string) => v !== value) })
    } else {
      dispatch({ type: 'setIncluded', value: dashboardFilter.included.filter((v: string) => v !== value) })
    }
  }

  function switchFilter (value: string, excluded: boolean) {
    if (excluded) {
      dispatch({ type: 'switchExcluded', value })
    } else {
      dispatch({ type: 'switchIncluded', value })
    }
  }

  useEffect(() => {
    if (cleared && !initialLoad) {
      dispatch({ type: 'clear' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cleared])

  useEffect(() => {
    if (initialLoad) {
      setInitialLoad(false)
      return
    }
    const newFilters = ConvertDashboardFilterToQueryFilters(dashboardFilter)
    editFilters(newFilters)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardFilter])

  return (
    <Accordion
      key={key}
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='panel1a-content'
        id='panel1a-header'
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <div className={classes.popupItem}>
              <div style={{ marginTop: '2px', marginRight: '8px' }}>
                <Icon icon={attribute.Icon} size='sm' />
              </div>
              <div style={{ display: 'flex' }}>
                {attribute.name}
                {attribute.name === 'Signals Score' && (
                  <Tooltip title='Powered by Artificial Intelligence'>
                    <div style={{ marginTop: '-5px' }}>
                      <Icon icon='aiSparkles' />
                    </div>
                  </Tooltip>
                )}
              </div>
              {attribute.tooltip && (
                <Tooltip title={attribute.tooltip}>
                  <div style={{ marginLeft: '5px', marginTop: '3px' }}>
                    <Icon icon='infoIcon' size='sm' color='#00000040' />
                  </div>
                </Tooltip>
              )}
            </div>
            {(dashboardFilter.included.length > 0 || dashboardFilter.excluded.length > 0) && (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '18px', height: '18px', marginLeft: '5px', borderRadius: '50%', backgroundColor: 'black', color: 'white', fontSize: '0.85rem' }}>
                  {['range', 'calendar'].includes(dashboardFilter.type) ? (
                    dashboardFilter.included.length ? 1 : 0
                  ) : (
                    (dashboardFilter.included.length || 0) + (dashboardFilter.excluded.length || 0)
                  )}
                </div>
                <Button
                  variant='text'
                  style={{ textTransform: 'capitalize' }}
                  onClick={(event) => {
                    event.stopPropagation()
                    dispatch({ type: 'clear' })
                  }}
                >
                  Clear
                </Button>
              </div>
            )}
          </div>
          {dashboardFilter.type !== 'calendar' && (
            <div>
              {dashboardFilter.included.map((value: string, index: number) => (
                <FilterChip
                  key={'included-' + index}
                  value={items ? items.find(i => i.value === value)?.label || value : value}
                  removeValue={() => {
                    removeFilter(value, false)
                  }}
                  changeValue={() => {
                    switchFilter(value, false)
                  }}
                  excludable={dashboardFilter.type === 'select'}
                  operator={dashboardFilter.type === 'range' ? (index === 0 ? '>=' : '<=') : undefined}
                />
              ))}
              {dashboardFilter.excluded.map((value: string, index: number) => (
                <FilterChip
                  key={'excluded-' + index}
                  value={items ? items.find(i => i.value === value)?.label || value : value}
                  removeValue={() => {
                    removeFilter(value, true)
                  }}
                  changeValue={() => {
                    switchFilter(value, true)
                  }}
                  excluded
                  excludable={dashboardFilter.type === 'select'}
                />
              ))}
            </div>
          )}
        </div>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        <div className={classes.filterValueList}>
          {expanded && (
            <DashboardFilterSelect
              search={search}
              setSearch={setSearch}
              filterType={dashboardFilter.type}
              fieldType={dashboardFilter.field}
              selected={dashboardFilter.included || []}
              setSelected={(newArray: any[]) => {
                handleEditFilter(newArray, false)
              }}
              excluded={dashboardFilter.excluded || []}
              setExcluded={(newArray: any[]) => {
                handleEditFilter(newArray, true)
              }}
              items={items}
              loadingItems={isLoading}
            />
          )}
        </div>
      </AccordionDetails>
    </Accordion>
  )
}
