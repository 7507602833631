import { useState, useEffect, useContext } from 'react'
import { Emitter, EVENT_TYPE } from '../helpers/EventEmitter'
import { Modal, ModalSection } from 'library/Modal'
import { TextBox } from 'library/materialUI/TextBox'
import { FlowBuilderContext } from '../FlowBuilderProvider'
import { handleSequence } from './convertToSequence'
import { Sequence } from 'classes/sequences'
import { handleFlowUpdates } from './updateCurrentFlow'

const SequenceState = {
  name: '',
  description: '',
  isReusable: true
}

export function SequenceModal () {
  const [modalOpen, setModalOpen] = useState(false)
  const [state, setState] = useState(SequenceState)
  const { toolkit, objectType } = useContext(FlowBuilderContext)

  useEffect(() => {
    const sequenceCallback = () => setModalOpen(true)
    Emitter.on(EVENT_TYPE.SEQUENCE_SELECTION, sequenceCallback)
    return () => {
      Emitter.remove(EVENT_TYPE.SEQUENCE_SELECTION, sequenceCallback)
    }
  })

  useEffect(() => {
    if (!modalOpen) {
      setState(SequenceState)
    }
  }, [modalOpen])

  const handleSave = () => {
    toolkit.removeFromSelection(toolkit.getNode('start'))
    const selection = toolkit.getSelection()
    const sequenceData = handleSequence(selection.getNodes())
    if (sequenceData) {
      const { nodeArray, outgoingPorts, edgeArray } = sequenceData
      const sequence = { ...state, edges: edgeArray, nodes: nodeArray, ports: outgoingPorts }
      Sequence.save({ sequence, objectType }).then((response: any) => {
        sequenceData.sequenceID = response.id
        sequenceData.description = state.description
        sequenceData.name = state.name
        handleFlowUpdates({ toolkit, context: sequenceData })
      })
    }
    setModalOpen(false)
  }

  const object = objectType === 'bots' ? 'Sequence' : 'Play'

  return (
    <Modal
      open={modalOpen}
      onHide={() => setModalOpen(false)}
      title={`${object} Configuration`}
      handleSave={handleSave}
      saveDisabled={!state.name}
    >
      <ModalSection
        title='Basic Details'
        subtitle={`Give your ${object.toLowerCase()} a name and description`}
      >
        <div style={{ marginBottom: 15 }}>
          <TextBox
            value={state.name}
            onChange={(value: string) => setState({ ...state, name: value })}
            label={`${object} title`}
          />
        </div>
        <TextBox
          value={state.description}
          onChange={(value: string) => setState({ ...state, description: value })}
          label={`${object} description`}
          rows={2}
        />
      </ModalSection>
    </Modal>
  )
}
