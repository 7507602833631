import React, { useState, useEffect } from 'react'
import { jsPlumbUtil } from 'jsplumbtoolkit'
import { ThemeProvider } from '@material-ui/core/styles'
import OptionPort from '../OptionPort'
import useNodeStyles from './NodeStyles'
import Divider from '@material-ui/core/Divider'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import CustomizedSnackbar from 'components/CustomizedSnackbar'
import { getContactLists } from 'api/crm_contacts'
import Typography from '@material-ui/core/Typography'
import { Button, Icon } from 'library/materialUI'
import {
  getIntegrationFeature,
  FEATURES
} from 'api/integrations'
import 'jsplumb'
import '../quill.css'
import { EVENT_TYPE, Emitter } from 'emitter'
import Picker from 'cf-components/Pickers'
import { Modal, ModalSection } from 'library/Modal'
import theme from 'theme'

function CreateContactModal (props) {
  const listID = props.listID
  const setListID = props.setListID

  if (!props.open) {
    return <></>
  }

  Emitter.on(EVENT_TYPE.BROWSER_BACK, props.onHide)

  return (
    <ThemeProvider theme={theme}>
      <Modal
        open={props.open}
        onHide={props.onHide}
        handleSave={() => {
          props.save({ listID })
        }}
        title='Create Contact'
        saveIcon='save'
        helplink='home/bots/nodes/AddToCampaign'
        helplinkLabel='Learn more about this skill'
      >
        <ModalSection
          subtitle='Select list to add the contact to after creation'
        >
          <div style={{ marginBottom: 15 }}>
            <Picker
              selection={listID}
              setSelection={(value) => setListID(value)}
              objectType='contactList'
              label='Select a campaign/list'
              loadAllProps={{ searchParams: { extraHeaders: { 'x-integration': props.integration } } }}
              queryBackend
            />
          </div>
          <div>
            <Typography variant='subtitle1'>
              If a list/campaign isn't selected, then the contact will still be added to your campaign enrollment integration,
              just not to a specific list/campaign
            </Typography>
          </div>
          <Button
            onClick={() => window.open('/#/settings/integrations/settings')}
            variant='text'
            style={{ textTransform: 'none' }}
          >
            <div style={{ display: 'flex' }}>
              <Icon icon='settings' size='sm' color='primary' />
              <div style={{ fontWeight: 'bold', marginLeft: 10 }}>Edit campaign enrollment integration</div>
            </div>
          </Button>
        </ModalSection>
      </Modal>
    </ThemeProvider>
  )
}

const CreateContactNode = props => {
  const base = props.base
  const classes = useNodeStyles(props)
  const [crmName, setCRMName] = useState('')
  const [lists, setLists] = useState([])
  const [currListID, setCurrListID] = useState(base?.state?.listID)
  const [snackState, setSnackState] = useState({
    open: false,
    variant: 'success',
    message: 'Your changes have been saved'
  })
  const currList = lists.find(list => list.value === currListID)

  const assignKey = data => {
    data._reactKey = data._reactKey || jsPlumbUtil.uuid()
    return data._reactKey
  }

  const saveChanges = ({ listID }) => {
    if (!base.toolkit) {
      return
    }
    const node = base.getNode()
    const newData =
    {
      ...base.state,
      listID: listID
    }
    base.toolkit.updateNode(node, newData)
    props.setModalOpen(false)
  }

  useEffect(() => {
    getIntegrationFeature(FEATURES.CRMENROLL)
      .then(response => {
        if (response.data.attributes.integration) {
          const integration = response.data.attributes.integration.name
          setCRMName(integration)
          getContactLists({ integration }).then((response) => {
            setLists(response.data.map((list) => ({ name: list.attributes.name, value: list.id })))
          }, (err) => {
            setSnackState({
              open: true,
              message: 'Failed to load contact lists',
              err: err.message
            })
          })
        }
      })
  }, [])
  return (
    <>
      <div>
        <div
          className={`${classes.dragger} ${classes.backgroundAction}`}
        >
          <div className='node-header'>
            <div className={classes.iconDiv}><PersonAddIcon /></div>
            <div className={classes.cardTitle}>Create Contact</div>
          </div>
          <div className='node-body'>
            <div>Create contact in {crmName || 'CRM'} and add to list {currList?.name || currListID}</div>
            <Divider style={{ marginTop: 5 }} />
            {base.state.ports.map((c, index) => {
              return (
                <OptionPort
                  index={index}
                  data={c}
                  label='Next'
                  key={assignKey(c)}
                  parent={base}
                  kind='backgroundAction'
                  type='create_contact'
                />
              )
            })}
          </div>
          <jtk-target
            scope='default'
          />
        </div>
        <jtk-target
          scope='default'
        />
      </div>
      <CreateContactModal
        open={props.modalOpen}
        onHide={() => props.setModalOpen(false)}
        save={saveChanges}
        listID={currListID}
        setListID={setCurrListID}
        lists={lists}
        integration={crmName}
      />
      <CustomizedSnackbar state={snackState} handler={setSnackState} />
    </>
  )
}

export default CreateContactNode
