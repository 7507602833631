import { makeStyles } from '@material-ui/core/styles'
import { ObjectAvatar } from 'cf-components/table/TableAvatars'
import { User } from 'classes/classes'
import Loader from 'library/loading/Loader'
import { Popper } from 'library/materialUI'
import { Icon } from 'library/materialUI/Icon'
import { useState } from 'react'
import { CompanyRow, CSVFileAttributes } from './CSVUploadModal'
import DisplayAccountOwners, { CompanyRowWithPreviousOwner } from './DisplayAccountOwners'

const useStyles = makeStyles((theme) => ({
  acocuntOwnerObjAvatar: {
    marginLeft: 'auto',
    width: '100%',
    marginRight: 25,
    '& a>div>div:nth-child(2)': {
      whiteSpace: 'nowrap'
    }
  },
  listItemContainer: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '33px 275px 26px 275px 70px'
  },
  companyContainer: {
    maxWidth: '290px',
    minWidth: '290px',
    '& div:nth-child(2)>h3': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      width: 170
    }
  },
  actionIcons: {
    cursor: 'pointer',
    margin: 5
  }
}))

interface CompanyItemProps {
  company: CompanyRow
  index: number
  // setCSVFileAttributes: ({ overrides, rowsWithConflicts } : { overrides?: CSVFileAttributes['overrides'], rowsWithConflicts: CSVFileAttributes['rowsWithConflicts'] }) => void
  overrides: boolean
  rowsWithConflicts: CSVFileAttributes['rowsWithConflicts']
  // handleAccountOwnerChange: (row: CompanyRow) => void
}

export default function CompanyItem ({ company, index, rowsWithConflicts, overrides }: CompanyItemProps) {
  const classes = useStyles()
  const { data: user, isLoading } = User.loadOne(company.owner.id)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const companyRequiresOverride = rowsWithConflicts[company.name] !== undefined
  const conflictHasBeenHandled = companyRequiresOverride ? rowsWithConflicts[company.name].conflict : undefined

  if (isLoading) {
    return (
      <Loader type='spinner' />
    )
  }
  const handleEnter = (e: any) => {
    setAnchorEl(e.currentTarget)
  }

  const handleLeave = () => {
    setAnchorEl(null)
  }

  const conflictIconKey = `${index}_conflictIcon`
  const conflictIcon = companyRequiresOverride ? !conflictHasBeenHandled
    ? (<div key={conflictIconKey}> <Icon icon='checkCircle' color='green' /> </div>)
    : (<div key={conflictIconKey}> <Icon icon='warning' color='#F4D451' /> </div>)
    : <span />

  const containerProps = company.previous_owner ? {
    onMouseEnter: handleEnter,
    onMouseLeave: handleLeave
  } : {}

  return (
    <div {...containerProps} className={classes.listItemContainer} key={`compaylistitem_${index}_${company.name}`}>
      <Popper
        open={Boolean(anchorEl) && company.owner.id !== company?.previous_owner?.id}
        anchorEl={anchorEl}
        placement='left'
        key={`poppper_${index}_${company.name}`}
        style={{ zIndex: 1500 }}
      >
        <DisplayAccountOwners
          row={company as CompanyRowWithPreviousOwner}
          overrides={overrides}
        />
      </Popper>
      {conflictIcon}
      <div className={classes.companyContainer}>
        <ObjectAvatar
          key={`${index}_${company.name}`}
          row={company}
        />
      </div>
      <span />
      <div className={classes.acocuntOwnerObjAvatar}>
        <ObjectAvatar
          key={`${index}_account_owner`}
          row={user}
          type='User'
        />
      </div>
    </div>
  )
}
