import React from 'react'
import DashboardCard from './DashboardCard'
import FullscreenIcon from '@material-ui/icons/Fullscreen'
import PieChart from './PieChart'

function PieChartCard (props) {
  const actions = [
    { name: 'See Full Screen Chart', action: () => props.onFullScreen(), icon: FullscreenIcon }
  ]
  return (
    <DashboardCard
      title={props.title}
      actions={actions}
      noScrollbox
    >
      <div style={{ height: 242 }}>
        <PieChart
          id={props.id}
          data={props.data}
        />
      </div>
    </DashboardCard>
  )
}

export default PieChartCard
