import { makeStyles } from '@material-ui/core/styles'
import { PageHeader, Tab, headerHeight, PageAction, PageTitle } from './PageHeader'

const useStyles = makeStyles(theme => ({
  singlePageContent: {
    height: `calc(100% - ${headerHeight + 1}px)`,
    position: 'relative',
    marginTop: 1
  }
}))

export interface PageProps {
  title: PageTitle,
  tabs: Tab[],
  subtitle?: string,
  action?: PageAction,
  back?: () => any,
  children: any
}

export function Page (props: PageProps): JSX.Element {
  const classes = useStyles()
  return (
    <>
      <PageHeader
        title={props.title}
        subtitle={props.subtitle}
        tabs={props.tabs}
        action={props.action}
        back={props.back}
      />
      <div className={classes.singlePageContent}>
        {props.children}
      </div>
    </>
  )
}
