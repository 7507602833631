import React, { useState, useEffect } from 'react'
import { makeStyles, ThemeProvider } from '@material-ui/core/styles'
import { jsPlumbUtil } from 'jsplumbtoolkit'
import { getTags, saveTag } from 'api/tags'
import OptionPort from '../OptionPort'
import Divider from '@material-ui/core/Divider'
import useNodeStyles from './NodeStyles'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemText from '@material-ui/core/ListItemText'
import Select from '@material-ui/core/Select'
import Checkbox from '@material-ui/core/Checkbox'
import Chip from '@material-ui/core/Chip'
import TagIcon from '@material-ui/icons/LocalOffer'
import '../quill.css'
import { Tooltip } from '@material-ui/core'
import ConversationTagsModal from 'pages/settings/Tags/ConversationTagsModal'
import FormControl from '@material-ui/core/FormControl'
import { EVENT_TYPE, Emitter } from 'emitter'
import { Modal, ModalSection } from 'library/Modal'
import { Icon } from 'library/materialUI'
import theme from 'theme'

const useStyles = makeStyles(theme => ({
  subtitle: {
    color: '#999',
    fontFamily: 'Poppins, sans serif',
    fontSize: '12px'
  },
  divider: {
    marginTop: 10,
    marginBottom: 10
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: -4,
    fontFamily: 'Poppins'
  },
  chip: {
    marginRight: 8,
    marginTop: 4,
    backgroundColor: '#d3d3d3',
    color: 'black',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  input: {
    padding: 6,
    borderRadius: 30
  },
  inputRoot: {
    borderRadius: 30
  },
  select: {
    minHeight: 32,
    borderRadius: 30
  },
  plusIcon: {
    marginLeft: '15px',
    cursor: 'pointer'
  }
}))

function DeletableChip (props) {
  const { onDelete, value } = props
  return (
    onDelete ? (
      <Chip
        value={value}
        deleteIcon={
          <div onMouseDown={(event) => {
            if (!props.disabled && event.button === 0) {
              event.stopPropagation()
              onDelete(value)
            }
          }}
          >
            <Icon icon='cancel' size='md' />
          </div>
        }
        {...props}
      />
    ) : (<Chip {...props} />)
  )
}

function TagModal (props) {
  const classes = useStyles()
  const [tags, setTags] = useState(props.tags)
  const [tenantTags, setTenantTags] = useState(props.tenantTags)
  const [tenantTagDict, setTenantTagDict] = useState(props.tenantTagDict)

  useEffect(() => {
    setTags(props.tags)
  }, [props.tags])

  useEffect(() => {
    setTenantTags(props.tenantTags)
  }, [props.tenantTags])

  useEffect(() => {
    setTenantTagDict(props.tenantTagDict)
  }, [props.tenantTagDict])

  const ITEM_HEIGHT = 40
  const ITEM_PADDING_TOP = 8
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 5.5 + ITEM_PADDING_TOP,
        width: 250
      }
    }
  }

  function cancelChanges () {
    props.onHide()
    setTags(props.tags)
  }

  const handleChange = (event) => {
    setTags(event.target.value)
  }

  const handleDelete = (value) => {
    const currentTags = [...tags]
    const index = currentTags.indexOf(value)
    if (index !== -1) {
      currentTags.splice(index, 1)
      setTags(currentTags)
    }
  }

  if (!tenantTagDict || !props.open) {
    return <></>
  }

  Emitter.on(EVENT_TYPE.BROWSER_BACK, cancelChanges)

  return (
    <ThemeProvider theme={theme}>
      <Modal
        open={props.open}
        onHide={cancelChanges}
        handleSave={() => props.save(tags)}
        title='Tag Conversation'
        helplink='home/bots/nodes/TagConversation'
        helplinkLabel='Learn more about this skill'
        saveIcon='save'
      >
        <ModalSection
          subtitle='Apply tags to the conversation for use in filtering'
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ width: '100%' }}>
              <FormControl variant='outlined' fullWidth>
                <Select
                  id='tagSelector'
                  style={{ borderRadius: 4, backgroundColor: 'white' }}
                  placeholder='Select tag(s)'
                  multiple
                  fullWidth
                  variant='outlined'
                  value={tags}
                  onChange={handleChange}
                  margin='dense'
                  MenuProps={MenuProps}
                  renderValue={(selected) => (
                    <div className={classes.chips}>
                      {selected.map((value) => (
                        <DeletableChip
                          key={value}
                          label={tenantTagDict[value] ? tenantTagDict[value].tag_name : null}
                          className={classes.chip}
                          onDelete={handleDelete}
                          value={value}
                        />
                      ))}
                    </div>
                  )}
                >
                  <MenuItem disabled value=''>
                    <em>-- Choose Tags To Apply --</em>
                  </MenuItem>
                  {tenantTags.map((tag) => (
                    <MenuItem key={tag.id} value={tag.id} style={{ height: 40 }}>
                      <Checkbox checked={tags.indexOf(tag.id) > -1} color='primary' />
                      <ListItemText primary={tag.attributes.tag_name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <Tooltip title='Create a new tag'>
              <div onClick={props.handleOpenNewTagModal} className={classes.plusIcon}>
                <Icon icon='addBox' size='md' />
              </div>
            </Tooltip>
          </div>
        </ModalSection>
      </Modal>
    </ThemeProvider>
  )
}

function RenderTags (props) {
  const chipClasses = useStyles()
  if (!props.tenantTagDict) {
    return <div>Loading...</div>
  }
  if (props.tags.length === 0) {
    return <div>Add Tags...</div>
  } else {
    return (
      <div className={chipClasses.chips} style={{ minHeight: 25 }}>
        {props.tags.map((value) => (
          <Chip
            key={value}
            color='primary'
            label={props.tenantTagDict[value] ? props.tenantTagDict[value].tag_name : null}
            className={chipClasses.chip}
          />
        ))}
      </div>
    )
  }
}

const TagNode = props => {
  const [chatServiceUrl, setServiceUrl] = useState(null)
  const [tenantTags, setTenantTags] = useState([])
  const [tenantTagDict, setTenantTagDict] = useState(null)
  const [createTagModalOpen, setCreateTagModalOpen] = useState(false)
  const [modalState, setModalState] = useState({
    tag_name: '',
    description: '',
    is_goal: false
  })
  const [token, setToken] = useState(null)
  const base = props.base
  const classes = useNodeStyles(props)

  const handleCreateTag = (tag_name, description, is_goal, id = null) => {
    saveTag({ tag_name, description, is_goal, token, chatServiceUrl, id }).then(response => {
      if (token && chatServiceUrl) {
        getTags({ token, chatServiceUrl }).then(response => {
          setTenantTags(response.data)
          let tagDict = {}
          response.data.map(
            tag => (tagDict = { ...tagDict, [tag.id]: tag.attributes })
          )
          setTenantTagDict(tagDict)
        })
        setCreateTagModalOpen(false)
        setModalState({
          tag_name: '',
          description: '',
          is_goal: true
        })
      }
    })
  }

  const openCreateTagModal = () => {
    setCreateTagModalOpen(true)
  }

  useEffect(() => {
    const headers = {
      'Content-Type': 'application/vnd.api+json'
    }
    fetch('/api/auth/token', {
      method: 'GET',
      headers: headers
    })
      .then(response => response.json())
      .then(account_response => {
        setToken(account_response.token)
      })
  }, [])

  useEffect(() => {
    fetch('/api/auth/me', {
      method: 'GET',
      headers: { 'Content-Type': 'application/vnd.api+json' }
    })
      .then(response => response.json())
      .then(response => {
        if (response.data && response.data.id) {
          setServiceUrl(response.data.links.chat_service || 'localhost:8002/api/chat-service/a')
        }
      })
  }, [])

  useEffect(() => {
    if (token && chatServiceUrl) {
      getTags({ token, chatServiceUrl }).then(response => {
        setTenantTags(response.data)
        let tagDict = {}
        response.data.map(
          tag => (tagDict = { ...tagDict, [tag.id]: tag.attributes })
        )
        setTenantTagDict(tagDict)
      })
    }
  }, [token, chatServiceUrl])

  const assignKey = data => {
    data._reactKey = data._reactKey || jsPlumbUtil.uuid()
    return data._reactKey
  }

  const saveChanges = (tags) => {
    if (!base.toolkit) {
      return
    }
    const node = base.getNode()
    const new_data = { ...base.state, tags: tags }
    base.toolkit.updateNode(node, new_data)
    props.setModalOpen(false)
  }

  return (
    <>
      <div>
        <div
          className={`${classes.dragger} ${classes.backgroundAction}`}
        >
          <div className='node-header'>
            <div className={classes.iconDiv}><TagIcon /></div>
            <div className={classes.cardTitle}>Tag Conversation</div>
          </div>
          <div className='node-body'>
            <RenderTags tags={base.state.tags} tenantTagDict={tenantTagDict} />
            <Divider style={{ marginTop: 5 }} />
            <div>
              {base.state.ports.map((c, index) => {
                return (
                  <OptionPort
                    index={index}
                    data={c}
                    key={assignKey(c)}
                    parent={base}
                    kind='backgroundAction'
                    label='Next'
                  />
                )
              })}
            </div>
          </div>
          <jtk-target
            scope='default'
          />
        </div>
      </div>
      <TagModal
        open={props.modalOpen}
        onHide={() => props.setModalOpen(false)}
        save={saveChanges}
        tags={base.state.tags}
        tenantTagDict={tenantTagDict}
        tenantTags={tenantTags}
        handleOpenNewTagModal={openCreateTagModal}
      />
      <ConversationTagsModal
        show={createTagModalOpen}
        state={modalState}
        onChange={setModalState}
        saveTag={handleCreateTag}
        onHide={() => setCreateTagModalOpen(false)}
      />

    </>
  )
}

export default TagNode
