import { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import RateReviewIcon from '@material-ui/icons/RateReview'
import Tooltip from '@material-ui/core/Tooltip'
import { SavedReplyModal } from 'pages/settings/SavedReplies/ReplyModal'
import SavedReply from 'classes/savedReplies'

const useStyles = makeStyles(theme => ({
  iconDiv: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 20
  },
  controlButton: {
    color: '#999999',
    cursor: 'pointer',
    height: '1em',
    '&:hover, &.valid-message': {
      fill: theme.palette.primary.main
    }
  }
}))

type ButtonProps = {
  message: string
  buttonFill: string
}
export default function SaveReplyButton (props: ButtonProps): JSX.Element {
  const classes = useStyles()
  const [editReply, setEditReply] = useState <SavedReply | null>(null)
  const { data: replies } = SavedReply.loadAll()
  const { message, buttonFill } = props

  return (
    <>
      <div id='saved_replies' className={classes.iconDiv}>
        <Tooltip title='Save reply'>
          <RateReviewIcon
            className={classes.controlButton}
            onClick={() => setEditReply(new SavedReply({ content: message }))}
            style={{ fill: buttonFill }}
          />
        </Tooltip>
      </div>
      {editReply &&
        <SavedReplyModal
          reply={editReply}
          onHide={() => setEditReply(null)}
          categories={replies.categories}
        />}
    </>
  )
}
