import { makeStyles } from '@material-ui/core/styles'
import { Card, Popper } from '@material-ui/core'
import Loader from 'library/loading/Loader'
import EmptyState from '../EmptyState'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import { CardContents, Fields } from './CardContents'
import { Actions } from './ActionsComponent'
import { StatsTooltip } from 'pages/admin_portal/StatsTooltip'
import { useState } from 'react'
import PaginationCard from './PaginationCard'

const useStyles = makeStyles(theme => ({
  card: {
    marginBottom: 12,
    padding: 15,
    display: 'grid',
    alignItems: 'center'
  },
  minimalCard: {
    alignItems: 'center',
    padding: 4,
    display: 'grid'
  },
  loadingDiv: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 70,
    height: 90
  },
  cardList: {
    padding: '0px 20px 20px 20px'
  },
  headerClass: {
    display: 'grid',
    padding: '5px 15px'
  },
  headerDiv: {
    display: 'flex'
  }
}))

interface HeaderProps<Class> {
  fields: Fields<Class>
  style: any
  state: any
  dispatch: any
}

function CardListHeader<Class> (props: HeaderProps<Class>): JSX.Element {
  const classes = useStyles()

  if (!props.fields) {
    return <></>
  }

  const Icon = props.state.sortAscending ? ArrowUpwardIcon : ArrowDownwardIcon
  const handleClick = (sortID: any): void => {
    if (sortID) {
      props.dispatch({ type: 'sort', value: sortID })
    }
  }

  return (
    <div className={classes.headerClass} style={props.style}>
      {props.fields.map((field, index) => {
        const sort = field.sort !== false
        return (
          <div
            key={index}
            className={classes.headerDiv}
            style={{ cursor: sort ? 'pointer' : 'auto' }}
            onClick={sort ? () => {
              if (field.sortField) {
                return handleClick(field.sortField)
              }
              return handleClick(field.name)
            } : undefined}
          >
            {field.label}
            <Icon
              fontSize='small'
              style={{
                marginLeft: 5,
                fill: '#7c7c7c',
                visibility: (props.state.sortCriteria === field.name) ? 'visible' : 'hidden'
              }}
            />
          </div>
        )
      })}
    </div>
  )
}

interface CardListProps<Class> {
  rows: Class[]
  columnStyles: string
  fields: Fields<Class>
  state: any
  dispatch: any
  actions?: Actions<Class>
  isLoading?: boolean
  variant?: 'minimal'
  actionPopRef?: any
  hoverPopover?: any
}

export function CardList<Class = Record<string, any>> (props: CardListProps<Class>): JSX.Element {
  const classes = useStyles()
  const { pageNumber, rowsPerPage } = props.state
  const fields = props.fields
  const style = { gridTemplateColumns: props.columnStyles }
  const length = props.rows.length
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)
  const [placement, setPlacement] = useState(0)

  if (props.isLoading) {
    return (
      <div className={classes.loadingDiv}>
        <Loader type='spinner' />
      </div>
    )
  }

  if (length) {
    if (props.variant) {
      return (
        <div>
          {props.rows.slice(0, 10).map((row: any) => {
            return (
              <Card
                key={row.id}
                className={classes.minimalCard}
                style={style}
                onClick={(e) => {
                  if (props.actionPopRef) {
                    props.actionPopRef.current = e.currentTarget.lastElementChild
                  }
                }}
              >
                <CardContents
                  row={row}
                  fields={fields}
                  actions={props.actions}
                />
              </Card>
            )
          })}
        </div>
      )
    } else {
    const rows = props.rows.slice((pageNumber - 1) * rowsPerPage, pageNumber * rowsPerPage)
    return (
      <div style={{ paddingTop: 10 }}>
        <CardListHeader
          fields={fields}
          style={style}
          state={props.state}
          dispatch={props.dispatch}
        />
        <div>
          {rows.map((row: any) => {
            return (
              <Card
                key={row.id}
                className={classes.card}
                style={style}
                onClick={(e) => {
                  if (props.actionPopRef) {
                    props.actionPopRef.current = e.currentTarget.lastElementChild
                  }
                }}
                onMouseEnter={(e) => {
                  if (props.hoverPopover) {
                    setAnchorEl(e.currentTarget)
                    setPlacement(row.id)
                  }
                }}
                onMouseLeave={() => {
                  if (props.hoverPopover) {
                    setAnchorEl(null)
                    setPlacement(0)
                  }
                }}
              >
                <CardContents
                  row={row}
                  fields={fields}
                  actions={props.actions}
                />
                {props.hoverPopover ? (
                  <Popper
                    id={row.id}
                    open={Boolean(row.id === placement)}
                    anchorEl={anchorEl}
                    placement='left'
                    style={{ paddingRight: 5 }}
                  >
                    <StatsTooltip
                      id={row.id}
                      stats={props.hoverPopover[row.id]}
                    />
                  </Popper>
                ) : <></>}
              </Card>
            )
          })}
        </div>
        <PaginationCard
          totalCount={length}
          state={props.state}
          dispatch={props.dispatch}
        />
      </div>
      )
    }
  }

  return (
    <div style={{ marginTop: 100 }}>
      <EmptyState
        message='No Records Found'
        sad
      />
    </div>
  )
}
