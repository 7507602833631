/* eslint-disable @typescript-eslint/naming-convention */
import { Popover, Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CustomSounds from 'classes/customSounds'
import User from 'classes/users'
import { reducer } from 'library/helpers'
import { Icon } from 'library/materialUI'
import { Sound, SoundType } from 'library/sounds/sound'
import { useCallback, useContext, useEffect, useReducer, useState } from 'react'
import { SessionContext } from 'session-context'

const useStyles = makeStyles(theme => ({
  main: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    cursor: 'pointer',
    color: 'white',
    opacity: 1,
    '&:hover': {
      opacity: 0.8
    }
  },
  paper: {
    borderRadius: 10
  },
  menu: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: 300,
    height: 300,
    padding: 15
  },
  menuRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 10,
    paddingRight: 10
  },
  menuLeftColumn: {
    display: 'flex',
    alignItems: 'center'
  },
  menuPlay: {
    marginLeft: 5,
    cursor: 'pointer'
  },
  menuMute: {
    cursor: 'pointer',
    color: 'rgba(0, 0, 0, 0.54)',
    '&:hover': {
      color: theme.palette.primary.main
    }
  }
}))

interface MuteOption {
  label: string
  sound: SoundType
  isMuted: boolean
}

const muteOptions: Record<string, MuteOption> = {
  'first-visit': {
    label: 'First-time Visitor',
    sound: 'simple',
    isMuted: false
  },
  'return-visit': {
    label: 'Returning Visitor',
    sound: 'decorativeOne',
    isMuted: false
  },
  'high-intent-page-visit': {
    label: 'Visit on High Intent page',
    sound: 'decorativeTwo',
    isMuted: false
  },
  'visitor-id': {
    label: 'Visitor Identified',
    sound: 'twinkle',
    isMuted: false
  },
  'email-visitor': {
    label: 'Visitor from Signals email',
    sound: 'register',
    isMuted: false
  },
  'conversation-started': {
    label: 'Conversation Started',
    sound: 'trumpet',
    isMuted: false
  },
  'live-chat': {
    label: 'Live Chat Started',
    sound: 'levelUp',
    isMuted: false
  },
  'calendar-drop': {
    label: 'Calendar Dropped',
    sound: 'gameShow',
    isMuted: false
  },
  'meeting-booked': {
    label: 'Meeting Booked',
    sound: 'gong',
    isMuted: false
  }
}

interface MuteMenuProps {
  muteOptions: Record<string, MuteOption>
  onMute: (muteOption: string, isMuted: boolean) => void
}

function MuteMenu ({ muteOptions, onMute }: MuteMenuProps): JSX.Element {
  const classes = useStyles()
  const [playing, setPlaying] = useState('')

  return (
    <div className={classes.menu}>
      {Object.entries(muteOptions).map(([key, muteOption]) => (
        <div key={key} className={classes.menuRow}>
          <div className={classes.menuLeftColumn}>
            {muteOption.label}
            <Tooltip
              title={'Preview ' + muteOption.label}
              placement='top'
            >
              <div className={classes.menuPlay}>
                <Icon
                  icon={(playing === key) ? 'pause' : 'playArrow'}
                  onClick={() => {
                    setPlaying(key)
                    Sound({ sound: muteOption.sound, onEnd: () => setPlaying('') })
                  }}
                />
              </div>
            </Tooltip>
          </div>
          <div className={classes.menuMute}>
            <Icon
              icon={muteOption.isMuted ? 'volumeOff' : 'volumeUp'}
              color={muteOption.isMuted ? 'currentColor' : 'primary'}
              onClick={() => onMute(key, !muteOption.isMuted)}
            />
          </div>
        </div>
      ))}
    </div>
  )
}

export default function Mute (): JSX.Element {
  const classes = useStyles()
  const { userObject } = useContext(SessionContext)
  const [state, dispatch] = useReducer(reducer, userObject.notificationSettings?.live_view_map || muteOptions)
  const isMuted = Object.values(state).every(muteOption => muteOption.isMuted)
  const [isExpanded, setIsExpanded] = useState(false)
  const { data: sounds, isLoading } = CustomSounds.loadCustomSounds()

  const updateUser = useCallback(() => {
    const notificationSettings = { ...userObject.notificationSettings }
    notificationSettings.live_view_map = state
    User.update(userObject.id, { notificationSettings }, true)
  }, [state, userObject.id, userObject.notificationSettings])

  const muteAll = (isMuted: boolean) => {
    const nState = { ...state }
    Object.keys(nState).forEach(key => {
      nState[key].isMuted = isMuted
    })
    dispatch(nState)
  }

  useEffect(() => {
    if (state !== userObject.notificationSettings?.live_view_map) {
      updateUser()
    }
  }, [state, updateUser, userObject.notificationSettings])

  useEffect(() => {
    if (!sounds || !sounds?.list[0] || isLoading) {
      return
    }
    const temp = { ...state }
    Object.keys(temp).forEach(key => {
      const soundList = sounds?.list[0]
      if (key === 'first-visit') {
        temp[key].sound = soundList.firstTimeVisitorSound.link_url
      } else if (key === 'return-visit') {
        temp[key].sound = soundList.returningVisitorSound.link_url
      } else if (key === 'high-intent-page-visit') {
        temp[key].sound = soundList.highIntentPageVisitorSound.link_url
      } else if (key === 'visitor-id') {
        temp[key].sound = soundList.visitorIdentifiedSound.link_url
      } else if (key === 'email-visitor') {
        temp[key].sound = soundList.visitorFromEmailSound.link_url
      } else if (key === 'conversation-started') {
        temp[key].sound = soundList.conversationStartedSound.link_url
      } else if (key === 'live-chat') {
        temp[key].sound = soundList.liveChatStartedSound.link_url
      } else if (key === 'calendar-drop') {
        temp[key].sound = soundList.calendarDropSound.link_url
      } else if (key === 'meeting-booked') {
        temp[key].sound = soundList.meetingBookedSound.link_url
      }
    })
    dispatch(temp)
    // eslint-disable-next-line
  }, [sounds])

  if (isLoading) {
    return <></>
  }

  return (
    <>
      <div
        id='mute-menu'
        className={classes.main}
        onClick={(_: any) => {
          setIsExpanded(!isExpanded)
        }}
      >
        <Tooltip title={isMuted ? 'Unmute All' : 'Mute All'} placement='bottom'>
          <div>
            <Icon
              icon={isMuted ? 'volumeOff' : 'volumeUp'}
              color='currentColor'
              onClick={(e: any) => {
                e.stopPropagation()
                muteAll(!isMuted)
              }}
            />
          </div>
        </Tooltip>
        <Icon icon='expand' color='currentColor' />
      </div>
      {isExpanded && (
        <Popover
          open={isExpanded}
          anchorEl={document.getElementById('mute-menu')}
          onClose={() => setIsExpanded(false)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          classes={{ paper: classes.paper }}
        >
          <MuteMenu
            muteOptions={state}
            onMute={(muteOption: string, isMuted: boolean) => {
              dispatch({ [muteOption]: { ...state[muteOption], isMuted } })
            }}
          />
        </Popover>
      )}
    </>
  )
}
