import { nodeMappingData } from 'library/flowBuilder/FlowBuilder'
import { ModalContentsProps } from 'library/flowBuilder/nodes/NodeModal'
import { categoryMapping } from '../nodeCategories'
import { branchingReducer } from '../../branching/reducer'
import { BranchingModal, BranchingNodeSchema } from '../../branching/BranchingModal'

function SegmentBranchingModal ({ state, dispatch }: ModalContentsProps): JSX.Element {
  return (
    <BranchingModal
      state={state}
      dispatch={dispatch}
      branchType='segment'
      subtitle='Use these filter criteria to create branching visitor experiences. A visitor must match all selected criteria in order to follow a branch.'
    />
  )
}

function BranchingBody (): JSX.Element {
  return (
    <div>
      Branch on company segment
    </div>
  )
}

export const segmentBranchingMapping: nodeMappingData = {
  icon: 'pieChart',
  title: 'Company Segment',
  body: BranchingBody,
  modal: SegmentBranchingModal,
  modalReducer: branchingReducer,
  modalSize: 'md',
  schema: BranchingNodeSchema,
  helpCenterLink: 'home/plays/skills/SegmentBranching',
  ...categoryMapping.branching
}
