import { track } from 'userpilot.js'
import { cleanFlow } from 'pages/bot/flowCleanup'
import { doPost, doFetch } from 'api/api'

const formToFlowSingleTeam = form => {
  const nodes = []
  const edges = []
  const ports = []

  let port
  let edge

  port = { id: 'default' }
  ports.push(port)
  edge = {
    data: {
      id: 'start-default',
      type: 'common'
    },
    source: 'start.default',
    target: 'a'
  }
  edges.push(edge)
  nodes.push({
    ports: [
      port
    ],
    id: 'start',
    type: 'start'
  })

  port = { id: 'default' }
  ports.push(port)
  edge = {
    data: {
      id: 'a-default',
      type: 'common'
    },
    source: 'a.default',
    target: 'b'
  }
  edges.push(edge)
  nodes.push({
    ports: [
      port
    ],
    id: 'a',
    body: form.welcome_message,
    type: 'text_capture'
  })

  const team_method = form.teams[0].method

  let save_method
  if (team_method === 'round_robin') {
    save_method = 'round_robin_team'
  } else {
    save_method = team_method
  }

  nodes.push({
    ports: [
      { id: 'away' },
      { id: 'available' }
    ],
    id: 'b',
    body: form.teams[0].welcome,
    away_body: form.teams[0].away,
    type: 'route',
    method: save_method,
    team_id: form.teams[0].team_id,
    user_ids: form.teams[0].user_ids
  })

  const flow = {
    nodes: nodes,
    edges: edges,
    ports: ports
  }
  return flow
}

const formToFlowAbe = form => {
  const nodes = []
  const edges = []
  const ports = []

  let port
  let edge

  port = { id: 'default' }
  ports.push(port)
  edge = {
    data: {
      id: 'start-default',
      type: 'common'
    },
    source: 'start.default',
    target: 'a'
  }
  edges.push(edge)
  nodes.push({
    ports: [
      port
    ],
    id: 'start',
    type: 'start'
  })

  port = { id: 'default' }
  ports.push(port)
  edge = {
    data: {
      id: 'a-default',
      type: 'common'
    },
    source: 'a.default',
    target: 'b'
  }
  edges.push(edge)
  nodes.push({
    ports: [
      port
    ],
    id: 'a',
    body: form.welcome_message,
    type: 'text_capture'
  })

  nodes.push({
    ports: [
      { id: 'away' },
      { id: 'available' }
    ],
    id: 'b',
    body: '',
    away_body: '',
    type: 'route',
    method: 'account_owner',
    team_id: null,
    user_ids: []
  })

  const flow = {
    nodes: nodes,
    edges: edges,
    ports: ports
  }
  return flow
}

const formToFlow = form => {
  const options = []
  const team_options_router_edges = {}
  const nodes = []
  const edges = []
  const ports = []

  if (form.teams.length === 1) {
    return formToFlowSingleTeam(form)
  }
  if (form.teams.length === 0) {
    return null
  }

  nodes.push({
    ports: [
      { id: 'default' }
    ],
    id: 'start',
    type: 'start'
  })
  ports.push({ id: 'default' })
  edges.push({
    data: {
      id: 'start-default',
      type: 'common'
    },
    source: 'start.default',
    target: 'team_router'
  })

  const team_options_ports = []

  for (let i = 0; i < form.teams.length; i++) {
    const item = form.teams[i]
    const save_method = item.method

    options.push({
      label: item.label,
      value: item.label,
      edge: '' + i
    })
    team_options_router_edges[i] = '' + i
    const option_node_id = 'option_' + i
    nodes.push({
      id: option_node_id,
      type: 'route',
      team_id: item.team_id,
      user_ids: item.user_ids,
      method: save_method,
      body: item.welcome,
      away_body: item.away,
      ports: [
        {
          id: 'away'
        },
        {
          id: 'available'
        }
      ]
    })
    team_options_ports.push({
      id: '' + i,
      label: item.label,
      value: item.label
    })
    edges.push({
      data: {
        id: 'team-router-' + i,
        type: 'common'
      },
      source: 'team_router.' + i,
      target: option_node_id
    })
  }

  nodes.push({
    id: 'team_router',
    type: 'options',
    body: form.welcome_message,
    buttons: options,
    ports: team_options_ports,
    responseType: 'buttons'
  })
  ports.push({ id: 'default' })
  edges.push({
    data: {
      id: 'start-default',
      type: 'common'
    },
    source: 'start.default',
    target: 'team_router'
  })

  const flow = {
    nodes: nodes,
    edges: edges,
    ports: ports
  }
  return flow
}

function saveBot ({ values, formikProps, botID, flow, builder_data }) {
  // TODO: convert the values.teams into a flow object that the bot can read
  track('Bot Saved')
  const template = {
    kind: values.kind || 'team_live_chat_router',
    teams: values.teams,
    welcome_message: values.welcome_message,
    availability_criteria: values.availability_criteria,
    bot_delay: values.bot_delay,
    url_matches: values.url_matches
  }

  let newFlow

  if (template.kind === 'flow' || template.kind === 'content_page') {
    newFlow = builder_data
  } else if (template.kind === 'abe_greeting') {
    newFlow = { data: formToFlowAbe(values) }
  } else {
    newFlow = { data: formToFlow(values) }
  }

  let old_flow_id = null
  if (flow && flow.id) {
    old_flow_id = flow.id
  }

  for (const t of values.triggers) {
    const delays = t.filters.filter(f => f.prop === 'delay').map(f => f.value)
    delays.push(0)
    const maxDelay = Math.max(...delays)
    t.cause.value = maxDelay
  }

  const targeting = {
    use_triggers: values.use_triggers,
    trigger_on: values.trigger_on,
    triggers: values.triggers.filter(t => t.filters.length > 0)
  }

  const saveBody = {
    type: 'flows',
    attributes: {
      template: template,
      prompt_style: values.prompt_style,
      mobile_prompt_style: values.mobile_prompt_style,
      targeting: targeting,
      flow: cleanFlow(newFlow),
      participant: {
        agent_name: values.participant_agent_name,
        avatar_url: values.participant_avatar_url
      },
      participant_id: values.participant_id,
      participant_selection_method: values.participant_selection_method,
      frequency: values.frequency || 'every time'
    },
    relationships: {
      previous_version: {
        data: {
          type: 'flows',
          id: old_flow_id
        }
      },
      belongs_to: {
        data: {
          type: 'bots',
          id: botID
        }
      }
    }
  }

  const bot_attributes = {
    id: botID,
    type: 'bots',
    attributes: {
      name: values.name,
      kind: values.kind,
      fragment: values.fragment,
      click_selector: values.click_selector,
      enabled: values.enabled
    }
  }

  let content_page_attributes = {}

  if (values.is_content_page) {
    content_page_attributes = {
      id: values.content_page_id,
      type: 'content_landing_pages',
      attributes: {
        title: values.page_title,
        background_color: values.background_color,
        background_type: values.background_type,
        secondary_color: values.secondary_color,
        bot_only: values.bot_only,
        use_logo_url: values.use_logo_url,
        logo_url: values.logo_url,
        qr_code: values.qr_code,
        pdf_content: values.pdf_content,
        logo_image: values.logo_image,
        theme: values.theme,
        page_name: values.page_name
      }
    }
  }

  let path = '/bots'
  let method = 'POST'
  if (botID) {
    method = 'PATCH'
    path += `/${botID}`
  }
  return doFetch({
    path,
    method,
    useChatURL: true,
    data: bot_attributes
  })
    .then(bot_response => {
      if (bot_response && bot_response.data && bot_response.data.id) {
        saveBody.relationships.belongs_to.data.id = bot_response.data.id
      }

      if (values.is_content_page) {
        content_page_attributes.attributes.bot_id = bot_response.data.id
        // Create content page
        let path = '/content_pages'
        let method = 'POST'
        if (values.content_page_id) {
          method = 'PATCH'
          path += `/${values.content_page_id}`
        }
        doFetch({
          path,
          useChatURL: true,
          method: method,
          data: content_page_attributes
        })
      }

      return doPost({ useChatURL: true, path: '/flows', data: saveBody })
        .then(response => {
          formikProps.setSubmitting(false)
          return response.data.id
        }).then((flow_id) => {
          bot_response.data.attributes.flow_id = flow_id
          return bot_response
        })
    })
};

export { saveBot }
