import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import * as am4plugins_timeline from '@amcharts/amcharts4/plugins/timeline'
import * as am4plugins_bullets from '@amcharts/amcharts4/plugins/bullets'
import { botHead, siteVisit, liveChat, newContact, meeting, opportunity } from './ChartIcons'
import dateFormat from 'dateformat'
import { getSummary } from './ActivityHelpers'

const activityIcons = {
  opportunity: {
    icon: opportunity,
    color: '#68B8DC'
  },
  meetings_booked: {
    icon: meeting,
    color: '#A367DC'
  },
  emails_captured: {
    icon: newContact,
    color: '#6771DC'
  },
  live_chats: {
    icon: liveChat,
    color: '#DC6888'
  },
  conversations: {
    icon: botHead,
    color: '#F56A48'
  },
  site_visits: {
    icon: siteVisit,
    color: '#FD9039'
  },
  page_views: {
    icon: siteVisit,
    color: '#FD9039'
  }
}

const activityKeys = {
  calendarDrops: 'meetings_booked',
  conversations: 'conversations',
  emailsCaptured: 'emails_captured',
  liveChats: 'live_chats',
  meetingsBooked: 'meetings_booked',
  pageViews: 'page_views',
  siteVisits: 'site_visits'
}

function createActivityChart ({ data, id, setVisibility, setAnchorEl, setTooltipData }) {
  am4core.addLicense('CH213476655')
  am4core.addLicense('TL272441676')

  if (!document.getElementById(id)) {
    return
  }

  const chart = am4core.create(id, am4plugins_timeline.CurveChart)
  let timeoutFunction
  const today = new Date()
  const startDate = new Date(today.setDate(today.getDate() - 30))

  chart.curveContainer.padding(10, 20, 10, 20)
  chart.maskBullets = false

  chart.dateFormatter.inputDateFormat = 'yyyy-MM-dd'
  chart.dateFormatter.dateFormat = 'dd'

  let nextDate = dateFormat(new Date(), 'yyyy-mm-dd')
  // const chartData = data.filter(a => a.event).sort((a, b) => a.date < b.date).map(d => {
  const chartData = data.sort((a, b) => a.date < b.date).map(d => {
    const summary = d.summarized_data
    const { mostRelevantEvent } = getSummary(summary)
    // const topEvent = Object.keys(summary).reduce(function (a, b) { return summary[a] > summary[b] ? a : b })
    const event = activityKeys[mostRelevantEvent]
    const endDate = nextDate
    nextDate = d.date
    return ({
      category: '',
      start: d.date,
      end: endDate,
      color: activityIcons[event].color,
      icon: activityIcons[event].icon,
      text: d.date,
      fill: 0.8,
      data: d
    })
  })

  chartData.push({
    category: '',
    start: startDate,
    end: nextDate,
    color: '#FD9039',
    icon: null,
    text: startDate,
    fill: 0
  })

  chart.data = chartData

  chart.fontSize = 10
  chart.tooltipContainer.fontSize = 10

  const categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis())
  categoryAxis.dataFields.category = 'category'
  categoryAxis.renderer.grid.template.disabled = true
  categoryAxis.renderer.labels.template.paddingRight = 10
  categoryAxis.renderer.minGridDistance = 5
  categoryAxis.renderer.innerRadius = 0
  categoryAxis.renderer.radius = 20

  const dateAxis = chart.xAxes.push(new am4charts.DateAxis())

  dateAxis.renderer.points = getPoints()

  dateAxis.renderer.autoScale = true
  dateAxis.renderer.autoCenter = true
  dateAxis.renderer.width = 100
  dateAxis.baseInterval = { count: 1, timeUnit: 'day' }
  dateAxis.renderer.tooltipLocation = 0
  dateAxis.renderer.line.strokeDasharray = '1,4'
  dateAxis.renderer.line.strokeOpacity = 0.9
  dateAxis.renderer.line.strokeWidth = 1
  dateAxis.renderer.line.stroke = '#8e5ae2'
  dateAxis.cursorTooltipEnabled = false
  dateAxis.endLocation = 1
  dateAxis.startLocation = 0
  dateAxis.min = startDate
  dateAxis.max = today

  const labelTemplate = dateAxis.renderer.labels.template
  labelTemplate.verticalCenter = 'middle'
  labelTemplate.fillOpacity = 1
  labelTemplate.background.fill = new am4core.InterfaceColorSet().getFor('background')
  labelTemplate.background.fillOpacity = 1
  labelTemplate.fill = new am4core.InterfaceColorSet().getFor('text')
  labelTemplate.padding(7, 7, 7, 7)

  const series = chart.series.push(new am4plugins_timeline.CurveColumnSeries())
  series.columns.template.height = am4core.percent(10)

  series.dataFields.openDateX = 'start'
  series.dataFields.dateX = 'end'
  series.dataFields.categoryY = 'category'
  series.baseAxis = categoryAxis
  series.columns.template.propertyFields.fill = 'color' // get color from data
  series.columns.template.propertyFields.stroke = 'color'
  series.columns.template.strokeOpacity = 0
  series.columns.template.fillOpacity = 0.9

  const imageBullet1 = series.bullets.push(new am4plugins_bullets.PinBullet())
  imageBullet1.background.radius = 18
  imageBullet1.locationX = 1
  imageBullet1.propertyFields.stroke = 'color'
  imageBullet1.background.propertyFields.fill = 'color'
  imageBullet1.image = new am4core.Image()
  imageBullet1.image.propertyFields.href = 'icon'
  imageBullet1.image.fill = 'white'
  imageBullet1.image.scale = 0.7
  imageBullet1.circle.radius = am4core.percent(100)
  imageBullet1.background.propertyFields.fillOpacity = 'fill'
  imageBullet1.background.strokeOpacity = 0
  imageBullet1.dy = -2
  imageBullet1.background.pointerBaseWidth = 10
  imageBullet1.background.pointerLength = 10

  imageBullet1.events.on('over', (ev) => {
    const target = ev.event.target
    clearTimeout(timeoutFunction)
    if (target.tagName === 'path') {
      setAnchorEl(ev.event.target)
      setTooltipData(ev.target.dataItem.dataContext.data)
    }
  })

  imageBullet1.events.on('out', (ev) => {
    timeoutFunction = setTimeout(() => {
      setAnchorEl(null)
    }, 50)
  })

  series.tooltip.pointerOrientation = 'up'

  imageBullet1.background.adapter.add('pointerAngle', (value, target) => {
    if (target.dataItem) {
      const position = dateAxis.valueToPosition(target.dataItem.openDateX.getTime())
      return dateAxis.renderer.positionToAngle(position)
    }
    return value
  })

  const hs = imageBullet1.states.create('hover')
  hs.properties.scale = 1.3
  hs.properties.opacity = 1

  const textBullet = series.bullets.push(new am4charts.LabelBullet())
  textBullet.label.propertyFields.text = 'text'
  textBullet.disabled = true
  textBullet.propertyFields.disabled = 'textDisabled'
  textBullet.label.strokeOpacity = 0
  textBullet.locationX = 1
  textBullet.dy = -100
  textBullet.label.textAlign = 'middle'

  const cursor = new am4plugins_timeline.CurveCursor()
  chart.cursor = cursor
  cursor.xAxis = dateAxis
  cursor.yAxis = categoryAxis
  cursor.lineY.disabled = true
  cursor.lineX.disabled = true

  // dateAxis.renderer.tooltipLocation2 = 0
  categoryAxis.cursorTooltipEnabled = false

  chart.zoomOutButton.disabled = true

  function getPoints () {
    const points = [{ x: -300, y: 200 }, { x: 0, y: 200 }]

    const w = 400
    const h = 400
    const levelCount = 4

    const radius = am4core.math.min(w / (levelCount - 1) / 2, h / 2)
    const startX = radius

    for (let a = 0; a < 25; a++) {
      const angle = 0 + a / 25 * 90
      const centerPoint = { y: 200 - radius, x: 0 }
      points.push({ y: centerPoint.y + radius * am4core.math.cos(angle), x: centerPoint.x + radius * am4core.math.sin(angle) })
    }

    for (let i = 0; i < levelCount; i++) {
      if (i % 2 !== 0) {
        points.push({ y: -h / 2 + radius, x: startX + w / (levelCount - 1) * i })
        points.push({ y: h / 2 - radius, x: startX + w / (levelCount - 1) * i })

        const centerPoint = { y: h / 2 - radius, x: startX + w / (levelCount - 1) * (i + 0.5) }
        if (i < levelCount - 1) {
          for (let k = 0; k < 50; k++) {
            const angle = -90 + k / 50 * 180
            points.push({ y: centerPoint.y + radius * am4core.math.cos(angle), x: centerPoint.x + radius * am4core.math.sin(angle) })
          }
        }

        if (i === levelCount - 1) {
          points.pop()
          points.push({ y: -radius, x: startX + w / (levelCount - 1) * i })
          const centerPoint = { y: -radius, x: startX + w / (levelCount - 1) * (i + 0.5) }
          for (let p = 0; p < 25; p++) {
            const angle = -90 + p / 25 * 90
            points.push({ y: centerPoint.y + radius * am4core.math.cos(angle), x: centerPoint.x + radius * am4core.math.sin(angle) })
          }
          points.push({ y: 0, x: 700 })
        }
      } else {
        points.push({ y: h / 2 - radius, x: startX + w / (levelCount - 1) * i })
        points.push({ y: -h / 2 + radius, x: startX + w / (levelCount - 1) * i })
        const centerPoint = { y: -h / 2 + radius, x: startX + w / (levelCount - 1) * (i + 0.5) }
        if (i < levelCount - 1) {
          for (let q = 0; q < 50; q++) {
            const angle = -90 - q / 50 * 180
            points.push({ y: centerPoint.y + radius * am4core.math.cos(angle), x: centerPoint.x + radius * am4core.math.sin(angle) })
          }
        }
      }
    }

    return points
  }

  chart.events.on('ready', () => {
    setVisibility('visible')
  })

  return chart
}

export { createActivityChart }
