import React, { useRef, useEffect, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { groupEvents, isTyping } from './groupEvents'
import { MessageGroupDisplay } from './MessageDisplayComponents'
import ConnectivityWarning from './ConnectivityWarning'
import TypingIndicator from './TypingIndicator'
import { ConversationContext } from './ConversationContext'

const useStyles = makeStyles(theme => ({
  messages: {
    marginTop: -10,
    height: 'calc(100% + 10px)',
    width: '100%',
    position: 'relative'
  },
  messageDisplay: {
    padding: '0px !important',
    overflow: 'auto',
    position: 'relative'
  },
  typingIndicator: {
    postion: 'absolute',
    bottom: 0
  },
  typingDiv: {
    height: 30,
    display: 'flex',
    fontSize: '12px',
    fontStyle: 'italic',
    alignItems: 'center',
    marginLeft: 20
  },
  avatarDiv: {
    paddingRight: 7
  }
}))

const scrollToBottom = (el) => {
  if (el) {
    el.scrollTop = el.scrollHeight - el.clientHeight
  }
}

function ActiveConversationDisplay (props) {
  const classes = useStyles()
  const participant = props.participant
  const { events, typing, connectionStatus } = useContext(ConversationContext)
  const conversation = props.conversation
  const { groups, displayEvents } = groupEvents({ events })
  const typingState = isTyping(typing, participant)
  const displayRef = useRef(null)
  useEffect(() => {
    setTimeout(() => {
      scrollToBottom(displayRef.current)
    }, 10)
  }, [displayEvents.length])

  return (
    <div className={classes.messages}>
      <div
        className={classes.messageDisplay}
        style={{ height: typing ? 'calc(100% - 30px)' : '100%' }}
        ref={displayRef}
      >
        {groups.map((group, index) => (
          <MessageGroupDisplay
            group={group}
            key={index}
          />
        ))}
        <ConnectivityWarning
          connectionStatus={connectionStatus}
          scrollToBottom={() => scrollToBottom(displayRef.current)}
        />
      </div>
      <TypingIndicator
        typing={typingState}
        conversation={conversation}
        lastEvent={events[events.length - 1]}
        currentID={participant ? participant.id : undefined}
      />
    </div>
  )
}

export default ActiveConversationDisplay
