import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PopoverTextField from 'components/PopoverTextField'
import { Formik } from 'formik'
import { Icon } from 'library/materialUI'

const useStyles = makeStyles(theme => ({
  socialIcon: {
    marginRight: 10,
    cursor: 'pointer'
  },
  widgetCtn: {
    display: 'flex'
  }
}))

/**
 * Handles the opening of social links in a new tab
 * @param {string} linkHandle
 * @param {SocialMediaHandle} socialMedia
 */
function openNewTab (linkHandle, socialMedia) {
  if (!linkHandle || !socialMedia) {
    return
  }
  if (!linkHandle.startsWith('http:') && !linkHandle.startsWith('https:')) {
    switch (socialMedia) {
      case 'linkedin_handle':
        linkHandle = `https://www.linkedin.com/${linkHandle}`
        break
      case 'twitter_handle':
        linkHandle = linkHandle.replace('@', '')
        linkHandle = `https://www.twitter.com/${linkHandle}`
        break
      case 'instagram_handle':
        linkHandle = linkHandle.replace('@', '')
        linkHandle = `https://www.instagram.com/${linkHandle}`
        break
      case 'facebook_handle':
        linkHandle = linkHandle.replace('/', '')
        linkHandle = `https://www.facebook.com/${linkHandle}`
        break
      default:
        break
    }
  }
  window.open(linkHandle, '_blank')
}

/**
 * @typedef {(keyof (typeof socialMediaIconMap))} SocialMediaHandle
 * @type {SocialMediaHandle[]}
 */
const allSocialMediaHandles = [
  'linkedin_handle',
  'twitter_handle',
  'instagram_handle',
  'facebook_handle'
]

const socialMediaIconMap = {
  linkedin_handle: { icon: 'linkedin', placeholder: 'in/jon-doe-84422b51', color: '#0072B1' },
  facebook_handle: { icon: 'facebook', placeholder: '/jon-doe', color: '#316FF6' },
  instagram_handle: { icon: 'instagram', placeholder: '@jon_doe', color: 'primary' },
  twitter_handle: { icon: 'xSocial', placeholder: '@jon_doe', color: 'primary' }
}

/**
 * @template {SocialMediaHandle} T
 * @param {T} handle
 * @returns {typeof socialMediaIconMap[T]['icon']}
 */
const getSocialMediaIcon = handle => socialMediaIconMap[handle].icon

/**
 * @typedef {Object} SocialMediaWidgetProps
 * @property {{[k in SocialMediaHandle]: string}} socialMediaHandles
 * @property {boolean} editMode - if editMode, all clicks will open the textField to edit instead of routing to link
 * @property {(media: SocialMediaHandle, linkHandle: string) => void} [onSave] - Save a social media field value. If ommited, editing will not be permitted.
 */
/**
 * @param {SocialMediaWidgetProps} props
 */
export default function SocialMediaWidgets (props) {
  const classes = useStyles()
  const { socialMediaHandles, onSave, editMode } = props
  const [activeHandle, setActiveHandle] = useState({ anchorEl: null, handle: null })

  if (!socialMediaHandles) {
    return <></>
  }

  /**
   * @param {MouseEvent} event
   * @param {SocialMediaHandle} socialMediaHandle
   */
  const handleWidgetClick = (event, socialMediaHandle) => {
    if (onSave && (editMode || !socialMediaHandles[socialMediaHandle] || event.shiftKey || event.ctrlKey)) {
      return setActiveHandle({
        anchorEl: event.currentTarget,
        handle: socialMediaHandle
      })
    }
    openNewTab(socialMediaHandles[socialMediaHandle], socialMediaHandle)
  }

  return (
    <>
      <div className={classes.widgetCtn}>
        {allSocialMediaHandles.map(handle => {
          if (!onSave && !socialMediaHandles[handle]) {
            return <></>
          }
          const HandleIcon = getSocialMediaIcon(handle)
          return (
            <div
              key={handle}
              fontSize='small'
              className={classes.socialIcon + (socialMediaHandles[handle] ? ' purple' : '')}
              onClick={e => handleWidgetClick(e, handle)}
            >
              <Icon icon={HandleIcon} color={socialMediaHandles[handle] ? socialMediaIconMap[handle].color : '#CBCBCB'} />
            </div>
          )
        })}
      </div>
      <Formik
        enableReinitialize
        initialValues={socialMediaHandles}
        onSubmit={values => {
          onSave && onSave(activeHandle.handle, values[activeHandle.handle])
        }}
        render={({ setFieldValue, values, submitForm, handleBlur }) => (
          <PopoverTextField
            startAdornmentIcon={socialMediaIconMap[activeHandle.handle]?.icon}
            anchorEl={activeHandle.anchorEl}
            placeholder={socialMediaIconMap[activeHandle.handle]?.placeholder}
            onClose={() => {
              // onSave(activeHandle.handle, activeValue)
              handleBlur()
              setActiveHandle({ anchorEl: null, handle: activeHandle.handle })
            }}
            value={values[activeHandle.handle] || ''}
            onChange={e => { setFieldValue(activeHandle.handle, e.target.value) }}
            onBlur={() => {
              handleBlur()
              setTimeout(() => {
                submitForm()
              }, 25)
            }}
          />
        )}
      />
    </>
  )
}
