import { makeStyles } from '@material-ui/core/styles'
import { Icon } from 'library/materialUI'
import { HelpCenterIcon } from '../HelpCenterCard'
import { capitalizeString } from 'helpers/strings'
import { triggerSupportBot } from 'pages/bot/InAppBot/triggerSupportBot'

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: '20px'
  },
  headerText: {
    fontSize: '24px',
    color: '#000000BF',
    marginBottom: '2px',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center'
  },
  subText: {
    color: '#00000080',
    fontSize: '12px'
  },
  textField: {
    marginTop: '5px'
  },
  stateField: {
    fontSize: '12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '25px',
    paddingLeft: '8px',
    paddingRight: '8px',
    marginLeft: '10px',
    borderRadius: '5px'
  },
  typeField: {
    color: '#808080',
    fontSize: '12px'
  },
  sectionTitle: {
    fontSize: '18px',
    color: '#000000BF',
    marginTop: '25px',
    marginBottom: '5px',
    fontWeight: 'bold'
  },
  statusField: {
    color: '#808080',
    fontSize: '14px'
  },
  updatedField: {
    color: '#C4C4C4',
    fontSize: '12px'
  },
  noticeBox: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '15px',
    marginBottom: '15px',
    padding: '15px',
    backgroundColor: '#F5F5F5',
    borderRadius: '5px',
    fontSize: '14px'
  },
  button: {
    height: '40px',
    width: '191px',
    border: '1px solid #D3D3D3',
    borderRadius: '5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    paddingLeft: '15px',
    paddingRight: '15px'
  },
  updateBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '10px',
    border: '1px solid #E2E2E2',
    borderRadius: '10px',
    marginTop: '10px'
  },
  updateText: {
    color: '#343434',
    fontSize: '14px',
    fontWeight: 'bold'
  }
}))

interface HistoryItemProps {
  title: string
  status: string
  isClosed: boolean
  type: string
  description: string
  caseNumber: string
  createdDate: string
  caseHistory: {
    Field: string
    OldValue: string
    NewValue: string
    CreatedDate: string
  }[]
}

export const HistoryItem = ({ title, status, isClosed, type, description, caseNumber, createdDate, caseHistory }: HistoryItemProps): JSX.Element => {
  const classes = useStyles()

  const renderStatusUpdates = () => {
    return caseHistory
      .filter(update => update.Field === 'Status')
      .map((historyItem, index) => (
        <div key={index} className={classes.updateBox}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div className={classes.updateText}>
              Status updated
            </div>
            <div style={{ color: '#C4C4C4', fontSize: '12px', marginLeft: 'auto', marginRight: '1px' }}>
              {
                new Date(historyItem.CreatedDate).toLocaleDateString('en-US', {
                  month: 'long', day: 'numeric', year: 'numeric'
                }) + ' at ' +
                new Date(historyItem.CreatedDate).toLocaleTimeString('en-US', {
                  hour: 'numeric', minute: '2-digit', hour12: true
                })
              }
            </div>
          </div>
          <div style={{ color: '#808080', fontSize: '14px' }}>
            Status: {historyItem.OldValue} to {historyItem.NewValue}
          </div>
        </div>
      ))
  }

  return (
    <div className={classes.container}>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div>
          <HelpCenterIcon
            icon={type === 'bug' ? 'noticeIcon' : 'lightBulbWhite'}
            size='small'
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '10px' }}>
          <div className={classes.headerText}>
            <div>
              {title}
            </div>
            <div className={classes.stateField} style={{ color: !isClosed ? '#81AF20' : '#505050', backgroundColor: !isClosed ? '#E6EFD2' : '#EAEAEA' }}>
              {(isClosed ? 'Closed' : 'Open')}
            </div>
          </div>
          <div className={classes.typeField}>
            {capitalizeString(type)}
          </div>
        </div>
      </div>
      <div className={classes.sectionTitle}>
        Status
      </div>
      <div className={classes.statusField}>
        {capitalizeString(status)}
      </div>
      <div className={classes.sectionTitle}>
        Updates
      </div>
      <div style={{ color: '#808080', fontSize: '12px' }}>
        You will be notified when the status changes. <a href='#/settings/my_settings/notifications' target='_blank' referrerPolicy='no-referrer'>Edit Settings</a>
      </div>
      {renderStatusUpdates()}
      <div className={classes.updateBox}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div className={classes.updateText}>
            Case created
          </div>
          <div style={{ color: '#C4C4C4', fontSize: '12px', marginLeft: 'auto', marginRight: '1px' }}>
            {
              new Date(createdDate).toLocaleDateString('en-US', {
                month: 'long', day: 'numeric', year: 'numeric'
              }) + ' at ' +
              new Date(createdDate).toLocaleTimeString('en-US', {
                hour: 'numeric', minute: '2-digit', hour12: true
              })
            }
          </div>
        </div>
      </div>
      {/* <div className={classes.noticeBox}>
        <div style={{ marginRight: '10px' }}>
          <Icon icon='notification' />
        </div>
        <div>
          You will be notified by email when this case has been closed.
        </div>
      </div> */}
      <div className={classes.sectionTitle}>
        Description:
      </div>
      <div className={classes.statusField}>
        {description}
      </div>
      <div className={classes.sectionTitle}>
        Case #
      </div>
      <div className={classes.statusField}>
        {caseNumber}
      </div>
      <div style={{ marginTop: '30px', marginBottom: '10px', fontSize: '14px ' }}>
        Follow up with an agent
      </div>
      <div onClick={() => triggerSupportBot()} className={classes.button}>
        <div>
          <Icon icon='chat' size='sm' />
        </div>
        <div style={{ marginLeft: '10px' }}>
          Chat with support
        </div>
      </div>
    </div>
  )
}
