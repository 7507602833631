import { makeStyles } from '@material-ui/core/styles'
import DateRangeSelector from 'cf-components/DateRangeSelector'
import { useState } from 'react'

const useStyles = makeStyles(theme => ({
  main: {
    width: '215px',
    height: '55px',
    backgroundColor: 'white',
    marginLeft: '15px',
    marginTop: '10px'
  }
}))

interface DateRange {
  startDate: Date
  endDate: Date
}

interface DateSelectProps {
  setSelected: (values: any[]) => void
  selected: string[]
}

export default function DateSelect ({ setSelected, selected }: DateSelectProps) {
  const classes = useStyles()
  const endDate: Date = selected[1] ? new Date(selected[1]) : new Date()
  let startDate: Date = new Date()
  startDate = selected[1] ? new Date(selected[0]) : new Date(startDate.setDate(startDate.getDate() - 30))
  const [dateRange, setDateRange] = useState<DateRange>({ startDate, endDate })

  const handleSelect = (value: DateRange) => {
    setDateRange(value)
    if (value.startDate && value.endDate) {
      setSelected([value.startDate.toISOString(), value.endDate.toISOString()])
    }
  }

  return (
    <div className={classes.main}>
      <DateRangeSelector
        range={dateRange}
        handleSelect={(value) => handleSelect(value)}
      />
    </div>
  )
}
