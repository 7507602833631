import { useEffect, useRef, useState } from 'react'
import { getFunnel } from 'api/reports'
import { createFunnelChart } from './charts/CreateFunnelChart'
import Loader from 'library/loading/Loader'
import { convertDateRange } from 'cf-components/FilterBar/FilterBar'

function FunnelChart (props) {
  const chartRef = useRef(null)
  const [visibility, setVisibility] = useState('hidden')

  useEffect(() => {
    if (props.dateValues) {
      setVisibility('hidden')
      const attributes = convertDateRange(props.dateValues)
      attributes.source_filter = props.sourceFilter
      getFunnel({ attributes }).then(response => {
        const id = 'funneldiv'
        const data = response.data.attributes.chart_data.filter(row => row.type !== 'Prompts')
        data[0].percent = '100%'

        for (let i = 1; i < data.length; i++) {
          data[i].percent = data[i - 1].count
            ? Math.round((data[i].count / data[i - 1].count) * 100, 1) + '%' : '0%'
        }

        if (chartRef.current) {
          chartRef.current.data = data
          setVisibility('visible')
        } else {
          const chart = createFunnelChart({
            data,
            id,
            setVisibility
          })
          chartRef.current = chart
        }
      })
    }
  }, [props.dateValues, props.sourceFilter])

  return (
    <>
      <div
        style={{
          display: visibility === 'visible' ? 'none' : 'block',
          ...props.loaderStyles
        }}
      >
        <Loader type='spinner' />
      </div>
      <div style={{ height: 360, visibility: visibility }}>
        <div id='funneldiv' style={{ width: '100%', height: '100%' }} />
      </div>
    </>
  )
}

export default FunnelChart
