import { Icon } from 'library/materialUI/Icon';

import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 124,
    margin: 5,
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    border: '1px solid #969496'
  },
  input: {
  },
  iconButton: {
    padding: 10,
    color: theme.palette.primary.main
  }
}));
type QuantityAdjusterProps = {
  disabled?: boolean
  value: number
  max?: number
  min?: number
  handleIncrement: () => void
  handleDecrement: () => void
}

export function QuantityAdjuster (props: QuantityAdjusterProps): JSX.Element {
  const classes = useStyles();
  const allowDecrement = props.min === undefined || props.value > props.min
  const allowIncrement = props.max === undefined || props.max === 0 || props.value < props.max
  return (
    <Paper component='form' className={classes.root}>
      <IconButton
        className={classes.iconButton}
        aria-label='decrement'
        onClick={props.handleDecrement}
        disabled={props.disabled || !allowDecrement}
      >
        <Icon icon='remove' size='sm' />
      </IconButton>
      <InputBase
        className={classes.input}
        value={props.value}
        readOnly
        inputProps={{ 'aria-label': 'quantity', style: { textAlign: 'center', fontSize: 19 } }}
        style={{ textAlign: 'center' }}
      />
      <IconButton
        className={classes.iconButton}
        aria-label='increment'
        onClick={props.handleIncrement}
        disabled={props.disabled || !allowIncrement}
      >
        <Icon icon='add' size='sm' />
      </IconButton>
    </Paper>
  )
}
