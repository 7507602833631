import { doGet, doPatch } from './api'

function getTenant ({ tenantID, authHeader }) {
  return doGet({ path: `/api/tenants/${tenantID}`, extraHeaders: authHeader })
}

function saveTenant ({ tenantID, attributes }) {
  const body = {
    id: parseInt(tenantID),
    type: 'tenants',
    attributes: attributes,
    relationships: {}
  }
  return doPatch({ path: `/api/tenants/${tenantID}`, data: body })
}

const getTenants = ({ user_id }) => {
  return doGet({ path: '/api/users/tenants' })
}

const loginNewTenant = ({ tenant_id }) => {
  return doGet({ path: `/api/users/tenants/${tenant_id}` })
}

export { getTenant, saveTenant, getTenants, loginNewTenant }
