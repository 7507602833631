import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import SaveIcon from '@material-ui/icons/Save'
import HistoryIcon from '@material-ui/icons/History'
import theme from 'theme'
import { ThemeProvider } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  menuBar: {
    height: 37,
    width: '100%',
    borderBottom: '1px solid rgba(0,0,0,0.2)',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  button: {
    marginRight: 10
  },
  modalContents: {
    maxHeight: 500
  }
}))

function TopMenuBar (props) {
  const classes = useStyles()

  const handleSave = props.initiateSave
  const handleVersionModal = props.showVersionHistory

  return (
    <ThemeProvider
      theme={theme}
    >
      <div className={classes.menuBar}>
        <Button
          startIcon={<HistoryIcon />}
          color='primary'
          className={classes.button}
          onClick={handleVersionModal}
          disabled={Boolean(props.activeTest)}
        >
          Version History
        </Button>
        <Button
          startIcon={<SaveIcon />}
          color='primary'
          className={classes.button}
          onClick={handleSave}
          disabled={props.saveDisabled}
        >
          Save
        </Button>
      </div>
    </ThemeProvider>
  )
}

export { TopMenuBar }
