import Persona from 'classes/personas';
import { Button, Icon, Typography } from 'library/materialUI';
import { makeStyles } from '@material-ui/core/styles'
import { PreviewStates } from './BuyingCommitteeModal';
import { useState } from 'react';
import { runPersonaPreview } from 'api/buying_committee';
import Loader from 'library/loading/Loader';
import StaticChip from 'library/StaticChip';

const useStyles = makeStyles(theme => ({
  iconDiv: {
    display: 'flex'
  },
  stateDiv: {
    height: '93%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center'
  },
  chipContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row'
  },
  stateTextSpacing: {
    marginTop: 15,
    marginBottom: 15
  }
}))

export default function TitlePreview ({ persona, previewState, setPreviewState, excludeTitle }: { persona: Persona, previewState: string, setPreviewState: (state: PreviewStates) => void, excludeTitle: (title: string) => void }): JSX.Element {
  const classes = useStyles()
  const [previewTitles, setPreviewTitles] = useState([])

  const runPreview = () => {
    setPreviewState('loading')
    runPersonaPreview({
      attributes: {
        departments: persona.departments,
        titles: persona.titles,
        excludedTitles: persona.excludedTitles,
        seniorities: persona.seniorities
      }
    }).then((response: any) => {
      setPreviewTitles(response.data.attributes.titles)
      setPreviewState('run')
    }).catch((err: Error) => {
      if (err.message === 'too_many_requests') {
        setPreviewState('out_of_requests')
      }
    })
  }
  switch (previewState) {
    case 'start':
      return (
        <div className={classes.stateDiv}>
          <div className={classes.iconDiv}><Icon icon='barsSearch' specificSize={40} /></div>
          <Typography className={classes.stateTextSpacing} variant='body1'>Load a preview of your possible titles</Typography>
          <Button
            variant='outlined'
            color='primary'
            onClick={runPreview}
          >
            Load Preview
          </Button>
        </div>
      )
    case 'refresh':
      return (
        <div className={classes.stateDiv}>
          <div className={classes.iconDiv}><Icon icon='refresh' specificSize={40} /></div>
          <Typography className={classes.stateTextSpacing} variant='body1'>Refresh to see your updated title preview</Typography>
          <Button
            variant='outlined'
            color='primary'
            onClick={runPreview}
          >
            Refresh Preview
          </Button>
        </div>
      )
    case 'empty':
      return (
        <div className={classes.stateDiv}>
          <div className={classes.iconDiv}><Icon icon='arrowBack' specificSize={40} /></div>
          <Typography className={classes.stateTextSpacing} variant='body1'>Select some filters</Typography>
        </div>
      )
    case 'too_many_titles':
      return (
        <div className={classes.stateDiv}>
          <div className={classes.iconDiv}><Icon icon='warning' specificSize={40} /></div>
          <Typography className={classes.stateTextSpacing} variant='body1'>Please narrow your title list(s) to have 50 or fewer titles. Consider consolidating similar titles.</Typography>
        </div>
      )
    case 'out_of_requests':
      return (
        <div className={classes.stateDiv}>
          <div className={classes.iconDiv}><Icon icon='warning' specificSize={40} /></div>
          <Typography className={classes.stateTextSpacing} variant='body1'>Due to high system load, we can’t process your preview right now</Typography>
        </div>
      )
    case 'loading':
      return (
        <div className={classes.stateDiv}>
          <Loader type='spinner' />
        </div>
      )
    default:
      if (previewTitles.length === 0) {
        return (
          <div className={classes.stateDiv}>
            <div className={classes.iconDiv}><Icon icon='close' specificSize={40} /></div>
            <Typography className={classes.stateTextSpacing} variant='body1'>No matching titles found</Typography>
          </div>
        )
      }
      return (
        <div className={classes.chipContainer}>
          {previewTitles.map(title => (
            <StaticChip
              key={title}
              value={title}
              canClose
              closeColor='#9933FF'
              onDelete={() => {
                setPreviewTitles(previewTitles.filter((t: string) => t !== title))
                excludeTitle(title)
              }}
            />
          ))}
        </div>
      )
  }
}
