import { makeStyles } from '@material-ui/core/styles'
import { reviewVotes } from 'api/crowdsource'
import { Icon } from 'library/materialUI'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    margin: '10px',
    fontSize: '14px',
    fontFamily: 'poppins'
  },
  approve: {
    display: 'flex',
    flexDirection: 'row',
    color: '#81AF20',
    margin: '5px',
    cursor: 'pointer'
  },
  deny: {
    display: 'flex',
    flexDirection: 'row',
    color: '#EA422A',
    margin: '5px',
    cursor: 'pointer'
  },
  edit: {
    display: 'flex',
    flexDirection: 'row',
    color: 'black',
    margin: '5px',
    cursor: 'pointer'
  },
  statusItem: {
    marginRight: '5px',
    fontFamily: 'poppins',
    fontSize: '14px'
  }
}))

interface CrowdSourceStatusDropdownProps {
  domain: string
  field: string
  value: any
  setModalOpen: (value: boolean) => void
  setStatus: (value: string) => void
  setModalDomain: (value: string) => void
  setModalField: (value: string) => void
  setPrevValue: (value: any) => void
}

export default function CrowdSourceStatusDropdown ({ domain, field, value, setModalOpen, setStatus, setModalDomain, setModalField, setPrevValue }: CrowdSourceStatusDropdownProps) {
  const classes = useStyles()

  const handleApprove = () => {
    reviewVotes({ domain: domain, field: field, approval: { value: value, approval: 'approved' } })
    setStatus('approved')
  }

  const handleDeny = () => {
    reviewVotes({ domain: domain, field: field, approval: { value: value, approval: 'denied' } })
    setStatus('denied')
  }

  const handleEdit = () => {
    setModalDomain(domain)
    setModalField(field)
    setPrevValue(value)
    setModalOpen(true)
  }

  return (
    <div className={classes.container}>
      <div className={classes.approve} onClick={() => handleApprove()}>
        <div className={classes.statusItem} style={{ marginTop: '2px' }}>
          <Icon icon='thumbUp' color='#81AF20' size='sm' />
        </div>
        <div className={classes.statusItem}>
          Approve
        </div>
      </div>
      <div className={classes.deny} onClick={() => handleDeny()}>
        <div className={classes.statusItem} style={{ marginTop: '2px' }}>
          <Icon icon='thumbDown' color='#EA422A' size='sm' />
        </div>
        <div className={classes.statusItem}>
          Deny
        </div>
      </div>
      <div className={classes.edit} onClick={() => handleEdit()}>
        <div className={classes.statusItem} style={{ marginTop: '2px' }}>
          <Icon icon='edit' size='sm' />
        </div>
        <div className={classes.statusItem}>
          Edit
        </div>
      </div>
    </div>
  )
}
