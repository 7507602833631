import { useContext } from 'react'
import { ListPageContext } from '../ListPage'
import { ObjectPaneContainer } from '../ObjectPaneContainer'
import { ObjectDataContainer } from './ObjectDataContainer'
import { objectTypeMapping, objectMappingTypes } from './ObjectTypeMapping'

export function ObjectPane () {
  const { object, pageType } = useContext(ListPageContext)

  const mappedObject = objectTypeMapping[pageType as keyof objectMappingTypes]
  let objectType, stats, Component, showActivities
  if (mappedObject) {
    ({ objectType, stats, noObject: Component, showActivities } = mappedObject)
  }

  if (!object) {
    return (
      <Component />
    )
  }

  return (
    <ObjectPaneContainer>
      {objectType && <ObjectDataContainer objectType={objectType} stats={stats} showActivities={showActivities} />}
    </ObjectPaneContainer>
  )
}
