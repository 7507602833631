import React, { useState } from 'react'
import RegistrationTemplate from './RegistrationTemplate'
import ResetPasswordFinishSideBar from './ResetPasswordFinishSideBar'
import { Redirect } from 'react-router-dom'

const ResetPassword = props => {
  const [resetSuccessful, setResetSuccessful] = useState(false)
  const [resetComplete, setResetComplete] = useState(false)
  const [loading, setLoading] = useState(false)

  function resetPassword (values) {
    setLoading(true)
    const token = new URLSearchParams(window.location.hash.split('?')[1]).get(
      'token'
    )
    fetch('/api/auth/reset_password:finish', {
      method: 'POST',
      headers: { 'Content-Type': 'application/vnd.api+json' },
      body: JSON.stringify({
        token: token,
        new_password: values.password
      })
    })
      .then(response => response.json())
      .then(response => {
        if (response.ok) {
          setResetSuccessful(true)
        }
      })
  }

  if (resetComplete) {
    return <Redirect to='/' />
  }

  return (
    <RegistrationTemplate>
      <ResetPasswordFinishSideBar
        loading={loading}
        onSubmit={resetPassword}
        resetSuccessful={resetSuccessful}
        setResetComplete={setResetComplete}
      />
    </RegistrationTemplate>
  )
}

export default ResetPassword
