import React, { useEffect, useState } from 'react'
import { makeStyles, createTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import { Button, IconButton } from '@material-ui/core'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import CloseIcon from '@material-ui/icons/Close'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'

const useStyles = makeStyles(theme => ({
  saveMenu: {
    position: 'absolute',
    width: 'calc(100% - 30px)',
    backgroundColor: '#FF5D5C',
    padding: '5px 5px 5px 25px',
    color: 'white',
    zIndex: 11,
    display: 'flex',
    fontWeight: 600,
    fontSize: '1.1em',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  invalid: {
    backgroundColor: '#FA8F35'
  }
}))

const buttonTheme = createTheme({
  palette: {
    parent: {
      main: '#FFF',
      contrastText: '#FF5D5C'
    }
  }
})

function BotSaveMenu (props) {
  const classes = useStyles()
  const surface = props.surface
  const unconnectedNodes = props.problemNodes
  const invalidNodes = props.invalidNodes
  const allNodes = [...unconnectedNodes, ...invalidNodes]
  const [nextNode, setNextNode] = useState(1)
  const [visible, setVisible] = useState(false)
  const [success, setSuccess] = useState(false)
  const isOnInvalidNode = (nextNode > unconnectedNodes.length) || (!nextNode && invalidNodes.length)

  useEffect(() => {
    if (props.saveAttempts) {
      setVisible(true)
    }
  }, [props.saveAttempts])

  useEffect(() => {
    if (visible && unconnectedNodes.length === 0 && invalidNodes.length === 0) {
      setSuccess(true)
      setTimeout(() => {
        setVisible(false)
      }, 2500)
    } else {
      setSuccess(false)
    }
  }, [unconnectedNodes.length, invalidNodes.length, visible])

  if (!visible) {
    return (
      <></>
    )
  }

  if (success) {
    return (
      <div
        className={classes.saveMenu}
        style={{ backgroundColor: '#43a047', height: 48 }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <CheckCircleOutlineIcon style={{ marginRight: 15 }} />
          All nodes fixed!
        </div>
      </div>
    )
  }

  const message = isOnInvalidNode
    ? `${invalidNodes.length} skill${invalidNodes.length > 1 ? 's' : ''} with incomplete settings`
    : `${unconnectedNodes.length} unconnected skill${unconnectedNodes.length > 1 ? 's' : ''}`

  function jumpToNextNode () {
    if (allNodes.length === 1) {
      surface.centerOn(allNodes[0])
    } else {
      surface.centerOn(allNodes[nextNode])
    }
    if (nextNode + 1 < allNodes.length) {
      setNextNode(nextNode + 1)
    } else {
      setNextNode(0)
    }
  }

  return (
    <ThemeProvider theme={buttonTheme}>
      <div
        className={classes.saveMenu + (isOnInvalidNode ? (' ' + classes.invalid) : '')}
      >
        <div>
          {message}
        </div>
        <div>
          <Button
            color='parent'
            variant='contained'
            size='small'
            endIcon={<NavigateNextIcon />}
            onClick={jumpToNextNode}
            style={{ marginRight: 15 }}
          >
            Jump to Node
          </Button>
          <IconButton
            onClick={() => setVisible(false)}
            color='parent'
          >
            <CloseIcon />
          </IconButton>
        </div>
      </div>
    </ThemeProvider>
  )
}

export default BotSaveMenu
