import { Fragment, useContext, useState } from 'react';
import { ContentsProps } from '../wizard/OnboardingWizard';
import { FullIntegrationsList } from 'pages/settings/Integrations/IntegrationsList';
import { makeStyles } from '@material-ui/core/styles';
import { Checkbox, Divider, Typography } from 'library/materialUI';
import { ScrollBox } from 'cf-components/ScrollBox';
import InformationIcon from 'library/InformationIcon';
import { Tenant } from 'classes/tenants';
import { SessionContext } from 'session-context';
import SearchBar from 'components/SearchBar';

const useStyles = makeStyles(theme => ({
  integration: {
    display: 'flex',
    alignItems: 'center',
    height: 34,
    font: 'normal normal normal 14px/21px Poppins',
    color: '#000000BF',
    cursor: 'pointer'
  },
  sectionHeader: {
    font: 'normal normal 600 13px/20px Poppins',
    color: '#000000BF',
    borderBottom: '1px solid #00000026',
    marginTop: 5,
    marginBottom: 5
  },
  integrationRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  selected: {
    background: '#f2f2f2'
  }
}))
const iconColor = '#00000040'
const categorySet = new Set(FullIntegrationsList.map(i => i.category))
const categoryNames = Array.from(categorySet)
categoryNames.sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' }))

const categorySearchOptions = categoryNames.map(name => {
  let count = 0
  FullIntegrationsList.forEach(i => { if (i.category === name) { count += 1 } })
  return { name, count }
})
categorySearchOptions.unshift({ name: 'All', count: FullIntegrationsList.length })

export const integrationsSubtitle = (
  <Typography>
    Which of these tools does your organization currently use?
    Move your cursor over the <InformationIcon color={iconColor} message='Additional information' placement='center' inline /> icon to see how Signals works with each.
  </Typography>
)

export function IntegrationsSection (props: ContentsProps) {
  const classes = useStyles()
  const { userObject } = useContext(SessionContext)
  const [selected, setSelected] = useState<string[]>([])
  const [selectedIntegration, setSelectedIntegration] = useState('All')
  const [query, setQuery] = useState('')

  const filteredCategories = categorySearchOptions.filter(obj => {
    return obj.name.toLowerCase().includes(query.toLowerCase())
  })

  const selectedCategories = categoryNames.filter(name => {
    if (selectedIntegration !== 'All') {
      return name.toLowerCase() === selectedIntegration.toLowerCase()
    } else {
      return true
    }
  })

  const handleSelection = (name: string) => {
    if (selected.includes(name)) {
      setSelected(old => old.filter(i => i !== name))
    } else {
      setSelected(old => [...old, name])
    }
  }

  props.handleNextRef.current = () => {
    const updates: Partial<Tenant> = {
      onboardingIntegrations: selected,
      onboardingCompleteTimestamp: new Date()
    }
    Tenant.update(userObject.tenantID, updates)
  }

  return (
    <div style={{ display: 'flex', borderTop: '1px solid #00000026', borderBottom: '1px solid #00000026', minHeight: 330 }}>
      <Divider />
      <ScrollBox noScrollBar style={{ width: 180, padding: 10, borderRight: '1px solid #00000026' }}>
        <SearchBar
          search={query}
          setSearch={(string: string) => setQuery(string)}
        />
        {filteredCategories.map(obj => (
          <Typography
            key={obj.name}
            style={{ margin: '7px 0px', cursor: 'pointer', alignItems: 'center', display: 'flex', justifyContent: 'space-between', padding: '0px 10px' }}
            className={obj.name === selectedIntegration ? classes.selected : ''}
            onClick={() => setSelectedIntegration(obj.name)}
          >
            {obj.name.charAt(0).toUpperCase() + obj.name.slice(1)}
            <p style={{ margin: 0 }}>{obj.count}</p>
          </Typography>
        ))}
      </ScrollBox>
      <ScrollBox
        noScrollBar
        style={{ maxHeight: 330 }}
      >
        <div style={{ width: 245, marginLeft: 5 }}>
          {selectedCategories.map((category, index) => (
            <Fragment key={index}>
              <div className={classes.sectionHeader}>
                {category.charAt(0).toUpperCase() + category.slice(1)}
              </div>
              <div>
                {FullIntegrationsList.filter(i => i.category === category).map((integration, index) => (
                  <div key={index} className={classes.integrationRow}>
                    <div
                      className={classes.integration}
                      onClick={() => handleSelection(integration.name)}
                    >
                      <Checkbox
                        checked={selected.includes(integration.name)}
                        onChange={() => undefined}
                      />
                      <img src={integration.icon} alt={integration.name} style={{ marginRight: 10, height: '18px' }} />
                      {integration.name}
                    </div>
                    <div>
                      <InformationIcon
                        message={integration.description}
                        color={iconColor}
                        placement='right'
                      />
                    </div>
                  </div>
                ))}
              </div>
            </Fragment>
          ))}
        </div>
      </ScrollBox>
    </div>
  )
}
