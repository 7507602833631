import Card from 'library/materialUI/Card'
import UserIdMapping from '../UserIdMapping'
import { useContext, useEffect } from 'react'
import { TabContext } from '../IntegrationPage/TabProvider'
import { IntegrationPageContext } from '../IntegrationPage/IntegrationPageProvider'

interface UserMappingCardProps {
  save: any
  handleEdit: any
  integrationSettings: any
  hubspotUsers: any
}

export default function UserMappingCard ({ save, handleEdit, integrationSettings, hubspotUsers }: UserMappingCardProps) {
  const { setCanSave, shouldSave } = useContext(TabContext)
  const { handleSaved } = useContext(IntegrationPageContext)

  useEffect(() => {
    if (shouldSave) {
      save()
      handleSaved()
    }
  })

  return (
    <Card
      content={
        <>
          <UserIdMapping
            currentMapping={
              integrationSettings.attributes.mappings.user_mapping || []
            }
            integrationSettings={integrationSettings}
            integrationName='HubSpot'
            integrationUsers={hubspotUsers}
            handleEdit={(data: any) => { handleEdit({ type: 'user_mapping', data: data }); setCanSave(true) }}
          />
        </>
      }
    />
  )
}
