export const durations = [
  { value: 1, label: 'Indefinitely', name: 'indefinite' },
  { value: 2, label: '30 Days', name: '30_days' },
  { value: 3, label: '60 Days', name: '60_days' },
  { value: 4, label: '90 Days', name: '90_days' },
  { value: 5, label: 'Until opportunity is created', name: 'until_opp_creation' },
  { value: 6, label: 'Until opportunity status is "closed"', name: 'until_opp_close' }
]

export const fromName = (name: string) => {
  let duration = durations[0]
  durations.forEach((d) => {
    if (d.name === name) {
      duration = d
    }
  })
  return duration
}

export const fromValue = (value: number | null) => {
  let duration = durations[0]
  durations.forEach((d) => {
    if (d.value === value) {
      duration = d
    }
  })
  return duration
}

interface DurationList {
  list: Durations[]
  dict: { [id: Durations['id']]: Durations }
}

export class Durations {
  name: string
  id: number

  constructor ({ value, label }: { value: number; label: string; }) {
    this.name = label
    this.id = value
  }

  static loadAll (): { data: DurationList, isLoading: boolean } {
    const data = serialize()
    return { data: data, isLoading: false }
  }
}

const serialize = (): any => {
  const durationsList: any = []
  const durationsDict: any = {}
  durations.map(duration => {
    durationsDict[duration.value] = new Durations(duration)
    durationsList.push(new Durations(duration))
    return null
  })
  return { list: durationsList, dict: durationsDict }
}
