import { useEffect, useState, useContext } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { usePostageSocket } from 'components/PostageSocket'
import Icon from '@material-ui/core/Icon'
import Tooltip from '@material-ui/core/Tooltip'
import { Link } from 'react-router-dom'
import { getCompanySummary } from 'api/participants'
import { HiddenLink } from 'cf-components/Link'
import { getInitials, Avatar } from 'cf-components/Avatars'
import EmptyState from 'cf-components/EmptyState'
import BaseCard from './BaseCard'
import { salesforce, hubSpot } from 'pages/people/ContactPage/integrations/integration-helpers'
import { ConversationContext } from '../../ConversationContext'

const useStyles = makeStyles(theme => ({
  accountTitle: {
    display: 'flex',
    alignItems: 'center',
    padding: '5px 0'
  },
  accountNameCtn: {
    marginLeft: 10,
    fontSize: 16,
    color: theme.palette.primary.main,
    '& > .link': {
      width: 'max-content',
      textDecoration: 'none',
      color: 'inherit',
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline'
      }
    }
  },
  accountName: {
    fontWeight: 'bold',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: 225
  },
  accountDomain: {
    fontSize: 14
  },
  buttons: {
    fontFamily: 'Poppins, sans serif',
    fontSize: 10,
    height: 23,
    width: 21,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 500,
    cursor: 'pointer'
  },
  imageIcon: {
    height: 14,
    width: 21
  },
  iconRoot: {
    display: 'flex',
    height: 18
  }
}))

export default function CompanyInfoCard (props) {
  const classes = useStyles()
  const { conversation } = useContext(ConversationContext)
  const [account, setAccount] = useState(undefined)
  const [refresh, setRefresh] = useState(true)
  const chatServiceUrl = window.chatServiceUrl
  const participantID = conversation?.relationships.for_participant.data.id
  const domain = account?.attributes?.domain

  useEffect(() => {
    if (!refresh) {
      setRefresh(true)
    }
    // eslint-disable-next-line
  }, [participantID])

  useEffect(() => {
    let mounted = true
    if (participantID && refresh) {
      getCompanySummary({ chatServiceUrl, participantID }).then(res => {
        if (mounted) {
          setRefresh(false)
          if (res?.data?.attributes) {
            setAccount(res.data)
          } else {
            setAccount(null)
          }
        }
      })
    }
    return () => {
      mounted = false
    }
  }, [chatServiceUrl, participantID, refresh])

  usePostageSocket((msg) => {
    if (msg.type === 'conversation_events') {
      if (['capture', 'chat'].includes(msg.attributes.kind) && msg.relationships.conversation.data.id === conversation?.id) {
        setRefresh(true)
      }
    }
  })

  let companyActions = <></>
  const crm_link = account?.attributes?.crm_link
  const integration = account?.attributes?.source_integration_name
  if (crm_link && integration) {
    let imgSrc = ''
    switch (integration) {
      case 'salesforce':
        imgSrc = salesforce.getColoredSVG('#975ce6')
        break
      case 'hubspot':
        imgSrc = hubSpot.getColoredSVG('#975ce6')
        break
      default:
        break
    }
    const title = 'View in ' + integration
    companyActions = (
      <Tooltip title={title}>
        <div
          className={classes.buttons}
          onClick={() => window.open(crm_link, '_blank')}
        >
          <Icon className={classes.iconRoot}>
            <img
              className={classes.imageIcon}
              src={imgSrc}
              alt='CRM'
            />
          </Icon>
        </div>
      </Tooltip>
    )
  }

  return (
    <BaseCard
      title='Company Info'
      id={domain}
      actions={companyActions}
    >
      <CompanyInfoContent
        account={account}
      />
    </BaseCard>
  )
}

/**
 * @param {{ account; }} props
 */
function CompanyInfoContent (props) {
  const { account } = props

  if (!(account?.attributes?.domain || account?.attributes?.name)) {
    return (
      <EmptyState
        sad
        message='No company to display'
      />
    )
  }

  let hq = ''
  if (account.attributes.city && account.attributes.state) {
    hq = account.attributes.city + ', ' + account.attributes.state
  } else if (account.attributes.location) {
    hq = account.attributes.location
  } else if (account.attributes.city) {
    hq = account.attributes.city
  } else if (account.attributes.state) {
    hq = account.attributes.state
  } else if (account.attributes.zipcode) {
    hq = account.attributes.zipcode
  }

  return (
    <>
      <CompanyLogoHeader
        account={account}
      />
      <div>
        <AttributeRow title='Industry' val={account.attributes.industry} />
        <AttributeRow title='Employee Count' val={account.attributes.employee_count_range} />
        <AttributeRow title='Location' val={hq} />
        <AttributeRow title='Annual Revenue' val={account.attributes.annual_revenue_range} />
      </div>
    </>
  )
}

const AttributeRow = withStyles(theme => ({
  attributeRow: {
    color: '#717171',
    paddingTop: 6,
    fontSize: 12
  }
}))(
  /**
   * @param {{
   * classes: import('@material-ui/styles').ClassNameMap;
   * title: string;
   * val: string|number;
   * }} props
   */
  function (props) {
    let { title, val, classes } = props
    if (!val) {
      val = '---'
    }
    return (
      <div className={classes.attributeRow}>{title}: <span>{val}</span></div>
    )
  }
)

/**
 * @param {{ account; }} props
 */
function CompanyLogoHeader (props) {
  const classes = useStyles()
  const { account } = props
  const domain = account.attributes.domain || account.attributes.website
  const name = account.attributes.name || account.attributes.domain || account.attributes.website
  const initials = getInitials(name, 'A')
  const openAccountWebsite = () => {
    if (domain) {
      window.open('https://' + domain, '_blank')
    }
  }

  const AvatarFragment = (
    <Avatar
      avatarUrl={`/api/logo-service/logo/${domain}.png`}
      size='md'
      initials={initials}
    />
  )
  const AvatarWithLink = account.relationships?.account?.data?.id ? (
    <HiddenLink to={'/accounts/' + account.relationships?.account?.data?.id} target='_blank'>
      {AvatarFragment}
    </HiddenLink>
  ) : AvatarFragment

  const AccountNameFragment = (
    <div className={classes.accountName}>
      {name}
    </div>
  )
  const AccountNameWithLink = account.attributes?.domain ? (
    <Link className='link' to={'/accounts/' + account.attributes.domain} target='_blank'>
      {AccountNameFragment}
    </Link>
  ) : AccountNameFragment

  return (
    <div className={classes.accountTitle}>
      {AvatarWithLink}
      <div className={classes.accountNameCtn}>
        {AccountNameWithLink}
        <div className={classes.accountDomain + ' link'} onClick={openAccountWebsite}>
          {domain}
        </div>
      </div>
    </div>
  )
}
