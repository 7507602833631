import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Select, Button, MenuItem, FormControl } from '@material-ui/core'
import CSVTemplate from 'assets/email_capture_domain_template.csv'
import { Modal, ModalSection } from 'library/Modal'
import ButtonTextField from 'components/ButtonTextField'
import CSVDropZone from 'library/segments/CSVDropZone'
import { EditableChip } from 'library/EditableChip'
import { Icon } from 'library/materialUI'

const useStyles = makeStyles(theme => ({
  subsection: {
    marginTop: 25,
    fontSize: '14px',
    fontWeight: 600
  },
  divider: {
    marginTop: 5,
    marginBottom: 5
  },
  domains: {
    display: 'flex',
    marginTop: 8,
    flexFlow: 'wrap'
  },
  chip: {
    margin: 5
  },
  deleteIcon: {
    fill: 'white'
  },
  sectionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  emailForm: {
    fontFamily: 'Poppins, sans serif'
  },
  error: {
    color: 'red'
  },
  errorTitle: {
    fontWeight: 'bold'
  }
}))

function EmailDomainModal (props) {
  const classes = useStyles()
  const { kind, setKind, csvImportErrors } = props
  const [domains, setDomains] = useState([])
  const [tab, setTab] = useState(0)
  const [csvFile, setCSVFile] = useState(undefined)

  let disabled = true

  useEffect(() => {
    if (!props.open) {
      setCSVFile(undefined)
      setTab(0)
      setDomains([])
    }
  }, [props.open])

  function addOption (label) {
    if (label === '') {
      return
    }
    const newDptions = domains.concat(label)
    setDomains(newDptions)
  }

  const handleDelete = optionToDelete => {
    setDomains(domains => domains.filter(option => option !== optionToDelete))
  }

  const handleSubmit = () => {
    props.createDomains({ domains, kind })
  }

  const handleCSVImport = () => {
    props.onCSVImport({ kind, csvFile })
  }

  const downloadTemplateCSV = () => {
    const fileName = CSVTemplate
    const link = document.createElement('a')
    link.setAttribute('href', fileName)
    link.setAttribute('download', 'email_domain_template.csv')
    document.body.appendChild(link)
    link.click()
  }

  if (tab === 0) {
    disabled = domains.length === 0
  } else if (tab === 1) {
    disabled = !csvFile
  }

  const saveButtonText = (domains.length > 1 || tab === 1) ? 'Add Domains' : 'Add Domain'

  return (
    <Modal
      title='Add Email Domains'
      onHide={props.onHide}
      open={props.open}
      size='sm'
      handleSave={tab === 0 ? handleSubmit : handleCSVImport}
      saveBtnText={saveButtonText}
      saveDisabled={disabled}
      closeBtnFunction={tab === 0 ? props.onHide : () => setTab(0)}
      cancelBtnText={tab === 0 ? 'Cancel' : 'Back'}
      leftBtnText={tab === 0 ? 'Upload CSV' : undefined}
      leftBtnFunction={tab === 0 ? () => setTab(1) : undefined}
      leftBtnIcon={tab === 0 ? 'upload' : undefined}
      saveIcon='add'
    >
      {tab === 0 ? (
        <>
          <ModalSection title='Domain Type'>
            <FormControl variant='outlined' fullWidth margin='dense'>
              <Select
                labelId='select-id'
                placeholder='Select Domain Type'
                fullWidth
                margin='dense'
                value={kind}
                onChange={e => setKind(e.target.value)}
              >
                <MenuItem disabled value=''>--Select Domain Type--</MenuItem>
                <MenuItem value='spam'>Spam Domain</MenuItem>
                <MenuItem value='personal'>Personal Domain</MenuItem>
                <MenuItem value='blocked'>Blocked Domain</MenuItem>
              </Select>
            </FormControl>
          </ModalSection>
          <ModalSection title='Email Domains'>
            <div className={classes.domains}>
              {domains.map((option, index) => {
                return (
                  <EditableChip
                    key={index}
                    value={option}
                    handleRemove={() => handleDelete(option)}
                    handleChange={newLabel => {
                      const newDomains = [...domains]
                      newDomains[index] = newLabel
                      setDomains(newDomains)
                    }}
                    className={classes.chip}
                  />
                )
              })}
            </div>
            <div>
              <ButtonTextField addButton={addOption} />
            </div>
          </ModalSection>
        </>
      ) : (
        <>
          <ModalSection
            title='Step 1: Download the CSV Template'
            subtitle="NOTE: 'Domain' fields is required."
          >
            <Button
              variant='outlined'
              color='primary'
              margin='dense'
              onClick={downloadTemplateCSV}
              startIcon={<Icon icon='download' size='sm' />}
              style={{ textTransform: 'none' }}
            >
              Download the template
            </Button>
          </ModalSection>
          <ModalSection
            title='Step 2: Upload your CSV'
          >
            <CSVDropZone
              CSVFile={csvFile}
              handleDrop={setCSVFile}
            />
            {csvImportErrors && csvImportErrors.length ? (
              csvImportErrors.map((error, index) => (
                <div className={classes.error} key={index}>
                  <span className={classes.errorTitle}>{error.title}: </span>
                  {error.detail}
                </div>
              ))
            ) : <></>}
          </ModalSection>
        </>
      )}
    </Modal>
  )
}

export default EmailDomainModal
