import { makeStyles } from '@material-ui/core/styles'
import { Button, Icon, Typography } from 'library/materialUI'

const useStyles = makeStyles((theme) => ({
  warningDiv: {
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.background.warning
  },
  setupBtn: {
    marginLeft: 'auto'
  }
}))

interface WarningDivProps {
  title: string
  notSetup: boolean
  btnRedirect?: string
}

export default function WarningDiv ({ title, notSetup, btnRedirect } : WarningDivProps) {
  const classes = useStyles()
  const text = notSetup ? 'has not been set up yet' : 'is not enabled'
  const btnTxt = notSetup ? 'SET IT UP' : 'ENABLE'
  const setupPath = btnRedirect || `/#/settings/${title.toLowerCase()}`
  return (
    <div className={classes.warningDiv}>
      <div style={{ margin: 10 }}>
        <Icon icon='warning' color='#F3D450' />
      </div>
      <Typography>{title} {text}</Typography>
      <Button className={classes.setupBtn} onClick={() => window.open(setupPath)}>{btnTxt}</Button>
    </div>
  )
}
