import Card from 'library/materialUI/Card'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from 'library/materialUI'
import { useContext, useEffect } from 'react'
import { TabContext } from '../IntegrationPage/TabProvider'
import { IntegrationPageContext } from '../IntegrationPage/IntegrationPageProvider'

const useStyles = makeStyles((theme) => ({
  subtitle1: {
    fontSize: 18,
    fontWeight: 600,
    marginBottom: 15
  },
  subtitle2: {
    fontSize: 15,
    fontWeight: 600,
    marginBottom: 10
  },
  checkboxes: {
    display: 'block'
  },
  bodyText: {
    marginBottom: 30,
    fontSize: 16
  }
}))

interface TaskGenerationCardProps {
  save: any
  handleEdit: any
  integrationSettings: any
}

export default function TaskGenerationCard ({ save, handleEdit, integrationSettings }: TaskGenerationCardProps) {
  const classes = useStyles()
  const { setCanSave, shouldSave } = useContext(TabContext)
  const { handleSaved } = useContext(IntegrationPageContext)

  useEffect(() => {
    if (shouldSave) {
      save()
      handleSaved()
    }
  })

  return (
    <Card
      content={
        <>
          <Typography className={classes.subtitle1}>
            Company Event Task Generation
          </Typography>
          <Typography variant='subtitle1' className={classes.subtitle2}>
            Select if you would like Signals events sent over to Hubspot as tasks. These events include:
          </Typography>
          <Typography variant='body2' className={classes.bodyText}>
            <ul>
              <li>First Page View</li>
              <li>Intent Page Views</li>
              <li>Email Captures</li>
              <li>Phone Captures</li>
              <li>Conversation Started</li>
              <li>Meeting Booked</li>
            </ul>
          </Typography>
          <FormControlLabel
            className={classes.checkboxes}
            control={
              <Checkbox
                color='primary'
                checked={
                  integrationSettings.attributes.config.task_creation
                }
                onChange={(e) => {
                  handleEdit({ type: 'task_creation', data: e.target.checked })
                  setCanSave(true)
                }}
              />
            }
            label='Enable Event Task Creation'
          />
        </>
      }
    />
  )
}
