import Card from 'library/materialUI/Card'
import IntegrationMapping from '../IntegrationMapping'
import Loader from 'library/loading/Loader'
import { IntegrationMappingCls } from 'classes/IntegrationMapping'
import { useContext, useEffect } from 'react'
import { TabContext } from '../IntegrationPage/TabProvider'
import { IntegrationPageContext } from '../IntegrationPage/IntegrationPageProvider'

const contactGroups = [{ value: 'contacts', name: 'Contact Fields' }]
interface FieldMappingCardProps {
  save: any
  integrationSettings: any
  externalFields: any
  focusMapping: any
}

export default function CompanyFieldMappingCard ({ save, externalFields, focusMapping }: FieldMappingCardProps) {
  const { data: crmAccountMapping, isLoading } = IntegrationMappingCls.loadOne('crm_account', 'hubspot')
  const { shouldSave } = useContext(TabContext)
  const { handleSaved } = useContext(IntegrationPageContext)

  useEffect(() => {
    if (shouldSave) {
      save()
      handleSaved()
    }
  })

  return (
    <Card
      content={
        <>
          {isLoading
            ? <Loader type='spinner' />
            : (
              <div>
                <IntegrationMapping
                  integrationName='Hubspot'
                  title='Company Property Mapping'
                  currentMapping={crmAccountMapping.mapping}
                  externalMappingFields={externalFields}
                  forObject='accounts'
                  handleEdit={(data: any) => { IntegrationMappingCls.save('crm_account', data, 'hubspot') }}
                  mappedGroups={contactGroups}
                  focusMapping={focusMapping}
                />
              </div>)}
        </>
      }
    />
  )
}
