import TextField from '@material-ui/core/TextField'
import { Formik } from 'formik'
import * as yup from 'yup'
import Team from 'classes/teams'
import { Modal, ModalSection } from 'library/Modal'
import Picker from 'cf-components/Pickers'

const validationSchema = yup.object().shape({
  name: yup.string().required().trim().min(1),
  teamMemberIDs: yup.array().required().min(1)
})

const initialValues: Pick<Team, 'name' | 'teamMemberIDs'> = {
  name: '',
  teamMemberIDs: []
}

export default function CreateTeamModal (props: {
  open: boolean
  onHide: () => void
}): JSX.Element {
  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      isInitialValid={false}
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm }) => {
        Team.save(values)
        props.onHide()
        resetForm()
      }}
    >
      {({ values, isValid, submitForm, setFieldValue, resetForm, isSubmitting, touched, setFieldTouched, errors }) => (
        <Modal
          open={props.open}
          onHide={() => {
            props.onHide()
            resetForm()
          }}
          title='Create New Team'
          closeBtnFunction={() => {
            props.onHide()
            resetForm()
          }}
          saveIcon='add'
          saveBtnText='Create Team'
          handleSave={submitForm}
          saveDisabled={!isValid || isSubmitting}
        >
          <ModalSection
            title='Team Name'
          >
            <TextField
              value={values.name}
              onChange={e => setFieldValue('name', e.target.value as string)}
              type='text'
              margin='dense'
              placeholder='Support Team, Sales Team, etc.'
              variant='outlined'
              fullWidth
              error={touched.name && !!errors?.name}
              onBlur={() => {
                setFieldValue('name', values.name?.trim())
                setFieldTouched('name')
              }}
            />
          </ModalSection>
          <ModalSection
            title='Add Agents to Team'
            subtitle='Select one or more agents from the list below to add to this team'
          >
            <Picker
              multiple
              placeholder='Select agents'
              objectType='users'
              selection={values.teamMemberIDs}
              setSelection={(values: number[]) => setFieldValue('teamMemberIDs', values)}
            />
          </ModalSection>
        </Modal>
      )}
    </Formik>
  )
}
