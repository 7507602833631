import { makeStyles } from '@material-ui/core/styles'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import { customStaticRanges } from 'cf-components/FilterBar/CustomDateRanges'
import { DateRange, DefinedRange } from 'react-date-range'

const useStyles = makeStyles(theme => ({
  dateRangePicker: {
    width: 365
  },
  definedRange: {
    marginTop: 60,
    width: 180
  }
}))

export type DateObj = { startDate: Date, endDate: Date }

interface DateRangeMenuProps {
  dateRange: any
  setDateRange: (selection: DateObj) => void
}

export default function DateRangeMenu ({ dateRange, setDateRange }: DateRangeMenuProps) {
  const classes = useStyles()
  return (
    <div>
      <div style={{ display: 'flex' }}>
        <DefinedRange
          onChange={(item: { range1: DateObj }) => setDateRange(item.range1)}
          ranges={[dateRange]}
          inputRanges={[]}
          staticRanges={customStaticRanges}
          className={classes.definedRange}
        />
        <DateRange
          onChange={(item: { range1: DateObj }) => setDateRange(item.range1)}
          moveRangeOnFirstSelection={false}
          ranges={[dateRange]}
          scroll={{ enabled: true }}
          className={classes.dateRangePicker}
        />
      </div>
    </div>
  )
}
