import { useReducer, useState } from 'react';
import { isEqual, update, cloneDeep } from 'lodash'

const removeFromArray = (arr: any[], value: any) => {
  return (
    arr.filter(v => v !== value)
  )
}

type ReducerAction = {
  operator?: 'increment' | 'decrement' | 'push' | 'remove'
  value?: any
  path: string
}

const reducer = (state: any, action: ReducerAction) => {
  let updater
  switch (action.operator) {
    case 'increment':
      updater = (n: number) => n + 1
      break
    case 'decrement':
      updater = (n: number) => n - 1
      break
    case 'push':
      updater = (n: any[]) => [...n, action.value]
      break
    case 'remove':
      updater = (n: any[]) => removeFromArray(n, action.value)
      break
    default:
      updater = () => action.value
  }

  const newState = { ...state }
  update(newState, action.path, updater)
  return newState
}

const hasChanged = (initialState: any, currentState: any) => {
  return !isEqual(initialState, currentState)
}

export type StateHandlers = [any, (action: ReducerAction) => any, () => boolean, () => void]

export function useCustomState<T> (initialState: T): StateHandlers {
  const [startState, setStartState] = useState(initialState)
  const [state, dispatch] = useReducer(reducer, initialState)
  return [state, dispatch, () => hasChanged(startState, state), () => setStartState(cloneDeep(state))]
}
