import { Card, Typography } from '@material-ui/core';
import AppPage from 'cf-components/AppPage';
import { makeStyles } from '@material-ui/core/styles'
import Picker from 'library/Picker';
import { useCallback, useEffect, useState } from 'react';
import { Select } from 'cf-components/material-wrappers/MaterialComponents';
import { getScriptSettings, saveScriptSettings } from 'api/admin_only/script_management';

const useStyles = makeStyles(theme => ({
    card: {
        marginBottom: 15,
        paddingLeft: 15,
        paddingTop: 15
    },
    cardTitle: {
        fontSize: 16,
        fontFamily: 'Poppins'
    },
    cardBody: {
        fontSize: 14,
        fontFamily: 'Poppins'
    },
    cardRow: {
        marginBottom: 20
    },
    selection: {
        maxWidth: 650
    }
}))

const dayMapping = {
    Monday: 0,
    Tuesday: 1,
    Wednesday: 2,
    Thursday: 3,
    Friday: 4,
    Saturday: 5,
    Sunday: 6
}
const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']

function ScriptManagementPage (): JSX.Element {
    const classes = useStyles()
    const [statusEmailUsers, setStatusEmailUsers] = useState<any[]>([])
    const [removalAlertUsers, setRemovalAlertUsers] = useState<any[]>([])
    const [sendingDays, setSendingDays] = useState<string[]>([])
    const [hasChanged, setHasChanged] = useState(false)

    const saveProps = {
        disabled: !hasChanged,
        save: () => onSave(),
        saveText: 'Save Settings',
        validPaths: 'status_management'
    }

    const loadSettings = useCallback(() => {
        getScriptSettings()
            .then((response) => {
                setStatusEmailUsers(response.data.attributes.status_emails)
                setRemovalAlertUsers(response.data.attributes.alert_emails)
                setSendingDays(response.data.attributes.status_days)
            })
    }, [])

    const onSave = () => {
        const attributes = {
            statusEmails: statusEmailUsers,
            alertEmails: removalAlertUsers,
            statusDays: sendingDays
        }
        saveScriptSettings({ attributes })
        setHasChanged(!hasChanged)
    }

    useEffect(() => {
        loadSettings()
    }, [loadSettings])

    return (
      <AppPage
        title='Tenant Status Management'
        padding={0}
        saveProps={saveProps}
      >
        <div style={{ marginLeft: 20, marginRight: 20, marginTop: 20 }}>
          <Card raised className={`${classes.card}`}>
            <div className={classes.cardTitle}>Status Emails</div>
            <div className={classes.cardRow}>
              <Typography style={{ marginBottom: 20 }} variant='subtitle1'>
                Who should receive updates of all customers for the following:
                <ul>
                  <li>the status of the script installation on their domains</li>
                  <li>the status of their feature crm integrations</li>
                  <li>the status their ip lookup and contact lookup credits</li>
                </ul>
              </Typography>
              <div className={classes.selection}>
                <Picker
                  selection={statusEmailUsers}
                  setSelection={(value: any) => {
                    setHasChanged(true)
                    setStatusEmailUsers(value)
                  }}
                  multiple
                  objectType='users'
                  label='Select Users'
                  keySelect='email'
                />
              </div>
            </div>
            <div className={classes.cardRow}>
              <Typography variant='subtitle1'>
                Which day(s) each week should the updates be sent?
              </Typography>
              <div className={classes.selection}>
                <Select
                  multiple
                  options={days}
                  onChange={(value: any) => {
                    setHasChanged(true)
                    setSendingDays(value.sort((a: keyof typeof dayMapping, b: keyof typeof dayMapping) => (dayMapping[a] < dayMapping[b] ? -1 : 1)))
                  }}
                  value={sendingDays}
                />
              </div>
            </div>
          </Card>
          <Card raised className={`${classes.card}`}>
            <div className={classes.cardTitle}>Script Removal Alerts</div>
            <div className={classes.cardRow}>
              <Typography variant='subtitle1'>
                Who should receive an alert if a customer removes the Signals script from their website?
              </Typography>
              <div className={classes.selection}>
                <Picker
                  selection={removalAlertUsers}
                  setSelection={(value: any) => {
                    setHasChanged(true)
                    setRemovalAlertUsers(value)
                  }}
                  multiple
                  objectType='users'
                  label='Select Users'
                  keySelect='email'
                />
              </div>
            </div>
          </Card>
        </div>
      </AppPage>
    )
}

export default ScriptManagementPage
