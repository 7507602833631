import { NPSSettings } from 'classes/npsSettings';
import { createContext, ReactNode } from 'react';
import { useCustomState, StateHandlers } from './useCustomState';

interface INPSContext {
  stateHandlers: StateHandlers
}

export const NPSContext = createContext<INPSContext>({
  stateHandlers: [{}, () => undefined, () => false, () => undefined]
})

type NPSContextProviderProps = {
  children: ReactNode
  settings: NPSSettings
}
export function NPSContextProvider (props: NPSContextProviderProps) {
  const stateHandlers = useCustomState(props.settings)

  return (
    <NPSContext.Provider
      value={{ stateHandlers }}
    >
      {props.children}
    </NPSContext.Provider>
  )
}
