import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Modal, ModalSection } from 'library/Modal'
import TextField from '@material-ui/core/TextField'

const useStyles = makeStyles(theme => ({
  textField: {
    marginBottom: 10,
    width: '100%'
  }
}))

const ChangeEmailModal = (props) => {
  const title = 'Change your email'
  const classes = useStyles()

  const [newEmailConfirmation, setNewEmailConfirmation] = useState('')
  const [newEmail, setNewEmail] = useState('')
  const [errors, setErrors] = useState(props.errors)

  const handleSubmit = () => {
    if (errors === '') {
      props.changeEmail(newEmail, newEmailConfirmation)
    }
  }

  return (
    <Modal
      title={title}
      onHide={props.onHide}
      open={props.open}
      saveBtnText={props.saveButtonText || 'Confirm'}
      handleSave={handleSubmit}
      size='xs'
    >
      <ModalSection>
        <TextField
          id='email'
          label='Email'
          className={classes.textField}
          onChange={e => setNewEmail(e.target.value)}
          margin='dense'
          variant='outlined'
        />
        <TextField
          id='confirm_email'
          label='Confirm Email'
          className={classes.textField}
          onChange={e => {
            setErrors('')
            setNewEmailConfirmation(e.target.value)
          }}
          margin='dense'
          variant='outlined'
          error={errors}
        />
        {errors ? <div style={{ color: 'red' }}>{errors}</div> : <div />}
        {props.errors ? <div style={{ color: 'red' }}>{props.errors}</div> : <div />}
      </ModalSection>
    </Modal>
  )
}

export default ChangeEmailModal
