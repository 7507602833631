import { PhotoUpload } from 'library/photoUpload/PhotoUpload'
import { ContentsProps } from '../wizard/OnboardingWizard'

/*
  How to configure CORS on a cloud storage bucket
  Check out this link for an example of how to do it. The config can be found in cloud-storage-cors.json in the root of Portal.
  https://medium.com/wesionary-team/configure-cross-origin-resource-sharing-cors-on-a-google-cloud-storage-bucket-79abc2422f52
*/

export const profilePictureSubtitle = 'Upload a photo of yourself below. ' +
'This photo will appear in the messenger and meeting invitations, so we recommend keeping it classy.'

export function ProfilePicture (props: ContentsProps) {
  window.sessionStorage.setItem('onboardingStep', 'photo')
  return (
    <div>
      <PhotoUpload
        saveRef={props.handleNextRef}
      />
    </div>
  )
}
