import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Card, CardContent, Typography, FormControlLabel, Radio, Button, Tooltip } from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'

const useStyles = makeStyles(theme => ({
  selectorCard: {
    width: 550,
    borderRadius: 10,
    margin: 50
  },
  container: {
    display: 'flex',
    justifyContent: 'center'
  },
  cardContentArea: {
    height: '100%',
    backgroundColor: 'rgba(193, 166, 237, 0.1)',
    borderRadius: 10,
    padding: 30,
    paddingTop: 20
  },
  header: {
    backgroundColor: theme.palette.primary.main
  },
  subtitle1: {
    fontSize: 20,
    fontWeight: 600,
    color: 'white',
    padding: 20
  },
  subtitle2: {
    fontSize: 15,
    fontWeight: 600,
    marginBottom: 10
  },
  bodyText: {
    color: '#808080',
    marginBottom: 10,
    fontSize: 15
  },
  select: {
    borderRadius: 40
  },
  nextButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  radio: {
    display: 'flex',
    width: 200
  },
  infoIcon: {
    marginTop: 'auto',
    marginBottom: 'auto',
    display: 'block'
  }
}))

const autoPromotionValues = [
  { value: true, name: 'Auto-promote', tooltip: 'The winning version based on your success metric will become the new default version.' },
  { value: false, name: 'Switch bot manually', tooltip: 'When your test is complete you will be notified and select which version becomes the default.' }
]

const SelectAutoPromotion = props => {
  const classes = useStyles()
  const state = props.state

  return (
    <>
      <div className={classes.container}>
        <Card className={classes.selectorCard} raised>
          <div className={classes.header}>
            <Typography variant='subtitle1' className={classes.subtitle1}>
              Promotion
            </Typography>
          </div>
          <CardContent
            className={classes.cardContentArea}
          >
            <Typography variant='body2' className={classes.bodyText}>
              When your test is complete choose how you would like to handle selecting a new default version of the bot for your website.
            </Typography>
            {autoPromotionValues.map((option, index) => (
              <div key={index} style={{ display: 'flex' }}>
                <FormControlLabel
                  key={index}
                  control={
                    <Radio
                      color='primary'
                      checked={state.attributes.auto_promote === option.value}
                      onChange={e => props.handleChange({ attr: 'auto_promote', value: option.value })}
                    />
                  }
                  label={option.name}
                  className={classes.radio}
                />
                <div className={classes.infoIcon}>
                  <Tooltip title={option.tooltip} placement='bottom'>
                    <InfoIcon fontSize='small' style={{ verticalAlign: 'sub', color: '#AA88E2' }} />
                  </Tooltip>
                </div>
              </div>
            ))}
            <div className={classes.nextButtonContainer}>
              <Button
                onClick={props.handleNext}
                className={classes.nextButton}
                variant='outlined'
                color='primary'
                disabled={!state.attributes.duration_criteria}
              >
                Next
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>
    </>
  )
}

export default SelectAutoPromotion
