import { makeStyles } from '@material-ui/core/styles'
import { Checkbox } from 'library/materialUI'
import { CardColumn } from './CardContents'
import { ActionsComponent } from './ActionsComponent'

const useStyles = makeStyles(theme => ({
  row: {
    display: 'flex',
    alignItems: 'center',
    borderBottomWidth: '1px',
    borderColor: '#CBCBCB',
    borderBottomStyle: 'solid',
    paddingRight: 20,
    width: 'fit-content',
    minWidth: 'calc(100% - 20px)'
  },
  rowScroll: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 'calc(100% - 400px)'
  },
  avatarDiv: {
    display: 'flex',
    fontSize: '12px',
    maxWidth: '250px'
  },
  accountName: {
    fontSize: '16px',
    fontFamily: 'poppins',
    paddingLeft: 10,
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  headDataItem: {
    display: 'flex',
    justifyContent: 'flex-start',
    fontSize: '16px',
    fontFamily: 'poppins',
    minWidth: 100,
    paddingLeft: 20,
    paddingRight: 20
  },
  dataItem: {
    display: 'flex',
    justifyContent: 'flex-start',
    fontSize: '16px',
    fontFamily: 'poppins',
    minWidth: 100,
    paddingLeft: 40,
    paddingRight: 20
  },
  dataItemColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    fontSize: '16px',
    fontFamily: 'poppins'
  },
  divider: {
    backgroundColor: '#C6ADF1'
  },
  grayText: {
    color: '#00000080',
    fontFamily: 'poppins',
    marginLeft: '5px'
  },
  headColumn: {
    display: 'flex',
    width: 400,
    fontSize: '16px',
    fontFamily: 'poppins',
    alignItems: 'center',
    position: 'sticky',
    borderRight: '1px solid #CBCBCB',
    paddingTop: 20,
    paddingBottom: 20,
    left: 0,
    backgroundColor: 'white',
    zIndex: 1
  }
}))

interface TableListRowProps<Class> {
  row: Class | any
  fields: any[]
  actions: any[]
  selected?: string[]
  setSelected?: (value: any) => void
  allSelected?: boolean
  ignoreSelect?: boolean
}

// This is can be used in a table with select options or without
export default function TableListRow<Class = any> ({ row, fields, actions, allSelected = false, selected = [], setSelected = () => undefined, ignoreSelect }: TableListRowProps<Class>) {
  const classes = useStyles()

  // This should be handled by the parent function or have a generic identifier for classes used
  const handleSelect = () => {
    const index = selected.indexOf(row.id)
    if (index !== -1) {
      const temp = [...selected]
      temp.splice(index, 1)
      setSelected(temp)
    } else {
      setSelected(selected.concat(row.id))
    }
  }

  const headField = fields[0]

  return (
    <div className={classes.row}>
      <div className={classes.headColumn}>
        {!ignoreSelect && (
          <div style={{ marginLeft: 20 }} onClick={() => handleSelect()}>
            <Checkbox
              checked={allSelected || selected.includes(row?.id)}
              onChange={() => undefined}
            />
          </div>)}
        {headField && (
          <div key='head' className={classes.headDataItem}>
            <CardColumn field={headField} row={row} />
          </div>
        )}
        {actions && (
          <div style={{ marginLeft: 'auto', marginRight: 10 }}>
            <ActionsComponent
              actions={actions}
              row={row}
            />
          </div>)}
      </div>
      <div className={classes.rowScroll}>
        {fields.map((field, index) => {
          if (index === 0) return null
          if (field.noDisplay) return null
          return (
            <div style={{ width: field.minWidth ? field.minWidth : 100 }} key={index} className={classes.dataItem}>
              <CardColumn field={field} row={row} width={field.minWidth || 100} />
            </div>
          )
        })}
      </div>
    </div>
  )
}
