import { useState, useRef, useContext, cloneElement } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import NoChatsIcon from 'img/NoChatsIcon.svg'
import ChatsSelectedIcon from 'img/ChatsSelected.svg'
import Divider from '@material-ui/core/Divider'
import Fab from '@material-ui/core/Fab'
import CancelIcon from '@material-ui/icons/Cancel'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import ToggleOffIcon from '@material-ui/icons/ToggleOff'
import TagIcon from '@material-ui/icons/LocalOffer'
import GroupAddIcon from '@material-ui/icons/GroupAdd'
import ContactsIcon from '@material-ui/icons/Contacts'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import RateReviewIcon from '@material-ui/icons/RateReview'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Button from '@material-ui/core/Button'
import { batchTagConversations } from 'api/tags'
import { batchUpdateStatus, batchUpdateAgents, batchDeleteConversations, batchUpdateReviewStatus } from 'api/conversations'
import CustomizedSnackbar from 'components/CustomizedSnackbar'
import HubSpotIcon from 'img/hubspotClear.png'
import SalesForceCloud from 'img/SalesForce_Cloud.svg'
import Icon from '@material-ui/core/Icon'
import { syncContactToSalesforce } from 'api/salesforce'
import { syncContactToHubSpot } from 'api/hubspot'
import AccessControl from 'components/AccessControl'
import { TenantDataContext } from './tenant-data-context'
import DeleteModal from 'components/DeleteModal'

const useStyles = makeStyles(theme => ({
  selectedConversationsPage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
  },
  header: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: '1.3em'
  },
  imageDiv: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: -50
  },
  subheader: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 10,
    fontSize: '1.1em'
  },
  icon: {
    marginRight: 15,
    display: 'flex'
  },
  divider: {
    marginTop: 20,
    marginBottom: 10
  },
  menu: {
    marginTop: 20
  },
  menuItem: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 15
  },
  button: {
    backgroundColor: '#F0E3FF',
    color: theme.palette.primary.main,
    boxShadow: 'none'
  },
  addTagLabel: {
    color: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 600,
    fontSize: '14px',
    borderBottom: '1px solid #ddd',
    padding: 8
  },
  tagLabel: {
    fontSize: '.9em'
  },
  addTagsButtonDiv: {
    padding: '15px 10px 15px 10px'
  },
  tagsDiv: {
    maxHeight: 250,
    overflow: 'auto'
  }
}))

const StyledMenuItem = withStyles(theme => ({
  root: {
    minHeight: 20,
    paddingTop: 0,
    paddingBottom: 0,
    width: 140
  }
}))(MenuItem)

function TagPicker (props) {
  const [tagIDs, setTagIDs] = useState([])
  const classes = useStyles()

  function handleChange (event, id) {
    let newIDs
    if (event.target.checked) {
      newIDs = [...tagIDs].concat(id)
    } else {
      newIDs = tagIDs.filter(t => t !== id)
    }
    setTagIDs(newIDs)
  }

  function handleTagging () {
    const chatServiceUrl = window.chatServiceUrl
    const conversationIDs = props.conversationIDs
    batchTagConversations({ chatServiceUrl, conversationIDs, tagIDs })
      .then(response => {
        props.handleClose()
        props.setSnackState({
          open: true,
          variant: 'success',
          message: 'Your tags have been added'
        })
      })
  }

  return (
    <div>
      <div className={classes.addTagLabel}>
        Add tags to these conversations
      </div>
      <div className={classes.tagsDiv}>
        <MenuList style={{ minWidth: 250 }}>
          {props.tags.map(t => (
            <MenuItem key={t.id} style={{ paddingTop: 1, paddingBottom: 1 }}>
              <FormControlLabel
                label={t.attributes.tag_name}
                classes={{ label: classes.tagLabel }}
                control={
                  <Checkbox
                    checked={tagIDs.includes(t.id)}
                    onChange={e => { handleChange(e, t.id) }}
                    color='primary'
                    style={{ padding: 5 }}
                  />
                }
              />
            </MenuItem>
          ))}
        </MenuList>
      </div>
      <Divider />
      <div className={classes.addTagsButtonDiv}>
        <Button
          variant='contained'
          color='primary'
          fullWidth
          disabled={tagIDs.length === 0}
          onClick={handleTagging}
        >
          Tag Conversation
        </Button>
      </div>
    </div>
  )
}

function AgentPicker (props) {
  const [participantIDs, setParticipantIDs] = useState([])
  const classes = useStyles()

  function handleChange (event, id) {
    let newIDs
    if (event.target.checked) {
      newIDs = [...participantIDs].concat(id)
    } else {
      newIDs = participantIDs.filter(t => t !== id)
    }
    setParticipantIDs(newIDs)
  }

  function handleAdding () {
    const conversationIDs = props.conversationIDs
    batchUpdateAgents({ conversationIDs, participantIDs })
      .then(response => {
        props.handleClose()
        props.setSnackState({
          open: true,
          variant: 'success',
          message: 'Agents have been added'
        })
      })
  }

  return (
    <div>
      <div className={classes.addTagLabel}>
        Add agents to these conversations
      </div>
      <div className={classes.tagsDiv}>
        <MenuList style={{ minWidth: 250 }}>
          {props?.users?.list?.map(a => (
            <MenuItem key={a.id} style={{ paddingTop: 1, paddingBottom: 1 }}>
              <FormControlLabel
                label={
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {a.name}
                  </div>
                }
                classes={{ label: classes.tagLabel }}
                control={
                  <Checkbox
                    checked={participantIDs.includes(props.agentDict.users[a.id])}
                    onChange={e => { handleChange(e, props.agentDict.users[a.id]) }}
                    color='primary'
                    style={{ padding: 5 }}
                  />
                }
              />
            </MenuItem>
          ))}
        </MenuList>
      </div>
      <Divider />
      <div className={classes.addTagsButtonDiv}>
        <Button
          variant='contained'
          color='primary'
          fullWidth
          disabled={participantIDs.length === 0}
          onClick={handleAdding}
        >
          Add Agents
        </Button>
      </div>
    </div>
  )
}

function ReviewPopup (props) {
  function handleReview (reviewed) {
    const conversationIDs = props.conversationIDs
    batchUpdateReviewStatus({ conversationIDs, reviewed })
      .then(response => {
        props.refresh()
        props.handleClose()
        props.setSnackState({
          open: true,
          variant: 'success',
          message: 'Your conversations have been updated'
        })
      })
  }

  return (
    <MenuList>
      {[{ label: 'Reviewed', status: true }, { label: 'Needs Review', status: false }].map((option, index) => (
        <StyledMenuItem
          key={index}
          onClick={() => handleReview(option.status)}
        >
          {option.label}
        </StyledMenuItem>
      ))}
    </MenuList>
  )
}

function StatusPopup (props) {
  function handleStatus (status) {
    const conversationIDs = props.conversationIDs
    batchUpdateStatus({ conversationIDs, status })
      .then(response => {
        props.refresh()
        props.handleClose()
        props.setSnackState({
          open: true,
          variant: 'success',
          message: 'Your conversations have been updated'
        })
      })
  }

  return (
    <MenuList>
      {[{ label: 'Open', status: 'open' }, { label: 'Close', status: 'closed' }].map((option, index) => (
        <StyledMenuItem
          key={option}
          onClick={() => handleStatus(option.status)}
        >
          {option.label}
        </StyledMenuItem>
      ))}
    </MenuList>
  )
}

function PopperFab (props) {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const anchorRef = useRef(null)

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setOpen(false)
  }

  return (
    <>
      <Fab
        onClick={handleToggle}
        variant='extended'
        size='small'
        ref={anchorRef}
        className={classes.button}
      >
        <props.icon />
        <div style={{ marginLeft: 15, marginRight: 10 }}>
          {props.title}
        </div>
        <ArrowDropDownIcon />
      </Fab>
      <Popper open={open} anchorEl={anchorRef.current} transition>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom'
            }}
          >
            <Paper id='menu-list-grow'>
              <ClickAwayListener onClickAway={handleClose}>
                <div>
                  {cloneElement(props.children, { handleClose: () => setOpen(false) })}
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}

function SyncButton (props) {
  const classes = useStyles()
  const contactIDs = props.selectedConversations.map(c => c.relationships.for_contact.data.id).filter(id => id != null)

  function startSync (integration) {
    if (contactIDs.length < 1) {
      props.setSnackState({
        open: true,
        variant: 'error',
        message: 'Unable to sync: no contact information'
      })
    }
    if (integration === 'Salesforce') {
      props.syncToSalesforce(contactIDs)
    } else if (integration === 'HubSpot') {
      props.syncToHubspot(contactIDs)
    }
  }

  if (props.salesforceEnabled && props.hubSpotEnabled) {
    return (
      <PopperFab
        title='Sync to ...'
        icon={ContactsIcon}
      >
        <MenuList>
          {['Salesforce', 'HubSpot'].map((option, index) => (
            <StyledMenuItem
              key={option}
              onClick={() => { startSync(option) }}
            >
              {option}
            </StyledMenuItem>
          ))}
        </MenuList>
      </PopperFab>
    )
  }
  let text = 'Sync to Salesforce'
  let src = SalesForceCloud
  let integration = 'Salesforce'
  if (props.hubSpotEnabled) {
    text = 'Sync to HubSpot'
    src = HubSpotIcon
    integration = 'HubSpot'
  }

  return (
    <Fab
      className={classes.button}
      variant='extended'
      size='small'
      onClick={() => { startSync(integration) }}
    >
      <Icon className={classes.icon}>
        <img className={classes.imageIcon} src={src} alt='Salesforce cloud' />
      </Icon>
      <div style={{ marginRight: 10 }}>
        {text}
      </div>
    </Fab>
  )
}

function SelectedConversations (props) {
  const classes = useStyles()
  const [snackState, setSnackState] = useState({ open: false })
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const { users, tags, agentDict } = useContext(TenantDataContext)

  const showSyncButton = props.hubSpotEnabled || props.salesforceEnabled
  const conversationIDs = props.selectedConversations.map(c => c.id)

  function syncToSalesforce (personIDs) {
    syncContactToSalesforce({ personIDs })
      .then(response => {
        if (response.ok) {
          setSnackState({
            open: true,
            variant: 'success',
            message: 'Contacts successfully synced to Salesforce'
          })
        } else if (response.errors) {
          const error = response.errors?.[0]
          const errorText = error.title
          setSnackState({
            open: true,
            variant: 'error',
            message: errorText
          })
        }
      })
  }

  function syncToHubSpot (personIDs) {
    syncContactToHubSpot({ personIDs })
      .then(status => {
        if (status === 200) {
          setSnackState({
            open: true,
            variant: 'success',
            message: 'Contacts successfully synced to HubSpot'
          })
        } else {
          setSnackState({
            open: true,
            variant: 'error',
            message: 'There was an error syncing to HubSpot'
          })
        }
      })
  }

  const nonDeletedTags = tags?.filter(t => !t.attributes.deleted_timestamp) || []

  if (props.selectedConversations.length) {
    const count = props.selectedConversations.length
    const header = count + ' conversations selected'
    return (
      <div className={classes.selectedConversationsPage}>
        <div>
          <div className={classes.imageDiv}>
            <img src={ChatsSelectedIcon} height={200} alt='Chats Icon' />
          </div>
          <div className={classes.header}>
            {header}
          </div>
          <div className={classes.menu}>
            <div className={classes.menuItem}>
              <PopperFab
                title='Status'
                icon={ToggleOffIcon}
              >
                <StatusPopup
                  conversationIDs={conversationIDs}
                  setSnackState={setSnackState}
                  refresh={props.refresh}
                />
              </PopperFab>
            </div>
            <AccessControl requiredPermission='manager'>
              <div className={classes.menuItem}>
                <PopperFab
                  title='Conversation Review'
                  icon={RateReviewIcon}
                >
                  <ReviewPopup
                    conversationIDs={conversationIDs}
                    setSnackState={setSnackState}
                    refresh={props.refresh}
                  />
                </PopperFab>
              </div>
            </AccessControl>
            <div className={classes.menuItem}>
              <PopperFab
                title='Tags'
                icon={TagIcon}
              >
                <TagPicker
                  tags={nonDeletedTags}
                  conversationIDs={conversationIDs}
                  setSnackState={setSnackState}
                />
              </PopperFab>
            </div>
            <div className={classes.menuItem}>
              <PopperFab
                title='Agents'
                icon={GroupAddIcon}
              >
                <AgentPicker
                  users={users}
                  agentDict={agentDict}
                  conversationIDs={conversationIDs}
                  setSnackState={setSnackState}
                />
              </PopperFab>
            </div>
            {showSyncButton &&
              <div className={classes.menuItem}>
                <SyncButton
                  salesforceEnabled={props.salesforceEnabled}
                  hubSpotEnabled={props.hubSpotEnabled}
                  selectedConversations={props.selectedConversations}
                  syncToSalesforce={syncToSalesforce}
                  syncToHubSpot={syncToHubSpot}
                  setSnackState={setSnackState}
                />
              </div>}
            <AccessControl requiredPermission='manager'>
              <div className={classes.menuItem}>
                <Fab
                  className={classes.button}
                  variant='extended'
                  size='small'
                  onClick={() => setDeleteModalOpen(true)}
                >
                  <DeleteForeverIcon className={classes.icon} />
                  <div style={{ marginRight: 10 }}>
                    Delete
                  </div>
                </Fab>
              </div>
            </AccessControl>
            <Divider className={classes.divider} />
            <div className={classes.menuItem}>
              <Fab
                color='primary'
                variant='extended'
                size='small'
                onClick={props.handleCancel}
              >
                <CancelIcon className={classes.icon} />
                <div style={{ marginRight: 10 }}>
                  Cancel
                </div>
              </Fab>
            </div>
          </div>
        </div>
        <CustomizedSnackbar
          state={snackState}
          handler={setSnackState}
        />
        <DeleteModal
          open={deleteModalOpen}
          onHide={() => setDeleteModalOpen(false)}
          deleteObject={() => {
            batchDeleteConversations({
              conversationIDs: conversationIDs
            }).then(response => {
              props.refresh()
              setSnackState({
                open: true,
                variant: 'success',
                message: 'Conversations deleted'
              })
            })
            setDeleteModalOpen(false)
            props.handleCancel()
          }}
          message='Are you sure you want to delete these conversations? This action cannot be undone.'
          title='Delete Conversations'
        />
      </div>
    )
  }
  return (
    <div className={classes.selectedConversationsPage}>
      <div>
        <div className={classes.imageDiv}>
          <img src={NoChatsIcon} height={150} alt='No chats icon' />
        </div>
        <div className={classes.header}>
          Select a conversation
        </div>
        <div className={classes.subheader}>
          Nothing is selected
        </div>
      </div>
    </div>
  )
}

export default SelectedConversations
