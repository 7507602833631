import { FormikErrors } from 'formik'
import { Typography } from 'library/materialUI'

interface FormikErrorProps {
  errorText: string | FormikErrors<any> | undefined
}

export default function FormikError ({ errorText } : FormikErrorProps) {
  return (
    <Typography style={{ color: 'red', fontSize: '13px', margin: '0' }}>
      {errorText}
    </Typography>
  )
}
