import React, { ReactNode } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ActionsMenu from 'cf-components/ActionsMenu'
import { ScrollBox } from 'cf-components/ScrollBox'

const useStyles = makeStyles(theme => ({
  dashboardCard: {
    borderRadius: 10,
    border: '1px solid #D3D3D3',
    backgroundColor: 'white',
    height: 'calc(100% - 5px)',
    padding: '20px',
    position: 'relative',
    fontFamily: 'poppins'
  },
  dashboardTabCard: {
    borderRadius: 10,
    borderTopLeftRadius: 0,
    border: '1px solid #D3D3D3',
    borderTop: 'none',
    backgroundColor: 'white',
    height: 'calc(100% - 5px)',
    padding: '20px',
    position: 'relative',
    fontFamily: 'poppins'
  },
  dashboardHeader: {
    marginBottom: 5,
    color: '#575757',
    fontSize: 20,
    fontFamily: 'poppins',
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: 600
  },
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    fontSize: '12px',
    fontFamily: 'poppins',
    marginRight: '5px'
  },
  maxHeight: {
    maxHeight: 390
  }
}))

interface DashboardCardProps {
  actions?: any
  hideHeader?: boolean
  hideTitle?: boolean
  topBorderRadius?: boolean
  childOverflow?: boolean
  children: ReactNode
  viewAllLabel?: () => void
  title: string | JSX.Element
  noScrollbox?: boolean
  titleAction?: JSX.Element
  closeAction?: boolean
}

function DashboardCard ({ actions, hideHeader, hideTitle, topBorderRadius, children, viewAllLabel, title, childOverflow, titleAction, noScrollbox = false, closeAction }: DashboardCardProps) {
  const classes = useStyles()
  const showActions = !!actions

  return (
    <div className={topBorderRadius ? classes.dashboardTabCard : childOverflow ? `${classes.dashboardCard} ${classes.maxHeight}` : classes.dashboardCard}>
      <div className={classes.dashboardHeader}>
        {!hideHeader && (
          <>
            <div style={{ minHeight: 40, display: 'flex', alignItems: 'center', width: '100%', justifyContent: closeAction ? undefined : 'space-between' }}>
              {hideTitle ? '' : title}
              {titleAction || <></>}
            </div>
            <div>
              {(showActions && !viewAllLabel)
              ? <ActionsMenu
                  actions={actions}
                /> : (viewAllLabel &&
                  <div onClick={() => viewAllLabel()} className={classes.link}>
                    view all
                  </div>
                )}
            </div>
          </>
        )}
      </div>
      {noScrollbox ? (
        <div
          style={{
            height: 'calc(100% - 50px)'
          }}
        >
          {children}
        </div>)
      : (
        <ScrollBox
          style={{
            height: 'calc(100% - 50px)',
            overflow: childOverflow ? 'auto' : ''
          }}
          noScrollBar
        >
          {children}
        </ScrollBox>)}
    </div>
  )
}

export default DashboardCard
