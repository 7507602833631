import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from 'cf-components/Table'
import { AvatarWithInfo, getColor, getInitials } from 'cf-components/Avatars'
import dateFormat from 'dateformat'
import { ListItemCard } from 'cf-components/CardList'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/DeleteForever'
import ActionsMenu from 'cf-components/ActionsMenu'
import sanitizeHTML from 'sanitize-html'

const useStyles = makeStyles(theme => ({
  question: {
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    fontSize: '.9em'
  },
  faqCard: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 2fr 1fr 50px',
    padding: 15,
    gridGap: 20,
    cursor: 'pointer',
    alignItems: 'center'
  },
  headerClass: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 2fr 1fr 50px',
    padding: '5px 15px',
    gridGap: 20
  }
}))

function FAQTable (props) {
  const editFAQ = props.editFAQ
  const classes = useStyles()
  const handleDelete = props.handleDelete

  const rows = props.rows ? props.rows.map(row => {
    const name = row.attributes.name
    const initials = getInitials(name, 'F')
    const createdDate = dateFormat(row.attributes.created_timestamp, 'mmm dd, yyyy')
    const question = row.attributes.questions[0]
    const response = row.attributes.response
    const searchField = name + question + response
    return (
      {
        name: name,
        searchField: searchField,
        initials: initials,
        createdDate: createdDate,
        id: row.id,
        question: question,
        response: response,
        questions: row.attributes.questions,
        tags: row.attributes.tags,
        keywords: row.attributes.keywords,
        excluded: row.attributes.excluded_keywords
      })
  }) : null

  function FAQCard (props) {
    const color = getColor(props.index)
    const row = props.row
    const edit = () => editFAQ({
      name: row.name,
      questions: row.questions,
      id: row.id,
      response: row.response,
      tags: row.tags,
      keywords: row.keywords,
      excluded: row.excluded
    })

    const actions = [
      { name: 'Edit FAQ', action: edit, icon: EditIcon },
      { name: 'Delete FAQ', action: () => handleDelete(row.id), icon: DeleteIcon }
    ]

    const response = sanitizeHTML(row.response, {
      allowedTags: [],
      allowedAttributes: []
    })

    return (
      <ListItemCard>
        <div className={classes.faqCard} onClick={edit}>
          <div style={{ width: '100%', overflow: 'hidden' }}>
            <AvatarWithInfo
              title={row.name}
              initials={row.initials}
              subtitle='FAQ'
              avatarColor={color}
              variant='rounded'
            />
          </div>
          <div className={classes.question}>
            {row.question}
          </div>
          <div className={classes.question}>
            {response}
          </div>
          <div style={{ fontSize: '.95em' }}>
            {row.createdDate}
          </div>
          <ActionsMenu
            actions={actions}
          />
        </div>
      </ListItemCard>
    )
  }

  const sortValues = [
    { label: 'Name', id: 'name', header: true },
    { label: 'Example Question', id: 'question', header: true },
    { label: 'Response', id: 'response', header: true },
    { label: 'Created Date', id: 'createdDate', header: true }
  ]

  return (
    <Table
      rows={rows}
      card={FAQCard}
      sortValues={sortValues}
      header
      headerClass={classes.headerClass}
      startingSortColumn='name'
    />
  )
}

export default FAQTable
