import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Card, Typography } from '@material-ui/core'
import { ErrorBoundary } from 'react-error-boundary'
import { SidebarContext } from '../Sidebar'

const useStyles = makeStyles(theme => ({
  card: {
    padding: '11px 12px 10px 12px',

    position: 'relative'
  },
  cardHeader: {
    marginBottom: 7,
    fontSize: 16,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  cardTitle: {
    flexGrow: 0,
    flexShrink: 1,
    flexBasis: 'auto',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    color: '#585858',
    lineHeight: 1.2
  },
  headerImage: {
    height: 16
  },
  cardActionsStyle: {
    flexGrow: 1,
    flexShrink: 0,
    flexBasis: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginLeft: 9
  }
}))

function CardActions (props) {
  const classes = useStyles()
  if (props.hideRemoveButton) {
    return <></>
  }

  if (props.actions) {
    return (
      <div className={classes.cardActionsStyle}>
        {props.actions}
      </div>
    )
  } else {
    return <></>
  }
}

function BaseCard (props) {
  const classes = useStyles()
  const { editMode, removeCardFromList } = useContext(SidebarContext)
  const style = {}
  if (props.allowOverflow) {
    style.overflow = 'initial'
  }

  const FallBackCard = ({ resetErrorBoundary }) => {
    const classes = useStyles()

    return (
      <Card className={classes.card}>
        <div className={classes.cardHeader}>
          <div className={classes.cardTitle}>{props.title}</div>
          <CardActions
            editMode={editMode}
            removeCardFromList={resetErrorBoundary}
          />
        </div>
        <Typography style={{ textAlign: 'center', margin: '10px 0px' }}>
          Something went wrong.
        </Typography>
      </Card>
    )
  }

  const handleReset = () => {
    // TODO: Handle the reset state accordingly
  }

  return (
    <ErrorBoundary
      FallbackComponent={FallBackCard}
      onReset={handleReset}
    >
      <Card className={classes.card} onClick={props.onClick} style={style}>
        <div className={classes.cardHeader}>
          <div className={classes.cardTitle}>{props.title}</div>
          <CardActions
            actions={props.actions}
            editMode={editMode}
            hideRemoveButton={props.hideRemoveButton}
            removeCardFromList={() => removeCardFromList(props.id)}
          />
        </div>
        <div>
          {props.children}
        </div>
      </Card>
    </ErrorBoundary>
  )
}

export default BaseCard
