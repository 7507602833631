import { useContext, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ConversionTimeline from './ConversionTimeline'
import TestDetailsCard from './TestDetailsCard'
import TestStatsTable from './TestOverviewTable'
import SecondaryMetricsPage from './SecondaryMetricsPage'
import { getTestStatistics, getGoalTimeline } from 'api/bot_test_reports'
import { SessionContext } from 'session-context'
import { useQuery } from 'react-query'
import EndTestModal from './EndTestModal'
import './reports.css'
import Loader from 'library/loading/Loader'

const useStyles = makeStyles(theme => ({
  dashboardPage: {
    height: '100vh'
  },
  dashboard: {
    fontFamily: 'Poppins, sans serif',
    marginTop: 1
  },
  overviewSection: {
    padding: 20
  },
  graphContent: {
    width: '100%',
    marginBottom: 15
  },
  topCards: {
    display: 'grid',
    gridTemplateColumns: '310px auto',
    gridGap: 20,
    marginBottom: 15
  },
  loadingDiv: {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))

function DashboardContents (props) {
  const classes = useStyles()
  const { user } = useContext(SessionContext)
  const [modalOpen, setModalOpen] = useState(false)
  const chatServiceUrl = user.links.chat_service
  const testID = props.testID
  const testData = props.testData

  const statsQuery = useQuery('stats' + testID, () => getTestStatistics({ chatServiceUrl, testID }))
  const goalQuery = useQuery('goals' + testID, () => getGoalTimeline({ chatServiceUrl, testID }))

  if (goalQuery.isLoading || statsQuery.isLoading || !testData) {
    return (
      <div className={classes.loadingDiv}>
        <Loader type='spinner' size='lg' />
      </div>
    )
  }

  const rows = statsQuery.data.data ? statsQuery.data.data : []
  const winningFlow = testData.attributes.winning_flow_id
  const primaryMetric = testData.attributes.success_metric
  let defaultFlow = testData.attributes.old_flow_id

  if (statsQuery.data.data) {
    defaultFlow = statsQuery.data.data.sort((a, b) => b.attributes.win_probability - a.attributes.win_probability).map(f => f.id)[0]
  }

  const now = new Date()
  const test = testData.attributes
  const endDate = new Date(test.end_time)
  const startDate = new Date(test.start_time)
  const winningFlowID = test.winning_flow_id
  const auto = test.auto_promote
  const criteria = test.duration_criteria
  let status = test.status

  if (startDate > now && status === 'active') {
    status = 'scheduled'
  }
  if (now > endDate && status === 'active' && criteria === 'time_based') {
    status = 'completed'
  }
  if (status === 'completed' && !winningFlowID && !auto) {
    status = 'pending'
  }

  const originalFlowID = testData.attributes.flow_ids[0]

  return (
    <>
      <div className={classes.dashboard} id='dashboard'>
        <div className={classes.overviewSection} id='dashboard-content'>
          <div className={classes.topCards}>
            <div>
              <TestDetailsCard
                test={testData}
                rows={rows}
                chatServiceUrl={chatServiceUrl}
                showModal={() => setModalOpen(true)}
                status={status}
                originalFlowID={originalFlowID}
              />
            </div>
            <div>
              <TestStatsTable
                rows={rows}
                winningFlow={winningFlow}
                status={status}
                chatServiceUrl={chatServiceUrl}
                originalFlowID={originalFlowID}
              />
            </div>
          </div>
          <div className={classes.graphContent}>
            <ConversionTimeline
              chartData={goalQuery.data.data.attributes.chart_data}
              startDate={startDate}
            />
          </div>
          <div>
            <SecondaryMetricsPage
              rows={rows}
              primaryMetric={primaryMetric}
              originalFlowID={originalFlowID}
            />
          </div>
        </div>
      </div>
      <EndTestModal
        open={modalOpen}
        onHide={() => setModalOpen(false)}
        test={testData}
        stats={statsQuery.data.data}
        chatServiceUrl={chatServiceUrl}
        defaultFlow={defaultFlow}
        status={status}
      />
    </>
  )
}

export default DashboardContents
