import { useDoQuery } from './useDoQuery'
import { GenericObject } from './genericObject'
import { LoadAllProps } from './queryHelpers'

interface IndustryList {
  list: Industry[]
  dict: { [id: Industry['id']]: Industry }
}

const initialState = {
  id: '',
  name: ''
}

export class Industry extends GenericObject {
  name: string

  static loadAll (loadAllProps?: LoadAllProps): { data: IndustryList, isLoading: boolean } {
    return useDoQuery({
      path: '/organizations/distinct_values?field=industry',
      objectClass: Industry,
      useChatURL: true,
      searchParams: loadAllProps?.searchParams
    })
  }

  constructor ({ row }: { row?: string }) {
    super({ row: { id: row, attributes: { name: row } } })
    const industry = row || initialState.name
    this.name = industry
  }
}
