import { jsPlumbToolkit, jsPlumbUtil } from 'jsplumbtoolkit'
import { SequenceContext } from './convertToSequence'

type FlowUpdateParams = {
  toolkit: jsPlumbToolkit,
  context: SequenceContext
}

export const handleFlowUpdates = ({ toolkit, context }: FlowUpdateParams) => {
  toolkit.remove(toolkit.getSelection())
  addNode({ toolkit, context })
}

const addNode = ({ toolkit, context }: FlowUpdateParams) => {
  const { sequenceID, coordinates, outgoingPorts } = context

  const ports = outgoingPorts.map(p => ({
    id: p.id,
    label: p.label
  }))
  const id = jsPlumbUtil.uuid().substring(0, 8)

  const data = {
    id,
    ports,
    top: coordinates.top,
    left: coordinates.left,
    kind: 'Sequence',
    settings: {
      sequenceID,
      description: context.description,
      name: context.name
    }
  }

  toolkit.addFactoryNode('sequence', data,
    function (newNode: any) {
      toolkit.batch(() => {
        for (const port of context.incomingPortIDs) {
          const id = jsPlumbUtil.uuid()
          const data = {
            id: id,
            type: 'common'
          }
          toolkit.addEdge({ source: port, target: newNode, data })
        }
        for (const p of outgoingPorts) {
          const id = jsPlumbUtil.uuid()
          const data = {
            id: id,
            type: 'common'
          }
          const source = newNode.id + '.' + p.id
          const target = p.target
          toolkit.addEdge({ source, target, data })
        }
      })
    }
  )
}
