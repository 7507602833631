export const employeeRanges: { label: string, value: string }[] = [
  { label: '1-9', value: '1_9' },
  { label: '10-19', value: '10_19' },
  { label: '20-49', value: '20_49' },
  { label: '50-99', value: '50_99' },
  { label: '100-249', value: '100_249' },
  { label: '250-499', value: '250_499' },
  { label: '500-999', value: '500_999' },
  { label: '1,000-4,999', value: '1000_4999' },
  { label: '5,000-9,999', value: '5000_9999' },
  { label: '10,000+', value: '10000' }
]

export const fromName = (name: string) => {
  let range = employeeRanges[0]
  employeeRanges.forEach((r) => {
    if (r.label === name) {
      range = r
    }
  })
  return range
}

interface EmployeeRangeList {
  list: EmployeeRange[]
  dict: { [id: EmployeeRange['id']]: EmployeeRange }
}

export class EmployeeRange {
  id: string
  name: string

  constructor ({ value, label }: { value: string; label: string; }) {
    this.name = label
    this.id = value
  }

  static loadAll (): { data: EmployeeRangeList, isLoading: boolean } {
    const data = serialize()
    return { data: data, isLoading: false }
  }
}

const serialize = (): any => {
  const rangeList: any = []
  const rangeDict: any = {}
  employeeRanges.map(range => {
    rangeDict[range.value] = new EmployeeRange(range)
    rangeList.push(new EmployeeRange(range))
    return null
  })
  return { list: rangeList, dict: rangeDict }
}
