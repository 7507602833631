import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import WarningRoundedIcon from '@material-ui/icons/WarningRounded'
import Tooltip from '@material-ui/core/Tooltip'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CancelIcon from '@material-ui/icons/Cancel'
import ErrorIcon from '@material-ui/icons/Error'
import ScheduleIcon from '@material-ui/icons/Schedule'

const useStyles = makeStyles((theme) => ({
  icon: {
    cursor: 'default',
    height: 30,
    width: 30
  }
}))

function InactiveUserMessage (props) {
  const classes = useStyles()

  let color = '#AAA'

  let tooltipText = 'User has never logged in to chat'
  if (!props.sendChatPermission) {
    tooltipText = 'User does not have permission to send chats'
    color = 'rgba(255, 188, 3)'
  }

  return (
    <Tooltip title={tooltipText} placement='left'>
      <WarningRoundedIcon className={classes.icon} style={{ paddingLeft: 17, color: color }} />
    </Tooltip>
  )
}

function CalendarSetUpIcon (props) {
  const classes = useStyles()
  const syncStatus = props.syncStatus
  let Icon = CancelIcon
  let color = 'rgba(219, 46, 46, 0.7)'
  let text = 'User has never linked their calendar'
  if (syncStatus === 'linked') {
    Icon = CheckCircleIcon
    color = 'rgba(0, 135, 7, 0.7)'
    text = "User's calendar is linked successfully"
  } else if (syncStatus === 'meeting_info_missing') {
    Icon = ErrorIcon
    color = 'rgba(255, 188, 3)'
    text = 'Meeting settings are missing.'
  } else if (syncStatus !== 'never') {
    Icon = ErrorIcon
    color = 'rgba(255, 188, 3)'
    text = "User's calendar is unlinked"
  }
  if (!props.bookMeetingsPermission) {
    Icon = CancelIcon
    color = 'rgba(219, 46, 46, 0.7)'
    text = 'User does not have permission to book meetings'
  }
  return (
    <Tooltip title={text} placement='right'>
      <Icon className={classes.icon} style={{ color: color, paddingLeft: 14 }} />
    </Tooltip>
  )
}

function MFASetUpIcon (props) {
  const classes = useStyles()
  const Icon = props.mfa ? CheckCircleIcon : ErrorIcon
  const text = props.mfa ? 'User has set up MFA' : 'User has not yet set up MFA'
  const color = props.mfa ? 'rgba(0, 135, 7, 0.7)' : 'rgba(255, 188, 3)'
  return (
    <Tooltip title={text} placement='right'>
      <Icon className={classes.icon} style={{ color: color, paddingLeft: 0 }} />
    </Tooltip>
  )
}

export const OutOfOfficeIcon = (props) => {
  const classes = useStyles()
  const color = props.color || '#8e5ae2'
  return (
    <Tooltip title={props.text} placement='left'>
      <ScheduleIcon className={classes.icon} style={{ cursor: 'pointer', color, paddingLeft: 17 }} onClick={props.action} />
    </Tooltip>
  )
}

export { InactiveUserMessage, CalendarSetUpIcon, MFASetUpIcon }
