import React, { useState, useRef, useReducer, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { NimblePicker } from 'emoji-mart'
import emoji_data from 'emoji-mart/data/emojione.json'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import MoodIcon from '@material-ui/icons/Mood'
import SendIcon from '@material-ui/icons/Send'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import DateRangeIcon from '@material-ui/icons/DateRange'
import { sendTypingEvent, sendEvent, sendCalendarDrop, sendMeetingButton } from 'api/conversation_events'
import ParticipantButton from './ParticipantButton'
import CalendarDropModal from '../calendar-drop/CalendarDropModal'
import SaveReplyButton from './SaveReplyButton'
import Tooltip from '@material-ui/core/Tooltip'
import 'emoji-mart/css/emoji-mart.css'
import AccessControl from 'components/AccessControl'
import SimpleTextEditor from 'library/richText/SimpleTextEditor'
import ConferencingButton from '../conferencing-link/ConferencingButton'

const useStyles = makeStyles(theme => ({
  messageEntry: {
    height: 'calc(100% - 30px)',
    display: 'grid',
    gridTemplateRows: '3fr 1fr',
    padding: '10px 15px 20px 15px'
  },
  chatBox: {
    borderRadius: 10,
    height: 88,
    border: '1px solid #888',
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'auto 40px'
  },
  messageInput: {
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  chatButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: 20,
    alignItems: 'center',
    position: 'relative'
  },
  sendButton: {
    paddding: 0,
    border: 0,
    width: '1.4em',
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center'
  },
  iconDiv: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 20
  },
  controlButton: {
    color: '#999999',
    cursor: 'pointer',
    height: '1em',
    '&:hover': {
      fill: theme.palette.primary.main
    }
  }
}))

const sendTypingMessage = ({ state, conversation, value, avatarURL }) => {
  const chatServiceUrl = window.chatServiceUrl
  const conversationID = conversation.id
  const oldText = state.message.replace('<br>', '')
  const newText = (value).replace('<br>', '').replace('<p></p>', '')
  if ((oldText === '' || oldText === '<p></p>') && newText !== '') {
    sendTypingEvent({ chatServiceUrl, conversationID, kind: 'type', avatarURL })
  } else if ((newText === '' || newText === '<p></p>') && oldText !== '' && !oldText.includes('<p></p>')) {
    sendTypingEvent({ chatServiceUrl, conversationID, kind: 'halt' })
  }
}

const formatMessage = (msg) => {
  let text = msg
  if (msg.replace('<p>', '').replace('</p>', '').replace('<br>', '').length < 1) {
    return ''
  }
  if (msg.endsWith('<p><br></p>')) {
    text = msg.replace('<p><br></p>', '')
  }
  return text
}

function EmojiPicker (props) {
  const classes = useStyles()
  if (props.open) {
    return (
      <div style={{ position: 'relative' }}>
        <ClickAwayListener onClickAway={props.closePicker}>
          <NimblePicker
            set='emojione'
            data={emoji_data}
            style={{ position: 'absolute', bottom: '30px', left: '-10px', zIndex: 3 }}
            title='Pick an emoji'
            onSelect={props.onEmojiSelect}
          />
        </ClickAwayListener>
        <div onMouseDown={(e) => { e.preventDefault(); props.closePicker() }} className={classes.iconDiv}>
          <HighlightOffIcon className={classes.controlButton} />
        </div>
      </div>
    )
  }
  return (
    <div
      onMouseDown={e => {
        e.preventDefault()
        props.openPicker()
      }}
      className={classes.iconDiv}
    >
      <Tooltip title='Insert emoji'>
        <MoodIcon className={classes.controlButton} />
      </Tooltip>
    </div>
  )
}

function getButtonFill (message) {
  let sendButtonFill = null
  if (message.replace('<br>', '').replace('<p>', '').replace('</p>', '').length) {
    sendButtonFill = '#8E5AE2'
  }
  return sendButtonFill
}

const initialState = {
  message: '',
  draftMessages: {}
}

function MessageEntry (props) {
  const classes = useStyles()
  const editorRef = useRef(null)
  const conversation = props.conversation
  const conversationID = conversation?.id
  const chatServiceUrl = window.chatServiceUrl
  const conferenceOpen = props.conferenceOpen
  const setConferenceOpen = props.setConferenceOpen
  const [openEmojiPicker, setOpenEmojiPicker] = useState(false)
  const [state, dispatch] = useReducer(reducer, initialState)
  const calendarModalOpen = props.calendarOpen
  const setCalendarModalOpen = props.setCalendarOpen

  props.messageRef.current = (content) => dispatch({ type: 'setMessage', value: content })

  const dropCalendar = (userID, meetingTypeID, textMessageReminders) => {
    sendCalendarDrop({
      conversationID: conversationID,
      chatServiceUrl: chatServiceUrl,
      meetingTypeID,
      userID,
      textMessageReminders: textMessageReminders
    })
  }

  const dropComplete = (meetingUrl) => {
    // Send short message
    const attributes = { kind: 'chat', body: 'You have been invited to join a video call! Click the button to join', data: {} }
    sendEvent({ chatServiceUrl, conversationID, attributes })
    sendMeetingButton({ conversationID, chatServiceUrl, meetingUrl })
  }

  function reducer (state, action) {
    switch (action.type) {
      case 'setMessage':
        return {
          ...state,
          message: action.value,
          draftMessages: { ...state.draftMessages, [conversationID]: action.value }
        }
      default:
        break
    }
  }

  function contentHTMLChanged (value) {
    const avatarURL = props.user.attributes.profile_picture_url
    sendTypingMessage({ state, conversation, value, avatarURL })
    dispatch({ type: 'setMessage', value: value })
  }

  const sendInputChat = () => {
    const body = formatMessage(state.message)
    if (!body) { return }
    const attributes = { kind: 'chat', body, data: {} }
    sendEvent({ chatServiceUrl, conversationID, attributes })
    dispatch({ type: 'setMessage', value: '' })
  }

  const onEnter = () => {
    sendInputChat()
    dispatch({ type: 'setMessage', value: '' })
  }

  const onEmojiSelect = (stuff) => {
    setOpenEmojiPicker(false)
    const editor = editorRef.current.getEditor()
    const range = editor.getSelection()
    const position = range ? range.index : 0
    editor.insertText(position, stuff.native)
  }

  const buttonFill = getButtonFill(state.message)

  useEffect(() => {
    const content = state.draftMessages[conversationID] || ''
    dispatch({ type: 'setMessage', value: content })
    // eslint-disable-next-line
  }, [conversationID])

  if (conversation === undefined || conversation === null) {
    return <></>
  }

  return (
    <AccessControl requiredPermission='send_chats'>
      <div id='internal_notes' className={classes.messageEntry}>
        <div id='saved_replies_3' className={classes.chatBox}>
          <div className={classes.messageInput}>
            <SimpleTextEditor
              message={state.message}
              onChange={contentHTMLChanged}
              onEnter={onEnter}
              placeholder='Type your message...'
              editorRef={editorRef}
              noToolbar
            />
          </div>
          <div className={classes.chatButtons}>
            <div className={classes.iconDiv}>
              <ConferencingButton
                conferenceOpen={conferenceOpen}
                setConferenceOpen={setConferenceOpen}
                dropComplete={dropComplete}
              />
            </div>
            <div className={classes.iconDiv}>
              <ParticipantButton conversation={conversation} />
            </div>
            <div>
              <EmojiPicker
                open={openEmojiPicker}
                closePicker={() => setOpenEmojiPicker(false)}
                openPicker={() => setOpenEmojiPicker(true)}
                onEmojiSelect={onEmojiSelect}
              />
            </div>
            <Tooltip title='Share calendar'>
              <div id='calendar_drop' className={classes.iconDiv}>
                <DateRangeIcon
                  className={classes.controlButton}
                  onClick={() => setCalendarModalOpen(true)}
                />
              </div>
            </Tooltip>
            <SaveReplyButton
              message={state.message}
              buttonFill={buttonFill}
            />
            <Tooltip title='Send message'>
              <div className={classes.sendButton} onClick={sendInputChat}>
                <SendIcon
                  className={classes.controlButton}
                  style={{ fill: buttonFill }}
                />
              </div>
            </Tooltip>
          </div>
        </div>
        <CalendarDropModal
          open={calendarModalOpen}
          onHide={() => setCalendarModalOpen(false)}
          dropCalendar={dropCalendar}
          validEmail={Boolean(props.conversation.attributes.email)}
        />
      </div>
    </AccessControl>
  )
}

export default MessageEntry
