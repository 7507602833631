import { makeStyles } from '@material-ui/core/styles'
import { SearchParams, QueryFilter } from 'classes/queryHelpers'
import { createContext, useState } from 'react'
import { CardList } from './CardList'
import { ObjectPane } from './objectPane/ObjectPane'

const useStyles = makeStyles(theme => ({
  objectListPage: {
    display: 'grid',
    gridTemplateColumns: '400px auto',
    height: '100%'
  },
  listSection: {
    borderRight: '1px solid #0000001A',
    height: '100%'
    // overflowY: 'auto'
  }
}))

interface IListPageContext {
  object: any
  objectClass: any
  pageType: string
}

export const ListPageContext = createContext<IListPageContext>({ object: null, objectClass: null, pageType: '' })

export function ListPage (props: {
  objectClass: any
  pageType: string
  filters?: QueryFilter[][]
  title?: string
  warning?: string
  history?: any
  filterOptions?: Object
}) {
  const classes = useStyles()
  const ObjectClass = props.objectClass
  const [selectedID, setSelectedID] = useState<number | null>(null)

  const select = (ID: number) => {
    if (selectedID === ID) {
      window.open('#' + object.link, '_self')
    } else {
      setSelectedID(ID)
    }
  }

  const searchParams: SearchParams = {
    sortColumn: 'name',
    pageSize: 100,
    pageNumber: 1,
    filters: props.filters
  }

  const { data, isLoading } = ObjectClass.loadAll({ searchParams })

  const object = selectedID ? data.dict[selectedID] : null
  const pageType = props.pageType

  return (
    <ListPageContext.Provider value={{ object, objectClass: ObjectClass, pageType }}>
      <div className={classes.objectListPage}>
        <div className={classes.listSection}>
          <CardList
            select={select}
            selectedID={selectedID}
            list={data?.list || []}
            title={props.title}
            history={props.history}
            isLoading={isLoading}
            filterOptions={props.filterOptions}
          />
        </div>
        <ObjectPane />
      </div>
    </ListPageContext.Provider>
  )
}
