import { doGet, doPost } from './api'

export const jobTitleSearch = (title: string): Promise<{
  data: {
    id: string
    type: 'title'
    attributes: {
      name: string
      number_of_people_matches: number
    }
  }[]
}> => doGet({
  path: `/integrations/enrich/titles?search=${title}`
})

export const getDepartments = (): Promise<any> => doGet({ path: '/integrations/enrich/departments' })
export const getSeniorities = (): Promise<any> => doGet({ path: '/integrations/enrich/seniorities' })
export const runPersonaPreview = (data: Record<string, any>): Promise<any> => doPost({ path: '/personas/preview', useChatURL: true, data })
export const getPersonaCounts = (personaID: string | number): Promise<Record<string, any>> => doGet({ path: `/v2/contacts/persona_count/${personaID}`, useChatURL: true })

export const generatePersonas = (data: Record<string, any>): Promise<any> => doPost({ path: '/api/core/ai/persona', useChatURL: false, skipDataConvert: true, data })
