import Loader from 'library/loading/Loader';
import { useEffect } from 'react'
import { useRouteMatch } from 'react-router-dom';

interface matchParams {
  params: {
    id: string
  }
}

const mywindow = window as any

export default function TermsAndConditionsTable () {
  const match: matchParams | null = useRouteMatch('/admin_portal/terms_and_conditions/:id')
  let id: number | null = null
  if (match) {
    id = parseInt(match.params.id)
  }
  useEffect(() => {
    try {
      /*
      mywindow.htmx.logger = function (elt: any, event: any, data: any) {
        if (console) {
          console.log('INFO:', event, elt, data);
        }
      }
      */
      const ids = ['terms-and-conditions-page']
      for (const id of ids) {
        const d = document.getElementById(id)
        mywindow.htmx.process(d)
        mywindow._hyperscript.processNode(d)
      }
    } catch (e) {
      console.log(e)
    }
    // eslint-disable-next-line
  }, [])
  return (
    <div
      id='terms-and-conditions-page'
      hx-get={`/api/core/web/admin_portal/terms_and_conditions/${id}`}
      hx-trigger='load'
      hx-indicator='#terms-and-conditions-loader'
    >
      <Loader />
    </div>
  )
}
