import { Fragment, useContext, useState } from 'react'
import { Tooltip } from 'library/materialUI'
import { Icon } from 'library/materialUI/Icon'
import { AddFilterButton } from './AddFilterButton'
import { CriteriaContext, CriteriaContextProvider, BranchType } from './CriteriaContext'
import { PopoverDiv } from '../nodes/PopoverDiv'
import { ValueSelector } from './selectors/ValueSelector'
import { compDict, FilterText } from './FilterText'

export type Filter = {
  prop: string
  cmp: keyof typeof compDict
  value: any
}

interface FilterProps {
  filter: Filter
  editFilter: (cmp: string, value: any, label: string) => void
  deleteFilter: () => void
  setCreateModalOpen?: (value: boolean) => void
}

function FilterDisplay (props: FilterProps) {
  const filter = props.filter
  const [open, setOpen] = useState(!filter.cmp)
  const { classes, filterOptions } = useContext(CriteriaContext)
  const option = filterOptions[filter.prop]

  const handleClose = () => {
    const emptyArray = Array.isArray(filter.value) && !filter.value.length
    if (filter.value !== '' && !emptyArray) {
      setOpen(false)
    }
  }

  const handleClickAway = (_event: any, reason: string) => {
    if (reason === 'backdropClick' && window.autocompleteOpen) {
      return
    }
    setOpen(false)
  }

  const handleDelete = (event: any) => {
    event.preventDefault()
    props.deleteFilter()
  }

  return (
    <div>
      <PopoverDiv
        popoverContents={
          <ValueSelector
            option={option}
            filter={filter}
            editFilter={props.editFilter}
            handleClose={handleClose}
            setCreateModalOpen={props.setCreateModalOpen}
          />
        }
        divContents={
          <>
            <Icon icon={option.icon} />
            <div style={{ fontWeight: 600, marginLeft: 5, marginRight: 5 }}>
              {option.label}
            </div>
            <FilterText filter={filter} option={option} />
            <Tooltip title='Delete Filter'>
              <div
                onClick={handleDelete}
                style={{ marginLeft: 10, display: 'flex' }}
              >
                <Icon icon='close' color={filter.value || filter.cmp || open ? '#975ce6' : '#EB4932'} />
              </div>
            </Tooltip>
          </>
        }
        divClass={filter.value || filter.cmp || open ? classes.filterDiv : classes.filterDivError}
        open={open}
        handleClose={handleClickAway}
        handleOpen={() => setOpen(true)}
      />
    </div>
  )
}

interface FilterListProps {
  filters: Filter[]
  editFilter: (index: number, cmp: string, value: any, label: string) => void
  deleteFilter: (index: number, filterOptions: any[]) => void
  removeOrGroup: () => void
  groupIndex: number
  setCreateModalOpen?: (value: boolean) => void
  oneFilter: boolean
}

function FilterList (props: FilterListProps) {
  const filters = props.filters
  const filterCount = filters.length
  const { classes, selectOptions } = useContext(CriteriaContext)
  if (!filterCount) {
    return (
      <div style={{ display: 'flex' }}>
        <AddFilterButton
          type='new'
        />
        {!props.oneFilter && (
          <div className={classes.filterDiv} onClick={props.removeOrGroup}>
            <Icon icon='close' color='primary' />
          </div>
        )}
      </div>
    )
  }

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      {filters.map((filter: any, index: number) => {
        const lastOne = index + 1 === filterCount
        const editFilter = (cmp: string, value: any, label: string) => {
          props.editFilter(index, cmp, value, label)
        }
        const deleteFilter = () => {
          props.deleteFilter(index, selectOptions)
        }
        return (
          <Fragment key={index}>
            <FilterDisplay
              filter={filter}
              editFilter={editFilter}
              deleteFilter={deleteFilter}
              setCreateModalOpen={props.setCreateModalOpen}
            />
            <AddFilterButton
              type={lastOne ? undefined : 'divider'}
            />
          </Fragment>
        )
      })}
    </div>
  )
}

interface FilterSelectorProps extends FilterListProps {
  addNewFilter: (option: string) => void
  branchType: BranchType
  setCreateModalOpen: (value: boolean) => void
}

export function FilterSelector (props: FilterSelectorProps) {
  const { filters, addNewFilter, editFilter, deleteFilter, removeOrGroup, groupIndex, branchType, setCreateModalOpen, oneFilter } = props

  return (
    <CriteriaContextProvider addNewFilter={addNewFilter} branchType={branchType}>
      <FilterList
        filters={filters}
        editFilter={editFilter}
        deleteFilter={deleteFilter}
        removeOrGroup={removeOrGroup}
        groupIndex={groupIndex}
        setCreateModalOpen={branchType === 'segment' ? setCreateModalOpen : undefined}
        oneFilter={oneFilter}
      />
    </CriteriaContextProvider>
  )
}
