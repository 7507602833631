import { useDoQuery } from './useDoQuery'
import { GenericObject } from './genericObject'

interface CRMAccountList {
  list: CRMAccount[]
  dict: { [id: CRMAccount['id']]: CRMAccount }
}

const initialState = {
  name: ''
}

export class CRMAccount extends GenericObject {
  name: string
  link: string

  static loadOne (id: string): { data: CRMAccountList, isLoading: boolean, isError: boolean, error: any } {
    return useDoQuery({ path: `/api/integrations/crm/accounts?filter[id]=${id}`, objectClass: CRMAccount })
  }

  constructor ({ row }: { row?: Record<string, any> }) {
    super({ row })
    const crmAccount = row?.attributes || initialState
    this.name = crmAccount.name
    this.link = row?.links?.self || ''
  }
}
