import { doGet, doPost } from './api'

export const processScores = ({ tenantID }: { tenantID: number }): void => {
    const data = {}
    const path = `/scores/process/${tenantID}`
    doPost({ path, data, useChatURL: true })
}

export const getScoreHistory = (domain: string, period: number) => {
    return doGet({
        path: `/scores/history/${domain}/${period}`,
        useChatURL: true
      })
}
