import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import ContactActivity from './ContactActivity'
import ContactSummary from './ContactSummary'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    height: '100%',
    width: '100%'
  },
  mainCard: {
    height: '100%',
    width: '100%',
    border: '1px solid rgba(0,0,0,0.1)',
    borderBottom: '0px',
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5
  },
  appBar: {
    transform: 'none'
  },
  paper: {
    boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2)',
    backgroundColor: 'rgba(0,0,0,0.05)'
  }
}))

function TabPanel (props) {
  const { children, value, index } = props

  return (
    <div
      hidden={value !== index}
      style={{ height: 'calc(100% - 48px)', width: '100%' }}
    >
      {children}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

function a11yProps (index) {
  return {
    id: `main-card-${index}`,
    'aria-controls': `main-cardpanel-${index}`
  }
}

function SideCard (props) {
  const classes = useStyles()
  const [value, setValue] = React.useState(1)
  const [filter, setFilter] = React.useState('all')

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const person = props?.person?.attributes || {}

  const summaryDetails = {
    leadStage: props.leadStage,
    conversations: 0,
    pageViews: 0,
    firstSeen: null,
    lastSeen: null,
    firstSource: null,
    lastSource: null
  }

  if (props.trackingEvents) {
    if (props.trackingEvents.length > 0) {
      summaryDetails.firstSeen = props.trackingEvents[props.trackingEvents.length - 1].attributes.created_timestamp
      summaryDetails.lastSeen = props.trackingEvents[0].attributes.created_timestamp
      summaryDetails.pageViews = props.pageViews
      summaryDetails.conversations = props.numConversations
      summaryDetails.firstSource = props.firstSource
      summaryDetails.lastSource = props.lastSource
    }
  }

  function showSelection (value) {
    setFilter(value)
    setValue(1)
  }

  const meetings = person.meetings?.length || 0

  return (
    <Card className={classes.mainCard}>
      <div className={classes.root}>
        <Paper position='static' className={classes.paper}>
          <Tabs value={value} onChange={handleChange} variant='fullWidth' indicatorColor='primary' textColor='primary'>
            <Tab label='Summary' {...a11yProps(1)} />
            <Tab label='Activity' {...a11yProps(1)} />
          </Tabs>
        </Paper>
        <TabPanel value={value} index={0}>
          <ContactSummary summaryDetails={summaryDetails} showSelection={showSelection} meetings={meetings} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ContactActivity
            person={person}
            trackingEvents={props.trackingEvents}
            openConvoTab={props.openConvoTab}
            openDetailsTab={props.openDetailsTab}
            addNote={props.addNote}
            deleteNote={props.deleteNote}
            editNote={props.editNote}
            notes={props.notes}
            openEditNoteModal={props.openEditNoteModal}
            filter={filter}
            setFilter={setFilter}
            convoTabOpen={props.convoTabOpen}
            userDict={props.userDict}
            openNoteModal={props.openNoteModal}
            setSubmissionModalOpen={props.setSubmissionModalOpen}
            setSubmissionID={props.setSubmissionID}
          />
        </TabPanel>
      </div>
    </Card>
  )
}

export default SideCard
