import IntegrationPage from './IntegrationPage/IntegrationPage'
import { useState } from 'react'
import PeopleMappingCard from './Salesloft/PeopleMappingCard'
import TabProvider from './IntegrationPage/TabProvider'
import UserMappingCard from './Salesloft/UserMappingCard'
import ContactOwnershipCard from './Salesloft/ContactOwnershipCard'
import IntegrationInfoCard from './Salesloft/IntegrationInfoCard'
import SetupCard from './Salesloft/SetupCard'
import UsageCard from './Salesloft/UsageCard'

export default function SalesloftIntegrationPages () {
  const [saveEnabled, setSaveEnabled] = useState(false)
  const [initSave, setInitSave] = useState(false)
  const ownershipDescription = 'Site visitors who are synced to Salesloft will be assigned to a Salesloft Owner based on the following settings, in order, then using any ownership rules set up in Salesloft skills in PlayMaker.'
  const ownershipWarning = 'Contacts gathered using a Prospect skill in PlayMaker will only be assigned ownership based on the rules set up in the Salesloft skills in PlayMaker, as these people haven’t had conversations or booked meetings'
  const tabs = [
    {
      name: 'Get Started',
      url: '/settings/salesloft/get-started',
      components: [<TabProvider key='firsttab' setCanSave={setSaveEnabled} shouldSave={initSave}><IntegrationInfoCard key='salesloft-get-started' /></TabProvider>, <TabProvider key='firsttab' setCanSave={setSaveEnabled} shouldSave={initSave}><SetupCard key='salesloft-setup' /></TabProvider>, <TabProvider key='firsttab' setCanSave={setSaveEnabled} shouldSave={initSave}><UsageCard key='salesloft-usage' /></TabProvider>]
    },
    {
      name: 'People',
      url: '/settings/salesloft/contacts',
      components: [<TabProvider key='secondtab' setCanSave={setSaveEnabled} shouldSave={initSave}><ContactOwnershipCard key='salesloft-ownership' description={ownershipDescription} warning={ownershipWarning} /> </TabProvider>, <TabProvider key='secondtab' setCanSave={setSaveEnabled} shouldSave={initSave}><PeopleMappingCard key='salesloft-peopleMapping' /> </TabProvider>]
    },
    {
      name: 'Users',
      url: '/settings/salesloft/users',
      components: [<TabProvider key='thirdtab' setCanSave={setSaveEnabled} shouldSave={initSave}><UserMappingCard key='salesloft-userMapping' /> </TabProvider>]
    }
  ]

  return (
    <IntegrationPage
      integration='Salesloft'
      tabs={tabs}
      saveDisabled={!saveEnabled}
      setShouldSave={setInitSave}
      oauth
    />
  )
}
