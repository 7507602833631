import { makeStyles } from '@material-ui/core/styles';
import SkeletonNumber from 'library/loading/skeleton/SkeletonNumber';
import { Modal } from 'library/Modal';

const useStyles = makeStyles((theme) => ({
  footerInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  info: {
    display: 'flex',
    flexDirection: 'column'
  },
  infoTitle: {
    color: '#7f7f7f',
    fontSize: '14px'
  }
}))

type UpdatePlanModalProps = {
  open: boolean
  onClose: () => void
  onSave: () => void
  planPrice: number
  duePrice?: number
  children?: React.ReactNode
  loading?: boolean
  saveDisabled: boolean
}

export default function UpdatePlanModal ({ open, onClose, onSave, planPrice, duePrice, loading, children, saveDisabled }: UpdatePlanModalProps): JSX.Element {
  const classes = useStyles()
  return (
    <Modal
      title='Update plan'
      size='md'
      saveBtnText='Update plan'
      open={open}
      onHide={onClose}
      handleSave={onSave}
      saveDisabled={saveDisabled}
      footerContents={(
        <div className={classes.footerInfo}>
          <div className={classes.info}>
            <div className={classes.infoTitle}>
              Plan cost
            </div>
            {loading ? <SkeletonNumber size='md' /> : `$${planPrice}/mo`}
          </div>
          <div className={classes.info}>
            <div className={classes.infoTitle}>
              Due today
            </div>
            {loading ? <SkeletonNumber size='md' /> : duePrice ? `$${duePrice}` : '$0'}
          </div>
          <div className={classes.info}>
            <div className={classes.infoTitle}>
              Cost Difference
            </div>
            {loading ? <SkeletonNumber size='md' /> : duePrice ? `+$${duePrice}` : '+$0'}
          </div>
        </div>
      )}
    >
      {children}
    </Modal>
  )
}
