import { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Avatar } from 'cf-components/Avatars'
import { getContactIntegrationMappings } from './integrations/integration-helpers'
import ContactSyncButtons from './integrations/ContactSyncButtons'
import SocialMediaWidgets from './SocialMediaWidgets'
import { Icon } from 'library/materialUI'
import { sourceIconMapping } from 'cf-components/table/TableAvatars'

const useStyles = makeStyles(theme => ({
  card: {
    height: '100%'
  },
  outerDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%'
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    padding: 15
  },
  actions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%'
  },
  buttonGroup: {
    paddingRight: 30
  },
  info: {
    paddingLeft: 15,
    fontFamily: 'Poppins, sans serif',
    fontSize: '14px',
    color: '#5A5A5A'
  },
  contactName: {
    fontWeight: 600,
    fontSize: '20px',
    maxWidth: 250,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  title: {
    paddingBottom: 3
  },
  divider: {
    height: 85,
    width: 1
  },
  followButton: {
    paddingLeft: 10
  },
  icon: {
    color: '#7A7A7A',
    height: 18,
    width: 18,
    paddingRight: 10
  },
  contactTopLine: {
    display: 'flex'
  },
  accountName: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 5
  },
  socialIcon: {
    color: '#CBCBCB',
    marginRight: 10
  },
  purpleSocialIcon: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    marginRight: 10
  },
  integrationCtn: {
    display: 'flex',
    gap: '4px',
    alignItems: 'center',
    marginLeft: 6
  }
}))

function ContactCard (props) {
  const classes = useStyles()
  const [integrationObjectMappings, setIntegrationObjectMappings] = useState({})
  const personID = props.person.id
  const person = props.person.attributes

  const refreshContactIntegrationMappings = contactID => {
    getContactIntegrationMappings(contactID).then(response => setIntegrationObjectMappings(response))
  }

  useEffect(() => {
    refreshContactIntegrationMappings(personID)
  }, [personID])

  let displayName = person.first_name + ' ' + person.last_name
  if (displayName === ' ') {
    displayName = person.email
  }

  const subtitle = person.title
  const companyName = person.company_name || ''
  const followed = props.followDict[personID]
  const avatarUrl = `/api/logo-service/logo/${person.domain}.png`
  const prospectIcon = sourceIconMapping[person.source]

  function imageExists (image_url) {
    const image = new Image()
    image.src = image_url
    if (!image.complete) {
      return false
    }
    return true
  }

  return (
    <div className={classes.card}>
      <div className={classes.outerDiv}>
        <div className={classes.container}>
          <Avatar
            contact={props.person}
            size='lg'
          />
          <div className={classes.info}>
            <div className={classes.contactTopLine}>
              <div className={classes.contactName}>
                {displayName}
              </div>
              <div style={{ paddingLeft: '10px', paddingRight: '10px' }}>{prospectIcon}</div>
              <div className={classes.integrationCtn}>
                <ContactSyncButtons
                  contact={props.person}
                  integrationObjectMappings={integrationObjectMappings}
                  refreshContactIntegrationMappings={refreshContactIntegrationMappings}
                  side='left'
                />
              </div>
            </div>
            {(companyName || subtitle) &&
              <div className={classes.accountName}>
                {imageExists(avatarUrl) ? (
                  <img src={avatarUrl} alt='company logo' style={{ width: 20, height: 20, borderRadius: 5 }} />
                ) : (
                  <Icon icon='accounts' color='#808080' size='md' />
                )}
                {companyName && (
                  <a href={`/#/accounts/${person.domain}`} style={{ marginLeft: 5 }} target='_blank' rel='noreferrer'>
                    {companyName}
                  </a>
                )}
                {companyName && subtitle && ' - '}
                {subtitle}
              </div>}
            <SocialMediaWidgets
              socialMediaHandles={person}
              onSave={(handle, value) => props.savePerson({ [handle]: value })}
            />
          </div>
        </div>
        <div className={classes.actions}>
          <div onClick={() => props.delete()} style={{ minWidth: '90px', marginBottom: '10px', border: '1px solid #D3D3D3', borderRadius: '5px', padding: '8px 15px 8px 15px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}>
            <div>
              <Icon icon='delete' color='#808080' size='sm' />
            </div>
            <div style={{ marginLeft: '10px', fontSize: '14px' }}>
              Delete
            </div>
          </div>
          <div onClick={() => props.setFollow(!followed)} style={{ minWidth: '90px', border: '1px solid #D3D3D3', borderRadius: '5px', padding: '8px 15px 8px 15px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}>
            <div>
              <Icon icon={followed ? 'check' : 'addContact'} color='#808080' size='sm' />
            </div>
            <div style={{ marginLeft: '10px', fontSize: '14px' }}>
              {followed ? 'Following' : 'Follow'}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactCard
