/* eslint-disable @typescript-eslint/naming-convention */
import { Modal } from 'library/Modal'
import { useState, useEffect, useReducer } from 'react'
import SignalsScoringStep1 from './SignalsScoringStep1'
import SignalsScoringStep2 from './SignalsScoringStep2'
import SignalsScoringStep3 from './SignalsScoringStep3'
import SignalsScoringStep4 from './SignalsScoringStep4'
import SignalsScoringStep5 from './SignalsScoringStep5'
import { SignalsScoringStepDisplay } from './SignalsScoringStepDisplay'
import ScoreSettings, { FactorGroup, DealCycleInterval } from 'classes/scoreSettings'
import { processScores } from 'api/scores'
import SignalsScoringStep6 from './SignalsScoringStep6'
import { EmailSubscription } from 'classes/emailSubscriptions'

interface SignalsScoringModalProps {
  modalOpen: boolean
  setModalOpen: (value: boolean) => void
  finishStep: () => void
}

const initialEmailSubscription: EmailSubscription = {
  name: 'Trending Companies',
  type: 'score_summary',
  cfRecipients: [],
  nonCfRecipients: [],
  frequency: { period: 'daily', option: 'weekends' },
  frequencyPeriod: 'daily',
  removedUsers: [],
  meta: {
    plays: [],
    dashboardFilters: 0,
    report: [],
    segment_summary: [],
    segment_id: 0,
    score_shift: 10
  },
  typeText: 'Scores Shift',
  delete: () => Promise.resolve(),
  id: 0,
  createdTimestamp: new Date(),
  createdDate: new Date().toDateString(),
  createdByUserID: 0,
  objectType: 'email_subscription',
  subtitle: '',
  searchField: ''
}

const reducer = (state: EmailSubscription, changes: any): EmailSubscription => {
  return { ...state, ...changes }
}

export function SignalsScoringModal ({ modalOpen, setModalOpen, finishStep }: SignalsScoringModalProps): JSX.Element {
  const [oppTypes, setOppTypes] = useState<string[]>([])
  const { data: settings, isError, isLoading, error } = ScoreSettings.loadOne()
  const [dealCycleGrain, setDealCycleGrain] = useState<DealCycleInterval>(settings?.dealCycleInterval)
  const [dealCycleValue, setDealCycleValue] = useState<number>(settings?.dealCycleValue)
  const [buyingCommitteePriorities, setBuyingCommitteePriorities] = useState<number[]>([])
  const [factors, setFactors] = useState<FactorGroup[]>(settings?.factorGroups)
  const [emailSubscription, setEmailSubscription] = useReducer(reducer, initialEmailSubscription)
  const [createSubscription, setCreateSubscription] = useState(false)

  const [step, setStep] = useState(1)
  useEffect(() => {
    if (!isLoading && !isError) {
      setDealCycleValue(settings.dealCycleValue)
      setDealCycleGrain(settings.dealCycleInterval)
      setBuyingCommitteePriorities(settings.buyingCommitteePriorities)
      setFactors(settings.factorGroups)
      setOppTypes(settings.opportunityTypes)
    }
  }, [isLoading, isError, settings])

  function saveSettings () {
    if (createSubscription) {
      EmailSubscription.save({ emailSubscription })
    }
    ScoreSettings.saveSettings({
      data: {
        dealCycleValue,
        dealCycleInterval: dealCycleGrain,
        factorGroups: factors,
        buyingCommitteePriorities,
        opportunityTypes: oppTypes
      }
    }).then(() => {
      processScores({ tenantID: settings.tenantId })
      setModalOpen(false)
      finishStep()
    })
  }

  let content = <></>
  if (isLoading) {
    content = <div>Loading...</div>
  } else if (isError) {
    content = <div>Error loading settings: {error.message}</div>
  } else if (step === 1) {
    content = <SignalsScoringStep1 oppTypes={oppTypes} setOppTypes={setOppTypes} />
  } else if (step === 2) {
    content = <SignalsScoringStep2 dealCycleGrain={dealCycleGrain} setDealCycleGrain={setDealCycleGrain} dealCycleValue={dealCycleValue} setDealCycleValue={setDealCycleValue} />
  } else if (step === 3) {
    content = <SignalsScoringStep3 buyingCommitteePriorities={buyingCommitteePriorities} setBuyingCommitteePriorities={setBuyingCommitteePriorities} />
  } else if (step === 4) {
    content = <SignalsScoringStep4 factors={factors} setFactors={setFactors} />
  } else if (step === 5) {
    content = (
      <SignalsScoringStep5
        setStep={setStep}
        oppTypes={oppTypes}
        averageCycle={dealCycleValue + ' ' + dealCycleGrain + '(s)'}
        personaPriority={buyingCommitteePriorities}
        factors={factors}
      />
    )
  } else if (step === 6) {
    content = <SignalsScoringStep6 emailSub={emailSubscription} createEmail={createSubscription} setEmailSub={setEmailSubscription} setCreateEmail={setCreateSubscription} />
  }

  return (
    <Modal
      open={modalOpen}
      onHide={() => { setModalOpen(false); setStep(1) }}
      title={<SignalsScoringStepDisplay step={step} setStep={setStep} />}
      handleSave={() => {
        step === 6 ? saveSettings() : setStep(step + 1)
      }}
      closeBtnFunction={() => { step === 1 ? setModalOpen(false) : setStep(step - 1) }}
      size='md'
      centerTitle
      saveIcon={step === 6 ? 'save' : 'arrowRightWithLine'}
      saveIconPosition={step === 6 ? 'start' : 'end'}
      saveBtnText={step === 6 ? 'Save' : 'Next'}
      cancelBtnText={step === 1 ? 'Cancel' : 'Back'}
      cancelIcon={step !== 1 ? 'arrowBack' : undefined}
      noCloseBtn
    >
      {content}
    </Modal>
  )
}
