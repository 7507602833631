const headers = {
  'Content-Type': 'application/vnd.api+json'
}

const getTestStatistics = ({ chatServiceUrl, testID }) => {
  return fetch(`https://${chatServiceUrl}/bot_tests/${testID}/reports/test_statistics`, {
    method: 'GET',
    headers: headers
  }).then((response) => response.json())
}

const getGoalTimeline = ({ chatServiceUrl, testID }) => {
  return fetch(`https://${chatServiceUrl}/bot_tests/${testID}/reports/goal_timeline`, {
    method: 'GET',
    headers: headers
  }).then((response) => response.json())
}

export { getTestStatistics, getGoalTimeline }
