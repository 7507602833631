import { GenericObject } from './genericObject'
import { LoadAllProps } from './queryHelpers'
import { useDoQuery } from './useDoQuery'

export interface AccountDetailsList {
  list: AccountDetails[]
  dict: { [id: AccountDetails['domain']]: AccountDetails }
}

export default class AccountDetails extends GenericObject {
  domain: string
  companyName: string
  field: string
  value: string
  valueInt: number
  valueTextList: string[]
  previousValue: any
  status: string
  createdTimestamp: Date
  createdByUserID: number
  tenantID: number
  linkedin_url: string

  static loadAll (props?: LoadAllProps): { data: AccountDetailsList, isLoading: boolean } {
    const path = '/account_details_moderation'
    return useDoQuery({
      path,
      useChatURL: true,
      objectClass: AccountDetails,
      searchParams: props?.searchParams
    })
  }

  constructor ({ row }: { row: Record<string, any> }) {
    super({ row })
    const accountDetails = row.attributes
    this.domain = accountDetails.domain
    this.companyName = accountDetails.company_name
    this.field = accountDetails.field
    this.value = accountDetails.value
    this.valueInt = accountDetails.value_int
    this.valueTextList = accountDetails.value_text_list
    this.previousValue = accountDetails.previous_value
    this.status = accountDetails.status
    this.createdTimestamp = accountDetails.created_timestamp
    this.createdByUserID = accountDetails.created_by_user_id
    this.tenantID = accountDetails.tenant_id
    this.linkedin_url = accountDetails.linked_in
  }
}
