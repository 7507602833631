import { Typography } from '@material-ui/core'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import AvailabilityCard from 'cf-components/AvailabilityCard'
import '../../bot/flow-builder/quill.css'
import InformationIcon from 'library/InformationIcon'
import SettingsCard from 'cf-components/SettingsCard'
import { Select } from 'cf-components/MuiComponents'
import ChatIconDisplaySettings from './ChatIconDisplaySettings'
import VisitorConversationsSettings from './VisitorConversations'

function ChatAvailability (props) {
  const state = props.state
  const dispatch = props.dispatch

  if (!state) {
    return <></>
  }

  const disableNewConversation = state.attributes.disable_new_conversation
  const promptVisibility = state.attributes.prompt_visibility
  const conversationBalanceThreshold = state.attributes.conversation_balance_threshold
  const teamOfflineToggle = state.attributes.offline_when_team_unavailable
  const autoCloseInterval = state.attributes.auto_close_interval_in_minutes || 10
  const defaultBotProfileId = state.attributes.default_bot_profile_id
  // const autoCloseNoAgentOnly = state.attributes.auto_close_conversations_no_agent_only

  const autoCloseIntervalOptions = [
    { value: -1, label: 'Don\'t Auto-close' },
    { value: 10, label: '10 minutes' },
    { value: 15, label: '15 minutes' },
    { value: 30, label: '30 minutes' },
    { value: 60, label: '1 hour' },
    { value: 240, label: '4 hours' },
    { value: 480, label: '8 hours' },
    { value: 720, label: '12 hours' },
    { value: 1440, label: '24 hours' }
  ]

  const balanceOptions = [
    { value: '0', label: 'Unlimited' },
    { value: '20', label: '20' },
    { value: '15', label: '15' },
    { value: '10', label: '10' },
    { value: '9', label: '9' },
    { value: '8', label: '8' },
    { value: '7', label: '7' },
    { value: '6', label: '6' },
    { value: '5', label: '5' },
    { value: '4', label: '4' },
    { value: '3', label: '3' },
    { value: '2', label: '2' },
    { value: '1', label: '1' }
  ]

  return (
    <div>
      <ChatIconDisplaySettings
        state={state}
        dispatch={dispatch}
        promptVisibility={promptVisibility}
        title='Chat Icon Display Settings'
      />
      <VisitorConversationsSettings
        disableNewConversation={disableNewConversation}
        dispatch={dispatch}
        title='Visitor Conversations'
        defaultBotProfileId={defaultBotProfileId}
      />
      <AvailabilityCard
        state={state.attributes}
        dispatch={dispatch}
        title='Company Chat Availability'
        scheduled={state.attributes.chat_setting === 'scheduled'}
      />
      <SettingsCard
        title='Agent Routing'
        subtitle='Set the maximum number of open conversations an agent can have before round-robin routing finds an agent that is under the threshold.'
      >
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
          <Typography id='balance-threshold-label' style={{ marginRight: 15 }}>
            Conversation Balance Threshold: {conversationBalanceThreshold || 'Unlimited'}
          </Typography>
          <InformationIcon
            message='Note: 1. Does not apply to Quick Draw routing.
              2. If all agents have met the threshold regular round-robin routing will resume.'
          />
        </div>
        <Select
          value={conversationBalanceThreshold || 0}
          onChange={(e) => dispatch({ type: 'updateSetting', name: 'conversation_balance_threshold', value: parseInt(e.target.value || 0) })}
          variant='rounded'
          label='--Select an option below--'
          options={balanceOptions}
          style={{ width: 200 }}
        />
        <div style={{ marginTop: 10 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={teamOfflineToggle}
                onChange={e => dispatch({ type: 'toggleSetting', name: 'offline_when_team_unavailable' })}
                value='teamOfflineToggle'
                color='primary'
              />
            }
            label="Set agent status to unavailable when their team's scheduled hours end."
          />
        </div>
      </SettingsCard>
      <SettingsCard
        title='Auto-close conversations'
        subtitle='After the last message is sent, how long would you like to wait before closing the conversation?'
      >
        <div>
          <Select
            value={autoCloseInterval}
            onChange={e => dispatch({ type: 'updateSetting', name: 'auto_close_interval_in_minutes', value: e.target.value })}
            variant='rounded'
            label='--Select an option below--'
            options={autoCloseIntervalOptions}
          />
        </div>
        {/* <div style={{ marginTop: 15 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={autoCloseNoAgentOnly}
                onChange={e => dispatch({ type: 'toggleSetting', name: 'auto_close_conversations_no_agent_only' })}
                value='teamOfflineToggle'
                color='primary'
              />
            }
            label='Only apply this setting to conversations that were not joined by an agent'
          />
        </div> */}
      </SettingsCard>
    </div>
  )
}

export default ChatAvailability
