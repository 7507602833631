import { doDelete, doGet, doPost } from 'api/api'
import GenericObject from './genericObject'
import { LoadAllProps } from './queryHelpers'
import { useDoQuery } from './useDoQuery'

interface BotSequenceList {
  list: BotSequence[]
  dict: { [id: BotSequence['id']]: BotSequence }
}

export class BotSequence extends GenericObject {
  tenantID: number
  description: string
  sequenceFlowID: number
  ports: any[]
  isActive: boolean
  updatedUserID: number
  objectType = 'sequence'

  get subtitle (): string { return this.description }
  get link (): string { return `/sequences/${this.id}/builder` }

  static loadAll (props?: LoadAllProps): { data: BotSequenceList, isLoading: boolean } {
    return useDoQuery({
        useChatURL: true,
        path: '/sequences',
        objectClass: BotSequence,
        searchParams: props?.searchParams
    })
  }

  static loadOne (ID: BotSequence['id']): { data: BotSequence, isLoading: boolean, isError: boolean, error: any } {
    return useDoQuery({ useChatURL: true, path: `/sequences/${ID}`, objectClass: BotSequence })
  }

  static loadStats (ID: BotSequence['id']): { data: any, isLoading: boolean } {
    return useDoQuery({ useChatURL: true, path: `/sequences/${ID}/stats`, objectClass: BotSequence })
  }

  static getRosterData (ID: BotSequence['id']): Promise<any> {
    return doGet({ path: `/sequences/${ID}/overview`, useChatURL: true })
      .then((response: any) => {
        return response.data.attributes.bot_stats
      })
  }

  delete () {
    const path = `/sequences/${this.id}`
    return doDelete({ path, useChatURL: true })
  }

  async clone (): Promise<string> {
    const path = `/sequences/${this.id}:clone`
    return doPost({ path, useChatURL: true }).then((response: any) => {
      return '/sequences/' + response.data.id + '/builder'
    })
  }

  constructor ({ row }: { row: Record<string, any> }) {
    super({ row })
    const attributes = row.attributes
    this.tenantID = attributes.tenant_id
    this.description = attributes.description
    this.sequenceFlowID = attributes.sequence_flow_id
    this.ports = attributes.ports
    this.isActive = attributes.is_active || false
    this.updatedUserID = attributes.updated_by_user_id
  }
}
