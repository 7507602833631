import React, { useRef, useEffect } from 'react'
import { PropTypes, SvgIconTypeMap } from '@material-ui/core'
import { OverridableComponent } from '@material-ui/core/OverridableComponent'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import Divider from '@material-ui/core/Divider'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import LoadingButton from 'components/LoadingButton'
import Typography from '@material-ui/core/Typography'
import { Icon } from 'library/materialUI/Icon'
import { TypeBackground } from '@material-ui/core/styles/createPalette'

interface WarningTypeBackground extends TypeBackground {
  warning: string
}

const useStyles = makeStyles(theme => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: '#FFF'
  },
  title: {
    backgroundColor: theme.palette.primary.main,
    color: 'white'
  },
  twoButtonFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 14
  },
  container: {
    backgroundColor: 'rgba(193, 166, 237, 0.1)',
    padding: 20,
    borderRadius: 10
  },
  subtitle: {
    marginBottom: 10,
    fontSize: 14
  },
  sectionTitle: {
    fontSize: 15,
    fontWeight: 600,
    marginBottom: 5
  },
  modalSection: {
    marginBottom: 10
  },
  noPadding: {
    padding: 0
  },
  divider: {
    marginBottom: 5
  },
  noTitlePadding: {
    padding: 0,
    overflow: 'hidden'
  },
  errorDiv: {
    marginTop: 20,
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    backgroundColor: (theme.palette.background as WarningTypeBackground).warning
  }
}))

export interface ModalButtonProps {
  text?: string | JSX.Element
  icon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>
  color?: PropTypes.Color
  variant?: 'text' | 'outlined' | 'contained' | 'loading'
  action?: React.MouseEventHandler<HTMLButtonElement>
  disabled?: boolean
  type?: 'button' | 'submit'
  className?: string
  style?: React.CSSProperties
}

export function ModalButton (props: ModalButtonProps): JSX.Element {
  const text = props.text
  const handleClick = props.action
  const disabled = props.disabled || false
  const color = props.color || 'primary'
  const variant = props.variant || 'outlined'
  const Icon = props.icon
  const type = props.type || 'button'

  if (!text) {
    return <></>
  }

  if (variant === 'loading') {
    return (
      <LoadingButton
        buttonText={text}
        onClick={handleClick}
        disabled={disabled}
        icon={Icon}
      />
    )
  }

  return (
    <Button
      startIcon={Icon ? <Icon /> : <></>}
      color={color}
      variant={variant}
      disabled={disabled}
      onClick={handleClick}
      type={type}
      className={props.className || undefined}
      style={props.style || undefined}
    >
      {text}
    </Button>
  )
}

export interface ModalSectionProps {
  title?: string | object
  subtitle?: string | JSX.Element
  children?: React.ReactNode
}

export function ModalSection (props: ModalSectionProps): JSX.Element {
  const classes = useStyles()
  return (
    <div className={classes.modalSection}>
      {Boolean(props.title) && (
        <>
          <Typography variant='subtitle1' className={classes.sectionTitle}>
            {props.title}
          </Typography>
          <Divider className={classes.divider} />
        </>
      )}
      {Boolean(props.subtitle) &&
        <Typography className={classes.subtitle}>
          {props.subtitle}
        </Typography>}
      {props.children}
    </div>
  )
}

export function ModalContents (props: { children: React.ReactNode }): JSX.Element {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      {props.children}
    </div>
  )
}

interface BasicModalProps extends Pick<ModalProps, 'size' | 'onHide' | 'noButton' | 'open' | 'title'> {
  confirmButtonText?: ModalButtonProps['text']
  confirmAction?: ModalButtonProps['action']
  confirmIcon?: ModalButtonProps['icon']
  cancelButtonText?: ModalButtonProps['text']
  cancelAction?: ModalButtonProps['action']
  message: JSX.Element | string
  confirmVariant?: ModalButtonProps['variant']
}

export function BasicModal (props: BasicModalProps): JSX.Element {
  const rightButtonProps: ModalButtonProps | undefined = props.confirmButtonText ? {
    text: props.confirmButtonText,
    action: props.confirmAction,
    icon: props.confirmIcon,
    variant: props.confirmVariant || 'outlined'
  } : undefined
  const leftButtonProps: ModalButtonProps | undefined = props.cancelButtonText ? {
    text: props.cancelButtonText,
    action: props.cancelAction || props.onHide,
    variant: 'text'
  } : undefined

  return (
    <Modal
      size={props.size || 'xs'}
      noButton={props.noButton}
      onHide={props.onHide}
      open={props.open}
      title={props.title}
      leftButtonProps={leftButtonProps}
      rightButtonProps={rightButtonProps}
    >
      {props.message}
    </Modal>
  )
}

export type ModalSizes = 'xs' | 'sm' | 'md' | 'lg'

export interface ModalProps {
  /** The size of the modal */
  size?: ModalSizes
  /** Callback to execute on close */
  onHide: () => void
  /** Whether or not the modal is open */
  open: boolean
  /** Include to prevent the button container from displaying */
  noButton?: boolean
  /** Use mode='plain' to prevent cf common styling */
  mode?: 'plain'
  /** The main title for the dialog */
  title: string | JSX.Element
  /** Modal contents */
  children?: React.ReactNode
  /** Prevent modal content default padding */
  noPadding?: boolean
  /** Left button */
  leftButtonProps?: ModalButtonProps
  /** Right button */
  rightButtonProps?: ModalButtonProps
  /** dividers above and below the message text */
  noDividers?: boolean
  /** remove padding around title */
  removeTitlePadding?: boolean
}

/**
 * Renders a Modal component
 */
export function Modal (props: ModalProps): JSX.Element {
  const classes = useStyles()
  const size = props.size || 'sm'
  const noButton = props.noButton || false
  const saveButtonRef = useRef(true)
  const onBackButtonRef = useRef(true)
  const dividers = !props.noDividers

  function closeModal (): void {
    props.onHide()
  }

  useEffect(() => {
    if (props.open) {
      saveButtonRef.current = true
      onBackButtonRef.current = true
    }
  }, [props.open])

  return (
    <Dialog
      onClose={closeModal}
      open={props.open}
      fullWidth
      maxWidth={size}
    >
      <MuiDialogTitle className={`${props.mode === 'plain' ? undefined : classes.title} ${props.removeTitlePadding ? classes.noTitlePadding : undefined}`}>
        {props.title}
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          style={{ color: props.mode === 'plain' ? 'rgba(0, 0, 0, 0.87)' : '#FFF' }}
          onClick={closeModal}
        >
          <CloseIcon />
        </IconButton>
      </MuiDialogTitle>
      <MuiDialogContent dividers={dividers} classes={{ dividers: props.noPadding ? classes.noPadding : undefined }}>
        {props.mode === 'plain'
          ? props.children
          : (
            <ModalContents>
              {props.children}
            </ModalContents>)}
      </MuiDialogContent>
      {!noButton &&
        <div className={classes.twoButtonFooter}>
          <div>
            <ModalButton {...props.leftButtonProps} />
          </div>
          <div>
            <ModalButton {...props.rightButtonProps} />
          </div>
        </div>}
    </Dialog>
  )
}
interface ModalErrorDivProps {
  errors: string
}

export function ModalErrorDiv ({ errors } : ModalErrorDivProps) {
  const classes = useStyles()
  return (
    <div className={classes.errorDiv}>
      <div style={{ margin: 10 }}>
        <Icon icon='warning' color='#F3D450' />
      </div>
      <Typography>{errors}</Typography>
    </div>
  )
}
