import { Integration } from 'classes/integrations'
import { GetIntegrationUsers, IntegrationUsers } from 'classes/integrationUsers'
import { UserMapping } from 'classes/intergrationUserMapping'
import Loader from 'library/loading/Loader'
import Card from 'library/materialUI/Card'
import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react'
import { IntegrationPageContext } from '../IntegrationPage/IntegrationPageProvider'
import { TabContext } from '../IntegrationPage/TabProvider'
import UserIdMapping from '../UserIdMapping'
import { HandleSave, useMappingState } from './mappingHook'

const SL = 'salesloft'

const handleUserCardSave = (um: UserMapping[], int: Integration) => {
  const newInt = int
  newInt.mappings.user_mapping = um
  Integration.update(newInt)
}

const initalUserMapping: UserMapping[] = []

export default function UserMappingCard () {
  const { data, isLoading } = Integration.loadOne(SL)
  const { data: intUsers, isLoading: intUsersIsLoading } = GetIntegrationUsers<IntegrationUsers>(SL)
  const { setCanSave, shouldSave } = useContext(TabContext)
  const { handleSaved } = useContext(IntegrationPageContext)
  const [initData, setInitData] = useState<UserMapping[]>([])
  const [mappingState, setMappingState] = useState<UserMapping[]>(initalUserMapping)
  useMappingState<UserMapping[]>({ isLoading, setMappingState, data: initData, mappingState, setCanSave })
  HandleSave<UserMapping[]>({ mappingState, shouldSave, saveFunction: () => handleUserCardSave(mappingState, data), handleSaved })
  HandleInitUserMapping(data, setInitData)

  if (isLoading || intUsersIsLoading) {
    return (
      <div style={{ height: 500, width: 500, marginLeft: '30%' }}>
        <Loader type='spinner' size='lg' />
      </div>
    )
  }
  return (
    <Card
      content={
        <UserIdMapping
          currentMapping={mappingState}
          integrationName='Salesloft'
          integrationUsers={intUsers || []}
          handleEdit={(edit: UserMapping[]) => setMappingState([...edit])}
          oneToOne
        />
      }
    />
  )
}

function HandleInitUserMapping (data: any, setInitData: Dispatch<SetStateAction<any>>) {
  useEffect(() => {
    if (data && data.mappings && data.mappings.user_mapping) {
      const newArray = [...data.mappings.user_mapping]
      setInitData(newArray)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])
}
