import { doPost, doPut } from 'api/api'
import GenericObject from './genericObject'
import { pick } from 'lodash'
import { useDoQuery } from './useDoQuery'

const defaultResponse: Partial<NPSResponse> = {
  score: null,
  feedback: '',
  clickedG2Link: false,
  clickedHelpdocsLink: false,
  leftG2Review: false
}

const postProps = ['score', 'feedback', 'clickedHelpdocsLink', 'clickedG2Link', 'meetingID', 'leftG2Review']

class NPSResponseReport {
  data: Record<string, any>
  score: number

  constructor ({ row }: { row: Record<string, any> }) {
    this.data = row.attributes.data
    this.score = row.attributes.score
  }
}

export class NPSResponse extends GenericObject {
  tenantID?: number
  userID?: number
  email?: string
  avatarUrl?: string
  score: number | null
  feedback?: string
  clickedHelpdocsLink: boolean
  clickedG2Link: boolean
  meetingID?: string
  leftG2Review: boolean

  get subtitle (): string { return this.email || '' }

  static loadAll () {
    return useDoQuery({
      path: '/nps/responses',
      objectClass: NPSResponse
    })
  }

  static getPieChart (days: number) {
    return useDoQuery({
      path: `/nps/responses/pie_chart?days=${days}`,
      objectClass: NPSResponseReport
    })
  }

  static getBarChart (days: number) {
    return useDoQuery({
      path: `/nps/responses/bar_chart?days=${days}`,
      objectClass: NPSResponseReport
    })
  }

  static getSummary (days: number) {
    return useDoQuery({
      path: `/nps/responses/summary?days=${days}`,
      objectClass: NPSResponseReport
    })
  }

  save () {
    const data = {
      type: 'nps_responses',
      attributes: { ...pick(this, postProps) }
    }
    const basePath = '/nps/responses'
    let doMethod = doPost
    let path = basePath
    if (this.id) {
      doMethod = doPut
      path = basePath + `/${this.id}`
    }
    return doMethod({ path, data }).then(response => {
      return new NPSResponse({ row: response.data })
    })
  }

  constructor ({ row }: { row?: Record<string, any> } = {}) {
    super({ row })
    const response = row?.attributes || defaultResponse
    this.score = response.score
    this.userID = response.user_id
    this.tenantID = response.tenant_id
    this.feedback = response.feedback
    this.clickedG2Link = response.clicked_g2_link
    this.clickedHelpdocsLink = response.clicked_helpdocs_link
    this.leftG2Review = response.left_g2_review
    this.email = response.email
    this.avatarUrl = response.avatar_url
  }
}
