import { useState, useRef, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import GroupAddIcon from '@material-ui/icons/GroupAdd'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import { getParticipants, addParticipants, removeParticipants } from 'api/conversation_participants'
import { useQuery, useQueryClient } from 'react-query'
import { TenantDataContext } from './tenant-data-context'
import CloseIcon from '@material-ui/icons/Close'
import PurpleAvatar from 'img/purple-avatar.svg'
import { Avatar, Fab, IconButton, Divider, MenuItem, Chip } from '@material-ui/core'
import SearchBar from 'components/SearchBar'
import EmptyState from 'components/EmptyState'
import MissingAvatar from 'img/agent-profile-large-invite.svg'
import { SessionContext } from 'session-context'
import Tooltip from '@material-ui/core/Tooltip'

const useStyles = makeStyles(theme => ({
  button: {
    color: '#999999',
    cursor: 'pointer',
    height: '1em',
    '&:hover': {
      fill: theme.palette.primary.main
    }
  },
  popup: {
    width: 360,
    fontFamily: theme.typography.fontFamily,
    fontSize: '14px'
  },
  titleDiv: {
    fontWeight: 600,
    fontSize: '13px'
  },
  topTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '5px 5px 5px 10px',
    color: '#5A5A5A'
  },
  closeButton: {
    height: 32,
    width: 32
  },
  closeIcon: {
    height: 20,
    width: 20
  },
  subtitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '7px 15px 7px 15px'
  },
  countDiv: {
    display: 'flex',
    color: '#8E5AE2'
  },
  participantCount: {
    backgroundColor: '#8E5AE2',
    color: 'white',
    fontSize: '13px',
    height: 20,
    width: 20,
    marginRight: 10
  },
  clearAllButton: {
    fontSize: '10px',
    boxShadow: 'none',
    textTransform: 'none',
    color: 'white',
    maxHeight: 18,
    marginBottom: 1
  },
  userListContainer: {
    height: 'calc(100% - 130px)'
  },
  userList: {
    padding: '15px 10px 10px 10px',
    fontWeight: 600,
    fontSize: '13px',
    height: 200,
    overflowY: 'auto'
  },
  mobileUserList: {
    padding: '15px 10px 10px 10px',
    fontWeight: 600,
    overflowY: 'auto',
    height: 'calc(100% - 127px)'
  },
  activeParticipantsDiv: {
    padding: 10,
    maxHeight: 115,
    overflowY: 'auto'
  },
  invitationText: {
    fontWeight: 600,
    fontSize: '13px',
    color: '#8E5AE2',
    padding: '7px 15px 7px 15px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  noTeammates: {
    paddingBottom: 30
  },
  searchBarDiv: {
    display: 'flex',
    marginBottom: 15
  },
  leaveButton: {
    fontSize: '10px',
    boxShadow: 'none',
    textTransform: 'none',
    color: 'white',
    maxHeight: 18,
    marginBottom: 1
  },
  menuItem: {
    fontWeight: 500,
    fontSize: '10px',
    lineHeight: '14px',
    width: 109,
    display: 'flex',
    justifyContent: 'center'
  },
  userRow: {
    display: 'flex',
    fontSize: '12px',
    color: '#999999',
    alignItems: 'center',
    paddingTop: 2,
    paddingBottom: 2,
    cursor: 'pointer'
  },
  userAvatar: {
    height: 25,
    width: 25,
    marginLeft: 5
  },
  userName: {
    paddingLeft: 5
  },
  checkBox: {
    padding: 5
  },
  chipsDiv: {
    fontWeight: 600,
    fontSize: '13px',
    minHeight: 76
  },
  agentChip: {
    color: '#8E5AE2',
    backgroundColor: '#F0E3FF',
    margin: 3
  },
  personChip: {
    color: '#ff8134',
    backgroundColor: '#ffe3d1',
    margin: 3
  },
  agentAvatar: {
    backgroundColor: '#FFF'
  },
  personAvatar: {
    backgroundColor: '#fff',
    color: '#ff8134 !important'
  }
}))

const getName = (conversation) => {
  const firstName = conversation?.attributes?.first_name
  const lastName = conversation?.attributes?.last_name
  const email = conversation?.attributes?.email
  let name = 'Site Visitor'
  let initials = 'SV'
  if (firstName && lastName) {
    name = firstName + ' ' + lastName
    initials = firstName.charAt(0) + lastName.charAt(0)
  } else if (firstName) {
    name = firstName
    initials = firstName.charAt(0)
  } else if (lastName) {
    name = lastName
    initials = lastName.charAt(0)
  } else if (email) {
    name = email.split('@')[0]
    initials = name.charAt(0)
  }
  return { name, initials }
}

function AgentChips (props) {
  const classes = useStyles()
  const conversation = props.conversation
  const { name, initials } = getName(conversation)

  const currentParticipants = props.currentParticipants
  const agents = props.agents

  if (!(currentParticipants && agents)) {
    return (
      <div className={classes.chipsDiv} />
    )
  }

  const activeAgents = []
  for (const participant of currentParticipants) {
    const agent = agents[participant]
    if (agent) {
      activeAgents.push({ ...agent, id: participant })
    }
  }

  return (
    <div className={classes.chipsDiv}>
      <Chip
        avatar={<Avatar className={classes.personAvatar} classes={{ root: classes.personAvatar }}>{initials}</Avatar>}
        label={name}
        className={classes.personChip}
      />
      {activeAgents.map((agent, index) => (
        <Chip
          avatar={
            <Avatar
              src={agent.avatar_url ? agent.avatar_url : PurpleAvatar}
              classes={{ img: classes.agentAvatar }}
            />
          }
          label={agent.agent_name}
          className={classes.agentChip}
          key={index}
        />
      ))}
    </div>
  )
}

function LeaveConvoDropdown (props) {
  const classes = useStyles()

  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = event => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  function leaveConversation () {
    props.leaveConversation()
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <>
      <Fab
        variant='extended'
        size='small'
        className={classes.leaveButton}
        style={{ backgroundColor: '#AA88E2' }}
        onClick={handleClick}
      >
        Leave Conversation
      </Fab>
      <Popper open={open} anchorEl={anchorEl} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom'
            }}
          >
            <Paper id='menu-list-grow'>
              <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                <MenuItem onClick={leaveConversation} className={classes.menuItem} dense>
                  Confirm Leaving
                </MenuItem>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}

function JoinLeaveButton (props) {
  const classes = useStyles()

  if (props.currentParticipant) {
    return (
      <LeaveConvoDropdown
        leaveConversation={props.leaveConversation}
      />
    )
  }
  return (
    <Fab
      variant='extended'
      size='small'
      className={classes.clearAllButton}
      style={{ backgroundColor: '#AA88E2' }}
      onClick={props.joinConversation}
    >
      Join Conversation
    </Fab>
  )
}

function UserRow (props) {
  const classes = useStyles()

  const agentUrl = props.user.avatarUrl

  return (
    <div className={classes.userRow} onClick={() => props.addToConversation(props.user.id)}>
      <div>
        <Avatar src={agentUrl || MissingAvatar} className={classes.userAvatar} classes={{ img: classes.agentAvatar }} />
      </div>
      <div className={classes.userName}>
        {props.user.name}
      </div>
    </div>
  )
}

function UsersList (props) {
  const users = props.users
  const userDict = props.userDict
  const activeIDs = props.activeIDs
  const classes = useStyles()
  const [search, setSearch] = useState('')

  if (!(users && userDict && activeIDs)) {
    return <></>
  }

  const eligibleUsers = users.filter(u => !activeIDs.includes(userDict[u.id]))

  const filteredUsers = eligibleUsers.filter(t => t.name.toLowerCase().includes(search.toLowerCase()))
    .sort((a, b) => a.name > b.name)

  return (
    <div>
      <div className={classes.searchBarDiv}>
        <SearchBar
          search={search}
          setSearch={setSearch}
        />
      </div>
      <div>
        {filteredUsers.length
          ? filteredUsers.map(user => (
            <UserRow
              user={user}
              addToConversation={props.addToConversation}
              key={user.id}
            />
          ))
          : <EmptyState message='No agents available' sad />}
      </div>
    </div>
  )
}

function ParticipantManager (props) {
  const classes = useStyles()
  const chatServiceUrl = window.chatServiceUrl
  const conversation = props.conversation
  const { agentDict, users } = useContext(TenantDataContext)
  const conversationID = conversation?.id
  const { user } = useContext(SessionContext)
  const queryClient = useQueryClient()

  const { data: participants } = useQuery(conversationID + '/participants', () => getParticipants({ chatServiceUrl, conversationID })
    .then(response => response.data), { staleTime: 20000 }
  )

  const activeIDs = participants?.attributes.participant_ids
  const participantCount = activeIDs?.length
  const userDict = agentDict?.users

  let currentParticipant = false
  if (userDict && activeIDs) {
    if (activeIDs.includes(userDict[user.id])) {
      currentParticipant = true
    }
  }

  const joinConversation = () => {
    addParticipants({ chatServiceUrl, conversationID, participants: [{ id: user.id, type: 'users' }] })
      .then(() => queryClient.invalidateQueries(conversationID + '/participants'))
  }

  const leaveConversation = () => {
    removeParticipants({ chatServiceUrl, conversationID, participants: [{ id: user.id, type: 'users' }] })
      .then(() => queryClient.invalidateQueries(conversationID + '/participants'))
  }

  const addToConversation = (userID) => {
    addParticipants({ chatServiceUrl, conversationID, participants: [{ id: userID, type: 'users' }] })
      .then(() => queryClient.invalidateQueries(conversationID + '/participants'))
  }

  return (
    <div className={classes.popup}>
      <div className={classes.topTitle}>
        <div>
          Conversation Participants:
        </div>
        <IconButton className={classes.closeButton} onClick={() => props.close()}>
          <CloseIcon className={classes.closeIcon} />
        </IconButton>
      </div>
      <Divider />
      <div className={classes.subtitle}>
        <div className={classes.countDiv}>
          <Avatar className={classes.participantCount}>{participantCount}</Avatar>
          <div>
            Current Participants
          </div>
        </div>
        <div>
          <JoinLeaveButton
            currentParticipant={currentParticipant}
            joinConversation={joinConversation}
            leaveConversation={leaveConversation}
          />
        </div>
      </div>
      <Divider />
      <div className={classes.activeParticipantsDiv}>
        <AgentChips
          conversation={conversation}
          agents={agentDict?.participants}
          currentParticipants={activeIDs}
        />
      </div>
      <div className={classes.userListContainer}>
        <Divider />
        <div className={classes.invitationText}>
          Add teammates to this conversation
        </div>
        <Divider />
        <div className={props.mobile ? classes.mobileUserList : classes.userList}>
          <UsersList
            users={users.list}
            userDict={userDict}
            activeIDs={activeIDs}
            addToConversation={addToConversation}
          />
        </div>
      </div>
    </div>
  )
}

export default function ParticipantButton (props) {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const anchorRef = useRef(null)

  const handleToggle = () => setOpen(!open)

  return (
    <>
      <Tooltip title='Add users to conversation'>
        <GroupAddIcon
          className={classes.button}
          ref={anchorRef}
          onClick={handleToggle}
        />
      </Tooltip>
      {open &&
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          transition style={{ zIndex: 10 }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: 'center bottom'
              }}
            >
              <Paper id='menu-list-grow'>
                <ClickAwayListener onClickAway={() => setOpen(false)}>
                  <div>
                    <ParticipantManager
                      conversation={props.conversation}
                      close={() => setOpen(false)}
                    />
                  </div>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>}
    </>
  )
}
