import { useState } from 'react'
import { Popover } from './materialUI/Popover';
import { Tooltip } from './materialUI/Tooltip'
import { NimblePicker } from 'emoji-mart'
import emojiData from 'emoji-mart/data/emojione.json'
import { Icon } from './materialUI/Icon';

type PickerProps = {
  insertEmoji: any
  anchorEl: any
  setAnchorEl: any
}

function Picker (props: PickerProps): JSX.Element {
  const { anchorEl, setAnchorEl, insertEmoji } = props
  const open = Boolean(anchorEl)

  if (open) {
    return (
      <div style={{ position: 'relative' }}>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
        >
          <NimblePicker
            set='emojione'
            data={emojiData}
            title='Pick an emoji'
            onSelect={insertEmoji}
          />
        </Popover>
        <div>
          <Icon icon='cancel' cursor='pointer' />
        </div>
      </div>
    )
  }
  return (
    <Tooltip title='Insert emoji'>
      <div>
        <Icon icon='smiley' cursor='pointer' />
      </div>
    </Tooltip>
  )
}

type EmojiPickerProps = {
  insertEmoji: any
}

export function EmojiPicker (props: EmojiPickerProps): JSX.Element {
  const [anchorEl, setAnchorEl] = useState<any>(null)

  const toggleAnchorEl = (event: any) => {
    if (!anchorEl) {
      setAnchorEl(event.currentTarget)
    }
  }

  const handleInsert = (emojiProps: any) => {
    props.insertEmoji(emojiProps)
    setAnchorEl(null)
  }

  return (
    <div onClick={toggleAnchorEl} style={{ display: 'flex', alignItems: 'center' }}>
      <Picker
        insertEmoji={handleInsert}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
      />
    </div>
  )
}
