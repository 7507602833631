import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Select from '@material-ui/core/Select'
import { getCustomFields } from 'api/custom_fields'
import MenuItem from '@material-ui/core/MenuItem'

const useStyles = makeStyles(theme => ({
  subtitle: {
    color: '#999',
    fontFamily: 'Poppins, sans serif',
    fontSize: '12px'
  },
  divider: {
    marginTop: 10,
    marginBottom: 10
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    marginRight: 8
  },
  input: {
    padding: 6,
    borderRadius: 30
  },
  inputRoot: {
    borderRadius: 30
  },
  select: {
    height: 32,
    borderRadius: 30
  },
  menuHeader: {
    borderBottom: '1px solid #BBB',
    width: '100%',
    fontWeight: 600,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: 'rgba(0,0,0,0.87)',
    backgroundColor: '#fff'
  },
  menuItem: {
    paddingTop: 3,
    paddingBottom: 3,
    paddingLeft: 25,
    fontSize: '.95em'
  }
}))

const ContactAttributes = [
  { name: 'first_name', display: 'First Name', group: 'contact' },
  { name: 'last_name', display: 'Last Name', group: 'contact' },
  { name: 'full_name', display: 'Full Name', group: 'contact' },
  { name: 'phone', display: 'Primary Phone', group: 'contact' },
  { name: 'mobile_phone', display: 'Mobile Phone', group: 'contact' },
  { name: 'other_phone', display: 'Alternate Phone', group: 'contact' },
  { name: 'email', display: 'Primary Email', group: 'contact' },
  { name: 'other_email', display: 'Alternate Email', group: 'contact' },
  { name: 'title', display: 'Job Title', group: 'contact' },
  { name: 'company_name', display: 'Company Name', group: 'company' },
  { name: 'company_website', display: 'Company Website', group: 'company' },
  { name: 'company_industry', display: 'Company Industry', group: 'company' },
  { name: 'company_employee_count', display: 'Company Employee Count', group: 'company' },
  { name: 'company_annual_revenue', display: 'Company Annual Revenue', group: 'company' }
]

let FilterGroups = [
  { value: 'contact', name: 'Contact Information' },
  { value: 'company', name: 'Company Information' },
  { value: 'custom', name: 'Custom Fields' }
]

interface AttributeSelectProps {
  type: string
  value: any
  setAttribute: (val: any) => void
}

function AttributeSelect ({ value, type, setAttribute }: AttributeSelectProps) {
  const classes = useStyles()
  const [fields, setFields] = useState<{ name: string; display: string; group: string; }[] | null>(null)
  const ITEM_HEIGHT = 40
  const ITEM_PADDING_TOP = 8
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 8.5 + ITEM_PADDING_TOP,
        width: 250
      }
    }
  }

  useEffect(() => {
    getCustomFields(type).then(response => {
      const customFields = response.data.map((f:any) => (
        { name: f.attributes.slug, display: f.attributes.label, group: 'custom' }
      ))
      setFields(ContactAttributes.concat(customFields))
      if (response.data.length === 0) {
        FilterGroups = [
          { value: 'contact', name: 'Contact Information' },
          { value: 'company', name: 'Company Information' }
        ]
      }
    })
  }, [type])

  if (!fields) {
    return (
      <></>
    )
  }

  return (
    <Select
      fullWidth
      variant='outlined'
      value={value || 'first_name'}
      onChange={(e) => {
        setAttribute({ attribute: e.target.value })
      }}
      MenuProps={MenuProps}
      margin='dense'
    >
      {
        FilterGroups.map((group, i) => {
          return (
            [
              <MenuItem disabled style={{ borderBottom: '1px solid #BBB', backgroundColor: 'white' }} key={i}>
                {group.name}
              </MenuItem>,
              fields.filter(item => item.group === group.value)
                .map((field, index) => (
                  <MenuItem value={field.name} key={index} className={classes.menuItem}>
                    {field.display}
                  </MenuItem>))
            ]
          )
        })
      }
    </Select>
  )
}

export default AttributeSelect
