import { Modal, ModalSection } from 'library/Modal'
import { TextField } from '@material-ui/core'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { Segment } from 'classes/segments'
import { defaultSegment } from './segmentHelpers'
import SegmentTypeButtons from './SegmentTypeButtons'
import { useContext } from 'react'
import { DynamicSegmentContext } from './DynamicSegmentContext'

export interface SegmentEditInfoModalProps {
  segment?: Segment
  open: boolean
  onHide: () => void
  onNext: (segment: Segment) => void
  currentSegments: Segment[]
}

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .required('Please enter a label for your field'),
  description: Yup.string()
    .nullable(),
  segmentType: Yup.string()
    .required('Select a segment type')
    .oneOf(['dynamic', 'static'], 'Invalid segment type')
})

const formatText = (text: string) => text.toLowerCase().replace(/\s/g, '')

export default function SegmentEditInfoModal (props: SegmentEditInfoModalProps): JSX.Element {
  const segmentNames = props.currentSegments.map(segment => formatText(segment.name))
  const { setTriggers } = useContext(DynamicSegmentContext)

  const handleNext: SegmentEditInfoModalProps['onNext'] = segment => {
    props.onNext(segment)
    props.onHide()
  }

  const checkForDuplicateNames = (segmentName: string, setStatus: (fields: { [field: string]: string }) => void) => {
    let error = { name: 'This Segment name already exists' }
    const formattedName = formatText(segmentName)
    if (segmentNames.includes(formattedName)) {
      setStatus(error)
      return true
    }
    if (formattedName === 'icp') {
      error = { name: 'We\'ve already created an ICP segment for you. Try a different name or configure your ICP.' }
      setStatus(error)
      return true
    }
    return false
  }

  return (
    <Formik
      initialValues={props.segment || defaultSegment}
      enableReinitialize
      isInitialValid={Boolean(props.segment?.id) || Boolean(props.segment?.name)}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        values.name = (values.name || '').trim()
        values.description = (values.description || '').trim()
        handleNext(values)
      }}
    >
      {({ submitForm, setFieldValue, resetForm, errors, values, isValid, touched, setFieldTouched, setErrors }) => (
        <Modal
          title={(values.id ? 'Edit' : 'Create') + ' Segment'}
          size='sm'
          open={props.open}
          onHide={() => {
            setTriggers([{ filters: [] }])
            props.onHide()
            resetForm()
          }}
          saveBtnText={(values.id && values.segmentType === 'static') ? 'Save' : 'Next'}
          handleSave={() => { if (!checkForDuplicateNames(values.name, setErrors)) { submitForm() } }}
          saveDisabled={!isValid}
          hideCancelButton
          noDividers
        >
          <ModalSection title='Segment Name'>
            {errors.name && <p style={{ color: 'red', fontSize: '13px', margin: '0' }}>{errors.name}</p>}
            <TextField
              variant='outlined'
              margin='dense'
              fullWidth
              label='Segment Name'
              placeholder='Enter Name'
              value={values?.name || ''}
              onChange={e => setFieldValue('name', e.target.value)}
              onBlur={() => setFieldTouched('name')}
              required
              error={touched?.name && !!errors?.name}
              autoFocus
            />
          </ModalSection>
          <ModalSection title='Description'>
            <TextField
              variant='outlined'
              margin='dense'
              fullWidth
              label='Segment Description'
              placeholder='Enter Description'
              multiline
              rows={4}
              value={values?.description || ''}
              onChange={e => setFieldValue('description', e.target.value)}
            />
          </ModalSection>
          <ModalSection title='Segment Type:'>
            <SegmentTypeButtons
              handleClick={setFieldValue}
              segmentType={values.segmentType}
            />
          </ModalSection>
        </Modal>
      )}
    </Formik>
  )
}
